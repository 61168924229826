import {McConsumerAction} from './mc-consumer-action.model';
import {McConsumerActionListGENERATED} from '../_generated/mc-consumer-action-list-generated.model';

export class McConsumerActionList extends McConsumerActionListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McConsumerActionList();
  }

  // ---------------------------------------------------------------------
}
