<ng-template #jobCreateEditModal let-modal>
  <!--Modal header section-->
  <div class="modal-header mc-job-create-edit-modal-header pb-0">

    <div class="d-block">
      <p class="h4" *ngIf="!editJob; else edit">{{'cc.jobs.view.create-job' | translate}}</p>

      <ng-template #edit>
        <p class="h4">{{'cc.job.view.edit-job' | translate}}</p>
      </ng-template>
    </div>

    <button type="button" class="mc-job-create-edit-modal-close-button close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div><!--Modal header /end-->

  <!--Modal body section-->
  <div class="modal-body">

    <!--Error section-->
    <div class="mc-notify-wrapper">
      <mc-notify-message [message]="errorObject.message" [type]="errorObject.type"></mc-notify-message>
    </div>

    <form [formGroup]="registerForm" class="mc-job-form">
      <div class="row">
        <div class="col-md-6">
          <div class="row mb-4 pt-3">
            <div class="col-md-3">
              <div class="text-capitalize mc-job-label">
                {{'cc.jobs.edit.type' | translate}}
                <span class="text-danger">*</span>
              </div>
            </div>
            <div class="col-md-9 pl-0">
              <!--Checkbox wrapper-->
              <div class="mc-checkbox-wrapper">
                <!--Mail-->
                <div class="form-check d-inline pl-0">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="jobClass"
                    id="mail"
                    value="SINGLE_MAIL"
                    formControlName="jobClass">
                  <label class="form-check-label text-capitalize" for="mail">
                    mail
                  </label>
                </div>

                <!--SMS-->
                <div class="form-check d-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="jobClass"
                    id="SMS"
                    value="SMS"
                    formControlName="jobClass">
                  <label class="form-check-label text-uppercase" for="SMS">
                    {{'cc.jobs.edit.sms' | translate}}
                  </label>
                </div>

                <!--Post-->
                <div class="form-check d-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="jobClass"
                    id="post"
                    value="POST"
                    formControlName="jobClass">
                  <label class="form-check-label text-capitalize" for="post">
                    {{'cc.jobs.edit.post' | translate}}
                  </label>
                </div>
              </div><!--Checkbox wrapper /end-->
            </div>
          </div>
          <!--Name section-->
          <div class="row py-2 mb-4">
            <div class="col-md-3">
              <div class=" text-capitalize">
                <label class="mc-job-label" for="name">
                  name
                  <span class="text-danger">*</span>
                </label>
              </div>
            </div>
            <div class="col=md-9">
              <input
                type="text"
                class="form-control"
                id="name"
                formControlName="name"
                aria-describedby="name"
                [ngClass]="isFormSubmitted && registerForm.controls['name'].errors ? 'mc-error-field' : ''"
              >
            </div>
          </div><!--Name /end-->
          <!--Description section-->
          <div class="row py-2">
            <div class="col-md-3">
              <div class=" text-capitalize">
                <label class="mc-job-label" for="description">{{'cc.common.view.description' | translate}}</label>
              </div>
            </div>
            <div class="col-md-9 pl-0">
               <textarea
                 class="form-control"
                 id="description"
                 rows="3"
                 formControlName="description"
                 aria-describedby="description"
                 [ngClass]="isFormSubmitted && registerForm.controls['description'].errors ? 'mc-error-field' : ''">
          </textarea>
            </div><!--Description /end-->
        </div>
        </div>
        <div class="col-md-6">
          <!--Schedule section-->
          <div class="row py-2 pt-3">
            <div class="col-md-3">
              <div class=" text-capitalize mc-job-label">{{'cc.common.view.schedule' | translate}}</div>
            </div>

            <div class="col-md-9">

              <!--Checkbox wrapper-->
              <div class="mc-checkbox-wrapper">

                <!--One time job checkbox-->
                <div class="form-check d-inline pl-0">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="jobType"
                    id="{{ONE_TIME_flag}}"
                    value="{{ONE_TIME_flag}}"
                    formControlName="jobType"
                  >
                  <label class="form-check-label text-capitalize" for="{{ONE_TIME_flag}}">
                    {{'cc.jobs.edit.one-time-job' | translate}}
                  </label>
                </div>

                <!--Cron expression checkbox-->
                <div class="form-check d-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="jobType"
                    id="CRON"
                    value="CRON"
                    formControlName="jobType"
                  >
                  <label class="form-check-label text-capitalize" for="CRON">
                    cron expression
                  </label>
                </div>

              </div><!--Checkbox wrapper /end-->

              <!--Scheduler section wrapper-->
              <div class="mt-3 mc-schedule-input-fields-wrapper">

                <!--One time job section-->
                <div class="row py-1" *ngIf="registerForm.value.jobType === 'ONE_TIME'">

                  <!--TODO: need to check if date and time need to have min value?-->
                  <!--Date input field-->
                  <div class="col-md-6">
                    <label class="form-check-label text-capitalize mc-job-label" for="oneTimeDate">
                      {{'cc.common.view.date' | translate}}
                      <span class="text-danger">*</span>
                    </label>
                    <input type="date"
                           id="oneTimeDate"
                           readonly
                           outsideDays="hidden"
                           ngbDatepicker
                           formControlName="date"
                           class="form-control mc-dates-inputs"
                           [ngClass]="isFormSubmitted && registerForm.controls['date'].errors ? 'mc-error-field' : ''"
                           #ToDateField="ngbDatepicker"
                           (click)="ToDateField.toggle()">
                  </div>

                  <!--Time input field-->
                  <div class="col-md-6">
                    <label class="form-check-label text-capitalize mc-job-label" for="oneTimeTime">
                      {{'cc.jobs.edit.time' | translate}}
                      <span class="text-danger">*</span>
                    </label>
                    <input type="time"
                           class="form-control"
                           formControlName="time"
                           [ngClass]="isFormSubmitted && registerForm.controls['time'].errors ? 'mc-error-field' : ''"
                           id="oneTimeTime">
                  </div>
                </div><!--One time job /end-->

                <!--Cron job section-->
                <div class="row pt-2" *ngIf="registerForm.value.jobType !== 'ONE_TIME'">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-12">
                        <lib-mc-cronex (cronEvent)="setCronEx($event)" [cronFromParent]="cronToChild"></lib-mc-cronex>
                      </div>
                    </div>

                    <!--Cron expression-->
                    <div class="row pt-4">
                      <div class="col-md-12">
                        <label class="form-check-label text-capitalize mc-job-label" for="cronExpression">
                          cron expression
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          class="form-control"
                          type="text"
                          name="cronExpression"
                          id="cronExpression"
                          value="cronExpression"
                          formControlName="cronExpression"
                          [ngClass]="isFormSubmitted && registerForm.controls['cronExpression'].errors ? 'mc-error-field' : ''"
                        >
                      </div><!--Cron expression /end-->
                    </div>
                  </div>
                </div>

              </div><!--Scheduler input fields wrapper /end-->

            </div>
          </div><!--Schedule /end-->
        </div>
      </div>
      </form>
    <!--Modal footer-->
    <div class="modal-footer border-top-0 mc-edit-role-wrapper py-4" style="margin-top: 210px;">
      <button type="button" class="btn mc-next-save-btn text-white" (click)="onSave()" [disabled]="false">{{'cc.common.view.save' | translate}}</button>
    </div>

  </div><!--Modal body /end-->
</ng-template>

<!--<button class="system-action-btn mb-0"-->
<!--   (click)="openModal(jobCreateEditModal)"-->
<!--   #openModalBtn-->
<!--    [ngStyle]="{ 'float' : editJob ? 'left' : 'right' }">-->
<!--  <i *ngIf="!editJob" class="fas fa-plus-circle"></i>-->
<!--  {{ editJob ? "Edit":"Create"}} job-->
<!--  <i *ngIf="editJob" class="fa fa-pen mc-cursor-pointer"></i>-->
<!--</button>-->
