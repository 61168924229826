import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {McForm, McValueLabelList} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';
import {MccFiSelectEventAfterValidation} from './mcc-fi-select-event-after-validation';

@Component({
  selector: 'mcc-fi-select',
  templateUrl: './mcc-fi-select.component.html',
  styleUrls: ['./mcc-fi-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MccFiSelectComponent implements OnInit, OnDestroy, OnChanges {

  requiredFlg!: Validators;
  validatorList: any[] = [];
  labelPositionClass!: string;
  formValidationTriggerSubscription!: Subscription;


  @Input() mcForm!: McForm;
  @Input() label!: string;
  @Input() name!: string;
  @Input() optionsVll!: McValueLabelList;
  @Input() value = '';
  @Input() placeholderOption!: string;
  @Output() eventSelectionChange = new EventEmitter();
  @Input() validRequiredFlg = false;
  @Input() validationInComponent = false;
  @Output() eventValueChanged = new EventEmitter();
  @Output() eventAfterValidation = new EventEmitter();
  @Input() labelOnTop = true;
  @Input() extraCssClass: any;
  @Input() isSelectInFilter = false;
  @Input() labelHasDunningIcon!: boolean;


  getSelectForm(): FormGroup {
    return this.mcForm.getControl(this.name) as FormGroup;
  }

  setCurrentValue() {
    if (!this.getSelectForm()) {
      const selectForm = this.mcForm.formBuilder.group({
        value: [this.value, this.validatorList],
        label: [this.label],
        errorFlg: [false],
        errorMsg: ['']
      });

      if (this.mcForm) {
        this.mcForm.addControl(this.name, selectForm);
      }
    } else {
      this.getSelectForm().controls['value'].setValue(this.value);
      this.getSelectForm().controls['errorFlg'].setValue(false);
      this.getSelectForm().controls['errorMsg'].setValue('');
    }

  }

   forcePlaceholderIntoOptionsVll() {
    if (this.placeholderOption && this.optionsVll && (!this.optionsVll.hasValue(''))) {
      this.optionsVll.addFirst('', this.placeholderOption);
    }
  }


  ngOnInit() {


    this.validationSetup();

    if (this.mcForm) {
      this.formValidationTriggerSubscription = this.mcForm.getValidationTriggerObservable().subscribe(() => {
        this.validateSelect();
      });
    }

    this.setCurrentValue();

    this.labelPositionCssClass();
  }

  // ---------------------------------------------------------------------
  ngOnChanges(changes: SimpleChanges): void {

    if (changes['optionsVll'] || changes['placeholderOption']) {
      this.forcePlaceholderIntoOptionsVll();
    }

    if (changes['validRequiredFlg']) {
      if ((changes['validRequiredFlg'].previousValue !== changes['validRequiredFlg'].currentValue)) {
        this.validationSetup();
        if (this.getSelectForm()) {
          this.getSelectForm().controls['value'].setValidators(this.validatorList);
          this.getSelectForm().controls['errorFlg'].setValue(false);
          this.getSelectForm().controls['errorMsg'].setValue('');
        }
      }
    }
  }

  // ---------------------------------------------------------------------
  ngOnDestroy(): void {
    if (this.formValidationTriggerSubscription) {
      this.formValidationTriggerSubscription.unsubscribe();
    }
    this.mcForm.formGroup.removeControl(this.name);
  }
  validateSelect() {
    this.validationSetup();
    this.getSelectForm().controls['value'].setValidators(this.validatorList);
    this.getSelectForm().get('value')?.updateValueAndValidity();
    if (this.getSelectForm().get('value')?.errors) {
      // @ts-ignore
      const validators = Object.keys(this.getSelectForm().get('value').errors);
      if (validators) {
        this.getSelectForm().get('errorFlg')?.setValue(true);
        validators.forEach(item => {
          if (item === 'required') {
            this.getSelectForm().get('errorMsg')?.setValue(`This field is required`);
          } else {
            this.getSelectForm().get('errorMsg')?.setValue(`Some validation`);
          }
        });
      }
    } else {
      this.getSelectForm().get('errorFlg')?.setValue(false);
      this.getSelectForm().get('errorMsg')?.setValue(``);
    }
    this.fireEventAfterValidation();
  }

  validationSetup() {
    this.validatorList = [];

    if (this.validRequiredFlg) {
      this.requiredFlg = Validators.required;
      this.validatorList.push(this.requiredFlg);
    }

    /*if (this.customValidator) {
      this.validatorList.push(this.customValidator);
    }*/
  }

  labelPositionCssClass() {
    if (this.labelOnTop === true) {
      this.labelPositionClass = 'flex-column';
    } else {
      this.labelPositionClass = 'flex-row align-items-center';
    }
  }

  /* actSelectionChange() {
     this.eventSelectionChange.emit(this.selectedValue);
   }*/


  public fireEventAfterValidation() {
    if (this.eventAfterValidation) {
      const eventObj = new MccFiSelectEventAfterValidation();
      eventObj.validFlg = !this.getSelectForm().get('value')?.errors;

      this.eventAfterValidation.emit(eventObj);
    }
  }

  clearErrors() {
    this.getSelectForm().get('errorFlg')?.setValue(false);
    this.getSelectForm().get('errorMsg')?.setValue('');
  }

  onSelectChanged(value: any) {
    this.clearErrors();
    if (this.eventValueChanged) {
      this.eventValueChanged.emit(value);
    }
  }


  clearInput() {
    this.mcForm.getControl(this.name)?.get('value')?.setValue('');
  }
}
