import {Component, Inject, OnInit} from '@angular/core';
import {
  BaseDirective,
  CurrencyUtil,
  EbFactoringService, IEbFactoring,
  McBillingBatchAssignedToStatementList,
  McBillingItem,
  McBillingStatement, McBillingStatementItem,
  McBillingStatementItemList, McBillingStatementService, McCurrencyService,
  McEntity2, McGod,
  McHtmlButton,
  McMyBillingStatementItemTable3Config, WebFile
} from '@miticon-ui/mc-core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Location} from '@angular/common';
import * as fileSaver from 'file-saver';
import {takeUntil} from "rxjs/operators";
import {MkFilterOutput, MkTableConfig} from "@miticon-ui/mc-components";

@Component({
  selector: 'mc-my-billing-statement-profile-page',
  templateUrl: './mc-my-billing-statement-profile-page.component.html'
})
export class McMyBillingStatementProfilePageComponent extends BaseDirective implements OnInit {

  mcBillingStatementId!: number;
  mcBillingStatement!: McBillingStatement;
  mcBillingBatchSnapshotList = new McBillingBatchAssignedToStatementList();
  mcBillingStatementItemList!: McBillingStatementItemList;
  mcBillingItem!: McBillingItem;
  mcBillingStatementItemTable3Config = new McMyBillingStatementItemTable3Config();
  childEntity!: McEntity2;
  amountSum: string | null = '';
  vatSum: string | null = '';
  amountWithVatSum = '';
  mcGod = McGod.getInstance();
  successMsg = '';
  errorMsg = '';
  snapshotListFinalSum = 0;
  amountWitVatTotalNumber = 0;
  webFile = new WebFile();
  webFileId!: number;
  webFileName!: string;
  entityAddress!: string;
  currency!: string;
  private factoring!: IEbFactoring | undefined;
  // ----------
  isLoading = false;
  tableConfig = new MkTableConfig();

  // ---------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private factoringService: EbFactoringService,
    private mcCurrencyService: McCurrencyService,
    private statementService: McBillingStatementService,
    @Inject('designConfig') design: any) {
    super();
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.route.paramMap
      .pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      // @ts-ignore
      this.mcBillingStatementId = +params.get('id');
      this.mcBillingStatement = new McBillingStatement();
      this.mcBillingStatementItemList = new McBillingStatementItemList();
      this.actLoad(0,100);
    });
    this.initTableConfig();
  }

  // ---------------------------------------------------------------------
  actLoad(pageNumber: number, pageSize: number) {
    this.isLoading = true;
    this.mcBillingStatement.loadById(this.mcBillingStatementId, () => {
      this.mcBillingStatement.payoutTableItems.items.forEach(item => {
        this.snapshotListFinalSum += item.finalSum;
      });
      this.factoring = this.factoringService.getChildFactoringForDate(this.mcBillingStatement.statementDate);
      this.webFile.getFileByIdAndType(this.mcBillingStatement.pdfWebfileId, WebFile.TYPE_CD_MC_BILLING_STATEMENT_PAYOUT_REPORT_PDF, () => {
          this.webFileName = this.webFile.filename;
        }
      );

      let tempAmountSum = 0;
      let tempVatSum = 0;
      let tempAmountWithVatSum = 0;
      this.mcBillingStatementItemList.pageNum = pageNumber;
      this.mcBillingStatementItemList.pageItemsPerPageCount = pageSize;
      this.mcBillingStatementItemList.loadAllByMcBillingStatementId(this.mcBillingStatementId, () => {
        this.isLoading = false;console.log(this.mcBillingStatementItemList)
        this.mcBillingStatementItemList.items.forEach(item => {
          item.currencyCode = this.mcBillingStatement.currencyCode;
          if (item.correctionTypeCd === McBillingStatementItem.CORRECTION_TYPE_CD_CREDIT) {
            tempAmountSum -= item.amount;
            tempVatSum -= item.vatForBillingStatementItem;
            tempAmountWithVatSum += -item.amount - item.vatForBillingStatementItem;
          } else {
            tempAmountSum += item.amount;
            tempVatSum += item.vatForBillingStatementItem;
            tempAmountWithVatSum += item.amount + item.vatForBillingStatementItem;
          }
        });
        this.amountSum = McGod.formatNumberToMoney(tempAmountSum);
        this.vatSum = McGod.formatNumberToMoney(tempVatSum);
        this.amountWithVatSum = this.format(tempAmountWithVatSum);
        this.amountWitVatTotalNumber = tempAmountWithVatSum;
      });

      const childEntityId = this.mcBillingStatement.childEntityId;
      this.webFileId = this.mcBillingStatement.pdfWebfileId;
      this.mcBillingBatchSnapshotList.loadByIdMcBillingStatement(this.mcBillingStatement.id, () => {
      }, () => {
        this.errorMsg = this.mcBillingBatchSnapshotList.apiErrorMessage;
      });
      this.childEntity = new McEntity2();
      this.childEntity.loadById(childEntityId, () => {
        this.entityAddress = `${this.childEntity.studioOwner} ${this.childEntity.studioOwnerAddress} ${this.childEntity.studioOwnerPostalCode} ${this.childEntity.studioOwnerCity}`;
      });
    });
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('cc.jobs.edit.type'), 'getTypeCdLabel()', 250, 'typeCd');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.item'), 'title', 250, 'title');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.batch'), 'mcBillingBatchName', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.period'), 'period', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.units'), 'fldNoOfItems()', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.price'), 'getPriceDescForStatementItem()', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.factoring.transactions.amount'), 'getAmount()', 250, 'amount');
    this.tableConfig.addColumnInnerHtml(McGod.t('cc.product.view.vat'), 'getVATString()', '');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.total-amount'), 'getTotalFormat()', 250, '');

  }

  onTableItemAction($event: McHtmlButton) {
  }

  onActionsDropdownClick(action: string) {
    if (action === 'PREVIEW_PDF') {
      this.webFile.getFileByIdAndType(this.mcBillingStatement.pdfWebfileId, WebFile.TYPE_CD_MC_BILLING_STATEMENT_PAYOUT_REPORT_PDF, () => {
        this.webFileName = this.webFile.filename;
        this.webFile.downloadFile().subscribe(response => {
          const blob = new Blob([response], {type: 'application/pdf'});
          const url = URL.createObjectURL(blob);
          const newWindow = window.open(url, '_blank');
          newWindow?.document.write(`<html><head><title>${this.webFile.filename}</title></head><body>
             <embed width="100%" height="100%" name="plugin" src="${url}"
            type="application/pdf" internalinstanceid="21"></body></html>`);
        });
      });
    }
    if (action === 'DOWNLOAD_PDF') {
      this.webFile.getFileByIdAndType(this.mcBillingStatement.pdfWebfileId, WebFile.TYPE_CD_MC_BILLING_STATEMENT_PAYOUT_REPORT_PDF, () => {
        this.webFile.downloadFile().subscribe(response => {
          fileSaver.saveAs(response, `${this.webFile.filename}`);
        });
      });
    }
    if (action === 'PREVIEW_XLS') {
      if (this.mcBillingStatement.isStatusCdFINALIZED()) {
        this.webFile.getFileByIdAndType(this.mcBillingStatement.xlsWebfileId, WebFile.TYPE_CD_MC_BILLING_STATEMENT_CONSUMERS_REPORT_CSV, () => {
          this.webFile.downloadFile().subscribe(response => {
            const blob = new Blob([response], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);
            const newWindow = window.open(url, '_blank');
            newWindow?.document.write(`<html><head><title>${this.webFile.filename}</title></head><body>
             <embed width="100%" height="100%" name="plugin" src="${url}"
            type="application/pdf" internalinstanceid="21"></body></html>`);
          });
        });
      }
    }
    if (action === 'DOWNLOAD_XLS') {
      if (this.mcBillingStatement.isStatusCdFINALIZED()) {
        this.webFile.getFileByIdAndType(this.mcBillingStatement.xlsWebfileId, WebFile.TYPE_CD_MC_BILLING_STATEMENT_CONSUMERS_REPORT_CSV, () => {
          this.webFile.downloadFile().subscribe(response => {
            fileSaver.saveAs(response, `${this.webFile.filename}`);
          });
        });
      }
    }
  }

  format(value: number): string {
    if (!value) {
      value = 0;
    }
    return CurrencyUtil.format(value, this.mcBillingStatement.currencyCode);
  }

  onFilterChanged(filterOutput: MkFilterOutput) {
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize);
  }
}
