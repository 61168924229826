
import {Table3Config} from '../extra/table-3-config';

// import {BkFinanceReportGENERATED} from '../_generated/bk-finance-report-generated.model';

export class BkFinanceReportTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colBookingDateFrom', 'Booking date from', 'bookingDateFrom', 'bookingDateFrom');
    this.addColumn('colBookingDateTo', 'Booking date to', 'bookingDateTo', 'bookingDateTo');
    this.addColumn('colIdWebFile', 'Webfile ID', 'idWebFile', 'idWebFile');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');

*/
  }
}
