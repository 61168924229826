/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EbPaymentReport2SepaFileTransactionFilter} from '../models/eb-payment-report-2-sepa-file-transaction-filter.model';

export class EbPaymentReport2SepaFileTransactionFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbPaymentReport2SepaFileTransactionFilter {
    console.error('MTCN-ERROR: Not Implemented: EbPaymentReport2SepaFileTransactionFilter::createNewInstance(). Add this method to final class and return new EbPaymentReport2SepaFileTransactionFilter();');
      throw new Error('Cannot EbPaymentReport2SepaFileTransactionFilter::createNewInstance(). Add this method to final class and return new EbPaymentReport2SepaFileTransactionFilter();');
  }

}
