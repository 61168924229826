import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {McEntityConsumerAttribute, McForm} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'mcc-fi-custom-attribute',
  templateUrl: './mcc-fi-custom-attribute.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MccFiCustomAttributeComponent implements OnInit, OnDestroy {

  formValidationTriggerSubscription!: Subscription;

  /*Events*/
  @Input() extraCssClasses!: any;
  @Input() customAttribute!: McEntityConsumerAttribute;
  @Input() mcForm!: McForm;
  @Input() name!: string;
  @Input() value: any;
  checkboxBooleanValue!: boolean;

  constructor() {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    if (this.customAttribute.isTypeCdBOOLEAN()) {
      this.setBooleanValue();
    }
    // console.log('CUSTOM', this.customAttribute);
  }

  // ---------------------------------------------------------------------
  ngOnDestroy(): void {
  }

  isVisible(): boolean {
    return true;
  }

  setBooleanValue() { // boolean custom attribute in DB is string
    this.checkboxBooleanValue = this.customAttribute.value === 'true' || this.customAttribute.value === '1';
  }


}
