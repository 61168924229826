import {McEntity} from './mc-entity.model';
import {McEntityListGENERATED} from '../_generated/mc-entity-list-generated.model';

export class McEntityList extends McEntityListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McEntityList();
  }

  // ---------------------------------------------------------------------
}
