<div class="row">
  <div class="col-md-12">
    <div class="p-3">
      <mcc-fi-checkbox *ngIf="customizeFlg" label="{{'cc.pricelist.customize' | translate}}"
                       [name]="'ctrlCustomize' + index"
                       [value]="service2PriceList.flgCustomPrice"
                       [isCheckboxChecked]="service2PriceList.flgCustomPrice"
                       [mcForm]="mcForm" (eventValueChanged)="onCustomPriceFlgValueChangeEmit($event)">
      </mcc-fi-checkbox>
    </div>
    <div [ngClass]="{'mc-disable' : (!service2PriceList.flgCustomPrice && customizeFlg)}">
      <div class="pricing-main-radios">
        <mcc-fi-radio-buttons2 [directionHorizontal]="true"
                               [mcForm]="mcForm"
                               [name]="'ctrlCharge' + index"
                               [value]="service2PriceList.pricingTypeCd ? service2PriceList.pricingTypeCd : ''"
                               [disabledOptionsVll]="areServiceButtonsDisabled ? disabledOptionsVll : null"
                               [optionsVll]="chargeOptionsVll"
                               (eventValueChanged)="onChargeOptionChangeEmit($event)"
                               [validRequiredFlg]="true"></mcc-fi-radio-buttons2>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <!-- Do not charge section -->
          <div *ngIf="additionalDoNotChargeFlg"
               [ngClass]="{'mc-disable' : (!service2PriceList.pricingTypeCd || service2PriceList.isPricingTypeCdDO_NOT_CHARGE_AT_ALL())}">
            <mcc-fi-checkbox [label]="additionalDoNotChargeLabel"
                             [name]="'ctrlAdditionalDoNotCharge' + index"
                             [value]="additionalDoNotChargeValue"
                             [isCheckboxChecked]="additionalDoNotChargeValue"
                             [mcForm]="mcForm" (eventValueChanged)="onAdditionalDoNotChargeValueChangeEmit($event)">
            </mcc-fi-checkbox>
          </div>
        </div>

        <!-- One time only section -->
        <div class="col-4">
          <div [ngClass]="{'mc-disable' : !service2PriceList.isPricingTypeCdONE_TIME_ONLY()}">
            <mcc-fi-number-type [nameForValue]="'ctrlValueOneTimeOnly' + index"
                                [nameForType]="'ctrlTypeOneTimeOnly' + index"
                                label=""
                                [validMinNumber]="0"
                                [validRequiredFlg]="service2PriceList.isPricingTypeCdONE_TIME_ONLY()"
                                [validNumberWithTwoDecimals]="true"
                                [value]="service2PriceList.priceOneTime"
                                [mcForm]="mcForm" [valueType]="currency" [disablePercentage]="true"
                                [chosenCurrency]="currency"
                                [labelOnTopFlg]="true"
                                [disableInputFlg]="!service2PriceList.isPricingTypeCdONE_TIME_ONLY()"
                                (eventAmountValueChanged)="onOneTimeOnlyChargeValueChangeEmit($event)">
            </mcc-fi-number-type>

          </div>
        </div>
        <!-- Mix section -->
        <div class="col-4">
          <div [ngClass]="{'mc-disable' : !service2PriceList.isPricingTypeCdMIX()}">
            <mcc-fi-number-type [nameForValue]="'ctrlValueMix' + index" [nameForType]="'ctrlTypeMix' + index"
                                label="{{'cc.pricelist.flat-price-of' | translate}}"
                                [validMinNumber]="0"
                                [validRequiredFlg]="service2PriceList.isPricingTypeCdMIX()"
                                [validNumberWithTwoDecimals]="true"
                                [value]="service2PriceList.priceFlat"
                                [mcForm]="mcForm" [valueType]="currency" [disablePercentage]="true"
                                [labelOnTopFlg]="false"
                                [chosenCurrency]="currency"
                                [disableInputFlg]="!service2PriceList.isPricingTypeCdMIX()"
                                (eventAmountValueChanged)="onMixFlatPriceValueChangeEmit($event)">
            </mcc-fi-number-type>

            <mcc-fi-textbox label="for" [name]="'ctrlForUnits' + index" size="10"
                            validIntegerNumber="true"
                            [value]="service2PriceList.unitLimitForFlatPrice"
                            [validIntegerNumber]="true"
                            [mcForm]="mcForm" additionalDescription="{{'cc.pricelist.unit-s' | translate}}"
                            [validRequiredFlg]="service2PriceList.isPricingTypeCdMIX()"
                            [disableInputFlg]="!service2PriceList.isPricingTypeCdMIX()"
                            (eventValueChanged)="onLimitForUnitsValueChangeEmit($event)"></mcc-fi-textbox>

            <mcc-fi-number-type [nameForValue]="'ctrlValuePlusPerAdditionalUnit' + index"
                                [nameForType]="'ctrlTypePlusPerAdditionalUnit' + index"
                                label="{{'cc.common.plus' | translate}}"
                                [validMinNumber]="0"
                                [chosenCurrency]="currency"
                                [validRequiredFlg]="service2PriceList.isPricingTypeCdMIX()"
                                [validNumberWithTwoDecimals]="true"
                                [value]="service2PriceList.priceAdditionalUnit"
                                additionalDescription="{{'cc.pricelist.per-additional-unit' | translate}}"
                                [mcForm]="mcForm"
                                [valueType]="service2PriceList.isFranchiseFeePerTransactionOrAdvertisingFee() ? 'percentage' : currency"
                                [disablePercentage]="true"
                                [labelOnTopFlg]="false"
                                [disableInputFlg]="!service2PriceList.isPricingTypeCdMIX()"
                                (eventAmountValueChanged)="onMixPlusPerAdditionUnitChangeValuEmit($event)"
                                [validMaxNumber]="service2PriceList.isFranchiseFeePerTransactionOrAdvertisingFee() ? 100 : undefined"
                                [disableMoneyValue]="service2PriceList.isFranchiseFeePerTransactionOrAdvertisingFee()">
            </mcc-fi-number-type>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
