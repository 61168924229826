import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseDirective, EbSepaTransactionService, McForm, McGod, McValueLabelList, PmTransaction} from "@miticon-ui/mc-core";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'pm-transactions-collection-type-part',
  templateUrl: './pm-transactions-collection-type-part.component.html',
  styleUrls: ['./pm-transactions-collection-type-part.component.css']
})
export class PmTransactionsCollectionTypePartComponent extends BaseDirective implements OnInit {

  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter();

  @Input() pmTransaction = new PmTransaction();

  mcGod = McGod.getInstance();

  mcForm = new McForm();

  collectionTypeVll: McValueLabelList;

  disableBtn = false;

  constructor(private ebSepaTransactionService: EbSepaTransactionService, private toastr: ToastrService) { super(); }

  ngOnInit(): void {
    this.collectionTypeVll = new McValueLabelList();
    this.collectionTypeVll.add(PmTransaction.COLLECTION_TYPE_CD_DO_NOT_COLLECT, McGod.t('Do not collect'));
    this.collectionTypeVll.add(PmTransaction.COLLECTION_TYPE_CD_DIRECT_DEBIT, McGod.t('Collect direct debit'));
  }

  actSave(){
    if(this.mcForm.isValid()){
      this.disableBtn = true;
      const collectionType = this.mcForm.getValue('ctrlCollectionType');

      this.ebSepaTransactionService.changeCollectionType(this.pmTransaction.id, collectionType).subscribe(
        () => {
          this.disableBtn = false;
          this.eventSaved.emit();
        },
        (err) => {
          this.disableBtn = false;
          this.toastr.error(err.error.message);
        }
      )
    }
  }

  actCancel() {
    this.eventCanceled.emit();
  }

}
