/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McProcessRunTable3ConfigGENERATED} from '../_generated/mc-process-run-table-3-config-generated.model';

// import {McProcessRunGENERATED} from '../_generated/mc-process-run-generated.model';

export class McProcessRunTable3Config extends McProcessRunTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colRun', 'Run', 'fldRunNameId()', '');
    this.addColumn('colCreatedOn', 'Created On', 'getSysCreatedDatetimeString()', '');
    this.addColumn('colNewItems', 'New Items', 'fldNewItems()', '');
    this.addColumn('colReady', 'Ready', 'fldReady()', '');
    this.addColumn('colBuilt', 'Built', 'fldBuilt()', '');
    this.addColumn('colDelivered', 'Delivered', 'fldDelivered()', '');
    this.addColumn('colSkipped', 'Skipped', 'fldSkipped()', '');
    this.addColumn('colError', 'Error', 'fldError()', '');
    this.addColumn('colStatusCd', 'Status', 'getStatusCdLabel()', 'statusCd');

  }
}
