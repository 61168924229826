
import {Table3Config} from '../extra/table-3-config';

// import {EbSepaFileTransactionGENERATED} from '../_generated/eb-sepa-file-transaction-generated.model';

export class EbSepaFileTransactionTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colBatchCollectionDate', 'Batch date from SEPA file', 'batchCollectionDate', 'batchCollectionDate');
    this.addColumn('colCollectionTypeCd', 'Collection type code', 'collectionTypeCd', 'collectionTypeCd');
    this.addColumn('colCreatedAt', 'Created at', 'createdAt', 'createdAt');
    this.addColumn('colCreatedDatetime', 'Created Datetime', 'createdDatetime', 'createdDatetime');
    this.addColumn('colCreatedUserId', 'Created User', 'createdUserId', 'createdUserId');
    this.addColumn('colCsrConsumer', 'McConsumer', 'csrConsumer', 'csrConsumer');
    this.addColumn('colDbtrNm', 'Debtor name', 'dbtrNm', 'dbtrNm');
    this.addColumn('colDbtracctIdIban', 'Debtor account IBAN', 'dbtracctIdIban', 'dbtracctIdIban');
    this.addColumn('colDbtragtFininstnidOthrId', 'debtor_agent_id', 'dbtragtFininstnidOthrId', 'dbtragtFininstnidOthrId');
    this.addColumn('colDelayFlg', 'Delay flag', 'delayFlg', 'delayFlg');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colDrctdbttxMndtrltdinfDtofsgntr', 'Direct debit transaction signature date', 'drctdbttxMndtrltdinfDtofsgntr', 'drctdbttxMndtrltdinfDtofsgntr');
    this.addColumn('colDrctdbttxMndtrltdinfMndtid', 'Direct debit transaction mandate ID', 'drctdbttxMndtrltdinfMndtid', 'drctdbttxMndtrltdinfMndtid');
    this.addColumn('colDueDatetime', 'Due Datetime', 'dueDatetime', 'dueDatetime');
    this.addColumn('colDunningCompletedFlg', 'dunning completed flag', 'dunningCompletedFlg', 'dunningCompletedFlg');
    this.addColumn('colEbFactoring', 'Factoring', 'ebFactoring', 'ebFactoring');
    this.addColumn('colExportDrctdbttxMndtrltdinfMndtid', 'Direct debit transaction mandate ID (EXPORT)', 'exportDrctdbttxMndtrltdinfMndtid', 'exportDrctdbttxMndtrltdinfMndtid');
    this.addColumn('colExtTransactionId', 'External transaction ID', 'extTransactionId', 'extTransactionId');
    this.addColumn('colFactoringAcceptedFlg', 'Factoring accepted flag', 'factoringAcceptedFlg', 'factoringAcceptedFlg');
    this.addColumn('colFixStatusCd', 'Fix Status code', 'fixStatusCd', 'fixStatusCd');
    this.addColumn('colIdCsrConsumer', 'Consumer ID', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colIdEbFactoring', 'Factoring contract applied on SEPA', 'idEbFactoring', 'idEbFactoring');
    this.addColumn('colIdEbSepaFile', 'SEPA file ID', 'idEbSepaFile', 'idEbSepaFile');
    this.addColumn('colIdFactoringEntity', 'Factoring Entity Id', 'idFactoringEntity', 'idFactoringEntity');
    this.addColumn('colIdMcEntity', 'Entity Id', 'idMcEntity', 'idMcEntity');
    this.addColumn('colInDunningFlg', 'In dunning flag', 'inDunningFlg', 'inDunningFlg');
    this.addColumn('colInstdamt', 'Instructed amount', 'instdamt', 'instdamt');
    this.addColumn('colInstdamtCcy', 'Instructed amount currency', 'instdamtCcy', 'instdamtCcy');
    this.addColumn('colLastPauseUnpauseByUserId', 'Last pause or unpause by user', 'lastPauseUnpauseByUserId', 'lastPauseUnpauseByUserId');
    this.addColumn('colLastUpdatedDatetime', 'Last updated Datetime', 'lastUpdatedDatetime', 'lastUpdatedDatetime');
    this.addColumn('colMaxPauseDays', 'Maximum pause days from entity', 'maxPauseDays', 'maxPauseDays');
    this.addColumn('colMcEntity', 'McEntity', 'mcEntity', 'mcEntity');
    this.addColumn('colNetFeeAmount', 'Net fee amount', 'netFeeAmount', 'netFeeAmount');
    this.addColumn('colNetFeeVatAmount', 'Net fee VAT amount', 'netFeeVatAmount', 'netFeeVatAmount');
    this.addColumn('colNonFactoringFlg', 'Non factoring flag', 'nonFactoringFlg', 'nonFactoringFlg');
    this.addColumn('colPauseDaysCountWithoutCurrentPauseDays', 'Paused days count without current pause days', 'pauseDaysCountWithoutCurrentPauseDays', 'pauseDaysCountWithoutCurrentPauseDays');
    this.addColumn('colPauseEndDate', 'Pause end date', 'pauseEndDate', 'pauseEndDate');
    this.addColumn('colPauseStartDate', 'Pause start date', 'pauseStartDate', 'pauseStartDate');
    this.addColumn('colPauseUnpauseReason', 'Last pause or unpause reason', 'pauseUnpauseReason', 'pauseUnpauseReason');
    this.addColumn('colPaymentAmount', 'Payment amount', 'paymentAmount', 'paymentAmount');
    this.addColumn('colPmtidEndtoendid', 'Payment ID', 'pmtidEndtoendid', 'pmtidEndtoendid');
    this.addColumn('colReason', 'Reason', 'reason', 'reason');
    this.addColumn('colReasonForChangingRequestedCollectionDate', 'Reason for changing requested collection date', 'reasonForChangingRequestedCollectionDate', 'reasonForChangingRequestedCollectionDate');
    this.addColumn('colReminderLastInterestCalculatedDatetime', 'Reminder Last Interest Calculated DateTime', 'reminderLastInterestCalculatedDatetime', 'reminderLastInterestCalculatedDatetime');
    this.addColumn('colRequestedCollectionDate', 'Requested collection date', 'requestedCollectionDate', 'requestedCollectionDate');
    this.addColumn('colRmtinfUstrd', 'Remittance info unstructured', 'rmtinfUstrd', 'rmtinfUstrd');
    this.addColumn('colSentToInkassoDatetime', 'Sent to inkasso datetime', 'sentToInkassoDatetime', 'sentToInkassoDatetime');
    this.addColumn('colSourceTypeCd', 'Source Type', 'sourceTypeCd', 'sourceTypeCd');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colTypeCd', 'Type', 'typeCd', 'typeCd');
    this.addColumn('colUpdatedAt', 'Updated at', 'updatedAt', 'updatedAt');
    this.addColumn('colWaitingPeriod', 'Waiting period', 'waitingPeriod', 'waitingPeriod');

*/
  }
}
