
import {Table3Config} from '../extra/table-3-config';

// import {McConsumerActionGENERATED} from '../_generated/mc-consumer-action-generated.model';

export class McConsumerActionTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colCsrConsumer', 'Consumer', 'csrConsumer', 'csrConsumer');
    this.addColumn('colEmailActionFlg', 'Email Action Flg', 'emailActionFlg', 'emailActionFlg');
    this.addColumn('colEmailBodyAttachmentWebfileId', 'Email Body Attachments Webfile Id', 'emailBodyAttachmentWebfileId', 'emailBodyAttachmentWebfileId');
    this.addColumn('colEmailBodyHtml', 'Email Body Html', 'emailBodyHtml', 'emailBodyHtml');
    this.addColumn('colEmailSubject', 'Email Subject', 'emailSubject', 'emailSubject');
    this.addColumn('colErrorMessage', 'Error Message', 'errorMessage', 'errorMessage');
    this.addColumn('colIdCsrConsumer', 'Consumer Id', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colPostActionFlg', 'Post Action Flg', 'postActionFlg', 'postActionFlg');
    this.addColumn('colPostWebfileId', 'Post Webfile Id', 'postWebfileId', 'postWebfileId');
    this.addColumn('colSentDatetime', 'Sent DateTime', 'sentDatetime', 'sentDatetime');
    this.addColumn('colSmsActionFlg', 'SMS Action Flg', 'smsActionFlg', 'smsActionFlg');
    this.addColumn('colSmsBody', 'SMS Body', 'smsBody', 'smsBody');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colTitle', 'Level title', 'title', 'title');
    this.addColumn('colTypeCd', 'Type code', 'typeCd', 'typeCd');

*/
  }
}
