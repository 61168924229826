export const ICON_VIEW_EMAIL = `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1844_8262)">
<path d="M20 4.5H4C2.9 4.5 2.01 5.4 2.01 6.5L2 18.5C2 19.6 2.9 20.5 4 20.5H20C21.1 20.5 22 19.6 22 18.5V6.5C22 5.4 21.1 4.5 20 4.5ZM19.6 8.75L12.53 13.17C12.21 13.37 11.79 13.37 11.47 13.17L4.4 8.75C4.15 8.59 4 8.32 4 8.03C4 7.36 4.73 6.96 5.3 7.31L12 11.5L18.7 7.31C19.27 6.96 20 7.36 20 8.03C20 8.32 19.85 8.59 19.6 8.75Z" fill="#53589B"/>
</g>
<defs>
<clipPath id="clip0_1844_8262">
<rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
`
