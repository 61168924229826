/*BbmTestObj*/
import {EbSepaExportGENERATED} from '../_generated/eb-sepa-export-generated.model';
import {McGod} from '../extra/mc-god.service';
import {McDateUtils} from '../common/mc-date-utils';
import {McHtml} from '../_core/mc-html';
import {ActionOptions} from '../_core/mc-html-action-options';
import {CurrencyUtil} from '../common/currency-util';

export class EbSepaExport extends EbSepaExportGENERATED {

  public static ACTION_REVERT = 'REVERT';
  public static ACTION_DOWNLOAD = 'DOWNLOAD';
  public static ACTION_SENT_TO_BANK = 'SENT_TO_BANK';
  public static ACTION_SEND_VIA_EBICS = 'SEND VIA EBICS';
  public static ACTION_UPLOAD_FILE = 'UPLOAD FILE';

  private _fldExportSepaActions: any = null;

  public static override createNewInstance() {
    return new EbSepaExport();
  }

  // ---------------------------------------------------------------------
  public populateForExport(bankAccountId: number, requestedCollectionDate: number): void {
    this.id = 0;
    this.idWebfile = 0;
    this.idMcEntity = McGod.getLoggedEntityIdFromToken();
    this.idSystemUser = McGod.getLoggedUserIdFromToken();
    this.idMcBankAccount = +bankAccountId;
    this.statusCd = EbSepaExport.STATUS_CD_EXPORTED;
    this.requestedCollectionDate = requestedCollectionDate;
  }

  fldExportSepaActions(): McHtml | null {
    if (!this.isStatusCdPENDING()) {
      if (!this._fldExportSepaActions) {
        const html = new McHtml();
        const actSet = html.addActionSet();
        if (!this.isStatusCdFAILED()) {
          actSet.addActionB(`<i class="fas mc-submit-color fa-file-download"></i> ${this.t('cc.out-factoring.download')}`, EbSepaExport.ACTION_DOWNLOAD, this, [McGod.PERM_EB_FACTORING_OUT_SEPA_OUT_VIEW], '', ActionOptions.OPTION_VIEW);
        }
        actSet.addActionB(`<i class="fas mc-submit-color fa-file-download"></i> ${this.t('cc.common.upload-file')}`, EbSepaExport.ACTION_UPLOAD_FILE, this, [McGod.PERM_EB_BANK_UPLOAD_SEPA_EXPORT_FILE], '', ActionOptions.OPTION_EDIT);
        if (this.isStatusCdEXPORTED()) {
          actSet.addActionB(`<i class="fas mc-submit-color fas fa-history mc-cursor-pointer"></i> ${this.t('cc.factoring.export-sepa.revert')}`, EbSepaExport.ACTION_REVERT, this, [McGod.PERM_EB_FACTORING_OUT_SEPA_OUT_VIEW], `${!this.isStatusCdEXPORTED() ? 'mc-disable-button' : ''}`, ActionOptions.OPTION_EDIT);
        }
        if (this.isTypeCdEBICS() && this.isStatusCdEXPORTED()) {
          actSet.addActionB(`<i class="fas mc-submit-color fa-paper-plane mc-cursor-pointer"></i> ${this.t('cc.ebics.send-via-ebics')}`, EbSepaExport.ACTION_SEND_VIA_EBICS, this, [McGod.PERM_EB_FACTORING_EBICS_SEPA_OUT], '', ActionOptions.OPTION_EDIT);
        }
        this._fldExportSepaActions = html;
      }
      // actSet.addActionB('<i class="fas mc-submit-color fa-share"></i> Mark as <br> "Sent to bank"', EbSepaExport.ACTION_SENT_TO_BANK, this, [McGod.PERM_EB_FACTORING_OUT_SEPA_OUT_VIEW]);
      return this._fldExportSepaActions.items;
    }
    return null;
  }


  // ---------------------------------------------------------------------
  exportDateTimeFormat() {
    // @ts-ignore
   /* return moment(this.exportDateTime).format("lll");
    return McDateUtils.formatDateToString(this.exportDateTime);*/

  }
  servicePeriodFormat() {
    // return moment(this.servicePeriod).format("MMM YYYY");
    return McDateUtils.formatDateToString(this.servicePeriod, 'MMM YYYY');
  }

  override getStatusCdLabel() {
    return EbSepaExportGENERATED.getStatusCdLabel(this.statusCd);
  }

  override  getTypeCdLabel(){
    return EbSepaExportGENERATED.getTypeCdLabel(this.typeCd);
  }

  revertExportedSepa(onSuccessCallback?: () => void, onErrorCallback?: () => void) {
    this.setApiLoading();
    this.apiService.revertSepaById(this.id)
      .subscribe(responseObj => {
      this.processApiSuccess(responseObj, onSuccessCallback);
    }, errorResponse => {
      this.processApiError(errorResponse, onErrorCallback);
    });
  }

  getRequestCollectionDate(): string { // In hurry for delivery, need to generate from BE
    if (this.requestedCollectionDate) {
      return McDateUtils.newFormatDateString(this.requestedCollectionDate);
    }
    return '';
  }

  getCreatedAtString(): string {
    if (this.sysCreatedDatetime) {
      return McDateUtils.newFormatDateTimeString(this.sysCreatedDatetime);
    }
    return '';
  }

  getTotalAmount() {
    return CurrencyUtil.format(this.totalAmountDue, this.mcCurrency.code);
  }
}
