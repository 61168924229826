/*BbmTestObj*/
import {ImDataSetGENERATED} from '../_generated/im-data-set-generated.model';

export class ImDataSet extends ImDataSetGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataSet();
  }

  // ---------------------------------------------------------------------
}
