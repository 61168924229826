<div class="content-wrapper">

  <div class="content-header">
    <div class="stepper-progress-wrap">
      <div class="stepper-progress">
        <div class="step-count">{{'cc.pricelist.step' | translate}} 1/3</div>
        <div class="step-done">{{'cc.pricelist.select-template' | translate}}</div>
        <div class="step-not-done">{{'cc.pricelist.prices-and-entities' | translate}}</div>
        <div class="step-not-done">{{'cc.pricelist.review-and-confirm' | translate}}</div>
      </div>
    </div>
    <h2>{{'cc.pricelist.create-new-pricelist' | translate}}</h2>
  </div>

  <div class="content-body">

    <mcc-message [errorMsg]="errorMsg"></mcc-message>
    <div class="mb-3">
      <mcc-fi-radio-buttons2 *ngIf="loadRadioButtonsFlg"
                             (eventValueChanged)="onTemplateChange($event)"
                             [label]="mcGod.t('cc.pricelist.please-select-a-template-new-pricelist-will-be-based-on')"
                             [name]="'ctrlTemplate'"
                             [directionHorizontal]="false"
                             [mcForm]="mcForm"
                             [value]="pricelistTemplateValue" additionalUpperTextForRadio="From"
                             [validRequiredFlg]="true" additionalBottomTextForRadio="Description"
                             [optionsVll]="templateVll"
                             [additionalTextForRadioVll]="templateRadioButtonsAdditionalTextVll"
                             [removeControlFromMcFormOnDestroy]="false"></mcc-fi-radio-buttons2>
    </div>
    <div class="pricelist-name">
      <mcc-fi-textbox [label]="mcGod.t('cc.pricelist.pricelist-name')" [name]="'ctrlPricelistName'"
                      [value]="pricelistNameValue"
                      [mcForm]="mcForm" [validRequiredFlg]="true"
                      [removeControlFromMcFormOnDestroy]="false"></mcc-fi-textbox>
      <span class="currency">{{currency}}</span>
    </div>



    <mcc-fi-textarea [name]="'ctrlPricelistDescription'" [label]="mcGod.t('cc.pricelist.pricelist-description')"
                     [value]="pricelistDescriptionValue"
                     [numCols]="35" [validRequiredFlg]="true" [mcForm]="mcForm"></mcc-fi-textarea>
  </div>

  <div class="content-footer">
    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel()"
                [label]="mcGod.t('cc.common.edit.cancel')"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actNext()" [label]="getNextLabel()" [disabledMcb]="saveButtonDisabledMcb"></mcc-button>
  </div>

</div>
