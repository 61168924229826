/*BbmTestObj*/
import {McEntity2Table3ConfigGENERATED} from '../_generated/mc-entity2-table-3-config-generated.model';


export class McPricelistTemplateEntitiesUsingTable3ConfigModel extends McEntity2Table3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.clearColumns();
    this.addColumn('colEntityName', 'Entity name', 'entityName', 'name');
    this.addColumn('colEntityId', 'Entity id', 'entityId', 'id');
    this.addColumn('colUsingPricelistFrom', 'Using pricelist from', 'getFromDate()', '');


  }
}
