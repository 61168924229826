import {McProcessFlowLevel} from './mc-process-flow-level.model';
import {McProcessFlowLevelListGENERATED} from '../_generated/mc-process-flow-level-list-generated.model';

export class McProcessFlowLevelList extends McProcessFlowLevelListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessFlowLevelList();
  }

  // ---------------------------------------------------------------------
}
