/*BbmTestObj*/
import { McBillingService2PriceListGENERATED } from "../_generated/mc-billing-service-2-price-list-generated.model";
import { McDateUtils } from "../common/mc-date-utils";
import { McHtmlActionSet } from "../_core/mc-html-action-set";
import { McGod } from "../extra/mc-god.service";
import { ActionOptions } from "../_core/mc-html-action-options";
import {McBillingServiceCatalog} from "../models/mc-billing-service-catalog.model";

export class McBillingService2PriceList extends McBillingService2PriceListGENERATED {
  public static ACTION_EDIT = "EDIT";

  priceOneTimeStr!: string;
  priceFlatStr!: string;
  priceAdditionalStr!: string;
  private _fldActions: McHtmlActionSet | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingService2PriceList();
  }

  // ---------------------------------------------------------------------
  getStartDate(): string {
    if (this.startDate) {
      return McDateUtils.newFormatDateString(this.startDate);
    }
    return "";
  }

  isFranchiseFeePerTransactionOrAdvertisingFee(): boolean {
    return this.serviceTypeCd === McBillingServiceCatalog.TYPE_CD_FRANCHISE_FEE_PER_TRANSACTION_TURNOVER ||
      this.serviceTypeCd === McBillingServiceCatalog.TYPE_CD_FRANCHISE_ADVERTISING_FEE;
  }

  getActivityUnitFld(): string {
    if (this.activityUnitTypeCd) {
      const activityUnit = this.activityUnitTypeCd
        .replace(/_/g, " ")
        .toLowerCase();
      return activityUnit.charAt(0).toUpperCase() + activityUnit.slice(1);
    }
    return "";
  }

  fldProfileActions() {
    if (!this._fldActions) {
      const actionSet = new McHtmlActionSet();
      if (!this.flgPriceFromContract) {
        actionSet.addActionB(
          `<i class="fas mc-submit-color fa-edit mc-cursor-pointer"></i> ${this.t(
            "cc.common.view.edit"
          )}`,
          McBillingService2PriceList.ACTION_EDIT,
          this,
          [
            this.flgItemFromTemplate
              ? McGod.PERM_MC_BILLING_TEMPLATE_PRICELIST_SERVICE_EDIT
              : McGod.PERM_MC_BILLING_PRICELIST_SERVICE_EDIT,
          ],
          "",
          ActionOptions.OPTION_EDIT
        );
      }
      this._fldActions = actionSet;
    }
    return this._fldActions;
  }

  getDeafaultCustomPriceIcon() {
    if (this.flgCustomPrice) {
      return `<div class="icon-tooltip mr-2"><img src="assets/images/pricetag-darkblue.svg"><span class="icon-tooltip-text">${this.t(
        "cc.pricelist.customized"
      )}</span></div>`;
    } else {
      return `<div class="icon-tooltip mr-2"><img src="assets/images/pricetag-mc-blue.svg"><span class="icon-tooltip-text">${this.t(
        "cc.common.default"
      )}</span></div>`;
    }
  }

  getChargeMode(): string {
    if (this.isPricingTypeCdDO_NOT_CHARGE_AT_ALL()) {
      return `${this.getDeafaultCustomPriceIcon()} ${this.t(
        "cc.pricelist.do-not-charge"
      )}`;
    }
    if (this.isPricingTypeCdONE_TIME_ONLY()) {
      return `${this.getDeafaultCustomPriceIcon()} ${this.t(
        "cc.pricelist.one-time-only"
      )} ${this.priceOneTimeStr} ${
        this.flgDoNotChargeForFactoredTransactions
          ? this.t("cc.pricelist.do-not-charge-for-factored-transactions")
          : this.flgDoNotChargeForWebDomains
          ? `${this.t("cc.pricelist.do-not-charge-for-selected-domains")} ${
              this.notChargeWebDomains
            }`
          : ""
      }`;
    }
    if (this.isPricingTypeCdMIX()) {
      return `${this.getDeafaultCustomPriceIcon()} ${this.t(
        "cc.pricelist.flat-price-of"
      )} ${this.priceFlatStr} ${this.t("cc.pricelist.for")} ${
        this.unitLimitForFlatPrice
      } ${this.t("cc.pricelist.units-plus")} ${
        this.priceAdditionalStr
      } ${this.t("cc.pricelist.per-additional-unit")} ${
        this.flgDoNotChargeForFactoredTransactions
          ? this.t("cc.pricelist.do-not-charge-for-factored-transactions")
          : this.flgDoNotChargeForWebDomains
          ? `${this.t("cc.pricelist.do-not-charge-for-selected-domains")} ${
              this.notChargeWebDomains
            }`
          : ""
      }`;
    }
    if (this.flgPriceFromContract) {
      return `${this.getDeafaultCustomPriceIcon()} <div class="icon-tooltip"><img src="assets/images/info-blue-tooltip.svg"><span class="icon-tooltip-text">${this.t(
        "cc.pricelist.this-fee-is-taken-from-the-currently-active-contract"
      )}.</span></div>`;
    }
    return "";
  }
}
