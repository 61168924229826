/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McProcessFlowCriteria} from '../models/mc-process-flow-criteria.model';
import {McProcessFlowCriteriaService} from '../services/mc-process-flow-criteria.service';
import {IMcProcessFlowCriteria} from '../_generated/mc-process-flow-criteria.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McProcessFlowCriteriaGENERATED extends BaseObject {


    public static readonly ATTRIBUTE_OPERATOR_TYPE_CD_IN: string = 'IN';
    public static readonly ATTRIBUTE_OPERATOR_TYPE_CD_PASSED: string = 'PASSED';
    public static readonly ATTRIBUTE_OPERATOR_TYPE_CD_ANNIVERSARY_IN: string = 'ANNIVERSARY_IN';
    public static readonly ATTRIBUTE_OPERATOR_TYPE_CD_ANNIVERSARY_PASSED: string = 'ANNIVERSARY_PASSED';
    public static readonly ATTRIBUTE_OPERATOR_TYPE_CD_EQUALS: string = 'EQUALS';
    public static readonly ATTRIBUTE_OPERATOR_TYPE_CD_GREATER_THAN: string = 'GREATER_THAN';
    public static readonly ATTRIBUTE_OPERATOR_TYPE_CD_LESS_THAN: string = 'LESS_THAN';
    public static readonly ATTRIBUTE_OPERATOR_TYPE_CD_EQUALS_IGNORE_CASE: string = 'EQUALS_IGNORE_CASE';
    public static readonly ATTRIBUTE_OPERATOR_TYPE_CD_CONTAINS: string = 'CONTAINS';
  public static readonly ATTRIBUTE_OPERATOR_TYPE_CD_LIST__ALL = [
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_IN,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_PASSED,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_ANNIVERSARY_IN,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_ANNIVERSARY_PASSED,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_EQUALS,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_GREATER_THAN,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_LESS_THAN,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_EQUALS_IGNORE_CASE,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_CONTAINS
];

    public static readonly ATTRIBUTE_OPTION_TYPE_CD_DAY: string = 'DAY';
    public static readonly ATTRIBUTE_OPTION_TYPE_CD_WORKING_DAY: string = 'WORKING_DAY';
    public static readonly ATTRIBUTE_OPTION_TYPE_CD_WEEK: string = 'WEEK';
    public static readonly ATTRIBUTE_OPTION_TYPE_CD_MONTH: string = 'MONTH';
  public static readonly ATTRIBUTE_OPTION_TYPE_CD_LIST__ALL = [
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPTION_TYPE_CD_DAY,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPTION_TYPE_CD_WORKING_DAY,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPTION_TYPE_CD_WEEK,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPTION_TYPE_CD_MONTH
];

    public static readonly ATTRIBUTE_TYPE_CD_BOOLEAN: string = 'BOOLEAN';
    public static readonly ATTRIBUTE_TYPE_CD_NUMBER: string = 'NUMBER';
    public static readonly ATTRIBUTE_TYPE_CD_MONEY: string = 'MONEY';
    public static readonly ATTRIBUTE_TYPE_CD_STRING: string = 'STRING';
    public static readonly ATTRIBUTE_TYPE_CD_DATETIME: string = 'DATETIME';
    public static readonly ATTRIBUTE_TYPE_CD_DATE: string = 'DATE';
  public static readonly ATTRIBUTE_TYPE_CD_LIST__ALL = [
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_BOOLEAN,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_NUMBER,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_MONEY,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_STRING,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_DATETIME,
    McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_DATE
];

    public static readonly CRITERIA_OPERATOR_TYPE_CD_AND: string = 'AND';
    public static readonly CRITERIA_OPERATOR_TYPE_CD_OR: string = 'OR';
  public static readonly CRITERIA_OPERATOR_TYPE_CD_LIST__ALL = [
    McProcessFlowCriteriaGENERATED.CRITERIA_OPERATOR_TYPE_CD_AND,
    McProcessFlowCriteriaGENERATED.CRITERIA_OPERATOR_TYPE_CD_OR
];

  public apiService: McProcessFlowCriteriaService;
  public _rawJson: IMcProcessFlowCriteria;
    id: number = 0;
  attributeName = '';
  attributeOperatorTypeCd: string;
  attributeOptionTypeCd: string;
  attributeTypeCd: string;
  criteriaOperatorTypeCd: string;
  idMcProcessFlow: number;
  seqNo: number;
  value = '';


  public properties: string[] = ['id', 'attributeName', 'attributeOperatorTypeCd', 'attributeOptionTypeCd', 'attributeTypeCd', 'criteriaOperatorTypeCd', 'idMcProcessFlow', 'seqNo', 'value'];
  public propertiesRegular: string[] = ['id', 'attributeName', 'attributeOperatorTypeCd', 'attributeOptionTypeCd', 'attributeTypeCd', 'criteriaOperatorTypeCd', 'idMcProcessFlow', 'seqNo', 'value'];
  public propertiesSpecial: string[] = [];



  public static getAttributeOperatorTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_IN, McGod.t('In'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_PASSED, McGod.t('Passed'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_ANNIVERSARY_IN, McGod.t('Anniversary in'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_ANNIVERSARY_PASSED, McGod.t('Anniversary passed'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_EQUALS, McGod.t('Equals'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_GREATER_THAN, McGod.t('Greater than'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_LESS_THAN, McGod.t('Less than'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_EQUALS_IGNORE_CASE, McGod.t('Equals ignore case'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_CONTAINS, McGod.t('Contains'));


    return list;
  }

  public static getAttributeOperatorTypeCdLabel(aCd: string): string {
    const list = McProcessFlowCriteriaGENERATED.getAttributeOperatorTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getAttributeOptionTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPTION_TYPE_CD_DAY, McGod.t('Day(s)'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPTION_TYPE_CD_WORKING_DAY, McGod.t('Working day(s)'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPTION_TYPE_CD_WEEK, McGod.t('Week(s)'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPTION_TYPE_CD_MONTH, McGod.t('Month(s)'));


    return list;
  }

  public static getAttributeOptionTypeCdLabel(aCd: string): string {
    const list = McProcessFlowCriteriaGENERATED.getAttributeOptionTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getAttributeTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_BOOLEAN, McGod.t('Boolean'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_NUMBER, McGod.t('Number'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_MONEY, McGod.t('Money'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_STRING, McGod.t('String'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_DATETIME, McGod.t('Datetime'));
    list.add(McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_DATE, McGod.t('Date'));


    return list;
  }

  public static getAttributeTypeCdLabel(aCd: string): string {
    const list = McProcessFlowCriteriaGENERATED.getAttributeTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getCriteriaOperatorTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowCriteriaGENERATED.CRITERIA_OPERATOR_TYPE_CD_AND, McGod.t('And'));
    list.add(McProcessFlowCriteriaGENERATED.CRITERIA_OPERATOR_TYPE_CD_OR, McGod.t('Or'));


    return list;
  }

  public static getCriteriaOperatorTypeCdLabel(aCd: string): string {
    const list = McProcessFlowCriteriaGENERATED.getCriteriaOperatorTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McProcessFlowCriteria {
    console.error('MTCN-ERROR: Not Implemented: McProcessFlowCriteria::createNewInstance(). Add this method to final class and return new McProcessFlowCriteria();');
    throw new Error('Cannot McProcessFlowCriteria::createNewInstance(). Add this method to final class and return new McProcessFlowCriteria();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcProcessFlowCriteria): McProcessFlowCriteria {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessFlowCriteriaService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McProcessFlowCriteria AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getAttributeOperatorTypeCdLabel(): string {
    return McProcessFlowCriteriaGENERATED.getAttributeOperatorTypeCdLabel(this.attributeOperatorTypeCd);
  }


 public  isAttributeOperatorTypeCdIN(): boolean {
    const result = (this.attributeOperatorTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_IN);

    return result;
  }

 public  isAttributeOperatorTypeCdPASSED(): boolean {
    const result = (this.attributeOperatorTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_PASSED);

    return result;
  }

 public  isAttributeOperatorTypeCdANNIVERSARY_IN(): boolean {
    const result = (this.attributeOperatorTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_ANNIVERSARY_IN);

    return result;
  }

 public  isAttributeOperatorTypeCdANNIVERSARY_PASSED(): boolean {
    const result = (this.attributeOperatorTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_ANNIVERSARY_PASSED);

    return result;
  }

 public  isAttributeOperatorTypeCdEQUALS(): boolean {
    const result = (this.attributeOperatorTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_EQUALS);

    return result;
  }

 public  isAttributeOperatorTypeCdGREATER_THAN(): boolean {
    const result = (this.attributeOperatorTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_GREATER_THAN);

    return result;
  }

 public  isAttributeOperatorTypeCdLESS_THAN(): boolean {
    const result = (this.attributeOperatorTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_LESS_THAN);

    return result;
  }

 public  isAttributeOperatorTypeCdEQUALS_IGNORE_CASE(): boolean {
    const result = (this.attributeOperatorTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_EQUALS_IGNORE_CASE);

    return result;
  }

 public  isAttributeOperatorTypeCdCONTAINS(): boolean {
    const result = (this.attributeOperatorTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPERATOR_TYPE_CD_CONTAINS);

    return result;
  }


  public getAttributeOptionTypeCdLabel(): string {
    return McProcessFlowCriteriaGENERATED.getAttributeOptionTypeCdLabel(this.attributeOptionTypeCd);
  }


 public  isAttributeOptionTypeCdDAY(): boolean {
    const result = (this.attributeOptionTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPTION_TYPE_CD_DAY);

    return result;
  }

 public  isAttributeOptionTypeCdWORKING_DAY(): boolean {
    const result = (this.attributeOptionTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPTION_TYPE_CD_WORKING_DAY);

    return result;
  }

 public  isAttributeOptionTypeCdWEEK(): boolean {
    const result = (this.attributeOptionTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPTION_TYPE_CD_WEEK);

    return result;
  }

 public  isAttributeOptionTypeCdMONTH(): boolean {
    const result = (this.attributeOptionTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_OPTION_TYPE_CD_MONTH);

    return result;
  }


  public getAttributeTypeCdLabel(): string {
    return McProcessFlowCriteriaGENERATED.getAttributeTypeCdLabel(this.attributeTypeCd);
  }


 public  isAttributeTypeCdBOOLEAN(): boolean {
    const result = (this.attributeTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_BOOLEAN);

    return result;
  }

 public  isAttributeTypeCdNUMBER(): boolean {
    const result = (this.attributeTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_NUMBER);

    return result;
  }

 public  isAttributeTypeCdMONEY(): boolean {
    const result = (this.attributeTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_MONEY);

    return result;
  }

 public  isAttributeTypeCdSTRING(): boolean {
    const result = (this.attributeTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_STRING);

    return result;
  }

 public  isAttributeTypeCdDATETIME(): boolean {
    const result = (this.attributeTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_DATETIME);

    return result;
  }

 public  isAttributeTypeCdDATE(): boolean {
    const result = (this.attributeTypeCd === McProcessFlowCriteriaGENERATED.ATTRIBUTE_TYPE_CD_DATE);

    return result;
  }


  public getCriteriaOperatorTypeCdLabel(): string {
    return McProcessFlowCriteriaGENERATED.getCriteriaOperatorTypeCdLabel(this.criteriaOperatorTypeCd);
  }


 public  isCriteriaOperatorTypeCdAND(): boolean {
    const result = (this.criteriaOperatorTypeCd === McProcessFlowCriteriaGENERATED.CRITERIA_OPERATOR_TYPE_CD_AND);

    return result;
  }

 public  isCriteriaOperatorTypeCdOR(): boolean {
    const result = (this.criteriaOperatorTypeCd === McProcessFlowCriteriaGENERATED.CRITERIA_OPERATOR_TYPE_CD_OR);

    return result;
  }


  
}
