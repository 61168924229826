<section>
  <div class="row mc-header-wrapper">
    <div class="col-md-12 mc-stepper-header-title" *ngIf="modalArea === 'addConsumers'">
      <span class="pl-4 pb-1 mr-2 mc-active">
        <span class="mc-notification-badge-gray mc-active-background">1</span>
        {{'cc.common.view.basic-info' | translate}}
      </span>
      <span class="pl-4 pb-1 ml-4 mr-2">
        <span class="mc-notification-badge-gray">2</span>
        {{'cc.consumers.view.extended-info' | translate}}
      </span>
    </div>
  </div>

  <form class="form-row pt-5 mc-add-consumers-form" [formGroup]="stepForm">

    <div class="mc-create-user-notify-wrapper">
      <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
    </div>

    <div class="row w-100 pt-2">
      <div class="col-md-6">
        <div class="form-group mb-0 row align-items-center">
          <label class="col-md-3 mb-0 mc-consumers-label">{{'cc.common.view.status' | translate}}</label>
          <div class="col-md-9">
            <label>
              <input type="radio" id="statusActive" value="active" formControlName="active">
              <label class="form-check-label" for="statusActive">{{ 'cc.common.view.active' | translate }}</label>
            </label>

            <label class="ml-4">
              <input type="radio" id="statusInactive" value="inactive" formControlName="active">
              <label class="form-check-label" for="statusInactive">{{'cc.common.view.inactive' | translate}}</label>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row w-100">
      <div class="col-md-6">
        <div class="form-group mb-2 row align-items-center">
          <label class="col-md-3 mb-0 mc-consumers-label">{{'cc.jobs.edit.type' | translate}}</label>
          <div class="col-md-9">
            <label>
              <input
                class="form-check-input"
                type="radio"
                id="individualPerson"
                value="person"
                formControlName="individual"
                #personType
                (ngModelChange)="onChangeType(personType.value)">
              <label class="form-check-label" for="individualPerson">{{'cc.consumers.edit.person' | translate}}</label>
            </label>

            <label class="ml-4">
              <input
                class="form-check-input"
                type="radio"
                id="individualCompany"
                value="company"
                formControlName="individual"
                #companyType
                (ngModelChange)="onChangeType(companyType.value)">
              <label class="form-check-label" for="individualCompany">{{'cc.consumers.edit.company' | translate}}</label>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row w-100">
      <div class="col-md-6">

        <div class="form-group row align-items-center">
          <label for="consumerExternalId" class="col-md-3 mc-consumers-label mb-0">{{'cc.common.edit.external-id' | translate}}</label>
          <div class="col-md-9">
            <input type="text"
                   id="consumerExternalId"
                   class="form-control"
                   formControlName="consumerExternalId"
                   [ngClass]="externalIdInvalid ? 'mc-error-field' : '' "
                   (keyup)="isNumberFormat($event)">
          </div>
        </div>

        <div class="form-group row align-items-center pb-1" [ngClass]="stepForm.value.individual !== 'company' ? 'disable-label' : null">
          <label for="companyName" class="col-md-3 mb-0 mc-consumers-label text-capitalize" [ngClass]="stepForm.value.individual === 'company' ? 'mc-mandatory-star' : ''">{{'cc.common.company-name' | translate}}</label>
          <div class="col-md-9">
            <input type="text"
                   id="companyName"
                   class="form-control"
                   [ngClass]="{'disable-input' : stepForm.value.individual !== 'company', 'mc-error-field' : companyNameNotValid}"
                   formControlName="companyName"
                   (input)="checkMandatoryInputFields('companyName')">
          </div>
        </div>

        <div class="contact-person-wrapper">
          <span *ngIf="stepForm.value.individual === 'company'" class="contact-person">{{'cc.common.contact-person' | translate}}</span>
          <span *ngIf="stepForm.value.individual === 'person'" class="contact-person">{{'cc.common.basic-information' | translate}}</span>
          <div class="form-group row align-items-center">
            <label for="consumerFirstName" class="col-md-3 mb-0 mc-consumers-label text-capitalize" [ngClass]="stepForm.value.individual === 'person' ? 'mc-mandatory-star' : ''">{{'cc.common.edit.first-name' | translate}}</label>
            <div class="col-md-9">
              <input type="text"
                     id="consumerFirstName"
                     class="form-control"
                     formControlName="consumerFirstName"
                     [ngClass]="firstnameNotValid ? 'mc-error-field' : '' "
                     (input)="checkMandatoryInputFields('consumerFirstName')">
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label for="consumerLastName" class="col-md-3 mb-0 mc-consumers-label text-capitalize" [ngClass]="stepForm.value.individual === 'person' ? 'mc-mandatory-star' : ''">{{'cc.common.edit.last-name' | translate}}</label>
            <div class="col-md-9">
              <input type="text"
                     id="consumerLastName"
                     class="form-control"
                     formControlName="consumerLastName"
                     [ngClass]="lastnameNotValid ? 'mc-error-field' : '' "
                     (input)="checkMandatoryInputFields('consumerLastName')">
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label for="consumerEmail" class="col-md-3 mb-0 mc-consumers-label">{{'cc.common.edit.email' | translate}}</label>
            <div class="col-md-9">
              <input type="email"
                     id="consumerEmail"
                     class="form-control"
                     formControlName="consumerEmail"
                     [ngClass]="emailInvalid ? 'mc-error-field' : '' "
                     (keyup) = "checkEmailFormat($event)">
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label for="consumerTitle" class="col-md-3 mc-consumers-label mb-0">{{'cc.common.edit.title' | translate}}</label>
            <div class="col-md-9">
              <input type="text" id="consumerTitle" class="form-control" formControlName="consumerTitle">
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label for="consumerBDay" class="col-md-3 mb-0 mc-consumers-label">{{'cc.common.edit.birthday' | translate}}</label>
            <div class="col-md-9">
              <input id="consumerBDay"
                     class="form-control mc-dates-inputs"
                     type="date"
                     name="consumerBDay"
                     autocomplete="off"
                     formControlName="consumerBDay"
                     placeholder="mm-dd-yyyy"
                     readonly
                     ngbDatepicker
                     outsideDays="hidden"
                     [minDate] = "minDate"
                     [maxDate] = "maxDate"
                     #dateField="ngbDatepicker"
                     (click)="dateField.toggle()">
            </div>
          </div>

          <div class="form-group py-2 row align-items-center">
            <label class="mc-consumers-label mb-0 col-md-2">{{'cc.common.edit.gender' | translate}}</label>
            <div class="col-md-10 pr-0 text-right">
              <label>
                <input
                  class="form-check-input"
                  type="radio"
                  name="gender"
                  id="genderMail"
                  value="MALE"
                  formControlName="gender">
                <label class="form-check-label" for="genderMail">{{'cc.common.edit.male' | translate}}</label>
              </label>

              <label class="ml-4">
                <input
                  class="form-check-input"
                  type="radio"
                  name="gender"
                  id="genderFemale"
                  value="FEMALE"
                  formControlName="gender">
                <label class="form-check-label" for="genderFemale">{{'cc.common.edit.female' | translate}}</label>
              </label>

              <label class="ml-4">
                <input
                  class="form-check-input"
                  type="radio"
                  name="gender"
                  id="genderOther"
                  value="OTHER"
                  formControlName="gender">
                <label class="form-check-label" for="genderOther">{{'cc.common.edit.prefer-not-to-say' | translate}}</label>
              </label>
            </div>
          </div>
        </div>

        <div class="contact-person-wrapper mt-4">
          <span class="contact-person">{{'cc.common.contact-information' | translate}}</span>
          <div class="form-group row align-items-center">
            <label for="consumerTelephone" class="col-md-3 mb-0 mc-consumers-label">{{ 'cc.common.edit.telephone' | translate }}</label>
            <div class="col-md-9">
              <input type="text"
                     id="consumerTelephone"
                     class="form-control"
                     formControlName="consumerTelephone"
                     [ngClass]="phoneNumberInvalid ? 'mc-error-field' : '' "
                     (keyup)="isPhoneNumberFormat($event)">
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label for="consumerCellphone" class="col-md-3 mb-0 mc-consumers-label">{{'cc.consumers.edit.cellphone' | translate}}</label>
            <div class="col-md-9">
              <input type="text"
                     id="consumerCellphone"
                     class="form-control"
                     formControlName="consumerCellphone"
                     [ngClass]="cellNumberInvalid ? 'mc-error-field' : '' "
                     (keyup)="isPhoneNumberFormat($event)">
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label for="consumerFax" class="col-md-3 mb-0 mc-consumers-label">{{ 'cc.common.edit.fax' | translate }}</label>
            <div class="col-md-9">
              <input type="text"
                     id="consumerFax"
                     class="form-control"
                     formControlName="consumerFax"
                     [ngClass]="faxNumberInvalid ? 'mc-error-field' : '' "
                     (keyup)="isPhoneNumberFormat($event)">
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="contact-person-wrapper">
          <span class="contact-person">{{'cc.common.address-information' | translate}}</span>
          <div class="form-group row align-items-center">
            <label for="consumerStreet" class="col-md-3 mc-consumers-label mb-0">{{'cc.consumers.edit.street' | translate}}</label>
            <div class="col-md-9">
              <input type="text" id="consumerStreet" class="form-control" formControlName="consumerStreet">
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label for="consumerAddressSupplement" class="col-md-3 mc-consumers-label mb-0">{{'cc.consumers.edit.address-supplement' | translate}}</label>
            <div class="col-md-9">
              <input type="text" id="consumerAddressSupplement" class="form-control" formControlName="consumerAddressSupplement">
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label for="consumerPostalCode" class="col-md-3 mc-consumers-label mb-0">{{'cc.consumers.edit.postal-code' | translate}}</label>
            <div class="col-md-9">
              <input type="text"
                     id="consumerPostalCode"
                     class="form-control"
                     formControlName="consumerPostalCode"
                     [ngClass]="postalCodeInvalid ? 'mc-error-field' : '' "
                     (keyup)="isNumberFormat($event)">
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label for="consumerCity" class="col-md-3 mb-0 mc-consumers-label">{{ 'cc.common.edit.city' | translate }}</label>
            <div class="col-md-9">
              <input type="text" id="consumerCity" class="form-control" formControlName="consumerCity">
            </div>
          </div>
        </div>

        <div class="contact-person-wrapper">
          <span class="contact-person">{{'cc.common.language-information' | translate}}</span>
          <div class="form-group row align-items-center">
            <label class="col-md-3 mb-0 mc-consumers-label" for="consumerISOCountry">ISO {{'cc.users.edit.country' | translate}}</label>
            <div class="col-md-9">
              <select *ngIf="countries" class="form-control" id="consumerISOCountry" formControlName="consumerISOCountry">
                <option *ngFor="let country of countries" [value]="country.value">{{country.name}}</option>
              </select>
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label class="col-md-3 mb-0 mc-consumers-label" for="consumerISOLanguage">ISO {{'cc.users.edit.language' | translate}}</label>
            <div class="col-md-9">
              <select *ngIf="languages" class="form-control" id="consumerISOLanguage" formControlName="consumerISOLanguage">
                <option *ngFor="let language of languages" [value]="language.value">{{language.name}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="contact-person-wrapper">
          <span class="contact-person">{{'cc.common.bank-information' | translate}}</span>
          <div class="form-group row align-items-center">
            <label for="consumerAccountOwner" class="col-md-3 mc-consumers-label mb-0">{{'cc.consumers.edit.account-owner' | translate}}</label>
            <div class="col-md-9">
              <input type="text" id="consumerAccountOwner" class="form-control" formControlName="consumerAccountOwner">
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label for="consumerIBAN" class="col-md-3 mc-consumers-label mb-0 text-uppercase">{{'cc.consumers.edit.iban' | translate}}</label>
            <div class="col-md-9">
              <input type="email"
                     id="consumerIBAN"
                     class="form-control"
                     formControlName="consumerIBAN"
                     [ngClass]="ibanNumberInvalid ? 'mc-error-field' : '' "
                     (focusout)="isIBANValid()">
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label for="consumerBIC" class="col-md-3 mc-consumers-label mb-0 text-uppercase">{{'cc.consumers.edit.bic' | translate}}</label>
            <div class="col-md-9">
              <input type="email" id="consumerBIC" class="form-control" formControlName="consumerBIC">
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label for="vat" class="col-md-3 mc-consumers-label mb-0 text-uppercase">{{'cc.product.view.vat' | translate}}</label>
            <div class="col-md-9">
              <input type="text" id="vat" class="form-control" formControlName="vat">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 mt-4 mb-3 mc-save-consumers-btn" *ngIf="modalArea !== 'addConsumers'">
      <button id="userGeneralDataBtn" class="btn btn-primary mc-next-save-btn" type="button" [disabled]="userDataFormValid" (click)="saveEditedConsumerData()">
        {{'cc.common.view.save' | translate}}
      </button>
    </div>

  </form>
</section>

