
import {Table3Config} from '../extra/table-3-config';

// import {CsrContract2ProductGENERATED} from '../_generated/csr-contract-2-product-generated.model';

export class CsrContract2ProductTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colCsrContract', 'Consumer contract', 'csrContract', 'csrContract');
    this.addColumn('colIdCsrContract', 'Consumer contract id', 'idCsrContract', 'idCsrContract');
    this.addColumn('colIdPpProductSnapshot', 'Product snapshot id', 'idPpProductSnapshot', 'idPpProductSnapshot');
    this.addColumn('colPpProductSnapshot', 'Product Snapshot', 'ppProductSnapshot', 'ppProductSnapshot');

*/
  }
}
