/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {ImDataSetAttribute} from '../models/im-data-set-attribute.model';
import {ImDataSetAttributeList} from '../models/im-data-set-attribute-list.model';
import {ImDataSetAttributeFilter} from '../models/im-data-set-attribute-filter.model';
import {ImDataSetAttributeService} from '../services/im-data-set-attribute.service';
import {IImDataSetAttribute} from '../_generated/im-data-set-attribute.interface';
import {McGod} from "../extra/mc-god.service";

export class ImDataSetAttributeListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'customFlg', 'description', 'enabledFlg', 'entityId', 'idDataSet', 'inheritableFlg', 'name', 'requiredFlg', 'sampleData', 'sysCreatedDatetime', 'typeCd'];
  public items: ImDataSetAttribute[] = [];
  public apiService: ImDataSetAttributeService;
 // protected tempObjList: ImDataSetAttributeList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): ImDataSetAttributeList {
    console.error('MTCN-ERROR: Not Implemented: ImDataSetAttributeList::createNewInstance(). Add this method to final class and return new ImDataSetAttributeList();');
    throw new Error('Cannot ImDataSetAttributeList::createNewInstance(). Add this method to final class and return new ImDataSetAttributeList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IImDataSetAttribute[]): ImDataSetAttributeList {
    const  listObj: ImDataSetAttributeList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: ImDataSetAttribute = ImDataSetAttribute.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): ImDataSetAttributeList {
    const  listObj: ImDataSetAttributeList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(ImDataSetAttributeService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IImDataSetAttribute[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: ImDataSetAttribute = ImDataSetAttribute.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aImDataSetAttributeFilter: ImDataSetAttributeFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aImDataSetAttributeFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): ImDataSetAttribute {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new ImDataSetAttribute();
    }
  }

  public replaceItem(newItem: ImDataSetAttribute) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

