export const ICON_ENTITY = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_123_1580)">
<path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 6C13.93 6 15.5 7.57 15.5 9.5C15.5 11.43 13.93 13 12 13C10.07 13 8.5 11.43 8.5 9.5C8.5 7.57 10.07 6 12 6ZM19 19H5V18.77C5 18.15 5.28 17.57 5.76 17.19C7.47 15.82 9.64 15 12 15C14.36 15 16.53 15.82 18.24 17.19C18.72 17.57 19 18.16 19 18.77V19Z" fill="#3B457C"/>
</g>
<defs>
<clipPath id="clip0_123_1580">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
`
