/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EbTransactionAdjustmentFilter} from '../models/eb-transaction-adjustment-filter.model';

export class EbTransactionAdjustmentFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbTransactionAdjustmentFilter {
    console.error('MTCN-ERROR: Not Implemented: EbTransactionAdjustmentFilter::createNewInstance(). Add this method to final class and return new EbTransactionAdjustmentFilter();');
      throw new Error('Cannot EbTransactionAdjustmentFilter::createNewInstance(). Add this method to final class and return new EbTransactionAdjustmentFilter();');
  }

}
