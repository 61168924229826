/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EbAccountConsumer} from '../models/eb-account-consumer.model';
import {EbAccountConsumerList} from '../models/eb-account-consumer-list.model';
import {EbAccountConsumerFilter} from '../models/eb-account-consumer-filter.model';
import {EbAccountConsumerService} from '../services/eb-account-consumer.service';
import {IEbAccountConsumer} from '../_generated/eb-account-consumer.interface';
import {McGod} from "../extra/mc-god.service";

export class EbAccountConsumerListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'amount', 'idCsrConsumer', 'jsonbTransactionDetails', 'paymentMethod', 'sysCreatedDatetime', 'type'];
  public items: EbAccountConsumer[] = [];
  public apiService: EbAccountConsumerService;
 // protected tempObjList: EbAccountConsumerList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbAccountConsumerList {
    console.error('MTCN-ERROR: Not Implemented: EbAccountConsumerList::createNewInstance(). Add this method to final class and return new EbAccountConsumerList();');
    throw new Error('Cannot EbAccountConsumerList::createNewInstance(). Add this method to final class and return new EbAccountConsumerList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEbAccountConsumer[]): EbAccountConsumerList {
    const  listObj: EbAccountConsumerList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EbAccountConsumer = EbAccountConsumer.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EbAccountConsumerList {
    const  listObj: EbAccountConsumerList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbAccountConsumerService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEbAccountConsumer[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EbAccountConsumer = EbAccountConsumer.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEbAccountConsumerFilter: EbAccountConsumerFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEbAccountConsumerFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EbAccountConsumer {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EbAccountConsumer();
    }
  }

  public replaceItem(newItem: EbAccountConsumer) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

