/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McProduct} from '../models/mc-product.model';
import {McProductList} from '../models/mc-product-list.model';
import {McProductFilter} from '../models/mc-product-filter.model';
import {McProductService} from '../services/mc-product.service';
import {IMcProduct} from '../_generated/mc-product.interface';
import {McGod} from "../extra/mc-god.service";

export class McProductListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'availableFrom', 'availableTo', 'chargingTypeCd', 'description', 'externalId', 'flgArchived', 'idCurrency', 'idMcEntity', 'idPpCategory', 'idVat', 'name', 'pricingTypeCd', 'refId', 'refTypeCd', 'serviceTypeCd', 'state', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'unitPrice', 'unitsInStock', 'version', 'category', 'currency', 'mcCurrency', 'mcVat', 'ppCategory', 'quantity', 'unitPriceLoc', 'vat', 'vatRate'];
  public items: McProduct[] = [];
  public apiService: McProductService;
 // protected tempObjList: McProductList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProductList {
    console.error('MTCN-ERROR: Not Implemented: McProductList::createNewInstance(). Add this method to final class and return new McProductList();');
    throw new Error('Cannot McProductList::createNewInstance(). Add this method to final class and return new McProductList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcProduct[]): McProductList {
    const  listObj: McProductList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McProduct = McProduct.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McProductList {
    const  listObj: McProductList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProductService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcProduct[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McProduct = McProduct.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcProductFilter: McProductFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcProductFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McProduct {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McProduct();
    }
  }

  public replaceItem(newItem: McProduct) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --

}

