import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WebFile, McUtilityService, McTrigger, McGod, EbSepaStatementReport} from '@miticon-ui/mc-core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';

@Component({
  selector: 'mc-bank-module-import-star-money-part',
  templateUrl: './mc-bank-module-import-star-money-part.component.html',
})
export class McBankModuleImportStarMoneyPartComponent implements OnInit {

  webFile!: WebFile;
  rawFile!: File
  messageType = '';
  message!: string;
  isEnabled!: boolean;
  @Output() refreshTable = new EventEmitter();
  entityId: number;
  userId: number;
  ebStatementReport = new EbSepaStatementReport();
  loaderFlg = false;

  @Input() saveTrigger!: McTrigger;
  @Input() cancelTrigger!: McTrigger;
  @Input() deleteTrigger!: McTrigger;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();

  constructor(private modalService: NgbModal,
              private utilityService: McUtilityService) {
    this.entityId = this.utilityService.getPropertyFromToken('entity_id');
    this.userId = this.utilityService.getPropertyFromToken('user_id');
  }

  ngOnInit() {
    this.webFile = new WebFile();
    this.isEnabled = false;
  }

  actUpload() {
    this.messageType = 'error';
    const relativePath = 'import-starmoney';
    if (this.rawFile != null) {
      this.loaderFlg = true;
      const dataBody = {
        file: this.rawFile,
        aRelativePath: `E${McGod.getLoggedEntityIdFromToken()}/${relativePath}`
      };
      this.webFile.upload(dataBody, WebFile.TYPE_CD_EB_BANK_SEPA_STATEMENT_REPORT,() => {
        this.ebStatementReport.idWebFile = this.webFile.id;
        this.ebStatementReport.idMcEntity = this.entityId;
        this.ebStatementReport.sysCreatedUserId = this.userId;
        this.ebStatementReport.callImportFile(this.webFile.id, () => {
          this.eventSaved.emit();
        }, () => {
          this.message = this.ebStatementReport.apiErrorMessage || 'An error has occured while importing Starmoney file.';
          this.messageType = 'error';
          this.isEnabled = false;
          this.loaderFlg = false;
        });
      }, () => {
        this.message = this.webFile.apiErrorMessage || 'Something went wrong';
        this.messageType = 'error';
        this.isEnabled = false;
        this.loaderFlg = false;
      });
    } else {
      this.message = 'Please import XML file for upload.';
      this.messageType = 'error';
      this.isEnabled = false;
    }
  }

  actCancel() {
    this.eventCanceled.emit();
  }


  onFileSelected(rawFile: File) {
    this.rawFile = rawFile;
    if (this.rawFile != null) {
      this.isEnabled = true;
    }
  }

}


