import {CsrContract2ProductFilterGENERATED} from '../_generated/csr-contract-2-product-filter-generated.model';

export class CsrContract2ProductFilter extends CsrContract2ProductFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrContract2ProductFilter();
  }

  // ---------------------------------------------------------------------
}
