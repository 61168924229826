import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {McFilterComponent} from './mc-filter.component';
import {McCheckboxComponent} from '../mc-checkbox/mc-checkbox.component';
import {McDropdownComponent} from '../mc-dropdown/mc-dropdown.component';
import {McInputFieldComponent} from '../mc-input-field/mc-input-field.component';
import {TranslateModule} from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { SafeHtmlModule } from '../safe-html/safe-html.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserModule } from '@angular/platform-browser';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    McFilterComponent,
    McCheckboxComponent,
    McDropdownComponent,
    McInputFieldComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    SafeHtmlModule,
    MatExpansionModule,
    MatInputModule
  ],
  exports: [
    McFilterComponent,
    McInputFieldComponent
  ]
})
export class McFilterModule { }
