/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McPriceList} from '../models/mc-price-list.model';
import {McPriceListList} from '../models/mc-price-list-list.model';
import {McPriceListFilter} from '../models/mc-price-list-filter.model';
import {McPriceListService} from '../services/mc-price-list.service';
import {IMcPriceList} from '../_generated/mc-price-list.interface';
import {McGod} from "../extra/mc-god.service";

export class McPriceListListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'description', 'idEntityOwner', 'idMcCurrency', 'idParentTemplate', 'sysCreatedDatetime', 'sysCreatedUserId', 'title', 'validFrom', 'validTo', 'createdByUserFullName', 'currency', 'entitiesUsingPricelist', 'mcBillingService2PriceListItemList', 'nameOfEntityOwner', 'nameOfOriginTemplate', 'numberOfCreatedPriceLists', 'numberOfCustomPrices', 'numberOfDefaultPrices', 'numberOfEntitiesUsingPricelist', 'numberOfEntitiesUsingTemplate'];
  public items: McPriceList[] = [];
  public apiService: McPriceListService;
 // protected tempObjList: McPriceListList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McPriceListList {
    console.error('MTCN-ERROR: Not Implemented: McPriceListList::createNewInstance(). Add this method to final class and return new McPriceListList();');
    throw new Error('Cannot McPriceListList::createNewInstance(). Add this method to final class and return new McPriceListList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcPriceList[]): McPriceListList {
    const  listObj: McPriceListList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McPriceList = McPriceList.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McPriceListList {
    const  listObj: McPriceListList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McPriceListService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcPriceList[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McPriceList = McPriceList.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcPriceListFilter: McPriceListFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcPriceListFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McPriceList {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McPriceList();
    }
  }

  public replaceItem(newItem: McPriceList) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadAllPossiblePriceListsForNewEntity( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getAllPossiblePriceListsForNewEntity( this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

  // ---------------------------------------------------------------------
  public loadAllPriceListInstantiatedFromTemplate( idTemplate: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getAllPriceListInstantiatedFromTemplate(idTemplate, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

  // ---------------------------------------------------------------------
  public loadAllPossibleTemplatesForNewPriceList( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getAllPossibleTemplatesForNewPriceList( this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

