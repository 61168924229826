import {McMappingItemFilterGENERATED} from '../_generated/mc-mapping-item-filter-generated.model';

export class McMappingItemFilter extends McMappingItemFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McMappingItemFilter();
  }

  // ---------------------------------------------------------------------
}
