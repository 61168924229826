import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from '@miticon-ui/mc-core';
import {McAccountInfoComponent} from './pages/mc-account-info/mc-account-info.component';
import {McPersonalInfoComponent} from './pages/mc-personal-info/mc-personal-info.component';

const routes: Routes = [
  {
    path: 'account-info',
    component: McAccountInfoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'personal-info',
    component: McPersonalInfoComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McProfileUserRoutingModule {
}
