/*BbmTestObj*/
import {McBillingServiceCatalogGENERATED} from '../_generated/mc-billing-service-catalog-generated.model';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McGod} from '../extra/mc-god.service';

export class McBillingServiceCatalog extends McBillingServiceCatalogGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingServiceCatalog();
  }

  public static getTypeCdsVll() {
    const list = new McValueLabelList();
    list.add('13', McGod.t('Initial entity setup'));
    list.add('1', McGod.t('Contract type change'));
    list.add('2', McGod.t('User account fee'));
    list.add('3', McGod.t('Batch transaction creation fee'));
    list.add('4', McGod.t('IBAN validity check'));
    list.add('5', McGod.t('Blacklist matching'));
    list.add('6', McGod.t('Transaction withdrawal attempt'));
    list.add('7', McGod.t('Manual payment matching'));
    list.add('8', McGod.t('Automatic payment matching'));
    list.add('9', McGod.t('Transaction return to client fee'));
    list.add('10', McGod.t('PDF creation'));
    list.add('11', McGod.t('Sent email'));
    list.add('12', McGod.t('Resent email'));
    list.add('14', McGod.t('cc.billing.payout-item-return'));
    return list;
  }

  // ---------------------------------------------------------------------
}
