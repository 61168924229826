import {Inject, Injectable} from '@angular/core';
import {McProcessRunServiceGENERATED} from '../_generated/mc-process-run-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McProcessRunService extends McProcessRunServiceGENERATED {

}
