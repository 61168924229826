/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CcMollieCustomerFilter} from '../models/cc-mollie-customer-filter.model';

export class CcMollieCustomerFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CcMollieCustomerFilter {
    console.error('MTCN-ERROR: Not Implemented: CcMollieCustomerFilter::createNewInstance(). Add this method to final class and return new CcMollieCustomerFilter();');
      throw new Error('Cannot CcMollieCustomerFilter::createNewInstance(). Add this method to final class and return new CcMollieCustomerFilter();');
  }

}
