import {McDashboard} from './mc-dashboard.model';
import {McDashboardListGENERATED} from '../_generated/mc-dashboard-list-generated.model';

export class McDashboardList extends McDashboardListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McDashboardList();
  }

  // ---------------------------------------------------------------------
}
