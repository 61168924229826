/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McPdfTemplate} from '../models/mc-pdf-template.model';
import {McPdfTemplateService} from '../services/mc-pdf-template.service';
import {IMcPdfTemplate} from '../_generated/mc-pdf-template.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McPdfTemplateGENERATED extends BaseObject {


    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_INACTIVE: string = 'INACTIVE';
  public static readonly STATUS_CD_LIST__ALL = [
    McPdfTemplateGENERATED.STATUS_CD_ACTIVE,
    McPdfTemplateGENERATED.STATUS_CD_INACTIVE
];

    public static readonly TYPE_CD_DUNNING: string = 'DUNNING';
    public static readonly TYPE_CD_MARKETING: string = 'MARKETING';
  public static readonly TYPE_CD_LIST__ALL = [
    McPdfTemplateGENERATED.TYPE_CD_DUNNING,
    McPdfTemplateGENERATED.TYPE_CD_MARKETING
];

  public apiService: McPdfTemplateService;
  public _rawJson: IMcPdfTemplate;
    id: number = 0;
  description = '';
  idMcEntity: number;
  idWebfile: number;
  statusCd: string;
  title = '';
  typeCd: string;


  public properties: string[] = ['id', 'description', 'idMcEntity', 'idWebfile', 'statusCd', 'title', 'typeCd'];
  public propertiesRegular: string[] = ['id', 'description', 'idMcEntity', 'idWebfile', 'statusCd', 'title', 'typeCd'];
  public propertiesSpecial: string[] = [];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McPdfTemplateGENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(McPdfTemplateGENERATED.STATUS_CD_INACTIVE, McGod.t('Inactive'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McPdfTemplateGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McPdfTemplateGENERATED.TYPE_CD_DUNNING, McGod.t('Dunning'));
    list.add(McPdfTemplateGENERATED.TYPE_CD_MARKETING, McGod.t('Marketing'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = McPdfTemplateGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McPdfTemplate {
    console.error('MTCN-ERROR: Not Implemented: McPdfTemplate::createNewInstance(). Add this method to final class and return new McPdfTemplate();');
    throw new Error('Cannot McPdfTemplate::createNewInstance(). Add this method to final class and return new McPdfTemplate();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcPdfTemplate): McPdfTemplate {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McPdfTemplateService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McPdfTemplate AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return McPdfTemplateGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === McPdfTemplateGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdINACTIVE(): boolean {
    const result = (this.statusCd === McPdfTemplateGENERATED.STATUS_CD_INACTIVE);

    return result;
  }


  public getTypeCdLabel(): string {
    return McPdfTemplateGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdDUNNING(): boolean {
    const result = (this.typeCd === McPdfTemplateGENERATED.TYPE_CD_DUNNING);

    return result;
  }

 public  isTypeCdMARKETING(): boolean {
    const result = (this.typeCd === McPdfTemplateGENERATED.TYPE_CD_MARKETING);

    return result;
  }


  
}
