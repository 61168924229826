/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
/* Using "any" data-type in returned observables to avoid importing   */
/* ItemClass and ItemListClass and circular dependencies.             */
/* ------------------------------------------------------------------ */
import {BaseObjectService} from '../_core/base-object-service';
import {IMcEntity2} from '../_generated/mc-entity2.interface';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';
// import {McEntity2} from '../models/mc-entity2.model';
// import {McEntity2List} from '../models/mc-entity2-list.model';
// import {McEntity2Filter} from '../models/mc-entity2-filter.model';
import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class McEntity2ServiceGENERATED extends BaseObjectService {
protected apiServiceUrlSubPath = "/mcentity/v2/mcentity2";

public getApiServiceRootUrl(): string {
    return this.apiUrl + this.apiServiceUrlSubPath;
  }

  /* Get all  from DB */
public getAll(aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/all?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  /* Get all  from DB without pager */
public getByFilter(aFilter: any, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/filter?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, aFilter.toDto(), {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

/* Get by id from DB */
public getById(id: number): Observable<IMcEntity2> {
  return this.httpClient.get<IMcEntity2>(this.getApiServiceRootUrl() + `/${id}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

// Insert Object
public insert(aMcEntity2: any): Observable<IMcEntity2> {
    return this.httpClient.post<IMcEntity2>(this.getApiServiceRootUrl()  , aMcEntity2.toDto(), {headers: this.httpOptions});
}
// Update object
public update(aMcEntity2: any): Observable<IMcEntity2> {
    return this.httpClient.put<IMcEntity2>(this.getApiServiceRootUrl() + `/`+ aMcEntity2.id, aMcEntity2.toDto(), {headers: this.httpOptions});
}


// DeleteAllFromDB
public deleteAllFromDb() {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/deleteAllFromDb`, {headers: this.httpOptions});
  }

  /* Get by id from DB */
public deleteById(id: number) {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/${id}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });

  }

  // -- CUSTOM METHODS --
  

  // ---------------------------------------------------------------------
  /* getAllWhereFactoringParentIsLoggedInEntity  from DB  */
  public getAllWhereFactoringParentIsLoggedInEntity( aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getallwherefactoringparentisloggedinentity?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getAllWhereFactoringChildIsLoggedInEntity  from DB  */
  public getAllWhereFactoringChildIsLoggedInEntity( aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getallwherefactoringchildisloggedinentity?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getAllThatImportedSepa  from DB  */
  public getAllThatImportedSepa( aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getallthatimportedsepa?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getAllWhereFactoringTransactionParentIsLoggedInEntity  from DB  */
  public getAllWhereFactoringTransactionParentIsLoggedInEntity( aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getallwherefactoringtransactionparentisloggedinentity?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getAllWhereFactoringTransactionChildIsLoggedInEntity  from DB  */
  public getAllWhereFactoringTransactionChildIsLoggedInEntity( aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getallwherefactoringtransactionchildisloggedinentity?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getAllWhereParentEntityIsLoggedInEntity  from DB  */
  public getAllWhereParentEntityIsLoggedInEntity( aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getallwhereparententityisloggedinentity?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getEntityHierarchyForSharedMapping  from DB  */
  public getEntityHierarchyForSharedMapping( aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getentityhierarchyforsharedmapping?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getEntityWithChildEntities  from DB  */
  public getEntityWithChildEntities( aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getentitywithchildentities?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getAllEntitiesUsingTemplate  from DB  */
  public getAllEntitiesUsingTemplate( idTemplate: number, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getallentitiesusingtemplate/${idTemplate}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getAllEntitiesUsingPriceList  from DB  */
  public getAllEntitiesUsingPriceList( idPriceList: number, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getallentitiesusingpricelist/${idPriceList}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getByMcBillingParentEntityId  from DB  */
  public getByMcBillingParentEntityId( idParentEntity: number, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getbymcbillingparententityid/${idParentEntity}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


}
