/*BbmTestObj*/
import {CsrDiscountGENERATED} from '../_generated/csr-discount-generated.model';

export class CsrDiscount extends CsrDiscountGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrDiscount();
  }

  // ---------------------------------------------------------------------
}
