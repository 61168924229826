import {McProcessRunJobInfoAbstract} from "./mc-process-run-job-info-abstract.model";
import moment from "moment";
import {ICON_LINK} from "../svg/icon-link";

export abstract class McProcessRunJobInfo extends McProcessRunJobInfoAbstract {

  public static override createNewInstance() {
    // @ts-ignore
    return new McProcessRunJobInfo();
  }

  getId() {
    return '#' + this.idMcProcessJobExecution;
  }

  getSuccess() {
    return `<div class='icon-text-table-cell' style='cursor: pointer'>${ICON_LINK} <span>${ this.successRunEntitiesCount }</div>`
  }

  getFailed() {
    return `<div class='icon-text-table-cell' style='cursor: pointer'>${ICON_LINK} <span>${ this.failedRunEntitiesCount }</div>`
  }

  getDelivered() {
    return this.deliveredEmailsCount;
  }

  getSkipped() {
    return `<div class='icon-text-table-cell' style='cursor: pointer'>${ICON_LINK} <span>${ this.skippedEmailsCount }</div>`
  }

  getError() {
    return `<div class='icon-text-table-cell' style='cursor: pointer'>${ICON_LINK} <span>${ this.errorEmailsCount }</div>`
  }

  getCreatedOn() {
    return moment(this.createdOn).format('DD.MM.YYYY hh:mm');
  }
}
