/*BbmTestObj*/
import {McPdfTemplateGENERATED} from '../_generated/mc-pdf-template-generated.model';
import {McGod} from '../extra/mc-god.service';
import {McHtmlActionSet} from '../_core/mc-html-action-set';
import {ActionOptions} from '../_core/mc-html-action-options';

export class McPdfTemplate extends McPdfTemplateGENERATED {

  public static ACTION_SHOW_PROFILE = 'PROFILE';
  public static ACTION_SHOW_EDIT = 'EDIT';

  private _fldActions: McHtmlActionSet | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McPdfTemplate();
  }

  // ---------------------------------------------------------------------

  fldActions() {
    if (!this._fldActions) {
      const actionSet = new McHtmlActionSet();
      // actionSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t('cc.common.view.view')}`, McPdfTemplate.ACTION_SHOW_PROFILE, this, [McGod.PERM_EB_FACTORING_MY_TRANSACTION_VIEW]);
      actionSet.addActionB(`<i class="fa mc-submit-color fa-pen px-1 mc-cursor-pointer"></i> ${this.t('cc.common.view.edit')}`, McPdfTemplate.ACTION_SHOW_EDIT, this, [McGod.PERM_MC_PROCESS_PDF_TEMPLATE_EDIT], '', ActionOptions.OPTION_EDIT);
      this._fldActions = actionSet;
    }
    return this._fldActions;
  }
}
