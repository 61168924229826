<h3 class="page-title">{{ "cc.pricelist.pricelists" | translate }}</h3>

<div class="actions-container">
  <span>{{ "cc.pricelist.pricelists" | translate }}</span>
  <div class="actions-wrapper">
    <button *ngIf="mcGod.userHasMcBillingPricelistAddPermission()"
            class="system-action-btn ml-auto" (click)="actShowNewPricelistMcb()">
      <i class="fas fa-plus-circle"></i>
      {{'cc.pricelist.new-pricelist' | translate}}
    </button>
  </div>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [totalItemsCount]="totalItemsCount"
  [filterConfig]="filterConfig"
  (filterChanged)="onFilterChanged($event)"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
  [searchTooltip]="searchTooltip"
></mk-mat-table>

<div class="row">
  <div class="mb-5 mt-5"></div>
  <mcc-modal1-wrapper modalSizeCd="small" [showMcb]="showAddPricelistStep1" *ngIf="showAddPricelistStep1.value"
                      (eventCanceled)="actCloseAddPricelistStepper()">
    <mc-pricelist-pricelist-select-template-part [mcForm]="mcFormForCreatePricelist" [mcTemplates]="mcTemplates"
                                                 (eventNext)="onAddPricelistStep1NextEmit($event)"
                                                 (eventCurrencySymbol)="onCurrencySymbolEmitted($event)"
                                                 (eventCanceled)="actCloseAddPricelistStepper()"></mc-pricelist-pricelist-select-template-part>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper modalSizeCd="full" [showMcb]="showAddPricelistStep2PricesAndEntities"
                      *ngIf="showAddPricelistStep2PricesAndEntities.value"
                      (eventCanceled)="actCloseAddPricelistStepper()">
    <mc-pricelist-pricelist-prices-and-entities-part [mcForm]="mcFormForCreatePricelist"
                                                     [entitiesValue]="pricelistEntitiesValue"
                                                     [currencyCode]="createPricelistCurrencySelected"
                                                     [templatePriceList]="chosenTemplatePriceList"
                                                     [defaultTemplatePriceList]="defaultChosenTemplatePriceList"
                                                     (eventCanceled)="actCloseAddPricelistStepper()"
                                                     (eventPrev)="onAddPricelistStep2PrevEmit()"
                                                     (eventSelectedEntities)="onSelectedEntities($event)"
                                                     (eventNext)="onAddPricelistStep2NextEmit()"></mc-pricelist-pricelist-prices-and-entities-part>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper modalSizeCd="full" [showMcb]="showAddPricelistStep3ReviewAndConfirm"
                      *ngIf="showAddPricelistStep3ReviewAndConfirm.value"
                      (eventCanceled)="actCloseAddPricelistStepper()">
    <mc-pricelist-pricelist-review-and-confirm-part [mcForm]="mcFormForCreatePricelist"
                                                    [currency]="currencyCode"
                                                    [entities]="pricelistEntitiesValue"
                                                    [templatePriceList]="chosenTemplatePriceList"
                                                    (eventCanceled)="actCloseAddPricelistStepper()"
                                                    (eventSaved)="onAddPricelistSaveEmit()"
                                                    [selectedEntities]="selectedEntities"
                                                    (eventPrev)="onAddPricelistStep3PrevEmit()"></mc-pricelist-pricelist-review-and-confirm-part>
  </mcc-modal1-wrapper>
</div>

<router-outlet></router-outlet>

