import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'ignore', useHash: true})],
  exports: [RouterModule]
})
export class McCoreUiRouterModule {
}
