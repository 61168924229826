/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McConsumerImportTable3ConfigGENERATED} from '../_generated/mc-consumer-import-table-3-config-generated.model';

// import {McConsumerImportGENERATED} from '../_generated/mc-consumer-import-generated.model';

export class McConsumerImportTable3Config extends McConsumerImportTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colImportName', this.t('cc.common.import-name'), 'importName', 'wf.filename');
    this.addColumn('colUserName', this.t('cc.common.imported-by'), 'userName', 'mcu.firstname');
    this.addColumn('colImportMode', this.t('cc.consumers.consumers-overview.import-mode'), 'fldImportMode()', 'importModeCd');
    this.addColumn('colRecords', 'Processed, Added, Updated, Skipped Records', 'fldRecords()', 'processedRecords');
    // this.addColumn('colProcessedRecords', 'Processed records', 'processedRecords', 'processedRecords');
    // this.addColumn('colAddedRecords', 'Added records', 'addedRecords', 'addedRecords');
    // this.addColumn('colUpdatedRecords', 'Updated records', 'updatedRecords', 'updatedRecords');
    this.addColumn('colStartDateAndTime', this.t('cc.factoring.imported-sepa.import-date-&-time'), 'fldCreatedAt()', 'createdAt');


  }
}
