import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {McBankAccountsCurrentEntityAdminComponent} from './mc-bank-accounts-current-entity-admin.component';
import {McBankAccountModalComponent} from './components/mc-bank-account-modal/mc-bank-account-modal.components';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {McComponentsModule} from '../mc-components.module';
import {TranslateModule} from '@ngx-translate/core';
import {McBankAccountFullNameModule} from '../mc-bank-account-full-name/mc-bank-account-full-name.module';

@NgModule({
  declarations: [McBankAccountsCurrentEntityAdminComponent, McBankAccountModalComponent],
  exports: [
    McBankAccountsCurrentEntityAdminComponent,
    McBankAccountModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    McComponentsModule,
    TranslateModule,
    McBankAccountFullNameModule
  ]
})
export class McBankAccountsCurrentEntityAdminModule { }
