import {ZzPocCarFilterGENERATED} from '../_generated/zz-poc-car-filter-generated.model';

export class ZzPocCarFilter extends ZzPocCarFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new ZzPocCarFilter();
  }

  // ---------------------------------------------------------------------
}
