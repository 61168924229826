import {McBillingBatch2TransactionFilterGENERATED} from '../_generated/mc-billing-batch-2-transaction-filter-generated.model';

export class McBillingBatch2TransactionFilter extends McBillingBatch2TransactionFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingBatch2TransactionFilter();
  }

  // ---------------------------------------------------------------------
}
