export class McProcessesResendActionModel {

  public emailActionFlg = false;
  public postActionFlg = false;
  public smsActionFlg = false;
  public emailActionTypeCd!: string;
  public postActionTypeCd!: string;
  public smsActionTypeCd!: string;
  public differentEmailAddress!: string;
  public differentPostAddress!: string;
  public differentCellphoneNumber!: string;
  public updateConsumersEmailFlg!: boolean;
  public updateConsumersPostAddressFlg!: boolean;
  public updateConsumersCellphoneNumberFlg!: boolean;
  public reason!: string;

  constructor() {
  }
}
