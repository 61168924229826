<div class="mcc-fi mcc-fi-select mcc-fi-select-{{name}} {{extraCssClass}} {{labelPositionClass}}">
<!--  <div *ngIf="isSelectInFilter" (click)="clearInput()" class="mcc-fi-select-clear">{{'cc.common.clear' | translate}}</div>-->
  <form [formGroup]="getSelectForm()" [ngClass]="{'validation-error' : this.getSelectForm().get('errorFlg')?.value}">
    <mat-form-field appearance="outline">
      <mat-label>{{ label }}</mat-label>
      <mat-select panelClass="dialog-select"
                  id="mcc-fi-{{name}}"
                  formControlName="value"
                  (valueChange)="onSelectChanged($event)"
                  disableOptionCentering>
        <mat-option *ngFor="let vlItem of optionsVll.items"  [value]="vlItem.value">
          {{ vlItem.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="error-msg">{{this.getSelectForm().get('errorMsg')?.value}}</div>
  </form>
</div>
