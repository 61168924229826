import {Component, OnInit} from '@angular/core';
import {
  EbSepaFileList,
  EbSepaFile,
  EbSepaFileFilter,
  McValueLabelList,
  McEntity2List,
  EbFactoringService, CurrencyUtil, EbSepaFileTransaction
} from '@miticon-ui/mc-core';
import {Pagination, McForm} from '@miticon-ui/mc-core';
import {EbSepaFileTable3Config} from './eb-sepa-file-table-3-config.model';
import {Subject} from 'rxjs';

@Component({
  selector: 'mc-out-factoring-sepa-in-list-page',
  templateUrl: './mc-out-factoring-sepa-in-list-page.component.html',
})
export class McOutFactoringSepaInListPageComponent implements OnInit {

  mcForm = new McForm();
  ebSepaFileList!: EbSepaFileList;
  ebSepaFileTable3Config = new EbSepaFileTable3Config();
  ebSepaFileFilter = new EbSepaFileFilter();
  msgText!: string;
  msgType!: string;
  ebSepaFileStatusCdVll: McValueLabelList = EbSepaFile.getStatusCdVll();
  entitiesThatImportedSepas = new McEntity2List();
  clearedAllMultipleFilters = new Subject<boolean>();
  entitiesThatImportedSepasVll!: McValueLabelList;
  entityIds: any[] = [];
  statusCds: any[] = [];
  ebSepaFileTransaction!: EbSepaFileTransaction;

  constructor(private factoringService: EbFactoringService) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    EbSepaFile.getServicePeriodVll();
    this.msgText = '';
    this.msgType = '';
    this.ebSepaFileList = new EbSepaFileList();
    this.ebSepaFileList.setPager(0, 100);
    this.entitiesThatImportedSepas.loadAllThatImportedSepa();
    this.entitiesThatImportedSepas.loadAllThatImportedSepa(() => {
      this.entitiesThatImportedSepas.addSortB('name', 'ASC');
      this.entitiesThatImportedSepasVll = this.entitiesThatImportedSepas.toVll('id', 'name');
    });
    this.actLoad();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.ebSepaFileFilter.outFactoringFlg = true;
    this.ebSepaFileFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.ebSepaFileFilter.statusCds = this.statusCds;
    this.ebSepaFileFilter.childEntityIds = this.entityIds;
    this.ebSepaFileList.loadByFilter(this.ebSepaFileFilter, () => {
      this.ebSepaFileList.items.map(item => {
        const code = this.factoringService.parentFactorings?.find(fac => fac.idChildMcEntity === item.idMcEntity)?.mcCurrency.code;
        item.acceptedAmountStr = this.format(item.acceptedAmount, code);
        item.totalAmountStr = this.format(item.totalAmountDue, code);
      });
    });
  }


  refreshTable() {
    this.actLoad();
    this.msgText = 'SEPA file successfully imported';
    this.msgType = 'success';
  }

  actClearFilter() {
    this.clearedAllMultipleFilters.next(true);
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.mcForm.getControl('selStatusCd')?.get('value')?.setValue('');
    // this.mcForm.getControl('selServicePeriod').get('value').setValue('');
    this.actLoad();
  }

  onSelectedStatus(statusVll: []) {
    this.statusCds = statusVll;
  }

  onSelectedEntity(entitiesVll: []) {
    this.entityIds = entitiesVll;
  }

  format(value: number, code: string): string {
    if (value) {
      return CurrencyUtil.format(value, code);
    }
    return CurrencyUtil.format(0, code);
  }
}
