/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingBatch2TransactionTable3ConfigGENERATED} from '../_generated/mc-billing-batch-2-transaction-table-3-config-generated.model';

// import {McBillingBatch2TransactionGENERATED} from '../_generated/mc-billing-batch-2-transaction-generated.model';

export class McBillingBatch2TransactionTable3Config extends McBillingBatch2TransactionTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdTransaction', 'Transaction id', 'idTransaction', 'idTransaction');
    this.addColumn('colIdBatch', 'Batch id', 'idBatch', 'idBatch');


  }
}
