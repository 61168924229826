import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {
  McBillingStatementList,
  McBillingStatement,
  McEntity2List,
  BaseDirective,
  SortCriteriaList
} from '@miticon-ui/mc-core';
import {
  McForm,
  McBoolean,
  McGod,
  McTrigger,
  McValueLabelList
} from "@miticon-ui/mc-core";
import {Router, ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: "mc-out-billing-statement-add-edit-part",
  templateUrl: "./mc-out-billing-statement-add-edit-part.component.html",
})
export class McOutBillingStatementAddEditPartComponent extends BaseDirective implements OnInit, OnDestroy {

  mcForm: McForm = new McForm();
  mcGod = McGod.getInstance();
  saveButtonDisabledMcb = new McBoolean();

  saveSubscription!: Subscription;
  deleteSubscription!: Subscription;
  cancelSubscription!: Subscription;
  errorMsgs: string[] = [];
  errorMsg!: string;
  childEntityIds = [];
  childEntitiesVll = new McValueLabelList();
  childEntities = new McEntity2List();
  childEntitiesValueVll = new McValueLabelList();

  @Input() mcBillingStatementId!: number;
  @Input() mcBillingStatement!: McBillingStatement;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;

  @Output() eventSaved = new EventEmitter<McBillingStatement>();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();


  // -- Constructor --
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private location: Location) {
    super();
  }


  ngOnInit() {
    if (!this.mcBillingStatement) {
      this.mcBillingStatement = new McBillingStatement();
    }
    this.actLoad()
  }


  actLoad(): void {
    this.mcBillingStatement = new McBillingStatement();
    this.mcBillingStatement.apiService.getFilteredListOfEntitiesForCreateStatement(0, 10000, new SortCriteriaList()).subscribe((validEntities) => {
      this.childEntities.items = validEntities.content;
      this.childEntities.items.sort((a, b) => {
        return (a.name).localeCompare(b.name);
      });

      this.childEntities.items.map(item => this.childEntitiesVll.add(item.id, item.name));
    });

    if (this.mcBillingStatementId > 0) {
      this.mcBillingStatement.loadById(this.mcBillingStatementId, () => {
        this.validatePrerequisites();
      });
    } else {
      // new item
      this.validatePrerequisites();
    }
  }

  // ---------------------------------------------------------------------
  actSave() {
    if (this.mcForm.isValid()) {
      const newStatements = new McBillingStatementList();

      this.childEntityIds.forEach(id => {
        this.mcBillingStatement.parentEntityId = McGod.getLoggedEntityIdFromToken();
        this.mcBillingStatement.childEntityId = id;
        this.mcBillingStatement.title = this.mcForm.getValue("ctrlTitle");
        this.mcBillingStatement.statementDate = this.mcForm.getValue("ctrlStatementDate");
        this.mcBillingStatement.statusCd = McBillingStatement.STATUS_CD_NEW;
        this.mcBillingStatement.flgHidden = this.mcForm.getValue('ctrlHideUnhideStatement') ? this.mcForm.getValue('ctrlHideUnhideStatement') : false;

        const mcStatementObject = new McBillingStatement();
        const statement = Object.assign(mcStatementObject, this.mcBillingStatement);
        newStatements.items.push(statement);
      });

      const autoImportFlg = this.mcForm.getValue("ctrlAutoImport");

      this.mcBillingStatement.apiService.createStatements(newStatements, autoImportFlg).pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.eventSaved.emit(this.mcBillingStatement);
      }, (error) => {
        this.mcBillingStatement.apiErrorMessage = error.error.message;
        this.saveButtonDisabledMcb.setFalse();
      });
    } else {
      this.saveButtonDisabledMcb.setFalse();
    }

  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.mcBillingStatement.exists()) {

      this.mcBillingStatement.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

// ---------------------------------------------------------------------
  validatePrerequisites() {
    this.errorMsgs = [];
    // populate validation here on required inputs
  }

  // ---------------------------------------------------------------------
  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }
  onSelectEntities(value: []) {
    this.childEntityIds = value;
  }
}
