import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CsrContractPreDefined,
  CsrContractPreDefinedService, McDateUtils,
  McForm,
  McValueLabelList
} from '@miticon-ui/mc-core';
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'mc-consumer-consumer-360-create-contract-products-and-services-part',
  templateUrl: './mc-consumer-consumer-360-create-contract-products-and-services-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-create-contract-products-and-services-part.component.scss']
})
export class McConsumerConsumer360CreateContractProductsAndServicesPartComponent implements OnInit {

  /*Variables*/
  @Input() selectedProduct: any;
  productsVll = new McValueLabelList();
  allContracts: CsrContractPreDefined[] = [];
  @Input() mcForm!: McForm;
  @Output() emitSelectedContract = new EventEmitter();
  todayDate = McDateUtils.getNowTimestamp();

  constructor(private csrContractPredefinedService: CsrContractPreDefinedService,
              private route: ActivatedRoute,
              private tS: TranslateService) {
  }

  ngOnInit() {
    this.getPredefinedContracts();
  }

  getPredefinedContracts() {
    const consumerId = this.route.snapshot.params['id'];
    this.csrContractPredefinedService.getAvailableForPurchase(consumerId).subscribe((contracts) => {
      this.allContracts = contracts;
      if(contracts) {
        contracts.forEach((contract: any) => this.productsVll.add(contract.id, contract.name))
      }
    });
  }

  onProductClick(productId: number) {
    this.selectedProduct = this.allContracts.find((contract) => contract.id === productId);
    this.mcForm.getControl('ctrlContractStartDate')?.get('value')?.setValue(null)
    this.mcForm.getControl('ctrlContractEndDate')?.get('value')?.setValue(null);
    this.emitSelectedContract.emit(this.selectedProduct);
  }

  onStartDateChange(event: string): void {
    if(this.selectedProduct.durationInMonths) {
      const endDate = new Date(this.addXMonthsToDate(this.mcForm.getControl('ctrlContractStartDate')?.value.value, this.selectedProduct.durationInMonths));
      this.mcForm.getControl('ctrlContractEndDate')?.get('value')?.setValue(endDate);
    } else this.mcForm.getControl('ctrlContractEndDate')?.get('value')?.setValue(null);
  }

  addXMonthsToDate(date: any, months: number) {
    const dateToBeFormatted = new Date(date);
    return dateToBeFormatted.setMonth(dateToBeFormatted.getMonth() + months)
  }

  getEndDate() {
    return this.mcForm.getControl('ctrlContractEndDate')?.value.value;
  }

  getPriceLabel(typeCd: string) {
    return CsrContractPreDefined.getLabel(typeCd).label;
  }

  getTypeCdLabel(typeCd: string) {
    return this.tS.instant(CsrContractPreDefined.getTypeCdLabel(typeCd));
  }

  isOneTimeType(){
    return this.selectedProduct.typeCd === CsrContractPreDefined.TYPE_CD_ONE_TIME;
  }

  getVat(): string {
    return CsrContractPreDefined.getVat(this.selectedProduct);
  }
}
