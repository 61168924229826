import {Component, Input, OnInit} from '@angular/core';
import {CdkStepper} from '@angular/cdk/stepper';

@Component({
  selector: 'mcc-stepper',
  templateUrl: './mcc-stepper.component.html',
  styleUrls: ['./mcc-stepper.component.scss'],
  providers: [{provide: CdkStepper, useExisting: MccStepperComponent}]
})
export class MccStepperComponent extends CdkStepper {


  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }

}
