import {ImDataExportTemplateFilterGENERATED} from '../_generated/im-data-export-template-filter-generated.model';

export class ImDataExportTemplateFilter extends ImDataExportTemplateFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataExportTemplateFilter();
  }

  // ---------------------------------------------------------------------
}
