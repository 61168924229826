import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {McEntityPasswordPolicyComponent} from "./mc-entity-password-policy.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {McComponentsModule} from "@miticon-ui/mc-components";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [McEntityPasswordPolicyComponent],
  exports: [
    McEntityPasswordPolicyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    McComponentsModule,
    TranslateModule
  ]
})
export class McEntityPasswordPolicyModule { }
