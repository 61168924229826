<div class="row p-0 m-0 d-flex flex-row align-items-center">
  <h3 class="pr-2 pt-1">{{'cc.out-factoring.imported-sepa-list' | translate}}</h3>
</div>

<div>
    <lib-mc-loader [showLoader]="ebSepaFileList.apiLoadingFlg"></lib-mc-loader>

    <div *ngIf="ebSepaFileList.apiErrorFlg">
        Error: {{ebSepaFileList.apiErrorMessage}}
    </div>


  <div class="mc-filter fancy-label">

    <i class="fas fa-info-circle mc-tooltip-top-margin" ngbTooltip="{{'cc.my-factoring.search-by-file-name' | translate}}" placement="bottom-left"></i>

      <mcc-fi-textbox [isClearEnabled]="true" label="{{'cc.common.search' | translate}}" name="txbSearchTerm" [mcForm]="mcForm" (eventEnterClicked)="actLoad()"></mcc-fi-textbox>

    <!--Status filter-->
    <mcc-multiselect-filter [optionVll]="ebSepaFileStatusCdVll" [clearedAllFilters]="clearedAllMultipleFilters"
                            [label]="'cc.common.view.status' | translate" name="selStatusCd" (eventSelectedItems)="onSelectedStatus($event)">
    </mcc-multiselect-filter>

    <!--Entity filter-->
    <mcc-multiselect-filter *ngIf="entitiesThatImportedSepas.apiSuccessFlg" [optionVll]="entitiesThatImportedSepasVll"
                            [clearedAllFilters]="clearedAllMultipleFilters" [label]="'cc.common.view.entity' | translate"
                            name="selChildEntityId" (eventSelectedItems)="onSelectedEntity($event)">
    </mcc-multiselect-filter>

    <button class="mc-link mc-filter-clear-button filter-clear-top-right" (click)="actClearFilter()">{{'cc.my-factoring.clear-all' | translate}}</button>
    <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>


  </div>

  <div class="mc-notify-msg">
    <mc-notify-message [message]="msgText" [type]="msgType"></mc-notify-message>
  </div>


  <!--  <a class="col-md-1 offset-md-11" routerLink="edit/0">Add New</a>-->

    <div *ngIf="ebSepaFileList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="ebSepaFileList"
                        [table3Config]="ebSepaFileTable3Config"
                        (eventPaginationOrSortChanged)="actLoad()"></mcc-fi-table-3>
    </div>

</div>

<router-outlet></router-outlet>

