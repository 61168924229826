
import {Table3Config} from '../extra/table-3-config';

// import {McBillingItemGENERATED} from '../_generated/mc-billing-item-generated.model';

export class McBillingItemTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colActivityDatetime', 'Activity datetime', 'activityDatetime', 'activityDatetime');
    this.addColumn('colAmount', 'Amount', 'amount', 'amount');
    this.addColumn('colChildEntityId', 'Child entity ID', 'childEntityId', 'childEntityId');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colEntryTypeCd', 'Credit or debit', 'entryTypeCd', 'entryTypeCd');
    this.addColumn('colIdBillingService', 'Billing service ID', 'idBillingService', 'idBillingService');
    this.addColumn('colMcBillingBatchId', 'Billing batch id', 'mcBillingBatchId', 'mcBillingBatchId');
    this.addColumn('colMcBillingStatementId', 'Billing statement ID', 'mcBillingStatementId', 'mcBillingStatementId');
    this.addColumn('colMcBillingStatementItemId', 'Billing statement item ID', 'mcBillingStatementItemId', 'mcBillingStatementItemId');
    this.addColumn('colMcBillingBatch', 'Billing Batch', 'mcBillingBatch', 'mcBillingBatch');
    this.addColumn('colMcBillingStatement', 'Billing Statement', 'mcBillingStatement', 'mcBillingStatement');
    this.addColumn('colMcBillingStatementItem', 'Billing statement item', 'mcBillingStatementItem', 'mcBillingStatementItem');
    this.addColumn('colParentEntityId', 'Parent entity ID', 'parentEntityId', 'parentEntityId');
    this.addColumn('colPayoutDate', 'Payout date', 'payoutDate', 'payoutDate');
    this.addColumn('colRefId', 'Ref ID', 'refId', 'refId');
    this.addColumn('colRefTypeCd', 'Ref type cd', 'refTypeCd', 'refTypeCd');
    this.addColumn('colStatusCd', 'Status cd', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colTotal', 'Total', 'total', 'total');
    this.addColumn('colTransactionReturnedTypeCd', 'Type cd', 'transactionReturnedTypeCd', 'transactionReturnedTypeCd');
    this.addColumn('colVatForMcBillingItem', 'Vat', 'vatForMcBillingItem', 'vatForMcBillingItem');
    this.addColumn('colVatPercent', 'VAT Percent', 'vatPercent', 'vatPercent');

*/
  }
}
