<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcBillingItemList.apiLoadingFlg || billingItemList.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h2 *ngIf="!isAggregate">Deaggregate</h2>
    <h2 *ngIf="isAggregate">Aggregate</h2>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-body">
    <mcc-message [errorMsg]="errorMsg"></mcc-message>

    <ng-container *ngIf="allowActions">
      <div class="info-container2 p-3">
        <mcc-row-title-description title="Aggregation name: "
                                   [description]="aggregationName"></mcc-row-title-description>
        <mcc-row-title-description title="Number of items: " [description]="noOfItems"></mcc-row-title-description>
        <mcc-row-title-description title="Amount: " [description]="mcGod.formatNumberToMoney(amount)"></mcc-row-title-description>
        <mcc-row-title-description title="VAT: " [description]="mcGod.formatNumberToMoney(vat)"></mcc-row-title-description>
        <mcc-row-title-description title="Amount with VAT: " [description]="mcGod.formatNumberToMoney(amountWithVat)"></mcc-row-title-description>
      </div>
      <div class="info-warning mt-5 mb-5">
        <div *ngIf="isAggregate"><i class="far fa-question-circle icon-orange"></i> <span class="single-line-txt">Are you sure you want to aggregate these items?</span>
        </div>
        <div *ngIf="!isAggregate"><i class="far fa-question-circle icon-orange"></i><span class="single-line-txt">Are you sure you want to deaggregate this aggregation?</span>
        </div>
      </div>
      <mcc-fi-checkbox *ngIf="!isAggregate" label="Leave billing statements in the statement"
                       name="ctrlLeaveBillingStatements"
                       [value]="true"
                       [isCheckboxChecked]="true"
                       [mcForm]="mcForm"></mcc-fi-checkbox>
    </ng-container>

  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="No"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actSave();" label="Yes"
                [disabledMcb]="disableAggregateDeaggregateBtnMcb"
                disableOnFirstClickFlg="true"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
