/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McProductTable3ConfigGENERATED} from '../_generated/mc-product-table-3-config-generated.model';

// import {McProductGENERATED} from '../_generated/mc-product-generated.model';

export class McConsumerContractProductTable3ConfigModel extends McProductTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();
    this.allowRowSelectionWithCheckboxesFlg = false;
    this.clearColumns();
    this.addColumn('id', this.t('cc.consumer.art-no'), 'id', '');
    this.addColumn('id', this.t('cc.consumer.art-name'), 'name', '');
    this.addColumn('desc', this.t('cc.consumer.art-description'), 'description', '').extraCssClass = 'mc-width-30-percetage';
    this.addColumn('price', this.t('cc.consumer.price-netto'), 'fldUnitPriceLoc()', '').extraCssClass = 'text-right';
    this.addColumn('vat', this.t('cc.product.view.vat'), 'fldVatRate()', '');
    this.addColumn('quantity', this.t('cc.jobs.view.quantity'), 'fldQuantity()', '');
    this.addColumn('remove', '', 'fldRemove()', '');

  }
}
