/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McCurrency} from '../models/mc-currency.model';
import {McCurrencyService} from '../services/mc-currency.service';
import {IMcCurrency} from '../_generated/mc-currency.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McCurrencyGENERATED extends BaseObject {


  public apiService: McCurrencyService;
  public _rawJson: IMcCurrency;
    id: number = 0;
  code = '';
  localizationKey = '';
  name = '';


  public properties: string[] = ['id', 'code', 'localizationKey', 'name'];
  public propertiesRegular: string[] = ['id', 'code', 'localizationKey', 'name'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): McCurrency {
    console.error('MTCN-ERROR: Not Implemented: McCurrency::createNewInstance(). Add this method to final class and return new McCurrency();');
    throw new Error('Cannot McCurrency::createNewInstance(). Add this method to final class and return new McCurrency();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcCurrency): McCurrency {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McCurrencyService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McCurrency AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
