<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcBillingItem.apiLoadingFlg || mcBillingStatementList.apiLoadingFlg || loadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h2>Assign to statement</h2>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!mcBillingItem.apiLoadingFlg" class="content-body">
    <mcc-message [errorMsg]="mcBillingItem.apiErrorMessage"></mcc-message>
    <mcc-message [errorMsg]="billingItemList.apiErrorMessage"></mcc-message>
    <!-- == MAIN CONTENT ======================================== -->
    <ng-container *ngIf="(mcBillingItem.apiSuccessFlg)">

      <div class="info-container2 p-3">
        <mcc-row-title-description title="Number of items:" [description]="numberOfItems"></mcc-row-title-description>
        <mcc-row-title-description title="Total amount:" [description]="formatNumberToMoneyWithExactCurrency(totalAmount)"></mcc-row-title-description>
        <mcc-row-title-description title="VAT:" [description]="formatNumberToMoneyWithExactCurrency(totalVAT)"></mcc-row-title-description>
        <mcc-row-title-description title="Total:" [description]="formatNumberToMoneyWithExactCurrency(totalAmount + (totalVAT ? totalVAT : 0))"></mcc-row-title-description>
      </div>

      <div class="row" *ngIf="mcGod.userHasMcBillingOutStatementAddPermission()">
        <button class="system-action-btn ml-auto mr-3 mt-3" (click)="actCreateStatement()"><i class="fas fa-plus-circle"></i>{{'cc.billing.create-new-statement' | translate}}</button>
      </div>
      <hr>
      <div>
        <mcc-fi-radio-buttons2 label="Statements"
                               name="ctrlStatements"
                               [mcForm]="mcForm"
                               [validRequiredFlg]="true"
                               [directionHorizontal]="false"
                               [optionsVll]="mcBillingStatementVll"></mcc-fi-radio-buttons2>
      </div>

      <div class="d-flex">
        <mcc-fi-radio-buttons2 label="Aggregate"
                               name="ctrlAggregate"
                               [mcForm]="mcForm"
                               [validRequiredFlg]="true"
                               [directionHorizontal]="false"
                               [optionsVll]="aggregateYesNoVll"></mcc-fi-radio-buttons2>
        <div class="d-flex flex-column mc-submit-color justify-content-center">
          <div class="icon-tooltip mt-4"><i class="fas fa-info-circle"></i>
            <span class="icon-tooltip-text">Billing items would be grouped by billing type.</span></div>
          <div class="icon-tooltip mt-2"><i class="fas fa-info-circle"></i>
            <span class="icon-tooltip-text">Billing items would not be grouped.</span></div>
        </div>
      </div>

      <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showAddEditStatementMcb.value" [showMcb]="showAddEditStatementMcb">
        <mc-out-billing-statement-add-edit-part (eventSaved)="onAddEditStatementSaved($event)" (eventCanceled)="showAddEditStatementMcb.setFalse()"></mc-out-billing-statement-add-edit-part>
      </mcc-modal1-wrapper>

    </ng-container>

  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel()"
                label="Cancel"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actAssign()" label="Assign" [disabledMcb]="disableAggregateBtnMcb"
                disableOnFirstClickFlg="true"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
