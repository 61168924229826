<div class="content-wrapper">

  <div>
    <div class="content-header">
      <h2>Consumer history for: {{csrConsumer?.firstName}} {{csrConsumer?.lastName}}</h2>
    </div>

    <div class="content-body">
      <div class="mt-5 position-relative">
        <div class="mc-notify-message-wrapper">
          <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
        </div>
      </div>

      <section class="row py-4 mc-consumer-details-history">

        <div class="col-md-12 pt-4">
          <mc-table *ngIf="isContentLoaded"
                    [tableContent]="tableContent">
          </mc-table>
        </div>

        <div *ngIf="csrConsumer.activeContracts" class="col-md-12">
          <div *ngFor="let contract of csrConsumer.activeContracts.items">
            <lib-csr-contract-history-overview [contractId]="contract.id"></lib-csr-contract-history-overview>
          </div>
        </div>

      </section>
    </div>
  </div>
</div>
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>

