/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McPdfTemplateFilter} from '../models/mc-pdf-template-filter.model';

export class McPdfTemplateFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McPdfTemplateFilter {
    console.error('MTCN-ERROR: Not Implemented: McPdfTemplateFilter::createNewInstance(). Add this method to final class and return new McPdfTemplateFilter();');
      throw new Error('Cannot McPdfTemplateFilter::createNewInstance(). Add this method to final class and return new McPdfTemplateFilter();');
  }

}
