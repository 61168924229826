
import {Table3Config} from '../extra/table-3-config';

// import {McProductGENERATED} from '../_generated/mc-product-generated.model';

export class McProductTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAvailableFrom', 'avai from', 'availableFrom', 'availableFrom');
    this.addColumn('colAvailableTo', 'avail to', 'availableTo', 'availableTo');
    this.addColumn('colChargingTypeCd', 'Charging type', 'chargingTypeCd', 'chargingTypeCd');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colExternalId', 'External Id', 'externalId', 'externalId');
    this.addColumn('colFlgArchived', 'Flag if product is archived', 'flgArchived', 'flgArchived');
    this.addColumn('colIdCurrency', 'Currency id', 'idCurrency', 'idCurrency');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdPpCategory', 'Category Id', 'idPpCategory', 'idPpCategory');
    this.addColumn('colIdVat', 'Vat id', 'idVat', 'idVat');
    this.addColumn('colMcCurrency', 'Currency id', 'mcCurrency', 'mcCurrency');
    this.addColumn('colMcVat', 'Vat id', 'mcVat', 'mcVat');
    this.addColumn('colName', 'Name', 'name', 'name');
    this.addColumn('colPpCategory', 'Category id', 'ppCategory', 'ppCategory');
    this.addColumn('colPricingTypeCd', 'Pricing type', 'pricingTypeCd', 'pricingTypeCd');
    this.addColumn('colRefId', 'Ref ID', 'refId', 'refId');
    this.addColumn('colRefTypeCd', 'Reference type', 'refTypeCd', 'refTypeCd');
    this.addColumn('colServiceTypeCd', 'Service type', 'serviceTypeCd', 'serviceTypeCd');
    this.addColumn('colState', 'state', 'state', 'state');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');
    this.addColumn('colUnitPrice', 'Unit price', 'unitPrice', 'unitPrice');
    this.addColumn('colUnitsInStock', 'Units In Stock', 'unitsInStock', 'unitsInStock');
    this.addColumn('colVersion', 'Version', 'version', 'version');

*/
  }
}
