/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McProcessFlow} from '../models/mc-process-flow.model';
import {McProcessFlowService} from '../services/mc-process-flow.service';
import {IMcProcessFlow} from '../_generated/mc-process-flow.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {McProcessFlowLevelList} from '../models/mc-process-flow-level-list.model';
import {McProcessFlowCriteriaList} from '../models/mc-process-flow-criteria-list.model';


export abstract class McProcessFlowGENERATED extends BaseObject {


    public static readonly COMPANY_INTEREST_RATE_TYPE_CD_DEFAULT: string = 'DEFAULT';
    public static readonly COMPANY_INTEREST_RATE_TYPE_CD_ENTER: string = 'ENTER';
  public static readonly COMPANY_INTEREST_RATE_TYPE_CD_LIST__ALL = [
    McProcessFlowGENERATED.COMPANY_INTEREST_RATE_TYPE_CD_DEFAULT,
    McProcessFlowGENERATED.COMPANY_INTEREST_RATE_TYPE_CD_ENTER
];

    public static readonly NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_REMAIN_IN_CURRENT_STATUS: string = 'REMAIN_IN_CURRENT_STATUS';
    public static readonly NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_SHOULD_GO_TO_INKASSO: string = 'SHOULD_GO_TO_INKASSO';
    public static readonly NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_SENDING_TO_INKASSO: string = 'SENDING_TO_INKASSO';
  public static readonly NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_LIST__ALL = [
    McProcessFlowGENERATED.NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_REMAIN_IN_CURRENT_STATUS,
    McProcessFlowGENERATED.NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_SHOULD_GO_TO_INKASSO,
    McProcessFlowGENERATED.NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_SENDING_TO_INKASSO
];

    public static readonly PERSON_INTEREST_RATE_TYPE_CD_DEFAULT: string = 'DEFAULT';
    public static readonly PERSON_INTEREST_RATE_TYPE_CD_ENTER: string = 'ENTER';
  public static readonly PERSON_INTEREST_RATE_TYPE_CD_LIST__ALL = [
    McProcessFlowGENERATED.PERSON_INTEREST_RATE_TYPE_CD_DEFAULT,
    McProcessFlowGENERATED.PERSON_INTEREST_RATE_TYPE_CD_ENTER
];

    public static readonly SEND_FLOW_TO_INKASSO_TRANSACTION_TYPE_CD_ALL_TRANSACTIONS: string = 'ALL_TRANSACTIONS';
    public static readonly SEND_FLOW_TO_INKASSO_TRANSACTION_TYPE_CD_EXPIRED_TRANSACTIONS: string = 'EXPIRED_TRANSACTIONS';
  public static readonly SEND_FLOW_TO_INKASSO_TRANSACTION_TYPE_CD_LIST__ALL = [
    McProcessFlowGENERATED.SEND_FLOW_TO_INKASSO_TRANSACTION_TYPE_CD_ALL_TRANSACTIONS,
    McProcessFlowGENERATED.SEND_FLOW_TO_INKASSO_TRANSACTION_TYPE_CD_EXPIRED_TRANSACTIONS
];

    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_INACTIVE: string = 'INACTIVE';
  public static readonly STATUS_CD_LIST__ALL = [
    McProcessFlowGENERATED.STATUS_CD_ACTIVE,
    McProcessFlowGENERATED.STATUS_CD_INACTIVE
];

  public apiService: McProcessFlowService;
  public _rawJson: IMcProcessFlow;
    id: number = 0;
  acceptedTransactionsEnteringFlowFlg: boolean;
  companyInterestRateTypeCd: string;
  description = '';
  dunningNoteBaseFeeAmount: number;
  emailFeeAmount: number;
  forDunningTransactionsEnteringFlowFlg: boolean;
  groupByConsumerFlg: boolean;
  idMcCurrency: number;
  idMcProcess: number;
  inheritableFlg: boolean;
  interestRateForCompanyAmount: number;
  interestRateForPersonAmount: number;
  newStatusForTransactionsAfterLastDunningLevel: string;
  personInterestRateTypeCd: string;
  postFeeAmount: number;
  returnedTransactionsEnteringFlowFlg: boolean;
  sendFlowToInkassoFlg: boolean;
  sendFlowToInkassoTransactionTypeCd: string;
  sendingToInkassoTransactionsEnteringFlowFlg: boolean;
  shouldGoToInkassoTransactionsEnteringFlowFlg: boolean;
  smsFeeAmount: number;
  statusCd: string;
  sysCreatedUserId: number;
  title = '';
  weekdayToSendNotification: number;
  flowCriteriaList: McProcessFlowCriteriaList;  levels: McProcessFlowLevelList;

  public properties: string[] = ['id', 'acceptedTransactionsEnteringFlowFlg', 'companyInterestRateTypeCd', 'description', 'dunningNoteBaseFeeAmount', 'emailFeeAmount', 'forDunningTransactionsEnteringFlowFlg', 'groupByConsumerFlg', 'idMcCurrency', 'idMcProcess', 'inheritableFlg', 'interestRateForCompanyAmount', 'interestRateForPersonAmount', 'newStatusForTransactionsAfterLastDunningLevel', 'personInterestRateTypeCd', 'postFeeAmount', 'returnedTransactionsEnteringFlowFlg', 'sendFlowToInkassoFlg', 'sendFlowToInkassoTransactionTypeCd', 'sendingToInkassoTransactionsEnteringFlowFlg', 'shouldGoToInkassoTransactionsEnteringFlowFlg', 'smsFeeAmount', 'statusCd', 'sysCreatedUserId', 'title', 'weekdayToSendNotification', 'flowCriteriaList', 'levels'];
  public propertiesRegular: string[] = ['id', 'acceptedTransactionsEnteringFlowFlg', 'companyInterestRateTypeCd', 'description', 'dunningNoteBaseFeeAmount', 'emailFeeAmount', 'forDunningTransactionsEnteringFlowFlg', 'groupByConsumerFlg', 'idMcCurrency', 'idMcProcess', 'inheritableFlg', 'interestRateForCompanyAmount', 'interestRateForPersonAmount', 'mcCurrency', 'mcProcess', 'newStatusForTransactionsAfterLastDunningLevel', 'personInterestRateTypeCd', 'postFeeAmount', 'returnedTransactionsEnteringFlowFlg', 'sendFlowToInkassoFlg', 'sendFlowToInkassoTransactionTypeCd', 'sendingToInkassoTransactionsEnteringFlowFlg', 'shouldGoToInkassoTransactionsEnteringFlowFlg', 'smsFeeAmount', 'statusCd', 'sysCreatedUserId', 'title', 'weekdayToSendNotification'];
  public propertiesSpecial: string[] = ['flowCriteriaList', 'levels'];



  public static getCompanyInterestRateTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowGENERATED.COMPANY_INTEREST_RATE_TYPE_CD_DEFAULT, McGod.t('Default'));
    list.add(McProcessFlowGENERATED.COMPANY_INTEREST_RATE_TYPE_CD_ENTER, McGod.t('Enter'));


    return list;
  }

  public static getCompanyInterestRateTypeCdLabel(aCd: string): string {
    const list = McProcessFlowGENERATED.getCompanyInterestRateTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getNewStatusForTransactionsAfterLastDunningLevelVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowGENERATED.NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_REMAIN_IN_CURRENT_STATUS, McGod.t('Remain in current status'));
    list.add(McProcessFlowGENERATED.NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_SHOULD_GO_TO_INKASSO, McGod.t('Should go to Inkasso'));
    list.add(McProcessFlowGENERATED.NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_SENDING_TO_INKASSO, McGod.t('Sending to Inkasso'));


    return list;
  }

  public static getNewStatusForTransactionsAfterLastDunningLevelLabel(aCd: string): string {
    const list = McProcessFlowGENERATED.getNewStatusForTransactionsAfterLastDunningLevelVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getPersonInterestRateTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowGENERATED.PERSON_INTEREST_RATE_TYPE_CD_DEFAULT, McGod.t('Default'));
    list.add(McProcessFlowGENERATED.PERSON_INTEREST_RATE_TYPE_CD_ENTER, McGod.t('Enter'));


    return list;
  }

  public static getPersonInterestRateTypeCdLabel(aCd: string): string {
    const list = McProcessFlowGENERATED.getPersonInterestRateTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getSendFlowToInkassoTransactionTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowGENERATED.SEND_FLOW_TO_INKASSO_TRANSACTION_TYPE_CD_ALL_TRANSACTIONS, McGod.t('All transactions'));
    list.add(McProcessFlowGENERATED.SEND_FLOW_TO_INKASSO_TRANSACTION_TYPE_CD_EXPIRED_TRANSACTIONS, McGod.t('Expired transactions'));


    return list;
  }

  public static getSendFlowToInkassoTransactionTypeCdLabel(aCd: string): string {
    const list = McProcessFlowGENERATED.getSendFlowToInkassoTransactionTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowGENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(McProcessFlowGENERATED.STATUS_CD_INACTIVE, McGod.t('Inactive'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McProcessFlowGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McProcessFlow {
    console.error('MTCN-ERROR: Not Implemented: McProcessFlow::createNewInstance(). Add this method to final class and return new McProcessFlow();');
    throw new Error('Cannot McProcessFlow::createNewInstance(). Add this method to final class and return new McProcessFlow();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcProcessFlow): McProcessFlow {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessFlowService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McProcessFlow AABB()');
          if (this._rawJson['flowCriteriaList']) {
        this.flowCriteriaList = McProcessFlowCriteriaList.createFromJson(this._rawJson['flowCriteriaList']);
      }
      if (this._rawJson['levels']) {
        this.levels = McProcessFlowLevelList.createFromJson(this._rawJson['levels']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getDunningNoteBaseFeeAmountStr(): string {
       return McGod.formatNumberToMoney(this.dunningNoteBaseFeeAmount);
    }

    public getEmailFeeAmountStr(): string {
       return McGod.formatNumberToMoney(this.emailFeeAmount);
    }

    public getInterestRateForCompanyAmountStr(): string {
       return McGod.formatNumberToMoney(this.interestRateForCompanyAmount);
    }

    public getInterestRateForPersonAmountStr(): string {
       return McGod.formatNumberToMoney(this.interestRateForPersonAmount);
    }

    public getPostFeeAmountStr(): string {
       return McGod.formatNumberToMoney(this.postFeeAmount);
    }

    public getSmsFeeAmountStr(): string {
       return McGod.formatNumberToMoney(this.smsFeeAmount);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('flowCriteriaList')) {
        if (this.flowCriteriaList != null) {
            dto['flowCriteriaList'] = this.flowCriteriaList.toDto();
        } else {
            dto['flowCriteriaList'] = [];
        }
    }
    if (this.hasOwnProperty('levels')) {
        if (this.levels != null) {
            dto['levels'] = this.levels.toDto();
        } else {
            dto['levels'] = [];
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getCompanyInterestRateTypeCdLabel(): string {
    return McProcessFlowGENERATED.getCompanyInterestRateTypeCdLabel(this.companyInterestRateTypeCd);
  }


 public  isCompanyInterestRateTypeCdDEFAULT(): boolean {
    const result = (this.companyInterestRateTypeCd === McProcessFlowGENERATED.COMPANY_INTEREST_RATE_TYPE_CD_DEFAULT);

    return result;
  }

 public  isCompanyInterestRateTypeCdENTER(): boolean {
    const result = (this.companyInterestRateTypeCd === McProcessFlowGENERATED.COMPANY_INTEREST_RATE_TYPE_CD_ENTER);

    return result;
  }


  public getNewStatusForTransactionsAfterLastDunningLevelLabel(): string {
    return McProcessFlowGENERATED.getNewStatusForTransactionsAfterLastDunningLevelLabel(this.newStatusForTransactionsAfterLastDunningLevel);
  }


 public  isNewStatusForTransactionsAfterLastDunningLevelREMAIN_IN_CURRENT_STATUS(): boolean {
    const result = (this.newStatusForTransactionsAfterLastDunningLevel === McProcessFlowGENERATED.NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_REMAIN_IN_CURRENT_STATUS);

    return result;
  }

 public  isNewStatusForTransactionsAfterLastDunningLevelSHOULD_GO_TO_INKASSO(): boolean {
    const result = (this.newStatusForTransactionsAfterLastDunningLevel === McProcessFlowGENERATED.NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_SHOULD_GO_TO_INKASSO);

    return result;
  }

 public  isNewStatusForTransactionsAfterLastDunningLevelSENDING_TO_INKASSO(): boolean {
    const result = (this.newStatusForTransactionsAfterLastDunningLevel === McProcessFlowGENERATED.NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_SENDING_TO_INKASSO);

    return result;
  }


  public getPersonInterestRateTypeCdLabel(): string {
    return McProcessFlowGENERATED.getPersonInterestRateTypeCdLabel(this.personInterestRateTypeCd);
  }


 public  isPersonInterestRateTypeCdDEFAULT(): boolean {
    const result = (this.personInterestRateTypeCd === McProcessFlowGENERATED.PERSON_INTEREST_RATE_TYPE_CD_DEFAULT);

    return result;
  }

 public  isPersonInterestRateTypeCdENTER(): boolean {
    const result = (this.personInterestRateTypeCd === McProcessFlowGENERATED.PERSON_INTEREST_RATE_TYPE_CD_ENTER);

    return result;
  }


  public getSendFlowToInkassoTransactionTypeCdLabel(): string {
    return McProcessFlowGENERATED.getSendFlowToInkassoTransactionTypeCdLabel(this.sendFlowToInkassoTransactionTypeCd);
  }


 public  isSendFlowToInkassoTransactionTypeCdALL_TRANSACTIONS(): boolean {
    const result = (this.sendFlowToInkassoTransactionTypeCd === McProcessFlowGENERATED.SEND_FLOW_TO_INKASSO_TRANSACTION_TYPE_CD_ALL_TRANSACTIONS);

    return result;
  }

 public  isSendFlowToInkassoTransactionTypeCdEXPIRED_TRANSACTIONS(): boolean {
    const result = (this.sendFlowToInkassoTransactionTypeCd === McProcessFlowGENERATED.SEND_FLOW_TO_INKASSO_TRANSACTION_TYPE_CD_EXPIRED_TRANSACTIONS);

    return result;
  }


  public getStatusCdLabel(): string {
    return McProcessFlowGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === McProcessFlowGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdINACTIVE(): boolean {
    const result = (this.statusCd === McProcessFlowGENERATED.STATUS_CD_INACTIVE);

    return result;
  }


  
}
