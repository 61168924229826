import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {McEntity2, McForm, McGod} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-transactions-pause-days-part',
  templateUrl: './app-shared-transactions-pause-days-part.component.html',
})
export class AppSharedTransactionsPauseDaysPartComponent implements OnInit {
  mcForm = new McForm();
  loggedEntity = new McEntity2();
  maxTransactionPauseDays!: number;
  loadingFlg = false;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() pauseDays = new EventEmitter<number>();

  ngOnInit() {
    this.loadingFlg = true;
    this.loggedEntity.loadById(McGod.getLoggedEntityIdFromToken(), () => {
      this.maxTransactionPauseDays = this.loggedEntity.maxTransactionPauseDays;
      this.loadingFlg = false;
    });
  }

  actSave() {
    if (this.mcForm.isValid()) {
      this.loggedEntity.maxTransactionPauseDays = this.mcForm.getValue('ctrlMaximalPauseDays');
      this.loadingFlg = true;
      this.loggedEntity.save(() => {
        this.loadingFlg = false;
        this.eventSaved.emit(this.loggedEntity.maxTransactionPauseDays);
      });
    }
  }

  actCancel() {
    this.eventCanceled.emit();
  }
}
