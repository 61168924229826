/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBillingPayoutFileItemFeCtd} from '../models/mc-billing-payout-file-item-fe-ctd.model';
import {McBillingPayoutFileItemFeCtdService} from '../services/mc-billing-payout-file-item-fe-ctd.service';
import {IMcBillingPayoutFileItemFeCtd} from '../_generated/mc-billing-payout-file-item-fe-ctd.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';




export abstract class McBillingPayoutFileItemFeCtdGENERATED extends BaseObject {











  public apiService: McBillingPayoutFileItemFeCtdService;
  public _rawJson: IMcBillingPayoutFileItemFeCtd;
    id: number = 0;
  amount: number;
  bic = '';
  description = '';
  entityName = '';
  iban = '';
  idMcBillingPayoutFile: number;
  idMcBillingStatement: number;
  idMcEntity: number;
  studioOwner = '';


  public properties: string[] = ['id', 'amount', 'bic', 'description', 'entityName', 'iban', 'idMcBillingPayoutFile', 'idMcBillingStatement', 'idMcEntity', 'studioOwner'];
  public propertiesRegular: string[] = ['id', 'amount', 'bic', 'description', 'entityName', 'iban', 'idMcBillingPayoutFile', 'idMcBillingStatement', 'idMcEntity', 'studioOwner'];
  public propertiesSpecial: string[] = [];











  // ---------------------------------------------------------------------
public static createNewInstance(): McBillingPayoutFileItemFeCtd {
    console.error('MTCN-ERROR: Not Implemented: McBillingPayoutFileItemFeCtd::createNewInstance(). Add this method to final class and return new McBillingPayoutFileItemFeCtd();');
    throw new Error('Cannot McBillingPayoutFileItemFeCtd::createNewInstance(). Add this method to final class and return new McBillingPayoutFileItemFeCtd();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBillingPayoutFileItemFeCtd): McBillingPayoutFileItemFeCtd {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingPayoutFileItemFeCtdService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBillingPayoutFileItemFeCtd AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getAmountStr(): string {
       return McGod.formatNumberToMoney(this.amount);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  










  
}
