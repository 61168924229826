/*BbmTestObj*/
import {EbPaymentReport2SepaFileTransactionGENERATED} from '../_generated/eb-payment-report-2-sepa-file-transaction-generated.model';
import {McHtmlActionSet} from '../_core/mc-html-action-set';
import {McGod} from '../extra/mc-god.service';
import {ActionOptions} from '../_core/mc-html-action-options';

export class EbPaymentReport2SepaFileTransaction extends EbPaymentReport2SepaFileTransactionGENERATED {

  public static ACTION_VIEW_DETAILS = 'VIEW_DETAILS';
  public static ACTION_VIEW_CONSUMER_PROFILE = 'VIEW_CONSUMER_PROFILE';
  public static ACTION_MATCH = 'MATCH';

  entityName!: string;
  consumer!: string;
  description!: string;
  importDateAndTime!: string;
  paymentDueDate!: string;
  requestCollectionDate!: string;
  amountDue!: string;
  status!: string;

  private _fldActions: McHtmlActionSet | null = null;



  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbPaymentReport2SepaFileTransaction();
  }

  // ---------------------------------------------------------------------
  public fldActions() {
    if (!this._fldActions) {
      const actionSet = new McHtmlActionSet();
      actionSet.addActionB(this.t('cc.my-factoring.view-details'), EbPaymentReport2SepaFileTransaction.ACTION_VIEW_DETAILS, this, [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_VIEW], '', ActionOptions.OPTION_VIEW);
      actionSet.addActionB(this.t('cc.common.view-consumer-profile'), EbPaymentReport2SepaFileTransaction.ACTION_VIEW_CONSUMER_PROFILE, this, [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_VIEW], '', ActionOptions.OPTION_VIEW);
      actionSet.addActionB(this.t('cc.incoming-payments.match'), EbPaymentReport2SepaFileTransaction.ACTION_MATCH, this, [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_VIEW], '', ActionOptions.OPTION_EDIT);
      this._fldActions = actionSet;
    }
    return this._fldActions;
  }


}
