import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CsrContractService} from '@miticon-ui/mc-core';
import {ActivatedRoute, Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'mc-consumer-confirmation-contract-code',
  templateUrl: './mc-consumer-confirmation-contract-code.component.html',
  styleUrls: ['./mc-consumer-confirmation-contract-code.component.scss']
})
export class McConsumerConfirmationContractCodeComponent implements OnInit {
  confirmationCodeForm!: FormGroup;
  csrContractId!: number;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private csrContractService: CsrContractService, private toastr: ToastrService) { }

  ngOnInit() {
    this.csrContractId = this.route.snapshot.params['id'];
    this.confirmationCodeForm = this.fb.group({
      codeNumber1: [''],
      codeNumber2: [''],
      codeNumber3: [''],
      codeNumber4: [''],
      codeNumber5: [''],
      codeNumber6: [''],
    });
  }

  validateConfirmationCode() {
    const codeNumber1 = this.confirmationCodeForm?.get('codeNumber1')?.value;
    const codeNumber2 = this.confirmationCodeForm.get('codeNumber2')?.value;
    const codeNumber3 = this.confirmationCodeForm.get('codeNumber3')?.value;
    const codeNumber4 = this.confirmationCodeForm.get('codeNumber4')?.value;
    const codeNumber5 = this.confirmationCodeForm.get('codeNumber5')?.value;
    const codeNumber6 = this.confirmationCodeForm.get('codeNumber6')?.value;

    const code = `${codeNumber1}${codeNumber2}${codeNumber3}${codeNumber4}${codeNumber5}${codeNumber6}`;

    this.csrContractService.validateConfirmationCode({confirmationCode: code, contractId: this.csrContractId})
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.router.navigate([`/consumer/confirmation/contract/${this.csrContractId}`],  { queryParams: { securityCode: code } });
      },
        () => {
          this.toastr.error('Your confirmation code has expired or is not correct.');
        });
  }
}
