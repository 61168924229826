<section class="mc-management-area" #myIdentifier>

  <div class="row">
    <div class="col-md-12">
      <h3 class="page-title d-inline">{{managementModuleDisplayName | translate}}</h3>
      <div class="mc-error">
        <mc-notify-message [message]="errorObject.message" [type]="errorObject.type"></mc-notify-message>
        <mc-notify-message *ngIf="successMessage" [message]="successMessage" type="success"></mc-notify-message>
      </div>
    </div>
  </div>

  <!-- Users area-->
  <div class="row" [ngClass]="managementAreaStyle ? 'mc-users-area': ''">

    <div class="actions-container" style='width: 100%; margin: 0 15px 15px 15px;' *ngIf="managementModule === 'entity'"  McPermission [hasPermissions]="userPermission" [page]="managementModule">
      <span>{{ "cc.entities.entities-list" | translate }}</span>
      <div [ngClass]="managementAreaStyle ? 'mc-add-new-user': ''" >
        <mc-create-entity-wrapper (updateEntity)="onSuccessEditCreate($event)"></mc-create-entity-wrapper>
      </div>
    </div>

    <!--Create new user-->
    <div class="actions-container" style='width: 100%; margin: 0 15px 15px 15px;' *ngIf="managementModule === 'user'" McPermission [hasPermissions]="userPermission" [page]="managementModule">
      <span>{{ "cc.users.users-list" | translate }}</span>
      <div [ngClass]="managementAreaStyle ? 'mc-add-new-user': ''">
        <lib-mc-create-user-wrapper (updateUserEvent)="onSuccessEditCreate($event)"></lib-mc-create-user-wrapper>
      </div>
    </div>


    <!--Create new product-->
    <div class="actions-container" style='width: 100%; margin: 0 15px 15px 15px;' *ngIf="managementModule === 'product'" McPermission [hasPermissions]="userPermission" [page]="managementModule">
      <span>{{ "cc.products.products-list" | translate }}</span>
    </div>


    <!--Create role modal-->
    <div *ngIf="managementModule === 'role'" class="actions-container" style='width: 100%; margin: 0 15px 15px 15px;'
         McPermission [hasPermissions]="userPermission" [page]="managementModule">
      <span>{{   "cc.roles.roles-list" | translate }}</span>
      <div style='display: flex'>
        <a *ngIf="mcGod.userHasMcRoleTemplateCreateEditPermission()" [ngbTooltip]="isRootParent ? mcGod.t('cc.roles.you-have-no-role-templates-available-to-create-a-role-from') : ''"
             class="system-action-btn mr-2" [ngClass]="{'system-action-btn-disabled': isRootParent}" id="newUserModalButton" (click)="actOpenCreateRoleFromTemplModal()">
            <i class="far fa-sticky-note"></i>
            {{'cc.roles.create-role-from-template' | translate}}
          </a>

        <mcc-modal1-wrapper *ngIf="showCreateRoleFromTemplateMcb.value" [showMcb]="showCreateRoleFromTemplateMcb" modalSizeCd="small">
          <app-shared-roles-create-role-from-template (eventSaved)="onCreateRoleFromTemplate()"
                                                      [roleTemplates]="roleTemplates"
                                                      (eventCanceled)="onCancelCreateRoleFromTemplate()"></app-shared-roles-create-role-from-template>
        </mcc-modal1-wrapper>
        <mc-role-create-wrapper [selectedEntity]="selectedEntityPath[selectedEntityPath.length - 1]" (updateTableContent)="getTableContent()"></mc-role-create-wrapper>

      </div>
    </div>


    <!--Create consumers-->
    <div class="actions-container" style='width: 100%; margin: 0 15px 15px 15px;' *ngIf="managementModule === 'consumers'" McPermission [hasPermissions]="userPermission" [page]="managementModule">
      <span>{{ "cc.common.consumers-list" | translate }}</span>
      <div [ngClass]="managementAreaStyle ? 'mc-add-new-user' : ''">
        <lib-mc-import-consumers (updateTable)="getTableContent()" class="mr-1"></lib-mc-import-consumers>
        <lib-mc-consumers-add (updateConsumerEvent)="onSuccessEditCreate($event)"></lib-mc-consumers-add>
      </div>
    </div>

    <!--Filter component-->
    <div class="col-md-12">
      <mc-filter [name]="managementModule"
                 [filterObject]="filterObject"></mc-filter>
    </div>


    <!--Table component-->
    <div class="col-md-12 mc-management-table">
      <mc-table *ngIf="isTableContentLoaded"
                [tableContent]="tableContent"
                [entityId]="entityId"
                (paginationEvent)="onPagination($event)"
                (selectedRowsEvent)="onSelectedRows($event)"></mc-table>
    </div>
  </div>

  <mcc-modal1-wrapper *ngIf="showResendUserMailFlg" [showMcb]="resendUserMailMcb" modalSizeCd="small" (eventCanceled)="onResendEmailCancel()">
    <mc-user-user-resend-email [user]="selectedUser" (cancelEvent)="onResendEmailCancel()" (onResendEmailEvent)="onResendEmail($event)"></mc-user-user-resend-email>
  </mcc-modal1-wrapper>
  <!--Popup wrapper-->
 <!-- <div class="mc-popup-wrapper" [ngStyle]="setPopupWidth()" [ngClass]="showPopup()">
    <div class="col-md-12 w-100" *ngIf="currentSelectedRows.length > 0">
      <div class="mc-user-popup-wrapper text-center">

        <mc-user-popup-buttons *ngIf="managementModule === 'user'"></mc-user-popup-buttons>

        <mc-role-popup-buttons *ngIf="managementModule === 'role'"></mc-role-popup-buttons>

        <mc-entity-popup-buttons *ngIf="managementModule === 'entity'"></mc-entity-popup-buttons>

        <mc-entity-popup-buttons *ngIf="managementModule === 'consumers'"></mc-entity-popup-buttons>
      </div>
    </div>

  </div>-->


</section>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>



