<!-- mcc-fi-table-2 -->
<div class="mc-table-wrapper mt-3 mb-5 pt-1" *ngIf="objectList.apiLoadingFlg">
  <img src="assets/images/loading-whitebg.gif" />
</div>
<div class="mc-table-wrapper mt-3 mb-5 pt-1" *ngIf="objectList.apiSuccessFlg">
  <div class="w-100 py-1">
    <!--Total numbers wrapper-->
    <div class="mc-table-total-numbers-wrapper ml-5 py-1">
      <!--Total displayed number-->
      <div class="d-inline px-3">
        <span class="text-capitalize font-italic pr-1"
          >{{ "cc.common.total-displayed-elements" | translate }}:</span
        >
        <span
          class="px-1"
          McDetectChanges
          [elementHtmlValue]="objectList.items.length"
          >{{ objectList.items.length }}</span
        >
      </div>

      <!--Table configuration button-->
      <div class="d-inline px-3">
        <mc-table-configuration-modal
          [tableConfig]="table2Config"
        ></mc-table-configuration-modal>
      </div>
    </div>
    <!--Total numbers wrapper /end-->

    <!--Mc table-->
    <table class="table table-striped mc-table">
      <!--Mc table header-->
      <thead class="mc-table-head">
        <tr>
          <!--Checkbox for select all-->
          <th
            class="text-center"
            *ngIf="table2Config.allowRowSelectionWithCheckboxesFlg"
          >
            <label class="custom-control fill-checkbox">
              <input
                type="checkbox"
                id="selectRows"
                class="fill-control-input"
                (click)="objectList.toggleSelectAll(); onSelectItem()"
                [checked]="objectList.isAllSelected()"
              />
              <span class="fill-control-indicator"></span>
            </label>
            <!-- <div ngbDropdown class="d-inline-block ml-4">
            <span class="" id="dropdownBasic" ngbDropdownToggle><i class="fas fa-ellipsis-v"></i></span>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic">
              <button ngbDropdownItem>View</button>
              <button ngbDropdownItem>Edit</button>
              <button ngbDropdownItem>Copy</button>
              <button ngbDropdownItem>Delete</button>
            </div>
          </div>-->
          </th>

          <!--Drag&Drop-->
          <th *ngIf="table2Config.allowDragDropRowsFlg">
            <span></span>
          </th>

          <th *ngFor="let item of filteredItems()" class="mc-table-head-item">
            <div class="mc-arrow-wrapper" *ngIf="item.sortFlag">
              <i
                class="fa fa-chevron-up mc-arrow-up-icon"
                (click)="onSortColumn(item.objectPropertyOrMethodName, 'asc')"
              ></i>
              <i
                class="fa fa-chevron-down mc-arrow-down-icon"
                (click)="onSortColumn(item.objectPropertyOrMethodName, 'desc')"
              ></i>
            </div>
            <!--header labels-->
            <span class="capitalize">{{ item.label }}</span>
          </th>
        </tr>
      </thead>
      <!--MC table head ./end-->

      <!--Mc table body-->
      <tbody class="mc-table-body">
        <tr
          *ngFor="
            let tableContentObjectItem of objectList.items;
            let contentIndex = index
          "
          class="mc-unselectable-content-on-press-shift"
          [ngClass]="
            tableContentObjectItem.isSelected() ? 'mc-selected-row' : ''
          "
        >
          <!--Select row item-->
          <td
            class="text-center mc-checkbox-td"
            *ngIf="table2Config.allowRowSelectionWithCheckboxesFlg"
          >
            <label class="custom-control fill-checkbox">
              <input
                (click)="
                  tableContentObjectItem.toggleSelected();
                  onSelectItem($event, contentIndex)
                "
                type="checkbox"
                [id]="tableContentObjectItem.id"
                class="fill-control-input"
                [checked]="tableContentObjectItem.isSelected()"
              />
              <span class="fill-control-indicator"></span>
            </label>
            <!--<div ngbDropdown class="d-inline-block ml-4">
            <span class="" id="dropdownBasic{{contentIndex}}" ngbDropdownToggle><i class="fas fa-ellipsis-v"></i></span>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem>View</button>
              <button ngbDropdownItem>Edit</button>
              <button ngbDropdownItem>Copy</button>
              <button ngbDropdownItem>Delete</button>
            </div>
          </div>-->
          </td>

          <!--Drag&Drop-->
          <!--     <td class="text-center mc-checkbox-td"
                 *ngIf="setup['isDragAndDrop']"
             >
               <div class="mc-drag-and-drop" cdkDragHandle>
                 <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                   <path
                     d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                   <path d="M0 0h24v24H0z" fill="none"></path>
                 </svg>
               </div>
             </td>
       -->
          <!--Table content-->
          <td
            *ngFor="let colConfig of filteredItems()"
            [ngClass]="colConfig.extraCssClass"
            (click)="onCellClick(colConfig, tableContentObjectItem)"
          >
            <mcc-html
              *ngIf="!colConfig.editComponentClass"
              [values]="
                getCellValueFromObject(
                  colConfig.objectPropertyOrMethodName,
                  tableContentObjectItem
                )
              "
            ></mcc-html>

            <!--Render component-->
            <span *ngIf="colConfig.editComponentClass">
              <mcc-dynamic-component-generator
                [dynamicComponentClass]="colConfig.editComponentClass"
                [dynamicComponentConfigObject]="
                  getDynamicComponentConfigObject(
                    colConfig,
                    tableContentObjectItem
                  )
                "
              ></mcc-dynamic-component-generator>
            </span>
          </td>
        </tr>
        <!--Table body content /end-->
      </tbody>
      <!--MC table body ./end-->
    </table>
  </div>
</div>
