<!--Role info wrapper-->
<div class="mc-role-edit-wrapper p-5">

  <!--Column wrapper-->
  <div class="col-md-12" *ngIf="roleInfo">

    <lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>


    <!--Role title-->
    <div class="row">
      <div class="col-md-12 text-center mc-edit-role-title">

        <p class="h4">
          <!--Entity name-->
          <span class="mc-active border-0 text-uppercase">
            {{entityName}}
          </span>

          <!--Role name-->
          <span>
            {{roleInfo['caption']}}
          </span>
        </p>
      </div>
    </div>

    <div class="row mb-5 mt-2">
      <div class="col-md-12">
        <mc-notify-message [message]="message" [type]="type"></mc-notify-message>
      </div>
    </div>

    <!--Basic info-->
    <div class="row pl-0 pb-3 mt-3 border-bottom">

      <div class="col-md-8 pl-0">




        <!--Title-->
        <p class=" mc-active h5 pb-3">{{ 'cc.common.view.basic-info' | translate }} <mc-edit-role-basic-info-modal [isRoleLinked]="roleInfo['linkedFlg']" [editPermissions]="false" [basicInfo]="roleInfo" (onUpdaetRole)="$event && getRole()"></mc-edit-role-basic-info-modal></p>

        <!--Status-->
        <div class="row">
          <div class="col-md-2">
            <p class="mc-property-title mb-2">status:</p>
          </div>
          <div class="col-md-10">
            <mc-status [status]="roleInfo['statusCode'] ? roleInfo['statusCode'].toLowerCase() : ''"></mc-status>
          </div>
        </div>

        <!--Name-->
        <div class="row">
          <div class="col-md-2">
            <p class="mc-property-title mb-2">name:</p>
          </div>
          <div class="col-md-10">
            <!--Entity name-->
            <span class="mc-active border-0 text-uppercase">
            {{entityName}}
            </span>

            <!--Role name-->
            <span>
            {{roleInfo['caption']}}
          </span>
          </div>
        </div>

        <!--Description-->
        <div class="row">
          <div class="col-md-2">
            <p class="mc-property-title mb-2">{{ 'cc.common.view.description' | translate }}:</p>
          </div>
          <div class="col-md-10"> {{roleInfo['description']}}</div>
        </div>

        <!--Set as template-->
        <ng-container *ngIf="mcGod.userHasMcRoleTemplateCreateEditPermission()">
          <div class="row">
            <div class="col-md-2">
              <p class="mc-property-title mb-2">{{ 'cc.roles.set-as-template' | translate }}:</p>
            </div>
            <div class="col-md-10"> {{roleInfo['templateFlg'] ? mcGod.t('cc.common.edit.yes') : mcGod.t('cc.common.edit.no')}}</div>
          </div>
        </ng-container>

        <!--Set as template-->
        <ng-container *ngIf="mcGod.userHasMcRoleTemplateCreateEditPermission() && roleInfo['templateFlg']">
          <div class="row">
            <div class="col-md-2">
              <p class="mc-property-title mb-2">Number of roles created from this template:</p>
            </div>
            <div class="col-md-10"> {{roleInfo['numberOfChildren']}}</div>
          </div>
        </ng-container>

        <!--Template label-->
        <ng-container *ngIf="mcGod.userHasMcRoleTemplateCreateEditPermission() && this.roleInfo['ancestorId']">
          <div class="row">
            <div class="col-md-2">
              <p class="mc-property-title mb-2">{{'cc.roles.template' | translate}}:</p>
            </div>
            <div class="col-md-10" style="display: inherit;">
              {{entityOriginName}} | {{roleTemplateName}} ({{getLinkStatus()}})
            <span *ngIf="roleInfo['linkedFlg']" class="ml-2" style="display: flex; flex-direction: row; cursor: pointer" (click)="linkUnlinkRole()"><img class="mr-2" style="align-self: baseline;" src="assets/images/role-icons/linked.svg"><p class="mc-submit-color"> {{'cc.roles.unlink' | translate}}</p></span>
            <span *ngIf="!roleInfo['linkedFlg']" class="ml-2" style="display: flex; flex-direction: row; cursor: pointer" (click)="linkUnlinkRole()"><img class="mr-2" style="align-self: baseline;" src="assets/images/role-icons/unlinked.svg"><p class="mc-submit-color"> {{'cc.roles.link' | translate}}</p></span>
            </div>
          </div>

          <mcc-dialog-box *ngIf="showLinkUnlinkDialogMcb.value" [enableCloseButtonFlg]="false"
                          [title]="roleInfo['linkedFlg'] ? mcGod.t('cc.roles.unlink-role') : mcGod.t('cc.roles.link-role')" name="link-unlink-role"
                          [description]="getLinkDialogDescription()"
                          [buttonsKvm]="linkDialogButtonsKvm"
                          popupType="question"
                          (eventActionButtonClicked)="onMcDialogBoxLinkRoleActionButtonClicked($event)"></mcc-dialog-box>
        </ng-container>


      </div>
    </div><!--Basic info /end-->

    <!--Permissions-->
    <div class="row mt-3 pl-0">
      <div class="col-md-12 pl-0">

        <!--Title-->
        <p class="text-capitalize mc-active h5 pb-3">{{'cc.roles.view.permissions' | translate}} <mc-edit-role-basic-info-modal [isRoleLinked]="roleInfo['linkedFlg']" [editPermissions]="true" [basicInfo]="roleInfo" (onUpdaetRole)="$event && getRole()"></mc-edit-role-basic-info-modal></p>

        <!--Permissions list-->
        <ul class="list-unstyled">
          <li *ngFor="let permission of roleInfo['permissionList']">
            <p class="text-lowercase-role">
              <span >{{permission['description']}}</span>
            </p>

          </li>
        </ul>
      </div>
    </div><!--Permissions /end-->
  </div>
</div>
