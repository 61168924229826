import {EbFactoringRequestTypeFilterGENERATED} from '../_generated/eb-factoring-request-type-filter-generated.model';

export class EbFactoringRequestTypeFilter extends EbFactoringRequestTypeFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbFactoringRequestTypeFilter();
  }

  // ---------------------------------------------------------------------
}
