<h3 class="page-title">{{ 'cc.bank.sepa-statement-report' | translate }}</h3>

<div class="actions-container">
  <span>{{ 'cc.bank.sepa-statement-report-list' | translate }}</span>
  <div class="actions-wrapper">
    <button class="system-action-btn mr-2" (click)="showImportStarmoneyMcb.setTrue()"><i class="fas fa-file-export"></i>{{'cc.bank.import-sepa-statement-file' | translate}}
    </button>
   <!-- <button class="system-action-btn"><i class="fa fa-bar-chart"></i>{{'cc.bank.view-statistics' | translate}}
    </button>-->
  </div>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="ebSepaStatementReportList.items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  [totalItemsCount]="ebSepaStatementReportList.totalItemsCount"
  [itemsPerPage]="ebSepaStatementReportList.pageItemsPerPageCount"
  (filterChanged)="onFilterChanged($event)"
  (actionMkMatMenuItems)="onMkMatMenuItemsAction($event)"
  [mkMatMenuActionItems]="mkMatMenuActionItems"
  [searchTooltip]="'cc.bank.search-by-filename' | translate"
>
</mk-mat-table>

<mcc-modal1-wrapper modalSizeCd="small" [showMcb]="showImportStarmoneyMcb" *ngIf="showImportStarmoneyMcb.value">
  <mc-bank-module-import-star-money-part (eventSaved)="actStarMoneyImported()" (eventCanceled)="showImportStarmoneyMcb.setFalse()"></mc-bank-module-import-star-money-part>
</mcc-modal1-wrapper>
