/*BbmTestObj*/
import {McBillingProcessedTransactionsInfoTableGENERATED} from '../_generated/mc-billing-processed-transactions-info-table-generated.model';

export class McBillingProcessedTransactionsInfoTable extends McBillingProcessedTransactionsInfoTableGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingProcessedTransactionsInfoTable();
  }

  // ---------------------------------------------------------------------
}
