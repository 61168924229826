/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McBillingServiceCatalogFilter} from '../models/mc-billing-service-catalog-filter.model';

export class McBillingServiceCatalogFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingServiceCatalogFilter {
    console.error('MTCN-ERROR: Not Implemented: McBillingServiceCatalogFilter::createNewInstance(). Add this method to final class and return new McBillingServiceCatalogFilter();');
      throw new Error('Cannot McBillingServiceCatalogFilter::createNewInstance(). Add this method to final class and return new McBillingServiceCatalogFilter();');
  }

}
