import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";

@Component({
  selector: "mc-job-action",
  template: ` <div class="mc-job-action-wrapper">
    <span
      *ngFor="let action of actionList"
      class="mx-1 rounded-circle mc-action mc-action-hover"
      [ngClass]="cssClassToHandleActionStatus(action)"
      (click)="onChooseAction(action, cssClassToHandleActionStatus(action))"
    >
      <img
        src="{{ icons[action]['path'] }}"
        alt="{{ icons[action]['alt'] }}"
        class="mc-action-icon _{{ action }}"
      />
    </span>
  </div>`,
})
export class McJobActionComponent {
  /*Public variables*/
  public icons: any = {
    run: {},
    unschedule: {},
    resume: {},
    pause: {},
  };
  public actionList: any;

  /*Events*/
  @Input() activeActions: any;
  @Output() chosenAction = new EventEmitter<object>();

  /*Constructor*/
  constructor(
    @Inject("designConfig") design: any,
    @Inject("utilityConfig") utilityConfig: any
  ) {
    /*Get icons from design config*/
    this.icons = Object.assign({}, design.schedulerModule.actionIcons);

    /*Get action list from utility config*/
    this.actionList = utilityConfig.schedulerModule.actionList;
  }

  /*On choose action*/
  public onChooseAction(action: any, inactive: any): void {
    action &&
      inactive !== "_inactive" &&
      this.chosenAction.emit({
        actionName: action,
        id: this.activeActions.id,
      });
  }

  public cssClassToHandleActionStatus(action: any): string {
    return action &&
      this.activeActions.actions.find(
        (item: any) => item.toLowerCase() === action
      )
      ? `_${action}`
      : "_inactive";
  }
}
