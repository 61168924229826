/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {ImDataMappingFilter} from '../models/im-data-mapping-filter.model';

export class ImDataMappingFilterGENERATED extends BaseObjectFilter{    public searchTerm: string | undefined;
    public createdByUserId: number | undefined;

  public properties: string[] = ['searchTerm', 'createdByUserId', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): ImDataMappingFilter {
    console.error('MTCN-ERROR: Not Implemented: ImDataMappingFilter::createNewInstance(). Add this method to final class and return new ImDataMappingFilter();');
      throw new Error('Cannot ImDataMappingFilter::createNewInstance(). Add this method to final class and return new ImDataMappingFilter();');
  }

}
