import {EbSepaFileReportFilterGENERATED} from '../_generated/eb-sepa-file-report-filter-generated.model';

export class EbSepaFileReportFilter extends EbSepaFileReportFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaFileReportFilter();
  }

  // ---------------------------------------------------------------------
}
