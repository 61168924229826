/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CsrConsumer2CtdEmailFilter} from '../models/csr-consumer-2-ctd-email-filter.model';

export class CsrConsumer2CtdEmailFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrConsumer2CtdEmailFilter {
    console.error('MTCN-ERROR: Not Implemented: CsrConsumer2CtdEmailFilter::createNewInstance(). Add this method to final class and return new CsrConsumer2CtdEmailFilter();');
      throw new Error('Cannot CsrConsumer2CtdEmailFilter::createNewInstance(). Add this method to final class and return new CsrConsumer2CtdEmailFilter();');
  }

}
