<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcProcessRun.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h1 *ngIf="mcProcessRun.exists()">Edit {{mcProcessRun.fldRunNameId()}}</h1>
    <h1 *ngIf="!mcProcessRun.exists()">New Run</h1>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!mcProcessRun.apiLoadingFlg" class="content-body">

    <mcc-message [errorMsg]="errorMsg"></mcc-message>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container>

      <div class="row">
        <div class="col-md-12">
<!--          <div class="content-section">-->
<!--            <h2>New Run</h2> &lt;!&ndash; TODO: Had problems with checkboxes component, i will change this &ndash;&gt;-->
          <div class="run-wrap">
            <mcc-fi-checkbox [label]="'Check for new items'"
                             [name]="'chkNewItems'"
                             [value]="mcProcessRun.loadItemsFlg"
                             [mcForm]="mcForm"></mcc-fi-checkbox>
          <p>Run checks for new items.</p>
        </div>

          <div class="run-wrap">
            <mcc-fi-checkbox [label]="'Build items'"
                             [name]="'chkBuildItems'"
                             [value]="mcProcessRun.buildItemsFlg"
                             [mcForm]="mcForm" (eventValueChanged)="checkBuildItemsCbValueChanged($event)"></mcc-fi-checkbox>
            <p>Run will build items during the execution.</p>
          </div>

          <div class="run-wrap ml-4" [ngClass]="{'mc-disable' : !mcForm.getValue('chkBuildItems')}">
            <mcc-fi-checkbox [label]="'Deliver items'"
                             [name]="'chkDeliverItems'"
                             [value]="mcProcessRun.deliverItemsFlg"
                             [mcForm]="mcForm"></mcc-fi-checkbox>
            <p>Items will be delivered right upon building.</p>
        </div>

          <div class="run-wrap">
            <mcc-fi-checkbox [label]="'Start run immediately'"
                             [name]="'chkStartRun'"
                             [value]="false"
                             [mcForm]="mcForm"></mcc-fi-checkbox>
            <p>Run will start as soon as it is saved.</p>
          </div>
<!--          </div>-->
        </div>
      </div><!-- row -->
    </ng-container>

  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="Cancel"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actSave();" label="Save" [disabledMcb]="saveButtonDisabledMcb"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
