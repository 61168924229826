<!--
<div *ngIf="htmlTag=='div'" class="mcc-html {{extraCssClasses}}" [innerHTML]="value | SafeHtml"></div>
<p *ngIf="htmlTag=='p'" class="mcc-html {{extraCssClasses}}" [innerHTML]="value | SafeHtml"></p>
-->

  <ng-container *ngFor="let item of valuesArray">
    <ng-container *ngIf="isItemMcHtmlActionSetAndHasPermission(item)">
      <mcc-html-action-set [actionSet]="item" (eventButtonClicked)="actButtonPressedFromActionItemSet($event)"></mcc-html-action-set>
    </ng-container>
    <ng-container *ngIf="isItemMcHtmlButtonAndHasPermission(item)">
      <button (click)="actButtonPressedForItem(item)" class="{{item.extraCssClass}}">{{item.label}}</button>
    </ng-container>
    <ng-container *ngIf="isItemMcHtmlLinkAndHasPermission(item)">
      <a (click)="actGoToPage(item)" [innerHTML]="item.getInnerHtml()" class="{{item.extraCssClass}} mc-cursor-pointer"></a>
    </ng-container>
    <ng-container *ngIf="isItemString(item)">
      <span *ngIf="htmlTag=='span'" class="mcc-html {{extraCssClasses}}" [innerHTML]="cleanValue(item) | SafeHtml"></span>
      <div *ngIf="htmlTag=='div'" class="mcc-html {{extraCssClasses}}" [innerHTML]="cleanValue(item) | SafeHtml"></div>
      <p *ngIf="htmlTag=='p'" class="mcc-html {{extraCssClasses}}" [innerHTML]="cleanValue(item) | SafeHtml"></p>
      <div *ngIf="htmlTag=='_raw'" class="mcc-html {{extraCssClasses}}" [outerHTML]="cleanValue(item) | SafeHtml"></div>
    </ng-container>
    <ng-container *ngIf="isItemTooltip(item)">
      <!--    <span *ngIf="htmlTag=='span'" placement="top" [ngbTooltip]="item.tooltipTextHtml" [tooltipClass]="item.tooltipCssClass" class="mcc-html {{extraCssClasses}}" [innerHTML]="item.bodyTextHtml | SafeHtml"></span>-->
      <span *ngIf="htmlTag=='span'" data-toggle="tooltip" data-placement="top" title="{{item.tooltipTextHtml}}" class="mcc-html mc-cursor-pointer {{extraCssClasses}}" [innerHTML]="item.bodyTextHtml | SafeHtml"></span>
    </ng-container>
  </ng-container>


