<div class="row py-5">
  <div class="col-md-12">
    <div class="mc-notify-wrapper pb-3">
      <mc-notify-message *ngIf="responseMessage && factoringList.items.length === 0" [message]="message"
                         [type]="type"></mc-notify-message>
      <mc-notify-message *ngIf="successResponseMessage" [message]="message" [type]="type"></mc-notify-message>
    </div>
   <!-- <mcc-modal1-wrapper [showMcb]="showFactoringAddEditModalMcb" (eventCanceled)="onCancelAddEditModal()">
      <app-shared-factoring-add-edit-part [idChildEntity]="idChildEntity" [ebFactoring]="ebFactoring"
      [ebFactoringId]="addEditEbFactoringId" (eventSaved)="factoringContractSaved()" (eventCanceled)="onCancelAddEditModal()">
      </app-shared-factoring-add-edit-part>
    </mcc-modal1-wrapper>-->
    <div class="row">
      <div class="col-md-12">
        <lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
        <!--<a class="mc-entity-profile-title ml-2 mc-float-right" style="cursor: pointer;"
           (click)="openAddEditFactoringModal()" *ngIf="viewCreateButton">+ Create
          new factoring contract</a>-->
        <!--<div *ngIf="isLoggedEntity" class="mc-factoring-enable-buttons">
          <input id="enable" type="radio" name="factoringEnable"
                 [checked]="factoringEnableFlg" #factoringEnabled [value]=true
                 (click)="enableFactoring(factoringEnabled.checked)"><label for="enable" class="pr-3">Enable</label>
          <input id="disable" type="radio" [checked]="!factoringEnableFlg"
                 name="factoringEnable" #factoringDisabled (click)="disableFactoring(factoringDisabled.checked)"
                 [value]=false><label for="disable">Disable</label>
        </div>-->
        <div class="row" *ngIf="factoringEnableFlg">
          <div class="col-md-12">
            <p class="pt-4" *ngIf="!activeFactoring.exists()">Currently, there are no active factoring contracts.</p>
            <p class="pt-4" *ngIf="pendingFactoringList.items.length === 1">There is 1 factoring contract in pending
              acceptance status.</p>
            <p class="pt-4" *ngIf="pendingFactoringList.items.length > 1">There
              are {{pendingFactoringList.items.length}} factoring contracts in pending acceptance status.</p>
          </div>
        </div>
        <!--<div class="row pl-4" *ngIf="activeFactoring.exists() && factoringEnableFlg">
          <div class="col-md-12">
            <div class="row pt-3 pb-3">
              <div class="col-md-12 pb-4 pt-4">
                <h5>Currently active factoring contract conditions:</h5>
              </div>
            </div>
            <app-shared-factoring-profile-part [ebFactoring]="activeFactoring"></app-shared-factoring-profile-part>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</div>
<div class="mc-factoring-table mt-4 ml-n5">
  <h3 class="ml-3">Factoring Contracts</h3>
  <div *ngIf="factoringList.apiSuccessFlg">
    <mcc-fi-table-3 [objectList]="factoringList"
                    [table3Config]="ebFactoringTable3Config"
                    (eventPaginationOrSortChanged)="actLoad()"
                    (eventItemAction)="actFactoringTableItemAction($event)"></mcc-fi-table-3>
    <mcc-modal1-wrapper [showMcb]="showFactoringProfileModalMcb" *ngIf="showFactoringProfileModalMcb.value">
      <app-shared-factoring-profile-part [ebFactoring]="profileEbFactoring"
                                         [ebFactoringId]="profileEbFactoringId"></app-shared-factoring-profile-part>
    </mcc-modal1-wrapper>
  </div>
</div>
