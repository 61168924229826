import {CmnEmail} from './cmn-email.model';
import {CmnEmailListGENERATED} from '../_generated/cmn-email-list-generated.model';

export class CmnEmailList extends CmnEmailListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CmnEmailList();
  }

  // ---------------------------------------------------------------------
}
