<h1 class="page-title">{{'cc.countries.countries' | translate}}</h1>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  (filterChanged)="onFilterChanged($event)"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
  [searchTooltip]="searchTooltip"
></mk-mat-table>

