/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McCountryFilter} from '../models/mc-country-filter.model';

export class McCountryFilterGENERATED extends BaseObjectFilter{    public searchTerm: string | undefined;

  public properties: string[] = ['searchTerm', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McCountryFilter {
    console.error('MTCN-ERROR: Not Implemented: McCountryFilter::createNewInstance(). Add this method to final class and return new McCountryFilter();');
      throw new Error('Cannot McCountryFilter::createNewInstance(). Add this method to final class and return new McCountryFilter();');
  }

}
