<form [formGroup]="importForm">
  <div class="card">
    <h2 class="title-medium-dark mb-large">
      {{ "cc.consumers.consumers-overview.import-mode" | translate }}
    </h2>
    <div class='input-wrapper'>
      <mat-form-field appearance="outline">
        <mat-label>{{ "cc.consumers.consumers-overview.import-mode" | translate }}*</mat-label>
        <mat-select panelClass="dialog-select"
                    formControlName="importMode"
                    disableOptionCentering>
          <mat-option *ngFor="let importMode of importModes"
                      [value]="importMode.value">
            {{ importMode.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>
