import {Inject, Injectable} from '@angular/core';
import {McProductServiceGENERATED} from '../_generated/mc-product-generated.service';
import {Observable} from "rxjs";
import {HttpHeaders} from "@angular/common/http";



@Injectable({
  providedIn: 'root'
})
export class McProductService extends McProductServiceGENERATED {

  public getByType(type: string): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/types/${type}`);
  }
}
