import {allPermissions} from './all-permissions.config';

/* Page permissions
*
* Use * (star) instead of number in url path*/
export const pagePermissions =
  [
    /*Entity*/
    {
      path: '/admin/entity/*/management/entity',
      permission: allPermissions.MC_ENTITY_CAN_READ.name
    },
    {
      path: '/admin/entity/*/management/entity/edit/*',
      permission: allPermissions.MC_ENTITY_CAN_UPDATE.name
    },

    /*User*/
    {
      path: '/admin/entity/*/management/user',
      permission: allPermissions.MC_SYSTEM_USER_CAN_READ.name
    },
    {
      path: '/admin/entity/*/management/user/edit/*',
      permission: allPermissions.MC_SYSTEM_USER_CAN_UPDATE.name
    },

    /*Role*/
    {
      path: '/admin/entity/*/management/role',
      permission: allPermissions.MC_LOGIN_CAN_READ.name
    },
    {
      path: '/admin/entity/*/management/role/edit/*',
      permission: allPermissions.MC_LOGIN_CAN_UPDATE.name
    },

    /*Consumer*/
    {
      path: '/admin/entity/*/management/consumers/edit/*',
      permission: allPermissions.CSR_CONSUMER_CAN_READ.name
    },
    {
      path: '/admin/entity/*/management/consumers',
      permission: allPermissions.CSR_CONSUMER_CAN_READ.name
    },

    /*Product*/
    {
      path: '/admin/entity/*/management/product',
      permission: allPermissions.PP_PRODUCT_CAN_READ.name
    },
    {
      path: '/admin/entity/*/management/product/edit/*',
      permission: allPermissions.PP_PRODUCT_CAN_UPDATE.name
    },

    /*Job*/
    {
      path: '/admin/entity/*/scheduler/jobs',
      permission: allPermissions.QRTZ_JOB_CAN_READ.name
    },
    {
      path: '/admin/entity/*/scheduler/jobs/edit/*',
      permission: allPermissions.QRTZ_JOB_CAN_UPDATE.name
    },

  ];
