/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EbFactoringRequestTypeTable3ConfigGENERATED} from '../_generated/eb-factoring-request-type-table-3-config-generated.model';

// import {EbFactoringRequestTypeGENERATED} from '../_generated/eb-factoring-request-type-generated.model';

export class EbFactoringRequestTypeTable3Config extends EbFactoringRequestTypeTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdChildMcEntity', 'Child entity ID', 'idChildMcEntity', 'idChildMcEntity');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colDescription', 'Description', 'description', 'description');


  }
}
