import {McBillingPayoutTableItemFilterGENERATED} from '../_generated/mc-billing-payout-table-item-filter-generated.model';

export class McBillingPayoutTableItemFilter extends McBillingPayoutTableItemFilterGENERATED  {
  idMcBillingPayoutFile!: number;
  public properties: string[] = ['idMcBillingPayoutFile'];

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingPayoutTableItemFilter();
  }

  // ---------------------------------------------------------------------
}
