/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EbSepaFileTransaction} from '../models/eb-sepa-file-transaction.model';
import {EbSepaFileTransactionList} from '../models/eb-sepa-file-transaction-list.model';
import {EbSepaFileTransactionFilter} from '../models/eb-sepa-file-transaction-filter.model';
import {EbSepaFileTransactionService} from '../services/eb-sepa-file-transaction.service';
import {IEbSepaFileTransaction} from '../_generated/eb-sepa-file-transaction.interface';
import {McGod} from "../extra/mc-god.service";

export class EbSepaFileTransactionListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'batchCollectionDate', 'collectionTypeCd', 'createdAt', 'createdDatetime', 'createdUserId', 'dbtrNm', 'dbtracctIdIban', 'dbtragtFininstnidOthrId', 'delayFlg', 'description', 'drctdbttxMndtrltdinfDtofsgntr', 'drctdbttxMndtrltdinfMndtid', 'dueDatetime', 'dunningCompletedFlg', 'exportDrctdbttxMndtrltdinfMndtid', 'extTransactionId', 'factoringAcceptedFlg', 'fixStatusCd', 'idCsrConsumer', 'idEbFactoring', 'idEbSepaFile', 'idFactoringEntity', 'idMcEntity', 'inDunningFlg', 'instdamt', 'instdamtCcy', 'lastPauseUnpauseByUserId', 'lastUpdatedDatetime', 'maxPauseDays', 'netFeeAmount', 'netFeeVatAmount', 'nonFactoringFlg', 'pauseDaysCountWithoutCurrentPauseDays', 'pauseEndDate', 'pauseStartDate', 'pauseUnpauseReason', 'paymentAmount', 'pmtidEndtoendid', 'reason', 'reasonForChangingRequestedCollectionDate', 'reminderLastInterestCalculatedDatetime', 'requestedCollectionDate', 'rmtinfUstrd', 'sentToInkassoDatetime', 'sourceTypeCd', 'statusCd', 'typeCd', 'updatedAt', 'waitingPeriod', 'amountDue', 'consumerName', 'contractNumber', 'currentPauseDaysCount', 'ebTransactionAdjustments', 'entityName', 'pausedByUserName', 'pausedDaysCount', 'pausedFlg', 'paymentAccountEntities', 'payoutStatusCd', 'reasonLoc', 'returnedIsoCode', 'returnedIsoCodeLabel', 'returnedTypeCd'];
  public items: EbSepaFileTransaction[] = [];
  public apiService: EbSepaFileTransactionService;
 // protected tempObjList: EbSepaFileTransactionList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbSepaFileTransactionList {
    console.error('MTCN-ERROR: Not Implemented: EbSepaFileTransactionList::createNewInstance(). Add this method to final class and return new EbSepaFileTransactionList();');
    throw new Error('Cannot EbSepaFileTransactionList::createNewInstance(). Add this method to final class and return new EbSepaFileTransactionList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEbSepaFileTransaction[]): EbSepaFileTransactionList {
    const  listObj: EbSepaFileTransactionList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EbSepaFileTransaction = EbSepaFileTransaction.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EbSepaFileTransactionList {
    const  listObj: EbSepaFileTransactionList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbSepaFileTransactionService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEbSepaFileTransaction[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EbSepaFileTransaction = EbSepaFileTransaction.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEbSepaFileTransactionFilter: EbSepaFileTransactionFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEbSepaFileTransactionFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EbSepaFileTransaction {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EbSepaFileTransaction();
    }
  }

  public replaceItem(newItem: EbSepaFileTransaction) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadBySepaFileId( idEbSepaFile: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getBySepaFileId(idEbSepaFile, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

  // ---------------------------------------------------------------------
  public loadBySepaFileIdAndNullCollectionDates( idEbSepaFile: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getBySepaFileIdAndNullCollectionDates(idEbSepaFile, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

  // ---------------------------------------------------------------------
  public loadByFactoringEntityId( idFactoringEntity: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByFactoringEntityId(idFactoringEntity, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

  // ---------------------------------------------------------------------
  public loadByEntityId( idMcEntity: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByEntityId(idMcEntity, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

  // ---------------------------------------------------------------------
  public loadByEntityIdAndStatusCd( idMcEntity: number, statusCds: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByEntityIdAndStatusCd(idMcEntity,statusCds, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }


  // ---------------------------------------------------------------------
  public loadByConsumerId( idCsrConsumer: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByConsumerId(idCsrConsumer, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }



// ---------------------------------------------------------------------
public callDevFixTransactions( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.devFixTransactions().subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

// ---------------------------------------------------------------------
public callDevFixBatchCollectionDate( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.devFixBatchCollectionDate().subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

// ---------------------------------------------------------------------
public callDevFixTransactionEntityId( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.devFixTransactionEntityId().subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

// ---------------------------------------------------------------------
public callDevFixBeneficiaryEntityId( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.devFixBeneficiaryEntityId().subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

// ---------------------------------------------------------------------
public callMarkAsSentToInkasso( transactionIds: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.markAsSentToInkasso(transactionIds).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

// ---------------------------------------------------------------------
public callChangeRequestedCollectionDate( transactionIds: any, requestedCollectionDate: any, reasonForChangingRequestedCollectionDate: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.changeRequestedCollectionDate(transactionIds,requestedCollectionDate,reasonForChangingRequestedCollectionDate).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

// ---------------------------------------------------------------------
public callPauseTransaction( transactionIds: any, pause_end_date: any, pauseUnpauseReason: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.pauseTransaction(transactionIds,pause_end_date,pauseUnpauseReason).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

// ---------------------------------------------------------------------
public callUnpauseTransaction( transactionIds: any, pauseUnpauseReason: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.unpauseTransaction(transactionIds,pauseUnpauseReason).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}



}

