import {Component, EventEmitter, Input, Output} from '@angular/core';
import {McBoolean} from '@miticon-ui/mc-core';

@Component({
  selector: 'mcc-button',
  templateUrl: './mcc-button.component.html'
})
export class MccButtonComponent {

  /*Events*/
  @Input() label!: string;
  @Input() disabledMcb = new McBoolean();
  @Input() disabledLabel = 'Please Wait';
  @Input() disableOnFirstClickFlg = false;

  @Input() extraCssClasses!: string;
  // @Input() name;
  @Output() eventClicked = new EventEmitter();


  // ---------------------------------------------------------------------
  actClick() {
    if (this.disableOnFirstClickFlg) {
      this.disabledMcb.setTrue();
    }
    this.eventClicked.emit();
  }

  getLabel() {
    let result = this.label;
    if (this.disabledMcb.value && this.disabledLabel && this.disabledLabel.length > 0) {
      result = this.disabledLabel;
    }
    return result;
  }

  getExtraCssClass() {
    let result = this.extraCssClasses;
    if (this.disabledMcb.value) {
      result = result + ' disabled';
    }
    return result;
  }
}
