import {Component, Inject, OnInit} from '@angular/core';
import {McGod, McProcess, McBoolean, McProcessFlow, McProcessFlowLevel} from "@miticon-ui/mc-core";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mc-process-flow-level-profile-page',
  templateUrl: './mc-process-flow-level-profile-page.component.html',
})
export class McProcessFlowLevelProfilePageComponent implements OnInit {

  mcProcessLevelId!: number;
  mcProcessFlowLevel!: McProcessFlowLevel;
  mcProcessFlow = new McProcessFlow();
  showEditLevelMcb = new McBoolean();
  mcProcess = new McProcess();
  mcGod = McGod.getInstance();
  mcProcessStatusActive = McProcess.STATUS_CD_ACTIVE;

  // ---------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute, private router: Router,
  @Inject('designConfig') design: any) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      // @ts-ignore
      this.mcProcessLevelId = +params.get('id');
      this.mcProcessFlowLevel = new McProcessFlowLevel();
      this.actLoad();
    });
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcProcessFlowLevel.loadById(this.mcProcessLevelId, () => {
      this.mcProcessFlow.loadById(this.mcProcessFlowLevel.idMcProcessFlow, () => {
        this.mcProcess.loadById(this.mcProcessFlow.idMcProcess); // TODO: Need property process name in flow
      });
    });
}

  goBack() {
    McGod.redirectBack();
  }

  actShowEditLevelInfo() {
    if (this.mcProcess.statusCd !== this.mcProcessStatusActive) {
      this.showEditLevelMcb.setTrue();
    }
  }

  actSaveEditLevel() {
    this.showEditLevelMcb.setFalse();
    this.actLoad();
  }

  actCancelEditLevel() {
    this.showEditLevelMcb.setFalse();
  }

  actGoToProcesses() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/processes/overview`]);
  }

  actGoToProcessProfile() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-profile/${this.mcProcess.id}`]);
  }

  actGoToProcessFlowProfile() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/processes/flow-profile/${this.mcProcessFlow.id}`]);
  }
}
