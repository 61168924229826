/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McPriceList2EntityTable3ConfigGENERATED} from '../_generated/mc-price-list-2-entity-table-3-config-generated.model';

// import {McPriceList2EntityGENERATED} from '../_generated/mc-price-list-2-entity-generated.model';

export class McPriceList2EntityTable3Config extends McPriceList2EntityTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdPriceList', 'Price list ID', 'idPriceList', 'idPriceList');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colValidFrom', 'Price list is valid from this date', 'validFrom', 'validFrom');
    this.addColumn('colValidTo', 'Price list is valid until this date', 'validTo', 'validTo');


  }
}
