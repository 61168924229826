import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {McAuthService} from '../services/mc-auth.service';
import {McUtilityService} from '../services/mc-utility.service';
import {JwtHelperService} from "@auth0/angular-jwt";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: McAuthService, private utilityService: McUtilityService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // @ts-ignore
    const authToken = JSON.parse(localStorage.getItem('mc-access-token'));
    // @ts-ignore
    const entity = JSON.parse(localStorage.getItem('mc-select-entity-modal-opened'));
    const tokenExpireTime = this.utilityService.getPropertyFromToken('exp');
    const now = new Date().valueOf();
    let authRequest;

    if (!authToken) {
      /*Header for login API calls*/
      authRequest = req.clone({
        headers: req.headers.set('Authorization', 'Basic ' + btoa('trusted-app:secret'))
      });
    } else if (!tokenExpireTime || now >= (tokenExpireTime * 1000)) {
      /*Check if token exists and expired*/
      this.authService.logout();
      authRequest = false;
    } else {
      /*Set token in header*/
      authRequest = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken)
      });
    }
    // @ts-ignore
    return next.handle(authRequest);
  }
}


