/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingStatementTable3ConfigGENERATED} from '../_generated/mc-billing-statement-table-3-config-generated.model';

// import {McBillingStatementGENERATED} from '../_generated/mc-billing-statement-generated.model';

export class McBillingStatementTable3Config extends McBillingStatementTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor(fldActionFlg = true) {
    super();

    this.clearColumns();
    if (fldActionFlg) {
      this.addColumn('colActions', '', 'fldActions()', '');
    }
    this.addColumn('colChildEntity', 'Entity', 'childEntityName', '');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colTotalAmount', 'Total amount', 'getTotalPayoutLoc()', '').extraCssClass = 'text-right';
    if (fldActionFlg) {
      this.addColumn('col noOfItems', 'No. of items', 'fldNumberOfItems()', '').extraCssClass = 'text-center';
    }
    this.addColumn('colStatementDate', 'Statement date', 'getStatementDate()', '');
    this.addColumn('colStatusCd', 'Status', 'fldStatusWithIconsOutPage()', 'statusCd');


  }
}
