import {CsrPaymentDetailsFilterGENERATED} from '../_generated/csr-payment-details-filter-generated.model';

export class CsrPaymentDetailsFilter extends CsrPaymentDetailsFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrPaymentDetailsFilter();
  }

  // ---------------------------------------------------------------------
}
