import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "mc-edit-entity-password-policy-modal",
  templateUrl: "./mc-edit-entity-password-policy-modal.component.html",
})
export class McEditEntityPasswordPolicyModalComponent {

  modalReference!: any;
  isFormEdit!: boolean;

  @Input() editEntityId!: number;
  @Input() parentData!: any;
  @Output() entityPasswordPolicyEvent = new EventEmitter<any>();

  constructor(
    private modalService: NgbModal
  ) {
  }

  openModal(content: any) {
    /*Modal reference - used for close it*/
    this.modalReference = this.modalService.open(content, {
      centered: true,
      size: "lg",
      windowClass: "mc-edit-entity-password-policy-modal",
      backdrop: "static"
    });
    this.isFormEdit = true;
  }

  // update passwordPolicy
  updatePasswordPolicyData(event: any) {
    this.entityPasswordPolicyEvent.emit(event);
  }
}
