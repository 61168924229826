import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {McUtilityService,} from "@miticon-ui/mc-core";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import _moment from "moment";

@Component({
  selector: "lib-step3",
  templateUrl: "./step3.component.html"
})
export class Step3Component implements OnInit {
  saveBtnType!: number;
  private _consumersAttributes = [];
  stepForm!: FormGroup;
  allRawData: any;

  destroyed$ = new Subject<void>();
  @Output() createUserBtnType = new EventEmitter<number>();

  // Inputs
  @Input() parentForm!: FormGroup;

  @Input() set customAttributes(value: any) {
    this._consumersAttributes = value;
  };

  get consumersAttributes() {
    return this._consumersAttributes;
  }

  constructor(private fb: FormBuilder,
              private utilityService: McUtilityService,) {
    this.createForm();
  }

  ngOnInit() {
    this.parentForm.setControl("step3", this.stepForm);
    this.allRawData = this.parentForm.getRawValue();

    this.parentForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.allRawData = this.parentForm.getRawValue();
      });
  }

  createForm() {
    this.stepForm = this.fb.group({});
  }

  onSave(type: number) {
    this.createUserBtnType.emit(
      this.saveBtnType = type
    );
  }

  /*Go to step*/
  goToStep(step: any) {
    this.utilityService.setStepNumber(step);
  }

  public formatDate(date: any) {
    return date ? _moment(date).format("LL") : "";
  }

}
