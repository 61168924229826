import {McUserFilterGENERATED} from '../_generated/mc-user-filter-generated.model';

export class McUserFilter extends McUserFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McUserFilter();
  }

  // ---------------------------------------------------------------------
}
