import {Component, Input, OnInit} from '@angular/core';
import {MkFilterConfig, MkFilterOutput, MkTableConfig} from "@miticon-ui/mc-components";
import {CsrContractHistory, CsrContractHistoryService, McGod, SortCriteriaList} from "@miticon-ui/mc-core";

@Component({
  selector: 'lib-csr-contract-history-overview',
  templateUrl: './csr-contract-history-overview.component.html',
  styleUrls: ['./csr-contract-history-overview.component.css']
})
export class CsrContractHistoryOverviewComponent implements OnInit {

  @Input() contractId: number;

  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  items = [];
  pageNumber = 0;
  pageSize = 5;
  totalItemsCount = 0;

  constructor(private csrContractHistoryService: CsrContractHistoryService) {}

  ngOnInit(): void {
    this.initTableConfig();
    this.actLoad(this.pageNumber, this.pageSize);
  }

  actLoad(pageNumber: number, pageSize: number): void {
    this.csrContractHistoryService.getByIdCsrContract(this.contractId, pageNumber, pageSize, new SortCriteriaList())
      .subscribe((data) => {
        this.totalItemsCount = data.totalElements;
        this.items = data.content.map((object: any) => object = CsrContractHistory.createFromJson(object));
      })
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('cc.contract-history.field-name'), 'fieldName', 250, 'fieldName');
    this.tableConfig.addColumnStandard(McGod.t('cc.contract-history.old-value'), 'oldValue', 250, 'oldValue');
    this.tableConfig.addColumnStandard(McGod.t('cc.contract-history.new-value'), 'newValue', 250, 'newValue');
    this.tableConfig.addColumnStandard(McGod.t('cc.contract-history.date-time'), 'formatCreationDate()', 250, 'sysCreatedDatetime');
  }

  onFilterChanged(filters: MkFilterOutput) {
    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize);
  }
}
