/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbStarmoneyFile} from '../models/eb-starmoney-file.model';
import {EbStarmoneyFileService} from '../services/eb-starmoney-file.service';
import {IEbStarmoneyFile} from '../_generated/eb-starmoney-file.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';




export abstract class EbStarmoneyFileGENERATED extends BaseObject {






    public static readonly STATUS_CD_COMPLETED: string = 'COMPLETED';
    public static readonly STATUS_CD_IN_PROGRESS: string = 'IN_PROGRESS';
    public static readonly STATUS_CD_FAILED: string = 'FAILED';
  public static readonly STATUS_CD_LIST__ALL = [
    EbStarmoneyFileGENERATED.STATUS_CD_COMPLETED,
    EbStarmoneyFileGENERATED.STATUS_CD_IN_PROGRESS,
    EbStarmoneyFileGENERATED.STATUS_CD_FAILED
];


  public apiService: EbStarmoneyFileService;
  public _rawJson: IEbStarmoneyFile;
    id: number = 0;
  createdAt: number;
  idMcEntity: number;
  idMcSystemUser: number;
  idWebFile: number;
  statusCd: string;
  updatedAt: number;
    amountReturned: any;    amountWithdrawn: any;    importDate: any;    importedBy: string;    newTransactionsCount: number;    repeatedTransactionsCount: number;    returnedTransactionsCount: number;    starMoneyFilename: string;    withdrawnTransactionsCount: number;

  public properties: string[] = ['id', 'createdAt', 'idMcEntity', 'idMcSystemUser', 'idWebFile', 'statusCd', 'updatedAt', 'amountReturned', 'amountWithdrawn', 'importDate', 'importedBy', 'newTransactionsCount', 'repeatedTransactionsCount', 'returnedTransactionsCount', 'starMoneyFilename', 'withdrawnTransactionsCount'];
  public propertiesRegular: string[] = ['id', 'createdAt', 'idMcEntity', 'idMcSystemUser', 'idWebFile', 'statusCd', 'updatedAt', 'amountReturned', 'amountWithdrawn', 'importDate', 'importedBy', 'newTransactionsCount', 'repeatedTransactionsCount', 'returnedTransactionsCount', 'starMoneyFilename', 'withdrawnTransactionsCount'];
  public propertiesSpecial: string[] = [];






  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbStarmoneyFileGENERATED.STATUS_CD_COMPLETED, McGod.t('Completed'));
    list.add(EbStarmoneyFileGENERATED.STATUS_CD_IN_PROGRESS, McGod.t('In progress'));
    list.add(EbStarmoneyFileGENERATED.STATUS_CD_FAILED, McGod.t('Failed'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = EbStarmoneyFileGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  // ---------------------------------------------------------------------
public static createNewInstance(): EbStarmoneyFile {
    console.error('MTCN-ERROR: Not Implemented: EbStarmoneyFile::createNewInstance(). Add this method to final class and return new EbStarmoneyFile();');
    throw new Error('Cannot EbStarmoneyFile::createNewInstance(). Add this method to final class and return new EbStarmoneyFile();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbStarmoneyFile): EbStarmoneyFile {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbStarmoneyFileService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbStarmoneyFile AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getCreatedAtStrD(): string {
       return McDateUtils.formatDateToString(this.createdAt);
    }

    public getCreatedAtStr(): string {
       return McDateUtils.formatDateTimeToString(this.createdAt);
    }

    public getAmountReturnedStr(): string {
       return McGod.formatNumberToMoney(this.amountReturned);
    }

    public getAmountWithdrawnStr(): string {
       return McGod.formatNumberToMoney(this.amountWithdrawn);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  





  public getStatusCdLabel(): string {
    return EbStarmoneyFileGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdCOMPLETED(): boolean {
    const result = (this.statusCd === EbStarmoneyFileGENERATED.STATUS_CD_COMPLETED);

    return result;
  }

 public  isStatusCdIN_PROGRESS(): boolean {
    const result = (this.statusCd === EbStarmoneyFileGENERATED.STATUS_CD_IN_PROGRESS);

    return result;
  }

 public  isStatusCdFAILED(): boolean {
    const result = (this.statusCd === EbStarmoneyFileGENERATED.STATUS_CD_FAILED);

    return result;
  }



  
// ---------------------------------------------------------------------
public callImportStarMoneyFile( ebStarMoneyFile: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.importStarMoneyFile(ebStarMoneyFile).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

}
