/*BbmTestObj*/
import {McMappingItemGENERATED} from '../_generated/mc-mapping-item-generated.model';

export class McMappingItem extends McMappingItemGENERATED {

  public static override createNewInstance(): McMappingItem {
    return new McMappingItem();
  }

}
