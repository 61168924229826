/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbSepaFileTransaction} from '../models/eb-sepa-file-transaction.model';
import {EbSepaFileTransactionService} from '../services/eb-sepa-file-transaction.service';
import {IEbSepaFileTransaction} from '../_generated/eb-sepa-file-transaction.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {EbTransactionAdjustmentList} from '../models/eb-transaction-adjustment-list.model';

import {McEntity2List} from '../models/mc-entity2-list.model';




export abstract class EbSepaFileTransactionGENERATED extends BaseObject {



    public static readonly COLLECTION_TYPE_CD_DO_NOT_COLLECT: string = 'DO_NOT_COLLECT';
    public static readonly COLLECTION_TYPE_CD_COLLECT_DIRECT_DEBIT: string = 'COLLECT_DIRECT_DEBIT';
  public static readonly COLLECTION_TYPE_CD_LIST__ALL = [
    EbSepaFileTransactionGENERATED.COLLECTION_TYPE_CD_DO_NOT_COLLECT,
    EbSepaFileTransactionGENERATED.COLLECTION_TYPE_CD_COLLECT_DIRECT_DEBIT
];
















    public static readonly FIX_STATUS_CD_PENDING_BILLING_ITEM_FIX: string = 'PENDING_BILLING_ITEM_FIX';
    public static readonly FIX_STATUS_CD_COMPLETED_BILLING_ITEM_FIX: string = 'COMPLETED_BILLING_ITEM_FIX';
  public static readonly FIX_STATUS_CD_LIST__ALL = [
    EbSepaFileTransactionGENERATED.FIX_STATUS_CD_PENDING_BILLING_ITEM_FIX,
    EbSepaFileTransactionGENERATED.FIX_STATUS_CD_COMPLETED_BILLING_ITEM_FIX
];



























    public static readonly SOURCE_TYPE_CD_IMPORT: string = 'IMPORT';
    public static readonly SOURCE_TYPE_CD_MANUAL: string = 'MANUAL';
    public static readonly SOURCE_TYPE_CD_API: string = 'API';
  public static readonly SOURCE_TYPE_CD_LIST__ALL = [
    EbSepaFileTransactionGENERATED.SOURCE_TYPE_CD_IMPORT,
    EbSepaFileTransactionGENERATED.SOURCE_TYPE_CD_MANUAL,
    EbSepaFileTransactionGENERATED.SOURCE_TYPE_CD_API
];

    public static readonly STATUS_CD_ACCEPTED: string = 'ACCEPTED';
    public static readonly STATUS_CD_REJECTED: string = 'REJECTED';
    public static readonly STATUS_CD_BLACKLISTED: string = 'BLACKLISTED';
    public static readonly STATUS_CD_NEW: string = 'NEW';
    public static readonly STATUS_CD_EXPORTED: string = 'EXPORTED';
    public static readonly STATUS_CD_PAID: string = 'PAID';
    public static readonly STATUS_CD_RETURNED: string = 'RETURNED';
    public static readonly STATUS_CD_FOR_DUNNING: string = 'FOR_DUNNING';
    public static readonly STATUS_CD_OVERPAID: string = 'OVERPAID';
    public static readonly STATUS_CD_SENDING_TO_INKASSO: string = 'SENDING_TO_INKASSO';
    public static readonly STATUS_CD_SENT_TO_INKASSO: string = 'SENT_TO_INKASSO';
    public static readonly STATUS_CD_SHOULD_GO_TO_INKASSO: string = 'SHOULD_GO_TO_INKASSO';
    public static readonly STATUS_CD_RETURNED_FROM_INKASSO: string = 'RETURNED_FROM_INKASSO';
    public static readonly STATUS_CD_REMOVED: string = 'REMOVED';
    public static readonly STATUS_CD_PROCESSED_EARLIER: string = 'PROCESSED_EARLIER';
    public static readonly STATUS_CD_SETTLED: string = 'SETTLED';
    public static readonly STATUS_CD_CANCELLED: string = 'CANCELLED';
    public static readonly STATUS_CD_OVERWRITTEN: string = 'OVERWRITTEN';
  public static readonly STATUS_CD_LIST__ALL = [
    EbSepaFileTransactionGENERATED.STATUS_CD_ACCEPTED,
    EbSepaFileTransactionGENERATED.STATUS_CD_REJECTED,
    EbSepaFileTransactionGENERATED.STATUS_CD_BLACKLISTED,
    EbSepaFileTransactionGENERATED.STATUS_CD_NEW,
    EbSepaFileTransactionGENERATED.STATUS_CD_EXPORTED,
    EbSepaFileTransactionGENERATED.STATUS_CD_PAID,
    EbSepaFileTransactionGENERATED.STATUS_CD_RETURNED,
    EbSepaFileTransactionGENERATED.STATUS_CD_FOR_DUNNING,
    EbSepaFileTransactionGENERATED.STATUS_CD_OVERPAID,
    EbSepaFileTransactionGENERATED.STATUS_CD_SENDING_TO_INKASSO,
    EbSepaFileTransactionGENERATED.STATUS_CD_SENT_TO_INKASSO,
    EbSepaFileTransactionGENERATED.STATUS_CD_SHOULD_GO_TO_INKASSO,
    EbSepaFileTransactionGENERATED.STATUS_CD_RETURNED_FROM_INKASSO,
    EbSepaFileTransactionGENERATED.STATUS_CD_REMOVED,
    EbSepaFileTransactionGENERATED.STATUS_CD_PROCESSED_EARLIER,
    EbSepaFileTransactionGENERATED.STATUS_CD_SETTLED,
    EbSepaFileTransactionGENERATED.STATUS_CD_CANCELLED,
    EbSepaFileTransactionGENERATED.STATUS_CD_OVERWRITTEN
];

    public static readonly TYPE_CD_DEBIT: string = 'DEBIT';
    public static readonly TYPE_CD_BANK_FEE: string = 'BANK_FEE';
    public static readonly TYPE_CD_INTEREST_FEE: string = 'INTEREST_FEE';
    public static readonly TYPE_CD_REMINDER_FEE: string = 'REMINDER_FEE';
    public static readonly TYPE_CD_REGULAR: string = 'REGULAR';
  public static readonly TYPE_CD_LIST__ALL = [
    EbSepaFileTransactionGENERATED.TYPE_CD_DEBIT,
    EbSepaFileTransactionGENERATED.TYPE_CD_BANK_FEE,
    EbSepaFileTransactionGENERATED.TYPE_CD_INTEREST_FEE,
    EbSepaFileTransactionGENERATED.TYPE_CD_REMINDER_FEE,
    EbSepaFileTransactionGENERATED.TYPE_CD_REGULAR
];



  public apiService: EbSepaFileTransactionService;
  public _rawJson: IEbSepaFileTransaction;
    id: number = 0;
  batchCollectionDate: number;
  collectionTypeCd: string;
  createdAt: number;
  createdDatetime: number;
  createdUserId: number;
  dbtrNm = '';
  dbtracctIdIban = '';
  dbtragtFininstnidOthrId = '';
  delayFlg: boolean;
  description = '';
  drctdbttxMndtrltdinfDtofsgntr = '';
  drctdbttxMndtrltdinfMndtid = '';
  dueDatetime: number;
  dunningCompletedFlg: boolean;
  exportDrctdbttxMndtrltdinfMndtid = '';
  extTransactionId = '';
  factoringAcceptedFlg: boolean;
  fixStatusCd: string;
  idCsrConsumer: number;
  idEbFactoring: number;
  idEbSepaFile: number;
  idFactoringEntity: number;
  idMcEntity: number;
  inDunningFlg: boolean;
  instdamt: number;
  instdamtCcy = '';
  lastPauseUnpauseByUserId: number;
  lastUpdatedDatetime: number;
  maxPauseDays: number;
  netFeeAmount: number;
  netFeeVatAmount: number;
  nonFactoringFlg: boolean;
  pauseDaysCountWithoutCurrentPauseDays: number;
  pauseEndDate: number;
  pauseStartDate: number;
  pauseUnpauseReason = '';
  paymentAmount: number;
  pmtidEndtoendid: number;
  reason = '';
  reasonForChangingRequestedCollectionDate = '';
  reminderLastInterestCalculatedDatetime: number;
  requestedCollectionDate: number;
  rmtinfUstrd = '';
  sentToInkassoDatetime: number;
  sourceTypeCd: string;
  statusCd: string;
  typeCd: string;
  updatedAt: number;
  waitingPeriod = '';
    amountDue: any;    consumerName: string;    contractNumber: string;    currentPauseDaysCount: number;    ebTransactionAdjustments: EbTransactionAdjustmentList;    entityName: string;    pausedByUserName: string;    pausedDaysCount: number;    pausedFlg: boolean;    paymentAccountEntities: McEntity2List;    payoutStatusCd: string;    reasonLoc: string;    returnedIsoCode: string;    returnedIsoCodeLabel: string;    returnedTypeCd: string;

  public properties: string[] = ['id', 'batchCollectionDate', 'collectionTypeCd', 'createdAt', 'createdDatetime', 'createdUserId', 'dbtrNm', 'dbtracctIdIban', 'dbtragtFininstnidOthrId', 'delayFlg', 'description', 'drctdbttxMndtrltdinfDtofsgntr', 'drctdbttxMndtrltdinfMndtid', 'dueDatetime', 'dunningCompletedFlg', 'exportDrctdbttxMndtrltdinfMndtid', 'extTransactionId', 'factoringAcceptedFlg', 'fixStatusCd', 'idCsrConsumer', 'idEbFactoring', 'idEbSepaFile', 'idFactoringEntity', 'idMcEntity', 'inDunningFlg', 'instdamt', 'instdamtCcy', 'lastPauseUnpauseByUserId', 'lastUpdatedDatetime', 'maxPauseDays', 'netFeeAmount', 'netFeeVatAmount', 'nonFactoringFlg', 'pauseDaysCountWithoutCurrentPauseDays', 'pauseEndDate', 'pauseStartDate', 'pauseUnpauseReason', 'paymentAmount', 'pmtidEndtoendid', 'reason', 'reasonForChangingRequestedCollectionDate', 'reminderLastInterestCalculatedDatetime', 'requestedCollectionDate', 'rmtinfUstrd', 'sentToInkassoDatetime', 'sourceTypeCd', 'statusCd', 'typeCd', 'updatedAt', 'waitingPeriod', 'amountDue', 'consumerName', 'contractNumber', 'currentPauseDaysCount', 'ebTransactionAdjustments', 'entityName', 'pausedByUserName', 'pausedDaysCount', 'pausedFlg', 'paymentAccountEntities', 'payoutStatusCd', 'reasonLoc', 'returnedIsoCode', 'returnedIsoCodeLabel', 'returnedTypeCd'];
  public propertiesRegular: string[] = ['id', 'batchCollectionDate', 'collectionTypeCd', 'createdAt', 'createdDatetime', 'createdUserId', 'csrConsumer', 'dbtrNm', 'dbtracctIdIban', 'dbtragtFininstnidOthrId', 'delayFlg', 'description', 'drctdbttxMndtrltdinfDtofsgntr', 'drctdbttxMndtrltdinfMndtid', 'dueDatetime', 'dunningCompletedFlg', 'ebFactoring', 'exportDrctdbttxMndtrltdinfMndtid', 'extTransactionId', 'factoringAcceptedFlg', 'fixStatusCd', 'idCsrConsumer', 'idEbFactoring', 'idEbSepaFile', 'idFactoringEntity', 'idMcEntity', 'inDunningFlg', 'instdamt', 'instdamtCcy', 'lastPauseUnpauseByUserId', 'lastUpdatedDatetime', 'maxPauseDays', 'mcEntity', 'netFeeAmount', 'netFeeVatAmount', 'nonFactoringFlg', 'pauseDaysCountWithoutCurrentPauseDays', 'pauseEndDate', 'pauseStartDate', 'pauseUnpauseReason', 'paymentAmount', 'pmtidEndtoendid', 'reason', 'reasonForChangingRequestedCollectionDate', 'reminderLastInterestCalculatedDatetime', 'requestedCollectionDate', 'rmtinfUstrd', 'sentToInkassoDatetime', 'sourceTypeCd', 'statusCd', 'typeCd', 'updatedAt', 'waitingPeriod', 'amountDue', 'consumerName', 'contractNumber', 'currentPauseDaysCount', 'entityName', 'pausedByUserName', 'pausedDaysCount', 'pausedFlg', 'payoutStatusCd', 'reasonLoc', 'returnedIsoCode', 'returnedIsoCodeLabel', 'returnedTypeCd'];
  public propertiesSpecial: string[] = ['ebTransactionAdjustments', 'paymentAccountEntities'];



  public static getCollectionTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbSepaFileTransactionGENERATED.COLLECTION_TYPE_CD_DO_NOT_COLLECT, McGod.t('Do not collect'));
    list.add(EbSepaFileTransactionGENERATED.COLLECTION_TYPE_CD_COLLECT_DIRECT_DEBIT, McGod.t('Collect direct debit'));


    return list;
  }

  public static getCollectionTypeCdLabel(aCd: string): string {
    const list = EbSepaFileTransactionGENERATED.getCollectionTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }
















  public static getFixStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbSepaFileTransactionGENERATED.FIX_STATUS_CD_PENDING_BILLING_ITEM_FIX, McGod.t('Pending Billing Item Fix'));
    list.add(EbSepaFileTransactionGENERATED.FIX_STATUS_CD_COMPLETED_BILLING_ITEM_FIX, McGod.t('COMPLETED Billing Item Fix'));


    return list;
  }

  public static getFixStatusCdLabel(aCd: string): string {
    const list = EbSepaFileTransactionGENERATED.getFixStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }



























  public static getSourceTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbSepaFileTransactionGENERATED.SOURCE_TYPE_CD_IMPORT, McGod.t('Imported'));
    list.add(EbSepaFileTransactionGENERATED.SOURCE_TYPE_CD_MANUAL, McGod.t('Manual'));
    list.add(EbSepaFileTransactionGENERATED.SOURCE_TYPE_CD_API, McGod.t('Api'));


    return list;
  }

  public static getSourceTypeCdLabel(aCd: string): string {
    const list = EbSepaFileTransactionGENERATED.getSourceTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbSepaFileTransactionGENERATED.STATUS_CD_ACCEPTED, McGod.t('cc.eb-factoring.accepted'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_REJECTED, McGod.t('cc.eb-factoring.rejected'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_BLACKLISTED, McGod.t('cc.factoring.transactions.blacklisted'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_NEW, McGod.t('cc.common.view.new'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_EXPORTED, McGod.t('cc.eb-sepa-export.exported'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_PAID, McGod.t('cc.factoring.transactions.paid'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_RETURNED, McGod.t('cc.factoring.transactions.returned'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_FOR_DUNNING, McGod.t('cc.factoring.transactions.for-dunning'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_OVERPAID, McGod.t('cc.transactions.overpaid'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_SENDING_TO_INKASSO, McGod.t('cc.transactions.sending-to-inkasso'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_SENT_TO_INKASSO, McGod.t('cc.transactions.sent-to-inkasso'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_SHOULD_GO_TO_INKASSO, McGod.t('cc.transactions.should-go-to-inkasso'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_RETURNED_FROM_INKASSO, McGod.t('cc.transactions.returned-from-inkasso'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_REMOVED, McGod.t('Removed'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_PROCESSED_EARLIER, McGod.t('Processed earlier'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_SETTLED, McGod.t('Settled'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_CANCELLED, McGod.t('Cancelled'));
    list.add(EbSepaFileTransactionGENERATED.STATUS_CD_OVERWRITTEN, McGod.t('Overwritten'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = EbSepaFileTransactionGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbSepaFileTransactionGENERATED.TYPE_CD_DEBIT, McGod.t('Debit'));
    list.add(EbSepaFileTransactionGENERATED.TYPE_CD_BANK_FEE, McGod.t('Bank Fee'));
    list.add(EbSepaFileTransactionGENERATED.TYPE_CD_INTEREST_FEE, McGod.t('Interest Fee'));
    list.add(EbSepaFileTransactionGENERATED.TYPE_CD_REMINDER_FEE, McGod.t('Reminder Fee'));
    list.add(EbSepaFileTransactionGENERATED.TYPE_CD_REGULAR, McGod.t('Reegular'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = EbSepaFileTransactionGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }



  // ---------------------------------------------------------------------
public static createNewInstance(): EbSepaFileTransaction {
    console.error('MTCN-ERROR: Not Implemented: EbSepaFileTransaction::createNewInstance(). Add this method to final class and return new EbSepaFileTransaction();');
    throw new Error('Cannot EbSepaFileTransaction::createNewInstance(). Add this method to final class and return new EbSepaFileTransaction();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbSepaFileTransaction): EbSepaFileTransaction {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbSepaFileTransactionService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbSepaFileTransaction AABB()');
          if (this._rawJson['ebTransactionAdjustments']) {
        this.ebTransactionAdjustments = EbTransactionAdjustmentList.createFromJson(this._rawJson['ebTransactionAdjustments']);
      }
      if (this._rawJson['paymentAccountEntities']) {
        this.paymentAccountEntities = McEntity2List.createFromJson(this._rawJson['paymentAccountEntities']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.createdDatetime);
    }

    public getCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.createdDatetime);
    }

    public getDueDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.dueDatetime);
    }

    public getDueDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.dueDatetime);
    }

    public getInstdamtStr(): string {
       return McGod.formatNumberToMoney(this.instdamt);
    }

    public getLastUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.lastUpdatedDatetime);
    }

    public getLastUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.lastUpdatedDatetime);
    }

    public getNetFeeAmountStr(): string {
       return McGod.formatNumberToMoney(this.netFeeAmount);
    }

    public getNetFeeVatAmountStr(): string {
       return McGod.formatNumberToMoney(this.netFeeVatAmount);
    }

    public getPauseEndDateStrD(): string {
       return McDateUtils.formatDateToString(this.pauseEndDate);
    }

    public getPauseEndDateStr(): string {
       return McDateUtils.formatDateTimeToString(this.pauseEndDate);
    }

    public getPauseStartDateStrD(): string {
       return McDateUtils.formatDateToString(this.pauseStartDate);
    }

    public getPauseStartDateStr(): string {
       return McDateUtils.formatDateTimeToString(this.pauseStartDate);
    }

    public getPaymentAmountStr(): string {
       return McGod.formatNumberToMoney(this.paymentAmount);
    }

    public getReminderLastInterestCalculatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.reminderLastInterestCalculatedDatetime);
    }

    public getReminderLastInterestCalculatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.reminderLastInterestCalculatedDatetime);
    }

    public getAmountDueStr(): string {
       return McGod.formatNumberToMoney(this.amountDue);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('ebTransactionAdjustments')) {
        if (this.ebTransactionAdjustments != null) {
            dto['ebTransactionAdjustments'] = this.ebTransactionAdjustments.toDto();
        } else {
            dto['ebTransactionAdjustments'] = [];
        }
    }
    if (this.hasOwnProperty('paymentAccountEntities')) {
        if (this.paymentAccountEntities != null) {
            dto['paymentAccountEntities'] = this.paymentAccountEntities.toDto();
        } else {
            dto['paymentAccountEntities'] = [];
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  


  public getCollectionTypeCdLabel(): string {
    return EbSepaFileTransactionGENERATED.getCollectionTypeCdLabel(this.collectionTypeCd);
  }


 public  isCollectionTypeCdDO_NOT_COLLECT(): boolean {
    const result = (this.collectionTypeCd === EbSepaFileTransactionGENERATED.COLLECTION_TYPE_CD_DO_NOT_COLLECT);

    return result;
  }

 public  isCollectionTypeCdCOLLECT_DIRECT_DEBIT(): boolean {
    const result = (this.collectionTypeCd === EbSepaFileTransactionGENERATED.COLLECTION_TYPE_CD_COLLECT_DIRECT_DEBIT);

    return result;
  }

















  public getFixStatusCdLabel(): string {
    return EbSepaFileTransactionGENERATED.getFixStatusCdLabel(this.fixStatusCd);
  }


 public  isFixStatusCdPENDING_BILLING_ITEM_FIX(): boolean {
    const result = (this.fixStatusCd === EbSepaFileTransactionGENERATED.FIX_STATUS_CD_PENDING_BILLING_ITEM_FIX);

    return result;
  }

 public  isFixStatusCdCOMPLETED_BILLING_ITEM_FIX(): boolean {
    const result = (this.fixStatusCd === EbSepaFileTransactionGENERATED.FIX_STATUS_CD_COMPLETED_BILLING_ITEM_FIX);

    return result;
  }




























  public getSourceTypeCdLabel(): string {
    return EbSepaFileTransactionGENERATED.getSourceTypeCdLabel(this.sourceTypeCd);
  }


 public  isSourceTypeCdIMPORT(): boolean {
    const result = (this.sourceTypeCd === EbSepaFileTransactionGENERATED.SOURCE_TYPE_CD_IMPORT);

    return result;
  }

 public  isSourceTypeCdMANUAL(): boolean {
    const result = (this.sourceTypeCd === EbSepaFileTransactionGENERATED.SOURCE_TYPE_CD_MANUAL);

    return result;
  }

 public  isSourceTypeCdAPI(): boolean {
    const result = (this.sourceTypeCd === EbSepaFileTransactionGENERATED.SOURCE_TYPE_CD_API);

    return result;
  }


  public getStatusCdLabel(): string {
    return EbSepaFileTransactionGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACCEPTED(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_ACCEPTED);

    return result;
  }

 public  isStatusCdREJECTED(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_REJECTED);

    return result;
  }

 public  isStatusCdBLACKLISTED(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_BLACKLISTED);

    return result;
  }

 public  isStatusCdNEW(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_NEW);

    return result;
  }

 public  isStatusCdEXPORTED(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_EXPORTED);

    return result;
  }

 public  isStatusCdPAID(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_PAID);

    return result;
  }

 public  isStatusCdRETURNED(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_RETURNED);

    return result;
  }

 public  isStatusCdFOR_DUNNING(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_FOR_DUNNING);

    return result;
  }

 public  isStatusCdOVERPAID(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_OVERPAID);

    return result;
  }

 public  isStatusCdSENDING_TO_INKASSO(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_SENDING_TO_INKASSO);

    return result;
  }

 public  isStatusCdSENT_TO_INKASSO(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_SENT_TO_INKASSO);

    return result;
  }

 public  isStatusCdSHOULD_GO_TO_INKASSO(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_SHOULD_GO_TO_INKASSO);

    return result;
  }

 public  isStatusCdRETURNED_FROM_INKASSO(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_RETURNED_FROM_INKASSO);

    return result;
  }

 public  isStatusCdREMOVED(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_REMOVED);

    return result;
  }

 public  isStatusCdPROCESSED_EARLIER(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_PROCESSED_EARLIER);

    return result;
  }

 public  isStatusCdSETTLED(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_SETTLED);

    return result;
  }

 public  isStatusCdCANCELLED(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_CANCELLED);

    return result;
  }

 public  isStatusCdOVERWRITTEN(): boolean {
    const result = (this.statusCd === EbSepaFileTransactionGENERATED.STATUS_CD_OVERWRITTEN);

    return result;
  }


  public getTypeCdLabel(): string {
    return EbSepaFileTransactionGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdDEBIT(): boolean {
    const result = (this.typeCd === EbSepaFileTransactionGENERATED.TYPE_CD_DEBIT);

    return result;
  }

 public  isTypeCdBANK_FEE(): boolean {
    const result = (this.typeCd === EbSepaFileTransactionGENERATED.TYPE_CD_BANK_FEE);

    return result;
  }

 public  isTypeCdINTEREST_FEE(): boolean {
    const result = (this.typeCd === EbSepaFileTransactionGENERATED.TYPE_CD_INTEREST_FEE);

    return result;
  }

 public  isTypeCdREMINDER_FEE(): boolean {
    const result = (this.typeCd === EbSepaFileTransactionGENERATED.TYPE_CD_REMINDER_FEE);

    return result;
  }

 public  isTypeCdREGULAR(): boolean {
    const result = (this.typeCd === EbSepaFileTransactionGENERATED.TYPE_CD_REGULAR);

    return result;
  }




  




// ---------------------------------------------------------------------
public callDevFixTransactions( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.devFixTransactions().subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

// ---------------------------------------------------------------------
public callDevFixBatchCollectionDate( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.devFixBatchCollectionDate().subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

// ---------------------------------------------------------------------
public callDevFixTransactionEntityId( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.devFixTransactionEntityId().subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

// ---------------------------------------------------------------------
public callDevFixBeneficiaryEntityId( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.devFixBeneficiaryEntityId().subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

// ---------------------------------------------------------------------
public callMarkAsSentToInkasso( transactionIds: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.markAsSentToInkasso(transactionIds).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

// ---------------------------------------------------------------------
public callChangeRequestedCollectionDate( transactionIds: any, requestedCollectionDate: any, reasonForChangingRequestedCollectionDate: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.changeRequestedCollectionDate(transactionIds,requestedCollectionDate,reasonForChangingRequestedCollectionDate).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

// ---------------------------------------------------------------------
public callPauseTransaction( transactionIds: any, pause_end_date: any, pauseUnpauseReason: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.pauseTransaction(transactionIds,pause_end_date,pauseUnpauseReason).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

// ---------------------------------------------------------------------
public callUnpauseTransaction( transactionIds: any, pauseUnpauseReason: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.unpauseTransaction(transactionIds,pauseUnpauseReason).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

  // ---------------------------------------------------------------------
  public calculateAndGetTotalAmountDueForConsumer( idCsrConsumer: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.calculateAndGetTotalAmountDueForConsumer(idCsrConsumer)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

}
