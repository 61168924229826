import {Inject, Injectable} from '@angular/core';
import {ImDataSetServiceGENERATED} from '../_generated/im-data-set-generated.service';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ImDataSetService extends ImDataSetServiceGENERATED {

  getAllDataSets(): Observable<any>  {
    return this.httpClient.get(`${this.apiServiceUrlSubPath}/all`);
  }

}
