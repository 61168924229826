import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {ImDataSet} from "@miticon-ui/mc-core";

@Component({
  selector: 'lib-data-import-add-import-step',
  templateUrl: './data-import-add-import-step.component.html',
  styleUrls: ['./data-import-add-import-step.component.scss']
})
export class DataImportAddImportStepComponent implements OnInit {

  @Input() importForm!: FormGroup;
  @Input() dataSet!: ImDataSet;
  @Output() emitImportForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  importModes:{label: string, value: string}[] = [];

  constructor(private fb: FormBuilder, private tS: TranslateService) {}

  ngOnInit(): void {
    this.createForm();
    this.importForm.valueChanges.subscribe(() => {
      this.emitImportForm.emit(this.importForm);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSet'] && changes['dataSet'].currentValue) {
      this.importModes = [];
      this.importModes.push({
        label: this.tS.instant("mem.import.import-new"),
        value: "IMPORT"
      });
      if(changes['dataSet'].currentValue.name == 'Consumers' ) {
        this.importModes.push(
          {
            label: this.tS.instant("mem.import.import-new-and-update"),
            value: "IMPORT_AND_UPDATE"

          },
          {
            label: this.tS.instant("mem.import.import-update"),
            value: "UPDATE"
          })
      }
    }
  }

  createForm() {
    this.importForm =  this.fb.group({
      importMode: ['', Validators.required]
    });
  }
}
