import { McUserUserProfilePageComponent } from './pages/mc-user-user-profile-page/mc-user-user-profile-page.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard, McPermissionGuard} from '@miticon-ui/mc-core';

const routes: Routes = [
{
    path: 'profile/:id',
    component: McUserUserProfilePageComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McUserRoutingModule {
}
