/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {CsrConsumer} from '../models/csr-consumer.model';
import {CsrConsumerService} from '../services/csr-consumer.service';
import {ICsrConsumer} from '../_generated/csr-consumer.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {CsrContractList} from '../models/csr-contract-list.model';
import {CsrBankAccountList} from '../models/csr-bank-account-list.model';


export abstract class CsrConsumerGENERATED extends BaseObject {


    public static readonly EMG_RELATION_CD_PARENT: string = 'PARENT';
    public static readonly EMG_RELATION_CD_SISTER_OR_BROTHER: string = 'SISTER_OR_BROTHER';
    public static readonly EMG_RELATION_CD_HUSBAND_OR_WIFE: string = 'HUSBAND_OR_WIFE';
    public static readonly EMG_RELATION_CD_COUSIN: string = 'COUSIN';
    public static readonly EMG_RELATION_CD_FRIEND: string = 'FRIEND';
  public static readonly EMG_RELATION_CD_LIST__ALL = [
    CsrConsumerGENERATED.EMG_RELATION_CD_PARENT,
    CsrConsumerGENERATED.EMG_RELATION_CD_SISTER_OR_BROTHER,
    CsrConsumerGENERATED.EMG_RELATION_CD_HUSBAND_OR_WIFE,
    CsrConsumerGENERATED.EMG_RELATION_CD_COUSIN,
    CsrConsumerGENERATED.EMG_RELATION_CD_FRIEND
];

    public static readonly GENDER_MALE: string = 'MALE';
    public static readonly GENDER_FEMALE: string = 'FEMALE';
    public static readonly GENDER_OTHER: string = 'OTHER';
  public static readonly GENDER_LIST__ALL = [
    CsrConsumerGENERATED.GENDER_MALE,
    CsrConsumerGENERATED.GENDER_FEMALE,
    CsrConsumerGENERATED.GENDER_OTHER
];

    public static readonly ISO_COUNTRY_ESP: string = 'ESP';
    public static readonly ISO_COUNTRY_IT: string = 'IT';
    public static readonly ISO_COUNTRY_CH: string = 'CH';
    public static readonly ISO_COUNTRY_ES: string = 'ES';
    public static readonly ISO_COUNTRY_AT: string = 'AT';
    public static readonly ISO_COUNTRY_BE: string = 'BE';
    public static readonly ISO_COUNTRY_CZ: string = 'CZ';
    public static readonly ISO_COUNTRY_DE: string = 'DE';
    public static readonly ISO_COUNTRY_NL: string = 'NL';
    public static readonly ISO_COUNTRY_PT: string = 'PT';
  public static readonly ISO_COUNTRY_LIST__ALL = [
    CsrConsumerGENERATED.ISO_COUNTRY_ESP,
    CsrConsumerGENERATED.ISO_COUNTRY_IT,
    CsrConsumerGENERATED.ISO_COUNTRY_CH,
    CsrConsumerGENERATED.ISO_COUNTRY_ES,
    CsrConsumerGENERATED.ISO_COUNTRY_AT,
    CsrConsumerGENERATED.ISO_COUNTRY_BE,
    CsrConsumerGENERATED.ISO_COUNTRY_CZ,
    CsrConsumerGENERATED.ISO_COUNTRY_DE,
    CsrConsumerGENERATED.ISO_COUNTRY_NL,
    CsrConsumerGENERATED.ISO_COUNTRY_PT
];

    public static readonly ISO_LANGUAGE_EN: string = 'EN';
    public static readonly ISO_LANGUAGE_DE: string = 'DE';
    public static readonly ISO_LANGUAGE_IT: string = 'IT';
    public static readonly ISO_LANGUAGE_PT: string = 'PT';
  public static readonly ISO_LANGUAGE_LIST__ALL = [
    CsrConsumerGENERATED.ISO_LANGUAGE_EN,
    CsrConsumerGENERATED.ISO_LANGUAGE_DE,
    CsrConsumerGENERATED.ISO_LANGUAGE_IT,
    CsrConsumerGENERATED.ISO_LANGUAGE_PT
];

    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_FROZEN: string = 'FROZEN';
    public static readonly STATUS_CD_INACTIVE: string = 'INACTIVE';
    public static readonly STATUS_CD_REGISTERED: string = 'REGISTERED';
    public static readonly STATUS_CD_BLOCKED: string = 'BLOCKED';
    public static readonly STATUS_CD_WAITING_FOR_APPROVAL: string = 'WAITING FOR APPROVAL';
  public static readonly STATUS_CD_LIST__ALL = [
    CsrConsumerGENERATED.STATUS_CD_ACTIVE,
    CsrConsumerGENERATED.STATUS_CD_FROZEN,
    CsrConsumerGENERATED.STATUS_CD_INACTIVE,
    CsrConsumerGENERATED.STATUS_CD_REGISTERED,
    CsrConsumerGENERATED.STATUS_CD_BLOCKED,
    CsrConsumerGENERATED.STATUS_CD_REGISTERED,
    CsrConsumerGENERATED.STATUS_CD_WAITING_FOR_APPROVAL
];

    public static readonly TYPE_CD_PERSON: string = 'PERSON';
    public static readonly TYPE_CD_COMPANY: string = 'COMPANY';
  public static readonly TYPE_CD_LIST__ALL = [
    CsrConsumerGENERATED.TYPE_CD_PERSON,
    CsrConsumerGENERATED.TYPE_CD_COMPANY
];

  public apiService: CsrConsumerService;
  public _rawJson: ICsrConsumer;
    id: number = 0;
  accountOwner = '';
  addressSupplement = '';
  archived: boolean;
  bankName = '';
  bic = '';
  birthday: number;
  blockReason = '';
  cellphone = '';
  city = '';
  companyName = '';
  contractNumber = '';
  countryName = '';
  email = '';
  emgCity = '';
  emgCountry = '';
  emgEmail = '';
  emgLastName = '';
  emgName = '';
  emgPhoneNumber = '';
  emgPostCode = '';
  emgRelationCd: string;
  emgStreet = '';
  euVat = '';
  extConsumerId = '';
  fax = '';
  firstName = '';
  flgDunningEnabled: boolean;
  flgInkassoEnabled: boolean;
  gender: string;
  iban = '';
  idMcEntity: number;
  isoCountry: string;
  isoLanguage: string;
  lastName = '';
  postCode = '';
  sepaMandanteDate: number;
  sepaMandanteId = '';
  statusCd: string;
  street = '';
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  sysUpdatedUserId: number;
  telephone = '';
  title = '';
  typeCd: string;
  version: number;
  activeContracts: CsrContractList;  bankAccounts: CsrBankAccountList;  displayName: string;  entityName: string;

  public properties: string[] = ['id', 'accountOwner', 'addressSupplement', 'archived', 'bankName', 'bic', 'birthday', 'blockReason', 'cellphone', 'city', 'companyName', 'contractNumber', 'countryName', 'customAttributes', 'customAttributesJsonb', 'email', 'emgCity', 'emgCountry', 'emgEmail', 'emgLastName', 'emgName', 'emgPhoneNumber', 'emgPostCode', 'emgRelationCd', 'emgStreet', 'euVat', 'extConsumerId', 'fax', 'firstName', 'flgDunningEnabled', 'flgInkassoEnabled', 'gender', 'iban', 'idMcEntity', 'isoCountry', 'isoLanguage', 'lastName', 'postCode', 'sepaMandanteDate', 'sepaMandanteId', 'statusCd', 'street', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'telephone', 'title', 'typeCd', 'version', 'activeContracts', 'bankAccounts', 'displayName', 'entityName'];
  public propertiesRegular: string[] = ['id', 'accountOwner', 'addressSupplement', 'archived', 'bankName', 'bic', 'birthday', 'blockReason', 'cellphone', 'city', 'companyName', 'contractNumber', 'countryName', 'customAttributes', 'customAttributesJsonb', 'email', 'emgCity', 'emgCountry', 'emgEmail', 'emgLastName', 'emgName', 'emgPhoneNumber', 'emgPostCode', 'emgRelationCd', 'emgStreet', 'euVat', 'extConsumerId', 'fax', 'firstName', 'flgDunningEnabled', 'flgInkassoEnabled', 'gender', 'iban', 'idMcEntity', 'isoCountry', 'isoLanguage', 'lastName', 'postCode', 'sepaMandanteDate', 'sepaMandanteId', 'statusCd', 'street', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'telephone', 'title', 'typeCd', 'version', 'displayName', 'entityName'];
  public propertiesSpecial: string[] = ['activeContracts', 'bankAccounts'];



  public static getEmgRelationCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrConsumerGENERATED.EMG_RELATION_CD_PARENT, McGod.t('Parent'));
    list.add(CsrConsumerGENERATED.EMG_RELATION_CD_SISTER_OR_BROTHER, McGod.t('Sister/Brother'));
    list.add(CsrConsumerGENERATED.EMG_RELATION_CD_HUSBAND_OR_WIFE, McGod.t('Husband/Wife'));
    list.add(CsrConsumerGENERATED.EMG_RELATION_CD_COUSIN, McGod.t('Cousin'));
    list.add(CsrConsumerGENERATED.EMG_RELATION_CD_FRIEND, McGod.t('Friend'));


    return list;
  }

  public static getEmgRelationCdLabel(aCd: string): string {
    const list = CsrConsumerGENERATED.getEmgRelationCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getGenderVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrConsumerGENERATED.GENDER_MALE, McGod.t('MALE'));
    list.add(CsrConsumerGENERATED.GENDER_FEMALE, McGod.t('FEMALE'));
    list.add(CsrConsumerGENERATED.GENDER_OTHER, McGod.t('OTHER'));


    return list;
  }

  public static getGenderLabel(aCd: string): string {
    const list = CsrConsumerGENERATED.getGenderVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getIsoCountryVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrConsumerGENERATED.ISO_COUNTRY_ESP, McGod.t('ESP'));
    list.add(CsrConsumerGENERATED.ISO_COUNTRY_IT, McGod.t('IT'));
    list.add(CsrConsumerGENERATED.ISO_COUNTRY_CH, McGod.t('CH'));
    list.add(CsrConsumerGENERATED.ISO_COUNTRY_ES, McGod.t('ES'));
    list.add(CsrConsumerGENERATED.ISO_COUNTRY_AT, McGod.t('AT'));
    list.add(CsrConsumerGENERATED.ISO_COUNTRY_BE, McGod.t('BE'));
    list.add(CsrConsumerGENERATED.ISO_COUNTRY_CZ, McGod.t('CZ'));
    list.add(CsrConsumerGENERATED.ISO_COUNTRY_DE, McGod.t('DE'));
    list.add(CsrConsumerGENERATED.ISO_COUNTRY_NL, McGod.t('NL'));
    list.add(CsrConsumerGENERATED.ISO_COUNTRY_PT, McGod.t('PT'));


    return list;
  }

  public static getIsoCountryLabel(aCd: string): string {
    const list = CsrConsumerGENERATED.getIsoCountryVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getIsoLanguageVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrConsumerGENERATED.ISO_LANGUAGE_EN, McGod.t('EN'));
    list.add(CsrConsumerGENERATED.ISO_LANGUAGE_DE, McGod.t('DE'));
    list.add(CsrConsumerGENERATED.ISO_LANGUAGE_IT, McGod.t('IT'));
    list.add(CsrConsumerGENERATED.ISO_LANGUAGE_PT, McGod.t('PT'));


    return list;
  }

  public static getIsoLanguageLabel(aCd: string): string {
    const list = CsrConsumerGENERATED.getIsoLanguageVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrConsumerGENERATED.STATUS_CD_ACTIVE, McGod.t('cc.common.view.active'));
    list.add(CsrConsumerGENERATED.STATUS_CD_FROZEN, McGod.t('cc.common.view.frozen'));
    list.add(CsrConsumerGENERATED.STATUS_CD_INACTIVE, McGod.t('cc.common.view.inactive'));
    list.add(CsrConsumerGENERATED.STATUS_CD_REGISTERED, McGod.t('cc.common.view.registered'));
    list.add(CsrConsumerGENERATED.STATUS_CD_BLOCKED, McGod.t('cc.common.view.blocked'));
    list.add(CsrConsumerGENERATED.STATUS_CD_REGISTERED, McGod.t('cc.common.view.registered'));
    list.add(CsrConsumerGENERATED.STATUS_CD_WAITING_FOR_APPROVAL, McGod.t('cc.common.view.waiting.for.approval'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = CsrConsumerGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrConsumerGENERATED.TYPE_CD_PERSON, McGod.t('cc.consumers.edit.person'));
    list.add(CsrConsumerGENERATED.TYPE_CD_COMPANY, McGod.t('cc.consumers.edit.company'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = CsrConsumerGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): CsrConsumer {
    console.error('MTCN-ERROR: Not Implemented: CsrConsumer::createNewInstance(). Add this method to final class and return new CsrConsumer();');
    throw new Error('Cannot CsrConsumer::createNewInstance(). Add this method to final class and return new CsrConsumer();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: ICsrConsumer): CsrConsumer {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrConsumerService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CsrConsumer AABB()');
          if (this._rawJson['activeContracts']) {
        this.activeContracts = CsrContractList.createFromJson(this._rawJson['activeContracts']);
      }
      if (this._rawJson['bankAccounts']) {
        this.bankAccounts = CsrBankAccountList.createFromJson(this._rawJson['bankAccounts']);
      }

  }

  // ---------------------------------------------------------------------

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('activeContracts')) {
        if (this.activeContracts != null) {
            dto['activeContracts'] = this.activeContracts.toDto();
        } else {
            dto['activeContracts'] = [];
        }
    }
    if (this.hasOwnProperty('bankAccounts')) {
        if (this.bankAccounts != null) {
            dto['bankAccounts'] = this.bankAccounts.toDto();
        } else {
            dto['bankAccounts'] = [];
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --


  public getEmgRelationCdLabel(): string {
    return CsrConsumerGENERATED.getEmgRelationCdLabel(this.emgRelationCd);
  }


 public  isEmgRelationCdPARENT(): boolean {
    const result = (this.emgRelationCd === CsrConsumerGENERATED.EMG_RELATION_CD_PARENT);

    return result;
  }

 public  isEmgRelationCdSISTER_OR_BROTHER(): boolean {
    const result = (this.emgRelationCd === CsrConsumerGENERATED.EMG_RELATION_CD_SISTER_OR_BROTHER);

    return result;
  }

 public  isEmgRelationCdHUSBAND_OR_WIFE(): boolean {
    const result = (this.emgRelationCd === CsrConsumerGENERATED.EMG_RELATION_CD_HUSBAND_OR_WIFE);

    return result;
  }

 public  isEmgRelationCdCOUSIN(): boolean {
    const result = (this.emgRelationCd === CsrConsumerGENERATED.EMG_RELATION_CD_COUSIN);

    return result;
  }

 public  isEmgRelationCdFRIEND(): boolean {
    const result = (this.emgRelationCd === CsrConsumerGENERATED.EMG_RELATION_CD_FRIEND);

    return result;
  }


  public getGenderLabel(): string {
    return CsrConsumerGENERATED.getGenderLabel(this.gender);
  }


 public  isGenderMALE(): boolean {
    const result = (this.gender === CsrConsumerGENERATED.GENDER_MALE);

    return result;
  }

 public  isGenderFEMALE(): boolean {
    const result = (this.gender === CsrConsumerGENERATED.GENDER_FEMALE);

    return result;
  }

 public  isGenderOTHER(): boolean {
    const result = (this.gender === CsrConsumerGENERATED.GENDER_OTHER);

    return result;
  }


  public getIsoCountryLabel(): string {
    return CsrConsumerGENERATED.getIsoCountryLabel(this.isoCountry);
  }


 public  isIsoCountryESP(): boolean {
    const result = (this.isoCountry === CsrConsumerGENERATED.ISO_COUNTRY_ESP);

    return result;
  }

 public  isIsoCountryIT(): boolean {
    const result = (this.isoCountry === CsrConsumerGENERATED.ISO_COUNTRY_IT);

    return result;
  }

 public  isIsoCountryCH(): boolean {
    const result = (this.isoCountry === CsrConsumerGENERATED.ISO_COUNTRY_CH);

    return result;
  }

 public  isIsoCountryES(): boolean {
    const result = (this.isoCountry === CsrConsumerGENERATED.ISO_COUNTRY_ES);

    return result;
  }

 public  isIsoCountryAT(): boolean {
    const result = (this.isoCountry === CsrConsumerGENERATED.ISO_COUNTRY_AT);

    return result;
  }

 public  isIsoCountryBE(): boolean {
    const result = (this.isoCountry === CsrConsumerGENERATED.ISO_COUNTRY_BE);

    return result;
  }

 public  isIsoCountryCZ(): boolean {
    const result = (this.isoCountry === CsrConsumerGENERATED.ISO_COUNTRY_CZ);

    return result;
  }

 public  isIsoCountryDE(): boolean {
    const result = (this.isoCountry === CsrConsumerGENERATED.ISO_COUNTRY_DE);

    return result;
  }

 public  isIsoCountryNL(): boolean {
    const result = (this.isoCountry === CsrConsumerGENERATED.ISO_COUNTRY_NL);

    return result;
  }

 public  isIsoCountryPT(): boolean {
    const result = (this.isoCountry === CsrConsumerGENERATED.ISO_COUNTRY_PT);

    return result;
  }


  public getIsoLanguageLabel(): string {
    return CsrConsumerGENERATED.getIsoLanguageLabel(this.isoLanguage);
  }


 public  isIsoLanguageEN(): boolean {
    const result = (this.isoLanguage === CsrConsumerGENERATED.ISO_LANGUAGE_EN);

    return result;
  }

 public  isIsoLanguageDE(): boolean {
    const result = (this.isoLanguage === CsrConsumerGENERATED.ISO_LANGUAGE_DE);

    return result;
  }

 public  isIsoLanguageIT(): boolean {
    const result = (this.isoLanguage === CsrConsumerGENERATED.ISO_LANGUAGE_IT);

    return result;
  }

 public  isIsoLanguagePT(): boolean {
    const result = (this.isoLanguage === CsrConsumerGENERATED.ISO_LANGUAGE_PT);

    return result;
  }


  public getStatusCdLabel(): string {
    return CsrConsumerGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === CsrConsumerGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdFROZEN(): boolean {
    const result = (this.statusCd === CsrConsumerGENERATED.STATUS_CD_FROZEN);

    return result;
  }

 public  isStatusCdINACTIVE(): boolean {
    const result = (this.statusCd === CsrConsumerGENERATED.STATUS_CD_INACTIVE);

    return result;
  }

 public  isStatusCdBLOCKED(): boolean {
    const result = (this.statusCd === CsrConsumerGENERATED.STATUS_CD_BLOCKED);

    return result;
  }

 public  isStatusCdWAITING_FOR_APPROVAL(): boolean {
    const result = (this.statusCd === CsrConsumerGENERATED.STATUS_CD_WAITING_FOR_APPROVAL);

    return result;
  }


  public getTypeCdLabel(): string {
    return CsrConsumerGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdPERSON(): boolean {
    const result = (this.typeCd === CsrConsumerGENERATED.TYPE_CD_PERSON);

    return result;
  }

 public  isTypeCdCOMPANY(): boolean {
    const result = (this.typeCd === CsrConsumerGENERATED.TYPE_CD_COMPANY);

    return result;
  }



}
