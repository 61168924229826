/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CsrBankAccountTable3ConfigGENERATED} from '../_generated/csr-bank-account-table-3-config-generated.model';

// import {CsrBankAccountGENERATED} from '../_generated/csr-bank-account-generated.model';

export class CsrBankAccountTable3Config extends CsrBankAccountTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colBankName', 'Bank name', 'bankName', 'bankName');
    this.addColumn('colIban', 'IBAN', 'iban', 'iban');
    this.addColumn('colIdCsrConsumer', 'Consumer ID', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colOwner', 'Account owner', 'owner', 'owner');


  }
}
