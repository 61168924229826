/*BbmTestObj*/
import {CmnEmailGENERATED} from '../_generated/cmn-email-generated.model';
import {McValueLabelList} from "../_core/mc-value-label-list";
import {McGod} from "../extra/mc-god.service";

export class CmnEmail extends CmnEmailGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CmnEmail();
  }

  // ---------------------------------------------------------------------

  public static getStatusVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add(CmnEmailGENERATED.STATUS_CD_NEW, McGod.t('New'));
    list.add(CmnEmailGENERATED.STATUS_CD_SCHEDULED, McGod.t('Scheduled'));
    list.add(CmnEmailGENERATED.STATUS_CD_ERROR, McGod.t('Failed'));
    list.add(CmnEmailGENERATED.STATUS_CD_SENT, McGod.t('Sent'));


    return list;
  }
}
