<div class="date-field">
  <div id="mcc-fi-{{name}}" class="mcc-fi mcc-fi-date-picker mcc-fi-date-picker-{{name}} {{extraCssClasses}}" [class.newStyleDate]="newStyleDate" [style.margin-top]="isBasicDatepickerInFilter ? '26px' : ''">
    <div *ngIf="!newStyleDate && this.datePickerViewType === 'POPUP'" [formGroup]="getDatePickerForm()"
         [ngClass]="{'validation-error' : getDatePickerForm().get('errorFlg')?.value}">
<!--      <div (click)="resetDatePicker()" class="mc-multiple-items-clear" style="text-transform: capitalize">{{'cc.common.clear' | translate}}</div>-->
      <label *ngIf="label" for="{{inputId}}" [ngClass]="{'mc-mandatory-star' : validRequiredFlg, 'mc-datepicker-in-filter' : isBasicDatepickerInFilter}">{{label}} </label>

      <input
        class="pt-3"
        id="{{inputId}}"
        [name]="name"
        [markDisabled]="markDisabledDays"
        [outsideDays]="displayDaysOutOfCurrentMonth"
        [readonly]="!allowManualEntryFlg"
        [minDate]="minDate"
        [maxDate]="maxDate"
        placeholder="dd.mm.yyyy"
        ngbDatepicker
        #toggleDate="ngbDatepicker"
        [displayMonths]="multipleMonthsNum"
        (click)="toggleDate.toggle()"
        formControlName="value"
        (ngModelChange)="onDatePickerChanged($event)">
      <div class="error-msg">{{this.getDatePickerForm().get('errorMsg')?.value}}</div>
    </div>

    <div *ngIf="newStyleDate && this.datePickerViewType === 'POPUP'" [formGroup]="getDatePickerForm()"
         [ngClass]="{'validation-error' : getDatePickerForm().get('errorFlg')?.value}">
      <mat-form-field appearance="outline">
        <mat-label>{{ label }}</mat-label>
        <input matInput [matDatepicker]="date" formControlName='value' [id]="inputId"
               (click)='date.open()' [min]='minDate' [max]=maxDate (dateChange)="onDatePickerChanged($event)" readonly>
        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
      </mat-form-field>
      <div class="error-msg">{{this.getDatePickerForm().get('errorMsg')?.value}}</div>
    </div>

    <div *ngIf="this.datePickerViewType === 'BASIC'" [formGroup]="getDatePickerForm()"
         [ngClass]="{'validation-error' : getDatePickerForm().get('errorFlg')?.value}">
      <label *ngIf="label" [for]="inputId" [ngClass]="{'mc-mandatory-star' : validRequiredFlg}">{{label}}</label>
      <ngb-datepicker
        [id]="inputId"
        [outsideDays]="displayDaysOutOfCurrentMonth"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [displayMonths]="multipleMonthsNum"
        formControlName="value"
        (ngModelChange)="onDatePickerChanged($event)"
      ></ngb-datepicker>
      <div class="error-msg">{{this.getDatePickerForm().get('errorMsg')?.value}}</div>
    </div>

    <!--<div *ngIf="this.datePickerViewType === 'DISABLED'" [formGroup]="datePickerForm" [ngClass]="{'validation-error' : this.datePickerForm.get('errorFlg').value}">
      <label *ngIf="label" [for]="inputId" [ngClass]="{'mc-mandatory-star' : validRequiredFlg}">{{label}}</label>
      <ngb-datepicker
        [id]="inputId"
        class="border-error"
        [disabled]="disabled"
        [outsideDays]="displayDaysOutOfCurrentMonth"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [displayMonths]="multipleMonthsNum"
        [(ngModel)]="value"
        (ngModelChange)="onValueChanged($event)"
      ></ngb-datepicker>
      <div class="error-msg">{{this.datePickerForm.get('errorMsg').value}}</div>
    </div>-->

    <div *ngIf="this.datePickerViewType === 'RANGE_SELECTION'" [formGroup]="getDatePickerForm()" [ngClass]="{'validation-error' : getDatePickerForm().get('errorFlg')?.value}">
      <label *ngIf="label" [for]="inputId" class="mc-range-date-label" [ngClass]="{'mc-mandatory-star' : validRequiredFlg}">{{label}}</label>

      <div class="form-inline">
        <div class="form-group hidden">
          <div class="input-group">
            <input name="datepicker"
                   ngbDatepicker
                   #datepicker="ngbDatepicker"
                   [autoClose]="'outside'"
                   (dateSelect)="onDateSelection($event)"
                   [displayMonths]="monthsDisplayed"
                   [dayTemplate]="t"
                   outsideDays="hidden"
                   [startDate]="fromDate!">
            <ng-template #t let-date let-focused="focused">
        <span class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
            >
          {{ date.day }}
        </span>
            </ng-template>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
<!--            <div (click)="resetDatePicker()" class="mc-multiple-items-clear" style="text-transform: capitalize">{{'cc.common.clear' | translate}}</div>-->
            <input #dpFromDate
                   class="form-control date-range-input"
                   name="dpFromDate"
                   (keypress)="onKeyPress()"
                   [value]="getValueForRange()"
                   (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                   [required]="validRequiredFlg"
                   (click)="datepicker.toggle()"
                   placeholder="Select date range">
            <!--<i class="fas fa-times" (click)="resetDatePicker()" style="cursor: pointer; top: 23px; left: 243px;"></i>-->
          </div>
          <i class="fa fa-calendar-alt"></i>
        </div>

      </div>
      <div class="error-msg">{{this.getDatePickerForm().get('errorMsg')?.value}}</div>

  </div>
</div>

</div>
