/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McBillingService2Vat} from '../models/mc-billing-service-2-vat.model';
import {McBillingService2VatList} from '../models/mc-billing-service-2-vat-list.model';
import {McBillingService2VatFilter} from '../models/mc-billing-service-2-vat-filter.model';
import {McBillingService2VatService} from '../services/mc-billing-service-2-vat.service';
import {IMcBillingService2Vat} from '../_generated/mc-billing-service-2-vat.interface';
import {McGod} from "../extra/mc-god.service";

export class McBillingService2VatListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'idBillingService', 'idVat'];
  public items: McBillingService2Vat[] = [];
  public apiService: McBillingService2VatService;
 // protected tempObjList: McBillingService2VatList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingService2VatList {
    console.error('MTCN-ERROR: Not Implemented: McBillingService2VatList::createNewInstance(). Add this method to final class and return new McBillingService2VatList();');
    throw new Error('Cannot McBillingService2VatList::createNewInstance(). Add this method to final class and return new McBillingService2VatList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcBillingService2Vat[]): McBillingService2VatList {
    const  listObj: McBillingService2VatList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McBillingService2Vat = McBillingService2Vat.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McBillingService2VatList {
    const  listObj: McBillingService2VatList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingService2VatService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcBillingService2Vat[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McBillingService2Vat = McBillingService2Vat.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcBillingService2VatFilter: McBillingService2VatFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcBillingService2VatFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McBillingService2Vat {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McBillingService2Vat();
    }
  }

  public replaceItem(newItem: McBillingService2Vat) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadByVATId( VATId: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByVATId(VATId, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

