<div class="mcc-fi mcc-fi-select mcc-multiselect mcc-fi-select-{{name}} {{extraCssClass}} {{labelPositionClass}}">
  <div>
    <label *ngIf="label" [ngClass]="{'mc-mandatory-star' : validRequiredFlg}" for="mcc-fi-{{name}}">{{label}}</label>

    <input  (click)="toggleDropdown()"  [ngClass]="{'border-error' : this.getMultiSelectForm().get('errorFlg')?.value}" [(ngModel)]="itemSelectedString" class="d-block single-input" id="mcc-fi-{{name}}" name="item" type="text" (keypress)="onKeypress()">


    <div class="mcc-multiselect-item-group d-block">
      <div *ngIf="itemsSelectedNumber > 1 && itemsSelectedNumber !== this.options.length - 1" [ngbTooltip]="getSelectedItemsTooltip()" [placement]="tooltipPlacement" class="mcc-multiselect-item-name d-flex">
        {{itemsSelectedNumber}} {{'cc.filter.selected' | translate}}
      </div>
      <div *ngIf="itemsSelectedNumber === 1" class="mcc-multiselect-item-name d-flex">{{itemsSelectedLabels[0]}}</div>

      <div *ngIf="itemsSelectedNumber !== undefined && itemsSelectedNumber === options.length - 1" data-placement="right" class="mcc-multiselect-item-name d-flex" [ngbTooltip]="getAllItemsTooltip()">{{('cc.my-factoring.all' | translate)}}</div>

      <div *ngIf="!itemsSelectedNumber" class="mcc-multiselect-item-name d-flex">{{('cc.common.edit.please-choose' | translate)}}</div>
      <div (click)="resetSelects()" *ngIf="itemsSelectedNumber !== 0 || !itemsSelectedNumber === undefined" class="close-ms"><i class="fas fa-times"></i></div>
    </div><!--mcc-multiselect-item-group-->
    <div class="error-msg">{{this.getMultiSelectForm().get('errorMsg')?.value}}</div>

  </div>
<!--  <div (click)="resetSelects()" class="mc-multiple-items-clear" style="text-transform: capitalize">{{'cc.common.clear' | translate}}</div>-->
  <div *ngIf="showMultiselectItems" class="mcc-multiselect-items">
    <div *ngFor="let vllItem of options">
      <input [id]="vllItem.label" type="checkbox" [checked]="vllItem.checked" #itemsCheckboxes (change)="checkSelected(vllItem)">
      <label [for]="vllItem.label">{{vllItem.label}}</label>
    </div>
  </div>

</div>
