import {EbPaymentReportFilterGENERATED} from '../_generated/eb-payment-report-filter-generated.model';

export class EbPaymentReportFilter extends EbPaymentReportFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbPaymentReportFilter();
  }

  // ---------------------------------------------------------------------
}
