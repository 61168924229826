/*BbmTestObj*/
import {McProcessItemGENERATED} from '../_generated/mc-process-item-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {McDateUtils} from '../common/mc-date-utils';
import {ActionOptions} from '../_core/mc-html-action-options';

export class McProcessItem extends McProcessItemGENERATED {

  public static ACTION_CHANGE_NEXT_ACTION_DATE = 'CHANGE-NEXT-ACTION-DATE'; // This format because of scss class in slide-action menu
  public static ACTION_VIEW_TRANSACTION_DETAILS = 'ACT_VIEW_TRANSACTION_DETAILS';
  public static ACTION_VIEW_ACTION_HISTORY = 'ACT_VIEW_ACTION_HISTORY';

  private _fldActions: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessItem();
  }

  // ---------------------------------------------------------------------

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`View transaction details`, McProcessItem.ACTION_VIEW_TRANSACTION_DETAILS, this, [McGod.PERM_EB_FACTORING_MY_TRANSACTION_VIEW], '', ActionOptions.OPTION_VIEW);
      actSet.addActionB(`View consumer action history`, McProcessItem.ACTION_VIEW_ACTION_HISTORY, this, [McGod.PERM_EB_FACTORING_MY_TRANSACTION_VIEW], '', ActionOptions.OPTION_VIEW);
      actSet.addActionB(`Change next action date`, McProcessItem.ACTION_CHANGE_NEXT_ACTION_DATE, this, [McGod.PERM_MC_PROCESS_ITEM_CHANGE_NEXT_ACTION_DATE], `mc-button-link`, ActionOptions.OPTION_EDIT); // ${this.newItemsCount > 0 ? '' : 'mc-disable'}
      this._fldActions = html;
    }
    return this._fldActions.items;
  }

  getActionStartDatetimeString(): string {
    if (this.actionStartDatetime) {
      return McDateUtils.newFormatDateTimeString(this.actionStartDatetime);
    }
    return '';
  }

  getTransactionAmountDue() {
    return this.pmTransaction.getAmountDue();
  }

  getTransactionDescription() {
    return this.pmTransaction.description;
  }

  getConsumerFullName() {
    return this.pmTransaction.csrConsumer.getDisplayName();
  }

  getFlowName() {
    return this.mcProcessFlow.title
  }

  getFlowLevelName() {
    return this.mcProcessFlowLevel.title
  }
}
