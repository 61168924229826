import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from '@miticon-ui/mc-core';
import {McSchedulerComponent} from './mc-scheduler.component';
import {McJobViewComponent} from './mc-job/mc-job-view/mc-job-view.component';
import {McPermissionGuard} from "@miticon-ui/mc-core";

const routes: Routes = [
  {
    path: ':module',
    component: McSchedulerComponent,
    canActivate: [AuthGuard, McPermissionGuard]
  },  {
    path: ':module/edit/:jobId',
    component: McJobViewComponent,
    canActivate: [AuthGuard, McPermissionGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McSchedulerRoutingModule {
}
