import {Component, Inject, OnInit} from '@angular/core';
import {
  BaseDirective,
  CurrencyUtil,
  EbFactoringService,
  EbSepaFileTransaction,
  EbSepaFileTransactionFilter,
  EbSepaFileTransactionList,
  IEbFactoring,
  McBoolean,
  McConsumer,
  McConsumerBalanceService,
  McCurrencyService,
  McForm,
  McGod,
  McHtmlButton,
  McSlideActionItem,
  McSlideActionSet,
  McValueLabelList,
  PmTransactionConsumerTable3Config,
  PmTransaction,
  PmTransactionFilter,
  PmTransactionList,
  PmTransactionService,
  CsrConsumerService,
  CsrConsumer,
  CsrBankAccount,
  CsrContract
} from '@miticon-ui/mc-core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ToastrService} from "ngx-toastr";
import {take} from "rxjs/operators";

@Component({
  selector: 'mc-consumer-consumer-transaction-overview-page',
  templateUrl: './mc-consumer-consumer-transaction-overview-page.component.html',
  styleUrls: ['./mc-consumer-consumer-transaction-overview-page.component.scss']
})
export class McConsumerConsumerTransactionOverviewPageComponent extends BaseDirective implements OnInit {

  /*Variables*/
  pmTransactionList = new PmTransactionList();
  pmTransactionTable3Config = new PmTransactionConsumerTable3Config();
  pmTransactionFilter = new PmTransactionFilter();
  mcConsumer = new McConsumer();
  mcConsumerId!: number;
  mcForm!: McForm;
  ebSepaFileTransactionFilter = new EbSepaFileTransactionFilter();
  showTransactionAddPaymentMcb = new McBoolean();
  showTransactionProfileMcb = new McBoolean();
  actionPmTransactionId = 0;
  pmTransaction = new PmTransaction();
  actionSet = new McSlideActionSet();
  showChangeCollectionDateMcb = new McBoolean();
  showAddTransactionsManuallyMcb = new McBoolean();
  isTransactionAcc!: boolean;
  isTransactionForDunning!: boolean;
  numberOfSelectedItems!: number;
  ebSepaFileTransactionCdVll: McValueLabelList = EbSepaFileTransaction.getStatusCdVll();
  typeCdVll: McValueLabelList = EbSepaFileTransaction.getFactoredServiceTypeCdVll();
  selectedTransactionList = new PmTransactionList();
  showAddPaymentForMultipleTransactions = new McBoolean();
  notifyMessage = {errorMessage: '', successMessage: ''};
  showPauseTransactionMcb = new McBoolean();
  ebFactoring!: IEbFactoring;
  entityId!: number;
  mcGod = McGod.getInstance();
  selectedTransactionsNumber!: number;
  nonFactoringTransactions!: any;
  areSelectedTransactions!: boolean;
  selectedNumberOfTransactions!: number;
  statusCds: any[] = [];
  typeCds: any[] = [];
  clearedAllMultipleFilters = new Subject<boolean>();
  pausedUnpausedCdVll = new McValueLabelList();
  inDunningCdVll = new McValueLabelList();
  completedDunningCdVll = new McValueLabelList();
  clearDateRangeSubject = new Subject<boolean>();
  paymentDueDateFrom: any;
  paymentDueDateTo: any;
  showConsumerProfileMcb = new McBoolean();
  consumerBalanceAmount!: number;
  currencyCode!: string;
  totalAmountDue!: number;
  csrContract!: CsrContract|undefined;


  // CsrConsumer
  csrConsumer = new CsrConsumer();
  csrConsumerId!: number;

  csrBankAccount = new CsrBankAccount();
  // ---------------------------------------------------------------------
  constructor(private route: ActivatedRoute,
              private mcConsumerBalanceService: McConsumerBalanceService,
              private csrConsumerService: CsrConsumerService,
              private mcCurrencyService: McCurrencyService,
              private ebFactoringService: EbFactoringService,
              private pmTransactionService: PmTransactionService,
              private router: Router,
              private toastr: ToastrService,
              @Inject('designConfig') design: any) {
   super();
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.ebFactoring = this.ebFactoringService.activeChildFactoring;
    this.pausedUnpausedCdVll = EbSepaFileTransaction.getPauseUnpausedCdVll();
    this.inDunningCdVll = EbSepaFileTransaction.getInDunningFlgCdVll();
    this.completedDunningCdVll = EbSepaFileTransaction.getDunningCompletedCdVll();
    this.typeCdVll = EbSepaFileTransaction.getFactoredServiceTypeCdVll();
    this.mcForm = new McForm();

    this.actionSet.addItem(this.mcGod.t('cc.transactions.record-payment-s'), EbSepaFileTransaction.ACTION_RECORD_PAYMENT, [McGod.PERM_EB_FACTORING_MY_TRANSACTION_RECORD_PAYMENT]);
    this.actionSet.addItem(McGod.t('cc.consumer.change-collection-date-s'), EbSepaFileTransaction.ACTION_CHANGE_COLLECTION_DATE,[McGod.PERM_EB_FACTORING_OUT_TRANSACTION_VIEW], 10, '');

    this.route.paramMap.subscribe(params => {
      // @ts-ignore
      this.csrConsumerId = +params.get('id');
      this.csrConsumer.loadById(this.csrConsumerId, () => {
        this.entityId = this.csrConsumer.idMcEntity;
        this.actLoad();
      });
    });
    this.ebSepaFileTransactionCdVll.items = this.ebSepaFileTransactionCdVll.items.filter((item: any) => {
      if ((item.label !== 'Blacklisted') && (item.label !== 'New')) {
        return item;
      }
    });
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    const filteredStatusCds = this.statusCds.filter(arr => arr.length);
    const filteredTypeCds = this.typeCds.filter(arr2 => arr2.length);
    this.pmTransactionFilter.statusCds = filteredStatusCds;
    this.pmTransactionFilter.factoringTypeWithDelayFilterOptionCds = filteredTypeCds;
    this.pmTransactionFilter.consumerId = this.csrConsumer.id;
    this.pmTransactionFilter.forConsumerFlg = true;
    this.pmTransactionFilter.pausedFilterOptionCd = this.mcForm.getValue('selPausedUnpaused');
    this.pmTransactionFilter.inDunningFilterOptionCd = this.mcForm.getValue('selInDunning');
    this.pmTransactionFilter.dunningCompletedFilterOptionCd = this.mcForm.getValue('selCompletedDunning');
    this.pmTransactionFilter.transactionPaymentDueDateTimeFromDate = this.paymentDueDateFrom ? this.paymentDueDateFrom : null;
    this.pmTransactionFilter.transactionPaymentDueDateTimeToDate = this.paymentDueDateTo ? this.paymentDueDateTo : null;
    this.pmTransactionList.loadByFilter(this.pmTransactionFilter, () => {
      this.currencyCode = this.pmTransactionList.items[0].mcFactoring.mcCurrency.code;
    });

    this.pmTransactionService.getTotalAmountDue(this.csrConsumerId).pipe(take(1)).subscribe(totalAmountDue => {
      this.totalAmountDue = totalAmountDue;
    })

    this.csrConsumerService.getConsumerBalance(this.csrConsumerId).subscribe((res) => {
      if (res === 0) {
        this.consumerBalanceAmount = 0;
      } else {
        this.consumerBalanceAmount = res;
      }
    });
    if (this.csrConsumer.bankAccounts && this.csrConsumer.bankAccounts.items.length) {
      this.csrBankAccount = this.csrConsumer.bankAccounts.items.filter(
        (bankAccount: CsrBankAccount) => bankAccount.flgPrimary
  
      )[0];
    }

    const contractList = this.csrConsumer.activeContracts.items;
    this.csrContract = contractList[0];
  }

  actClearFilter() {
    this.clearedAllMultipleFilters.next(true);
    this.clearDateRangeSubject.next(true);
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.mcForm.getControl('selPausedUnpaused')?.get('value')?.setValue('');
    this.mcForm.getControl('selInDunning')?.get('value')?.setValue('');
    this.mcForm.getControl('selCompletedDunning')?.get('value')?.setValue('');
    this.actLoad();
  }

  actOnTransactionTableItemAction(itemAction: McHtmlButton) {
    this.selectedTransactionList = new PmTransactionList();
    this.notifyMessage.errorMessage = '';
    this.actionPmTransactionId = itemAction.item.id;
    this.pmTransaction = itemAction.item;
    this.selectedTransactionList.items.push(this.pmTransaction);

    switch (itemAction.actionCdForEvent) {
      case PmTransaction.ACTION_RECORD_PAYMENT:
        this.showTransactionAddPaymentMcb.setTrue();
        break;
      case PmTransaction.ACTION_PAUSE_TRANSACTIONS:
       this.showPauseTransactionMcb.setTrue();
       break;
      case PmTransaction.ACTION_UNPAUSE_TRANSACTIONS:
        this.showPauseTransactionMcb.setTrue();
        break;
      case PmTransaction.ACTION_VIEW_CONSUMER_PROFILE:
        this.showConsumerProfileMcb.setTrue();
        break;
      case PmTransaction.ACTION_VIEW_DETAILS:
        this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/transactions/${itemAction.item.id}`]);
        break;
      /*case EbSepaFileTransaction.ACTION_SETTLE_WITH_BALANCE:
        const body: any = {};
        body.paymentMethod = 'CREDIT';
        body.amount = null;
        if (this.consumerBalanceAmount === 0 || !this.consumerBalanceAmount) {
          this.notifyMessage.successMessage = '';
          this.notifyMessage.errorMessage = McGod.t('cc.consumer.overview.this-action-cannot-be-completed-the-balance-on-the-consumers-account-is-0') + '.';
        } else {
          this.ebSepaFileTransaction.apiService.payTransaction(itemAction.item.id, body).pipe(take(1)).subscribe(() => {
            this.notifyMessage.errorMessage = '';
            this.notifyMessage.successMessage = McGod.t('cc.consumer.transaction-has-been-successfully-settled-with-balance') + '.';
            this.actLoad();
          }, error => {
            this.notifyMessage.successMessage = '';
            this.notifyMessage.errorMessage = McGod.t('cc.consumer.transaction-has-not-been-successfully-settled-with-balance-try-again-later') + '.';
          });
        }
        break;*/
     /* case EbSepaFileTransaction.ACTION_CHANGE_COLLECTION_DATE:
        if (this.ebSepaFileTransaction.nonFactoringFlg) {


          if (this.ebSepaFileTransaction.statusCd === 'ACCEPTED') {
            this.showChangeCollectionDateMcb.setTrue();
            this.selectedTransactionsNumber = 1;
            this.notifyMessage.errorMessage = '';
          } else {
            this.notifyMessage.errorMessage = McGod.t('cc.transactions.you-can-change-collection-date-only-for-transactions-with-status-accepted');
          }

        } else {
          this.notifyMessage.errorMessage = McGod.t('cc.transactions.you-can-change-collection-date-only-for-transactions-from-service-contract');
        }
        break;*/
    }
  }

  // ---------------------------------------------------------------------
  actOnTransactionProfileClose() {
    this.actLoad();
    this.showTransactionProfileMcb.setFalse();
  }

  // ---------------------------------------------------------------------

  actOnTransactionAddPaymentClose() {
    this.actLoad();
    this.showTransactionAddPaymentMcb.setFalse();
  }

  goBack() {
    McGod.redirectBack();
  }

  receiveStateOnSelect(selectedList: PmTransactionList) {
    this.selectedTransactionList = new PmTransactionList();
    if (selectedList) {
      this.numberOfSelectedItems = selectedList.getCount();
      this.selectedTransactionList = selectedList;
      this.selectedTransactionList.items.forEach(item => {
        this.isTransactionAcc = item.isStatusCdACCEPTED();
       /* if (item.inDunningFlg || item.dunningCompletedFlg) {
          this.isTransactionForDunning = true;
        } else {
          this.isTransactionForDunning = false;
        }*/
      });
      this.nonFactoringTransactions = this.selectedTransactionList.items.filter(item => {
       /* if (item.nonFactoringFlg) {
          return item;
        }*/
      });
      this.areSelectedTransactions = this.nonFactoringTransactions.length > 0;
    }
  }

  slideActionItemClicked(actionItem: McSlideActionItem) {
    switch (actionItem.actionCdForEvent) {
      case EbSepaFileTransaction.ACTION_CHANGE_COLLECTION_DATE:
        if (this.isTransactionAcc && this.areSelectedTransactions) {
          this.showChangeCollectionDateMcb.setTrue();
          this.selectedTransactionsNumber = this.selectedTransactionList.getCount();
          this.notifyMessage.errorMessage = '';
        } else {
          this.notifyMessage.errorMessage = McGod.t('cc.transactions.you-can-change-collection-date-only-for-transactions-from-service-contract-and-with-status-accepted');
    }
        break;
      case EbSepaFileTransaction.ACTION_RECORD_PAYMENT:
        this.multipleRecordPaymentShowMcb();
        break;
      case EbSepaFileTransaction.ACTION_PAUSE_TRANSACTIONS:
        if (!this.isTransactionForDunning) {
          this.notifyMessage.errorMessage = 'You can only pause transaction which is in dunning flow.';
        } else {
          this.notifyMessage.errorMessage = '';
          this.showPauseTransactionMcb.setTrue();
        }
        break;
      default:
    }
  }

  actCancelChangeCollectionModal() {
    this.showChangeCollectionDateMcb.setFalse();
    this.selectedTransactionList = this.pmTransactionList.getSelectedObjectList();
  }

  actChangeCollectionDate() {
    this.numberOfSelectedItems = 0;
    this.showChangeCollectionDateMcb.setFalse();
    this.actLoad();
    this.selectedTransactionList = new PmTransactionList();
  }

  multipleRecordPaymentShowMcb() {
    this.selectedTransactionList = this.pmTransactionList.getSelectedObjectList();

    if (this.isRecordPaymentPossible() && this.selectedTransactionList.items.every(item => item.amountDue > 0)) {
      this.showAddPaymentForMultipleTransactions.setTrue();
    } else if(!this.isRecordPaymentPossible() && this.selectedTransactionList.items.every(item => item.amountDue > 0)) {
      this.toastr.error(`${McGod.t('cc.transactions.record.payment.status-error')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
    } else if(this.isRecordPaymentPossible() && !this.selectedTransactionList.items.every(item => item.amountDue > 0)) {
      this.toastr.error(`${McGod.t('cc.transactions.the-amount-due-for-one-or-more-transactions-is-0')}.`, '', {timeOut: 4000});
    } else {
      this.toastr.error(`${McGod.t('cc.transactions.record.payment.status-error')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
      this.toastr.error(`${McGod.t('cc.transactions.the-amount-due-for-one-or-more-transactions-is-0')}.`, '', {timeOut: 4000});
    }
  }

  isRecordPaymentPossible() {
    return this.selectedTransactionList.items.every(item => item.statusCd === PmTransaction.STATUS_CD_SENT_TO_INKASSO || item.statusCd === PmTransaction.STATUS_CD_RETURNED ||
      item.statusCd === PmTransaction.STATUS_CD_SENDING_TO_INKASSO || item.statusCd === PmTransaction.STATUS_CD_SHOULD_GO_TO_INKASSO ||
      item.statusCd === PmTransaction.STATUS_CD_ACCEPTED || item.statusCd === PmTransaction.STATUS_CD_RETURNED_FROM_INKASSO ||
      item.statusCd === PmTransaction.STATUS_CD_FOR_DUNNING ||
      item.statusCd === PmTransaction.STATUS_CD_REJECTED);
  }

  actSelectedItems(selectedList: EbSepaFileTransactionList) {
    this.numberOfSelectedItems = selectedList.getCount();
  }

  actCancelAddEditMultiplePayments() {
    this.showAddPaymentForMultipleTransactions.setFalse();
    this.selectedTransactionList = new PmTransactionList();
  }

  actPaymentMultipleTransactionsSaved() {
    this.toastr.success(`The payment for ${this.selectedTransactionList.getCount()} transaction(s) was recorded successfully`, '', {timeOut: 4000, extendedTimeOut: 4000});
    this.numberOfSelectedItems = 0;
    this.showAddPaymentForMultipleTransactions.setFalse();
    this.selectedTransactionList = new PmTransactionList();
    this.actLoad();
  }

  paymentApiErrorEvent(errorMsg: string) {
    this.toastr.success(errorMsg, '', {timeOut: 4000, extendedTimeOut: 4000});
  }

  onTransactionPaused() {
    this.toastr.success(`${McGod.t('cc.transactions.transaction-s-was-paused-successfully')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
    this.actLoad();
    this.showPauseTransactionMcb.setFalse();
  }

  onPauseTransactionCanceled() {
    this.showPauseTransactionMcb.setFalse();
  }

  actShowAddTransactionMcb() {
    this.showAddTransactionsManuallyMcb.setTrue();
  }

  actAddTransactionManuallyCanceled() {
    this.showAddTransactionsManuallyMcb.setFalse();
  }

  actAddTransactionManuallySaved() {
    this.actLoad();
    this.showAddTransactionsManuallyMcb.setFalse();
    this.notifyMessage.successMessage = this.mcGod.t('cc.transactions.transaction-added-successfully');
  }

  onTransactionUnpaused() {
    this.toastr.success(`${McGod.t('cc.transactions.transaction-s-was-unpaused-successfully')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
    this.actLoad();
    this.showPauseTransactionMcb.setFalse();
  }

  onSelectedStatus(statusVll: any[]) {
    this.statusCds = statusVll;
  }
  onSelectedTypeCds(typesVll: any[]) {
    this.typeCds = typesVll;
  }
  onPaymentDueDateFrom(value: any) {
    if (value !== null) {
      this.paymentDueDateFrom = value;
    } else {
      this.paymentDueDateFrom = null;
    }
  }

  onPaymentDueDateTo(value: any) {
    if (value !== null) {
      this.paymentDueDateTo = value;
    } else {
      this.paymentDueDateTo = null;
    }
  }

  format(value: number): string {
    if (value) {
      return CurrencyUtil.format(value, this.currencyCode);
    }
    return CurrencyUtil.format(0, this.currencyCode);
  }
}
