<div [formGroup]="entitySummaryForm" class="mc-entity-summary-area">
  <div class="mc-notify-wrapper-summary-entity">
    <mc-notify-message [message]="message" [type]="type"></mc-notify-message>
  </div>
  <div class="row">
    <!--Basic info section-->
    <div class="col-md-3 mc-right-border-summary">
      <div class="mc-entity-summary-title">
        <i class="fas fa-pen" (click)="goToStep(1)"></i>
        <h6 class="pl-2">{{ 'cc.common.view.basic-info' | translate }}:</h6>
      </div>

      <!--Content row-->
      <div class="row pt-1 px-2">
        <!--Basic info column-->
        <div class="col-md-12">
          <!--Entity image-->
          <div class="row mb-3">
            <div class="col-md-12">
              <div class="text-uppercase set-font">{{ 'cc.common.edit.image' | translate }}:</div>
              <div class="position-top right-basic-info-col">
                <img *ngIf="imagePath" [src]="imagePath" class="summary-entity-image" alt="Entity image">
              </div>
            </div>
          </div>
          <!--Status-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">status:</div>
              <div class="position-top right-basic-info-col">
                <mc-status [status]="allRawData.step1?.gender"></mc-status>
              </div>
            </div>
          </div>

          <!--Name-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">name:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.name || '-'}}</div>
            </div>
          </div>

          <!--Abbreviation-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">abbreviation:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.abbreviation || '-'}}</div>
            </div>
          </div>

          <!--Description-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">{{ 'cc.common.view.description' | translate }}:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.description || '-'}}</div>
            </div>
          </div>

          <!--Phone number-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">{{ 'cc.common.edit.phone-number' | translate }}:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.phoneNumber || '-'}}</div>
            </div>
          </div>

          <!--Fax number-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">{{ 'cc.common.edit.fax-number' | translate }}:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.faxNumber || '-'}}</div>
            </div>
          </div>

          <!--Address-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">{{ 'cc.common.edit.address' | translate }}:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.address || '-'}}</div>
            </div>
          </div>

          <!--Mail domain-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">{{ 'cc.common.edit.mail-domain' | translate }}:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.mail || '-'}}</div>
            </div>
          </div>

          <!--Web domain-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">{{ 'cc.common.edit.web-domain' | translate }}:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.webDomain || '-'}}</div>
            </div>
          </div>

          <!--IP whitelist-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">{{ 'cc.common.edit.ip-whitelist' | translate }}:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.IP || '-'}}</div>
            </div>
          </div>

          <!--VAT number-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">{{ 'cc.common.edit.vat-number' | translate }}:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.vatNumber || '-'}}</div>
            </div>
          </div>

          <!--Impressum-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">{{ 'cc.common.edit.impressum' | translate }}:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.impressum || '-'}}</div>
            </div>
          </div>

          <!--Legal representative-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">{{ 'cc.common.edit.legal-representative' | translate }}:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.legalRepresentative || '-'}}</div>
            </div>
          </div>

          <!--Legal representative contact-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">{{ 'cc.common.edit.legal-representative-email' | translate }}:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.legalRepresentativeContact || '-'}}</div>
            </div>
          </div>

          <!--External Creditor Id-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">External creditor Id:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.extCreditorId || '-'}}</div>
            </div>
          </div>
          <!--Studio owner-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">Studio owner:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.studioOwner || '-'}}</div>
            </div>
          </div>
          <!--Studio owner address-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">Studio owner address:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.studioOwnerAddress || '-'}}</div>
            </div>
          </div>
          <!--Studio owner postal code-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">Studio owner postal code:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.studioOwnerPostalCode || '-'}}</div>
            </div>
          </div>
          <!--Studio owner city-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">Studio owner city:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.studioOwnerCity || '-'}}</div>
            </div>
          </div>
          <!--Studio manager-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">Studio manager:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.studioManager || '-'}}</div>
            </div>
          </div>
          <!--Court in charge-->
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="text-uppercase set-font">Court in charge:</div>
              <div class="position-top right-basic-info-col">{{allRawData.step1?.courtInCharge || '-'}}</div>
            </div>
          </div>
        </div><!--Basic info column /end-->
      </div><!--Content row /end-->
    </div>

    <!--Attributes section-->
    <div class="col-md-5 mc-right-border-summary mc-entity-attributes-area">
      <div class="mc-entity-attributes-wrapper">
        <div class="mc-entity-summary-title">
          <i class="fas fa-pen" (click)="goToStep(2)"></i>
          <h6 class="pl-2">{{ 'cc.common.view.attributes' | translate }}:</h6>
        </div>
        <div class="mc-attributes">

          <!--Inherited attributes-->
          <div class="row">
            <div class="col-md-12">
              <h5 class="attributes-subtitle">Inherited parent attributes</h5>
              <div class="mc-table-inherited-attributes-wrapper">
                <table class="table table-borderless mc-table-inherited-attributes">
                  <thead class="mc-table-header-inherited-attributes">
                  <tr>
                    <th class="text-uppercase"></th>
                    <th class="text-uppercase"></th>
                    <th class="text-uppercase"></th>
                    <th class="text-uppercase mc-blue-text">NAME</th>
                    <th class="text-uppercase mc-blue-text">TYPE</th>
                    <th class="text-uppercase mc-blue-text">{{ 'cc.common.view.default-value' | translate }}</th>
                    <th class="text-uppercase"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let attribute of allRawData.step2.inheritAttributes; let i = index" class="border-bottom-row">
                    <td>
                      <img [src]="attribute.enabled === true ? attributesIcons.enable.path : attributesIcons.disable.path" [alt]="attribute.enabled === true ? attributesIcons.enable.alt : attributesIcons.disable.alt">
                    </td>
                    <td>
                      <img [src]="attribute.inheritable === true ? attributesIcons.inheritable.path : attributesIcons.notInheritable.path" [alt]="attribute.inheritable === true ? attributesIcons.inheritable.alt : attributesIcons.notInheritable.alt">
                    </td>
                    <td>
                      <img [src]="attribute.required === true ? attributesIcons.required.path : attributesIcons.notRequired.path" [alt]="attribute.required === true ? attributesIcons.required.alt : attributesIcons.notRequired.alt">
                    </td>
                    <td>{{attribute.name}}</td>
                    <td [ngSwitch]="attribute.type">
                      <span *ngSwitchCase="'INTEGER'">{{attribute.length ? ('Number' + '(' + attribute.length + ')') : 'Number'}}</span>
                      <span *ngSwitchCase="'VARCHAR'">{{attribute.length ? ('String' + '(' + attribute.length + ')') : 'String'}}</span>
                      <span *ngSwitchCase="'DATETIME'">{{attribute.length ? ('Date Time' + '(' + attribute.length + ')') : 'Date Time'}}</span>
                      <span *ngSwitchCase="'DATE'">{{attribute.length ? ('Date ' + '(' + attribute.length + ')') : 'Date '}}</span>
                      <span *ngSwitchCase="'FLOAT'">{{attribute.length ? ('Decimal' + '(' + attribute.length + ')') : 'Decimal'}}</span>
                      <span *ngSwitchCase="'BOOLEAN'">{{attribute.length ? ('Yes/No' + '(' + attribute.length + ')') : 'Yes/No'}}</span>
                    </td>
                    <td>{{attribute.defaultValue}}</td>
                    <td>
                      <img [src]="hoveredIcon === 'inherit' + i ? attributesWhiteInfoIcon : attributesInfoIcon" class="mc-attribute-info" (mouseover)="hoveredIcon = 'inherit' + i" (mouseout)="hoveredIcon = null" [alt]="attributesIcons.info.alt" placement="left" [ngbTooltip]="attribute.description ? attribute.description : 'Description is not defined.'" tooltipClass="description-attribute">
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <br>
          <!--Local attributes-->
          <div class="row">
            <div class="col-md-12">
              <h5 class="attributes-subtitle">{{'cc.entity-profile.view.local-attributes' | translate}}</h5>
              <div class="mc-table-local-attributes-wrapper pr-4">
                <table class="table table-borderless mc-table-local-attributes">
                  <thead class="mc-table-header-local-attributes">
                  <tr>
                    <th class="text-uppercase"></th>
                    <th class="text-uppercase"></th>
                    <th class="text-uppercase"></th>
                    <th class="text-uppercase mc-blue-text">NAME</th>
                    <th class="text-uppercase mc-blue-text">TYPE</th>
                    <th class="text-uppercase mc-blue-text">{{ 'cc.common.view.default-value' | translate }}</th>
                    <th class="text-uppercase"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let attribute of allRawData.step2.localAttributes; let i = index" class="border-bottom-row">
                    <td>
                      <img [src]="attribute.enabled === true ? attributesIcons.enable.path : attributesIcons.disable.path" [alt]="attribute.enabled === true ? attributesIcons.enable.alt : attributesIcons.disable.alt">
                    </td>
                    <td>
                      <img [src]="attribute.inheritable === true ? attributesIcons.inheritable.path : attributesIcons.notInheritable.path" [alt]="attribute.inheritable === true ? attributesIcons.inheritable.alt : attributesIcons.notInheritable.alt">
                    </td>
                    <td>
                      <img [src]="attribute.required === true ? attributesIcons.required.path : attributesIcons.notRequired.path" [alt]="attribute.required === true ? attributesIcons.required.alt : attributesIcons.notRequired.alt">
                    </td>
                    <td>{{attribute.name}}</td>
                    <td>
                      {{attribute.length ? (attribute.type + '(' + attribute.length + ')') : attribute.type}}
                    </td>
                    <td *ngIf="attribute.defaultValue" [ngSwitch]="attribute.type">
                      <span *ngSwitchCase="'Date Time'">{{attribute.defaultValue.day + "/" + attribute.defaultValue.month + "/" + attribute.defaultValue.year}}</span>
                      <span *ngSwitchDefault>{{attribute.defaultValue}}</span>
                    </td>
                    <td *ngIf="!attribute.defaultValue"></td>
                    <td>
                      <img [src]="hoveredIcon === i ? attributesWhiteInfoIcon : attributesInfoIcon" class="mc-attribute-info" (mouseover)="hoveredIcon = i" (mouseout)="hoveredIcon = null" [alt]="attributesIcons.info.alt" placement="left" [ngbTooltip]="attribute.description ? attribute.description : 'Description is not defined.'" tooltipClass="description-attribute">
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      <!--Password policy section-->
      <div class="col-md-4">
        <div class="mc-entity-summary-title">
          <i class="fas fa-pen" (click)="goToStep(3)"></i>
          <h6 class="pl-2">{{'cc.common.view.password-policy' | translate}}:</h6>
        </div>
        <div class="row pt-1 px-2">
          <!--Password policy column-->
          <div class="col-md-12">

            <!--Maximum retry count-->
            <div class="row mb-2">
              <div class="col-md-8 text-uppercase set-font left-pass-col">{{ 'cc.common.edit.maximum-retry-count' | translate }}:</div>
              <div class="col-md-4 position-top right-pass-col">{{allRawData.step3.maxRetry === 1 ? allRawData.step3?.maxRetry + ' time' : allRawData.step3?.maxRetry + ' times'}}</div>
            </div>

            <!--Lockout time-->
            <div class="row mb-2">
              <div class="col-md-8 text-uppercase set-font left-pass-col">{{ 'cc.common.edit.lockout-time' | translate }}:</div>
              <div class="col-md-4 position-top right-pass-col">{{allRawData.step3.lockTime === 1 ? allRawData.step3?.lockTime + ' minute' : allRawData.step3?.lockTime + ' minutes'}}</div>
            </div>

            <!--Minimum password length-->
            <div class="row mb-2">
              <div class="col-md-8 text-uppercase set-font left-pass-col">min. password length:</div>
              <div class="col-md-4 position-top right-pass-col">{{allRawData.step3.minPassLength === 1 ? allRawData.step3?.minPassLength + ' character' : allRawData.step3?.minPassLength + ' characters'}}</div>
            </div>

            <!--Password expiration period-->
            <div class="row mb-2">
              <div class="col-md-8 text-uppercase set-font left-pass-col">{{ 'cc.common.edit.password-expiration-period' | translate }}:</div>
              <div class="col-md-4 position-top right-pass-col">{{allRawData.step3?.passExPeriod === 1 ? allRawData.step3?.passExPeriod + ' day' : allRawData.step3?.passExPeriod + ' days'}}</div>
            </div>

            <!--Minimum different characters-->
            <div class="row mb-2">
              <div class="col-md-8 text-uppercase set-font left-pass-col">min. different characters:</div>
              <div class="col-md-4 position-top right-pass-col">{{allRawData.step3?.minDiffChar === 1 ? allRawData.step3?.minDiffChar + ' character' : allRawData.step3?.minDiffChar + ' characters'}}</div>
            </div>

            <!--Minimum different passwords-->
            <div class="row mb-2">
              <div class="col-md-8 text-uppercase set-font left-pass-col">min different passwords:</div>
              <div class="col-md-4 right-col-position right-pass-col">{{allRawData.step3?.minDiffPass === 1 ? allRawData.step3?.minDiffPass + ' password' : allRawData.step3?.minDiffPass + ' passwords'}}</div>
            </div>

            <!--Minimum digits-->
            <div class="row mb-2">
              <div class="col-md-8 text-uppercase set-font left-pass-col">min. digits:</div>
              <div class="col-md-4 right-col-position right-pass-col">{{allRawData.step3?.minDigits === 1 ? allRawData.step3?.minDigits + ' digit' : allRawData.step3?.minDigits + ' digits'}}</div>
            </div>

            <!--Minimum special characters-->
            <div class="row mb-2">
              <div class="col-md-8 text-uppercase set-font left-pass-col">min. special characters:</div>
              <div class="col-md-4 right-col-position right-pass-col">{{allRawData.step3?.minSpecChar === 1 ? allRawData.step3?.minSpecChar + ' character' : allRawData.step3?.minSpecChar + ' characters'}}</div>
            </div>

            <!--Minimum lowercase letters-->
            <div class="row mb-2">
              <div class="col-md-8 text-uppercase set-font left-pass-col">min. lowercase letters:</div>
              <div class="col-md-4 right-col-position right-pass-col">{{allRawData.step3?.minLowLetters === 1 ? allRawData.step3?.minLowLetters + ' letter' : allRawData.step3?.minLowLetters + ' letters'}}</div>
            </div>

            <!--Minimum uppercase letters-->
            <div class="row">
              <div class="col-md-8 text-uppercase set-font left-pass-col">min. uppercase letters:</div>
              <div class="col-md-4 right-col-position right-pass-col">{{allRawData.step3?.minUpLetters === 1 ? allRawData.step3?.minUpLetters + ' letter' : allRawData.step3?.minUpLetters + ' letters'}}</div>
            </div>

          </div><!--Password policy column /end-->

          <div class="mc-entity-summary-title col-md-12 mt-3">
            <i class="fas fa-pen" (click)="goToStep(4)"></i>
            <h6 class="pl-2">Pricelist:</h6>
          </div>
          <div class="row pt-1 px-2">
            <!--Password policy column-->
            <div class="col-md-12">

              <!--Pricelist-->
              <div class="row mb-2 pl-2">
                <div class="col-md-12 text-uppercase set-font left-pass-col">Pricelist:</div>
                <div class="col-md-12 pl-3 position-top right-pass-col">{{pricelist?.title}}</div>
              </div>



              <!--Description-->
              <div class="row mb-2 pl-2">
                <div class="col-md-12 text-uppercase set-font left-pass-col">Description:</div>
                <div class="col-md-12 pl-3 position-top right-pass-col">{{pricelist?.description}}</div>
              </div>

            </div><!--Password policy column /end-->

          </div><!--Content row /end-->

        </div><!--Content row /end-->
      </div>

  </div>

  <!--Create new entity-->
  <div class="row">
    <div class="col-md-4 mc-entity-summary-create-btn">
      <button id="entitySummaryCreateBtn" class="btn btn-primary mc-next-save-btn" type="submit" (click)="createEntity(false)">
        {{'cc.common.view.create' | translate}}
      </button>
    </div>
    <div class="col-md-4 mc-entity-summary-createAdd-btn">
      <button id="entitySummaryCreate&AddBtn" class="btn btn-primary mc-next-save-btn" disabled type="submit" (click)="createEntity(true)">
        {{'cc.entities.view.create-&-add-roles' | translate}}
      </button>
    </div>
  </div>
</div>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
