import {Component, EventEmitter, Inject, Input, OnInit, Output} from "@angular/core";
import {
  CurrencyUtil, McBillingService2PriceListStepperTable3ConfigModel,
  McBoolean,
  McDateUtils, McEntity2, McEntity2List,
  McForm, McGod,
  McPriceList
} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-pricelist-pricelist-review-and-confirm-part',
  templateUrl: './mc-pricelist-pricelist-review-and-confirm-part.component.html',
})
export class McPricelistPricelistReviewAndConfirmPartComponent implements OnInit {

  saveButtonDisabledMcb = new McBoolean();
  mcBillingService2PriceListTable3Config = new  McBillingService2PriceListStepperTable3ConfigModel();
  mcPriceList = new McPriceList();
  entitiesSelected = [];
  validFrom: any;
  validFromDateString!: string;
  mcEntity = new McEntity2();

  @Input() mcForm!: McForm;
  @Input() entities!: any;
  @Input() templatePriceList!: McPriceList;
  @Input() selectedEntities!: McEntity2List;
  @Input() currency!: string;
  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventPrev = new EventEmitter();

  ngOnInit() {
    this.entitiesSelected = this.mcForm.getValue('ctrlEntitiesPricelist');
    this.validFrom = this.mcForm.getValue('ctrlValidFrom');
    this.validFromDateString = McDateUtils.formatDateToString(this.validFrom);

    this.mcPriceList.idParentTemplate = this.templatePriceList.id;
    this.mcPriceList.title = this.mcForm.getValue('ctrlPricelistName') + ' ' + this.currency;
    this.mcPriceList.description = this.mcForm.getValue('ctrlPricelistDescription');
    this.mcPriceList.description = this.mcForm.getValue('ctrlPricelistDescription');
    this.mcPriceList.validFrom = this.validFrom;
    this.mcPriceList.mcBillingService2PriceListItemList = this.templatePriceList.mcBillingService2PriceListItemList;

    this.templatePriceList.mcBillingService2PriceListItemList.items.map(item => {
      if (item.priceOneTime) {
        item.priceOneTimeStr = CurrencyUtil.format(item.priceOneTime, this.templatePriceList.currency.code);
      }
      if (item.priceFlat) {
        item.priceFlatStr = CurrencyUtil.format(item.priceFlat, this.templatePriceList.currency.code);
        item.priceAdditionalStr = CurrencyUtil.format(item.priceAdditionalUnit, this.templatePriceList.currency.code);
      }
    })
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actCreate() {

    this.mcPriceList.idEntityOwner = McGod.getLoggedEntityIdFromToken();
    this.mcPriceList.sysCreatedUserId = McGod.getLoggedEntityIdFromToken();

    this.mcPriceList.save(() => {
      this.eventSaved.emit();
    }, () => {

    });
  }

  actPrev() {
    this.eventPrev.emit();
  }

  getNumberOfEntitiesDesc() {
    const entityNames: any[] = [];
    this.entitiesSelected.forEach(entity => {
      this.selectedEntities.items.forEach(item => {
        if (item.id === entity) {
          entityNames.push(item.name);
        }
      });
    });
    return `<div class="icon-tooltip"><span>${this.entitiesSelected.length > 0 ? this.entitiesSelected.length : '0'}</span>
                    <span class="icon-tooltip-text">${entityNames.join(', ')}</span></div>`;
  }
}
