import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {AuthGuard, McPermissionGuard} from '@miticon-ui/mc-core';

const routes: Routes = [
    {
      path: '',
      component: DashboardComponent,
      canActivate: [AuthGuard]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McDashboardRoutingModule {
}
