import {Inject, Injectable} from '@angular/core';
import {McConsumerImportServiceGENERATED} from '../_generated/mc-consumer-import-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McConsumerImportService extends McConsumerImportServiceGENERATED {


}
