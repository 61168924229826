<div class="content-wrapper">

  <lib-mc-loader [showLoader]="mcChildEntityList.apiLoadingFlg"></lib-mc-loader>

  <div class="content-header">
    <h1>Edit Pricelist details</h1>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-body">

    <mcc-message [errorMsg]="mcPriceList.apiErrorMessage"></mcc-message>

    <div class="row">
      <div class="col-md-12">
        <div class="form-inner-content mt-3 pl-1">
          <mcc-fi-textbox [label]="mcGod.t('cc.pricelist.pricelist-name')" [name]="'ctrlPricelistName'" [value]="mcPriceList.title"
                          [mcForm]="mcForm" [validRequiredFlg]="true"></mcc-fi-textbox>

          <mcc-fi-textarea [name]="'ctrlPricelistDescription'" [label]="mcGod.t('cc.pricelist.pricelist-description')" [value]="mcPriceList.description"
                           [numCols]="35" [validRequiredFlg]="true" [mcForm]="mcForm"></mcc-fi-textarea>
        </div>

        <div class="form-inner-content mt-3 pl-1">
          <div *ngIf="mcChildEntityList.apiSuccessFlg && entitiesValuesLoadedFlg" class="col-6 mt-3">
            <mcc-fi-multiselect2 [mcForm]="mcForm" [label]="mcGod.t('cc.pricelist.entities-pricelist-will-apply-to')" [pleaseChoosePlaceHolderFlg]="true"
                                 [name]="'ctrlEntitiesPricelist'" [optionVll]="mcChildEntityVll" [valueVll]="entitiesValue" [removeControlFromMcFormOnDestroy]="false"></mcc-fi-multiselect2>
          </div>
          <div class="col-6 mt-3">
            <mcc-fi-date-picker
              [label]="mcGod.t('cc.pricelist.valid-from')"
              [removeControlFromMcFormOnDestroy]="false"
              [name]="'ctrlValidFrom'"
              [minDateInput]="datepickerTommorowValue"
              [valueInput]="mcPriceList.validFrom"
              [validRequiredFlg]="true"
              [mcForm]="mcForm">
            </mcc-fi-date-picker>
          </div>
        </div>


      </div>

    </div><!-- row -->

  </div><!-- content-body -->

  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary move-right" (eventClicked)="actCancel();"
                label="Cancel"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actSave()" label="Save"
                ></mcc-button>

  </div>

</div>

