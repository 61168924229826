/*BbmTestObj*/
import {EbInkassoExportGENERATED} from '../_generated/eb-inkasso-export-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {McDateUtils} from '../common/mc-date-utils';
import {ActionOptions} from '../_core/mc-html-action-options';
import {ICON_STATUS_EXPORTED} from "../svg/icon-status-exported";
import {ICON_STATUS_FAILED} from '../svg/icon-status-failed';
import {ICON_STATUS_IN_PROGRESS} from "../svg/icon-status-in-progress";

export class EbInkassoExport extends EbInkassoExportGENERATED {

  public static ACTION_DOWNLOAD = 'DOWNLOAD';
  private _fldActions: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbInkassoExport();
  }

  // ---------------------------------------------------------------------

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-download mc-cursor-pointer"></i> ${McGod.t('cc.out-factoring.download')}`, EbInkassoExport.ACTION_DOWNLOAD, this, [McGod.PERM_MC_PROCESS_VIEW], '', ActionOptions.OPTION_VIEW);
      this._fldActions = html;
    }
    return this._fldActions;
  }

  getCreatedDatetimeString() {
    return McDateUtils.newFormatDateTimeString(this.sysCreatedDatetime);
  }

  getStatusCd() {
    if (this.statusCd === EbInkassoExport.STATUS_CD_EXPORTED) {
      return `<div class='icon-text-table-cell'>${ICON_STATUS_EXPORTED} <span>Exported</div>`
    }
    if (this.statusCd === EbInkassoExport.STATUS_CD_FAILED) {
      return `<div class='icon-text-table-cell'>${ICON_STATUS_FAILED} <span>Failed</div>`
    }
    if (this.statusCd === EbInkassoExport.STATUS_CD_IN_PROGRESS) {
      return `<div class='icon-text-table-cell'>${ICON_STATUS_IN_PROGRESS} <span>In progress</div>`
    }
    return this.statusCd;
  }
}
