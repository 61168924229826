import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {McEntityService} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-role-create-wrapper',
  templateUrl: './mc-role-create-wrapper.component.html',
})
export class McRoleCreateWrapperComponent implements OnInit, AfterViewInit {

  selectedEntityPath!: any;
  isFormValid!: boolean;
  resetChildForm = {
    flag: Math.random(),
    reset: false
  };
  formSummery = {
    firstStepForm: {
      caption: '',
      description: '',
      setRoleAsATemplate: false,
      status: 'active'
    },
    secondStepForm: []
  };
  createdRoles: any[] = [];
  selectedStep!: number;

  /*Private variables*/
  private modalOpenSubscription!: any;
  private isNewRoleCreated = false;

  /*Events*/
  @Output() updateTableContent = new EventEmitter<boolean>();


  @Input() selectedEntity!: any;

  /*Constructor*/
  constructor(private modalService: NgbModal,
              private entityService: McEntityService) {

    /*Get selected entity path from service*/
    this.entityService._selectedEntityPath.subscribe(path => {
      this.selectedEntityPath = path;
    });
  }

  /*On init class*/
  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.isFormValid = false;
  }

  /*Receive first step form*/
  getFirstStepForm(event: any) {
    this.formSummery.firstStepForm = event;
  }

  /*Receive first step form*/
  getSecondStepForm(event: any) {
    this.formSummery.secondStepForm = event;
  }

  /*Open modal*/
  public openXl(content: any): void {
    this.modalOpenSubscription = this.modalService
      .open(content, {
        size: 'lg',
        windowClass: 'mc-create-role-modal',
        backdrop: "static",
        beforeDismiss: () => {
          this.createdRoles = [];
          return true;
        }
      });
  }

  /*Close modal*/
  public closeModal(event: any): void {
    if (event) {
      this.isNewRoleCreated = true;
      this.updateTableContent.emit(true);
      this.modalOpenSubscription.close();
    }
  }

  /*Reset form*/
  public resetForm(event: any): void {
    this.isNewRoleCreated = true;
    /*todo: find better solution for resetting child form*/
    this.resetChildForm = {
      flag: Math.random(),
      reset: event
    };
    if (event) {
      this.createdRoles.push(`${this.formSummery.firstStepForm.caption}`);
      this.formSummery = {
        firstStepForm: {
          caption: '',
          description: '',
          setRoleAsATemplate: false,
          status: ''
        },
        secondStepForm: []
      };
    }
  }

  /*Remove created role*/
  public removeCreatedRole(role: any): void {
    const roleIndex = this.createdRoles.findIndex(item => item === role);
    if (roleIndex >= 0) {
      this.createdRoles.splice(roleIndex, 1);
    }
  }

  /*On form valid*/
  public onCurrentFormValid(event: boolean) {
    this.isFormValid = event;
  }

  roleDataFormValid(data: boolean) {
    this.isFormValid = data;
  }

  public val(event: any) {
    this.isFormValid = event;
  }

  /*On get selected step number*/
  public onGetSelectedStep(event: any) {
    this.selectedStep = event;
  }

  permissionInvalid($event: boolean) {
    this.isFormValid = $event;
  }
}
