/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
/* Using "any" data-type in returned observables to avoid importing   */
/* ItemClass and ItemListClass and circular dependencies.             */
/* ------------------------------------------------------------------ */
import {BaseObjectService} from '../_core/base-object-service';
import {IMcBillingBatch2Transaction} from '../_generated/mc-billing-batch-2-transaction.interface';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';
// import {McBillingBatch2Transaction} from '../models/mc-billing-batch-2-transaction.model';
// import {McBillingBatch2TransactionList} from '../models/mc-billing-batch-2-transaction-list.model';
// import {McBillingBatch2TransactionFilter} from '../models/mc-billing-batch-2-transaction-filter.model';
import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class McBillingBatch2TransactionServiceGENERATED extends BaseObjectService {
protected apiServiceUrlSubPath = "/mcbilling/v3/mcbillingbatch2transaction";

public getApiServiceRootUrl(): string {
    return this.apiUrl + this.apiServiceUrlSubPath;
  }

  /* Get all  from DB */
public getAll(aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/all?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  /* Get all  from DB without pager */
public getByFilter(aFilter: any, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/filter?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, aFilter.toDto(), {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

/* Get by id from DB */
public getById(id: number): Observable<IMcBillingBatch2Transaction> {
  return this.httpClient.get<IMcBillingBatch2Transaction>(this.getApiServiceRootUrl() + `/${id}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

// Insert Object
public insert(aMcBillingBatch2Transaction: any): Observable<IMcBillingBatch2Transaction> {
    return this.httpClient.post<IMcBillingBatch2Transaction>(this.getApiServiceRootUrl()  , aMcBillingBatch2Transaction.toDto(), {headers: this.httpOptions});
}
// Update object
public update(aMcBillingBatch2Transaction: any): Observable<IMcBillingBatch2Transaction> {
    return this.httpClient.put<IMcBillingBatch2Transaction>(this.getApiServiceRootUrl() + `/`+ aMcBillingBatch2Transaction.id, aMcBillingBatch2Transaction.toDto(), {headers: this.httpOptions});
}


// DeleteAllFromDB
public deleteAllFromDb() {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/deleteAllFromDb`, {headers: this.httpOptions});
  }

  /* Get by id from DB */
public deleteById(id: number) {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/${id}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });

  }

  // -- CUSTOM METHODS --
  
}
