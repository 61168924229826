/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McUserTable3ConfigGENERATED} from '../_generated/mc-user-table-3-config-generated.model';

// import {McUserGENERATED} from '../_generated/mc-user-generated.model';

export class McUserTable3Config extends McUserTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdMcLogin', '', 'idMcLogin', 'idMcLogin');
    this.addColumn('colEmail', 'Email', 'email', 'email');
    this.addColumn('colFirstname', 'First name', 'firstname', 'firstname');
    this.addColumn('colLastname', 'Last name', 'lastname', 'lastname');
    this.addColumn('colNickname', 'nickname', 'nickname', 'nickname');
    this.addColumn('colTitle', 'title', 'title', 'title');
    this.addColumn('colGender', 'gender', 'gender', 'gender');
    this.addColumn('colIsoLanguage', 'iso_language', 'isoLanguage', 'isoLanguage');
    this.addColumn('colIsoCountry', 'iso_country', 'isoCountry', 'isoCountry');
    this.addColumn('colTimezone', 'timezone', 'timezone', 'timezone');


  }
}
