<div class="row p-0 m-0 d-flex flex-row align-items-center">
  <h3 class="pr-2 pt-1">{{'cc.factoring.transactions' | translate}}</h3>
</div>

<div>
  <lib-mc-loader [showLoader]="ebSepaFileTransactionList.apiLoadingFlg"></lib-mc-loader>

  <div *ngIf="ebSepaFileTransactionList.apiErrorFlg">
    Error: {{ebSepaFileTransactionList.apiErrorMessage}}
  </div>

  <div class="mc-filter fancy-label">

    <i class="fas fa-info-circle mc-tooltip-top-margin" tooltipClass="mc-tooltip-length"
       ngbTooltip="{{'cc.my-factoring.search-by-consumer-name-description-contract-number' | translate}}"
       placement="bottom-left"></i> <!-- TODO for release, maybe make a component -->

      <mcc-fi-textbox [isClearEnabled]="true" label="{{'cc.common.search' | translate}}" name="txbSearchTerm" [mcForm]="mcForm"
                    (eventEnterClicked)="actLoad()" size="35"></mcc-fi-textbox>

      <mcc-multiselect-filter [optionVll]="ebSepaFileTransactionCdVll" [labelHasTransactionIcon]="true" label="{{'cc.common.view.status' | translate}}" name="selStatus" (eventSelectedItems)="onSelectedStatus($event)" [clearedAllFilters]="clearedAllMultipleFilters">
      </mcc-multiselect-filter>
    <mcc-fi-select [mcForm]="mcForm" name="selPausedUnpaused" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="Paused"
                   [optionsVll]="pausedUnpausedCdVll" [isSelectInFilter]="true"></mcc-fi-select>
    <mcc-fi-select [mcForm]="mcForm" name="selInDunning" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="In Dunning"
                   [optionsVll]="inDunningCdVll" [isSelectInFilter]="true"></mcc-fi-select>
    <div class="wide-input">
      <mcc-fi-select [mcForm]="mcForm" name="selCompletedDunning" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="Completed dunning"
                     [optionsVll]="completedDunningCdVll" [isSelectInFilter]="true"></mcc-fi-select>
    </div>

      <mcc-multiselect-filter [optionVll]="typeCdVll" label="{{'cc.jobs.edit.type' | translate}}" name="selType" (eventSelectedItems)="onSelectedTypeCds($event)" [clearedAllFilters]="clearedAllMultipleFilters">
      </mcc-multiselect-filter>
    <div class="wide-date">
      <mcc-fi-date-picker
        datePickerViewType="RANGE_SELECTION"
        [mcForm]="mcForm"
        name="ctrlPaymentDueDate"
        label="Payment due date range"
        (eventFromDate)="onPaymentDueDateFrom($event)"
        (eventToDate)="onPaymentDueDateTo($event)"
        [allowManualEntryFlg]="false"
        monthsDisplayed="2"
        [clearDateRange]="clearDateRangeSubject"
      >
      </mcc-fi-date-picker>
    </div>


    <button class="mc-link mc-filter-clear-button filter-clear-top-right"
            (click)="actClearFilter()">{{'cc.my-factoring.clear-all' | translate}}</button>
    <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>


  </div>

  <!--<div *ngIf="notifyMessage.type">
    {{notifyMessage.message}}
  </div>-->
  <div class="col-md-12 position-relative mt-1 mb-5">
    <div class="mc-create-user-notify-wrapper">
      <mcc-message [successMsg]="notifyMessage.successMessage" [errorMsg]="notifyMessage.errorMessage" class="text-center"></mcc-message>
      <mcc-message [errorMsg]="errorMsg" class="text-center"></mcc-message>
    </div>
  </div>



  <div class="mc-transactions-table">
    <div *ngIf="ebSepaFileTransactionList.apiSuccessFlg">
      <mcc-fi-table-3 [objectList]="ebSepaFileTransactionList"
                      [table3Config]="ebSepaFileTransactionTable3Config"
                      (eventPaginationOrSortChanged)="actLoad()"
                      (eventItemAction)="actOnTransactionTableItemAction($event)"
                      (eventSelectionChanged)="actSelectedItems($event)"></mcc-fi-table-3>
    </div>

  </div>
  <mcc-modal1-wrapper *ngIf="showTransactionAddPaymentMcb.value" [showMcb]="showTransactionAddPaymentMcb"
                      modalSizeCd="small">
    <app-shared-transaction-adjustment-add-edit-part type="payment" (eventSaved)="actOnTransactionAddPaymentClose()"
                                                     ebFactoringLevel="CHILD"
                                                     (eventCanceled)="showTransactionAddPaymentMcb.setFalse()"
                                                     [ebSepaFileTransactionList]="selectedTransactionList"
                                                     ebTransactionAdjustmentId="0"
                                                     [ebSepaFileTransaction]="ebSepaFileTransaction"
                                                     [ebSepaFileTransactionId]="actionEbSepaFileTransactionId"></app-shared-transaction-adjustment-add-edit-part>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper *ngIf="showTransactionProfileMcb.value" [showMcb]="showTransactionProfileMcb" modalSizeCd="full" (eventCanceled)="actOnTransactionProfileClose()">
    <app-shared-transaction-profile-part [ebSepaFileTransactionId]="actionEbSepaFileTransaction.id"
                                         (eventClose)="actOnTransactionProfileClose()" ebFactoringLevel="CHILD"></app-shared-transaction-profile-part>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showAddPaymentForMultipleTransactions.value"
                      [showMcb]="showAddPaymentForMultipleTransactions">
    <app-shared-transaction-payment-add-edit-part (eventCanceled)="actCancelAddEditMultiplePayments()" (eventApiError)="paymentApiErrorEvent($event)"
                                                  (eventSaved)="actPaymentMultipleTransactionsSaved()">
    </app-shared-transaction-payment-add-edit-part>
  </mcc-modal1-wrapper>

<!--  <mcc-modal1-wrapper *ngIf="showConsumerProfileMcb.value" [showMcb]="showConsumerProfileMcb" modalSizeCd="full">-->
<!--    <mc-consumer-consumer-profile-part  (eventClosed)="actCancel()" [mcConsumerId]="ebSepaFileTransaction.idCsrConsumer"></mc-consumer-consumer-profile-part>-->
<!--  </mcc-modal1-wrapper>-->

  <mcc-modal1-wrapper [showMcb]="showChangeCollectionDateMcb" *ngIf="showChangeCollectionDateMcb.value"
                      modalSizeCd="small">
    <app-shared-change-collection-date-part [selectedTransactionList]="selectedTransactionList"
                                            [selectedTransactionsNumber]="numberOfSelectedItems"
                                            [ebSepaFileTransaction]="ebSepaFileTransaction"
                                            [ebFactoring]="ebFactoring"
                                            (eventCollectionDateCanceled)="actCancelChangeCollectionModal()"
                                            (eventCollectionDateChanged)="actChangeCollectionModal()"></app-shared-change-collection-date-part>
  </mcc-modal1-wrapper>

<!--<mcc-modal1-wrapper [showMcb]="showPauseTransactionMcb" *ngIf="showPauseTransactionMcb.value">-->
<!--  <app-shared-pause-transactions (eventPausedTransaction)="onTransactionPaused()"-->
<!--                                 (eventPauseTransactionCanceled)="onPauseTransactionCanceled()"-->
<!--                                 (eventUnpausedTransaction)="onTransactionUnpaused()"-->
<!--                                 [ebSepaFileTransaction]="actionEbSepaFileTransaction"-->
<!--  ></app-shared-pause-transactions>-->
<!--</mcc-modal1-wrapper>-->

  <mcc-slide-action-set [actionSet]="actionSet" [selectedNumber]="numberOfSelectedItems"
                        (eventActionClicked)="slideActionItemClicked($event)"></mcc-slide-action-set>

</div>

