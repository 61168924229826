
import {McBillingEntitiesUsingPricelistListGENERATED} from '../_generated/mc-billing-entities-using-pricelist-list-generated.model';
import {McDateUtils} from '../common/mc-date-utils';


export class McBillingEntitiesUsingPricelistList extends McBillingEntitiesUsingPricelistListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingEntitiesUsingPricelistList();
  }


  // ---------------------------------------------------------------------

}
