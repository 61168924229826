import {McEntity2} from './mc-entity2.model';
import {McEntity2ListGENERATED} from '../_generated/mc-entity2-list-generated.model';

export class McEntity2List extends McEntity2ListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McEntity2List();
  }

  // ---------------------------------------------------------------------
}
