<div style="cursor:pointer; color: #36B1F1" class="mb-4" (click)="navigateBack()"><i class="fas fa-chevron-left link"></i><h6 style="text-transform: uppercase; display: inline"> {{'cc.pricelist.back-to-entity-profile' | translate}}</h6></div>

<h2>{{'cc.pricelist.services-and-pricelist' | translate}}</h2>

<div class="mt-4 mb-3" *ngIf="mcBillingService2PriceListList.getCount() > 0"><i class="fas fa-tags mr-2" style="color: #36B1F1"></i><h5 style="display: inline"> {{'cc.pricelist.pricelist' | translate}}: {{mcEntity.name}} | {{mcPricelist.title}}</h5></div>

<div>
    <lib-mc-loader [showLoader]="mcBillingService2PriceListList.apiLoadingFlg"></lib-mc-loader>

    <div *ngIf="mcBillingService2PriceListList.apiErrorFlg">
        Error: {{mcBillingService2PriceListList.apiErrorMessage}}
    </div>

    <div *ngIf="mcBillingService2PriceListList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="mcBillingService2PriceListList"
                  [table3Config]="mcBillingService2PriceListTable3Config"
                 (eventPaginationOrSortChanged)="actLoad()"></mcc-fi-table-3>
    </div>

</div>

<router-outlet></router-outlet>

