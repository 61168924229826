
export class SortCriteriaItem {
  direction: string;
  propertyName: string;

  constructor(sortPropertyName: string, sortDirection: string) {
    this.direction = sortDirection;
    this.propertyName = sortPropertyName;
  }
}

export class SortCriteriaList {
  public items: Array<SortCriteriaItem> = [];

  constructor() {
  }
  clear() {
    this.items = [];
  }

  addSort(sortPropertyName: string, sortDirection: string) {
    this.items.push(new SortCriteriaItem(sortPropertyName, sortDirection));
  }


  toUrlQueryString(): string {
    let result = '';
    this.items.forEach(item => {
      result += '&sort=' + item.propertyName + ',' + item.direction;
    });
    return result;
  }

}
