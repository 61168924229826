<div class="mcc-fi mcc-multiselect mcc-fi-select-{{name}} {{extraCssClass}} {{labelPositionClass}}">
<!--  <div (click)="resetSelects()" class="mcc-multiselect-clear">{{'cc.common.clear' | translate}}</div>-->
    <div>
        <label *ngIf="label" [ngClass]="{'mc-mandatory-star' : validRequiredFlg}" for="mcc-fi-{{name}}">{{label}}</label>

        <input (click)="toggleDropdown()" [(ngModel)]="itemSelectedString" class="d-block width-control" id="mcc-fi-{{name}}" readonly name="item" type="text">


      <div class="mcc-multiselect-item-group d-block">
        <div *ngIf="itemsSelectedNumber > 1" [ngbTooltip]="getSelectedItemsTooltip()" class="mcc-multiselect-item-name d-flex">
          {{itemsSelectedNumber}} {{'cc.filter.selected' | translate}}
        </div>
        <div *ngIf="itemsSelectedNumber === 1" class="mcc-multiselect-item-name d-flex">{{itemsSelectedLabels[0]}}</div>
        <div *ngIf="!itemsSelectedNumber" class="mcc-multiselect-item-name d-flex" [ngbTooltip]="getAllItemsTooltip()">{{'cc.my-factoring.all' | translate}}</div>
        <div (click)="resetSelects()" *ngIf="itemsSelectedNumber !== 0 || !itemsSelectedNumber === undefined" class="close-ms"><i class="fas fa-times"></i></div>
      </div><!--mcc-multiselect-item-group-->
      <div class="error-msg">{{errorMsg}}</div>

    </div>
    <div *ngIf="showMultiselectItems" class="mcc-multiselect-items">
      <div *ngFor="let vllItem of options">
        <input [id]="vllItem.label" type="checkbox" [checked]="vllItem.checked" #itemsCheckboxes (change)="checkSelected(vllItem)">
        <label [for]="vllItem.label">
          <img class="multiselect-transaction-icon" *ngIf="labelHasTransactionIcon && vllItem.label !== '-- all'" [src]="geTransactionIconPath(vllItem.label)">
          {{vllItem.label}}</label>
      </div>
    </div>

</div>
