/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CsrDiscountFilter} from '../models/csr-discount-filter.model';

export class CsrDiscountFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrDiscountFilter {
    console.error('MTCN-ERROR: Not Implemented: CsrDiscountFilter::createNewInstance(). Add this method to final class and return new CsrDiscountFilter();');
      throw new Error('Cannot CsrDiscountFilter::createNewInstance(). Add this method to final class and return new CsrDiscountFilter();');
  }

}
