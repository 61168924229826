import {McProduct} from './mc-product.model';
import {McProductListGENERATED} from '../_generated/mc-product-list-generated.model';

export class McProductList extends McProductListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProductList();
  }

  // ---------------------------------------------------------------------
}
