<ng-template #newUserModal let-modal>
  <!--Modal header section-->
  <div class="modal-header mc-create-user-modal-header">
    <div>
      <h4 class="modal-title" id="mc-modal-new-user-title">{{'cc.users.view.create-user-for' | translate}}:</h4>
    </div>
    <div class="mc-create-user-entity-path" *ngIf="selectedEntityPath">
      <span *ngFor="let entity of selectedEntityPath">
        {{entity}}
      </span>
    </div>
    <button type="button" class="mc-new-user-close-btn close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div><!--Modal header -->
  <!--Modal body section-->
  <div class="modal-body">
    <div class="mc-create-new-user-wrapper">
      <div class="container-fluid">
        <div class="mc-create-user-notify-wrapper">
              <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
        </div>
        <form [formGroup]="createUserForm" (submit)="saveUser()" class="mc-create-user-parent-form">
          <lib-mc-stepper [isDataFormInvalid]="isUserFormValid">
            <cdk-step>
              <lib-mc-user-general-data
                [parentForm]="createUserForm"
                [modalArea]="modalArea"
                (isDataFormInvalid)="userDataFormValid($event)"
                (selectedImagePath)="imagePathFile($event)"
              ></lib-mc-user-general-data>
            </cdk-step>
            <cdk-step>
              <lib-mc-user-roles-data
                [parentForm]="createUserForm"
                [modalArea]="modalArea"
                [lastChildEntity]="lastChildEntity"
              ></lib-mc-user-roles-data>
            </cdk-step>
<!--            <cdk-step>-->
<!--              <lib-mc-user-entities-data></lib-mc-user-entities-data>-->
<!--            </cdk-step>-->
            <cdk-step>
              <lib-mc-user-summary-data
                [parentForm]="createUserForm"
                [imagePath]="imagePath"
                (createUserBtnType)="saveBtnType($event)">
              </lib-mc-user-summary-data>
            </cdk-step>
          </lib-mc-stepper>
        </form>
      </div><!--Container /end-->
    </div><!--Wrapper /end-->
  </div><!--Modal body /end-->
</ng-template>

<button class="system-action-btn" id="newUserModalButton" (click)="openModal(newUserModal)" #openModalBtn>
  <i class="fas fa-user-plus mc-add-new-user-icon"></i>
  {{'cc.users.view.create-new-user' | translate}}
</button>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
