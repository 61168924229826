/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingStatementTable3ConfigGENERATED} from '../_generated/mc-billing-statement-table-3-config-generated.model';

// import {McBillingStatementGENERATED} from '../_generated/mc-billing-statement-generated.model';

export class McBillingStatementPayoutFileTable3ConfigModel extends McBillingStatementTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colChildEntity', 'Entity', 'childEntityName', '');
    this.addColumn('colAccountOwner', this.t('cc.common.account-owner'), 'accountOwner', '');
    this.addColumn('colIBAN', 'IBAN', 'iban', '');
    this.addColumn('colBIC', 'BIC', 'bic', '');
    this.addColumn('colTotalAmount', 'Total amount', 'totalPayoutFormatted', '').extraCssClass = 'text-right';
    this.addColumn('colDescription', 'Description', 'payoutFileDescription', '');

  }
}
