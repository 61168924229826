<div class="content-wrapper">

  <div class="content-header">
    <h1>Resend Options</h1>
  </div>

  <div class="content-body">

    <!-- INFO -->
    <div class="mb-4">
      <mcc-row-title-description title="{{'cc.my-factoring.consumer' | translate}}"
                                 description="{{mcConsumer.fldFullName()}}"></mcc-row-title-description>
      <mcc-row-title-description title="{{'cc.processes.process-type' | translate}}"
                                 description="{{mcConsumerAction.getTypeCdLabel()}}"></mcc-row-title-description>
      <mcc-row-title-description title="Send date&time"
                                 description="{{mcConsumerAction.getSentDatetimeStr()}}"></mcc-row-title-description>
      <mcc-row-title-description title="{{'cc.jobs.view.actions' | translate}}"
                                 descriptionInnerHtml="{{actionList}}"></mcc-row-title-description>
    </div>

    <!-- EMAIL -->
    <div class="row">

      <div class="col-md-12">
        <mcc-fi-checkbox label="{{'cc.common.edit.email' | translate}}"
                         name="ctrlEmail"
                         [value]="mcConsumerAction.emailActionFlg"
                         [mcForm]="mcForm"></mcc-fi-checkbox>
      </div>

      <div class="col-md-6">

        <div class="" [ngClass]="{'mc-disable' : !mcForm.getValue('ctrlEmail')}">
          <mcc-fi-radio-buttons2 [name]="'ctrlEmailOptions'"
                                 [directionHorizontal]="false"
                                 [mcForm]="mcForm"
                                 [value]="'CURRENT_EMAIL'"
                                 [optionsVll]="emailOptionsVll"></mcc-fi-radio-buttons2>

          <span>{{mcConsumer.email}}</span>
          <div [ngClass]="{'mc-disable' : mcForm.getValue('ctrlEmailOptions') === 'CURRENT_EMAIL'}">
            <mcc-fi-textbox [mcForm]="mcForm" [name]="'ctrlDifferentEmail'"
                            [validRequiredFlg]="mcForm.getValue('ctrlEmailOptions') !== 'CURRENT_EMAIL' && mcForm.getValue('ctrlEmail')"
                            [validWhitespaces]="true"></mcc-fi-textbox>

            <mcc-fi-checkbox label="Update consumer's email address"
                             name="ctrlUpdateEmailAddress"
                             [value]="true"
                             [mcForm]="mcForm"></mcc-fi-checkbox>

            <i class="fas fa-info-circle" ngbTooltip="This email will be used from now on." placement="bottom"></i>
          </div>
        </div>

        <div *ngIf="!mcConsumerAction.emailActionFlg" class="col-md-6"
             [ngClass]="{'mc-disable' : !mcForm.getValue('ctrlEmail')}">
          <mcc-fi-textbox-with-token name="ctrlEmailSubject" label="{{'cc.processes.email-subject' | translate}}"
                                     tokenLabel="Tokens"
                                     [tokenVll]="tokenVll"
                                     [validRequiredFlg]="this.mcForm.getValue('ctrlEmail')"
                                     [mcForm]="mcForm"></mcc-fi-textbox-with-token>

          <mcc-fi-textarea-with-token tokenLabel="Tokens" name="ctrlEmailBody"
                                      label="{{'cc.processes.email-body' | translate}}" [numCols]="45"
                                      [tokenVll]="tokenVll"
                                      [validRequiredFlg]="this.mcForm.getValue('ctrlEmail')"
                                      [mcForm]="mcForm">
          </mcc-fi-textarea-with-token>
        </div>

      </div>

    </div>
    <hr>

    <!-- POST -->
    <div class="">

      <mcc-fi-checkbox label="Post"
                       name="ctrlPost"
                       [value]="mcConsumerAction.postActionFlg"
                       [mcForm]="mcForm"></mcc-fi-checkbox>

      <div class="" [ngClass]="{'mc-disable' : !mcForm.getValue('ctrlPost')}">
        <mcc-fi-radio-buttons2 [name]="'ctrlPostOptions'"
                               [directionHorizontal]="false"
                               [mcForm]="mcForm"
                               [value]="'CURRENT_POST_ADDRESS'"
                               [optionsVll]="postOptionsVll"></mcc-fi-radio-buttons2>

        <span>{{mcConsumer.addressSupplement}}</span>

        <div [ngClass]="{'mc-disable' : mcForm.getValue('ctrlPostOptions') === 'CURRENT_POST_ADDRESS'}">
          <mcc-fi-textarea [mcForm]="mcForm" [name]="'ctrlDifferentAddress'" numCols="45"
                           [validRequiredFlg]="mcForm.getValue('ctrlPostOptions') !== 'CURRENT_POST_ADDRESS' && mcForm.getValue('ctrlPost')"
                           [validWhitespaces]="true"></mcc-fi-textarea>

          <mcc-fi-checkbox label="Update consumer's address"
                           name="ctrlUpdateAddress"
                           [value]="true"
                           [mcForm]="mcForm"></mcc-fi-checkbox>

          <i class="fas fa-info-circle" ngbTooltip="This address will be used from now on." placement="bottom"></i>
        </div>
      </div>

    </div>

    <hr>

    <!-- SMS -->

    <div class="row">

      <div class="col-md-12">
        <mcc-fi-checkbox label="SMS"
                         name="ctrlSms"
                         [value]="mcConsumerAction.smsActionFlg"
                         [mcForm]="mcForm"></mcc-fi-checkbox>
      </div>

      <div class="col-md-6">

        <div class="" [ngClass]="{'mc-disable' : !mcForm.getValue('ctrlSms')}">
          <mcc-fi-radio-buttons2 [name]="'ctrlSmsOptions'"
                                 [directionHorizontal]="false"
                                 [mcForm]="mcForm"
                                 [value]="'CURRENT_CELLPHONE_NUMBER'"
                                 [optionsVll]="smsOptionsVll"></mcc-fi-radio-buttons2>

          <span>{{mcConsumer.cellphone}}</span>

          <div [ngClass]="{'mc-disable' : mcForm.getValue('ctrlSmsOptions') === 'CURRENT_CELLPHONE_NUMBER'}">
            <mcc-fi-textbox [mcForm]="mcForm" [name]="'ctrlDifferentPhoneNumber'"
                            [validRequiredFlg]="mcForm.getValue('ctrlSmsOptions') !== 'CURRENT_CELLPHONE_NUMBER' && mcForm.getValue('ctrlSms')"
                            [validWhitespaces]="true"></mcc-fi-textbox>

            <mcc-fi-checkbox label="Update consumer's phone number"
                             name="ctrlUpdatePhoneNumber"
                             [value]="true"
                             [mcForm]="mcForm"></mcc-fi-checkbox>

            <i class="fas fa-info-circle" ngbTooltip="This phone number will be used from now on."
               placement="bottom"></i>
          </div>
        </div>

      </div>

      <div class="col-md-6" [ngClass]="{'mc-disable' : !mcForm.getValue('ctrlSms')}">

        <mcc-fi-textarea-with-token tokenLabel="Tokens" [tokenVll]="tokenVll" name="ctrlSmsBody"
                                    label="{{'cc.processes.sms-body' | translate}}" [numCols]="45"
                                    [validRequiredFlg]="this.mcForm.getValue('ctrlSms')"
                                    [mcForm]="mcForm">
        </mcc-fi-textarea-with-token>

      </div>

    </div>

    <hr>

    <div>
      <mcc-fi-textarea [mcForm]="mcForm" [name]="'ctrlReason'" numCols="45" numRows="3" label="Reason"
                       [validRequiredFlg]="true"
                       [validWhitespaces]="true"></mcc-fi-textarea>
    </div>


    <!--    <mcc-message *ngIf="mcConsumerAction.id <= 0" infoMsg="No consumer action for this items"></mcc-message>-->
  </div>

  <div class="content-footer">
    <button class="btn btn-secondary move-right" (click)="actCancel()">{{'cc.common.edit.cancel' | translate}}</button>
    <button class="btn btn-primary" (click)="actResend()" [disabled]="resendButtonDisabledMcb.value"><i
      class="fas fa-sync"></i> Resend
    </button>
  </div>

</div>
