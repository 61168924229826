/*BbmTestObj*/
import {CsrRecurringDetailsGENERATED} from '../_generated/csr-recurring-details-generated.model';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McGod} from '../extra/mc-god.service';

export class CsrRecurringDetails extends CsrRecurringDetailsGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrRecurringDetails();
  }

  getWeekDaysVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add(1, McGod.t('cc.common.every-monday'));
    list.add(2, McGod.t('cc.common.every-tuesday'));
    list.add(3, McGod.t('cc.common.every-wednesday'));
    list.add(4, McGod.t('cc.common.every-thursday'));
    list.add(5, McGod.t('cc.common.every-friday'));
    return list;
  }

  public static override getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add(CsrRecurringDetailsGENERATED.TYPE_CD_WEEKLY, McGod.t('Weekly'));
    list.add(CsrRecurringDetailsGENERATED.TYPE_CD_MONTHLY, McGod.t('Monthly'));
    list.add(CsrRecurringDetailsGENERATED.TYPE_CD_QUARTERLY, McGod.t('Quarterly'));
    list.add(CsrRecurringDetailsGENERATED.TYPE_CD_HALF_YEARLY, McGod.t('Half-yearly'));
    list.add(CsrRecurringDetailsGENERATED.TYPE_CD_YEARLY, McGod.t('Yearly'));
    list.add(CsrRecurringDetails.TYPE_CD_ONE_TIME, McGod.t('One time'));


    return list;
  }

  getMonthDaysVll(): McValueLabelList {
    const list = new McValueLabelList();
    for (let i = 1; i < 29; i++) {
      list.add(i, `${McGod.t('cc.jobs.edit.every')} ${i}.`);
    }
    return list;
  }


  getQuarterlyVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add(1, McGod.t('cc.common.1st-month-of-the-quarter'));
    list.add(2, McGod.t('cc.common.2nd-month-of-the-quarter'));
    list.add(3, McGod.t('cc.common.3rd-month-of-the-quarter'));
    return list;
  }

  getHalfYearlyVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add(1, McGod.t('cc.common.january-july'));
    list.add(2, McGod.t('cc.common.february-august'));
    list.add(3, McGod.t('cc.common.march-september'));
    list.add(4, McGod.t('cc.common.april-october'));
    list.add(5, McGod.t('cc.common.may-november'));
    list.add(6, McGod.t('cc.common.june-december'));
    return list;
  }

  getYearlyVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add(1, McGod.t('cc.common.january'));
    list.add(2, McGod.t('cc.common.february'));
    list.add(3, McGod.t('cc.common.march'));
    list.add(4, McGod.t('cc.common.april'));
    list.add(5, McGod.t('cc.common.may'));
    list.add(6, McGod.t('cc.common.june'));
    list.add(7, McGod.t('cc.common.july'));
    list.add(8, McGod.t('cc.common.august'));
    list.add(9, McGod.t('cc.common.september'));
    list.add(10, McGod.t('cc.common.october'));
    list.add(11, McGod.t('cc.common.november'));
    list.add(12, McGod.t('cc.common.december'));
    return list;
  }

  static getReccuringTypes(durationInMonths: number) {
    let reccuringTypes = [
      { label: 'cc.consumer.weekly', value: CsrRecurringDetailsGENERATED.TYPE_CD_WEEKLY, durations: [0, 1, 3, 6, 12, 24, 36] },
      { label: 'cc.consumer.monthly', value: CsrRecurringDetailsGENERATED.TYPE_CD_MONTHLY, durations: [0, 1, 3, 6, 12, 24, 36] },
      { label: 'cc.consumer.quarterly', value: CsrRecurringDetailsGENERATED.TYPE_CD_QUARTERLY, durations: [0, 3, 6, 12, 24, 36]},
      { label: 'cc.consumer.half-yearly', value: CsrRecurringDetailsGENERATED.TYPE_CD_HALF_YEARLY, durations: [0, 6, 12, 24, 36]},
      { label: 'cc.consumer.yearly', value: CsrRecurringDetailsGENERATED.TYPE_CD_YEARLY, durations: [0, 12, 24, 36]}
    ];

    return reccuringTypes.filter(type => {
      return type.durations.includes(durationInMonths);
    });
  }

  static getReccuringLabel(reccuringName: string) {
    switch (reccuringName) {
      case CsrRecurringDetailsGENERATED.TYPE_CD_WEEKLY:
        return 'cc.consumer.weekly';
      case CsrRecurringDetailsGENERATED.TYPE_CD_MONTHLY:
        return 'cc.consumer.monthly';
      case CsrRecurringDetailsGENERATED.TYPE_CD_QUARTERLY:
        return 'cc.consumer.quarterly';
      case CsrRecurringDetailsGENERATED.TYPE_CD_HALF_YEARLY:
        return 'cc.consumer.half-yearly';
      case CsrRecurringDetailsGENERATED.TYPE_CD_YEARLY:
        return 'cc.consumer.yearly';
      default:
        return '';
    }
  }

  public getMonthCdLabel(): string {
    return CsrRecurringDetails.getTypeCdLabel(this.typeCd);
  }

  public static getOneTimeVll(): McValueLabelList{
    const list = new McValueLabelList();
    list.add(CsrRecurringDetails.TYPE_CD_ONE_TIME, McGod.t('One time'));

    return list;
  }

}
