/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingService2VatTable3ConfigGENERATED} from '../_generated/mc-billing-service-2-vat-table-3-config-generated.model';

// import {McBillingService2VatGENERATED} from '../_generated/mc-billing-service-2-vat-generated.model';

export class McBillingService2VatTable3Config extends McBillingService2VatTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdBillingService', 'Id of billing service', 'idBillingService', 'idBillingService');
    this.addColumn('colIdVat', 'Id of VAT', 'idVat', 'idVat');


  }
}
