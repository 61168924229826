import {Component, Inject, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {
  EbSepaFileTransaction,
  EbTransactionAdjustmentTable3Config,
  McBoolean,
  McHtmlButton,
  EbTransactionAdjustment,
  McGod,
  Table3Config,
  BaseDirective,
  McProcessItemTransactionAdjustmentTable3ConfigModel,
  McEntity2,
  EbFactoring,
  EbSepaFileTransactionList,
  EbSepaFileTransactionService,
  McConsumerBalanceService, CurrencyUtil, EbFactoringService, CsrConsumerService
} from '@miticon-ui/mc-core';
import {  ActivatedRoute } from '@angular/router';
import { takeUntil} from 'rxjs/operators';

const enum DialogMarkAsSentToInkassoAction {
  ACT_CANCEL = 'btn-secondary',
}

@Component({
  selector: 'app-shared-transaction-profile-part',
  templateUrl: './app-shared-transaction-profile-part.component.html',
})
export class AppSharedTransactionProfilePartComponent extends BaseDirective implements OnInit {

  @Input() ebSepaFileTransactionId!: number;
  @Input() showEditTransaction = true;
  @Input() ebFactoring = new EbFactoring();
  @Input() ebFactoringLevel!: string;

  @Output() eventClose = new EventEmitter();

  ebTransactionAdjustmentsTableConfig = new Table3Config();
  ebSepaFileTransaction = new EbSepaFileTransaction();
  showAddEditTransactionAdjustmentMcb = new McBoolean();
  addEditTransactionAdjustmentId!: number;
  addEditTransactionAdjustmentType!: 'payment' | 'adjustment';
  mcGod = McGod.getInstance();
  showPauseUnpauseTransactionMcb = new McBoolean();
  showChangeCollectionDateMcb = new McBoolean();
  showExportMcb = new McBoolean();
  showMarkAsSentToInkassoMcb = new McBoolean();
  successMsg!: string;
  canPauseUnpauseTransactionFlg = false;
  loggedEntityId = McGod.getLoggedEntityIdFromToken();
  rejectedStatusCd = EbSepaFileTransaction.STATUS_CD_REJECTED;
  mcEntity = new McEntity2();
  isTransactionUnpaused = false;
  transactionList = new EbSepaFileTransactionList();
  markAsSentToBankDialogMessage!: string;
  buttonsMarkAsSentToInkassoDialog = new Map<string, string>();
  errorMsg = '';
  loadingFlg = false;
  errorBalanceMsg!: string;
  successBalanceMsg!: string;
  balanceAmount!: number;
  currencyCode!: string;

  // ---------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
    private transactionService: EbSepaFileTransactionService,
    private mcConsumerBalanceService: McConsumerBalanceService,
    private csrConsumerService: CsrConsumerService,
    private factoringService: EbFactoringService) {
    super();
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.markAsSentToBankDialogMessage = 'Are you sure you want to mark 1 transaction as "Sent to Inkasso"?';
    this.buttonsMarkAsSentToInkassoDialog.set('btn-primary', 'Yes');
    this.buttonsMarkAsSentToInkassoDialog.set('btn-secondary', this.mcGod.t('cc.common.edit.cancel'));
    this.actLoad();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.getConsumerBalanceFromService();
    this.ebSepaFileTransaction.loadById(this.ebSepaFileTransactionId, () => {
      this.getCurrencyCodeDependingOnFactoringLevel();
      if (this.showEditTransaction) {
        this.ebTransactionAdjustmentsTableConfig = new EbTransactionAdjustmentTable3Config();
      } else {
        this.ebTransactionAdjustmentsTableConfig = new McProcessItemTransactionAdjustmentTable3ConfigModel();
      }
      this.ebSepaFileTransaction.ebTransactionAdjustments.items.forEach(item => {
        item.amountStr = this.format(item.amount);
      });
      this.canPauseUnpauseTransactionFlg = this.ebSepaFileTransaction.canPauseUnpauseTransactionFlg(this.ebSepaFileTransaction);
      this.mcEntity.loadById(this.ebSepaFileTransaction.idMcEntity);
      this.transactionList.addItem(this.ebSepaFileTransaction);
      this.validatePausedTransactionUsedDays();
      this.getConsumerBalanceFromService();
      this.canPauseUnpauseTransactionFlg = this.ebSepaFileTransaction.canPauseUnpauseTransactionFlg(this.ebSepaFileTransaction);
      this.ebSepaFileTransaction.ebTransactionAdjustments.items.map(item => item.amountStr = this.format(item.amount));
    });
  }

  actClose() {
    this.eventClose.emit();
  }

  getCurrencyCodeDependingOnFactoringLevel() {
    if(this.ebFactoringLevel === EbFactoring.FACTORING_LEVEL_PARENT) {
      this.currencyCode = this.factoringService.parentFactorings?.find(factoring => factoring.id ===  this.ebSepaFileTransaction.idEbFactoring)?.mcCurrency.code;
    } else {
      this.currencyCode = this.factoringService.childFactorings?.find(factoring => factoring.id === this.ebSepaFileTransaction.idEbFactoring)?.mcCurrency.code;
    }
  }

  actShowAddTransactionPayment() {
    if (!this.ebSepaFileTransaction.isStatusCdREJECTED()) {
      this.addEditTransactionAdjustmentType = 'payment';
      this.addEditTransactionAdjustmentId = 0;
      this.showAddEditTransactionAdjustmentMcb.setTrue();
    }
  }

  actSettleWithBalance() {
    const body: any = {};
    body.paymentMethod = 'CREDIT';
    body.amount = null;

    if (this.balanceAmount === 0 || !this.balanceAmount) {
      this.successBalanceMsg = '';
      this.errorBalanceMsg = McGod.t('cc.consumer.overview.this-action-cannot-be-completed-the-balance-on-the-consumers-account-is-0') + '.';
    } else {
      this.ebSepaFileTransaction.apiService.payTransaction(this.ebSepaFileTransaction.id, body)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
        this.errorBalanceMsg = '';
        this.successBalanceMsg = McGod.t('cc.consumer.transaction-has-been-successfully-settled-with-balance') + '.';
        this.actLoad();
      }, error => {
        this.successBalanceMsg = '';
        this.errorBalanceMsg = McGod.t('cc.consumer.transaction-has-not-been-successfully-settled-with-balance-try-again-later') + '.';
      });
    }

  }

  canSettleForBalanceStatus(): boolean {
    return this.ebSepaFileTransaction.isStatusCdEXPORTED() || this.ebSepaFileTransaction.isStatusCdPAID() ||
      this.ebSepaFileTransaction.isStatusCdSETTLED() || this.ebSepaFileTransaction.isStatusCdREJECTED() ||
      this.ebSepaFileTransaction.isStatusCdSENT_TO_INKASSO();
  }


  actShowAddTransactionAdjustment() {
    if (!this.ebSepaFileTransaction.isStatusCdREJECTED() && !this.ebSepaFileTransaction.pausedFlg) {
      this.addEditTransactionAdjustmentType = 'adjustment';
      this.addEditTransactionAdjustmentId = 0;
      this.showAddEditTransactionAdjustmentMcb.setTrue();
    }
  }

  actShowEditTransactionAdjustment(aTransactionAdjustmentId: number) {
    this.addEditTransactionAdjustmentId = aTransactionAdjustmentId;
    this.showAddEditTransactionAdjustmentMcb.setTrue();
  }
  actOnTransactionAdjustmentTableItemAction(itemAction: McHtmlButton) {
    switch (itemAction.actionCdForEvent) {
      case EbTransactionAdjustment.ACTION_EDIT:
        this.actShowEditTransactionAdjustment(itemAction.item.id);
        break;
      case EbTransactionAdjustment.ACTION_DELETE:
        const ebTransactionAdjustment: EbTransactionAdjustment = itemAction.item;
        ebTransactionAdjustment.delete(() => {
          this.actLoad();
        });
        break;
    }

  }

  actOnAddEditTransactionAdjustmentSaved() {
    this.actLoad();
    this.showAddEditTransactionAdjustmentMcb.setFalse();
  }

  onTransactionPausedFromProfile() {
      this.successMsg = 'The transaction was paused successfully';
      this.actLoad();
      this.showPauseUnpauseTransactionMcb.setFalse();
  }

  onPauseTransactionCanceledFromProfile() {
    this.showPauseUnpauseTransactionMcb.setFalse();
  }

  actShowPauseUnpauseTransaction() {
    this.showPauseUnpauseTransactionMcb.setTrue();
  }

  onTransactionUnpausedFromProfile() {
    this.isTransactionUnpaused = true;
    this.successMsg = 'The transaction was unpaused successfully';
    this.actLoad();
    this.showPauseUnpauseTransactionMcb.setFalse();
  }

  getTooltipForAdjustment() {
    if (this.ebSepaFileTransaction.isStatusCdREJECTED()) {
      return 'You can not add an adjustment because the transaction is rejected.';
    } else if (this.ebSepaFileTransaction.pausedFlg) {
      return 'You can not add an adjustment because the transaction is paused.';
    } else {
      return '';
    }
  }

  getTooltipForPayment() {
    if (this.ebSepaFileTransaction.isStatusCdREJECTED()) {
      return 'You can not add a payment because the transaction is rejected.';
    } else {
      return '';
    }
  }

  validatePausedTransactionUsedDays() {
    if (isNaN(this.ebSepaFileTransaction.pauseDaysCountWithoutCurrentPauseDays)) {
      this.ebSepaFileTransaction.pauseDaysCountWithoutCurrentPauseDays = 0;
    }
    if (isNaN(this.ebSepaFileTransaction.pausedDaysCount)) {
      this.ebSepaFileTransaction.pausedDaysCount = 0;
    }
  }

  getMaxPauseDaysLbl() {
    if (!this.isTransactionUnpaused && this.ebSepaFileTransaction.maxPauseDays) {
      return this.ebSepaFileTransaction.maxPauseDays;
    } else {
      return this.mcEntity.maxTransactionPauseDays;
    }
  }

  getConsumerBalanceFromService() {
    if (this.ebSepaFileTransaction.exists()) {
      this.csrConsumerService.getConsumerBalance(this.ebSepaFileTransaction.idCsrConsumer).subscribe((res) => {
        if (res === 0) {
          this.balanceAmount = 0;
        } else {
          this.balanceAmount = res;
        }
      });
    }

  }

  actShowChangeCollectionDate() {
    this.showChangeCollectionDateMcb.setTrue();
  }

  actCancelChangeCollectionModal() {
    this.showChangeCollectionDateMcb.setFalse();
  }

  actChangeCollectionModal() {
    this.actLoad();
    this.showChangeCollectionDateMcb.setFalse();
  }

  actShowMarkAsSentToInkasso() {
    this.showMarkAsSentToInkassoMcb.setTrue();
  }

  onMcDialogBoxMarkAsSentToInkassoActionButtonClicked(action: string) {
    if (action === DialogMarkAsSentToInkassoAction.ACT_CANCEL) {
      this.showMarkAsSentToInkassoMcb.setFalse();
    } else {
      this.transactionService.markAsSentToInkasso(this.transactionList.getIds()).subscribe(() => {
        this.showMarkAsSentToInkassoMcb.setFalse();
        this.actLoad();
        this.successMsg = 'Transactions are successfully marked as "Sent to Inkasso".';
      }, error => {
        this.showMarkAsSentToInkassoMcb.setFalse();
        this.errorMsg = error.error.message;
      });
    }
  }

  actMarkAsReturnedFromInkasso() {
    if (this.ebSepaFileTransaction.canMarkAsReturnedFromInkasso()) {
      const statusCd = this.ebSepaFileTransaction.statusCd;
      this.ebSepaFileTransaction.statusCd = EbSepaFileTransaction.STATUS_CD_RETURNED_FROM_INKASSO;
      this.loadingFlg = true;
      this.transactionService.markAsReturnedFromInkasso(this.ebSepaFileTransaction).subscribe(() => {
        this.successMsg = `${this.mcGod.t('cc.transactions.transaction-s-are-successfully-marked-as-returned-from-inkasso')}.`;
        this.loadingFlg = false;
      }, error => {
        this.errorMsg = error.error.message;
        this.ebSepaFileTransaction.statusCd = statusCd;
        this.loadingFlg = false;
      });
    }
  }

  format(value: number): string {
    if (value) {
      return CurrencyUtil.format(value, this.currencyCode);
    }
    return CurrencyUtil.format(0, this.currencyCode);
  }
}


