import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";

@Component({
  selector: "mcc-dynamic-component-generator",
  templateUrl: "./mcc-dynamic-component-generator.component.html",
})
export class MccDynamicComponentGeneratorComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @ViewChild("dynamicContainer", { read: ViewContainerRef })
  dynamicContainer: ViewContainerRef;

  @Input() dynamicComponentClass: any;
  @Input() dynamicComponentConfigObject: any; // TODO bmitic: create Base Config Object

  /*Variables*/
  public dynComponentInstance: any;
  // ---------------------------------------------------------------------
  constructor(
    private resolver: ComponentFactoryResolver,
    @Inject("designConfig") design: any
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["dynamicComponentConfigObject"]) {
      if (this.dynComponentInstance != null) {
        this.dynamicComponentConfigObject.applyToComponent(
          this.dynComponentInstance
        );
      }
    }
  }

  ngAfterViewInit() {
    const componentFactory = this.resolver.resolveComponentFactory(
      this.dynamicComponentClass
    );
    this.dynamicContainer.clear();
    const componentRef =
      this.dynamicContainer.createComponent(componentFactory);
    this.dynComponentInstance = componentRef.instance;
    this.dynComponentInstance.config = this.dynamicComponentConfigObject;
  }

  // ---------------------------------------------------------------------
  ngOnInit() {}
}
