/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McConsumerHistoryFilter} from '../models/mc-consumer-history-filter.model';

export class McConsumerHistoryFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McConsumerHistoryFilter {
    console.error('MTCN-ERROR: Not Implemented: McConsumerHistoryFilter::createNewInstance(). Add this method to final class and return new McConsumerHistoryFilter();');
      throw new Error('Cannot McConsumerHistoryFilter::createNewInstance(). Add this method to final class and return new McConsumerHistoryFilter();');
  }

}
