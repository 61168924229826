import {BaseObject} from '../_core/base-object';
import {McEntityIdAndName} from './mc-entity-id-and-name.model';
import {McProcessRunJobInfo} from './mc-process-run-job-info.model';
import {McRunItemEmailInfo} from './mc-run-item-email-info.model';

export abstract class McProcessRunJobInfoAbstract extends BaseObject {
  idMcProcessJobExecution: number;
  createdOn: any;
  successRunEntitiesCount: number;
  failedRunEntitiesCount: number;
  deliveredEmailsCount: number;
  skippedEmailsCount: number;
  errorEmailsCount: number;

  public propertiesRegular: string[] = ['idMcProcessJobExecution', 'createdOn', 'successRunEntitiesCount', 'failedRunEntitiesCount', 'deliveredEmailsCount', 'skippedEmailsCount', 'errorEmailsCount'];

  public static createFromJson(aItemJsonObj: any): McProcessRunJobInfo {
    const item = this.createNewInstance();
    item.populateFromJson(aItemJsonObj);
    return item;
  }

  public populateFromJson(aSourceJson: any) {
    this.propertiesRegular.forEach((key) => {
      if (aSourceJson.hasOwnProperty(key)) {
        // @ts-ignore
        this[key] = aSourceJson[key];
      }
    });
  }

  public static createNewInstance(): McProcessRunJobInfo {
    console.error('MTCN-ERROR: Not Implemented: McProcessRunJobInfo::createNewInstance(). Add this method to final class and return new McProcessRunJobInfo();');
    throw new Error('Cannot McProcessRunJobInfo::createNewInstance(). Add this method to final class and return new McProcessRunJobInfo();');
  }
}
