/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbSepaExport} from '../models/eb-sepa-export.model';
import {EbSepaExportService} from '../services/eb-sepa-export.service';
import {IEbSepaExport} from '../_generated/eb-sepa-export.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {McCurrency} from '../models/mc-currency.model';


export abstract class EbSepaExportGENERATED extends BaseObject {


    public static readonly CREATION_TYPE_CD_MANUAL: string = 'MANUAL';
    public static readonly CREATION_TYPE_CD_AUTO: string = 'AUTO';
  public static readonly CREATION_TYPE_CD_LIST__ALL = [
    EbSepaExportGENERATED.CREATION_TYPE_CD_MANUAL,
    EbSepaExportGENERATED.CREATION_TYPE_CD_AUTO
];

    public static readonly STATUS_CD_EXPORTED: string = 'EXPORTED';
    public static readonly STATUS_CD_REVERTED: string = 'REVERTED';
    public static readonly STATUS_CD_PENDING: string = 'PENDING';
    public static readonly STATUS_CD_FAILED: string = 'FAILED';
    public static readonly STATUS_CD_SENT_TO_BANK: string = 'SENT_TO_BANK';
    public static readonly STATUS_CD_IN_PROGRESS: string = 'IN_PROGRESS';
    public static readonly STATUS_CD_SEND_FAILED: string = 'SEND_FAILED';
  public static readonly STATUS_CD_LIST__ALL = [
    EbSepaExportGENERATED.STATUS_CD_EXPORTED,
    EbSepaExportGENERATED.STATUS_CD_REVERTED,
    EbSepaExportGENERATED.STATUS_CD_PENDING,
    EbSepaExportGENERATED.STATUS_CD_FAILED,
    EbSepaExportGENERATED.STATUS_CD_SENT_TO_BANK,
    EbSepaExportGENERATED.STATUS_CD_IN_PROGRESS,
    EbSepaExportGENERATED.STATUS_CD_SEND_FAILED
];

    public static readonly TYPE_CD_STAR_MONEY: string = 'STAR_MONEY';
    public static readonly TYPE_CD_EBICS: string = 'EBICS';
  public static readonly TYPE_CD_LIST__ALL = [
    EbSepaExportGENERATED.TYPE_CD_STAR_MONEY,
    EbSepaExportGENERATED.TYPE_CD_EBICS
];

  public apiService: EbSepaExportService;
  public _rawJson: IEbSepaExport;
    id: number = 0;
  allDebtors: number;
  creationTypeCd: string;
  idMcBankAccount: number;
  idMcCurrency: number;
  idMcEntity: number;
  idSystemUser: number;
  idWebfile: number;
  requestedCollectionDate: number;
  sepaExportFilename = '';
  servicePeriod: number;
  statusCd: string;
  statusDetails = '';
  sysCreatedDatetime: number;
  sysUpdatedDatetime: number;
  totalAmountDue: number;
  typeCd: string;
  bankName: string;  mcCurrency: McCurrency;

  public properties: string[] = ['id', 'allDebtors', 'creationTypeCd', 'idMcBankAccount', 'idMcCurrency', 'idMcEntity', 'idSystemUser', 'idWebfile', 'requestedCollectionDate', 'sepaExportFilename', 'servicePeriod', 'statusCd', 'statusDetails', 'sysCreatedDatetime', 'sysUpdatedDatetime', 'totalAmountDue', 'typeCd', 'bankName', 'mcCurrency'];
  public propertiesRegular: string[] = ['id', 'allDebtors', 'creationTypeCd', 'idMcBankAccount', 'idMcCurrency', 'idMcEntity', 'idSystemUser', 'idWebfile', 'mcCurrency', 'requestedCollectionDate', 'sepaExportFilename', 'servicePeriod', 'statusCd', 'statusDetails', 'sysCreatedDatetime', 'sysUpdatedDatetime', 'totalAmountDue', 'typeCd', 'bankName'];
  public propertiesSpecial: string[] = ['mcCurrency'];



  public static getCreationTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbSepaExportGENERATED.CREATION_TYPE_CD_MANUAL, McGod.t('Manual'));
    list.add(EbSepaExportGENERATED.CREATION_TYPE_CD_AUTO, McGod.t('Auto'));


    return list;
  }

  public static getCreationTypeCdLabel(aCd: string): string {
    const list = EbSepaExportGENERATED.getCreationTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbSepaExportGENERATED.STATUS_CD_EXPORTED, McGod.t('cc.eb-sepa-export.exported'));
    list.add(EbSepaExportGENERATED.STATUS_CD_REVERTED, McGod.t('cc.eb-sepa-export.reverted'));
    list.add(EbSepaExportGENERATED.STATUS_CD_PENDING, McGod.t('cc.common.pending'));
    list.add(EbSepaExportGENERATED.STATUS_CD_FAILED, McGod.t('cc.common.failed'));
    list.add(EbSepaExportGENERATED.STATUS_CD_SENT_TO_BANK, McGod.t('cc.eb-sepa-export.sent-to-bank'));
    list.add(EbSepaExportGENERATED.STATUS_CD_IN_PROGRESS, McGod.t('cc.common.in_progress'));
    list.add(EbSepaExportGENERATED.STATUS_CD_SEND_FAILED, McGod.t('cc.common.send-failed'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = EbSepaExportGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbSepaExportGENERATED.TYPE_CD_STAR_MONEY, McGod.t('Star Money'));
    list.add(EbSepaExportGENERATED.TYPE_CD_EBICS, McGod.t('Ebics'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = EbSepaExportGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): EbSepaExport {
    console.error('MTCN-ERROR: Not Implemented: EbSepaExport::createNewInstance(). Add this method to final class and return new EbSepaExport();');
    throw new Error('Cannot EbSepaExport::createNewInstance(). Add this method to final class and return new EbSepaExport();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbSepaExport): EbSepaExport {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbSepaExportService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbSepaExport AABB()');
          if (this._rawJson['mcCurrency']) {
        this.mcCurrency = McCurrency.createFromJson(this._rawJson['mcCurrency']);
      }

  }

  // ---------------------------------------------------------------------

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getTotalAmountDueStr(): string {
       return McGod.formatNumberToMoney(this.totalAmountDue);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('mcCurrency')) {
        if (this.mcCurrency != null) {
            dto['mcCurrency'] = this.mcCurrency.toDto();
        } else {
            dto['mcCurrency'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --


  public getCreationTypeCdLabel(): string {
    return EbSepaExportGENERATED.getCreationTypeCdLabel(this.creationTypeCd);
  }


 public  isCreationTypeCdMANUAL(): boolean {
    const result = (this.creationTypeCd === EbSepaExportGENERATED.CREATION_TYPE_CD_MANUAL);

    return result;
  }

 public  isCreationTypeCdAUTO(): boolean {
    const result = (this.creationTypeCd === EbSepaExportGENERATED.CREATION_TYPE_CD_AUTO);

    return result;
  }


  public getStatusCdLabel(): string {
    return EbSepaExportGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdEXPORTED(): boolean {
    const result = (this.statusCd === EbSepaExportGENERATED.STATUS_CD_EXPORTED);

    return result;
  }

 public  isStatusCdREVERTED(): boolean {
    const result = (this.statusCd === EbSepaExportGENERATED.STATUS_CD_REVERTED);

    return result;
  }

 public  isStatusCdPENDING(): boolean {
    const result = (this.statusCd === EbSepaExportGENERATED.STATUS_CD_PENDING);

    return result;
  }

 public  isStatusCdFAILED(): boolean {
    const result = (this.statusCd === EbSepaExportGENERATED.STATUS_CD_FAILED);

    return result;
  }

 public  isStatusCdSENT_TO_BANK(): boolean {
    const result = (this.statusCd === EbSepaExportGENERATED.STATUS_CD_SENT_TO_BANK);

    return result;
  }

 public  isStatusCdIN_PROGRESS(): boolean {
    const result = (this.statusCd === EbSepaExportGENERATED.STATUS_CD_IN_PROGRESS);

    return result;
  }

 public  isStatusCdSEND_FAILED(): boolean {
    const result = (this.statusCd === EbSepaExportGENERATED.STATUS_CD_SEND_FAILED);

    return result;
  }


  public getTypeCdLabel(): string {
    return EbSepaExportGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdSTAR_MONEY(): boolean {
    const result = (this.typeCd === EbSepaExportGENERATED.TYPE_CD_STAR_MONEY);

    return result;
  }

 public  isTypeCdEBICS(): boolean {
    const result = (this.typeCd === EbSepaExportGENERATED.TYPE_CD_EBICS);

    return result;
  }



  // ---------------------------------------------------------------------
  public exportSepaAndGet( ebSepaExport: any, transactionIds: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.exportSepaAndGet(ebSepaExport,transactionIds)
      .subscribe(responseJson => {
        if (responseJson !== null){
          this.populateFromJson(responseJson);
        }
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

// ---------------------------------------------------------------------
public callRevertSepaById( id: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.revertSepaById(id).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

}
