
import {Table3Config} from '../extra/table-3-config';

// import {EbInterest_rateGENERATED} from '../_generated/eb-interest_rate-generated.model';

export class EbInterest_rateTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colEndDatetime', 'End DateTime', 'endDatetime', 'endDatetime');
    this.addColumn('colInterestRate', 'Interest Rate', 'interestRate', 'interestRate');
    this.addColumn('colStartDatetime', 'Start DateTime', 'startDatetime', 'startDatetime');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colTypeCd', 'Type CD', 'typeCd', 'typeCd');

*/
  }
}
