/*BbmTestObj*/
import {EbSepaTransactionGENERATED} from '../_generated/eb-sepa-transaction-generated.model';

export class EbSepaTransaction extends EbSepaTransactionGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaTransaction();
  }

  // ---------------------------------------------------------------------
}
