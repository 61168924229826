import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation} from "@angular/core";
import {FormControl, FormGroup} from '@angular/forms';
import {McForm} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';
import {MccFiCheckboxEventAfterValidation} from './mcc-fi-checkbox-event-after-validation';

@Component({
  selector: 'mcc-fi-checkbox',
  templateUrl: './mcc-fi-checkbox.component.html',
  styleUrls: ['./mcc-fi-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MccFiCheckboxComponent implements OnInit, OnDestroy {

  // checkBoxForm: FormGroup;
  labelPositionClass!: string;
  formValidationTriggerSubscription!: Subscription;

  @Input() mcForm!: McForm;
  @Input() extraCssClasses!: string;
  @Input() name!: string;
  @Input() value = false;
  // @Input() frmGroup: FormGroup;
  @Input() label!: string;
  @Input() validRequiredFlg = false;
  // @Input() customValidator;
  // @Input() validationSetupObject: McValidation;
  @Input() validationInComponent = false;
  @Output() eventValueChanged = new EventEmitter();
  @Output() eventAfterValidation = new EventEmitter();
  // @Output() eventValidationTurnOn = new EventEmitter();
  // @Input() validationTrigger = false;
  @Input() optionsKvm!: Map<string, string>;
  // @Input() directionHorizontal = true;
  @Input() extraCssClass!: string;
  @Input() labelOnTop = false;
  @Input() isCheckboxChecked!: boolean;
  @Input() tooltipText!: string;


  getCheckBoxForm(): FormGroup {
    return this.mcForm.getControl(this.name) as FormGroup;
  }

  setCurrentValue() {
    if (!this.getCheckBoxForm()) {
      const selectForm = this.mcForm.formBuilder.group({
        value: [this.value],
        label: [this.label],
        errorFlg: [false],
        errorMsg: ['']
      });

      if (this.mcForm) {
        this.mcForm.addControl(this.name, selectForm);
      }
    } else {
      this.getCheckBoxForm().controls["value"].setValue(this.value);
      this.getCheckBoxForm().controls["errorFlg"].setValue(false);
      this.getCheckBoxForm().controls["errorMsg"].setValue('');
    }

  }

  ngOnInit() {

    this.labelPositionCssClass();

    if (!this.value) {
      this.value = false;
    }

    if (this.mcForm) {
      this.formValidationTriggerSubscription = this.mcForm.getValidationTriggerObservable().subscribe(() => {
        this.validateCheckBox();
      });
    }

    this.setCurrentValue();

    this.validationSetup();
  }

  ngOnDestroy(): void {
    if (this.formValidationTriggerSubscription) {
      this.formValidationTriggerSubscription.unsubscribe();
    }
    this.mcForm.formGroup.removeControl(this.name);
  }


  private validatorCheckBox(control: FormControl) {

    return control.value === true ? null : {
      validatorCheckBox: {
        valid: false
      }
    };
  }



  validationSetup() {
    if (this.validRequiredFlg) {
      // @ts-ignore
      this.getCheckBoxForm().get('value')?.setValidators(this.validatorCheckBox);
    }
  }


  validateCheckBox() {
    if (this.getCheckBoxForm().get('value')?.value === false && this.validRequiredFlg === true) {
      this.getCheckBoxForm().get('errorFlg')?.setValue(true);
      this.getCheckBoxForm().get('errorMsg')?.setValue(`This field is required`);
    } else {
      this.getCheckBoxForm().get('errorFlg')?.setValue(false);
      this.getCheckBoxForm().get('errorMsg')?.setValue(``);
    }
    this.fireEventAfterValidation();
  }

  labelPositionCssClass() {
    if (this.labelOnTop === true) {
      this.labelPositionClass = 'flex-column';
    } else {
      this.labelPositionClass = 'flex-row';
    }
  }

  public fireEventAfterValidation() {
    if (this.eventAfterValidation) {
      const eventObj = new MccFiCheckboxEventAfterValidation();
      eventObj.validFlg = !this.getCheckBoxForm()?.get('value')?.errors;

      this.eventAfterValidation.emit(eventObj);
    }
  }

  clearErrors() {
    this.getCheckBoxForm().get('errorFlg')?.setValue(false);
    this.getCheckBoxForm().get('errorMsg')?.setValue('');
  }

  onCheckBoxChanged(event: any) {
    this.clearErrors();
    if (this.eventValueChanged) {
      this.eventValueChanged.emit(event);
    }
  }

}
