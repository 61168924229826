import {ImDataExport} from './im-data-export.model';
import {ImDataExportListGENERATED} from '../_generated/im-data-export-list-generated.model';

export class ImDataExportList extends ImDataExportListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataExportList();
  }

  // ---------------------------------------------------------------------
}
