import { Component, OnInit } from '@angular/core';
import {ICON_GENERATE} from "../../../../assets/media/svg_icons/icon-generate";
import {FinanceGenerateReportDialogComponent} from "../finance-generate-report-dialog/finance-generate-report-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {HttpParams} from "@angular/common/http";
import {MkFilterConfig, MkFilterItemType, MkFilterOutput, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig} from "@miticon-ui/mc-components";
import {TranslateService} from "@ngx-translate/core";
import {BkFinanceReport, BkFinanceReportService, McGod, SortCriteriaItem, SortCriteriaList, WebFileService} from "@miticon-ui/mc-core";
import {saveAs} from "file-saver";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-finance-list-page',
  templateUrl: './finance-list-page.component.html',
  styleUrls: ['./finance-list-page.component.scss']
})
export class FinanceListPageComponent implements OnInit {
  iconGenerate = ICON_GENERATE;
  pageNumber = 0;
  pageSize = 5;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  items = [];
  isLoading: boolean = false;
  searchTooltip = this.tS.instant('cc.finance.search-tooltip');
  mkMatMenuActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.common.download-file'),
      matIcon: 'download',
      actionCd: 'Download',
      permission: McGod.PERM_EB_FACTORING_OUT_TRANSACTION_PAYMENT_ADD
    }
  ];
  totalItemsCount!: number;

  constructor(private dialog: MatDialog,
              private tS: TranslateService,
              private webFileService: WebFileService,
              private bkFinanceReportService: BkFinanceReportService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.initTable();
    this.initFilter();

    this.actLoad(this.pageNumber, this.pageSize, new HttpParams());
  }

  initTable() {
    this.tableConfig.addColumnStandard(this.tS.instant('cc.finance.report-name'), 'financeReportFilename', 250, 'financeReportFilename');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.finance.booking-date-from'), 'getDateFrom()', 250, 'dateFrom');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.finance.booking-date-to'), 'getDateTo()', 250, 'dateTo');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.invoices.view.creation-date'), 'getCreateDate()', 250, 'sysCreatedDatetime');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.finance.exported-by'), 'exportedBy', 250, 'exportedBy');
    this.tableConfig.addColumnSvgIcon(this.tS.instant('cc.common.status'), 'getStatusCd()');
  }

  initFilter() {
    this.filterConfig.addItem(MkFilterItemType.DATEPICKER, this.tS.instant("cc.finance.booking-date-from"));
    this.filterConfig.addItem(MkFilterItemType.DATEPICKER, this.tS.instant("cc.finance.booking-date-to"));
    this.filterConfig.addItem(MkFilterItemType.DATEPICKER, this.tS.instant("cc.invoices.view.creation-date"));
  }

  onFilterChanged(filterOutput: MkFilterOutput) {
    const params = new HttpParams()
      .set('page', filterOutput.pageEvent.pageIndex)
      .set('size',filterOutput.pageEvent.pageSize)
      .set('searchTerm', filterOutput.search ? filterOutput.search : '')
      .set('bookingDateFrom', filterOutput.selections[this.tS.instant("cc.finance.booking-date-from")] ? filterOutput.selections[this.tS.instant("cc.finance.booking-date-from")] : '')
      .set('bookingDateTo', filterOutput.selections[this.tS.instant("cc.finance.booking-date-to")] ? filterOutput.selections[this.tS.instant("cc.finance.booking-date-to")] : '')
      .set('createDate', filterOutput.selections[this.tS.instant("cc.invoices.view.creation-date")] ? filterOutput.selections[this.tS.instant("cc.invoices.view.creation-date")] : '');

    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, params);
  }

  actLoad(pageNumber: number, pageSize: number, params: HttpParams) {
    this.isLoading = true;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    const sort = new SortCriteriaItem('id', 'DESC');
    const sortCriteriaList = new SortCriteriaList();
    sortCriteriaList.addSort(sort.propertyName, sort.direction);
    this.bkFinanceReportService.get(params, pageNumber, pageSize, sortCriteriaList).subscribe((res)=> {
      if (res) {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => object = BkFinanceReport.createFromJson(object));
      }
    }, () => {
      this.isLoading = false;
    });
  }

  onActionMkMatMenuItems($event: MkMatTableMenuAction) {
    switch ($event.action.actionCd) {
      case 'Download':
        if ($event.item.statusCd === BkFinanceReport.STATUS_CD_CREATED) {
          this.webFileService.getById($event.item.idWebFile).subscribe((webFile: any) => {
            if (webFile) {
              this.webFileService.downloadFile($event.item.idWebFile, webFile.typeCd).subscribe((file: any) => {
                saveAs(file, webFile.originalFilename);
              })
            }
          })
        } else {
          this.toastr.error('You can\'t download invalid reports');
        }
        break;
    }
  }

  generateReport() {
    this.dialog.open(FinanceGenerateReportDialogComponent, {
      height: '700px',
      width: '650px',
      panelClass: 'dialog',
      autoFocus: false,
      restoreFocus: false,
    }).afterClosed().subscribe((data) => {
      if(data) {
        this.actLoad(this.pageNumber, this.pageSize, new HttpParams());
      }
    });
  }
}
