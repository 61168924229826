import {CcMollieTransaction} from './cc-mollie-transaction.model';
import {CcMollieTransactionListGENERATED} from '../_generated/cc-mollie-transaction-list-generated.model';

export class CcMollieTransactionList extends CcMollieTransactionListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CcMollieTransactionList();
  }

  // ---------------------------------------------------------------------
}
