<h3 class="page-title">{{ 'cc.billing.statements' | translate }}</h3>

<div class="actions-container">
  <span>{{ 'cc.billing.statements' | translate }}</span>
  <div class="actions-wrapper">
    <button *ngIf="mcGod.userHasMcBillingOutStatementAddPermission()" class="system-action-btn" (click)="showAddEditStatementMcb.setTrue()"><i
      class="fas fa-plus-circle mc-add-new-user-icon"></i>{{'cc.billing.create-new-statement' | translate}}
    </button>
  </div>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="mcBillingStatementList.items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  [totalItemsCount]="mcBillingStatementList.totalItemsCount"
  [itemsPerPage]="mcBillingStatementList.pageItemsPerPageCount"
  (filterChanged)="onFilterChanged($event)"
  (selectedItems)="onItemsSelected($event)"
  (actionMkMatMenuItems)="onMkMatMenuItemsAction($event)"
  [mkMatMenuActionItems]="mkMatMenuActionItems"
  [searchTooltip]="searchTooltip"
>
</mk-mat-table>

<mcc-modal1-wrapper modalSizeCd="small" *ngIf="showAddEditStatementMcb.value" [showMcb]="showAddEditStatementMcb">
  <mc-out-billing-statement-add-edit-part (eventCanceled)="showAddEditStatementMcb.setFalse()" (eventSaved)="actAddEditStatementSaved($event)"></mc-out-billing-statement-add-edit-part>
</mcc-modal1-wrapper>
<mcc-dialog-box *ngIf="showFinalizeStatementMcb.value" [enableCloseButtonFlg]="false"
                title="{{'cc.billing.finalize-statement' | translate}}" name="finalize-statement"
                description="{{'cc.billing.are-you-sure-you-want-to-finalize-the-selected-statement-(s)-this-action-cannot-be-undone' | translate}}"
                [buttonsKvm]="finalizeStatementDialogButtonsKvm"
                popupType="question"
                (eventActionButtonClicked)="onFinalizeStatementEmit($event)"></mcc-dialog-box>

<mcc-modal1-wrapper modalSizeCd="small" *ngIf="showCreatePayoutFileMcb.value" [showMcb]="showCreatePayoutFileMcb">
  <mc-out-billing-create-payout-file-part [statementIds]="selectedMcStatementIds"
                                          (eventCreated)="onCreatePayoutFile()"
                                          (eventCanceled)="onCreatePayoutFileCancelEmit()"></mc-out-billing-create-payout-file-part>
</mcc-modal1-wrapper>
<mcc-dialog-box *ngIf="showCancelStatementMcb.value" [enableCloseButtonFlg]="false"
                title="{{'cc.billing.cancel-statement' | translate}}(s)" name="cancel-statement"
                description="{{'cc.cancel-statement.are-you-sure-you-want-to-cancel-this-statement-this-action-can-not-be-undone-note-billing-items-will-be-removed-from-the-statement' | translate}}"
                [buttonsKvm]="cancelStatementDialogButtonsKvm"
                popupType="question"
                (eventActionButtonClicked)="onCancelStatementEmit($event)"></mcc-dialog-box>

<mcc-modal1-wrapper modalSizeCd="small" *ngIf="showRenameStatementMcb.value" [showMcb]="showRenameStatementMcb" [heightAutoFlg]="true" (eventCanceled)="onRenameStatementCancelEmit()">
  <mc-out-billing-rename-statement-part [mcStatement]="mcStatement" (eventSaved)="onRenameStatementSaveEmit()"
                                        (eventCanceled)="onRenameStatementCancelEmit()"></mc-out-billing-rename-statement-part>
</mcc-modal1-wrapper>
