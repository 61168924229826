<!--Edit role basic info modal-->
<ng-template #content let-modal>

  <!--Modal wrapper-->
  <div class="mc-role-create-wrapper p-3">

    <!--Modal header-->
    <div class="modal-header border-0 mc-role-modal-header">

      <!--Entity root-->
      <div class="d-block">
        <h4 class="modal-title">
          Edit
          <span *ngIf="!editPermissions">
            {{'cc.common.view.basic-info' | translate}}
          </span>
          <span *ngIf="editPermissions">
            {{'cc.roles.view.permissions' | translate}}
          </span>
          for role:

          <span class="text-uppercase text-muted">
            {{ selectedEntityPath[selectedEntityPath.length - 1] }}
          </span>
          <span class="text-capitalize">
            {{basicInfo['caption']}}
          </span>
        </h4>
        <p class="text-capitalize m-0 text-secondary h5">
          <span class="mc-active border-bottom-0" *ngFor="let entity of selectedEntityPath; let i = index">
            {{entity}} <span *ngIf="i !== selectedEntityPath.length - 1" class="text-dark h6 px-1"><small> > </small></span>
          </span>
        </p>
      </div>

      <!--Close button-->
      <button type="button" class="close mc-close-button " (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div><!--Modal header /end-->

    <!--Modal body-->
    <div class="modal-body mc-edit-role-wrapper">

      <!--Error section-->
      <div class="mc-error position-relative mt-1">
        <mc-notify-message [message]="errorObject.message" [type]="errorObject.type"></mc-notify-message>
      </div>

      <mc-role-basic-info *ngIf="!editPermissions"
                          [selectedEntity]="selectedEntityPath[selectedEntityPath.length - 1]"
                          [editRole]="basicInfo"
                          (onNextStep)="getForm($event)"
                          (onFormValid)="onCurrentFormValid($event)"></mc-role-basic-info>

      <mc-role-permissions *ngIf="editPermissions"
                           (onSelectPermission)="getPermissions($event)"
                           [editRole]="basicInfo"></mc-role-permissions>


    </div><!--Modal body /end-->

    <!--Modal footer-->
    <div class="modal-footer border-top-0 mc-edit-role-wrapper pb-4">
      <button type="button" class="btn mc-next-save-btn text-white" (click)="onUpdate()" [disabled]="isFormValid">{{'cc.common.view.save' | translate}}
      </button>
    </div>

  </div><!--Modal wrapper /end-->
</ng-template><!--Edit role modal /end-->

<!--Edit role button-->
<a id="editRoleBasicInfo" (click)="openXl(content)" [ngClass]="{'mc-disabled-link': isRoleLinked}" [ngbTooltip]="getTooltipForEditIcon()">
  <i class="fas fa-pen mc-edit-role-button mc-cursor-pointer"></i>
</a>
