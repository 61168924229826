
import {Table3Config} from '../extra/table-3-config';

// import {McBillingServiceCatalogGENERATED} from '../_generated/mc-billing-service-catalog-generated.model';

export class McBillingServiceCatalogTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colFlgPriceFromContract', 'Is price from contract', 'flgPriceFromContract', 'flgPriceFromContract');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colTypeCd', 'Type cd', 'typeCd', 'typeCd');

*/
  }
}
