import {Table3Config} from '@miticon-ui/mc-core';

export class McReportingTransactionsTableConfig extends Table3Config {

  constructor() {
    super();
    this.addColumn('colLabel', this.t('cc.common.contract-number'), 'contractNumber', 'consumer.contractNumber');
    this.addColumn('colTitle', this.t('cc.my-factoring.az-no'), 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colTitle', this.t('cc.consumers.view.consumer-name'), 'consumerName', 'consumer.firstName');
    this.addColumn('colTitle', `${this.t('cc.factoring.transactions.amount')}`, 'amountStr', 'instdamt').extraCssClass = 'text-right';
    this.addColumn('colTitle', `${this.t('cc.factoring.factoring-net-fee')}`, 'netFeeAmountStr', 'netFeeAmount').extraCssClass = 'text-right';
    // this.addColumn('colTitle', `${this.t('cc.product.view.vat')} (19%) (€)`, 'vatFormatNumberOnTwoDecimals()', 'netFeeVatAmount');
    this.addColumn('colTitle', `${this.t('cc.my-factoring.payment')}`, 'paymentAmountStr', 'paymentAmount').extraCssClass = 'text-right';
    this.addColumn('colTitle', this.t('cc.my-factoring.factoring-accepted-y-n'), 'formatBooleanFactoringAcceptedValue()', 'statusCd');
    this.addColumn('colTitle', this.t('cc.my-factoring.waiting-period'), 'waitingPeriod', 'waitingPeriod');
    this.addColumn('colTitle', this.t('cc.my-factoring.reason'), 'reasonLoc', '');
  }
}
