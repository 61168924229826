<div class="valign-container full-height mc-setup-area" [ngStyle]="{'background-image': 'url(' + welcomeImg + ')', 'background-repeat': 'no-repeat', 'min-height': '100%',
'min-width': '1024px', 'width': '100%', 'height': 'auto'}">
  <div class="container">
    <div class="row">
      <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
        <div class="card mc-login-card">
          <div class="card-header text-center mc-login-header">
            <img [src]="logo" alt="cc_logo">
            <h4 *ngIf="!isReset" class="card-title mc-card-title">Welcome to portal. Please enter initial password in order to
              proceed.</h4>
            <h4 *ngIf="isReset" class="card-title mc-card-title">Reset your password</h4>
          </div>
          <div class="card-body mc-login-card-body">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="mc-tabset">
              <li [ngbNavItem]="1" id="enterPassword" class="tab-title">
                <a ngbNavLink>Enter password</a>
                <ng-template ngbNavContent>
                  <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
                  <div class="row">
                    <form [formGroup]="enterPassForm">
                      <div class="row">
                        <div class="col-md-8 mc-divider mc-login-password-fields">
                          <div class="form-group mc-input-password">
                            <label for="password"></label>
                            <input [type]="seePassword ? 'text' : 'password'"
                                   name="password"
                                   class="form-control"
                                   [ngClass]="inputFieldsError ? 'mc-error-input-wrapper' : ''"
                                   id="password"
                                   formControlName="setPassword"
                                   placeholder="enter password"
                                   required/>
                            <mc-see-password (seePasswordOnEyeBtn)="onChangeSeePassword($event, 1)"></mc-see-password>
                          </div>
                          <div class="form-group mc-input-password">
                            <label for="checkPassword"></label>
                            <input [type]="seeRepeatedPassword ? 'text' : 'password'"
                                   class="form-control"
                                   [ngClass]="inputFieldsError ? 'mc-error-input-wrapper' : ''"
                                   id="checkPassword"
                                   name="password"
                                   placeholder="repeat password"
                                   formControlName="checkPassword"
                                   required/>
                            <mc-see-password (seePasswordOnEyeBtn)="onChangeSeePassword($event, 2)"></mc-see-password>
                          </div>
                        </div>
                        <div class="col-md-4 mc-password-policy">
                          <p>password policy</p>
                          <div class="mc-password-policy-requirements">
                            <div class="form-group">
                              <label class="custom-control fill-checkbox">
                                <input type="checkbox" class="fill-control-input" disabled [checked]="ifMinChars"/>
                                <span class="fill-control-indicator"></span>
                                <span class="fill-control-description">Min. characters: <strong>{{minChars}}</strong></span>
                              </label>
                            </div>
                            <div class="form-group">
                              <label class="custom-control fill-checkbox">
                                <input type="checkbox" class="fill-control-input" disabled [checked]="checkDiffChars"/>
                                <span class="fill-control-indicator"></span>
                                <span
                                  class="fill-control-description">Min. different characters: <strong>{{noOfDiffChar}}</strong></span>
                              </label>
                            </div>
                            <div class="form-group">
                              <label class="custom-control fill-checkbox">
                                <input type="checkbox" class="fill-control-input" disabled [checked]="checkDigits"/>
                                <span class="fill-control-indicator"></span>
                                <span
                                  class="fill-control-description">min. digits: <strong>{{noOfDigits}}</strong></span>
                              </label>
                            </div>
                            <div class="form-group">
                              <label class="custom-control fill-checkbox">
                                <input type="checkbox" class="fill-control-input" disabled
                                       [checked]="checkSpecialChars"/>
                                <span class="fill-control-indicator"></span>
                                <span
                                  class="fill-control-description">Min. special characters: <strong>{{noOfSpecialChars}}</strong></span>
                              </label>
                            </div>
                            <div class="form-group">
                              <label class="custom-control fill-checkbox">
                                <!--                                  <input type="checkbox" disabled [checked]="checkLowercaseLetters"/>-->
                                <input type="checkbox" class="fill-control-input" disabled
                                       [checked]="checkLowercaseLetters"/>
                                <span class="fill-control-indicator"></span>
                                <span
                                  class="fill-control-description">Min. lowercase letters: <strong>{{noOfLowercaseChars}}</strong></span>
                              </label>
                            </div>
                            <div class="form-group">
                              <label class="custom-control fill-checkbox">
                                <!--                                  <input type="checkbox" disabled [checked]="checkUppercaseLetters"/>-->
                                <input type="checkbox" class="fill-control-input" disabled
                                       [checked]="checkUppercaseLetters"/>
                                <span class="fill-control-indicator"></span>
                                <span
                                  class="fill-control-description">Min. uppercase letters: <strong>{{noOfUppercaseChars}}</strong></span>
                              </label>
                            </div>

                          </div>

                        </div>
                        <div class="col-md-12 text-center mc-login-btn-area">
                          <button class="btn boarding-btn mc-login-btn"
                                  type="submit" [ngClass]="ifPasswordValid ? 'btn-secondary' : 'btn-primary disabled'"
                                  (click)="saveAccount(1)">
                            {{ isReset ? "Confirm & log in" : ('cc.common.view.log-in' | translate)}}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </ng-template>
              </li>

              <li [ngbNavItem]="2" id="generatePassword">
                <a>{{'cc.common.view.generate-password' | translate}}</a>
                <ng-template ngbNavContent>
                  <div class="container generate-container">
                    <form [formGroup]="generatePasswordForm" style="padding-top: 20px !important;">
                      <div class="row">
                        <div class="mc-error">
                          <mc-notify-message [message]="this.generateError" [type]="this.generateError ? 'error': null"></mc-notify-message>
                        </div>
                      </div>
                      <div class="row first-row mt-5">
                        <div class="col-md-2">
                          <label for="length" class="">{{ 'cc.common.edit.length' | translate }}:</label>
                        </div>
                        <div class="col-md-2">
                             <input title="length"
                                    type="number"
                                    id="length"
                                    class="form-control length-input"
                                    name="length"
                                    formControlName="length"
                                    min={{minChars}}
                                    max="255"
                                    [ngModel]="lengthNumber"
                                    (keyup)="checkL()">
                        </div>
                        <div class="col-md-3">
                          <p>{{ 'cc.common.edit.characters' | translate }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                            <p>{{'cc.common.view.include-character-types' | translate}}:</p>
                          </div>
                        </div>
                      <div class="row first-row" >
                        <div class="col-md-12">
                            <ul formArrayName="includeCharTypes">
                              <li>
                                <input type="checkbox" id="check_1" name="check" value="check1" [formControlName]="0" [attr.disabled]="disableInputs ? true : null" >
                                <label for="check_1">A-Z</label>
                              </li>
                              <li>
                                <input type="checkbox" id="check_2" name="check" value="check2" [formControlName]="1" [attr.disabled]="disableInputs ? true : null">
                                <label for="check_2">a-z</label>
                              </li>
                              <li>
                                <input type="checkbox" id="check_3" name="check" value="check3" [formControlName]="2" [attr.disabled]="disableInputs ? true : null">
                                <label for="check_3">0-9</label>
                              </li>
                              <li>
                                <input type="checkbox" id="check_4" name="check" value="check4" [formControlName]="3" [attr.disabled]="disableInputs ? true : null">
                                <label for="check_4">/*_..</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="custom-control fill-checkbox">
                              <input type="checkbox" formControlName="excludeLookLikeCharacters" class="fill-control-input">
                              <span class="fill-control-indicator" style="-webkit-border-radius: 0;-moz-border-radius: 0;border-radius: 0;"></span>
                              <span>{{ 'cc.common.view.exclude-look-like-characters' | translate }}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="row first-row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="custom-control fill-checkbox">
                              <input type="checkbox" formControlName="ensurePasswordContainsCharFromAllGroups" class="fill-control-input">
                              <span class="fill-control-indicator" style="-webkit-border-radius: 0;-moz-border-radius: 0;border-radius: 0;"></span>
                              <span>{{ 'cc.common.view.ensure-that-password-contains-characters-from-every-group' | translate }}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <span class="sync-icon" [ngStyle]="{'background': 'url(' + syncIcon + ')'}"></span>
                      <div class="row">
                        <div class="col-md-12"><h6>Password</h6></div>
                      </div>
                      <div class="row first-row">
                        <div class="col-md-12 password-input" style="position: relative;">
                            <input [type]=" seePassword ? 'text': 'password'"
                                   class="generated-password form-control col-md-10"
                                   title=""
                                   formControlName="password">
                          <button type="button" class="col-md-2 mc-seepassword-btn" style="position: absolute; right: 100px;" (click)="seePasswordClick()">
                            <i class="fas fa-eye" style="font-size: 1rem;" [ngClass]="seePassword ? 'mc-active' : ''"></i>
                          </button>
                        </div>
                        <a (click)="generateRandomPassword()" class="sync-icon">
                          <img [src]="syncIcon">
                      </a>
                      </div>
                      <div class="row">
                       <div class="col-md-12">
                         <p class="copy-text">
                           {{'cc.common.view.please-remember-your-password-for-further-usage' | translate}}.
                           <a (click)="copyPassword()">
                             <img [src]="copyIcon">
                           </a>
                         </p>
                       </div>
                     </div>
                      <div class="row">
                        <div class="col-md-12 text-center mc-login-btn-area" style="text-align: center">
                          <button class="btn boarding-btn mc-login-btn"
                                  type="submit"
                                  (click)="saveAccount(2)"
                                  [ngClass]="ifGeneratePasswordValid ? 'btn-secondary' : 'btn-primary disabled'">{{ 'cc.common.view.accept-&-login' | translate }}</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
