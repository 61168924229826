/*BbmTestObj*/
import {McVatGENERATED} from '../_generated/mc-vat-generated.model';
import {McDateUtils} from "../common/mc-date-utils";
import {McBillingService2VatList} from './mc-billing-service-2-vat-list.model';

export class McVat extends McVatGENERATED {

  public mcBillingService2VATItemList = new McBillingService2VatList();

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McVat();
  }

  // ---------------------------------------------------------------------

  getStartDateString() {
    return McDateUtils.newFormatDateString(this.startDate);
  }

  getEndDateString(): any {
    if (this.endDate) {
      return McDateUtils.newFormatDateString(this.endDate);
    }
    return '';
  }

  getVatLabelForVll() {
    return `${this.vatRate}% - ${this.getVatTypeCdLabel()}`;
  }
}
