import {CmnEmailFilterGENERATED} from '../_generated/cmn-email-filter-generated.model';

export class CmnEmailFilter extends CmnEmailFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CmnEmailFilter();
  }

  // ---------------------------------------------------------------------
}
