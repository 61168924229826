import {CsrConsumer2CtdEmailFilterGENERATED} from '../_generated/csr-consumer-2-ctd-email-filter-generated.model';

export class CsrConsumer2CtdEmailFilter extends CsrConsumer2CtdEmailFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrConsumer2CtdEmailFilter();
  }

  // ---------------------------------------------------------------------
}
