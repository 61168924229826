/*BbmTestObj*/
import {CsrConsumerHistoryGENERATED} from '../_generated/csr-consumer-history-generated.model';

export class CsrConsumerHistory extends CsrConsumerHistoryGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrConsumerHistory();
  }

  // ---------------------------------------------------------------------
}
