import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MccFiFileUploadModule,
  McComponentsModule,
  McFilterModule,
  McStatusModule,
  McTableModule,
} from '@miticon-ui/mc-components';
import { McCoreModule } from '@miticon-ui/mc-core';
import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AppSharedModule } from '../app-shared/app-shared.module';
import { CsrContractModule } from '../csr-contract/csr-contract.module';
import { McEntityProfileModule } from '../mc-entity-profile/mc-entity-profile.module';
import { McProfileUserModule } from '../mc-profile-user/mc-profile-user.module';
import { McUserModule } from '../mc-user/mc-user.module';
import { Step1Component } from './mc-consumers/components/step1/step1.component';
import { Step2Component } from './mc-consumers/components/step2/step2.component';
import { Step3Component } from './mc-consumers/components/step3/step3.component';
import { McConsumersDetailsHistoryComponent } from './mc-consumers/compose-details/mc-consumers-details-history/mc-consumers-details-history.component';
import { McConsumersAddComponent } from './mc-consumers/compose/mc-consumers-add.component';
import { McConsumersArchiveComponent } from './mc-consumers/mc-consumers-archive/mc-consumers-archive.component';
import { McConsumersImportComponent } from './mc-consumers/mc-consumers-import/mc-consumers-import.component';
import { ImportConsumersStep1Component } from './mc-consumers/mc-import-consumers/components/import-consumers-step1/import-consumers-step1.component';
import { ImportConsumersStep2Component } from './mc-consumers/mc-import-consumers/components/import-consumers-step2/import-consumers-step2.component';
import { McImportConsumersComponent } from './mc-consumers/mc-import-consumers/mc-import-consumers.component';
import { McCreateEntityWrapperComponent } from './mc-entity/components/mc-create-entity-wrapper/mc-create-entity-wrapper.component';
import { McEditEntityWrapperComponent } from './mc-entity/components/mc-edit-entity-wrapper/mc-edit-entity-wrapper.component';
import { McEntityAttributesModule } from './mc-entity/components/mc-entity-attributes/mc-entity-attributes.module';
import { McEntityBasicInfoModule } from './mc-entity/components/mc-entity-basic-info/mc-entity-basic-info.module';
import { McEntityPasswordPolicyModule } from './mc-entity/components/mc-entity-password-policy/mc-entity-password-policy.module';
import { McEntityPricelistComponent } from './mc-entity/components/mc-entity-pricelist/mc-entity-pricelist.component';
import { McEntitySummaryDataComponent } from './mc-entity/components/mc-entity-summary-data/mc-entity-summary-data.component';
import { McImportSepaXmlComponent } from './mc-import-sepa-xml/mc-import-sepa-xml.component';
import { McInvoiceModule } from './mc-invoice/mc-invoice.module';
import { McManagementRoutingModule } from './mc-management-routing.module';
import { McManagementComponent } from './mc-management.component';
import { McEditRoleBasicInfoModalComponent } from './mc-role/components/mc-edit-role-basic-info-modal/mc-edit-role-basic-info-modal.component';
import { McRoleBasicInfoComponent } from './mc-role/components/mc-role-basic-info/mc-role-basic-info.component';
import { McRoleCreateSummaryComponent } from './mc-role/components/mc-role-create-summary/mc-role-create-summary.component';
import { McRolePermissionsComponent } from './mc-role/components/mc-role-permissions/mc-role-permissions.component';
import { McRoleCreateWrapperComponent } from './mc-role/mc-role-create-wrapper/mc-role-create-wrapper.component';
import { McRoleEditWrapperComponent } from './mc-role/mc-role-edit-wrapper/mc-role-edit-wrapper.component';
import { McCreateUserWrapperComponent } from './mc-users/components/mc-create-user-wrapper/mc-create-user-wrapper.component';
import { McEditUserWrapperComponent } from './mc-users/components/mc-edit-user-wrapper/mc-edit-user-wrapper.component';
import { McEntityPopupButtonsComponent } from './mc-users/components/mc-popup-buttons/mc-entity-popup-buttons/mc-entity-popup-buttons.component';
import { McRolePopupButtonsComponent } from './mc-users/components/mc-popup-buttons/mc-role-popup-buttons/mc-role-popup-buttons.component';
import { McUserPopupButtonsComponent } from './mc-users/components/mc-popup-buttons/mc-user-popup-buttons/mc-user-popup-buttons.component';
import { McUserEntitiesDataComponent } from './mc-users/components/mc-user-entities-data/mc-user-entities-data.component';
import { McUserGeneralDataComponent } from './mc-users/components/mc-user-general-data/mc-user-general-data.component';
import { McUserRolesDataComponent } from './mc-users/components/mc-user-roles-data/mc-user-roles-data.component';
import { McUserSummaryDataComponent } from './mc-users/components/mc-user-summary-data/mc-user-summary-data.component';

@NgModule({
  declarations: [
    McManagementComponent,
    McCreateUserWrapperComponent,
    McEditUserWrapperComponent,
    McUserGeneralDataComponent,
    McUserRolesDataComponent,
    McUserEntitiesDataComponent,
    McUserSummaryDataComponent,
    McUserPopupButtonsComponent,
    McEntityPopupButtonsComponent,
    McRolePopupButtonsComponent,
    McRoleBasicInfoComponent,
    McRolePermissionsComponent,
    McRoleCreateWrapperComponent,
    McRoleEditWrapperComponent,
    McRoleCreateSummaryComponent,
    McCreateEntityWrapperComponent,
    McEntitySummaryDataComponent,
    McEditRoleBasicInfoModalComponent,
    McConsumersAddComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    McEditEntityWrapperComponent,
    McConsumersDetailsHistoryComponent,
    McImportConsumersComponent,
    ImportConsumersStep1Component,
    ImportConsumersStep2Component,
    McImportSepaXmlComponent,
    ImportConsumersStep2Component,
    McConsumersArchiveComponent,
    McConsumersImportComponent,
    McEntityPricelistComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    McFilterModule,
    McTableModule,
    McComponentsModule,
    CdkStepperModule,
    McManagementRoutingModule,
    McCoreModule,
    McStatusModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    McEntityProfileModule,
    McEntityBasicInfoModule,
    McEntityAttributesModule,
    McEntityPasswordPolicyModule,
    TranslateModule,
    McInvoiceModule,
    MccFiFileUploadModule,
    AppSharedModule,
    McUserModule,
    McProfileUserModule,
    NgbNavModule,
    CsrContractModule
  ],
  exports: [
    McManagementComponent,
    McManagementRoutingModule,
    McImportSepaXmlComponent,
    McImportConsumersComponent,
    McConsumersAddComponent,
    McConsumersImportComponent,
    McConsumersDetailsHistoryComponent,
  ],
  entryComponents: [],
})
export class McManagementModule {}
