/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {CsrConsumer2CtdEmail} from '../models/csr-consumer-2-ctd-email.model';
import {CsrConsumer2CtdEmailService} from '../services/csr-consumer-2-ctd-email.service';
import {ICsrConsumer2CtdEmail} from '../_generated/csr-consumer-2-ctd-email.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {CsrConsumer} from '../models/csr-consumer.model';
import {CmnEmail} from '../models/cmn-email.model';


export abstract class CsrConsumer2CtdEmailGENERATED extends BaseObject {


  public apiService: CsrConsumer2CtdEmailService;
  public _rawJson: ICsrConsumer2CtdEmail;
    id: number = 0;
  description = '';
  idCsrConsumer: number;
  idCtdEmail: number;
  cmnEmail: CmnEmail;  csrConsumer: CsrConsumer;

  public properties: string[] = ['id', 'description', 'idCsrConsumer', 'idCtdEmail', 'cmnEmail', 'csrConsumer'];
  public propertiesRegular: string[] = ['id', 'csrConsumer', 'ctdEmail', 'description', 'idCsrConsumer', 'idCtdEmail'];
  public propertiesSpecial: string[] = ['cmnEmail', 'csrConsumer'];


  // ---------------------------------------------------------------------
public static createNewInstance(): CsrConsumer2CtdEmail {
    console.error('MTCN-ERROR: Not Implemented: CsrConsumer2CtdEmail::createNewInstance(). Add this method to final class and return new CsrConsumer2CtdEmail();');
    throw new Error('Cannot CsrConsumer2CtdEmail::createNewInstance(). Add this method to final class and return new CsrConsumer2CtdEmail();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: ICsrConsumer2CtdEmail): CsrConsumer2CtdEmail {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrConsumer2CtdEmailService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CsrConsumer2CtdEmail AABB()');
          if (this._rawJson['cmnEmail']) {
        this.cmnEmail = CmnEmail.createFromJson(this._rawJson['cmnEmail']);
      }
      if (this._rawJson['csrConsumer']) {
        this.csrConsumer = CsrConsumer.createFromJson(this._rawJson['csrConsumer']);
      }

  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('cmnEmail')) {
        if (this.cmnEmail != null) {
            dto['cmnEmail'] = this.cmnEmail.toDto();
        } else {
            dto['cmnEmail'] = null;
        }
    }
    if (this.hasOwnProperty('csrConsumer')) {
        if (this.csrConsumer != null) {
            dto['csrConsumer'] = this.csrConsumer.toDto();
        } else {
            dto['csrConsumer'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
