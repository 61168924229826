import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ICsrMapping} from '../_generated/csr-mapping.interface';
import {CsrMapping} from '../models/csr-mapping.model';
import {CsrMappingList} from '../models/csr-mapping-list.model';
import {CsrMappingServiceGENERATED} from '../_generated/csr-mapping-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CsrMappingService extends CsrMappingServiceGENERATED {

}
