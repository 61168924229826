
import {Table3Config} from '../extra/table-3-config';

// import {McProcessFlowGENERATED} from '../_generated/mc-process-flow-generated.model';

export class McProcessFlowTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAcceptedTransactionsEnteringFlowFlg', 'Accepted transactions entering flow flag', 'acceptedTransactionsEnteringFlowFlg', 'acceptedTransactionsEnteringFlowFlg');
    this.addColumn('colCompanyInterestRateTypeCd', 'Company interest rate type cd', 'companyInterestRateTypeCd', 'companyInterestRateTypeCd');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colDunningNoteBaseFeeAmount', 'Dunning note base fee amount', 'dunningNoteBaseFeeAmount', 'dunningNoteBaseFeeAmount');
    this.addColumn('colEmailFeeAmount', 'Email fee amount', 'emailFeeAmount', 'emailFeeAmount');
    this.addColumn('colForDunningTransactionsEnteringFlowFlg', 'For dunning transactions entering flow flag', 'forDunningTransactionsEnteringFlowFlg', 'forDunningTransactionsEnteringFlowFlg');
    this.addColumn('colGroupByConsumerFlg', 'Group by consumer Flg', 'groupByConsumerFlg', 'groupByConsumerFlg');
    this.addColumn('colIdMcCurrency', 'Currency Id', 'idMcCurrency', 'idMcCurrency');
    this.addColumn('colIdMcProcess', 'McProcess ID', 'idMcProcess', 'idMcProcess');
    this.addColumn('colInheritableFlg', 'Inheritable flag', 'inheritableFlg', 'inheritableFlg');
    this.addColumn('colInterestRateForCompanyAmount', 'Amount for interest rate for company', 'interestRateForCompanyAmount', 'interestRateForCompanyAmount');
    this.addColumn('colInterestRateForPersonAmount', 'Amount for interest rate for person', 'interestRateForPersonAmount', 'interestRateForPersonAmount');
    this.addColumn('colMcCurrency', 'Currency', 'mcCurrency', 'mcCurrency');
    this.addColumn('colMcProcess', 'McProcess', 'mcProcess', 'mcProcess');
    this.addColumn('colNewStatusForTransactionsAfterLastDunningLevel', 'New Status For Transactions After Last Dunning Level', 'newStatusForTransactionsAfterLastDunningLevel', 'newStatusForTransactionsAfterLastDunningLevel');
    this.addColumn('colPersonInterestRateTypeCd', 'Person interest rate type cd', 'personInterestRateTypeCd', 'personInterestRateTypeCd');
    this.addColumn('colPostFeeAmount', 'Post fee amount', 'postFeeAmount', 'postFeeAmount');
    this.addColumn('colReturnedTransactionsEnteringFlowFlg', 'Returned transactions entering flow flag', 'returnedTransactionsEnteringFlowFlg', 'returnedTransactionsEnteringFlowFlg');
    this.addColumn('colSendFlowToInkassoFlg', 'Send flow to inkasso after last dunning level', 'sendFlowToInkassoFlg', 'sendFlowToInkassoFlg');
    this.addColumn('colSendFlowToInkassoTransactionTypeCd', 'Send flow to inkasso transaction type cd', 'sendFlowToInkassoTransactionTypeCd', 'sendFlowToInkassoTransactionTypeCd');
    this.addColumn('colSendingToInkassoTransactionsEnteringFlowFlg', 'Sending to inkasso transactions entering flow flag', 'sendingToInkassoTransactionsEnteringFlowFlg', 'sendingToInkassoTransactionsEnteringFlowFlg');
    this.addColumn('colShouldGoToInkassoTransactionsEnteringFlowFlg', 'Should go to inkasso transactions entering flow flag', 'shouldGoToInkassoTransactionsEnteringFlowFlg', 'shouldGoToInkassoTransactionsEnteringFlowFlg');
    this.addColumn('colSmsFeeAmount', 'Sms fee amount', 'smsFeeAmount', 'smsFeeAmount');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colWeekdayToSendNotification', 'Weekday to send notification', 'weekdayToSendNotification', 'weekdayToSendNotification');

*/
  }
}
