/*BbmTestObj*/
import {EbSepaImportGENERATED} from '../_generated/eb-sepa-import-generated.model';
import { McHtml } from '../_core/mc-html';
import { McValueLabelList } from '../_core/mc-value-label-list';
import { McDateUtils } from '../common/mc-date-utils';
import { McGod } from '../extra/mc-god.service';
import { ActionOptions } from '../_core/mc-html-action-options';

export class EbSepaImport extends EbSepaImportGENERATED {
  public static ACTION_SHOW_REPORT = 'REPORT';
  public static ACTION_SHOW_REMOVE = 'REMOVE';
  public dates = '';
  acceptedAmountStr!: string;
  totalAmountStr!: string;
  rejectedAmountStr!: string;
  overwrittenAmountStr!: string;
  acceptedDebtorsNumber!: number;
  rejectedDebtorsNumber!: number;

  private _fldMyFactoringSepaActions: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaImport();
  }

  // ---------------------------------------------------------------------
  static getServicePeriodVll(): McValueLabelList {
    const list = new McValueLabelList();

    list.add(McDateUtils.getDateForServicePeriodSepaIn('nextMonth'), McGod.t('cc.my-factoring.next-month'));
    list.add(McDateUtils.getDateForServicePeriodSepaIn('currentMonth'), McGod.t('cc.my-factoring.current-month'));
    list.add(McDateUtils.getDateForServicePeriodSepaIn('lastMonth'), McGod.t('cc.common.date.last-month'));
    list.add(McDateUtils.getDateForServicePeriodSepaIn('twoMonthsAgo'), McGod.t('cc.my-factoring.two-months-ago'));

    return list;
  }

  fldMyFactoringSepaActions() {
    if (!this._fldMyFactoringSepaActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t('cc.my-factoring.pre-report')}`, EbSepaImport.ACTION_SHOW_REPORT, this, [McGod.PERM_EB_FACTORING_MY_SEPA_VIEW], '', ActionOptions.OPTION_VIEW);
      // actSet.addActionB(`<i class="far mc-submit-color fa-trash-alt mc-cursor-pointer"></i> ${this.t('cc.common.table.remove')}`, EbSepaFile.ACTION_SHOW_REMOVE, this, [McGod.PERM_EB_FACTORING_MY_SEPA_REMOVE]);

      this._fldMyFactoringSepaActions = html;
    }
    return this._fldMyFactoringSepaActions.items;
  }

  getImportDateStr(): string {
    if (this.sysCreatedDatetime) {
      return McDateUtils.newFormatDateTimeString(this.sysCreatedDatetime);
    }
    return '';
  }

  /*  getServicePeriod() {
      return McDateUtils.formatDateToString(this.servicePeriod);
    }*/

  fldTotalAmountDue() {
    return this.acceptedAmountStr + '/' + this.totalAmountStr;
  }

  fldAcceptedRejectedAmountDue() {
    return this.acceptedAmountStr + '/' + this.rejectedAmountStr;
  }

  fldAcceptedRejectedDebtors() {
    return `${this.acceptedDebtorsNumber ? this.acceptedDebtorsNumber : '0'}/${this.rejectedDebtorsNumber ? this.rejectedDebtorsNumber : '0'}`;
  }

  fldRemainingAmount() {
    return this.remainingAmount + ' EUR';
  }

  isNew() {
    return this.statusCd === EbSepaImport.STATUS_CD_NEW;
  }

  getRequestedCollectionDate() {
    return `${McDateUtils.newFormatDateString(this.requestedCollectionDate)}`;
  }

  getProcessedEarlier() {
    return this.processedEarlierCnt ? this.processedEarlierCnt : '0';
  }

  getOverwritten() {
    return this.overwrittenCnt ? this.overwrittenCnt : '0';
  }

  getRequestedOrBatchCollectionDates() { // In hurry for delivery, need to generate from BE
    if (this.requestedOrBatchCollectionDates && this.requestedOrBatchCollectionDates.length === 1) {
      return McDateUtils.newFormatDateString(this.requestedOrBatchCollectionDates[0]);
    }
    if (this.requestedOrBatchCollectionDates && this.requestedOrBatchCollectionDates.length > 1) {
      return this.requestedOrBatchCollectionDates.map( (item: string | number | Date) => `${McDateUtils.newFormatDateString(item)} <br />`);
    }
  }

  getOverwrittenAmount() {
    return this.overwrittenAmountStr;
  }

  public override populateFromJson(aSourceJson: any) {
    super.populateFromJson(aSourceJson);
    this.acceptedDebtorsNumber = aSourceJson.acceptedDebtors;
    this.rejectedDebtorsNumber = aSourceJson.rejectedDebtors;
  }
}
