import {Inject, Injectable} from '@angular/core';
import {McBillingBatchServiceGENERATED} from '../_generated/mc-billing-batch-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McBillingBatchService extends McBillingBatchServiceGENERATED {

}
