export const ICON_EDIT = `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="29" height="29" rx="1.5" fill="white" stroke="#333867"/>
<g clip-path="url(#clip0_1417_11955)">
<path d="M7.5 19.55V22.0833C7.5 22.3167 7.68333 22.5 7.91667 22.5H10.45C10.5583 22.5 10.6667 22.4583 10.7417 22.375L19.8417 13.2833L16.7167 10.1583L7.625 19.25C7.54167 19.3333 7.5 19.4333 7.5 19.55ZM22.2583 10.8667C22.5833 10.5417 22.5833 10.0167 22.2583 9.69167L20.3083 7.74167C19.9833 7.41667 19.4583 7.41667 19.1333 7.74167L17.6083 9.26667L20.7333 12.3917L22.2583 10.8667Z" fill="#333867"/>
</g>
<defs>
<clipPath id="clip0_1417_11955">
<rect width="20" height="20" fill="white" transform="translate(5 5)"/>
</clipPath>
</defs>
</svg>
`
