import {Inject, Injectable} from '@angular/core';
import {McBillingItemServiceGENERATED} from '../_generated/mc-billing-item-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McBillingItemService extends McBillingItemServiceGENERATED {

}
