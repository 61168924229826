import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ICsrAccountConsumer} from '../_generated/csr-account-consumer.interface';
import {CsrAccountConsumer} from '../models/csr-account-consumer.model';
import {CsrAccountConsumerList} from '../models/csr-account-consumer-list.model';
import {CsrAccountConsumerServiceGENERATED} from '../_generated/csr-account-consumer-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CsrAccountConsumerService extends CsrAccountConsumerServiceGENERATED {

}
