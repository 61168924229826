import {McCategory} from './mc-category.model';
import {McCategoryListGENERATED} from '../_generated/mc-category-list-generated.model';

export class McCategoryList extends McCategoryListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McCategoryList();
  }

  // ---------------------------------------------------------------------
}
