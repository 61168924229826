import {ImDataSetAttributeFilterGENERATED} from '../_generated/im-data-set-attribute-filter-generated.model';

export class ImDataSetAttributeFilter extends ImDataSetAttributeFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataSetAttributeFilter();
  }

  // ---------------------------------------------------------------------
}
