<ng-container>
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-wrapper">
<!--    <lib-mc-loader [showLoader]="webFile.apiLoadingFlg"></lib-mc-loader>-->

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-header">
      <div class="d-flex"><img src="assets/images/mapping-blue.svg"> <h4 class="modal-title pl-3">{{'cc.consumer.import.please-choose-mapping' | translate}}</h4>
      </div>
    </div>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-body">

      <div *ngIf="templateMappings.getCount() > 0">
        <mcc-fi-radio-buttons [mcForm]="mcForm" [optionsVlm]="optionsVlm" [name]="'templateMapping'" [value]="currentTemplateMappingName"
                              [directionHorizontal]="false" (eventValueChanged)="templateMappingValueChanged($event)"></mcc-fi-radio-buttons>
      </div>

      <div *ngIf="templateMappings.getCount() === 0">
        <mc-notify-message [type]="'error'" [message]="'There are no saved mappings.'"></mc-notify-message>
      </div>

    </div><!-- modal-body -->

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-footer">

      <button class="btn btn-secondary move-right" (click)="actCancel()">
        <i class="far fa-times-circle"></i> {{ 'cc.common.edit.cancel' | translate }}
      </button>
      <button class="btn btn-primary mr-3" [disabled]="applyMappingDisableFlg" (click)="actApplyMapping()">
        <img src="assets/images/mapping-white.svg" height="15px"> {{'cc.consumers.consumers-overview.apply-mapping' | translate}}
      </button>

    </div>
  </div>

  <router-outlet></router-outlet>
</ng-container>


