import {EbSepaFileFilterGENERATED} from '../_generated/eb-sepa-file-filter-generated.model';

export class EbSepaFileFilter extends EbSepaFileFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaFileFilter();
  }

  // ---------------------------------------------------------------------
}
