/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingStatementTable3ConfigGENERATED} from '../_generated/mc-billing-statement-table-3-config-generated.model';


export class McMyBillingStatementTable3Config extends McBillingStatementTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldMyActions()', '');
    this.addColumn('colTitle', this.t('cc.common.edit.title'), 'title', 'title');
    this.addColumn('col noOfItems', this.t('cc.billing.number-of-items'), 'fldNumberOfItems()', '');
    this.addColumn('colTotalAmount', this.t('cc.billing.total-amount'), 'getTotalPayoutLoc()', '');
    this.addColumn('colStatementDate', this.t('cc.billing.statement-date'), 'getStatementDate()', '');
    this.addColumn('colStatusCd', this.t('cc.common.view.status'), 'fldStatusWithIconsMyPage()', 'statusCd');


  }
}
