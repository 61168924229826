import {PpInvoiceFilterGENERATED} from '../_generated/pp-invoice-filter-generated.model';

export class PpInvoiceFilter extends PpInvoiceFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PpInvoiceFilter();
  }

  // ---------------------------------------------------------------------
}
