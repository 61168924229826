/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EbInterest_rate} from '../models/eb-interest_rate.model';
import {EbInterest_rateList} from '../models/eb-interest_rate-list.model';
import {EbInterest_rateFilter} from '../models/eb-interest_rate-filter.model';
import {EbInterest_rateService} from '../services/eb-interest_rate.service';
import {IEbInterest_rate} from '../_generated/eb-interest_rate.interface';
import {McGod} from "../extra/mc-god.service";

export class EbInterest_rateListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'description', 'endDatetime', 'interestRate', 'startDatetime', 'title', 'typeCd'];
  public items: EbInterest_rate[] = [];
  public apiService: EbInterest_rateService;
 // protected tempObjList: EbInterest_rateList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbInterest_rateList {
    console.error('MTCN-ERROR: Not Implemented: EbInterest_rateList::createNewInstance(). Add this method to final class and return new EbInterest_rateList();');
    throw new Error('Cannot EbInterest_rateList::createNewInstance(). Add this method to final class and return new EbInterest_rateList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEbInterest_rate[]): EbInterest_rateList {
    const  listObj: EbInterest_rateList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EbInterest_rate = EbInterest_rate.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EbInterest_rateList {
    const  listObj: EbInterest_rateList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbInterest_rateService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEbInterest_rate[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EbInterest_rate = EbInterest_rate.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEbInterest_rateFilter: EbInterest_rateFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEbInterest_rateFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EbInterest_rate {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EbInterest_rate();
    }
  }

  public replaceItem(newItem: EbInterest_rate) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadByTypeCd( typeCd: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByTypeCd(typeCd, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

