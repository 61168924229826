import {Inject, Injectable} from '@angular/core';
import {CcMollieCustomerServiceGENERATED} from '../_generated/cc-mollie-customer-generated.service';



@Injectable({
  providedIn: 'root'
})
export class CcMollieCustomerService extends CcMollieCustomerServiceGENERATED {

}
