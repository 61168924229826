import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BaseDirective, McBillingEntitiesUsingPricelistList, McGod,
  McPriceList, McPriceList2EntityService, SortCriteriaList
} from '@miticon-ui/mc-core';
import {takeUntil} from "rxjs/operators";
import {MkFilterOutput, MkTableConfig} from "@miticon-ui/mc-components";

@Component({
  selector: 'mc-pricelist-template-entities-using-template-part',
  templateUrl: './mc-pricelist-template-entities-using-template-part.component.html',
})
export class McPricelistTemplateEntitiesUsingTemplatePartComponent extends BaseDirective implements OnInit {

  mcEntitiesUsingPricelistList = new McBillingEntitiesUsingPricelistList();
  infoMsg!: string;
  tableConfig = new MkTableConfig();
  isLoading = false;

  @Input() mcPriceList!: McPriceList;

  @Output() eventClose = new EventEmitter();

  constructor(private mcPricelistMcEntity2Service: McPriceList2EntityService) {
    super();

  }


  ngOnInit() {
    this.actLoad(0, 100);
    this.initTableConfig();
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('cc.factoring.transactions.entity-name'), 'entityName');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.entity-id'), 'entityId');
    this.tableConfig.addColumnStandard(McGod.t('cc.pricelist.using-pricelist-from'), 'getFromDate()');
  }

  actLoad(pageNumber: number, pageSize: number) {
    this.isLoading = true;
    this.mcEntitiesUsingPricelistList.pageNum = pageNumber;
    this.mcEntitiesUsingPricelistList.pageItemsPerPageCount = pageSize;
    this.mcPricelistMcEntity2Service
      .getAllEntitiesUsingTemplate(this.mcPriceList.id, pageNumber, pageSize, new SortCriteriaList())
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.mcEntitiesUsingPricelistList.totalItemsCount = res.totalElements;
          this.mcEntitiesUsingPricelistList.populateFromJsonPageable(res);
          this.mcEntitiesUsingPricelistList.items.forEach(item => {
            res.content.map((resItem: any) => {
              if (item.id === resItem.id) {
                item.entityId = resItem.idMcEntity;
                item.startDate = resItem.validFrom;
              }
            });
          });
          this.isLoading = false;
        },
        (err) => {
          this.infoMsg = err.error.message;
        }
      );
  }

  actClose() {
    this.eventClose.emit();
  }

  onFilterChanged(filters: MkFilterOutput) {
    this.mcEntitiesUsingPricelistList.setSortB(filters.sort.sortProperty, filters.sort.sortType);
    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize);
  }
}
