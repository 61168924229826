import {Component, OnInit} from '@angular/core';
import { McCountry, McGod, McCountryFilter, McEntity2Service, McCountryService, SortCriteriaList, SortCriteria } from '@miticon-ui/mc-core';
import {Router} from "@angular/router";
import { MkFilterConfig, MkFilterOutput, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig } from '@miticon-ui/mc-components';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'mc-country-country-item-list',
  templateUrl: './mc-country-country-item-list.component.html',
})
export class McCountryCountryItemListComponent implements OnInit {

  mcCountryFilter = new McCountryFilter();
  mcGod = McGod.getInstance();
  countryEntities: any;
  pageNumber = 0;
  pageSize = 50;
  isLoading = false;
  totalItemsCount!: number;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  searchTooltip = this.tS.instant("cc.countries.search-by-country");
  items: McCountry[] = [];

  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.my-factoring.view-details'),
      actionCd: McCountry.ACTION_VIEW_DETAILS,
      matIcon: 'document_scanner',
      permission: McGod.PERM_MC_COUNTRY_VIEW,
    }
  ]

  constructor(private router: Router,
              private mcEntityService: McEntity2Service,
              private mcCountryService: McCountryService,
              private tS: TranslateService) {
  }

  ngOnInit() {
    this.actLoad(this.pageNumber, this.pageSize, this.mcCountryFilter, new SortCriteria('name', 'ASC'));
    this.initTableConfig();
  }

  public actLoad(pageNumber: number, pageSize: number, mcCountryFilter: McCountryFilter, sortCriteria: SortCriteria): void {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.isLoading = true;

    const sortCriteriaList = new SortCriteriaList();
    sortCriteriaList.addSort(sortCriteria.sortProperty, sortCriteria.sortType);

    const countriesRequest = this.mcCountryService.getByFilter(mcCountryFilter, this.pageNumber, this.pageSize, sortCriteriaList);
    const entitiesRequest = this.mcEntityService.getNumberOfEntitiesByCountryIds();

    forkJoin([countriesRequest, entitiesRequest]).subscribe(([countryResponse, entityResponse]) => {
      this.isLoading = false;

      if (countryResponse) {
        this.totalItemsCount = countryResponse.totalElements;
        this.countryEntities = entityResponse;

        this.items = countryResponse.content.map((object: any) => this.mapCountryWithEntities(object, this.countryEntities));

      }
    });
  }

  private mapCountryWithEntities(object: any, countryEntities: any[]): McCountry {
    const country = McCountry.createFromJson(object);
    const entity = countryEntities.find((item: any) => item.countryId === country.id);
    country.numberOfEntities = entity ? entity.numberOfEntities : 0;
    return country;
  }

  public initTableConfig(): void {
    this.tableConfig.addColumnStandard(this.tS.instant('cc.users.edit.country'), 'name', 250, 'name');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.pricelist.no-of-entities'), 'fldNumberOfEntities()', 250);
    this.tableConfig.addColumnInnerHtml(this.tS.instant('cc.country.vats'), 'fldVatNumberAndInfo()');
  }

  onFilterChanged(filterOutput: MkFilterOutput){
    this.mcCountryFilter.searchTerm = filterOutput.search ? filterOutput.search : '';
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, this.mcCountryFilter, filterOutput.sort);
  }

  onTableAction($event: MkMatTableMenuAction){
    if ($event.action.actionCd === McCountry.ACTION_VIEW_DETAILS) {
      this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/countries/profile/${$event.item.id}`);
    }
  }
}
