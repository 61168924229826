/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EbInkassoExportTable3ConfigGENERATED} from '../_generated/eb-inkasso-export-table-3-config-generated.model';

// import {EbInkassoExportGENERATED} from '../_generated/eb-inkasso-export-generated.model';

export class EbInkassoExportTable3Config extends EbInkassoExportTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colWebFileName', 'Exported file', 'exportFileName', '');
    this.addColumn('colExportedDateTime', 'Exported date & time', 'getCreatedDatetimeString()', '');
    this.addColumn('colAllDebtors', 'All debtors', 'totalDebtors', '');
    this.addColumn('colNoOfTransactions', 'Number of transactions', 'totalTransactions', '');
    this.addColumn('colExportedBy', 'Exported by', 'exportedByUserName', '');
      // this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');


  }
}
