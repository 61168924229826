import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {McJobsService, McUtilityService, McStateManagementService, McInvoiceService} from '@miticon-ui/mc-core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from "rxjs";
import {McJobCreateComponent} from "./mc-job/mc-job-create/mc-job-create.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'mc-scheduler',
  templateUrl: './mc-scheduler.component.html',
})
export class McSchedulerComponent implements OnInit, OnDestroy {

  tableContent!: any;
  entityId!: number;
  isContentLoaded = false;
  scheduleModule!: string | null;
  showLoader = false;
  pagination = {};
  errorObject = {
    message: '',
    type: ''
  };
  userPermissionCreateJob: any[] = [];

  /*Private variables*/
  private readonly paramsSubscription: any;
  private apiGetJobsSubscription!: Subscription;
  private apiUpdateJobsSubscription!: Subscription;

  /*Constructor*/
  constructor(
    private jobService: McJobsService,
    private activatedRoute: ActivatedRoute,
    private stateManagement: McStateManagementService,
    private utilityService: McUtilityService,
    private invoiceService: McInvoiceService,
    private dialog: MatDialog,
    @Inject('allPermissions') allPermissions: any
  ) {
    this.paramsSubscription = this.activatedRoute.paramMap.subscribe(param => {
      this.scheduleModule = param.get('module');
    });

    /*Permissions*/
    this.userPermissionCreateJob = [allPermissions.QRTZ_JOB_CAN_CREATE];
  }

  /*Init class*/
  ngOnInit() {

    /*Get entity id and jobs for table*/
    this.entityId = this.utilityService.getPropertyFromToken('entity_id');
    this.getJobs();

    this.isContentLoaded = true;
  }

  /*Update job action*/
  public updateJobAction(action: any): void {

    /*Reset error message*/
    this.errorObject = Object.assign({}, {
      message: '',
      type: ''
    });

    if (action && action.hasOwnProperty('actionName') && action.hasOwnProperty('id')) {
      this.apiUpdateJobsSubscription = this.jobService.updateJobsAction(action.actionName, action.id)
        .subscribe(response => {

          if (response && response.hasOwnProperty('id')) {
            this.getJobs();
          }
        }, error => {
          this.errorObject = Object.assign({}, {
            message: error.error.message,
            type: 'error'
          });
        });
    } else {
      this.errorObject = Object.assign({}, {
        message: 'Error while updating job.',
        type: 'error'
      });
    }

  }

  /*Get jobs for logged entity*/
  public getJobs(): void {
    this.showLoader = true;
    this.jobService.getJobsByEntity(this.pagination).subscribe(
      response => {
        this.tableContent = Object.assign({}, {
          ...response,
          _name: this.scheduleModule,
          isLoaded: true
        });
        this.isContentLoaded = true;
      },
      error => {
        this.errorObject = Object.assign({}, {
          message: error.error.message,
          type: 'error'
        });
      }).add(() => this.showLoader = false);
  }

  /*On pagination*/
  public onPagination(pagination: any) {
    if (pagination && Object.keys(pagination).length > 0) {
      this.pagination = Object.assign({}, pagination);
      this.getJobs();
    }
  }

  public openCreateJobDialog() {
    this.dialog.open(McJobCreateComponent, {
      height: '700px',
      width: '650px',
      panelClass: 'dialog',
      autoFocus: false,
      restoreFocus: false
    }).afterClosed().subscribe((isCreated) => {
      if(isCreated) {
        this.getJobs();
      }
    });
  }

  /*On destroy class*/
  ngOnDestroy(): void {
    this.paramsSubscription && this.paramsSubscription.unsubscribe();
    this.apiGetJobsSubscription && this.apiGetJobsSubscription.unsubscribe();
    this.apiUpdateJobsSubscription && this.apiUpdateJobsSubscription.unsubscribe();
  }
}
