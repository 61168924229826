<div class="content-wrapper">
  <lib-mc-loader [showLoader]="pmAdjustment.apiLoadingFlg"></lib-mc-loader>
  <div class="content-header-slim">
    <div class="header-icon"><img src="/assets/images/recordpayments-title.svg"></div>
    <h3>{{mcGod.t('cc.transactions.record-payment')}}</h3>
  </div>
  <div class="content-body">
    <div class="payment-information">
      <div class="info-summary">
        <mcc-message [errorMsg]="errorMsg"></mcc-message>
        <div class="info-summary-header">
          <h4>{{mcGod.t('cc.transactions.selected-transaction-s-balance-information')}}</h4>
        </div>
        <div class="inner-content">
          <mcc-row-title-description title="{{mcGod.t('cc.transactions.number-of-selected-transactions')}}:" [widerTitleFlg]="true"
                                     [description]="numberOfTransactions"></mcc-row-title-description>
          <mcc-row-title-description style="text-transform: uppercase" title="{{mcGod.t('cc.common.admin.entities')}}:" [widerTitleFlg]="true"
                                     [description]="getEntities()"></mcc-row-title-description>
          <mcc-row-title-description title="{{mcGod.t('cc.transactions.selected-transactions-amount')}}:" [widerTitleFlg]="true"
                                     [description]="transactionsAmount"></mcc-row-title-description>
        </div>
      </div>
    </div>

    <div class="payment-details" [ngClass]="diffEntitiesChosen ? 'mc-disable' : ''">
      <h5>{{mcGod.t('cc.transactions.payment-details')}}</h5>
      <mcc-fi-radio-buttons2 name="ctrlPaymentMethod" label="{{mcGod.t('cc.transactions.payment-details')}}"
                             [optionsVll]="typeCdVll"
                             [directionHorizontal]="true"
                             [labelOnTop]="true"
                             [value]="pmAdjustment.typeCd"
                             [validRequiredFlg]="true"
                             [mcForm]="mcForm"
                             (eventValueChanged)="onPaymentTypeValueChange($event)">
      </mcc-fi-radio-buttons2>

      <ng-container *ngIf="!isTypeCredit">
        <mcc-fi-number-type [mcForm]="mcForm" [value]="pmAdjustment.amount"
                            nameForValue="ctrlPaymentAmount"
                            label="{{mcGod.t('cc.transactions.payment-amount')}}"
                            nameForType="ctrlAmountType"
                            [validRequiredFlg]="true"
                            [disablePercentage]="true"
                            [chosenCurrency]="currency"
                            validMinNumber="0">
        </mcc-fi-number-type>
      </ng-container>

      <mcc-fi-textarea name="ctrlDescription" label="{{'cc.common.view.description' | translate}}"
                       [value]="pmAdjustment.description" [validRequiredFlg]="true"
                       [mcForm]="mcForm" [numCols]="45">
      </mcc-fi-textarea>


        <mcc-fi-radio-buttons2 *ngIf="!isTypeCredit && isParentEntity" name="ctrlPaymentEntity" label="Payment made to"
                               [optionsVll]="paymentAccountEntitiesVll"
                               [directionHorizontal]="false"
                               [labelOnTop]="true"
                               [validRequiredFlg]="true"
                               [mcForm]="mcForm">
        </mcc-fi-radio-buttons2>
    </div>
  </div>


  <div class="content-footer">
    <button class="btn btn-secondary move-right" (click)="actCancel();"><i class="far fa-times-circle"></i> {{'cc.common.edit.cancel' | translate}}
    </button>
    <button class="btn btn-primary" (click)="actSave();" label="Save" [disabled]="saveButtonDisabled"><i
      class="fas fa-save"></i> {{'cc.common.record' | translate}}
    </button>
  </div>
</div>
