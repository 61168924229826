import {McProductSnapshot} from './mc-product-snapshot.model';
import {McProductSnapshotListGENERATED} from '../_generated/mc-product-snapshot-list-generated.model';

export class McProductSnapshotList extends McProductSnapshotListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProductSnapshotList();
  }

  // ---------------------------------------------------------------------
}
