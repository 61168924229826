<div class="mcc-fi mcc-fi-textarea-with-token mcc-fi-textarea-with-token-{{name}} {{extraCssClasses}}"
     [ngClass]="{'validation-error' : this.getTextAreaForm().get('errorFlg')?.value}">
  <form [formGroup]="getTextAreaForm()">
    <label *ngIf="label" for="mcc-fi-{{name}}" [ngClass]="{'mc-mandatory-star' : validRequiredFlg}">{{label}}</label>

    <div class="d-flex flex-column mc-width-fit-content">
      <div class="col my-2 mc-text-align-right p-0">
        <div ngbDropdown class="d-inline-block">
          <button class="system-action-btn" ngbDropdownToggle><i class="fas fa-code"></i> {{tokenLabel}}</button>
          <div ngbDropdownMenu>
            <button *ngFor=" let item of tokenVll.items" (click)="insertToken(item.value)"
                    ngbDropdownItem>{{item.label}}</button>
          </div>
        </div>
      </div>

      <textarea
          #textBodytokened
          id="mcc-fi-{{name}}"
          name="txtArea-{{name}}"
          formControlName="value"
          rows="{{numRows}}"
          cols="{{numCols}}"
          [placeholder]="placeholder"
          [required]="validRequiredFlg"
          [readOnly]="readOnlyFlg"
          (ngModelChange)="onTextAreaChanged($event)">
    </textarea>
    </div>
    <div class="error-msg">{{this.getTextAreaForm().get('errorMsg')?.value}}</div>
  </form>
</div>
