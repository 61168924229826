/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {CsrConsumerImport} from '../models/csr-consumer-import.model';
import {CsrConsumerImportService} from '../services/csr-consumer-import.service';
import {ICsrConsumerImport} from '../_generated/csr-consumer-import.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class CsrConsumerImportGENERATED extends BaseObject {


    public static readonly IMPORT_MODE_CD_ADD: string = 'ADD';
    public static readonly IMPORT_MODE_CD_ADDUPD: string = 'ADDUPD';
    public static readonly IMPORT_MODE_CD_UPD: string = 'UPD';
  public static readonly IMPORT_MODE_CD_LIST__ALL = [
    CsrConsumerImportGENERATED.IMPORT_MODE_CD_ADD,
    CsrConsumerImportGENERATED.IMPORT_MODE_CD_ADDUPD,
    CsrConsumerImportGENERATED.IMPORT_MODE_CD_UPD
];

    public static readonly STATUS_CD_SUCCESS: string = 'SUCCESS';
  public static readonly STATUS_CD_LIST__ALL = [
    CsrConsumerImportGENERATED.STATUS_CD_SUCCESS
];

  public apiService: CsrConsumerImportService;
  public _rawJson: ICsrConsumerImport;
    id: number = 0;
  addedRecords: number;
  createdAt: number;
  createdBy: number;
  dateFormat = '';
  idMcEntity: number;
  idMcSystemUser: number;
  idWebFile: number;
  importModeCd: string;
  processedRecords: number;
  skippedRecords: number;
  statusCd: string;
  updatedAt: number;
  updatedBy: number;
  updatedRecords: number;
  importedBy: string;  importName: string;

  public properties: string[] = ['id', 'addedRecords', 'createdAt', 'createdBy', 'dateFormat', 'idMcEntity', 'idMcSystemUser', 'idWebFile', 'importModeCd', 'processedRecords', 'skippedRecords', 'statusCd', 'updatedAt', 'updatedBy', 'updatedRecords', 'importedBy', 'importName'];
  public propertiesRegular: string[] = ['id', 'addedRecords', 'createdAt', 'createdBy', 'dateFormat', 'idMcEntity', 'idMcSystemUser', 'idWebFile', 'importModeCd', 'processedRecords', 'skippedRecords', 'statusCd', 'updatedAt', 'updatedBy', 'updatedRecords', 'importedBy', 'importName'];
  public propertiesSpecial: string[] = [];



  public static getImportModeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrConsumerImportGENERATED.IMPORT_MODE_CD_ADD, McGod.t('Add new'));
    list.add(CsrConsumerImportGENERATED.IMPORT_MODE_CD_ADDUPD, McGod.t('Add new & update existing'));
    list.add(CsrConsumerImportGENERATED.IMPORT_MODE_CD_UPD, McGod.t('Update existing'));


    return list;
  }

  public static getImportModeCdLabel(aCd: string): string {
    const list = CsrConsumerImportGENERATED.getImportModeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrConsumerImportGENERATED.STATUS_CD_SUCCESS, McGod.t('Success'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = CsrConsumerImportGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): CsrConsumerImport {
    console.error('MTCN-ERROR: Not Implemented: CsrConsumerImport::createNewInstance(). Add this method to final class and return new CsrConsumerImport();');
    throw new Error('Cannot CsrConsumerImport::createNewInstance(). Add this method to final class and return new CsrConsumerImport();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: ICsrConsumerImport): CsrConsumerImport {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrConsumerImportService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CsrConsumerImport AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getImportModeCdLabel(): string {
    return CsrConsumerImportGENERATED.getImportModeCdLabel(this.importModeCd);
  }


 public  isImportModeCdADD(): boolean {
    const result = (this.importModeCd === CsrConsumerImportGENERATED.IMPORT_MODE_CD_ADD);

    return result;
  }

 public  isImportModeCdADDUPD(): boolean {
    const result = (this.importModeCd === CsrConsumerImportGENERATED.IMPORT_MODE_CD_ADDUPD);

    return result;
  }

 public  isImportModeCdUPD(): boolean {
    const result = (this.importModeCd === CsrConsumerImportGENERATED.IMPORT_MODE_CD_UPD);

    return result;
  }


  public getStatusCdLabel(): string {
    return CsrConsumerImportGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdSUCCESS(): boolean {
    const result = (this.statusCd === CsrConsumerImportGENERATED.STATUS_CD_SUCCESS);

    return result;
  }


  
// ---------------------------------------------------------------------
public callImportConsumers( mappingId: number, handleDuplicatesCd: string, dateFormat: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.importConsumers(mappingId,handleDuplicatesCd,dateFormat).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

  // ---------------------------------------------------------------------
  public loadByEntityIdAndWebFileId( idMcEntity: number, idWebFile: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByEntityIdAndWebFileId(idMcEntity,idWebFile)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

}
