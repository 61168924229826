import {CsrContractPreDefined} from './csr-contract-pre-defined.model';
import {CsrContractPreDefinedListGENERATED} from '../_generated/csr-contract-pre-defined-list-generated.model';

export class CsrContractPreDefinedList extends CsrContractPreDefinedListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrContractPreDefinedList();
  }

  // ---------------------------------------------------------------------
}
