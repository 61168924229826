<ng-container>
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-wrapper">
    <lib-mc-loader [showLoader]="loaderFlg"></lib-mc-loader>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-header">
      <div class="d-flex"><img src="assets/images/import_consumer.svg"> <h4 class="modal-title pl-3"
                                                                            id="mc-modal-new-user-title">{{'cc.consumers.consumers-overview.import-consumers' | translate}}</h4>
      </div>
    </div>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-body">
      <div class="row">

        <div class="col-md-12 mc-stepper-header-title mb-4">
        <span class="px-4 py-2 mc-semi-active">
          <span class="mc-notification-badge-gray mc-semi-active-background">1</span>
          {{'cc.consumers.consumers-overview.file-upload' | translate}}
        </span>
          <span class="px-4 py-2 mc-active">
          <span class="mc-notification-badge-gray mc-active-background">2</span>
            {{'cc.consumers.consumers-overview.mapping-&-importing' | translate}}
        </span>
        </div>

        <!-- --------------------------------------------------------------------------------------- -->

        <div class="col-md-12">
          <div class="mc-error position-relative mt-n3">
            <mc-notify-message [message]="notifyMessageObject.message"
                               [type]="notifyMessageObject.type"></mc-notify-message>
          </div>
        </div>

        <!-- --------------------------------------------------------------------------------------- -->
        <ng-container>
          <div class="col-md-12 mt-4 d-flex align-items-center mc-background-light-blue mc-g-height-50">
            <label class="mb-0 pl-3 h6">{{'cc.consumers.consumers-overview.currently-applied-mapping' | translate}}
              : </label>
            <label class="form-check-label w-25 mx-3 pt-1">{{currentTemplateMapping.mappingName}}</label>

            <button class="system-action-btn ml-auto pr-3"
                    (click)="actShowChooseMappingMcb()">{{'cc.consumer.import.choose-mapping' | translate}}</button>
          </div>

          <!-- -------------------- -->

          <div class="col-md-12 pt-3 d-flex flex-row align-items-center">
            <i class="far fa-calendar-alt text-primary"></i>
            <mcc-fi-select class="col-md-3 mx-2"
                           [extraCssClass]="'select-in-component-width-50 w-100'"
                           [mcForm]="mcForm"
                           [name]="'dateFormat'"
                           (eventValueChanged)="eventFromComponentTEST()"
                           [value]="'dd.MM.yyyy'"
                           [optionsVll]="dateFormatVll"
                           label="Date Format"></mcc-fi-select>
          </div>

          <!-- -------------------- -->
          <div class="col-md-12 mc-background-light-grey p-3">
            <i class="fas fa-info-circle text-primary"></i> <span
            class="pl-2">{{'cc.consumer.import.required-mapping-columns-contract-number-gender-first-name-last-name' | translate}}</span>
          </div>
          <!-- -------------------- -->

          <div class="col-md-12 mc-table-wrapper mc-consumers-import-table d-flex flex-column">
            <!--            <mc-add-custom-attribute class="ml-auto pt-3" [entityAttributes]="classCustomAtt"></mc-add-custom-attribute>-->

            <button class="system-action-btn ml-auto mt-3" (click)="actShowCreateCustomAttributeMcb()">
              <i class="fas fa-plus"></i> {{ 'cc.common.view.create-attribute' | translate }}</button>

            <ng-container *ngIf="!loaderFlg">
              <mcc-fi-table-2 [objectList]="mapping.mappingItems" [table2Config]="mappingItemTableConfig"
                              (eventTableCellEditConfig)="onMappingItemTableCellConfig($event)"
                              (eventTableCellEdited)="onMappingTableCellEdited($event)"></mcc-fi-table-2>
            </ng-container>
          </div>

          <!-- -------------------------------------------------------------------------------------- -->
          <div class="col-md-5 p-3 mc-background-light-grey d-flex justify-content-start">
            <mcc-fi-select [mcForm]="mcForm"
                           [name]="'importMode'"
                           [value]="''"
                           [optionsVll]="duplicateHandlingOptionsKvm"
                           (eventValueChanged)="checkImportMode($event)"
                           label="{{'cc.consumers.consumers-overview.import-mode' | translate}}"></mcc-fi-select>
          </div>

          <div class="col-md-7 p-3 mc-background-light-grey d-flex">
            <div>
              <mcc-fi-checkbox label="{{'cc.consumers.consumers-overview.save-mapping' | translate}}"
                               [name]="'saveMappingCb'"
                               [value]="false"
                               (eventValueChanged)="checkIfSaveMappingIsChecked($event)"
                               [mcForm]="mcForm"></mcc-fi-checkbox>

              <mcc-fi-textbox *ngIf="mcForm.getValue('saveMappingCb')"
                              label="{{'cc.consumers.consumers-overview.mapping-name' | translate}}:"
                              name="txbMappingName" [validRequiredFlg]="this.mcForm.getValue('saveMappingCb')"
                              [mcForm]="mcForm"></mcc-fi-textbox>
            </div>
            <div>
              <mcc-fi-checkbox *ngIf="mcForm.getValue('saveMappingCb')"
                               label="Share mapping"
                               [name]="'shareMappingCb'"
                               [value]="false"
                               [mcForm]="mcForm"></mcc-fi-checkbox>
            </div>
          </div>

        </ng-container>

        <div class="col-md-12 mb-5 mt-2">
          <div class="mc-error position-relative mt-n3">
            <mc-notify-message [message]="notifyMessageObject.message"
                               [type]="notifyMessageObject.type"></mc-notify-message>
          </div>
        </div>


        <mcc-modal1-wrapper *ngIf="showChooseMappingMcb.value" [showMcb]="showChooseMappingMcb">
          <mc-consumer-choose-mapping (cancelEmit)="closeChooseMappingMcb()" [templateMappings]="templateMappings" [currentTemplateMappingName]="currentTemplateMapping.mappingName"
                                      (chosenTemplateMappingEmit)="onTemplateMappingChanged($event)"></mc-consumer-choose-mapping>
        </mcc-modal1-wrapper>
        <mcc-modal1-wrapper *ngIf="showCreateCustomAttributeMcb.value" [showMcb]="showCreateCustomAttributeMcb">
          <app-shared-custom-attribute-add-edit-part (cancelEmit)="closeCreateCustomAttributeMcb()"
                                                     [entityAttributes]="classCustomAtt"></app-shared-custom-attribute-add-edit-part>
        </mcc-modal1-wrapper>

      </div>
    </div><!-- modal-body -->

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-footer">

      <button class="btn btn-secondary" (click)="actPrev()">
        <i class="fas fa-arrow-left"></i> {{'cc.common.view.prev' | translate}}
      </button>
      <button class="btn btn-secondary move-right" (click)="actCancel()">
        <i class="far fa-times-circle"></i> {{ 'cc.common.edit.cancel' | translate }}
      </button>
      <button class="btn btn-primary mr-3" (click)="actUpload()" [disabled]="disabledImportButtonFlag">
        <img src="assets/images/small_white_import_icon.svg"> {{'cc.common.view.import' | translate}}
      </button>

    </div>
  </div>

  <router-outlet></router-outlet>
</ng-container>


