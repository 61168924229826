<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="isLoading()"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h1>{{'cc.factoring.export-sepa' | translate}}</h1>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!isLoading()" class="content-body">
    <mcc-message [errorMsg]="bankAccounts.apiErrorMessage"></mcc-message>
    <mcc-message [errorMsg]="entity.apiErrorMessage"></mcc-message>
    <mcc-message [errorMsg]="sepaExport.apiErrorMessage"></mcc-message>
    <mcc-message [errorMsgs]="errorMsgs"
                 label="{{'cc.out-factoring.unable-to-complete-export-please-correct-the-following-problems' | translate}}:"></mcc-message>

    <!-- == STEP 1 ======================================== -->
    <ng-container *ngIf="isStep1()">

      <div class="row">
        <div class="col-md-12">
          <div class="content-section">
            <h2>{{ebSepaFileTransactionList.getCount()}} {{'cc.out-factoring.transactions-selected-for-export' | translate}}
              .</h2>

            <div>
              <label>The minimal requested collection date for the transactions you chose is: {{largestTransactionDateString}}</label>
              <mcc-fi-date-picker
                label="{{'cc.out-factoring.requested-collection-date' | translate}}"
                name="dpCollectionDate"
                [mcForm]="mcForm"
                [minDateInput]="largestTransactionDate"
                validRequiredFlg="true">
              </mcc-fi-date-picker>
            </div>

            <mcc-fi-select
              name="selBankAccountId"
              label="{{'cc.common.edit.bank-account' | translate}}"
              value=""
              [mcForm]="mcForm"
              [optionsVll]="bankAccounts.toVll('id', 'fldTitleIbanBicDefault()', '-- Select Bank Account')"
              validRequiredFlg="true">
            </mcc-fi-select>

          </div>
        </div>
      </div><!-- row -->
    </ng-container>


    <!-- == STEP 2 ======================================== -->
    <ng-container *ngIf="!isLoading() && isStep2()">

      <div class="row">
        <div class="col-md-12">
          <div class="content-section">
            <h2>{{'cc.out-factoring.export-sepa-has-completed-successfully' | translate}}. </h2>

            <div *ngIf="sepaExportWebFile.exists()" class="row">
              {{this.sepaExportWebFile.filename}}<br>
              <button (click)="downloadFile()">{{'cc.out-factoring.click-here-to-download-the-file' | translate}}!
              </button>
            </div>

          </div>
        </div>
      </div><!-- row -->
    </ng-container>


    <!-- == FOOTER ======================================== -->
    <!--THIS CONTAINER IS MANDATORY-->


  </div><!-- content-body -->
  <div class="content-footer">

    <mcc-button *ngIf="isStep2() || hasErrors()" class="move-right" extraCssClasses="btn btn-secondary move-right"
                (eventClicked)="actClose();" label="{{'cc.common.close' | translate}}"></mcc-button>
    <mcc-button *ngIf="isStep1()" extraCssClasses="btn btn-secondary move-right" (eventClicked)="actCancel();"
                label="{{'cc.common.edit.cancel' | translate}}"></mcc-button>
    <mcc-button *ngIf="isStep1()" extraCssClasses="btn btn-primary" (eventClicked)="actExport();"
                label="{{'cc.reporting.export' | translate}}" [disabledMcb]="exportButtonDisabledMcb"
                disableOnFirstClickFlg="true"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
