import {PpOrderFilterGENERATED} from '../_generated/pp-order-filter-generated.model';

export class PpOrderFilter extends PpOrderFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PpOrderFilter();
  }

  // ---------------------------------------------------------------------
}
