import {Table3ColumnConfig} from "./table-3-column-config";
import {Base} from '../_core/base.model';

export class Table3Config extends Base {
  private _builder: any = null;
  public columnObjectList: Table3ColumnConfig[] = [];
  public allowRowSelectionWithCheckboxesFlg = true;
  public allowDragDropRowsFlg = false;

  /*Constructor*/
  constructor() {
    super();
  }

  // ---------------------------------------------------------------------

  public clearColumns() {
    this.columnObjectList = [];
  }
  // ---------------------------------------------------------------------
  public addColumn(aColumnUniqueName: string, aColumnLabel: string, aObjectPropertyOrMethodName: string, aSortPropertyNameCsv: string) {
    const column = new Table3ColumnConfig(aColumnUniqueName, aColumnLabel, aObjectPropertyOrMethodName, aSortPropertyNameCsv);
    this.columnObjectList.push(column);
    return column;
  }

  // ---------------------------------------------------------------------
  public getBuilder(): BuilderTable3Config {
    if (this._builder == null) {
      this._builder = new BuilderTable3Config(this);
    }
    return this._builder;
  }

  addIdColumn() {
    const newColumn = new Table3ColumnConfig('id', 'ID', 'getRouterLink()', '');
    this.columnObjectList.push(newColumn);
  }
}

// ---------------------------------------------------------------------
class BuilderTable3Config {
  tableConfig: Table3Config;

  constructor(aTableConfig: Table3Config) {
    this.tableConfig = aTableConfig;
  }


}

