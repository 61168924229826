import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {McProcessRun} from "@miticon-ui/mc-core";
import {
  McForm,
  McBoolean,
} from "@miticon-ui/mc-core";

@Component({
  selector: "mc-process-process-run-add-edit",
  templateUrl: "./mc-process-process-run-add-edit.component.html",
})
export class McProcessProcessRunAddEditComponent implements OnInit {

  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  errorMsgs: string[] = [];
  mcProcessRunStatusRunning = McProcessRun.STATUS_CD_RUNNING;
  errorMsg!: string;

  // -- Input --
  @Input() mcProcessId!: number;
  @Input() mcProcessRunId!: number;
  @Input() mcProcessRun!: McProcessRun;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();





  // ---------------------------------------------------------------------
  ngOnInit() {
    if (!this.mcProcessRun) {
      this.mcProcessRun = new McProcessRun();
    }
    this.actLoad();
  }


  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcProcessRun = new McProcessRun();
    if (this.mcProcessRunId > 0) {
      this.mcProcessRun.loadById(this.mcProcessRunId, () => {
        this.validatePrerequisites();
      });
    } else {
      // new item
      this.validatePrerequisites();
    }

  }

  // ---------------------------------------------------------------------
  actSave() {

    if (this.mcForm.isValid()) { // TODO: had problem with checkboxes component, i will change this, Miljan
      this.mcProcessRun.idMcProcess = this.mcProcessId;
      this.mcProcessRun.typeCd = McProcessRun.TYPE_CD_MANUAL;

      this.mcProcessRun.loadItemsFlg = this.mcForm.getValue('chkNewItems');
      this.mcProcessRun.buildItemsFlg = this.mcForm.getValue('chkBuildItems');
      this.mcProcessRun.deliverItemsFlg = this.mcForm.getValue('chkDeliverItems');
      this.mcProcessRun.statusCd = this.mcForm.getValue('chkStartRun') ?
        McProcessRun.STATUS_CD_RUN_STARTING : (this.mcProcessRun.statusCd ? this.mcProcessRun.statusCd : McProcessRun.STATUS_CD_NEW);

      this.mcProcessRun.save(() => {
        this.eventSaved.emit();
      }, () => {
        this.saveButtonDisabledMcb.setFalse();
        this.errorMsg = this.mcProcessRun.apiErrorMessage;
      });
    } else {
      this.saveButtonDisabledMcb.setFalse();
    }
  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.mcProcessRun.exists()) {

      this.mcProcessRun.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

// ---------------------------------------------------------------------
  validatePrerequisites() {
    this.errorMsgs = [];
    // populate validation here on required inputs
  }

  // ---------------------------------------------------------------------
  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }

  checkBuildItemsCbValueChanged(eventValue: boolean) {
    if (!eventValue) {
      this.mcForm.getControl('chkDeliverItems')?.get('value')?.setValue(false);
    }
  }

}
