/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McEntity} from '../models/mc-entity.model';
import {McEntityService} from '../services/mc-entity.service';
import {IMcEntity} from '../_generated/mc-entity.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {McBankAccount} from '../models/mc-bank-account.model';


export abstract class McEntityGENERATED extends BaseObject {


  public apiService: McEntityService;
  public _rawJson: IMcEntity;
    id: number = 0;
  abbreviation = '';
  active: boolean;
  address = '';
  bankTransactionEntityNameOverride = '';
  city = '';
  companyForm = '';
  countryId: number;
  courtInCharge = '';
  createdAt: number;
  description = '';
  email = '';
  euVat = '';
  extCreditorId = '';
  fax = '';
  impressum = '';
  ipWhitelist = '';
  isVisibleToChildren: boolean;
  latitude: number;
  legalRepresentative = '';
  legalRepresentativeContact = '';
  logo = '';
  longitude: number;
  mailDomain = '';
  mailPrefix = '';
  maxTransactionPauseDays: number;
  name = '';
  parentEntityId: number;
  phone = '';
  postalCode = '';
  responsiblePerson = '';
  responsiblePersonFunction = '';
  rootEntityId: number;
  studioOwner = '';
  studioOwnerAddress = '';
  studioOwnerCity = '';
  studioOwnerPostalCode = '';
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  sysUpdatedUserId: number;
  tax = '';
  transactionDescriptionPrefix = '';
  updatedAt: number;
  ustId = '';
  version: number;
  webDomain = '';
  website = '';
  bankAccount: McBankAccount;  parent: McEntity;  rolesCount: number;  usersCount: number;

  public properties: string[] = ['id', 'abbreviation', 'active', 'address', 'bankTransactionEntityNameOverride', 'city', 'companyForm', 'countryId', 'courtInCharge', 'createdAt', 'customAttributeMap', 'description', 'email', 'euVat', 'extCreditorId', 'fax', 'impressum', 'ipWhitelist', 'isVisibleToChildren', 'latitude', 'legalRepresentative', 'legalRepresentativeContact', 'logo', 'longitude', 'mailDomain', 'mailPrefix', 'maxTransactionPauseDays', 'name', 'parentEntityId', 'phone', 'postalCode', 'responsiblePerson', 'responsiblePersonFunction', 'rootEntityId', 'studioOwner', 'studioOwnerAddress', 'studioOwnerCity', 'studioOwnerPostalCode', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'tax', 'transactionDescriptionPrefix', 'updatedAt', 'ustId', 'version', 'webDomain', 'website', 'bankAccount', 'parent', 'rolesCount', 'usersCount'];
  public propertiesRegular: string[] = ['id', 'abbreviation', 'active', 'address', 'bankTransactionEntityNameOverride', 'city', 'companyForm', 'countryId', 'courtInCharge', 'createdAt', 'customAttributeMap', 'description', 'email', 'euVat', 'extCreditorId', 'fax', 'impressum', 'ipWhitelist', 'isVisibleToChildren', 'latitude', 'legalRepresentative', 'legalRepresentativeContact', 'logo', 'longitude', 'mailDomain', 'mailPrefix', 'maxTransactionPauseDays', 'mcCountry', 'name', 'parentEntityId', 'phone', 'postalCode', 'responsiblePerson', 'responsiblePersonFunction', 'rootEntityId', 'studioOwner', 'studioOwnerAddress', 'studioOwnerCity', 'studioOwnerPostalCode', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'tax', 'transactionDescriptionPrefix', 'updatedAt', 'ustId', 'version', 'webDomain', 'website', 'rolesCount', 'usersCount'];
  public propertiesSpecial: string[] = ['bankAccount', 'parent'];


  // ---------------------------------------------------------------------
public static createNewInstance(): McEntity {
    console.error('MTCN-ERROR: Not Implemented: McEntity::createNewInstance(). Add this method to final class and return new McEntity();');
    throw new Error('Cannot McEntity::createNewInstance(). Add this method to final class and return new McEntity();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcEntity): McEntity {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McEntityService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McEntity AABB()');
          if (this._rawJson['bankAccount']) {
        this.bankAccount = McBankAccount.createFromJson(this._rawJson['bankAccount']);
      }
      if (this._rawJson['parent']) {
        this.parent = McEntity.createFromJson(this._rawJson['parent']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getCreatedAtStrD(): string {
       return McDateUtils.formatDateToString(this.createdAt);
    }

    public getCreatedAtStr(): string {
       return McDateUtils.formatDateTimeToString(this.createdAt);
    }

    public getLatitudeStr(): string {
       return McGod.formatNumberToMoney(this.latitude);
    }

    public getLongitudeStr(): string {
       return McGod.formatNumberToMoney(this.longitude);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getUpdatedAtStrD(): string {
       return McDateUtils.formatDateToString(this.updatedAt);
    }

    public getUpdatedAtStr(): string {
       return McDateUtils.formatDateTimeToString(this.updatedAt);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('bankAccount')) {
        if (this.bankAccount != null) {
            dto['bankAccount'] = this.bankAccount.toDto();
        } else {
            dto['bankAccount'] = null;
        }
    }
    if (this.hasOwnProperty('parent')) {
        if (this.parent != null) {
            dto['parent'] = this.parent.toDto();
        } else {
            dto['parent'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
