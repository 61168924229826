
import {Table3Config} from '../extra/table-3-config';

// import {PpOrderGENERATED} from '../_generated/pp-order-generated.model';

export class PpOrderTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDueDate', 'Due date', 'dueDate', 'dueDate');
    this.addColumn('colIdReceiverMcEntity', 'ID of the receiver entity', 'idReceiverMcEntity', 'idReceiverMcEntity');
    this.addColumn('colIdRefReceiver', 'ID of the referenced receiver', 'idRefReceiver', 'idRefReceiver');
    this.addColumn('colIdSenderMcEntity', 'ID of the sender entity', 'idSenderMcEntity', 'idSenderMcEntity');
    this.addColumn('colIdWebFile', 'Invoice web file ID', 'idWebFile', 'idWebFile');
    this.addColumn('colOrderNumber', 'Order number', 'orderNumber', 'orderNumber');
    this.addColumn('colReason', 'Reason', 'reason', 'reason');
    this.addColumn('colRefTypeCd', 'Ref type', 'refTypeCd', 'refTypeCd');
    this.addColumn('colSendingDate', 'Sending date', 'sendingDate', 'sendingDate');
    this.addColumn('colServicePeriodFrom', 'Service period from', 'servicePeriodFrom', 'servicePeriodFrom');
    this.addColumn('colServicePeriodTo', 'Service period to', 'servicePeriodTo', 'servicePeriodTo');
    this.addColumn('colState', 'State', 'state', 'state');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');
    this.addColumn('colTotalAmount', 'Total amount', 'totalAmount', 'totalAmount');
    this.addColumn('colTypeCd', 'Type', 'typeCd', 'typeCd');
    this.addColumn('colVersion', 'Version', 'version', 'version');

*/
  }
}
