import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UploadFileComponent} from './upload-file.component';
import {MccFiFileUploadModule} from '../mcc-fi-file-upload/mcc-fi-file-upload.module';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  declarations: [UploadFileComponent],
  imports: [
    CommonModule,
    MccFiFileUploadModule,
    TranslateModule,
  ],
  exports: [UploadFileComponent]
})
export class UploadFileModule { }
