/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {ZzPocCarTable3ConfigGENERATED} from '../_generated/zz-poc-car-table-3-config-generated.model';

// import {ZzPocCarGENERATED} from '../_generated/zz-poc-car-generated.model';

export class ZzPocCarTable3Config extends ZzPocCarTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colTypeCd', 'Type', 'typeCd', 'typeCd');
    this.addColumn('colTitle', 'Title', 'fldTitle()', 'title');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colHorsePower', 'Horse Power', 'horsePower', 'horsePower');
    this.addColumn('colSysCreatedUserId', 'Sys Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');


  }
}
