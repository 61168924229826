<div class="content-wrapper">
  <div class="content-header">
    <h2>{{ (isCreate ? 'cc.transactions.create-transaction-description' : 'cc.transactions.edit-transaction-description') | translate}}</h2>
  </div>
  <div class="content-body">
    <lib-mc-loader [showLoader]="loadingFlg"></lib-mc-loader>
    <div class="gen-info">
      <p>
        <i class="fas fa-info-circle" ngbTooltip="{{'cc.common.maximum-number-of-characters' | translate}} is 140, including mandatory part CC{contract-number}-ID{transactionId}E{entityId}">
        </i> {{'cc.common.maximum-number-of-characters' | translate}} 20
      </p>
    </div>
    <mcc-fi-textarea [name]="'ctrlTransactionDescription'"
                     label="{{'cc.factoring.transaction-description' | translate}}"
                     [validMaxLength]="20"
                     [numCols]="40"
                     [numRows]="1"
                     [value]="transactionDescription"
                     [mcForm]="mcForm"
                     placeholder='e.g Member Fee January/2022'>
    </mcc-fi-textarea>
  </div>
  <div class="content-footer">
    <button class="btn btn-secondary move-right" (click)="actCancel()"><i class="far fa-times-circle pr-2"></i>{{'cc.common.edit.cancel' | translate}}</button>
    <button class="btn btn-primary" (click)="actSave()"><i class="fas fa-save"></i> {{'cc.common.view.save' | translate}}</button>
  </div>
</div>
