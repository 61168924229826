/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EbStarmoneyFile} from '../models/eb-starmoney-file.model';
import {EbStarmoneyFileList} from '../models/eb-starmoney-file-list.model';
import {EbStarmoneyFileFilter} from '../models/eb-starmoney-file-filter.model';
import {EbStarmoneyFileService} from '../services/eb-starmoney-file.service';
import {IEbStarmoneyFile} from '../_generated/eb-starmoney-file.interface';
import {McGod} from "../extra/mc-god.service";

export class EbStarmoneyFileListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'createdAt', 'idMcEntity', 'idMcSystemUser', 'idWebFile', 'statusCd', 'updatedAt', 'amountReturned', 'amountWithdrawn', 'importDate', 'importedBy', 'newTransactionsCount', 'repeatedTransactionsCount', 'returnedTransactionsCount', 'starMoneyFilename', 'withdrawnTransactionsCount'];
  public items: EbStarmoneyFile[] = [];
  public apiService: EbStarmoneyFileService;
 // protected tempObjList: EbStarmoneyFileList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbStarmoneyFileList {
    console.error('MTCN-ERROR: Not Implemented: EbStarmoneyFileList::createNewInstance(). Add this method to final class and return new EbStarmoneyFileList();');
    throw new Error('Cannot EbStarmoneyFileList::createNewInstance(). Add this method to final class and return new EbStarmoneyFileList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEbStarmoneyFile[]): EbStarmoneyFileList {
    const  listObj: EbStarmoneyFileList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EbStarmoneyFile = EbStarmoneyFile.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EbStarmoneyFileList {
    const  listObj: EbStarmoneyFileList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbStarmoneyFileService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEbStarmoneyFile[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EbStarmoneyFile = EbStarmoneyFile.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEbStarmoneyFileFilter: EbStarmoneyFileFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEbStarmoneyFileFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EbStarmoneyFile {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EbStarmoneyFile();
    }
  }

  public replaceItem(newItem: EbStarmoneyFile) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
// ---------------------------------------------------------------------
public callImportStarMoneyFile( ebStarMoneyFile: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.importStarMoneyFile(ebStarMoneyFile).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

  // ---------------------------------------------------------------------
  public loadByEntityId( idMcEntity: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByEntityId(idMcEntity, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

