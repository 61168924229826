<div *ngIf="!localAttributesList && !inheritedAttributesList" class="col-md-12 mc-stepper-header-title">
  <span class="pl-4 pb-1 mr-2" [ngClass]="attributesTab ? 'mc-semi-active': ''">
    <span class="mc-notification-badge-gray" [ngClass]="attributesTab ? 'mc-semi-active-background': ''">1</span>
    {{ 'cc.common.view.basic-info' | translate }}
  </span>
  <span class="pl-4 pb-1 ml-4 mr-2" [ngClass]="attributesTab ? 'mc-active': ''">
    <span class="mc-notification-badge-gray" [ngClass]="attributesTab ? 'mc-active-background': ''">2</span>
      Attributes
  </span>
  <span class="pl-4 pb-1 ml-4 mr-2">
    <span class="mc-notification-badge-gray">3</span>
      Password policy
  </span>
  <span class="pl-4 pb-1 ml-4 mr-2">
    <span class="mc-notification-badge-gray">4</span>
      Pricelist
  </span>
</div>

<div class="row mc-entity-attributes-area gray-background">
  <div [formGroup]="entityAttributesForm" class="mc-entity-attributes-wrapper pt-5 col-md-12">
    <div *ngIf="localAttributesList || inheritedAttributesList" class="mc-notify-wrapper-attributes">
      <mc-notify-message [message]="message" [type]="type"></mc-notify-message>
    </div>
    <div class="row mc-attributes">
      <!--Left column local attributes-->
      <div class="col-md-6">
        <h5>{{'cc.entity-profile.view.local-attributes' | translate}}</h5>
        <mc-entity-local-attributes-modal (createNewLocalAttribute)="addNewLocalAttribute($event)" [attributesFromBe]="attributesFromBe" [parentLocalAttributes]="entityLocalAttributesList"></mc-entity-local-attributes-modal>
        <div class="mc-table-local-attributes-wrapper pr-4">
          <table class="table table-borderless mc-table-local-attributes">
            <thead class="mc-table-header-local-attributes">
            <tr>
              <th class="text-uppercase"></th>
              <th class="text-uppercase"></th>
              <th class="text-uppercase"></th>
              <th class="text-uppercase"></th>
              <th class="text-uppercase mc-blue-text">NAME</th>
              <th class="text-uppercase mc-blue-text">TYPE</th>
              <th class="text-uppercase mc-blue-text">{{ 'cc.common.view.default-value' | translate }}</th>
              <th class="text-uppercase"></th>
              <th class="text-uppercase"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let attribute of entityLocalAttributesList; let i = index" class="border-bottom-row">
              <td>
                <mc-entity-local-attributes-modal (editLocalAttribute)="editLocalAttribute($event, i)" [parentEntityID]="entityId" [parentLocalAttributes]="entityLocalAttributesList" [attributesFromBe]="attributesFromBe" [editAttribute]="attribute"></mc-entity-local-attributes-modal>
              </td>
              <td>
                <img [src]="attribute.enabled === true ? attributesIcons.enable.path : attributesIcons.disable.path" [alt]="attribute.enabled === true ? attributesIcons.enable.alt : attributesIcons.disable.alt">
              </td>
              <td>
                <img [src]="attribute.inheritable === true ? attributesIcons.inheritable.path : attributesIcons.notInheritable.path" [alt]="attribute.inheritable === true ? attributesIcons.inheritable.alt : attributesIcons.notInheritable.alt">
              </td>
              <td>
                <img [src]="attribute.required === true ? attributesIcons.required.path : attributesIcons.notRequired.path" [alt]="attribute.required === true ? attributesIcons.required.alt : attributesIcons.notRequired.alt">
              </td>
              <td>{{attribute.name}}</td>
              <td [ngSwitch]="attribute.type">
                <span *ngSwitchCase="'INTEGER'">{{attribute.length ? ('Number' + '(' + attribute.length + ')') : 'Number'}}</span>
                <span *ngSwitchCase="'VARCHAR'">{{attribute.length ? ('String' + '(' + attribute.length + ')') : 'String'}}</span>
                <span *ngSwitchCase="'DATETIME'">{{attribute.length ? ('Date Time' + '(' + attribute.length + ')') : 'Date Time'}}</span>
                <span *ngSwitchCase="'DATE'">{{attribute.length ? ('Date ' + '(' + attribute.length + ')') : 'Date '}}</span>
                <span *ngSwitchCase="'FLOAT'">{{attribute.length ? ('Decimal' + '(' + attribute.length + ')') : 'Decimal'}}</span>
                <span *ngSwitchCase="'BOOLEAN'">{{attribute.length ? ('Yes/No' + '(' + attribute.length + ')') : 'Yes/No'}}</span>
                <span *ngSwitchDefault>{{attribute.length ? (attribute.type + '(' + attribute.length + ')') : attribute.type}}</span>
              </td>
              <td *ngIf="attribute.defaultValue" [ngSwitch]="attribute.type">
                <span *ngSwitchCase="'DATETIME'">{{attribute.defaultValue.day + "/" + attribute.defaultValue.month + "/" + attribute.defaultValue.year}}</span>
                <span *ngSwitchCase="'DATE'">{{attribute.defaultValue.day + "/" + attribute.defaultValue.month + "/" + attribute.defaultValue.year}}</span>
                <span *ngSwitchCase="'Date Time'">{{attribute.defaultValue.day + "/" + attribute.defaultValue.month + "/" + attribute.defaultValue.year}}</span>
                <span *ngSwitchDefault>{{attribute.defaultValue}}</span>
              </td>
              <td *ngIf="!attribute.defaultValue"></td>
              <td>
                <img [src]="hoveredIcon === i ? attributesWhiteInfoIcon : attributesInfoIcon" class="mc-attribute-info" (mouseover)="hoveredIcon = i" (mouseout)="hoveredIcon = null" [alt]="attributesIcons.info.alt" placement="left" [ngbTooltip]="attribute.description ? attribute.description : 'Description is not defined.'" tooltipClass="description-attribute">
              </td>
              <td><span class="delete-local-attribute" (click)="removeLocalAttribute(i)">x</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--Right column inherited attributes-->
      <div class="col-md-6">
        <h5>{{'cc.common.view.inherited-parent-attributes' | translate}}</h5>
        <div class="mc-blue-text">
          <span class="inheritSelection" (click)="selectAll()">{{'cc.common.view.select-all' | translate}}</span> | <span class="inheritSelection" (click)="deselectAll()">deselect all</span>
        </div>
        <div class="mc-table-inherited-attributes-wrapper">
          <table class="table table-borderless mc-table-inherited-attributes">
            <thead class="mc-table-header-inherited-attributes">
            <tr>
              <th class="text-uppercase"></th>
              <th class="text-uppercase"></th>
              <th class="text-uppercase"></th>
              <th class="text-uppercase"></th>
              <th class="text-uppercase mc-blue-text">NAME</th>
              <th class="text-uppercase mc-blue-text">TYPE</th>
              <th class="text-uppercase mc-blue-text">{{ 'cc.common.view.default-value' | translate }}</th>
              <th class="text-uppercase"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let attribute of inheritedAttributes; let i = index" class="border-bottom-row">
              <td class="label-wrapper">
                <label class="custom-control fill-checkbox">
                  <input
                    type="checkbox"
                    class="fill-control-input"
                    id="inheritedAttribute{{i}}"
                    [checked]="attribute.select"
                    name="inheritedAttribute"
                    #selectInherit
                    (change)="onSelectInheritAttribute(attribute, selectInherit, i)"
                  />
                  <span class="fill-control-indicator checkbox-attributes-position"></span>
                </label>
              </td>
              <td>
                <img [src]="attribute.enabled === true ? attributesIcons.enable.path : attributesIcons.disable.path" [alt]="attribute.enabled === true ? attributesIcons.enable.alt : attributesIcons.disable.alt">
              </td>
              <td>
                <img [src]="attribute.inheritable === true ? attributesIcons.inheritable.path : attributesIcons.notInheritable.path" [alt]="attribute.inheritable === true ? attributesIcons.inheritable.alt : attributesIcons.notInheritable.alt">
              </td>
              <td>
                <img [src]="attribute.required === true ? attributesIcons.required.path : attributesIcons.notRequired.path" [alt]="attribute.required === true ? attributesIcons.required.alt : attributesIcons.notRequired.alt">
              </td>
              <td>{{attribute.name}}</td>
              <td [ngSwitch]="attribute.type">
                <span *ngSwitchCase="'INTEGER'">{{attribute.length ? ('Number' + '(' + attribute.length + ')') : 'Number'}}</span>
                <span *ngSwitchCase="'VARCHAR'">{{attribute.length ? ('String' + '(' + attribute.length + ')') : 'String'}}</span>
                <span *ngSwitchCase="'DATE'">{{attribute.length ? ('Date ' + '(' + attribute.length + ')') : 'Date '}}</span>
                <span *ngSwitchCase="'DATETIME'">{{attribute.length ? ('Date Time' + '(' + attribute.length + ')') : 'Date Time'}}</span>
                <span *ngSwitchCase="'FLOAT'">{{attribute.length ? ('Decimal' + '(' + attribute.length + ')') : 'Decimal'}}</span>
                <span *ngSwitchCase="'BOOLEAN'">{{attribute.length ? ('Yes/No' + '(' + attribute.length + ')') : 'Yes/No'}}</span>
              </td>
              <td>{{attribute.defaultValue}}</td>
              <td>
                <img [src]="hoveredIcon === 'inherit' + i ? attributesWhiteInfoIcon : attributesInfoIcon" class="mc-attribute-info" (mouseover)="hoveredIcon = 'inherit' + i" (mouseout)="hoveredIcon = null" [alt]="attributesIcons.info.alt" placement="left" [ngbTooltip]="attribute.description ? attribute.description : 'Description is not defined.'" tooltipClass="description-attribute">
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--Edit entityAttributes form-->
    <div *ngIf="isEdit" class="row mt-5">
      <div class="col-md-12">
        <button type="button" class="modal-save mb-4" [ngClass]="isDisabled === false ? 'mc-confirm-btn' : 'mc-disable-btn'" [disabled]="isDisabled" (click)="editEntityAttributes()">Save</button>
      </div>
    </div>
  </div>
</div>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
