/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McProcessRunFilter} from '../models/mc-process-run-filter.model';

export class McProcessRunFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProcessRunFilter {
    console.error('MTCN-ERROR: Not Implemented: McProcessRunFilter::createNewInstance(). Add this method to final class and return new McProcessRunFilter();');
      throw new Error('Cannot McProcessRunFilter::createNewInstance(). Add this method to final class and return new McProcessRunFilter();');
  }

}
