import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-shared-card',
  templateUrl: './app-shared-card.component.html',
  styleUrls: ['./app-shared-card.component.scss']
})
export class McCardComponent implements OnInit {

  @Input() header: string = '';
  @Input() showHeader = true;

  constructor() { }

  ngOnInit() {
  }

}
