
import {Table3Config} from '../extra/table-3-config';

// import {McBillingPayoutTableItemGENERATED} from '../_generated/mc-billing-payout-table-item-generated.model';

export class McBillingPayoutTableItemTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colBatch', 'Batch', 'batch', 'batch');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colFinalSum', 'Final sum', 'finalSum', 'finalSum');
    this.addColumn('colFlgToAdd', 'Flg To Add', 'flgToAdd', 'flgToAdd');
    this.addColumn('colInitialSum', 'Initial sum', 'initialSum', 'initialSum');
    this.addColumn('colPercentage', 'Percentage', 'percentage', 'percentage');

*/
  }
}
