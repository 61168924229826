/*BbmTestObj*/
import {ImDataImportGENERATED} from '../_generated/im-data-import-generated.model';
import {ICON_IMPORTED} from "../svg/icon-imported";
import {ICON_FAILED} from "../svg/icon-failed";
import {ICON_IN_PROGRESS} from "../svg/icon-in-progress";
import {ICON_PARTIALLY_IMPORTED} from "../svg/icon-partially-imported";
import moment from 'moment';

export class ImDataImport extends ImDataImportGENERATED {

  public override propertiesRegular: string[] = ['id', 'dataImport', 'dataSet', 'dataSetId', 'dataSetName', 'webFileName', 'delimiter', 'encoding', 'header', 'idMcEntity', 'importMode', 'mappingId', 'src', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'webFile', 'webFileId'];


  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataImport();
  }

  // ---------------------------------------------------------------------

  getImportDate() {
    return moment(this.sysCreatedDatetime).format('DD.MM.YYYY');
  }

  getStatusCd() {
    if (this.statusCd === ImDataImport.STATUS_CD_IMPORTED) {
      return `<div class='icon-text-table-cell'>${ICON_IMPORTED} <span>Imported</div>`
    }
    if (this.statusCd === ImDataImport.STATUS_CD_PARTIALLY_IMPORTED) {
      return `<div class='icon-text-table-cell'>${ICON_PARTIALLY_IMPORTED} <span>Partially imported</span></div>`
    }
    if (this.statusCd === ImDataImport.STATUS_CD_FAILED) {
      return `<div class='icon-text-table-cell'>${ICON_FAILED} <span>Failed</div>`
    }
    if (this.statusCd === ImDataImport.STATUS_CD_IN_PROGRESS) {
      return `<div class='icon-text-table-cell'>${ICON_IN_PROGRESS} <span>In progress</div>`
    }
    return this.statusCd;
  }

  getSource() {
    return this.src ? this.src : '-';
  }
}
