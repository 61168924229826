import {ImDataImportItem} from './im-data-import-item.model';
import {ImDataImportItemListGENERATED} from '../_generated/im-data-import-item-list-generated.model';

export class ImDataImportItemList extends ImDataImportItemListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataImportItemList();
  }

  // ---------------------------------------------------------------------
}
