import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'mk-mat-filter-wrapper',
  templateUrl: './mk-mat-filter-wrapper.component.html',
  styleUrls: ['./mk-mat-filter-wrapper.component.scss']
})
export class MkMatFilterWrapperComponent implements OnInit {

  isExpanded = false;
  panelOpenState = false;

  constructor() { }

  ngOnInit() {
  }

  toggleFilter() {
    this.isExpanded = !this.isExpanded;
  }
}
