
import {Table3Config} from '../extra/table-3-config';

// import {McBillingBatchAssignedToStatementGENERATED} from '../_generated/mc-billing-batch-assigned-to-statement-generated.model';

export class McBillingBatchAssignedToStatementTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAcceptedForSFColumnFinalSum', 'acceptedForSFColumnFinalSum', 'acceptedForSFColumnFinalSum', 'acceptedForSFColumnFinalSum');
    this.addColumn('colAcceptedForSFColumnInitialSum', 'acceptedForSFColumnInitialSum', 'acceptedForSFColumnInitialSum', 'acceptedForSFColumnInitialSum');
    this.addColumn('colAcceptedForSFColumnPercentage', 'acceptedForSFColumnPercentage', 'acceptedForSFColumnPercentage', 'acceptedForSFColumnPercentage');
    this.addColumn('colAlreadyPaidBalanceColumnFinalSum', 'alreadyPaidBalanceColumnFinalSum', 'alreadyPaidBalanceColumnFinalSum', 'alreadyPaidBalanceColumnFinalSum');
    this.addColumn('colAlreadyPaidBalanceColumnInitialSum', 'alreadyPaidBalanceColumnInitialSum', 'alreadyPaidBalanceColumnInitialSum', 'alreadyPaidBalanceColumnInitialSum');
    this.addColumn('colAlreadyPaidBalanceColumnPercentage', 'alreadyPaidBalanceColumnPercentage', 'alreadyPaidBalanceColumnPercentage', 'alreadyPaidBalanceColumnPercentage');
    this.addColumn('colAlreadyPaidFromConsumerBalanceColumnFinalSum', 'alreadyPaidFromConsumerBalanceColumnFinalSum', 'alreadyPaidFromConsumerBalanceColumnFinalSum', 'alreadyPaidFromConsumerBalanceColumnFinalSum');
    this.addColumn('colAlreadyPaidFromConsumerBalanceColumnInitialSum', 'alreadyPaidFromConsumerBalanceColumnInitialSum', 'alreadyPaidFromConsumerBalanceColumnInitialSum', 'alreadyPaidFromConsumerBalanceColumnInitialSum');
    this.addColumn('colAlreadyPaidFromConsumerBalanceColumnPercentage', 'alreadyPaidFromConsumerBalanceColumnPercentage', 'alreadyPaidFromConsumerBalanceColumnPercentage', 'alreadyPaidFromConsumerBalanceColumnPercentage');
    this.addColumn('colAlreadyReturnedBalanceColumnFinalSum', 'alreadyReturnedBalanceColumnFinalSum', 'alreadyReturnedBalanceColumnFinalSum', 'alreadyReturnedBalanceColumnFinalSum');
    this.addColumn('colAlreadyReturnedBalanceColumnInitialSum', 'alreadyReturnedBalanceColumnInitialSum', 'alreadyReturnedBalanceColumnInitialSum', 'alreadyReturnedBalanceColumnInitialSum');
    this.addColumn('colAlreadyReturnedBalanceColumnPercentage', 'alreadyReturnedBalanceColumnPercentage', 'alreadyReturnedBalanceColumnPercentage', 'alreadyReturnedBalanceColumnPercentage');
    this.addColumn('colBatchHeader', 'Batch header', 'batchHeader', 'batchHeader');
    this.addColumn('colIdMcBillingBatch', 'Id of Mc Billing Batch', 'idMcBillingBatch', 'idMcBillingBatch');
    this.addColumn('colIdMcBillingStatement', 'Id of Mc Billing Statement', 'idMcBillingStatement', 'idMcBillingStatement');
    this.addColumn('colImmediatelyPayableBalanceColumnFinalSum', 'immediatelyPayableBalanceColumnFinalSum', 'immediatelyPayableBalanceColumnFinalSum', 'immediatelyPayableBalanceColumnFinalSum');
    this.addColumn('colImmediatelyPayableBalanceColumnInitialSum', 'immediatelyPayableBalanceColumnInitialSum', 'immediatelyPayableBalanceColumnInitialSum', 'immediatelyPayableBalanceColumnInitialSum');
    this.addColumn('colImmediatelyPayableBalanceColumnPercentage', 'immediatelyPayableBalanceColumnPercentage', 'immediatelyPayableBalanceColumnPercentage', 'immediatelyPayableBalanceColumnPercentage');
    this.addColumn('colImmediatelyPayableConsumerBalanceColumnFinalSum', 'immediatelyPayableConsumerBalanceColumnFinalSum', 'immediatelyPayableConsumerBalanceColumnFinalSum', 'immediatelyPayableConsumerBalanceColumnFinalSum');
    this.addColumn('colImmediatelyPayableConsumerBalanceColumnInitialSum', 'immediatelyPayableConsumerBalanceColumnInitialSum', 'immediatelyPayableConsumerBalanceColumnInitialSum', 'immediatelyPayableConsumerBalanceColumnInitialSum');
    this.addColumn('colImmediatelyPayableConsumerBalanceColumnPercentage', 'immediatelyPayableConsumerConsumerBalanceColumnPercentage', 'immediatelyPayableConsumerBalanceColumnPercentage', 'immediatelyPayableConsumerBalanceColumnPercentage');
    this.addColumn('colLaterPayableBalanceColumnFinalSum', 'laterPayableBalanceColumnFinalSum', 'laterPayableBalanceColumnFinalSum', 'laterPayableBalanceColumnFinalSum');
    this.addColumn('colLaterPayableBalanceColumnInitialSum', 'laterPayableBalanceColumnInitialSum', 'laterPayableBalanceColumnInitialSum', 'laterPayableBalanceColumnInitialSum');
    this.addColumn('colLaterPayableBalanceColumnPercentage', 'laterPayableBalanceColumnPercentage', 'laterPayableBalanceColumnPercentage', 'laterPayableBalanceColumnPercentage');
    this.addColumn('colMcBillingBatch', 'McEntity', 'mcBillingBatch', 'mcBillingBatch');
    this.addColumn('colMcBillingStatement', 'McEntity', 'mcBillingStatement', 'mcBillingStatement');
    this.addColumn('colNotAcceptedForSFColumnFinalSum', 'notAcceptedForSFColumnFinalSum', 'notAcceptedForSFColumnFinalSum', 'notAcceptedForSFColumnFinalSum');
    this.addColumn('colNotAcceptedForSFColumnInitialSum', 'notAcceptedForSFColumnInitialSum', 'notAcceptedForSFColumnInitialSum', 'notAcceptedForSFColumnInitialSum');
    this.addColumn('colNotAcceptedForSFColumnPercentage', 'notAcceptedForSFColumnPercentage', 'notAcceptedForSFColumnPercentage', 'notAcceptedForSFColumnPercentage');
    this.addColumn('colReturningAmountFinalBounceColumnFinalSum', 'returningAmountFinalBounceColumnFinalSum', 'returningAmountFinalBounceColumnFinalSum', 'returningAmountFinalBounceColumnFinalSum');
    this.addColumn('colReturningAmountFinalBounceColumnInitialSum', 'returningAmountFinalBounceColumnInitialSum', 'returningAmountFinalBounceColumnInitialSum', 'returningAmountFinalBounceColumnInitialSum');
    this.addColumn('colReturningAmountFinalBounceColumnPercentage', 'returningAmountFinalBounceColumnPercentage', 'returningAmountFinalBounceColumnPercentage', 'returningAmountFinalBounceColumnPercentage');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');

*/
  }
}
