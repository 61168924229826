/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McMappingTable3ConfigGENERATED} from '../_generated/mc-mapping-table-3-config-generated.model';

// import {McMappingGENERATED} from '../_generated/mc-mapping-generated.model';

export class McMappingTable3Config extends McMappingTable3ConfigGENERATED {

  // ----------------------------------------------------------------e-----
  constructor() {
    super();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colMappingName', this.t('cc.consumers.consumers-overview.mapping-name'), 'fldMappingNameAndSharedIcon()', 'mappingName');
    this.addColumn('colCreationDate', this.t('cc.consumers.creation-date-&-time'), 'fldDateFormat()', 'creationDate');
    this.addColumn('colCreatedBy', this.t('cc.consumers.imports.created-by'), 'fldUserName()', 'user.firstname');


  }
}
