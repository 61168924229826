
import {Table3Config} from '../extra/table-3-config';

// import {CsrNotificationDetailsGENERATED} from '../_generated/csr-notification-details-generated.model';

export class CsrNotificationDetailsTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colEmail', 'Email', 'email', 'email');
    this.addColumn('colSendInvoice', 'Invoice', 'sendInvoice', 'sendInvoice');
    this.addColumn('colStreetAddress', 'Street address', 'streetAddress', 'streetAddress');

*/
  }
}
