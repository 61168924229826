import {PmAdjustmentGENERATED} from '../_generated/pm-adjustment-generated.model';
import {McDateUtils} from '../common/mc-date-utils';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McGod} from '../extra/mc-god.service';
import {CurrencyUtil} from "../common/currency-util";

export class PmAdjustment extends PmAdjustmentGENERATED {

  public currencyCode!: string;

 static override createNewInstance() {
    return new PmAdjustment();
  }
 static override getPaymentMethodCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add(PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CASH, McGod.t('cc.transaction.adjustment.cash'));
    list.add(PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT, McGod.t('cc.common.credit'));
    list.add(PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT_CARD, McGod.t('cc.consumer.credit-card'));
    return list;
  }

  getStatusLabel(aCd: string): string {
    const list = PmAdjustmentGENERATED.getStatusCdVll();
    return list.getLabel(aCd, 'n/a');
  }
  getCreatedDate() {
    return McDateUtils.newFormatDateTimeString(this.sysCreatedDatetime);
  }

  getBeneficiaryEntityName() {
    return this.beneficiaryEntity.name;
  }

  public getAmountStr(): string {
    return CurrencyUtil.format(this.amount, this.currencyCode);
  }
}
