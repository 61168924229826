/*BbmTestObj*/
import {EbStarmoneyFileGENERATED} from '../_generated/eb-starmoney-file-generated.model';
import {EbStarmoneyFileService} from '../services/eb-starmoney-file.service';
import {McDateUtils} from '../common/mc-date-utils';

export class EbStarmoneyFile extends EbStarmoneyFileGENERATED {
  public static override createNewInstance() {
    return new EbStarmoneyFile();
  }

  fldStarMoneyFileName() {
    if (this.statusCd === EbStarmoneyFile.STATUS_CD_IN_PROGRESS) {
      return `<div class="mc-disabled-table-row">${this.starMoneyFilename}</div>`;
    } else {
      return this.starMoneyFilename;
    }
  }

  fldImportDateAndTime(): string {
    if (this.importDate) {
      if (this.statusCd === EbStarmoneyFile.STATUS_CD_IN_PROGRESS) {
        return `<div class="mc-disabled-table-row">${McDateUtils.newFormatDateTimeString(this.importDate)}</div>`;
      } else {
        return McDateUtils.newFormatDateTimeString(this.importDate);
      }
    }
    return '';
  }

  formatHoursAndMinutesInto2Digits(num: number) {
    if (num < 10) {
      return '0' + num;
    } else {
      return num;
    }
  }

  fldWithdrawnAndReturnedTransactions() {
    if (this.statusCd === EbStarmoneyFile.STATUS_CD_IN_PROGRESS) {
      return `<div class="mc-disabled-table-row">${this.withdrawnTransactionsCount + '/' + this.returnedTransactionsCount}</div>`;
    } else {
      return this.withdrawnTransactionsCount + '/' + this.returnedTransactionsCount;
    }
  }

  displayAmountWithdrawn() {
    return this.amountWithdrawn + ' EUR';
  }

  displayAmountReturned() {
    return this.amountReturned + ' EUR';
  }

  fldWithdrawnReturnedAmount() {
    if (this.statusCd === EbStarmoneyFile.STATUS_CD_IN_PROGRESS) {
      return `<div class="mc-disabled-table-row">${this.getAmountWithdrawnStr() + '/' + this.getAmountReturnedStr()}</div>`;
    } else {
      return this.getAmountWithdrawnStr() + '/' + this.getAmountReturnedStr();
    }
  }

  fldNewRepeatedTransactions() {
    if (this.statusCd === EbStarmoneyFile.STATUS_CD_IN_PROGRESS) {
      return `<div class="mc-disabled-table-row">${this.newTransactionsCount + '/' + this.repeatedTransactionsCount}</div>`;
    } else {
      return this.newTransactionsCount + '/' + this.repeatedTransactionsCount;
    }
  }

  fldImportedBy() {
    if (this.statusCd === EbStarmoneyFile.STATUS_CD_IN_PROGRESS) {
      return `<div class="mc-disabled-table-row">${this.importedBy}</div>`;
    } else {
      return this.importedBy;
    }
  }

  fldStarMoneyStatus() {
    if (this.getStatusCdLabel() === '--BAD-CD--') {
      const completedStatus = EbStarmoneyFile.STATUS_CD_COMPLETED;
      return completedStatus.charAt(0) + completedStatus.substring(1).toLowerCase();
    } else {
      return this.getStatusCdLabel();
    }
  }
}
