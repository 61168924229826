import {BaseObject} from '../_core/base-object';

export class Table3ColumnConfig {
  private _builder: any = null;

  public name: string;
  public label: string;
  public objectPropertyOrMethodName: string;
  public isHide = false;
  public selected = false;
  public editComponentClass: any;
  public editComponentTemplateConfigObj: any; // TODO Properly typecast
  public editObjectPropertyOrMethodName!: string;
  public editObjectPropertyBoundFlg = true;
  public sortPropertyNames: Array<string> = [];
  public extraCssClass!: string;
  public onEditComponentGetConfigObj!: (aCurrentConfigObj: any, aCurrentObjectItem: BaseObject) => any;
  public clickEvent: any;
  public customMethod: any;
  public isUsingServiceBridge = false;
  public isUsingOnInit = false;
  public extraService: any;
  public extraServiceProperty: any;

  // ---------------------------------------------------------------------
  constructor(aColumnUniqueName: string, aColumnLabel: string, aObjectPropertyOrMethodName: string, aSortPropertyNamesCsv: string | null = null) {
    this.name = aColumnUniqueName;
    this.label = aColumnLabel;
    this.objectPropertyOrMethodName = aObjectPropertyOrMethodName;

    if ((aSortPropertyNamesCsv != null) && (aSortPropertyNamesCsv.length > 0)) {
      aSortPropertyNamesCsv.split(',').forEach((value) => {
        this.sortPropertyNames.push(value);
      });
    }
  }

  // ---------------------------------------------------------------------
  // TODO At some point we should add the ability to have more than one component here. It is easy.
  addEditComponent(aEditComponentClass: any, aEditObjectPropertyOrMethodName: string, aEditComponentTemplateConfigObj?: any): void {
    this.editComponentClass = aEditComponentClass;
    this.editObjectPropertyOrMethodName = aEditObjectPropertyOrMethodName;
    this.editComponentTemplateConfigObj = aEditComponentTemplateConfigObj;
  }

  // ---------------------------------------------------------------------
  public getBuilder(): BuilderTable2ColumnConfig {
    if (this._builder == null) {
      this._builder = new BuilderTable2ColumnConfig(this);
    }
    return this._builder;
  }

  /*Call this cusom click on td-cell in table on click*/
  public addClickEvent(aMethod: any) {
    this.clickEvent = aMethod;
    return this;
  }

  /*Call this matheod when td-cell is initialized in view*/
  public setCustomMethod(aMethod: any) {
    this.customMethod = aMethod;
    return this;
  }

  /*Set using bridge state management
  * set service and service property which will be updated*/
  public useServiceBridge(aService: any, aServiceProperty: any) {
    this.extraService = aService;
    this.extraServiceProperty = aServiceProperty;
    this.isUsingServiceBridge = true;
    return this;
  }

  public useOnInit(aService: any, aServiceProperty: any) {
    this.extraService = aService;
    this.extraServiceProperty = aServiceProperty;
    this.isUsingOnInit = true;
    return this;
  }

  /*Set value in state bridge*/
  public configureServiceBridge(aItemObject: any): any {

    /*Stop if method usingServiceBridge is not called*/
    if (!this.extraService || !this.extraServiceProperty) {
      return null;
    }

    /*Check if property in service is method*/
    if (this.extraService[this.extraServiceProperty] && this.extraService[this.extraServiceProperty].hasOwnProperty('prototype')) {
      this.extraService[this.extraServiceProperty](aItemObject);
    } else {
      this.extraService[this.extraServiceProperty] = aItemObject;
    }

  }

  /*Update class*/
  public update(aObject: this) {
    // @ts-ignore
    Object.keys(aObject).forEach(key => this[key] = aObject[key]);
    return this;
  }

  // ---------------------------------------------------------------------
  public hasSort(): boolean {
    return this.sortPropertyNames.length > 0;
  }
}

// ---------------------------------------------------------------------
class BuilderTable2ColumnConfig {
  columnConfig: Table3ColumnConfig;

  constructor(aColumnConfig: Table3ColumnConfig) {
    this.columnConfig = aColumnConfig;
  }

  // ---------------------------------------------------------------------
  public setEditComponent(aEditComponentClass: any, aEditObjectPropertyOrMethodName: string, aEditComponentTemplateConfigObj?: any): BuilderTable2ColumnConfig {
    this.columnConfig.addEditComponent(aEditComponentClass, aEditObjectPropertyOrMethodName, aEditComponentTemplateConfigObj);
    return this;
  }

  // ---------------------------------------------------------------------
  public setEditObjectPropertyBoundFlg(aValue: boolean) {
    this.columnConfig.editObjectPropertyBoundFlg = aValue;
    return this;
  }

  // ---------------------------------------------------------------------
  public setOnEditComponentGetConfigObj(aCallback: (aCurrentConfigObj: any, aCurrentObjectItem: BaseObject) => any) {
    this.columnConfig.onEditComponentGetConfigObj = aCallback;
  }

  // ---------------------------------------------------------------------
  public setExtraCssClass(aValue: string) {
    this.columnConfig.extraCssClass = aValue;
    return this;
  }


}
