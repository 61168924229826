import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  BaseObject,
  BaseObjectList,
  McHtmlButton,
  Pagination,
  Table3ColumnConfig,
  Table3Config,
} from "@miticon-ui/mc-core";
import { MccFiTable3CellEditConfigData } from "./mcc-fi-table-3-cell-edit-config-data.model";
import { MccFiSelectOptionConfig } from "../mcc-fi-select-option/mcc-fi-select-option-config";
import { MccFiSelectOptionComponent } from "../mcc-fi-select-option/mcc-fi-select-option.component";
import { MccFiTable3CellEditedData } from "./mcc-fi-table-3-cell-edited-data.model";

@Component({
  selector: "mcc-fi-table-3",
  templateUrl: "./mcc-fi-table-3.component.html",
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MccFiTable3Component {
  lastSelectedItemIndex: any;
  private _cacheFilteredItems: any;
  private _cacheGetCellValueFromObject = "BBM-getCellValueFromObject";
  private _cacheIsPagerVisible!: boolean;
  selectedObjectList!: BaseObjectList;

  @ViewChild("container", { read: ViewContainerRef }) container: any;
  /*Events*/
  @Input() objectList: BaseObjectList;
  @Input() table3Config!: Table3Config;
  @Input() noRecordsFoundMsgHtml = "No data to display";
  @Input() hidePagerFlg = false;
  @Input() hideRefreshButton = true;
  @Input() additionalTableInfo!: string;
  @Output() eventTableCellEdited = new EventEmitter();
  @Output() eventTableCellEditConfig =
    new EventEmitter<MccFiTable3CellEditConfigData>();
  @Output() eventSelectionChanged = new EventEmitter<any>();
  @Output() eventItemAction = new EventEmitter<McHtmlButton>();
  @Output() eventPaginationOrSortChanged = new EventEmitter();
  @Output() eventRefresh = new EventEmitter();

  // ---------------------------------------------------------------------
  // ---------------------------------------------------------------------
  getCellValueFromObject(
    aPropertyOrMethodName: string,
    aItemObject: BaseObject
  ) {
    return aItemObject.execPropertyOrMethodByName(aPropertyOrMethodName);
  }

  getDynamicComponentConfigObject(
    currentColumnConfig: Table3ColumnConfig,
    currentItemObj: BaseObject | any
  ) {
    // console.log("-- getDynamicComponentConfigObject -----------------------------------");
    let config = null;

    if (currentColumnConfig.editComponentClass === MccFiSelectOptionComponent) {
      config = new MccFiSelectOptionConfig();
      // create unique config object for each instance using Template
      if (currentColumnConfig.editComponentTemplateConfigObj != null) {
        config = Object.assign(
          config,
          currentColumnConfig.editComponentTemplateConfigObj
        );
      }

      // set any configuration parameters
      config.name = currentColumnConfig.name + "-" + currentItemObj.id;
      // @ts-ignore
      config.selectedValue =
        currentItemObj[currentColumnConfig.editObjectPropertyOrMethodName];

      // console.log("-------");

      //  console.log(config.name);
      // console.log("selected_val_11: ", config.selectedValue);

      // set OUTPUT callbacks
      config.eventSelectionChangeCallback = (newValue: string) => {
        // automatically update object if bound
        if (currentColumnConfig.editObjectPropertyBoundFlg) {
          // @ts-ignore
          currentItemObj[currentColumnConfig.editObjectPropertyOrMethodName] =
            newValue;
        }

        const dataTableCellEdited = new MccFiTable3CellEditedData(
          currentItemObj,
          currentColumnConfig.editObjectPropertyOrMethodName,
          newValue
        );
        // fire event on the table for each column change
        this.eventTableCellEdited.emit(dataTableCellEdited);
      };

      if (currentColumnConfig.onEditComponentGetConfigObj != null) {
        config = currentColumnConfig.onEditComponentGetConfigObj(
          config,
          currentItemObj
        );
      }

      // OUTPUT Imploementation CAN change the values of these objects, but should only change CONFIG object
      const eventObj = new MccFiTable3CellEditConfigData(
        config,
        currentItemObj,
        currentColumnConfig
      );
      this.eventTableCellEditConfig.emit(eventObj);
    }

    /*Call custom method if it exists*/
    if (currentColumnConfig.customMethod) {
      currentColumnConfig.customMethod(
        currentItemObj,
        currentColumnConfig.editComponentTemplateConfigObj,
        this.objectList,
        this.table3Config
      );
    }

    // if (currentColumnConfig && currentColumnConfig.hasOwnProperty('editComponentTemplateConfigObj') && currentColumnConfig.editComponentTemplateConfigObj.hasOwnProperty('objectItem'))
    //   currentColumnConfig.editComponentTemplateConfigObj.objectItem = currentItemObj;

    return config || currentColumnConfig.editComponentTemplateConfigObj; // {optionsKeyValueMap: optionsKvm, selectedValue: , label: ""};
  }

  /*Filtered items to display*/
  public filteredItems() {
    if (!this._cacheFilteredItems) {
      this._cacheFilteredItems = this.table3Config.columnObjectList.filter(
        (item) => !item.isHide
      );
    }
    return this._cacheFilteredItems;
  }

  /*On select items*/
  onSelectItem(event: any, itemIndex: any) {
    /*If user hold shift*/
    // @ts-ignore
    if (event && event.shiftKey) {
      this.objectList.items.map((item, index) => {
        /*If user is going down on selecting*/
        // @ts-ignore
        if (
          itemIndex > this.lastSelectedItemIndex &&
          index > this.lastSelectedItemIndex &&
          index < itemIndex
        ) {
          item.select();
        }

        /*If user going upper*/
        // @ts-ignore
        if (
          itemIndex < this.lastSelectedItemIndex &&
          index < this.lastSelectedItemIndex &&
          index > itemIndex
        ) {
          item.select();
        }
      });
    }

    const selectedItems = this.objectList.items.filter(
      (item) => item.selectedFlg
    );
    this.lastSelectedItemIndex =
      itemIndex != null && this.objectList.items[itemIndex].selectedFlg
        ? itemIndex
        : this.lastSelectedItemIndex;
    this.eventSelectionChanged.emit(this.objectList.getSelectedObjectList());
  }

  /*On table cell click*/
  public onCellClick(
    currentColumnConfig: Table3ColumnConfig,
    currentItemObj: any
  ) {
    /*Use custom click*/
    if (currentColumnConfig.clickEvent) {
      currentColumnConfig.clickEvent(currentItemObj, currentColumnConfig);
    }

    /*Use service state bridge*/
    if (currentColumnConfig.isUsingServiceBridge) {
      currentColumnConfig.configureServiceBridge(currentItemObj);
    }
  }

  /*On sorting*/
  public actSortColumn(
    columnConfig: Table3ColumnConfig,
    direction: "ASC" | "DESC"
  ) {
    this.objectList.clearSort();
    columnConfig.sortPropertyNames.forEach((sortPropertyName) => {
      this.objectList.addSortB(sortPropertyName, direction);
    });
    this.eventPaginationOrSortChanged.emit();
    // TODO RELOAD LIST REPLAY WOULD BE GREAT
  }

  public onCellOnInit(
    currentColumnConfig: Table3ColumnConfig,
    currentItemObj: any
  ) {
    if (currentColumnConfig.isUsingOnInit) {
      currentColumnConfig.configureServiceBridge(currentItemObj);
    }
  }

  onMccHtmlButtonClicked(mcHtmlButton: McHtmlButton) {
    this.eventItemAction.emit(mcHtmlButton);
  }

  onPaginationChange(pagination: Pagination) {
    this.objectList.setPager(pagination.pageNumber, pagination.pageSize);
    this.eventPaginationOrSortChanged.emit();
    // TODO RELOAD LIST REPLAY WOULD BE GREAT
  }

  // ---------------------------------------------------------------------
  isPagerVisible() {
    if (!this._cacheIsPagerVisible) {
      let result = false;
      if (this.objectList && !this.hidePagerFlg) {
        if (this.objectList.pagination) {
          if (this.objectList.pagination.totalPages > 0) {
            result = true;
          }
        }
      }
      this._cacheIsPagerVisible = result;
    }
    return this._cacheIsPagerVisible;
  }

  onRefreshButtonClicked() {
    this.eventRefresh.emit();
  }

  onColumnConfigEventSaved() {
    this._cacheFilteredItems = null;
  }
}
