<div class="content-wrapper">
  <lib-mc-loader [showLoader]="ebTransaction.apiLoadingFlg"></lib-mc-loader>
  <div class="content-header-slim">
    <div class="header-icon"><img src="/assets/images/recordpayments-title.svg"></div>
    <h3>{{'cc.common.view.add-transaction' | translate}} for {{csrConsumer.individual() ? csrConsumer.fldFullName() : csrConsumer.companyName}}</h3>
  </div>
  <mcc-message *ngIf="!ebFactoring.exists() && ebFactoring.apiSuccessFlg"
               errorMsg="Cant add transaction, because there is no active contract"></mcc-message>
  <div class="content-body mb-5" *ngIf="ebFactoring.exists()">
    <div class="info-summary">
      <div class="info-summary-header">
        <h4>{{'cc.common.view.add-transaction' | translate}}</h4>
      </div>
      <div class="inner-content">
        <mcc-row-title-description title="{{'cc.my-factoring.consumer' | translate}}:" [widerTitleFlg]="true"
                                   [description]="csrConsumer.fldFullName()"></mcc-row-title-description>
        <mcc-row-title-description title="{{'cc.common.view.entity' | translate}}:" [widerTitleFlg]="true"
                                   [description]="csrConsumer.entityName"></mcc-row-title-description>
        <mcc-row-title-description title="{{'cc.consumers.consumer-internal-id' | translate}}:" [widerTitleFlg]="true"
                                   [description]="csrConsumer.id"></mcc-row-title-description>
        <mcc-row-title-description title="{{'cc.common.contract-number' | translate}}:" [widerTitleFlg]="true"
                                   [description]="csrConsumer.contractNumber"></mcc-row-title-description>
      </div>
    </div>

    <mcc-message *ngIf="ebFactoring.exists() && ebTransaction.apiErrorFlg" [errorMsg]="ebTransaction.apiErrorMessage"></mcc-message>

    <div class="payment-details">
      <h5>{{'cc.transactions.new-transaction-details' | translate}}</h5>


      <div class="d-flex">
        <mcc-fi-number-type [mcForm]="mcForm" nameForValue="ctrlTransactionAmount"
                            label="{{'cc.transactions.transaction-amount' | translate}}"
                            nameForType="ctrlAmountType"
                            [chosenCurrency]="currency"
                            [valueType]="currency"
                            [validRequiredFlg]="true" [disablePercentage]="true"
                            [validWhitespaces]="true"></mcc-fi-number-type>
        <div class="tooltip-wide long-error-msg ml-3">
        <i class="fas fa-info-circle mc-tooltip-top-margin"
           ngbTooltip="This amount refers only to the original amount." placement="top-left"></i>
        </div>
      </div>

      <mcc-fi-textarea name="ctrlDescription" label="{{'cc.common.view.description' | translate}}"
                        [mcForm]="mcForm" [numCols]="45" [validWhitespaces]="true">
      </mcc-fi-textarea>

      <mcc-fi-date-picker label="{{'cc.transactions.payment-due-date' | translate}}"
                          name="ctrlPaymentDueDate"
                          [validRequiredFlg]="true"
                          [minDateInput]="minDate"
                          [maxDateInput]="maxDate"
                          [mcForm]="mcForm"></mcc-fi-date-picker>

      <div class="gen-info"><p><i class="fas fa-info-circle"></i> If the transaction's payment due date is in the
        future, the requested collection date will be set at the same date.<br>If the transaction's payment due date is
        in the past, the requested collection date will be set for today's date. </p>
      </div>

      <div class="col-5 position-relative">
        <mcc-fi-radio-buttons2 [name]="'ctrlCollectTransaction'" label="Further actions"
                               [directionHorizontal]="false"
                               [mcForm]="mcForm"
                               [value]="ebTransaction.collectionTypeCd"
                               [validRequiredFlg]="true"
                               [optionsVll]="collectTransactionVll"></mcc-fi-radio-buttons2>
        <div class="info-icon-positioning2 index100">
          <i class="fas fa-info-circle mc-tooltip-top-margin"
             ngbTooltip="The transaction will be sent to bank for the withdrawal." placement="top-right"></i>
        </div>
        <div class="info-icon-positioning">
          <i class="fas fa-info-circle mc-tooltip-top-margin"

             ngbTooltip="The transaction will be prepared for further dunning processing." placement="top-right"></i>
        </div>
      </div>
    </div>

  </div>

  <div class="row" *ngIf="ebFactoring.exists()">
    <div class="col-md-12 position-relative">
      <div class="above-footer">
        <mcc-message *ngIf="!ebFactoring.exists() && ebFactoring.apiSuccessFlg"
                     errorMsg="Cant add transaction, because there is no active contract"></mcc-message>
        <mcc-message *ngIf="ebFactoring.exists() && ebTransaction.apiErrorFlg"
                     errorMsg="{{ebTransaction.apiErrorMessage}}"></mcc-message>
      </div>
    </div>
  </div>

  <div class="content-footer">
    <button class="btn btn-secondary move-right" (click)="actCancel();"><i
      class="far fa-times-circle"></i> {{'cc.common.edit.cancel' | translate}}
    </button>
    <button class="btn btn-primary" *ngIf="ebFactoring.exists()" (click)="actSave();" label="Save"
            [disabled]="saveButtonDisabledMcb.value"><i
      class="fas fa-save"></i> {{'cc.common.table.add' | translate}}
    </button>
  </div>
</div>
