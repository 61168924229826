import {McBillingBatchAssignedToStatementFilterGENERATED} from '../_generated/mc-billing-batch-assigned-to-statement-filter-generated.model';

export class McBillingBatchAssignedToStatementFilter extends McBillingBatchAssignedToStatementFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingBatchAssignedToStatementFilter();
  }

  // ---------------------------------------------------------------------
}
