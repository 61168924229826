import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  McDateUtils, McEntity2List,
  McForm,
  McGod,
  McPriceList, McValueLabel,
  McValueLabelList
} from "@miticon-ui/mc-core";

@Component({
  selector: 'mc-pricelist-pricelist-edit-from-profile-part',
  templateUrl: './mc-pricelist-pricelist-edit-from-profile-part.component.html',
})
export class McPricelistPricelistEditFromProfilePartComponent implements OnInit, OnDestroy {

  /*Variables*/
  @Input() mcPriceList!: McPriceList;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();

  mcGod = McGod.getInstance();
  mcForm = new McForm();
  today = McDateUtils.getNowTimestamp();
  datepickerTommorowValue!: number;
  mcChildEntityVll!: McValueLabelList;
  entitiesValue!: McValueLabelList;
  mcChildEntityList!: McEntity2List;
  entitiesValuesLoadedFlg = false;



  ngOnInit() {
    this.datepickerTommorowValue = McDateUtils.addOrSubtractDateToNumber(McDateUtils.getNowTimestamp(), 'add', 1, 'days');
    this.entitiesValue = new McValueLabelList();
    this.mcChildEntityVll = new McValueLabelList();
    this.mcChildEntityList = new McEntity2List();
    this.mcChildEntityList.loadAllWhereParentEntityIsLoggedInEntity(() => {
      this.mcChildEntityList.items.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
      this.mcChildEntityVll = this.mcChildEntityList.toVll('id', 'name');
    });
    this.mcPriceList.entitiesUsingPricelist.items
      .map(entity => entity.entityId)
      .forEach(item => {
      const entityIdItem = new McValueLabel();
      entityIdItem.value = item;
      entityIdItem.label = item.toString();
      this.entitiesValue.addItem(entityIdItem);
    });

    this.entitiesValuesLoadedFlg = true;
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actSave() {
    if (this.mcForm.isValid()) {
      this.mcPriceList.title = this.mcForm.getValue('ctrlPricelistName');
      this.mcPriceList.description = this.mcForm.getValue('ctrlPricelistDescription');
      //this.mcPriceList.entityIdsUsingPricelist = this.mcForm.getValue('ctrlEntitiesPricelist');
      this.mcPriceList.validFrom = this.mcForm.getValue('ctrlValidFrom');
      console.log('before saving', this.mcPriceList);
      this.mcPriceList.save(() => {
        console.log('after', this.mcPriceList);
        this.eventSaved.emit();
      });
    }
  }

  ngOnDestroy() {
    if (this.mcPriceList.apiErrorMessage) {
      this.mcPriceList.apiErrorMessage = '';
    }
  }
}
