import {McBillingBatch2Transaction} from './mc-billing-batch-2-transaction.model';
import {McBillingBatch2TransactionListGENERATED} from '../_generated/mc-billing-batch-2-transaction-list-generated.model';

export class McBillingBatch2TransactionList extends McBillingBatch2TransactionListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingBatch2TransactionList();
  }

  // ---------------------------------------------------------------------
}
