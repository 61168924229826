/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBillingBatchAssignedToStatement} from '../models/mc-billing-batch-assigned-to-statement.model';
import {McBillingBatchAssignedToStatementService} from '../services/mc-billing-batch-assigned-to-statement.service';
import {IMcBillingBatchAssignedToStatement} from '../_generated/mc-billing-batch-assigned-to-statement.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McBillingBatchAssignedToStatementGENERATED extends BaseObject {


  public apiService: McBillingBatchAssignedToStatementService;
  public _rawJson: IMcBillingBatchAssignedToStatement;
    id: number = 0;
  acceptedForSFColumnFinalSum: number;
  acceptedForSFColumnInitialSum: number;
  acceptedForSFColumnPercentage: number;
  alreadyPaidBalanceColumnFinalSum: number;
  alreadyPaidBalanceColumnInitialSum: number;
  alreadyPaidBalanceColumnPercentage: number;
  alreadyPaidFromConsumerBalanceColumnFinalSum: number;
  alreadyPaidFromConsumerBalanceColumnInitialSum: number;
  alreadyPaidFromConsumerBalanceColumnPercentage: number;
  alreadyReturnedBalanceColumnFinalSum: number;
  alreadyReturnedBalanceColumnInitialSum: number;
  alreadyReturnedBalanceColumnPercentage: number;
  batchHeader = '';
  idMcBillingBatch: number;
  idMcBillingStatement: number;
  immediatelyPayableBalanceColumnFinalSum: number;
  immediatelyPayableBalanceColumnInitialSum: number;
  immediatelyPayableBalanceColumnPercentage: number;
  immediatelyPayableConsumerBalanceColumnFinalSum: number;
  immediatelyPayableConsumerBalanceColumnInitialSum: number;
  immediatelyPayableConsumerBalanceColumnPercentage: number;
  laterPayableBalanceColumnFinalSum: number;
  laterPayableBalanceColumnInitialSum: number;
  laterPayableBalanceColumnPercentage: number;
  notAcceptedForSFColumnFinalSum: number;
  notAcceptedForSFColumnInitialSum: number;
  notAcceptedForSFColumnPercentage: number;
  returningAmountFinalBounceColumnFinalSum: number;
  returningAmountFinalBounceColumnInitialSum: number;
  returningAmountFinalBounceColumnPercentage: number;
  sysCreatedDatetime: number;


  public properties: string[] = ['id', 'acceptedForSFColumnFinalSum', 'acceptedForSFColumnInitialSum', 'acceptedForSFColumnPercentage', 'alreadyPaidBalanceColumnFinalSum', 'alreadyPaidBalanceColumnInitialSum', 'alreadyPaidBalanceColumnPercentage', 'alreadyPaidFromConsumerBalanceColumnFinalSum', 'alreadyPaidFromConsumerBalanceColumnInitialSum', 'alreadyPaidFromConsumerBalanceColumnPercentage', 'alreadyReturnedBalanceColumnFinalSum', 'alreadyReturnedBalanceColumnInitialSum', 'alreadyReturnedBalanceColumnPercentage', 'batchHeader', 'idMcBillingBatch', 'idMcBillingStatement', 'immediatelyPayableBalanceColumnFinalSum', 'immediatelyPayableBalanceColumnInitialSum', 'immediatelyPayableBalanceColumnPercentage', 'immediatelyPayableConsumerBalanceColumnFinalSum', 'immediatelyPayableConsumerBalanceColumnInitialSum', 'immediatelyPayableConsumerBalanceColumnPercentage', 'laterPayableBalanceColumnFinalSum', 'laterPayableBalanceColumnInitialSum', 'laterPayableBalanceColumnPercentage', 'notAcceptedForSFColumnFinalSum', 'notAcceptedForSFColumnInitialSum', 'notAcceptedForSFColumnPercentage', 'returningAmountFinalBounceColumnFinalSum', 'returningAmountFinalBounceColumnInitialSum', 'returningAmountFinalBounceColumnPercentage', 'sysCreatedDatetime'];
  public propertiesRegular: string[] = ['id', 'acceptedForSFColumnFinalSum', 'acceptedForSFColumnInitialSum', 'acceptedForSFColumnPercentage', 'alreadyPaidBalanceColumnFinalSum', 'alreadyPaidBalanceColumnInitialSum', 'alreadyPaidBalanceColumnPercentage', 'alreadyPaidFromConsumerBalanceColumnFinalSum', 'alreadyPaidFromConsumerBalanceColumnInitialSum', 'alreadyPaidFromConsumerBalanceColumnPercentage', 'alreadyReturnedBalanceColumnFinalSum', 'alreadyReturnedBalanceColumnInitialSum', 'alreadyReturnedBalanceColumnPercentage', 'batchHeader', 'idMcBillingBatch', 'idMcBillingStatement', 'immediatelyPayableBalanceColumnFinalSum', 'immediatelyPayableBalanceColumnInitialSum', 'immediatelyPayableBalanceColumnPercentage', 'immediatelyPayableConsumerBalanceColumnFinalSum', 'immediatelyPayableConsumerBalanceColumnInitialSum', 'immediatelyPayableConsumerBalanceColumnPercentage', 'laterPayableBalanceColumnFinalSum', 'laterPayableBalanceColumnInitialSum', 'laterPayableBalanceColumnPercentage', 'mcBillingBatch', 'mcBillingStatement', 'notAcceptedForSFColumnFinalSum', 'notAcceptedForSFColumnInitialSum', 'notAcceptedForSFColumnPercentage', 'returningAmountFinalBounceColumnFinalSum', 'returningAmountFinalBounceColumnInitialSum', 'returningAmountFinalBounceColumnPercentage', 'sysCreatedDatetime'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): McBillingBatchAssignedToStatement {
    console.error('MTCN-ERROR: Not Implemented: McBillingBatchAssignedToStatement::createNewInstance(). Add this method to final class and return new McBillingBatchAssignedToStatement();');
    throw new Error('Cannot McBillingBatchAssignedToStatement::createNewInstance(). Add this method to final class and return new McBillingBatchAssignedToStatement();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBillingBatchAssignedToStatement): McBillingBatchAssignedToStatement {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingBatchAssignedToStatementService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBillingBatchAssignedToStatement AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getAcceptedForSFColumnFinalSumStr(): string {
       return McGod.formatNumberToMoney(this.acceptedForSFColumnFinalSum);
    }

    public getAcceptedForSFColumnInitialSumStr(): string {
       return McGod.formatNumberToMoney(this.acceptedForSFColumnInitialSum);
    }

    public getAcceptedForSFColumnPercentageStr(): string {
       return McGod.formatNumberToMoney(this.acceptedForSFColumnPercentage);
    }

    public getAlreadyPaidBalanceColumnFinalSumStr(): string {
       return McGod.formatNumberToMoney(this.alreadyPaidBalanceColumnFinalSum);
    }

    public getAlreadyPaidBalanceColumnInitialSumStr(): string {
       return McGod.formatNumberToMoney(this.alreadyPaidBalanceColumnInitialSum);
    }

    public getAlreadyPaidBalanceColumnPercentageStr(): string {
       return McGod.formatNumberToMoney(this.alreadyPaidBalanceColumnPercentage);
    }

    public getAlreadyPaidFromConsumerBalanceColumnFinalSumStr(): string {
       return McGod.formatNumberToMoney(this.alreadyPaidFromConsumerBalanceColumnFinalSum);
    }

    public getAlreadyPaidFromConsumerBalanceColumnInitialSumStr(): string {
       return McGod.formatNumberToMoney(this.alreadyPaidFromConsumerBalanceColumnInitialSum);
    }

    public getAlreadyPaidFromConsumerBalanceColumnPercentageStr(): string {
       return McGod.formatNumberToMoney(this.alreadyPaidFromConsumerBalanceColumnPercentage);
    }

    public getAlreadyReturnedBalanceColumnFinalSumStr(): string {
       return McGod.formatNumberToMoney(this.alreadyReturnedBalanceColumnFinalSum);
    }

    public getAlreadyReturnedBalanceColumnInitialSumStr(): string {
       return McGod.formatNumberToMoney(this.alreadyReturnedBalanceColumnInitialSum);
    }

    public getAlreadyReturnedBalanceColumnPercentageStr(): string {
       return McGod.formatNumberToMoney(this.alreadyReturnedBalanceColumnPercentage);
    }

    public getImmediatelyPayableBalanceColumnFinalSumStr(): string {
       return McGod.formatNumberToMoney(this.immediatelyPayableBalanceColumnFinalSum);
    }

    public getImmediatelyPayableBalanceColumnInitialSumStr(): string {
       return McGod.formatNumberToMoney(this.immediatelyPayableBalanceColumnInitialSum);
    }

    public getImmediatelyPayableBalanceColumnPercentageStr(): string {
       return McGod.formatNumberToMoney(this.immediatelyPayableBalanceColumnPercentage);
    }

    public getImmediatelyPayableConsumerBalanceColumnFinalSumStr(): string {
       return McGod.formatNumberToMoney(this.immediatelyPayableConsumerBalanceColumnFinalSum);
    }

    public getImmediatelyPayableConsumerBalanceColumnInitialSumStr(): string {
       return McGod.formatNumberToMoney(this.immediatelyPayableConsumerBalanceColumnInitialSum);
    }

    public getImmediatelyPayableConsumerBalanceColumnPercentageStr(): string {
       return McGod.formatNumberToMoney(this.immediatelyPayableConsumerBalanceColumnPercentage);
    }

    public getLaterPayableBalanceColumnFinalSumStr(): string {
       return McGod.formatNumberToMoney(this.laterPayableBalanceColumnFinalSum);
    }

    public getLaterPayableBalanceColumnInitialSumStr(): string {
       return McGod.formatNumberToMoney(this.laterPayableBalanceColumnInitialSum);
    }

    public getLaterPayableBalanceColumnPercentageStr(): string {
       return McGod.formatNumberToMoney(this.laterPayableBalanceColumnPercentage);
    }

    public getNotAcceptedForSFColumnFinalSumStr(): string {
       return McGod.formatNumberToMoney(this.notAcceptedForSFColumnFinalSum);
    }

    public getNotAcceptedForSFColumnInitialSumStr(): string {
       return McGod.formatNumberToMoney(this.notAcceptedForSFColumnInitialSum);
    }

    public getNotAcceptedForSFColumnPercentageStr(): string {
       return McGod.formatNumberToMoney(this.notAcceptedForSFColumnPercentage);
    }

    public getReturningAmountFinalBounceColumnFinalSumStr(): string {
       return McGod.formatNumberToMoney(this.returningAmountFinalBounceColumnFinalSum);
    }

    public getReturningAmountFinalBounceColumnInitialSumStr(): string {
       return McGod.formatNumberToMoney(this.returningAmountFinalBounceColumnInitialSum);
    }

    public getReturningAmountFinalBounceColumnPercentageStr(): string {
       return McGod.formatNumberToMoney(this.returningAmountFinalBounceColumnPercentage);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
