<section>
  <div class="col-md-12 mc-stepper-header-title" *ngIf="modalArea === 'addConsumers'">
    <span class="px-4 py-2 mc-semi-active">
      <span class="mc-notification-badge-gray mc-semi-active-background">1</span>
      {{ 'cc.common.view.basic-info' | translate }}
    </span>
    <span class="px-4 py-2 mc-active">
      <span class="mc-notification-badge-gray mc-active-background">2</span>
      {{'cc.consumers.view.extended-info' | translate}}
    </span>
  </div>

  <div class="row mt-5" *ngIf="!editing">

    <div class="col-md-6" *ngFor="let customAttribute of customAttributesFromDb; let index = index;">

      <!--Varchar-->
      <div class="form-group row" *ngIf="customAttribute.type === 'VARCHAR'">
        <label for="{{customAttribute.id}}" class="col-4 col-form-label text-capitalize mc-consumers-label text-break">{{customAttribute.name.split("_").join(" ")}}</label>
        <div class="col-8">
          <textarea class="form-control"
                    value=""
                    rows="2"
                    (change)="onChange($event, customAttribute.name)"
                    (keyup)="onChange($event, customAttribute.name)"
                    [(ngModel)]="customAttribute.value"
                    id="{{customAttribute.id}}">
          </textarea>
        </div>
      </div>

      <!--Bigint-->
      <div class="form-group row" *ngIf="customAttribute.type === 'INTEGER'">
        <label for="{{customAttribute.id}}" class="col-4 col-form-label text-capitalize mc-consumers-label text-break">{{customAttribute.name.split("_").join(" ")}}</label>
        <div class="col-8">
          <input class="form-control" type="number" value=""
                 (change)="onChange($event, customAttribute.name)"
                 (keyup)="onChange($event, customAttribute.name)"
                 [(ngModel)]="customAttribute.value"
                 id="{{customAttribute.id}}">
        </div>
      </div>

      <!--Decimal-->
      <div class="form-group row" *ngIf="customAttribute.type === 'FLOAT'">
        <label for="{{customAttribute.id}}" class="col-4 col-form-label text-capitalize mc-consumers-label text-break">{{customAttribute.name.split("_").join(" ")}}</label>
        <div class="col-8">
          <input class="form-control" type="number" min="1" value=""
                 (change)="onChange($event, customAttribute.name)"
                 (keyup)="onChange($event, customAttribute.name)"
                 [(ngModel)]="customAttribute.value"
                 id="{{customAttribute.id}}">
        </div>
      </div>

      <!--Timestamp-->
      <div class="form-group row" *ngIf="customAttribute.type === 'DATE' || customAttribute.type === 'DATETIME'">
        <label for="{{customAttribute.id}}" class="col-4 col-form-label text-capitalize mc-consumers-label text-break">{{customAttribute.name.split("_").join(" ")}}</label>
        <div class="col-8">
          <input id="{{customAttribute.id}}"
                 class="form-control mc-dates-inputs"
                 type="date"
                 name="{{customAttribute.id}}"
                 autocomplete="off"
                 [(ngModel)]="customAttribute.value"
                 (ngModelChange)="onChange($event, customAttribute.name)"
                 (keyup)="onChange($event, customAttribute.name)"
                 placeholder="mm-dd-yyyy"
                 readonly
                 ngbDatepicker
                 outsideDays="hidden"
                 #dateField="ngbDatepicker"
                 (click)="dateField.toggle(); onChange($event, customAttribute.name)">
        </div>
      </div>

      <div class="form-group row" *ngIf="customAttribute.type === 'BOOLEAN'">
        <label for="{{customAttribute.id}}" class="col-4 col-form-label text-capitalize mc-consumers-label text-break">{{customAttribute.name.split("_").join(" ")}}</label>
          <div class="col-8">
            <div class="btn-group btn-group-toggle" ngbRadioGroup>
              <label ngbButtonLabel class="btn btn-primary px-3 py-2 text-capitalize mc-cursor-pointer-radio-button" [ngClass]="{'active': customAttribute.value}" (click)="customAttribute.value = true">
                <input ngbButton type="radio" [value]="true" [checked]="customAttribute.value"> {{ "cc.common.edit.yes" | translate}}
              </label>
              <label ngbButtonLabel class="btn btn-primary px-3 py-2 text-capitalize mc-cursor-pointer-radio-button" [ngClass]="{'active': !customAttribute.value}" (click)="customAttribute.value = false">
                <input ngbButton type="radio" [value]="false" [checked]="!customAttribute.value"> {{ "cc.common.edit.no" | translate}}
              </label>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!--TODO: HOW TO KNOW TYPE OF ATTRIBUTE IF WE GOT ONLY NAME AND VALUE PROPERTY FROM BE. THIS IS TEMPORARY SOLUTION-->
  <div class="row mt-5" *ngIf="editing">

    <div class="col-md-6 mc-custom-attributes" *ngFor="let customAttribute of customAttributesFromDb">
      <!--INPUT TEXT FIELD-->
      <div class="form-group row" >
        <label for="example-number-input" class="col-4 col-form-label text-capitalize mc-consumers-label">{{customAttribute.name.split("_").join(" ")}}</label>
        <div class="col-8">
          <input class="form-control"
                 type="text"
                 [(ngModel)]="customAttribute.value"
                 id="example-number-input">
        </div>
      </div>
    </div>


  </div>


  <!--<form class="form-row pt-5 mc-add-consumers-form" [formGroup]="stepForm">-->
  <!--<div class="mc-create-user-notify-wrapper">-->
  <!--<mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>-->
  <!--</div>-->
  <!--<div class="row w-100 pt-4 pb-4">-->
  <!--<div class="col-md-6">-->
  <!--<div class="form-group row align-items-center">-->
  <!--<label for="contactPerson" class="col-md-5 mb-0 mc-consumers-label">Legal Representative</label>-->
  <!--<div class="col-md-7">-->
  <!--<input type="text"-->
  <!--id="contactPerson"-->
  <!--class="form-control"-->
  <!--formControlName="contactPerson">-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="form-group row align-items-center">-->
  <!--<label for="contactPersonMail" class="col-md-5 mb-0 mc-consumers-label">Legal Representative Email</label>-->
  <!--<div class="col-md-7">-->
  <!--<input type="text"-->
  <!--id="contactPersonMail"-->
  <!--class="form-control"-->
  <!--formControlName="contactPersonMail"-->
  <!--[ngClass]="invalidContactMail ? 'mc-error-field' : '' "-->
  <!--(keyup)="isMailValid()">-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="form-group row align-items-center">-->
  <!--<label for="consumerAmountDue" class="col-md-5 mb-0 mc-consumers-label">{{ 'cc.common.view.amount-due' | translate }}</label>-->
  <!--<div class="col-md-7">-->
  <!--<input type="text"-->
  <!--id="consumerAmountDue"-->
  <!--class="form-control"-->
  <!--formControlName="consumerAmountDue"-->
  <!--[ngClass]="amountDueInvalid ? 'mc-error-field' : '' "-->
  <!--(keyup)="isNumberFormat($event)">-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="form-group row align-items-center">-->
  <!--<label for="consumerAmountDueDate" class="col-md-5 mb-0 mc-consumers-label">{{'cc.consumers.edit.amount-due-date' | translate}}</label>-->
  <!--<div class="col-md-7">-->
  <!--<input type="date"-->
  <!--id="consumerAmountDueDate"-->
  <!--class="form-control mc-dates-inputs"-->
  <!--formControlName="consumerAmountDueDate"-->
  <!--autocomplete="off"-->
  <!--placeholder="mm-dd-yyyy"-->
  <!--readonly-->
  <!--outsideDays="hidden"-->
  <!--[minDate] = "minDate"-->
  <!--[maxDate] = "maxDate"-->
  <!--ngbDatepicker-->
  <!--#AmountDueDate="ngbDatepicker"-->
  <!--(click)="AmountDueDate.toggle()">-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="form-group row align-items-center">-->
  <!--<label for="consumerCCDate" class="col-md-5 mb-0 mc-consumers-label">{{'cc.consumers.edit.contract-cancellation-date' | translate}}</label>-->
  <!--<div class="col-md-7">-->
  <!--<input type="date"-->
  <!--id="consumerCCDate"-->
  <!--class="form-control mc-dates-inputs"-->
  <!--formControlName="consumerCCDate"-->
  <!--autocomplete="off"-->
  <!--placeholder="mm-dd-yyyy"-->
  <!--readonly-->
  <!--outsideDays="hidden"-->
  <!--[minDate] = "minDate"-->
  <!--[maxDate] = "maxDate"-->
  <!--ngbDatepicker-->
  <!--#CCancelDate="ngbDatepicker"-->
  <!--(click)="CCancelDate.toggle()">-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="form-group row align-items-center">-->
  <!--<label for="consumerCEDate" class="col-md-5 mb-0 mc-consumers-label">{{'cc.consumers.edit.contract-end-date' | translate}}</label>-->
  <!--<div class="col-md-7">-->
  <!--<input type="date"-->
  <!--id="consumerCEDate"-->
  <!--class="form-control mc-dates-inputs"-->
  <!--formControlName="consumerCEDate"-->
  <!--autocomplete="off"-->
  <!--placeholder="mm-dd-yyyy"-->
  <!--readonly-->
  <!--outsideDays="hidden"-->
  <!--[minDate] = "minDate"-->
  <!--[maxDate] = "maxDate"-->
  <!--ngbDatepicker-->
  <!--#CEndDate="ngbDatepicker"-->
  <!--(click)="CEndDate.toggle()">-->
  <!--</div>-->
  <!--</div>-->

  <!--</div>-->
  <!--<div class="col-md-6">-->
  <!--<div class="form-group py-2 row align-items-center">-->
  <!--<label class="col-md-4 mb-0 mc-consumers-label">{{'cc.consumers.edit.studio-banned' | translate}}</label>-->
  <!--<div class="col-md-8">-->
  <!--<label>-->
  <!--<input-->
  <!--class="form-check-input"-->
  <!--type="radio"-->
  <!--id="consumerSBYes"-->
  <!--value="yes"-->
  <!--formControlName="studioBanned">-->
  <!--<label class="form-check-label" for="consumerSBYes">{{ 'cc.common.edit.yes' | translate }}</label>-->
  <!--</label>-->

  <!--<label class="ml-4">-->
  <!--<input-->
  <!--class="form-check-input"-->
  <!--type="radio"-->
  <!--id="consumerSBNo"-->
  <!--value="no"-->
  <!--formControlName="studioBanned">-->
  <!--<label class="form-check-label" for="consumerSBNo">{{ 'cc.common.edit.no' | translate }}</label>-->
  <!--</label>-->

  <!--</div>-->
  <!--</div>-->
  <!--<div class="form-group row">-->
  <!--<label class="col-md-4 mb-0 mc-consumers-label">{{'cc.consumers.edit.payment-pause' | translate}}</label>-->
  <!--<div class="col-md-8">-->
  <!--<div class="row pb-3 align-items-center">-->
  <!--<label for="consumerPaymentPauseFrom" class="col-md-2 mb-0 mc-consumers-label">{{ 'cc.common.date.from' | translate }}</label>-->
  <!--<div class="col-md-10">-->
  <!--<input type="date"-->
  <!--id="consumerPaymentPauseFrom"-->
  <!--class="form-control mc-dates-inputs"-->
  <!--formControlName="consumerPaymentPauseFrom"-->
  <!--placeholder="mm-dd-yyyy"-->
  <!--autocomplete="off"-->
  <!--readonly-->
  <!--outsideDays="hidden"-->
  <!--[minDate] = "minDate"-->
  <!--[maxDate] = "maxDate"-->
  <!--ngbDatepicker-->
  <!--#FromDateField="ngbDatepicker"-->
  <!--(click)="FromDateField.toggle()">-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="row align-items-center">-->
  <!--<label for="consumerPaymentPauseTo" class="col-md-2 mb-0 mc-consumers-label">to</label>-->
  <!--<div class="col-md-10">-->
  <!--<input type="date"-->
  <!--id="consumerPaymentPauseTo"-->
  <!--class="form-control mc-dates-inputs"-->
  <!--formControlName="consumerPaymentPauseTo"-->
  <!--placeholder="mm-dd-yyyy"-->
  <!--autocomplete="off"-->
  <!--readonly-->
  <!--outsideDays="hidden"-->
  <!--[minDate] = "minDate"-->
  <!--[maxDate] = "maxDate"-->
  <!--ngbDatepicker-->
  <!--#ToDateField="ngbDatepicker"-->
  <!--(click)="ToDateField.toggle()">-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->

  <div class="col-md-12 mt-4 mb-3 mc-save-consumers-btn text-right" *ngIf="modalArea !== 'addConsumers'">
    <button id="userGeneralDataBtn" class="btn btn-primary mc-next-save-btn" type="button" [disabled]="saveBtnDisabled" (click)="saveEditedConsumerData()">
    {{'cc.common.view.save' | translate}}
    </button>
  </div>

  <!--</form>-->
</section>
