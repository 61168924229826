import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IMcProcessNotification} from '../_generated/mc-process-notification.interface';
import {McProcessNotification} from '../models/mc-process-notification.model';
import {McProcessNotificationList} from '../models/mc-process-notification-list.model';
import {McProcessNotificationServiceGENERATED} from '../_generated/mc-process-notification-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class McProcessNotificationService extends McProcessNotificationServiceGENERATED {

  public getByProcessId(id: number): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/find-all-by-process-id/` + id, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  override update(aMcProcessNotification: any): Observable<IMcProcessNotification> {
    return this.httpClient.put<IMcProcessNotification>(this.getApiServiceRootUrl() + `/`+ aMcProcessNotification.id, aMcProcessNotification, {headers: this.httpOptions});
  }

  public createMultiple(aMcProcessNotifications: McProcessNotification[]): Observable<McProcessNotification[]> {
    let notifications: any[] = [];
    aMcProcessNotifications.forEach((notification) => {
      notifications.push(notification.toDto());
    })
    return this.httpClient.post<McProcessNotification[]>(this.getApiServiceRootUrl() + '/create-multiple'  , notifications);
  }

  public editMultiple(aMcProcessNotifications: McProcessNotification[], processId: number): Observable<McProcessNotification[]> {
    let notifications: any[] = [];
    aMcProcessNotifications.forEach((notification) => {
      notifications.push(notification.toDto());
    })
    return this.httpClient.post<McProcessNotification[]>(this.getApiServiceRootUrl() + `/edit-multiple/${processId}`  , notifications);
  }
}
