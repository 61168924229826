<app-shared-go-back icon="fas fa-arrow-left" [title]="'cc.consumer.back-to-consumer-contracts' | translate"></app-shared-go-back>

<lib-mc-loader [showLoader]="csrConsumer.apiLoadingFlg"></lib-mc-loader>

<div class="actions-container mb-3">
  <span>{{csrConsumer.getDisplayName()}} - {{'cc.contract.contract' | translate}} {{csrContract.id}}</span>
  <div class="actions-wrapper">
<!--
    <button class="system-action-btn dark-system-action-btn mr-2" [ngClass]="this.csrContract.isStatusCdACTIVE() || this.csrContract.isStatusCdPENDING() ? 'mc-disable-button' : ''"><i class="fas fa-pen"></i> {{'cc.consumer.edit-contract' | translate}}</button>
-->
    <button class="system-action-btn dark-system-action-btn" [ngClass]="this.isSendInvitationMailEnabled() ?
    '' :
    'mc-disable-button'" (click)="sendInvitationEmail()"><i class="fas fa-paper-plane"></i>
      {{'cc.consumer.send-invitation-e-mail' | translate}}
    </button>
  </div>
</div>

<!--Consumer Information Card-->
<div class='card-wrapper card-wrapper-medium'>
  <app-shared-card header="{{'cc.my-factoring.consumer-information' | translate}}" [showHeader]="true">
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.first-name' | translate}}:" [description]="csrConsumer.firstName"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.last-name' | translate}}:" [description]="csrConsumer.lastName"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.studio' | translate}}:" [description]="csrConsumer.entityName"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.contract-id' | translate}}:" [description]="csrContract.id"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.view.consumer-id' | translate}}:" [description]=" csrConsumer.id"></mcc-row-title-description>
  </app-shared-card>

  <!--Payment Card-->
  <app-shared-card header="{{'cc.my-factoring.payment' | translate}}" [showHeader]="true">
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.my-factoring.payment' | translate}}:" [description]="csrContract.recurringDetails.getTypeCdLabel()"></mcc-row-title-description>
    <ng-container *ngIf="csrContract.recurringDetails.isTypeCdQUARTERLY()">
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                 [description]="csrContract.recurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'')"></mcc-row-title-description>
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.month' | translate}}:"
                                 [description]="csrContract.recurringDetails.getQuarterlyVll().getLabel(csrContract.recurringDetails.month,'')"></mcc-row-title-description>
    </ng-container>
    <ng-container *ngIf="csrContract.recurringDetails.isTypeCdMONTHLY()">
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                 [description]="csrContract.recurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'')"></mcc-row-title-description>
    </ng-container>
    <ng-container *ngIf="csrContract.recurringDetails.isTypeCdWEEKLY() ">
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                 [description]="csrContract.recurringDetails.getWeekDaysVll().getLabel(csrContract.recurringDetails.day,'')"></mcc-row-title-description>
    </ng-container>
    <ng-container *ngIf="csrContract.recurringDetails.isTypeCdYEARLY()">
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                 [description]="csrContract.recurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'')"></mcc-row-title-description>
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.month' | translate}}:"
                                 [description]="csrContract.recurringDetails.getYearlyVll().getLabel(csrContract.recurringDetails.month,'')"></mcc-row-title-description>
    </ng-container>
    <ng-container *ngIf="csrContract.recurringDetails.isTypeCdHALF_YEARLY()">
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                 [description]="csrContract.recurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'')"></mcc-row-title-description>
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.month' | translate}}:"
                                 [description]="csrContract.recurringDetails.getHalfYearlyVll().getLabel(csrContract.recurringDetails.month,'')"></mcc-row-title-description>
    </ng-container>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.my-factoring.payment-method' | translate}}:" [description]="csrContract.paymentDetails.getTypeCdLabel()"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.send-invitation-e-mail' | translate}}:" description="{{sendInvitationEmailFlg ? 'Yes' : 'No'}}"></mcc-row-title-description>
  </app-shared-card>

  <!--Products and Services Card-->
  <app-shared-card header="{{'cc.consumer.products-and-services' | translate}}" [showHeader]="true">
    <ng-container *ngFor="let product of csrContract.productSnapshots.items">
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.art-no' | translate}}:" [description]="product && product.productSnapshot.name ? product.productSnapshot.name : ''"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.art-description' | translate}}:" [description]="product && product.productSnapshot.description ? product.productSnapshot.description : ''"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.price-netto' | translate}}:" [description]="product && product.productSnapshot.unitPrice ? format(product.productSnapshot.unitPrice) : ''"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.product.view.vat' | translate}}:" [description]="product && product.productSnapshot.vat ? product.productSnapshot.vat.vatRate.toFixed(2).replace('.', ',') + '%' : ''"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.jobs.view.quantity' | translate}}:" [description]="product && product.quantity ? product.quantity : ''"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.billing.total-amount' | translate}}:" [description]="csrContract && format((product.productSnapshot.unitPrice + product.productSnapshot.unitPrice * product.productSnapshot.vat.vatRate / 100) * product.quantity)"></mcc-row-title-description>
      <br>
    </ng-container>
  </app-shared-card>

  <!--Details Card-->
  <app-shared-card header="{{'cc.billing.details' | translate}}" [showHeader]="true">
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.contract.contract-type' | translate}}:" [description]="csrContract && csrContract.endDate ? 'Limited' : 'Unlimited'"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.contract-start-date' | translate}}:" [description]="csrContract.startDate | date"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.contract-end-date' | translate}}:" [description]="csrContract.endDate | date"></mcc-row-title-description>
    <ng-container *ngIf="csrContract.notificationDetails">
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.send-invoice' | translate}}:" [description]="csrContract && csrContract.notificationDetails ? csrContract.notificationDetails.sendInvoice ? 'Yes' : 'No' : ''"></mcc-row-title-description>
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.email-address' | translate}}:" [description]="csrConsumer.email"></mcc-row-title-description>
    </ng-container>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.free-text' | translate}}" [description]="csrContract.notes"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.contract-renewal' | translate}}:" [description]="csrContract.autoRenewal ? 'Yes' : 'No'"></mcc-row-title-description>
    <ng-container *ngIf="csrContract.autoRenewal">
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.before-ending' | translate}}:" description="{{csrContract.endDate ? daysBeforeExpiration.toFixed(0) : ''}} {{'cc.factoring.day-s' | translate}}"></mcc-row-title-description>
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.extend-for' | translate}}:" description="{{csrContract.autoRenewalLength ? csrContract.autoRenewalLength: ''}} {{'cc.my-factoring.months' | translate}}"></mcc-row-title-description>
    </ng-container>
  </app-shared-card>
</div>
