import { Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import {McUtilityService, McAuthService, McStateManagementService} from '@miticon-ui/mc-core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {McSidebarMenuConfig} from "./mc-sidebar-menu-config";

@Component({
  selector: 'mc-sidebar',
  templateUrl: './mc-sidebar.component.html'
})
export class McSidebarComponent implements OnDestroy {

  logo: string;
  sideBarLinks;
  isExpanded!: boolean;
  selectedLink!: string;
  isSubLinkActive!: boolean;
  activeRoute;
  isActive: boolean;
  subMenuList: any[] = [];
  menu: McSidebarMenuConfig;

  userPermissions;
  entitySwitchedSubscription: Subscription;
  loaded = false;

  @Input() isCollapsed!: boolean;
  @Input() getLanguage!: boolean;
  @Output() onCollapse = new EventEmitter();

  constructor(
    private router: Router,
    private utilityService: McUtilityService,
    private authService: McAuthService,
    private stateManagement: McStateManagementService,
    private translate: TranslateService,
    @Inject('designConfig') designConfig: any,
    @Inject('sideBarMenu') sideBarConfig: any
  ) {
    this.logo = designConfig.logoWhiteText;

    this.sideBarLinks = sideBarConfig;

    this.userPermissions = this.utilityService.getAuthoritiesFromStorage();
    this.activeRoute = this.router.url.split('/').reverse()[0];
    this.isActive = this.subMenuList.includes(this.activeRoute);
    this.entitySwitchedSubscription = this.stateManagement.isSwitchedEntity.subscribe(boo => boo && (this.userPermissions = this.utilityService.getAuthoritiesFromStorage()));

    this.menu = new McSidebarMenuConfig();
  }

  userHasPermission(link: any) {
    /*Check if user has permissions and sidebar links has property 'permission'*/
    if (!this.userPermissions && link.hasOwnProperty('permission')) {
      this.authService.logout();
    }

    /*Check if link has empty permission property - show link
    *Check if permission exist in user permissions*/
    return !link.permission || this.userPermissions.find((item: any) => item === link.permission);
  }

  toggleDropdownMenu(el: any) {
    const click = document.getElementById(el.ulName);
    if (click && click.style.height === '0px') {
      click.style.transition = 'height 0.5s';
      click.style.height = this.calculateSubmenuHeight(el.children.length);
      el.isExpanded = true;
    } else {
      click ? click.style.height = '0px' : '';
      el.isExpanded = false;
    }
  }

  // Hide dropdown menu
  hideDropdownMenu(index: any, num: any, el: any) {
    this.isSubLinkActive = num === 2;
    this.selectedLink = index;
    if (el.ulName !== '') {
      const click = document.getElementById(el.ulName);
      if (click && click.style.height !== '0px') {
        click.style.height = '0px';
        click.style.transition = 'none';
        el.isExpanded = false;
      } else {
        click ? click.style.transition = 'height 0.5s' : '';
      }
    } else {
      this.sideBarLinks.forEach((item: any) => {
        if (item.isExpanded) {
          const click = document.getElementById(item.ulName);
          click ? click.style.height = '0px' : '';
          click ? click.style.transition = 'none': '';
          el.isExpanded = false;
        }
      });
    }

  }

  // Set active class
  setActiveClass(linkName: string, num: number) {
    this.isSubLinkActive = num === 2;
    this.selectedLink = linkName;
  }

  /*Calculating submenu height*/
  public calculateSubmenuHeight(childrenNumber: number): string {
    return `${childrenNumber * 47}px`;
  }

  onCollapseSidenav(event: boolean) {
    this.isCollapsed = event;
    this.onCollapse.emit(this.isCollapsed);
  }

  ngOnDestroy() {
    this.entitySwitchedSubscription && this.entitySwitchedSubscription.unsubscribe();
  }
}
