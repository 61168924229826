import {McProcessRun} from './mc-process-run.model';
import {McProcessRunListGENERATED} from '../_generated/mc-process-run-list-generated.model';

export class McProcessRunList extends McProcessRunListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessRunList();
  }

  // ---------------------------------------------------------------------

  getItemsByStatusCd(statusList: string[]) {
    const result: any = [];

    this.items.forEach( item => {
      if (statusList.includes(item.statusCd)) {
        result.push(item);
      }
    });

    return result;
  }
}
