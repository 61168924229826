import {CsrConsumer} from './csr-consumer.model';
import {CsrConsumerListGENERATED} from '../_generated/csr-consumer-list-generated.model';

export class CsrConsumerList extends CsrConsumerListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrConsumerList();
  }

  // ---------------------------------------------------------------------
}
