
import {Table3Config} from '../extra/table-3-config';

// import {McBankAccountGENERATED} from '../_generated/mc-bank-account-generated.model';

export class McBankAccountTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAccountNumber', 'Account number', 'accountNumber', 'accountNumber');
    this.addColumn('colAccountOwner', 'Account owner', 'accountOwner', 'accountOwner');
    this.addColumn('colAccountOwnerAddress', 'Account owner address', 'accountOwnerAddress', 'accountOwnerAddress');
    this.addColumn('colAccountOwnerPostalCode', 'Account postal code', 'accountOwnerPostalCode', 'accountOwnerPostalCode');
    this.addColumn('colBankCity', 'Bank city', 'bankCity', 'bankCity');
    this.addColumn('colBankCountry', 'Bank country', 'bankCountry', 'bankCountry');
    this.addColumn('colBankName', 'Bank name', 'bankName', 'bankName');
    this.addColumn('colBic', 'BIC', 'bic', 'bic');
    this.addColumn('colBlz', 'Bank code number', 'blz', 'blz');
    this.addColumn('colDefaultFlg', 'Default flag', 'defaultFlg', 'defaultFlg');
    this.addColumn('colEbicsStatus', 'Ebics status', 'ebicsStatus', 'ebicsStatus');
    this.addColumn('colIban', 'IBAN', 'iban', 'iban');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colSepaMandateDate', 'Sepa mandate date of signature', 'sepaMandateDate', 'sepaMandateDate');
    this.addColumn('colSepaMandateId', 'Sepa mandate id', 'sepaMandateId', 'sepaMandateId');
    this.addColumn('colSwift', 'Swift', 'swift', 'swift');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');

*/
  }
}
