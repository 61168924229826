
import {Table3Config} from '../extra/table-3-config';

// import {McBillingEntitiesUsingPricelistGENERATED} from '../_generated/mc-billing-entities-using-pricelist-generated.model';

export class McBillingEntitiesUsingPricelistTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colEntityId', 'Entity id', 'entityId', 'entityId');
    this.addColumn('colEntityName', 'Entity name', 'entityName', 'entityName');
    this.addColumn('colStartDate', 'Start date', 'startDate', 'startDate');

*/
  }
}
