import {CsrContractHistoryFilterGENERATED} from '../_generated/csr-contract-history-filter-generated.model';

export class CsrContractHistoryFilter extends CsrContractHistoryFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrContractHistoryFilter();
  }

  // ---------------------------------------------------------------------
}
