import {Injectable} from '@angular/core';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';

@Injectable({
  providedIn: 'root'
})

export class CdsLookupService  {

  public getImportCustomerDuplicateHandlingCdsKvm():McValueLabelList {
    const result = new McValueLabelList();
    result.add("ADD", McGod.t('cc.consumers.consumers-overview.add-new-consumer-without-updating-the-existing-ones'));
    result.add("ADDUPD", McGod.t('cc.consumers.consumers-overview.add-new-consumer-and-update-existing-ones'));
    result.add("UPD", McGod.t('cc.consumers.consumers-overview.update-existing-consumers'));
    result.add('', McGod.t('cc.consumer.import.please-choose-import-mode'));
    return result;
  }
}
