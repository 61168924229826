import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {EbFactoring} from '@miticon-ui/mc-core';
import {McBoolean} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-factoring-profile-modal-part',
  templateUrl: './app-shared-factoring-profile-modal-part.component.html',
  encapsulation: ViewEncapsulation.None
})

export class AppSharedFactoringProfileModalPartComponent implements OnInit, OnDestroy {

  // ---------------------------------------------------------------------
  @Input() visibleMcb = new McBoolean();
  @Input() ebFactoringId!: number;
  @Input() ebFactoring = new EbFactoring();
  @Output() eventCancel = new EventEmitter();
  // @Output() eventDone = new EventEmitter();

  // ---------------------------------------------------------------------
  constructor() {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
        this.actLoad();
  }

  // ---------------------------------------------------------------------
  ngOnDestroy() {
  }


  // ---------------------------------------------------------------------
  actLoad() {
  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.visibleMcb.setFalse();
    this.eventCancel.emit();
  }

  // ---------------------------------------------------------------------
  isVisible() {
    if (!this.visibleMcb) {
      this.visibleMcb = new McBoolean();
    }
    return this.visibleMcb.value;
  }
}
