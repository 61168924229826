/*BbmTestObj*/
import {McBillingStatementItemTable3ConfigGENERATED} from '../_generated/mc-billing-statement-item-table-3-config-generated.model';

export class McMyBillingStatementItemTable3Config extends McBillingStatementItemTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.clearColumns();
    this.addColumn('colTypeCd', this.t('cc.jobs.edit.type'), 'getTypeCdLabel()', 'typeCd');
    this.addColumn('colItem', this.t('cc.billing.item'), 'title', 'title');
    this.addColumn('colBatch', this.t('cc.billing.batch'), 'mcBillingBatchName', '');
    this.addColumn('colPeriod', this.t('cc.billing.period'), 'period', '');
    this.addColumn('colUnits', this.t('cc.billing.units'), 'fldNoOfItems()', '');
    this.addColumn('colPrice', this.t('cc.billing.price'), 'getPriceDescForStatementItem()', '');
    this.addColumn('colAmount', this.t('cc.factoring.transactions.amount'), 'getAmount()', 'amount');
    this.addColumn('colVat', this.t('cc.product.view.vat'), 'getVATString()', '');
    this.addColumn('colAmountWithVat', this.t('cc.billing.total-amount'), 'getTotalFormat()', '');

  }
}
