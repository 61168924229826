/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CsrNotificationDetailsTable3ConfigGENERATED} from '../_generated/csr-notification-details-table-3-config-generated.model';

// import {CsrNotificationDetailsGENERATED} from '../_generated/csr-notification-details-generated.model';

export class CsrNotificationDetailsTable3Config extends CsrNotificationDetailsTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colEmail', 'Email', 'email', 'email');
    this.addColumn('colStreetAddress', 'Street address', 'streetAddress', 'streetAddress');
    this.addColumn('colSendInvoice', 'Invoice', 'sendInvoice', 'sendInvoice');


  }
}
