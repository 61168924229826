import {EbAccountConsumerListGENERATED} from '../_generated/eb-account-consumer-list-generated.model';

export class EbAccountConsumerList extends EbAccountConsumerListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbAccountConsumerList();
  }

  // ---------------------------------------------------------------------
}
