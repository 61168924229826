/*BbmTestObj*/
import {PpInvoiceGENERATED} from '../_generated/pp-invoice-generated.model';

export class PpInvoice extends PpInvoiceGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PpInvoice();
  }

  // ---------------------------------------------------------------------
}
