<div class="mc-sidebar">
  <nav>
    <div class="mc-sidebar-body mc-scrollbar-vertical" *ngIf="menu.menuItemList.length">
      <ul class="flex-column sidebar-list">

        <li class="sidebar-list-item" [class.sidebar-list-item_collapsed]='isCollapsed'
            *ngFor="let menuItem of menu.menuItemList; let i = index" McdPermissions
            [permissions]="menuItem.permission"
           >

          <!--Items with submenu items-->
          <div *ngIf="menuItem.submenu.length > 0">
            <div  McdPermissions
                  [permissions]="menuItem.permission"
                  matTooltip="{{ menuItem.translationKey | translate }}"
                  [matTooltipClass]="menuItem.hasActiveChild ? 'tabs_tool_tip_active' : 'tabs_tool_tip'"
                  [matTooltipDisabled]="!isCollapsed">

              <div (click)="toggleDropdownMenu(menuItem, $event, 1)" [class.active-tab]="menuItem.hasActiveChild"
                   class="dropdown border-bottom" [class.dropdown-collapsed]='isCollapsed'>

                <!--Item icon-->
                <span class='menu-item-icon'
                      [class.menu-item-icon_collapsed]='isCollapsed'
                      [class.menu-item-icon_active]='menuItem.hasActiveChild'
                      [innerHTML]='menuItem.icon | safeHtml'>
              </span>

                <!--Item name-->
                <a *ngIf='!isCollapsed' [class.active-parent]="menuItem.hasActiveChild"
                   class="mc-sidebar-link mc-submenu">
                  {{menuItem.translationKey | translate}}
                </a>

                <!--Arrow icon-->
                <span class='mc-menu-arrow arrow-position'
                      [innerHTML]='(menuItem.isExpanded ? dropDownIcon : dropUpIcon) | safeHtml'
                      [class.mc-menu-arrow_active]='menuItem.hasActiveChild'></span>
              </div>

              <!--Sbumenu list TODO: create recursion for hierarchy menu-->
              <ul *ngIf="!isCollapsed"
                  [style.height]="menuItem.isExpanded ? calculateSubmenuHeight(menuItem) : '0'"
                  class="mc-sidebar-submenu">

                <li *ngFor="let subLink1 of menuItem.submenu; let in = index"
                    class="submenu-item ml-2 mt-0"
                    McdPermissions
                    [permissions]="subLink1.permission">

                  <!--Submenu item wrapper-->
                  <div class="submenu-item-hover d-flex" *ngIf="subLink1" (click)="onSelectSubmenuItem(subLink1.name)">

                    <!--Submenu item name-->
                    <a #link
                       class="dropdown-link mc-sidebar-link mc-sidebar-link-subitem"
                       routerLinkActive="active" routerLink="{{subLink1.route}}">
                      {{subLink1.translationKey | translate}}

                    </a>

                  </div><!--Submenu item wrapper /end-->
                </li>
              </ul>
            </div>
          </div><!--Items with submenu items /end-->

          <!--Items without submenu items-->
          <div  *ngIf="!menuItem.submenu.length">
            <div McdPermissions
                 [permissions]="menuItem.permission"
                 [class.sidebar-list-item_collapsed]='isCollapsed'
                 matTooltip="{{ menuItem.translationKey | translate }}"
                 [matTooltipClass]="'tabs_tool_tip'"
                 [matTooltipDisabled]="!isCollapsed"
                 [class.active-tab]='isActive(menuItem.route)'
                 class="dropdown"
                 (click)="onSelectMenuItem(menuItem)">
              <span class='menu-item-icon'
                    [class.menu-item-icon_collapsed]='isCollapsed'
                    [innerHTML]='menuItem.icon | safeHtml'></span>
              <a *ngIf='!isCollapsed'
                 routerLinkActive="active"
                 routerLink="{{menuItem.route}}"
                 (click)="onSelectMenuItem(menuItem)"
                 class='mc-sidebar-link-no-subitems'
                 [class.active-parent]='isActive(menuItem.route)'
                 >
                {{menuItem.translationKey | translate}}
              </a>

            </div>
          </div><!--Items without submenu items /end-->
        </li>
      </ul>
    </div>
  </nav>
</div>
