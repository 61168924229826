<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcBillingStatementItem.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h2>Remove from statement</h2>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-body">

    <div class="info-container2 p-3">
      <mcc-row-title-description title="Aggregation name:"
                                 [description]="mcBillingStatementItem.title"></mcc-row-title-description>
      <mcc-row-title-description title="{{'cc.billing.number-of-items' | translate}}:"
                                 [description]="mcBillingStatementItem.fldNoOfItems()"></mcc-row-title-description>
      <mcc-row-title-description title="{{'cc.factoring.transactions.amount' | translate}}:"
                                 [description]="mcBillingStatementItem.getAmount()"></mcc-row-title-description>
      <mcc-row-title-description title="{{'cc.product.view.vat' | translate}}:" description="{{mcBillingStatementItem.vatPercentageForCorrection + '%'}}"></mcc-row-title-description>
      <mcc-row-title-description title="{{'cc.billing.amount-with-vat' | translate}}:"
                                 [description]="mcBillingStatementItem.fldAmountWithVat()"></mcc-row-title-description>
    </div>

    <mcc-message [errorMsg]="errorMsg"></mcc-message>

    <div class="pt-4 pb-4 d-flex">

      <div class="info-warning">
        <!-- mcBillingStatementItem.mcBillingBatchId -->
        <img *ngIf="mcBillingStatementItem.mcBillingBatchId" class="icon-img" src="assets/images/exlam.svg">
        <i *ngIf="!mcBillingStatementItem.mcBillingBatchId" class="far fa-question-circle icon-orange"></i>
        <div class="d-flex flex-column">
          <span>Are you sure you want to remove this billing item from statement?</span>
          <span class="text-danger" *ngIf="mcBillingStatementItem.mcBillingBatchId">Agreeing to this action will also remove the transaction batch and all other billing items associated with it.</span>
        </div>
      </div>
    </div>


  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel()"
                label="No"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actSave()" label="Yes"
                disableOnFirstClickFlg="true"></mcc-button>
  </div>

</div>
