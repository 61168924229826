<app-shared-go-back icon="fas fa-arrow-left" [title]="'cc.common.back-to-transactions-list' | translate"></app-shared-go-back>

<lib-mc-loader [showLoader]="pmTransaction.apiLoadingFlg"></lib-mc-loader>

<div class="actions-container mb-3">
  <div class="d-flex align-items-center">
    <div class="actions-title mr-3">
      <span>{{'cc.common.transaction-details' | translate}} - </span><span class="ml-1">ID</span><span class="ml-1">{{pmTransaction.id}}</span>
    </div>
    <span> Status: </span>
    <div [innerHTML]="pmTransaction.getIconLabel()" class="mc-transaction-status ml-2"></div>
  </div>

  <div ngbDropdown class="actions-wrapper">
    <button ngbDropdownToggle class="system-action-btn dark-system-action-btn"><i class="fas fa-pen mc-add-new-user-icon"></i> {{'cc.common.view.edit' | translate}}
    </button>
    <div class="dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem *ngIf="mcGod.userHasEbFactoringOutTransactionPaymentAddPermission() && pmTransaction.amountDue > 0 && !pmTransaction.isStatusCdSETTLED()"
                (click)="actShowAddTransactionPayment()">
          <i class="fas fa-plus-circle"></i> {{'cc.my-factoring.add-payment' | translate}}
        </button>
        <button ngbDropdownItem *ngIf="!pmTransaction.flgPaused" (click)="pauseUnpauseTransaction()">
          <i class="fas fa-pause"></i> {{'cc.common.pause-transaction' | translate}}
        </button>
        <button ngbDropdownItem *ngIf="pmTransaction.flgPaused" (click)="pauseUnpauseTransaction()">
          <i class="fas fa-play"></i> {{'cc.common.unpause-transaction' | translate}}
        </button>
        <button ngbDropdownItem (click)="actShowMarkAsSentToInkasso()">
          <i class="fas fa-check-double"></i> {{"cc.common.mark-as-sent-to-inkasso" | translate}}
        </button>
        <!--<button ngbDropdownItem
                *ngIf="pmTransaction.showReturnFromInkasso()"
                (click)="actReturnFromInkasso()">
          <i class="fas fa-undo"></i> {{"cc.transactions.return-from-inkasso" | translate}}
        </button>-->

        <button ngbDropdownItem *ngIf="!pmTransaction.isStatusCdCANCELLED() && !pmTransaction.isStatusCdEXPORTED() &&
                                       !pmTransaction.isStatusCdSETTLED() && (!pmTransaction.isStatusCdNEW() || (pmTransaction.isCollectionTypeCdDRAFT() && pmTransaction.isStatusCdNEW())) &&
                                       !pmTransaction.isStatusCdPAID()" (click)="actStorno()">
          <i class="fas fa-times-circle"></i> {{'cc.common.storno' | translate}}
        </button>

      <button ngbDropdownItem *ngIf="pmTransaction.isCollectionTypeCdDRAFT()" (click)="actChangeCollectionType()">
        <i class="fas fa-edit"></i> {{'cc.transaction.change-collection-type' | translate}}
      </button>
    </div>
  </div>
</div>


<div class='card-wrapper'>
  <app-shared-card [showHeader]="false">
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.my-factoring.consumer' | translate}}:" [description]="pmTransaction.csrConsumer ? pmTransaction.csrConsumer.displayName : ''"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.consumer-internal-id' | translate}}:" [description]="pmTransaction.csrConsumer ? pmTransaction.csrConsumer.id : ''"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.studio' | translate}}:" [description]="pmTransaction.idMcEntity"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.contract-number' | translate}}:" [description]="contractNumber"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.out-factoring.external-transaction-id' | translate}}:" [description]="pmTransaction.idExtTransaction"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.out-factoring.transaction-id' | translate}}:" [description]="pmTransaction.id"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumers.imports.created-at' | translate}}:" [description]="pmTransaction.getCreatedDatetimeStr()"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.total-used-/-max-paused-days' | translate}}:" [description]="getMaxUsedTotalPausedDays()"></mcc-row-title-description>
  </app-shared-card>

  <app-shared-card [showHeader]="false" *ngIf="pmTransaction.exists()">
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.transactions.payment-due-date' | translate}}:" [description]="pmTransaction.getDueDateStr()"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.original-amount' | translate}}:" [description]="pmTransaction.getAmountStr()"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.my-factoring.remaining-amount-due' | translate}}:" [description]="pmTransaction.getAmountDue()"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.dashboard.balance' | translate}}:" [description]="pmTransaction.getBalanceStr()"></mcc-row-title-description>
  </app-shared-card>
</div>


<div class="actions-container mt-4">
  <header (click)="isTransactionHistoryExpanded = !isTransactionHistoryExpanded">
    <i class="fas fa-history fa-lg"></i>
    <span class="pl-2">{{'cc.my-factoring.transaction-history' | translate}}</span>
    <i [ngClass]="isTransactionHistoryExpanded ? 'fas fa-caret-up' : 'fas fa-caret-down'" class="ml-2"></i>
  </header>

</div>
<ng-container *ngIf="isTransactionHistoryExpanded">
  <div class="col-md-12 p-0" *ngIf="pmAdjustmentList.apiSuccessFlg">
    <mcc-fi-table-3 [objectList]="pmAdjustmentList"
                    [table3Config]="pmAdjustmentTable3Config"></mcc-fi-table-3>
  </div>
</ng-container>

<div class="actions-container">
  <header (click)="isBillingItemsExpanded = !isBillingItemsExpanded">
    <i class="fas fa-file-invoice fa-lg"></i>
    <span class="pl-2">{{'cc.common.billing-items' | translate}}</span>
    <i [ngClass]="isBillingItemsExpanded ? 'fas fa-caret-up' : 'fas fa-caret-down'" class="ml-2"></i>
  </header>
</div>

<ng-container *ngIf="isBillingItemsExpanded">
  <div class="col-md-12 p-0" *ngIf="billingItemList.apiSuccessFlg">
    <mcc-fi-table-3 [objectList]="billingItemForTableList"
                    [table3Config]="billingItemTable3Config"
                    (eventPaginationOrSortChanged)="loadBillingItems()"></mcc-fi-table-3>
  </div>
</ng-container>

<div class="actions-container">
  <header (click)="isPayoutDetailsExpanded = !isPayoutDetailsExpanded">
    <i class='fas fa-sign-in-alt' style='font-size:24px'></i>
    <span class="pl-2">{{'cc.common.payout-details' | translate}}</span>
    <i [ngClass]="isPayoutDetailsExpanded ? 'fas fa-caret-up' : 'fas fa-caret-down'" class="ml-2"></i>
  </header>

</div>
<ng-container *ngIf="isPayoutDetailsExpanded">
  <div class="col-md-12 p-0">
    <mcc-fi-table-3 [objectList]="payoutItemList"
                    [table3Config]="payoutItemTable3Config"
                    (eventPaginationOrSortChanged)="loadBillingItems()"
    ></mcc-fi-table-3>
  </div>
</ng-container>

<mcc-modal1-wrapper *ngIf="showTransactionAddPaymentMcb.value" [showMcb]="showTransactionAddPaymentMcb" modalSizeCd="small">
  <app-shared-transaction-adjustment-add-edit-part [pmTransaction]="pmTransaction" type="payment" (eventSaved)="actOnTransactionAddPaymentClose()" (eventCanceled)="showTransactionAddPaymentMcb.setFalse()" ebTransactionAdjustmentId="0"></app-shared-transaction-adjustment-add-edit-part>
</mcc-modal1-wrapper>

<mcc-dialog-box  *ngIf="showMarkAsSentToInkassoMcb.value" [enableCloseButtonFlg]="false"
                 title="Mark as sent to Inkasso" name="mar-as-sent-to-inkasso"
                 [description]="markAsSentToBankDialogMessage"
                 [buttonsKvm]="buttonsMarkAsSentToInkassoDialog"
                 popupType="question"
                 (eventActionButtonClicked)="onMarkAsSentToInkasso($event)">
</mcc-dialog-box>
  <mcc-modal1-wrapper [showMcb]="showPauseTransactionMcb" *ngIf="showPauseTransactionMcb.value">
    <app-shared-pause-transactions (eventPausedTransaction)="onPauseTransaction()"
                                   (eventUnpausedTransaction)="onUnpauseTransaction()"
                                   (eventPauseTransactionCanceled)="onPauseTransactionCanceled()"
                                   [pmTransactionList]="checkedTransactionList">
    </app-shared-pause-transactions>
  </mcc-modal1-wrapper>

<mcc-modal1-wrapper [showMcb]="showStornoMcb" *ngIf="showStornoMcb.value" modalSizeCd="small">
  <pm-transactions-storno-part [csrConsumer]="pmTransaction.csrConsumer" [pmTransactions]="selectedTransactionList"
                               (eventCanceled)="onCancelStorno()" (eventSaved)="onSaveStorno()">
  </pm-transactions-storno-part>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper [showMcb]="showCollectionTypeChangeMcb" *ngIf="showCollectionTypeChangeMcb.value" modalSizeCd="small">
  <pm-transactions-collection-type-part [pmTransaction]="pmTransaction"
                               (eventCanceled)="onCancelUpdateCollectionType()" (eventSaved)="onUpdateCollectionType()">
  </pm-transactions-collection-type-part>
</mcc-modal1-wrapper>





