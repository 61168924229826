/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McEntity2Table3ConfigGENERATED} from '../_generated/mc-entity2-table-3-config-generated.model';

// import {McEntity2GENERATED} from '../_generated/mc-entity2-generated.model';

export class McEntity2Table3Config extends McEntity2Table3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldEntity2Actions()', '');
    this.addColumn('colName', this.t('cc.common.name'), 'name', 'name');
    this.addColumn('colFactoringEnableFlg', this.t('cc.out-factoring.factoring-enabled'), 'fldFactoringEnabled()', 'factoringEnableFlg');


  }
}
