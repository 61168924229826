import {McEntity2FilterGENERATED} from '../_generated/mc-entity2-filter-generated.model';

export class McEntity2Filter extends McEntity2FilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McEntity2Filter();
  }

  // ---------------------------------------------------------------------
}
