import {McProcessRunItemFilterGENERATED} from '../_generated/mc-process-run-item-filter-generated.model';

export class McProcessRunItemFilter extends McProcessRunItemFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessRunItemFilter();
  }

  // ---------------------------------------------------------------------
}
