import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IPpInvoice} from '../_generated/pp-invoice.interface';
import {PpInvoice} from '../models/pp-invoice.model';
import {PpInvoiceList} from '../models/pp-invoice-list.model';
import {PpInvoiceServiceGENERATED} from '../_generated/pp-invoice-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PpInvoiceService extends PpInvoiceServiceGENERATED {

}
