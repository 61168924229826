import {Inject, Injectable} from '@angular/core';
import {CcMollieTransactionServiceGENERATED} from '../_generated/cc-mollie-transaction-generated.service';



@Injectable({
  providedIn: 'root'
})
export class CcMollieTransactionService extends CcMollieTransactionServiceGENERATED {

  pay(ids: number[], paymentMethod: string, amount: any, description: string, entityId: number) {
    const requestOptions: {} = {
      responseType: 'text'
    };
    const body: any = {};
    body.ids = ids;
    body.paymentMethod = paymentMethod;
    body.amount = amount;
    body.description = description;
    body.entityId = entityId;

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/pay`, body, requestOptions
    );
  }

}
