<div style="cursor:pointer; color: #36B1F1" class="mb-4 mt-3" (click)="navigateBack()"><i
  class="fas fa-chevron-left link"></i>
  <h6 style="text-transform: uppercase; display: inline" *ngIf="!isListTemplate"> {{'cc.pricelist.back-to-pricelists' | translate}}</h6>
  <h6 style="text-transform: uppercase; display: inline" *ngIf="isListTemplate"> {{'cc.pricelist.back-to-templates' | translate}}</h6>
</div>

<lib-mc-loader [showLoader]="mcPricelist.apiLoadingFlg"></lib-mc-loader>

<div class="row p-0 m-0 d-flex flex-row align-items-center">
  <h3 class="pr-2 pt-1" style="text-transform: capitalize">{{mcPricelist.title}}</h3>
</div>

<div>

  <mcc-message [errorMsg]="mcPricelist.apiErrorMessage" [successMsg]="succesMsg"></mcc-message>

  <div>
    <div class="w-100">
      <div class="info-summary-top-lite inner-content d-flex info-summary-blue">
        <div class="w-50">
          <div class="editable-item">
            <mcc-row-title-description
              [title]="!isListTemplate ? mcGod.t('cc.pricelist.pricelist-name') + ':' : mcGod.t('cc.pricelist.template-name') + ':'"
              [description]="mcPricelist.title"></mcc-row-title-description>
            <span *ngIf="mcGod.userHasMcBillingPricelistAddPermission()" class="edit-pen" (click)="onClickPricelistTemplateEdit()"><i class="fas fa-pen icon-blue"></i></span>
          </div>
          <div class="editable-item">
            <mcc-row-title-description
              [title]="!isListTemplate ? mcGod.t('cc.pricelist.pricelist-description') + ':' : mcGod.t('cc.pricelist.template-description') + ':'"
              [description]="mcPricelist.description"></mcc-row-title-description>
            <span *ngIf="mcGod.userHasMcBillingPricelistAddPermission()" class="edit-pen" (click)="onClickPricelistTemplateEdit()"><i class="fas fa-pen icon-blue"></i></span>
          </div>
          <mcc-row-title-description *ngIf="!isListTemplate" [title]="mcGod.t('cc.pricelist.template-origin') + ':'"
                                     [description]="mcPricelist.nameOfOriginTemplate"></mcc-row-title-description>
          <div class="editable-item">
            <mcc-row-title-description *ngIf="!isListTemplate" [title]="mcGod.t('cc.pricelist.no-of-entities') + ':'"
                                       (click)="showEntitiesUsingPricelistMcb.setTrue()"
                                       [description]="mcPricelist.numberOfEntitiesUsingPricelist"
                                       extraCssClasses="mc-description-blue-color"></mcc-row-title-description>
            <span class="edit-pen" *ngIf="!isListTemplate && mcGod.userHasMcBillingPricelistAddPermission()" (click)="onClickPricelistTemplateEdit()"><i class="fas fa-pen icon-blue"></i></span>
          </div>
          <mcc-row-title-description *ngIf="isListTemplate"
                                     [title]="mcGod.t('cc.pricelist.number-of-pricelists-created') + ':'"
                                     [description]="mcPricelist.numberOfCreatedPriceLists"
                                     (click)="showPriceListsCreatedFromThisTemplateMcb.setTrue()"
                                     extraCssClasses="mc-description-blue-color"></mcc-row-title-description>
        </div>
        <div class="w-50">
          <mcc-row-title-description *ngIf="isListTemplate"
                                     [title]="mcGod.t('cc.pricelist.no-of-entities-using-this-template-and-its-pricelists') + ':'"
                                     [description]="mcPricelist.numberOfEntitiesUsingTemplate"
                                     (click)="showEntitiesUsingThisTemplateMcb.setTrue()"
                                     extraCssClasses="mc-description-blue-color"></mcc-row-title-description>
          <mcc-row-title-description *ngIf="!isListTemplate" [title]="mcGod.t('cc.pricelist.default-custom-prices') + ':'"
                                     [descriptionInnerHtml]="mcPricelist.getDefaultCustomLbl()"
                                     extraCssClasses="mc-description-blue-color"></mcc-row-title-description>
          <div class="editable-item">
            <mcc-row-title-description *ngIf="!isListTemplate" [title]="mcGod.t('cc.pricelist.valid-from') + ':'"
                                       [description]="mcPricelist.getValidFromStr()"></mcc-row-title-description>
            <span class="edit-pen" (click)="onClickPricelistTemplateEdit()" *ngIf="!isListTemplate && mcGod.userHasMcBillingPricelistAddPermission()"><i
              class="fas fa-pen mc-blue-icon"></i></span></div>
          <mcc-row-title-description [title]="mcGod.t('cc.common.created-date-and-time') + ':'"
                                     [description]="mcPricelist.getSysCreatedDatetimeString()"></mcc-row-title-description>
          <mcc-row-title-description [title]="mcGod.t('cc.pricelist.created-by') + ':'"
                                     [description]="mcPricelist.createdByUserFullName"></mcc-row-title-description>
        </div>
      </div>

    </div>
  </div>

  <mcc-modal1-wrapper [showMcb]="showEditTemplateNameDescMcb" *ngIf="showEditTemplateNameDescMcb.value">
    <mc-pricelist-template-edit-from-profile-part [mcPriceList]="mcPricelist" (eventCanceled)="showEditTemplateNameDescMcb.setFalse()"
                                                  (eventSaved)="onEditTemplateSaved()"></mc-pricelist-template-edit-from-profile-part>
  </mcc-modal1-wrapper>
    <mcc-modal1-wrapper class="scroll" [showMcb]="showPriceListsCreatedFromThisTemplateMcb"
                        *ngIf="showPriceListsCreatedFromThisTemplateMcb.value">
      <mc-pricelist-template-pricelists-created-from-template-part
        (eventCanceled)="showPriceListsCreatedFromThisTemplateMcb.setFalse()"
        [mcPriceList]="mcPricelist"></mc-pricelist-template-pricelists-created-from-template-part>
    </mcc-modal1-wrapper>

  <mcc-modal1-wrapper [showMcb]="showEntitiesUsingThisTemplateMcb" *ngIf="showEntitiesUsingThisTemplateMcb.value">
    <mc-pricelist-template-entities-using-template-part (eventClose)="showEntitiesUsingThisTemplateMcb.setFalse()"
                                                        [mcPriceList]="mcPricelist"></mc-pricelist-template-entities-using-template-part>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper [showMcb]="showEditPriceListFromProfileMcb" *ngIf="showEditPriceListFromProfileMcb.value" (eventCanceled)="showEditPriceListFromProfileMcb.setFalse(); actLoad();">
    <mc-pricelist-pricelist-edit-from-profile-part [mcPriceList]="mcPricelist"
                                                   (eventCanceled)="showEditPriceListFromProfileMcb.setFalse(); actLoad();"
                                                   (eventSaved)="onEditPricelistSaved()"></mc-pricelist-pricelist-edit-from-profile-part>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper [showMcb]="showEntitiesUsingPricelistMcb" *ngIf="showEntitiesUsingPricelistMcb.value">
    <mc-pricelist-pricelist-entities-using-pricelist-part [mcPriceList]="mcPricelist"
                                                          (eventClose)="showEntitiesUsingPricelistMcb.setFalse()"></mc-pricelist-pricelist-entities-using-pricelist-part>
  </mcc-modal1-wrapper>


  <mcc-modal1-wrapper [showMcb]="showServiceAddEditMcb" *ngIf="showServiceAddEditMcb.value" (eventCanceled)="onServiceEditCancelEmit()">
    <mc-pricelist-pricelist-profile-service-edit-part [mcBillingService2PriceList]="mcService2Pricelist"
                                                      (eventSaved)="onServiceEditSaveEmit($event)" [templatePricelistId]="mcPricelist.idParentTemplate"
                                                      [areServiceButtonsDisabled]="true"
                                                      [currency]="currencyCode"
                                                      (eventCanceled)="onServiceEditCancelEmit()"></mc-pricelist-pricelist-profile-service-edit-part>
  </mcc-modal1-wrapper>
  <div class="services-price-table mc-pricelist-table">
    <div *ngIf="mcPricelist.apiSuccessFlg">
      <mcc-fi-table-3 [objectList]="mcBillingService2PriceListList"
                      [table3Config]="mcBillingService2PriceListTable3Config"
                      (eventItemAction)="onServiceTableItemAction($event)"
                      (eventPaginationOrSortChanged)="actLoad()"></mcc-fi-table-3>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

