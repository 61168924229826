<app-shared-go-back icon="fas fa-arrow-left" [title]="'cc.statement.back-to-statements-list' | translate"></app-shared-go-back>

<lib-mc-loader [showLoader]="mcBillingStatement.apiLoadingFlg"></lib-mc-loader>

<div class="actions-container mb-3">
  <div class="d-flex align-items-center">
    <div class="actions-title mr-3">
      <span>{{mcBillingStatement.title}} {{'cc.billing.details' | translate}}</span>
    </div>
  </div>

  <div ngbDropdown class="actions-wrapper">
    <button ngbDropdownToggle class="system-action-btn dark-system-action-btn">{{'cc.billing.actions' | translate}}</button>
    <div class="dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="onActionsDropdownClick('PREVIEW_PDF')" *ngIf="mcGod.userHasMcBillingMyStatementPreviewPdfPermission()"><i
          class="fas fa-search-plus"></i>{{'cc.billing.preview-pdf' | translate}}
        </button>
        <button ngbDropdownItem (click)="onActionsDropdownClick('DOWNLOAD_PDF')" *ngIf="mcGod.userHasMcBillingMyStatementDownloadPdfPermission()"><i
          class="fas fa-file-pdf"></i>{{'cc.billing.download-pdf' | translate}}
        </button>
        <!--<button ngbDropdownItem (click)="onActionsDropdownClick('PREVIEW_XLS')" *ngIf="mcGod.userHasMcBillingMyStatementPreviewXlsPermission()"><i
          class="fas fa-search-plus"></i>{{'cc.billing.preview-xls' | translate}}</button>-->
        <button ngbDropdownItem (click)="onActionsDropdownClick('DOWNLOAD_XLS')" *ngIf="mcGod.userHasMcBillingMyStatementDownloadXlsPermission()"><i
          class="fas fa-file-excel"></i>{{'cc.billing.download-xls' | translate}}
        </button>
    </div>
  </div>
</div>

<div class='card-wrapper'>
  <app-shared-card [showHeader]="false">
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.factoring.transactions.entity-name' | translate}}:"[description]="mcBillingStatement.childEntityName"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.address' | translate}}:" [description]="entityAddress"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.billing.entity-id' | translate}}:" [description]="mcBillingStatement.childEntityId"></mcc-row-title-description>
  </app-shared-card>

  <app-shared-card [showHeader]="false">
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.billing.statements-no' | translate}}:" [description]="mcBillingStatement.statementNumber"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.billing.statement-date' | translate}}:" [description]="mcBillingStatement.getStatementDate()"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.billing.no-of-items' | translate}}:" [description]="mcBillingStatement.mcBillingStatementItemsCount"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.billing.generated-file-name' | translate}}:" [description]="webFileName"></mcc-row-title-description>
  </app-shared-card>
</div>

<div class="actions-container mb-4 mt-3">
  <span>{{'cc.billing.statement-items' | translate}}</span>
</div>



  <mk-mat-table
    [isLoading]="isLoading"
    [items]="mcBillingStatementItemList.items"
    [config]="tableConfig"
    (filterChanged)="onFilterChanged($event)"
    [totalItemsCount]="mcBillingStatementItemList.totalItemsCount"
    [itemsPerPage]="mcBillingStatementItemList.pageItemsPerPageCount">
  </mk-mat-table>



  <div class="content-body mb-3">
    <!--<mcc-message [errorMsg]="mcBillingStatement.apiErrorMessage"></mcc-message>-->
    <mcc-message [errorMsg]="errorMsg" [successMsg]="successMsg"></mcc-message>

    <div class="row">
      <div class="col-md-12 mc-my-billing-statement-items-table">
        <div class="total-row mt-2">
          <div class="main-label">
            <h5>{{'cc.billing.total-fees' | translate}}:</h5>
          </div>
            <div class="sum-total">{{amountWithVatSum}}</div>
          </div>
      </div>
    </div>

    <div class="container-fluid standout mt-5"><!--div sa senkom da se odvoji tabela-->
    <div class="row">
      <div class="col-md-12 d-flex">
        <h4><img src="assets/images/info-square.svg" alt=""> {{'cc.billing.processed-transactions-info' | translate}}
        </h4>
      </div>
    </div>

    <hr>

    <div *ngFor="let item of mcBillingBatchSnapshotList.items" class="row">
      <div class="table-title ml-3"><h2>{{item.batchHeader}}</h2></div>

      <!--blue header-->
      <div class="col-12">
        <div class="row blue-batch-header">
          <div class="col-md-5 d-flex">
            <span>{{'cc.common.view.description' | translate}}</span>
          </div>
          <div class="col-md-3 d-flex">
            <span class="ml-auto">%</span>
          </div>
          <div class="col-md-2 d-flex">
            <span class="ml-auto">{{'cc.billing.initial-sum' | translate}}</span>
          </div>
          <div class="col-md-2 d-flex">
            <span class="ml-auto">{{'cc.billing.final-sum' | translate}}</span>
          </div>
        </div>
      </div>

      <div class="batch-table col-md-12 pt-2 mb-3"> <!--------------------- TABLE --------------------------------->

        <!--<div class="row table-header">  DO NOT DELETE THIS !!!! REQUIREMENTS MAY CHANGE AGAIN !!!
          <div class="col-md-5 d-flex">
            <span>{{'cc.common.view.description' | translate}}</span>
          </div>
          <div class="col-md-3 d-flex">
            <span class="ml-auto">%</span>
          </div>
          <div class="col-md-2 d-flex">
            <span class="ml-auto">{{'cc.billing.initial-sum' | translate}}</span>
          </div>
          <div class="col-md-2 d-flex">
            <span class="ml-auto">{{'cc.billing.final-sum' | translate}}</span>
          </div>
        </div>-->

        <!-- Acceptance-->
        <div class="row">
          <div class="col-md-12 d-flex">

            <h3>{{'cc.statement.acceptance' | translate}}</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5 d-flex">
            <h4>{{item.flgFactoring ? ('cc.my-factoring.accepted-for-factoring' | translate) : ('cc.billing.accepted-for-service' | translate)}}</h4>
          </div>
          <div class="col-md-3 d-flex">
            <span class="ml-auto">{{item.acceptedForSFColumnPercentage.toFixed(2).replace('.', ',')}} %</span>
          </div>
          <div class="col-md-2 d-flex">
            <span class="ml-auto">{{item.acceptedForSFColumnInitialSumLoc}}</span>
          </div>
          <div class="col-md-2 d-flex">
              <span
                class="ml-auto">{{format(item.acceptedForSFColumnFinalSum)}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5 d-flex">
            <h4>{{item.flgFactoring ? ('cc.my-factoring.not-accepted-for-factoring' | translate) : ('cc.billing.not-accepted-for-service' | translate)}}</h4>
          </div>
          <div class="col-md-3 d-flex">
            <span class="ml-auto">{{item.notAcceptedForSFColumnPercentage.toFixed(2).replace('.', ',')}} %</span>
          </div>
          <div class="col-md-2 d-flex">
            <span class="ml-auto">{{format(item.notAcceptedForSFColumnInitialSum)}}</span>
          </div>
          <div class="col-md-2 d-flex">
              <span
                class="ml-auto">{{format(item.notAcceptedForSFColumnFinalSum)}}</span>
          </div>
        </div>
      </div>


      <!--past-->
      <!-- Past statements-->
      <div class="batch-table col-md-12 pt-2 mb-3">

        <div class="row">
          <div class="col-md-12 d-flex">
            <h3>{{'cc.statement.past-statements' | translate}}</h3>
          </div>
        </div>

        <div class="row"> <!-- *ngIf="item.alreadyPaidBalanceColumnPercentage" -->
          <div class="col-md-5 d-flex">
            <h4>{{'cc.statement.already-paid-balance' | translate}}</h4>
          </div>
          <!--<div class="col-md-3 d-flex">
            <span class="ml-auto">{{item.alreadyPaidBalanceColumnPercentage ? item.alreadyPaidBalanceColumnPercentage.toFixed(2).replace('.', ',') : '0,00'}} %</span>
          </div>-->
          <div class="col-md-5 d-flex">
            <span class="ml-auto">{{format(item.alreadyPaidBalanceColumnInitialSum)}}</span>
          </div>
          <div class="col-md-2 d-flex">
              <span
                class="ml-auto">{{format(item.alreadyPaidBalanceColumnFinalSum)}}</span>
          </div>
        </div>

        <div class="row"> <!-- *ngIf="item.alreadyReturnedBalanceColumnPercentage" -->
          <div class="col-md-5 d-flex">
            <h4>{{'cc.statement.already-returned-balance-all-statements' | translate}}</h4>
          </div>
          <div class="col-md-5 d-flex">
            <span class="ml-auto">{{format(item.alreadyReturnedBalanceColumnInitialSum)}}</span>
          </div>
          <div class="col-md-2 d-flex">
            <span class="ml-auto">- {{format(item.alreadyReturnedBalanceColumnFinalSum)}}</span>
          </div>
        </div>
      </div>

      <div class="batch-table col-md-12 pt-2 mb-3">
        <div class="row">
          <div class="col-md-12 d-flex">
            <h3>{{'cc.statement.present-statements' | translate}}</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5 d-flex">
            <h4>{{'cc.my-factoring.immediately-payable-balance' | translate}}</h4>
          </div>
          <!--<div class="col-md-3 d-flex">
            <span class="ml-auto">{{item.immediatelyPayableBalanceColumnPercentage.toFixed(2).replace('.', ',') ? item.immediatelyPayableBalanceColumnPercentage.toFixed(2).replace('.', ',') : '0.00'}} %</span>
          </div>-->
          <div class="col-md-5 d-flex">
            <span class="ml-auto">{{format(item.immediatelyPayableBalanceColumnInitialSum)}}</span>
          </div>
          <div class="col-md-2 d-flex">
              <span
                class="ml-auto">{{format(item.immediatelyPayableBalanceColumnFinalSum)}}</span>
          </div>
        </div>

        <div class="row"> <!-- *ngIf="item.returningAmountFinalBounceColumnPercentage" -->
          <div class="col-md-5 d-flex">
            <h4>{{'cc.statement.returning-amount-soft-and-or-hard-bounce' | translate}}</h4>
          </div>
          <!--<div class="col-md-3 d-flex">
            <span class="ml-auto">{{item.returningAmountFinalBounceColumnPercentage.toFixed(2).replace('.', ',') ? item.returningAmountFinalBounceColumnPercentage.toFixed(2).replace('.', ',') : '0.00'}} %</span>
          </div>-->
          <div class="col-md-5 d-flex">
            <span class="ml-auto">{{format(item.returningAmountFinalBounceColumnInitialSum)}}</span>
          </div>
          <div class="col-md-2 d-flex">
              <span
                class="ml-auto">- {{format(item.returningAmountFinalBounceColumnFinalSum)}}</span>
          </div>
        </div>
      </div>
      <div class="batch-table col-md-12 pt-2 mb-3">
        <div class="row">
          <div class="col-md-12 d-flex">
            <h3>{{'cc.statement.future-statement' | translate}}</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5 d-flex">
            <h4>{{'cc.my-factoring.later-payable-balance' | translate}}</h4>
          </div>
          <!--<div class="col-md-3 d-flex">
            <span class="ml-auto">{{item.laterPayableBalanceColumnPercentage.toFixed(2).replace('.', ',')}} %</span>
          </div>-->
          <div class="col-md-5 d-flex">
            <span class="ml-auto">{{format(item.laterPayableBalanceColumnInitialSum)}}</span>
          </div>
          <div class="col-md-2 d-flex">
              <span
                class="ml-auto">{{format(item.laterPayableBalanceColumnFinalSum)}}</span>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div><!-- content-body -->

  <div class="content-body">

  <div class="container-fluid standout mb-5"><!--div sa senkom da se odvoji tabela-->
    <div class="row pt-3 pl-3">
      <h4><i class="fas fa-coins mc-submit-color"></i> {{'cc.billing.payout-balance' | translate}}</h4>
    </div>
    <hr>
    <div class="row">
      <div class="table-template col-md-12"> <!--------------------- PAYOUT TABLE --------------------------------->

        <div class="mr-3 ml-3">
          <div class="row table-header">
            <div class="col-md-3 d-flex">
              <span>{{'cc.common.view.description' | translate}}</span>
            </div>
            <div class="col-md-3 d-flex">
              <span class="ml-auto">{{'cc.billing.batch' | translate}}</span>
            </div>
            <!--<div class="col-md-2 d-flex">
              <span class="ml-auto">%</span>
            </div>-->
            <div class="col-md-3 d-flex">
              <span class="ml-auto">{{'cc.billing.initial-sum' | translate}}</span>
            </div>
            <div class="col-md-3 d-flex">
              <span class="ml-auto">{{'cc.billing.final-sum' | translate}}</span>
            </div>
          </div>

          <ng-container *ngIf="mcBillingStatement.payoutTableItems">
            <div *ngFor="let item of mcBillingStatement.payoutTableItems.items" class="row">
              <div class="col-md-3 d-flex">
                <span>{{item.description}}</span>
              </div>
              <div class="col-md-3 d-flex">
                <span class="ml-auto">{{item.batch}}</span>
              </div>
              <!--<div class="col-md-2 d-flex">
                <span class="ml-auto">{{item.percentage ? item.percentage.toFixed(2).replace('.', ',') : '0,00'}} %</span>
              </div>-->
              <div class="col-md-3 d-flex">
                <span class="ml-auto">{{format(item.initialSum)}}</span>
              </div>
              <div class="col-md-3 d-flex">
                <span class="ml-auto">{{item.flgToAdd ? '' : '- '}}{{format(item.finalSum)}}</span>
              </div>
            </div>
          </ng-container>

          <div class="row">
            <div class="col-md-12 d-flex w-100">
              <span>{{'cc.billing.total-fees' | translate}}</span>
              <span class="ml-auto">{{(amountWithVatSum).includes('-') ? amountWithVatSum.substring(1) : '-' + amountWithVatSum}}</span>
            </div>
          </div>

          <div class="row table-footer2">
            <div class="col-md-12 d-flex w-100">
              <span>{{'cc.billing.total-payout' | translate}}</span>
              <span class="ml-auto">{{format(mcBillingStatement.totalPayout)}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
