
import {Table3Config} from '../extra/table-3-config';

// import {PpPriceAdjustmentGENERATED} from '../_generated/pp-price-adjustment-generated.model';

export class PpPriceAdjustmentTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDescription', 'Price adjustment description', 'description', 'description');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');
    this.addColumn('colTitle', 'Price adjustment title', 'title', 'title');
    this.addColumn('colTypeCd', 'Price adjustment type', 'typeCd', 'typeCd');
    this.addColumn('colValue', 'Price adjustment value', 'value', 'value');
    this.addColumn('colValueTypeCd', 'Price adjustment value type', 'valueTypeCd', 'valueTypeCd');
    this.addColumn('colVersion', 'Version', 'version', 'version');

*/
  }
}
