/*BbmTestObj*/
import {CsrNotificationDetailsGENERATED} from '../_generated/csr-notification-details-generated.model';

export class CsrNotificationDetails extends CsrNotificationDetailsGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrNotificationDetails();
  }

  // ---------------------------------------------------------------------
}
