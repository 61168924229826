
import {Table3Config} from '../extra/table-3-config';

// import {PmAdjustmentGENERATED} from '../_generated/pm-adjustment-generated.model';

export class PmAdjustmentTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAmount', 'Amount', 'amount', 'amount');
    this.addColumn('colBeneficiaryEntity', 'Beneficiary entity', 'beneficiaryEntity', 'beneficiaryEntity');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colIdBeneficiaryEntity', 'Beneficiary entity', 'idBeneficiaryEntity', 'idBeneficiaryEntity');
    this.addColumn('colIdEbPaymentReport2Transaction', 'Eb Payment Report 2 Transaction ID', 'idEbPaymentReport2Transaction', 'idEbPaymentReport2Transaction');
    this.addColumn('colIdPmTransaction', 'Pm Transaction ID', 'idPmTransaction', 'idPmTransaction');
    this.addColumn('colIdRef', 'Ref ID', 'idRef', 'idRef');
    this.addColumn('colPaymentMethodCd', 'Payment Method', 'paymentMethodCd', 'paymentMethodCd');
    this.addColumn('colPmTransaction', 'Transaction', 'pmTransaction', 'pmTransaction');
    this.addColumn('colRefTypeCd', 'Ref Type CD', 'refTypeCd', 'refTypeCd');
    this.addColumn('colReturnTypeCd', 'Return type', 'returnTypeCd', 'returnTypeCd');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysDescription', 'System description', 'sysDescription', 'sysDescription');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colTypeCd', 'Type code', 'typeCd', 'typeCd');

*/
  }
}
