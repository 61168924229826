<!--Jobs wrapper-->
<div class="row mc-schedule-job-wrapper">

  <!--Title-->
  <div class="col-md-12 mc-tittle-wrapper">
    <h3 class="text-capitalize d-inline">{{scheduleModule}}</h3>

    <!--TODO; uncomment when BE implment total elements from DB.-->
    <!--    <div class="ml-3 py-1 d-inline" *ngIf="tableContent">-->
    <!--      <span class="font-italic text-capitalize pr-1">{{ 'cc.common.total-elements-from-db' | translate}}:</span>-->
    <!--      <span class="px-1" McDetectChanges [elementHtmlValue]="true"-->
    <!--            >{{tableContent.totalDbElements || 0}}</span>-->
    <!--    </div>-->
    <div class="mc-error">
      <mc-notify-message [message]="errorObject.message" [type]="errorObject.type"></mc-notify-message>
    </div>
  </div>

  <!--Filter-->
<!--  <div class="col-md-12">-->
<!--    <mc-filter [name]="scheduleModule"></mc-filter>-->
<!--  </div>-->
  <!--Create job-->
  <div class="actions-container" style='width: 100%; margin: 0 15px 15px 15px; background: #FFFF'>
    <span>{{ 'cc.jobs-list' | translate }}</span>
    <span McPermission
          [hasPermissions]="userPermissionCreateJob"
          [page]="scheduleModule">
      <button
        (click)="openCreateJobDialog()"
        class="system-action-btn">
        <i class="fas fa-plus-circle"></i>
        {{ 'cc.jobs.view.create-job' | translate }}
      </button>
    </span>
  </div>

  <!--Table-->
  <div class="col-md-12">
    <mc-table
      *ngIf="isContentLoaded"
      [tableContent]="tableContent"
      [entityId]="entityId"
      (paginationEvent)="onPagination($event)"
      (onSendStateToParentComponent)="updateJobAction($event)"
    ></mc-table>

  </div>

</div><!--Jobs wrapper /end-->

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
