import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { McBoolean, McCreateUserService, McEntityService, McGod, McProfileUserService } from '@miticon-ui/mc-core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';
import { McTableService } from '@miticon-ui/mc-components';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-mc-edit-user-wrapper',
  templateUrl: './mc-edit-user-wrapper.component.html'
})
export class McEditUserWrapperComponent implements OnInit {

  // Variables
  userImage: any;
  userId: any;
  userStatus!: string;
  userEmail!: string;
  userFirstname!: string;
  userLastname!: string;
  userNickname!: string;
  userGender!: string;
  userLanguage!: string;
  userTimezone: any;
  userCountry!: string;
  lastChildEntity!: string;
  responseMessage!: boolean;
  message!: string;
  type!: string;
  selectedEntityPath!: string[];
  userRoles!: [];
  userPersonalData!: any;
  showLoader!: boolean;
  userCanEditUsersPermission: any;
  isEnabledTwoFa!: boolean;
  mcGod = McGod.getInstance();
  twoFaAuth!: string;
  resendUserMailMcb = new McBoolean();

  constructor(
    private activatedRoute: ActivatedRoute,
    private createUserService: McCreateUserService,
    private entityService: McEntityService,
    private modalService: NgbModal,
    private location: Location,
    private profileUserService: McProfileUserService,
    private toastr: ToastrService,
    @Inject('designConfig') design: any,
    @Inject('allPermissions') allPermissions: any,
  ) {
    /*Set permissions*/
    this.userCanEditUsersPermission = [allPermissions.MC_SYSTEM_USER_CAN_UPDATE];
    this.userImage = design.userImage;
    this.userId = this.activatedRoute.snapshot.params['id'] - 0;
    this.getAllUserData(this.userId);
  }

  ngOnInit() {
    /*Get selected entity path from service*/
    this.entityService._selectedEntityPath.subscribe(path => {
      this.selectedEntityPath = path;
      this.lastChildEntity = this.selectedEntityPath.slice(-1)[0];
    });

    this.profileUserService.getUserData(this.userId).subscribe(
      (response) => {
        this.isEnabledTwoFa = response.is2faEnabled;
        if (response.is2faEnabled) {
          this.twoFaAuth = McGod.t('cc.common.edit.enabled');
        } else {
          this.twoFaAuth = McGod.t('cc.common.view.disabled');
        }
      });
  }

  // Get all data for specific user by id
  getAllUserData(userId: number) {
    this.showLoader = true;
    this.createUserService.getAllUserDataById(userId).subscribe(
      (response) => {
        this.userPersonalData = response;
        this.userStatus = response.statusCode.toLowerCase();
        this.userEmail = response.email;
        this.userFirstname = response.firstname;
        this.userLastname = response.lastname;
        this.userNickname = response.nickname;
        this.userGender = response.gender;
        this.userLanguage = response.isoLanguage;
        this.userTimezone = response.timezone;
        this.userCountry = response.isoCountry;
        this.userImage = response.image;
        this.userRoles = response.roles;
        this.showLoader = false;
        if (this.userImage === undefined) {
          this.userImage = 'https://image.shutterstock.com/image-vector/empty-photo-male-profile-gray-260nw-538707310.jpg';
        }
      },
      (error) => {
        this.apiErrorHandler(error);
        this.showLoader = false;
      }
    );
  }

  // Modals
  // Personal info modal
  openPersonalInfoModal(personalInfoEditModal: any) {
    this.modalService.open(personalInfoEditModal, {
      backdrop: 'static',
      centered: true,
      windowClass: 'mc-create-new-user-modal'
    });
  }

  // Edit roles modal
  openEditRolesModal(editRolesModal: any) {
    this.modalService.open(editRolesModal, {
      backdrop: 'static',
      centered: true,
      windowClass: 'mc-create-new-user-modal'
    });
  }


  onSuccessEditPersonalData(event: any) {
    if (event === true) {
      this.getAllUserData(this.userId);
    }
  }

  onChange2Fa($event: any) {
    if ($event) {
      this.isEnabledTwoFa = true;
      this.twoFaAuth = McGod.t('cc.common.edit.enabled');
    } else {
      this.isEnabledTwoFa = false;
      this.twoFaAuth = McGod.t('cc.common.view.disabled');
    }
  }

  onResendEmail(value: boolean) {
    this.resendUserMailMcb.setFalse();
    this.toastr.success(`${McGod.t(
      'cc.user.the-invitation-was-successfully-resent'
    )} ${McGod.t('cc.pricelist.for')} ${this.userPersonalData.firstname} ${
      this.userPersonalData.lastname
    }.`);
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  private apiErrorHandler(error: any) {
    let message = '';
    let msgType = '';
    if (error.status >= 500) {
      message = 'Server error';
      msgType = 'error';
    } else if (error.error) {
      if (error.error.errors.length > 1) {
        message = error.error.errors.join();
        msgType = 'error';
      } else {
        message = error.error.errors;
        msgType = 'error';
      }
    } else {
      message = 'Invalid request';
      msgType = 'error';
    }
    this.responseMessage = true;
    this.setNotificationMessage(message, msgType);
  }
}
