
import {Table3Config} from '../extra/table-3-config';

// import {EbPaymentReportGENERATED} from '../_generated/eb-payment-report-generated.model';

export class EbPaymentReportTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDetails', 'Xml sepa record', 'details', 'details');
    this.addColumn('colErrorReason', 'Error reason', 'errorReason', 'errorReason');
    this.addColumn('colFlgExported', 'Flag exported', 'flgExported', 'flgExported');
    this.addColumn('colIdEbReport', 'Eb bank report Id', 'idEbReport', 'idEbReport');
    this.addColumn('colImportTypeCd', 'Import type', 'importTypeCd', 'importTypeCd');
    this.addColumn('colMatchingType', 'Matching type', 'matchingType', 'matchingType');
    this.addColumn('colStatus', 'Status', 'status', 'status');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colTransactionDate', 'Transaction date', 'transactionDate', 'transactionDate');
    this.addColumn('colType', 'Payment type', 'type', 'type');

*/
  }
}
