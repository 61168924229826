import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {McProcess} from "@miticon-ui/mc-core";
import {
  McForm,
  McBoolean,
  McGod,
  McTrigger,
  McValueLabelList
} from "@miticon-ui/mc-core";
import {Router, ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {Subscription} from "rxjs";

@Component({
  selector: "mc-process-process-add-edit-part",
  templateUrl: "./mc-process-process-add-edit-part.component.html",
  styleUrls: ["./mc-process-process-add-edit-part.component.scss"]
})
export class McProcessProcessAddEditPartComponent implements OnInit {

  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  errorMsgs: string[] = [];

  // -- Input --
  @Input() mcProcessId!: number;
  @Input() mcProcess!: McProcess;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;


  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();
  processTypeVlm = new Map<string, string>();
  statusVlm = new Map<string, string>();
  inheritableVll = new McValueLabelList();
  mcProcessStatusActive = McProcess.STATUS_CD_ACTIVE;

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.processTypeVlm.set(McProcess.TYPE_CD_DUNNING, 'Dunning');
    this.processTypeVlm.set(McProcess.TYPE_CD_MARKETING, 'Marketing');
    this.processTypeVlm.set(McProcess.TYPE_CD_NOTIFICATIONS, 'Notifications');
    this.statusVlm.set(McProcess.STATUS_CD_ACTIVE, 'Active');
    this.statusVlm.set(McProcess.STATUS_CD_INACTIVE, 'Inactive');
    this.inheritableVll.add(true, 'Yes');
    this.inheritableVll.add(false, 'No');

    if (!this.mcProcess) {
      this.mcProcess = new McProcess();
    }

    this.actLoad();

  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    if (!this.mcProcess) {
      this.mcProcess = new McProcess();
      if (this.mcProcessId > 0) {
        this.mcProcess.loadById(this.mcProcessId, () => {
          this.validatePrerequisites();
        });
      }
    } else {
      // new item
      this.validatePrerequisites();
    }

  }

  // ---------------------------------------------------------------------
  actSave() {

    if (this.mcForm.isValid()) {
      this.mcProcess.idMcEntity = McGod.getLoggedEntityIdFromToken();
      this.mcProcess.sysCreatedUserId = McGod.getLoggedUserIdFromToken();
      this.mcProcess.title = this.mcForm.getValue("ctrlName");
      this.mcProcess.description = this.mcForm.getValue("ctrlDescription");
      this.mcProcess.typeCd = this.mcForm.getValue("ctrlProcessType");
      this.mcProcess.statusCd = this.mcForm.getValue("ctrlStatus");
      this.mcProcess.inheritableFlg = this.mcForm.getValue("ctrlInheritable");


      this.mcProcess.save(() => {
        this.eventSaved.emit();
      }, () => {
        this.saveButtonDisabledMcb.setFalse();
      });
    } else {
      this.saveButtonDisabledMcb.setFalse();
    }

  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.mcProcess.exists()) {

      this.mcProcess.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

// ---------------------------------------------------------------------
  validatePrerequisites() {
    this.errorMsgs = [];
    // populate validation here on required inputs
  }

  // ---------------------------------------------------------------------
  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }
}
