
export class McBoolean {
  public value = false;

  // ---------------------------------------------------------------------
  public toggle() {
    this.value = !this.value;
  }

  // ---------------------------------------------------------------------
  public setFalse() {
    this.value = false;
  }
  // ---------------------------------------------------------------------
  public setTrue() {
    this.value = true;
  }

  // ---------------------------------------------------------------------
  public setValue(aValue: boolean) {
    this.value = aValue;
  }
}
