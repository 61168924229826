import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {
  McDateUtils,
  McEntity2,
  McForm,
  McGod,
  McUser, PmTransaction, PmTransactionList
} from '@miticon-ui/mc-core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-shared-pause-transactions',
  templateUrl: './app-shared-pause-transactions.component.html',
  styleUrls: ['./app-shared-pause-transactions.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppSharedPauseTransactionsComponent implements OnInit {

  selectedTransactionsNumber!: number;
  selectedTransactionsAmount!: number;
  mcForm = new McForm();
  numbers = [];
  isTransactionPaused!: boolean;
  mcEntity = new McEntity2();
  maxDaysForPausingTransactions!: number;
  maxDateToPauseTransaction!: number;
  minDateToPauseTransaction!: number;
  errorMsg = '';
  mcGod = McGod.getInstance();
  minimalDateIsBiggerThenMaximalDate = false;
  mcUser = new McUser();

  @Input() pmTransactionList!: PmTransactionList;
  @Input() pmTransaction!: PmTransaction;
  pmTransactionTemp = new PmTransaction();

  @Output() eventPauseTransactionCanceled = new EventEmitter<boolean>();
  @Output() eventPausedTransaction = new EventEmitter<boolean>();
  @Output() eventUnpausedTransaction = new EventEmitter<boolean>();

  constructor(private router: Router) {
  }


  ngOnInit() {
    this.selectedTransactionsAmount = 0;
    if (this.pmTransactionList.getCount() === 1) {
      this.pmTransaction = this.pmTransactionList.items[0];
      this.mcUser.loadById(this.pmTransaction.lastPauseUnpauseByUserId);
      this.isTransactionPaused = this.pmTransaction.flgPaused;
      this.selectedTransactionsAmount = this.pmTransaction.amountDue;
      this.maxDaysForPausingTransactions = this.pmTransaction.mcEntity.maxTransactionPauseDays;
      const entityMaxPausedDays = this.pmTransaction.mcEntity.maxTransactionPauseDays;
      const pausedDaysCount = this.pmTransaction.pausedDaysCount;
      const days = entityMaxPausedDays - pausedDaysCount;
      if (days < 0) {
        this.maxDateToPauseTransaction = McDateUtils.getNowTimestamp();
      } else {
        this.maxDateToPauseTransaction = McDateUtils.addOrSubtractDateToNumber(McDateUtils.getNowTimestamp(), 'add', days, 'days');
      }
      if ((this.mcEntity.maxTransactionPauseDays === this.pmTransaction.pausedDaysCount) && !this.pmTransaction.flgPaused) {
        this.errorMsg = `This transaction has reached maximum pause days.`;
        this.minimalDateIsBiggerThenMaximalDate = true;
      } else {
        this.minDateToPauseTransaction = McDateUtils.addOrSubtractDateToNumber(McDateUtils.getNowTimestamp(), 'add', 0, 'days');
        if ((this.minDateToPauseTransaction > this.maxDateToPauseTransaction) && !this.pmTransaction.flgPaused) {
          this.errorMsg = 'You cannot pause this transaction because minimal date is bigger than maximal date';
          this.minimalDateIsBiggerThenMaximalDate = true;
        }
      }
      this.pmTransactionTemp = this.pmTransaction;
    } else {
      this.selectedTransactionsNumber = this.pmTransactionList.getCount();
      this.isTransactionPaused = this.pmTransactionList.getLastItem().flgPaused;
      this.pmTransactionList.items.forEach(item => {
        this.selectedTransactionsAmount += item.amountDue;
      });
      this.mcEntity.loadById(this.pmTransactionList.getLastItem().idMcEntity, () => {
        this.maxDaysForPausingTransactions = this.mcEntity.maxTransactionPauseDays;
        // Validation for pause days is done on BE
      });
    }


  }

  actCancel() {
    this.eventPauseTransactionCanceled.emit(true);
  }

  actPause() {
    if (this.mcForm.isValid()) {
      const pauseReason = this.mcForm.getValue('ctrlReason');
      const pauseEndDate = this.mcForm.getValue('ctrlPauseEndDate');

      if (this.pmTransactionList.getCount() === 1) {
        this.pmTransactionTemp.callPauseTransaction([this.pmTransaction.id], pauseEndDate, pauseReason, () => {
          this.eventPausedTransaction.emit(true);
        }, () => {
          this.errorMsg = this.pmTransactionTemp.apiErrorMessage;
        });
      } else {
        this.pmTransactionList.callPauseTransaction(this.pmTransactionList.getIds(), pauseEndDate, pauseReason, () => {
          this.eventPausedTransaction.emit(true);
        }, () => {
          this.errorMsg = this.pmTransactionList.apiErrorMessage;
        });
      }
    }

  }

  actUnpause() {
    if (this.mcForm.isValid()) {
      const unpauseReason = this.mcForm.getValue('ctrlReason');
      if (this.pmTransactionList.getCount() === 1) {
        this.pmTransactionTemp.callUnpauseTransaction(this.pmTransactionList.getIds(), unpauseReason, () => {
          this.eventUnpausedTransaction.emit(true);
        }, () => {
          this.errorMsg = this.pmTransactionTemp.apiErrorMessage;
        });
      } else {
        this.pmTransactionList.callUnpauseTransaction(this.pmTransactionList.getIds(), unpauseReason, () => {
          this.eventUnpausedTransaction.emit(true);
        }, () => {
          this.errorMsg = this.pmTransactionList.apiErrorMessage;
        });
      }
    }
  }

  actGoToEntitySettings() {
    this.router.navigate([`entity/${McGod.getLoggedEntityIdFromToken()}/entity-profile`], {queryParams: {activeTab: 'settingsTab'}});
  }
}
