import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'mc-entity-popup-buttons',
  templateUrl: './mc-entity-popup-buttons.component.html',
})
export class McEntityPopupButtonsComponent implements OnInit {

  /*Constructor*/
  constructor() { }

  /*On init class*/
  ngOnInit() {
  }
}
