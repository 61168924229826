
import {Table3Config} from '../extra/table-3-config';

// import {McBillingPayoutFile2StatementGENERATED} from '../_generated/mc-billing-payout-file-2-statement-generated.model';

export class McBillingPayoutFile2StatementTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colNumberOfStatements', 'Number Of Statements', 'numberOfStatements', 'numberOfStatements');
    this.addColumn('colTotalFileAmount', 'Total File Amount', 'totalFileAmount', 'totalFileAmount');
    this.addColumn('colPayoutDate', 'Payout Date', 'payoutDate', 'payoutDate');
    this.addColumn('colStatusCd', 'Status Cd', 'statusCd', 'statusCd');
    this.addColumn('colFileName', 'File Name', 'fileName', 'fileName');
    this.addColumn('colXlsWebfileId', 'XLS Webfile ID', 'xlsWebfileId', 'xlsWebfileId');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');

*/
  }
}
