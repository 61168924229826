import { Component, OnInit } from '@angular/core';
import {
  CcMollieCustomerService, CsrConsumer,
  CsrContract,
  CsrContractService, CurrencyUtil, EbFactoringService,
  McConsumer,
  McDateUtils,
} from '@miticon-ui/mc-core';
import {ActivatedRoute} from '@angular/router';
import {take} from 'rxjs/operators';

@Component({
  selector: 'mc-consumer-contract-profile-page',
  templateUrl: './mc-consumer-contract-profile-page.component.html',
  styleUrls: ['./mc-consumer-contract-profile-page.component.scss']
})
export class McConsumerContractProfilePageComponent implements OnInit {

  csrContract = new CsrContract();
  csrConsumer = new CsrConsumer();
  contractId!: number;
  totalAmount!: string;
  totalNetto = 0;
  currency!: string;
  sendInvitationEmailFlg!: boolean;

  daysBeforeExpiration!: number;

  constructor(private route: ActivatedRoute, private csrContractService: CsrContractService, private ccMollieCustomerService: CcMollieCustomerService,
              private ebFactoringService: EbFactoringService ) { }

  ngOnInit() {
    this.contractId = this.route.snapshot.params['id'];
    this.currency = this.ebFactoringService.activeChildFactoring.mcCurrency.code;

    this.actLoad();
  }

  public actLoad(): void {
    this.csrContract.loadById(this.contractId, () => {
      this.daysBeforeExpiration = McDateUtils.subtractTwoDates(this.csrContract.endDate, this.csrContract.cancellationDeadline);
      this.totalAmount = CurrencyUtil.format(this.totalNetto, this.currency);
      this.csrConsumer.loadById(this.csrContract.idCsrConsumer);
      this.ccMollieCustomerService.getByContractId(this.csrContract.id).subscribe(
        (response) => {
          const isExpireDateTimeBiggerThanToday = new Date(response.expireDatetime) > new Date();
          if (isExpireDateTimeBiggerThanToday) {
            this.sendInvitationEmailFlg = true;
          } else {
            this.sendInvitationEmailFlg = false;
          }
        },
        () => this.sendInvitationEmailFlg = false
      );
    });
  }

  sendInvitationEmail() {
    this.csrContractService.sendInvitationEmail({idCsrConsumer: this.csrContract.idCsrConsumer, idCsrContract: this.csrContract.id})
      .pipe(take(1))
      .subscribe();
  }

  isSendInvitationMailEnabled(): boolean {
    return this.csrContract && this.csrContract.paymentDetails &&
      this.csrContract.paymentDetails.isTypeCdCREDIT_CARD() &&
      this.csrContract.isStatusCdNEW();
  }

  format(value: number): string {
    if (value) {
      return CurrencyUtil.format(value, this.currency);
    }
    return CurrencyUtil.format(0, this.currency);
  }
}
