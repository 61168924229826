import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BaseObject,
  EbFactoring,
  EbFactoringRequestType,
  EbFactoringRequestTypeList,
  McBoolean,
  McForm
} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-factoring-request-type-add-edit-part',
  templateUrl: './app-shared-factoring-request-type-add-edit-part.component.html',
  styleUrls: ['./app-shared-factoring-request-type-add-edit-part.component.scss']
})
export class AppSharedFactoringRequestTypeAddEditPartComponent implements OnInit {

  @Input() ebFactoring!: EbFactoring;
  @Input() mcForm!: McForm;
  @Input() idChildEntity!: number;
  @Input() entityForCloningId!: number;
  @Input() ebFactoringIdForCloning!: number;
  @Input() selectedEntityId!: number;
  @Input() factoringRequestTypeList!: EbFactoringRequestTypeList;
  isVisible!: McBoolean;
  showAddRequestTypePart = new McBoolean();
  isAddRequestTypeCanceled!: boolean;
  showNowFlg = false;
  counter = -1;
  checkedRequests: any[] = [];
  checkedItems = new EbFactoringRequestTypeList();
  loadingRequestCounter = 0;

  @Output() factoringRequestTypesSaved = new EventEmitter();
  @Input() isContractCloned!: boolean;
  selectedFactoringRequestTypeList = new EbFactoringRequestTypeList();

  ngOnInit() {
    this.isVisible = new McBoolean();
    this.loadRequestTypeList();
  }

  openAddRequestTypeModal() {
    this.showAddRequestTypePart.setTrue();
  }

  onRequestTypeSaved() {
    this.loadRequestTypeList();
    this.showAddRequestTypePart.setFalse();
    this.factoringRequestTypesSaved.emit();
  }

  onRequestTypeCanceled() {
    this.showAddRequestTypePart.setFalse();
  }

  addRequestTypeModalCanceled() {
    this.isAddRequestTypeCanceled = true;
  }

  loadRequestTypeList() {
    if (!this.isContractCloned) {
      this.factoringRequestTypeList.loadByChildEntityId(this.idChildEntity, () => {
        this.factoringRequestTypeList.setSortB('title', 'ASC');
        this.showNowFlg = true;
      });
    }

    if (this.isContractCloned) {
      if (this.idChildEntity) {
        // this.checkedRequests = [];
        this.showNowFlg = false;
        this.factoringRequestTypeList.loadByChildEntityId(this.idChildEntity, () => {
          if (this.ebFactoringIdForCloning) {
            this.ebFactoring.loadById(this.ebFactoringIdForCloning, () => {

                if (this.ebFactoring.requestTypes) {

                  this.ebFactoring.requestTypes.items.forEach(item => {
                    const itemWithSameTitle = this.factoringRequestTypeList.items.find(req => item.title === req.title);
                    if (!itemWithSameTitle) {
                      const reqItem = item;
                      reqItem.id = this.counter--;
                      this.factoringRequestTypeList.addItem(reqItem);
                      if (this.loadingRequestCounter === 0) {
                        this.checkedRequests.push(reqItem.id);
                        this.checkedItems.addItem(reqItem);
                      }
                    } else {
                      if (this.loadingRequestCounter === 0) {
                        this.checkedRequests.push(itemWithSameTitle.id);
                        this.checkedItems.addItem(itemWithSameTitle);
                      }
                    }
                  });

                  if (this.loadingRequestCounter > 0) {
                    this.checkedRequests = [];
                    this.checkedItems.items.forEach( item => {
                      const itemWithSameTitleCheckedItems = this.factoringRequestTypeList.items.find(req => item.title === req.title);
                      this.checkedRequests.push(itemWithSameTitleCheckedItems ? itemWithSameTitleCheckedItems.id : '');
                    });
                  }
                  this.showNowFlg = true;
                  this.loadingRequestCounter++;

                } else {

                }
            });
          }
        });
      }
    }
  }

  checkedRequstTypesChanged(checkedList: any) {
    this.checkedItems = new EbFactoringRequestTypeList();
    this.checkedRequests = checkedList;
    this.checkedRequests.forEach((item: any) => {
      this.checkedItems.addItem(<BaseObject><unknown>this.factoringRequestTypeList.getItemById(Number(item)));
    });
  }
}
