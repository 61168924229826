/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EbStarmoneyFileTable3ConfigGENERATED} from '../_generated/eb-starmoney-file-table-3-config-generated.model';

// import {EbStarmoneyFileGENERATED} from '../_generated/eb-starmoney-file-generated.model';

export class EbStarmoneyFileTable3Config extends EbStarmoneyFileTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.addColumn('colStarMoneyFileName', 'StarMoney Filename', 'fldStarMoneyFileName()', 'wf.filename');
    this.addColumn('colImportDateAndTime', 'Import Date & Time', 'fldImportDateAndTime()', 'createdAt');
    this.addColumn('colWithdrawnReturnedTransactions', 'Withdrawn/Returned Transactions', 'fldWithdrawnAndReturnedTransactions()', '');
    this.addColumn('colWithdrawnReturnedAmount', 'Withdrawn/Returned Amount', 'fldWithdrawnReturnedAmount()', '').extraCssClass = 'text-right';
    this.addColumn('colNewRepeatedTransactions', 'New/Repeated Transactions', 'fldNewRepeatedTransactions()', '');
    this.addColumn('colImportedBy', 'Imported By', 'fldImportedBy()', 'user.firstname');
    this.addColumn('colStatusCd', this.t('cc.common.view.status'), 'fldStarMoneyStatus()', 'statusCd');
  }
}
