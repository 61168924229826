
import {Table3Config} from '../extra/table-3-config';

// import {EbSepaStatementReportGENERATED} from '../_generated/eb-sepa-statement-report-generated.model';

export class EbSepaStatementReportTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colClosingDate', 'Closing date', 'closingDate', 'closingDate');
    this.addColumn('colEntryCount', 'Entry count', 'entryCount', 'entryCount');
    this.addColumn('colIdCustomerStatement', 'Customer statement id', 'idCustomerStatement', 'idCustomerStatement');
    this.addColumn('colIdMcBankAccount', 'Bank account', 'idMcBankAccount', 'idMcBankAccount');
    this.addColumn('colIdMcCurrency', 'Currency', 'idMcCurrency', 'idMcCurrency');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdWebFile', 'Webfile ID', 'idWebFile', 'idWebFile');
    this.addColumn('colMcCurrency', 'McCurrency', 'mcCurrency', 'mcCurrency');
    this.addColumn('colOpeningDate', 'Opening date', 'openingDate', 'openingDate');
    this.addColumn('colStatementDate', 'Statement date', 'statementDate', 'statementDate');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');

*/
  }
}
