import { AppSharedTransactionPayoutItemsComponent } from './pages/app-shared-transaction-payout-items/app-shared-transaction-payout-items.component';
import { AppSharedTransactionBillingItemsComponent } from './pages/app-shared-transaction-billing-items/app-shared-transaction-billing-items.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [
{
    path: 'transaction-payout-items/:id',
    component: AppSharedTransactionPayoutItemsComponent
  },
{
    path: 'transaction-billing-items/:id',
    component: AppSharedTransactionBillingItemsComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppSharedRoutingModule {
}
