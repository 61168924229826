import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Location} from '@angular/common';
import {Observable} from 'rxjs';
import {McUtilityService} from '../services/mc-utility.service';

@Injectable({
  providedIn: 'root'
})
export class McPermissionGuard {

  permissionList: any[] = [];
  path = 'asdfasdfsdfs';

  /*Constructor*/
  constructor(private utilityService: McUtilityService,
              private router: Router,
              private location: Location,
              private activatedRoute: ActivatedRoute,
              @Inject('pagePermissions') pagePermissions: any) {

    /*Get permissions from config*/
    this.permissionList = pagePermissions;
  }

  /*Can activate*/
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    /*Check if url path has param*/
    const path = state.url.replace(/\#/g, '').replace(/\d{1,10}/g, '*');
    const pagePermission = this.permissionList.find(item => item.path === path);
    const userPermissions: any[] = this.utilityService.getAuthoritiesFromStorage();
    const entityId = this.utilityService.getPropertyFromToken('entity_id');
    /*if page doesn't have defined permission, return true*/
    if (!pagePermission || !pagePermission.permission) {
      return true;
    }
    /*Check if:
    *path exists, page permission exists, page permission has property 'permission',
    *user permissions exists, user permissions has page permission, entityId exists*/
    if (!path ||
      !pagePermission.hasOwnProperty('permission') ||
      !userPermissions ||
      !userPermissions.some(item => item === pagePermission.permission) ||
      !entityId) {

      /*navigate to dashboard page*/
      this.router.navigate([`admin/entity/${entityId}/dashboard`]);
    }

    /*User has permission*/
    return true;
  }

}
