/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McUser} from '../models/mc-user.model';
import {McUserService} from '../services/mc-user.service';
import {IMcUser} from '../_generated/mc-user.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McUserGENERATED extends BaseObject {


    public static readonly GENDER_MALE: string = 'MALE';
    public static readonly GENDER_FEMALE: string = 'FEMALE';
    public static readonly GENDER_OTHER: string = 'OTHER';
  public static readonly GENDER_LIST__ALL = [
    McUserGENERATED.GENDER_MALE,
    McUserGENERATED.GENDER_FEMALE,
    McUserGENERATED.GENDER_OTHER
];

  public apiService: McUserService;
  public _rawJson: IMcUser;
    id: number = 0;
  email = '';
  firstname = '';
  gender: string;
  idMcLogin: number;
  isoCountry = '';
  isoLanguage = '';
  lastname = '';
  nickname = '';
  timezone: number;
  title = '';


  public properties: string[] = ['id', 'email', 'firstname', 'gender', 'idMcLogin', 'isoCountry', 'isoLanguage', 'lastname', 'nickname', 'timezone', 'title'];
  public propertiesRegular: string[] = ['id', 'email', 'firstname', 'gender', 'idMcLogin', 'isoCountry', 'isoLanguage', 'lastname', 'nickname', 'timezone', 'title'];
  public propertiesSpecial: string[] = [];



  public static getGenderVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McUserGENERATED.GENDER_MALE, McGod.t('Male'));
    list.add(McUserGENERATED.GENDER_FEMALE, McGod.t('Female'));
    list.add(McUserGENERATED.GENDER_OTHER, McGod.t('Other'));


    return list;
  }

  public static getGenderLabel(aCd: string): string {
    const list = McUserGENERATED.getGenderVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McUser {
    console.error('MTCN-ERROR: Not Implemented: McUser::createNewInstance(). Add this method to final class and return new McUser();');
    throw new Error('Cannot McUser::createNewInstance(). Add this method to final class and return new McUser();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcUser): McUser {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McUserService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McUser AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getGenderLabel(): string {
    return McUserGENERATED.getGenderLabel(this.gender);
  }


 public  isGenderMALE(): boolean {
    const result = (this.gender === McUserGENERATED.GENDER_MALE);

    return result;
  }

 public  isGenderFEMALE(): boolean {
    const result = (this.gender === McUserGENERATED.GENDER_FEMALE);

    return result;
  }

 public  isGenderOTHER(): boolean {
    const result = (this.gender === McUserGENERATED.GENDER_OTHER);

    return result;
  }


  
}
