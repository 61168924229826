/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {CcMollieTransaction} from '../models/cc-mollie-transaction.model';
import {CcMollieTransactionList} from '../models/cc-mollie-transaction-list.model';
import {CcMollieTransactionFilter} from '../models/cc-mollie-transaction-filter.model';
import {CcMollieTransactionService} from '../services/cc-mollie-transaction.service';
import {ICcMollieTransaction} from '../_generated/cc-mollie-transaction.interface';
import {McGod} from "../extra/mc-god.service";

export class CcMollieTransactionListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'pmTransaction'];
  public items: CcMollieTransaction[] = [];
  public apiService: CcMollieTransactionService;
 // protected tempObjList: CcMollieTransactionList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CcMollieTransactionList {
    console.error('MTCN-ERROR: Not Implemented: CcMollieTransactionList::createNewInstance(). Add this method to final class and return new CcMollieTransactionList();');
    throw new Error('Cannot CcMollieTransactionList::createNewInstance(). Add this method to final class and return new CcMollieTransactionList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: ICcMollieTransaction[]): CcMollieTransactionList {
    const  listObj: CcMollieTransactionList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: CcMollieTransaction = CcMollieTransaction.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): CcMollieTransactionList {
    const  listObj: CcMollieTransactionList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CcMollieTransactionService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: ICcMollieTransaction[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: CcMollieTransaction = CcMollieTransaction.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aCcMollieTransactionFilter: CcMollieTransactionFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aCcMollieTransactionFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): CcMollieTransaction {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new CcMollieTransaction();
    }
  }

  public replaceItem(newItem: CcMollieTransaction) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

