/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McProcessFlowTable3ConfigGENERATED} from '../_generated/mc-process-flow-table-3-config-generated.model';

// import {McProcessFlowGENERATED} from '../_generated/mc-process-flow-generated.model';

export class McProcessFlowTable3Config extends McProcessFlowTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colTitle', this.t('cc.common.edit.title'), 'fldNameLink()', '');
    this.addColumn('colDescription', this.t('cc.common.view.description'), 'description', '');
      // this.addColumn('colInheritableFlg', 'Inheritable', 'formatInheritableFlg()', 'inheritableFlg');
    this.addColumn('colNoOfLevels', this.t('cc.processes.no-of-levels'), 'fldNoOfLevels()', '');
      // this.addColumn('colIdMcProcess', 'McProcess ID', 'idMcProcess', 'idMcProcess');
    this.addColumn('colStatusCd', this.t('cc.common.view.status'), 'fldStatus()', '');


  }
}
