<ng-template #importConsumers let-modal>

  <!--Modal header section-->
  <div class="modal-header mc-create-user-modal-header">
    <div>
      <h4 class="modal-title" id="mc-modal-new-user-title">{{'cc.consumers.consumers-overview.import-consumers' | translate}}</h4>
    </div>
    <button type="button" class="close mc-consumers-close-btn" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div><!--Modal header -->

  <!--Modal body section-->
  <div class="modal-body">
    <div class="mc-create-new-user-wrapper">
      <div class="container-fluid">

        <lib-import-consumers-step1 *ngIf="!toggleStep2" (eventUploadSuccess)="actGoToStep2()" (eventCancel)="closeModal()" [webFile]="webfile" ></lib-import-consumers-step1>

        <lib-import-consumers-step2 *ngIf="toggleStep2" (eventPrevStep)="actGoToStep1()" (eventCancel)="closeModal()" [webFile]="webfile" ></lib-import-consumers-step2>

      </div>
    </div>
  </div>

</ng-template>

<a class="mc-create-new-user-btn" (click)="openImportConsumersModal(importConsumers)">
  <i class="fas fa-plus-circle mc-add-new-user-icon"></i>
  {{'cc.consumers.consumers-overview.import-consumers' | translate}}
</a>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
