import {CsrContractProducts} from './csr-contract-products.model';
import {CsrContractProductsListGENERATED} from '../_generated/csr-contract-products-list-generated.model';

export class CsrContractProductsList extends CsrContractProductsListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrContractProductsList();
  }

  // ---------------------------------------------------------------------
}
