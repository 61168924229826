/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McProductSnapshot} from '../models/mc-product-snapshot.model';
import {McProductSnapshotService} from '../services/mc-product-snapshot.service';
import {IMcProductSnapshot} from '../_generated/mc-product-snapshot.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {McCurrency} from '../models/mc-currency.model';
import {McVat} from '../models/mc-vat.model';


export abstract class McProductSnapshotGENERATED extends BaseObject {


  public apiService: McProductSnapshotService;
  public _rawJson: IMcProductSnapshot;
    id: number = 0;
  availableFrom: number;
  availableTo: number;
  description = '';
  externalId = '';
  idCurrency: number;
  idMcEntity: number;
  idPpCategory: number;
  idPpProduct: number;
  idVat: number;
  name = '';
  sysCreatedDatetime: number;
  unitPrice: number;
  version: number;
  currency: McCurrency;  vat: McVat;

  public properties: string[] = ['id', 'availableFrom', 'availableTo', 'description', 'externalId', 'idCurrency', 'idMcEntity', 'idPpCategory', 'idPpProduct', 'idVat', 'name', 'sysCreatedDatetime', 'unitPrice', 'version', 'currency', 'vat'];
  public propertiesRegular: string[] = ['id', 'availableFrom', 'availableTo', 'description', 'externalId', 'idCurrency', 'idMcEntity', 'idPpCategory', 'idPpProduct', 'idVat', 'mcCurrenty', 'mcVat', 'name', 'ppProduct', 'sysCreatedDatetime', 'unitPrice', 'version'];
  public propertiesSpecial: string[] = ['currency', 'vat'];


  // ---------------------------------------------------------------------
public static createNewInstance(): McProductSnapshot {
    console.error('MTCN-ERROR: Not Implemented: McProductSnapshot::createNewInstance(). Add this method to final class and return new McProductSnapshot();');
    throw new Error('Cannot McProductSnapshot::createNewInstance(). Add this method to final class and return new McProductSnapshot();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcProductSnapshot): McProductSnapshot {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProductSnapshotService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McProductSnapshot AABB()');
          if (this._rawJson['currency']) {
        this.currency = McCurrency.createFromJson(this._rawJson['currency']);
      }
      if (this._rawJson['vat']) {
        this.vat = McVat.createFromJson(this._rawJson['vat']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getUnitPriceStr(): string {
       return McGod.formatNumberToMoney(this.unitPrice);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('currency')) {
        if (this.currency != null) {
            dto['currency'] = this.currency.toDto();
        } else {
            dto['currency'] = null;
        }
    }
    if (this.hasOwnProperty('vat')) {
        if (this.vat != null) {
            dto['vat'] = this.vat.toDto();
        } else {
            dto['vat'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
