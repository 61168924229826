/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbInterest_rate} from '../models/eb-interest_rate.model';
import {EbInterest_rateService} from '../services/eb-interest_rate.service';
import {IEbInterest_rate} from '../_generated/eb-interest_rate.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class EbInterest_rateGENERATED extends BaseObject {


    public static readonly TYPE_CD_REFERENT_INTEREST_RATE: string = 'REFERENT_INTEREST_RATE';
    public static readonly TYPE_CD_DEFAULT_PERSON_INTEREST_RATE: string = 'DEFAULT_PERSON_INTEREST_RATE';
    public static readonly TYPE_CD_DEFAULT_COMPANY_INTEREST_RATE: string = 'DEFAULT_COMPANY_INTEREST_RATE';
  public static readonly TYPE_CD_LIST__ALL = [
    EbInterest_rateGENERATED.TYPE_CD_REFERENT_INTEREST_RATE,
    EbInterest_rateGENERATED.TYPE_CD_DEFAULT_PERSON_INTEREST_RATE,
    EbInterest_rateGENERATED.TYPE_CD_DEFAULT_COMPANY_INTEREST_RATE
];

  public apiService: EbInterest_rateService;
  public _rawJson: IEbInterest_rate;
    id: number = 0;
  description = '';
  endDatetime: number;
  interestRate: number;
  startDatetime: number;
  title = '';
  typeCd: string;


  public properties: string[] = ['id', 'description', 'endDatetime', 'interestRate', 'startDatetime', 'title', 'typeCd'];
  public propertiesRegular: string[] = ['id', 'description', 'endDatetime', 'interestRate', 'startDatetime', 'title', 'typeCd'];
  public propertiesSpecial: string[] = [];



  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbInterest_rateGENERATED.TYPE_CD_REFERENT_INTEREST_RATE, McGod.t('Referent Interest Rate'));
    list.add(EbInterest_rateGENERATED.TYPE_CD_DEFAULT_PERSON_INTEREST_RATE, McGod.t('Default Person Interest Rate'));
    list.add(EbInterest_rateGENERATED.TYPE_CD_DEFAULT_COMPANY_INTEREST_RATE, McGod.t('Default Company Interest Rate'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = EbInterest_rateGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): EbInterest_rate {
    console.error('MTCN-ERROR: Not Implemented: EbInterest_rate::createNewInstance(). Add this method to final class and return new EbInterest_rate();');
    throw new Error('Cannot EbInterest_rate::createNewInstance(). Add this method to final class and return new EbInterest_rate();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbInterest_rate): EbInterest_rate {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbInterest_rateService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbInterest_rate AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getEndDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.endDatetime);
    }

    public getEndDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.endDatetime);
    }

    public getInterestRateStr(): string {
       return McGod.formatNumberToMoney(this.interestRate);
    }

    public getStartDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.startDatetime);
    }

    public getStartDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.startDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getTypeCdLabel(): string {
    return EbInterest_rateGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdREFERENT_INTEREST_RATE(): boolean {
    const result = (this.typeCd === EbInterest_rateGENERATED.TYPE_CD_REFERENT_INTEREST_RATE);

    return result;
  }

 public  isTypeCdDEFAULT_PERSON_INTEREST_RATE(): boolean {
    const result = (this.typeCd === EbInterest_rateGENERATED.TYPE_CD_DEFAULT_PERSON_INTEREST_RATE);

    return result;
  }

 public  isTypeCdDEFAULT_COMPANY_INTEREST_RATE(): boolean {
    const result = (this.typeCd === EbInterest_rateGENERATED.TYPE_CD_DEFAULT_COMPANY_INTEREST_RATE);

    return result;
  }


  
  // ---------------------------------------------------------------------
  public loadByTypeCdForDateTime( typeCd: string, refDateTime: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByTypeCdForDateTime(typeCd,refDateTime)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

}
