import {Inject, Injectable} from '@angular/core';
import {CsrRecurringDetailsServiceGENERATED} from '../_generated/csr-recurring-details-generated.service';



@Injectable({
  providedIn: 'root'
})
export class CsrRecurringDetailsService extends CsrRecurringDetailsServiceGENERATED {

}
