<!--Notify message wrapper-->
<div *ngIf="cssClass" class="mc-notify-message-wrapper">

  <!--Message section-->
  <p class="text-center p-1 {{cssClass}}">
    <i *ngIf="type === 'error'" class="fa fa-exclamation-circle"></i>
    {{message}}
    <a *ngIf="link && link.path" class="text-primary mc-cursor-pointer" (click)="navigateTo()">link.</a>
  </p>

</div><!-- end ./wrapper-->
