import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {McCreateUserService, userEnums} from '@miticon-ui/mc-core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'lib-mc-user-roles-data',
  templateUrl: './mc-user-roles-data.component.html'
})
export class McUserRolesDataComponent implements OnInit {

  // Variables
  roleDataTab: boolean;
  lockSaveBtn!: boolean;
  responseMessage!: boolean;
  saveResponseMessage!: boolean;
  message!: string;
  type!: string;
  allRoles: any[] = [];
  selectedRoles: any[] = [];
  showLoader!: boolean;
  editedUserId!: number;
  searchedRoles: any[] = [];

  @Input() lastChildEntity!: string;
  @Input() modalArea!: any;
  @Input() userRoles!: any;

  @Output() refreshUserDataEvent = new EventEmitter<boolean>();

  // Forms
  @Input() parentForm!: FormGroup;
  stepFormRoles!: FormGroup;
  showSelectedRolesForm: FormGroup;
  items!: FormArray;
  stepFormRolesRaw: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private createUserService: McCreateUserService,
    private modalService: NgbModal
  ) {
    this.roleDataTab = true;
    this.createForm();
    this.showSelectedRolesForm = this.fb.group({
      showSelected: false
    });
  }

  ngOnInit() {
    if (this.modalArea === 'createUser') {
      this.parentForm.setControl('step2', this.stepFormRoles);
    } else {
      this.autoselectUserRoles();
    }

    this.getAllRoles();
    this.stepFormRolesRaw = this.stepFormRoles.getRawValue();
    this.editedUserId = this.activatedRoute.snapshot.params['id'] - 0;

    /* Show Roles by checkbox*/
    this.showSelectedRolesForm.valueChanges.subscribe(() => {
      this.saveResponseMessage = false;

      if (this.showSelectedRolesForm.controls['showSelected'].value === true) {
        this.searchedRoles = this.allRoles.filter(item => item.selected === true);

        if (this.searchedRoles.length > 0) {
          this.responseMessage = false;
          this.setNotificationMessage('', '');
          this.createForm();
          this.searchedRoles.forEach(item => {
            this.addItem(item.id, item.caption, item.selected, item.entity.name);
          });
        } else {
          this.createForm();
          this.responseMessage = true;
          const msg = userEnums.userNoSelectedRoles;
          const msgType = `success`;
          this.setNotificationMessage(msg, msgType);
        }
      } else {
        this.responseMessage = false;
        this.setNotificationMessage('', '');
        this.createForm();
        this.allRoles.forEach(item => {
          this.addItem(item.id, item.caption, item.selected, item.entity.name);
        });
      }
      this.parentForm.setControl('step2', this.stepFormRoles);
    });
    /* Show Roles by checkbox #*/
  }

  // Get all roles - api call
  getAllRoles() {
    this.showLoader = true;
    const body = {};
    this.createUserService.getAllRolesService(body).subscribe(
      (response) => {
        this.allRoles = response.content;
        this.allRoles.forEach(item => {
          this.addItem(item.id, item.caption, item.selected = false, item.entity.name);
        });
        if (this.modalArea !== 'createUser') {
          this.autoselectUserRoles();
        }
        this.showLoader = false;
      },
      (error) => {
        this.apiErrorHandler(error);
        this.showLoader = false;
      }
    );
  }

  // Create StepForm
  createForm() {
    this.stepFormRoles = this.fb.group({
      selRoles: this.fb.array([])
    });
  }

  // Dynamically add checkboxes - 1
  addRolesControls(roleId: number, role: string, roleSelected: boolean, entityName: string) {
    return this.fb.group({
      id: roleId,
      name: role,
      selected: roleSelected,
      entity: entityName
    });
  }

  // Dynamically add checkboxes - 2
  addItem(roleId: number, role: string, selected: boolean, entityName: string): void {
    this.items = this.stepFormRoles.get('selRoles') as FormArray;
    this.items.push(this.addRolesControls(roleId, role, selected, entityName));
  }

  // Dynamically add checkboxes - 3
  get rolesArray(): any {
    return this.stepFormRoles.get('selRoles') as FormArray;
  }

  // Select Role
  selectRole(roleId: number) {
    // @ts-ignore
    this.selectedRoles = this.allRoles.map(item => {
      if (item.id === roleId) {
        item.selected = !item.selected;
      }
      return item;
      // @ts-ignore
    }).filter((item: any) => {
      if (item.selected) {
        return item.selected === true;
      }
    });
  }

  /* Search Roles by search field*/
  onSearchRoles(e: Event): void {
    const searched = (e.target as HTMLInputElement).value;
    let searchedRoles = [];

    if (searched !== '') {
      searchedRoles = this.allRoles.filter(item => item.caption.toLowerCase().includes(searched.toLowerCase()));
      this.createForm();
      searchedRoles.forEach(item => {
        this.addItem(item.id, item.caption, item.selected, item.entity.name);
      });
    } else {
      this.createForm();
      this.allRoles.forEach(item => {
        this.addItem(item.id, item.caption, item.selected, item.entity.name);
      });
    }
    this.parentForm.setControl('step2', this.stepFormRoles);
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  // Api ErrorHandler
  private apiErrorHandler(error: any) {
    let message = '';
    let msgType = '';
    if (error.status >= 500) {
      message = userEnums.userServerError;
      msgType = 'error';
    } else if (error.error) {
      if (error.error.errors.length > 1) {
        message = error.error.errors.join();
        msgType = 'error';
      } else {
        message = error.error.errors;
        msgType = 'error';
      }
    } else {
      message = userEnums.userRequestError;
      msgType = 'error';
    }
    this.responseMessage = true;
    this.setNotificationMessage(message, msgType);
  }

  /* Edit user roles section */
  // Select roles
  autoselectUserRoles() {
    this.createForm();
    this.allRoles.forEach(item => {
      this.userRoles.forEach((i: any) => {
        if (item.id === i.id) {
          item.selected = true;
        }
      });
      this.addItem(item.id, item.caption, item.selected, item.entity.name);
    });
    // update selected role counter
    // @ts-ignore
    this.selectedRoles = this.allRoles.filter(item => {
      if (item.selected) {
        return item.selected === true;
      }
    });
  }

  // save edited roles
  saveRoles() {
    this.showLoader = true;
    const form = this.stepFormRoles.value;
    const selectedRoles = form.selRoles
      // @ts-ignore
      .filter(item => item.selected === true)
      // @ts-ignore
      .map(item => item.id);
    const body = {
      userId: this.editedUserId,
      roleIds: selectedRoles
    };

    this.createUserService.saveEditedUserRoles(body).subscribe(
      (response) => {
        this.saveResponseMessage = true;
        this.setNotificationMessage(userEnums.userRolesEdited, 'success');
        this.refreshUserDataEvent.emit(true);
        setTimeout(() => {
          this.modalService.dismissAll('Cross click');
        }, 3000);
        this.showLoader = false;
      },
      (error) => {
        this.apiErrorHandler(error);
        this.showLoader = false;
      }
    );
  }


}
