/*BbmTestObj*/
import { EbSepaFileTransactionGENERATED } from "../_generated/eb-sepa-file-transaction-generated.model";
import { McDateUtils } from "../common/mc-date-utils";
import { McGod } from "../extra/mc-god.service";
import { McHtmlActionSet } from "../_core/mc-html-action-set";
import { McValueLabelList } from "../_core/mc-value-label-list";
import { ActionOptions } from "../_core/mc-html-action-options";
import { CurrencyUtil } from "../common/currency-util";

export class EbSepaFileTransaction extends EbSepaFileTransactionGENERATED {
  public static ACTION_VIEW_DETAILS = "VD";
  public static ACTION_RECORD_PAYMENT = "RP";
  public static ACTION_EXPORT = "EXPORT";
  public static ACTION_MARK_AS_SENT_TO_INKASSO = "MARK-AS-SENT-TO-INKASSO";
  public static ACTION_MARK_AS_RETURNED_FROM_INKASSO =
    "MARK-AS-RETURNED-FROM-INKASSO";
  public static ACTION_EDIT = "EDIT";
  public static ACTION_VIEW_CONSUMER_PROFILE = "VIEW-CONSUMER-PROFILE";
  public static ACTION_SETTLE_WITH_BALANCE = "SETTLE-WITH-BALANCE";
  public static ACTION_VIEW_CONSUMER_TRANSACTION = "VIEW-CONSUMER-TRANSACTION";
  public static ACTION_VIEW_BILLING_ITEMS = "VIEW-BILLING-ITEMS";
  public static ACTION_VIEW_PAYOUT_ITEMS = "VIEW-PAYOUT-ITEMS";
  public static ACTION_CHANGE_COLLECTION_DATE = "CHANGE-COLLECTION-DATE";
  public static ACTION_PAUSE_TRANSACTIONS = "PAUSE-TRANSACTIONS";
  public static ACTION_UNPAUSE_TRANSACTIONS = "UNPAUSE-TRANSACTIONS";
  public static ACTION_STORNO = "STORNO";

  private _fldActions: McHtmlActionSet | null = null;
  private _fldActionsMyFactoring: McHtmlActionSet | null = null;
  private _fldActionsConsumerTransaction: McHtmlActionSet | null = null;

  public currencyCode!: string;
  public amountStr = "";
  public netFeeAmountStr = "";
  public paymentAmountStr = "";

  /*  public contractNumber: string;
    public exportedMandateId: string;
    public consumerFirstName: string;
    public consumerLastName: string;
    public factoringNetFeeAmount: any;
    public factoringNetFeeVatAmount: any;
    public paymentAmount: any;
    public factoringAccepted: number;
    public waitingPeriod: string;
    public reason: string;*/

  public static override createNewInstance() {
    return new EbSepaFileTransaction();
  }

  public static override getStatusCdVll() {
    // FE dont need statuses like overwritten etc...
    const list = new McValueLabelList();
    list.add(
      EbSepaFileTransactionGENERATED.STATUS_CD_ACCEPTED,
      McGod.t("cc.eb-factoring.accepted")
    );
    list.add(
      EbSepaFileTransactionGENERATED.STATUS_CD_REJECTED,
      McGod.t("cc.eb-factoring.rejected")
    );
    list.add(
      EbSepaFileTransactionGENERATED.STATUS_CD_BLACKLISTED,
      McGod.t("cc.factoring.transactions.blacklisted")
    );
    list.add(
      EbSepaFileTransactionGENERATED.STATUS_CD_NEW,
      McGod.t("cc.common.view.new")
    );
    list.add(
      EbSepaFileTransactionGENERATED.STATUS_CD_EXPORTED,
      McGod.t("cc.eb-sepa-export.exported")
    );
    list.add(
      EbSepaFileTransactionGENERATED.STATUS_CD_PAID,
      McGod.t("cc.factoring.transactions.paid")
    );
    list.add(
      EbSepaFileTransactionGENERATED.STATUS_CD_RETURNED,
      McGod.t("cc.factoring.transactions.returned")
    );
    list.add(
      EbSepaFileTransactionGENERATED.STATUS_CD_FOR_DUNNING,
      McGod.t("cc.factoring.transactions.for-dunning")
    );
    list.add(
      EbSepaFileTransactionGENERATED.STATUS_CD_SENDING_TO_INKASSO,
      McGod.t("cc.transactions.sending-to-inkasso")
    );
    list.add(
      EbSepaFileTransactionGENERATED.STATUS_CD_SENT_TO_INKASSO,
      McGod.t("cc.transactions.sent-to-inkasso")
    );
    list.add(
      EbSepaFileTransactionGENERATED.STATUS_CD_SHOULD_GO_TO_INKASSO,
      McGod.t("cc.transactions.should-go-to-inkasso")
    );
    list.add(
      EbSepaFileTransactionGENERATED.STATUS_CD_SETTLED,
      McGod.t("Settled")
    );
    list.add(
      EbSepaFileTransactionGENERATED.STATUS_CD_CANCELLED,
      McGod.t("Cancelled")
    );
    list.add(
      EbSepaFileTransactionGENERATED.STATUS_CD_RETURNED_FROM_INKASSO,
      McGod.t("cc.transactions.returned-from-inkasso")
    );

    return list;
  }

  static getPauseUnpausedCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add("PAUSED", McGod.t("cc.common.edit.yes"));
    list.add("UNPAUSED", McGod.t("cc.common.edit.no"));

    return list;
  }

  static getDunningCompletedCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add("yes", McGod.t("cc.common.edit.yes"));
    list.add("no", McGod.t("cc.common.edit.no"));

    return list;
  }

  static getFactoredServiceTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add("FACTORING_NONDELAYED", "factored");
    list.add("FACTORING_DELAYED", "non-factored");
    list.add("SERVICE_NONDELAYED", "serviced");
    list.add("SERVICE_DELAYED", "serviced over limit");

    return list;
  }

  static getInDunningFlgCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add("YES", McGod.t("cc.common.edit.yes"));
    list.add("NO", McGod.t("cc.common.edit.no"));

    return list;
  }

  constructor() {
    super();
  }

  public formatCreatedDateTime(): string {
    if (this.createdDatetime) {
      return McDateUtils.newFormatDateTimeString(this.createdDatetime);
    }
    return "";
  }
  public fldActions() {
    if (!this._fldActions) {
      const actionSet = new McHtmlActionSet();
      actionSet.addActionB(
        `<i class="fas fa-eye"></i> ${this.t("cc.my-factoring.view-details")}`,
        EbSepaFileTransaction.ACTION_VIEW_DETAILS,
        this,
        [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_VIEW],
        "",
        ActionOptions.OPTION_VIEW
      );
      if (!this.isStatusCdREJECTED() && !this.isStatusCdCANCELLED()) {
        actionSet.addActionB(
          `<i class="fas fa-plus"></i> ${this.t(
            "cc.my-factoring.add-payment"
          )}`,
          EbSepaFileTransaction.ACTION_RECORD_PAYMENT,
          this,
          [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_PAYMENT_ADD],
          "",
          ActionOptions.OPTION_EDIT
        );
      }
      if (this.canSettleForBalanceStatus()) {
        actionSet.addActionB(
          `<i class="fas fa-plus"></i> ${McGod.t(
            "cc.common.view-settle-with-balance"
          )}`,
          EbSepaFileTransaction.ACTION_SETTLE_WITH_BALANCE,
          this,
          [McGod.PERM_EB_FACTORING_BALANCE_SETTLE_WITH_BALANCE],
          " ",
          ActionOptions.OPTION_EDIT
        );
      }
      if (!this.isStatusCdCANCELLED()) {
        actionSet.addActionB(
          '<i class="fas fa-highlighter"></i> Mark as "Sent to inkasso"',
          EbSepaFileTransaction.ACTION_MARK_AS_SENT_TO_INKASSO,
          this,
          [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_MARK_AS_SENT_TO_INKASSO],
          "",
          ActionOptions.OPTION_EDIT
        );
        actionSet.addActionB(
          '<i class="far mc-submit-color fa-times-circle"></i> Storno',
          EbSepaFileTransaction.ACTION_STORNO,
          this,
          [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_EDIT],
          "",
          ActionOptions.OPTION_EDIT
        );
        actionSet.addActionB(
          '<i class="fas fa-file-export"></i> Export',
          EbSepaFileTransaction.ACTION_EXPORT,
          this,
          this.getPermissionsForExport(),
          "",
          ActionOptions.OPTION_EDIT
        );
      }
      if (this.canMarkAsReturnedFromInkasso() && !this.isStatusCdCANCELLED()) {
        actionSet.addActionB(
          `<i class="fas fa-highlighter"></i> ${McGod.t(
            "cc.transactions.mark-as-returned-from-inkasso"
          )}`,
          EbSepaFileTransaction.ACTION_MARK_AS_RETURNED_FROM_INKASSO,
          this,
          [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_MARK_AS_SENT_TO_INKASSO],
          "",
          ActionOptions.OPTION_EDIT
        );
      }
      if (
        this.nonFactoringFlg &&
        this.statusCd === "ACCEPTED" &&
        !this.isStatusCdCANCELLED()
      ) {
        actionSet.addActionB(
          this.t("cc.consumer.change-collection-date"),
          EbSepaFileTransaction.ACTION_CHANGE_COLLECTION_DATE,
          this,
          [McGod.PERM_EB_FACTORING_MY_TRANSACTION_EDIT],
          "",
          ActionOptions.OPTION_EDIT
        );
      }
      // if (!this.isStatusCdREJECTED()) {
      //   actionSet.addActionB('Unpause transaction', EbSepaFileTransaction.ACTION_UNPAUSE_TRANSACTIONS, this, [McGod.PERM_EB_FACTORING_MY_TRANSACTION_EDIT]); // need to generate permission for pause
      // }
      // actionSet.addActionB('Edit', EbSepaFileTransaction.ACTION_EDIT, this, [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_EDIT] );
      actionSet.addActionB(
        '<i class="fas fa-file-export"></i> View Billing Items',
        EbSepaFileTransaction.ACTION_VIEW_BILLING_ITEMS,
        this,
        [
          McGod.PERM_EB_FACTORING_MY_TRANSACTION_BILLING_ITEMS_VIEW,
          McGod.PERM_EB_FACTORING_OUT_TRANSACTION_BILLING_ITEMS_VIEW,
        ],
        "",
        ActionOptions.OPTION_VIEW
      );
      actionSet.addActionB(
        '<i class="fas fa-file-export"></i> View Payout Items',
        EbSepaFileTransaction.ACTION_VIEW_PAYOUT_ITEMS,
        this,
        [
          McGod.PERM_EB_FACTORING_MY_TRANSACTION_PAYOUT_ITEMS_VIEW,
          McGod.PERM_EB_FACTORING_OUT_TRANSACTION_PAYOUT_ITEMS_VIEW,
        ],
        "",
        ActionOptions.OPTION_VIEW
      );
      actionSet.addActionB(
        `<i class="fas fa-file-invoice mc-cursor-pointer"></i> ${McGod.t(
          "cc.common.view-consumer-profile"
        )}`,
        EbSepaFileTransaction.ACTION_VIEW_CONSUMER_PROFILE,
        this,
        [McGod.PERM_MC_CONSUMER_VIEW],
        "",
        ActionOptions.OPTION_VIEW
      );
      this._fldActions = actionSet;
    }
    return this._fldActions;
  }

  public fldActionsMyFactoring() {
    if (!this._fldActionsMyFactoring) {
      const actionSet = new McHtmlActionSet();
      actionSet.addActionB(
        `<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t(
          "cc.my-factoring.view-details"
        )}`,
        EbSepaFileTransaction.ACTION_VIEW_DETAILS,
        this,
        [McGod.PERM_EB_FACTORING_MY_TRANSACTION_VIEW],
        "",
        ActionOptions.OPTION_VIEW
      );
      if (!this.isStatusCdREJECTED() && !this.isStatusCdCANCELLED()) {
        actionSet.addActionB(
          `<i class="fas mc-submit-color fa-plus"></i> ${this.t(
            "cc.my-factoring.add-payment"
          )}`,
          EbSepaFileTransaction.ACTION_RECORD_PAYMENT,
          this,
          [McGod.PERM_EB_FACTORING_MY_TRANSACTION_PAYMENT_ADD],
          "",
          ActionOptions.OPTION_EDIT
        );
      }
      if (this.canSettleForBalanceStatus()) {
        actionSet.addActionB(
          `<i class="fas fa-plus"></i> ${McGod.t(
            "cc.common.view-settle-with-balance"
          )}`,
          EbSepaFileTransaction.ACTION_SETTLE_WITH_BALANCE,
          this,
          [McGod.PERM_EB_FACTORING_BALANCE_SETTLE_WITH_BALANCE],
          " ",
          ActionOptions.OPTION_EDIT
        );
      }
      if (
        this.nonFactoringFlg &&
        this.statusCd === "ACCEPTED" &&
        !this.isStatusCdCANCELLED()
      ) {
        actionSet.addActionB(
          this.t("cc.consumer.change-collection-date"),
          EbSepaFileTransaction.ACTION_CHANGE_COLLECTION_DATE,
          this,
          [McGod.PERM_EB_FACTORING_MY_TRANSACTION_EDIT],
          "",
          ActionOptions.OPTION_EDIT
        );
      }
      if (
        this.canPauseUnpauseTransactionFlg(this) &&
        !this.isStatusCdCANCELLED()
      ) {
        if (this.pauseStartDate) {
          actionSet.addActionB(
            `<i class="fas mc-submit-color fa-play"></i> Unpause transaction`,
            EbSepaFileTransaction.ACTION_UNPAUSE_TRANSACTIONS,
            this,
            [McGod.PERM_EB_FACTORING_MY_TRANSACTION_UNPAUSE_TRANSACTION],
            "",
            ActionOptions.OPTION_EDIT
          );
        } else {
          actionSet.addActionB(
            `<i class="fas mc-submit-color fa-pause"></i> Pause transaction`,
            EbSepaFileTransaction.ACTION_PAUSE_TRANSACTIONS,
            this,
            [McGod.PERM_EB_FACTORING_MY_TRANSACTION_PAUSE_TRANSACTION],
            "",
            ActionOptions.OPTION_EDIT
          );
        }
      }
      actionSet.addActionB(
        '<i class="fas mc-submit-color fa-file-export"></i> View Billing Items',
        EbSepaFileTransaction.ACTION_VIEW_BILLING_ITEMS,
        this,
        [
          McGod.PERM_EB_FACTORING_MY_TRANSACTION_BILLING_ITEMS_VIEW,
          McGod.PERM_EB_FACTORING_OUT_TRANSACTION_BILLING_ITEMS_VIEW,
        ],
        "",
        ActionOptions.OPTION_VIEW
      );
      actionSet.addActionB(
        '<i class="fas mc-submit-color fa-file-export"></i> View Payout Items',
        EbSepaFileTransaction.ACTION_VIEW_PAYOUT_ITEMS,
        this,
        [
          McGod.PERM_EB_FACTORING_MY_TRANSACTION_PAYOUT_ITEMS_VIEW,
          McGod.PERM_EB_FACTORING_OUT_TRANSACTION_PAYOUT_ITEMS_VIEW,
        ],
        "",
        ActionOptions.OPTION_VIEW
      );
      actionSet.addActionB(
        `<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t(
          "cc.common.view-consumer-profile"
        )}`,
        EbSepaFileTransaction.ACTION_VIEW_CONSUMER_PROFILE,
        this,
        [McGod.PERM_MC_CONSUMER_VIEW],
        "",
        ActionOptions.OPTION_VIEW
      );
      this._fldActionsMyFactoring = actionSet;
    }
    return this._fldActionsMyFactoring;
  }

  public fldActionsConsumerTransaction() {
    if (!this._fldActionsConsumerTransaction) {
      const actionSet = new McHtmlActionSet();
      actionSet.addActionB(
        `<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t(
          "cc.my-factoring.view-details"
        )}`,
        EbSepaFileTransaction.ACTION_VIEW_DETAILS,
        this,
        [McGod.PERM_EB_FACTORING_MY_TRANSACTION_VIEW],
        "",
        ActionOptions.OPTION_VIEW
      );
      if (!this.isStatusCdREJECTED() && !this.isStatusCdCANCELLED()) {
        actionSet.addActionB(
          `<i class="fas mc-submit-color fa-plus"></i> ${this.t(
            "cc.my-factoring.add-payment"
          )}`,
          EbSepaFileTransaction.ACTION_RECORD_PAYMENT,
          this,
          [McGod.PERM_EB_FACTORING_MY_TRANSACTION_PAYMENT_ADD],
          "",
          ActionOptions.OPTION_EDIT
        );
      }
      if (
        this.nonFactoringFlg &&
        this.statusCd === "ACCEPTED" &&
        !this.isStatusCdCANCELLED()
      ) {
        actionSet.addActionB(
          this.t("cc.consumer.change-collection-date"),
          EbSepaFileTransaction.ACTION_CHANGE_COLLECTION_DATE,
          this,
          [McGod.PERM_EB_FACTORING_MY_TRANSACTION_EDIT],
          "",
          ActionOptions.OPTION_EDIT
        );
      }
      if (
        this.canPauseUnpauseTransactionFlg(this) &&
        !this.isStatusCdCANCELLED()
      ) {
        if (this.pauseStartDate) {
          actionSet.addActionB(
            `<i class="fas mc-submit-color fa-play"></i> Unpause transaction`,
            EbSepaFileTransaction.ACTION_UNPAUSE_TRANSACTIONS,
            this,
            [McGod.PERM_EB_FACTORING_MY_TRANSACTION_UNPAUSE_TRANSACTION],
            "",
            ActionOptions.OPTION_EDIT
          );
        } else {
          actionSet.addActionB(
            `<i class="fas mc-submit-color fa-pause"></i> Pause transaction`,
            EbSepaFileTransaction.ACTION_PAUSE_TRANSACTIONS,
            this,
            [McGod.PERM_EB_FACTORING_MY_TRANSACTION_PAUSE_TRANSACTION],
            "",
            ActionOptions.OPTION_EDIT
          );
        }
      }
      actionSet.addActionB(
        '<i class="fas mc-submit-color fa-file-export"></i> View Billing Items',
        EbSepaFileTransaction.ACTION_VIEW_BILLING_ITEMS,
        this,
        [
          McGod.PERM_EB_FACTORING_MY_TRANSACTION_BILLING_ITEMS_VIEW,
          McGod.PERM_EB_FACTORING_OUT_TRANSACTION_BILLING_ITEMS_VIEW,
        ],
        "",
        ActionOptions.OPTION_VIEW
      );
      actionSet.addActionB(
        '<i class="fas mc-submit-color fa-file-export"></i> View Payout Items',
        EbSepaFileTransaction.ACTION_VIEW_PAYOUT_ITEMS,
        this,
        [
          McGod.PERM_EB_FACTORING_MY_TRANSACTION_PAYOUT_ITEMS_VIEW,
          McGod.PERM_EB_FACTORING_OUT_TRANSACTION_PAYOUT_ITEMS_VIEW,
        ],
        "",
        ActionOptions.OPTION_VIEW
      );
      actionSet.addActionB(
        `<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t(
          "cc.common.view-consumer-profile"
        )}`,
        EbSepaFileTransaction.ACTION_VIEW_CONSUMER_PROFILE,
        this,
        [McGod.PERM_MC_CONSUMER_VIEW],
        "",
        ActionOptions.OPTION_VIEW
      );
      if (this.canSettleForBalanceStatus()) {
        actionSet.addActionB(
          `<i class="fas mc-submit-color fa-plus"></i> ${McGod.t(
            "cc.common.view-settle-with-balance"
          )}`,
          EbSepaFileTransaction.ACTION_SETTLE_WITH_BALANCE,
          this,
          [McGod.PERM_EB_FACTORING_BALANCE_SETTLE_WITH_BALANCE],
          "",
          ActionOptions.OPTION_EDIT
        );
      }
      this._fldActionsConsumerTransaction = actionSet;
    }
    return this._fldActionsConsumerTransaction;
  }

  public getPermissionsForExport() {
    return [
      McGod.PERM_EB_FACTORING_OUT_SEPA_EXPORT,
      McGod.PERM_EB_FACTORING_OUT_INKASSO_EXPORT,
    ];
  }

  public getTotal(id: number) {
    return this.calculateAndGetTotalAmountDueForConsumer(id);
  }

  public getFactoringType() {
    return this.nonFactoringFlg;
  }

  public canPauseUnpauseTransactionFlg(
    ebTransaction: EbSepaFileTransaction
  ): boolean {
    return (
      !ebTransaction.isStatusCdREJECTED() &&
      !ebTransaction.isStatusCdSENT_TO_INKASSO() &&
      !ebTransaction.isStatusCdSETTLED() &&
      !ebTransaction.isStatusCdCANCELLED()
    );
  }

  canMarkAsReturnedFromInkasso(): boolean {
    return (
      !this.nonFactoringFlg &&
      !this.delayFlg &&
      (this.isStatusCdSHOULD_GO_TO_INKASSO() ||
        this.isStatusCdSENDING_TO_INKASSO() ||
        this.isStatusCdSENT_TO_INKASSO())
    );
  }

  canSettleForBalanceStatus(): boolean {
    return (
      !this.isStatusCdCANCELLED() &&
      !this.isStatusCdEXPORTED() &&
      !this.isStatusCdPAID() &&
      !this.isStatusCdSETTLED() &&
      !this.isStatusCdREJECTED() &&
      !this.isStatusCdSENT_TO_INKASSO()
    );
  }

  public formatBooleanFactoringAcceptedValue() {
    return this.factoringAcceptedFlg
      ? this.t("cc.common.edit.yes")
      : this.t("cc.common.edit.no");
    // const translationKey = this['factoringAccepted'] ? "cc.common.edit.yes" : "cc.common.edit.no";
    /*TODO: find way to import translate service*/
    // this.translate.get(translationKey).subscribe(data => {
    //   console.log('prevedeno...........', data);
    //   return data
    // });
  }

  public getDescription() {
    return this.description;
  }

  public vatFormatNumberOnTwoDecimals() {
    return Number(this.netFeeVatAmount).toFixed(2).replace(".", ",");
  }

  public fldOriginalAmount(): number {
    return this.instdamt;
  }

  getImportDateStr(): string {
    if (this.createdDatetime) {
      return McDateUtils.newFormatDateTimeString(this.createdDatetime);
    }
    return "";
  }

  // ---------------------------------------------------------------------
  public getTypeCdVll() {
    return EbSepaFileTransaction.getTypeCdVll();
  }

  // ---------------------------------------------------------------------
  public getSourceTypeCdVll() {
    return EbSepaFileTransaction.getSourceTypeCdVll();
  }

  getExportedDateFormat(): string {
    if (this.createdDatetime) {
      return McDateUtils.newFormatDateString(this.createdDatetime);
    }
    return "";
  }

  getSentToInkassoDateTime(): string {
    if (this.sentToInkassoDatetime) {
      return McDateUtils.newFormatDateTimeString(this.sentToInkassoDatetime);
    }
    return "";
  }

  // Get icon for each status
  getStatusIcon(icon: string) {
    return `<div class="icon-tooltip">
                    <img src="assets/mc-components-assets/${icon}.svg">
                    <span class="icon-tooltip-text">${this.getStatusCdLabel()}</span></div>`;
  }

  // get icon for factoring or service
  getFactoringNonFactoring(reason: string, info: boolean) {
    return (
      `${
        this.nonFactoringFlg
          ? `<img src="assets/mc-components-assets/${
              this.delayFlg ? "s-gray" : "s-blue"
            }.svg">`
          : `<img src="assets/mc-components-assets/${
              this.delayFlg ? "f-gray" : "f-blue"
            }.svg">`
      }` +
      `<div class="icon-tooltip">
     <img src="assets/mc-components-assets/${info ? "info-blue3" : "i4"}.svg">
     <span class="${info ? "icon-tooltip-text" : "icon-tooltip-hidden"}">${
        reason.includes(".") ? this.t(reason) : reason
      }</span></div>`
    );
  }

  // same for all statuses, can be implemented
  getInDunningIcon() {
    return ` ${
      this.inDunningFlg
        ? `<div class="icon-tooltip">
                   <img src="assets/mc-components-assets/in-dunning-bell-orange-18.svg">
                   <span class="icon-tooltip-text">This transaction is in dunning.</span></div>`
        : ""
    }`;
  }

  // same for all statuses, can be implemented
  getDunningCompletedIcon() {
    return ` ${
      this.dunningCompletedFlg
        ? `<div class="icon-tooltip">
                    <img src="assets/mc-components-assets/dunning-completed.svg">
                    <span class="icon-tooltip-text">Dunning completed.</span></div>`
        : ""
    }`;
  }

  // same for all statuses, can be implemented
  getPausedIcon() {
    return `${
      this.pauseStartDate
        ? `<div class="icon-tooltip">
                    <i class="far fa-pause-circle text-orange-color"></i><span class="icon-tooltip-text">
                    PAUSE ENDS: ${this.getPauseEndDateStrD()}<br/> PAUSED REASON: ${
            this.pauseUnpauseReason
          }</span></div>`
        : ""
    }`;
  }

  getPayoutIcon(): string {
    if (this.payoutStatusCd === "DELAYED") {
      return `<div class="icon-tooltip">
                    <img src="assets/mc-components-assets/delayed_payout.svg">
                    <span class="icon-tooltip-text">Delayed payout</span></div>`;
    }
    if (this.payoutStatusCd === "PARTIALLY_PAID") {
      return `<div class="icon-tooltip">
                    <img src="assets/mc-components-assets/partially_paid_out.svg">
                    <span class="icon-tooltip-text">Partial payout</span></div>`;
    }
    if (this.payoutStatusCd === "FULLY_PAID") {
      return `<div class="icon-tooltip">
                    <img src="assets/mc-components-assets/fully_paid_out.svg">
                    <span class="icon-tooltip-text">Full payout</span></div>`;
    }
    if (this.payoutStatusCd === "RETURNED") {
      return `<div class="icon-tooltip">
                    <img src="assets/mc-components-assets/payout-returned.svg">
                    <span class="icon-tooltip-text">Returned</span></div>`;
    }
    if (this.payoutStatusCd === "CANCELLED") {
      return `<div class="icon-tooltip">
                    <img src="assets/mc-components-assets/payout-cancelled.svg">
                    <span class="icon-tooltip-text">Cancelled</span></div>`;
    }
    return "";
  }

  getIconLabel(): string {
    if (this.isStatusCdACCEPTED()) {
      if (this.delayFlg) {
        return `${this.getStatusIcon("accepted-delayed")}
                    ${this.getFactoringNonFactoring(this.reason, true)}
                    ${this.getInDunningIcon()} ${this.getDunningCompletedIcon()} ${this.getPayoutIcon()} ${this.getPausedIcon()} `;
      } else {
        return `${this.getStatusIcon("accepted")}
                ${this.getFactoringNonFactoring(
                  this.t("cc.transactions.fully-accepted"),
                  true
                )} ${this.getInDunningIcon()} ${this.getDunningCompletedIcon()} ${this.getPayoutIcon()} ${this.getPausedIcon()}`;
      }
    }

    if (this.isStatusCdNEW()) {
      return `${this.getStatusIcon("new")} ${this.getFactoringNonFactoring(
        "",
        false
      )} ${this.getPayoutIcon()} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdREJECTED()) {
      return `${this.getStatusIcon("rejected")} ${this.getFactoringNonFactoring(
        this.reason,
        true
      )} ${this.getPayoutIcon()} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdEXPORTED()) {
      const reason = `${this.t(
        "cc.transactions.exported-on"
      )} ${this.getExportedDateFormat()}`;
      return `${this.getStatusIcon("exported")} ${this.getFactoringNonFactoring(
        reason,
        true
      )} ${this.getPayoutIcon()} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdPAID()) {
      return `${this.getStatusIcon("paid")} ${this.getFactoringNonFactoring(
        "",
        false
      )}
                    ${this.getDunningCompletedIcon()} ${this.getPayoutIcon()} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdSETTLED()) {
      return `${this.getStatusIcon("settled")} ${this.getFactoringNonFactoring(
        "",
        false
      )} ${this.getDunningCompletedIcon()} ${this.getPayoutIcon()} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdRETURNED()) {
      const message = `${this.returnedTypeCd}: ${this.returnedIsoCode} - ${this.returnedIsoCodeLabel}`;
      return `${this.getStatusIcon("returned")} ${this.getFactoringNonFactoring(
        message,
        true
      )} ${this.getInDunningIcon()} ${this.getDunningCompletedIcon()} ${this.getPayoutIcon()} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdSHOULD_GO_TO_INKASSO()) {
      return `${this.getStatusIcon(
        "should-go-to-inkasso"
      )} ${this.getFactoringNonFactoring("", false)} ${this.getInDunningIcon()}
        ${this.getDunningCompletedIcon()} ${this.getPayoutIcon()} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdSENDING_TO_INKASSO()) {
      return `${this.getStatusIcon(
        "sending-to-inkasso"
      )} ${this.getFactoringNonFactoring("", false)} ${this.getInDunningIcon()}
        ${this.getDunningCompletedIcon()} ${this.getPayoutIcon()} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdSENT_TO_INKASSO()) {
      const message = `${this.t(
        "cc.transactions.sent-on"
      )} ${this.getSentToInkassoDateTime()}`;
      return `${this.getStatusIcon(
        "sent-to-inkasso"
      )} ${this.getFactoringNonFactoring(
        message,
        true
      )} ${this.getPayoutIcon()} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdRETURNED_FROM_INKASSO()) {
      return `${this.getStatusIcon(
        "returned-from-inkasso"
      )} ${this.getFactoringNonFactoring("", false)} ${this.getInDunningIcon()}
        ${this.getDunningCompletedIcon()} ${this.getPayoutIcon()} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdFOR_DUNNING()) {
      return `${this.getStatusIcon(
        "for-dunning"
      )} ${this.getFactoringNonFactoring(
        "",
        false
      )} ${this.getInDunningIcon()} ${this.getPayoutIcon()} ${this.getPausedIcon()}`;
    }
    if (this.isStatusCdCANCELLED()) {
      return `${this.getStatusIcon(
        "cancelled"
      )}  ${this.getFactoringNonFactoring("", false)}
                    ${this.getDunningCompletedIcon()} ${this.getPayoutIcon()} ${this.getPausedIcon()}`;
    }
    return "";
  }

  getRequestCollectionDate() {
    // In hurry for delivery, need to generate from BE
    if (this.requestedCollectionDate) {
      return McDateUtils.newFormatDateString(this.requestedCollectionDate);
    } else if (this.batchCollectionDate) {
      return McDateUtils.newFormatDateString(this.batchCollectionDate);
    } else {
      return "";
    }
  }

  getDueDateTime(): string {
    // requested in this format, that's why I cant use generated method
    if (this.dueDatetime) {
      return McDateUtils.newFormatDateString(this.dueDatetime);
    }
    return "";
  }

  getCreatedDateTimeString(): string {
    if (this.createdDatetime) {
      return McDateUtils.newFormatDateTimeString(this.createdDatetime);
    }
    return "";
  }

  getPausedStartDateString(): string {
    if (this.pauseStartDate) {
      return McDateUtils.newFormatDateTimeString(this.pauseStartDate);
    }
    return "";
  }

  getPausedEndDateString(): string {
    if (this.pauseEndDate) {
      return McDateUtils.newFormatDateTimeString(this.pauseEndDate);
    }
    return "";
  }

  getDueDateTimeString() {
    if (this.dueDatetime) {
      return McDateUtils.newFormatDateTimeString(this.dueDatetime);
    }
    return "";
  }

  getAmount() {
    return CurrencyUtil.format(this.amountDue, this.currencyCode);
  }
}
