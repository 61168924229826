
import {Table3Config} from '../extra/table-3-config';

// import {CcMollieTransactionGENERATED} from '../_generated/cc-mollie-transaction-generated.model';

export class CcMollieTransactionTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*

*/
  }
}
