

  <div class="content-wrapper">

    <lib-mc-loader [showLoader]="ebFactoring.apiLoadingFlg"></lib-mc-loader>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-header">
      <ng-container *ngIf="isClonedContract">
        <h1>{{'cc.contract.new-contract-for' | translate}} {{entityClone.name}}</h1>
      </ng-container>
      <ng-container *ngIf="!isClonedContract">
        <h1>{{'cc.contract.new-contract-for' | translate}} {{childEntity.name}}</h1>
      </ng-container>
    </div>

    <div class="content-body pb-5">
      <mcc-message [errorMsg]="errorMessage"></mcc-message>
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <h2>
              {{'cc.contract.contract-details' | translate}}
            </h2>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="inner-section">
            <h6>{{'cc.common.view.entity' | translate}}</h6>
            <p *ngIf="!isClonedContract"><strong>{{childEntity.name}}</strong></p>
            <p *ngIf="isClonedContract"><strong>{{entityClone.name}}</strong></p>
            <h6>{{'cc.contract.contract-type' | translate}}</h6>
            <p><strong>{{contractType === 'FACTORING' ? this.mcGod.t('cc.factoring.factoring') : 'Service'}}</strong>
            </p>
            <mcc-fi-textbox [name]="'ctrlFactoringContractName'"
                            label="{{'cc.my-factoring.contract-name' | translate}}"
                            [validRequiredFlg]="true" [validWhitespaces]="true"
                            [mcForm]="mcForm">
            </mcc-fi-textbox>
          </div>
        </div>
        <div class="col-md-6">
          <mcc-fi-select  name="selCurrency" label="{{'cc.common.currency' | translate}}"
                          placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                          [validRequiredFlg]="true"
                          [value]="ebFactoring.idMcCurrency ? ebFactoring.idMcCurrency : ''"
                          [optionsVll]="currencyVll"
                          (eventValueChanged)="onCurrencyChanged($event)"
                          [mcForm]="mcForm"></mcc-fi-select>
        </div>

      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <h2>{{'cc.contract.general-conditions' | translate}}</h2>
          </div>
        </div>


        <div class="col-md-6 mb-3">
          <div class="section-sub-header">
            <h3>{{'cc.contract.contract-validity' | translate}}</h3>
          </div>
          <div class="inner-section">
            <mcc-fi-date-picker
              label="{{'cc.factoring.start-date' | translate}}"
              [name]="'ctrlContractStartDate'"
              [validRequiredFlg]="true"
              [minDateInput]="today"
              [allowManualEntryFlg]="false"
              [mcForm]="mcForm">
            </mcc-fi-date-picker>

            <mcc-fi-date-picker
              label="{{'cc.factoring.end-date' | translate}}"
              [name]="'ctrlContractEndDate'"
              [valueInput]="ebFactoring?.contractEndDate"
              [allowManualEntryFlg]="true"
              [minDateInput]="today"
              [mcForm]="mcForm">
            </mcc-fi-date-picker>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="section-sub-header">
            <h3>{{'cc.contract.contract-cancellation' | translate}}</h3>
            <img src="/assets/images/info-blue-tooltip.svg" alt="tooltip icon" placement="left-bottom"
                 ngbTooltip="{{'cc.contracts.upon-receiving-the-contract-cancellation-request-the-contract-will-stop-being-active-after-the-period-defined-bellow' | translate}}">
          </div>
          <div class="inner-section">
            <mcc-fi-textbox [name]="'ctrlContractCancellationPeriod'"
                            label="{{'cc.out-factoring.cancellation-period' | translate}}"
                            [value]="ebFactoringClone?.contractCancellationPeriod >= 0 ? ebFactoringClone.contractCancellationPeriod : '2'"
                            [validRequiredFlg]="true"
                            [extraCssClasses]="'input-right'"
                            size="10"
                            [validIntegerNumber]="true"
                            [additionalDescription]="'cc.common.date.months' | translate"
                            [mcForm]="mcForm">

            </mcc-fi-textbox>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <h2>{{'cc.contract.requests' | translate}}</h2>
          </div>
          <div class="col-md-12 contract-request mb-4">
            <div class="inner-section">
              <app-shared-factoring-request-type-add-edit-part [idChildEntity]="idChildEntity"
                                                               [ebFactoring]="ebFactoring"
                                                               [mcForm]="mcForm"
                                                               [factoringRequestTypeList]="factoringRequestTypeList"
                                                               [ebFactoringIdForCloning]="ebFactoringId"
                                                               [isContractCloned]="isClonedContract"></app-shared-factoring-request-type-add-edit-part>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <h2>{{'cc.contract.fees' | translate}}</h2>
          </div>
        </div>
        <ng-container *ngIf="contractType === 'FACTORING'">
          <div class="col-md-6">
            <div class="inner-section">
              <mcc-fi-number-type nameForValue="ctrlFactoredClaimsFeeValue" nameForType="ctrlFactoredClaimsFeeType"
                                  label="{{'cc.contract.factored-transaction-fee' | translate}}" [validMinNumber]="0"
                                  [validMaxNumber]="100"
                                  [extraCssClasses]="'input-right'"
                                  [disableMoneyValue]="true"
                                  [value]="ebFactoringClone.factoredClaimFeePerTransaction >= 0 ? ebFactoringClone.factoredClaimFeePerTransaction : '2.10'"
                                  valueType="percentage" [validRequiredFlg]="true"
                                  validNumberWithTwoDecimals="true"
                                  [labelOnTopFlg]="true"
                                  [additionalDescription]="'per transaction'"
                                  [mcForm]="mcForm">
              </mcc-fi-number-type>
            </div>
          </div>

          <div class="col-md-6">
            <div class="inner-section">
              <mcc-fi-number-type nameForValue="ctrlNonFactoredClaimsFeeValue"
                                  nameForType="ctrlNonFactoredClaimsFeeType" [validMinNumber]="0" [validMaxNumber]="100"
                                  [value]="ebFactoringClone.nonFactoredClaimFeePerTransaction >= 0 ? ebFactoringClone.nonFactoredClaimFeePerTransaction : '2.10'"
                                  [extraCssClasses]="'input-right'"
                                  validNumberWithTwoDecimals="true"
                                  label="{{'cc.contract.non-factored-transaction-fee' | translate}}"
                                  [validRequiredFlg]="true" [mcForm]="mcForm" valueType="percentage"
                                  [disableMoneyValue]="true" [labelOnTopFlg]="true"
                                  additionalDescription="'{{'cc.contract.per-transaction' | translate}}'">
              </mcc-fi-number-type>
            </div>
          </div>

        </ng-container>

        <ng-container *ngIf="contractType === 'SERVICE'">
          <div class="col-md-6">
            <div class="content-section">
              <div class="inner-section">
                <mcc-fi-number-type nameForValue="ctrlNonFactoringPerTransactionValue" [validMinNumber]="0"
                                    [validMaxNumber]="100"
                                    [valueType]="'AMOUNT'"
                                    validNumberWithTwoDecimals="true"
                                    [chosenCurrency]="currencyCode"
                                    [value]="ebFactoringClone?.feePerTransaction >= 0 ? ebFactoringClone.feePerTransaction : ''"
                                    nameForType="ctrlNonFactoringPerTransactionType"
                                    label="{{'cc.contract.per-transaction' | translate}}"
                                    [validRequiredFlg]="true" [mcForm]="mcForm" [labelOnTopFlg]="true">
                </mcc-fi-number-type>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="col-md-6">
              <div class="inner-section">
                <mcc-fi-number-type nameForValue="ctrlNonFactoringPerBatchValue" [validMinNumber]="0"
                                    [validMaxNumber]="100"
                                    validNumberWithTwoDecimals="true"
                                    [value]="ebFactoringClone?.feePerBatch >= 0 ? ebFactoringClone.feePerBatch : ''"
                                    nameForType="ctrlNonFactoringPerBatchType"
                                    label="{{'cc.contract.per-batch-%-of-the-total-amount' | translate}}"
                                    [mcForm]="mcForm" valueType="percentage"
                                    [disableMoneyValue]="true"
                                    [labelOnTopFlg]="true">
                </mcc-fi-number-type>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="pb-4"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <h2>{{'cc.contract.limits' | translate}}</h2>
          </div>
        </div>
        <div class="col-md-6">
          <div class="section-sub-header">
            <h3>{{'cc.contract.failed-withdrawals' | translate}}</h3>
          </div>
          <div class="inner-section">
            <mcc-fi-textbox [name]="'ctrlMaximalNumberOfFailedWithdrawals'"
                            label="{{'cc.contract.maximal-number-of-failed-withdrawals' | translate}}"
                            [validRequiredFlg]="true"
                            [value]="ebFactoringClone?.maximalNumberOfFailedWithdrawals >= 0 ? ebFactoringClone.maximalNumberOfFailedWithdrawals : '2'"
                            [extraCssClasses]="'input-right'"
                            size="10"
                            [validIntegerNumber]="true" [mcForm]="mcForm">
            </mcc-fi-textbox>
          </div>
          <div class="section-sub-header">
            <h3>{{'cc.contract.import-time-limits' | translate}}</h3>
          </div>
          <div class="inner-section">
            <mcc-fi-textbox [name]="'ctrlAllowedToImportClaimsPast'"
                            label="{{'cc.contract.allowed-to-import-transactions-in-the-past' | translate}}"
                            size="10"
                            [extraCssClasses]="'input-right'"
                            [value]="ebFactoringClone?.monthsAllowedPastCount >= 0 ? ebFactoringClone.monthsAllowedPastCount : '2'"

                            [validIntegerNumber]="true" [validRequiredFlg]="true" [mcForm]="mcForm"

                            [additionalDescription]="'cc.common.date.months' | translate">
            </mcc-fi-textbox>

            <mcc-fi-textbox [name]="'ctrlAllowedToImportClaimsFuture'"
                            label="{{'cc.contract.allowed-to-import-transactions-in-the-future' | translate}}"
                            size="10"
                            [extraCssClasses]="'input-right'"
                            [value]="ebFactoringClone?.monthsAllowedFutureCount >= 0 ? ebFactoringClone.monthsAllowedFutureCount : '1'"
                            [validIntegerNumber]="true" [validRequiredFlg]="true" [mcForm]="mcForm"
                            [additionalDescription]="'cc.common.date.months' | translate">
            </mcc-fi-textbox>
          </div>


        </div>
        <div class="col-md-6">
          <div class="section-sub-header">
            <h3>{{'cc.contract.soft-limits' | translate}}</h3>
            <img src="/assets/images/info-blue-tooltip.svg" alt="tooltip icon"
                 ngbTooltip="{{'cc.contract.all-transactions-that-exceed-these-limits-will-be-delayed' | translate}}"
                 placement="bottom-right">
          </div>
          <div class="inner-section">
            <mcc-fi-number-type nameForValue="ctrlPerTransactionLimitValue" nameForType="ctrlPerTransactionLimitType"
                                label="{{'cc.contract.per-transaction-limit' | translate}}"
                                [validMinNumber]="0"
                                validRequiredFlg="true"
                                [chosenCurrency]="currencyCode"
                                validNumberWithTwoDecimal="true"
                                [value]="ebFactoringClone?.softLimitPerTransaction >= 0 ? ebFactoringClone.softLimitPerTransaction : '150'"
                                [mcForm]="mcForm" [disablePercentage]="true"
                                [labelOnTopFlg]="true">
            </mcc-fi-number-type>

            <mcc-fi-number-type nameForValue="ctrlPerSepaRunLimitValue" nameForType="ctrlPerSepaRunLimitType"
                                label="{{'cc.contract.per-sepa-run-limit' | translate}}"
                                [validMinNumber]="0"
                                validNumberWithTwoDecimals="true"
                                [chosenCurrency]="currencyCode"
                                [value]="ebFactoringClone?.softLimitPerSepaRun >= 0 ? ebFactoringClone.softLimitPerSepaRun : '5000'"
                                [mcForm]="mcForm" [disablePercentage]="true"
                                [labelOnTopFlg]="true">
            </mcc-fi-number-type>
          </div>
          <div class="section-sub-header">
            <h3>{{'cc.contract.hard-limits' | translate}}</h3>
            <img src="/assets/images/info-blue-tooltip.svg" alt="tooltip icon"
                 ngbTooltip="{{'cc.contract.all-transactions-that-exceed-these-limits-will-be-rejected' | translate}}"
                 placement="bottom-right">
          </div>
          <div class="inner-section">
            <div class="input-wrap d-flex align-items-end input-right">
              <mcc-fi-number-type nameForValue="ctrlCurrentEntityLimitValue" nameForType="ctrlCurrentEntityLimitType"
                                  label="{{'cc.contract.limit-for-current-entity' | translate}}"
                                  [validRequiredFlg]="true"
                                  [extraCssClasses]="'input-right'"
                                  [validMinNumber]="0"
                                  validNumberWithTwoDecimal="true"
                                  [chosenCurrency]="currencyCode"
                                  [value]="ebFactoringClone?.hardLimitForCurrentEntity >= 0 ? ebFactoringClone.hardLimitForCurrentEntity : '8000'"
                                  [mcForm]="mcForm" [disablePercentage]="true"
                                  [labelOnTopFlg]="true"
                                  additionalDescription="{{'cc.contract.in-last' | translate}}">
              </mcc-fi-number-type>

              <mcc-fi-textbox [name]="'ctrlHardCurrentEntityLimitDays'"
                              size="10"
                              [validRequiredFlg]="true"
                              [validMinNumber]="0"
                              [value]="ebFactoringClone?.hardLimitForCurrentEntityDays >= 0 ? ebFactoringClone.hardLimitForCurrentEntityDays : 30"
                              [validIntegerNumber]="true" [mcForm]="mcForm"
                              [additionalDescription]="'cc.factoring.day-s' | translate">
              </mcc-fi-textbox>

            </div>
            <div class="input-wrap d-flex align-items-end">
              <mcc-fi-number-type nameForValue="ctrlCumulativePerEntityAndSubentitiesLimitValue"
                                  nameForType="ctrlCumulativePerEntityAndSubentitiesLimitType"
                                  label="{{'cc.contract.cumulative-limit-for-current-entity-and-all-sub-entities' | translate}}"
                                  validMinNumber="0"
                                  validNumberWithTwoDecimal="true"
                                  [chosenCurrency]="currencyCode"
                                  (eventAmountValueChanged)="onCumulativeLimitValueChanged($event)"
                                  [value]="ebFactoringClone?.hardCumulativeLimit >= 0 ? ebFactoringClone.hardCumulativeLimit : '10000'"
                                  [mcForm]="mcForm" [disablePercentage]="true"
                                  [labelOnTopFlg]="true"
                                  additionalDescription="{{'cc.contract.in-last' | translate}}">>
              </mcc-fi-number-type>


              <mcc-fi-textbox [name]="'ctrlHardCumulativeEntityLimitDays'"
                              [validRequiredFlg]="isCumulativeLimitFilled"
                              validMinNumber="0"
                              size="10"
                              [value]="ebFactoringClone.hardCumulativeLimitDays >= 0 ? ebFactoringClone.hardCumulativeLimitDays : 30"
                              [validIntegerNumber]="true" [mcForm]="mcForm"
                              [extraCssClasses]="'input-right'"
                              [additionalDescription]="'cc.factoring.day-s' | translate">
              </mcc-fi-textbox>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <h2>{{'cc.contracts.payout-management' | translate}}</h2>
          </div>
        </div>
        <div class="col-md-6">
          <div class="section-sub-header">
            <h3>{{'cc.contracts.initial-payout' | translate}}</h3>
          </div>

          <ng-container *ngIf="contractType === 'FACTORING'">
            <mcc-fi-textbox [name]="'ctrFactoredTransactionsDays'" label="{{'cc.contracts.factored-transactions' | translate}}"
                            [validRequiredFlg]="true"
                            [validMinNumber]="0"
                            size="10"
                            [value]="ebFactoringClone?.daysForInitialPayoutForFactoredTransactions >= 0 ? ebFactoringClone.daysForInitialPayoutForFactoredTransactions : ''"
                            [extraCssClasses]="'input-right'"
                            [validIntegerNumber]="true" [mcForm]="mcForm"
                            additionalDescription="'{{'cc.contracts.day-s-after-initial-withdrawal' | translate}}'"></mcc-fi-textbox>

            <mcc-fi-textbox [name]="'ctrNonFactoredTransactionsDays'" label="{{'cc.contracts.non-factored-transactions' | translate}}"
                            [validRequiredFlg]="true"
                            [validMinNumber]="0"
                            size="10"
                            [value]="ebFactoringClone?.daysForInitialPayoutForNonFactoredTransactions >= 0 ? ebFactoringClone.daysForInitialPayoutForNonFactoredTransactions : ''"
                            [extraCssClasses]="'input-right'"
                            [validIntegerNumber]="true" [mcForm]="mcForm"
                            additionalDescription="'{{'cc.contracts.day-s-after-initial-withdrawal' | translate}}'"></mcc-fi-textbox>
          </ng-container>

          <ng-container *ngIf="contractType === 'SERVICE'">
            <div class="input-wrap d-flex align-items-end">
              <mcc-fi-number-type nameForValue="ctrlSplitValue"
                                  nameForType="ctrlSplitType"
                                  label="{{'cc.contracts.split' | translate}}"
                                  validRequiredFlg="true"
                                  validMinNumber="0"
                                  validMaxNumber="100"
                                  validNumberWithTwoDecimal="true"
                                  [value]="ebFactoringClone?.percentageForInitialPayoutNonFactoring >= 0 ? ebFactoringClone.percentageForInitialPayoutNonFactoring : ''"
                                  [mcForm]="mcForm" valueType="percentage"
                                  [disableMoneyValue]="true"
                                  [labelOnTopFlg]="true"
                                  additionalDescription="{{'cc.contracts.to-be-paid-out' | translate}}">
              </mcc-fi-number-type>

              <mcc-fi-textbox [name]="'ctrlToBePaidOutDaysAfterWithdrawal'"
                              [validRequiredFlg]="true"
                              [validMinNumber]="0"
                              size="10"
                              [value]="ebFactoringClone?.noDaysForInitialPayoutNonFactoring >= 0 ? ebFactoringClone.noDaysForInitialPayoutNonFactoring : ''"
                              [extraCssClasses]="'input-right'"
                              [validIntegerNumber]="true" [mcForm]="mcForm"
                              additionalDescription="'{{'cc.contracts.day-s-after-withdrawal' | translate}}'"></mcc-fi-textbox>
            </div>

            <mcc-fi-textbox [name]="'ctrlTheRestToBePaid'" label="{{'cc.contracts.the-rest-to-be-paid' | translate}}"
                            [validRequiredFlg]="true"
                            [validMinNumber]="0"
                            size="10"
                            [value]="ebFactoringClone?.noDaysForTheRestToBePaidNonFactoring >= 0 ? ebFactoringClone.noDaysForTheRestToBePaidNonFactoring : ''"
                            [extraCssClasses]="'input-right'"
                            [validIntegerNumber]="true" [mcForm]="mcForm"
                            additionalDescription="'{{'cc.contracts.day-s-after-withdrawal' | translate}}'"></mcc-fi-textbox>
          </ng-container>

        </div>
      </div>

      <div class="pb-3"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <h2>{{'cc.contract.blacklist-management' | translate}}</h2>
          </div>
        </div>
        <div class="col-md-12">
            <mcc-fi-radio-buttons label="{{'cc.contract.send-claims-to-blacklist' | translate}}"
                                  [name]="'ctrlSendClaimsToBlacklist'"
                                  [validRequiredFlg]="true"
                                  [directionHorizontal]="true"
                                  [mcForm]="mcForm"
                                  [value]="ebFactoringClone?.sendClaimsToBlacklistFlg ? 'yes' : 'no'"
                                  [labelOnTop]="false"
                                  (eventValueChanged)="sendClaimsToBlacklistRadioValueChanged($event)"
                                  [optionsVlm]="yesNoVlm"></mcc-fi-radio-buttons>
        </div>
        <div class="col-md-12">
          <ng-container *ngIf="sendToBlacklistAfterBounceRequiredFlg">
            <mcc-fi-radio-buttons label="{{'cc.contract.remove-member-without-open-debt-from-bl' | translate}}"
                                [name]="'ctrlRemoveMemberFromBlacklist'"
                                [validRequiredFlg]="true"
                                [directionHorizontal]="true"
                                [mcForm]="mcForm"
                                [value]="ebFactoringClone?.removeMemberWithoutOpenDebtFromBlFlg ? 'yes' : 'no'"
                                [labelOnTop]="false"
                                (eventValueChanged)="removeMemberWithoutOpenDebtFromBlacklistValueChanged($event)"
                                [optionsVlm]="yesNoVlm"></mcc-fi-radio-buttons>
          </ng-container>
        </div>
        <div class="col-md-6" *ngIf="sendToBlacklistAfterBounceRequiredFlg">
          <div class="section-sub-header">
            <h3>{{'cc.contract.soft-bounce' | translate}}</h3>
            <img src="/assets/images/info-blue-tooltip.svg" alt="tooltip icon"
                 ngbTooltip="{{'cc.contracts.soft-bounce-refers-to-unsuccessful-withdrawals-that-have-returned-with-one-of-the-following-codes-ac06-am04-md01-ms03' | translate}}">
          </div>
          <div class="inner-section">
            <mcc-fi-textbox [name]="'ctrlSentToBlacklistAfterSoft'"
                            label="{{'cc.contract.send-to-blacklist-after' | translate}}"
                            [validRequiredFlg]="sendToBlacklistAfterBounceRequiredFlg"
                            [validMinNumber]="0"
                            [value]="ebFactoringClone?.blacklistTriggerSoftBounceCount >= 0 ? ebFactoringClone.blacklistTriggerSoftBounceCount : 2"
                            size="10"
                            [extraCssClasses]="'input-right'"
                            [validIntegerNumber]="true" [mcForm]="mcForm"
                            additionalDescription="'{{'cc.contract.soft-bounce-failed-withdrawals' | translate}}'">
            </mcc-fi-textbox>
          </div>
          <div class="pb-4"></div>
        </div>
        <div class="col-md-6" *ngIf="sendToBlacklistAfterBounceRequiredFlg">
          <div class="section-sub-header">
            <h3>{{'cc.contract.hard-bounce' | translate}}</h3>

            <img src="/assets/images/info-blue-tooltip.svg" alt="tooltip icon" placement="left-bottom"
                 ngbTooltip="{{'cc.contracts.hard-bounce-refers-to-unsuccessful-withdrawals-that-have-returned-with-one-of-the-following-codes-ac01-ac04-md07-ms02-sl01' | translate}}">
          </div>
          <div class="inner-section">
            <mcc-fi-textbox [name]="'ctrlSentToBlacklistAfterHard'"
                            label="{{'cc.contract.send-to-blacklist-after' | translate}}"
                            [validRequiredFlg]="sendToBlacklistAfterBounceRequiredFlg"
                            [validMinNumber]="0"
                            [value]="ebFactoringClone?.blacklistTriggerHardBounceCount >= 0 ? ebFactoringClone.blacklistTriggerHardBounceCount : 1"
                            size="10"
                            [extraCssClasses]="'input-right'"
                            [validIntegerNumber]="true" [mcForm]="mcForm"
                            additionalDescription="'{{'cc.contract.hard-bounce-failed-withdrawals' | translate}}'">
            </mcc-fi-textbox>

          </div>
        </div>
      </div>

      <div class="row">
        <!--FIRST COLUMN-->
        <div *ngIf="showDeleteButtonFlg" class="col-md-12">
          <button (click)="actDeleteItem()">Delete</button>
        </div><!-- col -->
      </div><!-- row -->


    </div>
    <div class="row">
      <div class="col-md-12 position-relative">
        <div class="above-footer">
          <mcc-mc-form-message [mcForm]="mcForm" [extraCssClasses]="'message-background'" messageType="error"
                               messageText="Some validation error text"></mcc-mc-form-message>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 position-relative">
        <div class="above-footer">
          <mcc-mc-form-message [mcForm]="mcForm" messageType="error" extraCssClasses="message-background"
                               messageText="Required fields can't be empty"></mcc-mc-form-message>
          <mcc-message [errorMsg]="errorMessage"></mcc-message>
        </div>
      </div>
    </div>

    <div class="content-footer">


      <button class="btn btn-secondary" (click)="actBack()"><i
        class="fas fa-arrow-left"></i> {{'cc.common.view.prev' | translate}}</button>
      <button class="btn btn-secondary move-right"
              (click)="actCancel()"><i class="far fa-times-circle"></i> {{'cc.common.edit.cancel' | translate}}
      </button>
      <button class="btn btn-primary" (click)="!isClonedContract ? actSaveItem() : actSaveClonedItem()"><i
        class="fas fa-plus"></i> {{'cc.common.view.create' | translate}}</button>
    </div>

  </div>
