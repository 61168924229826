/*BbmTestObj*/
import {McBillingPayoutTableItemGENERATED} from '../_generated/mc-billing-payout-table-item-generated.model';

export class McBillingPayoutTableItem extends McBillingPayoutTableItemGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingPayoutTableItem();
  }

  // ---------------------------------------------------------------------
}
