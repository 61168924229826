import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IPpOrderItem} from '../_generated/pp-order-item.interface';
import {PpOrderItem} from '../models/pp-order-item.model';
import {PpOrderItemList} from '../models/pp-order-item-list.model';
import {PpOrderItemServiceGENERATED} from '../_generated/pp-order-item-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PpOrderItemService extends PpOrderItemServiceGENERATED {

}
