<ng-template #content let-modal>

      <!--Modal header section-->
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!--Modal body section-->
      <div class="modal-body">

        <!--Edit personal info title-->
        <h4 class="modal-title" id="modal-basic-title">{{ 'cc.common.edit.edit-personal-info' | translate }}</h4>
        <div class="mc-notify-wrapper">
          <!--Notification message-->
          <mc-notify-message [message]="message" [type]="type"></mc-notify-message>
        </div>
        <!--Edit personal info form-->
        <form [formGroup]="editPersonalInfoForm" style="width: 1000px; font-size: 105%;">
          <div class="mc-edit-personal-info-modal-wrapper">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="first-name" class="label-position">{{ 'cc.common.edit.first-name' | translate }} <i class="required-input">*</i></label>
                      <input type="text"
                             name="firstName"
                             class="form-control"
                             [ngClass]="{'is-invalid' : editPersonalInfoForm.get('firstName')?.touched && editPersonalInfoForm.get('firstName')?.invalid}"
                             id="first-name"
                             formControlName="firstName"
                             (input)="checkMandatoryInputFields('firstName')"
                             #FirstName
                             (change)="onFirstNameChanged($event)"
                             required>
                    </div>
                    <div class="form-group">
                      <label for="last-name" class="label-position">{{ 'cc.common.edit.last-name' | translate }} <i class="required-input">*</i></label>
                      <input type="text"
                             name="lastName"
                             class="form-control"
                             [ngClass]="{'is-invalid' : editPersonalInfoForm.get('lastName')?.touched && editPersonalInfoForm.get('lastName')?.invalid}"
                             id="last-name"
                             formControlName="lastName"
                             (input)="checkMandatoryInputFields('lastName')"
                             #LastName
                             (change)="onLastNameChanged($event)"
                             required>
                    </div>
                    <div class="form-group">
                      <label for="nick-name" class="label-position">Nickname</label>
                      <input type="text"
                             name="nickname"
                             class="form-control"
                             id="nick-name"
                             formControlName="nickname">
                    </div>
                    <div class="form-group gender">
                      <span>Gender <i class="required-input">*</i></span>
                      <div>
          <span class="mr-3 form-group">
            <input type="radio" id="male" value="MALE" name="gender" formControlName="gender">
            <label for="male" style="font-weight: 300;">{{ 'cc.common.edit.male' | translate }}</label>
          </span>
                        <span class="mr-3 form-group">
            <input type="radio" id="female" value="FEMALE" name="gender" formControlName="gender">
            <label for="female" style="font-weight: 300;">{{ 'cc.common.edit.female' | translate }}</label>
          </span>
                        <span class=" mr-3 form-group">
            <input type="radio" id="hidden" value="OTHER" name="gender" formControlName="gender">
            <label for="hidden" style="font-weight: 300;">{{ 'cc.common.edit.prefer-not-to-say' | translate }}</label>
          </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="userLanguage">{{'cc.users.edit.language' | translate}}</label>
                      <select *ngIf="languages" class="form-control" id="userLanguage" formControlName="language">
                        <option *ngFor="let language of languages" [value]="language.value">{{language.name}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="userTimezone">{{'cc.users.edit.timezone' | translate}}</label>
                      <select *ngIf="timezones" class="form-control" id="userTimezone" formControlName="timezone">
                        <option *ngFor="let timezone of timezones" [value]="timezone">{{timezone}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="userCountry">{{'cc.users.edit.country' | translate}}</label>
                      <select *ngIf="countries" class="form-control" id="userCountry" formControlName="country">
                        <option *ngFor="let country of countries" [value]="country.value">{{country.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dol-md-3">
                <div class="col-md-12">
                  <span class="image">{{ 'cc.common.edit.image' | translate }}</span>
                  <img [src]="imageUrl" class="personal-image" alt="Personal image">
                  <input type="file" accept="image/*" class="hidden-input" formControlName="image" (change)="onFileChanged($event)">
                  <span class="upload-img">{{ 'cc.common.edit.upload-image' | translate }}</span>
                </div>
              </div>
            </div>
          </div>
          <br>
          <br>
          <!--Submit form-->
          <div class="row">
            <div class="col-md-12">
              <button type="button" class="modal-save"
                      [ngClass]="FirstName.value !== '' && LastName.value !== '' && validButton === true ? 'mc-confirm-btn' : 'mc-disable-btn'"
                      (click)="changeUserData()"
                      [disabled]="FirstName.value === '' || LastName.value === ''"
                      [disabled]="!validButton"
              >{{'cc.common.view.save' | translate}}</button>
            </div>
          </div>
        </form>
      </div>





</ng-template>


<!--Link for open modal-->
<a class="mc-personal-info-edit mc-cursor-pointer ml-1" (click)="openModal(content)"><i class="fas fa-pen"></i></a>
