/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EbSepaImport} from '../models/eb-sepa-import.model';
import {EbSepaImportList} from '../models/eb-sepa-import-list.model';
import {EbSepaImportFilter} from '../models/eb-sepa-import-filter.model';
import {EbSepaImportService} from '../services/eb-sepa-import.service';
import {IEbSepaImport} from '../_generated/eb-sepa-import.interface';
import {McGod} from "../extra/mc-god.service";

export class EbSepaImportListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'acceptedAmount', 'acceptedDebtors', 'allDebtors', 'idMcEntity', 'idWebfile', 'overwrittenAmount', 'overwrittenCnt', 'processedEarlierCnt', 'processedNowCnt', 'rejectedAmount', 'rejectedDebtors', 'remainingAmount', 'requestedCollectionDate', 'servicePeriod', 'statusCd', 'sysCreatedDatetime', 'totalAmountDue', 'childEntityName', 'reporting', 'requestedOrBatchCollectionDates', 'sepaFilename'];
  public items: EbSepaImport[] = [];
  public apiService: EbSepaImportService;
 // protected tempObjList: EbSepaImportList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbSepaImportList {
    console.error('MTCN-ERROR: Not Implemented: EbSepaImportList::createNewInstance(). Add this method to final class and return new EbSepaImportList();');
    throw new Error('Cannot EbSepaImportList::createNewInstance(). Add this method to final class and return new EbSepaImportList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEbSepaImport[]): EbSepaImportList {
    const  listObj: EbSepaImportList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EbSepaImport = EbSepaImport.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EbSepaImportList {
    const  listObj: EbSepaImportList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbSepaImportService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEbSepaImport[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EbSepaImport = EbSepaImport.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEbSepaImportFilter: EbSepaImportFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEbSepaImportFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EbSepaImport {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EbSepaImport();
    }
  }

  public replaceItem(newItem: EbSepaImport) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadByEntityId( idMcEntity: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByEntityId(idMcEntity, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

// ---------------------------------------------------------------------
  public callImportFile( overwriteFlg: boolean, ebSepaFile: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.importFile(overwriteFlg,ebSepaFile)
      .subscribe(responseJson => {
        this.setApiSuccess();
        this.processApiSuccess(responseJson, onSuccessCallback);
      },
      errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

}

