import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  EbTransactionAdjustment,
  EbSepaFileTransaction,
  EbSepaFileTransactionList,
  EbFactoring, CurrencyUtil, PmTransaction, PmAdjustment, EbSepaTransactionService, CcMollieTransactionService
} from '@miticon-ui/mc-core';
import {
  McForm,
  McBoolean,
  McGod,
  McTrigger,
  McValueLabelList
} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-shared-transaction-adjustment-add-edit-part',
  templateUrl: './app-shared-transaction-adjustment-add-edit-part.component.html',
})
export class AppSharedTransactionAdjustmentAddEditPartComponent implements OnInit, OnDestroy {
  mcForm: McForm = new McForm();
  saveSubscription!: Subscription;
  deleteSubscription!: Subscription;
  cancelSubscription!: Subscription;
  errorMsgs: string[] = [];
  ebFactoring = new EbFactoring();
  @Input() ebSepaFileTransactionList = new EbSepaFileTransactionList();
  @Input() ebSepaFileTransaction!: EbSepaFileTransaction;
  @Input() ebSepaFileTransactionId = 0;
  @Input() ebTransactionAdjustmentId!: number;
  @Input() ebTransactionAdjustment!: EbTransactionAdjustment;
  @Input() type!: 'payment' | 'adjustment';
  @Input() ebFactoringLevel!: string;
  @Input() pmTransactionId!: number;
  @Input() pmTransaction!: PmTransaction;
  @Input() pmTransactionAdjustmentId!: number;
  @Input() saveTrigger!: McTrigger;
  @Input() cancelTrigger!: McTrigger;
  @Input() deleteTrigger!: McTrigger;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();
  mcGod = McGod.getInstance();
  typeCdVll!: McValueLabelList;
  paymentMethodCdVll!: McValueLabelList;
  paymentAccountEntitiesVll = new McValueLabelList();
  pageTitle = this.mcGod.t('cc.my-factoring.add-adjustment');
  currencyCode!: string;
  pmAdjustment = new PmAdjustment();
  isTypeCredit!: boolean;
  paymentMethod!: string;
  saveButtonDisabled = false;

  constructor(
    private ccMollieTransactionService: CcMollieTransactionService,
    private ebSepaTransactionService: EbSepaTransactionService,
    private toastr: ToastrService) {
  }

  subscribeToTriggers() {
    if (this.saveTrigger) {
      this.saveSubscription = this.saveTrigger.subscribe(() => {
        this.actSave();
      });
    }
    if (this.cancelTrigger) {
      this.cancelSubscription = this.cancelTrigger.subscribe(() => {
        this.actCancel();
      });
    }
    if (this.deleteTrigger) {
      this.deleteSubscription = this.deleteTrigger.subscribe(() => {
        this.actDeleteItem();
      });
    }
  }

  unsubscribeFromTriggers() {
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.pmAdjustment) {
      this.pmAdjustment = new PmAdjustment();
    }
    this.typeCdVll = PmAdjustment.getPaymentMethodCdVll();
    this.paymentMethodCdVll = PmAdjustment.getPaymentMethodCdVll();
    this.paymentMethodCdVll.items.splice(4, 1);
    this.actLoad();
    this.subscribeToTriggers();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromTriggers();
  }

  public actLoad(): void {
    this.currencyCode = this.pmTransaction.mcFactoring.mcCurrency.code;
    this.pmTransaction.paymentAccountEntities.items.map(item => this.paymentAccountEntitiesVll.add(item.id, item.name));
    this.pmAdjustment = new PmAdjustment();
    if (this.type === 'payment') {
      this.pageTitle = this.mcGod.t('cc.my-factoring.add-payment');
    } else {
      this.pageTitle = this.mcGod.t('cc.my-factoring.add-adjustment');
    }
    if (this.pmTransactionAdjustmentId > 0) {
      this.pmAdjustment.loadById(this.pmTransactionAdjustmentId, () => {
        this.type = this.pmAdjustment.isTypeCdPAYMENT() ? 'payment' : 'adjustment';
        this.setPageTitle();
      });
    } else {
      if (this.isTypePayment()) {
        this.pmAdjustment.typeCd = PmAdjustment.TYPE_CD_PAYMENT;
        this.pmAdjustment.paymentMethodCd = PmAdjustment.PAYMENT_METHOD_CD_CASH;
      }
    }
  }

  actSave() {
    this.saveButtonDisabled = true;
    if (this.mcForm.isValid()) {
      this.pmAdjustment.idPmTransaction = this.pmTransactionId;
      if (this.isTypePayment()) {
        this.pmAdjustment.typeCd = EbTransactionAdjustment.TYPE_CD_PAYMENT;
        this.pmAdjustment.paymentMethodCd = this.mcForm.getValue('rbPaymentMethodCd');
      } else {
        this.pmAdjustment.typeCd = this.mcForm.getValue('rbTypeCd');
      }
      if (!this.isTypeCredit) {
        this.pmAdjustment.amount = this.mcForm.getValue('ctrlAmount');
      }
      this.pmAdjustment.description = this.mcForm.getValue('ctrlDescription');

      const transactionIds: number[] = [];
      transactionIds.push(this.pmTransaction.id);

      const body: any = {};
      body.ids = transactionIds;
      body.paymentMethod = this.pmAdjustment.paymentMethodCd;
      body.amount = this.pmAdjustment.amount;
      body.description = this.pmAdjustment.description;
      body.entityId = this.mcForm.getValue('ctrlPaymentEntity') ? this.mcForm.getValue('ctrlPaymentEntity') : McGod.getLoggedEntityIdFromToken();
      if (this.paymentMethod === PmAdjustment.PAYMENT_METHOD_CD_CREDIT_CARD) {
        this.ccMollieTransactionService.pay(body.ids, body.paymentMethod, body.amount, body.description, body.entityId)
          .subscribe(res => {
            this.eventSaved.emit();
          }, error => {
            this.saveButtonDisabled = false;
            this.toastr.error(error.error.message, '', {timeOut: 4000, extendedTimeOut: 4000});
          });
      } else {
        this.ebSepaTransactionService.pay(body.ids, body.paymentMethod, body.amount, body.description, body.entityId)
          .subscribe(res => {
            this.eventSaved.emit();
          }, error => {
            const err = JSON.parse(error.error);
            this.toastr.error(err.message, '', {timeOut: 4000, extendedTimeOut: 4000});
            this.saveButtonDisabled = false;
          });
      }
    }
  }

  public setPageTitle() {
    if (this.pmAdjustment.id > 0) {
      this.pageTitle = this.mcGod.t('cc.common.view.edit') + ' ' + this.type + '(' + this.pmAdjustment.id + ')';
    } else {
      this.pageTitle = this.mcGod.t('cc.common.table.add') + ' ' + this.type;
    }

  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actDeleteItem() {
    if (this.pmAdjustment.exists()) {

      this.pmAdjustment.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }

  isTypePayment() {
    return this.type === 'payment';
  }

  isTypeAdjustment() {
    return this.type === 'adjustment';
  }

  format(value: number): string {
    if (value) {
      return CurrencyUtil.format(value, this.currencyCode);
    }
    return CurrencyUtil.format(0, this.currencyCode);
  }

  onPaymentTypeValueChange(value: any) {
    this.paymentMethod = value;
    if (value === PmAdjustment.TYPE_CD_CREDIT) {
      this.isTypeCredit = true;
    } else {
      this.isTypeCredit = false;
    }
  }
}
