import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {McGod, McHtmlActionSet, McHtmlButton, McHtmlLink, ActionOptions} from '@miticon-ui/mc-core';
import {Router} from '@angular/router';
import {ICON_VIEW_EMAIL} from "../svg_icons/icon-view-email";
import {ICON_PREVIEW_PDF} from "../svg_icons/icon-preview-pdf";
import {ICON_DOWNLOAD_PDF} from "../svg_icons/icon-download-pdf";


@Component({
  selector: 'mcc-html-action-set',
  templateUrl: './mcc-html-action-set.component.html',
  styleUrls: ['./mcc-html-action-set.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MccHtmlActionSetComponent implements OnInit {

  actionSetNearBottomFlg = false;
  actionSetWithOneItem = false;
  actionSetWithThreeItems = false;

  @Input() actionSet = new McHtmlActionSet();
  @Input() extraCssClasses = '';

  actionSetView = new McHtmlActionSet();
  actionSetEdit = new McHtmlActionSet();
  actionSetDelete = new McHtmlActionSet();
  actionSetNoOptions = new McHtmlActionSet();

  /*Events*/
  @Output() eventButtonClicked = new EventEmitter<McHtmlButton>();

  iconViewEmail = ICON_VIEW_EMAIL;
  iconPreviewPdf = ICON_PREVIEW_PDF;
  iconDownloadPdf = ICON_DOWNLOAD_PDF;

  /*Constructor*/
  constructor(private router: Router) {
  }

  ngOnInit() {
    this.actionSetView.items = this.actionSet.items.filter(item => item.actionOptions === ActionOptions.OPTION_VIEW);
    this.actionSetEdit.items = this.actionSet.items.filter(item => item.actionOptions === ActionOptions.OPTION_EDIT);
    this.actionSetDelete.items = this.actionSet.items.filter(item => item.actionOptions === ActionOptions.OPTION_DELETE);
    this.actionSetNoOptions.items = this.actionSet.items.filter(item => item.actionOptions === ActionOptions.NO_OPTIONS);
  }

  isItemMcHtmlButtonAndHasPermission(item: any) {
    return (item instanceof McHtmlButton) && (McGod.userHasPermissions(item.permissions));
  }

  isItemMcHtmlLinkAndHasPermission(item: any) {
    return (item instanceof McHtmlLink) && (McGod.userHasPermissions(item.permissions));
  }

  actGoToPage(item: McHtmlLink) {
    this.router.navigate([item.routeLink], item.queryParams);
  }

  actButtonPressedForItem(item: McHtmlButton) {
    this.eventButtonClicked.emit(item);
  }

  actionSetOnHover(el: { getBoundingClientRect: () => { (): any; new(): any; bottom: any; }; }) {
    const bottomBreakpoint = 300;
    const elementPosition = el.getBoundingClientRect().bottom;
    const windowHeight = window.innerHeight;
    const viewLength = this.actionSetView.items.length;
    const editLength = this.actionSetEdit.items.length;
    const deleteLength = this.actionSetDelete.items.length;

    switch (true) {
      case (windowHeight - elementPosition > bottomBreakpoint):
        this.actionSetWithOneItem = false;
        this.actionSetNearBottomFlg = false;
        this.actionSetWithThreeItems = false;
        break;
      case (windowHeight - elementPosition < bottomBreakpoint && deleteLength > 0):
        this.actionSetWithOneItem = false;
        this.actionSetNearBottomFlg = false;
        this.actionSetWithThreeItems = true;
        break;
      case (windowHeight - elementPosition < bottomBreakpoint && editLength < 1 && deleteLength < 1):
        this.actionSetWithOneItem = true;
        this.actionSetNearBottomFlg = false;
        this.actionSetWithThreeItems = false;
        break;
      case (windowHeight - elementPosition < bottomBreakpoint && deleteLength < 1):
        this.actionSetWithOneItem = false;
        this.actionSetNearBottomFlg = true;
        this.actionSetWithThreeItems = false;
        break;
      case (windowHeight - elementPosition < bottomBreakpoint && viewLength < 2 && editLength < 1):
        this.actionSetWithOneItem = false;
        this.actionSetNearBottomFlg = false;
        this.actionSetWithThreeItems = false;
        break;
      case (windowHeight - elementPosition < bottomBreakpoint && editLength < 2 && viewLength < 1):
        this.actionSetWithOneItem = false;
        this.actionSetNearBottomFlg = false;
        this.actionSetWithThreeItems = false;
        break;
    }
  }

  getIcon(label: string) {
    switch (label) {
      case "View email":
        return this.iconViewEmail;
      case "Preview pdf":
        return this.iconPreviewPdf;
      case "Download pdf":
        return this.iconDownloadPdf;
      default:
        return "";
    }
  }
}
