import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {McCustomAttribute, McEntityService, McForm, McNotifyMessageModel, McValueLabelList} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-custom-attribute-add-edit-part',
  templateUrl: './app-shared-custom-attribute-add-edit-part.component.html',
})
export class AppSharedCustomAttributeAddEditPartComponent implements OnInit {

  mcForm = new McForm();
  enabledVlm = new Map<string, string>();
  booleanVlm = new Map<string, string>();
  inheritableVlm = new Map<string, string>();
  requiredVlm = new Map<string, string>();
  typeVll = new McValueLabelList();
  defaultValueCbChecked!: boolean;
  notifyMessage!: McNotifyMessageModel;
  @Input() entityAttributes!: McCustomAttribute;
  @Output() cancelEmit = new EventEmitter();

  /*Constructor*/
  constructor(private entitiesService: McEntityService) {
  }

  /*On init*/
  ngOnInit() {
    this.notifyMessage = {message: '', type: ''};

    this.typeVll.add('INTEGER', 'Number');
    this.typeVll.add('VARCHAR', 'String');
    this.typeVll.add('DATE', 'Date');
    this.typeVll.add('DATETIME', 'Date Time');
    this.typeVll.add('FLOAT', 'Decimal');
    this.typeVll.add('BOOLEAN', 'Yes/No');
    this.typeVll.add('', '-- please choose');

    this.enabledVlm.set('yes', 'Yes');
    this.enabledVlm.set('no', 'No');
    this.booleanVlm.set('no', 'No');
    this.booleanVlm.set('yes', 'Yes');
    this.inheritableVlm.set('no', 'No');
    this.inheritableVlm.set('yes', 'Yes');
    this.requiredVlm.set('no', 'No');
    this.requiredVlm.set('yes', 'Yes');
  }

  actCancel() {
    this.cancelEmit.emit();
  }

  actAddAttribute() {
    let defaultValueLocalVariable;
    let enabledLocalVariable;
    let inheritableLocalVariable;
    let requiredLocalVariable;
    if (this.mcForm.isValid()) {
      if (this.checkAttributeUniqueName()) {
        if (this.mcForm.getValue('defaultValueCb')) {
          defaultValueLocalVariable = this.mcForm.getValue('defaultValue');
        } else {
          defaultValueLocalVariable = '';
        }
        if (this.mcForm.getValue('type') === 'varchar' && this.mcForm.getValue('length') === '') {
          this.mcForm.getControl('length')?.get('value')?.setValue('255');
        }
        enabledLocalVariable = this.mcForm.getValue('enabled') === 'yes';
        inheritableLocalVariable = this.mcForm.getValue('inheritable') === 'yes';
        requiredLocalVariable = this.mcForm.getValue('required') === 'yes';
        const newAttribute = {
          enabled: enabledLocalVariable,
          name: this.mcForm.getValue('name'),
          length: this.mcForm.getValue('length'),
          type: this.mcForm.getValue('type'),
          validationRegex: this.mcForm.getValue('validation'),
          description: this.mcForm.getValue('description'),
          inheritable: inheritableLocalVariable,
          required: requiredLocalVariable,
          defaultValueSelected: this.mcForm.getValue('defaultValueCb'),
          defaultValue: defaultValueLocalVariable,
          inherited: false
        };
        console.log('NEW ATTRIBUTE ``````````````````````', newAttribute);
        this.entityAttributes.updateCustomAttributes(newAttribute, this.entitiesService).then(data => this.actCancel());
      } else {
        this.notifyMessage = {message: 'Attribute with this name already exist', type: 'error'};
      }
    }
  }

  checkAttributeUniqueName() {
    const unique = this.entityAttributes.entityAttributesList.find( item => item === this.mcForm.getValue('name'));
    return unique === undefined;
  }

  defaultValueCbValueChanged(value: boolean) {
    this.defaultValueCbChecked = value;
  }

  typeSelectValueChanged(value: string) {
    this.mcForm.getControl('length')?.get('value')?.setValue('');
  }
}
