import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {
  McDateUtils,
  McEntityConsumerAttribute,
  McGod,
  CurrencyUtil, BaseDirective, CsrConsumer, CsrBankAccount,
  CsrBankAccountService,
  CsrContract
} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-consumer-consumer-profile-part',
  templateUrl: './mc-consumer-consumer-profile-part.component.html',
  styleUrls: ['./mc-consumer-consumer-profile-part.component.scss']
})
export class McConsumerConsumerProfilePartComponent extends BaseDirective implements OnInit {

  // -- Inputs --
  @Input() csrConsumerId!: number;
  csrConsumer = new CsrConsumer();
  @Output() eventClosed = new EventEmitter();
  mcGod = McGod.getInstance();
  @Input() currencyCode: string;
  csrBankAccount!: CsrBankAccount | undefined;
  csrContract !: CsrContract | undefined;

  constructor() {
      super();
  }

  ngOnInit() {
    //this.csrBankAccount = this.csrConsumer.bankAccounts.items.find(bankAcc => bankAcc.flgPrimary);
  
      this.csrConsumer.apiLoadingFlg = true
      this.csrConsumer.loadById(this.csrConsumerId, () => {
          this.csrConsumer.apiLoadingFlg = false;
          this.csrBankAccount = this.csrConsumer.bankAccounts.items.find(bankAcc => bankAcc.flgPrimary)
          this.csrContract = this.csrConsumer.activeContracts.items.find(contract => !contract.consumer360Flg)
        });
  }

  actClose() {
    this.eventClosed.emit();
  }


  showCustomAttribute(customAttribute: McEntityConsumerAttribute) {
    let returnValue;
    if (customAttribute.typeCd === McEntityConsumerAttribute.TYPE_CD_DATE && customAttribute.value !== '') {
      returnValue = McDateUtils.formatDateWithoutTimezone(customAttribute.value);
    } else if (customAttribute.typeCd === McEntityConsumerAttribute.TYPE_CD_BOOLEAN && customAttribute.value !== '') {
      returnValue = customAttribute.value === 'false' || customAttribute.value === '0' ? 'No' : 'Yes';
    } else {
      returnValue = customAttribute.value;
    }

    return returnValue;
  }

  format(value: number): string {
    if (value) {
      return CurrencyUtil.format(value, this.currencyCode);
    }
    return CurrencyUtil.format(0, this.currencyCode);
  }
}


