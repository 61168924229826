import { Component,OnInit } from '@angular/core';
import { MkFilterConfig, MkFilterOutput, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig } from '@miticon-ui/mc-components';
import {
  BaseDirective,
  EbSepaExportService,
  McBankAccount,
  McBankAccountFilter,
  McBankAccountService,
  McBoolean,
  McEbics,
  McEbicsFilter, McGod,
  SortCriteriaList
} from '@miticon-ui/mc-core';
import { TranslateService } from '@ngx-translate/core';
import * as fileSaver from 'file-saver';
import { ToastrService } from "ngx-toastr";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'mc-ebics-ebics-list-page',
  templateUrl: './mc-ebics-ebics-list-page.component.html',
  styleUrls: ['./mc-ebics-ebics-list-page.component.scss']
})
export class McEbicsEbicsListPageComponent extends BaseDirective implements OnInit {

  mcEbics = new McEbics();
  mcBankAccount = new McBankAccount();
  mcBankAccountFilter = new McBankAccountFilter();
  showCreateEbicsMcb = new McBoolean();
  mcEbicsFilter = new McEbicsFilter();
  pageNumber = 0;
  pageSize = 5;
  isLoading = false;
  totalItemsCount!: number;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  items: McBankAccount[] = [];
  searchTooltip = this.tS.instant('cc.ebics.search-by-bank');

  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.ebics.ebics-report'),
      actionCd: McEbics.ACTION_EBICS_REPORT,
      matIcon: 'remove_red_eye',
      permission: McGod.PERM_EB_FACTORING_EBICS_REPORT_VIEW
    },
    {
      title: this.tS.instant('cc.ebics.edit-ebics-account'),
      actionCd: McEbics.ACTION_EDIT_EBICS_ACCOUNT,
      matIcon: 'key',
      permission: McGod.PERM_EB_FACTORING_EBICS_REPORT_VIEW
    }
  ]

  constructor(private ebSepaExportService: EbSepaExportService,
              private toastr: ToastrService,
              private mcBankAccountService: McBankAccountService,
              private tS: TranslateService) {
    super();
  }

  ngOnInit() {
    this.actLoad(this.pageNumber, this.pageSize, this.mcBankAccountFilter);
    this.initTableConfig();
  }

  public actLoad(pageNumber: number, pageSize: number, mccBankAccFilter: McBankAccountFilter){
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.isLoading = true;
    const sortCriteriaList= new SortCriteriaList();
    this.mcBankAccountService.getByFilter(mccBankAccFilter, this.pageNumber, this.pageSize, sortCriteriaList).subscribe((res) => {
      if (res) {
        this.isLoading = false;
        this.items = res.content
          .filter((object: any) => object.ebicsStatus !== McBankAccount.EBICS_STATUS_NONE)
          .map((object: any) => McBankAccount.createFromJson(object));
        this.totalItemsCount = this.items.length;

      }
    });
  }

  initTableConfig(){
    this.tableConfig.addColumnStandard(this.tS.instant('cc.ebics.bank-name'), 'bankName', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.account-number'), 'accountNumber', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.iban'), 'iban', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.account-owner'), 'accountOwner', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.status'), 'getEbicsStatusLabel()', 250, '');
  }

  onFilterChanged(filterOutput: MkFilterOutput): void {
    this.mcEbicsFilter.searchTerm = filterOutput.search ? filterOutput.search : '';
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, this.mcEbicsFilter);
  }

  onCreateEbicsAccount() {
    this.mcBankAccount = new McBankAccount();
    this.showCreateEbicsMcb.setTrue();
  }

  onTableAction($event: MkMatTableMenuAction) {
    this.mcEbics = $event.item;
    console.log($event.item);
    switch ($event.action.actionCd) {
      case McEbics.ACTION_EDIT_EBICS_ACCOUNT:
        this.mcBankAccount = $event.item;
        this.showCreateEbicsMcb.setTrue();
        break;

      case McEbics.ACTION_EBICS_REPORT:
        this.ebSepaExportService.downloadEbicsFile(this.mcEbics.id).pipe(takeUntil(this.destroyed$))
          .subscribe((response) => {
            fileSaver.saveAs(response, `Ebics #${this.mcEbics.id}`);
          }, (error) => {
            this.toastr.error(error.error.message);
          });
        break;
    }
  }

  onCanceledEbicsCreation() {
    this.actLoad(this.pageNumber, this.pageSize, this.mcBankAccountFilter);
    this.showCreateEbicsMcb.setFalse();
  }
}
