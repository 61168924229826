/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CsrReccuringOptionsTable3ConfigGENERATED} from '../_generated/csr-reccuring-options-table-3-config-generated.model';

// import {CsrReccuringOptionsGENERATED} from '../_generated/csr-reccuring-options-generated.model';

export class CsrReccuringOptionsTable3Config extends CsrReccuringOptionsTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colName', 'Reccuring option name', 'name', 'name');


  }
}
