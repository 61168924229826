
import {Table3Config} from '../extra/table-3-config';

// import {CsrContractProductsGENERATED} from '../_generated/csr-contract-products-generated.model';

export class CsrContractProductsTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colQuantity', 'Qauntity', 'quantity', 'quantity');

*/
  }
}
