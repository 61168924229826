<div class="mcc-fi mcc-fi-textarea mcc-fi-textarea-{{name}} {{extraCssClasses}}"
     [ngClass]="{'validation-error' : this.getTextAreaForm().get('errorFlg')?.value}">
  <form [formGroup]="getTextAreaForm()">
    <label *ngIf="label" for="mcc-fi-{{name}}" [ngClass]="{'mc-mandatory-star' : validRequiredFlg}">{{label}}</label>
    <textarea
      id="mcc-fi-{{name}}"
      name="txtArea-{{name}}"
      formControlName="value"
      rows="{{numRows}}"
      cols="{{numCols}}"
      [placeholder]="placeholder"
      [required]="validRequiredFlg"
      [readOnly]="readOnlyFlg"
      [value]="value ? value : ''"
      (ngModelChange)="onTextAreaChanged($event)">
    </textarea>
    <div class="error-msg">{{this.getTextAreaForm().get('errorMsg')?.value}}</div>
  </form>
</div>
