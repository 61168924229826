import {McActionPdfTemplate} from './mc-action-pdf-template.model';
import {McActionPdfTemplateListGENERATED} from '../_generated/mc-action-pdf-template-list-generated.model';

export class McActionPdfTemplateList extends McActionPdfTemplateListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McActionPdfTemplateList();
  }

  // ---------------------------------------------------------------------
}
