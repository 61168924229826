/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBillingProcessedTransactionsInfoTable} from '../models/mc-billing-processed-transactions-info-table.model';
import {McBillingProcessedTransactionsInfoTableService} from '../services/mc-billing-processed-transactions-info-table.service';
import {IMcBillingProcessedTransactionsInfoTable} from '../_generated/mc-billing-processed-transactions-info-table.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';




export abstract class McBillingProcessedTransactionsInfoTableGENERATED extends BaseObject {














  public apiService: McBillingProcessedTransactionsInfoTableService;
  public _rawJson: IMcBillingProcessedTransactionsInfoTable;
    id: number = 0;
  ftAcceptedForFactoringA: number;
  ftAcceptedForFactoringB: number;
  ftAcceptedForFactoringC: number;
  ftImmediatelyPayableBalanceA: number;
  ftImmediatelyPayableBalanceB: number;
  ftImmediatelyPayableBalanceC: number;
  ftLaterPayableBalanceA: number;
  ftLaterPayableBalanceB: number;
  ftLaterPayableBalanceC: number;
  ftNotAcceptedForFactoringA: number;
  ftNotAcceptedForFactoringB: number;
  ftNotAcceptedForFactoringC: number;


  public properties: string[] = ['id', 'ftAcceptedForFactoringA', 'ftAcceptedForFactoringB', 'ftAcceptedForFactoringC', 'ftImmediatelyPayableBalanceA', 'ftImmediatelyPayableBalanceB', 'ftImmediatelyPayableBalanceC', 'ftLaterPayableBalanceA', 'ftLaterPayableBalanceB', 'ftLaterPayableBalanceC', 'ftNotAcceptedForFactoringA', 'ftNotAcceptedForFactoringB', 'ftNotAcceptedForFactoringC'];
  public propertiesRegular: string[] = ['id', 'ftAcceptedForFactoringA', 'ftAcceptedForFactoringB', 'ftAcceptedForFactoringC', 'ftImmediatelyPayableBalanceA', 'ftImmediatelyPayableBalanceB', 'ftImmediatelyPayableBalanceC', 'ftLaterPayableBalanceA', 'ftLaterPayableBalanceB', 'ftLaterPayableBalanceC', 'ftNotAcceptedForFactoringA', 'ftNotAcceptedForFactoringB', 'ftNotAcceptedForFactoringC'];
  public propertiesSpecial: string[] = [];














  // ---------------------------------------------------------------------
public static createNewInstance(): McBillingProcessedTransactionsInfoTable {
    console.error('MTCN-ERROR: Not Implemented: McBillingProcessedTransactionsInfoTable::createNewInstance(). Add this method to final class and return new McBillingProcessedTransactionsInfoTable();');
    throw new Error('Cannot McBillingProcessedTransactionsInfoTable::createNewInstance(). Add this method to final class and return new McBillingProcessedTransactionsInfoTable();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBillingProcessedTransactionsInfoTable): McBillingProcessedTransactionsInfoTable {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingProcessedTransactionsInfoTableService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBillingProcessedTransactionsInfoTable AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getFtAcceptedForFactoringAStr(): string {
       return McGod.formatNumberToMoney(this.ftAcceptedForFactoringA);
    }

    public getFtAcceptedForFactoringBStr(): string {
       return McGod.formatNumberToMoney(this.ftAcceptedForFactoringB);
    }

    public getFtAcceptedForFactoringCStr(): string {
       return McGod.formatNumberToMoney(this.ftAcceptedForFactoringC);
    }

    public getFtImmediatelyPayableBalanceAStr(): string {
       return McGod.formatNumberToMoney(this.ftImmediatelyPayableBalanceA);
    }

    public getFtImmediatelyPayableBalanceBStr(): string {
       return McGod.formatNumberToMoney(this.ftImmediatelyPayableBalanceB);
    }

    public getFtImmediatelyPayableBalanceCStr(): string {
       return McGod.formatNumberToMoney(this.ftImmediatelyPayableBalanceC);
    }

    public getFtLaterPayableBalanceAStr(): string {
       return McGod.formatNumberToMoney(this.ftLaterPayableBalanceA);
    }

    public getFtLaterPayableBalanceBStr(): string {
       return McGod.formatNumberToMoney(this.ftLaterPayableBalanceB);
    }

    public getFtLaterPayableBalanceCStr(): string {
       return McGod.formatNumberToMoney(this.ftLaterPayableBalanceC);
    }

    public getFtNotAcceptedForFactoringAStr(): string {
       return McGod.formatNumberToMoney(this.ftNotAcceptedForFactoringA);
    }

    public getFtNotAcceptedForFactoringBStr(): string {
       return McGod.formatNumberToMoney(this.ftNotAcceptedForFactoringB);
    }

    public getFtNotAcceptedForFactoringCStr(): string {
       return McGod.formatNumberToMoney(this.ftNotAcceptedForFactoringC);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  













  
}
