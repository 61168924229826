import {EbSepaFile} from './eb-sepa-file.model';
import {EbSepaFileListGENERATED} from '../_generated/eb-sepa-file-list-generated.model';
import {EbSepaFileService} from "../services/eb-sepa-file.service";

export class EbSepaFileList extends EbSepaFileListGENERATED {

  public override apiService!: EbSepaFileService;
  public static override createNewInstance() {
    return new EbSepaFileList();
  }

}
