/*BbmTestObj*/
import {McEbicsGENERATED} from '../_generated/mc-ebics-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {ActionOptions} from '../_core/mc-html-action-options';
export enum EbicsBankAccountStatus {
  NONE = 'NONE',
  NEW = 'NEW',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
}

export interface EbicsBankAccount {
  id: number;
  bankAccountId: number;
  entityId: number;
  userInitiated: boolean;
  iniSent: boolean;
  hiaSent: boolean;
  hpbSent: boolean;
  status: EbicsBankAccountStatus;
}

export interface PropertiesData {
  userId: number;
  partnerId: string;
  hostId: string;
  bankUrl: string;
  bankName: string;
  userName: string;
  userEmail: string;
  userCountry: string;
  userOrg: string;
  instituteId: string;
  productName: string;
  languageCode: string;
  countryCode: string;
  password: string;
}

export class McEbics extends McEbicsGENERATED {
  public static ACTION_EDIT_EBICS_ACCOUNT = 'EDIT-EBICS-ACCOUNT';
  public static ACTION_EBICS_REPORT = 'EBICS_REPORT';




  private _fldActions: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McEbics();
  }
}
