import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {McBillingStatement, McEntity2, McGod, WebFile} from '@miticon-ui/mc-core';
import {take} from 'rxjs/operators';

@Component({
  selector: 'mc-out-billing-finalize-statement-part',
  templateUrl: './mc-out-billing-finalize-statement-part.component.html',
})
export class McOutBillingFinalizeStatementPartComponent implements OnInit {

  mcStatementStatusLabel!: string;
  entityAddress!: string;
  errorMsg = '';
  mcEntity = new McEntity2();
  webFile = new WebFile();
  mcGod = McGod.getInstance();

  @Input() mcStatement!: McBillingStatement;
  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter<boolean>();

  /*On init*/
  ngOnInit() {
    this.mcStatementStatusLabel = this.mcStatement.getStatusCdLabel();
    this.mcEntity.loadById(this.mcStatement.childEntityId, () => {
      this.entityAddress = ` <div>${this.mcEntity.studioOwner} <br> ${this.mcEntity.studioOwnerAddress} <br>${this.mcEntity.studioOwnerPostalCode} ${this.mcEntity.studioOwnerCity}</div>`;
    }, () => {
      this.errorMsg = this.mcEntity.apiErrorMessage;
    });
    this.webFile.getFileByIdAndType(this.mcStatement.pdfWebfileId, WebFile.TYPE_CD_MC_BILLING_STATEMENT_PAYOUT_REPORT_PDF, () => {

    });
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actSave() {
    const statementIds = [];
    statementIds.push(this.mcStatement.id);
    this.mcStatement.apiService.finalizeStatement(statementIds).pipe(take(1)).subscribe((response) => {
      this.eventSaved.emit(true);
    }, () => {
      this.errorMsg = this.mcStatement.apiErrorMessage;
    });
  }
}
