/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {CsrConsumerImport} from '../models/csr-consumer-import.model';
import {CsrConsumerImportList} from '../models/csr-consumer-import-list.model';
import {CsrConsumerImportFilter} from '../models/csr-consumer-import-filter.model';
import {CsrConsumerImportService} from '../services/csr-consumer-import.service';
import {ICsrConsumerImport} from '../_generated/csr-consumer-import.interface';
import {McGod} from "../extra/mc-god.service";

export class CsrConsumerImportListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'addedRecords', 'createdAt', 'createdBy', 'dateFormat', 'idMcEntity', 'idMcSystemUser', 'idWebFile', 'importModeCd', 'processedRecords', 'skippedRecords', 'statusCd', 'updatedAt', 'updatedBy', 'updatedRecords', 'importedBy', 'importName'];
  public items: CsrConsumerImport[] = [];
  public apiService: CsrConsumerImportService;
 // protected tempObjList: CsrConsumerImportList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrConsumerImportList {
    console.error('MTCN-ERROR: Not Implemented: CsrConsumerImportList::createNewInstance(). Add this method to final class and return new CsrConsumerImportList();');
    throw new Error('Cannot CsrConsumerImportList::createNewInstance(). Add this method to final class and return new CsrConsumerImportList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: ICsrConsumerImport[]): CsrConsumerImportList {
    const  listObj: CsrConsumerImportList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: CsrConsumerImport = CsrConsumerImport.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): CsrConsumerImportList {
    const  listObj: CsrConsumerImportList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrConsumerImportService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: ICsrConsumerImport[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: CsrConsumerImport = CsrConsumerImport.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aCsrConsumerImportFilter: CsrConsumerImportFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aCsrConsumerImportFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): CsrConsumerImport {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new CsrConsumerImport();
    }
  }

  public replaceItem(newItem: CsrConsumerImport) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
// ---------------------------------------------------------------------
public callImportConsumers( mappingId: number, handleDuplicatesCd: string, dateFormat: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.importConsumers(mappingId,handleDuplicatesCd,dateFormat).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

}

