/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CsrConsumer2CtdEmailTable3ConfigGENERATED} from '../_generated/csr-consumer-2-ctd-email-table-3-config-generated.model';

// import {CsrConsumer2CtdEmailGENERATED} from '../_generated/csr-consumer-2-ctd-email-generated.model';

export class CsrConsumer2CtdEmailTable3Config extends CsrConsumer2CtdEmailTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colIdCsrConsumer', 'Consumer ID', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colCsrConsumer', 'Consumer', 'getCsrConsumer()', 'csrConsumer');
    this.addColumn('colCtdEmail', 'Email', 'getEmail()', 'ctdEmail');
    this.addColumn('colDateSent', 'Date sent', 'getDateSent()', 'date');
    this.addColumn('colType', 'Type', 'getType()', 'typeCd');
    this.addColumn('colCtdEmailStatus', 'Status', 'getEmailStatus()', 'getEmailStatus()');

    // this.addColumn('colDescription', 'Description', 'description', 'description');
    // this.addColumn('colIdCtdEmail', 'Ctd Email Id', 'idCtdEmail', 'idCtdEmail');

  }
}
