import {ZzPocConsumer} from './zz-poc-consumer.model';
import {ZzPocConsumerListGENERATED} from '../_generated/zz-poc-consumer-list-generated.model';

export class ZzPocConsumerList extends ZzPocConsumerListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new ZzPocConsumerList();
  }

  // ---------------------------------------------------------------------
}
