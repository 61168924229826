import {ZzPocCar} from './zz-poc-car.model';
import {ZzPocCarListGENERATED} from '../_generated/zz-poc-car-list-generated.model';

export class ZzPocCarList extends ZzPocCarListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new ZzPocCarList();
  }

  // ---------------------------------------------------------------------
}
