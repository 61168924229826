import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {McUtilityService} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-add-product-to-invoice',
  templateUrl: './mc-add-product-to-invoice.component.html',
})
export class McAddProductToInvoiceComponent implements OnInit {

  @Input() product: any;
  @Output() onSendProduct = new EventEmitter();

  constructor(private utilityService: McUtilityService) { }

  ngOnInit() {
    if (this.product) {
      this.product.numberOfProducts = 0;
    }
  }

  validateNumber(event: any) {
    if (event.key === '.' || event.key === '-' || event.key === '+' || event.key === 'e') {
      event.preventDefault();
    }

    this.onChangeValue();
  }

  /*On change value*/
  public onChangeValue() {
    this.onSendProduct.emit({
      product: this.product
    });
  }

  public onChangeNumberOfElements(minus: boolean): void {
    minus ? this.product.numberOfProducts-- : this.product.numberOfProducts++;
    this.onChangeValue();
  }
}
