import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {McEbicsServiceGENERATED} from '../_generated/mc-ebics-generated.service';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class McEbicsService extends McEbicsServiceGENERATED {

  constructor(private http: HttpClient) {
    super();
  }

  saveEbicsUser(entityId: number, bankAccountId: number, properties: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/ebfactoring/v2/ebics/${entityId}/bank-account/${bankAccountId}/user`, properties, {responseType: 'text'});
  }

  updateEbicsUser(entityId: number, bankAccountId: number, properties: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/ebfactoring/v2/ebics/${entityId}/bank-account/${bankAccountId}/user`, properties);
  }

  generateKeys(entityId: number, bankAccountId: number): Observable<any> {
    // @ts-ignore
    return this.http.post<any>(`${this.apiUrl}/ebfactoring/v2/ebics/${entityId}/bank-account/${bankAccountId}/letters`, {}, { ...this.httpOptions, responseType: 'text'});
  }

  getLetterPath(entityId: number, bankAccountId: number, letterCode: string): Observable<any> {
    // @ts-ignore
    return this.http.get<any>(`${this.apiUrl}/ebfactoring/v2/ebics/${entityId}/bank-account/${bankAccountId}/letters?letterCode=${letterCode}`, { ...this.httpOptions, responseType: 'blob'});
  }

  sendRequest(entityId: number, bankAccountId: number, requestCode: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/ebfactoring/v2/ebics/${entityId}/bank-account/${bankAccountId}/request?requestCode=${requestCode}`, {});
  }

  getEbicsUser(entityId: number, bankAccountId: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/ebfactoring/v2/ebics/${entityId}/bank-account/${bankAccountId}/user`);
  }



}
