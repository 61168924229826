
import {Table3Config} from '../extra/table-3-config';

// import {McProcessFlowCriteriaAttributeGENERATED} from '../_generated/mc-process-flow-criteria-attribute-generated.model';

export class McProcessFlowCriteriaAttributeTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAttributeName', 'Attribute name', 'attributeName', 'attributeName');
    this.addColumn('colAttributeTypeCd', 'Attribute type code', 'attributeTypeCd', 'attributeTypeCd');
    this.addColumn('colBooleanOperatorTypeCd', 'Boolean operator type code', 'booleanOperatorTypeCd', 'booleanOperatorTypeCd');
    this.addColumn('colDatetimeOperatorTypeCd', 'Datetime operator type code', 'datetimeOperatorTypeCd', 'datetimeOperatorTypeCd');
    this.addColumn('colDatetimeOptionTypeCd', 'Datetime option type code', 'datetimeOptionTypeCd', 'datetimeOptionTypeCd');
    this.addColumn('colMoneyOperatorTypeCd', 'Money operator type code', 'moneyOperatorTypeCd', 'moneyOperatorTypeCd');
    this.addColumn('colMoneyOptionTypeCd', 'Money option type code', 'moneyOptionTypeCd', 'moneyOptionTypeCd');
    this.addColumn('colNumberOperatorTypeCd', 'Number operator type code', 'numberOperatorTypeCd', 'numberOperatorTypeCd');
    this.addColumn('colNumberOptionTypeCd', 'Number option type code', 'numberOptionTypeCd', 'numberOptionTypeCd');
    this.addColumn('colStringOperatorTypeCd', 'String operator type code', 'stringOperatorTypeCd', 'stringOperatorTypeCd');
    this.addColumn('colStringOptionTypeCd', 'String option type code', 'stringOptionTypeCd', 'stringOptionTypeCd');
    this.addColumn('colValue', 'Value', 'value', 'value');

*/
  }
}
