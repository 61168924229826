import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {McGod, McValueLabelList} from '@miticon-ui/mc-core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'mcc-multiselect-filter',
  templateUrl: './mcc-multiselect-filter.component.html',
  styleUrls: ['./mcc-multiselect-filter.component.scss'],
  // eslint-disable-next-line
  host: {
    '(document:click)': 'onClick()',
  },
})
export class MccMultiselectFilterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() multiselectItems = [];
  showMultiselectItems!: boolean;
  itemsSelected: any[] = [];
  itemsSelectedLabels: any[] = [];
  itemsSelectedNumber = 0;
  itemTitles: any[] = [];
  checkedCheckboxes!: ElementRef<HTMLInputElement>[];
  checkedItems: any[] = [];
  destroy$ = new Subject<boolean>();
  options: {value: string, label: string, checked: boolean}[] = [];
  errorMsg!: string;

  @Input() optionVll!: McValueLabelList;
  @Input() name!: string;
  @Input() extraCssClass!: string;
  @Input() labelPositionClass!: string;
  @Input() label!: string;
  @Input() validRequiredFlg!: boolean;
  @Input() value!: string;
  @Input() clearedAllFilters!: Subject<boolean>;
  @Input() validRequired!: Subject<boolean>;
  @Input() labelHasTransactionIcon!: boolean;

  @Output() eventSelectedItems = new EventEmitter<any[]>();
  @Output() eventInvalidRequired = new EventEmitter<boolean>();
  public checkIfOthersAreSelected!: boolean;
  itemSelectedString = '';

  @ViewChildren('itemsCheckboxes') private itemsCheckboxes!: ElementRef<HTMLInputElement>[];
  public isCheckBoxChecked: any;
  public isAllSelected = true;


  constructor(private _eref: ElementRef) { }

  ngOnInit() {
    this.options.sort((a, b) => {
      const optA = a.label;
      const optB = b.label;
      return (optA < optB) ? -1 : (optA > optB) ? 1 : 0;
    });
    this.clearedAllFilters.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value) {
        this.resetSelects();
        this.showMultiselectItems = false;
      }
    });
    if (this.validRequired) {
      this.validRequired.pipe(takeUntil(this.destroy$)).subscribe( value => {
        if (value) {
          if (this.itemsSelectedNumber === undefined || this.itemsSelectedNumber === 0) {
            this.eventInvalidRequired.emit(true);
            this.errorMsg = McGod.t('cc.common.this-field-is-required');
          } else {
            this.errorMsg = '';
            this.eventInvalidRequired.emit(false);
          }
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.optionVll) {
      this.optionVll.items = changes['optionVll'].currentValue.items;
      this.options = this.optionVll.items.map(item => {
        return {
          value: item.value,
          label: item.label,
          checked: false
        };
      });
      this.options.unshift({
        value: '',
        label: McGod.t('cc.my-factoring.all'),
        checked: false,
      });
    }
  }

  onClick() {
    // @ts-ignore
    if (!this._eref.nativeElement.contains(event.target)) {
      this.showMultiselectItems = false;
    }
  }

  toggleDropdown() {
    this.showMultiselectItems = !this.showMultiselectItems;
  }

  getSelectedItemsTooltip() {
      if (this.itemsSelectedLabels.length === this.options.length) {
        this.itemsSelectedLabels.shift();
        return this.itemsSelectedLabels.join(', ');
      } else {
        return this.itemsSelectedLabels.join(', ');
      }
  }

  resetSelects() {
    this.itemsSelected = [];
    this.itemsSelectedNumber = 0;
    this.options = this.options.map(item => {
      item.checked = false;
      return item;
    });
    this.eventSelectedItems.emit(this.itemsSelected);
  }


  checkSelected(vllItem: {value: string, label: string, checked: boolean}) {
    const filtered = this.options.filter(item => item.checked === true);
    this.options = this.options.map(item => {

      if (filtered.length === this.options.length) {
        if (vllItem.value === '')  {
          item.checked = false;
        } else {
          if (vllItem.value === item.value) {
            item.checked = !item.checked;
          }
          if (item.value === '') {
            item.checked = false;
          }

        }
      } else {
        if (vllItem.value === '') {
          item.checked = true;
        }

        if (vllItem.value === item.value && vllItem.value !== '') {
          item.checked = !item.checked;
        }
      }

      return item;
    });
    this.itemsSelected = this.options.filter(item => item.checked === true).map(item => item.value);
    this.itemsSelectedLabels = this.options.filter(item => item.checked === true).map(item => item.label);
    if (this.options.length === this.itemsSelected.length) {
      this.itemsSelectedNumber = this.itemsSelected.length - 1;
    } else {
      this.itemsSelectedNumber = this.itemsSelected.length;
    }
    this.eventSelectedItems.emit(this.itemsSelected);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onKeypress() {
    return false;
  }

  getAllItemsTooltip() {
    const allOptionLabels = this.options.map(opt => opt.label);
    allOptionLabels.shift();
    return allOptionLabels.join(', ');
  }

  geTransactionIconPath(label: string): string {
    if (label !== '-- all') {
      return `assets/mc-components-assets/${label.toLowerCase().split(' ').join('-')}.svg`;
    }
    return '';
  }
}
