import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  EbSepaFileTransaction,
  McBoolean,
  McDateUtils,
  McGod,
  McForm,
  EbFactoring,
  McValueLabelList, EbFactoringService, BaseDirective, CsrConsumer,
  PmTransaction,
  EbSepaTransactionService
} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-transaction-add-manually-part',
  templateUrl: './app-shared-transaction-add-manually-part.component.html',
})
export class AppSharedTransactionAddManuallyPartComponent extends BaseDirective implements OnInit {

  mcForm = new McForm();
  ebTransaction = new EbSepaFileTransaction();
  saveButtonDisabledMcb = new McBoolean();
  today = McDateUtils.getNowTimestamp();
  @Input() csrConsumer!: CsrConsumer;
  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  mcGod = McGod.getInstance();
  ebFactoring = new EbFactoring();
  minDate!: number;
  maxDate!: number;
  collectTransactionVll = new McValueLabelList();
  currency!: string;
  ebSepaTransactionService = new EbSepaTransactionService();
  pmTransaction = new PmTransaction();

  constructor(private factoringService: EbFactoringService) {
    super();
  }

  ngOnInit() {
    this.ebFactoring.loadActiveForChildEntity(McGod.getLoggedEntityIdFromToken(), () => {
      this.minDate = McDateUtils.addOrSubtractDateToNumber(this.today, 'subtract', this.ebFactoring.monthsAllowedPastCount, 'months');
      this.maxDate = McDateUtils.addOrSubtractDateToNumber(this.today, 'add', this.ebFactoring.monthsAllowedFutureCount, 'months');
    });
    this.collectTransactionVll.add(PmTransaction.COLLECTION_TYPE_CD_DIRECT_DEBIT, 'Collect');
    this.collectTransactionVll.add(PmTransaction.COLLECTION_TYPE_CD_DO_NOT_COLLECT, 'Send to reminder only');
    this.collectTransactionVll.add(PmTransaction.COLLECTION_TYPE_CD_DRAFT, 'Draft');

    this.currency = this.factoringService.activeChildFactoring.mcCurrency.code;
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actSave() {

    if (this.mcForm.isValid()) {
      this.pmTransaction.amount = this.mcForm.getValue('ctrlTransactionAmount')
      this.pmTransaction.dueDate = this.mcForm.getValue('ctrlPaymentDueDate');
      this.pmTransaction.description = this.mcForm.getValue('ctrlDescription');
      this.pmTransaction.idCsrConsumer = this.csrConsumer.id
      this.pmTransaction.idMcEntity = McGod.getLoggedEntityIdFromToken();
      this.pmTransaction.collectionTypeCd = this.mcForm.getValue('ctrlCollectTransaction');


      this.ebSepaTransactionService.createManualTransaction(this.pmTransaction)
        .subscribe(res => {
        this.eventSaved.emit();
      });
    }
  }

}
