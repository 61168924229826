import {McProcessFlowLevelFilterGENERATED} from '../_generated/mc-process-flow-level-filter-generated.model';

export class McProcessFlowLevelFilter extends McProcessFlowLevelFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessFlowLevelFilter();
  }

  // ---------------------------------------------------------------------
}
