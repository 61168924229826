/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McProcessRun} from '../models/mc-process-run.model';
import {McProcessRunService} from '../services/mc-process-run.service';
import {IMcProcessRun} from '../_generated/mc-process-run.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McProcessRunGENERATED extends BaseObject {


    public static readonly STATUS_CD_NEW: string = 'NEW';
    public static readonly STATUS_CD_RUN_STARTING: string = 'RUN_STARTING';
    public static readonly STATUS_CD_LOADING: string = 'LOADING';
    public static readonly STATUS_CD_RUNNING: string = 'RUNNING';
    public static readonly STATUS_CD_READY: string = 'READY';
    public static readonly STATUS_CD_CLOSED: string = 'CLOSED';
    public static readonly STATUS_CD_ERROR: string = 'ERROR';
  public static readonly STATUS_CD_LIST__ALL = [
    McProcessRunGENERATED.STATUS_CD_NEW,
    McProcessRunGENERATED.STATUS_CD_RUN_STARTING,
    McProcessRunGENERATED.STATUS_CD_LOADING,
    McProcessRunGENERATED.STATUS_CD_RUNNING,
    McProcessRunGENERATED.STATUS_CD_READY,
    McProcessRunGENERATED.STATUS_CD_CLOSED,
    McProcessRunGENERATED.STATUS_CD_ERROR
];

    public static readonly TYPE_CD_AUTOMATIC: string = 'AUTOMATIC';
    public static readonly TYPE_CD_MANUAL: string = 'MANUAL';
  public static readonly TYPE_CD_LIST__ALL = [
    McProcessRunGENERATED.TYPE_CD_AUTOMATIC,
    McProcessRunGENERATED.TYPE_CD_MANUAL
];

  public apiService: McProcessRunService;
  public _rawJson: IMcProcessRun;
    id: number = 0;
  buildItemsFlg: boolean;
  builtDatetime: number;
  cleanedDatetime: number;
  deliverItemsFlg: boolean;
  idMcProcess: number;
  lastErrorMsg = '';
  loadItemsFlg: boolean;
  loadedDatetime: number;
  runDatetime: number;
  statusCd: string;
  sysCreatedDatetime: number;
  sysUpdatedDatetime: number;
  typeCd: string;
  builtItemsCount: number;  deliveredItemsCount: number;  errorItemsCount: number;  newItemsCount: number;  readyItemsCount: number;  skippedItemsCount: number;

  public properties: string[] = ['id', 'buildItemsFlg', 'builtDatetime', 'cleanedDatetime', 'deliverItemsFlg', 'idMcProcess', 'lastErrorMsg', 'loadItemsFlg', 'loadedDatetime', 'runDatetime', 'statusCd', 'sysCreatedDatetime', 'sysUpdatedDatetime', 'typeCd', 'builtItemsCount', 'deliveredItemsCount', 'errorItemsCount', 'newItemsCount', 'readyItemsCount', 'skippedItemsCount'];
  public propertiesRegular: string[] = ['id', 'buildItemsFlg', 'builtDatetime', 'cleanedDatetime', 'deliverItemsFlg', 'idMcProcess', 'lastErrorMsg', 'loadItemsFlg', 'loadedDatetime', 'runDatetime', 'statusCd', 'sysCreatedDatetime', 'sysUpdatedDatetime', 'typeCd', 'builtItemsCount', 'deliveredItemsCount', 'errorItemsCount', 'newItemsCount', 'readyItemsCount', 'skippedItemsCount'];
  public propertiesSpecial: string[] = [];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessRunGENERATED.STATUS_CD_NEW, McGod.t('New'));
    list.add(McProcessRunGENERATED.STATUS_CD_RUN_STARTING, McGod.t('Running - Starting'));
    list.add(McProcessRunGENERATED.STATUS_CD_LOADING, McGod.t('Loading Items'));
    list.add(McProcessRunGENERATED.STATUS_CD_RUNNING, McGod.t('Running'));
    list.add(McProcessRunGENERATED.STATUS_CD_READY, McGod.t('Ready'));
    list.add(McProcessRunGENERATED.STATUS_CD_CLOSED, McGod.t('Closed'));
    list.add(McProcessRunGENERATED.STATUS_CD_ERROR, McGod.t('Error'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McProcessRunGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessRunGENERATED.TYPE_CD_AUTOMATIC, McGod.t('Automatic'));
    list.add(McProcessRunGENERATED.TYPE_CD_MANUAL, McGod.t('Manual'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = McProcessRunGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McProcessRun {
    console.error('MTCN-ERROR: Not Implemented: McProcessRun::createNewInstance(). Add this method to final class and return new McProcessRun();');
    throw new Error('Cannot McProcessRun::createNewInstance(). Add this method to final class and return new McProcessRun();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcProcessRun): McProcessRun {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessRunService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McProcessRun AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getBuiltDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.builtDatetime);
    }

    public getBuiltDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.builtDatetime);
    }

    public getCleanedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.cleanedDatetime);
    }

    public getCleanedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.cleanedDatetime);
    }

    public getLoadedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.loadedDatetime);
    }

    public getLoadedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.loadedDatetime);
    }

    public getRunDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.runDatetime);
    }

    public getRunDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.runDatetime);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return McProcessRunGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdNEW(): boolean {
    const result = (this.statusCd === McProcessRunGENERATED.STATUS_CD_NEW);

    return result;
  }

 public  isStatusCdRUN_STARTING(): boolean {
    const result = (this.statusCd === McProcessRunGENERATED.STATUS_CD_RUN_STARTING);

    return result;
  }

 public  isStatusCdLOADING(): boolean {
    const result = (this.statusCd === McProcessRunGENERATED.STATUS_CD_LOADING);

    return result;
  }

 public  isStatusCdRUNNING(): boolean {
    const result = (this.statusCd === McProcessRunGENERATED.STATUS_CD_RUNNING);

    return result;
  }

 public  isStatusCdREADY(): boolean {
    const result = (this.statusCd === McProcessRunGENERATED.STATUS_CD_READY);

    return result;
  }

 public  isStatusCdCLOSED(): boolean {
    const result = (this.statusCd === McProcessRunGENERATED.STATUS_CD_CLOSED);

    return result;
  }

 public  isStatusCdERROR(): boolean {
    const result = (this.statusCd === McProcessRunGENERATED.STATUS_CD_ERROR);

    return result;
  }


  public getTypeCdLabel(): string {
    return McProcessRunGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdAUTOMATIC(): boolean {
    const result = (this.typeCd === McProcessRunGENERATED.TYPE_CD_AUTOMATIC);

    return result;
  }

 public  isTypeCdMANUAL(): boolean {
    const result = (this.typeCd === McProcessRunGENERATED.TYPE_CD_MANUAL);

    return result;
  }


  
  // ---------------------------------------------------------------------
  public startRun( mcProcessRunId: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.startRun(mcProcessRunId)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

  // ---------------------------------------------------------------------
  public closeRun( mcProcessRunId: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.closeRun(mcProcessRunId)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

}
