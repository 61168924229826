<ng-container>
  <div class="content-wrapper">
    <div class="content-header">
     {{'cc.out-factoring.enable-disable-factoring-for-this-entity' | translate}}
    </div>
    <div class="content-body">
      <mcc-fi-radio-buttons [name]="'ctrlEnableDisableFactoring'"
                            extraCssClasses="mc-float"
                            [validRequiredFlg]="true"
                            [directionHorizontal]="false"
                            [value]="mcEntity2.factoringEnableFlg ? 'yes' : 'no'"
                            [mcForm]="mcForm"
                            [labelOnTop]="false"
                            [optionsVlm]="enableDisableVlm"></mcc-fi-radio-buttons>
      </div>
    <div class="content-footer">
      <button class="btn btn-secondary move-right" (click)="actCancel()">{{'cc.common.edit.cancel' | translate}}</button>
      <button class="btn btn-primary link" (click)="actSave()">{{'cc.common.view.save' | translate}}</button>
    </div>
  </div>
</ng-container>
