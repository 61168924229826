
import {Table3Config} from '../extra/table-3-config';

// import {ImDataExportGENERATED} from '../_generated/im-data-export-generated.model';

export class ImDataExportTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDateFrom', 'Date from', 'dateFrom', 'dateFrom');
    this.addColumn('colDateTo', 'Date to', 'dateTo', 'dateTo');
    this.addColumn('colFileType', 'File type', 'fileType', 'fileType');
    this.addColumn('colIdDataExportTemplate', 'Data export template ID', 'idDataExportTemplate', 'idDataExportTemplate');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colStatusCd', 'Status', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colTitle', 'Data export title', 'title', 'title');

*/
  }
}
