import {McActionPdfTemplateFilterGENERATED} from '../_generated/mc-action-pdf-template-filter-generated.model';

export class McActionPdfTemplateFilter extends McActionPdfTemplateFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McActionPdfTemplateFilter();
  }

  // ---------------------------------------------------------------------
}
