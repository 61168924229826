
import {Table3Config} from '../extra/table-3-config';

// import {EbSepaExportGENERATED} from '../_generated/eb-sepa-export-generated.model';

export class EbSepaExportTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAllDebtors', 'All Debtors', 'allDebtors', 'allDebtors');
    this.addColumn('colCreationTypeCd', 'creation type cd', 'creationTypeCd', 'creationTypeCd');
    this.addColumn('colIdMcBankAccount', 'Bank account ID', 'idMcBankAccount', 'idMcBankAccount');
    this.addColumn('colIdMcCurrency', 'Currency', 'idMcCurrency', 'idMcCurrency');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdSystemUser', 'System user ID', 'idSystemUser', 'idSystemUser');
    this.addColumn('colIdWebfile', 'Webfile ID', 'idWebfile', 'idWebfile');
    this.addColumn('colMcCurrency', 'McCurrency', 'mcCurrency', 'mcCurrency');
    this.addColumn('colRequestedCollectionDate', 'Requested collection date', 'requestedCollectionDate', 'requestedCollectionDate');
    this.addColumn('colSepaExportFilename', 'Sepa Export Filename', 'sepaExportFilename', 'sepaExportFilename');
    this.addColumn('colServicePeriod', 'Service period', 'servicePeriod', 'servicePeriod');
    this.addColumn('colStatusCd', 'Status cd', 'statusCd', 'statusCd');
    this.addColumn('colStatusDetails', 'Status Details', 'statusDetails', 'statusDetails');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colTotalAmountDue', 'Total Amount Due', 'totalAmountDue', 'totalAmountDue');
    this.addColumn('colTypeCd', 'type cd', 'typeCd', 'typeCd');

*/
  }
}
