import {Inject, Injectable} from '@angular/core';
import {McBillingPayoutFile2StatementServiceGENERATED} from '../_generated/mc-billing-payout-file-2-statement-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McBillingPayoutFile2StatementService extends McBillingPayoutFile2StatementServiceGENERATED {

}
