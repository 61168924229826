/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McConsumerHistoryTable3ConfigGENERATED} from '../_generated/mc-consumer-history-table-3-config-generated.model';

// import {McConsumerHistoryGENERATED} from '../_generated/mc-consumer-history-generated.model';

export class McConsumerHistoryTable3Config extends McConsumerHistoryTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colFieldName', this.t('cc.common.view.field'), 'fldFieldLabel()', 'fieldName');
    this.addColumn('colOldValue', this.t('cc.consumers.view.old-value'), 'fldOldValue()', 'oldVarcharValue');
    this.addColumn('colNewVarcharValue', this.t('cc.consumers.view.new-value'), 'fldNewValue()', 'newVarcharValue');

    this.addColumn('colCreatedByUser', this.t('cc.consumers.view.changed-by'), 'createdByUser', 'ue.firstname');
    this.addColumn('colAssetCreationDatetime', this.t('cc.my-factoring.date-&-time'), 'fldDateTime()', 'assetCreationDatetime');


  }
}
