/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EbPaymentReportFilter} from '../models/eb-payment-report-filter.model';

export class EbPaymentReportFilterGENERATED extends BaseObjectFilter{    public statusCds: string[] = [];
    public searchTerm: string | undefined;
    public transactionDatetimeFrom: any | undefined;
    public transactionDatetimeTo: any | undefined;
    public types: string[] = [];
    public matchingTypes: string[] = [];

  public properties: string[] = ['statusCds', 'searchTerm', 'transactionDatetimeFrom', 'transactionDatetimeTo', 'types', 'matchingTypes', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbPaymentReportFilter {
    console.error('MTCN-ERROR: Not Implemented: EbPaymentReportFilter::createNewInstance(). Add this method to final class and return new EbPaymentReportFilter();');
      throw new Error('Cannot EbPaymentReportFilter::createNewInstance(). Add this method to final class and return new EbPaymentReportFilter();');
  }

}
