import {Component, OnInit, OnChanges, Input, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {EbConsumerBlacklist, McForm, McGod} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-blacklist-remove-part',
  templateUrl: './app-shared-blacklist-remove-part.component.html',
})
export class AppSharedBlacklistRemovePartComponent implements OnInit, OnChanges {

  // -- Inputs --
  @Input() ebConsumerBlacklistId!: number;
  @Input() ebConsumerBlacklist = new EbConsumerBlacklist();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventRemoved = new EventEmitter();
  public mcForm = new McForm();

  // ---------------------------------------------------------------------
  constructor() {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {

  }

  // ---------------------------------------------------------------------
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ebConsumerBlacklistId']) {
      if ((changes['ebConsumerBlacklistId'].previousValue !== changes['ebConsumerBlacklistId'].currentValue) && (changes['ebConsumerBlacklistId'].currentValue > 0)) {
       this.actLoad();
      }
    }
  }
  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.ebConsumerBlacklist = new  EbConsumerBlacklist();
    this.ebConsumerBlacklist.loadById(this.ebConsumerBlacklistId);
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actRemoveFromBlacklist() {
    if (this.mcForm.isValid()) {
      this.ebConsumerBlacklist.removedReason = this.mcForm.getValue('txbBlacklistReason');
      this.ebConsumerBlacklist.removedUserId = McGod.getLoggedUserIdFromToken();
      this.ebConsumerBlacklist.save(() => {
        this.eventRemoved.emit();
      });
    }
  }
}


