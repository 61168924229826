export enum userEnums {
  successMsg = "success",
  errorMsg = "error",
  mandatoryFields = "Please fill mandatory fields",
  enterEmail = "Please enter email",
  numberAndCharNotAllowed = "Numbers and special characters are not allowed!",
  firstNameMin2Char = "First name must have at least 2 characters",
  lastNameMin2Char = "Last name must have at least 2 characters!",
  nickNameMin2Char = "Nickname must have at least 2 characters",
  selectGender = "Please select gender!",
  userDoesntExist = "User doesn't exist",
  userAlreadyExist = "User with this e-mail already exists on another entity",
  enterValidEmail = "Please enter valid email format",
  successEdited = "User profile successfully edited!",
  userNoSelectedRoles = "There is no selected role",
  userRolesEdited = "Roles successfully edited!",
  userServerError = "Server error",
  userRequestError = "Invalid request",
  numberNotAllowed = "Numbers are not allowed"
}
