import {McEbics} from './mc-ebics.model';
import {McEbicsListGENERATED} from '../_generated/mc-ebics-list-generated.model';

export class McEbicsList extends McEbicsListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McEbicsList();
  }

  // ---------------------------------------------------------------------
}
