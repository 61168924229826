/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McMappingItem} from '../models/mc-mapping-item.model';
import {McMappingItemService} from '../services/mc-mapping-item.service';
import {IMcMappingItem} from '../_generated/mc-mapping-item.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McMappingItemGENERATED extends BaseObject {


  public apiService: McMappingItemService;
  public _rawJson: IMcMappingItem;
    id: number = 0;
  attributeName = '';
  columnName = '';
  idMcMapping: number;
  requiredFlg: boolean;
  sampleData = '';


  public properties: string[] = ['id', 'attributeName', 'columnName', 'idMcMapping', 'requiredFlg', 'sampleData'];
  public propertiesRegular: string[] = ['id', 'attributeName', 'columnName', 'idMcMapping', 'requiredFlg', 'sampleData'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): McMappingItem {
    console.error('MTCN-ERROR: Not Implemented: McMappingItem::createNewInstance(). Add this method to final class and return new McMappingItem();');
    throw new Error('Cannot McMappingItem::createNewInstance(). Add this method to final class and return new McMappingItem();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcMappingItem): McMappingItem {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McMappingItemService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McMappingItem AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
