
import {Table3Config} from '../extra/table-3-config';

// import {McBillingPayoutFileItemFeCtdGENERATED} from '../_generated/mc-billing-payout-file-item-fe-ctd-generated.model';

export class McBillingPayoutFileItemFeCtdTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAmount', 'Amount', 'amount', 'amount');
    this.addColumn('colBic', 'BIC', 'bic', 'bic');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colEntityName', 'Entity Name', 'entityName', 'entityName');
    this.addColumn('colIban', 'IBAN', 'iban', 'iban');
    this.addColumn('colIdMcBillingPayoutFile', 'Mc Billing Payout File Id', 'idMcBillingPayoutFile', 'idMcBillingPayoutFile');
    this.addColumn('colIdMcBillingStatement', 'Statement ID', 'idMcBillingStatement', 'idMcBillingStatement');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colStudioOwner', 'Studio Owner', 'studioOwner', 'studioOwner');

*/
  }
}
