import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {McConsumersService, McValidation, userEnums, McRegex} from "@miticon-ui/mc-core";
import {ActivatedRoute} from "@angular/router";
import {NgbModal, NgbDateAdapter, NgbDateNativeAdapter} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: "lib-step1",
  templateUrl: "./step1.component.html",
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class Step1Component implements OnInit {
  userId!: number;
  showLoader!: boolean;
  userDataFormValid!: boolean;
  message!: string;
  type!: string;
  languages: any;
  countries: any;
  responseMessage!: boolean;
  firstnameNotValid!: boolean;
  lastnameNotValid!: boolean;
  companyNameNotValid!: boolean;
  phoneNumberInvalid!: boolean;
  cellNumberInvalid!: boolean;
  faxNumberInvalid!: boolean;
  emailInvalid!: boolean;
  ibanNumberInvalid!: boolean;
  postalCodeInvalid!: boolean;
  externalIdInvalid!: boolean;
  isFirstNameRequired!: boolean;
  isLastNameRequired!: boolean;
  isCompanyNameRequired!: boolean;
  minDate = {year: 1900, month: 1, day: 1};
  maxDate = {year: 2100, month: 12, day: 31};

  // Inputs
  @Input() consumerData!: any;
  @Input() modalArea!: string;
  @ViewChild("bDayDateField") bDayDateField!: any;

  // Outputs
  @Output() isDataFormInvalid = new EventEmitter<boolean>();
  @Output() refreshUserDataEvent = new EventEmitter<boolean>();

  // Forms
  @Input() parentForm!: FormGroup;
  stepForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private consumersService: McConsumersService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.setSelecteFields();
    if (this.modalArea === "addConsumers") {
      this.parentForm.setControl("step1", this.stepForm);
      this.responseMessage = true;
      this.setNotificationMessage(userEnums.mandatoryFields, "success");
    } else {
      this.responseMessage = false;

      this.stepForm.patchValue({
        companyName: this.consumerData.companyName,
        consumerFirstName: this.consumerData.firstName,
        consumerLastName: this.consumerData.lastName,
        consumerTitle: this.consumerData.title,
        consumerBDay: this.consumerData.birthday ? new Date(this.consumerData.birthday) : null,
        consumerTelephone: this.consumerData.telephone,
        consumerCellphone: this.consumerData.cellphone,
        consumerFax: this.consumerData.fax,
        consumerEmail: this.consumerData.email,
        consumerStreet: this.consumerData.street,
        consumerAddressSupplement: this.consumerData.addressSupplement,
        consumerPostalCode: this.consumerData.postCode,
        consumerCity: this.consumerData.city,
        consumerISOCountry: this.consumerData.isoCountry,
        consumerISOLanguage: this.consumerData.isoLanguage,
        consumerAccountOwner: this.consumerData.accountOwner,
        consumerExternalId: this.consumerData.externalId,
        consumerIBAN: this.consumerData.iban,
        consumerBIC: this.consumerData.bic,
        idVat: this.consumerData.vatNumber
      });
      this.stepForm.get("gender")?.setValue(this.consumerData.gender);
      this.stepForm.get("active")?.setValue(this.consumerData.active ? "active" : "inactive");
      this.stepForm.get("individual")?.setValue(this.consumerData.individual === "Person" ? "person" : "company");
      this.userId = this.activatedRoute.snapshot.params["id"] - 0;

      this.refreshUserDataEvent.emit(true);
    }
    this.responseMessage = true;
    this.setNotificationMessage('Please fill mandatory fields', 'success');

    this.stepForm.valueChanges.subscribe( () => {
      this.shareUnlockProperty(this.stepForm.invalid);
    });
  }

  // Prefill selected fields (language, country)
  private setSelecteFields() {
    this.languages = [
      {name: "English", value: "EN"},
      {name: "German", value: "DE"},
      {name: "Italy", value: "IT"}
    ];
    this.countries = [
      {name: "USA", value: "US"},
      {name: "German", value: "DE"},
      {name: "Italy", value: "IT"}
    ];
  }

  // Init Form
  createForm() {
    this.stepForm = this.fb.group({
      active: ["active"],
      companyName: [""],
      consumerFirstName: ["", [Validators.required, Validators.pattern("^[A-ZÜÄÖa-züäöß\\-' ]*$"), Validators.minLength(2)]],
      consumerLastName: ["", [Validators.required, Validators.pattern("^[A-ZÜÄÖa-züäöß\\-' ]*$"), Validators.minLength(2)]],
      consumerTitle: [""],
      consumerBDay: [null],
      gender: [""],
      consumerTelephone: ["", [Validators.pattern(McRegex.mcPhoneNumberRegex)]],
      consumerCellphone: ["", [Validators.pattern(McRegex.mcPhoneNumberRegex)]],
      consumerFax: ["", [Validators.pattern(McRegex.mcPhoneNumberRegex)]],
      consumerEmail: ["", [ Validators.email]],
      consumerStreet: [""],
      consumerAddressSupplement: [""],
      consumerPostalCode: ["", [ Validators.pattern(McRegex.mcPostalCodeRegex)]],
      consumerCity: [""],
      consumerISOCountry: [""],
      consumerISOLanguage: [""],
      consumerAccountOwner: [""],
      individual: ["person"],
      consumerExternalId: ["", [ Validators.maxLength(30)]],
      consumerIBAN: ["", [ Validators.minLength(22)]],
      consumerBIC: [""],
      idVat: [""]
    });
  }

  // Check if is email valid
  private checkValidEmail(email: any) {
    const re = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,6})$/;
    return re.test(String(email).toLowerCase());
  }

  // Regex for text (letters) only
  private checkForLettersOnly(name: string) {
    const re = /[^A-Za-z]+/g;
    return re.test(String(name));
  }

  // Regex for number only
  private checkForNumber(name: any) {
    const re = /^[0-9]{0,9}(\.[0-9]{1,2})?$/;
    return re.test(String(name));
  }

  // Phone regex
  private checkPhoneFormat(phoneNo: any) {
    const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9()]*$/g;
    return re.test(String(phoneNo));
  }

  // Check phone format
  isPhoneNumberFormat(e: any) {
    const fax = this.stepForm.get("consumerFax")?.value.split("");
    const telephone = this.stepForm.get("consumerTelephone")?.value.split("");
    const cellphone = this.stepForm.get("consumerCellphone")?.value.split("");
    const value = (e.target as HTMLInputElement).value;
    const element = (e.target as HTMLInputElement).id;
    const phoneFormat = this.checkPhoneFormat(value);

    if (!phoneFormat && element === "consumerTelephone" && value.length > 0) {
      this.phoneNumberInvalid = true;
      this.setNotificationMessage("A valid Telephone number is required", "error");
      this.responseMessage = true;
      return;
    } else if (element === "consumerTelephone") {
      this.phoneNumberInvalid = false;
      this.responseMessage = false;
    }
    if (!phoneFormat && element === "consumerCellphone" && cellphone.length > 0) {
      this.cellNumberInvalid = true;
      this.setNotificationMessage("A valid Cellphone number is required", "error");
      this.responseMessage = true;
      return;
    } else if (element === "consumerCellphone") {
      this.cellNumberInvalid = false;
      this.responseMessage = false;
    }
    if (!phoneFormat && element === "consumerFax" && fax.length > 0) {
      this.faxNumberInvalid = true;
      this.setNotificationMessage("A valid fax number is required", "error");
      this.responseMessage = true;
      return;
    } else if (element === "consumerFax") {
      this.faxNumberInvalid = false;
      this.responseMessage = false;
    }
  }

  // Check email
  checkEmailFormat(e: any) {
    const emailArray = this.stepForm.get("consumerEmail")?.value.split("");
    const value = (e.target as HTMLInputElement).value;
    const phoneFormat = this.checkValidEmail(value);

    if (!phoneFormat && emailArray.length > 0) {
      this.emailInvalid = true;
      this.setNotificationMessage("A valid email format is required", "error");
      this.responseMessage = true;
      // this.shareUnlockProperty(true);
    } else {
      this.responseMessage = false;
      this.emailInvalid = false;
      // this.shareUnlockProperty(false);
    }

  }

  // IBAN check
  isIBANValid() {
    const iban = this.stepForm.getRawValue().consumerIBAN;
    if (iban.replace(/\s/g, "").length !== 22 && iban.replace(/\s/g, "").length !== 0) {
      this.setNotificationMessage("The length of IBAN is must be 22 characters", "error");
      this.responseMessage = true;
      this.ibanNumberInvalid = true;
    } else {
      this.responseMessage = false;
      this.ibanNumberInvalid = false;
    }
  }

  // Number check
  isNumberFormat(e: any) {
    const externalId = this.stepForm.get("consumerExternalId")?.value.split("");
    const postalCode = this.stepForm.get("consumerPostalCode")?.value.split("");
    const elValue = (e.target as HTMLInputElement).value;
    const el = (e.target as HTMLInputElement).id;
    const numberFormat = this.checkForNumber(elValue);
    const postalFormat = McValidation.mcCheckPostalCode(elValue);

    if (!postalFormat && el === "consumerPostalCode" && postalCode.length > 0) {
      this.postalCodeInvalid = true;
      this.setNotificationMessage("A valid Postal code is required", "error");
      this.responseMessage = true;
      return;
    } else {
      this.postalCodeInvalid = false;
      this.responseMessage = false;
    }
    if (externalId.length > 30 && el === "consumerExternalId") {
      this.externalIdInvalid = true;
      this.setNotificationMessage("The length of External ID can't be greater than 30 ", "error");
      this.responseMessage = true;
      return;
    } else {
      this.externalIdInvalid = false;
      this.responseMessage = false;
    }
  }

  // Check mandatory fields
  checkMandatoryInputFields(field: string) {
    let msgText = "";
    let msgType = "";
    const formData = this.stepForm.getRawValue();
    const firstName = formData.consumerFirstName;
    const lastName = formData.consumerLastName;
    const companyName = formData.companyName;

    switch (field) {
      case "consumerFirstName":
        if (firstName.length < 2) {
          msgText = userEnums.firstNameMin2Char;
          msgType = "error";
          this.firstnameNotValid = true;
        } else {
          this.responseMessage = false;
          this.firstnameNotValid = false;
        }
        this.setNotificationMessage(msgText, msgType);
        this.responseMessage = true;
        break;
      case "consumerLastName":
        if (lastName.length < 2) {
          msgText = userEnums.lastNameMin2Char;
          msgType = "error";
          this.lastnameNotValid = true;
        } else {
          this.responseMessage = false;
          this.lastnameNotValid = false;
        }
        this.setNotificationMessage(msgText, msgType);
        this.responseMessage = true;
        break;
      case "companyName":
        if (this.stepForm.get("companyName")?.invalid) {
          this.setNotificationMessage("Company name must not be empty", "error");
          this.responseMessage = true;
          this.companyNameNotValid = true;
        } else {
          this.setNotificationMessage("", "");
          this.responseMessage = false;
          this.companyNameNotValid = false;
        }
        break;
    }

    const form = this.stepForm;
    if (this.stepForm.controls["individual"].value === "person") {
      form.get("companyName")?.disable();
      // form.get("companyName").setValue("");
      form.get("companyName")?.clearValidators();
      form.get("companyName")?.updateValueAndValidity();
      this.companyNameNotValid = false;
      this.isCompanyNameRequired = false;
      form.get("consumerFirstName")?.setValidators([Validators.required, Validators.pattern("^[A-ZÜÄÖa-züäöß\\-' ]*$"), Validators.minLength(2)]);
      form.get("consumerFirstName")?.updateValueAndValidity();
      form.get("consumerLastName")?.setValidators([Validators.required, Validators.pattern("^[A-ZÜÄÖa-züäöß\\-' ]*$"), Validators.minLength(2)]);
      form.get("consumerLastName")?.updateValueAndValidity();
      this.isFirstNameRequired = true;
      this.isLastNameRequired = true;
      if (this.stepForm.controls["consumerFirstName"].valid &&
        this.stepForm.controls["consumerLastName"].valid) {
        // this.shareUnlockProperty(false);
      } else {
        // this.shareUnlockProperty(true);
      }
    } else {
      form.get("companyName")?.enable();
      form.get("companyName")?.setValidators(Validators.required);
      form.get("companyName")?.updateValueAndValidity();
      this.firstnameNotValid = false;
      this.lastnameNotValid = false;
      form.get("consumerFirstName")?.clearValidators();
      form.get("consumerFirstName")?.updateValueAndValidity();
      form.get("consumerLastName")?.clearValidators();
      form.get("consumerLastName")?.updateValueAndValidity();
      this.isCompanyNameRequired = true;
      this.isFirstNameRequired = false;
      this.isLastNameRequired = false;
      if (this.stepForm.controls["companyName"].valid) {
        // this.shareUnlockProperty(false);
      } else {
        // this.shareUnlockProperty(true);
      }
    }
  }

  // Unlock Next btn
  private shareUnlockProperty(valid: boolean) {
    this.isDataFormInvalid.emit(
      this.userDataFormValid = valid
    );
  }

  // When type is changed
  onChangeType(event: any) {
    const form = this.stepForm;
    switch (event) {
      case "person":
        form.get("companyName")?.disable();
        this.checkMandatoryInputFields("consumerFirstName");
        // form.get("companyName").setValue("");
        form.get("companyName")?.clearValidators();
        form.get("companyName")?.updateValueAndValidity();
        this.companyNameNotValid = false;
        this.isCompanyNameRequired = false;
        form.get("consumerFirstName")?.setValidators([Validators.required, Validators.pattern("^[A-ZÜÄÖa-züäöß\\-' ]*$"), Validators.minLength(2)]);
        form.get("consumerFirstName")?.updateValueAndValidity();
        form.get("consumerLastName")?.setValidators([Validators.required, Validators.pattern("^[A-ZÜÄÖa-züäöß\\-' ]*$"), Validators.minLength(2)]);
        form.get("consumerLastName")?.updateValueAndValidity();
        this.isFirstNameRequired = true;
        this.isLastNameRequired = true;
        break;
      case "company":
        form.get("companyName")?.enable();
        form.get("companyName")?.setValidators(Validators.required);
        form.get("companyName")?.updateValueAndValidity();
        this.isCompanyNameRequired = true;
        this.firstnameNotValid = false;
        this.lastnameNotValid = false;
        form.get("consumerFirstName")?.clearValidators();
        form.get("consumerFirstName")?.updateValueAndValidity();
        form.get("consumerLastName")?.clearValidators();
        form.get("consumerLastName")?.updateValueAndValidity();
        this.isFirstNameRequired = false;
        this.isLastNameRequired = false;
        this.checkMandatoryInputFields("companyName");
        break;
      default:
    }
  }

  saveEditedConsumerData() {
    this.showLoader = true;
    const stepFormData = this.stepForm.getRawValue();
    const body: any = {
      id: this.userId,
      companyName: stepFormData.individual === "company" ? stepFormData.companyName : null,
      firstName: stepFormData.consumerFirstName || null,
      lastName: stepFormData.consumerLastName || null,
      title: stepFormData.consumerTitle || null,
      birthday: stepFormData.consumerBDay === null ? null : new Date(stepFormData.consumerBDay),
      gender: stepFormData.gender,
      telephone: stepFormData.consumerTelephone || null,
      cellphone: stepFormData.consumerCellphone || null,
      fax: stepFormData.consumerFax || null,
      email: stepFormData.consumerEmail || null,
      street: stepFormData.consumerStreet || null,
      addressSupplement: stepFormData.consumerAddressSupplement || null,
      postCode: stepFormData.consumerPostalCode || null,
      city: stepFormData.consumerCity || null,
      isoCountry: stepFormData.consumerISOCountry || null,
      isoLanguage: stepFormData.consumerISOLanguage || null,
      accountOwner: stepFormData.consumerAccountOwner || null,
      externalId: stepFormData.consumerExternalId - 0 || null,
      iban: stepFormData.consumerIBAN || null,
      bic: stepFormData.consumerBIC || null,
      vatNumber: stepFormData.vat || null,
      active: stepFormData.active === "active",
      individual: stepFormData.individual === "person",
    };
    this.consumersService.updateConsumerBasicData(body).subscribe(
      (response) => {
        const msgText = userEnums.successEdited;
        const msgType = "success";
        this.responseMessage = true;
        this.setNotificationMessage(msgText, msgType);
        // event for refresh personal data after successfully save
        this.refreshUserDataEvent.emit(true);

        this.modalService.dismissAll("Cross click");
        this.showLoader = false;
      },
      (error) => {
        this.apiErrorHandler(error);
      }
    );
  }

  // Api ErrorHandler
  private apiErrorHandler(error: any) {
    let message = "";
    let msgType = "";
    if (error.status >= 500) {
      message = "Server error";
      msgType = "error";
    } else if (error.error) {
      if (error.error.errors.length > 1) {
        message = error.error.errors.join();
        msgType = "error";
      } else {
        message = error.error.errors;
        msgType = "error";
      }
    } else {
      message = "Invalid request";
      msgType = "error";
    }
    this.responseMessage = true;
    this.setNotificationMessage(message, msgType);
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

}
