import {BaseObject, Table3ColumnConfig} from '@miticon-ui/mc-core';

export class MccFiTable3CellEditConfigData {
  public editComponentConfigObj: any;
  public dataItemObj: BaseObject;
  public columnConfigObj: Table3ColumnConfig;


  constructor(aEditComponentConfigObj: any, aDataItemObj: BaseObject, aColumnConfigObj: Table3ColumnConfig) {
    this.editComponentConfigObj = aEditComponentConfigObj;
    this.dataItemObj = aDataItemObj;
    this.columnConfigObj = aColumnConfigObj;
  }
}
