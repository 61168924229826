/*BbmTestObj*/
import {EbConsumerBlacklistGENERATED} from '../_generated/eb-consumer-blacklist-generated.model';
import {McHtml} from '../_core/mc-html';
import {McDateUtils} from '../common/mc-date-utils';
import {McGod} from '../extra/mc-god.service';
import {ActionOptions} from '../_core/mc-html-action-options';

export class EbConsumerBlacklist extends EbConsumerBlacklistGENERATED {

  // ---------------------------------------------------------------------
  public static ACTION_SHOW_EDIT = 'EDIT';

  private _fldActions: McHtml | null = null;

  public static override createNewInstance() {
    return new EbConsumerBlacklist();
  }

  fldName() {
    return `${this.firstName} ${this.lastName}`;
  }

  fldAddedDate() {
      return `${McDateUtils.newFormatDateTimeString(this.addedDate)}`;
  }
  fldRemovedDate(): string {
    if (this.removedDate) {
      return `${McDateUtils.newFormatDateTimeString(this.removedDate)}`;
    }
    return '';
  }
  fldBirthday() {
    return `${McDateUtils.newFormatDateString(this.birthday)}`;
  }

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      if (!this.removedDate) {
        actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t('cc.out-factoring.remove-from-bl')}`, EbConsumerBlacklist.ACTION_SHOW_EDIT, this, [McGod.PERM_EB_FACTORING_OUT_BLACKLIST_VIEW], `${this.isRemovedFromBlacklist ? 'mc-disable-button' : ''}`, ActionOptions.OPTION_DELETE);
      }
      this._fldActions = html;
    }
    return this._fldActions.items;
  }






  // ---------------------------------------------------------------------
}
