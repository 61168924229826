/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McProcessFlowLevel} from '../models/mc-process-flow-level.model';
import {McProcessFlowLevelList} from '../models/mc-process-flow-level-list.model';
import {McProcessFlowLevelFilter} from '../models/mc-process-flow-level-filter.model';
import {McProcessFlowLevelService} from '../services/mc-process-flow-level.service';
import {IMcProcessFlowLevel} from '../_generated/mc-process-flow-level.interface';
import {McGod} from "../extra/mc-god.service";

export class McProcessFlowLevelListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'description', 'emailActionFlg', 'emailBodyTokened', 'emailPaymentQrCodeFlg', 'emailSubjectTokened', 'idEmailPdfTemplate', 'idMcProcessFlow', 'idPdfTemplate', 'levelFeeAmount', 'noDaysPaymentDeadlineAfterReminderIssue', 'noDaysWaitingPeriodAfterPaymentDeadline', 'postActionFlg', 'postPaymentQrCodeFlg', 'smsActionFlg', 'smsBodyTokened', 'title', 'mcPdfTemplateTitle', 'processActiveFlg'];
  public items: McProcessFlowLevel[] = [];
  public apiService: McProcessFlowLevelService;
 // protected tempObjList: McProcessFlowLevelList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProcessFlowLevelList {
    console.error('MTCN-ERROR: Not Implemented: McProcessFlowLevelList::createNewInstance(). Add this method to final class and return new McProcessFlowLevelList();');
    throw new Error('Cannot McProcessFlowLevelList::createNewInstance(). Add this method to final class and return new McProcessFlowLevelList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcProcessFlowLevel[]): McProcessFlowLevelList {
    const  listObj: McProcessFlowLevelList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McProcessFlowLevel = McProcessFlowLevel.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McProcessFlowLevelList {
    const  listObj: McProcessFlowLevelList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessFlowLevelService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcProcessFlowLevel[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McProcessFlowLevel = McProcessFlowLevel.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcProcessFlowLevelFilter: McProcessFlowLevelFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcProcessFlowLevelFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McProcessFlowLevel {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McProcessFlowLevel();
    }
  }

  public replaceItem(newItem: McProcessFlowLevel) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadByIdMcProcessFlow( idMcProcessFlow: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByIdMcProcessFlow(idMcProcessFlow, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

