
import {Table3Config} from '../extra/table-3-config';

// import {EbSepaTransactionGENERATED} from '../_generated/eb-sepa-transaction-generated.model';

export class EbSepaTransactionTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*

*/
  }
}
