/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McEbics} from '../models/mc-ebics.model';
import {McEbicsService} from '../services/mc-ebics.service';
import {IMcEbics} from '../_generated/mc-ebics.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McEbicsGENERATED extends BaseObject {


    public static readonly EBICS_STATUS_NONE: string = 'NONE';
    public static readonly EBICS_STATUS_NEW: string = 'NEW';
    public static readonly EBICS_STATUS_PENDING: string = 'PENDING';
    public static readonly EBICS_STATUS_ACCEPTED: string = 'ACCEPTED';
  public static readonly EBICS_STATUS_LIST__ALL = [
    McEbicsGENERATED.EBICS_STATUS_NONE,
    McEbicsGENERATED.EBICS_STATUS_NEW,
    McEbicsGENERATED.EBICS_STATUS_PENDING,
    McEbicsGENERATED.EBICS_STATUS_ACCEPTED
];

  public apiService: McEbicsService;
  public _rawJson: IMcEbics;
    id: number = 0;
  accountNumber = '';
  accountOwner = '';
  bankCity = '';
  bankCountry = '';
  bankName = '';
  blz = '';
  defaultFlg: boolean;
  ebicsStatus: string;
  iban = '';
  idMcEntity: number;
  swift = '';


  public properties: string[] = ['id', 'accountNumber', 'accountOwner', 'bankCity', 'bankCountry', 'bankName', 'blz', 'defaultFlg', 'ebicsStatus', 'iban', 'idMcEntity', 'swift'];
  public propertiesRegular: string[] = ['id', 'accountNumber', 'accountOwner', 'bankCity', 'bankCountry', 'bankName', 'blz', 'defaultFlg', 'ebicsStatus', 'iban', 'idMcEntity', 'swift'];
  public propertiesSpecial: string[] = [];



  public static getEbicsStatusVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McEbicsGENERATED.EBICS_STATUS_NONE, McGod.t('None'));
    list.add(McEbicsGENERATED.EBICS_STATUS_NEW, McGod.t('New'));
    list.add(McEbicsGENERATED.EBICS_STATUS_PENDING, McGod.t('Pending'));
    list.add(McEbicsGENERATED.EBICS_STATUS_ACCEPTED, McGod.t('Accepted'));


    return list;
  }

  public static getEbicsStatusLabel(aCd: string): string {
    const list = McEbicsGENERATED.getEbicsStatusVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McEbics {
    console.error('MTCN-ERROR: Not Implemented: McEbics::createNewInstance(). Add this method to final class and return new McEbics();');
    throw new Error('Cannot McEbics::createNewInstance(). Add this method to final class and return new McEbics();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcEbics): McEbics {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McEbicsService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McEbics AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getEbicsStatusLabel(): string {
    return McEbicsGENERATED.getEbicsStatusLabel(this.ebicsStatus);
  }


 public  isEbicsStatusNONE(): boolean {
    const result = (this.ebicsStatus === McEbicsGENERATED.EBICS_STATUS_NONE);

    return result;
  }

 public  isEbicsStatusNEW(): boolean {
    const result = (this.ebicsStatus === McEbicsGENERATED.EBICS_STATUS_NEW);

    return result;
  }

 public  isEbicsStatusPENDING(): boolean {
    const result = (this.ebicsStatus === McEbicsGENERATED.EBICS_STATUS_PENDING);

    return result;
  }

 public  isEbicsStatusACCEPTED(): boolean {
    const result = (this.ebicsStatus === McEbicsGENERATED.EBICS_STATUS_ACCEPTED);

    return result;
  }


  
  // ---------------------------------------------------------------------
  public loadDefaultByEntityId( idMcEntity: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getDefaultByEntityId(idMcEntity)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

}
