<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="csrMapping.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h1 *ngIf="csrMapping.exists()">{{'cc.consumers.edit-mapping' | translate}}: {{csrMapping.mappingName}}</h1>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!csrMapping.apiLoadingFlg" class="content-body">
    <div class="row mb-5">
      <div class="col-md-12">
        <div class="mc-error position-relative">
          <mc-notify-message [message]="notifyMessageObject.message" [type]="notifyMessageObject.type"></mc-notify-message>
        </div>
      </div>
    </div>

    <mcc-fi-textbox [name]="'ctrlMappingName'" label="{{'cc.consumers.consumers-overview.mapping-name' | translate}}"
                    [value]="csrMapping?.mappingName" validWhitespaces="true" validRequiredFlg="true"
                    [mcForm]="mcForm">
    </mcc-fi-textbox>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container>
      <mcc-fi-table-2 [objectList]="csrMapping.mappingItems"
                      (eventTableCellEditConfig)="onMappingItemTableCellConfig($event)"
                      (eventTableCellEdited)="onMappingTableCellEdited($event)"

      ></mcc-fi-table-2>
      <div class="col-md-12 mb-5 mt-n5">
        <div class="mc-error position-relative">
          <mc-notify-message [message]="notifyMessageObject.message" [type]="notifyMessageObject.type"></mc-notify-message>
        </div>
      </div>
    </ng-container>


  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary move-right" (eventClicked)="actClose();" label='<i class="far fa-times-circle"></i> {{"cc.common.close" | translate}}'></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary move-right" (eventClicked)="updateMappingItemList();" label='<i class="fas fa-save"></i> {{"cc.common.view.save" | translate}}'></mcc-button>

  </div>

</div>
<router-outlet></router-outlet>
