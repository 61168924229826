<lib-mc-loader [showLoader]="csrConsumer.apiLoadingFlg"></lib-mc-loader>

<div class="cardbox" *ngIf="!csrConsumer.apiLoadingFlg">
  <h2>{{'cc.consumer.overview.status-information' | translate}}</h2>
  <div class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.jobs.edit.type' | translate}}:</span>
      <span class='dialog-col-value'>{{ csrConsumer.typeCd ? csrConsumer.typeCd : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.consumers.action.type.dunning' | translate}}:</span>
      <span class='dialog-col-value'>{{ (csrConsumer.flgDunningEnabled ? 'cc.common.edit.yes' : 'cc.common.edit.no') | translate }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.consumers.action.type.inkasso' | translate}}:</span>
      <span class='dialog-col-value'>{{ (csrConsumer.flgInkassoEnabled ? 'cc.common.edit.yes' : 'cc.common.edit.no') | translate }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.edit.external-id' | translate}}:</span>
      <span class='dialog-col-value'>{{ csrConsumer.extConsumerId ? csrConsumer.extConsumerId : '-' }}</span>
    </div>
  </div>

  <div class="divider"></div>

  <h2>{{ "cc.common.edit.address" | translate}}</h2>
  <div class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.consumers.edit.street' | translate}}:</span>
      <span class='dialog-col-value'>{{ csrConsumer.street ? csrConsumer.street : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.consumers.edit.address-supplement' | translate}}:</span>
      <span class='dialog-col-value'>{{ csrConsumer.addressSupplement ? csrConsumer.addressSupplement : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.consumers.edit.postal-code' | translate}}:</span>
      <span class='dialog-col-value'>{{ csrConsumer.postCode ? csrConsumer.postCode : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.edit.city' | translate}}:</span>
      <span class='dialog-col-value'>{{ csrConsumer.city ? csrConsumer.city : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.users.edit.country' | translate}}:</span>
      <span class='dialog-col-value'>{{ csrConsumer.countryName ? csrConsumer.countryName : '-' }}</span>
    </div>
  </div>

  <div class="divider"></div>

  <h2>{{ "cc.consumer.overview.contact" | translate}}</h2>
  <div class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.edit.email' | translate}}:</span>
      <span class='dialog-col-value'>{{ csrConsumer.email ? csrConsumer.email : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.edit.telephone' | translate}}:</span>
      <span class='dialog-col-value'>{{ csrConsumer.telephone ? csrConsumer.telephone : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.consumers.edit.cellphone' | translate}}:</span>
      <span class='dialog-col-value'>{{ csrConsumer.cellphone ? csrConsumer.cellphone : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.edit.fax' | translate}}:</span>
      <span class='dialog-col-value'>{{ csrConsumer.fax ? csrConsumer.fax : '-' }}</span>
    </div>
  </div>

  <div class="divider"></div>

  <h2>{{'cc.users.edit.language' | translate}}</h2>
  <div class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.consumers.edit.iso-country' | translate}}:</span>
      <span class='dialog-col-value'>{{ csrConsumer.isoCountry ? getCountryName(csrConsumer.isoCountry) : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.consumers.edit.iso-language' | translate}}:</span>
      <span class='dialog-col-value'>{{ csrConsumer.isoLanguage ? getLanguageName(csrConsumer.isoLanguage) : '-' }}</span>
    </div>
  </div>
</div>
<div style="height: 20px"></div>
