<div class="content-wrapper">
  <lib-mc-loader [showLoader]="loadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h1>Create payout file</h1>
  </div>


  <div class="content-body">
    <mcc-message [errorMsg]="statementList.apiErrorMessage"></mcc-message>
    <mcc-message [errorMsg]="errorMsg"></mcc-message>

    <div *ngIf="!loadingFlg" class="row">
      <div class="col-md-12">
        <div class="content-section">

          <ng-container *ngIf="!fileCreatedFlg">
            <div class="row">
              <div class="col-md-12">
                <div class="mc-filter fancy-label">

                    <mcc-fi-textbox [isClearEnabled]="true" label="{{'cc.common.search' | translate}}" name="txbSearchTerm"
                                    (eventEnterClicked)="actLoad()"
                                    [mcForm]="mcForm"></mcc-fi-textbox>

                    <mcc-multiselect-filter [optionVll]="entityVll" [clearedAllFilters]="clearedAllMultipleFilters"
                                            label="Entity" name="selectEntity"
                                            (eventSelectedItems)="onSelectEntity($event)">
                    </mcc-multiselect-filter>

                  <div class="medium-date">
                  <mcc-fi-date-picker
                    [mcForm]="mcForm"
                    name="ctrlStatementDate"
                    label="Statement date"
                    [allowManualEntryFlg]="false"
                    (eventValueChanged)="onStatementDateValueChanged($event)"
                    [isBasicDatepickerInFilter]="true"
                  >
                  </mcc-fi-date-picker>
                  </div>

                  <button class="mc-link mc-filter-clear-button filter-clear-top-right"
                          (click)="actClearFilter()">{{'cc.my-factoring.clear-all' | translate}}
                  </button>
                  <button class="mc-button mc-filter-button"
                          [ngClass]="isFilterButtonDisabled ? 'mc-disable-button' : ''"
                          (click)="actLoad()"
                          [disabled]="isFilterButtonDisabled"><i class="fas fa-filter mr-2"></i>{{'cc.common.view.filter' | translate}}</button>
                </div>
              </div>

              <div class="col-md-12 mc-blue-text pt-3">
                <i class="fas fa-info-circle pr-2"></i>
                <span>Please select statement(s) to include in payout file.</span>
              </div>

              <div class="col-md-12">
                <mcc-fi-radio-buttons2 label="Create type"
                                       name="ctrlCreateType"
                                       [mcForm]="mcForm"
                                       [validRequiredFlg]="true"
                                       [directionHorizontal]="true"
                                       (eventValueChanged)="onCreateTypeChanged($event)"
                                       [optionsVll]="createTypeVll"></mcc-fi-radio-buttons2>
              </div>

              <div class="col-md-12">
                <mcc-fi-select [mcForm]="mcForm" name="selBankAccount" [validRequiredFlg]="true" [placeholderOption]="'cc.common.edit.please-choose' | translate" [label]="'cc.common.edit.bank-account' | translate"
                               [optionsVll]="bankAccountsVll"></mcc-fi-select>
              </div>

            </div>


            <div class="row">
              <div class="col-md-12" *ngIf="statementList.apiSuccessFlg">
                <mcc-fi-table-3 [objectList]="statementList"
                                [table3Config]="statementTable3Config"
                                (eventSelectionChanged)="actSelectedItems($event)"
                                (eventPaginationOrSortChanged)="actLoad()"></mcc-fi-table-3>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="fileCreatedFlg"> <!--------- FILE CREATED PART ----------->
            <div class="row">
              <div class="col-md-12">

                <mcc-message [successMsg]="successMsg"></mcc-message>
                <button class="mc-button-link mt-3"><i class="fas fa-file-csv"></i> {{webFile.originalFilename}}
                </button>

              </div>
            </div>
          </ng-container>

        </div>
      </div>
    </div><!-- row -->

  </div><!-- content-body -->

  <div class="row">
    <div class="col-md-12 position-relative">
      <div class="above-footer">
        <mcc-message [errorMsg]="errorMsg"></mcc-message>
      </div>
    </div>
  </div>

  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="Cancel"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                [disabledLabel]="saveButtonLabel"
                (eventClicked)="actSave();" label="{{saveButtonLabel}}"
                [ngClass]="isCreateButtonDisabled.value ? 'mc-disable-button' : ''"
                [disabledMcb]="isCreateButtonDisabled"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
