import {PpOrder} from './pp-order.model';
import {PpOrderListGENERATED} from '../_generated/pp-order-list-generated.model';

export class PpOrderList extends PpOrderListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PpOrderList();
  }

  // ---------------------------------------------------------------------
}
