/*BbmTestObj*/
import {McProcessGENERATED} from '../_generated/mc-process-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {ActionOptions} from '../_core/mc-html-action-options';
import {McDateUtils} from '../common/mc-date-utils';

export class McProcess extends McProcessGENERATED {

  public static ACTION_SHOW_PROFILE = 'PROFILE';
  public static ACTION_SHOW_ITEM_LIST = 'VIEW_ITEM_LIST';
  public static ACTION_SHOW_EMAIL = 'VIEW_EMAIL';
  public static ACTION_ACTIVATE = 'ACTIVATE';
  public static ACTION_DEACTIVATE = 'DEACTIVATE';

  private _fldActions: McHtml | null = null;
  private _fldNameLink: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcess();
  }

  // ---------------------------------------------------------------------

  fldName() {
    return this.title + ' Process';
  }

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.common.view-profile')}`, McProcess.ACTION_SHOW_PROFILE, this, [McGod.PERM_MC_PROCESS_VIEW], '', ActionOptions.OPTION_VIEW);
      if(this.typeCd != McProcess.TYPE_CD_NOTIFICATIONS) {
        actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> View items`, McProcess.ACTION_SHOW_ITEM_LIST, this, [McGod.PERM_MC_PROCESS_ITEM_VIEW], '', ActionOptions.OPTION_VIEW);
      } else {
        actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> View email`, McProcess.ACTION_SHOW_EMAIL, this, [McGod.PERM_MC_PROCESS_ITEM_VIEW], '', ActionOptions.OPTION_VIEW);
      }
      if (this.isStatusCdINACTIVE()) {
        actSet.addActionB(`<i class="fas mc-submit-color mc-cursor-pointer"></i> ${McGod.t('cc.common.activate')}`, McProcess.ACTION_ACTIVATE, this, [McGod.PERM_MC_PROCESS_EDIT], '', ActionOptions.OPTION_EDIT);
      } else {
        actSet.addActionB(`<i class="fas mc-submit-color mc-cursor-pointer"></i> ${McGod.t('cc.common.deactivate')}`, McProcess.ACTION_DEACTIVATE, this, [McGod.PERM_MC_PROCESS_EDIT], '', ActionOptions.OPTION_EDIT);
      }
      this._fldActions = html;
    }
    return this._fldActions;
  }

  formatInheritableFlg() {
    return this.inheritableFlg ? 'yes' : 'no';
  }

  fldStatus() {
    return (this.isStatusCdACTIVE() ? '<img src="assets/images/common-icons/active.svg" height="16px" width="16px">' : '<img src="assets/images/common-icons/inactive.svg" height="16px" width="16px">') + ' ' + this.getStatusCdLabel();
  }

  fldNameLink() {
    if (!this._fldNameLink) {
      const html = new McHtml();
      html.addLinkB(this.title, `entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-profile/${this.id}`, this, [McGod.PERM_MC_PROCESS_VIEW], 'mc-blue-link', ActionOptions.OPTION_VIEW);
      this._fldNameLink = html;
    }
    return this._fldNameLink.items;
  }

  fldFlowsActiveTotal(): string {
    if (this.activeMcProcessFlowCount && this.totalMcProcessFlowCount) {
      return this.activeMcProcessFlowCount + '/' + this.totalMcProcessFlowCount;
    }
    if (!this.activeMcProcessFlowCount && this.totalMcProcessFlowCount) {
      return '0/' + this.totalMcProcessFlowCount;
    }
    if (!this.activeMcProcessFlowCount && !this.totalMcProcessFlowCount) {
      return '0/0';
    }
    return '';
  }

  override getLastRunDateTimeStr(): string {
    if (this.lastRunDateTime) {
      return McDateUtils.newFormatDateTimeString(this.lastRunDateTime);
    }
    return '';
  }
}
