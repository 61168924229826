import {PpPriceAdjustmentFilterGENERATED} from '../_generated/pp-price-adjustment-filter-generated.model';

export class PpPriceAdjustmentFilter extends PpPriceAdjustmentFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PpPriceAdjustmentFilter();
  }

  // ---------------------------------------------------------------------
}
