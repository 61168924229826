/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {PpInvoice} from '../models/pp-invoice.model';
import {PpInvoiceService} from '../services/pp-invoice.service';
import {IPpInvoice} from '../_generated/pp-invoice.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class PpInvoiceGENERATED extends BaseObject {


    public static readonly STATE_DRAFT: string = 'DRAFT';
    public static readonly STATE_SCHEDULED: string = 'SCHEDULED';
    public static readonly STATE_SENT: string = 'SENT';
    public static readonly STATE_PAID: string = 'PAID';
    public static readonly STATE_OVERDUE: string = 'OVERDUE';
    public static readonly STATE_STORNO: string = 'STORNO';
  public static readonly STATE_LIST__ALL = [
    PpInvoiceGENERATED.STATE_DRAFT,
    PpInvoiceGENERATED.STATE_SCHEDULED,
    PpInvoiceGENERATED.STATE_SENT,
    PpInvoiceGENERATED.STATE_PAID,
    PpInvoiceGENERATED.STATE_OVERDUE,
    PpInvoiceGENERATED.STATE_STORNO
];

  public apiService: PpInvoiceService;
  public _rawJson: IPpInvoice;
    id: number = 0;
  dueDate: number;
  idPpOrder: number;
  invoiceNumber = '';
  senderId: number;
  sentDate: number;
  state: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  sysUpdatedUserId: number;
  totalAmount: number;
  version: number;
  description: string;  discount: number;  discountPrice: any;  frequency: string;  invoiceItemSorterId: number;  productId: number;  quantity: number;  totalPrice: any;  unitPrice: any;  vat: any;

  public properties: string[] = ['id', 'dueDate', 'idPpOrder', 'invoiceData', 'invoiceNumber', 'senderId', 'sentDate', 'state', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'totalAmount', 'version', 'description', 'discount', 'discountPrice', 'frequency', 'invoiceItemSorterId', 'productId', 'quantity', 'totalPrice', 'unitPrice', 'vat'];
  public propertiesRegular: string[] = ['id', 'dueDate', 'idPpOrder', 'invoiceData', 'invoiceNumber', 'senderId', 'sentDate', 'state', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'totalAmount', 'version', 'description', 'discount', 'discountPrice', 'frequency', 'invoiceItemSorterId', 'productId', 'quantity', 'totalPrice', 'unitPrice', 'vat'];
  public propertiesSpecial: string[] = [];



  public static getStateVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PpInvoiceGENERATED.STATE_DRAFT, McGod.t('Draft'));
    list.add(PpInvoiceGENERATED.STATE_SCHEDULED, McGod.t('Scheduled'));
    list.add(PpInvoiceGENERATED.STATE_SENT, McGod.t('Sent'));
    list.add(PpInvoiceGENERATED.STATE_PAID, McGod.t('Paid'));
    list.add(PpInvoiceGENERATED.STATE_OVERDUE, McGod.t('Overdue'));
    list.add(PpInvoiceGENERATED.STATE_STORNO, McGod.t('Storno'));


    return list;
  }

  public static getStateLabel(aCd: string): string {
    const list = PpInvoiceGENERATED.getStateVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): PpInvoice {
    console.error('MTCN-ERROR: Not Implemented: PpInvoice::createNewInstance(). Add this method to final class and return new PpInvoice();');
    throw new Error('Cannot PpInvoice::createNewInstance(). Add this method to final class and return new PpInvoice();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IPpInvoice): PpInvoice {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(PpInvoiceService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE PpInvoice AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getDueDateStrD(): string {
       return McDateUtils.formatDateToString(this.dueDate);
    }

    public getDueDateStr(): string {
       return McDateUtils.formatDateTimeToString(this.dueDate);
    }

    public getSentDateStrD(): string {
       return McDateUtils.formatDateToString(this.sentDate);
    }

    public getSentDateStr(): string {
       return McDateUtils.formatDateTimeToString(this.sentDate);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getTotalAmountStr(): string {
       return McGod.formatNumberToMoney(this.totalAmount);
    }

    public getDiscountPriceStr(): string {
       return McGod.formatNumberToMoney(this.discountPrice);
    }

    public getTotalPriceStr(): string {
       return McGod.formatNumberToMoney(this.totalPrice);
    }

    public getUnitPriceStr(): string {
       return McGod.formatNumberToMoney(this.unitPrice);
    }

    public getVatStr(): string {
       return McGod.formatNumberToMoney(this.vat);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStateLabel(): string {
    return PpInvoiceGENERATED.getStateLabel(this.state);
  }


 public  isStateDRAFT(): boolean {
    const result = (this.state === PpInvoiceGENERATED.STATE_DRAFT);

    return result;
  }

 public  isStateSCHEDULED(): boolean {
    const result = (this.state === PpInvoiceGENERATED.STATE_SCHEDULED);

    return result;
  }

 public  isStateSENT(): boolean {
    const result = (this.state === PpInvoiceGENERATED.STATE_SENT);

    return result;
  }

 public  isStatePAID(): boolean {
    const result = (this.state === PpInvoiceGENERATED.STATE_PAID);

    return result;
  }

 public  isStateOVERDUE(): boolean {
    const result = (this.state === PpInvoiceGENERATED.STATE_OVERDUE);

    return result;
  }

 public  isStateSTORNO(): boolean {
    const result = (this.state === PpInvoiceGENERATED.STATE_STORNO);

    return result;
  }


  
}
