import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Table2ColumnConfig, Table2Config} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-table-configuration-modal',
  templateUrl: './mc-table-configuration-modal.component.html',
})
export class McTableConfigurationModalComponent implements OnInit {

  tableSetupConfigurationTableContent!: boolean;
  settingsConfigurationModalSubscription: any;

  /*Events*/
  @Input() tableConfig!: Table2Config;
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  /*Open modal*/
  public openModal(modal: any) {
    this.settingsConfigurationModalSubscription = this.modalService.open(modal, {
      centered: true,
      windowClass: 'mc-table-settings-modal',
      size: 'lg',
      backdrop: 'static'
    });
  }

  /*On check column*/
  public onSelectTableColumnItem(column: { selected: boolean; }) {
    column.selected = !column.selected;
    this.tableSetupConfigurationTableContent = this.tableConfig.columnObjectList.filter(contentItem => !contentItem.selected).length === 0;
  }

  /*On save table configuration*/
  public onSaveTableColumnSetup() {
    this.tableConfig.columnObjectList.map(item => item.isHide = item.selected);
    this.settingsConfigurationModalSubscription.close();
  }

}
