/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EbPaymentReport2SepaFileTransaction} from '../models/eb-payment-report-2-sepa-file-transaction.model';
import {EbPaymentReport2SepaFileTransactionList} from '../models/eb-payment-report-2-sepa-file-transaction-list.model';
import {EbPaymentReport2SepaFileTransactionFilter} from '../models/eb-payment-report-2-sepa-file-transaction-filter.model';
import {EbPaymentReport2SepaFileTransactionService} from '../services/eb-payment-report-2-sepa-file-transaction.service';
import {IEbPaymentReport2SepaFileTransaction} from '../_generated/eb-payment-report-2-sepa-file-transaction.interface';
import {McGod} from "../extra/mc-god.service";

export class EbPaymentReport2SepaFileTransactionListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'amount', 'idPaymentReport', 'idSepaFileTransaction', 'ebPaymentReport'];
  public items: EbPaymentReport2SepaFileTransaction[] = [];
  public apiService: EbPaymentReport2SepaFileTransactionService;
 // protected tempObjList: EbPaymentReport2SepaFileTransactionList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbPaymentReport2SepaFileTransactionList {
    console.error('MTCN-ERROR: Not Implemented: EbPaymentReport2SepaFileTransactionList::createNewInstance(). Add this method to final class and return new EbPaymentReport2SepaFileTransactionList();');
    throw new Error('Cannot EbPaymentReport2SepaFileTransactionList::createNewInstance(). Add this method to final class and return new EbPaymentReport2SepaFileTransactionList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEbPaymentReport2SepaFileTransaction[]): EbPaymentReport2SepaFileTransactionList {
    const  listObj: EbPaymentReport2SepaFileTransactionList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EbPaymentReport2SepaFileTransaction = EbPaymentReport2SepaFileTransaction.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EbPaymentReport2SepaFileTransactionList {
    const  listObj: EbPaymentReport2SepaFileTransactionList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbPaymentReport2SepaFileTransactionService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEbPaymentReport2SepaFileTransaction[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EbPaymentReport2SepaFileTransaction = EbPaymentReport2SepaFileTransaction.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEbPaymentReport2SepaFileTransactionFilter: EbPaymentReport2SepaFileTransactionFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEbPaymentReport2SepaFileTransactionFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EbPaymentReport2SepaFileTransaction {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EbPaymentReport2SepaFileTransaction();
    }
  }

  public replaceItem(newItem: EbPaymentReport2SepaFileTransaction) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

