/*BbmTestObj*/
import {ImDataMappingItemGENERATED} from '../_generated/im-data-mapping-item-generated.model';

export class ImDataMappingItem extends ImDataMappingItemGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataMappingItem();
  }

  // ---------------------------------------------------------------------
}
