/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingPayoutFileFeCtdTable3ConfigGENERATED} from '../_generated/mc-billing-payout-file-fe-ctd-table-3-config-generated.model';

// import {McBillingPayoutFileFeCtdGENERATED} from '../_generated/mc-billing-payout-file-fe-ctd-generated.model';

export class McBillingPayoutFileFeCtdTable3Config extends McBillingPayoutFileFeCtdTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colFileName', 'File name', 'getFilenameWithIcon()', '');
    this.addColumn('colTypeCd', this.t('cc.payout.payout-type'), 'getTypeCdLabel()', '');
    this.addColumn('colBankName', this.t('cc.ebics.bank-name'), 'bankName', 'idMcBankAccount');
    this.addColumn('colNumberOfEntities', 'No. of statements', 'numberOfStatements', 'numberOfStatements');
    this.addColumn('colTotalFileAmount', 'Total file amount', 'fldTotalFileAmount()', 'totalFileAmount').extraCssClass = 'text-right';
    this.addColumn('colPayoutDate', 'Payout date', 'fldPayoutDate()', 'payoutDate');
    this.addColumn('colCreatedDateAndTime', 'Created Date & Time', 'fldCreatedDateTime()', 'sysCreatedDatetime');
    this.addColumn('colCreatedBy', 'Created by', 'createdBy', '');
    this.addColumn('colStatus', 'Status', 'getStatusCdLabel()', '');
  }
}
