
import {Table3Config} from '../extra/table-3-config';

// import {McMappingItemGENERATED} from '../_generated/mc-mapping-item-generated.model';

export class McMappingItemTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAttributeName', 'Attribute name', 'attributeName', 'attributeName');
    this.addColumn('colColumnName', 'Column name', 'columnName', 'columnName');
    this.addColumn('colIdMcMapping', 'McMapping ID', 'idMcMapping', 'idMcMapping');
    this.addColumn('colRequiredFlg', 'Required flag', 'requiredFlg', 'requiredFlg');
    this.addColumn('colSampleData', 'Sample data', 'sampleData', 'sampleData');

*/
  }
}
