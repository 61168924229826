import {Component, Inject, OnInit} from '@angular/core';
import {
  McCountry,
  McVat,
  McVatList,
  McGod,
  McBillingServiceCatalogList,
  McBillingService2VatService, SortCriteriaList, McBillingService2VatList
} from '@miticon-ui/mc-core';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'mc-country-country-profile-page',
  templateUrl: './mc-country-country-profile-page.component.html',
})
export class McCountryCountryProfilePageComponent implements OnInit {

  mcCountryId!: number;
  mcCountry!: McCountry;
  mcVatItemList = new McVatList();
  serviceList = new McBillingServiceCatalogList();
  result!: string;
  billingService2VatList = new McBillingService2VatList();

  // ---------------------------------------------------------------------
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private billingService2VatService: McBillingService2VatService,
    @Inject("designConfig") design: any) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      // @ts-ignore
      this.mcCountryId = +params.get('id');
      this.mcCountry = new McCountry();
      this.actLoad();
    });
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {

    this.mcCountry.loadById(this.mcCountryId, () => {
      this.mcVatItemList = this.mcCountry.mcVATItemList;
      this.mcVatItemList.items.forEach(item => {
        this.billingService2VatService.getByVATId(item.id, 0, 100, new SortCriteriaList())
            .subscribe(res => {
              this.billingService2VatList.items.push(...res.content);
            });
      });
      this.serviceList.loadAll(() => {
        this.serviceList.setApiSuccess();
      });
    });
  }

  getVatTypeLabel(typeCd: string) {
    return McVat.getVatTypeCdLabel(typeCd);
  }

  actGoBackToCountries() {
    this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/countries/overview`);
  }

  fldServices(mcVat: McVat) {
    let result = '';
    this.billingService2VatList.items
      .forEach(item => {
        const serviceItem: any = this.serviceList.getItemById(item.idBillingService);
        if (mcVat.id === item.idVat) {
          if (serviceItem) {
            result += `${serviceItem.title} <br>`;
          }
        }
      });

    return result;
  }
}
