import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  @Input() popupType: 'info' | 'warning' | 'question' | 'error' = 'question';
  @Input() header = '';
  @Input() description = '';
  @Input() cancelButtonLabel = '';
  @Input() saveButtonLabel = '';
  @Output() eventCancel = new EventEmitter();
  @Output() eventSave = new EventEmitter();

  iconClass = 'far fa-question-circle text-orange-color fa-4x';
  constructor() {
  }

  ngOnInit() {
    this.setIconClass();
  }

  setIconClass() {
    if (this.popupType === 'info') {
      this.iconClass = 'fas fa-info-circle text-orange-color fa-4x';
    } else if (this.popupType === 'warning') {
      this.iconClass = 'fas fa-exclamation-circle text-orange-color fa-4x';
    } else if (this.popupType === 'error') {
      this.iconClass = 'fas fa-times text-danger fa-4x';
    } else if (this.popupType === 'question') {
      this.iconClass = 'far fa-question-circle text-orange-color fa-4x';
    }
  }

  actCancel() {
    this.eventCancel.emit();
  }

  actSave() {
    this.eventSave.emit();
  }
}
