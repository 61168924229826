import {Component, EventEmitter, Inject, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CustomAttributeDto, McEntityService, McUtilityService} from "@miticon-ui/mc-core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoginEnums} from "@miticon-ui/mc-core";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: "mc-entity-attributes",
  templateUrl: "./mc-entity-attributes.component.html"
})
export class McEntityAttributesComponent implements OnInit {

  entityAttributesForm: FormGroup;
  attributesTab!: boolean;
  message!: string;
  type!: string;
  isDisabled!: boolean;
  entityLocalAttributesList: any[] = [];
  attributesFromBe: any[] = [];
  selectedAttributeList: any[] = [];
  customAttributeList!: any;
  newObj!: CustomAttributeDto;
  newArray: CustomAttributeDto[] = [];
  showLoader!: boolean;
  attributesIcons;
  attributesInfoIcon;
  attributesWhiteInfoIcon;
  hoveredIcon!: any;
  parentEntityId!: any;
  currentEntityId!: any;

  // Inputs
  @Input() editEntityId!: any;
  @Input() inheritedAttributes!: any;
  @Input() parentForm!: FormGroup;
  @Input() entityId!: any;
  // Inputs from editModal
  @Input() isEdit!: any;
  @Input() localAttributesList!: any;
  @Input() inheritedAttributesList!: any;
  @Input() markedInheritedAttributes!: any;

  // Outputs
  @Output() isEntityAttributesDataInvalid = new EventEmitter<boolean>();

  constructor(
    private modalService: NgbModal,
    private entityService: McEntityService,
    private utilityService: McUtilityService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    @Inject("designConfig") design: any
  ) {
    this.entityAttributesForm = new FormGroup({});
    // import attributes icons
    this.attributesIcons = design.attributesIcons;
    this.attributesInfoIcon = this.attributesIcons.info.path;
    this.attributesWhiteInfoIcon = this.attributesIcons.whiteInfo.path;

    /*Set unique Id for current entity*/
    this.activatedRoute.paramMap.subscribe(data => {
      if (data.get("id")) {
        this.currentEntityId = data.get("id");
      }
    });
  }

  ngOnInit() {
    // get attributes from be
    this.getInheritedAttributes();
    // if edit modal is opened set inherited attributes list for current entity
    if (this.inheritedAttributesList) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < this.inheritedAttributesList.length; i += 1) {
        // put all selected inherit attributes in the selectAttributeList
        if (this.inheritedAttributesList[i].select === true) {
          this.selectedAttributeList = [...this.selectedAttributeList, this.inheritedAttributesList[i]];
        }
      }
      this.inheritedAttributes = [...this.inheritedAttributesList];
    } else if (this.inheritedAttributes) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < this.inheritedAttributes.length; i += 1) {
        if (this.inheritedAttributes[i].select === true) {
          this.shareUnlockProperty(false);
          this.isDisabled = true;
          // put all selected inherit attributes in the selectAttributeList
          this.selectedAttributeList = [...this.selectedAttributeList, this.inheritedAttributes[i]];
        } else {
          this.shareUnlockProperty(true);
          this.isDisabled = false;
        }
      }
    }
    // if edit modal is opened set local attributes list for current entity
    if (this.localAttributesList) {
      this.entityLocalAttributesList = [...this.localAttributesList];
    }
    // set formValues
    this.createForm();
    // Forward child entitySummary form to the parent one
    if (this.parentForm) {
      this.parentForm.setControl("step2", this.entityAttributesForm);
    }
    // Set color for active tab
    this.attributesTab = true;
    // Disable saveBtn when editForm is empty
    this.isDisabled = !(this.localAttributesList || this.inheritedAttributesList);
    // Empty notification message
    this.setNotificationMessage("", "");
  }

  // Create form
  createForm() {
    this.entityAttributesForm = this.fb.group({
      inheritAttributes: [this.selectedAttributeList ? this.selectedAttributeList : this.inheritedAttributes],
      localAttributes: [this.entityLocalAttributesList]
    });
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  // Unlock Next btn
  private shareUnlockProperty(valid: boolean) {
    this.isEntityAttributesDataInvalid.emit(
      valid
    );
    this.isDisabled = valid;
  }

  public getInheritedAttributes() {
    let msgText;
    let msgType;
    const loggedEntityId = this.utilityService.getPropertyFromToken("entity_id");
    if (this.currentEntityId) {
      this.entityService.getMcEntityById(this.currentEntityId).subscribe(response => {
        this.parentEntityId = response.parentMcEntityId;
        if (this.parentEntityId) {
          this.entityService.getEntityAttributesById(this.parentEntityId).subscribe(responseAttr => {
            this.attributesFromBe = responseAttr;
          }, error => {
            if (error.status >= 500) {
              msgText = "Server is not available at the moment, please try again later.";
              msgType = "error";
              this.setNotificationMessage(msgText, msgType);
              return;
            }
            msgText = error.error ? error.error.message : "There is no custom attributes defined";
            msgType = "error";
            this.setNotificationMessage(msgText, msgType);
          });
        }
      }, error => {
        if (error.status >= 500) {
          msgText = "Server is not available at the moment, please try again later.";
          msgType = "error";
          return;
        }
        msgText = error.error ? error.error.message : "There is no basic info defined";
        msgType = "error";
      });
    } else {
      this.entityService.getEntityAttributesById(loggedEntityId).subscribe(response => {
        this.attributesFromBe = response;
      }, error => {
        if (error.status >= 500) {
          msgText = "Server is not available at the moment, please try again later.";
          msgType = "error";
          this.setNotificationMessage(msgText, msgType);
          return;
        }
        msgText = error.error ? error.error.message : "There is no custom attributes defined";
        msgType = "error";
        this.setNotificationMessage(msgText, msgType);
      });
    }
  }

  // Select all inherited attributes
  selectAll() {
    const inheritedList = this.inheritedAttributes;
    if (inheritedList) {
      if (inheritedList.length > 0) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < inheritedList.length; i += 1) {
          inheritedList[i].select = true;
        }
      }
      this.selectedAttributeList = [...this.inheritedAttributes];
      this.entityAttributesForm.get("inheritAttributes")?.setValue(this.selectedAttributeList);
      this.shareUnlockProperty(false);
    }
  }

  // Select all inherited attributes
  deselectAll() {
    const inheritedList = this.inheritedAttributes;
    if (inheritedList) {
      if (inheritedList.length > 0) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < inheritedList.length; i += 1) {
          inheritedList[i].select = false;
        }
      }
      this.selectedAttributeList = [];
      this.entityAttributesForm.get("inheritAttributes")?.setValue([]);
      if (this.entityLocalAttributesList.length === 0) {
        this.shareUnlockProperty(true);
      } else {
        this.shareUnlockProperty(false);
      }
    }
  }

  // Add new local attribute object to the array
  addNewLocalAttribute(localAttributeObj: CustomAttributeDto) {
    this.entityLocalAttributesList = [...this.entityLocalAttributesList, localAttributeObj];
    this.entityAttributesForm.get("localAttributes")?.setValue(this.entityLocalAttributesList);
    this.shareUnlockProperty(false);
  }

  // Edit local attribute object
  editLocalAttribute(localAttributeObj: CustomAttributeDto, index: any) {
    this.entityLocalAttributesList[index] = localAttributeObj;
    this.entityAttributesForm.get("localAttributes")?.setValue(this.entityLocalAttributesList);
    this.shareUnlockProperty(false);
  }

  // Remove localAttribute from the list
  removeLocalAttribute(index: number) {
    this.entityLocalAttributesList.splice(index, 1);
    this.entityAttributesForm.get("localAttributes")?.setValue(this.entityLocalAttributesList);
    if (this.entityLocalAttributesList.length === 0 && this.selectedAttributeList.length === 0) {
      this.shareUnlockProperty(true);
    } else {
      this.shareUnlockProperty(false);
    }
  }

  // When checkbox is selected
  onSelectInheritAttribute(selectedAttribute: any, event:any, index: number) {
    selectedAttribute.select = !selectedAttribute.select;
    if (event.checked === true) {
      this.selectedAttributeList = [...this.selectedAttributeList, selectedAttribute];
      this.entityAttributesForm.get("inheritAttributes")?.setValue(this.selectedAttributeList);
      this.shareUnlockProperty(false);
    } else if (event.checked === false) {
      const itemIndex = this.selectedAttributeList.findIndex(item => item.name === selectedAttribute.name);
      if (index >= 0) {
        this.selectedAttributeList.splice(itemIndex, 1);
      }
      this.entityAttributesForm.get("inheritAttributes")?.setValue(this.selectedAttributeList);
    }
    if (this.selectedAttributeList.length === 0 && this.entityLocalAttributesList.length === 0) {
      this.shareUnlockProperty(true);
    } else {
      this.shareUnlockProperty(false);
    }
  }

  // Edit entityAttributesData
  editEntityAttributes() {
    this.showLoader = true;
    if (this.isEdit === true) {
      let msgText: string;
      let msgType: string;

      const attributesData = this.entityAttributesForm.getRawValue();

      this.newArray = [];
      // Multiple array
      let filteredInheritedAttributes;
      let filteredLocalAttributes;
      if (attributesData.inheritAttributes) {
        filteredInheritedAttributes = attributesData.inheritAttributes.filter((item: any) => {
          item.inherited = true;
          return item;
        });
      }
      if (attributesData.localAttributes) {
        filteredLocalAttributes = attributesData.localAttributes.filter((item: any) => {
          item.inherited = false;
          return item;
        });
      }
      if (filteredLocalAttributes && filteredInheritedAttributes) {
        this.customAttributeList = [...filteredLocalAttributes, ...filteredInheritedAttributes];
      } else if (filteredLocalAttributes) {
        this.customAttributeList = [...filteredLocalAttributes];
      } else if (filteredInheritedAttributes) {
        this.customAttributeList = [...filteredInheritedAttributes];
      }
      const attributeList = this.customAttributeList;
      if (attributeList) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < attributeList.length; i += 1) {
          let attributeType;
          switch (attributeList[i].type) {
            case "Number":
              attributeType = "INTEGER";
              break;
            case "String":
              attributeType = "VARCHAR";
              break;
            case "Date":
              attributeType = "DATE";
              break;
            case "Date Time":
              attributeType = "DATETIME";
              break;
            case "Decimal":
              attributeType = "FLOAT";
              break;
            case "Yes/No":
              attributeType = "BOOLEAN";
              break;
            default:
              attributeType = attributeList[i].type;
          }
          this.newObj = {
            name: attributeList[i].name,
            description: attributeList[i].description,
            type : attributeType,
            length: attributeList[i].length,
            defaultValue: attributeList[i].defaultValue === "" ? null : attributeList[i].defaultValue,
            enabled: attributeList[i].enabled,
            required: attributeList[i].required,
            archived: false,
            validationRegex: attributeList[i].validationRegex,
            inherited: attributeList[i].inherited,
            inheritable: attributeList[i].inheritable
          };
          this.newArray = [...this.newArray, this.newObj];
        }
      }
      const editAttributesList = this.newArray;

      if (this.editEntityId) {
        // edit current entity
        this.entityService.editCurrentEntityAttributes(editAttributesList, this.editEntityId).subscribe(
          () => {
            msgText = LoginEnums.successMsg;
            msgType = LoginEnums.successMsg;
            this.modalService.dismissAll("Cross click");
            this.showLoader = false;

          },
          (error) => {
            if (error.status >= 500) {
              msgText = "Server is not available at the moment, please try again later.";
              msgType = "error";
              this.setNotificationMessage(msgText, msgType);
              this.showLoader = false;
              return;
            }

            msgText = error.error ? error.error.message : error.message;
            msgType = error.ok === false ? LoginEnums.errorMsg : LoginEnums.successMsg;
            this.setNotificationMessage(msgText, msgType);
            this.showLoader = false;
          }
        );
      } else {
        // edit logged entity
        this.entityService.editEntityAttributes(editAttributesList).subscribe(
          () => {
            msgText = LoginEnums.successMsg;
            msgType = LoginEnums.successMsg;
            this.modalService.dismissAll("Cross click");
            this.showLoader = false;

          },
          (error) => {
            this.showLoader = false;
            if (error.status >= 500) {
              msgText = "Server is not available at the moment, please try again later.";
              msgType = "error";
              this.setNotificationMessage(msgText, msgType);
              return;
            }

            msgText = error.error ? error.error.message : error.message;
            msgType = error.ok === false ? LoginEnums.errorMsg : LoginEnums.successMsg;
            this.setNotificationMessage(msgText, msgType);
          }
        );
      }
    }
  }
}
