<section class="mc-edit-user">

  <div class="mc-edit-user-general-data mt-4">
    <div class="row">
      <div class="col-md-12 mc-edit-user-general-data-user-name">
        {{userFirstname}} {{userLastname}}
      </div>

      <div class="mc-create-user-notify-wrapper">
        <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
      </div>

      <div class="col-md-12 mc-edit-user-general-data-personal-info">
        <a class="col-md-12 mc-edit-user-personal-info-link" id="personalInfoEditBtn" >
          {{ 'cc.common.personal-info' | translate }} <i class="fa fa-pen mc-cursor-pointer"
                           McPermission
                           [hasPermissions]="userCanEditUsersPermission"
                           (click)="openPersonalInfoModal(personalInfoEditModal)"
          ></i>
        </a>
        <div class="row pt-2">
          <div class="col-md-2">
            <div class="img-thumbnail mc-new-user-image"
                 [ngStyle]="{'background-image': 'url(' + userImage + ')'}">
            </div>
          </div>
          <div class="col-md-10">
            <div class="row mc-edit-user-first-block">
              <div class="col-md-12 mc-edit-user-preview-data">
                <span class="mc-edit-user-preview-data-field-name">{{'cc.common.view.status' | translate}}: </span>
                <span class="mc-edit-user-preview-data-field-value">
                  <mc-status [status]="userStatus"></mc-status>
                </span>
              </div>
              <div class="col-md-12 mc-edit-user-preview-data">
                <span class="mc-edit-user-preview-data-field-name">{{ 'cc.common.edit.email' | translate }}: </span>
                <span class="mc-edit-user-preview-data-field-value">{{userEmail}}</span>
              </div>
            </div>

            <div class="row mc-edit-user-second-block pt-3">
              <div class="col-md-6 mc-second-block-fields">
                <div class="mc-edit-user-preview-data">
                  <span class="mc-edit-user-preview-data-field-name">{{ 'cc.common.edit.first-name' | translate }}: </span>
                  <span class="mc-edit-user-preview-data-field-value">{{userFirstname}}</span>
                </div>
                <div class="mc-edit-user-preview-data">
                  <span class="mc-edit-user-preview-data-field-name">{{ 'cc.common.edit.last-name' | translate }}: </span>
                  <span class="mc-edit-user-preview-data-field-value">{{userLastname}}</span>
                </div>
                <div class="mc-edit-user-preview-data">
                  <span class="mc-edit-user-preview-data-field-name">Nickname: </span>
                  <span class="mc-edit-user-preview-data-field-value">{{userNickname}}</span>
                </div>
                <div class="mc-edit-user-preview-data">
                  <span class="mc-edit-user-preview-data-field-name">{{ 'cc.common.edit.gender' | translate }}: </span>
                  <span class="mc-edit-user-preview-data-field-value">{{userGender}}</span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mc-edit-user-preview-data">
                  <span class="mc-edit-user-preview-data-field-name">{{'cc.users.edit.language' | translate}}: </span>
                  <span class="mc-edit-user-preview-data-field-value">{{userLanguage}}</span>
                </div>
                <div class="mc-edit-user-preview-data">
                  <span class="mc-edit-user-preview-data-field-name">{{'cc.users.edit.timezone' | translate}}: </span>
                  <span class="mc-edit-user-preview-data-field-value">{{userTimezone}}</span>
                </div>
                <div class="mc-edit-user-preview-data">
                  <span class="mc-edit-user-preview-data-field-name">{{'cc.users.edit.country' | translate}}: </span>
                  <span class="mc-edit-user-preview-data-field-value">{{userCountry}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mc-edit-user-general-data-personal-info">
        <div class="col-md-12 mc-edit-user-personal-info-link mc-cursor-pointer">
          <a (click)="resendUserMailMcb.setTrue()">Resend user email<i class="fas fa-paper-plane mc-cursor-pointer"
          ></i></a>
        </div>
      </div>
      <div class="col-md-12 mc-edit-user-general-data-roles">
        <a class="col-md-12 mc-edit-user-personal-info-link" id="editRolesBtn" >
          {{ 'cc.common.admin.roles' | translate }}
          <i class="fa fa-pen mc-cursor-pointer"
             McPermission
             [hasPermissions]="userCanEditUsersPermission"
             (click)="openEditRolesModal(editRolesModal)"
          ></i>
        </a>
        <div class="col-md-12 pt-4 pb-4 mc-edit-user-personal-info-roles">
          <div *ngFor="let role of userRoles">
            <span class="mc-create-new-user-entity-name">{{role.entity.name}}</span>
            <span class="">{{role.caption}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-12 info-container-secondary" *ngIf="mcGod.userHasMcPasswordResetPermission() || mcGod.userHasMcDisableEnable2faPermission()">
        <p class="col-md-12 mc-edit-user-personal-info-link">
          {{'cc.common.account-info' | translate}}
        </p>
        <div class="row">
          <div class="col" *ngIf="mcGod.userHasMcDisableEnable2faPermission()">
            <div class="info-cell">
              <div class="icon-holder"><img src="assets/images/2factor.svg"></div>
              <div class="account-data">
                <span class="info-title">{{'cc.common.view.two-factor-authentication' | translate}}</span>
                <mc-two-fa-account-authentication-modal (childEvent)="onChange2Fa($event)" [userId]="userId" [twoFaForUser]="true" [parentData]="isEnabledTwoFa"></mc-two-fa-account-authentication-modal>
                <br>
                <span style="font-weight: 500">{{'cc.user.current-status' | translate}}
                  <img style="margin-left: 13px;" class="mc-user-profile-status-icon" [src]="isEnabledTwoFa ? 'assets/images/green-checkmark.svg' : 'assets/images/disabled.svg'"><span> {{twoFaAuth}}</span></span>
              </div>
            </div>
          </div>
          <div class="col" *ngIf="mcGod.userHasMcPasswordResetPermission()">
            <div class="info-cell">
              <div class="icon-holder"><img src="assets/images/lock.svg"></div>
              <div class="account-data">
                <span class="info-title">{{'cc.common.view.change-password' | translate}}</span>
                <mc-change-password-modal [userId]="userId" [changePassForUser]="true"></mc-change-password-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Personal info modal-->
<ng-template #personalInfoEditModal let-modal>
  <!--Modal header section-->
  <div class="modal-header mc-create-user-modal-header">
    <div>
      <h4 class="modal-title" id="mc-modal-new-user-title">Edit user personal info</h4>
    </div>
    <div class="mc-create-user-entity-path" *ngIf="selectedEntityPath">
      <span *ngFor="let entity of selectedEntityPath">
        {{entity}}
      </span>
    </div>
    <button type="button" class="close mc-new-user-close-btn" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div><!--Modal header -->
  <!--Modal body section-->
  <div class="modal-body">
    <lib-mc-user-general-data
      [userPersonalData]="userPersonalData"
      (refreshUserDataEvent)="onSuccessEditPersonalData($event)"></lib-mc-user-general-data>
  </div>
  <!--Modal body section #-->
</ng-template>

<!--Roles Modal-->
<ng-template #editRolesModal let-modal>
  <!--Modal header section-->
  <div class="modal-header mc-create-user-modal-header">
    <div>
      <h4 class="modal-title">Edit user roles for: {{userFirstname}} {{userLastname}}</h4>
    </div>
    <div class="mc-create-user-entity-path" *ngIf="selectedEntityPath">
      <span *ngFor="let entity of selectedEntityPath">
        {{entity}}
      </span>
    </div>
    <button type="button" class="close mc-new-user-close-btn" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div><!--Modal header -->

  <!--Modal body section-->
  <div class="modal-body">
    <lib-mc-user-roles-data
      [userRoles]="userRoles"
      [lastChildEntity]="lastChildEntity"
      (refreshUserDataEvent)="onSuccessEditPersonalData($event)"></lib-mc-user-roles-data>
  </div>
  <!--Modal body section#-->
</ng-template>

<mcc-modal1-wrapper *ngIf="resendUserMailMcb.value" [showMcb]="resendUserMailMcb" modalSizeCd="small" (eventCanceled)="resendUserMailMcb.setFalse()">
  <mc-user-user-resend-email [user]="userPersonalData" (cancelEvent)="resendUserMailMcb.setFalse()" (onResendEmailEvent)="onResendEmail($event)"></mc-user-user-resend-email>
</mcc-modal1-wrapper>



<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
