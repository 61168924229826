import {McConsumerHistoryFilterGENERATED} from '../_generated/mc-consumer-history-filter-generated.model';

export class McConsumerHistoryFilter extends McConsumerHistoryFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McConsumerHistoryFilter();
  }

  // ---------------------------------------------------------------------
}
