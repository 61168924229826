import {CsrConsumerTable3ConfigGENERATED} from "../_generated/csr-consumer-table-3-config-generated.model";

export class CsrConsumerListPageTable3ConfigModel extends CsrConsumerTable3ConfigGENERATED {

  constructor() {
    super();

    this.clearColumns();

    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('name', this.t('cc.consumers.view.consumer-name'), 'fldNameIndividualOrCompany()', 'firstName');
    this.addColumn('colConsumerId', this.t('cc.consumer.view.consumer-id'), 'id', 'id');
    this.addColumn('contractNumber', this.t('cc.common.contract-number'), 'contractNumber', 'contractNumber');
    this.addColumn('email', this.t('cc.common.edit.email'), 'email', 'email');
    // this.addColumn('gender', 'Gender', 'gender', 'gender');
    this.addColumn('address', this.t('cc.common.edit.address'), 'fldAddress()', 'city');
    this.addColumn('companyName', this.t('cc.consumers.edit.company'), 'companyName', 'companyName');
    this.addColumn('type', this.t('cc.jobs.edit.type'), 'getTypeCdLabel()', 'typeCd');
    this.addColumn('statusCd', this.t('cc.common.view.status'), 'getStatusCdLabel()', 'statusCd');
    // this.addColumn('colBlacklisted', 'Blacklist status', 'fldBlacklisted()', '');


  }
}
