export const ICON_UPLOAD = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin-top: 3px;" width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <rect id="rect-1" width="24" height="24" x="0" y="0"/>
    <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="userSpaceOnUse">
      <rect width="24" height="24" x="0" y="0" fill="black"/>
      <use fill="white" xlink:href="#rect-1"/>
    </mask>
  </defs>
  <g opacity=".6">
    <use fill="none" xlink:href="#rect-1"/>
    <g mask="url(#mask-2)">
      <rect width="24" height="24" x="0" y="0" fill="none" rx="0" ry="0"/>
      <path fill="rgb(1,21,34)" fill-rule="evenodd" d="M8.98999023 10H7.40002441c-.89001464 0-1.33001709-1.08000183-.7000122-1.71000671l4.58996582-4.58999634c.39001465-.3899994 1.02001953-.3899994 1.41003418 0l4.58996582 4.58999634C17.9199829 8.91999817 17.47003173 10 16.58001709 10h-1.59002686v5c0 .5499878-.4500122 1-1 1h-4c-.54998779 0-1-.4500122-1-1v-5zM6 20c-.5499878 0-1-.4500122-1-1s.4500122-1 1-1h12c.5499878 0 1 .4500122 1 1s-.4500122 1-1 1H6z"/>
    </g>
  </g>
</svg>`
