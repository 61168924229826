import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {McForm, McValueLabelList} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-consumer-consumer-create-contract-contract-renewal-part',
  templateUrl: './mc-consumer-consumer-create-contract-contract-renewal-part.component.html',
  styleUrls: ['./mc-consumer-consumer-create-contract-contract-renewal-part.component.scss']
})
export class McConsumerConsumerCreateContractContractRenewalPartComponent implements OnInit, OnChanges {
  @Input() selectedTab!: number;
  @Input() mcForm!: McForm;
  @Input() infoMessage!: string;
  @Input() errorMessage!: string;
  @Input() disableAutomaticCheckboxInput!: boolean;
  disableRenewalDaysMonths!: boolean;

  automaticRenewalDaysVll = new McValueLabelList();
  automaticRenewalMonthsVll = new McValueLabelList();
  disableNoAutomaticCheckbox!: boolean;
  automaticRenewalDate: any;
  disableAutomaticCheckboxFlg!: boolean;


  ngOnInit() {
    this.automaticRenewalDaysVll.add([30], '30 days');
    this.automaticRenewalDaysVll.add([90], '90 days');
    this.automaticRenewalDaysVll.add([180], '180 days');

    this.automaticRenewalMonthsVll.add(3, '3 months');
    this.automaticRenewalMonthsVll.add(6, '6 months');
    this.automaticRenewalMonthsVll.add(9, '9 months');
    this.automaticRenewalMonthsVll.add(12, '12 months');
    this.automaticRenewalMonthsVll.add(24, '24 months');

    this.disableRenewalDaysMonths = true;
    this.disableAutomaticCheckboxFlg = this.disableAutomaticCheckboxInput;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.disableAutomaticCheckboxFlg = this.disableAutomaticCheckboxInput;
      if (this.mcForm.getControl('ctrlAutomaticRenewal')) {
        this.mcForm.getControl('ctrlAutomaticRenewal')?.get('value')?.setValue(false);
      }
      if (this.mcForm.getControl('ctrlNoAutomaticRenewal')) {
        this.mcForm.getControl('ctrlNoAutomaticRenewal')?.get('value')?.setValue(false);
      }
      if (this.selectedTab === 4 && this.disableAutomaticCheckboxFlg) {
        this.mcForm.getControl('ctrlNoAutomaticRenewal')?.get('value')?.setValue(true);
        this.disableNoAutomaticCheckbox = true;
      }
      if (this.mcForm.getControl('contractType')?.value.value === 'UNLIMITED') {
        this.mcForm.getControl('ctrlNoAutomaticRenewal')?.get('value')?.setValue(true);
        this.disableNoAutomaticCheckbox = true;
      }
    } else {
      this.mcForm.getControl('ctrlNoAutomaticRenewal')?.get('value')?.setValue(false);
      this.disableNoAutomaticCheckbox = false;
    }
  }

  onAutomaticRenewal(value: any) {
    if (this.selectedTab === 4) {
      this.disableAutomaticCheckboxFlg = false;
      if (value) {
        this.disableNoAutomaticCheckbox = true;
        this.disableRenewalDaysMonths = false;
      } else {
        this.disableNoAutomaticCheckbox = false;
        this.disableRenewalDaysMonths = true;
      }
    }
  }

  onNoAutomaticRenewal(value: any) {
    if (this.selectedTab === 4) {
      this.disableNoAutomaticCheckbox = false;
      if (value) {
        this.disableAutomaticCheckboxFlg = true;
      } else {
        this.disableAutomaticCheckboxFlg = this.disableAutomaticCheckboxInput;
      }
    }
  }

  onAutomaticRenewalDaysValueChanged(value: any) {
    const contractEndDate = this.mcForm.getValue('ctrlContractEndDate');
    const endDate = new Date(contractEndDate);
    endDate.setDate( endDate.getDate() - value );
    this.automaticRenewalDate = endDate;
  }
}
