import {EbSepaImport} from './eb-sepa-import.model';
import {EbSepaImportListGENERATED} from '../_generated/eb-sepa-import-list-generated.model';

export class EbSepaImportList extends EbSepaImportListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaImportList();
  }

  // ---------------------------------------------------------------------
}
