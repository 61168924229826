import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EbFactoring, McForm} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-factoring-reject-contract-part',
  templateUrl: './app-shared-factoring-reject-contract-part.component.html',
})
export class AppSharedFactoringRejectContractPartComponent implements OnInit {

  mcForm = new McForm();
  @Input() ebFactoring!: EbFactoring;
  @Output() eventCancel = new EventEmitter();
  @Output() eventRejected = new EventEmitter();

  ngOnInit() {
  }

  isVisible(): boolean {
    return (this.ebFactoring != null);
  }

  actCancel() {
    this.eventCancel.emit();
    this.mcForm.getControl('txbRejectReason')?.get('value')?.setValue('');
  }

  actSaveItem() {
    if (this.mcForm.isValid()) {
      this.ebFactoring.contractRejectionReason = this.mcForm.getValue('txbRejectReason');
      this.ebFactoring.statusCd = EbFactoring.STATUS_CD_REJECTED;
      this.ebFactoring.save( () => {
        this.mcForm.getControl('txbRejectReason')?.get('value')?.setValue('');
        this.eventRejected.emit();
      });
    }
  }
}
