/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {PmAdjustmentTable3ConfigGENERATED} from '../_generated/pm-adjustment-table-3-config-generated.model';

// import {PmAdjustmentGENERATED} from '../_generated/pm-adjustment-generated.model';

export class PmAdjustmentTable3Config extends PmAdjustmentTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colTypeCd', this.t('cc.common.type-code'), 'typeCd', 'typeCd');
    this.addColumn('colSysCreatedDatetime', this.t('cc.common.created-date-and-time') , 'getCreatedDate()', 'sysUpdatedDatetime');
    this.addColumn('colDescription', this.t('cc.common.view.description'), 'description', 'description');
    this.addColumn('colAmount', this.t('cc.factoring.transactions.amount'), 'getAmountStr()', 'amount');
    this.addColumn('colPaymentMethodCd', this.t('cc.my-factoring.payment-method'), 'getPaymentMethodCdLabel()', 'paymentMethodCd');
    this.addColumn('colStatusCd', this.t('cc.common.status'), 'getStatusLabel()', 'statusCd');
    this.addColumn('colIdBeneficiaryEntity', this.t('cc.consumer.beneficiary-entity'), 'getBeneficiaryEntityName()', 'idBeneficiaryEntity');
    this.addColumn('colSysCreatedUserId', this.t('cc.consumers.imports.created-by'), 'createdByName', 'createdByName');
    }
}
