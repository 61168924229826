<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcBillingPayoutFileFeCtd.apiLoadingFlg || loadingFlg"></lib-mc-loader>

  <div class="row mx-0">
    <div class="mc-go-back-to-all" (click)="goToPayoutFileListPage()" *ngIf="mcGod.userHasMcBillingOutPayoutFilesViewPermission()">
      <
      {{'cc.payout-file.back-to-payout-files' | translate}}
    </div>
  </div>

  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header d-flex">
    <h3>{{mcBillingPayoutFileFeCtd.fileName}}</h3>

    <div ngbDropdown class="d-inline-block ml-auto">
      <button class="btn-outline-new" id="dropdownBasic1" ngbDropdownToggle>Actions</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem [ngClass]="{'mc-disable' : mcBillingPayoutFileFeCtd.isStatusCdFAILED()}" (click)="onActionsDropdownClick(globalActions.Download)" *ngIf="mcGod.userHasMcBillingOutPayoutFilesDownloadPermission()">
          <i class="fas fa-download"></i> {{'cc.common.download-file' | translate}}
        </button>
        <button ngbDropdownItem (click)="onActionsDropdownClick(globalActions.Delete)" *ngIf="mcGod.userHasMcBillingOutPayoutFilesDeletePermission()">
          <i class="far fa-trash-alt"></i> {{'cc.common.delete-file' | translate}}
        </button>
      </div>
    </div>

  </div>


  <div class="content-body">
    <mcc-message [errorMsg]="errorMsg"></mcc-message>

    <div *ngIf="!loadingFlg" class="row">
      <div class="col-md-12">
        <div class="content-section">

          <div class="row info-container2 p-3">
            <div class="col-md-6">
              <mcc-row-title-description title="Created date and time:" [description]="createdDAteTimeString"></mcc-row-title-description>
              <mcc-row-title-description title="{{'cc.consumers.imports.created-by' | translate}}:" [description]="mcBillingPayoutFileFeCtd.createdBy"></mcc-row-title-description>
              <mcc-row-title-description title="{{'cc.statement.number-of-statements' | translate}}:" [description]="mcBillingPayoutFileFeCtd.numberOfStatements"></mcc-row-title-description>
            </div>
            <div class="col-md-6">
              <mcc-row-title-description title="{{'cc.common.payout-date' | translate}}:" [description]="payoutDateString"></mcc-row-title-description>
              <mcc-row-title-description title="{{'cc.common.total-file-amount' | translate}}:" [description]="mcBillingPayoutFileFeCtd.fldTotalFileAmount()"></mcc-row-title-description>
              <mcc-row-title-description title="{{'cc.common.view.status' | translate}}:" [description]="mcBillingPayoutFileFeCtd.getStatusCdLabel()"></mcc-row-title-description>
            </div>
          </div>
        </div>

      </div>
    </div><!-- row -->

    <!--<div class="row">
            <div class="col-md-12">
              <div class="mc-filter fancy-label">
                <div class="multi-select-field">
                  <mcc-multiselect-filter [optionVll]="statusCdVll" [clearedAllFilters]="clearedAllMultipleFilters"
                                          label="{{'cc.common.view.status' | translate}}" name="selStatus"
                                          (eventSelectedItems)="onSelectStatusCds($event)">
                  </mcc-multiselect-filter>
                </div>

                <div class="multi-select-field">
                  <mcc-multiselect-filter [optionVll]="statementsCdsVll" [clearedAllFilters]="clearedAllMultipleFilters"
                                          label="Statement" name="selStatement"
                                          (eventSelectedItems)="onSelectStatementCds($event)">
                  </mcc-multiselect-filter>
                </div>

                &lt;!&ndash;<mcc-fi-textbox label="{{'cc.common.search' | translate}}" name="txbSearchTerm" (eventEnterClicked)="actLoad()"
                                [mcForm]="mcForm"></mcc-fi-textbox>&ndash;&gt;

                <button class="mc-link mc-filter-clear-button filter-clear-top-right"
                        (click)="actClearFilter()">{{'cc.common.filter.clear-all' | translate}}</button>
                <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>

              </div>
            </div>
          </div>-->

    <div class="row">
      <div class="col-md-12">
        <mcc-fi-table-3 [objectList]="mcBillingPayoutFileItemFeCtdList"
                        [table3Config]="mcBillingPayoutFileItemTable3Config"></mcc-fi-table-3>
      </div>
    </div>

    <mcc-modal1-wrapper modalSizeCd="small" [heightAutoFlg]="true" [showMcb]="showDeleteMcb" *ngIf="showDeleteMcb.value" (eventCanceled)="onDeletePayoutFileActionButtonClicked(globalActions.Cancel)">
      <div class="content-wrapper">
        <div class="content-header">
          <h3>{{'cc.payout.delete-payout-file' | translate}}</h3>
        </div>
        <div class="content-body">
          <div class="d-flex align-items-center">
            <h1 class="text-orange-color m-0 pr-3"><i class="far fa-question-circle"></i></h1>
            <span [innerHTML]="dialogDescription"></span>
          </div>
        </div>
        <div class="content-footer d-flex">
          <button class="btn btn-secondary ml-auto" (click)="onDeletePayoutFileActionButtonClicked(globalActions.Cancel)">{{'cc.common.edit.cancel' | translate}}</button>
          <button class="btn btn-primary" (click)="onDeletePayoutFileActionButtonClicked(globalActions.Delete)"><i class="far fa-trash-alt"></i> {{'cc.common.delete' | translate}}</button>
        </div>
      </div>
    </mcc-modal1-wrapper>

  </div><!-- content-body -->

</div>
<router-outlet></router-outlet>
