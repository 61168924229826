export const ICON_CONSUMER360 = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_123_1497)">
<path d="M20 7H15V4C15 2.9 14.1 2 13 2H11C9.9 2 9 2.9 9 4V7H4C2.9 7 2 7.9 2 9V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V9C22 7.9 21.1 7 20 7ZM9 12C9.83 12 10.5 12.67 10.5 13.5C10.5 14.33 9.83 15 9 15C8.17 15 7.5 14.33 7.5 13.5C7.5 12.67 8.17 12 9 12ZM12 18H6V17.57C6 16.97 6.36 16.42 6.92 16.18C7.56 15.9 8.26 15.75 9 15.75C9.74 15.75 10.44 15.9 11.08 16.18C11.63 16.42 12 16.96 12 17.57V18ZM13 9H11V4H13V9ZM17.25 16.5H14.75C14.34 16.5 14 16.16 14 15.75C14 15.34 14.34 15 14.75 15H17.25C17.66 15 18 15.34 18 15.75C18 16.16 17.66 16.5 17.25 16.5ZM17.25 13.5H14.75C14.34 13.5 14 13.16 14 12.75C14 12.34 14.34 12 14.75 12H17.25C17.66 12 18 12.34 18 12.75C18 13.16 17.66 13.5 17.25 13.5Z" fill="#3B457C"/>
</g>
<defs>
<clipPath id="clip0_123_1497">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
`
