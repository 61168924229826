import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {
  EbFactoring,
  EbFactoringList, EbFactoringService,
  EbSepaFile, EbSepaFileReport, EbSepaFileTransactionService, IEbFactoring,
  EbSepaImportService,
  McConsumersService,
  McDateUtils, McForm, McGod,
  McUtilityService,
  WebFile
} from '@miticon-ui/mc-core';
import {FormBuilder, FormGroup} from '@angular/forms';
import _moment from 'moment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {take} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'mc-my-factoring-import-sepa-file',
  templateUrl: './mc-my-factoring-import-sepa-file.component.html',
})
export class McMyFactoringImportSepaFileComponent implements OnInit {

  webFile!: WebFile;
  file!: File;
  importSepaFileFormGroup!: FormGroup;
  attributesInfoIcon: any;
  attributesIcons: any;
  datetoSend = '2019-10-01T12:30:41.830Z';
  factoringList!: EbFactoringList;
  activeFactoring!: IEbFactoring;
  message!: string;
  type!: string;
  ebSepaFile!: EbSepaFile;
  mcGod = McGod.getInstance();
  @Output() refreshTable = new EventEmitter();
  mcForm = new McForm();
  today = McDateUtils.getNowTimestamp();
  radioButtonsVlm = new Map<string, string>();
  disableInputValue = true;
  datePickerRequiredFlg = false;
  ebSepaFileReport = new EbSepaFileReport();
  ebFactoring = new EbFactoring();
  maxDateInput!: number;
  minDateInput!: number;
  showLoaderFlg = false;

  nextMonthLabel!: string;
  lastMonthLabel!: string;
  currentMonthLabel!: string;
  twoMonthsAgoLabel!: string;
  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter();

  constructor(private modalService: NgbModal,
              private consumersService: McConsumersService,
              private utilityService: McUtilityService,
              private factoringService: EbFactoringService,
              private formBuilder: FormBuilder,
              private transactionService: EbSepaFileTransactionService,
              private toastr: ToastrService,
              private importSepaService: EbSepaImportService,
              @Inject('designConfig') design: any) {
    this.attributesIcons = design.attributesIcons;
    this.attributesInfoIcon = this.attributesIcons.info.path;
  }

  ngOnInit() {
    this.ebFactoring.loadActiveForChildEntity(McGod.getLoggedEntityIdFromToken(), () => {
      const monthsInFuture = this.ebFactoring.monthsAllowedFutureCount;
      const monthsInPast = this.ebFactoring.monthsAllowedPastCount;
      const tempDate = McDateUtils.addOrSubtractDateToString(McDateUtils.getNowTimestamp(), 'add', monthsInFuture, '', 'months' );
      const pastDate = McDateUtils.addOrSubtractDateToString(McDateUtils.getNowTimestamp(), 'subtract', monthsInPast, '', 'months' );
      this.maxDateInput = McDateUtils.formatStringToTimestamp(tempDate);
      this.minDateInput = McDateUtils.formatStringToTimestamp(pastDate);

    });

    this.radioButtonsVlm.set('useCollectionDateFromSEPA', 'Use request collection date from SEPA file');
    this.radioButtonsVlm.set('chooseCollectionDate', 'Choose request collection date');
    this.factoringList = new EbFactoringList();
    const entityId = this.utilityService.getPropertyFromToken('entity_id');
    this.activeFactoring = this.factoringService.activeChildFactoring;
    if (this.activeFactoring) {
      this.message = '';
      this.type = '';
    } else {
      this.message = this.mcGod.t('cc.my-factoring.you-cant-import-sepa-file-since-there-is-no-active-factoring');
      this.type = 'error';
    }

    this.webFile = new WebFile();

    this.importSepaFileFormGroup = this.formBuilder.group({
      monthOfPerformance: ['nextMonth'],
      overWriteCB: [false]
    });
    this.actLoad();
  }

  changeSendDate() {
    if (this.importSepaFileFormGroup.get('monthOfPerformance')?.value === 'currentMonth') {
      this.datetoSend = _moment().format().substring(0, 8) + this.datetoSend.substring(8);
    } else if (this.importSepaFileFormGroup.get('monthOfPerformance')?.value === 'lastMonth') {
      this.datetoSend = _moment().subtract(1, 'months').format().substring(0, 8) + this.datetoSend.substring(8);
    } else if (this.importSepaFileFormGroup.get('monthOfPerformance')?.value === 'twoMonthsAgo') {
      this.datetoSend = _moment().subtract(2, 'months').format().substring(0, 8) + this.datetoSend.substring(8);
    } else if (this.importSepaFileFormGroup.get('monthOfPerformance')?.value === 'nextMonth') {
      this.datetoSend = _moment().add(1, 'months').format().substring(0, 8) + this.datetoSend.substring(8);
    }
  }

  actLoad() {
    this.currentMonthLabel = McDateUtils.formatDateToString(McDateUtils.getNowTimestamp(), 'MMMM, YYYY');
    this.nextMonthLabel = McDateUtils.addOrSubtractDateToString(McDateUtils.getNowTimestamp(), 'add', 1, 'MMMM, YYYY', 'months');
    this.lastMonthLabel = McDateUtils.addOrSubtractDateToString(McDateUtils.getNowTimestamp(), 'subtract', 1, 'MMMM, YYYY', 'months');
    this.twoMonthsAgoLabel = McDateUtils.addOrSubtractDateToString(McDateUtils.getNowTimestamp(), 'subtract', 2, 'MMMM, YYYY', 'months');
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actUpload() {
    if (this.mcForm.isValid()) {
      const relativePath = 'import-sepa';
      if (this.file != null) {
        const dataBody = {
          file: this.file,
          aRelativePath: `E${McGod.getLoggedEntityIdFromToken()}/${relativePath}`
        };
        this.changeSendDate();
        this.showLoaderFlg = true;
        this.webFile.upload(dataBody, WebFile.TYPE_CD_EB_BANK_SEPA_IMPORT, () => {
          const idWebFile = this.webFile.id;
          const overWriteCB = this.importSepaFileFormGroup.get('overWriteCB')?.value;

          this.ebSepaFile = new EbSepaFile();
          this.ebSepaFile.idWebfile = idWebFile;
          this.ebSepaFile.idMcEntity = this.utilityService.getPropertyFromToken('entity_id');
          if (this.mcForm.getValue('useCollectionDateFromSepa') === 'chooseCollectionDate') {
            this.ebSepaFile.requestedCollectionDate = this.mcForm.getValue('requestCollectionDate');
          }
          // this.ebSepaFile.overWriteCB = overWriteCB;
          console.log(idWebFile, this.ebSepaFile);
          this.importSepaService.importFile(overWriteCB, this.ebSepaFile).pipe(take(1)).subscribe((res) => {
            this.importSepaFileFormGroup.get('monthOfPerformance')?.setValue('nextMonth');
            this.importSepaFileFormGroup.get('overWriteCB')?.setValue(false);
            this.ebSepaFileReport.callGeneratePreReportForSepaFile(this.ebSepaFile.id);
            this.message = '';
            this.type = '';
            this.showLoaderFlg = false;
            this.eventSaved.emit();
            this.toastr.success(`${McGod.t('cc.factoring.sepa-successfully-imported')}`, '', {timeOut: 4000, extendedTimeOut: 10});
          }, error => {
            this.toastr.error(`${McGod.t('cc.my-factoring.an-error-has-occured-while-importing-sepa-file')}`, '', {timeOut: 4000, extendedTimeOut: 10});
            this.showLoaderFlg = false;
          });
        }, () => {
          this.toastr.error(`${McGod.t('cc.my-factoring.an-error-has-occured-while-uploading-sepa-file')}`, '', {timeOut: 4000, extendedTimeOut: 10});
          this.showLoaderFlg = false;
        });
      } else {
        this.message = this.mcGod.t('cc.my-factoring.please-import-sepa-file-for-upload');
        this.type = 'error';
      }
    }
  }

  receiveFile(file: File) {
    this.file = file;
  }

  onRadioValueChanged(value: string) {
    console.log(value);
    if (value === 'chooseCollectionDate') {
      this.datePickerRequiredFlg = true;
      this.disableInputValue = false;
    } else {
      this.datePickerRequiredFlg = false;
      this.disableInputValue = true;
    }
  }
}
