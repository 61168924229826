export const ICON_HEADER_DROPDOWN = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="10" fill="#D41769"/>
<g clip-path="url(#clip0_123_1159)">
<path d="M7.25829 9.75837L9.41663 11.9167C9.74163 12.2417 10.2666 12.2417 10.5916 11.9167L12.75 9.75837C13.275 9.23337 12.9 8.33337 12.1583 8.33337H7.84163C7.09996 8.33337 6.73329 9.23337 7.25829 9.75837Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_123_1159">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
`
