/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CsrMappingItemFilter} from '../models/csr-mapping-item-filter.model';

export class CsrMappingItemFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrMappingItemFilter {
    console.error('MTCN-ERROR: Not Implemented: CsrMappingItemFilter::createNewInstance(). Add this method to final class and return new CsrMappingItemFilter();');
      throw new Error('Cannot CsrMappingItemFilter::createNewInstance(). Add this method to final class and return new CsrMappingItemFilter();');
  }

}
