<ng-container>
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-wrapper">
    <!--    <lib-mc-loader [showLoader]="webFile.apiLoadingFlg"></lib-mc-loader>-->

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-header">
      <h4>{{'cc.contract.select-contract-type' | translate}}</h4>
    </div>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-body">

      <mcc-fi-radio-buttons [mcForm]="mcForm" [optionsVlm]="optionsVlm" [name]="'contractType'"
                            [directionHorizontal]="true" (eventValueChanged)="contractTypeValueChanged($event)"></mcc-fi-radio-buttons>

    </div><!-- modal-body -->

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-footer">

      <button class="btn btn-secondary" (click)="actPrev()">
        <i class="fas fa-arrow-left"></i> {{'cc.common.view.prev' | translate}}
      </button>
      <button class="btn btn-primary mr-3 move-right" [disabled]="nextDisableFlg" (click)="actNext()">
        {{'cc.common.view.next' | translate}} <i class="fas fa-arrow-right"></i>
      </button>

    </div>
  </div>

  <router-outlet></router-outlet>
</ng-container>
