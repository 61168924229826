import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  McPriceList, McPriceListList,
  McPricelistPricelistCreatedFromTemplateTable3ConfigModel
} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-pricelist-template-pricelists-created-from-template-part',
  templateUrl: './mc-pricelist-template-pricelists-created-from-template-part.component.html',
})
export class McPricelistTemplatePricelistsCreatedFromTemplatePartComponent implements OnInit {

  /*Variables*/
  @Input() mcPriceList!: McPriceList;

  @Output() eventCanceled = new EventEmitter();

  mcPricelistsCreatedFromTableConfig = new McPricelistPricelistCreatedFromTemplateTable3ConfigModel();
  mcPriceListList = new McPriceListList();
  infoMsg!: string;


  ngOnInit() {
    this.mcPriceListList.loadAllPriceListInstantiatedFromTemplate(this.mcPriceList.id, () => {
      if (this.mcPriceListList.getCount() === 0) {
        this.infoMsg = 'There are no pricelists created from this template yet';
      }
    });
  }

  actClose() {
    this.eventCanceled.emit();
  }
}
