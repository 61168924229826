<ng-container *ngIf="mcEntity2List.apiSuccessFlg">
  <div class="content-wrapper">
    <div class="content-header">
      <h3>{{'cc.out-factoring.select-entity-for-factoring' | translate}}</h3>
    </div>
    <div class="col-md-12 position-relative mt-1">
      <div class="mc-create-user-notify-wrapper">
        <mc-notify-message [message]=notifyMessage.message [type]="notifyMessage.type"></mc-notify-message>
      </div>
    </div>
    <div class="content-body">
      <mcc-fi-table-3 [objectList]="mcEntity2List"
                      (eventSelectionChanged)="receiveStateOnSelect($event)"
                      [table3Config]="mcEntity2Table3Config"
                      (eventItemAction)="actEntity2TableItemAction($event)"></mcc-fi-table-3>
      <mcc-modal1-wrapper [showMcb]="showEnableDisableFactoringModal" modalSizeCd="small"
                          *ngIf="showEnableDisableFactoringModal.value">
        <app-shared-factoring-enable-disable-part [mcEntity2Id]="mcEntity2Id"
                                                  (eventSaved)="onEnableDisableSaved()"
                                                  (eventCanceled)="onEnableDisableCanceled()"></app-shared-factoring-enable-disable-part>
      </mcc-modal1-wrapper>
    </div>
    <div class="content-footer">
      <button class="btn btn-primary move-right" [disabled]="!enableNext"
              (click)="goToNext()">{{'cc.common.view.next' | translate}} <i class="fas fa-arrow-right"></i></button>
    </div>
  </div>

</ng-container>
