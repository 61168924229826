/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EbSepaImportFilter} from '../models/eb-sepa-import-filter.model';

export class EbSepaImportFilterGENERATED extends BaseObjectFilter{    public searchTerm: string | undefined;
    public childEntityIds: number[] = [];
    public statusCds: string[] = [];
    public outFactoringFlg: boolean | undefined;

  public properties: string[] = ['searchTerm', 'childEntityIds', 'statusCds', 'outFactoringFlg', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbSepaImportFilter {
    console.error('MTCN-ERROR: Not Implemented: EbSepaImportFilter::createNewInstance(). Add this method to final class and return new EbSepaImportFilter();');
      throw new Error('Cannot EbSepaImportFilter::createNewInstance(). Add this method to final class and return new EbSepaImportFilter();');
  }

}
