/*BbmTestObj*/
import {McBillingStatementGENERATED} from '../_generated/mc-billing-statement-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {McDateUtils} from '../common/mc-date-utils';
import {ActionOptions} from '../_core/mc-html-action-options';
import {CurrencyUtil} from '../common/currency-util';


export class McBillingStatement extends McBillingStatementGENERATED {

  public static ACTION_SHOW_PROFILE = 'PROFILE';
  public static ACTION_CHANGE_STATUS = 'CHANGE-STATUS';
  public static ACTION_RENAME_STATEMENT = 'RENAME-STATEMENT';
  public static ACTION_AUTO_IMPORT = 'AUTO-IMPORT';
  public static ACTION_FINALIZE = 'FINALIZE';
  public static ACTION_CANCEL = 'CANCEL';
  public static ACTION_CREATE_PAYOUT_FILE = 'CREATE-PAYOUT-FILE';
  public static ACTION_RECALCULATE = 'RECALCULATE';
  public static ACTION_PREVIEW_PDF = 'PREVIEW-PDF';
  public static ACTION_DOWNLOAD_PDF = 'DOWNLOAD-PDF';
  public static ACTION_PREVIEW_XLS = 'PREVIEW-XLS';
  public static ACTION_DOWNLOAD_XLS = 'DOWNLOAD-XLS';
  public static ACTION_HIDE = 'HIDE-STATEMENT';
  public static ACTION_UNHIDE = 'UNHIDE-STATEMENT';
  public static FILTER_ENTITES = 'cc.common.view.entity';
  public static FILTER_STATUS = 'cc.common.view.status';
  public static FILTER_VISIBILITY = 'cc.statement.visibility';
  public static FILTER_EXPORTED = 'cc.eb-sepa-export.exported';

  public static override readonly STATUS_CD_NEW: string = 'NEW';
  public static override readonly STATUS_CD_PROCESSING: string = 'PROCESSING';
  public static override readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
  public static override readonly STATUS_CD_SUBMITTED: string = 'SUBMITTED';
  public static override readonly STATUS_CD_COMPLETED: string = 'COMPLETED';
  private _fldActions: McHtml | null = null;
  totalPayoutFormatted!: string;




  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingStatement();
  }


  // ---------------------------------------------------------------------

  fldActions(): string | McHtml {
    if (!this.isStatusCdCANCELED() && !this.isStatusCdIN_PROGRESS_AUTO_IMPORT() && !this.isStatusCdPENDING_AUTO_IMPORT() && !this.isStatusCdIN_PROGRESS_FINALIZATION() && !this.isStatusCdPENDING_FINALIZATION()) {
      if (!this._fldActions) {
        const html = new McHtml();
        const actSet = html.addActionSet();
        actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.my-factoring.view-details')}`, McBillingStatement.ACTION_SHOW_PROFILE, this, [McGod.PERM_MC_BILLING_OUT_STATEMENT_VIEW], '', ActionOptions.OPTION_VIEW);
        actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.billing.change-status')}`, McBillingStatement.ACTION_CHANGE_STATUS, this, [McGod.PERM_MC_BILLING_OUT_STATEMENT_CHANGE_STATUS], '', ActionOptions.OPTION_EDIT);
        actSet.addActionB(`<i class="fas mc-submit-color ${this.flgHidden ? 'fa-eye' : 'fa-eye-slash'} mc-cursor-pointer"></i> ${this.flgHidden ? 'Unhide' : 'Hide'}`, this.flgHidden ? McBillingStatement.ACTION_UNHIDE : McBillingStatement.ACTION_HIDE, this, [McGod.PERM_MC_BILLING_OUT_BILLING_HIDE_UNHIDE_STATEMENT], '', ActionOptions.OPTION_EDIT);
        if (!this.isStatusCdCOMPLETED()) {
          actSet.addActionB(`<i class="fas mc-submit-color fa-pencil-alt mc-cursor-pointer"></i> Rename statement`, McBillingStatement.ACTION_RENAME_STATEMENT, this, [McGod.PERM_MC_BILLING_OUT_STATEMENT_EDIT], '', ActionOptions.OPTION_EDIT);
          // actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.billing.recalculate')}`, McBillingStatement.ACTION_RECALCULATE, this, [McGod.PERM_MC_BILLING_OUT_STATEMENT_RECALCULATE]);
          actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.billing.auto-import-billing-item')}`, McBillingStatement.ACTION_AUTO_IMPORT, this, [McGod.PERM_MC_BILLING_OUT_STATEMENT_AUTO_IMPORT_BILLING_ITEMS], '', ActionOptions.OPTION_EDIT);
        }
        if (this.isStatusCdACTIVE()) {
          actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.billing.finalize-statement')}`, McBillingStatement.ACTION_FINALIZE, this, [McGod.PERM_MC_BILLING_OUT_STATEMENT_FINALIZE], '', ActionOptions.OPTION_EDIT);
        }
        if (this.isStatusCdACTIVE() || this.isStatusCdNEW()) {
          actSet.addActionB(`<i class="fas mc-submit-color fa-ban mc-cursor-pointer"></i> ${McGod.t('cc.billing.cancel-statement')}`, McBillingStatement.ACTION_CANCEL, this, [McGod.PERM_MC_BILLING_OUT_STATEMENT_EDIT], '', ActionOptions.OPTION_EDIT);
        }
        if (this.isStatusCdCOMPLETED() && !this.exportedDate) {
          actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.payout.create-payout-file')}`, McBillingStatement.ACTION_CREATE_PAYOUT_FILE, this, [McGod.PERM_MC_BILLING_OUT_PAYOUT_FILES_CREATE], '', ActionOptions.OPTION_EDIT);
        }
        this._fldActions = html;
      }
      return this._fldActions;
    }
    return '';
  }

  fldMyActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.my-factoring.view-details')}`, McBillingStatement.ACTION_SHOW_PROFILE, this, [McGod.PERM_MC_BILLING_MY_STATEMENT_VIEW], '', ActionOptions.OPTION_VIEW);
      actSet.addActionB(`<i class="fas mc-submit-color fa-search-plus mc-cursor-pointer"></i> ${McGod.t('cc.billing.preview-pdf')}`, McBillingStatement.ACTION_PREVIEW_PDF, this, [McGod.PERM_MC_BILLING_MY_STATEMENT_PREVIEW_PDF], '', ActionOptions.OPTION_VIEW);
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-pdf mc-cursor-pointer"></i> ${McGod.t('cc.billing.download-pdf')}`, McBillingStatement.ACTION_DOWNLOAD_PDF, this, [McGod.PERM_MC_BILLING_MY_STATEMENT_DOWNLOAD_PDF], '', ActionOptions.OPTION_VIEW);
      // actSet.addActionB(`<i class="fas mc-submit-color fa-search-plus mc-cursor-pointer"></i> ${McGod.t('cc.billing.preview-xls')}`, McBillingStatement.ACTION_PREVIEW_XLS, this, [McGod.PERM_MC_BILLING_MY_STATEMENT_PREVIEW_XLS]);
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-excel mc-cursor-pointer"></i> ${McGod.t('cc.billing.download-xls')}`, McBillingStatement.ACTION_DOWNLOAD_XLS, this, [McGod.PERM_MC_BILLING_MY_STATEMENT_DOWNLOAD_XLS], '', ActionOptions.OPTION_VIEW);
      this._fldActions = html;
    }
    return this._fldActions;
  }

  getTotalPayoutLoc(): string {
    return CurrencyUtil.format(this.totalPayout, this.currencyCode);
  }

  getStatementDatetimeString(): string {
    if (this.statementDate) {
      return McDateUtils.newFormatDateTimeString(this.statementDate);
    }
    return '';
  }

  getStatementDate(): string {
    if (this.statementDate) {
      return McDateUtils.newFormatDateString(this.statementDate);
    }
    return '';
  }

  fldNumberOfItems() {
    return this.mcBillingStatementItemsCount > 0 ? this.mcBillingStatementItemsCount : '0';
  }

  fldAmount() {
    return McGod.formatNumberToMoney(this.totalPayout);
  }

  // Get icon for exported/not exported
  getExportedIcon() {
    if (!(this.isStatusCdCOMPLETED() || this.isStatusCdFINALIZED())) {
      return '';
    }
    if (this.isStatusCdCOMPLETED()) {
      const exportedMessage = `Exported.`;
      return `<div class="icon-tooltip ml-3">
                    <img style="height: 19px;" src="assets/images/exported.svg">
                    <span class="icon-tooltip-text">${exportedMessage}</span></div>`;
    } else {
      return `<div class="icon-tooltip ml-3">
                    <img style="height: 19px;" src="assets/images/not-exported.svg">
                    <span class="icon-tooltip-text">Not exported yet</span></div>`;
    }

  }

  fldStatusWithIconsOutPage() {
    const icon = this.flgHidden ? ` <i class="fas fa-eye-slash mc-blue-text"></i>` : '';
    return this.getStatusCdLabel() + icon + this.getExportedIcon();
  }

  fldStatusWithIconsMyPage() {
    const icon = this.flgHidden ? ` <i class="fas fa-eye-slash mc-blue-text"></i>` : '';
    return this.getStatusCdLabel() + icon;
  }



}
