import {BkFinanceReport} from './bk-finance-report.model';
import {BkFinanceReportListGENERATED} from '../_generated/bk-finance-report-list-generated.model';

export class BkFinanceReportList extends BkFinanceReportListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new BkFinanceReportList();
  }

  // ---------------------------------------------------------------------
}
