/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McProcess} from '../models/mc-process.model';
import {McProcessList} from '../models/mc-process-list.model';
import {McProcessFilter} from '../models/mc-process-filter.model';
import {McProcessService} from '../services/mc-process.service';
import {IMcProcess} from '../_generated/mc-process.interface';
import {McGod} from "../extra/mc-god.service";

export class McProcessListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'description', 'idMcEntity', 'inheritableFlg', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'title', 'typeCd', 'activeMcProcessFlowCount', 'activeMcProcessItemCount', 'lastRunDateTime', 'totalMcProcessFlowCount'];
  public items: McProcess[] = [];
  public apiService: McProcessService;
 // protected tempObjList: McProcessList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProcessList {
    console.error('MTCN-ERROR: Not Implemented: McProcessList::createNewInstance(). Add this method to final class and return new McProcessList();');
    throw new Error('Cannot McProcessList::createNewInstance(). Add this method to final class and return new McProcessList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcProcess[]): McProcessList {
    const  listObj: McProcessList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McProcess = McProcess.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McProcessList {
    const  listObj: McProcessList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcProcess[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McProcess = McProcess.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcProcessFilter: McProcessFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcProcessFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McProcess {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McProcess();
    }
  }

  public replaceItem(newItem: McProcess) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadByIdMcEntity( idMcEntity: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByIdMcEntity(idMcEntity, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

