/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McProcessFlowCriteriaAttributeFilter} from '../models/mc-process-flow-criteria-attribute-filter.model';

export class McProcessFlowCriteriaAttributeFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProcessFlowCriteriaAttributeFilter {
    console.error('MTCN-ERROR: Not Implemented: McProcessFlowCriteriaAttributeFilter::createNewInstance(). Add this method to final class and return new McProcessFlowCriteriaAttributeFilter();');
      throw new Error('Cannot McProcessFlowCriteriaAttributeFilter::createNewInstance(). Add this method to final class and return new McProcessFlowCriteriaAttributeFilter();');
  }

}
