import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {DiscountBaseConfig} from '../mc-table/discount-base-config';
import {McTableService} from '../mc-table/mc-table.service';


@Component({
  selector: 'mc-price-adjustment',
  templateUrl: `./mc-price-adjustment.component.html`,
})
export class McPriceAdjustmentComponent {

  public showDropDown = false;

  /*Events*/
  @Output() onAdjustPrice = new EventEmitter<any>();
  @Output() onValidatingDiscount = new EventEmitter<any>();
  @Input() discount!: DiscountBaseConfig;
  @Input() totalAmountValue!: number;
  @Input() additional = false;
  @Input() isEnabled = false;

  /*Constructor*/
  constructor(private tableService: McTableService) {}

  /*Open/close drop down*/
  public onOpenCloseDropDown(): void {
    this.showDropDown = !this.showDropDown;
  }

  /*On change*/
  public onAmountPriceChange(event: any) {
    isNaN(event) && this.discountException(event.target.value, event.target.valueAsNumber);
    this.onValidatingDiscount.emit(!!this.discountValidation());

    /*TODO: create dynamically adding type of discount to DB and return to FE - include validation*/
    /*just like a flag*/
    this.onAdjustPrice.emit({
      useAdditionalDiscount: this.additional
    });
  }

  /*On clear amount price*/
  public onClearAmountPrice() {
    this.discount.value = 0;
    this.discount.amount = 0;
    this.discount.amountPerOneQuantity = 0;
    this.discount.valueTypeCd = 'PERCENTAGE';
    this.discount.typeCd = 'DISCOUNT';
    this.discount.excludeDiscountedItems = true;
    this.discount.isValid = true;
    this.onAdjustPrice.emit({discount: this.discount, useAdditionalDiscount: this.additional});
  }

  public discountValidation() {
    let validate = '';
    if (this.discount.valueTypeCd === 'PERCENTAGE' && this.discount.value > 100) {
      validate = '0% - 100%';
    }

    if (this.discount.valueTypeCd !== 'PERCENTAGE' && this.discount.value > this.totalAmountValue) {
      validate = `0€ - ${Number(this.totalAmountValue)}€`;
    }

    this.discount.isValid = !validate;
    return validate;
  }

  /*Discount exception*/
  public discountException(eventTargetValue: any, eventValueAsNumber: any) {
    const replacedNumber = eventTargetValue.match(/\-/g) ? eventTargetValue.replace(/\-/g, '') : eventTargetValue.match(/\+/g) ?  eventTargetValue.replace(/\+/g, '') : eventTargetValue.replace(/\e/g, '');
    this.discount.value = isNaN(eventValueAsNumber) ? 0 : Number(replacedNumber || eventTargetValue || 0);
    this.discount.value = !String(this.discount.value).match(/^\d+(?:\.\d{1,2})?$/g) ? this.tableService.toFixedTrunc(this.discount.value, 2) : this.discount.value;
  }

  /*On exclude already discounted items*/
  public onExcludeAlreadyDiscountedItems() {
    this.additional && this.onAmountPriceChange(this.discount.value);
  }

  /*Clear button CSS style*/
  public clearButtonCssStyle() {
    return {
      right: this.additional ? '145px' : '113px',
      top: !this.additional ? '-20px' : '0'
    };
  }

}
