/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EbAccountConsumerTable3ConfigGENERATED} from '../_generated/eb-account-consumer-table-3-config-generated.model';

// import {EbAccountConsumerGENERATED} from '../_generated/eb-account-consumer-generated.model';

export class EbAccountConsumerTable3Config extends EbAccountConsumerTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colAmount', 'Amount', 'amount', 'amount');
    this.addColumn('colCreatedDatetime', 'Created DateTime', 'createdDatetime', 'createdDatetime');
    this.addColumn('colIdMcConsumer', 'Mc Consumer', 'idMcConsumer', 'idMcConsumer');
    this.addColumn('colIdMcConsumer', 'Consumer ID', 'idMcConsumer', 'idMcConsumer');
    this.addColumn('colJsonbTransactionDetails', 'Transaction details', 'jsonbTransactionDetails', 'jsonbTransactionDetails');
    this.addColumn('colPaymentMethod', 'Payment Method', 'paymentMethod', 'paymentMethod');
    this.addColumn('colType', 'Type', 'type', 'type');


  }
}
