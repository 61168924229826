/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CsrPaymentDetailsFilter} from '../models/csr-payment-details-filter.model';

export class CsrPaymentDetailsFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrPaymentDetailsFilter {
    console.error('MTCN-ERROR: Not Implemented: CsrPaymentDetailsFilter::createNewInstance(). Add this method to final class and return new CsrPaymentDetailsFilter();');
      throw new Error('Cannot CsrPaymentDetailsFilter::createNewInstance(). Add this method to final class and return new CsrPaymentDetailsFilter();');
  }

}
