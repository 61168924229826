<div class="content-wrapper">
  <div class="content-header">
    <h1>Create payout file</h1>
  </div>
  <div class="content-body">
    <mcc-fi-radio-buttons2 label="Create type"
                           name="ctrlCreateType"
                           [mcForm]="mcForm"
                           [validRequiredFlg]="true"
                           [directionHorizontal]="true"
                           (eventValueChanged)="onCreateTypeChanged($event)"
                           [optionsVll]="createTypeVll"></mcc-fi-radio-buttons2>
    <mcc-fi-select [mcForm]="mcForm" name="selBankAccount" [validRequiredFlg]="true" [placeholderOption]="'cc.common.edit.please-choose' | translate" [label]="'cc.common.edit.bank-account' | translate"
                   [optionsVll]="bankAccountsVll"></mcc-fi-select>
  </div>
  <div class="content-footer">
    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="Cancel"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actSave();" label="{{saveButtonLabel}}"></mcc-button>
  </div>
</div>
