import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class McProfileUserService {

  // @ts-ignore
  private token = JSON.parse(localStorage.getItem('mc-user')).access_token;
  private apiUrl = 'http://localhost:8080/api';
  private httpOptions;
  constructor(
    private http: HttpClient,
    @Inject('environment') environment:any
  ) {
    /*Set API base url*/
    this.apiUrl = environment.apiUrl;
    this.httpOptions = environment.httpOptions(HttpHeaders, this.token);
  }

  /*2FA Security of the account*/
  public twoFactorAuthentication(body: { enabled: boolean; }) {
    return this.http.post<boolean>(this.apiUrl + `/account/2fa`, body , this.httpOptions);
  }

  /*2FA Security of the account for the user*/
  public twoFactorAuthenticationForUser(body: { enabled: boolean; }, userId: number) {
    return this.http.post<boolean>(this.apiUrl + `/mcuser/v2/mcuser/2fa/${userId}`, body , this.httpOptions);
  }

  /*Change password*/
  public changePassword(body: object) {
    // eslint-disable-next-line max-len
    return this.http.post<any>(this.apiUrl + '/account/change-password', body, this.httpOptions);
  }

  /*Change password for the user*/
  public changeUserPassword(body: object, userId: number) {
    return this.http.post<any>(this.apiUrl + `/mcuser/v2/mcuser/change-password/${userId}`, body, this.httpOptions);
  }

  /*Get password policy for user*/
  public getPasswordPolicyData(userId: any) {
    const apiUrl = this.apiUrl + `/core/user/${userId}/strongest-password-policy`;
    return this.http.get<any>(apiUrl);
  }

  /*Get userData for AccountInfo*/
  public getUserData(userId: number) {
    const apiUrl = this.apiUrl + `/account/${userId}`;
    return this.http.get<any>(apiUrl);
  }

  /*Edit userData*/
  public editUserData(body: { id: any; firstName: any; lastName: any; gender: any; isoCountry: any; isoLanguage: any; nickname: any; rawImageData: any; timezone: any; }) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + '',
      'Content-Type': 'multipart/form-data',
    });
    // @ts-ignore
    return this.http.put<any>(this.apiUrl + '/core/user', body, headers);
  }

  /*Get systemUserDataById for PersonalInfo*/
  public getSystemUserData(userId: number) {
    const apiUrl = this.apiUrl + `/core/user/${userId}/`;
    return this.http.get<any>(apiUrl);
  }


}
