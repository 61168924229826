import {Inject, Injectable} from '@angular/core';
import {McVatServiceGENERATED} from '../_generated/mc-vat-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McVatService extends McVatServiceGENERATED {

}
