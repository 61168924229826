<h3 class="page-title">{{'cc.common.pdf-templates' | translate}}</h3>

<div class="actions-container">
  <span><i class="title-icon fas fa-file-pdf"></i> {{'cc.common.pdf-templates' | translate}}</span>
  <div class="actions-wrapper">
    <button *ngIf="mcGod.userHasMcProcessPdfTemplateAddPermission()" (click)="actShowAddEditTemplate()" class="system-action-btn"><i class="fas fa-plus-circle"></i> {{'cc.common.add-pdf-template' | translate}}
    </button>
  </div>
</div>

<mcc-modal1-wrapper modalSizeCd="small" *ngIf="addPdfTemplateMcb.value" [showMcb]="addPdfTemplateMcb" (eventCanceled)="actCancelPdfTemplateAddEdit()">
  <mc-process-pdf-template-add-edit [mcPdfTemplate]="mcPdfTemplate" (eventSaved)="actSavedPdfTemplateAddEdit()" (eventCanceled)="actCancelPdfTemplateAddEdit()"></mc-process-pdf-template-add-edit>
</mcc-modal1-wrapper>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  (filterChanged)="onFilterChanged($event)"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
  [searchTooltip]="searchTooltip"
></mk-mat-table>
