
export class McHtmlTooltip {
  public bodyTextHtml: string;
  public tooltipTextHtml: string;
  public tooltipPlacement: 'top' | 'bottom' | 'left' | 'right' = 'top';
  public tooltipCssClass: string;
  public extraCssClass: string;

  constructor(bodyTextHtml: string, tooltipTextHtml: string, tooltipPlacement: 'top' | 'bottom' | 'left' | 'right' = 'top', tooltipCssClass: string = '', extraCssClass: string = '') {
    this.bodyTextHtml = bodyTextHtml;
    this.tooltipTextHtml = tooltipTextHtml;
    this.tooltipPlacement = tooltipPlacement;
    this.tooltipCssClass = tooltipCssClass;
    this.extraCssClass = extraCssClass;
  }

}
