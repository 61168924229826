import {EbInterest_rate} from './eb-interest_rate.model';
import {EbInterest_rateListGENERATED} from '../_generated/eb-interest_rate-list-generated.model';

export class EbInterest_rateList extends EbInterest_rateListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbInterest_rateList();
  }

  // ---------------------------------------------------------------------
}
