import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {McEntityService, McUtilityService} from "@miticon-ui/mc-core";
import {LoginEnums} from "@miticon-ui/mc-core";

@Component({
  selector: "mc-entity-password-policy",
  templateUrl: "./mc-entity-password-policy.component.html"
})
export class McEntityPasswordPolicyComponent implements OnInit {

  entityPasswordPolicyForm: FormGroup;
  message!: string;
  type!: string;
  isEnabled = true;
  isDisabled!: boolean;
  inheritParentPPolicy!: any;
  restoredValues = false;
  passwordPolicyTab!: boolean;
  showLoader!: boolean;

  // Edit entity Id
  @Input() editEntityId!: any;
  // ParentData
  @Input() parentData!: any;
  // Is edit
  @Input() isEdit!: any;
  // ParentForm
  @Input() parentForm!: FormGroup;

  // Outputs
  @Output() successfullyEditPasswordPolicy = new EventEmitter<any>();
  @Output() isEntityPasswordPolicyDataInvalid = new EventEmitter<boolean>();

  constructor(
    private modalService: NgbModal,
    private entityService: McEntityService,
    private utilityService: McUtilityService,
    private fb: FormBuilder
  ) {
    this.entityPasswordPolicyForm = new FormGroup({});
  }

  ngOnInit() {
    // set formValues
    this.createForm();
    // Forward child entityPasswordPolicy form to the parent one
    if (this.parentForm) {
      this.parentForm.setControl("step3", this.entityPasswordPolicyForm);
    }
    // Set color for active tab
    this.passwordPolicyTab = true;
    // Disable next btn on init
    this.shareUnlockProperty(true);
    this.isDisabled = false;
    // Empty notification message
    this.setNotificationMessage("", "");
    this.restoredValues = false;
  }

  // Create form
  private createForm() {
    this.entityPasswordPolicyForm = this.fb.group({
      inheritParent: [this.parentData && this.isEdit ? this.parentData.inheritFromParent : true, Validators.required],
      maxRetry: [this.parentData && this.parentData.maxRetryCount, [Validators.required, Validators.min(0)]],
      minDiffPass: [this.parentData && this.parentData.minPassHistory, [Validators.required, Validators.min(0)]],
      lockTime: [this.parentData && this.parentData.lockoutTime, [Validators.required, Validators.min(0)]],
      minDigits: [this.parentData && this.parentData.minDigits, [Validators.required, Validators.min(0)]],
      minPassLength: [this.parentData && this.parentData.minPassLength, [Validators.required, Validators.min(6)]],
      minSpecChar: [this.parentData && this.parentData.minSpecialChars, [Validators.required, Validators.min(0)]],
      passExPeriod: [this.parentData && this.parentData.passMaxDays, [Validators.required, Validators.min(1)]],
      minLowLetters: [this.parentData && this.parentData.minLowercaseLetters, [Validators.required, Validators.min(0)]],
      minDiffChar: [this.parentData && this.parentData.minDiffChars, [Validators.required, Validators.min(1)]],
      minUpLetters: [this.parentData && this.parentData.minUppercaseLetter, [Validators.required, Validators.min(0)]]
    });
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  // Unlock Next btn
  private shareUnlockProperty(valid: boolean) {
    this.isEntityPasswordPolicyDataInvalid.emit(
      valid
    );
    this.isDisabled = valid;
  }

  // Inherit parent password policy
  inheritParentPassPolicy(inheritParent: boolean) {
    this.setNotificationMessage("", "");
    if (inheritParent === true) {
      this.isEnabled = true;
      this.inheritParentPPolicy = inheritParent;
      this.shareUnlockProperty(false);
    } else {
      this.checkValidation();
    }
  }

  // Check if number is valid
  checkValidation() {
    const form = this.entityPasswordPolicyForm;
    const isInvalid = Object.keys(form.controls).find(item => form.controls[item].invalid);

    if (isInvalid) {
      if (form.get(isInvalid)?.errors && form.get(isInvalid)?.errors?["required"] : '') {
        this.setNotificationMessage(`Required fields can\'t be empty`, "error");
      } else if (form.get(isInvalid)?.errors && form.get(isInvalid)?.errors?["min"] : '') {
        this.setNotificationMessage(`Enter a number greater than or equal to ${form.get(isInvalid)?.errors?["min"] : ''}.`, "error");
      }
    } else {
      this.setNotificationMessage("", "");
    }

    // Check if form is valid and send it to parent
    this.shareUnlockProperty(!!isInvalid);
  }

  // Restore default values
  restoreDefault() {
    this.entityPasswordPolicyForm.get("maxRetry")?.setValue(this.parentData.maxRetryCount);
    this.entityPasswordPolicyForm.get("minDiffPass")?.setValue(this.parentData.minPassHistory);
    this.entityPasswordPolicyForm.get("lockTime")?.setValue(this.parentData.lockoutTime);
    this.entityPasswordPolicyForm.get("minDigits")?.setValue(this.parentData.minDigits);
    this.entityPasswordPolicyForm.get("minPassLength")?.setValue(this.parentData.minPassLength);
    this.entityPasswordPolicyForm.get("minSpecChar")?.setValue(this.parentData.minSpecialChars);
    this.entityPasswordPolicyForm.get("passExPeriod")?.setValue(this.parentData.passMaxDays);
    this.entityPasswordPolicyForm.get("minLowLetters")?.setValue(this.parentData.minLowercaseLetters);
    this.entityPasswordPolicyForm.get("minDiffChar")?.setValue(this.parentData.minDiffChars);
    this.entityPasswordPolicyForm.get("minUpLetters")?.setValue(this.parentData.minUppercaseLetter);
    this.setNotificationMessage("", "");
    this.isEnabled = true;
    this.restoredValues = true;
    this.shareUnlockProperty(false);
  }

  // Edit passwordPolicy
  changeEntityPasswordPolicy() {
    this.showLoader = true;
    if (this.isEdit === true) {
      let msgText: string;
      let msgType: string;
      const formData = this.entityPasswordPolicyForm.getRawValue();
      const body = {
        inheritFromParent: formData.inheritParent,
        maxRetryCount: formData.inheritParent === true ? "" : formData.maxRetry,
        minPassHistory: formData.inheritParent === true ? "" : formData.minDiffPass,
        lockoutTime: formData.inheritParent === true ? "" : formData.lockTime,
        minDigits: formData.inheritParent === true ? "" : formData.minDigits,
        minPassLength: formData.inheritParent === true ? "" : formData.minPassLength,
        minSpecialChars: formData.inheritParent === true ? "" : formData.minSpecChar,
        passMaxDays: formData.inheritParent === true ? "" : formData.passExPeriod,
        minLowercaseLetters: formData.inheritParent === true ? "" : formData.minLowLetters,
        minDiffChars: formData.inheritParent === true ? "" : formData.minDiffChar,
        minUppercaseLetter: formData.inheritParent === true ? "" : formData.minUpLetters
      };

      // stop here if form is invalid
      if (this.entityPasswordPolicyForm.invalid && formData.inheritParent === false) {
        this.checkValidation();
        this.isEnabled = false;
      } else {
        this.isEnabled = true;
        if (this.editEntityId) {
          // edit current entity password policy
          this.entityService.editCurrentEntityPasswordPolicy(body, this.editEntityId).subscribe(
            (response) => {
              this.successfullyEditPasswordPolicy.emit(response);
              msgText = LoginEnums.successMsg;
              msgType = LoginEnums.successMsg;
              this.setNotificationMessage(msgText, msgType);
              this.modalService.dismissAll("Cross click");
              this.showLoader = false;
            },
            (error) => {
              this.showLoader = false;
              if (error.status >= 500) {
                msgText = "Server is not available at the moment, please try again later.";
                msgType = "error";
                this.setNotificationMessage(msgText, msgType);
                return;
              }
              msgText = error.error ? error.error.message : error.message;
              msgType = error.ok === false ? LoginEnums.errorMsg : LoginEnums.successMsg;
              this.setNotificationMessage(msgText, msgType);
            }
          );
        } else {
          // edit logged entity password policy
          this.entityService.editPasswordPolicy(body).subscribe(
            (response) => {
              this.successfullyEditPasswordPolicy.emit(response);
              msgText = LoginEnums.successMsg;
              msgType = LoginEnums.successMsg;
              this.setNotificationMessage(msgText, msgType);
              this.modalService.dismissAll("Cross click");
              this.showLoader = false;
            },
            (error) => {
              this.showLoader = false;
              if (error.status >= 500) {
                msgText = "Server is not available at the moment, please try again later.";
                msgType = "error";
                this.setNotificationMessage(msgText, msgType);
                return;
              }
              msgText = error.error ? error.error.message : error.message;
              msgType = error.ok === false ? LoginEnums.errorMsg : LoginEnums.successMsg;
              this.setNotificationMessage(msgText, msgType);
            }
          );
        }
      }
    }
  }
}

