/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CsrContractTable3ConfigGENERATED} from '../_generated/csr-contract-table-3-config-generated.model';

// import {CsrContractGENERATED} from '../_generated/csr-contract-generated.model';

export class CsrContractTable3Config extends CsrContractTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldCsrContractActions()', '').extraCssClass = 'table-col-width-50';
    this.addColumn('id', this.t('cc.consumer.contract-id'), 'id', 'id');
    this.addColumn('colStartDate', this.t('cc.common.contract-start-date'), 'fldStartDate()', 'startDate');
    this.addColumn('colEndDate', this.t('cc.common.contract-end-date'), 'fldEndDate()', 'endDate');
    this.addColumn('colStatusCd', this.t('cc.common.view.status'), 'getStatusCd()', 'statusCd');
  }
}
