
import {Table3Config} from '../extra/table-3-config';

// import {McBillingStatementGENERATED} from '../_generated/mc-billing-statement-generated.model';

export class McBillingStatementTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colChildEntityId', 'Child entity ID', 'childEntityId', 'childEntityId');
    this.addColumn('colCompletedDatetime', 'Completed datetime', 'completedDatetime', 'completedDatetime');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colFeesAmount', 'Fees amount', 'feesAmount', 'feesAmount');
    this.addColumn('colFlgHidden', 'Hide status', 'flgHidden', 'flgHidden');
    this.addColumn('colIdPayoutFile', 'Payout File ID', 'idPayoutFile', 'idPayoutFile');
    this.addColumn('colParentEntityId', 'Parent entity ID', 'parentEntityId', 'parentEntityId');
    this.addColumn('colPdfWebfileId', 'PDF Webfile ID', 'pdfWebfileId', 'pdfWebfileId');
    this.addColumn('colStatementDate', 'Statement date', 'statementDate', 'statementDate');
    this.addColumn('colStatementNumber', 'Statement number', 'statementNumber', 'statementNumber');
    this.addColumn('colStatusCd', 'Status cd', 'statusCd', 'statusCd');
    this.addColumn('colStatusDetails', 'Status Details', 'statusDetails', 'statusDetails');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colTotalPayoutAmount', 'Total payout amount', 'totalPayoutAmount', 'totalPayoutAmount');
    this.addColumn('colTotalVat', 'Total VAT', 'totalVat', 'totalVat');
    this.addColumn('colXlsWebfileId', 'XLS Webfile ID', 'xlsWebfileId', 'xlsWebfileId');

*/
  }
}
