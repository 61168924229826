import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'lib-mcc-fi-file-upload',
  templateUrl: './mcc-fi-file-upload.component.html',
})
export class MccFiFileUploadComponent implements OnInit {

  file!: File;
  @ViewChild('labelImport')
  labelImport!: ElementRef;
  @Input() acceptProperty: any;
  @Output() eventFileSelected = new EventEmitter<File>();

  constructor() { }

  ngOnInit() {
  }

  onFileChanged(event: Event) {
    // @ts-ignore
    const inputFile: File = (event.target as HTMLInputElement).files[0];
    if (inputFile) {
      this.labelImport.nativeElement.innerText = inputFile.name;
      this.file = inputFile;
    }
    this.eventFileSelected.emit(this.file);
  }
}
