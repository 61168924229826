import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  CsrMapping,
  McBoolean,
  McGod,
  McDateUtils,
  BaseDirective,
} from "@miticon-ui/mc-core";

const enum SharingUnsharinDialogAction {
  ACT_CANCEL = "btn-secondary",
  ACT_YES = "btn-primary",
}

@Component({
  selector: "mc-consumer-consumer-mapping-profile-part",
  templateUrl: "./mc-consumer-consumer-mapping-profile-part.component.html",
})
export class McConsumerConsumerMappingProfilePartComponent
  extends BaseDirective
  implements OnInit
{
  // -- Inputs --
  @Input() csrMappingId!: number;
  @Input() csrMapping = new CsrMapping();
  showUnShareMappingMcb = new McBoolean();
  showShareMappingMcb = new McBoolean();
  showCopyMappingMcb = new McBoolean();
  sharingUnsharingCopyButtonKvm = new Map<string, string>();
  sharedDate!: string;
  mappingFromLoggedEntity!: boolean;

  @Output() eventClose = new EventEmitter();
  @Output() eventEditMapping = new EventEmitter();
  @Output() eventShare = new EventEmitter();
  @Output() eventUnshare = new EventEmitter();
  @Output() eventCopy = new EventEmitter();
  mcGod = McGod.getInstance();

  // ---------------------------------------------------------------------
  constructor() {
    super();
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.sharingUnsharingCopyButtonKvm.set(
      "btn-secondary",
      McGod.t("cc.common.edit.cancel")
    );
    this.sharingUnsharingCopyButtonKvm.set(
      "btn-primary",
      McGod.t("cc.common.edit.yes")
    );
    this.actLoad();
  }

  checkSharedStatus() {
    this.mappingFromLoggedEntity =
      this.csrMapping.idMcEntity === McGod.getLoggedEntityIdFromToken();
    this.sharedDate = McDateUtils.formatDateToString(
      this.csrMapping.sharingDate
    );
  }

  public actLoad(): void {
    if (this.csrMappingId > 0) {
      this.csrMapping = new CsrMapping();
      this.csrMapping.loadById(this.csrMappingId, () => {
        this.checkSharedStatus();
      });
    }
  }

  actClose() {
    this.eventClose.emit();
  }

  actEditMapping() {
    this.eventEditMapping.emit();
  }

  onMcDialogBoxUnshareMappingActionButtonClicked(action: string) {
    if (action === SharingUnsharinDialogAction.ACT_CANCEL) {
      this.showUnShareMappingMcb.setFalse();
    } else {
      this.eventUnshare.emit();
    }
  }

  onMcDialogBoxShareMappingActionButtonClicked(action: string) {
    if (action === SharingUnsharinDialogAction.ACT_CANCEL) {
      this.showShareMappingMcb.setFalse();
    } else {
      this.eventShare.emit();
    }
  }

  onMcDialogBoxCopyMappingActionButtonClicked(action: string) {
    if (action === SharingUnsharinDialogAction.ACT_CANCEL) {
      this.showCopyMappingMcb.setFalse();
    } else {
      this.eventCopy.emit(this.csrMapping);
    }
  }

  shareLinkClick() {
    this.showShareMappingMcb.setTrue();
  }
  unshareLinkClick() {
    this.showUnShareMappingMcb.setTrue();
  }
  copyLinkClick() {
    this.showCopyMappingMcb.setTrue();
  }
}
