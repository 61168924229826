import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {WebFile} from '../models/web-file.model';
import {WebFileServiceGENERATED} from '../_generated/web-file-generated.service';

import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WebFileService extends WebFileServiceGENERATED {

  public upload(aFile: any, type: string): Observable<WebFile> {
    const formData = new FormData();
    formData.append("file", aFile.file);
    formData.append("aRelativePath", aFile.aRelativePath);
    formData.append("type", type);

    const headers = new HttpHeaders(
      {
        Authorization: "Bearer " + "",
        "Content-Type": "multipart/form-data",
      });

  // bmitic: multipart form data header causes errors in backend of the application
    // we are sending the request without headers, but it may cause an error in the future.
    return this.httpClient.post<WebFile>(this.getApiServiceRootUrl() + `/upload?delimiter=${aFile.delimiter}`, formData);
  }
 /* public upload2(aFile: File): Observable<WebFile> {
    const formData = new FormData();
    formData.append("file", aFile);
    const headers = new HttpHeaders({
        Authorization: "Bearer " + "",
        "Content-Type": "multipart/form-data",
      });

      // @ts-ignore
    return this.httpClient.post(`${this.apiUrl}/core/WebFile/upload-file`, formData, headers).pipe(map((response: any) => {
        return new WebFile();
      }));

  }*/

  /* /!* Upload File For Importing Consumers *!/
   public uploadFileForImportingConsumers(body) {
     const headers = new HttpHeaders({
       Authorization: "Bearer " + "",
       "Content-Type": "multipart/form-data",
     });
     console.log(body);
     console.log(headers);
     // @ts-ignore
     return this.http.post(`${this.apiUrl}/core/consumer/save-file`, body, headers)
       ;
   }
 */

  /*Download web file*/
  public downloadWebFile(id: number, type: string): Observable<Blob> {
    return this.httpClient.get(`${this.getApiServiceRootUrl().replace('v2', 'v3')}/download/${id}/${type}`, { responseType: 'blob' });
  }

  public getByIdAndType(id: number, type: string): Observable<WebFile> {
    return this.httpClient.get<WebFile>(`${this.getApiServiceRootUrl()}/${id}/${type}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  downloadFile(id: number, type: string): Observable<Blob> {
    return this.httpClient.get(`${this.getApiServiceRootUrl()}/download/${id}/${type}`, { responseType: 'blob' });
  }

  public getWebFileByEmailAttachmentId(attachmentId: number): Observable<WebFile> {
    return this.httpClient.get<WebFile>(`${this.getApiServiceRootUrl()}/get-by-attachment-id/${attachmentId}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  generatePreviewByWebFileIdAndType(webFileId: number, type: string) {
    return this.httpClient.get(
      this.getApiServiceRootUrl() + `/generate-pdf-preview/${webFileId}/${type}`,
      { responseType: 'blob' }
    );
  }
}
