import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {
  McBillingService2PriceListList,
  McBillingService2PriceListTable3Config,
  McEntity2,
  McGod,
  McPriceList
} from '@miticon-ui/mc-core';
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'mc-pricelist-services-and-pricelist-list-page',
  templateUrl: './mc-pricelist-services-and-pricelist-list-page.component.html',
  encapsulation: ViewEncapsulation.None
})
export class McPricelistServicesAndPricelistListPageComponent implements OnInit {

  mcBillingService2PriceListList!: McBillingService2PriceListList;
  mcBillingService2PriceListTable3Config = new  McBillingService2PriceListTable3Config();
  parentEntityId!: number;
  childEntityId!: string;
  paramSubscription = new Subscription();
  mcEntity = new McEntity2();
  mcPricelist = new McPriceList();

  // ---------------------------------------------------------------------
  constructor(private activatedRoute: ActivatedRoute, private location: Location) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.parentEntityId = McGod.getLoggedEntityIdFromToken();
    this.paramSubscription = this.activatedRoute.paramMap.subscribe(data => {
      if (data.get('id')) {
        this.parentEntityId = Number(data.get('id'));
      }
    });
    this.mcEntity.loadById(this.parentEntityId);
    this.mcBillingService2PriceListList = new McBillingService2PriceListList();
    this.mcBillingService2PriceListList.setPager(0, 100);
    this.actLoad();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcBillingService2PriceListList.loadPriceListItemsInEffectForEntity(this.parentEntityId, () => {
      if (this.mcBillingService2PriceListList.getCount() > 0) {
        this.mcPricelist.loadById(this.mcBillingService2PriceListList.items[0].idPriceList);
      }
    });
  }

  navigateBack() {
    this.location.back();
  }
}
