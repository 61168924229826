/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McBillingEntitiesUsingPricelistFilter} from '../models/mc-billing-entities-using-pricelist-filter.model';

export class McBillingEntitiesUsingPricelistFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingEntitiesUsingPricelistFilter {
    console.error('MTCN-ERROR: Not Implemented: McBillingEntitiesUsingPricelistFilter::createNewInstance(). Add this method to final class and return new McBillingEntitiesUsingPricelistFilter();');
      throw new Error('Cannot McBillingEntitiesUsingPricelistFilter::createNewInstance(). Add this method to final class and return new McBillingEntitiesUsingPricelistFilter();');
  }

}
