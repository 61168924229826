<div class='card'>
  <h2 class="title-medium-dark mb-large">{{ 'cc.billing.details' | translate }}</h2>

  <h6 class="mc-mandatory-star">{{'cc.contract.contract-type' | translate}}</h6>
  <mcc-fi-radio-buttons2 [directionHorizontal]="true"
                         name="contractType"[validRequiredFlg]="selectedTab > 0"
                         [mcForm]="mcForm"
                         [optionsVll]="contractTypeOptionsVll"
                         (eventValueChanged)="contractTypeValueChanged($event)"
                         validRequiredFlg="true">
  </mcc-fi-radio-buttons2>
  <div class="d-flex flex-column">
    <ng-container *ngIf="showStartDateDatepicker">
      <mcc-fi-date-picker
        extraCssClasses="mr-2"
        label="{{'cc.factoring.start-date' | translate}}"
        [name]="'ctrlContractStartDate'"
        [validRequiredFlg]="selectedTab > 0"
        [minDateInput]="today"
        (eventValueChanged)="onStartDateChanged($event)"
        [allowManualEntryFlg]="false"
        [newStyleDate]="true"
        [mcForm]="mcForm">
      </mcc-fi-date-picker>
    </ng-container>
    <ng-container *ngIf="showEndDateDatepicker">
      <mcc-fi-date-picker
        label="{{'cc.factoring.end-date' | translate}}"
        [name]="'ctrlContractEndDate'"
        [validRequiredFlg]="selectedTab > 0"
        [allowManualEntryFlg]="true"
        [minDateInput]="mcForm.getValue('ctrlContractStartDate')"
        [newStyleDate]="true"
        [mcForm]="mcForm">
      </mcc-fi-date-picker>
    </ng-container>
    <div class="w-100">
      <mcc-fi-textarea [mcForm]="mcForm"
                       name="ctrlFreeText"
                       [placeholder]="'cc.consumer.free-text' | translate"
                       [numRows]="3"
                       [numCols]="50">
      </mcc-fi-textarea>
    </div>
  </div>
</div>
