import {Component, DoCheck, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CsrConsumer, McConsumer, McForm, McGod, McValueLabelList} from '@miticon-ui/mc-core';

export enum ContractType {
  CONTRACT_TYPE_UNLIMITED = 'UNLIMITED',
  CONTRACT_TYPE_LIMITED = 'LIMITED'
}

@Component({
  selector: 'mc-consumer-consumer-360-create-contract-details-part',
  templateUrl: './mc-consumer-consumer-360-create-contract-details-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-create-contract-details-part.scss']
})
export class McConsumerConsumer360CreateContractDetailsPartComponent implements OnInit, DoCheck {

  @Input() mcForm!: McForm;
  @Input() csrConsumer!: CsrConsumer;
  @Input() selectedTab!: number;
  contractTypeOptionsVll = new McValueLabelList();
  emailAddressVll = new McValueLabelList();
  today = Number(new Date());
  showStartDateDatepicker = false;
  showEndDateDatepicker = false;
  showEmailAddress = false;
  mcGod = McGod.getInstance();
  disableEmailAddressTextBox!: boolean;
  @Output() emitValidDetailsTab = new EventEmitter();

  ngOnInit() {
    this.contractTypeOptionsVll.add(ContractType.CONTRACT_TYPE_UNLIMITED, this.mcGod.t('cc.consumer.unlimited'));
    this.contractTypeOptionsVll.add(ContractType.CONTRACT_TYPE_LIMITED, this.mcGod.t('cc.consumer.limited'));

    this.emailAddressVll.add(1, this.mcGod.t('cc.consumer.use-email-address-profile'));
    this.emailAddressVll.add(2, this.mcGod.t('cc.consumer.change-email-address'));
  }

  contractTypeValueChanged(value: any) {
    if (value === ContractType.CONTRACT_TYPE_UNLIMITED) {
      this.showStartDateDatepicker = true;
      this.showEndDateDatepicker = false;
    }
    if (value === ContractType.CONTRACT_TYPE_LIMITED) {
      this.showStartDateDatepicker = true;
      this.showEndDateDatepicker = true;
    }
  }

  onSendInvoiceValueChanged(value: any) {
    this.showEmailAddress = !!value;
  }

  emailAddressValueChange(value: any) {
    if (value === 1) {
      this.mcForm.getControl('ctrlEmailAddressTextBox')?.get('value')?.setValue(this.csrConsumer.email);
      this.disableEmailAddressTextBox = true;
    }
    if (value === 2) {
      this.disableEmailAddressTextBox = false;
    }

  }

  onStartDateChanged($event: unknown) {
    if (this.mcForm.getControl('ctrlContractEndDate')) {
      this.mcForm.getControl('ctrlContractEndDate')?.get('value')?.setValue(null);
    }
  }

  ngDoCheck(): void {
    const contractType = this.mcForm.getControl('contractType')?.value.value;
    const contractStartDate = this.mcForm.getControl('ctrlContractStartDate')?.value.value;
    const contractEndDate = this.mcForm.getControl('ctrlContractEndDate')?.value.value;

    const isValid = contractType
      ? (contractType === ContractType.CONTRACT_TYPE_UNLIMITED)
        ? !!contractStartDate && contractStartDate !== ''
        : !!contractStartDate && contractStartDate !== '' && !!contractEndDate && contractEndDate !== ''
      : false;
    this.emitValidDetailsTab.emit(isValid);
  }
}
