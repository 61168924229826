<ng-template #chooseMapping let-modal>

  <div class="modal-header mc-create-user-modal-header">
    <div>
      <h4 class="modal-title" id="mc-modal-new-user-title">{{'cc.consumer.import.please-choose-mapping' | translate}}
        :</h4>
    </div>
    <button type="button" class="close mc-consumers-close-btn" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div><!--Modal header -->

  <!--Modal Body-->
  <div class="modal-body">
    <div class="mc-create-new-user-wrapper">
      <div class="container-fluid">

        <mc-notify-message message="{{message}}" type="{{type}}"></mc-notify-message>

        <div class="row" [formGroup]="mappingFormGroup" *ngIf="mappingList.getCount() > 0">

          <div class="col-md-6 d-flex flex-column justify-content-center align-items-start">
            <div *ngFor="let mapping of mappingList.items; index as i" class="form-check d-inline pl-0">
              <input type="radio" id="radioEl{{i}}" formControlName="mappingRadio" [value]="mapping">
              <label class="form-check-inline" for="radioEl{{i}}">{{mapping.mappingName}}</label>
            </div>
          </div>
          <div class="col-md-6">

          </div>

        </div>

        <div *ngIf="mappingList.getCount() === 0">
        </div>


      </div>
    </div>
  </div>

  <!--Modal Footer-->
  <div class="modal-footer">
    <div class="row w-100">

      <div class="col-md-9 offset-md-3 text-right">
        <button class="btn btn-sm mc-cancel-btn mr-5" (click)="closeModal()">
          {{'cc.common.edit.cancel' | translate}}
        </button>
        <button [disabled]="!enabled" class="nav-button btn btn-primary mc-next-save-btn mr-5" (click)="applyMapping()">
          {{'cc.consumers.consumers-overview.apply-mapping' | translate}}
        </button>
      </div>

    </div>
  </div>

</ng-template>

<button class="btn btn-outline-primary"
        (click)="openChooseMappingModal(chooseMapping)">{{'cc.consumer.import.choose-mapping' | translate}}</button>
