
import {Table3Config} from '../extra/table-3-config';

// import {McEntityGENERATED} from '../_generated/mc-entity-generated.model';

export class McEntityTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAbbreviation', 'Abbreviation', 'abbreviation', 'abbreviation');
    this.addColumn('colActive', 'Active', 'active', 'active');
    this.addColumn('colAddress', 'Address', 'address', 'address');
    this.addColumn('colBankTransactionEntityNameOverride', 'Alternative name', 'bankTransactionEntityNameOverride', 'bankTransactionEntityNameOverride');
    this.addColumn('colCity', 'City', 'city', 'city');
    this.addColumn('colCompanyForm', 'Company form', 'companyForm', 'companyForm');
    this.addColumn('colCountryId', 'Country Id', 'countryId', 'countryId');
    this.addColumn('colCourtInCharge', 'Court in charge', 'courtInCharge', 'courtInCharge');
    this.addColumn('colCreatedAt', 'Created at', 'createdAt', 'createdAt');
    this.addColumn('colCustomAttributeMap', 'custom attribute map', 'customAttributeMap', 'customAttributeMap');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colEmail', 'Email', 'email', 'email');
    this.addColumn('colEuVat', 'EU VAT', 'euVat', 'euVat');
    this.addColumn('colExtCreditorId', 'Creditor (Glaeubiger ID)', 'extCreditorId', 'extCreditorId');
    this.addColumn('colFax', 'Fax', 'fax', 'fax');
    this.addColumn('colImpressum', 'Impressum', 'impressum', 'impressum');
    this.addColumn('colIpWhitelist', 'IP whitelist', 'ipWhitelist', 'ipWhitelist');
    this.addColumn('colIsVisibleToChildren', 'Is visible to children?', 'isVisibleToChildren', 'isVisibleToChildren');
    this.addColumn('colLatitude', 'Latitude', 'latitude', 'latitude');
    this.addColumn('colLegalRepresentative', 'Legal representative', 'legalRepresentative', 'legalRepresentative');
    this.addColumn('colLegalRepresentativeContact', 'Legal representative contact', 'legalRepresentativeContact', 'legalRepresentativeContact');
    this.addColumn('colLogo', 'Logo', 'logo', 'logo');
    this.addColumn('colLongitude', 'Longitude', 'longitude', 'longitude');
    this.addColumn('colMailDomain', 'Mail domain', 'mailDomain', 'mailDomain');
    this.addColumn('colMailPrefix', 'Mail prefix', 'mailPrefix', 'mailPrefix');
    this.addColumn('colMaxTransactionPauseDays', 'Max transaction pause days', 'maxTransactionPauseDays', 'maxTransactionPauseDays');
    this.addColumn('colMcCountry', 'Country', 'mcCountry', 'mcCountry');
    this.addColumn('colName', 'Name', 'name', 'name');
    this.addColumn('colParentEntityId', 'Parent entity ID', 'parentEntityId', 'parentEntityId');
    this.addColumn('colPhone', 'Phone', 'phone', 'phone');
    this.addColumn('colPostalCode', 'Postal code', 'postalCode', 'postalCode');
    this.addColumn('colResponsiblePerson', 'Responsible person', 'responsiblePerson', 'responsiblePerson');
    this.addColumn('colResponsiblePersonFunction', 'Responsible person function', 'responsiblePersonFunction', 'responsiblePersonFunction');
    this.addColumn('colRootEntityId', 'Root entity ID', 'rootEntityId', 'rootEntityId');
    this.addColumn('colStudioOwner', 'Studio owner', 'studioOwner', 'studioOwner');
    this.addColumn('colStudioOwnerAddress', 'Studio owner address', 'studioOwnerAddress', 'studioOwnerAddress');
    this.addColumn('colStudioOwnerCity', 'Studio owner city', 'studioOwnerCity', 'studioOwnerCity');
    this.addColumn('colStudioOwnerPostalCode', 'Studio owner postal code', 'studioOwnerPostalCode', 'studioOwnerPostalCode');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');
    this.addColumn('colTax', 'Tax', 'tax', 'tax');
    this.addColumn('colTransactionDescriptionPrefix', 'Transaction description prefix', 'transactionDescriptionPrefix', 'transactionDescriptionPrefix');
    this.addColumn('colUpdatedAt', 'Updated at', 'updatedAt', 'updatedAt');
    this.addColumn('colUstId', 'UST - ID number', 'ustId', 'ustId');
    this.addColumn('colVersion', 'version', 'version', 'version');
    this.addColumn('colWebDomain', 'Web domain', 'webDomain', 'webDomain');
    this.addColumn('colWebsite', 'Website', 'website', 'website');

*/
  }
}
