import {McBillingStatementItemFilterGENERATED} from '../_generated/mc-billing-statement-item-filter-generated.model';

export class McBillingStatementItemFilter extends McBillingStatementItemFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingStatementItemFilter();
  }

  // ---------------------------------------------------------------------
}
