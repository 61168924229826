import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {McConsumersService, McEntityService} from "@miticon-ui/mc-core";
import {NgbModal, NgbDateAdapter, NgbDateNativeAdapter} from "@ng-bootstrap/ng-bootstrap";
import _moment from "moment";

@Component({
  selector: "lib-step2",
  templateUrl: "./step2.component.html",
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class Step2Component implements OnInit {

  userId!: number;
  message!: string;
  type!: string;
  responseMessage!: boolean;
  userDataFormValid!: boolean;
  amountDueInvalid!: boolean;
  invalidContactMail!: boolean;
  showLoader!: boolean;
  saveBtnDisabled!: boolean;
  minDate = {year: 1900, month: 1, day: 1};
  maxDate = {year: 2100, month: 12, day: 31};


  @Input() modalArea!: string;
  @Input() consumerData!: any;
  @Input() editing!: boolean;

  // Forms
  @Input() parentForm!: FormGroup;
  @Input() customAttributesFromDb!: any;
  stepForm!: FormGroup;

  // Outputs
  @Output() isDataFormInvalid = new EventEmitter<boolean>();
  @Output() refreshUserDataEvent = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private consumersService: McConsumersService,
              private modalService: NgbModal) {
    this.createForm();
  }


  ngOnInit() {
    this.amountDueInvalid = false;
    this.invalidContactMail = false;
    if (this.modalArea === "addConsumers") {
      this.parentForm.setControl("step2", this.stepForm);
    }
    else {
      this.userId = this.activatedRoute.snapshot.params["id"] - 0;
    }

  }

  createForm() {
    const emailRegExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,6})$/;
    this.stepForm = this.fb.group({
      contactPerson: [""],
      contactPersonMail: ["", Validators.pattern(emailRegExp)],
      consumerAmountDue: ["", [Validators.required, Validators.pattern("/^[0-9.]+$/")]],
      consumerAmountDueDate: [""],
      consumerCCDate: [""],
      consumerCEDate: [""],
      studioBanned: [""],
      consumerPaymentPauseFrom: [""],
      consumerPaymentPauseTo: [""]
    });
  }

  // Regex for number only
  private checkForNumber(name: any) {
    const re = /^[0-9]{0,9}(\.[0-9]{1,2})?$/;
    return re.test(String(name));
  }

  // Number check
  isNumberFormat(e: any) {
    const elValue = (e.target as HTMLInputElement).value;
    const el = (e.target as HTMLInputElement).id;
    const numberFormat = this.checkForNumber(elValue);

    if (!numberFormat && el === "consumerAmountDue") {
      this.amountDueInvalid = true;
      this.setNotificationMessage("A valid number format is required", "error");
      this.responseMessage = true;
      this.shareUnlockProperty(true);
      this.saveBtnDisabled = true;
      return;
    } else if (this.invalidContactMail === true) {
      this.amountDueInvalid = false;
      this.setNotificationMessage(`A valid email format is required.`, "error");
      this.shareUnlockProperty(true);
      this.responseMessage = true;
    } else {
      this.amountDueInvalid = false;
      this.setNotificationMessage("", "");
      this.responseMessage = false;
      this.shareUnlockProperty(false);
      this.saveBtnDisabled = false;
    }
  }

  isMailValid() {
    const form = this.stepForm;
    if (form.get("contactPersonMail")?.errors) {
      this.invalidContactMail = true;
      this.setNotificationMessage(`A valid email format is required.`, "error");
      this.shareUnlockProperty(true);
      this.responseMessage = true;
    } else if (this.amountDueInvalid === true) {
      this.invalidContactMail = false;
      this.setNotificationMessage("A valid number format is required", "error");
      this.shareUnlockProperty(true);
      this.responseMessage = true;
    } else {
      this.setNotificationMessage("", "");
      this.shareUnlockProperty(false);
      this.responseMessage = false;
      this.invalidContactMail = false;
    }
  }

  // Unlock Next btn
  private shareUnlockProperty(valid: boolean) {
    this.isDataFormInvalid.emit(
      this.userDataFormValid = valid
    );
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  saveEditedConsumerData() {
    this.showLoader = true;
    const stepFormData = this.stepForm.getRawValue();

    const editedAttributes: any[] = [];
    this.customAttributesFromDb.forEach((item: any) => {
      let val;
      if (item.type === "DATE" || item.type === 'DATETIME') {
        val = _moment(item.value).isValid() ? _moment(item.value).format("LL") : null;
      } else {
        val = item.value;
      }
      editedAttributes.push(Object.assign({}, {
        name: item.name,
        value: val
      }));
    });
    this.consumersService.updateConsumerExtendedData(this.userId, editedAttributes).subscribe(
      (response) => {
        const msgText = "Consumer successfully edited!";
        const msgType = "success";
        this.responseMessage = true;
        this.setNotificationMessage(msgText, msgType);
        // event for refresh personal data after successfully save
        this.refreshUserDataEvent.emit(true);

        this.modalService.dismissAll("Cross click");
        this.showLoader = false;
      },
      (error) => {
        this.apiErrorHandler(error);
      }
    );
  }

  // Api ErrorHandler
  private apiErrorHandler(error: any) {
    let message = "";
    let msgType = "";
    if (error.status >= 500) {
      message = "Server error";
      msgType = "error";
    } else if (error.error) {
      if (error.error.errors.length > 1) {
        message = error.error.errors.join();
        msgType = "error";
      } else {
        message = error.error.errors;
        msgType = "error";
      }
    } else {
      message = "Invalid request";
      msgType = "error";
    }
    this.responseMessage = true;
    this.setNotificationMessage(message, msgType);
  }

  /*on change*/
  public onChange(event: any, customAttributeName: any) {
    let value: any;
    if (event instanceof Date) {
      value = event;
    } else {
      value = event.target.value;
    }

    this.customAttributesFromDb = this.customAttributesFromDb.map((item: any) => {
      if (item.name === customAttributeName) {
        item["value"] = value;
      }
      return item;
    });

  }


}
