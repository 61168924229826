/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBankAccount} from '../models/mc-bank-account.model';
import {McBankAccountService} from '../services/mc-bank-account.service';
import {IMcBankAccount} from '../_generated/mc-bank-account.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McBankAccountGENERATED extends BaseObject {


    public static readonly EBICS_STATUS_NONE: string = 'NONE';
    public static readonly EBICS_STATUS_NEW: string = 'NEW';
    public static readonly EBICS_STATUS_PENDING: string = 'PENDING';
    public static readonly EBICS_STATUS_ACCEPTED: string = 'ACCEPTED';
  public static readonly EBICS_STATUS_LIST__ALL = [
    McBankAccountGENERATED.EBICS_STATUS_NONE,
    McBankAccountGENERATED.EBICS_STATUS_NEW,
    McBankAccountGENERATED.EBICS_STATUS_PENDING,
    McBankAccountGENERATED.EBICS_STATUS_ACCEPTED
];

  public apiService: McBankAccountService;
  public _rawJson: IMcBankAccount;
    id: number = 0;
  accountNumber = '';
  accountOwner = '';
  accountOwnerAddress = '';
  accountOwnerPostalCode = '';
  bankCity = '';
  bankCountry = '';
  bankName = '';
  bic = '';
  blz = '';
  defaultFlg: boolean;
  ebicsStatus: string;
  iban = '';
  idMcEntity: number;
  sepaMandateDate: number;
  sepaMandateId = '';
  swift = '';
  sysCreatedDatetime: number;
  sysUpdatedDatetime: number;


  public properties: string[] = ['id', 'accountNumber', 'accountOwner', 'accountOwnerAddress', 'accountOwnerPostalCode', 'bankCity', 'bankCountry', 'bankName', 'bic', 'blz', 'defaultFlg', 'ebicsStatus', 'iban', 'idMcEntity', 'sepaMandateDate', 'sepaMandateId', 'swift', 'sysCreatedDatetime', 'sysUpdatedDatetime'];
  public propertiesRegular: string[] = ['id', 'accountNumber', 'accountOwner', 'accountOwnerAddress', 'accountOwnerPostalCode', 'bankCity', 'bankCountry', 'bankName', 'bic', 'blz', 'defaultFlg', 'ebicsStatus', 'iban', 'idMcEntity', 'sepaMandateDate', 'sepaMandateId', 'swift', 'sysCreatedDatetime', 'sysUpdatedDatetime'];
  public propertiesSpecial: string[] = [];



  public static getEbicsStatusVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBankAccountGENERATED.EBICS_STATUS_NONE, McGod.t('None'));
    list.add(McBankAccountGENERATED.EBICS_STATUS_NEW, McGod.t('New'));
    list.add(McBankAccountGENERATED.EBICS_STATUS_PENDING, McGod.t('Pending'));
    list.add(McBankAccountGENERATED.EBICS_STATUS_ACCEPTED, McGod.t('Accepted'));


    return list;
  }

  public static getEbicsStatusLabel(aCd: string): string {
    const list = McBankAccountGENERATED.getEbicsStatusVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McBankAccount {
    console.error('MTCN-ERROR: Not Implemented: McBankAccount::createNewInstance(). Add this method to final class and return new McBankAccount();');
    throw new Error('Cannot McBankAccount::createNewInstance(). Add this method to final class and return new McBankAccount();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBankAccount): McBankAccount {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBankAccountService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBankAccount AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getEbicsStatusLabel(): string {
    return McBankAccountGENERATED.getEbicsStatusLabel(this.ebicsStatus);
  }


 public  isEbicsStatusNONE(): boolean {
    const result = (this.ebicsStatus === McBankAccountGENERATED.EBICS_STATUS_NONE);

    return result;
  }

 public  isEbicsStatusNEW(): boolean {
    const result = (this.ebicsStatus === McBankAccountGENERATED.EBICS_STATUS_NEW);

    return result;
  }

 public  isEbicsStatusPENDING(): boolean {
    const result = (this.ebicsStatus === McBankAccountGENERATED.EBICS_STATUS_PENDING);

    return result;
  }

 public  isEbicsStatusACCEPTED(): boolean {
    const result = (this.ebicsStatus === McBankAccountGENERATED.EBICS_STATUS_ACCEPTED);

    return result;
  }


  
  // ---------------------------------------------------------------------
  public loadDefault( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getDefault()
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

// ---------------------------------------------------------------------
public callSetDefault( id: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.setDefault(id)
    .subscribe(responseJson => {
        this.populateFromJson(responseJson);
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
}

}
