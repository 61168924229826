/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McProcessItem} from '../models/mc-process-item.model';
import {McProcessItemService} from '../services/mc-process-item.service';
import {IMcProcessItem} from '../_generated/mc-process-item.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {PmTransaction} from '../models/pm-transaction.model';
import {McProcessFlowLevel} from '../models/mc-process-flow-level.model';
import {McProcessFlow} from '../models/mc-process-flow.model';


export abstract class McProcessItemGENERATED extends BaseObject {


    public static readonly STATUS_CD_WAIT: string = 'WAIT';
    public static readonly STATUS_CD_READY: string = 'READY';
    public static readonly STATUS_CD_MOVING_TO_INKASSO: string = 'MOVING_TO_INKASSO';
    public static readonly STATUS_CD_OUT_OF_DUNNING: string = 'OUT_OF_DUNNING';
    public static readonly STATUS_CD_REMOVED: string = 'REMOVED';
  public static readonly STATUS_CD_LIST__ALL = [
    McProcessItemGENERATED.STATUS_CD_WAIT,
    McProcessItemGENERATED.STATUS_CD_READY,
    McProcessItemGENERATED.STATUS_CD_MOVING_TO_INKASSO,
    McProcessItemGENERATED.STATUS_CD_OUT_OF_DUNNING,
    McProcessItemGENERATED.STATUS_CD_REMOVED
];

  public apiService: McProcessItemService;
  public _rawJson: IMcProcessItem;
    id: number = 0;
  actionStartDatetime: number;
  addedInIdMcProcessRun: number;
  idMcConsumer: number;
  idMcProcess: number;
  idMcProcessFlow: number;
  idMcProcessFlowLevel: number;
  idPmTransaction: number;
  lastPaymentDueDatetime: number;
  statusCd: string;
  sysCreatedDatetime: number;
  mcProcessFlow: McProcessFlow;  mcProcessFlowLevel: McProcessFlowLevel;  pmTransaction: PmTransaction;

  public properties: string[] = ['id', 'actionStartDatetime', 'addedInIdMcProcessRun', 'idMcConsumer', 'idMcProcess', 'idMcProcessFlow', 'idMcProcessFlowLevel', 'idPmTransaction', 'lastPaymentDueDatetime', 'statusCd', 'sysCreatedDatetime', 'mcProcessFlow', 'mcProcessFlowLevel', 'pmTransaction'];
  public propertiesRegular: string[] = ['id', 'actionStartDatetime', 'addedInIdMcProcessRun', 'idMcConsumer', 'idMcProcess', 'idMcProcessFlow', 'idMcProcessFlowLevel', 'idPmTransaction', 'lastPaymentDueDatetime', 'mcProcess', 'mcProcessFlow', 'mcProcessFlowLevel', 'pmTransaction', 'statusCd', 'sysCreatedDatetime'];
  public propertiesSpecial: string[] = ['mcProcessFlow', 'mcProcessFlowLevel', 'pmTransaction'];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessItemGENERATED.STATUS_CD_WAIT, McGod.t('Waiting'));
    list.add(McProcessItemGENERATED.STATUS_CD_READY, McGod.t('Ready'));
    list.add(McProcessItemGENERATED.STATUS_CD_MOVING_TO_INKASSO, McGod.t('Moving to Inkasso'));
    list.add(McProcessItemGENERATED.STATUS_CD_OUT_OF_DUNNING, McGod.t('Out of Dunning'));
    list.add(McProcessItemGENERATED.STATUS_CD_REMOVED, McGod.t('REMOVED'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McProcessItemGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McProcessItem {
    console.error('MTCN-ERROR: Not Implemented: McProcessItem::createNewInstance(). Add this method to final class and return new McProcessItem();');
    throw new Error('Cannot McProcessItem::createNewInstance(). Add this method to final class and return new McProcessItem();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcProcessItem): McProcessItem {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessItemService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McProcessItem AABB()');
          if (this._rawJson['mcProcessFlow']) {
        this.mcProcessFlow = McProcessFlow.createFromJson(this._rawJson['mcProcessFlow']);
      }
      if (this._rawJson['mcProcessFlowLevel']) {
        this.mcProcessFlowLevel = McProcessFlowLevel.createFromJson(this._rawJson['mcProcessFlowLevel']);
      }
      if (this._rawJson['pmTransaction']) {
        this.pmTransaction = PmTransaction.createFromJson(this._rawJson['pmTransaction']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getActionStartDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.actionStartDatetime);
    }

    public getActionStartDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.actionStartDatetime);
    }

    public getLastPaymentDueDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.lastPaymentDueDatetime);
    }

    public getLastPaymentDueDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.lastPaymentDueDatetime);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('mcProcessFlow')) {
        if (this.mcProcessFlow != null) {
            dto['mcProcessFlow'] = this.mcProcessFlow.toDto();
        } else {
            dto['mcProcessFlow'] = null;
        }
    }
    if (this.hasOwnProperty('mcProcessFlowLevel')) {
        if (this.mcProcessFlowLevel != null) {
            dto['mcProcessFlowLevel'] = this.mcProcessFlowLevel.toDto();
        } else {
            dto['mcProcessFlowLevel'] = null;
        }
    }
    if (this.hasOwnProperty('pmTransaction')) {
        if (this.pmTransaction != null) {
            dto['pmTransaction'] = this.pmTransaction.toDto();
        } else {
            dto['pmTransaction'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return McProcessItemGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdWAIT(): boolean {
    const result = (this.statusCd === McProcessItemGENERATED.STATUS_CD_WAIT);

    return result;
  }

 public  isStatusCdREADY(): boolean {
    const result = (this.statusCd === McProcessItemGENERATED.STATUS_CD_READY);

    return result;
  }

 public  isStatusCdMOVING_TO_INKASSO(): boolean {
    const result = (this.statusCd === McProcessItemGENERATED.STATUS_CD_MOVING_TO_INKASSO);

    return result;
  }

 public  isStatusCdOUT_OF_DUNNING(): boolean {
    const result = (this.statusCd === McProcessItemGENERATED.STATUS_CD_OUT_OF_DUNNING);

    return result;
  }

 public  isStatusCdREMOVED(): boolean {
    const result = (this.statusCd === McProcessItemGENERATED.STATUS_CD_REMOVED);

    return result;
  }


  
// ---------------------------------------------------------------------
public callChangeActionStartDatetime( actionStartDatetime: any, processItemIds: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.changeActionStartDatetime(actionStartDatetime,processItemIds).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

}
