export const ICON_INFO = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1895_15095)">
<path d="M19.9997 3.33325C10.7997 3.33325 3.33301 10.7999 3.33301 19.9999C3.33301 29.1999 10.7997 36.6666 19.9997 36.6666C29.1997 36.6666 36.6663 29.1999 36.6663 19.9999C36.6663 10.7999 29.1997 3.33325 19.9997 3.33325ZM19.9997 28.3333C19.083 28.3333 18.333 27.5833 18.333 26.6666V19.9999C18.333 19.0833 19.083 18.3333 19.9997 18.3333C20.9163 18.3333 21.6663 19.0833 21.6663 19.9999V26.6666C21.6663 27.5833 20.9163 28.3333 19.9997 28.3333ZM21.6663 14.9999H18.333V11.6666H21.6663V14.9999Z" fill="#5EABBE"/>
</g>
<defs>
<clipPath id="clip0_1895_15095">
<rect width="40" height="40" fill="white"/>
</clipPath>
</defs>
</svg>
`
