/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {CsrBankAccount} from '../models/csr-bank-account.model';
import {CsrBankAccountService} from '../services/csr-bank-account.service';
import {ICsrBankAccount} from '../_generated/csr-bank-account.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class CsrBankAccountGENERATED extends BaseObject {


  public apiService: CsrBankAccountService;
  public _rawJson: ICsrBankAccount;
    id: number = 0;
  archived: boolean;
  bankName = '';
  bic = '';
  flgConsumer360: boolean;
  flgPrimary: boolean;
  flgVerify: boolean;
  iban = '';
  idCsrConsumer: number;
  owner = '';
  sepaMandateDate: number;
  sepaMandateId = '';
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  sysUpdatedUserId: number;
  version: number;


  public properties: string[] = ['id', 'archived', 'bankName', 'bic', 'flgConsumer360', 'flgPrimary', 'flgVerify', 'iban', 'idCsrConsumer', 'owner', 'sepaMandateDate', 'sepaMandateId', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'version'];
  public propertiesRegular: string[] = ['id', 'archived', 'bankName', 'bic', 'csrConsumer', 'flgConsumer360', 'flgPrimary', 'flgVerify', 'iban', 'idCsrConsumer', 'owner', 'sepaMandateDate', 'sepaMandateId', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'version'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): CsrBankAccount {
    console.error('MTCN-ERROR: Not Implemented: CsrBankAccount::createNewInstance(). Add this method to final class and return new CsrBankAccount();');
    throw new Error('Cannot CsrBankAccount::createNewInstance(). Add this method to final class and return new CsrBankAccount();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: ICsrBankAccount): CsrBankAccount {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrBankAccountService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CsrBankAccount AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  

}
