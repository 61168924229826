import {Component, Inject, OnInit} from '@angular/core';
import {McBoolean, McForm, McGod} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-dev-bbm-page',
  templateUrl: './mc-dev-bbm-page.component.html',
})

export class McDevBbmPageComponent implements OnInit {
  mcForm = new McForm();
  tempValue: string = 'original';
  showMcb = new McBoolean();

  // -- Variables -------------------------------

  // ---------------------------------------------------------------------
  constructor() {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    console.log('ngOnInit21');
  }

  actChange1() {
    console.log('actChange1-21');
    this.showMcb.setTrue();
  }

  actChange2() {
    console.log('actChange2-21');
    this.showMcb.setFalse();
    this.tempValue = "actChange2";
    this.showMcb.setTrue();
  }

  actHide1() {
    console.log('actHide1-21');
    this.showMcb.setFalse();
  }

}
