import {McProcessFlowCriteriaAttributeFilterGENERATED} from '../_generated/mc-process-flow-criteria-attribute-filter-generated.model';

export class McProcessFlowCriteriaAttributeFilter extends McProcessFlowCriteriaAttributeFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessFlowCriteriaAttributeFilter();
  }

  // ---------------------------------------------------------------------
}
