import {
  Component,EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {BaseObjectList, BaseObject, Table2ColumnConfig, Table2Config} from '@miticon-ui/mc-core';
import {MccFiTable2CellEditConfigData} from './mcc-fi-table-2-cell-edit-config-data.model';
import {MccFiSelectOptionConfig} from '../mcc-fi-select-option/mcc-fi-select-option-config';
import {MccFiSelectOptionComponent} from '../mcc-fi-select-option/mcc-fi-select-option.component';
import {MccFiTable2CellEditedData} from './mcc-fi-table-2-cell-edited-data.model';


@Component({
  selector: 'mcc-fi-table-2',
  templateUrl: './mcc-fi-table-2.component.html',
})
export class MccFiTable2Component {

  private lastSelectedItemIndex: any;

  @ViewChild('container', {read: ViewContainerRef}) container: any;
  /*Events*/
  @Input() objectList!: BaseObjectList;
  @Input() table2Config = new Table2Config();
  @Output() eventTableCellEdited = new EventEmitter();
  @Output() eventTableCellEditConfig = new EventEmitter<MccFiTable2CellEditConfigData>();
  @Output() onSendStateToParent = new EventEmitter<any>();


  // ---------------------------------------------------------------------
  // ---------------------------------------------------------------------
  getCellValueFromObject(aPropertyOrMethodName: string, aItemObject: BaseObject) {
    return aItemObject.execPropertyOrMethodByName(aPropertyOrMethodName);
  }

  getDynamicComponentConfigObject(currentColumnConfig: Table2ColumnConfig, currentItemObj: BaseObject) {
    // console.log("-- getDynamicComponentConfigObject -----------------------------------");
    let config = null;

    if (currentColumnConfig.editComponentClass === MccFiSelectOptionComponent) {
      config = new MccFiSelectOptionConfig();
      // create unique config object for each instance using Template
      if (currentColumnConfig.editComponentTemplateConfigObj != null) {
        config = Object.assign(config, currentColumnConfig.editComponentTemplateConfigObj);
      }

      // set any configuration parameters
      config.name = currentColumnConfig.name + '-' + currentItemObj.id;
      // @ts-ignore
      config.selectedValue = currentItemObj[currentColumnConfig.editObjectPropertyOrMethodName];

      // console.log("-------");

      //  console.log(config.name);
      // console.log("selected_val_11: ", config.selectedValue);

      // set OUTPUT callbacks
      config.eventSelectionChangeCallback = (newValue: string) => {
        // automatically update object if bound
        if (currentColumnConfig.editObjectPropertyBoundFlg) {
          // @ts-ignore
          currentItemObj[currentColumnConfig.editObjectPropertyOrMethodName] = newValue;
        }

        const dataTableCellEdited = new MccFiTable2CellEditedData(currentItemObj, currentColumnConfig.editObjectPropertyOrMethodName, newValue);
        // fire event on the table for each column change
        this.eventTableCellEdited.emit(dataTableCellEdited);
      };

      if (currentColumnConfig.onEditComponentGetConfigObj != null) {
        config = currentColumnConfig.onEditComponentGetConfigObj(config, currentItemObj);
      }

      // OUTPUT Imploementation CAN change the values of these objects, but should only change CONFIG object
      const eventObj = new MccFiTable2CellEditConfigData(config, currentItemObj, currentColumnConfig);
      this.eventTableCellEditConfig.emit(eventObj);
    }

    /*Call custom method if it exists*/
    if (currentColumnConfig.customMethod) {
      currentColumnConfig.customMethod(currentItemObj, currentColumnConfig.editComponentTemplateConfigObj, this.objectList, this.table2Config);
    }

    // if (currentColumnConfig && currentColumnConfig.hasOwnProperty('editComponentTemplateConfigObj') && currentColumnConfig.editComponentTemplateConfigObj.hasOwnProperty('objectItem'))
    //   currentColumnConfig.editComponentTemplateConfigObj.objectItem = currentItemObj;

    return config || currentColumnConfig.editComponentTemplateConfigObj; // {optionsKeyValueMap: optionsKvm, selectedValue: , label: ""};
  }

  /*Filtered items to display*/
  public filteredItems() {
    return this.table2Config.columnObjectList.filter(item => !item.isHide);
  }

  /*On select items*/
  onSelectItem(event = null, itemIndex = null) {
    /*If user hold shift*/
    // @ts-ignore
    if (event && event.shiftKey) {
      this.objectList.items.map((item, index) => {

        /*If user is going down on selecting*/
        // @ts-ignore
        if (itemIndex > this.lastSelectedItemIndex && index > this.lastSelectedItemIndex && index < itemIndex) {
          item.select();
        }

        /*If user going upper*/
        // @ts-ignore
        if (itemIndex < this.lastSelectedItemIndex && index < this.lastSelectedItemIndex && index > itemIndex) {
          item.select();
        }
      });
    }

    const selectedItems = this.objectList.items.filter(item => item.selectedFlg);
    this.lastSelectedItemIndex = itemIndex != null && this.objectList.items[itemIndex].selectedFlg ? itemIndex : this.lastSelectedItemIndex;
    this.onSendStateToParent.emit(selectedItems);
  }

  /*On table cell click*/
  public onCellClick(currentColumnConfig: Table2ColumnConfig, currentItemObj: any) {
    /*Use custom click*/
    if (currentColumnConfig.clickEvent) { currentColumnConfig.clickEvent(currentItemObj, currentColumnConfig); }

    /*Use service state bridge*/
    if (currentColumnConfig.isUsingServiceBridge) { currentColumnConfig.configureServiceBridge(currentItemObj); }
  }

  /*On sorting*/
  public onSortColumn(sortLabel: string, sortWay: string) {
    this.objectList.items.sort((a, b) => {
      /*If property exists and has value*/
      // @ts-ignore
      if (a[sortLabel] && b[sortLabel]) {
        if (sortWay === 'asc') {
          // @ts-ignore
          if (isNaN(a[sortLabel])) {
            // @ts-ignore
            return a[sortLabel].localeCompare(b[sortLabel]);
          } else {
            // @ts-ignore
            return a[sortLabel] - b[sortLabel];
          }
        }
        if (sortWay === 'desc') {
          // @ts-ignore
          if (isNaN(b[sortLabel])) {
            // @ts-ignore
            return b[sortLabel].localeCompare(a[sortLabel]);
          } else {
            // @ts-ignore
            return b[sortLabel] - a[sortLabel];
          }
        }
      }
    });
  }

  public onCellOnInit(currentColumnConfig: Table2ColumnConfig, currentItemObj: any) {
    if (currentColumnConfig.isUsingOnInit) { currentColumnConfig.configureServiceBridge(currentItemObj); }
  }
}
