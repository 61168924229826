export const ICON_IN_PROGRESS = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1384_25608)">
<path d="M11.0007 8.75073V12.4307C11.0007 12.7807 11.1907 13.1107 11.4907 13.2907L14.6107 15.1407C14.9707 15.3507 15.4307 15.2307 15.6407 14.8807C15.8507 14.5207 15.7407 14.0607 15.3807 13.8507L12.5107 12.1407V8.74073C12.5007 8.34073 12.1607 8.00073 11.7507 8.00073C11.3407 8.00073 11.0007 8.34073 11.0007 8.75073ZM21.0007 9.50073V4.21073C21.0007 3.76073 20.4607 3.54073 20.1507 3.86073L18.3707 5.64073C16.5607 3.83073 13.9807 2.79073 11.1607 3.04073C6.97074 3.42073 3.52074 6.79073 3.06074 10.9807C2.46074 16.4007 6.69074 21.0007 12.0007 21.0007C16.5907 21.0007 20.3807 17.5607 20.9307 13.1207C21.0007 12.5207 20.5307 12.0007 19.9307 12.0007C19.4307 12.0007 19.0107 12.3707 18.9507 12.8607C18.5207 16.3507 15.5107 19.0507 11.9007 19.0007C8.19074 18.9507 5.06074 15.8207 5.00074 12.1007C4.94074 8.20073 8.11074 5.00073 12.0007 5.00073C13.9307 5.00073 15.6807 5.79073 16.9507 7.05073L14.8607 9.14073C14.5407 9.46073 14.7607 10.0007 15.2107 10.0007H20.5007C20.7807 10.0007 21.0007 9.78073 21.0007 9.50073Z" fill="#042F4D"/>
</g>
<defs>
<clipPath id="clip0_1384_25608">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>`
