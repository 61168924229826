import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({

  selector: 'mc-input-field',
  templateUrl: './mc-input-field.component.html'
})
export class McInputFieldComponent {

  /*Events*/
  @Input() setup!: any;
  @Input() config!: any;
  @Output() newSetup = new EventEmitter();

  /*On key up input field*/
  public onKeyUp(event: any): void {
    this.setup.value = event.target.value;
    this.newSetup.emit(this.setup);
  }
}
