import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {McUtilityService} from './mc-utility.service';

@Injectable({
  providedIn: 'root'
})
export class McPermissionService {

  /*Private variables*/
  public _allPermissions: any;
  private apiUrl: string;
  private httpOptions;

  /*Constructor*/
  constructor(private httpClient: HttpClient,
              private utilityService: McUtilityService,
              @Inject('environment') environment: any) {

    /*Set API base url and header*/
    this.apiUrl = environment.apiUrl;
    this.httpOptions = environment.httpOptions(HttpHeaders);
  }

  /*Get all permissions from DB*/
  public getAllPermissionsByModuleFromBackend() {
    return this.httpClient.get<any>(this.apiUrl + `/permissions/all/modules`, this.httpOptions);
  }

  public hasUserPermission(permission: any): boolean {
    const userPermissions = this.utilityService.getAuthoritiesFromStorage();

    if (userPermissions && permission) {
      return userPermissions.some((item: any) => item === permission);
    }

    return false;
  }

}
