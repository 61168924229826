/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McMapping} from '../models/mc-mapping.model';
import {McMappingService} from '../services/mc-mapping.service';
import {IMcMapping} from '../_generated/mc-mapping.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {McMappingItemList} from '../models/mc-mapping-item-list.model';


export abstract class McMappingGENERATED extends BaseObject {


  public apiService: McMappingService;
  public _rawJson: IMcMapping;
    id: number = 0;
  creationDate: number;
  idMcEntity: number;
  idMcSystemUser: number;
  idWebFile: number;
  mappingName = '';
  sharedFlg: boolean;
  sharingDate: number;
  createdByUserName: string;  mappingItems: McMappingItemList;  sharedByEntityName: string;  sharedByUserName: string;

  public properties: string[] = ['id', 'creationDate', 'idMcEntity', 'idMcSystemUser', 'idWebFile', 'mappingName', 'sharedFlg', 'sharingDate', 'createdByUserName', 'mappingItems', 'sharedByEntityName', 'sharedByUserName'];
  public propertiesRegular: string[] = ['id', 'creationDate', 'idMcEntity', 'idMcSystemUser', 'idWebFile', 'mappingName', 'sharedFlg', 'sharingDate', 'createdByUserName', 'sharedByEntityName', 'sharedByUserName'];
  public propertiesSpecial: string[] = ['mappingItems'];


  // ---------------------------------------------------------------------
public static createNewInstance(): McMapping {
    console.error('MTCN-ERROR: Not Implemented: McMapping::createNewInstance(). Add this method to final class and return new McMapping();');
    throw new Error('Cannot McMapping::createNewInstance(). Add this method to final class and return new McMapping();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcMapping): McMapping {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McMappingService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McMapping AABB()');
          if (this._rawJson['mappingItems']) {
        this.mappingItems = McMappingItemList.createFromJson(this._rawJson['mappingItems']);
      }

  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('mappingItems')) {
        if (this.mappingItems != null) {
            dto['mappingItems'] = this.mappingItems.toDto();
        } else {
            dto['mappingItems'] = [];
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
  // ---------------------------------------------------------------------
  public loadByWebFileId( idWebFile: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByWebFileId(idWebFile)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

// ---------------------------------------------------------------------
public callCopyMappingWithMappingItems( idMcMapping: number, idMcSystemUser: number, idMcEntity: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.copyMappingWithMappingItems(idMcMapping,idMcSystemUser,idMcEntity).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

}
