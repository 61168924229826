import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ICmnEmail} from '../_generated/cmn-email.interface';
import {CmnEmail} from '../models/cmn-email.model';
import {CmnEmailList} from '../models/cmn-email-list.model';
import {CmnEmailServiceGENERATED} from '../_generated/cmn-email-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CmnEmailService extends CmnEmailServiceGENERATED {

}
