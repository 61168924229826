import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {MkFilterOutput, MkTableConfig} from '@miticon-ui/mc-components';
import {
  BaseDirective,
  McBillingEntitiesUsingPricelistList,
  McGod,
  McPriceList,
  McPriceList2EntityService,
  SortCriteriaList
} from '@miticon-ui/mc-core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mc-pricelist-pricelist-entities-using-pricelist-part',
  templateUrl: './mc-pricelist-pricelist-entities-using-pricelist-part.component.html',
})
export class McPricelistPricelistEntitiesUsingPricelistPartComponent extends BaseDirective implements OnInit {
  @Input() mcPriceList = new McPriceList();
  @Output() eventClose = new EventEmitter();
  mcEntitiesUsingPricelist: McBillingEntitiesUsingPricelistList = new McBillingEntitiesUsingPricelistList();
  tableConfig = new MkTableConfig();
  isLoading = false;
  infoMsg: any;

  constructor(private mcPricelistMcEntity2Service: McPriceList2EntityService) {
    super();
  }

  ngOnInit() {
    this.actLoad(0, 100);
    this.initTableConfig();
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('cc.factoring.transactions.entity-name'), 'entityName');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.entity-id'), 'entityId');
    this.tableConfig.addColumnStandard(McGod.t('cc.pricelist.using-pricelist-from'), 'getFromDate()');
  }

  actLoad(pageNumber: number, pageSize: number) {
    this.isLoading = true;
    this.mcEntitiesUsingPricelist.pageNum = pageNumber;
    this.mcEntitiesUsingPricelist.pageItemsPerPageCount = pageSize;
    this.mcPricelistMcEntity2Service
      .getAllEntitiesUsingPriceList(this.mcPriceList.id, pageNumber, pageSize, new SortCriteriaList())
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.mcEntitiesUsingPricelist.totalItemsCount = res.totalElements;
          this.mcEntitiesUsingPricelist.populateFromJsonPageable(res);
          this.mcEntitiesUsingPricelist.items.forEach(item => {
            res.content.map((resItem: any) => {
              if (item.id === resItem.id) {
                item.entityId = resItem.idMcEntity;
                item.startDate = resItem.validFrom;
              }
            });
          });
          this.isLoading = false;
        },
        (err) => {
          this.infoMsg = err.error.message;
        }
      );
  }

  actClose() {
    this.eventClose.emit();
  }

  onFilterChanged(filters: MkFilterOutput) {
    console.log(filters);
    this.mcEntitiesUsingPricelist.setSortB(filters.sort.sortProperty, filters.sort.sortType);
    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize);
  }
}
