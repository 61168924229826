import {McBillingItemFilterGENERATED} from '../_generated/mc-billing-item-filter-generated.model';

export class McBillingItemFilter extends McBillingItemFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingItemFilter();
  }

  // ---------------------------------------------------------------------
}
