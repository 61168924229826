<div class="content-wrapper">
  <lib-mc-loader
    [showLoader]="mcBillingStatement.apiLoadingFlg ||
    mcBillingStatementItemList.apiLoadingFlg ||
    mcBillingItem.apiLoadingFlg">
  </lib-mc-loader>

  <app-shared-go-back icon="fas fa-arrow-left" [title]="'cc.statement.back-to-statements-list' | translate"></app-shared-go-back>


  <div class="actions-container mb-3">
    <div class="d-flex align-items-center">
      <div class="actions-title mr-3">
        <span>{{mcBillingStatement.title}} {{'cc.billing.details' | translate}}</span>
      </div>
    </div>

    <div ngbDropdown class="actions-wrapper">
      <button ngbDropdownToggle class="system-action-btn dark-system-action-btn">{{'cc.billing.actions' | translate}}</button>
      <div class="dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="onActionsDropdownClick('AUTO_IMPORT')"
                [ngClass]="{'mc-disable' : isActionButtonDisabled('AUTO_IMPORT')}"
                *ngIf="mcGod.userHasMcBillingOutStatementAutoImportBillingItemsPermission()"><i
          class="fas fa-file-import"></i>{{'cc.billing.auto-import-billing-item' | translate}}
        </button>
        <button ngbDropdownItem (click)="onActionsDropdownClick('FINALIZE')"
                [ngClass]="{'mc-disable' : isActionButtonDisabled('FINALIZE')}"
                *ngIf="mcGod.userHasMcBillingOutStatementFinalizePermission()"><i
          class="fas fa-clipboard-check"></i>{{'cc.billing.finalize-statement' | translate}}
        </button>
        <button ngbDropdownItem (click)="onActionsDropdownClick('RENAME')"
                [ngClass]="{'mc-disable' : isActionButtonDisabled('RENAME')}"
                *ngIf="mcGod.userHasMcBillingOutStatementEditPermission()"><i
          class="fas fa-pencil-alt"></i>{{'cc.statement.rename-statement' | translate}}
        </button>
        <button ngbDropdownItem (click)="onActionsDropdownClick('HIDE')"
                *ngIf="mcGod.userHasMcBillingOutBillingHideUnhideStatementPermission()"><i
          [ngClass]="!mcBillingStatement.flgHidden ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>{{!mcBillingStatement.flgHidden ? mcGod.t('cc.statement.hide') : mcGod.t('cc.statement.unhide')}}
        </button>
        <button ngbDropdownItem (click)="onActionsDropdownClick('PREVIEW_PDF')"
                [ngClass]="{'mc-disable' : isActionButtonDisabled('PREVIEW_PDF')}"
                *ngIf="mcGod.userHasMcBillingOutStatementPreviewPdfPermission()"><i
          class="fas fa-file-pdf"></i>{{'cc.billing.preview-pdf' | translate}}
        </button>
        <button ngbDropdownItem (click)="onActionsDropdownClick('DOWNLOAD_PDF')"
                [ngClass]="{'mc-disable' : isActionButtonDisabled('DOWNLOAD_PDF')}"
                *ngIf="mcGod.userHasMcBillingOutStatementDownloadPdfPermission()"><i
          class="fas fa-file-pdf"></i>{{'cc.billing.download-pdf' | translate}}
        </button>
        <button ngbDropdownItem (click)="onActionsDropdownClick('DOWNLOAD_XLS')"
                [ngClass]="{'mc-disable' : isActionButtonDisabled('DOWNLOAD_XLS')}"
                *ngIf="mcGod.userHasMcBillingOutStatementDownloadXlsPermission()"><i
          class="fas fa-file-excel"></i>{{'cc.billing.download-xls' | translate}}
        </button>
        <button *ngIf="mcGod.userHasMcBillingOutStatementEditPermission() && (mcBillingStatement.isStatusCdCOMPLETED() || mcBillingStatement.isStatusCdFINALIZED())"
                ngbDropdownItem (click)="onActionsDropdownClick('REGENERATE_PDF_AND_XLS')">
          <i class="fas fa-file-alt"></i>{{'cc.billing.regenerate-pdf-and-xls' | translate}}
        </button>
      </div>
    </div>
  </div>

  <div class="card-wrapper">
    <app-shared-card showHeader="false">
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.factoring.transactions.entity-name' | translate}}:"
                                 [description]="mcBillingStatement.childEntityName"></mcc-row-title-description>
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.address' | translate}}:"
                                 [descriptionInnerHtml]="entityAddress"></mcc-row-title-description>
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.billing.entity-id' | translate}}:"
                                 [description]="mcBillingStatement.childEntityId"></mcc-row-title-description>
    </app-shared-card>
    <app-shared-card showHeader="false">
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.billing.statements-no' | translate}}:"
                                 [description]="mcBillingStatement.statementNumber"></mcc-row-title-description>
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.billing.statement-date' | translate}}:"
                                 [description]="mcBillingStatement.getStatementDate()"></mcc-row-title-description>
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.view.status' | translate}}:"
                                 [description]="mcBillingStatement.getStatusCdLabel()"></mcc-row-title-description>
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.billing.generated-file-name' | translate}}:"
                                 [description]="webFileName"></mcc-row-title-description>
      <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.statement.hidden' | translate}}:"
                                 [description]="mcBillingStatement.flgHidden ? 'Yes' : 'No'"></mcc-row-title-description>
    </app-shared-card>
  </div>

  <div class="actions-container mt-4">
      <span class="pl-2"> {{'cc.billing.statement-items' | translate}}</span>
    <div class="actions-wrapper">
      <button class="system-action-btn mr-2" *ngIf="mcGod.userHasMcBillingOutStatementAddCorrectionPermission()"
              [ngClass]="{'system-action-btn-disabled': mcBillingStatement.isStatusCdCOMPLETED() || mcBillingStatement.isStatusCdFINALIZED()}"
              (click)="actShowAddCorrection()">
        <i class="fas fa-plus-circle"></i>{{'cc.statement.add-correction' | translate}}
      </button>
      <button *ngIf="mcGod.userHasMcBillingOutStatementAddItemsPermission()" class="system-action-btn-disabled"
              [disabled]="true"
              (click)="actShowAddItems()"><i class="fas fa-plus-circle"></i> {{'cc.statement.add-items' | translate}}
      </button>
    </div>
  </div>

  <mk-mat-table
    [isLoading]="isLoading"
    [items]="mcBillingStatementItemList.items"
    [config]="tableConfig"
    [mkMatMenuActionItems]="mkMatMenuActionItems"
    (actionMkMatMenuItems)="onMkMatMenuItemsAction($event)"
    (filterChanged)="onFilterChanged($event)"
    [totalItemsCount]="mcBillingStatementItemList.totalItemsCount"
    [itemsPerPage]="mcBillingStatementItemList.pageItemsPerPageCount">
  </mk-mat-table>

  <div class="content-body mb-3">

    <mcc-message [errorMsg]="errorMsg" [successMsg]="successMsg"></mcc-message>


    <div class="row">
      <div class="col-md-12" *ngIf="mcBillingStatementItemList.apiSuccessFlg">

        <div class="total-row mt-2">
          <div class="main-label">
            <h5>Total fees:</h5>
          </div>
          <div class="sum-total">{{amountWithVatSum}}</div>
        </div>
      </div>
    </div>

    <div class="container-fluid standout mt-5">
      <div class="row">

        <div class="col-md-12 pt-3 d-flex">
          <h4><img src="assets/images/info-square.svg" alt=""> {{'cc.billing.processed-transactions-info' | translate}}
          </h4>
        </div>
      </div>
      <hr>

      <ng-container *ngIf="mcBillingBatchSnapshotList.items">
        <div *ngFor="let item of mcBillingBatchSnapshotList.items" class="row">
          <div class="table-title ml-3"><h2>{{item.batchHeader}}</h2></div>

          <!--blue header-->
          <div class="col-12">
            <div class="row blue-batch-header">
              <div class="col-md-5 d-flex">
                <span>{{'cc.common.view.description' | translate}}</span>
              </div>
              <div class="col-md-3 d-flex">
                <span class="ml-auto">%</span>
              </div>
              <div class="col-md-2 d-flex">
                <span class="ml-auto">{{'cc.billing.initial-sum' | translate}}</span>
              </div>
              <div class="col-md-2 d-flex">
                <span class="ml-auto">{{'cc.billing.final-sum' | translate}}</span>
              </div>
            </div>
          </div>


          <div class="batch-table col-md-12 pt-2 mb-3"> <!--------------------- TABLE --------------------------------->


            <!--<div class="row table-header">  DO NOT DELETE THIS !!!! REQUIREMENTS MAY CHANGE AGAIN !!!
              <div class="col-md-5 d-flex">
                <span>{{'cc.common.view.description' | translate}}</span>
              </div>
              <div class="col-md-3 d-flex">
                <span class="ml-auto">%</span>
              </div>
              <div class="col-md-2 d-flex">
                <span class="ml-auto">{{'cc.billing.initial-sum' | translate}}</span>
              </div>
              <div class="col-md-2 d-flex">
                <span class="ml-auto">{{'cc.billing.final-sum' | translate}}</span>
              </div>
            </div>-->


            <!-- Acceptance-->
            <div class="row">
              <div class="col-md-12 d-flex">

                <h3>{{'cc.statement.acceptance' | translate}}</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-md-5 d-flex">
                <h4>{{(item.batchHeader.toLowerCase().includes('dunning') || item.batchHeader.toLowerCase().includes('mahnung')) ? ('cc.my-factoring.accepted-for-dunning' | translate) : item.flgFactoring ? ('cc.my-factoring.accepted-for-factoring' | translate) : ('cc.billing.accepted-for-service' | translate)}}</h4>
              </div>
              <div class="col-md-3 d-flex">
                <span class="ml-auto">{{item.acceptedForSFColumnPercentage.toFixed(2).replace('.', ',')}} %</span>
              </div>
              <div class="col-md-2 d-flex">
                <span class="ml-auto">{{format(item.acceptedForSFColumnInitialSum)}}</span>
              </div>
              <div class="col-md-2 d-flex">
              <span
                class="ml-auto">{{format(item.acceptedForSFColumnFinalSum)}}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-md-5 d-flex">
                <h4>{{(item.batchHeader.toLowerCase().includes('dunning') || item.batchHeader.toLowerCase().includes('mahnung')) ? ('cc.my-factoring.not-accepted-for-dunning' | translate) : item.flgFactoring ? ('cc.my-factoring.not-accepted-for-factoring' | translate) : ('cc.billing.not-accepted-for-service' | translate)}}</h4>
              </div>
              <div class="col-md-3 d-flex">
                <span class="ml-auto">{{item.notAcceptedForSFColumnPercentage.toFixed(2).replace('.', ',')}} %</span>
              </div>
              <div class="col-md-2 d-flex">
                <span class="ml-auto">{{format(item.notAcceptedForSFColumnInitialSum)}}</span>
              </div>
              <div class="col-md-2 d-flex">
              <span
                class="ml-auto">{{format(item.notAcceptedForSFColumnFinalSum)}}</span>
              </div>
            </div>
          </div>


          <!--past-->
          <!-- Past statements-->
          <div class="batch-table col-md-12 pt-2 mb-3">

            <div class="row">
              <div class="col-md-12 d-flex">
                <h3>{{'cc.statement.past-statements' | translate}}</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-md-5 d-flex">
                <h4>{{'cc.statement.already-paid-balance' | translate}}</h4>
              </div>

              <div class="col-md-5 d-flex">
                <span class="ml-auto">{{format(item.alreadyPaidBalanceColumnInitialSum)}}</span>
              </div>
              <div class="col-md-2 d-flex">
              <span
                class="ml-auto">{{format(item.alreadyPaidBalanceColumnFinalSum)}}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-md-5 d-flex">
                <h4>{{'cc.statement.already-returned-balance-all-statements' | translate}}</h4>
              </div>

              <div class="col-md-5 d-flex">
                <span
                  class="ml-auto">{{format(item.alreadyReturnedBalanceColumnInitialSum)}}</span>
              </div>
              <div class="col-md-2 d-flex">
                <span
                  class="ml-auto">- {{format(item.alreadyReturnedBalanceColumnFinalSum)}}</span>
              </div>
            </div>

<!--            <div class="row">-->
<!--              <div class="col-md-5 d-flex">-->
<!--                <h4>{{'cc.statement.already-paid-from-consumer-balance' | translate}}</h4>-->
<!--              </div>-->
<!--              <div class="col-md-5 d-flex">-->
<!--                <span class="ml-auto">{{format(item.alreadyPaidFromConsumerBalanceColumnInitialSum)}}</span>-->
<!--              </div>-->
<!--              <div class="col-md-2 d-flex">-->
<!--              <span-->
<!--                class="ml-auto">{{format(item.alreadyPaidFromConsumerBalanceColumnFinalSum)}}</span>-->
<!--              </div>-->
<!--            </div>-->

          </div>

          <div class="batch-table col-md-12 pt-2 mb-3">
            <div class="row">
              <div class="col-md-12 d-flex">
                <h3>{{mcGod.t('cc.common.present-statement')}}</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-md-5 d-flex">
                <h4>{{'cc.my-factoring.immediately-payable-balance' | translate}}</h4>
              </div>
              <div class="col-md-5 d-flex">
                <span
                  class="ml-auto">{{format(item.immediatelyPayableBalanceColumnInitialSum)}}</span>
              </div>
              <div class="col-md-2 d-flex">
              <span
                class="ml-auto">{{format(item.immediatelyPayableBalanceColumnFinalSum)}}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-md-5 d-flex">
                <h4>{{'cc.statement.returning-amount-soft-and-or-hard-bounce' | translate}}</h4>
              </div>
              <div class="col-md-5 d-flex">
                <span class="ml-auto">{{format(item.returningAmountFinalBounceColumnInitialSum)}}</span>
              </div>
              <div class="col-md-2 d-flex">
              <span
                class="ml-auto">- {{format(item.returningAmountFinalBounceColumnFinalSum)}}</span>
              </div>
            </div>

<!--            <div class="row">-->
<!--              <div class="col-md-5 d-flex">-->
<!--                <h4>{{'cc.statement.immediately-payable-consumer-balance' | translate}}</h4>-->
<!--              </div>-->
<!--              <div class="col-md-5 d-flex">-->
<!--                <span-->
<!--                  class="ml-auto">{{format(item.immediatelyPayableConsumerBalanceColumnInitialSum)}}</span>-->
<!--              </div>-->
<!--              <div class="col-md-2 d-flex">-->
<!--              <span-->
<!--                class="ml-auto">{{format(item.immediatelyPayableConsumerBalanceColumnFinalSum)}}</span>-->
<!--              </div>-->
<!--            </div>-->

          </div>
          <div class="batch-table col-md-12 pt-2 mb-3">
            <div class="row">
              <div class="col-md-12 d-flex">
                <h3>{{'cc.statement.future-statement' | translate}}</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-md-5 d-flex">
                <h4>{{'cc.my-factoring.later-payable-balance' | translate}}</h4>
              </div>
              <div class="col-md-5 d-flex">
                <span class="ml-auto">{{format(item.laterPayableBalanceColumnInitialSum)}}</span>
              </div>
              <div class="col-md-2 d-flex">
              <span
                class="ml-auto">{{format(item.laterPayableBalanceColumnFinalSum)}}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>


    </div>


    <div class="container-fluid standout mt-5">
      <div class="row pt-3 pl-3">
        <h4><i class="fas fa-coins mc-submit-color"></i> {{'cc.billing.payout-balance' | translate}}</h4>
      </div>
      <hr>
      <div class="row">
        <div class="table-template col-md-12"> <!--------------------- PAYOUT TABLE --------------------------------->

          <div class="mr-3 ml-3">
            <div class="row table-header">
              <div class="col-md-3 d-flex">
                <span>{{'cc.common.view.description' | translate}}</span>
              </div>
              <div class="col-md-3 d-flex">
                <span class="ml-auto">{{'cc.billing.batch' | translate}}</span>
              </div>
              <div class="col-md-3 d-flex">
                <span class="ml-auto">{{'cc.billing.initial-sum' | translate}}</span>
              </div>
              <div class="col-md-3 d-flex">
                <span class="ml-auto">{{'cc.billing.final-sum' | translate}}</span>
              </div>
            </div>

            <ng-container *ngIf="mcBillingStatement.payoutTableItems">
              <div *ngFor="let item of mcBillingStatement.payoutTableItems.items" class="row">
                <div class="col-md-3 d-flex">
                  <span>{{item.description}}</span>
                </div>
                <div class="col-md-3 d-flex">
                  <span class="ml-auto">{{item.batch}}</span>
                </div>
                <div class="col-md-3 d-flex">
                  <span class="ml-auto">{{format(item.initialSum)}}</span>
                </div>
                <div class="col-md-3 d-flex">
                  <span class="ml-auto">{{item.flgToAdd ? '' : '- '}}{{format(item.finalSum)}}</span>
                </div>
              </div>
            </ng-container>
            <div class="row">
              <div class="col-md-12 d-flex w-100">
                <span>{{'cc.billing.total-fees' | translate}}</span>
                <span class="ml-auto">{{(amountWithVatSum).includes('-') ? amountWithVatSum.substring(1) : '-' + amountWithVatSum}}</span>
              </div>
            </div>

            <div class="row table-footer2">
              <div class="col-md-12 d-flex w-100">
                <span>{{'cc.billing.total-payout' | translate}}</span>
                <span class="ml-auto">{{format(mcBillingStatement.totalPayout)}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mcc-modal1-wrapper *ngIf="showAggregateDeaggreagateMcb.value" [showMcb]="showAggregateDeaggreagateMcb"
                        modalSizeCd="small" (eventCanceled)="onAggregateDeaggregateCancelEmit()">
      <mc-out-billing-aggregate-deaggregate-part [mcBillingStatementItem]="mcBillingStatementItem"
                                                 [mcBillingStatementItemList]="selectedMcBillingStatementItemList"
                                                 (eventSaved)="onAggregateDeaggregateSaveEmit($event)"
                                                 (eventCanceled)="onAggregateDeaggregateCancelEmit()"></mc-out-billing-aggregate-deaggregate-part>
    </mcc-modal1-wrapper>

    <mcc-modal1-wrapper *ngIf="showAddItemsMcb.value" [showMcb]="showAddItemsMcb"
                        (eventCanceled)="onAddItemsCancelEmit()">
      <mc-out-billing-add-statement-items-part (eventCanceled)="onAddItemsCancelEmit()"
                                               [mcBillingStatement]="mcBillingStatement"
                                               [mcBillingStatementId]="mcBillingStatement.id"
                                               (eventSaved)="onAddItemsSaveEmit()"></mc-out-billing-add-statement-items-part>
    </mcc-modal1-wrapper>

    <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showRemoveFromStatementMcb.value"
                        [showMcb]="showRemoveFromStatementMcb" (eventCanceled)="onRemoveItemsCancelEmit()">
      <mc-out-billing-remove-from-statement-part [mcBillingStatementItem]="mcBillingStatementItem"
                                                 [mcBillingStatement]="mcBillingStatement"
                                                 (eventSaved)="onRemoveItemsSaveEmit()"
                                                 (eventCanceled)="onRemoveItemsCancelEmit()"></mc-out-billing-remove-from-statement-part>
    </mcc-modal1-wrapper>

    <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showAddCorrectionMcb.value && factoring" [showMcb]="showAddCorrectionMcb"
                        (eventCanceled)="onAddCorrectionCancelEmit()">
      <mc-out-billing-add-correction-part [statementId]="mcBillingStatement.id"
                                          [currencySymbol]="factoring.mcCurrency.code"
                                          [mcStatementItem]="mcBillingStatementItem"
                                          (eventSaved)="onAddCorrectionSaveEmit()"
                                          (eventCanceled)="onAddCorrectionCancelEmit()"></mc-out-billing-add-correction-part>
    </mcc-modal1-wrapper>

    <mcc-modal1-wrapper modalSizeCd="medium" *ngIf="showFinalizeStatementMcb.value" [showMcb]="showFinalizeStatementMcb"
                        (eventCanceled)="onFinalizeStatementCancelEmit()">
      <mc-out-billing-finalize-statement-part [mcStatement]="mcBillingStatement"
                                              (eventCanceled)="onFinalizeStatementCancelEmit()"
                                              (eventSaved)="onFinalizeStatementSaveEmit()"></mc-out-billing-finalize-statement-part>

    </mcc-modal1-wrapper>

    <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showRenameStatementMcb.value" [showMcb]="showRenameStatementMcb"
                        heightAutoFlg="true" (eventCanceled)="onRenameStatementCancelEmit()">
      <mc-out-billing-rename-statement-part [mcStatement]="mcBillingStatement"
                                            (eventSaved)="onRenameStatementSaveEmit()"
                                            (eventCanceled)="onRenameStatementCancelEmit()"></mc-out-billing-rename-statement-part>
    </mcc-modal1-wrapper>

    <mcc-dialog-box *ngIf="shotDeleteCorrectionMcb.value" [enableCloseButtonFlg]="false"
                    title="Delete correction" name="mar-as-sent-to-inkasso"
                    [description]="'Are you sure you want to delete correction'"
                    [buttonsKvm]="buttonsDeleteCorrectionDialog"
                    popupType="question"
                    (eventActionButtonClicked)="onMcDialogBoxDeleteCorrectionActionButtonClicked($event)"></mcc-dialog-box>


<!--    <mcc-slide-action-set [actionSet]="mcSlideActionSet" [selectedNumber]="numberOfSelectedItems"
                          (eventActionClicked)="slideActionItemClicked($event)"></mcc-slide-action-set>-->

  </div>

</div>
<router-outlet></router-outlet>
