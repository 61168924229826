export const utility = {
  alphabet: ('ABCDEFGHIJKLMNOPQRSTUVWXYZ'),
  customAttributeTypes: [
    {type: 'INTEGER', name: 'Number'},
    {type: 'VARCHAR', name: 'String'},
    {type: 'DATE', name: 'Date'},
    {type: 'DATETIME', name: 'Date Time'},
    {type: 'FLOAT', name: 'Decimal'},
    {type: 'BOOLEAN', name: 'Yes/No'}
  ],
  schedulerModule: {
    actionList:  [
      // TODO: Uncomment when the issue with manually started jobs is resolved
      // 'run',
      'pause',
      'resume',
      'unschedule'
    ]
  },
  languages: [
    {
      name: 'English',
      abbreviation: 'en',
      default: true,
    },
    {
      name: 'Deutsch',
      abbreviation: 'de'
    },
    {
      name: 'Español',
      abbreviation: 'es'
    },
    {
      name: 'Czech',
      abbreviation: 'cs'
    },
    {
      name: 'French',
      abbreviation: 'fr'
    },
    {
      name: 'Italian',
      abbreviation: 'it'
    },
    {
      name: 'Dutch',
      abbreviation: 'nl'
    },
    {
      name: 'Polish',
      abbreviation: 'pl'
    },
    {
      name: 'Slovak',
      abbreviation: 'sk'
    },
    {
      name: 'Swedish',
      abbreviation: 'sv'
    }
  ],

  /*TIME ZONE todo: need define solution handling BE-FE*/
  timeZone: {
    europeBerlin: 'Europe/Berlin'
  }
};
