<div class="row p-0 m-0 d-flex flex-row align-items-center">
  <h3 class="pr-2 pt-1">{{'cc.common.starmoney-list' | translate}}</h3>
</div>

<div>
    <lib-mc-loader [showLoader]="ebSepaStatementReportList.apiLoadingFlg"></lib-mc-loader>

    <div *ngIf="ebSepaStatementReportList.apiErrorFlg">
        Error: {{ebSepaStatementReportList.apiErrorMessage}}
    </div>

  <div class="mc-filter fancy-label">

    <i class="fas fa-info-circle mc-tooltip-top-margin" ngbTooltip="Search by: File name"
       placement="bottom-left"></i>

      <mcc-fi-textbox [isClearEnabled]="true" label="Search" name="txbSearchTerm" [mcForm]="mcForm" (eventEnterClicked)="actLoad()"></mcc-fi-textbox>

    <mcc-fi-select [mcForm]="mcForm" name="selImportedBy" placeholderOption="-- all" label="Imported By"
                   [optionsVll]="importUsersVll"></mcc-fi-select>

    <button class="mc-link mc-filter-clear-button filter-clear-top-right" (click)="actClearFilter()">Clear all</button>
    <button class="mc-button mc-filter-button" (click)="actLoad()">Filter</button>

  </div>

    <!--  <a class="col-md-1 offset-md-11" routerLink="edit/0">Add New</a>-->

    <div class="col-md-12 pt-3 px-0 d-flex flex-row justify-content-end">
      <a class="system-action-btn mt-2" (click)="openImportStarMoneyModal()">
        <i class="fas fa-plus-circle mc-add-new-user-icon"></i>{{'cc.starmoney.import-star-money-file' | translate}}</a>
    </div>
    <mcc-modal1-wrapper modalSizeCd="small" [showMcb]="showImportStarmoneyMcb" *ngIf="showImportStarmoneyMcb.value">
      <mc-bank-module-import-star-money-part (eventSaved)="actStarMoneyImported()" (eventCanceled)="actCancel()"></mc-bank-module-import-star-money-part>
    </mcc-modal1-wrapper>

  <mcc-message [successMsg]="successMsg"></mcc-message>

    <div *ngIf="ebSepaStatementReportList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="ebSepaStatementReportList"
                        [table3Config]="ebSepaStatementReportTable3Config"
                        (eventPaginationOrSortChanged)="actLoad()"></mcc-fi-table-3>
    </div>

</div>

<router-outlet></router-outlet>

