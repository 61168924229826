import {McVatFilterGENERATED} from '../_generated/mc-vat-filter-generated.model';

export class McVatFilter extends McVatFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McVatFilter();
  }

  // ---------------------------------------------------------------------
}
