<ng-template #tableSettings let-modal>
  <div>
    <div class="modal-header">
      <h4 class="modal-title text-center" id="modal-basic-title1">{{ 'cc.consumers.view.table-settings' | translate
        }}</h4>
      <button type="button" class="close m-0 p-0" aria-label="Close" (click)="!tableSetupConfigurationTableContent && modal.dismiss('Croess click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-6" *ngFor="let tableColumnItem of tableConfig.columnObjectList">
          <label class="custom-control fill-checkbox mc-cursor-pointer">
            <input type="checkbox"
                   class="fill-control-input"
                   (click)="onSelectTableColumnItem(tableColumnItem)"
                   [checked]="!tableColumnItem.isHide"
            >
            <span class="fill-control-indicator"></span>
            <span class="text-capitalize">{{ tableColumnItem.label}}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
            <p class="m-0 mr-3 align-middle text-danger text-capitalize font-italic"
               *ngIf="tableSetupConfigurationTableContent">{{ "cc.table.table-need-content" | translate }}!</p>
            <button class="text-capitalize mc-button mc-submit-button"
                    [disabled]="tableSetupConfigurationTableContent"
                    (click)="onSaveTableColumnSetup()">
              {{ "cc.common.view.save" | translate }}
            </button>
    </div>
  </div>
</ng-template>


<div class="mc-table-settings-button d-inline text-capitalize"
     (click)="openModal(tableSettings)">
  {{ 'cc.common.table-configuration' | translate }}
  <i class="fas fa-cog mc-table-setting-icon ml-1"></i>
</div>
