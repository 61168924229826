import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AdminComponent} from './admin.component';
import {McAdminRoutingModule} from './mc-admin-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {McHeaderComponent} from './mc-header/mc-header.component';
import {McSidebarComponent} from './mc-sidebar/mc-sidebar.component';
import {McSelectEntityComponent} from './mc-header/components/mc-select-entity/mc-select-entity.component';
import {SelectEntityListItemComponent} from './mc-header/components/mc-select-entity/select-entity-list-item/select-entity-list-item.component';
import {SelectEntityListWrapperComponent} from './mc-header/components/mc-select-entity/select-entity-list-wrapper/select-entity-list-wrapper.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {McComponentsModule } from '@miticon-ui/mc-components';
import {TranslateModule} from '@ngx-translate/core';
import { SafeHtmlModule } from '../../assets/pipes/safe-html/safe-html.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AdminComponent,
    McHeaderComponent,
    McSidebarComponent,
    McSelectEntityComponent,
    SelectEntityListItemComponent,
    SelectEntityListWrapperComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    McAdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    McComponentsModule,
    TranslateModule,
    SafeHtmlModule,
    MatTooltipModule
  ],
  exports: [
    AdminComponent,
  ]
})

export class McAdminModule {
}
