import {EbTransactionAdjustment} from './eb-transaction-adjustment.model';
import {EbTransactionAdjustmentListGENERATED} from '../_generated/eb-transaction-adjustment-list-generated.model';

export class EbTransactionAdjustmentList extends EbTransactionAdjustmentListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbTransactionAdjustmentList();
  }

  // ---------------------------------------------------------------------
}
