import {Inject, Injectable} from '@angular/core';
import {McBillingBatch2TransactionServiceGENERATED} from '../_generated/mc-billing-batch-2-transaction-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McBillingBatch2TransactionService extends McBillingBatch2TransactionServiceGENERATED {

}
