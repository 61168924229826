<!--Manage user roles-->
<div class="mc-popup-button mc-border-left">
  <i class="fas fa-suitcase"></i>
  <p>Manage User Roles</p>
</div>

<!--Send user invite-->
<div class="mc-popup-button mc-border-left-right">
  <i class="far fa-envelope"></i>
  <p>Send User Invite</p>
</div>

<!--Change status-->
<div class="mc-popup-button mc-border-right">
  <i class="fas fa-sync-alt"></i>
  <p>Change Status</p>
</div>
