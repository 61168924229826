import {Inject, Injectable} from '@angular/core';
import {ZzPocCarServiceGENERATED} from '../_generated/zz-poc-car-generated.service';



@Injectable({
  providedIn: 'root'
})
export class ZzPocCarService extends ZzPocCarServiceGENERATED {

}
