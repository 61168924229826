/*BbmTestObj*/
import {ImDataExportGENERATED} from '../_generated/im-data-export-generated.model';
import {ICON_IMPORTED} from "../svg/icon-imported";
import {ICON_PARTIALLY_IMPORTED} from "../svg/icon-partially-imported";
import {ICON_FAILED} from "../svg/icon-failed";
import {ICON_IN_PROGRESS} from "../svg/icon-in-progress";
import moment from "moment/moment";

export class ImDataExport extends ImDataExportGENERATED {
  user: string;

  public properties: string[] = ['id', 'dateFrom', 'dateTo', 'fileType', 'idDataExportTemplate', 'idMcEntity', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'title', 'user'];
  public propertiesRegular: string[] = ['id', 'dateFrom', 'dateTo', 'fileType', 'idDataExportTemplate', 'idMcEntity', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'title', 'user'];

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataExport();
  }

  // ---------------------------------------------------------------------

  getExportedDate() {
    return moment(this.sysCreatedDatetime).format('DD.MM.YYYY');
  }

  getStatusCd() {
    if (this.statusCd === ImDataExport.STATUS_CD_EXPORTED) {
      return `<div class='icon-text-table-cell'>${ICON_IMPORTED} <span>Exported</div>`
    }
    if (this.statusCd === ImDataExport.STATUS_CD_PARTIALLY_EXPORTED) {
      return `<div class='icon-text-table-cell'>${ICON_PARTIALLY_IMPORTED} <span>Partially exported</span></div>`
    }
    if (this.statusCd === ImDataExport.STATUS_CD_FAILED) {
      return `<div class='icon-text-table-cell'>${ICON_FAILED} <span>Failed</div>`
    }
    if (this.statusCd === ImDataExport.STATUS_CD_IN_PROGRESS) {
      return `<div class='icon-text-table-cell'>${ICON_IN_PROGRESS} <span>In progress</div>`
    }
    return this.statusCd;
  }
}
