import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SetupComponent} from './pages/setup/setup.component';
import {TwoFaLoginComponent} from './pages/two-fa-login/two-fa-login.component';
import {AuthLockedComponent} from './pages/auth-locked/auth-locked.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {LoginComponent} from './pages/login/login.component';
import {IncompleteProfileComponent} from './pages/incomplete-profile/incomplete-profile.component';

const routes: Routes = [
    {
      path: 'login',
      component: LoginComponent,
      data : {isTokenExpired : ''}
    },
    {
      path: 'login/setup/:uuid',
      component: SetupComponent
    },
    {
      path: 'two-fa-login',
      component: TwoFaLoginComponent
    },
    {
      path: 'auth-locked',
      component: AuthLockedComponent
    },
    {
      path: 'forgot-password',
      component: ForgotPasswordComponent
    },
  {
    path: 'reset-password/:uuid',
    component: SetupComponent
  },
  {
    path: 'incomplete-profile',
    component: IncompleteProfileComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McLoginRoutingModule {
}
