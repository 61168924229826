import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CdsLookupService,
  McConsumersService, McEntityService,
  McMapping,
  McMappingList,
  WebFile,
  McCustomAttribute, McUtilityService
} from "@miticon-ui/mc-core";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormGroup} from "@angular/forms";


@Component({
  selector: 'lib-import-consumers-step2',
  templateUrl: './import-consumers-step2.component.html'
})
export class ImportConsumersStep2Component implements OnInit {

  entityId: number;
  notifyMessageObject = {
    type: "",
    message: ""
  };
  public mapping!: McMapping;
  public templateMappings!: McMappingList;
  public duplicateHandlingOptionsKvm = new Map<string, string>();
  public importConsumersForm!: FormGroup;
  public classCustomAtt: McCustomAttribute;
  public disabledImportButtonFlag = false;

  @Input() webFile!: WebFile;
  @Output() eventPrevStep = new EventEmitter();
  @Output() eventCancel = new EventEmitter();

  constructor( private consumersService: McConsumersService,
               private entitiesService: McEntityService,
               private translateService: TranslateService,
               private cdsLookupService: CdsLookupService,
               private fb: FormBuilder,
               private utilityService: McUtilityService) {
    this.entityId = this.utilityService.getPropertyFromToken("entity_id");
    this.classCustomAtt = new McCustomAttribute();
    this.classCustomAtt.getCustomAttribute(this.entitiesService);
  }

  ngOnInit() {
    this.createForm();
    this.mapping = new McMapping();
    this.templateMappings = new McMappingList();

    if (this.webFile.exists()) {
      this.mapping.loadByWebFileId(this.webFile.id, () => {
        this.notifyMessageObject.message = '';
        this.notifyMessageObject.type = '';
        this.templateMappings.setSortB('mappingName', "ASC");
        this.templateMappings.loadTemplatesAndSharedByEntityId(() => {
          this.notifyMessageObject.message = '';
          this.notifyMessageObject.type = '';
        }, () => {
          if (this.templateMappings.apiErrorFlg) {
            this.notifyMessageObject.message = this.templateMappings.apiErrorMessage;
            this.notifyMessageObject.type = 'error';
            console.log('ERROR LOAD TEMPLATES MAPPING IN STEP 2 IMPORT CONSUMERS 44444444');
          }
        });
      }, () => {
        console.log('ERROR LOAD MAPPING IN STEP 2 IMPORT CONSUMERS 44444444');
        this.notifyMessageObject.message = `${this.mapping.apiErrorMessage}`;
        this.notifyMessageObject.type = 'error';
      });
      // this.duplicateHandlingOptionsKvm = this.cdsLookupService.getImportCustomerDuplicateHandlingCdsKvm();
    }
    this.checkIfSaveMappingIsChecked();
  }

  private createForm() {
    this.importConsumersForm = this.fb.group({
      mappingForm: ""
    });
  }

  actPrev() {
    this.eventPrevStep.emit();
  }

  actCancel() {
    this.eventCancel.emit();
  }

  actImport(): any {
    this.disabledImportButtonFlag = true;
    const tempArray = this.mapping.mappingItems.items.filter((item: any) => {
      if (!item.attributeName) {
        return item;
      }
    });
    if (this.checkSelectDuplicates()) {
      this.disabledImportButtonFlag = false;
      return null;
    } else if ( tempArray.length > 0 ) {
      this.notifyMessageObject = {message: `Choose mapping key for ${tempArray[0].columnName}`, type: 'error'};
      this.disabledImportButtonFlag = false;
    } else if (this.importConsumersForm.get('mappingForm')?.value.duplicates === '') {
      this.notifyMessageObject = {message: 'Import mode is required. Please choose an appropriate import mode.', type: 'error'};
      this.disabledImportButtonFlag = false;
    } else {
      this.notifyMessageObject = {message: "", type: ""};
      if (this.importConsumersForm.get("mappingForm")?.value.mappingNameCheckBox) {
        if (this.importConsumersForm.get("mappingForm")?.value.mappingName === "") {
          this.notifyMessageObject = {message: "Enter mapping name", type: "error"};
          this.disabledImportButtonFlag = false;
          return null;
        } else if (!this.checkUniqueTemplateMappingName()) {
          this.notifyMessageObject = {message: "Mapping name must be unique", type: "error"};
          this.disabledImportButtonFlag = false;
          return null;
        } else {
          this.mapping.mappingName = this.importConsumersForm.get("mappingForm")?.value.mappingName;
        }
      }
      this.mapping.save(() => {
        this.notifyMessageObject.message = '';
        this.notifyMessageObject.type = '';
        const duplicate = this.importConsumersForm.get("mappingForm")?.value.duplicates;
        this.consumersService.importConsumers(duplicate).subscribe( res => { // Not working, this is old code
          this.notifyMessageObject.message = '';
          this.notifyMessageObject.type = '';
          this.eventCancel.emit();
          this.disabledImportButtonFlag = false;
        }, errorObject => {
          this.notifyMessageObject.message = errorObject.error.message;
          this.notifyMessageObject.type = 'error';
          this.disabledImportButtonFlag = false;
        }).add(() => {this.templateMappings.loadTemplatesAndSharedByEntityId(); });
      }, () => {
        this.notifyMessageObject.message = this.mapping.apiErrorMessage;
        this.notifyMessageObject.type = 'error';
        this.disabledImportButtonFlag = false;
      });
    }
  }

  checkSelectDuplicates(): boolean {
    for (let i = 0; i < this.mapping.mappingItems.items.length - 1; i++) {
      if (this.mapping.mappingItems.items[i].attributeName !== "Discard") {
        for (let j = i + 1; j < this.mapping.mappingItems.items.length; j++) {
          if (this.mapping.mappingItems.items[i].attributeName === this.mapping.mappingItems.items[j].attributeName) {
            this.translateService.get("cc.common.import-consumers-invalid-selection").subscribe(translatedWord =>
              this.notifyMessageObject = {message: translatedWord + `:
               ${this.mapping.mappingItems.items[i].columnName}, ${this.mapping.mappingItems.items[j].columnName}`, type: "error"});
            return true;
          }
        }
      }
    }
    this.notifyMessageObject = {message: "", type: ""};
    return false;
  }

  checkUniqueTemplateMappingName(): boolean {
    const templateMappingsNames = this.templateMappings.items.map(item => item.mappingName);
    const notUniqueNames = templateMappingsNames.filter(item =>
      item === this.importConsumersForm.get("mappingForm")?.value.mappingName);
    return notUniqueNames.length === 0;
  }

  checkIfSaveMappingIsChecked() {
    this.importConsumersForm.valueChanges.subscribe( data => {
      if (data.mappingForm.mappingNameCheckBox === false) {
        this.notifyMessageObject = {message: "", type: ""};
      }
    });
  }

  tableCellSelectChanged() {
    this.notifyMessageObject = {message: "", type: ""};
  }
}
