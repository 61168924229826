import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {
  EbSepaFile, EbSepaFileReport,
  EbSepaFileTransactionList, EbSepaFileTransactionService,
  McAuthService, McDateUtils,
  McNotifyMessageModel,
  McReportingCalculatingModel, McUtilityService,
  WebFile, McGod, EbFactoring, EbSepaFileTransaction, SortCriteriaList, EbFactoringService, CurrencyUtil
} from '@miticon-ui/mc-core';
import {McReportingTransactionsTableConfig} from './mc-reporting-transactions-table-config';
import {McReportingTransactionsNonFactoringTableConfig} from './mc-reporting-transactions-non-factoring-table-config';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'mc-my-factoring-reporting',
  templateUrl: './mc-my-factoring-reporting.component.html',
})
export class McMyFactoringReportingComponent implements OnInit, OnChanges {

  transactionList = new EbSepaFileTransactionList();
  transactionTableConfig!: McReportingTransactionsTableConfig;
  transactionTableNonFactoringConfig = new McReportingTransactionsNonFactoringTableConfig();
  notifyMessage!: McNotifyMessageModel;
  item!: EbSepaFile;
  webFile!: WebFile;
  ebSepaFileReport = new EbSepaFileReport();
  mcGod = McGod;
  ebFactoring = new EbFactoring();
  feePerTransactionAmountCd = EbFactoring.FEE_PER_TRANSACTION_TYPE_CD_AMOUNT;
  ebSepaFileTransaction = new EbSepaFileTransaction();
  currencyCode = '';


  @Input() ebSepaFile!: EbSepaFile;
  @Output() eventCanceled = new EventEmitter();

  constructor(private utilityService: McUtilityService,
              private ebFactoringService: EbFactoringService,
              private ebSepaFileTransactionService: EbSepaFileTransactionService) {
  }

  ngOnInit() {
    this.getReportingCalculationsBySepaFileId();
    this.currencyCode = this.ebFactoringService.activeChildFactoring.mcCurrency.code;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["ebSepaFile"]) {
      if ((changes["ebSepaFile"].previousValue !== changes["ebSepaFile"].currentValue)) {
        this.item = this.ebSepaFile;
        this.ebFactoring.loadActiveForChildEntity(this.mcGod.getLoggedEntityIdFromToken());
        this.webFile = new WebFile();
        this.webFile.getFileByIdAndType(this.item.idWebfile, WebFile.TYPE_CD_EB_BANK_SEPA_IMPORT, () => {
          const extansionIndex = this.webFile.filename.search(this.webFile.ext);
          if (extansionIndex > -1) {
            this.webFile.filename = this.webFile.filename.substr(0, extansionIndex - 1);
          }
        });
        this.transactionList = new EbSepaFileTransactionList();
        this.transactionTableConfig = new McReportingTransactionsTableConfig();
        this.getTransactionsBySepaFileId();

      } else {
        // this.actLoad(); // TODO in hurry need to do it right
      }
    }
  }

  isVisible(): boolean {
    return (this.ebSepaFile != null);
  }

  public getTransactionsBySepaFileId(): void {
    this.transactionList.apiSuccessFlg = false;
    this.transactionList.apiLoadingFlg = true;
    if (this.item.id) {
      this.transactionList.loadBySepaFileId(this.item.id, () => {
        this.transactionList.items.forEach(item => {
          item.amountStr = this.format(item.instdamt);
          item.netFeeAmountStr = this.format(item.netFeeAmount);
          item.paymentAmountStr = this.format(item.paymentAmount);

        });
      }, () => {
        this.transactionList.apiLoadingFlg = false;
        this.transactionList.apiErrorFlg = true;
      });
    }
  }

  /*Get reporting calculations by sepaFileId*/
  public getReportingCalculationsBySepaFileId(): void {
    if (this.item.id) {
      // TODO Commented out FOR CG Updates
      /*   this.ebSepaFileTransactionService.getReportingForImportedSepaFile(this.item.id)
          .subscribe(
            response => {
              this.reportingCalculations = response;
              if (this.reportingCalculations)
                Object.keys(this.reportingCalculations).forEach(key => this.reportingCalculations[key] =
                 this.utilityService.roundHalfUp(this.reportingCalculations[key], 2));
            },
            error => this.notifyMessage = {type: 'error', message: error.error.message || error.message}
          );*/
      this.ebSepaFileReport.loadBySepaFileId(this.item.id, () => {
        // this.ebSepaFileReport = this.ebSepaFileReport;
      });
    }
  }

  onDownloadReporting() {
    this.notifyMessage = {type: '', message: ''};
    if (this.item.id) {
      // TODO Commented out FOR CG Updates
      this.ebSepaFileTransactionService
        .downloadTransactionsReportingFile(this.item.id)
        .subscribe(
          data => {
            if (data && this.webFile && this.webFile.filename) {
              const downloadDate = McDateUtils.formatDateToString(McDateUtils.getNowTimestamp()).replace(/:/g, '-');
              const timZoneIndex = downloadDate.search(/\+/g);
              fileSaver.saveAs(data, `${this.webFile.filename}-pre-reporting-${downloadDate.substr(0, timZoneIndex)}`);
            }
          },
          error => this.notifyMessage = {type: 'error', message: error.error.message || error.message}
        );
    }
  }

  public onDownloadTransactionCsv() {
    this.notifyMessage = {type: '', message: ''};
    if (this.item.id) {
      // TODO commented out for CG updates
      this.ebSepaFileTransactionService
        .downloadCsvReportingFile(this.item.id)
        .subscribe(
          data => {
            if (data && this.webFile && this.webFile.filename) {
              const downloadDate = McDateUtils.formatDateToString(McDateUtils.getNowTimestamp()).replace(/:/g, '-');
              const timZoneIndex = downloadDate.search(/\+/g);
              fileSaver.saveAs(data, `${this.webFile.filename}-transaction-reporting-${downloadDate.substr(0, timZoneIndex)}.csv`);
            }
          },
          error => this.notifyMessage = {type: 'error', message: error.error.message || error.message}
        );
    }
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  format(value: number): string {
    if (value) {
      return CurrencyUtil.format(value, this.currencyCode);
    }
    return CurrencyUtil.format(0, this.currencyCode);
  }
}
