<div *ngIf="isVisible()" class="mcc-modal modal show d-block" [ngClass]="{'modal-small' : modalSizeCd === 'small', 'modal-medium' : modalSizeCd === 'medium'}" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" [ngClass]="{'height-auto' : heightAutoFlg}">
      <span class="modal-content-icon"
            [innerHTML]="iconClose | safeHtml"
            (click)="actCancel()"></span>

         <ng-content></ng-content>

    </div>
  </div>
</div>
<router-outlet></router-outlet>

