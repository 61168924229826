/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbSepaImport} from '../models/eb-sepa-import.model';
import {EbSepaImportService} from '../services/eb-sepa-import.service';
import {IEbSepaImport} from '../_generated/eb-sepa-import.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class EbSepaImportGENERATED extends BaseObject {


    public static readonly STATUS_CD_NEW: string = 'NEW';
    public static readonly STATUS_CD_PROCESSING: string = 'PROCESSING';
    public static readonly STATUS_CD_DONE: string = 'DONE';
    public static readonly STATUS_CD_REMOVED: string = 'REMOVED';
    public static readonly STATUS_CD_OVERWRITTEN: string = 'OVERWRITTEN';
  public static readonly STATUS_CD_LIST__ALL = [
    EbSepaImportGENERATED.STATUS_CD_NEW,
    EbSepaImportGENERATED.STATUS_CD_PROCESSING,
    EbSepaImportGENERATED.STATUS_CD_DONE,
    EbSepaImportGENERATED.STATUS_CD_REMOVED,
    EbSepaImportGENERATED.STATUS_CD_OVERWRITTEN
];

  public apiService: EbSepaImportService;
  public _rawJson: IEbSepaImport;
    id: number = 0;
  acceptedAmount: number;
  acceptedDebtors: number;
  allDebtors: number;
  idMcEntity: number;
  idWebfile: number;
  overwrittenAmount: number;
  overwrittenCnt: number;
  processedEarlierCnt: number;
  processedNowCnt: number;
  rejectedAmount: number;
  rejectedDebtors: number;
  remainingAmount: number;
  requestedCollectionDate: number;
  servicePeriod: number;
  statusCd: string;
  sysCreatedDatetime: number;
  totalAmountDue: number;
  childEntityName: string;  reporting: string;  requestedOrBatchCollectionDates: any;  sepaFilename: string;

  public properties: string[] = ['id', 'acceptedAmount', 'acceptedDebtors', 'allDebtors', 'idMcEntity', 'idWebfile', 'overwrittenAmount', 'overwrittenCnt', 'processedEarlierCnt', 'processedNowCnt', 'rejectedAmount', 'rejectedDebtors', 'remainingAmount', 'requestedCollectionDate', 'servicePeriod', 'statusCd', 'sysCreatedDatetime', 'totalAmountDue', 'childEntityName', 'reporting', 'requestedOrBatchCollectionDates', 'sepaFilename'];
  public propertiesRegular: string[] = ['id', 'acceptedAmount', 'acceptedDebtors', 'allDebtors', 'idMcEntity', 'idWebfile', 'mcEntity', 'overwrittenAmount', 'overwrittenCnt', 'processedEarlierCnt', 'processedNowCnt', 'rejectedAmount', 'rejectedDebtors', 'remainingAmount', 'requestedCollectionDate', 'servicePeriod', 'statusCd', 'sysCreatedDatetime', 'totalAmountDue', 'childEntityName', 'reporting', 'requestedOrBatchCollectionDates', 'sepaFilename'];
  public propertiesSpecial: string[] = [];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbSepaImportGENERATED.STATUS_CD_NEW, McGod.t('cc.common.view.new'));
    list.add(EbSepaImportGENERATED.STATUS_CD_PROCESSING, McGod.t('cc.common.view.processing'));
    list.add(EbSepaImportGENERATED.STATUS_CD_DONE, McGod.t('cc.common.view.done'));
    list.add(EbSepaImportGENERATED.STATUS_CD_REMOVED, McGod.t('Removed'));
    list.add(EbSepaImportGENERATED.STATUS_CD_OVERWRITTEN, McGod.t('Overwritten'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = EbSepaImportGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): EbSepaImport {
    console.error('MTCN-ERROR: Not Implemented: EbSepaImport::createNewInstance(). Add this method to final class and return new EbSepaImport();');
    throw new Error('Cannot EbSepaImport::createNewInstance(). Add this method to final class and return new EbSepaImport();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbSepaImport): EbSepaImport {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbSepaImportService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbSepaImport AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getAcceptedAmountStr(): string {
       return McGod.formatNumberToMoney(this.acceptedAmount);
    }

    public getOverwrittenAmountStr(): string {
       return McGod.formatNumberToMoney(this.overwrittenAmount);
    }

    public getRejectedAmountStr(): string {
       return McGod.formatNumberToMoney(this.rejectedAmount);
    }

    public getRemainingAmountStr(): string {
       return McGod.formatNumberToMoney(this.remainingAmount);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getTotalAmountDueStr(): string {
       return McGod.formatNumberToMoney(this.totalAmountDue);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return EbSepaImportGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdNEW(): boolean {
    const result = (this.statusCd === EbSepaImportGENERATED.STATUS_CD_NEW);

    return result;
  }

 public  isStatusCdPROCESSING(): boolean {
    const result = (this.statusCd === EbSepaImportGENERATED.STATUS_CD_PROCESSING);

    return result;
  }

 public  isStatusCdDONE(): boolean {
    const result = (this.statusCd === EbSepaImportGENERATED.STATUS_CD_DONE);

    return result;
  }

 public  isStatusCdREMOVED(): boolean {
    const result = (this.statusCd === EbSepaImportGENERATED.STATUS_CD_REMOVED);

    return result;
  }

 public  isStatusCdOVERWRITTEN(): boolean {
    const result = (this.statusCd === EbSepaImportGENERATED.STATUS_CD_OVERWRITTEN);

    return result;
  }


  
// ---------------------------------------------------------------------
public callImportFile( overwriteFlg: boolean, ebSepaFile: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.importFile(overwriteFlg,ebSepaFile)
    .subscribe(responseJson => {
        this.populateFromJson(responseJson);
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
}

}
