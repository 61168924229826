import {Component, OnInit} from '@angular/core';
import {McMapping, McForm, McHtmlButton, McBoolean, McGod, McValueLabelList, CsrMappingService, SortCriteriaList, CsrMapping, SortCriteriaItem, McUserService, McUser} from "@miticon-ui/mc-core";
import {HttpParams} from "@angular/common/http";
import {MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkTableConfig} from "@miticon-ui/mc-components";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";

const enum SharingUnsharinDialogAction {
  ACT_CANCEL = 'btn-secondary',
  ACT_YES = 'btn-primary'
}

@Component({
  selector: 'mc-consumer-consumer-import-mappings-list-page',
  templateUrl: './mc-consumer-consumer-import-mappings-list-page.component.html',
})
export class McConsumerConsumerImportMappingsListPageComponent implements OnInit {
  mcForm!: McForm;
  csrMapping = new CsrMapping();
  showMappingProfileMcb = new McBoolean();
  showMappingEditMcb = new McBoolean();
  showUnShareMappingMcb = new McBoolean();
  showShareMappingMcb = new McBoolean();
  showCopyMappingMcb = new McBoolean();
  csrMappingId!: number;
  sharingUnsharingCopyButtonKvm = new Map<string, string>();

  totalItemsCount!: number;
  pageSize = 5;
  pageNumber= 0;
  isLoading = false;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  items: CsrMapping[] = [];
  searchTooltip = this.tS.instant('cc.mapping-imports.search-tooltip');

  constructor(private tS: TranslateService,
              private mcUserService: McUserService,
              private csrMappingService: CsrMappingService,
              private toastrService: ToastrService) {}

  ngOnInit() {
    this.sharingUnsharingCopyButtonKvm.set('btn-secondary', this.tS.instant('cc.common.edit.cancel'));
    this.sharingUnsharingCopyButtonKvm.set('btn-primary', this.tS.instant('cc.common.edit.yes'));

    this.initTableConfig();
    this.initFilterConfig();
    this.actLoad(this.pageNumber, this.pageSize, this.getDefaultParams());
  }

  getDefaultParams() {
    return new HttpParams().set('page', this.pageNumber).set('size', this.pageSize);
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(this.tS.instant('cc.invoices.view.creation-date'), 'getCreationDate()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.billing.entity-id'), 'idMcEntity');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.users.view.user') + ' ID', 'idMcSystemUser', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.web-file.id'), 'idWebFile', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.consumers.consumers-overview.mapping-name'), 'mappingName', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.mapping.shared-flg'), 'sharedFlg', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.mapping.sharing-date'), 'getSharingDate()', 250);
  }

  initFilterConfig() {
    const usersValueLabelList = new McValueLabelList();
    this.mcUserService.getAllUsersWithCreatedMapping().subscribe((users: any) => {
      if(users) {
        users.content.forEach((user: McUser) => {
          usersValueLabelList.add(user.id, user.firstname + ' ' + user.lastname);
        })
      }
      this.filterConfig.addItem(MkFilterItemType.SELECT, this.tS.instant('cc.users.view.user'), usersValueLabelList.items.map((item) => new MkFilterValue(item.label, item.value)));
    })
  }

  onFilterChanged(filterOutput: MkFilterOutput) {
    const params = new HttpParams()
      .set('page', filterOutput.pageEvent.pageIndex)
      .set('size', filterOutput.pageEvent.pageSize)
      .set('term', filterOutput.search ? filterOutput.search : '')
      .set('userId', filterOutput.selections[this.tS.instant('cc.users.view.user')] ? filterOutput.selections[this.tS.instant('cc.users.view.user')] : '');
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, params);
  }

  actLoad(pageNumber: number, pageSize: number, params: HttpParams): void {
    this.isLoading = true;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    const sort = new SortCriteriaItem('id', 'DESC');
    const sortCriteriaList = new SortCriteriaList();
    sortCriteriaList.addSort(sort.propertyName, sort.direction);
    this.csrMappingService.get(params, pageNumber, pageSize, sortCriteriaList).subscribe((res)=> {
      if (res) {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => object = CsrMapping.createFromJson(object));
      }
    }, () => {
      this.isLoading = false;
    });
  }

  onTableItemAction(mcButton: McHtmlButton) {
    if (mcButton.actionCdForEvent === McMapping.ACTION_SHOW_EDIT) {
      this.actShowAddEdit(mcButton.item);
    } else if (mcButton.actionCdForEvent === McMapping.ACTION_SHOW_PROFILE) {
      this.actShowProfile(mcButton.item);
    } else if (mcButton.actionCdForEvent === McMapping.ACTION_COPY) {
      this.actionMenuCopyMapping(mcButton.item);
    } else if (mcButton.actionCdForEvent === McMapping.ACTION_SHARE) {
      this.actShareMapping(mcButton.item);
    } else if (mcButton.actionCdForEvent === McMapping.ACTION_UNSHARE) {
      this.actUnshareMapping(mcButton.item);
    }
  }

  actShowAddEdit(csrMapping: CsrMapping) {
    this.csrMappingId = csrMapping.id;
    this.csrMapping = csrMapping;
    this.showMappingEditMcb.setTrue();
  }

  actShowProfile(csrMapping: CsrMapping) {
    this.csrMappingId = csrMapping.id;
    this.csrMapping = csrMapping;
    this.showMappingProfileMcb.setTrue();
  }

  actCopyMapping(csrMapping: CsrMapping) {
    this.showCopyMappingMcb.setFalse();
    this.showMappingProfileMcb.setFalse();
    this.csrMapping = new CsrMapping();
    const entityId = McGod.getLoggedEntityIdFromToken();
    const userId = McGod.getLoggedUserIdFromToken();
    this.csrMapping.callCopyMappingWithMappingItems(csrMapping.id, userId, entityId, () => {
      this.toastrService.success(`${csrMapping.mappingName} ${this.tS.instant('cc.import-mappings.mapping-successfully-copied')}`);
      this.actLoad(this.pageNumber, this.pageSize, this.getDefaultParams());
    }, () => {
      this.toastrService.error(this.csrMapping.apiErrorMessage);
    });
  }

  actShareMapping(csrMapping: CsrMapping) {
    this.csrMappingId = csrMapping.id;
    this.csrMapping = csrMapping;
    this.showShareMappingMcb.setTrue();
  }

  actUnshareMapping(csrMapping: CsrMapping) {
    this.csrMappingId = csrMapping.id;
    this.csrMapping = csrMapping;
    this.showUnShareMappingMcb.setTrue();
  }

  onUpdatedMapping() {
    this.showMappingEditMcb.setFalse();
    this.actLoad(this.pageNumber, this.pageSize, this.getDefaultParams());
  }

  onCloseMapping() {
    this.showMappingEditMcb.setFalse();
  }

  onEditMapping() {
    this.showMappingProfileMcb.setFalse();
    this.showMappingEditMcb.setTrue();
  }

  actionMenuCopyMapping(csrMapping: CsrMapping) {
    this.csrMappingId = csrMapping.id;
    this.csrMapping = csrMapping;
    this.showCopyMappingMcb.setTrue();
  }

  onMcDialogBoxUnshareMappingActionButtonClicked(action: string) {
    if (action === SharingUnsharinDialogAction.ACT_CANCEL) {
      this.showUnShareMappingMcb.setFalse();
    } else {
      this.unShareMapping();
    }
  }

  onMcDialogBoxShareMappingActionButtonClicked(action: string) {
    if (action === SharingUnsharinDialogAction.ACT_CANCEL) {
      this.showShareMappingMcb.setFalse();
    } else {
      this.shareMapping();
    }
  }

  onMcDialogBoxCopyMappingActionButtonClicked(action: string) {
    if (action === SharingUnsharinDialogAction.ACT_CANCEL) {
      this.showCopyMappingMcb.setFalse();
    } else {
      this.actCopyMapping(this.csrMapping);
    }
  }

  shareMapping() {
    this.csrMapping.sharedFlg = true;
    this.csrMapping.save(() => {
      this.showShareMappingMcb.setFalse();
      this.showMappingProfileMcb.setFalse();
      this.toastrService.success(`${this.csrMapping.mappingName} ${this.tS.instant('cc.import-mappings.successfully-shared')}`);
      this.actLoad(this.pageNumber, this.pageSize, this.getDefaultParams());
    }, () => {
      this.showShareMappingMcb.setFalse();
      this.showMappingProfileMcb.setFalse();
      this.toastrService.error(this.csrMapping.apiErrorMessage);
    });
  }

  unShareMapping() {
    this.csrMapping.sharedFlg = false;
    this.csrMapping.save(() => {
      this.showUnShareMappingMcb.setFalse();
      this.showMappingProfileMcb.setFalse();
      this.toastrService.success(`${this.csrMapping.mappingName} ${this.tS.instant('cc.import-mappings.successfully-unshared')}`);
      this.actLoad(this.pageNumber, this.pageSize, this.getDefaultParams());
    }, () => {
      this.showUnShareMappingMcb.setFalse();
      this.showMappingProfileMcb.setFalse();
      this.toastrService.error(this.csrMapping.apiErrorMessage);
    });
  }
}
