import {McBillingStatementFilterGENERATED} from '../_generated/mc-billing-statement-filter-generated.model';

export class McBillingStatementFilter extends McBillingStatementFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingStatementFilter();
  }

  // ---------------------------------------------------------------------
}
