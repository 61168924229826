import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {McProcessFlowLevel} from '@miticon-ui/mc-core';
import {McForm,
  McBoolean,
  McTrigger,} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-process-process-level-add-edit',
  templateUrl: './mc-process-process-level-add-edit.component.html',
})
export class McProcessProcessLevelAddEditComponent implements OnInit {

  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  errorMsgs: string[] = [];

  // -- Input --
  @Input() mcProcessFlowLevelId!: number;
  @Input() mcProcessFlowLevel!: McProcessFlowLevel;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();


  // ---------------------------------------------------------------------
  ngOnInit() {
    if (!this.mcProcessFlowLevel) {
      this.mcProcessFlowLevel = new McProcessFlowLevel();
    }

    this.actLoad();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcProcessFlowLevel = new McProcessFlowLevel();
    if (this.mcProcessFlowLevelId > 0) {
      this.mcProcessFlowLevel.loadById(this.mcProcessFlowLevelId, () => {
        this.validatePrerequisites();
       });
    } else {
      // new item
      this.validatePrerequisites();
    }

  }

  // ---------------------------------------------------------------------
  actSave() {

    if (this.mcForm.isValid()) {
            this.mcProcessFlowLevel.idMcProcessFlow = this.mcForm.getValue('ctrlIdMcProcessFlow');
    this.mcProcessFlowLevel.title = this.mcForm.getValue('ctrlTitle');
    this.mcProcessFlowLevel.description = this.mcForm.getValue('ctrlDescription');
    this.mcProcessFlowLevel.emailActionFlg = this.mcForm.getValue('ctrlEmailActionFlg');
    this.mcProcessFlowLevel.emailPaymentQrCodeFlg = this.mcForm.getValue('ctrlEmailPaymentQrCodeFlg');
    this.mcProcessFlowLevel.emailSubjectTokened = this.mcForm.getValue('ctrlEmailSubjectTokened');
    this.mcProcessFlowLevel.emailBodyTokened = this.mcForm.getValue('ctrlEmailBodyTokened');
    this.mcProcessFlowLevel.postActionFlg = this.mcForm.getValue('ctrlPostActionFlg');
    this.mcProcessFlowLevel.postPaymentQrCodeFlg = this.mcForm.getValue('ctrlPostPaymentQrCodeFlg');
    this.mcProcessFlowLevel.idPdfTemplate = this.mcForm.getValue('ctrlPdfTemplate');
    this.mcProcessFlowLevel.smsActionFlg = this.mcForm.getValue('ctrlSmsActionFlg');
    this.mcProcessFlowLevel.smsBodyTokened = this.mcForm.getValue('ctrlSmsBodyTokened');
    this.mcProcessFlowLevel.noDaysPaymentDeadlineAfterReminderIssue = this.mcForm.getValue('ctrlNoDaysPaymentDeadlineAfterReminderIssue');
    this.mcProcessFlowLevel.noDaysWaitingPeriodAfterPaymentDeadline = this.mcForm.getValue('ctrlNoDaysWaitingPeriodAfterPaymentDeadline');

        this.mcProcessFlowLevel.save(() => {
            this.eventSaved.emit();
        }, () => {
            this.saveButtonDisabledMcb.setFalse();
        });
     } else {
      this.saveButtonDisabledMcb.setFalse();
    }

  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.mcProcessFlowLevel.exists()) {

      this.mcProcessFlowLevel.delete(() => {
         this.eventDeleted.emit();
      });
    }
  }

// ---------------------------------------------------------------------
  validatePrerequisites() {
    this.errorMsgs = [];
    // populate validation here on required inputs
  }

  // ---------------------------------------------------------------------
  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }
}
