import {Component, Inject, OnInit} from '@angular/core';
import {McProcessFlowLevel} from '@miticon-ui/mc-core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mc-process-level-profile-page',
  templateUrl: './mc-process-level-profile-page.component.html',
})
export class McProcessLevelProfilePageComponent implements OnInit {

  mcProcessFlowLevelId!: number;
  mcProcessFlowLevel!: McProcessFlowLevel;

  // ---------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
  @Inject('designConfig') design: any) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      // @ts-ignore
      this.mcProcessFlowLevelId = +params.get('id');
      this.mcProcessFlowLevel = new McProcessFlowLevel();
      this.actLoad();
    });
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {

    this.mcProcessFlowLevel.loadById(this.mcProcessFlowLevelId);
}
}
