import {Component, Inject, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {McProduct, CurrencyUtil} from '@miticon-ui/mc-core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mc-product-product-profile-part',
  templateUrl: './mc-product-product-profile-part.component.html',
  styleUrls: ['./mc-product-product-profile-part.component.scss']
})
export class McProductProductProfilePartComponent implements OnInit {

  // -- Inputs --
  @Input() mcProductId!: number;
  @Input() mcProduct = new McProduct();
  @Output() eventClose = new EventEmitter();


  // ---------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
  @Inject('designConfig') design: any) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.actLoad();
  }


  // ---------------------------------------------------------------------
  public actLoad(): void {
    if (this.mcProductId > 0) {
        this.mcProduct = new  McProduct();
        this.mcProduct.loadById(this.mcProductId);
     }
  }

  // ---------------------------------------------------------------------
  actClose() {
    this.eventClose.emit();
  }

  format(value: number): string {
    if (value) {
      if(this.mcProduct.pricingTypeCd === McProduct.PRICING_TYPE_CD_VALUE){
        return CurrencyUtil.format(value, this.mcProduct.mcCurrency.code);
      }
      else {
        return value + ' %'
      }
    }
    return CurrencyUtil.format(0, this.mcProduct.mcCurrency.code);
  }
}


