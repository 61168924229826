/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McDashboardTable3ConfigGENERATED} from '../_generated/mc-dashboard-table-3-config-generated.model';

// import {McDashboardGENERATED} from '../_generated/mc-dashboard-generated.model';

export class McDashboardTable3Config extends McDashboardTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colOverdueTransactions', 'Number of overdue transactions', 'overdueTransactions', 'overdueTransactions');
    this.addColumn('colToBeSentTransactions', 'Number of to be sent transactions', 'toBeSentTransactions', 'toBeSentTransactions');
    this.addColumn('colSentTransactions', 'Number of sent transactions', 'sentTransactions', 'sentTransactions');
    this.addColumn('colManualMatchingNeededTransactions', 'Number of manual matching needed for transactions', 'manualMatchingNeededTransactions', 'manualMatchingNeededTransactions');
    this.addColumn('colManuallyMatchedTransactions', 'Number of manually matched transactions', 'manuallyMatchedTransactions', 'manuallyMatchedTransactions');
    this.addColumn('colAutomaticallyMatchedTransactions', 'Number of automatically matched transactions', 'automaticallyMatchedTransactions', 'automaticallyMatchedTransactions');
    this.addColumn('colPaidTransactions', 'Number of paid transactions', 'paidTransactions', 'paidTransactions');
    this.addColumn('colRejectedTransactions', 'Number of rejected transactions', 'rejectedTransactions', 'rejectedTransactions');
    this.addColumn('colSentToInkassoTransactions', 'Number of sent to inkasso transactions', 'sentToInkassoTransactions', 'sentToInkassoTransactions');
    this.addColumn('colPdfGenerated', 'Number of pdf genereted', 'pdfGenerated', 'pdfGenerated');
    this.addColumn('colEmailsSent', 'Number of sent emails', 'emailsSent', 'emailsSent');
    this.addColumn('colActiveConsumers', 'Number of active consumers', 'activeConsumers', 'activeConsumers');
    this.addColumn('colInactiveConsumers', 'Number of inactive consumers', 'inactiveConsumers', 'inactiveConsumers');
    this.addColumn('colIsChildOnly', 'Is logged user child only', 'isChildOnly', 'isChildOnly');


  }
}
