<!--Mc entity profile wrapper-->
<div class="modal-body">
  <div class="col-md-6 d-flex flex-column">
    <h1>{{'cc.common.admin.entity-profile' | translate}}</h1>
  </div>
<div class="mc-entity-profile-wrapper">

  <!--Mc Selected entity profile page content-->
  <div class="mc-entity-profile-content px-5">

    <!--Entity path-->
    <div class="row pt-4">
      <!--Check if selected entity path exists-->
      <div class="col-md-12 text-center" *ngIf="selectedEntityPath.length > 0 && !editEntityId">

        <!--Render selected entity path-->
        <p class="d-inline-block px-1"
           *ngFor="let entity of selectedEntityPath; let i = index"
           [ngClass]="cssClassForEntityPath(i)"
        >{{entity}} <span
          class="pl-2" *ngIf="i < selectedEntityPath.length -1"> > </span></p>
        <hr class="m-0">
      </div>

      <!--Check if current entity path exists-->
      <div class="col-md-12 text-center" *ngIf="currentEntityPath?.length > 0">

        <!--Render current entity path-->
        <p class="d-inline-block px-1"
           *ngFor="let entity of currentEntityPath; let i = index"
           [ngClass]="cssClassForEntityPath(i)"
        >{{entity.name}} <span
          class="pl-2" *ngIf="i < currentEntityPath.length -1"> > </span></p>
        <hr class="m-0">
      </div>
    </div><!--Entity path /end-->

    <ul ngbNav #nav="ngbNav" [(activeId)]="active"
                class="mc-details-tabs">
      <!--Basic info section-->
      <li [ngbNavItem]="1" id="basicInfoTab">
        <a ngbNavLink>{{ 'cc.common.view.basic-info' | translate }}</a>
        <ng-template ngbNavContent>
          <div class="row py-5">
            <div class="col-md-12">
              <!--Entity basic info Modal-->
              <mc-edit-entity-basic-info-modal
                [editEntityId]="editEntityId" [parentData]="entityObject"
                (entityBasicInfoEvent)="onSuccessEditBasicInfo($event)"></mc-edit-entity-basic-info-modal>
              <!--Content row-->
              <div class="row pt-1 px-2" *ngIf="entityObject">
                <div class="col-md-2">
                  <img *ngIf="imgUrl" [src]="imgUrl" class="entity-image" alt="Entity image">
                </div>
                <div class="col-md-10">
                  <div class="row">
                    <!--Left column-->
                    <div class="col-md-6">

                      <!--Status-->
                      <div class="row">
                        <div class="col-md-6 text-uppercase set-font">status:</div>
                        <div class="col-md-6 h6 text-muted position-top">
                          <mc-status [status]="entityObject.statusCode.toLowerCase()"></mc-status>
                        </div>
                      </div>

                      <!--Country-->
                      <div class="row">
                        <div class="col-md-6 text-uppercase set-font">country:</div>
                        <div class="col-md-6 h6 text-muted position-top">{{countryName}}</div>
                      </div>

                      <!--Name-->
                      <div class="row">
                        <div class="col-md-6 text-uppercase set-font">name:</div>
                        <div class="col-md-6 h6 text-muted position-top">{{entityObject.name}}</div>
                      </div>

                      <!--Abbreviation-->
                      <div class="row">
                        <div class="col-md-6 text-uppercase set-font">abbreviation:</div>
                        <div class="col-md-6 h6 text-muted position-top">{{entityObject.abbreviation}}</div>
                      </div>

                      <!--Bank transaction entity name override-->
                      <div class="row">
                        <div class="col-md-6 text-uppercase set-font">Bank transaction entity name override:</div>
                        <div class="col-md-6 h6 text-muted position-top">{{mcEntity2.bankTransactionEntityNameOverride}}</div>
                      </div>

                      <!--Description-->
                      <div class="row">
                        <div class="col-md-6 text-uppercase set-font">description:</div>
                        <div class="col-md-6 h6 text-muted position-top">{{entityObject.description}}</div>
                      </div>

                      <!--Phone number-->
                      <div class="row">
                        <div class="col-md-6 text-uppercase set-font">{{ 'cc.common.edit.phone-number' | translate }}:
                        </div>
                        <div class="col-md-6 h6 text-muted position-top">{{entityObject.phone}}</div>
                      </div>

                      <!--Fax number-->
                      <div class="row">
                        <div class="col-md-6 text-uppercase set-font">{{ 'cc.common.edit.fax-number' | translate }}:
                        </div>
                        <div class="col-md-6 h6 text-muted position-top">{{entityObject.fax}}</div>
                      </div>

                      <!--Address-->
                      <div class="row">
                        <div class="col-md-6 text-uppercase set-font">{{ 'cc.common.edit.address' | translate }}:</div>
                        <div class="col-md-6 h6 text-muted position-top">{{entityObject.address}}</div>
                      </div>

                      <!--Legal representative-->
                      <div class="row">
                        <div
                          class="col-md-6 text-uppercase set-font">{{ 'cc.common.edit.legal-representative' | translate }}
                          :
                        </div>
                        <div class="col-md-6 h6 text-muted position-top">{{entityObject.legalRepresentative}}</div>
                      </div>

                      <!--Legal representative contact-->
                      <div class="row">
                        <div
                          class="col-md-6 text-uppercase set-font">{{ 'cc.common.edit.legal-representative-email' | translate }}
                          :
                        </div>
                        <div
                          class="col-md-6 h6 text-muted position-top">{{entityObject.legalRepresentativeContact}}</div>
                      </div>

                      <!--Court in charge-->
                      <div class="row">
                        <div
                          class="col-md-6 text-uppercase set-font">Court in charge
                          :
                        </div>
                        <div
                          class="col-md-6 h6 text-muted position-top">{{mcEntity2.courtInCharge}}</div>
                      </div>
                    </div><!--Left column /end-->

                    <!--Right column-->
                    <div class="col-md-6">

                      <!--Mail domain-->
                      <div class="row">
                        <div class="col-md-6 text-uppercase set-font">{{ 'cc.common.edit.mail-domain' | translate }}:
                        </div>
                        <div class="col-md-6 h6 text-muted position-top">{{entityObject.mailDomain}}</div>
                      </div>

                      <!--Web domain-->
                      <div class="row">
                        <div class="col-md-6 text-uppercase set-font">{{ 'cc.common.edit.web-domain' | translate }}:
                        </div>
                        <div class="col-md-6 h6 text-muted position-top">{{entityObject.webDomain}}</div>
                      </div>

                      <!--IP whitelist-->
                      <div class="row">
                        <div class="col-md-6 text-uppercase set-font">{{ 'cc.common.edit.ip-whitelist' | translate }}:
                        </div>
                        <div class="col-md-6 h6 text-muted position-top">{{entityObject.ipWhitelist}}</div>
                      </div>



                      <!--VAT number-->
                      <div class="row">
                        <div class="col-md-6 text-uppercase set-font">{{ 'cc.common.edit.vat-number' | translate }}:
                        </div>
                        <div class="col-md-6 h6 text-muted position-top">{{mcEntity2.euVat}}</div>
                      </div>

                      <!--External Creditor ID-->
                      <div class="row">
                        <div
                          class="col-md-6 text-uppercase set-font">{{ 'cc.common.entity.external-creditor-id' | translate }}
                          :
                        </div>
                        <div
                          class="col-md-6 h6 text-muted position-top">{{mcEntity2.extCreditorId}}</div>
                      </div>

                      <!--Impressum-->
                      <div class="row">
                        <div class="col-md-6 text-uppercase set-font">{{ 'cc.common.edit.impressum' | translate }}:
                        </div>
                        <div class="col-md-6 h6 text-muted position-top" [innerHTML]="impressum"></div>
                      </div>





                      <!--Studio owner-->
                      <div class="row">
                        <div
                          class="col-md-6 text-uppercase set-font">Studio owner
                          :
                        </div>
                        <div
                          class="col-md-6 h6 text-muted position-top">{{mcEntity2.studioOwner}}</div>
                      </div>

                      <!--Studio owner address-->
                      <div class="row">
                        <div
                          class="col-md-6 text-uppercase set-font">Studio owner address
                          :
                        </div>
                        <div
                          class="col-md-6 h6 text-muted position-top">{{mcEntity2.studioOwnerAddress}}</div>
                      </div>

                      <!--Studio owner postal code-->
                      <div class="row">
                        <div
                          class="col-md-6 text-uppercase set-font">Studio owner postal code
                          :
                        </div>
                        <div
                          class="col-md-6 h6 text-muted position-top">{{mcEntity2.studioOwnerPostalCode}}</div>
                      </div>

                      <!--Studio owner city-->
                      <div class="row">
                        <div
                          class="col-md-6 text-uppercase set-font">Studio owner city
                          :
                        </div>
                        <div
                          class="col-md-6 h6 text-muted position-top">{{mcEntity2.studioOwnerCity}}</div>
                      </div>

                      <!--Studio manager-->
                      <div class="row">
                        <div
                          class="col-md-6 text-uppercase set-font">Studio manager
                          :
                        </div>
                        <div
                          class="col-md-6 h6 text-muted position-top">{{mcEntity2.studioManager}}</div>
                      </div>

                    </div><!--Right column /end-->
                  </div>
                </div>
              </div><!--Content row /end-->

              <!--Else basic info-->
              <div class="mc-notify-wrapper">
                <mc-notify-message [message]="entityMessage" [type]="entityType"></mc-notify-message>
              </div>

              <hr>
              <!--<div class="row px-2" *ngIf="userId === 1 && !isLoggedEntity">

                &lt;!&ndash;Fix - add super user all permissions in all entities&ndash;&gt;
                <div class="col-md-12">
                  <span class="text-capitalize">
                    {{"cc.common.add-user-with-all-permissions-to-all-entities" | translate}}:
                  </span>
                  <button class="mx-4 mc-button mc-submit-button text-capitalize"
                          type="button"
                          (click)="onAddSuperUserWithAllPermissionsInAllEntities()">
                    {{'cc.common.add' | translate}}
                  </button>

                  &lt;!&ndash;TODO: need to test why notify message component not working on this page!&ndash;&gt;
                  &lt;!&ndash;This is solution until we fix notify message showing&ndash;&gt;
                  <span class="text-uppercase" *ngIf="addedUserStatus">
                    {{addedUserStatus}}
                  </span>
                </div>

                &lt;!&ndash;Fix consumer history&ndash;&gt;
                <div class="col-md-12">
                  <span class="text-capitalize">
                    {{"cc.common.fix-consumer-history" | translate}}:
                  </span>
                  <button class="mx-4 mc-button mc-submit-button text-capitalize"
                          type="button"
                          (click)="onFixConsumerHistory()">
                    {{'cc.common.fix-it' | translate}}
                  </button>

                  &lt;!&ndash;TODO: need to test why notify message component not working on this page!&ndash;&gt;
                  &lt;!&ndash;This is solution until we fix notify message showing&ndash;&gt;
                  <span class="text-uppercase" *ngIf="fixedConsumerHistoryStatus">
                    {{fixedConsumerHistoryStatus}}
                  </span>
                </div>

                &lt;!&ndash;Fix transactions&ndash;&gt;
                <div class="col-md-12">
                  <span class="text-capitalize">
                    {{"cc.common.fix-transactions" | translate}}:
                  </span>
                  <button class="mx-4 mc-button mc-submit-button text-capitalize"
                          type="button"
                          (click)="onFixTransactions()">
                    {{'cc.common.fix-it' | translate}}
                  </button>

                  &lt;!&ndash;TODO: need to test why notify message component not working on this page!&ndash;&gt;
                  &lt;!&ndash;This is solution until we fix notify message showing&ndash;&gt;
                  <span class="text-uppercase" *ngIf="fixedTransactionsStatus">
                    {{fixedTransactionsStatus}}
                  </span>
                </div>
              </div>-->
            </div>
          </div><!--Basic info /end-->
          <div class="row py-5">
            <div class="col-md-12">
              <h5 class="mc-entity-profile-title ml-2" style="cursor: pointer" [routerLink]="['/entity/'+entityId+'/pricelists/services-and-pricelist']"><i class="fas fa-tags"></i> {{'cc.pricelist.services-and-pricelist' | translate}}</h5>
            </div>
          </div>
        </ng-template>
      </li>



      <!--Bank accounts section-->
      <li [ngbNavItem]="2" id="bankAccountsTab">
        <a ngbNavLink>{{ 'cc.common.bank-accounts' | translate }}</a>
        <ng-template ngbNavContent>
          <div class="row mc-bank-accounts py-5">
            <div class="col-md-12">
              <mc-bank-accounts-current-entity-admin></mc-bank-accounts-current-entity-admin>
            </div>
          </div>
        </ng-template>
      </li>

      <!--Attributes section-->
      <li [ngbNavItem]="3" id="attributesTab">
        <a ngbNavLink>{{ 'cc.common.view.attributes' | translate }}</a>
        <ng-template ngbNavContent>
          <div class="row mc-attributes py-5">
            <div class="col-md-12">

              <!--Entity attributes Modal-->
              <mc-edit-entity-attributes-modal [editEntityId]="editEntityId"
                                               [markedInheritedAttributes]="currentEntityInheritedAttributes"
                                               [localAttributesData]="customAttributeList"
                                               [inheritedAttributesData]="inheritedAttributeList"
                                               (entityAttributesEvent)="onSuccessEditAttributes($event)"></mc-edit-entity-attributes-modal>

              <!--Table wrapper-->
              <div class="mc-attributes">

                <div class="row">
                  <!--Local attributes-->
                  <div class="col-md-6">
                    <h5 class="attributes-title-color">{{'cc.entity-profile.view.local-attributes' | translate}}</h5>
                    <div class="mc-table-local-attributes-wrapper pr-4">
                      <table class="table table-borderless mc-table-local-attributes">
                        <thead class="mc-table-header-local-attributes">
                        <tr>
                          <th class="text-uppercase"></th>
                          <th class="text-uppercase"></th>
                          <th class="text-uppercase"></th>
                          <th class="text-uppercase mc-header-text">NAME</th>
                          <th class="text-uppercase mc-header-text">TYPE</th>
                          <th
                            class="text-uppercase mc-header-text">{{ 'cc.common.view.default-value' | translate }}</th>
                          <th class="text-uppercase"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let attribute of customAttributeList; let i = index" class="border-bottom-row">
                          <td>
                            <img
                              [src]="attribute.enabled === true ? attributesIcons.enable.path : attributesIcons.disable.path"
                              [alt]="attribute.enabled === true ? attributesIcons.enable.alt : attributesIcons.disable.alt">
                          </td>
                          <td>
                            <img
                              [src]="attribute.inheritable === true ? attributesIcons.inheritable.path : attributesIcons.notInheritable.path"
                              [alt]="attribute.inheritable === true ? attributesIcons.inheritable.alt : attributesIcons.notInheritable.alt">
                          </td>
                          <td>
                            <img
                              [src]="attribute.required === true ? attributesIcons.required.path : attributesIcons.notRequired.path"
                              [alt]="attribute.required === true ? attributesIcons.required.alt : attributesIcons.notRequired.alt">
                          </td>
                          <td>{{attribute.name}}</td>
                          <td [ngSwitch]="attribute.type">
                            <span
                              *ngSwitchCase="'INTEGER'">{{attribute.length ? ('Number' + '(' + attribute.length + ')') : 'Number'}}</span>
                            <span
                              *ngSwitchCase="'VARCHAR'">{{attribute.length ? ('String' + '(' + attribute.length + ')') : 'String'}}</span>
                            <span
                              *ngSwitchCase="'DATETIME'">{{attribute.length ? ('Date Time' + '(' + attribute.length + ')') : 'Date Time'}}</span>
                            <span
                              *ngSwitchCase="'DATE'">{{attribute.length ? ('Date ' + '(' + attribute.length + ')') : 'Date '}}</span>
                            <span
                              *ngSwitchCase="'FLOAT'">{{attribute.length ? ('Decimal' + '(' + attribute.length + ')') : 'Decimal'}}</span>
                            <span
                              *ngSwitchCase="'BOOLEAN'">{{attribute.length ? ('Yes/No' + '(' + attribute.length + ')') : 'Yes/No'}}</span>
                          </td>
                          <td *ngIf="attribute.defaultValue" [ngSwitch]="attribute.type">
                            <span
                              *ngSwitchCase="'DATETIME'">{{attribute.defaultValue.day + "/" + attribute.defaultValue.month + "/" + attribute.defaultValue.year}}</span>
                            <span *ngSwitchDefault>{{attribute.defaultValue}}</span>
                          </td>
                          <td *ngIf="!attribute.defaultValue"></td>
                          <td>
                            <img [src]="hoveredIcon === i ? attributesWhiteInfoIcon : attributesInfoIcon"
                                 class="mc-attribute-info" (mouseover)="hoveredIcon = i" (mouseout)="hoveredIcon = null"
                                 [alt]="attributesIcons.info.alt" placement="left"
                                 tooltipClass="description-attribute">
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!--Inherited attributes-->
                  <div *ngIf="parentEntityId" class="col-md-6">
                    <h5 class="attributes-title-color">Inherited parent attributes</h5>
                    <div class="mc-table-inherited-attributes-wrapper">
                      <table class="table table-borderless mc-table-inherited-attributes">
                        <thead class="mc-table-header-inherited-attributes">
                        <tr>
                          <th class="text-uppercase"></th>
                          <th class="text-uppercase"></th>
                          <th class="text-uppercase"></th>
                          <th class="text-uppercase mc-header-text">NAME</th>
                          <th class="text-uppercase mc-header-text">TYPE</th>
                          <th
                            class="text-uppercase mc-header-text">{{ 'cc.common.view.default-value' | translate }}</th>
                          <th class="text-uppercase"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let attribute of inheritedAttributeList; let i = index" class="border-bottom-row"
                            [ngClass]="{'mc-select-background' : currentEntityInheritedAttributes?.includes(attribute.name)}">
                          <td>
                            <img
                              [src]="attribute.enabled === true ? attributesIcons.enable.path : attributesIcons.disable.path"
                              [alt]="attribute.enabled === true ? attributesIcons.enable.alt : attributesIcons.disable.alt">
                          </td>
                          <td>
                            <img
                              [src]="attribute.inheritable === true ? attributesIcons.inheritable.path : attributesIcons.notInheritable.path"
                              [alt]="attribute.inheritable === true ? attributesIcons.inheritable.alt : attributesIcons.notInheritable.alt">
                          </td>
                          <td>
                            <img
                              [src]="attribute.required === true ? attributesIcons.required.path : attributesIcons.notRequired.path"
                              [alt]="attribute.required === true ? attributesIcons.required.alt : attributesIcons.notRequired.alt">
                          </td>
                          <td>{{attribute.name}}</td>
                          <td [ngSwitch]="attribute.type">
                            <span
                              *ngSwitchCase="'INTEGER'">{{attribute.length ? ('Number' + '(' + attribute.length + ')') : 'Number'}}</span>
                            <span
                              *ngSwitchCase="'VARCHAR'">{{attribute.length ? ('String' + '(' + attribute.length + ')') : 'String'}}</span>
                            <span
                              *ngSwitchCase="'DATETIME'">{{attribute.length ? ('Date Time' + '(' + attribute.length + ')') : 'Date Time'}}</span>
                            <span
                              *ngSwitchCase="'DATE'">{{attribute.length ? ('Date ' + '(' + attribute.length + ')') : 'Date '}}</span>
                            <span
                              *ngSwitchCase="'FLOAT'">{{attribute.length ? ('Decimal' + '(' + attribute.length + ')') : 'Decimal'}}</span>
                            <span
                              *ngSwitchCase="'BOOLEAN'">{{attribute.length ? ('Yes/No' + '(' + attribute.length + ')') : 'Yes/No'}}</span>
                          </td>
                          <td>{{attribute.defaultValue}}</td>
                          <td>
                            <img [src]="hoveredIcon === 'inherit' + i ? attributesWhiteInfoIcon : attributesInfoIcon"
                                 class="mc-attribute-info" (mouseover)="hoveredIcon = 'inherit' + i"
                                 (mouseout)="hoveredIcon = null" [alt]="attributesIcons.info.alt" placement="left"
                                 tooltipClass="description-attribute">
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div><!--Attributes /end-->

          <!--Else custom attributes-->
          <div class="mc-notify-wrapper">
            <mc-notify-message [message]="customAttributesMessage" [type]="customAttributesType"></mc-notify-message>
          </div>
        </ng-template>
      </li>

      <!--Password policy section-->
      <li [ngbNavItem]="4" id="passwordPolicyTab">
        <a ngbNavLink>{{'cc.common.view.password-policy' | translate}}</a>
        <ng-template ngbNavContent>
          <div class="row py-5">
            <div class="col-md-12">
              <!--Entity password policy Modal-->
              <mc-edit-entity-password-policy-modal [editEntityId]="editEntityId" [parentData]="passwordPolicyObject"
                                                    (entityPasswordPolicyEvent)="onSuccessEditPasswordPolicy($event)"></mc-edit-entity-password-policy-modal>

              <!--Content row-->
              <div class="row pt-1 px-2" *ngIf="passwordPolicyObject">
                <!--Left column-->
                <div class="col-md-6">

                  <!--Maximum retry count-->
                  <div class="row">
                    <div class="col-md-5 text-uppercase set-font">{{ 'cc.common.edit.maximum-retry-count' | translate }}
                      :
                    </div>
                    <div
                      class="col-md-7 h6 text-muted position-top">{{passwordPolicyObject.maxRetryCount === 1 ? passwordPolicyObject.maxRetryCount + ' time' : passwordPolicyObject.maxRetryCount + ' times'}}</div>
                  </div>

                  <!--Lockout time-->
                  <div class="row">
                    <div class="col-md-5 text-uppercase set-font">{{ 'cc.common.edit.lockout-time' | translate }}:</div>
                    <div
                      class="col-md-7 h6 text-muted position-top">{{passwordPolicyObject.lockoutTime === 1 ? passwordPolicyObject.lockoutTime + ' minute' : passwordPolicyObject.lockoutTime + ' minutes'}}</div>
                  </div>

                  <!--Minimum password length-->
                  <div class="row">
                    <div class="col-md-5 text-uppercase set-font">min. password length:</div>
                    <div
                      class="col-md-7 h6 text-muted position-top">{{passwordPolicyObject.minPassLength === 1 ? passwordPolicyObject.minPassLength + ' character' : passwordPolicyObject.minPassLength + ' characters'}}</div>
                  </div>

                  <!--Password expiration period-->
                  <div class="row">
                    <div
                      class="col-md-5 text-uppercase set-font">{{ 'cc.common.edit.password-expiration-period' | translate }}
                      :
                    </div>
                    <div
                      class="col-md-7 h6 text-muted position-top">{{passwordPolicyObject.passMaxDays === 1 ? passwordPolicyObject.passMaxDays + ' day' : passwordPolicyObject.passMaxDays + ' days'}}</div>
                  </div>

                  <!--Minimum different characters-->
                  <div class="row">
                    <div class="col-md-5 text-uppercase set-font">min. different characters:</div>
                    <div
                      class="col-md-7 h6 text-muted position-top">{{passwordPolicyObject.minDiffChars === 1 ? passwordPolicyObject.minDiffChars + ' character' : passwordPolicyObject.minDiffChars + ' characters'}}</div>
                  </div>

                </div><!--Left column /end-->

                <!--Right column-->
                <div class="col-md-6">

                  <!--Minimum different passwords-->
                  <div class="row">
                    <div class="col-md-5 text-uppercase set-font">min different passwords:</div>
                    <div
                      class="col-md-7 h6 text-muted right-col-position">{{passwordPolicyObject.minPassHistory === 1 ? passwordPolicyObject.minPassHistory + ' password' : passwordPolicyObject.minPassHistory + ' passwords'}}</div>
                  </div>

                  <!--Minimum digits-->
                  <div class="row">
                    <div class="col-md-5 text-uppercase set-font">min. digits:</div>
                    <div
                      class="col-md-7 h6 text-muted right-col-position">{{passwordPolicyObject.minDigits === 1 ? passwordPolicyObject.minDigits + ' digit' : passwordPolicyObject.minDigits + ' digits'}}</div>
                  </div>

                  <!--Minimum special characters-->
                  <div class="row">
                    <div class="col-md-5 text-uppercase set-font">min. special characters:</div>
                    <div
                      class="col-md-7 h6 text-muted right-col-position">{{passwordPolicyObject.minSpecialChars === 1 ? passwordPolicyObject.minSpecialChars + ' character' : passwordPolicyObject.minSpecialChars + ' characters'}}</div>
                  </div>

                  <!--Minimum lowercase letters-->
                  <div class="row">
                    <div class="col-md-5 text-uppercase set-font">min. lowercase letters:</div>
                    <div
                      class="col-md-7 h6 text-muted right-col-position">{{passwordPolicyObject.minLowercaseLetters === 1 ? passwordPolicyObject.minLowercaseLetters + ' letter' : passwordPolicyObject.minLowercaseLetters + ' letters'}}</div>
                  </div>

                  <!--Minimum uppercase letters-->
                  <div class="row">
                    <div class="col-md-5 text-uppercase set-font">min. uppercase letters:</div>
                    <div
                      class="col-md-7 h6 text-muted right-col-position">{{passwordPolicyObject.minUppercaseLetter === 1 ? passwordPolicyObject.minUppercaseLetter + ' letter' : passwordPolicyObject.minUppercaseLetter + ' letters'}}</div>
                  </div>

                </div><!--Right column /end-->

              </div><!--Content row /end-->

              <!--Else basic info-->
              <div class="mc-notify-wrapper">
                <mc-notify-message [message]="passPolicyMessage" [type]="passPolicyType"></mc-notify-message>
              </div>
            </div>
          </div><!--Password policy /end-->
        </ng-template>
      </li>

      <!--Factoring section-->
      <li [ngbNavItem]="5" id="factoringTab">
        <a ngbNavLink>Factoring</a>
        <ng-template ngbNavContent>
          <mc-entity-factoring-wrapper-part-parent *ngIf="isLoggedEntity"></mc-entity-factoring-wrapper-part-parent>
          <mc-entity-factoring-wrapper-part-child *ngIf="!isLoggedEntity"></mc-entity-factoring-wrapper-part-child>
        </ng-template>
      </li>


      <!--Payment info section-->
    <li [ngbNavItem]="6" *ngIf="!isLoggedEntity" id="paymentInfoTab">
      <a ngbNavLink>{{'cc.common.view.payment-info' | translate}}</a>
      <ng-template ngbNavContent>
        <div class="row py-5">
          <div class="col-md-12 mc-payment-info">
            <lib-mc-invoice-overview
              [entityId]="entityId"
              [name]="entityName">
            </lib-mc-invoice-overview>
          </div>
        </div>
      </ng-template>
    </li>

      <ng-container *ngIf="mcGod.userHasMcEntityApiKeyViewPermission()">
        <li [ngbNavItem]="7" *ngIf="!isLoggedEntity" id="apiSettingsTab">
          <a ngbNavLink>{{'cc.entity.api-settings' | translate}}</a>
          <ng-template ngbNavContent>
            <div class="row py-5">
              <div class="col-md-12">
                <div class="d-flex">

                  <ng-container *ngIf="!entityApiKey">
                    <mcc-fi-textbox
                      [mcForm]="mcForm" label="{{'cc.entity.entity-key' | translate}}:"
                      name="ctrlEntityKey"></mcc-fi-textbox>
                    <div style="align-self: center; margin-top: 18px;margin-left: 20px;">
                      <button *ngIf="mcGod.userHasMcEntityApiKeyEditPermission()" class="btn btn-secondary" (click)="generateEntityKey()">{{'cc.entity.generate' | translate}}</button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="entityApiKey">
                    <mcc-fi-textbox
                      [mcForm]="mcForm" label="{{'cc.entity.entity-key' | translate}}:" [value]="entityApiKey"
                      name="ctrlEntityKey"></mcc-fi-textbox>
                    <div style="align-self: center; margin-top: 18px;margin-left: 20px;">
                      <button *ngIf="mcGod.userHasMcEntityApiKeyEditPermission()" class="btn btn-secondary" (click)="generateEntityKey()">{{'cc.entity.regenerate' | translate}}</button>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ng-container>


    <!--Payment info section-->
    <li [ngbNavItem]="8" *ngIf="!isLoggedEntity" id="settingsTab">
      <a ngbNavLink>Settings</a>
      <ng-template ngbNavContent>
        <div class="row py-5">
          <div class="col-md-12 mc-payment-info">
            <h5 class="mc-entity-profile-title ml-2">Pause days<i *ngIf="mcGod.userHasMcEntityEditMaxTransactionPauseDaysPermission()" class="fa fa-pen mc-entity-action-btn ml-2" (click)="actPauseDays()"></i></h5>
            <div class="row">
              <div class="col-md-6">
                <h6 class="pause-title-color">Maximal pause days: {{maximalPauseDays}}</h6>
              </div>
            </div>
            <mcc-modal1-wrapper [showMcb]="showPauseDaysMcb" *ngIf="showPauseDaysMcb.value" modalSizeCd="small">
              <app-shared-transactions-pause-days-part (eventSaved)="onPauseDaysSaved($event)"
              (eventCanceled)="onPauseDaysCanceled($event)"></app-shared-transactions-pause-days-part>
            </mcc-modal1-wrapper>
          </div>
        </div>

        <div class="row py-5">
          <div class="col-md-12 mc-payment-info">
            <h5 class="mc-entity-profile-title ml-2">{{'cc.factoring.transaction-description' | translate}}
              <i *ngIf="mcGod.userHasMcEntityAddEditTransactionDescriptionPermission() && isCreate" class="fa fa-plus-circle mc-entity-action-btn ml-2 mr-2" (click)="actTransactionDescription()"></i>
              <i *ngIf="mcGod.userHasMcEntityAddEditTransactionDescriptionPermission() && !isCreate" class="fa fa-pen mc-entity-action-btn" (click)="actTransactionDescription()"></i>
            </h5>
            <div class="row">
              <div class="col-md-6">
                <h6 class="pause-title-color">{{'cc.factoring.transaction-description' | translate }}: <span class="transaction-description-info">{{transactionDescription ? transactionDescription : '-'}}</span></h6>
              </div>
            </div>
            <mcc-modal1-wrapper [showMcb]="showTransactionDescriptionMcb" *ngIf="showTransactionDescriptionMcb.value" modalSizeCd="small">
              <mc-entity-transaction-description-modal (eventSaved)="onTransactionDescriptionSaved($event)"
                                                       (eventCanceled)="onTransactionDescriptionCanceled($event)"
                                                       [isCreate]="isCreate">
              </mc-entity-transaction-description-modal>
            </mcc-modal1-wrapper>
          </div>
        </div>
      </ng-template>
    </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div><!--Mc entity profile wrapper /end -->

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
