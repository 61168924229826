import {ImDataImportItemFilterGENERATED} from '../_generated/im-data-import-item-filter-generated.model';

export class ImDataImportItemFilter extends ImDataImportItemFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataImportItemFilter();
  }

  // ---------------------------------------------------------------------
}
