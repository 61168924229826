import {BaseObject} from '@miticon-ui/mc-core';

export class MccFiTable3CellEditedData {
  public dataItemObj: BaseObject;
  public propertyName: string;
  public newValue: string;

  constructor(dataItemObj: BaseObject, propertyName: string, newValue: string) {
    this.dataItemObj = dataItemObj;
    this.propertyName = propertyName;
    this.newValue = newValue;
  }


}
