/*BbmTestObj*/
import { McDateUtils } from '../common/mc-date-utils';
import { McBillingPayoutFileItemFeCtdGENERATED } from '../_generated/mc-billing-payout-file-item-fe-ctd-generated.model';

export class McBillingPayoutFileItemFeCtd extends McBillingPayoutFileItemFeCtdGENERATED {
  totalAmountFormatted!: string;
  totalPayoutAmount = 0;
  payoutDate!: string;
  accountOwner = '';

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingPayoutFileItemFeCtd();
  }

  // ---------------------------------------------------------------------
  getPayoutDate(): string {
    if (this.payoutDate) {
      return McDateUtils.newFormatDateString(this.payoutDate);
    }
    return '';
  }

  public override populateFromJson(aSourceJson: any) {
    super.populateFromJson(aSourceJson);
    this.payoutDate = aSourceJson.payoutDate;
    this.totalPayoutAmount = aSourceJson.totalPayoutAmount;
    this.accountOwner = aSourceJson.accountOwner;
  }
}
