
import {Table3Config} from '../extra/table-3-config';

// import {McBillingService2VatGENERATED} from '../_generated/mc-billing-service-2-vat-generated.model';

export class McBillingService2VatTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colIdBillingService', 'Id of billing service', 'idBillingService', 'idBillingService');
    this.addColumn('colIdVat', 'Id of VAT', 'idVat', 'idVat');

*/
  }
}
