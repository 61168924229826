import {PpPriceAdjustment} from './pp-price-adjustment.model';
import {PpPriceAdjustmentListGENERATED} from '../_generated/pp-price-adjustment-list-generated.model';

export class PpPriceAdjustmentList extends PpPriceAdjustmentListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PpPriceAdjustmentList();
  }

  // ---------------------------------------------------------------------
}
