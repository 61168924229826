import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {McForm, McGod, McMappingList} from "@miticon-ui/mc-core";

@Component({
  selector: 'mc-consumer-choose-mapping',
  templateUrl: './mc-consumer-choose-mapping.component.html',
})
export class McConsumerChooseMappingComponent implements OnInit {

  /*Variables*/
  applyMappingDisableFlg = true;
  optionsVlm = new Map<string, string>();
  mcForm = new McForm();
  @Input() templateMappings!: McMappingList;
  @Input() currentTemplateMappingName!: string;
  @Output() cancelEmit = new EventEmitter();
  @Output() chosenTemplateMappingEmit = new EventEmitter();

  ngOnInit() {
    this.setOptionsForRadioButtons();
  }

  actCancel() {
    this.cancelEmit.emit();
  }

  actApplyMapping() {
    const selectedTemplateMappingName = this.mcForm.getValue('templateMapping');
    let chosenTemplateMapping;
    chosenTemplateMapping = this.templateMappings.items.find( item => item.mappingName === selectedTemplateMappingName);
    this.chosenTemplateMappingEmit.emit(chosenTemplateMapping);
  }

  setOptionsForRadioButtons() {
    let optionValue;
    this.templateMappings.items.forEach( item => {

      const mappingFromLoggedEntity = (item.idMcEntity === McGod.getLoggedEntityIdFromToken());
      if (item.sharedFlg && mappingFromLoggedEntity) {
        optionValue = `${item.mappingName} [shared by ${item.sharedByUserName}]`;
      } else if (item.sharedFlg && !mappingFromLoggedEntity) {
        optionValue = `${item.mappingName} [shared by entity ${item.sharedByEntityName}]`;
      } else {
        optionValue = item.mappingName;
      }
      this.optionsVlm.set(item.mappingName, optionValue);
    });
  }

  templateMappingValueChanged(mappingName: string) {
    this.applyMappingDisableFlg = false;
  }
}
