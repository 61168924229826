import {PpOrderItemFilterGENERATED} from '../_generated/pp-order-item-filter-generated.model';

export class PpOrderItemFilter extends PpOrderItemFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PpOrderItemFilter();
  }

  // ---------------------------------------------------------------------
}
