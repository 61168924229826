/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CmnEmailFilter} from '../models/cmn-email-filter.model';

export class CmnEmailFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CmnEmailFilter {
    console.error('MTCN-ERROR: Not Implemented: CmnEmailFilter::createNewInstance(). Add this method to final class and return new CmnEmailFilter();');
      throw new Error('Cannot CmnEmailFilter::createNewInstance(). Add this method to final class and return new CmnEmailFilter();');
  }

}
