/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McProcessFlowFilter} from '../models/mc-process-flow-filter.model';

export class McProcessFlowFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProcessFlowFilter {
    console.error('MTCN-ERROR: Not Implemented: McProcessFlowFilter::createNewInstance(). Add this method to final class and return new McProcessFlowFilter();');
      throw new Error('Cannot McProcessFlowFilter::createNewInstance(). Add this method to final class and return new McProcessFlowFilter();');
  }

}
