/*BbmTestObj*/
import {McActionPdfTemplateGENERATED} from '../_generated/mc-action-pdf-template-generated.model';

export class McActionPdfTemplate extends McActionPdfTemplateGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McActionPdfTemplate();
  }

  // ---------------------------------------------------------------------
}
