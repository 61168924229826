/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CsrContractFilter} from '../models/csr-contract-filter.model';

export class CsrContractFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrContractFilter {
    console.error('MTCN-ERROR: Not Implemented: CsrContractFilter::createNewInstance(). Add this method to final class and return new CsrContractFilter();');
      throw new Error('Cannot CsrContractFilter::createNewInstance(). Add this method to final class and return new CsrContractFilter();');
  }

}
