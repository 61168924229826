<div class="mcc-slide-action-set-spacer"></div>
<div *ngIf="selectedNumber >= showSlideActionSetNoOfItems" class="mcc-slide-action-set mcc-slide-action-set-collapsed">
  <ul>
      <li *ngFor="let actionItem of actionSet.items"
          [ngClass]="(selectedNumber > actionItem.numberOfMaxSelectedItems && actionItem.numberOfMaxSelectedItems !== -1) ? 'mc-disable' : ''"
          class="mc-action-{{actionItem.actionCdForEvent.toLowerCase()}} {{actionItem.extraCssClass}}">

        <a *ngIf="!actionItem.divider" (click)="actClickedOnItem(actionItem)">{{actionItem.label}}</a>

        <span *ngIf="actionItem.divider"></span>

      </li>
  </ul>
</div>
