/*BbmTestObj*/
import {CsrBankAccountGENERATED} from '../_generated/csr-bank-account-generated.model';

export class CsrBankAccount extends CsrBankAccountGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrBankAccount();
  }

  // ---------------------------------------------------------------------
}
