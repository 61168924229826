import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { CsrContract, EbFactoring, McGod, McProcess } from '@miticon-ui/mc-core';
import {MatMenuTrigger} from "@angular/material/menu";
import {element} from "protractor";

export interface MkMatMenuItem {
  disabled?: boolean;
  matIcon: string;
  title: string;
  actionCd: string;
  permission: string,
}

@Component({
  selector: 'mk-mat-table-actions',
  templateUrl: './mk-mat-table-actions.component.html',
  styleUrls: ['./mk-mat-table-actions.component.scss']
})
export class MkMatTableActionsComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input() matMenuItems: MkMatMenuItem[];
  @Input() element: any;
  @Output() menuItemAction = new EventEmitter<MkMatMenuItem>();
  mcGod = McGod.getInstance();


  constructor() {
  }


  ngOnInit() {
  }

  isProcessMenuItemHidden(item: MkMatMenuItem) {
    const actionMap = new Map([
      [McProcess.STATUS_CD_ACTIVE, McProcess.ACTION_ACTIVATE],
      [McProcess.STATUS_CD_INACTIVE, McProcess.ACTION_DEACTIVATE],
      [McProcess.TYPE_CD_NOTIFICATIONS, McProcess.ACTION_SHOW_ITEM_LIST],
      [McProcess.TYPE_CD_DUNNING || McProcess.TYPE_CD_MARKETING, McProcess.ACTION_SHOW_EMAIL]
    ]);

    const isMatch = (key: string, action: string) => actionMap.get(key) === action;

    if (this.element instanceof McProcess) {
      const isProcessActive = isMatch(this.element.statusCd, item.actionCd);
      const isProcessInactive = isMatch(this.element.statusCd, item.actionCd);
      const isNotification = isMatch(this.element.typeCd, item.actionCd);
      const isDunningOrMarketing = isMatch(this.element.typeCd, item.actionCd);

      return isProcessActive || isProcessInactive || isNotification || isDunningOrMarketing;
    }

    return false;
  }

  isDisabled(item: MkMatMenuItem) {
    if (this.isCsrContractCanceled(item) || this.isEbFactoringNotPending(item)) {
      return true;
    }
    return item.disabled;
  }

  private isCsrContractCanceled(item: MkMatMenuItem): boolean {
    return this.element instanceof CsrContract &&
      this.element.statusCd === CsrContract.STATUS_CD_CANCELED &&
      item.actionCd === 'Cancel';
  }

  private isEbFactoringNotPending(item: MkMatMenuItem): boolean {
    return this.element instanceof EbFactoring &&
      this.element.statusCd !== EbFactoring.STATUS_CD_PENDING_ACCEPTANCE &&
      (item.actionCd === EbFactoring.ACTION_SHOW_REVOKE ||
        item.actionCd === EbFactoring.ACTION_SHOW_REJECT ||
        item.actionCd === EbFactoring.ACTION_SHOW_ACCEPT);
  }


  mouseEnter() {
    this.trigger.openMenu();
  }

  mouseLeave() {
    this.trigger.closeMenu();
  }

  matMenuItemClicked(item: MkMatMenuItem): void {
    this.menuItemAction.emit(item);
  }

  addMenuItem(item: MkMatMenuItem) {
    this.matMenuItems.push(item);
  }
}
