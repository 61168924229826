import { BaseObjectService } from './base-object-service';
import { McApiErrorResponse } from './mc-api-error-response.model';
import { McValueLabel } from './mc-value-label';
import { Base } from '../_core/base.model';
import { McGod } from '../extra/mc-god.service';
import { McHtml } from '../_core/mc-html';

export abstract class BaseObject extends Base {
  id: any;
  apiService: any;
  apiLoadingFlg = false;
  apiSuccessFlg = true;
  apiErrorFlg = false;
  apiErrorCd = '';
  apiErrorMessage!: string;
  _rawJson: any;
  itemRouteLink!: string;
  title!: string;
  selectedFlg = false;
  favoriteFlg = false;
  onSelectionChangedHandler?: (aItem: BaseObject) => void;
  properties: string[] = ['id'];
  propertiesRegular: string[] = ['id'];
  propertiesSpecial: string[] = [];
  navigation: string;

  constructor(aApiService: BaseObjectService | undefined) {
    super();
    this.apiService = aApiService;
  }

  public clear() {
    //TODO
  }

  // ---------------------------------------------------------------------
  public apiReset() {
    this.apiSuccessFlg = true;
    this.apiErrorFlg = false;
    this.apiLoadingFlg = false;
    this.apiErrorMessage = '';
    this.apiErrorCd = '';
  }

  // ---------------------------------------------------------------------
  public setApiLoading() {
    this.apiSuccessFlg = false;
    this.apiErrorFlg = false;
    this.apiLoadingFlg = true;
    this.apiErrorMessage = '';
    this.apiErrorCd = '';
  }

  // ---------------------------------------------------------------------
  public setApiSuccess() {
    this.apiSuccessFlg = true;
    this.apiErrorFlg = false;
    this.apiLoadingFlg = false;
    this.apiErrorMessage = '';
    this.apiErrorCd = '';
  }

  // ---------------------------------------------------------------------
  public setApiErrorData(aErrorResponse: string) {
    const mcApiErrorResponse = McApiErrorResponse.createNew(aErrorResponse);
    this.apiErrorMessage = mcApiErrorResponse.message;
    this.apiErrorCd = mcApiErrorResponse.code;
  }

  // ---------------------------------------------------------------------
  public setApiError() {
    this.apiSuccessFlg = false;
    this.apiErrorFlg = true;
    this.apiLoadingFlg = false;
  }

  // ---------------------------------------------------------------------
  public getCssClasses(): any {
    const result = {
      'in-favorites': this.favoriteFlg,
      selected: this.selectedFlg,
    };
    return result;
  }

  // ---------------------------------------------------------------------
  public toggleSelected() {
    this.selectedFlg = !this.selectedFlg;
    this.fireEventOnSelectionChange();
  }

  // ---------------------------------------------------------------------
  public select(): void {
    this.selectedFlg = true;
    this.fireEventOnSelectionChange();
  }

  // ---------------------------------------------------------------------
  public deselect(): void {
    this.selectedFlg = false;
    this.fireEventOnSelectionChange();
  }

  // ---------------------------------------------------------------------
  public isSelected() {
    return this.selectedFlg;
  }

  // ---------------------------------------------------------------------
  public getCurrentClassName(): string {
    const currentObjectConstructor: any = this.constructor;

    return currentObjectConstructor.name;
  }

  protected processApiSuccess(
    aResponseObj: any,
    onSuccessCallback?: () => void
  ) {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
    this.setApiSuccess();
  }

  protected processApiError(aErrorResponse: any, onErrorCallback?: () => void) {
    this.setApiErrorData(aErrorResponse);
    if (onErrorCallback) {
      onErrorCallback();
    }
    this.setApiError();
  }

  // ---------------------------------------------------------------------
  public loadById(
    aId: number,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
  ): void {
    this.setApiLoading();
    this.apiService.getById(aId).subscribe(
      (responseObj: any) => {
        this.populateFromJson(responseObj);
        this.processApiSuccess(responseObj, onSuccessCallback);
      },
      (errorResponse: any) => {
        this.processApiError(errorResponse, onErrorCallback);
      }
    );
  }

  // ---------------------------------------------------------------------
  public save(
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
  ): void {
    if (this.id > 0) {
      this._update(onSuccessCallback, onErrorCallback);
    } else {
      this._insert(onSuccessCallback, onErrorCallback);
    }
  }

  // ---------------------------------------------------------------------
  protected _update(
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
  ): void {
    this.setApiLoading();
    this.apiService.update(this).subscribe(
      (responseObj: any) => {
        this.populateFromJson(responseObj);
        this.processApiSuccess(responseObj, onSuccessCallback);
      },
      (errorResponse: any) => {
        this.processApiError(errorResponse, onErrorCallback);
      }
    );
  }

  // ---------------------------------------------------------------------
  protected _insert(
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
  ): void {
    this.setApiLoading();
    this.apiService.insert(this).subscribe(
      (responseObj: any) => {
        this.populateFromJson(responseObj);
        this.processApiSuccess(responseObj, onSuccessCallback);
      },
      (errorResponse: any) => {
        this.processApiError(errorResponse, onErrorCallback);
      }
    );
  }

  // ---------------------------------------------------------------------
  public delete(
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
  ): void {
    this.setApiLoading();
    this.apiService.deleteById(this.id).subscribe(
      (response: any) => {
        this.processApiSuccess(response, onSuccessCallback);
      },
      (errorResponse: any) => {
        this.processApiError(errorResponse, onErrorCallback);
      }
    );
  }

  // ---------------------------------------------------------------------
  public populateFromObject(aSourceObject: BaseObject) {
    this.properties.forEach((key) => {
      if (aSourceObject.hasOwnProperty(key)) {
        // @ts-ignore
        this[key] = aSourceObject[key];
      }
    });
  }

  protected abstract _populateFromJson_specialProperties(): any;

  // ---------------------------------------------------------------------
  public populateFromJson(aSourceJson: any) {
    this._rawJson = aSourceJson;
    this.propertiesRegular.forEach((key) => {
      if (aSourceJson.hasOwnProperty(key)) {
        // @ts-ignore
        this[key] = aSourceJson[key];
      }
    });
    this._populateFromJson_specialProperties();
  }

  // ---------------------------------------------------------------------
  public setOnSelectionChangedEventHandler(
    aOnSelectionChangedCallback?: (aItem: BaseObject) => void
  ) {
    this.onSelectionChangedHandler = aOnSelectionChangedCallback;
  }

  // ---------------------------------------------------------------------
  private fireEventOnSelectionChange() {
    if (this.onSelectionChangedHandler) {
      this.onSelectionChangedHandler(this);
    }
  }

  // ---------------------------------------------------------------------
  public execPropertyOrMethodByName(
    aPropertyNameOrMethodNameWithParentheses: string
  ): any {
    let result: any = null;
    if (aPropertyNameOrMethodNameWithParentheses != null) {
      if (aPropertyNameOrMethodNameWithParentheses.includes('.')) {
        const parts = aPropertyNameOrMethodNameWithParentheses.split('.', 2);
        const tempObj = this.execPropertyOrMethodByName(parts[0]);
        if (tempObj) {
          if (typeof tempObj.execPropertyOrMethodByName === 'function') {
            result = tempObj.execPropertyOrMethodByName(parts[1]);
          } else {
            const propName = parts[1];
            result = tempObj.propName;
          }
        } else {
          console.warn(
            'execPropertyOrMethodByName - property or method not found: ',
            parts[0]
          );
        }
      } else if (aPropertyNameOrMethodNameWithParentheses.includes('()')) {
        // method
        const methodName = aPropertyNameOrMethodNameWithParentheses.replace(
          '()',
          ''
        );
        // @ts-ignore
        result = this[methodName](); // call it
      } else {
        // @ts-ignore
        result = this[aPropertyNameOrMethodNameWithParentheses];
      }
    } else {
      throw new Error(
        'BaseObject: execPropertyOrMethodByName called with null aPropertyNameOrMethodNameWithParentheses argument'
      );
    }
    return result;
  }

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = {};
    for (const property of this.properties) {
      // @ts-ignore
      dto[property] = this[property];
    }

    return dto;
  }

  // ---------------------------------------------------------------------
  public exists() {
    return this.id > 0;
  }

  // ---------------------------------------------------------------------
  public addDtoProperty(aPropertyName: string): void {
    this.properties.push(aPropertyName);
  }

  // ---------------------------------------------------------------------
  public toVllItem(
    aValuePropertyOrMethodName: string,
    aLabelPropertyOrMethodName: string
  ): McValueLabel {
    const result = new McValueLabel();

    result.value = this.execPropertyOrMethodByName(aValuePropertyOrMethodName);
    result.label = this.execPropertyOrMethodByName(aLabelPropertyOrMethodName);
    return result;
  }
  // ---------------------------
  public override t(translationKey: string) {
    return McGod.t(translationKey);
  }

  getRouterLink() {
    const html = new McHtml();
    html.addLinkB(
      `<i class="fas fa-link tr-icon"></i> ${this.id}`,
      this.itemRouteLink,
      this,
      '',
      'link-cell',
      ''
    );
    return html.items;
  }
}
