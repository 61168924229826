import {Inject, Injectable} from '@angular/core';
import {EbInterest_rateServiceGENERATED} from '../_generated/eb-interest_rate-generated.service';



@Injectable({
  providedIn: 'root'
})
export class EbInterest_rateService extends EbInterest_rateServiceGENERATED {

}
