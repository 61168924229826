import {McCurrencyListGENERATED} from '../_generated/mc-currency-list-generated.model';

export class McCurrencyList extends McCurrencyListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McCurrencyList();
  }

  // ---------------------------------------------------------------------
}
