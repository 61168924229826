import {Inject, Injectable} from '@angular/core';
import {McBankAccount} from '../models/mc-bank-account.model';
import {McBankAccountServiceGENERATED} from '../_generated/mc-bank-account-generated.service';
import {map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';
import {HttpParams} from "@angular/common/http";
import {resolve} from "@angular/compiler-cli";


@Injectable({
  providedIn: 'root'
})
export class McBankAccountService extends McBankAccountServiceGENERATED {

  entityId!: number;
  bankAccounts: McBankAccount[] = [];

  validateIban(aMcBankAccount: McBankAccount): Observable<any> {
    return this.httpClient.post(`${this.getApiServiceRootUrl()}/validate-iban/`, aMcBankAccount.toDto(), this.httpOptions);
  }

  changeEntity(entityId: number) {
    this.entityId = entityId;

    this.getForEntity().pipe(take(1)).subscribe(response => {
        this.bankAccounts = response;
      });
  }

  checkIfAllEntitiesHaveBankAccount(entityIds: number[]) {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('entityIds', entityIds.toString());
    return this.httpClient.get<boolean>(`${this.getApiServiceRootUrl()}/check-if-all-entities-have-bank-account`, {params: httpParams});
  }

  getDefaultByEntityId(entityId: number): Observable<any> {
    return this.httpClient.get<boolean>(`${this.getApiServiceRootUrl()}/get-primary-by-entity-id/${entityId}`);
  }

}
