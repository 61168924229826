import {BaseObject, McStateManagementService, Table2Config} from "@miticon-ui/mc-core";

export class McConsumersImportsTableConfig extends Table2Config {
  stateManagement: McStateManagementService;
  constructor() {
    super();

    this.addColumn("id", "Id", "id", false);

    this.addColumn("fileType", "File type", "fileType", false);

    // Title Column with Editor Dropdown

    this.addColumn("createdBy", "Created by", "createdBy", true);

    this.addColumn("createdAt", "Created at", "createdAtFormat()", false);

    this.addColumn("updatedBy", "Updated by", "updatedBy", true);

    this.addColumn("updatedAt", "Updated At", "updatedAtFormat()", false);

    // Other Columns
    this.addColumn("processedRecords", "Processed records", "processedRecords", false);

    this.addColumn("startDateAndTime", "Start date & time", "startDateAndTime", false);

    this.addColumn("statusCd", "Status", "statusCd", false);

  }
}
