import {Component, Inject, OnInit} from '@angular/core';
import {ICON_CLOSE} from "../../../../assets/media/svg_icons/icon-close";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {McGod, McProcessNotification, McProcessNotificationService} from "@miticon-ui/mc-core";

@Component({
  selector: 'lib-mc-process-configuration-edit-dialog',
  templateUrl: './mc-process-configuration-edit-dialog.component.html',
  styleUrls: ['./mc-process-configuration-edit-dialog.component.scss']
})
export class McProcessConfigurationEditDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  form: FormGroup;
  notificationTypes = [{
    value: 'Billing',
    label: 'cc.process.billing-notifications',
    controlName: 'typeBillingChecked'
  },{
    value: 'Payment',
    label: 'cc.process.payment-notifications',
    controlName: 'typePaymentChecked'
  }];

  paymentNotifications = [{
    value: McProcessNotification.EVENT_FILTER_CD_MANUAL_PAYMENT,
    label: 'cc.process.manual-payments'
  },{
    value: McProcessNotification.EVENT_FILTER_CD_DIRECT_DEBIT_PAYMENT,
    label: 'cc.process.direct-debits'
  },{
    value: McProcessNotification.EVENT_FILTER_CD_ALL_PAYMENT,
    label: 'cc.process.all-payments'
  }];
  processId: number;
  processNotification: McProcessNotification;
  billingNotification: McProcessNotification;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { processId: number, processNotifications: McProcessNotification[]},
              public dialogRef: MatDialogRef<McProcessConfigurationEditDialogComponent>,
              private fb: FormBuilder,
              private mcProcessNotificationService: McProcessNotificationService) { }

  ngOnInit(): void {
    this.createForm();

    this.processId = this.data.processId;

    if(this.data.processNotifications) {
      this.data.processNotifications.forEach((notification) => {
        if (notification.eventTypeCd === McProcessNotification.EVENT_TYPE_CD_PAYMENT) {
          this.processNotification = notification;
          this.form.controls['typePaymentChecked'].setValue(true);
          this.form.controls['paymentType'].setValue(notification.eventFilterCd);
        }
        if (notification.eventTypeCd === McProcessNotification.EVENT_TYPE_CD_BILLING) {
          this.billingNotification = notification;
          this.form.controls['typeBillingChecked'].setValue(true);
        }
      });
    }


    this.form.controls['typePaymentChecked'].valueChanges.subscribe((data) => {
      if(!data) {
        this.form.controls['paymentType'].reset();
      }
    })
  }

  createForm() {
    this.form = this.fb.group({
      typePaymentChecked: false,
      typeBillingChecked: false,
      paymentType: ['']
    });
  }

  resetPaymentType() {
    if(!this.form.controls['typePaymentChecked'].value) {
      this.form.controls['paymentType'].reset()
    }
  }

  createOrEditNotificationType() {
    const notifications: McProcessNotification[] = [];

    const addNotification = (
      eventTypeCd: string,
      eventFilterCd: string,
    ) => {
      const notification = new McProcessNotification();
      notification.idMcProcess = this.processId;
      notification.eventFilterCd = eventFilterCd;
      notification.eventTypeCd = eventTypeCd;
      notification.idMcEntity = McGod.getLoggedEntityIdFromToken();
      notification.statusCd = McProcessNotification.STATUS_CD_ACTIVE;
      notification.typeCd = McProcessNotification.TYPE_CD_EMAIL;
      notifications.push(notification);
    };

    if (this.form.controls['typePaymentChecked'].value) {
      addNotification(
        McProcessNotification.EVENT_TYPE_CD_PAYMENT,
        this.form.controls['paymentType'].value
      );
    }

    if (this.form.controls['typeBillingChecked'].value) {
      addNotification(
        McProcessNotification.EVENT_TYPE_CD_BILLING,
        ""
      );
    }

    const serviceCall = this.processId ?
      this.mcProcessNotificationService.editMultiple(notifications, this.processId) :
      this.mcProcessNotificationService.createMultiple(notifications);

    serviceCall.subscribe((data) => {
      if (data) {
        this.dialogRef.close(true);
      }
    });
  }
}
