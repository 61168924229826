/*BbmTestObj*/
import {McBillingBatch2TransactionGENERATED} from '../_generated/mc-billing-batch-2-transaction-generated.model';

export class McBillingBatch2Transaction extends McBillingBatch2TransactionGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingBatch2Transaction();
  }

  // ---------------------------------------------------------------------
}
