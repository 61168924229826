import { Component, EventEmitter, Inject, Input, OnInit, Output, OnDestroy, ViewChild } from '@angular/core';
import {McUtilityService} from '@miticon-ui/mc-core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ICON_FILTER } from '../svg_icons/icon-filter';
import { ICON_DROP_UP } from '../svg_icons/icon-drop-up';
import { ICON_DROP_DOWN } from '../svg_icons/icon-drop-down';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mc-filter',
  templateUrl: './mc-filter.component.html',
  styleUrls: ['./mc-filter.component.scss']
})
export class McFilterComponent implements OnInit, OnDestroy {

  /*Public variables*/
  setup!: any;
  filterList: any[] = [];
  inputFields: any;
  checkboxes!: any;
  dropdowns: any;
  dates: any;
  amountDue: any;
  _filterObject: any;
  selectedFilters: any[] = [];
  filterImage;
  clearFilters = false;
  form: FormGroup;
  @ViewChild('multiselect') multiselect: MatSelect;
  iconFilter = ICON_FILTER;
  dropUpIcon = ICON_DROP_UP;
  dropDownIcon = ICON_DROP_DOWN;
  panelOpenState = false;



  /*Private*/
  private filterConfig;
  private _name!: string;
  private entityId;
  private paramsSubscription: Subscription;

  /*Events*/
  @Input() set name(value: string) {
    this._name = value;
    this.refreshFilterSetup();
  }
  @Input() set filterObject(value: any) {
    if (Object.keys(value).length === 1 && value.hasOwnProperty("fetchWithRoles")) {
      this.selectedFilters = [];
      this.inputFields.filter((item: any) => item.value = "");
      this.checkboxes.filter((item: any) => {
        item.value = "";
        item.checked = false;
      });
      this.dropdowns.filter((item: any) => item.value = "");
      this.dates.filter((item: any) => item.value = "");
      this.amountDue.filter((item: any) => item.value = "");
    } else {
      Object.keys(value).forEach(filterName => {
        this.filterList.filter(item => {
          if (item.name === filterName) {
            item.value = value[filterName];
          }
        });
      });
    }

    this.updateFilter();
  }
  @Output() onFilterProceed = new EventEmitter<any>();

  /*Get filter name*/
  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get name(): string {
    return this._name;
  }

  /*Constructor*/
  constructor(
    private utilityService: McUtilityService,
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    @Inject('filterConfig') filterConfig: any,
    @Inject('designConfig') design: any
  ) {

    /*Set filter setup from config file*/
    this.filterConfig = filterConfig;
    this.entityId = this.utilityService.getPropertyFromToken('entity_id');

    // Set image
    this.filterImage = design.filter;

    this.paramsSubscription = this.activatedRoute.paramMap.subscribe(data => {
      if (data.keys.length > 0) {
        this.filterList = this.filterList.map(filter => {
          // @ts-ignore
          if (data["params"].hasOwnProperty(filter.name)) {
            // @ts-ignore
            filter.value = data["params"][filter.name];
            filter.checked = true;
          } else {
            filter.value = "";
            filter.checked = false;
          }
          return filter;
        })
      }
    })
  }

  /*On init class*/
  ngOnInit() {
    this.refreshFilterSetup();
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      status: ['']
    });
  }

    /*Get values from filter*/
  public getInputValue(event: any) {
    this.filterList.filter(item => {
      if (item.name === event.name) {
        item.value = event.value;
        if (item.value) {
          !this.selectedFilters.some(selectedFilter => selectedFilter === item.name) && this.selectedFilters.push(item.name);
        } else {
          this.onRemoveFilter(item);
        }
      }
    });
  }

  /*On press filter*/
  public onPressFilterButton() {
    this.updateFilter();
    this.setup["filterByRoute"] && this.router.navigate([`admin/entity/${this.entityId}/management/${this._name}`, this._filterObject]) ||
      this.onFilterProceed.emit(this._filterObject);
    // *************do not save filter object for phase 1*******************
    // this.storeFilterValuesToStorage(this.name, this.filterList);

  }

  /*Update filter*/
  private updateFilter(): void {
    this._filterObject = {};
    this.filterList.map(item => {
      if (item.value) {
        this._filterObject[item.name] = `${item.value}`;
      }
    });
  }

  /*On remove filter*/
  public onRemoveFilter(filter: any): void {
    filter.value = "";
    this.selectedFilters.splice(this.selectedFilters.findIndex(selectedFilter => selectedFilter === filter.name), 1);

    if (filter.type === 1) {
      this.inputFields.find((item: any) => item.name === filter.name).value = "";
    }

    if (filter.type === 2) {
      this.checkboxes.find((item: any) => {
        if (item.name === filter.name) {
          item.value = '';
          item.checked = false;
        }
      });
    }

    if (filter.type === 3) {
      this.dropdowns.find((item: any) => item.name === filter.name).value = "";
    }

    /*TODO: amount due and date filters need to connect with BE.*/
    if (filter.type === 4) {
      this.dates.find((item: any) => item.name === filter.name).value = "";
    }

    if (filter.type === 5) {
      this.amountDue.find((item: any) => item.name === filter.name).value = "";
    }

  }

  // Remove all filters
  public removeFilters() {
    this.selectedFilters = [];
    this.clearFilters = true;
    this.filterList.filter(item => {
      if (item.checked === true) {
        item.checked = false;
      }
      if (item.value !== "") {
        item.value = "";
      }
    });
    this.form.reset();
    this.onPressFilterButton();
  }

  optionClick($event: any) {
    this.filterList.forEach((item) => {
      if((item.name === $event.value.name)) {
        item.value = $event.selected ? true : '';
      }
    });
  }


  /*Store filter values in cookie*/
  // public storeFilterValuesToStorage(pageName, filterObject): void {
  //   localStorage.setItem(`mc-filter-${pageName}`, JSON.stringify(filterObject));
  // }

  /*Get filter values from storage*/
  // public getFilterValuesFromStorage(pageName) {
  //   const storage = localStorage.getItem(`mc-filter-${pageName}`);
  //   return this.utilityService.isJson(storage) && JSON.parse(storage);
  // }

  /*Refresh filter setup*/
  private refreshFilterSetup() {
    this.setup = this.filterConfig && this.filterConfig.find((item: any) => item.name === this.name);
    /*If setup does not exist in filterConfig - do not show filter*/
    if (!this.setup) {
      this.clearFilterConfig();
    }

    /*Filter list from configuration*/
    this.filterList = this.setup.filterList || [];
    this.filterList.forEach(item => {
      if (item.value) {
        this.selectedFilters.push(item.name);
      }
    });

    /*Get filter configuration from localStorage*/
    // ************do not save filter object for phase 1*****************
    // const localStorageFilter = this.getFilterValuesFromStorage(this.name);
    // if (localStorageFilter) {
    //
    //   this.filterList = this.filterList.map(item => {
    //     let setupItem;
    //     localStorageFilter.find(storedFilter => {
    //       if (item.id === storedFilter.id) {
    //         setupItem = storedFilter;
    //       }
    //     });
    //     return setupItem || item;
    //   });
    // }

    /*Input fields setup*/
    this.inputFields = this.filterList.filter(item => item.type === 1);

    /*Checkbox setup*/
    this.checkboxes = this.filterList.filter(item => item.type === 2);

    /*Checkbox setup*/
    this.dropdowns = this.filterList.filter(item => item.type === 3);

    /*Date setup*/
    this.dates = this.filterList.filter(item => item.type === 4);

    /*amountDue setup*/
    this.amountDue = this.filterList.filter(item => item.type === 5);


    /**************do not use auto call filter for phase 1**********************/
    // this.onPressFilterButton();
  }

  /*Clear filter config*/
  private clearFilterConfig() {
    this.filterList = [];
    this.inputFields = [];
    this.checkboxes = [];
    this.dropdowns = [];
    this.dates = [];
    this.amountDue = [];
  }

  /*Checkbox CSS class*/
  public checkboxCssClass() {
    return this.checkboxes.length >= 2 ? 'col-md-5' : 'col-md-1';
  }

  /*Checkbox CSS class*/
  public inputFieldsCssClass() {
    return this.checkboxes.length >= 2 ? 'col-md-1' : 'col-md-5';
  }

  /*On destroy class*/
  ngOnDestroy() {
    this.removeFilters();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.paramsSubscription && this.paramsSubscription.unsubscribe();
  }

}
