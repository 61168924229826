import { Component, OnInit } from '@angular/core';
import {ICON_BACK} from "../../../../assets/media/svg_icons/icon-back";
import {ImDataExport, ImDataExportService, ImDataExportTemplate, ImDataExportTemplateService, McGod, SortCriteriaList} from "@miticon-ui/mc-core";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpParams} from '@angular/common/http';
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import moment from "moment";
import {DatePipe} from "@angular/common";


@Component({
  selector: 'lib-data-export-add',
  templateUrl: './data-export-add.component.html',
  styleUrls: ['./data-export-add.component.scss']
})
export class DataExportAddComponent implements OnInit {
  iconBack = ICON_BACK;
  form!: FormGroup;
  selectedReport: number;
  selectedReportName: string;
  exportReports!: ImDataExportTemplate[];
  fileTypes = ImDataExport.FILE_TYPE_LIST__ALL;
  isLoading: boolean = false;
  isLoadingExport: boolean = false;
  previewData!: string[];

  constructor(private router: Router,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private tS: TranslateService,
              private dataExportService: ImDataExportService,
              private dataExportTemplateService: ImDataExportTemplateService,
              private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.getDataExportTemplates();
    this.createForm();
  }

  export() {
    this.isLoadingExport = true;
    this.formatFormDates();
    this.dataExportService.export(this.form.value).subscribe((data) => {
      if(data) {
        const dataExportTemplate = this.exportReports.find((report) => report.id === this.form.controls['idDataExportTemplate'].value);
        var fileName =
          "Entity " + McGod.getLoggedEntityIdFromToken() + " - " +
          dataExportTemplate?.reportName;
          if(this.form.controls['dateFrom'].value && this.form.controls['dateTo'].value) {
          fileName = fileName + " " +
          moment(this.form.controls['dateFrom'].value).format('DD.MM.YYYY') + " - " +
          moment(this.form.controls['dateTo'].value).format('DD.MM.YYYY');
          }
        this.downloadFile(data, this.form.value.fileType, fileName);
        this.toastr.success(dataExportTemplate?.reportName + " " + this.tS.instant("cc.data-export.successfully"));
        this.isLoadingExport = false;
        this.goBack();
      }
    }, (message) => {
      this.isLoadingExport = false;
      this.toastr.error(message.error.message);
    })
  }

  private downloadFile(data: any, fileType: string, fileName: string) {
    switch (fileType) {
      case 'xls':
        this.downloadAsXLS(data, fileName);
        break;
      case 'csv':
        this.downloadAsCSV(data, fileName);
        break;
      case 'json':
        this.downloadAsJSON(data, fileName);
        break;
    }
  }

  private downloadAsXLS(data: any, fileName: string) {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    this.saveAsFile(blob, fileName + '.xls', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  }

  private downloadAsCSV(data: any, fileName: string) {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    this.saveAsFile(blob, fileName + '.csv', 'text/csv;charset=utf-8;');
  }

  private downloadAsJSON(blob: Blob, fileName: string) {
    const reader = new FileReader();
    reader.onload = () => {
      const jsonData = reader.result as string;
      const jsonBlob = new Blob([jsonData], { type: 'application/json' });
      this.saveAsFile(jsonBlob, fileName + '.json', 'application/json');
    };
    reader.readAsText(blob);
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string) {
    const blob = new Blob([buffer], { type: fileType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  getDataExportTemplates() {
    this.isLoading = true;
    this.dataExportTemplateService.get(new HttpParams(), 0 , 0, new SortCriteriaList())
      .subscribe((data) => {
        if (data) {
          this.isLoading = false;
          this.exportReports = data.content.map((object: any) => object = ImDataExportTemplate.createFromJson(object));
        }
      }, () => {
        this.isLoading = false;
      });
  }

  createForm() {
    this.form = this.fb.group({
      idDataExportTemplate: ['', Validators.required],
      dateFrom: [{ value: '', disabled: this.shouldDisableDate() }, this.getDateValidators()],
      dateTo: [{ value: '', disabled: this.shouldDisableDate() }, this.getDateValidators()],
      fileType: ['', Validators.required]
    });

    this.form.statusChanges.subscribe((formStatus) => {
      if(formStatus === 'VALID') {
        this.isLoading = true;
        this.formatFormDates();
        this.dataExportService.getPreview(this.form.value).subscribe((preview: any) => {
          this.isLoading = false;
          if(preview) {
            this.previewData = preview;
          }
        }, () => {
          this.isLoading = false;
        })
      }
    })
  }

  getDateValidators() {
    return this.shouldDisableDate() ? [] : [Validators.required];
  }

  shouldDisableDate(): boolean {
    return this.selectedReportName === 'Consumer with complaints';
  }

  updateDateFields() {
    const disable = this.shouldDisableDate();
    ['dateFrom', 'dateTo'].forEach((field) => {
      const control = this.form.get(field);
      if (control) {
        if (disable) {
          control.disable();
          control.clearValidators();
        } else {
          control.enable();
          control.setValidators([Validators.required]);
        }
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
  }

  selectExportReport(exportReport: any) {
    this.selectedReport = exportReport.id;
    this.selectedReportName = exportReport.reportName;
    this.updateDateFields();
  }

  goBack(): void {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/data/export`]);
  }

  private formatFormDates() {
    if(this.form.controls['dateFrom'].value) {
      this.form.controls['dateFrom'].setValue(this.formatDate(this.form.controls['dateFrom'].value), {emitEvent: false});
    }
    if(this.form.controls['dateTo'].value) {
      this.form.controls['dateTo'].setValue(this.formatDate(this.form.controls['dateTo'].value), {emitEvent: false});
    }
  }

  formatDate(dateString: string) {
    const date = new Date(dateString);
    return this.datePipe.transform(date, "yyyy-MM-dd");
  }
}
