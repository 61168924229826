import {McMenu, McGod} from '@miticon-ui/mc-core';
import { ICON_DASHBOARD } from '../../../assets/media/svg_icons/sidenav-icons/icon-dashboard';
import { ICON_CONSUMER360 } from '../../../assets/media/svg_icons/sidenav-icons/icon-consumer360';
import { ICON_CONSUMERS } from '../../../assets/media/svg_icons/sidenav-icons/icon-consumers';
import { ICON_TRANSACTIONS } from '../../../assets/media/svg_icons/sidenav-icons/icon-transactions';
import { ICON_INKASSO } from '../../../assets/media/svg_icons/sidenav-icons/icon-inkasso';
import { ICON_BILLING_PAYOUT } from '../../../assets/media/svg_icons/sidenav-icons/icon-billing-payout';
import { ICON_FINANCIAL } from '../../../assets/media/svg_icons/sidenav-icons/icon-financials';
import { ICON_PAYMENT } from '../../../assets/media/svg_icons/sidenav-icons/icon-payment';
import { ICON_CONFIGURATION } from '../../../assets/media/svg_icons/sidenav-icons/icon-configuration';
import { ICON_ENTITY } from '../../../assets/media/svg_icons/sidenav-icons/icon-entity';
import { ICON_MANAGEMENT } from '../../../assets/media/svg_icons/sidenav-icons/icon-management';
import { ICON_DATA } from "../../../assets/media/svg_icons/sidenav-icons/icon-data";

export class McSidebarMenuConfig {

  menu!: McMenu;
  permissions: any;
  loggedEntityId!: number;

  constructor() {
    this.buildMenu();
    McGod.getInstance()?.getBroadcastTriggerLoginChanged()?.subscribe(() => {
      this.buildMenu();
    });
  }

  public buildMenu() {
    this.menu = new McMenu();
    this.loggedEntityId = McGod.getLoggedEntityIdFromToken();

    /****************************************************
     * NEW MENU FROM MIC PARENT + CHILD INSTRUCTION LIST*
     * **************************************************/

    //New
    const dashboard = this.menu
      .addMenuItem('dashboard', 'cc.common.dashboard', ICON_DASHBOARD, [McGod.PERM_MC_ENTITY_DASHBOARD_VIEW], '/dashboard');

    const consumer360 = this.menu
      .addMenuItem('consumer360', 'cc.common.consumer-360', ICON_CONSUMER360, [McGod.PERM_CSR_CONSUMER_360], `/entity/${this.loggedEntityId}/consumers/smart-search-box`);


    const consumers = this.menu
      .addMenuItem('consumers', 'cc.common.consumers', ICON_CONSUMERS,
        [McGod.PERM_MC_CONSUMER_VIEW,
          McGod.PERM_MC_CONSUMER_IMPORT_VIEW,
          McGod.PERM_MC_CONSUMER_IMPORT_MAPPING_VIEW,
          McGod.PERM_EB_FACTORING_OUT_BLACKLIST_VIEW],
        '');
    consumers.addMenuItem('consumers overview', 'cc.consumer.create-and-overview', 'fas fa-users', [McGod.PERM_MC_CONSUMER_VIEW], `/entity/${this.loggedEntityId}/consumers/overview`);
    consumers.addMenuItem('import-mappings', 'cc.common.mapping-imports', 'fas fa-file-upload', [McGod.PERM_MC_CONSUMER_IMPORT_MAPPING_VIEW], `/entity/${this.loggedEntityId}/consumers/import-mappings`);
    consumers.addMenuItem('blacklist', 'cc.common.blacklist', 'fas fa-file-import', [McGod.PERM_EB_FACTORING_OUT_BLACKLIST_VIEW], `/entity/${this.loggedEntityId}/out-factoring/blacklist`);


    const transactions = this.menu.addMenuItem('transactions', 'cc.factoring.transactions', ICON_TRANSACTIONS,
      [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_VIEW,
        McGod.PERM_EB_FACTORING_MY_SEPA_VIEW,
        McGod.PERM_EB_FACTORING_OUT_SEPA_IN_VIEW],
      '');
    transactions.addMenuItem('out-transactions', 'cc.factoring.out-transactions', 'fas fa-receipt', [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_VIEW], `/entity/${this.loggedEntityId}/transactions/out`);
    transactions.addMenuItem('in-transactions', 'cc.factoring.in-transactions', 'fas fa-receipt', [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_VIEW], `/entity/${this.loggedEntityId}/transactions/in`);
    transactions.addMenuItem('my-sepa-in', 'cc.common.sepa-import', 'fas fa-archive', [McGod.PERM_EB_FACTORING_MY_SEPA_VIEW], `/entity/${this.loggedEntityId}/my-factoring/sepa-in`);
    transactions.addMenuItem('sepa-in', 'cc.common.imported-sepa-list', 'fas fa-archive', [McGod.PERM_EB_FACTORING_OUT_SEPA_IN_VIEW], `/entity/${this.loggedEntityId}/out-factoring/sepa-in`);

    const inkassoExport = this.menu.addMenuItem('inkasso-export', 'cc.common.inkasso-export', ICON_INKASSO, [McGod.PERM_EB_FACTORING_OUT_INKASSO_EXPORT], `/entity/${this.loggedEntityId}/out-factoring/inkasso-export`);

    const billingAndPayout = this.menu
      .addMenuItem('billingAndPayout', 'cc.common.billing-and-payout', ICON_BILLING_PAYOUT,
        [McGod.PERM_EB_FACTORING_OUT_SEPA_OUT_VIEW,
          McGod.PERM_MC_BILLING_OUT_BILLING_ITEM_VIEW,
          McGod.PERM_MC_BILLING_MY_STATEMENT_VIEW,
          McGod.PERM_MC_BILLING_OUT_STATEMENT_VIEW,
          McGod.PERM_MC_BILLING_OUT_PAYOUT_FILES_VIEW,
          McGod.PERM_MC_TEMP_INVOICES_VIEW
        ],
        ``);
    billingAndPayout.addMenuItem('sepa-out', 'cc.common.sepa-export', 'fas fa-file-import', [McGod.PERM_EB_FACTORING_OUT_SEPA_OUT_VIEW], `/entity/${this.loggedEntityId}/out-factoring/sepa-out`);
    billingAndPayout.addMenuItem('billing-items', 'cc.common.billing-items', 'fas fa-list', [McGod.PERM_MC_BILLING_OUT_BILLING_ITEM_VIEW], `/entity/${this.loggedEntityId}/out-billing/billing-items`);
    billingAndPayout.addMenuItem('statements-in', 'cc.billing.statements-in', 'fas fa-file-invoice', [McGod.PERM_MC_BILLING_MY_STATEMENT_VIEW], `/entity/${this.loggedEntityId}/my-billing/statements`);
    billingAndPayout.addMenuItem('statements-out', 'cc.common.statements-out', 'fas fa-file-invoice', [McGod.PERM_MC_BILLING_OUT_STATEMENT_VIEW], `/entity/${this.loggedEntityId}/out-billing/statements`);
    billingAndPayout.addMenuItem('payout-files', 'cc.common.payout', 'fas fa-copy', [McGod.PERM_MC_BILLING_OUT_PAYOUT_FILES_VIEW], `/entity/${this.loggedEntityId}/out-billing/payout-files`);
    billingAndPayout.addMenuItem('invoices', 'cc.common.invoices', 'fas fa-sticky-note', [McGod.PERM_MC_TEMP_INVOICES_VIEW], `/admin/entity/${this.loggedEntityId}/management/invoices/overview`);

    const financial = this.menu
      .addMenuItem('financial', 'cc.common.financial-reports', ICON_FINANCIAL, [McGod.PERM_EB_FACTORING_STARMONEY_VIEW], ``);
    financial.addMenuItem('sepa-statement-report', 'cc.bank.sepa-statement-report', 'fas fa-money-bill-wave', [McGod.PERM_EB_FACTORING_STARMONEY_VIEW], `/entity/${this.loggedEntityId}/bank-module/star-money`);
    financial.addMenuItem('psd2-statement-report', 'cc.bank.psd2-statement-report', 'fas fa-money-bill-wave', [McGod.PERM_EB_BANK_PSD2_REPORT_VIEW], `/entity/${this.loggedEntityId}/bank-module/psd2-statements`);
    financial.addMenuItem('incoming-records', 'cc.incoming-records', 'fas fa-arrow-right', [McGod.PERM_EB_FACTORING_STARMONEY_VIEW], `/entity/${this.loggedEntityId}/bank-module/incoming-payments`);
    financial.addMenuItem('finance', 'cc.finance', 'fas fa-arrow-right', [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_PAYMENT_ADD], `/entity/${this.loggedEntityId}/bank-module/finance`);

    const payment =  this.menu
      .addMenuItem('payment', 'cc.sidenav.payment-services', ICON_PAYMENT, [McGod.PERM_EB_FACTORING_EBICS_PROPERTIES], `/entity/${this.loggedEntityId}/ebics`);
    payment.addMenuItem('ebics', 'cc.ebics.ebics', 'fas fa-key', [McGod.PERM_EB_FACTORING_EBICS_PROPERTIES], `/entity/${this.loggedEntityId}/ebics`);


    //TODO: Add valid permissions
    const data = this.menu
      .addMenuItem('data', 'cc.data', ICON_DATA,
        [McGod.PERM_CSR_CONSUMER_IMPORT_VIEW, McGod.PERM_EB_FACTORING_OUT_SEPA_EXPORT],
        '');
    data.addMenuItem('dataImport', 'mem.club.data-import', 'fas fa-users', [McGod.PERM_CSR_CONSUMER_IMPORT_VIEW], `/entity/${this.loggedEntityId}/data/import`);
    data.addMenuItem('dataExport', 'cc.data-export', 'fas fa-file-upload', [McGod.PERM_EB_FACTORING_OUT_SEPA_EXPORT], `/entity/${this.loggedEntityId}/data/export`);


    const configuration = this.menu
      .addMenuItem('configuration', 'cc.common.configuration', ICON_CONFIGURATION,
        [McGod.PERM_MC_BILLING_PRICELIST_VIEW,
          McGod.PERM_MC_BILLING_TEMPLATE_PRICELIST_VIEW,
          McGod.PERM_MC_PROCESS_VIEW,
          McGod.PERM_MC_PROCESS_PDF_TEMPLATE_VIEW,
          McGod.PERM_PP_PRODUCT_VIEW,
          McGod.PERM_CSR_CONSUMER_360,
          McGod.PERM_MC_COUNTRY_VIEW,
          McGod.PERM_MC_TEMP_JOB_VIEW
        ],
        '');
    configuration.addMenuItem('pricelists', 'cc.pricelist.pricelists', 'far fa-list-alt', [McGod.PERM_MC_BILLING_PRICELIST_VIEW], `/entity/${this.loggedEntityId}/pricelists/pricelists`);
    configuration.addMenuItem('pricelists-templates', 'cc.common.pricelists-template', 'far fa-sticky-note', [McGod.PERM_MC_BILLING_TEMPLATE_PRICELIST_VIEW], `/entity/${this.loggedEntityId}/pricelists/templates`);
    configuration.addMenuItem('processesOverview', 'cc.common.processes', 'fas fa-users-cog', [McGod.PERM_MC_PROCESS_VIEW], `/entity/${this.loggedEntityId}/processes/overview`);
    configuration.addMenuItem('pdfTemplates', 'cc.common.pdf-templates', 'far fa-file-pdf', [McGod.PERM_MC_PROCESS_PDF_TEMPLATE_VIEW], `/entity/${this.loggedEntityId}/processes/templates`);
    configuration.addMenuItem('product', 'cc.common.products', 'fab fa-product-hunt', [McGod.PERM_PP_PRODUCT_VIEW], `/entity/${this.loggedEntityId}/products/list`);
    configuration.addMenuItem('contracts-360', 'cc.contracts-360', 'fab fa-product-hunt', [McGod.PERM_CSR_CONSUMER_360], `/entity/${this.loggedEntityId}/products/contracts-360`);
    configuration.addMenuItem('countrySetup', 'cc.countries.country-setup', 'fas fa-globe-europe', [McGod.PERM_MC_COUNTRY_VIEW], `/entity/${this.loggedEntityId}/countries/overview`);
    configuration.addMenuItem('jobs', 'cc.common.jobs', 'fas fa-briefcase', [McGod.PERM_MC_TEMP_JOB_VIEW], `/admin/entity/${this.loggedEntityId}/scheduler/jobs`);

    const entity = this.menu
      .addMenuItem('entity', 'cc.common.view.entity', ICON_ENTITY,
        [McGod.PERM_MC_ENTITY_PROFILE_VIEW,
          McGod.PERM_EB_FACTORING_OUT_CONTRACT_VIEW,
          McGod.PERM_EB_FACTORING_MY_CONTRACTS_VIEW,
          McGod.PERM_MC_ENTITY_VIEW],
        '');
    entity.addMenuItem('entity-profile', 'cc.common.profile', 'fa fa-cog', [McGod.PERM_MC_ENTITY_PROFILE_VIEW], `/entity/${this.loggedEntityId}/entity-profile`);
    entity.addMenuItem('contracts', 'cc.factoring.contracts', 'fas fa-archive', [McGod.PERM_EB_FACTORING_OUT_CONTRACT_VIEW], `/entity/${this.loggedEntityId}/out-factoring/contracts`);
    entity.addMenuItem('my-contracts', 'cc.common.my-contracts', 'fas fa-archive', [McGod.PERM_EB_FACTORING_MY_CONTRACTS_VIEW], `/entity/${this.loggedEntityId}/my-factoring/contracts`);
    entity.addMenuItem('related-entities', 'cc.common.related-entities', 'fas fa-building', [McGod.PERM_MC_ENTITY_VIEW], `/admin/entity/${this.loggedEntityId}/management/entity`);

    const userManagement = this.menu
      .addMenuItem('user-management-main', 'mem.user-management.user-management', ICON_MANAGEMENT,
        [McGod.PERM_MC_USER_VIEW,
          McGod.PERM_MC_ROLE_VIEW],
        ``);
    userManagement.addMenuItem('user', 'cc.common.admin.users', 'fas fa-users', [McGod.PERM_MC_USER_VIEW], `/admin/entity/${this.loggedEntityId}/management/user`);
    userManagement.addMenuItem('role', 'cc.common.admin.roles', 'fas fa-user-tag', [McGod.PERM_MC_ROLE_VIEW], `/admin/entity/${this.loggedEntityId}/management/role`);

    return this.menu;
  }
}
