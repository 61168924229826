export class CurrencyUtil {
  static CODE_EURO = 'EUR';
  static CODE_CHF = 'CHF';
  static CODE_CZK = 'CZK';

  static format(value: number = 0, currencyCode: string): string {
    switch (currencyCode) {
      case this.CODE_EURO:
        return value.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' €';
      case this.CODE_CHF:
        return value.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' fr.';
      case this.CODE_CZK:
        return value.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' Kč';
    }
    // this should never happened
    return String(value);
  }
}
