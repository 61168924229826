/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EbSepaStatementReportFilter} from '../models/eb-sepa-statement-report-filter.model';

export class EbSepaStatementReportFilterGENERATED extends BaseObjectFilter{    public searchTerm: string | undefined;
    public importedByUserId: number | undefined;
    public importDateFrom: any | undefined;
    public importDateTo: any | undefined;

  public properties: string[] = ['searchTerm', 'importedByUserId', 'importDateFrom', 'importDateTo', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbSepaStatementReportFilter {
    console.error('MTCN-ERROR: Not Implemented: EbSepaStatementReportFilter::createNewInstance(). Add this method to final class and return new EbSepaStatementReportFilter();');
      throw new Error('Cannot EbSepaStatementReportFilter::createNewInstance(). Add this method to final class and return new EbSepaStatementReportFilter();');
  }

}
