import {Component, Input} from '@angular/core';

@Component({
  selector: 'lib-mc-loader',
  templateUrl: './mc-loader.component.html',
  styles: [`
    .mc-loader-wrapper {
      background-color: rgba(0, 0, 0, 0.1);
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99999;
      width: 100vw;
      height: 100vh;
    }

    .mc-loader-show {
      display: block;
    }

    .mc-loader-content {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      max-width: 600px;
      width: 600px;
    }

    @keyframes lds-eclipse {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .mc-loader {
      position: relative;
      width: 200px;
      height: 200px;
      margin: 0 auto;
    }

    .mc-loader div {
      position: absolute;
      animation: lds-eclipse 1.2s linear infinite;
      width: 160px;
      height: 160px;
      top: 20px;
      left: 20px;
      border-radius: 50%;
      border: 15px solid transparent;
      border-top-color: #41457a;
      transform-origin: 80px 80px;
    }

    .title {
      margin-top: 50px;
      word-wrap: break-word;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      color: #53589B;
    }
  `]
})
export class McLoaderComponent {
  @Input() showLoader!: boolean;
  @Input() title!: string;
}
