import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {Link} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-notify-message',
  templateUrl: './mc-notify-message.component.html',
})
export class McNotifyMessageComponent implements OnInit {
  _message!: string;
  _type!: string;
  _link!: Link;
  cssClass!: string;

  // Inputs
  @Input()
  set message(message: string) {
    this._message = message;
    this.handleNotifyMessage();
  }

  @Input()
  set type(type: string) {
    this._type = type;
    this.handleNotifyMessage();
  }

  @Input()
  set link(link: Link) {
    this._link = link;
    this.handleNotifyMessage();
  }

  get type() {
    return this._type;
  }

  get message() {
    return this._message;
  }

  get link() {
    return this._link;
  }

  @Output() eventChanged = new EventEmitter();

  constructor(
    private router: Router
  ) {
  }

  // On init class
  ngOnInit() {
    this.handleNotifyMessage();
  }

  navigateTo() {
    this.router.navigate([this._link.path], {queryParams: {activeTab: this._link.params !== '' ? this._link.params : null}});
    this.eventChanged.emit(true);
  }

  // Handle message
  private handleNotifyMessage(): void {
    this.cssClass = '';
    switch (this.type) {
      case 'error':
        this.cssClass = `${this.type}-message`;
        break;

      case 'success':
        this.cssClass = `${this.type}-message`;
        break;

      case undefined:
        break;

      case '':
        break;

      default:
        console.error(`Bad type property for mcNotifyMessage component. Type value: ${this.type}`);
    }
  }

}
