<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="csrMapping.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header d-flex align-items-center">
    <h1 *ngIf="csrMapping.exists()">{{'cc.consumers.mapping' | translate}}: {{csrMapping.mappingName}}</h1>
    <img *ngIf="csrMapping.sharedFlg && !mappingFromLoggedEntity" src="assets/images/sharedbyothers.svg" ngbTooltip="{{'cc.import-mappings.shared-by-entity' | translate}} {{csrMapping.sharedByEntityName}} {{'cc.import-mappings.on' | translate}} {{sharedDate}}" alt="" width="35px" height="35px" placement="right" class="mx-2">
    <img *ngIf="csrMapping.sharedFlg && mappingFromLoggedEntity" class="mx-2" src="assets/images/sharedbyuser.svg"  ngbTooltip="{{'cc.import-mappings.shared-by' | translate}} {{csrMapping.sharedByUserName}} {{'cc.import-mappings.on' | translate}} {{sharedDate}}" alt="" height="35px" width="35px" placement="right">
  </div>

  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!csrMapping.apiLoadingFlg" class="content-body">
    <mcc-message [errorMsg]="csrMapping.apiErrorMessage"></mcc-message>

    <!--<div *ngIf="mcMapping.sharedFlg" class="col-md-12">
      <span class="font-italic mc-light-green-text">Shared by: {{mcUser.fldFullName()}}</span>
    </div>-->

    <div class="col-md-12 d-flex justify-content-end">
      <mcc-button *ngIf="!csrMapping.sharedFlg && mappingFromLoggedEntity && mcGod.userHasMcConsumerImportMappingSharePermission()" (eventClicked)="shareLinkClick()" extraCssClasses="system-action-btn mr-2" label='<i class="fas fa-share-alt"></i> {{"cc.import-mappings.share" | translate}}'></mcc-button>
      <mcc-button *ngIf="csrMapping.sharedFlg && mappingFromLoggedEntity && mcGod.userHasMcConsumerImportMappingSharePermission()" (eventClicked)="unshareLinkClick()" extraCssClasses="system-action-btn mr-2"  label='<img src="assets/images/unshare.svg" alt="" height="20px" width="20px"> {{"cc.import-mappings.unshare" | translate}}'></mcc-button>
      <mcc-button *ngIf="mcGod.userHasMcConsumerImportMappingEditPermission()" extraCssClasses="system-action-btn px-3 mr-2" (eventClicked)="copyLinkClick()" label='<i class="far fa-copy"></i> {{"cc.import-mappings.copy" | translate}}'></mcc-button>
      <mcc-button *ngIf="mappingFromLoggedEntity && mcGod.userHasMcConsumerImportMappingEditPermission()" extraCssClasses="system-action-btn" (eventClicked)="actEditMapping();" label='<i class="fas fa-pen"></i> {{"cc.common.view.edit" | translate}}'></mcc-button>
    </div>
    <!-- == MAIN CONTENT ======================================== -->
   <!-- <ng-container *ngIf="mcMapping.apiSuccessFlg">
      <mcc-fi-table-2 [objectList]="mcMapping.mappingItems" [table2Config]="tableConfig"></mcc-fi-table-2>
    </ng-container>-->

    <mcc-dialog-box *ngIf="showUnShareMappingMcb.value" [enableCloseButtonFlg]="false"
                    title='{{"cc.import-mappings.unshare" | translate}} "{{csrMapping.mappingName}}"' name="unshare-mapping"
                    description='{{"cc.import-mappings.are-you-sure-you-want-to-unshare" | translate}}  "{{csrMapping.mappingName}}" {{"cc.consumers.mapping" | translate}}?'
                    [buttonsKvm]="sharingUnsharingCopyButtonKvm"
                    popupType="question"
                    (eventActionButtonClicked)="onMcDialogBoxUnshareMappingActionButtonClicked($event)"></mcc-dialog-box>

    <mcc-dialog-box *ngIf="showShareMappingMcb.value" [enableCloseButtonFlg]="false"
                    title='{{"cc.import-mappings.share" | translate}} "{{csrMapping.mappingName}}"' name="share-mapping"
                    description='{{"cc.import-mappings.are-you-sure-you-want-to-share" | translate}} "{{csrMapping.mappingName}}" {{"cc.consumers.mapping" | translate}}?'
                    [buttonsKvm]="sharingUnsharingCopyButtonKvm"
                    popupType="question"
                    (eventActionButtonClicked)="onMcDialogBoxShareMappingActionButtonClicked($event)"></mcc-dialog-box>

    <mcc-dialog-box *ngIf="showCopyMappingMcb.value" [enableCloseButtonFlg]="false"
                    title='{{"cc.import-mappings.copy" | translate}} "{{csrMapping.mappingName}}"' name="copy-mapping"
                    description='{{"cc.import-mappings.are-you-sure-you-want-to-copy" | translate}} "{{csrMapping.mappingName}}" {{"cc.consumers.mapping" | translate}}?'
                    [buttonsKvm]="sharingUnsharingCopyButtonKvm"
                    popupType="question"
                    (eventActionButtonClicked)="onMcDialogBoxCopyMappingActionButtonClicked($event)"></mcc-dialog-box>

  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary move-right" (eventClicked)="actClose();" label='<i class="far fa-times-circle"></i> {{"cc.common.close" | translate}}'></mcc-button>

    <!--<mcc-button class="move-right" extraCssClasses="btn btn-secondary move-right" label="Share"></mcc-button>
    <mcc-button class="move-right" extraCssClasses="btn btn-secondary move-right" label="Copy"></mcc-button>-->
<!--    <mcc-button extraCssClasses="btn btn-primary move-right" (eventClicked)="actEditMapping();" label="{{'cc.common.view.edit' | translate}}"></mcc-button>-->

  </div>

</div>
<router-outlet></router-outlet>
