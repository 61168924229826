import { Component, OnInit } from '@angular/core';
import { McBoolean } from '@miticon-ui/mc-core';

@Component({
  selector: 'lib-consumer-mollie-successful-message',
  templateUrl: './consumer-mollie-successful-message.component.html',
  styleUrls: ['./consumer-mollie-successful-message.component.scss']
})
export class ConsumerMollieSuccessfulMessageComponent implements OnInit {

  showModal: McBoolean = new McBoolean();

  constructor() { }

  ngOnInit() {
    this.showModal.setTrue();
  }

}
