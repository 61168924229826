
import {Table3Config} from '../extra/table-3-config';

// import {EbPaymentReport2SepaFileTransactionGENERATED} from '../_generated/eb-payment-report-2-sepa-file-transaction-generated.model';

export class EbPaymentReport2SepaFileTransactionTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAmount', 'Amount', 'amount', 'amount');
    this.addColumn('colEbPaymentReport', 'Payment report', 'ebPaymentReport', 'ebPaymentReport');
    this.addColumn('colEbSepaFileTransaction', 'Sepa file transaction', 'ebSepaFileTransaction', 'ebSepaFileTransaction');
    this.addColumn('colIdPaymentReport', 'Payment report id', 'idPaymentReport', 'idPaymentReport');
    this.addColumn('colIdSepaFileTransaction', 'Id sepa file transaction', 'idSepaFileTransaction', 'idSepaFileTransaction');

*/
  }
}
