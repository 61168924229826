import { McEbicsEbicsAddEditPartComponent } from './components/mc-ebics-ebics-add-edit-part/mc-ebics-ebics-add-edit-part.component';
import { McEbicsEbicsListPageComponent } from './pages/mc-ebics-ebics-list-page/mc-ebics-ebics-list-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McEbicsRoutingModule } from './mc-ebics-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {McFilterModule, McTableModule} from '@miticon-ui/mc-components';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
McEbicsEbicsAddEditPartComponent,
McEbicsEbicsListPageComponent,
  ],
    imports: [
        CommonModule,
        McEbicsRoutingModule,
        NgbModule,
        McComponentsModule,
        McFilterModule,
        McTableModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
    ],
  exports: [
McEbicsEbicsAddEditPartComponent,
McEbicsEbicsListPageComponent,
    McEbicsRoutingModule,
    FormsModule,

  ]
})
export class McEbicsModule { }
