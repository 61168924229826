/*BbmTestObj*/
import {EbPsd2ReportGENERATED} from '../_generated/eb-psd2-report-generated.model';
import {CurrencyUtil} from "../common/currency-util";
import {McDateUtils} from "../common/mc-date-utils";

export class EbPsd2Report extends EbPsd2ReportGENERATED {

  public static FILTER_IMPORT_DATE = "Import date"; //todo translation
  public static FILTER_DATE_FORMAT = "YYYY-MM-DD";

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EbPsd2Report();
  }

  // ---------------------------------------------------------------------

  fldPsd2ReportStatus() {
    if (this.getStatusCdLabel() === '--BAD-CD--') {
      const completedStatus = EbPsd2Report.STATUS_CD_COMPLETED;
      return completedStatus.charAt(0) + completedStatus.substring(1).toLowerCase();
    } else {
      return this.getStatusCdLabel();
    }
  }

  fldDateFrom(){
    return this.dateFrom ? McDateUtils.newFormatDateTimeString(this.dateFrom) : '';
  }

  fldDateTo(){
    return this.dateTo ? McDateUtils.newFormatDateTimeString(this.dateTo) : '';
  }

  fldWithdrawnTotalMatchedCount() {
    return `${this.totalWithdrawnCount}/${this.matchedWithdrawnCount}`;
  }


  fldWithdrawnTotalMatchedAmount() {
    return `${CurrencyUtil.format(this.totalWithdrawnAmount, this.mcCurrency.code)}/${CurrencyUtil.format(this.matchedWithdrawnAmount, this.mcCurrency.code)}`;
  }

  fldReturnedTotalMatchedCount() {
    return `${this.totalReturnedCount}/${this.matchedReturnedCount}`;
  }

  fldReturnedTotalMatchedAmount() {
    return `${CurrencyUtil.format(this.totalReturnedAmount, this.mcCurrency.code)}/${CurrencyUtil.format(this.matchedReturnedAmount, this.mcCurrency.code)}`;
  }

}
