export class DiscountBaseConfig {
  value: any = 0;
  valueTypeCd = "PERCENTAGE";
  typeCd = "DISCOUNT";
  amount = 0;
  amountPerOneQuantity = 0;
  excludeDiscountedItems = true;
  isValid = true;

  constructor() {}
}
