<div class="container mc-create-role-modal-body border-bottom-0" *ngIf="permissionsByModule">
<div class="mc-create-role-modal-edit-body">
  <div class="row mc-header-wrapper" *ngIf="!editRole">
    <div class="col-md-12 mc-stepper-header-title">
      <span class="pl-4 pb-1 ml-4 mr-2 mc-semi-active-role">
        <span class="mc-notification-badge-blue">1</span>
        {{ 'cc.common.view.basic-info' | translate }}
      </span>
      <span class="pl-4 pb-1 ml-4 mr-2 mc-active">
        <span class="mc-notification-badge-gray mc-selected-step">2</span>
        {{'cc.roles.view.permissions' | translate}}
    </span>
    </div>
  </div>

  <!--Error section-->
  <div class="mc-error position-relative" style="padding-bottom: 50px">
    <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
  </div>

  <!--Permission content-->
  <div class="row">

    <!--Select permissions-->
    <div class="col-md-8">

      <form class="form-inline">

        <!--Search permissions-->
        <div class="row w-100">
          <div class="form-group mb-2">
            <label for="searchPermissions" class="text-capitalize h6 pr-3">permissions</label>
            <input [(ngModel)]="searchPermissionValue"
                   (ngModelChange)="onSearchPermission()"
                   name="searchPermissionValue"
                   type="text"
                   class="form-control mc-search-permissions"
                   id="searchPermissions"
                   placeholder="{{'cc.roles.edit.search-permissions' | translate}}">
            <i class="fa fa-search mc-search-icon"></i>
          </div>
        </div>

        <div class="row w-100 ml-3 mb-n5">
          <div class="col-md-8 offset-md-4">Permissions<span class="text-danger">*</span></div>
        </div>

        <!--List permissions-->
        <div class="row w-100">

          <!--Permission modules-->
          <div class="col-md-4 p-0 mc-permissions-module-wrapper mc-scrollbar-vertical">
            <ul class="list-unstyled mc-scrollbar-left">

              <li [ngClass]="setAllModulesCssClass()" class="text-capitalize font-italic mb-2 pl-3">all modules</li>
              <li *ngFor="let permissionModule of permissionsByModule.content"
                  (click)="onSelectedModule(permissionModule)"
                  [ngClass]="selectedModuleCssClass(permissionModule)"
                  class="m-0 pl-3 py-1 border-bottom mc-cursor-pointer">

                {{permissionModule['name']}}

                <span *ngIf="permissionModule['selectedPermissionsNumber'] > 0"
                      class="mc-permissions-number">{{permissionModule['selectedPermissionsNumber']}}</span>
              </li>
            </ul>
          </div>

          <!--Permissions list-->
          <div class="col-md-8 mc-permissions-list mc-gray-background mc-scrollbar-vertical">
            <div class="row">
              <div class="col-md-12" *ngIf="selectedModule">
                <ul class="list-unstyled p-3">
                  <li *ngFor="let permission of selectedModule.permissions"
                      (click)="onSelectedPermission(permission['id'])"
                      [ngClass]="selectedPermissionCssClass(permission)"
                      class="m-0 pl-3 py-1 border-bottom mc-cursor-pointer">

                    <input [checked]="permission['selected']"
                           type="checkbox"
                           class="align-middle mr-2">
                    {{permission['description']}}
                  </li>

                  <div *ngIf="selectedModule.permissions.length === 0" class="mt-3 px-2 position-relative">
                    <mc-notify-message [type]="'error'" [message]="'No permissions'"></mc-notify-message>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </form>
    </div><!--Select permission /end-->

    <!--Selected permissions summary-->
    <div class="col-md-4 mc-gray-background mc-selected-permissions-list mc-scrollbar-vertical">
      <div class="row mt-3">
        <div class="col-md-12">
          <p class="h6 pb-1 w-100 mc-selected-permissions-title">{{'cc.roles.edit.selected-permissions' | translate}}</p>

          <ul class="list-unstyled" *ngFor="let permissionModule of permissionsByModule.content">
            <li *ngIf="permissionModule['selectedPermissionsNumber']" class="mc-font-semi-weight">
              {{permissionModule['name']}}

              <ul class="list-unstyled font-weight-normal">
                <li *ngFor="let permission of permissionModule['permissions']" class="pl-3">
                    <span *ngIf="permission['selected']">
                      {{permission['description']}}
                      <span class="pl-2 mc-cursor-pointer"
                            (click)="onRemovePermission(permission, permissionModule)">X</span>
                    </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div><!--Selected permission /end-->

  </div>
</div>

</div>
