/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McConsumer} from '../models/mc-consumer.model';
import {McConsumerService} from '../services/mc-consumer.service';
import {IMcConsumer} from '../_generated/mc-consumer.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {McEntityConsumerAttributeList} from '../models/mc-entity-consumer-attribute-list.model';


export abstract class McConsumerGENERATED extends BaseObject {


    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_FROZEN: string = 'FROZEN';
    public static readonly STATUS_CD_INACTIVE: string = 'INACTIVE';
  public static readonly STATUS_CD_LIST__ALL = [
    McConsumerGENERATED.STATUS_CD_ACTIVE,
    McConsumerGENERATED.STATUS_CD_FROZEN,
    McConsumerGENERATED.STATUS_CD_INACTIVE
];

    public static readonly TYPE_CD_PERSON: string = 'PERSON';
    public static readonly TYPE_CD_COMPANY: string = 'COMPANY';
  public static readonly TYPE_CD_LIST__ALL = [
    McConsumerGENERATED.TYPE_CD_PERSON,
    McConsumerGENERATED.TYPE_CD_COMPANY
];

  public apiService: McConsumerService;
  public _rawJson: IMcConsumer;
    id: number = 0;
  accountOwner = '';
  active: boolean;
  addressSupplement = '';
  archived: boolean;
  bankName = '';
  bic = '';
  birthday: number;
  businessCellphone = '';
  businessTelephone = '';
  cellphone = '';
  city = '';
  companyName = '';
  contractBaseAmount: number;
  contractCancellationActiveOnDate: number;
  contractCancellationDate: number;
  contractCancellationReason = '';
  contractEndDate: number;
  contractModel = '';
  contractNumber = '';
  contractSigningDate: number;
  contractStartDate: number;
  contractStatus = '';
  contractType = '';
  countryName = '';
  createdAt: number;
  createdBy: number;
  email = '';
  euVat = '';
  extConsumerId = '';
  fax = '';
  firstName = '';
  gender = '';
  iban = '';
  idMcEntity: number;
  isoCountry = '';
  isoLanguage = '';
  lastName = '';
  postCode = '';
  sepaMandanteDate: number;
  sepaMandanteId = '';
  stateIndex: number;
  statusCd: string;
  street = '';
  telephone = '';
  title = '';
  typeCd: string;
  updatedAt: number;
  updatedBy: number;
  version: number;
  blacklistPercentage: number;  consumerDisplayName: string;  entityConsumerAttributes: McEntityConsumerAttributeList;  entityName: string;  numOfActiveContract: number;

  public properties: string[] = ['id', 'accountOwner', 'active', 'addressSupplement', 'archived', 'bankName', 'bic', 'birthday', 'businessCellphone', 'businessTelephone', 'cellphone', 'city', 'companyName', 'contractBaseAmount', 'contractCancellationActiveOnDate', 'contractCancellationDate', 'contractCancellationReason', 'contractEndDate', 'contractModel', 'contractNumber', 'contractSigningDate', 'contractStartDate', 'contractStatus', 'contractType', 'countryName', 'createdAt', 'createdBy', 'customAttributes', 'email', 'euVat', 'extConsumerId', 'fax', 'firstName', 'gender', 'iban', 'idMcEntity', 'individual', 'isoCountry', 'isoLanguage', 'lastName', 'postCode', 'sepaMandanteDate', 'sepaMandanteId', 'stateIndex', 'statusCd', 'street', 'telephone', 'title', 'typeCd', 'updatedAt', 'updatedBy', 'version', 'blacklistPercentage', 'consumerDisplayName', 'entityConsumerAttributes', 'entityName', 'numOfActiveContract'];
  public propertiesRegular: string[] = ['id', 'accountOwner', 'active', 'addressSupplement', 'archived', 'bankName', 'bic', 'birthday', 'businessCellphone', 'businessTelephone', 'cellphone', 'city', 'companyName', 'contractBaseAmount', 'contractCancellationActiveOnDate', 'contractCancellationDate', 'contractCancellationReason', 'contractEndDate', 'contractModel', 'contractNumber', 'contractSigningDate', 'contractStartDate', 'contractStatus', 'contractType', 'countryName', 'createdAt', 'createdBy', 'customAttributes', 'email', 'euVat', 'extConsumerId', 'fax', 'firstName', 'gender', 'iban', 'idMcEntity', 'individual', 'isoCountry', 'isoLanguage', 'lastName', 'postCode', 'sepaMandanteDate', 'sepaMandanteId', 'stateIndex', 'statusCd', 'street', 'telephone', 'title', 'typeCd', 'updatedAt', 'updatedBy', 'version', 'blacklistPercentage', 'consumerDisplayName', 'entityName', 'numOfActiveContract'];
  public propertiesSpecial: string[] = ['entityConsumerAttributes'];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McConsumerGENERATED.STATUS_CD_ACTIVE, McGod.t('cc.common.view.active'));
    list.add(McConsumerGENERATED.STATUS_CD_FROZEN, McGod.t('cc.common.view.frozen'));
    list.add(McConsumerGENERATED.STATUS_CD_INACTIVE, McGod.t('cc.common.view.inactive'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McConsumerGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McConsumerGENERATED.TYPE_CD_PERSON, McGod.t('cc.consumers.edit.person'));
    list.add(McConsumerGENERATED.TYPE_CD_COMPANY, McGod.t('cc.consumers.edit.company'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = McConsumerGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McConsumer {
    console.error('MTCN-ERROR: Not Implemented: McConsumer::createNewInstance(). Add this method to final class and return new McConsumer();');
    throw new Error('Cannot McConsumer::createNewInstance(). Add this method to final class and return new McConsumer();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcConsumer): McConsumer {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McConsumerService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McConsumer AABB()');
          if (this._rawJson['entityConsumerAttributes']) {
        this.entityConsumerAttributes = McEntityConsumerAttributeList.createFromJson(this._rawJson['entityConsumerAttributes']);
      }

  }

  // ---------------------------------------------------------------------

    public getContractBaseAmountStr(): string {
       return McGod.formatNumberToMoney(this.contractBaseAmount);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('entityConsumerAttributes')) {
        if (this.entityConsumerAttributes != null) {
            dto['entityConsumerAttributes'] = this.entityConsumerAttributes.toDto();
        } else {
            dto['entityConsumerAttributes'] = [];
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --


  public getStatusCdLabel(): string {
    return McConsumerGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === McConsumerGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdFROZEN(): boolean {
    const result = (this.statusCd === McConsumerGENERATED.STATUS_CD_FROZEN);

    return result;
  }

 public  isStatusCdINACTIVE(): boolean {
    const result = (this.statusCd === McConsumerGENERATED.STATUS_CD_INACTIVE);

    return result;
  }


  public getTypeCdLabel(): string {
    return McConsumerGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdPERSON(): boolean {
    const result = (this.typeCd === McConsumerGENERATED.TYPE_CD_PERSON);

    return result;
  }

 public  isTypeCdCOMPANY(): boolean {
    const result = (this.typeCd === McConsumerGENERATED.TYPE_CD_COMPANY);

    return result;
  }



// ---------------------------------------------------------------------
public callImportConsumers( mappingId: number, handleDuplicatesCd: string, dateFormat: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.importConsumers(mappingId,handleDuplicatesCd,dateFormat).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

// ---------------------------------------------------------------------
public callDevFixConsumerStatusAndTypeCode( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.devFixConsumerStatusAndTypeCode().subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

// ---------------------------------------------------------------------
public callDevFixConsumerContractNumber( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.devFixConsumerContractNumber().subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}






}
