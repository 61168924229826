/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {BkFinanceReport} from '../models/bk-finance-report.model';
import {BkFinanceReportService} from '../services/bk-finance-report.service';
import {IBkFinanceReport} from '../_generated/bk-finance-report.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class BkFinanceReportGENERATED extends BaseObject {


    public static readonly STATUS_CD_CREATED: string = 'CREATED';
    public static readonly STATUS_CD_INVALID: string = 'INVALID';
    public static readonly STATUS_CD_FAILED: string = 'FAILED';
  public static readonly STATUS_CD_LIST__ALL = [
    BkFinanceReportGENERATED.STATUS_CD_CREATED,
    BkFinanceReportGENERATED.STATUS_CD_INVALID,
    BkFinanceReportGENERATED.STATUS_CD_FAILED
];

  public apiService: BkFinanceReportService;
  public _rawJson: IBkFinanceReport;
    id: number = 0;
  bookingDateFrom: number;
  bookingDateTo: number;
  idWebFile: number;
  statusCd: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  sysUpdatedUserId: number;
  exportedBy: string;  financeReportFilename: string;

  public properties: string[] = ['id', 'bookingDateFrom', 'bookingDateTo', 'idWebFile', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'exportedBy', 'financeReportFilename'];
  public propertiesRegular: string[] = ['id', 'bookingDateFrom', 'bookingDateTo', 'idWebFile', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'exportedBy', 'financeReportFilename'];
  public propertiesSpecial: string[] = [];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(BkFinanceReportGENERATED.STATUS_CD_CREATED, McGod.t('Created'));
    list.add(BkFinanceReportGENERATED.STATUS_CD_INVALID, McGod.t('Invalid'));
    list.add(BkFinanceReportGENERATED.STATUS_CD_FAILED, McGod.t('Failed'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = BkFinanceReportGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): BkFinanceReport {
    console.error('MTCN-ERROR: Not Implemented: BkFinanceReport::createNewInstance(). Add this method to final class and return new BkFinanceReport();');
    throw new Error('Cannot BkFinanceReport::createNewInstance(). Add this method to final class and return new BkFinanceReport();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IBkFinanceReport): BkFinanceReport {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(BkFinanceReportService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE BkFinanceReport AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return BkFinanceReportGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdCREATED(): boolean {
    const result = (this.statusCd === BkFinanceReportGENERATED.STATUS_CD_CREATED);

    return result;
  }

 public  isStatusCdINVALID(): boolean {
    const result = (this.statusCd === BkFinanceReportGENERATED.STATUS_CD_INVALID);

    return result;
  }

 public  isStatusCdFAILED(): boolean {
    const result = (this.statusCd === BkFinanceReportGENERATED.STATUS_CD_FAILED);

    return result;
  }


  
}
