import { Component, OnInit } from '@angular/core';
import {Location} from "@angular/common";
import {FormGroup} from "@angular/forms";
import {ImDataImportService, ImDataMapping, ImDataMappingItem, ImDataMappingItemService, ImDataMappingService, ImDataSet} from "@miticon-ui/mc-core";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {ICON_BACK} from "../../../../assets/media/svg_icons/icon-back";
import {DataImportInfoDialogComponent} from "../../components/data-import-info-dialog/data-import-info-dialog.component";
import {ICON_ATTRIBUTE} from "../../../../assets/media/svg_icons/icon-attribute";


@Component({
  selector: 'lib-data-import-add',
  templateUrl: './data-import-add.component.html',
  styleUrls: ['./data-import-add.component.scss']
})
export class DataImportAddComponent implements OnInit {
  iconBack = ICON_BACK;
  uploadForm!: FormGroup;
  mappingForm!: FormGroup;
  importForm!: FormGroup;
  selectedStep = 0;
  uploadFormValid: boolean = false;
  validDelimiter: boolean = false;
  mappingFormValid: boolean = false;
  fileContent!: string[];
  dataSetId!: number;
  file: any;
  dataSet!: ImDataSet;

  constructor(private location: Location,
              private dataMappingService: ImDataMappingService,
              private dataMappingItemService: ImDataMappingItemService,
              private dataImportService: ImDataImportService,
              private dialog: MatDialog,
              private tS: TranslateService) {}

  ngOnInit(): void {
  }

  setFileContent(event: any): void {
    this.fileContent = event;
  }

  setFile(event: any): void {
    this.file = event;
  }

  setDataSet(event: any): void {
    this.dataSet = event;
    this.dataSetId = this.dataSet.id;
  }

  setUploadForm($event: FormGroup): void {
    this.uploadForm = $event;
  }

  setMappingForm($event: FormGroup): void {
    this.mappingForm = $event;
  }

  setImportForm($event: FormGroup): void {
    this.importForm = $event;
  }

  disableImport(): boolean {
    return !this.importForm?.value?.importMode;
  }

  isInvalidDelimiter(): boolean {
    if(this.file.type === 'text/csv') {
      return !this.validDelimiter;
    } else return false;
  }

  import(): void {
    if(Number(this.mappingForm.controls['choosenMapping'].value)) {
      if(this.mappingForm.controls['editedMapping'].value) {
        if(this.mappingForm.controls['updateMapping'].value) {
          this.updateMappingAndStartImport(Number(this.mappingForm.controls['choosenMapping'].value));
        } else this.createMappingAndStartImport();
      } else this.startImport(this.mappingForm.controls['choosenMapping'].value);
    } else {
      this.createMappingAndStartImport();
    }
  }

  createMappingAndStartImport() {
    const dataMapping = new ImDataMapping();
    const isSaveMapping = this.mappingForm.controls['mappingName'].value && this.mappingForm.controls['mappingName'].value.length > 0;
    dataMapping.mappingName = isSaveMapping ? this.mappingForm.controls['mappingName'].value : "NON_VISIBLE_" + (new Date()).toString();
    dataMapping.sharedFlg = false;
    dataMapping.visibleFlg = isSaveMapping;
    dataMapping.idDataSet = this.dataSetId;
    this.dataMappingService.insert(dataMapping).subscribe((mapping) => {
      if(mapping) {
        const mappingItems = this.mappingForm.controls['mappedAttributes'].value;
        mappingItems.forEach((mappingItem: any, index: number) => {
          this.dataMappingItemService.insert(this.mapToDataMappingItem(mappingItem, mapping.id))
            .subscribe((mappingItem) => {
              if(mappingItem && index === mappingItems.length - 1) {
                this.startImport(mapping.id);
              }
            })
        })
      }
    })
  }

  updateMappingAndStartImport(mappingId: number) {
    const dataMappingItemsList: any[] = [];
    const mappingItems = this.mappingForm.controls['mappedAttributes'].value;
    mappingItems.forEach((mappingItem: any) => {
      dataMappingItemsList.push(this.mapToDataMappingItem(mappingItem, mappingId));
    });
    this.dataMappingItemService.updateByMappingId(mappingId, dataMappingItemsList)
      .subscribe((data) => {
        if(data) {
          this.startImport(mappingId);
        }
      })
  }

  startImport(mappingId: number): void {
    const data = {
      file: this.file,
      aRelativePath: `E1/${localStorage.getItem('entityId')}/import`,
      type: "CSR_CONSUMER_IMPORT",
      source: this.uploadForm.controls['source'].value,
      importMode: this.importForm.controls['importMode'].value,
      mappingId: mappingId,
      customName: this.uploadForm.controls['customName'].value ? this.uploadForm.controls['customName'].value : "",
      datasetId: this.uploadForm.controls['dataSet'].value,
      delimiter: this.uploadForm.controls['otherDelimiter'].value
        ? this.uploadForm.controls['otherDelimiter'].value
        : this.uploadForm.controls['delimiter'].value,
      encoding: this.uploadForm.controls['encoding'].value,
      header: this.uploadForm.controls['header'].value
    }

    this.dataImportService.importData(data).subscribe((data) => {
      if(data) {
        this.location.back();
      }
    })
  }

  mapToDataMappingItem(mappingItem: any, mappingId: number): ImDataMappingItem {
    const dataMappingItem = new ImDataMappingItem();
    dataMappingItem.attributeName = mappingItem.attributeName.replace("*", "");
    dataMappingItem.columnName = mappingItem.columnName;
    dataMappingItem.sampleData = mappingItem.sampleData;
    dataMappingItem.idDataMapping = mappingId;
    return dataMappingItem;
  }

  goToNextStep(): void {
    if(this.selectedStep === 0 && this.dataSetId === 1) {
      this.dialog.open(DataImportInfoDialogComponent, {
        autoFocus: false,
        width: '600px',
        height: '250px',
        data: {
          title: this.tS.instant('mem.import.upload.req-fields'),
          icon: ICON_ATTRIBUTE,
          text: this.tS.instant('mem.data-import.info-dialog-message'),
          button: 'Ok'
        }
      })
    }
    this.selectedStep += 1;
  }

  goToPrevStep(): void {
    this.selectedStep -= 1;
  }

  goBack(): void {
    this.location.back();
  }
}
