/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McConsumerAction} from '../models/mc-consumer-action.model';
import {McConsumerActionList} from '../models/mc-consumer-action-list.model';
import {McConsumerActionFilter} from '../models/mc-consumer-action-filter.model';
import {McConsumerActionService} from '../services/mc-consumer-action.service';
import {IMcConsumerAction} from '../_generated/mc-consumer-action.interface';
import {McGod} from "../extra/mc-god.service";

export class McConsumerActionListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'emailActionFlg', 'emailBodyAttachmentWebfileId', 'emailBodyHtml', 'emailSubject', 'errorMessage', 'idCsrConsumer', 'postActionFlg', 'postWebfileId', 'sentDatetime', 'smsActionFlg', 'smsBody', 'statusCd', 'sysCreatedDatetime', 'title', 'typeCd'];
  public items: McConsumerAction[] = [];
  public apiService: McConsumerActionService;
 // protected tempObjList: McConsumerActionList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McConsumerActionList {
    console.error('MTCN-ERROR: Not Implemented: McConsumerActionList::createNewInstance(). Add this method to final class and return new McConsumerActionList();');
    throw new Error('Cannot McConsumerActionList::createNewInstance(). Add this method to final class and return new McConsumerActionList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcConsumerAction[]): McConsumerActionList {
    const  listObj: McConsumerActionList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McConsumerAction = McConsumerAction.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McConsumerActionList {
    const  listObj: McConsumerActionList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McConsumerActionService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcConsumerAction[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McConsumerAction = McConsumerAction.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcConsumerActionFilter: McConsumerActionFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcConsumerActionFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McConsumerAction {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McConsumerAction();
    }
  }

  public replaceItem(newItem: McConsumerAction) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

