import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ICsrContractHistory} from '../_generated/csr-contract-history.interface';
import {CsrContractHistory} from '../models/csr-contract-history.model';
import {CsrContractHistoryList} from '../models/csr-contract-history-list.model';
import {CsrContractHistoryServiceGENERATED} from '../_generated/csr-contract-history-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';


@Injectable({
  providedIn: 'root'
})
export class CsrContractHistoryService extends CsrContractHistoryServiceGENERATED {

  public getByIdCsrConsumer(idCsrConsumer: number, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/consumer/${idCsrConsumer}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  public getByIdCsrContract(idCsrContract: number, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/contract/${idCsrContract}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }


}
