import {Inject, Injectable} from '@angular/core';
import {EbSepaImportServiceGENERATED} from '../_generated/eb-sepa-import-generated.service';



@Injectable({
  providedIn: 'root'
})
export class EbSepaImportService extends EbSepaImportServiceGENERATED {

}
