import {Injectable} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {PmAdjustmentNotificationInfoServiceGENERATED} from '../_generated/pm-adjustment-notification-info-generated.service';
import {Observable} from 'rxjs';
import {SortCriteriaList} from "../_core/sort-criteria-list.model";


@Injectable({
  providedIn: 'root'
})
export class PmAdjustmentNotificationInfoService extends PmAdjustmentNotificationInfoServiceGENERATED {

  public getAllByEntityId(params: HttpParams, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    params = params.set('page', aPageNo.toString());
    params = params.set('size', aItemsPerPage.toString());

    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/get-all-by-entity-id?${params.toString()}${sortCriteria.toUrlQueryString()}`,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json'
          })
      });
  }
}
