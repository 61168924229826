import {McEntityIdAndName} from "./mc-entity-id-and-name.model";
import {BaseObject} from '../_core/base-object';

export abstract class McEntityIdAndNameAbstract extends BaseObject {
  id: number;
  name: string;

  public propertiesRegular: string[] = ['id', 'name'];

  public static createFromJson(aItemJsonObj: any): McEntityIdAndName {
    const item = this.createNewInstance();
    item.populateFromJson(aItemJsonObj);
    return item;
  }

  public populateFromJson(aSourceJson: any) {
    this.propertiesRegular.forEach((key) => {
      if (aSourceJson.hasOwnProperty(key)) {
        // @ts-ignore
        this[key] = aSourceJson[key];
      }
    });
  }

  public static createNewInstance(): McEntityIdAndName {
    console.error('MTCN-ERROR: Not Implemented: McEntityIdAndName::createNewInstance(). Add this method to final class and return new McEntityIdAndName();');
    throw new Error('Cannot McEntityIdAndName::createNewInstance(). Add this method to final class and return new McEntityIdAndName();');
  }
}
