<div class="overflow-fix">
<div class="row pt-4" *ngIf="!factoringIsEnabled">
  <div class="col-md-12">
    <p>Factoring for this entity has been disabled.</p>
  </div>
</div>
<!--<div class="row py-5" *ngIf="factoringIsEnabled">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <p *ngIf="!activeFactoring.exists()">Currently, there are no active factoring contracts.</p>-->
        <!--<div class="row">
            <div class="col-md-4">
              <p *ngIf="pendingFactoringList.items.length === 1">There is 1 factoring contract pending.</p>
              <p *ngIf="pendingFactoringList.items.length > 1">There are {{pendingFactoringList.items.length}} factoring contracts pending.</p>
            </div>
        </div>-->

        <!--<div>
          <div class="row pt-3 pb-3" *ngIf="activeFactoring.exists()">
            <div class="col-md-12 pb-4 pt-4">
              <h5>Currently active factoring contract conditions:</h5>
            </div>
          </div>
          <app-shared-factoring-profile-part [ebFactoring]="activeFactoring" *ngIf="activeFactoring.exists()"></app-shared-factoring-profile-part>
        </div>
      </div>-->
<!--    </div>
  </div>
</div>-->

<div class="col-md-12 position-relative mt-1 mb-5">
  <div class="mc-create-user-notify-wrapper">
    <mc-notify-message [message]=message [type]="type"></mc-notify-message>
  </div>
</div>

<mcc-modal1-wrapper *ngIf="ebFactoring" [showMcb]="showFactoringProfileModalMcb">
  <app-shared-factoring-profile-part [ebFactoring]="ebFactoring" [ebFactoringId]="profileEbFactoringId"></app-shared-factoring-profile-part>
</mcc-modal1-wrapper>

<div class="mc-factoring-table mt-4 ml-n5">
  <h3 class="ml-3">Factoring Contracts</h3>
  <div *ngIf="factoringList.apiSuccessFlg">
    <mcc-fi-table-3 [objectList]="factoringList"
                    [table3Config]="ebFactoringTable3Config"
                    (eventPaginationOrSortChanged)="actLoad()"
                    (eventItemAction)="actFactoringTableItemAction($event)"></mcc-fi-table-3>
  </div>
</div>
</div>
