import { McEbicsEbicsListPageComponent } from './pages/mc-ebics-ebics-list-page/mc-ebics-ebics-list-page.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard, McPermissionGuard} from '@miticon-ui/mc-core';

const routes: Routes = [
{
    path: '',
    component: McEbicsEbicsListPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McEbicsRoutingModule {
}
