<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcBillingStatement.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h1 *ngIf="mcBillingStatement.exists()">Edit McBillingStatement (#{{mcBillingStatement.id}})</h1>
    <h1 *ngIf="!mcBillingStatement.exists()">Create statement</h1>

  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!mcBillingStatement.apiLoadingFlg" class="content-body">
    <mcc-message [errorMsg]="mcBillingStatement.apiErrorMessage"></mcc-message>
    <mcc-message [errorMsgs]="errorMsgs" label="Unable to proceed: "></mcc-message>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container *ngIf="(mcBillingStatement.apiSuccessFlg) && (!hasErrors())">

      <div class="row">
        <div class="col-md-12">
          <div class="content-section">
            <h2>Statement setup</h2>
            <div class="row">
              <div class="statements-entities-filter">
                <mcc-fi-multiselect2 *ngIf="childEntitiesVll.items.length > 0" [optionVll]="childEntitiesVll" [label]="'cc.common.admin.entities' | translate"
                                     [valueVll]="childEntitiesValueVll" [pleaseChoosePlaceHolderFlg]="true" [name]="'selEntities'"
                                     (eventValueChanged)="onSelectEntities($event)" [validRequiredFlg]="true" [mcForm]="mcForm">
                </mcc-fi-multiselect2>
              </div>
            </div>

<!--            <mcc-fi-select [mcForm]="mcForm" name="selEntity" label="{{'cc.common.view.entity' | translate}}"-->
<!--                           placeholderOption="{{'cc.common.edit.please-choose' | translate}}" [validRequiredFlg]="true"-->
<!--                           [optionsVll]="childEntitiesVll"></mcc-fi-select>-->


            <mcc-fi-textbox label="Statement title" name="ctrlTitle" (eventEnterClicked)="actLoad()"
                            [mcForm]="mcForm" [validRequiredFlg]="true" [validWhitespaces]="true"></mcc-fi-textbox>

            <mcc-fi-date-picker
              label="Statement date"
              [name]="'ctrlStatementDate'"
              [validRequiredFlg]="true"
              [mcForm]="mcForm">
            </mcc-fi-date-picker>

            <mcc-fi-checkbox [name]="'ctrlAutoImport'" label="Auto-import billing item(s)" *ngIf="mcGod.userHasMcBillingOutStatementAutoImportBillingItemsPermission()"
                             [mcForm]="mcForm"></mcc-fi-checkbox>

            <mcc-fi-checkbox [name]="'ctrlHideUnhideStatement'" label="Hide statement" tooltipText="The Child entity will not be able to see this statement if this box is checked. You can edit this setting later."
                             [mcForm]="mcForm" *ngIf="mcGod.userHasMcBillingOutBillingHideUnhideStatementPermission()"></mcc-fi-checkbox>


          </div>
        </div>
      </div><!-- row -->
    </ng-container>

  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="Cancel"></mcc-button>
    <mcc-button *ngIf="(mcBillingStatement.apiSuccessFlg) && (!hasErrors())" extraCssClasses="btn btn-primary"
                (eventClicked)="actSave();" label="<i class='fas fa-check'></i> Create" [disabledMcb]="saveButtonDisabledMcb"
                disableOnFirstClickFlg="true"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
