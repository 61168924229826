/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingServiceUnitTypeTable3ConfigGENERATED} from '../_generated/mc-billing-service-unit-type-table-3-config-generated.model';

// import {McBillingServiceUnitTypeGENERATED} from '../_generated/mc-billing-service-unit-type-generated.model';

export class McBillingServiceUnitTypeTable3Config extends McBillingServiceUnitTypeTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colTypeCd', 'Type cd', 'typeCd', 'typeCd');
    this.addColumn('colIdMcBillingService', 'Billing service id', 'idMcBillingService', 'idMcBillingService');


  }
}
