/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McBillingPayoutFileItemFeCtd} from '../models/mc-billing-payout-file-item-fe-ctd.model';
import {McBillingPayoutFileItemFeCtdList} from '../models/mc-billing-payout-file-item-fe-ctd-list.model';
import {McBillingPayoutFileItemFeCtdFilter} from '../models/mc-billing-payout-file-item-fe-ctd-filter.model';
import {McBillingPayoutFileItemFeCtdService} from '../services/mc-billing-payout-file-item-fe-ctd.service';
import {IMcBillingPayoutFileItemFeCtd} from '../_generated/mc-billing-payout-file-item-fe-ctd.interface';
import {McGod} from "../extra/mc-god.service";

export class McBillingPayoutFileItemFeCtdListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'amount', 'bic', 'description', 'entityName', 'iban', 'idMcBillingPayoutFile', 'idMcBillingStatement', 'idMcEntity', 'studioOwner'];
  public items: McBillingPayoutFileItemFeCtd[] = [];
  public apiService: McBillingPayoutFileItemFeCtdService;
 // protected tempObjList: McBillingPayoutFileItemFeCtdList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingPayoutFileItemFeCtdList {
    console.error('MTCN-ERROR: Not Implemented: McBillingPayoutFileItemFeCtdList::createNewInstance(). Add this method to final class and return new McBillingPayoutFileItemFeCtdList();');
    throw new Error('Cannot McBillingPayoutFileItemFeCtdList::createNewInstance(). Add this method to final class and return new McBillingPayoutFileItemFeCtdList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcBillingPayoutFileItemFeCtd[]): McBillingPayoutFileItemFeCtdList {
    const  listObj: McBillingPayoutFileItemFeCtdList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McBillingPayoutFileItemFeCtd = McBillingPayoutFileItemFeCtd.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McBillingPayoutFileItemFeCtdList {
    const  listObj: McBillingPayoutFileItemFeCtdList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingPayoutFileItemFeCtdService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcBillingPayoutFileItemFeCtd[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McBillingPayoutFileItemFeCtd = McBillingPayoutFileItemFeCtd.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcBillingPayoutFileItemFeCtdFilter: McBillingPayoutFileItemFeCtdFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcBillingPayoutFileItemFeCtdFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McBillingPayoutFileItemFeCtd {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McBillingPayoutFileItemFeCtd();
    }
  }

  public replaceItem(newItem: McBillingPayoutFileItemFeCtd) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

