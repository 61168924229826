/*BbmTestObj*/
import { McProcessFlowCriteriaAttributeGENERATED } from "../_generated/mc-process-flow-criteria-attribute-generated.model";

export class McProcessFlowCriteriaAttribute extends McProcessFlowCriteriaAttributeGENERATED {
  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessFlowCriteriaAttribute();
  }

  // ---------------------------------------------------------------------
  public getOperatorTypeCdVll(type: string | undefined): any {
    let list;
    switch (type) {
      case McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_DATETIME:
      case 'DATE':
        list =
          McProcessFlowCriteriaAttributeGENERATED.getDatetimeOperatorTypeCdVll();
        return list;
      case McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_BOOLEAN:
        list =
          McProcessFlowCriteriaAttributeGENERATED.getBooleanOperatorTypeCdVll();
        return list;
      case McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_NUMBER:
        list =
          McProcessFlowCriteriaAttributeGENERATED.getNumberOperatorTypeCdVll();
        return list;
      case McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_STRING:
        list =
          McProcessFlowCriteriaAttributeGENERATED.getStringOperatorTypeCdVll();
        return list;
      case McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_MONEY:
        list =
          McProcessFlowCriteriaAttributeGENERATED.getMoneyOperatorTypeCdVll();
        return list;
    }
  }
  // ---------------------------------------------------------------------------
  public getOptionTypeCdVll(type: string | undefined): any {
    let list;
    switch (type) {
      case McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_DATETIME:
      case 'DATE':
        list =
          McProcessFlowCriteriaAttributeGENERATED.getDatetimeOptionTypeCdVll();
        return list;
      case McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_BOOLEAN:
        list =
          McProcessFlowCriteriaAttributeGENERATED.getDatetimeOptionTypeCdVll();
        return list;
      case McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_NUMBER:
        list =
          McProcessFlowCriteriaAttributeGENERATED.getNumberOptionTypeCdVll();
        return list;
      case McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_STRING:
        list =
          McProcessFlowCriteriaAttributeGENERATED.getStringOptionTypeCdVll();
        return list;
      case McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_MONEY:
        list =
          McProcessFlowCriteriaAttributeGENERATED.getMoneyOptionTypeCdVll();
        return list;
    }
  }
}
