<div *ngIf="isVisible()" class="mcc-modal modal show d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Factoring profile</h1>
        <button type="button" class="close" (click)="actCancel()">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-shared-factoring-profile-part [ebFactoring]="ebFactoring" [ebFactoringId]="ebFactoringId"></app-shared-factoring-profile-part>
      </div>

    </div>
  </div>
</div>
<router-outlet></router-outlet>

