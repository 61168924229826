/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McPriceList2EntityFilter} from '../models/mc-price-list-2-entity-filter.model';

export class McPriceList2EntityFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McPriceList2EntityFilter {
    console.error('MTCN-ERROR: Not Implemented: McPriceList2EntityFilter::createNewInstance(). Add this method to final class and return new McPriceList2EntityFilter();');
      throw new Error('Cannot McPriceList2EntityFilter::createNewInstance(). Add this method to final class and return new McPriceList2EntityFilter();');
  }

}
