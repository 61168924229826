
import {Table3Config} from '../extra/table-3-config';

// import {CsrConsumer2CtdEmailGENERATED} from '../_generated/csr-consumer-2-ctd-email-generated.model';

export class CsrConsumer2CtdEmailTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colCsrConsumer', 'Consumer', 'csrConsumer', 'csrConsumer');
    this.addColumn('colCtdEmail', 'Email', 'ctdEmail', 'ctdEmail');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colIdCsrConsumer', 'Csr Consumer Id', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colIdCtdEmail', 'Ctd Email Id', 'idCtdEmail', 'idCtdEmail');

*/
  }
}
