export const ICON_CLOSE = `<svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Search" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Purchase-product-popup" transform="translate(-766.000000, -49.000000)">
        <g id="close-24px" transform="translate(766.000000, 49.000000)">
<!--            <polygon id="Path" points="0 0 30 0 30 30 0 30"></polygon>-->
            <polygon id="Path" fill-opacity="0.87" fill="#53589B" fill-rule="nonzero" points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"></polygon>
        </g>
        </g>
    </g>
</svg>`
