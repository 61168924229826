/*BbmTestObj*/
import {EbSepaStatementReportGENERATED} from '../_generated/eb-sepa-statement-report-generated.model';
import {McDateUtils} from '../common/mc-date-utils';
import {CurrencyUtil} from "../common/currency-util";

export class EbSepaStatementReport extends EbSepaStatementReportGENERATED {

  public static FILTER_IMPORTED_BY = 'cc.common.imported-by';
  public static FILTER_IMPORTED_DATE_TIME = 'cc.factoring.imported-sepa.import-date-&-time';
  public static FILTER_STATUS = 'cc.common.status';

  public static ACTION_DOWNLOAD = 'ACTION_DOWNLOAD';

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaStatementReport();
  }

  fldStarMoneyFileName() {
    if (this.statusCd === EbSepaStatementReport.STATUS_CD_ACTIVE) {
      return `<div class="mc-disabled-table-row">${this.sepaStatementReportFilename}</div>`;
    } else {
      return this.sepaStatementReportFilename;
    }
  }

  fldImportDateAndTime(): string {
    if (this.importDate) {
      /*if (this.statusCd === EbSepaStatementReport.STATUS_CD_ACTIVE) {
        return `<div class="mc-disabled-table-row">${McDateUtils.newFormatDateTimeString(this.importDate)}</div>`;
      } else {
        return McDateUtils.newFormatDateTimeString(this.importDate);
      }*/
      return McDateUtils.newFormatDateTimeString(this.importDate);
    }
    return '';
  }

  formatHoursAndMinutesInto2Digits(num: number) {
    if (num < 10) {
      return '0' + num;
    } else {
      return num;
    }
  }


  fldNewRepeatedTransactions() {
    if (this.statusCd === EbSepaStatementReport.STATUS_CD_ACTIVE) {
      return `<div class="mc-disabled-table-row">${this.newTransactionsCount + '/' + this.repeatedTransactionsCount}</div>`;
    } else {
      return this.newTransactionsCount + '/' + this.repeatedTransactionsCount;
    }
  }

  fldImportedBy() {
    if (this.statusCd === EbSepaStatementReport.STATUS_CD_ACTIVE) {
      return `<div class="mc-disabled-table-row">${this.importedBy}</div>`;
    } else {
      return this.importedBy;
    }
  }

  fldStarMoneyStatus() {
    if (this.getStatusCdLabel() === '--BAD-CD--') {
      const completedStatus = EbSepaStatementReport.STATUS_CD_COMPLETED;
      return completedStatus.charAt(0) + completedStatus.substring(1).toLowerCase();
    } else {
      return this.getStatusCdLabel();
    }
  }

  fldWithdrawnTotalMatchedCount() {
    return `${this.totalWithdrawnCount}/${this.matchedWithdrawnCount}`;
  }


  fldWithdrawnTotalMatchedAmount() {
    return `${CurrencyUtil.format(this.totalWithdrawnAmount, this.mcCurrency.code)}/${CurrencyUtil.format(this.matchedWithdrawnAmount, this.mcCurrency.code)}`;
  }

  fldReturnedTotalMatchedCount() {
    return `${this.totalReturnedCount}/${this.matchedReturnedCount}`;
  }

  fldReturnedTotalMatchedAmount() {
    return `${CurrencyUtil.format(this.totalReturnedAmount, this.mcCurrency.code)}/${CurrencyUtil.format(this.matchedReturnedAmount, this.mcCurrency.code)}`;
  }

}
