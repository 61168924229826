import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BaseDirective, McBankAccountList,
  McBillingPayoutFileFeCtd,
  McForm,
  McGod,
  McValueLabelList
} from '@miticon-ui/mc-core';
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'mc-out-billing-create-payout-file-part',
  templateUrl: './mc-out-billing-create-payout-file-part.component.html',
  styleUrls: ['./mc-out-billing-create-payout-file-part.component.scss']
})
export class McOutBillingCreatePayoutFilePartComponent extends BaseDirective implements OnInit {

  mcForm = new McForm();
  saveButtonLabel = '<i class="fas fa-plus"></i> Create';
  createTypeVll = new McValueLabelList();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventCreated = new EventEmitter<string>();
  @Input() statementIds = [];
  payoutFile = new McBillingPayoutFileFeCtd();
  successMsg!: string;
  bankAccountsVll = new McValueLabelList();
  bankAccounts = new McBankAccountList();

  ngOnInit() {
    this.createTypeVll.add(McBillingPayoutFileFeCtd.TYPE_CD_STAR_MONEY, 'Star money');
    this.createTypeVll.add(McBillingPayoutFileFeCtd.TYPE_CD_EBICS, 'EBICS');

    this.loadBankAccountList();
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  loadBankAccountList() {
    this.bankAccounts.loadByEntityId(McGod.getLoggedEntityIdFromToken(), () => {
      this.bankAccounts.items.map(item => {
        const title = `${item.bankName} (IBAN: ${item.iban}, BIC: ${item.blz})`;
        this.bankAccountsVll.add(item.id, title);
      });
    });
  }

  onCreateTypeChanged($event: unknown) {
  }

  actSave() {
    if (this.mcForm.isValid()) {
      const bankAccountId = this.mcForm.getValue('selBankAccount');
      this.payoutFile.apiService.createPayoutFile(this.mcForm.getValue('ctrlCreateType'), this.statementIds, bankAccountId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res) => {
          this.eventCreated.emit();
        });
    }
  }
}
