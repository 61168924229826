<ng-container>
  <div class="content-wrapper">
    <div class="content-header-slim mc-text-align-left">
      <h2>{{'cc.roles.create-role-from-template' | translate}}</h2>
    </div>

    <div class="content-body-slim">
      <ng-container>

        <div class="row">
          <div class="col-md-12 mc-text-align-left d-flex">
            <p>{{'cc.roles.please-select-one-or-more-templates-to-create-a-role-from' | translate}}</p>
            <span class="text-danger">*</span>
          </div>
        </div>

        <div class="row" *ngFor="let template of roleTemplates">
          <div class="col-md-12">

            <div class="run-wrap role-template-wrap mc-text-align-left">
              <mcc-fi-checkbox [label]="template.caption"
                               [name]="template.caption"
                               [value]="template"
                               [isCheckboxChecked]="false"
                               #templateValue
                               (eventValueChanged)="onCheckboxEventValueChanged($event)"
                               (change)="onCheckboxChange($event, templateValue)"
                               [mcForm]="mcForm"></mcc-fi-checkbox>
              <div class="template-desc">
                <p class="mt-1">{{'cc.common.date.from' | translate}}: <span class="from-entity">{{template.entity.name}}</span></p>
                <p>{{'cc.common.view.description' | translate}}: <span class="description-txt">{{template.description}}</span></p>
              </div><!--template-desc-->
            </div>


            <!--          </div>-->
          </div>
        </div><!-- row -->
      </ng-container>
    </div>
    <div class="content-footer">
      <button class="btn btn-secondary move-right" (click)="onCancel()"><i class="far fa-times-circle pr-2"></i>{{'cc.common.edit.cancel' | translate}}</button>
      <button class="btn btn-primary" (click)="onCreate()" [disabled]="isButtonDisabled"><i class="fas fa-save pr-2"></i>{{'cc.common.view.create' | translate}}</button>
    </div>
  </div>
</ng-container>
