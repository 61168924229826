import {AfterContentChecked, Component, Inject, OnInit} from "@angular/core";
import {
  EbSepaFileTransactionList,
  EbSepaFileTransaction,
  EbSepaFileMyTransactionsTable3Config,
  EbSepaFileTransactionFilter,
  McGod,
  McEntity2List,
  McValueLabelList,
  McHtmlButton,
  McBoolean,
  McSlideActionSet,
  McSlideActionItem,
  EbFactoring,
  McUtilityService,
  McConsumer,
  McDateUtils,
  BaseDirective,
  EbFactoringService,
  CurrencyUtil,
  EbSepaFileTransactionService,
  McConsumerBalanceService
} from '@miticon-ui/mc-core';
import {McForm} from '@miticon-ui/mc-core';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {take, takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: "mc-my-factoring-transaction-list-page",
  templateUrl: "./mc-my-factoring-transaction-list-page.component.html",
})
export class McMyFactoringTransactionListPageComponent extends BaseDirective implements OnInit, AfterContentChecked {

  mcForm = new McForm();
  entitiesWithTransactions = new McEntity2List();
  ebSepaFileTransactionList!: EbSepaFileTransactionList;
  ebSepaFileTransactionTable3Config = new EbSepaFileMyTransactionsTable3Config();
  ebSepaFileTransactionFilter = new EbSepaFileTransactionFilter();
  ebSepaFileTransactionCdVll: McValueLabelList = EbSepaFileTransaction.getStatusCdVll();
  pausedUnpausedCdVll: McValueLabelList = EbSepaFileTransaction.getPauseUnpausedCdVll();
  inDunningCdVll: McValueLabelList = EbSepaFileTransaction.getInDunningFlgCdVll();
  completedDunningCdVll: McValueLabelList = EbSepaFileTransaction.getDunningCompletedCdVll();
  typeCdVll: McValueLabelList = EbSepaFileTransaction.getFactoredServiceTypeCdVll();
  entitiesWithTransactionsVll!: McValueLabelList;
  mcConsumer = new McConsumer();
  typeCds: any[] = [];
  statusCds: any[] = [];
  entityIds = [];
  clearedAllMultipleFilters: Subject<boolean> = new Subject<boolean>();

  actionEbSepaFileTransactionId = 0;
  actionEbSepaFileTransaction!: EbSepaFileTransaction;
  showTransactionAddPaymentMcb = new McBoolean();
  showTransactionProfileMcb = new McBoolean();
  showAddPaymentForMultipleTransactions = new McBoolean();
  selectedTransactionList = new EbSepaFileTransactionList();
  actionSet = new McSlideActionSet();
  numberOfSelectedItems!: number;
  notifyMessage = {errorMessage: "", successMessage: ""};
  mcGod = McGod.getInstance();
  showChangeCollectionDateMcb = new McBoolean();
  entityId!: number;
  ebSepaFileTransaction = new EbSepaFileTransaction();
  isNonFactoring!: boolean;
  ebFactoring = new EbFactoring();
  isTransactionAcc!: boolean;
  nonFactoringTransactions!: EbSepaFileTransaction[];
  areSelectedTransactions!: boolean;
  showPauseTransactionMcb = new McBoolean();
  clearDateRangeSubject = new Subject<boolean>();
  paymentDueDateFrom: any;
  paymentDueDateTo: any;
  showConsumerProfileMcb = new McBoolean();
  errorMsg!: string;
  currencyCode!: string;
  balanceAmount!: number;
  // ---------------------------------------------------------------------

  constructor(private utilityService: McUtilityService,
              private router: Router,
              private factoringService: EbFactoringService,
              private transactionService: EbSepaFileTransactionService,
              private mcConsumerBalanceService: McConsumerBalanceService,
              private toastr: ToastrService) {
    super();
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.ebSepaFileTransactionCdVll.items.splice(2, 2);
    this.entitiesWithTransactions.loadAllWhereFactoringTransactionChildIsLoggedInEntity(() => {
      this.entitiesWithTransactionsVll = this.entitiesWithTransactions.toVll('id', 'name');
      this.entitiesWithTransactionsVll.addFirst('', '-- all');
    });
    this.ebSepaFileTransactionList = new EbSepaFileTransactionList();
    this.ebSepaFileTransactionList.setPager(0, 100);
    this.actLoad();

    this.actionSet.addItem(McGod.t("cc.consumer.change-collection-date-s"), EbSepaFileTransaction.ACTION_CHANGE_COLLECTION_DATE,
      [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_VIEW],
      10, "");

    this.actionSet.addItem(this.mcGod.t("cc.transactions.record-payment-s"), EbSepaFileTransaction.ACTION_RECORD_PAYMENT, [McGod.PERM_EB_FACTORING_MY_TRANSACTION_RECORD_PAYMENT]);
  }

  ngAfterContentChecked() {
    if (this.selectedTransactionList.getCount() === 0) {
      this.notifyMessage.errorMessage = "";
    }
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.ebSepaFileTransactionFilter.outFactoringFlg = false;
    const entitiesIdToStringArr = this.entityIds.map((e: any) => e.toString());
    const filteredStatusCds = this.statusCds.filter((arr: []) => arr.length);
    const filteredEntityIds = entitiesIdToStringArr.filter(arr1 => arr1.length);
    const filteredTypeCds = this.typeCds.filter((arr2: []) => arr2.length);
    this.ebSepaFileTransactionFilter.searchTerm = this.mcForm.getValue("txbSearchTerm");
    this.ebSepaFileTransactionFilter.statusCds = filteredStatusCds;
    this.ebSepaFileTransactionFilter.childEntityIds = filteredEntityIds;
    this.ebSepaFileTransactionFilter.pausedFilterOptionCd = this.mcForm.getValue("selPausedUnpaused");
    this.ebSepaFileTransactionFilter.inDunningFilterOptionCd = this.mcForm.getValue('selInDunning');
    this.ebSepaFileTransactionFilter.dunningCompletedFilterOptionCd = this.mcForm.getValue('selCompletedDunning');
    this.ebSepaFileTransactionFilter.factoringTypeWithDelayFilterOptionCds = filteredTypeCds;
    this.ebSepaFileTransactionFilter.transactionPaymentDueDateTimeFromDate = this.paymentDueDateFrom ? this.paymentDueDateFrom : null;
    this.ebSepaFileTransactionFilter.transactionPaymentDueDateTimeToDate = this.paymentDueDateTo ? this.paymentDueDateTo : null;
    // this.ebSepaFileTransactionFilter.factoringTypeWithDelayFilterOptionCd = this.mcForm.getValue('selType');
    this.ebSepaFileTransactionList.loadByFilter(this.ebSepaFileTransactionFilter, () => {
      this.ebSepaFileTransactionList.items.forEach(item => {
        item.currencyCode = this.factoringService?.childFactorings?.find(factoring => factoring.id === item.idEbFactoring)?.mcCurrency.code;
      });
    });
  }

  actClearFilter() {
    this.clearedAllMultipleFilters.next(true);
    this.clearDateRangeSubject.next(true);
    this.mcForm.getControl("txbSearchTerm")?.get("value")?.setValue("");
    this.mcForm.getControl("selPausedUnpaused")?.get("value")?.setValue("");
    this.mcForm.getControl('selInDunning')?.get('value')?.setValue('');
    this.mcForm.getControl('selCompletedDunning')?.get('value')?.setValue('');
    this.actLoad();
  }


  actOnTransactionTableItemAction(itemAction: McHtmlButton) {
    this.actionEbSepaFileTransactionId = itemAction.item.id;
    this.actionEbSepaFileTransaction = itemAction.item;
    this.ebSepaFileTransaction = itemAction.item;
    this.isTransactionAcc = this.ebSepaFileTransaction.statusCd === "ACCEPTED";

    switch (itemAction.actionCdForEvent) {
      case EbSepaFileTransaction.ACTION_RECORD_PAYMENT:
        this.showTransactionAddPaymentMcb.setTrue();
        break;
      case EbSepaFileTransaction.ACTION_VIEW_DETAILS:
        this.showTransactionProfileMcb.setTrue();
        break;
      case EbSepaFileTransaction.ACTION_CHANGE_COLLECTION_DATE:
        if (this.isTransactionAcc) {
          this.mcConsumer.loadById(itemAction.item.idCsrConsumer, () => {
          });
          this.showChangeCollectionDateMcb.setTrue();
          this.numberOfSelectedItems = 1;
          this.ebSepaFileTransaction = itemAction.item;
          this.notifyMessage.errorMessage = "";
        } else {
          this.notifyMessage.errorMessage = McGod.t("cc.transactions.you-can-change-collection-date-only-for-transactions-with-status-accepted");
        }
        break;
      case EbSepaFileTransaction.ACTION_PAUSE_TRANSACTIONS:
        this.showPauseTransactionMcb.setTrue();
        break;
      case EbSepaFileTransaction.ACTION_UNPAUSE_TRANSACTIONS:
        this.showPauseTransactionMcb.setTrue();
        break;
      case EbSepaFileTransaction.ACTION_VIEW_BILLING_ITEMS:
        this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/s/transaction-billing-items/${this.actionEbSepaFileTransactionId}`);
        break;
      case EbSepaFileTransaction.ACTION_VIEW_PAYOUT_ITEMS:
        this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/s/transaction-payout-items/${this.actionEbSepaFileTransactionId}`);
        break;
      case EbSepaFileTransaction.ACTION_VIEW_CONSUMER_PROFILE:
        this.showConsumerProfileMcb.setTrue();
        break;
      case EbSepaFileTransaction.ACTION_SETTLE_WITH_BALANCE:
        const consumerId = itemAction.item.idCsrConsumer;
        // this.getConsumerBalanceFromService(consumerId);
        break;
    }
  }

  // getConsumerBalanceFromService(consumerId: number) {
  //   this.mcConsumerBalanceService.getConsumerBalance(consumerId).subscribe((res) => {
  //     if (Object.keys(res).length === 0) {
  //       this.balanceAmount = 0;
  //     } else {
  //       // @ts-ignore
  //       this.balanceAmount = res[0].amount;
  //     }
  //     this.actSettleWithBalance(this.actionEbSepaFileTransactionId);
  //   });
  // }

  // ---------------------------------------------------------------------

  actSettleWithBalance(ebSepaFileTransactionId: number) {
    const body: any = {};
    body.paymentMethod = 'CREDIT';
    body.amount = null;

    if (this.balanceAmount === 0 || !this.balanceAmount) {
      this.toastr.error(McGod.t('cc.consumer.overview.this-action-cannot-be-completed-the-balance-on-the-consumers-account-is-0') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
    } else {
      this.ebSepaFileTransaction.apiService.payTransaction(ebSepaFileTransactionId, body)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.toastr.success(McGod.t('cc.consumer.transaction-has-been-successfully-settled-with-balance') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
          this.actLoad();
        }, error => {
          this.toastr.error(McGod.t('cc.consumer.transaction-has-not-been-successfully-settled-with-balance-try-again-later') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
        });
    }
  }

  actOnTransactionProfileClose() {
    this.actLoad();
    this.showTransactionProfileMcb.setFalse();
  }

  // ---------------------------------------------------------------------
  actOnTransactionAddPaymentClose() {
    this.actLoad();
    this.showTransactionAddPaymentMcb.setFalse();
    this.notifyMessage.successMessage = `The payment for transaction was recorded successfully`;
    this.selectedTransactionList = new EbSepaFileTransactionList();
  }

  // ----------------------------------------------------------------------

  multipleRecordPaymentShowMcb() {
    this.showAddPaymentForMultipleTransactions.setTrue();
    this.selectedTransactionList = this.ebSepaFileTransactionList.getSelectedObjectList();
  }

  actCancelAddEditMultiplePayments() {
    this.showAddPaymentForMultipleTransactions.setFalse();
    this.selectedTransactionList = new EbSepaFileTransactionList();
  }

  actPaymentMultipleTransactionsSaved() {
    this.notifyMessage.successMessage = `The payment for ${this.selectedTransactionList.getCount()} transaction(s) was recorded successfully`;
    this.notifyMessage.errorMessage = "";
    this.numberOfSelectedItems = 0;
    this.actLoad();
    this.showAddPaymentForMultipleTransactions.setFalse();
    this.selectedTransactionList = new EbSepaFileTransactionList();
  }

  paymentApiErrorEvent(errorMsg: string) {
    this.notifyMessage.errorMessage = errorMsg;
    this.notifyMessage.successMessage = "";
  }

  slideActionItemClicked(actionItem: McSlideActionItem) {
    if (actionItem.actionCdForEvent === EbSepaFileTransaction.ACTION_RECORD_PAYMENT) {
      this.multipleRecordPaymentShowMcb();
    }
    if (actionItem.actionCdForEvent === EbSepaFileTransaction.ACTION_CHANGE_COLLECTION_DATE) {
      if (this.isTransactionAcc && this.areSelectedTransactions) {
        this.showChangeCollectionDateMcb.setTrue();
        this.numberOfSelectedItems = this.selectedTransactionList.getCount();
        this.notifyMessage.errorMessage = "";
      } else {
        this.notifyMessage.errorMessage = McGod.t("cc.transactions.you-can-change-collection-date-only-for-transactions-from-service-contract-and-with-status-accepted");
      }
    }
  }

  actSelectedItems(selectedList: EbSepaFileTransactionList) {
    if (selectedList) {
      this.numberOfSelectedItems = selectedList.getCount();
      this.selectedTransactionList = selectedList;
      this.selectedTransactionList.items.forEach(item => {
        this.isTransactionAcc = item.statusCd === ("ACCEPTED");
      });
      this.nonFactoringTransactions = this.selectedTransactionList.items.filter(item => item.nonFactoringFlg);
      this.areSelectedTransactions = this.nonFactoringTransactions.length > 0;
    }
  }

  actChangeCollectionModal() {
    this.showChangeCollectionDateMcb.setFalse();
    this.numberOfSelectedItems = 0;
    this.actLoad();
    this.selectedTransactionList = new EbSepaFileTransactionList();
  }

  actCancelChangeCollectionModal() {
    this.selectedTransactionList = new EbSepaFileTransactionList();
    this.showChangeCollectionDateMcb.setFalse();
  }

  onTransactionPaused() {
    this.notifyMessage.successMessage = "The transaction was paused successfully";
    this.actLoad();
    this.showPauseTransactionMcb.setFalse();
  }

  onPauseTransactionCanceled() {
    this.showPauseTransactionMcb.setFalse();
    this.actLoad();
  }

  onTransactionUnpaused() {
    this.notifyMessage.successMessage = "The transaction was unpaused successfully";
    this.actLoad();
    this.showPauseTransactionMcb.setFalse();
  }

  onSelectedTypeCds(typesVll: any[]) {
    this.typeCds = typesVll;
  }

  onSelectedStatus(statusVll: any[]) {
    this.statusCds = statusVll;
  }

  onPaymentDueDateFrom(value: any) {
    if (value !== null) {
      this.paymentDueDateFrom = value;
    } else {
      this.paymentDueDateFrom = null;
    }
  }

  onPaymentDueDateTo(value: any) {
    if (value !== null) {
      this.paymentDueDateTo = value;
    } else {
      this.paymentDueDateTo = null;
    }
  }

  actCancel() {
    this.showConsumerProfileMcb.setFalse();
  }

  format(value: number): string {
    if (value) {
      return CurrencyUtil.format(value, this.currencyCode);
    }
    return CurrencyUtil.format(0, this.currencyCode);
  }
}
