/*BbmTestObj*/
import {CsrContractProductsGENERATED} from '../_generated/csr-contract-products-generated.model';

export class CsrContractProducts extends CsrContractProductsGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrContractProducts();
  }

  // ---------------------------------------------------------------------
}
