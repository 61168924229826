import {McProcess} from './mc-process.model';
import {McProcessListGENERATED} from '../_generated/mc-process-list-generated.model';

export class McProcessList extends McProcessListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessList();
  }

  // ---------------------------------------------------------------------
}
