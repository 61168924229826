/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McPriceListFilter} from '../models/mc-price-list-filter.model';

export class McPriceListFilterGENERATED extends BaseObjectFilter{    public isTemplate: boolean | undefined;
    public searchTerm: string | undefined;
    public idEntityOwner: number | undefined;

  public properties: string[] = ['isTemplate', 'searchTerm', 'idEntityOwner', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McPriceListFilter {
    console.error('MTCN-ERROR: Not Implemented: McPriceListFilter::createNewInstance(). Add this method to final class and return new McPriceListFilter();');
      throw new Error('Cannot McPriceListFilter::createNewInstance(). Add this method to final class and return new McPriceListFilter();');
  }

}
