import {Injectable} from '@angular/core';
import {EbSepaTransactionServiceGENERATED} from '../_generated/eb-sepa-transaction-generated.service';
import {Observable} from 'rxjs';
import {PmTransaction} from '../models/pm-transaction.model';
import {HttpHeaders} from '@angular/common/http';
import {IPmTransaction} from '../_generated/pm-transaction.interface';


@Injectable({
  providedIn: 'root'
})
export class EbSepaTransactionService extends EbSepaTransactionServiceGENERATED {

  storno(transactionIds: any[], stornoReason: string, stornoDescription: string): Observable<any> {
    const requestOptions: {} = {
      responseType: 'text'
    };
    const body = {
      ids: transactionIds,
      stornoReason,
      stornoDescription
    };

    return this.httpClient.put<any>(this.getApiServiceRootUrl() + '/cancel', body, requestOptions
    );
  }

  pay(ids: number[], paymentMethod: string, amount: any, description: string, entityId: number) {
    const requestOptions: {} = {
      responseType: 'text'
    };
    const body: any = {};
    body.ids = ids;
    body.paymentMethod = paymentMethod;
    body.amount = amount;
    body.description = description;
    body.entityId = entityId;

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/pay`, body, requestOptions
    );
  }

  public sentToInkasso(transactionIds: any[]): Observable<any> {
    const requestOptions: {} = {
      responseType: 'text'
    };
    const body: any = {};
    body.transactionIds = transactionIds;

    return this.httpClient.put<any>(this.getApiServiceRootUrl() + `/markassenttoinkasso/${transactionIds}`, transactionIds, requestOptions);
  }

  returnFromInkasso(transactionIds: number[]): Observable<any> {
    const requestOptions: {} = {
      responseType: 'text'
    };

    return this.httpClient.put<any>(this.getApiServiceRootUrl() + `/returned-from-inkasso`, transactionIds, requestOptions)
  }

  public createManualTransaction(pmTransaction: PmTransaction): Observable<IPmTransaction> {
    return this.httpClient.post<IPmTransaction>(this.getApiServiceRootUrl() + '/manual', pmTransaction.toDto(), {
      headers: new HttpHeaders(
        {
          contentType: 'application/json'
        }
      )
    });
  }

  public changeCollectionType(transactionId: number, newCollectionType: string): Observable<IPmTransaction> {
    const body = {
      id: transactionId,
      collectionTypeCd: newCollectionType
    }

    return this.httpClient.put<IPmTransaction>(this.getApiServiceRootUrl() + '/collection-type', body, {
      headers: new HttpHeaders(
        {contentType: 'application/json'}
      )
    });
  }
}
