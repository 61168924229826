/*BbmTestObj*/
import {EbSepaFileTable3ConfigGENERATED} from '@miticon-ui/mc-core';

// import {EbSepaFileGENERATED} from '../_generated/eb-sepa-file-generated.model';

export class EbSepaFileTable3Config extends EbSepaFileTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();
    /*Reporting component*/
    /*this.addColumn('id', 'reporting', 'id', 'reporting')
      .setCustomMethod((aItemObject, aComponentConfig: McReportingConfig) => {
   aComponentConfig.objectItem = aItemObject;
})
.getBuilder()
  .setEditComponent(McReportingComponent, 'description', reportingComponentConfig);*/
    this.addColumn('childEntityName', this.t('cc.factoring.transactions.entity-name'), 'childEntityName', 'entity.name');
    this.addColumn('sepaFilename', this.t('cc.factoring.imported-sepa.sepa-filename'), 'sepaFilename', 'wf.originalFilename');
    this.addColumn('createdAt', this.t('cc.factoring.imported-sepa.import-date-&-time'), 'getImportDateStr()', 'createdAt')
      .getBuilder();
    this.addColumn('requestCollectionDate', 'Request collection date', 'getRequestedOrBatchCollectionDates()', 'requestedCollectionDate');
    this.addColumn('allDebtors', this.t('cc.factoring.imported-sepa.all-debtors'), 'allDebtors', '');
    this.addColumn('accRejDebtors', this.t('cc.factoring.imported-sepa.accepted-rejected-debtors'), 'acceptedRejectedDebtors', '');
    this.addColumn('totalAmountDue', `${this.t('cc.factoring.imported-sepa.accepted-total-amount')}`, 'fldTotalAmountDue()', '').extraCssClass = 'text-right';
// this.addColumn('remainingAmount', 'Remaining Amount', 'fldRemainingAmount()', 'remainingAmount');
    this.addColumn('statusCd', this.t('cc.common.view.status'), 'statusCd', 'statusCd');
  }
}
