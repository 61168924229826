<div class="mcc-modal modal show d-block modal-small" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content height-auto">
      <button type="button" class="close" (click)="actCancel()">
        <span>&times;</span>
      </button>
      <div class="content-wrapper">

        <div class="content-header">
          <h3 [innerHTML]="header"></h3>
        </div>

        <div class="content-body">
          <div class="d-flex align-items-center">
            <i class="{{iconClass}}"></i> <span class="pl-2" [innerHTML]="description"></span>
          </div>
          <ng-content></ng-content>
        </div>

        <div class="content-footer d-flex">
          <button class="btn btn-secondary ml-auto" [innerHTML]="cancelButtonLabel" (click)="actCancel()"></button>
          <button class="btn btn-primary" [innerHTML]="saveButtonLabel" (click)="actSave()"></button>
        </div>

      </div>
    </div>
  </div>
</div>
