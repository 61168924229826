<<div *ngIf="mcProcessFlowLevel.apiErrorFlg">
  ERROR
  {{mcProcessFlowLevel.apiErrorMessage}}
</div>
<div *ngIf="mcProcessFlowLevel.apiLoadingFlg">
  <img src="/assets/images/loading-whitebg.gif" />
</div>
<div *ngIf="mcProcessFlowLevel.apiSuccessFlg">

    <div>
    
<div><span style="width: 100px; text-align:right;">ID:: </span><span>{{mcProcessFlowLevel.id}} </span></div>
<div><span style="width: 100px; text-align:right;">McProcessFlow ID: </span><span>{{mcProcessFlowLevel.idMcProcessFlow}} </span></div>
<div><span style="width: 100px; text-align:right;">Level title: </span><span>{{mcProcessFlowLevel.title}} </span></div>
<div><span style="width: 100px; text-align:right;">Description: </span><span>{{mcProcessFlowLevel.description}} </span></div>
<div><span style="width: 100px; text-align:right;">Email action flag: </span><span>{{mcProcessFlowLevel.emailActionFlg}} </span></div>
<div><span style="width: 100px; text-align:right;">Email payment QR code flag: </span><span>{{mcProcessFlowLevel.emailPaymentQrCodeFlg}} </span></div>
<div><span style="width: 100px; text-align:right;">Email subject tokened: </span><span>{{mcProcessFlowLevel.emailSubjectTokened}} </span></div>
<div><span style="width: 100px; text-align:right;">Email body tokened: </span><span>{{mcProcessFlowLevel.emailBodyTokened}} </span></div>
<div><span style="width: 100px; text-align:right;">Post action flag: </span><span>{{mcProcessFlowLevel.postActionFlg}} </span></div>
<div><span style="width: 100px; text-align:right;">Post payment QR code flag: </span><span>{{mcProcessFlowLevel.postPaymentQrCodeFlg}} </span></div>
<div><span style="width: 100px; text-align:right;">SMS action flag: </span><span>{{mcProcessFlowLevel.smsActionFlg}} </span></div>
<div><span style="width: 100px; text-align:right;">SMS body tokened: </span><span>{{mcProcessFlowLevel.smsBodyTokened}} </span></div>
<div><span style="width: 100px; text-align:right;">Number of days payment deadline after reminder issue date: </span><span>{{mcProcessFlowLevel.noDaysPaymentDeadlineAfterReminderIssue}} </span></div>
<div><span style="width: 100px; text-align:right;">Number of days waiting period after payment deadline: </span><span>{{mcProcessFlowLevel.noDaysWaitingPeriodAfterPaymentDeadline}} </span></div>
      <hr />
    </div>


</div>

<router-outlet></router-outlet>
