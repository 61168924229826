/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {PmAdjustmentNotificationInfoTable3ConfigGENERATED} from '../_generated/pm-adjustment-notification-info-table-3-config-generated.model';

// import {PmAdjustmentNotificationInfoGENERATED} from '../_generated/pm-adjustment-notification-info-generated.model';

export class PmAdjustmentNotificationInfoTable3Config extends PmAdjustmentNotificationInfoTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
      this.addColumn('colActions', '', 'fldActions()', '');

      this.addColumn('colIdCsrConsumer2CtdEmail', 'Consumer ID', 'consumerId', 'consumerId');
      this.addColumn('colCsrConsumer2CtdEmail', 'Consumer', 'consumerName', 'consumerName');
      this.addColumn('colCtdEmail', 'Email', 'consumerEmail', 'consumerEmail');
      this.addColumn('colDateSent', 'Date sent', 'getDateSent()', 'dateSent');
      this.addColumn('colType', 'Type', 'getNotificationEventType()', 'notificationEventType');
      this.addColumn('colStatusDetails', 'Status', 'getEmailStatus()', 'emailStatus');
  }
}
