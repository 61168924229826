import {EbConsumerBlacklistFilterGENERATED} from '../_generated/eb-consumer-blacklist-filter-generated.model';

export class EbConsumerBlacklistFilter extends EbConsumerBlacklistFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbConsumerBlacklistFilter();
  }

  // ---------------------------------------------------------------------
}
