<span class="back-option" (click)="actGoToProcesses()">
  <span [innerHTML]="iconBack | safeHtml"></span>
  {{ "cc.process.back-to-processes" | translate }}
</span>

<div class="content-wrapper wrapper-padding">
  <div class="content-body">
    <mcc-menu [mcMenu]="mcMenu"></mcc-menu>
    <div class="row">
      <lib-mc-loader [showLoader]="mcProcessRunList.apiLoadingFlg"></lib-mc-loader>
      <!--<div class="col-md-12">
        <div class="mc-filter">
          <mcc-fi-textbox label="{{'cc.common.search' | translate}}" name="txbSearchTerm"
                          (eventEnterClicked)="actLoad()"
                          [mcForm]="mcForm"></mcc-fi-textbox>
          &lt;!&ndash;<mcc-fi-select [mcForm]="mcForm" name="selFlowLevel" label="Flow level" [optionsVll]="mcFlowLevelVll"
                         placeholderOption="{{'cc.my-factoring.all' | translate}}"></mcc-fi-select>
          <mcc-fi-select [mcForm]="mcForm" name="selStatus" label="Status" [optionsVll]="mcStatusVll"
                         placeholderOption="{{'cc.my-factoring.all' | translate}}"></mcc-fi-select>&ndash;&gt;

          <button class="mc-link mc-filter-clear-button filter-clear-top-right"
                  (click)="actClearFilter()">{{'cc.common.filter.clear-all' | translate}}</button>
          <button class="mc-button mc-filter-button"
                  (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>
        </div>
      </div>-->
    </div>

    <mcc-message [successMsg]="successMsg" [errorMsg]="errorMsg"></mcc-message>

    <div class="row">
      <!--<button (click)="actLoad()">Refresh</button>-->
      <div class="col-md-12 text-right table-top-btn">
        <div class="mc-width-fit-content ml-auto" [ngbTooltip]="(disableCreateRunFlg || mcProcess.isStatusCdINACTIVE()) ? ('cc.processes.process-the-process-is-active-create-new-run-option-is-disabled' | translate) : ''" placement="bottom"
             tooltipClass="mc-tooltip-top-margin-40">
          <button *ngIf="mcGod.userHasMcProcessRunAddPermission()" class="system-action-btn" [ngClass]="{'system-action-btn-disabled' : mcProcess.isStatusCdINACTIVE() || disableCreateRunFlg}" [disabled]="disableCreateRunFlg || mcProcess.isStatusCdINACTIVE()" (click)="actCreateProcessRun()"><i class="fas fa-plus-circle"></i>
            {{'cc.processes.process-runs.create-new-run' | translate}}
          </button>

        </div>
      </div>

      <div class="col-md-12" *ngIf="mcProcessRunList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="mcProcessRunList"
                        [table3Config]="mcProcessRunTable3Config"
                        (eventPaginationOrSortChanged)="actLoad()"
                        (eventItemAction)="actProcessRunTableItemAction($event)" [hideRefreshButton]="false" (eventRefresh)="onTableRefresh()"></mcc-fi-table-3>
      </div>
    </div>

    <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showAddEditMcProcessRunMCb.value"
                        (eventCanceled)="actRunCancelEmit()"
                        [showMcb]="showAddEditMcProcessRunMCb">
      <mc-process-process-run-add-edit [mcProcessId]="mcProcessId" [mcProcessRunId]="mcProcessRunId"
                                       (eventSaved)="actRunSaved()"
                                       (eventCanceled)="actRunCancelEmit()"></mc-process-process-run-add-edit>
    </mcc-modal1-wrapper>

    <mcc-modal1-wrapper *ngIf="showRunItemsMcb.value" [showMcb]="showRunItemsMcb"
                        (eventCanceled)="actCloseRunItemsProfile()">
      <mc-process-process-run-items-profile [mcProcessRun]="mcHtmlButton.item"
                                            [actionCdForEvent]="mcHtmlButton.actionCdForEvent"
                                            (eventCancel)="actCloseRunItemsProfile()"></mc-process-process-run-items-profile>
    </mcc-modal1-wrapper>

    <router-outlet></router-outlet>
  </div>


</div>
