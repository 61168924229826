
import {Table3Config} from '../extra/table-3-config';

// import {CsrBankAccountGENERATED} from '../_generated/csr-bank-account-generated.model';

export class CsrBankAccountTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colArchived', 'Archived', 'archived', 'archived');
    this.addColumn('colBankName', 'Bank name', 'bankName', 'bankName');
    this.addColumn('colBic', 'BIC', 'bic', 'bic');
    this.addColumn('colCsrConsumer', 'Consumer', 'csrConsumer', 'csrConsumer');
    this.addColumn('colFlgConsumer360', 'Flag consumer360', 'flgConsumer360', 'flgConsumer360');
    this.addColumn('colFlgPrimary', 'Primary flag', 'flgPrimary', 'flgPrimary');
    this.addColumn('colFlgVerify', 'Verify', 'flgVerify', 'flgVerify');
    this.addColumn('colIban', 'IBAN', 'iban', 'iban');
    this.addColumn('colIdCsrConsumer', 'Consumer ID', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colOwner', 'Account owner', 'owner', 'owner');
    this.addColumn('colSepaMandateDate', 'Sepa mandate date of signature', 'sepaMandateDate', 'sepaMandateDate');
    this.addColumn('colSepaMandateId', 'Sepa mandate id', 'sepaMandateId', 'sepaMandateId');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');
    this.addColumn('colVersion', 'Version', 'version', 'version');

*/
  }
}
