import {Table2ColumnConfig} from './table-2-column-config';

export class Table2Config {
  private _builder: any = null;
  columnObjectList: Table2ColumnConfig[] = [];
  allowRowSelectionWithCheckboxesFlg = true;
  allowDragDropRowsFlg = false;

  // ---------------------------------------------------------------------
  constructor() {

  }

  // ---------------------------------------------------------------------
  public addColumn(aColumnUniqueName: string, aColumnLabel: string, aObjectPropertyOrMethodName: string, aSortFlag: boolean) {
    const column = new Table2ColumnConfig(aColumnUniqueName, aColumnLabel, aObjectPropertyOrMethodName, aSortFlag);
    this.columnObjectList.push(column);
    return column;
  }

  // ---------------------------------------------------------------------
  public getBuilder(): BuilderTable2Config {
    if (this._builder == null) {
      this._builder = new BuilderTable2Config(this);
    }
    return this._builder;
  }
}

// ---------------------------------------------------------------------
class BuilderTable2Config {
  tableConfig: Table2Config;

  constructor(aTableConfig: Table2Config) {
    this.tableConfig = aTableConfig;
  }


/*  // ---------------------------------------------------------------------
  public allowRowSelectionWithCheckboxesFlg(aEditComponentClass: any, aEditObjectPropertyOrMethodName: string): BuilderTable2Config {
    this.columnConfig.addEditComponent(aEditComponentClass, aEditObjectPropertyOrMethodName);
    return this;
  }*/

  // ---------------------------------------------------------------------

}

