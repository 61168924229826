/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McProcessRunItemFilter} from '../models/mc-process-run-item-filter.model';

export class McProcessRunItemFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProcessRunItemFilter {
    console.error('MTCN-ERROR: Not Implemented: McProcessRunItemFilter::createNewInstance(). Add this method to final class and return new McProcessRunItemFilter();');
      throw new Error('Cannot McProcessRunItemFilter::createNewInstance(). Add this method to final class and return new McProcessRunItemFilter();');
  }

}
