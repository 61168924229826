/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McBillingPayoutFile2StatementFilter} from '../models/mc-billing-payout-file-2-statement-filter.model';

export class McBillingPayoutFile2StatementFilterGENERATED extends BaseObjectFilter{    public payoutDateFrom: any;
    public payoutDateTo: any;
    public createdDateFrom: any;
    public createdDateTo: any;
    public createdBy: number[] = [];

  public properties: string[] = ['payoutDateFrom', 'payoutDateTo', 'createdDateFrom', 'createdDateTo', 'createdBy', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingPayoutFile2StatementFilter {
    console.error('MTCN-ERROR: Not Implemented: McBillingPayoutFile2StatementFilter::createNewInstance(). Add this method to final class and return new McBillingPayoutFile2StatementFilter();');
      throw new Error('Cannot McBillingPayoutFile2StatementFilter::createNewInstance(). Add this method to final class and return new McBillingPayoutFile2StatementFilter();');
  }

}
