import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {McAuthService, McProfileUserService} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-personal-info',
  templateUrl: './mc-personal-info.component.html',
})
export class McPersonalInfoComponent implements OnInit {

  imgUrl: any = 'https://image.shutterstock.com/image-vector/empty-photo-male-profile-gray-260nw-538707310.jpg';
  message!: string;
  type!: string;
  childObject!: any;

  @Output() refreshUserDataEvent = new EventEmitter<boolean>();

  constructor(
    private authService: McAuthService,
    private profileUserService: McProfileUserService
  ) { }

  ngOnInit() {
    this.getUserInfo();
  }

  // getUserDataById
  getUserInfo() {
    const userId = this.authService.getLoggedUserInfo().user_id;
    this.profileUserService.getSystemUserData(userId).subscribe(
      (response) => {
        this.childObject = response;
        this.imgUrl = response.image;
        if (this.imgUrl === undefined) {
          this.imgUrl = 'https://image.shutterstock.com/image-vector/empty-photo-male-profile-gray-260nw-538707310.jpg';;
        }
      },
      (error) => {
        if (error.status >= 500) {
          this.message = 'Server is not available at the moment, please try again later.';
          this.type = 'error';
          return;
        }
        this.message = error.error ? error.error.message : 'There is no personal info defined';
        this.type = 'error';
      }
    );
  }

  onSuccessUserDataEvent(event: boolean) {
    if (event === true) {
      this.getUserInfo();
    }
  }
}
