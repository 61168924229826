<div class="content-wrapper">


  <div class="content-header">
    <h1>Edit Template Name And Description</h1>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-body">

    <div class="row">
      <div class="col-md-12">
        <div class="form-inner-content mt-3 pl-1">
          <mcc-fi-textbox name="ctrlTemplateName" label="{{'cc.pricelist.template-name' | translate}}"
                          [value]="mcPriceList.title" [validRequiredFlg]="true"
                          [mcForm]="mcForm">
          </mcc-fi-textbox>

          <mcc-fi-textarea name="ctrlTemplateDescription" label="{{'cc.pricelist.template-description' | translate}}" numCols="45"
                           [value]="mcPriceList.description" [validRequiredFlg]="true"
                           [mcForm]="mcForm">
          </mcc-fi-textarea>
        </div>

      </div>

    </div><!-- row -->

  </div><!-- content-body -->

  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary move-right" (eventClicked)="actCancel();"
                label="Cancel"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actSave()" label="Save"
                disableOnFirstClickFlg="true"></mcc-button>

  </div>

</div>
