import {Injectable} from '@angular/core';
import {McBillingStatementItemServiceGENERATED} from '../_generated/mc-billing-statement-item-generated.service';

import {McCurrencyService} from './mc-currency.service';


@Injectable({
  providedIn: 'root'
})
export class McBillingStatementItemService extends McBillingStatementItemServiceGENERATED {

  constructor(private currencyService: McCurrencyService) {
    super();
  }
}
