/*BbmTestObj*/
import {EbTransactionAdjustmentGENERATED} from '../_generated/eb-transaction-adjustment-generated.model';
import {McDateUtils} from '../common/mc-date-utils';
import {McHtmlActionSet} from '../_core/mc-html-action-set';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {ActionOptions} from '../_core/mc-html-action-options';


export class EbTransactionAdjustment extends EbTransactionAdjustmentGENERATED {

  public static ACTION_DELETE = 'X';
  public static ACTION_EDIT = 'EDIT';

  private _fldActions: McHtmlActionSet | null = null;
  public amountStr = '';

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbTransactionAdjustment();
  }

  // ---------------------------------------------------------------------
  public static getAdjustmentTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    // list.add(EbTransactionAdjustmentGENERATED.TYPE_CD_ORIGINAL, 'Original Amount');
    // list.add(EbTransactionAdjustmentGENERATED.TYPE_CD_PAYMENT, 'Payment');
    list.add(EbTransactionAdjustmentGENERATED.TYPE_CD_BANK_FEE, McGod.t('cc.transaction.adjustment.bank-fee'));
    list.add(EbTransactionAdjustmentGENERATED.TYPE_CD_INTEREST, McGod.t('cc.transaction.adjustment.interest'));
    list.add(EbTransactionAdjustmentGENERATED.TYPE_CD_CREDIT, McGod.t('cc.transaction.adjustment.credit'));
    list.add(EbTransactionAdjustmentGENERATED.TYPE_CD_INKASSO_FEE, McGod.t('cc.transaction.adjustment.inkasso-fee'));

    return list;
  }

  public static override getPaymentMethodCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add(EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT_CARD, McGod.t('cc.transaction.adjustment.credit-card'));
    list.add(EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CASH, McGod.t('cc.transaction.adjustment.cash'));
    list.add(EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CHEQUE, McGod.t('cc.transaction.adjustment.cheque'));
    list.add(EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_TRANSFER, McGod.t('cc.transaction.adjustment.transfer'));
    list.add(EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_DIRECT_DEBIT, McGod.t('Direct debit'));
    // list.add(EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_NOT_APPLICABLE, McGod.t('n/a'));


    return list;
  }


  // ---------------------------------------------------------------------
  public fldActions() {
    if (!this._fldActions) {
      const actionSet = new McHtmlActionSet();
      if (!this.isTypeCdORIGINAL() && !this.isTypeCdBANK_FEE()) { // original amounts cannot be deleted or edited
        let editPermissionOut = McGod.PERM_EB_FACTORING_OUT_TRANSACTION_ADJUSTMENT_EDIT;
        let deletePermissionOut = McGod.PERM_EB_FACTORING_OUT_TRANSACTION_ADJUSTMENT_DELETE;

        let editPermissionMy = McGod.PERM_EB_FACTORING_OUT_TRANSACTION_ADJUSTMENT_EDIT;
        let deletePermissionMy = McGod.PERM_EB_FACTORING_OUT_TRANSACTION_ADJUSTMENT_DELETE;

        if (this.isTypeCdPAYMENT()) {
          editPermissionOut = McGod.PERM_EB_FACTORING_OUT_TRANSACTION_PAYMENT_EDIT;
          deletePermissionOut = McGod.PERM_EB_FACTORING_OUT_TRANSACTION_PAYMENT_DELETE;

          editPermissionMy = McGod.PERM_EB_FACTORING_MY_TRANSACTION_PAYMENT_EDIT;
          deletePermissionMy = McGod.PERM_EB_FACTORING_MY_TRANSACTION_PAYMENT_DELETE;
        }

        if (this.getGrayouted() || (this.isStatusCdCOMPLETED() && this.isPaymentMethodCdDIRECT_DEBIT())) {
          return '';
        }

        if (!this.isTypeCdSTORNO()) {
          // actionSet.addActionB('Edit', EbTransactionAdjustment.ACTION_EDIT, this, [editPermissionOut, editPermissionMy], '', ActionOptions.OPTION_EDIT);
        }
        actionSet.addActionB('Delete', EbTransactionAdjustment.ACTION_DELETE, this, [deletePermissionOut, deletePermissionMy], '', ActionOptions.OPTION_DELETE);
    }
      this._fldActions = actionSet;
    }
    return this._fldActions;
  }

  // ---------------------------------------------------------------------

  getGrayouted(): boolean {
    return this.isPaymentMethodCdDIRECT_DEBIT() && (this.isStatusCdRETURNED() || this.isStatusCdPENDING());
  }


  override getAmountStr(): string {
    if (this.isTypeCdSTORNO()) {
      return `<div class="${this.getGrayouted() ? 'disable-label' : ''}">-${this.amountStr}</div>`;
    }
    return `<div class="${this.getGrayouted() ? 'disable-label' : ''}">${this.amountStr}</div>`;
  }

  getCreatedDateTimeString(): string {
    if (this.createdDatetime) {
      return `<div class="${this.getGrayouted() ? 'disable-label' : ''}">${McDateUtils.newFormatDateTimeString(this.createdDatetime)}</div>`;
    }
    return '';
  }

  fldDescription() {
    return `<div class="${this.getGrayouted() ? 'disable-label' : ''}">${this.description}</div>`;
  }

  fldPaymentMethodCdLabel() {
  return `<div class="${this.getGrayouted() ? 'disable-label' : ''}">${this.getPaymentMethodCdLabel()}</div>`;
  }

  fldAmountLoc() {
    const subtractSign = this.isTypeCdPAYMENT() || this.isTypeCdCREDIT() ? '- ' : '';
    return `${subtractSign}${this.amountStr}`;
  }

  fldStatus() {
    if (this.getStatusCdLabel()) {
      if (this.isStatusCdPENDING() || this.isStatusCdRETURNED()) {
        return `<div class="d-flex"><div class="${this.getGrayouted() ? 'disable-label' : ''}">${this.getStatusCdLabel()}</div>
                 <div class="icon-tooltip ml-2">
                    <img src="assets/images/info-blue-tooltip.svg">
                    <span class="icon-tooltip-text">${this.t('cc.consumer.this-payment-does-not-affect-the-total-amount-of-the-debt')}.</span></div></div>`;
      }
      if (this.isStatusCdCOMPLETED()) {
        return `<div class="d-flex"><div>${this.getStatusCdLabel()}</div>
                 <div class="icon-tooltip ml-2">
                    <img src="assets/images/info-blue-tooltip.svg">
                    <span class="icon-tooltip-text">${this.t('cc.consumer.this-payment-does-affect-the-total-amount-of-the-debt')}.</span></div></div>`;
      }
      if (this.getStatusCdLabel() === '--BAD-CD--') {
        return 'n/a';
      }
      return `<div>${this.getStatusCdLabel()}</div>`;
    }

    return '';
  }

  fldBeneficiaryEntityName() {
    return `<div class="${this.getGrayouted() ? 'disable-label' : ''}">${this.beneficiaryEntityName}</div>`;
  }

  fldCreatedBy() {
    return `<div class="${this.getGrayouted() ? 'disable-label' : ''}">${this.createdBy}</div>`;
  }

  fldTypeCdLabel() {
    if (this.getTypeCdLabel()) {
      return `<div class="${this.getGrayouted() ? 'disable-label' : ''}">${this.getTypeCdLabel()}</div>`;
    }
    return '';
  }
}
