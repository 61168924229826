import {McBillingService2VatFilterGENERATED} from '../_generated/mc-billing-service-2-vat-filter-generated.model';

export class McBillingService2VatFilter extends McBillingService2VatFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingService2VatFilter();
  }

  // ---------------------------------------------------------------------
}
