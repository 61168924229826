/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McProcessFlowCriteriaAttributeTable3ConfigGENERATED} from '../_generated/mc-process-flow-criteria-attribute-table-3-config-generated.model';

// import {McProcessFlowCriteriaAttributeGENERATED} from '../_generated/mc-process-flow-criteria-attribute-generated.model';

export class McProcessFlowCriteriaAttributeTable3Config extends McProcessFlowCriteriaAttributeTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colAttributeName', 'Attribute name', 'attributeName', 'attributeName');
    this.addColumn('colAttributeTypeCd', 'Attribute type code', 'attributeTypeCd', 'attributeTypeCd');
    this.addColumn('colDatetimeOperatorTypeCd', 'Datetime operator type code', 'datetimeOperatorTypeCd', 'datetimeOperatorTypeCd');
    this.addColumn('colBooleanOperatorTypeCd', 'Boolean operator type code', 'booleanOperatorTypeCd', 'booleanOperatorTypeCd');
    this.addColumn('colNumberOperatorTypeCd', 'Number operator type code', 'numberOperatorTypeCd', 'numberOperatorTypeCd');
    this.addColumn('colStringOperatorTypeCd', 'String operator type code', 'stringOperatorTypeCd', 'stringOperatorTypeCd');
    this.addColumn('colDatetimeOptionTypeCd', 'Datetime option type code', 'datetimeOptionTypeCd', 'datetimeOptionTypeCd');
    this.addColumn('colNumberOptionTypeCd', 'Number option type code', 'numberOptionTypeCd', 'numberOptionTypeCd');
    this.addColumn('colStringOptionTypeCd', 'String option type code', 'stringOptionTypeCd', 'stringOptionTypeCd');


  }
}
