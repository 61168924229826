import {McBillingItem} from './mc-billing-item.model';
import {McBillingItemListGENERATED} from '../_generated/mc-billing-item-list-generated.model';

export class McBillingItemList extends McBillingItemListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingItemList();
  }

  // ---------------------------------------------------------------------
}
