<ng-template #content let-modal>
  <!--Modal header section-->
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!--Modal body section-->
  <div class="modal-body">
    <!--Two fa title-->
    <h4 class="modal-title" id="modal-basic-title">{{'cc.common.view.two-factor-authentication' | translate}}</h4>
    <!--Notification message-->
    <div class="mc-notify-wrapper">
      <mc-notify-message [message]="messageText" [type]="messageType"></mc-notify-message>
    </div>
    <!--Authentication form-->
    <form [formGroup]="authForm">
      <span class="mr-4 form-group">
        <input type="radio" id="enable" value="enable" name="authentication" formControlName="authentication">
        <label for="enable">{{ 'cc.common.edit.enable' | translate }}</label>
      </span>
      <span class="form-group">
        <input type="radio" id="disable" value="disable" name="authentication" formControlName="authentication">
        <label for="disable">{{ 'cc.common.edit.disable' | translate }}</label>
      </span>
    </form>
  </div>

  <!--Modal footer section-->
  <div class="modal-footer">
    <!--Save btn-->
    <button type="button" class="modal-save" (click)="setTwoFactorAuthentication()">{{'cc.common.view.save' | translate}}</button>
  </div>
</ng-template>
<!--Link for open two fa modal-->
<a class="mc-account-info-edit mc-cursor-pointer ml-1" (click)="openModal(content)"><i class="fas fa-pen mc-blue-text"></i></a>
