/*BbmTestObj*/
import {McUserGENERATED} from '../_generated/mc-user-generated.model';

export class McUser extends McUserGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McUser();
  }

  fldFullName() {
    return this.firstname + ' ' + this.lastname;
}
  // ---------------------------------------------------------------------
}
