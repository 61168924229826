
import {Table3Config} from '../extra/table-3-config';

// import {McProcessLevelGENERATED} from '../_generated/mc-process-level-generated.model';

export class McProcessLevelTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colIdMcProcessFlow', 'McProcessFlow ID', 'idMcProcessFlow', 'idMcProcessFlow');
    this.addColumn('colTitle', 'Level title', 'title', 'title');
    this.addColumn('colDescription', 'Description', 'description', 'description');

*/
  }
}
