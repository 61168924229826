/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McPriceListTable3ConfigGENERATED} from '../_generated/mc-price-list-table-3-config-generated.model';

// import {McPriceListGENERATED} from '../_generated/mc-price-list-generated.model';

export class McPriceListTable3Config extends McPriceListTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colTitle', this.t('cc.pricelist.pricelist-name'), 'title', 'title');
    this.addColumn('colDescription', this.t('cc.pricelist.pricelist-description'), 'description', 'description');
    this.addColumn('colTemplateOrigin', this.t('cc.pricelist.template-origin'), 'nameOfOriginTemplate', '');
    this.addColumn('colNoOfEnt', this.t('cc.pricelist.no-of-entities'), 'fldNumberOfEntities()', '');
    this.addColumn('colDefCustomPrices', this.t('cc.pricelist.default-custom-prices'), 'getDefaultCustomLbl()', '');
    this.addColumn('colValidFrom', this.t('cc.pricelist.valid-from'), 'getValidFromStr()', 'validFrom');
    this.addColumn('colCreatedDateTime', this.t('cc.common.created-date-and-time'), 'getSysCreatedDatetimeString()', '');
    this.addColumn('colCreatedBy',  this.t('cc.pricelist.created-by'), 'createdByUserFullName', '');


  }
}
