import {Inject, Injectable} from '@angular/core';
import {McBillingBatchAssignedToStatementServiceGENERATED} from '../_generated/mc-billing-batch-assigned-to-statement-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McBillingBatchAssignedToStatementService extends McBillingBatchAssignedToStatementServiceGENERATED {

}
