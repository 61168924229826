<lib-mc-loader [showLoader]="csrConsumer.apiLoadingFlg"></lib-mc-loader>

<section *ngIf="!csrConsumer.apiLoadingFlg">
  <div class="cardbox" *ngIf="hasCustomInfo() else noAdditionalInfo">
    <h2>{{'cc.consumer.custom-info' | translate}}</h2>
    <div *ngIf="csrConsumer.additionalAttributes" class='dialog-row-wrapper'>
      <ng-container *ngFor="let item of csrConsumer.additionalAttributes | keyvalue">
        <div *ngIf="item.key.indexOf('contract') === -1" class='dialog-col'>
          <span class='dialog-col-label'>{{ item.key }}:</span>
          <span class='dialog-col-value'>{{ item.value }}</span>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #noAdditionalInfo>
    <div class="cardbox">{{ 'cc.consumer-360.no-additional-info' | translate }}</div>
  </ng-template>
</section>

