import {EbConsumerBlacklistListGENERATED} from '../_generated/eb-consumer-blacklist-list-generated.model';

export class EbConsumerBlacklistList extends EbConsumerBlacklistListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbConsumerBlacklistList();
  }

  // ---------------------------------------------------------------------
}
