/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EbSepaTransaction} from '../models/eb-sepa-transaction.model';
import {EbSepaTransactionList} from '../models/eb-sepa-transaction-list.model';
import {EbSepaTransactionFilter} from '../models/eb-sepa-transaction-filter.model';
import {EbSepaTransactionService} from '../services/eb-sepa-transaction.service';
import {IEbSepaTransaction} from '../_generated/eb-sepa-transaction.interface';
import {McGod} from "../extra/mc-god.service";

export class EbSepaTransactionListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'pmTransaction'];
  public items: EbSepaTransaction[] = [];
  public apiService: EbSepaTransactionService;
 // protected tempObjList: EbSepaTransactionList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbSepaTransactionList {
    console.error('MTCN-ERROR: Not Implemented: EbSepaTransactionList::createNewInstance(). Add this method to final class and return new EbSepaTransactionList();');
    throw new Error('Cannot EbSepaTransactionList::createNewInstance(). Add this method to final class and return new EbSepaTransactionList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEbSepaTransaction[]): EbSepaTransactionList {
    const  listObj: EbSepaTransactionList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EbSepaTransaction = EbSepaTransaction.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EbSepaTransactionList {
    const  listObj: EbSepaTransactionList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbSepaTransactionService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEbSepaTransaction[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EbSepaTransaction = EbSepaTransaction.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEbSepaTransactionFilter: EbSepaTransactionFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEbSepaTransactionFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EbSepaTransaction {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EbSepaTransaction();
    }
  }

  public replaceItem(newItem: EbSepaTransaction) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

