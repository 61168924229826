/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McProcessFlow} from '../models/mc-process-flow.model';
import {McProcessFlowList} from '../models/mc-process-flow-list.model';
import {McProcessFlowFilter} from '../models/mc-process-flow-filter.model';
import {McProcessFlowService} from '../services/mc-process-flow.service';
import {IMcProcessFlow} from '../_generated/mc-process-flow.interface';
import {McGod} from "../extra/mc-god.service";

export class McProcessFlowListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'acceptedTransactionsEnteringFlowFlg', 'companyInterestRateTypeCd', 'description', 'dunningNoteBaseFeeAmount', 'emailFeeAmount', 'forDunningTransactionsEnteringFlowFlg', 'groupByConsumerFlg', 'idMcCurrency', 'idMcProcess', 'inheritableFlg', 'interestRateForCompanyAmount', 'interestRateForPersonAmount', 'newStatusForTransactionsAfterLastDunningLevel', 'personInterestRateTypeCd', 'postFeeAmount', 'returnedTransactionsEnteringFlowFlg', 'sendFlowToInkassoFlg', 'sendFlowToInkassoTransactionTypeCd', 'sendingToInkassoTransactionsEnteringFlowFlg', 'shouldGoToInkassoTransactionsEnteringFlowFlg', 'smsFeeAmount', 'statusCd', 'sysCreatedUserId', 'title', 'weekdayToSendNotification', 'flowCriteriaList', 'levels'];
  public items: McProcessFlow[] = [];
  public apiService: McProcessFlowService;
 // protected tempObjList: McProcessFlowList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProcessFlowList {
    console.error('MTCN-ERROR: Not Implemented: McProcessFlowList::createNewInstance(). Add this method to final class and return new McProcessFlowList();');
    throw new Error('Cannot McProcessFlowList::createNewInstance(). Add this method to final class and return new McProcessFlowList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcProcessFlow[]): McProcessFlowList {
    const  listObj: McProcessFlowList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McProcessFlow = McProcessFlow.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McProcessFlowList {
    const  listObj: McProcessFlowList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessFlowService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcProcessFlow[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McProcessFlow = McProcessFlow.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcProcessFlowFilter: McProcessFlowFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcProcessFlowFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McProcessFlow {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McProcessFlow();
    }
  }

  public replaceItem(newItem: McProcessFlow) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadByMcProcessId( idMcProcess: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByMcProcessId(idMcProcess, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

