
import {Table3Config} from '../extra/table-3-config';

// import {ImDataExportTemplateGENERATED} from '../_generated/im-data-export-template-generated.model';

export class ImDataExportTemplateTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colReportName', 'Report Name', 'reportName', 'reportName');
    this.addColumn('colSqlReport', 'SQL Report', 'sqlReport', 'sqlReport');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');

*/
  }
}
