/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CsrConsumerImportTable3ConfigGENERATED} from '../_generated/csr-consumer-import-table-3-config-generated.model';

// import {CsrConsumerImportGENERATED} from '../_generated/csr-consumer-import-generated.model';

export class CsrConsumerImportTable3Config extends CsrConsumerImportTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
      this.addColumn('colImportName', this.t('cc.common.import-name'), 'importName', 'importName');
      this.addColumn('colUserName', this.t('cc.common.imported-by'), 'importedBy', 'importedBy');
      this.addColumn('colImportMode', this.t('cc.consumers.consumers-overview.import-mode'), 'fldImportMode()', 'importModeCd');
      this.addColumn('colRecords', 'Processed, Added, Updated, Skipped Records', 'fldRecords()', 'processedRecords');
      this.addColumn('colStartDateAndTime', this.t('cc.factoring.imported-sepa.import-date-&-time'), 'fldCreatedAt()', 'createdAt');
    }
}
