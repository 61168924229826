import {CcMollieCustomerGENERATED} from '../_generated/cc-mollie-customer-generated.model';

export class CcMollieCustomer extends CcMollieCustomerGENERATED{
  override id!: number;
  override confirmDatetime!: any;
  override confirmationCode!: string;
  override expireDatetime: any;
  override idCsrConsumer!: number;
  override idCsrContract!: number;
  override idMollieCustomer!: string;
  statusCd!: string;
  override mollieRedirectUrl!: string;
}
