/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {ImDataExport} from '../models/im-data-export.model';
import {ImDataExportList} from '../models/im-data-export-list.model';
import {ImDataExportFilter} from '../models/im-data-export-filter.model';
import {ImDataExportService} from '../services/im-data-export.service';
import {IImDataExport} from '../_generated/im-data-export.interface';
import {McGod} from "../extra/mc-god.service";

export class ImDataExportListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'dateFrom', 'dateTo', 'fileType', 'idDataExportTemplate', 'idMcEntity', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'title'];
  public items: ImDataExport[] = [];
  public apiService: ImDataExportService;
 // protected tempObjList: ImDataExportList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): ImDataExportList {
    console.error('MTCN-ERROR: Not Implemented: ImDataExportList::createNewInstance(). Add this method to final class and return new ImDataExportList();');
    throw new Error('Cannot ImDataExportList::createNewInstance(). Add this method to final class and return new ImDataExportList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IImDataExport[]): ImDataExportList {
    const  listObj: ImDataExportList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: ImDataExport = ImDataExport.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): ImDataExportList {
    const  listObj: ImDataExportList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(ImDataExportService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IImDataExport[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: ImDataExport = ImDataExport.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aImDataExportFilter: ImDataExportFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aImDataExportFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): ImDataExport {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new ImDataExport();
    }
  }

  public replaceItem(newItem: ImDataExport) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

