/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {PmAdjustment} from '../models/pm-adjustment.model';
import {PmAdjustmentService} from '../services/pm-adjustment.service';
import {IPmAdjustment} from '../_generated/pm-adjustment.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {McEntity} from '../models/mc-entity.model';


export abstract class PmAdjustmentGENERATED extends BaseObject {


    public static readonly PAYMENT_METHOD_CD_CREDIT_CARD: string = 'CREDIT_CARD';
    public static readonly PAYMENT_METHOD_CD_CASH: string = 'CASH';
    public static readonly PAYMENT_METHOD_CD_CHEQUE: string = 'CHEQUE';
    public static readonly PAYMENT_METHOD_CD_TRANSFER: string = 'TRANSFER';
    public static readonly PAYMENT_METHOD_CD_DIRECT_DEBIT: string = 'DIRECT_DEBIT';
    public static readonly PAYMENT_METHOD_CD_NOT_APPLICABLE: string = 'NOT_APPLICABLE';
    public static readonly PAYMENT_METHOD_CD_CREDIT: string = 'CREDIT';
  public static readonly PAYMENT_METHOD_CD_LIST__ALL = [
    PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT_CARD,
    PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CASH,
    PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CHEQUE,
    PmAdjustmentGENERATED.PAYMENT_METHOD_CD_TRANSFER,
    PmAdjustmentGENERATED.PAYMENT_METHOD_CD_DIRECT_DEBIT,
    PmAdjustmentGENERATED.PAYMENT_METHOD_CD_NOT_APPLICABLE,
    PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT
];

    public static readonly RETURN_TYPE_CD_SOFT: string = 'SOFT';
    public static readonly RETURN_TYPE_CD_HARD: string = 'HARD';
  public static readonly RETURN_TYPE_CD_LIST__ALL = [
    PmAdjustmentGENERATED.RETURN_TYPE_CD_SOFT,
    PmAdjustmentGENERATED.RETURN_TYPE_CD_HARD
];

    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_PENDING: string = 'PENDING';
    public static readonly STATUS_CD_RETURNED: string = 'RETURNED';
    public static readonly STATUS_CD_COMPLETED: string = 'COMPLETED';
    public static readonly STATUS_CD_REMOVED: string = 'REMOVED';
  public static readonly STATUS_CD_LIST__ALL = [
    PmAdjustmentGENERATED.STATUS_CD_ACTIVE,
    PmAdjustmentGENERATED.STATUS_CD_PENDING,
    PmAdjustmentGENERATED.STATUS_CD_RETURNED,
    PmAdjustmentGENERATED.STATUS_CD_COMPLETED,
    PmAdjustmentGENERATED.STATUS_CD_REMOVED
];

    public static readonly TYPE_CD_ORIGINAL: string = 'ORIGINAL';
    public static readonly TYPE_CD_PAYMENT: string = 'PAYMENT';
    public static readonly TYPE_CD_BANK_FEE: string = 'BANK_FEE';
    public static readonly TYPE_CD_REMINDER_FEE: string = 'REMINDER_FEE';
    public static readonly TYPE_CD_INTEREST: string = 'INTEREST';
    public static readonly TYPE_CD_CREDIT: string = 'CREDIT';
    public static readonly TYPE_CD_INKASSO_FEE: string = 'INKASSO_FEE';
    public static readonly TYPE_CD_STORNO: string = 'STORNO';
    public static readonly TYPE_CD_DISCOUNT: string = 'DISCOUNT';
  public static readonly TYPE_CD_LIST__ALL = [
    PmAdjustmentGENERATED.TYPE_CD_ORIGINAL,
    PmAdjustmentGENERATED.TYPE_CD_PAYMENT,
    PmAdjustmentGENERATED.TYPE_CD_BANK_FEE,
    PmAdjustmentGENERATED.TYPE_CD_REMINDER_FEE,
    PmAdjustmentGENERATED.TYPE_CD_INTEREST,
    PmAdjustmentGENERATED.TYPE_CD_CREDIT,
    PmAdjustmentGENERATED.TYPE_CD_INKASSO_FEE,
    PmAdjustmentGENERATED.TYPE_CD_STORNO,
    PmAdjustmentGENERATED.TYPE_CD_DISCOUNT
];

  public apiService: PmAdjustmentService;
  public _rawJson: IPmAdjustment;
    id: number = 0;
  amount: number;
  description = '';
  idBeneficiaryEntity: number;
  idEbPaymentReport2Transaction: number;
  idPmTransaction: number;
  idRef: number;
  paymentMethodCd: string;
  refTypeCd = '';
  returnTypeCd: string;
  statusCd: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysDescription = '';
  sysUpdatedDatetime: number;
  typeCd: string;
  beneficiaryEntity: McEntity;  createdByName: string;

  public properties: string[] = ['id', 'amount', 'description', 'idBeneficiaryEntity', 'idEbPaymentReport2Transaction', 'idPmTransaction', 'idRef', 'paymentMethodCd', 'refTypeCd', 'returnTypeCd', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysDescription', 'sysUpdatedDatetime', 'typeCd', 'beneficiaryEntity', 'createdByName'];
  public propertiesRegular: string[] = ['id', 'amount', 'beneficiaryEntity', 'description', 'idBeneficiaryEntity', 'idEbPaymentReport2Transaction', 'idPmTransaction', 'idRef', 'paymentMethodCd', 'pmTransaction', 'refTypeCd', 'returnTypeCd', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysDescription', 'sysUpdatedDatetime', 'typeCd', 'createdByName'];
  public propertiesSpecial: string[] = ['beneficiaryEntity'];



  public static getPaymentMethodCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT_CARD, McGod.t('cc.transaction.adjustment.credit-card'));
    list.add(PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CASH, McGod.t('cc.transaction.adjustment.cash'));
    list.add(PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CHEQUE, McGod.t('cc.transaction.adjustment.cheque'));
    list.add(PmAdjustmentGENERATED.PAYMENT_METHOD_CD_TRANSFER, McGod.t('cc.transaction.adjustment.transfer'));
    list.add(PmAdjustmentGENERATED.PAYMENT_METHOD_CD_DIRECT_DEBIT, McGod.t('Direct debit'));
    list.add(PmAdjustmentGENERATED.PAYMENT_METHOD_CD_NOT_APPLICABLE, McGod.t('n/a'));
    list.add(PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT, McGod.t('Credit'));


    return list;
  }

  public static getPaymentMethodCdLabel(aCd: string): string {
    const list = PmAdjustmentGENERATED.getPaymentMethodCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getReturnTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PmAdjustmentGENERATED.RETURN_TYPE_CD_SOFT, McGod.t('Soft'));
    list.add(PmAdjustmentGENERATED.RETURN_TYPE_CD_HARD, McGod.t('Hard'));


    return list;
  }

  public static getReturnTypeCdLabel(aCd: string): string {
    const list = PmAdjustmentGENERATED.getReturnTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PmAdjustmentGENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(PmAdjustmentGENERATED.STATUS_CD_PENDING, McGod.t('Pending'));
    list.add(PmAdjustmentGENERATED.STATUS_CD_RETURNED, McGod.t('Returned'));
    list.add(PmAdjustmentGENERATED.STATUS_CD_COMPLETED, McGod.t('Completed'));
    list.add(PmAdjustmentGENERATED.STATUS_CD_REMOVED, McGod.t('Removed'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = PmAdjustmentGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PmAdjustmentGENERATED.TYPE_CD_ORIGINAL, McGod.t('Original Amount'));
    list.add(PmAdjustmentGENERATED.TYPE_CD_PAYMENT, McGod.t('Payment'));
    list.add(PmAdjustmentGENERATED.TYPE_CD_BANK_FEE, McGod.t('Bank Fee'));
    list.add(PmAdjustmentGENERATED.TYPE_CD_REMINDER_FEE, McGod.t('Reminder Fee'));
    list.add(PmAdjustmentGENERATED.TYPE_CD_INTEREST, McGod.t('Interest'));
    list.add(PmAdjustmentGENERATED.TYPE_CD_CREDIT, McGod.t('Credit'));
    list.add(PmAdjustmentGENERATED.TYPE_CD_INKASSO_FEE, McGod.t('Inkasso Fee'));
    list.add(PmAdjustmentGENERATED.TYPE_CD_STORNO, McGod.t('Storno'));
    list.add(PmAdjustmentGENERATED.TYPE_CD_DISCOUNT, McGod.t('Discount'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = PmAdjustmentGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): PmAdjustment {
    console.error('MTCN-ERROR: Not Implemented: PmAdjustment::createNewInstance(). Add this method to final class and return new PmAdjustment();');
    throw new Error('Cannot PmAdjustment::createNewInstance(). Add this method to final class and return new PmAdjustment();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IPmAdjustment): PmAdjustment {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(PmAdjustmentService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE PmAdjustment AABB()');
          if (this._rawJson['beneficiaryEntity']) {
        this.beneficiaryEntity = McEntity.createFromJson(this._rawJson['beneficiaryEntity']);
      }

  }

  // ---------------------------------------------------------------------

    public getAmountStr(): string {
       return McGod.formatNumberToMoney(this.amount);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('beneficiaryEntity')) {
        if (this.beneficiaryEntity != null) {
            dto['beneficiaryEntity'] = this.beneficiaryEntity.toDto();
        } else {
            dto['beneficiaryEntity'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --


  public getPaymentMethodCdLabel(): string {
    return PmAdjustmentGENERATED.getPaymentMethodCdLabel(this.paymentMethodCd);
  }


 public  isPaymentMethodCdCREDIT_CARD(): boolean {
    const result = (this.paymentMethodCd === PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT_CARD);

    return result;
  }

 public  isPaymentMethodCdCASH(): boolean {
    const result = (this.paymentMethodCd === PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CASH);

    return result;
  }

 public  isPaymentMethodCdCHEQUE(): boolean {
    const result = (this.paymentMethodCd === PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CHEQUE);

    return result;
  }

 public  isPaymentMethodCdTRANSFER(): boolean {
    const result = (this.paymentMethodCd === PmAdjustmentGENERATED.PAYMENT_METHOD_CD_TRANSFER);

    return result;
  }

 public  isPaymentMethodCdDIRECT_DEBIT(): boolean {
    const result = (this.paymentMethodCd === PmAdjustmentGENERATED.PAYMENT_METHOD_CD_DIRECT_DEBIT);

    return result;
  }

 public  isPaymentMethodCdNOT_APPLICABLE(): boolean {
    const result = (this.paymentMethodCd === PmAdjustmentGENERATED.PAYMENT_METHOD_CD_NOT_APPLICABLE);

    return result;
  }

 public  isPaymentMethodCdCREDIT(): boolean {
    const result = (this.paymentMethodCd === PmAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT);

    return result;
  }


  public getReturnTypeCdLabel(): string {
    return PmAdjustmentGENERATED.getReturnTypeCdLabel(this.returnTypeCd);
  }


 public  isReturnTypeCdSOFT(): boolean {
    const result = (this.returnTypeCd === PmAdjustmentGENERATED.RETURN_TYPE_CD_SOFT);

    return result;
  }

 public  isReturnTypeCdHARD(): boolean {
    const result = (this.returnTypeCd === PmAdjustmentGENERATED.RETURN_TYPE_CD_HARD);

    return result;
  }


  public getStatusCdLabel(): string {
    return PmAdjustmentGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === PmAdjustmentGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdPENDING(): boolean {
    const result = (this.statusCd === PmAdjustmentGENERATED.STATUS_CD_PENDING);

    return result;
  }

 public  isStatusCdRETURNED(): boolean {
    const result = (this.statusCd === PmAdjustmentGENERATED.STATUS_CD_RETURNED);

    return result;
  }

 public  isStatusCdCOMPLETED(): boolean {
    const result = (this.statusCd === PmAdjustmentGENERATED.STATUS_CD_COMPLETED);

    return result;
  }

 public  isStatusCdREMOVED(): boolean {
    const result = (this.statusCd === PmAdjustmentGENERATED.STATUS_CD_REMOVED);

    return result;
  }


  public getTypeCdLabel(): string {
    return PmAdjustmentGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdORIGINAL(): boolean {
    const result = (this.typeCd === PmAdjustmentGENERATED.TYPE_CD_ORIGINAL);

    return result;
  }

 public  isTypeCdPAYMENT(): boolean {
    const result = (this.typeCd === PmAdjustmentGENERATED.TYPE_CD_PAYMENT);

    return result;
  }

 public  isTypeCdBANK_FEE(): boolean {
    const result = (this.typeCd === PmAdjustmentGENERATED.TYPE_CD_BANK_FEE);

    return result;
  }

 public  isTypeCdREMINDER_FEE(): boolean {
    const result = (this.typeCd === PmAdjustmentGENERATED.TYPE_CD_REMINDER_FEE);

    return result;
  }

 public  isTypeCdINTEREST(): boolean {
    const result = (this.typeCd === PmAdjustmentGENERATED.TYPE_CD_INTEREST);

    return result;
  }

 public  isTypeCdCREDIT(): boolean {
    const result = (this.typeCd === PmAdjustmentGENERATED.TYPE_CD_CREDIT);

    return result;
  }

 public  isTypeCdINKASSO_FEE(): boolean {
    const result = (this.typeCd === PmAdjustmentGENERATED.TYPE_CD_INKASSO_FEE);

    return result;
  }

 public  isTypeCdSTORNO(): boolean {
    const result = (this.typeCd === PmAdjustmentGENERATED.TYPE_CD_STORNO);

    return result;
  }

 public  isTypeCdDISCOUNT(): boolean {
    const result = (this.typeCd === PmAdjustmentGENERATED.TYPE_CD_DISCOUNT);

    return result;
  }



}
