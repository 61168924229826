<!--      FILTERS         -->
<ng-container *ngIf="filterConfig && showFilters">
  <mat-expansion-panel class="mb-3" (opened)="panelOpenState = true"(closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div>
          <span [innerHTML]='iconFilter | safeHtml'></span>
          <span>{{'cc.common.view.filter' | translate}}s</span>
        </div>
        <span [innerHTML]='(panelOpenState ? dropDownIcon : dropUpIcon) | safeHtml'></span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mk-mat-filter">
      <div class="mk-mat-filter-container">
        <div class="filter-inputs">
          <!--  SEARCH  -->
          <form [formGroup]="searchForm">
            <mat-form-field appearance="outline">
              <mat-label>Search</mat-label>
              <input type="text" matInput formControlName="search"  [matTooltip]="searchTooltip" (keyup.enter)="onFilter()">
            </mat-form-field>
          </form>

          <mk-mat-filter *ngFor="let item of filterConfig.items"
                           [type]="item.type"
                           [name]="item.name"
                           [values]="item.values"
                           [clearFilters]="clearFilters"
                           (valueChangeSingle)="onFilterSingleSelection(item.name, $event)"
                           (valueChangeMulti)="onFilterMultiSelection(item.name, $event)"
                           (valueChangeDatepicker)="onFilterDatepickerValueChange(item.name, $event)">
          </mk-mat-filter>
        </div>
      </div>
      <div class="filter-actions">
        <button mat-flat-button class="mc-button clear-all" (click)="onClearFilter()">{{'cc.common.clear-all-filters' | translate}}</button>
        <button mat-flat-button class="mc-button filter-button" (click)="onFilter()">{{'cc.common.view.filter' | translate}}</button>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>

<!--      TABLE         -->
<span class="total" *ngIf="showTotalItems">{{ 'cc.common.total-displayed-elements' | translate }}: {{this.dataSource.data.length}}</span>

<mat-card class="table-wrapper-card mt-small">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

    <table *ngIf="config" mat-table
           [dataSource]="dataSource"
           [class.no_checkbox]="!showCheckbox"
    >
      <!-- Checkbox-->
      <ng-container *ngIf="showCheckbox" matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element; let row;">
          <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
                        [checked]="selection.isSelected(element)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Three dot Options-->
      <ng-container matColumnDef="action" *ngIf="mkMatMenuActionItems">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <mk-mat-table-actions *ngIf="showActions(element)" [matMenuItems]="mkMatMenuActionItems" [element]="element" (menuItemAction)="onMenuItemAction($event, element)"></mk-mat-table-actions>
        </td>
      </ng-container>

      <!-- Columns-->
      <ng-container *ngFor="let column of config.columns" matColumnDef="{{column.propertyName}}" #column>
        <th mat-header-cell *matHeaderCellDef mat-sort-header [matSortDisabled]="column.sortPropertyName ? false : true" matSort (matSortChange)="onSortChange($event, column.sortPropertyName)"> {{column.title}} </th>
        <ng-container *ngIf="column.type === 0">
          <td mat-cell *matCellDef="let element">
            <div class="{{getColumnClass(column)}}" (click)="onClick(column, element)">
              <i class="fas fa-link tr-icon"></i>
              {{getValue(column, element)}}
            </div>
          </td>
        </ng-container>
        <ng-container *ngIf="column.type === 1">
          <td mat-cell *matCellDef="let element">
            <div class="{{getColumnClass(column)}}">
              {{getValue(column, element)}}
            </div>
          </td>
        </ng-container>
        <ng-container *ngIf="column.type === 3">
          <td mat-cell *matCellDef="let element">
            <div [innerHtml]="getValue(column, element)"></div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.type === 4">
          <td class="column_action" mat-cell *matCellDef="let element" (click)="onClick(column, element)">
            <i style="cursor: pointer" [class]="column.iconClass"></i>
            {{getValue(column, element)}}
          </td>
        </ng-container>

        <ng-container *ngIf="column.type === 5">
          <td mat-cell *matCellDef="let element">
            <div [innerHTML]="getValue(column, element) | safeHtml"></div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.type === 6">
          <td class="select-td" mat-cell *matCellDef="let element">
            <mat-select panelClass="dialog-select" (selectionChange)="onSelectionChange($event, element)" [placeholder]="'mem.import.select-attribute' | translate" [value]="element.columnName">
              <mat-option *ngFor="let option of column?.options" [value]="option">
                {{option}}
              </mat-option>
            </mat-select>
          </td>
        </ng-container>

        <ng-container *ngIf="column.type === 7">
          <td mat-cell *matCellDef="let element" (click)="onClick(column, element)">
            <div [innerHTML]="getValue(column, element) | safeHtml"></div>
          </td>
        </ng-container>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="selection.isSelected(row) ? 'highlighted-row' : ''"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell no-data" colspan="100">No data matching the filter</td>
      </tr>

    </table>


  <!-- Pagination-->
  <mat-paginator *ngIf="showPaginator"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
                 [pageIndex]="pageNumber"
                 [length]="pageLength"
                 aria-label="Select page"
                 showFirstLastButtons
                 (page)="onPageChanged($event)">
  </mat-paginator>
</mat-card>


