<!-- mcc-fi-table-2 -->
<div class="mc-table-wrapper mt-3 mb-5 pt-1" *ngIf="objectList.apiLoadingFlg">
  <img src="assets/images/loading-whitebg.gif"/>
</div>
<div class="mc-table-wrapper mt-3 mb-5 pt-1 pb-5" *ngIf="objectList.apiSuccessFlg">

  <mcc-html *ngIf="objectList.items.length==0" [values]="noRecordsFoundMsgHtml" extraCssClasses="no-records-found"></mcc-html>

  <div *ngIf="objectList.items.length>0" class="w-100 py-1">

    <!--Total numbers wrapper-->
    <div class="mc-table-total-numbers-wrapper">
      <!--Table configuration button-->
      <div class="d-inline-block">
       <a class="simple-icon-link" *ngIf="!hideRefreshButton" (click)="onRefreshButtonClicked()" ngbTooltip="refresh"><i class="fas fa-redo"></i></a>
        <!--<mc-table-3-configuration-modal [tableConfig]="table3Config" (eventSaved)="onColumnConfigEventSaved()"></mc-table-3-configuration-modal>-->
      </div>
      <!--Total displayed number-->
      <div class="d-inline-block px-2 ml-1">
        <span class="text-capitalize">{{ 'cc.common.total-displayed-elements' | translate }}:</span>
        <span class="px-1" McDetectChanges [elementHtmlValue]="objectList.items.length">{{objectList.items.length}}</span>
      </div>
      <!--Additional page info-->
      <div class="d-inline-block px-2" *ngIf="additionalTableInfo">
        <span class="font-italic">{{ additionalTableInfo }}</span>
      </div>


    </div><!--Total numbers wrapper /end-->

    <!--Mc table-->
    <table class="table table-striped mc-table">

      <!--Mc table header-->
      <thead class="mc-table-head">
      <tr>

        <!--Checkbox for select all-->
        <th class="text-center" *ngIf="table3Config.allowRowSelectionWithCheckboxesFlg">

          <label *ngIf="!objectList.singleSelectionFlg" class="custom-control fill-checkbox">
            <input type="checkbox" id="selectRows" class="fill-control-input" (click)="objectList.toggleSelectAll(); onSelectItem($event, lastSelectedItemIndex)"
                   [checked]="false">
            <span class="fill-control-indicator"></span>
          </label>
        </th>

        <!--Drag&Drop-->
        <th *ngIf="table3Config.allowDragDropRowsFlg">
          <span></span>
        </th>

        <th *ngFor="let item of filteredItems()" class="mc-table-head-item">
          <!--header labels-->
          <div class="table-head-title">
            <span class="capitalize">{{item.label}}</span>
            <div class="mc-arrow-wrapper" *ngIf="item.hasSort()">
              <i class='fas fa-caret-up mc-arrow-up-icon' (click)="actSortColumn(item, 'ASC')"></i>
              <i class='fas fa-caret-down mc-arrow-down-icon' (click)="actSortColumn(item, 'DESC')"></i>
            </div>
          </div>
        </th>
      </tr>
      </thead><!--MC table head ./end-->

      <!--Mc table body-->
      <tbody class="mc-table-body">

      <tr *ngFor="let tableContentObjectItem of objectList.items; let contentIndex = index"
          [ngClass]="tableContentObjectItem.isSelected() ? 'mc-selected-row' : ''">

        <!--Select row item-->
        <td class="text-center mc-checkbox-td"
            *ngIf="table3Config.allowRowSelectionWithCheckboxesFlg">

          <label class="custom-control fill-checkbox">
            <input
              (click)="tableContentObjectItem.toggleSelected(); onSelectItem($event, contentIndex)"
              type="checkbox"
              [id]="tableContentObjectItem.id"
              class="fill-control-input"
              [checked]="tableContentObjectItem.isSelected()"
            >
            <span class="fill-control-indicator"></span>
          </label>
          <!--<div ngbDropdown class="d-inline-block ml-4">
            <span class="" id="dropdownBasic{{contentIndex}}" ngbDropdownToggle><i class="fas fa-ellipsis-v"></i></span>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem>View</button>
              <button ngbDropdownItem>Edit</button>
              <button ngbDropdownItem>Copy</button>
              <button ngbDropdownItem>Delete</button>
            </div>
          </div>-->
        </td>

        <!--Drag&Drop-->
        <!--     <td class="text-center mc-checkbox-td"
                 *ngIf="setup['isDragAndDrop']"
             >
               <div class="mc-drag-and-drop" cdkDragHandle>
                 <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                   <path
                     d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                   <path d="M0 0h24v24H0z" fill="none"></path>
                 </svg>
               </div>
             </td>
       -->
        <!--Table content-->
        <td *ngFor="let colConfig of filteredItems()" [ngClass]="colConfig.extraCssClass" (click)="onCellClick(colConfig, tableContentObjectItem)">

            <mcc-html *ngIf="!colConfig.editComponentClass" [values]="getCellValueFromObject(colConfig.objectPropertyOrMethodName, tableContentObjectItem)" (eventButtonClicked)="onMccHtmlButtonClicked($event)"></mcc-html>

          <!--Render component-->
          <span *ngIf="colConfig.editComponentClass">
          <mcc-dynamic-component-generator [dynamicComponentClass]="colConfig.editComponentClass"
                                           [dynamicComponentConfigObject]="getDynamicComponentConfigObject(colConfig, tableContentObjectItem)"></mcc-dynamic-component-generator>

          </span>
        </td>

      </tr><!--Table body content /end-->
      </tbody><!--MC table body ./end-->
    </table>

    <mcc-pagination *ngIf="isPagerVisible()" [pagination]="objectList.pagination" (paginationChange)="onPaginationChange($event)"></mcc-pagination>

  </div>
</div>
