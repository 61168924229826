/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBillingService2PriceList} from '../models/mc-billing-service-2-price-list.model';
import {McBillingService2PriceListService} from '../services/mc-billing-service-2-price-list.service';
import {IMcBillingService2PriceList} from '../_generated/mc-billing-service-2-price-list.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McBillingService2PriceListGENERATED extends BaseObject {


    public static readonly PRICING_TYPE_CD_DO_NOT_CHARGE_AT_ALL: string = 'DO_NOT_CHARGE_AT_ALL';
    public static readonly PRICING_TYPE_CD_ONE_TIME_ONLY: string = 'ONE_TIME_ONLY';
    public static readonly PRICING_TYPE_CD_MIX: string = 'MIX';
  public static readonly PRICING_TYPE_CD_LIST__ALL = [
    McBillingService2PriceListGENERATED.PRICING_TYPE_CD_DO_NOT_CHARGE_AT_ALL,
    McBillingService2PriceListGENERATED.PRICING_TYPE_CD_ONE_TIME_ONLY,
    McBillingService2PriceListGENERATED.PRICING_TYPE_CD_MIX
];

  public apiService: McBillingService2PriceListService;
  public _rawJson: IMcBillingService2PriceList;
    id: number = 0;
  activityUnitTypeCd = '';
  flgCustomPrice: boolean;
  flgDoNotChargeForFactoredTransactions: boolean;
  flgDoNotChargeForWebDomains: boolean;
  idBillingService: number;
  idPriceList: number;
  notChargeWebDomains = '';
  priceAdditionalUnit: number;
  priceFlat: number;
  priceFromContractBatchFee: number;
  priceFromContractTransactionWithdrawalFeeFactored: number;
  priceFromContractTransactionWithdrawalFeeNonFactored: number;
  priceFromContractTransactionWithdrawalFeeService: number;
  priceOneTime: number;
  pricingTypeCd: string;
  sysUpdatedDatetime: number;
  unitLimitForFlatPrice: number;
  flgItemFromTemplate: boolean;  flgPriceFromContract: boolean;  serviceDescription: string;  serviceName: string;  serviceTypeCd: string;  startDate: any;

  public properties: string[] = ['id', 'activityUnitTypeCd', 'flgCustomPrice', 'flgDoNotChargeForFactoredTransactions', 'flgDoNotChargeForWebDomains', 'idBillingService', 'idPriceList', 'notChargeWebDomains', 'priceAdditionalUnit', 'priceFlat', 'priceFromContractBatchFee', 'priceFromContractTransactionWithdrawalFeeFactored', 'priceFromContractTransactionWithdrawalFeeNonFactored', 'priceFromContractTransactionWithdrawalFeeService', 'priceOneTime', 'pricingTypeCd', 'sysUpdatedDatetime', 'unitLimitForFlatPrice', 'flgItemFromTemplate', 'flgPriceFromContract', 'serviceDescription', 'serviceName', 'serviceTypeCd', 'startDate'];
  public propertiesRegular: string[] = ['id', 'activityUnitTypeCd', 'flgCustomPrice', 'flgDoNotChargeForFactoredTransactions', 'flgDoNotChargeForWebDomains', 'idBillingService', 'idPriceList', 'mcBillingServiceCatalog', 'mcPriceList', 'notChargeWebDomains', 'priceAdditionalUnit', 'priceFlat', 'priceFromContractBatchFee', 'priceFromContractTransactionWithdrawalFeeFactored', 'priceFromContractTransactionWithdrawalFeeNonFactored', 'priceFromContractTransactionWithdrawalFeeService', 'priceOneTime', 'pricingTypeCd', 'sysUpdatedDatetime', 'unitLimitForFlatPrice', 'flgItemFromTemplate', 'flgPriceFromContract', 'serviceDescription', 'serviceName', 'serviceTypeCd', 'startDate'];
  public propertiesSpecial: string[] = [];



  public static getPricingTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingService2PriceListGENERATED.PRICING_TYPE_CD_DO_NOT_CHARGE_AT_ALL, McGod.t('Do not charge at all'));
    list.add(McBillingService2PriceListGENERATED.PRICING_TYPE_CD_ONE_TIME_ONLY, McGod.t('One time only'));
    list.add(McBillingService2PriceListGENERATED.PRICING_TYPE_CD_MIX, McGod.t('Mix pricing'));


    return list;
  }

  public static getPricingTypeCdLabel(aCd: string): string {
    const list = McBillingService2PriceListGENERATED.getPricingTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McBillingService2PriceList {
    console.error('MTCN-ERROR: Not Implemented: McBillingService2PriceList::createNewInstance(). Add this method to final class and return new McBillingService2PriceList();');
    throw new Error('Cannot McBillingService2PriceList::createNewInstance(). Add this method to final class and return new McBillingService2PriceList();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBillingService2PriceList): McBillingService2PriceList {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingService2PriceListService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBillingService2PriceList AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getPriceAdditionalUnitStr(): string {
       return McGod.formatNumberToMoney(this.priceAdditionalUnit);
    }

    public getPriceFlatStr(): string {
       return McGod.formatNumberToMoney(this.priceFlat);
    }

    public getPriceFromContractBatchFeeStr(): string {
       return McGod.formatNumberToMoney(this.priceFromContractBatchFee);
    }

    public getPriceFromContractTransactionWithdrawalFeeFactoredStr(): string {
       return McGod.formatNumberToMoney(this.priceFromContractTransactionWithdrawalFeeFactored);
    }

    public getPriceFromContractTransactionWithdrawalFeeNonFactoredStr(): string {
       return McGod.formatNumberToMoney(this.priceFromContractTransactionWithdrawalFeeNonFactored);
    }

    public getPriceFromContractTransactionWithdrawalFeeServiceStr(): string {
       return McGod.formatNumberToMoney(this.priceFromContractTransactionWithdrawalFeeService);
    }

    public getPriceOneTimeStr(): string {
       return McGod.formatNumberToMoney(this.priceOneTime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getPricingTypeCdLabel(): string {
    return McBillingService2PriceListGENERATED.getPricingTypeCdLabel(this.pricingTypeCd);
  }


 public  isPricingTypeCdDO_NOT_CHARGE_AT_ALL(): boolean {
    const result = (this.pricingTypeCd === McBillingService2PriceListGENERATED.PRICING_TYPE_CD_DO_NOT_CHARGE_AT_ALL);

    return result;
  }

 public  isPricingTypeCdONE_TIME_ONLY(): boolean {
    const result = (this.pricingTypeCd === McBillingService2PriceListGENERATED.PRICING_TYPE_CD_ONE_TIME_ONLY);

    return result;
  }

 public  isPricingTypeCdMIX(): boolean {
    const result = (this.pricingTypeCd === McBillingService2PriceListGENERATED.PRICING_TYPE_CD_MIX);

    return result;
  }


  
}
