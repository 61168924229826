export const ICON_FILE_DOWNLOAD = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <rect id="rect-1" width="24" height="24" x="0" y="0"/>
    <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="userSpaceOnUse">
      <rect width="24" height="24" x="0" y="0" fill="black"/>
      <use fill="white" xlink:href="#rect-1"/>
    </mask>
  </defs>
  <g>
    <use fill="none" xlink:href="#rect-1"/>
    <g mask="url(#mask-2)">
      <rect width="24" height="24" x="0" y="0" fill="none" rx="0" ry="0"/>
      <path fill="rgb(4,47,77)" fill-rule="evenodd" d="M15 9h1.59000015c.8899994 0 1.32999993 1.07999992.70000077 1.71000004l-4.59000016 4.59000015c-.3900013.38999939-1.02000045.38999939-1.4100008 0L6.7000003 10.71000004C6.07000017 10.07999992 6.51999999 9 7.41000032 9H9V4c0-.54999995.45000076-1 1-1h4c.54999924 0 1 .45000005 1 1v5zM6 20c-.5500002 0-1-.45000076-1-1s.4499998-1 1-1h12c.54999924 0 1 .45000076 1 1s-.45000076 1-1 1H6z"/>
    </g>
  </g>
</svg>`
