import { McConsumerConsumerContractProfilePartComponent } from './components/mc-consumer-consumer-contract-profile-part/mc-consumer-consumer-contract-profile-part.component';
import { McConsumerConsumerCreateContractOverviewAndConfirmationPartComponent } from './components/mc-consumer-consumer-create-contract-overview-and-confirmation-part/mc-consumer-consumer-create-contract-overview-and-confirmation-part.component';
import { McConsumerConsumerCreateContractContractRenewalPartComponent } from './components/mc-consumer-consumer-create-contract-contract-renewal-part/mc-consumer-consumer-create-contract-contract-renewal-part.component';
import { McConsumerConsumer360CreateContractProductsAndServicesPartComponent } from './components/mc-consumer-consumer-360-create-contract-products-and-services-part/mc-consumer-consumer-360-create-contract-products-and-services-part.component';
import { McConsumerConsumer360CreateContractDetailsPartComponent } from './components/mc-consumer-consumer-360-create-contract-details-part/mc-consumer-consumer-360-create-contract-details-part.component';
import { McConsumerConsumer360CreateContractPaymentsPartComponent } from './components/mc-consumer-consumer-360-create-contract-payments-part/mc-consumer-consumer-360-create-contract-payments-part.component';
import { McConsumerConsumer360CreateContractPartComponent } from './components/mc-consumer-consumer-360-create-contract-part/mc-consumer-consumer-360-create-contract-part.component';
import { McConsumerConsumer360ContractsPartComponent } from './components/mc-consumer-consumer-360-contracts-part/mc-consumer-consumer-360-contracts-part.component';
import { McConsumerConsumer360TabsPageComponent } from './pages/mc-consumer-consumer-360-tabs-page/mc-consumer-consumer-360-tabs-page.component';
import { McConsumerConsumer360ProfilePartComponent } from './components/mc-consumer-consumer-360-profile-part/mc-consumer-consumer-360-profile-part.component';
import { McConsumerConsumer360SmartSearchBoxComponent } from './pages/mc-consumer-consumer-360-smart-search-box/mc-consumer-consumer-360-smart-search-box.component';
import { McConsumerActionHistoryPartComponent } from './components/mc-consumer-action-history-part/mc-consumer-action-history-part.component';
import { McConsumerChooseMappingComponent } from './components/mc-consumer-choose-mapping/mc-consumer-choose-mapping.component';
import { McConsumerImportConsumersStep2Component } from './components/mc-consumer-import-consumers-step-2/mc-consumer-import-consumers-step-2.component';
import { McConsumerImportConsumersStep1Component } from './components/mc-consumer-import-consumers-step-1/mc-consumer-import-consumers-step-1.component';
import { McConsumerConsumerMappingEditPartComponent } from './components/mc-consumer-consumer-mapping-edit-part/mc-consumer-consumer-mapping-edit-part.component';
import { McConsumerConsumerMappingProfilePartComponent } from './components/mc-consumer-consumer-mapping-profile-part/mc-consumer-consumer-mapping-profile-part.component';
import { McConsumerConsumerImportMappingsListPageComponent } from './pages/mc-consumer-consumer-import-mappings-list-page/mc-consumer-consumer-import-mappings-list-page.component';
import { McConsumerConsumerTransactionOverviewPageComponent } from './pages/mc-consumer-consumer-transaction-overview-page/mc-consumer-consumer-transaction-overview-page.component';
import { McConsumerConsumerHistoryPageComponent } from './pages/mc-consumer-consumer-history-page/mc-consumer-consumer-history-page.component';
import { McConsumerConsumerProfilePartComponent } from './components/mc-consumer-consumer-profile-part/mc-consumer-consumer-profile-part.component';
import { McConsumerConsumerProfilePageComponent } from './pages/mc-consumer-consumer-profile-page/mc-consumer-consumer-profile-page.component';
import { McConsumerConsumerAddEditPartComponent } from './components/mc-consumer-consumer-add-edit-part/mc-consumer-consumer-add-edit-part.component';
import { McConsumerConsumerImportListPageComponent } from './pages/mc-consumer-consumer-import-list-page/mc-consumer-consumer-import-list-page.component';
import { McConsumerConsumerListPageComponent } from './pages/mc-consumer-consumer-list-page/mc-consumer-consumer-list-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McConsumerRoutingModule } from './mc-consumer-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MccFiFileUploadModule, McComponentsModule} from "@miticon-ui/mc-components";
import {McFilterModule, McTableModule} from '@miticon-ui/mc-components';
import {McManagementModule} from '../mc-management/mc-management.module';
import {AppSharedModule} from "../app-shared/app-shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {CdkStepperModule} from '@angular/cdk/stepper';
import {McConsumerConsumer360CreateContractPaymentMethodPartComponent} from './components/mc-consumer-consumer-360-create-contract-payment-method-part/mc-consumer-consumer-360-create-contract-payment-method-part.component';
import { McConsumerConfirmationContractCodeComponent } from './components/mc-consumer-confirmation-contract-code/mc-consumer-confirmation-contract-code.component';
import { McConsumerContractProfilePageComponent } from './pages/mc-consumer-contract-profile-page/mc-consumer-contract-profile-page.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {CsrConsumerAddEditComponent } from './pages/csr-consumer-add-edit/csr-consumer-add-edit.component';
import {SafeHtmlModule} from "../../assets/pipes/safe-html/safe-html.module";
import {MatSelectModule} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatRadioModule} from "@angular/material/radio";
import {CsrConsumerProfileComponent } from './pages/csr-consumer-profile/csr-consumer-profile.component';
import {MatStepperModule} from "@angular/material/stepper";
import {CancelContractDialogComponent} from './components/mc-consumer-consumer-360-contracts-part/cancel-contract-dialog/cancel-contract-dialog.component';
import {ViewContractDialogComponent} from './components/mc-consumer-consumer-360-contracts-part/view-contract-dialog/view-contract-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {McConsumerConsumerInvoicesPartComponent} from './components/mc-consumer-consumer-invoices-part/mc-consumer-consumer-invoices-part.component';
import {McConsumerConsumer360BankInfoPartComponent} from './components/mc-consumer-consumer-360-bank-info-part/mc-consumer-consumer-360-bank-info-part.component';
import {McConsumerConsumer360AdditionalInfoPartComponent} from './components/mc-consumer-consumer-360-additional-info-part/mc-consumer-consumer-360-additional-info-part.component';

@NgModule({
  declarations: [
McConsumerConsumerContractProfilePartComponent,
McConsumerConsumerCreateContractOverviewAndConfirmationPartComponent,
McConsumerConsumerCreateContractContractRenewalPartComponent,
McConsumerConsumer360CreateContractProductsAndServicesPartComponent,
McConsumerConsumer360CreateContractPaymentsPartComponent,
McConsumerConsumer360CreateContractDetailsPartComponent,
McConsumerConsumer360CreateContractPartComponent,
McConsumerConsumer360ContractsPartComponent,
McConsumerConsumer360TabsPageComponent,
McConsumerConsumer360ProfilePartComponent,
McConsumerConsumer360SmartSearchBoxComponent,
McConsumerActionHistoryPartComponent,
McConsumerChooseMappingComponent,
McConsumerImportConsumersStep2Component,
McConsumerImportConsumersStep1Component,
McConsumerConsumerMappingEditPartComponent,
McConsumerConsumerMappingProfilePartComponent,
McConsumerConsumerImportMappingsListPageComponent,
McConsumerConsumerTransactionOverviewPageComponent,
McConsumerConsumerHistoryPageComponent,
McConsumerConsumerProfilePartComponent,
McConsumerConsumerProfilePageComponent,
McConsumerConsumerAddEditPartComponent,
McConsumerConsumerImportListPageComponent,
McConsumerConsumerListPageComponent,
    McConsumerConsumer360CreateContractPaymentMethodPartComponent,
    McConsumerConfirmationContractCodeComponent,
    McConsumerContractProfilePageComponent,
    CsrConsumerAddEditComponent,
    CsrConsumerProfileComponent,
    CancelContractDialogComponent,
    ViewContractDialogComponent,
    McConsumerConsumerInvoicesPartComponent,
    McConsumerConsumer360BankInfoPartComponent,
    McConsumerConsumer360AdditionalInfoPartComponent
  ],
    imports: [
        CommonModule,
        McConsumerRoutingModule,
        NgbModule,
        McComponentsModule,
        McFilterModule,
        McTableModule,
        FormsModule,
        ReactiveFormsModule,
        McManagementModule,
        AppSharedModule,
        TranslateModule,
        MccFiFileUploadModule,
        CdkStepperModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        SafeHtmlModule,
        MatSelectModule,
        MatDatepickerModule,
        MatRadioModule,
        MatStepperModule,
        MatDialogModule,
    ],
  exports: [
    McConsumerConsumerContractProfilePartComponent,
    McConsumerConsumerCreateContractContractRenewalPartComponent,
    McConsumerConsumerCreateContractOverviewAndConfirmationPartComponent,
    McConsumerConsumer360CreateContractProductsAndServicesPartComponent,
    McConsumerConsumer360CreateContractPaymentsPartComponent,
    McConsumerConsumer360CreateContractDetailsPartComponent,
    McConsumerConsumer360CreateContractPartComponent,
    McConsumerConsumer360ContractsPartComponent,
    McConsumerConsumer360TabsPageComponent,
    McConsumerConsumer360ProfilePartComponent,
    McConsumerConsumer360SmartSearchBoxComponent,
    McConsumerActionHistoryPartComponent,
    McConsumerChooseMappingComponent,
    McConsumerImportConsumersStep2Component,
    McConsumerImportConsumersStep1Component,
    McConsumerConsumerMappingEditPartComponent,
    McConsumerConsumerMappingProfilePartComponent,
    McConsumerConsumerImportMappingsListPageComponent,
    McConsumerConsumerTransactionOverviewPageComponent,
    McConsumerConsumerHistoryPageComponent,
    McConsumerConsumerProfilePartComponent,
    McConsumerConsumerProfilePageComponent,
    McConsumerConsumerAddEditPartComponent,
    McConsumerConsumerImportListPageComponent,
    McConsumerConsumerListPageComponent,
    McConsumerConsumer360CreateContractPaymentMethodPartComponent,
    McConsumerRoutingModule,
    FormsModule,
    McConsumerConfirmationContractCodeComponent,
  ]
})
export class McConsumerModule { }
