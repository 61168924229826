/*BbmTestObj*/
import {McCurrencyGENERATED} from '../_generated/mc-currency-generated.model';

export class McCurrency extends McCurrencyGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McCurrency();
  }
}
