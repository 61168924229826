/* eslint-disable */
import {Component, EventEmitter, Output} from '@angular/core';

@Component({

  selector: 'mc-see-password',
  templateUrl: './mc-see-password.component.html',
  styleUrls: ['./mc-see-password.component.scss']
})
export class McSeePasswordComponent {
  @Output() seePasswordOnEyeBtn = new EventEmitter<boolean>();
  seePassword = false;

  seePasswordClick() {
    this.seePasswordOnEyeBtn.emit(
      this.seePassword = !this.seePassword
    );
  }

}
