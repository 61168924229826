/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EbPsd2ReportFilter} from '../models/eb-psd2-report-filter.model';

export class EbPsd2ReportFilterGENERATED extends BaseObjectFilter{    public searchTerm: string | undefined;
    public dateFrom: any | undefined;
    public dateTo: any | undefined;

  public properties: string[] = ['searchTerm', 'dateFrom', 'dateTo', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbPsd2ReportFilter {
    console.error('MTCN-ERROR: Not Implemented: EbPsd2ReportFilter::createNewInstance(). Add this method to final class and return new EbPsd2ReportFilter();');
      throw new Error('Cannot EbPsd2ReportFilter::createNewInstance(). Add this method to final class and return new EbPsd2ReportFilter();');
  }

}
