/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {ImDataMappingItem} from '../models/im-data-mapping-item.model';
import {ImDataMappingItemList} from '../models/im-data-mapping-item-list.model';
import {ImDataMappingItemFilter} from '../models/im-data-mapping-item-filter.model';
import {ImDataMappingItemService} from '../services/im-data-mapping-item.service';
import {IImDataMappingItem} from '../_generated/im-data-mapping-item.interface';
import {McGod} from "../extra/mc-god.service";

export class ImDataMappingItemListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'attributeName', 'columnName', 'idDataMapping', 'requiredFlg', 'sampleData'];
  public items: ImDataMappingItem[] = [];
  public apiService: ImDataMappingItemService;
 // protected tempObjList: ImDataMappingItemList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): ImDataMappingItemList {
    console.error('MTCN-ERROR: Not Implemented: ImDataMappingItemList::createNewInstance(). Add this method to final class and return new ImDataMappingItemList();');
    throw new Error('Cannot ImDataMappingItemList::createNewInstance(). Add this method to final class and return new ImDataMappingItemList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IImDataMappingItem[]): ImDataMappingItemList {
    const  listObj: ImDataMappingItemList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: ImDataMappingItem = ImDataMappingItem.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): ImDataMappingItemList {
    const  listObj: ImDataMappingItemList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(ImDataMappingItemService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IImDataMappingItem[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: ImDataMappingItem = ImDataMappingItem.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aImDataMappingItemFilter: ImDataMappingItemFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aImDataMappingItemFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): ImDataMappingItem {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new ImDataMappingItem();
    }
  }

  public replaceItem(newItem: ImDataMappingItem) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

