/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McProcessFlowCriteriaAttribute} from '../models/mc-process-flow-criteria-attribute.model';
import {McProcessFlowCriteriaAttributeService} from '../services/mc-process-flow-criteria-attribute.service';
import {IMcProcessFlowCriteriaAttribute} from '../_generated/mc-process-flow-criteria-attribute.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McProcessFlowCriteriaAttributeGENERATED extends BaseObject {


    public static readonly ATTRIBUTE_TYPE_CD_BOOLEAN: string = 'BOOLEAN';
    public static readonly ATTRIBUTE_TYPE_CD_NUMBER: string = 'NUMBER';
    public static readonly ATTRIBUTE_TYPE_CD_MONEY: string = 'MONEY';
    public static readonly ATTRIBUTE_TYPE_CD_STRING: string = 'STRING';
    public static readonly ATTRIBUTE_TYPE_CD_DATETIME: string = 'DATETIME';
  public static readonly ATTRIBUTE_TYPE_CD_LIST__ALL = [
    McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_BOOLEAN,
    McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_NUMBER,
    McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_MONEY,
    McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_STRING,
    McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_DATETIME
];

    public static readonly BOOLEAN_OPERATOR_TYPE_CD_EQUALS: string = 'EQUALS';
  public static readonly BOOLEAN_OPERATOR_TYPE_CD_LIST__ALL = [
    McProcessFlowCriteriaAttributeGENERATED.BOOLEAN_OPERATOR_TYPE_CD_EQUALS
];

    public static readonly DATETIME_OPERATOR_TYPE_CD_IN: string = 'IN';
    public static readonly DATETIME_OPERATOR_TYPE_CD_PASSED: string = 'PASSED';
    public static readonly DATETIME_OPERATOR_TYPE_CD_ANNIVERSARY_IN: string = 'ANNIVERSARY_IN';
    public static readonly DATETIME_OPERATOR_TYPE_CD_ANNIVERSARY_PASSED: string = 'ANNIVERSARY_PASSED';
    public static readonly DATETIME_OPERATOR_TYPE_CD_EQUALS: string = 'EQUALS';
  public static readonly DATETIME_OPERATOR_TYPE_CD_LIST__ALL = [
    McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_IN,
    McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_PASSED,
    McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_ANNIVERSARY_IN,
    McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_ANNIVERSARY_PASSED,
    McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_EQUALS
];

    public static readonly DATETIME_OPTION_TYPE_CD_DAY: string = 'DAY';
    public static readonly DATETIME_OPTION_TYPE_CD_WORKING_DAY: string = 'WORKING_DAY';
    public static readonly DATETIME_OPTION_TYPE_CD_WEEK: string = 'WEEK';
    public static readonly DATETIME_OPTION_TYPE_CD_MONTH: string = 'MONTH';
  public static readonly DATETIME_OPTION_TYPE_CD_LIST__ALL = [
    McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPTION_TYPE_CD_DAY,
    McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPTION_TYPE_CD_WORKING_DAY,
    McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPTION_TYPE_CD_WEEK,
    McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPTION_TYPE_CD_MONTH
];

    public static readonly MONEY_OPERATOR_TYPE_CD_GREATER_THAN: string = 'GREATER_THAN';
    public static readonly MONEY_OPERATOR_TYPE_CD_LESS_THAN: string = 'LESS_THAN';
    public static readonly MONEY_OPERATOR_TYPE_CD_EQUALS: string = 'EQUALS';
  public static readonly MONEY_OPERATOR_TYPE_CD_LIST__ALL = [
    McProcessFlowCriteriaAttributeGENERATED.MONEY_OPERATOR_TYPE_CD_GREATER_THAN,
    McProcessFlowCriteriaAttributeGENERATED.MONEY_OPERATOR_TYPE_CD_LESS_THAN,
    McProcessFlowCriteriaAttributeGENERATED.MONEY_OPERATOR_TYPE_CD_EQUALS
];

  public static readonly MONEY_OPTION_TYPE_CD_LIST__ALL = [
];

    public static readonly NUMBER_OPERATOR_TYPE_CD_GREATER_THAN: string = 'GREATER_THAN';
    public static readonly NUMBER_OPERATOR_TYPE_CD_LESS_THAN: string = 'LESS_THAN';
    public static readonly NUMBER_OPERATOR_TYPE_CD_EQUALS: string = 'EQUALS';
  public static readonly NUMBER_OPERATOR_TYPE_CD_LIST__ALL = [
    McProcessFlowCriteriaAttributeGENERATED.NUMBER_OPERATOR_TYPE_CD_GREATER_THAN,
    McProcessFlowCriteriaAttributeGENERATED.NUMBER_OPERATOR_TYPE_CD_LESS_THAN,
    McProcessFlowCriteriaAttributeGENERATED.NUMBER_OPERATOR_TYPE_CD_EQUALS
];

  public static readonly NUMBER_OPTION_TYPE_CD_LIST__ALL = [
];

    public static readonly STRING_OPERATOR_TYPE_CD_EQUALS: string = 'EQUALS';
    public static readonly STRING_OPERATOR_TYPE_CD_EQUALS_IGNORE_CASE: string = 'EQUALS_IGNORE_CASE';
    public static readonly STRING_OPERATOR_TYPE_CD_CONTAINS: string = 'CONTAINS';
  public static readonly STRING_OPERATOR_TYPE_CD_LIST__ALL = [
    McProcessFlowCriteriaAttributeGENERATED.STRING_OPERATOR_TYPE_CD_EQUALS,
    McProcessFlowCriteriaAttributeGENERATED.STRING_OPERATOR_TYPE_CD_EQUALS_IGNORE_CASE,
    McProcessFlowCriteriaAttributeGENERATED.STRING_OPERATOR_TYPE_CD_CONTAINS
];

  public static readonly STRING_OPTION_TYPE_CD_LIST__ALL = [
];

  public apiService: McProcessFlowCriteriaAttributeService;
  public _rawJson: IMcProcessFlowCriteriaAttribute;
    id: number = 0;
  attributeName = '';
  attributeTypeCd: string;
  booleanOperatorTypeCd: string;
  datetimeOperatorTypeCd: string;
  datetimeOptionTypeCd: string;
  moneyOperatorTypeCd: string;
  moneyOptionTypeCd: string;
  numberOperatorTypeCd: string;
  numberOptionTypeCd: string;
  stringOperatorTypeCd: string;
  stringOptionTypeCd: string;
  value = '';


  public properties: string[] = ['id', 'attributeName', 'attributeTypeCd', 'booleanOperatorTypeCd', 'datetimeOperatorTypeCd', 'datetimeOptionTypeCd', 'moneyOperatorTypeCd', 'moneyOptionTypeCd', 'numberOperatorTypeCd', 'numberOptionTypeCd', 'stringOperatorTypeCd', 'stringOptionTypeCd', 'value'];
  public propertiesRegular: string[] = ['id', 'attributeName', 'attributeTypeCd', 'booleanOperatorTypeCd', 'datetimeOperatorTypeCd', 'datetimeOptionTypeCd', 'moneyOperatorTypeCd', 'moneyOptionTypeCd', 'numberOperatorTypeCd', 'numberOptionTypeCd', 'stringOperatorTypeCd', 'stringOptionTypeCd', 'value'];
  public propertiesSpecial: string[] = [];



  public static getAttributeTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_BOOLEAN, McGod.t('Boolean'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_NUMBER, McGod.t('Number'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_MONEY, McGod.t('Money'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_STRING, McGod.t('String'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_DATETIME, McGod.t('Datetime'));


    return list;
  }

  public static getAttributeTypeCdLabel(aCd: string): string {
    const list = McProcessFlowCriteriaAttributeGENERATED.getAttributeTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getBooleanOperatorTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowCriteriaAttributeGENERATED.BOOLEAN_OPERATOR_TYPE_CD_EQUALS, McGod.t('Equals'));


    return list;
  }

  public static getBooleanOperatorTypeCdLabel(aCd: string): string {
    const list = McProcessFlowCriteriaAttributeGENERATED.getBooleanOperatorTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getDatetimeOperatorTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_IN, McGod.t('In'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_PASSED, McGod.t('Passed'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_ANNIVERSARY_IN, McGod.t('Anniversary in'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_ANNIVERSARY_PASSED, McGod.t('Anniversary passed'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_EQUALS, McGod.t('Equals'));


    return list;
  }

  public static getDatetimeOperatorTypeCdLabel(aCd: string): string {
    const list = McProcessFlowCriteriaAttributeGENERATED.getDatetimeOperatorTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getDatetimeOptionTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPTION_TYPE_CD_DAY, McGod.t('Day(s)'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPTION_TYPE_CD_WORKING_DAY, McGod.t('Working day(s)'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPTION_TYPE_CD_WEEK, McGod.t('Week(s)'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPTION_TYPE_CD_MONTH, McGod.t('Month(s)'));


    return list;
  }

  public static getDatetimeOptionTypeCdLabel(aCd: string): string {
    const list = McProcessFlowCriteriaAttributeGENERATED.getDatetimeOptionTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getMoneyOperatorTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowCriteriaAttributeGENERATED.MONEY_OPERATOR_TYPE_CD_GREATER_THAN, McGod.t('Greater than'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.MONEY_OPERATOR_TYPE_CD_LESS_THAN, McGod.t('Less than'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.MONEY_OPERATOR_TYPE_CD_EQUALS, McGod.t('Equals'));


    return list;
  }

  public static getMoneyOperatorTypeCdLabel(aCd: string): string {
    const list = McProcessFlowCriteriaAttributeGENERATED.getMoneyOperatorTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getMoneyOptionTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    

    return list;
  }

  public static getMoneyOptionTypeCdLabel(aCd: string): string {
    const list = McProcessFlowCriteriaAttributeGENERATED.getMoneyOptionTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getNumberOperatorTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowCriteriaAttributeGENERATED.NUMBER_OPERATOR_TYPE_CD_GREATER_THAN, McGod.t('Greater than'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.NUMBER_OPERATOR_TYPE_CD_LESS_THAN, McGod.t('Less than'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.NUMBER_OPERATOR_TYPE_CD_EQUALS, McGod.t('Equals'));


    return list;
  }

  public static getNumberOperatorTypeCdLabel(aCd: string): string {
    const list = McProcessFlowCriteriaAttributeGENERATED.getNumberOperatorTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getNumberOptionTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    

    return list;
  }

  public static getNumberOptionTypeCdLabel(aCd: string): string {
    const list = McProcessFlowCriteriaAttributeGENERATED.getNumberOptionTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStringOperatorTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessFlowCriteriaAttributeGENERATED.STRING_OPERATOR_TYPE_CD_EQUALS, McGod.t('Equals'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.STRING_OPERATOR_TYPE_CD_EQUALS_IGNORE_CASE, McGod.t('Equals ignore case'));
    list.add(McProcessFlowCriteriaAttributeGENERATED.STRING_OPERATOR_TYPE_CD_CONTAINS, McGod.t('Contains'));


    return list;
  }

  public static getStringOperatorTypeCdLabel(aCd: string): string {
    const list = McProcessFlowCriteriaAttributeGENERATED.getStringOperatorTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStringOptionTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    

    return list;
  }

  public static getStringOptionTypeCdLabel(aCd: string): string {
    const list = McProcessFlowCriteriaAttributeGENERATED.getStringOptionTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McProcessFlowCriteriaAttribute {
    console.error('MTCN-ERROR: Not Implemented: McProcessFlowCriteriaAttribute::createNewInstance(). Add this method to final class and return new McProcessFlowCriteriaAttribute();');
    throw new Error('Cannot McProcessFlowCriteriaAttribute::createNewInstance(). Add this method to final class and return new McProcessFlowCriteriaAttribute();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcProcessFlowCriteriaAttribute): McProcessFlowCriteriaAttribute {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessFlowCriteriaAttributeService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McProcessFlowCriteriaAttribute AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getAttributeTypeCdLabel(): string {
    return McProcessFlowCriteriaAttributeGENERATED.getAttributeTypeCdLabel(this.attributeTypeCd);
  }


 public  isAttributeTypeCdBOOLEAN(): boolean {
    const result = (this.attributeTypeCd === McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_BOOLEAN);

    return result;
  }

 public  isAttributeTypeCdNUMBER(): boolean {
    const result = (this.attributeTypeCd === McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_NUMBER);

    return result;
  }

 public  isAttributeTypeCdMONEY(): boolean {
    const result = (this.attributeTypeCd === McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_MONEY);

    return result;
  }

 public  isAttributeTypeCdSTRING(): boolean {
    const result = (this.attributeTypeCd === McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_STRING);

    return result;
  }

 public  isAttributeTypeCdDATETIME(): boolean {
    const result = (this.attributeTypeCd === McProcessFlowCriteriaAttributeGENERATED.ATTRIBUTE_TYPE_CD_DATETIME);

    return result;
  }


  public getBooleanOperatorTypeCdLabel(): string {
    return McProcessFlowCriteriaAttributeGENERATED.getBooleanOperatorTypeCdLabel(this.booleanOperatorTypeCd);
  }


 public  isBooleanOperatorTypeCdEQUALS(): boolean {
    const result = (this.booleanOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.BOOLEAN_OPERATOR_TYPE_CD_EQUALS);

    return result;
  }


  public getDatetimeOperatorTypeCdLabel(): string {
    return McProcessFlowCriteriaAttributeGENERATED.getDatetimeOperatorTypeCdLabel(this.datetimeOperatorTypeCd);
  }


 public  isDatetimeOperatorTypeCdIN(): boolean {
    const result = (this.datetimeOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_IN);

    return result;
  }

 public  isDatetimeOperatorTypeCdPASSED(): boolean {
    const result = (this.datetimeOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_PASSED);

    return result;
  }

 public  isDatetimeOperatorTypeCdANNIVERSARY_IN(): boolean {
    const result = (this.datetimeOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_ANNIVERSARY_IN);

    return result;
  }

 public  isDatetimeOperatorTypeCdANNIVERSARY_PASSED(): boolean {
    const result = (this.datetimeOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_ANNIVERSARY_PASSED);

    return result;
  }

 public  isDatetimeOperatorTypeCdEQUALS(): boolean {
    const result = (this.datetimeOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPERATOR_TYPE_CD_EQUALS);

    return result;
  }


  public getDatetimeOptionTypeCdLabel(): string {
    return McProcessFlowCriteriaAttributeGENERATED.getDatetimeOptionTypeCdLabel(this.datetimeOptionTypeCd);
  }


 public  isDatetimeOptionTypeCdDAY(): boolean {
    const result = (this.datetimeOptionTypeCd === McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPTION_TYPE_CD_DAY);

    return result;
  }

 public  isDatetimeOptionTypeCdWORKING_DAY(): boolean {
    const result = (this.datetimeOptionTypeCd === McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPTION_TYPE_CD_WORKING_DAY);

    return result;
  }

 public  isDatetimeOptionTypeCdWEEK(): boolean {
    const result = (this.datetimeOptionTypeCd === McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPTION_TYPE_CD_WEEK);

    return result;
  }

 public  isDatetimeOptionTypeCdMONTH(): boolean {
    const result = (this.datetimeOptionTypeCd === McProcessFlowCriteriaAttributeGENERATED.DATETIME_OPTION_TYPE_CD_MONTH);

    return result;
  }


  public getMoneyOperatorTypeCdLabel(): string {
    return McProcessFlowCriteriaAttributeGENERATED.getMoneyOperatorTypeCdLabel(this.moneyOperatorTypeCd);
  }


 public  isMoneyOperatorTypeCdGREATER_THAN(): boolean {
    const result = (this.moneyOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.MONEY_OPERATOR_TYPE_CD_GREATER_THAN);

    return result;
  }

 public  isMoneyOperatorTypeCdLESS_THAN(): boolean {
    const result = (this.moneyOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.MONEY_OPERATOR_TYPE_CD_LESS_THAN);

    return result;
  }

 public  isMoneyOperatorTypeCdEQUALS(): boolean {
    const result = (this.moneyOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.MONEY_OPERATOR_TYPE_CD_EQUALS);

    return result;
  }


  public getMoneyOptionTypeCdLabel(): string {
    return McProcessFlowCriteriaAttributeGENERATED.getMoneyOptionTypeCdLabel(this.moneyOptionTypeCd);
  }



  public getNumberOperatorTypeCdLabel(): string {
    return McProcessFlowCriteriaAttributeGENERATED.getNumberOperatorTypeCdLabel(this.numberOperatorTypeCd);
  }


 public  isNumberOperatorTypeCdGREATER_THAN(): boolean {
    const result = (this.numberOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.NUMBER_OPERATOR_TYPE_CD_GREATER_THAN);

    return result;
  }

 public  isNumberOperatorTypeCdLESS_THAN(): boolean {
    const result = (this.numberOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.NUMBER_OPERATOR_TYPE_CD_LESS_THAN);

    return result;
  }

 public  isNumberOperatorTypeCdEQUALS(): boolean {
    const result = (this.numberOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.NUMBER_OPERATOR_TYPE_CD_EQUALS);

    return result;
  }


  public getNumberOptionTypeCdLabel(): string {
    return McProcessFlowCriteriaAttributeGENERATED.getNumberOptionTypeCdLabel(this.numberOptionTypeCd);
  }



  public getStringOperatorTypeCdLabel(): string {
    return McProcessFlowCriteriaAttributeGENERATED.getStringOperatorTypeCdLabel(this.stringOperatorTypeCd);
  }


 public  isStringOperatorTypeCdEQUALS(): boolean {
    const result = (this.stringOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.STRING_OPERATOR_TYPE_CD_EQUALS);

    return result;
  }

 public  isStringOperatorTypeCdEQUALS_IGNORE_CASE(): boolean {
    const result = (this.stringOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.STRING_OPERATOR_TYPE_CD_EQUALS_IGNORE_CASE);

    return result;
  }

 public  isStringOperatorTypeCdCONTAINS(): boolean {
    const result = (this.stringOperatorTypeCd === McProcessFlowCriteriaAttributeGENERATED.STRING_OPERATOR_TYPE_CD_CONTAINS);

    return result;
  }


  public getStringOptionTypeCdLabel(): string {
    return McProcessFlowCriteriaAttributeGENERATED.getStringOptionTypeCdLabel(this.stringOptionTypeCd);
  }



  
}
