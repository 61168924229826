/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EbSepaFileFilter} from '../models/eb-sepa-file-filter.model';

export class EbSepaFileFilterGENERATED extends BaseObjectFilter{    public searchTerm: string;
    public childEntityIds: number[] = [];
    public statusCds: string[] = [];
    public outFactoringFlg: boolean;

  public properties: string[] = ['searchTerm', 'childEntityIds', 'statusCds', 'outFactoringFlg', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbSepaFileFilter {
    console.error('MTCN-ERROR: Not Implemented: EbSepaFileFilter::createNewInstance(). Add this method to final class and return new EbSepaFileFilter();');
      throw new Error('Cannot EbSepaFileFilter::createNewInstance(). Add this method to final class and return new EbSepaFileFilter();');
  }

}
