/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {PpOrderItem} from '../models/pp-order-item.model';
import {PpOrderItemList} from '../models/pp-order-item-list.model';
import {PpOrderItemFilter} from '../models/pp-order-item-filter.model';
import {PpOrderItemService} from '../services/pp-order-item.service';
import {IPpOrderItem} from '../_generated/pp-order-item.interface';
import {McGod} from "../extra/mc-god.service";

export class PpOrderItemListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'description', 'idPpOrder', 'idPpProduct', 'name', 'note', 'quantity', 'sorterId', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'unitPrice', 'vat', 'version', 'discount'];
  public items: PpOrderItem[] = [];
  public apiService: PpOrderItemService;
 // protected tempObjList: PpOrderItemList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): PpOrderItemList {
    console.error('MTCN-ERROR: Not Implemented: PpOrderItemList::createNewInstance(). Add this method to final class and return new PpOrderItemList();');
    throw new Error('Cannot PpOrderItemList::createNewInstance(). Add this method to final class and return new PpOrderItemList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IPpOrderItem[]): PpOrderItemList {
    const  listObj: PpOrderItemList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: PpOrderItem = PpOrderItem.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): PpOrderItemList {
    const  listObj: PpOrderItemList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(PpOrderItemService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IPpOrderItem[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: PpOrderItem = PpOrderItem.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aPpOrderItemFilter: PpOrderItemFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aPpOrderItemFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): PpOrderItem {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new PpOrderItem();
    }
  }

  public replaceItem(newItem: PpOrderItem) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

