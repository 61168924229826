<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcConsumerBalanceList.apiLoadingFlg"></lib-mc-loader>
  <div class="content-header">
    <h3>{{'cc.consumer.consumers-balance' | translate}}</h3>
  </div>
  <div class="content-body">
    <div class="mc-filter fancy-label">

      <div *ngIf="entityVll.items.length > 0">
        <mcc-multiselect-filter [optionVll]="entityVll" label="{{'cc.common.view.entity' | translate}}" name="selEntity"
                                [clearedAllFilters]="clearedAllMultipleFilters" (eventSelectedItems)="onSelectEntities($event)">
        </mcc-multiselect-filter></div>

      <button class="mc-link mc-filter-clear-button filter-clear-top-right" (click)="actClearFilter()">{{'cc.my-factoring.clear-all' | translate}}</button>
      <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>


    </div>

    <div *ngIf="mcConsumerBalanceList.apiSuccessFlg">
      <mcc-fi-table-3 [objectList]="mcConsumerBalanceList"
                      [table3Config]="mcConsumerBalanceTable3Config"
                      (eventPaginationOrSortChanged)="actLoad()"
      ></mcc-fi-table-3>
    </div>
  </div>
  <div class="content-footer">
    <button class="btn btn-secondary move-right" (click)="actCancel();"><i class="far fa-times-circle"></i> {{'cc.common.edit.cancel' | translate}}
    </button>
  </div>
</div>
