import {McProcessRunFilterGENERATED} from '../_generated/mc-process-run-filter-generated.model';

export class McProcessRunFilter extends McProcessRunFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessRunFilter();
  }

  // ---------------------------------------------------------------------
}
