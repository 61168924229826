/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {CcMollieCustomer} from '../models/cc-mollie-customer.model';
import {CcMollieCustomerService} from '../services/cc-mollie-customer.service';
import {ICcMollieCustomer} from '../_generated/cc-mollie-customer.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class CcMollieCustomerGENERATED extends BaseObject {


  public apiService: CcMollieCustomerService;
  public _rawJson: ICcMollieCustomer;
    id: number = 0;
  confirmDatetime: number;
  confirmationCode = '';
  expireDatetime: number;
  idCsrConsumer: number;
  idCsrContract: number;
  idMollieCustomer = '';
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  mollieRedirectUrl: string;

  public properties: string[] = ['id', 'confirmDatetime', 'confirmationCode', 'expireDatetime', 'idCsrConsumer', 'idCsrContract', 'idMollieCustomer', 'sysCreatedDatetime', 'sysCreatedUserId', 'mollieRedirectUrl'];
  public propertiesRegular: string[] = ['id', 'confirmDatetime', 'confirmationCode', 'csrConsumer', 'csrContract', 'expireDatetime', 'idCsrConsumer', 'idCsrContract', 'idMollieCustomer', 'sysCreatedDatetime', 'sysCreatedUserId', 'mollieRedirectUrl'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): CcMollieCustomer {
    console.error('MTCN-ERROR: Not Implemented: CcMollieCustomer::createNewInstance(). Add this method to final class and return new CcMollieCustomer();');
    throw new Error('Cannot CcMollieCustomer::createNewInstance(). Add this method to final class and return new CcMollieCustomer();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: ICcMollieCustomer): CcMollieCustomer {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CcMollieCustomerService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CcMollieCustomer AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
  // ---------------------------------------------------------------------
  public loadByContractId( idCsrContract: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByContractId(idCsrContract)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

}
