import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {McFileUploadingTrigger, McForm, McGod, WebFile} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'mcc-fi-file-upload2',
  templateUrl: './mcc-fi-file-upload2.component.html',
  styleUrls: ['./mcc-fi-file-upload2.component.scss']
})
export class MccFiFileUpload2Component implements OnInit {

  file!: File | null;
  @Input() acceptProperty: any;
  @Input() mcForm!: McForm;
  // @Input() name: string;
  @Input() valueWebFileId!: number;
  @Input() validRequiredFlg = false;
  @Input() mcFileUploadingTrigger = new McFileUploadingTrigger();
  // @Output() eventWebFileUploaded = new EventEmitter<{ webFile: WebFile, errorMsg: string }>();
  @Output() eventSucceed = new EventEmitter<WebFile>();
  @Output() eventFailed = new EventEmitter<string>();
  public mcGod = McGod.getInstance();
  public webFile = new WebFile();
  public formValidationTrigger!: Subscription;
  public errorFlg = false;

  public inputText = this.mcGod.t('cc.consumers.consumers-overview.choose-fie');

  constructor() {
  }

  ngOnInit() {

    if (this.valueWebFileId > 0) {
      this.webFile.getFileByIdAndType(this.valueWebFileId, WebFile.TYPE_CD_UNKNOWN, () => {
        this.inputText = this.webFile.filename;
      });
    }

    if (this.mcForm) {

      this.mcFileUploadingTrigger.subscribe(next => {
        if (this.file !== null) {
          this.webFile.upload({file: this.file}, WebFile.TYPE_CD_UNKNOWN, () => {
            // this.eventWebFileUploaded.emit({ webFile: this.webFile, errorMsg: ''}); // TODO: CREATE NEW CLASS ?s
            this.eventSucceed.emit(this.webFile);
          }, () => {
            this.eventFailed.emit( this.webFile.apiErrorMessage );
          });
        } else {
          this.eventFailed.emit( this.mcGod.t('cc.consumers.consumers-overview.choose-fie'));
        }
      });

      this.formValidationTrigger = this.mcForm.getValidationTriggerObservable().subscribe(() => {
        this.validateUploadFile();
      });

    }

  }

  onFileChanged(event: Event) {
    // @ts-ignore
    const inputFile: File = (event.target as HTMLInputElement).files[0];
    if (inputFile) {
      this.inputText = inputFile.name;
      this.file = inputFile;
    } else {
      this.file = null;
      this.inputText = this.mcGod.t('cc.consumers.consumers-overview.choose-fie');
    }
    // this.eventFileSelected.emit(this.file);
  }

  validateUploadFile() {
    this.errorFlg = this.file === null;
  }

}
