/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EbInkassoExportFilter} from '../models/eb-inkasso-export-filter.model';

export class EbInkassoExportFilterGENERATED extends BaseObjectFilter{    public searchTerm: string | undefined;
    public exportedBy: number | undefined;
    public exportedDateFrom: any | undefined;
    public exportedDateTo: any | undefined;
    public idMcEntity: number | undefined;
    public statusCd: string | undefined;
    public creationTypeCd: string | undefined;

  public properties: string[] = ['searchTerm', 'exportedBy', 'exportedDateFrom', 'exportedDateTo', 'idMcEntity', 'statusCd', 'creationTypeCd', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbInkassoExportFilter {
    console.error('MTCN-ERROR: Not Implemented: EbInkassoExportFilter::createNewInstance(). Add this method to final class and return new EbInkassoExportFilter();');
      throw new Error('Cannot EbInkassoExportFilter::createNewInstance(). Add this method to final class and return new EbInkassoExportFilter();');
  }

}
