import {ZzPocGarage} from './zz-poc-garage.model';
import {ZzPocGarageListGENERATED} from '../_generated/zz-poc-garage-list-generated.model';

export class ZzPocGarageList extends ZzPocGarageListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new ZzPocGarageList();
  }

  // ---------------------------------------------------------------------
}
