import { McRegex } from "./mc-regex";

export class McValidation {

  validRequiredFlg!: boolean;
  validPattern!: string;
  validMinLength!: number;
  validMaxLength!: number;
  customValidator: any;

  static mcCheckPhoneNumber(phoneNumber: string) {
    return McRegex.mcPhoneNumberRegex.test(phoneNumber);
  }

  static mcCheckPostalCode(postalCode: string) {
    return McRegex.mcPostalCodeRegex.test(postalCode);
  }

  static mcCheckMinDate(minDate: number, value: number) {
    return minDate > value;
  }

  static mcCheckMaxDate(maxDate: number, value: number) {
    return maxDate < value;
  }

}
