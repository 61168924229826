/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingItemTable3ConfigGENERATED} from '../_generated/mc-billing-item-table-3-config-generated.model';

// import {McBillingItemGENERATED} from '../_generated/mc-billing-item-generated.model';

export class McPayoutItemTable3Model extends McBillingItemTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colType', this.t('cc.jobs.edit.type'), 'getRefTypeCdLabel()', 'typeCd');
    this.addColumn('colTotal', this.t('cc.statement.total'), 'getTotalAmount()', '');
    this.addColumn('colPayoutDate', this.t('cc.common.date') , 'getActivityDatetimeString()', 'payoutDatetime');
    }
}
