import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ImDataMappingItemServiceGENERATED} from '../_generated/im-data-mapping-item-generated.service';
import {Observable} from 'rxjs';
import * as CircularJSON from 'circular-json';


@Injectable({
  providedIn: 'root'
})
export class ImDataMappingItemService extends ImDataMappingItemServiceGENERATED {

  public updateByMappingId(mappingId: number, attributesList: any[]): Observable<any> {
    return this.httpClient.put<any>(this.getApiServiceRootUrl() + `/updatebymapping/` + mappingId, CircularJSON.stringify(attributesList), {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }
}
