import {McConsumer} from './mc-consumer.model';
import {McConsumerListGENERATED} from '../_generated/mc-consumer-list-generated.model';

export class McConsumerList extends McConsumerListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McConsumerList();
  }

  // ---------------------------------------------------------------------
}
