import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {McForm, McGod} from '@miticon-ui/mc-core';
import {FiTextboxConfig} from './fi-textbox-config';
import {Subject, Subscription} from 'rxjs';
import {MccFiTextboxEventAfterValidation} from './mcc-fi-textbox-event-after-validation';

@Component({
  selector: 'mcc-fi-textbox',
  templateUrl: './mcc-fi-textbox.component.html',
  styleUrls: ['./mcc-fi-textbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MccFiTextboxComponent implements OnInit, OnChanges, OnDestroy {

  requiredFlg!: Validators;
  pattern!: Validators;
  minLength!: Validators;
  maxLength!: Validators;
  numberWithTwoDecimalsRegex!: Validators;
  numberDecimalRegex!: Validators;
  numberIntegerRegex!: Validators;
  numberIntegerAndDecimalRegex!: Validators;
  onlyIntegersAndLettersRegex!: Validators;
  whiteSpaceRegex!: Validators;
  emailRegex!: Validators;
  phoneRegex!: Validators;
  validMin!: Validators;
  validMax!: Validators;
  numbersLettersAndHyphenRegex!: Validators;
  numbersLettersHyphenDotSpaceAndApostropheRegex!: Validators;
  numberDecimalRegexString = /^\d*\.\d{1,2}?\d*$/;
  numberIntegerRegexString = /^\d*\d*$/;
  numberIntegerAndDecimalRegexString = /^[0-9]+(\.[0-9]{1,2})?$/;
  whitespaceRegexString = /^(?!\s*$).+/;
  emailRegexString = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,6})$/;
  numberWithTwoDecimalsRegexString = /^\d+(\.\d{1,2})?$/;
  numbersLettersHyphenDotSpaceAndApostropheString = /^[A-Za-z0-9-. ']+$/;
  onlyIntegersAndLettersRegexString = /^[A-Za-z0-9]*([a-zA-Z]+[0-9]+|[0-9]+[a-zA-Z]+)*([0-9])?$/;
  numbersLettersAndHyphenRegexString = /^[A-Za-z0-9-]+$/;
  public phoneRegexString = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
  validatorList: any[] = [];
  mcGod = McGod.getInstance();
  // textBoxForm: FormGroup;
  cssClass!: string;
  formValidationTriggerSubscription!: Subscription;

  // BMITIC: Talk to me before enabling any additional inputs
  /*Events*/
  @Input() extraCssClasses: any;
  @Input() label: any;
  @Input() additionalDescription!: string;
  @Input() value: any;
  @Input() name!: string;
  @Input() mcForm!: McForm;
  @Input() placeholder = '';
  @Input() readOnlyFlg = false;
  @Input() validRequiredFlg = false;
  @Input() validPattern!: string;
  @Input() validMinLength!: number;
  @Input() validMaxLength!: number;
  @Input() validDecimalNumber!: boolean;
  @Input() validIntegerNumber!: boolean;
  @Input() validIntegerAndDecimalNumber!: boolean;
  @Input() validOnlyIntegersAndLetters!: boolean;
  @Input() validOnlyNumbersLettersAndHyphen!: boolean;
  @Input() validOnlyNumbersLettersHyphenDotSpaceAndApostrophe!: boolean;
  @Input() validWhitespaces!: boolean;
  @Input() validMinNumber!: number;
  @Input() validMaxNumber!: number;
  @Input() validEmail!: boolean;
  @Input() validPhone!: boolean;
  @Input() size = 45;
  @Input() validNumberWithTwoDecimals!: boolean;
  @Input() removeControlFromMcFormOnDestroy = true;
  @Input() txbSubject = new Subject<boolean>();
  @Input() extraCssClass!: any;
  @Input() formExtraCssClass: any;
  @Input() isClearEnabled = false;
  @Input() infoMsg!: string;
  @Input() config!: FiTextboxConfig;
  @Input() disableInputFlg = false;
  @Input() newStyleInput = false;
  @Output() eventValueChanged = new EventEmitter();
  @Output() eventAfterValidation = new EventEmitter();
  @Output() eventEnterClicked = new EventEmitter();

  constructor() {
  }

  getTextboxForm(): FormGroup {
    return <FormGroup><unknown>!this.config && this.mcForm.getControl(this.name) as FormGroup;
  }

  setCurrentValue() {
    if (!this.getTextboxForm()) {
      const textBoxForm = this.mcForm.formBuilder.group({
        value: [this.value, this.validatorList],
        label: [this.label],
        errorFlg: [false],
        errorMsg: ['']
      });

      if (this.mcForm) {
        this.mcForm.addControl(this.name, textBoxForm);
      }
    } else {
      this.getTextboxForm().controls['value'].setValue(this.value);
      this.getTextboxForm().controls['label'].setValue(this.label);
      this.getTextboxForm().controls['errorFlg'].setValue(false);
      this.getTextboxForm().controls['errorMsg'].setValue('');
    }
    this.disableInputFlg ? this.getTextboxForm().get('value')?.disable() : this.getTextboxForm().get('value')?.enable();
  }

  // ---------------------------------------------------------------------
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["value"]) {
      if ((changes['value'].previousValue !== changes['value'].currentValue)) {
        this.setCurrentValue();
      }
    }
    if (changes["validRequiredFlg"]) {
      if ((changes["validRequiredFlg"].previousValue !== changes['validRequiredFlg'].currentValue)) {
        this.validationSetup();
        if (this.getTextboxForm()) {
          this.getTextboxForm().controls['value'].setValidators(this.validatorList);
          this.getTextboxForm().controls['errorFlg'].setValue(false);
          this.getTextboxForm().controls['errorMsg'].setValue('');
        }
      }
    }
    if (changes['disableInputFlg'] && this.getTextboxForm()) {
      changes['disableInputFlg'].currentValue ? this.getTextboxForm().get('value')?.disable() : this.getTextboxForm().get('value')?.enable();
    }
  }

  ngOnInit() {
    if (this.mcForm && !this.config) {
      this.formValidationTriggerSubscription = this.mcForm.getValidationTriggerObservable().subscribe(() => {
        this.validateTextBox();
      });
    }

    if (!this.config) {
      this.validationSetup();
      this.setCurrentValue();
    } else {
      this.applyConfigToComponent();
    }
  }

  ngOnDestroy(): void {
    if (this.formValidationTriggerSubscription) {
      this.formValidationTriggerSubscription.unsubscribe();
    }

    if (this.removeControlFromMcFormOnDestroy) {
      this.mcForm.formGroup.removeControl(this.name);
    }
  }

  public applyConfigToComponent() {
    // @ts-ignore
    Object.keys(this.config).forEach(key => this[key] = this.config[key]);
  }



  validationSetup() {
    this.validatorList = [];

    if (this.validRequiredFlg) {
      this.requiredFlg = Validators.required;
      this.validatorList.push(this.requiredFlg);
    }

    if (this.validPattern) {
      this.pattern = Validators.pattern(this.validPattern);
      this.validatorList.push(this.pattern);
    }

    if (this.validMinLength) {
      this.minLength = Validators.minLength(this.validMinLength);
      this.validatorList.push(this.minLength);
    }

    if (this.validMaxLength) {
      this.maxLength = Validators.maxLength(this.validMaxLength);
      this.validatorList.push(this.maxLength);
    }

    if (this.validEmail) {
      this.emailRegex = Validators.pattern(this.emailRegexString);
      this.validatorList.push(this.emailRegex);
    }

    if (this.validPhone) {
      this.phoneRegex = Validators.pattern(this.phoneRegexString);
      this.validatorList.push(this.phoneRegex);
    }

    if (this.validMinNumber) {
      this.validMin = Validators.min(this.validMinNumber);
      this.validatorList.push(this.validMin);
    }

    if (this.validMaxNumber) {
      this.validMax = Validators.max(this.validMaxNumber);
      this.validatorList.push(this.validMax);
    }

    /*if (this.validMinNumber) {
      this.validMin = Validators.min(this.validMinNumber);
      this.validatorList.push(this.validMin);
    }

    if (this.validMaxNumber) {
      this.validMax = Validators.max(this.validMaxNumber);
      this.validatorList.push(this.validMax);
    }*/

    if (this.validDecimalNumber) {
      this.numberDecimalRegex = Validators.pattern(this.numberDecimalRegexString);
      this.validatorList.push(this.numberDecimalRegex);
    }
    if (this.validIntegerNumber) {
      this.numberIntegerRegex = Validators.pattern(this.numberIntegerRegexString);
      this.validatorList.push(this.numberIntegerRegex);
    }
    if (this.validOnlyIntegersAndLetters) {
      this.onlyIntegersAndLettersRegex = Validators.pattern(this.onlyIntegersAndLettersRegexString);
      this.validatorList.push(this.onlyIntegersAndLettersRegex);
    }

    if (this.validIntegerAndDecimalNumber) {
      this.numberIntegerAndDecimalRegex = Validators.pattern(this.numberIntegerAndDecimalRegexString);
      this.validatorList.push(this.numberIntegerAndDecimalRegex);
    }
    if (this.validWhitespaces) {
      this.whiteSpaceRegex = Validators.pattern(this.whitespaceRegexString);
      this.validatorList.push(this.whiteSpaceRegex);
    }

    if (this.validNumberWithTwoDecimals) {
      this.numberWithTwoDecimalsRegex = Validators.pattern(this.numberWithTwoDecimalsRegexString);
      this.validatorList.push(this.numberWithTwoDecimalsRegex);
    }

    if (this.validOnlyNumbersLettersAndHyphen) {
      this.numbersLettersAndHyphenRegex = Validators.pattern(this.numbersLettersAndHyphenRegexString);
      this.validatorList.push(this.numbersLettersAndHyphenRegex);
    }

    if (this.validOnlyNumbersLettersHyphenDotSpaceAndApostrophe) {
      this.numbersLettersHyphenDotSpaceAndApostropheRegex = Validators.pattern(this.numbersLettersHyphenDotSpaceAndApostropheString);
      this.validatorList.push(this.numbersLettersHyphenDotSpaceAndApostropheRegex)
    }

    /*   if (this.customValidator) {
         this.validatorList.push(this.customValidator);
       }
   */
  }

  public validateTextBox() {
    this.validationSetup();
    this.getTextboxForm().controls['value'].setValidators(this.validatorList);
    this.getTextboxForm().get('value')?.updateValueAndValidity();
    if (this.getTextboxForm().get('value')?.errors) {
      // @ts-ignore
      const validators = Object.keys(this.getTextboxForm().get('value')?.errors);
      if (validators) {
        this.getTextboxForm().get('errorFlg')?.setValue(true);
        validators.forEach(item => {
          switch (item) {
            case 'required':
              this.getTextboxForm().get('errorMsg')?.setValue(this.mcGod.t('cc.common.this-field-is-required'));
              break;
            case 'minlength':
              this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.length-must-be-at-least')} ${this.validMinLength}`);
              break;
            case 'maxlength':
              this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.length-must-be-under')} ${this.validMaxLength}`);
              break;
            case 'min':
              this.getTextboxForm().get('errorMsg')?.setValue(`Number must be greater than ${this.validMinNumber}`);
              break;
            case 'max':
              this.getTextboxForm().get('errorMsg')?.setValue(`Number must be less than ${this.validMaxNumber}`);
              break;
            case 'pattern':
              switch (true) {
                case (this.numberDecimalRegex && !this.numberDecimalRegexString.test(this.getTextboxForm().get('value')?.value)):
                  this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.this-field-is-numeric-and-accepts-whole-numbers-integers-and-decimal-values-with-dot')} (.).`);
                  break;
                case (this.numberIntegerRegex && !this.numberIntegerRegexString.test(this.getTextboxForm().get('value')?.value)):
                  this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.this-field-is-numeric-and-accepts-whole-numbers-integers')}.`);
                  break;
                case (this.numberIntegerAndDecimalRegex && !this.numberIntegerAndDecimalRegexString.test(this.getTextboxForm().get('value')?.value)):
                  this.getTextboxForm().get('errorMsg')?.setValue(this.mcGod.t('cc.common.this-field-is-numeric-and-accepts-whole-numbers-integers-and-decimal-values-with-dot'));
                  break;
                case (this.whiteSpaceRegex && !this.whitespaceRegexString.test(this.getTextboxForm().get('value')?.value)):
                  this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.no-whitespaces-allowed')}`);
                  break;
                case (this.emailRegex && !this.emailRegexString.test(this.getTextboxForm().get('value')?.value)):
                  this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.please-enter-valid-email')}.`);
                  break;
                case (this.phoneRegex && !this.phoneRegexString.test(this.getTextboxForm().get('value')?.value)):
                  this.getTextboxForm().get('errorMsg')?.setValue(`Please enter valid phone number`);
                  break;
                case (this.numberWithTwoDecimalsRegex && !this.numberWithTwoDecimalsRegexString.test(this.getTextboxForm().get('value')?.value)):
                  this.getTextboxForm().get('errorMsg')?.setValue(this.mcGod.t('cc.common.this-field-is-numeric-and-accepts-whole-numbers-integers-and-decimal-values-with-dot'));
                  break;
                case (this.onlyIntegersAndLettersRegex && !this.onlyIntegersAndLettersRegexString.test(this.getTextboxForm().get('value')?.value)):
                  this.getTextboxForm().get('errorMsg')?.setValue(this.mcGod.t('This field only accepts whole numbers and letters.'));
                  break;
                case (this.numbersLettersAndHyphenRegex && !this.numbersLettersAndHyphenRegexString.test(this.getTextboxForm().get('value')?.value)):
                  this.getTextboxForm().get('errorMsg')?.setValue(this.mcGod.t('This field only accepts whole numbers, letters and hyphen.'));
                  break;
                case (this.numbersLettersHyphenDotSpaceAndApostropheRegex && !this.numbersLettersHyphenDotSpaceAndApostropheString.test(this.getTextboxForm().get('value')?.value)):
                  this.getTextboxForm().get('errorMsg')?.setValue(this.mcGod.t('This field only accepts numbers, letters, hyphen, dot, space and apostrophe.'));
                  break;
                default:
                  this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.invalid-value')}`);
                  break;
              }
          }
        });
      }
    } else {
      this.getTextboxForm().get('errorFlg')?.setValue(false);
      this.getTextboxForm().get('errorMsg')?.setValue(``);
    }
    this.fireEventAfterValidation();
  }

  public fireEventAfterValidation() {
    if (this.eventAfterValidation) {
      const eventObj = new MccFiTextboxEventAfterValidation();
      eventObj.validFlg = !this.getTextboxForm().get('value')?.errors;

      this.eventAfterValidation.emit(eventObj);
    }
  }

  clearErrors() {
    this.getTextboxForm().get('errorFlg')?.setValue(false);
    this.getTextboxForm().get('errorMsg')?.setValue('');
  }

  onTextBoxChanged(value: any) {
    this.clearErrors();
    if (this.eventValueChanged) {
      this.eventValueChanged.emit(value);
    }
  }

  enterClicked() {
    this.eventEnterClicked.emit();
  }

  trim(el: { value: string; }) {
    this.mcForm.getControl(this.name)?.get('value')?.setValue(el.value.trim());
  }

  clearTextbox() {
    this.mcForm.getControl(this.name)?.get('value')?.setValue('');
  }

}
