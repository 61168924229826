import {Component, ChangeDetectorRef, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {Directionality} from '@angular/cdk/bidi';
import {CdkStepper} from '@angular/cdk/stepper';
import {McUtilityService} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'lib-mc-stepper',
  templateUrl: './mc-stepper.component.html',
  providers: [{provide: CdkStepper, useExisting: McStepperComponent}]
})
export class McStepperComponent extends CdkStepper implements OnInit {

  /*Events*/
  @Input() isDataFormInvalid!: boolean;
  @Output() selectedStep = new EventEmitter<number>();
  @ViewChild("viewChild") viewChild: ElementRef<HTMLElement>;

  /*Private variables*/
  private goToStepSubscription!: Subscription;

  // constructor(dir: Directionality,
  //             changeDetectorRef: ChangeDetectorRef,
  //             private utilityService: McUtilityService
  // ) {
  //   super(dir, changeDetectorRef, document.getElementById("viewChild"), null);
  // }

  ngOnInit(): void {
    this.isDataFormInvalid = true;

    /*Get step number*/
    // this.goToStepSubscription = this.utilityService._goToStep.subscribe(stepNumber => {
    //   if (stepNumber && !isNaN(stepNumber)) {
    //     this.selectedIndex = stepNumber - 1;
    //   }
    // });
  }

  onClick(index: number): void {
    this.selectedIndex = index;
  }

  stepBack() {
    this.isDataFormInvalid = false;
    this.selectedStep.emit(this.selectedIndex);
  }

  stepForward() {
    this.selectedStep.emit(this.selectedIndex);
  }

  validateUserForm() {
    this.isDataFormInvalid = true;
  }


}
