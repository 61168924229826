import {EbPaymentReport} from './eb-payment-report.model';
import {EbPaymentReportListGENERATED} from '../_generated/eb-payment-report-list-generated.model';

export class EbPaymentReportList extends EbPaymentReportListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbPaymentReportList();
  }

  // ---------------------------------------------------------------------
}
