<ng-template #localAttributes let-modal>

  <!--Modal body section-->
  <div class="modal-body">
    <h5 class="modal-title mc-local-attributes-title mb-2" id="modal-attributes-title">{{editAttribute ? 'Edit attribute' : 'Create attribute'}}</h5>
    <form [formGroup]="entityLocalAttributesForm" class="mc-entity-attributes-form">
      <div class="mc-create-entity-notify-wrapper">
        <mc-notify-message [message]="message" [type]="type"></mc-notify-message>
      </div>
      <div class="row">
        <!--Is enabled-->
        <div class="col-md-12 enabled">
          <div>
            <span class="mr-3 mc-entity-label">{{ 'cc.common.edit.enabled' | translate }}: <i class="required-input">*</i></span>
            <span class="mr-4 form-group">
              <input type="radio" id="activeEn" [value]=true name="enabled" formControlName="enabled">
              <label for="activeEn">Yes</label>
            </span>
            <span class="mr-4 form-group">
              <input type="radio" id="inactiveEn" [value]=false name="enabled" formControlName="enabled">
              <label for="inactiveEn">No</label>
            </span>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <!--Name-->
        <div class="col-md-6">
          <div class="form-group">
            <label for="localAttributeName" class="label-position mc-entity-label">{{'cc.common.view.name' | translate}} <i class="required-input">*</i></label>
            <input type="text"
                   name="name"
                   class="form-control"
                   [ngClass]="{'is-invalid' : this.existAttributeName === true || this.entityLocalAttributesForm.get('name')?.touched && this.entityLocalAttributesForm.get('name')?.invalid}"
                   id="localAttributeName"
                   formControlName="name"
                   #attributeName
                   (ngModelChange)="isFormValid(attributeName.value)"
                   required>
          </div>
        </div>
        <!--Length-->
        <div class="col-md-6">
          <label for="length" class="label-position mc-entity-label">{{ 'cc.common.edit.length' | translate }}</label>
          <input type="number"
                 name="length"
                 class="form-control"
                 [ngClass]="{'is-invalid' : this.isLengthValid === false}"
                 id="length"
                 formControlName="length"
                 min="0"
                 max="255"
                 (ngModelChange)="isFormValid(null)"
                 onkeypress="return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57">
        </div>
      </div>
      <div class="row">
        <!--Type-->
        <div class="col-md-6">
          <!--SelectBox channel types-->
          <div class="form-group">
            <label for="type" class="mc-entity-label">{{'cc.jobs.edit.type' | translate}} <i class="required-input">*</i></label>
            <select class="form-control" id="type" name="type" formControlName="type" [ngClass]="{'is-invalid' : this.entityLocalAttributesForm.get('type')?.touched && this.entityLocalAttributesForm.get('type')?.invalid}" (ngModelChange)="onChangeType($event);isFormValid(null)">
              <option *ngFor="let type of postgresAttributeTypes" [value]="type.name">{{type.name}}</option>
            </select>
          </div>
        </div>
        <!--Validation-->
        <div class="col-md-6">
          <div class="form-group">
            <label for="validationRegex" class="label-position mc-entity-label">{{'cc.common.edit.validation' | translate }}</label>
            <input type="text"
                   name="validationRegex"
                   class="form-control"
                   id="validationRegex"
                   formControlName="validationRegex">
          </div>
        </div>
      </div>
      <div class="row">
        <!--Description-->
        <div class="col-md-6">
          <div class="form-group">
            <label for="description" class="label-position mc-entity-label">Description</label>
            <textarea type="text"
                      rows="4"
                      name="description"
                      class="form-control"
                      id="description"
                      formControlName="description">
            </textarea>
          </div>
        </div>
        <!--Radio btn-groups-->
        <div class="col-md-6">
          <!--Is inheritable-->
          <div class="row inheritable-radio-btns-group">
            <div class="col-md-12 inheritable">
              <span class="mc-entity-label">{{ 'cc.common.edit.inheritable' | translate }}: <i class="required-input">*</i></span>
              <div class="inheritable-wrapper">
                <span class="mr-4 form-group">
                  <input type="radio" id="activeIn" [value]=true name="inheritable" formControlName="inheritable">
                  <label for="activeIn">Yes</label>
                </span>
                <span class="mr-4 form-group">
                  <input type="radio" id="inactiveIn" [value]=false name="inheritable" formControlName="inheritable">
                  <label for="inactiveIn">No</label>
                </span>
              </div>
            </div>
          </div>
          <!--Is required-->
          <div class="row">
            <div class="col-md-12 required">
              <span class="mc-entity-label">Required: <i class="required-input">*</i></span>
              <div class="required-wrapper">
                <span class="mr-4 form-group">
                  <input type="radio" id="activeRe" [value]=true name="required" formControlName="required">
                  <label for="activeRe">Yes</label>
                </span>
                <span class="mr-4 form-group">
                  <input type="radio" id="inactiveRe" [value]=false name="required" formControlName="required">
                  <label for="inactiveRe">No</label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Default value-->
      <div class="row">
        <div class="col-md-6">
          <label class="custom-control fill-checkbox" [ngClass]="{'disable-restore-btn' : entityLocalAttributesForm.get('type')?.value === ''}">
            <input
              type="checkbox"
              class="fill-control-input"
              id="defaultValueSelect"
              name="defaultValueSelect"
              formControlName="defaultValueSelect"
              [checked]="editAttribute?.defaultValue"
              #defaultValueSelect
              (ngModelChange)="checkDefaultValue(defaultValueSelect.checked)"
            />
            <span class="fill-control-indicator checkbox-attributes-position" [ngClass]="{'disable-checkbox' : entityLocalAttributesForm.get('type')?.value === ''}"></span>
            <span class="fill-control-description">{{ 'cc.common.view.default-value' | translate }}</span>
          </label>
          <div *ngIf="defaultSelected" [ngSwitch]="entityLocalAttributesForm.get('type')?.value">
            <!--If type is number-->
            <div *ngSwitchCase="'Number'">
              <input type="number"
                     id="defaultNumber"
                     name="defaultValue"
                     class="form-control"
                     formControlName="defaultValue"
                     onkeypress="if(event.charCode == 46){event.preventDefault();}">
            </div>
            <!--If type is string-->
            <div *ngSwitchCase="'String'" class="form-group">
              <input type="text"
                     name="defaultValue"
                     class="form-control"
                     id="defaultText"
                     formControlName="defaultValue">
            </div>
            <!--If type is Date Time-->
            <div *ngSwitchCase="'Date Time'">
              <input
                type="date"
                id="defaultValueDate"
                class="form-control mc-dates-inputs"
                name="defaultValue"
                formControlName="defaultValue"
                placeholder="yyyy-mm-dd"
                autocomplete="off"
                readonly
                #dateField="ngbDatepicker"
                (click)="dateField.toggle()"
                [minDate]="minDate"
                [maxDate]="maxDate"
                ngbDatepicker>
            </div>
            <!--If type is decimal-->
            <div *ngSwitchCase="'Decimal'">
              <input type="number"
                     placeholder="0.00"
                     id="defaultDecimalNumber"
                     name="defaultValue"
                     class="form-control"
                     formControlName="defaultValue"
                     #decimalNum
                     step="0.01"
                     (change)="setTwoNumberDecimal(decimalNum.value, 1)">
            </div>
            <!--If type is Yes/No-->
            <div *ngSwitchCase="'Yes/No'" class="form-group">
              <select class="form-control" id="DefaultValueYes" name="defaultValue" formControlName="defaultValue">
                <option value="Yes">{{ 'cc.common.edit.yes' | translate }}</option>
                <option value="No">{{ 'cc.common.edit.no' | translate }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="close mc-close-local-attribute-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">{{ 'cc.common.edit.cancel' | translate }}</span>
          </button>
          <button type="button" class="modal-save mc-save-local-attribute-btn" [ngClass]="{'mc-disable-btn' : !isEnabled}" (click)="setValues();modal.dismiss('Cross click')" [disabled]="!isEnabled">{{editAttribute ? 'Save' : 'Add attribute'}}</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<a *ngIf="!editAttribute" class="mc-create-entity-btn" (click)="openModal(localAttributes)">
  <i class="fas fa-plus-circle"></i>
  {{ 'cc.common.view.create-attribute' | translate }}
</a>

<i *ngIf="editAttribute" class="fa fa-pen mc-pen-icon mc-blue-text pr-0" (click)="openModal(localAttributes)"></i>
