import {Component, Input} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mc-bank-account-full-name',
  templateUrl: './mc-bank-account-full-name.component.html',
})
export class McBankAccountFullNameComponent {

  @Input() bankAccount: any;

}
