import {NgModule} from "@angular/core";
import {CsrContractHistoryOverviewComponent} from "./components/csr-contract-history-overview/csr-contract-history-overview.component";
import {MkMatTableModule} from "@miticon-ui/mc-components";
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [CsrContractHistoryOverviewComponent],
  exports: [CsrContractHistoryOverviewComponent],
  imports: [
    MkMatTableModule,
    CommonModule,
  ]
})
export class CsrContractModule {}
