/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {ImDataExportTemplate} from '../models/im-data-export-template.model';
import {ImDataExportTemplateService} from '../services/im-data-export-template.service';
import {IImDataExportTemplate} from '../_generated/im-data-export-template.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class ImDataExportTemplateGENERATED extends BaseObject {


    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_INACTIVE: string = 'INACTIVE';
    public static readonly STATUS_CD_PENDING: string = 'PENDING';
  public static readonly STATUS_CD_LIST__ALL = [
    ImDataExportTemplateGENERATED.STATUS_CD_ACTIVE,
    ImDataExportTemplateGENERATED.STATUS_CD_INACTIVE,
    ImDataExportTemplateGENERATED.STATUS_CD_PENDING
];

  public apiService: ImDataExportTemplateService;
  public _rawJson: IImDataExportTemplate;
    id: number = 0;
  description = '';
  reportName = '';
  sqlReport = '';
  statusCd: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;


  public properties: string[] = ['id', 'description', 'reportName', 'sqlReport', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId'];
  public propertiesRegular: string[] = ['id', 'description', 'reportName', 'sqlReport', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId'];
  public propertiesSpecial: string[] = [];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(ImDataExportTemplateGENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(ImDataExportTemplateGENERATED.STATUS_CD_INACTIVE, McGod.t('Inactive'));
    list.add(ImDataExportTemplateGENERATED.STATUS_CD_PENDING, McGod.t('Pending'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = ImDataExportTemplateGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): ImDataExportTemplate {
    console.error('MTCN-ERROR: Not Implemented: ImDataExportTemplate::createNewInstance(). Add this method to final class and return new ImDataExportTemplate();');
    throw new Error('Cannot ImDataExportTemplate::createNewInstance(). Add this method to final class and return new ImDataExportTemplate();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IImDataExportTemplate): ImDataExportTemplate {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(ImDataExportTemplateService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE ImDataExportTemplate AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return ImDataExportTemplateGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === ImDataExportTemplateGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdINACTIVE(): boolean {
    const result = (this.statusCd === ImDataExportTemplateGENERATED.STATUS_CD_INACTIVE);

    return result;
  }

 public  isStatusCdPENDING(): boolean {
    const result = (this.statusCd === ImDataExportTemplateGENERATED.STATUS_CD_PENDING);

    return result;
  }


  
}
