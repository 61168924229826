import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'mcc-fi-submit',
  templateUrl: './mcc-fi-submit.component.html'
})
export class MccFiSubmitComponent {

  /*Events*/
  @Input() label!: any;
  @Input() name!: any;
  @Input() frmGroup!: FormGroup;
  @Output() onClicked = new EventEmitter();


}
