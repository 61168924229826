<div class="mcc-fi mcc-fi-textbox-with-token mcc-fi-textbox-with-token-{{name}} {{extraCssClasses}}"
     [ngClass]="{'validation-error' : this.getTextboxForm().get('errorFlg')?.value}">
  <form [formGroup]="getTextboxForm()">
    <label *ngIf="label" for="mcc-fi-{{name}}" [ngClass]="{'mc-mandatory-star' : validRequiredFlg}">{{label}}</label>
    <div class="d-flex align-items-center">

      <div class="d-flex flex-column mc-width-fit-content">

        <div class="col my-2 mc-text-align-right p-0">
          <div ngbDropdown class="d-inline-block">
            <button class="system-action-btn" ngbDropdownToggle><i class="fas fa-code"></i> {{tokenLabel}}</button>
            <div ngbDropdownMenu>
              <button *ngFor=" let item of tokenVll.items" (click)="insertToken(item.value)"
                      ngbDropdownItem>{{item.label}}</button>
            </div>
          </div>
        </div>

        <input type="text"
               #textboxValue
               id="mcc-fi-{{name}}"
               name="txb-{{name}}"
               size="{{size}}"
               formControlName="value"
               ng-keydown="$event.keyCode != 32 ? $event:$event.preventDefault()"
               [placeholder]="placeholder"
               [required]="validRequiredFlg"
               [readOnly]="readOnlyFlg"
               (keyup.enter)="enterClicked()"
               (ngModelChange)="onTextBoxChanged($event)">
      </div>
      <span class="pl-2" *ngIf="additionalDescription">{{additionalDescription}}</span>
    </div>
    <div class="error-msg">{{this.getTextboxForm().get('errorMsg')?.value}}</div>
  </form>
</div>
