<form [formGroup]="uploadForm">
  <div class="card">
    <h2 class="title-medium-dark mb-large">
      {{ "cc.common.upload-file" | translate }}
    </h2>
    <div class='input-wrapper'>
      <mat-form-field appearance="outline">
        <mat-label>{{ "mem.config.data-set" | translate }}*</mat-label>
        <mat-select panelClass="dialog-select"
                    formControlName="dataSet"
                    disableOptionCentering>
          <mat-option *ngFor="let dataSet of dataSets"
                      (click)="setDataSet(dataSet)"
                      [value]="dataSet.id">
            {{ dataSet.name | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "mem.common.upload-file" | translate}}*</mat-label>
        <input matInput
               type="text"
               [value]='fileName'
               (click)="input.click()"
               accept=".csv, .xml, .json"
               readonly/>
        <span class='input-inner-icon'
              (click)="input.click()"
              [innerHTML]="iconUpload | safeHtml">
        </span>
      </mat-form-field>
      <input #input type='file' hidden
             formControlName="file"
             (change)="inputChange($event)"
             accept=".csv, .xml, .json"/>

      <mat-form-field class="disabled-input" appearance="outline">
        <mat-label>{{ "mem.data-import.source" | translate }}</mat-label>
        <input type="text"
               formControlName="source"
               readonly
               matInput/>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "mem.import.upload.custom-name" | translate }}*</mat-label>
        <input type="text"
               formControlName="customName"
               matInput/>
      </mat-form-field>

      <mat-form-field *ngIf="file && file.type === 'text/csv'" appearance="outline">
        <mat-label>{{ "cc.consumers.consumers-overview.delimiter" | translate }}*</mat-label>
        <mat-select panelClass="dialog-select"
                    formControlName="delimiter"
                    (valueChange)="setDelimiter($event, true)"
                    disableOptionCentering>
          <mat-option *ngFor="let delimiter of delimiters" [value]="delimiter.value">
            {{ delimiter.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="file && file.type === 'text/csv' && checkDelimiter()" appearance="outline">
        <mat-label>{{ "mem.import.upload.other-delimiter" | translate }}*</mat-label>
        <input type="text"
               maxlength="1"
               (keypress)="setDelimiter($event, false)"
               formControlName="otherDelimiter"
               matInput/>
      </mat-form-field>

      <mat-form-field *ngIf="file && file.type === 'text/csv'" appearance="outline">
        <mat-label>{{ "cc.consumers.consumers-overview.encoding" | translate }}*</mat-label>
        <mat-select panelClass="dialog-select"
                    formControlName="encoding"
                    disableOptionCentering>
          <mat-option *ngFor="let encoding of encodings" [value]="encoding.value">
            {{ encoding.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="mt-medium ml-medium" *ngIf="file && file.type === 'text/csv'">
      <mat-checkbox formControlName="header">
        {{ "mem.import.upload.first-row-header" | translate }}
      </mat-checkbox>
    </div>
    <div class="card req_att" *ngIf="dataSet && requiredAttributes">
      <span [innerHTML]="iconAttribute | safeHtml"></span>
      <div class="req_att_item">
        <span>{{ "mem.import.upload.req-fields" | translate }}:</span>
        <span *ngFor="let attribute of requiredAttributes; let last = last; let first = first">
          {{ attribute.name + (!last ? ',' : '') }}
        </span>
      </div>
    </div>
    <info-bar-message *ngIf='fileContent && fileContent.length <= 1' [errorBar]=true message='mem.data-import.empty-file'></info-bar-message>
    <info-bar-message *ngIf='(file && file.type === "text/csv" && isDelimiterSelected() ? !validDelimiter : false)' [errorBar]=true message='mem.data-import.invalid-delimiter'></info-bar-message>

    <div *ngIf="file && fileContent && fileContent.length > 1 && (file.type === 'text/csv' ? validDelimiter : true)">
      <div class="divider"></div>
      <h2 class="title-medium-dark mb-smallest">{{ "mem.import.file-preview" | translate }}</h2>
      <div class="mb-large">{{ "mem.import.file-preview-five-rows" | translate }}</div>
      <div class="table-wrapper">
        <table>
          <thead><tr><th *ngFor="let header of csvHeaders">{{ header }}</th></tr></thead>
          <tbody><tr *ngFor="let row of csvData"><td *ngFor="let cell of row">{{ cell }}</td></tr></tbody>
        </table>
      </div>
    </div>
  </div>
</form>
