import { McBankModuleIncomingPaymentsPotentialTransactionMatchesComponent } from './pages/mc-bank-module-incoming-payments-potential-transaction-matches/mc-bank-module-incoming-payments-potential-transaction-matches.component';
import { McBankModuleIncomingPaymentsListPageComponent } from './pages/mc-bank-module-incoming-payments-list-page/mc-bank-module-incoming-payments-list-page.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {
  McBankModuleSepaStatementReportListPageComponent
} from './pages/mc-bank-module-sepa-statement-report-list-page/mc-bank-module-sepa-statement-report-list-page.component';
import {
  EbBankModulePsd2StatementListPageComponent
} from "./pages/eb-bank-module-psd2-statement-list-page/eb-bank-module-psd2-statement-list-page.component";
import {FinanceListPageComponent} from "./pages/finance-list-page/finance-list-page.component";

const routes: Routes = [
  {
    path: 'incoming-payments-potential-transaction-matches/:id',
    component: McBankModuleIncomingPaymentsPotentialTransactionMatchesComponent
  },
  {
    path: 'incoming-payments',
    component: McBankModuleIncomingPaymentsListPageComponent
  },
  {
    path: 'star-money',
    component: McBankModuleSepaStatementReportListPageComponent
  },
  {
    path: 'psd2-statements',
    component: EbBankModulePsd2StatementListPageComponent
  },
  {
    path: 'finance',
    component: FinanceListPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McBankModuleRoutingModule {
}
