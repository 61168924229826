import { McOutFactoringInkassoExportListPageComponent } from './pages/mc-out-factoring-inkasso-export-list-page/mc-out-factoring-inkasso-export-list-page.component';
import { McOutFactoringSepaExportPartComponent } from './components/mc-out-factoring-sepa-export-part/mc-out-factoring-sepa-export-part.component';
import { McOutFactoringBlacklistAddEditPartComponent } from './components/mc-out-factoring-blacklist-add-edit-part/mc-out-factoring-blacklist-add-edit-part.component';
import { McOutFactoringBlacklistListPageComponent } from './pages/mc-out-factoring-blacklist-list-page/mc-out-factoring-blacklist-list-page.component';
import { McOutFactoringTransactionListPageComponent } from './pages/mc-out-factoring-transaction-list-page/mc-out-factoring-transaction-list-page.component';
import { McOutFactoringSepaOutListPageComponent } from './pages/mc-out-factoring-sepa-out-list-page/mc-out-factoring-sepa-out-list-page.component';
import { McOutFactoringSepaInListPageComponent } from './pages/mc-out-factoring-sepa-in-list-page/mc-out-factoring-sepa-in-list-page.component';
import { McOutFactoringContractListPageComponent } from './pages/mc-out-factoring-contract-list-page/mc-out-factoring-contract-list-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McOutFactoringRoutingModule } from './mc-out-factoring-routing.module';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MccFiFileUploadModule, McComponentsModule} from '@miticon-ui/mc-components';
import {McFilterModule, McTableModule, UploadFileModule} from '@miticon-ui/mc-components';
import {TranslateModule} from '@ngx-translate/core';
import {McManagementModule} from '../mc-management/mc-management.module';
import {AppSharedModule} from '../app-shared/app-shared.module';
import {McConsumerModule} from '../mc-consumer/mc-consumer.module';

@NgModule({
  declarations: [
McOutFactoringInkassoExportListPageComponent,
McOutFactoringSepaExportPartComponent,
McOutFactoringBlacklistAddEditPartComponent,
McOutFactoringBlacklistListPageComponent,
    McOutFactoringTransactionListPageComponent,
    McOutFactoringSepaOutListPageComponent,
    McOutFactoringSepaInListPageComponent,
    McOutFactoringContractListPageComponent,
  ],
    imports: [
        CommonModule,
        McOutFactoringRoutingModule,
        NgbModule,
        McComponentsModule,
        McFilterModule,
        McTableModule,
        McManagementModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AppSharedModule,
        McComponentsModule,
        McConsumerModule,
        MccFiFileUploadModule,
      UploadFileModule
    ],
  exports: [
McOutFactoringInkassoExportListPageComponent,
McOutFactoringSepaExportPartComponent,
McOutFactoringBlacklistAddEditPartComponent,
McOutFactoringBlacklistListPageComponent,
McOutFactoringTransactionListPageComponent,
McOutFactoringSepaOutListPageComponent,
McOutFactoringSepaInListPageComponent,
McOutFactoringContractListPageComponent,
    McOutFactoringRoutingModule,
    FormsModule,

  ]
})
export class McOutFactoringModule { }
