/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CsrPaymentDetailsTable3ConfigGENERATED} from '../_generated/csr-payment-details-table-3-config-generated.model';

// import {CsrPaymentDetailsGENERATED} from '../_generated/csr-payment-details-generated.model';

export class CsrPaymentDetailsTable3Config extends CsrPaymentDetailsTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdDdApproval', 'Direct debit approval id', 'idDdApproval', 'idDdApproval');
    this.addColumn('colIdWebfileDdApproval', 'Direct debit approval webfile Id', 'idWebfileDdApproval', 'idWebfileDdApproval');
    this.addColumn('colPaymentDeadlineInDays', 'Payment deadline in days', 'paymentDeadlineInDays', 'paymentDeadlineInDays');
    this.addColumn('colTypeCd', 'Statuses', 'typeCd', 'typeCd');


  }
}
