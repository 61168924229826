import {CsrAccountConsumerFilterGENERATED} from '../_generated/csr-account-consumer-filter-generated.model';

export class CsrAccountConsumerFilter extends CsrAccountConsumerFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrAccountConsumerFilter();
  }

  // ---------------------------------------------------------------------
}
