import {ImDataMappingItem} from './im-data-mapping-item.model';
import {ImDataMappingItemListGENERATED} from '../_generated/im-data-mapping-item-list-generated.model';

export class ImDataMappingItemList extends ImDataMappingItemListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataMappingItemList();
  }

  // ---------------------------------------------------------------------
}
