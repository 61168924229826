import {Component, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup} from "@angular/forms";
import {McAuthService, McEntityService, McPriceList, McUtilityService} from "@miticon-ui/mc-core";

@Component({
  selector: "mc-create-entity-wrapper",
  templateUrl: "./mc-create-entity-wrapper.component.html"
})
export class McCreateEntityWrapperComponent implements OnInit {

  isEntityFormValid!: boolean;
  message!: string;
  type!: string;
  passwordPolicyObject!: any;
  inheritedAttributesList!: any;
  parentEntityId!: any;
  imagePath: any;
  pricelist!: McPriceList;
  createEntityForm: FormGroup;
  allRawData: any;

  @Output() updateEntity = new EventEmitter<boolean>();

  @ViewChild("openModalBtn") openModalBtn!: HTMLAnchorElement;

  constructor(
    private authService: McAuthService,
    private entityService: McEntityService,
    private utilityService: McUtilityService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {
    this.createEntityForm = new FormGroup({});
  }

  ngOnInit() {
    this.createForm();
    this.allRawData = this.createEntityForm.getRawValue();
    // Get parent entity ID
    this.getEntityFromDb().then(() => this.getCustomAttributes());
    // Get parent password policy
    this.getPasswordPolicyFromDb();
  }

  // Create Form
  private createForm() {
    this.createEntityForm = this.fb.group({
      step1: "",
      step2: "",
      step3: "",
      step4: "",
      step5: ""
    });
  }

  // Modal
  openModal(newEntityModal: any) {
    this.modalService.open(newEntityModal, {
      centered: true,
      windowClass: "mc-create-new-entity-modal",
      backdrop: "static",
      beforeDismiss: () => {
        this.utilityService.setStepNumber(1);
        return true;
      }
    });
    this.entityDataFormValid(true);
  }

  // Is form valid
  public entityDataFormValid(data: boolean) {
    this.isEntityFormValid = data;
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  // Close modal if entity was successful created
  closeModal(close: boolean) {
    if (close === true) {
      this.modalService.dismissAll();
    }
  }

  // Update entity Data
  refreshData(event: boolean) {
    if (event === true) {
      this.updateEntity.emit(true);
    }
  }

  /*Get entity from DB*/
  private getEntityFromDb() {
    return new Promise((resolve) => {
      let msgText: string;
      let msgType: string;
      const entityId = this.utilityService.getPropertyFromToken("entity_id");
      if (entityId) {
        this.entityService.getMcEntityById(entityId).subscribe(response => {
          this.parentEntityId = response.parentMcEntityId;
          resolve('');
        }, error => {
          if (error.status >= 500) {
            msgText = "Server is not available at the moment, please try again later.";
            msgType = "error";
            return;
          }
          msgText = error.error ? error.error.message : "There is no basic info defined";
          msgType = "error";
        });
      }
    });
  }

  /*Get custom attributes*/
  private getCustomAttributes() {
    let msgText: string;
    let msgType: string;
    const entityId = this.utilityService.getPropertyFromToken("entity_id");
    if (entityId) {
      this.entityService.getEntityAttributesById(entityId).subscribe(response => {
        this.inheritedAttributesList = response.filter((item: any) => item.inheritable === true).map((item: any) => {
          item.select = true;
          return item;
        });
      }, error => {
        if (error.status >= 500) {
          msgText = "Server is not available at the moment, please try again later.";
          msgType = "error";
          return;
        }
        msgText = error.error ? error.error.message : "There is no custom attributes defined";
        msgType = "error";
      });
    }
  }

  /*Get password policy*/
  private getPasswordPolicyFromDb() {
    let msgText: string;
    let msgType: string;
    this.entityService.getPasswordPolicyData()
      .subscribe(response => {
        this.passwordPolicyObject = response;
      }, error => {
        if (error.status >= 500) {
          msgText = "Server is not available at the moment, please try again later.";
          msgType = "error";
          this.setNotificationMessage(msgText, msgType);
          return;
        }
        msgText = error.error ? error.error.message : "There is no password policy defined";
        msgType = "error";
        this.setNotificationMessage(msgText, msgType);
      });
  }

  // Send image path to summary page
  imagePathFile(path: string) {
    this.imagePath = path;
  }

  onPricelistChosen(value: McPriceList) {
    this.pricelist = value;
  }
}
