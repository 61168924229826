
import {Table3Config} from '../extra/table-3-config';

// import {ImDataImportGENERATED} from '../_generated/im-data-import-generated.model';

export class ImDataImportTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDataImport', 'Data import', 'dataImport', 'dataImport');
    this.addColumn('colDataSet', 'Data set', 'dataSet', 'dataSet');
    this.addColumn('colDataSetId', 'DataSet ID', 'dataSetId', 'dataSetId');
    this.addColumn('colDelimiter', 'Delimiter', 'delimiter', 'delimiter');
    this.addColumn('colEncoding', 'Encoding', 'encoding', 'encoding');
    this.addColumn('colHeader', 'Header', 'header', 'header');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colImportMode', 'Import mode', 'importMode', 'importMode');
    this.addColumn('colMappingId', 'Mapping Id', 'mappingId', 'mappingId');
    this.addColumn('colSrc', 'Source', 'src', 'src');
    this.addColumn('colStatusCd', 'Status', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colWebFile', 'Web file', 'webFile', 'webFile');
    this.addColumn('colWebFileId', 'Web file ID', 'webFileId', 'webFileId');

*/
  }
}
