/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McProcessLevelTable3ConfigGENERATED} from '../_generated/mc-process-level-table-3-config-generated.model';

// import {McProcessLevelGENERATED} from '../_generated/mc-process-level-generated.model';

export class McProcessLevelTable3Config extends McProcessLevelTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colTitle', this.t('cc.processes.levels.level-title'), 'fldNameLink()', '');
    // this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colFlowLevelActions', this.t('cc.jobs.view.actions'), 'fldLevelActionIcons()', '');
    this.addColumn('colPaymentDeadline', this.t('cc.common.payment-deadline'), 'noDaysPaymentDeadlineAfterReminderIssue', '');
    this.addColumn('colWaitingPeriod', this.t('cc.my-factoring.waiting-period'), 'noDaysWaitingPeriodAfterPaymentDeadline', '');
    this.addColumn('colLevelFeeAmount', this.t('cc.common.fee-amount'), 'amountStr', '').extraCssClass = 'text-right';


  }
}
