import {EbInterest_rateFilterGENERATED} from '../_generated/eb-interest_rate-filter-generated.model';

export class EbInterest_rateFilter extends EbInterest_rateFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbInterest_rateFilter();
  }

  // ---------------------------------------------------------------------
}
