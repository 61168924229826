import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {WebFile, WebFileService} from "@miticon-ui/mc-core";

@Component({
  selector: 'lib-mc-import-consumers',
  templateUrl: './mc-import-consumers.component.html',
})
export class McImportConsumersComponent implements OnInit {

  // Variables
  toggleStep2!: boolean;
  showLoader!: boolean;
  @Output() updateTable = new EventEmitter<any>();
  private modalOpenSubscription: any;
  webfile = new WebFile();


  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.toggleStep2 = false;

  }
  // Modal
  openImportConsumersModal(modalName: any) {
    this.modalOpenSubscription = this.modalService.open(modalName, {
      backdrop: "static",
      centered: true,
      windowClass: "mc-add-consumer-modal",
      beforeDismiss: () => {
        this.toggleStep2 = false;
        return true;
      }
    });
  }

  actGoToStep2() {
    this.toggleStep2 = !this.toggleStep2;
  }
  actGoToStep1() {
    this.toggleStep2 = !this.toggleStep2;
  }
  closeModal() {
    this.updateTable.emit();
    this.modalOpenSubscription.dismiss();
  }

}
