import {ImDataImport} from './im-data-import.model';
import {ImDataImportListGENERATED} from '../_generated/im-data-import-list-generated.model';

export class ImDataImportList extends ImDataImportListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataImportList();
  }

  // ---------------------------------------------------------------------
}
