import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";

@Component({
  selector: 'app-shared-mat-bottom-sheet',
  templateUrl: './app-shared-mat-bottom-sheet.component.html',
  styleUrls: ['./app-shared-mat-bottom-sheet.component.scss']
})
export class AppSharedMatBottomSheetComponent implements OnInit {

  constructor(private bottomSheet: MatBottomSheetRef<AppSharedMatBottomSheetComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: [{}]) { }

  ngOnInit(): void {
  }

  onItemClicked(item: any) {
    this.bottomSheet.dismiss(item.action);
  }
}
