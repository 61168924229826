/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
/* Using "any" data-type in returned observables to avoid importing   */
/* ItemClass and ItemListClass and circular dependencies.             */
/* ------------------------------------------------------------------ */
import {BaseObjectService} from '../_core/base-object-service';
import {IEbSepaFileTransaction} from '../_generated/eb-sepa-file-transaction.interface';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';
// import {EbSepaFileTransaction} from '../models/eb-sepa-file-transaction.model';
// import {EbSepaFileTransactionList} from '../models/eb-sepa-file-transaction-list.model';
// import {EbSepaFileTransactionFilter} from '../models/eb-sepa-file-transaction-filter.model';
import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EbSepaFileTransactionServiceGENERATED extends BaseObjectService {
protected apiServiceUrlSubPath = "/ebfactoring/v2/ebsepafiletransaction";

public getApiServiceRootUrl(): string {
    return this.apiUrl + this.apiServiceUrlSubPath;
  }

  /* Get all  from DB */
public getAll(aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/all?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  /* Get all  from DB without pager */
public getByFilter(aFilter: any, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/filter?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, aFilter.toDto(), {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

/* Get by id from DB */
public getById(id: number): Observable<IEbSepaFileTransaction> {
  return this.httpClient.get<IEbSepaFileTransaction>(this.getApiServiceRootUrl() + `/${id}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

// Insert Object
public insert(aEbSepaFileTransaction: any): Observable<IEbSepaFileTransaction> {
    return this.httpClient.post<IEbSepaFileTransaction>(this.getApiServiceRootUrl()  , aEbSepaFileTransaction.toDto(), {headers: this.httpOptions});
}
// Update object
public update(aEbSepaFileTransaction: any): Observable<IEbSepaFileTransaction> {
    return this.httpClient.put<IEbSepaFileTransaction>(this.getApiServiceRootUrl() + `/`+ aEbSepaFileTransaction.id, aEbSepaFileTransaction.toDto(), {headers: this.httpOptions});
}


// DeleteAllFromDB
public deleteAllFromDb() {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/deleteAllFromDb`, {headers: this.httpOptions});
  }

  /* Get by id from DB */
public deleteById(id: number) {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/${id}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });

  }

  // -- CUSTOM METHODS --
  
  // ---------------------------------------------------------------------
  /* getBySepaFileId  from DB  */
  public getBySepaFileId( idEbSepaFile: number, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getbysepafileid/${idEbSepaFile}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getBySepaFileIdAndNullCollectionDates  from DB  */
  public getBySepaFileIdAndNullCollectionDates( idEbSepaFile: number, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getbysepafileidandnullcollectiondates/${idEbSepaFile}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getByFactoringEntityId  from DB  */
  public getByFactoringEntityId( idFactoringEntity: number, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getbyfactoringentityid/${idFactoringEntity}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getByEntityId  from DB  */
  public getByEntityId( idMcEntity: number, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getbyentityid/${idMcEntity}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getByEntityIdAndStatusCd  from DB  */
  public getByEntityIdAndStatusCd( idMcEntity: number, statusCds: any, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getbyentityidandstatuscd/${idMcEntity}/${statusCds}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}



  // ---------------------------------------------------------------------
  /* getByConsumerId  from DB  */
  public getByConsumerId( idCsrConsumer: number, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getbyconsumerid/${idCsrConsumer}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}








  // ---------------------------------------------------------------------
  public devFixTransactions() {
    const body: any = {};
    
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/devfixtransactions`,  {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  // ---------------------------------------------------------------------
  public devFixBatchCollectionDate() {
    const body: any = {};
    
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/devfixbatchcollectiondate`,  {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  // ---------------------------------------------------------------------
  public devFixTransactionEntityId() {
    const body: any = {};
    
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/devfixtransactionentityid`,  {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  // ---------------------------------------------------------------------
  public devFixBeneficiaryEntityId() {
    const body: any = {};
    
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/devfixbeneficiaryentityid`,  {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  // ---------------------------------------------------------------------
  public markAsSentToInkasso( transactionIds: any) {
    const body: any = {};
    body.transactionIds = transactionIds;

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/markassenttoinkasso/${transactionIds}`,  {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  // ---------------------------------------------------------------------
  public changeRequestedCollectionDate( transactionIds: any, requestedCollectionDate: any, reasonForChangingRequestedCollectionDate: string) {
    const body: any = {};
    body.transactionIds = transactionIds;
body.requestedCollectionDate = requestedCollectionDate;
body.reasonForChangingRequestedCollectionDate = reasonForChangingRequestedCollectionDate;

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/changerequestedcollectiondate`,  body,  {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  // ---------------------------------------------------------------------
  public pauseTransaction( transactionIds: any, pause_end_date: any, pauseUnpauseReason: string) {
    const body: any = {};
    body.transactionIds = transactionIds;
body.pause_end_date = pause_end_date;
body.pauseUnpauseReason = pauseUnpauseReason;

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/pausetransaction/${transactionIds}/${pause_end_date}/${pauseUnpauseReason}`,  {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  // ---------------------------------------------------------------------
  public unpauseTransaction( transactionIds: any, pauseUnpauseReason: string) {
    const body: any = {};
    body.transactionIds = transactionIds;
body.pauseUnpauseReason = pauseUnpauseReason;

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/unpausetransaction/${transactionIds}/${pauseUnpauseReason}`,  {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}



  // ---------------------------------------------------------------------
  public calculateAndGetTotalAmountDueForConsumer( idCsrConsumer: number): Observable<IEbSepaFileTransaction> {
    return this.httpClient.get<IEbSepaFileTransaction>(this.getApiServiceRootUrl()
      + `/calculateandgettotalamountdueforconsumer/${idCsrConsumer}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

}
