import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {McCustomAttribute, McGod, McMapping, McMappingItemList, McMappingList, WebFile} from '@miticon-ui/mc-core';
import {McCsvColumnsMappingItemTableConfigDefaultModel} from './mc-csv-columns-mapping-item-table-config-default.model';
import {MccFiSelectOptionConfig} from '../mcc-fi-select-option/mcc-fi-select-option-config';
import {MccFiTable2CellEditConfigData} from '../mcc-fi-table-2/mcc-fi-table-2-cell-edit-config-data.model';
import {MccFiTable2CellEditedData} from '../mcc-fi-table-2/mcc-fi-table-2-cell-edited-data.model';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'lib-mc-csv-columns-mapping',
  templateUrl: './mc-csv-columns-mapping.component.html',
})
export class McCsvColumnsMappingComponent implements OnInit {

  // Variables
  mappingItemTableConfig!: McCsvColumnsMappingItemTableConfigDefaultModel;
  @Input() webFile!: WebFile;
  @Input() mapping!: McMapping;
  @Input() mappingItems!: McMappingItemList;
  @Input() templateMappings!: McMappingList;
  @Input() duplicatesHandlingOptionsKvm!: Map<string, string>;
  @Input() entityAttributes!: McCustomAttribute;
  @Input() parentForm!: FormGroup;
  @Output() onUpdatedItems = new EventEmitter();
  @Output() localAttribute = new EventEmitter();
  @Output() checkDuplicatesFlag = new EventEmitter();
  currentTemplateMapping!: McMapping;
  mappingForm!: FormGroup;
  constructor(private formBuilder: FormBuilder) {}

  // ---------------------------------------------------------------------
  ngOnInit() {
    console.log('template mappings', this.templateMappings);
    this.resetCurrentTemplateMapping();
    this.mappingItemTableConfig = new McCsvColumnsMappingItemTableConfigDefaultModel();

    this.mappingForm = this.formBuilder.group({
      mappingName: [''],
      duplicates: [''],
      mappingNameCheckBox: []
    });

    this.parentForm.setControl('mappingForm', this.mappingForm);

    this.mappingForm.get('mappingNameCheckBox')?.valueChanges.subscribe(data => {
      console.log(this.mappingForm.get('mappingNameCheckBox')?.value); });

  }

  // ---------------------------------------------------------------------
  onTemplateMappingChanged(chosenMapping: McMapping) {
    this.currentTemplateMapping = chosenMapping;
    this.mapping.mappingItems.updateItem(this.currentTemplateMapping.mappingItems.items, 'columnName', 'attributeName');
    this.checkDuplicatesFlag.emit();
  }

  // ---------------------------------------------------------------------
  public onMappingItemTableCellConfig(event: MccFiTable2CellEditConfigData): void {
    const optionsKvm = new Map<string, string>();
    optionsKvm.set('', McGod.t('cc.common.edit.please-choose'));
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for ( let i = 0; i < this.entityAttributes.entityAttributesList.length; i++) {
      optionsKvm.set(this.entityAttributes.entityAttributesList[i], this.entityAttributes.entityAttributesList[i]);
    }
    optionsKvm.set('Discard', 'DISCARD');

    const config: MccFiSelectOptionConfig = event.editComponentConfigObj;
    config.optionsKvm = optionsKvm;
    config.selectedValue = event.dataItemObj.execPropertyOrMethodByName(event.columnConfigObj.editObjectPropertyOrMethodName); // TODO HACK
  }

  // ---------------------------------------------------------------------
  onMappingTableCellEdited(event: MccFiTable2CellEditedData) {
    this.resetCurrentTemplateMapping();
    this.onUpdatedItems.emit();
  }

  // ---------------------------------------------------------------------
  resetCurrentTemplateMapping() {
    this.currentTemplateMapping = new McMapping(); // TODO Service
    this.currentTemplateMapping.mappingName = 'Custom mapping';
  }

  setDuplicates(option: any) {
    this.mappingForm.get('duplicates')?.setValue(option);
  }

}
