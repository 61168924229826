/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingServiceCatalogTable3ConfigGENERATED} from '../_generated/mc-billing-service-catalog-table-3-config-generated.model';

// import {McBillingServiceCatalogGENERATED} from '../_generated/mc-billing-service-catalog-generated.model';

export class McBillingServiceCatalogTable3Config extends McBillingServiceCatalogTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colTypeCd', 'Type cd', 'typeCd', 'typeCd');
    this.addColumn('colFlgPriceFromContract', 'Is price from contract', 'flgPriceFromContract', 'flgPriceFromContract');


  }
}
