import {Inject, Injectable} from '@angular/core';
import {McProcessRunItemServiceGENERATED} from '../_generated/mc-process-run-item-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McProcessRunItemService extends McProcessRunItemServiceGENERATED {

}
