import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

// Models
import {McUtilityService} from './mc-utility.service';

@Injectable({
  providedIn: 'root'
})
export class McRoleService {

  /*Private variables*/
  private apiUrl: string;
  private token;
  private httpOptions;
  // Constructor
  // @ts-ignore
  constructor(
    private httpClient: HttpClient,
    private utilityService: McUtilityService,
    @Inject('environment') environment: any) {
    // @ts-ignore
    this.token = JSON.parse(localStorage.getItem('mc-access-token'));
    /*Set API base url*/
    this.apiUrl = environment.apiUrl;
    this.httpOptions = environment.httpOptions(HttpHeaders, this.token);
  }

  /*Create new role for entity*/
  public createRole2Entity(body: { active: any; caption: any; description: any; templateFlg: any; mcEntityId: any; permissionIds: any[] | never[]; isProtected: boolean; entity?: any; linkedFlg?: boolean; ancestorId?: any; ancestorEntityName?: any; }) {
    return this.httpClient.post(`${this.apiUrl}/core/entity/role`, body, this.httpOptions);
  }

  public getAllTemplateRolesFromAncestors(id: any) {
    return this.httpClient.get(`${this.apiUrl}/core/entity/${id}/role-templates`, this.httpOptions);
  }

  /*Get role info*/
  public getEntityRoleById(id: any) {
    return this.httpClient.get(`${this.apiUrl}/core/entity/role/${id}`, this.httpOptions);
  }

  public getEntityRoleTemplate(id: number) {
    return this.httpClient.get(`${this.apiUrl}/core/user/role/${id}`, this.httpOptions);
  }

  /*Update entity role*/
  public updateEntityRole(body: any) {
    return this.httpClient.put(`${this.apiUrl}/core/entity/role`, body, this.httpOptions);
  }

  public updateEntityRoleLinkedFlg(id: any, linkedFlg: boolean) {
    return this.httpClient.put(`${this.apiUrl}/core/user/role/${id}?linkedFlg=${linkedFlg}`, this.httpOptions);
  }

  public fixRoleAncestorEntityName() {
    return this.httpClient.post(`${this.apiUrl}/core/dev/fix-role-ancestor-entity-name`, this.httpOptions);
  }

  public fixTemplateRoleNumberOfChildren() {
    return this.httpClient.post(`${this.apiUrl}/core/dev/fix-role-number-of-children`, this.httpOptions);
  }

}
