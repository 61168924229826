import {EbSepaExportFilterGENERATED} from '../_generated/eb-sepa-export-filter-generated.model';

export class EbSepaExportFilter extends EbSepaExportFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaExportFilter();
  }

  // ---------------------------------------------------------------------
}
