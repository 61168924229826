/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingEntitiesUsingPricelistTable3ConfigGENERATED} from '../_generated/mc-billing-entities-using-pricelist-table-3-config-generated.model';

// import {McBillingEntitiesUsingPricelistGENERATED} from '../_generated/mc-billing-entities-using-pricelist-generated.model';

export class McBillingEntitiesUsingPricelistTable3Config extends McBillingEntitiesUsingPricelistTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colEntityId', 'Entity id', 'entityId', 'entityId');
    this.addColumn('colEntityName', 'Entity name', 'entityName', 'entityName');
    this.addColumn('colStartDate', 'Start date', 'startDate', 'startDate');


  }
}
