/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {CsrContractHistory} from '../models/csr-contract-history.model';
import {CsrContractHistoryList} from '../models/csr-contract-history-list.model';
import {CsrContractHistoryFilter} from '../models/csr-contract-history-filter.model';
import {CsrContractHistoryService} from '../services/csr-contract-history.service';
import {ICsrContractHistory} from '../_generated/csr-contract-history.interface';
import {McGod} from "../extra/mc-god.service";

export class CsrContractHistoryListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'actionTypeCd', 'fieldName', 'fieldTypeCd', 'idCsrConsumer', 'idCsrContract', 'newValue', 'oldValue', 'sysCreatedDatetime', 'sysCreatedUserId', 'version'];
  public items: CsrContractHistory[] = [];
  public apiService: CsrContractHistoryService;
 // protected tempObjList: CsrContractHistoryList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrContractHistoryList {
    console.error('MTCN-ERROR: Not Implemented: CsrContractHistoryList::createNewInstance(). Add this method to final class and return new CsrContractHistoryList();');
    throw new Error('Cannot CsrContractHistoryList::createNewInstance(). Add this method to final class and return new CsrContractHistoryList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: ICsrContractHistory[]): CsrContractHistoryList {
    const  listObj: CsrContractHistoryList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: CsrContractHistory = CsrContractHistory.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): CsrContractHistoryList {
    const  listObj: CsrContractHistoryList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrContractHistoryService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: ICsrContractHistory[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: CsrContractHistory = CsrContractHistory.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aCsrContractHistoryFilter: CsrContractHistoryFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aCsrContractHistoryFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): CsrContractHistory {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new CsrContractHistory();
    }
  }

  public replaceItem(newItem: CsrContractHistory) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --

}

