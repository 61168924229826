<ng-container class="dialog">
  <div class="dialog_header">
    <span class="title-medium-dark">{{'mem.mapping.mapping-templates' | translate}}</span>
    <span class="dialog_header_icon" [innerHTML]="iconClose | safeHtml" (click)="dialogRef.close()"></span>
  </div>

  <mat-dialog-content>
    <h2 *ngIf="currentlyMapping" class="title-medium-dark mb-large">
      {{ 'cc.consumers.consumers-overview.currently-applied-mapping' | translate }}
    </h2>
    <div *ngIf="currentlyMapping">
      <div class='card mb-large' (click)="viewCurrentMappingItems = !viewCurrentMappingItems">
        <div class='flex-between choosenMapping'>
          <div class='flex'>
            <span class='flex mapping_icon' [innerHTML]="iconMappingTemplateBlack | safeHtml"></span>
            <div class="info-card">
              <h2 class='title-medium-dark-normal ml-small'>{{ currentlyMapping.mappingName }}</h2>
              <div class="info-card-label-value ml-small">
                <div class="label">
                  <span>{{'mem.member.create-date' | translate}}:</span>
                </div>
                <div class="value">
                  {{currentlyMapping.creationDate ? getMappingCreationDate(currentlyMapping.creationDate) : '-'}}
                </div>
              </div>
            </div>
          </div>
          <div class="list_attributes">
            <span class="list_attributes_label">{{ "cc.consumers.mapping" | translate }}</span>
            <span [class.isOpenMapping]="viewCurrentMappingItems" [innerHTML]="iconArrow | safeHtml">
          </span>
          </div>
        </div>
        <div *ngIf="viewCurrentMappingItems" class="attributes_info">
          <table class="custom-table mr-large">
            <thead>
            <tr>
              <th class="">{{ "mem.import.attribute" | translate }}</th>
              <th class="">{{ "mem.import.mapping.sample" | translate }}</th>
              <th class="">{{ "mem.import.mapping.column-name" | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor='let mappingItem of currentlyMapping.mappingItems'>
              <td>{{ mappingItem.attributeName }}</td>
              <td>{{ mappingItem.sampleData }}</td>
              <td>{{ mappingItem.columnName }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <h2 class="title-medium-dark mb-large">{{ "mem.data-import.list-of-mapping-templates" | translate }}</h2>
    <div *ngFor="let mapping of mappings;let i = index">
      <div class='card' (click)="chooseMapping(mapping, i)">
        <div class='flex-between' [class.choosenMapping]="navOpenState[i]">
          <div class='flex'>
            <span class='flex mapping_icon' [innerHTML]="iconMappingTemplateBlack | safeHtml"></span>
            <div class="info-card">
              <h2 class='title-medium-dark-normal ml-small'>{{ mapping.mappingName }}</h2>
              <div class="info-card-label-value ml-small">
                <div class="label">
                  <span>{{'mem.member.create-date' | translate}}:</span>
                </div>
                <div class="value">
                  {{mapping.creationDate ? getMappingCreationDate(mapping.creationDate) : '-'}}
                </div>
              </div>
            </div>
          </div>
          <div class="list_attributes">
            <span class="list_attributes_label">{{ "cc.consumers.mapping" | translate }}</span>
            <span [class.isOpenMapping]="navOpenState[i]" [innerHTML]="iconArrow | safeHtml">
          </span>
          </div>
        </div>
        <div class="attributes_info" *ngIf="navOpenState[i]">
          <table class="custom-table mr-large">
            <thead>
            <tr>
              <th class="">{{ "mem.import.attribute" | translate }}</th>
              <th class="">{{ "mem.import.mapping.sample" | translate }}</th>
              <th class="">{{ "mem.import.mapping.column-name" | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor='let mappingItem of mapping.mappingItems'>
              <td>{{ mappingItem.attributeName }}</td>
              <td>{{ mappingItem.sampleData }}</td>
              <td>{{ mappingItem.columnName }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button class="secondary-btn" (click)="dialogRef.close()">{{'cc.common.edit.cancel' | translate}}</button>
    <button mat-button class="primary-btn" type="submit" [disabled]="!choosenMapping" (click)="applyMapping()">{{'cc.common.view.apply' | translate}}</button>
  </div>
</ng-container>
