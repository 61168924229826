import {McProcessFlowCriteriaFilterGENERATED} from '../_generated/mc-process-flow-criteria-filter-generated.model';

export class McProcessFlowCriteriaFilter extends McProcessFlowCriteriaFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessFlowCriteriaFilter();
  }

  // ---------------------------------------------------------------------
}
