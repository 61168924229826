import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BaseDirective,
  EbFactoring,
  EbFactoringService,
  McBillingItem, McBillingItemList,
  McBillingStatement, McBillingStatementItem,
  McBillingStatementList,
  McBoolean,
  McForm, McGod,
  McValueLabelList, CurrencyUtil, IEbFactoring
} from "@miticon-ui/mc-core";

@Component({
  selector: 'mc-out-billing-assign-to-statement-part',
  templateUrl: './mc-out-billing-assign-to-statement-part.component.html',
})
export class McOutBillingAssignToStatementPartComponent extends BaseDirective implements OnInit {

  mcForm = new McForm();
  mcBillingStatementList = new McBillingStatementList();
  mcBillingStatementVll = new McValueLabelList();
  showAddEditStatementMcb = new McBoolean();
  aggregateYesNoVll = new McValueLabelList();
  numberOfItems!: number;
  totalAmount = 0;
  totalVAT = 0;
  mcGod = McGod.getInstance();
  mcBillingStatementItem = new McBillingStatementItem();
  loadingFlg = false;
  errorMsg = '';
  billingItemList = new McBillingItemList();

  @Input() mcBillingItem!: McBillingItem;
  @Input() mcBillingItemList!: McBillingItemList;
  disableAggregateBtnMcb = new McBoolean();

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();

  /*Constructor*/
  constructor(private factoringService: EbFactoringService) {
super();
  }

  /*On init*/
  ngOnInit() {
    this.actLoad();
    this.aggregateYesNoVll.add(true, 'Yes');
    this.aggregateYesNoVll.add(false, 'No');
    if (this.mcBillingItem.exists()) {
      this.numberOfItems = 1;
      this.totalAmount = this.mcBillingItem.amount;
      this.totalVAT = this.mcBillingItem.vatForMcBillingItem;
    } else {
      this.numberOfItems = this.mcBillingItemList.getCount();
      this.mcBillingItemList.items.forEach(item => {
        this.totalAmount += item.amount;
        this.totalVAT += item.vatForMcBillingItem;
      });
    }
  }

  actLoad() {
    this.mcBillingStatementList.loadAllActiveOrNewByParentEntityId(McGod.getLoggedEntityIdFromToken(), () => {
      this.mcBillingStatementVll = this.mcBillingStatementList.toVll('id', 'title');
    });
  }

  actCreateStatement() {
    this.showAddEditStatementMcb.setTrue();
  }

  onAddEditStatementSaved(mcBillingStatement: McBillingStatement) {
    this.showAddEditStatementMcb.setFalse();
    this.actLoad();
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actAssign() {
    if (this.mcForm.isValid()) {

      const statementId = this.mcForm.getValue('ctrlStatements');

      if (!this.mcForm.getValue('ctrlAggregate')) { // SINGLE

        if (this.mcBillingItem.exists()) {
          this.mcBillingItemList = new McBillingItemList();
          this.mcBillingItemList.addItem(this.mcBillingItem);
        }
        console.log('saveMcBillingItemsAncCreateMcBillingStatementItems not imeplemented on BE');
        // this.billingItemList.saveMcBillingItemsAncCreateMcBillingStatementItems(this.mcBillingItemList.toDto(), statementId, () => {
        //   this.eventSaved.emit();
        //   this.loadingFlg = false;
        // }, () => {
        //   this.loadingFlg = false;
        //   this.errorMsg = this.billingItemList.apiErrorMessage;
        // });
      } else { // AGGREGATION
        if (this.mcBillingItem.exists()) {
          this.mcBillingItemList = new McBillingItemList();
          this.mcBillingItemList.addItem(this.mcBillingItem);
        }
        console.log('callAddBillingItemsToAggregateStatementItem not imeplemented on BE');
        // this.mcBillingItem.callAddBillingItemsToAggregateStatementItem(this.mcBillingItemList.toDto(), statementId, () => {
        //   this.eventSaved.emit();
        //   this.loadingFlg = false;
        // }, () => {
        //   this.loadingFlg = false;
        //   this.errorMsg = this.billingItemList.apiErrorMessage;
        // });
      }

      // TODO: finish method
      // this.eventSaved.emit();
    } else {
      this.disableAggregateBtnMcb.setFalse();
    }
  }

  saveBillingItem() {
    if (this.mcBillingItem.exists()) {
      this.mcBillingItem.save(() => {
        this.eventSaved.emit();
        this.loadingFlg = false;
      }, () => {
        this.loadingFlg = false;
        this.errorMsg = this.mcBillingItem.apiErrorMessage;
      });
    } else { // SAVE ITEM LIST
      // this.mcBillingItemList.save
    }
  }

  getBillingItemFactoringCurrencySymbol() {
    const allContractsForEntity = this.factoringService.getAllFactorings();
    const billingChildEntityId = this.mcBillingItemList.getCount() > 0 ?
      this.mcBillingItemList.items[0].childEntityId : this.mcBillingItem.childEntityId;
    const contractStatusRejected = EbFactoring.STATUS_CD_REJECTED;
    const contractStatusPending = EbFactoring.STATUS_CD_PENDING_ACCEPTANCE;
    const contractStatusRevoked = EbFactoring.STATUS_CD_REVOKED;
    const contractsForChildEntity = allContractsForEntity
      .filter((contract: IEbFactoring) => contract.idChildMcEntity === billingChildEntityId &&
        contract.statusCd !== contractStatusPending &&
        contract.statusCd !== contractStatusRevoked &&
        contract.statusCd !== contractStatusRejected
    );
    const sortedContractsForChildEntity = contractsForChildEntity
      .sort( (contractA, contractB) =>
        contractB.contractStartDate ? contractB.contractStartDate : 0 - (contractA.contractStartDate ?
      contractA.contractStartDate : 0));
    const activityDateTime = this.mcBillingItemList.getCount() > 0 ?
      new Date(this.mcBillingItemList.items[0].activityDatetime) : new Date(this.mcBillingItem.activityDatetime);
    const contractForBillingItem = sortedContractsForChildEntity
      .find(item => activityDateTime.getTime() >= (item.contractStartDate ? item.contractStartDate : 0));
    return contractForBillingItem?.mcCurrency.code;
  }

  formatNumberToMoneyWithExactCurrency(amount: number) {
    const amountToBeFormatted = amount ? amount : 0;
    return CurrencyUtil.format(amountToBeFormatted, this.getBillingItemFactoringCurrencySymbol());
  }
}
