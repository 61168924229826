import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {McEntityProfileComponent} from './mc-entity-profile.component';
import {AuthGuard, McPermissionGuard} from '@miticon-ui/mc-core';


const routes: Routes = [
  {
    path: '',
    component: McEntityProfileComponent,
    canActivate: [AuthGuard, McPermissionGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McEntityProfileRoutingModule {
}
