export const ICON_SKIPPED_ITEMS = `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_954_10577)">
<path d="M18.7498 6.25H26.2498C26.9373 6.25 27.4998 6.8125 27.4998 7.5C27.4998 8.1875 26.9373 8.75 26.2498 8.75H18.7498C18.0623 8.75 17.4998 8.1875 17.4998 7.5C17.4998 6.8125 18.0623 6.25 18.7498 6.25ZM18.7498 13.125H26.2498C26.9373 13.125 27.4998 13.6875 27.4998 14.375C27.4998 15.0625 26.9373 15.625 26.2498 15.625H18.7498C18.0623 15.625 17.4998 15.0625 17.4998 14.375C17.4998 13.6875 18.0623 13.125 18.7498 13.125ZM18.7498 20H26.2498C26.9373 20 27.4998 20.5625 27.4998 21.25C27.4998 21.9375 26.9373 22.5 26.2498 22.5H18.7498C18.0623 22.5 17.4998 21.9375 17.4998 21.25C17.4998 20.5625 18.0623 20 18.7498 20ZM12.3123 23.9375L14.5498 21.7C14.7998 21.45 14.7998 21.0625 14.5498 20.8125L12.3123 18.575C11.9248 18.175 11.2498 18.45 11.2498 19.0125V23.5C11.2498 24.05 11.9248 24.325 12.3123 23.9375ZM11.2498 20H10.8748C7.93733 20 5.31233 17.8625 5.02483 14.9375C4.69983 11.5875 7.33733 8.75 10.6248 8.75H13.7498C14.4373 8.75 14.9998 8.1875 14.9998 7.5C14.9998 6.8125 14.4373 6.25 13.7498 6.25H10.6248C5.79983 6.25 1.92483 10.5 2.57483 15.45C3.09983 19.55 6.78733 22.5 10.9123 22.5H11.2498" fill="white"/>
</g>
<defs>
<clipPath id="clip0_954_10577">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>
`
