<ng-container>

  <div class="content-wrapper">

    <div class="content-header-slim">
      <img class="header-icon" src="/assets/images/change-collection-date2.svg"><h3>{{'cc.consumer.change-collection-date' | translate}}</h3>
    </div>
    <div class="content-body-slim">
      <mcc-message [errorMsg]="notifyMessage"></mcc-message>
      <lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
      <div class="info-summary">
        <div class="inner-content">
      <mcc-row-title-description title="{{'cc.consumer.selected-transaction-s' | translate}}:" [widerTitleFlg]="true" description="{{selectedTransactionsNumber}}"></mcc-row-title-description>

          <ng-container *ngIf="isSingleTransactionSelected">
            <mcc-row-title-description title="{{'cc.transactions.minimal-possible-collection-date' | translate}}:" [widerTitleFlg]="true" description="{{collectionDate | date}}"></mcc-row-title-description>
          </ng-container>


          <ng-container *ngIf="!isSingleTransactionSelected">
              <mcc-row-title-description title="{{'cc.transactions.minimal-possible-collection-date' | translate}}:" [widerTitleFlg]="true" description="{{minimalNewCollectionDate | date}}"></mcc-row-title-description>
          </ng-container>

          <mcc-row-title-description title="{{'cc.transactions.maximal-possible-collection-date' | translate}}:" [widerTitleFlg]="true" description="{{maxCollectionDate | date}}"></mcc-row-title-description>

        </div>
      </div>
      <div class="info-message">
        <mcc-fi-date-picker
          label="{{'cc.consumer.new-collection-date' | translate}}"
          name="ctrlNewCollectionDate"
          [validRequiredFlg]="true"
          [allowManualEntryFlg]="true"
          [minDateInput]="minimalNewCollectionDate"
          [maxDateInput]="maxCollectionDate"
          (keypress)="onKeyPressDatepicker($event)"
          [mcForm]="mcForm">
        </mcc-fi-date-picker>
        <i class="fas fa-info-circle mc-tooltip-top-margin" ngbTooltip="{{'cc.transactions.the-collection-date-can-be-moved-back-in-the-past-forth-in-the-future-as-much-as-the-contract-allows' | translate}}."
           placement="bottom-left"></i>
      </div>
      <mcc-fi-textarea name="ctrlReason" label="{{'cc.my-factoring.reason' | translate}}" [numCols]="35" [validRequiredFlg]="true" [validWhitespaces]="true"
                        [mcForm]="mcForm"></mcc-fi-textarea>
    </div>

    <div class="content-footer">
      <button class="btn btn-secondary move-right" (click)="eventCanceled()"><i class="far fa-times-circle pr-2"></i>{{'cc.common.edit.cancel' | translate}}</button>
      <button class="btn btn-primary" (click)="actChange()" [disabled]="buttonDisabled"><i class="fas fa-save pr-2"></i>{{'cc.consumer.change' | translate}}</button>
    </div>

  </div>

</ng-container>

