import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {Pagination} from '@miticon-ui/mc-core';

@Component({
  selector: 'mcc-pagination',
  templateUrl: './mcc-pagination.component.html',
  styleUrls: ['./mcc-pagination.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MccPaginationComponent {

  /*Variables*/
  @Input() pagination: Pagination = new Pagination();
  @Output() paginationChange = new EventEmitter<Pagination>();


  goToFirstPage() {
    if (!this.pagination.first) {
      this.pagination.pageNumber = 0;
      this.pagination.first = true;
      this.paginationChange.emit(this.pagination);
    }
  }

  goToPreviousPage() {
    if (!this.pagination.first) {
      this.pagination.pageNumber -= 1;
      this.paginationChange.emit(this.pagination);
    }
  }

  goToNextPage() {
    if (!this.pagination.last) {
      this.pagination.pageNumber += 1;
      this.paginationChange.emit(this.pagination);
    }
  }

  goToLastPage() {
    if (!this.pagination.last) {
      this.pagination.pageNumber = this.pagination.totalPages - 1;
      this.pagination.last = true;
      this.paginationChange.emit(this.pagination);
    }
  }

  onNumOfItemsPerPageChange(value: { option: number; }) {
    this.pagination.pageNumber = 0;
    this.pagination.pageSize = value.option;
    this.paginationChange.emit(this.pagination);
  }
}

