import {ActionOptions} from '../_core/mc-html-action-options';

export class McHtmlButton {
  label: string;
  actionCdForEvent: string;
  item: any; // TODO should be BaseObject
  extraCssClass: string;
  permissions: string[] | null ;
  actionOptions: ActionOptions;

  static get(label: string, actionCdForEvent: string, itemObj: any, permissions: string[], extraCssClass: string = '', actionOptions: ActionOptions) {
    const item = new McHtmlButton(label, actionCdForEvent, itemObj, permissions, extraCssClass, actionOptions);
    return item;
  }
  constructor(label: string, actionCdForEvent: string, itemObj: any, permissions: string[] | null, extraCssClass: string = '', actionOptions: ActionOptions) {
    this.label = label;
    this.actionCdForEvent = actionCdForEvent;
    this.item = itemObj;
    this.extraCssClass = extraCssClass;
    this.permissions = permissions;
    this.actionOptions = actionOptions;
  }
}
