<!--<div class="mc-go-back-to-all" (click)="goBack()">-->
<!--  < {{('cc.common.back-to-all' | translate)}}-->
<!--</div>-->

<span class="back-option" (click)="actGoToProcesses()">
  <span [innerHTML]="iconBack | safeHtml"></span>
  {{ "cc.process.back-to-processes" | translate }}
</span>

<!--<div><span class="text-primary">{{mcProcess.title}}</span></div>-->

<div class="content-wrapper wrapper-padding">
  <div class="content-body">
    <mcc-menu [mcMenu]="mcMenu"></mcc-menu>

    <div class="row">
      <lib-mc-loader [showLoader]="mcProcessItemList.apiLoadingFlg"></lib-mc-loader>
      <!--<div class="col-md-12">
        <div class="mc-filter">
          <mcc-fi-textbox label="{{'cc.common.search' | translate}}" name="txbSearchTerm"
                          (eventEnterClicked)="actLoad()"
                          [mcForm]="mcForm"></mcc-fi-textbox>
          <mcc-fi-select [mcForm]="mcForm" name="selFlowLevel" label="Flow level" [optionsVll]="mcFlowLevelVll"
                         placeholderOption="{{'cc.my-factoring.all' | translate}}"></mcc-fi-select>
          <mcc-fi-select [mcForm]="mcForm" name="selStatus" label="Status" [optionsVll]="mcStatusVll"
                         placeholderOption="{{'cc.my-factoring.all' | translate}}"></mcc-fi-select>

          <button class="mc-link mc-filter-clear-button filter-clear-top-right"
                  (click)="actClearFilter()">{{'cc.common.filter.clear-all' | translate}}</button>
          <button class="mc-button mc-filter-button"
                  (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>
        </div>
      </div>-->
    </div>

    <mcc-message [successMsg]="successMsg"></mcc-message>


    <div class="row">

      <div class="col-md-12" *ngIf="mcProcessItemList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="mcProcessItemList"
                        [table3Config]="mcProcessItemTable3Config"
                        (eventPaginationOrSortChanged)="actLoad()" (eventItemAction)="onTableItemAction($event)"
                        (eventSelectionChanged)="actTableSelectionChanged($event)"></mcc-fi-table-3>
      </div>

    </div>

    <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showChangeNextActionDateMcb.value" [showMcb]="showChangeNextActionDateMcb" (eventCanceled)="actCancelChangeNextActionDateTime()">
      <mc-process-process-item-change-next-action-datetime [mcProcessItemList]="selectedList"  (eventSaved)="actSavedChangeNextActionDateTime()"
                                              (eventCanceled)="actCancelChangeNextActionDateTime()"></mc-process-process-item-change-next-action-datetime>
    </mcc-modal1-wrapper>

    <mcc-modal1-wrapper *ngIf="showTransactionProfileMcb.value" [showMcb]="showTransactionProfileMcb" modalSizeCd="full">
      <app-shared-transaction-profile-part [ebSepaFileTransactionId]="ebSepaFileTransactionId" [showEditTransaction]="true" (eventClose)="actOnTransactionProfileClose()"></app-shared-transaction-profile-part>
    </mcc-modal1-wrapper>

    <mcc-modal1-wrapper *ngIf="showConsumerActionHistoryMcb.value" [showMcb]="showConsumerActionHistoryMcb" (eventCanceled)="actActionHistoryCancel()">
      <mc-consumer-action-history-part [csrConsumerId]="mcConsumerId" (eventCanceled)="actActionHistoryCancel()"></mc-consumer-action-history-part>
    </mcc-modal1-wrapper>

    <mcc-slide-action-set [actionSet]="actionSet" [selectedNumber]="this.selectedListNumber"
                          (eventActionClicked)="slideActionItemClicked($event)"></mcc-slide-action-set>

  </div>

  <router-outlet></router-outlet>

</div>
