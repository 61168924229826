import {McVat} from './mc-vat.model';
import {McVatListGENERATED} from '../_generated/mc-vat-list-generated.model';

export class McVatList extends McVatListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McVatList();
  }

  // ---------------------------------------------------------------------
}
