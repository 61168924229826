import {CsrMappingItemFilterGENERATED} from '../_generated/csr-mapping-item-filter-generated.model';

export class CsrMappingItemFilter extends CsrMappingItemFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrMappingItemFilter();
  }

  // ---------------------------------------------------------------------
}
