<ng-container>
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-wrapper">
    <lib-mc-loader [showLoader]="webFile.apiLoadingFlg"></lib-mc-loader>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-header">
      <div class="d-flex"><img src="assets/images/import_consumer.svg"> <h4 class="modal-title pl-3" id="mc-modal-new-user-title">{{'cc.consumers.consumers-overview.import-consumers' | translate}}</h4>
      </div>
    </div>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-body">

      <div class="col-md-12 mc-stepper-header-title">
          <span class="pl-4 pb-1 mr-2 mc-active">
            <span class="mc-notification-badge-gray mc-active-background mc-active-background">1</span>
            {{'cc.consumers.consumers-overview.file-upload' | translate}}
          </span>
        <span class="pl-4 pb-1 ml-4 mr-2">
            <span class="mc-notification-badge-gray">2</span>
          {{'cc.consumers.consumers-overview.mapping-&-importing' | translate}}
          </span>
      </div>

      <div class="col-md-12 position-relative mt-1">
        <div class="mc-create-user-notify-wrapper">
          <div class="w-100" *ngIf="webFile.apiErrorFlg">
            <mc-notify-message [message]=webFile.apiErrorMessage [type]="'error'"></mc-notify-message>
          </div>
          <div class="w-100" *ngIf="!isEnabled">
            <mc-notify-message [message]="message" [type]="messageType"></mc-notify-message>
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-5">
        <div class="input-group mb-3">

          <lib-mcc-fi-file-upload [acceptProperty]="'.csv'"
                                  (eventFileSelected)="onFileSelected($event)"></lib-mcc-fi-file-upload>

        </div>
      </div>
      <div class="row">

          <mcc-fi-select class="col-md-3 mt-4 pt-1 pl-5" [extraCssClass]="'select-in-component-width-50 w-100'"
                         [mcForm]="mcForm"
                          name="delimiter"
                          label="{{'cc.consumers.consumers-overview.delimiter' | translate}}"
                          [value]="';'"
                          [optionsVll]="delimiterVll" [labelOnTop]="false"></mcc-fi-select>

          <mcc-fi-select  class="col-md-3 mt-4 pt-1"
                          [extraCssClass]="'select-in-component-width-50 w-100'"
                          [mcForm]="mcForm"
                          name="encoding"
                          label="{{'cc.consumers.consumers-overview.encoding' | translate}}"
                          [value]="'UTF-8'"
                          [optionsVll]="encodingVll" [labelOnTop]="false"></mcc-fi-select>

      </div>

    </div><!-- modal-body -->

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-footer">

      <button class="btn btn-secondary move-right" (click)="actCancel()">
        <i class="far fa-times-circle"></i> {{ 'cc.common.edit.cancel' | translate }}
      </button>
      <button class="btn btn-primary mr-3" (click)="actUpload()">
        <i class="fas fa-upload"></i> {{'cc.consumers.consumers-overview.upload' | translate}}
      </button>

    </div>
  </div>

  <router-outlet></router-outlet>
</ng-container>


