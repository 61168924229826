
import {Table3Config} from '../extra/table-3-config';

// import {McProcessItemGENERATED} from '../_generated/mc-process-item-generated.model';

export class McProcessItemTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colActionStartDatetime', 'Action Start Datetime ', 'actionStartDatetime', 'actionStartDatetime');
    this.addColumn('colAddedInIdMcProcessRun', 'Added In McProcessRun ID', 'addedInIdMcProcessRun', 'addedInIdMcProcessRun');
    this.addColumn('colIdMcConsumer', 'McConsumer ID', 'idMcConsumer', 'idMcConsumer');
    this.addColumn('colIdMcProcess', 'McProcess ID', 'idMcProcess', 'idMcProcess');
    this.addColumn('colIdMcProcessFlow', 'McProcessFlow ID', 'idMcProcessFlow', 'idMcProcessFlow');
    this.addColumn('colIdMcProcessFlowLevel', 'McProcessFlowLevel ID', 'idMcProcessFlowLevel', 'idMcProcessFlowLevel');
    this.addColumn('colIdPmTransaction', 'Transaction ID', 'idPmTransaction', 'idPmTransaction');
    this.addColumn('colLastPaymentDueDatetime', 'Last PaymentDue Datetime ', 'lastPaymentDueDatetime', 'lastPaymentDueDatetime');
    this.addColumn('colMcProcess', 'Mc Process', 'mcProcess', 'mcProcess');
    this.addColumn('colMcProcessFlow', 'Mc Process Flow', 'mcProcessFlow', 'mcProcessFlow');
    this.addColumn('colMcProcessFlowLevel', 'Mc Process Flow level', 'mcProcessFlowLevel', 'mcProcessFlowLevel');
    this.addColumn('colPmTransaction', 'Pm Transaction', 'pmTransaction', 'pmTransaction');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');

*/
  }
}
