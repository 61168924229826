import {ImDataExportFilterGENERATED} from '../_generated/im-data-export-filter-generated.model';

export class ImDataExportFilter extends ImDataExportFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataExportFilter();
  }

  // ---------------------------------------------------------------------
}
