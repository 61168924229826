<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcProcessFlow.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h1 *ngIf="mcProcessFlow.exists()">{{'cc.common.view.edit' | translate}} {{'cc.contract.fees' | translate}}</h1>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!mcProcessFlow.apiLoadingFlg" class="content-body">
    <mcc-message [errorMsg]="mcProcessFlow.apiErrorMessage"></mcc-message>
    <mcc-message [errorMsgs]="errorMsgs" label="Unable to proceed: "></mcc-message>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container>

      <div class="row">
        <div class="col-md-12 pl-0">
          <ng-container>
            <div class="section-header">
              <h3>{{'cc.contract.fees' | translate}}</h3>
            </div>
            <div class="row">

              <div class="col-md-6">
                <div class="section-sub-header">
                  <h3>{{'cc.processes.interest-fee' | translate}}</h3>
                </div>
                <div class="form-inner-content pb-3">
                  <div>

                    <div class="position-relative">
                      <mcc-fi-radio-buttons2 label="{{'cc.processes.person-interest-rate' | translate}}"
                                             [name]="'ctrlInterestRatePerson'"
                                             [directionHorizontal]="false"
                                             [mcForm]="mcForm"
                                             [value]="mcProcessFlow.isPersonInterestRateTypeCdDEFAULT() ? 'DEFAULT' : 'ENTER'"
                                             [validRequiredFlg]="true"
                                             (eventValueChanged)="radioButtonsValueChange($event)"
                                             [optionsVll]="personInterestRateVll"></mcc-fi-radio-buttons2>
                      <div class="info-icon-positioning">
                        <i class="mc-blue-icon fas fa-info-circle"
                           ngbTooltip="Current base interest rate of the German Central bank is -0,8%. "
                           placement="bottom-right" tooltipClass="mc-tooltip-length"></i>
                      </div>
                    </div>


                    <div class="mt-n3">
                      <div class="position-relative">
                        <mcc-fi-number-type [mcForm]="mcForm"
                                            [value]="mcProcessFlow.isPersonInterestRateTypeCdENTER() ? mcProcessFlow.interestRateForPersonAmount : ''"
                                            [extraCssClasses]="mcForm.getValue('ctrlInterestRatePerson') !== 'DEFAULT'? '' : 'mc-disable'"
                                            nameForValue="ctrlPersonInterestRateAmount" label=""
                                            nameForType="ctrlPersonInterestRateType"
                                            validNumberWithTwoDecimals="true"
                                            [validMaxNumber]="mcForm.getValue('ctrlInterestRatePerson') !== 'DEFAULT' ? 5 : null"
                                            [validRequiredFlg]="mcForm.getValue('ctrlInterestRatePerson')!== 'DEFAULT'"
                                            [disableMoneyValue]="true"
                                            valueType="percentage"></mcc-fi-number-type>

                        <div class="info-icon-positioning3">
                          <i class="mc-blue-icon fas fa-info-circle"
                             ngbTooltip="Maximal custom interest rate is 5,00%"
                             placement="bottom-right" tooltipClass="mc-tooltip-length"></i>
                        </div>
                      </div>
                    </div>

                  </div>

                  <!--                <span>Company</span>-->
                  <div>
                    <div class="position-relative">
                      <mcc-fi-radio-buttons2 label="{{'cc.processes.company-interest-rate' | translate}}"
                                             [name]="'ctrlInterestRateCompany'"
                                             [directionHorizontal]="false"
                                             [mcForm]="mcForm"
                                             [value]="mcProcessFlow.isCompanyInterestRateTypeCdDEFAULT() ? 'DEFAULT' : 'ENTER'"
                                             [validRequiredFlg]="true"
                                             (eventValueChanged)="radioButtonsValueChange($event)"
                                             [optionsVll]="companyInterestRateVll"></mcc-fi-radio-buttons2>
                      <div class="info-icon-positioning">
                        <i class="mc-blue-icon fas fa-info-circle"
                           ngbTooltip="Current base interest rate of the German Central bank is -0,8%. "
                           placement="bottom-right" tooltipClass="mc-tooltip-length"></i></div>
                    </div>
                    <div class="mt-n3">
                      <div class="position-relative">
                        <mcc-fi-number-type [mcForm]="mcForm"
                                            [value]="mcProcessFlow.isCompanyInterestRateTypeCdENTER() ? mcProcessFlow.interestRateForCompanyAmount : ''"
                                            [extraCssClasses]="mcForm.getValue('ctrlInterestRateCompany') !== 'DEFAULT'? '' : 'mc-disable'"
                                            nameForValue="ctrlCompanyInterestRateAmount" label=""
                                            nameForType="ctrlCompanyInterestRateType"
                                            validNumberWithTwoDecimals="true"
                                            [validMaxNumber]="mcForm.getValue('ctrlInterestRateCompany') !== 'DEFAULT'? 9 : null"
                                            [validRequiredFlg]="mcForm.getValue('ctrlInterestRateCompany')!== 'DEFAULT'"
                                            [disableMoneyValue]="true"
                                            valueType="percentage"></mcc-fi-number-type>

                        <div class="info-icon-positioning3">
                          <i class="mc-blue-icon fas fa-info-circle"
                             ngbTooltip="Maximal custom interest rate is 9,00%"
                             placement="bottom-right" tooltipClass="mc-tooltip-length"></i></div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

              <div class="col-md-6">
                <div class="section-sub-header">
                  <h3>Dunning fee</h3>
                </div>
                <div class="form-inner-content pb-3">
                  <mcc-fi-number-type [mcForm]="mcForm" [value]="mcProcessFlow.dunningNoteBaseFeeAmount"
                                      nameForValue="ctrlDunningNoteValue"
                                      label="{{'cc.processes.dunning-note-base-fee' | translate}}"
                                      nameForType="ctrlDunningNoteType"
                                      [chosenCurrency]="currency"
                                      [validRequiredFlg]="true"
                                      validNumberWithTwoDecimals="true" [disablePercentage]="true"
                                      [valueType]="currency"></mcc-fi-number-type>

                  <!--<mcc-fi-number-type [mcForm]="mcForm" [value]="mcProcessFlow.emailFeeAmount"
                                      label="Email fee"
                                      nameForValue="ctrlEmailFeeValue" label="{{'cc.processes.email-fee' | translate}}"
                                      nameForType="ctrlEmailFeeType"
                                      [maxDecimalPlaces]="2" [disablePercentage]="true"
                                      valueType="euro"></mcc-fi-number-type>

                  <mcc-fi-number-type [mcForm]="mcForm" [value]="mcProcessFlow.smsFeeAmount"
                                      label="SMS fee"
                                      nameForValue="ctrlSMSFeeValue" label="{{'cc.processes.sms-fee' | translate}}"
                                      nameForType="ctrlSMSFeeType"
                                      [maxDecimalPlaces]="2" [disablePercentage]="true"
                                      valueType="euro"></mcc-fi-number-type>

                  <mcc-fi-number-type [mcForm]="mcForm" [value]="mcProcessFlow.postFeeAmount"
                                      label="POST fee"
                                      nameForValue="ctrlPostFeeValue" label="{{'cc.processes.post-fee' | translate}}"
                                      nameForType="ctrlPostFeeType"
                                      [maxDecimalPlaces]="2" [disablePercentage]="true"
                                      valueType="euro"></mcc-fi-number-type>-->
                </div>
              </div>

              <!-----------  END OF FEES -------->

            </div>
          </ng-container>

        </div>
      </div>
    </ng-container>
  </div><!-- row -->


</div><!-- content-body -->

<!-- == FOOTER ======================================== -->
<!--THIS CONTAINER IS MANDATORY-->
<div class="content-footer">

  <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
              label="{{'cc.common.edit.cancel' | translate}}"></mcc-button>
  <mcc-button *ngIf="(mcProcessFlow.apiSuccessFlg)" extraCssClasses="btn btn-primary"
              (eventClicked)="actSave();" label="{{'cc.common.view.save' | translate}}"
              [disabledMcb]="saveButtonDisabledMcb"
              disableOnFirstClickFlg="true"></mcc-button>
</div>
<router-outlet></router-outlet>
