import {McBillingServiceUnitTypeFilterGENERATED} from '../_generated/mc-billing-service-unit-type-filter-generated.model';

export class McBillingServiceUnitTypeFilter extends McBillingServiceUnitTypeFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingServiceUnitTypeFilter();
  }

  // ---------------------------------------------------------------------
}
