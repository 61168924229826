import { Component, OnInit } from '@angular/core';
import {CsrConsumer} from "@miticon-ui/mc-core";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'lib-mc-consumer-consumer-360-additional-info-part',
  templateUrl: './mc-consumer-consumer-360-additional-info-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-additional-info-part.component.scss']
})
export class McConsumerConsumer360AdditionalInfoPartComponent implements OnInit {
  csrConsumer: CsrConsumer =  new CsrConsumer();

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.actLoad();
  }

  actLoad() {
    this.route.paramMap.subscribe((res) => {
      this.csrConsumer.apiLoadingFlg = true
      // @ts-ignore
      this.csrConsumer.loadById(+res.get('id'), () => {
        this.csrConsumer.apiLoadingFlg = false;
      });
    });
  }

  hasCustomInfo() {
    for (const key in this.csrConsumer.additionalAttributes) {
      if (this.csrConsumer.additionalAttributes.hasOwnProperty(key)) {
        if (!key.includes("contract")) {
          return true;
        }
      }
    }
    return false;
  }
}
