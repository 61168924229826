<h3 class="page-title">{{ 'cc.finance' | translate }}</h3>

<div class="actions-container">
  <span>{{ 'cc.finance.reports-list' | translate }}</span>
  <div class="actions-wrapper">
    <button (click)="generateReport()" class="system-action-btn mr-2" style="gap: 10px">
      <span [innerHTML]="iconGenerate | safeHtml"></span>
      {{ 'cc.finance.generate-report' | translate}}
    </button>
  </div>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  [itemsPerPage]="pageSize"
  (filterChanged)="onFilterChanged($event)"
  [mkMatMenuActionItems]="mkMatMenuActionItems"
  (actionMkMatMenuItems)="onActionMkMatMenuItems($event)"
  [searchTooltip]="searchTooltip"
>
</mk-mat-table>
