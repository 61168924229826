import {Component, OnInit} from '@angular/core';
import {McBoolean, McForm, McGod, McPriceList, McPriceListFilter, McPriceListService, SortCriteriaList} from "@miticon-ui/mc-core";
import {Router} from "@angular/router";
import {MkFilterConfig, MkFilterOutput, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig} from "@miticon-ui/mc-components";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'mc-pricelist-templates-list-page',
  templateUrl: './mc-pricelist-templates-list-page.component.html',
})
export class McPricelistTemplatesListPageComponent implements OnInit {
  mcForm = new McForm();
  mcGod = McGod.getInstance();
  showAddEditMcPricelistTemplateMcb = new McBoolean();
  entityId!: number;
  mcTemplateFilter = new McPriceListFilter();

  totalItemsCount!: number;
  pageSize = 5;
  pageNumber= 0;
  isLoading = false;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  items: McPriceList[] = [];
  searchTooltip = this.tS.instant('cc.pricelist.search-tooltip');

  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.my-factoring.view-details'),
      actionCd: McPriceList.ACTION_VIEW_DETAILS,
      matIcon: 'visibility',
      permission: McGod.PERM_MC_BILLING_PRICELIST_VIEW
    }
  ];

  constructor(private router: Router,
              private tS: TranslateService,
              private mcPricelistService: McPriceListService,
              private toastrService: ToastrService) {
  }

  ngOnInit() {
    this.entityId = McGod.getLoggedEntityIdFromToken();
    this.initTableConfig();
    this.mcTemplateFilter.idEntityOwner = this.entityId;
    this.mcTemplateFilter.isTemplate = true;
    this.actLoad(this.pageNumber, this.pageSize, this.mcTemplateFilter);
  }

  initTableConfig(){
    this.tableConfig.addColumnStandard(this.tS.instant('cc.pricelist.template-name'), 'title', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.pricelist.template-description'), 'description', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.pricelist.no-of-pricelists-created'), 'fldNumberOfCreatedPricelists()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.pricelist.no-of-entities'), 'fldNumberOfEntities()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.created-date-and-time'), 'getSysCreatedDatetimeString()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.pricelist.created-by'), 'createdByUserFullName', 250);
  }

  actLoad(pageNumber: number, pageSize: number, mcPriceListFilter: McPriceListFilter): void {
    this.pageNumber=pageNumber;
    this.pageSize= pageSize;
    this.isLoading = true;
    const sortCriteriaList = new SortCriteriaList();

    this.mcPricelistService.getByFilter(mcPriceListFilter, this.pageNumber, this.pageSize, sortCriteriaList).subscribe((res) => {
      if(res){
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => McPriceList.createFromJson(object));
      }
    })
  }

  onFilterChanged(filterOutput: MkFilterOutput){
    this.mcTemplateFilter.searchTerm = filterOutput.search ? filterOutput.search : '';
    this.mcTemplateFilter.idEntityOwner = this.entityId;
    this.mcTemplateFilter.isTemplate = true;
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, this.mcTemplateFilter);
  }

  onTableAction($event: MkMatTableMenuAction) {
    switch ($event.action.actionCd) {
      case McPriceList.ACTION_VIEW_DETAILS:
        this.router.navigate([`/entity/${this.entityId}/pricelists/overview/${$event.item.id}`]);
        break;
    }
  }

  actShowAddPricelistTemplateMcb() {
    this.showAddEditMcPricelistTemplateMcb.setTrue();
  }

  onAddTemplatePricelistSaveEmit() {
    this.showAddEditMcPricelistTemplateMcb.setFalse();
    this.toastrService.success(this.tS.instant('cc.pricelist.pricelist-template-created'));
    this.actLoad(this.pageNumber, this.pageSize, this.mcTemplateFilter);
  }
}
