<div class="content-wrapper">
  <div *ngIf="ebConsumerBlacklist.apiErrorFlg">
    ERROR
    {{ebConsumerBlacklist.apiErrorMessage}}
  </div>
  <div *ngIf="ebConsumerBlacklist.apiLoadingFlg">
    <img src="/assets/images/loading-whitebg.gif"/>
  </div>
  <div class="content-header">
    <h3>{{'cc.out-factoring.remove-from-blacklist' | translate}} {{ebConsumerBlacklist.firstName}} {{ebConsumerBlacklist.lastName}}</h3>
  </div>
  <div class="content-body" *ngIf="ebConsumerBlacklist.apiSuccessFlg">
    <div class="row">
      <!--FIRST COLUMN-->
      <div class="col-md-6">



        <mcc-row-title-description title="{{'cc.common.edit.first-name' | translate}}:"
                                   [description]="ebConsumerBlacklist.firstName"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.common.edit.last-name' | translate}}:"
                                   [description]="ebConsumerBlacklist.lastName"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.common.edit.birthday' | translate}}:"
                                   [description]="ebConsumerBlacklist.birthday | date"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.common.iban' | translate}}" [description]="ebConsumerBlacklist.iban"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.consumers.edit.street' | translate}}:"
                                   [description]="ebConsumerBlacklist.street"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.common.edit.city' | translate}}" [description]="ebConsumerBlacklist.city"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.consumers.edit.postal-code' | translate}}:"
                                   [description]="ebConsumerBlacklist.postCode"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.common.edit.email' | translate}}:" [description]="ebConsumerBlacklist.email"></mcc-row-title-description>
      </div>
      <div class="col-md-6">

        <mcc-row-title-description title="{{'cc.out-factoring.transaction-id' | translate}}:"
                                   [description]="ebConsumerBlacklist.idTransaction"></mcc-row-title-description>

        <mcc-row-title-description title="External Transaction ID:"
                                   [description]="ebConsumerBlacklist.extTransactionId"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.out-factoring.allow-factoring' | translate}}:"
                                   [description]="ebConsumerBlacklist.allowFactoringFlg ? 'yes' : 'no'"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.out-factoring.allow-direct-debit'}}:"
                                   [description]="ebConsumerBlacklist.allowDirectDebitFlg ? 'yes' : 'no'"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.out-factoring.added-date-&-time' | translate}}:"
                                   [description]="ebConsumerBlacklist.fldAddedDate()"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.out-factoring.added-reason' | translate}}:"
                                   [description]="ebConsumerBlacklist.addedReason"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.out-factoring.added-by' | translate}}:"
                                   [description]="ebConsumerBlacklist.addedByUser"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.out-factoring.removed-date-&-time' | translate}}:"
                                   [description]="ebConsumerBlacklist.fldRemovedDate()"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.out-factoring.removed-reason' | translate}}:"
                                   [description]="ebConsumerBlacklist.removedReason"></mcc-row-title-description>

        <mcc-row-title-description title="{{'cc.out-factoring.removed-by' | translate}}:"
                                   [description]="ebConsumerBlacklist.removedByUser"></mcc-row-title-description>
      </div>
      <div class="col-md-12 mt-3">
        <mcc-fi-textbox [mcForm]="mcForm" [name]="'txbBlacklistReason'" label="{{'cc.my-factoring.reason' | translate}}" [validRequiredFlg]="true"
                        [validWhitespaces]="true"></mcc-fi-textbox>
      </div>

    </div>
  </div>
  <div class="content-footer">
    <button type="button" class="btn btn-secondary move-right" (click)="actCancel()">{{'cc.common.edit.cancel' | translate}}</button>
    <button type="button" class="btn btn-primary" (click)="actRemoveFromBlacklist()">{{'cc.common.table.remove' | translate}}</button>
  </div>
  <router-outlet></router-outlet>
</div>
