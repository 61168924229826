import {McBillingService2PriceList} from './mc-billing-service-2-price-list.model';
import {McBillingService2PriceListListGENERATED} from '../_generated/mc-billing-service-2-price-list-list-generated.model';

export class McBillingService2PriceListList extends McBillingService2PriceListListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingService2PriceListList();
  }

  // ---------------------------------------------------------------------
}
