import {Component, Inject, OnInit} from '@angular/core';
import {
  EbFactoring,
  EbSepaExport, EbSepaFile,
  EbSepaFileTransaction,
  McConsumer,
  McEntityConsumerAttribute,
  McEntityService,
  McProcessFlowCriteria,
  McProcessFlowLevel,
  WebFile,
  McGod, McProcessRunItem, McRoleService, McProcessItem, McBillingStatement
} from "@miticon-ui/mc-core";

@Component({
  selector: 'mc-dev-index-page',
  templateUrl: './mc-dev-index-page.component.html',
})

export class McDevIndexPageComponent implements OnInit {
  // -- Variables -------------------------------
  public mcConsumer = new McConsumer();
  public ebTransactions = new EbSepaFileTransaction();
  public ebSepaExport = new EbSepaExport();
  public ebSepaFile = new EbSepaFile();
  public mcEntityConsumer = new McEntityConsumerAttribute();
  public ebFactoring = new EbFactoring();
  public mcProcessFlowCriteria = new McProcessFlowCriteria();
  public mcProcessFlowLevel = new McProcessFlowLevel();
  public mcProcessRunItem = new McProcessRunItem();
  public mcProcessItem = new McProcessItem();
  public mcBillingStatement = new McBillingStatement();
  public webFile = new WebFile();
  public message: string;
  public messageType: string;

  // ---------------------------------------------------------------------
  constructor(public entityService: McEntityService, private roleService: McRoleService) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
  }

  fixConsumerStatusAndTypeCd() {
    this.mcConsumer.callDevFixConsumerStatusAndTypeCode(() => {
      this.message = 'Status and Type Code Fixed';
      this.messageType = 'success';
    }, () => {
      this.message = this.mcConsumer.apiErrorMessage;
      this.messageType = 'error';
    });
  }

  fixCustomAttributes() {
    this.mcEntityConsumer.callDevFixConsumerAttributes(() => {
      this.message = 'Consumer Attributes Fixed';
      this.messageType = 'success';
    }, () => {
      this.message = this.mcConsumer.apiErrorMessage;
      this.messageType = 'error';
    });
  }

  fixTransactions() {
    this.ebTransactions.callDevFixTransactions(() => {
      this.message = 'Transactions Fixed';
      this.messageType = 'success';
    }, () => {
      this.message = this.mcConsumer.apiErrorMessage;
      this.messageType = 'error';
    });
  }

  onAddSuperUserWithAllPermissionsInAllEntities() {
    this.entityService.addSuperUserWithAllPermissionsInAllEntities().subscribe(data => {
      this.messageType = 'success';
      this.message = 'successfully added users.';
    }, error => {
      console.log('-- Error while adding user to all entities -->>', error);
      this.messageType = 'error';
      this.message = 'Error while adding user to all entities.';
    });
  }

  onFixConsumerHistory() {
    this.entityService.fixConsumerHistory().subscribe(data => {
      this.messageType = 'success';
      this.message = 'successfully fixed consumer history.';
    }, error => {
      console.log('-- Error while adding user to all entities -->>', error);
      this.messageType = 'error';
      this.message = 'Error while fixing consumer history.';
    });
  }

  fixContractNumbers() {
    this.mcConsumer.callDevFixConsumerContractNumber(() => {
      this.message = 'Contract Numbers Fixed';
      this.messageType = 'success';
    }, () => {
      this.message = this.mcConsumer.apiErrorMessage;
      this.messageType = 'error';
    });
  }
  // fixRunItemsConsumerActionsWithoutTitle() {
  //   this.mcProcessRunItem.callDevFixRunItemsConsumerActionsWithoutTitle(() => {
  //     this.message = 'Run Items Consumer Actions Without Title Fixed';
  //     this.messageType = 'success';
  //   }, () => {
  //     this.message = this.webFile.apiErrorMessage;
  //     this.messageType = 'error';
  //   });
  // }

  fixRoleAncestorEntityName() {
    this.roleService.fixRoleAncestorEntityName().subscribe(() => {
      this.message = 'Role Ancestor Entity Name Fixed';
      this.messageType = 'success';
    }, (error) => {
      this.message = error.error.message;
      this.messageType = 'error';
    });
  }

  fixTemplateRoleNumberOfChildren() {
    this.roleService.fixTemplateRoleNumberOfChildren().subscribe(() => {
      this.message = 'Template Role Number Of Children Fixed';
      this.messageType = 'success';
    }, (error) => {
      this.message = error.error.message;
      this.messageType = 'error';
    });
  }

  fixBeneficiaryEntityId() {
    this.ebTransactions.callDevFixBeneficiaryEntityId(() => {
      this.message = 'Beneficiary Entity Id Fixed';
      this.messageType = 'success';
    }, () => {
      this.message = this.ebTransactions.apiErrorMessage;
      this.messageType = 'error';
    });
  }

  // fixChangeStatusForSettled() {
  //   this.mcProcessItem.callDevFixChangeStatusForSettled(() => {
  //     this.message = 'Status for Settled fixed';
  //     this.messageType = 'success';
  //   }, () => {
  //     this.message = this.mcProcessItem.apiErrorMessage;
  //     this.messageType = 'error';
  //   });
  // }

}
