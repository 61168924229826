/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EbInterest_rateTable3ConfigGENERATED} from '../_generated/eb-interest_rate-table-3-config-generated.model';

// import {EbInterest_rateGENERATED} from '../_generated/eb-interest_rate-generated.model';

export class EbInterest_rateTable3Config extends EbInterest_rateTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colTypeCd', 'Type CD', 'typeCd', 'typeCd');
    this.addColumn('colInterestRate', 'Interest Rate', 'interestRate', 'interestRate');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colStartDatetime', 'Start DateTime', 'startDatetime', 'startDatetime');
    this.addColumn('colEndDatetime', 'End DateTime', 'endDatetime', 'endDatetime');


  }
}
