
import {Table3Config} from '../extra/table-3-config';

// import {CsrDiscountGENERATED} from '../_generated/csr-discount-generated.model';

export class CsrDiscountTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colIdMcEntity', 'EntityId', 'idMcEntity', 'idMcEntity');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colTypeCd', 'Status code', 'typeCd', 'typeCd');
    this.addColumn('colValue', 'Value', 'value', 'value');

*/
  }
}
