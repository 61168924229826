
import {Table3Config} from '../extra/table-3-config';

// import {McPriceListGENERATED} from '../_generated/mc-price-list-generated.model';

export class McPriceListTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colIdEntityOwner', 'Entity who owns this price list', 'idEntityOwner', 'idEntityOwner');
    this.addColumn('colIdMcCurrency', 'Currency Id', 'idMcCurrency', 'idMcCurrency');
    this.addColumn('colIdParentTemplate', 'Template of this price list', 'idParentTemplate', 'idParentTemplate');
    this.addColumn('colMcCurrency', 'Currency', 'mcCurrency', 'mcCurrency');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colValidFrom', 'Price list is valid from this date', 'validFrom', 'validFrom');
    this.addColumn('colValidTo', 'Price list is valid until this date', 'validTo', 'validTo');

*/
  }
}
