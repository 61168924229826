/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McPdfTemplateTable3ConfigGENERATED} from '../_generated/mc-pdf-template-table-3-config-generated.model';

// import {McPdfTemplateGENERATED} from '../_generated/mc-pdf-template-generated.model';

export class McPdfTemplateTable3Config extends McPdfTemplateTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    // this.addColumn('colIdWebfile', 'Webfile ID', 'idWebfile', 'idWebfile');
      this.addColumn('colActions', '', 'fldActions()', '');
      this.addColumn('colTitle', 'Title', 'title', 'title');
      this.addColumn('colTypeCd', 'Type code', 'getTypeCdLabel()', 'typeCd');
      this.addColumn('colDescription', 'Description', 'description', 'description');
      this.addColumn('colStatusCd', 'Status code', 'getStatusCdLabel()', 'statusCd');


  }
}
