import { McOutBillingCreatePayoutFilePartComponent } from './components/mc-out-billing-create-payout-file-part/mc-out-billing-create-payout-file-part.component';
import { McOutBillingPayoutFileProfileComponent } from './pages/mc-out-billing-payout-file-profile/mc-out-billing-payout-file-profile.component';
import { McOutBillingPayoutFileAddEditPartComponent } from './components/mc-out-billing-payout-file-add-edit-part/mc-out-billing-payout-file-add-edit-part.component';
import { McOutBillingPayoutFileListPageComponent } from './pages/mc-out-billing-payout-file-list-page/mc-out-billing-payout-file-list-page.component';
import { McOutBillingRenameStatementPartComponent } from './components/mc-out-billing-rename-statement-part/mc-out-billing-rename-statement-part.component';
import { McOutBillingFinalizeStatementPartComponent } from './components/mc-out-billing-finalize-statement-part/mc-out-billing-finalize-statement-part.component';
import { McOutBillingAddCorrectionPartComponent } from './components/mc-out-billing-add-correction-part/mc-out-billing-add-correction-part.component';
import { McOutBillingRemoveFromStatementPartComponent } from './components/mc-out-billing-remove-from-statement-part/mc-out-billing-remove-from-statement-part.component';
import { McOutBillingAggregateDeaggregatePartComponent } from './components/mc-out-billing-aggregate-deaggregate-part/mc-out-billing-aggregate-deaggregate-part.component';
import { McOutBillingAddStatementItemsPartComponent } from './components/mc-out-billing-add-statement-items-part/mc-out-billing-add-statement-items-part.component';
import { McOutBillingStatementProfilePageComponent } from './pages/mc-out-billing-statement-profile-page/mc-out-billing-statement-profile-page.component';
import { McOutBillingAssignToStatementPartComponent } from './components/mc-out-billing-assign-to-statement-part/mc-out-billing-assign-to-statement-part.component';
import { McOutBillingAggregationAddEditPartComponent } from './components/mc-out-billing-aggregation-add-edit-part/mc-out-billing-aggregation-add-edit-part.component';
import { McOutBillingAggregatePartComponent } from './components/mc-out-billing-aggregate-part/mc-out-billing-aggregate-part.component';
import { McOutBillingBillingItemListPageComponent } from './pages/mc-out-billing-billing-item-list-page/mc-out-billing-billing-item-list-page.component';
import { McOutBillingStatementListPageComponent } from './pages/mc-out-billing-statement-list-page/mc-out-billing-statement-list-page.component';
import { McOutBillingStatementAddEditPartComponent } from './components/mc-out-billing-statement-add-edit-part/mc-out-billing-statement-add-edit-part.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McOutBillingRoutingModule } from './mc-out-billing-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {McFilterModule, McTableModule, UploadFileModule} from '@miticon-ui/mc-components';
import {TranslateModule} from "@ngx-translate/core";
import {AppSharedModule} from '../app-shared/app-shared.module';

@NgModule({
  declarations: [
McOutBillingCreatePayoutFilePartComponent,
McOutBillingPayoutFileProfileComponent,
McOutBillingPayoutFileAddEditPartComponent,
McOutBillingPayoutFileListPageComponent,
McOutBillingRenameStatementPartComponent,
McOutBillingFinalizeStatementPartComponent,
McOutBillingAddCorrectionPartComponent,
McOutBillingRemoveFromStatementPartComponent,
McOutBillingAggregateDeaggregatePartComponent,
McOutBillingAddStatementItemsPartComponent,
McOutBillingStatementProfilePageComponent,
McOutBillingAssignToStatementPartComponent,
McOutBillingAggregationAddEditPartComponent,
McOutBillingAggregatePartComponent,
McOutBillingBillingItemListPageComponent,
McOutBillingStatementListPageComponent,
McOutBillingStatementAddEditPartComponent,
  ],
    imports: [
        CommonModule,
        McOutBillingRoutingModule,
        NgbModule,
        McComponentsModule,
        McFilterModule,
        McTableModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AppSharedModule,
      UploadFileModule
    ],
  exports: [
McOutBillingCreatePayoutFilePartComponent,
McOutBillingPayoutFileProfileComponent,
McOutBillingPayoutFileAddEditPartComponent,
McOutBillingPayoutFileListPageComponent,
McOutBillingRenameStatementPartComponent,
McOutBillingFinalizeStatementPartComponent,
McOutBillingAddCorrectionPartComponent,
McOutBillingRemoveFromStatementPartComponent,
McOutBillingAggregateDeaggregatePartComponent,
McOutBillingAddStatementItemsPartComponent,
McOutBillingStatementProfilePageComponent,
McOutBillingAssignToStatementPartComponent,
McOutBillingAggregationAddEditPartComponent,
McOutBillingAggregatePartComponent,
McOutBillingBillingItemListPageComponent,
McOutBillingStatementListPageComponent,
McOutBillingStatementAddEditPartComponent,
    McOutBillingRoutingModule,
    FormsModule,
  ]
})
export class McOutBillingModule { }
