<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcProcessFlow.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h1 *ngIf="mcProcessFlow.exists()">{{'cc.common.view.edit' | translate}} {{'cc.common.general-info' | translate}}</h1>
    <h1 *ngIf="!mcProcessFlow.exists()">Add McProcessFlow (#{{mcProcessFlow.id}})</h1>

  </div>

  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!mcProcessFlow.apiLoadingFlg" class="content-body">
    <mcc-message [errorMsg]="mcProcessFlow.apiErrorMessage"></mcc-message>
    <mcc-message [errorMsgs]="errorMsgs" label="Unable to proceed: "></mcc-message>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container>

      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <h3>{{'cc.common.general-info' | translate}}</h3>
          </div>
          <div class="form-inner-content">
            <div class="row">
              <div class="col-md-6">
                <mcc-fi-radio-buttons label="{{'cc.common.view.status' | translate}}"
                                      [name]="'ctrlStatus'"
                                      [directionHorizontal]="true"
                                      [mcForm]="mcForm"
                                      [value]="mcProcessFlow.statusCd"
                                      [validRequiredFlg]="true"
                                      [optionsVlm]="statusVlm"></mcc-fi-radio-buttons>
              </div>
              <div class="col-md-6" style="margin-top: 20px">
                <mcc-fi-select  name="selDay" label="{{'cc.common.day' | translate}}"
                                placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                                [validRequiredFlg]="true"
                                [value]="mcProcessFlow.weekdayToSendNotification"
                                [optionsVll]="optionsWeekDaysVll"
                                (eventValueChanged)="onDayChanged($event)"
                                [mcForm]="mcForm"></mcc-fi-select>
              </div>
            </div>
          </div>
          <div class="form-inner-content">
            <mcc-fi-textbox label="{{'cc.common.edit.title' | translate}}" name="ctrlTitle" [value]="mcProcessFlow.title" [mcForm]="mcForm" [validRequiredFlg]="true"></mcc-fi-textbox>

            <mcc-fi-textarea [name]="'ctrlDescription'" [value]="mcProcessFlow.description" label="{{'cc.common.view.description' | translate}}" [numCols]="45"
                             [validRequiredFlg]="true" [mcForm]="mcForm"></mcc-fi-textarea>
          </div>
        </div>
      </div><!-- row -->
    </ng-container>
  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="{{'cc.common.edit.cancel' | translate}}"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actSave();" label="{{'cc.common.view.save' | translate}}" [disabledMcb]="saveButtonDisabledMcb"
                disableOnFirstClickFlg="true"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
