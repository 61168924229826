
import {Table3Config} from '../extra/table-3-config';

// import {PpInvoiceGENERATED} from '../_generated/pp-invoice-generated.model';

export class PpInvoiceTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDueDate', 'Due date', 'dueDate', 'dueDate');
    this.addColumn('colIdPpOrder', 'ID of the order', 'idPpOrder', 'idPpOrder');
    this.addColumn('colInvoiceData', 'Invoice data', 'invoiceData', 'invoiceData');
    this.addColumn('colInvoiceNumber', 'Invoice number', 'invoiceNumber', 'invoiceNumber');
    this.addColumn('colSenderId', 'Sender ID', 'senderId', 'senderId');
    this.addColumn('colSentDate', 'Sent date', 'sentDate', 'sentDate');
    this.addColumn('colState', 'State', 'state', 'state');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');
    this.addColumn('colTotalAmount', 'Total amount', 'totalAmount', 'totalAmount');
    this.addColumn('colVersion', 'Version', 'version', 'version');

*/
  }
}
