/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McEbicsFilter} from '../models/mc-ebics-filter.model';

export class McEbicsFilterGENERATED extends BaseObjectFilter{    public searchTerm: string | undefined;
    public ebicsStatuses: string[] = [];

  public properties: string[] = ['searchTerm', 'ebicsStatuses', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McEbicsFilter {
    console.error('MTCN-ERROR: Not Implemented: McEbicsFilter::createNewInstance(). Add this method to final class and return new McEbicsFilter();');
      throw new Error('Cannot McEbicsFilter::createNewInstance(). Add this method to final class and return new McEbicsFilter();');
  }

}
