import { Component,OnInit } from '@angular/core';
import { MkFilterConfig,MkFilterItemType,MkFilterOutput,MkFilterValue,MkMatMenuItem,MkMatTableMenuAction,MkTableConfig } from '@miticon-ui/mc-components';
import { EbFactoring,EbFactoringFilter,EbFactoringService,McBoolean,McForm,McGod,SortCriteriaList } from '@miticon-ui/mc-core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

const enum DialogAction {
  ACT_CANCEL = "btn-secondary",
  ACT_ACCEPT = "btn-primary"
}

@Component({
  selector: 'mc-my-factoring-contract-list-page',
  templateUrl: './mc-my-factoring-contract-list-page.component.html',
})
export class McMyFactoringContractListPageComponent implements OnInit {
  mcForm = new McForm();
  ebFactoringFilter = new EbFactoringFilter();
  showRejectMcb = new McBoolean();
  showProfileMcb = new McBoolean();
  ebFactoring!: EbFactoring | null;
  factoringToAccept!: EbFactoring;
  statusPendingMcb = new McBoolean();
  statusNotPendingMcb = new McBoolean();
  showAcceptContractDialogMcb = new McBoolean();
  acceptDialogButtonsKvm = new Map<string, string>();
  mcGod = McGod.getInstance();
  searchTooltip = this.tS.instant('cc.my-factoring.search-by-contract-name');
  items: EbFactoring[] = [];
  pageNumber = 0;
  pageSize = 5;
  isLoading = false;
  totalItemsCount!: number;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.consumer.filter.view-contract'),
      actionCd: EbFactoring.ACTION_SHOW_PROFILE,
      matIcon: 'document_scanner',
      permission: McGod.PERM_EB_FACTORING_OUT_CONTRACT_VIEW,
    },
    {
      title: this.tS.instant('Accept'),
      actionCd: EbFactoring.ACTION_SHOW_ACCEPT,
      matIcon: 'check',
      permission: McGod.PERM_EB_FACTORING_OUT_CONTRACT_VIEW,
    },
    {
      title: this.tS.instant('Reject'),
      actionCd: EbFactoring.ACTION_SHOW_REJECT,
      matIcon: 'close',
      permission: McGod.PERM_EB_FACTORING_OUT_CONTRACT_VIEW,
    },
  ];

  constructor(private ebFactoringService: EbFactoringService, private tS: TranslateService, private toastr: ToastrService) {}

  ngOnInit() {
    this.acceptDialogButtonsKvm.set('btn-primary', this.mcGod.t('cc.factoring.accept'));
    this.acceptDialogButtonsKvm.set('btn-secondary', this.mcGod.t('cc.common.edit.cancel'));

    this.initTableConfig();
    this.initFilterConfig();
    this.actLoad(this.pageNumber, this.pageSize, this.ebFactoringFilter);
  }

  public actLoad(pageNumber: number, pageSize: number, ebFactoringFilter: EbFactoringFilter): void {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.isLoading = true;
    this.statusPendingMcb.setFalse();
    this.ebFactoringFilter.outFactoringFlg = false;
    const sortCriteriaList = new SortCriteriaList();
    this.ebFactoringService.getByFilter(ebFactoringFilter, this.pageNumber, this.pageSize, sortCriteriaList).subscribe((res) => {
      if (res) {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => EbFactoring.createFromJson(object));
      }
    });
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(this.tS.instant('cc.my-factoring.contract-name'), 'factoringContractName', 250);
    this.tableConfig.addColumnSvgIcon(this.tS.instant('cc.common.contract-type'), 'getContractTypeFld()');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.start-date'), 'getStartDate()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.end-date'), 'getEndDate()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.view.status'), 'getStatusCdLabel()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.rejection-reason'), 'contractRejectionReason', 250);
  }

  initFilterConfig() {
    const statusValueLableList = EbFactoring.getStatusCdVll();
    const typeValueLableList = EbFactoring.getTypeCdVll();
    this.filterConfig.addItem(
      MkFilterItemType.SELECT,
      this.tS.instant('cc.common.contract-type'),
      typeValueLableList.items.map((item) => new MkFilterValue(item.label, item.value))
    );
    this.filterConfig.addItem(
      MkFilterItemType.MULTISELECT,
      this.tS.instant('cc.common.view.status'),
      statusValueLableList.items.map((item) => new MkFilterValue(item.label, item.value))
    );
  }

  onFilterChanged(filterOutput: MkFilterOutput) {
    this.ebFactoringFilter.searchTerm = filterOutput.search ? filterOutput.search : '';
    this.ebFactoringFilter.contractType = filterOutput.selections[this.tS.instant('cc.common.contract-type')] ? filterOutput.selections[this.tS.instant('cc.common.contract-type')] : '';
    this.ebFactoringFilter.statusCds = MkFilterOutput.convertFilterSelectionToArray(
      filterOutput.selections[this.tS.instant('cc.common.view.status')] ? filterOutput.selections[this.tS.instant('cc.common.view.status')] : []
    );

    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, this.ebFactoringFilter);
  }

  onTableAction($event: MkMatTableMenuAction) {
    switch ($event.action.actionCd) {
      case EbFactoring.ACTION_SHOW_REJECT:
        this.actShowRejectModal($event.item);
        break;

      case EbFactoring.ACTION_SHOW_PROFILE:
        this.actShowProfileModal($event.item);
        if ($event.item.statusCd === EbFactoring.STATUS_CD_PENDING_ACCEPTANCE) {
          this.statusPendingMcb.setTrue();
          this.statusNotPendingMcb.setFalse();
        } else {
          this.statusPendingMcb.setFalse();
          this.statusNotPendingMcb.setTrue();
        }
        break;

      case EbFactoring.ACTION_SHOW_ACCEPT:
        this.showAcceptContractDialogMcb.setTrue();
        this.factoringToAccept = $event.item;
        break;
    }
  }

  actShowRejectModal(ebFactoring: EbFactoring) {
    this.ebFactoring = ebFactoring;
    if (this.ebFactoring.statusCd === EbFactoring.STATUS_CD_PENDING_ACCEPTANCE) {
      this.showRejectMcb.setTrue();
    } else {
      this.toastr.error(this.tS.instant('cc.consumer.filter.you-can-only-reject-contract-in-status-pending-acceptance'));
    }
  }

  actShowProfileModal(ebFactoring: EbFactoring) {
    this.ebFactoring = ebFactoring;
    this.showProfileMcb.setTrue();
  }

  actAcceptContract(ebFactoring: EbFactoring | null) {
    this.ebFactoring = ebFactoring;
    if (this.ebFactoring?.statusCd === EbFactoring.STATUS_CD_PENDING_ACCEPTANCE) {
      this.ebFactoring.statusCd = EbFactoring.STATUS_CD_ACCEPTED;
      this.ebFactoring.save(
        () => {
          this.actLoad(this.pageNumber, this.pageSize, this.ebFactoringFilter);

          this.toastr.success(`${this.mcGod.t('cc.contract.contract')} '${this.ebFactoring?.factoringContractName}' ${this.mcGod.t('cc.consumer.filter.accepted-successfully')}.`);
        },
        () => {
          this.toastr.error(this.ebFactoring?.apiErrorMessage);
        }
      );
    } else {
      this.toastr.error(this.tS.instant('cc.consumer.filter.you-can-only-accept-contract-in-status-pending-acceptance'));
    }
  }

  actRejected() {
    this.actLoad(this.pageNumber, this.pageSize, this.ebFactoringFilter);
    this.showRejectMcb.setFalse();
  }

  onRejectModalCanceled() {
    this.ebFactoring = null;
    this.showRejectMcb.setFalse();
  }

  onAcceptContract() {
    this.actAcceptContract(this.ebFactoring);
    this.showProfileMcb.setFalse();
  }

  onRejectContract() {
    this.showRejectMcb.setTrue();
  }

  onCancelContract() {
    this.showProfileMcb.setFalse();
    this.actLoad(this.pageNumber, this.pageSize, this.ebFactoringFilter);
  }

  onMcDialogBoxAcceptContractActionButtonClicked(action: string) {
    if (action === DialogAction.ACT_CANCEL) {
      this.showAcceptContractDialogMcb.setFalse();
    } else {
      this.actAcceptContract(this.factoringToAccept);
      this.showAcceptContractDialogMcb.setFalse();
    }
  }
}
