/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {PpOrderItem} from '../models/pp-order-item.model';
import {PpOrderItemService} from '../services/pp-order-item.service';
import {IPpOrderItem} from '../_generated/pp-order-item.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {PpPriceAdjustment} from '../models/pp-price-adjustment.model';


export abstract class PpOrderItemGENERATED extends BaseObject {


  public apiService: PpOrderItemService;
  public _rawJson: IPpOrderItem;
    id: number = 0;
  description = '';
  idPpOrder: number;
  idPpProduct: number;
  name = '';
  note = '';
  quantity: number;
  sorterId: number;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  sysUpdatedUserId: number;
  unitPrice: number;
  vat: number;
  version: number;
  discount: PpPriceAdjustment;

  public properties: string[] = ['id', 'description', 'idPpOrder', 'idPpProduct', 'name', 'note', 'quantity', 'sorterId', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'unitPrice', 'vat', 'version', 'discount'];
  public propertiesRegular: string[] = ['id', 'description', 'idPpOrder', 'idPpProduct', 'name', 'note', 'quantity', 'sorterId', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'unitPrice', 'vat', 'version'];
  public propertiesSpecial: string[] = ['discount'];


  // ---------------------------------------------------------------------
public static createNewInstance(): PpOrderItem {
    console.error('MTCN-ERROR: Not Implemented: PpOrderItem::createNewInstance(). Add this method to final class and return new PpOrderItem();');
    throw new Error('Cannot PpOrderItem::createNewInstance(). Add this method to final class and return new PpOrderItem();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IPpOrderItem): PpOrderItem {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(PpOrderItemService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE PpOrderItem AABB()');
          if (this._rawJson['discount']) {
        this.discount = PpPriceAdjustment.createFromJson(this._rawJson['discount']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getUnitPriceStr(): string {
       return McGod.formatNumberToMoney(this.unitPrice);
    }

    public getVatStr(): string {
       return McGod.formatNumberToMoney(this.vat);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('discount')) {
        if (this.discount != null) {
            dto['discount'] = this.discount.toDto();
        } else {
            dto['discount'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
