/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McConsumerBalanceFilter} from '../models/mc-consumer-balance-filter.model';

export class McConsumerBalanceFilterGENERATED extends BaseObjectFilter{    public entityIds: number[] = [];

  public properties: string[] = ['entityIds', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McConsumerBalanceFilter {
    console.error('MTCN-ERROR: Not Implemented: McConsumerBalanceFilter::createNewInstance(). Add this method to final class and return new McConsumerBalanceFilter();');
      throw new Error('Cannot McConsumerBalanceFilter::createNewInstance(). Add this method to final class and return new McConsumerBalanceFilter();');
  }

}
