/*BbmTestObj*/
import {McCountryGENERATED} from '../_generated/mc-country-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {McVat} from './mc-vat.model';
import {ActionOptions} from '../_core/mc-html-action-options';

export class McCountry extends McCountryGENERATED{

  public static ACTION_VIEW_DETAILS = 'VD';
  public static ACTION_EDIT_COUNTRY = 'EDIT';
  private _fldActions: McHtml | null = null;

  public numberOfEntities!: number;
  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McCountry();
  }

  // ---------------------------------------------------------------------

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> View details`, McCountry.ACTION_VIEW_DETAILS, this, [McGod.PERM_MC_COUNTRY_VIEW], '', ActionOptions.OPTION_VIEW);
      // * Edit country removed because adding country will be done directly in base
      // actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> Edit country`, McCountry.ACTION_EDIT_COUNTRY, this, [McGod.PERM_MC_COUNTRY_EDIT], '', ActionOptions.OPTION_EDIT);
      this._fldActions = html;
    }
    return this._fldActions;
  }

  fldNumberOfEntities() {
    return this.numberOfEntities ? this.numberOfEntities : '0';
  }

  fldVatNumberAndInfo() {
    let result = '';
    this.mcVATItemList.items.forEach( item => {
      if (item.vatRate && !isNaN(Number(item.vatRate))) {
        const vatRateNumber = Number(item.vatRate);
        result += `<span>${vatRateNumber.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})} % (${item.vatTypeCd === McVat.VAT_TYPE_CD_OTHER ? item.vatDescription : McVat.getVatTypeCdLabel(item.vatTypeCd)})</span> <br>`;
      }
      if (item.vatRate === 0) {
        result += `<span>${item.vatRate.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})} % (${item.vatTypeCd === McVat.VAT_TYPE_CD_OTHER ? item.vatDescription : McVat.getVatTypeCdLabel(item.vatTypeCd)})</span> <br>`;
      }
    });
    return result;
  }
}
