import {EbPaymentReport2SepaFileTransactionFilterGENERATED} from '../_generated/eb-payment-report-2-sepa-file-transaction-filter-generated.model';

export class EbPaymentReport2SepaFileTransactionFilter extends EbPaymentReport2SepaFileTransactionFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbPaymentReport2SepaFileTransactionFilter();
  }

  // ---------------------------------------------------------------------
}
