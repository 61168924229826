/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {CsrPaymentDetails} from '../models/csr-payment-details.model';
import {CsrPaymentDetailsList} from '../models/csr-payment-details-list.model';
import {CsrPaymentDetailsFilter} from '../models/csr-payment-details-filter.model';
import {CsrPaymentDetailsService} from '../services/csr-payment-details.service';
import {ICsrPaymentDetails} from '../_generated/csr-payment-details.interface';
import {McGod} from "../extra/mc-god.service";

export class CsrPaymentDetailsListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'idRef', 'refTypeCd', 'typeCd'];
  public items: CsrPaymentDetails[] = [];
  public apiService: CsrPaymentDetailsService;
 // protected tempObjList: CsrPaymentDetailsList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrPaymentDetailsList {
    console.error('MTCN-ERROR: Not Implemented: CsrPaymentDetailsList::createNewInstance(). Add this method to final class and return new CsrPaymentDetailsList();');
    throw new Error('Cannot CsrPaymentDetailsList::createNewInstance(). Add this method to final class and return new CsrPaymentDetailsList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: ICsrPaymentDetails[]): CsrPaymentDetailsList {
    const  listObj: CsrPaymentDetailsList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: CsrPaymentDetails = CsrPaymentDetails.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): CsrPaymentDetailsList {
    const  listObj: CsrPaymentDetailsList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrPaymentDetailsService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: ICsrPaymentDetails[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: CsrPaymentDetails = CsrPaymentDetails.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aCsrPaymentDetailsFilter: CsrPaymentDetailsFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aCsrPaymentDetailsFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): CsrPaymentDetails {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new CsrPaymentDetails();
    }
  }

  public replaceItem(newItem: CsrPaymentDetails) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

