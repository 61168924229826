import {Component, EventEmitter, Input, Output} from '@angular/core';
import {McForm, McPriceList} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-pricelist-template-edit-from-profile-part',
  templateUrl: './mc-pricelist-template-edit-from-profile-part.component.html',
})
export class McPricelistTemplateEditFromProfilePartComponent {

  /*Variables*/
  mcForm = new McForm();

  @Input() mcPriceList!: McPriceList;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();


  actCancel() {
    this.eventCanceled.emit();
  }

  actSave() {
    if (this.mcForm.isValid()) {
      this.mcPriceList.title = this.mcForm.getValue('ctrlTemplateName');
      this.mcPriceList.description = this.mcForm.getValue('ctrlTemplateDescription');
      this.mcPriceList.save(() => {
        this.eventSaved.emit();
      });
    }
  }
}
