/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EbSepaFileTransactionFilter} from '../models/eb-sepa-file-transaction-filter.model';

export class EbSepaFileTransactionFilterGENERATED extends BaseObjectFilter{    public statusCds: string[] = [];
    public searchTerm: string;
    public childEntityIds: number[] = [];
    public outFactoringFlg: boolean;
    public forConsumerFlg: boolean;
    public consumerId: number;
    public transactionCreationFromDate: any;
    public transactionCreationToDate: any;
    public transactionPaymentDueDateTimeFromDate: any;
    public transactionPaymentDueDateTimeToDate: any;
    public transactionLastUpdatedFromDate: any;
    public transactionLastUpdatedToDate: any;
    public pausedFilterOptionCd: string;
    public inDunningFilterOptionCd: string;
    public dunningCompletedFilterOptionCd: string;
    public factoringTypeWithDelayFilterOptionCds: string[] = [];
    public paymentDueDateFrom: any;
    public paymentDueDateTo: any;

  public properties: string[] = ['statusCds', 'searchTerm', 'childEntityIds', 'outFactoringFlg', 'forConsumerFlg', 'consumerId', 'transactionCreationFromDate', 'transactionCreationToDate', 'transactionPaymentDueDateTimeFromDate', 'transactionPaymentDueDateTimeToDate', 'transactionLastUpdatedFromDate', 'transactionLastUpdatedToDate', 'pausedFilterOptionCd', 'inDunningFilterOptionCd', 'dunningCompletedFilterOptionCd', 'factoringTypeWithDelayFilterOptionCds', 'paymentDueDateFrom', 'paymentDueDateTo', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbSepaFileTransactionFilter {
    console.error('MTCN-ERROR: Not Implemented: EbSepaFileTransactionFilter::createNewInstance(). Add this method to final class and return new EbSepaFileTransactionFilter();');
      throw new Error('Cannot EbSepaFileTransactionFilter::createNewInstance(). Add this method to final class and return new EbSepaFileTransactionFilter();');
  }

}
