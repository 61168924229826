<section #viewChild class="container">
  <div [style.display]="selected ? 'block' : 'none'">
    <ng-container [ngTemplateOutlet]="selected?.content"></ng-container>
  </div>

  <footer class="step-navigation-bar justify-content-between d-flex mt-5 mb-3">
    <div class="col-md-4">
      <button type="button"
              class="nav-button btn btn-secondary mc-prev-btn"
              cdkStepperPrevious
              (click)="stepBack()"
              *ngIf="selectedIndex > 0">
        <i class="fas fa-chevron-left"></i>
        {{'cc.common.view.prev' | translate}}
      </button>
    </div>
    <div class="col-md-8 text-right">
      <button type="button" class="nav-button btn mc-button"
              cdkStepperNext
              (click)="stepForward()"
              [ngClass]="isDataFormInvalid ? 'disabled mc-click-not-allow' : 'btn-primary mc-next-save-btn'"
              *ngIf="selectedIndex + 1 !== steps.length"
              [disabled]="isDataFormInvalid">
        {{'cc.common.view.next' | translate}}
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>
  </footer>

</section>
