import { Component, OnInit } from '@angular/core';
import {ImDataImport, ImDataImportService, ImDataSetService, McGod, McValueLabelList, SortCriteriaList, WebFileService} from '@miticon-ui/mc-core';
import {MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig} from "@miticon-ui/mc-components";
import {Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {SortCriteriaItem} from "@miticon-ui/mc-core";
import {TranslateService} from "@ngx-translate/core";
import {saveAs} from "file-saver";
import {ICON_DATA_IMPORT} from "../../../../assets/media/svg_icons/sidenav-icons/icon-data-import";
import {DataImportViewDialogComponent} from "../data-import-view-dialog/data-import-view-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'lib-data-import-list-page',
  templateUrl: './data-import-list-page.component.html',
  styleUrls: ['./data-import-list-page.component.scss']
})
export class DataImportListPageComponent implements OnInit {

  items: ImDataImport[] = [];
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  isLoading = false;
  pageNumber: number = 0;
  pageSize: number = 5;
  totalItemsCount!: number;
  searchToolTip = this.tS.instant('mem.data-import-list-page.search');
  iconDataImport = ICON_DATA_IMPORT;

  //ADD VALID PERMISSION
  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant("mem.view-data-import"),
      actionCd: 'View import',
      matIcon: 'visibility',
      permission: McGod.PERM_CSR_CONSUMER_IMPORT_VIEW
    },
    {
      title: this.tS.instant("mem.download-data-import"),
      actionCd: 'Download file',
      matIcon: 'file_download',
      permission: McGod.PERM_CSR_CONSUMER_IMPORT_VIEW
    }
  ];
  constructor(private tS: TranslateService,
              private dataImportService: ImDataImportService,
              private dataSetService: ImDataSetService,
              private webFileService: WebFileService,
              private dialog: MatDialog,
              private router: Router) {}

  ngOnInit(): void {
    this.initTableConfig();
    this.initFilterConfig();
    const params = new HttpParams()
      .set('page', this.pageNumber)
      .set('size', this.pageSize);
    this.actLoad(this.pageNumber, this.pageSize, params);
  }

  actLoad(pageNumber: number, pageSize: number, params: HttpParams): void {
    this.isLoading = true;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    const sort = new SortCriteriaItem('id', 'DESC');
    const sortCriteriaList = new SortCriteriaList();
    sortCriteriaList.addSort(sort.propertyName, sort.direction);
    this.dataImportService.getAllImports(params, pageNumber, pageSize, sortCriteriaList).subscribe((res)=> {
      if (res) {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => object = ImDataImport.createFromJson(object));
      }
    }, () => {
      this.isLoading = false;
    });
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard("ID", 'id', 100 );
    this.tableConfig.addColumnStandard(this.tS.instant("cc.common.name"), 'dataImport', 100 );
    this.tableConfig.addColumnStandard(this.tS.instant("mem.data-import.data-set"), 'dataSetName', 100);
    this.tableConfig.addColumnStandard(this.tS.instant("mem.data-import.source"), 'getSource()',100 );
    this.tableConfig.addColumnSvgIcon(this.tS.instant("mem.data-import.date"), 'getImportDate()');
    this.tableConfig.addColumnSvgIcon(this.tS.instant("cc.common.status"), 'getStatusCd()');
  }

  initFilterConfig() {
    const dataSetValueLabelList = new McValueLabelList();
    const sourceValueLabelList = new McValueLabelList();
    const statusValueLabelList = new McValueLabelList();

    statusValueLabelList.add('In_progress', 'In progress');
    statusValueLabelList.add('Imported', 'Imported');
    statusValueLabelList.add('Partially_imported', 'Partially imported');
    statusValueLabelList.add('Failed', 'Failed');

    sourceValueLabelList.add( 'csv', 'CSV');
    sourceValueLabelList.add('xml','XML');
    sourceValueLabelList.add('json','JSON');

    this.dataSetService.getAll(0,0, new SortCriteriaList()).subscribe((dataSets) => {
      dataSets.forEach((dataSet: any) => {
        dataSetValueLabelList.add(dataSet.id, dataSet.name);
      })
      this.filterConfig.addItem(MkFilterItemType.SELECT, this.tS.instant("mem.data-import.data-set"), dataSetValueLabelList.items.map((item) => new MkFilterValue(item.label, item.value)));
      this.filterConfig.addItem(MkFilterItemType.SELECT, this.tS.instant("mem.data-import.source"), sourceValueLabelList.items.map((item) => new MkFilterValue(item.label, item.value)));
      this.filterConfig.addItem(MkFilterItemType.DATEPICKER, this.tS.instant("mem.data-import.date"));
      this.filterConfig.addItem(MkFilterItemType.SELECT, this.tS.instant("cc.common.status"), statusValueLabelList.items.map((item) => new MkFilterValue(item.label, item.value)));

    })
  }

  onFilterChanged(filterOutput: MkFilterOutput) {
    const params = new HttpParams()
      .set('page', filterOutput.pageEvent.pageIndex)
      .set('size',filterOutput.pageEvent.pageSize)
      .set('term', filterOutput.search ? filterOutput.search : '')
      .set('statusCd', filterOutput.selections[this.tS.instant("cc.common.status")] ? filterOutput.selections[this.tS.instant("cc.common.status")] : '')
      .set('source', filterOutput.selections[this.tS.instant("mem.data-import.source")] ? filterOutput.selections[this.tS.instant("mem.data-import.source")] : '')
      .set('dataSetId', filterOutput.selections[this.tS.instant("mem.data-import.data-set")] ? filterOutput.selections[this.tS.instant("mem.data-import.data-set")] : '')
      .set('createDate', filterOutput.selections[this.tS.instant("mem.data-import.date")] ? filterOutput.selections[this.tS.instant("mem.data-import.date")] : '')
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, params);
  }

  onTableAction($event: MkMatTableMenuAction) {
    switch ($event.action.actionCd) {
      case 'View import':
        this.dialog.open(DataImportViewDialogComponent, {
          height: '900px',
          width: '960px',
          panelClass: 'dialog',
          autoFocus: false,
          restoreFocus: false,
          data: $event.item.id
        });
        break;
      case 'Download file':
        this.webFileService.getById($event.item.webFileId).subscribe((dataImportFile: any) => {
          if(dataImportFile) {
            this.webFileService.downloadFile($event.item._rawJson.webFileId, dataImportFile.typeCd).subscribe((file:any) => {
               saveAs(file, dataImportFile.originalFilename);
            })
          }
        })
        break;
    }
  }

  addImport() {
    this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/data/import/add`);
  }
}
