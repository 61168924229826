/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbSepaFileReport} from '../models/eb-sepa-file-report.model';
import {EbSepaFileReportService} from '../services/eb-sepa-file-report.service';
import {IEbSepaFileReport} from '../_generated/eb-sepa-file-report.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';




export abstract class EbSepaFileReportGENERATED extends BaseObject {


































  public apiService: EbSepaFileReportService;
  public _rawJson: IEbSepaFileReport;
    id: number = 0;
  ftAcceptedForFactoringA: number;
  ftAcceptedForFactoringB: number;
  ftAcceptedForFactoringC: number;
  ftImmediatelyPayableBalanceA: number;
  ftImmediatelyPayableBalanceB: number;
  ftImmediatelyPayableBalanceC: number;
  ftLaterPayableBalanceA: number;
  ftLaterPayableBalanceB: number;
  ftLaterPayableBalanceC: number;
  ftNotAcceptedForFactoringA: number;
  ftNotAcceptedForFactoringB: number;
  ftNotAcceptedForFactoringC: number;
  idEbSepaFile: number;
  reportForFactoringFlg: boolean;
  stBatchFeeA: number;
  stBatchFeeB: number;
  stBatchFeeC: number;
  stBatchFeePlusVatC: number;
  stFactoringFeeA: number;
  stFactoringFeeB: number;
  stFactoringFeeC: number;
  stImmediatelyPayableTransactionCount: number;
  stNonFactoringFeePerTransactionB: number;
  stPlusVatC: number;
  stTotalFactoringFeeToBeDeductedC: number;
  ttFactoringFeeA: number;
  ttFactoringFeeB: number;
  ttFactoringFeeC: number;
  ttImmediatelyPayableBalanceA: number;
  ttImmediatelyPayableBalanceB: number;
  ttImmediatelyPayableBalanceC: number;
  ttPayableBalanceC: number;
    ftAcceptedForFactoringBLoc: string;    ftAcceptedForFactoringCLoc: string;    ftImmediatelyPayableBalanceBLoc: string;    ftImmediatelyPayableBalanceCLoc: string;    ftLaterPayableBalanceBLoc: string;    ftLaterPayableBalanceCLoc: string;    ftNotAcceptedForFactoringBLoc: string;    ftNotAcceptedForFactoringCLoc: string;    stBatchFeeBLoc: string;    stBatchFeeCLoc: string;    stBatchFeePlusVatCLoc: string;    stFactoringFeeALoc: string;    stFactoringFeeBLoc: string;    stFactoringFeeCLoc: string;    stNonFactoringFeePerTransactionBLoc: string;    stPlusVatCLoc: string;    stTotalFactoringFeeToBeDeductedCLoc: string;    ttFactoringFeeALoc: string;    ttFactoringFeeBLoc: string;    ttFactoringFeeCLoc: string;    ttImmediatelyPayableBalanceBLoc: string;    ttImmediatelyPayableBalanceCLoc: string;    ttPayableBalanceCLoc: string;

  public properties: string[] = ['id', 'ftAcceptedForFactoringA', 'ftAcceptedForFactoringB', 'ftAcceptedForFactoringC', 'ftImmediatelyPayableBalanceA', 'ftImmediatelyPayableBalanceB', 'ftImmediatelyPayableBalanceC', 'ftLaterPayableBalanceA', 'ftLaterPayableBalanceB', 'ftLaterPayableBalanceC', 'ftNotAcceptedForFactoringA', 'ftNotAcceptedForFactoringB', 'ftNotAcceptedForFactoringC', 'idEbSepaFile', 'reportForFactoringFlg', 'stBatchFeeA', 'stBatchFeeB', 'stBatchFeeC', 'stBatchFeePlusVatC', 'stFactoringFeeA', 'stFactoringFeeB', 'stFactoringFeeC', 'stImmediatelyPayableTransactionCount', 'stNonFactoringFeePerTransactionB', 'stPlusVatC', 'stTotalFactoringFeeToBeDeductedC', 'ttFactoringFeeA', 'ttFactoringFeeB', 'ttFactoringFeeC', 'ttImmediatelyPayableBalanceA', 'ttImmediatelyPayableBalanceB', 'ttImmediatelyPayableBalanceC', 'ttPayableBalanceC', 'ftAcceptedForFactoringBLoc', 'ftAcceptedForFactoringCLoc', 'ftImmediatelyPayableBalanceBLoc', 'ftImmediatelyPayableBalanceCLoc', 'ftLaterPayableBalanceBLoc', 'ftLaterPayableBalanceCLoc', 'ftNotAcceptedForFactoringBLoc', 'ftNotAcceptedForFactoringCLoc', 'stBatchFeeBLoc', 'stBatchFeeCLoc', 'stBatchFeePlusVatCLoc', 'stFactoringFeeALoc', 'stFactoringFeeBLoc', 'stFactoringFeeCLoc', 'stNonFactoringFeePerTransactionBLoc', 'stPlusVatCLoc', 'stTotalFactoringFeeToBeDeductedCLoc', 'ttFactoringFeeALoc', 'ttFactoringFeeBLoc', 'ttFactoringFeeCLoc', 'ttImmediatelyPayableBalanceBLoc', 'ttImmediatelyPayableBalanceCLoc', 'ttPayableBalanceCLoc'];
  public propertiesRegular: string[] = ['id', 'ebSepaFile', 'ftAcceptedForFactoringA', 'ftAcceptedForFactoringB', 'ftAcceptedForFactoringC', 'ftImmediatelyPayableBalanceA', 'ftImmediatelyPayableBalanceB', 'ftImmediatelyPayableBalanceC', 'ftLaterPayableBalanceA', 'ftLaterPayableBalanceB', 'ftLaterPayableBalanceC', 'ftNotAcceptedForFactoringA', 'ftNotAcceptedForFactoringB', 'ftNotAcceptedForFactoringC', 'idEbSepaFile', 'reportForFactoringFlg', 'stBatchFeeA', 'stBatchFeeB', 'stBatchFeeC', 'stBatchFeePlusVatC', 'stFactoringFeeA', 'stFactoringFeeB', 'stFactoringFeeC', 'stImmediatelyPayableTransactionCount', 'stNonFactoringFeePerTransactionB', 'stPlusVatC', 'stTotalFactoringFeeToBeDeductedC', 'ttFactoringFeeA', 'ttFactoringFeeB', 'ttFactoringFeeC', 'ttImmediatelyPayableBalanceA', 'ttImmediatelyPayableBalanceB', 'ttImmediatelyPayableBalanceC', 'ttPayableBalanceC', 'ftAcceptedForFactoringBLoc', 'ftAcceptedForFactoringCLoc', 'ftImmediatelyPayableBalanceBLoc', 'ftImmediatelyPayableBalanceCLoc', 'ftLaterPayableBalanceBLoc', 'ftLaterPayableBalanceCLoc', 'ftNotAcceptedForFactoringBLoc', 'ftNotAcceptedForFactoringCLoc', 'stBatchFeeBLoc', 'stBatchFeeCLoc', 'stBatchFeePlusVatCLoc', 'stFactoringFeeALoc', 'stFactoringFeeBLoc', 'stFactoringFeeCLoc', 'stNonFactoringFeePerTransactionBLoc', 'stPlusVatCLoc', 'stTotalFactoringFeeToBeDeductedCLoc', 'ttFactoringFeeALoc', 'ttFactoringFeeBLoc', 'ttFactoringFeeCLoc', 'ttImmediatelyPayableBalanceBLoc', 'ttImmediatelyPayableBalanceCLoc', 'ttPayableBalanceCLoc'];
  public propertiesSpecial: string[] = [];


































  // ---------------------------------------------------------------------
public static createNewInstance(): EbSepaFileReport {
    console.error('MTCN-ERROR: Not Implemented: EbSepaFileReport::createNewInstance(). Add this method to final class and return new EbSepaFileReport();');
    throw new Error('Cannot EbSepaFileReport::createNewInstance(). Add this method to final class and return new EbSepaFileReport();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbSepaFileReport): EbSepaFileReport {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbSepaFileReportService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbSepaFileReport AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getFtAcceptedForFactoringAStr(): string {
       return McGod.formatNumberToMoney(this.ftAcceptedForFactoringA);
    }

    public getFtAcceptedForFactoringBStr(): string {
       return McGod.formatNumberToMoney(this.ftAcceptedForFactoringB);
    }

    public getFtAcceptedForFactoringCStr(): string {
       return McGod.formatNumberToMoney(this.ftAcceptedForFactoringC);
    }

    public getFtImmediatelyPayableBalanceAStr(): string {
       return McGod.formatNumberToMoney(this.ftImmediatelyPayableBalanceA);
    }

    public getFtImmediatelyPayableBalanceBStr(): string {
       return McGod.formatNumberToMoney(this.ftImmediatelyPayableBalanceB);
    }

    public getFtImmediatelyPayableBalanceCStr(): string {
       return McGod.formatNumberToMoney(this.ftImmediatelyPayableBalanceC);
    }

    public getFtLaterPayableBalanceAStr(): string {
       return McGod.formatNumberToMoney(this.ftLaterPayableBalanceA);
    }

    public getFtLaterPayableBalanceBStr(): string {
       return McGod.formatNumberToMoney(this.ftLaterPayableBalanceB);
    }

    public getFtLaterPayableBalanceCStr(): string {
       return McGod.formatNumberToMoney(this.ftLaterPayableBalanceC);
    }

    public getFtNotAcceptedForFactoringAStr(): string {
       return McGod.formatNumberToMoney(this.ftNotAcceptedForFactoringA);
    }

    public getFtNotAcceptedForFactoringBStr(): string {
       return McGod.formatNumberToMoney(this.ftNotAcceptedForFactoringB);
    }

    public getFtNotAcceptedForFactoringCStr(): string {
       return McGod.formatNumberToMoney(this.ftNotAcceptedForFactoringC);
    }

    public getStBatchFeeAStr(): string {
       return McGod.formatNumberToMoney(this.stBatchFeeA);
    }

    public getStBatchFeeBStr(): string {
       return McGod.formatNumberToMoney(this.stBatchFeeB);
    }

    public getStBatchFeeCStr(): string {
       return McGod.formatNumberToMoney(this.stBatchFeeC);
    }

    public getStBatchFeePlusVatCStr(): string {
       return McGod.formatNumberToMoney(this.stBatchFeePlusVatC);
    }

    public getStFactoringFeeAStr(): string {
       return McGod.formatNumberToMoney(this.stFactoringFeeA);
    }

    public getStFactoringFeeBStr(): string {
       return McGod.formatNumberToMoney(this.stFactoringFeeB);
    }

    public getStFactoringFeeCStr(): string {
       return McGod.formatNumberToMoney(this.stFactoringFeeC);
    }

    public getStNonFactoringFeePerTransactionBStr(): string {
       return McGod.formatNumberToMoney(this.stNonFactoringFeePerTransactionB);
    }

    public getStPlusVatCStr(): string {
       return McGod.formatNumberToMoney(this.stPlusVatC);
    }

    public getStTotalFactoringFeeToBeDeductedCStr(): string {
       return McGod.formatNumberToMoney(this.stTotalFactoringFeeToBeDeductedC);
    }

    public getTtFactoringFeeAStr(): string {
       return McGod.formatNumberToMoney(this.ttFactoringFeeA);
    }

    public getTtFactoringFeeBStr(): string {
       return McGod.formatNumberToMoney(this.ttFactoringFeeB);
    }

    public getTtFactoringFeeCStr(): string {
       return McGod.formatNumberToMoney(this.ttFactoringFeeC);
    }

    public getTtImmediatelyPayableBalanceAStr(): string {
       return McGod.formatNumberToMoney(this.ttImmediatelyPayableBalanceA);
    }

    public getTtImmediatelyPayableBalanceBStr(): string {
       return McGod.formatNumberToMoney(this.ttImmediatelyPayableBalanceB);
    }

    public getTtImmediatelyPayableBalanceCStr(): string {
       return McGod.formatNumberToMoney(this.ttImmediatelyPayableBalanceC);
    }

    public getTtPayableBalanceCStr(): string {
       return McGod.formatNumberToMoney(this.ttPayableBalanceC);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

































  
  // ---------------------------------------------------------------------
  public loadBySepaFileId( idEbSepaFile: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getBySepaFileId(idEbSepaFile)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

// ---------------------------------------------------------------------
public callGeneratePreReportForSepaFile( idEbSepaFile: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.generatePreReportForSepaFile(idEbSepaFile).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

}
