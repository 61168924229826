/*BbmTestObj*/
import {ZzPocCarGENERATED} from '../_generated/zz-poc-car-generated.model';
import {McHtmlButton} from '../_core/mc-html-button';
import {McHtml} from '../_core/mc-html';

export class ZzPocCar extends ZzPocCarGENERATED {
  public readonly TYPE_CD_SUB: string = 'S';

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new ZzPocCar();
  }

  // ---------------------------------------------------------------------
  public fldTitle() {
    const result = new McHtml();
    // result.addLinkB( this.title, "profile/" + this.id, this, []);

    console.log("result99", result);
    return result;
  }

}
