import {Inject, Injectable} from '@angular/core';
import {McConsumerActionServiceGENERATED} from '../_generated/mc-consumer-action-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McConsumerActionService extends McConsumerActionServiceGENERATED {

}
