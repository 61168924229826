import {EbSepaFileReport} from './eb-sepa-file-report.model';
import {EbSepaFileReportListGENERATED} from '../_generated/eb-sepa-file-report-list-generated.model';

export class EbSepaFileReportList extends EbSepaFileReportListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaFileReportList();
  }

  // ---------------------------------------------------------------------
}
