<div class="valign-container full-height mc-login-area" [ngStyle]="{'background-image': 'url(' + welcomeImg + ')'}">
  <div class="container">
    <div class="row">
      <!--      <div class="col-md-6 offset-md-3" *ngIf="!loginMessage">-->
      <div class="col-md-12">
        <div class="card mc-incomplete-profile-card">
          <div class="text-center mc-incomplete-profile-header">

            <div class="mc-incomplete-profile-back-to-login">
              <app-mc-back-to-login></app-mc-back-to-login>
            </div>

            <img [src]="logo" alt="" height="70">
            <div class="pb-3">Your account is not completed. Please fill in the details to continue.</div>
          </div>

          <div class="card-body mc-incomplete-profile-card-body">
            <form [formGroup]="incompleteProfileForm" (ngSubmit)="saveAdditionalData()">
              <div class="row">
                <div class="col-md-12 px-4">

                  <div class="mc-create-user-notify-wrapper">
                     <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
                  </div>

                  <div class="mc-incomplete-profile-wrapper">
                    <div class="row pt-5 mc-incomplete-profile-information">
                      <div class="col-md-9">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group mc-user-first-name">
                              <label for="NewUserFirstName" class="mc-new-user-field-names">{{ 'cc.common.edit.first-name' | translate }}</label>
                              <input type="text"
                                     class="form-control"
                                     id="NewUserFirstName"
                                     formControlName="firstName"
                                     aria-describedby="firstName"
                                     [readOnly]="mandatoryFieldsDisabled"
                                     [ngClass]="mandatoryNotSelected ? 'mc-error-field' : ''"
                                     (keyup)="checkMandatoryInputFields('firstName')"
                                     (click)="clearInfoMsg()">
                            </div>
                            <div class="form-group mc-user-last-name">
                              <label for="NewUserLastName" class="mc-new-user-field-names">{{ 'cc.common.edit.last-name' | translate }}</label>
                              <input type="text"
                                     class="form-control"
                                     id="NewUserLastName"
                                     formControlName="lastName"
                                     aria-describedby="lastName"
                                     [readOnly]="mandatoryFieldsDisabled"
                                     [ngClass]="mandatoryNotSelected ? 'mc-error-field' : ''"
                                     (keyup)="checkMandatoryInputFields('lastName')"
                                     (click)="clearInfoMsg()">
                            </div>
                            <div class="form-group mc-user-nickname">
                              <label for="NewUserNickname" class="mc-new-user-field-names">Nickname</label>
                              <input type="text"
                                     class="form-control"
                                     id="NewUserNickname"
                                     formControlName="nickname"
                                     aria-describedby="nickname"
                                     [readOnly]="mandatoryFieldsDisabled"
                                     (keyup)="checkMandatoryInputFields('nickname')"
                                     (click)="clearInfoMsg()">
                            </div>

                            <div class="form-check mc-user-gender">
                              <div class="mc-new-user-field-names">{{ 'cc.common.edit.gender' | translate }}</div>

                              <input
                                class="form-check-input"
                                type="radio"
                                name="gender"
                                id="genderMail"
                                value="MALE"
                                formControlName="gender"
                                [ngClass]="genderRadio ? 'mc-create-user-genders' : ''"
                                [attr.disabled]="mandatoryFieldsDisabled"
                                (change)="checkMandatoryInputFields('gender')">
                              <label class="form-check-label" for="genderMail">{{ 'cc.common.edit.male' | translate }}</label>

                              <input
                                class="form-check-input"
                                type="radio"
                                name="gender"
                                id="genderFemale"
                                value="FEMALE"
                                formControlName="gender"
                                [attr.disabled]="mandatoryFieldsDisabled"
                                [ngClass]="genderRadio ? 'mc-create-user-genders' : ''"
                                (change)="checkMandatoryInputFields('gender')">
                              <label class="form-check-label" for="genderFemale">{{ 'cc.common.edit.female' | translate }}</label>

                              <input
                                class="form-check-input"
                                type="radio"
                                name="gender"
                                id="genderOther"
                                value="OTHER"
                                formControlName="gender"
                                [attr.disabled]="mandatoryFieldsDisabled"
                                [ngClass]="genderRadio ? 'mc-create-user-genders' : ''"
                                (change)="checkMandatoryInputFields('gender')">
                              <label class="form-check-label" for="genderOther">{{ 'cc.common.edit.prefer-not-to-say' | translate }}</label>

                            </div>
                          </div>
                          <div class="col-md-6">

                            <!--SelectBox languages-->
                            <div class="form-group">
                              <label class="mc-new-user-field-names" for="userLanguage">{{'cc.users.edit.language' | translate}}</label>
                              <select *ngIf="languages" class="form-control" id="userLanguage" formControlName="language">
                                <option *ngFor="let language of languages" [value]="language.value">{{language.name}}</option>
                              </select>
                            </div>

                            <!--SelectBox timezones-->
                            <div class="form-group">
                              <label class="mc-new-user-field-names" for="userTimezone">{{'cc.users.edit.timezone' | translate}}</label>
                              <select *ngIf="timezones" class="form-control" id="userTimezone" formControlName="timezone">
                                <option *ngFor="let timezone of timezones" [value]="timezone">{{timezone}}</option>
                              </select>
                            </div>

                            <!--SelectBox countries-->
                            <div class="form-group">
                              <label class="mc-new-user-field-names" for="userCountry">{{'cc.users.edit.country' | translate}}</label>
                              <select *ngIf="countries" class="form-control" id="userCountry" formControlName="country">
                                <option *ngFor="let country of countries" [value]="country.value">{{country.name}}</option>
                              </select>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="col-md-12 mc-new-user-image-area">
                          <div class="mc-new-user-field-names">{{ 'cc.common.edit.image' | translate }}</div>
                          <div class="img-thumbnail mc-new-user-image" [ngStyle]="{'background-image': 'url(' + userImage + ')'}" (click)="userFileUpload.click()"></div>
                          <input type="file" name="myImage" accept="image/*" formControlName="image" (change)="onFileChanged($event)" [attr.disabled]="mandatoryFieldsDisabled" #userFileUpload>
                          <a class="mc-upload-image-btn mt-1" (click)="userFileUpload.click()">{{ 'cc.common.edit.upload-image' | translate }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5 mb-3 float-right">
                    <div class="col-md-12">
                      <button id="userGeneralDataBtn" class="btn btn-primary mc-next-save-btn" type="submit" [disabled]="isMandatoryNotFilled ">
                        {{'cc.common.view.next' | translate}}
                        <i class="fas fa-chevron-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>



