import {CsrDiscountFilterGENERATED} from '../_generated/csr-discount-filter-generated.model';

export class CsrDiscountFilter extends CsrDiscountFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrDiscountFilter();
  }

  // ---------------------------------------------------------------------
}
