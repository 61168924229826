/*BbmTestObj*/
import {McDashboardGENERATED} from '../_generated/mc-dashboard-generated.model';

export class McDashboard extends McDashboardGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McDashboard();
  }

  // ---------------------------------------------------------------------
}
