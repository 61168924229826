import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mc-apply-options',
  templateUrl: './mc-apply-options.component.html',
})
export class McApplyOptionsComponent {

  /*Public variables*/
  public isDropdownOpen = false;

  /*Events*/
  @Input() objectProperty: any;
  @Output() selectedOption = new EventEmitter<any>();

  /*Select option*/
  public onSelectOption(bellow: any) {
    this.selectedOption.emit({
      bellow,
      objectProperty: this.objectProperty
    });
    this.isDropdownOpen = !this.isDropdownOpen;
  }

}
