<div class="mc-personal-info">
  <!--Personal info title-->
  <h4 class="mc-personal-info-title">{{ 'cc.common.personal-info' | translate }}</h4>
  <br>
  <div class="mc-personal-info-wrapper">
      <h5 class="mr-3">{{ 'cc.common.personal-info' | translate }}</h5>
      <!--Edit personal info modal-->
      <mc-edit-personal-info-modal
        *ngIf="childObject" [parentData]="childObject" (refreshUserDataEvent)="onSuccessUserDataEvent($event)"></mc-edit-personal-info-modal>
      <hr>
      <!--Personal info content-->
      <div class="row" *ngIf="childObject">
          <div class="col-md-3">
            <img *ngIf="imgUrl" [src]="imgUrl" class="user-image" alt="User image">
          </div>
          <div class="col-md-9 top-margin">
            <div class="row">
              <!--Left column-->
              <div class="col-md-6">
                <!--First name-->
                <div class="row">
                  <div class="col-md-3">
                    <p class="user-label">{{ 'cc.common.edit.first-name' | translate }}: </p>
                  </div>
                  <div class="col-md-9">
                    <p class="user-info h6 text-muted">{{childObject.firstname}}</p>
                  </div>
                </div>
                <!--Last name-->
                <div class="row">
                  <div class="col-md-3">
                    <p class="user-label">{{ 'cc.common.edit.last-name' | translate }}: </p>
                  </div>
                  <div class="col-md-9">
                    <p class="user-info h6 text-muted">{{childObject.lastname}}</p>
                  </div>
                </div>
                <!--Nickname-->
                <div class="row">
                  <div class="col-md-3">
                    <p class="user-label">{{'cc.common.view.nickname' | translate}}: </p>
                  </div>
                  <div class="col-md-9">
                    <p class="user-info h6 text-muted">{{childObject.nickname}}</p>
                  </div>
                </div>
                <!--Gender-->
                <div class="row">
                  <div class="col-md-3">
                    <p class="user-label">{{'cc.common.edit.gender' | translate}}: </p>
                  </div>
                  <div class="col-md-9">
                    <p class="user-info h6 text-muted">{{childObject.gender}}</p>
                  </div>
                </div>
              </div>
              <!--Right column-->
              <div class="col-md-6">
                <!--Language-->
                <div class="row">
                  <div class="col-md-3">
                    <p class="user-label">{{'cc.users.edit.language' | translate}}: </p>
                  </div>
                  <div class="col-md-9">
                    <p class="user-info h6 text-muted">{{childObject.isoLanguage}}</p>
                  </div>
                </div>
                <!--Timezone-->
                <div class="row">
                  <div class="col-md-3">
                    <p class="user-label">{{'cc.users.edit.timezone' | translate}}: </p>
                  </div>
                  <div class="col-md-9">
                    <p class="user-info h6 text-muted">{{childObject.timezone}}</p>
                  </div>
                </div>
                <!--Country-->
                <div class="row">
                  <div class="col-md-3">
                    <p class="user-label">{{'cc.users.edit.country' | translate}}: </p>
                  </div>
                  <div class="col-md-9">
                    <p class="user-info h6 text-muted">{{childObject.isoCountry}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                  </div>
                  <div class="col-md-9">
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <!--Notification message-->
      <div class="mc-notify-wrapper">
        <mc-notify-message [message]="message" [type]="type"></mc-notify-message>
      </div>
  </div>
</div>
