import {BkFinanceReportFilterGENERATED} from '../_generated/bk-finance-report-filter-generated.model';

export class BkFinanceReportFilter extends BkFinanceReportFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new BkFinanceReportFilter();
  }

  // ---------------------------------------------------------------------
}
