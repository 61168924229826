/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McPriceList2Entity} from '../models/mc-price-list-2-entity.model';
import {McPriceList2EntityService} from '../services/mc-price-list-2-entity.service';
import {IMcPriceList2Entity} from '../_generated/mc-price-list-2-entity.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McPriceList2EntityGENERATED extends BaseObject {


  public apiService: McPriceList2EntityService;
  public _rawJson: IMcPriceList2Entity;
    id: number = 0;
  flgActive: boolean;
  idMcEntity: number;
  idPriceList: number;
  validFrom: number;
  validTo: number;
  entityName: string;

  public properties: string[] = ['id', 'flgActive', 'idMcEntity', 'idPriceList', 'validFrom', 'validTo', 'entityName'];
  public propertiesRegular: string[] = ['id', 'flgActive', 'idMcEntity', 'idPriceList', 'mcPriceList', 'validFrom', 'validTo', 'entityName'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): McPriceList2Entity {
    console.error('MTCN-ERROR: Not Implemented: McPriceList2Entity::createNewInstance(). Add this method to final class and return new McPriceList2Entity();');
    throw new Error('Cannot McPriceList2Entity::createNewInstance(). Add this method to final class and return new McPriceList2Entity();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcPriceList2Entity): McPriceList2Entity {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McPriceList2EntityService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McPriceList2Entity AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getValidFromStrD(): string {
       return McDateUtils.formatDateToString(this.validFrom);
    }

    public getValidFromStr(): string {
       return McDateUtils.formatDateTimeToString(this.validFrom);
    }

    public getValidToStrD(): string {
       return McDateUtils.formatDateToString(this.validTo);
    }

    public getValidToStr(): string {
       return McDateUtils.formatDateTimeToString(this.validTo);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
