/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingBatchTable3ConfigGENERATED} from '../_generated/mc-billing-batch-table-3-config-generated.model';

// import {McBillingBatchGENERATED} from '../_generated/mc-billing-batch-generated.model';

export class McBillingBatchTable3Config extends McBillingBatchTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colDateFrom', 'Date from', 'dateFrom', 'dateFrom');
    this.addColumn('colDateTo', 'Date to', 'dateTo', 'dateTo');
    this.addColumn('colTypeCd', 'Type cd', 'typeCd', 'typeCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');


  }
}
