import {Injectable} from '@angular/core';
import {EbTransactionAdjustmentServiceGENERATED} from '../_generated/eb-transaction-adjustment-generated.service';


@Injectable({
  providedIn: 'root'
})
export class EbTransactionAdjustmentService extends EbTransactionAdjustmentServiceGENERATED {

}
