import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {McAuthService} from '../services/mc-auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: McAuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    // @ts-ignore
    const isAuth = JSON.parse(localStorage.getItem('mc-access-token'));
    if (!isAuth) {
      this.router.navigate(['/auth/login']);
    }

    return true;
  }
}
