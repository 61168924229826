import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {McBoolean} from '@miticon-ui/mc-core';
import {ICON_CLOSE} from "../svg_icons/icon-close";

@Component({
  selector: 'mcc-modal1-wrapper',
  templateUrl: './mcc-modal1-wrapper.component.html'
})
export class MccModal1WrapperComponent implements OnInit, OnDestroy{

  valuesArray: Array<any> = [];
  @Input() showMcb!: McBoolean;
  @Input() modalSizeCd: 'small' | 'medium' | 'full' = 'full';
  @Input() heightAutoFlg = false;
  @Input() closeButton = true;
  eventCancel: any;
  iconClose = ICON_CLOSE;

  @Output() eventCanceled = new EventEmitter();
  /*Constructor*/
  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'mc-overflow-hidden');
  }

  actCancel() {
    this.showMcb.setFalse();
    this.eventCanceled.emit();
  }

  actSave() {

  }

  isVisible() {
    return (this.showMcb && this.showMcb.value);
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'mc-overflow-hidden');
  }

}
