<div class="col-md-12 mc-stepper-header-title">
  <span class="pl-4 pb-1 mr-2" [ngClass]="pricelistTab ? 'mc-semi-active': ''">
    <span class="mc-notification-badge-gray" [ngClass]="pricelistTab ? 'mc-semi-active-background': ''">1</span>
    {{'cc.common.view.basic-info' | translate}}
  </span>
  <span class="pl-4 pb-1 ml-4 mr-2" [ngClass]="pricelistTab ? 'mc-semi-active': ''">
    <span class="mc-notification-badge-gray" [ngClass]="pricelistTab ? 'mc-semi-active-background': ''">2</span>
    {{ 'cc.common.view.attributes' | translate }}
  </span>
  <span class="pl-4 pb-1 ml-4 mr-2" [ngClass]="pricelistTab ? 'mc-semi-active': ''">
    <span class="mc-notification-badge-gray" [ngClass]="pricelistTab ? 'mc-semi-active-background': ''">3</span>
      Password policy
  </span>
  <span class="pl-4 pb-1 ml-4 mr-2" [ngClass]="pricelistTab ? 'mc-active': ''">
    <span class="mc-notification-badge-gray" [ngClass]="pricelistTab ? 'mc-active-background': ''">4</span>
    {{'cc.pricelist.pricelist' | translate}}
  </span>
</div>

<!--Modal body section-->
<div class="row mc-entity-password-policy gray-background" style="flex-direction: column">

  <div class="row">
    <div class="col-md-12 mc-text-align-left d-flex mt-4 ml-4">
      <p>{{'cc.pricelist.please-select-entity-pricelist' | translate}}:</p>
      <span class="text-danger">*</span>
    </div>
  </div>

  <div *ngFor="let pricelist of mcPricelistList.items">
    <div class="col-md-12">
      <div  class="run-wrap role-template-wrap mc-text-align-left">


            <input type="radio" id="pricelist{{pricelist.id}}" value="pricelist{{pricelist.id}}" name="pricelist"
                   (change)="onEventValueChange(pricelist)">
          <label for="pricelist{{pricelist.id}}">{{pricelist.title}}</label><br>


        <div class="template-desc">
          <p class="mt-1">{{'cc.common.date.from' | translate}}: <span
            class="from-entity">{{pricelist.nameOfEntityOwner}}</span></p>
          <p>{{'cc.common.view.description' | translate}}: <span class="description-txt">{{pricelist.description}}</span>
          </p>
        </div><!--template-desc-->
      </div>
    </div>
  </div>




  <!--<mcc-fi-radio-buttons2 label="Please select entity pricelist"
                         name="ctrlPricelist"
                         [mcForm]="mcForm"
                         value="ctrlPricelist"
                         [validRequiredFlg]="true"
                         [directionHorizontal]="false"
                         (eventValueChanged)="onEventValueChange($event)"
                         [optionsVll]="pricelistVll"></mcc-fi-radio-buttons2>-->


  <!--          </div>-->
</div>
