<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="loadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h1 *ngIf="mcCountry.exists()">{{'cc.common.view.edit' | translate}}</h1>
    <h1 *ngIf="!mcCountry.exists()">{{'cc.countries.add-country' | translate}}</h1>

  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-body">
    <mcc-message [errorMsg]="errorMsg"></mcc-message>

    <div *ngIf="countryLoadedFlg && servicesLoadedFlg" class="row">

      <div class="col-md-12">
        <div class="content-section">
          <h2>{{'cc.users.edit.country' | translate}}</h2>

          <div *ngIf="!mcCountry.exists() || mcCountry.numberOfEntities === 0">
            <div class="old-label">
              <mcc-fi-select [mcForm]="mcForm" [optionsVll]="countryVll"
                             [validRequiredFlg]="true"
                             [value]="mcCountry.name"
                             label="{{'cc.users.edit.country' | translate}}"
                             name="ctrlCountry"
                             placeholderOption="{{'cc.common.edit.please-choose' | translate}}"></mcc-fi-select>
            </div>
          </div>

          <div *ngIf="mcCountry.exists()">
            <mcc-row-title-description title="Country" [description]="mcCountry.name"></mcc-row-title-description>
          </div>

        </div>
      </div>

      <div class="col-md-12">
        <div class="content-section">
          <h2>{{'cc.countries.vat-definition' | translate}}</h2>

          <div class="w-100 d-flex">
            <span class="mc-submit-color"><i
              class="fas fa-info-circle"></i> {{'cc.countries.add-at-least-one-vat-in-order-to-proceed' | translate}}</span>
            <button class="system-action-btn ml-auto" (click)="actAddVat()"><i class="fas fa-plus-circle"></i> {{'cc.countries.add-vat' | translate}}
            </button>
          </div>

          <hr>

          <div *ngFor="let item of vatItemList.items; index as i">

            <div class="d-flex mb-3">
              <button class="system-action-btn ml-auto" (click)="removeVatFromList(i)">
                <i class="fas fa-times"></i>
              </button>
            </div>

            <mc-country-vat-add-edit-part [mcVatItem]="item" [index]="i" [mcForm]="mcForm"
                                          [servicesVll]="servicesVll" [serviceList]="serviceList">
            </mc-country-vat-add-edit-part>


            <hr>
          </div>

        </div>
      </div>

    </div><!-- row -->

    <mcc-dialog-box *ngIf="showRemoveVatDialogMcb.value" [enableCloseButtonFlg]="false"
                    title="{{'cc.countries.remove-vat' | translate}}"
                    description="{{'cc.countries.are-you-sure-you-want-to-remove-this-vat-item' | translate}}?"
                    [buttonsKvm]="removeVatItemDialogButtonsKvm"
                    popupType="question"
                    (eventActionButtonClicked)="onMcDialogBoxRemoveVatItemActionButtonClicked($event)"></mcc-dialog-box>

  </div><!-- content-body -->

  <div class="row">
    <div class="col-md-12 position-relative">
      <div class="above-footer">
        <mcc-message [errorMsg]="errorMsg"></mcc-message>
      </div>
    </div>
  </div>

  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel()"
                label="{{'cc.common.edit.cancel' | translate}}"></mcc-button>
    <mcc-button *ngIf="mcCountry.exists()" extraCssClasses="btn btn-primary"
                (eventClicked)="actSave()" label="Save" [disabledMcb]="saveButtonDisabledMcb"
                disableOnFirstClickFlg="true"></mcc-button>
    <mcc-button *ngIf="!mcCountry.exists()" extraCssClasses="btn btn-primary"
                (eventClicked)="actSave()" label="<i class='fas fa-plus'></i> {{'cc.common.table.add' | translate}}" [disabledMcb]="saveButtonDisabledMcb"
                disableOnFirstClickFlg="true"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
