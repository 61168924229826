import {Component, OnInit} from '@angular/core';
import {
  EbSepaFile,
  McValueLabelList,
  McHtmlButton, McBoolean, McGod, CurrencyUtil, EbFactoringService, EbSepaImportList, EbSepaImportTable3Config, EbSepaImportFilter, EbSepaImport
} from '@miticon-ui/mc-core';
import {Pagination, McForm} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-my-factoring-sepa-in-list-page',
  templateUrl: './mc-my-factoring-sepa-in-list-page.component.html',
})
export class McMyFactoringSepaInListPageComponent implements OnInit {

  mcForm = new McForm();
  ebSepaImportList = new EbSepaImportList();
  ebSepaImportTable3Config = new EbSepaImportTable3Config();
  ebSepaImportFilter = new EbSepaImportFilter();
  ebSepaFileStatusCdVll: McValueLabelList = EbSepaFile.getStatusCdVll();
  msgText!: string;
  msgType!: string;
  ebSepaFile!: EbSepaFile | null;
  showReportMcb = new McBoolean();
  showImportSepaMcb = new McBoolean();
  showRemoveSepaMcb = new McBoolean();
  dialogButtonKvm = new Map<string, string>();
  mcGod = McGod.getInstance();
  currencyCode!: string;

  // ---------------------------------------------------------------------
  constructor(private factoringService: EbFactoringService) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.dialogButtonKvm.set('btn-primary', 'Yes');
    this.dialogButtonKvm.set('btn-secondary', 'Cancel');
    this.msgText = '';
    this.msgType = '';
    this.ebSepaImportList.setPager(0, 100);
    this.actLoad();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.currencyCode = this.factoringService.activeChildFactoring.mcCurrency.code;
    this.ebSepaImportFilter.outFactoringFlg = false;
    this.ebSepaImportFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.ebSepaImportFilter.statusCds = this.mcForm.getValueArray('selStatusCd');
    this.ebSepaImportList.loadByFilter(this.ebSepaImportFilter, () => {
      this.ebSepaImportList.items.map(item => {
        item.acceptedAmountStr = this.format(item.acceptedAmount);
        item.rejectedAmountStr = this.format(item.rejectedAmount);
        item.totalAmountStr = this.format(item.totalAmountDue);
        item.overwrittenAmountStr = this.format(item.overwrittenAmount);
      });
    });
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.mcForm.getControl('selStatusCd')?.get('value')?.setValue('');
    this.mcForm.getControl('selServicePeriod')?.get('value')?.setValue('');
    this.actLoad();
  }

  onTableItemAction(mcButton: McHtmlButton) {
    if (mcButton.actionCdForEvent === EbSepaImport.ACTION_SHOW_REPORT) {
      this.actShowReportModal(mcButton.item);
    }
    if (mcButton.actionCdForEvent === EbSepaImport.ACTION_SHOW_REMOVE) {
      this.actShowRemoveModal(mcButton.item);
    }
  }

  actShowReportModal(ebSepaFile: EbSepaFile) {
    this.ebSepaFile = null;
    this.ebSepaFile = ebSepaFile;
    this.showReportMcb.setTrue();
  }
  actShowRemoveModal(ebSepaFile: EbSepaFile) {
    this.ebSepaFile = null;
    this.ebSepaFile = ebSepaFile;
    this.showRemoveSepaMcb.setTrue();
  }

  actClose() {
    this.showReportMcb.setFalse();
  }

  onSaveImportSepa() {
    this.showImportSepaMcb.setFalse();
    this.actLoad();
  }

  onCancelImportSepa() {
    this.showImportSepaMcb.setFalse();
  }

  onMcDialogBoxRemoveSepaActionButtonClicked(action: string) {
  }

  format(value: number): string {
    if (value) {
      return CurrencyUtil.format(value, this.currencyCode);
    }
    return CurrencyUtil.format(0, this.currencyCode);
  }
}
