import {Injectable} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {ImDataImportServiceGENERATED} from '../_generated/im-data-import-generated.service';
import {Observable} from 'rxjs';
import {SortCriteriaList} from "../../../../mc-core/src/lib/_core/sort-criteria-list.model";


@Injectable({
  providedIn: 'root'
})
export class ImDataImportService extends ImDataImportServiceGENERATED {

  public importData(data: any): Observable<any> {
    const formData = new FormData();
    formData.append("file", data.file, data.file.name);
    formData.append("aRelativePath", data.aRelativePath);
    formData.append("type", data.type);
    formData.append("source", data.source);
    formData.append("importMode", data.importMode);
    formData.append("mappingId", String(data.mappingId));
    formData.append("customName", data.customName)
    formData.append("datasetId", String(data.datasetId));
    formData.append("delimiter", data.delimiter);
    formData.append("encoding", data.encoding);
    if(data.header) {
      formData.append("header", data.header.toString());
    }
    return this.httpClient.put<any>(this.getApiServiceRootUrl() + `/import`, formData);
  }

  public getAllImports(params: HttpParams, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    params.set('page', aPageNo);
    params.set('size', aItemsPerPage);

    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `?${params.toString()}${sortCriteria.toUrlQueryString()}`,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json'
          })
      });
  }

}
