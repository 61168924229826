import {CsrConsumerFilterGENERATED} from '../_generated/csr-consumer-filter-generated.model';

export class CsrConsumerFilter extends CsrConsumerFilterGENERATED  {
  public countryCds: string[] = [];

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrConsumerFilter();
  }

  // ---------------------------------------------------------------------
}
