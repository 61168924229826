/*BbmTestObj*/
import {McCategoryGENERATED} from '../_generated/mc-category-generated.model';

export class McCategory extends McCategoryGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McCategory();
  }

  // ---------------------------------------------------------------------
}
