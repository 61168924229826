/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {PmTransaction} from '../models/pm-transaction.model';
import {PmTransactionService} from '../services/pm-transaction.service';
import {IPmTransaction} from '../_generated/pm-transaction.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {EbFactoring} from '../models/eb-factoring.model';
import {CsrConsumer} from '../models/csr-consumer.model';
import {McEntity} from '../models/mc-entity.model';
import {McEntityList} from '../models/mc-entity-list.model';
import {PmAdjustmentList} from '../models/pm-adjustment-list.model';


export abstract class PmTransactionGENERATED extends BaseObject {


    public static readonly COLLECTION_TYPE_CD_DO_NOT_COLLECT: string = 'DO_NOT_COLLECT';
    public static readonly COLLECTION_TYPE_CD_DIRECT_DEBIT: string = 'DIRECT_DEBIT';
    public static readonly COLLECTION_TYPE_CD_CREDIT_CARD: string = 'CREDIT_CARD';
    public static readonly COLLECTION_TYPE_CD_TRANSFER: string = 'TRANSFER';
    public static readonly COLLECTION_TYPE_CD_DRAFT: string = 'DRAFT';
  public static readonly COLLECTION_TYPE_CD_LIST__ALL = [
    PmTransactionGENERATED.COLLECTION_TYPE_CD_DO_NOT_COLLECT,
    PmTransactionGENERATED.COLLECTION_TYPE_CD_DIRECT_DEBIT,
    PmTransactionGENERATED.COLLECTION_TYPE_CD_CREDIT_CARD,
    PmTransactionGENERATED.COLLECTION_TYPE_CD_TRANSFER,
    PmTransactionGENERATED.COLLECTION_TYPE_CD_DRAFT
];

    public static readonly DUNNING_STATUS_CD_IN_DUNNING: string = 'IN_DUNNING';
    public static readonly DUNNING_STATUS_CD_DUNNING_COMPLETED: string = 'DUNNING_COMPLETED';
  public static readonly DUNNING_STATUS_CD_LIST__ALL = [
    PmTransactionGENERATED.DUNNING_STATUS_CD_IN_DUNNING,
    PmTransactionGENERATED.DUNNING_STATUS_CD_DUNNING_COMPLETED
];

    public static readonly REF_TYPE_CD_CSR_CONSUMER: string = 'CSR_CONSUMER';
    public static readonly REF_TYPE_CD_CSR_CONTRACT: string = 'CSR_CONTRACT';
  public static readonly REF_TYPE_CD_LIST__ALL = [
    PmTransactionGENERATED.REF_TYPE_CD_CSR_CONSUMER,
    PmTransactionGENERATED.REF_TYPE_CD_CSR_CONTRACT
];

    public static readonly SOURCE_TYPE_CD_IMPORT: string = 'IMPORT';
    public static readonly SOURCE_TYPE_CD_MANUAL: string = 'MANUAL';
    public static readonly SOURCE_TYPE_CD_API: string = 'API';
    public static readonly SOURCE_TYPE_CD_AUTO: string = 'AUTO';
  public static readonly SOURCE_TYPE_CD_LIST__ALL = [
    PmTransactionGENERATED.SOURCE_TYPE_CD_IMPORT,
    PmTransactionGENERATED.SOURCE_TYPE_CD_MANUAL,
    PmTransactionGENERATED.SOURCE_TYPE_CD_API,
    PmTransactionGENERATED.SOURCE_TYPE_CD_AUTO
];

    public static readonly STATUS_CD_ACCEPTED: string = 'ACCEPTED';
    public static readonly STATUS_CD_REJECTED: string = 'REJECTED';
    public static readonly STATUS_CD_BLACKLISTED: string = 'BLACKLISTED';
    public static readonly STATUS_CD_NEW: string = 'NEW';
    public static readonly STATUS_CD_EXPORTED: string = 'EXPORTED';
    public static readonly STATUS_CD_PAID: string = 'PAID';
    public static readonly STATUS_CD_RETURNED: string = 'RETURNED';
    public static readonly STATUS_CD_FOR_DUNNING: string = 'FOR_DUNNING';
    public static readonly STATUS_CD_OVERPAID: string = 'OVERPAID';
    public static readonly STATUS_CD_SENDING_TO_INKASSO: string = 'SENDING_TO_INKASSO';
    public static readonly STATUS_CD_SENT_TO_INKASSO: string = 'SENT_TO_INKASSO';
    public static readonly STATUS_CD_SHOULD_GO_TO_INKASSO: string = 'SHOULD_GO_TO_INKASSO';
    public static readonly STATUS_CD_RETURNED_FROM_INKASSO: string = 'RETURNED_FROM_INKASSO';
    public static readonly STATUS_CD_REMOVED: string = 'REMOVED';
    public static readonly STATUS_CD_PROCESSED_EARLIER: string = 'PROCESSED_EARLIER';
    public static readonly STATUS_CD_SETTLED: string = 'SETTLED';
    public static readonly STATUS_CD_CANCELLED: string = 'CANCELLED';
    public static readonly STATUS_CD_OVERWRITTEN: string = 'OVERWRITTEN';
    public static readonly STATUS_CD_FAILED: string = 'FAILED';
  public static readonly STATUS_CD_LIST__ALL = [
    PmTransactionGENERATED.STATUS_CD_ACCEPTED,
    PmTransactionGENERATED.STATUS_CD_REJECTED,
    PmTransactionGENERATED.STATUS_CD_BLACKLISTED,
    PmTransactionGENERATED.STATUS_CD_NEW,
    PmTransactionGENERATED.STATUS_CD_EXPORTED,
    PmTransactionGENERATED.STATUS_CD_PAID,
    PmTransactionGENERATED.STATUS_CD_RETURNED,
    PmTransactionGENERATED.STATUS_CD_FOR_DUNNING,
    PmTransactionGENERATED.STATUS_CD_OVERPAID,
    PmTransactionGENERATED.STATUS_CD_SENDING_TO_INKASSO,
    PmTransactionGENERATED.STATUS_CD_SENT_TO_INKASSO,
    PmTransactionGENERATED.STATUS_CD_SHOULD_GO_TO_INKASSO,
    PmTransactionGENERATED.STATUS_CD_RETURNED_FROM_INKASSO,
    PmTransactionGENERATED.STATUS_CD_REMOVED,
    PmTransactionGENERATED.STATUS_CD_PROCESSED_EARLIER,
    PmTransactionGENERATED.STATUS_CD_SETTLED,
    PmTransactionGENERATED.STATUS_CD_CANCELLED,
    PmTransactionGENERATED.STATUS_CD_OVERWRITTEN,
    PmTransactionGENERATED.STATUS_CD_FAILED
];

  public apiService: PmTransactionService;
  public _rawJson: IPmTransaction;
    id: number = 0;
  amount: number;
  collectionTypeCd: string;
  description = '';
  dueDate: number;
  dunningStatusCd: string;
  flgDelay: boolean;
  flgPaused: boolean;
  idCsrConsumer: number;
  idExtTransaction = '';
  idMcEntity: number;
  idMcFactoring: number;
  idRef: number;
  lastPauseUnpauseByUserId: number;
  maxPauseDays: number;
  pauseDaysCountWithoutCurrentPauseDays: number;
  pauseEndDate: number;
  pauseStartDate: number;
  pauseUnpauseReason = '';
  reason = '';
  refTypeCd: string;
  reminderLastInterestCalculatedDatetime: number;
  sentToInkassoDatetime: number;
  sourceTypeCd: string;
  statusCd: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  sysUpdatedUserId: number;
  version: number;
  waitingPeriod = '';
  amountDue: any;  balance: any;  consumerName: string;  createdByName: string;  csrConsumer: CsrConsumer;  matchingId: string;  mcEntity: McEntity;  mcFactoring: EbFactoring;  pausedDaysCount: number;  paymentAccountEntities: McEntityList;  pmAdjustments: PmAdjustmentList;  type: string;

  public properties: string[] = ['id', 'amount', 'collectionTypeCd', 'description', 'details', 'dueDate', 'dunningStatusCd', 'flgDelay', 'flgPaused', 'idCsrConsumer', 'idExtTransaction', 'idMcEntity', 'idMcFactoring', 'idRef', 'lastPauseUnpauseByUserId', 'maxPauseDays', 'pauseDaysCountWithoutCurrentPauseDays', 'pauseEndDate', 'pauseStartDate', 'pauseUnpauseReason', 'reason', 'refTypeCd', 'reminderLastInterestCalculatedDatetime', 'sentToInkassoDatetime', 'sourceTypeCd', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'version', 'waitingPeriod', 'amountDue', 'balance', 'consumerName', 'createdByName', 'csrConsumer', 'matchingId', 'mcEntity', 'mcFactoring', 'pausedDaysCount', 'paymentAccountEntities', 'pmAdjustments', 'type'];
  public propertiesRegular: string[] = ['id', 'amount', 'collectionTypeCd', 'csrConsumer', 'description', 'details', 'dueDate', 'dunningStatusCd', 'flgDelay', 'flgPaused', 'idCsrConsumer', 'idExtTransaction', 'idMcEntity', 'idMcFactoring', 'idRef', 'lastPauseUnpauseByUserId', 'maxPauseDays', 'mcEntity', 'mcFactoring', 'pauseDaysCountWithoutCurrentPauseDays', 'pauseEndDate', 'pauseStartDate', 'pauseUnpauseReason', 'reason', 'refTypeCd', 'reminderLastInterestCalculatedDatetime', 'sentToInkassoDatetime', 'sourceTypeCd', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'version', 'waitingPeriod', 'amountDue', 'balance', 'consumerName', 'createdByName', 'matchingId', 'pausedDaysCount', 'type'];
  public propertiesSpecial: string[] = ['csrConsumer', 'mcEntity', 'mcFactoring', 'paymentAccountEntities', 'pmAdjustments'];



  public static getCollectionTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PmTransactionGENERATED.COLLECTION_TYPE_CD_DO_NOT_COLLECT, McGod.t('Do not collect'));
    list.add(PmTransactionGENERATED.COLLECTION_TYPE_CD_DIRECT_DEBIT, McGod.t('Collect direct debit'));
    list.add(PmTransactionGENERATED.COLLECTION_TYPE_CD_CREDIT_CARD, McGod.t('Collect credit card'));
    list.add(PmTransactionGENERATED.COLLECTION_TYPE_CD_TRANSFER, McGod.t('Money transfer'));
    list.add(PmTransactionGENERATED.COLLECTION_TYPE_CD_DRAFT, McGod.t('Draft'));


    return list;
  }

  public static getCollectionTypeCdLabel(aCd: string): string {
    const list = PmTransactionGENERATED.getCollectionTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getDunningStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PmTransactionGENERATED.DUNNING_STATUS_CD_IN_DUNNING, McGod.t('In Dunning'));
    list.add(PmTransactionGENERATED.DUNNING_STATUS_CD_DUNNING_COMPLETED, McGod.t('Dunning completed'));


    return list;
  }

  public static getDunningStatusCdLabel(aCd: string): string {
    const list = PmTransactionGENERATED.getDunningStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getRefTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PmTransactionGENERATED.REF_TYPE_CD_CSR_CONSUMER, McGod.t('Csr Consumer'));
    list.add(PmTransactionGENERATED.REF_TYPE_CD_CSR_CONTRACT, McGod.t('Csr contract'));


    return list;
  }

  public static getRefTypeCdLabel(aCd: string): string {
    const list = PmTransactionGENERATED.getRefTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getSourceTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PmTransactionGENERATED.SOURCE_TYPE_CD_IMPORT, McGod.t('Imported'));
    list.add(PmTransactionGENERATED.SOURCE_TYPE_CD_MANUAL, McGod.t('Manual'));
    list.add(PmTransactionGENERATED.SOURCE_TYPE_CD_API, McGod.t('Api'));
    list.add(PmTransactionGENERATED.SOURCE_TYPE_CD_AUTO, McGod.t('Auto'));


    return list;
  }

  public static getSourceTypeCdLabel(aCd: string): string {
    const list = PmTransactionGENERATED.getSourceTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PmTransactionGENERATED.STATUS_CD_ACCEPTED, McGod.t('cc.eb-factoring.accepted'));
    list.add(PmTransactionGENERATED.STATUS_CD_REJECTED, McGod.t('cc.eb-factoring.rejected'));
    list.add(PmTransactionGENERATED.STATUS_CD_BLACKLISTED, McGod.t('cc.factoring.transactions.blacklisted'));
    list.add(PmTransactionGENERATED.STATUS_CD_NEW, McGod.t('cc.common.view.new'));
    list.add(PmTransactionGENERATED.STATUS_CD_EXPORTED, McGod.t('cc.eb-sepa-export.exported'));
    list.add(PmTransactionGENERATED.STATUS_CD_PAID, McGod.t('cc.factoring.transactions.paid'));
    list.add(PmTransactionGENERATED.STATUS_CD_RETURNED, McGod.t('cc.factoring.transactions.returned'));
    list.add(PmTransactionGENERATED.STATUS_CD_FOR_DUNNING, McGod.t('cc.factoring.transactions.for-dunning'));
    list.add(PmTransactionGENERATED.STATUS_CD_OVERPAID, McGod.t('cc.transactions.overpaid'));
    list.add(PmTransactionGENERATED.STATUS_CD_SENDING_TO_INKASSO, McGod.t('cc.transactions.sending-to-inkasso'));
    list.add(PmTransactionGENERATED.STATUS_CD_SENT_TO_INKASSO, McGod.t('cc.transactions.sent-to-inkasso'));
    list.add(PmTransactionGENERATED.STATUS_CD_SHOULD_GO_TO_INKASSO, McGod.t('cc.transactions.should-go-to-inkasso'));
    list.add(PmTransactionGENERATED.STATUS_CD_RETURNED_FROM_INKASSO, McGod.t('cc.transactions.returned-from-inkasso'));
    list.add(PmTransactionGENERATED.STATUS_CD_REMOVED, McGod.t('Removed'));
    list.add(PmTransactionGENERATED.STATUS_CD_PROCESSED_EARLIER, McGod.t('Processed earlier'));
    list.add(PmTransactionGENERATED.STATUS_CD_SETTLED, McGod.t('Settled'));
    list.add(PmTransactionGENERATED.STATUS_CD_CANCELLED, McGod.t('Cancelled'));
    list.add(PmTransactionGENERATED.STATUS_CD_OVERWRITTEN, McGod.t('Overwritten'));
    list.add(PmTransactionGENERATED.STATUS_CD_FAILED, McGod.t('Failed'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = PmTransactionGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): PmTransaction {
    console.error('MTCN-ERROR: Not Implemented: PmTransaction::createNewInstance(). Add this method to final class and return new PmTransaction();');
    throw new Error('Cannot PmTransaction::createNewInstance(). Add this method to final class and return new PmTransaction();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IPmTransaction): PmTransaction {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(PmTransactionService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE PmTransaction AABB()');
          if (this._rawJson['csrConsumer']) {
        this.csrConsumer = CsrConsumer.createFromJson(this._rawJson['csrConsumer']);
      }
      if (this._rawJson['mcEntity']) {
        this.mcEntity = McEntity.createFromJson(this._rawJson['mcEntity']);
      }
      if (this._rawJson['mcFactoring']) {
        this.mcFactoring = EbFactoring.createFromJson(this._rawJson['mcFactoring']);
      }
      if (this._rawJson['paymentAccountEntities']) {
        this.paymentAccountEntities = McEntityList.createFromJson(this._rawJson['paymentAccountEntities']);
      }
      if (this._rawJson['pmAdjustments']) {
        this.pmAdjustments = PmAdjustmentList.createFromJson(this._rawJson['pmAdjustments']);
      }

  }

  // ---------------------------------------------------------------------

    public getAmountStr(): string {
       return McGod.formatNumberToMoney(this.amount);
    }

    public getSentToInkassoDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sentToInkassoDatetime);
    }

    public getSentToInkassoDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sentToInkassoDatetime);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getAmountDueStr(): string {
       return McGod.formatNumberToMoney(this.amountDue);
    }

    public getBalanceStr(): string {
       return McGod.formatNumberToMoney(this.balance);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('csrConsumer')) {
        if (this.csrConsumer != null) {
            dto['csrConsumer'] = this.csrConsumer.toDto();
        } else {
            dto['csrConsumer'] = null;
        }
    }
    if (this.hasOwnProperty('mcEntity')) {
        if (this.mcEntity != null) {
            dto['mcEntity'] = this.mcEntity.toDto();
        } else {
            dto['mcEntity'] = null;
        }
    }
    if (this.hasOwnProperty('mcFactoring')) {
        if (this.mcFactoring != null) {
            dto['mcFactoring'] = this.mcFactoring.toDto();
        } else {
            dto['mcFactoring'] = null;
        }
    }
    if (this.hasOwnProperty('paymentAccountEntities')) {
        if (this.paymentAccountEntities != null) {
            dto['paymentAccountEntities'] = this.paymentAccountEntities.toDto();
        } else {
            dto['paymentAccountEntities'] = [];
        }
    }
    if (this.hasOwnProperty('pmAdjustments')) {
        if (this.pmAdjustments != null) {
            dto['pmAdjustments'] = this.pmAdjustments.toDto();
        } else {
            dto['pmAdjustments'] = [];
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --


  public getCollectionTypeCdLabel(): string {
    return PmTransactionGENERATED.getCollectionTypeCdLabel(this.collectionTypeCd);
  }


 public  isCollectionTypeCdDO_NOT_COLLECT(): boolean {
    const result = (this.collectionTypeCd === PmTransactionGENERATED.COLLECTION_TYPE_CD_DO_NOT_COLLECT);

    return result;
  }

 public  isCollectionTypeCdDIRECT_DEBIT(): boolean {
    const result = (this.collectionTypeCd === PmTransactionGENERATED.COLLECTION_TYPE_CD_DIRECT_DEBIT);

    return result;
  }

 public  isCollectionTypeCdCREDIT_CARD(): boolean {
    const result = (this.collectionTypeCd === PmTransactionGENERATED.COLLECTION_TYPE_CD_CREDIT_CARD);

    return result;
  }

 public  isCollectionTypeCdTRANSFER(): boolean {
    const result = (this.collectionTypeCd === PmTransactionGENERATED.COLLECTION_TYPE_CD_TRANSFER);

    return result;
  }

 public  isCollectionTypeCdDRAFT(): boolean {
    const result = (this.collectionTypeCd === PmTransactionGENERATED.COLLECTION_TYPE_CD_DRAFT);

    return result;
  }


  public getDunningStatusCdLabel(): string {
    return PmTransactionGENERATED.getDunningStatusCdLabel(this.dunningStatusCd);
  }


 public  isDunningStatusCdIN_DUNNING(): boolean {
    const result = (this.dunningStatusCd === PmTransactionGENERATED.DUNNING_STATUS_CD_IN_DUNNING);

    return result;
  }

 public  isDunningStatusCdDUNNING_COMPLETED(): boolean {
    const result = (this.dunningStatusCd === PmTransactionGENERATED.DUNNING_STATUS_CD_DUNNING_COMPLETED);

    return result;
  }


  public getRefTypeCdLabel(): string {
    return PmTransactionGENERATED.getRefTypeCdLabel(this.refTypeCd);
  }


 public  isRefTypeCdCSR_CONSUMER(): boolean {
    const result = (this.refTypeCd === PmTransactionGENERATED.REF_TYPE_CD_CSR_CONSUMER);

    return result;
  }

 public  isRefTypeCdCSR_CONTRACT(): boolean {
    const result = (this.refTypeCd === PmTransactionGENERATED.REF_TYPE_CD_CSR_CONTRACT);

    return result;
  }


  public getSourceTypeCdLabel(): string {
    return PmTransactionGENERATED.getSourceTypeCdLabel(this.sourceTypeCd);
  }


 public  isSourceTypeCdIMPORT(): boolean {
    const result = (this.sourceTypeCd === PmTransactionGENERATED.SOURCE_TYPE_CD_IMPORT);

    return result;
  }

 public  isSourceTypeCdMANUAL(): boolean {
    const result = (this.sourceTypeCd === PmTransactionGENERATED.SOURCE_TYPE_CD_MANUAL);

    return result;
  }

 public  isSourceTypeCdAPI(): boolean {
    const result = (this.sourceTypeCd === PmTransactionGENERATED.SOURCE_TYPE_CD_API);

    return result;
  }

 public  isSourceTypeCdAUTO(): boolean {
    const result = (this.sourceTypeCd === PmTransactionGENERATED.SOURCE_TYPE_CD_AUTO);

    return result;
  }


  public getStatusCdLabel(): string {
    return PmTransactionGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACCEPTED(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_ACCEPTED);

    return result;
  }

 public  isStatusCdREJECTED(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_REJECTED);

    return result;
  }

 public  isStatusCdBLACKLISTED(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_BLACKLISTED);

    return result;
  }

 public  isStatusCdNEW(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_NEW);

    return result;
  }

 public  isStatusCdEXPORTED(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_EXPORTED);

    return result;
  }

 public  isStatusCdPAID(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_PAID);

    return result;
  }

 public  isStatusCdRETURNED(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_RETURNED);

    return result;
  }

 public  isStatusCdFOR_DUNNING(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_FOR_DUNNING);

    return result;
  }

 public  isStatusCdOVERPAID(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_OVERPAID);

    return result;
  }

 public  isStatusCdSENDING_TO_INKASSO(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_SENDING_TO_INKASSO);

    return result;
  }

 public  isStatusCdSENT_TO_INKASSO(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_SENT_TO_INKASSO);

    return result;
  }

 public  isStatusCdSHOULD_GO_TO_INKASSO(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_SHOULD_GO_TO_INKASSO);

    return result;
  }

 public  isStatusCdRETURNED_FROM_INKASSO(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_RETURNED_FROM_INKASSO);

    return result;
  }

 public  isStatusCdREMOVED(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_REMOVED);

    return result;
  }

 public  isStatusCdPROCESSED_EARLIER(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_PROCESSED_EARLIER);

    return result;
  }

 public  isStatusCdSETTLED(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_SETTLED);

    return result;
  }

 public  isStatusCdCANCELLED(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_CANCELLED);

    return result;
  }

 public  isStatusCdOVERWRITTEN(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_OVERWRITTEN);

    return result;
  }

 public  isStatusCdFAILED(): boolean {
    const result = (this.statusCd === PmTransactionGENERATED.STATUS_CD_FAILED);

    return result;
  }



// ---------------------------------------------------------------------
public callPauseTransaction( transactionIds: any, pauseEndDate: any, pauseUnpauseReason: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.pauseTransaction(transactionIds,pauseEndDate,pauseUnpauseReason).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

// ---------------------------------------------------------------------
public callUnpauseTransaction( transactionIds: any, pauseUnpauseReason: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.unpauseTransaction(transactionIds,pauseUnpauseReason).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

}
