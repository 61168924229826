import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { McEntity2, McForm, McGod} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-factoring-enable-disable-part',
  templateUrl: './app-shared-factoring-enable-disable-part.component.html',
})
export class AppSharedFactoringEnableDisablePartComponent implements OnInit {

  /*Variables*/
  mcForm = new McForm();
  enableDisableVlm!: Map<boolean, string>;
  mcEntity2 = new McEntity2();
  mcGod = McGod.getInstance();
  @Input() mcEntity2Id!: number;
  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();

  ngOnInit() {
    this.actLoad();
    this.enableDisableVlm = new Map<boolean, string>();
    this.enableDisableVlm.set(true, this.mcGod.t('cc.common.edit.enable'));
    this.enableDisableVlm.set(false, this.mcGod.t('cc.common.edit.disable'));


  }

  // ---------------------------------------------------------------------
  actLoad() {
    this.mcEntity2.loadById(this.mcEntity2Id, () => {
      const enabledFlg = this.mcEntity2.factoringEnableFlg;
      this.mcForm.formGroup.get('value')?.setValue(enabledFlg ? 'yes' : 'no');
    });
  }

  actSave() {
    this.mcEntity2.factoringEnableFlg = this.mcForm.getValue('ctrlEnableDisableFactoring');
    this.mcEntity2.save(() => {
      this.eventSaved.emit();
    });
  }

  actCancel() {
    this.eventCanceled.emit();
  }
}
