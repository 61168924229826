/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbSepaFile} from '../models/eb-sepa-file.model';
import {EbSepaFileService} from '../services/eb-sepa-file.service';
import {IEbSepaFile} from '../_generated/eb-sepa-file.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';




export abstract class EbSepaFileGENERATED extends BaseObject {







    public static readonly STATUS_CD_NEW: string = 'NEW';
    public static readonly STATUS_CD_PROCESSING: string = 'PROCESSING';
    public static readonly STATUS_CD_DONE: string = 'DONE';
    public static readonly STATUS_CD_REMOVED: string = 'REMOVED';
    public static readonly STATUS_CD_OVERWRITTEN: string = 'OVERWRITTEN';
  public static readonly STATUS_CD_LIST__ALL = [
    EbSepaFileGENERATED.STATUS_CD_NEW,
    EbSepaFileGENERATED.STATUS_CD_PROCESSING,
    EbSepaFileGENERATED.STATUS_CD_DONE,
    EbSepaFileGENERATED.STATUS_CD_REMOVED,
    EbSepaFileGENERATED.STATUS_CD_OVERWRITTEN
];

  public apiService: EbSepaFileService;
  public _rawJson: IEbSepaFile;
    id: number = 0;
  createdAt: number;
  idMcEntity: number;
  idWebfile: number;
  requestedCollectionDate: number;
  servicePeriod: number;
  statusCd: string;
    acceptedAmount: any;    acceptedRejectedDebtors: string;    allDebtors: number;    childEntityName: string;    overwrittenAmount: any;    overwrittenCnt: number;    processedEarlierCnt: number;    processedNowCnt: number;    rejectedAmount: any;    remainingAmount: any;    reporting: string;    requestedOrBatchCollectionDates: any;    sepaFilename: string;    totalAmountDue: any;

  public properties: string[] = ['id', 'createdAt', 'idMcEntity', 'idWebfile', 'requestedCollectionDate', 'servicePeriod', 'statusCd', 'acceptedAmount', 'acceptedRejectedDebtors', 'allDebtors', 'childEntityName', 'overwrittenAmount', 'overwrittenCnt', 'processedEarlierCnt', 'processedNowCnt', 'rejectedAmount', 'remainingAmount', 'reporting', 'requestedOrBatchCollectionDates', 'sepaFilename', 'totalAmountDue'];
  public propertiesRegular: string[] = ['id', 'createdAt', 'idMcEntity', 'idWebfile', 'mcEntity', 'requestedCollectionDate', 'servicePeriod', 'statusCd', 'acceptedAmount', 'acceptedRejectedDebtors', 'allDebtors', 'childEntityName', 'overwrittenAmount', 'overwrittenCnt', 'processedEarlierCnt', 'processedNowCnt', 'rejectedAmount', 'remainingAmount', 'reporting', 'requestedOrBatchCollectionDates', 'sepaFilename', 'totalAmountDue'];
  public propertiesSpecial: string[] = [];







  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbSepaFileGENERATED.STATUS_CD_NEW, McGod.t('cc.common.view.new'));
    list.add(EbSepaFileGENERATED.STATUS_CD_PROCESSING, McGod.t('cc.common.view.processing'));
    list.add(EbSepaFileGENERATED.STATUS_CD_DONE, McGod.t('cc.common.view.done'));
    list.add(EbSepaFileGENERATED.STATUS_CD_REMOVED, McGod.t('Removed'));
    list.add(EbSepaFileGENERATED.STATUS_CD_OVERWRITTEN, McGod.t('Overwritten'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = EbSepaFileGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): EbSepaFile {
    console.error('MTCN-ERROR: Not Implemented: EbSepaFile::createNewInstance(). Add this method to final class and return new EbSepaFile();');
    throw new Error('Cannot EbSepaFile::createNewInstance(). Add this method to final class and return new EbSepaFile();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbSepaFile): EbSepaFile {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbSepaFileService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbSepaFile AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getCreatedAtStrD(): string {
       return McDateUtils.formatDateToString(this.createdAt);
    }

    public getCreatedAtStr(): string {
       return McDateUtils.formatDateTimeToString(this.createdAt);
    }

    public getAcceptedAmountStr(): string {
       return McGod.formatNumberToMoney(this.acceptedAmount);
    }

    public getOverwrittenAmountStr(): string {
       return McGod.formatNumberToMoney(this.overwrittenAmount);
    }

    public getRejectedAmountStr(): string {
       return McGod.formatNumberToMoney(this.rejectedAmount);
    }

    public getRemainingAmountStr(): string {
       return McGod.formatNumberToMoney(this.remainingAmount);
    }

    public getTotalAmountDueStr(): string {
       return McGod.formatNumberToMoney(this.totalAmountDue);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  






  public getStatusCdLabel(): string {
    return EbSepaFileGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdNEW(): boolean {
    const result = (this.statusCd === EbSepaFileGENERATED.STATUS_CD_NEW);

    return result;
  }

 public  isStatusCdPROCESSING(): boolean {
    const result = (this.statusCd === EbSepaFileGENERATED.STATUS_CD_PROCESSING);

    return result;
  }

 public  isStatusCdDONE(): boolean {
    const result = (this.statusCd === EbSepaFileGENERATED.STATUS_CD_DONE);

    return result;
  }

 public  isStatusCdREMOVED(): boolean {
    const result = (this.statusCd === EbSepaFileGENERATED.STATUS_CD_REMOVED);

    return result;
  }

 public  isStatusCdOVERWRITTEN(): boolean {
    const result = (this.statusCd === EbSepaFileGENERATED.STATUS_CD_OVERWRITTEN);

    return result;
  }


  
// ---------------------------------------------------------------------
public callImportFile( overwriteFlg: boolean, ebSepaFile: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.importFile(overwriteFlg,ebSepaFile)
    .subscribe(responseJson => {
        this.populateFromJson(responseJson);
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
}

}
