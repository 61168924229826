/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbPaymentReport2SepaFileTransaction} from '../models/eb-payment-report-2-sepa-file-transaction.model';
import {EbPaymentReport2SepaFileTransactionService} from '../services/eb-payment-report-2-sepa-file-transaction.service';
import {IEbPaymentReport2SepaFileTransaction} from '../_generated/eb-payment-report-2-sepa-file-transaction.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {EbPaymentReport} from '../models/eb-payment-report.model';




export abstract class EbPaymentReport2SepaFileTransactionGENERATED extends BaseObject {





  public apiService: EbPaymentReport2SepaFileTransactionService;
  public _rawJson: IEbPaymentReport2SepaFileTransaction;
    id: number = 0;
  amount: number;
  idPaymentReport: number;
  idSepaFileTransaction: number;
    ebPaymentReport: EbPaymentReport;

  public properties: string[] = ['id', 'amount', 'idPaymentReport', 'idSepaFileTransaction', 'ebPaymentReport'];
  public propertiesRegular: string[] = ['id', 'amount', 'ebPaymentReport', 'ebSepaFileTransaction', 'idPaymentReport', 'idSepaFileTransaction'];
  public propertiesSpecial: string[] = ['ebPaymentReport'];





  // ---------------------------------------------------------------------
public static createNewInstance(): EbPaymentReport2SepaFileTransaction {
    console.error('MTCN-ERROR: Not Implemented: EbPaymentReport2SepaFileTransaction::createNewInstance(). Add this method to final class and return new EbPaymentReport2SepaFileTransaction();');
    throw new Error('Cannot EbPaymentReport2SepaFileTransaction::createNewInstance(). Add this method to final class and return new EbPaymentReport2SepaFileTransaction();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbPaymentReport2SepaFileTransaction): EbPaymentReport2SepaFileTransaction {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbPaymentReport2SepaFileTransactionService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbPaymentReport2SepaFileTransaction AABB()');
          if (this._rawJson['ebPaymentReport']) {
        this.ebPaymentReport = EbPaymentReport.createFromJson(this._rawJson['ebPaymentReport']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getAmountStr(): string {
       return McGod.formatNumberToMoney(this.amount);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('ebPaymentReport')) {
        if (this.ebPaymentReport != null) {
            dto['ebPaymentReport'] = this.ebPaymentReport.toDto();
        } else {
            dto['ebPaymentReport'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  




  
}
