import {Component, Input, OnInit} from "@angular/core";
import {
  McBillingService2PriceList,
  McForm,
  McValueLabelList,
  McBillingServiceCatalog, McGod
} from "@miticon-ui/mc-core";

@Component({
  selector: 'mc-pricelist-pricelist-service-edit-part',
  templateUrl: './mc-pricelist-pricelist-service-edit-part.component.html',
})
export class McPricelistPricelistServiceEditPartComponent implements OnInit {

  chargeOptionsVll = new McValueLabelList();
  additionalDoNotChargeFlg = false;
  additionalDoNotChargeLabel!: string;
  additionalDoNotChargeValue!: boolean;
  serviceTypeCd!: string;
  defaultCopy: any;
  disabledOptionsVll!: McValueLabelList;

  @Input() customizeFlg = false;
  @Input() index!: number;
  @Input() service2PriceList!: McBillingService2PriceList;
  @Input() defaultService2PriceList!: McBillingService2PriceList;
  @Input() mcForm = new McForm();
  @Input() currency!: string;
  @Input() areServiceButtonsDisabled = false;

  /*Constructor*/
  constructor() {
  }
  /*On init*/
  ngOnInit() {
    this.service2PriceList.id = 0;

    this.chargeOptionsVll = McBillingService2PriceList.getPricingTypeCdVll();

    this.serviceTypeCd = this.service2PriceList.serviceTypeCd;

    if (this.serviceTypeCd === McBillingServiceCatalog.TYPE_CD_PDF_CREATION) {
      this.additionalDoNotChargeFlg = true;
      this.additionalDoNotChargeValue = this.service2PriceList.flgDoNotChargeForFactoredTransactions;
      this.additionalDoNotChargeLabel = McGod.t('cc.pricelist.do-not-charge-for-factored-transactions');
    }

    this.defaultCopy = {...this.defaultService2PriceList};
  }

  onChargeOptionChangeEmit(value: any) {
    this.service2PriceList.pricingTypeCd = value;
    if (value === McBillingService2PriceList.PRICING_TYPE_CD_ONE_TIME_ONLY) {
      this.mcForm.getControl('ctrlValueMix' + this.index)?.get('value')?.setValue('');
      this.mcForm.getControl('ctrlForUnits' + this.index)?.get('value')?.setValue('');
      this.mcForm.getControl('ctrlValuePlusPerAdditionalUnit' + this.index)?.get('value')?.setValue('');
      this.service2PriceList.priceFlat = 0;
      this.service2PriceList.unitLimitForFlatPrice = 0;
      this.service2PriceList.priceAdditionalUnit = 0;
    } else if (value === McBillingService2PriceList.PRICING_TYPE_CD_MIX) {
      this.mcForm.getControl('ctrlValueOneTimeOnly' + this.index)?.get('value')?.setValue('');
      this.service2PriceList.priceOneTime = 0;
    } else {
      this.mcForm.getControl('ctrlValueMix' + this.index)?.get('value')?.setValue('');
      this.mcForm.getControl('ctrlForUnits' + this.index)?.get('value')?.setValue('');
      this.mcForm.getControl('ctrlValuePlusPerAdditionalUnit' + this.index)?.get('value')?.setValue('');
      this.service2PriceList.priceFlat = 0;
      this.service2PriceList.unitLimitForFlatPrice = 0;
      this.service2PriceList.priceAdditionalUnit = 0;
      this.mcForm.getControl('ctrlValueOneTimeOnly' + this.index)?.get('value')?.setValue('');
      this.service2PriceList.priceOneTime = 0;
    }

  }


  onOneTimeOnlyChargeValueChangeEmit(value: number) {
    this.service2PriceList.priceOneTime = value;
  }

  onMixFlatPriceValueChangeEmit(value: number) {
    this.service2PriceList.priceFlat = value;
  }

  onLimitForUnitsValueChangeEmit(value: number) {
    this.service2PriceList.unitLimitForFlatPrice = value;
  }

  onMixPlusPerAdditionUnitChangeValuEmit(value: number) {
    this.service2PriceList.priceAdditionalUnit = value;
  }


  onAdditionalDoNotChargeValueChangeEmit(value: boolean) {
    if (this.serviceTypeCd === McBillingServiceCatalog.TYPE_CD_PDF_CREATION) {
      this.additionalDoNotChargeValue = value;
      this.service2PriceList.flgDoNotChargeForFactoredTransactions = value;
    }
  }

  onCustomPriceFlgValueChangeEmit(value: boolean) {
    this.areServiceButtonsDisabled = false;
    this.service2PriceList.flgCustomPrice = value;
    this.disabledOptionsVll = new McValueLabelList();
    this.disabledOptionsVll.items = this.chargeOptionsVll.items.filter(item => item.value !== this.service2PriceList.pricingTypeCd);
    if (!value) {
      this.service2PriceList.pricingTypeCd = this.defaultCopy.pricingTypeCd;
      this.service2PriceList.priceOneTime = this.defaultCopy.priceOneTime;
      this.service2PriceList.priceFlat = this.defaultCopy.priceFlat;
      this.service2PriceList.unitLimitForFlatPrice = this.defaultCopy.unitLimitForFlatPrice;
      this.service2PriceList.priceAdditionalUnit = this.defaultCopy.priceAdditionalUnit;
      this.service2PriceList.flgDoNotChargeForFactoredTransactions = this.defaultCopy.flgDoNotChargeForFactoredTransactions;

      if (this.serviceTypeCd === McBillingServiceCatalog.TYPE_CD_PDF_CREATION) {
        this.service2PriceList.flgDoNotChargeForFactoredTransactions = this.defaultCopy.flgDoNotChargeForFactoredTransactions;
      }

    }
  }

}
