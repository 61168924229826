<div class="content-wrapper">
  <lib-mc-loader [showLoader]="pmTransaction.apiLoadingFlg"></lib-mc-loader>

  <div class="content-header-slim">
    <h3>{{pageTitle}}</h3>
  </div>

  <div *ngIf="!pmAdjustment.apiLoadingFlg" class="content-body">
    <mcc-message [errorMsg]="pmAdjustment.apiErrorMessage"></mcc-message>
    <mcc-message  [errorMsgs]="errorMsgs" label="{{'cc.my-factoring.unable-to-proceed' | translate}}: "></mcc-message>

    <ng-container *ngIf="(pmAdjustment.apiSuccessFlg) && (!hasErrors())">

        <div class="row">
          <div class="col-md-12">

              <div class="payment-information">
                <div class="info-summary">
                  <div class="info-summary-header">
              <h4>{{'cc.my-factoring.consumer-information' | translate}}</h4>
                  </div>
                  <div class="inner-content desc-uppercase">
            <mcc-row-title-description title="{{'cc.my-factoring.consumer' | translate}}:"
                                       [description]="pmTransaction.csrConsumer ? pmTransaction.csrConsumer.displayName : ''"></mcc-row-title-description>

            <mcc-row-title-description title="{{'cc.common.contract-number' | translate}}:"
                                       [description]="pmTransaction.csrConsumer ? pmTransaction.csrConsumer.contractNumber : ''"></mcc-row-title-description>

            <mcc-row-title-description title="{{'cc.common.view.entity' | translate}}:"
                                       [description]="pmTransaction.csrConsumer ? pmTransaction.idMcEntity : ''"></mcc-row-title-description>

            <mcc-row-title-description title="{{'cc.my-factoring.original-amount' | translate}}:"
                                       [description]="pmTransaction.getAmount()"></mcc-row-title-description>

            <mcc-row-title-description title="{{'cc.my-factoring.remaining-amount-due' | translate}}:"
                                       [description]="pmTransaction.getAmountDue()"></mcc-row-title-description>
                  </div>
                </div>
              </div>


            <div class="payment-details mt-3">
              <h5>{{'cc.my-factoring.for-transaction' | translate}} #{{pmTransactionId}}</h5>

            <mcc-fi-radio-buttons2 *ngIf="isTypeAdjustment()" name="rbTypeCd"
                                   label="{{'cc.jobs.edit.type' | translate}}"
                                   [optionsVll]="typeCdVll"
                                   [value]="pmAdjustment.typeCd"
                                   [validRequiredFlg]="true"
                                   [mcForm]="mcForm"></mcc-fi-radio-buttons2>

            <mcc-fi-radio-buttons2 *ngIf="isTypePayment()" name="rbPaymentMethodCd"
                                   label="{{'cc.my-factoring.payment-method' | translate}}"
                                   [optionsVll]="paymentMethodCdVll"
                                   (eventValueChanged)="onPaymentTypeValueChange($event)"
                                   [value]="pmAdjustment.paymentMethodCd"
                                   [validRequiredFlg]="true"
                                   [mcForm]="mcForm"></mcc-fi-radio-buttons2>

              <ng-container *ngIf="!isTypeCredit">
                <mcc-fi-number-type nameForValue="ctrlAmount" nameForType="ctrlAmountType"
                                    label="{{'cc.factoring.transactions.amount' | translate}}" [validMinNumber]="0"
                                    [maxDecimalPlaces]="2"
                                    [value]="pmAdjustment.amount"
                                    [chosenCurrency]="currencyCode"
                                    [valueType]="currencyCode" [disablePercentage]="true" [validRequiredFlg]="true"
                                    [labelOnTopFlg]="true" [mcForm]="mcForm">
                </mcc-fi-number-type>
              </ng-container>

            <mcc-fi-textarea name="ctrlDescription" label="{{'cc.common.view.description' | translate}}"
                             [value]="pmAdjustment.description" [validRequiredFlg]="true"
                             [mcForm]="mcForm" [numCols]="45"
                             [mcForm]="mcForm">

            </mcc-fi-textarea>

              <ng-container *ngIf="!isTypeCredit">
                <mcc-fi-radio-buttons2 *ngIf="isTypePayment()" name="ctrlPaymentEntity" label="Payment made to"
                                       [optionsVll]="paymentAccountEntitiesVll"
                                       [directionHorizontal]="false"
                                       [labelOnTop]="true"
                                       [validRequiredFlg]="true"
                                       [mcForm]="mcForm"></mcc-fi-radio-buttons2>
              </ng-container>

            </div>
        </div>
      </div>
    </ng-container>


  </div>
  <div *ngIf="(pmAdjustment.apiSuccessFlg) && (!hasErrors())" class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary move-right" (eventClicked)="actCancel();"
                label="{{'cc.common.edit.cancel' | translate}}"></mcc-button>
    <button class="btn btn-primary" (click)="actSave();" [disabled]="saveButtonDisabled">{{'cc.common.view.save' | translate}}</button>
  </div>
</div>
