
import {Table3Config} from '../extra/table-3-config';

// import {PmAdjustmentNotificationInfoGENERATED} from '../_generated/pm-adjustment-notification-info-generated.model';

export class PmAdjustmentNotificationInfoTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colCsrConsumer2CtdEmail', 'Consumer2Email', 'csrConsumer2CtdEmail', 'csrConsumer2CtdEmail');
    this.addColumn('colIdCsrConsumer2CtdEmail', 'CsrConsumer2CtdEmailId', 'idCsrConsumer2CtdEmail', 'idCsrConsumer2CtdEmail');
    this.addColumn('colIdMcProcessNotification', 'Process Notification id', 'idMcProcessNotification', 'idMcProcessNotification');
    this.addColumn('colIdPmAdjustment', 'Pm Adjustment Id', 'idPmAdjustment', 'idPmAdjustment');
    this.addColumn('colNotificationStatusCd', 'Notification status', 'notificationStatusCd', 'notificationStatusCd');
    this.addColumn('colStatusDetails', 'Status details', 'statusDetails', 'statusDetails');

*/
  }
}
