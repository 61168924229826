import { Component,OnInit } from '@angular/core';
import { MkFilterConfig,MkFilterOutput,MkMatMenuItem,MkMatTableMenuAction,MkTableConfig } from '@miticon-ui/mc-components';
import { EbConsumerBlacklist,EbConsumerBlacklistFilter,EbConsumerBlacklistService,McBoolean,McConsumer,McGod,SortCriteriaList } from '@miticon-ui/mc-core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mc-out-factoring-blacklist-list-page',
  templateUrl: './mc-out-factoring-blacklist-list-page.component.html',
})
export class McOutFactoringBlacklistListPageComponent implements OnInit {

  showAddEditModalMcb = new McBoolean();
  showRemoveFromBlacklistMcb = new McBoolean();
  addEditConsumerId = 0;
  ebConsumerBlacklistFilter = new EbConsumerBlacklistFilter();
  removeConsumerBlacklist = new EbConsumerBlacklist();
  searchTooltip = McGod.t('cc.out-factoring.search-by-consumer-name-email');
  totalItemsCount!: number;
  pageSize = 5;
  pageNumber= 0;
  isLoading = false;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  items: EbConsumerBlacklist[] = [];

  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.common.delete'),
      actionCd: McConsumer.ACTION_SHOW_EDIT,
      matIcon: 'delete',
      permission: McGod.PERM_MC_CONSUMER_EDIT,
    }
  ]

  constructor(private toastr: ToastrService,
              private tS: TranslateService,
              private ebConsumerBlacklistService: EbConsumerBlacklistService) {}


  ngOnInit() {
    this.initTableConfig();
    this.actLoad(this.pageNumber, this.pageSize, this.ebConsumerBlacklistFilter);
  }

  initTableConfig(){
    this.tableConfig.addColumnStandard(this.tS.instant('cc.consumers.view.consumer-name'), 'fldName()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.edit.email'), 'email', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.out-factoring.added-date-&-time'), 'fldAddedDate()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.out-factoring.added-reason'), 'addedReason', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.out-factoring.added-by'), 'addedByUser', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.out-factoring.removed-date-&-time'), 'fldRemovedDate()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.out-factoring.removed-reason'), 'removedReason', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.out-factoring.removed-by'), 'removedByUser', 250);
  }

  onFilterChanged(filterOutput: MkFilterOutput){
    this.ebConsumerBlacklistFilter.searchTerm = filterOutput.search ? filterOutput.search : '';
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, this.ebConsumerBlacklistFilter);
  }

  actLoad(pageNumber: number, pageSize: number, ebConsumerBlacklistFilter: EbConsumerBlacklistFilter): void {
    this.pageNumber=pageNumber;
    this.pageSize= pageSize;
    this.isLoading = true;
    const sortCriteriaList = new SortCriteriaList();

    this.ebConsumerBlacklistService.getByFilter(ebConsumerBlacklistFilter, this.pageNumber, this.pageSize, sortCriteriaList).subscribe((res) => {
      if(res){
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => EbConsumerBlacklist.createFromJson(object));
      }
    })
  }

  actShowAddEdit(itemId = 0) {
    this.addEditConsumerId = itemId;
    this.showAddEditModalMcb.setTrue();
  }

  actShowRemoveFromBlacklist(ebConsumerBlacklist: EbConsumerBlacklist) {
    if (ebConsumerBlacklist.exists()) {
        this.removeConsumerBlacklist = ebConsumerBlacklist;
        this.showRemoveFromBlacklistMcb.setTrue();
    }
  }

  onTableAction($event: MkMatTableMenuAction){
    if ($event.action.actionCd === McConsumer.ACTION_SHOW_EDIT) {
      this.actShowRemoveFromBlacklist($event.item);
    }
  }

  actCloseModal() {
    this.showAddEditModalMcb.setFalse();
    this.showRemoveFromBlacklistMcb.setFalse();
    this.removeConsumerBlacklist = new EbConsumerBlacklist();
  }

  eventConsumerBlacklistSaved() {
    this.addEditConsumerId = -1;
    this.actLoad(this.pageNumber, this.pageSize, this.ebConsumerBlacklistFilter);
    this.showAddEditModalMcb.setFalse();
    this.toastr.success(`${McGod.t('cc.out-factoring.consumer-successfully-blacklisted')}.`, '', {timeOut: 4000, extendedTimeOut: 10});
  }

  eventRemovedFromBlacklist() {
    this.actLoad(this.pageNumber, this.pageSize, this.ebConsumerBlacklistFilter);
    this.showRemoveFromBlacklistMcb.setFalse();
    this.toastr.success(`${McGod.t('cc.out-factoring.consumer-successfully-removed-from-blacklist')}.`, '', {timeOut: 4000, extendedTimeOut: 10});
  }
}
