import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  CsrBankAccount,
  CsrBankAccountList,
  CsrConsumer, CsrConsumerService,
  EbFactoringService,
  McDateUtils, McEntityConsumerAttribute, McEntityConsumerAttributeList,
  McForm,
  McGod,
  McValueLabelList
} from '@miticon-ui/mc-core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'mc-consumer-consumer-add-edit-part',
  templateUrl: './mc-consumer-consumer-add-edit-part.component.html',
  styleUrls: ['./mc-consumer-consumer-add-edit-part.component.scss']
})
export class McConsumerConsumerAddEditPartComponent implements OnInit, OnChanges {

  mcForm = new McForm();
  yesNoVlm!: Map<string, string>;
  typeVlm!: Map<string, string>;
  statusVlm!: Map<string, string>;
  genderVll!: McValueLabelList;
  countryCdsVll!: McValueLabelList;
  languagesCdsVll!: McValueLabelList;
  firstLastNameRequiredFlg!: boolean;
  mcGod = McGod.getInstance();
  today = McDateUtils.getNowTimestamp();
  currency!: string;
  type!: string;
  message!: string;
  dunningFlg: boolean;
  csrBankAccount = new CsrBankAccount();

  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;

  @Input() csrConsumerId!: number;
  @Input() csrConsumer = new CsrConsumer();


  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();



  constructor(
    private activeRoute: ActivatedRoute,
    private factoringService: EbFactoringService,
    private csrConsumerService: CsrConsumerService) {
  }


  ngOnInit() {

    this.countryCdsVll = new McValueLabelList();
    this.countryCdsVll.add('US', this.mcGod.t('cc.common.view.usa'));
    this.countryCdsVll.add('DE', this.mcGod.t('cc.common.view.germany'));
    this.countryCdsVll.add('IT', this.mcGod.t('cc.common.view.italy'));

    this.genderVll = new McValueLabelList();
    this.genderVll.add('MALE', this.mcGod.t('cc.common.edit.male'));
    this.genderVll.add('FEMALE', this.mcGod.t('cc.common.edit.female'));
    this.genderVll.add('OTHER', this.mcGod.t('cc.common.edit.prefer-not-to-say'));

    this.languagesCdsVll = new McValueLabelList();
    this.languagesCdsVll.add('EN', this.mcGod.t('cc.common.view.english'));
    this.languagesCdsVll.add('DE', this.mcGod.t('cc.common.view.german'));
    this.languagesCdsVll.add('IT', this.mcGod.t('cc.common.view.italian'));

    this.yesNoVlm = new Map<string, string>();
    this.yesNoVlm.set('yes', this.mcGod.t('cc.common.edit.yes'));
    this.yesNoVlm.set('no', this.mcGod.t('cc.common.edit.no'));

    this.statusVlm = new Map<string, string>();
    this.statusVlm.set(CsrConsumer.STATUS_CD_ACTIVE, this.mcGod.t('cc.common.view.active'));
    this.statusVlm.set(CsrConsumer.STATUS_CD_INACTIVE, this.mcGod.t('cc.common.view.inactive'));

    this.typeVlm = new Map<string, string>();
    this.typeVlm.set(CsrConsumer.TYPE_CD_PERSON, this.mcGod.t('cc.consumers.edit.person'));
    this.typeVlm.set(CsrConsumer.TYPE_CD_COMPANY, this.mcGod.t('cc.consumers.edit.company'));

    this.currency = this.factoringService.activeChildFactoring.mcCurrency.code;

    if (this.csrConsumer.bankAccounts?.items?.length > 0) {
      const primaryBankAccounts = this.csrConsumer.bankAccounts.items.filter(item => item.flgPrimary);
      if (primaryBankAccounts.length > 0) {
        const latestPrimaryBankAccount = primaryBankAccounts.reduce((prev, curr) => {
          return prev.sysCreatedDatetime > curr.sysCreatedDatetime ? prev : curr;
        });
        this.csrBankAccount = latestPrimaryBankAccount;
      }
    }
  }

  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }
  // ---------------------------------------------------------------------
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['csrConsumerId']) {
      if ((changes['csrConsumerId'].previousValue !== changes['csrConsumerId'].currentValue)) {
        this.actLoad();
        this.firstLastNameRequiredFlg = this.csrConsumer.individual();
      }
    }
  }


  // ---------------------------------------------------------------------
  public actLoad(): void {
    if (this.csrConsumerId > 0) {
      this.csrConsumer.loadById(this.csrConsumerId, () => {
        this.firstLastNameRequiredFlg = this.csrConsumer.individual();
        this.dunningFlg = this.csrConsumer.flgDunningEnabled;
      });
    } else {
      this.firstLastNameRequiredFlg = true;
      this.dunningFlg = true;
    }

    this.csrConsumer.entityConsumerAttributes = new McEntityConsumerAttributeList();
    this.loadEntityCustomAttributes();
  }

  // ---------------------------------------------------------------------
  actSaveItem() {
    if (this.mcForm.isValid()) {
      this.csrConsumer.idMcEntity = McGod.getLoggedEntityIdFromToken();
      this.csrConsumer.extConsumerId = this.mcForm.getValue('ctrlExtConsumerId');
      this.csrConsumer.typeCd = this.mcForm.getValue('ctrlTypeCd');
      this.csrConsumer.statusCd = this.mcForm.getValue('ctrlStatusCd');
      this.csrConsumer.firstName = this.mcForm.getValue('ctrlFirstName');
      this.csrConsumer.lastName = this.mcForm.getValue('ctrlLastName');
      this.csrConsumer.companyName = this.mcForm.getValue('ctrlCompanyName');
      this.csrConsumer.title = this.mcForm.getValue('ctrlTitle');
      this.csrConsumer.gender = this.mcForm.getValue('ctrlGender');
      this.csrConsumer.birthday = this.mcForm.getValue('ctrlBirthday');
      this.csrConsumer.street = this.mcForm.getValue('ctrlStreet');
      this.csrConsumer.addressSupplement = this.mcForm.getValue('ctrlAddressSupplement');
      this.csrConsumer.postCode = this.mcForm.getValue('ctrlPostCode');
      this.csrConsumer.city = this.mcForm.getValue('ctrlCity');
      this.csrConsumer.isoCountry = this.mcForm.getValue('ctrlIsoCountry');
      this.csrConsumer.isoLanguage = this.mcForm.getValue('ctrlIsoLanguage');
      this.csrConsumer.telephone = this.mcForm.getValue('ctrlTelephone');
      this.csrConsumer.cellphone = this.mcForm.getValue('ctrlCellphone');
      this.csrConsumer.fax = this.mcForm.getValue('ctrlFax');
      this.csrConsumer.email = this.mcForm.getValue('ctrlEmail');
      this.setNotificationMessage(`Required fields can\'t be empty`, 'error');
      this.csrConsumer.iban = this.mcForm.getValue('ctrlIban');
      this.csrConsumer.bic = this.mcForm.getValue('ctrlBic');
      this.csrConsumer.accountOwner = this.mcForm.getValue('ctrlAccountOwner');
      this.csrConsumer.sepaMandanteId = this.mcForm.getValue('ctrlSepaMandateId');
      this.csrConsumer.sepaMandanteDate = this.mcForm.getValue('ctrlSepaMandateDate');
      this.csrConsumer.countryName = this.mcForm.getValue('ctrlCountryName');
      this.csrConsumer.bankName = this.mcForm.getValue('ctrlBankName');
      let additionalAttributes = new Map<string, any>();
      additionalAttributes.set('contractNumber', this.mcForm.getValue('ctrlContractNumber'));
      additionalAttributes.set('contractStatus', this.mcForm.getValue('ctrlContractStatus'));
      additionalAttributes.set('contractEndDate', this.mcForm.getValue('ctrlEndDate') ? McDateUtils.formatDateToString(this.mcForm.getValue('ctrlEndDate'), 'YYYY-MM-DD') : '');
      additionalAttributes.set('contractStartDate', this.mcForm.getValue('ctrlStartDate') ? McDateUtils.formatDateToString(this.mcForm.getValue('ctrlStartDate'), 'YYYY-MM-DD') : '');
      additionalAttributes.set('contractBaseAmount', this.mcForm.getValue('ctrlContractBaseAmount'));
      additionalAttributes.set('contractSigningDate', this.mcForm.getValue('ctrlSigningDate') ? McDateUtils.formatDateToString(this.mcForm.getValue('ctrlSigningDate'), 'YYYY-MM-DD') : '');
      additionalAttributes.set('contractCancellationDate', this.mcForm.getValue('ctrlCancellationDate') ? McDateUtils.formatDateToString(this.mcForm.getValue('ctrlCancellationDate'), 'YYYY-MM-DD') : '');
      additionalAttributes.set('contractCancellationActiveOnDate', this.mcForm.getValue('ctrlCancellationActiveOnDate') ? McDateUtils.formatDateToString(this.mcForm.getValue('ctrlCancellationActiveOnDate'), 'YYYY-MM-DD') : '');
      additionalAttributes.set('contractCancellationReason', this.mcForm.getValue('ctrlContractCancellationReason'));
      additionalAttributes.set('contractModel', this.mcForm.getValue('ctrlContractModel'));
      additionalAttributes.set('contractType', this.mcForm.getValue('ctrlContractType'));
      this.csrConsumer.flgDunningEnabled = this.dunningFlg;
      this.csrConsumer.bankAccounts = CsrBankAccountList.createNewInstance();

      if (this.csrConsumer.iban) {
        const csrBankAccount: CsrBankAccount = CsrBankAccount.createNewInstance();
        csrBankAccount.owner = this.csrConsumer.accountOwner;
        csrBankAccount.bankName = this.csrConsumer.bankName;
        csrBankAccount.iban = this.csrConsumer.iban;
        csrBankAccount.bic = this.csrConsumer.bic;
        csrBankAccount.sepaMandateId = this.csrConsumer.sepaMandanteId;
        csrBankAccount.sepaMandateDate = this.csrConsumer.sepaMandanteDate;
        this.csrConsumer.bankAccounts.items.push(csrBankAccount);
      }

      if (this.csrConsumer.entityConsumerAttributes) {
        this.csrConsumer.entityConsumerAttributes.items.forEach(item => {
          if (this.mcForm.getValue(item.name) != null) {
            item.value = this.mcForm.getValue(item.name).toString();
            additionalAttributes.set(item.name, item.value);
          }
        });
      }

      additionalAttributes.forEach((value, key) => {
        if (value === '' || value === 0) {
          additionalAttributes.delete(key);
        }
      });

      if(this.csrConsumer.additionalAttributes){
        for (const key of Object.keys(this.csrConsumer.additionalAttributes)) {
          additionalAttributes.set(key, this.mcForm.getValue(key).toString());
        }
      }

      let additionalAttributesObject: { [key: string]: any } = {};
      additionalAttributes.forEach((value, key) => {
        additionalAttributesObject[key] = value;
      });

      this.csrConsumer.additionalAttributes = additionalAttributesObject;
      this.csrConsumer.save(() => {
        this.csrConsumerId = this.csrConsumer.id;
        this.eventSaved.emit();
      }, () => {
      });
    }

  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.csrConsumerId = -4;
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.csrConsumer.exists()) {

      this.csrConsumer.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

  // ---------------------------------------------------------------------
  isVisible(): boolean {
    return (this.csrConsumer != null);
  }

  onTypeCodeChanged(value: any) {
    if (value === 'COMPANY') {
      // this.companyNameValidFlg = true;
      this.firstLastNameRequiredFlg = false;
    } else {
      this.firstLastNameRequiredFlg = true;
      // this.companyNameValidFlg = false;
    }
  }

  onDunningFlgChanged(value: string){
    this.dunningFlg = (value === 'yes');
  }

  loadEntityCustomAttributes(){
    this.csrConsumerService.getAttributes()
      .subscribe(
        (response: McEntityConsumerAttribute[]) => {
            response.forEach(item => {
              if(!item.name.startsWith('contract')){
                let entityAttribute = new McEntityConsumerAttribute();
                entityAttribute.name = item.name;
                entityAttribute.value = item.value;
                this.csrConsumer.entityConsumerAttributes.items.push(entityAttribute);
              }
            });
        }
      )
  }
}
