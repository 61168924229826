/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McEntityConsumerAttributeTable3ConfigGENERATED} from '../_generated/mc-entity-consumer-attribute-table-3-config-generated.model';

// import {McEntityConsumerAttributeGENERATED} from '../_generated/mc-entity-consumer-attribute-generated.model';

export class McEntityConsumerAttributeTable3Config extends McEntityConsumerAttributeTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colName', 'Name', 'name', 'name');
    this.addColumn('colTypeCd', 'Type', 'typeCd', 'typeCd');
    this.addColumn('colEnabledFlg', 'Enabled', 'enabledFlg', 'enabledFlg');
    this.addColumn('colArchivedFlg', 'Archived', 'archivedFlg', 'archivedFlg');
    this.addColumn('colInheritedFlg', 'Inherited', 'inheritedFlg', 'inheritedFlg');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colInheritableFlg', 'Inheritable', 'inheritableFlg', 'inheritableFlg');
    this.addColumn('colCreationDate', 'Creation date', 'creationDate', 'creationDate');
    this.addColumn('colDefaultValue', 'Default value', 'defaultValue', 'defaultValue');
    this.addColumn('colValidationRegex', 'Validation regex', 'validationRegex', 'validationRegex');


  }
}
