/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {CsrDiscount} from '../models/csr-discount.model';
import {CsrDiscountList} from '../models/csr-discount-list.model';
import {CsrDiscountFilter} from '../models/csr-discount-filter.model';
import {CsrDiscountService} from '../services/csr-discount.service';
import {ICsrDiscount} from '../_generated/csr-discount.interface';
import {McGod} from "../extra/mc-god.service";

export class CsrDiscountListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'idMcEntity', 'statusCd', 'typeCd', 'value'];
  public items: CsrDiscount[] = [];
  public apiService: CsrDiscountService;
 // protected tempObjList: CsrDiscountList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrDiscountList {
    console.error('MTCN-ERROR: Not Implemented: CsrDiscountList::createNewInstance(). Add this method to final class and return new CsrDiscountList();');
    throw new Error('Cannot CsrDiscountList::createNewInstance(). Add this method to final class and return new CsrDiscountList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: ICsrDiscount[]): CsrDiscountList {
    const  listObj: CsrDiscountList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: CsrDiscount = CsrDiscount.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): CsrDiscountList {
    const  listObj: CsrDiscountList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrDiscountService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: ICsrDiscount[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: CsrDiscount = CsrDiscount.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aCsrDiscountFilter: CsrDiscountFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aCsrDiscountFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): CsrDiscount {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new CsrDiscount();
    }
  }

  public replaceItem(newItem: CsrDiscount) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

