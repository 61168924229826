import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DataImportListPageComponent} from "./pages/data-import-list-page/data-import-list-page.component";
import {DataImportAddComponent} from "./pages/data-import-add/data-import-add.component";
import {DataExportListPageComponent} from "./pages/data-export-list-page/data-export-list-page.component";
import {DataExportAddComponent} from "./pages/data-export-add/data-export-add.component";

const routes: Routes = [
  {
    path: 'import',
    component: DataImportListPageComponent
  },
  {
    path: 'import/add',
    component: DataImportAddComponent
  },
  {
    path: 'export',
    component: DataExportListPageComponent
  },
  {
    path: 'export/add',
    component: DataExportAddComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImDataImportRoutingModule {
}
