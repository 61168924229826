export const ICON_FAILED_ITEMS = `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_954_10560)">
<path d="M17.3625 10.875L15 13.2375L12.6375 10.875C12.15 10.3875 11.3625 10.3875 10.875 10.875C10.3875 11.3625 10.3875 12.15 10.875 12.6375L13.2375 15L10.875 17.3625C10.3875 17.85 10.3875 18.6375 10.875 19.125C11.3625 19.6125 12.15 19.6125 12.6375 19.125L15 16.7625L17.3625 19.125C17.85 19.6125 18.6375 19.6125 19.125 19.125C19.6125 18.6375 19.6125 17.85 19.125 17.3625L16.7625 15L19.125 12.6375C19.6125 12.15 19.6125 11.3625 19.125 10.875C18.6375 10.4 17.8375 10.4 17.3625 10.875ZM15 2.5C8.0875 2.5 2.5 8.0875 2.5 15C2.5 21.9125 8.0875 27.5 15 27.5C21.9125 27.5 27.5 21.9125 27.5 15C27.5 8.0875 21.9125 2.5 15 2.5ZM15 25C9.4875 25 5 20.5125 5 15C5 9.4875 9.4875 5 15 5C20.5125 5 25 9.4875 25 15C25 20.5125 20.5125 25 15 25Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_954_10560">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>
`
