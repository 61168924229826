<div class="container mc-create-role-modal-body border-bottom-0 mc-gray-background pt-0" *ngIf="formSummery" style="height: 584px;">
  <!--Wrapper row-->
  <div class="row mc-create-role-summery-wrapper mc-scrollbar-vertical">
    <!--Basic info-->
    <div class="col-md-4 border-right">
      <!--Title-->
      <p class="h5 mc-active text-capitalize border-bottom-0 mt-4">
        <i class="fa fa-pen px-1 mc-cursor-pointer" (click)="goToStep(1)"></i>
        {{ 'cc.common.view.basic-info' | translate }}:
      </p>

      <!--Status-->
      <p class="text-uppercase mb-0 mt-2 mc-font-semi-weight-1">status</p>
      <div *ngIf="formSummery.firstStepForm.hasOwnProperty('status')">
        <mc-status [status]="formSummery.firstStepForm.status"></mc-status>
      </div>

      <!--Name-->
      <p class="text-uppercase mb-0 mt-3 mc-font-semi-weight-1">name</p>
      <p class="mb-3">{{selectedEntity}} {{formSummery.firstStepForm.caption}}</p>

      <!--Description-->
      <p class="text-uppercase mb-0 mc-font-semi-weight-1">{{ 'cc.common.view.description' | translate }}</p>
      <p class="mb-2">{{formSummery.firstStepForm.description}}</p>

      <!--Set as template-->
      <ng-container *ngIf="mcGod.userHasMcRoleTemplateCreateEditPermission()">
        <p class="text-uppercase mb-0 mc-font-semi-weight-1">{{ 'cc.roles.set-as-template' | translate }}</p>
        <p class="mb-2">{{formSummery.firstStepForm.setRoleAsATemplate ? mcGod.t('cc.common.edit.yes') : mcGod.t('cc.common.edit.no')}}</p>
      </ng-container>
    </div><!--Basic info /end-->

    <!--Selected permissions-->
    <div class="col-md-8">

      <!--Title-->
      <p class="h5 mc-active text-capitalize border-bottom-0 mt-4">
        <i class="fa fa-pen px-1 mc-cursor-pointer" (click)="goToStep(2)"></i>
        {{'cc.roles.view.permissions' | translate}}:
      </p>

      <ul class="list-unstyled" *ngFor="let permissionModule of formSummery.secondStepForm">
        <li *ngIf="permissionModule['permissions']" class="mc-font-semi-weight-1 text-uppercase">
          {{permissionModule['name']}}

          <ul class="list-unstyled font-weight-normal font text-lowercase">
            <li *ngFor="let permission of permissionModule['permissions']">
                    <span *ngIf="permission['selected']">
                      {{permission['description']}}
                    </span>
            </li>
          </ul>
        </li>
      </ul>

      <i *ngIf="formSummery.secondStepForm.length === 0">No selected permissions</i>


    </div><!--Selected permissions /end-->
  </div><!--Row /end-->
  <div class="row">
    <div class="col-md-12">

      <!--Error section-->
      <div class="mc-error position-relative mt-2">
        <mc-notify-message [message]="errorObject.message" [type]="errorObject.type"></mc-notify-message>
      </div>
    </div>
    <div class="col-md-6 offset-md-6 mc-action-buttons">
      <!--Create buttons-->
      <div class="mc-create-role-buttons-wrapper">
        <button class="mc-next-save-btn mc-button text-capitalize mx-2" (click)="onCreate()">{{ "cc.common.view.create" | translate }}</button>
        <button class="mc-next-save-btn mc-button mx-2" (click)="onCreate(true)">{{'cc.users.edit.create-&-add-new' | translate}}</button>
      </div>
    </div>
  </div>
</div>


