/*BbmTestObj*/
import {CsrMappingItemGENERATED} from '../_generated/csr-mapping-item-generated.model';

export class CsrMappingItem extends CsrMappingItemGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrMappingItem();
  }

  // ---------------------------------------------------------------------
}
