<lib-mc-loader [showLoader]="mcProcess.apiLoadingFlg"></lib-mc-loader>

<span class="back-option" (click)="actGoToProcesses()">
  <span [innerHTML]="iconBack | safeHtml"></span>
  {{ "cc.process.back-to-processes" | translate }}
</span>

<div class="content-wrapper wrapper-padding">
  <div class="content-body">
    <mcc-menu [mcMenu]="mcMenu"></mcc-menu>

    <div class="cardbox mb-3">
      <span class="cardbox_title">
        {{ mcProcess.title }} {{ "cc.common.overview" | translate | lowercase }}
      </span>
    </div>

    <ng-container *ngIf="filterConfig">
      <mat-expansion-panel class="mb-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div>
              <span [innerHTML]='iconFilter | safeHtml'></span>
              <span>{{'cc.common.view.filter' | translate}}s</span>
            </div>
            <span [innerHTML]='(panelOpenState ? dropDownIcon : dropUpIcon) | safeHtml'></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mk-mat-filter">
          <div class="mk-mat-filter-container">
            <div class="filter-inputs">
              <!--  SEARCH  -->
              <form [formGroup]="searchForm">
                <mat-form-field appearance="outline">
                  <mat-label>Search</mat-label>
                  <input type="text" matInput formControlName="search"  [matTooltip]="searchToolTip" (keyup.enter)="onFilter()">
                </mat-form-field>
              </form>

              <mk-mat-filter *ngFor="let item of filterConfig.items"
                             [type]="item.type"
                             [name]="item.name"
                             [values]="item.values"
                             [clearFilters]="clearFilters"
                             (valueChangeSingle)="onFilterSingleSelection(item.name, $event)"
                             (valueChangeDatepicker)="onFilterDatepickerValueChange(item.name, $event)">
              </mk-mat-filter>
            </div>
          </div>

          <div class="filter-actions">
            <button mat-flat-button class="mc-button clear-all" (click)="onClearFilter()">{{'cc.common.clear-all-filters' | translate}}</button>
            <button mat-flat-button class="mc-button filter-button" (click)="onFilter()">{{'cc.common.view.filter' | translate}}</button>
          </div>
        </div>
      </mat-expansion-panel>
    </ng-container>

    <mcc-fi-table-3 [objectList]="emailObjectList"
                    [table3Config]="tableConfig"
                    (eventPaginationOrSortChanged)="actLoad()"
                    (eventItemAction)="onTableItemAction($event)">
    </mcc-fi-table-3>
  </div>
</div>
