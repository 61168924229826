import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {McForm, McGod, McProfileUserService, McUtilityService, McValueLabelList} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-user-user-add-edit-part',
  templateUrl: './mc-user-user-add-edit-part.component.html',
  styleUrls: ['./mc-user-user-add-edit-part.scss']
})
export class McUserUserAddEditPartComponent implements OnInit {

  imageUrl: any = 'https://image.shutterstock.com/image-vector/empty-photo-male-profile-gray-260nw-538707310.jpg';
  message!: string;
  type!: string;
  isEnabled!: boolean;
  firstName!: string;
  lastName!: string;
  userImage: any;
  timezone!: number;
  editTimezone!: string;
  editLanguage!: string;
  mcForm!: McForm;

  @Input() parentData!: any;

  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter();
  genderVlm!: Map<string, string>;
  userLanguageOptionsVll = new McValueLabelList();
  userTimezoneOptionsVll = new McValueLabelList();
  userCountryOptionsVll = new McValueLabelList();
  errorMsg!: string;


  constructor(
    private modalService: NgbModal,
    private profileUserService: McProfileUserService,
    private fb: FormBuilder,
    private utilityService: McUtilityService
  ) {
  }

  ngOnInit() {
    this.mcForm = new McForm();
    this.genderVlm = new Map<string, string>();
    this.genderVlm.set('MALE', McGod.t('cc.common.edit.male'));
    this.genderVlm.set('FEMALE', McGod.t('cc.common.edit.female'));
    this.genderVlm.set('OTHER', McGod.t('cc.common.edit.prefer-not-to-say'));
    this.actLoad();
  }

  actLoad() {
    this.imageUrl = this.parentData.image;
    this.isEnabled = false;

    this.userLanguageOptionsVll.add('EN', 'English');
    this.userLanguageOptionsVll.add('DE', 'German');
    this.userLanguageOptionsVll.add('IT', 'Italian');

    this.userTimezoneOptionsVll.add(1, '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague');
    this.userTimezoneOptionsVll.add(-8, '(UTC-08:00) Pacific Time (US & Canada)');

    this.userCountryOptionsVll.add('US', 'Usa');
    this.userCountryOptionsVll.add('DE', 'Germany');
    this.userCountryOptionsVll.add('IT', 'Italy');
    if (this.parentData.timezone === 1) {
      this.editTimezone = '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague';
    } else {
      this.editTimezone = '(UTC-08:00) Pacific Time (US & Canada)';
    }
    switch (this.parentData.isoLanguage) {
      case 'EN':
        this.editLanguage = 'EN';
        break;
      case 'DE':
        this.editLanguage = 'DE';
        break;
      case 'IT':
        this.editLanguage = 'IT';
        break;
    }
  }

  // Take value from inputFileImage
  onFileChanged(event: any) {
    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (e: any) => {
      this.imageUrl = reader.result;
    });
    reader.readAsDataURL(file);
  }

  // Edit userData
  actSave() {
    if (this.mcForm.isValid()) {
      const userId = this.utilityService.getPropertyFromToken('user_id');
      const body = {
        id: userId,
        firstName: this.mcForm.getValue('ctrlFirstName'),
        lastName: this.mcForm.getValue('ctrlLastName'),
        gender: this.mcForm.getValue('ctrlGender'),
        isoCountry: this.mcForm.getValue('selUserCountry'),
        isoLanguage: this.mcForm.getValue('selUserLanguage'),
        nickname: this.mcForm.getValue('ctrlNickname'),
        rawImageData: this.imageUrl || null,
        timezone: this.mcForm.getValue('selUserTimezone')
      };
      this.isEnabled = true;
      this.profileUserService.editUserData(body).subscribe(
        (response) => {
          this.eventSaved.emit();
        },
        (error) => {
          if (error.status >= 500) {
            this.errorMsg = 'Server is not available at the moment, please try again later.';
            return;
          }
          this.errorMsg = error.error.message;
        }
      );
    }
  }

  getGender(): string {
    if (this.parentData.gender === 'MALE') {
      return 'MALE';
    }
    if (this.parentData.gender === 'FEMALE') {
      return 'FEMALE';
    }
    if (this.parentData.gender === 'OTHER') {
      return 'OTHER';
    }
    return '';
  }

  getSelectedLanguage(): string {
    if (this.parentData.isoLanguage === 'EN') {
      return 'EN';
    }
    if (this.parentData.isoLanguage === 'DE') {
      return 'DE';
    }
    if (this.parentData.isoLanguage === 'IT') {
      return 'IT';
    }
    return '';
  }

  getSelectedCountry(): string {
    if (this.parentData.isoCountry === 'US') {
      return 'US';
    }
    if (this.parentData.isoCountry === 'DE') {
      return 'DE';
    }
    if (this.parentData.isoCountry === 'IT') {
      return 'IT';
    }
    return '';
  }

  actCancel() {
    this.eventCanceled.emit();
  }
}

