<ng-container *ngIf="wrapInRowDivFlg">
  <div class="row {{extraCssClasses}}">
    <div class="title" [innerHTML]="title" [ngClass]="widerTitleFlg ? 'col-md-8' : 'col-md-4'"></div>
    <div *ngIf="!descriptionInnerHtml" class="description"  [ngClass]="widerTitleFlg ? 'col-md-4' : 'col-md-8'">
      {{description}}
      <mcc-html *ngIf="descriptionHtml" [values]="descriptionHtml"></mcc-html>
    </div>
    <div *ngIf="descriptionInnerHtml" [innerHTML]="descriptionInnerHtml" class="description"  [ngClass]="widerTitleFlg ? 'col-md-4' : 'col-md-8'">
      <mcc-html *ngIf="descriptionHtml" [values]="descriptionHtml"></mcc-html>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!wrapInRowDivFlg">
  <div class="col-md-4 title" [innerHTML]="title"></div>
  <div class="col-md-8 description">
    {{description}}
    <mcc-html *ngIf="descriptionHtml" [values]="descriptionHtml"></mcc-html>
  </div>
</ng-container>
