
import {Table3Config} from '../extra/table-3-config';

// import {McBillingBatchGENERATED} from '../_generated/mc-billing-batch-generated.model';

export class McBillingBatchTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDateFrom', 'Date from', 'dateFrom', 'dateFrom');
    this.addColumn('colDateTo', 'Date to', 'dateTo', 'dateTo');
    this.addColumn('colFlgActive', 'Flg Active', 'flgActive', 'flgActive');
    this.addColumn('colFlgFinalized', 'Flg finalized', 'flgFinalized', 'flgFinalized');
    this.addColumn('colIdChildEntity', 'Child entity id', 'idChildEntity', 'idChildEntity');
    this.addColumn('colIdContract', 'Id of contract', 'idContract', 'idContract');
    this.addColumn('colIdParentEntity', 'Parent entity id', 'idParentEntity', 'idParentEntity');
    this.addColumn('colIdSepaFile', 'Id sepa file for sepa batches', 'idSepaFile', 'idSepaFile');
    this.addColumn('colMcFactoring', 'Factoring', 'mcFactoring', 'mcFactoring');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colTypeCd', 'Type cd', 'typeCd', 'typeCd');
    this.addColumn('colTypeOfTransactionsInBatchCd', 'Type Of Transactions In Batch Cd', 'typeOfTransactionsInBatchCd', 'typeOfTransactionsInBatchCd');

*/
  }
}
