<h3 class="page-title">{{ 'cc.common.payout' | translate}}</h3>

<div class="row">
    <lib-mc-loader [showLoader]=" payoutFile.apiLoadingFlg"></lib-mc-loader>
</div>

<mcc-modal1-wrapper modalSizeCd="small" [heightAutoFlg]="true" [showMcb]="showDeleteMcb" *ngIf="showDeleteMcb.value" (eventCanceled)="onDeletePayoutFileActionButtonClicked(globalActions.Cancel)">
    <div class="content-wrapper">
      <div class="content-header">
        <h3>Delete payout file</h3>
      </div>
      <div class="content-body">
        <div class="d-flex align-items-center">
          <h1 class="text-orange-color m-0 pr-3"><i class="far fa-question-circle"></i></h1>
          <span [innerHTML]="dialogDescription"></span>
        </div>
      </div>
      <div class="content-footer d-flex">
        <button class="btn btn-secondary ml-auto" (click)="onDeletePayoutFileActionButtonClicked(globalActions.Cancel)">{{ 'cc.common.edit.cancel' | translate }}</button>
        <button class="btn btn-primary" (click)="onDeletePayoutFileActionButtonClicked(globalActions.Delete)"><i class="far fa-trash-alt mr-medium"></i>{{'cc.common.delete' | translate}}</button>
      </div>
    </div>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper modalSizeCd="full" [showMcb]="showAddEditPayoutFileMcb" *ngIf="showAddEditPayoutFileMcb.value" (eventCanceled)="onAddEditPayoutFileCancelEmit()">
    <mc-out-billing-payout-file-add-edit-part (eventCanceled)="onAddEditPayoutFileCancelEmit()" (eventSaved)="onCreatePayoutFileSaveEmit()"></mc-out-billing-payout-file-add-edit-part>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper modalSizeCd="small" [showMcb]="showUploadFileMcb" *ngIf="showUploadFileMcb.value">
  <lib-upload-file (eventUploaded)="onUploadFile($event)"></lib-upload-file>
</mcc-modal1-wrapper>

<div class="actions-container">
  <span>{{"cc.payout.payout-files" | translate}}</span>
  <div class="actions-wrapper">
    <button  *ngIf="mcGod.userHasMcBillingOutPayoutFilesCreatePermission()" class="system-action-btn mr-2" (click)="onCreatePayoutFile()">
      <i class="fas fa-plus-circle mc-add-new-user-icon"></i> {{'cc.payout.create-payout-file' | translate}}
    </button>
  </div>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  (eventColumnButtonAction)="onColumnButtonAction($event)"
  (actionMkMatMenuItems)="onTableAction($event)"
  [mkMatMenuActionItems]="tableActionItems"
  (filterChanged)="onFilterChanged($event)"
>
</mk-mat-table>
