export const ICON_TO_BE_SENT = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1594_11892)">
<path d="M17 10C17.1 10 17.19 10.01 17.28 10.01L4.39 4.58C3.73 4.31 3 4.79 3 5.51V9.22C3 9.68 3.31 10.08 3.76 10.19L11 12L3.76 13.81C3.31 13.92 3 14.32 3 14.78V18.49C3 19.21 3.73 19.69 4.39 19.41L10 17.05C10 17.03 10 17.02 10 17C10 13.14 13.14 10 17 10Z" fill="#53589B"/>
<path d="M17 12C14.24 12 12 14.24 12 17C12 19.76 14.24 22 17 22C19.76 22 22 19.76 22 17C22 14.24 19.76 12 17 12ZM18.29 19L16.64 17.35C16.55 17.26 16.49 17.13 16.49 17V14.5C16.49 14.22 16.71 14 16.99 14C17.27 14 17.49 14.22 17.49 14.5V16.79L18.99 18.29C19.19 18.49 19.19 18.8 18.99 19C18.8 19.2 18.49 19.2 18.29 19Z" fill="#53589B"/>
</g>
<defs>
<clipPath id="clip0_1594_11892">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
`
