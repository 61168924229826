import {McBillingPayoutFileItemFeCtd} from './mc-billing-payout-file-item-fe-ctd.model';
import {McBillingPayoutFileItemFeCtdListGENERATED} from '../_generated/mc-billing-payout-file-item-fe-ctd-list-generated.model';

export class McBillingPayoutFileItemFeCtdList extends McBillingPayoutFileItemFeCtdListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingPayoutFileItemFeCtdList();
  }

  // ---------------------------------------------------------------------
}
