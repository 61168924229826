/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McConsumerFilter} from '../models/mc-consumer-filter.model';

export class McConsumerFilterGENERATED extends BaseObjectFilter{    public fnames: string[] = [];
    public searchTerm: string | undefined;
    public statusCds: string[] = [];
    public typeCds: string[] = [];

  public properties: string[] = ['fnames', 'searchTerm', 'statusCds', 'typeCds', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McConsumerFilter {
    console.error('MTCN-ERROR: Not Implemented: McConsumerFilter::createNewInstance(). Add this method to final class and return new McConsumerFilter();');
      throw new Error('Cannot McConsumerFilter::createNewInstance(). Add this method to final class and return new McConsumerFilter();');
  }

}
