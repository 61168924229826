/*BbmTestObj*/
import {EbSepaStatementReportTable3ConfigGENERATED} from '../_generated/eb-sepa-statement-report-table-3-config-generated.model';


export class EbSepaStatementReportTable3Config extends EbSepaStatementReportTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.addColumn('colStarMoneyFileName', this.t('cc.starmoney.starmoney-filename'), 'fldStarMoneyFileName()', 'wf.filename');
    this.addColumn('colImportDateAndTime', this.t('cc.factoring.imported-sepa.import-date-&-time'), 'fldImportDateAndTime()', 'createdAt');
    this.addColumn('colWithdrawnReturnedTransactions', this.t('cc.starmoney.withdrawn-returned-transactions'), 'fldWithdrawnAndReturnedTransactions()', '');
    this.addColumn('colWithdrawnReturnedAmount', this.t('cc.starmoney.withdrawn-returned-amount'), 'fldWithdrawnReturnedAmount()', '').extraCssClass = 'text-right';
    this.addColumn('colNewRepeatedTransactions', this.t('cc.starmoney.new-repeated-transactions'), 'fldNewRepeatedTransactions()', '');
    this.addColumn('colImportedBy', this.t('cc.common.imported-by'), 'fldImportedBy()', 'user.firstname');
    this.addColumn('colStatusCd', this.t('cc.common.view.status'), 'fldStarMoneyStatus()', 'statusCd');
  }
}
