/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McProcessFlowCriteriaTable3ConfigGENERATED} from '../_generated/mc-process-flow-criteria-table-3-config-generated.model';

// import {McProcessFlowCriteriaGENERATED} from '../_generated/mc-process-flow-criteria-generated.model';

export class McProcessFlowCriteriaTable3Config extends McProcessFlowCriteriaTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdMcProcessFlow', 'McProcessFlow ID', 'idMcProcessFlow', 'idMcProcessFlow');
    this.addColumn('colAttributeName', 'Attribute name', 'attributeName', 'attributeName');
    this.addColumn('colAttributeTypeCd', 'Attribute type code', 'attributeTypeCd', 'attributeTypeCd');
    this.addColumn('colOperatorTypeCd', 'Operator type code', 'operatorTypeCd', 'operatorTypeCd');
    this.addColumn('colValue', 'Value', 'value', 'value');
    this.addColumn('colAttributeOptionTypeCd', 'Attribute option type code', 'attributeOptionTypeCd', 'attributeOptionTypeCd');
    this.addColumn('colSeqNo', 'Sequence number for appending multiple criteria', 'seqNo', 'seqNo');


  }
}
