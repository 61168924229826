import {McBillingServiceCatalog} from './mc-billing-service-catalog.model';
import {McBillingServiceCatalogListGENERATED} from '../_generated/mc-billing-service-catalog-list-generated.model';

export class McBillingServiceCatalogList extends McBillingServiceCatalogListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingServiceCatalogList();
  }

  // ---------------------------------------------------------------------
}
