import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ICsrConsumer2CtdEmail} from '../_generated/csr-consumer-2-ctd-email.interface';
import {CsrConsumer2CtdEmail} from '../models/csr-consumer-2-ctd-email.model';
import {CsrConsumer2CtdEmailList} from '../models/csr-consumer-2-ctd-email-list.model';
import {CsrConsumer2CtdEmailServiceGENERATED} from '../_generated/csr-consumer-2-ctd-email-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SortCriteriaList} from "../_core/sort-criteria-list.model";


@Injectable({
  providedIn: 'root'
})
export class CsrConsumer2CtdEmailService extends CsrConsumer2CtdEmailServiceGENERATED {

  public getAllByEntityId(entityId: any, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/get-all-by-entity-id/${entityId}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }
}
