import {EbInkassoExport} from './eb-inkasso-export.model';
import {EbInkassoExportListGENERATED} from '../_generated/eb-inkasso-export-list-generated.model';

export class EbInkassoExportList extends EbInkassoExportListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbInkassoExportList();
  }

  // ---------------------------------------------------------------------
}
