import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'mcc-dialog-box',
  templateUrl: './mcc-dialog-box.component.html',
  styleUrls: ['./mcc-dialog-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MccDialogBoxComponent implements OnInit {
  static readonly btnOk = 'btn-ok';
  static readonly btnCancel = 'btn-cancel';
  static readonly btnYes = 'btn-yes';
  static readonly btnNo = 'btn-no';
  static readonly btnDelete = 'btn-delete';
  static readonly btnClose = 'btn-close';

  @Output() eventActionButtonClicked = new EventEmitter<string>();
  @Output() eventCloseButtonClicked = new EventEmitter();
  @Input() buttonsKvm!: Map<string, string>;
  @Input() name!: string;
  @Input() extraCssClasses!: string;
  @Input() title!: string;
  @Input() description!: string;
  @Input() popupType: 'info' | 'warning' | 'question' | 'error' = 'question';
  @Input() buttonsSetCd!: 'yes-no' | 'ok-cancel' | 'close' | 'delete-cancel';
  @Input() btnOkLabel = 'Ok';
  @Input() btnCancelLabel = 'Cancel';
  @Input() btnYesLabel = 'Yes';
  @Input() btnNoLabel = 'No';
  @Input() btnCloseLabel = 'Close';
  @Input() btnDeleteLabel = 'Delete';
  @Input() enableCloseButtonFlg = true;

  // @Input() dialogBoxButtons: MccDialogBoxButtons[];

  constructor() {
  }

  ngOnInit() {
    if (!this.buttonsKvm) {
      this.setDefaultButtonsKvm();
    }
  }

  setDefaultButtonsKvm() {
    this.buttonsKvm = new Map<string, string>();
    if (this.buttonsSetCd === 'yes-no') {
      this.buttonsKvm.set(MccDialogBoxComponent.btnNo, this.btnNoLabel);
      this.buttonsKvm.set(MccDialogBoxComponent.btnYes, this.btnYesLabel);
    } else if (this.buttonsSetCd === 'ok-cancel') {
      this.buttonsKvm.set(MccDialogBoxComponent.btnOk, this.btnOkLabel);
      this.buttonsKvm.set(MccDialogBoxComponent.btnYes, this.btnYesLabel);
    } else if (this.buttonsSetCd === 'close') {
      this.buttonsKvm.set(MccDialogBoxComponent.btnClose, this.btnCloseLabel);
    } else if (this.buttonsSetCd === 'delete-cancel') {
      this.buttonsKvm.set(MccDialogBoxComponent.btnDelete, this.btnDeleteLabel);
      this.buttonsKvm.set(MccDialogBoxComponent.btnCancel, this.btnCancelLabel);
    }
  }

}
