
import {Table3Config} from '../extra/table-3-config';

// import {CsrRecurringDetailsGENERATED} from '../_generated/csr-recurring-details-generated.model';

export class CsrRecurringDetailsTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDay', 'Day in a', 'day', 'day');
    this.addColumn('colMonth', 'Month of the', 'month', 'month');
    this.addColumn('colTypeCd', 'Statuses', 'typeCd', 'typeCd');

*/
  }
}
