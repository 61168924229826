import { Component, OnInit } from '@angular/core';
import { ICON_DATA_IMPORT } from "../../../../assets/media/svg_icons/sidenav-icons/icon-data-import";
import { MkTableConfig } from "@miticon-ui/mc-components";
import {TranslateService} from "@ngx-translate/core";
import {HttpParams} from "@angular/common/http";
import {ImDataExport, McGod, SortCriteriaItem, SortCriteriaList} from '@miticon-ui/mc-core';
import {Router} from "@angular/router";
import {ImDataExportService} from "@miticon-ui/mc-core";

@Component({
  selector: 'lib-data-export-list-page',
  templateUrl: './data-export-list-page.component.html',
  styleUrls: ['./data-export-list-page.component.scss']
})
export class DataExportListPageComponent implements OnInit {
  iconDataExport = ICON_DATA_IMPORT;
  tableConfig = new MkTableConfig();
  items = [];
  isLoading: boolean = false;
  pageNumber = 0;
  pageSize = 5;
  totalItemsCount!: number;

  constructor(private tS: TranslateService,
              private router: Router,
              private dataExportService: ImDataExportService) { }

  ngOnInit(): void {
    this.initTable();

    this.actLoad(this.pageNumber, this.pageSize, new HttpParams());
  }

  initTable() {
    this.tableConfig.addColumnStandard(this.tS.instant('cc.data-export.report'), 'title', 250, 'report');
    this.tableConfig.addColumnStandard(this.tS.instant('mem.member.create-date'), 'getExportedDate()', 250, 'createDate');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.consumers.imports.created-by'), 'user', 250, 'createdBy');
    this.tableConfig.addColumnSvgIcon(this.tS.instant('cc.common.status'), 'getStatusCd()');
  }

  actLoad(pageNumber: number, pageSize: number, params: HttpParams) {
    this.isLoading = true;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    const sort = new SortCriteriaItem('id', 'DESC');
    const sortCriteriaList = new SortCriteriaList();
    sortCriteriaList.addSort(sort.propertyName, sort.direction);
    this.dataExportService.get(params, pageNumber, pageSize, sortCriteriaList).subscribe((res)=> {
      if (res) {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => object = ImDataExport.createFromJson(object));
      }
    }, () => {
      this.isLoading = false;
    });
  }

  export() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/data/export/add`]);
  }
}
