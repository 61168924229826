/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McProcessFlowLevel} from '../models/mc-process-flow-level.model';
import {McProcessFlowLevelService} from '../services/mc-process-flow-level.service';
import {IMcProcessFlowLevel} from '../_generated/mc-process-flow-level.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McProcessFlowLevelGENERATED extends BaseObject {


  public apiService: McProcessFlowLevelService;
  public _rawJson: IMcProcessFlowLevel;
    id: number = 0;
  description = '';
  emailActionFlg: boolean;
  emailBodyTokened = '';
  emailPaymentQrCodeFlg: boolean;
  emailSubjectTokened = '';
  idEmailPdfTemplate: number;
  idMcProcessFlow: number;
  idPdfTemplate: number;
  levelFeeAmount: number;
  noDaysPaymentDeadlineAfterReminderIssue: number;
  noDaysWaitingPeriodAfterPaymentDeadline: number;
  postActionFlg: boolean;
  postPaymentQrCodeFlg: boolean;
  smsActionFlg: boolean;
  smsBodyTokened = '';
  title = '';
  mcPdfTemplateTitle: string;  processActiveFlg: boolean;

  public properties: string[] = ['id', 'description', 'emailActionFlg', 'emailBodyTokened', 'emailPaymentQrCodeFlg', 'emailSubjectTokened', 'idEmailPdfTemplate', 'idMcProcessFlow', 'idPdfTemplate', 'levelFeeAmount', 'noDaysPaymentDeadlineAfterReminderIssue', 'noDaysWaitingPeriodAfterPaymentDeadline', 'postActionFlg', 'postPaymentQrCodeFlg', 'smsActionFlg', 'smsBodyTokened', 'title', 'mcPdfTemplateTitle', 'processActiveFlg'];
  public propertiesRegular: string[] = ['id', 'description', 'emailActionFlg', 'emailBodyTokened', 'emailPaymentQrCodeFlg', 'emailSubjectTokened', 'idEmailPdfTemplate', 'idMcProcessFlow', 'idPdfTemplate', 'levelFeeAmount', 'mcProcessFlow', 'noDaysPaymentDeadlineAfterReminderIssue', 'noDaysWaitingPeriodAfterPaymentDeadline', 'postActionFlg', 'postPaymentQrCodeFlg', 'smsActionFlg', 'smsBodyTokened', 'title', 'mcPdfTemplateTitle', 'processActiveFlg'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): McProcessFlowLevel {
    console.error('MTCN-ERROR: Not Implemented: McProcessFlowLevel::createNewInstance(). Add this method to final class and return new McProcessFlowLevel();');
    throw new Error('Cannot McProcessFlowLevel::createNewInstance(). Add this method to final class and return new McProcessFlowLevel();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcProcessFlowLevel): McProcessFlowLevel {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessFlowLevelService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McProcessFlowLevel AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getLevelFeeAmountStr(): string {
       return McGod.formatNumberToMoney(this.levelFeeAmount);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
