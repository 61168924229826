import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ImDataMappingItem, ImDataMappingService, ImDataSet, ImDataSetAttribute, ImDataSetAttributeService} from "@miticon-ui/mc-core";
import {ICON_DATA_IMPORT} from "../../../../../assets/media/svg_icons/sidenav-icons/icon-data-import";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {
  DataImportAttributeAddDialogComponent
} from "./data-import-attribute-add-dialog/data-import-attribute-add-dialog.component";
import {
  DataImportMappingTemplatesDialogComponent
} from "./data-import-mapping-templates-dialog/data-import-mapping-templates-dialog.component";
import {MkTableConfig} from "@miticon-ui/mc-components";
import { ICON_ATTRIBUTE } from '../../../../../assets/media/svg_icons/icon-attribute';
import {ICON_MAPPING_TEMPLATE_BLACK} from "../../../../../assets/media/svg_icons/icon-mapping-template-black";

@Component({
  selector: 'lib-data-import-add-mapping-step',
  templateUrl: './data-import-add-mapping-step.component.html',
  styleUrls: ['./data-import-add-mapping-step.component.scss']
})
export class DataImportAddMappingStepComponent implements OnInit {
  @Input() mappingForm!: FormGroup;
  @Input() fileContent!: string[];
  @Input() dataSet!: ImDataSet;
  @Input() dataSetId!: number;
  @Input() uploadForm!: FormGroup;
  @Output() mappingFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emitMappingForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  iconAttribute = ICON_ATTRIBUTE;
  iconMappingTemplateBlack = ICON_MAPPING_TEMPLATE_BLACK;
  choosenMapping!: any;
  items: any[] = [];
  allItems: any[] = [];
  tableConfig!: MkTableConfig;
  isLoading = false;
  pageSize = 5;
  pageNumber = 0;
  totalItemsCount = 0;
  consumerAttributes:ImDataSetAttribute[] = [];
  reqAttributes!: ImDataSetAttribute[];
  reqEmgAttributes = ImDataSetAttribute.reqEmgAttributes;
  mappedAttributes: any[] = [];
  useTemplate: boolean = false;
  mappingExist: boolean = false;
  checkedSaveMapping: boolean = false;
  checkedUpdateMapping: boolean = false;
  editedMapping: boolean = false;

  constructor(private fb: FormBuilder,
              private dialog: MatDialog,
              private tS: TranslateService,
              private dataMappingService: ImDataMappingService,
              private dataSetAttributeService: ImDataSetAttributeService) {}

  ngOnInit(): void {
    this.uploadForm.controls['file'].valueChanges.subscribe(() => {
      this.setTableContent();
    })
    if(this.dataSetId) {
      this.loadAttributes(this.dataSetId);
      this.loadMappings(this.dataSetId);
    }
    this.createForm();
    this.mappingForm.valueChanges.subscribe(() => {
      this.emitMappingForm.emit(this.mappingForm);
      this.mappingFormValid.emit(this.isMappingFormValid())
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fileContent'] && changes['fileContent'].currentValue) {
      this.setTableContent();
    }
    if (changes['dataSetId'] && changes['dataSetId'].currentValue) {
      this.loadAttributes(this.dataSetId);
      this.choosenMapping = null;
      this.mappingForm?.reset();
      this.mappingForm?.setErrors({ incorrect: true });
      this.mappedAttributes = [];
      this.emitMappingForm.emit(this.mappingForm);
      this.mappingFormValid.emit(this.isMappingFormValid());
    }
  }

  setTableContent(): void {
    let mappingItems = [];

    for (let i = 0; i < this.consumerAttributes.length; i++) {
      const item = {
        attributeName: this.consumerAttributes[i].name + (this.consumerAttributes[i].requiredFlg ? "*" : ""),
        sampleData: this.consumerAttributes[i].sampleData,
      };
      mappingItems.push(item);
      this.allItems = mappingItems.map((item: any) => item = ImDataMappingItem.createFromJson(item));
      this.items = this.allItems;
      this.initTableConfig();
    }
    if(this.choosenMapping) {
      this.mapAttributesToTable(this.choosenMapping);
    }
  }

  createForm(): void {
    this.mappingForm =  this.fb.group({
      choosenMapping: [''],
      mappingName: [''],
      updateMapping: [this.checkedUpdateMapping],
      editedMapping: [],
      mappedAttributes: ['']
    });
  }

  loadAttributes(dataSetId: number): void {
    this.dataSetAttributeService.getAttributesByDataSetId(dataSetId)
      .subscribe((res) => {
        this.consumerAttributes = res
          .sort((a: ImDataSetAttribute, b: ImDataSetAttribute) => a.id - b.id)
          .sort((a: ImDataSetAttribute, b: ImDataSetAttribute) => (a.requiredFlg === b.requiredFlg ? 0 : a.requiredFlg ? -1 : 1));
        this.reqAttributes = this.consumerAttributes.filter((attribute: ImDataSetAttribute) => attribute.requiredFlg);
        this.setTableContent();
      })
  }

  loadMappings(dataSetId: number) {
    this.dataMappingService.getMappingsByDataSetId(dataSetId).subscribe((data) => {
      this.mappingExist = data.length > 0;
    });
  }

  checkSaveMapping($event: any) {
    this.checkedSaveMapping = $event.checked;
    if(this.checkedSaveMapping) {
      const event = {
        checked: false
      }
      this.checkUpdateMapping(event);
    }
    this.mappingFormValid.emit(this.isMappingFormValid());
  }

  checkUpdateMapping($event: any) {
    this.checkedUpdateMapping = $event.checked;
    this.mappingForm.controls['updateMapping'].setValue(this.checkedUpdateMapping);
    if(this.checkedUpdateMapping) {
      const event = {
        checked: false
      }
      this.checkSaveMapping(event);
    }
    this.emitMappingForm.emit(this.mappingForm);
    this.mappingFormValid.emit(this.isMappingFormValid());
  }

  initTableConfig(): void {
    this.tableConfig = new MkTableConfig();
    let mappingColumnNames = [];
    if(this.uploadForm.controls['source'].value === 'csv') {
      mappingColumnNames = this.fileContent[0].split(this.uploadForm.controls['delimiter'].value != 'Other' ? this.uploadForm.controls['delimiter'].value : this.uploadForm.controls['otherDelimiter'].value);
    } else mappingColumnNames = this.fileContent[0].split(",");
    this.tableConfig.addColumnStandard(this.tS.instant("mem.import.attribute"), 'attributeName', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant("mem.import.mapping.sample"), 'sampleData', 250 ,'');
    this.tableConfig.addColumnSelect(this.tS.instant("mem.import.mapping.column-name"), 'id', mappingColumnNames, '');
  }

  onColumnSelection($event: any): void {
    this.mappedAttributes = this.mappedAttributes.filter(mappedAttribute =>
      mappedAttribute.attributeName !== $event.element.attributeName
    )
    $event.element.columnName = $event.$event.value;
    this.mappedAttributes.push($event.element);
    this.mappingForm.controls['mappedAttributes'].setValue(this.mappedAttributes);
    if(this.choosenMapping) {
      this.editedMapping = !this.compareArrays(this.mappedAttributes, this.choosenMapping.mappingItems);
    }
    this.mappingForm.controls['editedMapping'].setValue(this.editedMapping);
    this.mappingFormValid.emit(this.isMappingFormValid());
  }

  compareArrays(mappedAttributes: any[], attributesFromChoosenMapping: any[]): boolean {
    return mappedAttributes.length === attributesFromChoosenMapping.length && mappedAttributes.every(obj1 =>
      attributesFromChoosenMapping.find(obj2 =>
        obj2.attributeName === obj1.attributeName.replace("*","") && obj2.columnName === obj1.columnName
      )
    );
  }

  checkToggle(event: MatSlideToggleChange): void {
    if(this.mappingExist) {
      this.useTemplate = event.checked;
    }
  }

  openAddAttributeDialog(): void {
    this.dialog.open(DataImportAttributeAddDialogComponent, {
      height: '670px',
      width: '860px',
      autoFocus: false,
      restoreFocus: false,
      data: this.dataSetId
    }).afterClosed().subscribe((data) => {
      if(data) {
        this.loadAttributes(this.dataSetId);
      }
    });
  }

  openTemplateMappingsDialog(): void {
    this.dialog.open(DataImportMappingTemplatesDialogComponent, {
      height: '670px',
      width: '1300px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        choosenMapping: this.choosenMapping,
        dataSetId: this.dataSetId
      }
    }).afterClosed().subscribe((data) => {
      if(data) {
        this.choosenMapping = data;
        this.mappedAttributes = [];
        this.mapAttributesToTable(this.choosenMapping);
      }
    });
  }

  mapAttributesToTable(data: any) {
    let mappingColumnNames:any[] = [];
    if(this.uploadForm.controls['source'].value === 'csv') {
      mappingColumnNames = this.fileContent[0].split(
        this.uploadForm.controls['delimiter'].value != 'Other'
          ? this.uploadForm.controls['delimiter'].value
          : this.uploadForm.controls['otherDelimiter'].value
      );
    } else mappingColumnNames = this.fileContent[0].split(",");
    this.mappingForm.controls['choosenMapping'].setValue(data.id);
    this.items = this.allItems;
    this.items.forEach((item: any) => {
      item.columnName = "";
      this.choosenMapping.mappingItems.forEach((mappingItem: any) => {
        if(item.attributeName.replace("*","") === mappingItem.attributeName) {
          if (mappingItem.columnName && mappingColumnNames.indexOf(mappingItem.columnName) !== -1) {
            item.columnName = mappingItem.columnName;
            this.mappedAttributes.push(item);
          }
          this.mappingFormValid.emit(this.isMappingFormValid());
        }
      })
    })
    this.mappingForm.controls['mappedAttributes'].setValue(this.mappedAttributes);
    this.initTableConfig();
  }

  isReqAttributesValid(): boolean {
    return this.reqAttributes?.every((reqAttr: any) =>
      this.mappedAttributes.some((mappedAttr) => mappedAttr.attributeName.replace("*","") === reqAttr.name));
  }

  isMappingFormValid(): boolean {
    return this.isReqAttributesValid() &&
      (this.editedMapping ? (this.checkedSaveMapping || this.checkedUpdateMapping) && (this.checkedSaveMapping ?
          this.mappingForm.controls['mappingName']?.value && this.mappingForm.controls['mappingName']?.value.length > 0 : true)
        || this.checkedUpdateMapping : true) &&
      (this.checkedSaveMapping ? this.mappingForm.controls['mappingName']?.value && this.mappingForm.controls['mappingName']?.value.length > 0 : true);
  }
}
