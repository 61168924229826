/*BbmTestObj*/
import {ZzPocGarageGENERATED} from '../_generated/zz-poc-garage-generated.model';

export class ZzPocGarage extends ZzPocGarageGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new ZzPocGarage();
  }

  // ---------------------------------------------------------------------
}
