import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Table3Config} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-table-3-configuration-modal',
  templateUrl: './mc-table-3-configuration-modal.component.html',
})
export class McTable3ConfigurationModalComponent implements OnInit {

  /*Public variables*/
  tableSetupConfigurationTableContent!: boolean;
  /*Private variables*/
  private settingsConfigurationModalSubscription: any;

  /*Events*/
  @Input() tableConfig!: Table3Config;
  @Output() eventSaved = new EventEmitter();
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  /*Open modal*/
  public openModal(modal: any) {
    this.settingsConfigurationModalSubscription = this.modalService.open(modal, {
      centered: true,
      windowClass: 'mc-table-settings-modal',
      size: 'lg',
      backdrop: 'static'
    });
  }

  /*On check column*/
  public onSelectTableColumnItem(column: { selected: boolean; }) {
    column.selected = !column.selected;
    this.tableSetupConfigurationTableContent = this.tableConfig.columnObjectList.filter(contentItem => !contentItem.selected).length === 0;
  }

  /*On save table configuration*/
  public onSaveTableColumnSetup() {
    this.tableConfig.columnObjectList.map(item => item.isHide = item.selected);
    this.eventSaved.emit();
    this.settingsConfigurationModalSubscription.close();
  }

}
