/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {CsrMapping} from '../models/csr-mapping.model';
import {CsrMappingList} from '../models/csr-mapping-list.model';
import {CsrMappingFilter} from '../models/csr-mapping-filter.model';
import {CsrMappingService} from '../services/csr-mapping.service';
import {ICsrMapping} from '../_generated/csr-mapping.interface';
import {McGod} from "../extra/mc-god.service";

export class CsrMappingListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'creationDate', 'idMcEntity', 'idMcSystemUser', 'idWebFile', 'mappingName', 'sharedFlg', 'sharingDate', 'createdByUserName', 'mappingItems', 'sharedByEntityName', 'sharedByUserName'];
  public items: CsrMapping[] = [];
  public apiService: CsrMappingService;
 // protected tempObjList: CsrMappingList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrMappingList {
    console.error('MTCN-ERROR: Not Implemented: CsrMappingList::createNewInstance(). Add this method to final class and return new CsrMappingList();');
    throw new Error('Cannot CsrMappingList::createNewInstance(). Add this method to final class and return new CsrMappingList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: ICsrMapping[]): CsrMappingList {
    const  listObj: CsrMappingList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: CsrMapping = CsrMapping.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): CsrMappingList {
    const  listObj: CsrMappingList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrMappingService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: ICsrMapping[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: CsrMapping = CsrMapping.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aCsrMappingFilter: CsrMappingFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aCsrMappingFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): CsrMapping {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new CsrMapping();
    }
  }

  public replaceItem(newItem: CsrMapping) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadTemplatesAndSharedByEntityId( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getTemplatesAndSharedByEntityId( this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }



// ---------------------------------------------------------------------
public callCopyMappingWithMappingItems( idMcMapping: number, idMcSystemUser: number, idMcEntity: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.copyMappingWithMappingItems(idMcMapping,idMcSystemUser,idMcEntity).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

}

