/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McBillingService2PriceListFilter} from '../models/mc-billing-service-2-price-list-filter.model';

export class McBillingService2PriceListFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingService2PriceListFilter {
    console.error('MTCN-ERROR: Not Implemented: McBillingService2PriceListFilter::createNewInstance(). Add this method to final class and return new McBillingService2PriceListFilter();');
      throw new Error('Cannot McBillingService2PriceListFilter::createNewInstance(). Add this method to final class and return new McBillingService2PriceListFilter();');
  }

}
