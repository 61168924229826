<div class="row pb-5">
  <div class="col-md-12">
    <!--Page title-->
    <div class="row">
      <div class="col-md-6">
        <h3 class="mc-management-title d-inline text-capitalize">{{"cc.view.banking-module.star-money-files" | translate}}</h3>
      </div>
    </div>

    <!--Notify message-->
    <div class="row">
      <div class="col-md-12 position-relative mt-2" *ngIf="notifyMessage?.type">
        <mc-notify-message [type]="notifyMessage?.type"
                           [message]="notifyMessage?.message"></mc-notify-message>
      </div>
    </div>
  </div>
</div>
<div class="row">

  <!--Filter-->
<!--  <div class="col-md-12">-->
<!--    <mcc-filter [filterConfig]="filterConfig" (onFilterProceed)="onFilterProceed($event)"></mcc-filter>-->
<!--  </div>-->

  <div class="col-md-2 offset-md-10 pt-4">
    <mc-starmoney-import-file (refreshTable)="onImportStarMoneyFile()"></mc-starmoney-import-file>
  </div>

  <!--Table-->
  <div class="col-md-12">
    <mcc-fi-table-2 [objectList]="starMoneyFileList"
                    [table2Config]="starmoneyTableConfig">
    </mcc-fi-table-2>
  </div>

</div>
