import {McDashboardFilterGENERATED} from '../_generated/mc-dashboard-filter-generated.model';

export class McDashboardFilter extends McDashboardFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McDashboardFilter();
  }

  // ---------------------------------------------------------------------
}
