<lib-mc-loader [showLoader]="invoicesList.apiLoadingFlg"></lib-mc-loader>
<mcc-message [errorMsg]="errorMessage"></mcc-message>

<div class="mc-consumer-360-contracts">
  <div class="actions-container">
    <span>{{'cc.consumer-360.invoices' | translate}}</span>
  </div>

  <div *ngIf="invoicesList.apiSuccessFlg">
    <mk-mat-table
      [isLoading]="isLoading"
      [items]="ppInvoiceList.items"
      [config]="tableConfig"
      [showFilters]="false"
      [showTotalItems]="false"
      [totalItemsCount]="totalItemsCount"
      [itemsPerPage]="pageSize"
      [mkMatMenuActionItems]="mkMatMenuActionItems"
      (actionMkMatMenuItems)="onActionMkMatMenuItems($event)"
    >
    </mk-mat-table>
  </div>
</div>
