import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {
  McBoolean, McConsumerAction,
  McHtmlButton,
  McProcessRun,
  McProcessRunItem, McProcessRunItemDeliveredTable3ConfigModel,
  McProcessRunItemList,
  McProcessRunItemTable3Config, Table3Config
} from "@miticon-ui/mc-core";

@Component({
  selector: 'mc-process-process-run-items-profile',
  templateUrl: './mc-process-process-run-items-profile.component.html',
})
export class McProcessProcessRunItemsProfileComponent implements OnInit {

  @Input() actionCdForEvent!: string;
  @Input() mcProcessRun!: McProcessRun;
  @Output() eventCancel = new EventEmitter();
  mcProcessRunItemList = new McProcessRunItemList();
  mcProcessRunItemTable3Config = new Table3Config();
  mcRunItemsStatus = '';
  mcRunItem = new McProcessRunItem();
  showActionDetailsMcb = new McBoolean();
  mcConsumerAction!: McConsumerAction;
  mcActionCd!: string;

  ngOnInit() {
    this.actLoad();
  }

  actLoad() {
    if (this.actionCdForEvent === McProcessRun.ACTION_VIEW_NEW_ITEMS) {
      this.mcProcessRunItemTable3Config = new McProcessRunItemTable3Config();
      this.mcRunItemsStatus = 'New';
      this.mcProcessRunItemList.loadByIdMcProcessRunAndNewItemFlg(this.mcProcessRun.id, true);
    } else if (this.actionCdForEvent === McProcessRun.ACTION_VIEW_READY) {
      this.mcProcessRunItemTable3Config = new McProcessRunItemTable3Config();
      this.mcRunItemsStatus = McProcessRunItem.getStatusCdLabel(McProcessRunItem.STATUS_CD_READY);
      this.mcProcessRunItemList.loadByIdMcProcessRunAndStatusCd(this.mcProcessRun.id, McProcessRunItem.STATUS_CD_READY);
    } else if (this.actionCdForEvent === McProcessRun.ACTION_VIEW_BUILT) {
      this.mcProcessRunItemTable3Config = new McProcessRunItemTable3Config();
      this.mcRunItemsStatus = McProcessRunItem.getStatusCdLabel(McProcessRunItem.STATUS_CD_BUILT);
      this.mcProcessRunItemList.loadByIdMcProcessRunAndStatusCd(this.mcProcessRun.id, McProcessRunItem.STATUS_CD_BUILT);
    } else if (this.actionCdForEvent === McProcessRun.ACTION_VIEW_DELIVERED) {
      this.mcRunItemsStatus = McProcessRunItem.getStatusCdLabel(McProcessRunItem.STATUS_CD_DELIVERED);
      this.mcProcessRunItemTable3Config = new McProcessRunItemDeliveredTable3ConfigModel();
      this.mcProcessRunItemList.loadByIdMcProcessRunAndStatusCd(this.mcProcessRun.id, McProcessRunItem.STATUS_CD_DELIVERED);
    } else if (this.actionCdForEvent === McProcessRun.ACTION_VIEW_SKIPPED) {
      this.mcProcessRunItemTable3Config = new McProcessRunItemTable3Config();
      this.mcRunItemsStatus = McProcessRunItem.getStatusCdLabel(McProcessRunItem.STATUS_CD_SKIPPED);
      this.mcProcessRunItemList.loadByIdMcProcessRunAndStatusCd(this.mcProcessRun.id, McProcessRunItem.STATUS_CD_SKIPPED);
    } else if (this.actionCdForEvent === McProcessRun.ACTION_VIEW_ERROR) {
      this.mcProcessRunItemTable3Config = new McProcessRunItemTable3Config();
      this.mcRunItemsStatus = McProcessRunItem.getStatusCdLabel(McProcessRunItem.STATUS_CD_ERROR);
      this.mcProcessRunItemList.loadByIdMcProcessRunAndStatusCd(this.mcProcessRun.id, McProcessRunItem.STATUS_CD_ERROR);
    }

    console.log('RUN ITEM LIST 4444', this.mcProcessRunItemList);
  }

  onTableItemAction(mcButton: McHtmlButton) {
    this.mcRunItem = mcButton.item;
    this.mcActionCd = mcButton.actionCdForEvent;
    this.showActionDetailsMcb.setTrue();
  }

  actCancelActionDetailsMcb() {
    this.showActionDetailsMcb.setFalse();
    this.mcRunItem = new McProcessRunItem();
  }



  actCancel() {
    this.eventCancel.emit();
  }
}
