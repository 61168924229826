
import {Table3Config} from '../extra/table-3-config';

// import {EbTransactionAdjustmentGENERATED} from '../_generated/eb-transaction-adjustment-generated.model';

export class EbTransactionAdjustmentTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAmount', 'Amount', 'amount', 'amount');
    this.addColumn('colBeneficiaryEntityId', 'Beneficiary entity', 'beneficiaryEntityId', 'beneficiaryEntityId');
    this.addColumn('colCreatedDatetime', 'Created DateTime', 'createdDatetime', 'createdDatetime');
    this.addColumn('colCreatedUserId', 'Created User Id', 'createdUserId', 'createdUserId');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colEbSepaFileTransaction', 'Sepa File Transaction', 'ebSepaFileTransaction', 'ebSepaFileTransaction');
    this.addColumn('colIdEbSepaFileTransaction', 'SEPA file Transaction ID', 'idEbSepaFileTransaction', 'idEbSepaFileTransaction');
    this.addColumn('colIdEbStarmoneyFile2SepaFileTransaction', 'Eb StarMoney 2 Sepa File Transaction ID', 'idEbStarmoneyFile2SepaFileTransaction', 'idEbStarmoneyFile2SepaFileTransaction');
    this.addColumn('colPaymentMethodCd', 'Payment Method code', 'paymentMethodCd', 'paymentMethodCd');
    this.addColumn('colRefId', 'Ref ID', 'refId', 'refId');
    this.addColumn('colRefTypeCd', 'Ref Type CD', 'refTypeCd', 'refTypeCd');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colTypeCd', 'Type code', 'typeCd', 'typeCd');

*/
  }
}
