<div class="col-md-12 mc-stepper-header-title" *ngIf="modalArea === 'createUser'">
  <span class="px-4 py-2 mc-semi-active">
    <span class="mc-notification-badge-gray mc-semi-active-background">1</span>
    {{'cc.users.view.general-data' | translate}}
  </span>
  <span class="px-4 py-2" [ngClass]="roleDataTab ? 'mc-active': ''">
  <span class="mc-notification-badge-gray" [ngClass]="roleDataTab ? 'mc-active-background': ''">2</span>
   {{ 'cc.common.admin.roles' | translate }}
  </span>
</div>
<div class="mc-create-user-notify-wrapper">
  <mc-notify-message *ngIf="saveResponseMessage" [message]="message" [type]="type"></mc-notify-message>
</div>
<div class="mc-user-roles-area">
  <div class="row pt-4">
    <div class="col-md-12">
      <span class="mc-roles-entity">Entity:</span>
      <span class="mc-roles-entity-selected">{{lastChildEntity}}</span>
      <span class="mc-notification-badge-gray" *ngIf="selectedRoles.length > 0">{{selectedRoles.length}}</span>
    </div>
  </div>
  <div class="col-md-12 mt-4 mx-0 p-4 mc-user-roles-content">
    <div class="row p-0 m-0 pb-3">
      <div class="col-md-5 pl-0">
        <div class="form-group mb-0 mc-new-user-search">
          <label class="mb-0 pr-3 mc-user-roles">{{ 'cc.common.admin.roles' | translate }}</label>
          <input (keyup)="onSearchRoles($event)"
                 type="text"
                 class="form-control"
                 id="searchRoles"
                 aria-describedby="searchRoles"
                 placeholder="{{'cc.users.edit.search-roles' | translate}}">
          <i class="fa fa-search mc-new-user-search-icon"></i>
        </div>
      </div>
      <div class="col-md-7 mc-show-selected-only" [formGroup]="showSelectedRolesForm">
        <label class="custom-control fill-checkbox mb-0">
          <input
            formControlName="showSelected"
            id="rolesShowSelectedOnly"
            type="checkbox"
            class="fill-control-input">
          <span class="fill-control-indicator"></span>
          <span class="fill-control-description">{{'cc.users.edit.show-selected-only' | translate}}</span>
        </label>
      </div>
    </div>
    <div class="mc-create-user-notify-wrapper">
      <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
    </div>
    <div class="row p-0">
      <div class="col-md-12 mt-4 mc-user-roles-list">

        <div [formGroup]="stepFormRoles" >
          <ul class="p-3 mc-user-roles-item" formArrayName="selRoles">
            <li class="mc-role-entity"
                id="role_list_{{i}}"
                [ngClass]="control.get('selected').value ? 'mc-role-is-selected' : ''"
                *ngFor="let control of rolesArray.controls; let i = index"
                [formGroupName]="i">
              <label class="custom-control fill-checkbox mb-0" style="width: 90% !important;">
                <input
                  id="role_{{i}}"
                  type="checkbox"
                  class="fill-control-input"
                  formControlName="selected"
                  [checked]="control.get('selected').value"
                  (change)="selectRole(control.get('id').value)">
                <span class="mc-create-new-user-entity-name">{{control.get('entity').value}}</span>
                <span class="fill-control-indicator"></span>
                <span class="fill-control-description">{{control.get('name').value}}</span>
              </label>
              <i class="fas fa-info-circle"></i>
            </li>
          </ul>
        </div>

      </div>

      <div class="col-md-12">
        <div class="row mt-5 mb-3 float-right" *ngIf="modalArea !== 'createUser'">
          <div class="col-md-12">
            <button id="userGeneralDataBtn" class="btn btn-primary mc-next-save-btn" type="button" [disabled]="lockSaveBtn" (click)="saveRoles()">
              {{'cc.common.view.save' | translate}}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
