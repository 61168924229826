/*BbmTestObj*/
import {CsrMappingGENERATED} from '../_generated/csr-mapping-generated.model';
import moment from "moment";

export class CsrMapping extends CsrMappingGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrMapping();
  }

  // ---------------------------------------------------------------------

  getCreationDate() {
    return moment(this.creationDate).format('DD.MM.YYYY hh:mm');
  }

  getSharingDate() {
    return moment(this.sharingDate).format('DD.MM.YYYY hh:mm');
  }
}
