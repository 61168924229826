import {PmAdjustment} from './pm-adjustment.model';
import {PmAdjustmentListGENERATED} from '../_generated/pm-adjustment-list-generated.model';

export class PmAdjustmentList extends PmAdjustmentListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new PmAdjustmentList();
  }

  // ---------------------------------------------------------------------
}
