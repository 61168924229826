import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  McBillingStatementItem,
  McBillingItemList,
  McBillingItemTable3ConfigWithoutFldActionsModel,
  McBillingItem,
  McBillingItemFilter,
  McGod,
  McBillingStatement,
  McBillingServiceCatalog, CurrencyUtil, BaseDirective
} from '@miticon-ui/mc-core';
import {
  McForm,
  McBoolean,
  McValueLabelList
} from '@miticon-ui/mc-core';
import {Subject} from 'rxjs';

@Component({
  selector: 'mc-out-billing-add-statement-items-part',
  templateUrl: './mc-out-billing-add-statement-items-part.component.html',
})
export class McOutBillingAddStatementItemsPartComponent extends BaseDirective implements OnInit {

  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  errorMsgs: string[] = [];
  numberOfItems = 0;
  amount = 0;
  vat = 0;
  totalAmount = 0;
  aggregateYesNoVll = new McValueLabelList();
  mcBillingItemList = new McBillingItemList();
  selectedMcBillingItemList = new McBillingItemList();
  mcBillingItemTable3Config = new McBillingItemTable3ConfigWithoutFldActionsModel();
  loadingFlg = false;
  errorMsg = '';
  statusCdVll!: McValueLabelList;
  statementsCdsVll!: McValueLabelList;
  mcBillingItemFilter = new McBillingItemFilter();
  clearedAllMultipleFilters = new Subject<boolean>();
  statusCds: any[] = [];
  statementCds: any[] = [];
  mcGod = McGod.getInstance();
  typeCdVll!: McValueLabelList;
  typeCds: any[] = [];


  // -- Input --
  @Input() mcBillingStatement!: McBillingStatement;
  @Input() mcBillingStatementItemId!: number;
  @Input() mcBillingStatementItem!: McBillingStatementItem;
  @Input() mcBillingStatementId!: number;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();


  getUniqueValueLabelList(list: McValueLabelList) {
    const uniqueListItems = list.items
      .map(e => e.value)
      .map((e, i, final) => final.indexOf(e) === i && i)
      // @ts-ignore
      .filter(obj => list.items[obj])
      // @ts-ignore
      .map(e => list.items[e]);
    return uniqueListItems;
  }


  // ---------------------------------------------------------------------
  ngOnInit() {
    this.aggregateYesNoVll.add(true, "Yes");
    this.aggregateYesNoVll.add(false, "No");
    this.statusCdVll = new McValueLabelList();
    this.statusCdVll = McBillingItem.getStatusCdVll();
    this.statementsCdsVll = new McValueLabelList();
    this.typeCdVll = McBillingServiceCatalog.getTypeCdsVll();

    this.mcBillingItemFilter.statusCds = [];
    this.mcBillingItemFilter.statementIds = [];
    this.mcBillingItemFilter.amountFrom = null;
    this.mcBillingItemFilter.amountTo = null;
    this.mcBillingItemFilter.dateFrom = null;
    this.mcBillingItemFilter.dateTo = null;
    this.mcBillingItemFilter.statusCds = [McBillingItem.STATUS_CD_NEW];
    this.mcBillingItemFilter.entityIds = [this.mcBillingStatement.childEntityId];

    this.loadingFlg = true;
    this.mcBillingItemList.loadByFilter(this.mcBillingItemFilter, () => {
      this.mcBillingItemList.items.forEach(item => {
          this.statementsCdsVll.add(item.mcBillingStatementId, item.mcBillingStatementTitle);
          this.statementsCdsVll.items = this.getUniqueValueLabelList(this.statementsCdsVll);
      });
      this.loadingFlg = false;
    });

    if (!this.mcBillingStatementItem) {
      this.mcBillingStatementItem = new McBillingStatementItem();
    }


  }


  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcBillingItemFilter.statusCds = this.statusCds ? this.statusCds : [];
    this.mcBillingItemFilter.statementIds = this.statementCds ? this.statementCds : [];
    this.mcBillingItemFilter.amountFrom = null;
    this.mcBillingItemFilter.amountTo = null;
    this.mcBillingItemFilter.dateFrom = null;
    this.mcBillingItemFilter.dateTo = null;
    this.mcBillingItemFilter.mcBillingServiceCatalogIds = this.typeCds;
    this.mcBillingItemFilter.entityIds = [this.mcBillingStatement.childEntityId];

    this.mcBillingItemList.loadByFilter(this.mcBillingItemFilter, () => {
    });
  }

  // ---------------------------------------------------------------------
  actSave() {

    if (this.mcForm.isValid()) {

      if (this.selectedMcBillingItemList.getCount()) {


        if (!this.mcForm.getValue('ctrlAggregate')) { // SINGLE

        } else { // AGGREGATION

        }

      } else {
        this.errorMsg = 'Please select at least one billing item';
        this.saveButtonDisabledMcb.setFalse();
      }

    } else {
      this.saveButtonDisabledMcb.setFalse();
    }

  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.mcBillingStatementItem.exists()) {

      this.mcBillingStatementItem.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

// ---------------------------------------------------------------------
  validatePrerequisites() {
    this.errorMsgs = [];
  }

  // ---------------------------------------------------------------------

  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }

  onTableSelectionChange(selectedList: McBillingItemList) {
    this.amount = 0;
    this.vat = 0;
    this.totalAmount = 0;
    this.selectedMcBillingItemList = selectedList;
    this.numberOfItems = selectedList.getCount();
    this.selectedMcBillingItemList.items.forEach(item => {
      this.amount += item.amount;
      this.vat += item.vatForMcBillingItem;
      this.totalAmount += item.amount + item.vatForMcBillingItem;
    });
  }

  actClearFilter() {
    this.clearedAllMultipleFilters.next(true);
    this.actLoad();
  }

  onSelectedStatusCds(selectedStatusCds: any[]) {
    this.statusCds = selectedStatusCds;
  }

  onSelectStatementCds(value: any[]) {
    if (value !== null) {
      this.statementCds = value;
    }
  }

  onSelectType(value: any[]) {
    this.typeCds = value;
  }

  formatNumberToMoneyWithExactCurrency(amount: number): string {
    return CurrencyUtil.format(amount != null ? amount : 0, this.mcBillingStatement.currencyCode);
  }
}
