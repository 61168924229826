/*BbmTestObj*/
import {PpOrderItemGENERATED} from '../_generated/pp-order-item-generated.model';

export class PpOrderItem extends PpOrderItemGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PpOrderItem();
  }

  // ---------------------------------------------------------------------
}
