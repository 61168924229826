import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EbFactoringService, McBankAccountService, McCurrencyService, McGod} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public selectedLanguage!: string;
  /*Constructor*/
  constructor(
    private translate: TranslateService,
    private mcCurrencyService: McCurrencyService,
    private factoringService: EbFactoringService,
    private mcBankAccountService: McBankAccountService,
    @Inject('designConfig') designConfig: any,
    @Inject('environment') environment: any,
    public mcGod: McGod
  ) {


    /*todo: need to handle not logged user language... now is always EN*/
    translate.setDefaultLang('en');

    /*If title and fav icon exists*/
    if (designConfig.favIcon && designConfig.appTitle) {
      document.getElementById('app-icon')?.setAttribute('href', designConfig.favIcon);
      document.title = environment.production ? designConfig.appTitleProduction : designConfig.appTitle;
    }
  }

  ngOnInit() {
    if (McGod.getLoggedEntityIdFromToken()) {
      this.mcCurrencyService.getAllCurrencies();
      this.factoringService.changeEntity(McGod.getLoggedEntityIdFromToken());
      this.mcBankAccountService.changeEntity(McGod.getLoggedEntityIdFromToken());
    }
  }
}
