<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcStatementItem.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h2>Aggregate</h2>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-body">
    <mcc-message [errorMsg]="mcStatementItem.apiErrorMessage"></mcc-message>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container *ngIf="(mcStatementItem.apiSuccessFlg)">

      <div>
        <mcc-fi-textbox [name]="'ctrlAggregationName'"
                        label="Aggregation name"
                        [validRequiredFlg]="true" [validWhitespaces]="true"
                        [mcForm]="mcForm">
        </mcc-fi-textbox>
      </div>

    </ng-container>

  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="Cancel"></mcc-button>
    <mcc-button  extraCssClasses="btn btn-primary"
                 (eventClicked)="actSave();" label="<i class='fas fa-plus'></i> Add" [disabledMcb]="disableAggregateBtnMcb"
                 disableOnFirstClickFlg="true"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
