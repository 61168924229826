<!--Bank account section-->
<div *ngIf="bankAccounts.apiLoadingFlg">
  <lib-mc-loader [showLoader]="true"></lib-mc-loader>
</div>

<!--If error-->
<div class="row">
  <div class="mc-notify-wrapper mt-n2 col-md-12">
    <mc-notify-message [message]="message" [type]="type"></mc-notify-message>
  </div>
</div>

<!--Bank account creation-->
<div *ngIf="bankAccounts.apiSuccessFlg" class="row mc-bank-account-section">
  <div class="col-md-12">

    <!--Bank account title-->
    <h5 class="mt-4">{{'cc.common.bank-accounts' | translate}}</h5>

    <!--Bank account list-->
    <div class="row my-3 ml-1">
      <div *ngFor="let bankAccount of bankAccounts.items" class="pb-3 pl-4 left-border" [ngClass]="bankAccounts.items.length > 1 ? 'col-md-6' : 'col-md-7'">
        <div class="mc-card-icon">
          <i class="fas fa-book"></i>
        </div>
        <div class="row">
          <!--Bank account fullName-->
          <div class="col-md-8">
            <mc-bank-account-full-name [bankAccount]="bankAccount"></mc-bank-account-full-name>
          </div>

          <div class="col-md-4">
            <!--Edit bank account btn-->
            <mc-bank-account-modal [bankAccountId]="bankAccount.id" [bankAccountDefault]="bankAccount.defaultFlg" [entityId]="entityId" (updateBankAccounts)="refreshBankAccountList($event)" (deleteBankAcc)="onDeleteBankAccount($event)"></mc-bank-account-modal>
          </div>
        </div>
      </div>
    </div>

    <!--Add bank account btn-->
    <mc-bank-account-modal [entityId]="entityId" (updateBankAccounts)="refreshBankAccountList($event)" (deleteBankAcc)="onDeleteBankAccount($event)"></mc-bank-account-modal>
  </div>
</div>
