<h3 class="page-title">{{'cc.ebics.ebics' | translate}}</h3>

<div>
  <div class="actions-container">
    <span>{{"cc.ebics.ebics-list" | translate}}</span>
    <div class="actions-wrapper">
      <button  class="system-action-btn mr-2" (click)="onCreateEbicsAccount()">
        <i class="fas fa-plus-circle mc-add-new-user-icon"></i> {{'cc.ebics.create-ebics-account' | translate}}
      </button>
    </div>
  </div>

  <mcc-modal1-wrapper [showMcb]="showCreateEbicsMcb" *ngIf="showCreateEbicsMcb.value" (eventCanceled)="onCanceledEbicsCreation()">
    <mc-ebics-ebics-add-edit-part [mcBankAccount]="mcBankAccount" (eventCanceled)="onCanceledEbicsCreation()"></mc-ebics-ebics-add-edit-part>
  </mcc-modal1-wrapper>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  (filterChanged)="onFilterChanged($event)"
  (actionMkMatMenuItems)="onTableAction($event)"
  [mkMatMenuActionItems]="tableActionItems"
  [searchTooltip]="searchTooltip"
>
</mk-mat-table>

<router-outlet></router-outlet>

