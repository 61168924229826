/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {CsrPaymentDetails} from '../models/csr-payment-details.model';
import {CsrPaymentDetailsService} from '../services/csr-payment-details.service';
import {ICsrPaymentDetails} from '../_generated/csr-payment-details.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class CsrPaymentDetailsGENERATED extends BaseObject {


    public static readonly REF_TYPE_CD_CSR_BANK_ACCOUNT: string = 'CSR_BANK_ACCOUNT';
    public static readonly REF_TYPE_CD_CC_MOLLIE_CUSTOMER: string = 'CC_MOLLIE_CUSTOMER';
  public static readonly REF_TYPE_CD_LIST__ALL = [
    CsrPaymentDetailsGENERATED.REF_TYPE_CD_CSR_BANK_ACCOUNT,
    CsrPaymentDetailsGENERATED.REF_TYPE_CD_CC_MOLLIE_CUSTOMER
];

    public static readonly TYPE_CD_TRANSFER: string = 'TRANSFER';
    public static readonly TYPE_CD_CASH: string = 'CASH';
    public static readonly TYPE_CD_DIRECT_DEBIT: string = 'DIRECT_DEBIT';
    public static readonly TYPE_CD_CREDIT_CARD: string = 'CREDIT_CARD';
  public static readonly TYPE_CD_LIST__ALL = [
    CsrPaymentDetailsGENERATED.TYPE_CD_TRANSFER,
    CsrPaymentDetailsGENERATED.TYPE_CD_CASH,
    CsrPaymentDetailsGENERATED.TYPE_CD_DIRECT_DEBIT,
    CsrPaymentDetailsGENERATED.TYPE_CD_CREDIT_CARD
];

  public apiService: CsrPaymentDetailsService;
  public _rawJson: ICsrPaymentDetails;
    id: number = 0;
  idRef: number;
  refTypeCd: string;
  typeCd: string;


  public properties: string[] = ['id', 'idRef', 'refTypeCd', 'typeCd'];
  public propertiesRegular: string[] = ['id', 'idRef', 'refTypeCd', 'typeCd'];
  public propertiesSpecial: string[] = [];



  public static getRefTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrPaymentDetailsGENERATED.REF_TYPE_CD_CSR_BANK_ACCOUNT, McGod.t('Consumer bank account'));
    list.add(CsrPaymentDetailsGENERATED.REF_TYPE_CD_CC_MOLLIE_CUSTOMER, McGod.t('Credit card mollie customer'));


    return list;
  }

  public static getRefTypeCdLabel(aCd: string): string {
    const list = CsrPaymentDetailsGENERATED.getRefTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrPaymentDetailsGENERATED.TYPE_CD_TRANSFER, McGod.t('Money transfer'));
    list.add(CsrPaymentDetailsGENERATED.TYPE_CD_CASH, McGod.t('Cash onsite'));
    list.add(CsrPaymentDetailsGENERATED.TYPE_CD_DIRECT_DEBIT, McGod.t('Direct debit'));
    list.add(CsrPaymentDetailsGENERATED.TYPE_CD_CREDIT_CARD, McGod.t('Credit card'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = CsrPaymentDetailsGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): CsrPaymentDetails {
    console.error('MTCN-ERROR: Not Implemented: CsrPaymentDetails::createNewInstance(). Add this method to final class and return new CsrPaymentDetails();');
    throw new Error('Cannot CsrPaymentDetails::createNewInstance(). Add this method to final class and return new CsrPaymentDetails();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: ICsrPaymentDetails): CsrPaymentDetails {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrPaymentDetailsService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CsrPaymentDetails AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getRefTypeCdLabel(): string {
    return CsrPaymentDetailsGENERATED.getRefTypeCdLabel(this.refTypeCd);
  }


 public  isRefTypeCdCSR_BANK_ACCOUNT(): boolean {
    const result = (this.refTypeCd === CsrPaymentDetailsGENERATED.REF_TYPE_CD_CSR_BANK_ACCOUNT);

    return result;
  }

 public  isRefTypeCdCC_MOLLIE_CUSTOMER(): boolean {
    const result = (this.refTypeCd === CsrPaymentDetailsGENERATED.REF_TYPE_CD_CC_MOLLIE_CUSTOMER);

    return result;
  }


  public getTypeCdLabel(): string {
    return CsrPaymentDetailsGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdTRANSFER(): boolean {
    const result = (this.typeCd === CsrPaymentDetailsGENERATED.TYPE_CD_TRANSFER);

    return result;
  }

 public  isTypeCdCASH(): boolean {
    const result = (this.typeCd === CsrPaymentDetailsGENERATED.TYPE_CD_CASH);

    return result;
  }

 public  isTypeCdDIRECT_DEBIT(): boolean {
    const result = (this.typeCd === CsrPaymentDetailsGENERATED.TYPE_CD_DIRECT_DEBIT);

    return result;
  }

 public  isTypeCdCREDIT_CARD(): boolean {
    const result = (this.typeCd === CsrPaymentDetailsGENERATED.TYPE_CD_CREDIT_CARD);

    return result;
  }


  
}
