
import {Table3Config} from '../extra/table-3-config';

// import {EbSepaImportGENERATED} from '../_generated/eb-sepa-import-generated.model';

export class EbSepaImportTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAcceptedAmount', 'Accepted Amount', 'acceptedAmount', 'acceptedAmount');
    this.addColumn('colAcceptedDebtors', 'Accepted Debtors', 'acceptedDebtors', 'acceptedDebtors');
    this.addColumn('colAllDebtors', 'All Debtors', 'allDebtors', 'allDebtors');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdWebfile', 'Webfile ID', 'idWebfile', 'idWebfile');
    this.addColumn('colMcEntity', 'Child Entity', 'mcEntity', 'mcEntity');
    this.addColumn('colOverwrittenAmount', 'Overwritten Amount', 'overwrittenAmount', 'overwrittenAmount');
    this.addColumn('colOverwrittenCnt', 'Overwritten Cnt', 'overwrittenCnt', 'overwrittenCnt');
    this.addColumn('colProcessedEarlierCnt', 'Processed Earlier Cnt', 'processedEarlierCnt', 'processedEarlierCnt');
    this.addColumn('colProcessedNowCnt', 'Processed Now Cnt', 'processedNowCnt', 'processedNowCnt');
    this.addColumn('colRejectedAmount', 'Rejected Amount', 'rejectedAmount', 'rejectedAmount');
    this.addColumn('colRejectedDebtors', 'Rejected Debtors', 'rejectedDebtors', 'rejectedDebtors');
    this.addColumn('colRemainingAmount', 'Remaining Amount', 'remainingAmount', 'remainingAmount');
    this.addColumn('colRequestedCollectionDate', 'Manually requested collection date', 'requestedCollectionDate', 'requestedCollectionDate');
    this.addColumn('colServicePeriod', 'Service period', 'servicePeriod', 'servicePeriod');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colTotalAmountDue', 'Total Amount Due', 'totalAmountDue', 'totalAmountDue');

*/
  }
}
