export const environment = {
  production: true,
  apiUrl: 'api',
  httpOptions: (httpHeaders: new (arg0: { Authorization: string; 'Content-Type': string; }) => any, token: string) => {
    return {
      headers: new httpHeaders({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      })
    };
  }
};
