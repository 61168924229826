<span class="back-option" (click)="goBack()">
  <span [innerHTML]="iconBack | safeHtml"></span>
  {{ "mem.data-import.back" | translate }}
</span>
<mat-stepper [linear]="false">
  <mat-step [stepControl]="uploadForm" [completed]="false">
    <ng-template matStepLabel>
      {{ "cc.common.upload-file"| translate }}
    </ng-template>
    <lib-data-import-add-upload-step
      [uploadForm]="uploadForm"
      (uploadFormValid)="uploadFormValid = $event"
      (emitValidDelimiter)="validDelimiter = $event"
      (emitFileContent)="setFileContent($event)"
      (emitFile)="setFile($event)"
      (emitDataset)="setDataSet($event)"
      (emitUploadForm)="setUploadForm($event)"
    ></lib-data-import-add-upload-step>
    <div class="card card-flex-end">
      <button class="primary-btn ml-small"
              [disabled]="!uploadFormValid ||
              !fileContent ||
              fileContent.length <= 1 ||
              isInvalidDelimiter()"
              *ngIf="selectedStep === 0"
              (click)="goToNextStep()"
              matStepperNext>
        {{ "cc.common.view.next" | translate }}
      </button>
    </div>
  </mat-step>
  <mat-step [stepControl]="mappingForm" [completed]="false">
    <ng-template matStepLabel>
      {{ "cc.consumers.mapping" | translate }}
    </ng-template>
    <lib-data-import-add-mapping-step
      *ngIf="fileContent"
      [mappingForm]="mappingForm"
      [uploadForm]="uploadForm"
      [dataSet]="dataSet"
      [dataSetId]="dataSetId"
      [fileContent]="fileContent"
      (mappingFormValid)="mappingFormValid = $event"
      (emitMappingForm)="setMappingForm($event)"
    ></lib-data-import-add-mapping-step>
    <div class="card card-flex-end">
      <button class="primary-btn"
              *ngIf='selectedStep === 1'
              (click)="goToPrevStep()"
              matStepperPrevious>
        {{ "cc.common.view.prev" | translate }}
      </button>
      <button class="primary-btn ml-small"
              [disabled]="!mappingFormValid"
              *ngIf="selectedStep === 1"
              (click)="goToNextStep()"
              matStepperNext>
        {{ "cc.common.view.next" | translate }}
      </button>
    </div>
  </mat-step>
  <mat-step [stepControl]="importForm" [completed]="false">
    <ng-template matStepLabel>
      {{ "mem.data-import.import-file" | translate }}
    </ng-template>
    <lib-data-import-add-import-step
      [importForm]="importForm"
      [dataSet]="dataSet"
      (emitImportForm)="setImportForm($event)"
    ></lib-data-import-add-import-step>
    <div class="card card-flex-end">
      <button class="primary-btn"
              *ngIf='selectedStep === 2'
              (click)="goToPrevStep()"
              matStepperPrevious>
        {{ "cc.common.view.prev" | translate }}
      </button>
      <button class="primary-btn ml-small"
              [disabled]="disableImport()"
              (click)="import()"
              *ngIf="selectedStep === 2">
        {{ "cc.common.view.import" | translate }}
      </button>
    </div>
  </mat-step>
</mat-stepper>
