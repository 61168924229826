/*BbmTestObj*/
import {EbInterest_rateGENERATED} from '../_generated/eb-interest_rate-generated.model';

export class EbInterest_rate extends EbInterest_rateGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbInterest_rate();
  }

  // ---------------------------------------------------------------------
}
