<div id="mcc-fi-{{name}}"
     class="mcc-fi mcc-fi-radio-buttons mcc-fi-radio-buttons-{{name}} {{extraCssClass}} {{labelPositionClass}}">
  <form [formGroup]="getRadioForm()" [ngClass]="{'validation-error' : this.getRadioForm().get('errorFlg')?.value}">
    <label class="label" *ngIf="label" [ngClass]="{'mc-mandatory-star' : validRequiredFlg}">{{label}}</label>
      <ul [ngClass]="directionHorizontal ? 'direction-row' : 'direction-column'">
        <li *ngFor="let option of optionsVlm | keyvalue">
          <input
            type="radio"
            id="fi-{{name}}-{{option.key}}"
            value="{{option.key}}"
            formControlName="value">
          <label for="fi-{{name}}-{{option.key}}"
                 [ngClass]="{'radio-button-error' : this.getRadioForm().get('errorFlg')?.value}">
            {{option.value}}
          </label>
        </li>
      </ul>
  </form>
  <div class="error-msg">{{this.getRadioForm().get('errorMsg')?.value}}</div>
</div>






