/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CsrReccuringOptionsFilter} from '../models/csr-reccuring-options-filter.model';

export class CsrReccuringOptionsFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrReccuringOptionsFilter {
    console.error('MTCN-ERROR: Not Implemented: CsrReccuringOptionsFilter::createNewInstance(). Add this method to final class and return new CsrReccuringOptionsFilter();');
      throw new Error('Cannot CsrReccuringOptionsFilter::createNewInstance(). Add this method to final class and return new CsrReccuringOptionsFilter();');
  }

}
