/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McCurrencyTable3ConfigGENERATED} from '../_generated/mc-currency-table-3-config-generated.model';

// import {McCurrencyGENERATED} from '../_generated/mc-currency-generated.model';

export class McCurrencyTable3Config extends McCurrencyTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colSymbol', 'symbol', 'symbol', 'symbol');
    this.addColumn('colCode', 'code', 'code', 'code');


  }
}
