<div class="content-wrapper">
  <div class="content-header">
    <h2>Create EBICS account</h2>
  </div>
  <div class="content-body">
    <lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
    <ng-container *ngIf="errorMsg">
      <mcc-message [errorMsg]="errorMsg"></mcc-message>
    </ng-container>
    <div class="row">
      <div class="col-md-6">
        <div class="section-sub-header">
          <h6>Bank account</h6>
        </div>
        <mcc-fi-select [ngClass]="userSaved ? 'mc-disable' : ''" [mcForm]="mcForm" name="selBankAccount" [label]="'cc.common.edit.bank-account' | translate"
                       placeholderOption="{{'cc.common.edit.please-choose' | translate}}" [validRequiredFlg]="true"
                       [optionsVll]="bankAccountVll"></mcc-fi-select>
        <div class="section-sub-header">
          <h6>{{'cc.ebics.connection-settings' | translate}}</h6>
        </div>
        <mcc-fi-textbox [name]="'ctrlUserId'"
                        [label]="'cc.ebics.user-id' | translate"
                        [validRequiredFlg]="true"
                        [infoMsg]="'cc.ebics.the-bank-will-provide' | translate"
                        [value]="''"
                        [mcForm]="mcForm">
        </mcc-fi-textbox>
        <mcc-fi-textbox [name]="'ctrPartnerId'"
                        [label]="'cc.ebics.partner-id' | translate"
                        [validRequiredFlg]="true"
                        [infoMsg]="'cc.ebics.the-bank-will-provide' | translate"
                        [value]="''"
                        [mcForm]="mcForm">
        </mcc-fi-textbox>
        <mcc-fi-textbox [name]="'ctrlHostId'"
                        [label]="'cc.ebics.host-id' | translate"
                        [validRequiredFlg]="true"
                        [infoMsg]="'cc.ebics.the-bank-will-provide' | translate"
                        [value]="''"
                        [mcForm]="mcForm">
        </mcc-fi-textbox>
        <mcc-fi-textbox [name]="'ctrlBankUrl'"
                        [label]="'cc.ebics.bank-url' | translate"
                        [validRequiredFlg]="true"
                        [infoMsg]="'cc.ebics.the-bank-will-provide' | translate"
                        [value]="''"
                        [mcForm]="mcForm">
        </mcc-fi-textbox>
        <mcc-fi-textbox [name]="'ctrlBankName'"
                        [label]="'cc.ebics.bank-name' | translate"
                        [validRequiredFlg]="true"
                        [infoMsg]="'cc.ebics.the-bank-will-provide' | translate"
                        [value]="''"
                        [mcForm]="mcForm">
        </mcc-fi-textbox>
        <mcc-fi-textbox [name]="'ctrlUserName'"
                        [label]="'cc.ebics.user-name' | translate"
                        [validRequiredFlg]="true"
                        [infoMsg]="'cc.ebics.the-bank-will-provide' | translate"
                        [value]="''"
                        [mcForm]="mcForm">
        </mcc-fi-textbox>
        <mcc-fi-textbox [name]="'ctrlUserEmail'"
                        [label]="'cc.ebics.user-email' | translate"
                        [validRequiredFlg]="true"
                        [infoMsg]="'cc.ebics.the-bank-will-provide' | translate"
                        [value]="''"
                        [mcForm]="mcForm">
        </mcc-fi-textbox>
        <mcc-fi-textbox [name]="'ctrlUserOrg'"
                        [label]="'User org'"
                        [validRequiredFlg]="true"
                        [infoMsg]="'cc.ebics.the-bank-will-provide' | translate"
                        [value]="''"
                        [mcForm]="mcForm">
        </mcc-fi-textbox>
        <mcc-fi-textbox [name]="'ctrlInstituteId'"
                        [label]="'cc.ebics.institute-id' | translate"
                        [validRequiredFlg]="true"
                        [infoMsg]="'cc.ebics.the-bank-will-provide' | translate"
                        [value]="''"
                        [mcForm]="mcForm">
        </mcc-fi-textbox>
        <mcc-fi-textbox [name]="'ctrlProductName'"
                        [label]="'Product name'"
                        [validRequiredFlg]="true"
                        [infoMsg]="'cc.ebics.the-bank-will-provide' | translate"
                        [value]="''"
                        [mcForm]="mcForm">
        </mcc-fi-textbox>
        <div class="mc-float-right ebics-save-btn">
          <button class="btn btn-primary" (click)="saveBankAccAndProperties()" [disabled]="mcForm.formGroup.invalid">{{'cc.common.view.save' | translate}}</button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="section-sub-header">
          <h6>{{'cc.ebics.generate-keys' | translate}}</h6>
        </div>
        <ng-container *ngIf="!userInitiated">
          <div class="ebics-download mc-disable">
            <div>Empty file</div>
            <i class="fas fa-file-download"></i>
          </div>
          <div class="ebics-download mc-disable">
            <div>Empty file</div>
            <i class="fas fa-file-download"></i>
          </div>
          <div class="ebics-download mc-disable">
            <div>Empty file</div>
            <i class="fas fa-file-download"></i>
          </div>
        </ng-container>
        <ng-container *ngIf="userInitiated">
          <div *ngFor="let key of keys">
            <a class="ebics-download" (click)="getLetterPath(key)">
              <div>{{key}}</div>
              <i class="fas fa-file-download" ></i>
            </a>
          </div>
        </ng-container>
        <div class="mc-float-right">
          <ng-container *ngIf="!userInitiated && userSaved">
            <button class="btn btn-primary width-100" (click)="generateKeys()">{{'cc.ebics.generate-keys' | translate}}</button>
          </ng-container>
          <ng-container *ngIf="sendIniRequest">
            <button class="btn btn-secondary width-100" (click)="sendRequest('INI')">{{'cc.ebics.send-ini-request' | translate}}</button>
          </ng-container>
          <ng-container *ngIf="iniReqSent">
            <button class="btn btn-secondary width-100" (click)="sendRequest('HIA')">{{'cc.ebics.send-hia-request' | translate}}</button>
          </ng-container>
          <ng-container *ngIf="hiaReqSent">
            <button class="btn btn-secondary width-100" (click)="sendRequest('HBP')">{{'cc.ebics.send-hpb-request' | translate}}</button>
          </ng-container>
          <!--

          -->

        </div>
      </div>
    </div>
  </div>
  <div class="content-footer">
    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                [label]="'cc.common.close' | translate"></mcc-button>
  </div>
</div>

