/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {ImDataExport} from '../models/im-data-export.model';
import {ImDataExportService} from '../services/im-data-export.service';
import {IImDataExport} from '../_generated/im-data-export.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class ImDataExportGENERATED extends BaseObject {


    public static readonly FILE_TYPE_XLS: string = 'xls';
    public static readonly FILE_TYPE_CSV: string = 'csv';
    public static readonly FILE_TYPE_JSON: string = 'json';
  public static readonly FILE_TYPE_LIST__ALL = [
    ImDataExportGENERATED.FILE_TYPE_XLS,
    ImDataExportGENERATED.FILE_TYPE_CSV,
    ImDataExportGENERATED.FILE_TYPE_JSON
];

    public static readonly STATUS_CD_IN_PROGRESS: string = 'IN_PROGRESS';
    public static readonly STATUS_CD_EXPORTED: string = 'EXPORTED';
    public static readonly STATUS_CD_PARTIALLY_EXPORTED: string = 'PARTIALLY_EXPORTED';
    public static readonly STATUS_CD_FAILED: string = 'FAILED';
  public static readonly STATUS_CD_LIST__ALL = [
    ImDataExportGENERATED.STATUS_CD_IN_PROGRESS,
    ImDataExportGENERATED.STATUS_CD_EXPORTED,
    ImDataExportGENERATED.STATUS_CD_PARTIALLY_EXPORTED,
    ImDataExportGENERATED.STATUS_CD_FAILED
];

  public apiService: ImDataExportService;
  public _rawJson: IImDataExport;
    id: number = 0;
  dateFrom: number;
  dateTo: number;
  fileType: string;
  idDataExportTemplate: number;
  idMcEntity: number;
  statusCd: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  title = '';


  public properties: string[] = ['id', 'dateFrom', 'dateTo', 'fileType', 'idDataExportTemplate', 'idMcEntity', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'title'];
  public propertiesRegular: string[] = ['id', 'dateFrom', 'dateTo', 'fileType', 'idDataExportTemplate', 'idMcEntity', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'title'];
  public propertiesSpecial: string[] = [];



  public static getFileTypeVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(ImDataExportGENERATED.FILE_TYPE_XLS, McGod.t('xls'));
    list.add(ImDataExportGENERATED.FILE_TYPE_CSV, McGod.t('csv'));
    list.add(ImDataExportGENERATED.FILE_TYPE_JSON, McGod.t('json'));


    return list;
  }

  public static getFileTypeLabel(aCd: string): string {
    const list = ImDataExportGENERATED.getFileTypeVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(ImDataExportGENERATED.STATUS_CD_IN_PROGRESS, McGod.t('IN_PROGRESS'));
    list.add(ImDataExportGENERATED.STATUS_CD_EXPORTED, McGod.t('EXPORTED'));
    list.add(ImDataExportGENERATED.STATUS_CD_PARTIALLY_EXPORTED, McGod.t('PARTIALLY_EXPORTED'));
    list.add(ImDataExportGENERATED.STATUS_CD_FAILED, McGod.t('FAILED'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = ImDataExportGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): ImDataExport {
    console.error('MTCN-ERROR: Not Implemented: ImDataExport::createNewInstance(). Add this method to final class and return new ImDataExport();');
    throw new Error('Cannot ImDataExport::createNewInstance(). Add this method to final class and return new ImDataExport();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IImDataExport): ImDataExport {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(ImDataExportService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE ImDataExport AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getFileTypeLabel(): string {
    return ImDataExportGENERATED.getFileTypeLabel(this.fileType);
  }


 public  isFileTypeXls(): boolean {
    const result = (this.fileType === ImDataExportGENERATED.FILE_TYPE_XLS);

    return result;
  }

 public  isFileTypeCsv(): boolean {
    const result = (this.fileType === ImDataExportGENERATED.FILE_TYPE_CSV);

    return result;
  }

 public  isFileTypeJson(): boolean {
    const result = (this.fileType === ImDataExportGENERATED.FILE_TYPE_JSON);

    return result;
  }


  public getStatusCdLabel(): string {
    return ImDataExportGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdIN_PROGRESS(): boolean {
    const result = (this.statusCd === ImDataExportGENERATED.STATUS_CD_IN_PROGRESS);

    return result;
  }

 public  isStatusCdEXPORTED(): boolean {
    const result = (this.statusCd === ImDataExportGENERATED.STATUS_CD_EXPORTED);

    return result;
  }

 public  isStatusCdPARTIALLY_EXPORTED(): boolean {
    const result = (this.statusCd === ImDataExportGENERATED.STATUS_CD_PARTIALLY_EXPORTED);

    return result;
  }

 public  isStatusCdFAILED(): boolean {
    const result = (this.statusCd === ImDataExportGENERATED.STATUS_CD_FAILED);

    return result;
  }


  
}
