/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {ImDataImportItem} from '../models/im-data-import-item.model';
import {ImDataImportItemList} from '../models/im-data-import-item-list.model';
import {ImDataImportItemFilter} from '../models/im-data-import-item-filter.model';
import {ImDataImportItemService} from '../services/im-data-import-item.service';
import {IImDataImportItem} from '../_generated/im-data-import-item.interface';
import {McGod} from "../extra/mc-god.service";

export class ImDataImportItemListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'idDataImport', 'index', 'message', 'statusCd', 'sysCreatedDatetime'];
  public items: ImDataImportItem[] = [];
  public apiService: ImDataImportItemService;
 // protected tempObjList: ImDataImportItemList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): ImDataImportItemList {
    console.error('MTCN-ERROR: Not Implemented: ImDataImportItemList::createNewInstance(). Add this method to final class and return new ImDataImportItemList();');
    throw new Error('Cannot ImDataImportItemList::createNewInstance(). Add this method to final class and return new ImDataImportItemList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IImDataImportItem[]): ImDataImportItemList {
    const  listObj: ImDataImportItemList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: ImDataImportItem = ImDataImportItem.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): ImDataImportItemList {
    const  listObj: ImDataImportItemList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(ImDataImportItemService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IImDataImportItem[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: ImDataImportItem = ImDataImportItem.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aImDataImportItemFilter: ImDataImportItemFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aImDataImportItemFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): ImDataImportItem {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new ImDataImportItem();
    }
  }

  public replaceItem(newItem: ImDataImportItem) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

