import {Component, Inject, Input, OnInit} from '@angular/core';
import {
  McBillingService2Vat,
  McBillingServiceCatalogList,
  McForm,
  McValueLabelList,
  McVat,
  McValueLabel, McBillingService2VatService, SortCriteriaList, McBillingService2VatList
} from '@miticon-ui/mc-core';
import {Subject} from 'rxjs';

@Component({
  selector: 'mc-country-vat-add-edit-part',
  templateUrl: './mc-country-vat-add-edit-part.component.html',
})
export class McCountryVatAddEditPartComponent implements OnInit {

  vatTypeVll = new McValueLabelList();
  descriptionRequiredFlg = false;
  multiselectRequiredSubject = new Subject<boolean>();
  servicesMultiselectValues = new McValueLabelList();
  endDateMinDate: any;
  private mcBillingService2VATItemList!: McBillingService2Vat[];

  @Input() servicesVll = new McValueLabelList();
  @Input() serviceList = new McBillingServiceCatalogList();
  @Input() mcVatItem!: McVat;
  @Input() index!: number;
  @Input() mcForm = new McForm();

  constructor(private billingService2VatService: McBillingService2VatService) {
  }

  ngOnInit() {
    this.vatTypeVll = McVat.getVatTypeCdVll();
    this.mcForm.getValidationTriggerObservable().subscribe(next => {
      this.multiselectRequiredSubject.next(true);
    });
    this.billingService2VatService.getByVATId(this.mcVatItem.id, 0, 100, new SortCriteriaList())
      .subscribe(page => {
        this.mcBillingService2VATItemList = page.content;
        if (this.mcBillingService2VATItemList && this.mcBillingService2VATItemList.length > 0) {
          this.mcBillingService2VATItemList.forEach(item => {
            const service = new McValueLabel();
            service.value = item.idBillingService;
            service.label = item.title;
            this.servicesMultiselectValues.addItem(service);
          });
        }
      });

  }

  inputValuesChangedHandler() {
    this.mcVatItem.vatRate = this.mcForm.getValue('ctrlValueVatRate' + this.index);
    this.mcVatItem.vatTypeCd = this.mcForm.getValue('ctrlVatType' + this.index);
    this.mcVatItem.vatDescription = this.mcForm.getValue('ctrlDescription' + this.index);
    this.mcVatItem.startDate = this.mcForm.getValue('ctrlStartDate' + this.index);
    this.mcVatItem.endDate = this.mcForm.getValue('ctrlEndDate' + this.index);

    this.descriptionRequiredFlg = this.mcVatItem.vatTypeCd === McVat.VAT_TYPE_CD_OTHER;

    this.endDateMinDate = this.mcForm.getValue('ctrlStartDate' + this.index);

  }

  onServiceSelect(selectedServiceIds: any[]) {
    this.mcVatItem.mcBillingService2VATItemList = new McBillingService2VatList();
    selectedServiceIds.forEach(item => {
      const mcBillingService2VATItem = new McBillingService2Vat();
      mcBillingService2VATItem.idBillingService = item;
      this.mcVatItem.mcBillingService2VATItemList.addItem(mcBillingService2VATItem);
    });
  }

}
