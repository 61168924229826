import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  McManagementService,
  McUtilityService,
  McEntityService,
  McBoolean,
  McRoleService,
  McGod,
  User,
  CurrencyUtil,
  EbFactoringService,
} from '@miticon-ui/mc-core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { McTableService } from '@miticon-ui/mc-components';

@Component({
  selector: 'lib-mc-management',
  templateUrl: './mc-management.component.html',
})
export class McManagementComponent implements OnInit {
  /*Public variables*/
  selectedEntityPath!: any;
  public managementModule!: string | null;
  public managementModuleDisplayName!: string;
  public tableContent!: any;
  public managementAreaStyle!: boolean;
  public isTableContentLoaded = false;
  public errorObject = {
    type: '',
    message: '',
  };
  public currentSelectedRows: any = [];
  public userPermission;
  public entityId: number;
  public showLoader!: boolean;
  public filterObject = {
    fetchWithRoles: true,
  };
  public pdf!: any;
  public filename!: any;
  public mcGod = McGod.getInstance();

  /*Private variables*/
  private pagination = {};
  private paramsSubscription: Subscription;
  private resendUserMailSubscription: Subscription;
  private apiSubscription!: Subscription;
  private apiGetByEntityIdSubscription!: Subscription;
  private roleIdFromUrl!: any;
  private entityIdFromUrl!: any;

  /*Events*/
  @ViewChild('myIdentifier') tableWidth!: any;
  public showCreateRoleFromTemplateMcb = new McBoolean();
  public isRootParent!: boolean;
  public isRoleTemplates!: boolean;
  public roleCaption!: string;
  public roleTemplates!: any;
  public successMessage!: string;
  showResendUserMailFlg = false;
  selectedUser!: any;
  resendUserMailMcb = new McBoolean();

  @HostListener('window:resize')
  onResize() {
    this.setPopupWidth();
  }

  /*Constructor*/
  constructor(
    @Inject('allPermissions') allPermissions: any,
    @Inject('sideBarMenu') sideBarConfig: any,
    private route: ActivatedRoute,
    private router: Router,
    private utilityService: McUtilityService,
    private translate: TranslateService,
    private managementService: McManagementService,
    private domSanitizer: DomSanitizer,
    private entityService: McEntityService,
    private roleService: McRoleService,
    private mcTableService: McTableService,
    private factoringService: EbFactoringService
  ) {
    /*Get entity path*/
    this.entityService._selectedEntityPath.subscribe((path) => {
      this.selectedEntityPath = path;
    });

    /*Params subscription*/
    this.paramsSubscription = this.route.paramMap.subscribe((data) => {
      if (data.get('roleId')) {
        this.roleIdFromUrl = { roleId: data.get('roleId') };
      } else {
        this.roleIdFromUrl = null;
      }
      this.currentSelectedRows = [];
      if (data.get('module') !== this.managementModule) {
        this.errorObject = Object.assign({}, { type: '', message: '' });
        this.pagination = {};
        this.entityIdFromUrl = null;
        this.tableContent = null;
      }
      if (data.get('module') !== 'role') {
        this.successMessage = '';
      }
      this.filterObject = Object.assign(
        {},
        {
          fetchWithRoles: true,
        }
      );
      data.keys.forEach((item) => {
        if (item !== 'module') {
          // @ts-ignore
          this.filterObject[item] = data.get(item);
          // Object.keys(this.pagination).length > 0 && (this.pagination.page = 0);
        }
      });

      this.managementModule = data.get('module');
      this.entityIdFromUrl = data.get('entityId');
      // const moduleName = sideBarConfig.find(item => item.name === this.managementModule);
      // this.managementModuleDisplayName = moduleName && moduleName.sidebarLinkName;
      // if (!moduleName) {
      //   const subMenu = sideBarConfig.filter(item => item.children);
      //   if (subMenu && subMenu.length > 0) {
      // eslint-disable-next-line
      //     for (let i = 0; i < subMenu.length; i += 1) {
      //       const subMenuItemList = subMenu[i].children;
      //       if (subMenuItemList && subMenuItemList.length > 0) {
      // eslint-disable-next-line
      //         this.managementModuleDisplayName = subMenuItemList.find(item => item.name === this.managementModule).sidebarLinkName;
      //       }
      //     }
      //   }
      // }

      if (this.managementModule) {
        this.managementModuleDisplayName = this.managementModule;
      }

      this.getTableContent();
    });

    this.entityId = this.utilityService.getPropertyFromToken('entity_id');

    /*Permissions*/
    this.userPermission = [
      allPermissions.MC_SYSTEM_USER_CAN_CREATE,
      allPermissions.MC_ENTITY_CAN_CREATE,
      allPermissions.MC_LOGIN_CAN_CREATE,
      allPermissions.CSR_CONSUMER_CAN_CREATE,
      allPermissions.PP_PRODUCT_CAN_CREATE,
    ];

    /* USER TABLE RESEND EMAIL SUBSCRIPTION */
      this.mcTableService.resendUserMailSubject.subscribe((user) => {
        this.selectedUser = user;
        this.showResendUserMailFlg = true;
        this.resendUserMailMcb.setTrue();
      });
  }

  ngOnInit() {
    this.setAreaTitle();
    const entityId = this.utilityService.getPropertyFromToken('entity_id');

    this.roleService
      .getAllTemplateRolesFromAncestors(entityId)
      .subscribe((res) => {
        this.roleTemplates = res;
        if (
          this.roleTemplates.length > 0 &&
          this.selectedEntityPath.length > 1
        ) {
          this.isRootParent = false;
        } else {
          this.isRootParent = true;
        }
      });
  }

  setAreaTitle() {
    // todo: change to switch/case
    if (this.managementModule === 'user') {
      this.managementAreaStyle = true;
    }
  }

  /*Get table content*/
  public getTableContent(): void {
    let message = '';
    this.currentSelectedRows = [];
    this.showLoader = true;
    const moduleName =
      this.managementModule === 'role' ? 'entity/role' : this.managementModule;

    /*If entityIdFomUrl exists call getByEntityId endpoint
     * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * Current solution until we finish multi tab feature
     * * * * * * * * * * * * * * * * * * * * * * * * * * */
    if (this.entityIdFromUrl) {
      this.managementService
        .getUsersOrRolesByEntityId(this.managementModule, this.entityIdFromUrl)
        .subscribe(
          (response) => {
            this.setTableContent(response);
          },
          (error) => {
            if (error.status >= 500) {
              this.translate
                .get('cc.common.server-not-available-try-again')
                .subscribe((translateResponse: string) => {
                  message = translateResponse;
                });
              this.errorObject = Object.assign(
                {},
                {
                  type: 'error',
                  message,
                }
              );
            } else {
              this.errorObject = Object.assign(
                {},
                {
                  type: 'error',
                  message: error.error.error_description || error.error.message,
                }
              );
            }
            this.setTableContent(error);
          }
        )
        .add(() => (this.showLoader = false));
    } else {
      var filterPath = this.managementService
        .getAndFilterSystemManagementModule(moduleName, this.filterObject, this.pagination);
      /*Call search endpoint*/
      if(filterPath){
        filterPath.subscribe(
            (response) => {
              this.setTableContent(response);
            },
            (error) => {
              if (error.status >= 500) {
                this.translate
                  .get('cc.common.server-not-available-try-again')
                  .subscribe((translateResponse: string) => {
                    message = translateResponse;
                  });
                this.errorObject = Object.assign(
                  {},
                  {
                    type: 'error',
                    message,
                  }
                );
              } else {
                this.errorObject = Object.assign(
                  {},
                  {
                    type: 'error',
                    message: error.error.error_description || error.error.message,
                  }
                );
              }
              this.setTableContent(error);
            }
          )
            .add(() => (this.showLoader = false));
        }
    }
  }

  /*On pagination*/
  public onPagination(pagination: any) {
    if (pagination && Object.keys(pagination).length > 0) {
      this.pagination = Object.assign({}, pagination);
      this.getTableContent();
    }
  }

  /*Set table content*/
  public setTableContent(data: any) {
    this.isTableContentLoaded = true;
    if (data.hasOwnProperty('status') && data.status >= 400) {
      this.tableContent = { _name: this.managementModule };
      this.tableContent.isLoaded = true;
      return;
    }

    // Set recipient filed in invoices table
    /*if (this.managementModule === 'invoices') {
      data.content = data.content.map((item: any) => {
        item.totalBalance = CurrencyUtil.format(
          item.totalBalance,
          this.factoringService.activeChildFactoring.mcCurrency.code
        );
        return item;
      });
    }*/

    this.tableContent = data;
    this.tableContent.content.forEach((item: any) => {
      if (
        item.templateFlg &&
        this.mcGod.userHasMcRoleTemplateCreateEditPermission()
      ) {
        this.roleCaption = item.caption;
        this.roleCaption =
          'Template - ' + item.caption + ` (${item.numberOfChildren})`;
        item.caption = this.roleCaption;
      }
    });
    this.tableContent.isFiltered = Object.keys(this.filterObject).length > 1;
    this.tableContent._name = this.managementModule;
    this.tableContent.isLoaded = true;
    this.tableContent.totalElements = data.totalElements;

    this.tableContent.content.filter((item: any) => {
      if (item.customAttributes) {
        item.customAttributes.forEach((attr: any) => {
          item[attr.name] = attr.value;
        });
      }
      if (
        !this.mcGod.userHasMcRoleTemplateCreateEditPermission() &&
        item.templateFlg
      ) {
        this.tableContent.content.splice(item, 1);
      }
      this.currentSelectedRows.forEach((selectedItem: any) => {
        if (item.id === selectedItem.id) {
          item.selected = selectedItem.selected;
        }
      });
      return item;
    });

    // this.errorObject = !this.tableContent.content.length ? Object.assign({}, {
    //   type: "error",
    //   message: "No results"
    // }) : Object.assign({}, {type: "", message: ""});
  }

  /*Refresh tableContent when create/edit is done*/
  public onSuccessEditCreate(event: any) {
    if (event === true) {
      this.getTableContent();
    }
  }

  /*On selected rows*/
  public onSelectedRows(event: any): void {
    this.currentSelectedRows = event;
  }

  /*Popup show and hide CSS class*/
  public showPopup() {
    return this.currentSelectedRows.length > 0 &&
      this.managementModule !== 'product'
      ? 'mc-popup-wrapper-show'
      : '';
  }

  /*Set width of popup*/
  public setPopupWidth() {
    return {
      width: this.tableWidth
        ? this.tableWidth.nativeElement.offsetWidth + 'px'
        : '',
    };
  }

  actOpenCreateRoleFromTemplModal() {
    if (!this.isRootParent) {
      this.showCreateRoleFromTemplateMcb.setTrue();
    }
  }

  onCreateRoleFromTemplate() {
    this.getTableContent();
    this.showCreateRoleFromTemplateMcb.setFalse();
    this.successMessage = 'New role from template created successfully';
  }

  onCancelCreateRoleFromTemplate() {
    this.showCreateRoleFromTemplateMcb.setFalse();
  }

  onResendEmailCancel() {
    this.showResendUserMailFlg = false;
  }

  onResendEmail(value: boolean) {
    if (!value) {
      this.errorObject.message = McGod.t(
        'cc.product.the-invitation-couldnt-be-sent-due-to-the-server-error-please-contact-your-support'
      );
      this.showResendUserMailFlg = false;
    }
    this.getTableContent();
    this.showResendUserMailFlg = false;
    this.successMessage = `${McGod.t(
      'cc.user.the-invitation-was-successfully-resent'
    )}
    ${McGod.t('cc.pricelist.for')} ${this.selectedUser.firstname} ${
      this.selectedUser.lastname
    }.`;
  }
}
