
<div class="mcc-message {{extraCssClasses}}">
  <div *ngIf="errorMsg" class="mcc-message-error error"><i class="fas fa-exclamation-circle"></i> {{errorMsg}}</div>
  <ng-container *ngIf="errorMsgs && errorMsgs.length > 0">
    <h2 *ngIf="label && (label.length > 0) && (errorMsgs.length > 0)" [innerHTML]="label"></h2>
    <ul class="mcc-message-error error">
    <li *ngFor="let singleErrorMsg of errorMsgs;"  [innerHTML]="singleErrorMsg" ></li>
    </ul>
  </ng-container>

  <div *ngIf="infoMsg" class="mcc-message-info info"><i class="fas fa-info-circle"></i> {{infoMsg}}</div>
  <ng-container *ngIf="infoMsgs && infoMsgs.length > 0">
    <h2 *ngIf="label && (label.length > 0) && (infoMsgs.length > 0)" [innerHTML]="label"></h2>
    <ul class="mcc-message-info info">
      <li *ngFor="let singleInfoMsg of infoMsgs;" class="mcc-message-info info" [innerHTML]="singleInfoMsg" ></li>
    </ul>
  </ng-container>

  <div *ngIf="successMsg" class="mcc-message-success success" [innerHTML]="successMsg"> <i class="far fa-check-circle"></i> {{successMsg}}</div>
  <ng-container *ngIf="successMsgs && successMsgs.length > 0">
    <h2 *ngIf="label && (label.length > 0) && (successMsg.length > 0)" [innerHTML]="label"></h2>
    <ul class="mcc-message-success success">
      <li *ngFor="let singleInfoMsg of successMsg;" class="mcc-message-info info" [innerHTML]="singleInfoMsg" ></li>
    </ul>
  </ng-container>
</div>
