import {Inject, Injectable} from '@angular/core';
import {EbSepaStatementReportServiceGENERATED} from '../_generated/eb-sepa-statement-report-generated.service';



@Injectable({
  providedIn: 'root'
})
export class EbSepaStatementReportService extends EbSepaStatementReportServiceGENERATED {

}
