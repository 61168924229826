/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EbPsd2ReportTable3ConfigGENERATED} from '../_generated/eb-psd2-report-table-3-config-generated.model';

// import {EbPsd2ReportGENERATED} from '../_generated/eb-psd2-report-generated.model';

export class EbPsd2ReportTable3Config extends EbPsd2ReportTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colDateFrom', 'Date from', 'dateFrom', 'dateFrom');
    this.addColumn('colDateTo', 'Date to', 'dateTo', 'dateTo');
    this.addColumn('colEntryCount', 'Count of the entries in the psd2 transaction response', 'entryCount', 'entryCount');
    this.addColumn('colIdMcBankAccount', 'Bank account ID', 'idMcBankAccount', 'idMcBankAccount');
    this.addColumn('colIdMcCurrency', 'Currency ID', 'idMcCurrency', 'idMcCurrency');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colStatusCd', 'Status', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');


  }
}
