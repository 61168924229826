<h3 class="page-title">{{"cc.processes.process-overview" | translate}}</h3>

<div class="actions-container">
  <span>{{"cc.processes.process-list" | translate}}</span>
  <div class="actions-wrapper">
    <button *ngIf="mcGod.userHasMcProcessAddPermission()" (click)="actCreateProcess()" class="system-action-btn"><i class="fas fa-plus-circle"></i> {{"cc.processes.create-process" | translate}}
    </button>
  </div>
</div>

<div class="row">
  <mcc-modal1-wrapper [modalSizeCd]="'small'" *ngIf="showAddEditProcessMcb.value" [showMcb]="showAddEditProcessMcb">
    <mc-process-process-add-edit-part (eventCanceled)="actCloseAddEditProcess()"
                                      (eventSaved)="actAddEditProcessSaved()"></mc-process-process-add-edit-part>
  </mcc-modal1-wrapper>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
  (filterChanged)="onFilterChanged($event)"
  [searchTooltip]="searchTooltip"
></mk-mat-table>
<router-outlet></router-outlet>

