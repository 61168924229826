import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {McProcessFlowLevel, McPdfTemplateList, McProcess, McTokens, EbFactoringService} from "@miticon-ui/mc-core";
import {
  McForm,
  McBoolean,
  McGod,
  McValueLabelList
} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-process-flow-level-add-edit',
  templateUrl: './mc-process-process-flow-level-add-edit.component.html',
})
export class McProcessProcessFlowLevelAddEditComponent implements OnInit {

  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  errorMsgs: string[] = [];
  paymentQrCodeVll = new McValueLabelList();
  paymentQrCodeVllPost = new McValueLabelList();
  pdfTemplatesVll = new McValueLabelList();
  tokenVll = McTokens.getEmailTokenVll();
  errorMsg!: string;
  mcPdfTemplateList = new McPdfTemplateList();
  activeFactoringsForEntity = [];
  currency!: string;

  // -- Input --
  @Input() mcProcessFlowLevelId!: number;
  @Input() mcProcessFlowLevel!: McProcessFlowLevel;
  @Input() mcProcess = new McProcess();
  @Input() mcProcessFlowId!: number;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();


  // -- Constructor --
  constructor(
    private factoringService: EbFactoringService) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {



    this.mcPdfTemplateList.loadByEntityIdAndTypeCd(McGod.getLoggedEntityIdFromToken(),  this.mcProcess.typeCd, () => {
      this.pdfTemplatesVll = this.mcPdfTemplateList.toVll('id', 'title');
    });

    this.paymentQrCodeVll.add(true, 'Yes');
    this.paymentQrCodeVll.add(false, 'No');

    this.paymentQrCodeVllPost.add(true, 'Yes');
    this.paymentQrCodeVllPost.add(false, 'No');

    this.actLoad();

    this.currency = this.factoringService.activeChildFactoring.mcCurrency.code;
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    if (!this.mcProcessFlowLevel) {
      this.mcProcessFlowLevel = new McProcessFlowLevel();
      if (this.mcProcessFlowLevelId > 0) {
        this.mcProcessFlowLevel.loadById(this.mcProcessFlowLevelId, () => {
          this.validatePrerequisites();
        }, () => {
          this.errorMsg = this.mcProcessFlowLevel.apiErrorMessage;
        });
      }
    } else {
      // new item
      this.validatePrerequisites();
    }

  }

  // ---------------------------------------------------------------------
  actSave() {

    console.log('FORM GROUP 3333333333', this.mcForm.formGroup.controls);
    // this.mcForm.isValid();
    // this.saveButtonDisabledMcb.setFalse();

    if (this.mcForm.isValid()) {

      if (this.mcForm.getValue('ctrlEmail') || this.mcForm.getValue('ctrlPost') || this.mcForm.getValue('ctrlSms')) {

        this.saveButtonDisabledMcb.setTrue();

        this.mcProcessFlowLevel.title = this.mcForm.getValue('ctrlName');
        this.mcProcessFlowLevel.idMcProcessFlow = this.mcProcessFlowId;

        this.mcProcessFlowLevel.emailActionFlg = this.mcForm.getValue('ctrlEmail');
        this.mcProcessFlowLevel.postActionFlg = this.mcForm.getValue('ctrlPost');
        this.mcProcessFlowLevel.smsActionFlg = this.mcForm.getValue('ctrlSms');
        this.mcProcessFlowLevel.idPdfTemplate = Number(this.mcForm.getValue('ctrlPdfTemplate'));
        if (this.mcProcessFlowLevel.emailActionFlg) {
          this.mcProcessFlowLevel.emailPaymentQrCodeFlg = this.mcForm.getValue('ctrlPaymentQrCode');
          this.mcProcessFlowLevel.emailSubjectTokened = this.mcForm.getValue('ctrlEmailSubject');
          this.mcProcessFlowLevel.emailBodyTokened = this.mcForm.getValue('ctrlEmailBody');
        } else if (this.mcProcessFlowLevel.postActionFlg) {
          this.mcProcessFlowLevel.postPaymentQrCodeFlg = this.mcForm.getValue('ctrlPaymentQrCodePost');

        } else if (this.mcProcessFlowLevel.smsActionFlg) {
          this.mcProcessFlowLevel.smsBodyTokened = this.mcForm.getValue('ctrlSmsBody');
        }
        this.mcProcessFlowLevel.noDaysPaymentDeadlineAfterReminderIssue = this.mcForm.getValue('ctrlPaymentDeadline');
        this.mcProcessFlowLevel.noDaysWaitingPeriodAfterPaymentDeadline = this.mcForm.getValue('ctrlWaitingPeriodAfterPD');
        this.mcProcessFlowLevel.levelFeeAmount = this.mcForm.getValue('ctrlLevelFeeAmount');

        console.log('level 999999', this.mcProcessFlowLevel);

        this.mcProcessFlowLevel.save(() => {
          this.eventSaved.emit();
        }, () => {
          this.saveButtonDisabledMcb.setFalse();
          this.errorMsg = this.mcProcessFlowLevel.apiErrorMessage;
        });
        this.saveButtonDisabledMcb.setFalse();
      } else {
        this.errorMsg = 'At least one level action is required';
      }
    }

  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.mcProcessFlowLevel.exists()) {

      this.mcProcessFlowLevel.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

// ---------------------------------------------------------------------
  validatePrerequisites() {
    this.errorMsgs = [];
    // populate validation here on required inputs
  }

  // ---------------------------------------------------------------------
  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }
}
