import { Component, OnInit } from '@angular/core';
import { EbInkassoExport, EbInkassoExportFilter, WebFile, McHtmlButton, McValueLabelList, EbInkassoExportService, SortCriteriaList, McGod } from '@miticon-ui/mc-core';
import * as fileSaver from "file-saver";
import { TranslateService } from '@ngx-translate/core';
import { MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig } from '@miticon-ui/mc-components';
import { ToastrService } from "ngx-toastr";


@Component({
  selector: 'mc-out-factoring-inkasso-export-list-page',
  templateUrl: './mc-out-factoring-inkasso-export-list-page.component.html',
})
export class McOutFactoringInkassoExportListPageComponent implements OnInit {
  ebInkassoExportFilter = new EbInkassoExportFilter();
  webFile = new WebFile();
  pageNumber = 0;
  pageSize = 5;
  isLoading = false;
  items: EbInkassoExport[] = [];
  totalItemsCount!: number;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  searchToolTip = this.tS.instant('cc.inkasso-export-search-tooltip');
  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.common.download-file'),
      actionCd: EbInkassoExport.ACTION_DOWNLOAD,
      matIcon: 'download',
      permission: McGod.PERM_EB_FACTORING_OUT_INKASSO_EXPORT
    }];

  constructor(private ebInkassoExportService: EbInkassoExportService,
              private tS: TranslateService,
              private toastrService: ToastrService) {}

  ngOnInit() {
    this.initTableConfig();
    this.initFilterConfig();
    this.actLoad(this.pageNumber, this.pageSize, this.ebInkassoExportFilter);
  }

  public actLoad(pageNumber: number, pageSize: number, ebInkassoExportFilter: EbInkassoExportFilter): void {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.isLoading = true;
    const sortCriteriaList = new SortCriteriaList();
    this.ebInkassoExportService.getByFilter(ebInkassoExportFilter, this.pageNumber, this.pageSize, sortCriteriaList).subscribe((res) => {
      if (res) {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => object = EbInkassoExport.createFromJson(object));
      }
    });
  }

  initTableConfig(){
    this.tableConfig.addColumnStandard(this.tS.instant('cc.out-factoring.exported-file'), 'exportFileName', 250, '' );
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.create-type'), 'getCreationTypeCdLabel()', 250, '' );
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.export-sepa.export-date-&-time'), 'getCreatedDatetimeString()', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.imported-sepa.all-debtors'), 'totalDebtors', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.dashboard.number-of-transactions'), 'totalTransactions', 250, '');
    this.tableConfig.addColumnSvgIcon(this.tS.instant('cc.common.status'), 'getStatusCd()');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.finance.exported-by'), 'exportedByUserName', 250, '');
  }

  initFilterConfig(){
    const createTypeValueLabelList = new McValueLabelList();
    createTypeValueLabelList.add(EbInkassoExport.CREATION_TYPE_CD_MANUAL, 'Manual');
    createTypeValueLabelList.add(EbInkassoExport.CREATION_TYPE_CD_AUTO, 'Auto');
    this.filterConfig.addItem(MkFilterItemType.SELECT, this.tS.instant("cc.common.create-type"), createTypeValueLabelList.items.map((item) => new MkFilterValue(item.label, item.value)));


    const statusValueLabelList = new McValueLabelList();
    statusValueLabelList.add(EbInkassoExport.STATUS_CD_IN_PROGRESS, 'In progress');
    statusValueLabelList.add(EbInkassoExport.STATUS_CD_EXPORTED, 'Exported');
    statusValueLabelList.add(EbInkassoExport.STATUS_CD_FAILED, 'Failed');
    this.filterConfig.addItem(MkFilterItemType.SELECT, this.tS.instant("cc.common.status"), statusValueLabelList.items.map((item) => new MkFilterValue(item.label, item.value)));

    const exportedByList = new McValueLabelList();
    this.ebInkassoExportService.getByFilter(new EbInkassoExportFilter(), 0, 100, new SortCriteriaList()).subscribe((res)=> {
      if(res){
        res.content.forEach((item: EbInkassoExport) => {
          if(!exportedByList.hasValue(item.sysCreatedUserId)){
            exportedByList.add(item.sysCreatedUserId, item.exportedByUserName);
          }
        });
        this.filterConfig.addItem(MkFilterItemType.SELECT, this.tS.instant('cc.finance.exported-by'), exportedByList.items.map((item) => new MkFilterValue(item.label, item.value)));
      }
    });
    this.filterConfig.addItem(MkFilterItemType.RANGE_DATEPICKER, this.tS.instant('cc.common.select-date-range'));
  }

  onFilterChanged(filterOutput: MkFilterOutput): void {
    this.ebInkassoExportFilter.searchTerm= filterOutput.search ? filterOutput.search : '';
    this.ebInkassoExportFilter.exportedBy= filterOutput.selections[this.tS.instant('cc.finance.exported-by')] ? filterOutput.selections[this.tS.instant('Exported by')] : '';
    this.ebInkassoExportFilter.exportedDateFrom= filterOutput.selections[this.tS.instant('cc.common.select-date-range')]?.startDate ? new Date(new Date(filterOutput.selections[this.tS.instant('Select date range')]?.startDate).setHours(0,0,0,0)).toISOString() : '';
    this.ebInkassoExportFilter.exportedDateTo= filterOutput.selections[this.tS.instant('cc.common.select-date-range')]?.endDate ? new Date(new Date(filterOutput.selections[this.tS.instant('Select date range')]?.endDate).setHours(23,59,59,999)).toISOString() : '';
    this.ebInkassoExportFilter.statusCd = filterOutput.selections[this.tS.instant('cc.common.status')] ? filterOutput.selections[this.tS.instant('cc.common.status')] : '';
    this.ebInkassoExportFilter.creationTypeCd = filterOutput.selections[this.tS.instant('cc.common.create-type')] ? filterOutput.selections[this.tS.instant('cc.common.create-type')] : '';

    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, this.ebInkassoExportFilter);
  }

  onTableItemAction(mcHtmlButton: McHtmlButton) {
    if (mcHtmlButton.actionCdForEvent === EbInkassoExport.ACTION_DOWNLOAD) {
      this.webFile.getFileByIdAndType(mcHtmlButton.item.idWebFile, WebFile.TYPE_CD_IN_INKASSO_EXPORT, () => {
        this.downloadFile();
      });
    }
  }

  onTableAction($event: MkMatTableMenuAction){
    if($event.action.actionCd === EbInkassoExport.ACTION_DOWNLOAD){
        this.webFile.getFileByIdAndType($event.item.idWebFile, WebFile.TYPE_CD_IN_INKASSO_EXPORT, () => {
        this.downloadFile();
     });
    }
  }

  downloadFile() {
    this.webFile.downloadFile().subscribe(next => {
      fileSaver.saveAs(next, `${this.webFile.originalFilename}`);
    }, err => {
      this.toastrService.error(`Error downloading file ${this.webFile.originalFilename}`);
    });
  }
}
