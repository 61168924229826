import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ICsrMappingItem} from '../_generated/csr-mapping-item.interface';
import {CsrMappingItem} from '../models/csr-mapping-item.model';
import {CsrMappingItemList} from '../models/csr-mapping-item-list.model';
import {CsrMappingItemServiceGENERATED} from '../_generated/csr-mapping-item-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CsrMappingItemService extends CsrMappingItemServiceGENERATED {

}
