export const ICON_INFO_WHITE = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <rect id="rect-1" width="24" height="24" x="0" y="0"/>
    <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="userSpaceOnUse">
      <rect width="24" height="24" x="0" y="0" fill="black"/>
      <use fill="white" xlink:href="#rect-1"/>
    </mask>
  </defs>
  <g>
    <use fill="none" xlink:href="#rect-1"/>
    <g mask="url(#mask-2)">
      <path fill="none" d="M0 0h24v24H0V0z"/>
      <path fill="rgb(255,255,255)" fill-rule="evenodd" d="M12 2C6.48001099 2 2 6.47998047 2 12s4.48001099 10 10 10 10-4.47998047 10-10S17.51998901 2 12 2zm-1 5v2h2V7h-2zm1 10c-.5499878 0-1-.4500122-1-1v-4c0-.5499878.4500122-1 1-1s1 .4500122 1 1v4c0 .5499878-.4500122 1-1 1z"/>
    </g>
  </g>
</svg>`
