import {BaseObject, Table2ColumnConfig} from '@miticon-ui/mc-core';

export class MccFiTable2CellEditConfigData {
  editComponentConfigObj: any;
  dataItemObj: BaseObject;
  columnConfigObj: Table2ColumnConfig;


  constructor(aEditComponentConfigObj: any, aDataItemObj: BaseObject, aColumnConfigObj: Table2ColumnConfig) {
    this.editComponentConfigObj = aEditComponentConfigObj;
    this.dataItemObj = aDataItemObj;
    this.columnConfigObj = aColumnConfigObj;
  }
}
