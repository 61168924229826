import {EbSepaStatementReport} from './eb-sepa-statement-report.model';
import {EbSepaStatementReportListGENERATED} from '../_generated/eb-sepa-statement-report-list-generated.model';

export class EbSepaStatementReportList extends EbSepaStatementReportListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaStatementReportList();
  }

  // ---------------------------------------------------------------------
}
