/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McProcessRun} from '../models/mc-process-run.model';
import {McProcessRunList} from '../models/mc-process-run-list.model';
import {McProcessRunFilter} from '../models/mc-process-run-filter.model';
import {McProcessRunService} from '../services/mc-process-run.service';
import {IMcProcessRun} from '../_generated/mc-process-run.interface';
import {McGod} from "../extra/mc-god.service";

export class McProcessRunListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'buildItemsFlg', 'builtDatetime', 'cleanedDatetime', 'deliverItemsFlg', 'idMcProcess', 'lastErrorMsg', 'loadItemsFlg', 'loadedDatetime', 'runDatetime', 'statusCd', 'sysCreatedDatetime', 'sysUpdatedDatetime', 'typeCd', 'builtItemsCount', 'deliveredItemsCount', 'errorItemsCount', 'newItemsCount', 'readyItemsCount', 'skippedItemsCount'];
  public items: McProcessRun[] = [];
  public apiService: McProcessRunService;
 // protected tempObjList: McProcessRunList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProcessRunList {
    console.error('MTCN-ERROR: Not Implemented: McProcessRunList::createNewInstance(). Add this method to final class and return new McProcessRunList();');
    throw new Error('Cannot McProcessRunList::createNewInstance(). Add this method to final class and return new McProcessRunList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcProcessRun[]): McProcessRunList {
    const  listObj: McProcessRunList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McProcessRun = McProcessRun.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McProcessRunList {
    const  listObj: McProcessRunList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessRunService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcProcessRun[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McProcessRun = McProcessRun.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcProcessRunFilter: McProcessRunFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcProcessRunFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McProcessRun {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McProcessRun();
    }
  }

  public replaceItem(newItem: McProcessRun) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadByIdMcProcess( idMcProcess: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByIdMcProcess(idMcProcess, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

