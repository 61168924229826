<ng-container class="dialog">
  <div class="dialog_header">
    <span class="title-medium-dark">{{'cc.common.view.create-attribute' | translate}}</span>
    <span class="dialog_header_icon" [innerHTML]="iconClose | safeHtml" (click)="dialogRef.close()"></span>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.common.name' | translate}}*</mat-label>
          <input type="text"
                 formControlName="name"
                 matInput/>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.jobs.edit.type' | translate}}*</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="typeCd"
                      disableOptionCentering>
            <mat-option (click)="setTypeCd(type.value)" *ngFor="let type of attributeTypes" [value]="type.value">
              {{ type.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form_description">
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.common.view.description' | translate}}</mat-label>
          <input type="text"
                 formControlName="description"
                 matInput/>
        </mat-form-field>
      </div>
      <div class="radio_buttons">
        <div class="radio_buttons_item">
          <h3 class='title-small-dark mb-small'>{{'cc.common.edit.inheritable' | translate}}*</h3>
          <div class='input-wrapper mb-large'>
            <mat-radio-group formControlName="inheritableFlg">
              <mat-radio-button [value]="true" class='mr-large'>{{'cc.common.edit.yes' | translate}}</mat-radio-button>
              <mat-radio-button [value]="false">{{'cc.common.edit.no' | translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div *ngIf="typeCd" class="radio_buttons_item">
        <mat-checkbox (change)="checkDefaultValue($event)">{{ "cc.common.view.default-value" | translate }}</mat-checkbox>
      </div>
      <div class="form_default mt-medium" *ngIf="checkedDefaultValue">
        <mat-form-field *ngIf="typeCd === 'bigint' || typeCd === 'varchar' || typeCd === 'decimal'" appearance="outline">
          <mat-label>{{'cc.common.view.default-value' | translate}}*</mat-label>
          <input formControlName="sampleData"
                 (keydown)="checkValidation($event)"
                 matInput/>
        </mat-form-field>
        <div *ngIf="typeCd === 'boolean'" class='input-wrapper mb-large'>
          <mat-radio-group formControlName="sampleData">
            <mat-radio-button [value]="true" class='mr-large'>{{'cc.common.edit.yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false">{{'cc.common.edit.no' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field *ngIf="typeCd === 'timestamp'" appearance="outline">
          <mat-label>{{'cc.common.view.default-value' | translate}}*</mat-label>
          <input matInput [matDatepicker]="date" formControlName='sampleData'
                 (click)='date.open()' readonly>
          <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
          <mat-datepicker #date></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button class="secondary-btn" (click)="dialogRef.close()">{{'cc.common.edit.cancel' | translate}}</button>
    <button mat-button class="primary-btn" (click)="createAttribute()" [disabled]="!form.valid" type="submit">{{'cc.common.create' | translate}}</button>
  </div>
</ng-container>
