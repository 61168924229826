import {HttpHeaders} from '@angular/common/http';

export abstract class BaseEnvironment {

  production!: false;
  environmentCd = 'DEV1';
  clientCd = 'MTCNFRAMEWORKAPP';
  appTitle = 'Mtcn Framework App';
  apiUrl = ''; // http://localhost:8080/api';
  _rawJson!: string;

  public propertiesRequired = ['production', 'environmentCd', 'clientCd', 'appTitle', 'apiUrl'];

// ---------------------------------------------------------------------
  public abstract prepare(): any;

  // ---------------------------------------------------------------------
  // TODO Radica & Bogdan : do we need this token, and are CG Controllers allowing access even if token is  blank.
  public getHttpOptions(token?: string) {
    return {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      })
    };
  }

}
