<div class="content-wrapper">

  <lib-mc-loader [showLoader]="mcPriceList.apiLoadingFlg"></lib-mc-loader>

  <div class="content-header">
    <div class="stepper-progress-wrap">
      <div class="stepper-progress">
        <div class="step-count">{{'cc.pricelist.step' | translate}} 3/3</div>
        <div class="step-done">{{'cc.pricelist.select-template' | translate}}</div>
        <div class="step-done">{{'cc.pricelist.prices-and-entities' | translate}}</div>
        <div class="step-done">{{'cc.pricelist.review-and-confirm' | translate}}</div>
      </div>
    </div>
    <h2>{{'cc.pricelist.create-new-pricelist' | translate}}</h2>
  </div>

  <div class="content-body">
    <div class="info-container2 p-3">
      <div class="row">
        <div class="col-md-6">
          <mcc-row-title-description title="{{'cc.pricelist.pricelist-name' | translate}}:"
                                     [description]="mcPriceList.title"></mcc-row-title-description>
          <mcc-row-title-description title="{{'cc.pricelist.template-origin' | translate}}:"
                                     [description]="templatePriceList.title"></mcc-row-title-description>
          <mcc-row-title-description title="{{'cc.pricelist.pricelist-description' | translate}}:"
                                     [description]="mcPriceList.description"></mcc-row-title-description>
        </div>
        <div class="col-md-6 p-1">
          <mcc-row-title-description title="{{'cc.pricelist.valid-from' | translate}}:"
                                     [description]="validFromDateString"></mcc-row-title-description>
          <mcc-row-title-description title="{{'cc.pricelist.no-of-entities' | translate}}:"
                                     [descriptionInnerHtml]="getNumberOfEntitiesDesc()" extraCssClasses="mc-description-blue-color"></mcc-row-title-description>
        </div>
      </div>
    </div>

    <mcc-message [errorMsg]="mcPriceList.apiErrorMessage"></mcc-message>

    <div class="row">
      <div class="col-md-12 mt-3">
        <mcc-fi-table-3 [objectList]="templatePriceList.mcBillingService2PriceListItemList"
                        [table3Config]="mcBillingService2PriceListTable3Config"
        ></mcc-fi-table-3>
      </div>
    </div>


  </div>

  <div class="content-footer">
    <mcc-button extraCssClasses="btn btn-secondary"
                (eventClicked)="actPrev()" label="<i class='fas fa-arrow-left'></i> Prev"></mcc-button>
    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel()"
                label="{{'cc.common.edit.cancel' | translate}}"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actCreate()" label="<i class='fas fa-check'></i> {{'cc.common.create' | translate}}"
                [disabledMcb]="saveButtonDisabledMcb"></mcc-button>
  </div>

</div>
