/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {PmAdjustmentFilter} from '../models/pm-adjustment-filter.model';

export class PmAdjustmentFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): PmAdjustmentFilter {
    console.error('MTCN-ERROR: Not Implemented: PmAdjustmentFilter::createNewInstance(). Add this method to final class and return new PmAdjustmentFilter();');
      throw new Error('Cannot PmAdjustmentFilter::createNewInstance(). Add this method to final class and return new PmAdjustmentFilter();');
  }

}
