import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import _moment from "moment";

@Component({
  selector: "lib-mc-cronex",
  templateUrl: "./mc-cronex.component.html"
})

export class McCronexComponent implements OnInit {

  minutesForm!: FormGroup;
  hoursForm!: FormGroup;
  daysForm!: FormGroup;
  monthsForm!: FormGroup;
  yearsForm!: FormGroup;
  private today!: any;
  optionsMin: any[] = [];
  optionsHour: any[] = [];
  optionsMonth: any[] = [];
  optionsDay: any[] = [];
  optionsYear: any[] = [];
  daysOfWeek: any[] = [];
  numMonths: any[] = [];
  numOfWeeks: any[] = [];
  cronString = "0 0 0 ? * * *";

  @Input() cronFromParent!: string;
  @Output() cronEvent = new EventEmitter();

  cronFormRawValue = {
    secondValue: "0",
    minuteValue: "0",
    hourValue: "0",
    dayValue: "*",
    monthValue: "*",
    yearValue: "*"
  };
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.today = parseInt(_moment().format("YYYY"), 10);
    this.createForm();
    this.createArrays();
    this.onChanges();
    if (this.cronFromParent) {
      this.cronString = this.cronFromParent;
    }
    this.emitCronex();
    this.selectRadio();
    this.setFormWithParentCronex();
  }

  emitCronex() {
    this.cronEvent.emit(this.cronString);
  }

  selectRadio() {
    // Change radio button value on select button change

                         // MINUTES
    this.minutesForm.get("everyMinStartAt1")?.valueChanges.subscribe( data => {
      this.minutesForm.controls["minute"].setValue("onXMinute");
    });
    this.minutesForm.get("everyMinStartAt2")?.valueChanges.subscribe( data => {
      this.minutesForm.controls["minute"].setValue("onXMinute");
    });
    this.minutesForm.get("everyMinBetw1")?.valueChanges.subscribe( data => {
      this.minutesForm.controls["minute"].setValue("betweenXandYMinute");
    });
    this.minutesForm.get("everyMinBetw2")?.valueChanges.subscribe( data => {
      this.minutesForm.controls["minute"].setValue("betweenXandYMinute");
    });

                             // HOURS
    this.hoursForm.get("everyHourStartAt1")?.valueChanges.subscribe( data => {
      this.hoursForm.controls["hour"].setValue("onXHour");
    });
    this.hoursForm.get("everyHourStartAt2")?.valueChanges.subscribe( data => {
      this.hoursForm.controls["hour"].setValue("onXHour");
    });
    this.hoursForm.get("everyHourBetw1")?.valueChanges.subscribe( data => {
      this.hoursForm.controls["hour"].setValue("betweenXandYHour");
    });
    this.hoursForm.get("everyHourBetw2")?.valueChanges.subscribe( data => {
      this.hoursForm.controls["hour"].setValue("betweenXandYHour");
    });

                            // DAYS
    this.daysForm.get("specDayWeek")?.valueChanges.subscribe( data => {
      this.daysForm.controls["day"].setValue("specificDayWeek");
    });
    this.daysForm.get("everyDayStartAt1")?.valueChanges.subscribe( data => {
      this.daysForm.controls["day"].setValue("onXDay");
    });
    this.daysForm.get("everyDayStartAt2")?.valueChanges.subscribe( data => {
      this.daysForm.controls["day"].setValue("onXDay");
    });
    this.daysForm.get("lastOnMonth")?.valueChanges.subscribe( data => {
      this.daysForm.controls["day"].setValue("lastXdayMonth");
    });
    this.daysForm.get("xDaysEndMonth")?.valueChanges.subscribe( data => {
      this.daysForm.controls["day"].setValue("xDaysEnd");
    });
    this.daysForm.get("xSpecY1")?.valueChanges.subscribe( data => {
      this.daysForm.controls["day"].setValue("xSpecY");
    });
    this.daysForm.get("xSpecY2")?.valueChanges.subscribe( data => {
      this.daysForm.controls["day"].setValue("xSpecY");
    });

                            // MONTHS
    this.monthsForm.get("everyMonthStartAt1")?.valueChanges.subscribe( data => {
      this.monthsForm.controls["month"].setValue("onXMonth");
    });
    this.monthsForm.get("everyMonthStartAt2")?.valueChanges.subscribe( data => {
      this.monthsForm.controls["month"].setValue("onXMonth");
    });
    this.monthsForm.get("everyMonthBetw1")?.valueChanges.subscribe( data => {
      this.monthsForm.controls["month"].setValue("betweenXandYMonth");
    });
    this.monthsForm.get("everyMonthBetw2")?.valueChanges.subscribe( data => {
      this.monthsForm.controls["month"].setValue("betweenXandYMonth");
    });

                            // YEARS
    this.yearsForm.get("everyYearStartAt1")?.valueChanges.subscribe( data => {
      this.yearsForm.controls["year"].setValue("onXYear");
    });
    this.yearsForm.get("everyYearStartAt2")?.valueChanges.subscribe( data => {
      this.yearsForm.controls["year"].setValue("onXYear");
    });
    this.yearsForm.get("everyYearBetw1")?.valueChanges.subscribe( data => {
      this.yearsForm.controls["year"].setValue("betweenXandYYear");
    });
    this.yearsForm.get("everyYearBetw2")?.valueChanges.subscribe( data => {
      this.yearsForm.controls["year"].setValue("betweenXandYYear");
    });
  }

  onChanges() {
    // Change data in form depend on radio button selection

                    // MINUTES
    this.minutesForm.valueChanges.subscribe(data => {
      if (data.minute === "everyMinute") {
        this.cronFormRawValue.minuteValue = "*";
      } else if (data.minute === "specificMinute") {
        this.cronFormRawValue.minuteValue = data.specMinute;
      } else if (data.minute === "onXMinute") {
        this.cronFormRawValue.minuteValue = data.everyMinStartAt2 + "/" + data.everyMinStartAt1;
      } else if (data.minute === "betweenXandYMinute") {
        this.cronFormRawValue.minuteValue = data.everyMinBetw1 + "-" + data.everyMinBetw2;
      }
      this.updateCronString();
      this.emitCronex();
    });

                        // HOURS
    this.hoursForm.valueChanges.subscribe(data => {
      if (data.hour === "everyHour") {
        this.cronFormRawValue.hourValue = "*";
      } else if (data.hour === "specificHour") {
        this.cronFormRawValue.hourValue = data.specHour;
      } else if (data.hour === "onXHour") {
        this.cronFormRawValue.hourValue = data.everyHourStartAt2 + "/" + data.everyHourStartAt1;
      } else if (data.hour === "betweenXandYHour") {
        this.cronFormRawValue.hourValue = data.everyHourBetw1 + "-" + data.everyHourBetw2;
      }
      this.updateCronString();
      this.emitCronex();
    });

                        // DAY
    this.daysForm.valueChanges.subscribe(data => {
      if (data.day === "everyDay") {
        this.cronFormRawValue.dayValue = "*";
      } else if (data.day === "specificDay") {
        this.cronFormRawValue.dayValue = data.specDay;
      } else if (data.day === "specificDayWeek")  {
        this.cronFormRawValue.dayValue = data.specDayWeek.toUpperCase();
      } else if (data.day === "onXDay") {
        this.cronFormRawValue.dayValue = data.everyDayStartAt2 + "/" + data.everyDayStartAt1;
      } else if (data.day === "lastDay") {
        this.cronFormRawValue.dayValue = "L";
      } else if (data.day === "lastWeekDay") {
        this.cronFormRawValue.dayValue = "LW";
      } else if (data.day === "lastXdayMonth") {
        let string1;
        switch (data.lastOnMonth) {
          case "Sun": {
            string1 = "1L";
            break;
          }
          case "Mon": {
            string1 = "2L";
            break;
          }
          case "Tue": {
            string1 = "3L";
            break;
          }
          case "Wed": {
            string1 = "4L";
            break;
          }
          case "Thu": {
            string1 = "5L";
            break;
          }
          case "Fri": {
            string1 = "6L";
            break;
          }
          case "Sat": {
            string1 = "7L";
            break;
          }
        }
        // @ts-ignore
        this.cronFormRawValue.dayValue = string1;
      } else if (data.day === "xDaysEnd") {
        this.cronFormRawValue.dayValue = "L-" + data.xDaysEndMonth;
      } else if (data.day === "xSpecY") {
        let string1;
        switch (data.xSpecY2) {
          case "Sun": {
            string1 = "1";
            break;
          }
          case "Mon": {
            string1 = "2";
            break;
          }
          case "Tue": {
            string1 = "3";
            break;
          }
          case "Wed": {
            string1 = "4";
            break;
          }
          case "Thu": {
            string1 = "5";
            break;
          }
          case "Fri": {
            string1 = "6";
            break;
          }
          case "Sat": {
            string1 = "7";
            break;
          }
        }
        this.cronFormRawValue.dayValue = string1 + "#" + data.xSpecY1;
      }
      this.updateCronString();
      this.emitCronex();
    });

                    // MONTHS
    this.monthsForm.valueChanges.subscribe(data => {
      // MONTHS
      if (data.month === "everyMonth") {
        this.cronFormRawValue.monthValue = "*";
      } else if (data.month === "specificMonth") {
        this.cronFormRawValue.monthValue = data.specMonth.toUpperCase();
      } else if (data.month === "onXMonth") {
        let string2;
        switch (data.everyMonthStartAt2) {
          case "Jan": {
            string2 = "1";
            break;
          }
          case "Feb": {
            string2 = "2";
            break;
          }
          case "Mar": {
            string2 = "3";
            break;
          }
          case "Apr": {
            string2 = "4";
            break;
          }
          case "May": {
            string2 = "5";
            break;
          }
          case "Jun": {
            string2 = "6";
            break;
          }
          case "Jul": {
            string2 = "7";
            break;
          }
          case "Aug": {
            string2 = "8";
            break;
          }
          case "Sep": {
            string2 = "9";
            break;
          }
          case "Oct": {
            string2 = "10";
            break;
          }
          case "Nov": {
            string2 = "11";
            break;
          }
          case "Dec": {
            string2 = "12";
            break;
          }
        }
        this.cronFormRawValue.monthValue = string2 + "/" + data.everyMonthStartAt1;
      } else if (data.month === "betweenXandYMonth") {
        let string1;
        let string2;
        switch (data.everyMonthBetw1) {
          case "Jan": {
            string1 = "1";
            break;
          }
          case "Feb": {
            string1 = "2";
            break;
          }
          case "Mar": {
            string1 = "3";
            break;
          }
          case "Apr": {
            string1 = "4";
            break;
          }
          case "May": {
            string1 = "5";
            break;
          }
          case "Jun": {
            string1 = "6";
            break;
          }
          case "Jul": {
            string1 = "7";
            break;
          }
          case "Aug": {
            string1 = "8";
            break;
          }
          case "Sep": {
            string1 = "9";
            break;
          }
          case "Oct": {
            string1 = "10";
            break;
          }
          case "Nov": {
            string1 = "11";
            break;
          }
          case "Dec": {
            string1 = "12";
            break;
          }
        }
        switch (data.everyMonthBetw2) {
          case "Jan": {
            string2 = "1";
            break;
          }
          case "Feb": {
            string2 = "2";
            break;
          }
          case "Mar": {
            string2 = "3";
            break;
          }
          case "Apr": {
            string2 = "4";
            break;
          }
          case "May": {
            string2 = "5";
            break;
          }
          case "Jun": {
            string2 = "6";
            break;
          }
          case "Jul": {
            string2 = "7";
            break;
          }
          case "Aug": {
            string2 = "8";
            break;
          }
          case "Sep": {
            string2 = "9";
            break;
          }
          case "Oct": {
            string2 = "10";
            break;
          }
          case "Nov": {
            string2 = "11";
            break;
          }
          case "Dec": {
            string2 = "12";
            break;
          }
        }
        this.cronFormRawValue.monthValue = string1 + "-" + string2;
      }
      this.updateCronString();
      this.emitCronex();
    });

                // YEARS
    this.yearsForm.valueChanges.subscribe( data => {
      if (data.year === "everyYear") {
        this.cronFormRawValue.yearValue = "*";
      } else if (data.year === "specificYear") {
        this.cronFormRawValue.yearValue = data.specYear;
      } else if (data.year === "onXYear") {
        this.cronFormRawValue.yearValue = data.everyYearStartAt2 + "/" + data.everyYearStartAt1;
      } else if (data.year === "betweenXandYYear") {
        this.cronFormRawValue.yearValue = data.everyYearBetw1 + "-" + data.everyYearBetw2;
      }
      this.updateCronString();
      this.emitCronex();
    });
  }

  private createForm() {
    this.minutesForm = this.formBuilder.group({
      minute: ["specificMinute", Validators.required],
      specMinute: ["0"],
      everyMinStartAt1: ["0"],
      everyMinStartAt2: ["0"],
      everyMinBetw1: ["0"],
      everyMinBetw2: ["0"]
    });
    this.hoursForm = this.formBuilder.group({
      hour: ["specificHour", Validators.required],
      specHour: ["0"],
      everyHourStartAt1: ["0"],
      everyHourStartAt2: ["0"],
      everyHourBetw1: ["0"],
      everyHourBetw2: ["0"]
    });
    this.daysForm = this.formBuilder.group({
      day: ["everyDay", Validators.required],
      specDay: ["1"],
      specDayWeek: ["Sun"],
      everyDayStartAt1: ["1"],
      everyDayStartAt2: ["1"],
      lastOnMonth: ["Sun"],
      xDaysEndMonth: ["1"],
      xSpecY1: ["1"],
      xSpecY2: ["Sun"]
    });
    this.monthsForm = this.formBuilder.group({
      month: ["everyMonth", Validators.required],
      specMonth: ["Jan"],
      everyMonthStartAt1: ["1"],
      everyMonthStartAt2: ["Jan"],
      everyMonthBetw1: ["Jan"],
      everyMonthBetw2: ["Jan"]
    });
    this.yearsForm = this.formBuilder.group({
      year: ["everyYear", Validators.required],
      specYear: [this.today.toString()],
      everyYearStartAt1: ["1"],
      everyYearStartAt2: [this.today.toString()],
      everyYearBetw1: [this.today.toString()],
      everyYearBetw2: [this.today.toString()]
    });
  }

  private createArrays() {
    for (let i = 0; i < 60; i++) {
      this.optionsMin[i] = i.toString();
    }
    for (let i = 0; i < 24; i++) {
      this.optionsHour[i] = i.toString();
    }
    this.optionsMonth = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    for (let i = 0; i < 15; i++) {
      this.optionsYear[i] = (this.today + i).toString();
    }
    for (let i = 0; i < 31; i++) {
      this.optionsDay[i] = (i + 1).toString();
    }
    this.daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    for (let i = 0; i < 12; i++) {
      this.numMonths[i] = (i + 1).toString();
    }
    for (let i = 0; i < 5; i++) {
      this.numOfWeeks[i] = (i + 1).toString();
    }
  }

  updateCronString() {
    let cronex;
    // eslint-disable-next-line max-len
    // @ts-ignore
    if (this.daysForm.get("day")?.value === "specificDay" || this.daysForm.get("day")?.value === "onXDay" || this.daysForm.get("day").value === "lastWeekDay" || this.daysForm.get("day").value === "lastDay" || this.daysForm.get("day").value === "xDaysEnd") {
      // eslint-disable-next-line max-len
      cronex = this.cronFormRawValue.secondValue + " " + this.cronFormRawValue.minuteValue + " " + this.cronFormRawValue.hourValue + " " + this.cronFormRawValue.dayValue + " " + this.cronFormRawValue.monthValue + " ? " + this.cronFormRawValue.yearValue;
    } else {
      // eslint-disable-next-line max-len
      cronex = this.cronFormRawValue.secondValue + " " + this.cronFormRawValue.minuteValue + " " + this.cronFormRawValue.hourValue + " ? " + this.cronFormRawValue.monthValue + " " + this.cronFormRawValue.dayValue + " " + this.cronFormRawValue.yearValue;
    }
    this.cronString = cronex;
  }

  /*****************************      EDIT JOB - Transfer CronEx Into Form    *******************************************/
  setFormWithParentCronex() {
    if (this.cronFromParent) {

      const arrayOfValues = this.cronString.split(" ");

                      /***********     MINUTES     ************/
      const m = arrayOfValues[1];
      let minArray: any;
      minArray = m.split(/[.,\\/ -]/);

      if (minArray.length === 1) {
        if (m.indexOf("*") !== -1) {
          this.minutesForm.get("minute")?.setValue("everyMinute");
        } else {
          this.minutesForm.get("minute")?.setValue("specificMinute");
          this.minutesForm.get("specMinute")?.setValue(minArray[0]);
        }
      } else {
        if (m.indexOf("/") !== -1) {
          this.minutesForm.get("minute")?.setValue("onXMinute");
          this.minutesForm.get("everyMinStartAt1")?.setValue(minArray[1]);
          this.minutesForm.get("everyMinStartAt2")?.setValue(minArray[0]);
        } else {
          this.minutesForm.get("minute")?.setValue("betweenXandYMinute");
          this.minutesForm.get("everyMinBetw1")?.setValue(minArray[0]);
          this.minutesForm.get("everyMinBetw2")?.setValue(minArray[1]);
        }
      }
                  /**************   HOURS   **************/
      const h = arrayOfValues[2];
      let hourArray: any;
      hourArray = h.split(/[.,\\/ -]/);

      if (hourArray.length === 1) {
        if (h.indexOf("*") !== -1) {
          this.hoursForm.get("hour")?.setValue("everyHour");
        } else {
          this.hoursForm.get("hour")?.setValue("specificHour");
          this.hoursForm.get("specHour")?.setValue(hourArray[0]);
        }
      } else {
        if (h.indexOf("/") !== -1) {
          this.hoursForm.get("hour")?.setValue("onXHour");
          this.hoursForm.get("everyHourStartAt1")?.setValue(hourArray[1]);
          this.hoursForm.get("everyHourStartAt2")?.setValue(hourArray[0]);
        } else {
          this.hoursForm.get("hour")?.setValue("betweenXandYHour");
          this.hoursForm.get("everyHourBetw1")?.setValue(hourArray[0]);
          this.hoursForm.get("everyHourBetw2")?.setValue(hourArray[1]);
        }
      }
                      /**************   DAYS   **************/
      const ifQuestionMark = arrayOfValues[3];
      let dayArray: any;
      let dayLocal: any;

      if (ifQuestionMark === "?") {
        dayLocal = arrayOfValues[5];
        dayArray = dayLocal.split("");
        if (dayArray.length === 1) {
            this.daysForm.get("day")?.setValue("everyDay");
        } else if (dayArray.length === 2) {
          this.daysForm.get("day")?.setValue("lastXdayMonth");
          switch (dayLocal) {
            case "1L": {
              this.daysForm.get("lastOnMonth")?.setValue("Sun");
              break;
            }
            case "2L": {
              this.daysForm.get("lastOnMonth")?.setValue("Mon");
              break;
            }
            case "3L": {
              this.daysForm.get("lastOnMonth")?.setValue("Tue");
              break;
            }
            case "4L": {
              this.daysForm.get("lastOnMonth")?.setValue("Wed");
              break;
            }
            case "5L": {
              this.daysForm.get("lastOnMonth")?.setValue("Thu");
              break;
            }
            case "6L": {
              this.daysForm.get("lastOnMonth")?.setValue("Fri");
              break;
            }
            case "7L": {
              this.daysForm.get("lastOnMonth")?.setValue("Sat");
              break;
            }
          }
        } else if (dayArray.length === 3) {
          if (dayLocal.indexOf("#") !== -1) {
            this.daysForm.get("day")?.setValue("xSpecY");
            this.daysForm.get("xSpecY1")?.setValue(dayArray[2]);
            switch (dayArray[0]) {
              case "1": {
                this.daysForm.get("xSpecY2")?.setValue("Sun");
                break;
              }
              case "2": {
                this.daysForm.get("xSpecY2")?.setValue("Mon");
                break;
              }
              case "3": {
                this.daysForm.get("xSpecY2")?.setValue("Tue");
                break;
              }
              case "4": {
                this.daysForm.get("xSpecY2")?.setValue("Wed");
                break;
              }
              case "5": {
                this.daysForm.get("xSpecY2")?.setValue("Thu");
                break;
              }
              case "6": {
                this.daysForm.get("xSpecY2")?.setValue("Fri");
                break;
              }
              case "7": {
                this.daysForm.get("xSpecY2")?.setValue("Sat");
                break;
              }
            }
          } else {
            this.daysForm.get("day")?.setValue("specificDayWeek");
            let firstUpperCase = dayLocal.toLowerCase();
            firstUpperCase = firstUpperCase.charAt(0).toUpperCase() + firstUpperCase.slice(1);
            this.daysForm.get("specDayWeek")?.setValue(firstUpperCase);
          }
        }
      } else {
        dayLocal = arrayOfValues[3];
        dayArray = dayLocal.split(/[.,\\/ -]/);
        if (dayArray.length === 1) {
          if (dayLocal === "L") {
            this.daysForm.get("day")?.setValue("lastDay");
          } else if (dayLocal === "LW") {
            this.daysForm.get("day")?.setValue("lastWeekDay");
          } else {
            this.daysForm.get("day")?.setValue("specificDay");
            this.daysForm.get("specDay")?.setValue(dayLocal);
          }
        } else if (dayArray.length === 2) {
          if (dayArray[0] === "L") {
            this.daysForm.get("day")?.setValue("xDaysEnd");
            this.daysForm.get("xDaysEndMonth")?.setValue(dayArray[1]);
          } else {
            this.daysForm.get("day")?.setValue("onXDay");
            this.daysForm.get("everyDayStartAt1")?.setValue(dayArray[1]);
            this.daysForm.get("everyDayStartAt2")?.setValue(dayArray[0]);
          }
        }
      }


                  /**************   MONTHS   ***************/
      const monthLocal = arrayOfValues[4];
      const monthArray = monthLocal.split(/[.,\\/ -]/);

      if (monthArray.length === 1) {
        if (monthLocal.indexOf("*") !== -1) {
          this.monthsForm.get("month")?.setValue("everyMonth");
        } else {
          this.monthsForm.get("month")?.setValue("specificMonth");
          let firstUpperCase = monthLocal.toLowerCase();
          firstUpperCase = firstUpperCase.charAt(0).toUpperCase() + firstUpperCase.slice(1);
          this.monthsForm.get("specMonth")?.setValue(firstUpperCase);
        }
      } else {
        if (monthLocal.indexOf("/") !== -1) {
          this.monthsForm.get("month")?.setValue("onXMonth");
          this.monthsForm.get("everyMonthStartAt1")?.setValue(monthArray[1]);
          switch (monthArray[0]) {
            case "1": {
              this.monthsForm.get("everyMonthStartAt2")?.setValue("Jan");
              break;
            }
            case "2": {
              this.monthsForm.get("everyMonthStartAt2")?.setValue("Feb");
              break;
            }
            case "3": {
              this.monthsForm.get("everyMonthStartAt2")?.setValue("Mar");
              break;
            }
            case "4": {
              this.monthsForm.get("everyMonthStartAt2")?.setValue("Apr");
              break;
            }
            case "5": {
              this.monthsForm.get("everyMonthStartAt2")?.setValue("May");
              break;
            }
            case "6": {
              this.monthsForm.get("everyMonthStartAt2")?.setValue("Jun");
              break;
            }
            case "7": {
              this.monthsForm.get("everyMonthStartAt2")?.setValue("Jul");
              break;
            }
            case "8": {
              this.monthsForm.get("everyMonthStartAt2")?.setValue("Aug");
              break;
            }
            case "9": {
              this.monthsForm.get("everyMonthStartAt2")?.setValue("Sep");
              break;
            }
            case "10": {
              this.monthsForm.get("everyMonthStartAt2")?.setValue("Oct");
              break;
            }
            case "11": {
              this.monthsForm.get("everyMonthStartAt2")?.setValue("Nov");
              break;
            }
            case "12": {
              this.monthsForm.get("everyMonthStartAt2")?.setValue("Dec");
              break;
            }
          }
        } else {
          this.monthsForm.get("month")?.setValue("betweenXandYMonth");
          switch (monthArray[0]) {
            case "1": {
              this.monthsForm.get("everyMonthBetw1")?.setValue("Jan");
              break;
            }
            case "2": {
              this.monthsForm.get("everyMonthBetw1")?.setValue("Feb");
              break;
            }
            case "3": {
              this.monthsForm.get("everyMonthBetw1")?.setValue("Mar");
              break;
            }
            case "4": {
              this.monthsForm.get("everyMonthBetw1")?.setValue("Apr");
              break;
            }
            case "5": {
              this.monthsForm.get("everyMonthBetw1")?.setValue("May");
              break;
            }
            case "6": {
              this.monthsForm.get("everyMonthBetw1")?.setValue("Jun");
              break;
            }
            case "7": {
              this.monthsForm.get("everyMonthBetw1")?.setValue("Jul");
              break;
            }
            case "8": {
              this.monthsForm.get("everyMonthBetw1")?.setValue("Aug");
              break;
            }
            case "9": {
              this.monthsForm.get("everyMonthBetw1")?.setValue("Sep");
              break;
            }
            case "10": {
              this.monthsForm.get("everyMonthBetw1")?.setValue("Oct");
              break;
            }
            case "11": {
              this.monthsForm.get("everyMonthBetw1")?.setValue("Nov");
              break;
            }
            case "12": {
              this.monthsForm.get("everyMonthBetw1")?.setValue("Dec");
              break;
            }
          }
          switch (monthArray[1]) {
            case "1": {
              this.monthsForm.get("everyMonthBetw2")?.setValue("Jan");
              break;
            }
            case "2": {
              this.monthsForm.get("everyMonthBetw2")?.setValue("Feb");
              break;
            }
            case "3": {
              this.monthsForm.get("everyMonthBetw2")?.setValue("Mar");
              break;
            }
            case "4": {
              this.monthsForm.get("everyMonthBetw2")?.setValue("Apr");
              break;
            }
            case "5": {
              this.monthsForm.get("everyMonthBetw2")?.setValue("May");
              break;
            }
            case "6": {
              this.monthsForm.get("everyMonthBetw2")?.setValue("Jun");
              break;
            }
            case "7": {
              this.monthsForm.get("everyMonthBetw2")?.setValue("Jul");
              break;
            }
            case "8": {
              this.monthsForm.get("everyMonthBetw2")?.setValue("Aug");
              break;
            }
            case "9": {
              this.monthsForm.get("everyMonthBetw2")?.setValue("Sep");
              break;
            }
            case "10": {
              this.monthsForm.get("everyMonthBetw2")?.setValue("Oct");
              break;
            }
            case "11": {
              this.monthsForm.get("everyMonthBetw2")?.setValue("Nov");
              break;
            }
            case "12": {
              this.monthsForm.get("everyMonthBetw2")?.setValue("Dec");
              break;
            }
          }
        }
      }

                  /**************   YEARS   **************/
      const yearLocal = arrayOfValues[6];
      let yearArray: any;
      yearArray = yearLocal.split(/[.,\\/ -]/);

      if (yearArray.length === 1) {
        if (yearLocal.indexOf("*") !== -1) {
          this.yearsForm.get("year")?.setValue("everyYear");
        } else {
          this.yearsForm.get("year")?.setValue("specificYear");
          this.yearsForm.get("specYear")?.setValue(yearArray[0]);
        }
      } else {
        if (yearLocal.indexOf("/") !== -1) {
          this.yearsForm.get("year")?.setValue("onXYear");
          this.yearsForm.get("everyYearStartAt1")?.setValue(yearArray[1]);
          this.yearsForm.get("everyYearStartAt2")?.setValue(yearArray[0]);
        } else {
          this.yearsForm.get("year")?.setValue("betweenXandYYear");
          this.yearsForm.get("everyYearBetw1")?.setValue(yearArray[0]);
          this.yearsForm.get("everyYearBetw2")?.setValue(yearArray[1]);
        }
      }

    }
  }
}
