import { McMyBillingStatementProfilePageComponent } from './pages/mc-my-billing-statement-profile-page/mc-my-billing-statement-profile-page.component';
import { McMyBillingStatementListPageComponent } from './pages/mc-my-billing-statement-list-page/mc-my-billing-statement-list-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McMyBillingRoutingModule } from './mc-my-billing-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {McFilterModule, McTableModule} from '@miticon-ui/mc-components';
import {TranslateModule} from "@ngx-translate/core";
import {AppSharedModule} from '../app-shared/app-shared.module';

@NgModule({
  declarations: [
McMyBillingStatementProfilePageComponent,
McMyBillingStatementListPageComponent,
  ],
    imports: [
        CommonModule,
        McMyBillingRoutingModule,
        NgbModule,
        McComponentsModule,
        McFilterModule,
        McTableModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AppSharedModule,
    ],
  exports: [
McMyBillingStatementProfilePageComponent,
McMyBillingStatementListPageComponent,
    McMyBillingRoutingModule,
    FormsModule,

  ]
})
export class McMyBillingModule { }
