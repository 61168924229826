import {McProcessFlowFilterGENERATED} from '../_generated/mc-process-flow-filter-generated.model';

export class McProcessFlowFilter extends McProcessFlowFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessFlowFilter();
  }

  // ---------------------------------------------------------------------
}
