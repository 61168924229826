/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {PpPriceAdjustmentFilter} from '../models/pp-price-adjustment-filter.model';

export class PpPriceAdjustmentFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): PpPriceAdjustmentFilter {
    console.error('MTCN-ERROR: Not Implemented: PpPriceAdjustmentFilter::createNewInstance(). Add this method to final class and return new PpPriceAdjustmentFilter();');
      throw new Error('Cannot PpPriceAdjustmentFilter::createNewInstance(). Add this method to final class and return new PpPriceAdjustmentFilter();');
  }

}
