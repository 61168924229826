import { Component, OnInit } from '@angular/core';
import {BaseDirective, EbPsd2Report, EbPsd2ReportFilter, EbPsd2ReportList, EbPsd2ReportService, McBoolean, McDateUtils, McGod} from "@miticon-ui/mc-core";
import {MkFilterConfig, MkFilterItemType, MkFilterOutput, MkMatTableMenuAction, MkTableConfig} from "@miticon-ui/mc-components";
import {HttpParams} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'lib-eb-bank-module-psd2-statement-list-page',
  templateUrl: './eb-bank-module-psd2-statement-list-page.component.html',
  styleUrls: ['./eb-bank-module-psd2-statement-list-page.component.css']
})
export class EbBankModulePsd2StatementListPageComponent extends BaseDirective implements OnInit {

  showImportPsd2Mcb = new McBoolean();
  isLoading = false;
  ebPsd2ReportList = new EbPsd2ReportList();
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  ebPsd2ReportService = new EbPsd2ReportService();
  ebPsd2ReportFilter = new EbPsd2ReportFilter();

  constructor(private toastr: ToastrService) {
    super();
  }

  ngOnInit(): void {
    this.initTableConfig();
    this.initFilterConfig();

    this.actLoad(0, 100, new EbPsd2ReportFilter());
  }

  actLoad(pageNumber: number, pageSize: number, filters: EbPsd2ReportFilter) {
    this.isLoading = true;
    this.ebPsd2ReportList.pageNum = pageNumber;
    this.ebPsd2ReportList.pageItemsPerPageCount = pageSize;

    let params = new HttpParams()
      .set('term', filters.searchTerm ? filters.searchTerm : '')
      .set('dateFrom', filters.dateFrom ? McDateUtils.formatDateToString(filters.dateFrom, EbPsd2Report.FILTER_DATE_FORMAT): '')
      .set('dateTo', filters.dateTo ? McDateUtils.formatDateToString(filters.dateTo, EbPsd2Report.FILTER_DATE_FORMAT): '')
      .set('page', pageNumber)
      .set('size', pageSize);

    this.ebPsd2ReportService.get(params, 1, 50, this.ebPsd2ReportList.sortCriteria)
      .subscribe((res) => {
        this.ebPsd2ReportList.apiSuccessFlg = true
        this.ebPsd2ReportList.items = res.content.map((object: any) => EbPsd2Report.createFromJson(object));
        this.ebPsd2ReportList.totalItemsCount = res.totalElements;
        this.isLoading = false;
      })
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('cc.psd2.internal-id'), 'id');
    this.tableConfig.addColumnStandard(McGod.t('cc.psd2.date-from'), 'fldDateFrom()');
    this.tableConfig.addColumnStandard(McGod.t('cc.psd2.date-to'), 'fldDateTo()');
    this.tableConfig.addColumnStandard(McGod.t('cc.psd2.bank-name'), 'bankName');
    this.tableConfig.addColumnStandard(McGod.t('cc.bank.withdrawn-total-matched-count'), 'fldWithdrawnTotalMatchedCount()');
    this.tableConfig.addColumnStandard(McGod.t('cc.bank.withdrawn-total-matched-amount'), 'fldWithdrawnTotalMatchedAmount()');
    this.tableConfig.addColumnStandard(McGod.t('cc.bank.returned-total-matched-count'), 'fldReturnedTotalMatchedCount()');
    this.tableConfig.addColumnStandard(McGod.t('cc.bank.returned-total-matched-amount'), 'fldReturnedTotalMatchedAmount()');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.status'), 'fldPsd2ReportStatus()');
  }

  initFilterConfig(){
    this.filterConfig.addItem(MkFilterItemType.RANGE_DATEPICKER, McGod.t(EbPsd2Report.FILTER_IMPORT_DATE));
  }

  onFilterChanged(filters: MkFilterOutput) {
    this.ebPsd2ReportFilter.searchTerm = filters.search
    this.ebPsd2ReportFilter.dateFrom = filters.selections[McGod.t(EbPsd2Report.FILTER_IMPORT_DATE)]?.startDate;
    this.ebPsd2ReportFilter.dateTo = filters.selections[McGod.t(EbPsd2Report.FILTER_IMPORT_DATE)]?.endDate;

    this.ebPsd2ReportList.setSortB(filters.sort.sortProperty, filters.sort.sortType);
    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize, this.ebPsd2ReportFilter);
  }

  actPsd2StatementImported() {
    this.showImportPsd2Mcb.setFalse();
    this.actLoad(0, 100, this.ebPsd2ReportFilter);
    this.toastr.success(McGod.t('cc.psd2.report-successfully-imported'));
  }

  //todo implement download functionality
  onMkMatMenuItemsAction(event: MkMatTableMenuAction){}
}
