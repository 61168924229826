/*BbmTestObj*/
import {ImDataSetAttributeGENERATED} from '../_generated/im-data-set-attribute-generated.model';

export class ImDataSetAttribute extends ImDataSetAttributeGENERATED {

  public static reqEmgAttributes = ['emgName', 'emgLastName', 'emgPhoneNumber',
    'emgRelationCd', 'emgCity', 'emgCountry', 'emgEmail', 'emgPostCode', 'emgStreet' ];

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataSetAttribute();
  }

  // ---------------------------------------------------------------------
}
