<div class="content-wrapper">

  <lib-mc-loader [showLoader]="mcChildEntityList.apiLoadingFlg"></lib-mc-loader>

  <div class="content-header">
    <div class="stepper-progress-wrap">
      <div class="stepper-progress">
        <div class="step-count">{{'cc.pricelist.step' | translate}} 2/3</div>
        <div class="step-done">{{'cc.pricelist.select-template' | translate}}</div>
        <div class="step-done">{{'cc.pricelist.prices-and-entities' | translate}}</div>
        <div class="step-not-done">{{'cc.pricelist.review-and-confirm' | translate}}</div>
      </div>
    </div>
    <h2>{{'cc.pricelist.create-new-pricelist' | translate}}</h2>
  </div>

  <div *ngIf="entitiesLoadedFlg" class="content-body">

    <div class="info-summary-header-lite">
      <h4>Entities</h4>
    </div>

    <div class="d-flex">
      <div class="col-6 mt-3">
        <div class="tooltip-wider">
        <mcc-fi-multiselect2 [mcForm]="mcForm" [label]="mcGod.t('cc.pricelist.entities-pricelist-will-apply-to')"
                             [name]="'ctrlEntitiesPricelist'" [optionVll]="mcChildEntityVll" [valueVll]="entitiesValue" [removeControlFromMcFormOnDestroy]="false"></mcc-fi-multiselect2>
        </div>
      </div>
      <div class="col-6 mt-3">
        <mcc-fi-date-picker
          [label]="mcGod.t('cc.pricelist.valid-from')"
          [removeControlFromMcFormOnDestroy]="false"
          [name]="'ctrlValidFrom'"
          [minDateInput]="tomorrow"
          [valueInput]="validFromValue"
          [validRequiredFlg]="true"
          [mcForm]="mcForm">
        </mcc-fi-date-picker>
      </div>
    </div>

    <div class="info-summary-header-lite mb-3">
      <h4>{{'cc.pricelist.services' | translate}}</h4>
    </div>

    <ng-container *ngIf="loadingWebDomains">
      <div class="w-100" *ngFor="let item of templatePriceList.mcBillingService2PriceListItemList.items; index as i">

        <div class="info-summary-top-lite inner-content d-flex">
          <div class="w-50">
            <div class="service-item-name">
            <mcc-row-title-description title="{{'cc.pricelist.service-name' | translate}}: "
                                       [description]="fldServiceCatalog(item.idBillingService)?.title"></mcc-row-title-description></div>
            <mcc-row-title-description title="{{'cc.pricelist.activity-unit' | translate}}: "[description]="fldUnitType(item.activityUnitTypeCd)"></mcc-row-title-description>
          </div>

          <div class="w-50">
            <mcc-row-title-description title="{{'cc.pricelist.service-description' | translate}}: "
                                       [description]="fldServiceCatalog(item.idBillingService)?.description"></mcc-row-title-description>
          </div>
        </div>

        <div *ngIf="!fldServiceCatalog(item.idBillingService)?.flgPriceFromContract" class="w-100">
          <mc-pricelist-pricelist-service-edit-part [mcForm]="mcForm" [index]="i" [defaultService2PriceList]="defaultTemplatePriceList.mcBillingService2PriceListItemList.items[i]"
                                                    [service2PriceList]="item" [currency]="currencyCode" [areServiceButtonsDisabled]="true" [customizeFlg]="true"></mc-pricelist-pricelist-service-edit-part>
        </div>

      </div>
    </ng-container>

  </div>

  <div class="content-footer">
    <mcc-button extraCssClasses="btn btn-secondary"
                (eventClicked)="actPrev()" label="<i class='fas fa-arrow-left'></i> Prev"></mcc-button>
    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel()"
                label="{{'cc.common.edit.cancel' | translate}}"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actNext()" label="{{'cc.common.view.next' | translate}} <i class='fas fa-arrow-right'></i>"></mcc-button>
  </div>

</div>
