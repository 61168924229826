
import {Table3Config} from '../extra/table-3-config';

// import {McDashboardGENERATED} from '../_generated/mc-dashboard-generated.model';

export class McDashboardTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colActiveConsumers', 'Number of active consumers', 'activeConsumers', 'activeConsumers');
    this.addColumn('colAutomaticallyMatchedTransactions', 'Number of automatically matched transactions', 'automaticallyMatchedTransactions', 'automaticallyMatchedTransactions');
    this.addColumn('colConsumersWithBalance', 'Number of consumers with balance', 'consumersWithBalance', 'consumersWithBalance');
    this.addColumn('colEmailsSentChild', 'Number of sent emails by child', 'emailsSentChild', 'emailsSentChild');
    this.addColumn('colEmailsSentParent', 'Number of sent emails for parent', 'emailsSentParent', 'emailsSentParent');
    this.addColumn('colInactiveConsumers', 'Number of inactive consumers', 'inactiveConsumers', 'inactiveConsumers');
    this.addColumn('colIsChildOnly', 'Is logged user child only', 'isChildOnly', 'isChildOnly');
    this.addColumn('colManuallyMatchedTransactions', 'Number of manually matched transactions', 'manuallyMatchedTransactions', 'manuallyMatchedTransactions');
    this.addColumn('colManualMatchingNeededTransactions', 'Number of manual matching needed for transactions', 'manualMatchingNeededTransactions', 'manualMatchingNeededTransactions');
    this.addColumn('colOverdueTransactions', 'Number of overdue transactions', 'overdueTransactions', 'overdueTransactions');
    this.addColumn('colPaidTransactions', 'Number of paid transactions', 'paidTransactions', 'paidTransactions');
    this.addColumn('colPdfGenerated', 'Number of pdf generated', 'pdfGenerated', 'pdfGenerated');
    this.addColumn('colRejectedTransactions', 'Number of rejected transactions', 'rejectedTransactions', 'rejectedTransactions');
    this.addColumn('colSentToInkassoTransactions', 'Number of sent to inkasso transactions', 'sentToInkassoTransactions', 'sentToInkassoTransactions');
    this.addColumn('colSentTransactions', 'Number of sent transactions', 'sentTransactions', 'sentTransactions');
    this.addColumn('colToBeSentTransactions', 'Number of to be sent transactions', 'toBeSentTransactions', 'toBeSentTransactions');

*/
  }
}
