import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
  McConsumerBalanceFilter,
  McConsumerBalanceList,
  McConsumerBalanceTable3Config,
  McValueLabelList,
  McConsumerBalanceService, CurrencyUtil, EbFactoringService
} from '@miticon-ui/mc-core';
import {Subject} from "rxjs";

@Component({
  selector: 'app-shared-consumer-balance-part',
  templateUrl: './app-shared-consumer-balance-part.component.html',
  styleUrls: ['./app-shared-consumer-balance-part.component.scss']
})
export class AppSharedConsumerBalancePartComponent implements OnInit {

  /*Variables*/
  entityVll = new McValueLabelList();
  clearedAllMultipleFilters = new Subject<boolean>();
  mcConsumerBalanceList = new McConsumerBalanceList();
  mcConsumerBalanceTable3Config = new McConsumerBalanceTable3Config();
  mcConsumerBalanceFilter = new McConsumerBalanceFilter();
  entityIds: any[] = [];


  @Output() eventCanceled = new EventEmitter();

  constructor(private mcConsumerBalanceService: McConsumerBalanceService, private factoringService: EbFactoringService) {
  }

  ngOnInit() {
    this.actLoad();
  }

  actClearFilter() {
    this.clearedAllMultipleFilters.next(true);
  }

  actLoad() {
    this.mcConsumerBalanceFilter.entityIds = this.entityIds;
    this.mcConsumerBalanceList.loadByFilter(this.mcConsumerBalanceFilter, () => {
      this.mcConsumerBalanceList.items = this.mcConsumerBalanceList.items.filter(item => item.amount !== 0);
      this.mcConsumerBalanceList.items.map(item => this.entityVll.add(item.entityId, item.entityName));
      this.mcConsumerBalanceList.items.map(item => item.balanceAmountFormatted = CurrencyUtil.format(item.amount,
        this.factoringService.activeChildFactoring.mcCurrency.code));
      this.entityVll.items = this.getUniqueValueLabelList(this.entityVll);
      this.entityVll.items.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
    });
  }

  getUniqueValueLabelList(list: any) {
    const uniqueListItems = list.items
      .map((e: any) => e.value)
      .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)
      .filter((obj: any) => list.items[obj])
      .map((e:any) => list.items[e]);
    return uniqueListItems;
  }

  onSelectEntities(value: any[]) {
    if (value[0] === '') {
      this.entityIds = value.splice(0, 1);
    }
    this.entityIds = value;
  }

  actCancel() {
    this.eventCanceled.emit();
  }
}
