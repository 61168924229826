import {McProductFilterGENERATED} from '../_generated/mc-product-filter-generated.model';

export class McProductFilter extends McProductFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProductFilter();
  }

  // ---------------------------------------------------------------------
}
