
import {Table3Config} from '../extra/table-3-config';

// import {EbStarmoneyFileGENERATED} from '../_generated/eb-starmoney-file-generated.model';

export class EbStarmoneyFileTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colCreatedAt', 'Created at', 'createdAt', 'createdAt');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdMcSystemUser', 'System user ID', 'idMcSystemUser', 'idMcSystemUser');
    this.addColumn('colIdWebFile', 'Webfile ID', 'idWebFile', 'idWebFile');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colUpdatedAt', 'Updated at', 'updatedAt', 'updatedAt');

*/
  }
}
