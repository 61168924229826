import { NgModule } from '@angular/core';
import { McCoreComponent } from './mc-core.component';
import { McPermissionDirective } from './directives/mc-permission.directive';
import { McDetectChangesDirective } from './directives/mc-detect-changes.directive';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {McGod} from './extra/mc-god.service';
import {McdPermissionsDirective} from './directives/mcd-permissions.directive';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BaseDirective} from './directives/base.directive';

@NgModule({
  declarations: [McCoreComponent, McPermissionDirective, McdPermissionsDirective, McDetectChangesDirective, BaseDirective],
  imports: [
    TranslateModule, HttpClientModule, NgbModule
  ],
  exports: [McCoreComponent, McPermissionDirective, McdPermissionsDirective, McDetectChangesDirective, BaseDirective],

})
export class McCoreModule {
   constructor(public mcGod: McGod) {

   }
}
