import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ImDataImportRoutingModule } from './im-data-import-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {McFilterModule, McTableModule} from '@miticon-ui/mc-components';
import { DataImportListPageComponent } from './pages/data-import-list-page/data-import-list-page.component';
import {TranslateModule} from "@ngx-translate/core";
import {SafeHtmlModule} from "../../assets/pipes/safe-html/safe-html.module";
import { DataImportViewDialogComponent } from './pages/data-import-view-dialog/data-import-view-dialog.component';
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import { DataImportAddComponent } from './pages/data-import-add/data-import-add.component';
import { DataImportAddUploadStepComponent } from './pages/data-import-add/data-import-add-upload-step/data-import-add-upload-step.component';
import { DataImportAddMappingStepComponent } from './pages/data-import-add/data-import-add-mapping-step/data-import-add-mapping-step.component';
import { DataImportAddImportStepComponent } from './pages/data-import-add/data-import-add-import-step/data-import-add-import-step.component';
import {MatStepperModule} from "@angular/material/stepper";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { DataImportMappingTemplatesDialogComponent } from './pages/data-import-add/data-import-add-mapping-step/data-import-mapping-templates-dialog/data-import-mapping-templates-dialog.component';
import { DataImportAttributeAddDialogComponent } from './pages/data-import-add/data-import-add-mapping-step/data-import-attribute-add-dialog/data-import-attribute-add-dialog.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatRadioModule} from "@angular/material/radio";
import {AppSharedModule} from "../app-shared/app-shared.module";
import {MatDatepickerModule} from "@angular/material/datepicker";
import { DataImportInfoDialogComponent } from './components/data-import-info-dialog/data-import-info-dialog.component';
import { DataExportListPageComponent } from './pages/data-export-list-page/data-export-list-page.component';
import { DataExportAddComponent } from './pages/data-export-add/data-export-add.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";

@NgModule({
  declarations: [
    DataImportListPageComponent,
    DataImportViewDialogComponent,
    DataImportAddComponent,
    DataImportAddUploadStepComponent,
    DataImportAddMappingStepComponent,
    DataImportAddImportStepComponent,
    DataImportMappingTemplatesDialogComponent,
    DataImportAttributeAddDialogComponent,
    DataImportInfoDialogComponent,
    DataExportListPageComponent,
    DataExportAddComponent
  ],
  imports: [
    CommonModule,
    ImDataImportRoutingModule,
    NgbModule,
    McComponentsModule,
    McFilterModule,
    McTableModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SafeHtmlModule,
    SafeHtmlModule,
    MatButtonModule,
    MatDialogModule,
    MatStepperModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatRadioModule,
    AppSharedModule,
    MatDatepickerModule,
    MatProgressBarModule,
  ],
  exports: [
    ImDataImportRoutingModule,
    FormsModule,
    DataImportListPageComponent
  ],
  providers: [DatePipe]

})
export class ImDataImportModule { }
