import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CsrConsumer, McDateUtils, McEntityConsumerAttribute, McGod} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-consumer-consumer-360-profile-part',
  templateUrl: './mc-consumer-consumer-360-profile-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-profile-part.scss']
})
export class McConsumerConsumer360ProfilePartComponent implements OnInit {

  csrConsumer = new CsrConsumer();

  constructor(
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.actLoad();
  }

  actLoad() {
    this.route.paramMap.subscribe((res) => {
      this.csrConsumer.apiLoadingFlg = true
        // @ts-ignore
      this.csrConsumer.loadById(+res.get('id'), () => {
          this.csrConsumer.apiLoadingFlg = false;
        });
    });
  }

  getCountryName(countryCode: string): string {
    return CsrConsumer.getNameFromList(countryCode, CsrConsumer.ISO_COUNTRIES);
  }

  getLanguageName(languageCode: string): string {
    return CsrConsumer.getNameFromList(languageCode, CsrConsumer.ISO_LANGUAGES);
  }
}


