import {Inject, Injectable} from '@angular/core';
import {EbStarmoneyFileServiceGENERATED} from '../_generated/eb-starmoney-file-generated.service';


@Injectable({
  providedIn: 'root'
})
export class EbStarmoneyFileService extends EbStarmoneyFileServiceGENERATED {


}
