<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcProcessFlowLevel.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
      <h1 *ngIf="mcProcessFlowLevel.exists()">Edit McProcessFlowLevel (#{{mcProcessFlowLevel.id}})</h1>
    <h1 *ngIf="!mcProcessFlowLevel.exists()">Add McProcessFlowLevel (#{{mcProcessFlowLevel.id}})</h1>

  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!mcProcessFlowLevel.apiLoadingFlg" class="content-body">
    <mcc-message [errorMsg]="mcProcessFlowLevel.apiErrorMessage"></mcc-message>
    <mcc-message [errorMsgs]="errorMsgs" label="Unable to proceed: "></mcc-message>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container *ngIf="(mcProcessFlowLevel.apiSuccessFlg) && (!hasErrors())">

        <div class="row">
          <div class="col-md-12">
            <div class="content-section">
              <h2>Information.</h2>

                    
<mcc-fi-textbox name="ctrlIdMcProcessFlow" label="McProcessFlow ID"
        [value]="mcProcessFlowLevel.idMcProcessFlow"         [validRequiredFlg]="true"
        [mcForm]="mcForm">
</mcc-fi-textbox>
    
<mcc-fi-textbox name="ctrlTitle" label="Level title"
        [value]="mcProcessFlowLevel.title"         [validRequiredFlg]="true"
        [mcForm]="mcForm">
</mcc-fi-textbox>
    
<mcc-fi-textbox name="ctrlDescription" label="Description"
        [value]="mcProcessFlowLevel.description"         [validRequiredFlg]="true"
        [mcForm]="mcForm">
</mcc-fi-textbox>

              <mcc-fi-textbox name="ctrlPdfTemplate" label="Email pdf template ID"
                              [value]="mcProcessFlowLevel.idPdfTemplate"         [validRequiredFlg]="true"
                              [mcForm]="mcForm">
              </mcc-fi-textbox>
    
<mcc-fi-textbox name="ctrlEmailActionFlg" label="Email action flag"
        [value]="mcProcessFlowLevel.emailActionFlg"         [validRequiredFlg]="true"
        [mcForm]="mcForm">
</mcc-fi-textbox>
    
<mcc-fi-textbox name="ctrlEmailPaymentQrCodeFlg" label="Email payment QR code flag"
        [value]="mcProcessFlowLevel.emailPaymentQrCodeFlg"         [validRequiredFlg]="true"
        [mcForm]="mcForm">
</mcc-fi-textbox>

    
<mcc-fi-textbox name="ctrlEmailSubjectTokened" label="Email subject tokened"
        [value]="mcProcessFlowLevel.emailSubjectTokened"         [validRequiredFlg]="true"
        [mcForm]="mcForm">
</mcc-fi-textbox>
    
<mcc-fi-textbox name="ctrlEmailBodyTokened" label="Email body tokened"
        [value]="mcProcessFlowLevel.emailBodyTokened"         [validRequiredFlg]="true"
        [mcForm]="mcForm">
</mcc-fi-textbox>
    
<mcc-fi-textbox name="ctrlPostActionFlg" label="Post action flag"
        [value]="mcProcessFlowLevel.postActionFlg"         [validRequiredFlg]="true"
        [mcForm]="mcForm">
</mcc-fi-textbox>
    
<mcc-fi-textbox name="ctrlPostPaymentQrCodeFlg" label="Post payment QR code flag"
        [value]="mcProcessFlowLevel.postPaymentQrCodeFlg"         [validRequiredFlg]="true"
        [mcForm]="mcForm">
</mcc-fi-textbox>

    
<mcc-fi-textbox name="ctrlSmsActionFlg" label="SMS action flag"
        [value]="mcProcessFlowLevel.smsActionFlg"         [validRequiredFlg]="true"
        [mcForm]="mcForm">
</mcc-fi-textbox>
    
<mcc-fi-textbox name="ctrlSmsBodyTokened" label="SMS body tokened"
        [value]="mcProcessFlowLevel.smsBodyTokened"         [validRequiredFlg]="true"
        [mcForm]="mcForm">
</mcc-fi-textbox>
    
<mcc-fi-textbox name="ctrlNoDaysPaymentDeadlineAfterReminderIssue" label="Number of days payment deadline after reminder issue date"
        [value]="mcProcessFlowLevel.noDaysPaymentDeadlineAfterReminderIssue"         [validRequiredFlg]="true"
        [mcForm]="mcForm">
</mcc-fi-textbox>
    
<mcc-fi-textbox name="ctrlNoDaysWaitingPeriodAfterPaymentDeadline" label="Number of days waiting period after payment deadline"
        [value]="mcProcessFlowLevel.noDaysWaitingPeriodAfterPaymentDeadline"         [validRequiredFlg]="true"
        [mcForm]="mcForm">
</mcc-fi-textbox>


            </div>
          </div>
        </div><!-- row -->
    </ng-container>




    <!-- == FOOTER ======================================== -->
      <!--THIS CONTAINER IS MANDATORY-->
      <div class="content-footer">

        <mcc-button  extraCssClasses="btn btn-secondary move-right" (eventClicked)="actCancel();" label="Cancel"></mcc-button>
        <mcc-button  *ngIf="(mcProcessFlowLevel.apiSuccessFlg) && (!hasErrors())" extraCssClasses="btn btn-primary" (eventClicked)="actSave();" label="Save" [disabledMcb]="saveButtonDisabledMcb" disableOnFirstClickFlg="true"></mcc-button>
      </div>


  </div><!-- content-body -->

</div>
<router-outlet></router-outlet>
