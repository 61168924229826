<div *ngIf="!parentData" class="col-md-12 mc-stepper-header-title">
  <span class="pl-4 pb-1 mr-2" [ngClass]="basicInfoTab ? 'mc-active': ''">
    <span class="mc-notification-badge-gray" [ngClass]="basicInfoTab ? 'mc-active-background': ''">1</span>
    {{ 'cc.common.view.basic-info' | translate }}
  </span>
  <span class="pl-4 pb-1 ml-4 mr-2">
    <span class="mc-notification-badge-gray">2</span>
      {{ 'cc.common.view.attributes' | translate }}
  </span>
  <span class="pl-4 pb-1 ml-4 mr-2">
    <span class="mc-notification-badge-gray">3</span>
      Password policy
  </span>
  <span class="pl-4 pb-1 ml-4 mr-2">
    <span class="mc-notification-badge-gray">4</span>
      Pricelist
  </span>
</div>

<div class="row mc-entity-basic-info gray-background">
  <form [formGroup]="entityBasicInfoForm" class="entity-basic-info-form col-md-12">
    <div class="mc-notify-wrapper-basic-info">
      <mc-notify-message [message]="message" [type]="type"></mc-notify-message>
    </div>
    <!--Status-->
    <div class="row mb-2">
      <div class="col-md-12 gender">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-2 pl-0 mc-entity-label">
              Status:
            </div>
            <div class="col-md-10">
              <span class="mr-4 form-group d-inline">
                <input type="radio" id="activeEntity" [value]="true" name="status" formControlName="status">
                <label for="activeEntity">{{ 'cc.common.view.active' | translate }}</label>
              </span>
              <span class="mr-4 form-group d-inline">
                <input type="radio" id="inactiveEntity" [value]="false" name="status" formControlName="status">
                <label for="inactiveEntity">{{'cc.common.view.inactive' | translate}}</label>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Entity image-->
    <div class="row mb-1">
      <div class="col-md-12">
        <span class="mc-entity-label mr-2">Image <i class="required-input"></i></span>
        <img *ngIf="imageUrl" [src]="imageUrl" class="entity-image" alt="Entity image">
        <input type="file" accept="image/*" class="hidden-input" (change)="onFileChanged($event);isFormValid()">
        <input type="hidden" name="fileHidden" formControlName="image"/> <!-- Validation Field -->
        <span class="upload-img">{{ 'cc.common.edit.upload-image' | translate }}</span>
      </div>
    </div>
    <div class="row">
      <!--Country-->
      <div class="col-md-6">
        <div class="form-group mb-1">
          <label for="countryId" class="label-position mc-entity-label">{{'cc.users.edit.country' | translate}} <i class="required-input">*</i></label>
          <select  id="countryId" name="countryId" class="form-control" formControlName="countryId" (ngModelChange)="isFormValid()" [ngClass]="{'is-invalid' : this.entityBasicInfoForm.get('countryId')?.touched && this.entityBasicInfoForm.get('countryId')?.invalid}" required>
            <option *ngFor="let item of mcCountryList.items" [value]="item.id">{{item.name}}</option>
          </select>
        </div>
      </div>
      <!--Web domain-->
      <div class="col-md-6">
        <div class="form-group mb-1">
          <label for="webDomain" class="label-position mc-entity-label">{{ 'cc.common.edit.web-domain' | translate }} <i class="required-input">*</i></label>
          <input type="text"
                 name="webDomain"
                 class="form-control"
                 [ngClass]="{'is-invalid' : (entityBasicInfoForm.get('webDomain')?.touched && entityBasicInfoForm.get('webDomain')?.invalid) || invalidWebDomain === true  || existWebDomain === true}"
                 id="webDomain"
                 formControlName="webDomain"
                 #webDomain
                 (ngModelChange)="isFormValid();checkExistWebDomains(webDomain.value)"
                 required>
        </div>
      </div>

    </div>
    <div class="row">
      <!--Name-->
      <div class="col-md-6">
        <div class="form-group mb-1">
          <label for="name" class="label-position mc-entity-label">{{'cc.common.view.name' | translate}} <i class="required-input">*</i></label>
          <input type="text"
                 name="name"
                 class="form-control"
                 [ngClass]="{'is-invalid' : (entityBasicInfoForm.get('name')?.touched && entityBasicInfoForm.get('name')?.invalid) || existEntityName === true}"
                 id="name"
                 formControlName="name"
                 #entityName
                 (ngModelChange)="isFormValid();checkExistNames(entityName.value)"
                 required>
        </div>
      </div>
      <!--Mail domain-->
      <div class="col-md-6">
        <div class="form-group mb-1">
          <label for="mail" class="label-position mc-entity-label">{{ 'cc.common.edit.mail-domain' | translate }}<i class="required-input">*</i></label>
          <input type="text"
                 name="mail"
                 class="form-control"
                 [ngClass]="{'is-invalid' : entityBasicInfoForm.get('mail')?.touched && entityBasicInfoForm.get('mail')?.invalid || invalidMail === true}"
                 id="mail"
                 formControlName="mail"
                 (ngModelChange)="isFormValid()"
                 required>
        </div>
      </div>
    </div>
    <div class="row">
      <!--Abbreviation-->
      <div class="col-md-6">
        <div class="form-group mb-1">
          <label for="abbreviation" class="label-position mc-entity-label">Abbreviation <i class="required-input">*</i></label>
          <input type="text"
                 name="abbreviation"
                 class="form-control"
                 [ngClass]="{'is-invalid' : entityBasicInfoForm.get('abbreviation')?.touched && entityBasicInfoForm.get('abbreviation')?.invalid}"
                 id="abbreviation"
                 formControlName="abbreviation"
                 (ngModelChange)="isFormValid()"
                 required>
        </div>
      </div>
      <!--IP whitelist-->
      <div class="col-md-6">
        <div class="form-group hide-margin">
          <label for="ip" class="label-position mc-entity-label">{{ 'cc.common.edit.ip-whitelist' | translate }}</label>
          <textarea type="text"
                    name="IP"
                    class="form-control"
                    [ngClass]="{'is-invalid' : ipAddress.value !== '' && this.invalidIP === true}"
                    id="ip"
                    rows="2"
                    formControlName="IP"
                    #ipAddress
                    (ngModelChange)="isIpAddressValid(ipAddress.value)">
             </textarea>
        </div>
        <span class="gray-text-info">{{ 'cc.common.edit.enter-ip-addresses-and-separate-them-with-comma' | translate }}</span>
      </div>
    </div>
    <div class="row">
      <!--Description-->
      <div class="col-md-6">
        <div class="form-group mb-1">
          <label for="description" class="label-position mc-entity-label">{{ 'cc.common.view.description' | translate }}</label>
          <textarea type="text"
                    name="description"
                    class="form-control"
                    id="description"
                    rows="2"
                    formControlName="description">
            </textarea>
        </div>
      </div>

    </div>
    <br>
    <div class="row">

      <!--Bank transaction entity name override-->
      <div class="col-md-6">
        <div class="form-group mb-0">
          <label for="bankTransactionEntityName" class="label-position mc-entity-label">Bank transaction entity name override</label>
          <input type="text"
                 name="bankTransactionEntityName"
                 class="form-control"
                 (ngModelChange)="isFormValid()"
                 id="bankTransactionEntityName"
                 formControlName="bankTransactionEntityName">
        </div>
      </div>
      <!--VAT number-->
      <div class="col-md-6">
        <div class="form-group mb-1">
          <label for="vatNumber" class="label-position mc-entity-label">{{ 'cc.common.edit.vat-number' | translate }}</label>
          <input type="text"
                 name="vatNumber"
                 class="form-control"
                 [ngClass]="{'is-invalid' : invalidVatNumber === true}"
                 id="vatNumber"
                 formControlName="vatNumber">
        </div>
      </div>
      <div class="col-md-6"></div>
      <!--External Creditor Id-->
      <div class="col-md-6">
        <div class="form-group mb-0">
          <label for="extCreditorId" class="label-position mc-entity-label">{{ 'cc.common.entity.external-creditor-id' | translate }}</label>
          <input type="text"
                 name="extCreditorId"
                 class="form-control"
                 id="extCreditorId"
                 formControlName="extCreditorId">
        </div>
      </div>
    </div>
    <div class="row">
      <!--Phone number-->
      <div class="col-md-6">
        <div class="form-group mb-1">
          <label for="phoneNumber" class="label-position mc-entity-label">{{ 'cc.common.edit.phone-number' | translate }}</label>
          <input type="text"
                 name="phoneNumber"
                 class="form-control"
                 [ngClass]="{'is-invalid' : phoneNumber.value !== '' && this.invalidPhone === true}"
                 id="phoneNumber"
                 formControlName="phoneNumber"
                 #phoneNumber
                 (ngModelChange)="isFormValid()">
        </div>
      </div>
      <!--Impressum-->
      <div class="col-md-6">
        <div class="form-group mb-1">
          <label for="impressum" class="label-position mc-entity-label">{{ 'cc.common.edit.impressum' | translate }}</label>
          <textarea type="text"
                    name="impressum"
                    class="form-control"
                    id="impressum"
                    rows="2"
                    formControlName="impressum">
          </textarea>
        </div>
      </div>
      <!--Fax number-->
      <div class="col-md-6">
        <div class="form-group mb-1">
          <label for="faxNumber" class="label-position mc-entity-label">{{ 'cc.common.edit.fax-number' | translate }}</label>
          <input type="text"
                 name="faxNumber"
                 class="form-control"
                 [ngClass]="{'is-invalid' : faxNumber.value !== '' && this.invalidFax === true}"
                 id="faxNumber"
                 formControlName="faxNumber"
                 #faxNumber
                 (ngModelChange)="isFormValid()">
        </div>
      </div>
    </div>
    <div class="row">
      <!--Address-->
      <div class="col-md-6">
        <div class="form-group mb-1">
          <label for="address" class="label-position mc-entity-label">{{ 'cc.common.edit.address' | translate }}<i class="required-input">*</i></label>
          <textarea type="text"
                    name="address"
                    class="form-control"
                    [ngClass]="{'is-invalid' : (entityBasicInfoForm.get('address')?.touched && entityBasicInfoForm.get('address')?.invalid) }"
                    id="address"
                    rows="2"
                    formControlName="address"
                    #address
                    (ngModelChange)="isFormValid();isAddressValid(address.value);"
                    required>
             </textarea>
        </div>
      </div>
      <!--Studio owner-->
      <div class="col-md-6">
        <div class="form-group mb-0">
          <label for="studioOwner" class="label-position mc-entity-label">Studio owner</label>
          <input type="text"
                 name="studioOwner"
                 class="form-control"
                 (ngModelChange)="isFormValid()"
                 id="studioOwner"
                 formControlName="studioOwner">
        </div>
      </div>
      <div class="col-md-6"></div>
      <!--Studio owner address-->
      <div class="col-md-6">
        <div class="form-group mb-0">
          <label for="studioOwnerAddress" class="label-position mc-entity-label">Studio owner address</label>
          <input type="text"
                 name="studioOwnerAddress"
                 class="form-control"
                 (ngModelChange)="isFormValid()"
                 id="studioOwnerAddress"
                 formControlName="studioOwnerAddress">
        </div>
      </div>


    </div>
    <div class="row">
      <!--Legal representative-->
      <div class="col-md-6">
        <div class="form-group mb-0">
          <label for="legalRepresentative" class="label-position mc-entity-label">{{ 'cc.common.edit.legal-representative' | translate }}</label>
          <input type="text"
                 name="legalRepresentative"
                 class="form-control"
                 id="legalRepresentative"
                 formControlName="legalRepresentative">
        </div>
      </div>
      <!--Studio owner postal code-->
      <div class="col-md-6">
        <div class="form-group mb-0">
          <label for="studioOwnerPostalCode" class="label-position mc-entity-label">Studio owner postal code</label>
          <input type="text"
                 name="studioOwnerPostalCode"
                 class="form-control"
                 (ngModelChange)="isFormValid()"
                 id="studioOwnerPostalCode"
                 formControlName="studioOwnerPostalCode">
        </div>
      </div>

      <!--Legal representative contact-->
      <div class="col-md-6">
        <div class="form-group mb-0">
          <label for="legalRepresentativeContact" class="label-position mc-entity-label">{{ 'cc.common.edit.legal-representative-email' | translate }}</label>
          <input type="email"
                 name="legalRepresentativeContact"
                 class="form-control"
                 [ngClass]="{'is-invalid' : invalidLegalContact === true}"
                 id="legalRepresentativeContact"
                 formControlName="legalRepresentativeContact"
                 (ngModelChange)="isFormValid()">
        </div>
      </div>

      <!--Studio owner city-->
      <div class="col-md-6">
        <div class="form-group mb-0">
          <label for="studioOwnerCity" class="label-position mc-entity-label">Studio owner city</label>
          <input type="text"
                 name="studioOwnerCity"
                 class="form-control"
                 (ngModelChange)="isFormValid()"
                 id="studioOwnerCity"
                 formControlName="studioOwnerCity">
        </div>
      </div>

      <!--Studio owner city-->
      <div class="col-md-6">
        <div class="form-group mb-0">
          <label for="courtInCharge" class="label-position mc-entity-label">Court in charge</label>
          <input type="text"
                 name="courtInCharge"
                 class="form-control"
                 (ngModelChange)="isFormValid()"
                 id="courtInCharge"
                 formControlName="courtInCharge">
        </div>
      </div>

      <!--Studio manager-->
      <div class="col-md-6">
        <div class="form-group mb-0">
          <label for="studioManager" class="label-position mc-entity-label">Studio manager</label>
          <input type="text"
                 name="studioManager"
                 class="form-control"
                 (ngModelChange)="isFormValid()"
                 id="studioManager"
                 formControlName="studioManager">
        </div>
      </div>
    </div>

    <!--Edit basicInfo form-->
    <div *ngIf="isEdit" class="row mt-5">
      <div class="col-md-12">
        <button type="button" class="modal-save mb-4" [ngClass]="isDisabled === false ? 'mc-confirm-btn' : 'mc-disable-btn'" [disabled]="isDisabled" (click)="editEntityBasicInfo()">Save</button>
      </div>
    </div>
  </form>
</div>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
