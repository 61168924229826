import {Component, Inject, OnInit} from '@angular/core';
import {McAuthService} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {

  resetPasswordSubmitted!: boolean;
  welcomeImg: string;
  logo: string;
  email!: string;
  errorObject = {
    message: '',
    type: ''
  };

  /*Constructor*/
  constructor(private authService: McAuthService,
              @Inject('designConfig') design: any) {

    /*Get logo and background from environment*/
    this.welcomeImg = design.welcomeImg;
    this.logo = design.logo;
  }

  /*On init*/
  ngOnInit() {
  }

  /*On reset password*/
  public onResetPassword(): void {
    /*Check if error exists*/
    if (!this.email) {
      this.errorObject = Object.assign({}, {
        message: 'Please fill email input field.',
        type: 'error'
      });
      return;
    }

    /*Validate email string*/
    if (!this.validateEmailField()) {
      this.errorObject = Object.assign({}, {
        message: 'Not valid email.',
        type: 'error'
      });
      return;
    }

    /*API call body*/
    const data = {username: this.email};

    /*Reset password auth method
    * Subscription - show to user info for next step*/
    this.authService.forgotPassword(data)
      .subscribe(response => this.resetPasswordSubmitted = true,
        error => this.resetPasswordSubmitted = true);
  }

  /*Styling email input field on error*/
  public cssClassInputField(): string {
    return this.errorObject.type ? 'mc-alert-error-input' : '';
  }

  /*Validate email field*/
  private validateEmailField(): boolean {
    const retestString = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return retestString.test(String(this.email).toLowerCase());
  }
}
