
import {Table3Config} from '../extra/table-3-config';

// import {WebFileGENERATED} from '../_generated/web-file-generated.model';

export class WebFileTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDelimiter', 'Delimiter', 'delimiter', 'delimiter');
    this.addColumn('colExt', 'Extension', 'ext', 'ext');
    this.addColumn('colFilename', 'Filename', 'filename', 'filename');
    this.addColumn('colIdMcSystemUser', 'User', 'idMcSystemUser', 'idMcSystemUser');
    this.addColumn('colOriginalFilename', 'Original filename', 'originalFilename', 'originalFilename');
    this.addColumn('colPath', 'Path', 'path', 'path');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colTypeCd', 'Type code', 'typeCd', 'typeCd');
    this.addColumn('colUploadDatetime', 'Upload DateTime', 'uploadDatetime', 'uploadDatetime');

*/
  }
}
