import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ICON_CLOSE} from "../../../../../assets/media/svg_icons/icon-close";
import {MkFilterOutput, MkTableConfig} from "@miticon-ui/mc-components";
import {McGod, McProcessService, McEntityIdAndName, McRunItemEmailInfo} from "@miticon-ui/mc-core";

@Component({
  selector: 'lib-mc-job-details-dialog',
  templateUrl: './mc-job-details-dialog.component.html',
  styleUrls: ['./mc-job-details-dialog.component.scss']
})
export class McJobDetailsDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  items = [];
  pageNumber = 0;
  pageSize = 5;
  totalItemsCount = 0;
  isLoading = false;
  tableConfig = new MkTableConfig();
  jobExecId: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {jobExecId: number, title: string, tableType: string, dataType: any},
              public dialogRef: MatDialogRef<McJobDetailsDialogComponent>,
              private mcProcessService: McProcessService) { }

  ngOnInit(): void {
    this.initTableConfig();
    this.jobExecId = this.data.jobExecId;
    this.actLoad(this.pageNumber, this.pageSize);
  }

  actLoad(pageNumber: number, pageSize: number) {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.isLoading = true;
    let action;
    let json: any;

    switch (this.data.dataType) {
      case 'success':
        action = this.mcProcessService.getSuccessRunEntitiesByJobExecutionId(this.jobExecId, this.pageNumber, this.pageSize);
        json = McEntityIdAndName;
        break;
      case 'failed':
        action = this.mcProcessService.getFailedRunEntitiesByJobExecutionId(this.jobExecId, this.pageNumber, this.pageSize);
        json = McEntityIdAndName;
        break;
      case 'skipped':
        action = this.mcProcessService.getSkippedRunItemsByProcessJobExecutionId(this.jobExecId, this.pageNumber, this.pageSize);
        json = McRunItemEmailInfo;
        break;
      case 'error':
        action = this.mcProcessService.getErrorRunItemsByProcessJobExecutionId(this.jobExecId, this.pageNumber, this.pageSize);
        json = McRunItemEmailInfo;
        break;
    }

    action?.subscribe((res) => {
      if (res) {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => object = json.createFromJson(object));
      }
    });
  }

  initTableConfig() {
    if(this.data.tableType && this.data.tableType === 'run') {
      this.tableConfig.addColumnStandard(McGod.t('cc.common.view.entity'), 'name', 250);
      this.tableConfig.addColumnStandard(McGod.t('cc.billing.entity-id'), 'id', 250);
      } else {
      this.tableConfig.addColumnStandard(McGod.t('cc.my-factoring.consumer'), 'csrConsumerName', 250);
      this.tableConfig.addColumnStandard(McGod.t('cc.consumer.view.consumer-id'), 'idCsrConsumer', 250);
      this.tableConfig.addColumnStandard(McGod.t('cc.common.view.entity'), 'mcEntityName', 250);
      this.tableConfig.addColumnStandard(McGod.t('cc.jobs.details.consumer-email'), 'csrConsumerEmail', 250);
      this.tableConfig.addColumnStandard(McGod.t('cc.factoring.export-sepa.status-code'), 'emailStatus', 250);
    }
  }

  onFilterChanged(filters: MkFilterOutput) {
    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize);
  }
}
