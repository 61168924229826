import {McProcessNotification} from './mc-process-notification.model';
import {McProcessNotificationListGENERATED} from '../_generated/mc-process-notification-list-generated.model';

export class McProcessNotificationList extends McProcessNotificationListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new McProcessNotificationList();
  }

  // ---------------------------------------------------------------------
}
