<h3 class="page-title">{{ "cc.contracts-360" | translate}}</h3>

<div class="actions-container">
  <span>{{"cc.contracts-360-list" | translate}}</span>
  <div class="actions-wrapper">
    <button (click)="createContract360()"
            class="system-action-btn mr-2"
            style="gap: 10px">
      <span [innerHTML]="iconAdd | safeHtml"></span>
      {{"cc.create-contract-360" | translate}}
    </button>
  </div>
</div>

<mk-mat-table
  [items]="items"
  [itemsPerPage]="items.length"
  [config]="tableConfig"
  [isLoading]="isLoading"
  [filterConfig]="filterConfig"
  [pageNumber]="pageNumber"
  [pageSize]="pageSize"
  [searchTooltip]="searchToolTip"
  (filterChanged)="onFilterChanged($event)"
  [totalItemsCount]="totalItemsCount"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
></mk-mat-table>
