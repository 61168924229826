<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcProcessFlow.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h1
      *ngIf="mcProcessFlow.exists()">{{'cc.common.view.edit' | translate}} {{'cc.common.additional-info' | translate}}</h1>
    <h1 *ngIf="!mcProcessFlow.exists()">Add McProcessFlow (#{{mcProcessFlow.id}})</h1>

  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!mcProcessFlow.apiLoadingFlg" class="content-body">
    <mcc-message [errorMsg]="mcProcessFlow.apiErrorMessage"></mcc-message>
    <mcc-message [errorMsg]="errorMsg" label="Unable to proceed: "></mcc-message>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container>

      <div class="row">
        <div class="col-md-12 pl-0">
          <div class="section-header">
            <h3>{{'cc.common.additional-info' | translate}}</h3>
          </div>
          <div class="form-inner-content">

            <!--<mcc-fi-radio-buttons label="Inheritable"
                                  [name]="'ctrlInheritableFlg'"
                                  [directionHorizontal]="true"
                                  [mcForm]="mcForm"
                                  [value]="mcProcessFlow.inheritableFlg ? 'yes' : 'no'"
                                  [validRequiredFlg]="true"
                                  [optionsVlm]="inheritableVlm"></mcc-fi-radio-buttons>-->

            <mcc-fi-select [mcForm]="mcForm" [optionsVll]="transactionStatusVll"
                           [validRequiredFlg]="true"
                           [value]="mcProcessFlow.newStatusForTransactionsAfterLastDunningLevel"
                           label="After finishing the last dunning level of this flow, all transaction(s) will be in status:"
                           name="selTransactionStatusAfterLastLevel"
                           placeholderOption="{{'cc.common.edit.please-choose' | translate}}"></mcc-fi-select>

            <mcc-fi-checkbox (eventValueChanged)="cbSendFlowToInkassoValueChanged($event)"
                             [isCheckboxChecked]="mcProcessFlow.sendFlowToInkassoFlg"
                             [mcForm]="mcForm"
                             [value]="mcProcessFlow.sendFlowToInkassoFlg" label="{{'cc.processes.send-flow-to-inkasso-after-last-dunning-level' | translate}}"
                             name="ctrlSendFlowToInkasso"></mcc-fi-checkbox>


            <div class="col-8 position-relative">

              <mcc-fi-radio-buttons2 [directionHorizontal]="false"
                                     [extraCssClass]="mcForm.getValue('ctrlSendFlowToInkasso') ? '' : 'mc-disable'"
                                     [mcForm]="mcForm"
                                     [name]="'ctrlSendFlowTypeOfTransactions'"
                                     [optionsVll]="sendFlowToInkassoTransactionsVll"
                                     [validRequiredFlg]="cbSendFlowToInkassoValidFlg"
                                     [value]="mcProcessFlow.sendFlowToInkassoTransactionTypeCd"></mcc-fi-radio-buttons2>

              <div class="info-icon-positioning2">
                <i class="mc-blue-icon fas fa-info-circle"
                   ngbTooltip="{{'cc.process.all-consumers-transactions-the-ones-in-the-flow-and-those-that-are-not-paid-settled-will-go-to-inkasso'| translate}}"
                   placement="bottom-right" tooltipClass="mc-tooltip-length"></i></div>

              <div class="info-icon-positioning">
                <i class="mc-blue-icon fas fa-info-circle"
                   ngbTooltip="{{'cc.process.only-transactions-that-pass-the-last-dunning-level-of-this-flow-will-go-to-inkasso'|translate}}"
                   placement="bottom-right" tooltipClass="mc-tooltip-length"></i></div>

            </div>


            <mcc-fi-checkbox *ngIf="mcProcess.isTypeCdDUNNING()" [isCheckboxChecked]="mcProcessFlow.groupByConsumerFlg"
                             [mcForm]="mcForm"
                             [value]="mcProcessFlow.groupByConsumerFlg" label="{{'cc.processes.group-transactions-by-consumer' | translate}}"
                             name="ctrlGroupByConsumer"></mcc-fi-checkbox>

            <!--<mcc-fi-radio-buttons2 [name]="'ctrlSendFlowTypeOfClaims'" [extraCssClass]="mcForm.getValue('ctrlSendFlowToInkasso') ? '' : 'mc-disable'"
                                   [directionHorizontal]="true"
                                   [mcForm]="mcForm"
                                   [value]="mcProcessFlow.sendFlowToInkassoClaimTypeCd"
                                   [validRequiredFlg]="cbSendFlowToInkassoValidFlg"
                                   [optionsVll]="sendFlowToInkassoClaimsVll"></mcc-fi-radio-buttons2>-->

          </div>
        </div>
      </div><!-- row -->
    </ng-container>

  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-footer">

    <mcc-button (eventClicked)="actCancel();" class="move-right" extraCssClasses="btn btn-secondary"
                label="{{'cc.common.edit.cancel' | translate}}"></mcc-button>
    <mcc-button (eventClicked)="actSave();"
                [disabledMcb]="saveButtonDisabledMcb" disableOnFirstClickFlg="true"
                extraCssClasses="btn btn-primary"
                label="{{'cc.common.view.save' | translate}}"></mcc-button>
  </div>


</div>
<router-outlet></router-outlet>
