import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {McComponentsModule, McFilterModule, McTableModule} from "@miticon-ui/mc-components";
import {TranslateModule} from "@ngx-translate/core";
import { CdkStepperModule } from "@angular/cdk/stepper";
import {McInvoiceOverviewComponent} from "./mc-invoice-overview/mc-invoice-overview.component";
import {McInvoiceCreationComponent} from "./mc-invoice-creation/mc-invoice-creation.component";
import {InvoiceStep1Component} from "./components/invoice-step1/invoice-step1.component";
import {InvoiceStep2Component} from "./components/invoice-step2/invoice-step2.component";
import {RouterModule} from "@angular/router";
import { McInvoiceEditComponent } from "./mc-invoice-edit/mc-invoice-edit.component";
import { PpInvoiceGenerateInvoiceBulkPartComponent } from './components/pp-invoice-generate-invoice-bulk-part/pp-invoice-generate-invoice-bulk-part.component'

@NgModule({
  declarations: [
    McInvoiceOverviewComponent,
    McInvoiceCreationComponent,
    InvoiceStep1Component,
    InvoiceStep2Component,
    McInvoiceEditComponent,
    PpInvoiceGenerateInvoiceBulkPartComponent
  ],

    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        McComponentsModule,
        TranslateModule,
        McFilterModule,
        McTableModule,
        CdkStepperModule,
        NgbDatepickerModule,
        RouterModule
    ],

  exports: [
    McInvoiceOverviewComponent,
    McInvoiceCreationComponent,
    InvoiceStep1Component,
    InvoiceStep2Component
  ],
})
export class McInvoiceModule { }
