/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McPriceList2Entity} from '../models/mc-price-list-2-entity.model';
import {McPriceList2EntityList} from '../models/mc-price-list-2-entity-list.model';
import {McPriceList2EntityFilter} from '../models/mc-price-list-2-entity-filter.model';
import {McPriceList2EntityService} from '../services/mc-price-list-2-entity.service';
import {IMcPriceList2Entity} from '../_generated/mc-price-list-2-entity.interface';
import {McGod} from "../extra/mc-god.service";

export class McPriceList2EntityListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'flgActive', 'idMcEntity', 'idPriceList', 'validFrom', 'validTo', 'entityName'];
  public items: McPriceList2Entity[] = [];
  public apiService: McPriceList2EntityService;
 // protected tempObjList: McPriceList2EntityList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McPriceList2EntityList {
    console.error('MTCN-ERROR: Not Implemented: McPriceList2EntityList::createNewInstance(). Add this method to final class and return new McPriceList2EntityList();');
    throw new Error('Cannot McPriceList2EntityList::createNewInstance(). Add this method to final class and return new McPriceList2EntityList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcPriceList2Entity[]): McPriceList2EntityList {
    const  listObj: McPriceList2EntityList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McPriceList2Entity = McPriceList2Entity.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McPriceList2EntityList {
    const  listObj: McPriceList2EntityList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McPriceList2EntityService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcPriceList2Entity[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McPriceList2Entity = McPriceList2Entity.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcPriceList2EntityFilter: McPriceList2EntityFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcPriceList2EntityFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McPriceList2Entity {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McPriceList2Entity();
    }
  }

  public replaceItem(newItem: McPriceList2Entity) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadAllEntitiesUsingPriceList( idPriceList: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getAllEntitiesUsingPriceList(idPriceList, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

  // ---------------------------------------------------------------------
  public loadAllEntitiesUsingTemplate( idTemplate: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getAllEntitiesUsingTemplate(idTemplate, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

