export const ICON_SENT_TO_BANK = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1594_11904)">
<path d="M3.4 20.4L20.85 12.92C21.66 12.57 21.66 11.43 20.85 11.08L3.4 3.59999C2.74 3.30999 2.01 3.79999 2.01 4.50999L2 9.11999C2 9.61999 2.37 10.05 2.87 10.11L17 12L2.87 13.88C2.37 13.95 2 14.38 2 14.88L2.01 19.49C2.01 20.2 2.74 20.69 3.4 20.4Z" fill="#53589B"/>
</g>
<defs>
<clipPath id="clip0_1594_11904">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
`
