import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BaseDirective, McBankAccount,
  McBankAccountList, McCountry,
  McEbicsService,
  McEntity2,
  McForm,
  McGod,
  McValueLabelList, EbicsBankAccount, PropertiesData
} from '@miticon-ui/mc-core';
import {takeUntil} from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'mc-ebics-ebics-add-edit-part',
  templateUrl: './mc-ebics-ebics-add-edit-part.component.html',
  styleUrls: ['./mc-ebics-ebics-add-edit-part.component.scss']
})
export class McEbicsEbicsAddEditPartComponent extends BaseDirective implements OnInit {

  mcForm = new McForm();
  bankAccountList = new McBankAccountList();
  bankAccountVll = new McValueLabelList();
  mcEntity2 = new McEntity2();
  mcCountry = new McCountry();
  keys = [
    'A005',
    'E002',
    'X002'
  ];
  errorMsg!: string;
  letterPath!: string;
  userSaved = false;
  sendIniRequest = false;
  sendHiaRequest = false;
  ebicsUser!: {bankAccount: EbicsBankAccount, propertiesData: PropertiesData};
  iniReqSent!: boolean;
  hiaReqSent!: boolean;
  userInitiated!: boolean;
  showLoader!: boolean;
  href!: string;
  file!: File;

  @Input() mcBankAccount!: McBankAccount;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();

  /*Constructor*/
  constructor(private ebicsService: McEbicsService, private toastr: ToastrService) {
    super();
  }

  /*On init*/
  ngOnInit() {
    this.loadBankAccountList();
    this.loadEntity();

    if (this.mcBankAccount.exists()) {
      this.userSaved = true;
      this.loadEbicsUser();
    } else {
      this.mcForm.formGroup.reset();
    }


  }

  loadBankAccountList() {
    this.bankAccountList.loadByEntityId(McGod.getLoggedEntityIdFromToken(), () => {
      this.bankAccountList.items.map(item => {
        const bankAccountName = `${item.bankName} (IBAN: ${item.iban}, BIC: ${item.blz})`;
        this.bankAccountVll.add(item.id, bankAccountName);
      });
    });
  }

  loadEntity() {
    this.mcEntity2.loadById(McGod.getLoggedEntityIdFromToken(), () => {
      this.mcCountry.loadById(this.mcEntity2.countryId);
    });
  }

  loadEbicsUser(): void {
    this.showLoader = true;
    this.errorMsg = '';
    const entityId = McGod.getLoggedEntityIdFromToken();
    let bankAccountId = this.mcForm.getValue('selBankAccount');
    if (this.mcBankAccount.exists()) {
      bankAccountId = this.mcBankAccount.id;
    }
    this.ebicsService.getEbicsUser(entityId, bankAccountId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.showLoader = false;
        this.ebicsUser = response;
        if (this.ebicsUser.bankAccount.userInitiated && !this.iniReqSent) {
          this.sendIniRequest = true;
        }
        if (this.ebicsUser.bankAccount.iniSent) {
          this.iniReqSent = true;
          this.sendIniRequest = false;
        }
        if (this.ebicsUser.bankAccount.hiaSent) {
          this.hiaReqSent = true;
          this.iniReqSent = false;
        }
        this.userInitiated = this.ebicsUser.bankAccount.userInitiated;
        this.setEbicsUserValues();
      }, (err) => {
        this.showLoader = false;
        this.toastr.error(err.error.message, '', {timeOut: 0, extendedTimeOut: 10});
      });
  }

  setEbicsUserValues(): void {
      this.mcForm.getControl('selBankAccount')?.get('value')?.setValue(this.ebicsUser.bankAccount.bankAccountId);
      this.mcForm.getControl('ctrlUserId')?.get('value')?.setValue(this.ebicsUser.propertiesData.userId);
      this.mcForm.getControl('ctrPartnerId')?.get('value')?.setValue(this.ebicsUser.propertiesData.partnerId);
      this.mcForm.getControl('ctrlHostId')?.get('value')?.setValue(this.ebicsUser.propertiesData.hostId);
      this.mcForm.getControl('ctrlBankUrl')?.get('value')?.setValue(this.ebicsUser.propertiesData.bankUrl);
      this.mcForm.getControl('ctrlBankName')?.get('value')?.setValue(this.ebicsUser.propertiesData.bankName);
      this.mcForm.getControl('ctrlUserName')?.get('value')?.setValue(this.ebicsUser.propertiesData.userName);
      this.mcForm.getControl('ctrlUserEmail')?.get('value')?.setValue(this.ebicsUser.propertiesData.userEmail);
      this.mcForm.getControl('ctrlUserOrg')?.get('value')?.setValue(this.ebicsUser.propertiesData.userOrg);
      this.mcForm.getControl('ctrlInstituteId')?.get('value')?.setValue(this.ebicsUser.propertiesData.instituteId);
      this.mcForm.getControl('ctrlProductName')?.get('value')?.setValue(this.ebicsUser.propertiesData.productName);
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actSave() {
    this.eventSaved.emit();
  }

  generateKeys() {
    const entityId = McGod.getLoggedEntityIdFromToken();
    const bankAccountId = this.mcForm.getValue('selBankAccount');
    this.ebicsService.generateKeys(entityId, bankAccountId)
      .subscribe(
        (response) => {
          this.sendIniRequest = true;
          this.loadEbicsUser();
        },
        (err) => {
          this.toastr.error(err.error.message, '', {timeOut: 0, extendedTimeOut: 10});
        });
  }

  saveBankAccAndProperties() {
    const entityId = McGod.getLoggedEntityIdFromToken();
    const bankAccountId = this.mcForm.getValue('selBankAccount');
    const properties = this.getPropertyValues();
    if (this.userSaved) {
      this.ebicsService.updateEbicsUser(entityId, bankAccountId, properties)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response) => {
          this.toastr.success(`${McGod.t('cc.ebics.ebics-account-updated-successfully')}.`, '', {timeOut: 0, extendedTimeOut: 10});
          this.loadEbicsUser();
        }, (err) => {
          this.toastr.error(`${McGod.t('cc.ebics.ebics-account-not-updated-successfully-please-try-again-later')}.`, '', {timeOut: 0, extendedTimeOut: 10});
        });
    } else {
      this.ebicsService.saveEbicsUser(entityId, bankAccountId, properties)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response) => {
          this.loadEbicsUser();
          this.userSaved = true;
          if (this.mcBankAccount.exists()) {
            this.userInitiated = false;
          }
          this.toastr.success(`${McGod.t('cc.ebics.ebics-account-created-successfully')}.`, '', {timeOut: 0, extendedTimeOut: 10});
        }, (err) => {
          this.toastr.error(`${McGod.t('cc.ebics.ebics-account-not-created-successfully-please-try-again-later')}.`, '', {timeOut: 0, extendedTimeOut: 10});
        });
    }
  }

  getPropertyValues() {
    return {
      userId: this.mcForm.getValue('ctrlUserId'),
      partnerId: this.mcForm.getValue('ctrPartnerId'),
      hostId: this.mcForm.getValue('ctrlHostId'),
      'bank.url': this.mcForm.getValue('ctrlBankUrl'),
      'bank.name': this.mcForm.getValue('ctrlBankName'),
      'user.name': this.mcForm.getValue('ctrlUserName'),
      'user.email': this.mcForm.getValue('ctrlUserEmail'),
      instituteId: this.mcForm.getValue('ctrlInstituteId'),
      'user.country': this.mcCountry.code,
      'user.org': this.mcForm.getValue('ctrlUserOrg'),
      productName: this.mcForm.getValue('ctrlProductName'),
      languageCode: this.mcCountry.code,
      countryCode: this.mcCountry.code,
      password: 'gerniks393893',
    };
  }

  sendRequest(requestCode: string) {
    const entityId = McGod.getLoggedEntityIdFromToken();
    const bankAccountId = this.mcForm.getValue('selBankAccount');
    this.ebicsService.sendRequest(entityId, bankAccountId, requestCode)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (requestCode === 'INI') {
          this.sendHiaRequest = true;
        }
        this.loadEbicsUser();
      }, (err) => {
        this.toastr.error(err.error.message, '', {timeOut: 0, extendedTimeOut: 10});
      });
  }


  getLetterPath(letterCode: string) {
    const entityId = McGod.getLoggedEntityIdFromToken();
    const bankAccountId = this.mcForm.getValue('selBankAccount');
    this.ebicsService.getLetterPath(entityId, bankAccountId, letterCode)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.letterPath = response;
        fileSaver.saveAs(response, `${letterCode}.txt`);
      }, (err) => {
        this.toastr.error(err.error.message, '', {timeOut: 0, extendedTimeOut: 10});
      });
  }

}
