/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McBillingServiceCatalog} from '../models/mc-billing-service-catalog.model';
import {McBillingServiceCatalogList} from '../models/mc-billing-service-catalog-list.model';
import {McBillingServiceCatalogFilter} from '../models/mc-billing-service-catalog-filter.model';
import {McBillingServiceCatalogService} from '../services/mc-billing-service-catalog.service';
import {IMcBillingServiceCatalog} from '../_generated/mc-billing-service-catalog.interface';
import {McGod} from "../extra/mc-god.service";

export class McBillingServiceCatalogListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'description', 'flgPriceFromContract', 'title', 'typeCd', 'activityUnitTypes'];
  public items: McBillingServiceCatalog[] = [];
  public apiService: McBillingServiceCatalogService;
 // protected tempObjList: McBillingServiceCatalogList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingServiceCatalogList {
    console.error('MTCN-ERROR: Not Implemented: McBillingServiceCatalogList::createNewInstance(). Add this method to final class and return new McBillingServiceCatalogList();');
    throw new Error('Cannot McBillingServiceCatalogList::createNewInstance(). Add this method to final class and return new McBillingServiceCatalogList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcBillingServiceCatalog[]): McBillingServiceCatalogList {
    const  listObj: McBillingServiceCatalogList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McBillingServiceCatalog = McBillingServiceCatalog.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McBillingServiceCatalogList {
    const  listObj: McBillingServiceCatalogList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingServiceCatalogService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcBillingServiceCatalog[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McBillingServiceCatalog = McBillingServiceCatalog.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcBillingServiceCatalogFilter: McBillingServiceCatalogFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcBillingServiceCatalogFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McBillingServiceCatalog {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McBillingServiceCatalog();
    }
  }

  public replaceItem(newItem: McBillingServiceCatalog) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

