/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McBillingPayoutFileItemFeCtdFilter} from '../models/mc-billing-payout-file-item-fe-ctd-filter.model';

export class McBillingPayoutFileItemFeCtdFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingPayoutFileItemFeCtdFilter {
    console.error('MTCN-ERROR: Not Implemented: McBillingPayoutFileItemFeCtdFilter::createNewInstance(). Add this method to final class and return new McBillingPayoutFileItemFeCtdFilter();');
      throw new Error('Cannot McBillingPayoutFileItemFeCtdFilter::createNewInstance(). Add this method to final class and return new McBillingPayoutFileItemFeCtdFilter();');
  }

}
