/*BbmTestObj*/
import {
  PmTransactionTable3ConfigGENERATED
} from '../_generated/pm-transaction-table-3-config-generated.model';

export class PmTransactionConsumerTable3Config extends PmTransactionTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.addColumn('colActions', '', 'fldActionsConsumerTransaction()', '');
    this.addColumn('colConsumer', this.t('cc.consumers.view.consumer-name'), 'getConsumerName()', '');
    this.addColumn('colDescription', this.t('cc.common.view.description'), 'description', 'description').extraCssClass = 'mc-factoring-transactions-table-description-column';
    this.addColumn('colPaymentDueDate', this.t('cc.transactions.payment-due-date'), 'getDueDateStr()', 'dueDatetime');
    this.addColumn('colAmount', this.t('cc.table.amount-due'), 'getAmountDue()', '').extraCssClass = 'text-right';
    this.addColumn('colStatus', this.t('cc.common.view.status'), 'getIconLabel()', 'statusCd');
  }
}
