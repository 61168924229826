/*BbmTestObj*/
import {ImDataMappingGENERATED} from '../_generated/im-data-mapping-generated.model';

export class ImDataMapping extends ImDataMappingGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataMapping();
  }

  // ---------------------------------------------------------------------
}
