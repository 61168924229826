/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbTransactionAdjustment} from '../models/eb-transaction-adjustment.model';
import {EbTransactionAdjustmentService} from '../services/eb-transaction-adjustment.service';
import {IEbTransactionAdjustment} from '../_generated/eb-transaction-adjustment.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';




export abstract class EbTransactionAdjustmentGENERATED extends BaseObject {









    public static readonly PAYMENT_METHOD_CD_CREDIT_CARD: string = 'CREDIT_CARD';
    public static readonly PAYMENT_METHOD_CD_CASH: string = 'CASH';
    public static readonly PAYMENT_METHOD_CD_CHEQUE: string = 'CHEQUE';
    public static readonly PAYMENT_METHOD_CD_TRANSFER: string = 'TRANSFER';
    public static readonly PAYMENT_METHOD_CD_DIRECT_DEBIT: string = 'DIRECT_DEBIT';
    public static readonly PAYMENT_METHOD_CD_NOT_APPLICABLE: string = 'NOT_APPLICABLE';
    public static readonly PAYMENT_METHOD_CD_CREDIT: string = 'CREDIT';
  public static readonly PAYMENT_METHOD_CD_LIST__ALL = [
    EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT_CARD,
    EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CASH,
    EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CHEQUE,
    EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_TRANSFER,
    EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_DIRECT_DEBIT,
    EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_NOT_APPLICABLE,
    EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT
];



    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_PENDING: string = 'PENDING';
    public static readonly STATUS_CD_RETURNED: string = 'RETURNED';
    public static readonly STATUS_CD_COMPLETED: string = 'COMPLETED';
    public static readonly STATUS_CD_REMOVED: string = 'REMOVED';
  public static readonly STATUS_CD_LIST__ALL = [
    EbTransactionAdjustmentGENERATED.STATUS_CD_ACTIVE,
    EbTransactionAdjustmentGENERATED.STATUS_CD_PENDING,
    EbTransactionAdjustmentGENERATED.STATUS_CD_RETURNED,
    EbTransactionAdjustmentGENERATED.STATUS_CD_COMPLETED,
    EbTransactionAdjustmentGENERATED.STATUS_CD_REMOVED
];

    public static readonly TYPE_CD_ORIGINAL: string = 'ORIGINAL';
    public static readonly TYPE_CD_PAYMENT: string = 'PAYMENT';
    public static readonly TYPE_CD_BANK_FEE: string = 'BANK_FEE';
    public static readonly TYPE_CD_REMINDER_FEE: string = 'REMINDER_FEE';
    public static readonly TYPE_CD_INTEREST: string = 'INTEREST';
    public static readonly TYPE_CD_CREDIT: string = 'CREDIT';
    public static readonly TYPE_CD_INKASSO_FEE: string = 'INKASSO_FEE';
    public static readonly TYPE_CD_STORNO: string = 'STORNO';
  public static readonly TYPE_CD_LIST__ALL = [
    EbTransactionAdjustmentGENERATED.TYPE_CD_ORIGINAL,
    EbTransactionAdjustmentGENERATED.TYPE_CD_PAYMENT,
    EbTransactionAdjustmentGENERATED.TYPE_CD_BANK_FEE,
    EbTransactionAdjustmentGENERATED.TYPE_CD_REMINDER_FEE,
    EbTransactionAdjustmentGENERATED.TYPE_CD_INTEREST,
    EbTransactionAdjustmentGENERATED.TYPE_CD_CREDIT,
    EbTransactionAdjustmentGENERATED.TYPE_CD_INKASSO_FEE,
    EbTransactionAdjustmentGENERATED.TYPE_CD_STORNO
];

  public apiService: EbTransactionAdjustmentService;
  public _rawJson: IEbTransactionAdjustment;
    id: number = 0;
  amount: number;
  beneficiaryEntityId: number;
  createdDatetime: number;
  createdUserId: number;
  description = '';
  idEbSepaFileTransaction: number;
  idEbStarmoneyFile2SepaFileTransaction: number;
  paymentMethodCd: string;
  refId: number;
  refTypeCd = '';
  statusCd: string;
  typeCd: string;
    beneficiaryEntityName: string;    createdBy: string;    returnedIsoCode: string;    returnedIsoCodeLabel: string;    returnedTypeCd: string;

  public properties: string[] = ['id', 'amount', 'beneficiaryEntityId', 'createdDatetime', 'createdUserId', 'description', 'idEbSepaFileTransaction', 'idEbStarmoneyFile2SepaFileTransaction', 'paymentMethodCd', 'refId', 'refTypeCd', 'statusCd', 'typeCd', 'beneficiaryEntityName', 'createdBy', 'returnedIsoCode', 'returnedIsoCodeLabel', 'returnedTypeCd'];
  public propertiesRegular: string[] = ['id', 'amount', 'beneficiaryEntityId', 'createdDatetime', 'createdUserId', 'description', 'ebSepaFileTransaction', 'idEbSepaFileTransaction', 'idEbStarmoneyFile2SepaFileTransaction', 'paymentMethodCd', 'refId', 'refTypeCd', 'statusCd', 'typeCd', 'beneficiaryEntityName', 'createdBy', 'returnedIsoCode', 'returnedIsoCodeLabel', 'returnedTypeCd'];
  public propertiesSpecial: string[] = [];









  public static getPaymentMethodCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT_CARD, McGod.t('cc.transaction.adjustment.credit-card'));
    list.add(EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CASH, McGod.t('cc.transaction.adjustment.cash'));
    list.add(EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CHEQUE, McGod.t('cc.transaction.adjustment.cheque'));
    list.add(EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_TRANSFER, McGod.t('cc.transaction.adjustment.transfer'));
    list.add(EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_DIRECT_DEBIT, McGod.t('Direct debit'));
    list.add(EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_NOT_APPLICABLE, McGod.t('n/a'));
    list.add(EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT, McGod.t('Credit'));


    return list;
  }

  public static getPaymentMethodCdLabel(aCd: string): string {
    const list = EbTransactionAdjustmentGENERATED.getPaymentMethodCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbTransactionAdjustmentGENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(EbTransactionAdjustmentGENERATED.STATUS_CD_PENDING, McGod.t('Pending'));
    list.add(EbTransactionAdjustmentGENERATED.STATUS_CD_RETURNED, McGod.t('Returned'));
    list.add(EbTransactionAdjustmentGENERATED.STATUS_CD_COMPLETED, McGod.t('Completed'));
    list.add(EbTransactionAdjustmentGENERATED.STATUS_CD_REMOVED, McGod.t('Removed'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = EbTransactionAdjustmentGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbTransactionAdjustmentGENERATED.TYPE_CD_ORIGINAL, McGod.t('Original Amount'));
    list.add(EbTransactionAdjustmentGENERATED.TYPE_CD_PAYMENT, McGod.t('Payment'));
    list.add(EbTransactionAdjustmentGENERATED.TYPE_CD_BANK_FEE, McGod.t('Bank Fee'));
    list.add(EbTransactionAdjustmentGENERATED.TYPE_CD_REMINDER_FEE, McGod.t('Reminder Fee'));
    list.add(EbTransactionAdjustmentGENERATED.TYPE_CD_INTEREST, McGod.t('Interest'));
    list.add(EbTransactionAdjustmentGENERATED.TYPE_CD_CREDIT, McGod.t('Credit'));
    list.add(EbTransactionAdjustmentGENERATED.TYPE_CD_INKASSO_FEE, McGod.t('Inkasso Fee'));
    list.add(EbTransactionAdjustmentGENERATED.TYPE_CD_STORNO, McGod.t('Storno'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = EbTransactionAdjustmentGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): EbTransactionAdjustment {
    console.error('MTCN-ERROR: Not Implemented: EbTransactionAdjustment::createNewInstance(). Add this method to final class and return new EbTransactionAdjustment();');
    throw new Error('Cannot EbTransactionAdjustment::createNewInstance(). Add this method to final class and return new EbTransactionAdjustment();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbTransactionAdjustment): EbTransactionAdjustment {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbTransactionAdjustmentService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbTransactionAdjustment AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getAmountStr(): string {
       return McGod.formatNumberToMoney(this.amount);
    }

    public getCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.createdDatetime);
    }

    public getCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.createdDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  








  public getPaymentMethodCdLabel(): string {
    return EbTransactionAdjustmentGENERATED.getPaymentMethodCdLabel(this.paymentMethodCd);
  }


 public  isPaymentMethodCdCREDIT_CARD(): boolean {
    const result = (this.paymentMethodCd === EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT_CARD);

    return result;
  }

 public  isPaymentMethodCdCASH(): boolean {
    const result = (this.paymentMethodCd === EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CASH);

    return result;
  }

 public  isPaymentMethodCdCHEQUE(): boolean {
    const result = (this.paymentMethodCd === EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CHEQUE);

    return result;
  }

 public  isPaymentMethodCdTRANSFER(): boolean {
    const result = (this.paymentMethodCd === EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_TRANSFER);

    return result;
  }

 public  isPaymentMethodCdDIRECT_DEBIT(): boolean {
    const result = (this.paymentMethodCd === EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_DIRECT_DEBIT);

    return result;
  }

 public  isPaymentMethodCdNOT_APPLICABLE(): boolean {
    const result = (this.paymentMethodCd === EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_NOT_APPLICABLE);

    return result;
  }

 public  isPaymentMethodCdCREDIT(): boolean {
    const result = (this.paymentMethodCd === EbTransactionAdjustmentGENERATED.PAYMENT_METHOD_CD_CREDIT);

    return result;
  }




  public getStatusCdLabel(): string {
    return EbTransactionAdjustmentGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === EbTransactionAdjustmentGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdPENDING(): boolean {
    const result = (this.statusCd === EbTransactionAdjustmentGENERATED.STATUS_CD_PENDING);

    return result;
  }

 public  isStatusCdRETURNED(): boolean {
    const result = (this.statusCd === EbTransactionAdjustmentGENERATED.STATUS_CD_RETURNED);

    return result;
  }

 public  isStatusCdCOMPLETED(): boolean {
    const result = (this.statusCd === EbTransactionAdjustmentGENERATED.STATUS_CD_COMPLETED);

    return result;
  }

 public  isStatusCdREMOVED(): boolean {
    const result = (this.statusCd === EbTransactionAdjustmentGENERATED.STATUS_CD_REMOVED);

    return result;
  }


  public getTypeCdLabel(): string {
    return EbTransactionAdjustmentGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdORIGINAL(): boolean {
    const result = (this.typeCd === EbTransactionAdjustmentGENERATED.TYPE_CD_ORIGINAL);

    return result;
  }

 public  isTypeCdPAYMENT(): boolean {
    const result = (this.typeCd === EbTransactionAdjustmentGENERATED.TYPE_CD_PAYMENT);

    return result;
  }

 public  isTypeCdBANK_FEE(): boolean {
    const result = (this.typeCd === EbTransactionAdjustmentGENERATED.TYPE_CD_BANK_FEE);

    return result;
  }

 public  isTypeCdREMINDER_FEE(): boolean {
    const result = (this.typeCd === EbTransactionAdjustmentGENERATED.TYPE_CD_REMINDER_FEE);

    return result;
  }

 public  isTypeCdINTEREST(): boolean {
    const result = (this.typeCd === EbTransactionAdjustmentGENERATED.TYPE_CD_INTEREST);

    return result;
  }

 public  isTypeCdCREDIT(): boolean {
    const result = (this.typeCd === EbTransactionAdjustmentGENERATED.TYPE_CD_CREDIT);

    return result;
  }

 public  isTypeCdINKASSO_FEE(): boolean {
    const result = (this.typeCd === EbTransactionAdjustmentGENERATED.TYPE_CD_INKASSO_FEE);

    return result;
  }

 public  isTypeCdSTORNO(): boolean {
    const result = (this.typeCd === EbTransactionAdjustmentGENERATED.TYPE_CD_STORNO);

    return result;
  }


  
// ---------------------------------------------------------------------
public callRecordPaymentForMultipleTransactions( transactionIds: any, ebTransactionAdjustment: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.recordPaymentForMultipleTransactions(transactionIds,ebTransactionAdjustment).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}



}
