<div class='card'>
  <h2 class="title-medium-dark mb-large">{{ 'cc.my-factoring.payment' | translate }}</h2>

  <h6 class="mc-mandatory-star">{{ 'cc.my-factoring.payment' | translate }}</h6>
  <mcc-fi-radio-buttons2 name="ctrlPayment"
                         [directionHorizontal] = true
                         [mcForm]="mcForm"
                         [validRequiredFlg]="true"
                         [optionsVll]="paymentsVll"
                         (eventValueChanged)="onPaymentChangeEmit($event)">
  </mcc-fi-radio-buttons2>
</div>
