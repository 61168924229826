import {EbSepaFileTransactionFilterGENERATED} from '../_generated/eb-sepa-file-transaction-filter-generated.model';

export class EbSepaFileTransactionFilter extends EbSepaFileTransactionFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaFileTransactionFilter();
  }

  // ---------------------------------------------------------------------
}
