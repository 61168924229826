import {Component, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup } from "@angular/forms";
import { McAuthService, McEntityService, McCreateUserService } from "@miticon-ui/mc-core";

@Component({
  selector: "lib-mc-create-user-wrapper",
  templateUrl: "./mc-create-user-wrapper.component.html"
})
export class McCreateUserWrapperComponent implements OnInit {

  // Variables
  isUserFormValid!: boolean;
  imagePath!: string;
  selectedEntityPath!: string[];
  lastChildEntity!: string;
  saveUserBtnType!: number;
  responseMessage!: boolean;
  message!: string;
  type!: string;
  modalArea = "createUser";
  showLoader!: boolean;

  // Forms
  createUserForm: FormGroup;
  allRawData: any;

  @Output() updateUserEvent = new EventEmitter<any>();
  @ViewChild("openModalBtn") openModalBtn!: HTMLAnchorElement;

  constructor(
    private authService: McAuthService,
    private entityService: McEntityService,
    private createUserService: McCreateUserService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {
    this.createUserForm = new FormGroup({});
  }

  ngOnInit() {
    this.createForm();
    this.allRawData = this.createUserForm.getRawValue();

    /*Get selected entity path from service*/
    this.entityService._selectedEntityPath.subscribe(path => {
      this.selectedEntityPath = path;
      this.lastChildEntity = this.selectedEntityPath.slice(-1)[0];
    });
  }

  // Create Form
  private createForm() {
    this.createUserForm = this.fb.group({
      step1: "",
      step2: "",
      step3: ""
    });
  }

  // Modal
  openModal(newUserModal: any) {
    this.modalService.open(newUserModal, {
      backdrop: "static",
      centered: true,
      windowClass: "mc-create-new-user-modal"
    });
    this.responseMessage = false;
    this.userDataFormValid(true);
  }

  saveBtnType(saveUserBtnType: number) {
    this.saveUserBtnType = saveUserBtnType;
  }


  saveUser() {
    this.showLoader = true;
    const parentFormData = this.createUserForm.value;
    const selectedRoles = parentFormData.step2.selRoles
      .filter((item: any) => item.selected === true)
      .map((item: any) => item.id);

    const body = {
      email: parentFormData.step1.email,
      firstName: parentFormData.step1.firstName || null,
      gender: parentFormData.step1.gender || null,
      invitationRequired: true,
      isInvitationRequired: parentFormData.step3.sendInvite || false,
      isoCountry: parentFormData.step1.country || null,
      isoLanguage: parentFormData.step1.language || null,
      lastName: parentFormData.step1.lastName || null,
      nickname: parentFormData.step1.nickname || null,
      rawImageData: this.imagePath || null,
      roleIds: selectedRoles,
      timezone: 0
    };

    this.createUserService.createNewUser(body).subscribe(
      (response) => {
        if (this.saveUserBtnType === 1) {
          const msg = "success";
          const msgType = "success";
          this.responseMessage = true;
          this.setNotificationMessage(msg, msgType);
          this.modalService.dismissAll("Cross click");
        } else {
          const msg = "success";
          const msgType = "success";
          this.responseMessage = true;
          this.setNotificationMessage(msg, msgType);
          this.modalService.dismissAll("Cross click");
          document.getElementById("newUserModalButton")?.click();
        }
        this.updateUserEvent.emit(true);
        this.showLoader = false;
      },
      (error) => {
        let message = "";
        let msgType = "";
        if (error.status >= 500) {
          message = "Server error";
          msgType = "error";
        } else if (error.error) {
          if (error.error.errors.length > 1) {
            message = error.error.errors.join();
            msgType = "error";
          } else {
            message = error.error.errors;
            msgType = "error";
          }
        } else {
          message = "Invalid request";
          msgType = "error";
        }
        this.responseMessage = true;
        this.setNotificationMessage(message, msgType);
        setTimeout(() => {
          this.responseMessage = false;
          this.setNotificationMessage("", "");
        }, 5000);
        this.showLoader = false;
      },
    );
  }

  userDataFormValid(data: boolean) {
    this.isUserFormValid = data;
  }

  imagePathFile(path: string) {
    this.imagePath = path;
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }


}
