import {McProcessItem} from './mc-process-item.model';
import {McProcessItemListGENERATED} from '../_generated/mc-process-item-list-generated.model';

export class McProcessItemList extends McProcessItemListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessItemList();
  }

  // ---------------------------------------------------------------------
}
