import {EbFactoringFilterGENERATED} from '../_generated/eb-factoring-filter-generated.model';

export class EbFactoringFilter extends EbFactoringFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbFactoringFilter();
  }

  // ---------------------------------------------------------------------
}
