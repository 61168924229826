import {CsrNotificationDetails} from './csr-notification-details.model';
import {CsrNotificationDetailsListGENERATED} from '../_generated/csr-notification-details-list-generated.model';

export class CsrNotificationDetailsList extends CsrNotificationDetailsListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrNotificationDetailsList();
  }

  // ---------------------------------------------------------------------
}
