/*BbmTestObj*/
import {CsrConsumerImportGENERATED} from '../_generated/csr-consumer-import-generated.model';
import {McDateUtils} from "../common/mc-date-utils";

export class CsrConsumerImport extends CsrConsumerImportGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrConsumerImport();
  }

  fldRecords() {
    return this.processedRecords + '/' + this.addedRecords + '/' + this.updatedRecords + '/' + this.skippedRecords;
  }

  fldImportMode() {
    if (this.importModeCd === 'ADD') {
      return 'Add';
    } else if (this.importModeCd === 'ADDUPD') {
      return 'Add & Update';
    } else {
      return 'Update';
    }
  }

  fldCreatedAt() {
    return McDateUtils.newFormatDateTimeString(this.createdAt);
  }

  // ---------------------------------------------------------------------
}
