<div class="col-md-12 mc-stepper-header-title">
  <span class="px-4 py-2 mc-semi-active">
    <span class="mc-notification-badge-gray mc-semi-active-background">1</span>
    {{'cc.users.view.general-data' | translate}}
  </span>
  <span class="px-4 py-2" [ngClass]="roleDataTab ? 'mc-active': ''">
  <span class="mc-notification-badge-gray" [ngClass]="roleDataTab ? 'mc-active-background': ''">2</span>
    {{ 'cc.common.admin.roles' | translate }}
  </span>
</div>

<div class="mc-user-entities-area">
  <div class="row mt-5">
    <div class="col-md-5">
      <div class="form-group mb-0 mc-new-user-search">
        <label class="mb-0 pr-3">{{ 'cc.common.admin.entities' | translate }}</label>
        <input type="text" class="form-control" id="searchEntities" aria-describedby="searchEntities" placeholder="search entities">
        <i class="fa fa-search mc-new-user-search-icon"></i>
      </div>
    </div>
  </div>

  <div class="col-md-12 mt-4 mx-0 py-4">

    <div class="row p-0">

      <div class="col-md-6 px-0">
        <div class="mc-new-user-entity-name py-2">
          <span>LA</span>
        </div>
        <div class="mc-new-user-entities-list py-2 pl-5">
          <span>Beverly Hills</span>
        </div>
        <div class="mc-new-user-entities-list py-2 pl-5">
          <span>Hollywood</span>
        </div>
        <div class="mc-new-user-entities-list py-2 pl-5">
          <span>Santa Monica</span>
          <span class="mc-notification-badge-gray ml-2">2</span>
        </div>
      </div>


      <div class="col-md-6 p-3  mc-user-roles-content">
        <div class="row mx-0 p-3">
          <div class="col-md-8 pl-0">
            <div class="form-group mb-0 mc-new-user-search">
              <label class="mb-0 pr-3">{{ 'cc.common.admin.roles' | translate }}</label>
              <input type="text" class="form-control" id="searchRoles" aria-describedby="searchRoles" placeholder="{{'cc.users.edit.search-roles' | translate}}">
              <i class="fa fa-search mc-new-user-search-icon"></i>
            </div>
          </div>
          <div class="col-md-4 mc-show-selected-only px-0">
            <label class="custom-control fill-checkbox mb-0">
              <input id="rolesShowSelectedOnly" type="checkbox" class="fill-control-input" />
              <span class="fill-control-indicator"></span>
              <span class="fill-control-description">{{'cc.users.edit.show-selected-only' | translate}}</span>
            </label>
          </div>
        </div>

        <div class="row mx-0">
          <div class="col-md-12 p-3 mc-user-roles-item">
            <label class="custom-control fill-checkbox mb-0">
              <input type="checkbox" class="fill-control-input" />
              <span class="fill-control-indicator"></span>
              <span class="fill-control-description">LA Accountant</span>
            </label>
            <i class="fas fa-info-circle"></i>
          </div>
          <div class="col-md-12 p-3 mc-user-roles-item">
            <label class="custom-control fill-checkbox mb-0">
              <input type="checkbox" class="fill-control-input" />
              <span class="fill-control-indicator"></span>
              <span class="fill-control-description">LA Accountant</span>
            </label>
            <i class="fas fa-info-circle"></i>
          </div>
          <div class="col-md-12 p-3 mc-user-roles-item">
            <label class="custom-control fill-checkbox mb-0">
              <input type="checkbox" class="fill-control-input" />
              <span class="fill-control-indicator"></span>
              <span class="fill-control-description">LA Accountant</span>
            </label>
            <i class="fas fa-info-circle"></i>
          </div>
          <div class="col-md-12 p-3 mc-user-roles-item">
            <label class="custom-control fill-checkbox mb-0">
              <input type="checkbox" class="fill-control-input" />
              <span class="fill-control-indicator"></span>
              <span class="fill-control-description">LA Accountant</span>
            </label>
            <i class="fas fa-info-circle"></i>
          </div>
          <div class="col-md-12 p-3 mc-user-roles-item">
            <label class="custom-control fill-checkbox mb-0">
              <input type="checkbox" class="fill-control-input" />
              <span class="fill-control-indicator"></span>
              <span class="fill-control-description">LA Accountant</span>
            </label>
            <i class="fas fa-info-circle"></i>
          </div>
          <div class="col-md-12 p-3 mc-user-roles-item">
            <label class="custom-control fill-checkbox mb-0">
              <input type="checkbox" class="fill-control-input" />
              <span class="fill-control-indicator"></span>
              <span class="fill-control-description">LA Accountant</span>
            </label>
            <i class="fas fa-info-circle"></i>
          </div>
          <div class="col-md-12 p-3 mc-user-roles-item">
            <label class="custom-control fill-checkbox mb-0">
              <input type="checkbox" class="fill-control-input" />
              <span class="fill-control-indicator"></span>
              <span class="fill-control-description">LA Accountant</span>
            </label>
            <i class="fas fa-info-circle"></i>
          </div>
          <div class="col-md-12 p-3 mc-user-roles-item">
            <label class="custom-control fill-checkbox mb-0">
              <input type="checkbox" class="fill-control-input" />
              <span class="fill-control-indicator"></span>
              <span class="fill-control-description">LA Accountant</span>
            </label>
            <i class="fas fa-info-circle"></i>
          </div>

        </div>


      </div>

    </div>

  </div>

<!--  <div class="row mt-5 mb-3">-->
<!--    <div class="col-md-6">-->
<!--      <button id="userRolePrevBtn" type="button" class="btn btn-secondary mc-prev-btn">-->
<!--        <i class="fas fa-chevron-left"></i>-->
<!--        Prev-->
<!--      </button>-->
<!--    </div>-->
<!--    <div class="col-md-6 text-right">-->
<!--      <button id="userRoleNextBtn" class="btn btn-primary mc-next-save-btn" type="submit">-->
<!--        Next-->
<!--        <i class="fas fa-chevron-right"></i>-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->

</div>
