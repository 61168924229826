import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {McJobsService, McUtilityService, McGod, McProcessService, McProcessRunJobInfo, McEntityIdAndName, McRunItemEmailInfo, SortCriteriaList} from "@miticon-ui/mc-core";
import _moment from "moment";
import {ICON_BACK} from "../../../../assets/media/svg_icons/icon-back";
import {MkFilterOutput, MkTableConfig} from "@miticon-ui/mc-components";
import {McJobDetailsDialogComponent} from "./mc-job-details-dialog/mc-job-details-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'mc-job-view',
  templateUrl: './mc-job-view.component.html',
  styleUrls: ['./mc-job-view.component.scss']
})
export class McJobViewComponent implements OnInit {

  jobData!: any;
  jobID: any;
  jobName!: string;
  jobType!: string;
  jobDescription!: string;
  jobStatus!: string;
  jobGroup!: string;
  scheduleTime!: string;
  lastFiredTime!: string;
  nextFireTime!: string;
  scheduleType!: string;
  iconBack = ICON_BACK;

  isLoading = false;
  items: McProcessRunJobInfo[] = [];
  tableConfig = new MkTableConfig();
  totalItemsCount = 0;
  pageNumber = 0;
  pageSize = 5;

  constructor(private activatedRoute: ActivatedRoute,
              private jobsService: McJobsService,
              private mcProcessService: McProcessService,
              private utilityService: McUtilityService,
              private dialog: MatDialog) {
    this.activatedRoute.paramMap.subscribe(data => {
      this.jobID = data.get('jobId');
      this.getJobData(this.jobID);
    });
  }

  ngOnInit() {}

  getJobData(userID: number) {
    this.jobsService.getJobData(userID).subscribe(data => {
      this.jobData = data;

      this.jobName = data.name;
      this.jobType = data.jobClass;
      this.jobDescription = data.description;
      this.jobStatus = data.statusCode.toLowerCase();
      this.scheduleTime = _moment(data.scheduleTime).format('DD.MM.YYYY h:mm');
      if (data.lastFiredTime) {
        this.lastFiredTime = _moment(data.lastFiredTime).format('DD.MM.YYYY h:mm');
      }
      this.nextFireTime = _moment(data.nextFireTime).format('DD.MM.YYYY h:mm');
      this.scheduleType = data.jobType;
      this.jobGroup = data.group;

      if(this.isJobGroupReminders()) {
        this.initTableConfig();
        this.loadJobDetails(this.pageNumber, this.pageSize);
      }
    });
  }

  isJobGroupReminders() {
    return this.jobGroup && this.jobGroup === 'reminders';
  }

  loadJobDetails(pageNumber: number, pageSize: number) {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.isLoading = true;
    const sortCriteriaList = new SortCriteriaList();

    this.mcProcessService.getMcJobDetails(this.jobID, this.pageNumber, this.pageSize, sortCriteriaList).subscribe((res) => {
      if (res) {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => object = McProcessRunJobInfo.createFromJson(object));
      }
    });
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('cc.jobs.run'), 'getId()', 250, 'id');
    this.tableConfig.addColumnStandard(McGod.t('cc.jobs.created-on'), 'getCreatedOn()', 250);
    this.tableConfig.addColumnSvgIconAction(McGod.t('cc.jobs.success-run'), 'getSuccess()', 'success');
    this.tableConfig.addColumnSvgIconAction(McGod.t('cc.jobs.failed-run'), 'getFailed()', 'failed');
    this.tableConfig.addColumnStandard(McGod.t('cc.jobs.delivered-emails'), 'getDelivered()', 250);
    this.tableConfig.addColumnSvgIconAction(McGod.t('cc.jobs.skipped-emails'), 'getSkipped()', 'skipped');
    this.tableConfig.addColumnSvgIconAction(McGod.t('cc.jobs.error-emails'), 'getError()', 'error');
  }

  onFilterChanged(filters: MkFilterOutput) {
    this.loadJobDetails(filters.pageEvent.pageIndex, filters.pageEvent.pageSize);
  }

  onColumnButtonAction(event: any) {
    const getTitleAndTypes = (actionCd: string, elementId: string): { title: string; tableType: string, dataType: string } => {
      switch (actionCd) {
        case 'success':
          return {
            title: McGod.t('cc.jobs.details.success'),
            tableType: 'run',
            dataType: 'success',
          };
        case 'failed':
          return {
            title: McGod.t('cc.jobs.details.failed'),
            tableType: 'run',
            dataType: 'failed'
          };
        case 'skipped':
          return {
            title: `${McGod.t('cc.jobs.details.skipped')} #${elementId}`,
            tableType: 'email',
            dataType: 'skipped'
          };
        case 'error':
          return {
            title: `${McGod.t('cc.jobs.details.error')} #${elementId}`,
            tableType: 'email',
            dataType: 'error'
          };
        default:
          return {
            title: '',
            tableType: '',
            dataType: ''
          };
      }
    };

    const { title, tableType, dataType } = getTitleAndTypes(event.actionCd, event.element?.idMcProcessJobExecution || '');
    if (title && tableType && dataType) {
      this.openDetailsDialog(title, tableType, dataType, event.element?.idMcProcessJobExecution || '');
    }
  }

  openDetailsDialog(title: string, tableType: string, dataType: string, jobExecId: any) {
    this.dialog.open(McJobDetailsDialogComponent, {
      height: '900px',
      width: '960px',
      panelClass: 'dialog',
      autoFocus: false,
      restoreFocus: false,
      data: {
        jobExecId: jobExecId,
        title: title,
        tableType: tableType,
        dataType: dataType
      }
    })
  }

  goBack() {
    this.utilityService.goBack();
  }
}
