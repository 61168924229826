/*BankAccountObj*/
import {McBankAccountGENERATED} from '../_generated/mc-bank-account-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {ActionOptions} from '../_core/mc-html-action-options';
import {McEbics, PropertiesData} from '../models/mc-ebics.model';

export class McBankAccount extends McBankAccountGENERATED {
  public static ACTION_EDIT_EBICS_ACCOUNT = 'EDIT-EBICS-ACCOUNT';
  ebicsPropertiesData!: PropertiesData;
  private _fldActions: McHtml | null = null;

  public static override createNewInstance() {
    return new McBankAccount();
  }

  public fldTitle() {
    return this.bankName + ' (' + this.iban + ')';
  }

  public fldTitleIbanBicDefault() {
    const defaultValue = this.defaultFlg ? 'default' : '';
    return this.bankName + ' (IBAN: ' + this.iban + ', BIC: ' + this.swift + ') ' + defaultValue;
  }


  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas fa-key mc-submit-color mc-cursor-pointer"></i> ${McGod.t('cc.ebics.edit-ebics-account')}`, McBankAccount.ACTION_EDIT_EBICS_ACCOUNT, this, [McGod.PERM_MC_BILLING_MY_STATEMENT_VIEW], '', ActionOptions.OPTION_EDIT);
      actSet.addActionB(`<i class="fas fa-key mc-submit-color mc-cursor-pointer"></i> ${McGod.t('cc.ebics.ebics-report')}`, McEbics.ACTION_EBICS_REPORT, this, [McGod.PERM_EB_FACTORING_EBICS_REPORT_VIEW], '', ActionOptions.OPTION_VIEW);
      this._fldActions = html;
    }
    return this._fldActions;
  }
}
