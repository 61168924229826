import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IImDataImportItem} from '../_generated/im-data-import-item.interface';
import {ImDataImportItem} from '../models/im-data-import-item.model';
import {ImDataImportItemList} from '../models/im-data-import-item-list.model';
import {ImDataImportItemServiceGENERATED} from '../_generated/im-data-import-item-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ImDataImportItemService extends ImDataImportItemServiceGENERATED {

  public getByDataImportId(id: number): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/bydataimport/${id}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        })
    });
  }

}
