/*BbmTestObj*/
import {McConsumerBalanceGENERATED} from '../_generated/mc-consumer-balance-generated.model';

export class McConsumerBalance extends McConsumerBalanceGENERATED {
  balanceAmountFormatted!: string;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McConsumerBalance();
  }

  fldConsumerName() {
    return `${this.firstName} ${this.lastName}`;
  }

  // ---------------------------------------------------------------------
}
