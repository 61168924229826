
import {Table3Config} from '../extra/table-3-config';

// import {McBillingProcessedTransactionsInfoTableGENERATED} from '../_generated/mc-billing-processed-transactions-info-table-generated.model';

export class McBillingProcessedTransactionsInfoTableTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colFtAcceptedForFactoringA', '...', 'ftAcceptedForFactoringA', 'ftAcceptedForFactoringA');
    this.addColumn('colFtAcceptedForFactoringB', '...', 'ftAcceptedForFactoringB', 'ftAcceptedForFactoringB');
    this.addColumn('colFtAcceptedForFactoringC', '...', 'ftAcceptedForFactoringC', 'ftAcceptedForFactoringC');
    this.addColumn('colFtImmediatelyPayableBalanceA', '...', 'ftImmediatelyPayableBalanceA', 'ftImmediatelyPayableBalanceA');
    this.addColumn('colFtImmediatelyPayableBalanceB', '...', 'ftImmediatelyPayableBalanceB', 'ftImmediatelyPayableBalanceB');
    this.addColumn('colFtImmediatelyPayableBalanceC', '...', 'ftImmediatelyPayableBalanceC', 'ftImmediatelyPayableBalanceC');
    this.addColumn('colFtLaterPayableBalanceA', '...', 'ftLaterPayableBalanceA', 'ftLaterPayableBalanceA');
    this.addColumn('colFtLaterPayableBalanceB', '...', 'ftLaterPayableBalanceB', 'ftLaterPayableBalanceB');
    this.addColumn('colFtLaterPayableBalanceC', '...', 'ftLaterPayableBalanceC', 'ftLaterPayableBalanceC');
    this.addColumn('colFtNotAcceptedForFactoringA', '...', 'ftNotAcceptedForFactoringA', 'ftNotAcceptedForFactoringA');
    this.addColumn('colFtNotAcceptedForFactoringB', '...', 'ftNotAcceptedForFactoringB', 'ftNotAcceptedForFactoringB');
    this.addColumn('colFtNotAcceptedForFactoringC', '...', 'ftNotAcceptedForFactoringC', 'ftNotAcceptedForFactoringC');

*/
  }
}
