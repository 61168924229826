import {McBillingPayoutFileItemFeCtdFilterGENERATED} from '../_generated/mc-billing-payout-file-item-fe-ctd-filter-generated.model';

export class McBillingPayoutFileItemFeCtdFilter extends McBillingPayoutFileItemFeCtdFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingPayoutFileItemFeCtdFilter();
  }

  // ---------------------------------------------------------------------
}
