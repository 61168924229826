/*BbmTestObj*/
import {CcMollieTransactionGENERATED} from '../_generated/cc-mollie-transaction-generated.model';

export class CcMollieTransaction extends CcMollieTransactionGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CcMollieTransaction();
  }

  // ---------------------------------------------------------------------
}
