export const ICON_DATA_IMPORT = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_953_8981)">
<path d="M5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4H6C5.45 4 5 4.45 5 5ZM7.41 14H9V19C9 19.55 9.45 20 10 20H14C14.55 20 15 19.55 15 19V14H16.59C17.48 14 17.93 12.92 17.3 12.29L12.71 7.7C12.32 7.31 11.69 7.31 11.3 7.7L6.71 12.29C6.08 12.92 6.52 14 7.41 14Z" fill="#3B457C" stroke="#3B457C"/>
</g>
<defs>
<clipPath id="clip0_953_8981">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
`
