import {EbPsd2ReportFilterGENERATED} from '../_generated/eb-psd2-report-filter-generated.model';

export class EbPsd2ReportFilter extends EbPsd2ReportFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EbPsd2ReportFilter();
  }

  // ---------------------------------------------------------------------
}
