import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BaseObject, BaseObjectList, McForm, McGod} from '@miticon-ui/mc-core'
import {Subscription} from 'rxjs';
import {MccFiItemlistCheckboxesEventAfterValidation} from './mcc-fi-itemlist-checkboxes-event-after-validation';
import {MccFiCheckboxesEvent} from './mcc-fi-checkboxes-event.model';

@Component({
  selector: 'mcc-fi-itemlist-checkboxes',
  templateUrl: './mcc-fi-itemlist-checkboxes.component.html',
  styleUrls: ['./mcc-fi-itemlist-checkboxes.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MccFiItemlistCheckboxesComponent implements OnInit, OnDestroy, OnChanges {

  requiredFlg!: Validators;
  validatorList = [];
  // checkBoxesForm: FormGroup;
  labelPositionClass!: string;
  arrayOfValues!: string[];
  mcGod = McGod.getInstance();
  formValidationTriggerSubscription!: Subscription;
  mccFiCheckboxesEvent = new MccFiCheckboxesEvent();

  @Input() filterPropertyValue: any;
  @Input() filterPropertyOrMethod: any;

  @Input() itemDescriptionPropertyName!: string;
  @Input() mcForm!: McForm;
  @Input() extraCssClasses!: string;
  @Input() name!: string;
  @Input() values!: number[];
  @Input() itemValuePropertyName!: string;
  @Input() itemLabelPropertyName!: string;
  // @Input() frmGroup: FormGroup;
  @Input() label!: string;
  @Input() validRequiredFlg = false;
  @Input() validMinChecked!: number;
  @Input() validMaxChecked!: number;
  // @Input() customValidator;
  // @Input() validationSetupObject: McValidation;
  @Input() validationInComponent = false;
  @Output() eventValueChanged = new EventEmitter();
  @Output() eventAfterValidation = new EventEmitter();
  @Output() eventItemChanged = new EventEmitter();
  // @Output() eventValidationTurnOn = new EventEmitter();
  // @Input() validationTrigger = false;
  @Input() itemList!: BaseObjectList;
  @Input() directionHorizontal = true;
  @Input() extraCssClass: any;
  @Input() labelOnTop = true;

  constructor() {
  }

  getCheckBoxesForm(): FormGroup {
    return this.mcForm.getControl(this.name) as FormGroup;
  }

  createCheckBoxesFormControl() {
    if (!this.getCheckBoxesForm()) {
      const checkBoxesForm = this.mcForm.formBuilder.group({
        value: [],
        label: [this.label],
        errorFlg: [false],
        errorMsg: ['']
      });

      if (this.mcForm) {
        this.mcForm.addControl(this.name, checkBoxesForm);
      }
    }

  }

  ngOnInit() {

    this.arrayOfValues = [];
    this.labelPositionCssClass();
    if (this.mcForm) {
      this.formValidationTriggerSubscription = this.mcForm.getValidationTriggerObservable().subscribe(() => {
        this.validateCheckBoxes();
      });
    }
    this.createCheckBoxesFormControl();
    this.itemList.items.forEach((item: BaseObject) => {
      this.getCheckBoxesForm().addControl(this.getItemCtrlName(item), new FormControl());
    });

    if (this.values) {
      this.values.forEach(id => {
        const item = this.itemList.getItemById(id);
        if (item != null) {
          this.getCheckBoxesForm().get(this.getItemCtrlName(item))?.setValue(true);
          this.arrayOfValues.push(id.toString());
        }
      });
    }
    this.getCheckBoxesForm().get('value')?.setValue(this.arrayOfValues);

    this.validationSetup();
    this.onCheckBoxesChanged();

  }

  ngOnDestroy(): void {
    if (this.formValidationTriggerSubscription) {
      this.formValidationTriggerSubscription.unsubscribe();
    }
    this.mcForm.formGroup.removeControl(this.name);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createCheckBoxesFormControl();
    if (changes['itemList'] && this.getCheckBoxesForm() != null) {
      this.itemList = changes['itemList'].currentValue;
      this.itemList.items.forEach((item: BaseObject) => {
        this.getCheckBoxesForm().addControl(this.getItemCtrlName(item), new FormControl());
      });
    }
  }

  private validatorRequiredCheckBoxes(control: FormControl) {
    return control.value.length > 0 ? null : {
      requiredCheckBoxes: {
        valid: false
      }
    };
  }

  private validatorMinCheckBoxes(min: number) {
    return (control: FormControl) => {
      if (control.value && control.value.length >= min) {
        return null;
      } else {
        return {
          minCheckBoxes: {
            valid: false
          }
        };
      }
    };
  }

  private validatorMaxCheckBoxes(max: number) {
    return (control: FormControl) => {
      if (control.value && control.value.length <= max) {
        return null;
      } else {
        return {
          maxCheckBoxes: {
            valid: false
          }
        };
      }
    };
  }


  onCheckBoxesChanged() {
    this.itemList.items.forEach(item => {
      const ctrlName = this.getItemCtrlName(item);
      const ctrl = this.getCheckBoxesForm().get(ctrlName);
      const ctrlValue = this.getItemValue(item).toString();
      ctrl?.valueChanges.subscribe(() => {
        this.getCheckBoxesForm().get('errorFlg')?.setValue(false);
        this.getCheckBoxesForm().get('errorMsg')?.setValue(``);
        // this.eventValidationTurnOn.emit(false);
        if (this.getCheckBoxesForm().get(ctrlName)?.value === true) {
          this.arrayOfValues.push(ctrlValue);
          // this.checkBoxesForm.get("values").setValue("");
          this.getCheckBoxesForm().get('value')?.setValue(this.arrayOfValues);
          this.mccFiCheckboxesEvent.value = ctrlValue;
          this.mccFiCheckboxesEvent.checkedFlag = true;
        } else {
          const index = this.arrayOfValues.indexOf(ctrlValue);
          if (index > -1) {
            this.arrayOfValues.splice(index, 1);
            // this.checkBoxesForm.get("values").setValue("");
            this.getCheckBoxesForm().get('value')?.setValue(this.arrayOfValues);
            this.mccFiCheckboxesEvent.value = ctrlValue;
            this.mccFiCheckboxesEvent.checkedFlag = false;
          }
        }
        if (this.eventValueChanged) {
          this.eventValueChanged.emit(this.arrayOfValues);
        }
        if (this.eventItemChanged) {
          this.eventItemChanged.emit(this.mccFiCheckboxesEvent);
        }
      });
    });
  }


  validationSetup() {


    if (this.validRequiredFlg) {
      // @ts-ignore
      this.validatorList.push(this.validatorRequiredCheckBoxes);
    }

    if (this.validMinChecked) {
      // @ts-ignore
      this.validatorList.push(this.validatorMinCheckBoxes(this.validMinChecked));
    }

    if (this.validMaxChecked) {
      // @ts-ignore
      this.validatorList.push(this.validatorMaxCheckBoxes(this.validMaxChecked));
    }

    /*if (this.customValidator) {
      this.validatorList.push(this.customValidator);
    }*/
    this.getCheckBoxesForm().get('value')?.clearValidators();
    this.getCheckBoxesForm().get('value')?.setValidators(this.validatorList);
  }

  validateCheckBoxes() {
    this.validationSetup();
    if (this.getCheckBoxesForm().get('value')?.errors) {
      // @ts-ignore
      const validators = Object.keys(this.getCheckBoxesForm().get('value').errors);
      if (validators) {
        this.getCheckBoxesForm().get('errorFlg')?.setValue(true);
        validators.forEach(item => {
          switch (item) {
            case 'requiredCheckBoxes':
              this.getCheckBoxesForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.this-field-is-required')}`);
              break;
            case 'minCheckBoxes':
              this.getCheckBoxesForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.please-select-at-least')} ${this.validMinChecked}
               ${this.validMinChecked > 1 ? 'items' : 'item'}.`);
              break;
            case 'maxCheckBoxes':
              this.getCheckBoxesForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.the-maximum-number-of-selected-item-is')} ${this.validMaxChecked}.`);
              break;
          }
        });
      }
    } else {
      this.getCheckBoxesForm().get('errorFlg')?.setValue(false);
      this.getCheckBoxesForm().get('errorMsg')?.setValue(``);
    }
    this.fireEventAfterValidation();
  }

  labelPositionCssClass() {
    if (this.labelOnTop === true) {
      this.labelPositionClass = 'flex-column';
    } else {
      this.labelPositionClass = 'flex-row';
    }
  }

  public fireEventAfterValidation() {
    if (this.eventAfterValidation) {
      const eventObj = new MccFiItemlistCheckboxesEventAfterValidation();
      eventObj.validFlg = !this.getCheckBoxesForm().get('value')?.errors;

      this.eventAfterValidation.emit(eventObj);
    }
  }

  clearErrors() {
    this.getCheckBoxesForm().get('errorFlg')?.setValue(false);
    this.getCheckBoxesForm().get('errorMsg')?.setValue('');
  }


  getItemLabel(item: BaseObject) {
    return item.execPropertyOrMethodByName(this.itemLabelPropertyName);
  }

  getItemCtrlName(item: BaseObject) {
    return 'ctrl' + this.getItemValue(item);
  }

  getItemValue(item: BaseObject) {
    return item.execPropertyOrMethodByName(this.itemValuePropertyName);
  }

  getItemDescription(item: BaseObject) {
    return item.execPropertyOrMethodByName(this.itemDescriptionPropertyName);
  }

  hasItemDescription(item: BaseObject) {
    return this.itemDescriptionPropertyName;
  }

  filterItem(item: BaseObject): boolean {
    if (this.filterPropertyValue) {
      return this.filterPropertyValue === item.execPropertyOrMethodByName(this.filterPropertyOrMethod);
    } else {
      return true;
    }
  }
}
