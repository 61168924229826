<div class="dialog">
  <div class="dialog_header">
    <span class="title-medium-dark">{{data.title}}</span>
    <span class="dialog_header_icon"
          [innerHTML]="iconClose | safeHtml"
          (click)="dialogRef.close()">
      </span>
  </div>

  <mat-dialog-content>
    <mk-mat-table
      [items]="items"
      [config]="tableConfig"
      [isLoading]="isLoading"
      [totalItemsCount]="totalItemsCount"
      [itemsPerPage]="pageSize"
      [showCheckbox]="false"
      (filterChanged)="onFilterChanged($event)">
    </mk-mat-table>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button class="secondary-btn"
            (click)="dialogRef.close()">
      {{ "cc.common.close" | translate }}
    </button>
  </div>
</div>
