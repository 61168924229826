import { McStateManagementService } from "../state/state-management.service";
import {BaseObject} from "../_core/base-object";

export class Table2ColumnConfig {
  private _builder: any = null;

  name: string;
  label: string;
  objectPropertyOrMethodName: string;
  isHide = false;
  selected = false;
  editComponentClass: any;
  editComponentTemplateConfigObj: any; // TODO Properly typecast
  editObjectPropertyOrMethodName!: string;
  editObjectPropertyBoundFlg = true;
  sortFlag: boolean;
  extraCssClass!: string;
  onEditComponentGetConfigObj!: (aCurrentConfigObj: any, aCurrentObjectItem: BaseObject) => any;
  clickEvent: any;
  customMethod: any;
  isUsingServiceBridge = false;
  isUsingOnInit = false;
  extraService: any;
  extraServiceProperty: any;

  // ---------------------------------------------------------------------
  constructor(aColumnUniqueName: string, aColumnLabel: string, aObjectPropertyOrMethodName: string, aSortFlag: boolean) {
    this.name = aColumnUniqueName;
    this.label = aColumnLabel;
    this.objectPropertyOrMethodName = aObjectPropertyOrMethodName;
    this.sortFlag = aSortFlag;
  }

  // ---------------------------------------------------------------------
  // TODO At some point we should add the ability to have more than one component here. It is easy.
  addEditComponent(aEditComponentClass: any, aEditObjectPropertyOrMethodName: string, aEditComponentTemplateConfigObj?: any): void {
    this.editComponentClass = aEditComponentClass;
    this.editObjectPropertyOrMethodName = aEditObjectPropertyOrMethodName;
    this.editComponentTemplateConfigObj = aEditComponentTemplateConfigObj;
  }

  // ---------------------------------------------------------------------
  public getBuilder(): BuilderTable2ColumnConfig {
    if (this._builder == null) {
      this._builder = new BuilderTable2ColumnConfig(this);
    }
    return this._builder;
  }

  /*Call this cusom click on td-cell in table on click*/
  public addClickEvent(aMethod: (aItemObject: any) => void) {
    this.clickEvent = aMethod;
    return this;
  }

  /*Call this matheod when td-cell is initialized in view*/
  public setCustomMethod(aMethod: any) {
    this.customMethod = aMethod;
    return this;
  }

  /*Set using bridge state management
  * set service and service property which will be updated*/
  public useServiceBridge(aService: McStateManagementService, aServiceProperty: string) {
    this.extraService = aService;
    this.extraServiceProperty = aServiceProperty;
    this.isUsingServiceBridge = true;
    return this;
  }

  public useOnInit(aService: any, aServiceProperty: any) {
    this.extraService = aService;
    this.extraServiceProperty = aServiceProperty;
    this.isUsingOnInit = true;
    return this;
  }

  /*Set value in state bridge*/
  public configureServiceBridge(aItemObject: any): any {

    /*Stop if method usingServiceBridge is not called*/
    if (!this.extraService || !this.extraServiceProperty)
      return  null;

    /*Check if property in service is method*/
    if (this.extraService[this.extraServiceProperty] && this.extraService[this.extraServiceProperty].hasOwnProperty('prototype'))
      this.extraService[this.extraServiceProperty](aItemObject);
    else
      this.extraService[this.extraServiceProperty] = aItemObject;

  }

  /*Update class*/
  public update(aObject: this) {
    // @ts-ignore
    Object.keys(aObject).forEach(key => this[key] = aObject[key]);
    return this;
  }
}

// ---------------------------------------------------------------------
class BuilderTable2ColumnConfig {
  columnConfig: Table2ColumnConfig;

  constructor(aColumnConfig: Table2ColumnConfig) {
    this.columnConfig = aColumnConfig;
  }

  // ---------------------------------------------------------------------
  public setEditComponent(aEditComponentClass: any, aEditObjectPropertyOrMethodName: string, aEditComponentTemplateConfigObj?: any): BuilderTable2ColumnConfig {
    this.columnConfig.addEditComponent(aEditComponentClass, aEditObjectPropertyOrMethodName, aEditComponentTemplateConfigObj);
    console.log(aEditComponentClass);
    console.log(aEditObjectPropertyOrMethodName);
    console.log(aEditComponentTemplateConfigObj)
    return this;
  }

  // ---------------------------------------------------------------------
  public setEditObjectPropertyBoundFlg(aValue: boolean) {
    this.columnConfig.editObjectPropertyBoundFlg = aValue;
    return this;
  }
  // ---------------------------------------------------------------------
  public setExtraCssClass(aValue: string) {
    this.columnConfig.extraCssClass = aValue;
    return this;
  }
}
