import {Inject, Injectable} from '@angular/core';
import {McMappingServiceGENERATED} from '../_generated/mc-mapping-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McMappingService extends McMappingServiceGENERATED {


}
