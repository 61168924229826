import { McPricelistPricelistProfilePageComponent } from './pages/mc-pricelist-pricelist-profile-page/mc-pricelist-pricelist-profile-page.component';
import { McPricelistPricelistsListPageComponent } from './pages/mc-pricelist-pricelists-list-page/mc-pricelist-pricelists-list-page.component';
import { McPricelistServicesAndPricelistListPageComponent } from './pages/mc-pricelist-services-and-pricelist-list-page/mc-pricelist-services-and-pricelist-list-page.component';
import { McPricelistTemplatesListPageComponent } from './pages/mc-pricelist-templates-list-page/mc-pricelist-templates-list-page.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard, McPermissionGuard} from '@miticon-ui/mc-core';

const routes: Routes = [
{
    path: 'overview/:id',
    component: McPricelistPricelistProfilePageComponent
  },
{
    path: 'pricelists',
    component: McPricelistPricelistsListPageComponent
  },
{
    path: 'services-and-pricelist',
    component: McPricelistServicesAndPricelistListPageComponent
  },
{
    path: 'templates',
    component: McPricelistTemplatesListPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McPricelistRoutingModule {
}
