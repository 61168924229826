import {McConsumerHistory} from './mc-consumer-history.model';
import {McConsumerHistoryListGENERATED} from '../_generated/mc-consumer-history-list-generated.model';

export class McConsumerHistoryList extends McConsumerHistoryListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McConsumerHistoryList();
  }

  // ---------------------------------------------------------------------
}
