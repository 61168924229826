import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  EbSepaExport, EbSepaFileTransaction,
  EbSepaFileTransactionList,
  McBankAccountList, McBoolean, McDateUtils, McEntity2,
  McForm,
  McGod,
  WebFile
} from "@miticon-ui/mc-core";
import {NgbCalendar} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'mc-out-factoring-sepa-export-part',
  templateUrl: './mc-out-factoring-sepa-export-part.component.html',
})
export class McOutFactoringSepaExportPartComponent implements OnInit {
  mcForm = new McForm();
  bankAccounts = new McBankAccountList();
  entity = new McEntity2();
  disableWeekendDays: any;

  sepaExport = new EbSepaExport();
  sepaExportWebFile = new WebFile();
  currentStep: 1 | 2 = 1;
  errorMsgs: string[] = [];
  mcGod = McGod.getInstance();

  @Input() ebSepaFileTransactionList!: EbSepaFileTransactionList;
  @Output() eventCanceled = new EventEmitter();
  @Output() eventExported = new EventEmitter();
  @Output() eventClosed = new EventEmitter();
  exportButtonDisabledMcb = new McBoolean();
  largestTransactionDate!: number;
  largestTransactionDateString!: string;

  ngOnInit() {

    this.entity.loadById(McGod.getLoggedEntityIdFromToken(), () => {
      this.bankAccounts.loadByEntityId(McGod.getLoggedEntityIdFromToken(), () => {
        this.validatePrerequisites();
      });
    });

    this.checkLargestRequestCollectionDate();



  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actClose() {
    this.eventClosed.emit();
  }

  // ---------------------------------------------------------------------
  actExport() {
    if (this.mcForm.isValid()) {
      const bankAccountId: number = this.mcForm.getValue('selBankAccountId');
      const collectionDate: number = this.mcForm.getValue('dpCollectionDate');

      const newSepaExport = new EbSepaExport();
      newSepaExport.populateForExport(bankAccountId, collectionDate);

      this.sepaExport.exportSepaAndGet(newSepaExport, this.ebSepaFileTransactionList.getIds(), () => {
        if (this.sepaExport != null) {
          this.sepaExportWebFile.getFileByIdAndType(this.sepaExport.idWebfile, WebFile.TYPE_CD_EB_BANK_SEPA_EXPORT, () => {
            this.currentStep = 2;
            this.eventExported.emit();
          });
        }
      }, () => {
        this.exportButtonDisabledMcb.setFalse();
      });
    } else {
      this.exportButtonDisabledMcb.setFalse();
    }
  }

  // ---------------------------------------------------------------------
  isLoading() {
    return this.bankAccounts.apiLoadingFlg || this.entity.apiLoadingFlg;
  }

  // ---------------------------------------------------------------------
  validatePrerequisites() {
    this.errorMsgs = [];

    if (this.bankAccounts.getCount() === 0) {
      this.errorMsgs.push(this.mcGod.t('cc.out-factoring.entity-must-have-at-least-one-bank-account-added-please-navigate-to-entity-profile-and-set-it'));
    }

    if ((!this.entity.extCreditorId) || (this.entity.extCreditorId.length === 0)) {
      this.errorMsgs.push(this.mcGod.t('cc.out-factoring.entity-must-have-ext-creditor-id-specified-plase-navigate-to-entity-profile-and-set-it'));
    }

    if (this.ebSepaFileTransactionList.getCount() === 0) {
      this.errorMsgs.push(this.mcGod.t('cc.out-factoring.no-transactions-selected-please-go-back-and-select-transactions-you-wish-to-export'));
    } else {
      const badStatusItems: EbSepaFileTransaction[] = this.ebSepaFileTransactionList.items
        .filter((item: EbSepaFileTransaction) => !item.isStatusCdACCEPTED() && !item.isStatusCdRETURNED());
      if (badStatusItems.length > 0) {
        this.errorMsgs.push('Some selected transactions are not in correct status and can\'t be exported!  ' +
          'Please go back and select transactions in Accepted or Returned status only and try again.');
      }
    }

    return this.errorMsgs.length === 0;
  }

  // ---------------------------------------------------------------------
  hasErrors() {
    return this.errorMsgs.length > 0;
  }
  // ---------------------------------------------------------------------
  isStep1() {
    return (this.currentStep === 1) && this.bankAccounts.apiSuccessFlg && this.entity.apiSuccessFlg && (!this.hasErrors());
  }

  // ---------------------------------------------------------------------
  isStep2() {
    return (this.currentStep === 2) && this.bankAccounts.apiSuccessFlg && this.entity.apiSuccessFlg && (!this.hasErrors());
  }
  // ---------------------------------------------------------------------

  downloadFile() {
    this.sepaExportWebFile.download();
  }

  checkLargestRequestCollectionDate() {
    let largestDate = 0;
    this.ebSepaFileTransactionList.items.forEach( item => {
      if (item.requestedCollectionDate) {
        largestDate = McDateUtils.compareAndReturnLargerDate(largestDate, item.requestedCollectionDate);
      } else {
        largestDate = McDateUtils.compareAndReturnLargerDate(largestDate, item.batchCollectionDate);
      }
    });
    this.largestTransactionDate = largestDate;
    this.largestTransactionDateString = McDateUtils.formatDateToString(largestDate);
  }



}
