import { Injectable} from '@angular/core';
import { HttpHeaders} from '@angular/common/http';
import {EbPsd2Report} from '../models/eb-psd2-report.model';
import {EbPsd2ReportServiceGENERATED} from '../_generated/eb-psd2-report-generated.service';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EbPsd2ReportService extends EbPsd2ReportServiceGENERATED {

  public getPsd2Statement(idMcBankAccount: number, dateFrom: string, dateTo: string): Observable<any> {
    const requestBody: any = {
      "idMcBankAccount": idMcBankAccount,
      "dateFrom": dateFrom,
      "dateTo": dateTo
    }

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/transactions`, requestBody, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

}
