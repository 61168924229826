import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { McBoolean, McGod, McProfileUserService, McUser } from "@miticon-ui/mc-core";

@Component({
  selector: "mc-user-user-profile-page",
  templateUrl: "./mc-user-user-profile-page.component.html",
  styleUrls: ["./mc-user-user-profile-page.component.scss"],
})
export class McUserUserProfilePageComponent implements OnInit {
  mcUserId!: number;
  mcUser!: McUser;
  userOldObject!: any;
  userImg!: string;
  userStatus!: string;
  userTimezone!: string;
  twoFaAuth!: string;
  placeholderImg!: "assets/images/user-icon.png";
  showUserAddEditPart = new McBoolean();
  isEnabledTwoFa!: boolean;

  // ---------------------------------------------------------------------
  constructor(private route: ActivatedRoute, private profileUserService: McProfileUserService, @Inject("designConfig") design: any) {}

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      // @ts-ignore
      this.mcUserId = +params.get("id");
      this.mcUser = new McUser();
      this.actLoad();
    });
  }

  getUserTimezone() {
    if (this.mcUser.timezone === 1) {
      return "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague";
    } else {
      return "(UTC-08:00) Pacific Time (US & Canada)";
    }
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.getUserByOldMethod();
    this.mcUser.loadById(this.mcUserId, () => {});
    this.profileUserService.getUserData(this.mcUserId).subscribe((response) => {
      this.isEnabledTwoFa = response.is2faEnabled;
      if (response.is2faEnabled) {
        this.twoFaAuth = McGod.t("cc.common.edit.enabled");
      } else {
        this.twoFaAuth = McGod.t("cc.common.view.disabled");
      }
    });
  }

  // -------------------------------------------------------
  // Old method for getting user

  getUserByOldMethod() {
    this.profileUserService.getSystemUserData(this.mcUserId).subscribe(
      (response) => {
        this.userOldObject = response;
        this.userImg = response.image;
        this.userStatus = response.statusCode;
        this.userTimezone = response.timezone;
      },
      (error) => {
        if (error.status >= 500) {
          return;
        }
      }
    );
  }

  getUserCountry(): string {
    if (this.mcUser.isoCountry === "US") {
      return `USA <img class="mc-user-profile-status-icon" src="assets/images/usa.png">`;
    }
    if (this.mcUser.isoCountry === "DE") {
      return `Germany <img class="mc-user-profile-status-icon" src="assets/images/germany.png">`;
    }
    if (this.mcUser.isoCountry === "IT") {
      return `Italy <img class="mc-user-profile-status-icon" src="assets/images/italy.png">`;
    }
    return "";
  }

  getUserLanguage(): string {
    if (this.mcUser.isoLanguage === "EN") {
      return "English";
    }
    if (this.mcUser.isoLanguage === "DE") {
      return "German";
    }
    if (this.mcUser.isoLanguage === "IT") {
      return "Italian";
    }
    return "";
  }

  onUserProfileAddEditSaved(event: any) {
    this.showUserAddEditPart.setFalse();
    this.actLoad();
  }

  onUserProfileAddEditCanceled(event: any) {
    this.showUserAddEditPart.setFalse();
  }

  onChange2Fa($event: any) {
    if ($event) {
      this.isEnabledTwoFa = true;
      this.twoFaAuth = McGod.t("cc.common.edit.enabled");
    } else {
      this.isEnabledTwoFa = false;
      this.twoFaAuth = McGod.t("cc.common.view.disabled");
    }
  }
}
