/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {CsrAccountConsumer} from '../models/csr-account-consumer.model';
import {CsrAccountConsumerService} from '../services/csr-account-consumer.service';
import {ICsrAccountConsumer} from '../_generated/csr-account-consumer.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class CsrAccountConsumerGENERATED extends BaseObject {


    public static readonly PAYMENT_METHOD_TRANSFER: string = 'TRANSFER';
    public static readonly PAYMENT_METHOD_CASH: string = 'CASH';
  public static readonly PAYMENT_METHOD_LIST__ALL = [
    CsrAccountConsumerGENERATED.PAYMENT_METHOD_TRANSFER,
    CsrAccountConsumerGENERATED.PAYMENT_METHOD_CASH
];

    public static readonly TYPE_WITHDRAWAL: string = 'WITHDRAWAL';
    public static readonly TYPE_PAYMENT: string = 'PAYMENT';
  public static readonly TYPE_LIST__ALL = [
    CsrAccountConsumerGENERATED.TYPE_WITHDRAWAL,
    CsrAccountConsumerGENERATED.TYPE_PAYMENT
];

  public apiService: CsrAccountConsumerService;
  public _rawJson: ICsrAccountConsumer;
    id: number = 0;
  amount: number;
  idCsrConsumer: number;
  paymentMethod: string;
  sysCreatedDatetime: number;
  type: string;


  public properties: string[] = ['id', 'amount', 'idCsrConsumer', 'jsonbTransactionDetails', 'paymentMethod', 'sysCreatedDatetime', 'type'];
  public propertiesRegular: string[] = ['id', 'amount', 'csrConsumer', 'idCsrConsumer', 'jsonbTransactionDetails', 'paymentMethod', 'sysCreatedDatetime', 'type'];
  public propertiesSpecial: string[] = [];



  public static getPaymentMethodVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrAccountConsumerGENERATED.PAYMENT_METHOD_TRANSFER, McGod.t('Transfer'));
    list.add(CsrAccountConsumerGENERATED.PAYMENT_METHOD_CASH, McGod.t('Cash'));


    return list;
  }

  public static getPaymentMethodLabel(aCd: string): string {
    const list = CsrAccountConsumerGENERATED.getPaymentMethodVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrAccountConsumerGENERATED.TYPE_WITHDRAWAL, McGod.t('Withdrawal'));
    list.add(CsrAccountConsumerGENERATED.TYPE_PAYMENT, McGod.t('Payment'));


    return list;
  }

  public static getTypeLabel(aCd: string): string {
    const list = CsrAccountConsumerGENERATED.getTypeVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): CsrAccountConsumer {
    console.error('MTCN-ERROR: Not Implemented: CsrAccountConsumer::createNewInstance(). Add this method to final class and return new CsrAccountConsumer();');
    throw new Error('Cannot CsrAccountConsumer::createNewInstance(). Add this method to final class and return new CsrAccountConsumer();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: ICsrAccountConsumer): CsrAccountConsumer {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrAccountConsumerService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CsrAccountConsumer AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getAmountStr(): string {
       return McGod.formatNumberToMoney(this.amount);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getPaymentMethodLabel(): string {
    return CsrAccountConsumerGENERATED.getPaymentMethodLabel(this.paymentMethod);
  }


 public  isPaymentMethodTRANSFER(): boolean {
    const result = (this.paymentMethod === CsrAccountConsumerGENERATED.PAYMENT_METHOD_TRANSFER);

    return result;
  }

 public  isPaymentMethodCASH(): boolean {
    const result = (this.paymentMethod === CsrAccountConsumerGENERATED.PAYMENT_METHOD_CASH);

    return result;
  }


  public getTypeLabel(): string {
    return CsrAccountConsumerGENERATED.getTypeLabel(this.type);
  }


 public  isTypeWITHDRAWAL(): boolean {
    const result = (this.type === CsrAccountConsumerGENERATED.TYPE_WITHDRAWAL);

    return result;
  }

 public  isTypePAYMENT(): boolean {
    const result = (this.type === CsrAccountConsumerGENERATED.TYPE_PAYMENT);

    return result;
  }


  
}
