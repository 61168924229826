import {McProcessNotificationFilterGENERATED} from '../_generated/mc-process-notification-filter-generated.model';

export class McProcessNotificationFilter extends McProcessNotificationFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new McProcessNotificationFilter();
  }

  // ---------------------------------------------------------------------
}
