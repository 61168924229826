
import {Table3Config} from '../extra/table-3-config';

// import {McBillingBatch2TransactionGENERATED} from '../_generated/mc-billing-batch-2-transaction-generated.model';

export class McBillingBatch2TransactionTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colIdBatch', 'Batch id', 'idBatch', 'idBatch');
    this.addColumn('colIdTransaction', 'Transaction id', 'idTransaction', 'idTransaction');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');

*/
  }
}
