import {McConsumerImportFilterGENERATED} from '../_generated/mc-consumer-import-filter-generated.model';

export class McConsumerImportFilter extends McConsumerImportFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McConsumerImportFilter();
  }

  // ---------------------------------------------------------------------
}
