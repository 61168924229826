import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {McEntityService, McRoleService, McUtilityService} from '@miticon-ui/mc-core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'mc-edit-role-basic-info-modal',
  templateUrl: './mc-edit-role-basic-info-modal.component.html',
})
export class McEditRoleBasicInfoModalComponent implements OnInit {

  selectedEntityPath!: any;
  isFormValid!: boolean;
  errorObject = {
    type: '',
    message: ''
  };

  /*Private variables*/
  private modalOpenSubscription!: any;
  private form = {};
  private selectedPermissions = [];

  /*Events*/
  @Input() basicInfo!: any;
  @Input() editPermissions!: any;
  @Input() isRoleLinked!: boolean;
  @Output() onUpdaetRole = new EventEmitter<boolean>();

  /*Constructor*/
  constructor(private modalService: NgbModal,
              private roleService: McRoleService,
              private utilityService: McUtilityService,
              private entityService: McEntityService) {
    /*Get selected entity path from service*/
    this.entityService._selectedEntityPath.subscribe(path => {
      this.selectedEntityPath = path;
    });
  }

  /*On init class*/
  ngOnInit() {
    this.isFormValid = !this.editPermissions;
  }

  /*Open modal*/
  public openXl(content: any): void {
    if (!this.isRoleLinked) {
      this.modalOpenSubscription = this.modalService
        .open(content, {
          size: 'lg',
          windowClass: 'mc-create-role-modal',
          backdrop: "static",
          beforeDismiss: () => {
            this.getForm({});
            this.errorObject = Object.assign(this.errorObject, {
              type: '',
              message: ''
            });
            return true;
          }
        });
    }
  }

  /*Update role*/
  public onUpdate() {
    this.errorObject = Object.assign(this.errorObject, {
      type: '',
      message: ''
    });
    if (!this.isFormValid) {
      let form: any;

      if (Object.keys(this.form).length > 0) {
        form = {
       /*   id: this.basicInfo.id,
          active: this.form['status'].toLowerCase() === 'active',
          caption: this.form['caption'],
          description: this.form['description'],
          mcEntityId: this.utilityService.getPropertyFromToken('entity_id'),
          templateFlg: this.form['setRoleAsATemplate'],
          isProtected: true,*/
        };
      } else {
        form = {
          id: this.basicInfo.id,
          active: this.basicInfo['active'],
          caption: this.basicInfo['caption'],
          description: this.basicInfo['description'],
          templateFlg: this.basicInfo['templateFlg'] ? this.basicInfo['templateFlg'] : false,
          mcEntityId: this.utilityService.getPropertyFromToken('entity_id'),
          isProtected: true,
          permissionIds: []
        };

        this.selectedPermissions.forEach((item: any) => item.permissions.forEach((permission: any) => permission.id && form.permissionIds.push(permission.id)));
      }
      console.log('this form', this.form);
      console.log('form', form);

      this.roleService.updateEntityRole(form)
        .subscribe(response => {
            this.onUpdaetRole.emit(true);
            this.modalOpenSubscription.close();
          },
          error => {
            if (error.error.message) {
              this.errorObject = Object.assign(this.errorObject, {
                type: 'error',
                message: error.error.message
              });
            } else {
              this.errorObject = Object.assign(this.errorObject, {
                type: 'error',
                message: error.error.error_description
              });
            }
          });

    } else {
      this.errorObject = Object.assign(this.errorObject, {
        type: 'error',
        message: 'Need to fill all fields.'
      });
    }
  }

  /*Validate form*/
  public onCurrentFormValid(event: any): void {
    this.isFormValid = event;
  }

  /*Get basic form*/
  public getForm(event: any): void {
    this.form = event;
  }

  /*Get selected permissions*/
  public getPermissions(event: any): void {
    this.selectedPermissions = event;
  }

  getTooltipForEditIcon() {
    if (this.isRoleLinked && this.editPermissions) {
      return 'Permissions for linked roles can not be edited';
    } else if (this.isRoleLinked && !this.editPermissions) {
      return 'Basic info for linked roles can not be edited';
    } else {
      return '';
    }
  }
}
