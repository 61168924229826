import {Component, OnInit} from '@angular/core';
import {
  CsrConsumer,
  CsrConsumerFilter,
  CsrConsumerList, CsrConsumerService,
  McBoolean,
  McConsumer,
  CsrConsumerImport,
  McForm,
  McGod,
  McValueLabelList, SortCriteriaList
} from '@miticon-ui/mc-core';
import {AppSharedTransactionAddEditPartConfig} from '../../../app-shared/components/app-shared-transaction-add-edit-part/app-shared-transaction-add-edit-part-config.model';
import {MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkMatTableMenuAction, MkTableConfig} from '@miticon-ui/mc-components';
import {Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'mc-consumer-consumer-list-page',
  templateUrl: './mc-consumer-consumer-list-page.component.html',
})
export class McConsumerConsumerListPageComponent implements OnInit {

  mcForm!: McForm;
  showAddToBlacklistMcb = new McBoolean();
  showRemoveFromBlacklistMcb = new McBoolean();
  statusCdsVll: McValueLabelList = McConsumer.getStatusCdVll();
  typeCdsVll: McValueLabelList = McConsumer.getTypeCdVll();
  showAddEditModalMcb = new McBoolean();
  addEditConsumerId = 0;
  historyConsumerId = 0;
  mcGod = McGod.getInstance();
  showProfileMcb = new McBoolean();
  showHistoryMcb = new McBoolean();
  showImportConsumersStep1 = new McBoolean();
  showImportConsumersStep2 = new McBoolean();
  showAddTransactionsManuallyMcb = new McBoolean();
  addTansactionConfig = new AppSharedTransactionAddEditPartConfig();
  webFileId: number;
  showActionHistoryMcb = new McBoolean();
  successMsg!: string;
  csrConsumerImport = new CsrConsumerImport();
  consumerName = '';
  items = [];
  totalItemsCount = 0;
  pageSize = 50;
  showEntityNameColumn: null | McBoolean = null;


  // CsrConsumer
  csrConsumer = new CsrConsumer();
  csrConsumerList = new CsrConsumerList();
  isLoading = false;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  searchTooltip = McGod.t('cc.consumers-page.search-tooltip');
  mkMatMenuActionItems = [
    {
      title: McGod.t('cc.common.view-profile'),
      matIcon: 'remove_red_eye',
      actionCd: CsrConsumer.ACTION_SHOW_PROFILE,
      permission: McGod.PERM_MC_CONSUMER_VIEW
    },
    {
      title: McGod.t('cc.consumer.edit-consumer'),
      matIcon: 'edit',
      actionCd: CsrConsumer.ACTION_SHOW_EDIT,
      permission: McGod.PERM_MC_CONSUMER_EDIT
    },
    {
      title: McGod.t('cc.consumers.view.history'),
      matIcon: 'remove_red_eye',
      actionCd: CsrConsumer.ACTION_SHOW_HISTORY,
      permission: McGod.PERM_MC_CONSUMER_HISTORY_VIEW
    },
    {
      title: McGod.t('cc.common.view.view-transactions'),
      matIcon: 'remove_red_eye',
      actionCd: CsrConsumer.ACTION_SHOW_TRANSACTIONS,
      permission: McGod.PERM_MC_CONSUMER_TRANSACTION_VIEW
    },
    {
      title: McGod.t('cc.common.view.add-transaction'),
      matIcon: 'add_circle_outline',
      actionCd: CsrConsumer.ACTION_SHOW_ADD_TRANSACTION,
      permission: McGod.PERM_EB_FACTORING_MY_TRANSACTION_ADD
    },
    {
      title: 'Action History',
      matIcon: 'remove_red_eye',
      actionCd: CsrConsumer.ACTION_SHOW_ACTION_HISTORY,
      permission: McGod.PERM_MC_CONSUMER_ACTION_HISTORY_VIEW
    },
    {
      title: McGod.t('cc.consumers.view.invoices'),
      matIcon: 'pageview',
      actionCd: CsrConsumer.ACTION_SHOW_INVOICES,
      permission: McGod.PERM_CSR_CONSUMER_INVOICES_VIEW
    }
  ];

  constructor(private router: Router, private csrConsumerService: CsrConsumerService) {
  }


  ngOnInit() {
    this.mcForm = new McForm();
    this.csrConsumerList.setPager(0, 100);
    this.checkLoadConsumerByEntityName();
    this.initFilterConfig();

    this.actLoad(0, 50, new CsrConsumerFilter());
  }

  checkLoadConsumerByEntityName() {
    this.csrConsumerService.getSearchConsumersByEntityName().subscribe((res) => {
      this.showEntityNameColumn = new McBoolean();

      if (res) {
        this.showEntityNameColumn.setTrue();
        this.searchTooltip = McGod.t('cc.consumers-page.search-tooltip-with-entity-name');
      } else {
        this.showEntityNameColumn.setFalse();
      }

      this.initTableConfig();
    });
  }

  actAddSelectedToBlacklist() {
    this.showAddToBlacklistMcb.setTrue();
   // this.selectedConsumerList = this.mcConsumerList.getSelectedObjectList();
  }

  actRemoveSelectedFromBlacklist() {
    this.showRemoveFromBlacklistMcb.setTrue();
   // this.selectedConsumerList = this.mcConsumerList.getSelectedObjectList();
  }

  addConsumer() {
    this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/add`);
  }

  actShowAddEdit(itemId = 0) {
    this.addEditConsumerId = itemId;
    this.showAddEditModalMcb.setTrue();
  }

  actShowProfile(consumer: CsrConsumer) {
    if (consumer.exists()) {
      this.csrConsumer = consumer;
      this.showProfileMcb.setTrue();
    }
  }

  actShowAddTransactionManually(consumer: CsrConsumer) {
    if (consumer.exists()) {
      this.csrConsumer = consumer;
      this.showAddTransactionsManuallyMcb.setTrue();
    }
  }

  actShowHistory(itemId = 0) {
    this.historyConsumerId = itemId;
    this.showHistoryMcb.setTrue();
  }

  actShowActionHistory(consumer: CsrConsumer) {
   this.csrConsumer = consumer;
   this.showActionHistoryMcb.setTrue();
  }

  actActionHistoryCancel() {
    this.showActionHistoryMcb.setFalse();
    this.csrConsumer = new CsrConsumer();
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.mcForm.getControl('selStatusCd')?.get('value')?.setValue('');
    this.mcForm.getControl('selTypeCd')?.get('value')?.setValue('');
    this.actLoad(0, 100, new CsrConsumerFilter());
  }

  actCancel() {
    this.addEditConsumerId = -1; // TODO temp fix to force add edit component change event
    this.showAddEditModalMcb.setFalse();
    this.showProfileMcb.setFalse();
    this.csrConsumer = new CsrConsumer();
  }

  actOnAddEditSaved() {
    this.addEditConsumerId = -1; // TODO temp fix to force add edit component change event
    this.showAddEditModalMcb.setFalse();
    this.successMsg = this.mcGod.t('cc.consumer.consumer-saved-successfully');
    this.actLoad(0, 100, new CsrConsumerFilter());
  }

  // ---------------------------------------------------------------------


  actOnAddTransactionSaved() {
    this.actLoad(0, 100, new CsrConsumerFilter());
    this.addTansactionConfig.hide();
  }

  actShowImportConsumersStep1() {
    this.showImportConsumersStep1.setTrue();
  }

  actGoToDataImport() {
    this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/data/import/add`);
  }

  actCloseImportConsumerStep1() {
    this.showImportConsumersStep1.setFalse();
  }

  actImportConsumersGoToStep2(webFileId: number) {
    this.webFileId = webFileId;
    this.showImportConsumersStep2.setTrue();
    this.showImportConsumersStep1.setFalse();
  }

  actCloseImportConsumersStep2() {
    this.showImportConsumersStep2.setFalse();
  }

  actPrevToImportConsumersStep1() {
    this.showImportConsumersStep1.setTrue();
    this.showImportConsumersStep2.setFalse();
  }

  importedConsumers(webFileId: number) {
    this.showImportConsumersStep2.setFalse();
    this.csrConsumerImport.loadByEntityIdAndWebFileId(McGod.getLoggedEntityIdFromToken(), webFileId, () => {
      this.successMsg = 'Consumers imported successfully. Processed: ' + this.csrConsumerImport.processedRecords + ' Added: '
        + this.csrConsumerImport.addedRecords + ' Updated: ' + this.csrConsumerImport.updatedRecords + ' Skipped: ' + this.csrConsumerImport.skippedRecords;
    });
    this.actLoad(0, 100, new CsrConsumerFilter());
  }

  actAddTransactionManuallyCanceled() {
    this.showAddTransactionsManuallyMcb.setFalse();
    this.csrConsumer = new CsrConsumer();
  }

  actAddTransactionManuallySaved() {
    this.showAddTransactionsManuallyMcb.setFalse();
    this.csrConsumer = new CsrConsumer();
    this.successMsg = this.mcGod.t('cc.transactions.transaction-added-successfully') + '.';
    this.actLoad(0, 100, new CsrConsumerFilter());
  }

  //..........................................................


  actLoad(pageNumber: number, pageSize: number, filters: CsrConsumerFilter) {
    this.isLoading = true;
    let params = new HttpParams()
      .set('term', filters.searchTerm ? encodeURIComponent(filters.searchTerm) : '')
      .set('page', pageNumber)
      .set('size',pageSize);

    if (filters.statusCds && filters.statusCds.length > 0) {
      params = params.append('statusCds', filters.statusCds.toString());
    }

    if (filters.typeCds && filters.typeCds.length > 0) {
      params = params.append('typeCds', filters.typeCds.toString());
    }

    const sortCriteria = new SortCriteriaList();
    sortCriteria.addSort('id', 'DESC');

    this.csrConsumerService.get(params, pageNumber, pageSize, sortCriteria)
      .subscribe((res) => {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.pageSize = pageSize;
        this.items = res.content.map((object: any) => object = CsrConsumer.createFromJson(object));
      });
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('cc.consumers.view.consumer-name'), 'fldFullName()', 250, 'firstName');
    this.tableConfig.addColumnStandard(McGod.t('cc.consumer.view.consumer-id'), 'id', 250, 'id');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.edit.external-id'), 'extConsumerId', 250, 'extConsumerId');
    if (this.showEntityNameColumn != null && this.showEntityNameColumn.value) {
      this.tableConfig.addColumnStandard(McGod.t('cc.factoring.transactions.entity-name'), 'getEntityNameFromAdditionalAttributes()', 250, '');
    }
    this.tableConfig.addColumnStandard(McGod.t('cc.common.edit.email'), 'email', 250, 'email');
    this.tableConfig.addColumnInnerHtml(McGod.t('cc.common.edit.address'), 'fldAddress()', 'city');
    this.tableConfig.addColumnStandard(McGod.t('cc.consumers.edit.company'), 'companyName', 250, 'companyName');
    this.tableConfig.addColumnStandard(McGod.t('cc.jobs.edit.type'), 'getTypeCdLabel()', 250, 'typeCd');
  }

  initFilterConfig() {
    const statusCds = this.statusCdsVll.items.map((item) => new MkFilterValue(item.label, item.value));
    const typeCds = this.typeCdsVll.items.map((item) => new MkFilterValue(item.label, item.value));

    this.filterConfig.addItem(MkFilterItemType.SELECT, McGod.t(CsrConsumer.FILTER_STATUS), statusCds);
    this.filterConfig.addItem(MkFilterItemType.SELECT, McGod.t(CsrConsumer.FILTER_TYPE), typeCds);
  }

  onFilterChanged(filters: MkFilterOutput) {
    const csrConsumerFilter = new CsrConsumerFilter();
    this.csrConsumerList.setSortB(filters.sort.sortProperty, filters.sort.sortType);
    csrConsumerFilter.searchTerm = filters.search;
    csrConsumerFilter.statusCds = filters.selections[McGod.t(CsrConsumer.FILTER_STATUS)];
    csrConsumerFilter.typeCds = filters.selections[McGod.t(CsrConsumer.FILTER_TYPE)];

    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize, csrConsumerFilter);
  }


  onActionMkMatMenuItems($event: MkMatTableMenuAction) {
    this.csrConsumer = $event.item;
    switch ($event.action.actionCd) {
      case CsrConsumer.ACTION_SHOW_PROFILE: this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/${this.csrConsumer.id}/profile`);
      break;
      case CsrConsumer.ACTION_SHOW_EDIT: this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/${this.csrConsumer.id}/edit`);
      break;
      case CsrConsumer.ACTION_SHOW_ADD_TRANSACTION: this.actShowAddTransactionManually(this.csrConsumer);
      break;
      case CsrConsumer.ACTION_SHOW_HISTORY: this.actShowHistory(this.csrConsumer.id);
      break;
      case CsrConsumer.ACTION_SHOW_ACTION_HISTORY: this.actShowActionHistory(this.csrConsumer);
      break;
      case CsrConsumer.ACTION_SHOW_TRANSACTIONS: this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/transactions/${this.csrConsumer.id}`);
      break;
      case CsrConsumer.ACTION_SHOW_INVOICES: this.router.navigateByUrl(`admin/entity/${McGod.getLoggedEntityIdFromToken()}/management/invoices/consumers/${this.csrConsumer.id}`);
      break;
    }
  }
}
