import {PmAdjustmentNotificationInfo} from './pm-adjustment-notification-info.model';
import {PmAdjustmentNotificationInfoListGENERATED} from '../_generated/pm-adjustment-notification-info-list-generated.model';
import {HttpParams} from "@angular/common/http";

export class PmAdjustmentNotificationInfoList extends PmAdjustmentNotificationInfoListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PmAdjustmentNotificationInfoList();
  }

  // ---------------------------------------------------------------------

  public loadByParams(params: HttpParams, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return this.loadByParamsAction(params, onSuccessCallback, onErrorCallback);
  }

  public loadByParamsAction(
    params: any,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
  ): void {
    this.apiService
      .getAllByEntityId(params, this.pageNum, Number(this.pageItemsPerPageCount), this.sortCriteria)
      .subscribe(
        (responseJson: any) => {
          this.totalItemsCount = responseJson.totalElements;
          this.setApiSuccess();
          this.populateFromJsonPageable(responseJson);
          this.processPostLoad();
          this.processApiSuccess(responseJson, onSuccessCallback);
        },
        (errorResponse: any) => {
          this.setApiError();
          this.processApiError(errorResponse, onErrorCallback);
        }
      );
  }
}
