import {CsrContractHistory} from './csr-contract-history.model';
import {CsrContractHistoryListGENERATED} from '../_generated/csr-contract-history-list-generated.model';

export class CsrContractHistoryList extends CsrContractHistoryListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrContractHistoryList();
  }

  // ---------------------------------------------------------------------
}
