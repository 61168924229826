<ng-container *ngIf="csrContract.exists()">
  <div class="content-wrapper">

    <div *ngIf="csrContract.apiLoadingFlg">
      <img src="/assets/images/loading-whitebg.gif" />
    </div>

    <div class="content-header">
      <h1>{{'cc.contract.contract' | translate}} "{{mcConsumer.fldFullName()}} {{csrContract.id}}"</h1>
      <mcc-message [errorMsg]="csrContract.apiErrorMessage"></mcc-message>
    </div>

    <div class="content-body">
      <div class="info-data">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <h2>
                {{'cc.my-factoring.consumer-information' | translate}}
              </h2>
            </div>
          </div>
          <div class="col-md-12">
            <mcc-row-title-description      title="{{'cc.common.edit.company-name' | translate}}:" [description]="mcConsumer.companyName"></mcc-row-title-description>
            <mcc-row-title-description      title="{{'cc.common.edit.first-name' | translate}}:" [description]="mcConsumer.firstName"></mcc-row-title-description>
            <mcc-row-title-description     title="{{'cc.common.edit.last-name' | translate}}:" [description]="mcConsumer.lastName"></mcc-row-title-description>

            <mcc-row-title-description      title="{{'cc.common.view.entity' | translate}}:" [description]="mcConsumer.entityName"></mcc-row-title-description>
            <mcc-row-title-description      title="{{'cc.consumer.contract-id' | translate}}:" [description]="csrContract.id"></mcc-row-title-description>
            <mcc-row-title-description      title="{{'cc.consumer.view.consumer-id' | translate}}:" [description]="mcConsumer.id"></mcc-row-title-description>
          </div>

        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <h2>{{'cc.my-factoring.payment' | translate}}</h2>
            </div>
          </div>
          <div class="col-md-12">
            <mcc-row-title-description   title="{{'cc.my-factoring.payment' | translate}}:" [description]="csrContract.recurringDetails.getTypeCdLabel()"></mcc-row-title-description>

            <ng-container *ngIf="csrContract && csrContract.recurringDetails && csrContract.recurringDetails.isTypeCdQUARTERLY()">
              <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                         [description]="csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'')"></mcc-row-title-description>
              <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.month' | translate}}:"
                                         [description]="csrRecurringDetails.getQuarterlyVll().getLabel(csrContract.recurringDetails.month,'')"></mcc-row-title-description>
            </ng-container>
            <ng-container *ngIf="csrContract && csrContract.recurringDetails && csrContract.recurringDetails.isTypeCdMONTHLY()">
              <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                         [description]="csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'')"></mcc-row-title-description>
            </ng-container>
            <ng-container *ngIf="csrContract && csrContract.recurringDetails && csrContract.recurringDetails.isTypeCdWEEKLY() ">
              <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                         [description]="csrRecurringDetails.getWeekDaysVll().getLabel(csrContract && csrContract.recurringDetails  ? csrContract.recurringDetails.day : 0,'')"></mcc-row-title-description>
            </ng-container>
            <ng-container *ngIf="csrContract && csrContract.recurringDetails && csrContract.recurringDetails.isTypeCdYEARLY()">
              <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                         [description]="csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'')"></mcc-row-title-description>
              <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.month' | translate}}:"
                                         [description]="csrRecurringDetails.getYearlyVll().getLabel(csrContract.recurringDetails.month,'')"></mcc-row-title-description>
            </ng-container>
            <ng-container *ngIf="csrContract && csrContract.recurringDetails && csrContract.recurringDetails.isTypeCdHALF_YEARLY()">
              <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                         [description]="csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'')"></mcc-row-title-description>
              <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.month' | translate}}:"
                                         [description]="csrRecurringDetails.getHalfYearlyVll().getLabel(csrContract.recurringDetails.month,'')"></mcc-row-title-description>
            </ng-container>
            <!--<mcc-row-title-description  title="Day" [description]="csrContract.recurringDetails.day"></mcc-row-title-description>-->
          </div>
          </div>
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <h2>{{'cc.billing.details' | translate}}</h2>
            </div>
          </div>
          <div class="col-md-12">
              <mcc-row-title-description    title="{{'cc.contract.contract-type' | translate}}:" [description]="csrContract.durationInMonths ? 'Limited' : 'Unlimited'"></mcc-row-title-description>
              <mcc-row-title-description    title="{{'cc.common.contract-start-date' | translate}}:" [description]="csrContract.startDate"></mcc-row-title-description>
              <mcc-row-title-description    title="{{'cc.common.contract-end-date' | translate}}:" [description]="csrContract.endDate"></mcc-row-title-description>
              <mcc-row-title-description    title="{{'cc.consumer.send-invoice' | translate}}:"
                                              [description]="csrContract.notificationDetails.sendInvoice ? 'Yes' : 'No'">  </mcc-row-title-description>

              <mcc-row-title-description    title="{{'cc.consumer.email-address' | translate}}:" [description]="csrContract.notificationDetails.email"></mcc-row-title-description>
              <mcc-row-title-description    title="{{'cc.consumer.free-text' | translate}}:" [description]="csrContract.notes"></mcc-row-title-description>
            </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <h2>{{'cc.consumer.products-and-services' | translate}}</h2>
            </div>
          </div>
          <div class="col-md-12">
            <!--<ng-container>
              <div class="col-md-6">Services</div>
              <mcc-row-title-description    title="{{'cc.consumer.email-address' | translate}}:" [description]="csrContract.notificationDetails.email"></mcc-row-title-description>
              <mcc-row-title-description    title="{{'cc.consumer.email-address' | translate}}:" [description]="csrContract.notificationDetails.email"></mcc-row-title-description>
              <mcc-row-title-description    title="{{'cc.consumer.email-address' | translate}}:" [description]="csrContract.notificationDetails.email"></mcc-row-title-description>
              <mcc-row-title-description    title="{{'cc.consumer.email-address' | translate}}:" [description]="csrContract.notificationDetails.email"></mcc-row-title-description>
              <mcc-row-title-description    title="{{'cc.consumer.email-address' | translate}}:" [description]="csrContract.notificationDetails.email"></mcc-row-title-description>

            </ng-container>-->
          </div>
            <ng-container *ngFor="let product of csrContract.productSnapshots?.items">
              <div class="col-md-6 mb-3">
                {{'cc.consumer.product' | translate}}
              <mcc-row-title-description    title="{{'cc.consumer.art-no' | translate}}:" [description]="product.productSnapshot.id"></mcc-row-title-description>
              <mcc-row-title-description    title="{{'cc.consumer.art-description' | translate}}:" [description]="product.productSnapshot.description"></mcc-row-title-description>
              <mcc-row-title-description    title="{{'cc.consumer.price-netto' | translate}}:" [description]="product.productSnapshot.unitPrice.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' ' + entityCurrency"></mcc-row-title-description>
              <mcc-row-title-description    title="{{'cc.product.view.vat' | translate}}:" [description]="product.productSnapshot.vat.vatRate.toFixed(2).replace('.', ',') + '%'"></mcc-row-title-description>
              <mcc-row-title-description    title="{{'cc.jobs.view.quantity' | translate}}:" [description]="product.quantity"></mcc-row-title-description>
              </div>
            </ng-container>

            <div class="col-md-6">
              {{'cc.consumer.amount-and-price' | translate}}
              <mcc-row-title-description widerTitleFlg="{{true}}" title="{{'cc.consumer.amount-netto' | translate}}:"
                                         [description]="format(csrContract.priceUnit)"></mcc-row-title-description>
              <mcc-row-title-description widerTitleFlg="{{true}}" title="{{'cc.consumer.amount-brutto' | translate}}:"
                                         [description]="format(csrContract.priceUnit + csrContract.vatUnit)"></mcc-row-title-description>
              <mcc-row-title-description widerTitleFlg="{{true}}" title="{{'cc.consumer.total-netto' | translate}}:"
                                         [description]="format(csrContract.priceUnit)"></mcc-row-title-description>
              <mcc-row-title-description widerTitleFlg="{{true}}" title="{{'cc.consumer.total-vat' | translate}}:"
                                         [description]="format(csrContract.vatUnit)"></mcc-row-title-description>
            </div>


        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <h2>{{'cc.my-factoring.payment-method' | translate}}</h2>
            </div>
          </div>
          <div class="col-md-12">
            <div class="col-md-6">
              <mcc-row-title-description widerTitleFlg="{{true}}" title="{{'cc.my-factoring.payment-method' | translate}}:"
                                         [description]="csrContract.paymentDetails.getTypeCdLabel()"></mcc-row-title-description>
              <mcc-row-title-description *ngIf="csrContract.paymentDetails.isTypeCdTRANSFER()" extraCssClasses="mc-description-text-capitalize" title="Money transfer to:"
                                         [description]="mcEntity.name"></mcc-row-title-description>
            </div>
          </div>
          <ng-container *ngIf="csrContract.paymentDetails.isTypeCdTRANSFER()">

            <div class="col-md-12">

              <div class="col-md-6">
                <div class="section-sub-header">
                  <h3>{{'cc.common.bank-information' | translate}}</h3>
                </div>
                <mcc-row-title-description widerTitleFlg="{{true}}" title="{{'cc.common.iban' | translate}}:"
                                           [description]="bankAccount.iban"></mcc-row-title-description>
<!--                <mcc-row-title-description widerTitleFlg="{{true}}" title="{{'cc.consumers.edit.bic' | translate}}:"-->
<!--                                           [description]="csrContract.paymentDetails.bic"></mcc-row-title-description>-->
                <mcc-row-title-description widerTitleFlg="{{true}}" title="{{'cc.common.account-owner' | translate}}:"
                                           [description]="bankAccount.accountOwner"></mcc-row-title-description>
                <mcc-row-title-description widerTitleFlg="{{true}}" title="{{'cc.common.bank-name' | translate}}:"
                                           [description]="bankAccount.bankName"></mcc-row-title-description>
<!--                <mcc-row-title-description widerTitleFlg="{{true}}" title="Purpose of payment:"-->
<!--                                           [description]="csrContract.paymentDetails.paymentPurpose"></mcc-row-title-description>-->
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="csrContract.paymentDetails.isTypeCdDIRECT_DEBIT()">
            <div class="col-md-6">
              <div class="section-sub-header">
                <h3>{{'cc.consumer.direct-debit-information' | translate}}</h3>
              </div>
<!--              <mcc-row-title-description widerTitleFlg="{{true}}" title="{{'cc.consumer.direct-debit-id' | translate}}:"-->
<!--                                         [description]="csrContract.paymentDetails.idDdApproval"></mcc-row-title-description>-->
<!--              <mcc-row-title-description widerTitleFlg="{{true}}" title="{{'cc.consumer.direct-debit-start-date' | translate}}:"-->
<!--                                         [description]="csrContract.paymentDetails.ddApprovalStartDate"></mcc-row-title-description>-->
<!--              <mcc-row-title-description widerTitleFlg="{{true}}" title="{{'cc.consumer.direct-debit-end-date' | translate}}:"-->
<!--                                         [description]="csrContract.paymentDetails.ddApprovalEndDate"></mcc-row-title-description>-->
            </div>

          </ng-container>

        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <h2>{{'cc.consumer.contract-renewal' | translate}}</h2>
            </div>
          </div>
          <div class="col-md-12">
            <mcc-row-title-description  title="{{'cc.consumer.contract-renewal' | translate}}:"
                                       [description]="csrContract.autoRenewal ? 'Yes' : 'No'"></mcc-row-title-description>
<!--            <mcc-row-title-description *ngIf="csrContract.autoRenewal"  title="{{'cc.consumer.renewal-period' | translate}}:" [description]="csrContract.autoRenewalLength + ' ' + 'months'"></mcc-row-title-description>-->
            <mcc-row-title-description *ngIf="csrContract.autoRenewal"  title="{{'cc.consumer.before-ending' | translate}}:" [description]="daysBeforeExpiration.toFixed(0) + ' ' + 'days'"></mcc-row-title-description>
            <mcc-row-title-description *ngIf="csrContract.autoRenewal"  title="{{'cc.consumer.extend-for' | translate}}:" [description]="csrContract.autoRenewalLength + ' ' + 'months'"></mcc-row-title-description>

          </div>

        </div>

      </div>
    </div>

    <div class="content-footer">
      <button class="btn btn-secondary move-right" (click)="onClose()">{{'cc.common.close' | translate}}</button>
    </div>
  </div>
</ng-container>
<router-outlet></router-outlet>
