/*BbmTestObj*/
import {McPriceListGENERATED} from '../_generated/mc-price-list-generated.model';
import {McHtmlActionSet} from '../_core/mc-html-action-set';
import {McGod} from '../extra/mc-god.service';
import {McDateUtils} from '../common/mc-date-utils';
import {ActionOptions} from '../_core/mc-html-action-options';

export class McPriceList extends McPriceListGENERATED {

  public static ACTION_VIEW_DETAILS = 'VD';
  public static ACTION_EDIT = 'EDIT';

  private _fldActions: McHtmlActionSet | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McPriceList();
  }

  // ---------------------------------------------------------------------

  fldActions() {
    if (!this._fldActions) {
      const actionSet = new McHtmlActionSet();
      actionSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t('cc.my-factoring.view-details')}`,
        McPriceList.ACTION_VIEW_DETAILS, this, [this.idParentTemplate ? McGod.PERM_MC_BILLING_PRICELIST_VIEW : McGod.PERM_MC_BILLING_TEMPLATE_PRICELIST_VIEW], '', ActionOptions.OPTION_VIEW);
      // actionSet.addActionB(`<i class="fas mc-submit-color fa-edit mc-cursor-pointer"></i> ${this.t('cc.common.view.edit')}`, McPriceList.ACTION_EDIT, this, [McGod.PERM_MC_PROCESS_VIEW]);
      this._fldActions = actionSet;
    }
    return this._fldActions;
  }

  getDefaultCustomLbl(): string {
    let customResult = '';
    let defaultResult = '';
    if (this.mcBillingService2PriceListItemList) {
      this.mcBillingService2PriceListItemList.items.forEach(item => {
        if (item.flgCustomPrice) {
          customResult += `${item.serviceName}, `;
        } else {
          defaultResult += `${item.serviceName}, `;
        }
      });

      return `<div class="icon-tooltip2"><span>${this.numberOfDefaultPrices > 0 ? this.numberOfDefaultPrices : '0'}</span>
                    <span class="icon-tooltip-text2">${defaultResult}</span></div>` + ' / '
        + `<div class="icon-tooltip2"><span>${this.numberOfCustomPrices ? this.numberOfCustomPrices : '0'}</span><span class="icon-tooltip-text2">${customResult}</span></div>`;
    }

    return '';

  }

  override getValidFromStr(): string {
    if (this.validFrom) {
      return McDateUtils.newFormatDateString(this.validFrom);
    }
    return '';
  }

  getSysCreatedDatetimeString(): string {
    if (this.sysCreatedDatetime) {
      return McDateUtils.newFormatDateTimeString(this.sysCreatedDatetime);
    }
    return '';
  }

  fldNumberOfEntities() {
    const templateEntities = this.numberOfEntitiesUsingTemplate > 0 ? this.numberOfEntitiesUsingTemplate : '0';
    const pricelistEntities = this.numberOfEntitiesUsingPricelist > 0 ? this.numberOfEntitiesUsingPricelist : '0';
    return this.idParentTemplate ? pricelistEntities : templateEntities;
  }

  fldNumberOfCreatedPricelists() {
    return this.numberOfCreatedPriceLists > 0 ? this.numberOfCreatedPriceLists : '0';
  }
}
