
import {Table3Config} from '../extra/table-3-config';

// import {CsrPaymentDetailsGENERATED} from '../_generated/csr-payment-details-generated.model';

export class CsrPaymentDetailsTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colIdRef', 'Ref id for payment details based on selected payment type (id_csr_bank_account, id_cc_mollie_customer...)', 'idRef', 'idRef');
    this.addColumn('colRefTypeCd', 'Reference type based on selected payment type', 'refTypeCd', 'refTypeCd');
    this.addColumn('colTypeCd', 'Statuses', 'typeCd', 'typeCd');

*/
  }
}
