<div class="row">

  <div class="col-md-6">
    <div class="multi-row-error">
      <mcc-fi-number-type [nameForValue]="'ctrlValueVatRate' + index" [nameForType]="'ctrlTypeVatRate' + index"
                          label="{{'cc.countries.vat-rate' | translate}}"
                          [validMinNumber]="0"
                          validRequiredFlg="true"
                          [validNumberWithTwoDecimals]="true"
                          [value]="mcVatItem.vatRate"
                          validMaxNumber="100"
                          [mcForm]="mcForm" valueType="percentage" [disableMoneyValue]="true"
                          [labelOnTopFlg]="true" (eventAmountValueChanged)="inputValuesChangedHandler()">
      </mcc-fi-number-type>
    </div>
    <mcc-fi-select [mcForm]="mcForm" [optionsVll]="vatTypeVll"
                   [validRequiredFlg]="true"
                   [value]="mcVatItem.vatTypeCd ? mcVatItem.vatTypeCd : ''"
                   label="{{'cc.countries.vat-type' | translate}}"
                   [name]="'ctrlVatType' + index"
                   placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                   (eventValueChanged)="inputValuesChangedHandler()"></mcc-fi-select>

    <mcc-fi-textarea [name]="'ctrlDescription' + index" label="{{'cc.common.view.description' | translate}}"
                     numCols="35"
                     [value]="mcVatItem.vatDescription" [validRequiredFlg]="descriptionRequiredFlg"
                     [mcForm]="mcForm" (eventValueChanged)="inputValuesChangedHandler()">
    </mcc-fi-textarea>

  </div>

  <div class="col-md-6">
    <div class="longer-input-300">
      <mcc-fi-multiselect2 [optionVll]="servicesVll" label="{{'cc.countries.services-covered' | translate}}"
                           [name]="'ctrlServices' + index"
                           (eventValueChanged)="onServiceSelect($event)"
                           [mcForm]="mcForm" [valueVll]="servicesMultiselectValues.items.length > 0 ? servicesMultiselectValues : null" [validRequiredFlg]="true"
                           [pleaseChoosePlaceHolderFlg]="true">
      </mcc-fi-multiselect2>
    </div>
    <mcc-fi-date-picker [name]="'ctrlStartDate' + index" label="{{'cc.factoring.start-date' | translate}}"
                        [validRequiredFlg]="true"
                        [valueInput]="mcVatItem.startDate"
                        [mcForm]="mcForm" (eventValueChanged)="inputValuesChangedHandler()">
    </mcc-fi-date-picker>

    <mcc-fi-date-picker [name]="'ctrlEndDate' + index" label="{{'cc.factoring.end-date' | translate}}"
                        [validRequiredFlg]="true"
                        [valueInput]="mcVatItem.endDate"
                        [minDateInput]="endDateMinDate"
                        [mcForm]="mcForm" (eventValueChanged)="inputValuesChangedHandler()">
    </mcc-fi-date-picker>
  </div>

</div>
