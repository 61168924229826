import {ImDataSet} from './im-data-set.model';
import {ImDataSetListGENERATED} from '../_generated/im-data-set-list-generated.model';

export class ImDataSetList extends ImDataSetListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataSetList();
  }

  // ---------------------------------------------------------------------
}
