/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EbConsumerBlacklistFilter} from '../models/eb-consumer-blacklist-filter.model';

export class EbConsumerBlacklistFilterGENERATED extends BaseObjectFilter{    public fnames: string[] = [];
    public searchTerm: string | undefined;
    public addedByUserIds: number[] = [];
    public removedByUserIds: number[] = [];

  public properties: string[] = ['fnames', 'searchTerm', 'addedByUserIds', 'removedByUserIds', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbConsumerBlacklistFilter {
    console.error('MTCN-ERROR: Not Implemented: EbConsumerBlacklistFilter::createNewInstance(). Add this method to final class and return new EbConsumerBlacklistFilter();');
      throw new Error('Cannot EbConsumerBlacklistFilter::createNewInstance(). Add this method to final class and return new EbConsumerBlacklistFilter();');
  }

}
