
import {Table3Config} from '../extra/table-3-config';

// import {McPdfTemplateGENERATED} from '../_generated/mc-pdf-template-generated.model';

export class McPdfTemplateTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colIdMcEntity', 'McEntity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdWebfile', 'Webfile ID', 'idWebfile', 'idWebfile');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colTypeCd', 'Type code', 'typeCd', 'typeCd');

*/
  }
}
