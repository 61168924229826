/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CsrMappingTable3ConfigGENERATED} from '../_generated/csr-mapping-table-3-config-generated.model';

// import {CsrMappingGENERATED} from '../_generated/csr-mapping-generated.model';

export class CsrMappingTable3Config extends CsrMappingTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colCreationDate', 'Creation date', 'creationDate', 'creationDate');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdMcSystemUser', 'User ID', 'idMcSystemUser', 'idMcSystemUser');
    this.addColumn('colIdWebFile', 'Web file ID', 'idWebFile', 'idWebFile');
    this.addColumn('colMappingName', 'Mapping name', 'mappingName', 'mappingName');
    this.addColumn('colSharedFlg', 'Shared mapping flag', 'sharedFlg', 'sharedFlg');
    this.addColumn('colSharingDate', 'Sharing date', 'sharingDate', 'sharingDate');


  }
}
