/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CsrContractProductsFilter} from '../models/csr-contract-products-filter.model';

export class CsrContractProductsFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrContractProductsFilter {
    console.error('MTCN-ERROR: Not Implemented: CsrContractProductsFilter::createNewInstance(). Add this method to final class and return new CsrContractProductsFilter();');
      throw new Error('Cannot CsrContractProductsFilter::createNewInstance(). Add this method to final class and return new CsrContractProductsFilter();');
  }

}
