/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McVat} from '../models/mc-vat.model';
import {McVatList} from '../models/mc-vat-list.model';
import {McVatFilter} from '../models/mc-vat-filter.model';
import {McVatService} from '../services/mc-vat.service';
import {IMcVat} from '../_generated/mc-vat.interface';
import {McGod} from "../extra/mc-god.service";

export class McVatListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'endDate', 'idMcCountry', 'startDate', 'vatDescription', 'vatRate', 'vatTypeCd', 'mcVatDateActive'];
  public items: McVat[] = [];
  public apiService: McVatService;
 // protected tempObjList: McVatList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McVatList {
    console.error('MTCN-ERROR: Not Implemented: McVatList::createNewInstance(). Add this method to final class and return new McVatList();');
    throw new Error('Cannot McVatList::createNewInstance(). Add this method to final class and return new McVatList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcVat[]): McVatList {
    const  listObj: McVatList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McVat = McVat.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McVatList {
    const  listObj: McVatList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McVatService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcVat[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McVat = McVat.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcVatFilter: McVatFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcVatFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McVat {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McVat();
    }
  }

  public replaceItem(newItem: McVat) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadByCountryId( countryId: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByCountryId(countryId, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

