/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CsrContractProductsTable3ConfigGENERATED} from '../_generated/csr-contract-products-table-3-config-generated.model';

// import {CsrContractProductsGENERATED} from '../_generated/csr-contract-products-generated.model';

export class CsrContractProductsTable3Config extends CsrContractProductsTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colQuantity', 'Qauntity', 'quantity', 'quantity');


  }
}
