/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {ImDataExportFilter} from '../models/im-data-export-filter.model';

export class ImDataExportFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): ImDataExportFilter {
    console.error('MTCN-ERROR: Not Implemented: ImDataExportFilter::createNewInstance(). Add this method to final class and return new ImDataExportFilter();');
      throw new Error('Cannot ImDataExportFilter::createNewInstance(). Add this method to final class and return new ImDataExportFilter();');
  }

}
