import {ActionOptions} from '../_core/mc-html-action-options';

export class McHtmlLink {
  linkTextHtml: string;
  routeLink: string;
  queryParams: any;
  item: any;
  extraCssClass: string;
  iconUri!: string;
  permissions: string[] | "" | null;
  actionOptions: ActionOptions;



  constructor(linkTextHtml: string, routeLink: string, itemObj: any, permissions: string[] | '' | null, extraCssClass: string = '', actionOption: ActionOptions, queryParams: any) {
    this.linkTextHtml = linkTextHtml;
    this.routeLink = routeLink;
    this.actionOptions = actionOption;
    this.queryParams = queryParams;
    this.extraCssClass = extraCssClass;
    this.item = itemObj;
    this.permissions = permissions;
  }

  getInnerHtml() {
    if (this.iconUri) {
      return '<i>todoicon</i> ' + this.linkTextHtml;
    } else {
      return this.linkTextHtml;
    }
  }
}
