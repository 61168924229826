<ng-template #content let-modal>
  <!--Modal header section-->
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!--Modal body section-->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 px-0">
        <h4 class="modal-title mb-0" id="modal-basic-title">{{'cc.entity-profile.edit.edit-entity\'s-basic-info' | translate}}:</h4>
      </div>
    </div>
    <mc-entity-basic-info [editEntityId]="editEntityId" [parentData]="parentData" [isEdit]="isFormEdit" (successfullyEditBasicInfo)="updateBasicInfoData($event)"></mc-entity-basic-info>
  </div>
</ng-template>

<h5 class="mc-entity-profile-title ml-2">{{ 'cc.common.view.basic-info' | translate }} <i *ngIf="parentData" class="fa fa-pen mc-pen-icon" (click)="openModal(content)"></i></h5>
