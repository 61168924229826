import {Inject, Injectable} from '@angular/core';
import {CsrContract2ProductServiceGENERATED} from '../_generated/csr-contract-2-product-generated.service';



@Injectable({
  providedIn: 'root'
})
export class CsrContract2ProductService extends CsrContract2ProductServiceGENERATED {

}
