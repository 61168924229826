/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbFactoringRequestType} from '../models/eb-factoring-request-type.model';
import {EbFactoringRequestTypeService} from '../services/eb-factoring-request-type.service';
import {IEbFactoringRequestType} from '../_generated/eb-factoring-request-type.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class EbFactoringRequestTypeGENERATED extends BaseObject {


  public apiService: EbFactoringRequestTypeService;
  public _rawJson: IEbFactoringRequestType;
    id: number = 0;
  description = '';
  idChildMcEntity: number;
  title = '';


  public properties: string[] = ['id', 'description', 'idChildMcEntity', 'title'];
  public propertiesRegular: string[] = ['id', 'description', 'idChildMcEntity', 'title'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): EbFactoringRequestType {
    console.error('MTCN-ERROR: Not Implemented: EbFactoringRequestType::createNewInstance(). Add this method to final class and return new EbFactoringRequestType();');
    throw new Error('Cannot EbFactoringRequestType::createNewInstance(). Add this method to final class and return new EbFactoringRequestType();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbFactoringRequestType): EbFactoringRequestType {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbFactoringRequestTypeService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbFactoringRequestType AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
