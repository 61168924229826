import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {ImDataExportServiceGENERATED} from '../_generated/im-data-export-generated.service';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ImDataExportService extends ImDataExportServiceGENERATED {

  public getPreview(data: any): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/preview`, data, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  public export(data: any){
    return this.httpClient.post(this.getApiServiceRootUrl() + `/export`, data,  {responseType: 'blob'});
  }
}
