/*BbmTestObj*/
import {WebFileGENERATED} from '../_generated/web-file-generated.model';
import * as fileSaver from "file-saver";

export class WebFile extends WebFileGENERATED {
public apiDownloadingFlg = false;
public apiDownloadSuccessFlg = false;
public apiDownloadErrorFlg = false;

  public static readonly TYPE_CD_PM_TRANSACTION_CSV_EXPORT: string = 'PM_TRANSACTION_CSV_EXPORT';

  public static override createNewInstance() {
    return new WebFile();
  }
  // ---------------------------------------------------------------------
  public upload(aFile: any, type:string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.upload(aFile, type)
      .subscribe(responseObj => {
        this.populateFromJson(responseObj);
        this.processApiSuccess(responseObj, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

  public downloadFile(onSuccessCallback?: () => void, onErrorCallback?: () => void) {
    this.setApiLoading();
    return this.apiService.downloadWebFile(this.id, this.typeCd);
  }

  public getFileByIdAndType(id: number, type: string, onSuccessCallback?: () => void, onErrorCallback?: () => void){
    this.setApiLoading();
    this.apiService.getByIdAndType(id, type)
      .subscribe(response => {
        this.populateFromJson(response);
        this.processApiSuccess(response, onSuccessCallback);
      }, error => {
        this.processApiError(error, onErrorCallback)
      });
  }

  public setApiDownloadFlgs(aDownloadingFlg: boolean, aDownloadSuccessFlg: boolean, aDownloadErrorFlg: boolean) {
    this.apiDownloadingFlg = aDownloadingFlg;
    this.apiDownloadErrorFlg = aDownloadErrorFlg;
    this.apiDownloadSuccessFlg = aDownloadSuccessFlg;
  }
  public download(onSuccessCallback?: () => void, onErrorCallback?: () => void) {
    this.setApiDownloadFlgs(true, false, false);
    this.apiService.downloadWebFile(this.id, this.typeCd).subscribe(response => {
      fileSaver.saveAs(response, `${this.filename}`);
      if (onSuccessCallback) {
        onSuccessCallback();
      }
      this.setApiDownloadFlgs(false, true, false);
    }, () => {
      if (onErrorCallback) {
        onErrorCallback();
      }
      this.setApiDownloadFlgs(false, false, true);
    });
  }
}
