<div class="mc-apply-button-wrapper" ngbDropdown>
  <button ngbDropdownToggle
          class="mc-apply-button"
          >
    <span class="mc-more-options">...</span>
  </button>
  <div class="mc-options-dropdown" ngbDropdownMenu>

    <p class="mc-option-dropdown-item py-2 px-2 m-0 text-capitalize"
       ngbDropdownItem
       (click)="onSelectOption(false)"
       >{{ "cc.table.options.apply-to-the-rows-above" | translate}}</p>

    <p class="mc-option-dropdown-item py-2 px-2 m-0 text-capitalize"
       ngbDropdownItem
       (click)="onSelectOption(true)"
       >{{ "cc.table.options.apply-to-the-rows-below" | translate }}</p>
  </div>
</div>
