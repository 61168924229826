<ng-container>
  <div class="content-wrapper">


    <div class="content-header">
      <h1>{{'cc.common.upload-file' | translate}}</h1>
    </div><!--Modal header -->

    <!--Modal body section-->
    <div class="content-body">

      <div class="row">

        <div class="col-md-12 mt-5 px-5">
          <div class="input-group mb-3">

            <lib-mcc-fi-file-upload [acceptProperty]="'.xml'"
                                    (eventFileSelected)="onFileSelected($event)"></lib-mcc-fi-file-upload>

          </div>
        </div>
      </div>
    </div>
    <div class="content-footer">
      <!-- <button type="button" class="btn btn-secondary" (click)="actCancel()">Delete</button>-->
      <button type="button" class="btn btn-secondary move-right" (click)="actCancel()"><i class="far fa-times-circle"></i> {{'cc.common.edit.cancel' | translate}}</button>
      <button type="button" class="btn btn-primary" (click)="actUpload()"><i class="fas fa-upload"></i> {{'cc.consumers.consumers-overview.upload' | translate}}</button>
    </div>
  </div>


</ng-container>
