<lib-mc-loader [showLoader]="mcProcessFlowLevel.apiLoadingFlg || mcProcessFlow.apiLoadingFlg || mcProcess.apiLoadingFlg"></lib-mc-loader>

<!--<div class="mc-go-back-to-all" (click)="goBack()">-->
<!--  < {{('cc.common.back-to-all' | translate)}}-->
<!--</div>-->

<div class="breadcrumbs"><span (click)="actGoToProcesses()">{{'cc.processes.processes' | translate}}</span> > <span
  (click)="actGoToProcessProfile()">{{mcProcess.fldName()}}</span> >
  <span (click)="actGoToProcessFlowProfile()">{{mcProcessFlow.fldName()}}</span> > <span
    class="current-position">{{mcProcessFlowLevel.fldName()}}</span></div>

<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcProcessFlowLevel.apiLoadingFlg"></lib-mc-loader>

  <div>
    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-header">
      <h2>{{mcProcessFlowLevel.title}} Profile</h2>
    </div>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-body">
      <div class="info-summary-header-lite">
        <h4>{{"cc.common.general-info" | translate}} <i *ngIf="mcGod.userHasMcProcessFlowLevelEditPermission()" (click)="actShowEditLevelInfo()"
                            class="fas fa-pen"
                            [ngClass]="{'cursor-not-allowed' : mcProcess.statusCd === mcProcessStatusActive}"
                            [ngbTooltip]="mcProcess.statusCd === mcProcessStatusActive ? ('cc.processes.process-is-active-cant-edit' | translate) : ''"
                            placement="bottom-left"></i></h4>
      </div>
      <div class="info-summary-top-lite inner-content">
        <div class="row">
          <div class="col-md-6">
            <mcc-row-title-description title="{{'cc.common.name' | translate}}:"
                                       [description]="mcProcessFlowLevel.title"></mcc-row-title-description>
            <mcc-row-title-description title="{{'cc.common.fee-amount' | translate}}:"
                                       [description]="mcProcessFlowLevel.getLevelFeeAmountStr()"></mcc-row-title-description>
            <mcc-row-title-description title="{{'cc.common.pdf-template' | translate}}:"
                                       [description]="mcProcessFlowLevel.mcPdfTemplateTitle"></mcc-row-title-description>
          </div>
        </div>
      </div>

      <div class="info-summary-header-lite">
        <h4>{{'cc.jobs.view.actions' | translate}} <i *ngIf="mcGod.userHasMcProcessFlowLevelEditPermission()" (click)="actShowEditLevelInfo()"
                                                      class="fas fa-pen"
                                                      [ngClass]="{'cursor-not-allowed' : mcProcess.statusCd === mcProcessStatusActive}"
                                                      [ngbTooltip]="mcProcess.statusCd === mcProcessStatusActive ? ('cc.processes.process-is-active-cant-edit' | translate) : ''"
                                                      placement="bottom-left"></i></h4>
      </div>
      <div class="info-summary-top-lite inner-content">
        <div class="row">
          <div class="col-md-6">
            <div *ngIf="mcProcessFlowLevel.emailActionFlg">
              <div class="actions-subtitle"><img src="assets/images/mc-process-icons/email-action.svg"><h6>{{'cc.common.edit.email' | translate}}</h6>
              </div>
              <mcc-row-title-description title="{{'cc.processes.payment-qr-code' | translate}}:"
                                         [description]="mcProcessFlowLevel.fldBooleanToYesNo(mcProcessFlowLevel.emailPaymentQrCodeFlg)"></mcc-row-title-description>
              <mcc-row-title-description title="{{'cc.processes.email-subject' | translate}}:"
                                         [description]="mcProcessFlowLevel.emailSubjectTokened"></mcc-row-title-description>
              <mcc-row-title-description [extraCssClasses]="'email-body-emf'" title="{{'cc.processes.email-body' | translate}}:"
                                         [description]="mcProcessFlowLevel.emailBodyTokened"></mcc-row-title-description>

            </div>
            <div *ngIf="mcProcessFlowLevel.postActionFlg">
              <div class="actions-subtitle mt-4"><img src="assets/images/mc-process-icons/post-action.svg"><h6>{{'cc.jobs.edit.post' | translate}}</h6>
              </div>
              <mcc-row-title-description title="{{'cc.processes.payment-qr-code' | translate}}:"
                                         [description]="mcProcessFlowLevel.fldBooleanToYesNo(mcProcessFlowLevel.postPaymentQrCodeFlg)"></mcc-row-title-description>
            </div>


            <div *ngIf="mcProcessFlowLevel.smsActionFlg">
              <div class="actions-subtitle mt-4"><img src="assets/images/mc-process-icons/sms-action.svg"> <h6>SMS</h6>
              </div>
              <mcc-row-title-description [extraCssClasses]="'email-body-emf'" title="{{'cc.processes.sms-body' | translate}}:"
                                         [description]="mcProcessFlowLevel.smsBodyTokened"></mcc-row-title-description>
            </div>
          </div>
        </div>
      </div>

      <div class="info-summary-header-lite">
        <h4>{{'cc.process.flow.level.next-step(s)' | translate}} <i *ngIf="mcGod.userHasMcProcessFlowLevelEditPermission()" (click)="actShowEditLevelInfo()"
                            class="fas fa-pen"
                            [ngClass]="{'cursor-not-allowed' : mcProcess.statusCd === mcProcessStatusActive}"
                            [ngbTooltip]="mcProcess.statusCd === mcProcessStatusActive ? ('cc.processes.process-is-active-cant-edit' | translate) : ''"
                            placement="bottom-left"></i></h4>
      </div>
      <div class="info-summary-top-lite inner-content">
        <div class="row">
          <div class="col-md-6">
            <mcc-row-title-description title="{{'cc.common.payment-deadline' | translate}}:"
                                       [description]="mcProcessFlowLevel.noDaysPaymentDeadlineAfterReminderIssue + ' days after reminder issue date'"></mcc-row-title-description>
            <mcc-row-title-description title="{{'cc.processes.flow.level.waiting-period-after-pd' | translate}}:"
                                       [description]="mcProcessFlowLevel.noDaysWaitingPeriodAfterPaymentDeadline + ' ' + ('cc.common.day(s)' | translate)"></mcc-row-title-description>
          </div>
        </div>
      </div>

      <mcc-modal1-wrapper modalSizeCd="small" [showMcb]="showEditLevelMcb" *ngIf="showEditLevelMcb.value"
                          (eventCanceled)="actCancelEditLevel()">
        <mc-process-flow-level-add-edit [mcProcessFlowLevelId]="mcProcessLevelId" [mcProcessFlowId]="mcProcessFlow.id" [mcProcess]="mcProcess"
                                        [mcProcessFlowLevel]="mcProcessFlowLevel" (eventSaved)="actSaveEditLevel()"
                                        (eventCanceled)="actCancelEditLevel()"></mc-process-flow-level-add-edit>
      </mcc-modal1-wrapper>

    </div>
  </div>
</div>

<router-outlet></router-outlet>
