<h3 class="page-title">{{ 'cc.billing.statements' | translate }}</h3>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="mcBillingStatementList.items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  [totalItemsCount]="mcBillingStatementList.totalItemsCount"
  [itemsPerPage]="mcBillingStatementList.pageItemsPerPageCount"
  (filterChanged)="onFilterChanged($event)"
  (actionMkMatMenuItems)="onMkMatMenuItemsAction($event)"
  [mkMatMenuActionItems]="mkMatMenuActionItems"
  [searchTooltip]="searchTooltip">
>
</mk-mat-table>


