import {EbSepaFileTransactionTable3ConfigGENERATED} from "../_generated/eb-sepa-file-transaction-table-3-config-generated.model";

export class EbSepaFileMyTransactionsTable3Config extends EbSepaFileTransactionTable3ConfigGENERATED{
  constructor() {
    super();

    this.addColumn('colActions', '', 'fldActionsMyFactoring()', '');
    this.addColumn('colTitle', this.t('cc.consumers.view.consumer-name'), 'consumerName', 'consumer.firstName');
    this.addColumn('colDescription', this.t('cc.common.view.description'), 'rmtinfUstrd', 'rmtinfUstrd').extraCssClass = 'mc-factoring-transactions-table-description-column';
    this.addColumn('createdAt', this.t('cc.factoring.imported-sepa.import-date-&-time'), 'getImportDateStr()', 'createdAt');
    this.addColumn('colPaymentDueDate', 'Payment due date', 'getDueDateTime()', 'dueDatetime');
    this.addColumn('colRequestCollectionDate', 'Request collection date', 'getRequestCollectionDate()', 'requestedCollectionDate');
    this.addColumn('colAmount', `${this.t('cc.common.view.amount-due')}`, 'getAmount()', '').extraCssClass = 'text-right';
    this.addColumn('colStatus', this.t('cc.common.view.status'), 'getIconLabel()', 'statusCd');
  }
}
