/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McBillingBatchFilter} from '../models/mc-billing-batch-filter.model';

export class McBillingBatchFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingBatchFilter {
    console.error('MTCN-ERROR: Not Implemented: McBillingBatchFilter::createNewInstance(). Add this method to final class and return new McBillingBatchFilter();');
      throw new Error('Cannot McBillingBatchFilter::createNewInstance(). Add this method to final class and return new McBillingBatchFilter();');
  }

}
