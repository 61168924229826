import {ZzPocGarageFilterGENERATED} from '../_generated/zz-poc-garage-filter-generated.model';

export class ZzPocGarageFilter extends ZzPocGarageFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new ZzPocGarageFilter();
  }

  // ---------------------------------------------------------------------
}
