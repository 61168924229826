import {McProcessItemFilterGENERATED} from '../_generated/mc-process-item-filter-generated.model';

export class McProcessItemFilter extends McProcessItemFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessItemFilter();
  }

  // ---------------------------------------------------------------------
}
