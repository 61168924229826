/*BbmTestObj*/
import {McBillingBatchAssignedToStatementGENERATED} from '../_generated/mc-billing-batch-assigned-to-statement-generated.model';

export class McBillingBatchAssignedToStatement extends McBillingBatchAssignedToStatementGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingBatchAssignedToStatement();
  }

  // ---------------------------------------------------------------------
}
