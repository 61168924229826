import {McConsumerActionFilterGENERATED} from '../_generated/mc-consumer-action-filter-generated.model';

export class McConsumerActionFilter extends McConsumerActionFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McConsumerActionFilter();
  }

  // ---------------------------------------------------------------------
}
