<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcProcessFlowLevel.apiLoadingFlg"></lib-mc-loader>

  <div class="content-header">
    <h1 *ngIf="mcProcessFlowLevel.exists()">{{"cc.common.edit" | translate}} {{'cc.processes.level' | translate}} {{mcProcessFlowLevel.title}}</h1>
    <h1 *ngIf="!mcProcessFlowLevel.exists()">{{'cc.processes.flow..level.create-level' | translate}}</h1>

  </div>

  <div *ngIf="!mcProcessFlowLevel.apiLoadingFlg" class="content-body">
    <mcc-message [errorMsgs]="errorMsgs" label="{{'cc.my-factoring.unable-to-proceed' | translate}}: "></mcc-message>

    <ng-container *ngIf="(mcProcessFlowLevel.apiSuccessFlg) && (!hasErrors())">

      <div class="row">
        <div class="col-md-12 pl-0">
          <div class="section-header">
            <h3>{{"cc.common.general-info" | translate}}</h3>
          </div>
          <div class="form-inner-content pb-3">
            <mcc-fi-textbox name="ctrlName" label="{{'cc.common.name' | translate}}"
                            [value]="mcProcessFlowLevel.title" [validRequiredFlg]="true"
                            [mcForm]="mcForm">
            </mcc-fi-textbox>

            <mcc-fi-number-type [mcForm]="mcForm" [value]="mcProcessFlowLevel.exists() ? mcProcessFlowLevel.levelFeeAmount : 0"
                                label="{{'cc.common.fee-amount' | translate}}"
                                [nameForValue]="'ctrlLevelFeeAmount'"
                                nameForType="ctrlLevelFeeType"
                                [chosenCurrency]="currency"
                                validNumberWithTwoDecimals="true" [disablePercentage]="true"
                                [validRequiredFlg]="true"
                                [validMinNumber]="0"
                                [valueType]="currency"></mcc-fi-number-type>

            <mcc-fi-select [mcForm]="mcForm" name="ctrlPdfTemplate"
                           placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                           label="{{'cc.common.pdf-template' | translate}}"
                           [value]="mcProcessFlowLevel.idPdfTemplate ? mcProcessFlowLevel.idPdfTemplate : ''"
                           [validRequiredFlg]="true"
                           [optionsVll]="pdfTemplatesVll"></mcc-fi-select>

          </div>
          <div class="section-header">
            <h3>{{'cc.jobs.view.actions' | translate}}</h3>
          </div>
          <!-- ------------------------------------- -->
          <!-- Email -->

          <div class="form-inner-content">
            <mcc-fi-checkbox label="{{'cc.common.edit.email' | translate}}"
                             name="ctrlEmail"
                             [value]="mcProcessFlowLevel.emailActionFlg"
                             [mcForm]="mcForm"></mcc-fi-checkbox>

            <div [ngClass]="{ 'mc-disable' : !this.mcForm.getValue('ctrlEmail')}">
              <div class="ml-4">
                <mcc-fi-radio-buttons2 label="{{'cc.processes.payment-qr-code' | translate}}"
                                       [name]="'ctrlPaymentQrCode'"
                                       [directionHorizontal]="true"
                                       [mcForm]="mcForm"
                                       [value]="mcProcessFlowLevel.emailPaymentQrCodeFlg"
                                       [validRequiredFlg]="this.mcForm.getValue('ctrlEmail')"
                                       [optionsVll]="paymentQrCodeVll"></mcc-fi-radio-buttons2>

                <mcc-fi-textbox-with-token name="ctrlEmailSubject" label="{{'cc.processes.email-subject' | translate}}" tokenLabel="Tokens"
                                           [tokenVll]="tokenVll"
                                           [value]="mcProcessFlowLevel.emailSubjectTokened"
                                           [validRequiredFlg]="this.mcForm.getValue('ctrlEmail')"
                                           [mcForm]="mcForm"></mcc-fi-textbox-with-token>

                <mcc-fi-textarea-with-token tokenLabel="Tokens" name="ctrlEmailBody" label="{{'cc.processes.email-body' | translate}}" [numCols]="45"
                                            [tokenVll]="tokenVll"
                                            [value]="mcProcessFlowLevel.emailBodyTokened"
                                            [validRequiredFlg]="this.mcForm.getValue('ctrlEmail')"
                                            [mcForm]="mcForm">
                </mcc-fi-textarea-with-token>
              </div>
            </div> <!-- Email -->

            <hr class="mt-5 mb-5">
            <!-- ------------------------------------------------------------------- -->
            <!-- Post -->

            <mcc-fi-checkbox label="{{'cc.jobs.edit.post' | translate}}"
                             name="ctrlPost"
                             [value]="mcProcessFlowLevel.postActionFlg"
                             [mcForm]="mcForm"></mcc-fi-checkbox>

            <div [ngClass]="{ 'mc-disable' : !this.mcForm.getValue('ctrlPost')}">
              <div class="ml-4">
                <mcc-fi-radio-buttons2 label="{{'cc.processes.payment-qr-code' | translate}}"
                                       [name]="'ctrlPaymentQrCodePost'"
                                       [directionHorizontal]="true"
                                       [mcForm]="mcForm"
                                       [value]="mcProcessFlowLevel.postPaymentQrCodeFlg"
                                       [validRequiredFlg]="this.mcForm.getValue('ctrlPost')"
                                       [optionsVll]="paymentQrCodeVllPost"></mcc-fi-radio-buttons2>
              </div>
            </div>
            <hr class="mt-5 mb-5">
            <!-- ------------------------------------------------------------------- -->
            <!-- SMS -->

            <mcc-fi-checkbox label="SMS"
                             name="ctrlSms"
                             [value]="mcProcessFlowLevel.smsActionFlg"
                             [mcForm]="mcForm"></mcc-fi-checkbox>

            <div [ngClass]="{ 'mc-disable' : !this.mcForm.getValue('ctrlSms')}">
              <div class="ml-4">
                <mcc-fi-textarea-with-token tokenLabel="Tokens" [tokenVll]="tokenVll" name="ctrlSmsBody"
                                            label="{{'cc.processes.sms-body' | translate}}" [numCols]="45"
                                            [value]="mcProcessFlowLevel.smsBodyTokened"
                                            [validRequiredFlg]="this.mcForm.getValue('ctrlSms')"
                                            [mcForm]="mcForm">
                </mcc-fi-textarea-with-token>
              </div>
            </div>

          </div>
          <div class="section-header">
            <h3>{{'cc.process.flow.level.next-step(s)' | translate}}</h3>
          </div>
          <div class="form-inner-content pb-5">
            <mcc-fi-textbox name="ctrlPaymentDeadline" label="{{'cc.common.payment-deadline' | translate}}"
                            [value]="mcProcessFlowLevel.noDaysPaymentDeadlineAfterReminderIssue"
                            [validRequiredFlg]="true" [validIntegerNumber]="true" [size]="10"
                            [mcForm]="mcForm" additionalDescription="day(s) after reminder issue date">
            </mcc-fi-textbox>

            <mcc-fi-textbox name="ctrlWaitingPeriodAfterPD" label="{{'cc.processes.flow.level.waiting-period-after-pd' | translate}}"
                            [value]="mcProcessFlowLevel.noDaysWaitingPeriodAfterPaymentDeadline"
                            [validRequiredFlg]="true" [size]="10"
                            [mcForm]="mcForm" additionalDescription="{{'cc.common.day(s)' | translate}}">
            </mcc-fi-textbox>

          </div>

        </div>
      </div><!-- row -->
    </ng-container>

  </div><!-- content-body -->

  <div class="row">
    <div class="col-md-12 position-relative">
      <div class="above-footer">
        <mcc-mc-form-message [mcForm]="mcForm" messageType="error" extraCssClasses="message-background"
                             messageText="Required fields can't be empty"></mcc-mc-form-message>
        <mcc-message [errorMsg]="errorMsg"></mcc-message>
      </div>
    </div>
  </div>

  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="{{'cc.common.edit.cancel' | translate}}"></mcc-button>
    <mcc-button *ngIf="(mcProcessFlowLevel.apiSuccessFlg) && (!hasErrors())" extraCssClasses="btn btn-primary"
                (eventClicked)="actSave();" label="{{mcProcessFlowLevel.exists() ? 'Save' : ('cc.common.view.create' | translate)}}"
                [disabledMcb]="saveButtonDisabledMcb"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
