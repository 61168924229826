<!--THIS CONTAINER IS MANDATORY-->
<div class="mc-transaction-profile">
  <div class="content-wrapper">
    <lib-mc-loader [showLoader]="ebSepaFileTransaction.apiLoadingFlg || mcEntity.apiLoadingFlg || loadingFlg"></lib-mc-loader>

    <div class="row mx-0">
      <div class="mc-go-back-to-all" (click)="goToPrevious()">
        < back to transactions
      </div>
    </div>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-header">
      <h1 *ngIf="ebSepaFileTransaction.exists()"
          class="modal-title">Payout details - Transaction ID {{ebSepaFileTransactionId}}</h1>
    </div>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-body">
      <div class="row">
        <div class="col-md-12 pb-1">
          <div class="mc-transaction-notify-message">
            <mcc-message [errorMsg]="ebSepaFileTransaction.apiErrorMessage" [successMsg]="successMsg"></mcc-message>
            <mcc-message [errorMsg]="errorMsg"></mcc-message>
          </div>
        </div>
      </div>

      <!-- == MAIN CONTENT ======================================== -->
      <div>

        <div class="row">

          <div class="col-md-12">
            <div class="mc-transaction-status-label">
              <h6>{{'cc.common.view.status' | translate}}:</h6>
              <div [innerHTML]="ebSepaFileTransaction.getIconLabel()" class="mc-transaction-status">

              </div>
            </div>
          </div>
        </div>

        <div class="row info-data">
          <div class="col-md-7 mc-transaction-profile-left-column">
            <mcc-row-title-description [description]="ebSepaFileTransaction.consumerName"
                                       title="{{'cc.my-factoring.consumer' | translate}}:"></mcc-row-title-description>

            <mcc-row-title-description title="Consumer internal id:"
                                       [description]="ebSepaFileTransaction.idCsrConsumer"></mcc-row-title-description>

            <mcc-row-title-description [description]="ebSepaFileTransaction.entityName"
                                       title="{{'cc.common.view.entity' | translate}}:"></mcc-row-title-description>

            <mcc-row-title-description [description]="ebSepaFileTransaction.contractNumber"
                                       title="{{'cc.common.contract-number' | translate}}:"></mcc-row-title-description>

            <mcc-row-title-description [description]="ebSepaFileTransaction.extTransactionId"
                                       title="{{'cc.out-factoring.external-transaction-id' | translate}}:"></mcc-row-title-description>

            <mcc-row-title-description [description]="ebSepaFileTransaction.id"
                                       title="{{'cc.out-factoring.transaction-id' | translate}}:"></mcc-row-title-description>

            <mcc-row-title-description [description]="ebSepaFileTransaction.getCreatedDateTimeString()"
                                       title="{{'cc.consumers.imports.created-at' | translate}}:"></mcc-row-title-description>

            <mcc-row-title-description description="{{ebSepaFileTransaction.pausedDaysCount}}/{{getMaxPauseDaysLbl()}}"
                                       title="TOTAL USED/MAX PAUSE DAYS:"></mcc-row-title-description>
          </div>
          <div class="col-md-4 mc-transaction-profile-right-column">
            <mcc-row-title-description [description]="ebSepaFileTransaction.getDueDateTimeString()"
                                       class="mc-transaction-money-col"
                                       title="PAYMENT DUE DATE:"></mcc-row-title-description>

            <!-- <mcc-row-title-description class="mc-transaction-money-col"
                                        title="REQUEST COLLECTION DATE:"
                                        [description]="ebSepaFileTransaction.requestedCollectionDate"></mcc-row-title-description>-->

           <!-- <mcc-row-title-description [description]="format(ebSepaFileTransaction.instdamt)"
                                       class="mc-transaction-money-col"
                                       title="{{'cc.my-factoring.original-amount' | translate}}:"></mcc-row-title-description>-->

            <mcc-row-title-description *ngIf="ebSepaFileTransaction.statusCd !== rejectedStatusCd"
                                       [description]="ebSepaFileTransaction.getAmountDueStr()"
                                       class="mc-transaction-money-col mc-description-bold"
                                       title="{{'cc.my-factoring.remaining-amount-due' | translate}}:"></mcc-row-title-description>

            <!--<mcc-row-title-description class="pb-2" title="Surplus:"
                                       [description]="ebSepaFileTransaction.getAmountDueStr()"></mcc-row-title-description>-->
          </div>
        </div>

        <div class="row info-data" *ngIf="ebSepaFileTransaction.pausedFlg">
          <div class="col-md-12 mc-transaction-history-heading">
            <h5><i class="far fa-pause-circle mc-blue-icon"></i> Pausing information</h5>
          </div>


          <div class="col-md-12">
            <div class="important-data">
              <div class="col-md-7">
                <mcc-row-title-description
                  description="{{ebSepaFileTransaction.pausedDaysCount - ebSepaFileTransaction.pauseDaysCountWithoutCurrentPauseDays}}/{{ebSepaFileTransaction.maxPauseDays}}"
                  title="USED/CHOSEN PAUSED DAYS:"></mcc-row-title-description>
                <mcc-row-title-description [description]="ebSepaFileTransaction.getPausedStartDateString()"
                                           title="PAUSE STARTED:"></mcc-row-title-description>
                <mcc-row-title-description [description]="ebSepaFileTransaction.getPausedEndDateString()"
                                           title="PAUSE ENDS:"></mcc-row-title-description>
                <mcc-row-title-description [description]="ebSepaFileTransaction.pausedByUserName"
                                           title="PAUSED BY:"></mcc-row-title-description>
                <mcc-row-title-description [description]="ebSepaFileTransaction.pauseUnpauseReason"
                                           title="PAUSED REASON:"></mcc-row-title-description>
              </div>
            </div>
          </div>
        </div>

        <div class="row">

          <h3>Payout details</h3>

          <div class="col-md-12">
            <div class="mc-filter fancy-label">
              <mcc-fi-textbox label="{{'cc.common.search' | translate}}" name="txbSearchTerm" (eventEnterClicked)="actLoad()"
                              [mcForm]="mcForm"></mcc-fi-textbox>

                <mcc-multiselect-filter [optionVll]="statusCdVll" [clearedAllFilters]="clearedAllMultipleFilters"
                                        label="{{'cc.common.view.status' | translate}}" name="selStatus"
                                        (eventSelectedItems)="onSelectStatusCds($event)">
                </mcc-multiselect-filter>

                <mcc-multiselect-filter [optionVll]="amountVll" [clearedAllFilters]="clearedAllMultipleFilters"
                                        label="Amount" name="selAmount" (eventSelectedItems)="onSelectAmount($event)">
                </mcc-multiselect-filter>

              <div class="wide-date">
                <mcc-fi-date-picker
                  datePickerViewType="RANGE_SELECTION"
                  [mcForm]="mcForm"
                  name="ctrlActivityDates"
                  label="Activity date range"
                  (eventFromDate)="onActivityDateFrom($event)"
                  (eventToDate)="onActivityDateTo($event)"
                  [allowManualEntryFlg]="false"
                  monthsDisplayed="2"
                  [clearDateRange]="clearDateRangeSubject"
                >
                </mcc-fi-date-picker>
              </div>

              <button class="mc-link mc-filter-clear-button filter-clear-top-right"
                      (click)="actClearFilter()">{{'cc.common.filter.clear-all' | translate}}</button>
              <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>

            </div>
          </div>

          <div class="col-md-12">
            <mcc-fi-table-3 [objectList]="billingItemList"
                            [table3Config]="payoutItemTable3Config"
            ></mcc-fi-table-3>
          </div>
        </div>


      </div>


    </div>
  </div>
</div>

<router-outlet></router-outlet>
