
import {Table3Config} from '../extra/table-3-config';

// import {EbSepaFileGENERATED} from '../_generated/eb-sepa-file-generated.model';

export class EbSepaFileTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colCreatedAt', 'Created at', 'createdAt', 'createdAt');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdWebfile', 'Webfile ID', 'idWebfile', 'idWebfile');
    this.addColumn('colMcEntity', 'Child Entity', 'mcEntity', 'mcEntity');
    this.addColumn('colRequestedCollectionDate', 'Manually requested collection date', 'requestedCollectionDate', 'requestedCollectionDate');
    this.addColumn('colServicePeriod', 'Service period', 'servicePeriod', 'servicePeriod');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');

*/
  }
}
