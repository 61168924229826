/*BbmTestObj*/
import {McMappingGENERATED} from "../_generated/mc-mapping-generated.model";
import {McGod} from '../extra/mc-god.service';
import {McHtml} from '../_core/mc-html';
import {McDateUtils} from '../common/mc-date-utils';
import {ActionOptions} from '../_core/mc-html-action-options';

export class McMapping extends McMappingGENERATED {

  public static ACTION_SHOW_PROFILE = 'PROFILE';
  public static ACTION_SHOW_EDIT = 'EDIT';
  public static ACTION_COPY = 'COPY';
  public static ACTION_SHARE = 'SHARE';
  public static ACTION_UNSHARE = 'UNSHARE';

  private _fldActions: McHtml | null = null;

  public static override createNewInstance() {
    return new McMapping();
  }
  // ---------------------------------------------------------------------
  /*public loadByWebFileId(aWebFileId: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    // TODO Commented out for CG
/!*
    this.apiService.getByWebFileId(aWebFileId)
      .subscribe(responseObj => {
        this.populateFromObject(responseObj);
        if (onSuccessCallback) {
          onSuccessCallback();
        }
        this.setApiSuccess();
      }, error => {
        if (onErrorCallback) {
          onErrorCallback();
        }
        this.setApiError("API ERROR E1001");
      });
*!/
  }*/

  fldDateFormat() {
    return McDateUtils.newFormatDateTimeString(this.creationDate);
  }

  fldActions() {
    if (!this._fldActions) {
      // this.router.navigate([`/admin/entity/${entityId}/management/consumers/edit/${content.id}`], {queryParams: {activeTab: "paymentInfoTab"}});
      const loggedEntityid = McGod.getLoggedEntityIdFromToken();
      const html = new McHtml();
      // html.addLinkB('<i class="fa mc-submit-color fa-pen px-1 mc-cursor-pointer"></i>', `/admin/entity/${this.idMcEntity}/management/consumers/edit/${this.id}`, this, '', {});
      // html.addLinkB('<i class="fas mc-submit-color fa-file-invoice"></i>', `/admin/entity/${this.idMcEntity}/management/consumers/edit/${this.id}`, this,  '', {queryParams: {activeTab: 'paymentInfoTab'}});

      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t('cc.common.view.view')}`, McMapping.ACTION_SHOW_PROFILE, this, [McGod.PERM_MC_CONSUMER_IMPORT_MAPPING_VIEW], '', ActionOptions.OPTION_VIEW);
      if (this.idMcEntity === loggedEntityid) {
        actSet.addActionB(`<i class="fa mc-submit-color fa-pen px-1 mc-cursor-pointer"></i> ${this.t('cc.common.view.edit')}`, McMapping.ACTION_SHOW_EDIT, this, [McGod.PERM_MC_CONSUMER_IMPORT_MAPPING_EDIT], '', ActionOptions.OPTION_EDIT);
      }
      actSet.addActionB(`<i class="fas mc-submit-color fas fa-copy mc-cursor-pointer"></i> ${this.t('cc.import-mappings.copy')}`, McMapping.ACTION_COPY, this, [McGod.PERM_MC_CONSUMER_IMPORT_MAPPING_EDIT], '', ActionOptions.OPTION_EDIT);
      if (!this.sharedFlg && this.idMcEntity === loggedEntityid) {
        actSet.addActionB(`<i class="fas mc-submit-color fas fa-share-alt mc-cursor-pointer"></i> ${this.t('cc.import-mappings.share')}`, McMapping.ACTION_SHARE, this, [McGod.PERM_MC_CONSUMER_IMPORT_MAPPING_SHARE], '', ActionOptions.OPTION_EDIT);
      } else if (this.sharedFlg && this.idMcEntity === loggedEntityid) {
        actSet.addActionB(`<img src="assets/images/unshare.svg" alt="" width="15px" height="15px"> ${this.t('cc.import-mappings.unshare')}`, McMapping.ACTION_UNSHARE, this, [McGod.PERM_MC_CONSUMER_IMPORT_MAPPING_SHARE], 'unshare-icon', ActionOptions.OPTION_EDIT);
      }
      this._fldActions = html;
    }
    return this._fldActions;
  }

  fldMappingNameAndSharedIcon() {
    const sharedByLoggedEntity = this.idMcEntity === McGod.getLoggedEntityIdFromToken();
    if (this.sharedFlg && !sharedByLoggedEntity) {
      return this.mappingName + ` <img src="assets/images/sharedbyothers.svg" data-toggle="tooltip" title="${this.t('cc.import-mappings.shared-by-entity')} ${this.sharedByEntityName} ${this.t('cc.import-mappings.on')} ${McDateUtils.formatDateToString(this.sharingDate)}" alt="" width="25px" height="25px">`;
    } else if (this.sharedFlg && sharedByLoggedEntity) {
      return this.mappingName + ` <img src="assets/images/sharedbyuser.svg" data-toggle="tooltip" title="${this.t('cc.import-mappings.shared-by')} ${this.sharedByUserName} ${this.t('cc.import-mappings.on')} ${McDateUtils.formatDateToString(this.sharingDate)}" alt="" width="25px" height="25px">`;
    } else {
      return this.mappingName;
    }
  }

  fldUserName() {
    return (this.sharedFlg && this.idMcEntity !== McGod.getLoggedEntityIdFromToken()) ? 'n/a' : this.createdByUserName;
  }


}
