import {Component, EventEmitter, Input, Output} from "@angular/core";
import { McBillingStatement, McBillingStatementItem} from "@miticon-ui/mc-core";

@Component({
  selector: 'mc-out-billing-remove-from-statement-part',
  templateUrl: './mc-out-billing-remove-from-statement-part.component.html',
})
export class McOutBillingRemoveFromStatementPartComponent {

  /*Variables*/
  errorMsg = '';

  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter();
  @Input() mcBillingStatementItem = new McBillingStatementItem();
  @Input() mcBillingStatement = new McBillingStatement();

  actSave() {
    this.mcBillingStatement.callRemoveStatementItemsFromStatement(this.mcBillingStatement.id, [this.mcBillingStatementItem.id], () => {
      this.eventSaved.emit();
    }, () => {
      this.errorMsg = this.mcBillingStatement.apiErrorMessage;
    });
  }

  actCancel() {
    this.eventCanceled.emit();
  }
}
