<!--&lt;!&ndash;Manage attributes&ndash;&gt;-->
<!--<div class="mc-popup-button mc-border-left-right">-->
<!--  <i class="fas fa-list-ul"></i>-->
<!--  <p>Manage attributes</p>-->
<!--</div>-->

<!--&lt;!&ndash;Manage roles&ndash;&gt;-->
<!--<div class="mc-popup-button mc-border-right">-->
<!--  <i class="fas fa-suitcase"></i>-->
<!--  <p>Manage Roles</p>-->
<!--</div>-->

<!--Change password policy-->
<div class="mc-popup-button mc-border-left-right">
  <i class="fas fa-lock"></i>
  <p>Change password policy</p>
</div>

<!--Change status-->
<div class="mc-popup-button mc-border-right">
  <i class="fas fa-sync-alt"></i>
  <p>Change Status</p>
</div>
