/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EbPaymentReport2SepaFileTransactionTable3ConfigGENERATED} from '../_generated/eb-payment-report-2-sepa-file-transaction-table-3-config-generated.model';

// import {EbPaymentReport2SepaFileTransactionGENERATED} from '../_generated/eb-payment-report-2-sepa-file-transaction-generated.model';

export class EbPaymentReport2SepaFileTransactionTable3Config extends EbPaymentReport2SepaFileTransactionTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colEntityName', this.t('cc.factoring.transactions.entity-name'), 'amount', 'amount');
    this.addColumn('colConsumer', this.t('cc.my-factoring.consumer'), 'amount', 'amount');
    this.addColumn('colDescription', this.t('cc.common.view.description'), 'amount', 'amount');
    this.addColumn('colImportDateAndTime', this.t('cc.factoring.imported-sepa.import-date-&-time'), 'amount', 'amount');
    this.addColumn('colPaymentDueDate', this.t('cc.transactions.payment-due-date'), 'amount', 'amount');
    this.addColumn('colRequestColDate', this.t('cc.incoming-payments.request-collection-date'), 'amount', 'amount');
    this.addColumn('colAmountDue', this.t('cc.common.view.amount-due'), 'amount', 'amount');
    this.addColumn('colStatus', this.t('cc.common.view.status'), 'amount', 'amount');

  }
}
