<div class="consumer-confirmation-wrapper">
  <ng-container *ngIf="confirmationCodeValid">
    <lib-mc-loader [showLoader]="csrContract.apiLoadingFlg"></lib-mc-loader>

    <div class="consumer-confirmation-header">
      <h3>Contract confirmation</h3>
    </div>
    <div class='card-wrapper'>
      <app-shared-card header="{{'cc.my-factoring.consumer-information' | translate}}"[showHeader]="true">
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.first-name' | translate}}:" [description]="csrConsumer.firstName"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.last-name' | translate}}:" [description]="csrConsumer.lastName"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="Studio:" [description]="csrConsumer.entityName"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.contract-id' | translate}}:" [description]="csrContract.id"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.view.consumer-id' | translate}}:" [description]="csrConsumer.id"></mcc-row-title-description>
      </app-shared-card>
      <app-shared-card header="{{'cc.my-factoring.payment' | translate}}" [showHeader]="true">
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.my-factoring.payment' | translate}}:" [description]="csrContract.recurringDetails.getTypeCdLabel()"></mcc-row-title-description>
        <ng-container *ngIf="csrContract.recurringDetails.isTypeCdQUARTERLY()">
          <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                     [description]="csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'')"></mcc-row-title-description>
          <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.month' | translate}}:"
                                     [description]="csrRecurringDetails.getQuarterlyVll().getLabel(csrContract.recurringDetails.month,'')"></mcc-row-title-description>
        </ng-container>
        <ng-container *ngIf="csrContract.recurringDetails.isTypeCdMONTHLY()">
          <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                     [description]="csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'')"></mcc-row-title-description>
        </ng-container>
        <ng-container *ngIf="csrContract.recurringDetails.isTypeCdWEEKLY() ">
          <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                     [description]="csrRecurringDetails.getWeekDaysVll().getLabel(csrContract.recurringDetails.day,'')"></mcc-row-title-description>
        </ng-container>
        <ng-container *ngIf="csrContract.recurringDetails.isTypeCdYEARLY()">
          <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                     [description]="csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'')"></mcc-row-title-description>
          <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.month' | translate}}:"
                                     [description]="csrRecurringDetails.getYearlyVll().getLabel(csrContract.recurringDetails.month,'')"></mcc-row-title-description>
        </ng-container>
        <ng-container *ngIf="csrContract.recurringDetails.isTypeCdHALF_YEARLY()">
          <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.day' | translate}}:"
                                     [description]="csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'')"></mcc-row-title-description>
          <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.month' | translate}}:"
                                     [description]="csrRecurringDetails.getHalfYearlyVll().getLabel(csrContract.recurringDetails.month,'')"></mcc-row-title-description>
        </ng-container>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.my-factoring.payment-method' | translate}}:" [description]="csrContract.paymentDetails.getTypeCdLabel()"></mcc-row-title-description>
      </app-shared-card>
      <app-shared-card header="{{'cc.consumer.products-and-services' | translate}}" [showHeader]="true">
        <div *ngFor="let product of productList">
          <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.art-no' | translate}}:" [description]="product && product.productSnapshot.name ? product.productSnapshot.name : ''"></mcc-row-title-description>
          <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.art-description' | translate}}:" [description]="product && product.productSnapshot.description ? product.productSnapshot.description : ''"></mcc-row-title-description>
          <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.price-netto' | translate}}:" [description]="product && product.productSnapshot.unitPrice ? format(product.productSnapshot.unitPrice, product.productSnapshot.currency.code) : ''"></mcc-row-title-description>
          <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.product.view.vat' | translate}}:" [description]="product && product.productSnapshot.vat ? product.productSnapshot.vat.vatRate.toFixed(2).replace('.', ',') + '%' : ''"></mcc-row-title-description>
          <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.jobs.view.quantity' | translate}}:" [description]="product && product.quantity ? product.quantity : ''"></mcc-row-title-description>
          <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.billing.total-amount' | translate}}:" [description]="format((product.productSnapshot.unitPrice + product.productSnapshot.unitPrice * product.productSnapshot.vat.vatRate / 100) * product.quantity, product.productSnapshot.currency.code)"></mcc-row-title-description>
        </div>
      </app-shared-card>
      <app-shared-card header="{{'cc.billing.details' | translate}}"  [showHeader]="true">
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.contract.contract-type' | translate}}:" [description]="csrContract.endDate ? 'Limited' : 'Unlimited'"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.contract-start-date' | translate}}:" [description]="csrContract.startDate| date"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.contract-end-date' | translate}}:" [description]="csrContract.endDate | date"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.send-invoice' | translate}}:" [description]="csrContract.notificationDetails ? csrContract.notificationDetails.sendInvoice ? 'Yes' : 'No' : ''"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.email-address' | translate}}:" [description]="csrConsumer.email"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.free-text' | translate}}" [description]="csrContract.notes"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.contract-renewal' | translate}}:" [description]="csrContract.autoRenewal ? 'Yes' : 'No'"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.before-ending' | translate}}:" description="{{csrContract && csrContract.endDate ? daysBeforeExpiration.toFixed(0) : ''}} {{'cc.factoring.day-s' | translate}}"></mcc-row-title-description>
        <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.extend-for' | translate}}:" description="{{csrContract.autoRenewalLength}} {{'cc.my-factoring.months' | translate}}"></mcc-row-title-description>
      </app-shared-card>
    </div>
    <div class="contract-confirmation-footer d-flex justify-content-between">
      <div class="d-flex">
        <i class="fas fa-info-circle mc-tooltip-top-margin"></i>
        <p>
          The  only data that will be saved are those related to the payment of services by credit card to studio.
          <br>
          Other transactions/payments are not processed.
        </p>
      </div>
      <div>
        <button class="btn btn-primary" (click)="onAcceptContract()">Accept</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!confirmationCode">
    <mcc-modal1-wrapper [showMcb]="showConfirmationContractCode" *ngIf="!confirmationCode" modalSizeCd="small" [closeButton]="false">
      <mc-consumer-confirmation-contract-code></mc-consumer-confirmation-contract-code>
    </mcc-modal1-wrapper>
  </ng-container>

</div>
