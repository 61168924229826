import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WebFile} from "@miticon-ui/mc-core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'lib-import-consumers-step1',
  templateUrl: './import-consumers-step1.component.html',
})
export class ImportConsumersStep1Component implements OnInit {

  @Output() eventUploadSuccess = new EventEmitter();
  @Output() eventCancel = new EventEmitter();
  @Input() webFile!: WebFile;
  rawFile!: File;
  messageType = "";
  message!: string;
  isEnabled = false;
  formGroup!: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.formGroup = this.fb.group({
      delimiter: [";", Validators.required],
      encoding: [],
    });
  }

  // ---------------------------------------------------------------------
  actUpload() {
    this.messageType = "error";
    if (this.rawFile != null) {

      const dataBody = {
        file: this.rawFile,
        delimiter: this.formGroup.get("delimiter")?.value
      };
      this.webFile.upload(dataBody , WebFile.TYPE_CD_CSR_CONSUMER_IMPORT, () => {
        this.eventUploadSuccess.emit();
      }, () => {
        console.log(this);
      });
     } else {
      this.message = "Upload a file";
      this.messageType = "error";
      this.isEnabled = false;
    }
  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCancel.emit();
  }

  // ---------------------------------------------------------------------
  onFileSelected(rawFile: File) {
    this.rawFile = rawFile;
    if (this.rawFile != null) {
      this.isEnabled = true;
    }
  }

}

