
import {Table3Config} from '../extra/table-3-config';

// import {ZzPocGarageGENERATED} from '../_generated/zz-poc-garage-generated.model';

export class ZzPocGarageTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colHorsePower', 'Horse Power', 'horsePower', 'horsePower');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colTypeCd', 'Type', 'typeCd', 'typeCd');

*/
  }
}
