import {Inject, Injectable} from '@angular/core';
import {McProcessFlowServiceGENERATED} from '../_generated/mc-process-flow-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McProcessFlowService extends McProcessFlowServiceGENERATED {

}
