import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { McDashboardRoutingModule } from './mc-dashboard-routing.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { McComponentsModule} from '@miticon-ui/mc-components';
import {McFilterModule, McTableModule} from '@miticon-ui/mc-components';
import {TranslateModule} from '@ngx-translate/core';
import {AppSharedModule} from '../app-shared/app-shared.module';
import {SafeHtmlModule} from "../../assets/pipes/safe-html/safe-html.module";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDatepickerModule} from "@angular/material/datepicker";

@NgModule({
  declarations: [
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    McDashboardRoutingModule,
    NgbModule,
    McComponentsModule,
    McFilterModule,
    McTableModule,
    TranslateModule,
    AppSharedModule,
    SafeHtmlModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    ReactiveFormsModule

  ],
  exports: [
    McDashboardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardComponent
  ]
})
export class McDashboardModule { }
