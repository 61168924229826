
import {Table3Config} from '../extra/table-3-config';

// import {McBillingService2PriceListGENERATED} from '../_generated/mc-billing-service-2-price-list-generated.model';

export class McBillingService2PriceListTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colActivityUnitTypeCd', 'Activity unit', 'activityUnitTypeCd', 'activityUnitTypeCd');
    this.addColumn('colFlgCustomPrice', 'Is custom price', 'flgCustomPrice', 'flgCustomPrice');
    this.addColumn('colFlgDoNotChargeForFactoredTransactions', 'Flag do not for factored transactions', 'flgDoNotChargeForFactoredTransactions', 'flgDoNotChargeForFactoredTransactions');
    this.addColumn('colFlgDoNotChargeForWebDomains', 'Flag do not charge for selected web domains', 'flgDoNotChargeForWebDomains', 'flgDoNotChargeForWebDomains');
    this.addColumn('colIdBillingService', 'Billing service id', 'idBillingService', 'idBillingService');
    this.addColumn('colIdPriceList', 'Price list id', 'idPriceList', 'idPriceList');
    this.addColumn('colMcBillingServiceCatalog', 'Billing Service catalog', 'mcBillingServiceCatalog', 'mcBillingServiceCatalog');
    this.addColumn('colMcPriceList', 'Price List', 'mcPriceList', 'mcPriceList');
    this.addColumn('colNotChargeWebDomains', 'Web domains of entities we don't want to charge from', 'notChargeWebDomains', 'notChargeWebDomains');
    this.addColumn('colPriceAdditionalUnit', 'Additional unit price', 'priceAdditionalUnit', 'priceAdditionalUnit');
    this.addColumn('colPriceFlat', 'Flat price for mix pricing', 'priceFlat', 'priceFlat');
    this.addColumn('colPriceFromContractBatchFee', 'Price from contract', 'priceFromContractBatchFee', 'priceFromContractBatchFee');
    this.addColumn('colPriceFromContractTransactionWithdrawalFeeFactored', 'Price from contract', 'priceFromContractTransactionWithdrawalFeeFactored', 'priceFromContractTransactionWithdrawalFeeFactored');
    this.addColumn('colPriceFromContractTransactionWithdrawalFeeNonFactored', 'Price from contract', 'priceFromContractTransactionWithdrawalFeeNonFactored', 'priceFromContractTransactionWithdrawalFeeNonFactored');
    this.addColumn('colPriceFromContractTransactionWithdrawalFeeService', 'Price from contract', 'priceFromContractTransactionWithdrawalFeeService', 'priceFromContractTransactionWithdrawalFeeService');
    this.addColumn('colPriceOneTime', 'One time only price', 'priceOneTime', 'priceOneTime');
    this.addColumn('colPricingTypeCd', 'Pricing type', 'pricingTypeCd', 'pricingTypeCd');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colUnitLimitForFlatPrice', 'Flat price limit for mix pricing', 'unitLimitForFlatPrice', 'unitLimitForFlatPrice');

*/
  }
}
