<!--<h3 class="page-title">{{'cc.common.imports' | translate}}</h3>

<mk-mat-table
  [items]="mcConsumerImportList.items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  (filterChanged)="onFilterChanged($event)">
</mk-mat-table>-->



<div class="row p-0 m-0 d-flex flex-row align-items-center">
  <h3 class="pr-2">{{'cc.common.imports' | translate}}</h3>
</div>
<div>
    <lib-mc-loader [showLoader]="csrConsumerImportList.apiLoadingFlg"></lib-mc-loader>

    <div class="col-md-12" *ngIf="csrConsumerImportList.apiErrorFlg">
        Error: {{csrConsumerImportList.apiErrorMessage}}
    </div>

    <div class="mc-filter fancy-label">

      <i class="fas fa-info-circle mc-tooltip-top-margin" ngbTooltip="{{'cc.consumer.filter.search-by-import-name' | translate}}" placement="bottom-left"></i> <!-- TODO for release, maybe make a component -->

      <mcc-fi-textbox [isClearEnabled]="true" label="{{'cc.common.search' | translate}}" name="txbSearchTerm" [mcForm]="mcForm" (eventEnterClicked)="actLoad()"></mcc-fi-textbox>

      <mcc-fi-select *ngIf="mcUsers.apiSuccessFlg" [mcForm]="mcForm" name="selUserId"
                         placeholderOption="{{'cc.my-factoring.all' | translate}}" label="{{'cc.users.view.user' | translate}}"
                         [optionsVll]="mcUsersVll"></mcc-fi-select>

      <button class="mc-link mc-filter-clear-button filter-clear-top-right" (click)="actClearFilter()">{{'cc.common.filter.clear-all' | translate}}</button>
      <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>

    </div>

    <div class="col-md-12 p-0" *ngIf="csrConsumerImportList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="csrConsumerImportList"
                  [table3Config]="csrConsumerImportTable3Config"
                 (eventPaginationOrSortChanged)="actLoad()"></mcc-fi-table-3>
    </div>

</div>

