import {McBillingService2Vat} from './mc-billing-service-2-vat.model';
import {McBillingService2VatListGENERATED} from '../_generated/mc-billing-service-2-vat-list-generated.model';

export class McBillingService2VatList extends McBillingService2VatListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingService2VatList();
  }

  // ---------------------------------------------------------------------
}
