import {PmAdjustmentNotificationInfoFilterGENERATED} from '../_generated/pm-adjustment-notification-info-filter-generated.model';

export class PmAdjustmentNotificationInfoFilter extends PmAdjustmentNotificationInfoFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PmAdjustmentNotificationInfoFilter();
  }

  // ---------------------------------------------------------------------
}
