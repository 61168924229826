<h3 class="page-title">{{ 'cc.common.billing-items' | translate }}</h3>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="mcBillingItemList.items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  [totalItemsCount]="mcBillingItemList.totalItemsCount"
  [itemsPerPage]="mcBillingItemList.pageItemsPerPageCount"
  (filterChanged)="onFilterChanged($event)"
  (actionMkMatMenuItems)="onMenuItemAction($event)"
  [searchTooltip]="searchTooltip">
</mk-mat-table>

<!--todo Assign to Statement-->
<!--Add [mkMatMenuActionItems]="menuActionItems" and (selectedItems)="onItemsSelected($event)"to mk-mat-table when BE finishes Assign to statement logic. "FE is finished"-->

<mcc-modal1-wrapper *ngIf="showAssignToStatementMcb.value" modalSizeCd="small" [showMcb]="showAssignToStatementMcb" (eventCanceled)="onAssignToStatementCancelEmit()">
  <mc-out-billing-assign-to-statement-part [mcBillingItem]="mcBillingItem"
                                           [mcBillingItemList]="checkedBillingItemsList"
                                           (eventCanceled)="onAssignToStatementCancelEmit()"
                                           (eventSaved)="onAssignToStatementSaveEmit()">
  </mc-out-billing-assign-to-statement-part>
</mcc-modal1-wrapper>

