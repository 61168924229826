
import {Table3Config} from '../extra/table-3-config';

// import {McProcessGENERATED} from '../_generated/mc-process-generated.model';

export class McProcessTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colIdMcEntity', 'McEntity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colInheritableFlg', 'Inheritable flag', 'inheritableFlg', 'inheritableFlg');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colTitle', 'Process title', 'title', 'title');
    this.addColumn('colTypeCd', 'Process type code', 'typeCd', 'typeCd');

*/
  }
}
