
import {Table3Config} from '../extra/table-3-config';

// import {McConsumerHistoryGENERATED} from '../_generated/mc-consumer-history-generated.model';

export class McConsumerHistoryTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colActionTypeCd', 'Action Type CD', 'actionTypeCd', 'actionTypeCd');
    this.addColumn('colFieldLabel', 'Field Label', 'fieldLabel', 'fieldLabel');
    this.addColumn('colFieldName', 'Field Name', 'fieldName', 'fieldName');
    this.addColumn('colFieldSrcCd', 'Field Src CD', 'fieldSrcCd', 'fieldSrcCd');
    this.addColumn('colFieldTypeCd', 'Field Type CD', 'fieldTypeCd', 'fieldTypeCd');
    this.addColumn('colIdCsrConsumer', 'Consumer ID', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colNewBigintValue', 'New BigInt Value', 'newBigintValue', 'newBigintValue');
    this.addColumn('colNewBooleanValue', 'New Boolean Value', 'newBooleanValue', 'newBooleanValue');
    this.addColumn('colNewDatetimeValue', 'New DateTime Value', 'newDatetimeValue', 'newDatetimeValue');
    this.addColumn('colNewTextValue', 'New Text Value', 'newTextValue', 'newTextValue');
    this.addColumn('colNewVarcharValue', 'New Varchar Value', 'newVarcharValue', 'newVarcharValue');
    this.addColumn('colOldBigintValue', 'Old BigInt Value', 'oldBigintValue', 'oldBigintValue');
    this.addColumn('colOldBooleanValue', 'Old Boolean Value', 'oldBooleanValue', 'oldBooleanValue');
    this.addColumn('colOldDatetimeValue', 'Old DateTime Value', 'oldDatetimeValue', 'oldDatetimeValue');
    this.addColumn('colOldTextValue', 'Old Text Value', 'oldTextValue', 'oldTextValue');
    this.addColumn('colOldVarcharValue', 'Old Varchar Value', 'oldVarcharValue', 'oldVarcharValue');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');

*/
  }
}
