
import {Table3Config} from '../extra/table-3-config';

// import {EbFactoringGENERATED} from '../_generated/eb-factoring-generated.model';

export class EbFactoringTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAmountPaidAfterWithdrawalProcessingDays', 'Factored amount paid after withdrawal processing days', 'amountPaidAfterWithdrawalProcessingDays', 'amountPaidAfterWithdrawalProcessingDays');
    this.addColumn('colBlacklistTriggerHardBounceCount', 'Number of hard bounce failed withdrawals', 'blacklistTriggerHardBounceCount', 'blacklistTriggerHardBounceCount');
    this.addColumn('colBlacklistTriggerSoftBounceCount', 'Number of soft bounce failed withdrawals', 'blacklistTriggerSoftBounceCount', 'blacklistTriggerSoftBounceCount');
    this.addColumn('colChildEntity', 'Child Entity', 'childEntity', 'childEntity');
    this.addColumn('colContractCancellationPeriod', 'Contract cancellation period', 'contractCancellationPeriod', 'contractCancellationPeriod');
    this.addColumn('colContractEndDate', 'Factoring contract end date', 'contractEndDate', 'contractEndDate');
    this.addColumn('colContractRejectionReason', 'Contract rejection reason', 'contractRejectionReason', 'contractRejectionReason');
    this.addColumn('colContractStartDate', 'Factoring contract start date', 'contractStartDate', 'contractStartDate');
    this.addColumn('colCreatedBy', 'Created by', 'createdBy', 'createdBy');
    this.addColumn('colDaysForInitialPayoutForFactoredTransactions', 'Initial payout for factored transactions for factoring', 'daysForInitialPayoutForFactoredTransactions', 'daysForInitialPayoutForFactoredTransactions');
    this.addColumn('colDaysForInitialPayoutForNonFactoredTransactions', 'Initial payout for non-factored transactions for factoring', 'daysForInitialPayoutForNonFactoredTransactions', 'daysForInitialPayoutForNonFactoredTransactions');
    this.addColumn('colDelayDaysForOverLimit', 'Delayed days for over-limit', 'delayDaysForOverLimit', 'delayDaysForOverLimit');
    this.addColumn('colFactoredClaimFeePerTransaction', 'Factored claim fee', 'factoredClaimFeePerTransaction', 'factoredClaimFeePerTransaction');
    this.addColumn('colFactoringContractCd', 'Factoring contract ID', 'factoringContractCd', 'factoringContractCd');
    this.addColumn('colFactoringContractName', 'Factoring contract name', 'factoringContractName', 'factoringContractName');
    this.addColumn('colFeePerBatch', 'Fee per batch', 'feePerBatch', 'feePerBatch');
    this.addColumn('colFeePerTransaction', 'Fee per transaction amount', 'feePerTransaction', 'feePerTransaction');
    this.addColumn('colFeePerTransactionTypeCd', 'Fee per transaction type', 'feePerTransactionTypeCd', 'feePerTransactionTypeCd');
    this.addColumn('colHardCumulativeLimit', 'Hard cumulative limit', 'hardCumulativeLimit', 'hardCumulativeLimit');
    this.addColumn('colHardCumulativeLimitDays', 'Hard cumulative limit days', 'hardCumulativeLimitDays', 'hardCumulativeLimitDays');
    this.addColumn('colHardLimitForCurrentEntity', 'Hard limit for current entity', 'hardLimitForCurrentEntity', 'hardLimitForCurrentEntity');
    this.addColumn('colHardLimitForCurrentEntityDays', 'Hard limit for current entity days', 'hardLimitForCurrentEntityDays', 'hardLimitForCurrentEntityDays');
    this.addColumn('colIdChildMcEntity', 'Child (receiving) entity ID', 'idChildMcEntity', 'idChildMcEntity');
    this.addColumn('colIdMcCurrency', 'Currency ID', 'idMcCurrency', 'idMcCurrency');
    this.addColumn('colIdParentMcEntity', 'Parent (sending) entity ID', 'idParentMcEntity', 'idParentMcEntity');
    this.addColumn('colLimitPerConsumer', 'Limit per consumer', 'limitPerConsumer', 'limitPerConsumer');
    this.addColumn('colLimitPerEntityTree', 'Limit per entity tree', 'limitPerEntityTree', 'limitPerEntityTree');
    this.addColumn('colMaximalNumberOfFailedWithdrawals', 'Maximal number of failed withdrawals', 'maximalNumberOfFailedWithdrawals', 'maximalNumberOfFailedWithdrawals');
    this.addColumn('colMcCurrency', 'Currency', 'mcCurrency', 'mcCurrency');
    this.addColumn('colMonthsAllowedFutureCount', 'Allowed to import claim in the future (months)', 'monthsAllowedFutureCount', 'monthsAllowedFutureCount');
    this.addColumn('colMonthsAllowedPastCount', 'Allowed to import claim in the past (months)', 'monthsAllowedPastCount', 'monthsAllowedPastCount');
    this.addColumn('colNoDaysForInitialPayoutNonFactoring', 'Number of days for initial payout for non factoring', 'noDaysForInitialPayoutNonFactoring', 'noDaysForInitialPayoutNonFactoring');
    this.addColumn('colNoDaysForTheRestToBePaidNonFactoring', 'Number of days for the rest to be paid for non factoring', 'noDaysForTheRestToBePaidNonFactoring', 'noDaysForTheRestToBePaidNonFactoring');
    this.addColumn('colNonFactoredClaimFeePerTransaction', 'Non factored claim fee', 'nonFactoredClaimFeePerTransaction', 'nonFactoredClaimFeePerTransaction');
    this.addColumn('colParentEntity', 'Parent Entity', 'parentEntity', 'parentEntity');
    this.addColumn('colPercentageForInitialPayoutNonFactoring', 'Percentage for initial payout for non factoring', 'percentageForInitialPayoutNonFactoring', 'percentageForInitialPayoutNonFactoring');
    this.addColumn('colRemoveMemberWithoutOpenDebtFromBlFlg', 'Remove member without opet debt from blacklist flag', 'removeMemberWithoutOpenDebtFromBlFlg', 'removeMemberWithoutOpenDebtFromBlFlg');
    this.addColumn('colReturnedTransactionFee', 'Bank fee for returned transactions', 'returnedTransactionFee', 'returnedTransactionFee');
    this.addColumn('colSendClaimsToBlacklistFlg', 'Send claims to blacklist flag', 'sendClaimsToBlacklistFlg', 'sendClaimsToBlacklistFlg');
    this.addColumn('colSendDirectToInkassoFlg', 'Send to inkasso', 'sendDirectToInkassoFlg', 'sendDirectToInkassoFlg');
    this.addColumn('colSoftLimitPerSepaRun', 'Soft limit per SEPA run', 'softLimitPerSepaRun', 'softLimitPerSepaRun');
    this.addColumn('colSoftLimitPerTransaction', 'Soft limit per transaction', 'softLimitPerTransaction', 'softLimitPerTransaction');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');
    this.addColumn('colTotalMonthlyLimitPerEntity', 'Total monthly limit per entity', 'totalMonthlyLimitPerEntity', 'totalMonthlyLimitPerEntity');
    this.addColumn('colTypeCd', 'Factoring contract type', 'typeCd', 'typeCd');
    this.addColumn('colUpdatedBy', 'Updated by', 'updatedBy', 'updatedBy');
    this.addColumn('colVersion', 'Version', 'version', 'version');

*/
  }
}
