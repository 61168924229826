import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {
  McBillingStatementItem,
  McBoolean,
  McForm,
} from "@miticon-ui/mc-core";

@Component({
  selector: 'mc-out-billing-aggregation-add-edit-part',
  templateUrl: './mc-out-billing-aggregation-add-edit-part.component.html',
})
export class McOutBillingAggregationAddEditPartComponent implements OnInit {

  disableAggregateBtnMcb = new McBoolean();
  mcForm = new McForm();
  mcStatementItem = new McBillingStatementItem();

  @Input() statementId!: number;
  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();

  ngOnInit() {
  }

  actSave() {

    if (this.mcForm.isValid()) {
      this.mcStatementItem.title = this.mcForm.getValue('ctrlAggregationName');
      this.mcStatementItem.statusCd = McBillingStatementItem.STATUS_CD_ACTIVE;
      this.mcStatementItem.mcBillingStatementId = this.statementId;
      this.mcStatementItem.typeCd = McBillingStatementItem.TYPE_CD_AGGREGATE;
      this.mcStatementItem.amount = 0;

      this.mcStatementItem.save(() => {
        this.eventSaved.emit();
      });
    }

  }

  actCancel() {
    this.eventCanceled.emit();
  }

}
