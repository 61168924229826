/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EbAccountConsumerFilter} from '../models/eb-account-consumer-filter.model';

export class EbAccountConsumerFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbAccountConsumerFilter {
    console.error('MTCN-ERROR: Not Implemented: EbAccountConsumerFilter::createNewInstance(). Add this method to final class and return new EbAccountConsumerFilter();');
      throw new Error('Cannot EbAccountConsumerFilter::createNewInstance(). Add this method to final class and return new EbAccountConsumerFilter();');
  }

}
