<div class="container mc-create-role-modal-body px-0" [ngClass]="{'border-bottom-0': editRole}">
<div class="mc-create-role-modal-edit-body">
  <div class="row mc-header-wrapper" *ngIf="!editRole">
    <div class="col-md-12 mc-stepper-header-title">
  <span class="pl-4 pb-1 ml-4 mr-2 mc-active">
    <span class="mc-notification-badge-gray mc-selected-step">1</span>
    {{ 'cc.common.view.basic-info' | translate }}
  </span>
      <span class="pl-4 pb-1 ml-4 mr-2">
      <span class="mc-notification-badge-gray">2</span>
        {{'cc.roles.view.permissions' | translate}}
    </span>
    </div>
  </div>

  <!--Error section-->
  <div class="mc-create-user-notify-wrapper">
    <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
  </div>


  <form [formGroup]="registerForm">
    <!--Status label-->
    <div class="d-block">
      <p class="text-capitalize mc-role-label">
        status
        <span class="text-danger">*</span>
      </p>

      <div class="mc-checkbox-wrapper">
        <!--Active-->
        <div class="form-check d-inline pl-0">
          <input (change)="isFormValid()"
                 class="form-check-input"
                 type="radio"
                 name="status"
                 id="mc-active"
                 value="active"
                 [checked]="(editRole && editRole.statusCode == 'ACTIVE') || !editRole"
                 formControlName="status">
          <label class="form-check-label" for="mc-active">
            {{ 'cc.common.view.active' | translate }}
          </label>
        </div>

        <!--Inactive-->
        <div class="form-check d-inline">
          <input (change)="isFormValid()"
                 class="form-check-input"
                 type="radio"
                 name="status"
                 id="mc-inactive"
                 value="inactive"
                 [checked]="editRole && editRole.statusCode == 'INACTIVE'"
                 formControlName="status"
                 (input)="checkMandatoryInputFields()">
          <label class="form-check-label" for="mc-inactive">
            {{'cc.common.view.inactive' | translate}}
          </label>
        </div>
      </div>
    </div><!--Status /end-->

    <!--Role name-->
    <div class="row px-3 mt-3">
      <div class="form-group w-100">
        <label class="mc-role-label" for="caption">
          {{'cc.roles.view.role-name' | translate}}
          <span class="text-danger">*</span>
        </label>

        <!--Prepend selected entity name-->
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text bg-white border-right-0 text-muted" for="caption">{{selectedEntity}}</label>
          </div>

          <!--Input field-->
          <input (ngModelChange)="isFormValid()"
                 type="text"
                 class="form-control border-left-0"
                 id="caption"
                 formControlName="caption"
                 aria-describedby="caption"
                 [ngClass]="roleNameNotValid ? 'mc-error-field' : ''"
                 (input)="checkMandatoryInputFields()"
          >
        </div>

      </div>
    </div><!--Role name /end-->

    <!--Description-->
    <div class="row px-3">
      <div class="form-group w-100">
        <label class="mc-role-label text-capitalize" for="description">
          {{ 'cc.common.view.description' | translate }}
          <span class="text-danger">*</span>
        </label>
        <textarea (ngModelChange)="isFormValid()"
                  class="form-control"
                  id="description"
                  rows="5"
                  formControlName="description"
                  [ngClass]="descriptionNotValid ? 'mc-error-field' : ''"
                  (input)="checkMandatoryInputFields()"
        ></textarea>
      </div>
    </div><!--Description /end-->

    <div class="row px-3" *ngIf="mcGod.userHasMcRoleTemplateCreateEditPermission()">
      <div class="form-group w-100">
        <input type="checkbox" [ngbTooltip]="isSetTemplateCheckboxDisabled ? 'This field cannot be unchecked because a role has already been created from this template.' : ''"
               id="setRoleAsATemplate" formControlName="setRoleAsATemplate" class="mr-2" (click)="onCheckboxClicked()" (change)="isFormValid()" >
        <label for="setRoleAsATemplate">{{'cc.roles.set-role-as-a-template' | translate}}</label>
      </div>
    </div>
  </form>
</div>


</div>
