/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McDashboard} from '../models/mc-dashboard.model';
import {McDashboardList} from '../models/mc-dashboard-list.model';
import {McDashboardFilter} from '../models/mc-dashboard-filter.model';
import {McDashboardService} from '../services/mc-dashboard.service';
import {IMcDashboard} from '../_generated/mc-dashboard.interface';
import {McGod} from "../extra/mc-god.service";

export class McDashboardListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'activeConsumers', 'automaticallyMatchedTransactions', 'consumersWithBalance', 'emailsSentChild', 'emailsSentParent', 'inactiveConsumers', 'isChildOnly', 'manuallyMatchedTransactions', 'manualMatchingNeededTransactions', 'overdueTransactions', 'paidTransactions', 'pdfGenerated', 'rejectedTransactions', 'sentToInkassoTransactions', 'sentTransactions', 'toBeSentTransactions'];
  public items: McDashboard[] = [];
  public apiService: McDashboardService;
 // protected tempObjList: McDashboardList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McDashboardList {
    console.error('MTCN-ERROR: Not Implemented: McDashboardList::createNewInstance(). Add this method to final class and return new McDashboardList();');
    throw new Error('Cannot McDashboardList::createNewInstance(). Add this method to final class and return new McDashboardList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcDashboard[]): McDashboardList {
    const  listObj: McDashboardList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McDashboard = McDashboard.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McDashboardList {
    const  listObj: McDashboardList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McDashboardService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcDashboard[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McDashboard = McDashboard.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcDashboardFilter: McDashboardFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcDashboardFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McDashboard {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McDashboard();
    }
  }

  public replaceItem(newItem: McDashboard) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

