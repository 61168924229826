/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {ImDataImport} from '../models/im-data-import.model';
import {ImDataImportService} from '../services/im-data-import.service';
import {IImDataImport} from '../_generated/im-data-import.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class ImDataImportGENERATED extends BaseObject {


    public static readonly STATUS_CD_IN_PROGRESS: string = 'In_progress';
    public static readonly STATUS_CD_IMPORTED: string = 'Imported';
    public static readonly STATUS_CD_PARTIALLY_IMPORTED: string = 'Partially_imported';
    public static readonly STATUS_CD_FAILED: string = 'Failed';
  public static readonly STATUS_CD_LIST__ALL = [
    ImDataImportGENERATED.STATUS_CD_IN_PROGRESS,
    ImDataImportGENERATED.STATUS_CD_IMPORTED,
    ImDataImportGENERATED.STATUS_CD_PARTIALLY_IMPORTED,
    ImDataImportGENERATED.STATUS_CD_FAILED
];

  public apiService: ImDataImportService;
  public _rawJson: IImDataImport;
    id: number = 0;
  dataImport = '';
  dataSetId: number;
  delimiter = '';
  encoding = '';
  header: boolean;
  idMcEntity: number;
  importMode = '';
  mappingId: number;
  src = '';
  statusCd: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  webFileId: number;


  public properties: string[] = ['id', 'dataImport', 'dataSetId', 'delimiter', 'encoding', 'header', 'idMcEntity', 'importMode', 'mappingId', 'src', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'webFileId'];
  public propertiesRegular: string[] = ['id', 'dataImport', 'dataSet', 'dataSetId', 'delimiter', 'encoding', 'header', 'idMcEntity', 'importMode', 'mappingId', 'src', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'webFile', 'webFileId'];
  public propertiesSpecial: string[] = [];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(ImDataImportGENERATED.STATUS_CD_IN_PROGRESS, McGod.t('In progress'));
    list.add(ImDataImportGENERATED.STATUS_CD_IMPORTED, McGod.t('Imported'));
    list.add(ImDataImportGENERATED.STATUS_CD_PARTIALLY_IMPORTED, McGod.t('Partially imported'));
    list.add(ImDataImportGENERATED.STATUS_CD_FAILED, McGod.t('Failed'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = ImDataImportGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): ImDataImport {
    console.error('MTCN-ERROR: Not Implemented: ImDataImport::createNewInstance(). Add this method to final class and return new ImDataImport();');
    throw new Error('Cannot ImDataImport::createNewInstance(). Add this method to final class and return new ImDataImport();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IImDataImport): ImDataImport {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(ImDataImportService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE ImDataImport AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return ImDataImportGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdIn_progress(): boolean {
    const result = (this.statusCd === ImDataImportGENERATED.STATUS_CD_IN_PROGRESS);

    return result;
  }

 public  isStatusCdImported(): boolean {
    const result = (this.statusCd === ImDataImportGENERATED.STATUS_CD_IMPORTED);

    return result;
  }

 public  isStatusCdPartially_imported(): boolean {
    const result = (this.statusCd === ImDataImportGENERATED.STATUS_CD_PARTIALLY_IMPORTED);

    return result;
  }

 public  isStatusCdFailed(): boolean {
    const result = (this.statusCd === ImDataImportGENERATED.STATUS_CD_FAILED);

    return result;
  }


  
}
