import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BaseDirective,
  CsrConsumer,
  EbSepaTransactionService,
  McForm,
  McGod,
  McValueLabelList,
  PmTransaction,
  PmTransactionList
} from '@miticon-ui/mc-core';
import {takeUntil} from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'pm-transactions-storno-part',
  templateUrl: './pm-transactions-storno-part.component.html',
  styleUrls: ['./pm-transactions-storno-part.component.scss']
})
export class PmTransactionsStornoPartComponent extends BaseDirective implements OnInit {

  @Input() csrConsumer = new CsrConsumer();
  @Input() pmTransactions = new PmTransactionList();

  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter();

  mcGod = McGod.getInstance();
  mcForm = new McForm();
  stornoReasonVll = new McValueLabelList();
  pmTransaction = new PmTransaction();
  disableBtn = false;

  constructor(private ebSepaTransactionService: EbSepaTransactionService, private toastr: ToastrService) {
    super();
  }

  ngOnInit() {
    if (this.pmTransactions.getCount() === 1) {
      this.pmTransaction = this.pmTransactions.items[0];
    }
    this.stornoReasonVll = PmTransaction.getStornoReasonCdVll();

  }

  actSave() {
    if (this.mcForm.isValid()) {
      const transactionIds = this.pmTransactions.items.map(transaction => transaction.id);
      const stornoReason = this.mcForm.getControl('ctrlStornoReason')?.get('value')?.value;
      const stornoDescription = this.mcForm.getControl('ctrlDescription')?.get('value')?.value;
      this.disableBtn = true;

      this.ebSepaTransactionService.storno(transactionIds, stornoReason, stornoDescription).pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.disableBtn = false;
        this.eventSaved.emit();
        }, (err) => {
        const error = JSON.parse(err.error);
        this.disableBtn = false;
        this.toastr.error(error.message);
      });
    }
  }

  actCancel() {
    this.eventCanceled.emit();
  }
}
