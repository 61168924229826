/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {ZzPocConsumer} from '../models/zz-poc-consumer.model';
import {ZzPocConsumerList} from '../models/zz-poc-consumer-list.model';
import {ZzPocConsumerFilter} from '../models/zz-poc-consumer-filter.model';
import {ZzPocConsumerService} from '../services/zz-poc-consumer.service';
import {IZzPocConsumer} from '../_generated/zz-poc-consumer.interface';
import {McGod} from "../extra/mc-god.service";

export class ZzPocConsumerListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'accountOwner', 'active', 'addressSupplement', 'archived', 'bankName', 'bic', 'birthday', 'businessCellphone', 'businessTelephone', 'cellphone', 'city', 'companyName', 'contractBaseAmount', 'contractCancellationActiveOnDate', 'contractCancellationDate', 'contractCancellationReason', 'contractEndDate', 'contractModel', 'contractNumber', 'contractSigningDate', 'contractStartDate', 'contractStatus', 'contractType', 'countryName', 'createdAt', 'createdBy', 'customAttributes', 'email', 'euVat', 'extConsumerId', 'fax', 'firstName', 'gender', 'iban', 'idMcEntity', 'individual', 'isoCountry', 'isoLanguage', 'lastName', 'postCode', 'sepaMandanteDate', 'sepaMandanteId', 'stateIndex', 'statusCd', 'street', 'telephone', 'title', 'typeCd', 'updatedAt', 'updatedBy', 'version', 'blacklistPercentage', 'consumerDisplayName', 'entityConsumerAttributes', 'entityName', 'numOfActiveContract'];
  public items: ZzPocConsumer[] = [];
  public apiService: ZzPocConsumerService;
 // protected tempObjList: ZzPocConsumerList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): ZzPocConsumerList {
    console.error('MTCN-ERROR: Not Implemented: ZzPocConsumerList::createNewInstance(). Add this method to final class and return new ZzPocConsumerList();');
    throw new Error('Cannot ZzPocConsumerList::createNewInstance(). Add this method to final class and return new ZzPocConsumerList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IZzPocConsumer[]): ZzPocConsumerList {
    const  listObj: ZzPocConsumerList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: ZzPocConsumer = ZzPocConsumer.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): ZzPocConsumerList {
    const  listObj: ZzPocConsumerList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(ZzPocConsumerService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IZzPocConsumer[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: ZzPocConsumer = ZzPocConsumer.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aZzPocConsumerFilter: ZzPocConsumerFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aZzPocConsumerFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): ZzPocConsumer {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new ZzPocConsumer();
    }
  }

  public replaceItem(newItem: ZzPocConsumer) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
// ---------------------------------------------------------------------
public callImportConsumers( mappingId: number, handleDuplicatesCd: string, dateFormat: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.importConsumers(mappingId,handleDuplicatesCd,dateFormat).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

// ---------------------------------------------------------------------
public callDevFixConsumerStatusAndTypeCode( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.devFixConsumerStatusAndTypeCode().subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

// ---------------------------------------------------------------------
public callDevFixConsumerContractNumber( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.devFixConsumerContractNumber().subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}





}

