/*BbmTestObj*/
import {McBillingService2VatGENERATED} from '../_generated/mc-billing-service-2-vat-generated.model';

export class McBillingService2Vat extends McBillingService2VatGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingService2Vat();
  }

  // ---------------------------------------------------------------------
}
