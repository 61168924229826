import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-mc-core',
  template: `
    <p>
      mc-core works!
    </p>
  `,
  styles: []
})
export class McCoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
