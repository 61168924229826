<div class="content-wrapper storno-wrapper">
  <lib-mc-loader [showLoader]="pmTransactions.getCount() === 0"></lib-mc-loader>

  <div class="content-header">
    <h2>{{mcGod.t('cc.common.storno')}}</h2>
  </div>
  <div class="content-body">
    <ng-container *ngIf="pmTransactions.getCount() === 1">
      <app-shared-card showHeader="true" header="{{mcGod.t('cc.my-factoring.consumer-information')}}" class="mb-3">
        <mcc-row-title-description title="{{mcGod.t('cc.my-factoring.consumer')}}:" extraCssClasses="card-control"
                                   [description]="csrConsumer?.displayName">
        </mcc-row-title-description>

        <mcc-row-title-description title="{{mcGod.t('cc.common.contract-number')}}:" extraCssClasses="card-control"
                                   [description]="csrConsumer?.contractNumber">
        </mcc-row-title-description>

        <mcc-row-title-description title="{{mcGod.t('cc.consumer.studio')}}:" extraCssClasses="card-control"
                                   [description]="pmTransaction.mcEntity.name">
        </mcc-row-title-description>

        <mcc-row-title-description title="{{mcGod.t('cc.my-factoring.original-amount')}}:" extraCssClasses="card-control"
                                   [description]="pmTransaction?.getAmount()">
        </mcc-row-title-description>

        <mcc-row-title-description title="{{mcGod.t('cc.my-factoring.remaining-amount-due')}}" extraCssClasses="card-control"
                                   [description]="pmTransaction?.getAmountDue()">
        </mcc-row-title-description>
      </app-shared-card>

      <div class="actions-container mb-3">
        <span>{{mcGod.t('cc.out-factoring.transaction-id')}} - {{pmTransaction.id}}</span>
      </div>
    </ng-container>

    <ng-container *ngIf="pmTransactions.getCount() > 1">
      <div class="actions-container mb-3">
        <span>{{mcGod.t('cc.transactions.number-of-selected-transactions') | titlecase}}: {{pmTransactions.getCount()}}</span>
      </div>
    </ng-container>

    <mcc-fi-select [mcForm]="mcForm" name="ctrlStornoReason" label="{{mcGod.t('cc.storno.reason')}}"
                   [optionsVll]="stornoReasonVll" [validRequiredFlg]="true" placeholderOption="{{'cc.common.edit.please-choose' | translate}}"></mcc-fi-select>
    <mcc-fi-textarea name="ctrlDescription" label="{{'cc.common.view.description' | translate}}" [numCols]="35" [validRequiredFlg]="true" [validWhitespaces]="true"
                     [mcForm]="mcForm"></mcc-fi-textarea>
  </div>
  <div class="content-footer d-flex justify-content-end">
    <button [ngClass]="disableBtn ? 'mc-disable-button' : ''" class="system-action-btn dark-system-action-btn" (click)="actSave()">{{mcGod.t("cc.common.view.save")}}</button>
    <button class="system-action-btn light-system-action-btn" (click)="actCancel()">{{mcGod.t("cc.common.edit.cancel")}}</button>
  </div>
</div>
