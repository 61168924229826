import {Inject, Injectable} from '@angular/core';
import {McConsumerHistoryServiceGENERATED} from '../_generated/mc-consumer-history-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McConsumerHistoryService extends McConsumerHistoryServiceGENERATED {

}
