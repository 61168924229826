import { McProductProductListPageComponent } from './pages/mc-product-product-list-page/mc-product-product-list-page.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ContractsPredefinedListPageComponent} from "./pages/contracts-predefined-list-page/contracts-predefined-list-page.component";
import {
  ContractPredefinedAddEditComponent
} from "./pages/contracts-predefined-list-page/contract-predefined-add-edit/contract-predefined-add-edit.component";

const routes: Routes = [
  {
    path: 'list',
    component: McProductProductListPageComponent
  },
  {
    path: 'contracts-360',
    children: [
      {
        path: '',
        component: ContractsPredefinedListPageComponent
      },
      {
        path: 'create',
        component: ContractPredefinedAddEditComponent
      },
      {
        path: 'edit/:contractId',
        component: ContractPredefinedAddEditComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McProductRoutingModule {
}
