import { McCountryCountryProfilePageComponent } from './pages/mc-country-country-profile-page/mc-country-country-profile-page.component';
import { McCountryCountryItemListComponent } from './pages/mc-country-country-item-list/mc-country-country-item-list.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [
{
    path: 'profile/:id',
    component: McCountryCountryProfilePageComponent
  },
{
    path: 'overview',
    component: McCountryCountryItemListComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McCountryRoutingModule {
}
