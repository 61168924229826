import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ICON_CLOSE} from "../../../../assets/media/svg_icons/icon-close";
import {
  ICON_FAILED_WHITE,
  ICON_IMPORTED_WHITE,
  ICON_PARTIALLY_IMPORTED_WHITE,
  ICON_IN_PROGRESS_WHITE,
  ImDataImport,
  ImDataImportItem,
  ImDataImportItemService,
  ImDataImportService
} from "@miticon-ui/mc-core";
import {ICON_DROP_UP} from "../../../../assets/media/svg_icons/icon-drop-up";
import {ICON_FAILED_ITEMS} from "../../../../assets/media/svg_icons/icon-failed-items";
import {ICON_SKIPPED_ITEMS} from '../../../../assets/media/svg_icons/icon-skipped-items';

@Component({
  selector: 'lib-data-import-view-dialog',
  templateUrl: './data-import-view-dialog.component.html',
  styleUrls: ['./data-import-view-dialog.component.scss']
})
export class DataImportViewDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  iconImported = ICON_IMPORTED_WHITE;
  iconPartiallyImported = ICON_PARTIALLY_IMPORTED_WHITE;
  iconFailed = ICON_FAILED_WHITE;
  iconInProgress = ICON_IN_PROGRESS_WHITE;
  iconSkippedItems = ICON_SKIPPED_ITEMS;
  iconFailedItems = ICON_FAILED_ITEMS;
  iconArrow = ICON_DROP_UP;
  dataImport!: any;
  formattedDate!: string;
  dataImportAnalytics: any;
  viewSkippedItems: boolean = false;
  viewFailedItems: boolean = false;
  skippedItems: ImDataImportItem[] = [];
  failedItems: ImDataImportItem[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: number,
              public dialogRef: MatDialogRef<DataImportViewDialogComponent>,
              private dataImportService: ImDataImportService,
              private dataImportItemService: ImDataImportItemService) { }

  ngOnInit(): void {
    if(this.data) {
      this.loadDataImport(this.data);
    }
  }


  loadDataImport(dataImportId: number) {
    this.dataImportService.getById(dataImportId).subscribe((data) => {
      this.dataImport = data;
      if(this.dataImport.sysCreatedDatetime) {
        const date = new Date(this.dataImport.sysCreatedDatetime);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        // @ts-ignore
        this.formattedDate = date.toLocaleString('en-GB', options).replace(/\//g, '.').replace(",", "") + 'h';
      }
    })
    this.dataImportItemService.getByDataImportId(dataImportId).subscribe((data) => {
      this.dataImportAnalytics = data;
      this.skippedItems = this.dataImportAnalytics.dataImportItems
        .filter((item: ImDataImportItem) => item.statusCd === "SKIPPED")
        .sort((a: ImDataImportItem,b: ImDataImportItem) => {
          return a.index - b.index
        });
      this.failedItems = this.dataImportAnalytics.dataImportItems
        .filter((item: ImDataImportItem) => item.statusCd === "FAILED")
        .sort((a: ImDataImportItem,b: ImDataImportItem) => {
          return a.index - b.index
        });
    })
  }

  getFormattedImportMode(importMode: string) {
    const importModes: { [key: string]: string } = {
      IMPORT_AND_UPDATE: "mem.import.import-new-and-update",
      IMPORT: "mem.import.import-new",
      UPDATE: "mem.import.import-update",
    };

    return importModes[importMode] || "";
  }

  getStatusIcon(status: string): string {
    let icon = '';
    switch (status) {
      case ImDataImport.STATUS_CD_IMPORTED:
        icon = this.iconImported;
        break;
      case ImDataImport.STATUS_CD_PARTIALLY_IMPORTED:
        icon = this.iconPartiallyImported;
        break;
      case ImDataImport.STATUS_CD_FAILED:
        icon = this.iconFailed;
        break;
      case ImDataImport.STATUS_CD_IN_PROGRESS:
        icon = this.iconInProgress;
        break;
    }
    return icon;
  }

  getSkippedTitle(): string {
    return this.dataImport.importMode === "IMPORT" ? "mem.import.skipped-import-mode" : "mem.import.skipped-update-mode";
  }

  getIndex(index: number) {
    return index.toString().length === 1 ? "0" + index : index;
  }

  getStatusLabel(status: string) {
    return ImDataImport.getStatusCdLabel(status);
  }
}
