import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CsrConsumer,
  McBoolean,
  McConsumer,
  McConsumerAction,
  McConsumerActionList,
  McConsumerActionTable3Config,
  McForm, McHtmlButton
} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-consumer-action-history-part',
  templateUrl: './mc-consumer-action-history-part.component.html',
})
export class McConsumerActionHistoryPartComponent implements OnInit {

  @Input() csrConsumer = new CsrConsumer();
  @Input() csrConsumerId!: number;
  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  mcConsumerActionList = new McConsumerActionList();
  mcForm = new McForm();
  mcConsumerActionHistoryTable3Config = new McConsumerActionTable3Config();
  showActionDetailsMcb = new McBoolean();
  showActionResendMcb = new McBoolean();
  mcConsumerAction!: McConsumerAction;
  mcActionCd!: string;
  ngOnInit() {
    // if (this.csrConsumer.exists()) {
    //   this.mcConsumerActionList.loadByConsumerId(this.csrConsumer.id);
    // } else {
    //   this.csrConsumer.loadById(this.csrConsumerId);
    //   this.mcConsumerActionList.loadByConsumerId(this.csrConsumerId);
    // }
  }


  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
  }

  actClose() {
    this.eventCanceled.emit();
  }

  onTableItemAction(mcButton: McHtmlButton) {
    if (mcButton.actionCdForEvent === McConsumerAction.ACTION_RESEND) {
      this.mcConsumerAction = mcButton.item;
      this.showActionResendMcb.setTrue();
    } else if (mcButton.actionCdForEvent === McConsumerAction.ACTION_RETRY) {

    } else {
      this.mcConsumerAction = mcButton.item;
      this.mcActionCd = mcButton.actionCdForEvent;
      this.showActionDetailsMcb.setTrue();
    }
  }

  actCancelActionDetailsMcb() {
    this.showActionDetailsMcb.setFalse();
    this.mcConsumerAction = new McConsumerAction();
  }

  actCancelActionResendMcb() {
    this.showActionResendMcb.setFalse();
  }
}
