/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CsrContract2ProductTable3ConfigGENERATED} from '../_generated/csr-contract-2-product-table-3-config-generated.model';


export class CsrContract2ProductTable3Config extends CsrContract2ProductTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdCsrContract', 'Consumer contract', 'idCsrContract', 'idCsrContract');
    this.addColumn('colIdMcProductSnapshot', 'Product Snapshoot', 'idMcProductSnapshot', 'idMcProductSnapshot');


  }
}
