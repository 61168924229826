/*BbmTestObj*/
import {ImDataExportTemplateGENERATED} from '../_generated/im-data-export-template-generated.model';

export class ImDataExportTemplate extends ImDataExportTemplateGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataExportTemplate();
  }

  // ---------------------------------------------------------------------
}
