import {McBillingStatementItem} from './mc-billing-statement-item.model';
import {McBillingStatementItemListGENERATED} from '../_generated/mc-billing-statement-item-list-generated.model';

export class McBillingStatementItemList extends McBillingStatementItemListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingStatementItemList();
  }

  override populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;
    this.totalItemsCount = aJsonObjPageable.totalElements;

    // return listObj;
  }

  // ---------------------------------------------------------------------
}
