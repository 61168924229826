<ng-container>
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-wrapper">
    <!--    <lib-mc-loader [showLoader]="webFile.apiLoadingFlg"></lib-mc-loader>-->

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-header">
      <ng-container *ngIf="!isContractCloned">
        <h4>{{'cc.contracts.select-contract-type-and-entity' | translate}}</h4>
      </ng-container>
      <ng-container *ngIf="isContractCloned">
        <h4>{{'cc.contracts.select-entity-for-clone-contract' | translate}}</h4>
      </ng-container>
    </div>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-body">

      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <ng-container *ngIf="!isContractCloned">
              <h2>
                {{'cc.contract.contract-type' | translate}}
              </h2>
            </ng-container>
            <ng-container *ngIf="isContractCloned">
              <h2>
                {{'cc.contracts.cloning-contract-info' | translate}}
              </h2>
            </ng-container>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isContractCloned" class="mt-3">
        <mcc-row-title-description title="{{'cc.contracts.cloning-contract-type' | translate}}" [description]="isContractTypeOfService ? 'Service' : 'Factoring'"></mcc-row-title-description>
        <mcc-row-title-description title="{{'cc.contracts.cloning-from-entity' | translate}}" [description]="ebFactoring.childEntity.name"></mcc-row-title-description>
        <mcc-row-title-description title="{{'cc.contracts.cloning-contract-name' | translate}}" [description]="ebFactoring.factoringContractName"></mcc-row-title-description>
      </ng-container>

      <ng-container *ngIf="!isContractCloned">
        <mcc-fi-radio-buttons [mcForm]="mcForm" [optionsVlm]="optionsVlm" [name]="'contractType'"
                              [directionHorizontal]="true" (eventValueChanged)="contractTypeValueChanged($event)"></mcc-fi-radio-buttons>
      </ng-container>

      <div class="row mt-3">
        <div class="col-md-12">
          <div class="section-header">
            <h2>
              {{'cc.common.admin.entities' | translate}}
            </h2>
          </div>
        </div>
      </div>

      <mcc-message [successMsg]="successMessage" [errorMsg]="errorMsg"></mcc-message>

      <lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>

      <ng-container *ngIf="mcEntity2List.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="mcEntity2List"
                        (eventSelectionChanged)="receiveStateOnSelect($event)"
                        [table3Config]="mcEntity2Table3Config"
                        (eventItemAction)="actEntity2TableItemAction($event)"></mcc-fi-table-3>
      </ng-container>

    </div><!-- modal-body -->

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-footer">
      <button class="btn btn-primary mr-3 move-right" [disabled]="disableNext" (click)="actNext()">
        {{'cc.common.view.next' | translate}} <i class="fas fa-arrow-right"></i>
      </button>

    </div>
  </div>
</ng-container>
