import {CsrContractPreDefinedFilterGENERATED} from '../_generated/csr-contract-pre-defined-filter-generated.model';

export class CsrContractPreDefinedFilter extends CsrContractPreDefinedFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrContractPreDefinedFilter();
  }

  // ---------------------------------------------------------------------
}
