import {Component, Input, OnInit} from "@angular/core";
import {
  McForm, McProcessFlow, McProcessFlowCriteria, McProcessFlowCriteriaAttribute, McProcessFlowCriteriaAttributeList, McTrigger,
  McValueLabelList
} from "@miticon-ui/mc-core";
import {Subscription} from "rxjs";

@Component({
  selector: 'mc-process-process-flow-criteria',
  templateUrl: './mc-process-process-flow-criteria.component.html',
  styleUrls: ['./mc-process-process-flow-criteria.component.css']
})
export class McProcessProcessFlowCriteriaComponent implements OnInit {

  @Input() mcForm!: McForm;
  mcProcessFlowCriteriaAttribute: McProcessFlowCriteriaAttribute | undefined = new McProcessFlowCriteriaAttribute();
  operatorTypeCdVll: McValueLabelList | undefined = new McValueLabelList();
  optionTypeVll: McValueLabelList | undefined = new McValueLabelList();
  criteriaOperatorVll = new McValueLabelList();
  optionTypeRequiredFlg = false;

  @Input() id!: number;
  @Input() index!: number;
  @Input() mcProcessFlow = new McProcessFlow();
  @Input() mcProcessFlowCriteria = new McProcessFlowCriteria();
  @Input() attributesVll = new McValueLabelList();
  @Input() mcProcessFlowCriteriaAttributeList = new McProcessFlowCriteriaAttributeList();

  constructor() { }

  ngOnInit() {
    // debugger
    this.criteriaOperatorVll.add(McProcessFlowCriteria.CRITERIA_OPERATOR_TYPE_CD_AND, McProcessFlowCriteria.CRITERIA_OPERATOR_TYPE_CD_AND);
    // this.criteriaOperatorVll.add(McProcessFlowCriteria.CRITERIA_OPERATOR_TYPE_CD_OR, McProcessFlowCriteria.CRITERIA_OPERATOR_TYPE_CD_OR);
    if (this.index !== 0 && this.mcProcessFlowCriteria.criteriaOperatorTypeCd === undefined) { // temp solution
      // debugger
      this.mcProcessFlowCriteria.criteriaOperatorTypeCd = McProcessFlowCriteria.CRITERIA_OPERATOR_TYPE_CD_AND;
    }
    this.mcProcessFlowCriteria.seqNo = Math.abs(this.id);
    console.log('222222222222222', this.mcProcessFlowCriteria);
    if (this.mcProcessFlowCriteria.attributeName) {
      this.flowCriteriaAttributeSelectChanged(this.mcProcessFlowCriteria.attributeName);
    }
  }

  flowCriteriaAttributeSelectChanged(attributeValue: string) {
    this.resetValuesAfterAttributeChange();
    this.mcProcessFlowCriteriaAttribute = this.mcProcessFlowCriteriaAttributeList.getAttributeFromListByName(attributeValue);
    const attributeTypeCd = this.mcProcessFlowCriteriaAttribute?.attributeTypeCd;
    this.optionTypeVll = this.mcProcessFlowCriteriaAttribute?.getOptionTypeCdVll(attributeTypeCd);
    this.optionTypeRequiredFlg = this.optionTypeVll ? this.optionTypeVll.items.length >= 2 : false;
    // @ts-ignore
    this.operatorTypeCdVll = this.mcProcessFlowCriteriaAttribute ? this.mcProcessFlowCriteriaAttribute.getOperatorTypeCdVll(attributeTypeCd) : '';
    // @ts-ignore
    this.mcProcessFlowCriteria.attributeName = this.mcProcessFlowCriteriaAttribute.attributeName;
    // @ts-ignore
    this.mcProcessFlowCriteria.attributeTypeCd = this.mcProcessFlowCriteriaAttribute.attributeTypeCd;
  }

  flowCriteriaOperatorTypeChanged(attributeValue: string) {
    this.mcProcessFlowCriteria.attributeOperatorTypeCd = attributeValue;
  }

  flowCriteriaValueChanged() {
    this.mcProcessFlowCriteria.value = this.mcForm.getValue(`value${this.id}`);
  }

  flowCriteriaOptionTypeChanged(attributeValue: string) {
    this.mcProcessFlowCriteria.attributeOptionTypeCd = attributeValue;
  }

  resetValuesAfterAttributeChange() {
    if (this.mcForm.getControl(`value${this.id}`)) {
      this.mcForm.getControl(`value${this.id}`)?.get('value')?.setValue('');
      this.mcForm.getControl(`operatorType${this.id}`)?.get('value')?.setValue('');
      this.mcForm.getControl(`optionType${this.id}`)?.get('value')?.setValue('');
    }
  }

  flowCriteriaOperatorSelectChanged(type: string) {
    this.mcProcessFlowCriteria.criteriaOperatorTypeCd = type;
  }
}
