/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {CmnEmail} from '../models/cmn-email.model';
import {CmnEmailService} from '../services/cmn-email.service';
import {ICmnEmail} from '../_generated/cmn-email.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {WebFileList} from '../models/web-file-list.model';


export abstract class CmnEmailGENERATED extends BaseObject {


    public static readonly STATUS_CD_NEW: string = 'NEW';
    public static readonly STATUS_CD_SCHEDULED: string = 'SCHEDULED';
    public static readonly STATUS_CD_ERROR: string = 'ERROR';
    public static readonly STATUS_CD_SENT: string = 'SENT';
  public static readonly STATUS_CD_LIST__ALL = [
    CmnEmailGENERATED.STATUS_CD_NEW,
    CmnEmailGENERATED.STATUS_CD_SCHEDULED,
    CmnEmailGENERATED.STATUS_CD_ERROR,
    CmnEmailGENERATED.STATUS_CD_SENT
];

  public apiService: CmnEmailService;
  public _rawJson: ICmnEmail;
    id: number = 0;
  accountId: number;
  bccEmails = '';
  body = '';
  ccEmails = '';
  log = '';
  retryCount: number;
  sentDatetime: number;
  statusCd: string;
  subject = '';
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  toEmails = '';
  attachmentWebFiles: WebFileList;

  public properties: string[] = ['id', 'accountId', 'bccEmails', 'body', 'ccEmails', 'log', 'retryCount', 'sentDatetime', 'statusCd', 'subject', 'sysCreatedDatetime', 'sysCreatedUserId', 'toEmails', 'attachmentWebFiles'];
  public propertiesRegular: string[] = ['id', 'accountId', 'bccEmails', 'body', 'ccEmails', 'log', 'retryCount', 'sentDatetime', 'statusCd', 'subject', 'sysCreatedDatetime', 'sysCreatedUserId', 'toEmails'];
  public propertiesSpecial: string[] = ['attachmentWebFiles'];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CmnEmailGENERATED.STATUS_CD_NEW, McGod.t('New'));
    list.add(CmnEmailGENERATED.STATUS_CD_SCHEDULED, McGod.t('Scheduled'));
    list.add(CmnEmailGENERATED.STATUS_CD_ERROR, McGod.t('Error'));
    list.add(CmnEmailGENERATED.STATUS_CD_SENT, McGod.t('Sent'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = CmnEmailGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): CmnEmail {
    console.error('MTCN-ERROR: Not Implemented: CmnEmail::createNewInstance(). Add this method to final class and return new CmnEmail();');
    throw new Error('Cannot CmnEmail::createNewInstance(). Add this method to final class and return new CmnEmail();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: ICmnEmail): CmnEmail {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CmnEmailService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CmnEmail AABB()');
          if (this._rawJson['attachmentWebFiles']) {
        this.attachmentWebFiles = WebFileList.createFromJson(this._rawJson['attachmentWebFiles']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getSentDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sentDatetime);
    }

    public getSentDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sentDatetime);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('attachmentWebFiles')) {
        if (this.attachmentWebFiles != null) {
            dto['attachmentWebFiles'] = this.attachmentWebFiles.toDto();
        } else {
            dto['attachmentWebFiles'] = [];
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return CmnEmailGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdNEW(): boolean {
    const result = (this.statusCd === CmnEmailGENERATED.STATUS_CD_NEW);

    return result;
  }

 public  isStatusCdSCHEDULED(): boolean {
    const result = (this.statusCd === CmnEmailGENERATED.STATUS_CD_SCHEDULED);

    return result;
  }

 public  isStatusCdERROR(): boolean {
    const result = (this.statusCd === CmnEmailGENERATED.STATUS_CD_ERROR);

    return result;
  }

 public  isStatusCdSENT(): boolean {
    const result = (this.statusCd === CmnEmailGENERATED.STATUS_CD_SENT);

    return result;
  }


  
}
