import {McBillingPayoutFileFeCtdFilterGENERATED} from '../_generated/mc-billing-payout-file-fe-ctd-filter-generated.model';

export class McBillingPayoutFileFeCtdFilter extends McBillingPayoutFileFeCtdFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingPayoutFileFeCtdFilter();
  }

  // ---------------------------------------------------------------------
}
