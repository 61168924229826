import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IImDataExportTemplate} from '../_generated/im-data-export-template.interface';
import {ImDataExportTemplate} from '../models/im-data-export-template.model';
import {ImDataExportTemplateList} from '../models/im-data-export-template-list.model';
import {ImDataExportTemplateServiceGENERATED} from '../_generated/im-data-export-template-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ImDataExportTemplateService extends ImDataExportTemplateServiceGENERATED {

}
