/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EbSepaFileTransactionTable3ConfigGENERATED} from '../_generated/eb-sepa-file-transaction-table-3-config-generated.model';

// import {EbSepaFileTransactionGENERATED} from '../_generated/eb-sepa-file-transaction-generated.model';

export class EbSepaFileTransactionTable3Config extends EbSepaFileTransactionTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.addColumn('colActions', '', 'fldActions()', '').extraCssClass = 'table-col-width-50';
    this.addIdColumn();
    this.addColumn('colEntity', this.t('cc.consumer.studio'), 'entityName', 'entityName');
    this.addColumn('colConsumer', this.t('cc.my-factoring.consumer'), 'consumerName', 'consumer.firstName');
    // this.addColumn('colServicePeriod', this.t('cc.invoice.service-period'), 'getServicePeriodStr()', 'sepa.servicePeriod');
    this.addColumn('colDescription', this.t('cc.common.view.description'), 'rmtinfUstrd', 'rmtinfUstrd').extraCssClass = 'mc-factoring-transactions-table-description-column';
    this.addColumn('createdAt', this.t('cc.consumers.imports.created-at'), 'getImportDateStr()', 'createdAt');
    this.addColumn('colPaymentDueDate', 'Payment due date', 'getDueDateTime()', 'dueDatetime');
    // this.addColumn('colRequestCollectionDate', 'Request collection date', 'getRequestCollectionDate()', 'requestedCollectionDate');
    this.addColumn('colAmount', `${this.t('cc.common.view.amount-due')}`, 'amountStr', '').extraCssClass = 'text-right';
    this.addColumn('colStatus', this.t('cc.common.view.status'), `getIconLabel()`, 'statusCd');
  }
}
