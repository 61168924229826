import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ICsrReccuringOptions} from '../_generated/csr-reccuring-options.interface';
import {CsrReccuringOptions} from '../models/csr-reccuring-options.model';
import {CsrReccuringOptionsList} from '../models/csr-reccuring-options-list.model';
import {CsrReccuringOptionsServiceGENERATED} from '../_generated/csr-reccuring-options-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CsrReccuringOptionsService extends CsrReccuringOptionsServiceGENERATED {

}
