import {McGod} from '../extra/mc-god.service';

export class McApiErrorResponse {
  public rawHttpErrorResponse: any;
  public message = '';
  public code = '';
  public status = '';

  public static createNew(aErrorResponse: any) {
    return new McApiErrorResponse(aErrorResponse);
  }
  // ---------------------------------------------------------------------
  constructor(aErrorResponse: any) {
    this.populatePropertiesFromHttpErrorResponse(aErrorResponse);
  }

  // ---------------------------------------------------------------------
  private populatePropertiesFromHttpErrorResponse(aErrorResponse: { status: string; error: { status: string; message: string; }; message: string; }) {

    try {
      this.rawHttpErrorResponse = aErrorResponse;
      this.code = aErrorResponse.status;
      this.status = aErrorResponse.error.status;

      if (aErrorResponse.error.message) {
        this.message = aErrorResponse.error.message;
      } else if ((!McGod.isProduction())  &&  (aErrorResponse.message)) {
        this.message = aErrorResponse.message;
      } else {
        this.message = 'Unknown Error Occurred!';
      }

    } catch (e) {
      this.message = 'Unknown Error Occurred!';
      // console.warn("McResponseError Exception:" , e);
    }
  }
}
