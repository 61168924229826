import {McEntityConsumerAttribute} from './mc-entity-consumer-attribute.model';
import {McEntityConsumerAttributeListGENERATED} from '../_generated/mc-entity-consumer-attribute-list-generated.model';

export class McEntityConsumerAttributeList extends McEntityConsumerAttributeListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McEntityConsumerAttributeList();
  }

  // ---------------------------------------------------------------------
}
