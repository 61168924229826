<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
<lib-mc-loader [showLoader]="mcEntity.apiLoadingFlg || mcVatList.apiLoadingFlg || mcStatementItem.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h2>{{mcStatementItem.id > 0 ? 'Edit Correction' : 'Add Correction'}}</h2>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-body">

    <mcc-message [errorMsg]="errorMsg"></mcc-message>

    <mcc-fi-textbox label="Correction name" name="ctrlCorrectionName" [value]="mcStatementItem.title" [mcForm]="mcForm" [validRequiredFlg]="true"></mcc-fi-textbox>

    <mcc-fi-textarea [name]="'ctrlDescription'" label="{{'cc.common.view.description' | translate}}" [numCols]="45"
                     [validRequiredFlg]="true" [value]="mcStatementItem.description" [mcForm]="mcForm"></mcc-fi-textarea>

    <mcc-fi-number-type nameForValue="ctrlAmount" nameForType="ctrlAmountType"
                        label="{{'cc.factoring.transactions.amount' | translate}}" [validMinNumber]="0"
                        [maxDecimalPlaces]="2"
                        [chosenCurrency]="currencySymbol"
                        [value]="mcStatementItem.amount"
                        [valueType]="currencySymbol" disablePercentage="true" [validRequiredFlg]="true"
                        [labelOnTopFlg]="true" [mcForm]="mcForm">
    </mcc-fi-number-type>



    <mcc-fi-radio-buttons2 name="ctrlVatRate"
                           label="{{'cc.countries.vat-rate' | translate}}"
                           [optionsVll]="vatVll"
                           [validRequiredFlg]="true"
                           [directionHorizontal]="false"
                           [value]="mcStatementItem.vatPercentageForCorrection"
                           [mcForm]="mcForm"></mcc-fi-radio-buttons2>


    <div class="col-md-5 pl-0">
    <div class="position-relative">
    <mcc-fi-radio-buttons2 name="ctrCorrectionType"
                           label="Correction type"
                           [optionsVll]="correctionTypeCdVll"
                           [directionHorizontal]="false"
                           [validRequiredFlg]="true"
                           [value]="mcStatementItem.correctionTypeCd"
                           [mcForm]="mcForm"></mcc-fi-radio-buttons2>

    <div class="info-icon-positioning4">
        <i class="mc-blue-icon fas fa-info-circle"
           ngbTooltip="This type of correction will be deducted from the total fees amount."
           placement="top-right" tooltipClass="mc-tooltip-length-350"></i>
      </div>
      <div class="info-icon-positioning5">
        <i class="mc-blue-icon fas fa-info-circle"
           ngbTooltip="This type of correction will be added to the total fees amount."
           placement="top-right" tooltipClass="mc-tooltip-length-350"></i>
      </div>
  </div>
    </div>



  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel()"
                label="{{ 'cc.common.edit.cancel' | translate }}"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actSave()" label="<i class='fas fa-plus'></i> {{saveButtonLabel | translate}}"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
