import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {McForm, McGod} from '@miticon-ui/mc-core';
import {MccFiTextareaEventAfterValidation} from './mcc-fi-textarea-event-after-validation';
import {FiTextareaConfig} from './fi-textarea-config';

@Component({
  selector: 'mcc-fi-textarea',
  templateUrl: './mcc-fi-textarea.component.html',
  styleUrls: ['./mcc-fi-textarea.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MccFiTextareaComponent implements OnInit {
  requiredFlg!: Validators;
  pattern!: Validators;
  minLength!: Validators;
  maxLength!: Validators;
  numberRegex!: Validators;
  mcGod = McGod.getInstance();
  validMin!: Validators;
  validMax!: Validators;
  numberRegexString = /^\d*\.?\,?\d*$/;
  validatorList: any = [];
  cssClass!: string;
  formValidationTriggerSubscription!: Subscription;

  // BMITIC: Talk to me before enabling any additional inputs
  /*Events*/
  @Input() numRows = 5;
  @Input() numCols = 15;
  @Input() extraCssClasses: any;
  @Input() label!: string;
  @Input() value: any;
  @Input() name: any;
  @Input() mcForm!: McForm;
  // @Input() frmGroup: FormGroup;
  @Input() placeholder = '';
  @Input() readOnlyFlg = false;
  @Input() validRequiredFlg = false;
  @Input() validPattern!: string;
  @Input() validMinLength!: number;
  @Input() validMaxLength!: number;
  @Input() validNumber!: boolean;
  @Input() validMinNumber!: number;
  @Input() validMaxNumber!: number;
  @Input() validWhitespaces!: boolean;
  @Input() extraCssClass!: string;
  @Input() formExtraCssClass: any;

  whiteSpaceRegex!: Validators;
  whitespaceRegexString = /^(?!\s*$).+/;
  @Input() config!: FiTextareaConfig;
  @Output() eventValueChanged = new EventEmitter();
  @Output() eventAfterValidation = new EventEmitter();

  constructor() {
  }

  getTextAreaForm(): FormGroup {
    return <FormGroup><unknown>!this.config && this.mcForm.getControl(this.name) as FormGroup;
  }

  createTextareaFormControl() {
    const textAreaForm = this.mcForm.formBuilder.group({
      value: [this.value ? this.value : '', this.validatorList],
      label: [this.label],
      errorFlg: [false],
      errorMsg: ['']
    });

    if (this.mcForm) {
      this.mcForm.addControl(this.name, textAreaForm);
    }

  }

  ngOnInit() {
    if (this.mcForm && !this.config) {
      this.formValidationTriggerSubscription = this.mcForm.getValidationTriggerObservable().subscribe(() => {
        this.validateTextArea();
      });
    }

    if (!this.config) {
      this.validationSetup();
      this.createTextareaFormControl();
    } else {
      this.applyConfigToComponent();
    }

  }

  ngOnDestroy(): void {
    if (this.formValidationTriggerSubscription) {
      this.formValidationTriggerSubscription.unsubscribe();
    }
  }

  public applyConfigToComponent() {
    // @ts-ignore
    Object.keys(this.config).forEach(key => this[key] = this.config[key]);
  }


  validationSetup() {
    if (this.validRequiredFlg) {
      this.requiredFlg = Validators.required;
      this.validatorList.push(this.requiredFlg);
    }

    if (this.validPattern) {
      this.pattern = Validators.pattern(this.validPattern);
      this.validatorList.push(this.pattern);
    }

    if (this.validMinLength) {
      this.minLength = Validators.minLength(this.validMinLength);
      this.validatorList.push(this.minLength);
    }

    if (this.validMaxLength) {
      this.maxLength = Validators.maxLength(this.validMaxLength);
      this.validatorList.push(this.maxLength);
    }

    if (this.validWhitespaces) {
      this.whiteSpaceRegex = Validators.pattern(this.whitespaceRegexString);
      this.validatorList.push(this.whiteSpaceRegex);
    }


    if (this.validNumber) {
      this.numberRegex = Validators.pattern(this.numberRegexString);
      this.validatorList.push(this.numberRegex);
    }

  }

  public validateTextArea() {
    this.validationSetup();
    this.getTextAreaForm().controls['value'].setValidators(this.validatorList);
    this.getTextAreaForm().get('value')?.updateValueAndValidity();
    if (this.getTextAreaForm().get('value')?.errors) {
      // @ts-ignore
      const validators = Object.keys(this.getTextAreaForm().get('value').errors);
      if (validators) {
        this.getTextAreaForm().get('errorFlg')?.setValue(true);
        validators.forEach(item => {
          switch (item) {
            case 'required':
              this.getTextAreaForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.this-field-is-required')}`);
              break;
            case 'minlength':
              this.getTextAreaForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.length-must-be-at-least')} ${this.validMinLength}`);
              break;
            case 'maxlength':
              this.getTextAreaForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.length-must-be-under')} ${this.validMaxLength}`);
              break;
            case 'pattern':
              if (this.numberRegex && !this.numberRegexString.test(this.getTextAreaForm().get('value')?.value)) {
                this.getTextAreaForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.this-field-is-numeric-and-accepts-whole-numbers-integers-and-decimal-values-with-dot')}`);
                break;
              } else if (this.whiteSpaceRegex && !this.whitespaceRegexString.test(this.getTextAreaForm().get('value')?.value)) {
                  this.getTextAreaForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.no-whitespaces-allowed')}`);
                  break;
              } else {
                this.getTextAreaForm().get('errorMsg')?.setValue(this.mcGod.t('cc.common.invalid-value'));
                break;
              }
          }
        });
      }
    } else {
      this.getTextAreaForm().get('errorFlg')?.setValue(false);
      this.getTextAreaForm().get('errorMsg')?.setValue(``);
    }
    this.fireEventAfterValidation();
  }

  public fireEventAfterValidation() {
    if (this.eventAfterValidation) {
      const eventObj = new MccFiTextareaEventAfterValidation();
      eventObj.validFlg = !this.getTextAreaForm().get('value')?.errors;

      this.eventAfterValidation.emit(eventObj);
    }
  }

  clearErrors() {
    this.getTextAreaForm().get('errorFlg')?.setValue(false);
    this.getTextAreaForm().get('errorMsg')?.setValue('');
  }

  onTextAreaChanged(value: any) {
    this.clearErrors();
    if (this.eventValueChanged) {
      this.eventValueChanged.emit(value);
    }
  }

}
