<ng-container *ngIf="isVisible()">

  <ng-container *ngIf="customAttribute.isTypeCdVARCHAR()">
    <mcc-fi-textbox name="{{customAttribute.getMcFormControlName()}}" label="{{customAttribute.label}}"
                    [value]="customAttribute.value" [validRequiredFlg]="false" [newStyleInput]="true"
                    [mcForm]="mcForm">
    </mcc-fi-textbox>
  </ng-container>

  <ng-container *ngIf="customAttribute.isTypeCdNUMBER()">
    <mcc-fi-textbox name="{{customAttribute.getMcFormControlName()}}" label="{{customAttribute.label}}"
                    [value]="customAttribute.value" [validRequiredFlg]="false" [validIntegerAndDecimalNumber]="true"
                    [newStyleInput]="true" [mcForm]="mcForm">
    </mcc-fi-textbox>
  </ng-container>

  <ng-container *ngIf="customAttribute.isTypeCdBOOLEAN()">
    <mcc-fi-checkbox name="{{customAttribute.getMcFormControlName()}}" label="{{customAttribute.label}}"
                    [value]="checkboxBooleanValue" [isCheckboxChecked]="checkboxBooleanValue" [validRequiredFlg]="false"
                    [mcForm]="mcForm">
    </mcc-fi-checkbox>
  </ng-container>

  <ng-container *ngIf="customAttribute.isTypeCdDATE()">
    <mcc-fi-date-picker name="{{customAttribute.getMcFormControlName()}}" label="{{customAttribute.label}}"
                        [validRequiredFlg]="false" [valueInput]="customAttribute.value" [newStyleDate]="true"
                    [mcForm]="mcForm">
    </mcc-fi-date-picker>
  </ng-container>

</ng-container>
