<!--THIS CONTAINER IS MANDATORY-->
<div *ngIf="refreshFlg" class="content-wrapper">
  <lib-mc-loader [showLoader]="mcBillingService2PriceList.apiLoadingFlg || templatePricelist.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h1>{{'cc.pricelist.edit-service' | translate}}</h1>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-body">
    <mcc-message [errorMsg]="errorMsg"></mcc-message>
    <div class="info-container2 p-3 mb-3">
      <div class="row">
        <div class="col-md-6">
          <mcc-row-title-description [description]="mcBillingService2PriceList.serviceName"
                                     title="{{'cc.pricelist.service-name' | translate}}:"></mcc-row-title-description>
          <mcc-row-title-description [description]="mcBillingService2PriceList.serviceDescription"
                                     title="{{'cc.pricelist.service-description' | translate}}:"></mcc-row-title-description>
        </div>
        <div class="col-md-6">
          <mcc-row-title-description [description]="unitType"
                                     title="{{'cc.pricelist.activity-unit' | translate}}:"></mcc-row-title-description>
        </div>
      </div>
    </div>

    <div class="row pr-3 justify-content-end">
      <button *ngIf="!mcBillingService2PriceList.flgItemFromTemplate" class="system-action-btn mr-2" (click)="restoreDefaults()"><i class="fas fa-tag"></i> {{'cc.pricelist.restore-defaults' | translate}}</button>
      <button class="system-action-btn" (click)="undoChanges()"><i class="fas fa-undo"></i> {{'cc.pricelist.undo-changes' | translate}}</button>
    </div>

    <div class="row" *ngIf="refreshFlg">
      <div class="col-md-12">
        <div class="content-section">

          <div class="pricing-main-radios">
            <mcc-fi-radio-buttons2 (eventValueChanged)="onChargeOptionChangeEmit($event)"
                                   [directionHorizontal]="true"
                                   [mcForm]="mcForm"
                                   [name]="'ctrlCharge'"
                                   [optionsVll]="chargeOptionsVll"
                                   [disabledOptionsVll]="areServiceButtonsDisabled ? disabledOptionsVll : null" [validRequiredFlg]="true"
                                   [value]="mcBillingService2PriceList.pricingTypeCd ? mcBillingService2PriceList.pricingTypeCd : ''"></mcc-fi-radio-buttons2>
          </div>

          <div class="d-flex">
            <div class="col-4">
              <!-- Do not charge section -->
              <div *ngIf="additionalDoNotChargeFlg"
                   [ngClass]="{'mc-disable' : (!mcBillingService2PriceList.pricingTypeCd || mcBillingService2PriceList.isPricingTypeCdDO_NOT_CHARGE_AT_ALL())}">
                <mcc-fi-checkbox (eventValueChanged)="onAdditionalDoNotChargeValueChangeEmit($event)"
                                 [isCheckboxChecked]="additionalDoNotChargeValue"
                                 [label]="additionalDoNotChargeLabel"
                                 [mcForm]="mcForm" [name]="'ctrlAdditionalDoNotCharge'"
                                 [value]="additionalDoNotChargeValue">
                </mcc-fi-checkbox>
              </div>
            </div>

            <!-- One time only section -->
            <div class="col-4">
              <div [ngClass]="{'mc-disable' : !mcBillingService2PriceList.isPricingTypeCdONE_TIME_ONLY()}">
                <mcc-fi-number-type (eventAmountValueChanged)="onOneTimeOnlyChargeValueChangeEmit($event)"
                                    [disablePercentage]="true"
                                    [labelOnTopFlg]="true"
                                    [mcForm]="mcForm"
                                    [nameForType]="'ctrlTypeOneTimeOnly'"
                                    [nameForValue]="'ctrlValueOneTimeOnly'"
                                    [validMinNumber]="0"
                                    [chosenCurrency]="currency"
                                    [validNumberWithTwoDecimals]="true"
                                    [validRequiredFlg]="mcBillingService2PriceList.isPricingTypeCdONE_TIME_ONLY()"
                                    [value]="oneTimePrice" label=""
                                    [valueType]="currency">
                </mcc-fi-number-type>

              </div>
            </div>
            <!-- Mix section -->
            <div class="col-4">
              <div [ngClass]="{'mc-disable' : !mcBillingService2PriceList.isPricingTypeCdMIX()}">
                <mcc-fi-number-type (eventAmountValueChanged)="onMixFlatPriceValueChangeEmit($event)"
                                    [disablePercentage]="true"
                                    [labelOnTopFlg]="false"
                                    [mcForm]="mcForm"
                                    [nameForType]="'ctrlTypeMix'"
                                    [nameForValue]="'ctrlValueMix'"
                                    [validMinNumber]="0"
                                    [chosenCurrency]="currency"
                                    [validNumberWithTwoDecimals]="true"
                                    [validRequiredFlg]="mcBillingService2PriceList.isPricingTypeCdMIX()"
                                    [value]="mcBillingService2PriceList.priceFlat"
                                    label="{{'cc.pricelist.flat-price-of' | translate}}"
                                    [valueType]="currency">
                </mcc-fi-number-type>

                <mcc-fi-textbox (eventValueChanged)="onLimitForUnitsValueChangeEmit($event)"
                                additionalDescription="{{'cc.pricelist.unit-s' | translate}}" [mcForm]="mcForm"
                                [name]="'ctrlForUnits'"
                                [validIntegerNumber]="true"
                                [validRequiredFlg]="mcBillingService2PriceList.isPricingTypeCdMIX()"
                                [value]="mcBillingService2PriceList.unitLimitForFlatPrice"
                                label="{{'cc.pricelist.for' | translate}}"
                                size="10"></mcc-fi-textbox>

                <mcc-fi-number-type (eventAmountValueChanged)="onMixPlusPerAdditionUnitChangeValuEmit($event)"
                                    additionalDescription="{{'cc.pricelist.per-additional-unit' | translate}}"
                                    [disablePercentage]="true"
                                    [labelOnTopFlg]="false"
                                    [mcForm]="mcForm"
                                    [nameForType]="'ctrlTypePlusPerAdditionalUnit'"
                                    [nameForValue]="'ctrlValuePlusPerAdditionalUnit'"
                                    [validMinNumber]="0"
                                    [chosenCurrency]="currency"
                                    [validNumberWithTwoDecimals]="true"
                                    [validRequiredFlg]="mcBillingService2PriceList.isPricingTypeCdMIX()"
                                    [value]="mcBillingService2PriceList.priceAdditionalUnit" label="{{'cc.common.plus' | translate}}"
                                    [valueType]="mcBillingService2PriceList.isFranchiseFeePerTransactionOrAdvertisingFee() ? 'percentage' : currency"
                                    [validMaxNumber]="mcBillingService2PriceList.isFranchiseFeePerTransactionOrAdvertisingFee() ? 100 : undefined"
                                    [disableMoneyValue]="mcBillingService2PriceList.isFranchiseFeePerTransactionOrAdvertisingFee()">
                </mcc-fi-number-type>



              </div>
            </div>
          </div>

        </div>
      </div>
    </div><!-- row -->


  </div><!-- content-body -->

  <div class="content-footer">

    <mcc-button (eventClicked)="actCancel();" class="move-right" extraCssClasses="btn btn-secondary"
                label="{{'cc.common.edit.cancel' | translate}}"></mcc-button>
    <mcc-button (eventClicked)="actSave()"
                [disabledMcb]="saveButtonDisabledMcb" disableOnFirstClickFlg="true" extraCssClasses="btn btn-primary"
                label="{{'cc.common.view.save' | translate}}"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
