<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcStatement.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h2>Rename Statement title</h2>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-body">

<!--    <mcc-message [errorMsg]="errorMsg"></mcc-message>-->

    <div class="info-container2 p-3">
      <div class="row">
        <div class="col-md-12">
          <mcc-row-title-description title="Current statement title:"
                                     description="{{mcStatement.title}}"></mcc-row-title-description>
        </div>
      </div>
    </div>

    <div class="row pt-3">
      <div class="col-md-12">
        <mcc-fi-textbox  [name]="'statementTitle'" label="New Statement title" [validWhitespaces]="true"
                         (eventValueChanged)="onTextBoxValueChangeEmit($event)"
                         [mcForm]="mcForm"></mcc-fi-textbox>
      </div>
    </div>


  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel()"
                label="Cancel"></mcc-button>
    <button class="btn btn-primary" [ngClass]="{'mc-disable' : disabledRenameFlg}"
                (click)="actSave()"><i class="fas fa-pencil-alt"></i> Rename</button>
  </div>

</div>
<router-outlet></router-outlet>
