import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import {
  AdminComponent,
  ConsumerConfirmationContractComponent,
  ConsumerMollieSuccessfulMessageComponent,
} from '@miticon-ui/mc-core-ui';

const routes: Routes = [
  {
    path: 'entity/:id/data',
  component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/im-data-import-wrapper.module').then(
        (m) => m.ImDataImportWrapperModule
      ),
  },
  {
    path: 'consumer/confirmation/contract/:id',
    component: ConsumerConfirmationContractComponent,
  },
  {
    path: 'consumer/mollie/successful-message',
    component: ConsumerMollieSuccessfulMessageComponent,
  },
  {
    path: 'entity/:id/transactions',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/pm-transactions-wrapper.module').then(
        (m) => m.PmTransactionsWrapperModule
      ),
  },
  {
    path: 'entity/:id/ebics',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/mc-ebics-wrapper.module').then(
        (m) => m.McEbicsWrapperModule
      ),
  },
  {
    path: 'entity/:id/my-billing',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/mc-my-billing-wrapper.module').then(
        (m) => m.McMyBillingWrapperModule
      ),
  },
  {
    path: 'entity/:id/pricelists',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/mc-pricelist-wrapper.module').then(
        (m) => m.McPricelistWrapperModule
      ),
  },
  {
    path: 'entity/:id/countries',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/mc-country-wrapper.module').then(
        (m) => m.McCountryWrapperModule
      ),
  },
  {
    path: 'entity/:id/out-billing',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/mc-out-billing-wrapper.module').then(
        (m) => m.McOutBillingWrapperModule
      ),
  },
  {
    path: 'entity/:id/processes',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/mc-process-wrapper.module').then(
        (m) => m.McProcessWrapperModule
      ),
  },
  {
    path: 'entity/:id/users',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/mc-user-wrapper.module').then(
        (m) => m.McUserWrapperModule
      ),
  },
  {
    path: 'mc-dev',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/mc-dev-wrapper.module').then(
        (m) => m.McDevWrapperModule
      ),
  },
  {
    path: 'entity/:id/s',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/app-shared-wrapper.module').then(
        (m) => m.AppSharedWrapperModule
      ),
  },
  {
    path: 'entity/:id/bank-module',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/mc-bank-module-wrapper.module').then(
        (m) => m.McBankModuleWrapperModule
      ),
  },
  {
    path: 'entity/:id/my-factoring',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/mc-my-factoring-wrapper.module').then(
        (m) => m.McMyFactoringWrapperModule
      ),
  },
  {
    path: 'entity/:id/out-factoring',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/mc-out-factoring-wrapper.module').then(
        (m) => m.McOutFactoringWrapperModule
      ),
  },
  {
    path: 'entity/:id/products',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/mc-product-wrapper.module').then(
        (m) => m.McProductWrapperModule
      ),
  },
  {
    path: 'entity/:id/consumers',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/mc-consumer-wrapper.module').then(
        (m) => m.McConsumerWrapperModule
      ),
  },
  {
    path: 'entity/:id/entity-profile',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/mc-entity-profile-wrapper.module').then(
        (m) => m.McEntityProfileWrapperModule
      ),
  },
  {
    path: 'zz/poc',
    component: AdminComponent,
    loadChildren: () =>
      import('./wrapper-modules/zz-poc-wrapper.module').then(
        (m) => m.ZzPocWrapperModule
      ),
  },
  /*Login module*/
  {
    path: 'auth',
    loadChildren: () =>
      import('./wrapper-modules/mc-login-wrapper.module').then(
        (m) => m.McLoginWrapperModule
      ),
  },

  /*Admin module*/
  {
    path: 'admin/entity/:id',
    component: AdminComponent,
    children: [
      {
        path: 'management',
        loadChildren: () =>
          import('./wrapper-modules/mc-management-wrapper.module').then(
            (m) => m.McManagementWrapperModule
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./wrapper-modules/mc-dashboard-wrapper.module').then(
            (m) => m.McDashboardWrapperModule
          ),
      },
      {
        path: 'profile-user',
        loadChildren: () => import('./wrapper-modules/mc-profile-user-wrapper.module').then((m) => m.McProfileUserWrapperModule)
      },
      {
        path: 'scheduler',
        loadChildren: () =>
          import('./wrapper-modules/mc-scheduler-wrapper.module').then(
            (m) => m.McSchedulerWrapperModule
          ),
      },
      {
        path: 'banking-module',
        loadChildren: () =>
          import('./wrapper-modules/mc-banking-module-wrapper.module').then(
            (m) => m.McBankingModuleWrapperModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/auth/login',
  },
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'ignore',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
