<div class="row mc-add-product-to-invoice-wrapper">
  <div class="input-group mb-3 mc-add-product-input-wrapper">

    <!--Left button-->
    <div class="input-group-prepend">
      <button class="btn"
              [ngClass]="!product.numberOfProducts ? 'mc-click-not-allow' : 'mc-decrement-item'"
              type="button"
              (click)="onChangeNumberOfElements(true)"
              [disabled]="product.numberOfProducts === 0 || !product.numberOfProducts">-</button>
    </div>

    <!--Input field-->
    <input type="number"
           (keydown)="validateNumber($event)"
           (keyup)="onChangeValue()"
           [(ngModel)]="product.numberOfProducts"
           class="form-control mc-add-product-quantity-input-field"
           placeholder="{{'cc.common.row' | translate}}"
           step="1"
           pattern="[0-9]"
           min="0">

    <!--Right button-->
    <div class="input-group-append">
      <button class="btn"
              type="button"
              [ngClass]="product.numberOfProducts ? 'mc-increment-item' : 'mc-button-no-selected-products'"
              (click)="onChangeNumberOfElements(false)">+</button>
    </div>
  </div>
</div>
