import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ProductSearchDto} from '../models/product.model';

@Injectable({
  providedIn: 'root'
})
export class McProductOldService {

  private readonly apiUrl: string;
  private readonly httpOptions;

  constructor(
    private http: HttpClient,
    @Inject('environment') environment: any
  ) {
    /*Set API base url*/
    this.apiUrl = environment.apiUrl;
    this.httpOptions = environment.httpOptions(HttpHeaders);
  }

  /* Add Consumer */
  public getSearchedProducts(body: ProductSearchDto | any) {
    return this.http.get<ProductSearchDto>(`${this.apiUrl}/ppproduct/v3/ppproduct/all`, this.httpOptions);
  }

}
