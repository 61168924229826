<ng-template #importSepaFile let-modal>

  <!--Modal header section-->
  <div class="modal-header mc-create-user-modal-header">
    <div>
      <h4 class="modal-title" id="mc-modal-new-user-title">Import SEPA file</h4>
    </div>
    <button type="button" class="close mc-consumers-close-btn" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div><!--Modal header -->

  <!--Modal body section-->
  <div class="modal-body">
    <div class="mc-create-new-user-wrapper">
      <div class="container-fluid">

        <div class="row" [formGroup]="importSepaFileFormGroup">



          <div class="col-md-12 position-relative mt-1">
            <div class="mc-create-user-notify-wrapper">
              <div class="w-100 mc-notify-message-wrapper mc-background-loading mc-text-align-center py-1" *ngIf="webFile.apiLoadingFlg">{{'cc.my-factoring.loading-file-please-wait' | translate}}</div>
              <div *ngIf="message" class="mc-message-info">
                <mc-notify-message [message]="message" [type]="type"></mc-notify-message>
              </div>
            </div>
          </div>

          <div *ngIf="activeFactoring.exists()" class="col-md-12 mt-5">
            <div class="input-group mb-3">

              <span>Please choose SEPA XML file with standard UTF8 encoding</span>

            </div>
          </div>

          <div *ngIf="activeFactoring.exists()" class="col-md-12 mt-5">
            <div class="input-group mb-3">

              <lib-mcc-fi-file-upload (eventFileSelected)="receiveFile($event)"></lib-mcc-fi-file-upload>

            </div>
          </div>

          <div *ngIf="activeFactoring.exists()" class="col-md-12 mt-4 pt-1 d-flex align-items-center">
            <label class="mb-0 mc-field-width-30">Please pick service period: </label>
            <input
              class="form-control"
              type="radio"
              id="nextMonth"
              formControlName="monthOfPerformance"
              value="nextMonth">
            <label for="nextMonth" class="mb-0 mx-2">Next month<br><span class="font-italic">({{nextMonthLabel}})</span></label>
            <input
              class="form-control"
              type="radio"
              id="currentMonth"
              formControlName="monthOfPerformance"
              value="currentMonth">
            <label for="currentMonth" class="mb-0 mx-2">Current month<br><span class="font-italic">({{currentMonthLabel}})</span></label>
            <input
              class="form-control"
              type="radio"
              id="lastMonth"
              formControlName="monthOfPerformance"
              value="lastMonth">
            <label for="lastMonth" class="mb-0 mx-2">Last month<br><span class="font-italic">({{lastMonthLabel}})</span></label>
            <input
              class="form-control"
              type="radio"
              id="twoMonthsAgo"
              formControlName="monthOfPerformance"
              value="twoMonthsAgo">
            <label for="twoMonthsAgo" class="mb-0 mx-2">Two months ago<br><span class="font-italic">({{twoMonthsAgoLabel}})</span></label>
          </div>

          <div *ngIf="activeFactoring.exists()" class="col-md-12 mt-5">
            <label class="custom-control fill-checkbox mc-cursor-pointer mb-3">
              <input type="checkbox"
                     class="fill-control-input"
                     formControlName="overWriteCB">
              <span class="fill-control-indicator"></span>
              <span>Overwrite existing file for this time period</span>
            </label>
          </div>

          <div *ngIf="activeFactoring.exists()" class="col-md-8 d-flex">
            <img [src]="attributesInfoIcon" [alt]="attributesIcons.info.alt" class="mc-attribute-info mr-2">
            <small class="font-italic">
             Only one Sepa-XML file should be uploaded per month. If you upload another file for the same month, it will overwrite the existing SEPA transaction data.
            </small>
          </div>

          <!--******************* FOOTER ***************-->
          <div *ngIf="activeFactoring.exists()" class="col-md-12 mt-5">
            <div class="modal-footer">

              <button class="btn btn-sm mc-cancel-btn mr-5" (click)="actCancel()">
                Cancel
              </button>
              <button class="btn btn-primary mc-next-save-btn mr-5" (click)="actUpload()">
                Upload
              </button>

            </div>
          </div>
        </div>




      </div>
    </div>
  </div>

</ng-template>

<a class="mc-create-new-user-btn mc-create-entity-btn" (click)="openImportSepaFileModal(importSepaFile)">
  <i class="fas fa-plus-circle mc-add-new-user-icon"></i>
  Import SEPA file
</a>
<!--Loader-->
<!--<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>-->
