/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingPayoutFile2StatementTable3ConfigGENERATED} from '../_generated/mc-billing-payout-file-2-statement-table-3-config-generated.model';

// import {McBillingPayoutFile2StatementGENERATED} from '../_generated/mc-billing-payout-file-2-statement-generated.model';

export class McBillingPayoutFile2StatementTable3Config extends McBillingPayoutFile2StatementTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colNumberOfStatements', 'Number Of Statements', 'numberOfStatements', 'numberOfStatements');
    this.addColumn('colTotalFileAmount', 'Total File Amount', 'totalFileAmount', 'totalFileAmount');
    this.addColumn('colPayoutDate', 'Payout Date', 'payoutDate', 'payoutDate');
    this.addColumn('colStatusCd', 'Status Cd', 'statusCd', 'statusCd');
    this.addColumn('colFileName', 'File Name', 'fileName', 'fileName');
    this.addColumn('colTypeCd', 'Payout type', 'getTypeCdLabel()', '');
    this.addColumn('colPdfWebfileId', 'PDF Webfile ID', 'pdfWebfileId', 'pdfWebfileId');
    this.addColumn('colXlsWebfileId', 'XLS Webfile ID', 'xlsWebfileId', 'xlsWebfileId');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');


  }
}
