<h3 class="page-title">{{"cc.common.mapping-imports" | translate}}</h3>

<div class="actions-container">
  <span>{{"cc.common.mapping-imports-list" | translate}}</span>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  [itemsPerPage]="pageSize"
  (filterChanged)="onFilterChanged($event)"
  [searchTooltip]="searchTooltip"
  [showCheckbox]="false"
>
</mk-mat-table>

<div>
  <mcc-dialog-box *ngIf="showUnShareMappingMcb.value" [enableCloseButtonFlg]="false"
                  title='"{{"cc.import-mappings.unshare" | translate}} "{{csrMapping.mappingName}}"' name="unshare-mapping"
                  description='{{"cc.import-mappings.are-you-sure-you-want-to-unshare" | translate}} "{{csrMapping.mappingName}}" {{"cc.consumers.mapping" | translate}}?'
                  [buttonsKvm]="sharingUnsharingCopyButtonKvm"
                  popupType="question"
                  (eventActionButtonClicked)="onMcDialogBoxUnshareMappingActionButtonClicked($event)"></mcc-dialog-box>

  <mcc-dialog-box *ngIf="showShareMappingMcb.value" [enableCloseButtonFlg]="false"
                  title='"{{"cc.import-mappings.share" | translate}} "{{csrMapping.mappingName}}"' name="share-mapping"
                  description='{{"cc.import-mappings.are-you-sure-you-want-to-share" | translate}} "{{csrMapping.mappingName}}" {{"cc.consumers.mapping" | translate}}?'
                  [buttonsKvm]="sharingUnsharingCopyButtonKvm"
                  popupType="question"
                  (eventActionButtonClicked)="onMcDialogBoxShareMappingActionButtonClicked($event)"></mcc-dialog-box>

  <mcc-dialog-box *ngIf="showCopyMappingMcb.value" [enableCloseButtonFlg]="false"
                  title='"{{"cc.import-mappings.copy" | translate}} "{{csrMapping.mappingName}}"' name="copy-mapping"
                  description='{{"cc.import-mappings.are-you-sure-you-want-to-copy" | translate}} "{{csrMapping.mappingName}}" {{"cc.consumers.mapping" | translate}}?'
                  [buttonsKvm]="sharingUnsharingCopyButtonKvm"
                  popupType="question"
                  (eventActionButtonClicked)="onMcDialogBoxCopyMappingActionButtonClicked($event)"></mcc-dialog-box>

  <mcc-modal1-wrapper [showMcb]="showMappingProfileMcb" *ngIf="showMappingProfileMcb.value">
    <mc-consumer-consumer-mapping-profile-part [csrMappingId]="csrMappingId"
    (eventEditMapping)="onEditMapping()" (eventClose)="showMappingProfileMcb.setFalse()" (eventShare)="shareMapping()" (eventUnshare)="unShareMapping()" (eventCopy)="actCopyMapping($event)"></mc-consumer-consumer-mapping-profile-part>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper [showMcb]="showMappingEditMcb" *ngIf="showMappingEditMcb.value">
    <mc-consumer-consumer-mapping-edit-part [csrMappingId]="csrMappingId" [csrMapping]="csrMapping"
    (eventSaved)="onUpdatedMapping()" (eventClose)="onCloseMapping()"></mc-consumer-consumer-mapping-edit-part>
  </mcc-modal1-wrapper>
</div>

<router-outlet></router-outlet>
