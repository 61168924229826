import {McConsumerBalanceFilterGENERATED} from '../_generated/mc-consumer-balance-filter-generated.model';

export class McConsumerBalanceFilter extends McConsumerBalanceFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McConsumerBalanceFilter();
  }

  // ---------------------------------------------------------------------
}
