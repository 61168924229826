/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {ImDataSetAttribute} from '../models/im-data-set-attribute.model';
import {ImDataSetAttributeService} from '../services/im-data-set-attribute.service';
import {IImDataSetAttribute} from '../_generated/im-data-set-attribute.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class ImDataSetAttributeGENERATED extends BaseObject {


    public static readonly TYPE_CD_BIGINT: string = 'bigint';
    public static readonly TYPE_CD_VARCHAR: string = 'varchar';
    public static readonly TYPE_CD_TIMESTAMP: string = 'timestamp';
    public static readonly TYPE_CD_DECIMAL: string = 'decimal';
    public static readonly TYPE_CD_BOOLEAN: string = 'boolean';
  public static readonly TYPE_CD_LIST__ALL = [
    ImDataSetAttributeGENERATED.TYPE_CD_BIGINT,
    ImDataSetAttributeGENERATED.TYPE_CD_VARCHAR,
    ImDataSetAttributeGENERATED.TYPE_CD_TIMESTAMP,
    ImDataSetAttributeGENERATED.TYPE_CD_DECIMAL,
    ImDataSetAttributeGENERATED.TYPE_CD_BOOLEAN
];

  public apiService: ImDataSetAttributeService;
  public _rawJson: IImDataSetAttribute;
    id: number = 0;
  customFlg: boolean;
  description = '';
  enabledFlg: boolean;
  entityId: number;
  idDataSet: number;
  inheritableFlg: boolean;
  name = '';
  requiredFlg: boolean;
  sampleData = '';
  sysCreatedDatetime: number;
  typeCd: string;


  public properties: string[] = ['id', 'customFlg', 'description', 'enabledFlg', 'entityId', 'idDataSet', 'inheritableFlg', 'name', 'requiredFlg', 'sampleData', 'sysCreatedDatetime', 'typeCd'];
  public propertiesRegular: string[] = ['id', 'customFlg', 'dataSet', 'description', 'enabledFlg', 'entityId', 'idDataSet', 'inheritableFlg', 'name', 'requiredFlg', 'sampleData', 'sysCreatedDatetime', 'typeCd'];
  public propertiesSpecial: string[] = [];



  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(ImDataSetAttributeGENERATED.TYPE_CD_BIGINT, McGod.t('bigint'));
    list.add(ImDataSetAttributeGENERATED.TYPE_CD_VARCHAR, McGod.t('varchar'));
    list.add(ImDataSetAttributeGENERATED.TYPE_CD_TIMESTAMP, McGod.t('timestamp'));
    list.add(ImDataSetAttributeGENERATED.TYPE_CD_DECIMAL, McGod.t('decimal'));
    list.add(ImDataSetAttributeGENERATED.TYPE_CD_BOOLEAN, McGod.t('boolean'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = ImDataSetAttributeGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): ImDataSetAttribute {
    console.error('MTCN-ERROR: Not Implemented: ImDataSetAttribute::createNewInstance(). Add this method to final class and return new ImDataSetAttribute();');
    throw new Error('Cannot ImDataSetAttribute::createNewInstance(). Add this method to final class and return new ImDataSetAttribute();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IImDataSetAttribute): ImDataSetAttribute {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(ImDataSetAttributeService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE ImDataSetAttribute AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getTypeCdLabel(): string {
    return ImDataSetAttributeGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdBigint(): boolean {
    const result = (this.typeCd === ImDataSetAttributeGENERATED.TYPE_CD_BIGINT);

    return result;
  }

 public  isTypeCdVarchar(): boolean {
    const result = (this.typeCd === ImDataSetAttributeGENERATED.TYPE_CD_VARCHAR);

    return result;
  }

 public  isTypeCdTimestamp(): boolean {
    const result = (this.typeCd === ImDataSetAttributeGENERATED.TYPE_CD_TIMESTAMP);

    return result;
  }

 public  isTypeCdDecimal(): boolean {
    const result = (this.typeCd === ImDataSetAttributeGENERATED.TYPE_CD_DECIMAL);

    return result;
  }

 public  isTypeCdBoolean(): boolean {
    const result = (this.typeCd === ImDataSetAttributeGENERATED.TYPE_CD_BOOLEAN);

    return result;
  }


  
}
