/*BbmTestObj*/
import {McConsumerHistoryGENERATED} from '../_generated/mc-consumer-history-generated.model';
import {McDateUtils} from '../common/mc-date-utils';

export class McConsumerHistory extends McConsumerHistoryGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McConsumerHistory();
  }

  // ---------------------------------------------------------------------

  public fldFieldLabel() {
    return this.fieldName; // TODO translate field name into label
  }

  // ---------------------------------------------------------------------

  public fldOldValue(): string {
    let result: any = '';

    if (this.isFieldSrcCdCUSTOM()) {
      result = this.oldVarcharValue;
    } else {
      switch (this.fieldTypeCd) {
        case McConsumerHistory.FIELD_TYPE_CD_VARCHAR:
          result = this.oldVarcharValue;
          break;
        case McConsumerHistory.FIELD_TYPE_CD_BIGINT:
          result = this.oldBigintValue;
          break;
        case McConsumerHistory.FIELD_TYPE_CD_BOOLEAN:
          result = this.oldBooleanValue ? 'Yes' : 'No';
          break;
        case McConsumerHistory.FIELD_TYPE_CD_TEXT:
          result = this.oldTextValue;
          break;
        case McConsumerHistory.FIELD_TYPE_CD_TIMESTAMP:
          if (this.oldDatetimeValue) {
            result = McDateUtils.formatDateWithoutTimezone(this.oldDatetimeValue);
            break;
          } else {
            result = '';
            break;
          }
      }
    }
    return result;
  }

  public fldNewValue(): string {
    let result: any = '';

    if (this.isFieldSrcCdCUSTOM()) {
      result = this.newVarcharValue;
    } else {
      switch (this.fieldTypeCd) {
        case McConsumerHistory.FIELD_TYPE_CD_VARCHAR:
          result = this.newVarcharValue;
          break;
        case McConsumerHistory.FIELD_TYPE_CD_BIGINT:
          result = this.newBigintValue;
          break;
        case McConsumerHistory.FIELD_TYPE_CD_BOOLEAN:
          result = this.newBooleanValue ? 'Yes' : 'No';
          break;
        case McConsumerHistory.FIELD_TYPE_CD_TEXT:
          result = this.newTextValue;
          break;
        case McConsumerHistory.FIELD_TYPE_CD_TIMESTAMP:
          if (this.newDatetimeValue) {
            result = McDateUtils.formatDateWithoutTimezone(this.newDatetimeValue);
            break;
          } else {
            result = '';
            break;
          }
      }
    }
    return result;
  }

  fldDateTime(): string {
    if (this.sysCreatedDatetime) {
      return McDateUtils.newFormatDateTimeString(this.sysCreatedDatetime);
    }
    return '';
  }
}
