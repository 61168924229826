import {ImDataMapping} from './im-data-mapping.model';
import {ImDataMappingListGENERATED} from '../_generated/im-data-mapping-list-generated.model';

export class ImDataMappingList extends ImDataMappingListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataMappingList();
  }

  // ---------------------------------------------------------------------
}
