/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CsrAccountConsumerTable3ConfigGENERATED} from '../_generated/csr-account-consumer-table-3-config-generated.model';

// import {CsrAccountConsumerGENERATED} from '../_generated/csr-account-consumer-generated.model';

export class CsrAccountConsumerTable3Config extends CsrAccountConsumerTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colAmount', 'Amount', 'amount', 'amount');
    this.addColumn('colCreatedDatetime', 'Created DateTime', 'createdDatetime', 'createdDatetime');
    this.addColumn('colCsrConsumer', 'Mc Consumer', 'csrConsumer', 'csrConsumer');
    this.addColumn('colIdCsrConsumer', 'Consumer ID', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colJsonbTransactionDetails', 'Transaction details', 'jsonbTransactionDetails', 'jsonbTransactionDetails');
    this.addColumn('colPaymentMethod', 'Payment Method', 'paymentMethod', 'paymentMethod');
    this.addColumn('colType', 'Type', 'type', 'type');


  }
}
