/*BbmTestObj*/
import {EbSepaFileReportGENERATED} from '../_generated/eb-sepa-file-report-generated.model';

export class EbSepaFileReport extends EbSepaFileReportGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaFileReport();
  }

  // ---------------------------------------------------------------------
}
