<div ngbDropdown autoClose="outside" class="col-md-4 px-0 mw-100 mc-amount-due">
  <div ngbDropdownToggle class="mc-dropdown-text">{{ 'cc.common.view.amount-due' | translate }}<i class="mc-chevron bottom"></i></div>
  <div ngbDropdownMenu class="mc-dropdown-list">
    <form (submit)="save()" [formGroup]="form">
      <div class="form-group row mc-dropdown-item">
       <label for="moreThen">{{ 'cc.common.amount-due.more-than' | translate }}:</label>
        <input type="text" class="form-control"  id="moreThen" formControlName="more">
      </div>
      <div class="form-group row mc-dropdown-item">
        <label for="lessThen">{{ 'cc.common.amount-due.less-than' | translate }}:</label>
        <input type="text" class="form-control" id="lessThen" formControlName="less">
      </div>
      <span style="font-size: 70%; color: red;"
            *ngIf="(
            (more?.dirty || more?.touched) && more?.invalid)
            || ((less?.dirty || less?.touched) && less?.invalid)">{{'cc.consumers.view.amount-due-must-be-a-number' | translate}}.</span>
      <span style="font-size: 70%; color: red;"
            *ngIf="(!more?.invalid && !less?.invalid) && checkALargerThanB()">{{'cc.consumers.view.more-than-input-should-be-larger' | translate}}.</span>
      <button type="submit" class="btn btn-primary mc-next-save-btn apply-btn" ngbDropdownToggle
      [disabled]="form.invalid || checkALargerThanB()">{{ 'cc.common.view.apply' | translate }}</button>
    </form>
  </div>
</div>
