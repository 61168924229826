/*BbmTestObj*/
import {McConsumerTable3ConfigGENERATED} from '../_generated/mc-consumer-table-3-config-generated.model';

export class McConsumer360SmartSearchBoxTable3ConfigModel extends McConsumerTable3ConfigGENERATED {


  // ---------------------------------------------------------------------
  constructor() {
    super();
    this.clearColumns();

    this.addColumn('colActions', '', 'fldSmartSearchBoxActions()', '').extraCssClass = 'table-col-width-50';
    this.addIdColumn();
    this.addColumn('colName', this.t('cc.consumers.view.consumer-name'), 'fldNameIndividualOrCompany()', 'firstName').extraCssClass = 'mc-text-align-center';
    this.addColumn('colEntity', this.t('cc.common.view.entity'), 'entityName', '').extraCssClass = 'mc-text-align-center';
    this.addColumn('colNoOfActiveContracts', this.t('cc.consumer.number-of-active-contracts'), 'fldNumOfActiveContracts()', '').extraCssClass = 'mc-text-align-center';
  }
}
