
import {Table3Config} from '../extra/table-3-config';

// import {McProcessFlowLevelGENERATED} from '../_generated/mc-process-flow-level-generated.model';

export class McProcessFlowLevelTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colEmailActionFlg', 'Email action flag', 'emailActionFlg', 'emailActionFlg');
    this.addColumn('colEmailBodyTokened', 'Email body tokened', 'emailBodyTokened', 'emailBodyTokened');
    this.addColumn('colEmailPaymentQrCodeFlg', 'Email payment QR code flag', 'emailPaymentQrCodeFlg', 'emailPaymentQrCodeFlg');
    this.addColumn('colEmailSubjectTokened', 'Email subject tokened', 'emailSubjectTokened', 'emailSubjectTokened');
    this.addColumn('colIdEmailPdfTemplate', 'Email pdf template ID', 'idEmailPdfTemplate', 'idEmailPdfTemplate');
    this.addColumn('colIdMcProcessFlow', 'McProcessFlow ID', 'idMcProcessFlow', 'idMcProcessFlow');
    this.addColumn('colIdPdfTemplate', 'Pdf template ID', 'idPdfTemplate', 'idPdfTemplate');
    this.addColumn('colLevelFeeAmount', 'Level fee amount', 'levelFeeAmount', 'levelFeeAmount');
    this.addColumn('colMcProcessFlow', 'Mc Process Flow', 'mcProcessFlow', 'mcProcessFlow');
    this.addColumn('colNoDaysPaymentDeadlineAfterReminderIssue', 'Number of days payment deadline after reminder issue date', 'noDaysPaymentDeadlineAfterReminderIssue', 'noDaysPaymentDeadlineAfterReminderIssue');
    this.addColumn('colNoDaysWaitingPeriodAfterPaymentDeadline', 'Number of days waiting period after payment deadline', 'noDaysWaitingPeriodAfterPaymentDeadline', 'noDaysWaitingPeriodAfterPaymentDeadline');
    this.addColumn('colPostActionFlg', 'Post action flag', 'postActionFlg', 'postActionFlg');
    this.addColumn('colPostPaymentQrCodeFlg', 'Post payment QR code flag', 'postPaymentQrCodeFlg', 'postPaymentQrCodeFlg');
    this.addColumn('colSmsActionFlg', 'SMS action flag', 'smsActionFlg', 'smsActionFlg');
    this.addColumn('colSmsBodyTokened', 'SMS body tokened', 'smsBodyTokened', 'smsBodyTokened');
    this.addColumn('colTitle', 'Level title', 'title', 'title');

*/
  }
}
