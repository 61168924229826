/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {ZzPocConsumerTable3ConfigGENERATED} from '../_generated/zz-poc-consumer-table-3-config-generated.model';

// import {ZzPocConsumerGENERATED} from '../_generated/zz-poc-consumer-generated.model';

export class ZzPocConsumerTable3Config extends ZzPocConsumerTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colFirstName', 'First name', 'firstName', 'firstName');
    this.addColumn('colLastName', 'Last name', 'lastName', 'lastName');
    this.addColumn('colTestHtml', 'TestHtml', 'fldTestHtml()', '');
    this.addColumn('colGender', 'Gender', 'gender', 'gender');
    this.addColumn('colBirthday', 'Birthday', 'birthday', 'birthday');
    // this.addColumn('colCustomAttributes', 'Custom attributes', 'customAttributes', false);


  }
}
