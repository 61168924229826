import {CsrBankAccount} from './csr-bank-account.model';
import {CsrBankAccountListGENERATED} from '../_generated/csr-bank-account-list-generated.model';

export class CsrBankAccountList extends CsrBankAccountListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrBankAccountList();
  }

  // ---------------------------------------------------------------------
}
