<!--THIS CONTAINER IS MANDATORY-->
<div class="mc-transaction-profile">
  <div class="content-wrapper">
    <lib-mc-loader [showLoader]="ebSepaFileTransaction.apiLoadingFlg || loadingFlg"></lib-mc-loader>


    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-header">
      <img src="assets/mc-components-assets/info.svg">
      <h1 *ngIf="ebSepaFileTransaction.exists()"
          class="modal-title">{{'cc.my-factoring.transaction-details' | translate}}</h1>
    </div>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-body">
      <ng-container *ngIf="ebSepaFileTransaction.apiErrorMessage || successMsg || errorMsg">
        <div class="row">
          <div class="col-md-12 pb-4">
            <div class="mc-transaction-notify-message">
              <mcc-message [errorMsg]="ebSepaFileTransaction.apiErrorMessage" [successMsg]="successMsg"></mcc-message>
              <mcc-message [errorMsg]="errorMsg"></mcc-message>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- == MAIN CONTENT ======================================== -->
      <div>

        <div class="row">
          <div class="col-md-12">
            <div class="mc-transaction-status-label">
              <h6>{{'cc.common.view.status' | translate}}:</h6>
              <div [innerHTML]="ebSepaFileTransaction.getIconLabel()" class="mc-transaction-status">

              </div>

              <div ngbDropdown class="d-inline-block ml-auto">
                <button class="btn btn-outline-new" id="dropdownBasic1" ngbDropdownToggle [disabled]="ebSepaFileTransaction.isStatusCdCANCELLED()">Actions</button>
                <div class="dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownBasic1">

                  <div class="m-2">
                    <button ngbDropdownItem class="m-0" (click)="actShowAddTransactionPayment()"
                            *ngIf="mcGod.userHasEbFactoringOutTransactionPaymentAddPermission() && showEditTransaction"
                            [ngClass]="{'mc-button-grayout': ebSepaFileTransaction.isStatusCdREJECTED()}"
                            [ngbTooltip]="getTooltipForPayment()"><i class="fas fa-plus"></i> {{'cc.my-factoring.add-payment' | translate}}
                    </button>
                  </div>

                  <div class="m-2" [ngClass]="{'mc-hidden' : (canSettleForBalanceStatus())}">
                    <button ngbDropdownItem class="m-0" (click)="actSettleWithBalance()"
                            *ngIf="mcGod.userHasEbFactoringBalanceSettleWithBalancePermission() && showEditTransaction">
                      <i class="fas fa-plus"></i> {{'cc.common.view-settle-with-balance' | translate}}
                    </button>
                  </div>

                  <div class="m-2">
                    <button ngbDropdownItem class="m-0" (click)="actShowAddTransactionAdjustment()"
                            *ngIf="mcGod.userHasEbFactoringOutTransactionAdjustmentAddPermission() && showEditTransaction"
                            [ngClass]="{'mc-button-grayout': (ebSepaFileTransaction.isStatusCdREJECTED() || ebSepaFileTransaction.pausedFlg)}"
                            [ngbTooltip]="getTooltipForAdjustment()"
                    ><i
                      class="fas fa-sliders-h"></i> {{'cc.my-factoring.add-adjustment' | translate}}
                    </button>
                  </div>

                  <ng-container *ngIf="ebSepaFileTransaction.nonFactoringFlg && ebSepaFileTransaction.isStatusCdACCEPTED()
                            && (mcGod.userHasEbFactoringMyTransactionEditPermission() || mcGod.userHasEbFactoringOutTransactionEditPermission())">
                    <div class="dropdown-divider"></div>

                    <div class="m-2">

                      <button ngbDropdownItem class="m-0"
                              (click)="actShowChangeCollectionDate()">
                        {{'cc.consumer.change-collection-date' | translate}}
                      </button>

                    </div>

                  </ng-container>

                  <ng-container
                    *ngIf="loggedEntityId === ebSepaFileTransaction.idMcEntity && canPauseUnpauseTransactionFlg">

                    <div class="dropdown-divider"></div>

                    <div class="m-2">

                      <button ngbDropdownItem class="m-0" *ngIf="loggedEntityId === ebSepaFileTransaction.idMcEntity && canPauseUnpauseTransactionFlg && !ebSepaFileTransaction.pausedFlg
                            && mcGod.userHasEbFactoringMyTransactionPauseTransactionPermission()"
                              (click)="actShowPauseUnpauseTransaction()">
                        <i class="fas fa-pause"></i> Pause transaction
                      </button>

                    </div>

                    <div class="m-2">

                      <button ngbDropdownItem class="m-0" *ngIf="loggedEntityId === ebSepaFileTransaction.idMcEntity && canPauseUnpauseTransactionFlg && ebSepaFileTransaction.pausedFlg
                            && mcGod.userHasEbFactoringMyTransactionUnpauseTransactionPermission()"
                              (click)="actShowPauseUnpauseTransaction()">
                        <i class="fas fa-play"></i> Unpause transaction
                      </button>

                    </div>
                  </ng-container>

                  <ng-container *ngIf="loggedEntityId !== ebSepaFileTransaction.idMcEntity">
                    <!--<div class="dropdown-divider"></div>

                    <button ngbDropdownItem
                            (click)="actShowExportMcb()">
                      <i class="fas mc-submit-color fa-file-export"></i> Export
                    </button>-->

                    <div class="dropdown-divider"></div>

                    <div class="m-2">

                      <button ngbDropdownItem class="m-0"
                              (click)="actShowMarkAsSentToInkasso()">
                        <i class="fas mc-submit-color fa-highlighter"></i> Mark as "Sent to inkasso"
                      </button>

                    </div>

                    <ng-container *ngIf="ebSepaFileTransaction.canMarkAsReturnedFromInkasso()">
                      <div class="dropdown-divider"></div>

                      <div class="m-2">
                        <button ngbDropdownItem class="m-0"
                                (click)="actMarkAsReturnedFromInkasso()">
                          <i
                            class="fas mc-submit-color fa-highlighter"></i> {{'cc.transactions.mark-as-returned-from-inkasso' | translate}}
                        </button>
                      </div>
                    </ng-container>

                  </ng-container>

                </div>
              </div>


            </div>
          </div>
        </div>

        <div class="row info-data">
          <div class="col-md-7 mc-transaction-profile-left-column">
            <mcc-row-title-description [description]="ebSepaFileTransaction.consumerName"
                                       title="{{'cc.my-factoring.consumer' | translate}}:"></mcc-row-title-description>

            <mcc-row-title-description title="Consumer internal id:"
                                       [description]="ebSepaFileTransaction.idCsrConsumer"></mcc-row-title-description>

            <mcc-row-title-description [description]="ebSepaFileTransaction.entityName"
                                       title="{{'cc.common.view.entity' | translate}}:"></mcc-row-title-description>

            <mcc-row-title-description [description]="ebSepaFileTransaction.contractNumber"
                                       title="{{'cc.common.contract-number' | translate}}:"></mcc-row-title-description>

            <mcc-row-title-description [description]="ebSepaFileTransaction.extTransactionId"
                                       title="{{'cc.out-factoring.external-transaction-id' | translate}}:"></mcc-row-title-description>

            <mcc-row-title-description [description]="ebSepaFileTransaction.id"
                                       title="{{'cc.out-factoring.transaction-id' | translate}}:"></mcc-row-title-description>

            <mcc-row-title-description [description]="ebSepaFileTransaction.getCreatedDateTimeString()"
                                       title="{{'cc.consumers.imports.created-at' | translate}}:"></mcc-row-title-description>

            <mcc-row-title-description description="{{ebSepaFileTransaction.pausedDaysCount}}/{{getMaxPauseDaysLbl()}}"
                                       title="TOTAL USED/MAX PAUSE DAYS:"></mcc-row-title-description>
          </div>
          <div class="col-md-4 mc-transaction-profile-right-column">
            <mcc-row-title-description [description]="ebSepaFileTransaction.getDueDateTimeString()"
                                       class="mc-transaction-money-col"
                                       title="PAYMENT DUE DATE:"></mcc-row-title-description>

            <!-- <mcc-row-title-description class="mc-transaction-money-col"
                                        title="REQUEST COLLECTION DATE:"
                                        [description]="ebSepaFileTransaction.requestedCollectionDate"></mcc-row-title-description>-->

            <mcc-row-title-description [description]="format(ebSepaFileTransaction.instdamt)"
                                       class="mc-transaction-money-col"
                                       title="{{'cc.my-factoring.original-amount' | translate}}:"></mcc-row-title-description>

            <mcc-row-title-description *ngIf="ebSepaFileTransaction.statusCd !== rejectedStatusCd"
                                       [description]="format(ebSepaFileTransaction.amountDue)"
                                       class="mc-transaction-money-col mc-description-bold"
                                       title="{{'cc.my-factoring.remaining-amount-due' | translate}}:"></mcc-row-title-description>

            <mcc-row-title-description *ngIf="ebSepaFileTransaction.statusCd !== rejectedStatusCd"
                                       [description]="format(balanceAmount)"
                                       class="mc-transaction-money-col mc-description-bold"
                                       title="{{'cc.dashboard.balance' | translate}}:"></mcc-row-title-description>

            <!--<mcc-row-title-description class="pb-2" title="Surplus:"
                                       [description]="ebSepaFileTransaction.getAmountDueStr()"></mcc-row-title-description>-->
          </div>
        </div>

        <div class="row info-data" *ngIf="ebSepaFileTransaction.pausedFlg">
          <div class="col-md-12 mc-transaction-history-heading">
            <h5><i class="far fa-pause-circle mc-blue-icon"></i> Pausing information</h5>
          </div>


          <div class="col-md-12">
            <div class="important-data">
              <div class="col-md-7">
                <mcc-row-title-description
                  description="{{ebSepaFileTransaction.pausedDaysCount - ebSepaFileTransaction.pauseDaysCountWithoutCurrentPauseDays}}/{{ebSepaFileTransaction.maxPauseDays}}"
                  title="USED/CHOSEN PAUSED DAYS:"></mcc-row-title-description>
                <mcc-row-title-description [description]="ebSepaFileTransaction.getPausedStartDateString()"
                                           title="PAUSE STARTED:"></mcc-row-title-description>
                <mcc-row-title-description [description]="ebSepaFileTransaction.getPausedEndDateString()"
                                           title="PAUSE ENDS:"></mcc-row-title-description>
                <mcc-row-title-description [description]="ebSepaFileTransaction.pausedByUserName"
                                           title="PAUSED BY:"></mcc-row-title-description>
                <mcc-row-title-description [description]="ebSepaFileTransaction.pauseUnpauseReason"
                                           title="PAUSED REASON:"></mcc-row-title-description>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mc-transaction-history-heading">
            <h5><i class="fas fa-history mc-blue-icon"></i>{{'cc.my-factoring.transaction-history' | translate}}</h5>
          </div>
        </div>

        <mcc-message [errorMsg]="errorBalanceMsg" [successMsg]="successBalanceMsg" class="text-center"></mcc-message>

        <ng-container *ngIf="ebSepaFileTransaction.exists()">
          <div class="row">
            <div class="col-md-12">
              <mcc-fi-table-3 (eventItemAction)="actOnTransactionAdjustmentTableItemAction($event)"
                              [objectList]="ebSepaFileTransaction.ebTransactionAdjustments"
                              [table3Config]="ebTransactionAdjustmentsTableConfig"></mcc-fi-table-3>
            </div>
          </div>
        </ng-container>

        <mcc-modal1-wrapper *ngIf="showAddEditTransactionAdjustmentMcb.value"
                            [showMcb]="showAddEditTransactionAdjustmentMcb" modalSizeCd="small">
          <app-shared-transaction-adjustment-add-edit-part
            [ebFactoringLevel]="ebFactoringLevel"
            [ebSepaFileTransaction]="ebSepaFileTransaction"
            (eventCanceled)="showAddEditTransactionAdjustmentMcb.setFalse()"
            (eventSaved)="actOnAddEditTransactionAdjustmentSaved()"
            [ebSepaFileTransactionId]="ebSepaFileTransaction.id"
            [ebTransactionAdjustmentId]="addEditTransactionAdjustmentId"
            [type]="addEditTransactionAdjustmentType"></app-shared-transaction-adjustment-add-edit-part>
        </mcc-modal1-wrapper>

<!--        <mcc-modal1-wrapper *ngIf="showPauseUnpauseTransactionMcb.value" [showMcb]="showPauseUnpauseTransactionMcb">-->
<!--          <app-shared-pause-transactions (eventPauseTransactionCanceled)="onPauseTransactionCanceledFromProfile()"-->
<!--                                         (eventPausedTransaction)="onTransactionPausedFromProfile()"-->
<!--                                         (eventUnpausedTransaction)="onTransactionUnpausedFromProfile()"-->
<!--                                         [ebSepaFileTransaction]="ebSepaFileTransaction"></app-shared-pause-transactions>-->
<!--        </mcc-modal1-wrapper>-->

        <mcc-modal1-wrapper [showMcb]="showChangeCollectionDateMcb" *ngIf="showChangeCollectionDateMcb.value"
                            modalSizeCd="small">
          <app-shared-change-collection-date-part [selectedTransactionsNumber]="1"
                                                  [ebSepaFileTransaction]="ebSepaFileTransaction"
                                                  [ebFactoring]="ebFactoring"
                                                  (eventCollectionDateCanceled)="actCancelChangeCollectionModal()"
                                                  (eventCollectionDateChanged)="actChangeCollectionModal()"></app-shared-change-collection-date-part>
        </mcc-modal1-wrapper>


        <mcc-dialog-box *ngIf="showMarkAsSentToInkassoMcb.value" [enableCloseButtonFlg]="false"
                        title="Mark as sent to inkasso" name="mar-as-sent-to-inkasso"
                        [description]="markAsSentToBankDialogMessage"
                        [buttonsKvm]="buttonsMarkAsSentToInkassoDialog"
                        popupType="question"
                        (eventActionButtonClicked)="onMcDialogBoxMarkAsSentToInkassoActionButtonClicked($event)"></mcc-dialog-box>

      </div>


    </div>

    <div class="content-footer">
      <button (click)="actClose()" class="btn btn-secondary move-right">{{'cc.common.close' | translate}}</button>
    </div>

  </div>
</div>
<router-outlet></router-outlet>
