<!--Bank accounts choose-->
<div *ngIf="bankAccounts.apiSuccessFlg && bankAccounts.items.length" class="row">
  <div class="col-md-12">
    <label class="custom-control fill-checkbox">
      <input type="checkbox" id="selectRows" class="fill-control-input" [checked]="isChecked" #bankAccountCheckbox (click)="setDisable(bankAccountCheckbox.checked)">
      <span class="fill-control-indicator" style="top: 4px;"></span>
      <span class="text-muted font-weight-bold">{{'cc.common.alternate-receiving-accounts' | translate}}</span>
    </label>
    <div *ngIf="isChecked" class="row">
      <div class="col-md-3 ml-4">
        <label for="bankAccount">{{'cc.common.please-choose-an-alternate-receiving-account' | translate}}</label>
      </div>
      <div class="col-md-4">
        <select class="form-control" id="bankAccount" [(ngModel)]="bankAccountId" (ngModelChange)="onChangeBankAccount()">
          <option *ngFor="let bankAccount of bankAccounts.items" [value]="bankAccount.id">{{bankAccount.defaultFlg ? bankAccount.bankName + ', ' + bankAccount.accountNumber + ' (default)' : bankAccount.bankName + ', ' + bankAccount.accountNumber}}</option>
        </select>
      </div>
    </div>
  </div>
</div>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
