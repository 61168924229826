/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingStatementItemTable3ConfigGENERATED} from '../_generated/mc-billing-statement-item-table-3-config-generated.model';

// import {McBillingStatementItemGENERATED} from '../_generated/mc-billing-statement-item-generated.model';

export class McBillingStatementItemTable3Config extends McBillingStatementItemTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colTypeCd', this.t('cc.jobs.edit.type'), 'getTypeCdLabel()', 'typeCd');
    this.addColumn('colItem', this.t('cc.billing.item'), 'fldItem()', '');
    this.addColumn('colNoOfItems', this.t('cc.billing.number-of-items'), 'fldNoOfItems()', '');
    this.addColumn('colBatch', this.t('cc.billing.batch'), 'mcBillingBatchName', '');
    this.addColumn('colPeriod', this.t('cc.billing.period'), 'getPeriod()', '');
    this.addColumn('colPrice', this.t('cc.billing.price'), 'getPriceDescForStatementItem()', '');
    this.addColumn('colAmount', this.t('cc.factoring.transactions.amount'), 'getAmount()', 'amount').extraCssClass = 'mc-text-align-right';
    this.addColumn('colVat', this.t('cc.product.view.vat'), 'getVATString()', '').extraCssClass = 'mc-text-align-right';
    this.addColumn('colAmountWithVat', this.t('cc.billing.total-amount'), 'getTotalFormat()', '').extraCssClass = 'mc-text-align-right';

  }
}
