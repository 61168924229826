import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ICsrBankAccount} from '../_generated/csr-bank-account.interface';
import {CsrBankAccount} from '../models/csr-bank-account.model';
import {CsrBankAccountList} from '../models/csr-bank-account-list.model';
import {CsrBankAccountServiceGENERATED} from '../_generated/csr-bank-account-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CsrBankAccountService extends CsrBankAccountServiceGENERATED {

  public getByCsrConsumerId(csrConsumerId: number): Observable<CsrBankAccount> {
    return this.httpClient.get<CsrBankAccount>(this.getApiServiceRootUrl() + `/consumer-primary-bank-account/${csrConsumerId}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });

  }
}
