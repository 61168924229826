<div class="content-wrapper">
  <div class="content-header">
    <h2>Edit pause days</h2>
  </div>
  <div class="content-body">
    <lib-mc-loader [showLoader]="loadingFlg"></lib-mc-loader>
    <div class="gen-info"><p><i class="fas fa-info-circle"></i> Number of days single transaction can be paused for.</p></div>
    <mcc-fi-textbox [name]="'ctrlMaximalPauseDays'"
                    label="Maximal pause days"
                    [validRequiredFlg]="true"
                    validMinNumber="0"
                    size="10"
                    [value]="maxTransactionPauseDays"
                    [validIntegerNumber]="true" [mcForm]="mcForm"
                    [extraCssClasses]="'input-right'"
                    [additionalDescription]="'cc.factoring.day-s' | translate">
    </mcc-fi-textbox>
  </div>
  <div class="content-footer">
    <button class="btn btn-secondary move-right" (click)="actCancel()"><i class="far fa-times-circle pr-2"></i>{{'cc.common.edit.cancel' | translate}}</button>
    <button class="btn btn-primary" (click)="actSave()"><i class="fas fa-save"></i> {{'cc.common.view.save' | translate}}</button>
  </div>
</div>
