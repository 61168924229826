import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ICsrDiscount} from '../_generated/csr-discount.interface';
import {CsrDiscount} from '../models/csr-discount.model';
import {CsrDiscountList} from '../models/csr-discount-list.model';
import {CsrDiscountServiceGENERATED} from '../_generated/csr-discount-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CsrDiscountService extends CsrDiscountServiceGENERATED {

}
