<ng-container>

  <div class="content-wrapper">

    <lib-mc-loader></lib-mc-loader>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-header">
      <span style="display: flex; flex-direction: row; align-content: center">
        <img src="assets/images/common-icons/user-edit.svg">
        <h2 style="color: rgba(0,0,0,0.65); margin-left: 10px">{{ 'cc.common.edit.edit-personal-info' | translate }}</h2>
      </span>
            <mcc-message [errorMsg]="errorMsg"></mcc-message>
    </div>

    <div class="content-body personal-info">
      <div class="row">
        <div class="col-md-3">
          <div class="user-image-placeholder user-icon">
          <img (click)="fileInput.click()" [src]="imageUrl" alt="Personal image">
          <input #fileInput (change)="onFileChanged($event)" accept="image/*" type="file">
          </div>
          <span  (click)="fileInput.click()"  class="mc-blue-link upload-img"><i class="far fa-image"></i> {{ 'cc.common.edit.upload-image' | translate }}</span>
        </div>
        <div class="col-md-9">
          <div class="row">
        <div class="col-md-5">
          <mcc-fi-textbox [mcForm]="mcForm" [name]="'ctrlFirstName'"
                          [validRequiredFlg]="true"
                          [value]="parentData?.firstname ? parentData.firstname : ''"
                          label="{{ 'cc.common.edit.first-name' | translate }}"
                          size="15">
          </mcc-fi-textbox>
          <mcc-fi-textbox [mcForm]="mcForm" [name]="'ctrlLastName'"
                          [validRequiredFlg]="true"
                          [value]="parentData?.lastname ? parentData.lastname : ''"
                          label="{{ 'cc.common.edit.last-name' | translate }}"
                          size="15">
          </mcc-fi-textbox>
          <mcc-fi-textbox [mcForm]="mcForm" [name]="'ctrlNickname'"
                          [validRequiredFlg]="true"
                          [value]="parentData?.nickname ? parentData.nickname : ''"
                          label="{{'cc.common.view.nickname' | translate}}"
                          size="15">
          </mcc-fi-textbox>

        </div>
        <div class="col-md-7" >
          <mcc-fi-select [mcForm]="mcForm" [optionsVll]="userLanguageOptionsVll" [value]="getSelectedLanguage()"
                         label="{{'cc.users.edit.language' | translate}}" name="selUserLanguage"
                         placeholderOption="{{'cc.my-factoring.all' | translate}}"></mcc-fi-select>
          <div class="input-limit">
          <mcc-fi-select [mcForm]="mcForm" [optionsVll]="userTimezoneOptionsVll"
                        [value]="parentData?.timezone === 1 ? 1 : -8"
                         label="{{'cc.users.edit.timezone' | translate}}"
                         name="selUserTimezone"

                         placeholderOption="{{'cc.my-factoring.all' | translate}}"
                         size="10"></mcc-fi-select>
          </div>
          <mcc-fi-select [mcForm]="mcForm" [optionsVll]="userCountryOptionsVll" [value]="getSelectedCountry()"
                         label="{{'cc.users.edit.country' | translate}}" name="selUserCountry"

                         placeholderOption="{{'cc.my-factoring.all' | translate}}"></mcc-fi-select>
        </div>
            <div class="col-md-12">
              <mcc-fi-radio-buttons [directionHorizontal]="true"
                                    [labelOnTop]="false"
                                    [mcForm]="mcForm"
                                    [name]="'ctrlGender'"
                                    [optionsVlm]="genderVlm"
                                    [validRequiredFlg]="true"
                                    [value]="getGender()"
                                    label="{{'cc.common.edit.gender' | translate}}"></mcc-fi-radio-buttons>
            </div>
      </div>
      </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 position-relative">
        <div class="above-footer">
          <mcc-mc-form-message [extraCssClasses]="'message-background'" messageText="Some validation error text"
                               messageType="error"></mcc-mc-form-message>
        </div>
      </div>
    </div>
    <div class="content-footer">

      <button (click)="actCancel()" class="btn btn-secondary move-right"><i class="far fa-times-circle"></i> {{'cc.common.edit.cancel' | translate}}
      </button>
      <button (click)="actSave()" class="btn btn-primary"><i
        class="fas fa-plus"></i> {{'cc.common.view.save' | translate}}</button>
    </div>

  </div>
</ng-container>

<router-outlet></router-outlet>

