<ng-container class="dialog">
  <div class="dialog_header">
    <span class="dialog_header_title text-uppercase">
      {{ data.processName + ' ' + ("cc.common.edit.email" | translate) }}
    </span>
    <span class="dialog_header_icon"
          [innerHTML]="iconClose | safeHtml"
          (click)="dialogRef.close()"></span>
  </div>

  <mat-dialog-content>
    <div id="emailBody"></div>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button
            class="secondary-btn"
            (click)="dialogRef.close()">
      {{ "cc.common.close" | translate }}
    </button>
  </div>
</ng-container>
