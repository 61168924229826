import {McProcessLevelFilterGENERATED} from '../_generated/mc-process-level-filter-generated.model';

export class McProcessLevelFilter extends McProcessLevelFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessLevelFilter();
  }

  // ---------------------------------------------------------------------
}
