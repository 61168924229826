
import {Table3Config} from '../extra/table-3-config';

// import {CsrAccountConsumerGENERATED} from '../_generated/csr-account-consumer-generated.model';

export class CsrAccountConsumerTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAmount', 'Amount', 'amount', 'amount');
    this.addColumn('colCsrConsumer', 'Mc Consumer', 'csrConsumer', 'csrConsumer');
    this.addColumn('colIdCsrConsumer', 'Consumer ID', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colJsonbTransactionDetails', 'Transaction details', 'jsonbTransactionDetails', 'jsonbTransactionDetails');
    this.addColumn('colPaymentMethod', 'Payment Method', 'paymentMethod', 'paymentMethod');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colType', 'Type', 'type', 'type');

*/
  }
}
