/*BbmTestObj*/
import {EbFactoringTable3ConfigGENERATED} from '../_generated/eb-factoring-table-3-config-generated.model';

// import {EbFactoringGENERATED} from '../_generated/eb-factoring-generated.model';

export class EbFactoringTable3Config extends EbFactoringTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldOutFactoringActions()', '');
    this.addColumn('colFactoringContractName', this.t('cc.my-factoring.contract-name'), 'factoringContractName', 'factoringContractName');
    this.addColumn('colChildName', this.t('cc.factoring.transactions.entity-name'), 'getChildEntityName()', 'entity.name');
    this.addColumn('colContractType', this.t('cc.contract.contract-type'), 'getContractTypeFld()', 'typeCd');
    // this.addColumn('colFactoringContractName', 'Factoring Contract Name', 'factoringContractName', 'factoringContractName');
    this.addColumn('colContractStartDate', this.t('cc.factoring.start-date'), 'getStartDate()', 'contractStartDate');
    this.addColumn('colContractEndDate', this.t('cc.factoring.end-date'), 'getEndDate()', 'contractEndDate');
    this.addColumn('colStatusCd', this.t('cc.common.view.status'), 'getStatusCdLabel()', 'statusCd');
    this.addColumn('colContractRejectionReason', this.t('cc.factoring.rejection-reason'), 'contractRejectionReason', 'contractRejectionReason');
  }
}
