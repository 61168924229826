import {McPriceList} from './mc-price-list.model';
import {McPriceListListGENERATED} from '../_generated/mc-price-list-list-generated.model';

export class McPriceListList extends McPriceListListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McPriceListList();
  }

  // ---------------------------------------------------------------------
}
