/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBankAccountTable3ConfigGENERATED} from '../_generated/mc-bank-account-table-3-config-generated.model';

// import {McBankAccountGENERATED} from '../_generated/mc-bank-account-generated.model';

export class McBankAccountTable3Config extends McBankAccountTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();
      this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colBankName', 'Bank', 'bankName', 'bankName');
    this.addColumn('colAccountNumber', 'Account number', 'accountNumber', 'accountNumber');
    this.addColumn('colIban', 'IBAN', 'iban', 'iban');
    this.addColumn('colAccountOwner', 'Account owner', 'accountOwner', 'accountOwner');
    this.addColumn('colStatus', 'Status', 'ebicsStatus', 'ebicsStatus');


  }
}
