import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  BaseDirective,
  ChargingTypeCds,
  CsrConsumerService,
  EbFactoringService,
  McCategoryService,
  McCountry,
  McCurrencyService,
  McEntity2,
  McForm,
  McGod,
  McManagementService,
  McProduct,
  McProductService,
  McValueLabelList,
  McVatList,
  ServiceTypeCds,
  SortCriteriaList
} from '@miticon-ui/mc-core';
import {takeUntil} from "rxjs/operators";

export enum ProductAvailability {
  NOW = 'NOW',
  RANGE = 'RANGE'
}

@Component({
  selector: 'mc-product-add-edit-product',
  templateUrl: './mc-product-add-edit-product.component.html',
})


export class McProductAddEditProductComponent extends BaseDirective implements OnInit, OnDestroy {

  mcForm = new McForm();
  statusVll = new McValueLabelList();
  availabilityVll = new McValueLabelList();
  categoriesVll = new McValueLabelList();
  vatVll = new McValueLabelList();
  currencyVll = new McValueLabelList();
  refTypeVll = new McValueLabelList();
  chargingTypeVll = new McValueLabelList();
  productTypeVll = new McValueLabelList();
  refTypeCd: string;
  categories: any;
  loggedEntity = new McEntity2();
  country = new McCountry();
  vatList = new McVatList();
  product = new McProduct();
  disableChfValue!: boolean;
  currencyId!: number;
  currency!: string;
  pricingTypeVll = new McValueLabelList();
  referenceEntitiesVll = new McValueLabelList();
  activeFactoringsForEntity = [];
  mcProductService = new McProductService();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter<string>();
  errorMsg!: string;

  @Input() productId!: number;

  constructor(private managementService: McManagementService,
              private factoringService: EbFactoringService,
              private mcCurrencyService: McCurrencyService,
              private mcCategoryService: McCategoryService,
              private csrConsumerService: CsrConsumerService) {
    super();
  }

  ngOnInit() {
    this.actLoad();
    this.chargingTypeVll.items = ChargingTypeCds;
    this.productTypeVll.items =  ServiceTypeCds;
  }

  getProductAvailability() {
    return ProductAvailability;
  }

  actLoad() {
    this.statusVll.add('ACTIVE', McGod.t('cc.common.view.active'));
    this.statusVll.add('INACTIVE', McGod.t('cc.common.view.inactive'));
    this.pricingTypeVll = McProduct.getPricingTypeCdVll();

    this.availabilityVll.add(ProductAvailability.NOW, McGod.t('cc.product.edit.now'));
    this.availabilityVll.add(ProductAvailability.RANGE, McGod.t('cc.product.edit.select-range'));

    this.mcCategoryService.getAll(0, 100, new SortCriteriaList()).subscribe(
      (res) => {
        res.content.forEach((category: any) => this.categoriesVll.add(category.id, category.name));
      }
    );

    const sortCriteriaList = new SortCriteriaList();
    this.mcCurrencyService.getAll(0, 50, sortCriteriaList)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
      response.content.forEach((item: any) => {
        this.currencyVll.add(item.id , item.code);
      });
    });

    this.getVatList();

    this.refTypeVll = McProduct.getRefTypeCdVll();

    if (this.productId) {
      this.product.loadById(this.productId, () => {
        this.currency = this.product.mcCurrency.code;
        this.currencyId = this.product.idCurrency;
        this.refTypeCd = this.product.refTypeCd;
        this.mcForm.getControl('ctrlVat')?.get('value')?.setValue(this.product.idVat);
        this.mcForm.getControl('ctrlCategory')?.get('value')?.setValue(this.product.idPpCategory ? this.product.idPpCategory : '');
        this.mcForm.getControl('ctrlPricingType')?.get('value')?.setValue(this.product.pricingTypeCd ? this.product.pricingTypeCd : '')
        this.mcForm.getControl('ctrlCurrency')?.get('value')?.setValue(this.product.idCurrency);
        this.mcForm.getControl('ctrlChargingType')?.get('value')?.setValue(this.product.chargingTypeCd);
        this.mcForm.getControl('ctrlProductType')?.get('value')?.setValue(this.product.serviceTypeCd);
        this.referenceEntitiesVll = this.getReferenceEntitiesVll(this.product.refTypeCd)
      });
    } else {
      this.product = new McProduct();
      this.referenceEntitiesVll = this.getReferenceEntitiesVll(this.product.refTypeCd)
    }
  }


  getVatList() {
    this.loggedEntity.loadById(McGod.getLoggedEntityIdFromToken(), () => {
      this.country.loadById(this.loggedEntity.countryId);
      this.vatList.loadByCountryId(this.loggedEntity.countryId, () => {
        const activeVatsList = new McVatList();
        activeVatsList.items = this.vatList.items;
        this.vatVll = activeVatsList.toVll('id', 'getVatLabelForVll()');
      }, () => {
        this.errorMsg = this.vatList.apiErrorMessage;
      });
    });
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actSave() {
    if (this.mcForm.isValid()) {
        this.product.externalId = this.mcForm.getValue('ctrlExternalId');
        this.product.name = this.mcForm.getValue('ctrlName');
        this.product.description = this.mcForm.getValue('ctrlDescription');
        this.product.state = this.mcForm.getValue('ctrlStatus');
        this.product.unitPrice = this.mcForm.getValue('ctrlUnitCostValue');
        this.product.idVat = this.mcForm.getValue('ctrlVat');
        this.product.idPpCategory = this.mcForm.getValue('ctrlCategory');
        this.product.idMcEntity = McGod.getLoggedEntityIdFromToken();
        this.product.idCurrency = this.mcForm.getValue('ctrlCurrency');
        this.product.pricingTypeCd = this.mcForm.getValue('ctrlPricingType');
        this.product.refId = this.mcForm.getValue('ctrlRefId');
        this.product.refTypeCd = this.mcForm.getValue('ctrlRefType');
        this.product.chargingTypeCd = this.mcForm.getValue('ctrlChargingType');
        if(this.displayProductType()) {
          this.product.serviceTypeCd = this.mcForm.getValue('ctrlProductType');
        }

        if(this.product.id){
          this.mcProductService.update(this.product).subscribe(
            (res) => {
                this.eventSaved.emit(`${McGod.t('cc.product.the-product-changes-saved-successfully')}.`);
            },
            (error) => {
              this.errorMsg = error.error.message
            }
          );
        }
        else{
          this.mcProductService.insert(this.product).subscribe(
            (res) => {
                this.eventSaved.emit(`${McGod.t('cc.product.the-product-saved-successfully')}.`);
            },
            (error) => {
              this.errorMsg = error.error.message
            }
          );
        }
    }
  }

  onCurrencyValueChanged(value: string) {
    this.currencyVll.items.forEach(item => {
      if (item.value === Number(value)) {
          this.currency = item.label;
      }
    });
  }

  onRefTypeChange(value: string){
    this.referenceEntitiesVll = this.getReferenceEntitiesVll(value)
    this.product.refTypeCd = value;
  }

  displayProductType() {
    return this.mcForm.getControl('ctrlChargingType')?.value && this.mcForm.getControl('ctrlChargingType')?.value.value === 'CONTINUOUS';
  }

  getReferenceEntitiesVll(value: string): McValueLabelList {
    let entities =  this.csrConsumerService.getReferenceEntities(McGod.getLoggedEntityIdFromToken(), value === McProduct.REF_TYPE_CD_CSR_CONSUMER);
    let referenceEntitiesVll = new McValueLabelList();

    entities.subscribe(
      (res) => {
        res.forEach((item: any) => {
          referenceEntitiesVll.add(item.idRef, item.entityName);
        });
      }
    )

    return referenceEntitiesVll
  }
}
