<mcc-modal1-wrapper [showMcb]="showModalMcb" modalSizeCd="full" (eventCanceled)="actCanceled()">
  <div class="content-wrapper">
    <div class="content-header">
      <h1>{{'cc.consumer.history.consumer-history-for' | translate}} {{mcConsumer.typeCd === 'PERSON' ? mcConsumer.fldFullName() : mcConsumer.companyName}}</h1>
    </div>
    <div class="content-body">

      <div>
        <lib-mc-loader [showLoader]="mcConsumerHistoryList.apiLoadingFlg"></lib-mc-loader>

        <div *ngIf="mcConsumerHistoryList.apiErrorFlg">
          Error: {{mcConsumerHistoryList.apiErrorMessage}}
        </div>

        <div *ngIf="mcConsumerHistoryList.apiSuccessFlg">
          <mcc-fi-table-3 [objectList]="mcConsumerHistoryList"
                          [table3Config]="mcConsumerHistoryTable3Config"
                          (eventPaginationOrSortChanged)="actLoad()"></mcc-fi-table-3>
        </div>
      </div>
    </div>
      <div class="content-footer">
        <button type="button" class="btn btn-secondary move-right" (click)="actCanceled()">Close</button>
      </div>
  </div>
  <router-outlet></router-outlet>

</mcc-modal1-wrapper>
