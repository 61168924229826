import {BaseObject} from '../_core/base-object';
import {McRunItemEmailInfo} from './mc-run-item-email-info.model';

export abstract class McRunItemEmailInfoAbstract extends BaseObject {
  idCsrConsumer: number;
  csrConsumerName: string;
  csrConsumerEmail: string;
  idMcEntity: number;
  mcEntityName: string;
  emailStatus: string;

  public propertiesRegular: string[] = ['idCsrConsumer', 'csrConsumerName', 'csrConsumerEmail', 'idMcEntity', 'mcEntityName', 'emailStatus'];

  public static createFromJson(aItemJsonObj: any): McRunItemEmailInfo {
    const item = this.createNewInstance();
    item.populateFromJson(aItemJsonObj);
    return item;
  }

  public populateFromJson(aSourceJson: any) {
    this.propertiesRegular.forEach((key) => {
      if (aSourceJson.hasOwnProperty(key)) {
        // @ts-ignore
        this[key] = aSourceJson[key];
      }
    });
  }

  public static createNewInstance(): McRunItemEmailInfo {
    console.error('MTCN-ERROR: Not Implemented: McRunItemEmailInfo::createNewInstance(). Add this method to final class and return new McRunItemEmailInfo();');
    throw new Error('Cannot McRunItemEmailInfo::createNewInstance(). Add this method to final class and return new McRunItemEmailInfo();');
  }
}
