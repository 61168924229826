import {McBillingStatement} from './mc-billing-statement.model';
import {McBillingStatementListGENERATED} from '../_generated/mc-billing-statement-list-generated.model';

export class McBillingStatementList extends McBillingStatementListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingStatementList();
  }

  // ---------------------------------------------------------------------
}
