<div class="content-wrapper">
  <div class="content-header">
    <h1>{{'cc.common.view.edit' | translate}} {{'cc.processes.flow.flow-criteria' | translate}}</h1>
  </div>
  <div class="content-body">
    <div class="section-header">
      <h3>{{'cc.processes.flow.flow-criteria' | translate}}</h3>
    </div>
    <mcc-message [errorMsg]="errorMsg"></mcc-message>
    <div class="form-inner-content pb-3">
    <span class="mc-mandatory-star">{{'cc.processes.flow.set-criteria' | translate}}:</span>
    <div class="d-flex flex-column" *ngIf="mcProcessFlowCriteriaAttributeList.apiSuccessFlg">
      <div class="row d-flex" *ngFor="let criteria of mcProcessFlow.flowCriteriaList.items; index as i">
        <div class="col-md-10 d-flex justify-content-end">
          <mc-process-process-flow-criteria [attributesVll]="attributesVll"
                                            [id]="criteria.id"
                                            [index]="i"
                                            [mcProcessFlow]="mcProcessFlow"
                                            [mcForm]="mcForm"
                                            [mcProcessFlowCriteria]="criteria"
                                            [mcProcessFlowCriteriaAttributeList]="mcProcessFlowCriteriaAttributeList"></mc-process-process-flow-criteria>
        </div>
        <div class="col-md-2">
          <button *ngIf="i === 0" class="btn btn-outline-primary" (click)="addCriteria()"><i class="fas fa-plus"></i>
            {{'cc.processes.add-criteria' | translate}}
          </button>
          <button *ngIf="i !== 0" class="btn btn-outline-primary" (click)="removeCriteria(i, criteria.id)"><i
            class="fas fa-minus"></i></button>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="content-footer">
    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="{{'cc.common.edit.cancel' | translate}}"></mcc-button>
    <mcc-button *ngIf="(mcProcessFlowCriteriaAttributeList.apiSuccessFlg)" extraCssClasses="btn btn-primary"
                (eventClicked)="actSave();" label="{{'cc.common.view.save' | translate}}"
    ></mcc-button>
  </div>
</div>
