<!--MC Two FA section-->
<div class="mc-two-fa valign-container" [ngStyle]="{'background-image': 'url(' + welcomeImg + ')'}">
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">

        <!--Card section-->
        <div class="mc-card card">
          <div class="card-body">

            <!--Logo-->
            <img src="{{logo}}" class="mc-logo" alt="Cass Control Logo">

            <!--QR code section-->
            <div *ngIf="twoFaResponse.mfa_uri" class="mc-qr-code-section mt-3">
              <p class="text-center">Please download the application for the authentication</p>

              <!--Icons section-->
              <div class="mc-icon-section row">

                <!--Android-->
                <div class="col text-center">
                  <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                     target="_blank">
                    <img src="{{mobileIcons.android}}" class="mc-android-icon img-fluid" alt="">
                  </a>
                </div>

                <!--IOS-->
                <div class="col text-center">
                  <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" target="_blank">
                    <img src="{{mobileIcons.ios}}" class="mc-ios-icon img-fluid" alt="">
                  </a>
                </div>

              </div><!--Icons /end-->

              <hr>
              <p class="text-center">Please scan the QR code</p>

              <!--QR code image-->
              <qrcode [qrdata]="twoFaResponse.mfa_uri"
                      [width]="100"
                      [errorCorrectionLevel]="'M'"
                      [title]="'QR code'"
                      class="mc-qr-code"></qrcode>

            </div><!--QR code section /end-->

            <div class="mc-qr-code-form">

              <!--MC Notify Message-->
              <mc-notify-message [message]="message" [type]="type"></mc-notify-message>

              <!--QR code input-->
              <div class="form-label-group">
                <label for="qr-code" class="text-center w-100">Enter code provided by authentication app</label>
                <input type="text"
                       [(ngModel)]="qrCode"
                       (ngModelChange)="type = ''"
                       [ngClass]="cssClassQrCodeInputField()"
                       (keypress)="onKeydown($event)"
                       name="qr-code"
                       class="form-control"
                       id="qr-code">
              </div>

              <!--Buttons section-->
              <div class="form-group mt-5">

                <!--Previous button-->
                <button (click)="goToPreviousPage()" class="btn mc-two-fa-prev-button">
                  <i class="fa fa-angle-left"></i> {{'cc.common.view.prev' | translate}}
                </button>

                <!--Next button-->
                <button class="btn mc-two-fa-next-button" (click)="goToNextStepTwoFA()">
                  {{'cc.common.view.next' | translate}} <i class="fa fa-angle-right"></i>
                </button>

              </div><!--Buttons /end-->

            </div>
          </div>
        </div><!--Card /end-->
      </div>
    </div>
  </div>
</div>
