export const ICON_INKASSO = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_123_1535)">
<path d="M20 6H12L10.59 4.59C10.21 4.21 9.7 4 9.17 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM13 16H7C6.45 16 6 15.55 6 15C6 14.45 6.45 14 7 14H13C13.55 14 14 14.45 14 15C14 15.55 13.55 16 13 16ZM17 12H7C6.45 12 6 11.55 6 11C6 10.45 6.45 10 7 10H17C17.55 10 18 10.45 18 11C18 11.55 17.55 12 17 12Z" fill="#3B457C"/>
</g>
<defs>
<clipPath id="clip0_123_1535">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
`
