/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CsrDiscountTable3ConfigGENERATED} from '../_generated/csr-discount-table-3-config-generated.model';

// import {CsrDiscountGENERATED} from '../_generated/csr-discount-generated.model';

export class CsrDiscountTable3Config extends CsrDiscountTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdMcEntity', 'EntityId', 'idMcEntity', 'idMcEntity');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colTypeCd', 'Status code', 'typeCd', 'typeCd');
    this.addColumn('colValue', 'Value', 'value', 'value');


  }
}
