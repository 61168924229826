import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {McBoolean} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-dev-modal-part',
  templateUrl: './mc-dev-modal-part.component.html',
  encapsulation: ViewEncapsulation.None
})

export class McDevModalPartComponent implements OnInit, OnDestroy {

  // ---------------------------------------------------------------------
  @Input() visibleMcb = new McBoolean();
  @Output() eventCancel = new EventEmitter();

  // ---------------------------------------------------------------------
  constructor() {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
        this.actLoad();
  }

  // ---------------------------------------------------------------------
  ngOnDestroy() {
  }


  // ---------------------------------------------------------------------
  actLoad() {
  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.visibleMcb.setFalse();
    this.eventCancel.emit();
  }

  // ---------------------------------------------------------------------
  isVisible() {
    if (!this.visibleMcb) {
      this.visibleMcb = new McBoolean();
    }
    return this.visibleMcb.value;
  }
}
