/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McProcessLevelFilter} from '../models/mc-process-level-filter.model';

export class McProcessLevelFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProcessLevelFilter {
    console.error('MTCN-ERROR: Not Implemented: McProcessLevelFilter::createNewInstance(). Add this method to final class and return new McProcessLevelFilter();');
      throw new Error('Cannot McProcessLevelFilter::createNewInstance(). Add this method to final class and return new McProcessLevelFilter();');
  }

}
