/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {ImDataImport} from '../models/im-data-import.model';
import {ImDataImportList} from '../models/im-data-import-list.model';
import {ImDataImportFilter} from '../models/im-data-import-filter.model';
import {ImDataImportService} from '../services/im-data-import.service';
import {IImDataImport} from '../_generated/im-data-import.interface';
import {McGod} from "../extra/mc-god.service";

export class ImDataImportListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'dataImport', 'dataSetId', 'delimiter', 'encoding', 'header', 'idMcEntity', 'importMode', 'mappingId', 'src', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'webFileId'];
  public items: ImDataImport[] = [];
  public apiService: ImDataImportService;
 // protected tempObjList: ImDataImportList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): ImDataImportList {
    console.error('MTCN-ERROR: Not Implemented: ImDataImportList::createNewInstance(). Add this method to final class and return new ImDataImportList();');
    throw new Error('Cannot ImDataImportList::createNewInstance(). Add this method to final class and return new ImDataImportList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IImDataImport[]): ImDataImportList {
    const  listObj: ImDataImportList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: ImDataImport = ImDataImport.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): ImDataImportList {
    const  listObj: ImDataImportList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(ImDataImportService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IImDataImport[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: ImDataImport = ImDataImport.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aImDataImportFilter: ImDataImportFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aImDataImportFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): ImDataImport {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new ImDataImport();
    }
  }

  public replaceItem(newItem: ImDataImport) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

