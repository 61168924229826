import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseDirective, McForm, McGod, McValueLabelList, WebFile} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-consumer-import-consumers-step-1',
  templateUrl: './mc-consumer-import-consumers-step-1.component.html',
})
export class McConsumerImportConsumersStep1Component extends BaseDirective implements OnInit {

  /*Variables*/
  @Output() eventUploadSuccess = new EventEmitter();
  @Output() eventCancel = new EventEmitter();
  webFile = new WebFile();
  rawFile!: File;
  messageType = '';
  message!: string;
  isEnabled = false;
  mcForm = new McForm();
  delimiterVll!: McValueLabelList;
  encodingVll!: McValueLabelList;
  relativePath!: string;

  constructor() {
    super();
  }

  ngOnInit() {
    this.delimiterVll = new McValueLabelList();
    this.delimiterVll.add(';', ';');
    this.delimiterVll.add(',', ',');

    this.encodingVll = new McValueLabelList();
    this.encodingVll.add('UTF-8', 'UTF-8');
  }

  actUpload() {
    this.messageType = 'error';
    this.relativePath = 'import-consumers';
    if (this.rawFile != null) {
      const dataBody = {
        file: this.rawFile,
        delimiter: this.mcForm.getValue('delimiter'),
        aRelativePath: `E${McGod.getLoggedEntityIdFromToken()}/${this.relativePath}`
      };
      this.webFile.upload(dataBody , WebFile.TYPE_CD_CSR_CONSUMER_IMPORT, () => {
        this.eventUploadSuccess.emit(this.webFile.id);
      }, () => {
        console.log(this);
      });
    } else {
      this.message = 'Upload a file';
      this.messageType = 'error';
      this.isEnabled = false;
    }
  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCancel.emit();
  }

  // ---------------------------------------------------------------------
  onFileSelected(rawFile: File) {
    this.rawFile = rawFile;
    if (this.rawFile != null) {
      this.isEnabled = true;
    }
  }

}
