<section>
  <div class="form-row pt-5 mc-add-consumers-form mc-user-summary-area" [formGroup]="stepForm">

    <div class="col-md-8 row mx-0">
      <div class="col-md-12 px-0">
        <div class="mc-consumers-summary-title">
          <i class="fas fa-pen" (click)="goToStep(1)"></i>
          <h6 class="pl-2">{{ 'cc.common.view.basic-info' | translate }}:</h6>
        </div>
      </div>
      <div class="col-md-6 mc-consumers-summary-section">
        <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
          <div class="mc-consumers-summary-value">{{'cc.common.view.status' | translate}}</div>
          <span class="mc-edit-user-preview-data-field-value">
            <mc-status [status]="allRawData.step1?.active"></mc-status>
          </span>
        </div>
        <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
          <div class="mc-consumers-summary-value">{{'cc.jobs.edit.type' | translate}}</div>
          <div class="mc-consumers-summary-value">{{allRawData.step1?.individual || '-'}}</div>
        </div>
        <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
          <div class="mc-consumers-summary-value">{{ 'cc.common.edit.external-id' | translate }}</div>
          <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerExternalId || '-'}}</div>
        </div>
        <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
          <div class="mc-consumers-summary-value">{{'cc.common.company-name' | translate}}</div>
          <div class="mc-consumers-summary-value">{{allRawData.step1?.individual === 'company' ?
            allRawData.step1?.companyName : '-'}}
          </div>
        </div>

        <div class="contact-person-wrapper mt-1 mb-3">
          <span *ngIf="allRawData.step1?.individual === 'company'" class="contact-person">{{'cc.common.contact-person' | translate}}</span>
          <span *ngIf="allRawData.step1?.individual === 'person'" class="contact-person">{{'cc.common.basic-information' | translate}}</span>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">{{ 'cc.common.edit.first-name' | translate }}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerFirstName || '-'}}</div>
          </div>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">{{ 'cc.common.edit.last-name' | translate }}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerLastName || '-'}}</div>
          </div>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">{{'cc.common.edit.email' | translate}}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerEmail || '-'}}</div>
          </div>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">{{'cc.common.edit.title' | translate}}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerTitle || '-'}}</div>
          </div>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">{{ 'cc.common.edit.birthday' | translate }}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerBDay ? (allRawData.step1?.consumerBDay |
              date:'MMM d, y') : '-'}}
            </div>
          </div>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">{{ 'cc.common.edit.gender' | translate }}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.gender || '-'}}</div>
          </div>
        </div>

        <div class="contact-person-wrapper">
          <span class="contact-person">{{'cc.common.language-information' | translate}}</span>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">ISO {{'cc.users.edit.country' | translate}}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerISOCountry || '-'}}</div>
          </div>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">ISO {{'cc.users.edit.language' | translate}}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerISOLanguage || '-'}}</div>
          </div>
        </div>

      </div>

      <div class="col-md-6 mc-consumers-summary">

        <div class="contact-person-wrapper mt-2">
          <span class="contact-person">{{'cc.common.contact-information' | translate}}</span>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">{{ 'cc.common.edit.telephone' | translate }}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerTelephone || '-'}}</div>
          </div>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">{{'cc.consumers.edit.cellphone' | translate}}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerCellphone || '-'}}</div>
          </div>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">{{ 'cc.common.edit.fax' | translate }}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerFax || '-'}}</div>
          </div>
        </div>

        <div class="contact-person-wrapper">
          <span class="contact-person">{{'cc.common.address-information' | translate}}</span>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">{{'cc.consumers.edit.street' | translate}}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerStreet || '-'}}</div>
          </div>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">{{'cc.consumers.edit.address-supplement' | translate}}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerAddressSupplement || '-'}}</div>
          </div>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">{{'cc.consumers.edit.postal-code' | translate}}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerPostalCode || '-'}}</div>
          </div>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">{{ 'cc.common.edit.city' | translate }}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerCity || '-'}}</div>
          </div>
        </div>

        <div class="contact-person-wrapper">
          <span class="contact-person">{{'cc.common.bank-information' | translate}}</span>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value">{{'cc.consumers.edit.account-owner' | translate}}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerAccountOwner || '-'}}</div>
          </div>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value text-uppercase">{{'cc.consumers.edit.iban' | translate}}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerIBAN || '-'}}</div>
          </div>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value text-uppercase">{{'cc.consumers.edit.bic' | translate}}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.consumerBIC || '-'}}</div>
          </div>
          <div class="col-md-12 pt-1 pb-2 mc-consumers-summary-basic-info">
            <div class="mc-consumers-summary-value text-uppercase">{{'cc.product.view.vat' | translate}}</div>
            <div class="mc-consumers-summary-value">{{allRawData.step1?.vat || '-'}}</div>
          </div>
        </div>
      </div>
    </div>

    <!--Custom attributes-->
    <div class="col-md-4 mc-consumers-summary extended-info">

      <div class="mc-consumers-summary-title">
        <i class="fas fa-pen" (click)="goToStep(2)"></i>
        <h6 class="pl-2">{{'cc.consumers.view.extended-info' | translate}}:</h6>
      </div>

      <div class="col-md-12" *ngFor="let customAttribute of consumersAttributes; let index = index;">
        <div class="form-group row" *ngIf="customAttribute.type !== 'DATE' && customAttribute.type !== 'DATETIME' && customAttribute.type !== 'BOOLEAN'">
          <label class="col-4 col-form-label text-uppercase mt-n1 mc-custom-attributes-summary-view text-break">{{customAttribute.name.split("_").join(" ")}}</label>
          <div class="col-8">
            {{customAttribute.value || "-"}}
          </div>
        </div>

        <div class="form-group row" *ngIf="customAttribute.type === 'DATE' || customAttribute.type === 'DATETIME'">
          <label class="col-4 col-form-label text-uppercase mt-n1 mc-custom-attributes-summary-view text-break">{{customAttribute.name.split("_").join(" ")}}</label>
          <div class="col-8">
            {{formatDate(customAttribute.value) || "-"}}
          </div>
        </div>

        <div class="form-group row" *ngIf="customAttribute.type === 'BOOLEAN'">
          <label class="col-4 col-form-label text-uppercase mt-n1 mc-custom-attributes-summary-view text-break">{{customAttribute.name.split("_").join(" ")}}</label>
          <div class="col-8 text-capitalize">
            {{customAttribute.value ? ("cc.common.edit.yes" | translate) : ("cc.common.edit.no" | translate)}}
          </div>
        </div>

        </div>
      </div>
    </div><!--Custom attributes /end-->

    <!--Buttons-->
    <div class="col-md-12">
      <div class="col-md-6 pt-5 offset-md-6 mc-user-summary-action-btn mc-consumers-summary-btn">
        <div class="col-md-6 text-right mc-user-summary-create-btn">
          <button id="consumersSummaryBtn" class="btn btn-primary mc-next-save-btn" type="submit" (click)="onSave(1)">
            {{'cc.common.view.create' | translate}}
          </button>
        </div>
        <div class="col-md-6 text-right mc-user-summary-createAdd-btn">
          <button id="consumersSummaryCreate&AddBtn" class="btn btn-primary mc-next-save-btn" type="submit"
                  (click)="onSave(2)">
            {{'cc.users.edit.create-&-add-new' | translate}}
          </button>
        </div>
      </div>
    </div>

</section>
