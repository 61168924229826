import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {McForm, McGod, McValueLabelList} from '@miticon-ui/mc-core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'mcc-fi-multiselect2',
  templateUrl: './mcc-fi-multiselect2.component.html',
  styleUrls: ['./mcc-fi-multiselect2.component.scss'],
  host: {
    '(document:click)': 'onClick()',
  },
})
export class MccFiMultiselect2Component implements OnInit, OnDestroy, OnChanges {


  showMultiselectItems!: boolean;
  itemsSelected: any[] = [];
  itemsSelectedLabels: any[] = [];
  itemsSelectedNumber = 0;
  itemTitles: any[] = [];
  checkedCheckboxes!: ElementRef<HTMLInputElement>[];
  checkedItems: any[] = [];
  destroy$ = new Subject<boolean>();
  options: { value: string, label: string, checked: boolean }[] = [];
  errorMsg!: string;
  checkIfOthersAreSelected!: boolean;
  itemSelectedString = '';
  @ViewChildren('itemsCheckboxes') itemsCheckboxes!: ElementRef<HTMLInputElement>[];
  isCheckBoxChecked: any;
  isAllSelected = true;

  // --------------------------------------------------------------------
  values: any[] = [];

  formValidationTriggerSubscription!: Subscription;
  @Input() mcForm!: McForm;
  @Input() valueVll!: McValueLabelList;
  @Input() optionVll!: McValueLabelList;
  @Input() name!: string;
  @Input() extraCssClass!: string;
  @Input() labelPositionClass!: string;
  @Input() label!: string;
  @Input() removeControlFromMcFormOnDestroy = true;
  @Input() pleaseChoosePlaceHolderFlg = false;

  @Input() validRequiredFlg!: boolean;
  @Output() eventValueChanged = new EventEmitter<any[]>();
  @Input() resetSelectedCheckboxes!: Subject<boolean>;
  @Input() tooltipPlacement = 'right';

  constructor(private _eref: ElementRef) {
  }

  getMultiSelectForm(): FormGroup {
    return this.mcForm.getControl(this.name) as FormGroup;
  }

  setCurrentValue() {
    if (!this.getMultiSelectForm()) {
      const multiSelectForm = this.mcForm.formBuilder.group({
        value: [this.values, this.validRequiredFlg && this.validatorRequiredMultiSelect],
        label: [this.label],
        errorFlg: [false],
        errorMsg: ['']
      });

      if (this.mcForm) {
        this.mcForm.addControl(this.name, multiSelectForm);
      }
    } else {
      this.getMultiSelectForm().controls['value'].setValue(this.values);
      this.getMultiSelectForm().controls['errorFlg'].setValue(false);
      this.getMultiSelectForm().controls['errorMsg'].setValue('');
    }

  }

  ngOnInit() {
    this.options.sort((a, b) => {
      const optA = a.label;
      const optB = b.label;
      return (optA < optB) ? -1 : (optA > optB) ? 1 : 0;
    });

    if (this.mcForm) {
      this.formValidationTriggerSubscription = this.mcForm.getValidationTriggerObservable().subscribe(() => {
        this.validateMultiSelect();
      });
    }
    this.setCurrentValue();

    this.options.unshift({
      value: '',
      label: McGod.t('cc.my-factoring.all'),
      checked: false,
    });



  }



  private validatorRequiredMultiSelect(control: FormControl) {

    return control.value.length > 0 ? null : {
      validatorRequiredMultiSelect: {
        valid: false
      }
    };
  }

  validateMultiSelect() {
    if (!this.getMultiSelectForm().valid) {
      this.getMultiSelectForm().get('errorMsg')?.setValue('This field is required');
      this.getMultiSelectForm().get('errorFlg')?.setValue(true);
    }
  }


  // -------------------------------------------------------------------------------------------------------------


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['optionVll'].currentValue) {
      this.options = this.optionVll.items.map(item => {
        return {
          value: item.value,
          label: item.label,
          checked: false
        };
      });
    }

    if (changes['validRequiredFlg']) {
      if ((changes['validRequiredFlg'].previousValue !== changes['validRequiredFlg'].currentValue)) {
        if (this.validRequiredFlg && this.getMultiSelectForm()) {
          // @ts-ignore
          this.getMultiSelectForm().controls['value'].setValidators(this.validatorRequiredMultiSelect);
          this.getMultiSelectForm().get('value')?.updateValueAndValidity();
        }
      }
    }

    if (changes['valueVll'] && changes['valueVll'].currentValue) {
      this.options.forEach(item => {
        if (changes['valueVll'].currentValue.items.some((it: any) => item.value === it.value)) {
          item.checked = true;
          this.getLabelAndCheckSelectedValues();
        }
      });
      this.options.filter(item => item.checked);
    }


  }

  getLabelAndCheckSelectedValues() {
    this.values = this.options.filter(option => option.checked === true && option.value !== '').map(option => option.value);
    this.itemsSelectedLabels = this.options.filter(it => it.checked === true).map(it => it.label);
    this.getMultiSelectForm().get('value')?.setValue(this.values);
    if (this.options.length === this.values.length) {
      this.itemsSelectedNumber = this.itemsSelected.length - 1;
    } else {
      this.itemsSelectedNumber = this.values.length;
      this.eventValueChanged.emit(this.values);
    }
  }

  toggleDropdown() {
    this.showMultiselectItems = !this.showMultiselectItems;
  }

  getSelectedItemsTooltip() {
    if (this.itemsSelectedLabels.length === this.options.length) {
      this.itemsSelectedLabels.shift();
      return this.itemsSelectedLabels.join(', ');
    } else {
      return this.itemsSelectedLabels.join(', ');
    }
  }

  resetSelects() {
    this.itemsSelected = [];
    this.itemsSelectedNumber = 0;
    // this.options = this.options.map(item => {
    //   item.checked = false;
    //   return item;
    // });
    this.options.forEach(item => {
      item.checked = false;
      this.values = [];
      this.getMultiSelectForm().get('value')?.setValue(this.values);
    });
    this.getMultiSelectForm().controls['errorFlg'].setValue(false);
    this.getMultiSelectForm().controls['errorMsg'].setValue('');
  }


  checkSelected(vllItem: { value: string, label: string, checked: boolean }) {
    this.getMultiSelectForm().controls['errorFlg'].setValue(false);
    this.getMultiSelectForm().controls['errorMsg'].setValue('');
    const filtered = this.options.filter(item => item.checked === true);
    this.options = this.options.map(item => {

      if (filtered.length === this.options.length) {
        if (vllItem.value === '')  {
          item.checked = false;
        } else {
          if (vllItem.value === item.value) {
            item.checked = !item.checked;
          }
          if (item.value === '') {
            item.checked = false;
          }

        }
      } else {
        if (vllItem.value === '') {
          item.checked = true;
        }

        if (vllItem.value === item.value && vllItem.value !== '') {
          item.checked = !item.checked;
        }
      }

      return item;
    });
    this.getLabelAndCheckSelectedValues();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    if (this.removeControlFromMcFormOnDestroy) {
      this.mcForm.formGroup.removeControl(this.name);
    }
  }

  onKeypress() {
    return false;
  }

  getAllItemsTooltip() {
    const allOptionLabels = this.options.map(opt => opt.label);
    allOptionLabels.shift();
    return allOptionLabels.join(', ');
  }

  onClick() {
    // @ts-ignore
    if (!this._eref.nativeElement.contains(event.target)) {
      this.showMultiselectItems = false;
    }
  }
}
