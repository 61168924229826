/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McConsumerHistory} from '../models/mc-consumer-history.model';
import {McConsumerHistoryList} from '../models/mc-consumer-history-list.model';
import {McConsumerHistoryFilter} from '../models/mc-consumer-history-filter.model';
import {McConsumerHistoryService} from '../services/mc-consumer-history.service';
import {IMcConsumerHistory} from '../_generated/mc-consumer-history.interface';
import {McGod} from "../extra/mc-god.service";

export class McConsumerHistoryListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'actionTypeCd', 'fieldLabel', 'fieldName', 'fieldSrcCd', 'fieldTypeCd', 'idCsrConsumer', 'newBigintValue', 'newBooleanValue', 'newDatetimeValue', 'newTextValue', 'newVarcharValue', 'oldBigintValue', 'oldBooleanValue', 'oldDatetimeValue', 'oldTextValue', 'oldVarcharValue', 'sysCreatedDatetime', 'sysCreatedUserId', 'createdByUser'];
  public items: McConsumerHistory[] = [];
  public apiService: McConsumerHistoryService;
 // protected tempObjList: McConsumerHistoryList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McConsumerHistoryList {
    console.error('MTCN-ERROR: Not Implemented: McConsumerHistoryList::createNewInstance(). Add this method to final class and return new McConsumerHistoryList();');
    throw new Error('Cannot McConsumerHistoryList::createNewInstance(). Add this method to final class and return new McConsumerHistoryList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcConsumerHistory[]): McConsumerHistoryList {
    const  listObj: McConsumerHistoryList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McConsumerHistory = McConsumerHistory.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McConsumerHistoryList {
    const  listObj: McConsumerHistoryList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McConsumerHistoryService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcConsumerHistory[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McConsumerHistory = McConsumerHistory.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcConsumerHistoryFilter: McConsumerHistoryFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcConsumerHistoryFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McConsumerHistory {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McConsumerHistory();
    }
  }

  public replaceItem(newItem: McConsumerHistory) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadByConsumerId( idCsrConsumer: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByConsumerId(idCsrConsumer, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

