import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { McUserEntitiesList } from '../models/entity.model';
import {InvoiceTableModel} from "../models/invoice.model";

@Injectable({
  providedIn: 'root'
})
export class McStateManagementService {

  /*Constructor*/
  constructor() {
  }

  /*TODO: use this for selected entity in header and sidebar*/
  /*Selected entity*/
  public _selectedEntity = new BehaviorSubject({
    selected: false,
    id: 0
  });
  public selectedEntity = this._selectedEntity.asObservable();

  /*Entity list for select entity section*/
  public _listEntities = new BehaviorSubject([]);
  public listEntities = this._listEntities.asObservable();

  public setListEntities(entityList: McUserEntitiesList | never[]) {
    // @ts-ignore
    this._listEntities.next(entityList);
  }

  /*Open select entity modal*/
  /*TODO: create utility service to get all entities*/
  public _openSelectEntityModal = new BehaviorSubject(false);
  public openSelectEntityModal = this._openSelectEntityModal.asObservable();

  public setOpenSelectEntityModal(flag: boolean) {
    this._openSelectEntityModal.next(flag);
  }

  /*Switch entity - get new permissions from new token for sidebar list*/
  public _isSwitchedEntity = new BehaviorSubject(false);
  public isSwitchedEntity = this._isSwitchedEntity.asObservable();

  public setIsSwitchedEntity(flag: boolean) {
    this._isSwitchedEntity.next(flag);
  }

  /*Invoice state*/
  private _invoiceData: InvoiceTableModel | undefined;
  set invoiceData(invoice) {
    this._invoiceData = invoice;
  }
  get invoiceData() {
    return this._invoiceData;
  }

  /*Bridge state*/
  public _bridgeState = new BehaviorSubject(null);
  public bridgeState = this._bridgeState.asObservable();

  public setBridgeState(state: null) {
    this._bridgeState.next(state);
  }

  /*Bridge state*/
  public _collapseSidebar = new BehaviorSubject(null);
  public collapseSidebar = this._collapseSidebar.asObservable();

  public setCollapsedSidebar(state: boolean | null) {
    // @ts-ignore
    this._collapseSidebar.next(state);
  }
}
