import {CsrReccuringOptionsFilterGENERATED} from '../_generated/csr-reccuring-options-filter-generated.model';

export class CsrReccuringOptionsFilter extends CsrReccuringOptionsFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrReccuringOptionsFilter();
  }

  // ---------------------------------------------------------------------
}
