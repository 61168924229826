import {Component, OnInit, Input} from '@angular/core';
import {BaseDirective, CsrConsumer, CsrConsumerHistoryService, McConsumersService, SortCriteriaList} from '@miticon-ui/mc-core';
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'lib-mc-consumers-details-history',
  templateUrl: './mc-consumers-details-history.component.html'
})
export class McConsumersDetailsHistoryComponent extends BaseDirective implements OnInit {

  isContentLoaded!: boolean;
  tableContent: any;
  responseMessage!: boolean;
  message!: string;
  type!: string;
  showLoader!: boolean;

  @Input() csrConsumer!: CsrConsumer;

  constructor(
    private csrConsumerHistoryService: CsrConsumerHistoryService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.csrConsumer) {
      this.showLoader = true;
      this.responseMessage = false;
      this.csrConsumerHistoryService.getByConsumerId(this.csrConsumer.id, 0, 100, new SortCriteriaList())
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response) => {
          this.isContentLoaded = true;
          this.showLoader = false;
          if (response?['content'].length > 0 : '') {
            this.handleResponseItems(response);
            response['content'].forEach((item: any) => {
              item.attributeName = item.fieldName.replace(/_/g, ' ')
              item.modificationDate = item.sysCreatedDatetime
              item.systemUser = item.sysCreatedUserId
            })
          }

          this.tableContent = Object.assign({}, {
            ...response,
            _name: 'consumers_history',
            isLoaded: true
          });
          if (response?['content'].length === 0 : '') {
            this.message = '';
            this.type = '';
            this.responseMessage = true;
            this.setNotificationMessage(this.message, this.type);
          }
          this.populateTableContent(response);
        });
    }
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  private handleResponseItems(response: any){
    response['content'].forEach((item: any) => {
      item.attributeName = item.fieldName.replace(/_/g, ' ')

      item.modificationDate = item.sysCreatedDatetime
      item.systemUser = item.sysCreatedUserId
    })
  }

  private populateTableContent(response: any){
    this.tableContent = Object.assign({}, {
      ...response,
      _name: 'consumers_history',
      isLoaded: true
    });
    if (response?['content'].length === 0 : '') {
      this.message = '';
      this.type = '';
      this.responseMessage = true;
      this.setNotificationMessage(this.message, this.type);
    }
  }

}
