/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CsrNotificationDetailsFilter} from '../models/csr-notification-details-filter.model';

export class CsrNotificationDetailsFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrNotificationDetailsFilter {
    console.error('MTCN-ERROR: Not Implemented: CsrNotificationDetailsFilter::createNewInstance(). Add this method to final class and return new CsrNotificationDetailsFilter();');
      throw new Error('Cannot CsrNotificationDetailsFilter::createNewInstance(). Add this method to final class and return new CsrNotificationDetailsFilter();');
  }

}
