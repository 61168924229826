import {CsrNotificationDetailsFilterGENERATED} from '../_generated/csr-notification-details-filter-generated.model';

export class CsrNotificationDetailsFilter extends CsrNotificationDetailsFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrNotificationDetailsFilter();
  }

  // ---------------------------------------------------------------------
}
