<div class="mcc-dialog-box mcc-dialog-box-{{name}} {{extraCssClasses}} {{popupType}}">
  <div class="dialog-box-content">
    <!--    BOX HEADER START -->
    <div class="dialog-box-header" *ngIf="title">
      <h1>{{title}}</h1>
      <button *ngIf="enableCloseButtonFlg" class="dialog-box-close" (click)="eventCloseButtonClicked.emit()">&times;</button>
    </div>
    <!--    BOX HEADER END-->
    <!--    BOX BODY START-->
    <div class="dialog-box-body">
      <div class="description" *ngIf="description" [innerHTML]="description">
<!--        <p>{{description}}</p>-->
      </div>
      <div class="button-wrapper">
        <button
          *ngFor="let button of buttonsKvm | keyvalue"
          class="btn {{button.key}}" type="button"
          (click)="eventActionButtonClicked.emit(button.key)" [innerHTML]="button.value">
<!--          {{button.value}}-->
        </button>
      </div>
    </div>
    <!--    BOX BODY END-->
  </div>
</div>



