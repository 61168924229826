import {Component, OnInit, Renderer2} from '@angular/core';
import {
  EbFactoring,
  EbFactoringFilter,
  EbFactoringList,
  EbFactoringTable3Config,
  McBoolean,
  McEntityService,
  McForm,
  McGod,
  McHtmlButton,
  McUtilityService
} from '@miticon-ui/mc-core';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'mc-entity-factoring-wrapper-part-child',
  templateUrl: './mc-entity-factoring-wrapper-part-child.component.html',
})
export class McEntityFactoringWrapperPartChildComponent implements OnInit {
  ebFactoringFilter = new EbFactoringFilter();
  mcForm = new McForm();
  idChildEntity!: number;
  profileEbFactoringId!: number;
  showFactoringProfileModalMcb = new McBoolean();
  showPendingContractMcb = new McBoolean();
  ebFactoring!: EbFactoring;
  selectedEntityPath!: any;
  selectedEntity!: any;
  modalReference!: any;
  rejectModalReference!: any;
  entityId!: any;
  editEntityId!: any;
  entityName!: any;

  showLoader!: boolean;
  isLoggedEntity!: boolean;
  responseMessage!: boolean;
  factoringEnableFlg!: boolean;
  factoringIsEnabled!: boolean;
  submitDisabled!: boolean;
  isDisabled!: boolean;
  entityMessage!: string;

  entityType!: string;
  message!: string;
  type!: string;

  rejectForm!: FormGroup;

  activeFactoring = new EbFactoring();
  factoringList = new EbFactoringList();
  lastPendingItem = new EbFactoring();
  pendingFactoringList = new EbFactoringList();
  public ebFactoringTable3Config = new EbFactoringTable3Config();



  constructor(private entityService: McEntityService,
              private utilityService: McUtilityService,
              private activatedRoute: ActivatedRoute,
              private modalService: NgbModal,
              private renderer: Renderer2,
              private fb: FormBuilder) {
    this.getSelectedEntityPath();
    this.createForm();
  }

  ngOnInit() {
    this.renderer.removeClass(document.body, 'mc-overflow-hidden');
    /*Set unique Id for current entity*/
    this.activatedRoute.paramMap.subscribe(data => {
      if (data.get('id')) {
        this.entityId = data.get('id');
        this.editEntityId = data.get('id');
        // disable payment info for logged entity
        this.isLoggedEntity = data.get('id') !== this.utilityService.getPropertyFromToken('entity_id').toString();
      } else {
        this.entityId = this.utilityService.getPropertyFromToken('entity_id');
        this.isLoggedEntity = false;
      }
    });

    // Getting factoringEnableFlg
    this.entityService.getMcEntityById(this.entityId).subscribe(response => {
      this.factoringIsEnabled = response.factoringEnableFlg;

      this.activeFactoring = new EbFactoring();

      // Setting factoring list
      this.factoringList = new EbFactoringList();
      this.factoringList.setPager(0, 50);
      this.factoringList.loadByChildEntityId(this.entityId, () => {
        this.pendingFactoringList = this.factoringList.getPendingList();
        if (this.pendingFactoringList.items.length > 0) {
          this.lastPendingItem = this.pendingFactoringList.items[this.pendingFactoringList.items.length - 1];
        }
        if (this.factoringList.getActiveItem() !== null) {
          this.activeFactoring = this.factoringList.getActiveItem();
        }
        if (this.factoringList.items.length > 0) {
          this.responseMessage = false;
        }
      });
    });

  }

  actFactoringTableItemAction(htmlButton: McHtmlButton) {
    if (htmlButton.actionCdForEvent === EbFactoring.ACTION_SHOW_PROFILE) {
      this.actShowProfileModal(htmlButton.item);
    } else if (htmlButton.actionCdForEvent === EbFactoring.ACTION_SHOW_REVOKE) {
      this.actRevoke(htmlButton.item);
    }
  }

  actShowProfileModal(ebFactoring: EbFactoring) {
    this.profileEbFactoringId = ebFactoring.id;
    this.ebFactoring = ebFactoring;
    this.showFactoringProfileModalMcb.setTrue();
  }

  actRevoke(ebFactoring: EbFactoring) {
    this.ebFactoring = ebFactoring;
    if (this.ebFactoring.statusCd === EbFactoring.STATUS_CD_PENDING_ACCEPTANCE) {
      this.ebFactoring.statusCd = EbFactoring.STATUS_CD_REVOKED;
      this.ebFactoring.save(() => {
        this.actLoad();
        this.message = `Factoring contract '${this.ebFactoring.factoringContractName}' revoked successfully.`;
        this.type = 'success';
      }, () => {
        this.message =  this.ebFactoring.apiErrorMessage;
        this.type = 'error';
      });
    } else {
      this.message =  `You can only revoke contracts in status "Pending acceptance".`;
      this.type = 'error';
    }
  }

  public actLoad(): void {
    this.ebFactoringFilter = new EbFactoringFilter();
    this.ebFactoringFilter.parentEntityId = McGod.getLoggedEntityIdFromToken();
    this.ebFactoringFilter.statusCds = this.mcForm.getValueArray('selStatusCd');
    this.ebFactoringFilter.childEntityIds = this.mcForm.getValueArray('selChildEntityId');
    console.log('filter030:', this.ebFactoringFilter);
    // TODO Need to connect this when the loadByFilter is implemented on be
    // this.ebFactoringList.loadByFilter(this.ebFactoringFilter);
    // this.ebFactoringList.searchTerm = this.mcForm.getValue('txbSearchTerm');
    // this.ebFactoringList.loadByFilter(this.ebFactoringFilter);
    this.factoringList.loadByChildEntityId(this.entityId);
  }

  createForm() {
    this.rejectForm = this.fb.group({
      rejectReason: ['']
    });
  }

  openPendingContractMethod() {
    this.showPendingContractMcb.setTrue();
  }

  // TODO Maybe create general method for this in some service
  /*Get selected entity path*/
  private getSelectedEntityPath() {
    this.showLoader = true;
    this.entityService._selectedEntityPath.subscribe(response => {
      this.selectedEntityPath = response;
      this.showLoader = false;
    }, (error) => {
      this.showLoader = false;
      if (error.status >= 500) {
        this.entityMessage = 'Server is not available at the moment, please try again later.';
        this.entityType = 'error';
        return;
      }
      this.entityMessage = error.error ? error.error.message : 'There is no basic info defined';
      this.entityType = 'error';
    });
  }

  acceptContract() {
    this.lastPendingItem.statusCd = EbFactoring.STATUS_CD_ACCEPTED;
    this.lastPendingItem.save(() => {
      this.factoringList.loadByChildEntityId(this.entityId, () => {
        this.pendingFactoringList = this.factoringList.getPendingList();
        this.activeFactoring = this.factoringList.getActiveItem();
      });
      this.modalService.dismissAll();
    }, () => {
      this.lastPendingItem.statusCd = EbFactoring.STATUS_CD_PENDING_ACCEPTANCE;
      this.message = 'There was a problem saving this factoring.';
      this.type = 'error';
      this.responseMessage = true;
      this.setNotificationMessage(this.message, this.type);
    });
  }

  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  rejectContract(rejectReason: any) {
    this.lastPendingItem.statusCd = EbFactoring.STATUS_CD_REJECTED;
    this.lastPendingItem.contractRejectionReason = rejectReason;
    this.lastPendingItem.save(() => {
      this.modalService.dismissAll();
    }, () => {
      this.lastPendingItem.statusCd = EbFactoring.STATUS_CD_PENDING_ACCEPTANCE;
      this.message = 'There was a problem saving this factoring.';
      this.type = 'error';
      this.responseMessage = true;
      this.setNotificationMessage(this.message, this.type);
    });
  }

  disableCreateButton($event: boolean) {
    this.submitDisabled = $event;
  }
}
