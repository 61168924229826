import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {McCurrencyService, McForm, McGod} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';
import {MccFiNumberTypeEventAfterValidation} from './mcc-fi-number-type-event-after-validation';

@Component({
  selector: 'mcc-fi-number-type',
  templateUrl: './mcc-fi-number-type.component.html',
  styleUrls: ['./mcc-fi-number-type.component.scss']
})
export class MccFiNumberTypeComponent implements OnInit, OnChanges, OnDestroy {

  requiredFlg!: Validators;
  pattern!: Validators;
  minLength!: Validators;
  maxLength!: Validators;
  numberDecimalRegex!: Validators;
  numberIntegerRegex!: Validators;
  numberWithTwoDecimalsRegex!: Validators;
  whiteSpaceRegex!: Validators;
  emailRegex!: Validators;
  validMin!: Validators;
  validMax!: Validators;
  numberDecimalRegexString = /^\d*\.?\d*$/;
  numberIntegerRegexString = /^\d*\d*$/;
  whitespaceRegexString = /^(?!\s*$).+/;
  numberWithTwoDecimalsRegexString = /^\d+(\.\d{1,2})?$/;
  emailRegexString = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,6})$/;
  validatorList: any[] = [];
  mcGod = McGod.getInstance();
  mouseDown!: boolean;
  // textBoxForm: FormGroup;
  cssClass!: string;
  currencies: any[] = [];
  formValidationTriggerSubscription!: Subscription;

  // BMITIC: Talk to me before enabling any additional inputs
  /*Events*/
  @Input() extraCssClasses: any;
  @Input() label!: string;
  @Input() value = '';
  @Input() additionalDescription!: string;
  @Input() valueType!: '€' | 'percentage' | 'fr.';
  @Input() nameForValue: any;
  @Input() nameForType: any;
  @Input() disablePercentage = false;
  @Input() disableMoneyValue = false;
  @Input() mcForm!: McForm;
  @Input() chosenCurrency!: string;

  // @Input() frmGroup: FormGroup;
  @Input() placeholder = '';
  @Input() readOnlyFlg = false;
  @Input() validRequiredFlg = false;
  @Input() validPattern!: string;
  @Input() validMinLength!: number;
  @Input() validMaxLength!: number;
  @Input() validDecimalNumber = true;
  @Input() validIntegerNumber!: boolean;
  @Input() validWhitespaces!: boolean;
  @Input() validMinNumber!: number;
  @Input() validMaxNumber!: number;
  @Input() validEmail!: boolean;
  @Input() labelOnTopFlg = true;
  @Input() maxDecimalPlaces!: number;
  @Input() validNumberWithTwoDecimals!: boolean;
  @Input() removeControlFromMcFormOnDestroy = true;
  @Input() disableInputFlg = false;
  @Input() newStyleCurrency = false;

  // max decimal places
//  @Input() customValidator;
//  @Input() validationSetupObject: McValidation;
  // @Input() validationTrigger = false;
  @Input() extraCssClass = '';
  @Input() formExtraCssClass: any;
  @Input() isPercentageDisplayed!: boolean;
  @Input() isEuroDisplayed!: boolean;
  // @Input() frmValidationTrigger: McFormValidationTrigger;

  // @Input() colWidth;

  @Output() eventAmountValueChanged = new EventEmitter();
  @Output() eventTypeValueChanged = new EventEmitter();
  @Output() eventAfterValidation = new EventEmitter();
  @Output() eventEnterClicked = new EventEmitter();
  public isSelected!: boolean;
  selectedCurrency = 'EUR';
  // @Output() eventValidationTurnOn = new EventEmitter();
  // @Output() newSetup = new EventEmitter();

  constructor(private el: ElementRef, private mcCurrencyService: McCurrencyService) {
  }

  getTextboxForm(): FormGroup {
    return this.mcForm.getControl(this.nameForValue) as FormGroup;
  }

  getSelectForm(): FormGroup {
    return this.mcForm.getControl(this.nameForType) as FormGroup;
  }

  setCurrentValue() {
    if (!this.getTextboxForm()) {
      const textBoxForm = this.mcForm.formBuilder.group({
        value: [this.value, this.validatorList],
        label: [this.label],
        errorFlg: [false],
        errorMsg: ['']
      });

      if (this.mcForm) {
        this.mcForm.addControl(this.nameForValue, textBoxForm);
      }
    } else {
      this.getTextboxForm().controls['value'].setValue(this.value);
      this.getTextboxForm().controls['errorFlg'].setValue(false);
      this.getTextboxForm().controls['errorMsg'].setValue('');
    }

    if (!this.getSelectForm()) {
      const selectForm = this.mcForm.formBuilder.group({
        value: [this.valueType],
      });

      if (this.mcForm) {
        this.mcForm.addControl(this.nameForType, selectForm);
      }
    } else {
      this.getSelectForm().controls['value'].setValue(this.valueType);
    }

    this.disableInputFlg ? this.getTextboxForm().get('value')?.disable() : this.getTextboxForm().get('value')?.enable();
  }

  // ---------------------------------------------------------------------


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      if ((changes['value'].previousValue !== changes['value'].currentValue)) {
        this.setCurrentValue();
      }
    }
    if (changes['validRequiredFlg']) {
      if ((changes['validRequiredFlg'].previousValue !== changes['validRequiredFlg'].currentValue)) {
        this.validationSetup();
        if (this.getTextboxForm()) {
          this.getTextboxForm().controls['value'].setValidators(this.validatorList);
          this.getTextboxForm().controls['errorFlg'].setValue(false);
          this.getTextboxForm().controls['errorMsg'].setValue('');
        }
      }
    }

    if (changes['disableInputFlg'] && this.getTextboxForm()) {
      changes['disableInputFlg'].currentValue ? this.getTextboxForm().get('value')?.disable() : this.getTextboxForm().get('value')?.enable();
    }
  }

  ngOnInit() {
    if (this.mcForm) {
      this.formValidationTriggerSubscription = this.mcForm.getValidationTriggerObservable().subscribe(() => {
        this.validateTextBox();
      });
    }

    this.validationSetup();
    this.setCurrentValue();

    this.currencies = this.mcCurrencyService.currencies;
  }

  ngOnDestroy(): void {
    if (this.formValidationTriggerSubscription) {
      this.formValidationTriggerSubscription.unsubscribe();
    }

    if (this.removeControlFromMcFormOnDestroy) {
      this.mcForm.formGroup.removeControl(this.nameForValue);
      this.mcForm.formGroup.removeControl(this.nameForType);
    }
  }

  validationSetup() {
    this.validatorList = [];

    if (this.validRequiredFlg) {
      this.requiredFlg = Validators.required;
      this.validatorList.push(this.requiredFlg);
    }

    if (this.validPattern) {
      this.pattern = Validators.pattern(this.validPattern);
      this.validatorList.push(this.pattern);
    }

    if (this.validMinLength) {
      this.minLength = Validators.minLength(this.validMinLength);
      this.validatorList.push(this.minLength);
    }

    if (this.validMaxLength) {
      this.maxLength = Validators.maxLength(this.validMaxLength);
      this.validatorList.push(this.maxLength);
    }

    if (this.validEmail) {
      this.emailRegex = Validators.pattern(this.emailRegexString);
      this.validatorList.push(this.emailRegex);
    }

    if (this.validMinNumber) {
      this.validMin = Validators.min(this.validMinNumber);
      this.validatorList.push(this.validMin);
    }

    if (this.validMaxNumber) {
      this.validMax = Validators.max(this.validMaxNumber);
      this.validatorList.push(this.validMax);
    }

    /*if (this.validMinNumber) {
      this.validMin = Validators.min(this.validMinNumber);
      this.validatorList.push(this.validMin);
    }

    if (this.validMaxNumber) {
      this.validMax = Validators.max(this.validMaxNumber);
      this.validatorList.push(this.validMax);
    }*/

    if (this.validDecimalNumber) {
      this.numberDecimalRegex = Validators.pattern(this.numberDecimalRegexString);
      this.validatorList.push(this.numberDecimalRegex);
    }
    if (this.validIntegerNumber) {
      this.numberIntegerRegex = Validators.pattern(this.numberIntegerRegexString);
      this.validatorList.push(this.numberIntegerRegex);
    }
    if (this.validWhitespaces) {
      this.whiteSpaceRegex = Validators.pattern(this.whitespaceRegexString);
      this.validatorList.push(this.whiteSpaceRegex);
    }
    if (this.validNumberWithTwoDecimals) {
      this.numberWithTwoDecimalsRegex = Validators.pattern(this.numberWithTwoDecimalsRegexString);
      this.validatorList.push(this.numberWithTwoDecimalsRegex);
    }

    /*   if (this.customValidator) {
         this.validatorList.push(this.customValidator);
       }
   */
  }

  public validateTextBox() {
    this.validationSetup();
    this.getTextboxForm().controls['value'].setValidators(this.validatorList);
    this.getTextboxForm().get('value')?.updateValueAndValidity();
    if (this.getTextboxForm().get('value')?.errors) {
      // @ts-ignore
      const validators = Object.keys(this.getTextboxForm().get('value')?.errors);
      if (validators) {
        this.getTextboxForm().get('errorFlg')?.setValue(true);
        validators.forEach(item => {
          switch (item) {
            case 'required':
              this.getTextboxForm().get('errorMsg')?.setValue(this.mcGod.t('cc.common.this-field-is-required'));
              break;
            case 'minlength':
              this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.length-must-be-at-least')} ${this.validMinLength}`);
              break;
            case 'maxlength':
              this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.length-must-be-under')} ${this.validMaxLength}`);
              break;
            case 'min':
              this.getTextboxForm().get('errorMsg')?.setValue(`Number must be greater than ${this.validMinNumber}`);
              break;
            case 'max':
              this.getTextboxForm().get('errorMsg')?.setValue(`Number must be less than ${this.validMaxNumber}`);
              break;
            case 'pattern':
              // @ts-ignore
              if (this.numberDecimalRegex && !this.numberDecimalRegexString.test(this.getTextboxForm().get('value').value)) {
                this.getTextboxForm().get('errorMsg')?.setValue(`This field is numeric and accepts only positive whole numbers and decimal numbers with dot (.)`);
                break;
              } else if (this.numberIntegerRegex && !this.numberIntegerRegexString.test(this.getTextboxForm().get('value')?.value)) {
                this.getTextboxForm().get('errorMsg')?.setValue(`This field is numeric and accepts only positive whole numbers`);
                break;
              } else if (this.whiteSpaceRegex && !this.whitespaceRegexString.test(this.getTextboxForm().get('value')?.value)) {
                this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.no-whitespaces-allowed')}`);
                break;
              } else if (this.emailRegex && !this.emailRegexString.test(this.getTextboxForm().get('value')?.value)) {
                this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.please-enter-valid-email')}`);
                break;
              } else if (this.numberWithTwoDecimalsRegex && !this.numberWithTwoDecimalsRegexString.test(this.getTextboxForm().get('value')?.value)) {
                this.getTextboxForm().get('errorMsg')?.setValue('This field accepts only whole numbers or decimal numbers with two decimal places.');
                break;
              } else {
                this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.invalid-value')}`);
                break;
              }
          }
        });
      }
    } else {
      this.getTextboxForm().get('errorFlg')?.setValue(false);
      this.getTextboxForm().get('errorMsg')?.setValue(``);
    }
    this.fireEventAfterValidation();
  }

  public fireEventAfterValidation() {
    if (this.eventAfterValidation) {
      const eventObj = new MccFiNumberTypeEventAfterValidation();
      eventObj.validFlg = !this.getTextboxForm().get('value')?.errors;

      this.eventAfterValidation.emit(eventObj);
    }
  }

  clearErrors() {
    this.getTextboxForm().get('errorFlg')?.setValue(false);
    this.getTextboxForm().get('errorMsg')?.setValue('');
  }

  onTextBoxChanged(value: any) {
    this.clearErrors();
    if (this.eventAmountValueChanged) {
      this.eventAmountValueChanged.emit(value);
    }
  }

  enterClicked() {
    this.eventEnterClicked.emit();
  }

  onSelectChanged(selectElement: { value: any; }) {
    this.clearErrors();
    if (this.eventTypeValueChanged) {
      this.eventTypeValueChanged.emit(selectElement.value);
    }
  }

  disableCurrency(currencyCode: string): boolean {
    return this.chosenCurrency !== currencyCode;
  }

  selectCurrency(code: string): boolean {
    return this.chosenCurrency === code;
  }
}
