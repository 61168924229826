import {ImDataExportTemplate} from './im-data-export-template.model';
import {ImDataExportTemplateListGENERATED} from '../_generated/im-data-export-template-list-generated.model';

export class ImDataExportTemplateList extends ImDataExportTemplateListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataExportTemplateList();
  }

  // ---------------------------------------------------------------------
}
