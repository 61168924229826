import {Inject, Injectable} from '@angular/core';
import {CsrNotificationDetailsServiceGENERATED} from '../_generated/csr-notification-details-generated.service';



@Injectable({
  providedIn: 'root'
})
export class CsrNotificationDetailsService extends CsrNotificationDetailsServiceGENERATED {

}
