import {Inject, Injectable} from '@angular/core';
import {McProcessItemServiceGENERATED} from '../_generated/mc-process-item-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McProcessItemService extends McProcessItemServiceGENERATED {

}
