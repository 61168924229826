<div class="row pt-3" [formGroup]="mappingForm">


  <div class="col-md-12 mt-4 d-flex align-items-center">
    <label class="mb-0 h6">{{'cc.consumers.consumers-overview.currently-applied-mapping' | translate}}: </label>
    <label class="form-check-label w-25 mx-3 pt-1">{{currentTemplateMapping.mappingName}}</label>
    <lib-mc-choose-mapping [mappingList]="templateMappings"
                           (eventMappingSelected)="onTemplateMappingChanged($event)"></lib-mc-choose-mapping>
  </div>


  <!--CUSTOM ATTRIBUTE MODAL-->
  <div class="col-md-2 mt-3 d-flex justify-content-start">
    <i class="fas fa-info-circle mc-tooltip" ngbTooltip="{{'cc.consumer.import.required-mapping-columns-contract-number-gender-first-name-last-name' | translate}}" placement="right" tooltipClass="mc-tooltip-length"></i> <!-- TODO for release, maybe make a component -->
  </div>
  <div class="col-md-2 offset-md-8 mt-3">
    <mc-add-custom-attribute [entityAttributes]="entityAttributes"></mc-add-custom-attribute>
  </div>


  <!--  TABLE   -->
  <div class="col-md-12 mc-table-wrapper mc-consumers-import-table">
    <mcc-fi-table-2 [objectList]="mapping.mappingItems" [table2Config]="mappingItemTableConfig"
                    (eventTableCellEditConfig)="onMappingItemTableCellConfig($event)"
                    (eventTableCellEdited)="onMappingTableCellEdited($event)"></mcc-fi-table-2>
  </div>

  <!--  SAVE MAPPING  -->
  <div class="col-md-12 p-3">
    <label class="custom-control fill-checkbox mc-cursor-pointer mb-3">
      <input type="checkbox"
             class="fill-control-input" #saveMappingCB formControlName="mappingNameCheckBox">
      <span class="fill-control-indicator"></span>
      <span class="text-capitalize h6">{{'cc.consumers.consumers-overview.save-mapping' | translate}}</span>
    </label>
    <div class="row">
      <div class="col-md-6 pr-0 d-flex align-items-center">
        <label *ngIf="saveMappingCB.checked" [ngClass]="saveMappingCB.checked ? '' : 'disabled'" class="mb-0"
               for="saveMappingName">{{'cc.consumers.consumers-overview.mapping-name' | translate}}:</label>
        <input *ngIf="saveMappingCB.checked" id="saveMappingName" class="mx-2 form-control w-75" type="text"
               formControlName="mappingName">
      </div>
    </div>
  </div>


  <!--  How to handle duplicates  -->
  <div class="col-md-12 p-3">
    <div class="row mt-3 pl-3 d-flex flex-column">
      <label class="mc-mandatory-star">{{'cc.consumers.consumers-overview.import-mode' | translate}}</label>
      <div class="p-0 mc-width-fit-content">
        <mcc-fi-select-option (eventSelectionChange)="setDuplicates($event)"
                            [optionsKvm]="duplicatesHandlingOptionsKvm" [selectedValue]="''"></mcc-fi-select-option>
      </div>
    </div>
  </div>

</div>
