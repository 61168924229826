<div *ngIf="!actionSet.horizontalFlg" class="mcc-html-action-set mc-tooltip-trigger {{extraCssClasses}}" (mouseenter)="actionSetOnHover($event.target)" >
  <span *ngIf="actionSet.items.length>0" class=""><i class="fas fa-ellipsis-v"></i></span>

  <div class="view-edit" [ngClass]="{'actionSetNearBottom': actionSetNearBottomFlg, 'actionSetWithThreeItems': actionSetWithThreeItems,
  'actionSetWithOneItem' : actionSetWithOneItem}">
    <ng-container *ngIf="actionSetView.items.length > 0">
      <div class="html-span">
        <div class="html-span-heading">
          <div class="d-flex">
            <i class="fas fa-eye mr-2"></i>
            <p>View</p>
          </div>
          <div>
            <i class="fas fa-chevron-right"></i>
          </div>
        </div>
        <ul class="html-span-first-child">
          <ng-container  *ngFor="let item of actionSetView.items">
            <li *ngIf="isItemMcHtmlButtonAndHasPermission(item)">
              <button (click)="actButtonPressedForItem(item)" class="{{item.extraCssClass}}" [innerHTML]="item.label"></button>
            </li>
            <li *ngIf="isItemMcHtmlLinkAndHasPermission(item)">
              <button (click)="actGoToPage(item)"  class="{{item.extraCssClass}} mc-cursor-pointer" [innerHTML]="item.getInnerHtml()"></button>
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="actionSetNoOptions.items.length > 0">
      <ng-container class="html-span" *ngFor="let item of actionSetNoOptions.items">
        <div class="no_options" (click)="actButtonPressedForItem(item)" *ngIf="isItemMcHtmlButtonAndHasPermission(item)">
          <span [innerHTML]="getIcon(item.label) | safeHtml"></span>
          <span class="no_options_label" [innerHTML]="item.label"></span>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="actionSetEdit.items.length > 0">
      <div class="html-span">
        <div class="html-span-heading">
          <div class="d-flex">
            <i class="fas fa-pen mr-2"></i>
            <p>Edit</p>
          </div>
          <div>
            <i class="fas fa-chevron-right"></i>
          </div>
        </div>
        <ul [ngClass]="actionSetView.items.length < 1 ? 'html-span-first-child' : 'html-span-second-child'">
          <ng-container *ngFor="let item of actionSetEdit.items">
            <li *ngIf="isItemMcHtmlButtonAndHasPermission(item)">
              <button (click)="actButtonPressedForItem(item)" class="{{item.extraCssClass}}" [innerHTML]="item.label"></button>
            </li>
            <li *ngIf="isItemMcHtmlLinkAndHasPermission(item)">
              <button (click)="actGoToPage(item)"  class="{{item.extraCssClass}} mc-cursor-pointer" [innerHTML]="item.getInnerHtml()"></button>
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="actionSetDelete.items.length > 0">
      <div class="html-span">
        <div class="html-span-heading">
          <div *ngIf="isItemMcHtmlButtonAndHasPermission(actionSetDelete.items[0])">
            <div class="d-flex" (click)="actButtonPressedForItem(actionSetDelete.items[0])">
              <i class="fas fa-trash-alt mr-2"></i>
              <p>Delete</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>




<!--<ul class="">
<ng-container *ngFor="let item of actionSet.items">
  <li *ngIf="isItemMcHtmlButtonAndHasPermission(item)">
    <button (click)="actButtonPressedForItem(item)" class="{{item.extraCssClass}}" [innerHTML]="item.label"></button>
  </li>
  <li *ngIf="isItemMcHtmlLinkAndHasPermission(item)">
      <a (click)="actGoToPage(item)"  class="{{item.extraCssClass}} mc-cursor-pointer" [innerHTML]="item.getInnerHtml()"></a>
  </li>
</ng-container>
</ul>-->
</div>

<div class="mcc-html-action-set-horizontal" *ngIf="actionSet.horizontalFlg">
  <ul class="">
    <ng-container *ngFor="let item of actionSet.items">
      <li *ngIf="isItemMcHtmlButtonAndHasPermission(item)">
        <button (click)="actButtonPressedForItem(item)" class="{{item.extraCssClass}}" [innerHTML]="item.label"></button>
      </li>
      <li *ngIf="isItemMcHtmlLinkAndHasPermission(item)">
        <a (click)="actGoToPage(item)"  class="{{item.extraCssClass}} mc-cursor-pointer" [innerHTML]="item.getInnerHtml()"></a>
      </li>
    </ng-container>
  </ul>
</div>
