/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EbFactoringTable3ConfigGENERATED} from '../_generated/eb-factoring-table-3-config-generated.model';


export class  EbFactoringMyFactoringTable3ConfigModel extends EbFactoringTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldMyFactoringActions()', '');
    this.addColumn('colFactoringContractName', this.t('cc.my-factoring.contract-name'), 'factoringContractName', 'factoringContractName');
    this.addColumn('colContractType', this.t('cc.contract.contract-type'), 'getContractTypeFld()', 'typeCd');
    this.addColumn('colContractStartDate', this.t('cc.factoring.start-date'), 'getStartDate()', 'contractStartDate');
    this.addColumn('colContractEndDate', this.t('cc.factoring.end-date'), 'getEndDate()', 'contractEndDate');
    this.addColumn('colStatusCd', this.t('cc.common.view.status'), 'getStatusCdLabel()', 'statusCd');
    this.addColumn('colContractRejectionReason', this.t('cc.factoring.rejection-reason'), 'contractRejectionReason', 'contractRejectionReason');


  }
}
