import {Inject, Injectable} from '@angular/core';
import {McActionPdfTemplateServiceGENERATED} from '../_generated/mc-action-pdf-template-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McActionPdfTemplateService extends McActionPdfTemplateServiceGENERATED {

}
