<!--MC Two FA section-->
<h2>Mc Dev Index Page</h2>
<div class="mc-notify-message-wrapper mt-5">
  <mc-notify-message [message]="this.message" [type]="messageType"></mc-notify-message>
</div>
<div class="row pt-5">
  <div class="col-md-12">
    <span>Fix Consumers Status And Type Cd</span>
    <button class="mx-4 mc-button mc-submit-button text-capitalize" (click)="fixConsumerStatusAndTypeCd()">Fix it</button>
  </div>
  <div class="col-md-12 pt-2">
    <span>Fix Consumers Custom Attributes</span>
    <button class="mx-4 mc-button mc-submit-button text-capitalize" (click)="fixCustomAttributes()">Fix it</button>
  </div>
  <div class="col-md-12 pt-2">
    <span>Fix Transactions</span>
    <button class="mx-4 mc-button mc-submit-button text-capitalize" (click)="fixTransactions()">Fix it</button>
  </div>
  <div class="col-md-12 pt-2">
    <span>Fix Contract Numbers</span>
    <button class="mx-4 mc-button mc-submit-button text-capitalize" (click)="fixContractNumbers()">Fix it</button>
  </div>
  <div class="col-md-12 pt-2">
    <span>Fix Role Ancestor Entity Name</span>
    <button class="mx-4 mc-button mc-submit-button text-capitalize" (click)="fixRoleAncestorEntityName()">Fix it</button>
  </div>

  <div class="col-md-12 pt-2">
    <span>Fix Template Role Number Of Children</span>
    <button class="mx-4 mc-button mc-submit-button text-capitalize" (click)="fixTemplateRoleNumberOfChildren()">Fix it</button>
  </div>

  <div class="col-md-12 pt-2">
    <span>Fix Beneficiary Entity Id</span>
    <button class="mx-4 mc-button mc-submit-button text-capitalize" (click)="fixBeneficiaryEntityId()">Fix it</button>
  </div>

  <!--  <div class="col-md-12 pt-2">-->
  <!--    <span>Fix Sending to Inkasso to Settled</span>-->
  <!--    <button class="mx-4 mc-button mc-submit-button text-capitalize" (click)="fixChangeStatusForSettled()">Fix it</button>-->
  <!--  </div>-->

  <div class="col-md-12 pt-2">
    <!--<span class="text-capitalize">
                    {{"cc.common.add-user-with-all-permissions-to-all-entities" | translate}}:
                  </span>-->
    <span>Add user with all permissions to all entities</span>
    <button class="mx-4 mc-button mc-submit-button text-capitalize" type="button" (click)="onAddSuperUserWithAllPermissionsInAllEntities()">
      <!--      {{'cc.common.add' | translate}}-->
      Add
    </button>

    <!--TODO: need to test why notify message component not working on this page!-->
    <!--This is solution until we fix notify message showing-->
    <!--<span class="text-uppercase" *ngIf="addedUserStatus">
                    {{addedUserStatus}}
                  </span>-->
  </div>

  <!--Fix consumer history-->
  <div class="col-md-12">
    <!--<span class="text-capitalize">
                    {{"cc.common.fix-consumer-history" | translate}}:
                  </span>-->
    <span>Fix Consumer History</span>
    <button class="mx-4 mc-button mc-submit-button text-capitalize" type="button" (click)="onFixConsumerHistory()">
      <!--      {{'cc.common.fix-it' | translate}}-->
      Fix it
    </button>

    <!--TODO: need to test why notify message component not working on this page!-->
    <!--This is solution until we fix notify message showing-->
    <!-- <span class="text-uppercase" *ngIf="fixedConsumerHistoryStatus">
                    {{fixedConsumerHistoryStatus}}
                  </span>-->
  </div>
</div>
<router-outlet></router-outlet>
