import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {McProcessFlow} from "@miticon-ui/mc-core";
import {
  McForm,
  McBoolean,
  McTrigger,
} from "@miticon-ui/mc-core";
import {Router, ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {Subscription} from "rxjs";

@Component({
  selector: "mc-process-process-flow-transactions-entering-the-flow-edit",
  templateUrl: "./mc-process-process-flow-transactions-entering-the-flow-edit.component.html",
})
export class McProcessProcessFlowTransactionsEnteringTheFlowEditComponent implements OnInit {

  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  errorMsgs: string[] = [];
  errorMsg = '';

  // -- Input --
  @Input() mcProcessFlowId!: number;
  @Input() mcProcessFlow!: McProcessFlow;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();


  // ---------------------------------------------------------------------
  ngOnInit() {
    if (!this.mcProcessFlow) {
      this.mcProcessFlow = new McProcessFlow();
    }

    this.actLoad();
  }


  // ---------------------------------------------------------------------
  public actLoad(): void {
    // this.mcProcessFlow = new McProcessFlow();
    if (this.mcProcessFlowId > 0 && !this.mcProcessFlow.exists()) {
      this.mcProcessFlow.loadById(this.mcProcessFlowId, () => {
        this.validatePrerequisites();
      });
    } else {
      // new item
      this.validatePrerequisites();
    }

  }

  // ---------------------------------------------------------------------
  actSave() {

    if (this.mcForm.isValid()) {

      this.mcProcessFlow.acceptedTransactionsEnteringFlowFlg = this.mcForm.getValue("ctrlTransactionsAccepted");
      this.mcProcessFlow.returnedTransactionsEnteringFlowFlg = this.mcForm.getValue("ctrlTransactionsReturned");
      this.mcProcessFlow.forDunningTransactionsEnteringFlowFlg = this.mcForm.getValue("ctrlTransactionsForDunning");
      this.mcProcessFlow.shouldGoToInkassoTransactionsEnteringFlowFlg = this.mcForm.getValue("ctrlTransactionsShouldGoToInkasso");
      this.mcProcessFlow.sendingToInkassoTransactionsEnteringFlowFlg = this.mcForm.getValue("ctrlTransactionsSendingToInkasso");

      if (!this.validTransactionsEnteringFlowFlags()) {
        this.errorMsg = "You must select at least one of the transaction statuses that will go into this flow.";
        this.saveButtonDisabledMcb.setFalse();
      } else {
        this.mcProcessFlow.save(() => {
          this.eventSaved.emit();
        }, () => {
          this.saveButtonDisabledMcb.setFalse();
        });
      }

    } else {
      this.saveButtonDisabledMcb.setFalse();
    }

  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.mcProcessFlow.exists()) {

      this.mcProcessFlow.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

// ---------------------------------------------------------------------
  validatePrerequisites() {
    this.errorMsgs = [];
    // populate validation here on required inputs
  }

  // ---------------------------------------------------------------------
  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }

  validTransactionsEnteringFlowFlags(): boolean {
    return this.mcProcessFlow.acceptedTransactionsEnteringFlowFlg || this.mcProcessFlow.returnedTransactionsEnteringFlowFlg ||
      this.mcProcessFlow.forDunningTransactionsEnteringFlowFlg || this.mcProcessFlow.shouldGoToInkassoTransactionsEnteringFlowFlg ||
      this.mcProcessFlow.sendingToInkassoTransactionsEnteringFlowFlg;
  }
}
