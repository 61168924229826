import {PmTransactionFilterGENERATED} from '../_generated/pm-transaction-filter-generated.model';

export class PmTransactionFilter extends PmTransactionFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new PmTransactionFilter();
  }

  // ---------------------------------------------------------------------
}
