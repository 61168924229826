import {EbSepaTransaction} from './eb-sepa-transaction.model';
import {EbSepaTransactionListGENERATED} from '../_generated/eb-sepa-transaction-list-generated.model';

export class EbSepaTransactionList extends EbSepaTransactionListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaTransactionList();
  }

  // ---------------------------------------------------------------------
}
