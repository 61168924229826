/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McEntity2Filter} from '../models/mc-entity2-filter.model';

export class McEntity2FilterGENERATED extends BaseObjectFilter{    public countryId: number | undefined;

  public properties: string[] = ['countryId', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McEntity2Filter {
    console.error('MTCN-ERROR: Not Implemented: McEntity2Filter::createNewInstance(). Add this method to final class and return new McEntity2Filter();');
      throw new Error('Cannot McEntity2Filter::createNewInstance(). Add this method to final class and return new McEntity2Filter();');
  }

}
