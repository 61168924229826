import { McMyFactoringTransactionListPageComponent } from './pages/mc-my-factoring-transaction-list-page/mc-my-factoring-transaction-list-page.component';
import { McMyFactoringSepaInListPageComponent } from './pages/mc-my-factoring-sepa-in-list-page/mc-my-factoring-sepa-in-list-page.component';
import { McMyFactoringContractListPageComponent } from './pages/mc-my-factoring-contract-list-page/mc-my-factoring-contract-list-page.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [
{
    path: 'transactions',
    component: McMyFactoringTransactionListPageComponent
  },
{
    path: 'sepa-in',
    component: McMyFactoringSepaInListPageComponent
  },
{
    path: 'contracts',
    component: McMyFactoringContractListPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McMyFactoringRoutingModule {
}
