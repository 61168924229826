import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PmTransactionsRoutingModule } from './pm-transactions-routing.module';
import { PmTransactionsListComponent } from './pages/pm-transactions-list/pm-transactions-list.component';
import { PmTransactionsPageComponent } from './pages/pm-transactions-page/pm-transactions-page.component';
import {TranslateModule} from '@ngx-translate/core';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {NgbDropdownModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {McConsumerModule} from '../mc-consumer/mc-consumer.module';
import {AppSharedModule} from '../app-shared/app-shared.module';
import { PmTransactionsStornoPartComponent } from './components/pm-transactions-storno-part/pm-transactions-storno-part.component';
import { PmTransactionsCollectionTypePartComponent } from './pm-transactions-collection-type-part/pm-transactions-collection-type-part.component';


@NgModule({
  declarations: [
    PmTransactionsListComponent, PmTransactionsPageComponent, PmTransactionsStornoPartComponent, PmTransactionsCollectionTypePartComponent
  ],
  imports: [
    CommonModule,
    PmTransactionsRoutingModule,
    TranslateModule,
    McComponentsModule,
    NgbTooltipModule,
    AppSharedModule,
    McConsumerModule,
    NgbDropdownModule,
  ]
})
export class PmTransactionsModule { }
