import {Inject, Injectable} from '@angular/core';
import {McProcessLevelServiceGENERATED} from '../_generated/mc-process-level-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McProcessLevelService extends McProcessLevelServiceGENERATED {

}
