/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingPayoutTableItemTable3ConfigGENERATED} from '../_generated/mc-billing-payout-table-item-table-3-config-generated.model';

// import {McBillingPayoutTableItemGENERATED} from '../_generated/mc-billing-payout-table-item-generated.model';

export class McBillingPayoutTableItemTable3Config extends McBillingPayoutTableItemTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colBatch', 'Batch', 'batch', 'batch');
    this.addColumn('colPercentage', 'Percentage', 'percentage', 'percentage');
    this.addColumn('colInitialSum', 'Initial sum', 'initialSum', 'initialSum');
    this.addColumn('colFinalSum', 'Final sum', 'finalSum', 'finalSum');


  }
}
