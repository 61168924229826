export abstract class BaseObjectFilter {
  public properties: string[] = [];

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = {};
    for (const property of this.properties) {
      // @ts-ignore
      dto[property] = this[property];
    }

    return dto;
  }

}


