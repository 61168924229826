/*BbmTestObj*/
import {McProcessRunGENERATED} from '../_generated/mc-process-run-generated.model';
import {McGod} from '../extra/mc-god.service';
import {McHtml} from '../_core/mc-html';
import {McDateUtils} from '../common/mc-date-utils';
import {ActionOptions} from '../_core/mc-html-action-options';

export class McProcessRun extends McProcessRunGENERATED {

  public static ACTION_VIEW_NEW_ITEMS = 'ACT_VIEW_NEW_ITEMS';
  public static ACTION_VIEW_READY = 'ACT_VIEW_READY';
  public static ACTION_VIEW_BUILT = 'ACT_VIEW_BUILT';
  public static ACTION_VIEW_DELIVERED = 'ACT_VIEW_DELIVERED';
  public static ACTION_VIEW_SKIPPED = 'ACT_VIEW_SKIPPED';
  public static ACTION_VIEW_ERROR = 'ACT_VIEW_ERROR';
  public static ACTION_SHOW_EDIT = 'ACT_SHOW_EDIT';
  public static ACTION_START_RUN = 'ACT_START_RUN';
  public static ACTION_PAUSE_RUN = 'ACT_PAUSE_RUN';
  // public static ACTION_CANCEL_RUN = 'ACT_CANCEL_RUN';
  // public static ACTION_CLEAR_RUN = 'ACT_CLEAR_RUN';
  public static ACTION_CLOSE_RUN = 'ACT_CLOSE_RUN';

  private _fldActions: McHtml | null = null;
  private _fldSkipped: McHtml | null = null;
  private _fldNewItems: McHtml | null = null;
  private _fldReady: McHtml | null = null;
  private _fldBuilt: McHtml | null = null;
  private _fldDelivered: McHtml | null = null;
  private _fldError: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessRun();
  }

  // ---------------------------------------------------------------------

  canStart(): boolean {
    return this.isStatusCdREADY()  || this.isStatusCdNEW();
  }
  canEdit(): boolean {
    return this.isStatusCdREADY()  || this.isStatusCdNEW();
  }
  canClose(): boolean {
    return this.isStatusCdREADY() ||  this.isStatusCdNEW() ||  this.isStatusCdERROR();
  }

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-pen mc-cursor-pointer"></i> Edit run`, McProcessRun.ACTION_SHOW_EDIT, this, [McGod.PERM_MC_PROCESS_RUN_EDIT], `${!this.canEdit() ? 'mc-disable-button' : ''}`, ActionOptions.OPTION_EDIT);
      actSet.addActionB(`<i class="fas mc-submit-color fa-play mc-cursor-pointer"></i> Start run`, McProcessRun.ACTION_START_RUN, this, [McGod.PERM_MC_PROCESS_RUN_MANAGE], `${!this.canStart() ? 'mc-disable-button' : ''}`, ActionOptions.OPTION_EDIT);
// TODO REMOVE      actSet.addActionB(`<i class="fas mc-submit-color fa-pause mc-cursor-pointer"></i> Pause run`, McProcessRun.ACTION_PAUSE_RUN, this, [McGod.PERM_MC_PROCESS_RUN_MANAGE], `${!this.isStatusCdRUN_STARTING() ? 'mc-disable-button' : ''}`);
      actSet.addActionB(`<i class="fas mc-submit-color fa-times mc-cursor-pointer"></i> Close run`, McProcessRun.ACTION_CLOSE_RUN, this, [McGod.PERM_MC_PROCESS_RUN_MANAGE], `${!this.canClose() ? 'mc-disable-button' : ''}`, ActionOptions.OPTION_EDIT);
      this._fldActions = html;
    }
    // actSet.addActionB(`<i class="fas mc-submit-color fa-broom mc-cursor-pointer"></i> Clear run`, McProcessRun.ACTION_CLEAR_RUN, this, [McGod.PERM_MC_PROCESS_RUN_MANAGE], ``);
    return this._fldActions.items;
  }

  fldNewItems() {
    if (!this._fldNewItems) {
      const html = new McHtml();
      html.addButtonB(`${this.newItemsCount}`, McProcessRun.ACTION_VIEW_NEW_ITEMS, this, [McGod.PERM_EB_FACTORING_OUT_BLACKLIST_VIEW], `mc-button-link`, ActionOptions.OPTION_VIEW); // ${this.newItemsCount > 0 ? '' : 'mc-disable'}
      this._fldNewItems = html;
    }
    return this._fldNewItems.items;
  }

  fldReady() {
    if (!this._fldReady) {
      const html = new McHtml();
      html.addButtonB(`${this.readyItemsCount}`, McProcessRun.ACTION_VIEW_READY, this, [McGod.PERM_EB_FACTORING_OUT_BLACKLIST_VIEW], 'mc-button-link', ActionOptions.OPTION_VIEW);
      this._fldReady = html;
    }
    return this._fldReady.items;
  }

  fldBuilt() {
    if (!this._fldBuilt) {
      const html = new McHtml();
      html.addButtonB(`${this.builtItemsCount}`, McProcessRun.ACTION_VIEW_BUILT, this, [McGod.PERM_EB_FACTORING_OUT_BLACKLIST_VIEW], 'mc-button-link', ActionOptions.OPTION_VIEW);
      this._fldBuilt = html;
    }
    return this._fldBuilt.items;
  }

  fldDelivered() {
    if (!this._fldDelivered) {
      const html = new McHtml();
      html.addButtonB(`${this.deliveredItemsCount}`, McProcessRun.ACTION_VIEW_DELIVERED, this, [McGod.PERM_EB_FACTORING_OUT_BLACKLIST_VIEW], 'mc-button-link', ActionOptions.OPTION_VIEW);
      this._fldDelivered = html;
    }
    return this._fldDelivered.items;
  }


  fldSkipped() {
    if (! this._fldSkipped) {
      const html = new McHtml();
      html.addButtonB(`${this.skippedItemsCount}`, McProcessRun.ACTION_VIEW_SKIPPED, this, [McGod.PERM_EB_FACTORING_OUT_BLACKLIST_VIEW], 'mc-button-link', ActionOptions.OPTION_VIEW);
      this._fldSkipped = html;
    }
    return this._fldSkipped.items;
  }

  fldError() {
    if (!this._fldError) {
      const html = new McHtml();
      html.addButtonB(`${this.errorItemsCount}`, McProcessRun.ACTION_VIEW_ERROR, this, [McGod.PERM_EB_FACTORING_OUT_BLACKLIST_VIEW], 'mc-button-link', ActionOptions.OPTION_VIEW);
      this._fldError = html;
    }
    return this._fldError.items;
  }

  fldRunNameId() {
    return 'Run #' + this.id;
  }

  getSysCreatedDatetimeString(): string {
    if (this.sysCreatedDatetime) {
      return McDateUtils.newFormatDateTimeString(this.sysCreatedDatetime);
    }
    return '';
  }
}
