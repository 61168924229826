import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {FormGroup, FormBuilder, Form} from "@angular/forms";
import {DomSanitizer} from "@angular/platform-browser";
import _moment from "moment";
import {NgbDateAdapter, NgbDateNativeAdapter} from "@ng-bootstrap/ng-bootstrap";
import {McInvoiceService, McUtilityService, McStateManagementService, McDateUtils, PpOrder} from "@miticon-ui/mc-core";
import {Router, ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Location} from "@angular/common";
import {Subscription} from "rxjs";

@Component({
  selector: "lib-invoice-step2",
  templateUrl: "./invoice-step2.component.html",
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class InvoiceStep2Component implements OnInit, OnDestroy {

  responseMessageDisplay!: boolean;
  notifyMsgType!: string;
  notifyMsgMessage!: string;
  scheduleTodaySendDate!: any;
  scheduleDueDate!: any;
  isScheduleSelected = false;
  selectedSchedule = false;
  selectedScheduleSendNow = false;
  selectedScheduleDelayed = false;
  isInvoiceSchedule = false;
  selectedScheduleDraft = false;
  hideSendDateField = false;
  minScheduleSendDate = {year: 0, month: 0, day: 0};
  minScheduleDueDate = {year: 0, month: 0, day: 0};
  maxScheduleDate = {year: 2100, month: 12, day: 31};
  tableContent: any;
  invoiceId!: number;
  idReceiverConsumer: number;
  isEditArea!: boolean;
  activeRoute;
  showLoader!: any;
  createdInvoiceList = [];
  servicePeriodFrom!: string;
  servicePeriodTo!: string;
  dataReceivedFromStep1: any;
  bankAccount!: any;
  isDisableCreate!: boolean;
  isDisableCreateAndAddNew!: boolean;
  isDisableUpdate!: boolean;
  isDisablePreview!: boolean;

  // edit area
  receivedInvoiceData: any;
  receivedInvoiceDataStatus!: string;
  receivedInvoiceDataSendDate: any;
  receivedInvoiceDataDueDate: any;
  idSenderMcEntity: any;

  invoicePreviewPdf: any;
  invoicePreviewFilename!: string;
  stepForm!: FormGroup;
  private invoiceProductListSubscription!: Subscription;

  @Input() parentForm!: FormGroup;
  @Input() orderId!: any;
  @Output() isDataFormInvalid = new EventEmitter<boolean>();

  @HostListener("window:resize")
  onResize() {
    this.stateManagementService.setCollapsedSidebar(window.innerWidth < 1600);
  }

  constructor(
    private fb: FormBuilder,
    private utilityService: McUtilityService,
    private invoiceService: McInvoiceService,
    private stateManagementService: McStateManagementService,
    private router: Router,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,
    private _location: Location
  ) {
    this.createForm();
    this.onResize()
    // check if invoice is for consumers or entities
    this.activeRoute = this.router.url.includes("consumers");
  }

  ngOnInit() {
    this.parentForm.setControl("step2", this.stepForm);

    // Create Invoice
    this.parentForm.get("dataFromStep1")?.valueChanges.subscribe(() => {
      this.dataReceivedFromStep1 = this.parentForm.get("dataFromStep1")?.value;

      this.invoiceId = this.dataReceivedFromStep1.invoiceId ? this.dataReceivedFromStep1.invoiceId : null;
      this.isEditArea = !!this.dataReceivedFromStep1.isEditArea;
      this.idReceiverConsumer = this.dataReceivedFromStep1.idReceiverCsrConsumer
      this.idSenderMcEntity = this.dataReceivedFromStep1.idSenderMcEntity

      if (this.dataReceivedFromStep1.servicePeriodTo === null) {
        this.dataReceivedFromStep1.servicePeriodTo = this.dataReceivedFromStep1.servicePeriodFrom;
      }

      this.servicePeriodFrom = _moment(this.dataReceivedFromStep1.servicePeriodFrom).format("ll");
      this.servicePeriodTo = _moment(this.dataReceivedFromStep1.servicePeriodTo).format("ll");

      if (this.dataReceivedFromStep1.orderItems) {
        this.showLoader = true;
        this.tableContent = Object.assign({}, {
          content: [...this.dataReceivedFromStep1.orderItems],
          discount: this.dataReceivedFromStep1.discount,
          _name: "invoice_summary",
          isLoaded: true
        });
        this.showLoader = false;
      }
    });

    this.invoiceProductListSubscription = this.invoiceService.invoiceList.subscribe(list => {
      if (this.tableContent && this.tableContent.hasOwnProperty("content"))
        this.tableContent.content = list;
    });

    // Edit Invoice

    if (this.parentForm.get("receivedData")) {
      this.parentForm.get("receivedData")?.valueChanges.subscribe(() => {
        this.receivedInvoiceData = this.parentForm.get("receivedData")?.value;

        this.isEditArea = true;

        this.receivedInvoiceDataStatus = this.receivedInvoiceData.state;
        this.receivedInvoiceDataSendDate = this.receivedInvoiceData.sendDate;
        this.receivedInvoiceDataDueDate = this.receivedInvoiceData.dueDate;
        this.setScheduleStatus(this.receivedInvoiceData.state);

      });
    }

    this.scheduleTodaySendDate = "";
    this.scheduleDueDate = "";
    this.responseMessageDisplay = false;

  }

  setScheduleStatus(status: string) {
    if (status === "SCHEDULED") {
      this.setMinimumDates();
      const sendDateValue = this.receivedInvoiceDataSendDate === null ? "" : this.receivedInvoiceDataSendDate;
      const dueDateValue = this.receivedInvoiceDataDueDate === null ? "" : this.receivedInvoiceDataDueDate;
      this.isInvoiceSchedule = true;
      this.selectedSchedule = true;
      this.isScheduleSelected = true;
      this.selectedScheduleDelayed = true;
      this.stepForm.get("scheduleState")?.setValue("SCHEDULED");
      this.stepForm.get("sendDate")?.setValue(sendDateValue);
      this.stepForm.get("dueDate")?.setValue(dueDateValue);
      this.scheduleTodaySendDate = _moment(sendDateValue).format("lll");
      this.scheduleDueDate = _moment(dueDateValue).format("ll");
    } else {
      this.stepForm.get("scheduleState")?.setValue("DRAFT");
    }
  }

  setMinimumDates() {
    const date = new Date();

    this.minScheduleSendDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };

    this.minScheduleDueDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate() + 1
    };
  }

  createForm() {
    this.stepForm = this.fb.group({
      scheduleState: ["DRAFT"],
      sendDatePicker: [""],
      sendTimePicker: ["06:00"],
      sendDate: [""],
      dueDate: [""],
      changeDueDateDate: [""]
    });
  }

  changeSchedule(e: any) {
    const form = this.stepForm;
    const el = (e.target as HTMLInputElement).id;
    this.setMinimumDates();
    form.get("sendDate")?.reset();
    form.get("dueDate")?.reset();
    form.get("sendDatePicker")?.reset();
    form.get("sendTimePicker")?.setValue("06:00");
    form.get("changeDueDateDate")?.reset();
    this.scheduleTodaySendDate = "";
    this.scheduleDueDate = "";
    this.responseMessageDisplay = false;
    switch (el) {
      case "saveAsDraft":
        this.hideSendDateField = true;
        this.selectedSchedule = false;
        this.selectedScheduleSendNow = false;
        this.selectedScheduleDelayed = false;
        this.selectedScheduleDraft = true;
        this.isScheduleSelected = false;
        form.get("scheduleState")?.setValue("DRAFT");
        break;
      case "print":
        this.hideSendDateField = true;
        this.selectedSchedule = false;
        this.selectedScheduleSendNow = false;
        this.selectedScheduleDelayed = false;
        this.selectedScheduleDraft = false;
        this.isScheduleSelected = false;
        form.get("scheduleState")?.setValue("PRINT");
        break;
      case "scheduleSendNow":
        this.hideSendDateField = true;
        this.isInvoiceSchedule = false;
        this.selectedSchedule = true;
        this.selectedScheduleSendNow = true;
        this.selectedScheduleDelayed = false;
        this.selectedScheduleDraft = false;
        this.isScheduleSelected = true;
        const predefinedDueDate = new Date();
        predefinedDueDate.setDate(new Date().getDate() + 14);
        this.scheduleDueDate = _moment(predefinedDueDate).format("ll");
        form.get("dueDate")?.setValue(predefinedDueDate);
        form.get("scheduleState")?.setValue("SEND_NOW");
        break;
      case "scheduleSendDelayed":
        this.hideSendDateField = false;
        this.selectedSchedule = true;
        this.selectedScheduleSendNow = false;
        this.selectedScheduleDelayed = true;
        this.selectedScheduleDraft = false;
        this.isScheduleSelected = true;
        form.get("scheduleState")?.setValue("SCHEDULED");
        if (this.isEditArea) {
          this.isInvoiceSchedule = true;
          this.scheduleTodaySendDate = (this.receivedInvoiceDataSendDate === null || this.receivedInvoiceDataSendDate === "") ?  "-" : _moment(this.receivedInvoiceDataSendDate).format("lll");
          this.scheduleDueDate = (this.receivedInvoiceDataDueDate === null || this.receivedInvoiceDataDueDate === "") ? "" : _moment(this.receivedInvoiceDataDueDate).format("ll");
        } else {
          this.isInvoiceSchedule = false;
          this.scheduleTodaySendDate = "";
          this.scheduleDueDate = "";
        }
        break;
    }
  }

  fullDate() {
    this.isDisableCreate = false;
    this.isDisableCreateAndAddNew = false;
    this.isDisableUpdate = false;
    const form = this.stepForm;
    const date = form.get("sendDatePicker")?.value;
    const time = form.get("sendTimePicker")?.value;
    this.responseMessageDisplay = false;

    if (date && time) {
      // this.scheduleDueDate = "";
      const selectedScheduleSendDate = new Date(_moment(_moment(date).format("ll") + " " + time).format());
      const selectedScheduledDueDate = new Date();
      selectedScheduledDueDate.setDate(new Date(selectedScheduleSendDate).getDate() + 14);
      this.scheduleTodaySendDate = _moment(selectedScheduleSendDate).format("lll");
      if (form.get("dueDate")?.value === null) {
        this.scheduleDueDate = _moment(selectedScheduledDueDate).format("ll");
      }
      form.get("sendDate")?.setValue(selectedScheduleSendDate);
      if (form.get("changeDueDateDate")?.value === null || form.get("changeDueDateDate")?.value === "") {
        form.get("dueDate")?.setValue(selectedScheduledDueDate);
        this.scheduleDueDate = _moment(selectedScheduledDueDate).format("ll");
      }
    } else if (date === null && time === "") {
      form.get("sendDate")?.setValue(null);
      this.scheduleTodaySendDate = "";
      this.scheduleDueDate = "";
      form.get("changeDueDateDate")?.setValue(null);
    }
  }

  changeDueDate() {
    this.responseMessageDisplay = false;
    const form = this.stepForm;
    const selectChangedDueDate = form.get("changeDueDateDate")?.value;
    form.get("dueDate")?.setValue(selectChangedDueDate);
    this.scheduleDueDate = selectChangedDueDate === null ? "" : _moment(new Date(selectChangedDueDate)).format("ll");
    this.isDisableCreate = false;
    this.isDisableCreateAndAddNew = false;
    this.isDisableUpdate = false;
  }

  onReceiveErrorFromChild(error: any) {
    if (error) {
      this.responseMessageDisplay = true;
      this.setNotificationMessage(error.message, error.type);
    }
  }

  invoicePreview() {
    this.setNotificationMessage("Please wait until your invoice is loading", "error");
    this.showLoader = true;
    this.isDisablePreview = true;
    const dataForSend = this.createInvoiceDataObject();

    this.invoiceService.invoiceGeneratePreview(dataForSend).subscribe(
      response => {
        this.setNotificationMessage("", "");
        this.isDisablePreview = false;
        this.invoicePreviewPdf = "";
        this.invoicePreviewFilename = "";
        const blob = new Blob([response], {type: 'application/pdf'});
        const url = URL.createObjectURL(blob);
        const newWindow = window.open(url, '_blank');
        newWindow?.document.write(`<html><head><title>Invoice</title></head><body>
             <embed width="100%" height="100%" name="plugin" src="${url}"
            type="application/pdf" internalinstanceid="21"></body></html>`);
        this.showLoader = false;
      },
      error => {
        this.isDisablePreview = false;
        this.responseMessageDisplay = true;
        this.apiErrorHandler(error);
        this.showLoader = false;
      }
    );
  }

  createInvoiceDataObject() {
    const stepForm = this.stepForm.getRawValue();
    const scheduleState = stepForm.scheduleState;
    const sendingDate = (stepForm.sendDate === null || stepForm.sendDate === "") ? null : _moment(stepForm.sendDate).format();
    const dueDate = (stepForm.dueDate === null || stepForm.dueDate === "") ? null : _moment(stepForm.dueDate).format();
    let receivedData: any;

    if (this.isEditArea) {
      receivedData = this.receivedInvoiceData;
      receivedData.sendingDate = sendingDate;
      receivedData.dueDate = dueDate;
      receivedData.state = scheduleState;
      receivedData.orderItems = this.stateManagementService.invoiceData?.orderItems;
      receivedData.excludeDiscountedItems = !!this.stateManagementService.invoiceData?.excludeDiscountedItems;
      receivedData.discount = this.stateManagementService.invoiceData?.discount;
      receivedData.servicePeriodFrom = this.dataReceivedFromStep1.servicePeriodFrom;
      receivedData.servicePeriodTo = this.dataReceivedFromStep1.servicePeriodTo;
      receivedData.idReceiverCsrConsumer = this.idReceiverConsumer
      receivedData.idSenderMcEntity = this.idSenderMcEntity
      receivedData.typeCd = this.receivedInvoiceData.typeCd ? this.receivedInvoiceData.typeCd : PpOrder.TYPE_CD_OTHER;

      if (this.bankAccount) {
        receivedData.idMcBankAccount = this.bankAccount;
      } else {
        receivedData.idMcBankAccount = this.receivedInvoiceData.chosenBankAccount.id;
      }

      return receivedData;
    } else {
      this.dataReceivedFromStep1.idMcBankAccount = this.bankAccount;
      receivedData = this.dataReceivedFromStep1;
      receivedData.sendingDate = sendingDate;
      receivedData.dueDate = dueDate;
      receivedData.state = scheduleState;
      receivedData.idReceiverCsrConsumer = this.idReceiverConsumer
      receivedData.idSenderMcEntity = this.idSenderMcEntity
      receivedData.typeCd = this.dataReceivedFromStep1.typeCd ? this.dataReceivedFromStep1.typeCd : PpOrder.TYPE_CD_OTHER;

      return receivedData;
    }
  }

  createInvoice(buttonId: number) {
    this.setNotificationMessage("Please wait. Your invoice is being created", "error");
    this.showLoader = true;
    if (buttonId === 1) {
      this.isDisableCreate = true;
    } else {
      this.isDisableCreateAndAddNew = true;
    }
    const stepForm = this.stepForm.getRawValue();
    const scheduledState = stepForm.scheduleState;
    const scheduledSendDate = stepForm.sendDate;
    const scheduledDueDate = stepForm.dueDate;
    const dataForSend = this.createInvoiceDataObject();

    if (scheduledState === 'SCHEDULED' && McDateUtils.formatDateToTimestamp(scheduledSendDate) < McDateUtils.getNowTimestamp()) {
      this.showLoader = false;
      this.setNotificationMessage("Scheduled date can't be before current date.", "error");
      this.responseMessageDisplay = true;
      return;
    }
    if (scheduledState === 'SEND_NOW' && McDateUtils.formatDateToTimestamp(scheduledDueDate) < McDateUtils.getNowTimestamp()) {
      this.showLoader = false;
      this.setNotificationMessage("Due date can't be before current date.", "error");
      this.responseMessageDisplay = true;
      return;
    }
    if (scheduledState === "SCHEDULED" && (scheduledSendDate === null || scheduledDueDate === null)) {
      this.showLoader = false;
      this.setNotificationMessage("Please set Send and Due date", "error");
      this.responseMessageDisplay = true;
      return;
    }

    if (scheduledState === "SCHEDULED" && !_moment(stepForm.dueDate).isAfter(stepForm.sendDate)) {
      this.showLoader = false;
      this.setNotificationMessage("Due date can't be before Send date.", "error");
      this.responseMessageDisplay = true;
      return;
    }
    this.invoiceService.createInvoice(dataForSend).subscribe(
      response => {
        this.setNotificationMessage("", "");
        this.showLoader = false;
        if (buttonId === 1) {
          this.isDisableCreate = false;
          const entityId: number = this.utilityService.getPropertyFromToken("entity_id") - 0;

          this.invoiceService.addCreatedInvoiceToList(this.createdInvoiceList);

          this.router.navigateByUrl(`admin/entity/${entityId}/management/invoices/consumers/${this.idReceiverConsumer}`);

          this.goToStep(1);
          this.invoiceService.clearInvoiceList();
        } else if (buttonId === 2) {
          this.isDisableCreateAndAddNew = false;
          this.goToStep(1);
          this.parentForm.get("resetServicePeriodDates")?.setValue(true);
          this.invoiceService.clearInvoiceList();
          this.userDataFormInvalid(true);
        }
      },
      error => {
        this.showLoader = false;
        this.isDisableCreate = false;
        this.isDisableCreateAndAddNew = false;
        this.responseMessageDisplay = true;
        this.apiErrorHandler(error);
        return;
    });
  }

  updateInvoice() {
    this.setNotificationMessage("Please wait. Your invoice is being created", "error");
    this.showLoader = true;
    this.isDisableUpdate = true;
    const dataForSend = this.createInvoiceDataObject();

    if (dataForSend.state === "SCHEDULED" && (dataForSend.sendDate === null || dataForSend.dueDate === null)) {
      this.showLoader = false;
      this.setNotificationMessage("Please set Send and Due date", "error");
      this.responseMessageDisplay = true;
      return;
    }

    if (dataForSend.state === "SCHEDULED" && !_moment(dataForSend.dueDate).isAfter(dataForSend.sendDate)) {
      this.showLoader = false;
      this.setNotificationMessage("Due date can't be before Send date.", "error");
      this.responseMessageDisplay = true;
      return;
    }

    this.invoiceService.updateOrderList(this.invoiceId, dataForSend).subscribe(
      response => {
        this.invoiceService.setLastCreatedInvoice(this.invoiceId);
        this.setNotificationMessage("", "");
        this.isDisableUpdate = false;
        const entityId: number = this.utilityService.getPropertyFromToken("entity_id") - 0;

        this.invoiceService.addCreatedInvoiceToList(this.createdInvoiceList);
        this.router.navigateByUrl(`admin/entity/${entityId}/management/invoices/consumers/${this.idReceiverConsumer}`);
        this.goToStep(1);
        this.invoiceService.clearInvoiceList();
        this.showLoader = false;
      },
      error => {
        this.showLoader = false;
        this.isDisableUpdate = false;
        this.responseMessageDisplay = true;
        this.apiErrorHandler(error);
      });
  }

  goToPrevious() {
    this._location.back();
    this.utilityService.setStepNumber(1);
  }

  /*Go to step*/
  goToStep(step: any) {
    this.utilityService.setStepNumber(step);
  }

  // Api ErrorHandler
  private apiErrorHandler(error: any) {
    let message = "";
    let msgType = "";
    if (error.status >= 500) {
      message = "Server error";
      msgType = "error";
    } else {
      message = "An error has occurred!";
      msgType = "error";
    }
    this.responseMessageDisplay = true;
    this.setNotificationMessage(message, msgType);
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.notifyMsgMessage = message;
    this.notifyMsgType = type;
  }

  // Unlock Next btn
  userDataFormInvalid(data: boolean) {
    this.isDataFormInvalid.emit(data);
  }

  ngOnDestroy() {
    this.invoiceProductListSubscription.unsubscribe();
  }



}


