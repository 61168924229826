/*BbmTestObj*/
import {CsrReccuringOptionsGENERATED} from '../_generated/csr-reccuring-options-generated.model';

export class CsrReccuringOptions extends CsrReccuringOptionsGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrReccuringOptions();
  }

  // ---------------------------------------------------------------------
}
