/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {ZzPocCarFilter} from '../models/zz-poc-car-filter.model';

export class ZzPocCarFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): ZzPocCarFilter {
    console.error('MTCN-ERROR: Not Implemented: ZzPocCarFilter::createNewInstance(). Add this method to final class and return new ZzPocCarFilter();');
      throw new Error('Cannot ZzPocCarFilter::createNewInstance(). Add this method to final class and return new ZzPocCarFilter();');
  }

}
