/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
/* Using "any" data-type in returned observables to avoid importing   */
/* ItemClass and ItemListClass and circular dependencies.             */
/* ------------------------------------------------------------------ */
import {BaseObjectService} from '../_core/base-object-service';
import {IMcBillingServiceCatalog} from '../_generated/mc-billing-service-catalog.interface';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';
// import {McBillingServiceCatalog} from '../models/mc-billing-service-catalog.model';
// import {McBillingServiceCatalogList} from '../models/mc-billing-service-catalog-list.model';
// import {McBillingServiceCatalogFilter} from '../models/mc-billing-service-catalog-filter.model';
import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class McBillingServiceCatalogServiceGENERATED extends BaseObjectService {
protected apiServiceUrlSubPath = "/mcbilling/v3/mcbillingservicecatalog";

public getApiServiceRootUrl(): string {
    return this.apiUrl + this.apiServiceUrlSubPath;
  }

  /* Get all  from DB */
public getAll(aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/all?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  /* Get all  from DB without pager */
public getByFilter(aFilter: any, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/filter?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, aFilter.toDto(), {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

/* Get by id from DB */
public getById(id: number): Observable<IMcBillingServiceCatalog> {
  return this.httpClient.get<IMcBillingServiceCatalog>(this.getApiServiceRootUrl() + `/${id}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

// Insert Object
public insert(aMcBillingServiceCatalog: any): Observable<IMcBillingServiceCatalog> {
    return this.httpClient.post<IMcBillingServiceCatalog>(this.getApiServiceRootUrl()  , aMcBillingServiceCatalog.toDto(), {headers: this.httpOptions});
}
// Update object
public update(aMcBillingServiceCatalog: any): Observable<IMcBillingServiceCatalog> {
    return this.httpClient.put<IMcBillingServiceCatalog>(this.getApiServiceRootUrl() + `/`+ aMcBillingServiceCatalog.id, aMcBillingServiceCatalog.toDto(), {headers: this.httpOptions});
}


// DeleteAllFromDB
public deleteAllFromDb() {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/deleteAllFromDb`, {headers: this.httpOptions});
  }

  /* Get by id from DB */
public deleteById(id: number) {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/${id}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });

  }

  // -- CUSTOM METHODS --
  
}
