import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  McAuthService,
  McEntityService,
  McStateManagementService,
  McCreateUserService,
  McGod,
  McDashboardService, BaseDirective
} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import { ICON_DROP_DOWN } from '../../../assets/media/svg_icons/icon-drop-down';
import { ICON_DROP_UP } from '../../../assets/media/svg_icons/icon-drop-up';
import { ICON_LOGOUT } from '../../../assets/media/svg_icons/icon-logout';
import { ICON_COLLAPSE } from '../../../assets/media/svg_icons/icon-collapse';
import { ICON_LOGO } from '../../../assets/media/svg_icons/icon-logo';
import { ICON_EXPAND } from '../../../assets/media/svg_icons/icon-expand';

@Component({
  selector: 'mc-header',
  templateUrl: './mc-header.component.html'
})
export class McHeaderComponent extends BaseDirective implements OnInit, OnDestroy {

  logoutIcon: string;
  logo: string;
  selectedEntityFullPathTooltip = '';
  selectedEntity!: any;
  listEntitiesFromDB!: any;
  selectedEntityParentList: any[] = [];
  userInfo!: any;
  username = '';
  languagesList = [];
  selectedLanguage!: string;
  isSidebarCollapsed!: boolean;
  mcGod = McGod.getInstance();
  softwareVersion!: string;
  panelOpenState: boolean = false;
  dropDownIcon = ICON_DROP_DOWN;
  dropUpIcon = ICON_DROP_UP;
  iconLogout = ICON_LOGOUT;
  iconCollapse = ICON_COLLAPSE;
  iconLogo = ICON_LOGO;
  iconExpande = ICON_EXPAND;

  paramsSubscription!: Subscription;
  entitySwitchedSubscription!: Subscription;
  getNewEntityListSubscription!: Subscription;
  apiSubscription!: Subscription;
  entityIdFromRoute!: number;
  designConfig: any;
  mainLogo: any;
  @Input() set user(value: any) {
    if (value) {
      this.userInfo = value;
      this.username = value.nickname ? value.nickname : value.firstname + " " + value.lastname;
    }
  };
  @Input() set isCollapsed(isCollapsed: any) {
    this.isSidebarCollapsed = isCollapsed;
  };
  @Input() set setLanguage(language: any) {
    this.selectedLanguage = language || 'en';
  }

  @Output() onSelectedEntity = new EventEmitter();
  @Output() onGetAllEntities = new EventEmitter();
  @Output() onSidebarCollapse = new EventEmitter();


  /*Constructor*/
  constructor(
    private activatedRoute: ActivatedRoute,
    private entityService: McEntityService,
    private authService: McAuthService,
    private userService: McCreateUserService,
    private stateManagement: McStateManagementService,
    private translate: TranslateService,
    private router: Router,
    private dashboardService: McDashboardService,
    @Inject('designConfig') design: any,
    @Inject('utilityConfig') utility: any
  ) {
    super();
    this.logo = design.logoOnly;
    this.logoutIcon = design.logoutIcon;
    this.languagesList = utility.languages;
    this.designConfig = design;
  }

  ngOnInit() {
    this.paramsSubscription = this.activatedRoute.paramMap.subscribe(data => {
      this.entityIdFromRoute = Number(data.get('id'));
      this.getEntitiesFromDB(this.entityIdFromRoute);
    });

    this.getLoggedEntity();
    this.entitySwitchedSubscription = this.stateManagement.isSwitchedEntity.subscribe(boo => boo && this.getLoggedEntity());
    this.getNewEntityListSubscription = this.stateManagement.openSelectEntityModal.subscribe(data => data && this.getEntitiesFromDB(this.entityIdFromRoute));

    this.dashboardService.getSoftwareVersion().pipe(takeUntil(this.destroyed$)).subscribe(response => {
        this.softwareVersion = response.swVersion.replace(/[A-Za-z-]/g, '');
      });
  }

  /*Logout*/
  logout() {
    this.authService.logout();
  }

  collapseSidebar() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed
    this.onSidebarCollapse.emit(this.isSidebarCollapsed);
  }

  /*Get entities from DB
  * and set selected entity from params ID*/
  private getEntitiesFromDB(id: any) {
    if (id) {
      this.entityService.getEntitiesFromDB()
        .subscribe(response => {
          this.listEntitiesFromDB = response;
          this.stateManagement.setListEntities(response);
          this.onGetAllEntities.emit(this.listEntitiesFromDB);
          /*Find default entity*/
          this.selectedEntity = this.listEntitiesFromDB.find((item: any) => {
            if (item.id === id) {
              this.selectedEntityParentList = [];
              this.selectedEntityFullPathTooltip = "";
              this.handleEntityPath(item);
              this.entityService.setSelectedEntityPath([...this.selectedEntityParentList, item.name]);
              this.selectedEntityParentList.forEach((name, index) => {
                this.selectedEntityFullPathTooltip += name + " > " + (this.selectedEntityParentList.length - 1 === index ? item.name : "");
              });
              this.onSelectedEntity.emit(item);
              return item;
            }
          });
        }, error => console.log("This is API error...", error))
        .add(() => this.stateManagement.setOpenSelectEntityModal(false));
    }
  }


  openModal() {
    this.getEntitiesFromDB(this.entityIdFromRoute);
  }

  handleEntityPath(entity: any): void {
    if (entity.parentMcEntityId) {
      this.listEntitiesFromDB.map((item: any) => {
        if (entity.parentMcEntityId === item.id) {
          this.selectedEntityParentList.unshift(item.name);
          this.handleEntityPath(item);
        }
      });
    }
  }

  onChooseLanguage(language: any) {
    if (this.selectedLanguage !== language.toLowerCase()) {
      this.userService.setUserLanguage({isoLanguage: language.toLowerCase()})
        .subscribe(response => {
          this.selectedLanguage = language;
          this.translate.currentLang = language;
          this.translate.use(language);
          this.router.navigateByUrl('/');
        }, error => {
        });
    }
  }

  public getLoggedEntity(): void {
    this.entityService.getLoggedinEntity()
      .subscribe(response => {
        this.mainLogo = response.logo || this.designConfig.noLogo;
      }, error => this.mainLogo = this.designConfig.logo);
  }


  actPersonalInfoClick() {
    this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/users/profile/${McGod.getLoggedUserIdFromToken()}`);
  }
}
