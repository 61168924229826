import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'mc-user-popup-buttons',
  templateUrl: './mc-user-popup-buttons.component.html',
})
export class McUserPopupButtonsComponent implements OnInit {

  /*Constructor*/
  constructor() { }

  /*On init class*/
  ngOnInit() {
  }
}
