import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {
  EbFactoring,
  EbSepaFileTransaction,
  EbSepaFileTransactionList,
  McConsumer,
  McDateUtils,
  McForm,
  McGod
} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-change-collection-date-part',
  templateUrl: './app-shared-change-collection-date-part.component.html',
  styleUrls: ['./app-shared-change-collection-date-part.scss']
})
export class AppSharedChangeCollectionDatePartComponent implements OnInit {
  public mcForm = new McForm();

  /*Variables*/
  minimalNewCollectionDate!: number;
  maxCollectionDate!: number;
  isSingleTransactionSelected!: boolean;
  allowedDaysForFutureCount!: number;
  minimalPaymentDueDate!: number;
  collectionDate!: number;
  mandanteDates = [];
  minimalMandanteDate!: number;
  transactionFactoring = new EbFactoring();
  factoringId!: number;
  @Input() entityId!: number;
  @Input() ebSepaFileTransaction = new EbSepaFileTransaction();
  @Input() selectedTransactionList = new EbSepaFileTransactionList();
  @Input() selectedTransactionsNumber!: number;
  @Input() ebFactoring = new EbFactoring();
  mcConsumer = new McConsumer();

  @Output() eventCollectionDateCanceled = new EventEmitter();
  @Output() eventCollectionDateChanged = new EventEmitter();
  @Output() eventIsNonFactoring = new EventEmitter<boolean>();
  notifyMessage!: string;
  showLoader!: boolean;
  buttonDisabled!: boolean;
  maximalPaymentDueDate!: number;

  ngOnInit() {
    this.manageCollectionDates();
  }


  manageCollectionDates() {
    this.showLoader = true;
    this.notifyMessage = '';
    if (this.selectedTransactionsNumber === 1) {
      this.selectedTransactionList = new EbSepaFileTransactionList();
      this.isSingleTransactionSelected = true;
      this.mcConsumer.loadById(this.ebSepaFileTransaction.idCsrConsumer, () => {
        if (this.ebSepaFileTransaction.dueDatetime && this.mcConsumer.sepaMandanteDate) {
          if (this.ebSepaFileTransaction.dueDatetime < this.mcConsumer.sepaMandanteDate) {
            this.collectionDate = this.mcConsumer.sepaMandanteDate;
          } else {
            this.collectionDate = this.ebSepaFileTransaction.dueDatetime;
          }
          this.factoringId = this.ebSepaFileTransaction.idEbFactoring;
          this.transactionFactoring.loadById(this.factoringId, () => {
            this.allowedDaysForFutureCount = this.transactionFactoring.monthsAllowedFutureCount * 30;
            const dueDateTime = new Date(this.ebSepaFileTransaction.dueDatetime);
            this.maxCollectionDate = dueDateTime.setDate(dueDateTime.getDate() + this.allowedDaysForFutureCount);
            this.showLoader = false;
            if ((new Date(this.minimalNewCollectionDate)) > new Date(this.maxCollectionDate)) {
              this.notifyMessage = McGod.t('cc.transactions.you-cannot-change-collection-date-s-because-maximal-possible-collection-date-needs-to-be-equal-or-bigger-than-minimal-possible-collection-date');
              this.buttonDisabled = true;
            }
          });
          this.minimalNewCollectionDate = this.collectionDate;
          this.selectedTransactionList.items.push(this.ebSepaFileTransaction);
        } else {
          this.notifyMessage = 'The transaction\'s RCD cannot be changed because the consumer doesn\'t have mandanten date. Please update the consumer.';
          this.buttonDisabled = true;
          this.showLoader = false;
        }
      });
    } else if (this.selectedTransactionsNumber > 1) {
      this.isSingleTransactionSelected = false;
      const transactions = this.selectedTransactionList.items;
      const nonFactoringTransactions = transactions.filter(item => item.nonFactoringFlg);
      let transactionWithMinPdd = new EbSepaFileTransaction();
      const dueDates: number[] = [];
      const mandanteDates: number[] = [];
      nonFactoringTransactions.forEach( trans => {
        this.mcConsumer.loadById(trans.idCsrConsumer, () => {
          mandanteDates.push(this.mcConsumer.sepaMandanteDate);
          nonFactoringTransactions.forEach(trans1 => {
            dueDates.push(trans1.dueDatetime);
          });
          this.minimalMandanteDate = mandanteDates.reduce( (a, b) => {
            return a > b ? a : b;
          });
          this.minimalPaymentDueDate = dueDates.reduce((a, b) => {
            return a < b ? a : b;
          });
          this.maximalPaymentDueDate = dueDates.reduce( (a, b) => {
            return a > b ? a : b;
          });
          if (this.minimalMandanteDate > this.maximalPaymentDueDate) {
            this.minimalNewCollectionDate = this.minimalMandanteDate;
          } else {
            this.minimalNewCollectionDate = this.maximalPaymentDueDate;
          }
          nonFactoringTransactions.filter(transaction => {
            if (transaction.dueDatetime === this.minimalPaymentDueDate) {
              transactionWithMinPdd = transaction;
            }
          });
          this.transactionFactoring.loadById(transactionWithMinPdd.idEbFactoring, () => {
            this.showLoader = false;
            this.allowedDaysForFutureCount = this.transactionFactoring.monthsAllowedFutureCount * 30;
            const dueDateTimeNew = new Date(this.minimalPaymentDueDate);
            this.maxCollectionDate = dueDateTimeNew.setDate(dueDateTimeNew.getDate() + this.allowedDaysForFutureCount);
            if (McDateUtils.formatDateToTimestamp(McDateUtils.formatDateToString(this.maxCollectionDate)) < McDateUtils.formatDateToTimestamp(McDateUtils.formatDateToString(this.minimalNewCollectionDate))) {
              this.notifyMessage = McGod.t('cc.transactions.you-cannot-change-collection-date-s-because-maximal-possible-collection-date-needs-to-be-equal-or-bigger-than-minimal-possible-collection-date');
              this.buttonDisabled = true;
            }
          });
        });
      });

    }
  }
  eventCanceled() {
    this.selectedTransactionsNumber = 0;
    this.eventCollectionDateCanceled.emit();
  }

  actChange() {
    if (this.mcForm.isValid()) {
      const newCollectionDate = this.mcForm.getValue('ctrlNewCollectionDate');
      const reasonForChangingCollDate = this.mcForm.getValue('ctrlReason');
      this.ebSepaFileTransaction.callChangeRequestedCollectionDate(this.selectedTransactionList.getIds(), newCollectionDate, reasonForChangingCollDate, () => {
        this.eventCollectionDateChanged.emit();
      }, () => {
        this.notifyMessage = 'You can change collection date only for non-paused transactions with status "Accepted"';
      });
    }
  }

  onKeyPressDatepicker(event: KeyboardEvent) {
      return false;
  }
}
