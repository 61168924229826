import {Component, Input, Output, OnInit, EventEmitter, ChangeDetectorRef, Inject} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {McUtilityService, McEntityService, McPriceList, McGod, McPriceList2EntityService} from "@miticon-ui/mc-core";
import {LoginEnums} from "@miticon-ui/mc-core";

@Component({
  selector: "mc-entity-summary-data",
  templateUrl: "./mc-entity-summary-data.component.html"
})
export class McEntitySummaryDataComponent implements OnInit {

  // Variables
  entitySummaryForm: FormGroup;
  allRawData: any;
  message!: any;
  type!: any;
  customAttributeList!: any;
  newObj!: any;
  newArray: any[] = [];
  showLoader!: boolean;
  attributesIcons;
  attributesInfoIcon;
  attributesWhiteInfoIcon;
  hoveredIcon!: any;

  destroyed$ = new Subject<void>();

  // Forms
  @Input() parentForm!: FormGroup;
  @Input() imagePath!: any;
  @Input() pricelist!: McPriceList;
  // Outputs
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() updateEntityData = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private utilityService: McUtilityService,
    private entityService: McEntityService,
    private mcPriceList2EntityService: McPriceList2EntityService,
    @Inject("designConfig") design: any
  ) {
    this.entitySummaryForm = new FormGroup({});

    // import attribute icons
    this.attributesIcons = design.attributesIcons;
    this.attributesInfoIcon = this.attributesIcons.info.path;
    this.attributesWhiteInfoIcon = this.attributesIcons.whiteInfo.path;
  }

  ngOnInit() {
    // set formValues
    this.createForm();
    // Forward child entitySummary form to the parent one
    this.parentForm.setControl("step5", this.entitySummaryForm);
    this.allRawData = this.parentForm.getRawValue();

    this.parentForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.allRawData = this.parentForm.getRawValue();
        this.cd.markForCheck();
      });
  }

  // Create form
  createForm() {
    this.entitySummaryForm = this.fb.group({
      sendInvite: [true]
    });
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  /*Go to step*/
  goToStep(step: any) {
    this.utilityService.setStepNumber(step);
  }

  // Create new entity
  createEntity(createAndAddRoles: any) {
    this.showLoader = true;
    this.newArray = [];
    let msgText;
    let msgType;
    const parentFormData = this.allRawData;
    // Multiple array
    let filteredInheritedAttributes;
    let filteredLocalAttributes;
    if (parentFormData.step2.inheritAttributes) {
      filteredInheritedAttributes = parentFormData.step2.inheritAttributes.filter((item: any) => {
        item.inherited = true;
        return item;
      });
    }
    if (parentFormData.step2.localAttributes) {
      filteredLocalAttributes = parentFormData.step2.localAttributes.filter((item: any) => {
        item.inherited = false;
        return item;
      });
    }
    if (filteredLocalAttributes && filteredInheritedAttributes) {
      this.customAttributeList = [...filteredLocalAttributes, ...filteredInheritedAttributes];
    }
    const attributeList = this.customAttributeList;
    if (attributeList) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < attributeList.length; i += 1) {
        let attributeType;
        switch (attributeList[i].type) {
          case "Number":
            attributeType = "INTEGER";
            break;
          case "String":
            attributeType = "VARCHAR";
            break;
          case "Date Time":
            attributeType = "DATETIME";
            break;
          case "Date":
            attributeType = "DATE";
            break;
          case "Decimal":
            attributeType = "FLOAT";
            break;
          case "Yes/No":
            attributeType = "BOOLEAN";
            break;
          default:
            attributeType = attributeList[i].type;
        }
        this.newObj = {
          name: attributeList[i].name,
          description: attributeList[i].description,
          type : attributeType,
          length: attributeList[i].length,
          defaultValue: attributeList[i].defaultValue === "" ? null : attributeList[i].defaultValue,
          enabled: attributeList[i].enabled,
          required: attributeList[i].required,
          archived: false,
          validationRegex: attributeList[i].validationRegex,
          inherited: attributeList[i].inherited,
          inheritable: attributeList[i].inheritable
        };
        this.newArray = [...this.newArray, this.newObj];
      }
    }

    // @ts-ignore
    if (this.pricelist) {
      const body = {
        // backend needs this fields for complete the request
        isVisibleToChildren: true,
        mailPrefix: "",
        // basic info values
        active: parentFormData.step1.status,
        imageData: this.imagePath,
        name: parentFormData.step1.name,
        mailDomain: parentFormData.step1.mail,
        abbreviation: parentFormData.step1.abbreviation,
        webDomain: parentFormData.step1.webDomain,
        description: parentFormData.step1.description || null,
        ipWhitelist: parentFormData.step1.IP || null,
        phone: parentFormData.step1.phoneNumber || null,
        bankAccount: null,
        fax: parentFormData.step1.faxNumber || null,
        euVat: parentFormData.step1.vatNumber || null,
        address: parentFormData.step1.address || null,
        impressum: parentFormData.step1.impressum || null,
        legalRepresentative: parentFormData.step1.legalRepresentative || null,
        legalRepresentativeContact: parentFormData.step1.legalRepresentativeContact || null,
        extCreditorId: parentFormData.step1.extCreditorId || null,
        countryId: parentFormData.step1.countryId || null,
        bankTransactionEntityName: parentFormData.step1.bankTransactionEntityName || null,
        priceListId: this.pricelist.id,
        studioOwner: parentFormData.step1.studioOwner || null,
        studioOwnerAddress: parentFormData.step1.studioOwnerAddress || null,
        studioOwnerPostalCode: parentFormData.step1.studioOwnerPostalCode || null,
        studioOwnerCity: parentFormData.step1.studioOwnerCity || null,
        studioManager: parentFormData.step1.studioManager || null,
        courtInCharge: parentFormData.step1.courtInCharge || null,
        // attributes values
        customAttributes: this.newArray,
        // password policy values
        passwordPolicy: {
          inheritFromParent: parentFormData.step3.inheritParent,
          maxRetryCount: parentFormData.step3.inheritParent === true ? "" : parentFormData.step3.maxRetry,
          minPassHistory: parentFormData.step3.inheritParent === true ? "" : parentFormData.step3.minDiffPass,
          lockoutTime: parentFormData.step3.inheritParent === true ? "" : parentFormData.step3.lockTime,
          minDigits: parentFormData.step3.inheritParent === true ? "" : parentFormData.step3.minDigits,
          minPassLength: parentFormData.step3.inheritParent === true ? "" : parentFormData.step3.minPassLength,
          minSpecialChars: parentFormData.step3.inheritParent === true ? "" : parentFormData.step3.minSpecChar,
          passMaxDays: parentFormData.step3.inheritParent === true ? "" : parentFormData.step3.passExPeriod,
          minLowercaseLetters: parentFormData.step3.inheritParent === true ? "" : parentFormData.step3.minLowLetters,
          minDiffChars: parentFormData.step3.inheritParent === true ? "" : parentFormData.step3.minDiffChar,
          minUppercaseLetter: parentFormData.step3.inheritParent === true ? "" : parentFormData.step3.minUpLetters
        }
      };

      this.entityService.createNewEntity(body).subscribe(
        (response) => {
          if (createAndAddRoles === false) {
            msgText = "success";
            msgType = "success";
            this.setNotificationMessage(msgText, msgType);
            this.updateEntityData.emit(true);
            this.closeModal.emit(true);
          } else {
            msgText = "success";
            msgType = "success";
            this.setNotificationMessage(msgText, msgType);
            this.closeModal.emit(true);
            // Redirect to createRoles stepper
            // document.getElementById('mc-create-new-entity-btn').click();
          }
          this.showLoader = false;
          const priceListBody = {
            idMcEntity: response.id,
            idPriceList: this.pricelist.id,
            validFrom: new Date(),
            flgActive: true
          };
          this.mcPriceList2EntityService.createNewMcPriceList2Entity(priceListBody).subscribe(
            (res) => {
              console.log('Price list ' + res.idPriceList + ' added for entity ID: ' + res.idMcEntity)
            }
          );
        }, (error) => {
          this.showLoader = false;
          /*if (error.status >= 500) {
            msgText = "Server is not available at the moment, please try again later.";
            msgType = "error";
            this.setNotificationMessage(msgText, msgType);
            return;
          }*/
          msgText = error.error ? error.error.message : error.message;
          msgType = error.ok === false ? LoginEnums.errorMsg : LoginEnums.successMsg;
          this.setNotificationMessage(msgText, msgType);
        }
      );
    } else {
      this.showLoader = false;
      this.setNotificationMessage(McGod.t('cc.pricelist.you-must-choose-the-pricelist-previous-step-in-order-to-complete-the-entity-creation-process'), 'error');
    }

  }

}

