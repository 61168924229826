import {CsrMappingItem} from './csr-mapping-item.model';
import {CsrMappingItemListGENERATED} from '../_generated/csr-mapping-item-list-generated.model';

export class CsrMappingItemList extends CsrMappingItemListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrMappingItemList();
  }

  updateItems(objectList: any[], objectSearchPropertyName: any, objectApplyPropertyName: string) {
    this.items.map(item => {
      // @ts-ignore
      const foundObjectListItem = objectList.find(objectListItem => objectListItem[objectSearchPropertyName] === item[objectSearchPropertyName]);
      // @ts-ignore
      foundObjectListItem && (item[objectApplyPropertyName] = foundObjectListItem[objectApplyPropertyName]);
    });
  }

  // ---------------------------------------------------------------------
}
