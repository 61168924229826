/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CsrRecurringDetailsTable3ConfigGENERATED} from '../_generated/csr-recurring-details-table-3-config-generated.model';

// import {CsrRecurringDetailsGENERATED} from '../_generated/csr-recurring-details-generated.model';

export class CsrRecurringDetailsTable3Config extends CsrRecurringDetailsTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colDay', 'Day in a', 'day', 'day');
    this.addColumn('colMonth', 'Month of the', 'month', 'month');
    this.addColumn('colTypeCd', 'Statuses', 'typeCd', 'typeCd');


  }
}
