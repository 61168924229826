import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BaseDirective, McProfileUserService} from '@miticon-ui/mc-core';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'mc-two-fa-account-authentication-modal',
  templateUrl: './mc-two-fa-account-authentication-modal.component.html',
})
export class McTwoFaAccountAuthenticationModalComponent extends BaseDirective implements OnInit {

  modalReference!: any;
  authForm: FormGroup;
  isEnabled!: boolean;
  messageText!: string;
  messageType!: string;
  twoFaAuth!: string;
  @Input() twoFaForUser!: boolean;
  @Output() childEvent = new EventEmitter();
  @Input() parentData!: boolean;
  @Input() userId!: number;

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private profileUserService: McProfileUserService,
  ) {
    super();
    this.authForm = new FormGroup({});
  }

  ngOnInit(): void {
  }

  openModal(content: any) {
    /*Modal reference - used for close it*/
    this.modalReference = this.modalService.open(content, {
      centered: true,
      windowClass: 'mc-account-info-authentication-modal',
      backdrop: 'static'
    });
    // Set radioBtn value
    this.authForm = this.fb.group({
      authentication: [this.parentData ? 'enable' : 'disable']
    });
  }

  // Set 2FA authentication
  setTwoFactorAuthentication() {
    const formData = this.authForm.getRawValue();
    const enabled = formData.authentication === 'enable';
    const body = {
      enabled
    };
    if (this.twoFaForUser) {
      this.profileUserService.twoFactorAuthenticationForUser(body, this.userId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.modalService.dismissAll('Cross click');
          this.childEvent.emit(
            enabled === true ? this.isEnabled = true : this.isEnabled = false
          );
        }, (error) => {
          if (error.status >= 500) {
            this.messageText = 'Server is not available at the moment, please try again later.';
            this.messageType = 'error';
            return;
          }
          this.messageText = error.error ? error.error.message : error.message;
          this.messageType = 'error';
        });
    } else {
      this.profileUserService.twoFactorAuthentication(body)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
        () => {
          this.childEvent.emit(
            enabled === true ? this.isEnabled = true : this.isEnabled = false
          );
          this.modalService.dismissAll('Cross click');
        },
        (error) => {
          if (error.status >= 500) {
            this.messageText = 'Server is not available at the moment, please try again later.';
            this.messageType = 'error';
            return;
          }
          this.messageText = error.error ? error.error.message : error.message;
          this.messageType = 'error';
        }
      );
    }
  }

}
