<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcCountry.apiLoadingFlg || serviceList.apiLoadingFlg"></lib-mc-loader>

  <span class="mc-link mc-cursor-pointer" (click)="actGoBackToCountries()">< Go back to countries</span>

  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h2>Country details</h2>

  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-body">
    <mcc-message [errorMsg]="mcCountry.apiErrorMessage"></mcc-message>


    <div class="info-summary-header-lite">
      <h4>Country</h4>
    </div>
    <div class="info-summary-top-lite inner-content">
      <div class="row">
        <div class="col-md-6">
          <mcc-row-title-description title="Country:"
                                     [description]="mcCountry.name"></mcc-row-title-description>
        </div>
      </div>
    </div>

    <div class="info-summary-header-lite">
      <h4>Vat definition</h4>
    </div>
    <div class="info-summary-top-lite inner-content">
      <div class="row">

        <div *ngFor="let item of mcVatItemList.items" class="col-md-6">
          <div class="content-section">
            <h2>VAT</h2>
            <mcc-row-title-description title="VAT rate:" [description]="item.vatRate.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' %'"></mcc-row-title-description>
            <mcc-row-title-description title="VAT Type:"
                                       [description]="getVatTypeLabel(item.vatTypeCd)"></mcc-row-title-description>
            <mcc-row-title-description title="Description:"
                                       [description]="item.vatDescription"></mcc-row-title-description>
            <mcc-row-title-description title="Services covered:" [descriptionInnerHtml]="fldServices(item)"></mcc-row-title-description>
            <mcc-row-title-description title="Start date:"
                                       [description]="item.getStartDateString()"></mcc-row-title-description>
            <mcc-row-title-description title="End date:"
                                       [description]="item.getEndDateString()"></mcc-row-title-description>
          </div>
        </div>
      </div>

    </div>


  </div><!-- content-body -->

</div>
