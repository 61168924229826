import {CsrConsumerHistoryFilterGENERATED} from '../_generated/csr-consumer-history-filter-generated.model';

export class CsrConsumerHistoryFilter extends CsrConsumerHistoryFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrConsumerHistoryFilter();
  }

  // ---------------------------------------------------------------------
}
