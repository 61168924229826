import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';

@Component({
  selector: 'mcc-info-label-card',
  templateUrl: './mcc-info-label-card.component.html',
  styleUrls: ['./mcc-info-label-card.component.scss']
})
export class MccInfoLabelCardComponent implements OnInit {
  @Input() label!: string;
  @Input() description!: string;
  @Input() descColor!: 'gray' | 'yellow' | 'red' | 'green' | 'lightblue';
  @Input() icon!: string;
  @Input() isDescriptionLink = false;
  @Output() eventDescriptionClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onDescriptionClicked() {
    this.eventDescriptionClicked.emit();
  }
}
