import {McHtmlButton} from './mc-html-button';
import {McHtmlLink} from './mc-html-link';
import {McHtmlTooltip} from './mc-html-tooltip';
import {McHtmlActionSet} from './mc-html-action-set';
import {ActionOptions} from '../_core/mc-html-action-options';

export class McHtml {
  items = new Array<any>();

  static getButton(label: string, actionCdForEvent: string, itemObj: any, permissions: string[], extraCssClass: string = '', actionOptions: ActionOptions) {
    const item = new McHtmlButton(label, actionCdForEvent, itemObj, permissions, extraCssClass, actionOptions);
    return item;
  }

  static getLink(linkTextHtml: string, routeLink: string, itemObj: any, permissions: string[] | '', extraCssClass: string = '', actionOptions: ActionOptions | '', queryParams: any) {
    const item = new McHtmlLink(linkTextHtml, routeLink, itemObj, permissions, extraCssClass, queryParams, actionOptions);
    return item;
  }

  addActionSet() {
    const actionSet = new McHtmlActionSet();
    this.items.push(actionSet);
    return actionSet;
  }

  addItemB(aItem: McHtmlLink | McHtmlButton | McHtmlActionSet) {
    this.items.push(aItem);
    return this;
  }

  addButtonB(label: string, actionCdForEvent: string, itemObj: any, permissions: string[], extraCssClass: string = '', actionOptions: ActionOptions) {
    const item = McHtml.getButton(label, actionCdForEvent, itemObj, permissions, extraCssClass, actionOptions);
    this.items.push(item);
    return this;
  }

  addLinkB(linkTextHtml: string, routeLink: string, itemObj: any, permissions: string[] | '', extraCssClass: string = '', actionOptions: ActionOptions | '', queryParams: any = {}) {
    const item = McHtml.getLink(linkTextHtml, routeLink, itemObj, permissions, extraCssClass, actionOptions, queryParams);
    this.items.push(item);
    return this;
  }

  addTooltipB(bodyTextHtml: string, tooltipTextHtml: string, tooltipPlacement: 'top' | 'bottom' | 'left' | 'right' = 'top', tooltipCssClass: string = '', extraCssClass: string = '') {
    const item = new McHtmlTooltip(bodyTextHtml, tooltipTextHtml, tooltipPlacement, tooltipCssClass, extraCssClass);
    this.items.push(item);
    return this;
  }

  addPlainHtmlB(htmlBody: string) {
    this.items.push(htmlBody);
    return this;
  }

}
