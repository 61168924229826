<ng-container  *ngIf="dataImport" class="dialog">
  <div class="dialog_header">
    <span class="dialog_header_title text-uppercase">
      {{ ("mem.club.data-import" | translate) + ': ' + dataImport.dataImport }}
    </span>
    <span class="dialog_header_icon"
          [innerHTML]="iconClose | safeHtml"
          (click)="dialogRef.close()"></span>
  </div>

  <mat-dialog-content>
    <div>
      <div class='card'>
        <div class="items info">
          <div class="items_info">
            <div class='card_title'>{{ dataImport.dataImport }}</div>
          </div>
          <div class="info_status">
            <span [innerHTML]="getStatusIcon(dataImport.statusCd) | safeHtml"></span>
            <span class="card_description">{{ getStatusLabel(dataImport.statusCd) }}</span>
          </div>
        </div>
        <div class="dialog-row-wrapper">
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ "mem.import.uploaded-file" | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ dataImport.webFileName }}</span>
          </div>
        </div>
        <div class="dialog-row-wrapper">
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">ID:</span>
            <span class="dialog_content_row dialog-col-value">{{ dataImport.id }}</span>
          </div>
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ "mem.data-import.data-set" | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ dataImport.dataSetName }}</span>
          </div>
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ "mem.data-import.source" | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ dataImport.src }}</span>
          </div>
        </div>
        <div class="dialog-row-wrapper">
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ "mem.data-import.date" | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ formattedDate  }}</span>
          </div>
        </div>
        <div class="dialog-row-wrapper">
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ "cc.consumers.consumers-overview.import-mode" | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ getFormattedImportMode(dataImport.importMode) | translate }}</span>
          </div>
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ "mem.import.total-items" | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ dataImportAnalytics.totalItems + " " + ("mem.import.items" | translate) }}</span>
          </div>
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ "mem.import.imported-items" | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ dataImportAnalytics.importedItems + " " + ("mem.import.items" | translate) }}</span>
          </div>
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ "mem.import.items-failed" | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ dataImportAnalytics.failedItems + " " + ("mem.import.items" | translate) }}</span>
          </div>
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ "mem.import.items-skipped" | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ dataImportAnalytics.skippedItems + " " + ("mem.import.items" | translate) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="dataImportAnalytics && dataImportAnalytics.failedItems" (click)="viewFailedItems = !viewFailedItems">
      <div class='card'>
        <div class="items">
          <div class="items_info">
            <span class='flex' [innerHTML]="iconFailedItems | safeHtml"></span>
            <div>
              <div class='card_title'>{{ "mem.import.items-failed-to-import" | translate }}</div>
              <span class="card_description">{{ "mem.import.failed-message" | translate }}</span>
            </div>
          </div>
          <div>
            <span [class.open_state]="viewFailedItems" [innerHTML]="iconArrow | safeHtml"></span>
          </div>
        </div>
        <div class="view_indexes" *ngIf="viewFailedItems">
          <h4 class="view_indexes_title">{{ "mem.import.failed-item-number" | translate }}</h4>
          <div class="view_indexes_row">
            <div class="view_indexes_row_item" *ngFor="let failedItem of failedItems">{{ getIndex(failedItem.index) }}</div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="dataImportAnalytics && dataImportAnalytics.skippedItems" (click)="viewSkippedItems = !viewSkippedItems">
      <div class='card'>
        <div class="items">
          <div class="items_info">
            <span class='flex' [innerHTML]="iconSkippedItems | safeHtml"></span>
            <div>
              <div class='card_title'>{{ "mem.import.skipped-items" | translate }}</div>
              <span class="card_description">{{ getSkippedTitle() | translate }}</span>
            </div>
          </div>
          <div>
            <span [class.open_state]="viewSkippedItems" [innerHTML]="iconArrow | safeHtml"></span>
          </div>
        </div>
        <div class="view_indexes" *ngIf="viewSkippedItems">
          <h4 class="view_indexes_title">{{ "mem.import.skipped-item-number" | translate }}</h4>
          <div class="view_indexes_row">
            <div class="view_indexes_row_item" *ngFor="let skippedItem of skippedItems">{{ getIndex(skippedItem.index) }}</div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button
            class="secondary-btn"
            (click)="dialogRef.close()">
      {{ "cc.common.close" | translate }}
    </button>
  </div>
</ng-container>
