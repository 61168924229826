<ng-container class="dialog">
  <div class="dialog_header">
    <span class="dialog_header_title text-uppercase">
      {{ ((processNotification ? 'cc.common.edit' : "cc.common.create") | translate) + ' ' + ('cc.process.configuration' | translate) }}
    </span>
    <span class="dialog_header_icon"
          [innerHTML]="iconClose | safeHtml"
          (click)="dialogRef.close()"></span>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="form_fields">
        <div *ngFor="let type of notificationTypes">
          <mat-checkbox
            (change)="resetPaymentType()"
            [formControlName]="type.controlName">
            {{ type.label | translate }}
          </mat-checkbox>
        </div>
      </div>
      <div class="form_fields">
        <mat-form-field *ngIf="form.controls['typePaymentChecked'].value" appearance="outline">
          <mat-label>{{ "cc.process.payment-notifications" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="paymentType"
                      disableOptionCentering>
            <mat-option *ngFor="let type of paymentNotifications"
                        [value]="type.value">
              {{ type.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button
            class="secondary-btn"
            (click)="dialogRef.close()">
      {{ 'cc.common.edit.cancel' | translate }}
    </button>
    <button mat-button
            [disabled]="!form.valid ||
            form.controls['typePaymentChecked'].value  ? !form.controls['paymentType'].value : false"
            (click)="createOrEditNotificationType()"
            class="primary-btn"
            type="submit">
      {{ "cc.common.view.save" | translate }}
    </button>
  </div>
</ng-container>
