/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McCountryTable3ConfigGENERATED} from '../_generated/mc-country-table-3-config-generated.model';

// import {McCountryGENERATED} from '../_generated/mc-country-generated.model';

export class McCountryTable3Config extends McCountryTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colMcCountryName', this.t('cc.users.edit.country'), 'name', 'name');
    this.addColumn('colNoOfEntities', this.t('cc.pricelist.no-of-entities'), 'fldNumberOfEntities()', '');
    this.addColumn('colNoOfVATs', this.t('cc.country.vats'), 'fldVatNumberAndInfo()', '');

  }
}
