import {CcMollieCustomerFilterGENERATED} from '../_generated/cc-mollie-customer-filter-generated.model';

export class CcMollieCustomerFilter extends CcMollieCustomerFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CcMollieCustomerFilter();
  }

  // ---------------------------------------------------------------------
}
