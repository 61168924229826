
import {Table3Config} from '../extra/table-3-config';

// import {McBillingServiceUnitTypeGENERATED} from '../_generated/mc-billing-service-unit-type-generated.model';

export class McBillingServiceUnitTypeTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colIdMcBillingService', 'Billing service id', 'idMcBillingService', 'idMcBillingService');
    this.addColumn('colTypeCd', 'Type cd', 'typeCd', 'typeCd');

*/
  }
}
