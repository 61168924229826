/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McBillingItemFilter} from '../models/mc-billing-item-filter.model';

export class McBillingItemFilterGENERATED extends BaseObjectFilter{    public entityIds: number[] = [];
    public types: string[] = [];
    public statementIds: number[] = [];
    public statusCds: string[] = [];
    public amountFrom: any | undefined;
    public amountTo: any | undefined;
    public dateFrom: any | undefined;
    public dateTo: any | undefined;
    public mcBillingServiceCatalogIds: number[] = [];
    public idTransaction: number | undefined;
    public flgPayoutOrBilling: boolean | undefined;
    public refId: number | undefined;

  public properties: string[] = ['entityIds', 'types', 'statementIds', 'statusCds', 'amountFrom', 'amountTo', 'dateFrom', 'dateTo', 'mcBillingServiceCatalogIds', 'idTransaction', 'flgPayoutOrBilling', 'refId', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingItemFilter {
    console.error('MTCN-ERROR: Not Implemented: McBillingItemFilter::createNewInstance(). Add this method to final class and return new McBillingItemFilter();');
      throw new Error('Cannot McBillingItemFilter::createNewInstance(). Add this method to final class and return new McBillingItemFilter();');
  }

}
