/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McBillingService2PriceList} from '../models/mc-billing-service-2-price-list.model';
import {McBillingService2PriceListList} from '../models/mc-billing-service-2-price-list-list.model';
import {McBillingService2PriceListFilter} from '../models/mc-billing-service-2-price-list-filter.model';
import {McBillingService2PriceListService} from '../services/mc-billing-service-2-price-list.service';
import {IMcBillingService2PriceList} from '../_generated/mc-billing-service-2-price-list.interface';
import {McGod} from "../extra/mc-god.service";

export class McBillingService2PriceListListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'activityUnitTypeCd', 'flgCustomPrice', 'flgDoNotChargeForFactoredTransactions', 'flgDoNotChargeForWebDomains', 'idBillingService', 'idPriceList', 'notChargeWebDomains', 'priceAdditionalUnit', 'priceFlat', 'priceFromContractBatchFee', 'priceFromContractTransactionWithdrawalFeeFactored', 'priceFromContractTransactionWithdrawalFeeNonFactored', 'priceFromContractTransactionWithdrawalFeeService', 'priceOneTime', 'pricingTypeCd', 'sysUpdatedDatetime', 'unitLimitForFlatPrice', 'flgItemFromTemplate', 'flgPriceFromContract', 'serviceDescription', 'serviceName', 'serviceTypeCd', 'startDate'];
  public items: McBillingService2PriceList[] = [];
  public apiService: McBillingService2PriceListService;
 // protected tempObjList: McBillingService2PriceListList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingService2PriceListList {
    console.error('MTCN-ERROR: Not Implemented: McBillingService2PriceListList::createNewInstance(). Add this method to final class and return new McBillingService2PriceListList();');
    throw new Error('Cannot McBillingService2PriceListList::createNewInstance(). Add this method to final class and return new McBillingService2PriceListList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcBillingService2PriceList[]): McBillingService2PriceListList {
    const  listObj: McBillingService2PriceListList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McBillingService2PriceList = McBillingService2PriceList.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McBillingService2PriceListList {
    const  listObj: McBillingService2PriceListList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingService2PriceListService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcBillingService2PriceList[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McBillingService2PriceList = McBillingService2PriceList.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcBillingService2PriceListFilter: McBillingService2PriceListFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcBillingService2PriceListFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McBillingService2PriceList {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McBillingService2PriceList();
    }
  }

  public replaceItem(newItem: McBillingService2PriceList) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadPriceListItemsInEffectForEntity( entityId: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getPriceListItemsInEffectForEntity(entityId, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

