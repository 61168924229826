import { Component, OnInit } from '@angular/core';
import {McProfileUserService, McAuthService} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-account-info',
  templateUrl: './mc-account-info.component.html',
})
export class McAccountInfoComponent implements OnInit {
  isEnabled!: boolean;
  messageText!: string;
  messageType!: string;

  constructor(
    private authService: McAuthService,
    private profileUserService: McProfileUserService
  ) { }

  ngOnInit() {
    this.getUserDataById();
  }

  // Get userData from dataBase
  getUserDataById() {
    const userId = this.authService.getLoggedUserInfo().user_id;
    console.log(userId);
    this.profileUserService.getUserData(userId).subscribe(
      (response) => {
        this.isEnabled = response.is2faEnabled;
      },
      (error) => {
        if (error.status >= 500) {
          this.messageText = 'Server is not available at the moment, please try again later.';
          this.messageType = 'error';
          return;
        }
        this.messageText = error.error ? error.error.message : error.message;
        this.messageType = 'error';
      }
    );
  }
}
