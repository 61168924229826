import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CsrBankAccount, CsrBankAccountService,
  CsrConsumer,
  CsrPaymentDetails,
  McBankAccount, McBankAccountService,
  McConsumersService,
  McEntity2Service,
  McForm,
  McGod,
  McValueLabelList
} from '@miticon-ui/mc-core';
import {ICON_ATTRIBUTE} from "../../../../assets/media/svg_icons/icon-attribute";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ICON_IMPORTED, ICON_FAILED} from "@miticon-ui/mc-core";


@Component({
  selector: 'mc-consumer-consumer-360-create-contract-payment-method-part',
  templateUrl: './mc-consumer-consumer-360-create-contract-payment-method-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-create-contract-payment-method-part.component.scss']
})
export class McConsumerConsumer360CreateContractPaymentMethodPartComponent implements OnInit {

  @Input() mcForm!: McForm;
  @Input() csrConsumer!: CsrConsumer;
  @Input() selectedTab!: number;
  @Input() errorMessage!: string;
  @Input() mcBankAccount!: any;

  @Output() eventFileUploaded = new EventEmitter<File | null>();
  @Output() eventPaymentMethod = new EventEmitter();
  @Output() eventPaymentPurpose = new EventEmitter();
  @Output() eventBankFormControlsEmpty = new EventEmitter();
  @Output() eventMcBankAccount = new EventEmitter();
  @Output() eventPrimaryCsrBankAccount = new EventEmitter();

  paymentMethodOptionsVll = new McValueLabelList();
  mcBankAccountsVll = new McValueLabelList();
  bankInfoForm = false;
  file!: File | null;
  consumerIban!: string;
  consumerBic!: string;
  consumerAccountOwner!: string;
  consumerBankName!: string;
  transferMoneyOptionsVll = new McValueLabelList();
  csrPaymentDetails = new CsrPaymentDetails();
  showBankInfoForTransferMoney = false;
  paymentMethodDefaultValue: any = null;
  today = new Date();
  paymentType: any;
  primaryBankAccount: CsrBankAccount;
  mcGod = McGod.getInstance();
  iconSucces = ICON_IMPORTED;
  iconFailed = ICON_FAILED;
  iconGenerate = ICON_ATTRIBUTE;

  constructor(private mcConsumerService: McConsumersService,
              private mcEntity2Service: McEntity2Service,
              private mcBankAccountService: McBankAccountService,
              private csrBankAccountService: CsrBankAccountService,
              private toastr: ToastrService,
              private tS: TranslateService) {
  }

  ngOnInit() {
    this.paymentMethodOptionsVll = CsrPaymentDetails.getTypeCdVll();

    this.eventFileUploaded.emit(this.file);

    this.mcEntity2Service.getAncestors(McGod.getLoggedEntityIdFromToken())
      .subscribe((entities) => {
        entities.forEach(item => {
          this.transferMoneyOptionsVll.add(item.id, item.name);
        });
      });

    this.csrConsumer.bankAccounts.items.forEach((bankAccount) => {
      if(bankAccount.flgPrimary) {
        this.primaryBankAccount = bankAccount;
        this.eventPrimaryCsrBankAccount.emit(this.primaryBankAccount);
      }
    })
  }

  onPaymentMethodValueChanged(paymentType: any) {
    this.errorMessage = '';
    switch (paymentType) {
      case CsrPaymentDetails.TYPE_CD_CASH: {
         this.paymentType = 'CASH';
         break;
      }
      case  CsrPaymentDetails.TYPE_CD_TRANSFER: {
        this.paymentType = 'TRANSFER';
        break;
      }
      case CsrPaymentDetails.TYPE_CD_DIRECT_DEBIT: {
        this.paymentType = 'DIRECT_DEBIT';
        break;
      }
      case CsrPaymentDetails.TYPE_CD_CREDIT_CARD: {
        this.paymentType = 'CREDIT_CARD';
        break;
      }
    }
    this.mcBankAccount = null;
    this.eventMcBankAccount.emit(this.mcBankAccount);
    this.eventPaymentMethod.emit(CsrPaymentDetails.getTypeCdLabel(this.paymentType));
  }
  onDiffBankAccountChanged(value: any) {
    this.bankInfoForm = !!value;
  }

  showNewDetailsForBankInfo() {
    const bankFormControls = [];
    bankFormControls.push(this.mcForm.getControl('ctrlIban'), this.mcForm.getControl('ctrlBic'), this.mcForm.getControl('ctrlAccountOwner'),
      this.mcForm.getControl('ctrlBankName'));
    const validBankInfo = this.mcForm.getControl('ctrlIban')?.valid && this.mcForm.getControl('ctrlBic')?.valid
    && this.mcForm.getControl('ctrlAccountOwner')?.valid && this.mcForm.getControl('ctrlBankName')?.valid;
    if (validBankInfo) {
      this.consumerIban = this.mcForm.getValue('ctrlIban');
      this.consumerBic = this.mcForm.getValue('ctrlBic');
      this.consumerAccountOwner = this.mcForm.getValue('ctrlAccountOwner');
      this.consumerBankName = this.mcForm.getValue('ctrlBankName');
      this.bankInfoForm = false;
      this.mcForm.getControl('ctrlUseDiffBankAccount')?.get('value')?.setValue(false);
    } else {
      bankFormControls.forEach(control => {
        if (control?.invalid) {
          control.get('errorFlg')?.setValue(true);
          control.get('errorMsg')?.setValue(this.mcGod.t('cc.common.this-field-is-required'));
        }
      });
    }
  }

  onBankAccountValueChanged(value: any) {
    this.mcBankAccount = value;
    this.eventMcBankAccount.emit(this.mcBankAccount);
    this.showBankInfoForTransferMoney = true;
  }

  onTransferMoneyValueChanged(value: any) {
    this.mcBankAccountsVll = new McValueLabelList();
    this.mcBankAccount = null;
    this.eventMcBankAccount.emit(this.mcBankAccount);
    this.mcBankAccountService.getDefaultByEntityId(value).subscribe((bankAccounts) => {
      if(bankAccounts.length > 1) {
        this.showBankInfoForTransferMoney = false;
        bankAccounts.forEach((bankAccount: McBankAccount) => {
          this.mcBankAccountsVll.addItem({
            label: bankAccount.bankName,
            value: bankAccount
          });
        })
      } else {
        this.mcBankAccount = bankAccounts[0];
        this.eventMcBankAccount.emit(this.mcBankAccount);
        this.showBankInfoForTransferMoney = true;
      }
    })
  }

  generateSepaMandate() {
    let newBankAccount = new CsrBankAccount();
    newBankAccount.flgPrimary = true;
    newBankAccount.flgConsumer360 = true;
    newBankAccount.idCsrConsumer = this.csrConsumer.id;
    newBankAccount.iban = this.mcForm.getValue('ctrlIban');
    newBankAccount.bic = this.mcForm.getValue('ctrlBic');
    newBankAccount.owner = this.mcForm.getValue('ctrlAccountOwner');
    newBankAccount.bankName = this.mcForm.getValue('ctrlBankName');

    this.csrBankAccountService.insert(newBankAccount).subscribe((data: any) => {
      if(data) {
        this.primaryBankAccount = data;
        this.eventPrimaryCsrBankAccount.emit(this.primaryBankAccount);
        this.showNewDetailsForBankInfo();
        this.toastr.success(this.tS.instant("cc.consumer360.generated-sepa-mandate"))
      }
    }, (err) => {
      this.toastr.error(err.error.message);
    })

  }
}
