import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ICON_CLOSE} from "@miticon-ui/mc-components";

@Component({
  selector: 'lib-data-import-info-dialog',
  templateUrl: './data-import-info-dialog.component.html',
  styleUrls: ['./data-import-info-dialog.component.scss']
})
export class DataImportInfoDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, icon: string, text: string, button: string},
              public dialogRef: MatDialogRef<DataImportInfoDialogComponent>) { }

  ngOnInit(): void {
  }

}
