import {ImDataMappingFilterGENERATED} from '../_generated/im-data-mapping-filter-generated.model';

export class ImDataMappingFilter extends ImDataMappingFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataMappingFilter();
  }

  // ---------------------------------------------------------------------
}
