import {CommonModule, DatePipe} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MccFiFileUploadModule, McComponentsModule, McFilterModule, McTableModule } from '@miticon-ui/mc-components';
import { AppSharedModule } from '../app-shared/app-shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { McBankModuleImportStarMoneyPartComponent } from './components/mc-bank-module-import-star-money-part/mc-bank-module-import-star-money-part.component';
import { McBankModuleRoutingModule } from './mc-bank-module-routing.module';
import { McBankModuleIncomingPaymentsListPageComponent } from './pages/mc-bank-module-incoming-payments-list-page/mc-bank-module-incoming-payments-list-page.component';
import { McBankModuleIncomingPaymentsPotentialTransactionMatchesComponent } from './pages/mc-bank-module-incoming-payments-potential-transaction-matches/mc-bank-module-incoming-payments-potential-transaction-matches.component';
import { McBankModuleStarMoneyListPageComponent } from './pages/mc-bank-module-star-money-list-page/mc-bank-module-star-money-list-page.component';
import { McConsumerModule } from '../mc-consumer/mc-consumer.module';
import { McBankModuleSepaStatementReportListPageComponent } from './pages/mc-bank-module-sepa-statement-report-list-page/mc-bank-module-sepa-statement-report-list-page.component';
import { EbBankModuleImportPsd2StatementPartComponent } from './components/eb-bank-module-import-psd2-statement-part/eb-bank-module-import-psd2-statement-part.component';
import { EbBankModulePsd2StatementListPageComponent } from './pages/eb-bank-module-psd2-statement-list-page/eb-bank-module-psd2-statement-list-page.component';
import { FinanceListPageComponent } from './pages/finance-list-page/finance-list-page.component';
import {SafeHtmlModule} from "../../assets/pipes/safe-html/safe-html.module";
import { FinanceGenerateReportDialogComponent } from './pages/finance-generate-report-dialog/finance-generate-report-dialog.component';
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";

@NgModule({
  declarations: [
    McBankModuleIncomingPaymentsPotentialTransactionMatchesComponent,
    McBankModuleIncomingPaymentsListPageComponent,
    McBankModuleImportStarMoneyPartComponent,
    McBankModuleStarMoneyListPageComponent,
    McBankModuleSepaStatementReportListPageComponent,
    EbBankModuleImportPsd2StatementPartComponent,
    EbBankModulePsd2StatementListPageComponent,
    FinanceListPageComponent,
    FinanceGenerateReportDialogComponent,
  ],
  imports: [
    CommonModule,
    McBankModuleRoutingModule,
    NgbModule,
    McComponentsModule,
    McFilterModule,
    McTableModule,
    FormsModule,
    ReactiveFormsModule,
    MccFiFileUploadModule,
    TranslateModule,
    AppSharedModule,
    McConsumerModule,
    SafeHtmlModule,
    MatButtonModule,
    MatDialogModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    McBankModuleIncomingPaymentsPotentialTransactionMatchesComponent,
    McBankModuleIncomingPaymentsListPageComponent,
    McBankModuleImportStarMoneyPartComponent,
    McBankModuleStarMoneyListPageComponent,
    McBankModuleRoutingModule,
    FormsModule,
  ],
  providers: [DatePipe]
})
export class McBankModuleModule {}
