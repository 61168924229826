import {Component, Inject, OnInit} from '@angular/core';
import {ImDataMappingService, McMapping} from "@miticon-ui/mc-core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import moment from "moment";
import {ICON_CLOSE} from "../../../../../../assets/media/svg_icons/icon-close";
import {ICON_MAPPING_TEMPLATE_BLACK} from "../../../../../../assets/media/svg_icons/icon-mapping-template-black";
import {ICON_ARROW} from "../../../../../../assets/media/svg_icons/icon-arrow";


@Component({
  selector: 'lib-data-import-mapping-templates-dialog',
  templateUrl: './data-import-mapping-templates-dialog.component.html',
  styleUrls: ['./data-import-mapping-templates-dialog.component.scss']
})
export class DataImportMappingTemplatesDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  iconArrow = ICON_ARROW;
  iconMappingTemplateBlack = ICON_MAPPING_TEMPLATE_BLACK;

  mappings: any;
  navOpenState: boolean[] = [];
  choosenMapping!: McMapping | null;
  currentlyMapping: any;
  viewCurrentMappingItems: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA)
              private data: {
                choosenMapping: any,
                dataSetId: number
              }, public dialogRef: MatDialogRef<DataImportMappingTemplatesDialogComponent>,
              private dataMappingService: ImDataMappingService) {}

  ngOnInit(): void {
    this.dataMappingService.getMappingsByDataSetId(this.data.dataSetId).subscribe((data) => {
      this.mappings = data;
      for(let i = 0; i < this.mappings.length; i++) {
        this.navOpenState[i] = false;
      }
      if(this.data && this.data.choosenMapping) {
        this.mappings = this.mappings.filter((item: any) => item.id != this.data.choosenMapping.id);
        this.currentlyMapping = this.data.choosenMapping;
      }
    });
  }

  chooseMapping(choosenMapping: McMapping, index: number) {
    for(let i = 0; i < this.navOpenState.length; i++) {
      if(i === index) {
        this.navOpenState[index] = !this.navOpenState[index];
        this.choosenMapping = !this.navOpenState[index] ? null : choosenMapping;
      } else {
        this.navOpenState[i] = false;
      }
    }
  }

  getMappingCreationDate(mappingCreationDate: string) {
    return moment(mappingCreationDate).format('DD.MM.YYYY');
  }

  applyMapping() {
    this.dialogRef.close(this.choosenMapping);
  }

}
