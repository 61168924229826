/*BbmTestObj*/
import {McConsumerActionGENERATED} from '../_generated/mc-consumer-action-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {McDateUtils} from '../common/mc-date-utils';
import {ActionOptions} from '../_core/mc-html-action-options';

export class McConsumerAction extends McConsumerActionGENERATED {

  public static ACTION_SHOW_EMAIL = 'ACT_SHOW_EMAIL';
  public static ACTION_SHOW_POST = 'ACT_SHOW_POST';
  public static ACTION_SHOW_SMS = 'ACT_SHOW_SMS';
  public static ACTION_RESEND = 'ACT_RESEND';
  public static ACTION_RETRY = 'ACT_RETRY';

  private _fldActions: McHtml | null = null;
  private _fldStatus: McHtml | null = null;
  private _fldLevelActions: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McConsumerAction();
  }

  // ---------------------------------------------------------------------

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<img src="assets/images/common-icons/resend.svg" alt=""> Resend`, McConsumerAction.ACTION_RESEND, this, [McGod.PERM_MC_PROCESS_FLOW_LEVEL_VIEW], `mc-button-link`, ActionOptions.OPTION_EDIT);
      actSet.addActionB(`<i class="fas fa-sync mc-blue-text"></i> Retry`, McConsumerAction.ACTION_RETRY, this, [McGod.PERM_MC_PROCESS_FLOW_LEVEL_VIEW], `mc-button-link`, ActionOptions.OPTION_EDIT);
      this._fldActions = html;
    }

    return this._fldActions.items;
  }

  fldStatus() {
    return this.getStatusCdLabel();
  }

  fldLevelActions() {
    if (!this._fldLevelActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.horizontalFlg = true;
      if (this.emailActionFlg) {
        actSet.addActionB(`<img src="assets/images/mc-process-icons/email-action.svg" alt=""> Email`, McConsumerAction.ACTION_SHOW_EMAIL, this, [McGod.PERM_MC_PROCESS_FLOW_LEVEL_VIEW], `mc-button-link`, ActionOptions.OPTION_VIEW); // ${this.newItemsCount > 0 ? '' : 'mc-disable'}
      }
      if (this.postActionFlg) {
        actSet.addActionB(`<img src="assets/images/mc-process-icons/post-action.svg"> Post`, McConsumerAction.ACTION_SHOW_POST, this, [McGod.PERM_MC_PROCESS_FLOW_LEVEL_VIEW], `mc-button-link`, ActionOptions.OPTION_VIEW); // ${this.newItemsCount > 0 ? '' : 'mc-disable'}
      }
      if (this.smsActionFlg) {
        actSet.addActionB(`<img src="assets/images/mc-process-icons/sms-action.svg"> SMS`, McConsumerAction.ACTION_SHOW_SMS, this, [McGod.PERM_MC_PROCESS_FLOW_LEVEL_VIEW], `mc-button-link`, ActionOptions.OPTION_VIEW); // ${this.newItemsCount > 0 ? '' : 'mc-disable'}
      }
      this._fldLevelActions = html;
    }
    return this._fldLevelActions.items;
  }

  getSentDatetimeString(): string {
    if (this.sentDatetime) {
      return McDateUtils.newFormatDateTimeString(this.sentDatetime);
    }
    return '';
  }
}
