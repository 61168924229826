import { McPricelistPricelistEntitiesUsingPricelistPartComponent } from './components/mc-pricelist-pricelist-entities-using-pricelist-part/mc-pricelist-pricelist-entities-using-pricelist-part.component';
import { McPricelistTemplatePricelistsCreatedFromTemplatePartComponent } from './components/mc-pricelist-template-pricelists-created-from-template-part/mc-pricelist-template-pricelists-created-from-template-part.component';
import { McPricelistTemplateEntitiesUsingTemplatePartComponent } from './components/mc-pricelist-template-entities-using-template-part/mc-pricelist-template-entities-using-template-part.component';
import { McPricelistPricelistEditFromProfilePartComponent } from './components/mc-pricelist-pricelist-edit-from-profile-part/mc-pricelist-pricelist-edit-from-profile-part.component';
import { McPricelistTemplateEditFromProfilePartComponent } from './components/mc-pricelist-template-edit-from-profile-part/mc-pricelist-template-edit-from-profile-part.component';
import { McPricelistPricelistProfileServiceEditPartComponent } from './components/mc-pricelist-pricelist-profile-service-edit-part/mc-pricelist-pricelist-profile-service-edit-part.component';
import { McPricelistPricelistReviewAndConfirmPartComponent } from './components/mc-pricelist-pricelist-review-and-confirm-part/mc-pricelist-pricelist-review-and-confirm-part.component';
import { McPricelistPricelistPricesAndEntitiesPartComponent } from './components/mc-pricelist-pricelist-prices-and-entities-part/mc-pricelist-pricelist-prices-and-entities-part.component';
import { McPricelistPricelistSelectTemplatePartComponent } from './components/mc-pricelist-pricelist-select-template-part/mc-pricelist-pricelist-select-template-part.component';
import { McPricelistPricelistProfilePageComponent } from './pages/mc-pricelist-pricelist-profile-page/mc-pricelist-pricelist-profile-page.component';
import { McPricelistPricelistsListPageComponent } from './pages/mc-pricelist-pricelists-list-page/mc-pricelist-pricelists-list-page.component';
import { McPricelistServicesAndPricelistListPageComponent } from './pages/mc-pricelist-services-and-pricelist-list-page/mc-pricelist-services-and-pricelist-list-page.component';
import { McPricelistPricelistServiceEditPartComponent } from './components/mc-pricelist-pricelist-service-edit-part/mc-pricelist-pricelist-service-edit-part.component';
import { McPricelistTemplateAddEditPartComponent } from './components/mc-pricelist-template-add-edit-part/mc-pricelist-template-add-edit-part.component';
import { McPricelistTemplatesListPageComponent } from './pages/mc-pricelist-templates-list-page/mc-pricelist-templates-list-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McPricelistRoutingModule } from './mc-pricelist-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {McFilterModule, McTableModule} from '@miticon-ui/mc-components';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
McPricelistPricelistEntitiesUsingPricelistPartComponent,
McPricelistTemplatePricelistsCreatedFromTemplatePartComponent,
McPricelistTemplateEntitiesUsingTemplatePartComponent,
McPricelistPricelistEditFromProfilePartComponent,
McPricelistTemplateEditFromProfilePartComponent,
McPricelistPricelistProfileServiceEditPartComponent,
McPricelistPricelistReviewAndConfirmPartComponent,
McPricelistPricelistPricesAndEntitiesPartComponent,
McPricelistPricelistSelectTemplatePartComponent,
McPricelistPricelistProfilePageComponent,
McPricelistPricelistsListPageComponent,
McPricelistServicesAndPricelistListPageComponent,
McPricelistPricelistServiceEditPartComponent,
McPricelistTemplateAddEditPartComponent,
McPricelistTemplatesListPageComponent,
  ],
  imports: [
    CommonModule,
    McPricelistRoutingModule,
    NgbModule,
    McComponentsModule,
    McFilterModule,
    McTableModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    McComponentsModule,
    McComponentsModule,
  ],
  exports: [
McPricelistPricelistEntitiesUsingPricelistPartComponent,
McPricelistTemplatePricelistsCreatedFromTemplatePartComponent,
McPricelistTemplateEntitiesUsingTemplatePartComponent,
McPricelistPricelistEditFromProfilePartComponent,
McPricelistTemplateEditFromProfilePartComponent,
McPricelistPricelistProfileServiceEditPartComponent,
McPricelistPricelistReviewAndConfirmPartComponent,
McPricelistPricelistPricesAndEntitiesPartComponent,
McPricelistPricelistSelectTemplatePartComponent,
McPricelistPricelistProfilePageComponent,
McPricelistPricelistsListPageComponent,
McPricelistServicesAndPricelistListPageComponent,
McPricelistPricelistServiceEditPartComponent,
McPricelistTemplateAddEditPartComponent,
McPricelistTemplatesListPageComponent,
    McPricelistRoutingModule,
    FormsModule,

  ]
})
export class McPricelistModule { }
