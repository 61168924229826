import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {EbFactoring, McTrigger} from '@miticon-ui/mc-core';
import {McBoolean} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-factoring-add-edit-modal-part',
  templateUrl: './app-shared-factoring-add-edit-modal-part.component.html',
  encapsulation: ViewEncapsulation.None
})

export class AppSharedFactoringAddEditModalPartComponent implements OnInit, OnDestroy {

  // ---------------------------------------------------------------------
  public saveTrigger = new McTrigger();

  // ---------------------------------------------------------------------
  @Input() visibleMcb = new McBoolean();
  @Input() ebFactoringId!: number;
  @Input() ebFactoring!: EbFactoring;
  @Input() idChildEntity!: number;
  @Input() ebFactoringForRequestTypeList!: EbFactoring;
  @Output() eventCancel = new EventEmitter();
  @Output( )eventSaved = new EventEmitter();

  //  @Output() eventDone = new EventEmitter();

  // ---------------------------------------------------------------------
  constructor() {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
        this.actLoad();
  }

  // ---------------------------------------------------------------------
  ngOnDestroy() {
  }


  // ---------------------------------------------------------------------
  actLoad() {
  }

  // ---------------------------------------------------------------------
  actSave() {
    this.saveTrigger.fire();
    this.eventSaved.emit();
  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.visibleMcb.setFalse();
    this.eventCancel.emit();
  }

  // ---------------------------------------------------------------------
  isVisible() {
    if (!this.visibleMcb) {
      this.visibleMcb = new McBoolean();
    }
    return this.visibleMcb.value;
  }
}
