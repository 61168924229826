import {Component, OnInit} from '@angular/core';
import { McProcess, McBoolean, McForm, McGod, McProcessService, SortCriteriaList, SortCriteria } from '@miticon-ui/mc-core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import { MkFilterConfig, MkFilterOutput, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig } from '@miticon-ui/mc-components';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mc-process-process-list-page',
  templateUrl: './mc-process-process-list-page.component.html',
})
export class McProcessProcessListPageComponent implements OnInit {

  showAddEditProcessMcb = new McBoolean();
  mcForm = new McForm();
  mcGod = McGod.getInstance();
  successMsg!: string;
  errorMsg!: string;
  mcProcess = new McProcess();
  items: McProcess[] = [];
  pageNumber = 0;
  pageSize = 5;
  isLoading = false;
  totalItemsCount!: number;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  searchTooltip = this.tS.instant('cc.process-list-page-search-tooltip');
  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.common.view-profile'),
      actionCd: McProcess.ACTION_SHOW_PROFILE,
      matIcon: 'remove_red_eye',
      permission: McGod.PERM_MC_PROCESS_VIEW,
    },
    {
      title: this.tS.instant('cc.processes-view-items'),
      actionCd: McProcess.ACTION_SHOW_ITEM_LIST,
      matIcon: 'document_scanner',
      permission: McGod.PERM_MC_PROCESS_VIEW,
    },
    {
      title: this.tS.instant('cc.processes-view-email'),
      actionCd: McProcess.ACTION_SHOW_EMAIL,
      matIcon: 'document_scanner',
      permission: McGod.PERM_MC_PROCESS_VIEW,
    },
    {
      title: this.tS.instant('cc.common.activate'),
      actionCd: McProcess.ACTION_ACTIVATE,
      matIcon: 'edit',
      permission: McGod.PERM_MC_PROCESS_EDIT,
    },
    {
      title: this.tS.instant('cc.common.deactivate'),
      actionCd: McProcess.ACTION_DEACTIVATE,
      matIcon: 'edit',
      permission: McGod.PERM_MC_PROCESS_EDIT,
    }
  ];

  constructor(private router: Router,
              private toastr: ToastrService,
              private mcProcessService: McProcessService,
              private tS: TranslateService) {
  }

  ngOnInit() {
    this.actLoad(this.pageNumber, this.pageSize, new SortCriteria('id', 'DESC'));
    this.initTableConfig();
  }

  public actLoad(pageNumber: number, pageSize: number, sortCriteria: SortCriteria): void {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.isLoading = true;

    const sortCriteriaList = new SortCriteriaList();
    sortCriteriaList.addSort(sortCriteria.sortProperty, sortCriteria.sortType);

    this.mcProcessService.getByIdMcEntity(McGod.getLoggedEntityIdFromToken(), this.pageNumber, this.pageSize, sortCriteriaList).subscribe((res) => {
      if (res) {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => McProcess.createFromJson(object));
      }
    });
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(this.tS.instant('cc.processes.process-name'), 'title', 250, 'title');
    this.tableConfig.addColumnSvgIcon(this.tS.instant('cc.processes.process-type'), 'getTypeCdLabel()', 'typeCd');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.processes.flows-active-total'), 'fldFlowsActiveTotal()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.processes.active-items'), 'activeMcProcessFlowCount', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.processes.last-run'), 'getLastRunDateTimeStr()', 250);
    this.tableConfig.addColumnInnerHtml(this.tS.instant('cc.common.status'), 'fldStatus()', 'statusCd');
  }

  onFilterChanged(filterOutput: MkFilterOutput){
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, filterOutput.sort);
  }

  actCreateProcess() {
    this.showAddEditProcessMcb.setTrue();
  }

  actCloseAddEditProcess() {
    this.showAddEditProcessMcb.setFalse();
  }

  actAddEditProcessSaved() {
    this.showAddEditProcessMcb.setFalse();
    this.toastr.success(`${McGod.t('cc.processes.process-successfully-created')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
    this.actLoad(this.pageNumber, this.pageSize, new SortCriteria('id', 'DESC'));
  }

  onTableAction($event: MkMatTableMenuAction) {
    switch ($event.action.actionCd){
      case McProcess.ACTION_SHOW_PROFILE:
        this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-profile/${$event.item.id}`);
        break;
      case McProcess.ACTION_SHOW_ITEM_LIST:
        this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-items/${$event.item.id}`);
        break;
      case McProcess.ACTION_SHOW_EMAIL:
        this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-email/${$event.item.id}`);
        break;
      case McProcess.ACTION_ACTIVATE:
        this.activateProcess($event.item);
        break;
      case McProcess.ACTION_DEACTIVATE:
        this.deactivateProcess($event.item);
        break;
    }
  }

  activateProcess(mcProcess: McProcess) {
    this.mcProcess = mcProcess;
    this.mcProcess.statusCd = McProcess.STATUS_CD_ACTIVE;
    this.mcProcess.save( () => {
      this.toastr.success(this.mcGod.t('cc.processes.process-successfully-activated'));
      this.actLoad(this.pageNumber, this.pageSize, new SortCriteria('id', 'DESC'));

    }, () => {
      this.toastr.error(this.mcProcess.apiErrorMessage);
      this.mcProcess.statusCd = McProcess.STATUS_CD_INACTIVE;
    });
  }

  deactivateProcess(mcProcess: McProcess) {
    this.mcProcess = mcProcess;
    this.mcProcess.statusCd = McProcess.STATUS_CD_INACTIVE;
    this.mcProcess.save( () => {
      this.toastr.success(this.mcGod.t('cc.processes.process-successfully-deactivated'));
      this.actLoad(this.pageNumber, this.pageSize, new SortCriteria('id', 'DESC'));

    }, () => {
      this.toastr.error(this.mcProcess.apiErrorMessage);
      this.mcProcess.statusCd = McProcess.STATUS_CD_ACTIVE;
    });
  }

}
