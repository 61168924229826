<div class="content-wrapper">
<div class="content-header">
  <div class="row p-0 m-0 d-flex flex-row align-items-center">
    <h3 class="pr-2 pt-1" style="text-transform: capitalize">Entities using template '{{mcPriceList.title}}'</h3>
  </div>
</div>


<div class="content-body">

  <mcc-message [infoMsg]="infoMsg"></mcc-message>

  <div>
    <mk-mat-table
      [isLoading]="isLoading"
      [items]="mcEntitiesUsingPricelistList.items"
      [config]="tableConfig"
      [totalItemsCount]="mcEntitiesUsingPricelistList.totalItemsCount"
      [itemsPerPage]="mcEntitiesUsingPricelistList.pageItemsPerPageCount"
      (filterChanged)="onFilterChanged($event)">
      >
    </mk-mat-table>
  </div>


</div>
<div class="content-footer">
  <mcc-button class="move-right" extraCssClasses="btn btn-secondary move-right" (eventClicked)="actClose();"
              label="Close"></mcc-button>
</div>
</div>

