import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McApplyOptionsComponent } from './mc-apply-options.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [McApplyOptionsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule
  ],
  exports: [McApplyOptionsComponent]
})
export class McApplyOptionsModule { }
