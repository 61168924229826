import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'mcc-message',
  templateUrl: './mcc-message.component.html',
  styleUrls: ['./mcc-message.component.scss']
})
export class MccMessageComponent implements OnInit {

  public valuesArray: Array<any> = [];
  /*Events*/

  // @Input() valueOrValues;
  @Input() extraCssClasses = '';
  @Input() label = '';
  @Input() errorMsg!: string;
  @Input() errorMsgs!: string[];
  @Input() infoMsg!: string;
  @Input() infoMsgs!: string[];
  @Input() successMsg!: string;
  @Input() successMsgs!: string[];

  /*Constructor*/
  constructor(private router: Router) {
  }

  ngOnInit(): void {

  }

}
