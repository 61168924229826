<h3 class="page-title">{{"cc.factoring.transactions" | translate}}</h3>
<div>
    <lib-mc-loader [showLoader]="ebSepaFileTransactionList.apiLoadingFlg || loadingFlg"></lib-mc-loader>

    <div *ngIf="ebSepaFileTransactionList.apiErrorFlg">
        Error: {{ebSepaFileTransactionList.apiErrorMessage}}
    </div>

  <app-shared-filter-wrapper>
    <div class="filter-container">
      <div class="filter-inputs">
        <!-- Search-->
        <mcc-fi-textbox [isClearEnabled]="false" label="{{'cc.common.search' | translate}}" name="txbSearchTerm"
                        (eventEnterClicked)="actLoad()"
                        [mcForm]="mcForm"
                        tooltipClass="mc-tooltip-length"
                        ngbTooltip="{{'cc.my-factoring.search-by-consumer-name-description-contract-number' | translate}}"
                        placeholder="Search"
                        placement="bottom-left">
        </mcc-fi-textbox>

        <!-- Create type-->
        <mcc-multiselect-filter [optionVll]="typeCdVll" label="{{'cc.common.create-type' | translate}}" name="selType"
                                (eventSelectedItems)="onSelectedTypeCds($event)" [clearedAllFilters]="clearedAllMultipleFilters">
        </mcc-multiselect-filter>

        <!-- Status-->
        <mcc-multiselect-filter [optionVll]="ebSepaFileTransactionCdVll"
                                [labelHasTransactionIcon]="true"
                                label="{{'cc.common.view.status' | translate}}" name="selStatus"
                                (eventSelectedItems)="onSelectedStatus($event)" [clearedAllFilters]="clearedAllMultipleFilters">
        </mcc-multiselect-filter>

        <!-- Entity-->
        <mcc-multiselect-filter [optionVll]="entitiesWithTransactionsVll" label="{{'cc.consumer.studio' | translate}}"
                                name="selEntity" (eventSelectedItems)="onSelectedEntity($event)" [clearedAllFilters]="clearedAllMultipleFilters">
        </mcc-multiselect-filter>

        <!-- Dunning-->
        <mcc-fi-select [mcForm]="mcForm" [labelHasDunningIcon]="true" name="selInDunning" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="Dunning"
                       [optionsVll]="inDunningCdVll" [isSelectInFilter]="true">
        </mcc-fi-select>

        <!-- Type-->
        <mcc-multiselect-filter [optionVll]="typeCdVll" label="{{'cc.jobs.edit.type' | translate}}" name="selType"
                                (eventSelectedItems)="onSelectedTypeCds($event)" [clearedAllFilters]="clearedAllMultipleFilters">
        </mcc-multiselect-filter>

        <!-- Date-->
        <mcc-fi-date-picker datePickerViewType="RANGE_SELECTION" [mcForm]="mcForm" name="ctrlPaymentDueDate" label="Payment due date range"
                            (eventFromDate)="onPaymentDueDateFrom($event)" (eventToDate)="onPaymentDueDateTo($event)" [allowManualEntryFlg]="false"
                            monthsDisplayed="2" [clearDateRange]="clearDateRangeSubject">
        </mcc-fi-date-picker>
      </div>
      <div class="filter-actions">
        <button class="clear-all" (click)="actClearFilter()">{{'cc.common.clear-all-filters' | translate}}</button>
        <button class="mc-button filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>
      </div>
    </div>
  </app-shared-filter-wrapper>

  <div class="actions-container">
    <span>{{'cc.transactions.transactions-list' | translate}}</span>
    <div class="actions-wrapper">
      <button *ngIf="ebSepaFileTransactionList.getCount() > 0" class="system-action-btn" (click)="actExportAllTransactions()"><i class="fas fa-file-export"></i>{{"cc.out-factoring.transactions.export-all" | translate}}</button>
    </div>
  </div>

  <!-- Transactions Table-->
  <div class="mc-transactions-table">
    <div *ngIf="ebSepaFileTransactionList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="ebSepaFileTransactionList"
                        [table3Config]="ebSepaFileTransactionTable3Config"
                        [additionalTableInfo]="additionalTableInfo"
                        (eventSelectionChanged)="receiveStateOnSelect($event)"
                        (eventPaginationOrSortChanged)="actLoad()"
                        (eventItemAction)="actOnTransactionTableItemAction($event)">
        </mcc-fi-table-3>
    </div>
  </div>

  <mcc-dialog-box *ngIf="showMarkAsSentToInkassoMcb.value" [enableCloseButtonFlg]="false"
                  title="Mark as sent to inkasso" name="mar-as-sent-to-inkasso"
                  [description]="markAsSentToBankDialogMessage"
                  [buttonsKvm]="buttonsMarkAsSentToInkassoDialog"
                  popupType="question"
                  (eventActionButtonClicked)="onMcDialogBoxMarkAsSentToInkassoActionButtonClicked($event)">
  </mcc-dialog-box>

 <!-- <mcc-modal1-wrapper *ngIf="showSepaExportMcb.value" [showMcb]="showSepaExportMcb" modalSizeCd="full">
    <mc-out-factoring-sepa-export-part [ebSepaFileTransactionList]="ebSepaFileTransactionList.getSelectedObjectList()" (eventExported)="actLoad()" (eventClosed)="showSepaExportMcb.setFalse()" (eventCanceled)="showSepaExportMcb.setFalse()"></mc-out-factoring-sepa-export-part>
  </mcc-modal1-wrapper>-->
<!--  <mcc-modal1-wrapper *ngIf="showConsumerProfileMcb.value" [showMcb]="showConsumerProfileMcb" modalSizeCd="full">-->
<!--    <mc-consumer-consumer-profile-part  (eventClosed)="actCancel()" [mcConsumerId]="consumerId"></mc-consumer-consumer-profile-part>-->
<!--  </mcc-modal1-wrapper>-->

  <mcc-modal1-wrapper *ngIf="showTransactionAddPaymentMcb.value" [showMcb]="showTransactionAddPaymentMcb" modalSizeCd="small">
    <app-shared-transaction-adjustment-add-edit-part ebFactoringLevel="PARENT" [ebSepaFileTransaction]="actionEbSepaFileTransaction" type="payment" (eventSaved)="actOnTransactionAddPaymentClose()" (eventCanceled)="showTransactionAddPaymentMcb.setFalse()" ebTransactionAdjustmentId="0" [ebSepaFileTransactionId]="actionEbSepaFileTransactionId"></app-shared-transaction-adjustment-add-edit-part>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper *ngIf="showTransactionProfileMcb.value" [showMcb]="showTransactionProfileMcb" modalSizeCd="full" (eventCanceled)="actLoad()">
    <app-shared-transaction-profile-part [ebSepaFileTransactionId]="actionEbSepaFileTransaction.id" (eventClose)="actOnTransactionProfileClose()" ebFactoringLevel="PARENT"></app-shared-transaction-profile-part>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showAddPaymentForMultipleTransactions.value"
                      [showMcb]="showAddPaymentForMultipleTransactions">
    <app-shared-transaction-payment-add-edit-part
                                                  (eventCanceled)="actCancelAddEditMultiplePayments()" (eventApiError)="paymentApiErrorEvent($event)"
                                                  (eventSaved)="actPaymentMultipleTransactionsSaved()">
    </app-shared-transaction-payment-add-edit-part>
  </mcc-modal1-wrapper>

<!--  <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showExportMcb.value" [showMcb]="showExportMcb" (eventCanceled)="actCanceledExportMcb()">-->
<!--    <app-shared-factoring-export-part [currencyCode]="currencyCode" [isExportAll]="isExportAll" [ebSepaFileTransactionFilter]="ebSepaFileTransactionFilter"-->
<!--                                      [ebSepaFileTransactionList]="checkedTransactionList" (eventExported)="actTransactionsExported()"-->
<!--                                      (eventSaved)="actExportTransactionsSaved($event)" (eventCanceled)="actCanceledExportMcb()"-->
<!--    ></app-shared-factoring-export-part>-->
<!--  </mcc-modal1-wrapper>-->

<!--  <mcc-modal1-wrapper [showMcb]="showPauseTransactionMcb" *ngIf="showPauseTransactionMcb.value">-->
<!--    <app-shared-pause-transactions (eventPausedTransaction)="onPauseTransaction()"-->
<!--                                   (eventUnpausedTransaction)="onUnpauseTransaction()"-->
<!--                                   (eventPauseTransactionCanceled)="onPauseTransactionCanceled()"-->
<!--                                   [ebSepaFileTransactionList]="selectedTransactionList"-->
<!--                                   [ebSepaFileTransaction]="actionEbSepaFileTransaction"-->
<!--    ></app-shared-pause-transactions>-->
<!--  </mcc-modal1-wrapper>-->

  <mcc-slide-action-set [actionSet]="actionSet" [selectedNumber]="numberOfSelectedItems" [showSlideActionSetNoOfItems]="1"
                        (eventActionClicked)="slideActionItemClicked($event)">
  </mcc-slide-action-set>

</div>

