/*BbmTestObj*/
import {EbConsumerBlacklistTable3ConfigGENERATED} from '../_generated/eb-consumer-blacklist-table-3-config-generated.model';

// import {EbConsumerBlacklistGENERATED} from '../_generated/eb-consumer-blacklist-generated.model';

export class EbConsumerBlacklistTable3Config extends EbConsumerBlacklistTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colName', this.t('cc.consumers.view.consumer-name'), 'fldName()', 'firstName');
    this.addColumn('colEmail', this.t('cc.common.edit.email'), 'email', 'email');
    // this.addColumn('colAllowFactoringFlg', 'Allow factoring flag', 'activeBlacklist.allowFactoringFlg', 'ebConsumerBlacklist.allowFactoringFlg');
    // this.addColumn('colAllowDirectDebitFlg', 'Allow direct debit flag', 'activeBlacklist.allowDirectDebitFlg', 'ebConsumerBlacklist.allowDirectDebitFlg');
    this.addColumn('colAddedDate', this.t('cc.out-factoring.added-date-&-time'), 'fldAddedDate()', 'addedDate');
    this.addColumn('colAddedReason', this.t('cc.out-factoring.added-reason'), 'addedReason', 'addedReason');
    this.addColumn('colAddedBy', this.t('cc.out-factoring.added-by'), 'addedByUser', 'addedByUser.firstname');
    this.addColumn('colRemovedDate', this.t('cc.out-factoring.removed-date-&-time'), 'fldRemovedDate()', 'removedDate');
    this.addColumn('colRemovedReason', this.t('cc.out-factoring.removed-reason'), 'removedReason', 'removedReason');
    this.addColumn('colRemovedBy', this.t('cc.out-factoring.removed-by'), 'removedByUser', 'removedByUser.firstname');


  }
}
