import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {McAuthService, McForm, McRoleService, McUtilityService} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-shared-roles-create-role-from-template',
  templateUrl: './app-shared-roles-create-role-from-template.component.html',
})
export class AppSharedRolesCreateRoleFromTemplateComponent implements OnInit, OnDestroy {

  /*Variables*/
  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter();
  @Output() eventIsRoleTemplates = new EventEmitter();
  mcForm = new McForm();
  @Input() roleTemplates!: any[];
  apiCreateRoleSubscription!: Subscription;
  apiGetRoleSubscription!: Subscription;
  checkedRoleTemplate!: any;
  checkedRoleTemplates: any[] = [];
  isCheckboxChecked!: boolean;
  isButtonDisabled = true;

  constructor(private authService: McAuthService,
              private roleService: McRoleService,
              private utilityService: McUtilityService) {
  }

  /*On init*/
  ngOnInit() {
    this.roleTemplates.sort((a, b) => {
      const roleA = a.caption;
      const roleB = b.caption;
      return (roleA < roleB) ? -1 : (roleA > roleB) ? 1 : 0;
    });
  }

  onCancel() {
    this.eventCanceled.emit();
  }

  onCreate() {
    this.checkedRoleTemplates.forEach(item => {
      const permissionIds: any[] = [];
      item.permissionList.forEach((perm: any) => {
        permissionIds.push(perm.id);
      });
      const createRoleDto = {
        active: item.active,
        caption: item.caption,
        description: item.description,
        templateFlg: false,
        entity: item.entity,
        linkedFlg: true,
        ancestorId: item.id,
        ancestorEntityName: item.entity.name,
        mcEntityId: this.utilityService.getPropertyFromToken('entity_id'),
        permissionIds,
        isProtected: true
      };
      this.apiCreateRoleSubscription = this.roleService.createRole2Entity(createRoleDto).subscribe((res) => {
        this.eventSaved.emit();
      }, error => {
        console.log(error);
      });
    });
  }

  ngOnDestroy(): void {
    this.apiCreateRoleSubscription && this.apiCreateRoleSubscription.unsubscribe();
    this.apiGetRoleSubscription && this.apiGetRoleSubscription.unsubscribe();
  }

  onCheckboxChange($event: any, templateValue: any) {
    if ($event && this.isCheckboxChecked) {
      this.checkedRoleTemplate = templateValue.value;
      this.checkedRoleTemplates.push(this.checkedRoleTemplate);
    } else {
      this.checkedRoleTemplates.splice(this.checkedRoleTemplates.length - 1, 1);
    }
  }

  onCheckboxEventValueChanged($event: any) {
    this.isCheckboxChecked = !!$event;
    const checkboxes = document.querySelectorAll('input[type=checkbox]');
    const empty = [].filter.call( checkboxes, ( el:any ) => {
      return !el.checked;
    });

    if (checkboxes.length === empty.length) {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }
}
