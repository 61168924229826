import {Inject, Injectable} from '@angular/core';
import {PmTransactionServiceGENERATED} from '../_generated/pm-transaction-generated.service';
import {Observable} from "rxjs";
import {PmTransactionFilter} from "../models/pm-transaction-filter.model";
import {SortCriteriaList} from "../_core/sort-criteria-list.model";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PmTransactionService extends PmTransactionServiceGENERATED {

  public getPotentialMatches(aFilter: any, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/getPotentialMatches?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, aFilter.toDto(), {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });

  }

  getTotalAmountDue(consumerId: number): Observable<number> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/consumer/${consumerId}/due`, {headers: this.httpOptions});
  }

  exportToFileFormat(fileFormat: string, transactionFilter: PmTransactionFilter): Observable<number> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/export/${fileFormat}`, transactionFilter, { headers: this.httpOptions });
  }
}
