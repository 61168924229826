import {Component, OnInit} from '@angular/core';
import {
  McForm,
  McConsumerImportFilter, McUserList, McValueLabelList, SortCriteriaList, CsrConsumerImportService, CsrConsumerImportList, CsrConsumerImportTable3Config, CsrConsumerImport
} from "@miticon-ui/mc-core";
import {HttpParams} from "@angular/common/http";
@Component({
  selector: 'mc-consumer-consumer-import-list-page',
  templateUrl: './mc-consumer-consumer-import-list-page.component.html',
})
export class McConsumerConsumerImportListPageComponent implements OnInit {

  mcForm = new McForm();
  csrConsumerImportList = new CsrConsumerImportList();
  csrConsumerImportService = new CsrConsumerImportService();
  mcConsumerImportListFilter = new McConsumerImportFilter();
  csrConsumerImportTable3Config = new  CsrConsumerImportTable3Config();
  mcUsers = new McUserList();
  mcUsersVll = new McValueLabelList();
  // ---------------------------------------------------------------------
  ngOnInit() {
    this.csrConsumerImportList.setPager(0, 100);
    this.csrConsumerImportList.setSortB('id', 'DESC');
    this.mcUsers.loadAllThatImportedConsumers(() => {
      this.mcUsersVll = this.mcUsers.toVll('id', 'fldFullName()')
    });
    this.actLoad();
  }

  // ---------------------------------------------------------------------

  public actLoad(): void{
    this.mcConsumerImportListFilter = new McConsumerImportFilter();
    this.mcConsumerImportListFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.mcConsumerImportListFilter.userIds = this.mcForm.getValueArray('selUserId');
    let params = new HttpParams()
      .set('term', this.mcConsumerImportListFilter.searchTerm ? this.mcConsumerImportListFilter.searchTerm : '')
      .set('userId', this.mcConsumerImportListFilter.userIds[0] ? this.mcConsumerImportListFilter.userIds[0]: '')
      .set('page', 0)
      .set('size', 100);

    const sortCriteria = new SortCriteriaList();
    sortCriteria.addSort('id', 'DESC');

    this.csrConsumerImportService.get(params, 0, 100, sortCriteria)
      .subscribe((res) => {
        this.csrConsumerImportList.apiSuccessFlg = true
        this.csrConsumerImportList.items = res.content.map((object: any) => CsrConsumerImport.createFromJson(object));
      });

  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.mcForm.getControl('selUserId')?.get('value')?.setValue('');
    this.actLoad();
  }
}
