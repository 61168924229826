/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EbSepaTransactionTable3ConfigGENERATED} from '../_generated/eb-sepa-transaction-table-3-config-generated.model';

// import {EbSepaTransactionGENERATED} from '../_generated/eb-sepa-transaction-generated.model';

export class EbSepaTransactionTable3Config extends EbSepaTransactionTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();


  }
}
