
export class McSlideActionItem {
  label: string;
  actionCdForEvent: string;
  item: any;
  extraCssClass: string;
  permissions: string[];
  divider: boolean;
  numberOfMaxSelectedItems: number;

  public static get(label: string, actionCdForEvent: string, permissions: string[], divider: boolean,
                    numberOfMaxSelectedItems: number = -1, extraCssClass: string = '') {

    const item = new McSlideActionItem(label, actionCdForEvent, permissions, divider, numberOfMaxSelectedItems, extraCssClass);
    return item;

  }
  constructor(label: string, actionCdForEvent: string, permissions: string[], divider: boolean,
              numberOfMaxSelectedItems: number = -1, extraCssClass: string = '') {

    this.label = label;
    this.actionCdForEvent = actionCdForEvent;
    this.divider = divider;
    this.numberOfMaxSelectedItems = numberOfMaxSelectedItems;
    this.extraCssClass = extraCssClass;
    this.permissions = permissions;

  }
}
