import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {
  McBillingService2PriceList,
  McBillingServiceCatalog,
McBillingServiceUnitType, McPriceList
} from "@miticon-ui/mc-core";
import {
  McForm,
  McBoolean,
  McGod,
  McValueLabelList
} from "@miticon-ui/mc-core";
import {Subscription} from "rxjs";

@Component({
  selector: "mc-pricelist-pricelist-profile-service-edit-part",
  templateUrl: "./mc-pricelist-pricelist-profile-service-edit-part.component.html",
})
export class McPricelistPricelistProfileServiceEditPartComponent implements OnInit {
  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  saveSubscription!: Subscription;
  deleteSubscription!: Subscription;
  cancelSubscription!: Subscription;
  errorMsg = "";
  unitType = "";
  additionalDoNotChargeFlg = false;
  chargeOptionsVll!: McValueLabelList;
  disabledOptionsVll!: McValueLabelList;
  serviceTypeCd!: string;
  additionalDoNotChargeLabel!: string;
  additionalDoNotChargeValue!: boolean;
  isServiceTypeUserSetup = false;
  service2PricelistStartingCopy = new McBillingService2PriceList();
  refreshFlg = true;
  oneTimePrice!: any;
  templatePricelist = new McPriceList();
  defaultService: McBillingService2PriceList | undefined = new McBillingService2PriceList();
  restoredToDefault!: boolean;

  // -- Input --
  @Input() mcBillingService2PriceListId!: number;
  @Input() mcBillingService2PriceList!: McBillingService2PriceList;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;
  @Input() templatePricelistId!: number;
  @Input() areServiceButtonsDisabled = false;
  @Input() currency!: string;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();

  // ---------------------------------------------------------------------
  ngOnInit() {
    // this.service2PricelistStartingCopy = this.mcBillingService2PriceList;
    this.chargeOptionsVll = new McValueLabelList();
    this.disabledOptionsVll = new McValueLabelList();


    if (this.templatePricelistId) {
      this.templatePricelist.loadById(this.templatePricelistId, () => {
        this.defaultService = this.templatePricelist.mcBillingService2PriceListItemList.items.find(item =>
          item.serviceTypeCd === this.mcBillingService2PriceList.serviceTypeCd);
        this.actLoad();
      });
    } else {
      this.actLoad();
    }
  }


  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.setValueInFields();

    this.unitType = McBillingServiceUnitType.getTypeCdLabel(this.mcBillingService2PriceList.activityUnitTypeCd);

    this.refreshFlg = true;
  }

  // ---------------------------------------------------------------------
  actSave() {

    if (this.mcForm.isValid()) {
      if (!this.mcBillingService2PriceList.flgItemFromTemplate) { // TODO: need to fix this, customPriceFlg, currently no other way because of field component event
        this.mcBillingService2PriceList.flgCustomPrice = !(this.mcBillingService2PriceList.pricingTypeCd === this.defaultService?.pricingTypeCd &&
          this.mcBillingService2PriceList.priceOneTime === this.defaultService?.priceOneTime &&
          this.mcBillingService2PriceList.priceFlat === this.defaultService.priceFlat &&
          this.mcBillingService2PriceList.unitLimitForFlatPrice === this.defaultService.unitLimitForFlatPrice &&
          this.mcBillingService2PriceList.priceAdditionalUnit === this.defaultService.priceAdditionalUnit &&
          this.mcBillingService2PriceList.flgDoNotChargeForFactoredTransactions === this.defaultService.flgDoNotChargeForFactoredTransactions);
      }
      if (this.restoredToDefault) {
        this.mcBillingService2PriceList.flgCustomPrice = false;
      }

      this.mcBillingService2PriceList.save(() => {
        this.eventSaved.emit(this.mcBillingService2PriceList.serviceName);
      }, () => {
        this.saveButtonDisabledMcb.setFalse();
        this.errorMsg = this.mcBillingService2PriceList.apiErrorMessage;
      });
    } else {
      this.saveButtonDisabledMcb.setFalse();
    }

  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.mcBillingService2PriceList.exists()) {

      this.mcBillingService2PriceList.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

// ---------------------------------------------------------------------
  validatePrerequisites() {
  }

  // ---------------------------------------------------------------------
  hasErrors() {
  }

  onChargeOptionChangeEmit(value: string) {
    this.mcBillingService2PriceList.pricingTypeCd = value;

    if (value === McBillingService2PriceList.PRICING_TYPE_CD_ONE_TIME_ONLY) {
      this.mcForm.getControl('ctrlValueMix')?.get('value')?.setValue('');
      this.mcForm.getControl('ctrlForUnits')?.get('value')?.setValue('');
      this.mcForm.getControl('ctrlValuePlusPerAdditionalUnit')?.get('value')?.setValue('');
      this.mcBillingService2PriceList.priceFlat = 0;
      this.mcBillingService2PriceList.unitLimitForFlatPrice = 0;
      this.mcBillingService2PriceList.priceAdditionalUnit = 0;
    } else if (value === McBillingService2PriceList.PRICING_TYPE_CD_MIX) {
      this.mcForm.getControl('ctrlValueOneTimeOnly')?.get('value')?.setValue('');
      this.mcBillingService2PriceList.priceOneTime = 0;
    } else {
      this.mcForm.getControl('ctrlValueMix')?.get('value')?.setValue('');
      this.mcForm.getControl('ctrlForUnits')?.get('value')?.setValue('');
      this.mcForm.getControl('ctrlValuePlusPerAdditionalUnit')?.get('value')?.setValue('');
      this.mcBillingService2PriceList.priceFlat = 0;
      this.mcBillingService2PriceList.unitLimitForFlatPrice = 0;
      this.mcBillingService2PriceList.priceAdditionalUnit = 0;
      this.mcForm.getControl('ctrlValueOneTimeOnly')?.get('value')?.setValue('');
      this.mcBillingService2PriceList.priceOneTime = 0;
    }
  }

  onAdditionalDoNotChargeValueChangeEmit(value: boolean) {
    if (this.serviceTypeCd === McBillingServiceCatalog.TYPE_CD_PDF_CREATION) {
      this.additionalDoNotChargeValue = value;
      this.mcBillingService2PriceList.flgDoNotChargeForFactoredTransactions = value;
    }
  }

  ondDoNotChargeForSelectedDomainsValueChangeEmit(value: any[]) {
    console.log("MULTISELECT", value);
    let result = "";
    value.forEach((item, index) => {
      result += `${item}${index === value.length - 1 && ";"}`;
    });
    this.mcBillingService2PriceList.notChargeWebDomains = result;
  }

  onOneTimeOnlyChargeValueChangeEmit(value: number) {
    this.mcBillingService2PriceList.priceOneTime = value;
  }

  onMixFlatPriceValueChangeEmit(value: number) {
    this.mcBillingService2PriceList.priceFlat = value;
  }

  onLimitForUnitsValueChangeEmit(value: number) {
    this.mcBillingService2PriceList.unitLimitForFlatPrice = value;
  }

  onMixPlusPerAdditionUnitChangeValuEmit(value: number) {
    this.mcBillingService2PriceList.priceAdditionalUnit = value;
  }

  undoChanges() {
    this.refreshFlg = false;
    this.service2PricelistStartingCopy.loadById(this.mcBillingService2PriceList.id, () => {
      this.mcBillingService2PriceList = Object.create(this.service2PricelistStartingCopy);
      this.refreshFlg = true;
    });
  }

  restoreDefaults() {

    if (this.defaultService) {
      this.mcBillingService2PriceList.pricingTypeCd = this.defaultService.pricingTypeCd;
      this.mcBillingService2PriceList.priceOneTime = this.defaultService.priceOneTime;
      this.mcBillingService2PriceList.priceFlat = this.defaultService.priceFlat;
      this.mcBillingService2PriceList.unitLimitForFlatPrice = this.defaultService.unitLimitForFlatPrice;
      this.mcBillingService2PriceList.priceAdditionalUnit = this.defaultService.priceAdditionalUnit;
      this.restoredToDefault = true;

      if (this.serviceTypeCd === McBillingServiceCatalog.TYPE_CD_PDF_CREATION) {
        this.mcBillingService2PriceList.flgDoNotChargeForFactoredTransactions = this.defaultService.flgDoNotChargeForFactoredTransactions;
      }
    }


    this.setValueInFields();

    this.mcForm.getControl('ctrlValueOneTimeOnly')?.get('value')?.setValue(this.mcBillingService2PriceList.priceOneTime);
    this.mcForm.getControl('ctrlValueMix')?.get('value')?.setValue(this.mcBillingService2PriceList.priceFlat);
    this.mcForm.getControl('ctrlForUnits')?.get('value')?.setValue(this.mcBillingService2PriceList.unitLimitForFlatPrice);
    this.mcForm.getControl('ctrlValuePlusPerAdditionalUnit')?.get('value')?.setValue(this.mcBillingService2PriceList.priceAdditionalUnit);
  }

  setValueInFields() {
    this.oneTimePrice = this.mcBillingService2PriceList.priceOneTime ? this.mcBillingService2PriceList.priceOneTime : '';
    // debugger

    this.chargeOptionsVll = McBillingService2PriceList.getPricingTypeCdVll();
    this.disabledOptionsVll = new McValueLabelList();
    this.disabledOptionsVll.items = this.chargeOptionsVll.items.filter(item => item.value !== this.mcBillingService2PriceList.pricingTypeCd);

    this.serviceTypeCd = this.mcBillingService2PriceList.serviceTypeCd;
    if (this.serviceTypeCd === McBillingServiceCatalog.TYPE_CD_PDF_CREATION) {
      this.additionalDoNotChargeFlg = true;
      this.additionalDoNotChargeValue = this.mcBillingService2PriceList.flgDoNotChargeForFactoredTransactions;
      this.additionalDoNotChargeLabel = McGod.t('cc.pricelist.do-not-charge-for-factored-transactions');
    } else if (this.serviceTypeCd === McBillingServiceCatalog.TYPE_CD_USER_ACCOUNT_FEE) {
      this.additionalDoNotChargeFlg = true;
      // this.additionalDoNotChargeValue = this.mcBillingService2PriceList.flgDoNotChargeForWebDomains;
      // this.additionalDoNotChargeLabel = McGod.t('cc.pricelist.do-not-charge-for-selected-domains');
      this.isServiceTypeUserSetup = true;
    }
  }
}
