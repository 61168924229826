<mcc-message [infoMsg]="infoMessage"></mcc-message>
<mcc-message [errorMsg]="errorMessage"></mcc-message>

<div class='card-wrapper'>

  <!--  Automatic Renewal Card-->
  <app-shared-card [showHeader]="false">
    <div class="pl-4 pr-4">
      <h6 class="mb-2">{{'cc.consumer.contract-renewal' | translate}}</h6>
      <div class="d-flex mb-2">
        <div class="mr-3" [ngClass]="disableAutomaticCheckboxFlg ? 'mc-disable-button' : ''">
          <mcc-fi-checkbox [mcForm]="mcForm"
                           label="{{'cc.consumer.automatic-renewal' | translate}}"
                           name="ctrlAutomaticRenewal"
                           (eventValueChanged)="onAutomaticRenewal($event)">
          </mcc-fi-checkbox>
        </div>
          <div [ngClass]="disableNoAutomaticCheckbox ? 'mc-disable-button' : ''">
            <mcc-fi-checkbox [mcForm]="mcForm"
                             label="{{'cc.consumer.no-automatic-renewal' | translate}}"
                             name="ctrlNoAutomaticRenewal"
                             (eventValueChanged)="onNoAutomaticRenewal($event)"></mcc-fi-checkbox>
          </div>
        </div>
        <div class="d-flex align-middle" [ngClass]="disableRenewalDaysMonths ? 'mc-disable-button' : ''">
          <mcc-fi-select [optionsVll]="automaticRenewalDaysVll"
                         [value]="'30'"
                         [mcForm]="mcForm" name="ctrlAutomaticRenewalDays"
                         (eventValueChanged)="onAutomaticRenewalDaysValueChanged($event)"></mcc-fi-select>
          <span class="ml-2 mr-2 mt-2">{{'cc.consumer.before-ending-extends-for' | translate}}</span>
          <mcc-fi-select [optionsVll]="automaticRenewalMonthsVll"
                         value="12"
                         [mcForm]="mcForm" name="ctrlAutomaticRenewalYears">
          </mcc-fi-select>
        </div>
      </div>
  </app-shared-card>
</div>



