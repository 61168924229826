<form *ngIf="isVisible()">
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-wrapper">
    <lib-mc-loader [showLoader]="csrConsumer.apiLoadingFlg"></lib-mc-loader>

    <div *ngIf="(!csrConsumer.apiLoadingFlg)">

      <!--THIS CONTAINER IS MANDATORY-->
      <div class="content-header">
        <span class="content-header-title" *ngIf="csrConsumer.exists()">{{'cc.consumer.overview.edit-consumer' | translate}}: {{csrConsumer.individual ? csrConsumer.fldFullName() : csrConsumer.companyName }}</span>
        <span class="content-header-title" *ngIf="!csrConsumer.exists()">{{'cc.consumers.view.create-new-consumer' | translate}}</span>
        <mcc-message [errorMsg]="csrConsumer.apiErrorMessage"></mcc-message>
      </div>

      <!--THIS CONTAINER IS MANDATORY-->
      <div class="content-body">
        <h2>{{'cc.consumer.overview.status-information' | translate}}</h2>
        <div class="content-section">
          <mcc-fi-radio-buttons label="{{'cc.jobs.edit.type' | translate}}"
                                [name]="'ctrlTypeCd'"
                                [directionHorizontal]="true"
                                [value]="csrConsumer.typeCd"
                                [mcForm]="mcForm"
                                [validRequiredFlg]="true"
                                (eventValueChanged)="onTypeCodeChanged($event)"
                                [optionsVlm]="typeVlm"></mcc-fi-radio-buttons>

          <mcc-fi-radio-buttons label="{{'cc.consumers.action.type.dunning' | translate}}"
                                [name]="'ctrlDunningFlg'"
                                [value]="dunningFlg ? 'yes': 'no'"
                                [directionHorizontal]="true"
                                [mcForm]="mcForm"
                                [validRequiredFlg]="true"
                                (eventValueChanged)="onDunningFlgChanged($event)"
                                [optionsVlm]="yesNoVlm"></mcc-fi-radio-buttons>

          <mcc-fi-textbox style="margin-top: 15px;" name="ctrlExtConsumerId" label="{{'cc.common.edit.external-id' | translate}}"
                          [value]="csrConsumer.extConsumerId"
                          [newStyleInput]="true"
                          [mcForm]="mcForm">
          </mcc-fi-textbox>
        </div>

        <h2>{{'cc.common.basic-information' | translate}}</h2>
        <div class="content-section">
          <mcc-fi-textbox name="ctrlCompanyName" label="{{'cc.common.company-name' | translate}}"
                          [value]="csrConsumer.companyName" [validRequiredFlg]="!firstLastNameRequiredFlg"
                          [mcForm]="mcForm" [newStyleInput]="true" [validOnlyNumbersLettersHyphenDotSpaceAndApostrophe]="true">
          </mcc-fi-textbox>

          <mcc-fi-textbox name="ctrlFirstName" label="{{'cc.common.edit.first-name' | translate}}"
                          [value]="csrConsumer.firstName" [validRequiredFlg]="firstLastNameRequiredFlg"
                          [validMinLength]="2" [validOnlyNumbersLettersHyphenDotSpaceAndApostrophe]="true"
                          [mcForm]="mcForm" [newStyleInput]="true">
          </mcc-fi-textbox>

          <mcc-fi-textbox name="ctrlLastName" label="{{'cc.common.edit.last-name' | translate}}"
                          [value]="csrConsumer.lastName" [validRequiredFlg]="firstLastNameRequiredFlg"
                          [validMinLength]="2" [validOnlyNumbersLettersHyphenDotSpaceAndApostrophe]="true"
                          [mcForm]="mcForm" [newStyleInput]="true">
          </mcc-fi-textbox>

          <mcc-fi-textbox name="ctrlTitle" label="{{'cc.common.edit.title' | translate}}"
                          [value]="csrConsumer.title"
                          [mcForm]="mcForm" [newStyleInput]="true">
          </mcc-fi-textbox>


          <mcc-fi-date-picker
            label="{{'cc.common.edit.birthday' | translate}}"
            [name]="'ctrlBirthday'"
            [valueInput]="csrConsumer.birthday"
            [maxDateInput]="today"
            [newStyleDate]="true"
            [mcForm]="mcForm">
          </mcc-fi-date-picker>

          <mcc-fi-select [mcForm]="mcForm"
                         name="ctrlGender"
                         label="{{'cc.common.edit.gender' | translate}}"
                         [value]="csrConsumer.gender"
                         placeholderOption="{{'cc.common.edit.gender' | translate}}"
                         [optionsVll]="genderVll"></mcc-fi-select>
        </div>

        <h2>{{"cc.common.edit.address" | translate}}</h2>
        <div class="content-section">
          <mcc-fi-textbox name="ctrlStreet" label="{{'cc.consumers.edit.street' | translate}}"
                          [value]="csrConsumer.street"
                          [mcForm]="mcForm" [newStyleInput]="true">
          </mcc-fi-textbox>

          <mcc-fi-textbox name="ctrlAddressSupplement" label="{{'cc.consumers.edit.address-supplement' | translate}}"
                          [value]="csrConsumer.addressSupplement"
                          [mcForm]="mcForm" [newStyleInput]="true">
          </mcc-fi-textbox>

          <mcc-fi-textbox name="ctrlPostCode" label="{{'cc.consumers.edit.postal-code' | translate}}"
                          [value]="csrConsumer.postCode"
                          [mcForm]="mcForm" [newStyleInput]="true">
          </mcc-fi-textbox>

          <mcc-fi-textbox name="ctrlCity" label="{{'cc.common.edit.city' | translate}}"
                          [value]="csrConsumer.city"
                          [mcForm]="mcForm" [newStyleInput]="true">
          </mcc-fi-textbox>
          <mcc-fi-textbox name="ctrlCountryName" label="{{'cc.users.edit.country' | translate}}"
                          [value]="csrConsumer.countryName"
                          [mcForm]="mcForm" [newStyleInput]="true">
          </mcc-fi-textbox>
        </div>

        <h2>{{ "cc.consumer.overview.contact" | translate}}</h2>
        <div class="content-section">
          <mcc-fi-textbox name="ctrlEmail" label="{{'cc.common.edit.email' | translate}}"
                          [value]="csrConsumer.email" [validEmail]="true"
                          [validRequiredFlg]="true"  [validWhitespaces]="true"
                          [mcForm]="mcForm" [newStyleInput]="true">
          </mcc-fi-textbox>

          <mcc-fi-textbox name="ctrlTelephone" label="{{'cc.common.edit.telephone' | translate}}"
                          [value]="csrConsumer.telephone" [validPhone]="true"
                          [mcForm]="mcForm" [newStyleInput]="true">
          </mcc-fi-textbox>

          <mcc-fi-textbox name="ctrlCellphone" label="{{'cc.consumers.edit.cellphone' | translate}}"
                          [value]="csrConsumer.cellphone" [validPhone]="true"
                          [mcForm]="mcForm" [newStyleInput]="true">
          </mcc-fi-textbox>

          <mcc-fi-textbox name="ctrlFax" label="{{'cc.common.edit.fax' | translate}}"
                          [mcForm]="mcForm" [newStyleInput]="true" [value]="csrConsumer.fax" [validPhone]="true">
          </mcc-fi-textbox>

        </div><!-- col-1 -->

        <h2>{{"cc.users.edit.language" | translate}}</h2>
        <div class="content-section">
          <mcc-fi-select [mcForm]="mcForm"
                         name="ctrlIsoCountry"
                         label="{{'cc.consumers.edit.iso-country' | translate}}"
                         [value]="csrConsumer.isoCountry"
                         placeholderOption="{{'cc.common.edit.choose-country' | translate}}"
                         [optionsVll]="countryCdsVll"></mcc-fi-select>

          <mcc-fi-select [mcForm]="mcForm"
                         name="ctrlIsoLanguage"
                         label="{{'cc.consumers.edit.iso-language' | translate}}"
                         [value]="csrConsumer.isoLanguage"
                         placeholderOption="{{'cc.common.edit.choose-language' | translate}}"
                         [optionsVll]="languagesCdsVll"></mcc-fi-select>
        </div>

        <h2 *ngIf="csrConsumer.entityConsumerAttributes">{{'cc.consumer.overview.additional-information' | translate}}</h2>
        <div *ngIf="csrConsumer.entityConsumerAttributes" class="row">
          <h2>{{'cc.common.bank-information' | translate}}</h2>
          <div class="content-section">
            <mcc-fi-textbox name="ctrlAccountOwner" label="{{'cc.consumers.edit.account-owner' | translate}}"
                            [value]="csrBankAccount.owner"
                            [mcForm]="mcForm" [newStyleInput]="true">
            </mcc-fi-textbox>

            <mcc-fi-textbox name="ctrlBankName" label="{{'cc.common.bank-name' | translate}}"
                            [value]="csrBankAccount.bankName"
                            [mcForm]="mcForm" [newStyleInput]="true">
            </mcc-fi-textbox>

            <mcc-fi-textbox name="ctrlIban" label="{{'cc.consumers.edit.iban' | translate}}"
                            [value]="csrBankAccount.iban" [validOnlyIntegersAndLetters]="true"
                            [mcForm]="mcForm" [newStyleInput]="true">
            </mcc-fi-textbox>

            <mcc-fi-textbox name="ctrlBic" label="{{'cc.consumers.edit.bic' | translate}}"
                            [value]="csrBankAccount.bic"
                            [mcForm]="mcForm" [newStyleInput]="true">
            </mcc-fi-textbox>

            <mcc-fi-textbox name="ctrlSepaMandateId" label="Sepa Mandante ID"
                            [value]="csrBankAccount.sepaMandateId"
                            [mcForm]="mcForm" [newStyleInput]="true">
            </mcc-fi-textbox>

            <mcc-fi-date-picker
              label="Sepa mandante date of signature"
              [name]="'ctrlSepaMandateDate'"
              [valueInput]="csrBankAccount.sepaMandateDate"
              [newStyleDate]="true"
              [mcForm]="mcForm">
            </mcc-fi-date-picker>

          </div>

          <h2>{{'cc.consumers.edit.contract-information' | translate}}</h2>
          <div class="content-section">
            <mcc-fi-textbox name="ctrlContractNumber" label="{{'cc.common.contract-number' | translate}}"
                            [value]="csrConsumer.contractNumber ? csrConsumer.contractNumber : csrConsumer.contractAttributes.contractNumber" [validRequiredFlg]="false" [validOnlyNumbersLettersAndHyphen]="true" [validWhitespaces]="true"
                            [mcForm]="mcForm" [newStyleInput]="true">
            </mcc-fi-textbox>

            <mcc-fi-textbox name="ctrlContractModel" label="{{'cc.common.contract-model' | translate}}"
                            [value]="csrConsumer.contractAttributes.contractModel" [validWhitespaces]="true"
                            [mcForm]="mcForm" [newStyleInput]="true">
            </mcc-fi-textbox>

            <mcc-fi-textbox name="ctrlContractType" label="{{'cc.common.contract-type' | translate}}"
                            [value]="csrConsumer.contractAttributes.contractType" [validWhitespaces]="true"
                            [mcForm]="mcForm" [newStyleInput]="true">
            </mcc-fi-textbox>

            <mcc-fi-textbox name="ctrlContractStatus" label="{{'cc.common.contract-status' | translate}}"
                            [value]="csrConsumer.contractAttributes.contractStatus" [validWhitespaces]="true"
                            [mcForm]="mcForm" [newStyleInput]="true">
            </mcc-fi-textbox>

            <mcc-fi-date-picker
              label="{{'cc.common.contract-signing-date' | translate}}"
              [name]="'ctrlSigningDate'"
              [valueInput]="csrConsumer.contractAttributes.contractSigningDate"
              [mcForm]="mcForm"
              [newStyleDate]="true">
            </mcc-fi-date-picker>

            <mcc-fi-date-picker
              label="{{'cc.common.contract-start-date' | translate}}"
              [name]="'ctrlStartDate'"
              [valueInput]="csrConsumer.contractAttributes.contractStartDate"
              [mcForm]="mcForm"
              [newStyleDate]="true">
            </mcc-fi-date-picker>

            <mcc-fi-date-picker
              label="{{'cc.common.contract-end-date' | translate}}"
              [name]="'ctrlEndDate'"
              [valueInput]="csrConsumer.contractAttributes.contractEndDate"
              [mcForm]="mcForm"
              [newStyleDate]="true">
            </mcc-fi-date-picker>

            <mcc-fi-number-type nameForValue="ctrlContractBaseAmount"
                                nameForType="ctrlContractBaseType" [validMinNumber]="0"
                                [value]="csrConsumer.contractAttributes.contractBaseAmount"
                                [extraCssClasses]="'input-right'"
                                validNumberWithTwoDecimals="true"
                                [chosenCurrency]="currency"
                                label="{{'cc.common.contract-base-amount' | translate}}"
                                [mcForm]="mcForm" [valueType]="currency"
                                [newStyleCurrency]="true"
                                disablePercentage="{{true}}" [labelOnTopFlg]="true">
            </mcc-fi-number-type>

            <mcc-fi-date-picker
              label="{{'cc.common.contract-cancellation-date' | translate}}"
              [name]="'ctrlCancellationDate'"
              [valueInput]="csrConsumer.contractAttributes.contractCancellationDate"
              [mcForm]="mcForm"
              [newStyleDate]="true">
            </mcc-fi-date-picker>

            <mcc-fi-date-picker
              label="{{'cc.common.cancellation-active-on-date' | translate}}"
              [name]="'ctrlCancellationActiveOnDate'"
              [valueInput]="csrConsumer.contractAttributes.contractCancellationActiveOnDate"
              [mcForm]="mcForm"
              [newStyleDate]="true">
            </mcc-fi-date-picker>

            <mcc-fi-textbox style="grid-column: 1 / -1;" name="ctrlContractCancellationReason" label="{{'cc.common.contract-cancellation-reason' | translate}}"
                            [value]="csrConsumer.contractAttributes.contractCancellationReason" [validWhitespaces]="true"
                            [mcForm]="mcForm" [newStyleInput]="true">
            </mcc-fi-textbox>

          </div>

          <h2>{{'cc.consumer.custom-info' | translate}}</h2>
          <div *ngIf="csrConsumer.entityConsumerAttributes.items" class="content-section">
            <ng-container *ngFor="let customAttribute of csrConsumer.entityConsumerAttributes.getFirstHalfOfItems()">
              <mcc-fi-textbox
                label="{{ customAttribute.name }}"
                name="{{ customAttribute.name }}"
                value="{{ this.csrConsumer.additionalAttributes ? this.csrConsumer.additionalAttributes[customAttribute.name] : '' }}"
                [mcForm]="mcForm"
                [newStyleInput]="true"></mcc-fi-textbox>
            </ng-container>
            <ng-container *ngFor="let customAttribute of csrConsumer.entityConsumerAttributes.getLastHalfOfItems()">
              <mcc-fi-textbox
                label="{{ customAttribute.name }}"
                name="{{ customAttribute.name }}"
                value="{{ this.csrConsumer.additionalAttributes ? this.csrConsumer.additionalAttributes[customAttribute.name] : '' }}"
                [mcForm]="mcForm"
                [newStyleInput]="true"></mcc-fi-textbox>
            </ng-container>
          </div>
        </div>


      </div><!-- modal-body -->

      <!--THIS CONTAINER IS MANDATORY-->
      <div class="content-footer">
        <!-- <button type="button" class="btn btn-secondary" (click)="actCancel()">Delete</button>-->
        <button mat-button class="secondary-btn move-right" (click)="actCancel()">{{ 'cc.common.edit.cancel' | translate }}</button>
        <button mat-button class="primary-btn" (click)="actSaveItem()" type="submit">{{ (csrConsumer.exists() ? 'cc.common.view.save' : 'cc.common.create') | translate }}</button>
      </div>
    </div>
  </div>
</form>


