import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {McUtilityService} from './mc-utility.service';

@Injectable({
  providedIn: 'root'
})
export class McJobsService {

  /*Private variables*/
  private readonly apiUrl: string;
  private readonly httpOptions: object;

  /*Constructor*/
  constructor(
    private httpClient: HttpClient,
    private utilityService: McUtilityService,
    @Inject('environment') environment: any
  ) {

    /*Set API base url*/
    this.apiUrl = environment.apiUrl;
    this.httpOptions = environment.httpOptions(HttpHeaders);
  }

  /*Get jobs by entity*/
  public getJobsByEntity(pagination: any) {
    const apiUrl = Object.keys(pagination).length > 0 ? `${this.apiUrl}/scheduler/job/search?size=${pagination.size}&page=${pagination.page}` :  `${this.apiUrl}/scheduler/job/search`;
    return this.httpClient.post(apiUrl, this.httpOptions);
  }

  /*Update jobs action*/
  public updateJobsAction(actionName: string, jobId: number) {
    return this.httpClient.put<any>(`${this.apiUrl}/scheduler/job/${jobId}/${actionName}`, this.httpOptions);
  }

  /*Create new job*/
  public createJob(job: any) {
    return this.httpClient.post<any>(`${this.apiUrl}/scheduler/job`, job, this.httpOptions);
  }

  /*Get Job Data*/
  public getJobData(id: number) {
    return this.httpClient.get<any>(`${this.apiUrl}/scheduler/job/${id}`, this.httpOptions);
  }

  /*Update Job Data*/
  public updateJobData(id: number, job: any) {
    return this.httpClient.put<any>(`${this.apiUrl}/scheduler/job/${id}`, job, this.httpOptions);
  }
}
