import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[McDetectChanges]'
})
export class McDetectChangesDirective implements OnChanges {

  /*Private variable*/
  private oldValue!: string;

  /*Events*/
  @Input() set elementHtmlValue(value: string) {
    if (this.oldValue !== value) {
      this.renderer.addClass(this.el.nativeElement, "mc-detect-html-changes");
    }
  }

  /*Constructor*/
  constructor(private el: ElementRef,
              private renderer: Renderer2) {
  }

  /*On change detection*/
  ngOnChanges(changes: SimpleChanges) {
    this.renderer.addClass(this.el.nativeElement, "mc-detect-html-changes");
    setTimeout(() => {
      this.renderer.removeClass(this.el.nativeElement, "mc-detect-html-changes");
    }, 2000);
  }

}
