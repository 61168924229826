import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders} from '@angular/common/http';

import {Consumer, PageableConsumerDto, ConsumerHistoryResponse} from '../models/consumer.model';
import {Observable} from 'rxjs';
import {McMappingItemList} from '../models/mc-mapping-item-list.model';

@Injectable({
  providedIn: 'root'
})
export class McConsumersService {

  private readonly apiUrl: string;
  private readonly httpOptions;

  constructor(private http: HttpClient,
              @Inject('environment') environment: any
  ) {
    /*Set API base url*/
    this.apiUrl = environment.apiUrl;
    this.httpOptions = environment.httpOptions(HttpHeaders);
  }

  /* Add Consumer */
  public addNewConsumer(body: Consumer) {
    return this.http.post<Consumer>(`${this.apiUrl}/core/consumer`, body, this.httpOptions);
  }
  /* Get Consumer */
  public getConsumerData(id: number): Observable<HttpEvent<PageableConsumerDto>> {
    return this.http.get<PageableConsumerDto>(`${this.apiUrl}/core/consumer/${id}`, this.httpOptions);
  }

  /* Update Consumer Basic Info*/
  public updateConsumerBasicData(data: Consumer) {
    return this.http.put<Consumer>(`${this.apiUrl}/core/consumer`, data, this.httpOptions);
  }

  /* Update Consumer Extended Info*/
  public updateConsumerExtendedData(id: number, data: any) {
    return this.http.put<any>(`${this.apiUrl}/core/consumer/${id}/custom-attributes`, data, this.httpOptions);
  }

  /* Get Consumer history */
  public getConsumerHistory(id: number) {
    return this.http.post<ConsumerHistoryResponse>(`${this.apiUrl}/core/consumer/history/${id}/search`, this.httpOptions);
  }

   /*Get Entity Attributes*/
  public getCustomAttributes(): Observable<any> {
    return this.http.get(`${this.apiUrl}/core/entity/1/custom-attribute`, this.httpOptions);
  }

  /*Update Mapping Items List - Import Consumers*/
  public updateMappingItemsList(mappingItemsList: McMappingItemList) {
    return this.http.put(`${this.apiUrl}/core/McMappingItem/list`, mappingItemsList.toDto());
  }

  /*Get template mapping*/
  public getTemplateMappings(): Observable<any> {
    return this.http.get(`${this.apiUrl}/core/McMapping/all/template`);
  }

  public importConsumers(body: { mappingId: number; handleDuplicatesCd: any; dateFormat: any; }) {
    return this.http.post(`${this.apiUrl}/mcconsumer/v2/mcconsumer/importconsumers`, body, this.httpOptions);
  }

  public generateDirectDebitIdByConsumerId(id: number) {
    return this.http.get(`${this.apiUrl}/mcconsumer/v2/csrcontract/direct-debit-id/${id}`, { responseType: 'text'});
  }

  public getContractsForConsumerByConsumerId(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/mcconsumer/v2/csrcontract/consumer/${id}`);
  }
}

