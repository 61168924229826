<div class="row p-0 m-0 d-flex flex-row align-items-center">
  <h3 class="pr-2 pt-1">{{'cc.my-factoring.sepa-list' | translate}}</h3>
</div>
<div>
  <lib-mc-loader [showLoader]="ebSepaImportList.apiLoadingFlg"></lib-mc-loader>

  <div *ngIf="ebSepaImportList.apiErrorFlg">
    Error: {{ebSepaImportList.apiErrorMessage}}
  </div>

  <!-- <a routerLink="edit/0">Add New</a>-->

  <div class="mc-filter fancy-label">

    <i class="fas fa-info-circle mc-tooltip-top-margin" ngbTooltip="{{'cc.my-factoring.search-by-file-name' | translate}}" placement="bottom-left"></i>

      <mcc-fi-textbox [isClearEnabled]="true" label="{{'cc.common.search' | translate}}" name="txbSearchTerm" [mcForm]="mcForm"
                    (eventEnterClicked)="actLoad()"></mcc-fi-textbox>

    <mcc-fi-select [mcForm]="mcForm" name="selStatusCd" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="{{'cc.common.view.status' | translate}}"
                   [optionsVll]="ebSepaFileStatusCdVll"></mcc-fi-select>
   <!-- <mcc-fi-select [mcForm]="mcForm" name="selServicePeriod" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="{{'cc.invoice.service-period' | translate}}"
                   [optionsVll]="servicePeriodVll"></mcc-fi-select>-->

    <button class="mc-link mc-filter-clear-button filter-clear-top-right" (click)="actClearFilter()">{{'cc.my-factoring.clear-all' | translate}}</button>
    <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>

  </div>
  <div class="mc-notify-msg mt-5">
    <mc-notify-message [message]="msgText" [type]="msgType"></mc-notify-message>
  </div>

  <div class="mc-float-right">
    <button class="system-action-btn mt-2" (click)="showImportSepaMcb.setTrue()"><i
      class="fas fa-plus-circle mc-add-new-user-icon"></i>{{'cc.factoring.imported-sepa.import-sepa-file' | translate}}
    </button>
  </div>

  <mcc-modal1-wrapper [showMcb]="showImportSepaMcb" *ngIf="showImportSepaMcb.value">
    <mc-my-factoring-import-sepa-file (eventSaved)="onSaveImportSepa()"
                                      (eventCanceled)="onCancelImportSepa()"></mc-my-factoring-import-sepa-file>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper [showMcb]="showReportMcb" *ngIf="showReportMcb.value">
    <mc-my-factoring-reporting [ebSepaFile]="ebSepaFile" (eventCanceled)="actClose()"></mc-my-factoring-reporting>
  </mcc-modal1-wrapper>

  <mcc-dialog-box *ngIf="showRemoveSepaMcb.value" [enableCloseButtonFlg]="false"
                  title="Remove {{ebSepaFile?.title}} SEPA" name="remove-sepa"
                  description="Are you sure you want to remove this SEPA?"
                  [buttonsKvm]="dialogButtonKvm"
                  popupType="question"
                  (eventActionButtonClicked)="onMcDialogBoxRemoveSepaActionButtonClicked($event)"></mcc-dialog-box>

  <div *ngIf="ebSepaImportList.apiSuccessFlg">
    <mcc-fi-table-3 [objectList]="ebSepaImportList"
                    [table3Config]="ebSepaImportTable3Config"
                    (eventPaginationOrSortChanged)="actLoad()"
                    (eventItemAction)="onTableItemAction($event)"></mcc-fi-table-3>
  </div>

</div>

<router-outlet></router-outlet>


