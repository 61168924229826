<div class="mc-account-info">
  <!--Account info title-->
  <h4 class="mc-account-info-title">{{ 'cc.common.account-info' | translate }}</h4>
  <br>
  <!--Account info content-->
  <div>
    <span class="mc-account-info-text mr-2">{{'cc.common.view.two-factor-authentication' | translate}}</span>
    <!--Two fa AA modal-->
    <mc-two-fa-account-authentication-modal [parentData]="isEnabled"></mc-two-fa-account-authentication-modal>
    <p *ngIf="isEnabled" >{{'cc.common.edit.enabled' | translate}}</p>
    <p *ngIf="!isEnabled">{{'cc.common.view.disabled' | translate}}</p>
    <!--<p *ngIf="isEnabld !== undefined"><i class="fas" [ngClass]="isEnabled !== undefined && isEnabled === true ? 'fa-check' : 'fa-times'"></i>
      {{isEnabled !== undefined && isEnabled === true ? ' Enabled' : ' Disabled'}}</p>-->
  </div>
  <div>
    <hr>
    <!--Change password modal-->
    <mc-change-password-modal></mc-change-password-modal>
  </div>
  <!--Notification message-->
  <div class="mc-notify-wrapper">
    <mc-notify-message [message]="messageText" [type]="messageType"></mc-notify-message>
  </div>
</div>
