import {McBillingBatchFilterGENERATED} from '../_generated/mc-billing-batch-filter-generated.model';

export class McBillingBatchFilter extends McBillingBatchFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingBatchFilter();
  }

  // ---------------------------------------------------------------------
}
