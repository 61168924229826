<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcProcessFlow.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h1 *ngIf="mcProcessFlow.exists()">{{'cc.common.view.edit' | translate}} Transactions entering the flow</h1>
    <h1 *ngIf="!mcProcessFlow.exists()">Add McProcessFlow (#{{mcProcessFlow.id}})</h1>

  </div>

  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!mcProcessFlow.apiLoadingFlg" class="content-body">
    <mcc-message [errorMsg]="mcProcessFlow.apiErrorMessage"></mcc-message>
    <mcc-message [errorMsg]="errorMsg" label="Unable to proceed: "></mcc-message>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container>

      <div class="row">
        <div class="col-md-12">
          <span>Transactions that will enter the flow</span>
          <div class="form-inner-content">

            <div class="d-flex pt-2">
              <mcc-fi-checkbox class="pr-4" label="Accepted"
                               name="ctrlTransactionsAccepted"
                               [value]="mcProcessFlow.acceptedTransactionsEnteringFlowFlg"
                               [isCheckboxChecked]="mcProcessFlow.acceptedTransactionsEnteringFlowFlg"
                               [mcForm]="mcForm"></mcc-fi-checkbox>

              <mcc-fi-checkbox class="pr-4" label="Returned"
                               name="ctrlTransactionsReturned"
                               [value]="mcProcessFlow.returnedTransactionsEnteringFlowFlg"
                               [isCheckboxChecked]="mcProcessFlow.returnedTransactionsEnteringFlowFlg"
                               [mcForm]="mcForm"></mcc-fi-checkbox>

              <mcc-fi-checkbox class="pr-4" label="For dunning"
                               name="ctrlTransactionsForDunning"
                               [value]="mcProcessFlow.forDunningTransactionsEnteringFlowFlg"
                               [isCheckboxChecked]="mcProcessFlow.forDunningTransactionsEnteringFlowFlg"
                               [mcForm]="mcForm"></mcc-fi-checkbox>

              <mcc-fi-checkbox class="pr-4" label="Should go to Inkasso"
                               name="ctrlTransactionsShouldGoToInkasso"
                               [value]="mcProcessFlow.shouldGoToInkassoTransactionsEnteringFlowFlg"
                               [isCheckboxChecked]="mcProcessFlow.shouldGoToInkassoTransactionsEnteringFlowFlg"
                               [mcForm]="mcForm"></mcc-fi-checkbox>

              <mcc-fi-checkbox label="Sending to Inkasso"
                               name="ctrlTransactionsSendingToInkasso"
                               [value]="mcProcessFlow.sendingToInkassoTransactionsEnteringFlowFlg"
                               [isCheckboxChecked]="mcProcessFlow.sendingToInkassoTransactionsEnteringFlowFlg"
                               [mcForm]="mcForm"></mcc-fi-checkbox>

            </div>

          </div>
        </div>
      </div><!-- row -->
    </ng-container>
  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="{{'cc.common.edit.cancel' | translate}}"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actSave();" label="{{'cc.common.view.save' | translate}}" [disabledMcb]="saveButtonDisabledMcb"
                disableOnFirstClickFlg="true"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
