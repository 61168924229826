import { SelectionModel } from '@angular/cdk/collections';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseDirective, BaseObject, EbInkassoExport, McGod, SortCriteria} from '@miticon-ui/mc-core';
import { MkMatMenuItem } from '../mk-mat-table/mk-mat-table-actions/mk-mat-table-actions.component';
import { MkFilterConfig, MkFilterOutput } from './models/mk-filter.config';
import { MkTableColumn, MkTableColumnType, MkTableConfig } from './models/mk-table.config';
import {takeUntil} from "rxjs/operators";
import {MatTableDataSource} from "@angular/material/table";
import { ICON_FILTER } from '../svg_icons/icon-filter';
import { ICON_DROP_UP } from '../svg_icons/icon-drop-up';
import { ICON_DROP_DOWN } from '../svg_icons/icon-drop-down';
import {MatSelectChange} from "@angular/material/select";

export interface MkMatTableMenuAction {
  action: MkMatMenuItem;
  item: any;
}

@Component({
  selector: 'mk-mat-table',
  templateUrl: './mk-mat-table.component.html',
  styleUrls: ['./mk-mat-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MkMatTableComponent<T extends BaseObject> extends BaseDirective implements OnInit, OnChanges {
  /******** Filters ******/
  @Input() filterConfig: MkFilterConfig | undefined;
  @Input() dataset: Array<any> = [];
  @Input() showPaginator: boolean = true;
  @Input() showCheckbox: boolean = true;
  @Input() showFilters: boolean = true;
  @Input() showTotalItems: boolean = true;
  @Input() isBlacklist: boolean = false;
  @Input() isInkassoExport: boolean = false;
  /******** Filters ******/
    // when Filter button is clicked
  @Output() filterChanged = new EventEmitter<MkFilterOutput>();
  // when values in filter change
  @Output() filterValuesChanged = new EventEmitter<MkFilterOutput>();
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  /******** Table ******/
  @Input() set items(data: any) {
    this.setTableData(data);
  }
  @Input() config: MkTableConfig;
  @Input() totalItemsCount: number;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('empTbSort') empTbSort = new MatSort();

  iconFilter = ICON_FILTER;
  dropUpIcon = ICON_DROP_UP;
  dropDownIcon = ICON_DROP_DOWN;
  panelOpenState = false;

  /******** Table ******/

  /******** Paging ******/
  @Input() initPageSize = 5;
  @Input() initSort: SortCriteria = new SortCriteria('id', 'DESC');
  @Input() pageSizeOptions: number[] = [5, 50, 100, 200, 500, 1000, 5000, 10000];
  @Input() pageNumber = 0;
  @Input() pageSize = 0;
  @Input() pageLength = 0;
  @Input() isLoading = false;
  @Input() itemsPerPage = 0;
  @Input() searchTooltip: string;
  @Input() searchForm = new FormGroup({});
  @Input() filtersFilterForm = new FormGroup({});

  /******** Menu items ******/
  @Input() mkMatMenuActionItems: MkMatMenuItem[];
  @Output() actionMkMatMenuItems = new EventEmitter<any>();
  @Output() selectedItems = new EventEmitter<any[]>();
  @Output() eventColumnButtonAction = new EventEmitter<any>();
  @Output() eventColumnSelection = new EventEmitter<any>();

  dataSource = new MatTableDataSource();
  displayedColumns: string[] | null = null;
  filterOutput: MkFilterOutput;
  selections: any = {};
  selection = new SelectionModel<any>(true, []);
  selectedItemsArray: BaseObject[] = [];
  clearFilters = false;
  mcGod = McGod.getInstance();


  constructor(private router: Router, private fb: FormBuilder, private route: ActivatedRoute, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.searchForm.addControl('search', new FormControl());

    this.displayedColumns = this.config.columns.map((column) => column.propertyName);
    if(this.showCheckbox) {
      if (this.mkMatMenuActionItems) {
        this.displayedColumns.unshift('action');
      }
      this.displayedColumns.unshift('select');
    }
    const pageEvent = new PageEvent();
    pageEvent.pageIndex = this.pageNumber;
    pageEvent.pageSize = this.itemsPerPage;
    this.filterOutput = new MkFilterOutput(pageEvent, this.initSort);

    this.selection.changed.asObservable().pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      if (res.added.length > 0) {
        res.added.map((item) => this.selectedItemsArray.push(item));
        this.selectedItems.emit(this.selectedItemsArray);
      }
      if (res.removed.length > 0) {
        res.removed.forEach((removedItem) => {
          this.selectedItemsArray = this.selectedItemsArray.filter((item) => item.id !== removedItem.id);
        });
        this.selectedItems.emit(this.selectedItemsArray);
      }
    });

    this.searchForm.valueChanges.subscribe((res) => {
      if (res.search) {
        this.filterOutput.search = res.search;
        this.filterValuesChanged.emit(this.filterOutput);
      }
    });

  }

  setTableData(data: any) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.empTbSort;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row) => {
        this.selection.select(row);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      if(this.paginator) {
        this.paginator.pageIndex = this.pageNumber;
        this.paginator.length = this.totalItemsCount;
        this.paginator.pageSize = this.filterOutput.pageEvent.pageSize;
        this.resetSelection();
      }
    }, 50);
  }

  /******** Events  ******/

  onFilterSingleSelection(name: string, elem: any) {
    this.selections[name] = elem;
    this.filterOutput.selections = this.selections;
    this.filterValuesChanged.emit(this.filterOutput);
  }

  onFilterMultiSelection(name: string, elem: any[]) {
    this.selections[name] = elem;
    this.filterOutput.selections = this.selections;
    this.filterValuesChanged.emit(this.filterOutput);
  }

  onFilterDatepickerValueChange(name: string, elem: any) {
    this.selections[name] = elem;
    this.filterOutput.selections = this.selections;
    this.filterValuesChanged.emit(this.filterOutput);
  }

  onSortChange(sort: Sort, sortPropertyName: string) {
    const sortCriteria: SortCriteria = {
      sortProperty: sortPropertyName,
      sortType: sort.direction,
    };
    this.filterOutput.sort = sortCriteria;
    this.pageNumber = 0;
    this.filterOutput.pageEvent.pageIndex = 0;
    this.filterChanged.emit(this.filterOutput);
  }

  onPageChanged(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.pageLength = event.length;
    this.filterOutput.pageEvent = event;
    this.filterChanged.emit(this.filterOutput);
  }

  /******** Events End ******/

  getColumnClass(column: MkTableColumn): string {
    if (column.type === MkTableColumnType.ID) {
      return 'id-class';
    }
    return '';
  }

  getValue(column: MkTableColumn, source: T): string {
    if (column.type === MkTableColumnType.ID) {
      return source.id;
    }
    return source.execPropertyOrMethodByName(column.propertyName);
  }

  onSelectionChange($event: MatSelectChange, element: any) {
    this.eventColumnSelection.emit({$event, element});
  }

  onClick(column: MkTableColumn, element: BaseObject) {
    if (column.type === MkTableColumnType.ID) {
      this.router.navigate([element.navigation]);
    }
    if (column.type === MkTableColumnType.BUTTON_ACTION || column.type === MkTableColumnType.SVG_ICON_ACTION) {
      this.eventColumnButtonAction.emit({ actionCd: column.actionCd, element });
    }
  }

  onFilter() {
    this.filterOutput.search = this.searchForm.get('search')?.value;
    this.clearFilters = false;
    this.pageNumber = 0;
    this.filterOutput.pageEvent.pageIndex = 0;
    this.pageSize = this.filterOutput.pageEvent.pageSize;
    this.filterOutput.selections = this.selections;
    this.filterChanged.emit(this.filterOutput);
  }

  onClearFilter() {
    this.searchForm.get('search')?.setValue('');
    this.clearFilters = true;
    this.searchForm.reset();
    setTimeout(() => {
      this.clearFilters = false;
    }, 1000);
    this.filterOutput.search = '';
    this.filterOutput.selections = '';
    this.selections = {};
    this.filterChanged.emit(this.filterOutput);
    this.filterValuesChanged.emit(this.filterOutput);
  }

  onTableAction($event: any) {
    this.action.emit($event);
  }

  onMenuItemAction($event: MkMatMenuItem, element: any) {
    const mkMatTableMenuAction: MkMatTableMenuAction = {
      action: $event,
      item: element,
    };
    this.actionMkMatMenuItems.emit(mkMatTableMenuAction);
  }

  matMenuItemClicked(item: MkMatMenuItem): void {
    this.actionMkMatMenuItems.emit(item);
  }

  resetSelection() {
    this.selectedItemsArray = [];
    this.selectedItems.emit(this.selectedItemsArray);
  }

  showActions(element: any){
    return !(this.isBlacklist && element.removedDate) &&
      !(this.isInkassoExport && element.statusCd != EbInkassoExport.STATUS_CD_EXPORTED);
  }
}
