<
<div *ngIf="mcConsumer.apiErrorFlg">
  ERROR
  {{mcConsumer.apiErrorMessage}}
</div>
<div *ngIf="mcConsumer.apiLoadingFlg">
  <img src="/assets/images/loading-whitebg.gif"/>
</div>
<div *ngIf="mcConsumer.apiSuccessFlg">

  <div>
    <div class="row info-data title-right">
      <div class="col-md-6">
        <div><span style="width: 100px; text-align:right;">ID:: </span><span>{{mcConsumer.id}} </span></div>
        <div><span style="width: 100px; text-align:right;">Entity ID: </span><span>{{mcConsumer.idMcEntity}} </span>
        </div>
        <div><span
          style="width: 100px; text-align:right;">External Consumer ID: </span><span>{{mcConsumer.extConsumerId}} </span>
        </div>
        <div><span style="width: 100px; text-align:right;">Type code: </span><span>{{mcConsumer.typeCd}} </span></div>
        <div><span style="width: 100px; text-align:right;">Status code: </span><span>{{mcConsumer.statusCd}} </span>
        </div>
        <div><span style="width: 100px; text-align:right;">First name: </span><span>{{mcConsumer.firstName}} </span>
        </div>
        <div><span style="width: 100px; text-align:right;">Last name: </span><span>{{mcConsumer.lastName}} </span></div>
        <div><span style="width: 100px; text-align:right;">Company name: </span><span>{{mcConsumer.companyName}} </span>
        </div>
        <div><span
          style="width: 100px; text-align:right;">Contract number: </span><span>{{mcConsumer.contractNumber}} </span>
        </div>
        <div><span style="width: 100px; text-align:right;">Title: </span><span>{{mcConsumer.title}} </span></div>
        <div><span style="width: 100px; text-align:right;">Gender: </span><span>{{mcConsumer.gender}} </span></div>
        <div><span style="width: 100px; text-align:right;">Birthday: </span><span>{{mcConsumer.birthday}} </span></div>
        <div><span style="width: 100px; text-align:right;">Street: </span><span>{{mcConsumer.street}} </span></div>
        <div><span
          style="width: 100px; text-align:right;">Address supplement: </span><span>{{mcConsumer.addressSupplement}} </span>
        </div>
        <div><span style="width: 100px; text-align:right;">Post code: </span><span>{{mcConsumer.postCode}} </span></div>
        <div><span style="width: 100px; text-align:right;">City: </span><span>{{mcConsumer.city}} </span></div>
        <div><span
          style="width: 100px; text-align:right;">Country ISO code: </span><span>{{mcConsumer.isoCountry}} </span></div>
        <div><span
          style="width: 100px; text-align:right;">Language ISO code: </span><span>{{mcConsumer.isoLanguage}} </span>
        </div>
        <div><span
          style="width: 100px; text-align:right;">Individual/company flag: </span><span>{{mcConsumer.individual()}} </span>
        </div>
        <div><span style="width: 100px; text-align:right;">Telephone: </span><span>{{mcConsumer.telephone}} </span>
        </div>
        <div><span style="width: 100px; text-align:right;">Cellphone: </span><span>{{mcConsumer.cellphone}} </span>
        </div>
        <div><span style="width: 100px; text-align:right;">Fax: </span><span>{{mcConsumer.fax}} </span></div>
        <div><span style="width: 100px; text-align:right;">Email: </span><span>{{mcConsumer.email}} </span></div>
        <div><span style="width: 100px; text-align:right;">IBAN: </span><span>{{mcConsumer.iban}} </span></div>
        <div><span style="width: 100px; text-align:right;">BIC: </span><span>{{mcConsumer.bic}} </span></div>
        <div><span
          style="width: 100px; text-align:right;">Account owner: </span><span>{{mcConsumer.accountOwner}} </span></div>
        <div><span
          style="width: 100px; text-align:right;">Active/inactive flag: </span><span>{{mcConsumer.active}} </span></div>
        <div><span style="width: 100px; text-align:right;">Archived flag: </span><span>{{mcConsumer.archived}} </span>
        </div>
        <div><span
          style="width: 100px; text-align:right;">Index of state: </span><span>{{mcConsumer.stateIndex}} </span></div>
<!--        <div><span-->
<!--          style="width: 100px; text-align:right;">Custom attributes: </span><span>{{mcConsumer.customAttributes}} </span>-->
<!--        </div>-->
        <div><span style="width: 100px; text-align:right;">Created at: </span><span>{{mcConsumer.createdAt}} </span>
        </div>
        <div><span style="width: 100px; text-align:right;">Updated at: </span><span>{{mcConsumer.updatedAt}} </span>
        </div>
        <div><span style="width: 100px; text-align:right;">Created by: </span><span>{{mcConsumer.createdBy}} </span>
        </div>
        <div><span style="width: 100px; text-align:right;">Updated by: </span><span>{{mcConsumer.updatedBy}} </span>
        </div>
        <div><span style="width: 100px; text-align:right;">null: </span><span>{{mcConsumer.version}} </span></div>
        <hr/>
      </div>
      <div class="col-md-6">
        <div *ngFor="let mcCustomAttribute of mcConsumer.entityConsumerAttributes.items">
          <span style="width: 100px; text-align:right;">{{mcCustomAttribute.name}}: </span>
          <span>{{mcCustomAttribute.value}}</span>
        </div>
      </div>
    </div>
  </div>


</div>

<router-outlet></router-outlet>
