import {McBillingProcessedTransactionsInfoTableFilterGENERATED} from '../_generated/mc-billing-processed-transactions-info-table-filter-generated.model';

export class McBillingProcessedTransactionsInfoTableFilter extends McBillingProcessedTransactionsInfoTableFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingProcessedTransactionsInfoTableFilter();
  }

  // ---------------------------------------------------------------------
}
