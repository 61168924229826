import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SortCriteriaList } from '../_core/sort-criteria-list.model';
import { McBillingPayoutFileItemFeCtdServiceGENERATED } from '../_generated/mc-billing-payout-file-item-fe-ctd-generated.service';

@Injectable({
  providedIn: 'root',
})
export class McBillingPayoutFileItemFeCtdService extends McBillingPayoutFileItemFeCtdServiceGENERATED {
  protected apiServiceUrlSubPath = '/mcbilling/v3/mcbillingpayoutfileitem';

  public getByFilter(aFilter: any, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.post<any>(this.apiUrl + this.apiServiceUrlSubPath + `/filter?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, aFilter.toDto(), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }
}
