import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MkFilterItemType, MkFilterValue} from '../models/mk-filter.config';
import {BaseDirective, McDateUtils} from '@miticon-ui/mc-core';
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {takeUntil} from "rxjs/operators";
import moment from "moment";


@Component({
  selector: 'mk-mat-filter',
  templateUrl: './mk-mat-filter.component.html',
  styleUrls: ['./mk-mat-filter.component.scss'],
})
export class MkMatFilterComponent extends BaseDirective implements OnInit, OnChanges {
  @ViewChild('multiselect') multiselect: MatSelect;

  @Input() values: MkFilterValue[];
  @Input() type: MkFilterItemType;
  @Input() name: string;
  @Input() label: string | undefined;
  @Input() clearFilters = false;

  @Output() valueChangeSingle: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChangeMulti: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() valueChangeDatepicker: EventEmitter<any> = new EventEmitter<any>();
  @Input() filterForm = new FormGroup({});
  startDate: any;
  endDate: any;
  allSelected = false;

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    if (this.type === MkFilterItemType.SELECT) {
      this.filterForm.addControl(`select${this.name}`, new FormControl());
    }
    if (this.type === MkFilterItemType.MULTISELECT) {
      this.filterForm.addControl(`multiselect${this.name}`, new FormControl());
    }
    if (this.type === MkFilterItemType.RANGE_DATEPICKER) {
      this.filterForm.addControl(`fromDate${this.name}`, new FormControl());
      this.filterForm.addControl(`toDate${this.name}`, new FormControl());
    }
    if (this.type === MkFilterItemType.DATEPICKER) {
      this.filterForm.addControl(`singleDate${this.name}`, new FormControl());
    }

    this.filterForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (res[`multiselect${this.name}`]) {
        this.valueChangeMulti.emit(res[`multiselect${this.name}`]);
      }
      if (res[`select${this.name}`]) {
        this.valueChangeSingle.emit(res[`select${this.name}`]);
      }
      if (res[`fromDate${this.name}`]) {
        this.startDate = McDateUtils.addHoursToDate(res[`fromDate${this.name}`], 2);
        this.valueChangeDatepicker.emit({ startDate: res[`fromDate${this.name}`], endDate: this.endDate });
      }
      if (res[`toDate${this.name}`]) {
        this.endDate = McDateUtils.addHoursToDate(res[`toDate${this.name}`], 2);
        this.valueChangeDatepicker.emit({startDate: this.startDate, endDate: res[`toDate${this.name}`]});
      }
      if (res[`singleDate${this.name}`]) {
        this.valueChangeDatepicker.emit(res[`singleDate${this.name}`]);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['clearFilters'].currentValue) {
      this.filterForm.reset();
      this.allSelected = false;
    }
  }

  startDateChange(event: any) {
    this.startDate = McDateUtils.addHoursToDate(event.value, 2);
    this.valueChangeDatepicker.emit({ startDate: this.startDate, endDate: this.endDate });
  }

  endDateChange(event: any) {
    this.endDate = McDateUtils.addHoursToDate(event.value, 2);
    this.valueChangeDatepicker.emit({ startDate: this.startDate, endDate: this.endDate });
  }

  filterLabelCapitalize(label: string): string {
    return label.charAt(0).toUpperCase() + label.slice(1);
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.multiselect.options.forEach((item: MatOption) => item.select());
    } else {
      this.multiselect.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.multiselect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  onMultiValueChange($event: any) {
    this.valueChangeMulti.emit($event);
  }

  singleDatepickerDateChange(event: any) {
    this.valueChangeDatepicker.emit(moment(event.value).utcOffset(0, true).format());
  }
}
