import {CsrDiscount} from './csr-discount.model';
import {CsrDiscountListGENERATED} from '../_generated/csr-discount-list-generated.model';

export class CsrDiscountList extends CsrDiscountListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrDiscountList();
  }

  // ---------------------------------------------------------------------
}
