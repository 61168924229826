import { Injectable } from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import { McGod } from "../extra/mc-god.service";

@Injectable({
  providedIn: "root",
})
export class McManagementService {
  private apiUrl: string;
  private httpOptions;

  /*Constructor*/
  constructor(
    private httpClient: HttpClient
  ) //            @Inject("environment") environment
  {
    /*Set API base url*/
    this.apiUrl = McGod.getEnv().apiUrl;
    this.httpOptions = McGod.getHttpOptions();
  }

  /*Get management list for current page*/
  public getSystemManagementFromDb(page: string) {
    return this.httpClient.get<any>(
      this.apiUrl + `/core/${page}/all`,
      this.httpOptions
    );
  }

  public getAndFilterSystemManagementModule(
    page: string | null,
    body: any,
    pagination: { size?: any; page?: any }
  ) {
    let apiUrl: string;
    if (page === "consumers") {
      apiUrl =
        Object.keys(pagination).length > 0
          ? `${this.apiUrl}/core/consumer/search?size=${pagination.size}&page=${pagination.page}`
          : this.apiUrl + `/core/consumer/search`;
      return this.httpClient.post<any>(apiUrl, body, this.httpOptions);
    } else if(page !== "invoices") {
        apiUrl =
          Object.keys(pagination).length > 0
            ? this.apiUrl +
            `/core/${page}/search?size=${pagination.size}&page=${pagination.page}`
            : this.apiUrl + `/core/${page}/search`;
        return this.httpClient.post<any>(apiUrl, body, this.httpOptions);
    }
    else
      return null;
  }

  /*Get management list for ID*/
  public getUsersOrRolesByEntityId(page: string | null, id: any) {
    const apiUrl = `${this.apiUrl}/core/entity/${id}/${page}`;
    return this.httpClient.get<any>(apiUrl, this.httpOptions);
  }

  /*Get permissions by role ID*/
  public getPermissionsByRoleId(id: any) {
    const apiUrl = `${this.apiUrl}/core/user/role/${id}`;
    return this.httpClient.get<any>(apiUrl, this.httpOptions);
  }

  /*Create new product*/
  public createProduct(body: any) {
    return this.httpClient.post<boolean>(
      this.apiUrl + `/core/product`,
      body,
      this.httpOptions
    );
  }

  /*Edit product*/
  public editProduct(body: any) {
    return this.httpClient.put<boolean>(
      this.apiUrl + `/core/product`,
      body,
      this.httpOptions
    );
  }

  /*Get all products*/
  public getAllProducts() {
    return this.httpClient.get(
      this.apiUrl + `/core/product/all`,
      this.httpOptions
    );
  }

  /*Create new category*/
  public createCategory(body: { name: any; description: any }) {
    return this.httpClient.post<boolean>(
      this.apiUrl + `/ppproduct/v3/ppcategory`,
      body,
      this.httpOptions
    );
  }

  /*Get all products*/
  public getProductById(productId: any) {
    return this.httpClient.get(
      this.apiUrl + `/core/product/${productId}`,
      this.httpOptions
    );
  }

  /*Get all product categories*/
  public getAllProductCategories() {
    return this.httpClient.get<any[]>(
      this.apiUrl + `/ppproduct/v3/ppcategory/all`,
      this.httpOptions
    );
  }

  /*Create pdf template*/
  public createPdfTemplate(entityId: any) {
    return this.httpClient.get(
      this.apiUrl + `/cc/pdf/invoice/generate/${entityId}`,
      { responseType: "blob" }
    );
  }
}
