import {Component, Input} from '@angular/core';
import {McHtml} from '@miticon-ui/mc-core';

@Component({
  selector: 'mcc-row-title-description',
  templateUrl: './mcc-row-title-description.component.html',
  styleUrls: ['./mcc-row-title-description.scss']
})
export class MccRowTitleDescriptionComponent {

  public valuesArray: Array<any> = [];

  @Input() title!: string;
  @Input() description!: string | number;
  @Input() descriptionInnerHtml!: string;
  @Input() descriptionHtml!: McHtml;
  @Input() extraCssClasses = '';
  @Input() wrapInRowDivFlg = true;
  @Input() widerTitleFlg = false;


}
