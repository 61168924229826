import {ImDataMappingItemFilterGENERATED} from '../_generated/im-data-mapping-item-filter-generated.model';

export class ImDataMappingItemFilter extends ImDataMappingItemFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataMappingItemFilter();
  }

  // ---------------------------------------------------------------------
}
