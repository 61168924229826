import {McBillingPayoutFile2Statement} from './mc-billing-payout-file-2-statement.model';
import {McBillingPayoutFile2StatementListGENERATED} from '../_generated/mc-billing-payout-file-2-statement-list-generated.model';

export class McBillingPayoutFile2StatementList extends McBillingPayoutFile2StatementListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingPayoutFile2StatementList();
  }

  // ---------------------------------------------------------------------
}
