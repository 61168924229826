import {Component, EventEmitter, Input, Output} from '@angular/core';
import {McGod, McHtmlActionSet, McHtmlButton, McHtmlLink, McHtmlTooltip} from '@miticon-ui/mc-core';
import {McHtml} from '@miticon-ui/mc-core';
import {Router} from '@angular/router';

@Component({
  selector: 'mcc-html',
  templateUrl: './mcc-html.component.html'
})
export class MccHtmlComponent {

  public valuesArray: Array<any> = [];
  /*Events*/

  // @Input() valueOrValues;

  @Input() htmlTag: 'span' | 'div' | 'p' | '_raw' = 'span';
  @Input() safeHtmlFlg = true; // to implement pipe that bypass security
  @Input() extraCssClasses = '';

  @Input('values')
  set values(valueOrValues: any) {
    if (valueOrValues instanceof McHtml) {
      this.valuesArray = valueOrValues.items;
    } else if (valueOrValues instanceof Array) {
      this.valuesArray = valueOrValues;
    } else {
      this.valuesArray = [];
      this.valuesArray.push(valueOrValues);
    }
  }

  /*Constructor*/
  constructor(private router: Router) {
  }

  @Output() eventButtonClicked = new EventEmitter<McHtmlButton>();

  isItemMcHtmlActionSetAndHasPermission(item: any) {
    const result = (item instanceof McHtmlActionSet) && (McGod.userHasPermissions(item.permissions));
    return result;
  }

  isItemMcHtmlButtonAndHasPermission(item: any) {
    return (item instanceof McHtmlButton) && (McGod.userHasPermissions(item.permissions));
  }

  isItemMcHtmlLinkAndHasPermission(item: any) {
    return (item instanceof McHtmlLink) && (McGod.userHasPermissions(item.permissions));
  }

  isItemString(item: any) {
    return typeof item === 'string' || item instanceof String || !isNaN(item);
  }

  isItemTooltip(item: any) {
    return item instanceof McHtmlTooltip;
  }

  actButtonPressedForItem(item: McHtmlButton) {
    this.eventButtonClicked.emit(item);
  }
  actButtonPressedFromActionItemSet(item: McHtmlButton) {
    this.eventButtonClicked.emit(item);
  }

  cleanValue(aValueRaw: any) {
    if (aValueRaw) {
      return aValueRaw;
    } else {
      return '';
    }
  }

  actGoToPage(item: McHtmlLink) {
    this.router.navigate([item.routeLink], item.queryParams);
  }
}
