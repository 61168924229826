import {Component, OnInit} from '@angular/core';
import {
  BaseDirective,
  EbSepaStatementReport,
  EbSepaStatementReportFilter,
  EbSepaStatementReportList,
  McBoolean,
  McGod, McUser, McUserService, WebFile
} from '@miticon-ui/mc-core';
import {MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig} from '@miticon-ui/mc-components';
import {ToastrService} from "ngx-toastr";
import * as fileSaver from "file-saver";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'lib-mc-bank-module-sepa-statement-report-list-page',
  templateUrl: './mc-bank-module-sepa-statement-report-list-page.component.html',
  styleUrls: ['./mc-bank-module-sepa-statement-report-list-page.component.scss']
})
export class McBankModuleSepaStatementReportListPageComponent extends BaseDirective implements OnInit {
  isLoading = false;
  ebSepaStatementReportList = new EbSepaStatementReportList();
  ebSepaStatementReportFilter = new EbSepaStatementReportFilter();
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  mkMatMenuActionItems: MkMatMenuItem[] = [
    {
      title: McGod.t('cc.out-factoring.download'),
      actionCd: EbSepaStatementReport.ACTION_DOWNLOAD,
      matIcon: 'cloud_download',
      permission: McGod.PERM_EB_FACTORING_STARMONEY_VIEW
    }
  ];
  showImportStarmoneyMcb = new McBoolean();
  webFile = new WebFile();

  constructor(private toastr: ToastrService, private userService: McUserService) {
    super();
  }

  ngOnInit(): void {
    this.initTableConfig();
    this.initFilterConfig();
    this.ebSepaStatementReportFilter.importDateFrom = '';
    this.ebSepaStatementReportFilter.importDateTo = '';
    this.ebSepaStatementReportFilter.searchTerm = '';
    this.ebSepaStatementReportFilter.importedByUserId = 0;
    this.actLoad(0, 100, this.ebSepaStatementReportFilter);

    /*this.userService.getAllThatImportSepaStatementReportFile()
      .pipe(takeUntil(this.destroyed$))
      .subscribe();*/
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('cc.bank.file-name'), 'sepaStatementReportFilename');
    this.tableConfig.addColumnStandard(McGod.t('cc.factoring.imported-sepa.import-date-&-time'), 'fldImportDateAndTime()');
    this.tableConfig.addColumnStandard(McGod.t('cc.bank.withdrawn-total-matched-count'), 'fldWithdrawnTotalMatchedCount()');
    this.tableConfig.addColumnStandard(McGod.t('cc.bank.withdrawn-total-matched-amount'), 'fldWithdrawnTotalMatchedAmount()');
    this.tableConfig.addColumnStandard(McGod.t('cc.bank.returned-total-matched-count'), 'fldReturnedTotalMatchedCount()');
    this.tableConfig.addColumnStandard(McGod.t('cc.bank.returned-total-matched-amount'), 'fldReturnedTotalMatchedAmount()');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.imported-by'), 'importedBy');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.status'), 'fldStarMoneyStatus()');
  }

  initFilterConfig() {
    const importedByValues: MkFilterValue[] = [];

    /*this.userService.getAllThatImportSepaStatementReportFile()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: McUser[]) => {
        const mcUsers = res.map(item => McUser.createFromJson(item));
        mcUsers.forEach(item => importedByValues.push(new MkFilterValue(item.fldFullName(), item.id)));
      });*/
    this.filterConfig.addItem(MkFilterItemType.RANGE_DATEPICKER, McGod.t(EbSepaStatementReport.FILTER_IMPORTED_DATE_TIME));
    this.filterConfig.addItem(MkFilterItemType.SELECT, McGod.t(EbSepaStatementReport.FILTER_IMPORTED_BY), importedByValues);
  }

  actLoad(pageNumber: number, pageSize: number, filters: EbSepaStatementReportFilter) {
    this.isLoading = true;
    this.ebSepaStatementReportList.pageNum = pageNumber;
    this.ebSepaStatementReportList.pageItemsPerPageCount = pageSize;
    this.ebSepaStatementReportList.loadByFilter(filters, () => {
      this.isLoading = false;
    });
  }

  onFilterChanged(filters: MkFilterOutput) {
    const ebSepaStatementReportFilter = new EbSepaStatementReportFilter();
    this.ebSepaStatementReportList.setSortB(filters.sort.sortProperty, filters.sort.sortType);
    ebSepaStatementReportFilter.searchTerm = filters.search;
    ebSepaStatementReportFilter.importedByUserId = filters.selections[McGod.t(EbSepaStatementReport.FILTER_IMPORTED_BY)];
    ebSepaStatementReportFilter.importDateFrom = filters.selections[McGod.t(EbSepaStatementReport.FILTER_IMPORTED_DATE_TIME)]?.startDate;
    ebSepaStatementReportFilter.importDateTo = filters.selections[McGod.t(EbSepaStatementReport.FILTER_IMPORTED_DATE_TIME)]?.endDate;
    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize, ebSepaStatementReportFilter);
  }


  onMkMatMenuItemsAction(event: MkMatTableMenuAction) {
    if (event.action.actionCd === EbSepaStatementReport.ACTION_DOWNLOAD) {
      this.webFile.getFileByIdAndType(event.item.idWebFile, WebFile.TYPE_CD_EB_BANK_SEPA_STATEMENT_REPORT, () => {
        this.webFile.downloadFile().subscribe(response => {
          fileSaver.saveAs(response, `${this.webFile.originalFilename}`);
        });
      });
    }
  }

  actStarMoneyImported() {
    this.showImportStarmoneyMcb.setFalse();
    this.actLoad(0, 100, this.ebSepaStatementReportFilter);
    this.toastr.success(McGod.t('cc.incoming-payments.sepa-statement-report-file-successfully-imported'));
  }
}
