/*BbmTestObj*/
import {PmAdjustmentNotificationInfoGENERATED} from '../_generated/pm-adjustment-notification-info-generated.model';
import {McHtml} from "../_core/mc-html";
import {McGod} from "../extra/mc-god.service";
import {ActionOptions} from "../_core/mc-html-action-options";
import moment from "moment/moment";
import {ICON_EMAIL_SENT} from "../svg/icon-email-sent";
import {ICON_EMAIL_FAILED} from "../svg/icon-email-failed";
import {McProcessNotificationGENERATED} from '../_generated/mc-process-notification-generated.model';

export class PmAdjustmentNotificationInfo extends PmAdjustmentNotificationInfoGENERATED {
  public static ACTION_VIEW_EMAIL = 'VIEW_EMAIL';
  public static ACTION_DOWNLOAD_PDF = 'DOWNLOAD_PDF';
  public static ACTION_PREVIEW_PDF = 'PREVIEW_PDF';
  private _fldActions: McHtml | null = null;
  consumerId: number;
  consumerName: string;
  consumerEmail: string;
  dateSent: string;
  notificationEventType: string;
  emailStatus: string;
  emailBody: string;
  attachmentIds: number[];


  public propertiesRegular: string[] = ['id', 'consumerId', 'consumerName', 'consumerEmail', 'dateSent', 'notificationEventType', 'emailStatus', 'emailBody', 'csrConsumer2CtdEmail', 'idCsrConsumer2CtdEmail', 'idMcProcessNotification', 'idPmAdjustment', 'notificationStatusCd', 'statusDetails', 'attachmentIds'];


  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PmAdjustmentNotificationInfo();
  }

  // ---------------------------------------------------------------------

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`View email`, PmAdjustmentNotificationInfo.ACTION_VIEW_EMAIL, this, [McGod.PERM_MC_PROCESS_ITEM_VIEW], '', ActionOptions.NO_OPTIONS);
      if(this.notificationEventType === McProcessNotificationGENERATED.EVENT_TYPE_CD_BILLING) {
        actSet.addActionB(`Preview pdf`, PmAdjustmentNotificationInfo.ACTION_PREVIEW_PDF, this, [McGod.PERM_MC_PROCESS_ITEM_VIEW], '', ActionOptions.NO_OPTIONS);
        actSet.addActionB(`Download pdf`, PmAdjustmentNotificationInfo.ACTION_DOWNLOAD_PDF, this, [McGod.PERM_MC_PROCESS_ITEM_VIEW], '', ActionOptions.NO_OPTIONS);
      }
      this._fldActions = html;
    }
    return this._fldActions;
  }

  getDateSent() {
    return moment(this.dateSent).format('DD.MM.YYYY hh:mm');
  }

  getEmailStatus() {
    if(this.emailStatus === 'SENT') {
      return `<div class='icon-text-table-cell'>${ICON_EMAIL_SENT} <span>Sent</span></div>`
    }  else if(this.emailStatus === 'ERROR') {
      return `<div class='icon-text-table-cell'>${ICON_EMAIL_FAILED} <span>Failed</span></div>`
    } else return this.emailStatus;
  }

  getNotificationEventType() {
    return this.notificationEventType.toLowerCase().replace(/(^|\s)\S/g, function (firstLetter) {
      return firstLetter.toUpperCase();
    }) + " notification";
  }
}
