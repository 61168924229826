import { McUserUserResendEmailComponent } from './components/mc-user-user-resend-email/mc-user-user-resend-email.component';
import { McUserUserAddEditPartComponent } from './components/mc-user-user-add-edit-part/mc-user-user-add-edit-part.component';
import { McUserUserProfilePageComponent } from './pages/mc-user-user-profile-page/mc-user-user-profile-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McUserRoutingModule } from './mc-user-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {McFilterModule, McTableModule} from '@miticon-ui/mc-components';
import {McProfileUserModule} from '../mc-profile-user/mc-profile-user.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
McUserUserResendEmailComponent,
McUserUserAddEditPartComponent,
McUserUserProfilePageComponent,
  ],
  imports: [
    CommonModule,
    McUserRoutingModule,
    NgbModule,
    McComponentsModule,
    McFilterModule,
    McTableModule,
    FormsModule,
    ReactiveFormsModule,
    McProfileUserModule,
    TranslateModule,
  ],
  exports: [
McUserUserResendEmailComponent,
McUserUserAddEditPartComponent,
McUserUserProfilePageComponent,
    McUserRoutingModule,
    FormsModule,

  ]
})
export class McUserModule { }
