import {PpOrderItem} from './pp-order-item.model';
import {PpOrderItemListGENERATED} from '../_generated/pp-order-item-list-generated.model';

export class PpOrderItemList extends PpOrderItemListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PpOrderItemList();
  }

  // ---------------------------------------------------------------------
}
