
import {Table3Config} from '../extra/table-3-config';

// import {McProcessRunGENERATED} from '../_generated/mc-process-run-generated.model';

export class McProcessRunTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colBuildItemsFlg', 'Build items flag', 'buildItemsFlg', 'buildItemsFlg');
    this.addColumn('colBuiltDatetime', 'Built datetime', 'builtDatetime', 'builtDatetime');
    this.addColumn('colCleanedDatetime', 'Cleaned datetime', 'cleanedDatetime', 'cleanedDatetime');
    this.addColumn('colDeliverItemsFlg', 'Deliver items flag', 'deliverItemsFlg', 'deliverItemsFlg');
    this.addColumn('colIdMcProcess', 'McProcess ID', 'idMcProcess', 'idMcProcess');
    this.addColumn('colLastErrorMsg', 'Last Error Msg', 'lastErrorMsg', 'lastErrorMsg');
    this.addColumn('colLoadItemsFlg', 'Load items flag', 'loadItemsFlg', 'loadItemsFlg');
    this.addColumn('colLoadedDatetime', 'Loaded datetime', 'loadedDatetime', 'loadedDatetime');
    this.addColumn('colRunDatetime', 'Run datetime', 'runDatetime', 'runDatetime');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colTypeCd', 'Type code', 'typeCd', 'typeCd');

*/
  }
}
