import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {EbFactoringRequestType, McForm} from '@miticon-ui/mc-core';
import {McBoolean} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-factoring-request-type-add-edit-modal-part',
  templateUrl: './app-shared-factoring-request-type-add-edit-modal-part.component.html',
  encapsulation: ViewEncapsulation.None
})

export class AppSharedFactoringRequestTypeAddEditModalPartComponent implements OnInit {
  factoringRequestType!: EbFactoringRequestType;
  mcForm!: McForm;

  @Input() visibleMcb = new McBoolean();
  @Input() idChildEntity!: number;
  @Output() eventCancel = new EventEmitter();
  @Output() eventSaved = new EventEmitter();

  ngOnInit() {
    this.factoringRequestType = new EbFactoringRequestType();

    if (!this.mcForm) {
      this.mcForm = new McForm();
    }
  }

  actCancel() {
    this.visibleMcb.setFalse();
    this.eventCancel.emit();
  }

  isVisible() {
    if (!this.visibleMcb) {
      this.visibleMcb = new McBoolean();
    }
    return this.visibleMcb.value;
  }

  addRequest(requestType: any) {
    if (this.mcForm.isValid()) {
      this.factoringRequestType.id = 0;
      this.factoringRequestType.idChildMcEntity = this.idChildEntity;
      this.factoringRequestType.title = requestType.mcForm.formGroup.value.requestType.value;
      this.factoringRequestType.save(() => {
        this.visibleMcb.setFalse();
        this.eventSaved.emit();
      }, () => {
        this.mcForm.getControl('requestType')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('requestType')?.get('errorMsg')?.setValue('Factoring request type with this name already exists.');
      });
    }
  }
}

