import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {McBillingStatement, McForm} from "@miticon-ui/mc-core";

@Component({
  selector: 'mc-out-billing-rename-statement-part',
  templateUrl: './mc-out-billing-rename-statement-part.component.html',
})
export class McOutBillingRenameStatementPartComponent implements OnChanges {

  /*Variables*/
  mcForm = new McForm();
  disabledRenameFlg = true;

  @Input() mcStatement!: McBillingStatement;
  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter<boolean>();


  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actSave() {
    if (this.mcForm.isValid()) {
      this.mcStatement.title = this.mcForm.getValue('statementTitle');
      this.mcStatement.save(() => {
        this.eventSaved.emit();
      }, () => {
        this.mcForm.getControl('statementTitle')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('statementTitle')?.get('errorMsg')?.setValue(this.mcStatement.apiErrorMessage);
      });
    }
  }

  onTextBoxValueChangeEmit(value: string) {
    (value !== null && value !== '') ? this.disabledRenameFlg = false : this.disabledRenameFlg = true;
  }
}
