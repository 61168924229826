/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBillingPayoutFile2Statement} from '../models/mc-billing-payout-file-2-statement.model';
import {McBillingPayoutFile2StatementService} from '../services/mc-billing-payout-file-2-statement.service';
import {IMcBillingPayoutFile2Statement} from '../_generated/mc-billing-payout-file-2-statement.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';




export abstract class McBillingPayoutFile2StatementGENERATED extends BaseObject {










  public apiService: McBillingPayoutFile2StatementService;
  public _rawJson: IMcBillingPayoutFile2Statement;
    id: number = 0;
  numberOfStatements: number;
  totalFileAmount: number;
  payoutDate: number;
  statusCd = '';
  fileName = '';
  xlsWebfileId: number;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;


  public properties: string[] = ['id', 'numberOfStatements', 'totalFileAmount', 'payoutDate', 'statusCd', 'fileName', 'xlsWebfileId', 'sysCreatedDatetime', 'sysCreatedUserId'];
  public propertiesRegular: string[] = ['id', 'numberOfStatements', 'totalFileAmount', 'payoutDate', 'statusCd', 'fileName', 'xlsWebfileId', 'sysCreatedDatetime', 'sysCreatedUserId'];
  public propertiesSpecial: string[] = [];










  // ---------------------------------------------------------------------
public static createNewInstance(): McBillingPayoutFile2Statement {
    console.error('MTCN-ERROR: Not Implemented: McBillingPayoutFile2Statement::createNewInstance(). Add this method to final class and return new McBillingPayoutFile2Statement();');
    throw new Error('Cannot McBillingPayoutFile2Statement::createNewInstance(). Add this method to final class and return new McBillingPayoutFile2Statement();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBillingPayoutFile2Statement): McBillingPayoutFile2Statement {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingPayoutFile2StatementService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBillingPayoutFile2Statement AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getTotalFileAmountStr(): string {
       return McGod.formatNumberToMoney(this.totalFileAmount);
    }

    public getPayoutDateStrD(): string {
       return McDateUtils.formatDateToString(this.payoutDate);
    }

    public getPayoutDateStr(): string {
       return McDateUtils.formatDateTimeToString(this.payoutDate);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  









  
// ---------------------------------------------------------------------
public callCreatePayoutFilesByStatementIds( statementIds: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.createPayoutFilesByStatementIds(statementIds)
    .subscribe(responseJson => {
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
}

}
