import {McEntityConsumerAttributeFilterGENERATED} from '../_generated/mc-entity-consumer-attribute-filter-generated.model';

export class McEntityConsumerAttributeFilter extends McEntityConsumerAttributeFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McEntityConsumerAttributeFilter();
  }

  // ---------------------------------------------------------------------
}
