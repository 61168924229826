import {BaseEnvironment} from '../_core/base-environment.model';

// ---------------------------------------------------------------------
// Note: There are two files that load the environment
// 1. src/environment/environment.ts
// 2. src/assets/config/environment.json (this can be localy overwritten on any environment since it is in the dist folder.
// ---------------------------------------------------------------------
export class McEnvironment extends BaseEnvironment {
  // -- declare app specific environment properties

  constructor() {
    super();
    // set default values for the properties
  }

  public prepare() {
    // calculate any properties after initial load of the environment
 }
}
