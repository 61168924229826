import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {McForm} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';
import {MccFiCheckboxesEventAfterValidation} from './mcc-fi-checkboxes-event-after-validation';

@Component({
  selector: 'mcc-fi-checkboxes',
  templateUrl: './mcc-fi-checkboxes.component.html',
  styleUrls: ['./mcc-fi-checkboxes.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MccFiCheckboxesComponent implements OnInit, OnDestroy {

  requiredFlg!: Validators;
  validatorList = [];
  labelPositionClass!: string;
  arrayOfValues!: string[];
  public formValidationTriggerSubscription!: Subscription;

  @Input() mcForm!: McForm;
  @Input() extraCssClasses!: string;
  @Input() name!: string;
  @Input() values!: string[];
  // @Input() frmGroup: FormGroup;
  @Input() label!: string;
  @Input() validRequiredFlg = false;
  @Input() validMinChecked!: number;
  @Input() validMaxChecked!: number;
  // @Input() customValidator;
  // @Input() validationSetupObject: McValidation;
  @Input() validationInComponent = false;
  @Output() eventValueChanged = new EventEmitter();
  @Output() eventAfterValidation = new EventEmitter();
  // @Output() eventValidationTurnOn = new EventEmitter();
  // @Input() validationTrigger = false;
  @Input() optionsVlm!: Map<string, string>;
  @Input() directionHorizontal = true;
  @Input() extraCssClass!: string;
  @Input() labelOnTop = true;

  constructor() {
  }

  getCheckBoxesForm(): FormGroup {
    return this.mcForm.getControl(this.name) as FormGroup;
  }

  createCheckBoxesFormControl() {
    const checkBoxesForm = this.mcForm.formBuilder.group({
      value: [],
      label: [this.label],
      errorFlg: [false],
      errorMsg: ['']
    });

    if (this.mcForm) {
      // console.log('added control ', this.name);
      this.mcForm.addControl(this.name, checkBoxesForm);
    }

  }

  ngOnInit() {

    // console.log('teodora21a');
    this._isValidForNgInit();
    this.arrayOfValues = [];
    this.labelPositionCssClass();

    if (this.mcForm) {
      this.formValidationTriggerSubscription = this.mcForm.getValidationTriggerObservable().subscribe(() => {
        this.validateCheckBoxes();
      });
    }
    // console.log('teodora21b');

    this.createCheckBoxesFormControl();
    // console.log('teodora21c');

    this.optionsVlm.forEach((value, key) => {
      this.getCheckBoxesForm().addControl(key, new FormControl());
    });

    if (this.values) {
      this.values.forEach(key => {
        const control = Object.keys(this.getCheckBoxesForm().controls).find(item => item === key);
        if (control) {
          this.getCheckBoxesForm().get(control)?.setValue(true);
          this.arrayOfValues.push(control);
        }
      });
    }

    this.getCheckBoxesForm().get('value')?.setValue(this.arrayOfValues);

    this.validationSetup();


    this.onCheckBoxesChanged();

  }

  ngOnDestroy(): void {
    if (this.formValidationTriggerSubscription) {
      this.formValidationTriggerSubscription.unsubscribe();
    }
    this.mcForm.formGroup.removeControl(this.name);
  }

  private validatorRequiredCheckBoxes(control: FormControl) {
    return control.value.length > 0 ? null : {
      requiredCheckBoxes: {
        valid: false
      }
    };
  }

  private validatorMinCheckBoxes(min: number) {
    return (control: FormControl) => {
      if (control.value && control.value.length >= min) {
        return null;
      } else {
        return {
          minCheckBoxes: {
            valid: false
          }
        };
      }
    };
  }

  private validatorMaxCheckBoxes(max: number) {
    return (control: FormControl) => {
      if (control.value && control.value.length <= max) {
        return null;
      } else {
        return {
          maxCheckBoxes: {
            valid: false
          }
        };
      }
    };
  }


  onCheckBoxesChanged() {
    const controls = Object.keys(this.getCheckBoxesForm().controls).filter((item: string) => item !== 'errorMsg' && item !== 'errorFlg' && item !== 'value');

    controls.forEach(item => {
      this.getCheckBoxesForm().get(item)?.valueChanges.subscribe(() => {
        this.getCheckBoxesForm().get('errorFlg')?.setValue(false);
        this.getCheckBoxesForm().get('errorMsg')?.setValue(``);
        // this.eventValidationTurnOn.emit(false);
        if (this.getCheckBoxesForm().get(item)?.value === true) {
          this.arrayOfValues.push(item);
          // this.checkBoxesForm.get("values").setValue("");
          this.getCheckBoxesForm().get('value')?.setValue(this.arrayOfValues);
        } else {
          const index = this.arrayOfValues.indexOf(item);
          if (index > -1) {
            this.arrayOfValues.splice(index, 1);
            // this.checkBoxesForm.get("values").setValue("");
            this.getCheckBoxesForm().get('value')?.setValue(this.arrayOfValues);
          }
        }
        if (this.eventValueChanged) {
          console.log('USO');
          this.eventValueChanged.emit(this.arrayOfValues);
        }
      });
    });
  }


  validationSetup() {

    if (this.validRequiredFlg) {
      // @ts-ignore
      this.validatorList.push(this.validatorRequiredCheckBoxes);
    }

    if (this.validMinChecked) {
      // @ts-ignore
      this.validatorList.push(this.validatorMinCheckBoxes(this.validMinChecked));
    }

    if (this.validMaxChecked) {
      // @ts-ignore
      this.validatorList.push(this.validatorMaxCheckBoxes(this.validMaxChecked));
    }

    /*if (this.customValidator) {
      this.validatorList.push(this.customValidator);
    }*/

    this.getCheckBoxesForm().get('value')?.setValidators(this.validatorList);
  }

  validateCheckBoxes() {
    this.validationSetup();
    if (this.getCheckBoxesForm().get('value')?.errors) {
      // @ts-ignore
      const validators = Object.keys(this.getCheckBoxesForm()?.get('value')?.errors);
      if (validators) {
        this.getCheckBoxesForm().get('errorFlg')?.setValue(true);
        validators.forEach(item => {
          switch (item) {
            case 'requiredCheckBoxes':
              this.getCheckBoxesForm().get('errorMsg')?.setValue(`This field is required`);
              break;
            case 'minCheckBoxes':
              this.getCheckBoxesForm().get('errorMsg')?.setValue(`Please select at least ${this.validMinChecked}
               ${this.validMinChecked > 1 ? 'items' : 'item'}.`);
              break;
            case 'maxCheckBoxes':
              this.getCheckBoxesForm().get('errorMsg')?.setValue(`The maximum number of selected items is ${this.validMaxChecked}.`);
              break;
          }
        });
      }
    } else {
      this.getCheckBoxesForm().get('errorFlg')?.setValue(false);
      this.getCheckBoxesForm().get('errorMsg')?.setValue(``);
    }
    this.fireEventAfterValidation();
  }

  labelPositionCssClass() {
    if (this.labelOnTop === true) {
      this.labelPositionClass = 'flex-column';
    } else {
      this.labelPositionClass = 'flex-row';
    }
  }

  public fireEventAfterValidation() {
    if (this.eventAfterValidation) {
      const eventObj = new MccFiCheckboxesEventAfterValidation();
      eventObj.validFlg = !this.getCheckBoxesForm().get('value')?.errors;

      this.eventAfterValidation.emit(eventObj);
    }
  }

  clearErrors() {
    this.getCheckBoxesForm().get('errorFlg')?.setValue(false);
    this.getCheckBoxesForm().get('errorMsg')?.setValue('');
  }


  _isValidForNgInit(): boolean {
    let result = true;

    if (this.name == null) {
      console.error('MTCN-ERROR: mcc-fi-checkboxes called without "name" input.');
      result = false;
    } else if (this.mcForm == null) {
      console.error('MTCN-ERROR: mcc-fi-checkboxes called without "mcForm" input.');
      result = false;
    }

    return result;
  }
}
