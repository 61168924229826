import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule, LOCALE_ID} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AuthInterceptor, McGod} from '@miticon-ui/mc-core';
import {environment} from '../environments/environment';
import {McCoreUiModule} from '@miticon-ui/mc-core-ui';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
/*Configs*/
import {design} from '../config/design.config';
import {utility} from '../config/utility.config';
import {sideBarMenu} from '../config/side-bar-menu.config';
import {tableConfig} from '../config/table.config';
import {filterConfig} from '../config/filter.config';
import {pagePermissions} from '../config/page-permissions.config';
import {allPermissions} from '../config/all-permissions.config';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {McEnvironmentService} from '@miticon-ui/mc-core';
import {Location} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';


registerLocaleData(localeDe, 'de-DE');

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}

export function initMcGod(mcGod: McGod) {
  return (): Promise<any> => {
    return mcGod.appInit();
  };
}


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    McCoreUiModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      maxOpened: 1
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    McGod,
    { provide: APP_INITIALIZER, useFactory: initMcGod, deps: [McGod, HttpClientModule, McEnvironmentService, Location, TranslateModule], multi: true},
    { provide: LOCALE_ID, useValue: 'de-DE'},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: 'environment', useValue: environment}, // TODO This one should be removed when references to it are replacecd with McGod
    {provide: 'environment_OnlyForMcEnvironmentService', useValue: environment},
    {provide: 'designConfig', useValue: design},
    {provide: 'utilityConfig', useValue: utility},
    {provide: 'sideBarMenu', useValue: sideBarMenu},
    {provide: 'tableConfig', useValue: tableConfig},
    {provide: 'filterConfig', useValue: filterConfig},
    {provide: 'pagePermissions', useValue: pagePermissions},
    {provide: 'allPermissions', useValue: allPermissions}
  ],

})
export class AppModule { }
