import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BaseDirective,
  EbFactoring,
  McEntity2,
  McEntity2List,
  McEntity2Table3Config,
  McForm,
  McGod,
  McHtmlButton
} from '@miticon-ui/mc-core';

@Component({
  selector: 'lib-app-shared-factoring-select-contract-type-and-entity',
  templateUrl: './app-shared-factoring-select-contract-type-and-entity.component.html',
  styleUrls: ['./app-shared-factoring-select-contract-type-and-entity.component.css']
})
export class AppSharedFactoringSelectContractTypeAndEntityComponent extends BaseDirective implements OnInit {

  mcForm = new McForm();
  optionsVlm = new Map<string, string>();
  mcGod = McGod.getInstance();
  isServiceContract!: boolean;
  mcEntity = new McEntity2();
  @Output() prevEmit = new EventEmitter();
  @Output() nextEmit = new EventEmitter();

  @Output() eventShowAddEditPart = new EventEmitter();
  @Output() selectedEntityId = new EventEmitter<number>();
  @Output() eventCanceled = new EventEmitter();

  @Input() isContractTypeOfService!: boolean;
  @Input() isContractCloned!: boolean;
  @Output() eventEntityForCloning = new EventEmitter<McEntity2>();
  @Input() ebFactoring!: EbFactoring;
  mcEntity2Table3Config = new McEntity2Table3Config();
  mcEntity2List = new McEntity2List();
  disableNext = true;
  notifyMessage = {message: '', type: ''};
  showLoader!: boolean;
  successMessage!: string;
  errorMsg!: string;
  isFactoringContract!: boolean;
  isEntityWithFactoringEnableFlgTrueChecked!: boolean;
  isEntityChecked = false;
  factoringEnabledFlgValue!: string;
  serviceToFactoringValue!: string;
  factoringToServiceValue!: string;

  constructor() {
    super();
  }

  ngOnInit() {
    this.optionsVlm.set(EbFactoring.TYPE_CD_FACTORING, this.mcGod.t('cc.contract.factoring-contract'));
    this.optionsVlm.set(EbFactoring.TYPE_CD_SERVICE, this.mcGod.t('cc.contracts.service-contract'));

    this.actLoad();
  }

  contractTypeValueChanged(value: any) {
    this.isEntityChecked = false;
    if (value === 'FACTORING') {
      this.successMessage = '';
      this.errorMsg = '';
      this.isServiceContract = false;
      this.isFactoringContract = true;
      if (this.isEntityWithFactoringEnableFlgTrueChecked) {
        this.disableNext = false;
      }
      if ((this.factoringEnabledFlgValue === 'service') || (this.factoringEnabledFlgValue === 'not_allowed')) {
        this.disableNext = true;
        this.errorMsg = this.mcGod.t('cc.out-factoring.you-can-not-create-factoring-contract-for-entities-with-factoring-disabled');
        this.factoringToServiceValue = 'allow_for_service';
      }
      if (this.factoringEnabledFlgValue === 'something') {
        this.disableNext = false;
        this.errorMsg = '';
      }
      if (this.serviceToFactoringValue === 'service_no') {
        this.disableNext = true;
        this.errorMsg = this.mcGod.t('cc.out-factoring.you-can-not-create-factoring-contract-for-entities-with-factoring-disabled');
      }
    }
    if (value === 'SERVICE') {
      this.errorMsg = '';
      if (this.factoringEnabledFlgValue === 'enable_button_no_factoring') {
        this.serviceToFactoringValue = 'service_no';
        this.disableNext = false;
      }
      if (this.factoringEnabledFlgValue === 'enable_button_factoring') {
        this.serviceToFactoringValue = 'service_yes';
        this.disableNext = false;
      }
      if (this.factoringEnabledFlgValue === 'not_allowed' || this.factoringToServiceValue === 'allow_for_service') {
        this.disableNext = false;
      }
      this.isServiceContract = true;
      this.isFactoringContract = true;
    }
  }

  actNext() {
    if (!this.isContractCloned) {
      const contractType = this.mcForm.getValue('contractType');
      this.nextEmit.emit(contractType);
    }
    if (this.isContractCloned && this.isContractTypeOfService) {
      const contractTypeService = 'SERVICE';
      this.nextEmit.emit(contractTypeService);
    }
    if (this.isContractCloned && !this.isContractTypeOfService) {
      const contractTypeFactoring = 'FACTORING';
      this.nextEmit.emit(contractTypeFactoring);
    }
  }

  actLoad() {
    this.disableNext = true;
    this.showLoader = true;
    this.mcEntity2List = new McEntity2List();
    this.mcEntity2List.setSingleSelectionFlg(true);
    this.mcEntity2List.setPager(0, 500);
    this.mcEntity2List.loadAllWhereParentEntityIsLoggedInEntity(() => {
      this.mcEntity2List.setSortB('name', 'ASC');
      this.mcEntity2List.items.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
      this.showLoader = false;
    });
  }

  receiveStateOnSelect(event: any) {
    this.successMessage = '';
    if (event.getCount() === 0) {
      this.disableNext = true;
    }
    if (this.isContractCloned) {
      if (event.getCount() === 1 && this.isContractTypeOfService) {
        this.eventEntityForCloning.emit(event.items[0]);
        this.disableNext = false;
        this.errorMsg = '';
      }
      if (event.getCount() === 1 && !this.isContractTypeOfService && event.items[0].factoringEnableFlg) {
        this.eventEntityForCloning.emit(event.items[0]);
        this.disableNext = false;
        this.errorMsg = '';
      }
      if (event.getCount() === 1 && !this.isContractTypeOfService && !event.items[0].factoringEnableFlg) {
        this.disableNext = true;
        this.errorMsg = this.mcGod.t('cc.out-factoring.you-can-not-create-factoring-contract-for-entities-with-factoring-disabled');
      }
    }
    if (!this.isContractCloned) {
      if (event.getCount() === 1 && event.items[0].factoringEnableFlg && !this.isServiceContract) {
        this.selectedEntityId.emit(event.items[0].id);
        this.factoringEnabledFlgValue = 'enable_button_factoring';
        this.disableNext = false;
        this.errorMsg = '';
      }
      if (event.getCount() === 1 && !event.items[0].factoringEnableFlg && !this.isServiceContract) {
        this.selectedEntityId.emit(event.items[0].id);
        this.factoringEnabledFlgValue = 'enable_button_no_factoring';
        this.disableNext = true;
        this.errorMsg = this.mcGod.t('cc.out-factoring.you-can-not-create-factoring-contract-for-entities-with-factoring-disabled');
      }
      if (event.getCount() === 1 && event.items[0].factoringEnableFlg && this.isServiceContract) {
        this.selectedEntityId.emit(event.items[0].id);
        this.serviceToFactoringValue = '';
        this.factoringEnabledFlgValue = 'something';
        this.disableNext = false;
      }
      if (event.getCount() === 1 && !event.items[0].factoringEnableFlg && this.isServiceContract) {
        this.selectedEntityId.emit(event.items[0].id);
        this.factoringEnabledFlgValue = 'service';
        this.disableNext = false;
      }
      if (event.getCount() === 1 && event.items[0].factoringEnableFlg && !this.isServiceContract && !this.isFactoringContract) {
        this.selectedEntityId.emit(event.items[0].id);
        this.isEntityWithFactoringEnableFlgTrueChecked = true;
        this.disableNext = true;
      }
      if (event.getCount() === 1 && !event.items[0].factoringEnableFlg && !this.isServiceContract && !this.isFactoringContract) {
        this.selectedEntityId.emit(event.items[0].id);
        this.disableNext = true;
        this.factoringEnabledFlgValue = 'not_allowed';
        this.isEntityWithFactoringEnableFlgTrueChecked = false;
        this.errorMsg = '';
      }
    }
  }

  actEntity2TableItemAction(htmlButton: McHtmlButton) {
    if (htmlButton.actionCdForEvent === McEntity2.ACTION_ENABLE_FACTORING) {
      this.mcEntity.loadById(htmlButton.item.id, () => {
        this.mcEntity.factoringEnableFlg = !this.mcEntity.factoringEnableFlg;
        this.showLoader = true;
        this.mcEntity.save(() => {
          this.actLoad();
          this.showLoader = false;
          this.successMessage = `${McGod.t('cc.contracts.you-have-successfully')} ${this.mcEntity.factoringEnableFlg ? McGod.t('cc.common.edit.enabled') : McGod.t('cc.common.view.disabled')} ${McGod.t('cc.contracts.factoring-on-entity')} "${this.mcEntity.name}"`;
          this.errorMsg = '';
        });
      });
    }
  }
}
