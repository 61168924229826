import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {McGod} from '../extra/mc-god.service';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';

export abstract class BaseObjectService {
  protected httpClient: HttpClient;
  protected httpOptions: any;
  protected apiUrl: string;
  protected apiServiceUrlSubPath!: string;

  /*Constructor*/
  constructor() {

    /*Set API base url and header*/
    this.httpClient = McGod.getHttpClient();
    this.apiUrl = McGod.getEnv().apiUrl;
    this.httpOptions = McGod.getHttpOptions();
  }

  public getApiServiceRootUrl(): string {
    return this.apiServiceUrlSubPath;
  }

  public get(params: HttpParams, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    params.set('page', aPageNo);
    params.set('size', aItemsPerPage);

    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `?${params.toString()}${sortCriteria.toUrlQueryString()}`,
      {
      });
  }


  public abstract getAll(aPageNo: number, aItemsPerPage: number, sortCriteriaList: SortCriteriaList): Observable<any>;

  public abstract getByFilter(aFilter: any, aPageNo: number, aItemsPerPage: number, sortCriteriaList: SortCriteriaList): Observable<any>;

  public abstract getById(id: number): Observable<any>;
  public abstract deleteAllFromDb(): Observable<any>;

  public deleteById(id: number): Observable<any> {
    return new Observable();
  }

  public insert(aObject: any): Observable<any> {
    return new Observable();
  }

  public update(aObject: any): Observable<any> {
    return new Observable();
  }
}
