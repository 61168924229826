import {Inject, Injectable} from '@angular/core';
import {McBillingService2PriceListServiceGENERATED} from '../_generated/mc-billing-service-2-price-list-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McBillingService2PriceListService extends McBillingService2PriceListServiceGENERATED {

}
