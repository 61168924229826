import {McMapping} from './mc-mapping.model';
import {McMappingListGENERATED} from '../_generated/mc-mapping-list-generated.model';

export class McMappingList extends McMappingListGENERATED {

  public static override createNewInstance() {
    return new McMappingList();
  }
  // ---------------------------------------------------------------------
/*  public loadTemplatesByEntityId(entityId: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    // TODO Commented out for CG
    this.apiService.getTemplatesByEntityId(entityId)
      .subscribe(responseObjList => {
        this.tempObjList = responseObjList;
        this.items = this.tempObjList.items;
        this.processPostLoad();
        if (onSuccessCallback) {
          onSuccessCallback();
        }
        this.setApiSuccess();
      }, error => {
        if (onErrorCallback) {
          onErrorCallback();
        }

        this.setApiError('API ERROR E1001');
      });

  }*/
}
