import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {EbFactoringRequestType, McBoolean, McForm, McGod} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-factoring-request-type-add-part',
  templateUrl: './app-shared-factoring-request-type-add-part.component.html',
})
export class AppSharedFactoringRequestTypeAddPartComponent implements OnInit, OnChanges {
  factoringRequestType!: EbFactoringRequestType;
  mcForm!: McForm;

  // ---------------------------------------------------------------------
  @Input() visibleMcb = new McBoolean();
  @Input() idChildEntity!: number;
  @Input() isContractCloned!: boolean;
  @Input() entityForCloningId!: number;
  @Output() eventCancel = new EventEmitter();
  @Output() eventSaved = new EventEmitter();
  mcGod = McGod.getInstance();
  @Input() isCanceled!: boolean;

  ngOnInit() {
    this.actLoad();

    if (!this.mcForm) {
      this.mcForm = new McForm();
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isCanceled) {
      this.factoringRequestType = new EbFactoringRequestType();
      this.mcForm.getControl('requestType')?.get('value')?.setValue('');
      this.mcForm.getControl('requestType')?.get('errorFlg')?.setValue('');
      this.mcForm.getControl('requestType')?.get('errorMsg')?.setValue('');
    }
  }

  actLoad() {
    this.factoringRequestType = new EbFactoringRequestType();
  }

  actCancel() {
    this.eventCancel.emit();
    this.mcForm.getControl('requestType')?.get('value')?.setValue('');
    this.mcForm.getControl('requestType')?.get('value')?.setValue('');
    this.mcForm.getControl('requestType')?.get('errorFlg')?.setValue('');
    this.mcForm.getControl('requestType')?.get('errorMsg')?.setValue('');
  }

  isVisible() {
    if (!this.visibleMcb) {
      this.visibleMcb = new McBoolean();
    }
    return this.visibleMcb.value;
  }

  addRequest(requestType: any) {
    if (this.mcForm.isValid()) {
      const requestName = (requestType.mcForm.formGroup.value.requestType.value).trim();
      this.factoringRequestType.id = 0;
      this.factoringRequestType.idChildMcEntity = this.idChildEntity;
      this.factoringRequestType.title = requestName;
      this.factoringRequestType.save(() => {
        this.mcForm.getControl('requestType')?.get('value')?.setValue('');
        this.mcForm.getControl('requestType')?.get('value')?.setValue('');
        this.visibleMcb.setFalse();
        this.eventSaved.emit();
      }, () => {
        this.mcForm.getControl('requestType')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('requestType')?.get('errorMsg')?.setValue(this.mcGod.t('cc.contract.request-type-with-this-name-already-exists'));
      });
    }
  }
}
