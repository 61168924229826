import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-mc-user-entities-data',
  templateUrl: './mc-user-entities-data.component.html'
})
export class McUserEntitiesDataComponent implements OnInit {

  roleDataTab!: boolean;

  constructor() { }

  ngOnInit() {
    this.roleDataTab = true;
  }

}
