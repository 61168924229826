/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingItemTable3ConfigGENERATED} from '../_generated/mc-billing-item-table-3-config-generated.model';

// import {McBillingItemGENERATED} from '../_generated/mc-billing-item-generated.model';

export class McBillingItemTable3Config extends McBillingItemTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colEntity', this.t('cc.common.view.entity'), 'childEntityName', '');
    this.addColumn('colType', this.t('cc.jobs.edit.type'), 'getRefTypeCdLabel()', 'typeCd');
    this.addColumn('colRefId', this.t('cc.statement.reference-id'), 'refId', 'refId');
    this.addColumn('colItem', this.t('cc.billing.item'), 'getItemTitle()', 'title');
    this.addColumn('colBatch', this.t('cc.billing.batch'), 'mcBillingBatchName', '');
    this.addColumn('colAmount', this.t('cc.factoring.transactions.amount'), 'getAmount()', 'amount').extraCssClass = 'text-right';
    this.addColumn('colVat', this.t('cc.product.view.vat'), 'getVATString()', '').extraCssClass = 'text-right';
    this.addColumn('colTotal', this.t('cc.statement.total'), 'getTotalAmount()', '').extraCssClass = 'text-right';
    this.addColumn('colActivityDatetime', this.t('cc.statement.activity-date-and-time'), 'getActivityDatetimeString()', 'activityDatetime');
    this.addColumn('colPayoutDateTime', this.t('cc.common.payout-date'), 'getPayoutDateString()', '');
    this.addColumn('colStatement', this.t('cc.statement.statement-name'), 'mcBillingStatementTitle', '');
    this.addColumn('colStatusCd', this.t('cc.common.view.status'), 'getStatusCdLabel()', 'statusCd');


  }
}
