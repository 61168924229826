import {Injectable} from '@angular/core';
import {EbSepaExportServiceGENERATED} from '../_generated/eb-sepa-export-generated.service';
import {McCurrency} from '../models/mc-currency.model';
import {EbSepaExport} from '../models/eb-sepa-export.model';
import {Observable} from 'rxjs';
import {HttpHeaders} from '@angular/common/http';
import {PmTransactionFilter} from '../models/pm-transaction-filter.model';




@Injectable({
  providedIn: 'root'
})
export class EbSepaExportService extends EbSepaExportServiceGENERATED {


  public getCurrency(exportId: number) {
    return this.httpClient.get<McCurrency>(this.getApiServiceRootUrl() + `/${exportId}/currency`);
  }

  public exportAllFilteredTransactions(ebSepaExport: EbSepaExport , pmTransactionFilter: PmTransactionFilter): Observable<any> {
    const body = {
      ebSepaExport: ebSepaExport.toDto(),
      pmTransactionFilter:  pmTransactionFilter.toDto()
    };

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/transaction-filter`, body);
  }

  public sendViaEbics(sepaExportId: number ) {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + '/ebics/send/' + `${sepaExportId}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  uploadSepaExportFile(sepaexportId: number, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/${sepaexportId}/upload`, formData);
  }

  downloadEbicsFile(ebicsId: number): Observable<Blob> {
    return this.httpClient.get<Blob>(this.getApiServiceRootUrl() + `/${ebicsId}/status`);
  }

  createSepaExportJob(data: any): Observable<ArrayBuffer> {
    return this.httpClient.post(this.getApiServiceRootUrl() + `/schedule-sepa-export-by-filter`, data, {responseType: 'arraybuffer'});
  }
}
