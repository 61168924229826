import {CsrRecurringDetails} from './csr-recurring-details.model';
import {CsrRecurringDetailsListGENERATED} from '../_generated/csr-recurring-details-list-generated.model';

export class CsrRecurringDetailsList extends CsrRecurringDetailsListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrRecurringDetailsList();
  }

  // ---------------------------------------------------------------------
}
