import {McValueLabelList} from '../_core/mc-value-label-list';

export class McTokens {
  static getEmailTokenVll() {
   const tokenVll = new McValueLabelList();
   tokenVll.add('<Consumer:getFirstName()>', 'Consumer First Name');
   tokenVll.add('<Consumer:getLastName()>', 'Consumer Last Name');
   tokenVll.add('<Consumer:getCompanyName()>', 'Consumer Company Name');
   tokenVll.add('<Entity:getName()>', 'Entity Name');
   tokenVll.add('<Entity:getImpressum()>', 'Entity Impressum');
   tokenVll.add('<Transaction:getPaymentDeadlineDate()>', 'Payment Deadline Date');
   return tokenVll;
 }

}
