<div class="mcc-fi mcc-fi-textbox mcc-fi-textbox-{{name}} {{extraCssClasses}}" [class.newStyle]="newStyleInput"
     [ngClass]="{'validation-error' : this.getTextboxForm().get('errorFlg')?.value}">
  <div (click)="clearTextbox()" *ngIf="isClearEnabled" class="mc-search-clear">{{'cc.common.clear' | translate}}</div>
  <form [formGroup]="getTextboxForm()">
    <ng-container *ngIf="newStyleInput else oldStyleInput">
      <mat-form-field appearance="outline">
        <mat-label>{{ label }}</mat-label>
        <input type="text"
               #textboxValue
               id="mcc-fi-{{name}}"
               name="txb-{{name}}"
               size="{{size}}"
               formControlName="value"
               (change)="trim(textboxValue)"
               [required]="validRequiredFlg"
               [readOnly]="readOnlyFlg"
               (keyup.enter)="enterClicked()"
               (ngModelChange)="onTextBoxChanged($event)"
               matInput>
      </mat-form-field>
      <span class="pl-2" *ngIf="additionalDescription">{{additionalDescription}}</span>
    </ng-container>
    <ng-template #oldStyleInput>
      <label *ngIf="label" for="mcc-fi-{{name}}" [ngClass]="{'mc-mandatory-star' : validRequiredFlg}">{{label}}
        <span *ngIf="infoMsg"><img src="assets/images/info-blue-tooltip.svg" [ngbTooltip]="infoMsg"></span>
      </label>

      <div class="d-flex align-items-center">
        <input type="text"
               #textboxValue
               id="mcc-fi-{{name}}"
               name="txb-{{name}}"
               size="{{size}}"
               formControlName="value"
               ng-keydown="$event.keyCode != 32 ? $event:$event.preventDefault()"
               (change)="trim(textboxValue)"
               [placeholder]="placeholder"
               [required]="validRequiredFlg"
               [readOnly]="readOnlyFlg"
               (keyup.enter)="enterClicked()"
               (ngModelChange)="onTextBoxChanged($event)">
        <span class="pl-2" *ngIf="additionalDescription">{{additionalDescription}}</span>
      </div>
    </ng-template>

    <div class="error-msg">{{this.getTextboxForm().get('errorMsg')?.value}}</div>
  </form>
</div>
