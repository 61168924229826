<ng-container>
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-wrapper">
    <!--    <lib-mc-loader [showLoader]="webFile.apiLoadingFlg"></lib-mc-loader>-->

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-header">
      <div class="d-flex align-items-center"><i class="fas fa-plus mc-blue-text mr-2"></i> <h4 class="m-0">{{"cc.common.view.create-attribute"| translate}}</h4></div>
    </div>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-body">
      <div class="row">

        <div class="col-md-12 position-relative mt-1">
          <div class="mc-create-user-notify-wrapper">
            <div class="mc-message-info">
              <mc-notify-message [message]="notifyMessage.message" [type]="notifyMessage.type"></mc-notify-message>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <mcc-fi-radio-buttons label="{{'cc.common.edit.enabled' | translate}}"
                                [name]="'enabled'"
                                [directionHorizontal]="true"
                                [value]="'yes'"
                                [mcForm]="mcForm"
                                [validRequiredFlg]="true"
                                [optionsVlm]="enabledVlm"></mcc-fi-radio-buttons>
        </div>
        <div class="col-md-6">
          <mcc-fi-textbox  [name]="'name'" label="{{'cc.common.name' | translate}}"
                           [validRequiredFlg]="true"
                           [mcForm]="mcForm"></mcc-fi-textbox>

          <mcc-fi-select  [name]="'type'" label="{{'cc.jobs.edit.type' | translate}}"
                          [validRequiredFlg]="true"
                          [value]="''"
                          [optionsVll]="typeVll"
                          (eventValueChanged)="typeSelectValueChanged($event)"
                          [mcForm]="mcForm"></mcc-fi-select>
        </div>
        <div class="col-md-6">
          <mcc-fi-textbox  [name]="'length'" label="{{'cc.common.edit.length' | translate}}"
                           [extraCssClasses]="this.mcForm.getValue('type') != 'varchar' ? 'mc-disable-button' : ''"
                           [validDecimalNumber]="true"
                           [mcForm]="mcForm"></mcc-fi-textbox>

          <mcc-fi-textbox  [name]="'validation'" label="{{'cc.common.edit.validation' | translate}}"
                           [value]="''"
                           [extraCssClasses]="this.mcForm.getValue('type') === 'boolean' ? 'mc-disable-button' : ''"
                           [mcForm]="mcForm"></mcc-fi-textbox>
        </div>
        <div class="col-md-12">
          <mcc-fi-textarea [name]="'description'" label="{{'cc.common.view.description' | translate}}" [numCols]="60"
                           [value]="''"
                           [mcForm]="mcForm"></mcc-fi-textarea>

          <mcc-fi-radio-buttons label="Inheritable"
                                [name]="'inheritable'"
                                [directionHorizontal]="true"
                                [value]="'yes'"
                                [mcForm]="mcForm"
                                [validRequiredFlg]="true"
                                [optionsVlm]="inheritableVlm"></mcc-fi-radio-buttons>

          <mcc-fi-radio-buttons label="Required"
                                [name]="'required'"
                                [directionHorizontal]="true"
                                [value]="'yes'"
                                [mcForm]="mcForm"
                                [validRequiredFlg]="true"
                                [optionsVlm]="requiredVlm"></mcc-fi-radio-buttons>

          <mcc-fi-checkbox label="{{'cc.common.view.default-value' | translate}}"
                           [extraCssClass]="this.mcForm.getValue('type') === '' ? 'mc-disable-button' : ''"
                           [name]="'defaultValueCb'"
                           [value]="false"
                           (eventValueChanged)="defaultValueCbValueChanged($event)"
                           [mcForm]="mcForm"></mcc-fi-checkbox>

          <div [ngClass]="{'mc-disable-button' : !defaultValueCbChecked}" [ngSwitch]="mcForm.getValue('type')">
            <mcc-fi-textbox *ngSwitchCase="'INTEGER'" [name]="'defaultValue'"
                            [validDecimalNumber]="true"
                            [validRequiredFlg]="defaultValueCbChecked"
                            [mcForm]="mcForm"></mcc-fi-textbox>

            <mcc-fi-textbox *ngSwitchCase="'VARCHAR'" [name]="'defaultValue'"
                            [validRequiredFlg]="defaultValueCbChecked"
                            [mcForm]="mcForm"></mcc-fi-textbox>

            <mcc-fi-date-picker *ngSwitchCase="'DATE'" [name]="'defaultValue'"
                                [validRequiredFlg]="defaultValueCbChecked"
                                [mcForm]="mcForm"></mcc-fi-date-picker>

            <mcc-fi-date-picker *ngSwitchCase="'DATETIME'" [name]="'defaultValue'"
                                [validRequiredFlg]="defaultValueCbChecked"
                                [mcForm]="mcForm"></mcc-fi-date-picker>

            <mcc-fi-textbox *ngSwitchCase="'FLOAT'" [name]="'defaultValue'"
                            [validDecimalNumber]="true"
                            [validRequiredFlg]="defaultValueCbChecked"
                            [mcForm]="mcForm"></mcc-fi-textbox>

            <mcc-fi-radio-buttons *ngSwitchCase="'BOOLEAN'"
                                  [name]="'defaultValue'"
                                  [directionHorizontal]="true"
                                  [mcForm]="mcForm"
                                  [validRequiredFlg]="defaultValueCbChecked"
                                  [optionsVlm]="booleanVlm"></mcc-fi-radio-buttons>
          </div>

        </div>


      </div>

    </div><!-- modal-body -->

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-footer">

      <button class="btn btn-secondary move-right" (click)="actCancel()">
        <i class="far fa-times-circle"></i> {{ 'cc.common.edit.cancel' | translate }}
      </button>
      <button class="btn btn-primary mr-3" (click)="actAddAttribute()">
        <i class="fas fa-plus"></i> {{'cc.common.edit.add-attribute' | translate}}
      </button>

    </div>
  </div>

  <router-outlet></router-outlet>
</ng-container>
