import {EbPaymentReport2SepaFileTransaction} from './eb-payment-report-2-sepa-file-transaction.model';
import {EbPaymentReport2SepaFileTransactionListGENERATED} from '../_generated/eb-payment-report-2-sepa-file-transaction-list-generated.model';

export class EbPaymentReport2SepaFileTransactionList extends EbPaymentReport2SepaFileTransactionListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbPaymentReport2SepaFileTransactionList();
  }

  // ---------------------------------------------------------------------
}
