
import {Table3Config} from '../extra/table-3-config';

// import {ImDataMappingItemGENERATED} from '../_generated/im-data-mapping-item-generated.model';

export class ImDataMappingItemTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAttributeName', 'Attribute name', 'attributeName', 'attributeName');
    this.addColumn('colColumnName', 'Column name', 'columnName', 'columnName');
    this.addColumn('colIdDataMapping', 'DataMapping ID', 'idDataMapping', 'idDataMapping');
    this.addColumn('colMapping', 'Mapping', 'mapping', 'mapping');
    this.addColumn('colRequiredFlg', 'Required flag', 'requiredFlg', 'requiredFlg');
    this.addColumn('colSampleData', 'Sample data', 'sampleData', 'sampleData');

*/
  }
}
