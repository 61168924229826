import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {McAuthService, McCreateUserService, McUtilityService, userEnums} from "@miticon-ui/mc-core";

@Component({
  selector: "lib-incomplete-profile",
  templateUrl: "./incomplete-profile.html"
})
export class IncompleteProfileComponent implements OnInit {

  // Variables
  welcomeImg: string;
  userImage: any;
  logo: string;
  message!: string;
  type!: string;
  responseMessage!: boolean;
  timezones: any;
  languages: any;
  countries: any;
  isMandatoryNotFilled!: boolean;
  mandatoryNotSelected!: boolean;
  genderRadio!: boolean;
  entityId!: number;
  mandatoryFieldsDisabled!: boolean;
  showLoader!: boolean;

  // Form
  incompleteProfileForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: McAuthService,
    private createUserService: McCreateUserService,
    private utilityService: McUtilityService,
    @Inject("designConfig") design: any,
  ) {
    this.incompleteProfileForm = new FormGroup({});
    this.welcomeImg = design.welcomeImg;
    this.userImage = design.userImage;
    this.logo = design.logo;

    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation()?.extras.state) {
        this.entityId = this.router.getCurrentNavigation()?.extras?.state?.["entity_id"];
      } else {
        this.authService.logout();
      }
    });
  }

  ngOnInit(): void {
    this.setSelectedFields();
    this.createForm();
    this.isMandatoryNotFilled = true;
    this.primaryMsg();
  }

  // Prefill selected fields (language, timezone, country)
  private setSelectedFields() {
    // setLanguages
    this.languages = [
      {name: "English", value: "EN"},
      {name: "German", value: "DE"},
      {name: "Italy", value: "IT"}
    ];
    // setTimezones
    this.timezones = [
      "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      "(UTC-08:00) Pacific Time (US & Canada)"
    ];
    // setCountries
    this.countries = [
      {name: "USA", value: "US"},
      {name: "German", value: "DE"},
      {name: "Italy", value: "IT"}
    ];
  }

  // Set email primary msg
  primaryMsg() {
    this.responseMessage = true;
    this.setNotificationMessage(userEnums.mandatoryFields, "success");

  }

  // Check mandatory fields
  checkMandatoryInputFields(field: string) {
    let msgText = "";
    let msgType = "";
    const formData = this.incompleteProfileForm.getRawValue();
    const firstName = formData.firstName;
    const lastName = formData.lastName;
    const nickname = formData.nickname;

    switch (field) {
      case "firstName":
        if (firstName.length < 2 ) {
          msgText = userEnums.firstNameMin2Char;
          msgType = "error";
        } else {
          this.responseMessage = false;
        }
        this.setNotificationMessage(msgText, msgType);
        this.responseMessage = true;
        break;
      case "lastName":
        if (lastName.length < 2) {
          msgText = userEnums.lastNameMin2Char;
          msgType = "error";
        } else {
          this.responseMessage = false;
        }
        this.setNotificationMessage(msgText, msgType);
        this.responseMessage = true;
        break;
      case "gender":
        if (formData.gender === "") {
          msgText = userEnums.selectGender;
          msgType = "error";
          this.setNotificationMessage(msgText, msgType);
          this.responseMessage = true;
        } else {
          this.responseMessage = false;
        }
        break;
    }

    this.isMandatoryNotFilled = !(this.incompleteProfileForm?.controls?.["firstName"].valid &&
      this.incompleteProfileForm.controls["lastName"].valid &&
      this.incompleteProfileForm.controls["gender"].valid);
  }

  // Upload and preview image
  onFileChanged(event: Event) {
    // @ts-ignore
    const file: File | undefined = (event.target as HTMLInputElement)?.files?[0] : undefined;
    const reader = new FileReader();
    reader.onload = () => {
      this.userImage = reader.result;
    };
    // @ts-ignore
    reader.readAsDataURL(file);
  }

  // Initialize page form
  createForm() {
    this.incompleteProfileForm = this.fb.group({
      firstName: ["", [Validators.required, Validators.minLength(2)]],
      lastName: ["", [Validators.required, Validators.minLength(2)]],
      nickname: ["", [Validators.minLength(2)]],
      language: [this.languages[0].value],
      timezone: [this.timezones[0]],
      country: [this.countries[0].value],
      image: [""],
      gender: ["", Validators.required],
    });
  }

  saveAdditionalData() {
    this.showLoader = true;
    const parentFormData = this.incompleteProfileForm.value;
    const body = {
      firstName: parentFormData.firstName,
      lastName: parentFormData.lastName,
      nickname: parentFormData.nickname || null,
      gender: parentFormData.gender,
      isoCountry: parentFormData.country || null,
      isoLanguage: parentFormData.language || null,
      timezone: 0,
      rawImageData: this.userImage || null
    };

    this.createUserService.updateUserIncompleteProfile(body).subscribe(
      (response) => {
        const msgText = "User profile complete!";
        const msgType = "success";
        this.responseMessage = true;
        this.setNotificationMessage(msgText, msgType);
        this.router.navigate([`admin/entity/${this.entityId}/dashboard`]);
        this.showLoader = false;
      },
      (error) => {
        let message = "";
        let msgType = "";
        if (error.status >= 500) {
          message = userEnums.userServerError;
          msgType = "error";
        } else if (error.status === 403) {
          message = userEnums.userServerError;
          msgType = "error";
        } else if (error.error) {
          if (error.error.errors.length > 1) {
            message = error.error.errors.join();
            msgType = "error";
          } else {
            message = error.error.errors;
            msgType = "error";
          }
        } else {
          message = userEnums.userRequestError;
          msgType = "error";
        }
        this.responseMessage = true;
        this.setNotificationMessage(message, msgType);
        setTimeout(() => {
          this.responseMessage = false;
          this.setNotificationMessage("", "");
        }, 5000);
        this.showLoader = false;
      }
    );
  }

  // Clear info message
  clearInfoMsg() {
    this.responseMessage = false;
    this.mandatoryNotSelected = false;
    this.setNotificationMessage("", "");
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

}
