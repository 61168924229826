<ng-container class="dialog">
  <div class="dialog_header">
    <span class="dialog_header_title text-uppercase">
      {{ "cc.jobs.view.create-job" | translate }}
    </span>
    <span class="dialog_header_icon"
          [innerHTML]="iconClose | safeHtml"
          (click)="dialogRef.close(false)"></span>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{ "cc.jobs.job-type" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="type"
                      disableOptionCentering>
            <mat-option *ngFor="let type of jobTypes"
                        (click)="changeJobType(type.value)"
                        [value]="type.value">
              {{ type.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="jobType" appearance="outline">
          <mat-label>{{ 'cc.common.name' | translate }}</mat-label>
          <input type="text"
                 formControlName="name"
                 matInput/>
        </mat-form-field>
      </div>
      <div *ngIf="jobType"  class="form_description">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.common.view.description' | translate }}</mat-label>
          <input type="text"
                 formControlName="description"
                 matInput/>
        </mat-form-field>
      </div>
      <div *ngIf="jobType" class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{ "cc.jobs.schedule" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="jobType"
                      (valueChange)="resetDayAndHour()"
                      disableOptionCentering>
            <mat-option *ngFor="let scheduleType of scheduleTypes"
                        [value]="scheduleType.value">
              {{ scheduleType.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="form.controls['jobType'].value && !(form.controls['jobType'].value === 'DAILY')" appearance="outline">
          <mat-label>{{ "cc.common.day" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="day"
                      disableOptionCentering>
            <mat-option
              (click)="changeMonthDay(day.value)"
              *ngFor="let day of (form.controls['jobType'].value === 'WEEKLY'
              ? weeklyDays : (isSepaExport() ? monthlyDays : statementMonthlyDays))"
                        [value]="day.value">
              {{ day.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="!isSendRemindersAutomation() && (form.controls['day'].value || form.controls['jobType'].value === 'DAILY')" class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{ "cc.jobs.edit.time" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="hour"
                      disableOptionCentering>
            <mat-option *ngFor="let hour of hours"
                        [value]="hour.value">
              {{ hour.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form_fields" *ngIf="secondStatement">
        <mat-form-field appearance="outline">
          <mat-label>{{ "cc.jobs.second-statement-x-days" | translate }}</mat-label>
          <input type="text"
                 (keydown)="onlyNumbersCheck($event)"
                 formControlName="secondStatement"
                 matInput/>
        </mat-form-field>
      </div>
      <div class="form_fields" *ngIf="jobType">
        <mat-form-field appearance="outline" *ngIf="isSepaExport()">
          <mat-label>{{ "cc.incoming-payments.request-collection-date" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="statementDate"
                      disableOptionCentering>
            <mat-option *ngFor="let date of statementDate" [value]="date.value">
              {{ date.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="isSepaExport() && isNumberOfDays()">
          <mat-label>{{ "cc.jobs.creation-date-number-of-days" | translate }}</mat-label>
          <input type="text"
                 (keydown)="onlyNumbersCheck($event)"
                 formControlName="statementDateDays"
                 matInput/>
        </mat-form-field>
      </div>
      <div class="form_fields" *ngIf="jobType">
        <mat-form-field appearance="outline" *ngIf="isSepaExport()">
          <mat-label>{{ "cc.common.edit.bank-account" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="idMcBankAccount"
                      disableOptionCentering>
            <mat-option *ngFor="let bank of bankAccounts" [value]="bank.id">
              {{ bank.bankName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="isSepaExport()">
          <mat-label>{{ "cc.jobs.export-file-type" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="typeCd"
                      disableOptionCentering>
            <mat-option *ngFor="let exportType of exportFileTypes" [value]="exportType.value">
              {{ exportType.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form_fields" *ngIf="jobType">
        <mat-form-field appearance="outline" *ngIf="isSepaExport()">
          <mat-label>{{ "cc.factoring.transactions.transaction-status" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="statusCds"
                      disableOptionCentering>
            <mat-option *ngFor="let status of transactionStatuses" [value]="status.value">
              {{ status.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isSendRemindersAutomation()" appearance="outline">
          <mat-label>{{ "cc.jobs.edit.time" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="hour"
                      disableOptionCentering>
            <mat-option *ngFor="let hour of remindersHours"
                        [value]="hour.value">
              {{ hour.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "cc.common.admin.entities" | translate }}</mat-label>
          <mat-select formControlName="childEntityIds"
                      #multiselect
                      multiple
                      disableOptionCentering>
            <div class="select-all-checkbox" [class.all-checked]="allSelected">
              <mat-checkbox [(ngModel)]="allSelected"
                            [ngModelOptions]="{standalone: true}"
                            (change)="toggleSelectAllEntities()">
                {{   "cc.common.view.select-all" | translate}}
              </mat-checkbox>
            </div>
            <mat-option  *ngFor="let entity of entities" [value]="entity.id" (click)="optionClick()">
              {{ entity.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form_fields" *ngIf="jobType">
        <mat-form-field appearance="outline" *ngIf="isStatementsAutomation()">
          <mat-label>{{ "cc.billing.statement-date" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="statementDate"
                      disableOptionCentering>
            <mat-option *ngFor="let date of statementDate" [value]="date.value">
              {{ date.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="isStatementsAutomation() && isNumberOfDays()">
          <mat-label>{{ "cc.jobs.creation-date-number-of-days" | translate }}</mat-label>
          <input type="text"
                 (keydown)="onlyNumbersCheck($event)"
                 formControlName="statementDateDays"
                 matInput/>
        </mat-form-field>
      </div>
      <div *ngIf="isSendRemindersAutomation()">
        <div class="line mt-medium"></div>
        <div class="config mt-medium">{{ "cc.jobs.run-config" | translate }}</div>
        <div class="config_item mt-large">{{ "cc.jobs.run.new-items" | translate }}</div>
        <div class="config_item mt-large">{{ "cc.jobs.run.execution" | translate }}</div>
        <div class="config_item mt-large">{{ "cc.jobs.run.as-soon" | translate }}</div>
        <div class="config_item mt-large">{{ "cc.jobs.run.upon-duilding" | translate }}</div>
      </div>
      <div class="radio_buttons" *ngIf="jobType && isStatementsAutomation()">
        <div class="radio_buttons_item">
          <h3>{{ "cc.jobs.auto-import-billing-items" | translate }}</h3>
          <div>
            <mat-radio-group formControlName="autoImportFlg">
              <mat-radio-button [value]="true">{{'cc.common.edit.yes' | translate}}</mat-radio-button>
              <mat-radio-button [value]="false">{{'cc.common.edit.no' | translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="radio_buttons_item">
          <h3>{{ "cc.jobs.finalize-statements" | translate }}</h3>
            <div>
            <mat-radio-group formControlName="finalizeFlg">
              <mat-radio-button [value]="true">{{'cc.common.edit.yes' | translate}}</mat-radio-button>
              <mat-radio-button [value]="false">{{'cc.common.edit.no' | translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button
            class="secondary-btn"
            (click)="dialogRef.close(false)">
      {{ 'cc.common.edit.cancel' | translate }}
    </button>
    <button mat-button
            class="primary-btn"
            [disabled]="!areAllFieldsFilled()"
            (click)="createJob()"
            type="submit">
      {{ 'cc.common.create' | translate }}
    </button>
  </div>
</ng-container>
