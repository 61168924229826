/*BbmTestObj*/
import {McProductSnapshotGENERATED} from '../_generated/mc-product-snapshot-generated.model';

export class McProductSnapshot extends McProductSnapshotGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProductSnapshot();
  }

  // ---------------------------------------------------------------------
}
