/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McConsumerBalance} from '../models/mc-consumer-balance.model';
import {McConsumerBalanceList} from '../models/mc-consumer-balance-list.model';
import {McConsumerBalanceFilter} from '../models/mc-consumer-balance-filter.model';
import {McConsumerBalanceService} from '../services/mc-consumer-balance.service';
import {IMcConsumerBalance} from '../_generated/mc-consumer-balance.interface';
import {McGod} from "../extra/mc-god.service";

export class McConsumerBalanceListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'amount', 'entityId', 'entityName', 'firstName', 'lastName'];
  public items: McConsumerBalance[] = [];
  public apiService: McConsumerBalanceService;
 // protected tempObjList: McConsumerBalanceList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McConsumerBalanceList {
    console.error('MTCN-ERROR: Not Implemented: McConsumerBalanceList::createNewInstance(). Add this method to final class and return new McConsumerBalanceList();');
    throw new Error('Cannot McConsumerBalanceList::createNewInstance(). Add this method to final class and return new McConsumerBalanceList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcConsumerBalance[]): McConsumerBalanceList {
    const  listObj: McConsumerBalanceList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McConsumerBalance = McConsumerBalance.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McConsumerBalanceList {
    const  listObj: McConsumerBalanceList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McConsumerBalanceService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcConsumerBalance[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McConsumerBalance = McConsumerBalance.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcConsumerBalanceFilter: McConsumerBalanceFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcConsumerBalanceFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McConsumerBalance {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McConsumerBalance();
    }
  }

  public replaceItem(newItem: McConsumerBalance) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

