/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McBillingPayoutFileFeCtd} from '../models/mc-billing-payout-file-fe-ctd.model';
import {McBillingPayoutFileFeCtdList} from '../models/mc-billing-payout-file-fe-ctd-list.model';
import {McBillingPayoutFileFeCtdFilter} from '../models/mc-billing-payout-file-fe-ctd-filter.model';
import {McBillingPayoutFileFeCtdService} from '../services/mc-billing-payout-file-fe-ctd.service';
import {IMcBillingPayoutFileFeCtd} from '../_generated/mc-billing-payout-file-fe-ctd.interface';
import {McGod} from "../extra/mc-god.service";

export class McBillingPayoutFileFeCtdListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'fileName', 'idMcBankAccount', 'numberOfStatements', 'payoutDate', 'statusCd', 'statusDetails', 'sysCreatedDatetime', 'sysCreatedUserId', 'totalFileAmount', 'typeCd', 'xlsWebfileId', 'bankName', 'createdBy', 'mcCurrency'];
  public items: McBillingPayoutFileFeCtd[] = [];
  public apiService: McBillingPayoutFileFeCtdService;
 // protected tempObjList: McBillingPayoutFileFeCtdList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingPayoutFileFeCtdList {
    console.error('MTCN-ERROR: Not Implemented: McBillingPayoutFileFeCtdList::createNewInstance(). Add this method to final class and return new McBillingPayoutFileFeCtdList();');
    throw new Error('Cannot McBillingPayoutFileFeCtdList::createNewInstance(). Add this method to final class and return new McBillingPayoutFileFeCtdList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcBillingPayoutFileFeCtd[]): McBillingPayoutFileFeCtdList {
    const  listObj: McBillingPayoutFileFeCtdList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McBillingPayoutFileFeCtd = McBillingPayoutFileFeCtd.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McBillingPayoutFileFeCtdList {
    const  listObj: McBillingPayoutFileFeCtdList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingPayoutFileFeCtdService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcBillingPayoutFileFeCtd[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McBillingPayoutFileFeCtd = McBillingPayoutFileFeCtd.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcBillingPayoutFileFeCtdFilter: McBillingPayoutFileFeCtdFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcBillingPayoutFileFeCtdFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McBillingPayoutFileFeCtd {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McBillingPayoutFileFeCtd();
    }
  }

  public replaceItem(newItem: McBillingPayoutFileFeCtd) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

