import {CsrContract2ProductListGENERATED} from '../_generated/csr-contract-2-product-list-generated.model';

export class CsrContract2ProductList extends CsrContract2ProductListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrContract2ProductList();
  }

  // ---------------------------------------------------------------------
}
