import {McUser} from './mc-user.model';
import {McUserListGENERATED} from '../_generated/mc-user-list-generated.model';

export class McUserList extends McUserListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McUserList();
  }

  // ---------------------------------------------------------------------
}
