import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McEntityProfileComponent } from './mc-entity-profile.component';
import {McEntityProfileRoutingModule} from './mc-entity-profile-routing.module';
import {NgbModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {McStatusModule, McComponentsModule, McBankAccountsCurrentEntityAdminModule} from '@miticon-ui/mc-components';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {McEditEntityBasicInfoModalComponent} from './components/mc-edit-entity-basic-info-modal/mc-edit-entity-basic-info-modal.component';
import {McEditEntityAttributesModalComponent} from './components/mc-edit-entity-attributes-modal/mc-edit-entity-attributes-modal.component';
import {McEditEntityPasswordPolicyModalComponent} from './components/mc-edit-entity-password-policy-modal/mc-edit-entity-password-policy-modal.component';
import {McEntityTransactionDescriptionModalComponent} from './components/mc-entity-transaction-description-modal/mc-entity-transaction-description-modal.component';
import {McEntityBasicInfoModule} from '../mc-management/mc-entity/components/mc-entity-basic-info/mc-entity-basic-info.module';
import {McEntityAttributesModule} from '../mc-management/mc-entity/components/mc-entity-attributes/mc-entity-attributes.module';
import {McEntityPasswordPolicyModule} from '../mc-management/mc-entity/components/mc-entity-password-policy/mc-entity-password-policy.module';
import {TranslateModule} from '@ngx-translate/core';
import {McInvoiceModule} from '../mc-management/mc-invoice/mc-invoice.module';
import { McEntityFactoringWrapperPartParentComponent } from './parts/mc-entity-factoring-wrapper-part/mc-entity-factoring-wrapper-part-parent.component';
import {McEntityFactoringWrapperPartChildComponent} from './parts/mc-entity-factoring-wrapper-part/mc-entity-factoring-wrapper-part-child.component';
import { AppSharedModule } from '../app-shared/app-shared.module';


@NgModule({
  declarations: [
    McEntityProfileComponent,
    McEditEntityBasicInfoModalComponent,
    McEditEntityAttributesModalComponent,
    McEntityTransactionDescriptionModalComponent,
    McEditEntityPasswordPolicyModalComponent,
    McEntityFactoringWrapperPartParentComponent,
    McEntityFactoringWrapperPartChildComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgbTooltipModule,
    McEntityProfileRoutingModule,
    McStatusModule,
    McComponentsModule,
    ReactiveFormsModule,
    McEntityBasicInfoModule,
    McEntityAttributesModule,
    McEntityPasswordPolicyModule,
    TranslateModule,
    McInvoiceModule,
    McBankAccountsCurrentEntityAdminModule,
    FormsModule,
    AppSharedModule
  ],
  exports: [
    McEntityProfileRoutingModule,
    McEntityProfileComponent,
  ]
})
export class McEntityProfileModule { }
