import {CsrContractProductsFilterGENERATED} from '../_generated/csr-contract-products-filter-generated.model';

export class CsrContractProductsFilter extends CsrContractProductsFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrContractProductsFilter();
  }

  // ---------------------------------------------------------------------
}
