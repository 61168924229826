import {EbSepaFileTransaction, McBoolean, McConsumer} from '@miticon-ui/mc-core';
import {AppSharedTransactionAddEditPartComponent} from './app-shared-transaction-add-edit-part.component';

export class AppSharedTransactionAddEditPartConfig {
  private _component: AppSharedTransactionAddEditPartComponent;
  private _handlerComponentSet: () => void;
  private type: 'add' | 'edit' | '';
 // private _readyFlg = false;

  public showMcb = new McBoolean();
  public consumer: McConsumer | null;
  public ebSepaFileTransactionId: number;
  public ebSepaFileTransaction: EbSepaFileTransaction | null;

  // ---------------------------------------------------------------------
  private clear() {
    this.type = '';
    this.ebSepaFileTransactionId = 0;
    this.consumer = null;
    this.ebSepaFileTransaction = null;
  }

  // ---------------------------------------------------------------------
  public showAdd(consumer: McConsumer) {
    this.clear();
    this.type = 'add';

    this.consumer = consumer;
    this.showMcb.setTrue();
  }

  // ---------------------------------------------------------------------
  public showEditById(ebSepaFileTransactionId: number) {
    this.clear();
    this.type = 'edit';
    this.ebSepaFileTransactionId = ebSepaFileTransactionId;
    this.showMcb.setTrue();
  }

  // ---------------------------------------------------------------------
  public init(component: AppSharedTransactionAddEditPartComponent) {
    this._component = component;
  }

  // ---------------------------------------------------------------------
  public isVisible(): boolean {
    return this.showMcb.value;
  }

  // ---------------------------------------------------------------------
  public hide() {
    this.showMcb.setFalse();
  }

  public setComponent(aComponent: any) {
    this._component = aComponent;
    if (this._handlerComponentSet) {
      this._handlerComponentSet();
    }
  }

  public isTypeAdd() {
    return this.type === "add";
  }
  public isTypeEdit() {
    return this.type === "edit";
  }

  public isReady() {
    return this.isTypeAdd() || this.isTypeEdit();
  }
}
