import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {EbPsd2ReportService, McBankAccountList, McDateUtils, McForm, McGod, McValueLabelList} from "@miticon-ui/mc-core";

@Component({
  selector: 'eb-bank-module-import-psd2-statement-part',
  templateUrl: './eb-bank-module-import-psd2-statement-part.component.html',
  styleUrls: ['./eb-bank-module-import-psd2-statement-part.component.css']
})
export class EbBankModuleImportPsd2StatementPartComponent implements OnInit {

  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter();

  bankAccounts = new McBankAccountList()

  //----services
  ebPsd2ReportService = new EbPsd2ReportService();
  mcGod = McGod.getInstance();

  //----form
  mcForm = new McForm();
  bankAccountVll = new McValueLabelList();

  //----flags
  apiErrorFlg = false;
  errorMsg!: string;
  successMsg!: string;
  loadingFlg = false;
  badInputsFlg = false;
  badInputsErrorMsg = '';
  importedFlg = false;

  constructor() { }

  ngOnInit(): void {

    this.bankAccounts.loadByEntityId(McGod.getLoggedEntityIdFromToken(), () => {
      this.bankAccountVll = this.bankAccounts.toVll('id', 'fldTitleIbanBicDefault()', `-- ${this.mcGod.t('cc.common.please-select-bank-account')}`);
      this.loadingFlg = false;
    });

  }

  actImport(){
    const bankAccountId: number = this.mcForm.getValue('selBankAccountId');
    const dateFrom: string = McDateUtils.formatDateToString(this.mcForm.getValue('dateFrom'), 'YYYY-MM-DD')
    const dateTo: string = McDateUtils.formatDateToString(this.mcForm.getValue('dateTo'), 'YYYY-MM-DD')

    //todo implement importing

    this.ebPsd2ReportService.getPsd2Statement(bankAccountId, dateFrom, dateTo)
      .subscribe(res => {
          this.importedFlg = true
          this.eventSaved.emit(res);
      },
        error => {
          this.apiErrorFlg = true
          this.errorMsg = error.error.message
        })
  }

  actCancel() {
    this.eventCanceled.emit();
  }

}
