import {PmAdjustmentFilterGENERATED} from '../_generated/pm-adjustment-filter-generated.model';

export class PmAdjustmentFilter extends PmAdjustmentFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new PmAdjustmentFilter();
  }

  // ---------------------------------------------------------------------
}
