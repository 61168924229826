import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { McDateUtils, McForm, McProcessItem, McProcessItemList} from "@miticon-ui/mc-core";

@Component({
  selector: 'mc-process-process-item-change-next-action-datetime',
  templateUrl: './mc-process-process-item-change-next-action-datetime.component.html',
})
export class McProcessProcessItemChangeNextActionDatetimeComponent implements OnInit {

  mcForm = new McForm();
  today = McDateUtils.getNowTimestamp();
  todayString!: string;
  mcProcessItem = new McProcessItem();
  errorMsg = '';
  @Input() mcProcessItemList!: McProcessItemList;
  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter();

  ngOnInit() {
    this.todayString = McDateUtils.formatDateToString(this.today);
  }

  actSaved() {
    if (this.mcForm.isValid()) {
      const nextActionDate = this.mcForm.getValue('ctrlNextActionDate');
      console.log('ITEM LIST 444', this.mcProcessItemList);
      this.mcProcessItem.callChangeActionStartDatetime(nextActionDate, this.mcProcessItemList.getIds(), () => {
        this.eventSaved.emit();
      }, () => {
        this.errorMsg = this.mcProcessItem.apiErrorMessage;
      });

    }
  }

  actCanceled() {
    this.eventCanceled.emit();
  }
}
