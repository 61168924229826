/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {ImDataMappingItemTable3ConfigGENERATED} from '../_generated/im-data-mapping-item-table-3-config-generated.model';

// import {ImDataMappingItemGENERATED} from '../_generated/im-data-mapping-item-generated.model';

export class ImDataMappingItemTable3Config extends ImDataMappingItemTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colAttributeName', 'Attribute name', 'attributeName', 'attributeName');
    this.addColumn('colColumnName', 'Column name', 'columnName', 'columnName');
    this.addColumn('colIdDataMapping', 'DataMapping ID', 'idDataMapping', 'idDataMapping');
    this.addColumn('colMapping', 'Mapping', 'mapping', 'mapping');
    this.addColumn('colRequiredFlg', 'Required flag', 'requiredFlg', 'requiredFlg');
    this.addColumn('colSampleData', 'Sample data', 'sampleData', 'sampleData');


  }
}
