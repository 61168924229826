
import {Table3Config} from '../extra/table-3-config';

// import {McBillingStatementItemGENERATED} from '../_generated/mc-billing-statement-item-generated.model';

export class McBillingStatementItemTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAmount', 'Amount', 'amount', 'amount');
    this.addColumn('colCorrectionTypeCd', 'Correction Type Cd', 'correctionTypeCd', 'correctionTypeCd');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colIdBillingService', 'Id Billing Service', 'idBillingService', 'idBillingService');
    this.addColumn('colMcBillingBatchId', 'Billing batch id', 'mcBillingBatchId', 'mcBillingBatchId');
    this.addColumn('colMcBillingStatementId', 'Billing statement ID', 'mcBillingStatementId', 'mcBillingStatementId');
    this.addColumn('colMcBillingBatch', 'Billing batch', 'mcBillingBatch', 'mcBillingBatch');
    this.addColumn('colMcBillingStatement', 'Billing statement', 'mcBillingStatement', 'mcBillingStatement');
    this.addColumn('colPriceDescription', 'Price description', 'priceDescription', 'priceDescription');
    this.addColumn('colStatusCd', 'Status cd', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colTransactionReturnedTypeCd', 'Type cd', 'transactionReturnedTypeCd', 'transactionReturnedTypeCd');
    this.addColumn('colTypeCd', 'Type cd', 'typeCd', 'typeCd');
    this.addColumn('colVatPercentageForCorrection', 'Vat percentage for correction', 'vatPercentageForCorrection', 'vatPercentageForCorrection');
    this.addColumn('colVatXAmount', 'vat x amount', 'vatXAmount', 'vatXAmount');

*/
  }
}
