/*BbmTestObj*/
import {CsrConsumerGENERATED} from '../_generated/csr-consumer-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {ActionOptions} from '../_core/mc-html-action-options';
import {McDateUtils} from "../common/mc-date-utils";
import {McEntityConsumerAttributeList} from "../models/mc-entity-consumer-attribute-list.model";
import {TypographyUtils} from "../common/typography-utils";
import {ICON_LINK} from "../svg/icon-link";

export class CsrConsumer extends CsrConsumerGENERATED {

  private _fldActions!: McHtml | null;
  private _fldActionsSmartSearchBox!: McHtml;
  entityConsumerAttributes: McEntityConsumerAttributeList;
  contractAttributes = {
    contractNumber: '',
    contractStatus: '',
    contractEndDate: '',
    contractStartDate: '',
    contractBaseAmount: 0,
    contractSigningDate: '',
    contractActiveOndate: '',
    contractCancellationDate: '',
    contractCancellationReason: '',
    contractCancellationActiveOnDate: '',
    contractModel: '',
    contractType: '',
  };

  additionalAttributes: { [key: string]: any };

  public static ACTION_SHOW_PROFILE = 'PROFILE';
  public static ACTION_SHOW_EDIT = 'EDIT';
  public static ACTION_SHOW_ADD_TRANSACTION = 'AT';
  public static ACTION_SHOW_HISTORY = 'HISTORY';
  public static ACTION_SHOW_ACTION_HISTORY = 'ACT_SHOW_ACTION_HISTORY';
  public static ACTION_SHOW_TRANSACTIONS = 'ACT_SHOW_TRANSACTIONS';
  public static ACTION_SHOW_INVOICES = 'ACT_SHOW_INVOICES';

  public static FILTER_STATUS = 'cc.common.view.status';
  public static FILTER_TYPE = 'cc.jobs.edit.type';
  public static FILTER_COUNTRY   = 'cc.users.edit.country';

  public properties: string[] = ['id', 'accountOwner', 'addressSupplement', 'archived', 'bankName', 'bic', 'birthday', 'cellphone', 'city', 'companyName', 'contractBaseAmount', 'contractCancellationActiveOnDate', 'contractCancellationDate', 'contractCancellationReason', 'contractEndDate', 'contractModel', 'contractNumber', 'contractSigningDate', 'contractStartDate', 'contractStatus', 'contractType', 'countryName', 'customAttributes', 'customAttributesJsonb', 'email', 'emgLastName', 'emgName', 'emgPhoneNumber', 'emgRelationCd', 'euVat', 'extConsumerId', 'fax', 'firstName', 'flgDunningEnabled', 'flgInkassoEnabled', 'gender', 'iban', 'idMcEntity', 'isoCountry', 'isoLanguage', 'lastName', 'postCode', 'sepaMandanteDate', 'sepaMandanteId', 'statusCd', 'street', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'telephone', 'title', 'typeCd', 'version', 'activeContracts', 'bankAccounts', 'displayName', 'entityName', 'idMcEntity'];
  public propertiesRegular: string[] = ['id', 'accountOwner', 'addressSupplement', 'archived', 'bankName', 'bic', 'birthday', 'cellphone', 'city', 'companyName', 'contractBaseAmount', 'contractCancellationActiveOnDate', 'contractCancellationDate', 'contractCancellationReason', 'contractEndDate', 'contractModel', 'contractNumber', 'contractSigningDate', 'contractStartDate', 'contractStatus', 'contractType', 'countryName', 'customAttributes', 'customAttributesJsonb', 'email', 'emgLastName', 'emgName', 'emgPhoneNumber', 'emgRelationCd', 'euVat', 'extConsumerId', 'fax', 'firstName', 'flgDunningEnabled', 'flgInkassoEnabled', 'gender', 'iban', 'idMcEntity', 'isoCountry', 'isoLanguage', 'lastName', 'postCode', 'sepaMandanteDate', 'sepaMandanteId', 'statusCd', 'street', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'telephone', 'title', 'typeCd', 'version', 'displayName', 'entityName','idMcEntity'];

  public static ISO_COUNTRIES = [
    { label: 'cc.common.view.spain', value: 'ESP' },
    { label: 'cc.common.view.italy', value: 'IT' },
    { label: 'cc.common.view.switzerland', value: 'CH' },
    { label: 'cc.common.view.austria', value: 'AT' },
    { label: 'cc.common.view.belgium', value: 'BE' },
    { label: 'cc.common.view.czechia', value: 'CZ' },
    { label: 'cc.common.view.germany', value: 'DE' },
    { label: 'cc.common.view.netherlands', value: 'NL' },
    { label: 'cc.common.view.portugal', value: 'PT' },
  ];

  public static ISO_LANGUAGES = [
    { label: 'cc.common.view.english', value: 'EN' },
    { label: 'cc.common.view.german', value: 'DE' },
    { label: 'cc.common.view.italian', value: 'IT' },
    { label: 'cc.common.view.portugal', value: 'PT'}
  ];

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrConsumer();
  }

  // ---------------------------------------------------------------------

  fldBlacklisted() {
    /*if (this.activeBlacklist.exists()) { // TODO Blacklist
      return 'Blacklisted';
    } else {
      return '';
    }*/
  }

  public static getNameFromList(code: string, list: { value: string, label: string }[]): string {
    if (code) {
      const item = list.find((entry) => entry.value === code);
      return item ? McGod.t(item.label) : '-';
    }
    return '-';
  }

  fldName() {
    //   if (this.individual === true) {
    return ''.concat(this.fldFullName(), '<br>', this.companyName);
    //  } else {
    //    return this.companyName;
    //  }
  }

  fldActions() {
    // this.router.navigate([`/admin/entity/${entityId}/management/consumers/edit/${content.id}`], {queryParams: {activeTab: "paymentInfoTab"}});

//    return "dasa je car";

    if (!this._fldActions) {
      const html = new McHtml();
      // html.addLinkB('<i class="fa mc-submit-color fa-pen px-1 mc-cursor-pointer"></i>', `/admin/entity/${this.idMcEntity}/management/consumers/edit/${this.id}`, this, '', {});
      // html.addLinkB('<i class="fas mc-submit-color fa-file-invoice"></i>', `/admin/entity/${this.idMcEntity}/management/consumers/edit/${this.id}`, this,  '', {queryParams: {activeTab: 'paymentInfoTab'}});

      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.common.view-profile')}`, CsrConsumer.ACTION_SHOW_PROFILE, this, [McGod.PERM_MC_CONSUMER_VIEW], '', ActionOptions.OPTION_VIEW);
      actSet.addActionB(`<i class="fa mc-submit-color fa-pen mc-cursor-pointer"></i> ${McGod.t('cc.consumer.edit-consumer')}`, CsrConsumer.ACTION_SHOW_EDIT, this, [McGod.PERM_MC_CONSUMER_EDIT], '', ActionOptions.OPTION_EDIT);
      actSet.addLinkB(`<i class="fa mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.consumers.view.history')}`, `/entity/${this.idMcEntity}/consumers/history/${this.id}`, this, [McGod.PERM_MC_CONSUMER_HISTORY_VIEW], '', ActionOptions.OPTION_VIEW);

      // actSet.addLinkB('<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> View', `/entity/${this.idMcEntity}/consumers/profile/${this.id}`, this);
      // actSet.addLinkB('<i class="fa mc-submit-color fa-pen px-1 mc-cursor-pointer"></i> Edit', `/admin/entity/${this.idMcEntity}/management/consumers/edit/${this.id}`, this, '', {queryParams: {activeTab: 'paymentInfoTab'}});
      // OLD actSet.addActionB('<i class="fa mc-submit-color fa-file-invoice px-1 mc-cursor-pointer"></i> History', McConsumer.ACTION_SHOW_HISTORY, this);
      actSet.addLinkB(`<i class="fa mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.common.view.view-transactions')}`, `/entity/${this.idMcEntity}/consumers/transactions/${this.id}`, this, [McGod.PERM_MC_CONSUMER_TRANSACTION_VIEW], '', ActionOptions.OPTION_VIEW);
      actSet.addActionB(`<i class="fa mc-submit-color fa-pen mc-cursor-pointer"></i> ${McGod.t('cc.common.view.add-transaction')}`, CsrConsumer.ACTION_SHOW_ADD_TRANSACTION, this, [McGod.PERM_EB_FACTORING_MY_TRANSACTION_ADD], '', ActionOptions.OPTION_EDIT);
      actSet.addLinkB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.common.invoices')}`, `/admin/entity/${this.idMcEntity}/management/consumers/edit/${this.id}`, this, [McGod.PERM_MC_TEMP_INVOICES_VIEW], '', ActionOptions.OPTION_VIEW, {queryParams: {activeTab: 'paymentInfoTab'}});
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> Action History`, CsrConsumer.ACTION_SHOW_ACTION_HISTORY, this, [McGod.PERM_MC_CONSUMER_ACTION_HISTORY_VIEW], '', ActionOptions.OPTION_VIEW);
      this._fldActions = html;
    }

    return this._fldActions;
  }

  // ---------------------------------------------------------------------
  fldAddress() {
    return this.cleanStr(this.street) + ' ' + this.cleanStr(this.addressSupplement) + '<br>' + this.cleanStr(this.postCode) + ' ' + this.cleanStr(this.city);
  }

  getNow() {
    return McDateUtils.newFormatDateTimeString(new Date());
  }

  cleanStr(str: string) {
    if (str) {
      return str;
    } else {
      return '';
    }
  }

  fldSmartSearchBoxActions() {
    if (!this._fldActionsSmartSearchBox) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addLinkB(`<i class="fa mc-submit-color fa-file-invoice px-1 mc-cursor-pointer"></i> ${McGod.t('cc.common.view.view-transactions')}`, `/entity/${this.idMcEntity}/consumers/transactions/${this.id}`, this, [McGod.PERM_MC_CONSUMER_TRANSACTION_VIEW], '', ActionOptions.OPTION_VIEW);
      actSet.addLinkB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.common.invoices')}`, `/admin/entity/${this.idMcEntity}/management/consumers/edit/${this.id}`, this, [McGod.PERM_MC_TEMP_INVOICES_VIEW], '', ActionOptions.OPTION_VIEW, {queryParams: {activeTab: 'paymentInfoTab'}});
      this._fldActionsSmartSearchBox = html;
    }

    return this._fldActionsSmartSearchBox;
  }

  individual(): boolean {
    return this.typeCd === CsrConsumer.TYPE_CD_PERSON;
  }

  getDisplayName() {
    return this.displayName;
  }

  fldFullName() {
    return this.firstName + ' ' + this.lastName;
  }

  getNameAndProfileLink() {
    return (this.firstName || this.lastName) ? `<div class='icon-text-table-cell' style='cursor: pointer'>${ICON_LINK} <span>${ this.firstName + ' ' + this.lastName }</div>` : `<div class='icon-text-table-cell' style='cursor: pointer'>${ICON_LINK} <span>-</div>`;
  }

  fldNameIndividualOrCompany(): string {
    switch(this.typeCd) {
      case "PERSON":
        return this.getDisplayName();
      case "COMPANY":
        return this.companyName;
      default:
        return '';
    }
  }

  fldNumOfActiveContracts() {
    if (this.activeContracts.items.length > 0) {
      return this.activeContracts.getCount();
    }
    return '0';
  }

  getFormattedDate(date: any): string {
    if (date)
      return McDateUtils.formatDateWithoutTimezone(date);
    else {
      return '';
    }
  }

  getCountryName() {
    if(this.isoCountry) {
      const country = CsrConsumer.ISO_COUNTRIES.find((country) => country.value === this.isoCountry);
      return country ? McGod.t(country.label) : '-';
    } else return  '-';
  }

  getActiveContracts() {
    return this.activeContracts.items.length;
  }

  override getTypeCdLabel(): string {
    return this.typeCd ? TypographyUtils.capitalizeFirstLetter(this.typeCd) : '';
  }

  public override populateFromJson(aSourceJson: any) {
    super.populateFromJson(aSourceJson);
    this.contractAttributes = aSourceJson.additionalAttributes;

    this.additionalAttributes = {};
    for (const key in aSourceJson.additionalAttributes) {
      if (!key.startsWith('contract')) {
        this.additionalAttributes[key] = aSourceJson.additionalAttributes[key];
      }
    }
  }

  public toDto(): any {
    const dto = super.toDto();
    if(this.additionalAttributes != null){
        dto['additionalAttributes'] = this.additionalAttributes;
    }
    return dto;
  }

  getEntityNameFromAdditionalAttributes(): string {
    return this.additionalAttributes['entityName'];
  }

  getCompanyName() {
   return this.companyName ? this.companyName : '-';
  }
}
