import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IBkFinanceReport} from '../_generated/bk-finance-report.interface';
import {BkFinanceReport} from '../models/bk-finance-report.model';
import {BkFinanceReportList} from '../models/bk-finance-report-list.model';
import {BkFinanceReportServiceGENERATED} from '../_generated/bk-finance-report-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BkFinanceReportService extends BkFinanceReportServiceGENERATED {

  override insert(aBkFinanceReport: any): Observable<IBkFinanceReport> {
    return this.httpClient.post<IBkFinanceReport>(this.getApiServiceRootUrl()  , aBkFinanceReport, {headers: this.httpOptions});
  }
}
