import { McDevBbmPageComponent } from './pages/mc-dev-bbm-page/mc-dev-bbm-page.component';
import { McDevIndexPageComponent } from './pages/mc-dev-index-page/mc-dev-index-page.component';
import { McDevTestFiPageComponent } from './pages/mc-dev-test-fi-page/mc-dev-test-fi-page.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard, McPermissionGuard} from '@miticon-ui/mc-core';

const routes: Routes = [
{
    path: 'bbm',
    component: McDevBbmPageComponent
  },
{
    path: '',
    component: McDevIndexPageComponent
  },
{
    path: 'forms',
    component: McDevTestFiPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McDevRoutingModule {
}
