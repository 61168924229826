import {WebFileFilterGENERATED} from '../_generated/web-file-filter-generated.model';

export class WebFileFilter extends WebFileFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new WebFileFilter();
  }

  // ---------------------------------------------------------------------
}
