import {CcMollieCustomerTable3ConfigGENERATED} from '../_generated/cc-mollie-customer-table-3-config-generated.model';

export class CcMollieCustomerTable3Config extends CcMollieCustomerTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colConfirmDatetime', 'Confirm date', 'confirmDatetime', 'confirmDatetime');
    this.addColumn('colConfirmationCode', 'Confirmation code', 'confirmationCode', 'confirmationCode');
    this.addColumn('colCsrConsumer', 'CsrConsumer', 'csrConsumer', 'csrConsumer');
    this.addColumn('colCsrContract', 'CsrContract', 'csrContract', 'csrContract');
    this.addColumn('colExpireDatetime', 'Expiration date', 'expireDatetime', 'expireDatetime');
    this.addColumn('colIdCsrConsumer', 'Id CsrConsumer', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colIdCsrContract', 'Id CsrContract', 'idCsrContract', 'idCsrContract');
    this.addColumn('colIdMollieCustomer', 'Mollie ID', 'idMollieCustomer', 'idMollieCustomer');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');


  }
}
