import {EbSepaStatementReportFilterGENERATED} from '../_generated/eb-sepa-statement-report-filter-generated.model';

export class EbSepaStatementReportFilter extends EbSepaStatementReportFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaStatementReportFilter();
  }

  // ---------------------------------------------------------------------
}
