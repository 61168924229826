import {Inject, Injectable} from '@angular/core';
import {EbAccountConsumerServiceGENERATED} from '../_generated/eb-account-consumer-generated.service';



@Injectable({
  providedIn: 'root'
})
export class EbAccountConsumerService extends EbAccountConsumerServiceGENERATED {

}
