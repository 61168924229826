/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CcMollieTransactionTable3ConfigGENERATED} from '../_generated/cc-mollie-transaction-table-3-config-generated.model';

// import {CcMollieTransactionGENERATED} from '../_generated/cc-mollie-transaction-generated.model';

export class CcMollieTransactionTable3Config extends CcMollieTransactionTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();


  }
}
