/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McConsumerAction} from '../models/mc-consumer-action.model';
import {McConsumerActionService} from '../services/mc-consumer-action.service';
import {IMcConsumerAction} from '../_generated/mc-consumer-action.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McConsumerActionGENERATED extends BaseObject {


    public static readonly STATUS_CD_PENDING: string = 'PENDING';
    public static readonly STATUS_CD_DONE: string = 'DONE';
    public static readonly STATUS_CD_ERROR: string = 'ERROR';
  public static readonly STATUS_CD_LIST__ALL = [
    McConsumerActionGENERATED.STATUS_CD_PENDING,
    McConsumerActionGENERATED.STATUS_CD_DONE,
    McConsumerActionGENERATED.STATUS_CD_ERROR
];

    public static readonly TYPE_CD_DUNNING: string = 'DUNNING';
  public static readonly TYPE_CD_LIST__ALL = [
    McConsumerActionGENERATED.TYPE_CD_DUNNING
];

  public apiService: McConsumerActionService;
  public _rawJson: IMcConsumerAction;
    id: number = 0;
  emailActionFlg: boolean;
  emailBodyAttachmentWebfileId: number;
  emailBodyHtml = '';
  emailSubject = '';
  errorMessage = '';
  idCsrConsumer: number;
  postActionFlg: boolean;
  postWebfileId: number;
  sentDatetime: number;
  smsActionFlg: boolean;
  smsBody = '';
  statusCd: string;
  sysCreatedDatetime: number;
  title = '';
  typeCd: string;


  public properties: string[] = ['id', 'emailActionFlg', 'emailBodyAttachmentWebfileId', 'emailBodyHtml', 'emailSubject', 'errorMessage', 'idCsrConsumer', 'postActionFlg', 'postWebfileId', 'sentDatetime', 'smsActionFlg', 'smsBody', 'statusCd', 'sysCreatedDatetime', 'title', 'typeCd'];
  public propertiesRegular: string[] = ['id', 'csrConsumer', 'emailActionFlg', 'emailBodyAttachmentWebfileId', 'emailBodyHtml', 'emailSubject', 'errorMessage', 'idCsrConsumer', 'postActionFlg', 'postWebfileId', 'sentDatetime', 'smsActionFlg', 'smsBody', 'statusCd', 'sysCreatedDatetime', 'title', 'typeCd'];
  public propertiesSpecial: string[] = [];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McConsumerActionGENERATED.STATUS_CD_PENDING, McGod.t('cc.common.view.pending'));
    list.add(McConsumerActionGENERATED.STATUS_CD_DONE, McGod.t('cc.common.view.done'));
    list.add(McConsumerActionGENERATED.STATUS_CD_ERROR, McGod.t('cc.common.view.error'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McConsumerActionGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McConsumerActionGENERATED.TYPE_CD_DUNNING, McGod.t('cc.consumers.action.type.dunning'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = McConsumerActionGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McConsumerAction {
    console.error('MTCN-ERROR: Not Implemented: McConsumerAction::createNewInstance(). Add this method to final class and return new McConsumerAction();');
    throw new Error('Cannot McConsumerAction::createNewInstance(). Add this method to final class and return new McConsumerAction();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcConsumerAction): McConsumerAction {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McConsumerActionService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McConsumerAction AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSentDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sentDatetime);
    }

    public getSentDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sentDatetime);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return McConsumerActionGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdPENDING(): boolean {
    const result = (this.statusCd === McConsumerActionGENERATED.STATUS_CD_PENDING);

    return result;
  }

 public  isStatusCdDONE(): boolean {
    const result = (this.statusCd === McConsumerActionGENERATED.STATUS_CD_DONE);

    return result;
  }

 public  isStatusCdERROR(): boolean {
    const result = (this.statusCd === McConsumerActionGENERATED.STATUS_CD_ERROR);

    return result;
  }


  public getTypeCdLabel(): string {
    return McConsumerActionGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdDUNNING(): boolean {
    const result = (this.typeCd === McConsumerActionGENERATED.TYPE_CD_DUNNING);

    return result;
  }


  
}
