import {Component, Inject, OnInit} from '@angular/core';
import {McConsumer} from '@miticon-ui/mc-core';
import {ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mc-consumer-consumer-profile-page',
  templateUrl: './mc-consumer-consumer-profile-page.component.html',
})
export class McConsumerConsumerProfilePageComponent implements OnInit {

  mcConsumerId!: number;
  mcConsumer!: McConsumer;

  constructor(
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      // @ts-ignore
      this.mcConsumerId = +params.get('id');
      this.mcConsumer = new McConsumer();
      this.actLoad();
    });
  }

  public actLoad(): void {

    this.mcConsumer.loadById(this.mcConsumerId);
}
}
