<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcProcess.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <div class="content-header-title" *ngIf="mcProcess.exists()">{{"cc.processes.edit-process" | translate}} {{mcProcess.title}}</div>
    <div class="content-header-title" *ngIf="!mcProcess.exists()">{{"cc.processes.create-process" | translate}}</div>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!mcProcess.apiLoadingFlg" class="content-body">
    <mcc-message *ngIf="mcProcess.apiErrorMessage" [errorMsg]="mcProcess.apiErrorMessage"></mcc-message>
    <mcc-message *ngIf="mcProcess.apiErrorMessage && errorMsgs" [errorMsgs]="errorMsgs" label="Unable to proceed: "></mcc-message>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container>
      <div class="content-body">
        <h2>{{ "cc.common.general-info" | translate }}</h2>
        <div class="content-section">
          <mcc-fi-radio-buttons label="{{'cc.common.view.status' | translate}}"
                                [name]="'ctrlStatus'"
                                [directionHorizontal]="true"
                                [mcForm]="mcForm"
                                [value]="mcProcess.exists() ? mcProcess.statusCd : 'INACTIVE'"
                                [extraCssClass]="!mcProcess.exists() ? 'mc-disable' : ''"
                                [validRequiredFlg]="true"
                                [optionsVlm]="statusVlm"></mcc-fi-radio-buttons>
        </div>
        <div class="content-section">
          <div [ngClass]="{'mc-disable' : mcProcess.exists()}">
            <mcc-fi-radio-buttons label="{{'cc.processes.process-type' | translate}}"
                                  [name]="'ctrlProcessType'"
                                  [directionHorizontal]="true"
                                  [mcForm]="mcForm"
                                  [value]="mcProcess.typeCd"
                                  [validRequiredFlg]="true"
                                  [optionsVlm]="processTypeVlm"></mcc-fi-radio-buttons>
          </div>
        </div>
        <div class="content-section">
          <div [ngClass]="{'mc-disable' : mcProcess.statusCd === mcProcessStatusActive}">
            <mcc-fi-textbox label="{{'cc.common.name' | translate}}" name="ctrlName" [value]="mcProcess.title"
                            [newStyleInput]="true" [mcForm]="mcForm" [validRequiredFlg]="true" [validWhitespaces]="true"></mcc-fi-textbox>
          </div>
        </div>
        <div class="content-section description">
          <div [ngClass]="{'mc-disable' : mcProcess.statusCd === mcProcessStatusActive}">
            <mcc-fi-textbox name="ctrlDescription" label="{{'cc.common.view.description' | translate}}"
                            [value]="mcProcess.description" [validWhitespaces]="true"
                            [mcForm]="mcForm" [newStyleInput]="true">
            </mcc-fi-textbox>
          </div>
        </div>

        <h2>{{ "cc.common.additional-settings" | translate }}</h2>
        <div class="content-section">
          <div class="form-inner-content" [ngClass]="{'mc-disable' : mcProcess.statusCd === mcProcessStatusActive}">
            <mcc-fi-radio-buttons2 label="{{'cc.common.edit.inheritable' | translate}}"
                                   [name]="'ctrlInheritable'"
                                   [directionHorizontal]="true"
                                   [mcForm]="mcForm"
                                   [value]="mcProcess.inheritableFlg"
                                   [validRequiredFlg]="true"
                                   [optionsVll]="inheritableVll"></mcc-fi-radio-buttons2>
          </div>
        </div>
      </div>
    </ng-container>

  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-footer">
    <!-- <button type="button" class="btn btn-secondary" (click)="actCancel()">Delete</button>-->
    <button mat-button class="secondary-btn move-right" (click)="actCancel()">{{ 'cc.common.edit.cancel' | translate }}</button>
    <button mat-button class="primary-btn" [disabled]="saveButtonDisabledMcb.value" (click)="actSave()" type="submit">{{ (mcProcess.exists() ? 'cc.common.view.save' : 'cc.common.create') | translate }}</button>
  </div>
</div>
<router-outlet></router-outlet>
