import {EbFactoring} from "./eb-factoring.model";
import {EbFactoringListGENERATED} from "../_generated/eb-factoring-list-generated.model";

export class EbFactoringList extends EbFactoringListGENERATED {
  isActiveContract!: boolean;

  // constructor(apiService: any) {
  //   super(apiService);
  // }

  public static override createNewInstance() {
    return new EbFactoringList();
  }

  getActiveItem(): EbFactoring {
    let activeItem = new EbFactoring();
    console.log("000000000", this.items);
    this.items.forEach(item => {
      // TODO what to do if two items end up active at the same time?
      if (item.isActive()) {
        activeItem = item;
      }
    });
    return activeItem;
  }


  getPendingList(): EbFactoringList {
    const pendingList = new EbFactoringList();
    this.items.forEach(item => {
      if (item.statusCd === EbFactoring.STATUS_CD_PENDING_ACCEPTANCE) {
        pendingList.addItem(item);
      }
    });
    return pendingList;
  }
}
