/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
/* Using "any" data-type in returned observables to avoid importing   */
/* ItemClass and ItemListClass and circular dependencies.             */
/* ------------------------------------------------------------------ */
import {BaseObjectService} from '../_core/base-object-service';
import {IImDataImportItem} from '../_generated/im-data-import-item.interface';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';
// import {ImDataImportItem} from '../models/im-data-import-item.model';
// import {ImDataImportItemList} from '../models/im-data-import-item-list.model';
// import {ImDataImportItemFilter} from '../models/im-data-import-item-filter.model';
import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImDataImportItemServiceGENERATED extends BaseObjectService {
protected apiServiceUrlSubPath = "/imdataimport/v4/dataimportitem";

public getApiServiceRootUrl(): string {
    return this.apiUrl + this.apiServiceUrlSubPath;
  }

  /* Get all  from DB */
public getAll(aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/all?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  /* Get all  from DB without pager */
public getByFilter(aFilter: any, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/filter?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, aFilter.toDto(), {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

/* Get by id from DB */
public getById(id: number): Observable<IImDataImportItem> {
  return this.httpClient.get<IImDataImportItem>(this.getApiServiceRootUrl() + `/${id}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

// Insert Object
public insert(aImDataImportItem: any): Observable<IImDataImportItem> {
    return this.httpClient.post<IImDataImportItem>(this.getApiServiceRootUrl()  , aImDataImportItem.toDto(), {headers: this.httpOptions});
}
// Update object
public update(aImDataImportItem: any): Observable<IImDataImportItem> {
    return this.httpClient.put<IImDataImportItem>(this.getApiServiceRootUrl() + `/`+ aImDataImportItem.id, aImDataImportItem.toDto(), {headers: this.httpOptions});
}


// DeleteAllFromDB
public deleteAllFromDb() {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/deleteAllFromDb`, {headers: this.httpOptions});
  }

  /* Get by id from DB */
public deleteById(id: number) {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/${id}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });

  }

  // -- CUSTOM METHODS --
  
}
