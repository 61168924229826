import { Component, OnInit } from "@angular/core";
import { McConsumersImportsTableConfig } from "./mc-consumers-imports-table-config";
import {
  McUtilityService,
  McStateManagementService,
  McConsumerImportList,
  McConsumerImportService,
} from "@miticon-ui/mc-core";

@Component({
  selector: "lib-mc-consumers-import",
  templateUrl: "./mc-consumers-import.component.html",
})
export class McConsumersImportComponent implements OnInit {
  tableContent: any;
  showLoader!: boolean;
  responseMessageShow!: boolean;
  notifyMsgText!: string;
  notifyMsgType!: string;
  entityId!: number;
  tableConfig!: McConsumersImportsTableConfig;

  consumerImport!: McConsumerImportList;

  constructor(
    private mcConsumerImportService: McConsumerImportService,
    private utilityService: McUtilityService,
    private stateManagementService: McStateManagementService
  ) {}

  ngOnInit() {
    this.tableConfig = new McConsumersImportsTableConfig();
    this.entityId = +this.utilityService.getPropertyFromToken("entity_id");
    this.consumerImport = new McConsumerImportList();
    this.consumerImport.loadAll(() => {
      this.consumerImport.items.sort((a, b) => {
        /*TODO: add this functionality to table - receive flag to sort by special property in itemObject (be careful about property type and
                    way of sorting)*/
        return b.id - a.id;
      });
    });
  }

  onFilterProceed() {
    console.log("filter");

    // this.consumerImport = event;
  }
}
