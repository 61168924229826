import {ImDataSetFilterGENERATED} from '../_generated/im-data-set-filter-generated.model';

export class ImDataSetFilter extends ImDataSetFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataSetFilter();
  }

  // ---------------------------------------------------------------------
}
