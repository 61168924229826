
import {Table3Config} from '../extra/table-3-config';

// import {McCurrencyGENERATED} from '../_generated/mc-currency-generated.model';

export class McCurrencyTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colCode', 'Code', 'code', 'code');
    this.addColumn('colLocalizationKey', 'Localization Key', 'localizationKey', 'localizationKey');
    this.addColumn('colName', 'Name', 'name', 'name');

*/
  }
}
