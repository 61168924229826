import {Table2Config} from '@miticon-ui/mc-core';
import {MccFiSelectOptionComponent} from '../mcc-fi-select-option/mcc-fi-select-option.component';

export class McCsvColumnsMappingItemTableConfigDefaultModel extends Table2Config {
  constructor() {
    super();

    this.addColumn('colName', 'CSV Columns Keys', 'columnName', false);

    // Title Column with Editor Dropdown

    this.addColumn('attributeName', 'Mapping Keys', 'attributeName', true).getBuilder()
      .setExtraCssClass('h6')
      .setEditComponent(MccFiSelectOptionComponent, 'attributeName');

    // Other Columns
    this.addColumn('sampleData', 'Sample Data', 'sampleData', false);
  }
}
