/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {PpPriceAdjustment} from '../models/pp-price-adjustment.model';
import {PpPriceAdjustmentService} from '../services/pp-price-adjustment.service';
import {IPpPriceAdjustment} from '../_generated/pp-price-adjustment.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class PpPriceAdjustmentGENERATED extends BaseObject {


    public static readonly TYPE_CD_DISCOUNT: string = 'DISCOUNT';
  public static readonly TYPE_CD_LIST__ALL = [
    PpPriceAdjustmentGENERATED.TYPE_CD_DISCOUNT
];

    public static readonly VALUE_TYPE_CD_PERCENTAGE: string = 'PERCENTAGE';
    public static readonly VALUE_TYPE_CD_ABSOLUTE_VALUE: string = 'ABSOLUTE_VALUE';
  public static readonly VALUE_TYPE_CD_LIST__ALL = [
    PpPriceAdjustmentGENERATED.VALUE_TYPE_CD_PERCENTAGE,
    PpPriceAdjustmentGENERATED.VALUE_TYPE_CD_ABSOLUTE_VALUE
];

  public apiService: PpPriceAdjustmentService;
  public _rawJson: IPpPriceAdjustment;
    id: number = 0;
  description = '';
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  sysUpdatedUserId: number;
  title = '';
  typeCd: string;
  value: number;
  valueTypeCd: string;
  version: number;


  public properties: string[] = ['id', 'description', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'title', 'typeCd', 'value', 'valueTypeCd', 'version'];
  public propertiesRegular: string[] = ['id', 'description', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'title', 'typeCd', 'value', 'valueTypeCd', 'version'];
  public propertiesSpecial: string[] = [];



  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PpPriceAdjustmentGENERATED.TYPE_CD_DISCOUNT, McGod.t('Discount'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = PpPriceAdjustmentGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getValueTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PpPriceAdjustmentGENERATED.VALUE_TYPE_CD_PERCENTAGE, McGod.t('Percentage'));
    list.add(PpPriceAdjustmentGENERATED.VALUE_TYPE_CD_ABSOLUTE_VALUE, McGod.t('Absolute value'));


    return list;
  }

  public static getValueTypeCdLabel(aCd: string): string {
    const list = PpPriceAdjustmentGENERATED.getValueTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): PpPriceAdjustment {
    console.error('MTCN-ERROR: Not Implemented: PpPriceAdjustment::createNewInstance(). Add this method to final class and return new PpPriceAdjustment();');
    throw new Error('Cannot PpPriceAdjustment::createNewInstance(). Add this method to final class and return new PpPriceAdjustment();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IPpPriceAdjustment): PpPriceAdjustment {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(PpPriceAdjustmentService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE PpPriceAdjustment AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getValueStr(): string {
       return McGod.formatNumberToMoney(this.value);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getTypeCdLabel(): string {
    return PpPriceAdjustmentGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdDISCOUNT(): boolean {
    const result = (this.typeCd === PpPriceAdjustmentGENERATED.TYPE_CD_DISCOUNT);

    return result;
  }


  public getValueTypeCdLabel(): string {
    return PpPriceAdjustmentGENERATED.getValueTypeCdLabel(this.valueTypeCd);
  }


 public  isValueTypeCdPERCENTAGE(): boolean {
    const result = (this.valueTypeCd === PpPriceAdjustmentGENERATED.VALUE_TYPE_CD_PERCENTAGE);

    return result;
  }

 public  isValueTypeCdABSOLUTE_VALUE(): boolean {
    const result = (this.valueTypeCd === PpPriceAdjustmentGENERATED.VALUE_TYPE_CD_ABSOLUTE_VALUE);

    return result;
  }


  
}
