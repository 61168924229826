import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {
  EbFactoringRequestTypeList,
  McBoolean,
  McConsumerList, McConsumerTable3Config,
  McDateUtils,
  McForm,
  McGod,
  McValidation,
  McValueLabelList
} from "@miticon-ui/mc-core";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'mc-dev-test-fi-page',
  templateUrl: './mc-dev-test-fi-page.component.html',
})

export class McDevTestFiPageComponent implements OnInit {

  @ViewChild("emailBodytokened")
  emailBodytokened!: ElementRef;

  mcConsumerList = new McConsumerList();
  mcConsumerTable3Config = new McConsumerTable3Config();
  factoringRequestTypeList = new EbFactoringRequestTypeList();
  list!: McValueLabelList;
  togglePopupMcFLg = new McBoolean();
  optionsVllJob!: McValueLabelList;
  radioButtonsVlm!: Map<string, string>;
  checkBoxesVlm!: Map<string, string>;
  minDate!: number;
  maxDate!: number;
  textboxValidationFlg = true;
  diffTypesVll = new McValueLabelList();
  emailTokenVll = new McValueLabelList();
  emailTokenVllTEST = new McValueLabelList();
  successMsg = '';
  errorMsg = '';
  mcGod = McGod.getInstance();
  mcForm!: McForm;
  fg!: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.fg = this.fb.group({
      textarea: ['']
    });

    this.emailTokenVll.add('<Consumer.FirstName>', 'Consumer First Name');
    this.emailTokenVllTEST.add('<Consumer.FirstName>', 'Consumer First Name');
    this.emailTokenVll.add('<Consumer.LastName>', 'Consumer Last Name');
    this.emailTokenVllTEST.add('<Consumer.LastName>', 'Consumer Last Name');
    this.emailTokenVll.add('<Entity.Name>', 'Entity Name');
    this.mcConsumerList.loadAll();
    this.factoringRequestTypeList.loadByChildEntityId(1);
    this.list = new McValueLabelList();
    this.list.add('frontend', 'Frontend Dev');
    this.list.add('backend', 'Backend Dev');
    this.list.add('devOps', 'Dev Ops');

    this.diffTypesVll.add('sss', 'sss');
    this.diffTypesVll.add(7, '7');
    this.diffTypesVll.add(true, 'True');

    if (!this.mcForm) {
      this.mcForm = new McForm();
      /*this.mcForm.getControl('jobRadio').valueChanges.subscribe( data => {
        console.log('', data);
      });*/
    }



    this.optionsVllJob = new McValueLabelList();
    this.optionsVllJob.add("frontend", "FrontEnd Dev");
    this.optionsVllJob.add("backend", "Backend Dev");
    this.optionsVllJob.add("devOps", "Dev Ops");

    this.radioButtonsVlm = new Map<string, string>();
    this.radioButtonsVlm.set("true", "True");
    this.radioButtonsVlm.set("false", "False");

    this.checkBoxesVlm = new Map<string, string>();
    this.checkBoxesVlm.set("true", "True");
    this.checkBoxesVlm.set("false", "False");

    const minDate = new Date(2020, 1 - 1, 10);
    this.minDate = McDateUtils.formatDateToTimestamp(minDate);
    const maxDate = new Date(2020, 1 - 1, 20);
    this.maxDate = McDateUtils.formatDateToTimestamp(maxDate);


    /* this.optionsVlmJob2 = new Map<string, string>();
    this.optionsVlmJob2.set("designer", "Designer");
    this.optionsVlmJob2.set("productOwner", "Product Owner");
    this.optionsVlmJob2.set("projectManager", "Project Manager");

    this.createValidationObjects();

    this.formGroup = this.fb.group({
    });

    console.log(this.formGroup);
    if (this.formGroup.get("checkboxes")) {
      this.formGroup.get("checkboxes").valueChanges.subscribe(data => {
        console.log(data, "@@@@@@@@@@@8858555@@@@");
      });
    }*/

  }

  createValidationObjects() {
    /*this.firstNameValidationObj = new McValidation();
    this.firstNameValidationObj.validRequiredFlg = true;
    this.firstNameValidationObj.validMinLength = 2;
    this.firstNameValidationObj.validMaxLength = 6;

    this.lastNameValidationObj = new McValidation();
    this.lastNameValidationObj.validRequiredFlg = true;
    this.lastNameValidationObj.validMinLength = 3;
    this.lastNameValidationObj.validMaxLength = 12;

    this.jobValidationObj = new McValidation();
    this.jobValidationObj.validRequiredFlg = true;

    this.checkboxesValidationObj = new McValidation();
    this.checkboxesValidationObj.validMaxLength = 2;
    this.checkboxesValidationObj.validMinLength = 1;
    this.checkboxesValidationObj.validRequiredFlg = true;*/

  }

  actSubmit() {

    if (this.mcForm.isValid()) {
      console.log('Form is valid');
    } else {
      console.log('Form is invalid');
    }

    /*this.validateNow = true;
    if (this.formGroup.valid) {
      // call BE
    }*/
  }

  changeMinDate() {
    /*this.validateNow = false;
    const minDate = new Date(2019, 12 - 1, 10);
    this.minDate = McDateUtils.formatDateToTimestamp(minDate);
    const maxDate = new Date(2019, 12 - 1, 20);
    this.maxDate = McDateUtils.formatDateToTimestamp(maxDate);*/
  }

  valueChanged($event: unknown) {
    console.log($event, "SSSSSSSSSSSSSS");
  }

  changedCheckBox($event: any) {
    console.log($event, "VALUE");
  }

  onRadioButtonsChanged(value: unknown) {
    console.log(value, '222222222');
  }

  onCheckboxChanged($event: unknown) {

  }

  isMcFormValid() {
    this.mcForm.isValid();
    console.log(this.mcForm, 'mcccccccccccccccccccccccc');
  }


  changeValidation() {
    this.textboxValidationFlg = !this.textboxValidationFlg;
  }

  insertEmailToken(token: string) {
    console.log('aa8888888888888', this.emailBodytokened);
    const indexOfIndicatorStart = this.emailBodytokened.nativeElement.selectionStart;
    const indexOfIndicatorEnd = this.emailBodytokened.nativeElement.selectionEnd;
    debugger
    let tempValueOfEmailBody: string;
    // tempValueOfEmailBody = this.mcForm.getValue('ctrlEmailToken');
    tempValueOfEmailBody = this.fg.get('textarea')?.value;
    // tempValueOfEmailBody = tempValueOfEmailBody.concat(token);
    tempValueOfEmailBody = tempValueOfEmailBody.substr(0, indexOfIndicatorStart) + token + tempValueOfEmailBody.substr(indexOfIndicatorEnd);
    // this.mcForm.getControl('ctrlEmailToken').get('value').setValue(tempValueOfEmailBody);
    this.fg.get('textarea')?.setValue(tempValueOfEmailBody);
    console.log('CONTROL 00000000', this.mcForm.getControl("ctrlEmailToken"));
  }

  webFileUploaded() {  // uploadedEmitObject: any TODO: Define object if this is the right way
    // if (uploadedEmitObject.successFlg) {


      this.errorMsg = '';
      this.successMsg = 'WebFile uploaded: '; // + uploadedEmitObject.webFile.filename;
    // } else {
    //   this.successMsg = '';
    //   this.errorMsg = 'Uploading file failed';
    // }
  }
}
