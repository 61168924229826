/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McBillingBatchAssignedToStatementFilter} from '../models/mc-billing-batch-assigned-to-statement-filter.model';

export class McBillingBatchAssignedToStatementFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingBatchAssignedToStatementFilter {
    console.error('MTCN-ERROR: Not Implemented: McBillingBatchAssignedToStatementFilter::createNewInstance(). Add this method to final class and return new McBillingBatchAssignedToStatementFilter();');
      throw new Error('Cannot McBillingBatchAssignedToStatementFilter::createNewInstance(). Add this method to final class and return new McBillingBatchAssignedToStatementFilter();');
  }

}
