import {Inject, Injectable} from '@angular/core';
import {CsrPaymentDetailsServiceGENERATED} from '../_generated/csr-payment-details-generated.service';



@Injectable({
  providedIn: 'root'
})
export class CsrPaymentDetailsService extends CsrPaymentDetailsServiceGENERATED {

}
