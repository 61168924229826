import {
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseDirective, LoginEnums, McGod} from '@miticon-ui/mc-core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {McAuthService, McProfileUserService} from '@miticon-ui/mc-core';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'mc-change-password-modal',
  templateUrl: './mc-change-password-modal.component.html',
})
export class McChangePasswordModalComponent extends BaseDirective implements OnInit {

  modalReference!: any;
  welcomeImg!: string;
  logo: string;
  message!: string;
  type!: string;
  counter!: number;
  canValidate!: boolean;
  ifMinChars!: boolean;
  checkDiffChars!: boolean;
  checkDigits!: boolean;
  checkSpecialChars!: boolean;
  checkLowercaseLetters!: boolean;
  checkUppercaseLetters!: boolean;
  minChars!: number;
  noOfDiffChar!: number;
  noOfDigits!: number;
  noOfSpecialChars!: number;
  noOfLowercaseChars!: number;
  noOfUppercaseChars!: number;
  seePassword = false;
  seeCurrentPassword = false;
  seeRepeatedPassword = false;
  ifPasswordValid!: boolean;
  responseMessage = false;
  inputFieldsError!: boolean;
  inputRepeatPasswordFieldError!: boolean;

  changePassForm: FormGroup;

  @Input() changePassForUser = false;
  @Input() userId!: number;

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private authService: McAuthService,
    private profileUserService: McProfileUserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject('designConfig') designConfig: any
  ) {
    super();
    this.changePassForm = new FormGroup({});
    this.logo = designConfig.logo;
  }

  ngOnInit() {
    this.changePassForm = this.fb.group({
      currentPassword: [''],
      newPassword: [''],
      repeatPassword: ['']
    });

    this.passwordPolicyData();

    this.changePassForm.valueChanges.subscribe(() => {
      this.validatePassword(1 && 2);
    });

  }


  openModal(content: any) {
    this.canValidate = false;
    this.seeRepeatedPassword = false;
    this.seePassword = false;
    this.seeCurrentPassword = false;
    /*Modal reference - used for close it*/
    this.modalReference = this.modalService.open(content, {
      centered: true,
      // size: 'lg',
      windowClass: 'mc-change-password-modal',
      backdrop: 'static',
      beforeDismiss: () => {
        this.canValidate = true;
        this.resetForm();
        this.setNotificationMessage('', '');
        return true;
      }
    });
  }


  // Dynamic password policy
  passwordPolicyData() {
    const userId = this.authService.getLoggedUserInfo().user_id;
    this.profileUserService.getPasswordPolicyData(userId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
      (response) => {
        const policyData = response;
        this.minChars = policyData.minPassLength;
        this.noOfDiffChar = policyData.minDiffChars;
        this.noOfDigits = policyData.minDigits;
        this.noOfSpecialChars = policyData.minSpecialChars;
        this.noOfLowercaseChars = policyData.minLowercaseLetters;
        this.noOfUppercaseChars = policyData.minUppercaseLetter;
      },
      (error) => {
        if (error.status >= 500) {
          this.setNotificationMessage('Server is not available at the moment, please try again later.', 'error');
          return;
        }
        this.responseMessage = true;
        this.message = `User not found`;
        this.type = error.ok === false ? 'error' : 'success';
      }
    );
  }

  // See password on eye-button click
  onChangeSeePassword(seePassData: boolean, type: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (type === 1) ? this.seePassword = seePassData : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (type === 2) ? this.seeRepeatedPassword = seePassData : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (type === 3) ? this.seeCurrentPassword = seePassData : null;
  }

  // Check password fields
  validatePassword(type: number) {
    if (this.canValidate) {
      return;
    }
    this.responseMessage = false;
    this.inputFieldsError = false;
    const formData = this.changePassForm.getRawValue();
    const passwordEntered = formData.newPassword;
    const formatSpecialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    // Check if there is a minimum of characters
    this.ifMinChars = passwordEntered.length > this.minChars - 1;

    /* Check for minimum different characters*/
    this.counter = 0;
    for (const character of passwordEntered) {
      if (passwordEntered.indexOf(character) > 0) {
        this.counter++;
      }
    }
    this.checkDiffChars = this.counter >= this.noOfDiffChar;

    // Check the number of digits
    this.checkDigits = /\D/.test(passwordEntered) &&
      passwordEntered.length - passwordEntered.replace(/\d/g, '').length > this.noOfDigits - 1;

    // Check the number of special characters
    this.checkSpecialChars = formatSpecialChars.test(passwordEntered) &&
      passwordEntered.length - passwordEntered.replace(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '').length > this.noOfSpecialChars - 1;

    // Check for lowercase letters
    this.checkLowercaseLetters = /[a-z]/.test(passwordEntered) &&
      passwordEntered.length - passwordEntered.replace(/[a-z]/g, '').length > this.noOfLowercaseChars - 1;


    // Check for uppercase letters
    this.checkUppercaseLetters = /[A-Z]/.test(passwordEntered) &&
      passwordEntered.length - passwordEntered.replace(/[A-Z]/g, '').length > this.noOfUppercaseChars - 1;
    // Check if password is valid and enable form submit
    this.ifPasswordValid = this.ifMinChars
      && this.checkDiffChars
      && this.checkDigits
      && this.checkSpecialChars
      && this.checkLowercaseLetters
      && this.checkUppercaseLetters
      && formData.newPassword === formData.repeatPassword;
    if (type === 2) {
      let msgText: string;
      let msgType: string;
      if (formData.newPassword !== formData.repeatPassword) {
        msgText = McGod.t('cc.user.passwords-do-not-match');
        msgType = 'error';
        this.setNotificationMessage(msgText, msgType);
        this.inputRepeatPasswordFieldError = true;
      } else if (formData.currentPassword === formData.newPassword) {
        msgText = 'Your password must be different than last 8 passwords.';
        msgType = 'error';
        this.setNotificationMessage(msgText, msgType);
      } else {
        this.inputRepeatPasswordFieldError = false;
      }

    }
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  // Change password and go to login page - for enter and generate password
  resetPassword() {
    let msgText: string;
    let msgType: string;
    const formData = this.changePassForm.getRawValue();
    if (this.changePassForUser) {
      this.profileUserService.changeUserPassword({
        newPassword: formData.newPassword,
        confirmPassword: formData.repeatPassword,
      }, this.userId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.modalService.dismissAll('Cross click');
          this.changePassForm.reset();
        }, (error) => {
          if (error.status >= 500) {
            msgText = 'Server is not available at the moment, please try again later.';
            msgType = 'error';
            this.setNotificationMessage(msgText, msgType);
            return;
          } else if (error.status === 400) {
            msgText = error.error.message;
            msgType = error.ok === false ? LoginEnums.errorMsg : LoginEnums.successMsg;
            this.setNotificationMessage(msgText, msgType);
            this.responseMessage = true;
            this.inputFieldsError = true;
          } else {
            this.responseMessage = false;
            this.inputFieldsError = false;
          }
        });
    } else {
      this.profileUserService.changePassword({
        newPassword: formData.newPassword,
        confirmPassword: formData.repeatPassword,
        oldPassword: formData.currentPassword
      })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          () => {
            // msgText = LoginEnums.successMsg;
            // msgType = LoginEnums.successMsg;
            this.modalService.dismissAll('Cross click');
            this.authService.logout();
            this.changePassForm.reset();
          },
          (error) => {
            if (error.status >= 500) {
              msgText = 'Server is not available at the moment, please try again later.';
              msgType = 'error';
              this.setNotificationMessage(msgText, msgType);
              return;
            } else if (error.status === 400) {
              msgText = error.error.message;
              msgType = error.ok === false ? LoginEnums.errorMsg : LoginEnums.successMsg;
              this.setNotificationMessage(msgText, msgType);
              this.responseMessage = true;
              this.inputFieldsError = true;
            } else {
              this.responseMessage = false;
              this.inputFieldsError = false;
            }

          }
        );
    }
  }

  resetForm() {
    this.changePassForm.reset();
    this.inputRepeatPasswordFieldError = false;
    this.inputFieldsError = false;
    this.ifMinChars = false;
    this.checkDiffChars = false;
    this.checkDigits = false;
    this.checkSpecialChars = false;
    this.checkLowercaseLetters = false;
    this.checkUppercaseLetters = false;
    this.setNotificationMessage('', '');
  }
}


