/*BbmTestObj*/
import {McPriceListTable3ConfigGENERATED} from '../_generated/mc-price-list-table-3-config-generated.model';


export class McPricelistPricelistCreatedFromTemplateTable3ConfigModel extends McPriceListTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.clearColumns();
    this.addColumn('colPricelistName', 'Pricelist name', 'title', '');


  }
}
