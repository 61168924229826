/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {CmnEmail} from '../models/cmn-email.model';
import {CmnEmailList} from '../models/cmn-email-list.model';
import {CmnEmailFilter} from '../models/cmn-email-filter.model';
import {CmnEmailService} from '../services/cmn-email.service';
import {ICmnEmail} from '../_generated/cmn-email.interface';
import {McGod} from "../extra/mc-god.service";

export class CmnEmailListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'accountId', 'bccEmails', 'body', 'ccEmails', 'log', 'retryCount', 'sentDatetime', 'statusCd', 'subject', 'sysCreatedDatetime', 'sysCreatedUserId', 'toEmails', 'attachmentWebFiles'];
  public items: CmnEmail[] = [];
  public apiService: CmnEmailService;
 // protected tempObjList: CmnEmailList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CmnEmailList {
    console.error('MTCN-ERROR: Not Implemented: CmnEmailList::createNewInstance(). Add this method to final class and return new CmnEmailList();');
    throw new Error('Cannot CmnEmailList::createNewInstance(). Add this method to final class and return new CmnEmailList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: ICmnEmail[]): CmnEmailList {
    const  listObj: CmnEmailList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: CmnEmail = CmnEmail.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): CmnEmailList {
    const  listObj: CmnEmailList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CmnEmailService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: ICmnEmail[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: CmnEmail = CmnEmail.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aCmnEmailFilter: CmnEmailFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aCmnEmailFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): CmnEmail {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new CmnEmail();
    }
  }

  public replaceItem(newItem: CmnEmail) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

