import {MccFiSelectOptionComponent} from './mcc-fi-select-option.component';
import {EventEmitter} from '@angular/core';

export class MccFiSelectOptionConfig {
  label: string | undefined;
  selectedValue: string | undefined;
  name: string | undefined;
  optionsKvm: Map<string, string> | undefined;
  eventSelectionChangeCallback: any;
  extraCssClass!: string;

  constructor(optionsKvm?: Map<string, string>, label?: string, selectedValue?: string, name?: string) {
    this.label = label;
    this.selectedValue = selectedValue;
    this.name = name;
    this.optionsKvm = optionsKvm;
  }

  public applyToComponent(aSelectComponent: MccFiSelectOptionComponent) {
    Object.keys(this).forEach(keyName => {
      // @ts-ignore
      aSelectComponent[keyName] = this[keyName];
    });



    aSelectComponent.eventSelectionChange.subscribe( event => {
      if (this.eventSelectionChangeCallback != null) {
        this.eventSelectionChangeCallback(event);
      }
    });
  }

}
