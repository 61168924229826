/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EbSepaImportTable3ConfigGENERATED} from '../_generated/eb-sepa-import-table-3-config-generated.model';

// import {EbSepaImportGENERATED} from '../_generated/eb-sepa-import-generated.model';

export class EbSepaImportTable3Config extends EbSepaImportTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();
    this.addColumn('colActions', '', 'fldMyFactoringSepaActions()', '');
    this.addColumn('sepaFilename', this.t('cc.factoring.imported-sepa.sepa-filename'), 'sepaFilename', 'wf.originalFilename');
    this.addColumn('createdAt', this.t('cc.factoring.imported-sepa.import-date-&-time'), 'getImportDateStr()', 'createdAt')
      .getBuilder();
    this.addColumn('requestCollectionDate', 'Request collection date', 'getRequestedCollectionDate()', '');
    this.addColumn('allDebtors', this.t('cc.factoring.imported-sepa.all-debtors'), 'allDebtors', '');
    this.addColumn('processedNowCnt', this.t('Processed now'), 'processedNowCnt', '');
    this.addColumn('processedEarlierCnt', this.t('Processed earlier'), 'getProcessedEarlier()', '');
    this.addColumn('accRejDebtors', this.t('cc.factoring.imported-sepa.accepted-rejected-debtors'), 'fldAcceptedRejectedDebtors()', '');
    this.addColumn('overwrittenCnt', this.t('Overwritten'), 'getOverwritten()', '');
    this.addColumn('totalAmountDue', this.t('Total amount'), 'totalAmountStr', '').extraCssClass = 'text-right';
    this.addColumn('accRejAmountDue', this.t('Accepted/rejected amount'), 'fldAcceptedRejectedAmountDue()', '').extraCssClass = 'text-right';
    this.addColumn('overwrittenAmount', this.t('Overwritten amount'), 'getOverwrittenAmount()', '').extraCssClass = 'text-right';
  }
}
