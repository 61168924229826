
import {Table3Config} from '../extra/table-3-config';

// import {EbFactoringRequestTypeGENERATED} from '../_generated/eb-factoring-request-type-generated.model';

export class EbFactoringRequestTypeTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colIdChildMcEntity', 'Child entity ID', 'idChildMcEntity', 'idChildMcEntity');
    this.addColumn('colTitle', 'Title', 'title', 'title');

*/
  }
}
