import {McMenuItemInterface} from '../models/mc-menu-item-interface';

export class McMenuItem {
  name!: string;
  translationKey!: string;
  icon!: string;
  route!: string;
  permission!: any[];
  submenu!: McMenuItem[];
  isExpanded = false;
  hasActiveChild = false;
  isDisplayed = false;

  public isActive = false;

  constructor(aObject: McMenuItemInterface) {
    /*TODO: why update? because we will create menuItem with icon only without name or translation key, so you have interface for object
        argument and you can see code suggestion for it when start typing - see example in sidebar.component 55-60 code lines*/
    this.update(aObject);
  }

  /*Update class*/
  public update(aObject: McMenuItemInterface) {
    // @ts-ignore
    Object.keys(aObject).forEach(key => this[key] = aObject[key]);

    if (!this.submenu)
      this.submenu = [];

    return this;
  }

  public addMenuItem(aName: string, aTranslationKey: string, aIcon: string, aPermission: any[], aRoute: string): McMenuItem {
    const newMenuItem = {
      name: aName,
      translationKey: aTranslationKey,
      icon: aIcon,
      route: aRoute,
      permission: aPermission
    };
    const menuItem = new McMenuItem(newMenuItem);
    this.submenu.push(menuItem);
    return menuItem;
  }

}
