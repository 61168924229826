/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McPriceListTable3ConfigGENERATED} from '../_generated/mc-price-list-table-3-config-generated.model';

// import {McPriceListGENERATED} from '../_generated/mc-price-list-generated.model';

export class McPriceListTemplateTable3ConfigModel extends McPriceListTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colTitle', this.t('cc.pricelist.template-name'), 'title', 'title');
    this.addColumn('colDescription', this.t('cc.pricelist.template-description'), 'description', 'description');
    this.addColumn('colNoOfPricelists', this.t('cc.pricelist.no-of-pricelists-created'), 'fldNumberOfCreatedPricelists()', '');
    this.addColumn('colNoOfEnt', this.t('cc.pricelist.no-of-entities'), 'fldNumberOfEntities()', '');
    this.addColumn('colCreatedDateTime', this.t('cc.common.created-date-and-time'), 'getSysCreatedDatetimeString()', '');
    this.addColumn('colCreatedBy', this.t('cc.pricelist.created-by'), 'createdByUserFullName', '');


  }
}
