<!--Change permissions-->
<div class="mc-popup-button mc-border-left-right">
  <i class="fas fa-unlock-alt"></i>
  <p>Change permissions</p>
</div>

<!--Change status-->
<div class="mc-popup-button mc-border-right">
  <i class="fas fa-sync-alt"></i>
  <p>Change Status</p>
</div>

<!--Menage role users-->
<div class="mc-popup-button mc-border-right">
  <i class="fas fa-user"></i>
  <p>Menage Role Users</p>
</div>
