<lib-mc-loader [showLoader]="mcProcessFlow.apiLoadingFlg || mcProcess.apiLoadingFlg"></lib-mc-loader>

<!--<div class="mc-go-back-to-all" (click)="goBack()">-->
<!--  < {{('cc.common.back-to-all' | translate)}}-->
<!--</div>-->

<div class="breadcrumbs"><span (click)="actGoToProcesses()">{{'cc.processes.processes' | translate}}</span> > <span
  (click)="actGoToProcessProfile()">{{mcProcess.fldName()}}</span> > <span
  class="current-position">{{mcProcessFlow.fldName()}}</span></div>


<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcProcessFlow.apiLoadingFlg"></lib-mc-loader>

  <div>
    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-header">
      <h2>{{mcProcess.title}} {{'cc.processes.process' | translate}}: {{mcProcessFlow.title}} {{'cc.common.profile' | translate}}</h2>
    </div>

    <div class="col-md-12">
      <mcc-message [successMsg]="successMsg" [errorMsg]="flowLevelsObjectList.apiErrorMessage"></mcc-message>
      <mcc-message [errorMsg]="mcProcessFlow.apiErrorMessage"></mcc-message>
    </div>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-body">

      <div class="info-summary-header-lite">
        <h4>{{'cc.common.general-info' | translate}} <i *ngIf="mcGod.userHasMcProcessFlowEditPermission()" (click)="actShowGeneralFlowInfoEdit()"
                            class="fas fa-pen" [ngClass]="{'cursor-not-allowed' : mcProcess.statusCd === mcProcessStatusActive}"
                            [ngbTooltip]="mcProcess.statusCd === mcProcessStatusActive ? ('cc.processes.process-is-active-cant-edit' | translate) : ''"
                            placement="bottom-left"></i></h4>
      </div>
      <div class="info-summary-top-lite inner-content">
        <div class="row">
          <div class="col-md-6">
            <mcc-row-title-description title="{{'cc.common.name' | translate}}:" [description]="mcProcessFlow.title"></mcc-row-title-description>
            <mcc-row-title-description title="{{'cc.common.view.description' | translate}}:"
                                       [description]="mcProcessFlow.description"></mcc-row-title-description>
          </div>

          <div class="col-md-6">
            <mcc-row-title-description title="{{'cc.common.view.status' | translate}}:"
                                       [descriptionInnerHtml]="mcProcessFlow.fldStatus()"></mcc-row-title-description>
            <mcc-row-title-description title="{{'cc.common.day' | translate}}:"
                                       [descriptionInnerHtml]="recurringDetails.getWeekDaysVll().getLabel(mcProcessFlow.weekdayToSendNotification,'')"></mcc-row-title-description>
          </div>

        </div>
      </div>

      <!-- TRANSACTIONS ENTERING THE FLOW -->

      <div class="info-summary-header-lite">
        <h4>Transactions entering the flow <i *ngIf="mcGod.userHasMcProcessFlowEditPermission()" (click)="actShowTransactionsEnteringTheFlowEdit()"
                                                        class="fas fa-pen" [ngClass]="{'cursor-not-allowed' : mcProcess.statusCd === mcProcessStatusActive}"
                                                        [ngbTooltip]="mcProcess.statusCd === mcProcessStatusActive ? ('cc.processes.process-is-active-cant-edit' | translate) : ''"
                                                        placement="bottom-left"></i></h4>
      </div>
      <div class="info-summary-top-lite inner-content">
        <div class="row">
          <div class="col-md-6">
            <mcc-row-title-description title="Accepted:" [description]="fldBooleanToYesNo(mcProcessFlow.acceptedTransactionsEnteringFlowFlg)"></mcc-row-title-description>
            <mcc-row-title-description title="Returned:"
                                       [description]="fldBooleanToYesNo(mcProcessFlow.returnedTransactionsEnteringFlowFlg)"></mcc-row-title-description>
            <mcc-row-title-description title="For dunning:"
                                       [description]="fldBooleanToYesNo(mcProcessFlow.forDunningTransactionsEnteringFlowFlg)"></mcc-row-title-description>
          </div>

          <div class="col-md-6">
            <mcc-row-title-description title="Should go to Inkasso:"
                                       [descriptionInnerHtml]="fldBooleanToYesNo(mcProcessFlow.shouldGoToInkassoTransactionsEnteringFlowFlg)"></mcc-row-title-description>
            <mcc-row-title-description title="Sending to Inkasso:"
                                       [description]="fldBooleanToYesNo(mcProcessFlow.sendingToInkassoTransactionsEnteringFlowFlg)"></mcc-row-title-description>
          </div>

        </div>
      </div>


      <div class="info-summary-header-lite">
        <h4>{{'cc.processes.flow.criteria' | translate}} <i *ngIf="mcGod.userHasMcProcessFlowEditPermission()" (click)="actShowFlowCriteriaEdit()"
                        class="fas fa-pen" [ngClass]="{'cursor-not-allowed' : mcProcess.statusCd === mcProcessStatusActive}"
                        [ngbTooltip]="mcProcess.statusCd === mcProcessStatusActive ? ('cc.processes.process-is-active-cant-edit' | translate) : ''"
                        placement="bottom-left"></i></h4>
      </div>
      <div class="info-summary-top-lite inner-content">
        <div *ngIf="mcProcessFlow.apiSuccessFlg" class="d-flex flex-column">
          <div *ngFor="let criteria of mcProcessFlow.flowCriteriaList.items">
            <span class="criteria-tag-alt"
                  *ngIf="criteria.criteriaOperatorTypeCd !== ''">{{criteria.criteriaOperatorTypeCd}}</span>
            <span class="criteria-tag"> {{criteria.attributeName}}</span>
            <span class="criteria-tag-alt"> {{criteria.attributeOperatorTypeCd}}</span>
            <span class="criteria-tag"> {{criteria.value}}</span>
            <span class="criteria-tag"
                  *ngIf="criteria.attributeOptionTypeCd"> {{criteria.getAttributeOptionTypeCdLabel()}}</span>
          </div>
        </div>
      </div>

      <ng-container *ngIf="mcProcess.isTypeCdDUNNING()">
        <div class="info-summary-header-lite">
          <h4>{{'cc.contract.fees' | translate}} <i *ngIf="mcGod.userHasMcProcessFlowEditPermission()" (click)="actShowFeesEdit()"
                      class="fas fa-pen" [ngClass]="{'cursor-not-allowed' : mcProcess.statusCd === mcProcessStatusActive}"
                      [ngbTooltip]="mcProcess.statusCd === mcProcessStatusActive ? ('cc.processes.process-is-active-cant-edit' | translate) : ''"
                      placement="bottom-left"></i></h4>
        </div>
        <div class="info-summary-top-lite inner-content">
          <div class="row">
            <div class="col-md-6">
              <div class="section-sub-header">
                <h3>{{'cc.processes.interest-fee' | translate}}</h3>
              </div>
              <!-- TODO: need properties needed -->
              <div class="pl-3">
                <div class="position-relative" *ngIf="mcProcessFlow.isPersonInterestRateTypeCdDEFAULT()">
                  <mcc-row-title-description title="{{'cc.processes.person-interest-rate' | translate}}: "

                                             [description]="'Default (5% above the base rate of the German Central bank)'"
                                             extraCssClasses="mr-3"></mcc-row-title-description>
                  <div class="info-icon-positioning2">
                    <i class="mc-blue-icon fas fa-info-circle"
                       ngbTooltip="Current base interest rate of the German Central bank is -0,8%. "
                       placement="bottom-right" tooltipClass="mc-tooltip-length"></i>
                  </div>
                </div>
                <mcc-row-title-description *ngIf="mcProcessFlow.isPersonInterestRateTypeCdENTER()"
                                           title="{{'cc.processes.person-interest-rate' | translate}}: "
                                           [description]="mcProcessFlow.fldFlowFeesInterestRatePerson()"
                ></mcc-row-title-description>

                <div class="position-relative" *ngIf="mcProcessFlow.isCompanyInterestRateTypeCdDEFAULT()">

                  <mcc-row-title-description
                    title="{{'cc.processes.company-interest-rate' | translate}}: "
                     [description]="'Default (9% above the base rate of the German Central bank)'" extraCssClasses="mr-3"></mcc-row-title-description>

                  <div class="info-icon-positioning2">
                    <i class="mc-blue-icon fas fa-info-circle"
                       ngbTooltip="Current base interest rate of the German Central bank is -0,8%. "
                       placement="bottom-right" tooltipClass="mc-tooltip-length"></i></div>
                </div>

                <mcc-row-title-description *ngIf="mcProcessFlow.isCompanyInterestRateTypeCdENTER()"
                                           title="{{'cc.processes.company-interest-rate' | translate}}: "
                                           [description]="mcProcessFlow.fldFlowFeesInterestRateCompany()"
                                           ></mcc-row-title-description>
              </div>
            </div>
            <div class="col-md-6">
              <div class="section-sub-header">
                <h3>{{'cc.processes.dunning-fee' | translate}}</h3>
              </div>
              <div class="pl-3">
                <mcc-row-title-description title="{{'cc.processes.dunning-note-base-fee' | translate}}: "
                                           [description]="mcProcessFlow.dunningNoteBaseFeeAmount"
                                           [widerTitleFlg]="true"></mcc-row-title-description>
              <!--  <mcc-row-title-description title="{{'cc.processes.email-fee' | translate}}: " [description]="mcProcessFlow.getEmailFeeAmountStr()"
                                           [widerTitleFlg]="true"></mcc-row-title-description>
                <mcc-row-title-description title="{{'cc.processes.sms-fee' | translate}}: " [description]="mcProcessFlow.getSmsFeeAmountStr()"
                                           [widerTitleFlg]="true"></mcc-row-title-description>
                <mcc-row-title-description title="{{'cc.processes.post-fee' | translate}}: " [description]="mcProcessFlow.getPostFeeAmountStr()"
                                           [widerTitleFlg]="true"></mcc-row-title-description> -->
              </div>
            </div>
          </div>
        </div>
      </ng-container>


      <div class="info-summary-header-lite">
        <h4>{{'cc.common.additional-info' | translate}} <i *ngIf="mcGod.userHasMcProcessFlowEditPermission()" (click)="actShowAdditionalInfoEdit()"
                               class="fas fa-pen" [ngClass]="{'cursor-not-allowed' : mcProcess.statusCd === mcProcessStatusActive}"
                               [ngbTooltip]="mcProcess.statusCd === mcProcessStatusActive ? 'Process is active, can\'t edit' : ''"
                               placement="bottom-left"></i></h4>
      </div>

      <div class="info-summary-top-lite inner-content">
        <div class="row">
          <div class="col-md-8">

            <mcc-row-title-description title="After finishing the last dunning level of this flow, all transaction(s) will be in status"
                                       [description]="mcProcessFlow.getNewStatusForTransactionsAfterLastDunningLevelLabel()" [widerTitleFlg]="true"></mcc-row-title-description>

           <mcc-row-title-description title="{{'cc.processes.send-flow-to-inkasso-after-last-dunning-level' | translate}}:" [widerTitleFlg]="true"
                                      [description]="mcProcessFlow.sendFlowToInkassoFlg ? mcProcessFlow.getSendFlowToInkassoTransactionTypeCdLabel() : mcProcessFlow.fldSendFlowToInkassoLabel()"></mcc-row-title-description>

            <mcc-row-title-description *ngIf="mcProcess.isTypeCdDUNNING()" title="{{'cc.processes.group-transactions-by-consumer' | translate}}:" [widerTitleFlg]="true"
                                       [description]="mcProcessFlow.fldGroupByConsumer()"></mcc-row-title-description>

          </div>
        </div>

      </div>


      <div class="actions-container">
        <span>{{'cc.processes.levels' | translate}}</span>
        <div class="actions-wrapper">
          <button *ngIf="mcGod.userHasMcProcessFlowLevelAddPermission()" (click)="addLevel()" class="system-action-btn"><i class="fas fa-plus-circle"></i> {{'cc.processes.levels.add-level' | translate}}</button>
        </div>
      </div>

      <div *ngIf="flowLevelsObjectList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="flowLevelsObjectList"
                        [table3Config]="levelTableConfig"
                        (eventItemAction)="actProcessLevelTableAction($event)"
                        (eventPaginationOrSortChanged)="actLoad()">
        </mcc-fi-table-3>
      </div>

      <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showGeneralFlowInfoEdit.value" [showMcb]="showGeneralFlowInfoEdit" (eventCanceled)="actCancelGeneralFlowInfoEdit()">
        <mc-process-process-flow-general-flow-info-edit [mcProcessFlow]="mcProcessFlow"
                                                        (eventSaved)="actSavedGeneralFlowInfoEdit()"
                                                        (eventCanceled)="actCancelGeneralFlowInfoEdit()"></mc-process-process-flow-general-flow-info-edit>
      </mcc-modal1-wrapper>

      <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showAdditionalFlowInfoEdit.value"
                          [showMcb]="showAdditionalFlowInfoEdit" (eventCanceled)="actCancelAdditionalInfoEdit()">
        <mc-process-process-flow-additional-flow-info-edit [mcProcessFlow]="mcProcessFlow"
                                                           [mcProcess]="mcProcess"
                                                           (eventSaved)="actSavedAdditionalInfoEdit()"
                                                           (eventCanceled)="actCancelAdditionalInfoEdit()"></mc-process-process-flow-additional-flow-info-edit>
      </mcc-modal1-wrapper>

      <mcc-modal1-wrapper *ngIf="showAddEditLevelMcb.value" [showMcb]="showAddEditLevelMcb"
                          (eventCanceled)="actCancelAddEditLevel()">
        <mc-process-flow-level-add-edit [mcProcessFlowLevel]="mcProcessLevel" [mcProcessFlowId]="mcProcessFlow.id"
                                        (eventSaved)="actSaveAddEditLevel()" [mcProcess]="mcProcess"
                                        (eventCanceled)="actCancelAddEditLevel()"></mc-process-flow-level-add-edit>
      </mcc-modal1-wrapper>

      <mcc-modal1-wrapper *ngIf="showEditCriteriaFlowMcb.value" [showMcb]="showEditCriteriaFlowMcb"
                          (eventCanceled)="actEditCriteriaCanceled()">
        <mc-process-process-flow-criteria-edit [mcProcessFlow]="mcProcessFlow"
                                               (eventCanceled)="actEditCriteriaCanceled()"
                                               (eventSaved)="actEditCriteriaSaved()"></mc-process-process-flow-criteria-edit>
      </mcc-modal1-wrapper>

      <mcc-modal1-wrapper *ngIf="showEditFeesMcb.value" [showMcb]="showEditFeesMcb"
                          (eventCanceled)="actFlowFeesEditCancel()">
        <mc-process-process-flow-fees-edit [mcProcessFlow]="mcProcessFlow" (eventCanceled)="actFlowFeesEditCancel()"
                                           (eventSaved)="actFlowFeesEditSaved()"></mc-process-process-flow-fees-edit>
      </mcc-modal1-wrapper>

      <mcc-modal1-wrapper *ngIf="showEditTransactionsEnteringTheFlowMcb.value" [showMcb]="showEditTransactionsEnteringTheFlowMcb"
                          (eventCanceled)="onTransactionsEnteringTheFlowEditCancelEmit()">
        <mc-process-process-flow-transactions-entering-the-flow-edit [mcProcessFlow]="mcProcessFlow" (eventCanceled)="onTransactionsEnteringTheFlowEditCancelEmit()"
                                           (eventSaved)="onTransactionsEnteringTheFlowEditSaveEmit()"></mc-process-process-flow-transactions-entering-the-flow-edit>
      </mcc-modal1-wrapper>

    </div>
  </div>
</div>

<router-outlet></router-outlet>
