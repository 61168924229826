import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  EbFactoringService,
  McBillingItem,
  McBillingItemFilter,
  McBillingItemList,
  McBillingServiceCatalog,
  McBoolean,
  McEntity2List,
  McForm,
  McGod,
  McValueLabelList
} from '@miticon-ui/mc-core';
import {MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkMatMenuItem, MkTableConfig} from "@miticon-ui/mc-components";
import {AppSharedMatBottomSheetComponent} from "../../../app-shared/components/app-shared-mat-bottom-sheet/app-shared-mat-bottom-sheet.component";
import {MatBottomSheet} from "@angular/material/bottom-sheet";

@Component({
  selector: 'mc-out-billing-billing-item-list-page',
  templateUrl: './mc-out-billing-billing-item-list-page.component.html',
})
export class McOutBillingBillingItemListPageComponent implements OnInit, OnDestroy {
  mcForm = new McForm();
  mcGod = McGod.getInstance();
  isLoading = false;
  mcBillingItemList = new McBillingItemList();
  mcBillingItem = new McBillingItem();
  showAssignToStatementMcb = new McBoolean();
  mcBillingItemFilter = new McBillingItemFilter();
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  checkedBillingItemsList = new McBillingItemList();
  mcEntity2List = new McEntity2List();
  minAmount!: number;
  maxAmount!: number;
  amountVll!: McValueLabelList;
  searchTooltip = McGod.t('cc.filters.search-by-reference-id');
  menuActionItems: MkMatMenuItem[] = [{
    title: McGod.t('cc.common.assign-to-statement'),
    actionCd: McBillingItem.ACTION_ASSIGN_TO_STATEMENT,
    matIcon: 'note_add',
    permission: McGod.PERM_MC_BILLING_OUT_BILLING_ITEM_VIEW
  }];

  constructor(private factoringService: EbFactoringService, private matBottomSheet: MatBottomSheet) {
  }

  ngOnInit() {
    this.mcBillingItemList = new McBillingItemList();
    this.mcBillingItemList.setPager(0, 100);
    this.mcEntity2List.loadAllWhereParentEntityIsLoggedInEntity(() => {
      this.mcEntity2List.items.sort((a, b) => a.name.localeCompare(b.name));
      this.initFilterConfig();
    });

    this.actLoad(0, 100, this.mcBillingItemFilter);
    this.initTableConfig();
  }

  actLoad(pageNumber: number, pageSize: number, filters: McBillingItemFilter) {
    this.isLoading = true;
    this.mcBillingItemFilter.amountFrom = this.minAmount;
    this.mcBillingItemFilter.amountTo = this.maxAmount;
    this.mcBillingItemList.pageNum = pageNumber;
    this.mcBillingItemList.pageItemsPerPageCount = pageSize;
    this.mcBillingItemList.loadByFilter(filters, () => {
      this.checkedBillingItemsList = new McBillingItemList();
        this.mcBillingItemList.items.map(item => {
          item.currencyCode = this.factoringService.getParentFactoringForDateAndChildEntityId(item.activityDatetime, item.childEntityId)?.mcCurrency.code;
        });
        this.getTotalAmountVll();
      this.isLoading = false;
    });
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('cc.common.view.entity'), 'childEntityName', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.jobs.edit.type'), 'getRefTypeCdLabel()', 250, 'typeCd');
    this.tableConfig.addColumnStandard(McGod.t('cc.statement.reference-id'), 'refId', 250, 'refId');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.item-type'), 'getItemTitle()', 250, 'title');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.batch'), 'mcBillingBatchName', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.factoring.transactions.amount'), 'getAmount()', 250, 'amount');
    this.tableConfig.addColumnInnerHtml(McGod.t('cc.product.view.vat'), 'getVATString()',  '');
    this.tableConfig.addColumnStandard(McGod.t('cc.statement.total'), 'getTotalAmount()', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.statement.activity-date-and-time'), 'getActivityDatetimeString()', 250, 'activityDatetime');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.payout-date'), 'getPayoutDateString()', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.statement.statement-name'), 'mcBillingStatementTitle', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.view.status'), 'getStatusCdLabel()', 250, 'statusCd');
  }

  initFilterConfig() {
    const entityFilterValues = this.mcEntity2List.items.map((entity) => new MkFilterValue(entity.name, entity.id));
    const filteredEntities = [...new Map(entityFilterValues.map((item) => [item.value, item])).values()];
    const typeFilter = McBillingServiceCatalog.getTypeCdsVll().items.map((item) => new MkFilterValue(item.label, item.value));
    const statusFilter = McBillingItem.getStatusCdVll().items.map((item) => new MkFilterValue(item.label, item.value));
    const amountFilter = this.getTotalAmountVll().items.map((item) => new MkFilterValue(item.label, item.value));

    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(McBillingItem.FILTER_ENTITIES), filteredEntities);
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(McBillingItem.FILTER_TYPE), typeFilter);
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(McBillingItem.FILTER_STATUS), statusFilter);
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(McBillingItem.FILTER_AMOUNT), amountFilter);
    this.filterConfig.addItem(MkFilterItemType.RANGE_DATEPICKER, McGod.t(McBillingItem.FILTER_DATE_RANGE));
  }

  onFilterChanged(filters: MkFilterOutput) {
    const billingItemsFilter = new McBillingItemFilter();
    this.mcBillingItemList.setSortB(filters.sort.sortProperty, filters.sort.sortType);
    billingItemsFilter.refId = parseInt(filters.search);
    billingItemsFilter.entityIds = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(McBillingItem.FILTER_ENTITIES)]);
    billingItemsFilter.mcBillingServiceCatalogIds = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(McBillingItem.FILTER_TYPE)]);
    billingItemsFilter.statusCds = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(McBillingItem.FILTER_STATUS)]);
    billingItemsFilter.amountFrom = filters.selections[McGod.t(McBillingItem.FILTER_AMOUNT)] ? filters.selections[McGod.t(McBillingItem.FILTER_AMOUNT)][0][0] : '';
    billingItemsFilter.amountTo = filters.selections[McGod.t(McBillingItem.FILTER_AMOUNT)] ? filters.selections[McGod.t(McBillingItem.FILTER_AMOUNT)][0][1] : '';
    billingItemsFilter.dateFrom = filters.selections[McGod.t(McBillingItem.FILTER_DATE_RANGE)]?.startDate;
    billingItemsFilter.dateTo = filters.selections[McGod.t(McBillingItem.FILTER_DATE_RANGE)]?.endDate;
    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize, billingItemsFilter);
  }

  onItemsSelected(items: McBillingItem[]) {
    if (items.length === 0) {
      this.matBottomSheet.dismiss();
    }
    this.checkedBillingItemsList = new McBillingItemList();
    this.checkedBillingItemsList.items = items;
    const bulkContainerLength = document.getElementsByClassName('bulk-container').length;
    if (items.length > 0 && !bulkContainerLength) {
      const bulkDialog = this.matBottomSheet.open(AppSharedMatBottomSheetComponent, {
        closeOnNavigation: true,
        hasBackdrop: false,
        data: [
          {
            icon: 'note_add',
            title: McGod.t('cc.common.assign-to-statement'),
            action: McBillingItem.ACTION_ASSIGN_TO_STATEMENT,
          }
        ],
      });

      bulkDialog.afterDismissed().subscribe((bulkAction) => {
        switch (bulkAction) {
          case McBillingItem.ACTION_ASSIGN_TO_STATEMENT:
            this.showAssignToStatementMcb.setTrue();
            break;
        }
      });
    }
  }

  onMenuItemAction(mkMatTableMenuAction: any) {
    if (mkMatTableMenuAction.action.actionCd === McBillingItem.ACTION_ASSIGN_TO_STATEMENT) {
      this.checkedBillingItemsList = new McBillingItemList();
      this.mcBillingItem = mkMatTableMenuAction.item;
      this.showAssignToStatementMcb.setTrue();
    }
  }

  onAssignToStatementSaveEmit() {
    this.showAssignToStatementMcb.setFalse();
    // this.successMsg = 'Billing item successfully assigned to Statement.';
    // this.actLoad();
    this.checkedBillingItemsList = new McBillingItemList();
  }

  onAssignToStatementCancelEmit() {
    if (this.checkedBillingItemsList.items.length > 0) {
      this.onItemsSelected(this.checkedBillingItemsList.items);
    } else {
      this.mcBillingItem = new McBillingItem();
    }
    this.checkedBillingItemsList = new McBillingItemList();
    this.showAssignToStatementMcb.setFalse();
  }

  getTotalAmountVll() {
    this.amountVll = new McValueLabelList();
    this.amountVll.add(['0', '499.99'], '0 - 499,99 €');
    this.amountVll.add(['500', '999.99'], '500,00 € - 999,99 €');
    this.amountVll.add(['1000', '1999.99'], '1000,00 € - 1999,99 €');
    this.amountVll.add(['2000', '4999.99'], '2000,00 € - 4999,99 €');
    this.amountVll.add(['5000', '9999.99'], '5000,00 € - 9999,99 €');
    this.amountVll.add(['10000', null], 'Over 10000,00 €');
    const result = this.amountVll.items.sort((a, b) => {
      return a.value[0] - b.value[0];
    });
    this.amountVll.items = result;
    return this.amountVll;
  }

  ngOnDestroy() {
    this.matBottomSheet.dismiss();
  }
}
