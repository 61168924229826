/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CsrMappingFilter} from '../models/csr-mapping-filter.model';

export class CsrMappingFilterGENERATED extends BaseObjectFilter{    public searchTerm: string | undefined;
    public createdByUserId: number | undefined;

  public properties: string[] = ['searchTerm', 'createdByUserId', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrMappingFilter {
    console.error('MTCN-ERROR: Not Implemented: CsrMappingFilter::createNewInstance(). Add this method to final class and return new CsrMappingFilter();');
      throw new Error('Cannot CsrMappingFilter::createNewInstance(). Add this method to final class and return new CsrMappingFilter();');
  }

}
