/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBillingEntitiesUsingPricelist} from '../models/mc-billing-entities-using-pricelist.model';
import {McBillingEntitiesUsingPricelistService} from '../services/mc-billing-entities-using-pricelist.service';
import {IMcBillingEntitiesUsingPricelist} from '../_generated/mc-billing-entities-using-pricelist.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McBillingEntitiesUsingPricelistGENERATED extends BaseObject {


  public apiService: McBillingEntitiesUsingPricelistService;
  public _rawJson: IMcBillingEntitiesUsingPricelist;
    id: number = 0;
  entityId: number;
  entityName = '';
  startDate: number;


  public properties: string[] = ['id', 'entityId', 'entityName', 'startDate'];
  public propertiesRegular: string[] = ['id', 'entityId', 'entityName', 'startDate'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): McBillingEntitiesUsingPricelist {
    console.error('MTCN-ERROR: Not Implemented: McBillingEntitiesUsingPricelist::createNewInstance(). Add this method to final class and return new McBillingEntitiesUsingPricelist();');
    throw new Error('Cannot McBillingEntitiesUsingPricelist::createNewInstance(). Add this method to final class and return new McBillingEntitiesUsingPricelist();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBillingEntitiesUsingPricelist): McBillingEntitiesUsingPricelist {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingEntitiesUsingPricelistService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBillingEntitiesUsingPricelist AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getStartDateStrD(): string {
       return McDateUtils.formatDateToString(this.startDate);
    }

    public getStartDateStr(): string {
       return McDateUtils.formatDateTimeToString(this.startDate);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
