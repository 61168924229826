import {Component, OnInit, Input} from '@angular/core';
import {Location} from '@angular/common';
import {McGod} from '@miticon-ui/mc-core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-shared-go-back',
  templateUrl: './app-shared-go-back.component.html',
  styleUrls: ['./app-shared-go-back.component.scss']
})
export class AppSharedGoBackComponent implements OnInit {

  @Input() icon!: string;
  @Input() title!: string;
  @Input() routeLink!: string;

  constructor(private location: Location, private router: Router) { }

  ngOnInit() {
  }

  goBack() {
    if (this.routeLink) {
      this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/` + this.routeLink]);
    } else {
      this.location.back();
    }
  }
}
