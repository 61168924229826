import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {McEntity2, McForm, McGod} from '@miticon-ui/mc-core';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'mc-entity-transaction-description-modal',
  templateUrl: './mc-entity-transaction-description-modal.component.html',
  styleUrls: ['./mc-entity-transaction-description-modal.component.css']
})
export class McEntityTransactionDescriptionModalComponent implements OnInit {
  mcForm = new McForm();
  loggedEntity = new McEntity2();
  transactionDescription!: string;
  loadingFlg = false;

  @Input() isCreate: boolean;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();

  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {
    this.loadingFlg = true;
    this.loggedEntity.loadById(McGod.getLoggedEntityIdFromToken(), () => {
      this.transactionDescription = this.loggedEntity.transactionDescriptionPrefix;
      this.loadingFlg = false;
    });
  }

  actSave() {
    if (this.mcForm.isValid()) {
      this.loggedEntity.transactionDescriptionPrefix = this.mcForm.getValue('ctrlTransactionDescription');
      this.loadingFlg = true;
      this.loggedEntity.save(() => {
        this.loadingFlg = false;
        this.toastr.success(`${McGod.t('cc.transactions.transaction-description-successfully-added')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
        this.eventSaved.emit(this.loggedEntity.transactionDescriptionPrefix);
      }, ()=> {
        this.toastr.error(this.loggedEntity.apiErrorMessage, '', {timeOut: 4000, extendedTimeOut: 4000});
      });
    }
  }

  actCancel() {
    this.eventCanceled.emit();
  }

}
