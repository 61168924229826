<h3 class="page-title">{{"cc.common.consumer-360" | translate}}</h3>

<div class="actions-container">
  <span>{{"cc.common.consumers-list" | translate}}</span>
  <div class="actions-wrapper">
    <button  *ngIf="mcGod.userHasMcConsumerAddPermission()" class="system-action-btn mr-2" (click)="addConsumer()">
      <i class="fas fa-plus-circle mc-add-new-user-icon"></i> {{'cc.consumers.create.create-consumers' | translate}}
    </button>
  </div>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  [itemsPerPage]="pageSize"
  (filterChanged)="onFilterChanged($event)"
  [mkMatMenuActionItems]="mkMatMenuActionItems"
  (actionMkMatMenuItems)="onActionMkMatMenuItems($event)"
  (eventColumnButtonAction)="onColumnButtonAction($event)"
  [searchTooltip]="searchTooltip"
>
</mk-mat-table>
<router-outlet></router-outlet>



