import { McMyBillingStatementProfilePageComponent } from './pages/mc-my-billing-statement-profile-page/mc-my-billing-statement-profile-page.component';
import { McMyBillingStatementListPageComponent } from './pages/mc-my-billing-statement-list-page/mc-my-billing-statement-list-page.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [
{
    path: 'statement/:id',
    component: McMyBillingStatementProfilePageComponent
  },
{
    path: 'statements',
    component: McMyBillingStatementListPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McMyBillingRoutingModule {
}
