/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
/* Using "any" data-type in returned observables to avoid importing   */
/* ItemClass and ItemListClass and circular dependencies.             */
/* ------------------------------------------------------------------ */
import {BaseObjectService} from '../_core/base-object-service';
import {IMcBillingStatement} from '../_generated/mc-billing-statement.interface';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';
// import {McBillingStatement} from '../models/mc-billing-statement.model';
// import {McBillingStatementList} from '../models/mc-billing-statement-list.model';
// import {McBillingStatementFilter} from '../models/mc-billing-statement-filter.model';
import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class McBillingStatementServiceGENERATED extends BaseObjectService {
protected apiServiceUrlSubPath = "/mcbilling/v3/mcbillingstatement";

public getApiServiceRootUrl(): string {
    return this.apiUrl + this.apiServiceUrlSubPath;
  }

  /* Get all  from DB */
public getAll(aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/all?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  /* Get all  from DB without pager */
public getByFilter(aFilter: any, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/filter?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, aFilter.toDto(), {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

/* Get by id from DB */
public getById(id: number): Observable<IMcBillingStatement> {
  return this.httpClient.get<IMcBillingStatement>(this.getApiServiceRootUrl() + `/${id}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

// Insert Object
public insert(aMcBillingStatement: any): Observable<IMcBillingStatement> {
    return this.httpClient.post<IMcBillingStatement>(this.getApiServiceRootUrl()  , aMcBillingStatement.toDto(), {headers: this.httpOptions});
}
// Update object
public update(aMcBillingStatement: any): Observable<IMcBillingStatement> {
    return this.httpClient.put<IMcBillingStatement>(this.getApiServiceRootUrl() + `/`+ aMcBillingStatement.id, aMcBillingStatement.toDto(), {headers: this.httpOptions});
}


// DeleteAllFromDB
public deleteAllFromDb() {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/deleteAllFromDb`, {headers: this.httpOptions});
  }

  /* Get by id from DB */
public deleteById(id: number) {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/${id}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });

  }

  // -- CUSTOM METHODS --
  
  // ---------------------------------------------------------------------
  public regenerateXLSandPDF( statementId: number) {
    const body: any = {};
    body.statementId = statementId;

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/regeneratexlsandpdf/${statementId}`,  {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  // ---------------------------------------------------------------------
  /* getByIdPayoutFile  from DB  */
  public getByIdPayoutFile( idPayoutFile: number, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getbyidpayoutfile/${idPayoutFile}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  /* getAllActiveOrNewByParentEntityId  from DB  */
  public getAllActiveOrNewByParentEntityId( parentEntityId: number, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl()
      + `/getallactiveornewbyparententityid/${parentEntityId}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}


  // ---------------------------------------------------------------------
  public collectBillingItems( id: number) {
    const body: any = {};
    body.id = id;

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/collectbillingitems/${id}`,  {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  // ---------------------------------------------------------------------
  public recalculate( id: number): Observable<IMcBillingStatement> {
    return this.httpClient.get<IMcBillingStatement>(this.getApiServiceRootUrl()
      + `/recalculate/${id}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  // ---------------------------------------------------------------------
  public changeStatusHidden( ids: any) {
    const body: any = {};
    body.ids = ids;

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/changestatushidden/${ids}`,  {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  // ---------------------------------------------------------------------
  public removeStatementItemsFromStatement( statementId: number, statementItemIds: any) {
    const body: any = {};
    body.statementId = statementId;
body.statementItemIds = statementItemIds;

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/removestatementitemsfromstatement/${statementId}/${statementItemIds}`,  {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

}
