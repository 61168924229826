import {Component, EventEmitter, Inject, Input, OnInit, Output} from "@angular/core";
import {
  McAuthService,
  McBillingItemList,
  McBillingStatementItem,
  McBillingStatementItemList,
  McBoolean,
  McForm,
  McGod
} from "@miticon-ui/mc-core";

@Component({
  selector: "mc-out-billing-aggregate-deaggregate-part",
  templateUrl: "./mc-out-billing-aggregate-deaggregate-part.component.html",
})
export class McOutBillingAggregateDeaggregatePartComponent implements OnInit {

  aggregationName!: string;
  noOfItems!: number;
  amount = 0;
  vat = 0;
  amountWithVat = 0;
  errorMsg!: string;
  disableAggregateDeaggregateBtnMcb = new McBoolean();
  isAggregate!: boolean;
  allowActions = true;
  mcForm = new McForm();
  mcGod = McGod.getInstance();
  mcBillingItemList = new McBillingItemList();
  billingItemList = new McBillingItemList(); // Need this to call method, error is occurred when you pass same list on same object method
  statementId!: number;

  @Input() mcBillingStatementItem = new McBillingStatementItem();
  @Input() mcBillingStatementItemList = new McBillingStatementItemList();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter<boolean>();

  ngOnInit() {
    if (this.mcBillingStatementItem.exists()) {
      this.aggregationName = this.mcBillingStatementItem.nameOfBillingServiceForStatementItem;
      this.isAggregate = this.mcBillingStatementItem.isTypeCdSINGLE();
      this.amount = this.mcBillingStatementItem.amount;
      this.vat = this.mcBillingStatementItem.vatForBillingStatementItem;
      this.noOfItems = 1;
      this.amountWithVat = this.amount + this.vat;
      this.statementId = this.mcBillingStatementItem.mcBillingStatementId;
    } else {
      const lastItemIsSingle = this.mcBillingStatementItemList.getLastItem().isTypeCdSINGLE();
      const everyItemTypeIsSame = this.mcBillingStatementItemList.items.every(item => lastItemIsSingle ? item.isTypeCdSINGLE() : item.isTypeCdAGGREGATE());
      if (everyItemTypeIsSame) {
        const aggregationNames = this.mcBillingStatementItemList.items.map(item => item.title);
        this.aggregationName = aggregationNames.join(', ');
        this.statementId = this.mcBillingStatementItemList.getLastItem().mcBillingStatementId;
      } else {
        this.errorMsg = 'Selected items does not have same type.';
        this.allowActions = false;
      }
    }
  }

  actSave() {
    if (this.allowActions) {

      if (this.isAggregate) { // AGGREGATE
      } else { // DEAGGREGATE
        const leaveStatementItemsInStatement = this.mcForm.getValue('ctrlLeaveBillingStatements');
      }

    }
  }

  actCancel() {
    this.eventCanceled.emit();
  }
}
