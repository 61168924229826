/*BbmTestObj*/
import {McEntityConsumerAttributeGENERATED} from '../_generated/mc-entity-consumer-attribute-generated.model';

export class McEntityConsumerAttribute extends McEntityConsumerAttributeGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McEntityConsumerAttribute();
  }

  public getMcFormControlName(): string {
    return 'ctrlCustom' + this.name;
  }

  // ---------------------------------------------------------------------

}
