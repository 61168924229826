/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EbStarmoneyFileFilter} from '../models/eb-starmoney-file-filter.model';

export class EbStarmoneyFileFilterGENERATED extends BaseObjectFilter{    public searchTerm: string;
    public importedByUserId: number;

  public properties: string[] = ['searchTerm', 'importedByUserId', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbStarmoneyFileFilter {
    console.error('MTCN-ERROR: Not Implemented: EbStarmoneyFileFilter::createNewInstance(). Add this method to final class and return new EbStarmoneyFileFilter();');
      throw new Error('Cannot EbStarmoneyFileFilter::createNewInstance(). Add this method to final class and return new EbStarmoneyFileFilter();');
  }

}
