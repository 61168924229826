import {
  Component, EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {MccFiSelectOptionConfig} from './mcc-fi-select-option-config';

@Component({
  selector: 'mcc-fi-select-option',
  templateUrl: './mcc-fi-select-option.component.html',
  styleUrls: ['./mcc-fi-select-option.component.css']
})
export class MccFiSelectOptionComponent implements OnInit {

  @Input() label: string = '';
  @Input() name: string = '';
  @Input() optionsKvm = new Map<string, string>();
  @Input() selectedValue: string = '';
  @Input() config = new MccFiSelectOptionConfig();
  @Output() eventSelectionChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if (this.config != null) {
      this.config.applyToComponent(this);
    }
    if (this.name == null) {
      this.name = 'label-' + this.selectedValue;
    }
  }
  actSelectionChange() {
    this.eventSelectionChange.emit(this.selectedValue);
  }
}
