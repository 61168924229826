import {Component, OnInit, Input, Output, EventEmitter, Inject} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {McBankAccount} from '@miticon-ui/mc-core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mc-bank-account-modal',
  templateUrl: './mc-bank-account-modal.component.html'
})
export class McBankAccountModalComponent implements OnInit {

  modalReference!: any;
  message!: string;
  type!: string;
  trashIcon;
  showLoader!: boolean;
  bankAccountForm: FormGroup;
  bankAccount!: McBankAccount;

  @Input() bankAccountId = 0;
  @Input() bankAccountDefault!: any;
  @Input() entityId!: any;
  @Output() updateBankAccounts = new EventEmitter<any>();
  @Output() deleteBankAcc = new EventEmitter<any>();

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    @Inject('designConfig') design: any
  ) {
    this.bankAccountForm = new FormGroup({});
    this.trashIcon = design.trashIcon;
  }

  ngOnInit() {
    // Init bankAccountObj
    this.bankAccount = new McBankAccount();

    if (this.bankAccountId > 0) {
      this.bankAccount.loadById(this.bankAccountId);
    }
  }

  openModal(content: any) {
    this.deleteBankAcc.emit('');
    this.setNotificationMessage('', '');
    // Modal reference
    this.modalReference = this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'mc-bank-account',
      backdrop: 'static'
    });
    // Form values
    this.bankAccountForm = this.fb.group({
      accountOwner: [this.bankAccount.accountOwner, Validators.required],
      bankName: [this.bankAccount.bankName, Validators.required],
      bankCity: [this.bankAccount.bankCity, Validators.required],
      bankCountry: [this.bankAccount.bankCountry, Validators.required],
      accountNumber: [this.bankAccount.accountNumber, Validators.required],
      blz: [this.bankAccount.blz, Validators.required],
      swift: [this.bankAccount.swift, Validators.required],
      iban: [this.bankAccount.iban, Validators.required],
    });
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  // Set default bankAccount
  setDefaultBankAccount() {
    this.deleteBankAcc.emit('');
    if (!this.bankAccountDefault) {
      this.bankAccount.defaultFlg = true;
      this.bankAccount.save(() => {
        this.updateBankAccounts.emit(true);
      });
    }
  }

  // Delete bankAccount
  deleteBankAccount() {
    this.bankAccount.delete(() => {
      this.updateBankAccounts.emit(true);
      this.deleteBankAcc.emit('');
    },
      () => {
        this.deleteBankAcc.emit('Can\'t delete bank account already used in some invoices.');
    });
  }

  // Add/edit bankAccount
  addEditBankAccount() {
    const isInvalid = Object.keys(this.bankAccountForm.controls).find(item => this.bankAccountForm.controls[item].invalid);
    if (isInvalid) {
      this.setNotificationMessage(`Required fields can't be empty`, 'error');
    } else {
      this.showLoader = true;
      this.setNotificationMessage('', '');
      const form = this.bankAccountForm.getRawValue();
      this.bankAccount.accountOwner = form.accountOwner;
      this.bankAccount.bankName = form.bankName;
      this.bankAccount.bankCity = form.bankCity;
      this.bankAccount.bankCountry = form.bankCountry;
      this.bankAccount.accountNumber = form.accountNumber;
      this.bankAccount.blz = form.blz;
      this.bankAccount.swift = form.swift;
      this.bankAccount.iban = form.iban;
      this.bankAccount.idMcEntity = this.entityId;
      this.bankAccount.save(() => {
        this.showLoader = false;
        this.updateBankAccounts.emit(true);
        this.modalService.dismissAll('Cross click');
      }, () => {
        this.showLoader = false;
        this.setNotificationMessage(this.bankAccount.apiErrorMessage, 'error');
      });
    }
  }

}
