/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {ImDataSetTable3ConfigGENERATED} from '../_generated/im-data-set-table-3-config-generated.model';

// import {ImDataSetGENERATED} from '../_generated/im-data-set-generated.model';

export class ImDataSetTable3Config extends ImDataSetTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colAllowCustomAtt', 'Allow Custom Attribute', 'allowCustomAtt', 'allowCustomAtt');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colName', 'Name', 'name', 'name');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');


  }
}
