import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PmTransactionFilter } from '../models/pm-transaction-filter.model';
import { EbInkassoExportServiceGENERATED } from '../_generated/eb-inkasso-export-generated.service';
import { IEbInkassoExport } from '../_generated/eb-inkasso-export.interface';

@Injectable({
  providedIn: 'root',
})
export class EbInkassoExportService extends EbInkassoExportServiceGENERATED {
  public exportAllForInkasso(ebInkassoExport: any, pmTransactionFilter: PmTransactionFilter, delimiter: string): Observable<IEbInkassoExport> {
    const body: any = {};
    body.ebInkassoExport = ebInkassoExport.toDto();
    body.pmTransactionFilter = pmTransactionFilter;
    body.delimiter = delimiter;

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/export-by-filter`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }
}
