<h3 class="page-title">{{ "cc.common.pricelists-template" | translate }}</h3>

<div class="actions-container">
  <span>{{ "cc.common.pricelists-template" | translate }}</span>
  <div class="actions-wrapper">
    <button *ngIf="mcGod.userHasMcBillingTemplatePricelistAddPermission()"
            class="system-action-btn ml-auto"
            (click)="actShowAddPricelistTemplateMcb()">
      <i class="fas fa-plus-circle"></i>
      {{'cc.pricelist.new-pricelist-template' | translate}}
    </button>
  </div>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [totalItemsCount]="totalItemsCount"
  [filterConfig]="filterConfig"
  (filterChanged)="onFilterChanged($event)"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
  [searchTooltip]="searchTooltip"
></mk-mat-table>

<div class="row">
  <mcc-modal1-wrapper
    *ngIf="showAddEditMcPricelistTemplateMcb.value"
    [showMcb]="showAddEditMcPricelistTemplateMcb">
    <mc-pricelist-template-add-edit-part
      (eventSaved)="onAddTemplatePricelistSaveEmit()"
      (eventCanceled)="showAddEditMcPricelistTemplateMcb.setFalse()">
    </mc-pricelist-template-add-edit-part>
  </mcc-modal1-wrapper>
</div>

<router-outlet></router-outlet>
