import {CsrAccountConsumer} from './csr-account-consumer.model';
import {CsrAccountConsumerListGENERATED} from '../_generated/csr-account-consumer-list-generated.model';

export class CsrAccountConsumerList extends CsrAccountConsumerListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrAccountConsumerList();
  }

  // ---------------------------------------------------------------------
}
