import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {MccFiRadioButtonsEventAfterValidation} from './mcc-fi-radio-buttons-event-after-validation';
import {McForm, McGod} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'mcc-fi-radio-buttons',
  templateUrl: './mcc-fi-radio-buttons.component.html',
  styleUrls: ['./mcc-fi-radio-buttons.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MccFiRadioButtonsComponent implements OnInit, OnDestroy {

  requiredFlg!: Validators;
  mcGod = McGod.getInstance();
  validatorList: any[] = [];
  // radioButtonsForm: FormGroup;
  labelPositionClass!: string;
  formValidationTriggerSubscription!: Subscription;

  @Input() mcForm!: McForm;
  @Input() extraCssClasses!: string;
  @Input() valueToLabelKvm = new Map<string, string>();
  @Input() name!: string;
  @Input() value!: string;
  // @Input() frmGroup: FormGroup;
  @Input() label!: string;
  @Input() validRequiredFlg = false;
  // @Input() customValidator;
  // @Input() validationSetupObject: McValidation;
  @Input() validationInComponent = false;
  @Output() eventValueChanged = new EventEmitter();
  @Output() eventAfterValidation = new EventEmitter();
  // @Output() eventValidationTurnOn = new EventEmitter();
  // @Input() validationTrigger = false;
  @Input() optionsVlm!: Map<any, string>;
  @Input() directionHorizontal = true;
  @Input() extraCssClass: any;
  @Input() labelOnTop = true;


  // @Output() eventSelectionChanged = new EventEmitter<MccFiRadioButtonsEventSelectionChanged>();

  constructor() {
  }

  getRadioForm(): FormGroup {
    return this.mcForm.getControl(this.name) as FormGroup;
  }

  setCurrentValue() {
    if (!this.getRadioForm()) {
      const radioForm = this.mcForm.formBuilder.group({
        value: [this.value, this.validatorList],
        label: [this.label],
        errorFlg: [false],
        errorMsg: ['']
      });

      if (this.mcForm) {
        this.mcForm.addControl(this.name, radioForm);
      }
    } else {
      this.getRadioForm().controls['value'].setValue(this.value);
      this.getRadioForm().controls['errorFlg'].setValue(false);
      this.getRadioForm().controls['errorMsg'].setValue('');
    }
  }

  ngOnInit() {

    this.validationSetup();

    this.labelPositionCssClass();

    if (this.mcForm) {
      this.formValidationTriggerSubscription = this.mcForm.getValidationTriggerObservable().subscribe(() => {
        this.validateRadioButton();
      });
    }

    if (!this.value) {
      this.value = '';
    }

    this.setCurrentValue();

    this.getRadioForm().get('value')?.valueChanges.subscribe(data => {
      this.onRadioChanged(data);
    });
  }

  ngOnDestroy(): void {
    if (this.formValidationTriggerSubscription) {
      this.formValidationTriggerSubscription.unsubscribe();
    }
    this.mcForm.formGroup.removeControl(this.name);
  }


  validationSetup() {


    if (this.validRequiredFlg) {
      this.requiredFlg = Validators.required;
      this.validatorList.push(this.requiredFlg);
    }

    /*if (this.customValidator) {
      this.validatorList.push(this.customValidator);
    }*/
  }

  validateRadioButton() {
    this.validationSetup();
    if (this.getRadioForm().get('value')?.errors) {
      // @ts-ignore
      const validators = Object.keys(this.getRadioForm().get('value').errors);
      if (validators) {
        this.getRadioForm().get('errorFlg')?.setValue(true);
        validators.forEach(item => {
          if (item === 'required') {
            this.getRadioForm().get('errorMsg')?.setValue(this.mcGod.t('cc.common.this-field-is-required'));
          } else {
            this.getRadioForm().get('errorMsg')?.setValue(`Some validation`);
          }
        });
      }
    } else {
      this.getRadioForm().get('errorFlg')?.setValue(false);
      this.getRadioForm().get('errorMsg')?.setValue(``);
    }
    this.fireEventAfterValidation();
  }

  labelPositionCssClass() {
    if (this.labelOnTop === true) {
      this.labelPositionClass = 'flex-column';
    } else {
      this.labelPositionClass = 'flex-row';
    }
  }

  public fireEventAfterValidation() {
    if (this.eventAfterValidation) {
      const eventObj = new MccFiRadioButtonsEventAfterValidation();
      eventObj.validFlg = !this.getRadioForm().get('value')?.errors;

      this.eventAfterValidation.emit(eventObj);
    }
  }

  clearErrors() {
    this.getRadioForm().get('errorFlg')?.setValue(false);
    this.getRadioForm().get('errorMsg')?.setValue('');
  }

  onRadioChanged(value: any) {
    this.clearErrors();
    if (this.eventValueChanged) {
      this.eventValueChanged.emit(value);
    }
  }
}
