/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McDashboard} from '../models/mc-dashboard.model';
import {McDashboardService} from '../services/mc-dashboard.service';
import {IMcDashboard} from '../_generated/mc-dashboard.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McDashboardGENERATED extends BaseObject {


  public apiService: McDashboardService;
  public _rawJson: IMcDashboard;
    id: number = 0;
  activeConsumers: number;
  automaticallyMatchedTransactions: number;
  consumersWithBalance: number;
  emailsSentChild: number;
  emailsSentParent: number;
  inactiveConsumers: number;
  isChildOnly: boolean;
  manuallyMatchedTransactions: number;
  manualMatchingNeededTransactions: number;
  overdueTransactions: number;
  paidTransactions: number;
  pdfGenerated: number;
  rejectedTransactions: number;
  sentToInkassoTransactions: number;
  sentTransactions: number;
  toBeSentTransactions: number;


  public properties: string[] = ['id', 'activeConsumers', 'automaticallyMatchedTransactions', 'consumersWithBalance', 'emailsSentChild', 'emailsSentParent', 'inactiveConsumers', 'isChildOnly', 'manuallyMatchedTransactions', 'manualMatchingNeededTransactions', 'overdueTransactions', 'paidTransactions', 'pdfGenerated', 'rejectedTransactions', 'sentToInkassoTransactions', 'sentTransactions', 'toBeSentTransactions'];
  public propertiesRegular: string[] = ['id', 'activeConsumers', 'automaticallyMatchedTransactions', 'consumersWithBalance', 'emailsSentChild', 'emailsSentParent', 'inactiveConsumers', 'isChildOnly', 'manuallyMatchedTransactions', 'manualMatchingNeededTransactions', 'overdueTransactions', 'paidTransactions', 'pdfGenerated', 'rejectedTransactions', 'sentToInkassoTransactions', 'sentTransactions', 'toBeSentTransactions'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): McDashboard {
    console.error('MTCN-ERROR: Not Implemented: McDashboard::createNewInstance(). Add this method to final class and return new McDashboard();');
    throw new Error('Cannot McDashboard::createNewInstance(). Add this method to final class and return new McDashboard();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcDashboard): McDashboard {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McDashboardService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McDashboard AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
