<div class="confirmation-contract-code d-flex flex-column">
  <div class="lock-icon d-flex justify-content-center">
    <img src="assets/images/unlock-keyhole-solid.svg">
  </div>
  <h4>
    Authentication
  </h4>
  <p>Please enter confirmation code send via email.</p>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-between">
      <form [formGroup]="confirmationCodeForm">
        <input type="text" maxlength="1" formControlName="codeNumber1" #focusableNumber1 (keyup.space)="focusableNumber2.focus()">
        <input type="text" maxlength="1" formControlName="codeNumber2" #focusableNumber2 (keyup.space)="focusableNumber3.focus()" (keyup.backspace)="focusableNumber1.focus()">
        <input type="text" maxlength="1" formControlName="codeNumber3" #focusableNumber3 (keyup.space)="focusableNumber4.focus()" (keyup.backspace)="focusableNumber2.focus()">
        <input type="text" maxlength="1" formControlName="codeNumber4" #focusableNumber4 (keyup.space)="focusableNumber5.focus()" (keyup.backspace)="focusableNumber3.focus()">
        <input type="text" maxlength="1" formControlName="codeNumber5" #focusableNumber5 (keyup.space)="focusableNumber6.focus()" (keyup.backspace)="focusableNumber4.focus()">
        <input type="text" maxlength="1" formControlName="codeNumber6" #focusableNumber6 (keyup.backspace)="focusableNumber5.focus()">
      </form>
    </div>
    <button class="btn btn-primary" (click)="validateConfirmationCode()">Confirm</button>
  </div>
</div>
