import {CsrConsumerHistory} from './csr-consumer-history.model';
import {CsrConsumerHistoryListGENERATED} from '../_generated/csr-consumer-history-list-generated.model';

export class CsrConsumerHistoryList extends CsrConsumerHistoryListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrConsumerHistoryList();
  }

  // ---------------------------------------------------------------------
}
