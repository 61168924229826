import {Inject, Injectable} from '@angular/core';
import {CsrConsumerServiceGENERATED} from '../_generated/csr-consumer-generated.service';
import {Observable} from "rxjs";
import {ICsrConsumer} from "../_generated/csr-consumer.interface";
import {CsrConsumer} from "../models/csr-consumer.model";
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CsrConsumerService extends CsrConsumerServiceGENERATED {
  public getConsumerBalance(consumerId: number): Observable<number> {
    return this.httpClient.get<number>(this.getApiServiceRootUrl() + `/${consumerId}/balance`);
  }

  public getAttributes() {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/custom-attributes`);
  }

  public getSearchConsumersByEntityName(): Observable<boolean> {
    return this.httpClient.get<boolean>(this.getApiServiceRootUrl() + '/search-consumers-by-entity');
  }

  public getReferenceEntities(parentEntityId: number, externalStudios: boolean): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + `/csrconsumer/v4/csrconsumer/product-reference-entities/${parentEntityId}`+ `?externalStudios=${externalStudios.toString()}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  public getConsumersByInvoiceType(invoiceType: string): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + `/csrconsumer/v4/csrconsumer/invoice-type/${invoiceType}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

}
