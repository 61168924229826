
import {Table3Config} from '../extra/table-3-config';

// import {CsrReccuringOptionsGENERATED} from '../_generated/csr-reccuring-options-generated.model';

export class CsrReccuringOptionsTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colName', 'Reccuring option name', 'name', 'name');

*/
  }
}
