/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McProcess} from '../models/mc-process.model';
import {McProcessService} from '../services/mc-process.service';
import {IMcProcess} from '../_generated/mc-process.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McProcessGENERATED extends BaseObject {


    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_INACTIVE: string = 'INACTIVE';
  public static readonly STATUS_CD_LIST__ALL = [
    McProcessGENERATED.STATUS_CD_ACTIVE,
    McProcessGENERATED.STATUS_CD_INACTIVE
];

    public static readonly TYPE_CD_DUNNING: string = 'DUNNING';
    public static readonly TYPE_CD_MARKETING: string = 'MARKETING';
    public static readonly TYPE_CD_NOTIFICATIONS: string = 'NOTIFICATIONS';
  public static readonly TYPE_CD_LIST__ALL = [
    McProcessGENERATED.TYPE_CD_DUNNING,
    McProcessGENERATED.TYPE_CD_MARKETING,
    McProcessGENERATED.TYPE_CD_NOTIFICATIONS
];

  public apiService: McProcessService;
  public _rawJson: IMcProcess;
    id: number = 0;
  description = '';
  idMcEntity: number;
  inheritableFlg: boolean;
  statusCd: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  title = '';
  typeCd: string;
  activeMcProcessFlowCount: number;  activeMcProcessItemCount: number;  lastRunDateTime: any;  totalMcProcessFlowCount: number;

  public properties: string[] = ['id', 'description', 'idMcEntity', 'inheritableFlg', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'title', 'typeCd', 'activeMcProcessFlowCount', 'activeMcProcessItemCount', 'lastRunDateTime', 'totalMcProcessFlowCount'];
  public propertiesRegular: string[] = ['id', 'description', 'idMcEntity', 'inheritableFlg', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'title', 'typeCd', 'activeMcProcessFlowCount', 'activeMcProcessItemCount', 'lastRunDateTime', 'totalMcProcessFlowCount'];
  public propertiesSpecial: string[] = [];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessGENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(McProcessGENERATED.STATUS_CD_INACTIVE, McGod.t('Inactive'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McProcessGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessGENERATED.TYPE_CD_DUNNING, McGod.t('Dunning'));
    list.add(McProcessGENERATED.TYPE_CD_MARKETING, McGod.t('Marketing'));
    list.add(McProcessGENERATED.TYPE_CD_NOTIFICATIONS, McGod.t('Notifications'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = McProcessGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McProcess {
    console.error('MTCN-ERROR: Not Implemented: McProcess::createNewInstance(). Add this method to final class and return new McProcess();');
    throw new Error('Cannot McProcess::createNewInstance(). Add this method to final class and return new McProcess();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcProcess): McProcess {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McProcess AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getLastRunDateTimeStrD(): string {
       return McDateUtils.formatDateToString(this.lastRunDateTime);
    }

    public getLastRunDateTimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.lastRunDateTime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return McProcessGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === McProcessGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdINACTIVE(): boolean {
    const result = (this.statusCd === McProcessGENERATED.STATUS_CD_INACTIVE);

    return result;
  }


  public getTypeCdLabel(): string {
    return McProcessGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdDUNNING(): boolean {
    const result = (this.typeCd === McProcessGENERATED.TYPE_CD_DUNNING);

    return result;
  }

 public  isTypeCdMARKETING(): boolean {
    const result = (this.typeCd === McProcessGENERATED.TYPE_CD_MARKETING);

    return result;
  }

 public  isTypeCdNOTIFICATIONS(): boolean {
    const result = (this.typeCd === McProcessGENERATED.TYPE_CD_NOTIFICATIONS);

    return result;
  }


  
}
