<!--Mc Table wrapper-->
<div class="mc-table-wrapper mb-5" [ngClass]="handleHorizontalScroll()"
     *ngIf="tableApiCallFinished && tableContent">

  <!--Mc table-->
  <table class="table table-striped mc-table">

    <!--Mc table header-->
    <thead class="mc-table-head">
    <tr>

      <!--Checkbox for select all-->
      <th class="text-center"
          *ngIf="setup['hasCheckbox']"
          McPermission
          [hasPermissions]="userCanEditPermission"
          [page]="tableContent._name">
        <label class="custom-control fill-checkbox">
          <input type="checkbox" id="selectRows" class="fill-control-input" (click)="onSelectAllRows()"
                 [(ngModel)]="selectAllRows">
          <span class="fill-control-indicator"></span>
        </label>
      </th>

      <!--Drag&Drop-->
      <th *ngIf="setup['isDragAndDrop']">
        <span></span>
      </th>

      <th *ngFor="let rowItem of setup['tableContent']"
          [ngStyle]="imageColumnStyle(rowItem['label'])"
          [ngClass]="rowItem['css'] && rowItem['css']['_class']"
          scope="col"
          class="mc-table-head-item">

        <div style='display: flex; gap: 5px'>
          <!--header labels-->
          <span class="capitalize" *ngIf="rowItem['label'] !== 'image'">{{rowItem['translateKey'] | translate}}</span>


          <!--Arrows for sorting-->
          <div class="mc-arrow-wrapper" *ngIf="rowItem['label'] !== 'image' && rowItem['isSortable']">
            <i class="fa fa-chevron-up mc-arrow-up-icon" (click)="onColumnSort(rowItem['id'], 'ascending')"></i>
            <i class="fa fa-chevron-down mc-arrow-down-icon" (click)="onColumnSort(rowItem['id'], 'descending')"></i>
          </div>
        </div>

      </th>
    </tr>
    </thead><!--MC table head ./end-->

    <!--Mc table body-->
    <tbody class="mc-table-body" cdkDropList [cdkDropListData]="tableContentList" (cdkDropListDropped)="onDrop($event)">

    <tr *ngFor="let content of tableContentList; let contentIndex = index"
        [ngClass]="setup['hasCheckbox'] && content['selected'] && content['statusCode'] !== 'inactive' ? 'mc-selected-row' : ''"
        class="{{content['highlight'] ? 'mc-table-detect-changes-once' : ''}}"
        cdkDrag
        cdkDragDisabled="{{!setup['isDragAndDrop']}}"
        [ngStyle]="{'opacity': content['statusCode'] && content['statusCode'].toLowerCase() === 'inactive' ? 0.3 : 1}">

      <!--Select row item-->
      <td class="text-center mc-checkbox-td"
          *ngIf="setup['hasCheckbox']"
          McPermission
          [hasPermissions]="userCanEditPermission"
          [page]="tableContent._name">
        <label class="custom-control fill-checkbox">
          <input
            (change)="onSelectRow(content)"
            type="checkbox"
            id="{{content['id']}}"
            class="fill-control-input"
            [checked]="content['selected']"
          >
          <span class="fill-control-indicator"></span>
        </label>
      </td>

      <!--Drag&Drop-->
      <td class="text-center mc-checkbox-td"
          *ngIf="setup['isDragAndDrop']"
      >
        <div class="mc-drag-and-drop" cdkDragHandle>
          <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
            <path
              d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </td>

      <!--Table content-->
      <td *ngFor="let td of setup['tableRowList']; let i = index"
          class="align-middle {{td['css'] && td['css']['_class']}}"
          [ngStyle]="td['css'] && td['css']['_style']"
          [ngClass]="imageColumnCssClass(td['label'])">
        <!--Image-->
        <span *ngIf="td['label'] === 'image'">
          <img src="{{content[td['label']]}}" class="mc-table-image" alt="">
        </span>


        <!--Content-->
        <!--Todo: make components load dynamically to avoid a lot of ngIf...-->
        <span
          *ngIf="td['label'] !== 'statusCode' && td['label'] !== 'image' && td['label'] !== 'actions' && !td['conf'] && td['label'] !== 'button-remove-reset'
           && td['label'] !== 'button-add-product' && !td['componentName'] && td['label'] !== 'description-memo'"
        >


          <span *ngIf="td.label === 'caption' && tableContent._name === 'role' && content.templateFlg">
              <img src="assets/images/role-icons/template.svg">
          </span>

          <span *ngIf="td.label === 'caption' && tableContent._name === 'role' && content.linkedFlg" [ngbTooltip]="getTooltipForLinkedIcon(content)">
            <img src="assets/images/role-icons/linked.svg">
          </span>

          <span *ngIf="td.label === 'caption' && tableContent._name === 'role' && !content.linkedFlg && content.ancestorId" [ngbTooltip]="getTooltipForUnlinkedIcon(content)">
            <img src="assets/images/role-icons/unlinked.svg">
          </span>

          <span *ngIf="td.label === 'caption' && tableContent._name === 'role' && !content.ancestorId && !content.templateFlg">
            <img src="assets/images/role-icons/user-created.svg">
          </span>
          <!--Roles td for user table-->
              <span *ngIf="td['label'] === 'roles' && tableContent._name === 'user'; else notUserRoles">
                <p class="my-0" *ngFor="let role of content[td['label']]">

                  <!--Left icon for roles td in user table-->
                  <span class="mc-submit-color mr-1"
                        *ngIf="td['leftIcon']"><i class="{{td['leftIcon']}}"></i></span>

                  <!--Entity name-->
                  <span class="mc-submit-color mr-1" *ngIf="role['entity']">{{role['entity']['name']}}</span>
                  <!--Role name-->
                  <span>{{role['caption']}}</span>


                  <span class="mc-submit-color ml-1"
                        *ngIf="td['rightIcon']"><i class="{{td['rightIcon']}}"></i></span>

                </p>
              </span>



          <!--td content for not user role-->
              <ng-template #notUserRoles>
                <!--Left icon in td-->
                <span class="mc-submit-color"
                      *ngIf="td['leftIcon']">
                  <span *ngFor="let icon of td['leftIcon']"
                        [ngClass]="icon['css'] && icon['css']['_class']"
                        [ngbTooltip]="!content.linkedFlg ? (icon['toolTip'] | translate) : 'Linked roles cannot be edited'"
                        McPermission [hasPermissions]="icon['permission']" [page]="tableContent._name"
                        [usePermissionDirective]="icon['permission']"
                        class="mc-cursor-pointer">
                  <!--  <i class="{{icon['icon']}} mx-1"
                       *ngIf="checkCondition(content, icon['_showingCondition'])"
                       ></i>-->
                  </span>
                </span>

                <!--Format content-->
                <span
                  #elementContent
                  [ngbTooltip]="td['toolTip'] && (td['toolTip'] | translate)"
                  class="{{setHighLightCssClass(content, elementContent)}}"
                  [ngClass]="{'mc-table-cursor-pointer mc-submit-color' : td['routeName'] || td['modal']}"
                  (click)="td['routeName'] ? onRedirect(td['routeName'], td['paramAttribute'], content['id'], content[td['label']], td['routePermission']) : td['modal'] ? open(td['modal'], content['id'], content[td['label']]) : null"
                >
                  {{formatDisplayValue(content, td)}}
                </span>

                <!--Right icon in td-->
                <span class="mc-submit-color"
                      *ngIf="td['rightIcon']">
                  <span *ngFor="let icon of td['rightIcon']"
                        [ngClass]="icon['css'] && icon['css']['_class']"
                        [ngStyle]="icon['css'] && icon['css']['_style']"
                        [ngbTooltip]="icon['toolTip'] && (icon['toolTip'] | translate)"
                        McPermission [hasPermissions]="icon['permission']" [page]="tableContent._name"
                        [usePermissionDirective]="icon['permission']"
                        class="mc-cursor-pointer">
                    <i class="{{icon['icon']}} mx-1"
                       *ngIf="checkCondition(content, icon['_showingCondition'])"
                       ></i>
                  </span>
                </span>

              </ng-template>
        </span><!--Content /end-->

        <span *ngIf="td['componentName'] === 'McPriceAdjustmentComponent'" style="display: block; position: relative;">
<!--          <ng-container *ngComponentOutlet="renderComponent(td['componentName']); injector: createInjector(td)">-->
          <!--          </ng-container>-->
          <mc-apply-options *ngIf="tableContentList.length > 1" [objectProperty]="'discount'"
                            (selectedOption)="onApplyOptions($event, content, contentIndex)"></mc-apply-options>
          <mc-price-adjustment [discount]="content['discount']"
                               [totalAmountValue]="content['unitPrice']"
                               (onValidatingDiscount)="onValidatingDiscount()"
                               (onAdjustPrice)="onCalculateDiscount(content)"></mc-price-adjustment>
        </span>

        <span *ngIf="td['componentName'] === 'McInvoiceTotalPriceComponent'">
         <!-- <span *ngIf="setup['name'] === 'invoice_summary'; else invoice">
            {{content.discount.value}}
          </span>-->
          <ng-template #invoice>
                      <mc-product-total-price [productTotalPrice]="content['totalPrice']"
                                              [calculationType]="'DISCOUNT'"
                                              [productDiscount]="content['discount']['amount']"
                                              [setup]="td['componentSetup']"></mc-product-total-price>
          </ng-template>

        </span>

        <span *ngIf="td['label'] === 'description-memo'">
          <!--Description-->
          <p class="mb-0 mc-g-font-size-13 font-weight-bold"
             *ngIf="content.description">{{"cc.common.view.description" | translate}}</p>
          <p class="mb-1">{{content.description}}</p>

          <!--Memo-->
          <p class="mb-0 mc-g-font-size-13 font-weight-bold"
             *ngIf="content.note">{{"cc.jobs.view.memo" | translate}}</p>
          <p class="mb-0">{{content.note}}</p>
        </span>

        <!--Buttons for remove and reset products-->
        <span *ngIf="td['label'] === 'button-remove-reset'">
          <span class="mc-icon mx-1" (click)="resetProductInInvoice(content, contentIndex)">
            <img src="{{designConfig.syncIcon}}" alt="">
          </span>
          <span class="mc-icon mx-1" (click)="removeProductFromInvoice(contentIndex)">
            <img src="{{designConfig.trashIcon}}" alt="">
          </span>
        </span>

        <!--Add product button-->
        <span *ngIf="td['label'] === 'button-add-product' && content['statusCode'] !== 'inactive'">
          <mc-add-product-to-invoice [product]="content"
                                     (onSendProduct)="onReceiveFromChild($event)"></mc-add-product-to-invoice>
        </span>

        <!--Status component-->
        <span *ngIf="td['label'] === 'statusCode'">
          <mc-status [status]="formatStatusCode(content[td['label']])"></mc-status>
        </span>

        <!--Job action component-->
        <span *ngIf="td['label'] === 'actions'">
          <mc-job-action
            [activeActions]="{
            actions: content[td['label']],
            id: content['id']
            }"
            (chosenAction)="onReceiveFromChild($event)">
          </mc-job-action>
        </span>

        <!--Input fields-->
        <span *ngIf="td['conf']">
          <span *ngIf="td['conf']['element'] === 'input'">
             <input type="{{td['conf']['type']}}"
                    step="{{td['conf']['steps']}}"
                    class="form-control mc-table-input-field {{td['css'] && td['css']['_class']}}"
                    [readOnly]="td['conf']['isReadonly']"
                    [value]="setInputValue(content, td)"
                    (change)="onChangeInvoiceValue(content)"
                    (keyup)="onChangeInvoiceValue(content)"
                    [(ngModel)]="content[td['label']]"
                    [ngStyle]="td['css'] && td['css']['_style']"
                    [min]="0"
                    [ngClass]="td['conf']['isReadonly'] ? 'mc-readonly ' : null"
                    McDetectChanges [elementHtmlValue]="td['label'] === 'totalPrice' && setInputValue(content, td)"
                    placeholder="{{td['conf']['placeholder']}}">
          </span>

          <span *ngIf="td['conf']['element'] === 'textarea'" class="mc-table-textarea">

            <!--Description-->
            <p class="mb-0 mc-g-font-size-13 font-weight-bold">{{"cc.common.view.description" | translate}}</p>
             <textarea
               class="form-control mb-2 {{td['css'] && td['css']['_class']}}"
               [rows]="td['conf']['rows']"
               (change)="onChangeDescriptionAndMemo()"
               [(ngModel)]="content['description']"
               [ngStyle]="td['css'] && td['css']['_style']"
               [ngClass]="td['conf']['isReadonly'] ? 'mc-readonly ' : null"
               McDetectChanges [elementHtmlValue]="td['label'] === 'totalPrice' && content[td['label']]"
               placeholder="{{'cc.common.view.description' | translate}}">
             </textarea>

            <!--Apply memo-->
            <div *ngIf="tableContentList.length > 1" class="position-relative" ngbDropdown>
              <button
                ngbDropdownToggle
                class="mc-table-textarea-apply-button"
                (click)="openOptions(content)">
               <span class="mc-table-more-options">...</span>
              </button>
              <div ngbDropdownMenu class="mc-table-options-dropdown">
                <p ngbDropdownItem class="mc-table-dropdown-item py-2 px-2 m-0 text-capitalize"
                   (click)="onApplyMemo(content['note'], contentIndex, true)">{{ "cc.table.options.apply-to-the-rows-above" | translate}}</p>
                <p ngbDropdownItem class="mc-table-dropdown-item py-2 px-2 m-0 text-capitalize"
                   (click)="onApplyMemo(content['note'], contentIndex, false)">{{ "cc.table.options.apply-to-the-rows-below" | translate }}</p>
              </div>
            </div>

            <!--Memo-->
            <p class="mb-0 mc-g-font-size-13 font-weight-bold">{{"cc.jobs.view.memo" | translate}}</p>
            <textarea
              class="form-control {{td['css'] && td['css']['_class']}}"
              [rows]="td['conf']['rows']"
              [(ngModel)]="content['note']"
              (change)="onChangeDescriptionAndMemo()"
              [ngStyle]="td['css'] && td['css']['_style']"
              [ngClass]="td['conf']['isReadonly'] ? 'mc-readonly ' : null"
              McDetectChanges [elementHtmlValue]="td['label'] === 'totalPrice' && content[td['label']]"
              placeholder='{{"cc.jobs.view.memo" | translate}}'>
             </textarea>
          </span>

        </span>
      </td>

    </tr><!--Table body content /end-->
    </tbody><!--MC table body ./end-->

    <!--***************************************************************************************************************
    ****************************************Invoice summary - second table*********************************************
    *******************************************************************************************************************-->
    <tbody *ngIf="setup['type'] === 'invoice' && tableContentList.length > 0" class="mc-table-summary">

    <!--Subtotal-->
    <tr>
      <td *ngIf="setup['isDragAndDrop']"></td>
      <!--<td *ngIf="setup['type'] === 'invoice' && setup['name'] !== 'invoice_summary'"></td>-->
      <td></td>
      <td></td>
      <td class="text-capitalize text-right border-right" colspan="2">
        {{ "cc.table.invoice.subtotal" | translate }}
      </td>
      <td colspan="2"></td>
      <td McDetectChanges [elementHtmlValue]="onSubtotal()" class="text-right mc-font-weight-500">
        {{onSubtotal()}}
      </td>
    <!--  <td *ngIf="setup['type'] === 'invoice' && setup['name'] !== 'invoice_summary'"></td>-->
    </tr>

    <!--Additional discount-->
    <tr>
    <!--  <td *ngIf="setup['isDragAndDrop']"></td>-->
      <!--<td *ngIf="setup['type'] === 'invoice' && setup['name'] !== 'invoice_summary'"></td>-->
      <td colSpan="2" class="vertical-align-middle">
       <!-- <span *ngIf="invoiceObject.VATlist.length > 1 && setup['name'] !== 'invoice_summary'"
              style="font-size: 13px; font-style: italic">
          <i class="fas fa-info-circle"></i>
          Additional discount on the invoice is<br> possible only for products with unified VAT.
        </span>-->
      </td>
      <td class="text-capitalize text-right border-right" colspan="2"
          [ngStyle]="setCssStyleOnDisableAdditionalDiscount()">
        {{ "cc.common.additional-discount" | translate }}
      </td>
      <td colspan="2" class="text-right vertical-align-middle" [ngStyle]="setCssStyleOnDisableAdditionalDiscount()">
        <!--<span *ngIf="setup['name'] === 'invoice_summary'; else invoice">
        </span>-->
        <ng-template #invoice>
          <mc-price-adjustment [discount]="invoiceObject.discount"
                               [additional]="true"
                               [totalAmountValue]="invoiceObject.subtotalValue"
                               [isEnabled]="invoiceObject.VATlist.length > 1"
                               (onValidatingDiscount)="onValidatingDiscount()"
                               (onAdjustPrice)="onCalculateAdditionalDiscount()"></mc-price-adjustment>
        </ng-template>

      </td>
      <td McDetectChanges
          [elementHtmlValue]="onCalculateAdditionalDiscount()"
          [ngStyle]="setCssStyleOnDisableAdditionalDiscount()"
          class="text-right vertical-align-middle">
          <span>
           <span
             *ngIf="invoiceObject.discount.typeCd === 'DISCOUNT' && invoiceObject.discount.value > 0">-</span>{{onCalculateAdditionalDiscount()}}
          </span>
      </td>
  <!--    <td *ngIf="setup['type'] === 'invoice' && setup['name'] !== 'invoice_summary'"></td>-->
    </tr>

    <!--New subtotal-->
    <tr>
    <!--  <td *ngIf="setup['isDragAndDrop']"></td>
      <td *ngIf="setup['type'] === 'invoice' && setup['name'] !== 'invoice_summary'"></td>-->
      <td></td>
      <td></td>
      <td class="text-capitalize text-right border-right" colspan="2">
        {{ "cc.common.new-subtotal" | translate }}
      </td>
      <td colspan="2"></td>
      <td McDetectChanges
          [elementHtmlValue]="newSubtotal()"
          class="text-right mc-font-weight-500">
          <span>
            {{newSubtotal()}}
          </span>
      </td>
      <!--<td *ngIf="setup['type'] === 'invoice' && setup['name'] !== 'invoice_summary'"></td>-->
    </tr>

    <!--Additional VAT-->
    <tr *ngFor="let vat of invoiceObject.VATlist">
    <!--  <td *ngIf="setup['isDragAndDrop']"></td>
      <td *ngIf="setup['type'] === 'invoice' && setup['name'] !== 'invoice_summary'"></td>-->
      <td></td>
      <td></td>
      <td class="text-capitalize text-right border-right" McDetectChanges [elementHtmlValue]="vat" colspan="2">
        {{ "cc.table.invoice.additional" | translate }} {{ "cc.product.view.vat" | translate }} {{vat}}%
      </td>
      <td colspan="2"></td>
      <td McDetectChanges [elementHtmlValue]="VATsubtotal(vat)" class="text-right">
        {{VATsubtotal(vat)}}
      </td>
    <!--  <td *ngIf="setup['type'] === 'invoice' && setup['name'] !== 'invoice_summary'"></td>-->
    </tr>

    <!--Total balance-->
    <tr class="mc-tr-black">
     <!-- <td *ngIf="setup['isDragAndDrop']"></td>
      <td *ngIf="setup['type'] === 'invoice' && setup['name'] !== 'invoice_summary'"></td>
      <td></td>-->
      <td></td>
      <td class="text-capitalize text-right border-right" colspan="2">
        {{ "cc.table.invoice.total-balance" | translate }}
      </td>
      <td colspan="2">
        <!--Total discount-->
        <span *ngIf="totalDiscount() !== 0" class="float-right">
          <span class="font-italic">
            {{"cc.common.discount.after-applying-all-discounts" | translate}}
          </span>
          <span McDetectChanges [elementHtmlValue]="totalDiscount()">
            ({{totalDiscount()}})
          </span>
        </span>
      </td>
      <td McDetectChanges [elementHtmlValue]="invoiceSummary()" class="text-right mc-font-weight-600">
        {{invoiceSummary()}}
      </td>
    <!--  <td *ngIf="setup['type'] === 'invoice' && setup['name'] !== 'invoice_summary'">
      </td>-->
    </tr>
    </tbody><!--Table summary /end-->


  </table><!--MC table ./end-->

  <!--No data to display-->
  <div *ngIf="!tableContentList || tableContentList.length === 0" class="text-center w-100 my-3">No data to display.
  </div>

  <!--MC table footer-->
  <div class="row mc-table-footer" *ngIf="setup['type'] !== 'invoice'">
    <!--Paginator-->
    <mat-paginator [pageSizeOptions]="rowsPerPageList"
                   [pageSize]="paginationObject.itemsPerPage"
                   [pageIndex]="paginationObject.currentPage - 1"
                   [length]="totalItems"
                   aria-label="Select page"
                   showFirstLastButtons
                   (page)="onPageChanged($event)">
    </mat-paginator><!--Paginator ./end-->
  </div><!--MC footer ./end-->
</div><!--Mc table wrapper ./end-->

<!--todo: implement service to open modal-->
<!--Modal for role permissions-->
<ng-template #permissionsListModal let-modal>
    <div *ngIf="permissionsForRoleId">
      <div class="modal-header">
        <h4 class="modal-title text-center" id="modal-basic-title">{{permissionsForRoleId.caption}}</h4>
        <button type="button" class="close m-0 p-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <p class="h5">{{ 'cc.common.view.basic-info' | translate }}</p>

        <!--Status-->
        <div class="row">
          <div class="col-md-3 text-muted text-uppercase mc-label-text">{{ "cc.common.view.status" | translate }}:</div>
          <div class="col-md-9 position-top">
            <mc-status [status]="permissionsForRoleId.statusCode"></mc-status>
          </div>
        </div>

        <!--Name-->
        <div class="row">
          <div class="col-md-3 text-muted text-uppercase mc-label-text">{{ "cc.common.name" | translate }}:</div>
          <div class="col-md-9 position-top">{{permissionsForRoleId.caption}}</div>
        </div>

        <!--Description-->
        <div class="row">
          <div class="col-md-3 text-muted text-uppercase mc-label-text">{{ 'cc.common.view.description' | translate }}:
          </div>
          <div class="col-md-9 position-top">{{permissionsForRoleId.description}}</div>
        </div>
        <hr>

        <!--Permissions list-->
        <div class="row">
          <div class="col-md-3 text-muted text-uppercase mc-label-text">{{ "cc.roles.view.permissions" | translate}}:
          </div>
          <div class="col-md-9 position-top">
            <p class="capitalize w-100"
               *ngFor="let permission of permissionsForRoleId.permissionList">{{permission['description']}}</p>
          </div>
        </div>
      </div>
    </div>
</ng-template>

<ng-template #tableSettings let-modal>
  <div *ngIf="tableSetupConfiguration">
    <div class="modal-header">
      <h4 class="modal-title text-center" id="modal-basic-title1">{{ 'cc.consumers.view.table-settings' | translate
        }}</h4>
      <button type="button" class="close m-0 p-0" aria-label="Close" (click)="modal.dismiss('Croess click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
   <!--     <div class="col-md-6" *ngFor="let tableColumnItem of tableSetupConfiguration['tableContent']">
          <label class="custom-control fill-checkbox mc-cursor-pointer">
            <input type="checkbox"
                   class="fill-control-input"
                   (click)="onSelectTableRowItem(tableColumnItem)"
                   [checked]="tableColumnItem['selected']"
            >
            <span class="fill-control-indicator"></span>
            <span class="text-capitalize">{{ tableColumnItem['translateKey'] | translate }}</span>
          </label>
        </div>-->
      </div>
    </div>
    <div class="modal-footer">
      <p class="m-0 mr-3 align-middle text-danger text-capitalize font-italic"
         *ngIf="tableSetupConfigurationTableContent">{{ "cc.table.table-need-content" | translate }}!</p>
      <button class="text-capitalize mc-button mc-submit-button"
              [disabled]="tableSetupConfigurationTableContent"
              (click)="onChangeTableColumnSetup()">
        {{ "cc.common.view.save" | translate }}
      </button>
    </div>
  </div>
</ng-template>
