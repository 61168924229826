import {CsrContractFilterGENERATED} from '../_generated/csr-contract-filter-generated.model';

export class CsrContractFilter extends CsrContractFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrContractFilter();
  }

  // ---------------------------------------------------------------------
}
