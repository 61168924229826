/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McPriceList} from '../models/mc-price-list.model';
import {McPriceListService} from '../services/mc-price-list.service';
import {IMcPriceList} from '../_generated/mc-price-list.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {McBillingService2PriceListList} from '../models/mc-billing-service-2-price-list-list.model';
import {McBillingEntitiesUsingPricelistList} from '../models/mc-billing-entities-using-pricelist-list.model';
import {McCurrency} from '../models/mc-currency.model';


export abstract class McPriceListGENERATED extends BaseObject {


  public apiService: McPriceListService;
  public _rawJson: IMcPriceList;
    id: number = 0;
  description = '';
  idEntityOwner: number;
  idMcCurrency: number;
  idParentTemplate: number;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  title = '';
  validFrom: number;
  validTo: number;
  createdByUserFullName: string;  currency: McCurrency;  entitiesUsingPricelist: McBillingEntitiesUsingPricelistList;  mcBillingService2PriceListItemList: McBillingService2PriceListList;  nameOfEntityOwner: string;  nameOfOriginTemplate: string;  numberOfCreatedPriceLists: number;  numberOfCustomPrices: number;  numberOfDefaultPrices: number;  numberOfEntitiesUsingPricelist: number;  numberOfEntitiesUsingTemplate: number;

  public properties: string[] = ['id', 'description', 'idEntityOwner', 'idMcCurrency', 'idParentTemplate', 'sysCreatedDatetime', 'sysCreatedUserId', 'title', 'validFrom', 'validTo', 'createdByUserFullName', 'currency', 'entitiesUsingPricelist', 'mcBillingService2PriceListItemList', 'nameOfEntityOwner', 'nameOfOriginTemplate', 'numberOfCreatedPriceLists', 'numberOfCustomPrices', 'numberOfDefaultPrices', 'numberOfEntitiesUsingPricelist', 'numberOfEntitiesUsingTemplate'];
  public propertiesRegular: string[] = ['id', 'description', 'idEntityOwner', 'idMcCurrency', 'idParentTemplate', 'mcCurrency', 'sysCreatedDatetime', 'sysCreatedUserId', 'title', 'validFrom', 'validTo', 'createdByUserFullName', 'nameOfEntityOwner', 'nameOfOriginTemplate', 'numberOfCreatedPriceLists', 'numberOfCustomPrices', 'numberOfDefaultPrices', 'numberOfEntitiesUsingPricelist', 'numberOfEntitiesUsingTemplate'];
  public propertiesSpecial: string[] = ['currency', 'entitiesUsingPricelist', 'mcBillingService2PriceListItemList'];


  // ---------------------------------------------------------------------
public static createNewInstance(): McPriceList {
    console.error('MTCN-ERROR: Not Implemented: McPriceList::createNewInstance(). Add this method to final class and return new McPriceList();');
    throw new Error('Cannot McPriceList::createNewInstance(). Add this method to final class and return new McPriceList();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcPriceList): McPriceList {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McPriceListService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McPriceList AABB()');
          if (this._rawJson['currency']) {
        this.currency = McCurrency.createFromJson(this._rawJson['currency']);
      }
      if (this._rawJson['entitiesUsingPricelist']) {
        this.entitiesUsingPricelist = McBillingEntitiesUsingPricelistList.createFromJson(this._rawJson['entitiesUsingPricelist']);
      }
      if (this._rawJson['mcBillingService2PriceListItemList']) {
        this.mcBillingService2PriceListItemList = McBillingService2PriceListList.createFromJson(this._rawJson['mcBillingService2PriceListItemList']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getValidFromStrD(): string {
       return McDateUtils.formatDateToString(this.validFrom);
    }

    public getValidFromStr(): string {
       return McDateUtils.formatDateTimeToString(this.validFrom);
    }

    public getValidToStrD(): string {
       return McDateUtils.formatDateToString(this.validTo);
    }

    public getValidToStr(): string {
       return McDateUtils.formatDateTimeToString(this.validTo);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('currency')) {
        if (this.currency != null) {
            dto['currency'] = this.currency.toDto();
        } else {
            dto['currency'] = null;
        }
    }
    if (this.hasOwnProperty('entitiesUsingPricelist')) {
        if (this.entitiesUsingPricelist != null) {
            dto['entitiesUsingPricelist'] = this.entitiesUsingPricelist.toDto();
        } else {
            dto['entitiesUsingPricelist'] = [];
        }
    }
    if (this.hasOwnProperty('mcBillingService2PriceListItemList')) {
        if (this.mcBillingService2PriceListItemList != null) {
            dto['mcBillingService2PriceListItemList'] = this.mcBillingService2PriceListItemList.toDto();
        } else {
            dto['mcBillingService2PriceListItemList'] = [];
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
