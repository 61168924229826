import {CsrMappingFilterGENERATED} from '../_generated/csr-mapping-filter-generated.model';

export class CsrMappingFilter extends CsrMappingFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrMappingFilter();
  }

  // ---------------------------------------------------------------------
}
