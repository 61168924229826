<!--Job overview wrapper-->
<section class="mc-edit-user mc-job-overview">

  <!--Back button-->
  <span class="back-option" (click)="goBack()">
    <span [innerHTML]="iconBack | safeHtml"></span>
    {{( "cc.common.back-to-all" | translate) + " " + ("cc.common.jobs" | translate)}}
  </span>

  <div class="cardbox mt-medium" *ngIf="jobName">
    <div class="cardbox_header">
      <span class="cardbox_title">{{ jobName | uppercase }}</span>
    </div>

    <div class="divider"></div>

    <div class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.view.status' | translate}}:</span>
        <span class='dialog-col-value'>
          <mc-status [status]="jobStatus"></mc-status>
        </span>
      </div>
    </div>

    <div class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.jobs.edit.type' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ jobType ? ( jobType.split("_").join(" ") | titlecase ) : '-'}}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.view.description' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ jobDescription ? jobDescription : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.job.view.schedule-type' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ scheduleType ? ( scheduleType.split("_").join(" ")  | titlecase ) : '-' }}
        </span>
      </div>
    </div>

    <div class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.jobs.view.schedule-time' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ scheduleTime ? scheduleTime : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.jobs.view.last-fired-time' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ lastFiredTime ? lastFiredTime : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.jobs.view.next-fire-time' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ nextFireTime ? nextFireTime : '-' }}
        </span>
      </div>
    </div>
  </div>
</section><!--Job overview wrapper /end-->

<section *ngIf="isJobGroupReminders()" class="mt-medium">
  <mk-mat-table
    [isLoading]="isLoading"
    [items]="items"
    [config]="tableConfig"
    [totalItemsCount]="totalItemsCount"
    [itemsPerPage]="pageSize"
    [showCheckbox]="false"
    (filterChanged)="onFilterChanged($event)"
    (eventColumnButtonAction)="onColumnButtonAction($event)">
  </mk-mat-table>
</section>
