<ng-template #addNewConsumer let-modal>
  <!--Modal header section-->
  <div class="modal-header mc-create-user-modal-header">
    <div>
      <h4 class="modal-title" id="mc-modal-new-user-title">{{'cc.consumers.create.create-consumers' | translate}}</h4>
    </div>
<!--    <div class="mc-create-user-entity-path" *ngIf="selectedEntityPath">-->
<!--      <span *ngFor="let entity of selectedEntityPath">-->
<!--        {{entity}}-->
<!--      </span>-->
<!--    </div>-->
    <button type="button" class="close mc-consumers-close-btn" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div><!--Modal header -->
  <!--Modal body section-->
  <div class="modal-body">
    <div class="mc-create-new-user-wrapper">
      <div class="container-fluid">
        <div class="mc-create-user-notify-wrapper">
        <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
      </div>
        <form [formGroup]="addConsumerForm" (submit)="addConsumer()" class="mc-create-user-parent-form">
          <!--Stepper-->
          <lib-mc-stepper [isDataFormInvalid]="isUserFormValid">
            <!-- Basic info -->
            <cdk-step>
              <lib-step1
                [modalArea] = "modalArea"
                [parentForm]="addConsumerForm"
                (isDataFormInvalid)="userDataFormValid($event)">
              </lib-step1>
            </cdk-step>
            <!-- Extended info -->
            <cdk-step>
              <lib-step2
                [modalArea] = "modalArea"
                [customAttributesFromDb]="customAttributesFromDb"
                [parentForm]="addConsumerForm"
                (isDataFormInvalid)="userDataFormValid($event)">
              </lib-step2>
            </cdk-step>
            <!-- Summary -->
            <cdk-step>
              <lib-step3 [parentForm]="addConsumerForm"
                         [customAttributes]="customAttributesFromDb"
                         (createUserBtnType)="saveBtnType($event)"></lib-step3>
            </cdk-step>
          </lib-mc-stepper>
        </form>
      </div><!--Container /end-->
    </div><!--Wrapper /end-->
  </div><!--Modal body /end-->
</ng-template>

<a class="mc-create-new-user-btn" id="newConsumerModalButton" (click)="openConsumerModal(addNewConsumer)">
  <i class="fas fa-plus-circle mc-add-new-user-icon"></i>
  {{'cc.consumers.view.create-new-consumer' | translate}}
</a>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
