import {Inject, Injectable} from '@angular/core';
import {McBillingServiceUnitTypeServiceGENERATED} from '../_generated/mc-billing-service-unit-type-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McBillingServiceUnitTypeService extends McBillingServiceUnitTypeServiceGENERATED {

}
