<div class="col-md-12 mc-stepper-header-title" *ngIf="modalArea === 'createUser'">
  <span class="pl-4 pb-1 mr-2" [ngClass]="generalDataTab ? 'mc-active': ''">
    <span class="mc-notification-badge-gray" [ngClass]="generalDataTab ? 'mc-active-background': ''">1</span>
    {{'cc.users.view.general-data' | translate}}
  </span>
  <span class="pl-4 pb-1 ml-4 mr-2">
  <span class="mc-notification-badge-gray">2</span>
    {{ 'cc.common.admin.roles' | translate }}
  </span>
</div>

<div class="row" [ngClass]="modalArea === 'createUser' ? 'mt-5' : '' ">
  <div [formGroup]="stepForm" class="col-md-12">

    <div class="col-md-6 offset-md-3" *ngIf="modalArea === 'createUser'">
      <div class="form-group mc-user-email">
        <label for="userEmail" class="mc-new-user-field-names">{{ 'cc.common.edit.email' | translate }}</label>
        <input
          type="email"
          class="form-control"
          id="userEmail"
          aria-describedby="email"
          formControlName="email"
          [ngClass]="isEmailNotValid ? 'mc-error-field' : '' "
          (ngModelChange)="checkUserByEmail()">
      </div>
    </div>

    <div class="col-md-6 offset-md-3" *ngIf="modalArea === 'createUser'">
      <div class="form-group mc-add-user-information">
        <label class="custom-control fill-checkbox">
          <input
            id="NewUserInformation"
            type="checkbox"
            class="fill-control-input"
            [attr.disabled]="isAddUserInfo"
            [checked]="isAddUserInfoChecked"
            (change)="toggleAdditionalUserInfo($event)"
            #addUserInfoCheckbox />
          <span class="fill-control-indicator"></span>
          <span class="fill-control-description">{{'cc.users.edit.add-user-information-now' | translate}}</span>
        </label>
      </div>
    </div>

    <div class="mc-create-user-notify-wrapper">
      <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
    </div>

    <div class="mc-user-general-data-wrapper">
      <div class="row pt-5 mc-user-information" [ngClass]="showAdditionalUserInfo ? 'mc-show-user-info' : ''">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mc-user-first-name">
                <label for="NewUserFirstName" class="mc-new-user-field-names" [ngClass]="mandatoryFieldsDisabled ? 'mc-no-mandatory' : ''">{{ 'cc.common.edit.first-name' | translate }}</label>
                <input type="text"
                       class="form-control"
                       id="NewUserFirstName"
                       formControlName="firstName"
                       aria-describedby="firstName"
                       [ngClass]="firstnameNotValid ? 'mc-error-field' : '' "
                       [readOnly]="mandatoryFieldsDisabled"
                       (input)="checkMandatoryInputFields('firstName')"
                       (click)="clearInfoMsg()">
              </div>
              <div class="form-group mc-user-last-name">
                <label for="NewUserLastName" class="mc-new-user-field-names" [ngClass]="mandatoryFieldsDisabled ? 'mc-no-mandatory' : ''">{{ 'cc.common.edit.last-name' | translate }}</label>
                <input type="text"
                       class="form-control"
                        id="NewUserLastName"
                        formControlName="lastName"
                        aria-describedby="lastName"
                       [ngClass]="lastnameNotValid ? 'mc-error-field' : '' "
                       [readOnly]="mandatoryFieldsDisabled"
                       (input)="checkMandatoryInputFields('lastName')"
                       (click)="clearInfoMsg()">
              </div>
              <div class="form-group mc-user-nickname">
                <label for="NewUserNickname" class="mc-new-user-field-names">Nickname</label>
                <input
                  type="text"
                  class="form-control"
                  id="NewUserNickname"
                  formControlName="nickname"
                  aria-describedby="nickname"
                  [readOnly]="mandatoryFieldsDisabled">
              </div>

              <div class="form-check mc-user-gender">
                <div class="mc-new-user-field-names" [ngClass]="mandatoryFieldsDisabled ? 'mc-no-mandatory' : ''">{{ 'cc.common.edit.gender' | translate }}</div>

                <input
                  class="form-check-input"
                  type="radio"
                  name="gender"
                  id="genderMail"
                  value="MALE"
                  formControlName="gender"
                  [attr.disabled]="mandatoryFieldsDisabled"
                  (change)="checkMandatoryInputFields('gender')">
                <label class="form-check-label" for="genderMail">{{ 'cc.common.edit.male' | translate }}</label>

                <input
                  class="form-check-input"
                  type="radio"
                  name="gender"
                  id="genderFemale"
                  value="FEMALE"
                  formControlName="gender"
                  [attr.disabled]="mandatoryFieldsDisabled"
                  (change)="checkMandatoryInputFields('gender')">
                <label class="form-check-label" for="genderFemale">{{ 'cc.common.edit.female' | translate }}</label>

                <input
                  class="form-check-input"
                  type="radio"
                  name="gender"
                  id="genderOther"
                  value="OTHER"
                  formControlName="gender"
                  [attr.disabled]="mandatoryFieldsDisabled"
                  (change)="checkMandatoryInputFields('gender')">
                <label class="form-check-label" for="genderOther">{{ 'cc.common.edit.prefer-not-to-say' | translate }}</label>

              </div>
            </div>
            <div class="col-md-6">
              <!--SelectBox languages-->
              <div class="form-group">
                <label class="mc-new-user-field-names" for="userLanguage">{{'cc.users.edit.language' | translate}}</label>
                <select *ngIf="languages" class="form-control" id="userLanguage" formControlName="language">
                  <option *ngFor="let language of languages" [value]="language.value">{{language.name}}</option>
                </select>
              </div>
              <!--SelectBox timezones-->
              <div class="form-group">
                <label class="mc-new-user-field-names" for="userTimezone">{{'cc.users.edit.timezone' | translate}}</label>
                <select *ngIf="timezones" class="form-control" id="userTimezone" formControlName="timezone">
                  <option *ngFor="let timezone of timezones" [value]="timezone">{{timezone}}</option>
                </select>
              </div>
              <!--SelectBox countries-->
              <div class="form-group">
                <label class="mc-new-user-field-names" for="userCountry">{{'cc.users.edit.country' | translate}}</label>
                <select *ngIf="countries" class="form-control" id="userCountry" formControlName="country">
                  <option *ngFor="let country of countries" [value]="country.value">{{country.name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="col-md-12 mc-new-user-image-area">
            <div class="mc-new-user-field-names">{{ 'cc.common.edit.image' | translate }}</div>
            <div class="img-thumbnail mc-new-user-image" [ngStyle]="{'background-image': 'url(' + userImage + ')'}" (click)="userFileUpload.click()"></div>
            <input type="file" name="myImage" accept="image/*" formControlName="image" (change)="onFileChanged($event)" #userFileUpload>
            <a class="mc-upload-image-btn mt-1" (click)="userFileUpload.click()">{{ 'cc.common.edit.upload-image' | translate }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5 mb-3 float-right" *ngIf="modalArea !== 'createUser'">
      <div class="col-md-12">
        <button id="userGeneralDataBtn" class="btn btn-primary mc-next-save-btn" type="button" [disabled]="lockSaveBtn" (click)="saveUserData()">
          {{'cc.common.view.save' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>

