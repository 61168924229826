/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {ZzPocGarageFilter} from '../models/zz-poc-garage-filter.model';

export class ZzPocGarageFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): ZzPocGarageFilter {
    console.error('MTCN-ERROR: Not Implemented: ZzPocGarageFilter::createNewInstance(). Add this method to final class and return new ZzPocGarageFilter();');
      throw new Error('Cannot ZzPocGarageFilter::createNewInstance(). Add this method to final class and return new ZzPocGarageFilter();');
  }

}
