import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  McCountry,
  McVatList,
  McVat,
  McBillingServiceCatalogList,
  McEntity2Filter,
  McBillingService2VatService,
  McBillingService2VatList,
} from "@miticon-ui/mc-core";
import {
  McForm,
  McBoolean,
  McGod,
  McCountriesData,
  McTrigger,
  McValueLabelList,
} from "@miticon-ui/mc-core";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";

@Component({
  selector: "mc-country-country-add-edit-part",
  templateUrl: "./mc-country-country-add-edit-part.component.html",
})
export class McCountryCountryAddEditPartComponent implements OnInit, OnDestroy {
  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  loadingFlg!: boolean;

  saveSubscription!: Subscription;
  deleteSubscription!: Subscription;
  cancelSubscription!: Subscription;
  errorMsgs: string[] = [];
  mcCountriesData = new McCountriesData();
  countryVll = new McValueLabelList();
  vatItemList = new McVatList();
  showRemoveVatDialogMcb = new McBoolean();
  removeVatItemDialogButtonsKvm = new Map<string, string>();
  vatIndexForRemoving!: number;
  servicesVll = new McValueLabelList();
  serviceList = new McBillingServiceCatalogList();
  service = new McBillingService2VatService();
  errorMsg = "";
  servicesLoadedFlg = false;
  countryLoadedFlg = false;

  @Input() mcCountryId!: number;
  @Input() mcCountry!: McCountry;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;

  @Input() saveTrigger!: McTrigger;
  @Input() cancelTrigger!: McTrigger;
  @Input() deleteTrigger!: McTrigger;

  @Output() eventSaved = new EventEmitter<string>();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  // ---------------------------------------------------------------------
  subscribeToTriggers() {
    // -- triggers --
    if (this.saveTrigger) {
      this.saveSubscription = this.saveTrigger.subscribe(() => {
        this.actSave();
      });
    }
    if (this.cancelTrigger) {
      this.cancelSubscription = this.cancelTrigger.subscribe(() => {
        this.actCancel();
      });
    }
    if (this.deleteTrigger) {
      this.deleteSubscription = this.deleteTrigger.subscribe(() => {
        this.actDeleteItem();
      });
    }
  }

  // ---------------------------------------------------------------------
  unsubscribeFromTriggers() {
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    if (!this.mcCountry) {
      this.mcCountry = new McCountry();
    }

    this.serviceList.loadAll(
      () => {
        this.servicesVll = this.serviceList.toVll("id", "title");
        this.servicesLoadedFlg = true;
        this.loadingFlg = false;
      },
      () => {
        this.errorMsg = this.serviceList.apiErrorMessage;
      }
    );
    this.countryVll = this.mcCountriesData.getCountryVll();
    this.removeVatItemDialogButtonsKvm.set(
      "b btn-primary",
      McGod.t("cc.common.edit.yes")
    ); // Component works this way, value is also button css class
    this.removeVatItemDialogButtonsKvm.set(
      "a btn-secondary",
      McGod.t("cc.common.edit.no")
    );

    this.actLoad();
    this.subscribeToTriggers();
  }

  // ---------------------------------------------------------------------
  /*  ngOnChanges(changes: SimpleChanges): void {
      if (changes.mcCountryId) {
        if ((changes.mcCountryId.previousValue !== changes.mcCountryId.currentValue) && (changes.mcCountryId.currentValue > 0)) {

        }
      }
    } */

  // ---------------------------------------------------------------------
  ngOnDestroy(): void {
    this.unsubscribeFromTriggers();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    if (this.mcCountryId > 0) {
      this.mcCountry.loadById(
        this.mcCountryId,
        () => {
          this.vatItemList = this.mcCountry.mcVATItemList;
          this.countryLoadedFlg = true;
        },
        () => {
          this.errorMsg = this.mcCountry.apiErrorMessage;
        }
      );
      const filter = new McEntity2Filter();
      filter.countryId = this.mcCountryId;
    } else if (!this.mcCountry.exists()) {
      this.mcCountry = new McCountry();
      this.vatItemList = new McVatList();
      this.countryLoadedFlg = true;
    } else {
      this.vatItemList = this.mcCountry.mcVATItemList;
      this.countryLoadedFlg = true;
    }
    // new item
    this.validatePrerequisites();
  }

  // ---------------------------------------------------------------------
  actSave() {
    if (this.mcForm.isValid()) {
      if (this.vatItemList.getCount() > 0) {
        if (!this.mcCountry.exists() || this.mcCountry.numberOfEntities === 0) {
          this.mcCountry.name = this.mcForm.getValue("ctrlCountry");
        }

        this.mcCountry.mcVATItemList = new McVatList();

        this.mcCountry.save(
          () => {
            this.vatItemList.items.forEach((item) => {
              item.idMcCountry = this.mcCountry.id;
              const billingServiceList = item.mcBillingService2VATItemList;
              item.save(
                () => {
                  item.mcBillingService2VATItemList = billingServiceList;
                  item.mcBillingService2VATItemList.apiService
                    .deleteByVatId(item.id)
                    .subscribe(() => {
                      item.mcBillingService2VATItemList.items.forEach(
                        (itemService) => {
                          itemService.idVat = item.id;
                          itemService.save();
                        }
                      );
                    });
                },
                () => {}
              );
            });

            this.eventSaved.emit(this.mcCountry.name);
          },
          () => {
            this.saveButtonDisabledMcb.setFalse();
            this.errorMsg = this.mcCountry.apiErrorMessage;
          }
        );
      } else {
        // NO VAT ADDED
        this.errorMsg = McGod.t("cc.countries.you-must-add-at-least-one-vat");
        this.saveButtonDisabledMcb.setFalse();
      }
    } else {
      this.saveButtonDisabledMcb.setFalse();
    }
  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.mcCountry.exists()) {
      this.mcCountry.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

  // ---------------------------------------------------------------------
  validatePrerequisites() {
    this.errorMsgs = [];
    // populate validation here on required inputs
  }

  // ---------------------------------------------------------------------
  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }

  actAddVat() {
    const vatItem = new McVat();
    this.vatItemList.addItem(vatItem);
  }

  removeVatFromList(index: number) {
    this.vatIndexForRemoving = index;
    this.showRemoveVatDialogMcb.setTrue();
  }

  onMcDialogBoxRemoveVatItemActionButtonClicked(event: string) {
    if (event === "b btn-primary") {
      // Component works this way, value is also button css class
      this.vatItemList.items.splice(this.vatIndexForRemoving, 1);
      this.showRemoveVatDialogMcb.setFalse();
    } else {
      this.showRemoveVatDialogMcb.setFalse();
    }
  }
}
