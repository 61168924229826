/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McEntityConsumerAttributeFilter} from '../models/mc-entity-consumer-attribute-filter.model';

export class McEntityConsumerAttributeFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McEntityConsumerAttributeFilter {
    console.error('MTCN-ERROR: Not Implemented: McEntityConsumerAttributeFilter::createNewInstance(). Add this method to final class and return new McEntityConsumerAttributeFilter();');
      throw new Error('Cannot McEntityConsumerAttributeFilter::createNewInstance(). Add this method to final class and return new McEntityConsumerAttributeFilter();');
  }

}
