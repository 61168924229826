import {McMappingFilterGENERATED} from '../_generated/mc-mapping-filter-generated.model';

export class McMappingFilter extends McMappingFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McMappingFilter();
  }

  // ---------------------------------------------------------------------
}
