import {Component, OnInit} from '@angular/core';
import {McAuthService} from '@miticon-ui/mc-core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-mc-back-to-login',
  template: `<div class="mc-go-back" (click)="goToLogin()"><p class="m-0">< back to login</p></div>`
})
export class McBackToLoginComponent implements OnInit {

  // Constructor
  constructor(private authService: McAuthService) {
  }

  // On init class
  ngOnInit() {
  }

  // Go to login page
  goToLogin() {
    this.authService.logout();
  }

}
