/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CsrContract2ProductFilter} from '../models/csr-contract-2-product-filter.model';

export class CsrContract2ProductFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrContract2ProductFilter {
    console.error('MTCN-ERROR: Not Implemented: CsrContract2ProductFilter::createNewInstance(). Add this method to final class and return new CsrContract2ProductFilter();');
      throw new Error('Cannot CsrContract2ProductFilter::createNewInstance(). Add this method to final class and return new CsrContract2ProductFilter();');
  }

}
