/*BbmTestObj*/
import {McBillingEntitiesUsingPricelistGENERATED} from '../_generated/mc-billing-entities-using-pricelist-generated.model';
import {McDateUtils} from '../common/mc-date-utils';

export class McBillingEntitiesUsingPricelist extends McBillingEntitiesUsingPricelistGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingEntitiesUsingPricelist();
  }

  public getFromDate() {
    return McDateUtils.newFormatDateTimeString(this.startDate);
  }

  // ---------------------------------------------------------------------
}
