/*BbmTestObj*/
import {McProcessLevelGENERATED} from '../_generated/mc-process-level-generated.model';
import {McGod} from '../extra/mc-god.service';
import {McHtml} from '../_core/mc-html';
import {ActionOptions} from '../_core/mc-html-action-options';

export class McProcessLevel extends McProcessLevelGENERATED {

  public static ACTION_SHOW_EDIT = 'EDIT';
  public static ACTION_SHOW_PROFILE = 'PROFILE';

  private _fldActions: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessLevel();
  }

  // ---------------------------------------------------------------------

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> View level`, McProcessLevel.ACTION_SHOW_PROFILE, this, [McGod.PERM_MC_CONSUMER_VIEW], '', ActionOptions.OPTION_VIEW);
      actSet.addActionB(`<i class="fas mc-submit-color fa-pen mc-cursor-pointer"></i> Edit level`, McProcessLevel.ACTION_SHOW_EDIT, this, [McGod.PERM_MC_CONSUMER_VIEW], ``, ActionOptions.OPTION_EDIT);
      this._fldActions = html;
    }
    return this._fldActions.items;
  }
}
