import {Component, OnInit} from '@angular/core';
import {
  EbSepaFileTransaction,
  McBillingItem, McBillingItemFilter,
  McBillingItemList,
  McBillingItemTable3ConfigWithoutFldActionsModel,
  McEntity2, McForm,
  McGod, McValueLabelList
} from '@miticon-ui/mc-core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-shared-transaction-billing-items',
  templateUrl: './app-shared-transaction-billing-items.component.html',
})

export class AppSharedTransactionBillingItemsComponent implements OnInit {

  ebSepaFileTransaction = new EbSepaFileTransaction();
  ebSepaFileTransactionId!: number;
  mcEntity = new McEntity2();
  successMsg = '';
  errorMsg = '';
  rejectedStatusCd = EbSepaFileTransaction.STATUS_CD_REJECTED;
  isTransactionUnpaused = false;
  billingItemList = new McBillingItemList();
  billingItemTable3Config = new McBillingItemTable3ConfigWithoutFldActionsModel();
  loadingFlg = false;
  mcGod = McGod.getInstance();
  mcForm = new McForm();
  statusCds: any[] = [];
  statusCdVll!: McValueLabelList;
  amountVll!: McValueLabelList;
  clearedAllMultipleFilters = new Subject<boolean>();
  minAmount!: number;
  maxAmount!: number;
  clearDateRangeSubject = new Subject<boolean>();
  activityDateFromInput: any;
  activityDateToInput: any;
  mcBillingItemFilter = new McBillingItemFilter();

  // ---------------------------------------------------------------------
  constructor(private route: ActivatedRoute, private router: Router, private _location: Location) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.billingItemList.setPager(0, 100);
    this.loadingFlg = true;
    if (!this.mcGod.userHasEbFactoringMyTransactionBillingItemsViewPermission() && !this.mcGod.userHasEbFactoringOutTransactionBillingItemsViewPermission()) {
      this.router.navigateByUrl('');
    } else {
      this.route.paramMap.subscribe(params => {
          // this.ebSepaFileTransactionId = +params?.get('id');
          this.actLoad();
          this.loadingFlg = false;
      });
    }
    this.statusCdVll = new McValueLabelList();
    this.statusCdVll = McBillingItem.getStatusCdVll();
  }

  public actLoad(): void {
      this.ebSepaFileTransaction.loadById(this.ebSepaFileTransactionId, () => {
        if (McGod.getLoggedEntityIdFromToken() === this.ebSepaFileTransaction.idMcEntity && !this.mcGod.userHasEbFactoringMyTransactionBillingItemsViewPermission() ||
          McGod.getLoggedEntityIdFromToken() === this.ebSepaFileTransaction.idFactoringEntity && !this.mcGod.userHasEbFactoringOutTransactionBillingItemsViewPermission() ||
          (McGod.getLoggedEntityIdFromToken() !== this.ebSepaFileTransaction.idMcEntity && McGod.getLoggedEntityIdFromToken() !== this.ebSepaFileTransaction.idFactoringEntity)) {
          this.router.navigateByUrl('');
        }
        this.mcEntity.loadById(this.ebSepaFileTransaction.idMcEntity);
        this.mcBillingItemFilter.statusCds = this.statusCds;
        this.mcBillingItemFilter.amountFrom = this.minAmount;
        this.mcBillingItemFilter.amountTo = this.maxAmount;
        this.mcBillingItemFilter.flgPayoutOrBilling = false;
        this.mcBillingItemFilter.idTransaction = this.ebSepaFileTransactionId;
        this.mcBillingItemFilter.refId = this.mcForm.getValue('txbSearchTerm');
        this.mcBillingItemFilter.dateFrom = this.activityDateFromInput ? this.activityDateFromInput : null;
        this.mcBillingItemFilter.dateTo = this.activityDateToInput ? this.activityDateToInput : null;
        this.billingItemList.loadByFilter(this.mcBillingItemFilter, () => {
          this.getTotalAmountVll();
        });
        // this.billingItemList.loadBillingItemsByTransactionId(this.ebSepaFileTransactionId);
      });
  }

  getMaxPauseDaysLbl() {
    if (!this.isTransactionUnpaused && this.ebSepaFileTransaction.maxPauseDays) {
      return this.ebSepaFileTransaction.maxPauseDays;
    } else {
      return this.mcEntity.maxTransactionPauseDays;
    }
  }

  goToPrevious() {
    this._location.back();
  }

  actClearFilter() {
    this.clearedAllMultipleFilters.next(true);
    this.clearDateRangeSubject.next(true);
    if (this.mcForm.getControl('ctrlActivityDateFrom')) {
      this.mcForm.getControl('ctrlActivityDateFrom')?.get('value')?.setValue(null);
    }
    if (this.mcForm.getControl('ctrlActivityDateTo')) {
      this.mcForm.getControl('ctrlActivityDateTo')?.get('value')?.setValue(null);
    }
    this.actLoad();
  }

  onSelectStatusCds(value: any[]) {
    this.statusCds = value;
  }

  onSelectAmount(value: any[]) {
    const minAmounts: any[] = [];
    const maxAmounts: any[] = [];
    value.forEach(item => {
      minAmounts.push(item[0]);
      maxAmounts.push(item[1]);
    });
    this.minAmount = Math.min(...minAmounts);
    this.maxAmount = Math.max(...maxAmounts);
  }

  onActivityDateFrom(value: any) {
    if (value !== null) {
      this.activityDateFromInput = value;
    } else {
      this.activityDateFromInput = null;
    }
  }

  onActivityDateTo(value: any) {
    if (value !== null) {
      this.activityDateToInput = value;
    } else {
      this.activityDateToInput = null;
    }
  }

  getTotalAmountVll() {
    this.amountVll = new McValueLabelList();
    this.amountVll.add(['0', '499.99'], '0 - 499,99 €');
    this.amountVll.add(['500', '999.99'], '500,00 € - 999,99 €');
    this.amountVll.add(['1000', '1999.99'], '1000,00 € - 1999,99 €');
    this.amountVll.add(['2000', '4999.99'], '2000,00 € - 4999,99 €');
    this.amountVll.add(['5000', '9999.99'], '5000,00 € - 9999,99 €');
    this.amountVll.add(['10000', null], 'Over 10000,00 €');
    const result = this.amountVll.items.sort((a, b) => {
      return a.value[0] - b.value[0];
    });
    this.amountVll.items = result;
    return this.amountVll;
  }
}
