/*BbmTestObj*/
import {McEntityGENERATED} from '../_generated/mc-entity-generated.model';

export class McEntity extends McEntityGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McEntity();
  }

  // ---------------------------------------------------------------------
}
