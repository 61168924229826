import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  McRoleService,
  McEntityService,
  McUtilityService,
  McGod,
  McBoolean,
  Link,
} from "@miticon-ui/mc-core";
import { of, Subscription } from "rxjs";
import { concatMap, tap } from "rxjs/operators";

const enum LinkDialogAction {
  ACT_CANCEL = "btn-secondary",
  ACT_Ok = "btn-primary",
}

@Component({
  selector: "mc-role-edit-wrapper",
  templateUrl: "./mc-role-edit-wrapper.component.html",
})
export class McRoleEditWrapperComponent implements OnInit, OnDestroy {
  public roleInfo!: any;
  public entityName!: string;
  public setAsATemplate!: boolean;
  public notifyObject = {
    type: "",
    message: "",
  };
  public message!: string;
  public type!: string;
  public roleTemplateName!: string;
  public entityOriginName!: string;
  /*Private variables*/
  private roleId!: any;
  private selectedPermissions = [];
  public ancestorId!: number;

  /*Subscriptions*/
  private paramsSubscription!: any;
  private selectedEntityPathSubscription!: any;
  private getEntityRoleApiCallSubscription!: any;
  public mcGod = McGod.getInstance();
  public showLinkUnlinkDialogMcb = new McBoolean();
  public linkDialogButtonsKvm = new Map<string, string>();
  public entityAncestorRoleSubscription!: Subscription;
  public showLoader!: boolean;

  /*Constructor*/
  constructor(
    private activatedRoute: ActivatedRoute,
    private entityService: McEntityService,
    private utilityService: McUtilityService,
    private roleService: McRoleService
  ) {}

  /*On init class*/
  ngOnInit() {
    /*Params subscription*/

    this.paramsSubscription = this.activatedRoute.paramMap.subscribe((data) => {
      if (data.get("id")) {
        this.roleId = data.get("id");
        this.getRole();
      }
    });

    /*Get selected entity name from service*/
    this.selectedEntityPathSubscription =
      this.entityService._selectedEntityPath.subscribe((path) => {
        this.entityName = path[path.length - 1];
      });

    this.linkDialogButtonsKvm.set("btn-primary", "Ok");
    this.linkDialogButtonsKvm.set(
      "btn-secondary",
      this.mcGod.t("cc.common.edit.cancel")
    );
  }

  /*Get role info*/
  public getRole(): void {
    if (this.roleId) {
      this.getEntityRoleApiCallSubscription = this.roleService
        .getEntityRoleById(this.roleId)
        .pipe(
          tap((data) => {
            if (data) {
              // @ts-ignore
              this.ancestorId = data["ancestorId"];
              this.roleInfo = data;
            }
          }),
          concatMap((res) => {
            if (this.ancestorId) {
              return this.roleService.getEntityRoleTemplate(this.ancestorId);
            }
            return of(null);
          })
        )
        .subscribe(
          (response) => {
            if (response) {
              // @ts-ignore
              this.entityOriginName = response["entity"].name;
              // @ts-ignore
              this.roleTemplateName = response["caption"];
              this.roleTemplateName = this.roleTemplateName.replace(
                /^/,
                "Template "
              );
            }
          },
          (error) => {
            if (error) {
              this.showLoader = false;
            }
          }
        );
    }
  }

  /*On destroy class*/
  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
    this.selectedEntityPathSubscription.unsubscribe();
    this.getEntityRoleApiCallSubscription.unsubscribe();
    if (this.entityAncestorRoleSubscription) {
      this.entityAncestorRoleSubscription.unsubscribe();
    }
  }

  getLinkStatus() {
    // @ts-ignore
    if (this.roleInfo["linkedFlg"]) {
      return "LINKED";
    } else {
      return "UNLINKED";
    }
  }

  getLinkDialogDescription() {
    // @ts-ignore
    return `${this.mcGod.t("cc.roles.this-action-will")} ${
      this.roleInfo["linkedFlg"]
        ? this.mcGod.t("cc.roles.unlink")
        : this.mcGod.t("cc.roles.link")
    }
     ${this.mcGod.t(
       "cc.roles.the-role-from-template-origin-the-role-will-become-independent-from-the-changes-to-original-template"
     )}.`;
  }

  onMcDialogBoxLinkRoleActionButtonClicked(action: string) {
    if (action === LinkDialogAction.ACT_CANCEL) {
      this.showLinkUnlinkDialogMcb.setFalse();
    } else {
      // @ts-ignore
      const linkedFlg = !this.roleInfo["linkedFlg"];
      // @ts-ignore
      const id = this.roleInfo["id"];
      this.roleService.updateEntityRoleLinkedFlg(id, linkedFlg).subscribe(
        (res) => {
          // @ts-ignore
          if (res["linkedFlg"]) {
            this.message = "Role linked successfully";
            this.type = "success";
          } else {
            this.message = "Role unlinked successfully";
            this.type = "success";
          }
          this.showLinkUnlinkDialogMcb.setFalse();
          this.getRole();
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  linkUnlinkRole() {
    this.showLinkUnlinkDialogMcb.setTrue();
  }
}
