<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="statementItems.apiLoadingFlg || loadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h2 *ngIf="!isPartOfAssignToStatementStepper">Aggregate</h2>
    <h2 *ngIf="isPartOfAssignToStatementStepper">Assign to statement</h2>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!statementItems.apiLoadingFlg" class="content-body">
    <mcc-message [errorMsg]="errorMsg"></mcc-message>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container *ngIf="(statementItems.apiSuccessFlg)">

      <div class="row">
        <mcc-fi-radio-buttons2 label="Aggregate"
                               name="ctrlAggregate"
                               [mcForm]="mcForm"
                               [validRequiredFlg]="true"
                               [directionHorizontal]="false"
                               [value]="false"
                               [optionsVll]="aggregateYesNoVll"></mcc-fi-radio-buttons2>
      </div>

      <div [ngClass]="{'mc-disable' : !mcForm.getValue('ctrlAggregate')}">
        <div class="row">
          <button class="btn btn-outline-primary ml-auto" (click)="actShowAddAggregation()"><i
            class="fas fa-plus-circle"></i> Add Aggregation
          </button>
        </div>

        <div class="row">
          <mcc-fi-radio-buttons2 label="Aggregation"
                                 name="ctrlAggregation"
                                 [mcForm]="mcForm"
                                 [validRequiredFlg]="mcForm.getValue('ctrlAggregate')"
                                 [directionHorizontal]="false"
                                 [optionsVll]="statementItemVll"></mcc-fi-radio-buttons2>
        </div>
      </div>

      <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showAddAggregationMcb" [showMcb]="showAddAggregationMcb"
                          (eventCanceled)="actAddAggregationCanceled()">
        <mc-out-billing-aggregation-add-edit-part [statementId]="mcBillingItem.mcBillingStatementId" (eventSaved)="actAddAggregationSaved()"
                                                  (eventCanceled)="actAddAggregationCanceled()"></mc-out-billing-aggregation-add-edit-part>
      </mcc-modal1-wrapper>

    </ng-container>

  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <div class="content-footer">

    <mcc-button *ngIf="isPartOfAssignToStatementStepper" extraCssClasses="btn btn-secondary" (eventClicked)="actPrev();"
                label="<i class='fas fa-arrow-left'></i> Prev"></mcc-button>

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="Cancel"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actSave();" label="{{isPartOfAssignToStatementStepper ? 'Assign' : 'Aggregate'}}"
                [disabledMcb]="disableAggregateBtnMcb"
                disableOnFirstClickFlg="true"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
