/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EbSepaFileTransactionTable3ConfigGENERATED} from '../_generated/eb-sepa-file-transaction-table-3-config-generated.model';

// import {EbSepaFileTransactionGENERATED} from '../_generated/eb-sepa-file-transaction-generated.model';

export class EbSepaFileConsumerTransactionTable3Config extends EbSepaFileTransactionTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.addColumn('colActions', '', 'fldActionsConsumerTransaction()', '');
    this.addColumn('colConsumer', this.t('cc.consumers.view.consumer-name'), 'consumerName', '');
    this.addColumn('colDescription', this.t('cc.common.view.description'), 'rmtinfUstrd', 'rmtinfUstrd').extraCssClass = 'mc-factoring-transactions-table-description-column';
    // this.addColumn('colServicePeriod', this.t('cc.invoice.service-period'), '', 'sepa.servicePeriod');
    this.addColumn('colPaymentDueDate', 'Payment due date', 'getDueDateTime()', 'dueDatetime');
    this.addColumn('colRequestCollectionDate', 'Request collection date', 'getRequestCollectionDate()', 'requestedCollectionDate');
    this.addColumn('colAmount', this.t('cc.table.amount-due'), 'amountStr', '').extraCssClass = 'text-right';
    this.addColumn('colStatus', this.t('cc.common.view.status'), 'getIconLabel()', 'statusCd');
  }
}
