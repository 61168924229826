import {McBillingEntitiesUsingPricelistFilterGENERATED} from '../_generated/mc-billing-entities-using-pricelist-filter-generated.model';

export class McBillingEntitiesUsingPricelistFilter extends McBillingEntitiesUsingPricelistFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingEntitiesUsingPricelistFilter();
  }

  // ---------------------------------------------------------------------
}
