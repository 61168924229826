
import {Table3Config} from '../extra/table-3-config';

// import {McVatGENERATED} from '../_generated/mc-vat-generated.model';

export class McVatTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colEndDate', 'End Date', 'endDate', 'endDate');
    this.addColumn('colIdMcCountry', 'Country Id', 'idMcCountry', 'idMcCountry');
    this.addColumn('colMcCountry', 'Country', 'mcCountry', 'mcCountry');
    this.addColumn('colStartDate', 'Start Date', 'startDate', 'startDate');
    this.addColumn('colVatDescription', 'VAT Description', 'vatDescription', 'vatDescription');
    this.addColumn('colVatRate', 'VAT rate', 'vatRate', 'vatRate');
    this.addColumn('colVatTypeCd', 'VAT Type Cd', 'vatTypeCd', 'vatTypeCd');

*/
  }
}
