<div class="mcc-fi {{extraCssClasses}} mc-price-adjustment-wrapper">
  <div *ngIf="!newStyleCurrency" class="d-flex" [ngClass]="labelOnTopFlg ? 'flex-column' : 'align-items-center'">

    <label *ngIf="label" for="mcc-fi-{{nameForValue}}" class="{{labelOnTopFlg ? '' : 'm-0'}}"
           [ngClass]="{'mc-mandatory-star' : validRequiredFlg}">{{label}}</label>
    <div>
      <div class="input-group d-flex align-items-center"
           [ngClass]="{'validation-error' : this.getTextboxForm().get('errorFlg')?.value}">
        <div class="d-flex">
          <!--Input field - amount value-->
          <form [formGroup]="getTextboxForm()">
            <input type="text"
                   size="9"
                   [min]="validMinNumber"
                   [max]="validMaxNumber"
                   [ngClass]="{'ml-2' : !labelOnTopFlg}"
                   class="mc-text-align-right"
                   id="mcc-fi-{{nameForValue}}"
                   name="txb-{{nameForValue}}"
                   formControlName="value"
                   [placeholder]="placeholder"
                   [required]="validRequiredFlg"
                   [readOnly]="readOnlyFlg"
                   (keyup.enter)="enterClicked()"
                   (ngModelChange)="onTextBoxChanged($event)">
          </form>

          <!--Select option-->
          <form [formGroup]="getSelectForm()">
            <div class="input-group-append h-100">
              <select class="custom-select border-left-0 mc-no-focus mc-select-blue h-100" formControlName="value"
                      (change)="onSelectChanged($event)">
                <option [ngClass]="{'mc-not-display' : disablePercentage}" value="percentage" class="bg-light c">%</option>
                <ng-container *ngIf="!disableMoneyValue">
                  <ng-container *ngFor="let currency of currencies">
                    <option [ngClass]="{'mc-not-display' : disableCurrency(currency.code)}" [value]="currency.code" class="bg-light" [selected]="selectCurrency(currency.code)">{{currency.code}}</option>
                  </ng-container>
                </ng-container>
              </select>
            </div>
          </form>
        </div>
        <span class="pl-2" *ngIf="additionalDescription">{{additionalDescription}}</span>
      </div>
    </div>
  </div>

  <form [formGroup]="getTextboxForm()" *ngIf="newStyleCurrency" style="display: flex; gap: 15px;">
    <mat-form-field style="width: 50%;" appearance="outline">
      <mat-label>{{ label }}</mat-label>
      <input type="text"
             size="9"
             [min]="validMinNumber"
             [max]="validMaxNumber"
             [ngClass]="{'ml-2' : !labelOnTopFlg}"
             class="mc-text-align-right"
             id="mcc-fi-{{nameForValue}}"
             name="txb-{{nameForValue}}"
             formControlName="value"
             [placeholder]="placeholder"
             [required]="validRequiredFlg"
             [readOnly]="readOnlyFlg"
             (keyup.enter)="enterClicked()"
             (ngModelChange)="onTextBoxChanged($event)"
             matInput>
    </mat-form-field>

    <mat-form-field style="width: 10%" appearance="outline">
      <mat-select panelClass="dialog-select"
                  formControlName="value"
                  (onSelectionChange)="onSelectChanged($event)"
                  disableOptionCentering>
        <mat-option [value]="'percentage'">%</mat-option>
        <mat-option *ngFor="let currency of currencies" (onSelectionChange)="selectCurrency(currency.code)" [value]="currency.code">
          {{ currency.code }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <div class="error-msg">{{this.getTextboxForm().get('errorMsg')?.value}}</div>
</div>
