/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingService2PriceListTable3ConfigGENERATED} from '../_generated/mc-billing-service-2-price-list-table-3-config-generated.model';

// import {McBillingService2PriceListGENERATED} from '../_generated/mc-billing-service-2-price-list-generated.model';

export class McBillingService2PriceListTable3Config extends McBillingService2PriceListTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colServiceName', this.t('cc.pricelist.service-name'), 'serviceName', 'serviceName');
    this.addColumn('colServiceDescription', this.t('cc.pricelist.service-description'), 'serviceDescription', 'serviceDescription');
    this.addColumn('colActivityUnit', this.t('cc.pricelist.activity-unit'), 'getActivityUnitFld()', 'activityUnitTypeCd');
    this.addColumn('chargeMode', this.t('cc.pricelist.charge-mode'), 'getChargeMode()', 'chargeMode');
    this.addColumn('startDate', this.t('cc.factoring.start-date'), 'getStartDate()', 'startDate');


  }
}
