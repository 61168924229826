/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBillingBatch} from '../models/mc-billing-batch.model';
import {McBillingBatchService} from '../services/mc-billing-batch.service';
import {IMcBillingBatch} from '../_generated/mc-billing-batch.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McBillingBatchGENERATED extends BaseObject {


    public static readonly TYPE_CD_API: string = 'API';
    public static readonly TYPE_CD_MANUAL: string = 'MANUAL';
    public static readonly TYPE_CD_IMPORT: string = 'IMPORT';
  public static readonly TYPE_CD_LIST__ALL = [
    McBillingBatchGENERATED.TYPE_CD_API,
    McBillingBatchGENERATED.TYPE_CD_MANUAL,
    McBillingBatchGENERATED.TYPE_CD_IMPORT
];

    public static readonly TYPE_OF_TRANSACTIONS_IN_BATCH_CD_COLLECT: string = 'COLLECT';
    public static readonly TYPE_OF_TRANSACTIONS_IN_BATCH_CD_DUNNING: string = 'DUNNING';
  public static readonly TYPE_OF_TRANSACTIONS_IN_BATCH_CD_LIST__ALL = [
    McBillingBatchGENERATED.TYPE_OF_TRANSACTIONS_IN_BATCH_CD_COLLECT,
    McBillingBatchGENERATED.TYPE_OF_TRANSACTIONS_IN_BATCH_CD_DUNNING
];

  public apiService: McBillingBatchService;
  public _rawJson: IMcBillingBatch;
    id: number = 0;
  dateFrom: number;
  dateTo: number;
  flgActive: boolean;
  flgFinalized: boolean;
  idChildEntity: number;
  idContract: number;
  idParentEntity: number;
  idSepaFile: number;
  sysCreatedDatetime: number;
  sysUpdatedDatetime: number;
  typeCd: string;
  typeOfTransactionsInBatchCd: string;


  public properties: string[] = ['id', 'dateFrom', 'dateTo', 'flgActive', 'flgFinalized', 'idChildEntity', 'idContract', 'idParentEntity', 'idSepaFile', 'sysCreatedDatetime', 'sysUpdatedDatetime', 'typeCd', 'typeOfTransactionsInBatchCd'];
  public propertiesRegular: string[] = ['id', 'dateFrom', 'dateTo', 'flgActive', 'flgFinalized', 'idChildEntity', 'idContract', 'idParentEntity', 'idSepaFile', 'mcFactoring', 'sysCreatedDatetime', 'sysUpdatedDatetime', 'typeCd', 'typeOfTransactionsInBatchCd'];
  public propertiesSpecial: string[] = [];



  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingBatchGENERATED.TYPE_CD_API, McGod.t('API'));
    list.add(McBillingBatchGENERATED.TYPE_CD_MANUAL, McGod.t('MANUAL'));
    list.add(McBillingBatchGENERATED.TYPE_CD_IMPORT, McGod.t('IMPORT'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = McBillingBatchGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeOfTransactionsInBatchCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingBatchGENERATED.TYPE_OF_TRANSACTIONS_IN_BATCH_CD_COLLECT, McGod.t('COLLECT'));
    list.add(McBillingBatchGENERATED.TYPE_OF_TRANSACTIONS_IN_BATCH_CD_DUNNING, McGod.t('DUNNING'));


    return list;
  }

  public static getTypeOfTransactionsInBatchCdLabel(aCd: string): string {
    const list = McBillingBatchGENERATED.getTypeOfTransactionsInBatchCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McBillingBatch {
    console.error('MTCN-ERROR: Not Implemented: McBillingBatch::createNewInstance(). Add this method to final class and return new McBillingBatch();');
    throw new Error('Cannot McBillingBatch::createNewInstance(). Add this method to final class and return new McBillingBatch();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBillingBatch): McBillingBatch {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingBatchService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBillingBatch AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getDateFromStrD(): string {
       return McDateUtils.formatDateToString(this.dateFrom);
    }

    public getDateFromStr(): string {
       return McDateUtils.formatDateTimeToString(this.dateFrom);
    }

    public getDateToStrD(): string {
       return McDateUtils.formatDateToString(this.dateTo);
    }

    public getDateToStr(): string {
       return McDateUtils.formatDateTimeToString(this.dateTo);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getTypeCdLabel(): string {
    return McBillingBatchGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdAPI(): boolean {
    const result = (this.typeCd === McBillingBatchGENERATED.TYPE_CD_API);

    return result;
  }

 public  isTypeCdMANUAL(): boolean {
    const result = (this.typeCd === McBillingBatchGENERATED.TYPE_CD_MANUAL);

    return result;
  }

 public  isTypeCdIMPORT(): boolean {
    const result = (this.typeCd === McBillingBatchGENERATED.TYPE_CD_IMPORT);

    return result;
  }


  public getTypeOfTransactionsInBatchCdLabel(): string {
    return McBillingBatchGENERATED.getTypeOfTransactionsInBatchCdLabel(this.typeOfTransactionsInBatchCd);
  }


 public  isTypeOfTransactionsInBatchCdCOLLECT(): boolean {
    const result = (this.typeOfTransactionsInBatchCd === McBillingBatchGENERATED.TYPE_OF_TRANSACTIONS_IN_BATCH_CD_COLLECT);

    return result;
  }

 public  isTypeOfTransactionsInBatchCdDUNNING(): boolean {
    const result = (this.typeOfTransactionsInBatchCd === McBillingBatchGENERATED.TYPE_OF_TRANSACTIONS_IN_BATCH_CD_DUNNING);

    return result;
  }


  
}
