/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McBillingPayoutFileFeCtdFilter} from '../models/mc-billing-payout-file-fe-ctd-filter.model';

export class McBillingPayoutFileFeCtdFilterGENERATED extends BaseObjectFilter{    public searchTerm: string | undefined;
    public typeCds: string[] = [];
    public payoutDateFrom: any | undefined;
    public payoutDateTo: any | undefined;
    public createdDateFrom: any | undefined;
    public createdDateTo: any | undefined;
    public createdBy: number[] = [];
    public bankAccountIds: number[] = [];

  public properties: string[] = ['searchTerm', 'typeCds', 'payoutDateFrom', 'payoutDateTo', 'createdDateFrom', 'createdDateTo', 'createdBy', 'bankAccountIds', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingPayoutFileFeCtdFilter {
    console.error('MTCN-ERROR: Not Implemented: McBillingPayoutFileFeCtdFilter::createNewInstance(). Add this method to final class and return new McBillingPayoutFileFeCtdFilter();');
      throw new Error('Cannot McBillingPayoutFileFeCtdFilter::createNewInstance(). Add this method to final class and return new McBillingPayoutFileFeCtdFilter();');
  }

}
