<button (click)="togglePopupMcFLg.setTrue()"> Open popup</button>

<!--MC Two FA section-->
<div class="container pt-5 pb-5" [formGroup]="fg">
  <h1 class="mb-5">Fi components</h1>

  <!--  <mcc-fi-password [validRequiredFlg]="true" label="PASSWORD" name="password" [placeholder]="'Enter password'"
                     [mcForm]="mcForm" [showConfirmPasswordFlg]="true" [passwordPolicy]="mcPasswordPolicy"
                     [showPasswordHintsFlg]="true"
                     confirmPasswordPlaceholder="Confirm password"></mcc-fi-password>-->
  <!--  showPasswordHintsFlg set to false to not show password hints-->

  <mcc-fi-number-type [mcForm]="mcForm" nameForValue="priceValue" label="Factored claims fees" nameForType="priceType"
                      [validRequiredFlg]="true" [labelOnTopFlg]="false"
                      [additionalDescription]="'month(s)'"></mcc-fi-number-type>
  <span>{{mcForm.getValue('priceValue')}}</span> <br>
  <span>{{mcForm.getValue('priceType')}}</span>

  <hr>

  <!-- <mcc-fi-radio-buttons2 label="Boolean Radio buttons"
                          name="yesNo"
                          [mcForm]="mcForm"
                          [validRequiredFlg]="true"
                          [value]="7"
                          (eventValueChanged)="onRadioButtonsChanged($event)"
                          [optionsVll]="diffTypesVll"></mcc-fi-radio-buttons2>

    <hr>
    <mcc-fi-itemlist-checkboxes
      label="CHECK ITEMLIST BOXES"
      *ngIf="factoringRequestTypeList.apiSuccessFlg"
      name="childCategories"
      (eventValueChanged)="onCheckboxChanged($event)"
      [mcForm]="mcForm"
      [itemList]="factoringRequestTypeList"
      [validRequiredFlg]="true"
      itemLabelPropertyName="title"
      itemValuePropertyName="id"
      itemDescriptionPropertyName="id"></mcc-fi-itemlist-checkboxes>
    <hr>-->

  <mcc-fi-textbox [name]="'lastName'" [label]="'Last Name'"
                  [placeholder]="'Enter Last Name'" [additionalDescription]="'month(s)'"
                  [validRequiredFlg]="textboxValidationFlg" [validWhitespaces]="true"
                  [mcForm]="mcForm" (eventValueChanged)="valueChanged($event)"></mcc-fi-textbox>
  <mcc-fi-textbox [name]="'y'" [label]="'y'"
                  [placeholder]="'y'" [validRequiredFlg]="textboxValidationFlg" [validWhitespaces]="true" [validPhone]="true"
                  [mcForm]="mcForm" (eventValueChanged)="valueChanged($event)"></mcc-fi-textbox>

  <button (click)="changeValidation()">Validation flag change</button>

  <hr>

  <!--TODO: bug this component adds control to radio buttons-->
  <!-- <mcc-fi-select [label]="'Job'"
                  [name]="'job'"
                  [mcForm]="mcForm"
                  [validRequiredFlg]="true"
                  [labelOnTop]="false"
                  [placeholderOption]="'ssdfas'"
                  value=""
                  [optionsVll]="optionsVllJob"></mcc-fi-select>

   <hr>

   <mcc-fi-checkbox [label]="'CheckBox'"
                    [name]="'check'"
                    [value]="false"
                    [mcForm]="mcForm"
                    [validRequiredFlg]="true"></mcc-fi-checkbox>

   <hr>
   <mcc-fi-date-picker
     [label]="'Datepicker label'"
     [name]="'contractStartDate'"
     [validRequiredFlg]="true"
     [allowManualEntryFlg]="true"
     [mcForm]="mcForm">
   </mcc-fi-date-picker>
   <hr> -->
  <mcc-fi-textarea [name]="'Description'" [label]="'Description'"
                   [placeholder]="'Enter Description'" [validRequiredFlg]="true"
                   [mcForm]="mcForm" (eventValueChanged)="valueChanged($event)">
  </mcc-fi-textarea>
  <hr>

  <mcc-mc-form-message [mcForm]="mcForm" messageType="error" messageText="Some validation error text"
                       [showInputLabelsInMessageFlg]="true"></mcc-mc-form-message>

  <mcc-fi-multiselect2 [mcForm]="mcForm" label="MULTISELECT TEST" [name]="'multiselect'" [valueVll]="emailTokenVllTEST" [optionVll]="emailTokenVll" [validRequiredFlg]="true"></mcc-fi-multiselect2>

  <button (click)="isMcFormValid()">Is form valid</button>

  <!--  <div class="col-md-12 p-0" *ngIf="mcConsumerList.apiSuccessFlg">
      <mcc-fi-table-4 [objectList]="mcConsumerList"
                      [table4Config]="mcConsumerTable3Config"></mcc-fi-table-4>
    </div>-->
  <hr>

  <mcc-fi-textarea-with-token [name]="'ctrlEmailToken'" [label]="'Text with tokens'" tokenLabel="Tokens"
                              [mcForm]="mcForm" [numCols]="45" [tokenVll]="emailTokenVll"></mcc-fi-textarea-with-token>
  <mcc-fi-textbox-with-token [name]="'ctrlEmailSubjectToken'" [label]="'subject with tokens'" tokenLabel="Tokens"
                             [mcForm]="mcForm" [size]="35" [tokenVll]="emailTokenVll"></mcc-fi-textbox-with-token>
  <hr>

  <mcc-message [successMsg]="successMsg" [errorMsg]="errorMsg"></mcc-message>

  <router-outlet></router-outlet>

</div>

