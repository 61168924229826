/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EbFactoringFilter} from '../models/eb-factoring-filter.model';

export class EbFactoringFilterGENERATED extends BaseObjectFilter{    public childEntityIds: number[] = [];
    public parentEntityId: number | undefined;
    public statusCds: string[] = [];
    public searchTerm: string | undefined;
    public outFactoringFlg: boolean | undefined;
    public contractType: string | undefined;

  public properties: string[] = ['childEntityIds', 'parentEntityId', 'statusCds', 'searchTerm', 'outFactoringFlg', 'contractType', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbFactoringFilter {
    console.error('MTCN-ERROR: Not Implemented: EbFactoringFilter::createNewInstance(). Add this method to final class and return new EbFactoringFilter();');
      throw new Error('Cannot EbFactoringFilter::createNewInstance(). Add this method to final class and return new EbFactoringFilter();');
  }

}
