import {Component, Inject, OnInit} from '@angular/core';
import {
  McProcessFlow,
  McProcessLevelTable3Config,
  McBoolean,
  McHtmlButton,
  McGod,
  McProcess,
  McProcessFlowLevel,
  McProcessFlowLevelList,
  EbInterest_rate, CurrencyUtil, CsrRecurringDetails, EbFactoringService
} from '@miticon-ui/mc-core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mc-process-flow-profile-page',
  templateUrl: './mc-process-flow-profile-page.component.html',
})
export class McProcessFlowProfilePageComponent implements OnInit {

  mcProcessFlowId!: number;
  mcProcessFlow!: McProcessFlow;
  levelTableConfig = new McProcessLevelTable3Config();
  showGeneralFlowInfoEdit = new McBoolean();
  showAdditionalFlowInfoEdit = new McBoolean();
  flowLevelsObjectList = new McProcessFlowLevelList();
  showAddEditLevelMcb = new McBoolean();
  showEditCriteriaFlowMcb = new McBoolean();
  showEditFeesMcb = new McBoolean();
  showEditTransactionsEnteringTheFlowMcb = new McBoolean();
  mcProcessLevel = new McProcessFlowLevel();
  mcGod = McGod.getInstance();
  mcProcess = new McProcess();
  successMsg = '';
  mcProcessStatusActive = McProcess.STATUS_CD_ACTIVE;
  interestTest = new EbInterest_rate();
  currency!: string;
  recurringDetails = new CsrRecurringDetails();

  // ---------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private factoringService: EbFactoringService,
    @Inject('designConfig') design: any) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      // @ts-ignore
      this.mcProcessFlowId = +params.get('id');
      this.mcProcessFlow = new McProcessFlow();
      this.actLoad();
    });
    this.currency = this.factoringService.activeChildFactoring.mcCurrency.code;

  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcProcessFlow.loadById(this.mcProcessFlowId, () => {
      this.mcProcess.loadById(this.mcProcessFlow.idMcProcess);
      this.flowLevelsObjectList.loadByIdMcProcessFlow(this.mcProcessFlow.id, () => {
        this.flowLevelsObjectList.items.forEach(item => {
        item.amountStr = CurrencyUtil.format(item.levelFeeAmount, this.currency);
        });
      });
    });
}

  addLevel() {
    this.showAddEditLevelMcb.setTrue();
  }

  actCancelGeneralFlowInfoEdit() {
    this.showGeneralFlowInfoEdit.setFalse();
    this.actLoad();
  }

  actSavedGeneralFlowInfoEdit() {
    this.showGeneralFlowInfoEdit.setFalse();
    this.actLoad();
  }

  actShowGeneralFlowInfoEdit() {
    if (this.mcProcess.statusCd !== this.mcProcessStatusActive) {
      this.showGeneralFlowInfoEdit.setTrue();
    }
  }

  actSavedAdditionalInfoEdit() {
    this.showAdditionalFlowInfoEdit.setFalse();
    this.actLoad();
  }

  actCancelAdditionalInfoEdit() {
    this.showAdditionalFlowInfoEdit.setFalse();
    this.actLoad();
  }

  actShowAdditionalInfoEdit() {
    if (this.mcProcess.statusCd !== this.mcProcessStatusActive) {
      this.showAdditionalFlowInfoEdit.setTrue();
    }
  }

  actCancelAddEditLevel() {
    this.showAddEditLevelMcb.setFalse();
    this.mcProcessLevel = new McProcessFlowLevel();
  }

  actSaveAddEditLevel() {
    this.showAddEditLevelMcb.setFalse();
    this.actLoad();
    this.mcProcessLevel = new McProcessFlowLevel();
    this.successMsg = this.mcGod.t('cc.processes.level.level-successfully-saved');
  }

  actProcessLevelTableAction(mcButton: McHtmlButton) {
    if (mcButton.actionCdForEvent === McProcessFlowLevel.ACTION_SHOW_EDIT) {
      this.mcProcessLevel = mcButton.item;
      this.showAddEditLevelMcb.setTrue();
    } else {
      this.mcProcessLevel = mcButton.item;
      this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/processes/level-profile/${mcButton.item.id}`);
    }
  }

  goBack() {
    this.mcGod.redirectBack();
  }

  actShowFlowCriteriaEdit() {
    if (this.mcProcess.statusCd !== this.mcProcessStatusActive) {
      this.showEditCriteriaFlowMcb.setTrue();
    }
  }

  actEditCriteriaCanceled() {
    this.actLoad();
    this.showEditCriteriaFlowMcb.setFalse();
  }
  actEditCriteriaSaved() {
    this.actLoad();
    this.showEditCriteriaFlowMcb.setFalse();
  }

  actGoToProcesses() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/processes/overview`]);
  }
  actGoToProcessProfile() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-profile/${this.mcProcess.id}`]);
  }

  actShowFeesEdit() {
    if (this.mcProcess.statusCd !== this.mcProcessStatusActive) {
      this.showEditFeesMcb.setTrue();
    }
  }

  actFlowFeesEditCancel() {
    this.showEditFeesMcb.setFalse();
    this.actLoad();
  }

  actFlowFeesEditSaved() {
    this.showEditFeesMcb.setFalse();
    this.actLoad();
  }

  fldBooleanToYesNo(value: boolean) {
    return value ? 'Yes' : 'No';
  }

  actShowTransactionsEnteringTheFlowEdit() {
    if (this.mcProcess.statusCd !== this.mcProcessStatusActive) {
      this.showEditTransactionsEnteringTheFlowMcb.setTrue();
    }
  }

  onTransactionsEnteringTheFlowEditCancelEmit() {
    this.actLoad();
    this.showEditTransactionsEnteringTheFlowMcb.setFalse();
  }

  onTransactionsEnteringTheFlowEditSaveEmit() {
    this.actLoad();
    this.showEditTransactionsEnteringTheFlowMcb.setFalse();
  }
}
