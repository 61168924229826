/* eslint-disable */
import {Component, Input} from '@angular/core';

@Component({
  selector: 'mc-status',
  template: `<span class="text-capitalize" *ngIf="status"><i class="fas fa-circle pr-1 mc-status-{{status}}"></i>{{status}}</span>`,
})
export class McStatusComponent {

  /*We are receiving status from BE, if there is new status, just add class for it in _status.scss file*/
  /*Events*/
  @Input() status!: string;
}
