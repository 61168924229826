import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {McForm, McGod, McValueLabelList} from '@miticon-ui/mc-core';
import {FiTextboxWithTokenConfig} from './fi-textbox-with-token-config';
import {Subscription} from 'rxjs';
import {MccFiTextboxWithTokenEventAfterValidation} from './mcc-fi-textbox-with-token-event-after-validation';

@Component({
  selector: 'mcc-fi-textbox-with-token',
  templateUrl: './mcc-fi-textbox-with-token.component.html',
  styleUrls: ['./mcc-fi-textbox-with-token.component.css']
})
export class MccFiTextboxWithTokenComponent implements OnInit, OnChanges {

  @ViewChild('textboxValue')
  textboxValue!: ElementRef;
  requiredFlg!: Validators;
  pattern!: Validators;
  minLength!: Validators;
  maxLength!: Validators;
  numberDecimalRegex!: Validators;
  numberIntegerRegex!: Validators;
  whiteSpaceRegex!: Validators;
  emailRegex!: Validators;
  validMin!: Validators;
  validMax!: Validators;
  numberDecimalRegexString = /^\d*\.\d{1,2}?\d*$/;
  numberIntegerRegexString = /^\d*\d*$/;
  whitespaceRegexString = /^(?!\s*$).+/;
  emailRegexString = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,6})$/;
  validatorList: any[] = [];
  mcGod = McGod.getInstance();
  // textBoxForm: FormGroup;
  cssClass!: string;

  formValidationTriggerSubscription!: Subscription;

  // BMITIC: Talk to me before enabling any additional inputs
  /*Events*/
  @Input() extraCssClasses: any;
  @Input() label: any;
  @Input() additionalDescription!: string;
  @Input() value = '';
  @Input() name!: string;
  @Input() mcForm!: McForm;
  @Input() tokenLabel = 'tokens';
  // @Input() frmGroup: FormGroup;
  @Input() placeholder = '';
  @Input() readOnlyFlg = false;
  @Input() validRequiredFlg = false;
  @Input() validPattern!: string;
  @Input() validMinLength!: number;
  @Input() validMaxLength!: number;
  @Input() validDecimalNumber!: boolean;
  @Input() validIntegerNumber!: boolean;
  @Input() validWhitespaces!: boolean;
  @Input() validMinNumber!: number;
  @Input() validMaxNumber!: number;
  @Input() validEmail!: boolean;
  @Input() size = 45;
  @Input() extraCssClass!: string;
  @Input() formExtraCssClass: any;

  // @Input() colWidth;
  @Input() config!: FiTextboxWithTokenConfig;
  @Input() tokenVll = new McValueLabelList();

  @Output() eventValueChanged = new EventEmitter();
  @Output() eventAfterValidation = new EventEmitter();
  @Output() eventEnterClicked = new EventEmitter();


  constructor() {
  }

  getTextboxForm(): FormGroup {
    return <FormGroup><unknown>!this.config && this.mcForm.getControl(this.name) as FormGroup;
  }

  setCurrentValue() {
    if (!this.getTextboxForm()) {
      const textBoxForm = this.mcForm.formBuilder.group({
        value: [this.value, this.validatorList],
        label: [this.label],
        errorFlg: [false],
        errorMsg: ['']
      });

      if (this.mcForm) {
        this.mcForm.addControl(this.name, textBoxForm);
      }
    } else {
      this.getTextboxForm().controls['value'].setValue(this.value);
      this.getTextboxForm().controls['label'].setValue(this.label);
      this.getTextboxForm().controls['errorFlg'].setValue(false);
      this.getTextboxForm().controls['errorMsg'].setValue('');
    }

  }

  // ---------------------------------------------------------------------
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      if ((changes['value'].previousValue !== changes['value'].currentValue)) {
        this.setCurrentValue();
      }
    }
    if (changes["validRequiredFlg"]) {
      if ((changes["validRequiredFlg"].previousValue !== changes['validRequiredFlg'].currentValue)) {
        this.validationSetup();
        if (this.getTextboxForm()) {
          this.getTextboxForm().controls['value'].setValidators(this.validatorList);
          this.getTextboxForm().controls['errorFlg'].setValue(false);
          this.getTextboxForm().controls['errorMsg'].setValue('');
        }
      }
    }
  }

  ngOnInit() {
    if (this.mcForm && !this.config) {
      this.formValidationTriggerSubscription = this.mcForm.getValidationTriggerObservable().subscribe(() => {
        this.validateTextBox();
      });
    }

    if (!this.config) {
      this.validationSetup();
      this.setCurrentValue();
    } else {
      this.applyConfigToComponent();
    }

  }

  ngOnDestroy(): void {
    if (this.formValidationTriggerSubscription) {
      this.formValidationTriggerSubscription.unsubscribe();
    }

    this.mcForm.formGroup.removeControl(this.name);
  }

  public applyConfigToComponent() {
    // @ts-ignore
    Object.keys(this.config).forEach(key => this[key] = this.config[key]);
  }


  validationSetup() {
    this.validatorList = [];
    if (this.validRequiredFlg) {
      this.requiredFlg = Validators.required;
      this.validatorList.push(this.requiredFlg);
    }

    if (this.validPattern) {
      this.pattern = Validators.pattern(this.validPattern);
      this.validatorList.push(this.pattern);
    }

    if (this.validMinLength) {
      this.minLength = Validators.minLength(this.validMinLength);
      this.validatorList.push(this.minLength);
    }

    if (this.validMaxLength) {
      this.maxLength = Validators.maxLength(this.validMaxLength);
      this.validatorList.push(this.maxLength);
    }

    if (this.validEmail) {
      this.emailRegex = Validators.pattern(this.emailRegexString);
      this.validatorList.push(this.emailRegex);
    }

    if (this.validMinNumber) {
      this.validMin = Validators.min(this.validMinNumber);
      this.validatorList.push(this.validMin);
    }

    if (this.validMaxNumber) {
      this.validMax = Validators.max(this.validMaxNumber);
      this.validatorList.push(this.validMax);
    }

    /*if (this.validMinNumber) {
      this.validMin = Validators.min(this.validMinNumber);
      this.validatorList.push(this.validMin);
    }

    if (this.validMaxNumber) {
      this.validMax = Validators.max(this.validMaxNumber);
      this.validatorList.push(this.validMax);
    }*/

    if (this.validDecimalNumber) {
      this.numberDecimalRegex = Validators.pattern(this.numberDecimalRegexString);
      this.validatorList.push(this.numberDecimalRegex);
    }
    if (this.validIntegerNumber) {
      this.numberIntegerRegex = Validators.pattern(this.numberIntegerRegexString);
      this.validatorList.push(this.numberIntegerRegex);
    }
    if (this.validWhitespaces) {
      this.whiteSpaceRegex = Validators.pattern(this.whitespaceRegexString);
      this.validatorList.push(this.whiteSpaceRegex);
    }

    /*   if (this.customValidator) {
         this.validatorList.push(this.customValidator);
       }
   */
  }

  public validateTextBox() {
    this.validationSetup();
    this.getTextboxForm().controls['value'].setValidators(this.validatorList);
    if (this.getTextboxForm().get('value')?.errors) {
      // @ts-ignore
      const validators = Object.keys(this.getTextboxForm().get('value').errors);
      if (validators) {
        this.getTextboxForm().get('errorFlg')?.setValue(true);
        validators.forEach(item => {
          switch (item) {
            case 'required':
              this.getTextboxForm().get('errorMsg')?.setValue(this.mcGod.t('cc.common.this-field-is-required'));
              break;
            case 'minlength':
              this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.length-must-be-at-least')} ${this.validMinLength}`);
              break;
            case 'maxlength':
              this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.length-must-be-under')} ${this.validMaxLength}`);
              break;
            case 'min':
              this.getTextboxForm().get('errorMsg')?.setValue(`Number must be greater than ${this.validMinNumber}`);
              break;
            case 'max':
              this.getTextboxForm().get('errorMsg')?.setValue(`Number must be less than ${this.validMaxNumber}`);
              break;
            case 'pattern':
              if (this.numberDecimalRegex && !this.numberDecimalRegexString.test(this.getTextboxForm().get('value')?.value)) {
                this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.this-field-is-numeric-and-accepts-whole-numbers-integers-and-decimal-values-with-dot')} (.).`);
                break;
              } else if  (this.numberIntegerRegex && !this.numberIntegerRegexString.test(this.getTextboxForm().get('value')?.value)) {
                this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.this-field-is-numeric-and-accepts-whole-numbers-integers')}.`);
                break;
              } else if (this.whiteSpaceRegex && !this.whitespaceRegexString.test(this.getTextboxForm().get('value')?.value)) {
                this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.no-whitespaces-allowed')}`);
                break;
              } else if (this.emailRegex && !this.emailRegexString.test(this.getTextboxForm().get('value')?.value)) {
                this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.please-enter-valid-email')}`);
                break;
              } else {
                this.getTextboxForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.invalid-value')}`);
                break;
              }
          }
        });
      }
    } else {
      this.getTextboxForm().get('errorFlg')?.setValue(false);
      this.getTextboxForm().get('errorMsg')?.setValue(``);
    }
    this.fireEventAfterValidation();
  }

  public fireEventAfterValidation() {
    if (this.eventAfterValidation) {
      const eventObj = new MccFiTextboxWithTokenEventAfterValidation();
      eventObj.validFlg = !this.getTextboxForm().get('value')?.errors;

      this.eventAfterValidation.emit(eventObj);
    }
  }

  clearErrors() {
    this.getTextboxForm().get('errorFlg')?.setValue(false);
    this.getTextboxForm().get('errorMsg')?.setValue('');
  }

  onTextBoxChanged(value: any) {
    this.clearErrors();
    if (this.eventValueChanged) {
      this.eventValueChanged.emit(value);
    }
  }

  enterClicked() {
    this.eventEnterClicked.emit();
  }


  insertToken(token: string) {
    debugger;
    const indexOfIndicatorStart = this.textboxValue.nativeElement.selectionStart;
    const indexOfIndicatorEnd = this.textboxValue.nativeElement.selectionEnd;
    let tempValueOfEmailBody: string;
    tempValueOfEmailBody = this.getTextboxForm().get('value')?.value;
    tempValueOfEmailBody = tempValueOfEmailBody.substr(0, indexOfIndicatorStart) + token + tempValueOfEmailBody.substr(indexOfIndicatorEnd);
    this.getTextboxForm().get('value')?.setValue(tempValueOfEmailBody);
  }

}
