/*BbmTestObj*/
import {McProcessFlowLevelGENERATED} from '../_generated/mc-process-flow-level-generated.model';
import {McGod} from '../extra/mc-god.service';
import {McHtml} from '../_core/mc-html';
import {ActionOptions} from '../_core/mc-html-action-options';
import {CurrencyUtil} from '../common/currency-util';

export class McProcessFlowLevel extends McProcessFlowLevelGENERATED {

  public static ACTION_SHOW_EDIT = 'EDIT';
  public static ACTION_SHOW_PROFILE = 'PROFILE';
  public amountStr!: string;

  private _fldActions: McHtml | null = null;
  private _fldNameLink: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessFlowLevel();
  }
  // ---------------------------------------------------------------------
  fldName() {
   return this.title + ' Level';
  }

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> View level`, McProcessFlowLevel.ACTION_SHOW_PROFILE, this, [McGod.PERM_MC_PROCESS_FLOW_LEVEL_VIEW], '', ActionOptions.OPTION_VIEW);
      if (!this.processActiveFlg) {
        actSet.addActionB(`<i class="fas mc-submit-color fa-pen mc-cursor-pointer"></i> Edit level`, McProcessFlowLevel.ACTION_SHOW_EDIT, this, [McGod.PERM_MC_PROCESS_FLOW_LEVEL_EDIT], ``, ActionOptions.OPTION_EDIT);
      }
      this._fldActions = html;
    }
    return this._fldActions.items;
  }

  fldNameLink() {
    if (!this._fldNameLink) {
      const html = new McHtml();
      html.addLinkB( this.title, `entity/${McGod.getLoggedEntityIdFromToken()}/processes/level-profile/${this.id}`, this, [McGod.PERM_MC_PROCESS_FLOW_LEVEL_VIEW], 'mc-blue-link', ActionOptions.OPTION_VIEW);
      this._fldNameLink = html;
    }
    return this._fldNameLink.items;
  }

  fldLevelActionIcons() {
    let result = '';
    if (this.emailActionFlg) {
      result += `<div class="table-action-icon"><img src="assets/images/mc-process-icons/email-action.svg">Email</div>`;
    }
    if (this.postActionFlg) {
      result += `<div class="table-action-icon"><img src="assets/images/mc-process-icons/post-action.svg">Post</div>`;
    }
    if (this.smsActionFlg) {
      result += `<div class="table-action-icon"><img src="assets/images/mc-process-icons/sms-action.svg">SMS</div>`;
    }
    return result;
  }

  fldBooleanToYesNo(value: boolean) {
    return value ? 'Yes' : 'No';
  }


}
