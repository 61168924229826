import {CsrConsumer2CtdEmail} from './csr-consumer-2-ctd-email.model';
import {CsrConsumer2CtdEmailListGENERATED} from '../_generated/csr-consumer-2-ctd-email-list-generated.model';

export class CsrConsumer2CtdEmailList extends CsrConsumer2CtdEmailListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrConsumer2CtdEmailList();
  }

  // ---------------------------------------------------------------------

  public loadByEntityId( entityId: number , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return this.loadByEntityIdAction(entityId, onSuccessCallback, onErrorCallback);
  }

  public loadByEntityIdAction(
    entityId: any,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
  ): void {
    this.apiService
      .getAllByEntityId(entityId, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
      .subscribe(
        (responseJson: any) => {
          this.totalItemsCount = responseJson.totalElements;
          this.setApiSuccess();
          this.populateFromJsonPageable(responseJson);
          this.processPostLoad();
          this.processApiSuccess(responseJson, onSuccessCallback);
        },
        (errorResponse: any) => {
          this.setApiError();
          this.processApiError(errorResponse, onErrorCallback);
        }
      );
  }
}
