import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ChangeDetectorRef
} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {McUtilityService} from "@miticon-ui/mc-core";

@Component({
  selector: "lib-mc-user-summary-data",
  templateUrl: "./mc-user-summary-data.component.html"
})
export class McUserSummaryDataComponent implements OnInit {

  // Variables
  allRawData: any;
  saveBtnType!: number;
  isInvitationChecked!: boolean;
  createBtnDisabled!: boolean;

  destroyed$ = new Subject<void>();
  @Output() createUserBtnType = new EventEmitter<number>();

  // Forms
  @Input() imagePath: any;
  @Input() parentForm!: FormGroup;
  stepForm3!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private utilityService: McUtilityService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.parentForm.setControl("step3", this.stepForm3);
    this.allRawData = this.parentForm.getRawValue();
    this.isInvitationChecked = true;
    this.createBtnDisabled = false;
    this.imagePath = "https://image.shutterstock.com/image-vector/empty-photo-male-profile-gray-260nw-538707310.jpg";

    this.parentForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.allRawData = this.parentForm.getRawValue();
        this.cd.markForCheck();
      });

  }

  createForm() {
    this.stepForm3 = this.fb.group({
      sendInvite: [true]
    });
  }

  /*Go to step*/
  goToStep(step: any) {
    this.utilityService.setStepNumber(step);
  }

  createNewUser(type: number) {
    this.createUserBtnType.emit(
        this.saveBtnType = type
    );
  }

}
