import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {McAuthService, McCreateUserService, userEnums} from '@miticon-ui/mc-core';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'lib-mc-user-general-data',
  templateUrl: './mc-user-general-data.component.html'
})
export class McUserGeneralDataComponent implements OnInit {

  // Variables
  userImage: any;
  showAdditionalUserInfo!: boolean;
  responseMessage!: boolean;
  message!: string;
  type!: string;
  timezones: any;
  languages: any;
  countries: any;
  isEmailNotValid!: boolean;
  firstnameNotValid!: boolean;
  lastnameNotValid!: boolean;
  isAddUserInfo!: boolean | null;
  isAddUserInfoChecked!: boolean | null;
  mandatoryFieldsDisabled!: boolean | null;
  generalDataTab!: boolean;
  userDataFormValid!: boolean;
  isInAnotherEntity!: boolean;
  lockSaveBtn!: boolean;
  showLoader!: boolean;
  editedUserId!: number;

  @Input() modalArea!: any;
  @Input() userPersonalData!: any;

  @Output() isDataFormInvalid = new EventEmitter<boolean>();
  @Output() selectedImagePath = new EventEmitter<string>();
  @Output() refreshUserDataEvent = new EventEmitter<boolean>();

  // Forms
  @Input() parentForm!: FormGroup;
  stepForm!: FormGroup;
  stepFormRaw: any;

  // ViewChild
  @ViewChild('addUserInfoCheckbox') addUserInfoCheckbox!: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: McAuthService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private createUserService: McCreateUserService,
    @Inject('designConfig') design: any
  ) {
    this.userImage = design.userImage;
    this.setSelectFields();
    this.createForm();
  }

  ngOnInit() {
    if (this.modalArea === 'createUser') {
      this.parentForm.setControl('step1', this.stepForm);
      this.isAddUserInfo = true;
    } else {
      this.responseMessage = true;
      this.setNotificationMessage(userEnums.mandatoryFields, 'success');
      this.showAdditionalUserInfo = true;
      this.lockSaveBtn = true;

      this.stepForm.patchValue({
        email: this.userPersonalData.email,
        firstName: this.userPersonalData.firstname,
        lastName: this.userPersonalData.lastname,
        nickname: this.userPersonalData.nickname,
        language: this.userPersonalData.isoLanguage,
        timezone: this.userPersonalData.timezones,
        country: this.userPersonalData.isoCountry,
      });
      this.stepForm.get('gender')?.setValue(this.userPersonalData.gender);
      this.userImage = this.userPersonalData.image;
    }
    this.stepFormRaw = this.stepForm.getRawValue();
    this.generalDataTab = true;
    this.emailPrimaryMsg();
    this.checkMandatoryFieldsIsEmpty();
    this.editedUserId = this.activatedRoute.snapshot.params['id'] - 0;
  }

  // Prefill select fields
  // todo: remove this part after BE implementation is completed
  setSelectFields() {
    // setLanguages
    this.languages = [
      {name: 'English', value: 'EN'},
      {name: 'German', value: 'DE'},
      {name: 'Italy', value: 'IT'}
    ];
    // setTimezones
    this.timezones = [
      '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
      '(UTC-08:00) Pacific Time (US & Canada)'
    ];
    // setCountries
    this.countries = [
      {name: 'USA', value: 'US'},
      {name: 'German', value: 'DE'},
      {name: 'Italy', value: 'IT'}
    ];
  }

  // Set email primary msg
  emailPrimaryMsg() {
    if (this.modalArea === 'createUser') {
      this.responseMessage = true;
      this.setNotificationMessage(userEnums.enterEmail, 'success');
    }
  }

  // Unlock Next btn
  private shareUnlockProperty(valid: boolean) {
    this.isDataFormInvalid.emit(
      this.userDataFormValid = valid
    );
  }

  // Clear info message
  clearInfoMsg() {
    this.responseMessage = false;
    this.setNotificationMessage('', '');
  }

  // Toggle Addition User info in "General Data"
  toggleAdditionalUserInfo(e: Event) {
    const isChecked = (e.target as HTMLInputElement).checked;
    this.showAdditionalUserInfo = !this.showAdditionalUserInfo;

    if (isChecked && !this.stepForm.valid) {
      this.shareUnlockProperty(true);
      this.responseMessage = true;
      this.setNotificationMessage(userEnums.mandatoryFields, 'success');
    } else {
      this.shareUnlockProperty(false);
      this.responseMessage = false;
      this.setNotificationMessage('', '');
      this.resetMandatoryFields();
    }
  }

  // Check mandatory fields
  checkMandatoryInputFields(field: string) {
    let msgText = '';
    let msgType = '';
    const formData = this.stepForm.getRawValue();
    const firstName = formData.firstName;
    const lastName = formData.lastName;

    switch (field) {
      case 'firstName':
        if (firstName.length < 2) {
          msgText = userEnums.firstNameMin2Char;
          msgType = 'error';
          this.setNotificationMessage(msgText, msgType);
          this.firstnameNotValid = true;
          this.responseMessage = true;
        } else {
          this.responseMessage = false;
          this.firstnameNotValid = false;
        }
        break;
      case 'lastName':
        if (lastName.length < 2) {
          msgText = userEnums.lastNameMin2Char;
          msgType = 'error';
          this.setNotificationMessage(msgText, msgType);
          this.lastnameNotValid = true;
          this.responseMessage = true;
        } else {
          this.responseMessage = false;
          this.lastnameNotValid = false;
        }
        break;
      case 'gender':
        if (formData.gender === '') {
          msgText = userEnums.selectGender;
          msgType = 'error';
          this.setNotificationMessage(msgText, msgType);
          this.responseMessage = true;
        } else {
          this.responseMessage = false;
        }
        break;
    }
    if (this.stepForm.controls['firstName'].valid &&
      this.stepForm.controls['lastName'].valid &&
      this.stepForm.controls['gender'].valid &&
      this.showAdditionalUserInfo) {
      this.shareUnlockProperty(false);
      this.lockSaveBtn = false;
    } else {
      this.shareUnlockProperty(true);
      this.lockSaveBtn = true;
    }
  }

  // Upload and preview image
  onFileChanged(event: Event) {
    // @ts-ignore
    const file: File = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.userImage = reader.result;

      this.selectedImagePath.emit(
        this.userImage
      );
    };
    reader.readAsDataURL(file);
  }

  /* EMAIL FIELD SECTION */

  // Check if is email valid
  private validateEmail(email: string) {
    const re = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,6})$/;
    return re.test(String(email).toLowerCase());
  }

  // Check User by Email
  checkUserByEmail() {
    this.mandatoryFieldsDisabled = null;
    this.responseMessage = false;
    this.isEmailNotValid = false;
    this.isAddUserInfoChecked = null;
    let msgText = '';
    let msgType = '';
    const userEmail = this.stepForm.getRawValue().email;
    const isEmailValid = this.validateEmail(userEmail);
    const body = {
      email: userEmail,
    };

    if (userEmail !== '') {
      if (isEmailValid) {
        this.createUserService.checkSystemUserByEmail(body).subscribe(
          (response) => {
            if (response === null) {
              this.resetMandatoryFields();
              this.mandatoryFieldsDisabled = null;
              this.shareUnlockProperty(false);
              this.isAddUserInfo = null;
              this.responseMessage = true;
              msgText = userEnums.userDoesntExist;
              msgType = `success`;
              this.setNotificationMessage(msgText, msgType);
              this.isAddUserInfoChecked = false;
              (this.modalArea === 'createUser') ? this.showAdditionalUserInfo = false : this.showAdditionalUserInfo = true;
            } else {
              this.resetMandatoryFields();
              this.isInAnotherEntity = true;
              this.stepForm.patchValue({
                firstName: response.firstname,
                lastName: response.lastname,
                nickname: response.nickname
              });
              this.stepForm.get('gender')?.setValue(response.gender);
              msgText = userEnums.userAlreadyExist;
              msgType = `success`;
              this.setNotificationMessage(msgText, msgType);
              this.responseMessage = true;
              this.isAddUserInfo = null;
              this.mandatoryFieldsDisabled = true;
              this.showAdditionalUserInfo = true;
              this.isAddUserInfoChecked = true;
              this.isAddUserInfo = true;
              this.shareUnlockProperty(false);
            }
          },
          (error) => {
            if (error.status >= 500) {
              msgText = userEnums.userServerError;
              msgType = 'error';
            } else if (error.status === 409) {
              this.resetMandatoryFields();
              msgText = 'User exists in all entities';
              msgType = 'error';
              this.isAddUserInfo = true;
              this.mandatoryFieldsDisabled = null;
              this.shareUnlockProperty(true);
            } else if (!error.ok) {
              this.resetMandatoryFields();
              msgText = 'User exist';
              msgType = 'error';
              this.isAddUserInfo = true;
              this.mandatoryFieldsDisabled = null;
              this.shareUnlockProperty(true);
            }
            this.setNotificationMessage(msgText, msgType);
            this.responseMessage = true;
          }
        );
      } else {
        this.mandatoryFieldsDisabled = null;
        this.shareUnlockProperty(true);
        this.isAddUserInfo = true;
        this.isEmailNotValid = false;
        this.responseMessage = true;
        this.isAddUserInfoChecked = false;
        this.showAdditionalUserInfo = this.modalArea !== 'createUser';
        msgText = userEnums.enterValidEmail;
        msgType = `error`;
        this.setNotificationMessage(msgText, msgType);
      }
    } else {
      this.showAdditionalUserInfo = this.modalArea !== 'createUser';
      this.isEmailNotValid = true;
      this.responseMessage = true;
      this.isAddUserInfo = true;
      this.isAddUserInfoChecked = false;
      this.resetMandatoryFields();
      msgText = userEnums.enterEmail;
      msgType = `error`;
      this.setNotificationMessage(msgText, msgType);
    }
  }

  /* EMAIL FIELD SECTION END*/

  // Initialize page form
  createForm() {
    this.stepForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      nickname: ['', [Validators.minLength(2)]],
      language: [this.languages[0].value],
      timezone: [this.timezones[0]],
      country: [this.countries[0].value],
      image: [''],
      gender: ['', Validators.required],
    });
  }

  // Reset Mandatory Fields
  private resetMandatoryFields() {
    this.stepForm.get('firstName')?.reset();
    this.stepForm.get('lastName')?.reset();
    this.stepForm.get('nickname')?.reset();
    this.stepForm.get('gender')?.reset();
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  // Api ErrorHandler
  private apiErrorHandler(error: any) {
    let message = '';
    let msgType = '';
    if (error.status >= 500) {
      message = userEnums.userServerError;
      msgType = 'error';
    } else if (error.error) {
      if (error.error.errors.length > 1) {
        message = error.error.errors.join();
        msgType = 'error';
      } else {
        message = error.error.errors;
        msgType = 'error';
      }
    } else {
      message = userEnums.userRequestError;
      msgType = 'error';
    }
    this.responseMessage = true;
    this.setNotificationMessage(message, msgType);
  }

  /* Edit User Area */
  checkMandatoryFieldsIsEmpty() {
    const stepFormRaw = this.stepForm.getRawValue();
    this.lockSaveBtn = !(stepFormRaw.firstName !== '' && stepFormRaw.lastName !== '' && stepFormRaw.gender !== '');
  }

  saveUserData() {
    this.showLoader = true;
    const stepFormData = this.stepForm.value;
    const body = {
      id: this.editedUserId,
      firstName: stepFormData.firstName,
      lastName: stepFormData.lastName,
      gender: stepFormData.gender,
      isoCountry: stepFormData.country || null,
      isoLanguage: stepFormData.language || null,
      nickname: stepFormData.nickname || null,
      rawImageData: this.userImage || null,
      timezone: 0
    };

    this.createUserService.saveEditedUserPersonalInfo(body).subscribe(
      (response) => {
        const msgText = userEnums.successEdited;
        const msgType = 'success';
        this.responseMessage = true;
        this.setNotificationMessage(msgText, msgType);
        // event for refresh personal data after successfully save
        this.refreshUserDataEvent.emit(true);

        setTimeout(() => {
          this.modalService.dismissAll('Cross click');
        }, 3000);
        this.showLoader = false;
      },
      (error) => {
        this.apiErrorHandler(error);
        this.showLoader = false;
      }
    );

  }
}

