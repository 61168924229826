<ng-template #content let-modal>
  <div class="container mc-change-password-area">
    <div class="row">
      <div class="col-12">
        <div class="card mc-login-card" style="width: 534px;">
          <div class="card-header text-center mc-login-header">
            <!--Logo image-->
            <img [src]="logo" alt="cc_logo">
            <!--Change password title-->
            <h5 class="card-title mc-card-title">{{ 'cc.common.edit.change-your-password' | translate }}</h5>
          </div>
          <!--Change password content-->
          <div class="card-body mc-login-card-body">
            <!--Notification message-->
            <mc-notify-message [message]="message" [type]="type"></mc-notify-message>
            <div class="row">
              <!--Change password form-->
              <form [formGroup]="changePassForm">
                <div class="row">
                  <div class="col-md-7 mc-divider mc-login-password-fields">
                    <ng-container *ngIf="!changePassForUser">
                      <!--Current password area-->
                      <div class="form-group mc-input-password">
                        <label for="current-password" class="label-position">{{ 'cc.common.edit.current-password' | translate }}</label>
                        <input [type]="seeCurrentPassword ? 'text' : 'password'"
                               name="password"
                               class="form-control"
                               [ngClass]="inputFieldsError ? 'mc-error-input-wrapper' : ''"
                               id="current-password"
                               formControlName="currentPassword"
                               required>
                        <!--See password (eye)-->
                        <mc-see-password (seePasswordOnEyeBtn)="onChangeSeePassword($event, 3)"></mc-see-password>
                      </div>
                    </ng-container>
                    <!--New password area-->
                    <div class="form-group mc-input-password">
                      <label for="checkPassword" class="label-position">{{ 'cc.common.edit.new-password' | translate }}</label>
                      <input [type]="seePassword ? 'text' : 'password'"
                             class="form-control"
                             [ngClass]="inputFieldsError ? 'mc-error-input-wrapper' : ''"
                             id="checkPassword"
                             name="password"
                             formControlName="newPassword"
                             required
                             (keyup)="validatePassword(1)" />
                      <!--See password (eye)-->
                      <mc-see-password (seePasswordOnEyeBtn)="onChangeSeePassword($event, 1)"></mc-see-password>
                    </div>
                    <!--Repeat new password area-->
                    <div class="form-group mc-input-password">
                      <label for="repeat-password" class="label-position">{{ 'cc.common.edit.repeat-new-password' | translate }}</label>
                      <input [type]="seeRepeatedPassword ? 'text' : 'password'"
                             name="password"
                             class="form-control"
                             [ngClass]="inputRepeatPasswordFieldError || inputFieldsError ? 'mc-error-input-wrapper' : ''"
                             id="repeat-password"
                             formControlName="repeatPassword"
                             required
                             (keyup)="validatePassword(2)"
                             />
                      <!--See password (eye)-->
                      <mc-see-password (seePasswordOnEyeBtn)="onChangeSeePassword($event, 2)"></mc-see-password>
                    </div>
                  </div>
                  <!--Password Policy area-->
                  <div class="col-md-5 mc-password-policy">
                    <p>{{'cc.common.view.password-policy' | translate}}</p>
                    <div class="mc-password-policy-requirements">
                      <div class="form-group">
                        <label class="custom-control fill-checkbox m-0">
                          <input type="checkbox" class="fill-control-input" disabled [checked]="ifMinChars"/>
                          <span class="fill-control-indicator" style="border-radius: 10px"></span>
                          <span class="fill-control-description" style="font-size: 10px; text-transform: uppercase;">{{'cc.common.edit.minimum-characters' | translate}}: <strong>{{minChars}}</strong></span>
                        </label>
                      </div>
                      <div class="form-group">
                        <label class="custom-control fill-checkbox m-0">
                          <input type="checkbox" class="fill-control-input" disabled [checked]="checkDiffChars"/>
                          <span class="fill-control-indicator" style="border-radius: 10px"></span>
                          <span class="fill-control-description" style="font-size: 10px; text-transform: uppercase;">{{'cc.common.edit.minimum-different-characters' | translate}}: <strong>{{noOfDiffChar}}</strong></span>
                        </label>
                      </div>
                      <div class="form-group">
                        <label class="custom-control fill-checkbox m-0">
                          <input type="checkbox" class="fill-control-input" disabled [checked]="checkDigits"/>
                          <span class="fill-control-indicator" style="border-radius: 10px"></span>
                          <span class="fill-control-description" style="font-size: 10px; text-transform: uppercase;">{{'cc.common.edit.minimum-digits' | translate}}: <strong>{{noOfDigits}}</strong></span>
                        </label>
                      </div>
                      <div class="form-group">
                        <label class="custom-control fill-checkbox m-0">
                          <input type="checkbox" class="fill-control-input" disabled [checked]="checkSpecialChars"/>
                          <span class="fill-control-indicator" style="border-radius: 10px"></span>
                          <span class="fill-control-description" style="font-size: 10px; text-transform: uppercase;">{{'cc.common.edit.minimum-special-characters' | translate}}: <strong>{{noOfSpecialChars}}</strong></span>
                        </label>
                      </div>
                      <div class="form-group">
                        <label class="custom-control fill-checkbox m-0" >
                          <input type="checkbox" class="fill-control-input" disabled [checked]="checkLowercaseLetters"/>
                          <span class="fill-control-indicator" style="border-radius: 10px"></span>
                          <span class="fill-control-description" style="font-size: 10px; text-transform: uppercase;">{{'cc.common.edit.minimum-lowercase-letters' | translate}}: <strong>{{noOfLowercaseChars}}</strong></span>
                        </label>
                      </div>
                      <div class="form-group">
                        <label class="custom-control fill-checkbox m-0">
                          <input type="checkbox" class="fill-control-input" disabled [checked]="checkUppercaseLetters"/>
                          <span class="fill-control-indicator" style="border-radius: 10px"></span>
                          <span class="fill-control-description" style="font-size: 10px; text-transform: uppercase;">{{'cc.common.edit.minimum-uppercase-letters' | translate}}: <strong>{{noOfUppercaseChars}}</strong></span>
                        </label>
                      </div>
                    </div>

                  </div>
                  <div class="col-md-12 text-center mc-btn-group">
                    <!--Cancel btn-->
                    <button class="btn btn-sm mc-cancel-btn"
                            type="button" (click)="modal.dismiss('Cross click')">
                     {{ 'cc.common.edit.cancel' | translate }}
                    </button>
                    <!--Confirm btn-->
                    <button class="btn mc-confirm-btn"
                            type="submit"
                            (click)="resetPassword()">
                      {{ 'cc.common.edit.confirm' | translate }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<a class="mc-account-info-reset  mc-cursor-pointer ml-1" (click)="openModal(content)"><i class="fa fa-pen mc-blue-text"></i></a>

<!--<pre>{{closeResult}}</pre>-->
