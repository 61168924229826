<ng-container>
  <div class="content-wrapper">
    <lib-mc-loader [showLoader]="loaderFlg"></lib-mc-loader>

    <div>

    </div>


    <div class="content-header">
      <h1>{{'cc.bank.import-sepa-statement-file' | translate}}</h1>
    </div><!--Modal header -->

    <!--Modal body section-->
    <div class="content-body">

      <div class="row">
        <div class="col-md-12 position-relative mt-1">
          <div class="mc-create-user-notify-wrapper">
            <div class="w-100 mc-notify-message-wrapper mc-background-loading mc-text-align-center py-1"
                 *ngIf="webFile.apiLoadingFlg">{{'cc.my-factoring.loading-file-please-wait' | translate}}
            </div>
            <div class="w-100" *ngIf="webFile.apiErrorFlg">
              <mc-notify-message [message]=webFile.apiErrorMessage [type]="'error'"></mc-notify-message>
            </div>
            <div class="w-100" *ngIf="!isEnabled">
              <mc-notify-message [message]="message" [type]="messageType"></mc-notify-message>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-5 px-5">
          <div class="input-group mb-3">

            <lib-mcc-fi-file-upload [acceptProperty]="'.xml'"
                                    (eventFileSelected)="onFileSelected($event)"></lib-mcc-fi-file-upload>

          </div>
        </div>
      </div>
    </div>
    <div class="content-footer">
      <!-- <button type="button" class="btn btn-secondary" (click)="actCancel()">Delete</button>-->
      <button type="button" class="btn btn-secondary move-right" (click)="actCancel()"><i class="far fa-times-circle"></i> {{'cc.common.edit.cancel' | translate}}</button>
      <button type="button" class="btn btn-primary" (click)="actUpload()"><i class="fas fa-upload"></i> {{'cc.consumers.consumers-overview.upload' | translate}}</button>
  </div>
  </div>
  <router-outlet></router-outlet>


</ng-container>

