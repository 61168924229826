/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CsrConsumerFilter} from '../models/csr-consumer-filter.model';

export class CsrConsumerFilterGENERATED extends BaseObjectFilter{    public fnames: string[] = [];
    public birthday: any | undefined;
    public searchTerm: string | undefined;
    public statusCds: string[] = [];
    public typeCds: string[] = [];

  public properties: string[] = ['fnames', 'birthday', 'searchTerm', 'statusCds', 'typeCds', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrConsumerFilter {
    console.error('MTCN-ERROR: Not Implemented: CsrConsumerFilter::createNewInstance(). Add this method to final class and return new CsrConsumerFilter();');
      throw new Error('Cannot CsrConsumerFilter::createNewInstance(). Add this method to final class and return new CsrConsumerFilter();');
  }

}
