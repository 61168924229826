/*BbmTestObj*/
import {ImDataImportItemGENERATED} from '../_generated/im-data-import-item-generated.model';

export class ImDataImportItem extends ImDataImportItemGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataImportItem();
  }

  // ---------------------------------------------------------------------
}
