import {CsrConsumerImport} from './csr-consumer-import.model';
import {CsrConsumerImportListGENERATED} from '../_generated/csr-consumer-import-list-generated.model';

export class CsrConsumerImportList extends CsrConsumerImportListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrConsumerImportList();
  }

  // ---------------------------------------------------------------------
}
