export const ICON_STATUS_IN_PROGRESS = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2850_27468)">
<path d="M21.29 5.8902L11.29 15.8902C10.9 16.2802 10.27 16.2802 9.87998 15.8902L7.04998 13.0602C6.65998 12.6702 6.65998 12.0402 7.04998 11.6502C7.43998 11.2602 8.06998 11.2602 8.45998 11.6502L10.58 13.7702L19.87 4.4802C20.26 4.0902 20.89 4.0902 21.28 4.4802C21.68 4.8702 21.68 5.5002 21.29 5.8902ZM15.77 2.7402C14.08 2.0502 12.16 1.8102 10.16 2.1702C6.08998 2.9002 2.83998 6.1802 2.14998 10.2502C1.00998 17.0002 6.62998 22.7802 13.34 21.9102C17.3 21.4002 20.62 18.4502 21.66 14.6002C22.06 13.1302 22.1 11.7102 21.87 10.3802C21.74 9.5802 20.75 9.2702 20.17 9.8402C19.94 10.0702 19.84 10.4102 19.9 10.7302C20.12 12.0602 20.02 13.4802 19.38 14.9902C18.22 17.7002 15.7 19.6902 12.77 19.9602C7.66998 20.4302 3.43998 16.1102 4.06998 10.9802C4.49998 7.4402 7.34998 4.5602 10.88 4.0702C12.61 3.8302 14.25 4.1602 15.65 4.8802C16.04 5.0802 16.51 5.0102 16.82 4.7002C17.3 4.2202 17.18 3.4102 16.58 3.1002C16.31 2.9802 16.04 2.8502 15.77 2.7402Z" fill="#FFD542"/>
</g>
<defs>
<clipPath id="clip0_2850_27468">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
`
