import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IMcPriceList} from '../_generated/mc-price-list.interface';
import {McPriceListServiceGENERATED} from '../_generated/mc-price-list-generated.service';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class McPriceListService extends McPriceListServiceGENERATED {

  public override getById(id: number): Observable<IMcPriceList> {
    return this.httpClient.get<IMcPriceList>(this.getApiServiceRootUrl().replace('v2', 'v3') + `/${id}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });

  }

}
