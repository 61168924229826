import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
    McGod,
    McPriceListList,
} from "@miticon-ui/mc-core";

@Component({
    selector: 'lib-mc-entity-pricelist',
    templateUrl: './mc-entity-pricelist.component.html',
    styleUrls: ['./mc-entity-pricelist.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class McEntityPricelistComponent implements OnInit {

    entityPasswordPolicyForm!: FormGroup;
    message!: string;
    type!: string;
    isEnabled = true;
    isDisabled!: boolean;
    restoredValues = false;
    pricelistTab!: boolean;
    showLoader!: boolean;
    mcPricelistList!: McPriceListList;
    form!: FormGroup;
    userId!: number;
    @Input() parentForm!: FormGroup;


    // Edit entity Id
    @Input() editEntityId!: any;

    // Outputs
    @Output() eventPricelistChosen = new EventEmitter<any>();
    @Output() isPricelistFormInvalid = new EventEmitter<boolean>();

    constructor(private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.userId = McGod.getLoggedUserIdFromToken();
        this.mcPricelistList = new McPriceListList();
        this.mcPricelistList.loadAllPossiblePriceListsForNewEntity();
        // Set color for active tab
        this.pricelistTab = true;
        this.isDisabled = false;
        // Empty notification message
        this.setNotificationMessage("", "");
        this.restoredValues = false;
        this.form = this.fb.group({
            pricelist: ['', Validators.required]
        });
        if (this.parentForm) {
            this.parentForm.setControl("step4", this.form);
        }
    }

    // Display notification message
    private setNotificationMessage(message: string, type: string): void {
        this.message = message;
        this.type = type;
    }

    // Unlock Next btn
    private shareUnlockProperty(valid: boolean) {
        this.isPricelistFormInvalid.emit(
            valid
        );
    }

    onEventValueChange(value: any) {
        this.eventPricelistChosen.emit(value);
    }
}
