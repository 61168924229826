import {Inject, Injectable} from '@angular/core';
import {McBillingProcessedTransactionsInfoTableServiceGENERATED} from '../_generated/mc-billing-processed-transactions-info-table-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McBillingProcessedTransactionsInfoTableService extends McBillingProcessedTransactionsInfoTableServiceGENERATED {

}
