/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McProcessTable3ConfigGENERATED} from '../_generated/mc-process-table-3-config-generated.model';

// import {McProcessGENERATED} from '../_generated/mc-process-generated.model';

export class McProcessTable3Config extends McProcessTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colTitle', this.t('cc.processes.process-name'), 'fldNameLink()', 'title');
    this.addColumn('colTypeCd', this.t('cc.processes.process-type'), 'getTypeCdLabel()', 'typeCd');
    this.addColumn('colFlows', this.t('cc.processes.flows-active-total'), 'fldFlowsActiveTotal()', '');
    this.addColumn('colActiveItems', this.t('cc.processes.active-items'), 'activeMcProcessFlowCount', '');
    // this.addColumn("colSysCreatedDatetime", "System Created Datetime", "getSysCreatedDatetimeStr()", "sysCreatedDatetime");
    this.addColumn('colLastRun', this.t('cc.processes.last-run'), 'getLastRunDateTimeStr()', '');
    this.addColumn('colStatusCd', this.t('cc.common.view.status'), 'fldStatus()', 'statusCd');

  }
}
