import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CsrConsumer, CsrConsumerService, McGod} from "@miticon-ui/mc-core";
import {ICON_BACK} from "../../../../assets/media/svg_icons/icon-back";
import {ICON_EDIT} from "../../../../assets/media/svg_icons/icon-edit";
import moment from "moment/moment";

@Component({
  selector: 'lib-csr-consumer-profile',
  templateUrl: './csr-consumer-profile.component.html',
  styleUrls: ['./csr-consumer-profile.component.scss']
})
export class CsrConsumerProfileComponent implements OnInit {
  csrConsumer: CsrConsumer;
  iconBack = ICON_BACK;
  iconEdit = ICON_EDIT;
  consumerInfoTabs = [{
    title: 'cc.my-factoring.consumer-information',
    value: 'Consumer'
  }, {
    title: 'cc.common.bank-information',
    value: 'Bank'
  }, {
    title: 'cc.consumer.overview.additional-information',
    value: 'Additional'
  }];
  activeTab: string = 'Consumer';
  previousBankAccounts: any;
  primaryBankAccount: any;
  noBankAccounts = false;
  csrContract: any;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private csrConsumerService: CsrConsumerService) {
  }

  ngOnInit(): void {
    this.route.params.pipe().subscribe((params) => {
      const consumerId = params['id'];
      if (consumerId) return this.loadConsumer(consumerId);
    });
  }

  loadConsumer(consumerId: number) {
    this.csrConsumerService.getById(consumerId).subscribe((csrConsumer: any) => {
      this.csrConsumer = csrConsumer;
      this.csrContract = csrConsumer.activeContracts[0];

      if(csrConsumer.bankAccounts && csrConsumer.bankAccounts.length > 0) {
        this.noBankAccounts = false;
        this.primaryBankAccount = csrConsumer.bankAccounts.find((account: any) => account.flgPrimary === true);
        this.previousBankAccounts = csrConsumer.bankAccounts.filter((account: any) => account !== this.primaryBankAccount);
      } else this.noBankAccounts = true;
    })
  }

  goToOverview() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/overview`]);
  }

  goToEditConsumer() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/${this.csrConsumer.id}/edit`]);
  }

  isCompany() {
    return this.csrConsumer.typeCd === CsrConsumer.TYPE_CD_COMPANY;
  }

  setActiveTab(tab: any) {
    this.activeTab = tab.value;
  }

  hasCustomInfo() {
    for (const key in this.csrConsumer.additionalAttributes) {
      if (this.csrConsumer.additionalAttributes.hasOwnProperty(key)) {
        if (!key.includes("contract")) {
          return true;
        }
      }
    }
    return false;
  }

  getFormattedDate(date: any) {
    return date ? moment(date).format('DD.MM.YYYY') : '-';
  }

  getCountryName(countryCode: string): string {
    return CsrConsumer.getNameFromList(countryCode, CsrConsumer.ISO_COUNTRIES);
  }

  getLanguageName(languageCode: string): string {
    return CsrConsumer.getNameFromList(languageCode, CsrConsumer.ISO_LANGUAGES);
  }
}
