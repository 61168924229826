/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McProcessFilter} from '../models/mc-process-filter.model';

export class McProcessFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProcessFilter {
    console.error('MTCN-ERROR: Not Implemented: McProcessFilter::createNewInstance(). Add this method to final class and return new McProcessFilter();');
      throw new Error('Cannot McProcessFilter::createNewInstance(). Add this method to final class and return new McProcessFilter();');
  }

}
