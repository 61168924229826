import {McBillingBatchAssignedToStatement} from './mc-billing-batch-assigned-to-statement.model';
import {McBillingBatchAssignedToStatementListGENERATED} from '../_generated/mc-billing-batch-assigned-to-statement-list-generated.model';

export class McBillingBatchAssignedToStatementList extends McBillingBatchAssignedToStatementListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingBatchAssignedToStatementList();
  }

  // ---------------------------------------------------------------------
}
