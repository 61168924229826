/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {CsrRecurringDetails} from '../models/csr-recurring-details.model';
import {CsrRecurringDetailsService} from '../services/csr-recurring-details.service';
import {ICsrRecurringDetails} from '../_generated/csr-recurring-details.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class CsrRecurringDetailsGENERATED extends BaseObject {


    public static readonly TYPE_CD_WEEKLY: string = 'WEEKLY';
    public static readonly TYPE_CD_TWO_WEEKS: string = 'TWO_WEEKS';
    public static readonly TYPE_CD_MONTHLY: string = 'MONTHLY';
    public static readonly TYPE_CD_QUARTERLY: string = 'QUARTERLY';
    public static readonly TYPE_CD_HALF_YEARLY: string = 'HALF_YEARLY';
    public static readonly TYPE_CD_YEARLY: string = 'YEARLY';
  public static readonly TYPE_CD_ONE_TIME: string = 'ONE_TIME';
  public static readonly TYPE_CD_LIST__ALL = [
    CsrRecurringDetailsGENERATED.TYPE_CD_WEEKLY,
    CsrRecurringDetailsGENERATED.TYPE_CD_TWO_WEEKS,
    CsrRecurringDetailsGENERATED.TYPE_CD_MONTHLY,
    CsrRecurringDetailsGENERATED.TYPE_CD_QUARTERLY,
    CsrRecurringDetailsGENERATED.TYPE_CD_HALF_YEARLY,
    CsrRecurringDetailsGENERATED.TYPE_CD_YEARLY,
    CsrRecurringDetailsGENERATED.TYPE_CD_ONE_TIME
];

  public apiService: CsrRecurringDetailsService;
  public _rawJson: ICsrRecurringDetails;
    id: number = 0;
  day: number;
  month: number;
  typeCd: string;


  public properties: string[] = ['id', 'day', 'month', 'typeCd'];
  public propertiesRegular: string[] = ['id', 'day', 'month', 'typeCd'];
  public propertiesSpecial: string[] = [];



  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrRecurringDetailsGENERATED.TYPE_CD_WEEKLY, McGod.t('Weekly'));
    list.add(CsrRecurringDetailsGENERATED.TYPE_CD_TWO_WEEKS, McGod.t('Two-weeks'));
    list.add(CsrRecurringDetailsGENERATED.TYPE_CD_MONTHLY, McGod.t('Monthly'));
    list.add(CsrRecurringDetailsGENERATED.TYPE_CD_QUARTERLY, McGod.t('Quarterly'));
    list.add(CsrRecurringDetailsGENERATED.TYPE_CD_HALF_YEARLY, McGod.t('Half-yearly'));
    list.add(CsrRecurringDetailsGENERATED.TYPE_CD_YEARLY, McGod.t('Yearly'));
    list.add(CsrRecurringDetailsGENERATED.TYPE_CD_ONE_TIME, McGod.t('One time'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = CsrRecurringDetailsGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): CsrRecurringDetails {
    console.error('MTCN-ERROR: Not Implemented: CsrRecurringDetails::createNewInstance(). Add this method to final class and return new CsrRecurringDetails();');
    throw new Error('Cannot CsrRecurringDetails::createNewInstance(). Add this method to final class and return new CsrRecurringDetails();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: ICsrRecurringDetails): CsrRecurringDetails {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrRecurringDetailsService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CsrRecurringDetails AABB()');

  }

  // ---------------------------------------------------------------------


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --


  public getTypeCdLabel(): string {
    return CsrRecurringDetailsGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdWEEKLY(): boolean {
    const result = (this.typeCd === CsrRecurringDetailsGENERATED.TYPE_CD_WEEKLY);

    return result;
  }

 public  isTypeCdTWO_WEEKS(): boolean {
    const result = (this.typeCd === CsrRecurringDetailsGENERATED.TYPE_CD_TWO_WEEKS);

    return result;
  }

 public  isTypeCdMONTHLY(): boolean {
    const result = (this.typeCd === CsrRecurringDetailsGENERATED.TYPE_CD_MONTHLY);

    return result;
  }

 public  isTypeCdQUARTERLY(): boolean {
    const result = (this.typeCd === CsrRecurringDetailsGENERATED.TYPE_CD_QUARTERLY);

    return result;
  }

 public  isTypeCdHALF_YEARLY(): boolean {
    const result = (this.typeCd === CsrRecurringDetailsGENERATED.TYPE_CD_HALF_YEARLY);

    return result;
  }

 public  isTypeCdYEARLY(): boolean {
    const result = (this.typeCd === CsrRecurringDetailsGENERATED.TYPE_CD_YEARLY);

    return result;
  }

  public  isTypeCdONE_TIME(): boolean {
    const result = (this.typeCd === CsrRecurringDetailsGENERATED.TYPE_CD_ONE_TIME);

    return result;
  }



}
