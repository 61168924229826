/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingItemTable3ConfigGENERATED} from '../_generated/mc-billing-item-table-3-config-generated.model';

// import {McBillingItemGENERATED} from '../_generated/mc-billing-item-generated.model';

export class McBillingItemTable3ConfigWithoutFldActionsModel extends McBillingItemTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colType', this.t('cc.jobs.edit.type'), 'getRefTypeCdLabel()', 'typeCd');
    this.addColumn('colMcBillingStatementItem', this.t('cc.billing.item'), 'getBillingTitle()', '');
    this.addColumn('colAmount', this.t('cc.common.view.amount-due'), 'getAmount()', 'amount');
    this.addColumn('colVat', this.t('cc.product.view.vat'), 'getVATString()', '').extraCssClass = 'text-left';
    this.addColumn('colTotal', this.t('cc.statement.total'), 'getTotalAmount()', '').extraCssClass = 'text-left';
    this.addColumn('colActivityDatetime', this.t('cc.statement.activity-date-and-time'), 'getActivityDatetimeString()', 'activityDatetime');
    this.addColumn('colStatusCd', this.t('cc.common.view.status'), 'getStatusCdLabel()', 'statusCd');
 }
}
