import {McBillingServiceUnitType} from './mc-billing-service-unit-type.model';
import {McBillingServiceUnitTypeListGENERATED} from '../_generated/mc-billing-service-unit-type-list-generated.model';

export class McBillingServiceUnitTypeList extends McBillingServiceUnitTypeListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingServiceUnitTypeList();
  }

  // ---------------------------------------------------------------------
}
