import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {
  BaseDirective,
  CdsLookupService, McBoolean, McConsumersService, McCustomAttribute, McForm, McGod,
  McUtilityService, McValueLabelList, CsrConsumerImportService, CsrMapping, CsrMappingList, CsrConsumerService
} from "@miticon-ui/mc-core";
import {TranslateService} from "@ngx-translate/core";
import {McCsvColumnsMappingItemTableConfigDefaultModel} from "@miticon-ui/mc-components";
import {MccFiSelectOptionConfig, MccFiTable2CellEditConfigData, MccFiTable2CellEditedData} from "@miticon-ui/mc-components";


@Component({
  selector: 'mc-consumer-import-consumers-step-2',
  templateUrl: './mc-consumer-import-consumers-step-2.component.html',
})
export class McConsumerImportConsumersStep2Component extends BaseDirective implements OnInit{

  /*Variables*/
  entityId = 0;
  notifyMessageObject = {
    type: '',
    message: ''
  };
  mapping = new CsrMapping();
  templateMappings = new CsrMappingList();
  duplicateHandlingOptionsKvm = new McValueLabelList();
  classCustomAtt = new McCustomAttribute();
  disabledImportButtonFlag = false;
  mcForm = new McForm();
  currentTemplateMapping = new CsrMapping()
  dateFormatVll = new McValueLabelList();
  mappingItemTableConfig = new McCsvColumnsMappingItemTableConfigDefaultModel();
  mcGod = McGod.getInstance();
  showChooseMappingMcb = new McBoolean();
  showCreateCustomAttributeMcb = new McBoolean();
  loaderFlg = false;

  @Input() webFileId = 0;
  @Output() eventPrevStep = new EventEmitter();
  @Output() eventCancel = new EventEmitter();
  @Output() eventImported = new EventEmitter<number>();

  constructor( private consumersService: McConsumersService,
               private csrConsumerService: CsrConsumerService,
               private translateService: TranslateService,
               private cdsLookupService: CdsLookupService,
               private utilityService: McUtilityService,
               private csrConsumerImportService: CsrConsumerImportService) {
    super();
  }
  ngOnInit() {
    this.dateFormatVll.add('dd.MM.yyyy', this.mcGod.t('cc.consumer.import.date-format.dd.mm.yyyy'));
    this.dateFormatVll.add('dd.MM.yy', this.mcGod.t('cc.consumer.import.date-format.dd.mm.yy'));
    this.dateFormatVll.add('dd/MM/yyyy', this.mcGod.t('cc.consumer.import.date-format.dd/mm/yyyy'));
    this.dateFormatVll.add('dd/MM/yy', this.mcGod.t('cc.consumer.import.date-format.dd/mm/yy'));
    this.dateFormatVll.add('MM/dd/yyyy', this.mcGod.t('cc.consumer.import.date-format.mm/dd/yyyy'));
    this.dateFormatVll.add('MM/dd/yy', this.mcGod.t('cc.consumer.import.date-format.mm/dd/yy'));
    this.dateFormatVll.add('dd-MM-yyyy', this.mcGod.t('cc.consumer.import.date-format.dd-mm-yyyy'));
    this.dateFormatVll.add('dd-MM-yy', this.mcGod.t('cc.consumer.import.date-format.dd-mm-yy'));
    this.dateFormatVll.add('yyyy-MM-dd', this.mcGod.t('cc.consumer.import.date-format.yyyy-mm-dd'));

    this.entityId = this.utilityService.getPropertyFromToken('entity_id');
    this.classCustomAtt = new McCustomAttribute();
    this.classCustomAtt.getCustomAttribute(this.csrConsumerService);
    this.resetCurrentTemplateMapping();

    if (this.webFileId > 0) {
      this.loaderFlg = true;
      this.mapping.loadByWebFileId(this.webFileId, () => {
        this.loaderFlg = false;
        this.notifyMessageObject.message = '';
        this.notifyMessageObject.type = '';
        this.mapping.mappingItems.items.reverse();
        this.templateMappings.setSortB('mappingName', 'ASC');
        this.templateMappings.loadTemplatesAndSharedByEntityId(() => {
          this.notifyMessageObject.message = '';
          this.notifyMessageObject.type = '';
          this.loaderFlg = false;
        }, () => {
          this.loaderFlg = false;
          if (this.templateMappings.apiErrorFlg) {
            this.notifyMessageObject.message = this.templateMappings.apiErrorMessage;
            this.notifyMessageObject.type = 'error';
          }
        });
      }, () => {
        this.disabledImportButtonFlag = true;
        this.notifyMessageObject.message = `${this.mapping.apiErrorMessage}`;
        this.notifyMessageObject.type = 'error';
        this.loaderFlg = false;
      });
      this.duplicateHandlingOptionsKvm = this.cdsLookupService.getImportCustomerDuplicateHandlingCdsKvm();
    }
  }

  // -----------------------------------------------------------------------------------

  checkUniqueTemplateMappingName(): boolean {
    const templateMappingsNames = this.templateMappings.items.map(item => item.mappingName);
    const notUniqueNames = templateMappingsNames.filter(item =>
      item === this.mcForm.getValue('txbMappingName'));
    return notUniqueNames.length === 0;
  }

  // -----------------------------------------------------------------------

  checkIfSaveMappingIsChecked(checked: boolean) {
      if (checked === false) {
        this.notifyMessageObject = {message: '', type: ''};
      }
  }

  checkImportMode($event: string) {
    console.log(this.mcForm, 'MCFORM 22222');
    this.notifyMessageObject = {message: '', type: ''};
  }
  // ------------------------------------------------------------------------------


  checkSelectDuplicates(): boolean {
    for (let i = 0; i < this.mapping.mappingItems.items.length - 1; i++) {
      if (this.mapping.mappingItems.items[i].attributeName !== 'Discard') {
        for (let j = i + 1; j < this.mapping.mappingItems.items.length; j++) {
          if (this.mapping.mappingItems.items[i].attributeName === this.mapping.mappingItems.items[j].attributeName) {
            this.translateService.get('cc.common.import-consumers-invalid-selection').subscribe(translatedWord =>
              this.notifyMessageObject = {message: translatedWord + `:
               ${this.mapping.mappingItems.items[i].columnName}, ${this.mapping.mappingItems.items[j].columnName}`, type: 'error'});
            return true;
          }
        }
      }
    }
    this.notifyMessageObject = {message: '', type: ''};
    return false;
  }

  // -----------------------------------------------------------------------------

  actPrev() {
    this.eventPrevStep.emit();
  }

  actCancel() {
    this.eventCancel.emit();
  }

  // ------------------------------------------------------------------------------

  actUpload() {
    this.loaderFlg = true;
    this.disabledImportButtonFlag = true;
    const tempArray = this.mapping.mappingItems.items.filter(item => !item.attributeName);
    if (this.checkSelectDuplicates()) {
      this.disabledImportButtonFlag = false;
      this.loaderFlg = false;
    } else if ( tempArray.length > 0 ) {
      this.notifyMessageObject = {message: `Choose mapping key for ${tempArray[0].columnName}`, type: 'error'};
      this.disabledImportButtonFlag = false;
      this.loaderFlg = false;
    } else if (this.mcForm.getValue('importMode') === '') {
      this.notifyMessageObject = {message: 'Import mode is required. Please choose an appropriate import mode.', type: 'error'};
      this.disabledImportButtonFlag = false;
      this.loaderFlg = false;
    } else {
      this.notifyMessageObject = {message: '', type: ''};
      if (this.mcForm.getValue('saveMappingCb') === true) {
        if (this.mcForm.getValue('txbMappingName') === '') {
          this.notifyMessageObject = {message: 'Enter mapping name', type: 'error'};
          this.disabledImportButtonFlag = false;
          this.loaderFlg = false;
        } else if (!this.checkUniqueTemplateMappingName()) {
          this.notifyMessageObject = {message: 'Mapping name must be unique', type: 'error'};
          this.disabledImportButtonFlag = false;
          this.loaderFlg = false;
        } else {
          this.mapping.mappingName = this.mcForm.getValue('txbMappingName');
        }
        this.mapping.sharedFlg = this.mcForm.getValue('shareMappingCb');
      }
      this.mapping.save(() => {
        this.notifyMessageObject.message = '';
        this.notifyMessageObject.type = '';
        const dateFormatSelectValue = this.mcForm.getValue('dateFormat');
        const duplicate = this.mcForm.getValue('importMode');
        this.csrConsumerImportService.importConsumers(this.mapping.id, duplicate, dateFormatSelectValue).subscribe( res => {
          this.notifyMessageObject.message = '';
          this.notifyMessageObject.type = '';
          this.eventImported.emit(this.mapping.idWebFile);
          this.disabledImportButtonFlag = false;
          this.loaderFlg = false;
        }, errorObject => {
          this.notifyMessageObject.message = errorObject.error.message;
          this.notifyMessageObject.type = 'error';
          this.disabledImportButtonFlag = false;
          this.loaderFlg = false;
        }).add(() => {this.templateMappings.loadTemplatesAndSharedByEntityId(); });
      }, () => {
        this.notifyMessageObject.message = this.mapping.apiErrorMessage;
        this.notifyMessageObject.type = 'error';
        this.disabledImportButtonFlag = false;
        this.loaderFlg = false;
      });
    }
  }

  // ---------------------------------------------------------------------

  onMappingTableCellEdited(event: MccFiTable2CellEditedData) {
    this.resetCurrentTemplateMapping();
    this.notifyMessageObject = {message: '', type: ''};
  }

  // ---------------------------------------------------------------------

  resetCurrentTemplateMapping() {
    this.currentTemplateMapping = new CsrMapping();
    this.currentTemplateMapping.mappingName = 'Custom mapping';
  }

  // ------------------------------------------------

  public onMappingItemTableCellConfig(event: MccFiTable2CellEditConfigData): void {
    const optionsKvm = new Map<string, string>();
    optionsKvm.set('', McGod.t('cc.common.edit.please-choose'));
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for ( let i = 0; i < this.classCustomAtt.entityAttributesList.length; i++) {
      optionsKvm.set(this.classCustomAtt.entityAttributesList[i], this.classCustomAtt.entityAttributesList[i]);
    }
    optionsKvm.set('Discard', 'DISCARD');

    const config: MccFiSelectOptionConfig = event.editComponentConfigObj;
    config.optionsKvm = optionsKvm;
    config.selectedValue = event.dataItemObj.execPropertyOrMethodByName(event.columnConfigObj.editObjectPropertyOrMethodName); // TODO HACK
  }

  onTemplateMappingChanged(chosenMapping: CsrMapping) {
    this.closeChooseMappingMcb();
    this.currentTemplateMapping = chosenMapping;
    this.mapping.mappingItems.updateItems(this.currentTemplateMapping.mappingItems.items, 'columnName', 'attributeName');
    this.checkSelectDuplicates();
  }

  actShowChooseMappingMcb() {
    this.showChooseMappingMcb.setTrue();
  }

  closeChooseMappingMcb() {
    this.showChooseMappingMcb.setFalse();
  }

  actShowCreateCustomAttributeMcb() {
    this.showCreateCustomAttributeMcb.setTrue();
  }

  closeCreateCustomAttributeMcb() {
    this.showCreateCustomAttributeMcb.setFalse();
  }

  eventFromComponentTEST() {
  }

}
