/*BbmTestObj*/
import {McProcessFlowCriteriaGENERATED} from '../_generated/mc-process-flow-criteria-generated.model';

export class McProcessFlowCriteria extends McProcessFlowCriteriaGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessFlowCriteria();
  }

  // ---------------------------------------------------------------------
}
