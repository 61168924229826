import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {McGod} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-role-basic-info',
  templateUrl: './mc-role-basic-info.component.html'
})
export class McRoleBasicInfoComponent implements OnInit {

  roleNameNotValid!: boolean;
  descriptionNotValid!: boolean;
  setRoleAsATemplate!: boolean;
  responseMessage!: boolean;
  isRegisterFormValid!: boolean;
  lockSaveBtn!: boolean;
  message!: string;
  type!: string;
  registerForm!: FormGroup;
  errorObject = {
    type: '',
    message: ''
  };

  /*Events*/
  @Input() selectedEntity!: any;
  @Input() editRole!: any;
  public isSetTemplateCheckboxDisabled!: boolean;

  /*TODO: this is hack solution until we solve validation in stepper*/
  @Input() set selectedStep(step: number) {
    if (this.registerForm) {
      const descriptionField = this.registerForm.get('description');
      if (step === 0 && descriptionField && !descriptionField.errors) {
        descriptionField.setValue(descriptionField.value.trim());
      }
    }
  }

  @Input() set resetForm(value: any) {
    if (value && value.reset) {
      this.registerForm && this.registerForm.reset();
    }
  }

  @Output() onFormValid = new EventEmitter<boolean>();
  @Output() onNextStep = new EventEmitter<object>();
  @Output() isDataFormInvalid = new EventEmitter<boolean>();
  public mcGod = McGod.getInstance();


  /*Constructor*/
  constructor(private formBuilder: FormBuilder) {
  }

  /*On init class*/
  ngOnInit() {
    if (this.editRole) {
      this.isSetTemplateCheckboxDisabled = this.editRole.numberOfChildren > 0;
    }

    this.createForm();
    this.isFormValid();
    this.lockSaveBtn = true;
  }


  /*Get form controls*/
  get form() {
    return this.registerForm.controls;
  }

  // Regex for at least one letter in a string
  private getLetters(name: string) {
    const re = /(?=.*[a-zA-Z])/;
    return re.test(String(name));
  }


  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  // Unlock Next btn
  private shareUnlockProperty(valid: boolean) {
    this.isDataFormInvalid.emit(
      this.isRegisterFormValid = valid
    );
  }

  checkMandatoryInputFields() {
    let msgText = '';
    let msgType = '';
    const formData = this.registerForm.getRawValue();
    const roleName = formData.caption;
    const description = formData.description;
    const status = formData.status;
    const isRoleNameValid = this.getLetters(roleName);

    if (!isRoleNameValid && roleName.length >= 2) {
      msgText = 'Numbers or special characters without letters are not allowed!';
      msgType = 'error';
      this.roleNameNotValid = true;
      this.responseMessage = true;
      this.setNotificationMessage(msgText, msgType);
      this.shareUnlockProperty(true);
      this.lockSaveBtn = true;
    } else if (roleName.length < 2) {
      msgText = 'Role name must have at least 2 characters!';
      msgType = 'error';
      this.roleNameNotValid = true;
      this.responseMessage = true;
      this.setNotificationMessage(msgText, msgType);
      this.shareUnlockProperty(true);
      this.lockSaveBtn = true;
    } else if (description.length < 1) {
      msgText = 'Please fill in the mandatory fields.';
      msgType = 'error';
      this.descriptionNotValid = true;
      this.responseMessage = true;
      this.setNotificationMessage(msgText, msgType);
      this.shareUnlockProperty(true);
      this.lockSaveBtn = true;
    } else if (status === null) {
      msgType = 'error';
      msgText = 'Please fill in the mandatory fields.';
      this.roleNameNotValid = false;
      this.setNotificationMessage(msgText, msgType);
      this.shareUnlockProperty(true);
      this.lockSaveBtn = true;
    } else {
      this.shareUnlockProperty(false);
      this.lockSaveBtn = false;
      this.responseMessage = false;
      this.roleNameNotValid = false;
      this.descriptionNotValid = false;
    }
  }

  /*Check valid fields*/
  public isFormValid() {
    /*Find some invalid field*/
    const isInvalid = Object.keys(this.form).some(item => {
        return this.form[item].invalid;
      }
    );
    this.onFormValid.emit(isInvalid);
    this.onNextStep.emit(this.registerForm.getRawValue());
  }


  /*Create form*/
  private createForm() {
    this.registerForm = this.formBuilder.group({
      status: [this.editRole && this.editRole.statusCode || 'active', Validators.required],
      caption: [this.editRole && this.editRole.caption || '', Validators.required],
      description: [this.editRole && this.editRole.description || '', Validators.required],
      setRoleAsATemplate: [this.editRole && this.editRole.templateFlg || false]
    });
  }


  onCheckboxClicked() {
    if (this.isSetTemplateCheckboxDisabled) {
      return false;
    } else {
      return null;
    }
  }
}
