import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'mc-edit-entity-wrapper',
  templateUrl: './mc-edit-entity-wrapper.component.html'
})
export class McEditEntityWrapperComponent implements OnInit {

  entityId!: any;

  constructor(private activatedRoute: ActivatedRoute) {

    /*Set unique Id for current entity*/
    this.activatedRoute.paramMap.subscribe(data => {
      if (data.get("id")) {
        this.entityId = data.get("id");
      }
    });
  }

  ngOnInit() {
  }

}
