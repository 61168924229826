<div class="row p-0 m-0 d-flex flex-row align-items-center">
  <h3 class="pr-2 pt-1" style="text-transform: capitalize">Pricelists created from template '{{mcPriceList.title}}'</h3>
</div>

<div>

  <mcc-message [infoMsg]="infoMsg"></mcc-message>

  <div *ngIf="mcPriceList.apiSuccessFlg">
    <mcc-fi-table-3 [objectList]="mcPriceListList"
                    [table3Config]="mcPricelistsCreatedFromTableConfig"></mcc-fi-table-3>
  </div>

  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary move-right" (eventClicked)="actClose();"
                label="Close"></mcc-button>

  </div>

</div>

