import {EbInkassoExportFilterGENERATED} from '../_generated/eb-inkasso-export-filter-generated.model';

export class EbInkassoExportFilter extends EbInkassoExportFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbInkassoExportFilter();
  }

  // ---------------------------------------------------------------------
}
