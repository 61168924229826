import {McPriceListFilterGENERATED} from '../_generated/mc-price-list-filter-generated.model';

export class McPriceListFilter extends McPriceListFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McPriceListFilter();
  }

  // ---------------------------------------------------------------------
}
