<!--Create Role modal-->
<ng-template #content let-modal>

  <!--Modal wrapper-->
  <div class="mc-role-create-wrapper">

    <!--Modal header-->
    <div class="modal-header border-0 mc-role-modal-header">

      <!--Entity root-->
      <div class="d-block">
        <h4 class="modal-title">{{'cc.roles.edit.create-role' | translate}}:</h4>
        <p class="m-0 text-secondary h5">
          {{'cc.roles.edit.root-entity' | translate}}:
          <span class="mc-active border-bottom-0" *ngFor="let entity of selectedEntityPath; let i = index">
            {{entity}} <span *ngIf="i !== selectedEntityPath.length - 1" class="text-dark h6 px-1"><small> > </small></span>
          </span>
        </p>
      </div>

      <!--Close button-->
      <button aria-label="Close" type="button" class="close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div><!--Modal header /end-->

    <!--Modal body-->
    <div class="modal-body pt-0">

      <!--Stepper-->
      <lib-mc-stepper [isDataFormInvalid]="isFormValid" (isGoToNextStep)="val($event)" (selectedStep)="onGetSelectedStep($event)">

        <!--Role basic-->
        <cdk-step>
          <mc-role-basic-info [selectedStep]="selectedStep"
                              (isDataFormInvalid)="roleDataFormValid($event)"
                              [resetForm]="resetChildForm"
                              [selectedEntity]="selectedEntityPath[selectedEntityPath.length - 1]"
                              (onNextStep)="getFirstStepForm($event)"
                              (onFormValid)="onCurrentFormValid($event)"></mc-role-basic-info>
        </cdk-step>

        <!--Role permission-->
        <cdk-step>
          <mc-role-permissions [selectedStep]="selectedStep"
                               [resetForm]="resetChildForm"
                               (onSelectPermission)="getSecondStepForm($event)"
                               (isDataFormInvalid)="roleDataFormValid($event)"></mc-role-permissions>
        </cdk-step>

        <!--Role summary-->
        <cdk-step>
          <mc-role-create-summary #sbuComponent [formSummery]="formSummery"
                                  (closeModal)="closeModal($event)"
                                  (onCreateNewForm)="resetForm($event)"
                                  [selectedEntity]="selectedEntityPath[selectedEntityPath.length - 1]"></mc-role-create-summary>
        </cdk-step>
      </lib-mc-stepper><!--Stepper /end-->

      <!--Created roles-->
      <div *ngIf="createdRoles.length > 0" class="mc-created-roles">
        <span class="text-uppercase font-weight-bold pr-3">{{ 'cc.common.edit.created' | translate }}:</span>
        <span *ngFor="let role of createdRoles; let i = index">
          <span class="font-weight-bold"> {{selectedEntity}}</span> {{role}}
          <span class="ml-1 text-muted mc-cursor-pointer" (click)="removeCreatedRole(role)">x</span>
          <span *ngIf="i !== createdRoles.length - 1" class="px-2 text-muted">|</span>
        </span>
      </div><!--Create roles /end-->
    </div><!--Modal body /end-->
  </div><!--Modal wrapper /end-->
</ng-template><!--Create Role modal /end-->

<!--Create new role-->
<button class="system-action-btn" id="newUserModalButton" (click)="openXl(content)">
  <i class="fas fa-plus-circle"></i>
  {{'cc.roles.view.create-new-role' | translate}}
</button>
