/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {ZzPocConsumerFilter} from '../models/zz-poc-consumer-filter.model';

export class ZzPocConsumerFilterGENERATED extends BaseObjectFilter{    public fnames: string[] = [];
    public searchTerm: string;
    public statusCds: string[] = [];
    public typeCds: string[] = [];

  public properties: string[] = ['fnames', 'searchTerm', 'statusCds', 'typeCds', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): ZzPocConsumerFilter {
    console.error('MTCN-ERROR: Not Implemented: ZzPocConsumerFilter::createNewInstance(). Add this method to final class and return new ZzPocConsumerFilter();');
      throw new Error('Cannot ZzPocConsumerFilter::createNewInstance(). Add this method to final class and return new ZzPocConsumerFilter();');
  }

}
