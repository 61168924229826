import {Component, OnInit} from '@angular/core';
import {
  EbSepaFileTransactionList,
  EbSepaFileTransaction,
  EbSepaFileTransactionTable3Config,
  EbSepaFileTransactionFilter,
  McNotifyMessageModel,
  McGod,
  McValueLabelList,
  McEntity2List,
  McBoolean,
  McHtmlButton,
  EbSepaFileTransactionService,
  McSlideActionSet,
  McSlideActionItem,
  BaseDirective, EbTransactionAdjustment, EbFactoringService, EbFactoring, CurrencyUtil,
  McConsumerBalanceService, SortCriteriaList
} from '@miticon-ui/mc-core';
import {McForm} from '@miticon-ui/mc-core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

const enum DialogMarkAsSentToInkassoAction {
  ACT_CANCEL = 'btn-secondary',
  ACT_YES = 'btn-primary'
}

@Component({
  selector: 'mc-out-factoring-transaction-list-page',
  templateUrl: './mc-out-factoring-transaction-list-page.component.html',
  styleUrls: ['./mc-out-factoring-transaction-list-page.component.scss']
})
export class McOutFactoringTransactionListPageComponent extends BaseDirective implements OnInit {

  mcForm = new McForm();
  entitiesWithTransactions = new McEntity2List();
  ebSepaFileTransactionList!: EbSepaFileTransactionList;
  ebSepaFileTransactionTable3Config = new EbSepaFileTransactionTable3Config();
  ebSepaFileTransactionFilter = new EbSepaFileTransactionFilter();
  receivedListFromTable: any;
  ebSepaFileTransactionCdVll: McValueLabelList = EbSepaFileTransaction.getStatusCdVll();
  pausedUnpausedCdVll: McValueLabelList = EbSepaFileTransaction.getPauseUnpausedCdVll();
  inDunningCdVll: McValueLabelList = EbSepaFileTransaction.getInDunningFlgCdVll();
  completedDunningCdVll: McValueLabelList = EbSepaFileTransaction.getDunningCompletedCdVll();
  typeCdVll: McValueLabelList = EbSepaFileTransaction.getFactoredServiceTypeCdVll();
  entitiesWithTransactionsVll!: McValueLabelList;
  notifyMessage!: McNotifyMessageModel;
  showSepaExportMcb = new McBoolean();
  showTransactionAddPaymentMcb = new McBoolean();
  showTransactionProfileMcb = new McBoolean();
  showMarkAsSentToInkassoMcb = new McBoolean();
  showExportMcb = new McBoolean();
  buttonsMarkAsSentToInkassoDialog = new Map<string, string>();
  typeCds: any[] = [];
  statusCds: any[] = [];
  entityIds: any[] = [];
  successMsg!: string;
  additionalTableInfo!: string;
  filteredAmount!: string;
  actionEbSepaFileTransactionId = 0;
  actionEbSepaFileTransaction!: EbSepaFileTransaction;
  showAddEditTransactionMcb = new McBoolean();
  mcGod = McGod.getInstance();
  checkedTransactionList = new EbSepaFileTransactionList();
  markAsSentToBankDialogMessage!: string;

  selectedTransactionList = new EbSepaFileTransactionList();
  showAddPaymentForMultipleTransactions = new McBoolean();
  actionSet = new McSlideActionSet();
  numberOfSelectedItems!: number;
  transactionsExportedFlg = false;
  showPauseTransactionMcb = new McBoolean();
  showStornoMcb = new McBoolean();
  clearedAllMultipleFilters = new Subject<boolean>();
  clearDateRangeSubject = new Subject<boolean>();
  paymentDueDateFrom: any;
  paymentDueDateTo: any;
  statusLabel!: string;
  loadingFlg = false;
  showConsumerProfileMcb = new McBoolean();
  consumerId!: number;
  ebSepaFileTransaction: EbSepaFileTransaction = new EbSepaFileTransaction();
  isExportAll = false;
  currencyCode!: string;
  ebSepaFileTransactions!: [];
  status!: string;
  balanceAmount!: number;
  sortCriteriaList = new SortCriteriaList();
  transactionStornoIds: any[] = [];
  transactionStornoId!: number;
  // ---------------------------------------------------------------------
  constructor(private router: Router,
              private transactionService: EbSepaFileTransactionService,
              private factoringService: EbFactoringService,
              private mcConsumerBalanceService: McConsumerBalanceService,
              private toastr: ToastrService) {
    super();
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.notifyMessage = {message: '', type: ''};
    this.buttonsMarkAsSentToInkassoDialog.set('btn-primary', 'Yes');
    this.buttonsMarkAsSentToInkassoDialog.set('btn-secondary', this.mcGod.t('cc.common.edit.cancel'));
    this.ebSepaFileTransactionCdVll.items.splice(2, 2);
    this.entitiesWithTransactions.loadAllWhereFactoringTransactionParentIsLoggedInEntity(() => {
      this.entitiesWithTransactions.items.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
      this.entitiesWithTransactionsVll = this.entitiesWithTransactions.toVll('id', 'name');
    });
    this.ebSepaFileTransactionList = new EbSepaFileTransactionList();
    this.ebSepaFileTransactionList.setPager(0, 100);
    this.actionSet.addItem('Storno', EbSepaFileTransaction.ACTION_STORNO, [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_EDIT]);
    this.actionSet.addItem(this.mcGod.t('cc.transactions.record-payment-s'), EbSepaFileTransaction.ACTION_RECORD_PAYMENT, [McGod.PERM_EB_FACTORING_MY_TRANSACTION_RECORD_PAYMENT]);
    this.actionSet.addItem('Mark as "Sent to Inkasso"', EbSepaFileTransaction.ACTION_MARK_AS_SENT_TO_INKASSO, [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_MARK_AS_SENT_TO_INKASSO]);
    this.actionSet.addItem('Export', EbSepaFileTransaction.ACTION_EXPORT, [McGod.PERM_EB_FACTORING_OUT_INKASSO_EXPORT, McGod.PERM_EB_FACTORING_OUT_SEPA_EXPORT]);
    this.actionSet.addItem('Pause/Unpause transactions', EbSepaFileTransaction.ACTION_PAUSE_TRANSACTIONS, [McGod.PERM_EB_FACTORING_MY_TRANSACTION_PAUSE_TRANSACTION]);

    this.actLoad();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.ebSepaFileTransactionFilter.outFactoringFlg = true;
    const entitiesIdToStringArr = this.entityIds.map(e => e.toString());
    const filteredStatusCds = this.statusCds.filter(arr => arr.length);
    const filteredEntityIds = entitiesIdToStringArr.filter(arr1 => arr1.length);
    const filteredTypeCds = this.typeCds.filter(arr2 => arr2.length);
    this.ebSepaFileTransactionFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.ebSepaFileTransactionFilter.statusCds = filteredStatusCds;
    this.ebSepaFileTransactionFilter.childEntityIds = filteredEntityIds;
    this.ebSepaFileTransactionFilter.pausedFilterOptionCd = this.mcForm.getValue('selPausedUnpaused');
    this.ebSepaFileTransactionFilter.inDunningFilterOptionCd = this.mcForm.getValue('selInDunning');
    this.ebSepaFileTransactionFilter.dunningCompletedFilterOptionCd = this.mcForm.getValue('selCompletedDunning');
    this.ebSepaFileTransactionFilter.factoringTypeWithDelayFilterOptionCds = filteredTypeCds;
    this.ebSepaFileTransactionFilter.transactionPaymentDueDateTimeFromDate = this.paymentDueDateFrom ? this.paymentDueDateFrom : null;
    this.ebSepaFileTransactionFilter.transactionPaymentDueDateTimeToDate = this.paymentDueDateTo ? this.paymentDueDateTo : null;
    // this.ebSepaFileTransactionFilter.factoringTypeWithDelayFilterOptionCd = this.mcForm.getValue('selType');

    this.ebSepaFileTransactionList.loadByFilter(this.ebSepaFileTransactionFilter, () => {
      this.ebSepaFileTransactionList.items.forEach(item => {
        this.currencyCode = this.factoringService.parentFactorings?.find(factoring => factoring.id === item.idEbFactoring)?.mcCurrency.code;
        item.amountStr = this.format(item.amountDue);
      });

      const filteredAmount = this.ebSepaFileTransactionList.items.map(item => {
        return item.amountDue;
      });

      this.filteredAmount = this.format(filteredAmount.reduce((a, b) => a + b, 0));
    });
  }

  receiveStateOnSelect(event: any) {
    this.numberOfSelectedItems = event.getCount();
    this.receivedListFromTable.exportedItems = event;
  }

  receiveFlag() {
    this.router.navigate([`admin/entity/${McGod.getLoggedEntityIdFromToken()}/factoring/export-sepa`]);
    this.receivedListFromTable.exportedItems = [];

  }

  actClearFilter() {
    this.clearedAllMultipleFilters.next(true);
    this.clearDateRangeSubject.next(true);
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.mcForm.getControl('selPausedUnpaused')?.get('value')?.setValue('');
    this.mcForm.getControl('selInDunning')?.get('value')?.setValue('');
    this.mcForm.getControl('selCompletedDunning')?.get('value')?.setValue('');
    this.actLoad();
    this.notifyMessage = {message: '', type: ''};
  }

  actOnTransactionTableItemAction(itemAction: McHtmlButton) {
    this.actionEbSepaFileTransactionId = itemAction.item.id;
    this.actionEbSepaFileTransaction = itemAction.item;

    switch (itemAction.actionCdForEvent) {
      case EbSepaFileTransaction.ACTION_EDIT:

        break;
      case EbSepaFileTransaction.ACTION_RECORD_PAYMENT:

        this.showTransactionAddPaymentMcb.setTrue();
        break;
      case EbSepaFileTransaction.ACTION_VIEW_DETAILS:
        this.showTransactionProfileMcb.setTrue();
        break;
      case EbSepaFileTransaction.ACTION_PAUSE_TRANSACTIONS:
        this.showPauseTransactionMcb.setTrue();
        break;
      case EbSepaFileTransaction.ACTION_MARK_AS_SENT_TO_INKASSO:
        this.actMarkAsSentToInkasso(itemAction.item);
        break;
      case EbSepaFileTransaction.ACTION_MARK_AS_RETURNED_FROM_INKASSO:
        this.actMarkAsReturnedFromInkasso(itemAction.item);
        break;
      case EbSepaFileTransaction.ACTION_EXPORT:
        this.actShowExportMcb(itemAction.item);
        break;
      case EbSepaFileTransaction.ACTION_VIEW_BILLING_ITEMS:
        this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/s/transaction-billing-items/${this.actionEbSepaFileTransactionId}`);
        break;
      case EbSepaFileTransaction.ACTION_VIEW_PAYOUT_ITEMS:
        this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/s/transaction-payout-items/${this.actionEbSepaFileTransactionId}`);
        break;
      case EbSepaFileTransaction.ACTION_VIEW_CONSUMER_PROFILE:
        this.showConsumerProfileMcb.setTrue();
        this.consumerId =  itemAction.item.idCsrConsumer;
        break;
      case EbSepaFileTransaction.ACTION_STORNO:
        this.transactionStornoIds = [];
        this.transactionStornoId = itemAction.item.id;
        this.transactionStornoIds.push(this.transactionStornoId);
        this.showStornoMcb.setTrue();
        this.status = EbTransactionAdjustment.TYPE_CD_STORNO;
        this.ebSepaFileTransaction = new EbSepaFileTransaction();
        this.ebSepaFileTransaction.apiService.updateStatus(this.transactionStornoIds, this.status).pipe(take(1)).subscribe(() => {
          this.notifyMessage = {message: McGod.t('cc.common.the-payment-for-the-transaction-was-recorded-successfully'), type: 'success'};
          this.actLoad();
        });
        break;
      case EbSepaFileTransaction.ACTION_SETTLE_WITH_BALANCE:
        const consumerId = itemAction.item.idCsrConsumer;
        // this.getConsumerBalanceFromService(consumerId);
        break;
    }
  }

  // getConsumerBalanceFromService(consumerId: number) {
  //   this.mcConsumerBalanceService.getConsumerBalance(consumerId).subscribe((res) => {
  //     if (Object.keys(res).length === 0) {
  //       this.balanceAmount = 0;
  //     } else {
  //       // @ts-ignore
  //       this.balanceAmount = res[0].amount;
  //     }
  //     this.actSettleWithBalance(this.actionEbSepaFileTransactionId);
  //   });
  // }

// ---------------------------------------------------------------------

  actSettleWithBalance(ebSepaFileTransactionId: number) {
    const body: any = {};
    body.paymentMethod = 'CREDIT';
    body.amount = null;

    if (this.balanceAmount === 0 || !this.balanceAmount) {
      this.toastr.error(McGod.t('cc.consumer.overview.this-action-cannot-be-completed-the-balance-on-the-consumers-account-is-0') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
    } else {
      this.ebSepaFileTransaction.apiService.payTransaction(ebSepaFileTransactionId, body)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.toastr.success(McGod.t('cc.consumer.transaction-has-been-successfully-settled-with-balance') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
          this.actLoad();
        }, error => {
          this.toastr.error(McGod.t('cc.consumer.transaction-has-not-been-successfully-settled-with-balance-try-again-later') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
        });
    }
  }

  actOnTransactionProfileClose() {
    this.actLoad();
    this.showTransactionProfileMcb.setFalse();
  }

  // ---------------------------------------------------------------------
  actOnTransactionAddPaymentClose() {
    this.notifyMessage = {message: 'The payment for transaction was recorded successfully', type: 'success'};
    this.actLoad();
    this.showTransactionAddPaymentMcb.setFalse();
  }

  // ---------------------------------------------------------------------



  actOnAddTransactionSaved() {
    this.actLoad();
    this.showAddEditTransactionMcb.setFalse();
  }

  actMarkAsSentToInkasso(transaction = null) {
    if (transaction) {
      this.checkedTransactionList = new EbSepaFileTransactionList();
      this.checkedTransactionList.addItem(transaction);
    } else {
      this.checkedTransactionList = this.ebSepaFileTransactionList.getSelectedObjectList();
    }
    if (this.checkedTransactionList.getCount() === 1) {
      this.markAsSentToBankDialogMessage = 'Are you sure you want to mark ' + this.checkedTransactionList.getCount() + ' transaction as "Sent to Inkasso"?';
    } else {
      this.markAsSentToBankDialogMessage = 'Are you sure you want to mark ' + this.checkedTransactionList.getCount() + ' transactions as "Sent to Inkasso"?';
    }
    this.showMarkAsSentToInkassoMcb.setTrue();
  }

  actMarkAsReturnedFromInkasso(transaction: EbSepaFileTransaction) {
    transaction.statusCd = EbSepaFileTransaction.STATUS_CD_RETURNED_FROM_INKASSO;
    const statusCd = transaction.statusCd;
    this.transactionService.markAsReturnedFromInkasso(transaction).subscribe(() => {
      this.actLoad();
      this.notifyMessage = {message: `${this.mcGod.t('cc.transactions.transaction-s-are-successfully-marked-as-returned-from-inkasso')}.`, type: 'success'};
      this.loadingFlg = false;
    }, error => {
      this.notifyMessage = {message: error.error.message, type: 'error'};
      transaction.statusCd = statusCd;
      this.loadingFlg = false;
    });
  }

  onMcDialogBoxMarkAsSentToInkassoActionButtonClicked(action: string) {
    if (action === DialogMarkAsSentToInkassoAction.ACT_CANCEL) {
      this.showMarkAsSentToInkassoMcb.setFalse();
    } else {
      this.transactionService.markAsSentToInkasso(this.checkedTransactionList.getIds()).subscribe(() => {
        this.showMarkAsSentToInkassoMcb.setFalse();
        this.actLoad();
        this.notifyMessage = {message: 'Transactions are successfully marked as "Sent to Inkasso".', type: 'success'};
      }, error => {
        this.showMarkAsSentToInkassoMcb.setFalse();
        this.notifyMessage = {message: error.error.message, type: 'error'};
      });
    }
  }

  actPaymentMultipleTransactionsSaved() {
    this.notifyMessage.message = `The payment for ${this.selectedTransactionList.getCount()} transaction(s) was recorded successfully`;
    this.notifyMessage.type = 'success';
    this.actLoad();
    this.showAddPaymentForMultipleTransactions.setFalse();
    this.selectedTransactionList = new EbSepaFileTransactionList();
    this.numberOfSelectedItems = 0;
  }

  paymentApiErrorEvent(errorMsg: string) {
    this.notifyMessage.message = errorMsg;
    this.notifyMessage.type = 'error';
  }

  actCancelAddEditMultiplePayments() {
    this.showAddPaymentForMultipleTransactions.setFalse();
    this.selectedTransactionList = new EbSepaFileTransactionList();
  }

  multipleRecordPaymentShowMcb() {
    this.showAddPaymentForMultipleTransactions.setTrue();
    this.selectedTransactionList = this.ebSepaFileTransactionList.getSelectedObjectList();
  }

  slideActionItemClicked(actionItem: McSlideActionItem) {
    if (actionItem.actionCdForEvent === EbSepaFileTransaction.ACTION_RECORD_PAYMENT) {
      this.multipleRecordPaymentShowMcb();
    } else if (actionItem.actionCdForEvent === EbSepaFileTransaction.ACTION_MARK_AS_SENT_TO_INKASSO) {
      this.actMarkAsSentToInkasso();
    } else if (actionItem.actionCdForEvent === EbSepaFileTransaction.ACTION_EXPORT) {
      this.isExportAll = false;
      this.actShowExportMcb();
    } else if (actionItem.actionCdForEvent === EbSepaFileTransaction.ACTION_STORNO) {
      this.actStornoMcb();
    } else if (actionItem.actionCdForEvent === EbSepaFileTransaction.ACTION_PAUSE_TRANSACTIONS) {
      this.selectedTransactionList = this.ebSepaFileTransactionList.getSelectedObjectList();

      const isItemRejected = this.selectedTransactionList.items.some(item => item.isStatusCdREJECTED());
      const isItemPaid = this.selectedTransactionList.items.some(item => item.isStatusCdPAID());
      const isItemSentToInkasso = this.selectedTransactionList.items.some(item => item.isStatusCdSENT_TO_INKASSO());
      const isItemSettled = this.selectedTransactionList.items.some(item => item.isStatusCdSETTLED());
      const isItemCancelled = this.selectedTransactionList.items.some(item => item.isStatusCdCANCELLED());
      const isTransactionPaused = this.selectedTransactionList.items.some(item => item.pausedFlg);
      if (!this.selectedTransactionList.items.every(item => item.pausedFlg)) {
        this.notifyMessage.message = 'Selected transactions does not have same pause status.';
        this.notifyMessage.type = 'error';
      }
      if (!isItemRejected && !isItemSentToInkasso && !isItemSettled && !isItemCancelled) {
        this.notifyMessage = {message: '', type: ''};
        this.showPauseTransactionMcb.setTrue();
      } else {
        this.notifyMessage.message = 'You can only pause not paused transactions in statuses "Accepted", "Paid", "For dunning", "Returned", "Sending to inkasso" or "Should go to inkasso".';
        this.notifyMessage.type = 'error';
      }
    }
  }

  actStornoMcb() {
    this.transactionStornoIds = [];
    this.transactionStornoIds = this.selectedTransactionList.items.map(item => item.id);
    this.selectedTransactionList = this.ebSepaFileTransactionList.getSelectedObjectList();
    this.selectedTransactionList.items.map(item => item.statusCd === EbTransactionAdjustment.TYPE_CD_STORNO);
    this.transactionStornoIds = this.selectedTransactionList.items.map(item => item.id);
    this.status = EbTransactionAdjustment.TYPE_CD_STORNO;
    this.ebSepaFileTransaction.apiService.updateStatus(this.transactionStornoIds , this.status).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.notifyMessage = {message: McGod.t('cc.common.the-payment-for-the-transactions-was-recorded-successfully'), type: 'success'};
      this.actLoad();
    });
  }

  actCanceledExportMcb() { // In hurry for delivery
    if (this.transactionsExportedFlg) {
      this.checkedTransactionList = new EbSepaFileTransactionList();
      this.showExportMcb.setFalse();
      this.actLoad();
      this.numberOfSelectedItems = 0;
    } else {
      this.showExportMcb.setFalse();
    }
  }

  actShowExportMcb(transaction = null) {
    if (transaction) {
      this.checkedTransactionList = new EbSepaFileTransactionList();
      this.checkedTransactionList.addItem(transaction);
    } else {
      this.checkedTransactionList = this.ebSepaFileTransactionList.getSelectedObjectList();
    }
    this.transactionsExportedFlg = false;
    this.showExportMcb.setTrue();
  }

  actExportTransactionsSaved(exportType: string) {
    this.checkedTransactionList = new EbSepaFileTransactionList();
    this.showExportMcb.setFalse();
    this.actLoad();
    this.numberOfSelectedItems = 0;
  }

  actTransactionsExported() {
    this.transactionsExportedFlg = true;

    if (this.isExportAll) {
      const loggedEntity = McGod.getLoggedEntityIdFromToken();
      this.showExportMcb.setFalse();
      this.successMsg = `${McGod.t('cc.out-factoring.if-you-want-to-see-exported-file-click')} <a href="/#/entity/${loggedEntity}/out-factoring/sepa-out">${McGod.t('cc.payout.here')}.</a>`;
      this.actLoad();
    }
  }

  onTransactionPausedUnpaused(pausedFlg: boolean) {
    this.showPauseTransactionMcb.setFalse();
    if (pausedFlg) {
      this.notifyMessage = {message: 'The transaction was paused successfully', type: 'success'};
    } else {
      this.notifyMessage = {message: 'The transaction was unpaused successfully', type: 'success'};
    }
  }

  onPauseTransaction() {
    this.showPauseTransactionMcb.setFalse();
    this.actLoad();
    this.numberOfSelectedItems = 0;
    this.notifyMessage = {message: 'The transaction(s) was paused successfully', type: 'success'};
  }

  onUnpauseTransaction() {
    this.showPauseTransactionMcb.setFalse();
    this.actLoad();
    this.numberOfSelectedItems = 0;
    this.notifyMessage = {message: 'The transaction was unpaused successfully', type: 'success'};
  }

  onPauseTransactionCanceled() {
    this.showPauseTransactionMcb.setFalse();
  }

  onSelectedTypeCds(typesVll: []) {
    this.typeCds = typesVll;
  }

  onSelectedStatus(statusVll: []) {
    this.statusCds = statusVll;
  }

  onSelectedEntity(entitiesVll: []) {
    this.entityIds = entitiesVll;
  }
  onPaymentDueDateFrom(value: any) {
    if (value !== null) {
      this.paymentDueDateFrom = value;
    } else {
      this.paymentDueDateFrom = null;
    }
  }

  onPaymentDueDateTo(value: any) {
    if (value !== null) {
      this.paymentDueDateTo = value;
    } else {
      this.paymentDueDateTo = null;
    }
  }

  actCancel() {
    this.showConsumerProfileMcb.setFalse();
  }

  actExportAllTransactions() {
    this.isExportAll = true;
    this.checkedTransactionList = this.ebSepaFileTransactionList;
    this.transactionsExportedFlg = false;
    this.showExportMcb.setTrue();
  }

  format(value: number): string {
    if (value) {
      return CurrencyUtil.format(value, this.currencyCode);
    }
    return CurrencyUtil.format(0, this.currencyCode);
  }
}

