/*BbmTestObj*/
import {McConsumerImportGENERATED} from '../_generated/mc-consumer-import-generated.model';
import { McDateUtils } from '../common/mc-date-utils';

export class McConsumerImport extends McConsumerImportGENERATED {
  public static FILTER_USER = 'User';


  public static override createNewInstance() {
    return new McConsumerImport();
  }

  fldRecords() {
    return this.processedRecords + '/' + this.addedRecords + '/' + this.updatedRecords + '/' + this.skippedRecords;
  }

  fldCreatedAt() {
    return McDateUtils.newFormatDateTimeString(this.createdAt);
  }

  fldImportMode() {
    if (this.importModeCd === 'ADD') {
      return 'Add';
    } else if (this.importModeCd === 'ADDUPD') {
      return 'Add & Update';
    } else {
      return 'Update';
    }
  }

 /* updatedAtFormat() {
    return moment(this.updatedAt).format("ll");
  }
  createdAtFormat() {
    return moment(this.createdAt).format("ll");
  }*/
}
