import {Observable, Subject} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {McGod} from '../extra/mc-god.service';
import {McFormValidationTrigger} from './mc-form-validation-trigger';

export class McForm {
  formGroup: FormGroup;
  formBuilder: FormBuilder;
  validationTrigger: McFormValidationTrigger;

  constructor() {
    this.formBuilder = McGod.getService(FormBuilder);
    this.validationTrigger = new McFormValidationTrigger();
    this.formGroup = this.formBuilder.group({});
  }

  isValid() {
    this.validationTrigger.next();
    return this.formGroup.valid;
  }

  getValue(aCtrlName: string, aDefaultValue: any = '') {
    let result = aDefaultValue;
    if (this.getControl(aCtrlName)) {
      const tempResult =  this.formGroup.get(aCtrlName + '.value')?.value;
      if (tempResult != null) {
        result = tempResult;
      } else {
      }
    } else {
    }
    return result;
  }

  getValueArray(aCtrlName: string) {
    let val = this.getValue(aCtrlName, '');
    if (val instanceof Array) {
      // do nothing
    } else if ((val == null) || (val === '')) {
      val = [];
    } else {
      val = [val];
    }
    return val;
  }
  addControl(aCtrlName: string, aFormCtrlOrGroupObj: any) {
    this.formGroup.addControl(aCtrlName, aFormCtrlOrGroupObj);
  }

  getControl(aCtrlName: string) {
    return this.formGroup.get(aCtrlName);
  }

  getValidationTriggerObservable(): Observable<void> {
    return this.validationTrigger.asObservable();
  }

  getInvalidControls() {
    const invalidControlsArr: any[] = [];
    const controls = this.formGroup.controls;
    Object.entries(controls).forEach( control => {
      if (control[1].status === 'INVALID') {
        invalidControlsArr.push(control[1]);
      }
    });
    return invalidControlsArr;
  }
}
