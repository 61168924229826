
import {Table3Config} from '../extra/table-3-config';

// import {McProcessRunItemGENERATED} from '../_generated/mc-process-run-item-generated.model';

export class McProcessRunItemTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colBuildNextActionStartDatetime', 'Built Next Action Start Datetime ', 'buildNextActionStartDatetime', 'buildNextActionStartDatetime');
    this.addColumn('colBuiltFlg', 'Built flag', 'builtFlg', 'builtFlg');
    this.addColumn('colBuiltPaymentDueDatetime', 'Built Payment Due Datetime ', 'builtPaymentDueDatetime', 'builtPaymentDueDatetime');
    this.addColumn('colCsrConsumerAction', 'Csr Consumer Action', 'csrConsumerAction', 'csrConsumerAction');
    this.addColumn('colFeesAddedToTransactionFlg', 'Fees added to transaction Flg', 'feesAddedToTransactionFlg', 'feesAddedToTransactionFlg');
    this.addColumn('colIdCsrConsumerAction', 'Consumer Action', 'idCsrConsumerAction', 'idCsrConsumerAction');
    this.addColumn('colIdMcProcessFlow', 'McProcessFlow', 'idMcProcessFlow', 'idMcProcessFlow');
    this.addColumn('colIdMcProcessFlowLevel', 'McProcessFlowLevel Id', 'idMcProcessFlowLevel', 'idMcProcessFlowLevel');
    this.addColumn('colIdMcProcessItem', 'McProcessItem ID', 'idMcProcessItem', 'idMcProcessItem');
    this.addColumn('colIdMcProcessRun', 'McProcessRun ID', 'idMcProcessRun', 'idMcProcessRun');
    this.addColumn('colLastErrorMsg', 'Last Error Msg', 'lastErrorMsg', 'lastErrorMsg');
    this.addColumn('colMcProcessItem', 'Mc Process Item', 'mcProcessItem', 'mcProcessItem');
    this.addColumn('colMcProcessRun', 'Mc Process Run', 'mcProcessRun', 'mcProcessRun');
    this.addColumn('colNewItemFlg', 'New item flag', 'newItemFlg', 'newItemFlg');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');

*/
  }
}
