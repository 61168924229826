
import {Table3Config} from '../extra/table-3-config';

// import {CsrConsumerHistoryGENERATED} from '../_generated/csr-consumer-history-generated.model';

export class CsrConsumerHistoryTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colActionTypeCd', 'Action Type CD', 'actionTypeCd', 'actionTypeCd');
    this.addColumn('colFieldName', 'Field Name', 'fieldName', 'fieldName');
    this.addColumn('colFieldTypeCd', 'Field Type CD', 'fieldTypeCd', 'fieldTypeCd');
    this.addColumn('colIdCsrConsumer', 'Consumer ID', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colNewValue', 'New Value', 'newValue', 'newValue');
    this.addColumn('colOldValue', 'Old Value', 'oldValue', 'oldValue');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');

*/
  }
}
