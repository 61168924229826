export const ICON_MAPPING_TEMPLATE_BLACK = `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1083_11888)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25 3.75H5C3.625 3.75 2.5 4.875 2.5 6.25V23.75C2.5 25.125 3.625 26.25 5 26.25H25C26.375 26.25 27.5 25.125 27.5 23.75V6.25C27.5 4.875 26.375 3.75 25 3.75ZM11.25 21.25H7.5C6.8125 21.25 6.25 20.6875 6.25 20C6.25 19.3125 6.8125 18.75 7.5 18.75H11.25C11.9375 18.75 12.5 19.3125 12.5 20C12.5 20.6875 11.9375 21.25 11.25 21.25ZM11.25 16.25H7.5C6.8125 16.25 6.25 15.6875 6.25 15C6.25 14.3125 6.8125 13.75 7.5 13.75H11.25C11.9375 13.75 12.5 14.3125 12.5 15C12.5 15.6875 11.9375 16.25 11.25 16.25ZM11.25 11.25H7.5C6.8125 11.25 6.25 10.6875 6.25 10C6.25 9.3125 6.8125 8.75 7.5 8.75H11.25C11.9375 8.75 12.5 9.3125 12.5 10C12.5 10.6875 11.9375 11.25 11.25 11.25ZM23.375 13.9L19.4125 17.8625C18.925 18.35 18.125 18.35 17.6375 17.8625L15.875 16.0875C15.3875 15.6 15.3875 14.8125 15.875 14.325C16.3625 13.8375 17.15 13.8375 17.6375 14.325L18.525 15.2125L21.6125 12.125C22.1 11.6375 22.8875 11.6375 23.375 12.125L23.3875 12.1375C23.8625 12.625 23.8625 13.425 23.375 13.9Z" fill="#53589B"/>
</g>
<defs>
<clipPath id="clip0_1083_11888">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>
`
