import {McMenuItem} from './mc-menu-item';

export class McMenu {

  public menuItemList: McMenuItem[] = [];

  constructor() {
  }

  public addMenuItem(aName: string, aTranslationKey: string, aIcon: string, aPermission: any[], aRoute: string, aIsActive = false): McMenuItem {
    const newMenuItem = {
      name: aName,
      translationKey: aTranslationKey,
      icon: aIcon,
      route: aRoute,
      permission: aPermission,
      isActive: aIsActive
    };
    const menuItem = new McMenuItem(newMenuItem);
    this.menuItemList.push(menuItem);
    return menuItem;
  }
}
