
import {Table3Config} from '../extra/table-3-config';

// import {CsrConsumerImportGENERATED} from '../_generated/csr-consumer-import-generated.model';

export class CsrConsumerImportTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAddedRecords', 'Added records', 'addedRecords', 'addedRecords');
    this.addColumn('colCreatedAt', 'Date time created', 'createdAt', 'createdAt');
    this.addColumn('colCreatedBy', 'Created by', 'createdBy', 'createdBy');
    this.addColumn('colDateFormat', 'Date format', 'dateFormat', 'dateFormat');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdMcSystemUser', 'User ID', 'idMcSystemUser', 'idMcSystemUser');
    this.addColumn('colIdWebFile', 'Web file ID', 'idWebFile', 'idWebFile');
    this.addColumn('colImportModeCd', 'Import mode code', 'importModeCd', 'importModeCd');
    this.addColumn('colProcessedRecords', 'Processed records', 'processedRecords', 'processedRecords');
    this.addColumn('colSkippedRecords', 'Skipped records', 'skippedRecords', 'skippedRecords');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colUpdatedAt', 'Date time updated', 'updatedAt', 'updatedAt');
    this.addColumn('colUpdatedBy', 'Updated by', 'updatedBy', 'updatedBy');
    this.addColumn('colUpdatedRecords', 'Updated records', 'updatedRecords', 'updatedRecords');

*/
  }
}
