/*BbmTestObj*/
import {EbAccountConsumerGENERATED} from '../_generated/eb-account-consumer-generated.model';

export class EbAccountConsumer extends EbAccountConsumerGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbAccountConsumer();
  }

  // ---------------------------------------------------------------------
}
