<div id="mcc-fi-{{name}}"
     class="mcc-fi mt-3 mcc-fi-radio-buttons mcc-fi-radio-buttons-{{name}} {{extraCssClass}} {{labelPositionClass}}">
  <form [formGroup]="getRadioForm()" [ngClass]="{'validation-error' : this.getRadioForm().get('errorFlg')?.value}">
    <h3 class="radio-buttons-title" *ngIf="label" [ngClass]="{'mc-mandatory-star' : validRequiredFlg}">{{label}}</h3>

    <mat-radio-group formControlName="value" (change)="onRadioChanged($event)">
    <ul [ngClass]="directionHorizontal ? 'direction-row' : 'direction-column'">
      <li *ngFor="let option of optionsVll.items; index as i" [ngClass]="disabledOptionsVll && areButtonsDisabled(option) ? 'mc-disable' : ''">
          <mat-radio-button
            [id]="'fi-' + name + '-' + option.value"
            [value]="option.value"
            [ngClass]="{'radio-button-error' : this.getRadioForm().get('errorFlg')?.value}">
            {{ option.label }}
          </mat-radio-button>
    <div *ngIf="additionalTextForRadioVll && additionalTextForRadioVll.items.length > 0">
          <div class="radio-item-wrap">
            <p>
              <span class="important-item">
          <mcc-row-title-description title="{{additionalUpperTextForRadio}}:"
                                     [description]="additionalTextForRadioVll.items[i].value"></mcc-row-title-description>
              </span>
              <mcc-row-title-description title="{{additionalBottomTextForRadio}}:"
                                         [description]="additionalTextForRadioVll.items[i].label"></mcc-row-title-description>
            </p>
          </div>
        </div>
      </li>
    </ul>
    </mat-radio-group>

  </form>
  <div class="error-msg">{{this.getRadioForm().get('errorMsg')?.value}}</div>
</div>






