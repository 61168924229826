/*BbmTestObj*/
import {McBillingPayoutFile2StatementGENERATED} from '../_generated/mc-billing-payout-file-2-statement-generated.model';

export class McBillingPayoutFile2Statement extends McBillingPayoutFile2StatementGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingPayoutFile2Statement();
  }

  // ---------------------------------------------------------------------
}
