/*All permissions - will be injected in all modules where we need to handle permissions;
* 'pages' is contain all pages where permission can be applied - need ti for reusable components;
* Also we need to update this file always when backend add new permission in DB;*/
export const allPermissions = {

  /*Entity*/
  MC_ENTITY_CAN_READ: {
    name: "MC_ENTITY_CAN_READ",
    pages: []
  },
  MC_ENTITY_CAN_CREATE: {
    name: "MC_ENTITY_CAN_CREATE",
    pages: ["entity"]
  },
  MC_ENTITY_CAN_DELETE: {
    name: "MC_ENTITY_CAN_DELETE",
    pages: []
  },
  MC_ENTITY_CAN_UPDATE: {
    name: "MC_ENTITY_CAN_UPDATE",
    pages: ["entity"]
  },
  MC_ENTITY_ADMIN: {
    name: "MC_ENTITY_ADMIN",
    pages: ["jobs"]
  },

  /*Role*/
  MC_LOGIN_CAN_READ: {
    name: "MC_LOGIN_CAN_READ",
    pages: []
  },
  MC_LOGIN_CAN_CREATE: {
    name: "MC_LOGIN_CAN_CREATE",
    pages: ["role"]
  },
  MC_LOGIN_CAN_DELETE: {
    name: "MC_LOGIN_CAN_DELETE",
    pages: []
  },
  MC_LOGIN_CAN_UPDATE: {
    name: "MC_LOGIN_CAN_UPDATE",
    pages: ["role"]
  },
  MC_LOGIN_ADMIN: {
    name: "MC_LOGIN_ADMIN",
    pages: []
  },

  /*User*/
  MC_SYSTEM_USER_CAN_READ: {
    name: "MC_SYSTEM_USER_CAN_READ",
    pages: []
  },
  MC_SYSTEM_USER_CAN_CREATE: {
    name: "MC_SYSTEM_USER_CAN_CREATE",
    pages: ["user"]
  },
  MC_SYSTEM_USER_CAN_DELETE: {
    name: "MC_SYSTEM_USER_CAN_DELETE",
    pages: []
  },
  MC_SYSTEM_USER_CAN_UPDATE: {
    name: "MC_SYSTEM_USER_CAN_UPDATE",
    pages: ["user"]
  },

  /*Product*/
  PP_PRODUCT_CAN_READ: {
    name: "PP_PRODUCT_CAN_READ",
    pages: []
  },
  PP_PRODUCT_CAN_CREATE: {
    name: "PP_PRODUCT_CAN_CREATE",
    pages: ["product"]
  },
  PP_PRODUCT_CAN_DELETE: {
    name: "PP_PRODUCT_CAN_DELETE",
    pages: []
  },
  PP_PRODUCT_CAN_UPDATE: {
    name: "PP_PRODUCT_CAN_UPDATE",
    pages: ["product"]
  },
  PP_INVOICE_CAN_UPDATE: {
    name: "CSR_CONSUMER_INVOICES_ADD",
    pages: ["invoice"]
  },

  /*Consumer*/
  CSR_CONSUMER_CAN_READ: {
    name: "CSR_CONSUMER_CAN_READ",
    pages: []
  },
  CSR_CONSUMER_CAN_CREATE: {
    name: "CSR_CONSUMER_CAN_CREATE",
    pages: ["consumers"]
  },
  CSR_CONSUMER_CAN_DELETE: {
    name: "CSR_CONSUMER_CAN_DELETE",
    pages: []
  },
  CSR_CONSUMER_CAN_UPDATE: {
    name: "CSR_CONSUMER_CAN_UPDATE",
    pages: ["consumers"]
  },

  /*Job*/
  QRTZ_JOB_CAN_READ: {
    name: "QRTZ_JOB_CAN_READ",
    pages: []
  },
  QRTZ_JOB_CAN_CREATE: {
    name: "QRTZ_JOB_CAN_CREATE",
    pages: ["jobs"]
  },
  QRTZ_JOB_CAN_DELETE: {
    name: "QRTZ_JOB_CAN_DELETE",
    pages: []
  },
  QRTZ_JOB_CAN_UPDATE: {
    name: "QRTZ_JOB_CAN_UPDATE",
    pages: ["jobs"]
  },


  TEST_PERMISSION: {
    name: "TEST_PERMISSION",
    page: []
  }
};
