import {Inject, Injectable} from '@angular/core';
import {EbPaymentReport2SepaFileTransactionServiceGENERATED} from '../_generated/eb-payment-report-2-sepa-file-transaction-generated.service';



@Injectable({
  providedIn: 'root'
})
export class EbPaymentReport2SepaFileTransactionService extends EbPaymentReport2SepaFileTransactionServiceGENERATED {

}
