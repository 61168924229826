/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McMappingItemTable3ConfigGENERATED} from '../_generated/mc-mapping-item-table-3-config-generated.model';

// import {McMappingItemGENERATED} from '../_generated/mc-mapping-item-generated.model';

export class McMappingItemTable3Config extends McMappingItemTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.addColumn('colIdMcMapping', 'McMapping ID', 'idMcMapping', 'idMcMapping');
    this.addColumn('colColumnName', 'Column name', 'columnName', 'columnName');
    this.addColumn('colAttributeName', 'Attribute name', 'attributeName', 'attributeName');
    this.addColumn('colSampleData', 'Sample data', 'sampleData', 'sampleData');


  }
}
