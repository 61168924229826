import {Inject, Injectable} from '@angular/core';
import {PmAdjustmentServiceGENERATED} from '../_generated/pm-adjustment-generated.service';




@Injectable({
  providedIn: 'root'
})
export class PmAdjustmentService extends PmAdjustmentServiceGENERATED {

}
