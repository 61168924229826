import { McConsumerConsumer360TabsPageComponent } from './pages/mc-consumer-consumer-360-tabs-page/mc-consumer-consumer-360-tabs-page.component';
import { McConsumerConsumer360SmartSearchBoxComponent } from './pages/mc-consumer-consumer-360-smart-search-box/mc-consumer-consumer-360-smart-search-box.component';
import { McConsumerConsumerImportMappingsListPageComponent } from './pages/mc-consumer-consumer-import-mappings-list-page/mc-consumer-consumer-import-mappings-list-page.component';
import { McConsumerConsumerTransactionOverviewPageComponent } from './pages/mc-consumer-consumer-transaction-overview-page/mc-consumer-consumer-transaction-overview-page.component';
import { McConsumerConsumerHistoryPageComponent } from './pages/mc-consumer-consumer-history-page/mc-consumer-consumer-history-page.component';
import { McConsumerConsumerProfilePageComponent } from './pages/mc-consumer-consumer-profile-page/mc-consumer-consumer-profile-page.component';
import { McConsumerConsumerImportListPageComponent } from './pages/mc-consumer-consumer-import-list-page/mc-consumer-consumer-import-list-page.component';
import { McConsumerConsumerListPageComponent } from './pages/mc-consumer-consumer-list-page/mc-consumer-consumer-list-page.component';
import { McConsumerContractProfilePageComponent } from './pages/mc-consumer-contract-profile-page/mc-consumer-contract-profile-page.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {McConsumerConsumer360ProfilePartComponent} from './components/mc-consumer-consumer-360-profile-part/mc-consumer-consumer-360-profile-part.component';
import {McConsumerConsumer360ContractsPartComponent} from './components/mc-consumer-consumer-360-contracts-part/mc-consumer-consumer-360-contracts-part.component';
import {McConsumerConsumer360CreateContractPartComponent} from './components/mc-consumer-consumer-360-create-contract-part/mc-consumer-consumer-360-create-contract-part.component';
import {CsrConsumerAddEditComponent} from "./pages/csr-consumer-add-edit/csr-consumer-add-edit.component";
import {CsrConsumerProfileComponent} from "./pages/csr-consumer-profile/csr-consumer-profile.component";
import {McConsumerConsumerInvoicesPartComponent} from "./components/mc-consumer-consumer-invoices-part/mc-consumer-consumer-invoices-part.component";
import {McConsumerConsumer360BankInfoPartComponent} from "./components/mc-consumer-consumer-360-bank-info-part/mc-consumer-consumer-360-bank-info-part.component";
import {McConsumerConsumer360AdditionalInfoPartComponent} from "./components/mc-consumer-consumer-360-additional-info-part/mc-consumer-consumer-360-additional-info-part.component";

const routes: Routes = [
{
    path: 'consumer-360-tabs/:id',
    component: McConsumerConsumer360TabsPageComponent,
    children: [
      {
        path: 'profile/:id',
        component: McConsumerConsumer360ProfilePartComponent
      },
      {
        path: 'bank-info/:id',
        component: McConsumerConsumer360BankInfoPartComponent
      },
      {
        path: 'additional-info/:id',
        component: McConsumerConsumer360AdditionalInfoPartComponent
      },
      {
        path: 'contracts/:id',
        component: McConsumerConsumer360ContractsPartComponent
      },
      {
        path: 'invoices/:id',
        component: McConsumerConsumerInvoicesPartComponent
      }
      ]
  },
  {
    path: 'create-contract/:id',
    component: McConsumerConsumer360CreateContractPartComponent
  },
  {
    path: 'consumer-360-tabs/:id/contracts/:id/contract/:id',
    component: McConsumerContractProfilePageComponent,
  },
  {
    path: 'consumer-360-tabs/:id/profile',
    component: McConsumerConsumer360TabsPageComponent
  },
{
    path: 'smart-search-box',
    component: McConsumerConsumer360SmartSearchBoxComponent
  },
{
    path: 'import-mappings',
    component: McConsumerConsumerImportMappingsListPageComponent
  },
{
    path: 'transactions/:id',
    component: McConsumerConsumerTransactionOverviewPageComponent
  },
{
    path: 'history/:id',
    component: McConsumerConsumerHistoryPageComponent
  },
{
    path: 'profile/:id',
    component: McConsumerConsumerProfilePageComponent
  },
{
    path: 'import',
    component: McConsumerConsumerImportListPageComponent
  },
  {
    path: 'overview',
    component: McConsumerConsumerListPageComponent
  },
  {
    path: ':id/profile',
    component: CsrConsumerProfileComponent
  },
  {
    path: 'add',
    component: CsrConsumerAddEditComponent
  },
  {
    path: 'add-360',
    component: CsrConsumerAddEditComponent
  },
  {
    path: ':id/edit',
    component: CsrConsumerAddEditComponent
  },
  {
    path: ':id/edit-360',
    component: CsrConsumerAddEditComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McConsumerRoutingModule {
}
