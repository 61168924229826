import {Inject, Injectable} from '@angular/core';
import {McBillingServiceCatalogServiceGENERATED} from '../_generated/mc-billing-service-catalog-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McBillingServiceCatalogService extends McBillingServiceCatalogServiceGENERATED {
  apiServiceUrlSubPath = "/mcbilling/v3/mcbillingservicecatalog";
}
