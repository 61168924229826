import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'lib-mc-amount-due',
  templateUrl: './mc-amount-due.component.html'
})
export class McAmountDueComponent implements OnInit {
  minAmount!: any;
  maxAmount!: any;

  form!: FormGroup;

  @Input() setup!: any;

  @Output() newSetup = new EventEmitter();

  constructor(private fb: FormBuilder) { }


  ngOnInit() {
    this.form = this.fb.group({
      more: ['', {validators: [Validators.required, Validators.pattern('^[0-9]*$')]}],
      less: ['', {validators: [Validators.required, Validators.pattern('^[0-9]*$')]}]
    });
  }

  save() {
    this.minAmount = +this.form.get('more')?.value;
    this.maxAmount = +this.form.get('less')?.value;
    this.setup.value = `More than ${this.minAmount} & less than ${this.maxAmount}`;
    this.newSetup.emit(this.setup);
    this.form.reset();
    if (this.minAmount === this.maxAmount) {
      this.setup.value = `Equals ${this.minAmount}`;
      this.newSetup.emit(this.setup);
      this.form.reset();
    }
  }

  get more() {
    return this.form.get('more');
  }

  get less() {
    return this.form.get('less');
  }

  checkALargerThanB() {
    return this.form.get('more')?.value - 0 > this.form.get('less')?.value - 0;
  }
}
