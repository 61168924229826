import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  McPriceList,
  McBillingServiceCatalogList,
  McBillingService2PriceList,
  McBillingService2PriceListList,
  McBillingServiceCatalog,
  McEntityService,
  McBillingServiceUnitType,
  McCurrencyService,
  SortCriteriaList,
  BaseDirective,
} from "@miticon-ui/mc-core";
import {
  McForm,
  McBoolean,
  McGod,
  McTrigger,
  McValueLabelList,
} from "@miticon-ui/mc-core";
import { Router, ActivatedRoute } from "@angular/router";

import { takeUntil } from "rxjs/operators";

@Component({
  selector: "mc-pricelist-template-add-edit-part",
  templateUrl: "./mc-pricelist-template-add-edit-part.component.html",
})
export class McPricelistTemplateAddEditPartComponent
  extends BaseDirective
  implements OnInit
{
  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  errorMsgs: string[] = [];
  mcServiceCatalogList = new McBillingServiceCatalogList();
  loadingFinished = false;
  errorMsg = "";
  currencyVll = new McValueLabelList();
  currencyCode!: string;

  // -- Input --
  @Input() mcPriceListId!: number;
  @Input() mcPriceList = new McPriceList();
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;

  @Input() saveTrigger!: McTrigger;
  @Input() cancelTrigger!: McTrigger;
  @Input() deleteTrigger!: McTrigger;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();

  // -- Constructor --
  constructor(
    private activeRoute: ActivatedRoute,
    private mcEntityService: McEntityService,
    private currencyService: McCurrencyService
  ) {
    super();
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.mcServiceCatalogList.loadAll(() => {
      this.actLoad();
    });

    const sortCriteriaList = new SortCriteriaList();
    this.currencyService
      .getAll(0, 50, sortCriteriaList)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        response.content.forEach((item: any) => {
          this.currencyVll.add(item.id, item.code);
        });
      });
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcPriceList = new McPriceList();
    if (this.mcPriceListId > 0) {
      this.mcPriceList.loadById(this.mcPriceListId, () => {
        this.validatePrerequisites();
        this.loadingFinished = true;
      });
    } else {
      this.mcPriceList.mcBillingService2PriceListItemList =
        new McBillingService2PriceListList();
      this.mcServiceCatalogList.items.forEach((item) => {
        const service2PriceItem = new McBillingService2PriceList();
        service2PriceItem.idBillingService = item.id;
        service2PriceItem.serviceTypeCd = item.typeCd;
        service2PriceItem.activityUnitTypeCd = item.activityUnitTypes[0];
        this.mcPriceList.mcBillingService2PriceListItemList.addItem(
          service2PriceItem
        );
      });

      this.validatePrerequisites();
      this.loadingFinished = true;
    }
    this.mcPriceList.mcBillingService2PriceListItemList.items = this.mcPriceList.mcBillingService2PriceListItemList.items.filter(item => item.idBillingService !== 14);
  }

  // ---------------------------------------------------------------------
  actSave() {
    if (this.mcForm.isValid()) {
      this.mcPriceList.title = this.mcForm.getValue("ctrlTemplateName");
      this.mcPriceList.description = this.mcForm.getValue(
        "ctrlTemplateDescription"
      );
      this.mcPriceList.idEntityOwner = McGod.getLoggedEntityIdFromToken();
      this.mcPriceList.idMcCurrency = this.mcForm.getValue("selCurrency");
      this.mcPriceList.sysCreatedUserId = McGod.getLoggedEntityIdFromToken();

      this.mcPriceList.save(
        () => {
          this.eventSaved.emit();
        },
        () => {
          this.saveButtonDisabledMcb.setFalse();
        }
      );

      this.saveButtonDisabledMcb.setFalse();
    } else {
      this.saveButtonDisabledMcb.setFalse();
    }
  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.mcPriceList.exists()) {
      this.mcPriceList.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

  // ---------------------------------------------------------------------
  validatePrerequisites() {
    this.errorMsgs = [];
    // populate validation here on required inputs
  }

  // ---------------------------------------------------------------------
  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }

  // ------------------------------------------------------
  fldServiceCatalog(serviceId: number) {
    // @ts-ignore
    const catalog: McBillingServiceCatalog =
      this.mcServiceCatalogList.getItemById(serviceId);
    return catalog;
  }

  fldUnitType(activityUnitTypeCd: string) {
    return McBillingServiceUnitType.getTypeCdLabel(activityUnitTypeCd);
  }

  onCurrencyValueChanged(value: any) {
    this.currencyVll.items.forEach((item) => {
      if (item.value === Number(value)) {
        this.currencyCode = item.label;
      }
    });
  }
}
