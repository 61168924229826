import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {
  EbFactoringService,
  McBoolean,
  McForm,
  McProcessFlow,
  McTrigger,
  McValueLabelList
} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'mc-process-process-flow-fees-edit',
  templateUrl: './mc-process-process-flow-fees-edit.component.html',
})
export class McProcessProcessFlowFeesEditComponent implements OnInit {

  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  errorMsgs: string[] = [];
  personInterestRateVll = new McValueLabelList();
  companyInterestRateVll = new McValueLabelList();
  currency!: string;

  // -- Input --
  @Input() mcProcessFlowId!: number;
  @Input() mcProcessFlow!: McProcessFlow;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;


  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();

  constructor(private ebFactoringService: EbFactoringService ) {
  }

  ngOnInit() {
    this.personInterestRateVll.add(McProcessFlow.PERSON_INTEREST_RATE_TYPE_CD_DEFAULT, 'Default (5% above the base rate of the German Central bank)');
    this.personInterestRateVll.add(McProcessFlow.PERSON_INTEREST_RATE_TYPE_CD_ENTER, 'Enter interest rate');
    this.companyInterestRateVll.add(McProcessFlow.COMPANY_INTEREST_RATE_TYPE_CD_DEFAULT, 'Default (9% above the base rate of the German Central bank)');
    this.companyInterestRateVll.add(McProcessFlow.COMPANY_INTEREST_RATE_TYPE_CD_ENTER, 'Enter interest rate');

    this.currency = this.ebFactoringService.activeChildFactoring.mcCurrency.code;

  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actSave() {
    if (this.mcForm.isValid()) {
      this.mcProcessFlow.personInterestRateTypeCd = this.mcForm.getValue('ctrlInterestRatePerson');
      if (this.mcProcessFlow.personInterestRateTypeCd === McProcessFlow.PERSON_INTEREST_RATE_TYPE_CD_ENTER) {
        this.mcProcessFlow.interestRateForPersonAmount = this.mcForm.getValue('ctrlPersonInterestRateAmount');
      }
      this.mcProcessFlow.companyInterestRateTypeCd = this.mcForm.getValue('ctrlInterestRateCompany');
      if (this.mcProcessFlow.companyInterestRateTypeCd === McProcessFlow.COMPANY_INTEREST_RATE_TYPE_CD_ENTER) {
        this.mcProcessFlow.interestRateForCompanyAmount = this.mcForm.getValue('ctrlCompanyInterestRateAmount');
      }

      this.mcProcessFlow.dunningNoteBaseFeeAmount = this.mcForm.getValue('ctrlDunningNoteValue');


      this.mcProcessFlow.save(() => {
        this.eventSaved.emit();
      }, () => {
        this.saveButtonDisabledMcb.setFalse();
      });
    } else {
      this.saveButtonDisabledMcb.setFalse();
    }
  }

  radioButtonsValueChange($event: any) {
    if ($event === 'DEFAULT' && this.mcForm.getValue('ctrlInterestRatePerson') === 'DEFAULT') {
      console.log('person control', this.mcForm.getControl('ctrlPersonInterestRateAmount'));
      const control = this.mcForm.getControl('ctrlPersonInterestRateAmount');
      control?.get('value')?.setValue('');
    }
    if ($event === 'DEFAULT' && this.mcForm.getValue('ctrlInterestRateCompany') === 'DEFAULT') {
      console.log('company control', this.mcForm.getControl('ctrlCompanyInterestRateAmount'));
      const control = this.mcForm.getControl('ctrlCompanyInterestRateAmount');
      control?.get('value')?.setValue('');
    }
  }
}

