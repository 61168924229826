/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {CsrContract} from '../models/csr-contract.model';
import {CsrContractList} from '../models/csr-contract-list.model';
import {CsrContractFilter} from '../models/csr-contract-filter.model';
import {CsrContractService} from '../services/csr-contract.service';
import {ICsrContract} from '../_generated/csr-contract.interface';
import {McGod} from "../extra/mc-god.service";

export class CsrContractListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'approvalKey', 'autoRenewal', 'autoRenewalLength', 'cancellationDeadline', 'cancellationReason', 'consumer360Flg', 'consumerName', 'consumerStatusCd', 'contractCancellationActiveOnDate', 'contractCancellationDate', 'contractModel', 'contractName', 'contractNumber', 'contractSigningDate', 'contractType', 'description', 'durationInDays', 'durationInMonths', 'endDate', 'idBeneficiary', 'idCsrConsumer', 'idCsrContractPreDefined', 'idCsrDiscount', 'idExternal', 'idMcEntity', 'idWebFile', 'idWebfileDdApproval', 'idWebfileOther', 'idWebfileSignature', 'jsonbNotificationsDetails', 'jsonbPaymentDetails', 'jsonbRecurringDetails', 'nextRecurring', 'notes', 'priceUnit', 'startDate', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'vatUnit', 'version', 'discount', 'name', 'notificationDetails', 'paymentDetails', 'products', 'productSnapshots', 'recurringDetails'];
  public items: CsrContract[] = [];
  public apiService: CsrContractService;
 // protected tempObjList: CsrContractList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrContractList {
    console.error('MTCN-ERROR: Not Implemented: CsrContractList::createNewInstance(). Add this method to final class and return new CsrContractList();');
    throw new Error('Cannot CsrContractList::createNewInstance(). Add this method to final class and return new CsrContractList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: ICsrContract[]): CsrContractList {
    const  listObj: CsrContractList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: CsrContract = CsrContract.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): CsrContractList {
    const  listObj: CsrContractList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrContractService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: ICsrContract[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: CsrContract = CsrContract.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aCsrContractFilter: CsrContractFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aCsrContractFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): CsrContract {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new CsrContract();
    }
  }

  public replaceItem(newItem: CsrContract) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public contractsByConsumer( idConsumer: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.contractsByConsumer(idConsumer, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

