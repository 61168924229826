/*BbmTestObj*/
import {PpOrderGENERATED} from '../_generated/pp-order-generated.model';
import {McDateUtils} from '../common/mc-date-utils';
import {CurrencyUtil} from '../common/currency-util';
export class PpOrder extends PpOrderGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PpOrder();
  }

  public static ACTION_SHOW_EDIT = 'EDIT';
  public static ACTION_CANCEL = 'CANCEL';
  public static ACTION_SHOW_PREVIEW = 'PREVIEW';
  public static ACTION_SHOW_LICENSE_PREVIEW = 'LICENSE_PREVIEW';
  public static FILTER_STATE = "pp.invoice.state";
  public static FILTER_TYPE = "pp.invoice.type";
  public static FILTER_CREATION_DATE = "Creation date";
  public static FILTER_DATE_FORMAT = "YYYY-MM-DD";


  // ---------------------------------------------------------------------

  fldSysCreatedDate(){
    return this.sysCreatedDatetime ? McDateUtils.newFormatDateTimeString(this.sysCreatedDatetime) : '';
  }

  fldDueDate(){
    return this.dueDate ? McDateUtils.newFormatDateTimeString(this.dueDate) : '';
  }

  fldSendingDate(){
    return this.sendingDate ? McDateUtils.newFormatDateTimeString(this.sendingDate) : '';
  }

  fldTotalAmount(){
    return CurrencyUtil.format(this.totalAmount, this.currency.code);
  }

  fldType(): string{
    const list = PpOrderGENERATED.getTypeCdVll();
    return list.getLabel(this.typeCd, '--BAD-CD--');
  }

  fldState(): string{
    const list = PpOrderGENERATED.getStateVll();
    return list.getLabel(this.state, '--BAD-CD--');
  }
}
