import { NgModule } from '@angular/core';
import { McBankAccountFullNameComponent } from './mc-bank-account-full-name.component';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [McBankAccountFullNameComponent],
  imports: [
    TranslateModule,
    CommonModule
  ],
  exports: [McBankAccountFullNameComponent]
})
export class McBankAccountFullNameModule { }
