import {CsrRecurringDetailsFilterGENERATED} from '../_generated/csr-recurring-details-filter-generated.model';

export class CsrRecurringDetailsFilter extends CsrRecurringDetailsFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrRecurringDetailsFilter();
  }

  // ---------------------------------------------------------------------
}
