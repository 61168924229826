import {EbSepaTransactionFilterGENERATED} from '../_generated/eb-sepa-transaction-filter-generated.model';

export class EbSepaTransactionFilter extends EbSepaTransactionFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaTransactionFilter();
  }

  // ---------------------------------------------------------------------
}
