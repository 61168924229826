import { McProductProductProfilePartComponent } from './components/mc-product-product-profile-part/mc-product-product-profile-part.component';
import { McProductAddEditProductComponent } from './components/mc-product-add-edit-product/mc-product-add-edit-product.component';
import { McProductAddCategoryComponent } from './components/mc-product-add-category/mc-product-add-category.component';
import { McProductProductListPageComponent } from './pages/mc-product-product-list-page/mc-product-product-list-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McProductRoutingModule } from './mc-product-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {McFilterModule, McTableModule} from '@miticon-ui/mc-components';
import {McManagementModule} from '../mc-management/mc-management.module';
import {TranslateModule} from "@ngx-translate/core";
import {ContractsPredefinedListPageComponent} from './pages/contracts-predefined-list-page/contracts-predefined-list-page.component';
import {SafeHtmlModule} from "../../assets/pipes/safe-html/safe-html.module";
import {ContractPredefinedAddEditComponent} from './pages/contracts-predefined-list-page/contract-predefined-add-edit/contract-predefined-add-edit.component';
import {MatRadioModule} from "@angular/material/radio";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ContractPredefinedViewDialogComponent} from './pages/contracts-predefined-list-page/contract-predefined-view-dialog/contract-predefined-view-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
  declarations: [
    McProductProductProfilePartComponent,
    McProductAddEditProductComponent,
    McProductAddCategoryComponent,
    McProductProductListPageComponent,
    ContractsPredefinedListPageComponent,
    ContractPredefinedAddEditComponent,
    ContractPredefinedViewDialogComponent,
  ],
  imports: [
    CommonModule,
    McProductRoutingModule,
    NgbModule,
    McComponentsModule,
    McFilterModule,
    McTableModule,
    FormsModule,
    ReactiveFormsModule,
    McManagementModule,
    TranslateModule,
    SafeHtmlModule,
    MatRadioModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
  ],
  exports: [
    McProductProductProfilePartComponent,
    McProductAddEditProductComponent,
    McProductAddCategoryComponent,
    McProductProductListPageComponent,
    McProductRoutingModule,
    FormsModule,
  ]
})
export class McProductModule { }
