import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EbSepaFileServiceGENERATED} from '../_generated/eb-sepa-file-generated.service';



@Injectable({
  providedIn: 'root'
})
export class EbSepaFileService extends EbSepaFileServiceGENERATED {

  headers = new HttpHeaders({ 'Content-Type': 'text/xml' }).set('Accept', 'text/xml');

  downloadSepaFile(id: number) {
    return this.httpClient.get(`${this.apiUrl}/core/EbSepaFile/download/${id}`,  { responseType: 'blob' });
  }

}
