<section class="mc-invoice-step justify-content-start">
  <div class="row mc-header-wrapper">
    <div class="col-md-12 mc-stepper-header-title">
      <span class="pl-4 pb-1 mr-2 mc-active">
        <span class="mc-notification-badge-gray mc-active-background">1</span>
        {{ 'cc.common.products' | translate }}
      </span>
      <span class="pl-4 pb-1 ml-4 mr-2">
        <span class="mc-notification-badge-gray">2</span>
        {{'cc.invoice.view.schedule-and-summary' | translate}}
      </span>
    </div>
  </div>


  <div class="mc-create-user-notify-wrapper pt-3" style="position: relative">
    <mc-notify-message *ngIf="responseMessageDisplay" [message]="notifyMsgMessage" [type]="notifyMsgType" [link]="link"></mc-notify-message>
  </div>

  <form *ngIf="bankAccounts.items.length > 0 || this.receivedInvoiceData" class="pt-0 mc-create-invoices-form" [formGroup]="stepForm">

    <div class="row justify-content-between align-items-center mt-3 mb-3">
      <div class="col-md-9 d-flex align-items-center text-left">
        <span class="mc-invoice-schedule-select mc-mandatory-star">{{'cc.invoice.service-period' | translate}}:</span>
        <div class="col-md-4 d-flex align-items-center">
          <label for="servicePeriodFrom" class="mb-0 pr-2">{{'cc.common.date.from' | translate}}</label>
          <input type="date"
                 id="servicePeriodFrom"
                 class="form-control mc-dates-inputs"
                 autocomplete="off"
                 formControlName="servicePeriodFrom"
                 placeholder="dd-mm-yyyy"
                 [minDate] = "minServicePeriodDate"
                 [maxDate] = "maxServicePeriodDate"
                 ngbDatepicker
                 outsideDays="hidden"
                 #servicePeriodFrom="ngbDatepicker"
                 (click)="servicePeriodFrom.toggle()">
        </div>
        <div class="col-md-4 d-flex align-items-center">
          <label for="servicePeriodTo" class="mb-0 pr-2">{{'cc.common.date.to' | translate}}</label>
          <input type="date"
                 id="servicePeriodTo"
                 class="form-control mc-dates-inputs"
                 autocomplete="off"
                 formControlName="servicePeriodTo"
                 placeholder="dd-mm-yyyy"
                 [minDate] = "minServicePeriodSendDate"
                 [maxDate] = "maxServicePeriodDate"
                 ngbDatepicker
                 outsideDays="hidden"
                 #servicePeriodTo="ngbDatepicker"
                 (click)="servicePeriodTo.toggle()">
        </div>
      </div>
      <div class="col-md-3 text-right">
        <div class="mc-add-new-user">
          <button class="system-action-btn" id="addProductsModalButton" (click)="openProductsModal(addProducts)">
            <i class="fas fa-plus-circle mc-add-new-user-icon"></i>
            {{'cc.common.add-products' | translate}}
          </button>
        </div>
      </div>
    </div>

    <!-- Invoice Table -->
    <div class="col-md-12 p-0 mc-table-component-wrapper">
      <mc-table [tableContent]="invoiceTableContent"
                (sendMessageToParent)="isDiscountInvalid($event)"
                (onSendStateToParentComponent)="sendSelectedProducts($event)">
      </mc-table>
    </div>
  </form>
</section>

<!--Add products modal-->
<ng-template #addProducts let-modal>
  <!--Modal header section-->
  <div class="modal-header mc-create-user-modal-header">
    <div>
      <h4 class="modal-title" id="mc-modal-new-user-title">{{'cc.common.add-products' | translate}}</h4>
    </div>
    <button type="button" class="close mc-consumers-close-btn" aria-label="Close"
            (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div><!--Modal header -->
  <!--Modal body section-->
  <div class="modal-body">
    <div class="mc-create-new-user-wrapper pt-3">
      <div class="container-fluid">

        <!--Filter component-->
        <div class="col-md-12 px-0">
          <mc-filter [name]="filtersName" (onFilterProceed)="getFilter($event)"></mc-filter>
        </div>

        <!-- Product Table -->
        <div class="col-md-12 mc-table-component-wrapper px-0 pt-1">
          <mc-table [tableContent]="tableContent"
                    (onSendStateToParentComponent)="receiveSelectedProduct($event)"
                    (selectedRowsEvent)="receiveSelectedTableData($event)">
          </mc-table>
        </div>

        <div class="col-md-12 mc-selected-product-wrapper" *ngIf="productList.length > 0">
          <h5>{{'cc.common.selected-products' | translate}}</h5>
          <ul class="mc-selected-products-list pl-1">
            <li class="mc-list-item" *ngFor="let product of productList">
              <span>{{product.name}}
                <span *ngIf="product.numberOfProducts">({{product.numberOfProducts}})</span>
              </span>
            </li>
          </ul>
        </div>
        <div class="col-md-12 mc-no-selected-products" *ngIf="!productList.length">
          <h5>{{"cc.common.no-select-products" | translate}}</h5>
        </div>
        <div class="col-md-12 text-right mc-add-products-modal-close-area">
          <button class="btn-sm mc-table-button mc-warning-button mr-3" type="button" (click)="modal.dismiss()">{{'cc.common.edit.cancel' | translate}}</button>
          <button class="btn-sm mc-table-button"
                  [ngClass]="!productList.length ? 'disabled mc-click-not-allow' : 'mc-success-button'"
                  type="button" (click)="onFinishAddingProducts(); modal.dismiss()"
                  [disabled]="!productList.length">{{'cc.common.view.apply' | translate}}</button>
        </div>

      </div><!--Container /end-->
    </div><!--Wrapper /end-->
  </div><!--Modal body /end-->

</ng-template>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
