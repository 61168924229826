<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
<div class="header">
  <h3 class="page-title">{{ "cc.common.dashboard" | translate }}</h3>
  <form [formGroup]="form">
    <mat-form-field class="date-range" appearance="outline">
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Start date" (dateChange)="onDateFrom($event)" readonly formControlName="fromDate">
        <input matEndDate placeholder="End date" (dateChange)="onDateTo($event)" readonly formControlName="toDate">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>
  <label class="header_statistics" (click)="toggleStatisticsSelect = !toggleStatisticsSelect">
    Statistics
    <span [innerHTML]="(toggleStatisticsSelect ? iconDropDown : iconDropUp) | safeHtml"></span>
  </label>
  <div *ngIf="toggleStatisticsSelect" class="header_statistics_options">
    <mat-checkbox *ngFor="let option of statisticsOptions" [(ngModel)]="option.value">
      {{ option.label | translate }}
    </mat-checkbox>
  </div>
</div>
<section [class.displayNone]="showLoader">
  <div class="section two mt-extra-large">
    <div class="section_info" [class.displayNone]="!checkVisibility('cc.dashboard.bank-transactions')">
      <div class="section_info_title">{{ "cc.dashboard.bank-transactions" | translate }}</div>
      <div class="card" *ngFor="let transaction of bankTransactions">
        <div class="flex">
          <div class="section_info_line"></div>
          <div class="w-100 flex mr-small" style="gap: 15px">
          <span class="section_info_value">
              {{ transaction.value }}
            </span>
            <div class="section_info_description flex w-100">
            <span class="section_info_description_title">
              {{ transaction.label | translate }}
            </span>
              <span [innerHTML]="transaction.icon | safeHtml"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section_info" [class.displayNone]="!checkVisibility('cc.transaction.matching-payments')">
      <div class="section_info_title">{{ "cc.transaction.matching-payments" | translate }}</div>
      <div class="card chart">
      </div>
      <canvas id="matchingPaymentsChart"></canvas>
      <div class="section_info_total">
        <div class="section_info_total_title">{{ "cc.statement.total" | translate }}</div>
        <span class="section_info_total_value" style="color: #53589B">{{ totalMatchingPayment }}</span>
      </div>
    </div>
  </div>
  <div class="section two"
       [class.marginTop]="(
     checkVisibility('cc.consumers.action.type.dunning') ||
     checkVisibility('cc.dashboard.inkasso')) &&
     checkVisibility('cc.transaction.matching-payments')">
    <div class="section_info" [class.displayNone]="!checkVisibility('cc.consumers.action.type.dunning')">
      <div class="section_info_title">{{ "cc.consumers.action.type.dunning" | translate }}</div>
      <div class="card chart">
      </div>
      <canvas id="dunningChart"></canvas>
      <div class="section_info_total" style="left: 100px">
        <div class="section_info_total_title">{{ "cc.statement.total" | translate }}</div>
        <span class="section_info_total_value" style="color: #5EABBE">{{ totalDunning }}</span>
      </div>
    </div>
    <div class="section_info" [class.displayNone]="!checkVisibility('cc.dashboard.inkasso')">
      <div class="section_info_title">{{ "cc.dashboard.inkasso" | translate }}</div>
      <div class="card chart">
      </div>
      <canvas id="inkassoChart"></canvas>
      <div class="section_info_total">
        <div class="section_info_total_title">{{ "cc.statement.total" | translate }}</div>
        <span class="section_info_total_value" style="color: #D71969">{{ totalInkasso }}</span>
      </div>
    </div>
  </div>

  <div class="section mt-extra-large"
       [class.displayNone]="!checkVisibility('cc.dashboard.consumer-complaints-and-related-tr')"
       [class.marginTop]="(checkVisibility('cc.transaction.matching-payments') ||
     checkVisibility('cc.consumers.action.type.dunning') ||
     checkVisibility('cc.dashboard.inkasso'))">
    <div class="section_info w-100">
      <div class="section_info_title">{{ "cc.dashboard.consumer-complaints-and-related-tr" | translate }}</div>
      <div class="card">
        <div class="complaint-container">
          <canvas id="chartComplaintDunning"></canvas>
          <div id="legendComplaintDunning"></div>

          <canvas id="chartComplaintInkasso"></canvas>
          <div id="legendComplaintInkasso"></div>
        </div>
        <span class="y-title">{{ "cc.dashboard.consumers-and-transactions" | translate }}</span>
        <span class="x-title">{{ "cc.dashboard.number-of-consumers-and-transactions" | translate }}</span>
        <div class="line"></div>
        <div class="complaint-info">
          <span [innerHTML]="iconLightBulb | safeHtml"></span>
          {{ "cc.dashboard.complaints-charts.info" | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="section mt-extra-large"
       [class.displayNone]="!checkVisibility('cc.dashboard.transaction-amounts')"
       [class.marginTop]="checkVisibility('cc.dashboard.transaction-amounts') &&
       !checkVisibility('cc.dashboard.consumer-complaints-and-related-tr') &&
     (checkVisibility('cc.transaction.matching-payments') ||
     checkVisibility('cc.consumers.action.type.dunning') ||
     checkVisibility('cc.dashboard.inkasso'))">
    <div class="section_info w-100">
      <div class="section_info_title">{{ "cc.dashboard.transaction-amounts" | translate }}</div>
      <div class="card statuses">
        <div class="revenue w-100">
          <div id="charts-container-1"></div>
          <div class="percentage">
            <div class="percentage_item" *ngFor="let item of transactionsAmounts | slice:0:6">{{ item.percentage }}%</div>
          </div>
          <div class="percentage mt-medium">
            <div class="percentage_revenue" *ngFor="let item of transactionsAmounts | slice:0:6">
              <div class="percentage_name">{{ item.name | translate }}</div>
              <span class="percentage_value">{{ item.revenue + " " + currency }}</span>
            </div>
          </div>
          <div id="charts-container-2" class="mt-extra-large"></div>
          <div class="percentage">
            <div class="percentage_item" *ngFor="let item of transactionsAmounts | slice:6:12">{{ item.percentage }}%</div>
          </div>
          <div class="percentage mt-medium">
            <div class="percentage_revenue" *ngFor="let item of transactionsAmounts | slice:6:12">
              <div class="percentage_name">{{ item.name | translate }}</div>
              <span class="percentage_value">{{ item.revenue + " " + currency }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section mt-extra-large"
       [class.displayNone]="!checkVisibility('cc.dashboard.transaction-statuses')"
       [class.marginTop]="!checkVisibility('cc.dashboard.transaction-amounts') &&
       !checkVisibility('cc.dashboard.consumer-complaints-and-related-tr') &&
       (checkVisibility('cc.transaction.matching-payments') ||
     (!checkVisibility('cc.transaction.matching-payments') &&
     (checkVisibility('cc.consumers.action.type.dunning') ||
     checkVisibility('cc.dashboard.inkasso'))))">
    <div class="section_info w-100">
      <div class="section_info_title">{{ "cc.dashboard.transaction-statuses" | translate }}</div>
      <div class="card statuses">
        <canvas id="chartTransactionStatuses"></canvas>
      </div>
    </div>
  </div>
</section>
