<form [formGroup]="mappingForm">
  <div class='card'>
    <div class="mapping mt-medium">
      <h2 class="title-medium-dark mb-large">
        {{ "mem.import.mapping-template" | translate }}
      </h2>
      <div class="mapping_toggle">
        <span>{{ "mem.import.mapping-template-use" | translate }}</span>
        <mat-slide-toggle [class.checkedToggle]="useTemplate"
                          [class.noMapping]="!mappingExist"
                          [disabled]="!mappingExist"
                          (change)="checkToggle($event)">
        </mat-slide-toggle>
      </div>
    </div>
    <div class='card flex-between mt-large' *ngIf="useTemplate">
      <div class='flex'>
        <span class='flex' [innerHTML]="iconMappingTemplateBlack | safeHtml"></span>
        <h2 class='title-medium-dark-normal ml-small mt-small'>
          {{ choosenMapping ? choosenMapping.mappingName : ( "mem.import.mapping-template" | translate ) }}
        </h2>
      </div>
      <button mat-button class="primary-btn" (click)="openTemplateMappingsDialog()">
        {{ "cc.pricelist.select-template" | translate}}
      </button>
    </div>
    <div class="divider"></div>
    <div class='attributes flex-between'>
      <div class="attributes_header">
        <div>
          <h2 class="title-medium-dark">{{ "cc.consumers.mapping" | translate}}</h2>
          <div class="attributes_header_subtitle">{{ "mem.import.select-columns-message" | translate }}</div>
        </div>
        <button mat-button class="primary-btn" *ngIf="dataSet && dataSet.allowCustomAtt" (click)="openAddAttributeDialog()">
          {{ "cc.common.view.create-attribute" | translate }}
        </button>
      </div>
      <div class="card attributes_req mt-small" *ngIf="dataSetId && reqAttributes">
        <span [innerHTML]="iconAttribute | safeHtml"></span>
        <div class="attributes_req_item">
          <span>{{ "mem.import.upload.req-fields" | translate }}:</span>
          <span *ngFor="let attribute of reqAttributes; let last = last; let first = first">
          {{ attribute.name + (!last ? ',' : '') }}
        </span>
        </div>
      </div>
      <div>
        <mk-mat-table *ngIf="this.items.length > 0"
                      [items]="items"
                      [config]="tableConfig"
                      [pageSize]="pageSize"
                      [isLoading]="isLoading"
                      [itemsPerPage]="items.length"
                      [showPaginator]="false"
                      [showCheckbox]="false"
                      (eventColumnSelection)="onColumnSelection($event)"
                      [totalItemsCount]="totalItemsCount">
        </mk-mat-table>
      </div>
    </div>
  </div>

  <div class='card' *ngIf="!choosenMapping && isReqAttributesValid()">
    <h2 class="title-medium-dark mb-large">
      {{ "mem.import.save-as-template" | translate }}
    </h2>
    <div class="radio_buttons_item body-text-medium">
      <mat-checkbox (change)="checkSaveMapping($event)">
        {{ "cc.consumers.consumers-overview.save-mapping" | translate }}
      </mat-checkbox>
    </div>
    <div class="form_default" *ngIf="checkedSaveMapping">
      <mat-form-field class="mapping_name_form" appearance="outline">
        <mat-label>{{ "cc.consumers.consumers-overview.mapping-name" | translate}}*</mat-label>
        <input formControlName="mappingName"
               matInput/>
      </mat-form-field>
    </div>
  </div>

  <div class='card' *ngIf="choosenMapping && editedMapping">
    <h2 class="title-medium-dark mb-large">
      {{ "mem.import.save-or-update-as-template" | translate }}
    </h2>
    <div class="radio_buttons_item body-text-medium">
      <mat-checkbox [checked]="checkedSaveMapping" (change)="checkSaveMapping($event)">
        {{ "mem.import.save-as-new-mapping" | translate }}
      </mat-checkbox>
      <mat-checkbox style="margin-left: 20px" [checked]="checkedUpdateMapping" (change)="checkUpdateMapping($event)">
        {{ "mem.import.update-existing-mapping" | translate }}
      </mat-checkbox>
    </div>
    <div class="form_default" *ngIf="checkedSaveMapping">
      <mat-form-field class="mapping_name_form" appearance="outline">
        <mat-label>{{ "cc.consumers.consumers-overview.mapping-name" | translate}}*</mat-label>
        <input formControlName="mappingName"
               matInput/>
      </mat-form-field>
    </div>
  </div>
</form>
