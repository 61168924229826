import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {ICON_CLOSE} from "../../../../assets/media/svg_icons/icon-close";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {BkFinanceReportService} from "@miticon-ui/mc-core";
import {ToastrService} from "ngx-toastr";
import {DatePipe} from "@angular/common";
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

const moment = _rollupMoment || _moment;

export const FORMAT = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'lib-finance-generate-report-dialog',
  templateUrl: './finance-generate-report-dialog.component.html',
  styleUrls: ['./finance-generate-report-dialog.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: FORMAT},
  ]
})
export class FinanceGenerateReportDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  form!: FormGroup;
  maxDate: Moment;
  isLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<FinanceGenerateReportDialogComponent>,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private datePipe: DatePipe,
              private bkFinanceReportService: BkFinanceReportService) { }

  ngOnInit(): void {
    this.maxDate = moment();
    this.maxDate.set('month', this.maxDate.month() - 1);
    this.maxDate.set('date', this.maxDate.daysInMonth());

    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      bookingDateFrom: ['', Validators.required],
      bookingDateTo: ['', Validators.required]
    });
  }

  formatDate(dateString: string) {
    const date = new Date(dateString);
    return this.datePipe.transform(date, "yyyy-MM-dd");
  }

  generateReport() {
    this.isLoading = true;
    this.form.controls['bookingDateFrom'].setValue(this.formatDate(this.form.controls['bookingDateFrom'].value));
    this.form.controls['bookingDateTo'].setValue(this.formatDate(this.form.controls['bookingDateTo'].value));

    this.bkFinanceReportService.insert(this.form.value).subscribe((data) => {
      if(data) {
        this.isLoading = false;
        this.dialogRef.close(true);
      }
    }, (error) => {
      this.isLoading = false;
      this.toastr.error(error.error.message);
    })
  }

  updateForm(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>, controlName: string) {
    if (controlName === 'bookingDateFrom') {
      this.form.controls[controlName].setValue(normalizedMonthAndYear);
    } else {
      normalizedMonthAndYear.set('date', normalizedMonthAndYear.daysInMonth());
      this.form.controls[controlName].setValue(normalizedMonthAndYear);
    }
    datepicker.close();
  }
}
