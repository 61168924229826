<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper" *ngIf="config.ebSepaFileTransaction">
  <lib-mc-loader [showLoader]="config.ebSepaFileTransaction?.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
      <h1 *ngIf="config.ebSepaFileTransaction?.exists()">Edit config.ebSepaFileTransaction (#{{config.ebSepaFileTransaction?.id}})</h1>
    <h1 *ngIf="config.isTypeAdd()">Add Transaction for {{config.consumer?.fldFullName()}} (#{{config.consumer?.id}})</h1>

  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!config.ebSepaFileTransaction?.apiLoadingFlg" class="content-body">
    <mcc-message [errorMsg]="config.ebSepaFileTransaction.apiErrorMessage"></mcc-message>
    <mcc-message [errorMsgs]="errorMsgs" label="Unable to proceed: "></mcc-message>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container *ngIf="(config.ebSepaFileTransaction.apiSuccessFlg) && (!hasErrors())">

        <div class="row">
          <div class="col-md-12">
            <div class="content-section">
              <h2>Consumer</h2>

              <mcc-row-title-description title="Consumer" [description]="config.consumer?.fldFullName()"></mcc-row-title-description>

              <h2>Transaction</h2>
              <mcc-fi-radio-buttons2 [mcForm]="mcForm"  name="rbsTypeCd" label="Type" [optionsVll]="config.ebSepaFileTransaction.getTypeCdVll()" [value]="config.ebSepaFileTransaction.typeCd" validRequiredFlg="true"></mcc-fi-radio-buttons2>



<mcc-fi-textbox name="ctrlInstdamt" label="Amount (e)"
        [value]="config.ebSepaFileTransaction.instdamt"
                [validRequiredFlg]="true" [validDecimalNumber]="true"
        [mcForm]="mcForm">
</mcc-fi-textbox>


              <mcc-fi-date-picker name="ctrlDueDatetime" label="Due DateTime"
                                  [valueInput]="config.ebSepaFileTransaction.dueDatetime"
                                  [validRequiredFlg]="true"
                                  [mcForm]="mcForm">
              </mcc-fi-date-picker>

<mcc-fi-textarea name="ctrlDescription" label="Description"
        [value]="config.ebSepaFileTransaction.description"
        [mcForm]="mcForm">
</mcc-fi-textarea>




            </div>
          </div>
        </div><!-- row -->
    </ng-container>




    <!-- == FOOTER ======================================== -->
      <!--THIS CONTAINER IS MANDATORY-->
      <div class="content-footer">
      <h3>Not Completed...</h3>
<!--
        <mcc-button  extraCssClasses="btn btn-secondary move-right" (eventClicked)="actCancel();" label="Cancel"></mcc-button>
        <mcc-button  *ngIf="(config.ebSepaFileTransaction.apiSuccessFlg) && (!hasErrors())" extraCssClasses="btn btn-primary" (eventClicked)="actSave();" label="Save" [disabledMcb]="saveButtonDisabledMcb" disableOnFirstClickFlg="true"></mcc-button>
-->
      </div>


  </div><!-- content-body -->

</div>
<router-outlet></router-outlet>
