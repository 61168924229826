/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {ImDataMapping} from '../models/im-data-mapping.model';
import {ImDataMappingService} from '../services/im-data-mapping.service';
import {IImDataMapping} from '../_generated/im-data-mapping.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {ImDataMappingItemList} from '../models/im-data-mapping-item-list.model';


export abstract class ImDataMappingGENERATED extends BaseObject {


  public apiService: ImDataMappingService;
  public _rawJson: IImDataMapping;
    id: number = 0;
  creationDate: number;
  idDataSet: number;
  idMcEntity: number;
  idMcSystemUser: number;
  idWebFile: number;
  mappingName = '';
  sharedFlg: boolean;
  sharingDate: number;
  visibleFlg: boolean;
  createdByUserName: string;  mappingItems: ImDataMappingItemList;  sharedByEntityName: string;  sharedByUserName: string;

  public properties: string[] = ['id', 'creationDate', 'idDataSet', 'idMcEntity', 'idMcSystemUser', 'idWebFile', 'mappingName', 'sharedFlg', 'sharingDate', 'visibleFlg', 'createdByUserName', 'mappingItems', 'sharedByEntityName', 'sharedByUserName'];
  public propertiesRegular: string[] = ['id', 'creationDate', 'idDataSet', 'idMcEntity', 'idMcSystemUser', 'idWebFile', 'mappingName', 'sharedFlg', 'sharingDate', 'visibleFlg', 'createdByUserName', 'sharedByEntityName', 'sharedByUserName'];
  public propertiesSpecial: string[] = ['mappingItems'];


  // ---------------------------------------------------------------------
public static createNewInstance(): ImDataMapping {
    console.error('MTCN-ERROR: Not Implemented: ImDataMapping::createNewInstance(). Add this method to final class and return new ImDataMapping();');
    throw new Error('Cannot ImDataMapping::createNewInstance(). Add this method to final class and return new ImDataMapping();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IImDataMapping): ImDataMapping {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(ImDataMappingService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE ImDataMapping AABB()');
          if (this._rawJson['mappingItems']) {
        this.mappingItems = ImDataMappingItemList.createFromJson(this._rawJson['mappingItems']);
      }

  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('mappingItems')) {
        if (this.mappingItems != null) {
            dto['mappingItems'] = this.mappingItems.toDto();
        } else {
            dto['mappingItems'] = [];
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
