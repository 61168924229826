/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EbInterest_rateFilter} from '../models/eb-interest_rate-filter.model';

export class EbInterest_rateFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbInterest_rateFilter {
    console.error('MTCN-ERROR: Not Implemented: EbInterest_rateFilter::createNewInstance(). Add this method to final class and return new EbInterest_rateFilter();');
      throw new Error('Cannot EbInterest_rateFilter::createNewInstance(). Add this method to final class and return new EbInterest_rateFilter();');
  }

}
