import {McHtmlButton} from './mc-html-button';
import {McHtmlLink} from './mc-html-link';
import {McGod} from '../extra/mc-god.service';
import {ActionOptions} from '../_core/mc-html-action-options';


export class McHtmlActionSet {
  items = new Array<any>();
  permissions!: string[];
  horizontalFlg = false;

  constructor() {
  }

  public addActionB(label: string, actionCdForEvent: string, itemObj: any, permissions: string[], extraCssClass: string = '', actionOptions: ActionOptions) {
    if (McGod.userHasPermissions(permissions)) {
      // TODO: Hardcoded below No need to pass permissions if already checked
      const item = new McHtmlButton(label, actionCdForEvent, itemObj, null, extraCssClass, actionOptions);
      this.items.push(item);
    }
    return this;
  }

  public addLinkB(linkTextHtml: string, routeLink: string, itemObj: any, permissions: string[], extraCssClass: string = '', actionOptions: ActionOptions, queryParams: any = {}) {
    if (McGod.userHasPermissions(permissions)) {
      // TODO: Hardcoded below No need to pass permissions if already checked
      const item = new McHtmlLink(linkTextHtml, routeLink, itemObj, null, extraCssClass, actionOptions, queryParams);
      this.items.push(item);
    }
    return this;
  }
}

