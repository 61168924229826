/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {PmAdjustmentNotificationInfo} from '../models/pm-adjustment-notification-info.model';
import {PmAdjustmentNotificationInfoService} from '../services/pm-adjustment-notification-info.service';
import {IPmAdjustmentNotificationInfo} from '../_generated/pm-adjustment-notification-info.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class PmAdjustmentNotificationInfoGENERATED extends BaseObject {


    public static readonly NOTIFICATION_STATUS_CD_SENT: string = 'SENT';
    public static readonly NOTIFICATION_STATUS_CD_NOT_SENT: string = 'NOT_SENT';
    public static readonly NOTIFICATION_STATUS_CD_PENDING: string = 'PENDING';
  public static readonly NOTIFICATION_STATUS_CD_LIST__ALL = [
    PmAdjustmentNotificationInfoGENERATED.NOTIFICATION_STATUS_CD_SENT,
    PmAdjustmentNotificationInfoGENERATED.NOTIFICATION_STATUS_CD_NOT_SENT,
    PmAdjustmentNotificationInfoGENERATED.NOTIFICATION_STATUS_CD_PENDING
];

  public apiService: PmAdjustmentNotificationInfoService;
  public _rawJson: IPmAdjustmentNotificationInfo;
    id: number = 0;
  idCsrConsumer2CtdEmail: number;
  idMcProcessNotification: number;
  idPmAdjustment: number;
  notificationStatusCd: string;
  statusDetails = '';


  public properties: string[] = ['id', 'idCsrConsumer2CtdEmail', 'idMcProcessNotification', 'idPmAdjustment', 'notificationStatusCd', 'statusDetails'];
  public propertiesRegular: string[] = ['id', 'csrConsumer2CtdEmail', 'idCsrConsumer2CtdEmail', 'idMcProcessNotification', 'idPmAdjustment', 'notificationStatusCd', 'statusDetails'];
  public propertiesSpecial: string[] = [];



  public static getNotificationStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PmAdjustmentNotificationInfoGENERATED.NOTIFICATION_STATUS_CD_SENT, McGod.t('Sent'));
    list.add(PmAdjustmentNotificationInfoGENERATED.NOTIFICATION_STATUS_CD_NOT_SENT, McGod.t('Not sent'));
    list.add(PmAdjustmentNotificationInfoGENERATED.NOTIFICATION_STATUS_CD_PENDING, McGod.t('Pending'));


    return list;
  }

  public static getNotificationStatusCdLabel(aCd: string): string {
    const list = PmAdjustmentNotificationInfoGENERATED.getNotificationStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): PmAdjustmentNotificationInfo {
    console.error('MTCN-ERROR: Not Implemented: PmAdjustmentNotificationInfo::createNewInstance(). Add this method to final class and return new PmAdjustmentNotificationInfo();');
    throw new Error('Cannot PmAdjustmentNotificationInfo::createNewInstance(). Add this method to final class and return new PmAdjustmentNotificationInfo();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IPmAdjustmentNotificationInfo): PmAdjustmentNotificationInfo {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(PmAdjustmentNotificationInfoService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE PmAdjustmentNotificationInfo AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getNotificationStatusCdLabel(): string {
    return PmAdjustmentNotificationInfoGENERATED.getNotificationStatusCdLabel(this.notificationStatusCd);
  }


 public  isNotificationStatusCdSENT(): boolean {
    const result = (this.notificationStatusCd === PmAdjustmentNotificationInfoGENERATED.NOTIFICATION_STATUS_CD_SENT);

    return result;
  }

 public  isNotificationStatusCdNOT_SENT(): boolean {
    const result = (this.notificationStatusCd === PmAdjustmentNotificationInfoGENERATED.NOTIFICATION_STATUS_CD_NOT_SENT);

    return result;
  }

 public  isNotificationStatusCdPENDING(): boolean {
    const result = (this.notificationStatusCd === PmAdjustmentNotificationInfoGENERATED.NOTIFICATION_STATUS_CD_PENDING);

    return result;
  }


  
}
