<h3 class="page-title">{{ 'cc.bank.psd2-statement-report' | translate }}</h3>

<div class="actions-container">
  <span>{{ 'cc.bank.psd2-statement-report-list' | translate }}</span>
  <div class="actions-wrapper">
    <button class="system-action-btn mr-2" (click)="showImportPsd2Mcb.setTrue()"><i class="fas fa-file-export"></i>{{'cc.bank.import-psd2-statement-file' | translate}}
    </button>
  </div>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="ebPsd2ReportList.items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  [totalItemsCount]="ebPsd2ReportList.totalItemsCount"
  [itemsPerPage]="ebPsd2ReportList.pageItemsPerPageCount"
  (filterChanged)="onFilterChanged($event)"
  (actionMkMatMenuItems)="onMkMatMenuItemsAction($event)"
  [mkMatMenuActionItems]=""
  [searchTooltip]=""
>
</mk-mat-table>

<mcc-modal1-wrapper modalSizeCd="small" [showMcb]="showImportPsd2Mcb" *ngIf="showImportPsd2Mcb.value">
  <eb-bank-module-import-psd2-statement-part (eventSaved)="actPsd2StatementImported()" (eventCanceled)="showImportPsd2Mcb.setFalse()"></eb-bank-module-import-psd2-statement-part>
</mcc-modal1-wrapper>
