/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
/* Using "any" data-type in returned observables to avoid importing   */
/* ItemClass and ItemListClass and circular dependencies.             */
/* ------------------------------------------------------------------ */
import {BaseObjectService} from '../_core/base-object-service';
import {IMcActionPdfTemplate} from '../_generated/mc-action-pdf-template.interface';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';
// import {McActionPdfTemplate} from '../models/mc-action-pdf-template.model';
// import {McActionPdfTemplateList} from '../models/mc-action-pdf-template-list.model';
// import {McActionPdfTemplateFilter} from '../models/mc-action-pdf-template-filter.model';
import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class McActionPdfTemplateServiceGENERATED extends BaseObjectService {
protected apiServiceUrlSubPath = "/mcprocess/v2/mcactionpdftemplate";

public getApiServiceRootUrl(): string {
    return this.apiUrl + this.apiServiceUrlSubPath;
  }

  /* Get all  from DB */
public getAll(aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/all?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  /* Get all  from DB without pager */
public getByFilter(aFilter: any, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/filter?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, aFilter.toDto(), {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

/* Get by id from DB */
public getById(id: number): Observable<IMcActionPdfTemplate> {
  return this.httpClient.get<IMcActionPdfTemplate>(this.getApiServiceRootUrl() + `/${id}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

// Insert Object
public insert(aMcActionPdfTemplate: any): Observable<IMcActionPdfTemplate> {
    return this.httpClient.post<IMcActionPdfTemplate>(this.getApiServiceRootUrl()  , aMcActionPdfTemplate.toDto(), {headers: this.httpOptions});
}
// Update object
public update(aMcActionPdfTemplate: any): Observable<IMcActionPdfTemplate> {
    return this.httpClient.put<IMcActionPdfTemplate>(this.getApiServiceRootUrl() + `/`+ aMcActionPdfTemplate.id, aMcActionPdfTemplate.toDto(), {headers: this.httpOptions});
}


// DeleteAllFromDB
public deleteAllFromDb() {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/deleteAllFromDb`, {headers: this.httpOptions});
  }

  /* Get by id from DB */
public deleteById(id: number) {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/${id}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });

  }

  // -- CUSTOM METHODS --
  
}
