import {McCountry} from './mc-country.model';
import {McCountryListGENERATED} from '../_generated/mc-country-list-generated.model';

export class McCountryList extends McCountryListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McCountryList();
  }

  // ---------------------------------------------------------------------
}
