/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {ImDataImportItemTable3ConfigGENERATED} from '../_generated/im-data-import-item-table-3-config-generated.model';

// import {ImDataImportItemGENERATED} from '../_generated/im-data-import-item-generated.model';

export class ImDataImportItemTable3Config extends ImDataImportItemTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colDataImport', 'Data import', 'dataImport', 'dataImport');
    this.addColumn('colIdDataImport', 'Data import ID', 'idDataImport', 'idDataImport');
    this.addColumn('colIndex', 'Index', 'index', 'index');
    this.addColumn('colMessage', 'Message', 'message', 'message');
    this.addColumn('colStatusCd', 'Status', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');


  }
}
