/*BbmTestObj*/
import {PpPriceAdjustmentGENERATED} from '../_generated/pp-price-adjustment-generated.model';

export class PpPriceAdjustment extends PpPriceAdjustmentGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PpPriceAdjustment();
  }

  // ---------------------------------------------------------------------
}
