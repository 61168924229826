import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {McSlideActionItem, McSlideActionSet} from "@miticon-ui/mc-core";

@Component({
  selector: 'mcc-slide-action-set',
  templateUrl: './mcc-slide-action-set.component.html',
  styleUrls: ['./mcc-slide-action-set.component.scss']
})
export class MccSlideActionSetComponent implements OnInit {

  // Variables

  @Input() selectedNumber!: number;
  @Input() showSlideActionSetNoOfItems = 2;
  @Input() actionSet!: McSlideActionSet;
  @Output() eventActionClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  actClickedOnItem(actionItem: McSlideActionItem) {
    this.eventActionClicked.emit(actionItem);
  }
}
