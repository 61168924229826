/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CsrContractHistoryTable3ConfigGENERATED} from '../_generated/csr-contract-history-table-3-config-generated.model';

// import {CsrContractHistoryGENERATED} from '../_generated/csr-contract-history-generated.model';

export class CsrContractHistoryTable3Config extends CsrContractHistoryTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActionTypeCd', 'Action Type CD', 'actionTypeCd', 'actionTypeCd');
    this.addColumn('colFieldName', 'Field Name', 'fieldName', 'fieldName');
    this.addColumn('colFieldTypeCd', 'Field Type CD', 'fieldTypeCd', 'fieldTypeCd');
    this.addColumn('colIdCsrConsumer', 'Consumer ID', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colIdCsrContract', 'Contract ID', 'idCsrContract', 'idCsrContract');
    this.addColumn('colNewValue', 'New Value', 'newValue', 'newValue');
    this.addColumn('colOldValue', 'Old Value', 'oldValue', 'oldValue');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colVersion', 'Version', 'version', 'version');


  }
}
