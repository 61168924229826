import {Component, OnInit} from '@angular/core';
import {McBillingService2PriceListList, McBoolean, McEntity2List, McForm, McGod, McPriceList, McPriceListFilter, McPriceListList, McPriceListService, McValueLabel, McValueLabelList, SortCriteriaList} from "@miticon-ui/mc-core";
import {Router} from "@angular/router";
import {MkFilterConfig, MkFilterOutput, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig} from "@miticon-ui/mc-components";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'mc-pricelist-pricelists-list-page',
  templateUrl: './mc-pricelist-pricelists-list-page.component.html',
  styleUrls: ['./mc-pricelists-pricelists-list-page.scss']
})
export class McPricelistPricelistsListPageComponent implements OnInit {
  mcTemplates = new McPriceListList();
  mcForm = new McForm();
  mcFormForCreatePricelist = new McForm();
  entityId!: number;
  showAddPricelistStep1 = new McBoolean();
  showAddPricelistStep2PricesAndEntities = new McBoolean();
  showAddPricelistStep3ReviewAndConfirm = new McBoolean();
  chosenTemplatePriceList = new McPriceList();
  defaultChosenTemplatePriceList = new McPriceList();
  mcTemplateFilter = new McPriceListFilter();
  pricelistEntitiesValue!: McValueLabelList;
  mcGod = McGod.getInstance();
  selectedEntities!: McEntity2List;
  createPricelistCurrencySelected!: string;
  currencyCode!: string;

  totalItemsCount!: number;
  pageSize = 5;
  pageNumber= 0;
  isLoading = false;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  items: McPriceList[] = [];
  searchTooltip = this.tS.instant('cc.pricelist.search-tooltip');

  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.my-factoring.view-details'),
      actionCd: McPriceList.ACTION_VIEW_DETAILS,
      matIcon: 'visibility',
      permission: McGod.PERM_MC_BILLING_PRICELIST_VIEW
    }
  ];

  constructor(private router: Router,
              private tS: TranslateService,
              private toastrService: ToastrService,
              private mcPricelistService: McPriceListService) {
  }

  ngOnInit() {
    this.entityId = McGod.getLoggedEntityIdFromToken();
    this.mcTemplates.loadAllPossibleTemplatesForNewPriceList();

    this.initTableConfig();
    this.mcTemplateFilter.idEntityOwner = this.entityId;
    this.mcTemplateFilter.isTemplate = false;
    this.actLoad(this.pageNumber, this.pageSize, this.mcTemplateFilter);
  }

  initTableConfig(){
    this.tableConfig.addColumnStandard(this.tS.instant('cc.pricelist.pricelist-name'), 'title', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.pricelist.pricelist-description'), 'description', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.pricelist.template-origin'), 'nameOfOriginTemplate', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.pricelist.no-of-entities'), 'fldNumberOfEntities()', 250);
    this.tableConfig.addColumnSvgIcon(this.tS.instant('cc.pricelist.default-custom-prices'), 'getDefaultCustomLbl()', '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.pricelist.valid-from'), 'getValidFromStr()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.created-date-and-time'), 'getSysCreatedDatetimeString()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.pricelist.created-by'), 'createdByUserFullName', 250);
  }

  actLoad(pageNumber: number, pageSize: number, mcPriceListFilter: McPriceListFilter): void {
    this.pageNumber=pageNumber;
    this.pageSize= pageSize;
    this.isLoading = true;
    const sortCriteriaList = new SortCriteriaList();

    this.mcPricelistService.getByFilter(mcPriceListFilter, this.pageNumber, this.pageSize, sortCriteriaList).subscribe((res) => {
      if(res){
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => McPriceList.createFromJson(object));
      }
    })
  }

  onFilterChanged(filterOutput: MkFilterOutput){
    this.mcTemplateFilter.searchTerm = filterOutput.search ? filterOutput.search : '';
    this.mcTemplateFilter.idEntityOwner = this.entityId;
    this.mcTemplateFilter.isTemplate = false;
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, this.mcTemplateFilter);
  }

  onTableAction($event: MkMatTableMenuAction) {
    switch ($event.action.actionCd) {
      case McPriceList.ACTION_VIEW_DETAILS:
        this.router.navigate([`/entity/${this.entityId}/pricelists/overview/${$event.item.id}`]);
        break;
    }
  }

  actShowNewPricelistMcb() {
    this.showAddPricelistStep1.setTrue();
  }

  actCloseAddPricelistStepper() {
    this.showAddPricelistStep1.setFalse();
    this.showAddPricelistStep2PricesAndEntities.setFalse();
    this.showAddPricelistStep3ReviewAndConfirm.setFalse();
    this.mcFormForCreatePricelist = new McForm();
    this.pricelistEntitiesValue = new McValueLabelList();
    this.actLoad(this.pageNumber, this.pageSize, this.mcTemplateFilter);
    this.mcTemplates.loadAllPossibleTemplatesForNewPriceList();
  }

  onAddPricelistStep1NextEmit(currencyCode: string) {
    this.currencyCode = currencyCode;

    // @ts-ignore
    this.chosenTemplatePriceList = this.mcTemplates.getItemById(this.mcFormForCreatePricelist.getValue('ctrlTemplate'));
    this.defaultChosenTemplatePriceList.mcBillingService2PriceListItemList = new McBillingService2PriceListList();
    this.chosenTemplatePriceList.mcBillingService2PriceListItemList.items.forEach(item => {
      // @ts-ignore
      this.defaultChosenTemplatePriceList.mcBillingService2PriceListItemList.addItem({...item});
    });

    this.showAddPricelistStep2PricesAndEntities.setTrue();
    this.showAddPricelistStep1.setFalse();

  }

  onAddPricelistStep2PrevEmit() {
    this.showAddPricelistStep1.setTrue();
    this.showAddPricelistStep2PricesAndEntities.setFalse();
  }

  onAddPricelistStep2NextEmit() {
    this.showAddPricelistStep2PricesAndEntities.setFalse();
    this.showAddPricelistStep3ReviewAndConfirm.setTrue();
    this.pricelistEntitiesValue = new McValueLabelList();
    const entitiesValue: [] = this.mcFormForCreatePricelist.getValue('ctrlEntitiesPricelist');
    entitiesValue.forEach(item => {
      const entity = new McValueLabel();
      entity.label = item;
      entity.value = item;
      this.pricelistEntitiesValue.addItem(entity);
    });
  }

  onAddPricelistStep3PrevEmit() {
    this.pricelistEntitiesValue = new McValueLabelList();
    const entitiesValue: [] = this.mcFormForCreatePricelist.getValue('ctrlEntitiesPricelist');
    entitiesValue.forEach(item => {
      const entity = new McValueLabel();
      entity.label = item;
      entity.value = item;
      this.pricelistEntitiesValue.addItem(entity);
    });
    this.showAddPricelistStep2PricesAndEntities.setTrue();
    this.showAddPricelistStep3ReviewAndConfirm.setFalse();
  }

  onAddPricelistSaveEmit() {
    this.showAddPricelistStep3ReviewAndConfirm.setFalse();
    this.mcFormForCreatePricelist = new McForm();
    this.actLoad(this.pageNumber, this.pageSize, this.mcTemplateFilter);
    this.mcTemplates.loadAllPossibleTemplatesForNewPriceList();
  }

  onSelectedEntities(value: McEntity2List) {
    this.selectedEntities = value;
  }

  onCurrencySymbolEmitted(value: string) {
    this.createPricelistCurrencySelected = value;
  }
}
