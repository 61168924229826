import {Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import {McUtilityService} from '../services/mc-utility.service';
import {McPermissionService} from '../services/mc-permission.service';
import {McGod} from '../extra/mc-god.service';

@Directive({
  selector: '[McdPermissions]'
})
export class McdPermissionsDirective implements OnInit {


  /*Events*/
  @Input() permissions: any;
  @Input() operation = 'OR';


  /*Constructor*/
  constructor(private utilityService: McUtilityService,
              private permissionService: McPermissionService,
              private renderer: Renderer2,
              private el: ElementRef) {

  }

  /*On init class*/
  ngOnInit() {

    /*Show or hide element*/
    if ( !this.userHasValidPermissions()) {
      const parent = this.renderer.createElement('div');
      const parentNode = this.renderer.appendChild(parent, this.el.nativeElement);
      this.renderer.removeChild(parentNode, this.el.nativeElement);
    }
  }

  /*Check user has permission*/
  private userHasValidPermissions() {
    let result = true;
    // console.log(this.hasPermissions);
    // console.log(this.userPermissions);
    if (this.permissions) {
      result = McGod.userHasPermissions(this.permissions);

       /*for (const permCd of this.permissions) {
        result = result && McGod.userHasPermission(permCd);
        console.log("ChecvkingPerm99: ", permCd);
      }*/
    }
    return result;
  }

}
