<ng-template #newEntityModal let-modal>

  <!--Modal header section-->
  <div class="modal-header mc-create-entity-modal-header">
    <h4 class="modal-title">{{'cc.entities.edit.create-entity' | translate}}:</h4>
    <button type="button" class="close mc-entity-close-btn" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div><!--Modal header -->

  <!--Modal body section-->
  <div class="modal-body">
    <div class="mc-create-new-entity-wrapper">
      <div class="container-fluid">
        <div class="mc-create-entity-notify-wrapper">
          <mc-notify-message [message]="message" [type]="type"></mc-notify-message>
        </div>
        <form [formGroup]="createEntityForm">
          <lib-mc-stepper [isDataFormInvalid]="isEntityFormValid">
            <!--First step - entity-basic-info-->
            <cdk-step>
              <mc-entity-basic-info
                [parentForm]="createEntityForm"
                (isEntityBasicInfoDataInvalid)="entityDataFormValid($event)"
                (selectedImagePath)="imagePathFile($event)"
              >
              </mc-entity-basic-info>
            </cdk-step>
            <!--Second step - entity-attributes-->
            <cdk-step>
              <mc-entity-attributes
                [entityId]="parentEntityId"
                [inheritedAttributes]="inheritedAttributesList"
                [parentForm]="createEntityForm"
                (isEntityAttributesDataInvalid)="entityDataFormValid($event)">
              </mc-entity-attributes>
            </cdk-step>
            <!--Third step - entity-password-policy-->
            <cdk-step>
              <mc-entity-password-policy
                [parentData]="passwordPolicyObject"
                [parentForm]="createEntityForm"
                (isEntityPasswordPolicyDataInvalid)="entityDataFormValid($event)">
              </mc-entity-password-policy>
            </cdk-step>
            <!--Fourth step - entity-pricelist-->
            <cdk-step>
              <lib-mc-entity-pricelist
                [parentForm]="createEntityForm"
                (eventPricelistChosen)="onPricelistChosen($event)"
                (isPricelistFormInvalid)="entityDataFormValid($event)">
              </lib-mc-entity-pricelist>
            </cdk-step>
            <!--Summary step - summary-data-->
            <cdk-step>
              <mc-entity-summary-data
                [pricelist]="pricelist"
                [parentForm]="createEntityForm"
                [imagePath]="imagePath"
                (closeModal)="closeModal($event)"m
                (updateEntityData)="refreshData($event)">
              </mc-entity-summary-data>
            </cdk-step>
          </lib-mc-stepper>
        </form>
      </div><!--Container /end-->
    </div><!--Wrapper /end-->
  </div><!--Modal body /end-->
</ng-template>

<button id="mc-create-new-entity-btn" class="system-action-btn" (click)="openModal(newEntityModal)" #openModalBtn>
  <i class="fas fa-plus-circle"></i>
  {{'cc.entities.view.create-new-entity' | translate}}
</button>
