/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EbPaymentReport} from '../models/eb-payment-report.model';
import {EbPaymentReportList} from '../models/eb-payment-report-list.model';
import {EbPaymentReportFilter} from '../models/eb-payment-report-filter.model';
import {EbPaymentReportService} from '../services/eb-payment-report.service';
import {IEbPaymentReport} from '../_generated/eb-payment-report.interface';
import {McGod} from "../extra/mc-god.service";

export class EbPaymentReportListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'details', 'errorReason', 'flgExported', 'idEbReport', 'importTypeCd', 'matchingType', 'status', 'sysCreatedDatetime', 'sysUpdatedDatetime', 'transactionDate', 'type', 'amount', 'currencyStr', 'debtorAccountId', 'debtorName', 'description'];
  public items: EbPaymentReport[] = [];
  public apiService: EbPaymentReportService;
 // protected tempObjList: EbPaymentReportList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbPaymentReportList {
    console.error('MTCN-ERROR: Not Implemented: EbPaymentReportList::createNewInstance(). Add this method to final class and return new EbPaymentReportList();');
    throw new Error('Cannot EbPaymentReportList::createNewInstance(). Add this method to final class and return new EbPaymentReportList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEbPaymentReport[]): EbPaymentReportList {
    const  listObj: EbPaymentReportList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EbPaymentReport = EbPaymentReport.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EbPaymentReportList {
    const  listObj: EbPaymentReportList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbPaymentReportService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEbPaymentReport[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EbPaymentReport = EbPaymentReport.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEbPaymentReportFilter: EbPaymentReportFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEbPaymentReportFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EbPaymentReport {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EbPaymentReport();
    }
  }

  public replaceItem(newItem: EbPaymentReport) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --

// ---------------------------------------------------------------------
public callMatch( id: number, id_transactions: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.match(id,id_transactions).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

// ---------------------------------------------------------------------
  public callMarkAsInvalid( id: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.markAsInvalid(id)
      .subscribe(responseJson => {
        this.setApiSuccess();
        this.processApiSuccess(responseJson, onSuccessCallback);
      },
      errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

}

