import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ImDataSetAttributeService} from "@miticon-ui/mc-core";
import {TranslateService} from "@ngx-translate/core";
import {ICON_CLOSE} from "@miticon-ui/mc-components";

@Component({
  selector: 'lib-data-import-attribute-add-dialog',
  templateUrl: './data-import-attribute-add-dialog.component.html',
  styleUrls: ['./data-import-attribute-add-dialog.component.scss']
})
export class DataImportAttributeAddDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  form!: FormGroup;
  attributeTypes =  [
    { value: 'bigint', label: 'Number'},
    { value: 'varchar', label: 'String'},
    { value: 'timestamp', label: 'Date Time'},
    { value: 'decimal', label: 'Decimal'},
    { value: 'boolean', label: 'Yes/No'}];
  checkedDefaultValue: boolean = false;
  dataSetId!: number;
  typeCd!: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: number,
              public dialogRef: MatDialogRef<DataImportAttributeAddDialogComponent>,
              private fb:  FormBuilder,
              private dataSetAttributeService: ImDataSetAttributeService,
              private cdr: ChangeDetectorRef,
              private tS: TranslateService) {}

  ngOnInit(): void {
    this.dataSetId = this.data;
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      idMcEntity: [localStorage.getItem('entityId')],
      creationDate: [new Date()],
      customFlg: [true],
      description: [''],
      enabledFlg: [true],
      idDataSet: [this.dataSetId],
      inheritableFlg: ['', Validators.required],
      requiredFlg: [false],
      name: ['', Validators.required],
      typeCd: ['', Validators.required],
      sampleData: [''],
    });
  }

  setTypeCd(typeCd: string) {
    this.typeCd = typeCd;
    this.form.controls['sampleData'].setValue(null);
  }

  checkValidation(event: KeyboardEvent) {
    switch (this.typeCd) {
      case 'bigint':
        this.onlyNumbers(event);
        break;
      case 'decimal':
        this.onlyDecimal(event, this.form.controls['sampleData'].value);
        break;
    }
  }

  onlyNumbers(event: KeyboardEvent) {
    const onlyNumbersRegex = /[0-9\b]/;
    const backspace = 'Backspace'
    if (!event.key.match(onlyNumbersRegex) && event.key != backspace) {
      event.preventDefault();
    }
  }

  onlyDecimal(event: KeyboardEvent, formValue: any) {
    const onlyDecimalRegex = /[0-9+-\.]/;
    if(event.key === '.' && formValue && formValue.indexOf('.') !== -1) {
      event.preventDefault();
    }
    if (!onlyDecimalRegex.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight") {
      event.preventDefault();
    }
  }

  onlyNumbersCheck(event: KeyboardEvent) {
    this.onlyNumbers(event);
  }

  createAttribute() {
    const attributeName = this.form.get('name')?.value;
    this.form.get('label')?.setValue(attributeName);
    if(this.form.valid) {
      this.dataSetAttributeService.insert(this.form.value).subscribe((data) => {
        if(data) {
          this.dialogRef.close(true);
        }
      });
    }
  }

  checkDefaultValue($event: any) {
    this.checkedDefaultValue = $event.checked;
    if($event.checked) {
      this.form.get('value')?.setValidators(Validators.required);
    } else {
      this.form.get('value')?.clearValidators();
      this.form.get('value')?.updateValueAndValidity();
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

}
