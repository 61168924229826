import {McConsumerBalance} from './mc-consumer-balance.model';
import {McConsumerBalanceListGENERATED} from '../_generated/mc-consumer-balance-list-generated.model';

export class McConsumerBalanceList extends McConsumerBalanceListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McConsumerBalanceList();
  }

  // ---------------------------------------------------------------------
}
