import {CsrContractListGENERATED} from '../_generated/csr-contract-list-generated.model';

export class CsrContractList extends CsrContractListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrContractList();
  }

  // ---------------------------------------------------------------------

}
