/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McProductSnapshot} from '../models/mc-product-snapshot.model';
import {McProductSnapshotList} from '../models/mc-product-snapshot-list.model';
import {McProductSnapshotFilter} from '../models/mc-product-snapshot-filter.model';
import {McProductSnapshotService} from '../services/mc-product-snapshot.service';
import {IMcProductSnapshot} from '../_generated/mc-product-snapshot.interface';
import {McGod} from "../extra/mc-god.service";

export class McProductSnapshotListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'availableFrom', 'availableTo', 'description', 'externalId', 'idCurrency', 'idMcEntity', 'idPpCategory', 'idPpProduct', 'idVat', 'name', 'sysCreatedDatetime', 'unitPrice', 'version', 'currency', 'vat'];
  public items: McProductSnapshot[] = [];
  public apiService: McProductSnapshotService;
 // protected tempObjList: McProductSnapshotList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProductSnapshotList {
    console.error('MTCN-ERROR: Not Implemented: McProductSnapshotList::createNewInstance(). Add this method to final class and return new McProductSnapshotList();');
    throw new Error('Cannot McProductSnapshotList::createNewInstance(). Add this method to final class and return new McProductSnapshotList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcProductSnapshot[]): McProductSnapshotList {
    const  listObj: McProductSnapshotList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McProductSnapshot = McProductSnapshot.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McProductSnapshotList {
    const  listObj: McProductSnapshotList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProductSnapshotService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcProductSnapshot[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McProductSnapshot = McProductSnapshot.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcProductSnapshotFilter: McProductSnapshotFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcProductSnapshotFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McProductSnapshot {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McProductSnapshot();
    }
  }

  public replaceItem(newItem: McProductSnapshot) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

