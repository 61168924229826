/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {PpOrderTable3ConfigGENERATED} from '../_generated/pp-order-table-3-config-generated.model';

// import {PpOrderGENERATED} from '../_generated/pp-order-generated.model';

export class PpOrderTable3Config extends PpOrderTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colOrderNumber', 'Order number', 'orderNumber', 'orderNumber');
    this.addColumn('colIdReceiverCsrConsumer', 'Receiver', 'receiver', 'receiver');
    this.addColumn('colSendingDate', 'Sending date', 'sendingDate', 'sendingDate');
    this.addColumn('colDueDate', 'Due date', 'dueDate', 'dueDate');
    this.addColumn('colSysCreatedDatetime', 'Creation date', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colState', 'State', 'state', 'state');
    this.addColumn('colTotalAmount', 'Total amount', 'totalAmount', 'totalAmount');
  }
}
