import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Invoice, OrderNumber, OrderList, OrderSchedule} from '../models/invoice.model';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class McInvoiceService {

  constructor(private http: HttpClient,
              @Inject('environment') environment: any) {
    /*Set API base url*/
    this.apiUrl = environment.apiUrl;
    this.httpOptions = environment.httpOptions(HttpHeaders);
  }

  private readonly apiUrl: string;
  private readonly httpOptions;

  /************************************Invoice************************************/
  /*Invoice list*/
  public _invoiceList = [];
  private _invoiceListBehavior = new BehaviorSubject([]);
  public invoiceList = this._invoiceListBehavior.asObservable();

  private _createdInvoiceList = new BehaviorSubject([]);
  public createdInvoiceList = this._createdInvoiceList.asObservable();

  private _lastCreatedInvoice = new BehaviorSubject(null);
  public lastCreatedInvoice = this._lastCreatedInvoice.asObservable();

  /* Get All Invoices for Consumer */
  public getInvoices(body: { receiverConsumerId: any; allEntity: boolean; search: string; states: never[]; }, pagination: { size?: any; page?: any; }) {
    const apiUrl = Object.keys(pagination).length > 0 ? `${this.apiUrl}/ppinvoice/v4/pporder?size=${pagination.size}&page=${pagination.page}` :  `${this.apiUrl}/ppinvoice/v4/pporder`;
    return this.http.get(apiUrl);
  }

  public getOrdersByFilter(params: HttpParams){
    return this.http.get(`${this.apiUrl}/ppinvoice/v4/pporder` + `?${params.toString()}` + `&sort=id,DESC`);
  }

  /* Create Invoice*/
  public createInvoice(body: any) {
    return this.http.post(`${this.apiUrl}/ppinvoice/v4/pporder`, body, this.httpOptions);
  }

  /* Update Invoice */
  public updateOrderList(orderId: number, body: any) {
    return this.http.put<OrderList>(`${this.apiUrl}/ppinvoice/v4/pporder/${orderId}`, body, this.httpOptions);
  }

  /* Send Order Schedule */
  public sendOrderSchedule(orderId: any, body: any) {
    return this.http.put<OrderSchedule>(`${this.apiUrl}/ppinvoice/v4/pporder/${orderId}/proceed`, body, this.httpOptions);
  }

  /* Verify Order */
  public verifyOrder(orderId: any) {
    return this.http.put(`${this.apiUrl}/ppinvoice/v4/pporder/${orderId}/verify`, this.httpOptions);
  }

  /* Invoice overview */
  public invoiceGeneratePreview(data: any) {
    return this.http.post(`${this.apiUrl}/ppinvoice/v4/pporder/generate-pdf-preview/`, data, {responseType: 'blob'});
  }

  /* Get All Order Items by orderId */
  public getAllOrderItems(orderId: number) {
    return this.http.get(`${this.apiUrl}/ppinvoice/v4/pporder/${orderId}`, this.httpOptions);
  }

  public cancelOrder(orderId: number){
    return this.http.put(`${this.apiUrl}/ppinvoice/v4/pporder/cancel/${orderId}`, this.httpOptions)
  }


  public addProductToInvoice(product: { hasOwnProperty?: any; vat?: any; quantity?: any; unitPrice?: any; discount?: any; discounts?: any; id?: any; vatRate?: any}) {
    let defineProduct = {};

    if (product.hasOwnProperty('quantity')) {
      defineProduct = {
        ...product,
        idVat: String(product.vat),
        totalPrice: product.quantity * product.unitPrice,
        discount: product.discount
      };
    } else {
      defineProduct = {
        ...product,
        idPpProduct: product.id,
        note: '',
        quantity: 1,
        totalPrice: 1 * product.unitPrice,
        idVat: String(product.vat),
        orderItemSorterId: this._invoiceList.length + 1,
        discount: {
          value: 0,
          amount: 0,
          valueTypeCd: 'PERCENTAGE',
          typeCd: 'DISCOUNT'
        },
        vat: product.vatRate ? product.vatRate : 0
      };
    }
    // @ts-ignore
    this._invoiceList.push(defineProduct);
    this._invoiceListBehavior.next(this._invoiceList);
  }

  public removeProductFromInvoice(productIndex: number) {
    this._invoiceList.splice(productIndex, 1);
    this._invoiceListBehavior.next(this._invoiceList);
  }

  public updateProductInInvoice(productIndex: any, product: any) {
      // @ts-ignore
    this._invoiceList[productIndex] = product;
      this._invoiceListBehavior.next(this._invoiceList);
  }

  /*Get product from invoice list - use it for reset values*/
  public getProductFromInvoiceList(productId: any): any {
    if (productId) {
      return this._invoiceList.find((product: any) => product.id === productId);
    }
  }

  /*Clear invoice list*/
  public clearInvoiceList() {
    this._invoiceList = [];
    this._invoiceListBehavior.next(this._invoiceList);
  }

  public addCreatedInvoiceToList(invoiceIdList: never[]) {
    this._createdInvoiceList.next(invoiceIdList);
  }

  public setLastCreatedInvoice(invoiceId: number | null) {
    // @ts-ignore
    this._lastCreatedInvoice.next(invoiceId);
  }

}
