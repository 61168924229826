import {Inject, Injectable} from '@angular/core';
import {McBillingPayoutFileFeCtdServiceGENERATED} from '../_generated/mc-billing-payout-file-fe-ctd-generated.service';
import {Observable} from "rxjs";
import {HttpHeaders} from "@angular/common/http";



@Injectable({
  providedIn: 'root'
})
export class McBillingPayoutFileFeCtdService extends McBillingPayoutFileFeCtdServiceGENERATED {

  constructor() {
    super();
  }

  public createPayoutFile(type: string, statementIds: number[], bankAccountId: number): Observable<any> {
    const body = {
      type,
      statementIds,
      bankAccountId
    };
    return this.httpClient.post(`${this.getBaseUrl(type)}/statements`, body, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  public sendViaEbics(payoutFileId: number ) {
    return this.httpClient.post<any>(this.getBaseUrl('EBICS') + '/ebics/send/' + `${payoutFileId}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  private getBaseUrl(type: string): string {
    if (type === 'EBICS') {
      return this.apiUrl + '/ebbank/v3/ebpayout'
    }
    return this.getApiServiceRootUrl();
  }

  uploadFile(payoutFileId: number, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(this.getApiServiceRootUrl() + `/${payoutFileId}/upload`, formData);
  }

}
