import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mc-core-ui',
  templateUrl: './mc-core-ui.component.html',
  styleUrls: ['./assets/scss/styles.scss'],
  encapsulation: ViewEncapsulation.None
})
export class McCoreUiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
