/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McCategory} from '../models/mc-category.model';
import {McCategoryService} from '../services/mc-category.service';
import {IMcCategory} from '../_generated/mc-category.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McCategoryGENERATED extends BaseObject {


  public apiService: McCategoryService;
  public _rawJson: IMcCategory;
    id: number = 0;
  createdAt: number;
  createdBy: number;
  description = '';
  idMcEntity: number;
  name = '';
  updatedAt: number;
  updatedBy: number;
  version: number;


  public properties: string[] = ['id', 'createdAt', 'createdBy', 'description', 'idMcEntity', 'name', 'updatedAt', 'updatedBy', 'version'];
  public propertiesRegular: string[] = ['id', 'createdAt', 'createdBy', 'description', 'idMcEntity', 'name', 'updatedAt', 'updatedBy', 'version'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): McCategory {
    console.error('MTCN-ERROR: Not Implemented: McCategory::createNewInstance(). Add this method to final class and return new McCategory();');
    throw new Error('Cannot McCategory::createNewInstance(). Add this method to final class and return new McCategory();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcCategory): McCategory {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McCategoryService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McCategory AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getCreatedAtStrD(): string {
       return McDateUtils.formatDateToString(this.createdAt);
    }

    public getCreatedAtStr(): string {
       return McDateUtils.formatDateTimeToString(this.createdAt);
    }

    public getUpdatedAtStrD(): string {
       return McDateUtils.formatDateToString(this.updatedAt);
    }

    public getUpdatedAtStr(): string {
       return McDateUtils.formatDateTimeToString(this.updatedAt);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
