/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {CsrNotificationDetails} from '../models/csr-notification-details.model';
import {CsrNotificationDetailsService} from '../services/csr-notification-details.service';
import {ICsrNotificationDetails} from '../_generated/csr-notification-details.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class CsrNotificationDetailsGENERATED extends BaseObject {


  public apiService: CsrNotificationDetailsService;
  public _rawJson: ICsrNotificationDetails;
    id: number = 0;
  email = '';
  sendInvoice: boolean;
  streetAddress = '';


  public properties: string[] = ['id', 'email', 'sendInvoice', 'streetAddress'];
  public propertiesRegular: string[] = ['id', 'email', 'sendInvoice', 'streetAddress'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): CsrNotificationDetails {
    console.error('MTCN-ERROR: Not Implemented: CsrNotificationDetails::createNewInstance(). Add this method to final class and return new CsrNotificationDetails();');
    throw new Error('Cannot CsrNotificationDetails::createNewInstance(). Add this method to final class and return new CsrNotificationDetails();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: ICsrNotificationDetails): CsrNotificationDetails {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrNotificationDetailsService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CsrNotificationDetails AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
