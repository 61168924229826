<mat-expansion-panel class="mb-3" (opened)="panelOpenState = true"(closed)="panelOpenState = false">
  <mat-expansion-panel-header>
    <mat-panel-title>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
</mat-expansion-panel>



