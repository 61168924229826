/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EbPsd2Report} from '../models/eb-psd2-report.model';
import {EbPsd2ReportList} from '../models/eb-psd2-report-list.model';
import {EbPsd2ReportFilter} from '../models/eb-psd2-report-filter.model';
import {EbPsd2ReportService} from '../services/eb-psd2-report.service';
import {IEbPsd2Report} from '../_generated/eb-psd2-report.interface';
import {McGod} from "../extra/mc-god.service";

export class EbPsd2ReportListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'dateFrom', 'dateTo', 'entryCount', 'idMcBankAccount', 'idMcCurrency', 'idMcEntity', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'bankName', 'matchedReturnedAmount', 'matchedReturnedCount', 'matchedWithdrawnAmount', 'matchedWithdrawnCount', 'mcCurrency', 'totalReturnedAmount', 'totalReturnedCount', 'totalWithdrawnAmount', 'totalWithdrawnCount'];
  public items: EbPsd2Report[] = [];
  public apiService: EbPsd2ReportService;
 // protected tempObjList: EbPsd2ReportList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbPsd2ReportList {
    console.error('MTCN-ERROR: Not Implemented: EbPsd2ReportList::createNewInstance(). Add this method to final class and return new EbPsd2ReportList();');
    throw new Error('Cannot EbPsd2ReportList::createNewInstance(). Add this method to final class and return new EbPsd2ReportList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEbPsd2Report[]): EbPsd2ReportList {
    const  listObj: EbPsd2ReportList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EbPsd2Report = EbPsd2Report.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EbPsd2ReportList {
    const  listObj: EbPsd2ReportList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbPsd2ReportService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEbPsd2Report[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EbPsd2Report = EbPsd2Report.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEbPsd2ReportFilter: EbPsd2ReportFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEbPsd2ReportFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EbPsd2Report {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EbPsd2Report();
    }
  }

  public replaceItem(newItem: EbPsd2Report) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --

}

