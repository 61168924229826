<div class="mcc-fi mcc-fi-checkbox mcc-fi-checkbox-{{name}} {{extraCssClass}} {{labelPositionClass}}">
<form [formGroup]="getCheckBoxForm()" [ngClass]="{'validation-error' : this.getCheckBoxForm()?.get('errorFlg')?.value}">
  <div style="display: inline">
    <input type="checkbox"
           id="mcc-fi-{{name}}"
           formControlName="value"
           [checked]="isCheckboxChecked"
           (ngModelChange)="onCheckBoxChanged($event)">
    <label *ngIf="label"  for="mcc-fi-{{name}}" >{{label}} <i class="fas fa-info-circle mc-tooltip-top-margin ml-2" style="margin-top: 0 !important;" tooltipClass="mc-tooltip-length" *ngIf="tooltipText"
                                                              [ngbTooltip]="tooltipText"
                                                              placement="bottom-left"></i></label>

  </div>

  <div class="error-msg">{{this.getCheckBoxForm()?.get('errorMsg')?.value}}</div>

</form>

</div>
