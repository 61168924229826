import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McJobActionComponent } from './mc-job-action.component';

@NgModule({
  declarations: [McJobActionComponent],
  exports: [
    McJobActionComponent
  ],
  imports: [
    CommonModule
  ]
})
export class McJobActionModule { }
