import {Component, OnInit} from '@angular/core';
import {
  EbSepaExportList,
  EbSepaExport,
  EbSepaExportFilter,
  EbSepaExportService,
  McValueLabelList,
  WebFile,
  McBoolean,
  McGod,
  McBankAccountService, BaseDirective, SortCriteriaList
} from '@miticon-ui/mc-core';
import {McForm} from '@miticon-ui/mc-core';
import * as fileSaver from 'file-saver';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ToastrService} from "ngx-toastr";
import { MkFilterConfig, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig, MkFilterItemType, MkFilterValue, MkFilterOutput } from '@miticon-ui/mc-components';
import { TranslateService } from '@ngx-translate/core';

enum RevertDialogAction {
  ACT_CANCEL = 'btn-secondary',
  ACT_REVERT = 'btn-primary'
}

@Component({
  selector: 'mc-out-factoring-sepa-out-list-page',
  templateUrl: './mc-out-factoring-sepa-out-list-page.component.html',
})
export class McOutFactoringSepaOutListPageComponent extends BaseDirective implements OnInit {

  mcForm = new McForm();
  ebSepaExportList: EbSepaExportList = new EbSepaExportList();
  ebSepaExportFilter = new EbSepaExportFilter();
  bankVll: McValueLabelList = new McValueLabelList();
  clearedAllMultipleFilters = new Subject<boolean>();
  ebSepaExport!: EbSepaExport;
  mcGod = McGod.getInstance();
  notifyMessage = {message: '', type: ''};
  webFile = new WebFile();
  revertButtonsKvm = new Map<string, string>();
  showRevertDialogMcb = new McBoolean();
  revertDialogMessage!: string;
  showUploadFileMcb = new McBoolean();
  selectedSepaExportId = 0;
  items: EbSepaExport[] = [];
  pageNumber = 0;
  pageSize = 5;
  isLoading = false;
  totalItemsCount!: number;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();

  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.out-factoring.download'),
      actionCd: EbSepaExport.ACTION_DOWNLOAD,
      matIcon: 'download',
      permission: McGod.PERM_EB_FACTORING_OUT_SEPA_EXPORT
    },
    {
      title: this.tS.instant('mem.common.upload-file'),
      actionCd: EbSepaExport.ACTION_UPLOAD_FILE,
      matIcon: 'upload',
      permission: McGod.PERM_EB_FACTORING_OUT_SEPA_EXPORT
    },
    {
      title: this.tS.instant('cc.factoring.export-sepa.revert'),
      actionCd: EbSepaExport.ACTION_REVERT,
      matIcon: 'settings_backup_restore',
      permission: McGod.PERM_EB_FACTORING_OUT_SEPA_EXPORT
    },
    {
      title: this.tS.instant('cc.ebics.send-via-ebics'),
      actionCd: EbSepaExport.ACTION_SEND_VIA_EBICS,
      matIcon: 'send',
      permission: McGod.PERM_EB_FACTORING_OUT_SEPA_EXPORT
    }
  ];

  constructor(private ebSepaExportService: EbSepaExportService,
              private mcBankAccountService: McBankAccountService,
              private toastr: ToastrService,
              private  tS: TranslateService) {
    super();
  }

  ngOnInit() {
    this.mcBankAccountService.bankAccounts.map(item => {
      this.bankVll.add(item.id, item.bankName);
    });
    this.ebSepaExportList.setPager(0, 100);
    this.revertButtonsKvm.set(RevertDialogAction.ACT_REVERT, this.mcGod.t('cc.factoring.export-sepa.revert'));
    this.revertButtonsKvm.set(RevertDialogAction.ACT_CANCEL, this.mcGod.t('cc.common.edit.cancel'));
    this.initTableConfig();
    this.initFilterConfig();
    this.actLoad(this.pageNumber, this.pageSize, this.ebSepaExportFilter);
  }

  public actLoad(pageNumber: number, pageSize: number, ebSepaFilter: EbSepaExportFilter): void{
    this.pageNumber=pageNumber;
    this.pageSize= pageSize;
    this.isLoading = true;
    const sortCriteriaList = new SortCriteriaList();
    this.ebSepaExportService.getByFilter(ebSepaFilter, this.pageNumber,this.pageSize, sortCriteriaList).subscribe((res)=> {
      if(res){
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => object = EbSepaExport.createFromJson(object));
      }
    });
  }

  initTableConfig(){
    this.tableConfig.addColumnStandard(this.tS.instant('cc.out-factoring.exported-file'), 'sepaExportFilename', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.export-sepa.export-type'), 'getTypeCdLabel()', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.psd2.bank-name'),'bankName', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.export-sepa.export-date-&-time'), 'getCreatedAtString()', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.incoming-payments.request-collection-date'), 'getRequestCollectionDate()', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.imported-sepa.all-debtors'), 'allDebtors', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.imported-sepa.total-amount-due'), 'getTotalAmount()', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.status'), 'getStatusCdLabel()', 250, '');
  }

  initFilterConfig(){
    const typeValueLabelList = EbSepaExport.getTypeCdVll();
    const statusValueLabelList = EbSepaExport.getStatusCdVll();
    this.filterConfig.addItem(MkFilterItemType.SELECT, this.tS.instant('cc.factoring.export-sepa.export-type'),typeValueLabelList.items.map((item) => new MkFilterValue(item.label, item.value)));
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, this.tS.instant('cc.common.status'),statusValueLabelList.items.map((item) => new MkFilterValue(item.label, item.value)));
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, this.tS.instant('cc.psd2.bank-name'),this.bankVll.items.map((item) => new MkFilterValue(item.label, item.value)));
  }

  onFilterChanged(filterOutput: MkFilterOutput): void {
    this.ebSepaExportFilter.searchTerm = filterOutput.search ? filterOutput.search : '';
    this.ebSepaExportFilter.typeCds = filterOutput.selections[this.tS.instant('cc.factoring.export-sepa.export-type')] ? filterOutput.selections[this.tS.instant('cc.factoring.export-sepa.export-type')] : '';
    this.ebSepaExportFilter.statusCds = MkFilterOutput.convertFilterSelectionToArray(filterOutput.selections[this.tS.instant('cc.common.status')]? filterOutput.selections[this.tS.instant('cc.common.status')] : []) ;
    this.ebSepaExportFilter.bankAccountIds = MkFilterOutput.convertFilterSelectionToArray(filterOutput.selections[this.tS.instant('cc.psd2.bank-name')]? filterOutput.selections[McGod.t('cc.psd2.bank-name')]: []);
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, this.ebSepaExportFilter);
  }

  onTableAction($event: MkMatTableMenuAction){
    switch ($event.action.actionCd){
      case EbSepaExport.ACTION_REVERT:
          this.actRevertExportedSepa($event.item);
          break;
      case EbSepaExport.ACTION_UPLOAD_FILE:
        this.showUploadFileMcb.setTrue();
        this.selectedSepaExportId = $event.item.id;
        break;
      case EbSepaExport.ACTION_DOWNLOAD:
        this.actDownloadSepaFile($event.item.idWebfile);
        break;
      case EbSepaExport.ACTION_SEND_VIA_EBICS:
        this.ebSepaExportService.sendViaEbics($event.item.id).pipe(take(1)).subscribe(res => {
          this.actLoad(this.pageNumber, this.pageSize, this.ebSepaExportFilter);
        });
        break;
    }
  }

  actRevertExportedSepa(ebSepaExport: EbSepaExport) {
    this.revertDialogMessage = `${this.mcGod.t('cc.out-factoring.sepa.are-you-sure-you-want-to-revert-the-exported-sepa-named')} "${ebSepaExport.sepaExportFilename}" ${this.mcGod.t('and all of its transactions')}?`;
    this.showRevertDialogMcb.setTrue();
    this.ebSepaExport = ebSepaExport;
  }

  onMcDialogBoxRevertSepaActionButtonClicked(action: string) {
    this.revertDialogMessage = '';
    if (action === RevertDialogAction.ACT_REVERT) {
      if (this.ebSepaExport.statusCd === EbSepaExport.STATUS_CD_EXPORTED) {
        this.ebSepaExport.revertExportedSepa(() => {
          this.actLoad(this.pageNumber, this.pageSize, this.ebSepaExportFilter);
          this.toastr.success(`${this.mcGod.t('cc.out-factoring.sepa-file')} "${this.ebSepaExport.sepaExportFilename}" ${this.mcGod.t('cc.out-factoring.sepa.reverted-successfully')}.`);
          this.showRevertDialogMcb.setFalse();
        }, () => {
          this.toastr.error(this.ebSepaExport.apiErrorMessage);
          this.showRevertDialogMcb.setFalse();
        });
      } else {
        this.toastr.error('cc.factoring-revert-sepa-files-in-status');
      }
    } else {
      this.showRevertDialogMcb.setFalse();
    }
  }

  onUploadFile($event: any) {
    this.ebSepaExportService.uploadSepaExportFile(this.selectedSepaExportId, $event.file)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.toastr.success('Success');
        this.showUploadFileMcb.setFalse();
        this.actLoad(this.pageNumber, this.pageSize, this.ebSepaExportFilter);
      }, (error) => {
        this.toastr.error(error.error.message);
      });
  }
  onUploadCancel(){
    this.showUploadFileMcb.setFalse();
  }

  private actDownloadSepaFile(idWebfile: number) {
    this.webFile.typeCd = WebFile.TYPE_CD_EB_BANK_SEPA_EXPORT;
    this.webFile.getFileByIdAndType(idWebfile, WebFile.TYPE_CD_EB_BANK_SEPA_EXPORT, () => {
      this.webFile.downloadFile().subscribe(response => {
        fileSaver.saveAs(response, `${this.webFile.filename}`);
      });
    });
  }

}
