import {CsrReccuringOptions} from './csr-reccuring-options.model';
import {CsrReccuringOptionsListGENERATED} from '../_generated/csr-reccuring-options-list-generated.model';

export class CsrReccuringOptionsList extends CsrReccuringOptionsListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrReccuringOptionsList();
  }

  // ---------------------------------------------------------------------
}
