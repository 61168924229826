import { Component, Inject, OnInit } from '@angular/core';
import { ICON_CLOSE } from "@miticon-ui/mc-components";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {CsrBankAccount, CsrContract, CsrPaymentDetails, CsrRecurringDetails, McBankAccount, McGod} from "@miticon-ui/mc-core";

@Component({
  selector: 'lib-view-contract-dialog',
  templateUrl: './view-contract-dialog.component.html',
  styleUrls: ['./view-contract-dialog.component.scss']
})
export class ViewContractDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  reccuringDetails: CsrRecurringDetails;
  paymentDetails: CsrPaymentDetails;
  csrBankAccount: CsrBankAccount;
  mcBankAccount: McBankAccount;

  constructor(
    @Inject(MAT_DIALOG_DATA) public csrContract: CsrContract,
    public dialogRef: MatDialogRef<ViewContractDialogComponent>
  ) { }

  ngOnInit(): void {
    this.reccuringDetails = JSON.parse(this.csrContract.jsonbRecurringDetails);
    this.paymentDetails = JSON.parse(this.csrContract.jsonbPaymentDetails);
    this.csrBankAccount = this.csrContract._rawJson.csrBankAccount;
    if(this.csrContract._rawJson.mcBankAccount) {
      this.mcBankAccount = this.csrContract._rawJson.mcBankAccount;
    }
  }

  getMethod(): string {
    const paymentTypeKey = this.findTrueKey(this.reccuringDetails);

    switch (paymentTypeKey) {
      case 'typeCdWEEKLY':
        return CsrRecurringDetails.getTypeCdLabel(CsrRecurringDetails.TYPE_CD_WEEKLY);
      case 'typeCdMONTHLY':
        return CsrRecurringDetails.getTypeCdLabel(CsrRecurringDetails.TYPE_CD_MONTHLY);
      case 'typeCdQUARTERLY':
        return CsrRecurringDetails.getTypeCdLabel(CsrRecurringDetails.TYPE_CD_QUARTERLY);
      case 'typeCdHALF_YEARLY':
        return CsrRecurringDetails.getTypeCdLabel(CsrRecurringDetails.TYPE_CD_HALF_YEARLY);
      case 'typeCdYEARLY':
        return CsrRecurringDetails.getTypeCdLabel(CsrRecurringDetails.TYPE_CD_YEARLY);
      default:
        return '-';
    }
  }

  private findTrueKey(data: any): string | null {
    for (const key in data) {
      if (data[key] === true) {
        return key;
      }
    }
    return null;
  }

  getDay(): string {
    const paymentMethod = this.getMethod();

    switch (paymentMethod) {
      case McGod.t('Weekly'):
        return new CsrRecurringDetails().getWeekDaysVll().getLabel(this.reccuringDetails.day, '');
      case McGod.t('Monthly'):
      case McGod.t('Quarterly'):
      case McGod.t('Half-yearly'):
      case McGod.t('Yearly'):
        return new CsrRecurringDetails().getMonthDaysVll().getLabel(this.reccuringDetails.day, '');
      default:
        return '-';
    }
  }

  getMonth(): string {
    const paymentMethod = this.getMethod();

    switch (paymentMethod) {
      case McGod.t('Quarterly'):
        return new CsrRecurringDetails().getQuarterlyVll().getLabel(this.reccuringDetails.month, '');
      case McGod.t('Half-yearly'):
        return new CsrRecurringDetails().getHalfYearlyVll().getLabel(this.reccuringDetails.month, '');
      case McGod.t('Yearly'):
        return new CsrRecurringDetails().getYearlyVll().getLabel(this.reccuringDetails.month, '');
      default:
        return '-';
    }
  }
  getPaymentMethod(): string {
    const paymentTypeKey = this.findTrueKey(this.paymentDetails);

    switch (paymentTypeKey) {
      case 'typeCdDIRECT_DEBIT':
        return CsrContract.PAYMENT_METHOD_DIRECT_DEBIT;
      case 'typeCdTRANSFER':
        return CsrContract.PAYMENT_METHOD_MONEY_TRANSFER;
      default:
        return '-';
    }
  }
}
