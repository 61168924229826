import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {EbSepaFileTransaction} from '@miticon-ui/mc-core';
import {McForm,
  McBoolean,
  McTrigger,} from '@miticon-ui/mc-core';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';
import {AppSharedTransactionAddEditPartConfig} from './app-shared-transaction-add-edit-part-config.model';

@Component({
  selector: 'app-shared-transaction-add-edit-part',
  templateUrl: './app-shared-transaction-add-edit-part.component.html',
})
export class AppSharedTransactionAddEditPartComponent implements OnInit, OnDestroy {

  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  saveSubscription!: Subscription;
  deleteSubscription!: Subscription;
  cancelSubscription!: Subscription;
  errorMsgs: string[] = [];


  @Input() config!: AppSharedTransactionAddEditPartConfig;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;

  @Input() saveTrigger!: McTrigger;
  @Input() cancelTrigger!: McTrigger;
  @Input() deleteTrigger!: McTrigger;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();

  subscribeToTriggers() {
    // -- triggers --
    if (this.saveTrigger) {
      this.saveSubscription = this.saveTrigger.subscribe(() => {
        this.actSave();
      });
    }
    if (this.cancelTrigger) {
      this.cancelSubscription = this.cancelTrigger.subscribe(() => {
        this.actCancel();
      });
    }
    if (this.deleteTrigger) {
      this.deleteSubscription = this.deleteTrigger.subscribe(() => {
        this.actDeleteItem();
      });
    }
  }

  unsubscribeFromTriggers() {
    // -- triggers --
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.config.setComponent(this);

    if (!this.config.ebSepaFileTransaction) {
      this.config.ebSepaFileTransaction = new EbSepaFileTransaction();
    }

    this.actLoad();
    this.subscribeToTriggers();

  }

  ngOnDestroy(): void {
    this.unsubscribeFromTriggers();
  }

  public actLoad(): void {
    this.config.ebSepaFileTransaction = new EbSepaFileTransaction();
    if (this.config.ebSepaFileTransactionId > 0) {
      this.config.ebSepaFileTransaction.loadById(this.config.ebSepaFileTransactionId, () => {
      });
    }

  }

  actSave() {

    if (this.mcForm.isValid() && this.config.ebSepaFileTransaction) {
            this.config.ebSepaFileTransaction.idCsrConsumer = this.mcForm.getValue('ctrlIdCsrConsumer');
    this.config.ebSepaFileTransaction.idEbSepaFile = this.mcForm.getValue('ctrlIdEbSepaFile');
    this.config.ebSepaFileTransaction.idEbFactoring = this.mcForm.getValue('ctrlIdEbFactoring');
    this.config.ebSepaFileTransaction.idFactoringEntity = this.mcForm.getValue('ctrlIdFactoringEntity');
    this.config.ebSepaFileTransaction.statusCd = this.mcForm.getValue('ctrlStatusCd');
    this.config.ebSepaFileTransaction.typeCd = this.mcForm.getValue('ctrlTypeCd');
    this.config.ebSepaFileTransaction.sourceTypeCd = this.mcForm.getValue('ctrlSourceTypeCd');
    this.config.ebSepaFileTransaction.delayFlg = this.mcForm.getValue('ctrlDelayFlg');
    this.config.ebSepaFileTransaction.pmtidEndtoendid = this.mcForm.getValue('ctrlPmtidEndtoendid');
    this.config.ebSepaFileTransaction.instdamt = this.mcForm.getValue('ctrlInstdamt');
    this.config.ebSepaFileTransaction.instdamtCcy = this.mcForm.getValue('ctrlInstdamtCcy');
    this.config.ebSepaFileTransaction.drctdbttxMndtrltdinfMndtid = this.mcForm.getValue('ctrlDrctdbttxMndtrltdinfMndtid');
    this.config.ebSepaFileTransaction.drctdbttxMndtrltdinfDtofsgntr = this.mcForm.getValue('ctrlDrctdbttxMndtrltdinfDtofsgntr');
    this.config.ebSepaFileTransaction.dbtragtFininstnidOthrId = this.mcForm.getValue('ctrlDbtragtFininstnidOthrId');
    this.config.ebSepaFileTransaction.dbtrNm = this.mcForm.getValue('ctrlDbtrNm');
    this.config.ebSepaFileTransaction.dbtracctIdIban = this.mcForm.getValue('ctrlDbtracctIdIban');
    this.config.ebSepaFileTransaction.rmtinfUstrd = this.mcForm.getValue('ctrlRmtinfUstrd');
    this.config.ebSepaFileTransaction.description = this.mcForm.getValue('ctrlDescription');
    this.config.ebSepaFileTransaction.createdAt = this.mcForm.getValue('ctrlCreatedAt');
    this.config.ebSepaFileTransaction.updatedAt = this.mcForm.getValue('ctrlUpdatedAt');
    this.config.ebSepaFileTransaction.exportDrctdbttxMndtrltdinfMndtid = this.mcForm.getValue('ctrlExportDrctdbttxMndtrltdinfMndtid');
    this.config.ebSepaFileTransaction.extTransactionId = this.mcForm.getValue('ctrlExtTransactionId');
    this.config.ebSepaFileTransaction.netFeeAmount = this.mcForm.getValue('ctrlNetFeeAmount');
    this.config.ebSepaFileTransaction.netFeeVatAmount = this.mcForm.getValue('ctrlNetFeeVatAmount');
    this.config.ebSepaFileTransaction.paymentAmount = this.mcForm.getValue('ctrlPaymentAmount');
    this.config.ebSepaFileTransaction.waitingPeriod = this.mcForm.getValue('ctrlWaitingPeriod');
    this.config.ebSepaFileTransaction.reason = this.mcForm.getValue('ctrlReason');
    this.config.ebSepaFileTransaction.factoringAcceptedFlg = this.mcForm.getValue('ctrlFactoringAcceptedFlg');
    this.config.ebSepaFileTransaction.createdDatetime = this.mcForm.getValue('ctrlCreatedDatetime');
    this.config.ebSepaFileTransaction.dueDatetime = this.mcForm.getValue('ctrlDueDatetime');

        this.config.ebSepaFileTransaction.save(() => {
            this.eventSaved.emit();
        }, () => {
            this.saveButtonDisabledMcb.setFalse();
        });
     } else {
      this.saveButtonDisabledMcb.setFalse();
    }

  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.config.ebSepaFileTransaction) {

      this.config.ebSepaFileTransaction.delete(() => {
         this.eventDeleted.emit();
      });
    }
  }


  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }
}
