import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class McStateHelperService {

  /*Constructor*/
  constructor() { }

}
