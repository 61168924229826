import {McProcessRunItem} from './mc-process-run-item.model';
import {McProcessRunItemListGENERATED} from '../_generated/mc-process-run-item-list-generated.model';

export class McProcessRunItemList extends McProcessRunItemListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessRunItemList();
  }

  // ---------------------------------------------------------------------
}
