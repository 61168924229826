import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {McProcessFlow, McProcess} from '@miticon-ui/mc-core';
import {
  McForm,
  McBoolean,
  McTrigger,
  McValueLabelList
} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-process-process-flow-additional-flow-info-edit',
  templateUrl: './mc-process-process-flow-additional-flow-info-edit.component.html',
})
export class McProcessProcessFlowAdditionalFlowInfoEditComponent implements OnInit {

  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  errorMsgs: string[] = [];
  inheritableVlm = new Map<string, string>();
  sendFlowToInkassoTransactionsVll = new McValueLabelList();
  cbSendFlowToInkassoValidFlg = false;
  transactionStatusVll = new McValueLabelList();
  errorMsg = '';

  // -- Input --
  @Input() mcProcessFlowId!: number;
  @Input() mcProcessFlow!: McProcessFlow;
  @Input() mcProcess!: McProcess;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();


  // ---------------------------------------------------------------------
  ngOnInit() {
    this.transactionStatusVll = McProcessFlow.getNewStatusForTransactionsAfterLastDunningLevelVll();

    this.inheritableVlm.set('yes', 'Yes');
    this.inheritableVlm.set('no', 'No');
    this.sendFlowToInkassoTransactionsVll = McProcessFlow.getSendFlowToInkassoTransactionTypeCdVll();

    if (!this.mcProcessFlow) {
      this.mcProcessFlow = new McProcessFlow();
    }

    this.actLoad();

  }


  // ---------------------------------------------------------------------
  public actLoad(): void {
    if (!this.mcProcessFlow) {
      this.mcProcessFlow = new McProcessFlow();
      if (this.mcProcessFlowId > 0) {
        this.mcProcessFlow.loadById(this.mcProcessFlowId, () => {
          this.validatePrerequisites();
        });
      }
    } else {
      // new item
      this.validatePrerequisites();
    }

  }

  // ---------------------------------------------------------------------
  actSave() {

    if (this.mcForm.isValid()) {

      // this.mcProcessFlow.inheritableFlg = this.mcForm.getValue("ctrlInheritableFlg") === 'yes';
      this.mcProcessFlow.newStatusForTransactionsAfterLastDunningLevel = this.mcForm.getValue('selTransactionStatusAfterLastLevel');
      this.mcProcessFlow.sendFlowToInkassoFlg = this.mcForm.getValue('ctrlSendFlowToInkasso');

      if (this.mcProcess.isTypeCdDUNNING()) {
        this.mcProcessFlow.groupByConsumerFlg = this.mcForm.getValue('ctrlGroupByConsumer');
      }
      if (this.mcProcessFlow.sendFlowToInkassoFlg) {
        this.mcProcessFlow.sendFlowToInkassoTransactionTypeCd = this.mcForm.getValue('ctrlSendFlowTypeOfTransactions');
      }

      if (this.mcProcessFlow.sendFlowToInkassoFlg && this.mcProcessFlow.newStatusForTransactionsAfterLastDunningLevel === McProcessFlow.NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_REMAIN_IN_CURRENT_STATUS) {
        this.errorMsg = 'Status must be either "Sending to Inkasso" or "Should go to Inkasso". Please choose one of these two options.';
        this.saveButtonDisabledMcb.setFalse();
      } else {
        this.mcProcessFlow.save(() => {
          this.eventSaved.emit();
        }, () => {
          this.saveButtonDisabledMcb.setFalse();
        });
      }

    } else {
      this.saveButtonDisabledMcb.setFalse();
    }

  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.mcProcessFlow.exists()) {

      this.mcProcessFlow.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

// ---------------------------------------------------------------------
  validatePrerequisites() {
    this.errorMsgs = [];
    // populate validation here on required inputs
  }

  // ---------------------------------------------------------------------
  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }

  cbSendFlowToInkassoValueChanged(cbValue: boolean) {
    this.cbSendFlowToInkassoValidFlg = cbValue;
  }
}
