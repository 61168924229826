import { McDevBbmPageComponent } from './pages/mc-dev-bbm-page/mc-dev-bbm-page.component';
import { McDevIndexPageComponent } from './pages/mc-dev-index-page/mc-dev-index-page.component';
import { McDevTestFiPageComponent } from './pages/mc-dev-test-fi-page/mc-dev-test-fi-page.component';
import { McDevModalPartComponent } from './components/mc-dev-modal-part/mc-dev-modal-part.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McDevRoutingModule } from './mc-dev-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {McFilterModule, McTableModule} from '@miticon-ui/mc-components';

@NgModule({
  declarations: [
McDevBbmPageComponent,
McDevIndexPageComponent,
McDevTestFiPageComponent,
McDevModalPartComponent,
  ],
  imports: [
    CommonModule,
    McDevRoutingModule,
    NgbModule,
    McComponentsModule,
    McFilterModule,
    McTableModule,
  FormsModule,
  ReactiveFormsModule,
  ],
  exports: [
McDevBbmPageComponent,
McDevIndexPageComponent,
McDevTestFiPageComponent,
McDevModalPartComponent,
    McDevRoutingModule,
    FormsModule,

  ]
})
export class McDevModule { }
