import {Table2Config} from "@miticon-ui/mc-core";

export class McStarmoneyTableConfig extends Table2Config {

  constructor() {
    super();

    this.addColumn("starMoneyFilename", "StarMoney Filename", "starMoneyFilename", true);

    this.addColumn("importDate", "Import date", "getImportDateStr()", true);

    this.addColumn("withdrawnAndReturnedTransactions", "Withdrawn vs Returned transactions", "displayWithdrawnAndReturnedTransactions()", true);

    this.addColumn("amountWithdrawn", "Amount Withdrawn", "displayAmountWithdrawn()", true);

    this.addColumn("amountReturned", "Amount Returned", "displayAmountReturned()", true);

    this.addColumn("numberOfNewTransactions", "No. of New Transactions", "newTransactionsCount", true);

    this.addColumn("numberOfRepeatedTransactions", "No. of Repeated Transactions", "repeatedTransactionsCount", true);
  }
}

