import {Component, Input, OnInit} from '@angular/core';
import {EbFactoringService, McEntityService, McGod} from '@miticon-ui/mc-core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-select-entity-list-item',
  templateUrl: './select-entity-list-item.component.html'
})
export class SelectEntityListItemComponent implements OnInit{

  isToggle = false;
  name!: string;
  message = '';

  @Input() child!: any;
  children: any[] = [];

  constructor(private entityService: McEntityService) {
  }

  ngOnInit(): void {
    this.children.push(this.child);
    this.child.children.sort((a: any, b: any) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
    });
  }


  onToggle(): void {
    this.isToggle = !this.isToggle;
  }

  onSelectEntity() {
    this.child.selected = !this.child.selected;
    this.entityService.setSelectedEntity(this.child);
  }

  setCssArrowClass() {
    return this.child.children.length > 0 ? (this.isToggle ? 'fa-caret-down' : 'fa-caret-up text-primary') : ('ml-2');
  }

  setNameClass(className: any) {
    return this.child.selected ? className : null;
  }

}
