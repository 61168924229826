export const ICON_FAILED = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <rect id="rect-1" width="24" height="24" x="0" y="0"/>
    <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="userSpaceOnUse">
      <rect width="24" height="24" x="0" y="0" fill="black"/>
      <use fill="white" xlink:href="#rect-1"/>
    </mask>
  </defs>
  <g>
    <use fill="none" xlink:href="#rect-1"/>
    <g mask="url(#mask-2)">
      <g opacity=".87">
        <path fill="none" d="M0 0h24v24H0V0z"/>
      </g>
      <path fill="rgb(230,0,0)" fill-rule="evenodd" d="M2 12C2 6.47000122 6.4699707 2 12 2s10 4.47000122 10 10-4.4699707 10-10 10S2 17.52999878 2 12zm12.89001465 4.30000305c.39001465.3899994 1.02001953.3899994 1.41003418 0 .38000488-.38999939.38000488-1.02999878 0-1.41000366L13.41003418 12l2.89001465-2.88999939c.39001465-.38999939.39001465-1.02000427 0-1.41000366-.39001465-.3899994-1.02001953-.3899994-1.41003418 0L12 10.58999634l-2.88989258-2.8899994c-.39001465-.38999938-1.02001953-.38999938-1.41003418 0-.39001465.3899994-.39001465 1.02000428 0 1.41000367L10.5900879 12l-2.89001465 2.88999939c-.39001465.38999939-.39001465 1.02000427 0 1.41000366.39001465.3899994 1.02001953.3899994 1.41003418 0L12 13.41000366l2.89001465 2.8899994z"/>
    </g>
  </g>
</svg>`
