<div id="mcc-fi-{{name}}"
     class="mcc-fi mcc-fi-checkboxes mcc-fi-checkboxes-{{name}} {{extraCssClass}} {{labelPositionClass}}">
  <form [formGroup]="getCheckBoxesForm()" [ngClass]="{'validation-error' : this.getCheckBoxesForm().get('errorFlg')?.value}">
    <label *ngIf="label" [ngClass]="{'mc-mandatory-star' : validRequiredFlg}">{{label}}</label>
    <ul [ngClass]="directionHorizontal ? 'direction-row' : 'direction-column'">
        <li *ngFor="let option of optionsVlm | keyvalue">
          <input
            type="checkbox"
            id="fi-{{option.key}}"
            value="{{option.key}}"
            formControlName="value">
          <label for="fi-{{option.key}}"
                 [ngClass]="{'checkbox-button-error' : this.getCheckBoxesForm().get('errorFlg')?.value}">
            {{option.value}}
          </label>
        </li>
      </ul>
  </form>
  <div class="error-msg">{{this.getCheckBoxesForm().get('errorMsg')?.value}}</div>
</div>






