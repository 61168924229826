import {McEntityService} from "../services/mc-entity.service";
import {CsrConsumerService} from "../services/csr-consumer.service";
import {McEntityConsumerAttribute} from "./mc-entity-consumer-attribute.model";
import {CustomAttributeDto} from "./entity.model";


export class McCustomAttribute {

  entityAttributesList: any[] = [];
  mcEntityConsumerAttribute = new McEntityConsumerAttribute();

  standardEntityAttributes = ["extConsumerId", "firstName", "lastName", "companyName", "title", "gender", "birthday",
    "street", "addressSupplement", "postCode", "city", "isoCountry", "isoLanguage", "individual", "telephone", "cellphone", "fax",
    "email", "ba_iban", "ba_bic", "euVat", "ba_accountOwner", "active", "ba_sepaMandanteId", "ba_sepaMandanteDate", "aa_contractSigningDate", "aa_contractStartDate",
    "aa_contractEndDate", "aa_contractBaseAmount", "aa_contractCancellationDate", "aa_contractCancellationActiveOnDate", "aa_contractCancellationReason", "aa_contractModel",
    "aa_contractType", "aa_contractStatus", "countryName", "ba_bankName", "typeCd", "flgDunningEnabled"];

  customAttributesList: CustomAttributeDto[] = [];

  getCustomAttribute(service: McEntityService | CsrConsumerService) {
    service.getAttributes().subscribe(response => {
      let uniqueAttr = response.map((item: { name: any; }) => item.name)
        .filter((attr:any) => !this.entityAttributesList.includes(attr.substring(-2)))
        .map((attr:any) => 'aa_' + attr);

      this.entityAttributesList = this.standardEntityAttributes
          .concat(uniqueAttr);
      // console.log(this.entityAttributesList, "ENTITY-ATTRIBUTE------CLASS");
      this.customAttributesList = uniqueAttr;
      // console.log(this.customAttributesList, "CUSTOM-ATTRIBUTE-----CLASS");
    });
  }

  updateCustomAttributes(customAttribute: CustomAttributeDto, service: McEntityService) {
    return new Promise((resolve, reject) => {
      this.customAttributesList.push(customAttribute);
      service.editEntityAttributes(this.customAttributesList).subscribe(response => {
        this.mcEntityConsumerAttribute.callDevFixConsumerAttributes();
        console.log(response, "UPDATE SUCCESS");
        this.getCustomAttribute(service);
        resolve(response);
      }, error => reject(error));
    });

  }

  editCustomAttribute(customAttribute: CustomAttributeDto, index: any, service: McEntityService) {
    return new Promise((resolve, reject) => {
      this.customAttributesList[index] = customAttribute;
      service.editEntityAttributes(this.customAttributesList).subscribe(response => {
        console.log(response, "UPDATE SUCCESS");

        this.getCustomAttribute(service);
        // console.log(this.entityAttributesList, "@@@@@@@@");
        resolve(response);
      }, error => reject(error));
    });
  }

}
