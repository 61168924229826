import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {McEnvironment} from '../extra/mc-environment.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class McEnvironmentService {

  constructor(private httpClient: HttpClient, @Inject('environment_OnlyForMcEnvironmentService') private environmentTsObj: any
               ) {
  }

  private readonly mcEnviromentPath = 'assets/config/environment.json';

  // ---------------------------------------------------------------------
  public static createFromJson(mcEnvironmentLocalJson: any, environmentTsObj: any): McEnvironment {
    let item = new McEnvironment();
    item = Object.assign(item, environmentTsObj);
    item = Object.assign(item, mcEnvironmentLocalJson);
    item.prepare();
    item = Object.assign(item, mcEnvironmentLocalJson);
    return item;
  }

// ---------------------------------------------------------------------
  getMcEnvironment(onSuccessCallback?: () => void, onErrorCallback?: () => void): Observable<McEnvironment> {
    console.log('McEnvironmentService: Looking For Environment at ' + this.mcEnviromentPath);
    return this.httpClient.get<McEnvironment>(this.mcEnviromentPath, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    }).pipe(map((response: any) => {
       return McEnvironmentService.createFromJson(response, this.environmentTsObj);
    }));
  }

 /* getMcEnvironment1(onSuccessCallback?: () => void, onErrorCallback?: () => void): Observable<any> {
    return this.httpClient.get<McEnvironment>(this.mcEnviromentPath, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }
*/}
