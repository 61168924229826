<!--
<h3 class="page-title">{{"cc.common.consumers-overview" | translate}}</h3>

<lib-mc-loader [showLoader]="mcConsumerList.apiLoadingFlg"></lib-mc-loader>

<app-shared-filter-wrapper>
  <div class="filter-container">
    <div class="filter-inputs">

      &lt;!&ndash; Search&ndash;&gt;
      <mcc-fi-textbox [isClearEnabled]="false" label="{{'cc.common.search' | translate}}" name="txbSearchTerm"
                      (eventEnterClicked)="actLoad()"
                      [mcForm]="mcForm"
                      tooltipClass="mc-tooltip-length"
                      ngbTooltip="{{'cc.consumer.overview.search-by-consumer-name-consumer-id-contract-number-email-company-name' | translate}}"
                      placeholder="Search"
                      placement="bottom-left">
      </mcc-fi-textbox>

      &lt;!&ndash; Status&ndash;&gt;
      <mcc-fi-select [mcForm]="mcForm" name="selStatusCd" label="{{'cc.common.view.status' | translate}}"
                     placeholderOption="{{'cc.my-factoring.all' | translate}}"
                     [optionsVll]="statusCdsVll"></mcc-fi-select>

      &lt;!&ndash; Type&ndash;&gt;
      <mcc-fi-select [mcForm]="mcForm" name="selTypeCd" label="{{'cc.jobs.edit.type' | translate}}"
                     placeholderOption="{{'cc.my-factoring.all' | translate}}" [optionsVll]="typeCdsVll">
      </mcc-fi-select>
    </div>

    <div class="filter-actions">
      <button class="clear-all" (click)="actClearFilter()">{{'cc.common.clear-all-filters' | translate}}</button>
      <button class="mc-button filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>
    </div>
  </div>
</app-shared-filter-wrapper>

<div class="actions-container">
  <span>{{'cc.common.consumers-list' | translate}}</span>
  <div class="actions-wrapper">
    <button *ngIf="mcGod.userHasMcConsumerAddPermission()" class="system-action-btn mr-2" (click)="actShowAddEdit()">
      <i class="fas fa-plus-circle mc-add-new-user-icon"></i> {{ 'cc.consumers.view.create-new-consumer' | translate }}
    </button>
    <button *ngIf="mcGod.userHasMcConsumerImportPerformPermission()" class="system-action-btn" (click)="actShowImportConsumersStep1()">
      <i class="fas fa-plus-circle mc-add-new-user-icon"></i> {{'cc.consumers.consumers-overview.import-consumers' | translate}}
    </button>
  </div>
</div>

<mcc-message [errorMsg]="mcConsumerList.apiErrorMessage" [successMsg]="successMsg"></mcc-message>

<div class="col-md-12 p-0" *ngIf="mcConsumerList.apiSuccessFlg">
  <mcc-fi-table-3 [objectList]="mcConsumerList"
                  [table3Config]="mcConsumerTable3Config"
                  (eventPaginationOrSortChanged)="actLoad()"
                  (eventItemAction)="onTableItemAction($event)"></mcc-fi-table-3>
</div>



-->
<h3 class="page-title">{{"cc.common.consumers" | translate}}</h3>

<div class="actions-container">
  <span>{{"cc.common.consumers-list" | translate}}</span>
  <div class="actions-wrapper">
    <button  *ngIf="mcGod.userHasMcConsumerAddPermission()" class="system-action-btn mr-2" (click)="addConsumer()">
      <i class="fas fa-plus-circle mc-add-new-user-icon"></i> {{'cc.consumers.view.create-new-consumer' | translate}}
    </button>
    <button *ngIf="mcGod.userHasMcConsumerImportPerformPermission()" class="system-action-btn" (click)="actGoToDataImport()">
      <i class="fas fa-plus-circle mc-add-new-user-icon"></i> {{ 'cc.consumers.consumers-overview.import-consumers' | translate }}
    </button>
  </div>
</div>

<mk-mat-table *ngIf="this.showEntityNameColumn"
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  [itemsPerPage]="pageSize"
  (filterChanged)="onFilterChanged($event)"
  [mkMatMenuActionItems]="mkMatMenuActionItems"
  (actionMkMatMenuItems)="onActionMkMatMenuItems($event)"
  [searchTooltip]="searchTooltip"
>
</mk-mat-table>

<mcc-modal1-wrapper *ngIf="showAddEditModalMcb.value" [showMcb]="showAddEditModalMcb" (eventCanceled)="actCancel()">
  <mc-consumer-consumer-add-edit-part
    [csrConsumerId]="addEditConsumerId"
    [csrConsumer]="csrConsumer"
    (eventCanceled)="actCancel()"
    (eventSaved)="actOnAddEditSaved()"
  ></mc-consumer-consumer-add-edit-part>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper *ngIf="showImportConsumersStep1.value" [showMcb]="showImportConsumersStep1">
  <mc-consumer-import-consumers-step-1 (eventCancel)="actCloseImportConsumerStep1()" (eventUploadSuccess)="actImportConsumersGoToStep2($event)"></mc-consumer-import-consumers-step-1>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper *ngIf="showImportConsumersStep2.value" [showMcb]="showImportConsumersStep2">
  <mc-consumer-import-consumers-step-2
    (eventCancel)="actCloseImportConsumersStep2()"
    (eventImported)="importedConsumers($event)"
    (eventPrevStep)="actPrevToImportConsumersStep1()"
    [webFileId]="webFileId"
  ></mc-consumer-import-consumers-step-2>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper modalSizeCd="small" *ngIf="showProfileMcb.value" [showMcb]="showProfileMcb">
  <mc-consumer-consumer-profile-part (eventClosed)="actCancel()"></mc-consumer-consumer-profile-part>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper *ngIf="showHistoryMcb.value" [showMcb]="showHistoryMcb">
  <lib-mc-consumers-details-history [csrConsumer]="csrConsumer"></lib-mc-consumers-details-history>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper modalSizeCd="small" *ngIf="showAddTransactionsManuallyMcb.value" [showMcb]="showAddTransactionsManuallyMcb">
  <app-shared-transaction-add-manually-part
    [csrConsumer]="csrConsumer"
    (eventCanceled)="actAddTransactionManuallyCanceled()"
    (eventSaved)="actAddTransactionManuallySaved()"
  ></app-shared-transaction-add-manually-part>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper *ngIf="showActionHistoryMcb.value" [showMcb]="showActionHistoryMcb" (eventCanceled)="actActionHistoryCancel()">
  <mc-consumer-action-history-part [csrConsumer]="csrConsumer" (eventCanceled)="actActionHistoryCancel()"></mc-consumer-action-history-part>
</mcc-modal1-wrapper>

