export const ICON_LINK = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="2" fill="#53589B"/>
<g clip-path="url(#clip0_1855_15864)">
<path d="M16.1667 7.83337H13.6667C13.2083 7.83337 12.8333 8.20837 12.8333 8.66671C12.8333 9.12504 13.2083 9.50004 13.6667 9.50004H16.1667C17.5417 9.50004 18.6667 10.625 18.6667 12C18.6667 13.375 17.5417 14.5 16.1667 14.5H13.6667C13.2083 14.5 12.8333 14.875 12.8333 15.3334C12.8333 15.7917 13.2083 16.1667 13.6667 16.1667H16.1667C18.4667 16.1667 20.3333 14.3 20.3333 12C20.3333 9.70004 18.4667 7.83337 16.1667 7.83337ZM8.66666 12C8.66666 12.4584 9.04166 12.8334 9.49999 12.8334H14.5C14.9583 12.8334 15.3333 12.4584 15.3333 12C15.3333 11.5417 14.9583 11.1667 14.5 11.1667H9.49999C9.04166 11.1667 8.66666 11.5417 8.66666 12ZM10.3333 14.5H7.83332C6.45832 14.5 5.33332 13.375 5.33332 12C5.33332 10.625 6.45832 9.50004 7.83332 9.50004H10.3333C10.7917 9.50004 11.1667 9.12504 11.1667 8.66671C11.1667 8.20837 10.7917 7.83337 10.3333 7.83337H7.83332C5.53332 7.83337 3.66666 9.70004 3.66666 12C3.66666 14.3 5.53332 16.1667 7.83332 16.1667H10.3333C10.7917 16.1667 11.1667 15.7917 11.1667 15.3334C11.1667 14.875 10.7917 14.5 10.3333 14.5Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1855_15864">
<rect width="20" height="20" fill="white" transform="translate(2 2)"/>
</clipPath>
</defs>
</svg>`
