import {McBillingBatch} from './mc-billing-batch.model';
import {McBillingBatchListGENERATED} from '../_generated/mc-billing-batch-list-generated.model';

export class McBillingBatchList extends McBillingBatchListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingBatchList();
  }

  // ---------------------------------------------------------------------
}
