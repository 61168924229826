
import {Table3Config} from '../extra/table-3-config';

// import {McProcessFlowCriteriaGENERATED} from '../_generated/mc-process-flow-criteria-generated.model';

export class McProcessFlowCriteriaTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAttributeName', 'Attribute name', 'attributeName', 'attributeName');
    this.addColumn('colAttributeOperatorTypeCd', 'Attribute operator type code', 'attributeOperatorTypeCd', 'attributeOperatorTypeCd');
    this.addColumn('colAttributeOptionTypeCd', 'Attribute option type code', 'attributeOptionTypeCd', 'attributeOptionTypeCd');
    this.addColumn('colAttributeTypeCd', 'Attribute type code', 'attributeTypeCd', 'attributeTypeCd');
    this.addColumn('colCriteriaOperatorTypeCd', 'Criteria operator type code', 'criteriaOperatorTypeCd', 'criteriaOperatorTypeCd');
    this.addColumn('colIdMcProcessFlow', 'McProcessFlow ID', 'idMcProcessFlow', 'idMcProcessFlow');
    this.addColumn('colSeqNo', 'Sequence number for appending multiple criteria', 'seqNo', 'seqNo');
    this.addColumn('colValue', 'Value', 'value', 'value');

*/
  }
}
