<ng-container *ngIf="isVisible()">
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-wrapper">
    <lib-mc-loader [showLoader]="ebFactoring.apiLoadingFlg"></lib-mc-loader>

    <div>

      <!--THIS CONTAINER IS MANDATORY-->
      <div class="content-header">
        <h3 *ngIf="ebFactoring.exists()">{{'cc.my-factoring.reject-factoring' | translate}} {{ebFactoring.factoringContractName}}</h3>
        <mcc-message [errorMsg]="ebFactoring.apiErrorMessage"></mcc-message>
      </div>

      <!--THIS CONTAINER IS MANDATORY-->
      <div class="content-body">
        <span>{{'cc.factoring.you-are-about-to-reject' | translate}} "{{ebFactoring.factoringContractName}}". {{'cc.my-factoring.leave-reason-for-rejecting-the-contract' | translate}}.</span>
        <mcc-fi-textbox [mcForm]="mcForm" [name]="'txbRejectReason'" [validRequiredFlg]="true"
                        [validWhitespaces]="true" label="{{'cc.factoring.rejection-reason' | translate}}"></mcc-fi-textbox>
      </div>

      <div class="content-footer">
        <button type="button" class="btn btn-secondary move-right" (click)="actCancel()">{{'cc.common.edit.cancel' | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="actSaveItem()">{{'cc.factoring.reject' | translate}}</button>
      </div>
    </div>
  </div>
</ng-container>
