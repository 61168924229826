import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {NgbModal, NgbDateAdapter, NgbDateNativeAdapter} from '@ng-bootstrap/ng-bootstrap';
import {McConsumer, McConsumersService, McEntityService} from '@miticon-ui/mc-core';


@Component({
  selector: 'lib-mc-consumers-add',
  templateUrl: './mc-consumers-add.component.html',
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class McConsumersAddComponent implements OnInit {

  responseMessage!: boolean;
  message!: string;
  type!: string;
  showLoader!: boolean;
  isUserFormValid!: boolean;
  selectedEntityPath!: string[];
  saveUserBtnType!: number;
  modalArea = 'addConsumers';
  customAttributesFromDb: any[] = [];
  addConsumerForm!: FormGroup;

  @Output() updateConsumerEvent = new EventEmitter<any>();

  constructor(private modalService: NgbModal,
              private fb: FormBuilder,
              private entityService: McEntityService,
              private consumerService: McConsumersService) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.addConsumerForm = this.fb.group({
      step1: '',
      step2: '',
      step3: ''
    });
  }

  // Modal
  openConsumerModal(addNewConsumer: any) {
    this.entityService.getEntityAttributes().subscribe(response => {
      this.customAttributesFromDb = response.map((item: any, index: number) => {
        item.id = index;
        item.value = null;
        return item;
      });
    }, error => {
      console.log(error);
    });
    this.modalService.open(addNewConsumer, {
      backdrop: 'static',
      centered: true,
      windowClass: 'mc-add-consumer-modal',
      beforeDismiss: () => {
        this.customAttributesFromDb = [];
        return true;
      }
    });
  }

  userDataFormValid(data: boolean) {
    this.isUserFormValid = data;
  }

  saveBtnType(saveUserBtnType: number) {
    this.saveUserBtnType = saveUserBtnType;
  }

  addConsumer() {
    this.showLoader = true;
    const bodyData: any = [];
    const parentFormData = this.addConsumerForm.value;
    const body = {
      active: parentFormData.step1.active === 'active',
      companyName: parentFormData.step1.individual === 'company' ? parentFormData.step1.companyName : null,
      firstName: parentFormData.step1.consumerFirstName,
      lastName: parentFormData.step1.consumerLastName,
      title: parentFormData.step1.consumerTitle,
      birthday: parentFormData.step1.consumerBDay ? new Date(parentFormData.step1.consumerBDay) : null,
      gender: parentFormData.step1.gender || null,
      telephone: parentFormData.step1.consumerTelephone || null,
      cellphone: parentFormData.step1.consumerCellphone || null,
      fax: parentFormData.step1.consumerFax || null,
      email: parentFormData.step1.consumerEmail || null,
      street: parentFormData.step1.consumerStreet || null,
      addressSupplement: parentFormData.step1.consumerAddressSupplement || null,
      postCode: parentFormData.step1.consumerPostalCode || null,
      city: parentFormData.step1.consumerCity || null,
      isoCountry: parentFormData.step1.consumerISOCountry || null,
      isoLanguage: parentFormData.step1.consumerISOLanguage || null,
      accountOwner: parentFormData.step1.consumerAccountOwner || null,
      individual: parentFormData.step1.individual === 'person',
      externalId: parentFormData.step1.consumerExternalId - 0 || null,
      iban: parentFormData.step1.consumerIBAN || null,
      bic: parentFormData.step1.consumerBIC || null,
      vatNumber: parentFormData.step1.vat || null,
      customAttributes: this.customAttributesFromDb.map(item => {
          return {
            name: item.name,
            value: item.value
          };
        })
    };

    bodyData.push(body);

    this.consumerService.addNewConsumer(bodyData).subscribe(
      (response) => {
        if (this.saveUserBtnType === 1) {
          const msg = 'success';
          const msgType = 'success';
          this.responseMessage = true;
          this.setNotificationMessage(msg, msgType);
          this.modalService.dismissAll('Cross click');
        } else {
          const msg = 'success';
          const msgType = 'success';
          this.responseMessage = true;
          this.setNotificationMessage(msg, msgType);
          this.modalService.dismissAll('Cross click');
          document.getElementById('newConsumerModalButton')?.click();
        }
        this.updateConsumerEvent.emit(true);
        this.showLoader = false;
      },
      (error) => {
        let message = '';
        let msgType = '';
        if (error.status > 500) {
          message = 'Server error';
          msgType = 'error';
        } else if (error.error.errors === 'Consumer with this contract number already exists.') {
          message = error.error.errors;
          msgType = error;
        } else if (error.error) {
          if (error.error.errors.length > 1) {
            message = error.error.errors.join();
            msgType = 'error';
          } else {
            message = error.error.errors;
            msgType = 'error';
          }
        } else {
          message = 'Invalid request';
          msgType = 'error';
        }
        this.responseMessage = true;
        this.setNotificationMessage(message, msgType);
        setTimeout(() => {
          this.responseMessage = false;
          this.setNotificationMessage('', '');
        }, 5000);
        this.showLoader = false;
      }
    );
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }


}

