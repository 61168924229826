import {McCurrencyFilterGENERATED} from '../_generated/mc-currency-filter-generated.model';

export class McCurrencyFilter extends McCurrencyFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McCurrencyFilter();
  }

  // ---------------------------------------------------------------------
}
