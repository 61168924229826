import {Inject, Injectable} from '@angular/core';
import {EbSepaFileReportServiceGENERATED} from '../_generated/eb-sepa-file-report-generated.service';



@Injectable({
  providedIn: 'root'
})
export class EbSepaFileReportService extends EbSepaFileReportServiceGENERATED {

}
