<div class="content-wrapper">
  <div class="content-header-slim">
    <h3>{{ 'pp.invoice.generate-invoices' | translate }}</h3>
  </div>

  <div class="filter-container">
    <div class="filter-inputs">
      <mcc-message *ngIf="apiErrorFlg" [errorMsg]="errorMsg"></mcc-message>

      <mcc-fi-select
        [mcForm]="mcForm"
        name="selInvoiceType"
        placeholderOption="{{ 'pp.invoice.choose-invoice-type' | translate }}"
        label="Invoice type"
        [optionsVll]="invoiceTypeVll"
        (eventValueChanged)="onSelectedInvoiceType($event)"
        [validRequiredFlg]="true"
      >
      </mcc-fi-select>

      <mcc-multiselect-filter
        [optionVll]="entitiesVll"
        label="{{ 'cc.common.admin.entities' | translate }}"
        name="selEntity"
        (eventSelectedItems)="onSelectedEntity($event)"
        [clearedAllFilters]="clearEntitiesFilter"
      >
      </mcc-multiselect-filter>

      <mcc-fi-date-picker
        datePickerViewType="RANGE_SELECTION"
        [mcForm]="mcForm"
        name="ctrlServicePeriod"
        label="Service period date range"
        (eventFromDate)="onServicePeriodDateFrom($event)"
        (eventToDate)="onServicePeriodDateTo($event)"
        [allowManualEntryFlg]="true"
        monthsDisplayed="2"
      >
      </mcc-fi-date-picker>

      <mcc-fi-date-picker
        [mcForm]="mcForm"
        name="ctrlSendingDate"
        label="{{'pp.invoice.sending-date' | translate}}"
        validRequiredFlg="true"
      >
      </mcc-fi-date-picker>

    </div>
  </div>

  <div *ngIf="badInputsFlg">
    <mcc-message [errorMsg]="badInputsErrorMsg"></mcc-message>
  </div>

  <div class="content-footer">
    <button class="btn btn-secondary move-right" (click)="actCancel()">
      <i class="far fa-times-circle"></i> {{ generatedFlg ? ('cc.common.close' | translate) : ('cc.common.edit.cancel' | translate) }}
    </button>
    <button class="btn btn-primary" (click)="actGenerate()" [disabled]="badInputsFlg"><i class="fas fa-file-export"></i> {{ 'cc.entity.generate' | translate }}</button>
  </div>
</div>
