<div class="mt-2">

  <!--Arrow icon-->
  <i class="fas pr-2 mc-cursor-pointer" [ngClass]="setCssArrowClass()" (click)="onToggle()"></i>

  <!--Entity name-->
  <div [ngClass]="setNameClass('mc-selected-entity')" class="mc-entity-name" (click)="onSelectEntity()">
    <p class="m-0 mc-cursor-pointer" [ngClass]="setCssClass('mc-root-entity-name')">
      {{entity.name}}
    </p>
  </div>
  <hr class="m-0" *ngIf="entity.children.length > 0">

  <!--Loop trough children-->
  <div *ngIf="!isToggle">
    <app-select-entity-list-item *ngFor="let child of entity.children" [child]="child"></app-select-entity-list-item>
  </div>
</div>
