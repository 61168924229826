/*BbmTestObj*/
import {ZzPocConsumerGENERATED} from '../_generated/zz-poc-consumer-generated.model';
import {McHtml} from '../_core/mc-html';

export class ZzPocConsumer extends ZzPocConsumerGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new ZzPocConsumer();
  }

  public fldTestHtml() {
    const html = new McHtml()
    // html.addButtonB("testMcHtml1", "TEST_MC_HTML1", this, []);
    // html.addButtonB("testMcHtml2", "TEST_MC_HTML2", this, []);
    // html.addLinkB('testMcLink1', '/admin/entity/1/management/consumers/edit/900', this, []);

    return html.items;
  }
  // ---------------------------------------------------------------------
}
