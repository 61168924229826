import {McEntityFilterGENERATED} from '../_generated/mc-entity-filter-generated.model';

export class McEntityFilter extends McEntityFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McEntityFilter();
  }

  // ---------------------------------------------------------------------
}
