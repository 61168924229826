
import {Table3Config} from '../extra/table-3-config';

// import {PmTransactionGENERATED} from '../_generated/pm-transaction-generated.model';

export class PmTransactionTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAmount', 'Amount', 'amount', 'amount');
    this.addColumn('colCollectionTypeCd', 'Collection type code', 'collectionTypeCd', 'collectionTypeCd');
    this.addColumn('colCsrConsumer', 'CsrConsumer', 'csrConsumer', 'csrConsumer');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colDetails', 'Transaction details', 'details', 'details');
    this.addColumn('colDueDate', 'Due Date', 'dueDate', 'dueDate');
    this.addColumn('colDunningStatusCd', 'Dunning type code', 'dunningStatusCd', 'dunningStatusCd');
    this.addColumn('colFlgDelay', 'Delay flag', 'flgDelay', 'flgDelay');
    this.addColumn('colFlgPaused', 'Is transaction paused', 'flgPaused', 'flgPaused');
    this.addColumn('colIdCsrConsumer', 'Consumer Id', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colIdExtTransaction', 'External transaction ID', 'idExtTransaction', 'idExtTransaction');
    this.addColumn('colIdMcEntity', 'Entity Id', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdMcFactoring', 'Factoring', 'idMcFactoring', 'idMcFactoring');
    this.addColumn('colIdRef', 'Ref Id', 'idRef', 'idRef');
    this.addColumn('colLastPauseUnpauseByUserId', 'Last pause or unpause by user', 'lastPauseUnpauseByUserId', 'lastPauseUnpauseByUserId');
    this.addColumn('colMaxPauseDays', 'Maximum pause days from entity', 'maxPauseDays', 'maxPauseDays');
    this.addColumn('colMcEntity', 'McEntity', 'mcEntity', 'mcEntity');
    this.addColumn('colMcFactoring', 'McFactoring', 'mcFactoring', 'mcFactoring');
    this.addColumn('colPauseDaysCountWithoutCurrentPauseDays', 'Paused days count without current pause days', 'pauseDaysCountWithoutCurrentPauseDays', 'pauseDaysCountWithoutCurrentPauseDays');
    this.addColumn('colPauseEndDate', 'Pause end date', 'pauseEndDate', 'pauseEndDate');
    this.addColumn('colPauseStartDate', 'Pause start date', 'pauseStartDate', 'pauseStartDate');
    this.addColumn('colPauseUnpauseReason', 'Last pause or unpause reason', 'pauseUnpauseReason', 'pauseUnpauseReason');
    this.addColumn('colReason', 'Reason', 'reason', 'reason');
    this.addColumn('colRefTypeCd', 'Ref type', 'refTypeCd', 'refTypeCd');
    this.addColumn('colReminderLastInterestCalculatedDatetime', 'Reminder Last Interest Calculated DateTime', 'reminderLastInterestCalculatedDatetime', 'reminderLastInterestCalculatedDatetime');
    this.addColumn('colSentToInkassoDatetime', 'Sent to inkasso datetime', 'sentToInkassoDatetime', 'sentToInkassoDatetime');
    this.addColumn('colSourceTypeCd', 'Source Type', 'sourceTypeCd', 'sourceTypeCd');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');
    this.addColumn('colVersion', 'Version', 'version', 'version');
    this.addColumn('colWaitingPeriod', 'Waiting period', 'waitingPeriod', 'waitingPeriod');

*/
  }
}
