import {Inject, Injectable} from '@angular/core';
import {McProcessFlowCriteriaAttributeServiceGENERATED} from '../_generated/mc-process-flow-criteria-attribute-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McProcessFlowCriteriaAttributeService extends McProcessFlowCriteriaAttributeServiceGENERATED {

}
