import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BaseDirective,
  EbFactoringService,
  EbPaymentReport, EbPaymentReportService,
  McBoolean,
  McEntity2List,
  McGod,
  McValueLabelList,
  PmTransaction,
  PmTransactionFilter,
  PmTransactionList,
} from '@miticon-ui/mc-core';
import {take, takeUntil} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig} from "@miticon-ui/mc-components";
import {AppSharedMatBottomSheetComponent} from "../../../../modules/app-shared/components/app-shared-mat-bottom-sheet/app-shared-mat-bottom-sheet.component"
import {MatBottomSheet} from "@angular/material/bottom-sheet";

@Component({
  selector: 'mc-bank-module-incoming-payments-potential-transaction-matches',
  templateUrl: './mc-bank-module-incoming-payments-potential-transaction-matches.component.html',
  styleUrls: ['./mc-bank-module-incoming-payments-potential-transaction-matches.component.scss'],
})
export class McBankModuleIncomingPaymentsPotentialTransactionMatchesComponent extends BaseDirective implements OnInit, OnDestroy {
  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter();

  isLoading = false;
  ebPaymentReport = new EbPaymentReport();
  statusVll: McValueLabelList = PmTransaction.getPotentialMatchesStatusCdVll();
  paymentReportId!: number;
  details: any;
  showConsumerProfileMcb = new McBoolean();
  consumerId = 0;
  pmTransaction = new PmTransaction();
  checkedTransactionList = new PmTransactionList();
  entitiesWithTransactions = new McEntity2List();
  pmTransactionFilter = new PmTransactionFilter();
  pmTransactionList = new PmTransactionList();
  actionPmTransaction!: any;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  searchTooltip = McGod.t('cc.my-factoring.search-by-consumer-name-description-contract-number');
  mkMatMenuActionItems: MkMatMenuItem[] = [{
    title: McGod.t('cc.incoming-payments.match'),
    actionCd: PmTransaction.ACTION_MATCH,
    matIcon: 'gps_fixed',
    permission: McGod.PERM_EB_FACTORING_OUT_VIEW
  }];

  constructor(private ebFactoringService: EbFactoringService, private ebPaymentReportService: EbPaymentReportService,
              private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private matBottomSheet: MatBottomSheet) {
    super();
  }

  ngOnInit() {
    this.pmTransactionList.setPager(0, 100);
    this.entitiesWithTransactions.loadAllWhereFactoringTransactionParentIsLoggedInEntity(() => {
      this.entitiesWithTransactions.items.sort((a, b) => a.name.localeCompare(b.name));
    });
    this.route.params.subscribe((params) => {
      this.paymentReportId = Number(params['id']);
      this.loadPaymentReport();
    });
    this.initTableConfig();
  }

  loadPaymentReport() {
    this.ebPaymentReport.loadById(this.paymentReportId, () => {
      this.details = this.ebPaymentReport.extractedDetails;
      this.pmTransactionFilter.consumerFullName = this.ebPaymentReport.getDebtorName();
      this.pmTransactionFilter.iban = this.ebPaymentReport.getIban();
      this.pmTransactionFilter.description = this.ebPaymentReport.description;
      this.pmTransactionFilter.outTransactionFlg = true;
      this.filterTransactionsBaseOnPaymentReport();
      this.pmTransactionList.getPotentialMatches(this.pmTransactionFilter,() => {
        this.pmTransactionList.items.map((item) => (item.navigation = `/entity/${McGod.getLoggedEntityIdFromToken()}/transactions/${item.id}`));
        this.initFilterConfig();
      });
    });
  }

  actLoad(pageNumber: number, pageSize: number, filters: PmTransactionFilter) {
    this.isLoading = true;
    this.pmTransactionList.pageNum = pageNumber;
    this.pmTransactionList.pageItemsPerPageCount = pageSize;
    this.filterTransactionsBaseOnPaymentReport();
    this.pmTransactionList.getPotentialMatches(filters, () => {
      this.isLoading = false;
    });
  }

  initTableConfig() {
    this.tableConfig.addColumnId([`/entity/${McGod.getLoggedEntityIdFromToken()}/transactions`], 'id');
    this.tableConfig.addColumnStandard(McGod.t('cc.consumer.studio'), 'getStudioName()', 250, 'idMcEntity');
    this.tableConfig.addColumnButtonAction(McGod.t('cc.my-factoring.consumer'), 'getConsumerName()', 'fas fa-user tr-icon', PmTransaction.ACTION_VIEW_CONSUMER_PROFILE);
    this.tableConfig.addColumnStandard(McGod.t('cc.common.view.description'), 'description', 250, 'description');
    this.tableConfig.addColumnStandard(McGod.t('cc.factoring.imported-sepa.import-date-&-time'), 'getCreatedDatetimeStr()', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.transactions.payment-due-date'), 'getDueDateStr()', 250, 'dueDate');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.view.amount-due'), 'getAmountDue()', 250, 'amount');
    this.tableConfig.addColumnInnerHtml(McGod.t('cc.common.view.status'), 'getIconLabel()', '');
  }

  initFilterConfig() {
    const entityFilterValues = this.entitiesWithTransactions.items.map((entity) => new MkFilterValue(entity.name, entity.id));
    const filteredEntities = [...new Map(entityFilterValues.map((item) => [item.value, item])).values()];
    const statusFilter = this.statusVll.items.map((item) => new MkFilterValue(item.label, item.value));

    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(PmTransaction.FILTER_ENTITIES), filteredEntities);
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(PmTransaction.FILTER_STATUS), statusFilter);
  }

  onFilterChanged(filters: MkFilterOutput) {
    const transactionFilter = new PmTransactionFilter();
    this.pmTransactionList.setSortB(filters.sort.sortProperty, filters.sort.sortType);

    transactionFilter.searchTerm = filters.search;
    if (filters.selections[McGod.t(PmTransaction.FILTER_STATUS)] && filters.selections[McGod.t(PmTransaction.FILTER_STATUS)].length !== 0) {
      transactionFilter.statusCds = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(PmTransaction.FILTER_STATUS)]);
    } else {
      if (this.ebPaymentReport.type === EbPaymentReport.TYPE_STORNO) {
        transactionFilter.statusCds = [PmTransaction.STATUS_CD_ACCEPTED, PmTransaction.STATUS_CD_PAID, PmTransaction.STATUS_CD_RETURNED,
          PmTransaction.STATUS_CD_EXPORTED, PmTransaction.STATUS_CD_SHOULD_GO_TO_INKASSO, PmTransaction.STATUS_CD_SENDING_TO_INKASSO,
          PmTransaction.STATUS_CD_RETURNED_FROM_INKASSO, PmTransaction.STATUS_CD_FOR_DUNNING];
      } else if (this.ebPaymentReport.extractedDetails.creditDebitIndicator === EbPaymentReport.CREDIT_INDICATOR_TYPE_DEBIT) {
        transactionFilter.statusCds = [PmTransaction.STATUS_CD_PAID];
      } else {
        transactionFilter.statusCds = [PmTransaction.STATUS_CD_RETURNED,PmTransaction.STATUS_CD_SENDING_TO_INKASSO,
          PmTransaction.STATUS_CD_SHOULD_GO_TO_INKASSO,
          PmTransaction.STATUS_CD_RETURNED_FROM_INKASSO,PmTransaction.STATUS_CD_ACCEPTED, PmTransaction.STATUS_CD_FOR_DUNNING];
      }
    }
    transactionFilter.childEntityIds = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(PmTransaction.FILTER_ENTITIES)]);
    transactionFilter.outTransactionFlg = true;

    let filterCleared = !filters.search &&
      (!filters.selections[McGod.t(PmTransaction.FILTER_STATUS)] || filters.selections[McGod.t(PmTransaction.FILTER_STATUS)]?.length === 0) &&
      (!filters.selections[McGod.t(PmTransaction.FILTER_ENTITIES)] || filters.selections[McGod.t(PmTransaction.FILTER_ENTITIES)]?.length === 0);

    if(filterCleared){
      transactionFilter.consumerFullName = this.ebPaymentReport.getDebtorName();
      transactionFilter.iban = this.ebPaymentReport.getIban();
      transactionFilter.description = this.ebPaymentReport.description;
    }

    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize, transactionFilter);
  }

  onMenuItemAction(mkMatTableMenuAction: MkMatTableMenuAction) {
    if (mkMatTableMenuAction.action.actionCd === PmTransaction.ACTION_MATCH) {
      const selectedTransactionIds = [];
      selectedTransactionIds.push(mkMatTableMenuAction.item.id);
      this.matchTransactions(selectedTransactionIds);
    }
  }

  onColumnButtonAction(event: any) {
    if (event.actionCd === PmTransaction.ACTION_VIEW_CONSUMER_PROFILE) {
      this.actionPmTransaction = event.element;
      this.consumerId = event.element.csrConsumer.id;
      this.showConsumerProfileMcb.setTrue();
    }
  }

  onItemsSelected(items: PmTransaction[]) {
    if (items.length === 0) {
      this.matBottomSheet.dismiss();
    }
    this.checkedTransactionList = new PmTransactionList();
    this.checkedTransactionList.items = items;
    const bulkContainerLength = document.getElementsByClassName('bulk-container').length;
    if (items.length > 0 && !bulkContainerLength) {
      const bulkDialog = this.matBottomSheet.open(AppSharedMatBottomSheetComponent, {
        closeOnNavigation: true,
        hasBackdrop: false,
        data: [
          {
            icon: 'gps_fixed',
            title: McGod.t('cc.incoming-payments.match'),
            action: PmTransaction.ACTION_MATCH,
          }
        ],
      });

      bulkDialog.afterDismissed().subscribe((bulkAction) => {
        switch (bulkAction) {
          case PmTransaction.ACTION_MATCH:
            const selectedTransactionIds: number[] = this.checkedTransactionList.items.map(item => item.id);
            this.matchTransactions(selectedTransactionIds);
            break;
        }
      });
    }
  }

  filterTransactionsBaseOnPaymentReport() {
      if (this.ebPaymentReport.type === EbPaymentReport.TYPE_STORNO) {
        this.pmTransactionFilter.statusCds = [PmTransaction.STATUS_CD_ACCEPTED, PmTransaction.STATUS_CD_PAID, PmTransaction.STATUS_CD_RETURNED,
          PmTransaction.STATUS_CD_EXPORTED, PmTransaction.STATUS_CD_SHOULD_GO_TO_INKASSO, PmTransaction.STATUS_CD_SENDING_TO_INKASSO,
          PmTransaction.STATUS_CD_RETURNED_FROM_INKASSO, PmTransaction.STATUS_CD_FOR_DUNNING,
          PmTransaction.STATUS_CD_REJECTED];
      } else if (this.ebPaymentReport.extractedDetails.creditDebitIndicator === EbPaymentReport.CREDIT_INDICATOR_TYPE_DEBIT) {
        this.statusVll.items = this.statusVll.items.filter(status => status.value.includes(PmTransaction.STATUS_CD_PAID));
        this.pmTransactionFilter.statusCds = [PmTransaction.STATUS_CD_PAID];
      } else {
        this.statusVll.items = this.statusVll.items.filter(status => !status.value.includes(PmTransaction.STATUS_CD_PAID));
        this.pmTransactionFilter.statusCds = [PmTransaction.STATUS_CD_RETURNED,PmTransaction.STATUS_CD_SENDING_TO_INKASSO,
                                              PmTransaction.STATUS_CD_SHOULD_GO_TO_INKASSO,
                                              PmTransaction.STATUS_CD_RETURNED_FROM_INKASSO,PmTransaction.STATUS_CD_ACCEPTED,
                                              PmTransaction.STATUS_CD_REJECTED];
      }
  }

  matchTransactions(selectedTransactionIds: number[]) {
    this.ebPaymentReportService.match(this.ebPaymentReport.id, selectedTransactionIds).pipe(take(1)).subscribe( () => {
      this.toastr.success(`${McGod.t('cc.incoming-payments.transactions-matched-successfully')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
      this.actLoad(0, 100, this.pmTransactionFilter);
    }, error => {
      this.toastr.error(error.error.message, '', {timeOut: 4000, extendedTimeOut: 4000});
    });
  }

  markAsInvalid() {
    this.ebPaymentReport.apiService
      .markAsInvalid(this.ebPaymentReport.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          if (res.status !== EbPaymentReport.STATUS_UNMATCHED) {
            this.toastr.success(McGod.t('cc.incoming-payments.incoming-record-has-been-marked-as-invalid'));
          } else {
            this.toastr.success(McGod.t('cc.incoming-payments.incoming-record-status-has-been-returned-to-unmatched'));
          }
          this.loadPaymentReport();
        },
        (err) => {
          this.toastr.error(err.error.message);
        }
      );
  }

  ngOnDestroy() {
    this.matBottomSheet.dismiss();
  }
}
