/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McBillingServiceUnitTypeFilter} from '../models/mc-billing-service-unit-type-filter.model';

export class McBillingServiceUnitTypeFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingServiceUnitTypeFilter {
    console.error('MTCN-ERROR: Not Implemented: McBillingServiceUnitTypeFilter::createNewInstance(). Add this method to final class and return new McBillingServiceUnitTypeFilter();');
      throw new Error('Cannot McBillingServiceUnitTypeFilter::createNewInstance(). Add this method to final class and return new McBillingServiceUnitTypeFilter();');
  }

}
