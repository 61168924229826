import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  McProcessFlow,
  McProcess,
  McProcessFlowCriteriaAttribute,
  McProcessFlowCriteria,
  McProcessFlowCriteriaList, McProcessFlowCriteriaAttributeList, EbFactoringService, CsrRecurringDetails
} from '@miticon-ui/mc-core';
import {
  McForm,
  McBoolean,
  McValueLabelList
} from '@miticon-ui/mc-core';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'mc-process-process-flow-add-edit',
  templateUrl: './mc-process-process-flow-add-edit.component.html',
})
export class McProcessProcessFlowAddEditComponent implements OnInit {

  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  errorMsgs: string[] = [];
  statusVlm = new Map<string, string>();
  inheritableVll = new McValueLabelList();
  personInterestRateVll = new McValueLabelList();
  companyInterestRateVll = new McValueLabelList();
  sendFlowToInkassoTransactionsVll = new McValueLabelList();
  cbSendFlowToInkassoValidFlg = false;
  defaultFee = McProcessFlow.COMPANY_INTEREST_RATE_TYPE_CD_DEFAULT;
  errorMsg = '';
  transactionStatusVll = new McValueLabelList();
  selectedWeekdayToSendNotification!: string;
  recurringDetailsWeekDaysVll = new McValueLabelList();

  // -- Input --
  @Input() mcProcessFlowId!: number;
  @Input() mcProcessFlow!: McProcessFlow;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;
  @Input() mcProcessId!: number;
  @Input() mcProcess!: McProcess;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();

  // CRITERIA PROPERTIES
  public mcProcessFlowCriteriaList = new McProcessFlowCriteriaList();
  public mcProcessFlowCriteriaAttributeList = new McProcessFlowCriteriaAttributeList();
  public mcProcessFlowCriteriaAttribute = new McProcessFlowCriteriaAttribute();
  public attributesVll = new McValueLabelList();
  public criteriaId = -1;
  currency!: string;
  csrRecurringDetails = new CsrRecurringDetails();


  // -- Constructor --
  constructor(
    private activeRoute: ActivatedRoute,
    private factoringService: EbFactoringService,) {
  }


  // ---------------------------------------------------------------------
  ngOnInit() {
    this.transactionStatusVll = McProcessFlow.getNewStatusForTransactionsAfterLastDunningLevelVll();
    const criteria1 = new McProcessFlowCriteria();
    criteria1.id = this.criteriaId--;
    this.mcProcessFlowCriteriaList.items.push(criteria1);
    this.mcProcessFlowCriteriaAttributeList.loadAll(() => {
      this.attributesVll = this.mcProcessFlowCriteriaAttributeList.toVll('attributeName', 'attributeName');
      this.attributesVll.removeItemByValue('[Transaction] status_cd');
    });

    this.recurringDetailsWeekDaysVll = this.csrRecurringDetails.getWeekDaysVll();

    this.statusVlm.set(McProcessFlow.STATUS_CD_ACTIVE, 'Active');
    this.statusVlm.set(McProcessFlow.STATUS_CD_INACTIVE, 'Inactive');
    this.inheritableVll.add(true, 'Yes');
    this.inheritableVll.add(false, 'No');
    this.personInterestRateVll.add(McProcessFlow.PERSON_INTEREST_RATE_TYPE_CD_DEFAULT, 'Default (5% above the base rate of the German Central bank)');
    this.personInterestRateVll.add(McProcessFlow.PERSON_INTEREST_RATE_TYPE_CD_ENTER, 'Enter interest rate');
    this.companyInterestRateVll.add(McProcessFlow.COMPANY_INTEREST_RATE_TYPE_CD_DEFAULT, 'Default (9% above the base rate of the German Central bank)');
    this.companyInterestRateVll.add(McProcessFlow.COMPANY_INTEREST_RATE_TYPE_CD_ENTER, 'Enter interest rate');
    this.sendFlowToInkassoTransactionsVll = McProcessFlow.getSendFlowToInkassoTransactionTypeCdVll();


    if (!this.mcProcessFlow) {
      this.mcProcessFlow = new McProcessFlow();
    }

    this.currency = this.factoringService.activeChildFactoring.mcCurrency.code;

    this.actLoad();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcProcessFlow = new McProcessFlow();
    if (this.mcProcessFlowId > 0) {
      this.mcProcessFlow.loadById(this.mcProcessFlowId, () => {
        // this.cbSendFlowToInkassoValidFlg =
        this.validatePrerequisites();
      });
    } else {
      // new item
      this.validatePrerequisites();
    }

  }

  // ---------------------------------------------------------------------
  actSave() {
    if (this.mcForm.isValid()) {

      let isDueDateTimeInCriteriaList = false;


      this.mcProcessFlowCriteriaList.items.forEach(item => {
        if (item.attributeName === '[Transaction] due_date') { // TODO: This value comes from BE
          isDueDateTimeInCriteriaList = true;
        }
      });

      if (isDueDateTimeInCriteriaList) {
        this.mcProcessFlow.idMcProcess = this.mcProcessId;
        this.mcProcessFlow.statusCd = this.mcForm.getValue('ctrlStatus');
        this.mcProcessFlow.title = this.mcForm.getValue('ctrlTitle');
        this.mcProcessFlow.description = this.mcForm.getValue('ctrlDescription');
        this.mcProcessFlow.inheritableFlg = this.mcForm.getValue('ctrlInheritableFlg');
        this.mcProcessFlow.flowCriteriaList = this.mcProcessFlowCriteriaList;
        this.mcProcessFlow.weekdayToSendNotification = this.mcForm.getValue('selDay');

        // Transactions flags
        this.mcProcessFlow.acceptedTransactionsEnteringFlowFlg = this.mcForm.getValue('ctrlTransactionsAccepted');
        this.mcProcessFlow.returnedTransactionsEnteringFlowFlg = this.mcForm.getValue('ctrlTransactionsReturned');
        this.mcProcessFlow.forDunningTransactionsEnteringFlowFlg = this.mcForm.getValue('ctrlTransactionsForDunning');
        this.mcProcessFlow.shouldGoToInkassoTransactionsEnteringFlowFlg = this.mcForm.getValue('ctrlTransactionsShouldGoToInkasso');
        this.mcProcessFlow.sendingToInkassoTransactionsEnteringFlowFlg = this.mcForm.getValue('ctrlTransactionsSendingToInkasso');

        // Fees
        this.mcProcessFlow.personInterestRateTypeCd = this.mcForm.getValue('ctrlInterestRatePerson');
        if (this.mcProcessFlow.personInterestRateTypeCd === McProcessFlow.PERSON_INTEREST_RATE_TYPE_CD_ENTER) {
          this.mcProcessFlow.interestRateForPersonAmount = this.mcForm.getValue('ctrlPersonInterestRateAmount');
        } else {
          this.mcProcessFlow.interestRateForPersonAmount = 4.12;
        }

        this.mcProcessFlow.companyInterestRateTypeCd = this.mcForm.getValue('ctrlInterestRateCompany');
        if (this.mcProcessFlow.companyInterestRateTypeCd === McProcessFlow.COMPANY_INTEREST_RATE_TYPE_CD_ENTER) {
          this.mcProcessFlow.interestRateForCompanyAmount = this.mcForm.getValue('ctrlCompanyInterestRateAmount');
        } else {
          this.mcProcessFlow.interestRateForCompanyAmount = 8.12;
        }

        this.mcProcessFlow.dunningNoteBaseFeeAmount = this.mcForm.getValue('ctrlDunningNoteValue');
        /*this.mcProcessFlow.emailFeeAmount = this.mcForm.getValue('ctrlEmailFeeValue');
        this.mcProcessFlow.smsFeeAmount = this.mcForm.getValue('ctrlSMSFeeValue');
        this.mcProcessFlow.postFeeAmount = this.mcForm.getValue('ctrlPostFeeValue');*/


        this.mcProcessFlow.newStatusForTransactionsAfterLastDunningLevel = this.mcForm.getValue('selTransactionStatusAfterLastLevel');


        this.mcProcessFlow.sendFlowToInkassoFlg = this.mcForm.getValue('ctrlSendFlowToInkasso');
        if (this.mcProcess.isTypeCdDUNNING()) {
          this.mcProcessFlow.groupByConsumerFlg = this.mcForm.getValue('ctrlGroupByConsumer');
        }
        if (this.mcProcessFlow.sendFlowToInkassoFlg) {
          this.mcProcessFlow.sendFlowToInkassoTransactionTypeCd = this.mcForm.getValue('ctrlSendFlowTypeOfTransactions');
        }

        if (this.mcProcessFlow.sendFlowToInkassoFlg && this.mcProcessFlow.newStatusForTransactionsAfterLastDunningLevel === McProcessFlow.NEW_STATUS_FOR_TRANSACTIONS_AFTER_LAST_DUNNING_LEVEL_REMAIN_IN_CURRENT_STATUS) {
          this.errorMsg = 'Status must be either "Sending to Inkasso" or "Should go to Inkasso". Please choose one of these two options.';
          this.saveButtonDisabledMcb.setFalse();
        } else if (!this.validTransactionsEnteringFlowFlags()) {
          this.errorMsg = 'You must select at least one of the transaction statuses that will go into this flow.';
          this.saveButtonDisabledMcb.setFalse();
        } else {
          this.mcProcessFlow.save(() => {
            this.eventSaved.emit();
          }, () => {
            this.errorMsg = this.mcProcessFlow.apiErrorMessage;
            this.saveButtonDisabledMcb.setFalse();
          });
        }
      } else {
        this.errorMsg = 'The flow cannot be saved without a "due_date" criteria';
        this.saveButtonDisabledMcb.setFalse();
      }

    } else {
      this.saveButtonDisabledMcb.setFalse();
    }

  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.mcProcessFlow.exists()) {

      this.mcProcessFlow.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

// ---------------------------------------------------------------------
  validatePrerequisites() {
    this.errorMsgs = [];
    // populate validation here on required inputs
  }

  // ---------------------------------------------------------------------
  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }

  // ---------------------------------------------------------------------

  addCriteria() {
    const criteria = new McProcessFlowCriteria();
    criteria.id = this.criteriaId--;
    this.mcProcessFlowCriteriaList.items.push(criteria);

  }

  removeCriteria(index: number) {
    this.mcProcessFlowCriteriaList.items.splice(index, 1);
  }

  cbSendFlowToInkassoValueChanged(cbValue: boolean) {
    this.cbSendFlowToInkassoValidFlg = cbValue;
  }

  validTransactionsEnteringFlowFlags(): boolean {
    return this.mcProcessFlow.acceptedTransactionsEnteringFlowFlg || this.mcProcessFlow.returnedTransactionsEnteringFlowFlg ||
      this.mcProcessFlow.forDunningTransactionsEnteringFlowFlg || this.mcProcessFlow.shouldGoToInkassoTransactionsEnteringFlowFlg ||
      this.mcProcessFlow.sendingToInkassoTransactionsEnteringFlowFlg;
  }

  onDayChanged(value: string) {
      this.selectedWeekdayToSendNotification = value;
  }
}
