import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {McEntityService} from '@miticon-ui/mc-core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mc-add-custom-attribute',
  templateUrl: './mc-add-custom-attribute.component.html',
})
export class McAddCustomAttributeComponent implements OnInit {

  modalReference!: any;
  entityLocalAttributesForm: FormGroup;
  message!: string;
  type!: string;
  postgresAttributeTypes;
  isEnabled!: boolean;
  invalidForm!: boolean;
  existAttributeName!: boolean;
  isLengthValid!: boolean;
  defaultSelected!: boolean;
  minDate!: any;
  maxDate!: any;
  @Input() entityAttributes!: any;
  @Input() indexOfCustomAttribute!: any;

  @Input() editAttribute!: any;

  // For editing, in html pass: list of custom attributes, custom attribute object and index of that attribute in custom attribute list
  // _____________________________________________________________________________________________________________

  constructor(
    private modalService: NgbModal,
    private entitiesService: McEntityService,
    private fb: FormBuilder,
    @Inject('utilityConfig') utility: any
  ) {
    this.entityLocalAttributesForm = new FormGroup({});
    // Get customAttributes types from utility config
    this.postgresAttributeTypes = utility.customAttributeTypes;
  }

  ngOnInit() {
  }

  openModal(customAttributes: any) {
    this.setNotificationMessage('', '');
    this.existAttributeName = false;
    this.isLengthValid = true;
    this.defaultSelected = !!(this.editAttribute && this.editAttribute.defaultValue);
    this.invalidForm = false;
    this.minDate = {year: 1900, month: 1, day: 1};
    this.maxDate = {year: 2100, month: 12, day: 31};
    // check if is edit mode available and disable save btn if it's not
    this.isEnabled = !!this.editAttribute;
    /*Modal reference - used for close it*/
    this.modalReference = this.modalService.open(customAttributes, {
      centered: true,
      size: 'lg',
      windowClass: 'mc-entity-local-attributes-modal',
      backdrop: 'static'
    });
    // set attributes types values on open modal
    let attributeType;
    if (this.editAttribute) {
      switch (this.editAttribute.type) {
        case 'INTEGER':
          attributeType = 'Number';
          break;
        case 'VARCHAR':
          attributeType = 'String';
          break;
        case 'DATE':
          attributeType = 'Date';
          break;
        case 'DATETIME':
          attributeType = 'Date time';
          break;
        case 'FLOAT':
          attributeType = 'Decimal';
          break;
        case 'BOOLEAN':
          attributeType = 'Yes/No';
          break;
        default:
          attributeType = this.editAttribute.type;
      }
    }
    // set formValues
    this.entityLocalAttributesForm = this.fb.group({
      enabled: [this.editAttribute ? this.editAttribute.enabled : true, Validators.required],
      name: [this.editAttribute ? this.editAttribute.name : '', Validators.required],
      length: [this.editAttribute ? this.editAttribute.length : '', [Validators.min(0), Validators.max(255)]],
      type: [attributeType ? attributeType : '', Validators.required],
      validationRegex: [this.editAttribute ? this.editAttribute.validationRegex : ''],
      description: [this.editAttribute ? this.editAttribute.description : ''],
      inheritable: [this.editAttribute ? this.editAttribute.inheritable : true, Validators.required],
      required: [this.editAttribute ? this.editAttribute.required : true, Validators.required],
      defaultValueSelect: [this.editAttribute ? this.editAttribute.defaultValueSelect : ''],
      defaultValue: [this.editAttribute ? this.editAttribute.defaultValue : ''],
      inherited: [false]
    });

    // this.entityLocalAttributesForm.get('name').valueChanges.pipe(debounceTime(100)).subscribe( data => {
    //   this.isFormValid(data);
    // });
    // Disable length field if type is not string
    if (this.editAttribute && this.entityLocalAttributesForm.getRawValue().type === 'String') {
      this.entityLocalAttributesForm.get('length')?.enable();
    } else {
      this.entityLocalAttributesForm.get('length')?.disable();
    }
    // Disable validation field if type is yes/no
    if (this.editAttribute && this.entityLocalAttributesForm.getRawValue().type === 'Yes/No') {
      this.entityLocalAttributesForm.get('validationRegex')?.disable();
    } else {
      this.entityLocalAttributesForm.get('validationRegex')?.enable();
    }

    this.entityLocalAttributesForm.get('type')?.valueChanges.subscribe( data => {
      console.log('DEFAULT CHECK', data);
    });
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  // Check if form is valid
  public isFormValid(attributeName: string) {
    this.isEnabled = false;
    let name;
    if (attributeName) {
      name = attributeName.trim().toLowerCase();
    }
    // when name is changed
    if (name) {
      // search throw parentLocalAttributeList
      if (this.editAttribute) {
        const filteredArray = this.entityAttributes.entityAttributesList.filter((obj: any) => obj.name !== this.editAttribute.name);
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < filteredArray.length; i += 1) {
          if (name === filteredArray[i].toLowerCase()) {
            this.existAttributeName = true;
            this.isEnabled = false;
            return this.setNotificationMessage(`Name you entered already exists. Attribute name must be unique.`, 'error');
          } else if (this.invalidForm === true && this.isLengthValid === true) {
            this.existAttributeName = false;
            this.isEnabled = false;
            this.setNotificationMessage(`Required fields can\'t be empty`, 'error');
          } else if (this.isLengthValid === false) {
            this.existAttributeName = false;
            this.isEnabled = false;
            this.setNotificationMessage(`String can't contains more than 255 characters.`, 'error');
          } else {
            this.existAttributeName = false;
            this.isEnabled = true;
            this.setNotificationMessage(``, '');
          }
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.entityAttributes.entityAttributesList.length; i += 1) {
          if (name === this.entityAttributes.entityAttributesList[i].toLowerCase()) {
            this.existAttributeName = true;
            this.isEnabled = false;
            return this.setNotificationMessage(`Name you entered already exists. Attribute name must be unique.`, 'error');
          } else if (this.invalidForm === true && this.isLengthValid === true) {
            this.existAttributeName = false;
            this.isEnabled = false;
            this.setNotificationMessage(`Required fields can\'t be empty`, 'error');
          } else if (this.isLengthValid === false) {
            this.existAttributeName = false;
            this.isEnabled = false;
            this.setNotificationMessage(`String can't contains more than 255 characters.`, 'error');
          } else {
            this.existAttributeName = false;
            this.isEnabled = true;
            this.setNotificationMessage(``, '');
          }
        }
      }
    }
    // check if form is valid
    const form = this.entityLocalAttributesForm;
    const isInvalid = Object.keys(form.controls).find(item => form.controls[item].invalid);
    if (isInvalid) {
      this.invalidForm = true;
      if (form.get(isInvalid)?.errors && form.get(isInvalid)?.errors?.['required']) {
        this.isEnabled = false;
        this.setNotificationMessage(`Required fields can\'t be empty`, 'error');
      } else if (isInvalid === 'length') {
        this.isEnabled = false;
        this.isLengthValid = false;
        this.setNotificationMessage(`String can't contains more than 255 characters.`, 'error');
      }
    } else if (this.existAttributeName === true) {
      this.isEnabled = false;
      this.invalidForm = false;
      this.setNotificationMessage(`Name you entered already exists. Attribute name must be unique.`, 'error');
    } else {
      this.invalidForm = false;
      this.isLengthValid = true;
      this.isEnabled = true;
      this.setNotificationMessage('', '');
    }
  }

  // when type is changed
  onChangeType(event: any) {
    const form = this.entityLocalAttributesForm;
    switch (event) {
      case 'INTEGER':
        form.get('length')?.disable();
        form.get('validationRegex')?.enable();
        form.get('length')?.setValue('');
        form.get('defaultValue')?.setValue('');
        break;
      case 'VARCHAR':
        form.get('length')?.enable();
        form.get('validationRegex')?.enable();
        form.get('defaultValue')?.setValue('');
        break;
      case 'DATETIME':
        form.get('length')?.disable();
        form.get('validationRegex')?.enable();
        form.get('length')?.setValue('');
        form.get('defaultValue')?.setValue('');
        break;
      case 'DATE':
        form.get('length')?.disable();
        form.get('validationRegex')?.enable();
        form.get('length')?.setValue('');
        form.get('defaultValue')?.setValue('');
        break;
      case 'FLOAT':
        form.get('length')?.disable();
        form.get('validationRegex')?.enable();
        form.get('length')?.setValue('');
        form.get('defaultValue')?.setValue('');
        break;
      case 'BOOLEAN':
        form.get('length')?.disable();
        form.get('validationRegex')?.disable();
        form.get('length')?.setValue('');
        form.get('defaultValue')?.setValue('');
        break;
      default:
        console.log('event', event);
    }
  }

  // Check if defaultValue is checked
  checkDefaultValue(event: any) {
    const form = this.entityLocalAttributesForm;
    if (event === false) {
      form.get('defaultValue')?.setValue('');
      this.defaultSelected = false;
    } else {
      this.defaultSelected = true;
    }
  }

  // Set two number decimal
  public setTwoNumberDecimal(value: any, num: number) {
    const form = this.entityLocalAttributesForm;

    if (num === 1) {
      form.get('defaultValue')?.setValue(parseFloat(value).toFixed(2));
    }
  }

  // Set form values
  setValues() {
    const form = this.entityLocalAttributesForm;
    if ((form.getRawValue().length === '' || form.getRawValue().length === null) && form.getRawValue().type === 'String') {
      form.get('length')?.setValue('255');
    }
    const formData = form.getRawValue();
    if (this.editAttribute) {
      this.entityAttributes.editCustomAttribute(formData, this.indexOfCustomAttribute, this.entitiesService)
        .then( () => this.modalReference.dismiss());
    } else {
      console.log(formData, 'New Attribute');
      this.entityAttributes.updateCustomAttributes(formData, this.entitiesService).then(() => this.modalReference.dismiss());

    }
  }

}
