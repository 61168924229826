<div class="row" [formGroup]="formGroup">

  <div class="col-md-12 mc-stepper-header-title">
    <span class="pl-4 pb-1 mr-2 mc-active">
      <span class="mc-notification-badge-gray mc-active-background mc-active-background">1</span>
      {{'cc.consumers.consumers-overview.file-upload' | translate}}
    </span>
    <span class="pl-4 pb-1 ml-4 mr-2">
    <span class="mc-notification-badge-gray">2</span>
      {{'cc.consumers.consumers-overview.mapping-&-importing' | translate}}
    </span>
  </div>

  <div class="col-md-12 position-relative mt-1">
    <div class="mc-create-user-notify-wrapper">
      <div class="w-100 mc-notify-message-wrapper mc-background-loading mc-text-align-center py-1" *ngIf="webFile.apiLoadingFlg">{{'cc.my-factoring.loading-file-please-wait' | translate}}</div>
      <div class="w-100" *ngIf="webFile.apiErrorFlg"><mc-notify-message [message]=webFile.apiErrorMessage [type]="'error'"></mc-notify-message></div>
      <div class="w-100" *ngIf="!isEnabled"><mc-notify-message [message]="message" [type]="messageType"></mc-notify-message></div>
    </div>
  </div>

  <div class="col-md-12 mt-5 px-5">
    <div class="input-group mb-3">

      <!--<div class="custom-file">
        <input type="file" class="custom-file-input" id="importFile" (change)="onFileChanged($event)">
        <label class="custom-file-label" #labelImport for="importFile"><i class="fas fa-search"></i> Choose file</label>
      </div>-->
      <lib-mcc-fi-file-upload [acceptProperty]="'.csv'" (eventFileSelected)="onFileSelected($event)"></lib-mcc-fi-file-upload>

    </div>
  </div>

  <div class="col-md-12 mt-4 pt-1 pl-5 d-flex align-items-center">
    <label class="mb-0 mc-field-width-10" for="delimiterSelect">{{'cc.consumers.consumers-overview.delimiter' | translate}}</label>
    <select class="form-check-label custom-select mc-field-width-7 mx-3" id="delimiterSelect" formControlName="delimiter">
      <option class="font-weight-bold">;</option>
      <option class="font-weight-bold">,</option>
<!--      <option disabled class="mc-cursor-not-allowed">/</option>-->
    </select>
  </div>

  <div class="col-md-12 mt-2 mb-4 pt-1 pl-5 d-flex align-items-center">
    <label class="mb-0 mc-field-width-10" for="encodingSelector">{{'cc.consumers.consumers-overview.encoding' | translate}}</label>
    <select id="encodingSelector" class="form-check-label custom-select mc-field-width-15 mx-3">
      <option>UTF-8</option>
      <!--<option disabled>ISO-8859-15</option>
      <option disabled>ISO-8859-1</option>-->
    </select>
  </div>


  <div class="col-md-12">
    <div class="modal-footer">
      <button class="btn btn-sm mc-cancel-btn mr-5" (click)="actCancel()">
        {{ 'cc.common.edit.cancel' | translate }}
      </button>
      <button class="btn btn-primary mc-next-save-btn mr-3" (click)="actUpload()">
        {{'cc.consumers.consumers-overview.upload' | translate}}
      </button>
    </div>
  </div>
</div>
