import {CsrConsumerImportFilterGENERATED} from '../_generated/csr-consumer-import-filter-generated.model';

export class CsrConsumerImportFilter extends CsrConsumerImportFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrConsumerImportFilter();
  }

  // ---------------------------------------------------------------------
}
