/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBillingItem} from '../models/mc-billing-item.model';
import {McBillingItemService} from '../services/mc-billing-item.service';
import {IMcBillingItem} from '../_generated/mc-billing-item.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McBillingItemGENERATED extends BaseObject {


    public static readonly ENTRY_TYPE_CD_DEBIT: string = 'DEBIT';
    public static readonly ENTRY_TYPE_CD_CREDIT: string = 'CREDIT';
  public static readonly ENTRY_TYPE_CD_LIST__ALL = [
    McBillingItemGENERATED.ENTRY_TYPE_CD_DEBIT,
    McBillingItemGENERATED.ENTRY_TYPE_CD_CREDIT
];

    public static readonly REF_TYPE_CD_EB_SEPA_FILE: string = 'EB_SEPA_FILE';
    public static readonly REF_TYPE_CD_MC_ENTITY: string = 'MC_ENTITY';
    public static readonly REF_TYPE_CD_EB_FACTORING: string = 'EB_FACTORING';
    public static readonly REF_TYPE_CD_MC_PDF: string = 'MC_PDF';
    public static readonly REF_TYPE_CD_MC_CONSUMER: string = 'MC_CONSUMER';
    public static readonly REF_TYPE_CD_MC_BILLING_BATCH: string = 'MC_BILLING_BATCH';
    public static readonly REF_TYPE_CD_MC_BANK_ACCOUNT: string = 'MC_BANK_ACCOUNT';
    public static readonly REF_TYPE_CD_PM_TRANSACTION: string = 'PM_TRANSACTION';
    public static readonly REF_TYPE_CD_PM_ADJUSTMENT: string = 'PM_ADJUSTMENT';
  public static readonly REF_TYPE_CD_LIST__ALL = [
    McBillingItemGENERATED.REF_TYPE_CD_EB_SEPA_FILE,
    McBillingItemGENERATED.REF_TYPE_CD_MC_ENTITY,
    McBillingItemGENERATED.REF_TYPE_CD_EB_FACTORING,
    McBillingItemGENERATED.REF_TYPE_CD_MC_PDF,
    McBillingItemGENERATED.REF_TYPE_CD_MC_CONSUMER,
    McBillingItemGENERATED.REF_TYPE_CD_MC_BILLING_BATCH,
    McBillingItemGENERATED.REF_TYPE_CD_MC_BANK_ACCOUNT,
    McBillingItemGENERATED.REF_TYPE_CD_PM_TRANSACTION,
    McBillingItemGENERATED.REF_TYPE_CD_PM_ADJUSTMENT
];

    public static readonly STATUS_CD_NEW: string = 'NEW';
    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_CANCELED: string = 'CANCELED';
    public static readonly STATUS_CD_FINALIZED: string = 'FINALIZED';
  public static readonly STATUS_CD_LIST__ALL = [
    McBillingItemGENERATED.STATUS_CD_NEW,
    McBillingItemGENERATED.STATUS_CD_ACTIVE,
    McBillingItemGENERATED.STATUS_CD_CANCELED,
    McBillingItemGENERATED.STATUS_CD_FINALIZED
];

    public static readonly TRANSACTION_RETURNED_TYPE_CD_TRANSACTION_RETURNED_FEE: string = 'TRANSACTION_RETURNED_FEE';
    public static readonly TRANSACTION_RETURNED_TYPE_CD_BANK_FEES_FOR_RETURNED_TRANSACTION: string = 'BANK_FEES_FOR_RETURNED_TRANSACTION';
    public static readonly TRANSACTION_RETURNED_TYPE_CD_RETURNED_TRANSACTION_AMOUNT: string = 'RETURNED_TRANSACTION_AMOUNT';
  public static readonly TRANSACTION_RETURNED_TYPE_CD_LIST__ALL = [
    McBillingItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_TRANSACTION_RETURNED_FEE,
    McBillingItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_BANK_FEES_FOR_RETURNED_TRANSACTION,
    McBillingItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_RETURNED_TRANSACTION_AMOUNT
];

  public apiService: McBillingItemService;
  public _rawJson: IMcBillingItem;
    id: number = 0;
  activityDatetime: number;
  amount: number;
  childEntityId: number;
  description = '';
  entryTypeCd: string;
  idBillingService: number;
  mcBillingBatchId: number;
  mcBillingStatementId: number;
  mcBillingStatementItemId: number;
  parentEntityId: number;
  payoutDate: number;
  refId: number;
  refTypeCd: string;
  statusCd: string;
  sysCreatedDatetime: number;
  sysUpdatedDatetime: number;
  title = '';
  total: number;
  transactionReturnedTypeCd: string;
  vatForMcBillingItem: number;
  vatPercent: number;
  childEntityName: string;  mcBillingBatchName: string;  mcBillingStatementItemTitle: string;  mcBillingStatementTitle: string;

  public properties: string[] = ['id', 'activityDatetime', 'amount', 'childEntityId', 'description', 'entryTypeCd', 'idBillingService', 'mcBillingBatchId', 'mcBillingStatementId', 'mcBillingStatementItemId', 'parentEntityId', 'payoutDate', 'refId', 'refTypeCd', 'statusCd', 'sysCreatedDatetime', 'sysUpdatedDatetime', 'title', 'total', 'transactionReturnedTypeCd', 'vatForMcBillingItem', 'vatPercent', 'childEntityName', 'mcBillingBatchName', 'mcBillingStatementItemTitle', 'mcBillingStatementTitle'];
  public propertiesRegular: string[] = ['id', 'activityDatetime', 'amount', 'childEntityId', 'description', 'entryTypeCd', 'idBillingService', 'mcBillingBatchId', 'mcBillingStatementId', 'mcBillingStatementItemId', 'mcBillingBatch', 'mcBillingStatement', 'mcBillingStatementItem', 'parentEntityId', 'payoutDate', 'refId', 'refTypeCd', 'statusCd', 'sysCreatedDatetime', 'sysUpdatedDatetime', 'title', 'total', 'transactionReturnedTypeCd', 'vatForMcBillingItem', 'vatPercent', 'childEntityName', 'mcBillingBatchName', 'mcBillingStatementItemTitle', 'mcBillingStatementTitle'];
  public propertiesSpecial: string[] = [];



  public static getEntryTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingItemGENERATED.ENTRY_TYPE_CD_DEBIT, McGod.t('Debit'));
    list.add(McBillingItemGENERATED.ENTRY_TYPE_CD_CREDIT, McGod.t('Credit'));


    return list;
  }

  public static getEntryTypeCdLabel(aCd: string): string {
    const list = McBillingItemGENERATED.getEntryTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getRefTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingItemGENERATED.REF_TYPE_CD_EB_SEPA_FILE, McGod.t('EbSepaFile'));
    list.add(McBillingItemGENERATED.REF_TYPE_CD_MC_ENTITY, McGod.t('McEntity'));
    list.add(McBillingItemGENERATED.REF_TYPE_CD_EB_FACTORING, McGod.t('EbFactoring'));
    list.add(McBillingItemGENERATED.REF_TYPE_CD_MC_PDF, McGod.t('McPdf'));
    list.add(McBillingItemGENERATED.REF_TYPE_CD_MC_CONSUMER, McGod.t('McConsumer'));
    list.add(McBillingItemGENERATED.REF_TYPE_CD_MC_BILLING_BATCH, McGod.t('McBillingBatch'));
    list.add(McBillingItemGENERATED.REF_TYPE_CD_MC_BANK_ACCOUNT, McGod.t('McBankAccount'));
    list.add(McBillingItemGENERATED.REF_TYPE_CD_PM_TRANSACTION, McGod.t('PmTransaction'));
    list.add(McBillingItemGENERATED.REF_TYPE_CD_PM_ADJUSTMENT, McGod.t('PmAdjustment'));


    return list;
  }

  public static getRefTypeCdLabel(aCd: string): string {
    const list = McBillingItemGENERATED.getRefTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingItemGENERATED.STATUS_CD_NEW, McGod.t('New'));
    list.add(McBillingItemGENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(McBillingItemGENERATED.STATUS_CD_CANCELED, McGod.t('Canceled'));
    list.add(McBillingItemGENERATED.STATUS_CD_FINALIZED, McGod.t('Finalized'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McBillingItemGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTransactionReturnedTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_TRANSACTION_RETURNED_FEE, McGod.t('Transaction returned fee'));
    list.add(McBillingItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_BANK_FEES_FOR_RETURNED_TRANSACTION, McGod.t('Bank fees for returned transactions'));
    list.add(McBillingItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_RETURNED_TRANSACTION_AMOUNT, McGod.t('Returned transaction amount'));


    return list;
  }

  public static getTransactionReturnedTypeCdLabel(aCd: string): string {
    const list = McBillingItemGENERATED.getTransactionReturnedTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McBillingItem {
    console.error('MTCN-ERROR: Not Implemented: McBillingItem::createNewInstance(). Add this method to final class and return new McBillingItem();');
    throw new Error('Cannot McBillingItem::createNewInstance(). Add this method to final class and return new McBillingItem();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBillingItem): McBillingItem {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingItemService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBillingItem AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getActivityDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.activityDatetime);
    }

    public getActivityDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.activityDatetime);
    }

    public getAmountStr(): string {
       return McGod.formatNumberToMoney(this.amount);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getTotalStr(): string {
       return McGod.formatNumberToMoney(this.total);
    }

    public getVatForMcBillingItemStr(): string {
       return McGod.formatNumberToMoney(this.vatForMcBillingItem);
    }

    public getVatPercentStr(): string {
       return McGod.formatNumberToMoney(this.vatPercent);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getEntryTypeCdLabel(): string {
    return McBillingItemGENERATED.getEntryTypeCdLabel(this.entryTypeCd);
  }


 public  isEntryTypeCdDEBIT(): boolean {
    const result = (this.entryTypeCd === McBillingItemGENERATED.ENTRY_TYPE_CD_DEBIT);

    return result;
  }

 public  isEntryTypeCdCREDIT(): boolean {
    const result = (this.entryTypeCd === McBillingItemGENERATED.ENTRY_TYPE_CD_CREDIT);

    return result;
  }


  public getRefTypeCdLabel(): string {
    return McBillingItemGENERATED.getRefTypeCdLabel(this.refTypeCd);
  }


 public  isRefTypeCdEB_SEPA_FILE(): boolean {
    const result = (this.refTypeCd === McBillingItemGENERATED.REF_TYPE_CD_EB_SEPA_FILE);

    return result;
  }

 public  isRefTypeCdMC_ENTITY(): boolean {
    const result = (this.refTypeCd === McBillingItemGENERATED.REF_TYPE_CD_MC_ENTITY);

    return result;
  }

 public  isRefTypeCdEB_FACTORING(): boolean {
    const result = (this.refTypeCd === McBillingItemGENERATED.REF_TYPE_CD_EB_FACTORING);

    return result;
  }

 public  isRefTypeCdMC_PDF(): boolean {
    const result = (this.refTypeCd === McBillingItemGENERATED.REF_TYPE_CD_MC_PDF);

    return result;
  }

 public  isRefTypeCdMC_CONSUMER(): boolean {
    const result = (this.refTypeCd === McBillingItemGENERATED.REF_TYPE_CD_MC_CONSUMER);

    return result;
  }

 public  isRefTypeCdMC_BILLING_BATCH(): boolean {
    const result = (this.refTypeCd === McBillingItemGENERATED.REF_TYPE_CD_MC_BILLING_BATCH);

    return result;
  }

 public  isRefTypeCdMC_BANK_ACCOUNT(): boolean {
    const result = (this.refTypeCd === McBillingItemGENERATED.REF_TYPE_CD_MC_BANK_ACCOUNT);

    return result;
  }

 public  isRefTypeCdPM_TRANSACTION(): boolean {
    const result = (this.refTypeCd === McBillingItemGENERATED.REF_TYPE_CD_PM_TRANSACTION);

    return result;
  }

 public  isRefTypeCdPM_ADJUSTMENT(): boolean {
    const result = (this.refTypeCd === McBillingItemGENERATED.REF_TYPE_CD_PM_ADJUSTMENT);

    return result;
  }


  public getStatusCdLabel(): string {
    return McBillingItemGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdNEW(): boolean {
    const result = (this.statusCd === McBillingItemGENERATED.STATUS_CD_NEW);

    return result;
  }

 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === McBillingItemGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdCANCELED(): boolean {
    const result = (this.statusCd === McBillingItemGENERATED.STATUS_CD_CANCELED);

    return result;
  }

 public  isStatusCdFINALIZED(): boolean {
    const result = (this.statusCd === McBillingItemGENERATED.STATUS_CD_FINALIZED);

    return result;
  }


  public getTransactionReturnedTypeCdLabel(): string {
    return McBillingItemGENERATED.getTransactionReturnedTypeCdLabel(this.transactionReturnedTypeCd);
  }


 public  isTransactionReturnedTypeCdTRANSACTION_RETURNED_FEE(): boolean {
    const result = (this.transactionReturnedTypeCd === McBillingItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_TRANSACTION_RETURNED_FEE);

    return result;
  }

 public  isTransactionReturnedTypeCdBANK_FEES_FOR_RETURNED_TRANSACTION(): boolean {
    const result = (this.transactionReturnedTypeCd === McBillingItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_BANK_FEES_FOR_RETURNED_TRANSACTION);

    return result;
  }

 public  isTransactionReturnedTypeCdRETURNED_TRANSACTION_AMOUNT(): boolean {
    const result = (this.transactionReturnedTypeCd === McBillingItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_RETURNED_TRANSACTION_AMOUNT);

    return result;
  }


  
}
