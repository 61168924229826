import {Component, Inject, OnInit} from '@angular/core';
import {McConsumerHistoryList, McConsumerHistoryTable3Config, McBoolean, McGod, McConsumer} from "@miticon-ui/mc-core";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'mc-consumer-consumer-history-page',
  templateUrl: './mc-consumer-consumer-history-page.component.html',
})
export class McConsumerConsumerHistoryPageComponent implements OnInit {

  mcConsumerHistoryList!: McConsumerHistoryList;
  mcConsumerHistoryTable3Config = new  McConsumerHistoryTable3Config();
  showModalMcb = new McBoolean();
  mcConsumerId!: number;
  mcConsumer = new McConsumer();

  // ---------------------------------------------------------------------
  constructor( private route: ActivatedRoute) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.showModalMcb.setTrue();
    this.mcConsumerHistoryList = new McConsumerHistoryList();
    this.mcConsumerHistoryList.setPager(0, 100);
    this.route.paramMap.subscribe(params => {
      // @ts-ignore
      this.mcConsumerId = +params.get('id');
      console.log('CONSUMER ID', this.mcConsumerId);
      this.mcConsumer.loadById(this.mcConsumerId);
      this.actLoad();
    });
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcConsumerHistoryList.loadByConsumerId(this.mcConsumerId);
  }

  actCanceled() {
    McGod.redirectBack();
  }
}
