import {Inject, Injectable} from '@angular/core';
import {McCountryServiceGENERATED} from '../_generated/mc-country-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McCountryService extends McCountryServiceGENERATED {

}
