/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McBillingPayoutFile2Statement} from '../models/mc-billing-payout-file-2-statement.model';
import {McBillingPayoutFile2StatementList} from '../models/mc-billing-payout-file-2-statement-list.model';
import {McBillingPayoutFile2StatementFilter} from '../models/mc-billing-payout-file-2-statement-filter.model';
import {McBillingPayoutFile2StatementService} from '../services/mc-billing-payout-file-2-statement.service';
import {IMcBillingPayoutFile2Statement} from '../_generated/mc-billing-payout-file-2-statement.interface';
import {McGod} from "../extra/mc-god.service";

export class McBillingPayoutFile2StatementListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'numberOfStatements', 'totalFileAmount', 'payoutDate', 'statusCd', 'fileName', 'xlsWebfileId', 'sysCreatedDatetime', 'sysCreatedUserId'];
  public items: McBillingPayoutFile2Statement[] = [];
  public apiService: McBillingPayoutFile2StatementService;
 // protected tempObjList: McBillingPayoutFile2StatementList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingPayoutFile2StatementList {
    console.error('MTCN-ERROR: Not Implemented: McBillingPayoutFile2StatementList::createNewInstance(). Add this method to final class and return new McBillingPayoutFile2StatementList();');
    throw new Error('Cannot McBillingPayoutFile2StatementList::createNewInstance(). Add this method to final class and return new McBillingPayoutFile2StatementList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcBillingPayoutFile2Statement[]): McBillingPayoutFile2StatementList {
    const  listObj: McBillingPayoutFile2StatementList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McBillingPayoutFile2Statement = McBillingPayoutFile2Statement.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
public static createFromJsonPageable(aJsonObjPageable: any): McBillingPayoutFile2StatementList {
    const  listObj: McBillingPayoutFile2StatementList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingPayoutFile2StatementService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcBillingPayoutFile2Statement[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McBillingPayoutFile2Statement = McBillingPayoutFile2Statement.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcBillingPayoutFile2StatementFilter: McBillingPayoutFile2StatementFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcBillingPayoutFile2StatementFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McBillingPayoutFile2Statement {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McBillingPayoutFile2Statement();
    }
  }
  // -- CUSTOM LIST METHODS --
  
// ---------------------------------------------------------------------
public callCreatePayoutFilesByStatementIds( statementIds: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.createPayoutFilesByStatementIds(statementIds)
    .subscribe(responseJson => {
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
}

}

