export const ICON_EXPAND = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_130_1264)">
<path d="M20 6L9 6C8.45 6 8 6.45 8 7C8 7.55 8.45 8 9 8L20 8C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6ZM20 11H12C11.45 11 11 11.45 11 12C11 12.55 11.45 13 12 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11ZM21 17C21 16.45 20.55 16 20 16L9 16C8.45 16 8 16.45 8 17C8 17.55 8.45 18 9 18H20C20.55 18 21 17.55 21 17ZM3.7 9.12L6.58 12L3.7 14.88C3.31 15.27 3.31 15.9 3.7 16.29C4.09 16.68 4.72 16.68 5.11 16.29L8.7 12.7C9.09 12.31 9.09 11.68 8.7 11.29L5.11 7.7C4.72 7.31 4.09 7.31 3.7 7.7C3.32 8.09 3.31 8.73 3.7 9.12Z" fill="#3B457C"/>
</g>
<defs>
<clipPath id="clip0_130_1264">
<rect width="24" height="24" fill="white" transform="matrix(-1 0 0 -1 24 24)"/>
</clipPath>
</defs>
</svg>
`
