/*BbmTestObj*/
import {McPriceList2EntityGENERATED} from '../_generated/mc-price-list-2-entity-generated.model';

export class McPriceList2Entity extends McPriceList2EntityGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McPriceList2Entity();
  }

  // ---------------------------------------------------------------------
}
