
// @dynamic
import {FormGroup} from '@angular/forms';

export class McFormUtils {

public static  getControlValue(aCtrlName: string, aFormGroup: FormGroup) {
    return aFormGroup.get(aCtrlName + '.value')?.value;
  }
}
