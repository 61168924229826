import {McProcessFlowCriteria} from './mc-process-flow-criteria.model';
import {McProcessFlowCriteriaListGENERATED} from '../_generated/mc-process-flow-criteria-list-generated.model';

export class McProcessFlowCriteriaList extends McProcessFlowCriteriaListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessFlowCriteriaList();
  }

  // ---------------------------------------------------------------------
}
