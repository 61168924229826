/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McMappingFilter} from '../models/mc-mapping-filter.model';

export class McMappingFilterGENERATED extends BaseObjectFilter{    public searchTerm: string | undefined;
    public createdByUserId: number | undefined;
    public createDate: any | undefined;

  public properties: string[] = ['searchTerm', 'createdByUserId', 'createDate', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McMappingFilter {
    console.error('MTCN-ERROR: Not Implemented: McMappingFilter::createNewInstance(). Add this method to final class and return new McMappingFilter();');
      throw new Error('Cannot McMappingFilter::createNewInstance(). Add this method to final class and return new McMappingFilter();');
  }

}
