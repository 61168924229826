<ng-template #content let-modal>
  <!--Modal header section-->
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!--Modal body section-->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 px-0">
        <h4 class="modal-title mb-0" id="modal-basic-title">Edit entity's password policy:</h4>
      </div>
    </div>
    <mc-entity-password-policy [editEntityId]="editEntityId" [parentData]="parentData" [isEdit]="isFormEdit" (successfullyEditPasswordPolicy)="updatePasswordPolicyData($event)"></mc-entity-password-policy>
  </div>
</ng-template>

<h5 class="mc-entity-profile-title ml-2">{{'cc.common.view.password-policy' | translate}} <i *ngIf="parentData" class="fa fa-pen mc-pen-icon" (click)="openModal(content)"></i></h5>
