import {Component, OnInit} from '@angular/core';
import {
  McGod,
  McPdfTemplate,
  McBoolean,
  McPdfTemplateService, SortCriteriaList, SortCriteria
} from '@miticon-ui/mc-core';
import { MkFilterConfig, MkFilterOutput, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig } from '@miticon-ui/mc-components';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mc-process-pdf-template-list',
  templateUrl: './mc-process-pdf-template-list.component.html',
})
export class McProcessPdfTemplateListComponent implements OnInit {
  addPdfTemplateMcb = new McBoolean();
  mcPdfTemplate = new McPdfTemplate();
  mcGod = McGod.getInstance();
  pageNumber = 0;
  pageSize = 50;
  isLoading = false;
  totalItemsCount!: number;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  items: McPdfTemplate[] = [];
  searchTooltip = this.tS.instant("cc.process-pdf-template-search-tooltip");
  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.common.view.edit'),
      actionCd: McPdfTemplate.ACTION_SHOW_EDIT,
      matIcon: 'edit',
      permission: McGod.PERM_MC_PROCESS_PDF_TEMPLATE_EDIT,
    }
  ]

  // ---------------------------------------------------------------------
 constructor(private tS: TranslateService,
             private mcPdfTemplateService: McPdfTemplateService,
             private toastr: ToastrService) {
 }

  ngOnInit() {
    this.actLoad(this.pageNumber, this.pageSize, new SortCriteria('title', 'DESC'));
    this.initTableConfig();
  }

  public actLoad(pageNumber: number, pageSize: number, sortCriteria: SortCriteria): void {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.isLoading = true;

    const sortCriteriaList = new SortCriteriaList();
    sortCriteriaList.addSort(sortCriteria.sortProperty, sortCriteria.sortType);

    this.mcPdfTemplateService.getByEntityId(McGod.getLoggedEntityIdFromToken(),this.pageNumber, this.pageSize, sortCriteriaList).subscribe((res)=>{
      if(res){
            this.isLoading = false;
            this.totalItemsCount = res.totalElements;
            this.items = res.content.map((object: any) => McPdfTemplate.createFromJson(object));
          }
    });
  }

  private initTableConfig(): void {
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.edit.title'), 'title', 250, 'title');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.type-code'), 'getTypeCdLabel()', 250, 'typeCd');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.view.description'), 'description', 250, 'description');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.export-sepa.status-code'), 'getStatusCdLabel()', 250, 'statusCd');
  }

  onFilterChanged(filterOutput: MkFilterOutput){
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, filterOutput.sort);
  }


  actShowAddEditTemplate() {
    this.addPdfTemplateMcb.setTrue();
  }

  actCancelPdfTemplateAddEdit() {
    this.mcPdfTemplate = new McPdfTemplate();
    this.addPdfTemplateMcb.setFalse();
  }

  actSavedPdfTemplateAddEdit() {
    this.actLoad(this.pageNumber, this.pageSize, new SortCriteria('title', 'DESC'));
    this.addPdfTemplateMcb.setFalse();
    this.toastr.success('PDF Template' + this.tS.instant('cc.common.added-successfully'));
    this.mcPdfTemplate = new McPdfTemplate();
  }

  onTableAction($evet: MkMatTableMenuAction){
    if ($evet.action.actionCd === McPdfTemplate.ACTION_SHOW_EDIT) {
      this.mcPdfTemplate = $evet.item;
      this.actShowAddEditTemplate();
    }
  }
}
