<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcEntity.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h2>Finalize statement</h2>
  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-body">

    <mcc-message [errorMsg]="errorMsg"></mcc-message>

    <div class="info-container2 p-3">
      <div class="row">
        <div class="col-md-6">

          <mcc-row-title-description title="{{'cc.factoring.transactions.entity-name' | translate}}:"
                                     description="{{mcStatement.childEntityName}}"></mcc-row-title-description>
          <mcc-row-title-description title="Address:" descriptionInnerHtml="{{entityAddress}}"></mcc-row-title-description>
          <mcc-row-title-description title="Entity ID:"
                                     description="{{mcStatement.childEntityId}}"></mcc-row-title-description>
        </div>

        <div class="col-md-6">
          <mcc-row-title-description title="Statement No:"
                                     description="{{mcStatement.statementNumber}}"></mcc-row-title-description>
          <mcc-row-title-description title="Statement Date:"
                                     description="{{mcStatement.getStatementDate()}}"></mcc-row-title-description>
          <mcc-row-title-description title="Status:"
                                     description="{{mcStatementStatusLabel}}"></mcc-row-title-description>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="info-question p-3"><i class="far fa-question-circle icon-orange"></i> <span style="padding-top: 25px">Are you sure you want to finalize this statement? This action cannot be undone.</span>
      </div>
    </div>


  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel()"
                label="{{mcGod.t('cc.common.edit.cancel')}}"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actSave()" label="{{mcGod.t('cc.common.finalize')}}"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
