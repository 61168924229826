import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import {McBankAccountList, McBankAccountService, McInvoiceService, McUtilityService} from "@miticon-ui/mc-core";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "mc-bank-account-select-alternate",
  templateUrl: "./mc-bank-account-select-alternate.component.html",
})
export class McBankAccountSelectAlternateComponent implements OnInit {

  message!: string;
  type!: string;
  entityId!: any;
  bankAccounts!: McBankAccountList;
  isChecked!: boolean;
  bankAccountId!: any;
  defaultBankAccount!: any;
  bankAccountOnEdit!: any;
  showLoader!: boolean;

  @Input() orderId!: any;
  @Output() sendBankAccount = new EventEmitter<any>();
  @Output() sendErrorMessage = new EventEmitter<any>();

  constructor(
    private utilityService: McUtilityService,
    private invoiceService: McInvoiceService,
    public translate: TranslateService
  ) {
  }

  /*On init class*/
  ngOnInit() {
    this.bankAccounts = new McBankAccountList();
    this.getBankAccountByEntityId();
  }

  // Get bankAccounts for current entity
  getBankAccountByEntityId() {
    this.showLoader = true;
    const entityId = this.utilityService.getPropertyFromToken("entity_id");
    this.bankAccounts.loadByEntityId(entityId, () => {
      this.showLoader = false;
      const bankAccountList = this.bankAccounts.items;
      if (bankAccountList && bankAccountList.length > 0) {
        this.defaultBankAccount = bankAccountList.find(item => item.defaultFlg === true)?.id;
        this.sendBankAccount.emit(this.defaultBankAccount);
      }

      // If edit
      this.onUpdateInvoice();
    }, () => {
      this.showLoader = false;
      this.sendErrorMessage.emit({
        message: this.bankAccounts.apiErrorMessage || "Error occured while loading bank accounts.",
        type: "error"
      });
    });
  }

  onUpdateInvoice() {
    if (this.orderId) {
      this.showLoader = true;
      this.invoiceService.getAllOrderItems(this.orderId).subscribe((response) => {
        // @ts-ignore
        const editBankAccountId = response["chosenBankAccount"] && response["chosenBankAccount"]["id"];
        if (editBankAccountId) {
          this.bankAccountOnEdit = editBankAccountId;
          this.sendBankAccount.emit(this.bankAccountOnEdit);
          this.bankAccountId = editBankAccountId;
          if (editBankAccountId !== this.defaultBankAccount) {
            this.isChecked = true;
          }
        } else {
          this.isChecked = false;
        }
        this.showLoader = false;
      }, () => {
        this.showLoader = false;
      });
    }
  }

  // Send bankAccount to parent
  onChangeBankAccount() {
    this.bankAccountId = Number(this.bankAccountId);
    this.sendBankAccount.emit(this.bankAccountId);
  }

  // Set disable
  setDisable(bankAccount: any) {
    this.isChecked = bankAccount === true;
    if (this.isChecked === true) {
      if (this.bankAccountOnEdit) {
        this.bankAccountId = Number(this.bankAccountOnEdit);
        this.sendBankAccount.emit(this.bankAccountId);
      } else {
        this.bankAccountId = Number(this.defaultBankAccount);
        this.sendBankAccount.emit(this.bankAccountId);
      }
    } else {
      this.sendBankAccount.emit(this.defaultBankAccount);
    }
  }
}
