<div class="content-wrapper">
  <lib-mc-loader [showLoader]="loadingFlg"></lib-mc-loader>
  <div class="content-header-slim">
    <h3>{{'cc.bank.import-psd2-statement-file' | translate}}</h3>
  </div>


  <div class="content-body">
    <mcc-message *ngIf="apiErrorFlg" [errorMsg]="errorMsg"></mcc-message>

    <mcc-fi-select
      name="selBankAccountId"
      label="{{'cc.common.edit.bank-account' | translate}}"
      value=""
      [mcForm]="mcForm"
      [optionsVll]="bankAccountVll"
      validRequiredFlg="true">
    </mcc-fi-select>

    <mcc-fi-date-picker
      label="{{'cc.psd2.date-from' | translate}}"
      name="dateFrom"
      [mcForm]="mcForm"
      validRequiredFlg="true">
    </mcc-fi-date-picker>

    <mcc-fi-date-picker
      label="{{'cc.psd2.date-to' | translate}}"
      name="dateTo"
      [mcForm]="mcForm"
      validRequiredFlg="true">
    </mcc-fi-date-picker>


    <div *ngIf="badInputsFlg">
      <mcc-message [errorMsg]="badInputsErrorMsg"></mcc-message>
    </div>

    <div class="content-footer">
      <button class="btn btn-secondary move-right" (click)="actCancel()">
        <i class="far fa-times-circle"></i> {{importedFlg ? ('cc.common.close' | translate) : ('cc.common.edit.cancel' | translate)}}
      </button>
      <button class="btn btn-primary" (click)="actImport()" [disabled]="badInputsFlg">
        <i class="fas fa-file-export"></i> {{'cc.out-factoring.download' | translate}}
      </button>
    </div>

  </div>
</div>
<router-outlet></router-outlet>

