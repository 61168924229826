import {CsrPaymentDetails} from './csr-payment-details.model';
import {CsrPaymentDetailsListGENERATED} from '../_generated/csr-payment-details-list-generated.model';

export class CsrPaymentDetailsList extends CsrPaymentDetailsListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrPaymentDetailsList();
  }

  // ---------------------------------------------------------------------
}
