export const ICON_DROP_UP = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" style='transform: rotate(180deg)' xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_123_1201)">
<path d="M15.2899 12.29L12.6999 9.7C12.3099 9.31 11.6799 9.31 11.2899 9.7L8.69995 12.29C8.06995 12.92 8.51995 14 9.40995 14H14.5899C15.4799 14 15.9199 12.92 15.2899 12.29Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_123_1201">
<rect width="24" height="24" fill="white" transform="matrix(-1 0 0 -1 24 24)"/>
</clipPath>
</defs>
</svg>`
