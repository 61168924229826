<div class="form-group mcc-fi-select-option" [ngClass]="config && config.extraCssClass">
  <label *ngIf="label" for="{{name}}">{{label}}</label>
  <select class="form-control" id="{{name}}" [(ngModel)]="selectedValue" (change)="actSelectionChange()">
    <option *ngFor="let option of optionsKvm | keyvalue" [value]="option.key" [selected]="selectedValue == option.key">{{option.value}}</option>
  </select>
</div>
<!--<div ngbDropdown>-->
<!--  <div class="mc-dropdown-toggle text-uppercase" id="languageDropdown" ngbDropdownToggle>{{selected}}</div>-->
<!--  <div ngbDropdownMenu class="mc-dropdown-menu mc-dropdown-language" aria-labelledby="languageDropdown">-->
<!--    <a ngbDropdownItem-->
<!--       class="language-link"-->
<!--       *ngFor="let option of options"-->
<!--       (click)="selected = option"-->
<!--    >{{option}}</a>-->
<!--  </div>-->
<!--</div>-->
