<h3 class="page-title">{{'cc.common.blacklist' | translate}}</h3>

<div>
  <div class="actions-container">
    <span>{{'cc.contract.blacklist-management' | translate}}</span>
    <div class="actions-wrapper">
      <button class="system-action-btn" (click)="actShowAddEdit()"><i class="fas fa-plus-circle mc-add-new-user-icon"></i>{{'cc.out-factoring.add-consumer-to-blacklist' | translate}}</button>
    </div>
  </div>

    <mcc-modal1-wrapper [showMcb]="showAddEditModalMcb" *ngIf="showAddEditModalMcb.value">
      <mc-out-factoring-blacklist-add-edit-part [ebConsumerBlacklistId]="addEditConsumerId"
      (eventCanceled)="actCloseModal()" (eventSaved)="eventConsumerBlacklistSaved()"></mc-out-factoring-blacklist-add-edit-part>
    </mcc-modal1-wrapper>
  <mcc-modal1-wrapper *ngIf="showRemoveFromBlacklistMcb.value" [showMcb]="showRemoveFromBlacklistMcb" (eventCanceled)="actCloseModal()">
    <app-shared-blacklist-remove-part [ebConsumerBlacklistId]="removeConsumerBlacklist.id" (eventCanceled)="actCloseModal()" (eventRemoved)="eventRemovedFromBlacklist()"></app-shared-blacklist-remove-part>
  </mcc-modal1-wrapper>

</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [totalItemsCount]="totalItemsCount"
  [filterConfig]="filterConfig"
  (filterChanged)="onFilterChanged($event)"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
  [isBlacklist] = "true"
  [searchTooltip]="searchTooltip"
></mk-mat-table>

<router-outlet></router-outlet>

