<ng-template #content let-modal>

  <!--Modal header section-->
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'cc.common.view.select-entity' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div><!--Modal header -->

  <!--Modal body section-->
  <div class="modal-body">
    <!--MC Two FA section valign-container-->
    <div class="mc-select-entity-wrapper">
      <div class="container-fluid">

        <!--Search entity section-->
        <div class="row text-center">
          <!--Search entity input field-->
          <div class="form-label-group mc-search-entity-wrapper col-md-3">
            <input [(ngModel)]="searchEntityValue"
                   (ngModelChange)="onSearchEntity()"
                   type="text"
                   name="entity"
                   class="form-control"
                   placeholder="Entity"
                   id="entity"
            >
            <i *ngIf="searchEntityValue == ''" class="fa fa-search mc-search-entity-icon" aria-hidden="true"></i>

          </div><!--Search entity field /end-->

          <!--Filter by letter-->
          <div class="mc-letter-filter col-md-9 mb-3">
              <span *ngFor="let item of alphabetSelect; let i = index"
                    (click)="onSelectLetter(i)"
                    class="mc-latter-item"
                    [ngClass]="item['selected'] === true ? 'selected' : 'null'"
              >
                {{item['value']}}
              </span>
          </div><!--Filter by letter /end-->

        </div><!--Search entity /end-->
      </div><!--Container /end-->

      <!--Entities tree wrapper-->
      <div class="container-fluid mc-entities-tree-wrapper">
        <div class="col-md-12" [ngClass]="setOverflowClass()">
          <div class="row">

            <!--Notify message-->
            <div class="container">
              <div class="col-md-6 offset-md-3 position-relative">
                <mc-notify-message [type]="notifyMessageObj.type"
                                   [message]="notifyMessageObj.message"></mc-notify-message>
              </div>
            </div><!--Notify message /end-->

            <!--Looping from entities - wrapper-->
            <div class="col-md-4 mc-entity-list-wrapper mt-3"
                 [ngStyle]="(searchEntityValue !== '' || filterEntityValue !== '') && {'max-height': '80px'}"
                 *ngFor="let entity of entities; let index = index"
                 (click)="onChangeSelectedEntity()"
                  (dblclick)="onProceed()">

              <!--All entities-->
              <app-select-entity-list-wrapper *ngIf="searchEntityValue === '' && filterEntityValue === ''"
                                              [entity]="entity"
                                              class="col-md-3"></app-select-entity-list-wrapper>

              <!--Searched entities-->
              <app-select-entity-list-item *ngIf="searchEntityValue !== '' && filterEntityValue === ''" [child]="entity"
                                           class="col-md-3"></app-select-entity-list-item>

              <!--Filtered entities-->
              <app-select-entity-list-item *ngIf="filterEntityValue !== '' && searchEntityValue === ''" [child]="entity"
                                           class="col-md-3"></app-select-entity-list-item>
            </div>

          </div>
        </div>
      </div><!--Entities tree wrapper /end-->
    </div><!--Wrapper /end-->

  </div><!--Modal body /end-->
  <div class="modal-footer">
    <!--Proceed buttons section-->
    <button class="btn btn-outline-dark mc-proceed-button" (click)="onProceed();">
      {{'cc.common.view.proceed' | translate}} >
    </button>
  </div>
</ng-template>

<!--Header icon-->
<span [innerHTML]='iconHeaderDropdown | safeHtml'
      (click)="openModal(content)"
      *ngIf="openFromHeader"></span>

<!--Sidebar icon-->
<i class="fa fa-angle-right mc-arrow mc-arrow-sidebar"
   (click)="openModal(content)"
    *ngIf="!openFromHeader"></i>
