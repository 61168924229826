import {McBillingPayoutFileFeCtd} from './mc-billing-payout-file-fe-ctd.model';
import {McBillingPayoutFileFeCtdListGENERATED} from '../_generated/mc-billing-payout-file-fe-ctd-list-generated.model';

export class McBillingPayoutFileFeCtdList extends McBillingPayoutFileFeCtdListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingPayoutFileFeCtdList();
  }

  // ---------------------------------------------------------------------
}
