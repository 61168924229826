/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McConsumerBalanceTable3ConfigGENERATED} from '../_generated/mc-consumer-balance-table-3-config-generated.model';

// import {McConsumerBalanceGENERATED} from '../_generated/mc-consumer-balance-generated.model';

export class McConsumerBalanceTable3Config extends McConsumerBalanceTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colConsumerName', this.t('cc.consumers.view.consumer-name'), 'fldConsumerName()', '');
    this.addColumn('colEntityName', this.t('cc.factoring.transactions.entity-name'), 'entityName', 'entityName');
    this.addColumn('colAmount', this.t('cc.dashboard.balance'), 'balanceAmountFormatted', 'amount');
  }
}
