/*BbmTestObj*/
import {CsrPaymentDetailsGENERATED} from '../_generated/csr-payment-details-generated.model';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McGod} from '../extra/mc-god.service';

export class CsrPaymentDetails extends CsrPaymentDetailsGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrPaymentDetails();
  }

  // ---------------------------------------------------------------------

  public static override getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    // list.add(CsrPaymentDetailsGENERATED.TYPE_CD_CREDIT_CARD, McGod.t('cc.consumer.credit-card'));
    list.add(CsrPaymentDetailsGENERATED.TYPE_CD_DIRECT_DEBIT, McGod.t('cc.consumer.direct-debit'));
    list.add(CsrPaymentDetailsGENERATED.TYPE_CD_TRANSFER, McGod.t('cc.consumer.money-transfer'));
    // list.add(CsrPaymentDetailsGENERATED.TYPE_CD_CASH, McGod.t('cc.consumer.cash-onsite'));

    return list;
  }
}
