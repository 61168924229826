/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {PmTransactionTable3ConfigGENERATED} from '../_generated/pm-transaction-table-3-config-generated.model';

// import {PmTransactionGENERATED} from '../_generated/pm-transaction-generated.model';

export class PmTransactionTable3Config extends PmTransactionTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '').extraCssClass = 'table-col-width-50';
    this.addIdColumn();
    this.addColumn('colPaymentMethod', this.t('cc.common.create-type'), 'sourceTypeCd', '');
    this.addColumn('colMcEntity', this.t('cc.consumer.studio'), 'getStudioName()', 'idMcEntity');
    this.addColumn('colCsrConsumer', this.t('cc.my-factoring.consumer'), 'getConsumerName()', 'csrConsumer');
    this.addColumn('colDescription', this.t('cc.common.view.description'), 'description', 'description');
    this.addColumn('colMatchingId', this.t('cc.common.view.matching-id'), 'matchingId', 'matchingId');
    this.addColumn('colCreatedAt', this.t('cc.consumers.imports.created-at'), 'getCreatedDatetimeStr()', 'sysCreatedDatetime');
    this.addColumn('colPaymentDueDate', this.t('cc.transactions.payment-due-date'), 'getDueDateStr()', 'dueDate');
    this.addColumn('colAmount', this.t('cc.common.view.amount-due'), 'getAmountDue()', 'amount').extraCssClass = 'text-right';
    this.addColumn('colStatusCd', this.t('cc.common.view.status'), 'getIconLabel()', 'statusCd');
    }
}
