<div class="content-wrapper">
  <div class="content-header">
    <h1>Change next action date time</h1>
  </div>
  <div class="content-body">

    <mcc-message [errorMsg]="errorMsg"></mcc-message>


      <div class="row">
        <div class="col-md-12">
          <div class="info-container2 p-3">
          <mcc-row-title-description [widerTitleFlg]="true" title="NUMBER OF SELECTED PROCESS ITEMS:
" [description]="mcProcessItemList.getCount()"></mcc-row-title-description>
          <mcc-row-title-description title="MINIMAL DATE:"
                                     [widerTitleFlg]="true"     [description]="todayString"></mcc-row-title-description>
          </div>
        </div>
      </div>



    <div class="col-md-12 mt-3">
      <mcc-fi-date-picker label="Choose date"
                          name="ctrlNextActionDate"
                          [validRequiredFlg]="true"
                          [minDateInput]="today"
                          [mcForm]="mcForm">
      </mcc-fi-date-picker>
    </div>

  </div>
  <div class="content-footer">
    <button type="button" class="btn btn-secondary move-right" (click)="actCanceled()"><i class="far fa-times-circle"></i> Close</button>
    <button type="button" class="btn btn-primary" (click)="actSaved()"><i class="fas fa-save"></i> Save</button>
  </div>
</div>
