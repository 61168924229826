import {McProcessFlowCriteriaAttribute} from './mc-process-flow-criteria-attribute.model';
import {McProcessFlowCriteriaAttributeListGENERATED} from '../_generated/mc-process-flow-criteria-attribute-list-generated.model';
import {McValueLabelList} from '../_core/mc-value-label-list';

export class McProcessFlowCriteriaAttributeList extends McProcessFlowCriteriaAttributeListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessFlowCriteriaAttributeList();
  }
  // --------------------------------------------------------------------------
  getAttributeFromListByName(name: string) {
    return this.items.find( item => item.attributeName === name);
  }
}
