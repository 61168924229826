import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {
  EbFactoringService,
  McBillingStatementItem,
  McEntity2,
  McForm,
  McGod,
  McValueLabelList,
  McVatList
} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-out-billing-add-correction-part',
  templateUrl: './mc-out-billing-add-correction-part.component.html',
  encapsulation: ViewEncapsulation.None
})
export class McOutBillingAddCorrectionPartComponent implements OnInit {

  mcForm = new McForm();
  vatVll!: McValueLabelList;
  correctionTypeCdVll!: McValueLabelList;
  mcVatList = new McVatList();
  mcEntity = new McEntity2();
  errorMsg = '';
  saveButtonLabel = '';
  currency!: string;

  @Input() statementId!: number;
  @Input() mcStatementItem = new McBillingStatementItem();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter();
  @Input() currencySymbol!: string;

  /*Constructor*/
  constructor(private factoringService: EbFactoringService) {

  }

  /*On init*/
  ngOnInit() {
    this.vatVll = new McValueLabelList();
    this.correctionTypeCdVll = new McValueLabelList();

    this.correctionTypeCdVll = McBillingStatementItem.getCorrectionTypeCdVll();
    this.mcEntity.loadById(McGod.getLoggedEntityIdFromToken(), () => {
      this.mcVatList.loadByCountryId(this.mcEntity.countryId, () => {
        const newVatsList = new McVatList();
        const vats = this.mcVatList.items.filter(item => item.mcVatDateActive);
        newVatsList.items = vats;
        this.vatVll = newVatsList.toVll('vatRate', 'getVatLabelForVll()');
        this.vatVll.addItem({value: 0, label: 'No VAT'});
      }, () => {
        this.errorMsg = this.mcVatList.apiErrorMessage;
      });
    }, () => {
      this.errorMsg = this.mcEntity.apiErrorMessage;
    });

    this.saveButtonLabel = this.mcStatementItem.exists() ? 'cc.common.view.save' : 'cc.common.table.add';
    this.currency = this.factoringService.activeChildFactoring.mcCurrency.code;
  }

  actSave() {
    if (this.mcForm.isValid()) {

      this.mcStatementItem.title = this.mcForm.getValue('ctrlCorrectionName');
      this.mcStatementItem.description = this.mcForm.getValue('ctrlDescription');
      this.mcStatementItem.amount = this.mcForm.getValue('ctrlAmount');
      this.mcStatementItem.vatPercentageForCorrection = this.mcForm.getValue('ctrlVatRate');
      const vatToMultiply = this.mcStatementItem.vatPercentageForCorrection / 100;
      this.mcStatementItem.vatForBillingStatementItem = Number(this.mcStatementItem.amount) * Number(vatToMultiply);
      this.mcStatementItem.correctionTypeCd = this.mcForm.getValue('ctrCorrectionType');
      this.mcStatementItem.typeCd = McBillingStatementItem.TYPE_CD_CORRECTION;
      this.mcStatementItem.vatXAmount = this.mcStatementItem.correctionTypeCd === McBillingStatementItem.CORRECTION_TYPE_CD_CHARGE ?
        Number(this.mcStatementItem.amount) + Number(this.mcStatementItem.vatForBillingStatementItem) : Number(this.mcStatementItem.amount) - Number(this.mcStatementItem.vatForBillingStatementItem);
      this.mcStatementItem.mcBillingStatementId = this.statementId;
      this.mcStatementItem.statusCd = McBillingStatementItem.STATUS_CD_ACTIVE;

      console.log('STATEMENT ITEM', this.mcStatementItem);

      this.mcStatementItem.save(() => {
        this.eventSaved.emit();
      }, () => {
        this.errorMsg = this.mcStatementItem.apiErrorMessage;
      });
    }
  }

  actCancel() {
    this.eventCanceled.emit();
  }

}
