import {PpInvoice} from './pp-invoice.model';
import {PpInvoiceListGENERATED} from '../_generated/pp-invoice-list-generated.model';

export class PpInvoiceList extends PpInvoiceListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new PpInvoiceList();
  }

  // ---------------------------------------------------------------------
}
