<div class="content-wrapper">
  <div class="content-header">
    <div class="row p-0 m-0 d-flex flex-row align-items-center">
      <h3 class="pr-2 pt-1" style="text-transform: capitalize">Entities using pricelist '{{ mcPriceList.title }}'</h3>
    </div>
  </div>
  <div class="content-body">
    <mk-mat-table
      [isLoading]="isLoading"
      [items]="mcEntitiesUsingPricelist.items"
      [config]="tableConfig"
      [totalItemsCount]="mcEntitiesUsingPricelist.totalItemsCount"
      [itemsPerPage]="mcEntitiesUsingPricelist.pageItemsPerPageCount"
      (filterChanged)="onFilterChanged($event)">
      >
    </mk-mat-table>


  </div>
  <div class="content-footer">
    <mcc-button class="move-right" extraCssClasses="btn btn-secondary move-right" (eventClicked)="actClose()" label="Close"></mcc-button>
  </div>

</div>
