/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CsrConsumerImportFilter} from '../models/csr-consumer-import-filter.model';

export class CsrConsumerImportFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrConsumerImportFilter {
    console.error('MTCN-ERROR: Not Implemented: CsrConsumerImportFilter::createNewInstance(). Add this method to final class and return new CsrConsumerImportFilter();');
      throw new Error('Cannot CsrConsumerImportFilter::createNewInstance(). Add this method to final class and return new CsrConsumerImportFilter();');
  }

}
