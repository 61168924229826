import {EbTransactionAdjustmentFilterGENERATED} from '../_generated/eb-transaction-adjustment-filter-generated.model';

export class EbTransactionAdjustmentFilter extends EbTransactionAdjustmentFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbTransactionAdjustmentFilter();
  }

  // ---------------------------------------------------------------------
}
