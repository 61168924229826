import {McCountryFilterGENERATED} from '../_generated/mc-country-filter-generated.model';

export class McCountryFilter extends McCountryFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McCountryFilter();
  }

  // ---------------------------------------------------------------------
}
