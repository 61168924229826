/*Filter configuration
* property name: filter component will find filter setup by name (page)
* Property setup.isDisabled: filter button will be disabled (for demo purposes)*/

export const filterConfig = [
  {
    id: 1,
    name: "entity",
    isDisabled: false,
    filterByRoute: true,
    filterList: [{
      id: 10,
      type: 1,
      value: "",
      name: "search",
      label: "cc.common.search",
      display: 1,
      inputType: "text",
      placeholder: "cc.common.view.entity",
      required: false
    },
      {
        id: 11,
        type: 2,
        name: "active",
        label: "cc.common.view.active",
        display: 2,
        value: "",
        checked: false
      },
      {
        id: 12,
        type: 2,
        name: "inactive",
        label: "cc.common.view.inactive",
        display: 2,
        value: "",
        checked: false
      },
      // {
      //   id: 13,
      //   type: 3,
      //   name: 'levels',
      //   label: 'All levels',
      //   display: 1,
      //   value: 'sss',
      //   list: ['Level A', 'Level B', 'Level C']
      // }
    ]
  },
  {
    id: 10,
    name: "user",
    isDisabled: false,
    filterByRoute: true,
    filterList: [{
      id: 1,
      type: 1,
      value: "",
      name: "search",
      label: "cc.common.search",
      display: 1,
      inputType: "text",
      placeholder: "cc.users.view.username-or-email",
      required: false
    },
      {
        id: 11,
        type: 2,
        name: "active",
        label: "cc.common.view.active",
        display: 2,
        value: "",
        checked: false
      },
      {
        id: 12,
        type: 2,
        name: "inactive",
        label: "cc.common.view.inactive",
        display: 2,
        value: "",
        checked: false
      },
      {
        id: 13,
        type: 2,
        name: "pending",
        label: "cc.common.view.pending",
        display: 2,
        value: "",
        checked: false
      },
      {
        id: 14,
        type: 2,
        name: "draft",
        label: "cc.common.view.draft",
        display: 2,
        value: "",
        checked: false
      },
      // {
      //   id: 13,
      //   type: 3,
      //   name: "levels",
      //   label: "levels",
      //   display: 1,
      //   value: "Select levels",
      //   list: ["Level A", "Level B", "Level C"]
      // },
      // {
      //   id: 15,
      //   type: 4,
      //   name: "date",
      //   label: "Date",
      //   display: 1,
      //   value: "Select date",
      //   list: [
      //     {name: "Today", value: ""},
      //     {name: "One day ago", value: ""},
      //     {name: "Last 3 days", value: ""},
      //     {name: "Last week", value: ""},
      //     {name: "Last month", value: ""},
      //     {name: "Last 3 months", value: ""},
      //     {name: "Custom date", value: ""}
      //   ]
      // }
    ]
  },
  {
    id: 1,
    name: "role",
    isDisabled: false,
    filterByRoute: true,
    filterList: [{
      id: 10,
      type: 1,
      value: "",
      name: "search",
      label: "cc.common.search",
      display: 1,
      inputType: "text",
      placeholder: "cc.roles.view.role-name",
      required: false
    },
      {
        id: 11,
        type: 2,
        name: "active",
        label: "cc.common.view.active",
        display: 2,
        value: "",
        checked: false
      },
      {
        id: 12,
        type: 2,
        name: "inactive",
        label: "cc.common.view.inactive",
        display: 2,
        value: "",
        checked: false
      },
      // {
      //   id: 13,
      //   type: 3,
      //   name: 'levels',
      //   label: 'levels',
      //   display: 1,
      //   value: 'Select levels',
      //   list: ['Level A', 'Level B', 'Level C']
      // }
    ]
  },
  {
    id: 11,
    name: "product",
    isDisabled: false,
    filterByRoute: true,
    filterList: [{
      id: 1,
      type: 1,
      value: "",
      name: "search",
      label: "cc.common.search",
      display: 1,
      inputType: "text",
      placeholder: "cc.products.view.product-name",
      required: false
    },
      // {
      //   id: 2,
      //   type: 2,
      //   name: "active",
      //   label: "cc.common.view.active",
      //   display: 2,
      //   value: "",
      //   checked: false
      // },
      // {
      //   id: 3,
      //   type: 2,
      //   name: "inactive",
      //   label: "cc.common.view.inactive",
      //   display: 2,
      //   value: "",
      //   checked: false
      // },
      // {
      //   id: 4,
      //   type: 3,
      //   name: 'channel',
      //   label: 'Channel',
      //   display: 1,
      //   value: '',
      //   selectedValue: 'Select Channel',
      //   list: ['Channel A', 'Channel B', 'Channel C']
      // },
      // {
      //   id: 5,
      //   type: 3,
      //   name: 'price',
      //   label: 'Price',
      //   display: 1,
      //   value: '',
      //   list: ['Price A', 'Price B', 'Price C']
      // },
      // {
      //   id: 6,
      //   type: 3,
      //   name: 'available',
      //   label: 'Available',
      //   display: 1,
      //   value: '',
      //   list: ['Available A', 'Available B', 'Available C']
      // }
    ]
  },
  {
    id: 12,
    name: "jobs",
    isDisabled: true,
    filterByRoute: true,
    filterList: [{
      id: 1,
      type: 1,
      value: "",
      name: "search",
      label: "cc.common.search",
      display: 1,
      inputType: "text",
      placeholder: "cc.jobs.view.job-name",
      required: false
    }]
  },
  {
    id: 13,
    name: "consumers",
    isDisabled: false,
    filterByRoute: true,
    filterList: [{
      id: 1,
      type: 1,
      value: "",
      name: "search",
      label: "cc.common.search",
      display: 1,
      inputType: "text",
      placeholder: "cc.consumers.view.consumer-name",
      required: false
    },
    {
      id: 2,
      type: 2,
      name: "active",
      label: "cc.common.view.active",
      display: 2,
      value: "",
      checked: false
    },
    {
      id: 3,
      type: 2,
      name: "inactive",
      label: "cc.common.view.inactive",
      display: 2,
      value: "",
      checked: false
    },
    // {
    //   id: 3,
    //   type: 4,
    //   name: "date",
    //   label: "cc.common.view.date",
    //   display: 1,
    //   value: "Select date",
    //   list: [
    //     {name: "cc.common.date.today", value: ""},
    //     {name: "cc.common.date.one-day-ago", value: ""},
    //     {name: "cc.common.date.last-3-days", value: ""},
    //     {name: "cc.common.date.last-week", value: ""},
    //     {name: "cc.common.date.last-month", value: ""},
    //     {name: "cc.common.date.last-3 months", value: ""},
    //     {name: "cc.common.date.custom-date", value: ""}
    //   ]},
    //   {
    //     id: 4,
    //     type: 5,
    //     name: "amount due",
    //     label: "amount due",
    //     display: 1,
    //     value: "",
    //   }
    ]
  },
  {
    id: 14,
    name: "consumers_history",
    isDisabled: true,
    filterByRoute: true,
    filterList: [{
      id: 1,
      type: 1,
      value: "",
      name: "search",
      display: 1,
      label: "cc.common.search",
      inputType: "text",
      placeholder: "cc.common.view.default-value",
      required: false
    },
    {
      id: 2,
      type: 2,
      name: "active",
      label: "cc.common.view.active",
      display: 2,
      value: "",
      checked: false
    },
    // {
    //   id: 2,
    //   type: 4,
    //   name: "date",
    //   label: "cc.common.view.date",
    //   display: 1,
    //   value: "Select date",
    //   list: [
    //     {name: "cc.common.date.today", value: ""},
    //     {name: "cc.common.date.one-day-ago", value: ""},
    //     {name: "cc.common.date.last-3-days", value: ""},
    //     {name: "cc.common.date.last-week", value: ""},
    //     {name: "cc.common.date.last-month", value: ""},
    //     {name: "cc.common.date.last-3 months", value: ""},
    //     {name: "cc.common.date.custom-date", value: ""}]
    //   },
    //   {
    //     id: 3,
    //     type: 5,
    //     name: "amount due",
    //     label: "cc.common.view.amount-due",
    //     display: 1,
    //     value: "",
    //   }
    ]
  },
  {
    id: 15,
    name: "invoiceOverview",
    filterByRoute: false,
    isDisabled: false,
    filterList: [
    {
      id: 1,
      type: 1,
      value: "",
      name: "search",
      label: "cc.common.search",
      inputType: "text",
      placeholder: "cc.common.search",
      required: false
    },
    {
      id: 2,
      type: 2,
      name: "scheduled",
      label: "cc.common.view.scheduled",
      display: 2,
      value: "",
      checked: false
    },
    {
      id: 3,
      type: 2,
      name: "draft",
      label: "cc.common.view.draft",
      display: 2,
      value: "",
      checked: false
    },
    {
      id: 4,
      type: 2,
      name: "invoiced",
      label: "cc.common.view.invoiced",
      display: 2,
      value: "",
      checked: false
    },
    {
      id: 5,
      type: 2,
      name: "print",
      label: "cc.common.print",
      display: 2,
      value: "",
      checked: false
    }]
  },
  {
    id: 16,
    name: "invoices",
    isDisabled: false,
    filterByRoute: true,
    filterList: [{
      id: 1,
      type: 1,
      value: "",
      name: "search",
      label: "cc.common.search",
      display: 1,
      inputType: "text",
      placeholder: "cc.common.search",
      required: false
    },
      {
        id: 4,
        type: 2,
        name: "SCHEDULED",
        label: "cc.common.view.scheduled",
        display: 2,
        value: "",
        checked: false
      },
      {
        id: 5,
        type: 2,
        name: "DRAFT",
        label: "cc.common.view.draft",
        display: 2,
        value: "",
        checked: false
      },
      {
        id: 6,
        type: 2,
        name: "INVOICED",
        label: "cc.common.view.invoiced",
        display: 2,
        value: "",
        checked: false
      },
      {
        id: 7,
        type: 2,
        name: "PRINT",
        label: "cc.common.print",
        display: 2,
        value: "",
        checked: false
      },
      {
        id: 8,
        type: 2,
        name: "CANCELED",
        label: "cc.common.view.canceled",
        display: 2,
        value: "",
        checked: false
      }
      ]
  },
  {
    id: 17,
    name: "invoicesProducts",
    isDisabled: false,
    filterByRoute: false,
    filterList: [{
      id: 1,
      type: 1,
      value: "",
      name: "search",
      display: 1,
      label: "cc.common.search",
      inputType: "text",
      placeholder: "Product name",
      required: false
    }]
  },
  {
    id: 18,
    name: "consumersImportMappings",
    isDisabled: false,
    filterByRoute: false,
    filterList: [{
      id: 1,
      type: 1,
      value: "",
      name: "search",
      display: 1,
      label: "cc.common.search",
      inputType: "text",
      placeholder: "search...",
      required: false
    }]
  }
];
