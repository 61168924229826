import { Component, OnInit } from '@angular/core';
import {CsrConsumer, McGod} from "@miticon-ui/mc-core";
import {ActivatedRoute, Router} from "@angular/router";
import {ICON_EDIT} from "../../../../assets/media/svg_icons/icon-edit";
import moment from "moment";

@Component({
  selector: 'lib-mc-consumer-consumer-360-bank-info-part',
  templateUrl: './mc-consumer-consumer-360-bank-info-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-bank-info-part.component.scss']
})
export class McConsumerConsumer360BankInfoPartComponent implements OnInit {
  iconEdit = ICON_EDIT;
  csrConsumer: CsrConsumer =  new CsrConsumer();
  previousBankAccounts: any;
  primaryBankAccount: any;
  noBankAccounts = false;

  constructor(private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.actLoad();
  }

  actLoad() {
    this.route.paramMap.subscribe((res) => {
      this.csrConsumer.apiLoadingFlg = true
      // @ts-ignore
      this.csrConsumer.loadById(+res.get('id'), () => {
        this.csrConsumer.apiLoadingFlg = false;
        if(this.csrConsumer.bankAccounts && this.csrConsumer.bankAccounts.items.length > 0) {
          this.noBankAccounts = false;
          this.primaryBankAccount = this.csrConsumer.bankAccounts.items.find((account: any) => account.flgPrimary === true);
          this.previousBankAccounts = this.csrConsumer.bankAccounts.items.filter((account: any) => account !== this.primaryBankAccount);
        } else this.noBankAccounts = true;
      });
    });
  }

  getFormattedDate(date: any) {
    return date ? moment(date).format('DD.MM.YYYY') : '-';
  }

  goToEditConsumer() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/${this.csrConsumer.id}/edit-360`]);
  }
}
