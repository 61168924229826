/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {PpOrderItemTable3ConfigGENERATED} from '../_generated/pp-order-item-table-3-config-generated.model';

// import {PpOrderItemGENERATED} from '../_generated/pp-order-item-generated.model';

export class PpOrderItemTable3Config extends PpOrderItemTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colIdPpOrder', 'ID of the order', 'idPpOrder', 'idPpOrder');
    this.addColumn('colIdPpProduct', 'ID of the product', 'idPpProduct', 'idPpProduct');
    this.addColumn('colName', 'Name of the order item', 'name', 'name');
    this.addColumn('colNote', 'Note', 'note', 'note');
    this.addColumn('colQuantity', 'Quantity', 'quantity', 'quantity');
    this.addColumn('colSorterId', 'Sorter ID', 'sorterId', 'sorterId');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');
    this.addColumn('colUnitPrice', 'Unit price', 'unitPrice', 'unitPrice');
    this.addColumn('colVat', 'VAT', 'vat', 'vat');
    this.addColumn('colVersion', 'Version', 'version', 'version');


  }
}
