import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { McForm, McGod, EbFactoring} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-factoring-select-contract-type-part',
  templateUrl: './app-shared-factoring-select-contract-type-part.component.html',
})
export class AppSharedFactoringSelectContractTypePartComponent implements OnInit {

  mcForm = new McForm();
  optionsVlm = new Map<string, string>();
  mcGod = McGod.getInstance();
  nextDisableFlg = true;
  @Output() prevEmit = new EventEmitter();
  @Output() nextEmit = new EventEmitter();

  ngOnInit() {
    this.optionsVlm.set(EbFactoring.TYPE_CD_FACTORING, this.mcGod.t('cc.contract.factoring-contract'));
    this.optionsVlm.set(EbFactoring.TYPE_CD_SERVICE, this.mcGod.t('cc.contracts.service-contract'));
  }

  contractTypeValueChanged(contractType: string) {
    this.nextDisableFlg = false;
  }

  actPrev() {
    this.prevEmit.emit();
  }

  actNext() {
    const contractType = this.mcForm.getValue('contractType');
    this.nextEmit.emit(contractType);
  }
}
