import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {McForm} from "@miticon-ui/mc-core";
import {Subscription} from "rxjs";

@Component({
  selector: "mcc-mc-form-message",
  templateUrl: "./mcc-mc-form-message.component.html",
  styleUrls: ["./mcc-mc-form-message.component.scss"]
})
export class MccMcFormMessageComponent implements OnInit, OnDestroy {

  @Input() mcFormValidFlg = true;
  formValidationTriggerSubscription!: Subscription;

  @Input() mcForm!: McForm;
  @Input() messageType!: "info" | "warning" | "error";
  @Input() messageText!: string;
  @Input() showInputLabelsInMessageFlg = false;
  @Input() extraCssClasses = '';
  constructor() {
  }

  ngOnInit() {
    if (this.mcForm) {
      this.formValidationTriggerSubscription = this.mcForm.getValidationTriggerObservable().subscribe(() => {
        this.setMcFormValidFlg(this.mcForm.formGroup.valid);
      });
    }
  }

  ngOnDestroy() {
    if (this.formValidationTriggerSubscription) {
      this.formValidationTriggerSubscription.unsubscribe();
    }
  }

  setMcFormValidFlg(flg: boolean) {
    this.mcFormValidFlg = flg;
    if (!this.mcFormValidFlg) {
      const invalidControls = this.mcForm.getInvalidControls();
      let message = "";
      if (this.showInputLabelsInMessageFlg) {
        if (invalidControls.length > 3) {
          message = "Please make sure all required fields are filled out correctly";
        } else {
          message = "Please make sure the following fields are filled out correctly: ";
          invalidControls.forEach((item, index) => {
            message = message.concat(item.value.label);
            if (invalidControls.length > 1 && index < invalidControls.length - 1) {
              message = message.concat(", ");
            }
          });
        }
      } else {
        message = "Please make sure all required fields are filled out correctly";
      }
      this.messageText = message;
    }
  }

}
