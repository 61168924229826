<app-shared-go-back icon="fas fa-arrow-left" title="{{'cc.incoming-records.back-to-incoming-records' | translate}}"></app-shared-go-back>

<lib-mc-loader [showLoader]="!ebPaymentReport.apiSuccessFlg || pmTransactionList.apiLoadingFlg"></lib-mc-loader>
<div class="actions-container mb-3">
  <div class="d-flex align-items-center">
      <span>{{'cc.incoming-payments.potential-transaction-matches' | translate}} </span>
  </div>

  <div ngbDropdown class="actions-wrapper" *ngIf="ebPaymentReport.isStatusINVALID() || ebPaymentReport.isStatusUNMATCHED()">
    <button ngbDropdownToggle class="system-action-btn dark-system-action-btn"><i class="fas fa-pen mc-add-new-user-icon"></i> {{'cc.common.view.edit' | translate}}
    </button>
    <div class="dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <button ngbDropdownItem *ngIf="ebPaymentReport.isStatusUNMATCHED()" (click)="markAsInvalid()">
        <i class="fa fa-times"></i> {{'cc.incoming-payments.mark-as-invalid' | translate}}
      </button>
      <button ngbDropdownItem *ngIf="ebPaymentReport.isStatusINVALID()" (click)="markAsInvalid()">
        <i class="fas fa-undo"></i> {{'cc.incoming-payments.undo-mark-as-invalid' | translate}}
      </button>
    </div>
  </div>
</div>
<div class="card-wrapper">
  <app-shared-card header="Payment details" class="mb-4">
    <mcc-row-title-description extraCssClasses="card-control" [title]="'cc.jobs.edit.type' | translate" [description]="ebPaymentReport.getPaymentType()"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" [title]="'cc.incoming-payments.payer' | translate" [description]="ebPaymentReport.getDebtorName()"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" [title]="'cc.common.iban' | translate" [description]="ebPaymentReport.getIban()"></mcc-row-title-description>
    <mcc-row-title-description
      extraCssClasses="card-control"
      [title]="'cc.incoming-payments.payment-date' | translate"
      [description]="ebPaymentReport && ebPaymentReport.extractedDetails ? ebPaymentReport.getTransactionDateTime() : ''"></mcc-row-title-description>

    <mcc-row-title-description extraCssClasses="card-control" [title]="'cc.transactions.payment-amount' | translate" [description]="ebPaymentReport.getAmount()"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" [title]="'cc.common.view.description' | translate" [description]="ebPaymentReport.extractedDetails.description"></mcc-row-title-description>
  </app-shared-card>
</div>
<h3 class="page-title">{{'cc.incoming-payments.search-for-potential-matches' | translate}}</h3>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="pmTransactionList.items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  [totalItemsCount]="pmTransactionList.totalItemsCount"
  [itemsPerPage]="pmTransactionList.pageItemsPerPageCount"
  (filterChanged)="onFilterChanged($event)"
  (eventColumnButtonAction)="onColumnButtonAction($event)"
  (actionMkMatMenuItems)="onMenuItemAction($event)"
  (selectedItems)="onItemsSelected($event)"
  [mkMatMenuActionItems]="mkMatMenuActionItems"
  [searchTooltip]="searchTooltip">
</mk-mat-table>

<mcc-modal1-wrapper *ngIf="showConsumerProfileMcb.value" [showMcb]="showConsumerProfileMcb" modalSizeCd="full">
  <mc-consumer-consumer-profile-part
    (eventClosed)="showConsumerProfileMcb.setFalse()"
    [csrConsumerId]="consumerId"
    [currencyCode]="actionPmTransaction.mcFactoring.mcCurrency.code">
  </mc-consumer-consumer-profile-part>
</mcc-modal1-wrapper>
