/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {CmnEmailTable3ConfigGENERATED} from '../_generated/cmn-email-table-3-config-generated.model';

// import {CmnEmailGENERATED} from '../_generated/cmn-email-generated.model';

export class CmnEmailTable3Config extends CmnEmailTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colAccountId', 'Account Id', 'accountId', 'accountId');
    this.addColumn('colBccEmails', 'Email addresses in Bcc', 'bccEmails', 'bccEmails');
    this.addColumn('colBody', 'Body', 'body', 'body');
    this.addColumn('colCcEmails', 'Email addresses in Cc', 'ccEmails', 'ccEmails');
    this.addColumn('colLog', 'Sending email error log', 'log', 'log');
    this.addColumn('colRetryCount', 'Retry count', 'retryCount', 'retryCount');
    this.addColumn('colSentDatetime', 'The sent time', 'sentDatetime', 'sentDatetime');
    this.addColumn('colStatusCd', 'status', 'statusCd', 'statusCd');
    this.addColumn('colSubject', 'Subject', 'subject', 'subject');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colToEmails', 'Email addresses in To', 'toEmails', 'toEmails');


  }
}
