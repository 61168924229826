/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McBillingPayoutTableItemFilter} from '../models/mc-billing-payout-table-item-filter.model';

export class McBillingPayoutTableItemFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingPayoutTableItemFilter {
    console.error('MTCN-ERROR: Not Implemented: McBillingPayoutTableItemFilter::createNewInstance(). Add this method to final class and return new McBillingPayoutTableItemFilter();');
      throw new Error('Cannot McBillingPayoutTableItemFilter::createNewInstance(). Add this method to final class and return new McBillingPayoutTableItemFilter();');
  }

}
