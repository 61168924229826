import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {McMapping, McMappingList} from '@miticon-ui/mc-core';

@Component({
  selector: 'lib-mc-choose-mapping',
  templateUrl: './mc-choose-mapping.component.html',
})
export class McChooseMappingComponent implements OnInit {

  @Input() mappingList!: McMappingList;
  // @Input() chosenMapping: McMapping;
  @Output() eventMappingSelected = new EventEmitter<McMapping>();
  private modalOpenSubscription: any;
  showLoader!: boolean;
  mappingFormGroup!: FormGroup;
  message!: string;
  type!: string;
  enabled!: boolean;

  constructor(private modalService: NgbModal,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
   this.mappingFormGroup = this.formBuilder.group({
     mappingRadio: ['']
   });
  }

  // Modal
  openChooseMappingModal(modalName: any) {
    if (this.mappingList.items.length === 0) {
      this.message = 'There are no saved mappings';
      this.type = 'error';
      this.enabled = false;
    } else {
      this.enabled = true;
    }
    this.modalOpenSubscription = this.modalService.open(modalName, {
      backdrop: 'static',
      centered: true,
      windowClass: 'mc-entity-local-attributes-modal',
      beforeDismiss: () => {
        this.message = '';
        this.type = '';
        this.enabled = true;
        this.mappingFormGroup.get('mappingRadio')?.setValue('');
        return true;
      }
    });
  }

  closeModal() {
    this.modalOpenSubscription.dismiss();
  }

  applyMapping() {
    this.eventMappingSelected.emit(this.mappingFormGroup.get('mappingRadio')?.value);
    this.modalOpenSubscription.dismiss();
  }
}
