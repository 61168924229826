<ng-container *ngIf="isVisible()">
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-wrapper">
    <lib-mc-loader [showLoader]="ebSepaFile.apiLoadingFlg"></lib-mc-loader>

    <div *ngIf="(!ebSepaFile.apiLoadingFlg)">

      <!--THIS CONTAINER IS MANDATORY-->
      <div class="content-header">
        <h4 class="modal-title">{{'cc.my-factoring.pre-report-for' | translate}} {{webFile.filename}}</h4>
      </div>

      <!--THIS CONTAINER IS MANDATORY-->
      <div class="content-body">

        <div class="row mc-g-height-30 mc-g-padding-x-27">
          <div class="col-md-12 position-relative" *ngIf="notifyMessage?.type">
            <mc-notify-message [type]="notifyMessage?.type"
                               [message]="notifyMessage?.message"
            >
            </mc-notify-message>
          </div>
        </div>

        <!--Download pre-report-->
        <!--<div class="col-md-12 text-right my-2">
          <span class="mc-text-click text-capitalize" (click)="onDownloadReporting()">Download Pre-report summary</span>
        </div>-->


        <div *ngIf="ebSepaFileReport.reportForFactoringFlg">      <!-- Factoring -->


          <div class="col-md-12 mb-5">
            <table class="table">

              <!--First table-->
              <tbody>

              <!--Accepted for factoring-->
              <tr>
                <td
                  class="p-0 mc-border-top border-right text-left pl-3">{{'cc.my-factoring.accepted-for-factoring' | translate}}</td>
                <td
                  class="p-0 mc-border-top border-right text-right pr-3">{{ebSepaFileReport.ftAcceptedForFactoringA | number:'.2-2'}}
                  %
                </td>
                <td
                  class="p-0 mc-border-top border-right text-right pr-3">{{ebSepaFileReport.ftAcceptedForFactoringBLoc}}
                </td>
                <td
                  class="p-0 mc-border-top text-right pr-3">{{ebSepaFileReport.ftAcceptedForFactoringCLoc}}
                </td>
              </tr>

              <!--Not accepted for factoring-->
              <tr>
                <td
                  class="p-0 border-right text-left pl-3">{{'cc.my-factoring.not-accepted-for-factoring' | translate}}</td>
                <td
                  class="p-0 border-right text-right pr-3">{{ebSepaFileReport.ftNotAcceptedForFactoringA | number:'.2-2'}}
                  %
                </td>
                <td
                  class="p-0 border-right text-right pr-3">{{ebSepaFileReport.ftNotAcceptedForFactoringBLoc}}
                </td>
                <td class="p-0 text-right pr-3">{{ebSepaFileReport.ftNotAcceptedForFactoringCLoc}}</td>
              </tr>

              <!--Immediately payable balance-->
              <tr>
                <td
                  class="p-0 border-right text-left pl-3">{{'cc.my-factoring.immediately-payable-balance' | translate}}</td>
                <td
                  class="p-0 border-right text-right pr-3">{{ebSepaFileReport.ftImmediatelyPayableBalanceA | number:'.2-2'}}
                  %
                </td>
                <td
                  class="p-0 border-right text-right pr-3">{{ebSepaFileReport.ftImmediatelyPayableBalanceBLoc}}
                </td>
                <td class="p-0 text-right pr-3">{{ebSepaFileReport.ftImmediatelyPayableBalanceCLoc}}</td>
              </tr>

              <!--Later payable balance-->
              <tr>
                <td
                  class="p-0 mc-border-bottom border-right text-left pl-3">{{'cc.my-factoring.later-payable-balance' | translate}}</td>
                <td
                  class="p-0 mc-border-bottom border-right text-right pr-3">{{ebSepaFileReport.ftLaterPayableBalanceA | number:'.2-2'}}
                  %
                </td>
                <td
                  class="p-0 mc-border-bottom border-right text-right pr-3">{{ebSepaFileReport.ftLaterPayableBalanceBLoc}}
                </td>
                <td
                  class="p-0 mc-border-bottom text-right pr-3">{{ebSepaFileReport.ftLaterPayableBalanceCLoc}}
                </td>
              </tr>
              </tbody>

              <!--Second table-->
              <tbody>
              <tr>
                <td colspan="4" class="mc-reporting-table-td-gap"></td>
              </tr>
              <!--Factoring fee-->
              <tr>
                <td
                  class="p-0 mc-border-top border-right text-left pl-3">{{'cc.my-factoring.factoring-fee' | translate}}</td>
                <td
                  class="p-0 mc-border-top border-right text-right pr-3">{{ebSepaFileReport.stFactoringFeeA | number:'.2-2'}}
                  %
                </td>
                <td
                  class="p-0 mc-border-top border-right text-right pr-3">{{ebSepaFileReport.stFactoringFeeBLoc}}
                </td>
                <td class="p-0 mc-border-top text-right pr-3">{{ebSepaFileReport.stFactoringFeeCLoc}}</td>
              </tr>

              <!--Plus VAT-->
              <tr>
                <td
                  class="p-0 border-right text-left pl-3">{{'cc.my-factoring.plus-vat-19' | translate}}</td>
                <td class="p-0 border-right text-right pr-3"></td>
                <td class="p-0 border-right text-right pr-3"></td>
                <td class="p-0 text-right pr-3">+ {{ebSepaFileReport.stPlusVatC | number:'.2-2'}}</td>
              </tr>

              <!--Total factoring fee to be deducted-->
              <tr>
                <td
                  class="p-0 mc-border-bottom border-right text-left pl-3">{{'cc.my-factoring.total-fees-to-be-deducted' | translate}}</td>
                <td class="p-0 mc-border-bottom border-right text-right pr-3"></td>
                <td class="p-0 mc-border-bottom border-right text-right pr-3"></td>
                <td
                  class="p-0 mc-border-bottom text-right pr-3">{{ebSepaFileReport.stTotalFactoringFeeToBeDeductedCLoc}}
                </td>
              </tr>
              </tbody>

              <!--Third table-->
              <tbody class="mt-3">
              <tr>
                <td colspan="4" class="mc-reporting-table-td-gap"></td>
              </tr>
              <!--Immediately payable balance-->
              <tr>
                <td
                  class="p-0 mc-border-top border-right text-left pl-3">{{'cc.my-factoring.immediately-payable-balance' | translate}}</td>
                <td
                  class="p-0 mc-border-top border-right text-right pr-3">{{ebSepaFileReport.ttImmediatelyPayableBalanceA | number:'.2-2'}}
                  %
                </td>
                <td
                  class="p-0 mc-border-top border-right text-right pr-3">{{ebSepaFileReport.ttImmediatelyPayableBalanceBLoc}}
                </td>
                <td
                  class="p-0 mc-border-top text-right pr-3">{{ebSepaFileReport.ttImmediatelyPayableBalanceCLoc}}
                </td>
              </tr>

              <!--Factoring fee-->
              <tr>
                <td
                  class="p-0 border-right text-left pl-3">{{'cc.my-factoring.factoring-fee-plus-19%-vat' | translate}}</td>
                <td class="p-0 border-right text-right pr-3"></td>
                <!--{{reportingCalculations.ttFactoringFeeA | number:'.2-2'}}%-->
                <td class="p-0 border-right text-right pr-3"></td>
                <!--{{reportingCalculations.ttFactoringFeeB | number:'.2-2'}}&euro;-->
                <td class="p-0 text-right pr-3">- {{ebSepaFileReport.ttFactoringFeeCLoc | number:'.2-2'}}</td>
              </tr>

              <!--Payable balance-->
              <tr>
                <td
                  class="p-0 mc-border-bottom border-right text-left pl-3 font-weight-bold">{{'cc.my-factoring.estimated-payable-balance' | translate}}</td>
                <td class="p-0 mc-border-bottom border-right text-right pr-3"></td>
                <td class="p-0 mc-border-bottom border-right text-right pr-3"></td>
                <td
                  class="p-0 mc-border-bottom font-weight-bold text-right pr-3">{{ebSepaFileReport.ttPayableBalanceCLoc}}
                </td>
              </tr>
              </tbody><!--Third table /end-->
            </table>
          </div>

          <!--Download transactions csv
          <div class="col-md-12 text-right mb-n3">
            <span class="mc-text-click text-capitalize"
                  (click)="onDownloadTransactionCsv()">Export and download Pre-report table</span>
          </div> -->

          <!--Transaction table-->
          <div class="col-md-12" *ngIf="transactionList.apiSuccessFlg">
            <mcc-fi-table-3 [table3Config]="transactionTableConfig" [objectList]="transactionList"
                            (eventPaginationOrSortChanged)="getTransactionsBySepaFileId()"></mcc-fi-table-3>
          </div>
        </div>

        <!-- -------------------------------------------------------------------------------------------- -->

                          <!-- NON FACTORING --->

        <div *ngIf="!ebSepaFileReport.reportForFactoringFlg">

          <div class="col-md-12 mb-5">
            <table class="table">
              <!--First table-->
              <tbody>
              <!--Accepted for non-factoring-->
              <tr>
                <td
                  class="p-0 mc-border-top border-right text-left pl-3">Accepted for Service</td>
                <td
                  class="p-0 mc-border-top border-right text-right pr-3">{{ebSepaFileReport.ftAcceptedForFactoringA | number:'.2-2'}}
                  %
                </td>
                <td
                  class="p-0 mc-border-top border-right text-right pr-3">{{ebSepaFileReport.ftAcceptedForFactoringBLoc}}
                </td>
                <td
                  class="p-0 mc-border-top text-right pr-3">{{ebSepaFileReport.ftAcceptedForFactoringCLoc}}
                </td>
              </tr>

              <!--Not accepted for factoring-->
              <tr>
                <td
                  class="p-0 border-right text-left pl-3">Not accepted for Service</td>
                <td
                  class="p-0 border-right text-right pr-3">{{ebSepaFileReport.ftNotAcceptedForFactoringA | number:'.2-2'}}
                  %
                </td>
                <td
                  class="p-0 border-right text-right pr-3">{{ebSepaFileReport.ftNotAcceptedForFactoringBLoc}}
                </td>
                <td class="p-0 text-right pr-3">{{ebSepaFileReport.ftNotAcceptedForFactoringCLoc}}</td>
              </tr>

              <!--Immediately payable balance-->
              <tr>
                <td
                  class="p-0 border-right text-left pl-3">{{'cc.my-factoring.immediately-payable-balance' | translate}}</td>
                <td
                  class="p-0 border-right text-right pr-3">{{ebSepaFileReport.ftImmediatelyPayableBalanceA | number:'.2-2'}}
                  %
                </td>
                <td
                  class="p-0 border-right text-right pr-3">{{ebSepaFileReport.ftImmediatelyPayableBalanceBLoc}}
                </td>
                <td class="p-0 text-right pr-3">{{ebSepaFileReport.ftImmediatelyPayableBalanceCLoc}}</td>
              </tr>

              <!--Later payable balance-->
              <tr>
                <td
                  class="p-0 mc-border-bottom border-right text-left pl-3">{{'cc.my-factoring.later-payable-balance' | translate}}</td>
                <td
                  class="p-0 mc-border-bottom border-right text-right pr-3">{{ebSepaFileReport.ftLaterPayableBalanceA | number:'.2-2'}}
                  %
                </td>
                <td
                  class="p-0 mc-border-bottom border-right text-right pr-3">{{ebSepaFileReport.ftLaterPayableBalanceBLoc}}
                </td>
                <td
                  class="p-0 mc-border-bottom text-right pr-3">{{ebSepaFileReport.ftLaterPayableBalanceCLoc}}
                </td>
              </tr>
              </tbody>

              <!--Second table-->
              <tbody>
              <tr>
                <td colspan="4" class="mc-reporting-table-td-gap"></td>
              </tr>
              <!--Factoring fee-->
              <tr>
                <td
                  class="p-0 mc-border-top border-right text-left pl-3">{{'cc.transactions.transaction-(s)' | translate}}</td>
                <td
                  class="p-0 mc-border-top border-right text-right pr-3">{{ebSepaFileReport.stImmediatelyPayableTransactionCount | number:''}}
                </td>
                <td
                  class="p-0 mc-border-top border-right text-right pr-3">
                  {{'cc.contract.per-transaction' | translate}}
                  {{ebFactoring.feePerTransactionTypeCd === feePerTransactionAmountCd ? ebSepaFileReport.stNonFactoringFeePerTransactionBLoc : ebSepaFileReport.stNonFactoringFeePerTransactionB | number:'.2-2' + '%'}}
                  {{ebSepaFileReport.stNonFactoringFeePerTransactionB | number:'.2-2'}}{{this.ebFactoring.feePerTransactionTypeCd === feePerTransactionAmountCd ? '€' : '%' }}
                </td>
                <td class="p-0 mc-border-top text-right pr-3">{{ebSepaFileReport.stFactoringFeeCLoc}}</td>
              </tr>

              <!--Plus VAT-->
              <tr>
                <td
                  class="p-0 border-right text-left pl-3">{{'cc.my-factoring.plus-vat-19' | translate}}</td>
                <td class="p-0 border-right text-right pr-3"></td>
                <td class="p-0 border-right text-right pr-3"></td>
                <td class="p-0 text-right pr-3">+ {{ebSepaFileReport.stPlusVatCLoc | number:'.2-2'}}</td>
              </tr>
              <!--Factoring fee-->
              <tr>
                <td
                  class="p-0 border-right text-left pl-3">{{'cc.my-factoring.batch-fee' | translate}}</td>
                <td
                  class="p-0 border-right text-right pr-3">{{ebSepaFileReport.stBatchFeeA | number:'.2-2'}}
                  %
                </td>
                <td
                  class="p-0 border-right text-right pr-3">{{ebSepaFileReport.stBatchFeeBLoc}}
                </td>
                <td class="p-0 text-right pr-3">+ {{ebSepaFileReport.stBatchFeeCLoc}}</td>
              </tr>

              <!--Plus VAT-->
              <tr>
                <td
                  class="p-0 border-right text-left pl-3">{{'cc.my-factoring.plus-vat-19' | translate}}</td>
                <td class="p-0 border-right text-right pr-3"></td>
                <td class="p-0 border-right text-right pr-3"></td>
                <td class="p-0 text-right pr-3">+ {{ebSepaFileReport.stBatchFeePlusVatCLoc}}
                </td>
              </tr>

              <!--Total factoring fee to be deducted-->
              <tr>
                <td
                  class="p-0 mc-border-bottom border-right text-left pl-3">{{'cc.my-factoring.total-fees-to-be-deducted' | translate}}</td>
                <td class="p-0 mc-border-bottom border-right text-right pr-3"></td>
                <td class="p-0 mc-border-bottom border-right text-right pr-3"></td>
                <td
                  class="p-0 mc-border-bottom text-right pr-3">{{ebSepaFileReport.stTotalFactoringFeeToBeDeductedCLoc}}
                </td>
              </tr>
              </tbody>

              <!--Third table-->
              <tbody class="mt-3">
              <tr>
                <td colspan="4" class="mc-reporting-table-td-gap"></td>
              </tr>
              <!--Immediately payable balance-->
              <tr>
                <td
                  class="p-0 mc-border-top border-right text-left pl-3">{{'cc.my-factoring.immediately-payable-balance' | translate}}</td>
                <td
                  class="p-0 mc-border-top border-right text-right pr-3">{{ebSepaFileReport.ttImmediatelyPayableBalanceA | number:'.2-2'}}
                  %
                </td>
                <td
                  class="p-0 mc-border-top border-right text-right pr-3">{{ebSepaFileReport.ttImmediatelyPayableBalanceBLoc}}
                </td>
                <td
                  class="p-0 mc-border-top text-right pr-3">{{ebSepaFileReport.ttImmediatelyPayableBalanceCLoc}}
                </td>
              </tr>

              <!--Factoring fee-->
              <tr>
                <td
                  class="p-0 border-right text-left pl-3">{{'cc.my-factoring.total-fees-to-be-deducted' | translate}}</td>
                <td class="p-0 border-right text-right pr-3"></td>
                <!--{{reportingCalculations.ttFactoringFeeA | number:'.2-2'}}%-->
                <td class="p-0 border-right text-right pr-3"></td>
                <!--{{reportingCalculations.ttFactoringFeeB | number:'.2-2'}}&euro;-->
                <td class="p-0 text-right pr-3">- {{ebSepaFileReport.ttFactoringFeeCLoc}}</td>
              </tr>

              <!--Payable balance-->
              <tr>
                <td
                  class="p-0 mc-border-bottom border-right text-left pl-3 font-weight-bold">{{'cc.my-factoring.estimated-payable-balance' | translate}}</td>
                <td class="p-0 mc-border-bottom border-right text-right pr-3"></td>
                <td class="p-0 mc-border-bottom border-right text-right pr-3"></td>
                <td
                  class="p-0 mc-border-bottom font-weight-bold text-right pr-3">{{ebSepaFileReport.ttPayableBalanceCLoc}}
                </td>
              </tr>
              </tbody><!--Third table /end-->
            </table>
          </div>

          <!--Download transactions csv
          <div class="col-md-12 text-right mb-n3">
            <span class="mc-text-click text-capitalize"
                  (click)="onDownloadTransactionCsv()">Export and download Pre-report table</span>
          </div> -->

          <!--Transaction table-->
          <div class="col-md-12">
            <mcc-fi-table-3 [table3Config]="transactionTableNonFactoringConfig" [objectList]="transactionList"
                            (eventPaginationOrSortChanged)="getTransactionsBySepaFileId()"></mcc-fi-table-3>
          </div>
        </div>

      </div>
    </div>

    <div class="content-footer">
      <button type="button" class="btn btn-secondary move-right"
              (click)="actCancel()">{{'cc.common.close' | translate}}</button>
    </div>
  </div>
</ng-container>
