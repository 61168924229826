/*BbmTestObj*/
import {McBillingStatementItemGENERATED} from '../_generated/mc-billing-statement-item-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {CurrencyUtil} from '../common/currency-util';
import {ActionOptions} from '../_core/mc-html-action-options';
import {McDateUtils} from '../common/mc-date-utils';

export class McBillingStatementItem extends McBillingStatementItemGENERATED {

  public static ACTION_AGGREGATE = 'AGGREGATE';
  public static ACTION_DEAGGREGATE = 'DEAGGREGATE';
  public static ACTION_DELETE_CORRECTION = 'DELETE-CORRECTION';
  public static ACTION_EDIT_CORRECTION = 'EDIT-CORRECTION';
  public static ACTION_REMOVE_FROM_STATEMENT = 'REMOVE-FROM-STATEMENT';
  public static ACTION_ASSIGN_TO_STATEMENT = 'ASSIGN-TO-STATEMENT';

  private _fldActions: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingStatementItem();
  }

  // ---------------------------------------------------------------------

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      if (this.isTypeCdSINGLE()) {
        actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t('cc.billing.aggregate')}`, McBillingStatementItem.ACTION_AGGREGATE, this, [McGod.PERM_MC_BILLING_OUT_STATEMENT_ITEM_AGGREGATE], '', ActionOptions.OPTION_EDIT);
      } else if (this.isTypeCdAGGREGATE()) {
        actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t('cc.billing.deaggregate')}`, McBillingStatementItem.ACTION_DEAGGREGATE, this, [McGod.PERM_MC_BILLING_OUT_STATEMENT_ITEM_DEAGGREGATE], '', ActionOptions.OPTION_EDIT);
      }
      if (!this.isTypeCdCORRECTION()) {
        actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t('cc.billing.remove-from-statement')}`, McBillingStatementItem.ACTION_REMOVE_FROM_STATEMENT, this, [McGod.PERM_MC_BILLING_OUT_STATEMENT_ITEM_REMOVE_FROM_STATEMENT], '', ActionOptions.OPTION_EDIT);
      } else {
        actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> Edit correction`, McBillingStatementItem.ACTION_EDIT_CORRECTION, this, [McGod.PERM_MC_BILLING_OUT_STATEMENT_EDIT_CORRECTION], '', ActionOptions.OPTION_EDIT);
        actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> Remove correction`, McBillingStatementItem.ACTION_REMOVE_FROM_STATEMENT, this, [McGod.PERM_MC_BILLING_OUT_STATEMENT_ITEM_REMOVE_FROM_STATEMENT], '', ActionOptions.OPTION_DELETE);
      }
      this._fldActions = html;
    }
    return this._fldActions;
  }

  onlyUnique(value: any, index: any, self: string | any[]) {
    return self.indexOf(value) === index;
  }

  getInfoIconForVAT(): string {
    const vat = this.isTypeCdCORRECTION() ? this.vatPercentageForCorrection : this.vatPercentageForAggregateAndSingle;
    const vats: any[] = [];
    if (vat) {
      if (Array.isArray(vat)) {
        vat.forEach(item => vats.push(item));
      } else {
        vats.push(vat);
      }
      const unique = vats.filter((item, pos) => {
        return vats.indexOf(item) === pos;
      });
      const uniqueResults: any[] = [];
      unique.forEach(item => uniqueResults.push(Number(item).toFixed(2).replace('.', ',')));
      let res;
      if (uniqueResults.length === 1) {
        res = uniqueResults[0];
      } else {
        res = uniqueResults.join('%; ');
      }
      return `<div class="icon-tooltip mr-2"><img src="assets/images/info-blue-tooltip.svg"><span class="icon-tooltip-text">${this.t('cc.product.view.vat')}: ${res ? res : ''}%</span></div>`;
    } else {
      return `<div class="icon-tooltip mr-2"><img src="assets/images/info-blue-tooltip.svg"><span class="icon-tooltip-text">${this.t('cc.product.view.vat')}: 0,00 %</span></div>`;
    }


  }

  getAmount() {
    return CurrencyUtil.format(this.amount, this.currencyCode);
  }

  getPeriod(): string {
    if (this.period) {
      return this.period;
    }
    return '';
  }

  getVATString(): string {
    return `${CurrencyUtil.format(this.vatForBillingStatementItem, this.currencyCode)} ${this.getInfoIconForVAT()} `;
  }

  fldAmountWithVat() {
    const isCreditType = this.correctionTypeCd === McBillingStatementItem.CORRECTION_TYPE_CD_CREDIT;
    return `${isCreditType ? CurrencyUtil.format(-this.amount - this.vatForBillingStatementItem, this.currencyCode) : CurrencyUtil.format(this.amount + this.vatForBillingStatementItem, this.currencyCode)}`;
  }

  fldVat() {
    const isCreditType = this.correctionTypeCd === McBillingStatementItem.CORRECTION_TYPE_CD_CREDIT;
    return isCreditType ? -this.vatForBillingStatementItem : this.vatForBillingStatementItem;
  }

  getPriceDescForStatementItem() {
    if (this.priceDescription.includes('$')) {
      const description = this.priceDescription.split('$');
      const line = description[0];
      const line1 = description[1];
      return `${line} <br>
              ${line1}`;
    } else {
      return this.priceDescription;
    }
  }

  fldNoOfItems() {
    return this.typeCd === McBillingStatementItem.TYPE_CD_CORRECTION ? 1 : this.numberOfBillingItemsInAggregation > 0 ? this.numberOfBillingItemsInAggregation : '0';
  }

  fldItem() {
    return this.typeCd === McBillingStatementItem.TYPE_CD_CORRECTION ? this.title + ' ' + this.description : (this.title ? this.title : this.nameOfBillingServiceForStatementItem);
  }

  fldAmount() {
    const isCreditType = this.correctionTypeCd === McBillingStatementItem.CORRECTION_TYPE_CD_CREDIT;
    return McGod.formatNumberToMoney(isCreditType ? -this.amount : this.amount);
  }

  getCurrencySymbol(): string {
    return '';
  }

  getTotalFormat(): string {
    return CurrencyUtil.format(this.totalAmount, this.currencyCode);
  }

}
