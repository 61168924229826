import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PmTransactionsListComponent} from './pages/pm-transactions-list/pm-transactions-list.component';
import {PmTransactionsPageComponent} from './pages/pm-transactions-page/pm-transactions-page.component';

const routes: Routes = [
  {
    path: 'out',
    component: PmTransactionsListComponent,
  },
  {
    path: 'in',
    component: PmTransactionsListComponent
  },
  {
    path: ':id',
    component: PmTransactionsPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PmTransactionsRoutingModule { }
