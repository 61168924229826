/*BbmTestObj*/
import {McBillingServiceUnitTypeGENERATED} from '../_generated/mc-billing-service-unit-type-generated.model';

export class McBillingServiceUnitType extends McBillingServiceUnitTypeGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingServiceUnitType();
  }

  // ---------------------------------------------------------------------
}
