<div [formGroup]="stepForm3" class="mc-user-summary-area">
<!--<div class="mc-user-summary-area">-->
  <div class="row mt-5">
    <div class="col-md-6 mc-user-summary-section">
      <div class="mc-user-summary-title">
        <i class="fas fa-pen" (click)="goToStep(1)"></i>
        <h6 class="pl-2">{{ 'cc.common.view.basic-info' | translate }}:</h6>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="pt-1 pb-2 mc-user-summary-basic-info">
            <div class="mc-user-summary-value">{{ 'cc.common.edit.email' | translate }}</div>
            <div class="mc-user-summary-value">{{allRawData.step1?.email || '-'}}</div>
          </div>
          <div class="pt-1 pb-2 mc-user-summary-basic-info">
            <div class="mc-user-summary-value">{{ 'cc.common.edit.first-name' | translate }}</div>
            <div class="mc-user-summary-value">{{allRawData.step1?.firstName || '-'}}</div>
          </div>
          <div class="pt-1 pb-2 mc-user-summary-basic-info">
            <div class="mc-user-summary-value">{{ 'cc.common.edit.last-name' | translate }}</div>
            <div class="mc-user-summary-value">{{allRawData.step1?.lastName || '-'}}</div>
          </div>
          <div class="pt-1 pb-2 mc-user-summary-basic-info">
            <div class="mc-user-summary-value">Nickname</div>
            <div class="mc-user-summary-value">{{allRawData.step1?.nickname || '-'}}</div>
          </div>
          <div class="pt-1 pb-2 mc-user-summary-basic-info">
            <div class="mc-user-summary-value">{{ 'cc.common.edit.gender' | translate }}</div>
            <div class="mc-user-summary-value">{{allRawData.step1?.gender || '-'}}</div>
          </div>
          <div class="pt-1 pb-2 mc-user-summary-basic-info">
            <div class="mc-user-summary-value">{{'cc.users.edit.language' | translate}}</div>
            <div class="mc-user-summary-value">{{allRawData.step1?.language || '-'}}</div>
          </div>
          <div class="pt-1 pb-2 mc-user-summary-basic-info">
            <div class="mc-user-summary-value">Time Zone</div>
            <div class="mc-user-summary-value">{{allRawData.step1?.timezone || '-'}}</div>
          </div>
          <div class="pt-1 pb-2 mc-user-summary-basic-info">
            <div class="mc-user-summary-value">{{'cc.users.edit.country' | translate}}</div>
            <div class="mc-user-summary-value">{{allRawData.step1?.country || '-'}}</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="img-thumbnail mc-new-user-image" [ngStyle]="{'background-image': 'url(' + imagePath + ')'}"></div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="mc-user-summary-title">
        <i class="fas fa-pen" (click)="goToStep(2)"></i>
        <h6 class="pl-2">{{ 'cc.common.admin.roles' | translate }}:</h6>
      </div>
      <div class="col-md-12">
        <div class="py-2 mc-user-summary-role-info">
          <div *ngFor="let role of allRawData.step2?.selRoles">
            <div class="mc-user-summary-role-name" *ngIf="role.selected">
              <span>{{role.entity}}</span>
              <span>{{role.name}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-7 offset-md-5 mc-user-summary-action-btn">
      <div class="col-md-4 pt-5 px-0 mc-user-summary-send-invite">
        <label class="custom-control fill-checkbox mb-0">
          <input type="checkbox" class="fill-control-input" formControlName="sendInvite" [checked]="isInvitationChecked">
          <span class="fill-control-indicator"></span>
          <span class="fill-control-description">{{'cc.users.edit.send-user-invite' | translate}}</span>
        </label>
        <i class="far fa-envelope fa-3x"></i>
      </div>
      <div class="col-md-4 pt-5 px-0 mc-user-summary-create-btn">
        <button id="userSummaryCreateBtn" class="btn btn-primary mc-next-save-btn" type="submit"
                [disabled]="createBtnDisabled" (click)="createNewUser(1)">
          {{'cc.common.view.create' | translate}}
        </button>
      </div>
      <div class="col-md-4 pt-5 px-0 mc-user-summary-createAdd-btn">
        <button id="userSummaryCreate&AddBtn" class="btn btn-primary mc-next-save-btn" type="submit" [disabled]="createBtnDisabled" (click)="createNewUser(2)">
          {{'cc.users.edit.create-&-add-new' | translate}}
        </button>
      </div>
    </div>
  </div>

</div>
