import {McBankAccountFilterGENERATED} from '../_generated/mc-bank-account-filter-generated.model';

export class McBankAccountFilter extends McBankAccountFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBankAccountFilter();
  }

  // ---------------------------------------------------------------------
}
