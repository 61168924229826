/*BbmTestObj*/
import {CsrConsumer2CtdEmailGENERATED} from '../_generated/csr-consumer-2-ctd-email-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {ActionOptions} from '../_core/mc-html-action-options';
import {ICON_EMAIL_SENT} from "../svg/icon-email-sent";
import {ICON_EMAIL_FAILED} from "../svg/icon-email-failed";
import moment from 'moment';

export class CsrConsumer2CtdEmail extends CsrConsumer2CtdEmailGENERATED {
  public static ACTION_VIEW_EMAIL = 'VIEW_EMAIL';

  private _fldActions: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrConsumer2CtdEmail();
  }

  // ---------------------------------------------------------------------

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> View email`, CsrConsumer2CtdEmail.ACTION_VIEW_EMAIL, this, [McGod.PERM_MC_PROCESS_ITEM_VIEW], '', ActionOptions.OPTION_VIEW);
      this._fldActions = html;
    }
    return this._fldActions;
  }

  getCsrConsumer() {
    return this._rawJson.csrConsumer.firstName + " " + this._rawJson.csrConsumer.lastName;
  }

  getEmail() {
    return this._rawJson.csrConsumer.email;
  }

  getEmailStatus() {
    if(this._rawJson.cmnEmail.statusCd === 'SENT') {
      return `<div class='icon-text-table-cell'>${ICON_EMAIL_SENT} <span>Sent</span></div>`
    }  else if(this._rawJson.cmnEmail.statusCd === 'ERROR') {
      return `<div class='icon-text-table-cell'>${ICON_EMAIL_FAILED} <span>Failed</span></div>`
    } else return this._rawJson.cmnEmail.statusCd;
  }


  getDateSent() {
    return moment(this._rawJson.cmnEmail.sysCreatedDatetime).format('DD.MM.YYYY hh:mm');
  }
}
