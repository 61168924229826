/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McConsumerActionTable3ConfigGENERATED} from '../_generated/mc-consumer-action-table-3-config-generated.model';

// import {McConsumerActionGENERATED} from '../_generated/mc-consumer-action-generated.model';

export class McConsumerActionTable3Config extends McConsumerActionTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    // this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colTypeCd', 'Type', 'getTypeCdLabel()', 'typeCd');
    this.addColumn('colTitle', 'Flow/Level', 'title', 'title');
    this.addColumn('colCommunicationCode', 'Communication code', '', '');
    this.addColumn('colLevelActions', 'Actions', 'fldLevelActions()', '');
      this.addColumn('colSentDateTime', 'Sent Date&Time', 'getSentDatetimeString()', 'sentDatetime');
      this.addColumn('colItems', 'Items', '', '');
      this.addColumn('colResendReason', 'Resend reason', '', '');
    this.addColumn('colResendDateTime', 'Resend Date&Time', '', '');
    this.addColumn('colStatusCd', 'Status', 'fldStatus()', 'statusCd');

  }
}
