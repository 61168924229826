/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McDashboardFilter} from '../models/mc-dashboard-filter.model';

export class McDashboardFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McDashboardFilter {
    console.error('MTCN-ERROR: Not Implemented: McDashboardFilter::createNewInstance(). Add this method to final class and return new McDashboardFilter();');
      throw new Error('Cannot McDashboardFilter::createNewInstance(). Add this method to final class and return new McDashboardFilter();');
  }

}
