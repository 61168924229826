<ng-container>
  <div class="mc-pause-transaction">
    <div class="content-wrapper">

      <div class="content-header">
        <ng-container *ngIf="pmTransactionList.getCount() === 1">
          <h2 *ngIf="isTransactionPaused">Unpause transaction</h2>
          <h2 *ngIf="!isTransactionPaused">Pause transaction</h2>
        </ng-container>
        <ng-container *ngIf="pmTransactionList.getCount() > 1">
          <h2 *ngIf="isTransactionPaused">Unpause transactions</h2>
          <h2 *ngIf="!isTransactionPaused">Pause transactions</h2>
        </ng-container>

      </div>

      <div class="content-body">
        <mcc-message [errorMsg]="errorMsg"></mcc-message>


        <ng-container *ngIf="pmTransactionList.getCount() === 1">
          <div *ngIf="!isTransactionPaused" class="gen-info"><p><i class="fas fa-info-circle"></i> A transaction can be
            paused for maximum of {{maxDaysForPausingTransactions}} days. <span
              *ngIf="mcGod.userHasMcEntityEditMaxTransactionPauseDaysPermission()">To change this go<span
              class="text-primary mc-cursor-pointer"><a (click)="actGoToEntitySettings()"> here</a></span></span></p>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mc-transaction-status-label">
                <h6>{{'cc.common.view.status' | translate}}:</h6>
                <div class="mc-transaction-status" [innerHTML]="pmTransaction.getIconLabel()"></div>
              </div>
            </div>
          </div>
          <div class="row info-data">
            <div class="col-md-7 mc-transaction-profile-left-column">
              <mcc-row-title-description title="{{'cc.my-factoring.consumer' | translate}}"
                                         [description]="pmTransaction.csrConsumer.displayName"></mcc-row-title-description>

              <mcc-row-title-description title="{{'cc.common.view.entity' | translate}}"
                                         [description]="pmTransaction.mcEntity.name "></mcc-row-title-description>

              <mcc-row-title-description title="{{'cc.common.contract-number' | translate}}"
                                         [description]="pmTransaction.csrConsumer.contractNumber"></mcc-row-title-description>

              <mcc-row-title-description title="{{'cc.out-factoring.external-transaction-id' | translate}}"
                                         [description]="pmTransaction.idExtTransaction"></mcc-row-title-description>

              <mcc-row-title-description title="{{'cc.out-factoring.transaction-id' | translate}}"
                                         [description]="pmTransaction.id"></mcc-row-title-description>

              <mcc-row-title-description title="{{'cc.consumers.imports.created-at' | translate}}"
                                         [description]="pmTransaction.getCreatedDatetimeStr()"></mcc-row-title-description>
            </div>
            <div class="col-md-4 mc-transaction-profile-right-column">
              <mcc-row-title-description class="mc-transaction-money-col"
                                         title="PAYMENT DUE DATE"
                                         [description]="pmTransaction.getDueDateStr()"></mcc-row-title-description>

              <!-- <mcc-row-title-description class="mc-transaction-money-col"
                                          title="REQUEST COLLECTION DATE:"
                                          [description]="ebSepaFileTransaction.requestedCollectionDate"></mcc-row-title-description>-->

              <mcc-row-title-description class="mc-transaction-money-col"
                                         title="{{'cc.my-factoring.original-amount' | translate}}"
                                         [description]="pmTransaction.getAmount()"></mcc-row-title-description>

              <mcc-row-title-description class="mc-transaction-money-col mc-description-bold"
                                         title="{{'cc.my-factoring.remaining-amount-due' | translate}}"
                                         [description]="pmTransaction.getAmountDue()"></mcc-row-title-description>

              <!--<mcc-row-title-description class="pb-2" title="Surplus:"
                                         [description]="ebSepaFileTransaction.getAmountDueStr()"></mcc-row-title-description>-->
            </div>
          </div>
          <ng-container *ngIf="isTransactionPaused">
            <div class="row info-data">
              <div class="col-md-12 mc-transaction-profile-left-column">
                <mcc-row-title-description title="USED/MAX PAUSE DAYS"
                                           description="{{pmTransaction.pauseDaysCountWithoutCurrentPauseDays}}/{{pmTransaction.maxPauseDays}}"></mcc-row-title-description>

                <mcc-row-title-description title="Last pause start date & time"
                                           [description]="pmTransaction.getPauseStartDateStr()"></mcc-row-title-description>

                <mcc-row-title-description title="Last pause reason"
                                           [description]="pmTransaction.pauseUnpauseReason"></mcc-row-title-description>

                <mcc-row-title-description title="Paused by"
                                           [description]="mcUser.fldFullName()"></mcc-row-title-description>
              </div>
            </div>
          </ng-container>
        </ng-container>

          <div [ngClass]="{'mc-disable' : minimalDateIsBiggerThenMaximalDate}">
            <mcc-fi-textarea name="ctrlReason" label="Reason" [numCols]="35" [validRequiredFlg]="true"
                             [validWhitespaces]="true"
                             [mcForm]="mcForm"></mcc-fi-textarea>
            <mcc-fi-date-picker *ngIf="!isTransactionPaused"
                                label="Pause ends"
                                name="ctrlPauseEndDate"
                                [validRequiredFlg]="true"
                                [minDateInput]="minDateToPauseTransaction"
                                [mcForm]="mcForm">
            </mcc-fi-date-picker>
          </div>

      </div>

      <div class="content-footer">
        <button class="btn btn-secondary move-right" (click)="actCancel()"><i
          class="far fa-times-circle pr-2"></i>{{'cc.common.edit.cancel' | translate}}</button>
        <button *ngIf="isTransactionPaused" class="btn btn-primary" (click)="actUnpause()"><i
          class="far fa-pause-circle pr-2"></i>Unpause
        </button>
        <button *ngIf="!isTransactionPaused" [disabled]="minimalDateIsBiggerThenMaximalDate" class="btn btn-primary" (click)="actPause()"><i
          class="far fa-pause-circle pr-2"></i>Pause
        </button>
      </div>

    </div>
  </div>

</ng-container>
