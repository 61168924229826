import {ImDataSetAttribute} from './im-data-set-attribute.model';
import {ImDataSetAttributeListGENERATED} from '../_generated/im-data-set-attribute-list-generated.model';

export class ImDataSetAttributeList extends ImDataSetAttributeListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new ImDataSetAttributeList();
  }

  // ---------------------------------------------------------------------
}
