/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBillingStatementItem} from '../models/mc-billing-statement-item.model';
import {McBillingStatementItemService} from '../services/mc-billing-statement-item.service';
import {IMcBillingStatementItem} from '../_generated/mc-billing-statement-item.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McBillingStatementItemGENERATED extends BaseObject {


    public static readonly CORRECTION_TYPE_CD_CREDIT: string = 'CREDIT';
    public static readonly CORRECTION_TYPE_CD_CHARGE: string = 'CHARGE';
  public static readonly CORRECTION_TYPE_CD_LIST__ALL = [
    McBillingStatementItemGENERATED.CORRECTION_TYPE_CD_CREDIT,
    McBillingStatementItemGENERATED.CORRECTION_TYPE_CD_CHARGE
];

    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
  public static readonly STATUS_CD_LIST__ALL = [
    McBillingStatementItemGENERATED.STATUS_CD_ACTIVE
];

    public static readonly TRANSACTION_RETURNED_TYPE_CD_TRANSACTION_RETURNED_FEE: string = 'TRANSACTION_RETURNED_FEE';
    public static readonly TRANSACTION_RETURNED_TYPE_CD_BANK_FEES_FOR_RETURNED_TRANSACTION: string = 'BANK_FEES_FOR_RETURNED_TRANSACTION';
    public static readonly TRANSACTION_RETURNED_TYPE_CD_RETURNED_TRANSACTION_AMOUNT: string = 'RETURNED_TRANSACTION_AMOUNT';
  public static readonly TRANSACTION_RETURNED_TYPE_CD_LIST__ALL = [
    McBillingStatementItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_TRANSACTION_RETURNED_FEE,
    McBillingStatementItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_BANK_FEES_FOR_RETURNED_TRANSACTION,
    McBillingStatementItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_RETURNED_TRANSACTION_AMOUNT
];

    public static readonly TYPE_CD_SINGLE: string = 'SINGLE';
    public static readonly TYPE_CD_AGGREGATE: string = 'AGGREGATE';
    public static readonly TYPE_CD_CORRECTION: string = 'CORRECTION';
  public static readonly TYPE_CD_LIST__ALL = [
    McBillingStatementItemGENERATED.TYPE_CD_SINGLE,
    McBillingStatementItemGENERATED.TYPE_CD_AGGREGATE,
    McBillingStatementItemGENERATED.TYPE_CD_CORRECTION
];

  public apiService: McBillingStatementItemService;
  public _rawJson: IMcBillingStatementItem;
    id: number = 0;
  amount: number;
  correctionTypeCd: string;
  description = '';
  idBillingService: number;
  mcBillingBatchId: number;
  mcBillingStatementId: number;
  priceDescription = '';
  statusCd: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  title = '';
  transactionReturnedTypeCd: string;
  typeCd: string;
  vatPercentageForCorrection: number;
  vatXAmount: number;
  currencyCode: string;  mcBillingBatchName: string;  nameOfBillingServiceForStatementItem: string;  numberOfBillingItemsInAggregation: number;  period: string;  totalAmount: any;  vatForBillingStatementItem: any;  vatPercentageForAggregateAndSingle: any;

  public properties: string[] = ['id', 'amount', 'correctionTypeCd', 'description', 'idBillingService', 'mcBillingBatchId', 'mcBillingStatementId', 'priceDescription', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'title', 'transactionReturnedTypeCd', 'typeCd', 'vatPercentageForCorrection', 'vatXAmount', 'currencyCode', 'mcBillingBatchName', 'nameOfBillingServiceForStatementItem', 'numberOfBillingItemsInAggregation', 'period', 'totalAmount', 'vatForBillingStatementItem', 'vatPercentageForAggregateAndSingle'];
  public propertiesRegular: string[] = ['id', 'amount', 'correctionTypeCd', 'description', 'idBillingService', 'mcBillingBatchId', 'mcBillingStatementId', 'mcBillingBatch', 'mcBillingStatement', 'priceDescription', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'title', 'transactionReturnedTypeCd', 'typeCd', 'vatPercentageForCorrection', 'vatXAmount', 'currencyCode', 'mcBillingBatchName', 'nameOfBillingServiceForStatementItem', 'numberOfBillingItemsInAggregation', 'period', 'totalAmount', 'vatForBillingStatementItem', 'vatPercentageForAggregateAndSingle'];
  public propertiesSpecial: string[] = [];



  public static getCorrectionTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingStatementItemGENERATED.CORRECTION_TYPE_CD_CREDIT, McGod.t('Credit'));
    list.add(McBillingStatementItemGENERATED.CORRECTION_TYPE_CD_CHARGE, McGod.t('Charge'));


    return list;
  }

  public static getCorrectionTypeCdLabel(aCd: string): string {
    const list = McBillingStatementItemGENERATED.getCorrectionTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingStatementItemGENERATED.STATUS_CD_ACTIVE, McGod.t('ACTIVE'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McBillingStatementItemGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTransactionReturnedTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingStatementItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_TRANSACTION_RETURNED_FEE, McGod.t('Transaction returned fee'));
    list.add(McBillingStatementItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_BANK_FEES_FOR_RETURNED_TRANSACTION, McGod.t('Bank fees for returned transactions'));
    list.add(McBillingStatementItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_RETURNED_TRANSACTION_AMOUNT, McGod.t('Returned transaction amount'));


    return list;
  }

  public static getTransactionReturnedTypeCdLabel(aCd: string): string {
    const list = McBillingStatementItemGENERATED.getTransactionReturnedTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingStatementItemGENERATED.TYPE_CD_SINGLE, McGod.t('Single'));
    list.add(McBillingStatementItemGENERATED.TYPE_CD_AGGREGATE, McGod.t('Aggregate'));
    list.add(McBillingStatementItemGENERATED.TYPE_CD_CORRECTION, McGod.t('Correction'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = McBillingStatementItemGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McBillingStatementItem {
    console.error('MTCN-ERROR: Not Implemented: McBillingStatementItem::createNewInstance(). Add this method to final class and return new McBillingStatementItem();');
    throw new Error('Cannot McBillingStatementItem::createNewInstance(). Add this method to final class and return new McBillingStatementItem();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBillingStatementItem): McBillingStatementItem {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingStatementItemService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBillingStatementItem AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getAmountStr(): string {
       return McGod.formatNumberToMoney(this.amount);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getVatPercentageForCorrectionStr(): string {
       return McGod.formatNumberToMoney(this.vatPercentageForCorrection);
    }

    public getVatXAmountStr(): string {
       return McGod.formatNumberToMoney(this.vatXAmount);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getCorrectionTypeCdLabel(): string {
    return McBillingStatementItemGENERATED.getCorrectionTypeCdLabel(this.correctionTypeCd);
  }


 public  isCorrectionTypeCdCREDIT(): boolean {
    const result = (this.correctionTypeCd === McBillingStatementItemGENERATED.CORRECTION_TYPE_CD_CREDIT);

    return result;
  }

 public  isCorrectionTypeCdCHARGE(): boolean {
    const result = (this.correctionTypeCd === McBillingStatementItemGENERATED.CORRECTION_TYPE_CD_CHARGE);

    return result;
  }


  public getStatusCdLabel(): string {
    return McBillingStatementItemGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === McBillingStatementItemGENERATED.STATUS_CD_ACTIVE);

    return result;
  }


  public getTransactionReturnedTypeCdLabel(): string {
    return McBillingStatementItemGENERATED.getTransactionReturnedTypeCdLabel(this.transactionReturnedTypeCd);
  }


 public  isTransactionReturnedTypeCdTRANSACTION_RETURNED_FEE(): boolean {
    const result = (this.transactionReturnedTypeCd === McBillingStatementItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_TRANSACTION_RETURNED_FEE);

    return result;
  }

 public  isTransactionReturnedTypeCdBANK_FEES_FOR_RETURNED_TRANSACTION(): boolean {
    const result = (this.transactionReturnedTypeCd === McBillingStatementItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_BANK_FEES_FOR_RETURNED_TRANSACTION);

    return result;
  }

 public  isTransactionReturnedTypeCdRETURNED_TRANSACTION_AMOUNT(): boolean {
    const result = (this.transactionReturnedTypeCd === McBillingStatementItemGENERATED.TRANSACTION_RETURNED_TYPE_CD_RETURNED_TRANSACTION_AMOUNT);

    return result;
  }


  public getTypeCdLabel(): string {
    return McBillingStatementItemGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdSINGLE(): boolean {
    const result = (this.typeCd === McBillingStatementItemGENERATED.TYPE_CD_SINGLE);

    return result;
  }

 public  isTypeCdAGGREGATE(): boolean {
    const result = (this.typeCd === McBillingStatementItemGENERATED.TYPE_CD_AGGREGATE);

    return result;
  }

 public  isTypeCdCORRECTION(): boolean {
    const result = (this.typeCd === McBillingStatementItemGENERATED.TYPE_CD_CORRECTION);

    return result;
  }


  
}
