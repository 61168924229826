import { McOutBillingPayoutFileProfileComponent } from './pages/mc-out-billing-payout-file-profile/mc-out-billing-payout-file-profile.component';
import { McOutBillingPayoutFileListPageComponent } from './pages/mc-out-billing-payout-file-list-page/mc-out-billing-payout-file-list-page.component';
import { McOutBillingStatementProfilePageComponent } from './pages/mc-out-billing-statement-profile-page/mc-out-billing-statement-profile-page.component';
import { McOutBillingBillingItemListPageComponent } from './pages/mc-out-billing-billing-item-list-page/mc-out-billing-billing-item-list-page.component';
import { McOutBillingStatementListPageComponent } from './pages/mc-out-billing-statement-list-page/mc-out-billing-statement-list-page.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard, McPermissionGuard} from '@miticon-ui/mc-core';

const routes: Routes = [
{
    path: 'payout/:id',
    component: McOutBillingPayoutFileProfileComponent
  },
{
    path: 'payout-files',
    component: McOutBillingPayoutFileListPageComponent
  },
{
    path: 'billing-items',
    component: McOutBillingBillingItemListPageComponent
  },
{
    path: 'statements',
    component: McOutBillingStatementListPageComponent
  },
  {
    path: 'statement/:id',
    component: McOutBillingStatementProfilePageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McOutBillingRoutingModule {
}
