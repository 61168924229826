/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBillingBatch2Transaction} from '../models/mc-billing-batch-2-transaction.model';
import {McBillingBatch2TransactionService} from '../services/mc-billing-batch-2-transaction.service';
import {IMcBillingBatch2Transaction} from '../_generated/mc-billing-batch-2-transaction.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McBillingBatch2TransactionGENERATED extends BaseObject {


  public apiService: McBillingBatch2TransactionService;
  public _rawJson: IMcBillingBatch2Transaction;
    id: number = 0;
  idBatch: number;
  idTransaction: number;
  sysCreatedDatetime: number;


  public properties: string[] = ['id', 'idBatch', 'idTransaction', 'sysCreatedDatetime'];
  public propertiesRegular: string[] = ['id', 'idBatch', 'idTransaction', 'sysCreatedDatetime'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): McBillingBatch2Transaction {
    console.error('MTCN-ERROR: Not Implemented: McBillingBatch2Transaction::createNewInstance(). Add this method to final class and return new McBillingBatch2Transaction();');
    throw new Error('Cannot McBillingBatch2Transaction::createNewInstance(). Add this method to final class and return new McBillingBatch2Transaction();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBillingBatch2Transaction): McBillingBatch2Transaction {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingBatch2TransactionService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBillingBatch2Transaction AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
