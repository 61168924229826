import {Component, Input, OnInit} from '@angular/core';
import {EbFactoringService, McEntityService} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-select-entity-list-wrapper',
  templateUrl: './select-entity-list-wrapper.component.html'
})
export class SelectEntityListWrapperComponent implements OnInit {

  isToggle!: boolean;
  @Input() entity!: any;

  constructor(private entityService: McEntityService) {
  }

  ngOnInit(): void {
    this.entity.children.sort((a: any, b: any) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
    });
  }

  onToggle(): void {
    this.isToggle = !this.isToggle;
  }

  onSelectEntity() {
    this.entity.selected = !this.entity.selected;
    this.entityService.setSelectedEntity(this.entity);
  }

  setCssArrowClass() {
    return this.entity.children.length > 0 ? (this.isToggle ? 'fas fa-caret-down' : 'fas fa-caret-up text-primary') : ('ml-2');
  }

  setCssClass(className: any) {
    const listClasses = [];
    !this.entity.parentMcEntityId && listClasses.push(className);
    return listClasses;
  }

  setNameClass(className: any) {
    return this.entity.selected ? className : null;
  }
}
