import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'mc-product-total-price',
  template: `<p class="my-1" McDetectChanges [elementHtmlValue]="productTotalPrice">{{productTotalPrice}}</p>
  <p class="mb-1" *ngIf="showDiscount()" McDetectChanges [elementHtmlValue]="productDiscount">
    <span *ngIf="calculationType === 'DISCOUNT'">-</span>{{productDiscount}}</p>`,
})
export class McProductTotalPriceComponent {

  /*Private variables*/
  private _productTotalPrice!: string;
  private _productDiscount!: string;
  private _setup: any;

  /*Setters*/
  @Input() set productTotalPrice(price) {
    this._productTotalPrice = Number(price).toFixed(this.setup ? this.setup.toFix : 2);
  }

  @Input() set productDiscount(discount) {
    this._productDiscount = Number(discount).toFixed(this.setup ? this.setup.toFix : 2);
  }

  @Input() set setup(setupObject) {
    if (setupObject) {
      this._setup = setupObject;
      this._productDiscount = Number(this._productDiscount).toFixed(this.setup.toFix);
      this._productTotalPrice = Number(this._productTotalPrice).toFixed(this.setup.toFix);
    }
  }

  @Input() calculationType: any;

  /*Getters*/
  get productTotalPrice() {
    return this._productTotalPrice;
  }

  get productDiscount() {
    return this._productDiscount;
  }

  get setup() {
    return this._setup;
  }

  /*Constructor*/
  constructor() {
  }

  /*Show discount condition*/
  public showDiscount() {
    return Number(this.productDiscount) > 0;
  }

}
