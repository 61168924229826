import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MccFiFileUploadComponent} from './mcc-fi-file-upload.component';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [MccFiFileUploadComponent],
  exports: [
    MccFiFileUploadComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class MccFiFileUploadModule { }
