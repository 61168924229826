import {BaseObject} from '@miticon-ui/mc-core';

export class MccFiTable2CellEditedData {
  dataItemObj: BaseObject;
  propertyName: string;
  newValue: string;

  constructor(dataItemObj: BaseObject, propertyName: string, newValue: string) {
    this.dataItemObj = dataItemObj;
    this.propertyName = propertyName;
    this.newValue = newValue;
  }


}
