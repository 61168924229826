/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EbTransactionAdjustmentTable3ConfigGENERATED} from '../_generated/eb-transaction-adjustment-table-3-config-generated.model';

// import {EbTransactionAdjustmentGENERATED} from '../_generated/eb-transaction-adjustment-generated.model';

export class EbTransactionAdjustmentTable3Config extends EbTransactionAdjustmentTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colTypeCd', this.t('cc.jobs.edit.type'), 'fldTypeCdLabel()', 'typeCd');
    this.addColumn('colCreatedDatetime', this.t('cc.my-factoring.date-&-time'), 'getCreatedDateTimeString()', 'createdDatetime');
    this.addColumn('colDescription', this.t('cc.common.view.description'), 'fldDescription()', 'description');
    this.addColumn('colAmount', this.t('cc.factoring.transactions.amount'), 'getAmountStr()', 'amount').extraCssClass = 'text-right';
    this.addColumn('colPaymentMethod', this.t('cc.my-factoring.payment-method'), 'fldPaymentMethodCdLabel()', 'paymentMethodCd');
    this.addColumn('ctrlStatus', this.t('cc.common.view.status'), 'fldStatus()', 'statusCd');
    this.addColumn('colBeneficiaryEntity', this.t('cc.consumer.beneficiary-entity'), 'fldBeneficiaryEntityName()', 'beneficiaryEntityName');
    this.addColumn('colCreatedBy', this.t('cc.consumers.imports.created-by'), 'fldCreatedBy()', 'createdBy');

//    this.addColumn('colCreatedUserId', 'Created User Id', 'createdUserId', 'createdUserId');


  }
}
