import {PmTransactionTable3ConfigGENERATED} from '../_generated/pm-transaction-table-3-config-generated.model';


export class PmTransactionPotentialMatchesTable3Config extends PmTransactionTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldPotentialMatchesActions()', '').extraCssClass = 'table-col-width-50';
    this.addColumn('colMcEntity', this.t('cc.factoring.transactions.entity-name'), 'getStudioName()', 'idMcEntity');
    this.addColumn('colConsumer', this.t('cc.my-factoring.consumer'), 'getConsumerName()', '');
    this.addColumn('colDescription', this.t('cc.common.view.description'), 'description', 'description');
    this.addColumn('colDateAndTime', this.t('cc.factoring.imported-sepa.import-date-&-time'), 'getCreatedDatetimeStr()', '');
    this.addColumn('colPaymentDueDate', this.t('cc.transactions.payment-due-date'), 'getDueDateStr()', 'dueDate');
    this.addColumn('colAmount', this.t('cc.common.view.amount-due'), 'getAmountDue()', 'amount').extraCssClass = 'text-right';
    this.addColumn('colStatus', this.t('cc.common.view.status'), 'getIconLabel()', '');
  }
}
