import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {McPermissionService, McUtilityService} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'mc-role-permissions',
  templateUrl: './mc-role-permissions.component.html',
})
export class McRolePermissionsComponent implements OnInit {

  permissionsByModule!: any;
  selectedModule!: any;
  searchPermissionValue = '';
  selectedPermissions = [];
  responseMessage!: boolean;
  message!: string;
  type!: string;
  errorObject = {
    type: '',
    message: ''
  };

  /*Private variable*/
  private goToStepSubscription!: Subscription;

  /*Events*/
  @Input() set resetForm(value: any) {
    value && value.reset && (this.getPermissionsFromDB());
  }

  @Input() editRole!: any;

  /*TODO: this is hack solution until we solve validation in stepper*/
  @Input() set selectedStep(step: number) {
    if (step === 1) {
      if (this.selectedPermissions.length > 0) {
        this.selectedPermissions = [...this.selectedPermissions];
        this.onSelectPermission.emit(this.selectedPermissions);
        this.isDataFormInvalid.emit(false);
      } else {
        this.isDataFormInvalid.emit(true);
      }
    }
  }

  @Output() onSelectPermission = new EventEmitter<object>();
  @Output() isDataFormInvalid = new EventEmitter<boolean>();


  /*Constructor*/
  constructor(private permissionService: McPermissionService, private utilityService: McUtilityService, private cdRef: ChangeDetectorRef) {
  }

  /*On init class*/
  ngOnInit() {
    this.responseMessage = true;
    this.setNotificationMessage('Please select permissions.', 'success');
    this.getPermissionsFromDB();

    /*Get step number*/
    this.goToStepSubscription = this.utilityService._goToStep.subscribe(stepNumber => {
      if (stepNumber && !isNaN(stepNumber)) {
      }
    });
  }


  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  /*Get permissions from DB*/
  public getPermissionsFromDB(): void {
    this.permissionService.getAllPermissionsByModuleFromBackend()
      .subscribe(response => {
          this.permissionsByModule = response;
          this.selectedModule = this.permissionsByModule.content[0];
          this.errorObject = Object.assign({}, {
            message: '',
            type: ''
          });

          if (this.editRole) {
            this.editRole.permissionList.forEach((permission: any) => {
              this.onSelectedPermission(permission.id);
            });
          }
        },
        error => {
          if (error.hasOwnProperty('error') && error.error.hasOwnProperty('error_description')) {
            this.errorObject = Object.assign({}, {
              message: error.error.error_description,
              type: 'error'
            });
          } else {
            this.errorObject = Object.assign({}, {
              message: 'Error while getting permissions from DB',
              type: 'error'
            });
          }
        });
  }

  /*Set selected permission module*/
  public onSelectedModule(permissionModule: any): void {
    this.searchPermissionValue = '';
    this.selectedModule = permissionModule;
  }

  /*Set selected permission module*/
  public onSelectedPermission(permissionId: number): void {
    if (permissionId && this.selectedModule && this.selectedModule.permissions.length > 0) {
      this.permissionsByModule.content.forEach((permissionModule: any) => {
        permissionModule.permissions.forEach((permission: any) => {
          if (permission.id === permissionId) {
            permission.selected = !permission.selected;
          }
          permissionModule.selectedPermissionsNumber = permissionModule.permissions.filter((item: any) => item.selected).length;
        });
      });
      this.emitSelectedPermissions();
    }
  }

  /*Emit selected permissions*/
  private emitSelectedPermissions(): void {
    // @ts-ignore
    this.selectedPermissions = this.permissionsByModule.content.map((permissionModule: any) => {
      const roleObject = {
        name: '',
        permissions: []
      };
      if (permissionModule.hasOwnProperty('selectedPermissionsNumber') && permissionModule.selectedPermissionsNumber > 0) {
        roleObject.name = permissionModule.name;
        roleObject.permissions = permissionModule.permissions.filter((permission: any) => permission.selected);
        return roleObject;
      }

    }).filter((item: any) => item);

    if (this.selectedPermissions.length > 0) {
      this.onSelectPermission.emit(this.selectedPermissions);
      this.isDataFormInvalid.emit(false);
    } else {
      this.isDataFormInvalid.emit(true);
    }
  }

  /*Remove selected permission*/
  public onRemovePermission(permission: any, permissionModule: any): void {
    permission.selected = false;
    permissionModule.selectedPermissionsNumber -= 1;
    this.emitSelectedPermissions();
  }

  /*Search permissions*/
  public onSearchPermission(): void {
    const foundPemrmissions = {
      permissions: []
    };
    if (this.searchPermissionValue.length > 0) {
      this.permissionsByModule.content.forEach((permissionModule: any) => {
        permissionModule.permissions.forEach((permission: any) => {
          if (permission.description.toLowerCase().search(this.searchPermissionValue.toLowerCase()) >= 0) {
            // @ts-ignore
            foundPemrmissions.permissions.push(permission);
          }
        });
      });
    }
    this.selectedModule = this.searchPermissionValue.length > 0 ? foundPemrmissions : this.permissionsByModule.content[0];
  }

  /*Set selected module CSS class*/
  public selectedModuleCssClass(permissionModule:any): string {
    return this.selectedModule && this.selectedModule.name === permissionModule.name && this.searchPermissionValue.length === 0 ? 'mc-gray-background' : '';
  }

  /*Set selected permission CSS class*/
  public selectedPermissionCssClass(permission:any): string {
    return permission.hasOwnProperty('selected') && permission.selected ? 'mc-selected-row' : '';
  }

  /*Set all modules CSS class*/
  public setAllModulesCssClass(): string {
    return this.searchPermissionValue.length > 0 ? 'mc-gray-background' : '';
  }
}
