/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McBillingBatch2TransactionFilter} from '../models/mc-billing-batch-2-transaction-filter.model';

export class McBillingBatch2TransactionFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingBatch2TransactionFilter {
    console.error('MTCN-ERROR: Not Implemented: McBillingBatch2TransactionFilter::createNewInstance(). Add this method to final class and return new McBillingBatch2TransactionFilter();');
      throw new Error('Cannot McBillingBatch2TransactionFilter::createNewInstance(). Add this method to final class and return new McBillingBatch2TransactionFilter();');
  }

}
