/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McProcessFlowCriteriaAttribute} from '../models/mc-process-flow-criteria-attribute.model';
import {McProcessFlowCriteriaAttributeList} from '../models/mc-process-flow-criteria-attribute-list.model';
import {McProcessFlowCriteriaAttributeFilter} from '../models/mc-process-flow-criteria-attribute-filter.model';
import {McProcessFlowCriteriaAttributeService} from '../services/mc-process-flow-criteria-attribute.service';
import {IMcProcessFlowCriteriaAttribute} from '../_generated/mc-process-flow-criteria-attribute.interface';
import {McGod} from "../extra/mc-god.service";

export class McProcessFlowCriteriaAttributeListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'attributeName', 'attributeTypeCd', 'booleanOperatorTypeCd', 'datetimeOperatorTypeCd', 'datetimeOptionTypeCd', 'moneyOperatorTypeCd', 'moneyOptionTypeCd', 'numberOperatorTypeCd', 'numberOptionTypeCd', 'stringOperatorTypeCd', 'stringOptionTypeCd', 'value'];
  public items: McProcessFlowCriteriaAttribute[] = [];
  public apiService: McProcessFlowCriteriaAttributeService;
 // protected tempObjList: McProcessFlowCriteriaAttributeList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProcessFlowCriteriaAttributeList {
    console.error('MTCN-ERROR: Not Implemented: McProcessFlowCriteriaAttributeList::createNewInstance(). Add this method to final class and return new McProcessFlowCriteriaAttributeList();');
    throw new Error('Cannot McProcessFlowCriteriaAttributeList::createNewInstance(). Add this method to final class and return new McProcessFlowCriteriaAttributeList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcProcessFlowCriteriaAttribute[]): McProcessFlowCriteriaAttributeList {
    const  listObj: McProcessFlowCriteriaAttributeList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McProcessFlowCriteriaAttribute = McProcessFlowCriteriaAttribute.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McProcessFlowCriteriaAttributeList {
    const  listObj: McProcessFlowCriteriaAttributeList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessFlowCriteriaAttributeService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcProcessFlowCriteriaAttribute[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McProcessFlowCriteriaAttribute = McProcessFlowCriteriaAttribute.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcProcessFlowCriteriaAttributeFilter: McProcessFlowCriteriaAttributeFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcProcessFlowCriteriaAttributeFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McProcessFlowCriteriaAttribute {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McProcessFlowCriteriaAttribute();
    }
  }

  public replaceItem(newItem: McProcessFlowCriteriaAttribute) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

