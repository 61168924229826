/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McConsumerImport} from '../models/mc-consumer-import.model';
import {McConsumerImportService} from '../services/mc-consumer-import.service';
import {IMcConsumerImport} from '../_generated/mc-consumer-import.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McConsumerImportGENERATED extends BaseObject {


    public static readonly IMPORT_MODE_CD_ADD: string = 'ADD';
    public static readonly IMPORT_MODE_CD_ADDUPD: string = 'ADDUPD';
    public static readonly IMPORT_MODE_CD_UPD: string = 'UPD';
  public static readonly IMPORT_MODE_CD_LIST__ALL = [
    McConsumerImportGENERATED.IMPORT_MODE_CD_ADD,
    McConsumerImportGENERATED.IMPORT_MODE_CD_ADDUPD,
    McConsumerImportGENERATED.IMPORT_MODE_CD_UPD
];

    public static readonly STATUS_CD_SUCCESS: string = 'SUCCESS';
  public static readonly STATUS_CD_LIST__ALL = [
    McConsumerImportGENERATED.STATUS_CD_SUCCESS
];

  public apiService: McConsumerImportService;
  public _rawJson: IMcConsumerImport;
    id: number = 0;
  addedRecords: number;
  createdAt: number;
  createdBy: number;
  dateFormat = '';
  idMcEntity: number;
  idMcSystemUser: number;
  idWebFile: number;
  importModeCd: string;
  processedRecords: number;
  skippedRecords: number;
  statusCd: string;
  updatedAt: number;
  updatedBy: number;
  updatedRecords: number;
  importName: string;  userName: string;

  public properties: string[] = ['id', 'addedRecords', 'createdAt', 'createdBy', 'dateFormat', 'idMcEntity', 'idMcSystemUser', 'idWebFile', 'importModeCd', 'processedRecords', 'skippedRecords', 'statusCd', 'updatedAt', 'updatedBy', 'updatedRecords', 'importName', 'userName'];
  public propertiesRegular: string[] = ['id', 'addedRecords', 'createdAt', 'createdBy', 'dateFormat', 'idMcEntity', 'idMcSystemUser', 'idWebFile', 'importModeCd', 'processedRecords', 'skippedRecords', 'statusCd', 'updatedAt', 'updatedBy', 'updatedRecords', 'importName', 'userName'];
  public propertiesSpecial: string[] = [];



  public static getImportModeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McConsumerImportGENERATED.IMPORT_MODE_CD_ADD, McGod.t('Add new'));
    list.add(McConsumerImportGENERATED.IMPORT_MODE_CD_ADDUPD, McGod.t('Add new & update existing'));
    list.add(McConsumerImportGENERATED.IMPORT_MODE_CD_UPD, McGod.t('Update existing'));


    return list;
  }

  public static getImportModeCdLabel(aCd: string): string {
    const list = McConsumerImportGENERATED.getImportModeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McConsumerImportGENERATED.STATUS_CD_SUCCESS, McGod.t('Success'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McConsumerImportGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McConsumerImport {
    console.error('MTCN-ERROR: Not Implemented: McConsumerImport::createNewInstance(). Add this method to final class and return new McConsumerImport();');
    throw new Error('Cannot McConsumerImport::createNewInstance(). Add this method to final class and return new McConsumerImport();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcConsumerImport): McConsumerImport {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McConsumerImportService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McConsumerImport AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getImportModeCdLabel(): string {
    return McConsumerImportGENERATED.getImportModeCdLabel(this.importModeCd);
  }


 public  isImportModeCdADD(): boolean {
    const result = (this.importModeCd === McConsumerImportGENERATED.IMPORT_MODE_CD_ADD);

    return result;
  }

 public  isImportModeCdADDUPD(): boolean {
    const result = (this.importModeCd === McConsumerImportGENERATED.IMPORT_MODE_CD_ADDUPD);

    return result;
  }

 public  isImportModeCdUPD(): boolean {
    const result = (this.importModeCd === McConsumerImportGENERATED.IMPORT_MODE_CD_UPD);

    return result;
  }


  public getStatusCdLabel(): string {
    return McConsumerImportGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdSUCCESS(): boolean {
    const result = (this.statusCd === McConsumerImportGENERATED.STATUS_CD_SUCCESS);

    return result;
  }


  
  // ---------------------------------------------------------------------
  public loadByEntityIdAndWebFileId( idMcEntity: number, idWebFile: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByEntityIdAndWebFileId(idMcEntity,idWebFile)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

}
