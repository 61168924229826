/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EbTransactionAdjustmentTable3ConfigGENERATED} from '../_generated/eb-transaction-adjustment-table-3-config-generated.model';

// import {EbTransactionAdjustmentGENERATED} from '../_generated/eb-transaction-adjustment-generated.model';

export class McProcessItemTransactionAdjustmentTable3ConfigModel extends EbTransactionAdjustmentTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.clearColumns();
    //   this.addColumn('colIdEbSepaFileTransaction', 'SEPA file Transaction ID', 'idEbSepaFileTransaction', 'idEbSepaFileTransaction');
    // this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colTypeCd', this.t('cc.jobs.edit.type'), 'getTypeCdLabel()', 'typeCd');
    this.addColumn('colCreatedDatetime', this.t('cc.my-factoring.date-&-time'), 'getCreatedDateTimeString()', 'createdDatetime');
    this.addColumn('colDescription', this.t('cc.common.view.description'), 'description', 'description');
    this.addColumn('colAmount', this.t('cc.factoring.transactions.amount'), 'fldAmountLoc()', 'amount').extraCssClass = 'mc-text-align-right';
    this.addColumn('colPaymentMethod', this.t('cc.my-factoring.payment-method'), 'getPaymentMethodCdLabel()', 'paymentMethodCd').extraCssClass = 'mc-text-align-right';
    this.addColumn('colCreatedBy', this.t('cc.consumers.imports.created-by'), 'createdBy', 'createdBy').extraCssClass = 'mc-text-align-right';

//    this.addColumn('colCreatedUserId', 'Created User Id', 'createdUserId', 'createdUserId');


  }
}
