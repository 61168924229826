import {Inject, Injectable} from '@angular/core';
import {McBillingPayoutTableItemServiceGENERATED} from '../_generated/mc-billing-payout-table-item-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McBillingPayoutTableItemService extends McBillingPayoutTableItemServiceGENERATED {

}
