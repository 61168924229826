import {Component, Inject, OnInit} from '@angular/core';
import {ICON_CLOSE} from "../../../../assets/media/svg_icons/icon-close";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {McProcessNotification} from "@miticon-ui/mc-core";

@Component({
  selector: 'lib-mc-process-email-view-dialog',
  templateUrl: './mc-process-email-view-dialog.component.html',
  styleUrls: ['./mc-process-email-view-dialog.component.scss']
})
export class McProcessEmailViewDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { processName: string, emailBody: string },
              public dialogRef: MatDialogRef<McProcessEmailViewDialogComponent>) { }

  ngOnInit(): void {
    let emailText;
    emailText = this.data.emailBody.replace(/\n/g, "<br><br>");

    // @ts-ignore
    document.getElementById("emailBody").innerHTML = emailText;
  }

}
