/*BbmTestObj*/
import {CsrContractHistoryGENERATED} from '../_generated/csr-contract-history-generated.model';
import {McDateUtils} from "../../projects";

export class CsrContractHistory extends CsrContractHistoryGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrContractHistory();
  }

  // ---------------------------------------------------------------------
  formatCreationDate(){
    return McDateUtils.formatDateToString(this.sysCreatedDatetime, 'MMM D, YYYY');
  }
}
