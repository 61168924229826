<div *ngIf="isVisible()" class="mcc-modal modal show d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Type of request name</h1>
        <button type="button" class="close" (click)="actCancel()">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">

          <mcc-fi-textbox [name]="'requestType'" [label]="'Type of request name'"
                          [validRequiredFlg]="true"
                          #requestType
                          [mcForm]="mcForm">
          </mcc-fi-textbox>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="actCancel()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="addRequest(requestType)">Add</button>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>

