/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingPayoutFileItemFeCtdTable3ConfigGENERATED} from '../_generated/mc-billing-payout-file-item-fe-ctd-table-3-config-generated.model';

// import {McBillingPayoutFileItemFeCtdGENERATED} from '../_generated/mc-billing-payout-file-item-fe-ctd-generated.model';

export class McBillingPayoutFileItemFeCtdTable3Config extends McBillingPayoutFileItemFeCtdTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colEntityName', this.t('cc.common.view.entity'), 'entityName', 'entityName');
    this.addColumn('colStudioOwner', this.t('cc.common.account-owner'), 'accountOwner', 'studioOwner');
    this.addColumn('colIban', 'IBAN', 'iban', 'iban');
    this.addColumn('colBic', 'BIC', 'bic', 'bic');
    this.addColumn('colAmount', this.t('cc.billing.total-amount'), 'totalAmountFormatted', 'amount');
    this.addColumn('colAmount', this.t('cc.common.payout-date'), 'getPayoutDate()', 'payoutDate');
    this.addColumn('colDescription', this.t('cc.common.view.description'), 'description', 'description');


  }
}
