import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ICsrContractPreDefined} from '../_generated/csr-contract-pre-defined.interface';
import {CsrContractPreDefined} from '../models/csr-contract-pre-defined.model';
import {CsrContractPreDefinedList} from '../models/csr-contract-pre-defined-list.model';
import {CsrContractPreDefinedServiceGENERATED} from '../_generated/csr-contract-pre-defined-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import * as CircularJSON from "circular-json";


@Injectable({
  providedIn: 'root'
})
export class CsrContractPreDefinedService extends CsrContractPreDefinedServiceGENERATED {

  override insert(aCsrContractPreDefined: any): Observable<ICsrContractPreDefined> {
    return this.httpClient.post<ICsrContractPreDefined>(this.getApiServiceRootUrl()  , aCsrContractPreDefined);
  }

  override update(aCsrContractPreDefined: any): Observable<ICsrContractPreDefined> {
    return this.httpClient.put<ICsrContractPreDefined>(this.getApiServiceRootUrl() + `/`+ aCsrContractPreDefined.id, aCsrContractPreDefined);
  }

  public getAvailableForPurchase(idConsumer: number): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/available-for-purchase/${idConsumer}`);
  }
}
