import {McBillingPayoutTableItem} from './mc-billing-payout-table-item.model';
import {McBillingPayoutTableItemListGENERATED} from '../_generated/mc-billing-payout-table-item-list-generated.model';

export class McBillingPayoutTableItemList extends McBillingPayoutTableItemListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingPayoutTableItemList();
  }

  // ---------------------------------------------------------------------
}
