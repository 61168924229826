import {McGod} from '../extra/mc-god.service';
import {McSlideActionItem} from '../_core/mc-slide-action-item';

export class McSlideActionSet {
  items = new Array<McSlideActionItem>();
  permissions!: string[];

  constructor() {
  }

  public addItem(label: string, actionCdForEvent: string, permissions: string[], numberOfMaxSelectedItems: number = -1, extraCssClass: string = '') {
    if (McGod.userHasPermissions(permissions)) {
      if (!this.items.find(item => item.actionCdForEvent === actionCdForEvent)) {
        const item = new McSlideActionItem(label, actionCdForEvent, permissions, false, numberOfMaxSelectedItems, extraCssClass);
        this.items.push(item);
      }
    }
    return this;
  }

  public addDivider(extraCssClass: string = '') {
    const item = new McSlideActionItem('', '', [], true, -1, extraCssClass);
    this.items.push(item);
    return this;
  }

  deleteItem(actionCdForEvent: string) {
    const index = this.items.findIndex(item => item.actionCdForEvent === actionCdForEvent);
    if (index !== -1) {
      this.items.splice(index, 1);
    }
  }


}
