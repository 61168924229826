<div *ngIf="isVisible()" class="mcc-modal modal show d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" *ngIf="ebFactoringId > 0">Edit factoring {{ebFactoringId}}</h1>
        <h1 class="modal-title" *ngIf="ebFactoringId == 0">{{'cc.contract.add-new-factoring' | translate}}</h1>
        <button type="button" class="close" (click)="actCancel()">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="ebFactoring" >
        <div class="container-fluid">

          <app-shared-factoring-add-edit-part [isVisible]="visibleMcb"
                                              [idChildEntity]="idChildEntity"
                                              [ebFactoring]="ebFactoring"
                                              ></app-shared-factoring-add-edit-part>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="actCancel()">{{'cc.common.edit.cancel' | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="actSave()">{{'cc.common.view.save' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>

