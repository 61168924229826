import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {
  McBillingPayoutFileFeCtd, McBillingStatementFilter,
  McBillingStatementList,
  McBillingStatementTable3Config, McBoolean,
  McForm,
  McValueLabelList,
  WebFile,
  SortCriteriaList, BaseDirective, McBankAccountList, McGod, McBillingStatement
} from '@miticon-ui/mc-core';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'mc-out-billing-payout-file-add-edit-part',
  templateUrl: './mc-out-billing-payout-file-add-edit-part.component.html',
  styleUrls: ['./mc-out-billing-payout-file-add-edit-part.component.scss'],
})
export class McOutBillingPayoutFileAddEditPartComponent extends BaseDirective implements OnInit {

  errorMsg = '';
  successMsg = '';
  loadingFlg = false;
  mcForm = new McForm();
  statementList = new McBillingStatementList();
  statementFilter = new McBillingStatementFilter();
  selectedStatementList = new McBillingStatementList();
  payoutFile = new McBillingPayoutFileFeCtd();
  statementTable3Config = new McBillingStatementTable3Config(false);
  fileCreatedFlg = false;
  webFile = new WebFile();
  saveButtonLabel = '<i class="fas fa-plus"></i> Create';
  entityVll = new McValueLabelList();
  clearedAllMultipleFilters = new Subject<boolean>();
  isFilterButtonDisabled!: boolean;
  isCreateButtonDisabled = new McBoolean();
  selectedEntityIds: any[] = [];
  statusCds: any[] = [];
  statementDate: any;
  createTypeVll = new McValueLabelList();
  showLoader = false;
  bankAccountsVll = new McValueLabelList();
  bankAccounts = new McBankAccountList();

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();

    constructor() {
        super();
    }

  ngOnInit() {
    this.isFilterButtonDisabled = false;
    this.isCreateButtonDisabled.setTrue();

    this.createTypeVll.add(McBillingPayoutFileFeCtd.TYPE_CD_STAR_MONEY, 'Star money');
    this.createTypeVll.add(McBillingPayoutFileFeCtd.TYPE_CD_EBICS, 'EBICS');

    this.loadBankAccountList();
    this.actLoad();


  }

  getUniqueValueLabelList(list: McValueLabelList) {
    const uniqueListItems = list.items
      .map(e => e.value)
      .map((e, i, final) => final.indexOf(e) === i && i)
      // @ts-ignore
      .filter(obj => list.items[obj])
      // @ts-ignore
      .map(e => list.items[e]);
    return uniqueListItems;
  }

  loadBankAccountList() {
    this.bankAccounts.loadByEntityId(McGod.getLoggedEntityIdFromToken(), () => {
      this.bankAccounts.items.map(item => {
        const title = `${item.bankName} (IBAN: ${item.iban}, BIC: ${item.blz})`;
        this.bankAccountsVll.add(item.id, title);
      });
    });
  }

  doFilterPropertiesHaveValues(): boolean | undefined {
    const searchTerm = this.mcForm.getValue('txbSearchTerm');
    const idsOfChildrenEntities = this.selectedEntityIds;
    const statusCds = this.statusCds;
    const flgOutBilling = true;
    const statementDate = this.statementDate;
    if (searchTerm === '' && idsOfChildrenEntities.length === 0 && statusCds.length === 0 && flgOutBilling && (statementDate === undefined || statementDate === null)) {
      return true;
    }
    return undefined;
  }


  actLoad() {
    this.loadingFlg = true;
    this.statementFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.statementFilter.idsOfChildrenEntities = this.selectedEntityIds;
    this.statementFilter.statusCds = this.statusCds;
    this.statementFilter.flgOutBilling = true;
    this.statementFilter.statementDate = this.statementDate;

    this.statementList.loadByFilter(this.statementFilter, () => {
      if (this.statementList.getCount() === 0 && this.doFilterPropertiesHaveValues()) {
        this.isFilterButtonDisabled = true;
      } else {
        this.isFilterButtonDisabled = false;
      }
      this.statementList.items = this.statementList.items
        .filter((item: McBillingStatement) => item.isStatusCdFINALIZED() && !item.idPayoutFile);
      this.entityVll = new McValueLabelList();
      this.statementList.items.sort((a, b) => {
        const nameA = a.childEntityName.toUpperCase();
        const nameB = b.childEntityName.toUpperCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
      this.statementList.items.map(it => this.entityVll.add(it.childEntityId, it.childEntityName));
      this.entityVll.items = this.getUniqueValueLabelList(this.entityVll);
      this.loadingFlg = false;
    });
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    if (this.mcForm.getControl('ctrlStatementDate')) {
      this.mcForm.getControl('ctrlStatementDate')?.get('value')?.setValue(null);
    }
    this.clearedAllMultipleFilters.next(true);
    this.actLoad();
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actSave() {
    if (this.mcForm.isValid()) {
      this.showLoader = true;
      const selectedStatementIds = this.selectedStatementList.items.map(item => item.id);
      const bankAccountId = this.mcForm.getValue('selBankAccount');
      this.payoutFile.apiService.createPayoutFile(this.mcForm.getValue('ctrlCreateType'), selectedStatementIds, bankAccountId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res) => {
          this.eventSaved.emit();
          this.showLoader = false;
        });
    } else {
      this.showLoader = false;
    }
  }

  actSelectedItems(selectedList: McBillingStatementList) {
    this.selectedStatementList = selectedList;
    if (this.selectedStatementList.items.length > 0) {
      this.isCreateButtonDisabled.setFalse();
    } else {
      this.isCreateButtonDisabled.setTrue();
    }
  }

  onSelectEntity(value: any[]) {
    this.selectedEntityIds = value;
  }

  onStatementDateValueChanged(value: any) {
    if (value === null) {
      this.statementDate = null;
    } else {
      this.statementDate = value;
    }
  }

  onCreateTypeChanged($event: unknown) {

  }
}
