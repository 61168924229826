/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {ZzPocCar} from '../models/zz-poc-car.model';
import {ZzPocCarService} from '../services/zz-poc-car.service';
import {IZzPocCar} from '../_generated/zz-poc-car.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';




export abstract class ZzPocCarGENERATED extends BaseObject {







    public static readonly TYPE_CD_SUV: string = 'S';
    public static readonly TYPE_CD_COMPACT: string = 'C';
    public static readonly TYPE_CD_ECONOMY: string = 'E';
    public static readonly TYPE_CD_TRUCK: string = 'T';
    public static readonly TYPE_CD_BUS: string = 'B';
  public static readonly TYPE_CD_LIST__ALL = [
    ZzPocCarGENERATED.TYPE_CD_SUV,
    ZzPocCarGENERATED.TYPE_CD_COMPACT,
    ZzPocCarGENERATED.TYPE_CD_ECONOMY,
    ZzPocCarGENERATED.TYPE_CD_TRUCK,
    ZzPocCarGENERATED.TYPE_CD_BUS
];

  public apiService: ZzPocCarService;
  public _rawJson: IZzPocCar;
    id: number = 0;
  description = '';
  horsePower: number;
  sysCreatedUserId: number;
  sysUpdatedUserId: number;
  title = '';
  typeCd: string;


  public properties: string[] = ['id', 'description', 'horsePower', 'sysCreatedUserId', 'sysUpdatedUserId', 'title', 'typeCd'];
  public propertiesRegular: string[] = ['id', 'description', 'horsePower', 'sysCreatedUserId', 'sysUpdatedUserId', 'title', 'typeCd'];
  public propertiesSpecial: string[] = [];







  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(ZzPocCarGENERATED.TYPE_CD_SUV, McGod.t('Sports Utility Vehicle'));
    list.add(ZzPocCarGENERATED.TYPE_CD_COMPACT, McGod.t('Compact'));
    list.add(ZzPocCarGENERATED.TYPE_CD_ECONOMY, McGod.t('Economy'));
    list.add(ZzPocCarGENERATED.TYPE_CD_TRUCK, McGod.t('Truck'));
    list.add(ZzPocCarGENERATED.TYPE_CD_BUS, McGod.t('Bus'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = ZzPocCarGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): ZzPocCar {
    console.error('MTCN-ERROR: Not Implemented: ZzPocCar::createNewInstance(). Add this method to final class and return new ZzPocCar();');
    throw new Error('Cannot ZzPocCar::createNewInstance(). Add this method to final class and return new ZzPocCar();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IZzPocCar): ZzPocCar {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(ZzPocCarService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE ZzPocCar AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  






  public getTypeCdLabel(): string {
    return ZzPocCarGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdSUV(): boolean {
    const result = (this.typeCd === ZzPocCarGENERATED.TYPE_CD_SUV);

    return result;
  }

 public  isTypeCdCOMPACT(): boolean {
    const result = (this.typeCd === ZzPocCarGENERATED.TYPE_CD_COMPACT);

    return result;
  }

 public  isTypeCdECONOMY(): boolean {
    const result = (this.typeCd === ZzPocCarGENERATED.TYPE_CD_ECONOMY);

    return result;
  }

 public  isTypeCdTRUCK(): boolean {
    const result = (this.typeCd === ZzPocCarGENERATED.TYPE_CD_TRUCK);

    return result;
  }

 public  isTypeCdBUS(): boolean {
    const result = (this.typeCd === ZzPocCarGENERATED.TYPE_CD_BUS);

    return result;
  }


  
}
