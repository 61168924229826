import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'mc-checkbox',
  templateUrl: './mc-checkbox.component.html'
})
export class McCheckboxComponent implements OnInit{

  /*Events*/
  @Input() setup!: any;
  @Input() config!: any;
  @Output() newSetup = new EventEmitter();

  /*On init class*/
  ngOnInit() {
    this.setup.checked = this.setup.value ;
  }

  /*On change*/
  public onChangeCheckBox() {
    this.setup.checked = !this.setup.checked;
    this.setup.value = this.setup.checked ? this.setup.checked : "";
    this.newSetup.emit(this.setup);
  }

}
