<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcBillingStatementItem.apiLoadingFlg || mcBillingItemList.apiLoadingFlg || loadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h1>Add item(s)</h1>
  </div>


  <div class="content-body">
    <mcc-message [errorMsg]="mcBillingStatementItem.apiErrorMessage"></mcc-message>
    <mcc-message [errorMsg]="errorMsg"></mcc-message>

      <div *ngIf="!loadingFlg" class="row">
        <div class="col-md-12">
          <div class="content-section">

            <div class="info-container2 p-3">
              <mcc-row-title-description title="{{'cc.billing.number-of-items' | translate}}" [description]="numberOfItems"></mcc-row-title-description>
              <mcc-row-title-description title="Amount:" [description]="formatNumberToMoneyWithExactCurrency(amount)"></mcc-row-title-description>
              <mcc-row-title-description title="VAT:" [description]="formatNumberToMoneyWithExactCurrency(vat)"></mcc-row-title-description>
              <mcc-row-title-description title="Total:" [description]="formatNumberToMoneyWithExactCurrency(totalAmount)"></mcc-row-title-description>
            </div>

            <div class="d-flex p-3">
              <mcc-fi-radio-buttons2 label="Aggregate"
                                     name="ctrlAggregate"
                                     [mcForm]="mcForm"
                                     [validRequiredFlg]="true"
                                     [directionHorizontal]="false"
                                     [optionsVll]="aggregateYesNoVll"></mcc-fi-radio-buttons2>
              <div class="d-flex flex-column mc-submit-color justify-content-center">
                <div class="icon-tooltip mt-4"><i class="fas fa-info-circle"></i>
                  <span class="icon-tooltip-text">Billing items would be grouped by billing type.</span></div>
                <div class="icon-tooltip"><i class="fas fa-info-circle"></i>
                  <span class="icon-tooltip-text">Billing items would not be grouped.</span></div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="mc-filter fancy-label">
                  <div class="extra-wide-input">
                        <mcc-multiselect-filter [optionVll]="typeCdVll" [clearedAllFilters]="clearedAllMultipleFilters"
                                                label="Item" name="selType"
                                                (eventSelectedItems)="onSelectType($event)">
                        </mcc-multiselect-filter>
                    </div>

                  <!--<mcc-fi-textbox label="{{'cc.common.search' | translate}}" name="txbSearchTerm" (eventEnterClicked)="tablLoad()"
                                  [mcForm]="mcForm"></mcc-fi-textbox>-->

                  <button class="mc-link mc-filter-clear-button filter-clear-top-right"
                          (click)="actClearFilter()">{{'cc.common.filter.clear-all' | translate}}</button>
                  <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12" *ngIf="mcBillingItemList.apiSuccessFlg">
                <mcc-fi-table-3 [objectList]="mcBillingItemList"
                                [table3Config]="mcBillingItemTable3Config"
                                (eventSelectionChanged)="onTableSelectionChange($event)"
                                (eventPaginationOrSortChanged)="actLoad()"></mcc-fi-table-3>
              </div>
            </div>

          </div>
        </div>
      </div><!-- row -->

  </div><!-- content-body -->

  <div class="row">
    <div class="col-md-12 position-relative">
      <div class="above-footer">
        <mcc-message [errorMsg]="errorMsg"></mcc-message>
      </div>
    </div>
  </div>

  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="Cancel"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actSave();" label="Add" [disabledMcb]="saveButtonDisabledMcb"
                disableOnFirstClickFlg="true"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
