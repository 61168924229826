/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McActionPdfTemplateTable3ConfigGENERATED} from '../_generated/mc-action-pdf-template-table-3-config-generated.model';

// import {McActionPdfTemplateGENERATED} from '../_generated/mc-action-pdf-template-generated.model';

export class McActionPdfTemplateTable3Config extends McActionPdfTemplateTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colTitle', 'McActionPdfTemplate title', 'title', 'title');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colTypeCd', 'McActionPdfTemplate type code', 'typeCd', 'typeCd');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');


  }
}
