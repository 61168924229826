import {McProcessLevel} from './mc-process-level.model';
import {McProcessLevelListGENERATED} from '../_generated/mc-process-level-list-generated.model';

export class McProcessLevelList extends McProcessLevelListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessLevelList();
  }

  // ---------------------------------------------------------------------
}
