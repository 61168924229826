/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McProcessRunItem} from '../models/mc-process-run-item.model';
import {McProcessRunItemList} from '../models/mc-process-run-item-list.model';
import {McProcessRunItemFilter} from '../models/mc-process-run-item-filter.model';
import {McProcessRunItemService} from '../services/mc-process-run-item.service';
import {IMcProcessRunItem} from '../_generated/mc-process-run-item.interface';
import {McGod} from "../extra/mc-god.service";

export class McProcessRunItemListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'buildNextActionStartDatetime', 'builtFlg', 'builtPaymentDueDatetime', 'feesAddedToTransactionFlg', 'idCsrConsumerAction', 'idMcProcessFlow', 'idMcProcessFlowLevel', 'idMcProcessItem', 'idMcProcessRun', 'lastErrorMsg', 'newItemFlg', 'statusCd', 'csrConsumerAction', 'mcProcessFlowLevelName', 'mcProcessFlowName', 'mcProcessItem'];
  public items: McProcessRunItem[] = [];
  public apiService: McProcessRunItemService;
 // protected tempObjList: McProcessRunItemList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProcessRunItemList {
    console.error('MTCN-ERROR: Not Implemented: McProcessRunItemList::createNewInstance(). Add this method to final class and return new McProcessRunItemList();');
    throw new Error('Cannot McProcessRunItemList::createNewInstance(). Add this method to final class and return new McProcessRunItemList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcProcessRunItem[]): McProcessRunItemList {
    const  listObj: McProcessRunItemList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McProcessRunItem = McProcessRunItem.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McProcessRunItemList {
    const  listObj: McProcessRunItemList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessRunItemService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcProcessRunItem[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McProcessRunItem = McProcessRunItem.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcProcessRunItemFilter: McProcessRunItemFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcProcessRunItemFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McProcessRunItem {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McProcessRunItem();
    }
  }

  public replaceItem(newItem: McProcessRunItem) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadByIdMcProcessRunAndNewItemFlg( idMcProcessRun: number, newItemFlg: boolean, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByIdMcProcessRunAndNewItemFlg(idMcProcessRun,newItemFlg, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

  // ---------------------------------------------------------------------
  public loadByIdMcProcessRunAndStatusCd( idMcProcessRun: number, statusCd: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByIdMcProcessRunAndStatusCd(idMcProcessRun,statusCd, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

