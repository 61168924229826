/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McProductSnapshotTable3ConfigGENERATED} from '../_generated/mc-product-snapshot-table-3-config-generated.model';

// import {McProductSnapshotGENERATED} from '../_generated/mc-product-snapshot-generated.model';

export class McProductSnapshotTable3Config extends McProductSnapshotTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdMcProduct', 'Product', 'idMcProduct', 'idMcProduct');
    this.addColumn('colIdVat', 'Vat id', 'idVat', 'idVat');
    this.addColumn('colIdCurrency', 'Currency id', 'idCurrency', 'idCurrency');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdPpCategory', 'Category Id', 'idPpCategory', 'idPpCategory');
    this.addColumn('colExternalId', 'External Id', 'externalId', 'externalId');
    this.addColumn('colName', 'Name', 'name', 'name');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colState', 'state', 'state', 'state');
    this.addColumn('colUnitPrice', 'Unit price', 'unitPrice', 'unitPrice');
    this.addColumn('colUnitsInStock', 'Units In Stock', 'unitsInStock', 'unitsInStock');
    this.addColumn('colAvailableFrom', 'avai from', 'availableFrom', 'availableFrom');
    this.addColumn('colAvailableTo', 'avail to', 'availableTo', 'availableTo');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');


  }
}
