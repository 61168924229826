<div id="mcc-fi-{{name}}"
     class="mcc-fi mcc-fi-itemlist-checkboxes mcc-fi-itemlist-checkboxes-{{name}} {{extraCssClass}} {{labelPositionClass}}">
  <form [formGroup]="getCheckBoxesForm()"
        [ngClass]="{'validation-error' : this.getCheckBoxesForm()?.get('errorFlg')?.value}">
    <label *ngIf="label" [ngClass]="{'mc-mandatory-star' : validRequiredFlg}">{{label}}</label>
    <ul [ngClass]="directionHorizontal ? 'direction-row' : 'direction-column'">
        <ng-container *ngFor="let item of itemList.items">
          <li *ngIf="filterItem(item)">
            <input
              type="checkbox"
              id="{{getItemCtrlName(item)}}"
              value="{{getItemValue(item)}}"
              formControlName="{{getItemCtrlName(item)}}">
            <label for="{{getItemCtrlName(item)}}">{{getItemLabel(item)}}
              <div *ngIf="hasItemDescription(item)" class="description">
                {{getItemDescription(item)}}
              </div>
            </label>
          </li>
        </ng-container>
      </ul>
  </form>
  <div class="error-msg">{{this.getCheckBoxesForm()?.get('errorMsg')?.value}}</div>
</div>

