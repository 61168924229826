import {McBillingProcessedTransactionsInfoTable} from './mc-billing-processed-transactions-info-table.model';
import {McBillingProcessedTransactionsInfoTableListGENERATED} from '../_generated/mc-billing-processed-transactions-info-table-list-generated.model';

export class McBillingProcessedTransactionsInfoTableList extends McBillingProcessedTransactionsInfoTableListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingProcessedTransactionsInfoTableList();
  }

  // ---------------------------------------------------------------------
}
