import {EbStarmoneyFileFilterGENERATED} from '../_generated/eb-starmoney-file-filter-generated.model';

export class EbStarmoneyFileFilter extends EbStarmoneyFileFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbStarmoneyFileFilter();
  }

  // ---------------------------------------------------------------------
}
