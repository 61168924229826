/*BbmTestObj*/
import {CsrContract2ProductGENERATED} from '../_generated/csr-contract-2-product-generated.model';

export class CsrContract2Product extends CsrContract2ProductGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrContract2Product();
  }

  // ---------------------------------------------------------------------
}
