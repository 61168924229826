import {McBillingService2PriceListFilterGENERATED} from '../_generated/mc-billing-service-2-price-list-filter-generated.model';

export class McBillingService2PriceListFilter extends McBillingService2PriceListFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingService2PriceListFilter();
  }

  // ---------------------------------------------------------------------
}
