import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class McCreateUserService {

  // @ts-ignore
  private token = JSON.parse(localStorage.getItem('mc-user')).access_token;
  private apiUrl: string;
  private httpOptions;

  constructor(private http: HttpClient,
              @Inject('environment') environment: any
  ) {
    /*Set API base url*/
    this.apiUrl = environment.apiUrl;
    this.httpOptions = environment.httpOptions(HttpHeaders, this.token);
  }

  /* Check system user by email */
  public checkSystemUserByEmail(data: any) {
    const api = this.apiUrl + `/core/user/check/email`;
    return this.http.post<any>(api, data);
  }

  /* Get all roles */
  public getAllRolesService(data: {}) {
    const api = this.apiUrl + `/core/entity/role/search`;
    return this.http.post<any>(api, data);
  }

  /* Create User */
  public createNewUser(data: { email: any; firstName: any; gender: any; invitationRequired: boolean; isInvitationRequired: any; isoCountry: any; isoLanguage: any; lastName: any; nickname: any; rawImageData: string | null; roleIds: any; timezone: number; }) {
    const api = this.apiUrl + `/core/user`;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + '',
      'Content-Type': 'multipart/form-data',
    });
    // @ts-ignore
    return this.http.post<any>(api, data, headers);
  }

  /* Update User - incomplete profile*/
  public updateUserIncompleteProfile(data: { firstName: any; lastName: any; nickname: any; gender: any; isoCountry: any; isoLanguage: any; timezone: number; rawImageData: any; }) {
    const api = this.apiUrl + `/core/user`;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + '',
      'Content-Type': 'multipart/form-data',
    });
    // @ts-ignore
    return this.http.put<any>(api, data, headers);
  }

  /* Get all roles */
  public getAllUserDataById(data: number) {
    return this.http.get<any>(this.apiUrl + `/core/user/${data}`);
  }

  /* Update User Personal Info */
  public saveEditedUserPersonalInfo(data: { id: number; firstName: any; lastName: any; gender: any; isoCountry: any; isoLanguage: any; nickname: any; rawImageData: any; timezone: number; }) {
    const api = this.apiUrl + `/core/entity/user`;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + '',
      'Content-Type': 'multipart/form-data',
    });
    // @ts-ignore
    return this.http.put<any>(api, data, headers);
  }

  /* Update edited roles */
  public saveEditedUserRoles(data: { userId: number; roleIds: any; }) {
    const api = this.apiUrl + `/core/user/role`;
    return this.http.put<any>(api, data);
  }

  /*Update user language*/
  public setUserLanguage(language: { isoLanguage: any; }) {
    return this.http.put<any>(`${this.apiUrl}/core/user/language`, language);
  }

}


