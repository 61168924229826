import {Component, EventEmitter, OnInit, Output, } from '@angular/core';
import {McBoolean, McEntity2, McEntity2List, McEntity2Table3Config, McGod, McHtmlButton} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-factoring-select-entity-part',
  templateUrl: './app-shared-factoring-select-entity-part.component.html',
})
export class AppSharedFactoringSelectEntityPartComponent implements OnInit {

  /*Variables*/
  @Output() eventShowAddEditPart = new EventEmitter();
  @Output() selectedEntityId = new EventEmitter<number>();
  mcEntity2Id!: number;
  mcEntity2Table3Config = new McEntity2Table3Config();
  mcEntity2List = new McEntity2List();
  enableNext = false;
  showEnableDisableFactoringModal = new McBoolean();
  notifyMessage = {message: '', type: ''};
  mcGod = McGod.getInstance();

  ngOnInit() {
    this.actLoad();
  }

  actLoad() {
    this.mcEntity2List = new McEntity2List();
    this.mcEntity2List.setSingleSelectionFlg(true);
    this.mcEntity2List.setPager(0, 50);
    this.mcEntity2List.loadAllWhereParentEntityIsLoggedInEntity(() => {
      this.mcEntity2List.setSortB('name', 'ASC');
    });
  }

  goToNext() {
    this.eventShowAddEditPart.emit();
  }

  receiveStateOnSelect(event: any) {
    console.log(event);
    if (event.getCount() === 1 && event.items[0].factoringEnableFlg) {
      this.selectedEntityId.emit(event.items[0].id);
      this.enableNext = true;
      this.notifyMessage.message = '';
      this.notifyMessage.type = '';
    } else if (event.getCount() === 0 || event.items[0].factoringEnableFlg === false) {
      this.enableNext = false;
      if (event.getCount() === 0) {
        this.notifyMessage.message = '';
        this.notifyMessage.type = '';
      } else if (event.items[0].factoringEnableFlg === false) {
        this.notifyMessage.message = this.mcGod.t('cc.out-factoring.you-can-not-create-factoring-contract-for-entities-with-factoring-disabled');
        this.notifyMessage.type = 'error';
      }
    }
  }

  actEntity2TableItemAction(htmlButton: McHtmlButton) {
    if (htmlButton.actionCdForEvent === McEntity2.ACTION_ENABLE_FACTORING) {
      this.actShowEnableDisableModal(htmlButton.item);
    }
  }

  actShowEnableDisableModal(mcEntity2: McEntity2) {
    if (mcEntity2.id) {
      this.mcEntity2Id = mcEntity2.id;
    }
    this.showEnableDisableFactoringModal.setTrue();
  }

  onEnableDisableSaved() {
    this.showEnableDisableFactoringModal.setFalse();
    this.actLoad();
  }

  onEnableDisableCanceled() {
    this.showEnableDisableFactoringModal.setFalse();
  }
}
