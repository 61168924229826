import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {McPdfTemplate, WebFile, McFileUploadingTrigger} from '@miticon-ui/mc-core';
import {
  McForm,
  McBoolean,
  McGod,
  McTrigger,
  McValueLabelList
} from '@miticon-ui/mc-core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';

@Component({
  selector: 'mc-process-pdf-template-add-edit',
  templateUrl: './mc-process-pdf-template-add-edit.component.html',
})
export class McProcessPdfTemplateAddEditComponent implements OnInit {

  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  mcProcessStatusVll = new McValueLabelList();
  mcProcessTypeVll = new McValueLabelList();
  rawFile!: File;
  webFile = new WebFile();
  errorMsg = '';
  mcGod = McGod.getInstance();

  errorMsgs: string[] = [];
  mcFileUploadingTrigger = new McFileUploadingTrigger();

  // -- Input --
  @Input() mcPdfTemplateId!:number;
  @Input() mcPdfTemplate!: McPdfTemplate;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();


  // ---------------------------------------------------------------------
  ngOnInit() {
    this.mcProcessStatusVll.add(McPdfTemplate.STATUS_CD_ACTIVE, McPdfTemplate.getStatusCdLabel(McPdfTemplate.STATUS_CD_ACTIVE));
    this.mcProcessStatusVll.add(McPdfTemplate.STATUS_CD_INACTIVE, McPdfTemplate.getStatusCdLabel(McPdfTemplate.STATUS_CD_INACTIVE));
    this.mcProcessTypeVll.add(McPdfTemplate.TYPE_CD_DUNNING, McPdfTemplate.getTypeCdLabel(McPdfTemplate.TYPE_CD_DUNNING));
    this.mcProcessTypeVll.add(McPdfTemplate.TYPE_CD_MARKETING, McPdfTemplate.getTypeCdLabel(McPdfTemplate.TYPE_CD_MARKETING));

    if (!this.mcPdfTemplate) {
      this.mcPdfTemplate = new McPdfTemplate();
    }

    this.actLoad();

  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    if (this.mcPdfTemplateId > 0) {
      this.mcPdfTemplate.loadById(this.mcPdfTemplateId, () => {
        this.validatePrerequisites();
      }, () => {
        this.errorMsg = this.mcPdfTemplate.apiErrorMessage;
      });
    } else {
      // new item
      this.validatePrerequisites();
    }

  }

  // ---------------------------------------------------------------------
  actSave() {
    this.saveButtonDisabledMcb.setTrue();

    if (this.mcForm.isValid()) {

      this.mcFileUploadingTrigger.next(true);

    } else {
      this.saveButtonDisabledMcb.setFalse();
    }

  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.mcPdfTemplate.exists()) {

      this.mcPdfTemplate.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

// ---------------------------------------------------------------------
  validatePrerequisites() {
    this.errorMsgs = [];
    // populate validation here on required inputs
  }

  // ---------------------------------------------------------------------
  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }

  eventWebFileUploaded(webFile: WebFile) { // SAVING MC PROCESS PDF TEMPLATE
    if (webFile) {

      this.mcPdfTemplate.idWebfile = webFile.id;
      this.mcPdfTemplate.idMcEntity = McGod.getLoggedEntityIdFromToken();
      this.mcPdfTemplate.statusCd = this.mcForm.getValue('ctrlStatusCd');
      this.mcPdfTemplate.typeCd = this.mcForm.getValue('ctrlTypeCd');
      this.mcPdfTemplate.title = this.mcForm.getValue('ctrlTitle');
      this.mcPdfTemplate.description = this.mcForm.getValue('ctrlDescription');

      this.mcPdfTemplate.save(() => {
        this.eventSaved.emit();
      }, () => {
        this.saveButtonDisabledMcb.setFalse();
        this.errorMsg = this.mcPdfTemplate.apiErrorMessage;
      });
    }
  }

  eventWebFileUploadingFailed(errorMsg: string) {
    this.errorMsg = errorMsg;
    this.saveButtonDisabledMcb.setFalse();
  }
}
