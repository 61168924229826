
<section class="row py-4">

  <div class="col-md-3">
    <h3 class="mc-management-title d-inline">Imports</h3>
  </div>


  <!--  <div class="col-md-12 mc-create-user-notify-wrapper">-->
  <!--    <mc-notify-message *ngIf="responseMessageShow" [message]="notifyMsgText" [type]="notifyMsgType"></mc-notify-message>-->
  <!--  </div>-->

  <!--Filter component-->
  <div class="col-md-12 px-0 mt-4">
<!--    <mcc-filter [filterConfig]="filterConfig" (onFilterProceed)="onFilterProceed($event)"></mcc-filter>-->
  </div>

  <!--Table component-->
  <div  class="col-md-12 px-0">

    <mcc-fi-table-2 [objectList]="consumerImport"
                    [table2Config]="tableConfig">
    </mcc-fi-table-2>

  </div>


</section>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>


