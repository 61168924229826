<div class='dialog-wrapper'>
  <div class="dialog">
    <div class="dialog_header">
      <div class='dialog-icon'>
        <span [innerHTML]="data.icon | safeHtml"></span>
      </div>
      <span class="title-medium-dark">{{data.title}}</span>
      <span class="dialog_header_icon"
            [innerHTML]="iconClose | safeHtml"
            (click)="dialogRef.close()">
      </span>
    </div>

    <div>{{data.text}}</div>

    <div class='dialog-footer'>
      <button mat-button class="secondary-btn"
              (click)="dialogRef.close()">
        {{ data.button }}
      </button>
    </div>
  </div>
</div>
