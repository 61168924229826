import {McProcessFilterGENERATED} from '../_generated/mc-process-filter-generated.model';

export class McProcessFilter extends McProcessFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessFilter();
  }

  // ---------------------------------------------------------------------
}
