import {Component, OnInit} from '@angular/core';
import {
  McBoolean, EbSepaStatementReportList, EbSepaStatementReportFilter, EbSepaStatementReportTable3Config, McValueLabelList
} from '@miticon-ui/mc-core';
import {McForm} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-bank-module-star-money-list-page',
  templateUrl: './mc-bank-module-star-money-list-page.component.html',
})
export class McBankModuleStarMoneyListPageComponent implements OnInit {

  mcForm = new McForm();
  showImportStarmoneyMcb = new McBoolean();
  successMsg = '';
  importUsersVll = new McValueLabelList();
  ebSepaStatementReportList = new EbSepaStatementReportList();
  ebSepaStatementReportFilter = new EbSepaStatementReportFilter();
  ebSepaStatementReportTable3Config = new EbSepaStatementReportTable3Config();

  ngOnInit() {
    this.ebSepaStatementReportList.setPager(0, 100);
    this.actLoad();
  }

  actLoad(): void {
    this.ebSepaStatementReportFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.ebSepaStatementReportFilter.importedByUserId = this.mcForm.getValue('selImportedBy');
    this.ebSepaStatementReportList.loadByFilter(this.ebSepaStatementReportFilter, () => {
      this.ebSepaStatementReportList.items.map(item => {
        if (!this.importUsersVll.items.some(user => user.value === item.sysCreatedUserId)) {
          this.importUsersVll.add(item.sysCreatedUserId, item.importedBy);
        }
      });
    });
  }

  openImportStarMoneyModal() {
    this.showImportStarmoneyMcb.setTrue();
  }

  actCancel() {
    this.showImportStarmoneyMcb.setFalse();
  }
  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.mcForm.getControl('selImportedBy')?.get('value')?.setValue('');
    this.actLoad();
  }

  actStarMoneyImported() {
    this.showImportStarmoneyMcb.setFalse();
    this.actLoad();
    this.successMsg = 'StarMoney file successfully imported.';
  }

}
