
import {Table3Config} from '../extra/table-3-config';

// import {McPriceList2EntityGENERATED} from '../_generated/mc-price-list-2-entity-generated.model';

export class McPriceList2EntityTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colFlgActive', 'Is price list active', 'flgActive', 'flgActive');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdPriceList', 'Price list ID', 'idPriceList', 'idPriceList');
    this.addColumn('colMcPriceList', 'Price List', 'mcPriceList', 'mcPriceList');
    this.addColumn('colValidFrom', 'Price list is valid from this date', 'validFrom', 'validFrom');
    this.addColumn('colValidTo', 'Price list is valid until this date', 'validTo', 'validTo');

*/
  }
}
