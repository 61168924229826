import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import {MccFiFileUploadModule, McComponentsModule} from "@miticon-ui/mc-components";
import {TranslateModule} from "@ngx-translate/core";
import {McManagementModule} from "../mc-management/mc-management.module";
import {McBankingModuleRoutingModule} from "./mc-banking-module-routing.module";
import { McStarmoneyComponent } from "./pages/mc-starmoney/mc-starmoney.component";
import { McStarmoneyImportFileComponent } from './pages/mc-starmoney/components/mc-starmoney-import-file/mc-starmoney-import-file.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [McStarmoneyComponent, McStarmoneyImportFileComponent],
  imports: [
    CommonModule,
    McBankingModuleRoutingModule,
    McComponentsModule,
    TranslateModule,
    McManagementModule,
    FormsModule,
    ReactiveFormsModule,
    MccFiFileUploadModule,
  ],
  exports: [McStarmoneyComponent]
})
export class McBankingModuleModule { }
