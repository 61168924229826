import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  McAuthService,
  McBoolean,
  McConsumer,
  McConsumerAction,
  McForm,
  McProcessesResendActionModel, McTokens,
  McValueLabelList
} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-processes-action-resend-part',
  templateUrl: './app-shared-processes-action-resend-part.component.html',
})
export class AppSharedProcessesActionResendPartComponent implements OnInit {

  mcConsumer = new McConsumer();
  mcForm = new McForm();
  emailOptionsVll = new McValueLabelList();
  postOptionsVll = new McValueLabelList();
  smsOptionsVll = new McValueLabelList();
  actionList = '';
  resendButtonDisabledMcb = new McBoolean();
  tokenVll = McTokens.getEmailTokenVll();
  @Input() mcConsumerAction!: McConsumerAction;
  @Output() eventCanceled = new EventEmitter();
  resendAction!: McProcessesResendActionModel;

  ngOnInit() {
    this.resendAction = new McProcessesResendActionModel();
    this.mcConsumer.loadById(this.mcConsumerAction.idCsrConsumer);
    this.createActionString();
    this.emailOptionsVll.add('CURRENT_EMAIL', 'Use current email');
    this.emailOptionsVll.add('DIFFERENT_EMAIL', 'Use different email');
    this.postOptionsVll.add('CURRENT_POST_ADDRESS', 'Use current address');
    this.postOptionsVll.add('DIFFERENT_POST_ADDRESS', 'Use different address');
    this.smsOptionsVll.add('CURRENT_CELLPHONE_NUMBER', 'Use current phone number');
    this.smsOptionsVll.add('DIFFERENT_CELLPHONE_NUMBER', 'Use different phone number');
  }

  createActionString() {
    if (this.mcConsumerAction.emailActionFlg) {
      this.actionList += `<div class="table-action-icon mc-blue-text"><img src="assets/images/mc-process-icons/email-action.svg">Email</div>`;
    }
    if (this.mcConsumerAction.postActionFlg) {
      this.actionList += `<div class="table-action-icon"><img src="assets/images/mc-process-icons/post-action.svg">Post</div>`;
    }
    if (this.mcConsumerAction.smsActionFlg) {
      this.actionList += `<div class="table-action-icon"><img src="assets/images/mc-process-icons/sms-action.svg">SMS</div>`;
    }
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actResend() {
    debugger;

    if (this.mcForm.isValid()) {
      this.resendButtonDisabledMcb.setTrue();
      let mcConsumerChanged = false;
      // EMAIL
      this.resendAction.emailActionFlg = this.mcForm.getValue('ctrlEmail');
      if (this.resendAction.emailActionFlg) {
        this.resendAction.emailActionTypeCd = this.mcForm.getValue('ctrlEmailOptions');
        if (this.resendAction.emailActionTypeCd === 'DIFFERENT_EMAIL') {
          this.resendAction.differentEmailAddress = this.mcForm.getValue('ctrlDifferentEmail');
          this.resendAction.differentEmailAddress = this.mcForm.getValue('ctrlDifferentEmail');
          this.resendAction.updateConsumersEmailFlg = this.mcForm.getValue('ctrlUpdateEmailAddress');
          if (this.resendAction.updateConsumersEmailFlg) {
            this.mcConsumer.email = this.resendAction.differentEmailAddress;
            mcConsumerChanged = true;
          }
        }
      }

      // POST
      this.resendAction.postActionFlg = this.mcForm.getValue('ctrlPost');
      if (this.resendAction.postActionFlg) {
        this.resendAction.postActionTypeCd = this.mcForm.getValue('ctrlPostOptions');
        if (this.resendAction.postActionTypeCd === 'DIFFERENT_POST_ADDRESS') {
          this.resendAction.differentPostAddress = this.mcForm.getValue('ctrlDifferentAddress');
          this.resendAction.updateConsumersPostAddressFlg = this.mcForm.getValue('ctrlUpdateAddress');
          if (this.resendAction.updateConsumersPostAddressFlg) {
            this.mcConsumer.addressSupplement = this.resendAction.differentPostAddress;
            mcConsumerChanged = true;
          }
        }
      }

      // SMS
      this.resendAction.smsActionFlg = this.mcForm.getValue('ctrlSms');
      if (this.resendAction.smsActionFlg) {
        this.resendAction.smsActionTypeCd = this.mcForm.getValue('ctrlSmsOptions');
        if (this.resendAction.smsActionTypeCd === 'DIFFERENT_CELLPHONE_NUMBER') {
          this.resendAction.differentCellphoneNumber = this.mcForm.getValue('ctrlDifferentPhoneNumber');
          this.resendAction.updateConsumersCellphoneNumberFlg = this.mcForm.getValue('ctrlUpdatePhoneNumber');
          if (this.resendAction.updateConsumersPostAddressFlg) {
            this.mcConsumer.cellphone = this.resendAction.differentCellphoneNumber;
            mcConsumerChanged = true;
          }
        }
      }

      console.log('RESEND ACTION BODY 77777', this.resendAction);
      // if (mcConsumerChanged) {
      //   this.mcConsumer.save();
      // }

      this.resendButtonDisabledMcb.setFalse(); // In development
    }

  }
}
