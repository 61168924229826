<h3 class="page-title">{{ 'cc.factoring.contracts' | translate}}</h3>

<div>
  <mcc-modal1-wrapper [showMcb]="showRejectMcb" *ngIf="showRejectMcb.value" (eventCanceled)="onRejectModalCanceled()" modalSizeCd="small">
    <app-shared-factoring-reject-contract-part [ebFactoring]="ebFactoring" (eventRejected)="actRejected()"
    (eventCancel)="onRejectModalCanceled()"></app-shared-factoring-reject-contract-part>
  </mcc-modal1-wrapper>
  <mcc-modal1-wrapper *ngIf="ebFactoring && showProfileMcb.value" [showMcb]="showProfileMcb">
    <app-shared-factoring-profile-part [statusMyPendingMcb]="statusPendingMcb"
                                       [statusNotMyPendingMcb]="statusNotPendingMcb"
                                       (eventAccepted)="onAcceptContract()"
                                       (eventRejected)="onRejectContract()"
                                       (eventCanceled)="onCancelContract()"
                                       [ebFactoring]="ebFactoring"
                                       [ebFactoringId]="ebFactoring.id"></app-shared-factoring-profile-part>
  </mcc-modal1-wrapper>
  <mcc-dialog-box *ngIf="showAcceptContractDialogMcb.value" [enableCloseButtonFlg]="false"
                  title="{{'cc.my-factoring.accept-contract' | translate}}" name="accept-contract"
                  description="{{'cc.my-factoring.are-you-sure-you-want-to-accept-this-contract' | translate}}?"
                  [buttonsKvm]="acceptDialogButtonsKvm"
                  popupType="question"
                  (eventActionButtonClicked)="onMcDialogBoxAcceptContractActionButtonClicked($event)"></mcc-dialog-box>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
  (filterChanged)="onFilterChanged($event)"
  [searchTooltip]="searchTooltip"

></mk-mat-table>
<router-outlet></router-outlet>

