/*BbmTestObj*/
import {CsrAccountConsumerGENERATED} from '../_generated/csr-account-consumer-generated.model';

export class CsrAccountConsumer extends CsrAccountConsumerGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrAccountConsumer();
  }

  // ---------------------------------------------------------------------
}
