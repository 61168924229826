import {Inject, Injectable} from '@angular/core';
import {McProcessFlowLevelServiceGENERATED} from '../_generated/mc-process-flow-level-generated.service';


@Injectable({
  providedIn: 'root'
})
export class McProcessFlowLevelService extends McProcessFlowLevelServiceGENERATED {

}
