import {McProductSnapshotFilterGENERATED} from '../_generated/mc-product-snapshot-filter-generated.model';

export class McProductSnapshotFilter extends McProductSnapshotFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProductSnapshotFilter();
  }

  // ---------------------------------------------------------------------
}
