/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {PmTransactionFilter} from '../models/pm-transaction-filter.model';

export class PmTransactionFilterGENERATED extends BaseObjectFilter{    public statusCds: string[] = [];
    public paymentMethod: string[] = [];
    public searchTerm: string | undefined;
    public childEntityIds: number[] = [];
    public consumerId: number | undefined;
    public pausedFilterOptionCd: string | undefined;
    public inDunningFilterOptionCd: string | undefined;
    public dunningCompletedFilterOptionCd: string | undefined;
    public paymentDueDateFrom: any | undefined;
    public paymentDueDateTo: any | undefined;
    public consumerFullName: string | undefined;
    public iban: string | undefined;
    public description: string | undefined;
    public outTransactionFlg: boolean | undefined;
    public forConsumerFlg: boolean | undefined;
    public transactionPaymentDueDateTimeFromDate: any | undefined;
    public transactionPaymentDueDateTimeToDate: any | undefined;
    public factoringTypeWithDelayFilterOptionCds: string[] = [];
    public updatedFrom: any | undefined;
    public updatedTo: any | undefined;
    public idExtConsumer: string | undefined;

  public properties: string[] = ['statusCds', 'paymentMethod', 'searchTerm', 'childEntityIds', 'consumerId', 'pausedFilterOptionCd', 'inDunningFilterOptionCd', 'dunningCompletedFilterOptionCd', 'paymentDueDateFrom', 'paymentDueDateTo', 'consumerFullName', 'iban', 'description', 'outTransactionFlg', 'forConsumerFlg', 'transactionPaymentDueDateTimeFromDate', 'transactionPaymentDueDateTimeToDate', 'factoringTypeWithDelayFilterOptionCds', 'updatedFrom', 'updatedTo', 'idExtConsumer', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): PmTransactionFilter {
    console.error('MTCN-ERROR: Not Implemented: PmTransactionFilter::createNewInstance(). Add this method to final class and return new PmTransactionFilter();');
      throw new Error('Cannot PmTransactionFilter::createNewInstance(). Add this method to final class and return new PmTransactionFilter();');
  }

}
