/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McProcessRunItem} from '../models/mc-process-run-item.model';
import {McProcessRunItemService} from '../services/mc-process-run-item.service';
import {IMcProcessRunItem} from '../_generated/mc-process-run-item.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {McConsumerAction} from '../models/mc-consumer-action.model';
import {McProcessItem} from '../models/mc-process-item.model';


export abstract class McProcessRunItemGENERATED extends BaseObject {


    public static readonly STATUS_CD_READY: string = 'READY';
    public static readonly STATUS_CD_BUILDING: string = 'BUILDING';
    public static readonly STATUS_CD_BUILT: string = 'BUILT';
    public static readonly STATUS_CD_DELIVERING: string = 'DELIVERING';
    public static readonly STATUS_CD_DELIVERED: string = 'DELIVERED';
    public static readonly STATUS_CD_REMOVED: string = 'REMOVED';
    public static readonly STATUS_CD_SKIPPED: string = 'SKIPPED';
    public static readonly STATUS_CD_ERROR: string = 'ERROR';
    public static readonly STATUS_CD_CANCELLED: string = 'CANCELLED';
  public static readonly STATUS_CD_LIST__ALL = [
    McProcessRunItemGENERATED.STATUS_CD_READY,
    McProcessRunItemGENERATED.STATUS_CD_BUILDING,
    McProcessRunItemGENERATED.STATUS_CD_BUILT,
    McProcessRunItemGENERATED.STATUS_CD_DELIVERING,
    McProcessRunItemGENERATED.STATUS_CD_DELIVERED,
    McProcessRunItemGENERATED.STATUS_CD_REMOVED,
    McProcessRunItemGENERATED.STATUS_CD_SKIPPED,
    McProcessRunItemGENERATED.STATUS_CD_ERROR,
    McProcessRunItemGENERATED.STATUS_CD_CANCELLED
];

  public apiService: McProcessRunItemService;
  public _rawJson: IMcProcessRunItem;
    id: number = 0;
  buildNextActionStartDatetime: number;
  builtFlg: boolean;
  builtPaymentDueDatetime: number;
  feesAddedToTransactionFlg: boolean;
  idCsrConsumerAction: number;
  idMcProcessFlow: number;
  idMcProcessFlowLevel: number;
  idMcProcessItem: number;
  idMcProcessRun: number;
  lastErrorMsg = '';
  newItemFlg: boolean;
  statusCd: string;
  csrConsumerAction: McConsumerAction;  mcProcessFlowLevelName: string;  mcProcessFlowName: string;  mcProcessItem: McProcessItem;

  public properties: string[] = ['id', 'buildNextActionStartDatetime', 'builtFlg', 'builtPaymentDueDatetime', 'feesAddedToTransactionFlg', 'idCsrConsumerAction', 'idMcProcessFlow', 'idMcProcessFlowLevel', 'idMcProcessItem', 'idMcProcessRun', 'lastErrorMsg', 'newItemFlg', 'statusCd', 'csrConsumerAction', 'mcProcessFlowLevelName', 'mcProcessFlowName', 'mcProcessItem'];
  public propertiesRegular: string[] = ['id', 'buildNextActionStartDatetime', 'builtFlg', 'builtPaymentDueDatetime', 'csrConsumerAction', 'feesAddedToTransactionFlg', 'idCsrConsumerAction', 'idMcProcessFlow', 'idMcProcessFlowLevel', 'idMcProcessItem', 'idMcProcessRun', 'lastErrorMsg', 'mcProcessItem', 'mcProcessRun', 'newItemFlg', 'statusCd', 'mcProcessFlowLevelName', 'mcProcessFlowName'];
  public propertiesSpecial: string[] = ['csrConsumerAction', 'mcProcessItem'];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessRunItemGENERATED.STATUS_CD_READY, McGod.t('Ready'));
    list.add(McProcessRunItemGENERATED.STATUS_CD_BUILDING, McGod.t('Building'));
    list.add(McProcessRunItemGENERATED.STATUS_CD_BUILT, McGod.t('Built'));
    list.add(McProcessRunItemGENERATED.STATUS_CD_DELIVERING, McGod.t('Delivering'));
    list.add(McProcessRunItemGENERATED.STATUS_CD_DELIVERED, McGod.t('Delivered'));
    list.add(McProcessRunItemGENERATED.STATUS_CD_REMOVED, McGod.t('Removed'));
    list.add(McProcessRunItemGENERATED.STATUS_CD_SKIPPED, McGod.t('Skipped'));
    list.add(McProcessRunItemGENERATED.STATUS_CD_ERROR, McGod.t('Error'));
    list.add(McProcessRunItemGENERATED.STATUS_CD_CANCELLED, McGod.t('Cancelled'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McProcessRunItemGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McProcessRunItem {
    console.error('MTCN-ERROR: Not Implemented: McProcessRunItem::createNewInstance(). Add this method to final class and return new McProcessRunItem();');
    throw new Error('Cannot McProcessRunItem::createNewInstance(). Add this method to final class and return new McProcessRunItem();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcProcessRunItem): McProcessRunItem {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessRunItemService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McProcessRunItem AABB()');
          if (this._rawJson['csrConsumerAction']) {
        this.csrConsumerAction = McConsumerAction.createFromJson(this._rawJson['csrConsumerAction']);
      }
      if (this._rawJson['mcProcessItem']) {
        this.mcProcessItem = McProcessItem.createFromJson(this._rawJson['mcProcessItem']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getBuildNextActionStartDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.buildNextActionStartDatetime);
    }

    public getBuildNextActionStartDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.buildNextActionStartDatetime);
    }

    public getBuiltPaymentDueDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.builtPaymentDueDatetime);
    }

    public getBuiltPaymentDueDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.builtPaymentDueDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('csrConsumerAction')) {
        if (this.csrConsumerAction != null) {
            dto['csrConsumerAction'] = this.csrConsumerAction.toDto();
        } else {
            dto['csrConsumerAction'] = null;
        }
    }
    if (this.hasOwnProperty('mcProcessItem')) {
        if (this.mcProcessItem != null) {
            dto['mcProcessItem'] = this.mcProcessItem.toDto();
        } else {
            dto['mcProcessItem'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return McProcessRunItemGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdREADY(): boolean {
    const result = (this.statusCd === McProcessRunItemGENERATED.STATUS_CD_READY);

    return result;
  }

 public  isStatusCdBUILDING(): boolean {
    const result = (this.statusCd === McProcessRunItemGENERATED.STATUS_CD_BUILDING);

    return result;
  }

 public  isStatusCdBUILT(): boolean {
    const result = (this.statusCd === McProcessRunItemGENERATED.STATUS_CD_BUILT);

    return result;
  }

 public  isStatusCdDELIVERING(): boolean {
    const result = (this.statusCd === McProcessRunItemGENERATED.STATUS_CD_DELIVERING);

    return result;
  }

 public  isStatusCdDELIVERED(): boolean {
    const result = (this.statusCd === McProcessRunItemGENERATED.STATUS_CD_DELIVERED);

    return result;
  }

 public  isStatusCdREMOVED(): boolean {
    const result = (this.statusCd === McProcessRunItemGENERATED.STATUS_CD_REMOVED);

    return result;
  }

 public  isStatusCdSKIPPED(): boolean {
    const result = (this.statusCd === McProcessRunItemGENERATED.STATUS_CD_SKIPPED);

    return result;
  }

 public  isStatusCdERROR(): boolean {
    const result = (this.statusCd === McProcessRunItemGENERATED.STATUS_CD_ERROR);

    return result;
  }

 public  isStatusCdCANCELLED(): boolean {
    const result = (this.statusCd === McProcessRunItemGENERATED.STATUS_CD_CANCELLED);

    return result;
  }


  
}
