/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McVatTable3ConfigGENERATED} from '../_generated/mc-vat-table-3-config-generated.model';

// import {McVatGENERATED} from '../_generated/mc-vat-generated.model';

export class McVatTable3Config extends McVatTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdMcCountry', 'Country Id', 'idMcCountry', 'idMcCountry');
    this.addColumn('colVatDescription', 'VAT Description', 'vatDescription', 'vatDescription');
    this.addColumn('colStartDate', 'Start Date', 'startDate', 'startDate');
    this.addColumn('colEndDate', 'End Date', 'endDate', 'endDate');
    this.addColumn('colVatTypeCd', 'VAT Type Cd', 'vatTypeCd', 'vatTypeCd');


  }
}
