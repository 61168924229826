/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McCategory} from '../models/mc-category.model';
import {McCategoryList} from '../models/mc-category-list.model';
import {McCategoryFilter} from '../models/mc-category-filter.model';
import {McCategoryService} from '../services/mc-category.service';
import {IMcCategory} from '../_generated/mc-category.interface';
import {McGod} from "../extra/mc-god.service";

export class McCategoryListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'createdAt', 'createdBy', 'description', 'idMcEntity', 'name', 'updatedAt', 'updatedBy', 'version'];
  public items: McCategory[] = [];
  public apiService: McCategoryService;
 // protected tempObjList: McCategoryList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McCategoryList {
    console.error('MTCN-ERROR: Not Implemented: McCategoryList::createNewInstance(). Add this method to final class and return new McCategoryList();');
    throw new Error('Cannot McCategoryList::createNewInstance(). Add this method to final class and return new McCategoryList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcCategory[]): McCategoryList {
    const  listObj: McCategoryList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McCategory = McCategory.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McCategoryList {
    const  listObj: McCategoryList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McCategoryService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcCategory[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McCategory = McCategory.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcCategoryFilter: McCategoryFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcCategoryFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McCategory {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McCategory();
    }
  }

  public replaceItem(newItem: McCategory) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

