import {EbSepaImportFilterGENERATED} from '../_generated/eb-sepa-import-filter-generated.model';

export class EbSepaImportFilter extends EbSepaImportFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbSepaImportFilter();
  }

  // ---------------------------------------------------------------------
}
