/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBillingPayoutFileFeCtd} from '../models/mc-billing-payout-file-fe-ctd.model';
import {McBillingPayoutFileFeCtdService} from '../services/mc-billing-payout-file-fe-ctd.service';
import {IMcBillingPayoutFileFeCtd} from '../_generated/mc-billing-payout-file-fe-ctd.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {McCurrency} from '../models/mc-currency.model';


export abstract class McBillingPayoutFileFeCtdGENERATED extends BaseObject {


    public static readonly STATUS_CD_CREATED: string = 'CREATED';
    public static readonly STATUS_CD_PENDING: string = 'PENDING';
    public static readonly STATUS_CD_IN_PROGRESS: string = 'IN_PROGRESS';
    public static readonly STATUS_CD_FAILED: string = 'FAILED';
    public static readonly STATUS_CD_SENT_TO_BANK: string = 'SENT_TO_BANK';
  public static readonly STATUS_CD_LIST__ALL = [
    McBillingPayoutFileFeCtdGENERATED.STATUS_CD_CREATED,
    McBillingPayoutFileFeCtdGENERATED.STATUS_CD_PENDING,
    McBillingPayoutFileFeCtdGENERATED.STATUS_CD_IN_PROGRESS,
    McBillingPayoutFileFeCtdGENERATED.STATUS_CD_FAILED,
    McBillingPayoutFileFeCtdGENERATED.STATUS_CD_SENT_TO_BANK
];

    public static readonly TYPE_CD_STAR_MONEY: string = 'STAR_MONEY';
    public static readonly TYPE_CD_EBICS: string = 'EBICS';
  public static readonly TYPE_CD_LIST__ALL = [
    McBillingPayoutFileFeCtdGENERATED.TYPE_CD_STAR_MONEY,
    McBillingPayoutFileFeCtdGENERATED.TYPE_CD_EBICS
];

  public apiService: McBillingPayoutFileFeCtdService;
  public _rawJson: IMcBillingPayoutFileFeCtd;
    id: number = 0;
  fileName = '';
  idMcBankAccount: number;
  numberOfStatements: number;
  payoutDate: number;
  statusCd: string;
  statusDetails = '';
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  totalFileAmount: number;
  typeCd: string;
  xlsWebfileId: number;
  bankName: string;  createdBy: string;  mcCurrency: McCurrency;

  public properties: string[] = ['id', 'fileName', 'idMcBankAccount', 'numberOfStatements', 'payoutDate', 'statusCd', 'statusDetails', 'sysCreatedDatetime', 'sysCreatedUserId', 'totalFileAmount', 'typeCd', 'xlsWebfileId', 'bankName', 'createdBy', 'mcCurrency'];
  public propertiesRegular: string[] = ['id', 'fileName', 'idMcBankAccount', 'numberOfStatements', 'payoutDate', 'statusCd', 'statusDetails', 'sysCreatedDatetime', 'sysCreatedUserId', 'totalFileAmount', 'typeCd', 'xlsWebfileId', 'bankName', 'createdBy'];
  public propertiesSpecial: string[] = ['mcCurrency'];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingPayoutFileFeCtdGENERATED.STATUS_CD_CREATED, McGod.t('Created'));
    list.add(McBillingPayoutFileFeCtdGENERATED.STATUS_CD_PENDING, McGod.t('Pending'));
    list.add(McBillingPayoutFileFeCtdGENERATED.STATUS_CD_IN_PROGRESS, McGod.t('In progress'));
    list.add(McBillingPayoutFileFeCtdGENERATED.STATUS_CD_FAILED, McGod.t('Failed'));
    list.add(McBillingPayoutFileFeCtdGENERATED.STATUS_CD_SENT_TO_BANK, McGod.t('cc.mc-billing-payout-file.sent-to-bank'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McBillingPayoutFileFeCtdGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingPayoutFileFeCtdGENERATED.TYPE_CD_STAR_MONEY, McGod.t('Star Money'));
    list.add(McBillingPayoutFileFeCtdGENERATED.TYPE_CD_EBICS, McGod.t('Ebics'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = McBillingPayoutFileFeCtdGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McBillingPayoutFileFeCtd {
    console.error('MTCN-ERROR: Not Implemented: McBillingPayoutFileFeCtd::createNewInstance(). Add this method to final class and return new McBillingPayoutFileFeCtd();');
    throw new Error('Cannot McBillingPayoutFileFeCtd::createNewInstance(). Add this method to final class and return new McBillingPayoutFileFeCtd();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBillingPayoutFileFeCtd): McBillingPayoutFileFeCtd {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingPayoutFileFeCtdService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBillingPayoutFileFeCtd AABB()');
          if (this._rawJson['mcCurrency']) {
        this.mcCurrency = McCurrency.createFromJson(this._rawJson['mcCurrency']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getTotalFileAmountStr(): string {
       return McGod.formatNumberToMoney(this.totalFileAmount);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('mcCurrency')) {
        if (this.mcCurrency != null) {
            dto['mcCurrency'] = this.mcCurrency.toDto();
        } else {
            dto['mcCurrency'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return McBillingPayoutFileFeCtdGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdCREATED(): boolean {
    const result = (this.statusCd === McBillingPayoutFileFeCtdGENERATED.STATUS_CD_CREATED);

    return result;
  }

 public  isStatusCdPENDING(): boolean {
    const result = (this.statusCd === McBillingPayoutFileFeCtdGENERATED.STATUS_CD_PENDING);

    return result;
  }

 public  isStatusCdIN_PROGRESS(): boolean {
    const result = (this.statusCd === McBillingPayoutFileFeCtdGENERATED.STATUS_CD_IN_PROGRESS);

    return result;
  }

 public  isStatusCdFAILED(): boolean {
    const result = (this.statusCd === McBillingPayoutFileFeCtdGENERATED.STATUS_CD_FAILED);

    return result;
  }

 public  isStatusCdSENT_TO_BANK(): boolean {
    const result = (this.statusCd === McBillingPayoutFileFeCtdGENERATED.STATUS_CD_SENT_TO_BANK);

    return result;
  }


  public getTypeCdLabel(): string {
    return McBillingPayoutFileFeCtdGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdSTAR_MONEY(): boolean {
    const result = (this.typeCd === McBillingPayoutFileFeCtdGENERATED.TYPE_CD_STAR_MONEY);

    return result;
  }

 public  isTypeCdEBICS(): boolean {
    const result = (this.typeCd === McBillingPayoutFileFeCtdGENERATED.TYPE_CD_EBICS);

    return result;
  }


  
}
