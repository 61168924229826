
import {Table3Config} from '../extra/table-3-config';

// import {McEntity2GENERATED} from '../_generated/mc-entity2-generated.model';

export class McEntity2Table3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAbbreviation', 'Abbreviation', 'abbreviation', 'abbreviation');
    this.addColumn('colActive', 'Active', 'active', 'active');
    this.addColumn('colAddress', 'Address', 'address', 'address');
    this.addColumn('colBankAccount', 'Bank account', 'bankAccount', 'bankAccount');
    this.addColumn('colBankTransactionEntityNameOverride', 'Alternative name', 'bankTransactionEntityNameOverride', 'bankTransactionEntityNameOverride');
    this.addColumn('colCountryId', 'Country Id', 'countryId', 'countryId');
    this.addColumn('colCourtInCharge', 'Court in charge', 'courtInCharge', 'courtInCharge');
    this.addColumn('colCreatedAt', 'created_at', 'createdAt', 'createdAt');
    this.addColumn('colCreatedBy', 'created by', 'createdBy', 'createdBy');
    this.addColumn('colCustomAttributeMap', 'custom attribute map', 'customAttributeMap', 'customAttributeMap');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colEuVat', 'EU VAT', 'euVat', 'euVat');
    this.addColumn('colExtCreditorId', 'Creditor (Glaeubiger ID)', 'extCreditorId', 'extCreditorId');
    this.addColumn('colFactoringEnableFlg', 'Factoring enabled flag', 'factoringEnableFlg', 'factoringEnableFlg');
    this.addColumn('colFax', 'Fax', 'fax', 'fax');
    this.addColumn('colImpressum', 'Impressum', 'impressum', 'impressum');
    this.addColumn('colIpWhitelist', 'IP whitelist', 'ipWhitelist', 'ipWhitelist');
    this.addColumn('colIsVisibleToChildren', 'Is visible to children?', 'isVisibleToChildren', 'isVisibleToChildren');
    this.addColumn('colLegalRepresentative', 'Legal representative', 'legalRepresentative', 'legalRepresentative');
    this.addColumn('colLegalRepresentativeContact', 'Legal representative contact', 'legalRepresentativeContact', 'legalRepresentativeContact');
    this.addColumn('colMailDomain', 'Mail domain', 'mailDomain', 'mailDomain');
    this.addColumn('colMailPrefix', 'Mail prefix', 'mailPrefix', 'mailPrefix');
    this.addColumn('colMaxTransactionPauseDays', 'Max transaction pause days', 'maxTransactionPauseDays', 'maxTransactionPauseDays');
    this.addColumn('colName', 'Name', 'name', 'name');
    this.addColumn('colParentEntityId', 'Parent entity ID', 'parentEntityId', 'parentEntityId');
    this.addColumn('colPhone', 'Phone', 'phone', 'phone');
    this.addColumn('colRootEntityId', 'Root entity ID', 'rootEntityId', 'rootEntityId');
    this.addColumn('colStudioManager', 'Studio manager', 'studioManager', 'studioManager');
    this.addColumn('colStudioOwner', 'Studio owner', 'studioOwner', 'studioOwner');
    this.addColumn('colStudioOwnerAddress', 'Studio owner address', 'studioOwnerAddress', 'studioOwnerAddress');
    this.addColumn('colStudioOwnerCity', 'Studio owner city', 'studioOwnerCity', 'studioOwnerCity');
    this.addColumn('colStudioOwnerPostalCode', 'Studio owner postal code', 'studioOwnerPostalCode', 'studioOwnerPostalCode');
    this.addColumn('colTransactionDescriptionPrefix', 'Transaction description prefix', 'transactionDescriptionPrefix', 'transactionDescriptionPrefix');
    this.addColumn('colUpdatedAt', 'updated_at', 'updatedAt', 'updatedAt');
    this.addColumn('colUpdatedBy', 'updated by', 'updatedBy', 'updatedBy');
    this.addColumn('colVersion', 'version', 'version', 'version');
    this.addColumn('colWebDomain', 'Web domain', 'webDomain', 'webDomain');

*/
  }
}
