<div class="">
  <div class="col-md-12 mc-stepper-header-title mb-4">
  <span class="px-4 py-2 mc-semi-active">
    <span class="mc-notification-badge-gray mc-semi-active-background">1</span>
    {{'cc.consumers.consumers-overview.file-upload' | translate}}
  </span>
    <span class="px-4 py-2 mc-active">
  <span class="mc-notification-badge-gray mc-active-background">2</span>
      {{'cc.consumers.consumers-overview.mapping-&-importing' | translate}}
  </span>
  </div>

  <div class="mc-error position-relative mt-n3">
    <mc-notify-message [message]="notifyMessageObject.message" [type]="notifyMessageObject.type"></mc-notify-message>
  </div>

  <lib-mc-csv-columns-mapping *ngIf="mapping.mappingItems" [parentForm]="importConsumersForm"
                              [entityAttributes]="classCustomAtt"
                              [mapping]="mapping"
                              [templateMappings]="templateMappings"
                              (onUpdatedItems)="tableCellSelectChanged()"
                              [webFile]="webFile"
                              [duplicatesHandlingOptionsKvm]="duplicateHandlingOptionsKvm"
                              (checkDuplicatesFlag)="checkSelectDuplicates()"></lib-mc-csv-columns-mapping>

  <div class="col-md-12 mb-5 mt-2">
    <div *ngIf="!mapping.apiErrorFlg" class="mc-error position-relative mt-n3">
      <mc-notify-message [message]="notifyMessageObject.message" [type]="notifyMessageObject.type"></mc-notify-message>
    </div>
  </div>

  <div class="modal-footer  mt-4">
      <div class="row w-100">
        <div class="col-md-4">
          <button class="nav-button btn btn-secondary mc-prev-btn" (click)="actPrev()">
            < {{'cc.common.view.prev' | translate}}
          </button>
        </div>
        <div class="col-md-8 text-right">
          <button class="btn btn-sm mc-cancel-btn mr-5" (click)="actCancel()">
            {{'cc.common.edit.cancel' | translate}}
          </button>
          <button class="nav-button btn btn-primary mc-next-save-btn mr-5" [disabled]="disabledImportButtonFlag" (click)="actImport()">
            {{'cc.common.view.import' | translate}}
          </button>
        </div>
      </div>
  </div>
</div>
