import {Component, EventEmitter, Inject, Input, OnInit, Output} from "@angular/core";
import {
  McAuthService,
  McBillingItem, McBillingItemList,
  McBillingStatementItem,
  McBillingStatementItemList,
  McBoolean,
  McForm,
  McValueLabelList
} from "@miticon-ui/mc-core";

@Component({
  selector: "mc-out-billing-aggregate-part",
  templateUrl: "./mc-out-billing-aggregate-part.component.html",
})
export class McOutBillingAggregatePartComponent implements OnInit {

  statementItemVll = new McValueLabelList();
  billingItemList = new McBillingItemList();
  aggregateYesNoVll = new McValueLabelList();
  statementItems = new McBillingStatementItemList();
  mcBillingStatementItem = new McBillingStatementItem();
  disableAggregateBtnMcb = new McBoolean();
  mcForm = new McForm();
  showAddAggregationMcb = new McBoolean();
  loadingFlg = false;
  errorMsg = "";
  statementId!: number;

  @Input() mcBillingItem = new McBillingItem();
  @Input() mcBillingItemList = new McBillingItemList();
  @Input() isPartOfAssignToStatementStepper = false;
  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventPrev = new EventEmitter();


  /*On init*/
  ngOnInit() {
    if (this.mcBillingItem.exists()) {
      this.statementId = this.mcBillingItem.mcBillingStatementId;
    } else {
      this.statementId = this.mcBillingItemList.getLastItem().mcBillingStatementId;
    }
    this.aggregateYesNoVll.add(false, "No");
    this.aggregateYesNoVll.add(true, "Yes");
    this.loadStatementItems();
  }

  actSave() {

    if (this.mcForm.isValid()) {

      this.loadingFlg = true;

      if (this.isPartOfAssignToStatementStepper) {

        if (this.mcForm.getValue("ctrlAggregate")) {  // AGGREGATE IN STEPPER

          const statementItemId = this.mcForm.getValue("ctrlAggregation");

          if (this.mcBillingItem.exists()) {
            this.mcBillingItem.mcBillingStatementItemId = statementItemId;
          } else {
            this.mcBillingItemList.items.forEach(item => {
              item.mcBillingStatementItemId = statementItemId;
            });
          }
          this.saveBillingItem();

        } else {  // SINGLE

          debugger

          if (this.mcBillingItem.exists()) {
            this.mcBillingStatementItem.typeCd = McBillingStatementItem.TYPE_CD_SINGLE;
            this.mcBillingStatementItem.statusCd = McBillingStatementItem.STATUS_CD_ACTIVE;
            this.mcBillingStatementItem.mcBillingStatementId = this.mcBillingItem.mcBillingStatementId;
            this.mcBillingStatementItem.amount = this.mcBillingItem.amount;
            this.mcBillingStatementItem.title = this.mcBillingItem.title;
            this.mcBillingStatementItem.description = this.mcBillingItem.description;
            this.mcBillingStatementItem.save(() => {
              this.mcBillingItem.mcBillingStatementItemId = this.mcBillingStatementItem.id;
              this.saveBillingItem();
            }, () => {
              this.loadingFlg = false;
              this.errorMsg = this.mcBillingStatementItem.apiErrorMessage;
            });
          } else {

            // const mcBillingItemListTemp = new McBillingItemList();
            console.log('saveMcBillingItemsAncCreateMcBillingStatementItems not imeplemented on BE');
            // this.billingItemList.saveMcBillingItemsAncCreateMcBillingStatementItems(this.mcBillingItemList.toDto(), this.mcBillingItem.mcBillingStatementId, () => {
            //   this.eventSaved.emit();
            //   this.loadingFlg = false;
            // }, () => {
            //   this.loadingFlg = false;
            //   this.errorMsg = this.billingItemList.apiErrorMessage;
            // });

          }

        }

      } else { // AGGREGATE
        const statementItemId = this.mcForm.getValue("ctrlAggregation");

        if (this.mcBillingItem.exists()) {
          this.mcBillingItem.mcBillingStatementItemId = statementItemId;
        } else {
          this.mcBillingItemList.items.forEach(item => {
            item.mcBillingStatementItemId = statementItemId;
          });
        }

        this.saveBillingItem();
      }

    }

  }

  actCancel() {
    this.eventCanceled.emit();
  }

  saveBillingItem() {
    if (this.mcBillingItem.exists()) {
      this.mcBillingItem.save(() => {
        this.eventSaved.emit();
        this.loadingFlg = false;
      }, () => {
        this.loadingFlg = false;
        this.errorMsg = this.mcBillingItem.apiErrorMessage;
      });
    } else { // SAVE ITEM LIST
      // this.mcBillingItemList.save
    }
  }

  loadStatementItems() {
    this.statementItems.loadAllByMcBillingStatementId(this.statementId, () => {
      this.statementItemVll = this.statementItems.toVll("id", "title");
      this.loadingFlg = false;
      this.statementItems.items = this.statementItems.items
        .filter(item => item.typeCd === McBillingStatementItem.TYPE_CD_AGGREGATE);
    }, () => {
      this.loadingFlg = false;
      this.errorMsg = this.statementItems.apiErrorMessage;
    });
  }

  actAddAggregationCanceled() {
    this.showAddAggregationMcb.setFalse();
  }

  actShowAddAggregation() {
    this.showAddAggregationMcb.setTrue();
  }

  actAddAggregationSaved() {
    this.showAddAggregationMcb.setFalse();
    this.loadStatementItems();
  }

  actPrev() {
    this.eventPrev.emit();
  }
}
