/*BbmTestObj*/
import {McProcessFlowGENERATED} from '../_generated/mc-process-flow-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {ActionOptions} from '../_core/mc-html-action-options';

export class McProcessFlow extends McProcessFlowGENERATED {

  public static ACTION_SHOW_PROFILE = 'PROFILE';

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessFlow();
  }

  // ---------------------------------------------------------------------
  fldName() {
    return this.title + ' Flow';
  }
  fldActions() {
    const html = new McHtml();
    const actSet = html.addActionSet();
    actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t('cc.processes.view-flow')}`, McProcessFlow.ACTION_SHOW_PROFILE, this, [McGod.PERM_MC_PROCESS_FLOW_VIEW], '', ActionOptions.OPTION_VIEW);

    return html;
  }

  fldNameLink() {
    const html = new McHtml();
    html.addLinkB( this.title, `entity/${McGod.getLoggedEntityIdFromToken()}/processes/flow-profile/${this.id}`, this, [McGod.PERM_MC_PROCESS_FLOW_VIEW], 'mc-blue-link', ActionOptions.OPTION_VIEW);
    return html.items;
  }

  formatInheritableFlg() {
    return this.inheritableFlg ? 'yes' : 'no';
  }

  fldNoOfLevels() {
    if (this.levels) {
      return this.levels.getCount();
    }
    return '';
  }

  fldStatus() {
    return (this.isStatusCdACTIVE() ? '<img src="assets/images/common-icons/active.svg" height="16px" width="16px">' : '<img src="assets/images/common-icons/inactive.svg" height="16px" width="16px">') + ' ' + this.getStatusCdLabel();
  }

  fldSendFlowToInkassoLabel() {
    return this.sendFlowToInkassoFlg ? 'Yes' : 'No';
  }

  fldGroupByConsumer() {
    return this.groupByConsumerFlg ? 'Yes' : 'No';
  }

  fldFlowFeesInterestRateCompany() {
    return 'Custom ' + this.interestRateForCompanyAmount + '%';
  }

  fldFlowFeesInterestRatePerson() {
    return 'Custom ' + this.interestRateForPersonAmount + '%';
  }
}
