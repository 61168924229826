import { Component, Input, OnInit } from '@angular/core';
import {ICON_INFO_WHITE} from "../../../../assets/media/svg_icons/icon-info-white";
import {ICON_ERROR} from "../../../../assets/media/svg_icons/icon-error";

@Component({
  selector: 'info-bar-message',
  templateUrl: './info-bar-message.component.html',
  styleUrls: ['./info-bar-message.component.scss'],
})
export class InfoBarMessageComponent implements OnInit {
  @Input() icon: string = '';
  @Input() message: string = '';
  @Input() followingMessage: string;
  @Input() errorBar: boolean = false;

  iconInfoWhite = ICON_INFO_WHITE;
  iconError = ICON_ERROR;
  constructor() {}

  ngOnInit(): void {}
}
