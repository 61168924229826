import {CsrMapping} from './csr-mapping.model';
import {CsrMappingListGENERATED} from '../_generated/csr-mapping-list-generated.model';

export class CsrMappingList extends CsrMappingListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrMappingList();
  }

  // ---------------------------------------------------------------------
}
