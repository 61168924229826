import {McGod, McMenu, McMenuItem} from '@miticon-ui/mc-core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICON_DROP_DOWN } from '../svg_icons/icon-drop-down';
import { ICON_DROP_UP } from '../svg_icons/icon-drop-up';

@Component({

  selector: 'mc-menu',
  templateUrl: './mc-menu.component.html',
})
export class McMenuComponent implements OnInit {

  // isCollapsed = true;
  isItemSelected = false;
  @Input() public menu!: McMenu;
  @Input() isCollapsed!: boolean;
  @Output() onCollapse = new EventEmitter();
  dropDownIcon = ICON_DROP_DOWN;
  dropUpIcon = ICON_DROP_UP;
  constructor() {
  }

  ngOnInit() {
    this.menu.menuItemList.forEach(parentMenuItem => {
      if (parentMenuItem.submenu.length) {
        parentMenuItem.submenu.map(submenuItem => {
          if (McGod.matchActiveRoute(submenuItem.route)) {
            parentMenuItem.hasActiveChild = true;
            parentMenuItem.isExpanded = true;
          }
        });
      }
    });
  }

  public onSelectSubmenuItem(aMenuItemName: string): void {
      this.menu.menuItemList.map(menuItem => {
        if (menuItem.submenu.findIndex(subMenuItem => subMenuItem.name === aMenuItemName) > -1) {
          menuItem.isExpanded = true;
          menuItem.hasActiveChild = true;
        } else {
          menuItem.hasActiveChild = false;
          /*todo: uncomment this if client want to have feature: on ona click other submenus is collapsed*/
          // menuItem.isExpanded = false;
        }

      });
  }

  public toggleDropdownMenu(aMenuItem: McMenuItem, event: any, aElementNumber: number) {
    this.isItemSelected = true;
    let ulElement;
    if(!this.isCollapsed) {
      if (aElementNumber === 1) {
        ulElement = event.currentTarget.nextElementSibling;
      } else if (aElementNumber === 2) {
        ulElement = event.target.nextSibling;
      } else {
        ulElement = event.currentTarget.nextElementSibling;
      }
      if (ulElement && ulElement.style.height === '0px') {
        ulElement.style.transition = 'height 0.5s';
        ulElement.style.height = this.calculateSubmenuHeight(aMenuItem);
        aMenuItem.isExpanded = true;
      } else {
        ulElement.style.height = '0px';
        aMenuItem.isExpanded = false;
      }
    }
    return true;
  }

  public calculateSubmenuHeight(aMenuItem: McMenuItem): string {

    const userPermissions = McGod.getLoggedUserPermissionsFromToken();
    aMenuItem.submenu.map(subMenuItem => {
      if (subMenuItem.permission.length > 0) {
        if (McGod.userHasPermissions(subMenuItem.permission)) {
          subMenuItem.isDisplayed = true;
        } else {
          subMenuItem.isDisplayed = false;
        }
      } else {
        subMenuItem.isDisplayed = true;
      }
    });
    const numberOfDisplayedSubmenuItems = aMenuItem.submenu.filter(subMenuItem => subMenuItem.isDisplayed);
    return `${numberOfDisplayedSubmenuItems.length * 45}px`;
  }

  onSelectMenuItem(aMenuItem: McMenuItem) {
    if(!this.isCollapsed) {
      this.isItemSelected = true;
      this.menu.menuItemList.map(menuItem => {
        if(menuItem.name === aMenuItem.name) {
          menuItem.isActive = true;
        }
        menuItem.hasActiveChild = false;
      });
    }
  }

  isActive(menuItemRoute: string): boolean {
    const currentUrl = window.location.href.substring(window.location.href.indexOf("#") + 1);
    if(menuItemRoute === '/dashboard') {
      return currentUrl.includes(menuItemRoute);
    } else return menuItemRoute === currentUrl;
  }
}
