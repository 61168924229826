import {McProcessFlow} from './mc-process-flow.model';
import {McProcessFlowListGENERATED} from '../_generated/mc-process-flow-list-generated.model';

export class McProcessFlowList extends McProcessFlowListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessFlowList();
  }

  // ---------------------------------------------------------------------
}
