/*BbmTestObj*/
import {McProcessNotificationGENERATED} from '../_generated/mc-process-notification-generated.model';

export class McProcessNotification extends McProcessNotificationGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new McProcessNotification();
  }

  // ---------------------------------------------------------------------
}
