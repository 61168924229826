/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {ZzPocGarageTable3ConfigGENERATED} from '../_generated/zz-poc-garage-table-3-config-generated.model';

// import {ZzPocGarageGENERATED} from '../_generated/zz-poc-garage-generated.model';

export class ZzPocGarageTable3Config extends ZzPocGarageTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.addColumn('colTypeCd', 'Type', 'typeCd', 'typeCd');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colHorsePower', 'Horse Power', 'horsePower', 'horsePower');
    this.addColumn('colSysCreatedUserId', 'Sys Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');


  }
}
