<ng-template let-modal #importStarMoney>

  <div class="modal-header mc-create-user-modal-header">
    <div>
      <h4 class="modal-title" id="mc-modal-new-user-title">Import StarMoney File</h4>
    </div>
    <button type="button" class="close mc-consumers-close-btn" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div><!--Modal header -->

  <!--Modal body section-->
  <div class="modal-body">
    <div class="mc-create-new-user-wrapper">
      <div class="container-fluid">

        <div class="row" [formGroup]="formGroup">
          <div class="col-md-12 position-relative mt-1">
            <div class="mc-create-user-notify-wrapper">
              <div class="w-100 mc-notify-message-wrapper mc-background-loading mc-text-align-center py-1" *ngIf="webFile.apiLoadingFlg">{{'cc.my-factoring.loading-file-please-wait' | translate}}</div>
              <div class="w-100" *ngIf="webFile.apiErrorFlg"><mc-notify-message [message]=webFile.apiErrorMessage [type]="'error'"></mc-notify-message></div>
              <div class="w-100" *ngIf="!isEnabled"><mc-notify-message [message]="message" [type]="messageType"></mc-notify-message></div>
            </div>
          </div>

          <div class="col-md-12 mt-5 px-5">
            <div class="input-group mb-3">

              <lib-mcc-fi-file-upload [acceptProperty]="'.csv'" (eventFileSelected)="onFileSelected($event)"></lib-mcc-fi-file-upload>

            </div>
          </div>

          <div class="col-md-12 mt-4 pt-1 pl-5 d-flex align-items-center">
            <label class="mb-0 mc-field-width-10" for="delimiterSelect">Delimiter</label>
            <select class="form-check-label custom-select mc-field-width-7 mx-3" id="delimiterSelect" formControlName="delimiter">
              <option class="font-weight-bold">;</option>
              <option class="font-weight-bold">,</option>
            </select>
          </div>

          <div class="col-md-12 mt-2 mb-4 pt-1 pl-5 d-flex align-items-center">
            <label class="mb-0 mc-field-width-10" for="encodingSelector">Encoding</label>
            <select id="encodingSelector" class="form-check-label custom-select mc-field-width-15 mx-3">
              <option>UTF-8</option>
            </select>
          </div>


          <div class="col-md-12">
            <div class="modal-footer">
              <button class="btn btn-sm mc-cancel-btn mr-5" (click)="actCancel()">
                Cancel
              </button>
              <button class="btn btn-primary mc-next-save-btn mr-3" (click)="actUpload()">
                Upload
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-template>


<a class="mc-create-entity-btn" (click)="importStarMoneyModal(importStarMoney)">
  <i class="fas fa-plus-circle mc-add-new-user-icon"></i>
  Import StarMoney File
</a>
