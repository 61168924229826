export const ICON_PREVIEW_PDF = `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1844_8276)">
<path d="M14.59 3.09C14.21 2.71 13.7 2.5 13.17 2.5H6C4.9 2.5 4 3.4 4 4.5V20.5C4 21.6 4.89 22.5 5.99 22.5H18C19.1 22.5 20 21.6 20 20.5V9.33C20 8.8 19.79 8.29 19.41 7.92L14.59 3.09ZM15 18.5H9C8.45 18.5 8 18.05 8 17.5C8 16.95 8.45 16.5 9 16.5H15C15.55 16.5 16 16.95 16 17.5C16 18.05 15.55 18.5 15 18.5ZM15 14.5H9C8.45 14.5 8 14.05 8 13.5C8 12.95 8.45 12.5 9 12.5H15C15.55 12.5 16 12.95 16 13.5C16 14.05 15.55 14.5 15 14.5ZM13 8.5V4L18.5 9.5H14C13.45 9.5 13 9.05 13 8.5Z" fill="#53589B"/>
</g>
<defs>
<clipPath id="clip0_1844_8276">
<rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
`
