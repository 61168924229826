import {Inject, Injectable} from '@angular/core';
import {McConsumerBalanceServiceGENERATED} from '../_generated/mc-consumer-balance-generated.service';



@Injectable({
  providedIn: 'root'
})


export class McConsumerBalanceService extends McConsumerBalanceServiceGENERATED {
}
