import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ICsrConsumerHistory} from '../_generated/csr-consumer-history.interface';
import {CsrConsumerHistory} from '../models/csr-consumer-history.model';
import {CsrConsumerHistoryList} from '../models/csr-consumer-history-list.model';
import {CsrConsumerHistoryServiceGENERATED} from '../_generated/csr-consumer-history-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CsrConsumerHistoryService extends CsrConsumerHistoryServiceGENERATED {

}
