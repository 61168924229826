import {CcMollieCustomer} from './cc-mollie-customer.model';
import {CcMollieCustomerListGENERATED} from '../_generated/cc-mollie-customer-list-generated.model';

export class CcMollieCustomerList extends CcMollieCustomerListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CcMollieCustomerList();
  }

  // ---------------------------------------------------------------------
}
