import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {
  EbFactoringList,
  McEntityService,
  McUtilityService,
  McStateManagementService,
  EbFactoring,
  EbFactoringTable3Config,
  EbFactoringFilter,
  McGod,
  McForm,
  McHtmlButton, McBoolean,
} from '@miticon-ui/mc-core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'mc-entity-factoring-wrapper-part-parent',
  templateUrl: './mc-entity-factoring-wrapper-part-parent.component.html'
})
export class McEntityFactoringWrapperPartParentComponent implements OnInit {
  ebFactoringTable3Config = new EbFactoringTable3Config();
  ebFactoringFilter = new EbFactoringFilter();
  mcForm = new McForm();
  profileEbFactoringId!: number;
  profileEbFactoring = new EbFactoring();

  ebFactoring = new EbFactoring();
  ebFactoringId!: number;
  activeFactoring = new EbFactoring();
  factoringList = new EbFactoringList();
  pendingFactoringList = new EbFactoringList();

  showFactoringAddEditModalMcb = new McBoolean();
  showFactoringProfileModalMcb = new McBoolean();
  addEditEbFactoringId!: number;
  idChildEntity!: number;

  showLoader!: boolean;
  responseMessage!: boolean;
  successResponseMessage!: boolean;
  viewCreateButton!: boolean;
  factoringEnableFlg!: boolean;
  isLoggedEntity!: boolean;

  entityMessage!: string;
  entityType!: string;
  message!: string;
  type!: string;

  selectedEntityPath!: any;
  selectedEntity!: string;
  entityObject!: any;
  entityId!: string | null;
  editEntityId!: string | null;
  entityName!: string;
  statusCd!: string;


  @ViewChild('factoringEnabled') factoringEnabled!: any;
  @ViewChild('factoringDisabled') factoringDisabled!: any;


  constructor(private entityService: McEntityService,
              private utilityService: McUtilityService,
              private stateManagementService: McStateManagementService,
              private renderer: Renderer2,
              private activatedRoute: ActivatedRoute) {
    this.getSelectedEntityPath();
  }

  ngOnInit() {
    /*Set unique Id for current entity*/
    // TODO create general method for this
    /*this.entityId = McGod.getLoggedEntityIdFromToken();
    this.isLoggedEntity = this.utilityService.isLoggedEntity();*/
    this.renderer.removeClass(document.body, 'mc-overflow-hidden');

    this.activatedRoute.paramMap.subscribe(data => {
      if (data.get('id')) {
        this.entityId = data.get('id');
        this.editEntityId = data.get('id');
        this.isLoggedEntity = data.get('id') !== this.utilityService.getPropertyFromToken('entity_id').toString();
      } else {
        this.entityId = this.utilityService.getPropertyFromToken('entity_id');
        this.isLoggedEntity = false;
      }
    });
    this.idChildEntity = Number(this.entityId);

    this.activeFactoring = new EbFactoring();

    // Setting factoring list
    this.factoringList = new EbFactoringList();
    this.factoringList.setPager(0, 50);
    this.factoringList.loadByChildEntityId(Number(this.entityId), () => {
      this.pendingFactoringList = this.factoringList.getPendingList();
      if (this.factoringList.getActiveItem() !== null) {
        this.activeFactoring = this.factoringList.getActiveItem();
      }
      if (this.factoringList.items.length > 0) {
        this.responseMessage = false;
      }
    });

    // Checking if parent enable or disable factoring
    this.entityService.getMcEntityById(Number(this.entityId)).subscribe(response => {
      this.entityName = response.name;
      this.entityObject = response;
      this.factoringEnableFlg = response.factoringEnableFlg;

      if (this.factoringEnableFlg === true) {
        this.factoringEnableFlg = response.factoringEnableFlg;
        this.responseMessage = true;
        this.viewCreateButton = true;
        this.setNotificationMessage('Currently there is no factoring contract created.', 'success');
      } else {
        this.factoringEnableFlg = response.factoringEnableFlg;
        this.responseMessage = false;
        this.viewCreateButton = false;
      }
    });
  }

  openAddEditFactoringModal() {
    this.addEditEbFactoringId = 0;
    this.showFactoringAddEditModalMcb.setTrue();
  }

  public actLoad(): void {
    this.ebFactoringFilter = new EbFactoringFilter();
    this.ebFactoringFilter.parentEntityId = McGod.getLoggedEntityIdFromToken();
    this.ebFactoringFilter.statusCds = this.mcForm.getValueArray('selStatusCd');
    this.ebFactoringFilter.childEntityIds = this.mcForm.getValueArray('selChildEntityId');
    console.log('filter030:', this.ebFactoringFilter);
    // TODO Need to connect this when the loadByFilter is implemented on be
    // this.ebFactoringList.loadByFilter(this.ebFactoringFilter);
    // this.ebFactoringList.searchTerm = this.mcForm.getValue('txbSearchTerm');
    // this.ebFactoringList.loadByFilter(this.ebFactoringFilter);
    this.factoringList.loadByChildEntityId(this.idChildEntity);
  }

  actFactoringTableItemAction(htmlButton: McHtmlButton) {
    if (htmlButton.actionCdForEvent === EbFactoring.ACTION_SHOW_PROFILE) {
      this.actShowProfileModal(htmlButton.item);
    } else if (htmlButton.actionCdForEvent === EbFactoring.ACTION_SHOW_REVOKE) {
      this.actRevoke(htmlButton.item);
    }
  }

  actShowProfileModal(ebFactoring: EbFactoring) {
    this.profileEbFactoringId = ebFactoring.id;
    this.ebFactoring = ebFactoring;
    this.showFactoringProfileModalMcb.setTrue();
  }

  actRevoke(ebFactoring: EbFactoring) {
    this.ebFactoring = ebFactoring;
    if (this.ebFactoring.statusCd === EbFactoring.STATUS_CD_PENDING_ACCEPTANCE) {
      this.ebFactoring.statusCd = EbFactoring.STATUS_CD_REVOKED;
      this.ebFactoring.save(() => {
        this.actLoad();
        this.message = `Factoring contract '${this.ebFactoring.factoringContractName}' revoked successfully.`;
        this.type = 'success';
      }, () => {
        this.message =  this.ebFactoring.apiErrorMessage;
        this.type = 'error';
      });
    } else {
      this.message =  `You can only revoke contracts in status "Pending acceptance".`;
      this.type = 'error';
    }
  }
  /*Get selected entity path*/
  private getSelectedEntityPath() {
    this.showLoader = true;
    this.entityService._selectedEntityPath.subscribe(response => {
      this.selectedEntityPath = response;
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
      if (error.status >= 500) {
        this.entityMessage = 'Server is not available at the moment, please try again later.';
        this.entityType = 'error';
        return;
      }
      this.entityMessage = error.error ? error.error.message : 'There is no basic info defined';
      this.entityType = 'error';
    });
  }

  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }


  // TODO simplify this method
  enableFactoring(event: any) {
    this.factoringEnableFlg = event;
    const body = {
      mailPrefix: '',
      active: this.entityObject.active,
      imageData: this.entityObject.logo,
      name: this.entityObject.name,
      mailDomain: this.entityObject.mailDomain,
      abbreviation: this.entityObject.abbreviation,
      webDomain: this.entityObject.webDomain,
      description: this.entityObject.description || null,
      ipWhitelist: this.entityObject.ipWhitelist || null,
      phone: this.entityObject.phone || null,
      bankAccount: this.entityObject.bankAccount || null,
      fax: this.entityObject.fax || null,
      euVat: this.entityObject.euVat || null,
      address: this.entityObject.address || null,
      impressum: this.entityObject.impressum || null,
      legalRepresentative: this.entityObject.legalRepresentative || null,
      legalRepresentativeContact: this.entityObject.legalRepresentativeContact || null,
      factoringEnableFlg: this.factoringEnableFlg
    };
    if (this.entityId) {
      this.showLoader = true;
      this.entityService.editCurrentEntityBasicInfo(body, this.entityId).subscribe(
        () => {
          this.viewCreateButton = true;
          this.responseMessage = true;
          this.setNotificationMessage('Currently there is no factoring contract created.', 'success');
          this.showLoader = false;
        });
    }
  }

  // TODO simplify this method
  disableFactoring(event: any) {
    if (event) {
      this.factoringEnableFlg = false;
    }
    const body = {
      mailPrefix: '',
      active: this.entityObject.active,
      imageData: this.entityObject.logo,
      name: this.entityObject.name,
      mailDomain: this.entityObject.mailDomain,
      abbreviation: this.entityObject.abbreviation,
      webDomain: this.entityObject.webDomain,
      description: this.entityObject.description || null,
      ipWhitelist: this.entityObject.ipWhitelist || null,
      phone: this.entityObject.phone || null,
      bankAccount: this.entityObject.bankAccount || null,
      fax: this.entityObject.fax || null,
      euVat: this.entityObject.euVat || null,
      address: this.entityObject.address || null,
      impressum: this.entityObject.impressum || null,
      legalRepresentative: this.entityObject.legalRepresentative || null,
      legalRepresentativeContact: this.entityObject.legalRepresentativeContact || null,
      factoringEnableFlg: this.factoringEnableFlg
    };
    if (this.entityId) {
      this.showLoader = true;
      this.entityService.editCurrentEntityBasicInfo(body, this.entityId).subscribe(
        () => {
          this.showLoader = false;
          this.responseMessage = false;
          this.viewCreateButton = false;
          this.showLoader = false;
        });
    }
  }

  factoringContractSaved() {
    this.factoringList.loadByChildEntityId(Number(this.entityId), () => {
      this.pendingFactoringList = this.factoringList.getPendingList();
    });
    this.successResponseMessage = true;
    this.message = 'Factoring contract created successfully';
    this.type = 'success';
    this.setNotificationMessage(this.message, this.type);
    this.showFactoringAddEditModalMcb.setFalse();
    setTimeout(() => {
      this.successResponseMessage = false;
    }, 10000);
  }

  onCancelAddEditModal() {
    this.showFactoringAddEditModalMcb.setFalse();
  }
}
