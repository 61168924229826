/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McProcessFlowLevelTable3ConfigGENERATED} from '../_generated/mc-process-flow-level-table-3-config-generated.model';

// import {McProcessFlowLevelGENERATED} from '../_generated/mc-process-flow-level-generated.model';

export class McProcessFlowLevelTable3Config extends McProcessFlowLevelTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdMcProcessFlow', 'McProcessFlow ID', 'idMcProcessFlow', 'idMcProcessFlow');
    this.addColumn('colTitle', 'Level title', 'title', 'title');
    this.addColumn('colDescription', 'Description', 'description', 'description');


  }
}
