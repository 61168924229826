<div *ngIf="!parentData" class="col-md-12 mc-stepper-header-title">
  <span class="pl-4 pb-1 mr-2" [ngClass]="passwordPolicyTab ? 'mc-semi-active': ''">
    <span class="mc-notification-badge-gray" [ngClass]="passwordPolicyTab ? 'mc-semi-active-background': ''">1</span>
    {{'cc.common.view.basic-info' | translate}}
  </span>
  <span class="pl-4 pb-1 ml-4 mr-2" [ngClass]="passwordPolicyTab ? 'mc-semi-active': ''">
    <span class="mc-notification-badge-gray" [ngClass]="passwordPolicyTab ? 'mc-semi-active-background': ''">2</span>
    {{ 'cc.common.view.attributes' | translate }}
  </span>
  <span class="pl-4 pb-1 ml-4 mr-2" [ngClass]="passwordPolicyTab ? 'mc-active': ''">
    <span class="mc-notification-badge-gray" [ngClass]="passwordPolicyTab ? 'mc-active-background': ''">3</span>
      Password policy
  </span>
  <span class="pl-4 pb-1 ml-4 mr-2">
    <span class="mc-notification-badge-gray">4</span>
      Pricelist
  </span>
</div>

<!--Modal body section-->
<div class="row mc-entity-password-policy gray-background">
  <form [formGroup]="entityPasswordPolicyForm" class="entity-password-policy-form col-md-12">
    <div [ngClass]="!editEntityId ?  'mc-notify-wrapper-create-password-policy' : 'mc-notify-wrapper-password-policy'">
      <mc-notify-message [message]="message" [type]="type"></mc-notify-message>
    </div>
    <div class="row">
      <!--Inherit from parent-->
      <div class="col-md-12 parent-password-policy mb-5">
        <label class="custom-control fill-checkbox">
          <input
            type="checkbox"
            class="fill-control-input"
            id="inheritParent"
            name="inheritParent"
            formControlName="inheritParent"
            #inheritParent
            (click)="inheritParentPassPolicy(inheritParent.checked)"
          />
          <span class="fill-control-indicator"></span>
          <span class="fill-control-description">{{ "cc.common.edit.use-default" | translate }}</span>
        </label>
      </div>
    </div>
    <!--Restore default values-->
    <span class="restore-policy mc-cursor-pointer mb-2" [ngClass]="{'disable-restore-btn' : inheritParent.checked === true}" (click)="restoreDefault()">{{'cc.common.edit.undo-changes' | translate}}</span>
    <div class="parent-policy-wrapper">
      <span class="parent-policy-text">{{ 'cc.common.edit.parent-password-policy' | translate }}</span>
      <div class="row">
        <!--Maximum retry count-->
        <div class="col-md-6" [ngClass]="{'disable-password-policy-wrapper' : inheritParent.checked === true}">
          <div class="row form-group">
            <div class="col-md-7 center-label-wrap">
              <label for="maxRetry" class="label-position">{{ 'cc.common.edit.maximum-retry-count' | translate }}</label>
            </div>
            <div class="col-md-5">
              <input type="number"
                     name="maxRetry"
                     class="form-control"
                     id="maxRetry"
                     formControlName="maxRetry"
                     min="0"
                     oninput="this.value = Math.abs(this.value)"
                     #maxRetry
                     [ngClass]="{
                   'disable-password-policy' : inheritParent.checked === true,
                   'is-invalid' : entityPasswordPolicyForm.get('maxRetry')?.touched && entityPasswordPolicyForm.get('maxRetry')?.invalid,
                   'invalid-number' : restoredValues === false && entityPasswordPolicyForm.get('maxRetry')?.touched && entityPasswordPolicyForm.get('maxRetry')?.invalid
                  }"
                     (ngModelChange)="checkValidation()">
              <span>{{maxRetry.value == 1 ? ' time' : ' times'}}</span>
            </div>
          </div>
        </div>
        <!--Minimum different passwords-->
        <div class="col-md-6" [ngClass]="{'disable-password-policy-wrapper' : inheritParent.checked === true}">
          <div class="row form-group">
            <div class="col-md-7 center-label-wrap">
              <label for="minDiffPass" class="label-position">{{ 'cc.common.edit.minimum-different-passwords' | translate }}</label>
            </div>
            <div class="col-md-5">
              <input type="number"
                     name="minDiffPass"
                     class="form-control"
                     id="minDiffPass"
                     formControlName="minDiffPass"
                     min="0"
                     oninput="this.value = Math.abs(this.value)"
                     #minDiffPass
                     [ngClass]="{
                   'disable-password-policy' : inheritParent.checked === true,
                   'is-invalid' : entityPasswordPolicyForm.get('minDiffPass')?.touched && entityPasswordPolicyForm.get('minDiffPass')?.invalid,
                   'invalid-number' : restoredValues === false && entityPasswordPolicyForm.get('minDiffPass')?.touched && entityPasswordPolicyForm.get('minDiffPass')?.invalid
                 }"
                     (ngModelChange)="checkValidation()">
              <span>{{minDiffPass.value == 1 ? ' password' : ' passwords'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!--Lockout time-->
        <div class="col-md-6" [ngClass]="{'disable-password-policy-wrapper' : inheritParent.checked === true}">
          <div class="row form-group">
            <div class="col-md-7 center-label-wrap">
              <label for="lockTime" class="label-position">{{ 'cc.common.edit.lockout-time' | translate }}</label>
            </div>
            <div class="col-md-5">
              <input type="number"
                     name="lockTime"
                     class="form-control"
                     id="lockTime"
                     formControlName="lockTime"
                     min="0"
                     oninput="this.value = Math.abs(this.value)"
                     #lockTime
                     [ngClass]="{
                   'disable-password-policy' : inheritParent.checked === true,
                   'is-invalid' : entityPasswordPolicyForm.get('lockTime')?.touched && entityPasswordPolicyForm.get('lockTime')?.invalid,
                   'invalid-number' : restoredValues === false && entityPasswordPolicyForm.get('lockTime')?.touched && entityPasswordPolicyForm.get('lockTime')?.invalid
                 }"
                     (ngModelChange)="checkValidation()">
              <span>{{lockTime.value == 1 ? ' minute' : ' minutes'}}</span>
            </div>
          </div>
        </div>
        <!--Minimum digits-->
        <div class="col-md-6" [ngClass]="{'disable-password-policy-wrapper' : inheritParent.checked === true}">
          <div class="row form-group">
            <div class="col-md-7 center-label-wrap">
              <label for="minDigits" class="label-position">{{ 'cc.common.edit.minimum-digits' | translate }}</label>
            </div>
            <div class="col-md-5">
              <input type="number"
                     name="minDigits"
                     class="form-control"
                     id="minDigits"
                     formControlName="minDigits"
                     min="0"
                     oninput="this.value = Math.abs(this.value)"
                     #minDigits
                     [ngClass]="{
                   'disable-password-policy' : inheritParent.checked === true,
                   'is-invalid' : entityPasswordPolicyForm.get('minDigits')?.touched && entityPasswordPolicyForm.get('minDigits')?.invalid,
                   'invalid-number' : restoredValues === false && entityPasswordPolicyForm.get('minDigits')?.touched && entityPasswordPolicyForm.get('minDigits')?.invalid
                 }"
                     (ngModelChange)="checkValidation()">
              <span>{{minDigits.value == 1 ? ' digit' : ' digits'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!--Minimum password length-->
        <div class="col-md-6" [ngClass]="{'disable-password-policy-wrapper' : inheritParent.checked === true}">
          <div class="row form-group">
            <div class="col-md-7 center-label-wrap">
              <label for="minPassLength" class="label-position">{{'cc.common.edit.minimum-password-length' | translate}}</label>
            </div>
            <div class="col-md-5">
              <input type="number"
                     name="minPassLength"
                     class="form-control"
                     id="minPassLength"
                     formControlName="minPassLength"
                     min="6"
                     oninput="this.value = Math.abs(this.value)"
                     #minPassLength
                     [ngClass]="{
                   'disable-password-policy' : inheritParent.checked === true,
                   'is-invalid' : entityPasswordPolicyForm.get('minPassLength')?.touched && entityPasswordPolicyForm.get('minPassLength')?.invalid,
                   'invalid-number' : restoredValues === false && entityPasswordPolicyForm.get('minPassLength')?.touched && entityPasswordPolicyForm.get('minPassLength')?.invalid
                 }"
                     (ngModelChange)="checkValidation()">
              <span>{{minPassLength.value == 1 ? ' character' : ' characters'}}</span>
            </div>
          </div>
        </div>
        <!--Minimum special characters-->
        <div class="col-md-6" [ngClass]="{'disable-password-policy-wrapper' : inheritParent.checked === true}">
          <div class="row form-group">
            <div class="col-md-7 center-label-wrap">
              <label for="minSpecChar" class="label-position">{{ 'cc.common.edit.minimum-special-characters' | translate }}</label>
            </div>
            <div class="col-md-5">
              <input type="number"
                     name="minSpecChar"
                     class="form-control"
                     id="minSpecChar"
                     formControlName="minSpecChar"
                     min="0"
                     oninput="this.value = Math.abs(this.value)"
                     #minSpecChar
                     [ngClass]="{
                   'disable-password-policy' : inheritParent.checked === true,
                   'is-invalid' : entityPasswordPolicyForm.get('minSpecChar')?.touched && entityPasswordPolicyForm.get('minSpecChar')?.invalid,
                   'invalid-number' : restoredValues === false && entityPasswordPolicyForm.get('minSpecChar')?.touched && entityPasswordPolicyForm.get('minSpecChar')?.invalid
                 }"
                     (ngModelChange)="checkValidation()">
              <span>{{minSpecChar.value == 1 ? ' character' : ' characters'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!--Password expiration period-->
        <div class="col-md-6" [ngClass]="{'disable-password-policy-wrapper' : inheritParent.checked === true}">
          <div class="row form-group">
            <div class="col-md-7 center-label-wrap">
              <label for="passExPeriod" class="label-position">{{ 'cc.common.edit.password-expiration-period' | translate }}</label>
            </div>
            <div class="col-md-5">
              <input type="number"
                     name="passExPeriod"
                     class="form-control"
                     id="passExPeriod"
                     formControlName="passExPeriod"
                     min="1"
                     oninput="this.value = Math.abs(this.value)"
                     #passExPeriod
                     [ngClass]="{
                   'disable-password-policy' : inheritParent.checked === true,
                   'is-invalid' : entityPasswordPolicyForm.get('passExPeriod')?.touched && entityPasswordPolicyForm.get('passExPeriod')?.invalid,
                   'invalid-number' : restoredValues === false && entityPasswordPolicyForm.get('passExPeriod')?.touched && entityPasswordPolicyForm.get('passExPeriod')?.invalid
                 }"
                     (ngModelChange)="checkValidation()">
              <span>{{passExPeriod.value == 1 ? ' day' : ' days'}}</span>
            </div>
          </div>
        </div>
        <!--Minimum lowercase letters-->
        <div class="col-md-6" [ngClass]="{'disable-password-policy-wrapper' : inheritParent.checked === true}">
          <div class="row form-group">
            <div class="col-md-7 center-label-wrap">
              <label for="minLowLetters" class="label-position">Minimum lowercase letters</label>
            </div>
            <div class="col-md-5">
              <input type="number"
                     name="minLowLetters"
                     class="form-control"
                     id="minLowLetters"
                     formControlName="minLowLetters"
                     min="0"
                     oninput="this.value = Math.abs(this.value)"
                     #minLowLetters
                     [ngClass]="{
                   'disable-password-policy' : inheritParent.checked === true,
                   'is-invalid' : entityPasswordPolicyForm.get('minLowLetters')?.touched && entityPasswordPolicyForm.get('minLowLetters')?.invalid,
                   'invalid-number' : restoredValues === false && entityPasswordPolicyForm.get('minLowLetters')?.touched && entityPasswordPolicyForm.get('minLowLetters')?.invalid
                 }"
                     (ngModelChange)="checkValidation()">
              <span>{{minLowLetters.value == 1 ? ' letter' : ' letters'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!--Minimum different characters-->
        <div class="col-md-6" [ngClass]="{'disable-password-policy-wrapper' : inheritParent.checked === true}">
          <div class="row form-group">
            <div class="col-md-7 center-label-wrap">
              <label for="minDiffChar" class="label-position">{{ 'cc.common.edit.minimum-different-characters' | translate }}</label>
            </div>
            <div class="col-md-5">
              <input type="number"
                     name="minDiffChar"
                     class="form-control"
                     id="minDiffChar"
                     formControlName="minDiffChar"
                     min="1"
                     oninput="this.value = Math.abs(this.value)"
                     #minDiffChar
                     [ngClass]="{
                   'disable-password-policy' : inheritParent.checked === true,
                   'is-invalid' : entityPasswordPolicyForm.get('minDiffChar')?.touched && entityPasswordPolicyForm.get('minDiffChar')?.invalid,
                   'invalid-number' : restoredValues === false && entityPasswordPolicyForm.get('minDiffChar')?.touched && entityPasswordPolicyForm.get('minDiffChar')?.invalid
                 }"
                     (ngModelChange)="checkValidation()">
              <span>{{minDiffChar.value == 1 ? ' character' : ' characters'}}</span>
            </div>
          </div>
        </div>
        <!--Minimum uppercase letters-->
        <div class="col-md-6" [ngClass]="{'disable-password-policy-wrapper' : inheritParent.checked === true}">
          <div class="row form-group">
            <div class="col-md-7 center-label-wrap">
              <label for="minUpLetters" class="label-position">{{ 'cc.common.edit.minimum-uppercase-letters' | translate}}</label>
            </div>
            <div class="col-md-5">
              <input type="number"
                     name="minUpLetters"
                     class="form-control"
                     id="minUpLetters"
                     formControlName="minUpLetters"
                     min="0"
                     oninput="this.value = Math.abs(this.value)"
                     #minUpLetters
                     [ngClass]="{
                   'disable-password-policy' : inheritParent.checked === true,
                   'is-invalid' : entityPasswordPolicyForm.get('minUpLetters')?.touched && entityPasswordPolicyForm.get('minUpLetters')?.invalid,
                   'invalid-number' : restoredValues === false && entityPasswordPolicyForm.get('minUpLetters')?.touched && entityPasswordPolicyForm.get('minUpLetters')?.invalid
                 }"
                     (ngModelChange)="checkValidation()">
              <span>{{minUpLetters.value == 1 ? ' letter' : ' letters'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Edit passwordPolicy form-->
    <div *ngIf="isEdit" class="row mt-5">
      <div class="col-md-12">
        <button type="button" class="modal-save mb-4" [ngClass]="isDisabled === false ? 'mc-confirm-btn' : 'mc-disable-btn'" [disabled]="isDisabled" (click)="changeEntityPasswordPolicy()">{{'cc.common.view.save' | translate}}</button>
      </div>
    </div>
  </form>
</div>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
