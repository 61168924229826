export const ICON_LIGHT_BULD = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.6" clip-path="url(#clip0_2556_18595)">
                                <path d="M7.49996 17.5C7.49996 17.9584 7.87496 18.3334 8.33329 18.3334H11.6666C12.125 18.3334 12.5 17.9584 12.5 17.5V16.6667H7.49996V17.5ZM9.99996 1.66669C6.78329 1.66669 4.16663 4.28335 4.16663 7.50002C4.16663 9.48335 5.15829 11.225 6.66663 12.2834V14.1667C6.66663 14.625 7.04163 15 7.49996 15H12.5C12.9583 15 13.3333 14.625 13.3333 14.1667V12.2834C14.8416 11.225 15.8333 9.48335 15.8333 7.50002C15.8333 4.28335 13.2166 1.66669 9.99996 1.66669ZM12.375 10.9167L11.6666 11.4167V13.3334H8.33329V11.4167L7.62496 10.9167C6.49996 10.1334 5.83329 8.85835 5.83329 7.50002C5.83329 5.20002 7.69996 3.33335 9.99996 3.33335C12.3 3.33335 14.1666 5.20002 14.1666 7.50002C14.1666 8.85835 13.5 10.1334 12.375 10.9167Z" fill="#333867"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_2556_18595">
                                <rect width="20" height="20" fill="white"/>
                                </clipPath>
                                </defs>
                                </svg>
`
