import {McPriceList2Entity} from './mc-price-list-2-entity.model';
import {McPriceList2EntityListGENERATED} from '../_generated/mc-price-list-2-entity-list-generated.model';

export class McPriceList2EntityList extends McPriceList2EntityListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McPriceList2EntityList();
  }

  // ---------------------------------------------------------------------
}
