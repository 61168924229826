import {Inject, Injectable} from '@angular/core';
import {McProductSnapshotServiceGENERATED} from '../_generated/mc-product-snapshot-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McProductSnapshotService extends McProductSnapshotServiceGENERATED {

}
