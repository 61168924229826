<ng-container>
  <div class="content-wrapper">
    <div class="content-header">
      <h1>{{'cc.out-factoring.add-new-type-of-request' | translate}}</h1>
    </div>
    <div class="content-body">

      <mcc-fi-textbox [name]="'requestType'" label="{{'cc.out-factoring.type-of-request-name' | translate}}"
                      [validRequiredFlg]="true"
                      #requestType
                      [mcForm]="mcForm"
                      [validWhitespaces]="true">
      </mcc-fi-textbox>
    </div>
    <div class="content-footer">
      <button type="button" class="btn btn-secondary move-right" (click)="actCancel()">{{'cc.common.edit.cancel' | translate}}</button>
      <button type="button" class="btn btn-primary" (click)="addRequest(requestType)">{{'cc.common.table.add' | translate}}</button>
    </div>
  </div>
</ng-container>

