import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {McMenu, McMenuItem} from "@miticon-ui/mc-core";
import {Router} from "@angular/router";

@Component({
  selector: 'mcc-menu',
  templateUrl: './mcc-menu.component.html',
  styleUrls: ['./mcc-menu.component.scss']
})
export class MccMenuComponent implements OnInit {

  @Input() mcMenu!: McMenu;
  @Input() horizontalDirectionFlg = true;
  @Output() menuItemClickedEmit = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit() {
  }

  menuItemClicked(menuItem: McMenuItem) {
    this.router.navigate([menuItem.route]);
    // this.menuItemClickedEmit.emit(menuItem);
  }
}
