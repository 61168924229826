<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcPdfTemplate.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h1 *ngIf="mcPdfTemplate.exists()">{{'cc.common.view.edit' | translate}} {{mcPdfTemplate.title}} {{'cc.common.pdf-template' | translate}}</h1>
    <h1 *ngIf="!mcPdfTemplate.exists()">{{'cc.common.add-pdf-template' | translate}}</h1>

  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-body">
    <mcc-message [errorMsg]="errorMsg"></mcc-message>
    <mcc-message [errorMsgs]="errorMsgs" label="{{'cc.my-factoring.unable-to-proceed' | translate}}: "></mcc-message>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container *ngIf="(mcPdfTemplate.apiSuccessFlg) && (!hasErrors())">

      <div class="row">
        <div class="col-md-12">
          <div class="content-section">

            <div class="input-group mb-4">

              <mcc-fi-file-upload2 [mcForm]="mcForm" [validRequiredFlg]="true" [valueWebFileId]="mcPdfTemplate.idWebfile" (eventFailed)="eventWebFileUploadingFailed($event)"
                                   (eventSucceed)="eventWebFileUploaded($event)"
                                   [mcFileUploadingTrigger]="mcFileUploadingTrigger"></mcc-fi-file-upload2>

            </div>

            <mcc-fi-radio-buttons2 label="{{'cc.common.view.status' | translate}}"
                                   [name]="'ctrlStatusCd'"
                                   [directionHorizontal]="true"
                                   [mcForm]="mcForm"
                                   [value]="mcPdfTemplate.statusCd"
                                   [validRequiredFlg]="true"
                                   [optionsVll]="mcProcessStatusVll"></mcc-fi-radio-buttons2>

            <mcc-fi-radio-buttons2 label="{{'cc.processes.process-type' | translate}}"
                                   [name]="'ctrlTypeCd'"
                                   [directionHorizontal]="true"
                                   [mcForm]="mcForm"
                                   [value]="mcPdfTemplate.typeCd"
                                   [validRequiredFlg]="true"
                                   [optionsVll]="mcProcessTypeVll"></mcc-fi-radio-buttons2>

            <mcc-fi-textbox name="ctrlTitle" label="{{'cc.common.edit.title' | translate}}"
                            [value]="mcPdfTemplate.title" [validRequiredFlg]="true"
                            [mcForm]="mcForm">
            </mcc-fi-textbox>

            <mcc-fi-textarea [name]="'ctrlDescription'" [value]="mcPdfTemplate.description" label="{{'cc.common.view.description' | translate}}"
                             [numCols]="45"
                             [validRequiredFlg]="true" [mcForm]="mcForm"></mcc-fi-textarea>


          </div>
        </div>
      </div><!-- row -->
    </ng-container>

  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="{{'cc.common.edit.cancel' | translate}}"></mcc-button>
    <mcc-button *ngIf="(mcPdfTemplate.apiSuccessFlg) && (!hasErrors())" extraCssClasses="btn btn-primary"
                (eventClicked)="actSave();" label="{{'cc.common.view.save' | translate}}" disableOnFirstClickFlg="true" [disabledMcb]="saveButtonDisabledMcb"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
