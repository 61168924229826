import {McPriceList2EntityFilterGENERATED} from '../_generated/mc-price-list-2-entity-filter-generated.model';

export class McPriceList2EntityFilter extends McPriceList2EntityFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McPriceList2EntityFilter();
  }

  // ---------------------------------------------------------------------
}
