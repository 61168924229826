/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {WebFileFilter} from '../models/web-file-filter.model';

export class WebFileFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): WebFileFilter {
    console.error('MTCN-ERROR: Not Implemented: WebFileFilter::createNewInstance(). Add this method to final class and return new WebFileFilter();');
      throw new Error('Cannot WebFileFilter::createNewInstance(). Add this method to final class and return new WebFileFilter();');
  }

}
