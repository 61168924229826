<span class="back-option" (click)="goToOverview()">
  <span [innerHTML]="iconBack | safeHtml"></span>{{ "cc.consumer.back-to-overview" | translate }}
</span>
<section *ngIf="csrConsumer">
  <div class="cardbox">
    <div class="cardbox_header">
      <span class="cardbox_title">{{( isCompany() ? csrConsumer.companyName : csrConsumer.firstName + " " + csrConsumer.lastName)| uppercase}}</span>
      <button mat-button
              class="primary-btn"
              (click)="goToEditConsumer()"
              type="submit">
        {{ 'cc.consumer.edit-consumer' | translate }}
      </button>
    </div>
    <div class="divider"></div>
    <div class='dialog-row-wrapper dialog-header'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumer.view.consumer-id' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.id ? csrConsumer.id : '-' }}</span>
      </div>
      <div class='dialog-col' *ngIf="!isCompany()">
        <span class='dialog-col-label'>{{ 'cc.common.edit.birthday' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.birthday ? getFormattedDate(csrConsumer.birthday) : '-' }}</span>
      </div>
      <div class='dialog-col' *ngIf="!isCompany()">
        <span class='dialog-col-label'>{{ 'cc.common.edit.gender' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.gender ? csrConsumer.gender : '-' }}</span>
      </div>
      <div class='dialog-col' *ngIf="isCompany()">
        <span class='dialog-col-label'>{{ 'cc.common.edit.first-name' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.firstName ? csrConsumer.firstName : '-' }}</span>
      </div>
      <div class='dialog-col' *ngIf="isCompany()">
        <span class='dialog-col-label'>{{ 'cc.common.edit.last-name' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.lastName ? csrConsumer.lastName : '-' }}</span>
      </div>
      <div class='dialog-col' *ngIf="isCompany()">
        <span class='dialog-col-label'>{{ 'cc.common.edit.title' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.title ? csrConsumer.title : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.product.view.vat' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.euVat ? csrConsumer.euVat : '-' }}</span>
      </div>
    </div>
  </div>
  <div class="tabs cardbox">
    <div class="tabs_option" (click)="setActiveTab(tab)" [class.activeTab]="tab.value === activeTab" *ngFor="let tab of consumerInfoTabs">
      <span class="tabs_option_title">{{ tab.title | translate }}</span>
    </div>
  </div>
  <div class="cardbox" *ngIf="activeTab === 'Consumer'">
    <h2>{{'cc.consumer.overview.status-information' | translate}}</h2>
    <div class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.jobs.edit.type' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.typeCd ? csrConsumer.typeCd : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.action.type.dunning' | translate}}:</span>
        <span class='dialog-col-value'>{{ (csrConsumer.flgDunningEnabled ? 'cc.common.edit.yes' : 'cc.common.edit.no') | translate }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.action.type.inkasso' | translate}}:</span>
        <span class='dialog-col-value'>{{ (csrConsumer.flgInkassoEnabled ? 'cc.common.edit.yes' : 'cc.common.edit.no') | translate }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.edit.external-id' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.extConsumerId ? csrConsumer.extConsumerId : '-' }}</span>
      </div>
    </div>

    <div class="divider"></div>

    <h2>{{ "cc.common.edit.address" | translate}}</h2>
    <div class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.street' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.street ? csrConsumer.street : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.address-supplement' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.addressSupplement ? csrConsumer.addressSupplement : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.postal-code' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.postCode ? csrConsumer.postCode : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.edit.city' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.city ? csrConsumer.city : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.users.edit.country' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.countryName ? csrConsumer.countryName : '-' }}</span>
      </div>
    </div>

    <div class="divider"></div>

    <h2>{{ "cc.consumer.overview.contact" | translate}}</h2>
    <div class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.edit.email' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.email ? csrConsumer.email : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.edit.telephone' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.telephone ? csrConsumer.telephone : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.cellphone' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.cellphone ? csrConsumer.cellphone : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.edit.fax' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.fax ? csrConsumer.fax : '-' }}</span>
      </div>
    </div>

    <div class="divider"></div>

    <h2>{{'cc.users.edit.language' | translate}}</h2>
    <div class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.iso-country' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.isoCountry ? getCountryName(csrConsumer.isoCountry) : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.iso-language' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.isoLanguage ? getLanguageName(csrConsumer.isoLanguage) : '-' }}</span>
      </div>
    </div>
  </div>

  <div class="cardbox" *ngIf="activeTab === 'Additional'">
    <h2>{{'cc.consumers.edit.contract-information' | translate}}</h2>
    <div class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-number' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.contractNumber ? csrContract.contractNumber : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-model' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.contractModel ?  csrContract.contractModel : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-type' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.contractType ?  csrContract.contractType : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-status' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.statusCd ? csrContract.statusCd : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-signing-date' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.contractSigningDate ? getFormattedDate(csrContract.contractSigningDate) : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-start-date' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.startDate ? getFormattedDate(csrContract.startDate) : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-end-date' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.endDate ? getFormattedDate(csrContract.endDate) : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-base-amount' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.priceUnit ? csrContract.priceUnit : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-cancellation-date' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.contractCancellationDate ? getFormattedDate(csrContract.contractCancellationDate) : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.cancellation-active-on-date' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.contractCancellationActiveOnDate ? getFormattedDate(csrContract.contractCancellationActiveOnDate) : '-'}}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-cancellation-reason' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.cancellationReason ? csrContract.cancellationReason : '-'}}</span>
      </div>
    </div>

    <section *ngIf="hasCustomInfo()">
      <div class="divider"></div>
      <h2>{{'cc.consumer.custom-info' | translate}}</h2>
      <div *ngIf="csrConsumer.additionalAttributes" class='dialog-row-wrapper'>
        <ng-container *ngFor="let item of csrConsumer.additionalAttributes | keyvalue">
          <div *ngIf="item.key.indexOf('contract') === -1" class='dialog-col'>
            <span class='dialog-col-label'>{{ item.key }}:</span>
            <span class='dialog-col-value'>{{ item.value }}</span>
          </div>
        </ng-container>
      </div>
    </section>
  </div>

  <section *ngIf="activeTab === 'Bank'">
    <div class="cardbox" *ngIf="noBankAccounts">{{ 'cc.consumer.no-bank-accounts' | translate }}</div>
    <div class="cardbox" *ngIf="primaryBankAccount">
      <div class="cardbox_header">
        <h2 class="cardbox_title">{{ primaryBankAccount.bankName }}</h2>
        <span class="cardbox_header_icon" [innerHTML]="iconEdit | safeHtml" (click)="goToEditConsumer()"></span>
      </div>
      <div class="divider"></div>
      <div class='dialog-row-wrapper'>
        <div class='dialog-col'>
          <span class='dialog-col-label'>{{ 'cc.consumers.edit.account-owner' | translate}}:</span>
          <span class='dialog-col-value'>{{ primaryBankAccount.owner ? primaryBankAccount.owner : '-' }}</span>
        </div>
        <div class='dialog-col'>
          <span class='dialog-col-label'>{{ 'cc.consumers.edit.iban' | translate}}:</span>
          <span class='dialog-col-value'>{{ primaryBankAccount.iban ? primaryBankAccount.iban : '-' }}</span>
        </div>
        <div class='dialog-col'>
          <span class='dialog-col-label'>{{ 'cc.consumers.edit.bic' | translate}}:</span>
          <span class='dialog-col-value'>{{ primaryBankAccount.bic ? primaryBankAccount.bic : '-' }}</span>
        </div>
        <div class='dialog-col'>
          <span class='dialog-col-label'>{{ 'cc.consumer.overview.sepa-mandate-id' | translate}}:</span>
          <span class='dialog-col-value'>{{ primaryBankAccount.sepaMandateId ? primaryBankAccount.sepaMandateId : '-' }}</span>
        </div>
        <div class='dialog-col'>
          <span class='dialog-col-label'>{{ 'cc.consumer.overview.sepa-mandate-date-of-signature' | translate }}:</span>
          <span class='dialog-col-value'>{{ primaryBankAccount.sepaMandateDate ? getFormattedDate(primaryBankAccount.sepaMandateDate) : '-' }}</span>
        </div>
      </div>
    </div>

    <h2 class="bank_title" *ngIf="previousBankAccounts && previousBankAccounts.length > 0">{{ 'cc.consumer.previous-accounts' | translate }}</h2>
    <div class="cardbox" *ngFor="let bankAccount of previousBankAccounts">
      <h2>{{ bankAccount.bankName }}</h2>
      <div class="divider"></div>
      <div class='dialog-row-wrapper'>
        <div class='dialog-col'>
          <span class='dialog-col-label'>{{ 'cc.consumers.edit.account-owner' | translate}}:</span>
          <span class='dialog-col-value'>{{ bankAccount.owner ? bankAccount.owner : '-' }}</span>
        </div>
        <div class='dialog-col'>
          <span class='dialog-col-label'>{{ 'cc.consumers.edit.iban' | translate}}:</span>
          <span class='dialog-col-value'>{{ bankAccount.iban ? bankAccount.iban : '-' }}</span>
        </div>
        <div class='dialog-col'>
          <span class='dialog-col-label'>{{ 'cc.consumers.edit.bic' | translate}}:</span>
          <span class='dialog-col-value'>{{ bankAccount.bic ? bankAccount.bic : '-' }}</span>
        </div>
        <div class='dialog-col'>
          <span class='dialog-col-label'>{{ 'cc.consumer.overview.sepa-mandate-id' | translate}}:</span>
          <span class='dialog-col-value'>{{ bankAccount.sepaMandateId ? bankAccount.sepaMandateId : '-' }}</span>
        </div>
        <div class='dialog-col'>
          <span class='dialog-col-label'>{{ 'cc.consumer.overview.sepa-mandate-date-of-signature' | translate }}:</span>
          <span class='dialog-col-value'>{{ bankAccount.sepaMandateDate ? getFormattedDate(bankAccount.sepaMandateDate) : '-' }}</span>
        </div>
      </div>
    </div>
  </section>
</section>
