/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McBillingStatementItem} from '../models/mc-billing-statement-item.model';
import {McBillingStatementItemList} from '../models/mc-billing-statement-item-list.model';
import {McBillingStatementItemFilter} from '../models/mc-billing-statement-item-filter.model';
import {McBillingStatementItemService} from '../services/mc-billing-statement-item.service';
import {IMcBillingStatementItem} from '../_generated/mc-billing-statement-item.interface';
import {McGod} from "../extra/mc-god.service";

export class McBillingStatementItemListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'amount', 'correctionTypeCd', 'description', 'idBillingService', 'mcBillingBatchId', 'mcBillingStatementId', 'priceDescription', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'title', 'transactionReturnedTypeCd', 'typeCd', 'vatPercentageForCorrection', 'vatXAmount', 'currencyCode', 'mcBillingBatchName', 'nameOfBillingServiceForStatementItem', 'numberOfBillingItemsInAggregation', 'period', 'totalAmount', 'vatForBillingStatementItem', 'vatPercentageForAggregateAndSingle'];
  public items: McBillingStatementItem[] = [];
  public apiService: McBillingStatementItemService;
 // protected tempObjList: McBillingStatementItemList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingStatementItemList {
    console.error('MTCN-ERROR: Not Implemented: McBillingStatementItemList::createNewInstance(). Add this method to final class and return new McBillingStatementItemList();');
    throw new Error('Cannot McBillingStatementItemList::createNewInstance(). Add this method to final class and return new McBillingStatementItemList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcBillingStatementItem[]): McBillingStatementItemList {
    const  listObj: McBillingStatementItemList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McBillingStatementItem = McBillingStatementItem.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McBillingStatementItemList {
    const  listObj: McBillingStatementItemList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingStatementItemService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcBillingStatementItem[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McBillingStatementItem = McBillingStatementItem.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcBillingStatementItemFilter: McBillingStatementItemFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcBillingStatementItemFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McBillingStatementItem {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McBillingStatementItem();
    }
  }

  public replaceItem(newItem: McBillingStatementItem) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadAllByMcBillingStatementId( mcBillingStatementId: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getAllByMcBillingStatementId(mcBillingStatementId, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

