import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {McEntityAttributesComponent} from "./mc-entity-attributes.component";
import {NgbDatepickerModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {McComponentsModule} from "@miticon-ui/mc-components";
import {McEntityLocalAttributesModalComponent} from "./components/mc-entity-local-attributes/mc-entity-local-attributes-modal.component";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [McEntityAttributesComponent, McEntityLocalAttributesModalComponent],
  exports: [
    McEntityAttributesComponent,
    McEntityLocalAttributesModalComponent
  ],
  imports: [
    CommonModule,
    NgbTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    McComponentsModule,
    NgbDatepickerModule,
    TranslateModule
  ]
})
export class McEntityAttributesModule { }
