import {Component, OnInit} from '@angular/core';
import {McBankAccountList, McUtilityService} from '@miticon-ui/mc-core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mc-bank-accounts-current-entity-admin',
  templateUrl: './mc-bank-accounts-current-entity-admin.component.html',
})
export class McBankAccountsCurrentEntityAdminComponent implements OnInit {

  /*Public variables*/
  message!: string;
  type!: string;
  entityId!: any;
  bankAccounts!: McBankAccountList;

  constructor(
    private utilityService: McUtilityService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService
  ) {
  }

  /*On init class*/
  ngOnInit() {
    /*Set unique Id for current entity*/
    this.activatedRoute.paramMap.subscribe(data => {
      if (data.get('id')) {
        this.entityId = data.get('id');
      } else {
        this.entityId = this.utilityService.getPropertyFromToken('entity_id');
      }
    });

    this.bankAccounts = new McBankAccountList();
    this.getBankAccountByEntityId();
  }

  // Get bankAccounts for current entity
  getBankAccountByEntityId() {
    this.bankAccounts.loadByEntityId(this.entityId, () => {}, () => {
      this.message = this.bankAccounts.apiErrorMessage;
      this.type = 'error';
    });
  }

  // Update bankAccounts
  refreshBankAccountList(event: boolean) {
    if (event === true) {
      this.getBankAccountByEntityId();
    }
  }

  // Display error message on delete bankAccount used in some invoices
  onDeleteBankAccount(bankAccount: any) {
    if (bankAccount !== '') {
      this.message = bankAccount;
      this.type = 'error';
    } else {
      this.message = '';
      this.type = '';
    }
  }
}
