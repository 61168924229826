import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "mc-edit-entity-attributes-modal",
  templateUrl: "./mc-edit-entity-attributes-modal.component.html",
})
export class McEditEntityAttributesModalComponent implements OnInit {

  modalReference!: any;
  isFormEdit!: boolean;

  @Input() editEntityId!: number;
  @Input() localAttributesData!: any;
  @Input() inheritedAttributesData!: any;
  @Input() markedInheritedAttributes!: any;
  @Output() entityAttributesEvent = new EventEmitter<any>();

  constructor(
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
  }

  openModal(content: any) {
    /*Modal reference - used for close it*/
    this.modalReference = this.modalService.open(content, {
      centered: true,
      size: "lg",
      windowClass: "mc-edit-entity-attributes-modal",
      backdrop: "static",
      beforeDismiss: () => {
        this.entityAttributesEvent.emit(true);
        return true;
      }
    });
    this.isFormEdit = true;
  }
}
