<div class="content-wrapper">
  <div *ngIf="totalItemsCount > 0" class="col-md-12 py-4">
    <h3>Contract ID: {{ contractId }}</h3>
    <mk-mat-table
      [isLoading]="false"
      [items]="items"
      [itemsPerPage]="pageSize"
      [totalItemsCount]="totalItemsCount"
      [config]="tableConfig"
      [filterConfig]="filterConfig"
      [showFilters]="false"
      [showTotalItems]="false"
      (filterChanged)="onFilterChanged($event)">
    </mk-mat-table>
  </div>
</div>
