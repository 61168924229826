import { McOutFactoringInkassoExportListPageComponent } from './pages/mc-out-factoring-inkasso-export-list-page/mc-out-factoring-inkasso-export-list-page.component';
import { McOutFactoringTransactionListPageComponent } from './pages/mc-out-factoring-transaction-list-page/mc-out-factoring-transaction-list-page.component';
import { McOutFactoringSepaOutListPageComponent } from './pages/mc-out-factoring-sepa-out-list-page/mc-out-factoring-sepa-out-list-page.component';
import { McOutFactoringSepaInListPageComponent } from './pages/mc-out-factoring-sepa-in-list-page/mc-out-factoring-sepa-in-list-page.component';
import { McOutFactoringContractListPageComponent } from './pages/mc-out-factoring-contract-list-page/mc-out-factoring-contract-list-page.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {McOutFactoringBlacklistListPageComponent} from "../mc-out-factoring/pages/mc-out-factoring-blacklist-list-page/mc-out-factoring-blacklist-list-page.component";

const routes: Routes = [
{
    path: 'inkasso-export',
    component: McOutFactoringInkassoExportListPageComponent
  },
{
    path: 'blacklist',
    component: McOutFactoringBlacklistListPageComponent
  },
{
    path: 'transactions',
    component: McOutFactoringTransactionListPageComponent
  },
{
    path: 'sepa-out',
    component: McOutFactoringSepaOutListPageComponent
  },
{
    path: 'sepa-in',
    component: McOutFactoringSepaInListPageComponent
  },
{
    path: 'contracts',
    component: McOutFactoringContractListPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McOutFactoringRoutingModule {
}
