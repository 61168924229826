/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {ImDataSetAttributeFilter} from '../models/im-data-set-attribute-filter.model';

export class ImDataSetAttributeFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): ImDataSetAttributeFilter {
    console.error('MTCN-ERROR: Not Implemented: ImDataSetAttributeFilter::createNewInstance(). Add this method to final class and return new ImDataSetAttributeFilter();');
      throw new Error('Cannot ImDataSetAttributeFilter::createNewInstance(). Add this method to final class and return new ImDataSetAttributeFilter();');
  }

}
