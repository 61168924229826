import { BaseObject } from './base-object';
import { BaseObjectService } from './base-object-service';
import { Pagination } from './pagination.model';
import { SortCriteriaList } from './sort-criteria-list.model';
import { McApiErrorResponse } from './mc-api-error-response.model';
import { McValueLabelList } from './mc-value-label-list';
import { Base } from '../_core/base.model';


export abstract class BaseObjectList extends Base {
  items: BaseObject[] = [];
  apiService: any;
  apiLoadingFlg = false;
  apiSuccessFlg = true;
  apiErrorFlg = false;
  apiErrorMessage!: string;
  apiErrorCd = '';
  pageItemsPerPageCount = 20;
  pageNum = 0;
  totalItemsCount = 0;


  protected tempObjList!: BaseObjectList;
  private _selectedObjectList!: BaseObjectList;
  private _thisIsSelectedObjectListFlg = false;
  private _trackSelectionAcrossPagesFlg = false;
  protected selectedRangeStartItem!: BaseObject;
  protected selectedRangeStartIndex!: number;

  public singleSelectionFlg = false;
  public tag = '';
  public pagination = new Pagination();
  public sortCriteria = new SortCriteriaList();

  // ---------------------------------------------------------------------
  public static createNewInstance(): BaseObjectList {
    throw new Error(
      'Cannot execute BaseObjectList::createNewInstance(). Add this method to final class and return new CLASSNME(); '
    );
  }

  // ---------------------------------------------------------------------
  constructor(aApiService?: BaseObjectService | undefined) {
    super();
    this.apiService = aApiService;
  }

  // ---------------------------------------------------------------------
  public apiReset() {
    this.apiSuccessFlg = true;
    this.apiErrorFlg = false;
    this.apiLoadingFlg = false;
    this.apiErrorMessage = '';
    this.apiErrorCd = '';
  }

  // ---------------------------------------------------------------------
  public setApiLoading() {
    this.apiSuccessFlg = false;
    this.apiErrorFlg = false;
    this.apiLoadingFlg = true;
    this.apiErrorMessage = '';
    this.apiErrorCd = '';
  }

  // ---------------------------------------------------------------------
  public setApiSuccess() {
    this.apiSuccessFlg = true;
    this.apiErrorFlg = false;
    this.apiLoadingFlg = false;
    this.apiErrorMessage = '';
    this.apiErrorCd = '';
  }

  // ---------------------------------------------------------------------
  public setApiErrorData(aErrorResponse: string) {
    const mcApiErrorResponse = McApiErrorResponse.createNew(aErrorResponse);
    this.apiErrorMessage = mcApiErrorResponse.message;
    this.apiErrorCd = mcApiErrorResponse.code;
  }

  // ---------------------------------------------------------------------
  public setApiError() {
    this.apiSuccessFlg = false;
    this.apiErrorFlg = true;
    this.apiLoadingFlg = false;
  }

  // ---------------------------------------------------------------------
  public getCount(): number {
    return this.items.length;
  }

  // ---------------------------------------------------------------------
  protected processApiSuccess(
    aResponseObj: any,
    onSuccessCallback?: () => void
  ) {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
    this.setApiSuccess();
  }

  protected processApiError(aErrorResponse: any, onErrorCallback?: () => void) {
    this.setApiErrorData(aErrorResponse);
    if (onErrorCallback) {
      onErrorCallback();
    }
  }

  // ---------------------------------------------------------------------
  public getSelectedCount(): number {
    let count = 0;
    if (!this.isThisSelectedObjectList()) {
      count = this.getSelectedObjectList().getCount();
    } else {
      console.error(
        'BaseObjectList ERROR-A1: getSelected Cannot be called on SelectedList!'
      );
    }
    return count;
  }

  // ---------------------------------------------------------------------
  public getSelectedIds() {
    let ids: number[] = [];

    if (!this.isThisSelectedObjectList()) {
      ids = this.getSelectedObjectList().getIds();
    } else {
      console.error(
        'BaseObjectList ERROR-A2: getSelectedIds Cannot be called on SelectedList!'
      );
    }
    return ids;
  }

  // ---------------------------------------------------------------------
  public getItemById(aId: number, aDefaultValue = null) {
    let result: BaseObject | null = aDefaultValue;
    this.items.forEach((item, index) => {
      if (item.id == aId) {
        result = item;
      }
    });
    return result;
  }

  // ---------------------------------------------------------------------
  public getIds() {
    const ids: number[] = [];
    this.items.forEach((item, index) => {
      ids.push(item.id);
    });
    return ids;
  }

  // ---------------------------------------------------------------------
  public addItem(aItem: BaseObject): void {
    if (this._selectedObjectList) {
      this._addSelectionEventHandlerForItem(aItem);
    }
    const itemList = this.items ? this.items : [];
    itemList.push(aItem);
    this.items = itemList;
  }

  // ---------------------------------------------------------------------
  public removeItemById(aItemId: number): void {
    this.items.forEach((item, index) => {
      if (item.id === aItemId) {
        this.items.splice(index, 1);
      }
    });
  }

  // ---------------------------------------------------------------------
  public selectItemById(aItemId: any): void {
    this.items.forEach((item, index) => {
      if (item.id === aItemId) {
        item.select();
      }
    });
  }

  // ---------------------------------------------------------------------
  public loadSelected_TODO() {
    let arr = [];
    arr = this.items.filter((item) => item.selectedFlg);
  }

  public abstract populateFromJson(
    aJsonObj: any
  ): any;

  public abstract populateFromJsonPageable(aJsonObj: any): any;

  // ---------------------------------------------------------------------
  public loadAll(
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
  ): void {
    this.setApiLoading();
    this.apiService
      .getAll(this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
      .subscribe(
        (responseJson: any) => {
          this.totalItemsCount = responseJson.totalElements;
          this.populateFromJsonPageable(responseJson);
          this.processPostLoad();
          this.processApiSuccess(responseJson, onSuccessCallback);
        },
        (errorResponse: any) => {
          this.processApiError(errorResponse, onErrorCallback);
        }
      );
  }

  // ---------------------------------------------------------------------
  public loadByFilter(
    aFilterObj: any,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
  ): void {
    this.setApiLoading();
    this.apiService
      .getByFilter(
        aFilterObj,
        this.pageNum,
        this.pageItemsPerPageCount,
        this.sortCriteria
      )
      .subscribe(
        (responseJson: any) => {
          this.totalItemsCount = responseJson.totalElements;
          this.setApiSuccess();
          this.populateFromJsonPageable(responseJson);
          this.processPostLoad();
          this.processApiSuccess(responseJson, onSuccessCallback);
        },
        (errorResponse: any) => {
          this.setApiError();
          this.processApiError(errorResponse, onErrorCallback);
        }
      );
  }
  // ---------------------------------------------------------------------
  public deleteAllFromDb(
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
  ): void {
    this.setApiLoading();
    this.apiService.deleteAllFromDb().subscribe(
      (response: any) => {
        this.processApiSuccess(response, onSuccessCallback);
      },
      (errorResponse: any) => {
        this.processApiError(errorResponse, onErrorCallback);
      }
    );
  }

  // ---------------------------------------------------------------------
  protected processPostLoad() {
    if (!this.isSelectionTrackingActrossPagesEnabled()) {
      //  this._selectedObjectList = null;
    }
    this._setSelectedItems();
    this._addSelectionEventHandlerForItems();
  }

  // ---------------------------------------------------------------------
  protected _addSelectionEventHandlerForItem(item: BaseObject) {
    item.setOnSelectionChangedEventHandler((aItem: BaseObject) => {
      if (this._selectedObjectList) {
        if (aItem.selectedFlg) {
          if (this.singleSelectionFlg) {
            this.deselectAll();
            aItem.selectedFlg = true;
          }
          this._selectedObjectList.addItem(aItem);
        } else {
          this._selectedObjectList.removeItemById(aItem.id);
        }
      }
    });
    //      item.setOnSelectionChangedEventHandler( this._eventHandler_item_onSelectionChanged);
  }

  // ---------------------------------------------------------------------
  protected _setSelectedItems() {
    if (this._selectedObjectList) {
      this._selectedObjectList.items.forEach((item, index) => {
        this.selectItemById(item.id);
      });
    }
  }

  // ---------------------------------------------------------------------
  protected _addSelectionEventHandlerForItems() {
    this.items.forEach((item, index) => {
      this._addSelectionEventHandlerForItem(item);
      //      item.setOnSelectionChangedEventHandler( this._eventHandler_item_onSelectionChanged);
      //       console.log('-- MAPPING EVENT HANDLER --');
    });
  }

  // ---------------------------------------------------------------------
  public getFirstHalfOfItems(): any[] {
    const list = new Array();
    const halfIndex = Math.ceil(this.getCount() / 2) - 1;
    this.items.forEach((item, index) => {
      if (index <= halfIndex) {
        list.push(item);
      }
    });
    return list;
  }

  // ---------------------------------------------------------------------
  public getLastHalfOfItems(): any[] {
    const list = new Array();
    const halfIndex = Math.ceil(this.getCount() / 2) - 1;
    this.items.forEach((item, index) => {
      if (index > halfIndex) {
        list.push(item);
      }
    });
    return list;
  }

  public setSingleSelectionFlg(aValue: boolean) {
    this.singleSelectionFlg = aValue;
  }
  // ---------------------------------------------------------------------
  // ---------------------------------------------------------------------
  public getSelectedObjectList(): any {
    if (!this.isThisSelectedObjectList()) {
      if (!this._selectedObjectList) {
        const tempSelectedObjList: BaseObjectList = Object.create(
          this.constructor.prototype
        );
        tempSelectedObjList.constructor.apply(tempSelectedObjList);
        tempSelectedObjList.setThisIsSelectedObjectList(true);
        this._selectedObjectList = tempSelectedObjList;
        this.items.forEach((item, index) => {
          if (item.isSelected()) {
            this._selectedObjectList.addItem(item);
          }
        });
      }
    } else {
      console.error(
        'getSelectedObjectList(): BaseObjectList : Invalid request E1231'
      );
      // return throwError('invalid ID');
    }
    return this._selectedObjectList;
  }

  // ---------------------------------------------------------------------
  public setPager(aPageNum: number, aItemsPerPageCount: number) {
    this.pageItemsPerPageCount = aItemsPerPageCount;
    this.pageNum = aPageNum;
  }

  // ---------------------------------------------------------------------
  public isThisSelectedObjectList() {
    return this._thisIsSelectedObjectListFlg;
  }

  // ---------------------------------------------------------------------
  public setThisIsSelectedObjectList(aValue: boolean) {
    this._thisIsSelectedObjectListFlg = aValue;
  }

  // ---------------------------------------------------------------------
  public enableSelectionTrackingActrossPages() {
    this._trackSelectionAcrossPagesFlg = true;
  }

  // ---------------------------------------------------------------------
  public disableSelectionTrackingActrossPages() {
    this._trackSelectionAcrossPagesFlg = false;
  }

  // ---------------------------------------------------------------------
  public isSelectionTrackingActrossPagesEnabled(): boolean {
    return this._trackSelectionAcrossPagesFlg;
  }

  // ---------------------------------------------------------------------
  public deselectAll(): void {
    const selectedCount = this.getCount() - 1;
    for (let idx = selectedCount; idx >= 0; idx--) {
      this.items[idx].deselect();
    }
  }

  // ---------------------------------------------------------------------
  public selectAll() {
    const numberOfItems = this.items.length;
    for (let idx = 0; idx < numberOfItems; idx++) {
      if (!this.items[idx].selectedFlg) {
        this.items[idx].select();
      }
    }
  }

  // ---------------------------------------------------------------------
  getItemIndexInList(baseObject: BaseObject): number {
    let result = -1;
    this.items.forEach((item, index) => {
      if (baseObject.id === item.id) {
        result = index;
      }
    });
    return result;
    // TODO: suggestion shorter code
    // return this.items.findIndex(item => baseObject.id === item.id);
  }

  // ---------------------------------------------------------------------
  startSelectionRange(baseObject: BaseObject) {
    const lastCheckedIndex = this.getItemIndexInList(baseObject);
    this.selectedRangeStartItem = baseObject;
    this.selectedRangeStartIndex = lastCheckedIndex;
  }

  // ----------------------------------------------------------------------------------
  endOrStartSelectionRangeUsingShiftSelection(baseObject: BaseObject) {
    const lastCheckedIndex = this.getItemIndexInList(baseObject);
    if (!this.selectedRangeStartItem) {
      this.selectedRangeStartItem = baseObject;
      this.selectedRangeStartIndex = lastCheckedIndex;
      return;
    }
    this.items.forEach((baseObjectElement, index) => {
      if (this.selectedRangeStartIndex < lastCheckedIndex) {
        if (index >= this.selectedRangeStartIndex && index < lastCheckedIndex) {
          if (
            this.selectedRangeStartItem.selectedFlg !==
              baseObjectElement.selectedFlg ||
            this.selectedRangeStartItem.selectedFlg !== baseObject.selectedFlg
          ) {
            baseObjectElement.toggleSelected();
          }
        }
      } else if (this.selectedRangeStartIndex > lastCheckedIndex) {
        if (index <= this.selectedRangeStartIndex && index > lastCheckedIndex) {
          if (
            this.selectedRangeStartItem.selectedFlg !==
              baseObjectElement.selectedFlg ||
            this.selectedRangeStartItem.selectedFlg !== baseObject.selectedFlg
          ) {
            baseObjectElement.toggleSelected();
          }
        }
      }
    });
  }

  public toggleSelectAll() {
    if (this.isAllSelected()) {
      this.deselectAll();
    } else {
      this.selectAll();
    }
  }

  public isAllSelected(): boolean {
    let result = true;
    if (this.getCount() > 0) {
      for (let idx = 0; idx < this.getCount(); idx++) {
        result = result && this.items[idx].isSelected();
      }
    } else {
      result = false; // when no items return false
    }

    return result;
  }

  // ---------------------------------------------------------------------
  public toKvm(
    aKeyPropertyOrMethodName: string,
    aValuePropertyOrMethodName: string
  ) {
    const result = new Map<string, any>();

    if (this.getCount() > 0) {
      for (let idx = 0; idx < this.getCount(); idx++) {
        const key = this.items[idx].execPropertyOrMethodByName(
          aKeyPropertyOrMethodName
        );
        const val = this.items[idx].execPropertyOrMethodByName(
          aValuePropertyOrMethodName
        );
        result.set(key, val);
      }
    }
    return result;
  }

  // ---------------------------------------------------------------------
  public toVll(
    aValuePropertyOrMethodName: string,
    aLabelPropertyOrMethodName: string,
    aBlankValueFillerLabel = ''
  ): McValueLabelList {
    const result = new McValueLabelList();
    if (aBlankValueFillerLabel && aBlankValueFillerLabel.length > 0) {
      result.add('', aBlankValueFillerLabel);
    }

    if (this.getCount() > 0) {
      for (let idx = 0; idx < this.getCount(); idx++) {
        result.addItem(
          this.items[idx].toVllItem(
            aValuePropertyOrMethodName,
            aLabelPropertyOrMethodName
          )
        );
      }
    }
    return result;
  }

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto: any[] = [];
    this.items.forEach((item) => {
      dto.push(item.toDto());
    });
    return dto;
  }

  // ---------------------------------------------------------------------
  public setSortB(
    sortPropertyName: string,
    sortDirection: string
  ): any {
    if (this.sortCriteria) {
      this.sortCriteria.clear();
      return this.addSortB(sortPropertyName, sortDirection);
    }
  }

  // ---------------------------------------------------------------------
  public clearSort(): void {
    if (this.sortCriteria) {
      this.sortCriteria.clear();
    }
  }

  // ---------------------------------------------------------------------
  public addSortB(
    sortPropertyName: string,
    sortDirection: string
  ) {
    this.sortCriteria.addSort(sortPropertyName, sortDirection);
    return this;
  }
}
