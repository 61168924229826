/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {CcMollieTransaction} from '../models/cc-mollie-transaction.model';
import {CcMollieTransactionService} from '../services/cc-mollie-transaction.service';
import {ICcMollieTransaction} from '../_generated/cc-mollie-transaction.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {PmTransaction} from '../models/pm-transaction.model';


export abstract class CcMollieTransactionGENERATED extends BaseObject {


  public apiService: CcMollieTransactionService;
  public _rawJson: ICcMollieTransaction;
    id: number = 0;
  pmTransaction: PmTransaction;

  public properties: string[] = ['id', 'pmTransaction'];
  public propertiesRegular: string[] = ['id'];
  public propertiesSpecial: string[] = ['pmTransaction'];


  // ---------------------------------------------------------------------
public static createNewInstance(): CcMollieTransaction {
    console.error('MTCN-ERROR: Not Implemented: CcMollieTransaction::createNewInstance(). Add this method to final class and return new CcMollieTransaction();');
    throw new Error('Cannot CcMollieTransaction::createNewInstance(). Add this method to final class and return new CcMollieTransaction();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: ICcMollieTransaction): CcMollieTransaction {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CcMollieTransactionService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CcMollieTransaction AABB()');
          if (this._rawJson['pmTransaction']) {
        this.pmTransaction = PmTransaction.createFromJson(this._rawJson['pmTransaction']);
      }

  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('pmTransaction')) {
        if (this.pmTransaction != null) {
            dto['pmTransaction'] = this.pmTransaction.toDto();
        } else {
            dto['pmTransaction'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
