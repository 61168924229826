import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IImDataMapping} from '../_generated/im-data-mapping.interface';
import {ImDataMapping} from '../models/im-data-mapping.model';
import {ImDataMappingList} from '../models/im-data-mapping-list.model';
import {ImDataMappingServiceGENERATED} from '../_generated/im-data-mapping-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ImDataMappingService extends ImDataMappingServiceGENERATED {

  getMappingsByDataSetId(id: number): Observable<any>  {
    return this.httpClient.get(`${this.getApiServiceRootUrl()}/dataset/${id}`);
  }
}
