import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {McAuthService} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-auth-locked',
  templateUrl: './auth-locked.component.html',
})
export class AuthLockedComponent implements OnInit {
  welcomeImg: string;
  logo: string;
  loginResponse!: any;

  // Constructor
  constructor(private router: Router,
              private route: ActivatedRoute,
              private authService: McAuthService,
              @Inject('designConfig') design: any) {

    // Setup from env
    this.welcomeImg = design.welcomeImg;
    this.logo = design.logo;

    // Get state from login component
    // if state no exists redirect user to login page
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation()?.extras.state) {
        this.loginResponse = this.router.getCurrentNavigation()?.extras.state;
      } else {
        this.authService.logout();
      }
    });
  }

  // On init class
  ngOnInit() {
  }

}
