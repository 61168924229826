import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IPpPriceAdjustment} from '../_generated/pp-price-adjustment.interface';
import {PpPriceAdjustment} from '../models/pp-price-adjustment.model';
import {PpPriceAdjustmentList} from '../models/pp-price-adjustment-list.model';
import {PpPriceAdjustmentServiceGENERATED} from '../_generated/pp-price-adjustment-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PpPriceAdjustmentService extends PpPriceAdjustmentServiceGENERATED {

}
