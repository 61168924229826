import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ImDataSet, ImDataSetAttribute, ImDataSetAttributeService, ImDataSetService, SortCriteriaList} from "@miticon-ui/mc-core";
import {TranslateService} from "@ngx-translate/core";
import {ICON_UPLOAD} from "../../../../../assets/media/svg_icons/icon-upload";
import {ICON_ATTRIBUTE} from "../../../../../assets/media/svg_icons/icon-attribute";
import {ICON_FILE_DOWNLOAD} from "../../../../../assets/media/svg_icons/icon-file-download";

@Component({
  selector: 'lib-data-import-add-upload-step',
  templateUrl: './data-import-add-upload-step.component.html',
  styleUrls: ['./data-import-add-upload-step.component.scss']
})
export class DataImportAddUploadStepComponent implements OnInit {
  @Input() uploadForm!: FormGroup;
  @Output() emitUploadForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() uploadFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emitValidDelimiter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emitFileContent: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() emitFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitDataset: EventEmitter<ImDataSet> = new EventEmitter<ImDataSet>();

  iconUpload = ICON_UPLOAD;
  iconDownload = ICON_FILE_DOWNLOAD;
  iconAttribute = ICON_ATTRIBUTE;

  requiredAttributes!: ImDataSetAttribute[];
  reqEmgAttributes = ImDataSetAttribute.reqEmgAttributes;

  dataSets: ImDataSet[] = [];
  dataSet!: ImDataSet;

  file: any;
  fileName = '';
  fileContent!: string[];
  validDelimiter!: boolean;

  csvHeaders!: string[];
  csvData!: string[][];

  encodings = [{
    label: "UTF 8",
    value: "UTF 8"
  }];

  delimiters = [
    {
      label: this.tS.instant("mem.import.delimiter.semicolon"),
      value: ";"
    },
    {
      label: this.tS.instant("mem.import.delimiter.comma"),
      value: ","
    },
    {
      label: this.tS.instant("mem.import.delimiter.space"),
      value: " "
    },
    {
      label: this.tS.instant("mem.import.delimiter.other"),
      value: "Other"
    }];

  constructor(private fb: FormBuilder,
              private tS: TranslateService,
              private dataSetService: ImDataSetService,
              private dataSetAttributeService: ImDataSetAttributeService) {}

  ngOnInit(): void {
    this.createForm();
    this.loadDataSets();
    this.uploadForm.valueChanges.subscribe(() => {
      this.emitUploadForm.emit(this.uploadForm);
      this.uploadFormValid.emit(this.isUploadFormValid());
    })
  }

  loadDataSets() {
    this.dataSetService.getAll(0,0,new SortCriteriaList()).subscribe((data) => {
      this.dataSets = data;
    })
  }

  createForm(): void {
    this.uploadForm = this.fb.group({
      dataSet: ['', Validators.required],
      file: ['', Validators.required],
      source: [{ value: null, disabled: true },''],
      customName: ['', Validators.required],
      delimiter: [''],
      otherDelimiter: [''],
      encoding: [''],
      header: []
    });
  }

  inputChange(fileInputEvent: any): void {
    this.file = fileInputEvent.target.files[0];
    this.emitFile.emit(this.file);
    this.setFilePreview();
    this.fileName = fileInputEvent.target.files[0].name;
    this.uploadForm.controls['source'].setValue(fileInputEvent.target.files[0].type.split('/').pop());
    this.uploadForm.controls['otherDelimiter'].valueChanges.subscribe((value) => {
      if(value) {
        this.setDelimiter(value, false);
      }
    })
  }

  setDelimiter($event: any, predefinedDelimiter: boolean) {
    if(predefinedDelimiter) {
      if($event != 'Other') {
        this.readCsvFile($event);
      }
    } else {
      this.readCsvFile($event);
    }
  }

  setFilePreview(): void {
    if (this.file) {
      switch (this.file.type) {
        case 'text/csv':
          if(this.uploadForm.controls['delimiter'].value) {
            this.readCsvFile(this.uploadForm.controls['delimiter'].value);
          }
          break;
        case 'text/xml':
          this.convertXmlToCsv();
          break;
        case 'application/json':
          this.convertJsonToCsv();
      }
    }
  }

  readCsvFile(delimiter: string): void {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const contents = e.target.result;
      this.fileContent = this.getPreviewContents(contents).map(item => item.replace(/"/g, ''));

      if (this.fileContent.length !== 0) {
        if (!this.fileContent[0].includes(delimiter)) {
          this.validDelimiter = false;
          this.emitValidDelimiter.emit(this.validDelimiter);
        } else {
          this.validDelimiter = true;
          this.emitValidDelimiter.emit(this.validDelimiter);
        }
        this.csvHeaders = this.fileContent[0].split(delimiter);
        this.csvData = this.fileContent.slice(1).map(line => line.split(delimiter));
      } else {
        this.fileContent = [];
      }

      this.emitFileContent.emit(this.fileContent);
    };
    reader.readAsText(this.file);
  }


  convertJsonToCsv(): void {
    const fileReader = new FileReader();
    fileReader.onload = (event: any) => {
      const jsonData = JSON.parse(event.target.result);
      const csvRows: string[] = [];
      const headerRow: string[] = Object.keys(jsonData[0]);
      csvRows.push(headerRow.join(','));

      for (const jsonObject of jsonData) {
        const csvRow: string[] = headerRow.map(key => {
          const value = jsonObject[key] !== undefined ? jsonObject[key] : '';
          return `"${value}"`;
        });
        csvRows.push(csvRow.join(','));
      }

      this.fileContent = csvRows.slice(0, 5).map(item => item.replace(/"/g, ''));
      if(this.fileContent.length != 0) {
        this.csvHeaders = this.fileContent[0].split(',');
        this.csvData = this.fileContent.slice(1).map(line => line.split(','));
        this.emitFileContent.emit(this.fileContent);
      } else this.fileContent = [];
      this.emitFileContent.emit(this.fileContent);
    };

    fileReader.readAsText(this.file);
  }

  convertXmlToCsv(): void {
    const fileReader = new FileReader();

    fileReader.onload = (event: any) => {
      const parser = new DOMParser();
      const xmlData = event.target.result;

      if (xmlData) {
        const xmlDoc = parser.parseFromString(xmlData, 'text/xml');
        const csvRows: string[] = [];
        const xmlRootElement = xmlDoc.documentElement;
        const headerRow: string[] = [];
        const firstXmlElement: any = xmlRootElement.firstElementChild;
        if (firstXmlElement) {
          for (const header of firstXmlElement.children) {
            headerRow.push(header.tagName);
          }
          csvRows.push(headerRow.join(','));
        }

        const xmlElements: any = xmlRootElement.querySelectorAll('*');
        for (const xmlElement of xmlElements) {
          const csvRow: string[] = [];
          for (const child of xmlElement.children) {
            csvRow.push(child.textContent || '');
          }
          csvRows.push(csvRow.join(','));
        }

        this.fileContent = csvRows.filter(row => row != "").map(item => item.replace(/"/g, ''));
        if(this.fileContent.length != 0) {
          this.csvHeaders = this.fileContent[0].split(',');
          this.csvData = this.fileContent.slice(1).slice(0, 5).map(line => line.split(','));
        } else this.fileContent = [];
        this.emitFileContent.emit(this.fileContent);
      }
    };

    fileReader.readAsText(this.file);
  }


  getPreviewContents(contents: string): string[] {
    const lines = contents.split('\n').slice(0, 5); // Get first 5 lines
    return lines;
  }

  setDataSet(dataSet: ImDataSet): void {
    this.dataSet = dataSet;
    this.dataSetAttributeService.getAttributesByDataSetId(dataSet.id)
      .subscribe((res) => {
        this.requiredAttributes = res.filter((attribute: ImDataSetAttribute) => attribute.requiredFlg);
      })
    this.emitDataset.emit(this.dataSet);
  }

  isUploadFormValid(): boolean {
    return this.uploadForm.valid &&
      (this.file?.type === 'text/csv' ? (
        this.uploadForm.controls['encoding'].value &&
        this.isDelimiterSelected()
      ) : true)
  }

  isDelimiterSelected() {
    return this.uploadForm.controls['delimiter'].value && (
      this.uploadForm.controls['delimiter'].value === 'Other' ?
        this.uploadForm.controls['otherDelimiter'].value
        : true);
  }

  checkDelimiter(): boolean {
    return this.uploadForm.controls['delimiter'].value === 'Other';
  }
}
