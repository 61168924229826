import {McMappingItem} from './mc-mapping-item.model';
import {McMappingItemListGENERATED} from '../_generated/mc-mapping-item-list-generated.model';

export class McMappingItemList extends McMappingItemListGENERATED {

  public static override createNewInstance(): McMappingItemList {
    return new McMappingItemList();
  }

  // ---------------------------------------------------------------------
  public loadByWebFileId(aWebFileId: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    // TODO Commented out for CG


  }

  updateItem(objectList: any[], objectSearchPropertyName: any, objectApplyPropertyName: string) {
    this.items.map(item => {
      // @ts-ignore
      const foundObjectListItem = objectList.find(objectListItem => objectListItem[objectSearchPropertyName] === item[objectSearchPropertyName]);
      // @ts-ignore
      foundObjectListItem && (item[objectApplyPropertyName] = foundObjectListItem[objectApplyPropertyName]);
    });
  }

}
