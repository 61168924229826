<ng-template #bankAccount let-modal>
  <!--Modal header section-->
  <div class="modal-header ">
    <div class="mc-bank-account-modal-header">
      <h4 class="modal-title" id="bank-account-title">{{bankAccountId && bankAccountId !== 0 ? ("cc.common.edit-bank-account" | translate) : ("cc.common.create-new-bank-account" | translate)}}</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true" class="mc-close-btn">&times;</span>
    </button>
  </div>

  <!--Modal body section-->
  <div class="modal-body">
    <div class="container-fluid">
      <form [formGroup]="bankAccountForm" class="bank-account-form">
        <div class="mc-notify-wrapper mt-n2">
          <mc-notify-message [message]="message" [type]="type"></mc-notify-message>
        </div>

        <!--Account owner-->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="accountOwner" class="label-position">{{"cc.common.account-owner" | translate}} <i class="required-input">*</i></label>
              <input type="text"
                     name="accountOwner"
                     class="form-control"
                     id="accountOwner"
                     formControlName="accountOwner"
                     required>
            </div>
          </div>
        </div>

        <!--Bank name-->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="bankName" class="label-position">{{"cc.common.bank-name" | translate}} <i class="required-input">*</i></label>
              <input type="text"
                     name="bankName"
                     class="form-control"
                     id="bankName"
                     formControlName="bankName"
                     required>
            </div>
          </div>
        </div>

        <!--Bank city-->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="bankCity" class="label-position">{{"cc.common.bank-city" | translate}} <i class="required-input">*</i></label>
              <input type="text"
                     name="bankCity"
                     class="form-control"
                     id="bankCity"
                     formControlName="bankCity"
                     required>
            </div>
          </div>
        </div>

        <!--Bank country-->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="bankCountry" class="label-position">{{"cc.common.bank-country" | translate}} <i class="required-input">*</i></label>
              <input type="text"
                     name="bankCountry"
                     class="form-control"
                     id="bankCountry"
                     formControlName="bankCountry"
                     required>
            </div>
          </div>
        </div>

        <!--Account number-->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="accountNumber" class="label-position">{{"cc.common.account-number" | translate}} <i class="required-input">*</i></label>
              <input type="text"
                     name="accountNumber"
                     class="form-control"
                     id="accountNumber"
                     formControlName="accountNumber"
                     required>
            </div>
          </div>
        </div>

        <!--Blz-->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="blz" class="label-position">{{"cc.common.blz" | translate}} <i class="required-input">*</i></label>
              <input type="text"
                     name="blz"
                     class="form-control"
                     id="blz"
                     formControlName="blz"
                     required>
            </div>
          </div>
        </div>

        <!--Swift-->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="swift" class="label-position">{{"cc.common.swift" | translate}} <i class="required-input">*</i></label>
              <input type="text"
                     name="swift"
                     class="form-control"
                     id="swift"
                     formControlName="swift"
                     required>
            </div>
          </div>
        </div>

        <!--Iban-->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="iban" class="label-position">{{"cc.common.iban" | translate}} <i class="required-input">*</i></label>
              <input type="text"
                     name="iban"
                     class="form-control"
                     id="iban"
                     formControlName="iban"
                     required>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 text-right mc-user-summary-createAdd-btn">
            <!--Close btn-->
            <button type="button" class="mc-cancel-btn float-left" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">{{"cc.common.close" | translate}}</span>
            </button>

            <!--Save btn-->
            <button type="button"
                    class="btn btn-primary mc-next-save-btn" (click)="addEditBankAccount();">
              {{'cc.common.view.save' | translate}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!--Default btn-->
<button *ngIf="bankAccountId !== 0" type="button" class="system-action-btn" [ngClass]="bankAccountDefault ? 'btn-primary text-white' : 'mc-blue-btn'" (click)="setDefaultBankAccount()">
  {{bankAccountDefault ? ('cc.common.default' | translate) : ('cc.common.set-default' | translate)}}
</button>

<button *ngIf="bankAccountId === 0" class="system-action-btn" (click)="openModal(bankAccount)">
  <i class="fas fa-plus-circle"></i>
  {{'cc.common.add-bank-account' | translate}}
</button>
<i *ngIf="bankAccountId !== 0" class="fa fa-pen mc-pen-icon mc-blue-text mx-3" (click)="openModal(bankAccount)"></i>
<a *ngIf="!bankAccountDefault && bankAccountId !== 0" (click)="deleteBankAccount()">
  <img [src]="trashIcon" alt="Trash icon">
</a>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
