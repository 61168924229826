import {McBillingServiceCatalogFilterGENERATED} from '../_generated/mc-billing-service-catalog-filter-generated.model';

export class McBillingServiceCatalogFilter extends McBillingServiceCatalogFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingServiceCatalogFilter();
  }

  // ---------------------------------------------------------------------
}
