/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CsrAccountConsumerFilter} from '../models/csr-account-consumer-filter.model';

export class CsrAccountConsumerFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrAccountConsumerFilter {
    console.error('MTCN-ERROR: Not Implemented: CsrAccountConsumerFilter::createNewInstance(). Add this method to final class and return new CsrAccountConsumerFilter();');
      throw new Error('Cannot CsrAccountConsumerFilter::createNewInstance(). Add this method to final class and return new CsrAccountConsumerFilter();');
  }

}
