/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McBillingStatementFilter} from '../models/mc-billing-statement-filter.model';

export class McBillingStatementFilterGENERATED extends BaseObjectFilter{    public flgOutBilling: boolean | undefined;
    public searchTerm: string | undefined;
    public statusCds: string[] = [];
    public idsOfChildrenEntities: number[] = [];
    public flgHidden: boolean | undefined;
    public statementDate: any | undefined;
    public flgExported: boolean | undefined;

  public properties: string[] = ['flgOutBilling', 'searchTerm', 'statusCds', 'idsOfChildrenEntities', 'flgHidden', 'statementDate', 'flgExported', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingStatementFilter {
    console.error('MTCN-ERROR: Not Implemented: McBillingStatementFilter::createNewInstance(). Add this method to final class and return new McBillingStatementFilter();');
      throw new Error('Cannot McBillingStatementFilter::createNewInstance(). Add this method to final class and return new McBillingStatementFilter();');
  }

}
