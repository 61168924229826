<div class="mc-menu-2-wrapper d-flex" [ngClass]="horizontalDirectionFlg ? 'flex-column' : 'flex-row'">

  <ul class="mc-menu-2-items d-flex" [ngClass]="horizontalDirectionFlg ? 'flex-row' : 'flex-column'">
    <li class="mc-menu-2-item mc-menu-2-item-background" *ngFor="let menuItem of mcMenu.menuItemList">
      <a class="mc-menu-2-item-link" [ngClass]="{'mc-menu-2-item-link-active' : menuItem.isActive}" (click)="menuItemClicked(menuItem)">{{menuItem.translationKey}}</a>
    </li>
  </ul>


</div>
