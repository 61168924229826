import {Inject, Injectable} from '@angular/core';
import {EbFactoringRequestTypeServiceGENERATED} from '../_generated/eb-factoring-request-type-generated.service';



@Injectable({
  providedIn: 'root'
})
export class EbFactoringRequestTypeService extends EbFactoringRequestTypeServiceGENERATED {


}
