import { McMyFactoringImportSepaFileComponent } from './components/mc-my-factoring-import-sepa-file/mc-my-factoring-import-sepa-file.component';
import { McMyFactoringReportingComponent } from './components/mc-my-factoring-reporting/mc-my-factoring-reporting.component';
import { McMyFactoringTransactionListPageComponent } from './pages/mc-my-factoring-transaction-list-page/mc-my-factoring-transaction-list-page.component';
import { McMyFactoringSepaInListPageComponent } from './pages/mc-my-factoring-sepa-in-list-page/mc-my-factoring-sepa-in-list-page.component';
import { McMyFactoringContractListPageComponent } from './pages/mc-my-factoring-contract-list-page/mc-my-factoring-contract-list-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McMyFactoringRoutingModule } from './mc-my-factoring-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MccFiFileUploadModule, McComponentsModule} from '@miticon-ui/mc-components';
import {McFilterModule, McTableModule} from '@miticon-ui/mc-components';
import {McManagementModule} from '../mc-management/mc-management.module';
import {AppSharedModule} from '../app-shared/app-shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {McConsumerModule} from '../mc-consumer/mc-consumer.module';

@NgModule({
  declarations: [
McMyFactoringImportSepaFileComponent,
McMyFactoringReportingComponent,
McMyFactoringTransactionListPageComponent,
McMyFactoringSepaInListPageComponent,
McMyFactoringContractListPageComponent,
  ],
    imports: [
        CommonModule,
        McMyFactoringRoutingModule,
        NgbModule,
        McComponentsModule,
        McFilterModule,
        McTableModule,
        FormsModule,
        ReactiveFormsModule,
        McManagementModule,
        AppSharedModule,
        MccFiFileUploadModule,
        TranslateModule,
        McConsumerModule,
        McConsumerModule,
    ],
  exports: [
McMyFactoringImportSepaFileComponent,
McMyFactoringReportingComponent,
McMyFactoringTransactionListPageComponent,
McMyFactoringSepaInListPageComponent,
McMyFactoringContractListPageComponent,
    McMyFactoringRoutingModule,
    FormsModule,

  ]
})
export class McMyFactoringModule { }
