import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  CsrMapping,
  McCustomAttribute,
  McEntityService,
  McForm,
  McGod,
  BaseDirective,
} from "@miticon-ui/mc-core";
import { ActivatedRoute } from "@angular/router";
import {
  MccFiSelectOptionConfig,
  MccFiTable2CellEditConfigData,
  MccFiTable2CellEditedData,
} from "@miticon-ui/mc-components";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "mc-consumer-consumer-mapping-edit-part",
  templateUrl: "./mc-consumer-consumer-mapping-edit-part.component.html",
})
export class McConsumerConsumerMappingEditPartComponent
  extends BaseDirective
  implements OnInit
{
  @Input() csrMappingId!: number;
  @Input() csrMapping = new CsrMapping();
  @Output() eventClose = new EventEmitter();
  @Output() eventSaved = new EventEmitter();
  @Input() config: any;
  notifyMessageObject = {
    type: "",
    message: "",
  };
  public entityAttributes = new McCustomAttribute();
  public mcForm = new McForm();

  constructor(
    private translateService: TranslateService,
    private entityService: McEntityService
  ) {
    super();
  }

  ngOnInit() {
    this.actLoad();
  }

  public onMappingItemTableCellConfig(
    event: MccFiTable2CellEditConfigData
  ): void {
    const optionsKvm = new Map<string, string>();
    optionsKvm.set("", McGod.t("cc.common.edit.please-choose"));
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (
      let i = 0;
      i < this.entityAttributes.entityAttributesList.length;
      i++
    ) {
      optionsKvm.set(
        this.entityAttributes.entityAttributesList[i],
        this.entityAttributes.entityAttributesList[i]
      );
    }
    optionsKvm.set("Discard", "DISCARD");
    const config: MccFiSelectOptionConfig = event.editComponentConfigObj;
    config.optionsKvm = optionsKvm;
    config.selectedValue = event.dataItemObj.execPropertyOrMethodByName(
      event.columnConfigObj.editObjectPropertyOrMethodName
    ); // TODO HACK
  }

  onMappingTableCellEdited(event: MccFiTable2CellEditedData) {
    this.checkSelectDuplicates();
  }

  updateMappingItemList() {
    if (!this.checkSelectDuplicates() && this.mcForm.isValid()) {
      /*this.consumersService.updateMappingItemsList(this.mappingItems).subscribe( data => {
        this.config.modal.dismiss();
      });*/
      this.csrMapping.mappingName = this.mcForm.getValue("ctrlMappingName");
      this.csrMapping.save(
        () => {
          console.log("SUCCES?????");
          this.eventSaved.emit();
          this.config.modal.dismiss();
        },
        () => {
          this.notifyMessageObject.message = this.csrMapping.apiErrorMessage;
          this.notifyMessageObject.type = "error";
        }
      );
    }
  }

  checkSelectDuplicates(): boolean {
    for (let i = 0; i < this.csrMapping.mappingItems.items.length - 1; i++) {
      if (this.csrMapping.mappingItems.items[i].attributeName !== "Discard") {
        for (let j = i + 1; j < this.csrMapping.mappingItems.items.length; j++) {
          if (
            this.csrMapping.mappingItems.items[i].attributeName ===
            this.csrMapping.mappingItems.items[j].attributeName
          ) {
            this.translateService
              .get("cc.common.import-consumers-invalid-selection")
              .subscribe(
                (translatedWord) =>
                  (this.notifyMessageObject = {
                    message:
                      translatedWord +
                      `:
               ${this.csrMapping.mappingItems.items[i].columnName}, ${this.csrMapping.mappingItems.items[j].columnName}`,
                    type: "error",
                  })
              );
            return true;
          }
        }
      }
    }
    this.notifyMessageObject = { message: "", type: "" };
    return false;
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    if (this.csrMappingId > 0) {
      this.csrMapping = new CsrMapping();
      this.csrMapping.loadById(this.csrMappingId);
    }
    this.entityAttributes.getCustomAttribute(this.entityService);
  }

  // ---------------------------------------------------------------------
  actClose() {
    this.eventClose.emit();
  }
}
