import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IImDataSetAttribute} from '../_generated/im-data-set-attribute.interface';
import {ImDataSetAttribute} from '../models/im-data-set-attribute.model';
import {ImDataSetAttributeList} from '../models/im-data-set-attribute-list.model';
import {ImDataSetAttributeServiceGENERATED} from '../_generated/im-data-set-attribute-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ImDataSetAttributeService extends ImDataSetAttributeServiceGENERATED {

  public getAttributesByDataSetId(id: number): Observable<any>  {
    return this.httpClient.get(`${this.getApiServiceRootUrl()}/dataset/${id}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        })
    });
  }

  override insert(aImDataSetAttribute: any): Observable<IImDataSetAttribute> {
    return this.httpClient.post<IImDataSetAttribute>(this.getApiServiceRootUrl()  , aImDataSetAttribute, {headers: this.httpOptions});
  }
}
