/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {ImDataMappingItemFilter} from '../models/im-data-mapping-item-filter.model';

export class ImDataMappingItemFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): ImDataMappingItemFilter {
    console.error('MTCN-ERROR: Not Implemented: ImDataMappingItemFilter::createNewInstance(). Add this method to final class and return new ImDataMappingItemFilter();');
      throw new Error('Cannot ImDataMappingItemFilter::createNewInstance(). Add this method to final class and return new ImDataMappingItemFilter();');
  }

}
