import {Component, OnInit} from '@angular/core';
import {
  McProcessRunList,
  McProcessRun,
  McProcessRunTable3Config,
  McForm,
  McBoolean,
  McProcess,
  McGod,
  McMenu,McHtmlButton
} from '@miticon-ui/mc-core';
import {ActivatedRoute, Router} from '@angular/router';
import {ICON_BACK} from "../../../../assets/media/svg_icons/icon-back";

@Component({
  selector: 'mc-process-process-run-list-page',
  templateUrl: './mc-process-process-run-list-page.component.html',
})
export class McProcessProcessRunListPageComponent implements OnInit {

  mcProcessRunList!: McProcessRunList;
  mcProcess = new McProcess();
  mcProcessId!: number;
  mcForm = new McForm();
  mcProcessRunTable3Config = new McProcessRunTable3Config();
  showAddEditMcProcessRunMCb = new McBoolean();
  showRunItemsMcb = new McBoolean();
  mcMenu = new McMenu();
  mcHtmlButton!: McHtmlButton;
  mcProcessRunId!: number;
  successMsg!: string;
  errorMsg!: string;
  mcProcessRun = new McProcessRun();
  disableCreateRunFlg!: boolean;
  mcGod = McGod.getInstance();
  iconBack = ICON_BACK;

  // ---------------------------------------------------------------------
  constructor(private route: ActivatedRoute,
              private router: Router) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.mcProcessRunList = new McProcessRunList();
    this.mcProcessRunList.setPager(0, 100);

    this.route.paramMap.subscribe(params => {
      // @ts-ignore
      this.mcProcessId = +params.get('id');
      this.actLoad();

      this.mcMenu.addMenuItem('configuration', this.mcGod.t('cc.common.configuration'), '', [McGod.PERM_MC_PROCESS_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-profile/${this.mcProcessId}`);
      this.mcMenu.addMenuItem('items', this.mcGod.t('cc.common.items'), '', [McGod.PERM_MC_PROCESS_ITEM_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-items/${this.mcProcessId}`);
      // this.mcMenu.addMenuItem('statistics', 'Statistics', '', [], ``);
      this.mcMenu.addMenuItem('runs', this.mcGod.t('cc.processes.runs'), '', [McGod.PERM_MC_PROCESS_RUN_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-runs/${this.mcProcessId}`, true);

    });

  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcProcessRunList.loadByIdMcProcess(this.mcProcessId, () => {
      const tempArrList = this.mcProcessRunList.getItemsByStatusCd([McProcessRun.STATUS_CD_CLOSED]);
      this.disableCreateRunFlg = tempArrList.length !== this.mcProcessRunList.getCount();
    });
    this.mcProcess.loadById(this.mcProcessId);
  }

  public actClearFilter(): void {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');

    this.actLoad();
  }

  actCreateProcessRun() {
    this.showAddEditMcProcessRunMCb.setTrue();
  }

  actRunSaved() {
    this.showAddEditMcProcessRunMCb.setFalse();
    this.successMsg = this.mcGod.t('cc.processes.process-run-saved-successfully');
    this.mcProcessRun = new McProcessRun();
    this.mcProcessRunId = 0;
    this.actLoad();
  }

  goBack() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/processes`]);
  }

  actProcessRunTableItemAction(mcButton: McHtmlButton) {
    this.mcHtmlButton = mcButton;
    if (mcButton.actionCdForEvent === McProcessRun.ACTION_VIEW_NEW_ITEMS) {
      this.showRunItemsMcb.setTrue();
    } else if (mcButton.actionCdForEvent === McProcessRun.ACTION_VIEW_BUILT) {
      this.showRunItemsMcb.setTrue();
    } else if (mcButton.actionCdForEvent === McProcessRun.ACTION_VIEW_DELIVERED) {
      this.showRunItemsMcb.setTrue();
    } else if (mcButton.actionCdForEvent === McProcessRun.ACTION_VIEW_ERROR) {
      this.showRunItemsMcb.setTrue();
    } else if (mcButton.actionCdForEvent === McProcessRun.ACTION_VIEW_READY) {
      this.showRunItemsMcb.setTrue();
    } else if (mcButton.actionCdForEvent === McProcessRun.ACTION_VIEW_SKIPPED) {
      this.showRunItemsMcb.setTrue();
    } else if (mcButton.actionCdForEvent === McProcessRun.ACTION_SHOW_EDIT) {
      this.mcProcessRunId = mcButton.item.id;
      this.showAddEditMcProcessRunMCb.setTrue();
    } else if (mcButton.actionCdForEvent === McProcessRun.ACTION_START_RUN) {
      this.mcProcessRun = mcButton.item;
      this.mcProcessRun.startRun(this.mcProcessRun.id, () => {
      }, () => {
        this.errorMsg = this.mcProcessRun.apiErrorMessage;
      });
    } else if (mcButton.actionCdForEvent === McProcessRun.ACTION_CLOSE_RUN) {
      this.mcProcessRun = mcButton.item;
      this.mcProcessRun.closeRun(this.mcProcessRun.id, () => {
        this.actLoad();
      }, () => {
        this.errorMsg = this.mcProcessRun.apiErrorMessage;
      });
    }
  }

  actRunCancelEmit() {
    this.showAddEditMcProcessRunMCb.setFalse();
    this.mcProcessRunId = 0;
    this.mcProcessRun = new McProcessRun();
  }

  actGoToProcesses() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/processes/overview`]);
  }

  actCloseRunItemsProfile() {
    this.showRunItemsMcb.setFalse();
  }

  onTableRefresh() {
    this.actLoad();
  }
}
