/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McConsumerImportFilter} from '../models/mc-consumer-import-filter.model';

export class McConsumerImportFilterGENERATED extends BaseObjectFilter{    public searchTerm: string | undefined;
    public userIds: number[] = [];

  public properties: string[] = ['searchTerm', 'userIds', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McConsumerImportFilter {
    console.error('MTCN-ERROR: Not Implemented: McConsumerImportFilter::createNewInstance(). Add this method to final class and return new McConsumerImportFilter();');
      throw new Error('Cannot McConsumerImportFilter::createNewInstance(). Add this method to final class and return new McConsumerImportFilter();');
  }

}
