import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {WebFile, McUtilityService, EbStarmoneyFile} from '@miticon-ui/mc-core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mc-starmoney-import-file',
  templateUrl: './mc-starmoney-import-file.component.html',
})
export class McStarmoneyImportFileComponent implements OnInit {

  webFile!: WebFile;
  rawFile!: File;
  messageType = '';
  message!: string;
  formGroup!: FormGroup;
  private modalOpenSubscription: any;
  isEnabled!: boolean;
  @Output() refreshTable = new EventEmitter();
  entityId: number;
  userId: number;
  ebStarMoneyFile!: any;

  constructor(private modalService: NgbModal,
              private fb: FormBuilder,
              private utilityService: McUtilityService) {
    this.entityId = this.utilityService.getPropertyFromToken('entity_id');
    this.userId = this.utilityService.getPropertyFromToken('user_id');
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.formGroup = this.fb.group({
      delimiter: [';', Validators.required],
      encoding: [],
    });

    this.webFile = new WebFile();
    this.isEnabled = false;

  }

  // Modal
  importStarMoneyModal(modalName: any) {
    debugger
    this.modalOpenSubscription = this.modalService.open(modalName, {
      backdrop: 'static',
      centered: true,
      windowClass: 'mc-add-consumer-modal',
      beforeDismiss: () => {
        this.messageType = '';
        this.message = '';
        return true;
      }
    });
  }

  // ---------------------------------------------------------------------
  actUpload() {
    this.messageType = 'error';
    if (this.rawFile != null) {
      const delimiter = this.formGroup.get('delimiter')?.value;
      this.webFile.upload({file: this.rawFile, delimiter}, WebFile.TYPE_CD_EB_BANK_SEPA_STATEMENT_REPORT,() => {
        this.ebStarMoneyFile = new EbStarmoneyFile();
        this.ebStarMoneyFile.idWebFile = this.webFile.id;
        this.ebStarMoneyFile.idMcEntity = this.entityId;
        this.ebStarMoneyFile.idMcSystemUser = this.userId;
        this.ebStarMoneyFile.callImportStarMoneyFile(this.ebStarMoneyFile, () => {
          this.refreshTable.emit();
          this.modalService.dismissAll();
        }, () => {
          this.message = this.ebStarMoneyFile.apiErrorMessage || 'An error has occured while importing Starmoney file.';
          this.messageType = 'error';
          this.isEnabled = false;
        });
      }, () => {
        this.message = this.webFile.apiErrorMessage || 'Something went wrong';
        this.messageType = 'error';
        this.isEnabled = false;
      });
    } else {
      this.message = 'Upload file';
      this.messageType = 'error';
      this.isEnabled = false;
    }
  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.modalService.dismissAll();
  }

  // ---------------------------------------------------------------------
  onFileSelected(rawFile: File) {
    this.rawFile = rawFile;
    // this.isEnabled = this.rawFile != null;
    if (this.rawFile != null) {
      this.isEnabled = true;
    }
  }

}

