import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {McEntityBasicInfoComponent} from "./mc-entity-basic-info.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {McComponentsModule} from "@miticon-ui/mc-components";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [McEntityBasicInfoComponent],
  exports: [
    McEntityBasicInfoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    McComponentsModule,
    TranslateModule
  ]
})
export class McEntityBasicInfoModule { }
