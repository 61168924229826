/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McVat} from '../models/mc-vat.model';
import {McVatService} from '../services/mc-vat.service';
import {IMcVat} from '../_generated/mc-vat.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McVatGENERATED extends BaseObject {


    public static readonly VAT_TYPE_CD_STANDARD_RATE: string = 'STANDARD_RATE';
    public static readonly VAT_TYPE_CD_REDUCED_RATE: string = 'REDUCED_RATE';
    public static readonly VAT_TYPE_CD_OTHER: string = 'OTHER';
  public static readonly VAT_TYPE_CD_LIST__ALL = [
    McVatGENERATED.VAT_TYPE_CD_STANDARD_RATE,
    McVatGENERATED.VAT_TYPE_CD_REDUCED_RATE,
    McVatGENERATED.VAT_TYPE_CD_OTHER
];

  public apiService: McVatService;
  public _rawJson: IMcVat;
    id: number = 0;
  endDate: number;
  idMcCountry: number;
  startDate: number;
  vatDescription = '';
  vatRate: number;
  vatTypeCd: string;
  mcVatDateActive: boolean;

  public properties: string[] = ['id', 'endDate', 'idMcCountry', 'startDate', 'vatDescription', 'vatRate', 'vatTypeCd', 'mcVatDateActive'];
  public propertiesRegular: string[] = ['id', 'endDate', 'idMcCountry', 'mcCountry', 'startDate', 'vatDescription', 'vatRate', 'vatTypeCd', 'mcVatDateActive'];
  public propertiesSpecial: string[] = [];



  public static getVatTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McVatGENERATED.VAT_TYPE_CD_STANDARD_RATE, McGod.t('Standard rate'));
    list.add(McVatGENERATED.VAT_TYPE_CD_REDUCED_RATE, McGod.t('Reduced rate'));
    list.add(McVatGENERATED.VAT_TYPE_CD_OTHER, McGod.t('Other'));


    return list;
  }

  public static getVatTypeCdLabel(aCd: string): string {
    const list = McVatGENERATED.getVatTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McVat {
    console.error('MTCN-ERROR: Not Implemented: McVat::createNewInstance(). Add this method to final class and return new McVat();');
    throw new Error('Cannot McVat::createNewInstance(). Add this method to final class and return new McVat();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcVat): McVat {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McVatService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McVat AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getEndDateStrD(): string {
       return McDateUtils.formatDateToString(this.endDate);
    }

    public getEndDateStr(): string {
       return McDateUtils.formatDateTimeToString(this.endDate);
    }

    public getStartDateStrD(): string {
       return McDateUtils.formatDateToString(this.startDate);
    }

    public getStartDateStr(): string {
       return McDateUtils.formatDateTimeToString(this.startDate);
    }

    public getVatRateStr(): string {
       return McGod.formatNumberToMoney(this.vatRate);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getVatTypeCdLabel(): string {
    return McVatGENERATED.getVatTypeCdLabel(this.vatTypeCd);
  }


 public  isVatTypeCdSTANDARD_RATE(): boolean {
    const result = (this.vatTypeCd === McVatGENERATED.VAT_TYPE_CD_STANDARD_RATE);

    return result;
  }

 public  isVatTypeCdREDUCED_RATE(): boolean {
    const result = (this.vatTypeCd === McVatGENERATED.VAT_TYPE_CD_REDUCED_RATE);

    return result;
  }

 public  isVatTypeCdOTHER(): boolean {
    const result = (this.vatTypeCd === McVatGENERATED.VAT_TYPE_CD_OTHER);

    return result;
  }


  
}
