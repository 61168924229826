<mcc-fi-itemlist-checkboxes
    *ngIf="factoringRequestTypeList.apiSuccessFlg && showNowFlg"
    label="{{'cc.contract.request-types' | translate}}"
    name="chkFactoringRequestTypes"
    [validRequiredFlg]="true"
    [validMinChecked]="1"
    [directionHorizontal]="false"
    [mcForm]="mcForm"
    [itemList]="factoringRequestTypeList"
    [values]="checkedRequests"
    itemLabelPropertyName="title"
    itemValuePropertyName="id"
    (eventValueChanged)="checkedRequstTypesChanged($event)"
    [labelOnTop]="true"></mcc-fi-itemlist-checkboxes>
<button class="system-action-btn" (click)="openAddRequestTypeModal()"><i class="fas fa-plus-circle"></i>
  {{'cc.contract.add-request-types' | translate}}</button>
<mcc-modal1-wrapper [showMcb]="showAddRequestTypePart" [modalSizeCd]="'small'" style="float: right" (eventCanceled)="addRequestTypeModalCanceled()">
  <app-shared-factoring-request-type-add-part [idChildEntity]="idChildEntity"
                                              [isCanceled]="isAddRequestTypeCanceled"
                                              (eventCancel)="onRequestTypeCanceled();"
                                              [entityForCloningId]="entityForCloningId"
                                              [isContractCloned]="isContractCloned"
                                              (eventSaved)="onRequestTypeSaved()"></app-shared-factoring-request-type-add-part>
</mcc-modal1-wrapper>
<div style="height: 30px; width: auto;"></div>
