import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-mc-consumers-archive',
  templateUrl: './mc-consumers-archive.component.html'
})
export class McConsumersArchiveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
