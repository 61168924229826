/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {PpOrderFilter} from '../models/pp-order-filter.model';

export class PpOrderFilterGENERATED extends BaseObjectFilter{    public searchTerm: string | undefined;
    public states: string[] = [];
    public types: string[] = [];
    public creationDateFrom: any | undefined;
    public creationDateTo: any | undefined;

  public properties: string[] = ['searchTerm', 'states', 'types', 'creationDateFrom', 'creationDateTo', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): PpOrderFilter {
    console.error('MTCN-ERROR: Not Implemented: PpOrderFilter::createNewInstance(). Add this method to final class and return new PpOrderFilter();');
      throw new Error('Cannot PpOrderFilter::createNewInstance(). Add this method to final class and return new PpOrderFilter();');
  }

}
