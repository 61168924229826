import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ICsrConsumerImport} from '../_generated/csr-consumer-import.interface';
import {CsrConsumerImport} from '../models/csr-consumer-import.model';
import {CsrConsumerImportList} from '../models/csr-consumer-import-list.model';
import {CsrConsumerImportServiceGENERATED} from '../_generated/csr-consumer-import-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CsrConsumerImportService extends CsrConsumerImportServiceGENERATED {

}
