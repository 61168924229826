import {McPdfTemplate} from './mc-pdf-template.model';
import {McPdfTemplateListGENERATED} from '../_generated/mc-pdf-template-list-generated.model';

export class McPdfTemplateList extends McPdfTemplateListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McPdfTemplateList();
  }

  // ---------------------------------------------------------------------
}
