<lib-mc-loader [showLoader]="csrConsumer.apiLoadingFlg"></lib-mc-loader>

<section *ngIf="!csrConsumer.apiLoadingFlg">
  <div class="cardbox" *ngIf="noBankAccounts">{{ 'cc.consumer.no-bank-accounts' | translate }}</div>
  <div class="cardbox" *ngIf="primaryBankAccount">
    <div class="cardbox_header">
      <h2 class="cardbox_title">{{ primaryBankAccount.bankName }}</h2>
      <span class="cardbox_header_icon" [innerHTML]="iconEdit | safeHtml" (click)="goToEditConsumer()"></span>
    </div>
    <div class="divider"></div>
    <div class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.account-owner' | translate}}:</span>
        <span class='dialog-col-value'>{{ primaryBankAccount.owner ? primaryBankAccount.owner : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.iban' | translate}}:</span>
        <span class='dialog-col-value'>{{ primaryBankAccount.iban ? primaryBankAccount.iban : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.bic' | translate}}:</span>
        <span class='dialog-col-value'>{{ primaryBankAccount.bic ? primaryBankAccount.bic : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumer.overview.sepa-mandate-id' | translate}}:</span>
        <span class='dialog-col-value'>{{ primaryBankAccount.sepaMandateId ? primaryBankAccount.sepaMandateId : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumer.overview.sepa-mandate-date-of-signature' | translate }}:</span>
        <span class='dialog-col-value'>{{ primaryBankAccount.sepaMandateDate ? getFormattedDate(primaryBankAccount.sepaMandateDate) : '-' }}</span>
      </div>
    </div>
  </div>

  <h2 class="bank_title" *ngIf="previousBankAccounts && previousBankAccounts.length > 0">{{ 'cc.consumer.previous-accounts' | translate }}</h2>
  <div class="cardbox" *ngFor="let bankAccount of previousBankAccounts">
    <h2>{{ bankAccount.bankName }}</h2>
    <div class="divider"></div>
    <div class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.account-owner' | translate}}:</span>
        <span class='dialog-col-value'>{{ bankAccount.owner ? bankAccount.owner : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.iban' | translate}}:</span>
        <span class='dialog-col-value'>{{ bankAccount.iban ? bankAccount.iban : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.bic' | translate}}:</span>
        <span class='dialog-col-value'>{{ bankAccount.bic ? bankAccount.bic : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumer.overview.sepa-mandate-id' | translate}}:</span>
        <span class='dialog-col-value'>{{ bankAccount.sepaMandateId ? bankAccount.sepaMandateId : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumer.overview.sepa-mandate-date-of-signature' | translate }}:</span>
        <span class='dialog-col-value'>{{ bankAccount.sepaMandateDate ? getFormattedDate(bankAccount.sepaMandateDate) : '-' }}</span>
      </div>
    </div>
  </div>
</section>
