import {CcMollieTransactionFilterGENERATED} from '../_generated/cc-mollie-transaction-filter-generated.model';

export class CcMollieTransactionFilter extends CcMollieTransactionFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CcMollieTransactionFilter();
  }

  // ---------------------------------------------------------------------
}
