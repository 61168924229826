<div *ngIf="isVisible()" class="mcc-modal modal show d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Add to blacklist</h1>
        <button type="button" class="close" (click)="actCancel()">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">

          <div class="row m-0 p-0">
            <div class="col-md-12">
              <div class="mc-create-entity-notify-wrapper">
                <mc-notify-message [message]="notifyMessage.message" [type]="notifyMessage.type"></mc-notify-message>
              </div>
            </div>
            <div class="col-md-7 p-0 pt-5">
              <div class="row m-0 p-0">
                <div class="col-md-12 p-0 m-0">
                  <mcc-fi-textarea [name]="'reason'" [label]="'Reason'" [numCols]="35"
                                   [validRequiredFlg]="true" [mcForm]="mcForm"></mcc-fi-textarea>
                </div>
                <div class="col-md-12 pt-3">
                  <mcc-fi-checkbox [label]="'Allow factoring'"
                                   [name]="'factoring'"
                                   [value]="false"
                                   [mcForm]="mcForm"></mcc-fi-checkbox>
                </div>
                <div class="col-md-12">
                  <mcc-fi-checkbox [label]="'Allow direct debit'"
                                   [name]="'directDebit'"
                                   [value]="false"
                                   [mcForm]="mcForm"></mcc-fi-checkbox>
                </div>
              </div>
            </div>
            <div class="col-md-5 p-0 pt-5 d-flex flex-column">
              <span>Selected consumers:</span>
              <mcc-html *ngFor="let consumer of selectedConsumerList.items" [values]="consumer.fldName()">
              </mcc-html>
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="actCancel()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="actAddToBlacklist()">Blacklist</button>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>

