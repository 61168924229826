/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McBillingBatchAssignedToStatement} from '../models/mc-billing-batch-assigned-to-statement.model';
import {McBillingBatchAssignedToStatementList} from '../models/mc-billing-batch-assigned-to-statement-list.model';
import {McBillingBatchAssignedToStatementFilter} from '../models/mc-billing-batch-assigned-to-statement-filter.model';
import {McBillingBatchAssignedToStatementService} from '../services/mc-billing-batch-assigned-to-statement.service';
import {IMcBillingBatchAssignedToStatement} from '../_generated/mc-billing-batch-assigned-to-statement.interface';
import {McGod} from "../extra/mc-god.service";

export class McBillingBatchAssignedToStatementListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'acceptedForSFColumnFinalSum', 'acceptedForSFColumnInitialSum', 'acceptedForSFColumnPercentage', 'alreadyPaidBalanceColumnFinalSum', 'alreadyPaidBalanceColumnInitialSum', 'alreadyPaidBalanceColumnPercentage', 'alreadyPaidFromConsumerBalanceColumnFinalSum', 'alreadyPaidFromConsumerBalanceColumnInitialSum', 'alreadyPaidFromConsumerBalanceColumnPercentage', 'alreadyReturnedBalanceColumnFinalSum', 'alreadyReturnedBalanceColumnInitialSum', 'alreadyReturnedBalanceColumnPercentage', 'batchHeader', 'idMcBillingBatch', 'idMcBillingStatement', 'immediatelyPayableBalanceColumnFinalSum', 'immediatelyPayableBalanceColumnInitialSum', 'immediatelyPayableBalanceColumnPercentage', 'immediatelyPayableConsumerBalanceColumnFinalSum', 'immediatelyPayableConsumerBalanceColumnInitialSum', 'immediatelyPayableConsumerBalanceColumnPercentage', 'laterPayableBalanceColumnFinalSum', 'laterPayableBalanceColumnInitialSum', 'laterPayableBalanceColumnPercentage', 'notAcceptedForSFColumnFinalSum', 'notAcceptedForSFColumnInitialSum', 'notAcceptedForSFColumnPercentage', 'returningAmountFinalBounceColumnFinalSum', 'returningAmountFinalBounceColumnInitialSum', 'returningAmountFinalBounceColumnPercentage', 'sysCreatedDatetime'];
  public items: McBillingBatchAssignedToStatement[] = [];
  public apiService: McBillingBatchAssignedToStatementService;
 // protected tempObjList: McBillingBatchAssignedToStatementList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingBatchAssignedToStatementList {
    console.error('MTCN-ERROR: Not Implemented: McBillingBatchAssignedToStatementList::createNewInstance(). Add this method to final class and return new McBillingBatchAssignedToStatementList();');
    throw new Error('Cannot McBillingBatchAssignedToStatementList::createNewInstance(). Add this method to final class and return new McBillingBatchAssignedToStatementList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcBillingBatchAssignedToStatement[]): McBillingBatchAssignedToStatementList {
    const  listObj: McBillingBatchAssignedToStatementList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McBillingBatchAssignedToStatement = McBillingBatchAssignedToStatement.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McBillingBatchAssignedToStatementList {
    const  listObj: McBillingBatchAssignedToStatementList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingBatchAssignedToStatementService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcBillingBatchAssignedToStatement[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McBillingBatchAssignedToStatement = McBillingBatchAssignedToStatement.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcBillingBatchAssignedToStatementFilter: McBillingBatchAssignedToStatementFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcBillingBatchAssignedToStatementFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McBillingBatchAssignedToStatement {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McBillingBatchAssignedToStatement();
    }
  }

  public replaceItem(newItem: McBillingBatchAssignedToStatement) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadByIdMcBillingStatement( idMcBillingStatement: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByIdMcBillingStatement(idMcBillingStatement, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

