<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcProcessFlow.apiLoadingFlg"></lib-mc-loader>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
    <h1
      *ngIf="mcProcessFlow.exists()">{{"cc.common.edit" | translate}} {{"cc.processes.process-flow" | translate}} {{'cc.processes.process-flow' | translate}}</h1>
    <h1 *ngIf="!mcProcessFlow.exists()">{{mcProcess.title}}: {{"cc.processes.create-flow" | translate}}</h1>

  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!mcProcessFlow.apiLoadingFlg" class="content-body pb-5">
    <mcc-message [errorMsg]="errorMsg"></mcc-message>
    <mcc-message [errorMsgs]="errorMsgs" label="{{'cc.my-factoring.unable-to-proceed' | translate}}"></mcc-message>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container>

      <div class="row">
        <div class="col-md-12 pl-0">
          <div class="section-header">
            <h3>{{"cc.common.general-info" | translate}}</h3>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-inner-content pb-3">
                <mcc-fi-radio-buttons label="{{'cc.common.view.status' | translate}}"
                                      [name]="'ctrlStatus'"
                                      [directionHorizontal]="true"
                                      [mcForm]="mcForm"
                                      [value]="'INACTIVE'"
                                      [validRequiredFlg]="true"
                                      [extraCssClass]="'mc-disable'"
                                      [optionsVlm]="statusVlm"></mcc-fi-radio-buttons>

                <mcc-fi-textbox label="{{'cc.common.edit.title' | translate}}" name="ctrlTitle"
                                [value]="mcProcessFlow.title" [mcForm]="mcForm"
                                [validRequiredFlg]="true" [validWhitespaces]="true"></mcc-fi-textbox>

                <mcc-fi-textarea [name]="'ctrlDescription'" [value]="mcProcessFlow.description"
                                 label="{{'cc.common.view.description' | translate}}"
                                 [numCols]="45"
                                 [validRequiredFlg]="true" [validWhitespaces]="true" [mcForm]="mcForm"></mcc-fi-textarea>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-inner-content pb-3">
              <mcc-fi-select  name="selDay" label="{{'cc.common.day' | translate}}"
                              placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                              [validRequiredFlg]="true"
                              [value]=""
                              [optionsVll]="recurringDetailsWeekDaysVll"
                              (eventValueChanged)="onDayChanged($event)"
                              [mcForm]="mcForm"></mcc-fi-select>
              </div>
            </div>
          </div>
          <div class="col-md-12 pl-0">
            <div class="section-header">
              <h3>Transactions entering the flow</h3>
            </div>
            <div class="form-inner-content pb-3">

              <span class="mc-mandatory-star">Transactions that will enter the flow</span>

              <div class="d-flex pt-2">
                <mcc-fi-checkbox class="pr-4" label="Accepted"
                                 name="ctrlTransactionsAccepted"
                                 [value]="false"
                                 [mcForm]="mcForm"></mcc-fi-checkbox>

                <mcc-fi-checkbox class="pr-4" label="Returned"
                                 name="ctrlTransactionsReturned"
                                 [value]="false"
                                 [mcForm]="mcForm"></mcc-fi-checkbox>

                <mcc-fi-checkbox class="pr-4" label="For dunning"
                                 name="ctrlTransactionsForDunning"
                                 [value]="false"
                                 [mcForm]="mcForm"></mcc-fi-checkbox>

                <mcc-fi-checkbox class="pr-4" label="Should go to Inkasso"
                                 name="ctrlTransactionsShouldGoToInkasso"
                                 [value]="false"
                                 [mcForm]="mcForm"></mcc-fi-checkbox>

                <mcc-fi-checkbox label="Sending to Inkasso"
                                 name="ctrlTransactionsSendingToInkasso"
                                 [value]="false"
                                 [mcForm]="mcForm"></mcc-fi-checkbox>

              </div>

            </div>
          </div>

          <div class="col-md-12 pl-0">
            <div class="section-header">
              <h3>{{"cc.processes.criteria-for-entering-the-flow" | translate}}</h3>
            </div>
            <div class="form-inner-content pb-3">
              <span class="mc-mandatory-star">{{'cc.processes.flow.set-criteria' | translate}}:</span>
              <div class="d-flex flex-column">
                <div class="row d-flex" *ngFor="let criteria of mcProcessFlowCriteriaList.items; index as i">
                  <div class="col-md-10 d-flex justify-content-end">
                    <mc-process-process-flow-criteria [attributesVll]="attributesVll"
                                                      [id]="criteria.id"
                                                      [index]="i"
                                                      [mcForm]="mcForm"
                                                      [mcProcessFlowCriteria]="criteria"
                                                      [mcProcessFlowCriteriaAttributeList]="mcProcessFlowCriteriaAttributeList"></mc-process-process-flow-criteria>
                  </div>
                  <div class="col-md-2">
                    <button *ngIf="i === 0" class="system-action-btn" (click)="addCriteria()"><i
                      class="fas fa-plus-circle"></i> {{"cc.processes.add-criteria" | translate}}
                    </button>
                    <button *ngIf="i !== 0" class="system-action-btn" (click)="removeCriteria(i)"><i
                      class="fas fa-minus-circle"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ---------------------- FEES --------------- -->

          <ng-container *ngIf="mcProcess.isTypeCdDUNNING()">
            <div class="section-header">
              <h3>{{"cc.contract.fees" | translate}}</h3>
            </div>
            <div class="row">

              <div class="col-md-6">
                <div class="section-sub-header">
                  <h3>{{"cc.processes.interest-fee" | translate}}</h3>
                </div>
                <div class="form-inner-content pb-3">
                  <div>
                    <div class="position-relative">
                      <mcc-fi-radio-buttons2 label="{{'cc.processes.person-interest-rate' | translate}}"
                                             [name]="'ctrlInterestRatePerson'"
                                             [directionHorizontal]="false"
                                             [mcForm]="mcForm"
                                             [value]="defaultFee"
                                             [validRequiredFlg]="true"
                                             [optionsVll]="personInterestRateVll"></mcc-fi-radio-buttons2>
                      <div class="info-icon-positioning">
                        <i class="mc-blue-icon fas fa-info-circle"
                           ngbTooltip="Current base interest rate of the German Central bank is -0,8%. "
                           placement="bottom-right" tooltipClass="mc-tooltip-length"></i>
                      </div>
                    </div>
                    <div class="mt-n3">
                      <div class="position-relative">
                        <mcc-fi-number-type [mcForm]="mcForm" [value]=""
                                            [extraCssClasses]="mcForm.getValue('ctrlInterestRatePerson') !== 'DEFAULT'? '' : 'mc-disable'"
                                            nameForValue="ctrlPersonInterestRateAmount" label=""
                                            nameForType="ctrlPersonInterestRateType"
                                            validNumberWithTwoDecimals="true"
                                            [validMaxNumber]="mcForm.getValue('ctrlInterestRatePerson') !== 'DEFAULT' ? 5 : null"
                                            [validRequiredFlg]="mcForm.getValue('ctrlInterestRatePerson')!== 'DEFAULT'"
                                            [disableMoneyValue]="true"
                                            valueType="percentage"></mcc-fi-number-type>

                        <div class="info-icon-positioning3">
                          <i class="mc-blue-icon fas fa-info-circle"
                             ngbTooltip="Maximal custom interest rate is 5,00%"
                             placement="bottom-right" tooltipClass="mc-tooltip-length"></i>
                        </div>

                      </div>
                    </div>
                  </div>


                  <!--                <span>Company</span>-->
                  <div>
                    <div class="position-relative">
                      <mcc-fi-radio-buttons2 label="{{'cc.processes.company-interest-rate' | translate}}"
                                             [name]="'ctrlInterestRateCompany'"
                                             [directionHorizontal]="false"
                                             [mcForm]="mcForm"
                                             [value]="defaultFee"
                                             [validRequiredFlg]="true"
                                             [optionsVll]="companyInterestRateVll"></mcc-fi-radio-buttons2>
                      <div class="info-icon-positioning">
                        <i class="mc-blue-icon fas fa-info-circle"
                           ngbTooltip="Current base interest rate of the German Central bank is -0,8%. "
                           placement="bottom-right" tooltipClass="mc-tooltip-length"></i></div>
                    </div>
                    <div class="mt-n3">
                      <div class="position-relative">
                        <mcc-fi-number-type [mcForm]="mcForm" [value]=""
                                            [extraCssClasses]="mcForm.getValue('ctrlInterestRateCompany') !== 'DEFAULT'? '' : 'mc-disable'"
                                            nameForValue="ctrlCompanyInterestRateAmount" label=""
                                            nameForType="ctrlCompanyInterestRateType"
                                            validNumberWithTwoDecimals="true"
                                            [validMaxNumber]="mcForm.getValue('ctrlInterestRateCompany') !== 'DEFAULT'? 9 : null"
                                            [validRequiredFlg]="mcForm.getValue('ctrlInterestRateCompany')!== 'DEFAULT'"
                                            [disableMoneyValue]="true"
                                            valueType="percentage"></mcc-fi-number-type>
                        <div class="info-icon-positioning3">
                          <i class="mc-blue-icon fas fa-info-circle"
                             ngbTooltip="Maximal custom interest rate is 9,00%"
                             placement="bottom-right" tooltipClass="mc-tooltip-length"></i></div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="section-sub-header">
                  <h3>{{"cc.processes.dunning-fee" | translate}}</h3>
                </div>
                <div class="form-inner-content pb-3">
                  <mcc-fi-number-type [mcForm]="mcForm" [value]=""
                                      label="{{'cc.processes.dunning-note-base-fee' | translate}}"
                                      nameForValue="ctrlDunningNoteValue" label="Dunning note base fee"
                                      nameForType="ctrlDunningNoteType"
                                      validNumberWithTwoDecimals="true"
                                      [validRequiredFlg]="true"
                                      [disablePercentage]="true"
                                      [chosenCurrency]="currency"
                                      [valueType]="currency"></mcc-fi-number-type>

                  <!-- <mcc-fi-number-type [mcForm]="mcForm" [value]="0"
                                       label="{{'cc.processes.email-fee' | translate}}"
                                       nameForValue="ctrlEmailFeeValue" label="Email fee"
                                       nameForType="ctrlEmailFeeType"
                                       [maxDecimalPlaces]="2" [disablePercentage]="true"
                                       valueType="euro"></mcc-fi-number-type>

                   <mcc-fi-number-type [mcForm]="mcForm" [value]="0"
                                       label="{{'cc.processes.sms-fee' | translate}}"
                                       nameForValue="ctrlSMSFeeValue" label="SMS fee"
                                       nameForType="ctrlSMSFeeType"
                                       [maxDecimalPlaces]="2" [disablePercentage]="true"
                                       valueType="euro"></mcc-fi-number-type>

                   <mcc-fi-number-type [mcForm]="mcForm" [value]="0"
                                       label="{{'cc.processes.post-fee' | translate}}"
                                       nameForValue="ctrlPostFeeValue" label="Post fee"
                                       nameForType="ctrlPostFeeType"
                                       [maxDecimalPlaces]="2" [disablePercentage]="true"
                                       valueType="euro"></mcc-fi-number-type>-->
                </div>
              </div>

              <!-----------  END OF FEES -------->

            </div>
          </ng-container>

          <div class="section-header">
            <h3>{{'cc.common.additional-info' | translate}}</h3>
          </div>
          <div class="form-inner-content pb-3">
            <!--<mcc-fi-radio-buttons2 label="Inheritable"
                                   [name]="'ctrlInheritableFlg'"
                                   [directionHorizontal]="true"
                                   [mcForm]="mcForm"
                                   [value]="mcProcessFlow.inheritableFlg"
                                   [validRequiredFlg]="true"
                                   [optionsVll]="inheritableVll"></mcc-fi-radio-buttons2>-->

            <mcc-fi-select [mcForm]="mcForm" name="selTransactionStatusAfterLastLevel"
                           placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                           label="After finishing the last dunning level of this flow, all transaction(s) will be in status"
                           [validRequiredFlg]="true"
                           [optionsVll]="transactionStatusVll"></mcc-fi-select>

            <mcc-fi-checkbox name="ctrlSendFlowToInkasso"
                             label="{{'cc.processes.send-flow-to-inkasso-after-last-dunning-level' | translate}}"
                             [mcForm]="mcForm"
                             (eventValueChanged)="cbSendFlowToInkassoValueChanged($event)"></mcc-fi-checkbox>

            <div class="col-8 position-relative">


              <mcc-fi-radio-buttons2 [name]="'ctrlSendFlowTypeOfTransactions'"
                                     [extraCssClass]="mcForm.getValue('ctrlSendFlowToInkasso') ? '' : 'mc-disable'"
                                     [directionHorizontal]="false"
                                     [mcForm]="mcForm"
                                     [validRequiredFlg]="cbSendFlowToInkassoValidFlg"
                                     [optionsVll]="sendFlowToInkassoTransactionsVll"></mcc-fi-radio-buttons2>

              <div class="info-icon-positioning2">
                <i class="mc-blue-icon fas fa-info-circle"
                   ngbTooltip="All consumer-s transactions (the ones in the flow and those that are not Paid/Settled) will go to Inkasso."
                   placement="bottom-right" tooltipClass="mc-tooltip-length"></i></div>

              <div class="info-icon-positioning">
                <i class="mc-blue-icon fas fa-info-circle"
                   ngbTooltip="Only transactions that reach the last dunning level of this flow will go to Inkasso."
                   placement="bottom-right" tooltipClass="mc-tooltip-length"></i></div>

              </div>


            <mcc-fi-checkbox *ngIf="mcProcess.isTypeCdDUNNING()" name="ctrlGroupByConsumer"
                             label="{{'cc.processes.group-transactions-by-consumer' | translate}}"
                             [mcForm]="mcForm"></mcc-fi-checkbox>


          </div>
        </div>
      </div><!-- row -->
    </ng-container>

  </div><!-- content-body -->

  <div class="row">
    <div class="col-md-12 position-relative">
      <div class="above-footer">
        <mcc-message [errorMsg]="errorMsg"></mcc-message>
      </div>
    </div>
  </div>

  <!-- == FOOTER ======================================== -->
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="{{'cc.common.edit.cancel' | translate}}"></mcc-button>
    <mcc-button *ngIf="(mcProcessFlow.apiSuccessFlg) && (!hasErrors())" extraCssClasses="btn btn-primary"
                (eventClicked)="actSave();" label="{{'cc.common.view.create' | translate}}"
                [disabledMcb]="saveButtonDisabledMcb"
                disableOnFirstClickFlg="true"></mcc-button>
  </div>

</div>
<router-outlet></router-outlet>
