/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McProcessRunItemTable3ConfigGENERATED} from '../_generated/mc-process-run-item-table-3-config-generated.model';

// import {McProcessRunItemGENERATED} from '../_generated/mc-process-run-item-generated.model';

export class McProcessRunItemTable3Config extends McProcessRunItemTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.clearColumns();
    // this.addColumn("colIdMcProcessRun", "McProcessRun ID", "idMcProcessRun", "idMcProcessRun");
    // this.addColumn("colIdMcProcessItem", "McProcessItem ID", "idMcProcessItem", "idMcProcessItem");
    // this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colConsumer', 'Consumer', 'getConsumerFullName()', '');
    this.addColumn('colTransaction', 'Transaction description', 'getTransactionDescription()', 'transactionDescription');
    this.addColumn('colAmountDue', 'Amount due', 'getAmountDue()', 'transactionAmountDue');
    this.addColumn('colFlow', 'Flow', 'mcProcessFlowName', 'mcProcessFlowName');
    this.addColumn('colLevel', 'Level', 'mcProcessFlowLevelName', 'mcProcessFlowLevelName');
    this.addColumn('colActions', 'Actions', 'fldLevelActions()', '');
    this.addColumn('colStatusCd', 'Status code', 'fldStatus()', 'statusCd');


  }
}
