import { McProcessLevelProfilePageComponent } from './pages/mc-process-level-profile-page/mc-process-level-profile-page.component';
import { McProcessPdfTemplateListComponent } from './pages/mc-process-pdf-template-list/mc-process-pdf-template-list.component';
import { McProcessProcessRunListPageComponent } from './pages/mc-process-process-run-list-page/mc-process-process-run-list-page.component';
import { McProcessProcessItemListPageComponent } from './pages/mc-process-process-item-list-page/mc-process-process-item-list-page.component';
import { McProcessFlowLevelProfilePageComponent } from './pages/mc-process-flow-level-profile-page/mc-process-flow-level-profile-page.component';
import { McProcessFlowProfilePageComponent } from './pages/mc-process-flow-profile-page/mc-process-flow-profile-page.component';
import { McProcessProcessProfilePageComponent } from './pages/mc-process-process-profile-page/mc-process-process-profile-page.component';
import { McProcessProcessListPageComponent } from './pages/mc-process-process-list-page/mc-process-process-list-page.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {McProcessProcessEmailListPageComponent} from "./pages/mc-process-process-email-list-page/mc-process-process-email-list-page.component";

const routes: Routes = [
{
    path: 'level-profile/:id',
    component: McProcessLevelProfilePageComponent
  },
{
    path: 'templates',
    component: McProcessPdfTemplateListComponent
  },
{
    path: 'process-runs/:id',
    component: McProcessProcessRunListPageComponent
  },
{
    path: 'process-items/:id',
    component: McProcessProcessItemListPageComponent
  },
  {
    path: 'process-email/:id',
    component: McProcessProcessEmailListPageComponent
  },
{
    path: 'level-profile/:id',
    component: McProcessFlowLevelProfilePageComponent
  },
{
    path: 'flow-profile/:id',
    component: McProcessFlowProfilePageComponent
  },
{
    path: 'process-profile/:id',
    component: McProcessProcessProfilePageComponent
  },
{
    path: 'overview',
    component: McProcessProcessListPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McProcessRoutingModule {
}
