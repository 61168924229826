import {Component, EventEmitter, Output} from '@angular/core';
import {BaseDirective, McForm, McManagementService} from '@miticon-ui/mc-core';
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'mc-product-add-category',
  templateUrl: './mc-product-add-category.component.html',
})
export class McProductAddCategoryComponent extends BaseDirective {
  mcForm = new McForm();
  errorMsg!: string;


  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter();

  constructor(private mcManagamentService: McManagementService) {
    super();
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actSave() {
    if (this.mcForm.isValid()) {
      const category = {
        name: this.mcForm.getValue('ctrlName'),
        description: this.mcForm.getValue('ctrlDescription')
      };
      this.mcManagamentService.createCategory(category)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res) => {
          this.eventSaved.emit();
        }, (error) => {
          this.errorMsg = error.error.message
        });
    }
  }

}
