import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { McUtilityService } from "../services/mc-utility.service";
import { McPermissionService } from "../services/mc-permission.service";

@Directive({
  selector: "[McPermission]",
})
export class McPermissionDirective implements OnInit {
  /*Private variables*/
  private userPermissions;
  private _usePermissionDirective = true;

  /*Events*/
  @Input() hasPermissions: any;
  @Input() operation = "OR";
  @Input() page!: string;
  @Input() set usePermissionDirective(boo) {
    if (typeof boo !== undefined) {
      this._usePermissionDirective = boo;
    }
  }

  get usePermissionDirective() {
    return this._usePermissionDirective;
  }
  /*Constructor*/
  constructor(
    private utilityService: McUtilityService,
    private permissionService: McPermissionService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    /*Get user permissions and all available permissions from DB*/
    this.userPermissions =
      this.utilityService.getAuthoritiesFromStorage();
  }

  /*On init class*/
  ngOnInit() {
    /*Show or hide element*/
    if (this.usePermissionDirective && !this.checkPermission()) {
      const parent = this.renderer.createElement("div");
      const parentNode = this.renderer.appendChild(
        parent,
        this.el.nativeElement
      );
      this.renderer.removeChild(parentNode, this.el.nativeElement);
    }
  }

  /*Check user has permission*/
  private checkPermission() {
    let found;
    if (!this.hasPermissions.length) return true;
    if (this.userPermissions && this.hasPermissions) {
      for (let i = 0; i < this.hasPermissions.length; i++) {
        /*Find element permission in user permissions*/
        found = this.userPermissions.find((item: any) => {
          if (item === this.hasPermissions[i].name) {
            /*If page exist and permission has pages
             * Find page in pages and return it
             * Else return found item */
            if (this.page && this.hasPermissions[i].pages.length > 0) {
              return this.hasPermissions[i].pages.find(
                (page: string) => page === this.page
              );
            } else {
              return item;
            }
          }
        });

        /*If operation is 'AND', break for loop on first null result*/
        if (this.operation === "AND" && !found) {
          break;
        }

        /*If operation is 'OR', break for loop on first true result*/
        if (this.operation === "OR" && found) {
          break;
        }
      }
    }
    return found;
  }
}
