/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McEntity} from '../models/mc-entity.model';
import {McEntityList} from '../models/mc-entity-list.model';
import {McEntityFilter} from '../models/mc-entity-filter.model';
import {McEntityService} from '../services/mc-entity.service';
import {IMcEntity} from '../_generated/mc-entity.interface';
import {McGod} from "../extra/mc-god.service";

export class McEntityListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'abbreviation', 'active', 'address', 'bankTransactionEntityNameOverride', 'city', 'companyForm', 'countryId', 'courtInCharge', 'createdAt', 'customAttributeMap', 'description', 'email', 'euVat', 'extCreditorId', 'fax', 'impressum', 'ipWhitelist', 'isVisibleToChildren', 'latitude', 'legalRepresentative', 'legalRepresentativeContact', 'logo', 'longitude', 'mailDomain', 'mailPrefix', 'maxTransactionPauseDays', 'name', 'parentEntityId', 'phone', 'postalCode', 'responsiblePerson', 'responsiblePersonFunction', 'rootEntityId', 'studioOwner', 'studioOwnerAddress', 'studioOwnerCity', 'studioOwnerPostalCode', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'tax', 'transactionDescriptionPrefix', 'updatedAt', 'ustId', 'version', 'webDomain', 'website', 'bankAccount', 'parent', 'rolesCount', 'usersCount'];
  public items: McEntity[] = [];
  public apiService: McEntityService;
 // protected tempObjList: McEntityList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McEntityList {
    console.error('MTCN-ERROR: Not Implemented: McEntityList::createNewInstance(). Add this method to final class and return new McEntityList();');
    throw new Error('Cannot McEntityList::createNewInstance(). Add this method to final class and return new McEntityList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcEntity[]): McEntityList {
    const  listObj: McEntityList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McEntity = McEntity.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McEntityList {
    const  listObj: McEntityList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McEntityService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcEntity[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McEntity = McEntity.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcEntityFilter: McEntityFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcEntityFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McEntity {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McEntity();
    }
  }

  public replaceItem(newItem: McEntity) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadAllWhereParentEntityIsLoggedInEntity( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getAllWhereParentEntityIsLoggedInEntity( this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

