<div class="pager d-flex justify-content-end">

  <!--Pagination left-->
  <div class="pager-left">
    <span>{{'cc.common.items-per-page' | translate}}</span>
    <ng-select
      [searchable]="false"
      [clearable]="false"
      dropdownPosition="top"
      [items]="pagination.pageSizeOptions"
      bindLabel="option"
      bindValue="option"
      [(ngModel)]="pagination.pageSize"
      (change)="onNumOfItemsPerPageChange($event)"></ng-select>
    <span> / {{pagination.totalElements}}</span>
  </div>

  <!--Pagination center-->
  <div class="pager-center">
    <span class="pager-page">{{'cc.common.page' | translate}} {{pagination.pageNumber + 1}} / {{pagination.totalPages}}</span>
  </div>

  <!--Pagination right-->
  <div class="pager-right">
    <i class="fas	fa-angle-right btn-first-pg" (click)="goToFirstPage()"></i>
    <i class="fas fa-angle-left btn-prev" (click)="goToPreviousPage()"></i>
    <i class="fas fa-angle-right btn-next" (click)="goToNextPage()"></i>
    <i class="fas	fa-angle-right btn-last-pg" (click)="goToLastPage()"></i>
  </div>
</div>
