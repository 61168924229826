import {McConsumerFilterGENERATED} from '../_generated/mc-consumer-filter-generated.model';

export class McConsumerFilter extends McConsumerFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McConsumerFilter();
  }

  // ---------------------------------------------------------------------
}
