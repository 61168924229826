import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McPriceAdjustmentComponent } from './mc-price-adjustment.component';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [McPriceAdjustmentComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  exports: [McPriceAdjustmentComponent]
})
export class McPriceAdjustmentModule { }
