/*BbmTestObj*/
import {McBillingItemGENERATED} from '../_generated/mc-billing-item-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {McDateUtils} from '../common/mc-date-utils';
import {ActionOptions} from '../_core/mc-html-action-options';
import {CurrencyUtil} from '../common/currency-util';

export class McBillingItem extends McBillingItemGENERATED {

  public static ACTION_ASSIGN_TO_STATEMENT = 'ASSIGN_TO_STATEMENT';
  public static ACTION_AGGREGATE = 'AGGREGATE';
  private _fldActions: McHtml | null = null;

  public static FILTER_ENTITIES = 'cc.common.view.entity';
  public static FILTER_TYPE = 'cc.common.item-type';
  public static FILTER_STATUS = 'cc.common.status';
  public static FILTER_AMOUNT = 'cc.factoring.transactions.amount';
  public static FILTER_DATE_RANGE = 'cc.common.activity-date-range';

  public currencyCode!: string;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingItem();
  }
  // ---------------------------------------------------------------------

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      if (this.statusCd !== McBillingItem.STATUS_CD_FINALIZED) {
        actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> Assign to statement`, McBillingItem.ACTION_ASSIGN_TO_STATEMENT, this, [McGod.PERM_MC_BILLING_OUT_BILLING_ITEM_ASSIGN_TO_STATEMENT], '', ActionOptions.OPTION_EDIT);
        // actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> Aggregate`, McBillingItem.ACTION_AGGREGATE, this, [McGod.PERM_MC_PROCESS_ITEM_VIEW]);
      }
      this._fldActions = html;
    }
    return this._fldActions;
  }

  getVatMoney(): string {
    return CurrencyUtil.format(this.vatForMcBillingItem, this.currencyCode);
  }

  getAmount(): string {
    return this.amount ? CurrencyUtil.format(this.amount, this.currencyCode) : CurrencyUtil.format(0, this.currencyCode);
  }

  getTotalAmount(): string {
    if (this.vatPercent) {
      return CurrencyUtil.format(this.amount + this.amount * this.vatPercent / 100, this.currencyCode);
    } else {
      return CurrencyUtil.format(this.amount, this.currencyCode);
    }
  }

  getPayoutDateString(): string {
    if (this.payoutDate) {
      return McDateUtils.newFormatDateString(this.payoutDate);
    }
    return '';
  }

  getActivityDatetimeString(): string {
    if (this.activityDatetime) {
      return McDateUtils.newFormatDateTimeString(this.activityDatetime);
    }
    return '';
  }

  getItemTitle(): string {
    if (this.title) {
      return this.title;
    }
    return 'Payout item';
  }
  getBillingTitle(): string {
    if (this.title) {
      return this.title;
    }
    return 'Billing item';
  }

  getInfoIconForVAT(): string {
    const vat = this.vatPercent ? this.vatPercent : 0;
    return `<div class="icon-tooltip mr-2"><img src="assets/images/info-blue-tooltip.svg"><span class="icon-tooltip-text">${this.t('cc.product.view.vat')}: ${vat.toFixed(2).replace('.', ',')} %</span></div>`;
  }

  getVATString(): string {
    if (this.vatForMcBillingItem) {
      return CurrencyUtil.format(this.vatForMcBillingItem, this.currencyCode) + this.getInfoIconForVAT();
    } else {
      return CurrencyUtil.format(0, this.currencyCode) + this.getInfoIconForVAT();
    }
  }
}
