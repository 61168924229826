import {McPdfTemplateFilterGENERATED} from '../_generated/mc-pdf-template-filter-generated.model';

export class McPdfTemplateFilter extends McPdfTemplateFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McPdfTemplateFilter();
  }

  // ---------------------------------------------------------------------
}
