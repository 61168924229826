/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EbTransactionAdjustment} from '../models/eb-transaction-adjustment.model';
import {EbTransactionAdjustmentList} from '../models/eb-transaction-adjustment-list.model';
import {EbTransactionAdjustmentFilter} from '../models/eb-transaction-adjustment-filter.model';
import {EbTransactionAdjustmentService} from '../services/eb-transaction-adjustment.service';
import {IEbTransactionAdjustment} from '../_generated/eb-transaction-adjustment.interface';
import {McGod} from "../extra/mc-god.service";

export class EbTransactionAdjustmentListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'amount', 'beneficiaryEntityId', 'createdDatetime', 'createdUserId', 'description', 'idEbSepaFileTransaction', 'idEbStarmoneyFile2SepaFileTransaction', 'paymentMethodCd', 'refId', 'refTypeCd', 'statusCd', 'typeCd', 'beneficiaryEntityName', 'createdBy', 'returnedIsoCode', 'returnedIsoCodeLabel', 'returnedTypeCd'];
  public items: EbTransactionAdjustment[] = [];
  public apiService: EbTransactionAdjustmentService;
 // protected tempObjList: EbTransactionAdjustmentList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbTransactionAdjustmentList {
    console.error('MTCN-ERROR: Not Implemented: EbTransactionAdjustmentList::createNewInstance(). Add this method to final class and return new EbTransactionAdjustmentList();');
    throw new Error('Cannot EbTransactionAdjustmentList::createNewInstance(). Add this method to final class and return new EbTransactionAdjustmentList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEbTransactionAdjustment[]): EbTransactionAdjustmentList {
    const  listObj: EbTransactionAdjustmentList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EbTransactionAdjustment = EbTransactionAdjustment.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EbTransactionAdjustmentList {
    const  listObj: EbTransactionAdjustmentList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbTransactionAdjustmentService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEbTransactionAdjustment[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EbTransactionAdjustment = EbTransactionAdjustment.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEbTransactionAdjustmentFilter: EbTransactionAdjustmentFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEbTransactionAdjustmentFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EbTransactionAdjustment {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EbTransactionAdjustment();
    }
  }

  public replaceItem(newItem: EbTransactionAdjustment) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  


// ---------------------------------------------------------------------
public callRecordPaymentForMultipleTransactions( transactionIds: any, ebTransactionAdjustment: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.recordPaymentForMultipleTransactions(transactionIds,ebTransactionAdjustment).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}


}

