import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  EbTransactionAdjustment,
  PmTransactionList,
  EbFactoringService, PmAdjustment, EbSepaTransactionService, CcMollieTransactionService, CurrencyUtil
} from '@miticon-ui/mc-core';
import {
  McForm,
  McGod,
  McTrigger,
  McValueLabelList
} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-shared-transaction-payment-add-edit-part',
  templateUrl: './app-shared-transaction-payment-add-edit-part.component.html',
})
export class AppSharedTransactionPaymentAddEditPartComponent implements OnInit, OnDestroy {

  mcForm: McForm = new McForm();
  saveSubscription!: Subscription;
  deleteSubscription!: Subscription;
  cancelSubscription!: Subscription;
  errorMsgs: string[] = [];
  errorMsg!: string;
  typeCdVll = new McValueLabelList();
  paymentAccountEntitiesVll = new McValueLabelList();
  transactionsAmount: string = '';
  numberOfTransactions = 0;
  mcGod = McGod.getInstance();
  diffEntitiesChosen!: boolean;
  currency!: string;
  paymentMethod!: string;
  pmAdjustment = new PmAdjustment();
  isTypeCredit!: boolean;
  selectedTransactionsRemainingAmount: number;
  saveButtonDisabled = false;

  // -- Input --
  @Input() ebTransactionAdjustment!: EbTransactionAdjustment;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;
  @Input() pmTransactions = new PmTransactionList();
  @Input() saveTrigger!: McTrigger;
  @Input() cancelTrigger!: McTrigger;
  @Input() deleteTrigger!: McTrigger;
  @Input() isParentEntity: boolean;

  @Output() eventSaved = new EventEmitter();
  @Output() eventApiError = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();

  constructor(private factoringService: EbFactoringService,
              private ebSepaTransactionService: EbSepaTransactionService,
              private ccMollieTransactionService: CcMollieTransactionService,
              private toastr: ToastrService) {
  }

  // ---------------------------------------------------------------------
  subscribeToTriggers() {
    if (this.saveTrigger) {
      this.saveSubscription = this.saveTrigger.subscribe(() => {
        this.actSave();
      });
    }
    if (this.cancelTrigger) {
      this.cancelSubscription = this.cancelTrigger.subscribe(() => {
        this.actCancel();
      });
    }
    if (this.deleteTrigger) {
      this.deleteSubscription = this.deleteTrigger.subscribe(() => {
        this.actDeleteItem();
      });
    }
  }

  // ---------------------------------------------------------------------
  unsubscribeFromTriggers() {
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.typeCdVll = PmAdjustment.getPaymentMethodCdVll();
    this.currency = this.pmTransactions.items[0].mcFactoring.mcCurrency.code;

    this.actLoad();
    this.subscribeToTriggers();

    if (this.pmTransactions.items) {
      this.numberOfTransactions = this.pmTransactions.items.length;
      let tempAmount = 0;
      this.pmTransactions.items.forEach(item => {
        tempAmount += item.amountDue;
      });
      this.selectedTransactionsRemainingAmount = tempAmount;
      this.transactionsAmount = CurrencyUtil.format(tempAmount, this.currency);
    }
  }

  // ---------------------------------------------------------------------
  ngOnDestroy(): void {
    this.unsubscribeFromTriggers();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    const entityIds: any[] = [];
    this.pmTransactions.items.forEach(item => {
      entityIds.push(item.idMcEntity);
    });
    this.paymentAccountEntitiesVll = this.pmTransactions.items[0].paymentAccountEntities.toVll('id', 'name');

    this.diffEntitiesChosen = !entityIds.every(item => item === this.pmTransactions.getLastItem().idMcEntity);

    if (this.diffEntitiesChosen) {
      this.errorMsg = 'You can only record multiple payments for transactions created on the same entity.';
    }
  }

  actSave() {
    this.saveButtonDisabled = true;
    if (this.mcForm.isValid()) {
      const chosenPaymentAmount = this.mcForm.getValue('ctrlPaymentAmount') ? parseInt(this.mcForm.getValue('ctrlPaymentAmount')) : 0;
      this.pmAdjustment.paymentMethodCd = this.mcForm.getValue('ctrlPaymentMethod');
      if (!this.isTypeCredit) {
        this.pmAdjustment.amount = this.mcForm.getValue('ctrlPaymentAmount');
      }
      this.pmAdjustment.description = this.mcForm.getValue('ctrlDescription');
      const body: any = {};

      body.paymentMethod = this.pmAdjustment.paymentMethodCd;
      body.amount = this.pmAdjustment.amount;
      body.description = this.pmAdjustment.description;
      this.isParentEntity && this.isTypeCredit || !this.isParentEntity && !this.isTypeCredit || !this.isParentEntity && this.isTypeCredit ?
        body.entityId = McGod.getLoggedEntityIdFromToken() : body.entityId = this.mcForm.getValue('ctrlPaymentEntity')
      body.ids = this.pmTransactions.getIds();

      if(chosenPaymentAmount <= this.selectedTransactionsRemainingAmount) {
        if (this.paymentMethod === PmAdjustment.PAYMENT_METHOD_CD_CREDIT_CARD) {
          this.ccMollieTransactionService.pay(body.ids, body.paymentMethod, body.amount, body.description, body.entityId).subscribe(res => {
            this.eventSaved.emit();
          }, error => {
            this.saveButtonDisabled = false;
            const err = JSON.parse(error.error);
            this.toastr.error(err.message, '', {timeOut: 4000, extendedTimeOut: 4000});
          });
        } else {
          this.ebSepaTransactionService.pay(body.ids, body.paymentMethod, body.amount, body.description, body.entityId).subscribe(res => {
            this.eventSaved.emit();
          }, error => {
            this.saveButtonDisabled = false;
            const err = JSON.parse(error.error);
            this.toastr.error(err.message, '', {timeOut: 4000, extendedTimeOut: 4000});
          });
        }
      } else {
        this.saveButtonDisabled = false;
        this.toastr.error(`${McGod.t('cc.transactions.record.payment.the-amount-you-entered-is-greater-than-the-total-debt-please-enter-the-correct-amount')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
      }
    }
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actDeleteItem() {
    if (this.ebTransactionAdjustment.exists()) {

      this.ebTransactionAdjustment.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

  validatePrerequisites() {
    this.errorMsgs = [];
  }

  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }

  getEntities() {
    const entities: any[] = [];
    if (this.diffEntitiesChosen) {
      this.pmTransactions.items.forEach(item => {
        entities.push(item.mcEntity.name);
      });
      return entities.join(', ');
    } else {
      return this.pmTransactions.items[0].mcEntity.name;
    }
  }

  onPaymentTypeValueChange(value: any) {
    this.paymentMethod = value;
    if (value === PmAdjustment.TYPE_CD_CREDIT) {
      this.isTypeCredit = true;
    } else {
      this.isTypeCredit = false;
    }
  }
}
