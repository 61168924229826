import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BaseDirective, McBoolean,McGod, McProduct, McProductFilter, SortCriteriaList, McProductService,} from "@miticon-ui/mc-core";
import {MkTableConfig, MkFilterConfig, MkMatMenuItem, MkFilterOutput, MkMatTableMenuAction} from "@miticon-ui/mc-components";
import {takeUntil} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'mc-product-product-list-page',
  templateUrl: './mc-product-product-list-page.component.html',
  encapsulation: ViewEncapsulation.None
})

export class McProductProductListPageComponent extends BaseDirective implements OnInit {
  mcProduct!: McProduct;
  mcProductFilter = new McProductFilter();
  totalItemsCount!: number;
  pageSize = 5;
  pageNumber= 0;
  isLoading = false;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  items: McProduct[] = [];
  searchTooltip = this.tS.instant('cc.products.search-tooltip');

  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.common.view.view'),
      actionCd: McProduct.ACTION_SHOW_PROFILE,
      matIcon: 'visibility',
      permission: McGod.PERM_PP_PRODUCT_VIEW
    },
    {
      title: this.tS.instant('cc.common.edit'),
      actionCd: McProduct.ACTION_SHOW_EDIT,
      matIcon: 'edit',
      permission: McGod.PERM_PP_PRODUCT_UPDATE,
    },
    {
      title: this.tS.instant('cc.common.delete'),
      actionCd: McProduct.ACTION_SHOW_DELETE,
      matIcon: 'delete',
      permission: McGod.PERM_PP_PRODUCT_UPDATE
    }
  ];

  showCreateCategoryMcb = new McBoolean();
  showCreateProductMcb = new McBoolean();
  showProductProfileMcb = new McBoolean();
  showDeleteProduct = new McBoolean();
  buttonsDeleteProductDialog = new Map<string, string>();
  productToDelete = new McProduct();

  constructor(private tS: TranslateService,
              private toastrService: ToastrService,
              private mcProductService: McProductService) {
    super();
  }

  ngOnInit() {
    this.buttonsDeleteProductDialog.set('b btn-primary', McGod.t('cc.common.edit.yes'));
    this.buttonsDeleteProductDialog.set('a btn-secondary', McGod.t('cc.common.edit.no'));

    this.initTableConfig();
    this.actLoad(this.pageNumber, this.pageSize, this.mcProductFilter);
  }

  initTableConfig(){
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.name'), 'name', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.product.view.unit-cost'), 'fldUnitPriceLoc()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.product.view.vat') + '(%)', 'fldVatRate()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('pp.product.charging-type'), 'getChargingType()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('pp.product.product-type'), 'getProductType()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.jobs.view.category'), 'fldCategory()', 250);
    this.tableConfig.addColumnSvgIcon(this.tS.instant('cc.common.status'), 'getStatusCd()');
  }

  actLoad(pageNumber: number, pageSize: number, mcProductFilter: McProductFilter): void {
    this.pageNumber=pageNumber;
    this.pageSize= pageSize;
    this.isLoading = true;
    const sortCriteriaList = new SortCriteriaList();

    this.mcProductService.getByFilter(mcProductFilter, this.pageNumber, this.pageSize, sortCriteriaList).subscribe((res) => {
      if(res){
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => McProduct.createFromJson(object));
      }
    })
  }

  onFilterChanged(filterOutput: MkFilterOutput){
    this.mcProductFilter.searchTerm = filterOutput.search ? filterOutput.search : '';
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, this.mcProductFilter);
  }

  onTableAction($event: MkMatTableMenuAction) {
    switch ($event.action.actionCd) {
      case McProduct.ACTION_SHOW_PROFILE:
        this.showProductProfileMcb.setTrue();
        this.mcProduct = $event.item;
        break;
      case McProduct.ACTION_SHOW_EDIT:
        this.showCreateProductMcb.setTrue();
        this.mcProduct = $event.item;
        break;
      case McProduct.ACTION_SHOW_DELETE:
        this.showDeleteProduct.setTrue();
        this.productToDelete = $event.item;
        break;
    }
  }

  onCreateCategoryCanceled() {
    this.showCreateCategoryMcb.setFalse();
  }

  onCreateCategorySaved() {
    this.showCreateCategoryMcb.setFalse();
    this.toastrService.success(this.tS.instant('mem.services.category-has-been-successfully-added'));
  }

  onCreateProductCanceled() {
    this.showCreateProductMcb.setFalse();
  }

  onCreateProductSaved(message: string) {
    this.showCreateProductMcb.setFalse();
    this.toastrService.success(message);
    this.actLoad(this.pageNumber, this.pageSize, this.mcProductFilter);
  }

  onCreateProduct() {
    this.showCreateProductMcb.setTrue();
    this.mcProduct = new McProduct();
  }

  onDeleteProductDialogButtonsClicked(value: string) {
    if (value === 'b btn-primary') {
      this.mcProductService.archive(this.productToDelete.id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response) => {
          if (response) {
            this.showDeleteProduct.setFalse();
            this.toastrService.success(this.tS.instant('cc.product.the-product-has-been-successfully-deleted'));
            this.actLoad(this.pageNumber, this.pageSize, this.mcProductFilter);
          }
        }, () => {
          this.showDeleteProduct.setFalse();
          this.toastrService.error(this.tS.instant('cc.product.error-occurred-the-product-has-not-been-deleted-please-try-again'))
        });
    } else {
      this.showDeleteProduct.setFalse();
    }
  }
}
