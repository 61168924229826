import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseDirective, McAuthService, McForm, McGod, McUser, McUserService, User} from '@miticon-ui/mc-core';
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'mc-user-user-resend-email',
  templateUrl: './mc-user-user-resend-email.component.html',
  styleUrls: ['./mc-user-user-resend-email.component.scss']
})
export class McUserUserResendEmailComponent extends BaseDirective implements OnInit {

  userName!: string;
  mcForm = new McForm();
  changeEmailFlg = false;
  errorMsg = '';
  isEmailUpdated!: boolean;
  @Input() user!: McUser;
  @Output() cancelEvent = new EventEmitter();
  @Output() onResendEmailEvent = new EventEmitter<boolean>();


  /*Constructor*/
  constructor(private authService: McAuthService,
              private mcUserService: McUserService) {
    super();
  }

  /*On init*/
  ngOnInit() {
  }

  onChangeEmailEmit(value: boolean) {
    this.changeEmailFlg = value;
  }

  onCancel() {
    this.cancelEvent.emit();
  }

  onResendEmail() {
    if (this.mcForm.isValid()) {

      let email = '';
      if (this.mcForm.getValue('ctrlChangeEmail')) {
        email = this.mcForm.getValue('ctrlEmail');
      }

      this.mcUserService.sendInvitationMail(this.user.id, McGod.getLoggedEntityIdFromToken(), email)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          if (this.mcForm.getValue('ctrlUpdateUserEmail')) {
            this.loadAndSaveUser(this.user, email);
          } else {
            this.onResendEmailEvent.emit(false);
          }
        }, error => {
          this.errorMsg = error.message;
        });

    }
  }

  loadAndSaveUser(user: McUser, email: string) {
    const userToUpdate = new McUser();
    userToUpdate.loadById(user.id, () => {
      userToUpdate.email = email;
      userToUpdate.save(() => {
        this.onResendEmailEvent.emit();
      }, () => {
        this.errorMsg = userToUpdate.apiErrorMessage;
      });
    }, () => {
      this.errorMsg = userToUpdate.apiErrorMessage;
    });
  }

  onUpdateEmailChecked(value:boolean) {
    this.isEmailUpdated = value;
  }
}
