import {Inject, Injectable} from '@angular/core';
import {ZzPocConsumerServiceGENERATED} from '../_generated/zz-poc-consumer-generated.service';



@Injectable({
  providedIn: 'root'
})
export class ZzPocConsumerService extends ZzPocConsumerServiceGENERATED {

}
