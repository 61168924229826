import {Component, Inject, Input, OnInit} from '@angular/core';
import {
  CmnEmail,
  McGod,
  McHtmlButton,
  McMenu,
  McProcess, McProcessNotification, McValueLabelList, PmAdjustmentNotificationInfo, PmAdjustmentNotificationInfoList, PmAdjustmentNotificationInfoTable3Config,
  WebFile, WebFileService
} from "@miticon-ui/mc-core";
import {ActivatedRoute, Router} from "@angular/router";
import {ICON_BACK} from "../../../../assets/media/svg_icons/icon-back";
import {McProcessEmailViewDialogComponent} from "../mc-process-email-view-dialog/mc-process-email-view-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ICON_DROP_DOWN, ICON_DROP_UP, ICON_FILTER} from "@miticon-ui/mc-components";
import {MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue} from "@miticon-ui/mc-components";
import {FormControl, FormGroup} from "@angular/forms";
import {HttpParams} from "@angular/common/http";
import {saveAs} from "file-saver";
import {Observable} from "rxjs";
import {debug} from "ng-packagr/lib/utils/log";
@Component({
  selector: 'lib-mc-process-process-email-list-page',
  templateUrl: './mc-process-process-email-list-page.component.html',
  styleUrls: ['./mc-process-process-email-list-page.component.scss']
})
export class McProcessProcessEmailListPageComponent implements OnInit {
  mcProcessId!: number;
  mcProcess = new McProcess();
  mcMenu = new McMenu();
  mcGod = McGod.getInstance();
  iconBack = ICON_BACK;
  emailObjectList = new PmAdjustmentNotificationInfoList();
  tableConfig = new PmAdjustmentNotificationInfoTable3Config();
  filterConfig: MkFilterConfig = new MkFilterConfig();
  panelOpenState = false;
  iconFilter = ICON_FILTER;
  dropUpIcon = ICON_DROP_UP;
  dropDownIcon = ICON_DROP_DOWN;
  searchForm = new FormGroup({});
  clearFilters = false;
  selections: any = {};
  searchToolTip = this.mcGod.t('cc.process-email.tooltip');

  constructor(private router: Router, private route: ActivatedRoute,
              @Inject('designConfig') design: any,
              private dialog: MatDialog,
              private webFileService: WebFileService
              ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      // @ts-ignore
      this.mcProcessId = +params.get('id');
      this.actLoad();
      this.initFilters();
    });
    this.searchForm.addControl('search', new FormControl());
  }

  initFilters() {
    const statusesList = new McValueLabelList();
    statusesList.add('PENDING', McGod.t('Pending'));
    statusesList.add('ERROR', McGod.t('Failed'));
    statusesList.add('SENT', McGod.t('Sent'));

    const statusFilter = statusesList.items.map((item) => new MkFilterValue(item.label, item.value));
    const typeFilter = McProcessNotification.getEventTypeCdVll().items.map((item) => new MkFilterValue(item.label, item.value));

    this.filterConfig.addItem(MkFilterItemType.DATEPICKER, McGod.t('cc.process-email.date-sent'));
    this.filterConfig.addItem(MkFilterItemType.SELECT, McGod.t('cc.common.status'), statusFilter);
    this.filterConfig.addItem(MkFilterItemType.SELECT, McGod.t('cc.jobs.edit.type'), typeFilter);
  }

  actGoToProcesses() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/processes/overview`]);
  }

  actLoad() {
    this.mcProcess.loadById(this.mcProcessId, () => {
      let params = new HttpParams();

      if (this.selections) {
        if (this.selections.Status) {
          params = params.set('status', this.selections.Status);
        }
        if (this.selections.Type) {
          params = params.set('type', this.selections.Type);
        }
        if (this.selections['Date sent']) {
          params = params.set('dateSent', this.selections['Date sent']);
        }
      }
      if(this.searchForm.get('search')?.value) {
        params = params.set('search', this.searchForm.get('search')?.value);
      }
      this.emailObjectList.loadByParams(params);
      this.mcMenu = new McMenu();
      this.mcMenu.addMenuItem('configuration', this.mcGod.t('cc.common.configuration'), '', [McGod.PERM_MC_PROCESS_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-profile/${this.mcProcessId}`);
      if(this.mcProcess.typeCd === "NOTIFICATIONS") {
        this.mcMenu.addMenuItem('email', this.mcGod.t('cc.common.edit.email'), '', [McGod.PERM_MC_PROCESS_ITEM_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-email/${this.mcProcessId}`, true);
      } else {
        this.mcMenu.addMenuItem('items', this.mcGod.t('cc.common.items'), '', [McGod.PERM_MC_PROCESS_ITEM_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-items/${this.mcProcessId}`);
        // this.mcMenu.addMenuItem('statistics', 'Statistics', '', [], ``);
        this.mcMenu.addMenuItem('runs', this.mcGod.t('cc.processes.runs'), '', [McGod.PERM_MC_PROCESS_RUN_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-runs/${this.mcProcessId}`);
      }
    });
  }

  onTableItemAction(mcButton: McHtmlButton) {
    if (mcButton.actionCdForEvent === PmAdjustmentNotificationInfo.ACTION_VIEW_EMAIL) {
      this.dialog.open(McProcessEmailViewDialogComponent, {
        height: '700px',
        width: '650px',
        panelClass: 'dialog',
        autoFocus: false,
        restoreFocus: false,
        data: {
          processName: this.mcProcess.title,
          emailBody: mcButton.item.emailBody
        }
      });
    } else if(mcButton.actionCdForEvent === PmAdjustmentNotificationInfo.ACTION_PREVIEW_PDF) {
      this.actShowPreview(mcButton.item.attachmentIds[0]);
    } else if(mcButton.actionCdForEvent === PmAdjustmentNotificationInfo.ACTION_DOWNLOAD_PDF) {
      this.actDownloadPdf(mcButton.item.attachmentIds[0]);
    }
  }

  onFilter() {
    this.clearFilters = false;
    this.actLoad();
  }

  onClearFilter() {
    this.searchForm.get('search')?.setValue('');
    this.selections = {};
    this.clearFilters = true;
    this.searchForm.reset();
    this.actLoad();
    setTimeout(() => {
      this.clearFilters = false;
    }, 1000);
  }

  onFilterSingleSelection(name: string, elem: any) {
    this.selections[name] = elem;
  }

  onFilterDatepickerValueChange(name: string, elem: any) {
    this.selections[name] = elem;
  }

  private actShowPreview(attachmentId: number){
    this.webFileService.getWebFileByEmailAttachmentId(attachmentId).subscribe(response => {
      const webFile: WebFile = new WebFile();
      webFile.populateFromJson(response);
      this.webFileService.generatePreviewByWebFileIdAndType(webFile.id, webFile.typeCd).subscribe(res => {
          const blob = new Blob([res], {type: 'application/pdf'});
          const url = URL.createObjectURL(blob);
          const newWindow = window.open(url, '_blank');
          newWindow?.document.write(`<html><head><title>EmailAttachment</title></head><body>
               <embed width="100%" height="100%" name="plugin" src="${url}"
              type="application/pdf" internalinstanceid="21"></body></html>`);
        });
    });
  }

  private actDownloadPdf(attachmentId: number) {
    this.webFileService.getWebFileByEmailAttachmentId(attachmentId).subscribe(response => {
      const webFile: WebFile = new WebFile();
      webFile.populateFromJson(response);
      this.webFileService.downloadFile(webFile.id, webFile.typeCd).subscribe((file:any) => {
        saveAs(file, webFile.originalFilename);
      });
    });
  }
}
