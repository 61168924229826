
import {Table3Config} from '../extra/table-3-config';

// import {McProductSnapshotGENERATED} from '../_generated/mc-product-snapshot-generated.model';

export class McProductSnapshotTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAvailableFrom', 'avai from', 'availableFrom', 'availableFrom');
    this.addColumn('colAvailableTo', 'avail to', 'availableTo', 'availableTo');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colExternalId', 'External Id', 'externalId', 'externalId');
    this.addColumn('colIdCurrency', 'Currency id', 'idCurrency', 'idCurrency');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdPpCategory', 'Category Id', 'idPpCategory', 'idPpCategory');
    this.addColumn('colIdPpProduct', 'Product id', 'idPpProduct', 'idPpProduct');
    this.addColumn('colIdVat', 'Vat id', 'idVat', 'idVat');
    this.addColumn('colMcCurrenty', 'Currency id', 'mcCurrenty', 'mcCurrenty');
    this.addColumn('colMcVat', 'Vat id', 'mcVat', 'mcVat');
    this.addColumn('colName', 'Name', 'name', 'name');
    this.addColumn('colPpProduct', 'Product', 'ppProduct', 'ppProduct');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colUnitPrice', 'Unit price', 'unitPrice', 'unitPrice');
    this.addColumn('colVersion', 'Version', 'version', 'version');

*/
  }
}
