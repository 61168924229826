/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McActionPdfTemplate} from '../models/mc-action-pdf-template.model';
import {McActionPdfTemplateService} from '../services/mc-action-pdf-template.service';
import {IMcActionPdfTemplate} from '../_generated/mc-action-pdf-template.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McActionPdfTemplateGENERATED extends BaseObject {


    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_INACTIVE: string = 'INACTIVE';
  public static readonly STATUS_CD_LIST__ALL = [
    McActionPdfTemplateGENERATED.STATUS_CD_ACTIVE,
    McActionPdfTemplateGENERATED.STATUS_CD_INACTIVE
];

    public static readonly TYPE_CD_DUNNING: string = 'DUNNING';
    public static readonly TYPE_CD_REMINDER: string = 'REMINDER';
  public static readonly TYPE_CD_LIST__ALL = [
    McActionPdfTemplateGENERATED.TYPE_CD_DUNNING,
    McActionPdfTemplateGENERATED.TYPE_CD_REMINDER
];

  public apiService: McActionPdfTemplateService;
  public _rawJson: IMcActionPdfTemplate;
    id: number = 0;
  description = '';
  statusCd: string;
  title = '';
  typeCd: string;


  public properties: string[] = ['id', 'description', 'statusCd', 'title', 'typeCd'];
  public propertiesRegular: string[] = ['id', 'description', 'statusCd', 'title', 'typeCd'];
  public propertiesSpecial: string[] = [];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McActionPdfTemplateGENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(McActionPdfTemplateGENERATED.STATUS_CD_INACTIVE, McGod.t('Inactive'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McActionPdfTemplateGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McActionPdfTemplateGENERATED.TYPE_CD_DUNNING, McGod.t('Dunning'));
    list.add(McActionPdfTemplateGENERATED.TYPE_CD_REMINDER, McGod.t('Reminder'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = McActionPdfTemplateGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McActionPdfTemplate {
    console.error('MTCN-ERROR: Not Implemented: McActionPdfTemplate::createNewInstance(). Add this method to final class and return new McActionPdfTemplate();');
    throw new Error('Cannot McActionPdfTemplate::createNewInstance(). Add this method to final class and return new McActionPdfTemplate();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcActionPdfTemplate): McActionPdfTemplate {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McActionPdfTemplateService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McActionPdfTemplate AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return McActionPdfTemplateGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === McActionPdfTemplateGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdINACTIVE(): boolean {
    const result = (this.statusCd === McActionPdfTemplateGENERATED.STATUS_CD_INACTIVE);

    return result;
  }


  public getTypeCdLabel(): string {
    return McActionPdfTemplateGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdDUNNING(): boolean {
    const result = (this.typeCd === McActionPdfTemplateGENERATED.TYPE_CD_DUNNING);

    return result;
  }

 public  isTypeCdREMINDER(): boolean {
    const result = (this.typeCd === McActionPdfTemplateGENERATED.TYPE_CD_REMINDER);

    return result;
  }


  
}
