<div class="valign-container full-height mc-login-area" [ngStyle]="{'background-image': 'url(' + welcomeImg + ')'}">
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div class="card mc-login-card">
          <div class="text-center mc-login-header">
            <img [src]="logo" alt="">
          </div>
          <div class="card-body mc-login-card-body">
            <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
            <form [formGroup]="loginForm">
              <div class="mc-login-password-fields">
                <div class="form-label-group mc-login-input">
                  <label for="username"></label>
                  <input type="text"
                         name="username"
                         formControlName="username"
                         class="form-control"
                         [ngClass]="inputFieldsError ? 'mc-error-input-wrapper' : ''"
                         id="username"
                         placeholder="E-mail"
                         required
                         (keyup)="validateLogin()"
                          (keyup.enter)="login()">
                </div>
                <div class="form-label-group mc-login-input mc-input-password">
                  <label for="password"></label>
                  <input [type]="seePassword ? 'text' : 'password'"
                         class="form-control"
                         [ngClass]="inputFieldsError ? 'mc-error-input-wrapper' : ''"
                         id="password"
                         name="password"
                         formControlName="password"
                         placeholder="Password"
                         (keyup.enter)="login()"
                         required
                         />
                  <mc-see-password (seePasswordOnEyeBtn)="onChangeSeePassword($event)"></mc-see-password>
                </div>
              </div>
              <div class="col-md-12 text-center mc-login-btn-area">
                <button class="btn btn-primary boarding-btn"
                        type="button"
                        id="loginBtn"
                        [disabled]="!isFormValid || loginForm.invalid"
                        (click)="login()">
                  {{'cc.common.view.log-in' | translate}}
                </button>
              </div>
              <div class="row mc-login-loginAlternatives">
                <div class="col mc-divider">
                  <a routerLink="/auth/forgot-password" class="float-right" id="forgotPassword">
                   {{ 'cc.common.view.forgot-password' | translate }}
                  </a>
                </div>
                <div class="col mc-keep-me-logged">
                  <div class="form-group">
                    <label for="rememberMe" class="custom-control fill-checkbox">
                      <input type="checkbox" class="fill-control-input" id="rememberMe" [checked]="isKeepMeLoggedInChecked" (click)="rememberMeChb($event)" />
                      <span class="fill-control-indicator"></span>
                      <span class="fill-control-description">{{'cc.common.view.keep-me-logged-in' | translate}}</span>
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
<!--      <div class="col-md-6 offset-md-3" *ngIf="loginMessage === 'mfa_required'">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <img [src]="logo" alt="" height="50" style="margin-bottom: 10px">-->
<!--            <h6 style="margin-bottom: 30px">Please download app for authentication and scan the code</h6>-->
<!--            <div class="row" *ngIf="mfaOTP !== 'undefined'">-->
<!--              <div class="col-md-6 text-left" style="padding-bottom: 30px">-->
<!--                <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"-->
<!--                   target="_blank">-->
<!--                  <i class="fab fa-apple" style="font-size: 100px; margin-right: 20px; vertical-align: middle;"></i>-->
<!--                </a>-->
<!--                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"-->
<!--                   target="_blank">-->
<!--                  <i class="fab fa-android" style="font-size: 100px; vertical-align: middle;"></i>-->
<!--                </a>-->
<!--              </div>-->
<!--              <div class="col-md-6 text-right">-->
<!--                <qrcode [qrdata]="mfaOTP" [size]="100" [level]="'M'"-->
<!--                        style="margin-right: 20px;vertical-align: middle;"></qrcode>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="row ">-->
<!--              <form (submit)="loginMFA(loginMFAForm)" class="col-md-12" #loginMFAForm="ngForm">-->
<!--                <div class="form-label-group">-->
<!--                  <input type="text" name="code" class="form-control" id="code" ngModel-->
<!--                         #codeInput="ngModel" required>-->
<!--                  <label for="code">Enter code provided by authentication app</label>-->
<!--                </div>-->
<!--                <div class="form-group">-->
<!--                  <a href="#" class="btn btn-primary boarding-btn">Login</a>-->
<!--                </div>-->
<!--              </form>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-md-12" *ngIf="loginMessage === 'select_entity_required'">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <img [src]="logo" alt="" height="50" style="margin-bottom: 50px">-->
<!--            <ul class="entities-list">-->
<!--              <ng-template #recursiveList let-entities>-->
<!--                <li *ngFor="let item of entities">-->
<!--                  <span (click)="entityLogin(item.entityId)">{{item.name}}</span>-->
<!--                  <ul *ngIf="item.children.length > 0">-->
<!--                    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.children }"></ng-container>-->
<!--                  </ul>-->
<!--                </li>-->
<!--              </ng-template>-->
<!--              <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: entities }"></ng-container>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</div>
<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>

