<ng-container>
  <div class="content-wrapper">
    <div class="content-header">
      <h1>{{'cc.factoring.imported-sepa.import-sepa-file' | translate}}</h1>
    </div>
    <div class="content-body">
      <div class="row" [formGroup]="importSepaFileFormGroup">

        <lib-mc-loader [showLoader]="showLoaderFlg"></lib-mc-loader>


        <div class="col-md-12 position-relative mt-1">
          <div class="mc-create-user-notify-wrapper">
            <div class="w-100 mc-notify-message-wrapper mc-background-loading mc-text-align-center py-1" *ngIf="webFile.apiLoadingFlg">{{'cc.my-factoring.loading-file-please-wait' | translate}}</div>
            <div *ngIf="message" class="mc-message-info">
              <mc-notify-message [message]="message" [type]="type"></mc-notify-message>
            </div>
          </div>
        </div>

        <div *ngIf="activeFactoring" class="col-md-12 mt-5">
          <div class="input-group mb-3">

            <span>{{'cc.my-factoring.please-choose-sepa-xml-file-with-standard-utf8-encoding' | translate}}</span>

          </div>
        </div>

        <div *ngIf="activeFactoring" class="col-md-12 mt-5">
          <div class="input-group mb-3">

            <lib-mcc-fi-file-upload [acceptProperty]="'.xml'" (eventFileSelected)="receiveFile($event)"></lib-mcc-fi-file-upload>

          </div>
        </div>

        <div *ngIf="activeFactoring" class="col-md-12 pt-4 d-flex">
          <mcc-fi-radio-buttons class="mr-5" name="useCollectionDateFromSepa"
                                label="Collection date handling:"
                                [extraCssClasses]=""
                                [validRequiredFlg]="true"
                                [mcForm]="mcForm"
                                [directionHorizontal]="false"
                                [labelOnTop]="false"
                                (eventValueChanged)="onRadioValueChanged($event)"
                                [optionsVlm]="radioButtonsVlm"></mcc-fi-radio-buttons>
          <mcc-fi-date-picker [extraCssClasses]="disableInputValue ? 'mc-disable-button' : ''" label="{{'cc.out-factoring.requested-collection-date' | translate}}"
                              name="requestCollectionDate"
                              [validRequiredFlg]="datePickerRequiredFlg"
                              [minDateInput]="minDateInput"
                              [maxDateInput]="maxDateInput"
                              [mcForm]="mcForm">
          </mcc-fi-date-picker>
        </div>
        <!--<div *ngIf="activeFactoring.exists()" class="col-md-12 mt-4 pt-1 d-flex align-items-center">
          <label class="mb-0 mc-field-width-30">{{'cc.my-factoring.please-pick-service-period' | translate}}: </label>
          <input
            class="form-control"
            type="radio"
            id="nextMonth"
            formControlName="monthOfPerformance"
            value="nextMonth">
          <label for="nextMonth" class="mb-0 mx-2">{{'cc.my-factoring.next-month' | translate}}<br><span class="font-italic">({{nextMonthLabel}})</span></label>
          <input
            class="form-control"
            type="radio"
            id="currentMonth"
            formControlName="monthOfPerformance"
            value="currentMonth">
          <label for="currentMonth" class="mb-0 mx-2">{{'cc.my-factoring.current-month' | translate}}<br><span class="font-italic">({{currentMonthLabel}})</span></label>
          <input
            class="form-control"
            type="radio"
            id="lastMonth"
            formControlName="monthOfPerformance"
            value="lastMonth">
          <label for="lastMonth" class="mb-0 mx-2">{{'cc.common.date.last-month' | translate}}<br><span class="font-italic">({{lastMonthLabel}})</span></label>
          <input
            class="form-control"
            type="radio"
            id="twoMonthsAgo"
            formControlName="monthOfPerformance"
            value="twoMonthsAgo">
          <label for="twoMonthsAgo" class="mb-0 mx-2">{{'cc.my-factoring.two-months-ago' | translate}}<br><span class="font-italic">({{twoMonthsAgoLabel}})</span></label>
        </div>-->

        <div *ngIf="activeFactoring" class="col-md-12 mt-3">
          <label class="custom-control fill-checkbox mc-cursor-pointer mb-3">
            <input type="checkbox"
                   class="fill-control-input"
                   formControlName="overWriteCB">
            <span class="fill-control-indicator"></span>
            <span>{{'cc.my-factoring.overwrite-existing-file-for-this-time-period' | translate}}</span>
          </label>
          <i class="fas fa-info-circle mc-tooltip-top-margin ml-1 mt-1"
             ngbTooltip="{{'cc.my-factoring.only-one-sepa-xml-file-should-be-uploaded-per-month-if-you-upload-another-file-for the same month-it-will-overwrite-the-existing-SEPA-transaction-data' | translate}}." placement="right-bottom"></i>
      </div>
      </div>
    </div>
    <div class="content-footer">
      <button class="btn btn-secondary move-right" (click)="actCancel()">
        {{'cc.common.edit.cancel' | translate}}
      </button>
      <button class="btn btn-primary" (click)="actUpload()">
        {{'cc.consumers.consumers-overview.upload' | translate}}
      </button>
    </div>
  </div>
</ng-container>
