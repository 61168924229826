import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  EbFactoring,
  EbFactoringList,
  McConsumersService,
  McUtilityService,
  WebFile,
  McDateUtils,
  EbSepaFile
} from '@miticon-ui/mc-core';
import {FormBuilder, FormGroup} from '@angular/forms';
import _moment from 'moment';

@Component({
  selector: 'lib-mc-import-sepa-xml',
  templateUrl: './mc-import-sepa-xml.component.html'
})
export class McImportSepaXmlComponent implements OnInit {

  private modalOpenSubscription: any;
  webFile!: WebFile;
  file!: File;
  importSepaFileFormGroup!: FormGroup;
  attributesInfoIcon: any;
  attributesIcons: any;
  servicePeriodDate: any;
  datetoSend = '2019-10-01T12:30:41.830Z';
  factoringList!: EbFactoringList;
  activeFactoring!: EbFactoring;
  message!: string;
  type!: string;
  ebSepaFile!: EbSepaFile;
  @Output() refreshTable = new EventEmitter();

  nextMonthLabel!: string;
  lastMonthLabel!: string;
  currentMonthLabel!: string;
  twoMonthsAgoLabel!: string;

  constructor(private modalService: NgbModal,
              private consumersService: McConsumersService,
              private utilityService: McUtilityService,
              private formBuilder: FormBuilder,
              @Inject('designConfig') design: any) {
    this.attributesIcons = design.attributesIcons;
    this.attributesInfoIcon = this.attributesIcons.info.path;
  }

  ngOnInit() {
    this.activeFactoring = new EbFactoring();
    this.factoringList = new EbFactoringList();
    const entityId = this.utilityService.getPropertyFromToken('entity_id');
    this.factoringList.loadByChildEntityId(entityId, () => {
      this.activeFactoring = this.factoringList.getActiveItem();
      if (this.activeFactoring.exists()) {
        this.message = '';
        this.type = '';
      } else {
        this.message = 'You can\'t import SEPA file, since there is no active factoring.';
        this.type = 'error';
      }
    });

    this.webFile = new WebFile();

    this.importSepaFileFormGroup = this.formBuilder.group({
      monthOfPerformance: ['nextMonth'],
      overWriteCB: [false]
    });
  }

  changeSendDate() {
      if (this.importSepaFileFormGroup.get('monthOfPerformance')?.value === 'currentMonth') {
        this.datetoSend = _moment().format().substring(0, 8) + this.datetoSend.substring(8);
      } else if (this.importSepaFileFormGroup.get('monthOfPerformance')?.value === 'lastMonth') {
        this.datetoSend =  _moment().subtract(1, 'months').format().substring(0, 8) + this.datetoSend.substring(8);
      } else if (this.importSepaFileFormGroup.get('monthOfPerformance')?.value === 'twoMonthsAgo') {
        this.datetoSend =  _moment().subtract(2, 'months').format().substring(0, 8) + this.datetoSend.substring(8);
      } else if (this.importSepaFileFormGroup.get('monthOfPerformance')?.value === 'nextMonth') {
        this.datetoSend =  _moment().add(1, 'months').format().substring(0, 8) + this.datetoSend.substring(8);
      }
  }

  // Modal
  openImportSepaFileModal(modalName: any) {
    this.currentMonthLabel = McDateUtils.formatDateToString(McDateUtils.getNowTimestamp(), 'MMMM, YYYY');
    this.nextMonthLabel = McDateUtils.addOrSubtractDateToString(McDateUtils.getNowTimestamp(), 'add', 1, 'MMMM, YYYY', 'months');
    this.lastMonthLabel = McDateUtils.addOrSubtractDateToString(McDateUtils.getNowTimestamp(), 'subtract', 1, 'MMMM, YYYY', 'months');
    this.twoMonthsAgoLabel = McDateUtils.addOrSubtractDateToString(McDateUtils.getNowTimestamp(), 'subtract', 2, 'MMMM, YYYY', 'months');

    this.modalOpenSubscription = this.modalService.open(modalName, {
      backdrop: 'static',
      centered: true,
      windowClass: 'mc-add-consumer-modal',
      beforeDismiss: () => {
        this.importSepaFileFormGroup.get('monthOfPerformance')?.setValue('nextMonth');
        this.importSepaFileFormGroup.get('overWriteCB')?.setValue(false);
        this.message = '';
        this.type = '';
        return true;
      }
    });
  }

  actCancel() {
    this.modalOpenSubscription.dismiss();
  }
  actUpload() {
    const relativePath = 'import-sepa';
    if (this.file != null) {
      this.changeSendDate();
      const dataBody = {
        file: this.file,
        aRelativePath: relativePath
      };
      this.webFile.upload(dataBody , WebFile.TYPE_CD_EB_BANK_SEPA_IMPORT, () => {
        const idWebFile = this.webFile.id;
        // const servicePeriod = McDateUtils.formatDateToTimestamp(this.datetoSend);
        const overWriteCB = this.importSepaFileFormGroup.get('overWriteCB')?.value;

        this.ebSepaFile = new EbSepaFile();
        this.ebSepaFile.idWebfile = idWebFile;
        // this.ebSepaFile.servicePeriod = servicePeriod;
        this.ebSepaFile.idMcEntity = this.utilityService.getPropertyFromToken('entity_id');
        // this.ebSepaFile.overWriteCB = overWriteCB;
        this.ebSepaFile.callImportFile(overWriteCB, this.ebSepaFile, () => {
          this.modalOpenSubscription.dismiss();
          this.refreshTable.emit();
        }, () => {
          this.message = this.ebSepaFile.apiErrorMessage || 'An error has occured while importing SEPA file.';
          this.type = 'error';
        });
      }, () => {
        this.message = this.webFile.apiErrorMessage || 'An error has occured while uploading SEPA file.';
        this.type = 'error';
      });
    }
  }

  receiveFile(file: File) {
    this.file = file;
  }

}
