import {Inject, Injectable} from '@angular/core';
import {McProcessServiceGENERATED} from '../_generated/mc-process-generated.service';
import {Observable} from "rxjs";
import {HttpHeaders} from "@angular/common/http";
import {SortCriteriaList} from "../_core/sort-criteria-list.model";



@Injectable({
  providedIn: 'root'
})
export class McProcessService extends McProcessServiceGENERATED {

  public scheduleReminderJob(reminderJobDto: any): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/schedule-reminder-job`, reminderJobDto, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  public getValidEntitiesObjectsForRemindersAutomation(): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/automation-valid-entities`,{
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  public getMcJobDetails(jobInfoId: number, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + `/mcprocess/v4/mcprocessjobexecution/get-by-scheduler-job-info-id/${jobInfoId}?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`,{
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  public getSuccessRunEntitiesByJobExecutionId(jobExecId: number, aPageNo: number, aItemsPerPage: number): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + `/mcprocess/v4/mcprocessjobexecution/get-success-run-entities-by-job-execution-id/${jobExecId}?page=${aPageNo}&size=${aItemsPerPage}`,{
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  public getFailedRunEntitiesByJobExecutionId(jobExecId: number, aPageNo: number, aItemsPerPage: number): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + `/mcprocess/v4/mcprocessjobexecution/get-failed-run-entities-by-job-execution-id/${jobExecId}?page=${aPageNo}&size=${aItemsPerPage}`,{
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  public getSkippedRunItemsByProcessJobExecutionId(jobExecId: number, aPageNo: number, aItemsPerPage: number): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + `/mcprocess/v4/mcprocessjobexecution/get-skipped-emails-by-job-execution-id/${jobExecId}?page=${aPageNo}&size=${aItemsPerPage}`,{
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  public getErrorRunItemsByProcessJobExecutionId(jobExecId: number, aPageNo: number, aItemsPerPage: number): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + `/mcprocess/v4/mcprocessjobexecution/get-error-emails-by-job-execution-id/${jobExecId}?page=${aPageNo}&size=${aItemsPerPage}`,{
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }
}
