<div class="d-flex">
  <mcc-fi-select *ngIf="index > 0" [mcForm]="mcForm" name="criteriaOperator{{id}}"
                 [optionsVll]="criteriaOperatorVll" [value]="'AND'"
                 (eventValueChanged)="flowCriteriaOperatorSelectChanged($event)" [validRequiredFlg]="true"></mcc-fi-select>

  <mcc-fi-select class="mx-2" [mcForm]="mcForm" name="attributeType{{id}}" placeholderOption="attribute"
                 [optionsVll]="attributesVll" [value]="mcProcessFlowCriteria.attributeName ? mcProcessFlowCriteria.attributeName : ''"
                 (eventValueChanged)="flowCriteriaAttributeSelectChanged($event)" [validRequiredFlg]="true"></mcc-fi-select>

  <mcc-fi-select [mcForm]="mcForm" name="operatorType{{id}}" placeholderOption="operatorType" [value]="mcProcessFlowCriteria.attributeOperatorTypeCd ? mcProcessFlowCriteria.attributeOperatorTypeCd : ''"
                 [optionsVll]="operatorTypeCdVll" (eventValueChanged)="flowCriteriaOperatorTypeChanged($event)" [validRequiredFlg]="true"></mcc-fi-select>

  <mcc-fi-textbox class="mx-2" [placeholder]="'value'" name="value{{id}}" [size]="20" [value]="mcProcessFlowCriteria.value"
                  [mcForm]="mcForm" (eventValueChanged)="flowCriteriaValueChanged()" [validRequiredFlg]="true"></mcc-fi-textbox>

  <mcc-fi-select [mcForm]="mcForm" name="optionType{{id}}" placeholderOption="optionType" [value]="mcProcessFlowCriteria.attributeOptionTypeCd ? mcProcessFlowCriteria.attributeOptionTypeCd : ''"
                 [extraCssClass]="optionTypeVll?.items?.length < 2 ? 'mc-disable-button' : ''" [validRequiredFlg]="optionTypeRequiredFlg"
                 [optionsVll]="optionTypeVll" (eventValueChanged)="flowCriteriaOptionTypeChanged($event)"></mcc-fi-select>
</div>
