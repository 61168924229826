/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McEbicsTable3ConfigGENERATED} from '../_generated/mc-ebics-table-3-config-generated.model';

// import {McEbicsGENERATED} from '../_generated/mc-ebics-generated.model';

export class McEbicsTable3Config extends McEbicsTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'fldMyActions()', '');
    this.addColumn('colBankName',  this.t('cc.common.bank-name'), 'bankName', 'bankName');
    this.addColumn('colAccountNumber', this.t('cc.common.account-number'), 'accountNumber', 'accountNumber');
    this.addColumn('colIban', this.t('cc.common.iban'), 'iban', 'iban');
    this.addColumn('colAccountOwner', this.t('cc.common.account-owner'), 'accountOwner', 'accountOwner');
    this.addColumn('colStatus', this.t('cc.common.view.status'), 'status', 'status');
  }
}
