import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({

  selector: 'mc-dropdown',
  template: `
    <div class="col-md-4 pb-2 mw-100 select-wrapper">
      <select class="form-control" (change)="onChangeSelectOption()">
        <option *ngFor="let item of setup.list; let i = index" ngValue="{{item}}" [selected]="selectedValue">{{item}}</option>
      </select>
    </div>`,
})
export class McDropdownComponent implements OnInit {

  /*Public variables*/
  selectedValue!: any;

  /*Events*/
  @Input() setup!: any;
  @Input() config!: any;
  @Output() newSetup = new EventEmitter();

  constructor() {
  }

  /*On init class*/
  ngOnInit() {
    this.selectedValue = this.setup.value;
  }

  /*On change selected option*/
  onChangeSelectOption() {
    this.setup.value = this.selectedValue;
  }
}
