<h3 class="page-title">{{ 'cc.data-export' | translate }}</h3>

<div class="actions-container">
  <span>{{ 'cc.data-export.reports-list' | translate }}</span>
  <div class="actions-wrapper">
    <button (click)="export()" class="system-action-btn mr-2" style="gap: 10px">
      <span [innerHTML]="iconDataExport | safeHtml"></span>
      {{ 'cc.export-data' | translate}}
    </button>
  </div>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [totalItemsCount]="totalItemsCount"
  [itemsPerPage]="pageSize"
  [showCheckbox]="false"
>
</mk-mat-table>
