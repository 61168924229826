<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcProduct.apiLoadingFlg"></lib-mc-loader>

  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header">
      <h1 *ngIf="mcProduct.exists()">{{'cc.product.product-details' | translate}}</h1>
  </div>

  <!--THIS CONTAINER IS MANDATORY-->
  <div *ngIf="!mcProduct.apiLoadingFlg" class="content-body">
    <mcc-message [errorMsg]="mcProduct.apiErrorMessage"></mcc-message>

    <!-- == MAIN CONTENT ======================================== -->
    <ng-container *ngIf="mcProduct.apiSuccessFlg">

      <div class="row info-data title-right">


          <div class="col-md-6">
            <div class="content-section">
              <h2>{{'cc.consumer.overview.status-information' | translate}}</h2>

              <mcc-row-title-description title="{{'cc.common.view.status' | translate}}:" [description]="mcProduct.state"></mcc-row-title-description>
              <mcc-row-title-description title="{{'cc.common.edit.external-id' | translate}}:" [description]="mcProduct.externalId"></mcc-row-title-description>
            </div>

            <div class="content-section">

              <h2>
                Basic information
              </h2>
              <mcc-row-title-description title="{{'cc.common.name' | translate}}:" [description]="mcProduct.name"></mcc-row-title-description>
              <mcc-row-title-description title="{{'cc.common.view.description' | translate}}:" [description]="mcProduct.description"></mcc-row-title-description>
              <mcc-row-title-description title="{{'cc.product.view.unit-cost' | translate}}:" [description]="format(mcProduct.unitPrice)"></mcc-row-title-description>
              <mcc-row-title-description title="{{'cc.countries.vat-rate' | translate}}:" [description]="mcProduct.mcVat.vatRate.toFixed(2).replace('.', ',') + '%' + ' - ' + mcProduct.mcVat.getVatTypeCdLabel()"></mcc-row-title-description>

            </div>
          </div>

        <div class="col-md-6">
          <div class="content-section">
            <h2>Additional information</h2>
           <!-- <mcc-row-title-description         title="{{'cc.product.view.units-in-stock' | translate}}:" [description]="mcProduct.unitsInStock"></mcc-row-title-description>
            <mcc-row-title-description         title="{{'cc.common.view.available-from' | translate}}:" [description]="mcProduct.fldAvailableFrom()"></mcc-row-title-description>
            <mcc-row-title-description         title="{{'cc.common.view.available-to' | translate}}:" [description]="mcProduct.fldAvailableTo()"></mcc-row-title-description>-->
            <mcc-row-title-description title="{{'cc.jobs.view.category' | translate}}:" [description]="mcProduct.fldCategory()"></mcc-row-title-description>
          </div>
        </div>

            </div>

    </ng-container>

  </div><!-- content-body -->

  <!-- == FOOTER ======================================== -->
  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-footer">
    <button type="button" class="btn btn-secondary move-right" (click)="actClose()">{{'cc.common.close' | translate}}</button>

  </div>

</div>

