import {McBillingPayoutFile2StatementFilterGENERATED} from '../_generated/mc-billing-payout-file-2-statement-filter-generated.model';

export class McBillingPayoutFile2StatementFilter extends McBillingPayoutFile2StatementFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingPayoutFile2StatementFilter();
  }

  // ---------------------------------------------------------------------
}
