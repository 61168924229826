import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbDateAdapter, NgbDateNativeAdapter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import _moment from 'moment';
import {McJobsService} from '@miticon-ui/mc-core';

@Component({
  selector: "mc-job-create-edit-modal",
  templateUrl: "./mc-job-create-edit-modal.component.html",
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class McJobCreateEditModalComponent {
  ONE_TIME_flag = "ONE_TIME";
  registerForm!: FormGroup;
  isFormSubmitted = false;
  cronexString!: string;
  errorObject = {
    type: "",
    message: ""
  };
  public dateObject = {
    date: "",
    time: ""
  };
  public dateMinValue = {
    year: Number(_moment().format("YYYY")),
    month: Number(_moment().format("MM")),
    day: Number(_moment().format("DD"))
  };
  cronToChild!: string;

  /*Private variables*/
  private modalOpenSubscription!: any;
  date!: any;
  editHours!: any;

  /*Events*/
  @Input() editJob!: any;
  @Output() onSuccessCreateEditJob = new EventEmitter<boolean>();




  /*Constructor*/
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private jobService: McJobsService
  ) {

  }

  /*Open modal*/
  openModal(modalName: any) {
    this.modalOpenSubscription = this.modalService.open(modalName, {
      centered: true,
      size: "lg",
      windowClass: "mc-job-create-edit-modal",
      backdrop: "static",
      beforeDismiss: () => {
        this.isFormSubmitted = false;
        this.errorObject = Object.assign({}, {type: "", message: ""});
        return true;
      }
    });
    this.dateObject = {
      date: new Date().toISOString().split("T")[0],
      time: _moment().format("HH:mm")
    };
    this.createForm();
    // @ts-ignore
    this.editHours = _moment(this.editJob["scheduleTime"]).format("HH:mm");

    // @ts-ignore
    this.registerForm.get("date")?.setValue(this.editJob ? new Date(this.editJob["scheduleTime"]) : this.dateObject.date);
    this.registerForm.get("time")?.setValue(this.editJob ? this.editHours : this.dateObject.time);
  }

  /*Create form*/
  private createForm() {
    this.registerForm = this.formBuilder.group({
      jobClass: [this.editJob && this.editJob["type"] || "SINGLE_MAIL", Validators.required],
      name: [this.editJob && this.editJob["name"] || "", Validators.required],
      description: [this.editJob && this.editJob["description"] || ""],
      jobType: [this.editJob && this.editJob["jobType"] || this.ONE_TIME_flag, Validators.required],
      time: [this.editJob && this.editJob["scheduleTime"] || this.dateObject.time, Validators.required],
      date: [this.editJob && this.editJob["scheduleTime"] || this.dateObject.date, Validators.required],
      startTime: [this.editJob && this.editJob["startTime"] || this.dateObject.time, Validators.required],
      cronExpression: [this.editJob && this.editJob["cronExpression"] || null]
    });
    if (this.editJob) {
      this.cronToChild = this.editJob["cronExpression"];
    }
  }

  /*On save changes*/
  onSave(): any {
    if (!this.editJob) {

      this.createUpdateValidation();

      /*Check if form is valid*/
      if (this.registerForm.invalid) {
        // eslint-disable-next-line max-len
        const invalidField = Object.keys(this.registerForm.value).filter(inputField => this.registerForm.controls[inputField].invalid).join();
        return this.errorObject = Object.assign({}, {type: "error", message: `Please fill ${invalidField || "mandatory"} field(s).`});
      }

      /*Create job*/
      this.jobService.createJob(this.registerForm.value)
        .subscribe(response => {
          this.registerForm.reset();
          this.onSuccessCreateEditJob.emit(true);
          this.modalOpenSubscription.close();
        }, error => {
          if (error && error.hasOwnProperty("error")) {
            this.errorObject = Object.assign({}, {
              message: error.error.message,
              type: "error"
            });
          } else {
            this.errorObject = Object.assign({}, {
              message: "Error while creating job.",
              type: "error"
            });
          }
        })
        .add(() => this.isFormSubmitted = false);
    } else {

      this.createUpdateValidation();
      this.registerForm.controls["date"].setValue(new Date(this.registerForm.get("date")?.value));
      /*Check if form is valid*/
      if (this.registerForm.invalid) {
        // eslint-disable-next-line max-len
        const invalidField = Object.keys(this.registerForm.value).filter(inputField => this.registerForm.controls[inputField].invalid).join();
        return this.errorObject = Object.assign({}, {type: "error", message: `Please fill ${invalidField || "mandatory"} field(s).`});
      }

      /*Update Job*/
      this.jobService.updateJobData(this.editJob["id"], this.registerForm.value).subscribe( data => {
        this.onSuccessCreateEditJob.emit(true);
        this.modalOpenSubscription.close();
      }, error => {
        this.errorObject = Object.assign({}, {
          message: "Error while updating job.",
          type: "error"
        });
      }).add(() => this.isFormSubmitted = false);
    }
  }

  setCronEx(event: any) {
    this.cronexString = event;
    this.registerForm.controls["cronExpression"].setValue(this.cronexString);
  }

  createUpdateValidation() {
    this.errorObject = Object.assign({}, {type: "", message: ""});
    this.isFormSubmitted = true;

    /*Set/remove validator for 'cronExpression' field depending on 'jobType' field*/
    if (this.registerForm.get("jobType")?.value === this.ONE_TIME_flag) {
      this.registerForm.get("cronExpression")?.clearValidators();
      this.registerForm.get("cronExpression")?.updateValueAndValidity();

      /*TODO: Remove this line, when BE can accept empty string for 'cronExpression' field, if user select CRON job type*/
      this.registerForm.controls["cronExpression"].setValue(null);
    } else {
      this.registerForm.get("cronExpression")?.setValidators(Validators.required);
      this.registerForm.get("cronExpression")?.updateValueAndValidity();
    }

    /*Set date object to 'date' field*/
    if ((this.registerForm.get("date")?.value instanceof Date)) {
      // eslint-disable-next-line max-len
      this.registerForm.controls["startTime"].setValue(_moment(new Date(this.registerForm.get("date")?.value).toISOString().split("T")[0] + " " + this.registerForm.get("time")?.value).format());
    } else {
      // eslint-disable-next-line max-len
      this.registerForm.controls["startTime"].setValue(_moment(this.registerForm.get("date")?.value + " " + this.registerForm.get("time")?.value).format());
    }
    this.registerForm.controls["date"].setValue(new Date(this.registerForm.get("date")?.value));

  }
}
