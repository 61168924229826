/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McEntityConsumerAttribute} from '../models/mc-entity-consumer-attribute.model';
import {McEntityConsumerAttributeService} from '../services/mc-entity-consumer-attribute.service';
import {IMcEntityConsumerAttribute} from '../_generated/mc-entity-consumer-attribute.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McEntityConsumerAttributeGENERATED extends BaseObject {


    public static readonly TYPE_CD_VARCHAR: string = 'VARCHAR';
    public static readonly TYPE_CD_BOOLEAN: string = 'BOOLEAN';
    public static readonly TYPE_CD_DATE: string = 'DATE';
    public static readonly TYPE_CD_NUMBER: string = 'NUMBER';
  public static readonly TYPE_CD_LIST__ALL = [
    McEntityConsumerAttributeGENERATED.TYPE_CD_VARCHAR,
    McEntityConsumerAttributeGENERATED.TYPE_CD_BOOLEAN,
    McEntityConsumerAttributeGENERATED.TYPE_CD_DATE,
    McEntityConsumerAttributeGENERATED.TYPE_CD_NUMBER
];

  public apiService: McEntityConsumerAttributeService;
  public _rawJson: IMcEntityConsumerAttribute;
    id: number = 0;
  archivedFlg: boolean;
  creationDate: number;
  defaultValue = '';
  description = '';
  enabledFlg: boolean;
  groupName = '';
  idMcEntity: number;
  inheritableFlg: boolean;
  inheritedFlg: boolean;
  label = '';
  name = '';
  typeCd: string;
  validationRegex = '';
  value: string;

  public properties: string[] = ['id', 'archivedFlg', 'creationDate', 'defaultValue', 'description', 'enabledFlg', 'groupName', 'idMcEntity', 'inheritableFlg', 'inheritedFlg', 'label', 'name', 'typeCd', 'validationRegex', 'value'];
  public propertiesRegular: string[] = ['id', 'archivedFlg', 'creationDate', 'defaultValue', 'description', 'enabledFlg', 'groupName', 'idMcEntity', 'inheritableFlg', 'inheritedFlg', 'label', 'name', 'typeCd', 'validationRegex', 'value'];
  public propertiesSpecial: string[] = [];



  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McEntityConsumerAttributeGENERATED.TYPE_CD_VARCHAR, McGod.t('Varchar'));
    list.add(McEntityConsumerAttributeGENERATED.TYPE_CD_BOOLEAN, McGod.t('Boolean'));
    list.add(McEntityConsumerAttributeGENERATED.TYPE_CD_DATE, McGod.t('Date'));
    list.add(McEntityConsumerAttributeGENERATED.TYPE_CD_NUMBER, McGod.t('Number'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = McEntityConsumerAttributeGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McEntityConsumerAttribute {
    console.error('MTCN-ERROR: Not Implemented: McEntityConsumerAttribute::createNewInstance(). Add this method to final class and return new McEntityConsumerAttribute();');
    throw new Error('Cannot McEntityConsumerAttribute::createNewInstance(). Add this method to final class and return new McEntityConsumerAttribute();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcEntityConsumerAttribute): McEntityConsumerAttribute {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McEntityConsumerAttributeService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McEntityConsumerAttribute AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getTypeCdLabel(): string {
    return McEntityConsumerAttributeGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdVARCHAR(): boolean {
    const result = (this.typeCd === McEntityConsumerAttributeGENERATED.TYPE_CD_VARCHAR);

    return result;
  }

 public  isTypeCdBOOLEAN(): boolean {
    const result = (this.typeCd === McEntityConsumerAttributeGENERATED.TYPE_CD_BOOLEAN);

    return result;
  }

 public  isTypeCdDATE(): boolean {
    const result = (this.typeCd === McEntityConsumerAttributeGENERATED.TYPE_CD_DATE);

    return result;
  }

 public  isTypeCdNUMBER(): boolean {
    const result = (this.typeCd === McEntityConsumerAttributeGENERATED.TYPE_CD_NUMBER);

    return result;
  }


  

// ---------------------------------------------------------------------
public callDevFixConsumerAttributes( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.devFixConsumerAttributes().subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

}
