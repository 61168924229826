
import {Table3Config} from '../extra/table-3-config';

// import {McConsumerBalanceGENERATED} from '../_generated/mc-consumer-balance-generated.model';

export class McConsumerBalanceTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAmount', 'Amount', 'amount', 'amount');
    this.addColumn('colEntityId', 'Entity id', 'entityId', 'entityId');
    this.addColumn('colEntityName', 'Entity name', 'entityName', 'entityName');
    this.addColumn('colFirstName', 'First name', 'firstName', 'firstName');
    this.addColumn('colLastName', 'Last name', 'lastName', 'lastName');

*/
  }
}
