import {CsrBankAccountFilterGENERATED} from '../_generated/csr-bank-account-filter-generated.model';

export class CsrBankAccountFilter extends CsrBankAccountFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrBankAccountFilter();
  }

  // ---------------------------------------------------------------------
}
