import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {McRoleService, McUtilityService, McEntityService, McGod, BaseDirective} from '@miticon-ui/mc-core';
import {Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: "mc-role-create-summary",
  templateUrl: "./mc-role-create-summary.component.html",
})
export class McRoleCreateSummaryComponent extends BaseDirective implements OnInit {

  selectedEntityPath!: any;
  errorObject = {
    type: "",
    message: ""
  };
  public mcGod = McGod.getInstance();

  /*Events*/
  @Input() selectedEntity!: any;
  @Input() formSummery!: any;
  @Output() closeModal = new EventEmitter<boolean>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onGoToStep = new EventEmitter<object>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onCreateNewForm = new EventEmitter<boolean>();

  /*Constructor*/
  constructor(private roleService: McRoleService,
              private utilityService: McUtilityService,
              private entityService: McEntityService) {
    super();
    this.entityService._selectedEntityPath.subscribe(path => {
      this.selectedEntityPath = path;
    });
  }

  /*On init class*/
  ngOnInit() {
  }

  /*On create role*/
  public onCreate(goToFirstStep = false) {
    let isFormValid;
    this.errorObject = {
      type: "",
      message: ""
    };
    if (this.formSummery) {
      const form = {
        active: this.formSummery.firstStepForm.status === "active" ? "true" : "false",
        caption: this.formSummery.firstStepForm.caption,
        description: this.formSummery.firstStepForm.description,
        templateFlg: this.formSummery.firstStepForm.setRoleAsATemplate,
        mcEntityId: this.utilityService.getPropertyFromToken("entity_id"),
        permissionIds: [],
        isProtected: true
      };
      // eslint-disable-next-line max-len
      this.formSummery.secondStepForm.forEach((item: any
      ) =>
        // @ts-ignore
        item.permissions.forEach((permission: any) => permission.id && form.permissionIds.push(permission.id)));


      isFormValid = !Object.keys(form).some(item => {
        // @ts-ignore
        if (!form[item]) {
          if (item === 'templateFlg') {
            if (form[item] === null) {
              form[item] = false;
              return form[item].valid;
            }
            if (form[item] === false || form[item] === true) {
              return form[item].valid;
            }
          }
        }
        // @ts-ignore
        return (!form.permissionIds && form[item]) || (!form[item]);
      });
      /*If form is valid call service*/
      if (isFormValid) {
        this.roleService.createRole2Entity(form)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(response => {
            this.closeModal.emit(!goToFirstStep);
            if (goToFirstStep) {
              this.goToStep(1, true);
            }
          }, error => {
            this.errorObject.type = "error";
            this.errorObject.message = error.error.message ? error.error.message : error.error.error_description;
          });
      } else {
        this.errorObject.type = "error";
        this.errorObject.message = "Need to fill mandatory fields.";
      }
    }
  }

  /*Go to step*/
  goToStep(step: any, resetForm = false) {
    this.utilityService.setStepNumber(step);
    this.onCreateNewForm.emit(resetForm);
  }

}
