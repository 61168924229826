
import {Table3Config} from '../extra/table-3-config';

// import {McEbicsGENERATED} from '../_generated/mc-ebics-generated.model';

export class McEbicsTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAccountNumber', 'Account number', 'accountNumber', 'accountNumber');
    this.addColumn('colAccountOwner', 'Account owner', 'accountOwner', 'accountOwner');
    this.addColumn('colBankCity', 'Bank city', 'bankCity', 'bankCity');
    this.addColumn('colBankCountry', 'Bank country', 'bankCountry', 'bankCountry');
    this.addColumn('colBankName', 'Bank name', 'bankName', 'bankName');
    this.addColumn('colBlz', 'Bank code number', 'blz', 'blz');
    this.addColumn('colDefaultFlg', 'Default flag', 'defaultFlg', 'defaultFlg');
    this.addColumn('colEbicsStatus', 'Ebics status', 'ebicsStatus', 'ebicsStatus');
    this.addColumn('colIban', 'IBAN', 'iban', 'iban');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colSwift', 'Swift', 'swift', 'swift');

*/
  }
}
