/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {ImDataSetAttributeTable3ConfigGENERATED} from '../_generated/im-data-set-attribute-table-3-config-generated.model';

// import {ImDataSetAttributeGENERATED} from '../_generated/im-data-set-attribute-generated.model';

export class ImDataSetAttributeTable3Config extends ImDataSetAttributeTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colCustomFlg', 'Custom Flag', 'customFlg', 'customFlg');
    this.addColumn('colDataSet', 'Data set', 'dataSet', 'dataSet');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colEnabledFlg', 'Enabled Flag', 'enabledFlg', 'enabledFlg');
    this.addColumn('colEntityId', 'Entity ID', 'entityId', 'entityId');
    this.addColumn('colIdDataSet', 'DataSet ID', 'idDataSet', 'idDataSet');
    this.addColumn('colInheritableFlg', 'Inheritable Flag', 'inheritableFlg', 'inheritableFlg');
    this.addColumn('colName', 'Name', 'name', 'name');
    this.addColumn('colRequiredFlg', 'Required Flag', 'requiredFlg', 'requiredFlg');
    this.addColumn('colSampleData', 'Sample data', 'sampleData', 'sampleData');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colTypeCd', 'Type', 'typeCd', 'typeCd');


  }
}
