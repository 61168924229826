export class Pagination {
  first = true;
  last = true;
  totalPages = 0;
  pageNumber = 0;
  pageSize = 20;
  totalElements!: number;
  numberOfElements!: number;
  previousPageIndex!: number;
  sort = new PaginationSort();
  pageSizeOptions = [{option: '5'}, {option: '20'}, {option: '50'}, {option: '100'}, {option: '200'}, {option: '500'}, {option: '1000'}, {option: '5000'}, {option: '10000'}];
  empty!: boolean;

  constructor(aPageSize: number = 20) {
    this.pageSize = aPageSize;
  }
}

class PaginationSort {
  public unsorted = true;
  public sorted = false;
  public empty = true;
}

export interface PageSizeOption {
  option: string;
}
