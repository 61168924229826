/*BbmTestObj*/
import {EbPaymentReportTable3ConfigGENERATED} from '../_generated/eb-payment-report-table-3-config-generated.model';

// import {EbPaymentReportGENERATED} from '../_generated/eb-payment-report-generated.model';

export class EbPaymentReportTable3Config extends EbPaymentReportTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colActions', '', 'incomingPaymentsActions()', '');
    this.addColumn('colDebtorName', 'Debtor Name', 'getDebtorName()', 'debtorName');
    this.addColumn('colIBAN', 'IBAN', 'getIban()', 'IBAN');
    this.addColumn('colDescription', 'Description', 'getDescription()', 'description').extraCssClass = 'mc-field-width-30';
    this.addColumn('colDateAndTime', 'Date and time', 'getTransactionDateTime()', 'dateAndTime');
    this.addColumn('colAmount', 'Amount', 'getAmount()', 'amount');
    this.addColumn('colPaymentType', this.t('cc.jobs.edit.type'), 'getPaymentType()', '');
    this.addColumn('colStatus', 'Status', 'getIconLabel()', 'status').extraCssClass = 'text-left';
  }
}
