import {Inject, Injectable} from '@angular/core';
import {McEntityConsumerAttributeServiceGENERATED} from '../_generated/mc-entity-consumer-attribute-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McEntityConsumerAttributeService extends McEntityConsumerAttributeServiceGENERATED {

}
