import {ZzPocConsumerFilterGENERATED} from '../_generated/zz-poc-consumer-filter-generated.model';

export class ZzPocConsumerFilter extends ZzPocConsumerFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new ZzPocConsumerFilter();
  }

  // ---------------------------------------------------------------------
}
