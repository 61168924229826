import {McCategoryFilterGENERATED} from '../_generated/mc-category-filter-generated.model';

export class McCategoryFilter extends McCategoryFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McCategoryFilter();
  }

  // ---------------------------------------------------------------------
}
