export class FiTextboxConfig {

  name!: string;
  placeholder!: string;
  value!: string;
  required!: string;
  readOnly!: boolean;
  label!: string;
  keyup: any;

  constructor(configObject: { placeholder: string; name: string; }) {
    this.update(configObject);
  }

  update(objectUpdate: { [x: string]: any; placeholder?: string; name?: string; }) {
    // @ts-ignore
    Object.keys(objectUpdate).forEach(key => this[key] = objectUpdate[key]);
  }

}
