import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {
  CurrencyUtil,
  McBillingService2PriceList,
  McBillingService2PriceListList,
  McBillingService2PriceListProfileTable3Config,
  McBoolean, McEntity2List,
  McGod,
  McHtmlButton,
  McPriceList
} from '@miticon-ui/mc-core';

import {Location} from '@angular/common';
import {Subscription} from "rxjs";
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'mc-pricelist-pricelist-profile-page',
  templateUrl: './mc-pricelist-pricelist-profile-page.component.html',
  styleUrls: ['./mc-pricelist-pricelist-profile-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class McPricelistPricelistProfilePageComponent implements OnInit {

  mcBillingService2PriceListList!: McBillingService2PriceListList;
  mcBillingService2PriceListTable3Config = new  McBillingService2PriceListProfileTable3Config();
  mcPricelist = new McPriceList();
  pricelistId!: number;
  paramSubscription = new Subscription();
  isListTemplate!: boolean;
  showServiceAddEditMcb = new McBoolean();
  succesMsg!: string;
  mcService2Pricelist = new McBillingService2PriceList();
  mcGod = McGod.getInstance();
  showEditTemplateNameDescMcb = new McBoolean();
  showEditPriceListFromProfileMcb = new McBoolean();
  showEntitiesUsingPricelistMcb = new McBoolean();
  showPriceListsCreatedFromThisTemplateMcb = new McBoolean();
  showEntitiesUsingThisTemplateMcb = new McBoolean();
  mcEntity2List = new McEntity2List();
  currencyCode!: string;

  // ---------------------------------------------------------------------
  constructor(private location: Location, private activatedRoute: ActivatedRoute) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.paramSubscription = this.activatedRoute.paramMap.subscribe(data => {
      if (data.get('id')) {
        this.pricelistId = Number(data.get('id'));
      }
    });
    this.actLoad();
  }

  // ---------------------------------------------------------------------




  public actLoad(): void {
    this.mcPricelist.loadById(this.pricelistId, () => {

      if (this.mcPricelist.idParentTemplate === undefined) {
        this.isListTemplate = true;
      }
      this.mcBillingService2PriceListList = new McBillingService2PriceListList();
      this.mcBillingService2PriceListList.setPager(0, 100);
      this.mcBillingService2PriceListList = this.mcPricelist.mcBillingService2PriceListItemList;
      this.currencyCode = this.mcPricelist.currency.code;

      this.mcBillingService2PriceListList.items.map(item => {
        if (item.priceOneTime || item.priceOneTime >= 0) {
          item.priceOneTimeStr = CurrencyUtil.format(item.priceOneTime, this.currencyCode);
        }
        if (item.priceFlat || item.priceFlat >= 0) {
          item.priceFlatStr = CurrencyUtil.format(item.priceFlat, this.currencyCode);
          item.priceAdditionalStr = CurrencyUtil.format(item.priceAdditionalUnit, this.currencyCode);
        }
      })

      if (this.mcBillingService2PriceListList.items) {
        this.mcBillingService2PriceListList.items.sort((a, b) => {
          const idA = a.id;
          const idB = b.id;
          return (idA < idB) ? -1 : (idA > idB) ? 1 : 0;
        });
      }



    });
  }

  navigateBack() {
    this.location.back();
  }

  onServiceTableItemAction(htmlButton: McHtmlButton) {
    if (htmlButton.actionCdForEvent === McBillingService2PriceList.ACTION_EDIT) {
      this.mcService2Pricelist = htmlButton.item;
      this.showServiceAddEditMcb.setTrue();
    }
  }

  onServiceEditCancelEmit() {
    this.actLoad();
    this.showServiceAddEditMcb.setFalse();
  }

  onServiceEditSaveEmit(value: string) {
    this.actLoad();
    this.showServiceAddEditMcb.setFalse();
    this.succesMsg = `Service '${value}' edited successfully.`;
  }

  onClickPricelistTemplateEdit() {
    if (this.isListTemplate) {
      this.showEditTemplateNameDescMcb.setTrue();
    } else {
      this.showEditPriceListFromProfileMcb.setTrue();
    }
  }

  onEditTemplateCanceled() {
    this.showEditTemplateNameDescMcb.setFalse();
  }

  onEditTemplateSaved() {
    this.showEditTemplateNameDescMcb.setFalse();
    this.actLoad();
    this.succesMsg = 'Template edited successfully.';
  }

  onEditPricelistSaved() {
    this.showEditPriceListFromProfileMcb.setFalse();
    this.actLoad();
    this.succesMsg = 'Pricelist edited successfully.';
  }
}
