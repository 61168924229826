<h3 class="page-title">{{ "mem.club.data-import" | translate}}</h3>

<div class="actions-container">
  <span>{{"mem.data-import-list" | translate}}</span>
  <div class="actions-wrapper">
    <button (click)="addImport()" class="system-action-btn mr-2" style="gap: 10px">
      <span [innerHTML]="iconDataImport | safeHtml"></span>
      {{"mem.data-import.import" | translate}}
    </button>
  </div>
</div>

<mk-mat-table
  [items]="items"
  [itemsPerPage]="items.length"
  [config]="tableConfig"
  [isLoading]="isLoading"
  [filterConfig]="filterConfig"
  [pageNumber]="pageNumber"
  [pageSize]="pageSize"
  [searchTooltip]="searchToolTip"
  (filterChanged)="onFilterChanged($event)"
  [totalItemsCount]="totalItemsCount"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
></mk-mat-table>

<!--[isImport]="true"-->
