import { McCountryCountryProfilePageComponent } from './pages/mc-country-country-profile-page/mc-country-country-profile-page.component';
import { McCountryVatAddEditPartComponent } from './components/mc-country-vat-add-edit-part/mc-country-vat-add-edit-part.component';
import { McCountryCountryAddEditPartComponent } from './components/mc-country-country-add-edit-part/mc-country-country-add-edit-part.component';
import { McCountryCountryItemListComponent } from './pages/mc-country-country-item-list/mc-country-country-item-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McCountryRoutingModule } from './mc-country-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {McFilterModule, McTableModule} from '@miticon-ui/mc-components';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
McCountryCountryProfilePageComponent,
McCountryVatAddEditPartComponent,
McCountryCountryAddEditPartComponent,
McCountryCountryItemListComponent,
  ],
  imports: [
    CommonModule,
    McCountryRoutingModule,
    NgbModule,
    McComponentsModule,
    McFilterModule,
    McTableModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
McCountryCountryProfilePageComponent,
McCountryVatAddEditPartComponent,
McCountryCountryAddEditPartComponent,
McCountryCountryItemListComponent,
    McCountryRoutingModule,
    FormsModule,

  ]
})
export class McCountryModule { }
