import { Component, OnInit } from "@angular/core";
import {McStarmoneyFilterConfig} from "./resources/mc-starmoney-filter-config";
import {EbStarmoneyFileList, McNotifyMessageModel, McUtilityService} from "@miticon-ui/mc-core";
import {McStarmoneyTableConfig} from "./resources/mc-starmoney-table-config";

@Component({
  selector: "mc-starmoney",
  templateUrl: "./mc-starmoney.component.html"
})
export class McStarmoneyComponent implements OnInit {

  /*Public variables*/
  public filterConfig = new McStarmoneyFilterConfig();
  public starMoneyFileList: EbStarmoneyFileList;
  public starmoneyTableConfig: McStarmoneyTableConfig;
  public notifyMessage: McNotifyMessageModel;

  constructor(
    private utilityService: McUtilityService
  ) { }

  ngOnInit() {
    this.starMoneyFileList = new EbStarmoneyFileList();
    this.starmoneyTableConfig = new McStarmoneyTableConfig();

    this.getStarMoneyFiles();
  }

  getStarMoneyFiles() {
    const entityId = this.utilityService.getPropertyFromToken("entity_id");
    this.starMoneyFileList.loadByEntityId(entityId);
  }

  /*On filter*/
  public onFilterProceed(event: any) {
    console.log("- FILTER EVENT -->>", event);
  }

  // On import starMoney file in import modal
  public onImportStarMoneyFile() {
    this.notifyMessage = {type: "success", message: "Import starmoney file succeed"};
    this.getStarMoneyFiles();
  }

}
