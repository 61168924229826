import {Component, OnInit} from '@angular/core';
import {
  BaseDirective,
  CsrConsumer,
  CsrContract,
  CsrContractProducts,
  CsrContractService,
  CsrRecurringDetails,
  CurrencyUtil,
  McBoolean,
  McDateUtils,
  McForm,
} from '@miticon-ui/mc-core';
import {ActivatedRoute} from '@angular/router';
import {takeUntil, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-consumer-confirmation-contract',
  templateUrl: './consumer-confirmation-contract.component.html',
  styleUrls: ['./consumer-confirmation-contract.component.scss'],
})
export class ConsumerConfirmationContractComponent extends BaseDirective implements OnInit {
  confirmationCode!: string;
  mcForm = new McForm();
  showConfirmationContractCode = new McBoolean();
  contractId!: number;
  confirmationCodeValid = false;
  csrConsumer = new CsrConsumer();
  csrContract = new CsrContract();
  productList!: CsrContractProducts[];
  daysBeforeExpiration = 0;
  csrRecurringDetails = new CsrRecurringDetails();

  constructor(private route: ActivatedRoute, private csrContractService: CsrContractService, private toast: ToastrService) {
    super();
  }

  ngOnInit() {
    this.contractId = this.route.snapshot.params['id'];
    this.route.queryParams.subscribe((response) => {
      this.confirmationCode = response['securityCode'];
      if (!this.confirmationCode) {
        this.showConfirmationContractCode.setTrue();
      } else {
        this.csrContractService.validateConfirmationCode({confirmationCode: this.confirmationCode, contractId: this.contractId})
          .pipe(
            tap(() => {
              this.csrContractService.readCsrContractProductsList( {confirmationCode: this.confirmationCode, contractId: this.contractId})
                .pipe(takeUntil(this.destroyed$))
                .subscribe( data => {
                  this.productList = data;
                });
              this.csrContractService.readCsrContract({ confirmationCode: this.confirmationCode, contractId: this.contractId})
                .subscribe( (data) => {
                  this.csrContract = this.createFromJson(data);
                  this.daysBeforeExpiration = McDateUtils.subtractTwoDates(this.csrContract.endDate, this.csrContract.cancellationDeadline);
                });
            }),
            takeUntil(this.destroyed$))
          .subscribe((res) => {
            this.csrConsumer = res;
            this.confirmationCodeValid = true;

          }, () => {
            this.toast.error('Confirmation code is not valid');
          });
      }
    });
  }

  onAcceptContract() {
    this.csrContractService.acceptContract({confirmationCode: this.confirmationCode, contractId: this.contractId})
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        window.location.href = response.mollieRedirectUrl;
      },
        (error) => {
        this.toast.error(error.error.message);
        });
  }

  createFromJson(aItemJsonObj: any): CsrContract {
    const item = CsrContract.createNewInstance(); // Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
    return item;
  }

  format(value: number, currency: string): string {
    if (value) {
      return CurrencyUtil.format(value, currency);
    }
    return CurrencyUtil.format(0, currency);
  }
}
