<h3 class="page-title">{{ "cc.common.products" | translate }}</h3>

<div class="actions-container">
  <span>{{ "cc.products.products-list" | translate }}</span>
  <div class="actions-wrapper">
    <button (click)="showCreateCategoryMcb.setTrue()" class="system-action-btn mr-2">
      <i class="fas fa-plus-circle mc-add-new-user-icon"></i>
      {{ "cc.product.edit.create-new-category" | translate }}
    </button>
    <button (click)="onCreateProduct()" class="system-action-btn mr-2">
      <i class="fas fa-plus-circle mc-add-new-user-icon"></i>
      {{ "cc.product.view.create-new-product" | translate }}
    </button>
  </div>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [totalItemsCount]="totalItemsCount"
  [filterConfig]="filterConfig"
  (filterChanged)="onFilterChanged($event)"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
  [searchTooltip]="searchTooltip"
></mk-mat-table>

<div style="padding-bottom: 20px">
  <mcc-modal1-wrapper [showMcb]="showCreateCategoryMcb" *ngIf="showCreateCategoryMcb.value" modalSizeCd="small">
    <mc-product-add-category (eventCanceled)="onCreateCategoryCanceled()" (eventSaved)="onCreateCategorySaved()"></mc-product-add-category>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper [showMcb]="showCreateProductMcb" *ngIf="showCreateProductMcb.value">
    <mc-product-add-edit-product (eventCanceled)="onCreateProductCanceled()" (eventSaved)="onCreateProductSaved($event)" [productId]="mcProduct?.id"></mc-product-add-edit-product>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper [showMcb]="showProductProfileMcb" *ngIf="showProductProfileMcb.value">
    <mc-product-product-profile-part (eventClose)="showProductProfileMcb.setFalse()" [mcProduct]="mcProduct"></mc-product-product-profile-part>
  </mcc-modal1-wrapper>

  <mcc-dialog-box *ngIf="showDeleteProduct.value" [enableCloseButtonFlg]="false"
                  title="{{'cc.product.delete-product' | translate}}" name="delete-product"
                  description="{{'cc.product.are-you-sure-you-want-to-delete-product' | translate}}?"
                  [buttonsKvm]="buttonsDeleteProductDialog"
                  popupType="question"
                  (eventActionButtonClicked)="onDeleteProductDialogButtonsClicked($event)">
  </mcc-dialog-box>
</div>
