<h3 class="page-title">{{'cc.out-factoring.exported-sepa-list' | translate}}</h3>
<div>
    <lib-mc-loader [showLoader]="ebSepaExportList.apiLoadingFlg"></lib-mc-loader>

    <div *ngIf="ebSepaExportList.apiErrorFlg">
        Error: {{ebSepaExportList.apiErrorMessage}}
    </div>

  <div class="col-md-12 position-relative mt-1 mb-5">
    <div class="mc-create-user-notify-wrapper">
      <mc-notify-message [message]=notifyMessage.message [type]="notifyMessage.type"></mc-notify-message>
    </div>
  </div>

  <mcc-dialog-box *ngIf="showRevertDialogMcb.value" [enableCloseButtonFlg]="false"
                  title="{{'cc.out-factoring.revert-this-exported-sepa' | translate}}" name="revert-export-sepa"
                  description="{{revertDialogMessage}}"
                  [buttonsKvm]="revertButtonsKvm"
                  popupType="question"
                  (eventActionButtonClicked)="onMcDialogBoxRevertSepaActionButtonClicked($event)"></mcc-dialog-box>

</div>

<mcc-modal1-wrapper modalSizeCd="small" [showMcb]="showUploadFileMcb" *ngIf="showUploadFileMcb.value">
  <lib-upload-file (eventUploaded)="onUploadFile($event)" (eventCancel)="onUploadCancel()"></lib-upload-file>
</mcc-modal1-wrapper>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  (filterChanged)="onFilterChanged($event)"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
></mk-mat-table>
