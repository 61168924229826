<!--MC Two FA section-->
<div class="mc-auth-locked-wrapper valign-container" [ngStyle]="{'background-image': 'url(' + welcomeImg + ')'}">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">

        <!--Card section-->
        <div class="mc-card card">

          <!--Card header-->
          <div class="mc-card-header text-center">

            <app-mc-back-to-login></app-mc-back-to-login>

            <!--Logo-->
            <img src="{{logo}}" class="mc-logo" alt="Cass Control Logo">
            <hr class="mt-4">
          </div><!--Car header /end-->

          <!--Card body-->
          <div class="card-body text-center mc-card-body pt-0">
            <h4 class="pb-4">
              Logging in is temporary unavailable
            </h4>

            <!--Error message-->
            <p>
              {{loginResponse.error.error_description}}
            </p>

          </div><!--Card body /end-->

        </div><!--Card /end-->
      </div>
    </div><!--Row /end-->
  </div><!--Container /end-->
</div><!--Wrapper /end-->
