import {Component, Inject, OnInit} from '@angular/core';
import {ICON_CLOSE} from "@miticon-ui/mc-components";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ICON_INFO} from "../../../../../assets/media/svg_icons/icon-info";
import {CsrContractService, McGod} from "@miticon-ui/mc-core";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'lib-cancel-contract-dialog',
  templateUrl: './cancel-contract-dialog.component.html',
  styleUrls: ['./cancel-contract-dialog.component.scss']
})
export class CancelContractDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  iconInfo = ICON_INFO;

  constructor(@Inject(MAT_DIALOG_DATA) public data: number,
              public dialogRef: MatDialogRef<CancelContractDialogComponent>,
              private csrContractService: CsrContractService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  cancelContract() {
    this.csrContractService.cancelContract(this.data).subscribe((data) => {
      if(data) {
        this.toastr.success(McGod.t("cc.consumer360.successfully-cancelled"));
        this.dialogRef.close(true);
      }
    }, (err) => {
      this.toastr.error(err.error.message);
    })
  }
}
