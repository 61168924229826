/*BbmTestObj*/
import {EbFactoringRequestTypeGENERATED} from "../_generated/eb-factoring-request-type-generated.model";

export class EbFactoringRequestType extends EbFactoringRequestTypeGENERATED {

  public static override createNewInstance() {
    return new EbFactoringRequestType();
  }


}
