import {Directive, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';


@Directive({
  selector: '[libBase]'
})
export class BaseDirective implements OnDestroy {
  destroyed$ = new Subject();

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
