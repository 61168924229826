import {Component, Input, OnInit} from '@angular/core';
import {
  PmTransactionList,
  PmTransaction,
  McBillingStatementItemList,
  McBillingStatementList,
  McBillingItemList,
  McBillingItemService,
  McBillingItemTable3ConfigWithoutFldActionsModel,
  McBillingItemFilter,
  McBoolean, PmAdjustment, McNotifyMessageModel,
  McPayoutItemTable3Model,
  PmAdjustmentList,
  PmAdjustmentTable3Config, McConsumerBalanceService, BaseDirective, McBillingItem, McEntity2, McGod, EbSepaTransactionService, EbSepaFileTransactionService, EbSepaFileTransactionList,
} from '@miticon-ui/mc-core';
import { ActivatedRoute } from '@angular/router';
import {take, takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

const enum DialogMarkAsSentToInkassoAction {
  ACT_CANCEL = 'btn-secondary',
  ACT_YES = 'btn-primary'
}

@Component({
  selector: 'pm-transactions-page',
  templateUrl: './pm-transactions-page.component.html',
  styleUrls: ['./pm-transactions-page.component.scss']
})
export class PmTransactionsPageComponent extends BaseDirective implements OnInit {

  isExpanded = false;
  pmTransactionId!: number;
  pmTransaction!: PmTransaction;
  mcBillingStatementItemList = new McBillingStatementItemList();
  billingStatementList = new McBillingStatementList();
  billingItemList = new McBillingItemList();
  billingItemForTableList = new McBillingItemList();
  billingItemTable3Config = new McBillingItemTable3ConfigWithoutFldActionsModel();
  mcBillingItemFilter = new McBillingItemFilter();
  payoutItemList = new McBillingItemList();
  payoutItemTable3Config = new McPayoutItemTable3Model();
  pmAdjustmentList = new PmAdjustmentList();
  pmAdjustmentTable3Config = new PmAdjustmentTable3Config();
  addEditTransactionAdjustmentType!: 'payment' | 'adjustment';
  addEditTransactionAdjustmentId!: number;
  showTransactionAddPaymentMcb = new McBoolean();
  mcGod = McGod.getInstance();
  balanceAmount!: number;
  errorBalanceMsg!: string;
  successBalanceMsg!: string;
  currency!: string;
  @Input() type!: 'payment' | 'adjustment';
  @Input() pmTransactionAdjustmentId!: number;
  pageTitle = this.mcGod.t('cc.my-factoring.add-adjustment');
  pmAdjustment = new PmAdjustment();
  notifyMessage!: McNotifyMessageModel;
  isTransactionHistoryExpanded = false;
  isBillingItemsExpanded = false;
  isPayoutDetailsExpanded = false;
  stornoIds!: any [];
  selectedTransactionList = new PmTransactionList();
  showMarkAsSentToInkassoMcb = new McBoolean();
  status!: string;
  markAsSentToBankDialogMessage!: string;
  buttonsMarkAsSentToInkassoDialog = new Map<string, string>();
  showPauseTransactionMcb = new McBoolean();
  numberOfSelectedItems!: number;
  checkedTransactionList = new PmTransactionList();
  mcEntity = new McEntity2();
  transactionIds: any [];
  showStornoMcb = new McBoolean();
  selectedTransactions = new PmTransactionList();
  showCollectionTypeChangeMcb = new McBoolean();
  contractNumber: string;

  constructor(
    private ebSepaTransactionService: EbSepaTransactionService,
    private toastr: ToastrService,
    private ebSepaFileTransactionService: EbSepaFileTransactionService,
    private route: ActivatedRoute,
    private billingItemService: McBillingItemService,
    private payoutItemService: McBillingItemService,
    private mcConsumerBalanceService: McConsumerBalanceService,
  ) {
    super();
  }

  ngOnInit() {
    this.markAsSentToBankDialogMessage = 'Are you sure you want to mark transaction as "Sent to inkasso"?';
    this.buttonsMarkAsSentToInkassoDialog.set('btn-primary', 'Yes');
    this.buttonsMarkAsSentToInkassoDialog.set('btn-secondary', 'No');
    this.route.paramMap.subscribe(params => {
      // @ts-ignore
      this.pmTransactionId = +params.get('id');
      this.pmTransaction = new PmTransaction();
      this.actLoad();
    });
    this.mcEntity.loadById(McGod.getLoggedEntityIdFromToken());
  }

  loadBillingItems() {
    this.mcBillingItemFilter.idTransaction = this.pmTransactionId;
    this.billingItemService.getAllByTransactionId(this.pmTransactionId, this.billingItemList.pagination.pageNumber, this.billingItemList.pagination.numberOfElements,
      this.billingItemList.sortCriteria)
      .pipe(takeUntil(this.destroyed$))
      .subscribe( data => {
        this.billingItemList.populateFromJsonPageable(data);
        this.billingItemList.items.map(item => {
          item.currencyCode = this.pmTransaction.mcFactoring.mcCurrency.code;
        });
        this.billingItemForTableList.items = this.billingItemList.items.filter(item => item.entryTypeCd === McBillingItem.ENTRY_TYPE_CD_DEBIT);
        this.payoutItemList.items = this.billingItemList.items.filter(item => item.entryTypeCd === McBillingItem.ENTRY_TYPE_CD_CREDIT);
        this.billingItemForTableList.items.reverse();
        this.payoutItemList.items.reverse();
      }, (err) => {});
  }

  loadPmTransactionsAndAdjustments() {
    this.pmTransaction.loadById(this.pmTransactionId, () => {
      this.pmAdjustmentList = this.pmTransaction.pmAdjustments;
      this.pmAdjustmentList.items.map(item => {
        item.currencyCode = this.pmTransaction.mcFactoring.mcCurrency.code;
      })
      this.pmAdjustmentList.items.reverse();
     this.loadBillingItems();
     this.loadContract();
    });
  }

  actLoad(): void {
    this.loadPmTransactionsAndAdjustments();
    // this.loadBillingItems();
  }

  actShowAddTransactionPayment() {
      this.addEditTransactionAdjustmentType = 'payment';
      this.addEditTransactionAdjustmentId = 0;
      this.showTransactionAddPaymentMcb.setTrue();
  }

  getTooltipForPayment() {
    if (this.pmTransaction.isStatusCdREJECTED()) {
      return 'You can not add a payment because the transaction is rejected.';
    } else {
      return '';
    }
  }
  getMaxUsedTotalPausedDays() {
    return this.pmTransaction.pausedDaysCount + '/' + this.mcEntity.maxTransactionPauseDays;
  }

  /*canSettleForBalanceStatus(): boolean {
    return this.pmTransaction.isStatusCdEXPORTED() || this.pmTransaction.isStatusCdPAID() ||
      this.pmTransaction.isStatusCdSETTLED() || this.pmTransaction.isStatusCdREJECTED() ||
      this.pmTransaction.isStatusCdSENT_TO_INKASSO();
  }

  /*actSettleWithBalance() {
    const body: any = {};
    body.paymentMethod = 'CREDIT';
    body.amount = null;

    if (this.pmTransaction.balance === 0 || !this.pmTransaction.balance) {
      this.toastr.error(McGod.t('cc.consumer.overview.this-action-cannot-be-completed-the-balance-on-the-consumers-account-is-0') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
    } else {
      this.ebSepaFileTransactionService.payTransaction(this.pmTransaction.id, body)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.toastr.success(McGod.t('cc.consumer.transaction-has-been-successfully-settled-with-balance') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
          this.actLoad();
        }, error => {
          this.toastr.error(McGod.t('cc.consumer.transaction-has-not-been-successfully-settled-with-balance-try-again-later') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
        });
    }
  }*/
  actOnTransactionAddPaymentClose() {
    this.toastr.success(McGod.t('The payment for transaction was recorded successfully') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
    this.loadTransaction();
    this.actLoad();
    this.showTransactionAddPaymentMcb.setFalse();
  }
  actShowMarkAsSentToInkasso() {
    this.showMarkAsSentToInkassoMcb.setTrue();
  }

  onMarkAsSentToInkasso(action: string) {
    const transactionIds = [];
    transactionIds.push(this.pmTransaction.id);
    if (action === DialogMarkAsSentToInkassoAction.ACT_CANCEL) {
      this.showMarkAsSentToInkassoMcb.setFalse();
    } else {
      this.ebSepaTransactionService.sentToInkasso(transactionIds).pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.showMarkAsSentToInkassoMcb.setFalse();
        this.loadTransaction();
        this.toastr.success(McGod.t('cc.common.success-inkasso-message') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
      }, error => {
        this.showMarkAsSentToInkassoMcb.setFalse();
        this.toastr.error(McGod.t('cc.common.error-inkasso-message') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
      });
    }
  }
  actStorno() {
    this.selectedTransactionList = new PmTransactionList();
    this.showStornoMcb.setTrue();
    this.selectedTransactionList.items.push(this.pmTransaction);
    this.stornoIds = [];
    this.stornoIds.push(this.pmTransactionId);
  }

  actChangeCollectionType(){
    this.showCollectionTypeChangeMcb.setTrue();
  }
  pauseUnpauseTransaction() {
    this.showPauseTransactionMcb.setTrue();
    this.checkedTransactionList = new PmTransactionList();
    this.checkedTransactionList.items.push(this.pmTransaction);
  }
  onUnpauseTransaction() {
    this.showPauseTransactionMcb.setFalse();
    this.loadTransaction();
    this.toastr.success(`${McGod.t('cc.transactions.transaction-s-was-unpaused-successfully')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
  }

  onPauseTransactionCanceled() {
    this.showPauseTransactionMcb.setFalse();
  }
  onPauseTransaction() {
    this.showPauseTransactionMcb.setFalse();
    this.loadTransaction();
    this.toastr.success(`${McGod.t('cc.transactions.transaction-s-was-paused-successfully')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
  }
  loadTransaction() {
    this.pmTransaction = new PmTransaction();
    this.pmTransaction.loadById(this.pmTransactionId, () => {
    });
  }

  onSaveStorno() {
    this.showStornoMcb.setFalse();
    this.toastr.success(`${McGod.t('cc.common.the-transaction-was-successfully-canceled')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
    this.loadTransaction();
    this.actLoad();
  }

  onCancelStorno() {
    this.showStornoMcb.setFalse();
  }

  onUpdateCollectionType(){
    this.showCollectionTypeChangeMcb.setFalse();
    this.loadTransaction();
    this.actLoad();
  }

  onCancelUpdateCollectionType(){
    this.showCollectionTypeChangeMcb.setFalse();
  }

  actReturnFromInkasso() {
    const transactionIds = [];
    transactionIds.push(this.pmTransaction.id);
    this.ebSepaTransactionService.returnFromInkasso(transactionIds).pipe(take(1)).subscribe( () => {
      this.pmTransaction.statusCd = 'RETURNED_FROM_INKASSO';
      this.toastr.success(`${McGod.t('cc.transactions.transaction-successfully-returned-from-inkasso')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
    }, error => {

      this.toastr.error(`${error.message}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
    });
  }

  loadContract(){
    this.contractNumber = this.pmTransaction.csrConsumer.activeContracts && this.pmTransaction.csrConsumer.activeContracts.items
      && this.pmTransaction.csrConsumer.activeContracts.items.length > 0 ?
      this.pmTransaction.csrConsumer.activeContracts.items[0].contractNumber : '';
  }
}
