import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { McProfileUserService, McUtilityService } from "@miticon-ui/mc-core";
import { LoginEnums, userEnums } from "@miticon-ui/mc-core";

@Component({
  selector: "mc-edit-personal-info-modal",
  templateUrl: "./mc-edit-personal-info-modal.component.html",
})
export class McEditPersonalInfoModalComponent implements OnInit {
  modalReference!: any;
  editPersonalInfoForm: FormGroup;
  imageUrl: any =
    "https://image.shutterstock.com/image-vector/empty-photo-male-profile-gray-260nw-538707310.jpg";
  languages!: any;
  timezones!: any;
  countries!: any;
  message!: string;
  type!: string;
  isEnabled!: boolean;
  firstName!: string;
  lastName!: string;
  userImage: any;
  private firstnameNotValid!: boolean;
  private lastnameNotValid!: boolean;
  private responseMessage!: boolean;
  lockSaveBtn!: boolean;
  editDataFormValid!: boolean;
  validButton!: boolean;
  timezone!: number;
  editTimezone!: string;
  editLanguage!: string;

  @Input() parentData!: any;

  @Output() refreshUserDataEvent = new EventEmitter<boolean>();
  @Output() isEditDataFormValid = new EventEmitter<boolean>();

  constructor(
    private modalService: NgbModal,
    private profileUserService: McProfileUserService,
    private fb: FormBuilder,
    private utilityService: McUtilityService
  ) {
    this.editPersonalInfoForm = new FormGroup({});
  }

  ngOnInit() {}

  private checkForLettersOnly(name: string) {
    const re = /^\D+$/g;
    return re.test(String(name));
  }

  private shareUnlockProperty(valid: boolean) {
    this.isEditDataFormValid.emit((this.editDataFormValid = valid));
  }

  checkMandatoryInputFields(field: string) {
    let msgText = "";
    let msgType = "";
    const formData = this.editPersonalInfoForm.getRawValue();
    const firstName = formData.firstName;
    const lastName = formData.lastName;
    const isFirstNameValid = this.checkForLettersOnly(firstName);
    const isLastNameValid = this.checkForLettersOnly(lastName);

    switch (field) {
      case "firstName":
        if (!isFirstNameValid) {
          if (firstName === "") {
            msgText = `Required fields can't be empty`;
          } else {
            msgText = userEnums.numberNotAllowed;
          }
          msgType = "error";
          this.firstnameNotValid = true;
          this.validButton = false;
        } else if (firstName.length < 2) {
          msgText = userEnums.firstNameMin2Char;
          msgType = "error";
          this.firstnameNotValid = true;
          this.validButton = false;
        } else {
          this.responseMessage = false;
          this.firstnameNotValid = false;
          this.validButton = true;
        }
        this.setNotificationMessage(msgText, msgType);
        this.responseMessage = true;
        break;
      case "lastName":
        if (!isLastNameValid) {
          if (lastName === "") {
            msgText = `Required fields can't be empty`;
          } else {
            msgText = userEnums.numberNotAllowed;
          }
          msgType = "error";
          this.lastnameNotValid = true;
          this.validButton = false;
        } else if (lastName.length < 2) {
          msgText = userEnums.lastNameMin2Char;
          msgType = "error";
          this.lastnameNotValid = true;
          this.validButton = false;
        } else {
          this.responseMessage = false;
          this.lastnameNotValid = false;
          this.validButton = true;
        }
        this.setNotificationMessage(msgText, msgType);
        this.responseMessage = true;
        break;
    }
    if (
      this.editPersonalInfoForm.controls["firstName"].valid &&
      this.editPersonalInfoForm.controls["lastName"].valid &&
      this.editPersonalInfoForm.controls["gender"].valid &&
      this.editPersonalInfoForm
    ) {
      this.shareUnlockProperty(false);
      this.lockSaveBtn = false;
    } else {
      this.shareUnlockProperty(true);
      this.lockSaveBtn = true;
    }
  }

  openModal(content: any) {
    this.setNotificationMessage("", "");
    this.imageUrl = this.parentData.image;
    this.isEnabled = false;
    /*Modal reference - used for close it*/
    this.modalReference = this.modalService.open(content, {
      centered: true,
      size: "lg",
      windowClass: "mc-edit-personal-info-modal",
      backdrop: "static",
      beforeDismiss: () => {
        this.validButton = false;
        return true;
      },
    });
    this.languages = [
      { name: "English", value: "EN" },
      { name: "German", value: "DE" },
      { name: "Italian", value: "IT" },
    ];
    // setTimezones
    this.timezones = [
      "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      "(UTC-08:00) Pacific Time (US & Canada)",
    ];
    // setCountries
    this.countries = [
      { name: "USA", value: "US" },
      { name: "Germany", value: "DE" },
      { name: "Italy", value: "IT" },
    ];
    if (this.parentData.timezone === 1) {
      this.editTimezone =
        "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague";
    } else {
      this.editTimezone = "(UTC-08:00) Pacific Time (US & Canada)";
    }
    switch (this.parentData.isoLanguage) {
      case "EN":
        this.editLanguage = "EN";
        break;
      case "DE":
        this.editLanguage = "DE";
        break;
      case "IT":
        this.editLanguage = "IT";
        break;
    }
    // set editFormValues
    this.editPersonalInfoForm = this.fb.group({
      firstName: [this.parentData.firstname, Validators.required],
      lastName: [this.parentData.lastname, Validators.required],
      nickname: [this.parentData.nickname],
      language: [this.editLanguage],
      timezone: [this.editTimezone],
      country: [this.parentData.isoCountry],
      image: [],
      gender: [this.parentData.gender, Validators.required],
    });

    this.editPersonalInfoForm
      .get("timezone")
      ?.valueChanges.subscribe((data) => {
        this.validButton = true;
      });
    this.editPersonalInfoForm
      .get("language")
      ?.valueChanges.subscribe((data) => {
        this.validButton = true;
      });
    this.editPersonalInfoForm.get("country")?.valueChanges.subscribe((data) => {
      this.validButton = true;
    });
    this.editPersonalInfoForm.get("image")?.valueChanges.subscribe((data) => {
      this.validButton = true;
    });
    this.editPersonalInfoForm.get("gender")?.valueChanges.subscribe((data) => {
      this.validButton = true;
    });
    this.editPersonalInfoForm
      .get("nickname")
      ?.valueChanges.subscribe((data) => {
        this.validButton = true;
      });
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  // Take value from inputFileImage
  onFileChanged(event: any) {
    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener("load", (e: any) => {
      this.imageUrl = reader.result;
    });
    reader.readAsDataURL(file);
  }

  // Take value from inputFirstName
  onFirstNameChanged(event: any) {
    this.firstName = event.target.value;
    if (event.target.value === "") {
      this.setNotificationMessage(`Required fields can't be empty`, "error");
    } else if (this.lastName !== "") {
      this.setNotificationMessage("", "");
    }
  }

  // Take value from inputLastName
  onLastNameChanged(event: any) {
    this.lastName = event.target.value;
    if (event.target.value === "") {
      this.setNotificationMessage(`Required fields can't be empty`, "error");
    } else if (this.firstName !== "") {
      this.setNotificationMessage("", "");
    }
  }

  // Edit userData
  changeUserData() {
    let msgText: string;
    let msgType: string;
    const formData = this.editPersonalInfoForm.getRawValue();
    const userId = this.utilityService.getPropertyFromToken("user_id");
    if (
      formData.timezone ===
      "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
    ) {
      this.timezone = 1;
    } else {
      this.timezone = -8;
    }
    const body = {
      id: userId,
      firstName: formData.firstName,
      lastName: formData.lastName,
      gender: formData.gender,
      isoCountry: formData.country || null,
      isoLanguage: formData.language || null,
      nickname: formData.nickname || null,
      rawImageData: this.imageUrl || null,
      timezone: this.timezone,
    };

    // stop here if form is invalid
    if (this.editPersonalInfoForm.invalid) {
      msgText = `Required fields can't be empty`;
      msgType = "error";
      this.setNotificationMessage(msgText, msgType);
      this.refreshUserDataEvent.emit(true);
      this.isEnabled = false;
    } else {
      this.isEnabled = true;
      this.profileUserService.editUserData(body).subscribe(
        (response) => {
          msgText = LoginEnums.successMsg;
          msgType = LoginEnums.successMsg;
          this.refreshUserDataEvent.emit(true);
          this.modalService.dismissAll("Cross click");
        },
        (error) => {
          if (error.status >= 500) {
            msgText =
              "Server is not available at the moment, please try again later.";
            msgType = "error";
            this.setNotificationMessage(msgText, msgType);
            return;
          }
          msgText = error.error ? error.error.message : error.message;
          msgType =
            error.ok === false ? LoginEnums.errorMsg : LoginEnums.successMsg;
          this.setNotificationMessage(msgText, msgType);
        }
      );
    }
  }
}
