<div class="content-wrapper">

  <div class="content-header">
    <h3>{{'cc.user.resend-invitation-email' | translate}}</h3>
  </div>

  <div class="content-body">
    <div class="colored-header">
      <span>{{'cc.user.user-info' | translate}}</span>
    </div>

    <div class="info-container">
      <mcc-row-title-description title="{{'cc.user.user' | translate}}:"
                                 description="{{user.firstname + ' ' + user.lastname}}"></mcc-row-title-description>
      <mcc-row-title-description title="{{'cc.consumer.email-address' | translate}}:"
                                 description="{{user.email}}"></mcc-row-title-description>
    </div>

    <mcc-message [errorMsg]="errorMsg"></mcc-message>

    <div class="change-users-email-form-wrapper">
      <mcc-fi-checkbox [mcForm]="mcForm" label="{{'cc.consumer.change-email-address' | translate}}" name="ctrlChangeEmail"
                       (eventValueChanged)="onChangeEmailEmit($event)"></mcc-fi-checkbox>
      <ng-container *ngIf="changeEmailFlg">
        <mcc-fi-textbox [validRequiredFlg]="true" [validEmail]="true" [mcForm]="mcForm" label="{{'cc.user.new-email-address' | translate}}" name="ctrlEmail"></mcc-fi-textbox>
        <mcc-fi-checkbox [mcForm]="mcForm" label="{{'cc.user.update-email-address-in-users-profile' | translate}}"
                         (eventValueChanged)="onUpdateEmailChecked($event)"
                         name="ctrlUpdateUserEmail"></mcc-fi-checkbox>
      </ng-container>
    </div>
  </div>


  <div class="content-footer">
    <button class="btn btn-secondary move-right" (click)="onCancel()">{{'cc.common.edit.cancel' | translate}}</button>
    <button class="btn btn-primary" (click)="onResendEmail()"><i class="fas fa-sync"></i> {{'cc.user.resend' | translate}}</button>
  </div>

</div>
