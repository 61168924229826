/*BbmTestObj*/
import {McBillingBatchGENERATED} from '../_generated/mc-billing-batch-generated.model';

export class McBillingBatch extends McBillingBatchGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingBatch();
  }

  // ---------------------------------------------------------------------
}
