/*BbmTestObj*/
import {McProcessRunItemGENERATED} from '../_generated/mc-process-run-item-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {ActionOptions} from '../_core/mc-html-action-options';
import {CurrencyUtil} from '../common/currency-util';

export class McProcessRunItem extends McProcessRunItemGENERATED {

  public static ACTION_SHOW_EMAIL = 'ACT_SHOW_EMAIL';
  public static ACTION_SHOW_POST = 'ACT_SHOW_POST';
  public static ACTION_SHOW_SMS = 'ACT_SHOW_SMS';

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProcessRunItem();
  }

  // ---------------------------------------------------------------------

  fldLevelActions() {
    const html = new McHtml();
    const actSet = html.addActionSet();
    actSet.horizontalFlg = true;
    if (this.csrConsumerAction.emailActionFlg) {
      actSet.addActionB(`<img src="assets/images/mc-process-icons/email-action.svg" alt=""> Email`, McProcessRunItem.ACTION_SHOW_EMAIL, this, [McGod.PERM_MC_PROCESS_FLOW_LEVEL_VIEW], `mc-button-link`, ActionOptions.OPTION_VIEW); // ${this.newItemsCount > 0 ? '' : 'mc-disable'}
    }
    if (this.csrConsumerAction.postActionFlg) {
      actSet.addActionB(`<img src="assets/images/mc-process-icons/post-action.svg"> Post`, McProcessRunItem.ACTION_SHOW_POST, this, [McGod.PERM_MC_PROCESS_FLOW_LEVEL_VIEW], `mc-button-link`, ActionOptions.OPTION_VIEW); // ${this.newItemsCount > 0 ? '' : 'mc-disable'}
    }
    if (this.csrConsumerAction.smsActionFlg) {
      actSet.addActionB(`<img src="assets/images/mc-process-icons/sms-action.svg"> SMS`, McProcessRunItem.ACTION_SHOW_SMS, this, [McGod.PERM_MC_PROCESS_FLOW_LEVEL_VIEW], `mc-button-link`, ActionOptions.OPTION_VIEW); // ${this.newItemsCount > 0 ? '' : 'mc-disable'}
    }
    return html.items;
  }

  fldStatus() {
    const statusLabel = this.getStatusCdLabel();
    let returnValue = '';
    if (this.isStatusCdERROR()) {
      returnValue = `<div class="icon-tooltip"><span>${statusLabel}</span>
                    <span class="icon-tooltip-text">${this.lastErrorMsg}</span></div>`;
    } else {
      returnValue = statusLabel;
    }
    return returnValue;
  }

  getAmountDue() {
    return CurrencyUtil.format(this.mcProcessItem.pmTransaction.amountDue, this.mcProcessItem.pmTransaction.mcFactoring.mcCurrency.code);
  }

  getTransactionDescription() {
    return this.mcProcessItem.pmTransaction.description;
  }

  getConsumerFullName() {
    return this.mcProcessItem.pmTransaction.csrConsumer.displayName;
  }
}
