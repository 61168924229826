import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {
  BaseDirective,
  McBoolean,
  McForm,
  McGod,
  McPriceListList,
  McValueLabel,
  McValueLabelList
} from "@miticon-ui/mc-core";
import {Subject} from "rxjs";

@Component({
  selector: 'mc-pricelist-pricelist-select-template-part',
  templateUrl: './mc-pricelist-pricelist-select-template-part.component.html',
  styleUrls: ['mc-pricelist-pricelist-select-template-part.component.scss']
})
export class McPricelistPricelistSelectTemplatePartComponent extends BaseDirective implements OnInit {

  templateVll!: McValueLabelList;
  saveButtonDisabledMcb = new McBoolean();
  pricelistNameValue = '';
  pricelistDescriptionValue = '';
  pricelistTemplateValue = '';
  errorMsg = '';
  mcGod = McGod.getInstance();
  templateRadioButtonsAdditionalTextVll!: McValueLabelList;
  loadRadioButtonsFlg = false;
  currency!: string | undefined;

  @Input() mcTemplates!: McPriceListList;
  @Input() mcForm!: McForm;
  @Input() resetWhenSaved = new Subject<boolean>();
  @Output() eventNext = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventCurrencySymbol = new EventEmitter<string>();

  /*Constructor*/
  constructor() {
    super();
  }
  /*On init*/
  ngOnInit() {
    this.templateRadioButtonsAdditionalTextVll = new McValueLabelList();

    if (this.mcTemplates.getCount() === 0) {
      this.errorMsg = McGod.t('cc.pricelist.there-is-no-template-in-this-entity');
    }

    if (this.mcForm.getControl('ctrlTemplate')) {
      this.pricelistTemplateValue = this.mcForm.getValue('ctrlTemplate');
      this.pricelistDescriptionValue = this.mcForm.getValue('ctrlPricelistDescription');
      this.pricelistNameValue = this.mcForm.getValue('ctrlPricelistName');
      if (this.mcForm.getControl('ctrlValidFrom')) {
        this.mcForm.formGroup.removeControl('ctrlValidFrom');
      }
      if (this.mcForm.getControl('ctrlEntitiesPricelist')) {
        this.mcForm.formGroup.removeControl('ctrlEntitiesPricelist');
      }
    }

    this.templateVll = new McValueLabelList();
    this.mcTemplates.items.forEach(item => {
      const template = new McValueLabel();
      template.value = item.id;
      template.label = item.title;
      this.templateVll.addItem(template);
      const additionalText = new McValueLabel();
      additionalText.value = item.nameOfEntityOwner;
      additionalText.label = item.description;
      this.templateRadioButtonsAdditionalTextVll.addItem(additionalText);
    });
    this.loadRadioButtonsFlg = true;
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actNext() {
    if (this.mcForm.isValid()) {
      this.eventNext.emit(this.currency);
    }
  }

  getNextLabel() {
    return `${this.mcGod.t('cc.common.view.next')} <i class='fas fa-arrow-right'></i>`;
  }

  onTemplateChange(value: number) {
    if (value) {
      this.currency = this.mcTemplates?.items?.find(item => item.id === value)?.currency.code;
      this.eventCurrencySymbol.emit(this.mcTemplates.items.find(item => item.id === value)?.currency.code);
    }
  }
}
