/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McProcessItemTable3ConfigGENERATED} from '../_generated/mc-process-item-table-3-config-generated.model';

// import {McProcessItemGENERATED} from '../_generated/mc-process-item-generated.model';

export class McProcessItemTable3Config extends McProcessItemTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
  constructor() {
    super();

    this.clearColumns();
    // this.addColumn('colIdMcProcess', 'McProcess ID', 'idMcProcess', 'idMcProcess');
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('colConsumer', this.t('cc.my-factoring.consumer'), 'getConsumerFullName()', '');
    this.addColumn('colTransaction', this.t('cc.factoring.transaction-description'), 'getTransactionDescription()', '');
    this.addColumn('colAmountDue', this.t('cc.common.view.amount-due'), 'getTransactionAmountDue()', '');
    this.addColumn('colFlow', this.t('cc.processes.flow'), 'getFlowName()', '');
    this.addColumn('colLevel', this.t('cc.processes.level'), 'getFlowLevelName()', '');
    this.addColumn('colNextActionDate', this.t('cc.processes.next-action-date'), 'getActionStartDatetimeString()', '');
    // this.addColumn("colChangeNextActionDate", "", "fldChangeNextActionDateTime()", "");
    this.addColumn('colStatusCd', this.t('cc.common.view.status'), 'getStatusCdLabel()', 'statusCd');
    // this.addColumn('colAssetCreationDatetime', 'Asset Creation Datetime', 'assetCreationDatetime', 'assetCreationDatetime');


  }
}
