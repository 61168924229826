
import {Table3Config} from '../extra/table-3-config';

// import {ZzPocConsumerGENERATED} from '../_generated/zz-poc-consumer-generated.model';

export class ZzPocConsumerTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAccountOwner', 'Account owner', 'accountOwner', 'accountOwner');
    this.addColumn('colActive', 'Active/inactive flag', 'active', 'active');
    this.addColumn('colAddressSupplement', 'Address supplement', 'addressSupplement', 'addressSupplement');
    this.addColumn('colArchived', 'Archived flag', 'archived', 'archived');
    this.addColumn('colBankName', 'Bank name', 'bankName', 'bankName');
    this.addColumn('colBic', 'BIC', 'bic', 'bic');
    this.addColumn('colBirthday', 'Birthday', 'birthday', 'birthday');
    this.addColumn('colBusinessCellphone', 'Business cellphone', 'businessCellphone', 'businessCellphone');
    this.addColumn('colBusinessTelephone', 'Business telephone', 'businessTelephone', 'businessTelephone');
    this.addColumn('colCellphone', 'Cellphone', 'cellphone', 'cellphone');
    this.addColumn('colCity', 'City', 'city', 'city');
    this.addColumn('colCompanyName', 'Company name', 'companyName', 'companyName');
    this.addColumn('colContractBaseAmount', 'Contract base amount', 'contractBaseAmount', 'contractBaseAmount');
    this.addColumn('colContractCancellationActiveOnDate', 'Contract cancellation active on date(Gekundiggt zum datum)', 'contractCancellationActiveOnDate', 'contractCancellationActiveOnDate');
    this.addColumn('colContractCancellationDate', 'Contract cancellation date(Gekundiggt am datum)', 'contractCancellationDate', 'contractCancellationDate');
    this.addColumn('colContractCancellationReason', 'Contract cancellation reason', 'contractCancellationReason', 'contractCancellationReason');
    this.addColumn('colContractEndDate', 'Contract end date(Vertragsende)', 'contractEndDate', 'contractEndDate');
    this.addColumn('colContractModel', 'Contract model', 'contractModel', 'contractModel');
    this.addColumn('colContractNumber', 'Contract number', 'contractNumber', 'contractNumber');
    this.addColumn('colContractSigningDate', 'Contract signing date(Vertragsabsclussdatum)', 'contractSigningDate', 'contractSigningDate');
    this.addColumn('colContractStartDate', 'Contract start date(Vertragsabeginn)', 'contractStartDate', 'contractStartDate');
    this.addColumn('colContractStatus', 'Contract status', 'contractStatus', 'contractStatus');
    this.addColumn('colContractType', 'Contract type', 'contractType', 'contractType');
    this.addColumn('colCountryName', 'Country name', 'countryName', 'countryName');
    this.addColumn('colCreatedAt', 'Created at', 'createdAt', 'createdAt');
    this.addColumn('colCreatedBy', 'Created by', 'createdBy', 'createdBy');
    this.addColumn('colCustomAttributes', 'Custom attributes', 'customAttributes', 'customAttributes');
    this.addColumn('colEmail', 'Email', 'email', 'email');
    this.addColumn('colEuVat', 'VAT', 'euVat', 'euVat');
    this.addColumn('colExtConsumerId', 'External Consumer ID', 'extConsumerId', 'extConsumerId');
    this.addColumn('colFax', 'Fax', 'fax', 'fax');
    this.addColumn('colFirstName', 'First name', 'firstName', 'firstName');
    this.addColumn('colGender', 'Gender', 'gender', 'gender');
    this.addColumn('colIban', 'IBAN', 'iban', 'iban');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIndividual', 'Individual/company flag', 'individual', 'individual');
    this.addColumn('colIsoCountry', 'Country ISO code', 'isoCountry', 'isoCountry');
    this.addColumn('colIsoLanguage', 'Language ISO code', 'isoLanguage', 'isoLanguage');
    this.addColumn('colLastName', 'Last name', 'lastName', 'lastName');
    this.addColumn('colPostCode', 'Post code', 'postCode', 'postCode');
    this.addColumn('colSepaMandanteDate', 'Sepa mandate date of signature', 'sepaMandanteDate', 'sepaMandanteDate');
    this.addColumn('colSepaMandanteId', 'Sepa mandante id', 'sepaMandanteId', 'sepaMandanteId');
    this.addColumn('colStateIndex', 'Index of state', 'stateIndex', 'stateIndex');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colStreet', 'Street', 'street', 'street');
    this.addColumn('colTelephone', 'Telephone', 'telephone', 'telephone');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colTypeCd', 'Type code', 'typeCd', 'typeCd');
    this.addColumn('colUpdatedAt', 'Updated at', 'updatedAt', 'updatedAt');
    this.addColumn('colUpdatedBy', 'Updated by', 'updatedBy', 'updatedBy');
    this.addColumn('colVersion', 'Version', 'version', 'version');

*/
  }
}
