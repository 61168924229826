/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McCountry} from '../models/mc-country.model';
import {McCountryList} from '../models/mc-country-list.model';
import {McCountryFilter} from '../models/mc-country-filter.model';
import {McCountryService} from '../services/mc-country.service';
import {IMcCountry} from '../_generated/mc-country.interface';
import {McGod} from "../extra/mc-god.service";

export class McCountryListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'code', 'ebReturnPeriod', 'enabled', 'idMcCurrency', 'locale', 'name', 'mcVATItemList'];
  public items: McCountry[] = [];
  public apiService: McCountryService;
 // protected tempObjList: McCountryList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McCountryList {
    console.error('MTCN-ERROR: Not Implemented: McCountryList::createNewInstance(). Add this method to final class and return new McCountryList();');
    throw new Error('Cannot McCountryList::createNewInstance(). Add this method to final class and return new McCountryList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcCountry[]): McCountryList {
    const  listObj: McCountryList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McCountry = McCountry.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McCountryList {
    const  listObj: McCountryList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McCountryService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcCountry[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McCountry = McCountry.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcCountryFilter: McCountryFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcCountryFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McCountry {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McCountry();
    }
  }

  public replaceItem(newItem: McCountry) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

