<div class="content-wrapper">
  <mcc-message [errorMsg]="errorMsg"></mcc-message>
  <lib-mc-loader [showLoader]="product.apiLoadingFlg"></lib-mc-loader>
  <div class="content-header">
    <h1>{{productId ? ("cc.product.view.edit-product" | translate) : ("cc.product.view.create-new-product" | translate)}}</h1>
  </div>
  <div class="content-body">
    <div class="row">
      <div class="col-md-6">
        <mcc-fi-radio-buttons2 label="{{'cc.common.view.status' | translate}}:"
                               name="ctrlStatus"
                               [mcForm]="mcForm"
                               [value]="product?.state"
                               [validRequiredFlg]="true"
                               [directionHorizontal]="true"
                               [optionsVll]="statusVll"></mcc-fi-radio-buttons2>

        <mcc-fi-textbox name="ctrlExternalId" label="{{ 'cc.common.edit.external-id' | translate }}:" [mcForm]="mcForm" [value]="product?.externalId">
        </mcc-fi-textbox>

        <div *ngIf="referenceEntitiesVll.items.length > 0">
          <mcc-fi-select name="ctrlRefType"
                         label="{{'cc.common.ref-type' | translate}}:"
                         [value]="product?.refTypeCd"
                         [optionsVll]="refTypeVll"
                         (eventValueChanged)="onRefTypeChange($event)"
                         [mcForm]="mcForm"></mcc-fi-select>
          <mcc-fi-select name="ctrlRefId"
                       label="{{'cc.common.ref-id' | translate}}:"
                       [value]="product?.refId"
                       [optionsVll]="referenceEntitiesVll"
                       [mcForm]="mcForm"></mcc-fi-select>
        </div>

        <mcc-fi-textbox name="ctrlName" label="{{'cc.common.view.name' | translate}}:" [mcForm]="mcForm" [validRequiredFlg]="true" [value]="product?.name">
        </mcc-fi-textbox>

        <mcc-fi-textarea name="ctrlDescription" label="{{ 'cc.common.view.description' | translate }}:" [value]="product?.description"
                         [numCols]="35"
                         [mcForm]="mcForm"></mcc-fi-textarea>

        <mcc-fi-number-type nameForValue="ctrlUnitCostValue"
                            nameForType="ctrlUnitCostType"
                            [value]="product?.unitPrice"
                            validRequiredFlg="true"
                            label="{{'cc.product.view.unit-cost' | translate}}:"
                            validMinNumber="0"
                            [mcForm]="mcForm"
                            [labelOnTopFlg]="true">
        </mcc-fi-number-type>


        <!--<mcc-fi-textbox name="ctrlUnitsInStock" label="{{'cc.product.view.units-in-stock' | translate}}" [mcForm]="mcForm" validIntegerNumber="true" [value]="product?.unitsInStock">
        </mcc-fi-textbox>-->
      </div>
      <div class="col-md-6">
        <mcc-fi-select name="ctrlPricingType"
                       label="{{'pp.product.pricing-type' | translate}}:"
                       [value]="product.pricingTypeCd ? product.pricingTypeCd : ''"
                       [validRequiredFlg]="true"
                       placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                       [optionsVll]="pricingTypeVll"
                       [mcForm]="mcForm"
        ></mcc-fi-select>

        <mcc-fi-select name="ctrlCurrency" label="{{'cc.common.currency' | translate}}:"
                       [value]="product.currency ? product.currency : ''"
                       [validRequiredFlg]="true"
                       placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                       [optionsVll]="currencyVll"
                       (eventValueChanged)="onCurrencyValueChanged($event)"
                       [mcForm]="mcForm">
        </mcc-fi-select>


        <mcc-fi-select  name="ctrlVat" label="{{'cc.product.view.vat' | translate}}:"
                        [value]="product.idVat ? product.idVat : ''"
                        [validRequiredFlg]="true"
                        placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                        [optionsVll]="vatVll"
                        [mcForm]="mcForm"></mcc-fi-select>

        <mcc-fi-select  name="ctrlChargingType" label="{{'pp.product.charging-type' | translate}}:"
                        [value]="product.chargingTypeCd ? product.chargingTypeCd : ''"
                        [validRequiredFlg]="true"
                        placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                        [optionsVll]="chargingTypeVll"
                        [mcForm]="mcForm"></mcc-fi-select>

        <mcc-fi-select *ngIf="displayProductType()"
                        name="ctrlProductType" label="{{'pp.product.product-type' | translate}}:"
                        [value]="product.serviceTypeCd ? product.serviceTypeCd : ''"
                        [validRequiredFlg]="true"
                        placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                        [optionsVll]="productTypeVll"
                        [mcForm]="mcForm"></mcc-fi-select>

        <mcc-fi-select  name="ctrlCategory" label="{{'cc.jobs.view.category' | translate}}:"
                        [value]="product.idPpCategory ? product.idPpCategory : ''"
                        placeholderOption="{{'cc.product.uncategorized' | translate}}"
                        [optionsVll]="categoriesVll"
                        [mcForm]="mcForm"></mcc-fi-select>
      </div>
    </div>
  </div>

  <div class="content-footer">

    <button class="btn btn-secondary move-right"
            (click)="actCancel()"> {{'cc.common.edit.cancel' | translate}}
    </button>
    <button class="btn btn-primary" (click)="actSave()"> {{'cc.common.view.save' | translate}}</button>
  </div>
</div>
