<mat-form-field appearance="outline">
  <mat-label>{{setup.placeholder | translate}}</mat-label>
  <input type="{{setup.inputType}}"
         name="{{setup.name}}"
         id="{{setup.name}}"
         required="{{setup.required}}"
         value="{{setup.value}}"
         (keyup)="onKeyUp($event)"
         matInput/>
</mat-form-field>

