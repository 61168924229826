import {
  FiTextboxConfig,
  MccFiSelectOptionComponent,
  MccFiSelectOptionConfig,
  MccFiTextboxComponent,
} from "@miticon-ui/mc-components";

export class McStarmoneyFilterConfig {
  constructor() {
    const optionsKvm = new Map<string, string>();
    optionsKvm.set("Sample title 0", "Sample: Title 0");
    optionsKvm.set("Sample title 1", "Sample: Title 1");
    optionsKvm.set("Sample title 2", "Sample: Title 2");

    /*    this.addComponent(MccFiTextboxComponent, new FiTextboxConfig({placeholder: "search", name: "filter-searchs"})).addExtraCssClass("col-md-2 d-inline-block");
    this.addComponent(MccFiSelectOptionComponent, new MccFiSelectOptionConfig(optionsKvm, "", "", "a")).addExtraCssClass("col-md-2 d-inline-block");
    this.addComponent(MccFiSelectOptionComponent, new MccFiSelectOptionConfig(optionsKvm, "", "", "a")).addExtraCssClass("col-md-2 d-inline-block");*/
  }
}
