<!--THIS CONTAINER IS MANDATORY-->
<div class="content-wrapper">
  <lib-mc-loader
    [showLoader]="mcPriceList.apiLoadingFlg || mcServiceCatalogList.apiLoadingFlg"></lib-mc-loader>


  <div class="content-header">
    <h1 *ngIf="mcPriceList.exists()">Edit McPriceList (#{{mcPriceList.id}})</h1>
    <h1 *ngIf="!mcPriceList.exists()">{{'cc.pricelist.create-new-pricelist-template' | translate}}</h1>

  </div>


  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-body">
    <mcc-message [errorMsg]="mcPriceList.apiErrorMessage"></mcc-message>
    <mcc-message [errorMsg]="errorMsg"></mcc-message>

    <div class="row">
      <div class="col-md-12">

        <div class="info-summary-header-lite">
          <h4>{{'cc.pricelist.template-information' | translate}}</h4>
        </div>
<div class="form-inner-content mt-3 pl-1">
  <div class="row">
    <div class="col-md-6">
      <mcc-fi-textbox name="ctrlTemplateName" label="{{'cc.pricelist.template-name' | translate}}"
                      [value]="mcPriceList.title" [validRequiredFlg]="true"
                      [mcForm]="mcForm">
      </mcc-fi-textbox>

      <mcc-fi-textarea name="ctrlTemplateDescription" label="{{'cc.pricelist.template-description' | translate}}" numCols="45"
                       [value]="mcPriceList.description" [validRequiredFlg]="true"
                       [mcForm]="mcForm">
      </mcc-fi-textarea>
    </div>
    <div class="col-md-6">
      <mcc-fi-select  name="selCurrency" label="{{'cc.common.currency' | translate}}"
                      placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                      [validRequiredFlg]="true"
                      [value]="''"
                      (eventValueChanged)="onCurrencyValueChanged($event)"
                      [optionsVll]="currencyVll"
                      [mcForm]="mcForm"></mcc-fi-select>
    </div>
  </div>


</div>

        <div class="info-summary-header-lite mb-3">
          <h4>Services</h4>
        </div>

        <ng-container *ngIf="loadingFinished">
          <div class="w-100" *ngFor="let item of mcPriceList.mcBillingService2PriceListItemList.items; index as i">

            <div class="info-summary-lite inner-content d-flex">
              <div class="w-50">
                <div class="service-item-name">
                <mcc-row-title-description title="{{'cc.pricelist.service-name' | translate}}: "
                                           [description]="fldServiceCatalog(item.idBillingService).title"></mcc-row-title-description></div>
                <mcc-row-title-description title="{{'cc.pricelist.activity-unit' | translate}}: " [description]="fldUnitType(item.activityUnitTypeCd)"></mcc-row-title-description>
              </div>

              <div class="w-50">
                <mcc-row-title-description title="{{'cc.pricelist.service-description' | translate}}: "
                                           [description]="fldServiceCatalog(item.idBillingService).description"></mcc-row-title-description>
              </div>
            </div>

            <div *ngIf="!fldServiceCatalog(item.idBillingService).flgPriceFromContract" class="w-100">
              <mc-pricelist-pricelist-service-edit-part [mcForm]="mcForm" [index]="i" [currency]="currencyCode"
                                                        [service2PriceList]="item"></mc-pricelist-pricelist-service-edit-part>
            </div>

          </div>
        </ng-container>

      </div>

    </div><!-- row -->

  </div><!-- content-body -->

  <div class="content-footer">

    <mcc-button class="move-right" extraCssClasses="btn btn-secondary move-right" (eventClicked)="actCancel();"
                label="Cancel"></mcc-button>
    <mcc-button extraCssClasses="btn btn-primary"
                (eventClicked)="actSave();" label="Save" [disabledMcb]="saveButtonDisabledMcb"
                disableOnFirstClickFlg="true"></mcc-button>

  </div>

</div>
