import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {McProcessFlow, CsrRecurringDetails, McValueLabelList, McGod} from '@miticon-ui/mc-core';
import {
  McForm,
  McBoolean,
  McTrigger,
} from "@miticon-ui/mc-core";
import {Router, ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {Subscription} from "rxjs";

@Component({
  selector: "mc-process-process-flow-general-flow-info-edit",
  templateUrl: "./mc-process-process-flow-general-flow-info-edit.component.html",
})
export class McProcessProcessFlowGeneralFlowInfoEditComponent implements OnInit {

  mcForm: McForm = new McForm();
  saveButtonDisabledMcb = new McBoolean();
  errorMsgs: string[] = [];
  statusVlm = new Map<string, string>();
  optionsWeekDaysVll: McValueLabelList = new McValueLabelList();
  selectedWeekdayToSendNotification!: string;
  csrRecurringDetails = new CsrRecurringDetails();

  // -- Input --
  @Input() mcProcessFlowId!: number;
  @Input() mcProcessFlow!: McProcessFlow;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();




  // ---------------------------------------------------------------------
  ngOnInit() {
    this.optionsWeekDaysVll = this.csrRecurringDetails.getWeekDaysVll();

    this.statusVlm.set(McProcessFlow.STATUS_CD_ACTIVE, "Active");
    this.statusVlm.set(McProcessFlow.STATUS_CD_INACTIVE, "Inactive");

    if (!this.mcProcessFlow) {
      this.mcProcessFlow = new McProcessFlow();
    }

    this.actLoad();

  }


  // ---------------------------------------------------------------------
  public actLoad(): void {
    if (!this.mcProcessFlow) {
      this.mcProcessFlow = new McProcessFlow();
      if (this.mcProcessFlowId > 0) {
        this.mcProcessFlow.loadById(this.mcProcessFlowId, () => {
          this.validatePrerequisites();
        });
      }
    } else {
      // new item
      this.validatePrerequisites();
    }

  }

  // ---------------------------------------------------------------------
  actSave() {

    if (this.mcForm.isValid()) {

      const currentFlowStatus = this.mcProcessFlow.statusCd;

      this.mcProcessFlow.statusCd = this.mcForm.getValue("ctrlStatus");
      this.mcProcessFlow.title = this.mcForm.getValue("ctrlTitle");
      this.mcProcessFlow.description = this.mcForm.getValue("ctrlDescription");
      this.mcProcessFlow.weekdayToSendNotification = this.mcForm.getValue('selDay');

      this.mcProcessFlow.save(() => {
        this.eventSaved.emit();
      }, () => {
        this.saveButtonDisabledMcb.setFalse();
        this.mcProcessFlow.statusCd = currentFlowStatus;
      });
    } else {
      this.saveButtonDisabledMcb.setFalse();
    }

  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.mcProcessFlow.exists()) {

      this.mcProcessFlow.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

// ---------------------------------------------------------------------
  validatePrerequisites() {
    this.errorMsgs = [];
    // populate validation here on required inputs
  }

  // ---------------------------------------------------------------------
  hasErrors(): boolean {
    return this.errorMsgs.length > 0;
  }

  onDayChanged(value: string) {
    this.selectedWeekdayToSendNotification = value;
  }
}
