<div *ngIf="mcUser.apiSuccessFlg" class="mc-user-profile">

  <div class="row">
    <div class="col-md-12">
      <div class="colored-header">
        <span style="display: flex; flex-direction: row">
          <h2 style="margin-right: 10px; align-self: center;">{{'cc.common.personal-info' | translate}}</h2>
          <mcc-modal1-wrapper *ngIf="showUserAddEditPart.value" [showMcb]="showUserAddEditPart" modalSizeCd="small">
            <mc-user-user-add-edit-part *ngIf="userOldObject" [parentData]="userOldObject" (eventSaved)="onUserProfileAddEditSaved($event)" (eventCanceled)="onUserProfileAddEditCanceled($event)"
            ></mc-user-user-add-edit-part>
          </mcc-modal1-wrapper>
        <a class="mc-personal-info-edit mc-cursor-pointer ml-1" style="color: #ffffff;" (click)="showUserAddEditPart.setTrue()"><i class="fas fa-pen"></i></a>
        </span>
      </div>
      <div class="info-container">
        <div class="row">
          <div class="col-md-2">
            <div class="user-image-placeholder">
              <img *ngIf="!userImg" src="assets/images/user-icon.png">
              <img *ngIf="userImg" [src]="userImg">
              <!--user image would come here-->
            </div>
          </div>
          <div class="col-md-10">
            <h3>{{mcUser.fldFullName()}}</h3>
            <div>
              <p style="text-transform: uppercase">{{'cc.common.view.status' | translate}}
                <img *ngIf="userStatus === 'ACTIVE'" class="mc-user-profile-status-icon" src="../../../../assets/images/common-icons/active.svg">
                <img *ngIf="userStatus === 'INACTIVE'" class="mc-user-profile-status-icon" src="../../../../assets/images/common-icons/inactive.svg">
                {{userStatus}}<span></span></p>
            </div>
            <div><p style="text-transform: uppercase">{{'cc.common.edit.email' | translate}} <i class="far fa-envelope"></i> <span>{{mcUser.email}} </span></p></div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="info-container-secondary">
    <div class="row">
      <div class="col">
        <mcc-row-title-description title="{{'cc.common.edit.first-name' | translate}}" [description]="mcUser.firstname"></mcc-row-title-description>
        <mcc-row-title-description title="{{'cc.common.edit.last-name' | translate}}" [description]="mcUser.lastname"></mcc-row-title-description>
        <mcc-row-title-description title="{{'cc.common.view.nickname' | translate}}" [description]="mcUser.nickname"></mcc-row-title-description>
        <mcc-row-title-description title="{{'cc.common.edit.gender' | translate}}" [description]="mcUser.gender"></mcc-row-title-description>
      </div>
      <div class="col">
        <mcc-row-title-description title="{{'cc.users.edit.language' | translate}}" [description]="getUserLanguage()"></mcc-row-title-description>
        <mcc-row-title-description title="{{'cc.users.edit.timezone' | translate}}" [description]="getUserTimezone()"></mcc-row-title-description>
        <mcc-row-title-description title="{{'cc.users.edit.country' | translate}}" [descriptionInnerHtml]="getUserCountry()"></mcc-row-title-description>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="colored-header">
        <h2>{{'cc.common.account-info' | translate}}</h2>
      </div>

      <div class="info-container-secondary">
        <div class="row">
          <div class="col">
            <div class="info-cell">
              <div class="icon-holder"><img src="assets/images/2factor.svg"></div>
              <div class="account-data">
                <span class="info-title">{{'cc.common.view.two-factor-authentication' | translate}}</span>
                <mc-two-fa-account-authentication-modal (childEvent)="onChange2Fa($event)" [userId]="mcUserId" [parentData]="isEnabledTwoFa"></mc-two-fa-account-authentication-modal>
                <br>
                <span style="font-weight: 500">{{'cc.user.current-status' | translate}}
                  <img style="margin-left: 13px;" class="mc-user-profile-status-icon" [src]="isEnabledTwoFa ? 'assets/images/green-checkmark.svg' : 'assets/images/disabled.svg'"><span> {{twoFaAuth}}</span></span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="info-cell">
              <div class="icon-holder"><img src="assets/images/lock.svg"></div>
              <div class="account-data">
                <span class="info-title">{{'cc.common.view.change-password' | translate}}</span>
                <mc-change-password-modal></mc-change-password-modal>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--<div class="row">
        <div class="col-md-12">
          <h2>Assigned roles</h2>
        </div>
      </div>-->


    </div>


    <router-outlet></router-outlet>
