/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CcMollieTransactionFilter} from '../models/cc-mollie-transaction-filter.model';

export class CcMollieTransactionFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CcMollieTransactionFilter {
    console.error('MTCN-ERROR: Not Implemented: CcMollieTransactionFilter::createNewInstance(). Add this method to final class and return new CcMollieTransactionFilter();');
      throw new Error('Cannot CcMollieTransactionFilter::createNewInstance(). Add this method to final class and return new CcMollieTransactionFilter();');
  }

}
