/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBillingServiceUnitType} from '../models/mc-billing-service-unit-type.model';
import {McBillingServiceUnitTypeService} from '../services/mc-billing-service-unit-type.service';
import {IMcBillingServiceUnitType} from '../_generated/mc-billing-service-unit-type.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McBillingServiceUnitTypeGENERATED extends BaseObject {


    public static readonly TYPE_CD_SYSTEM_SETUP: string = 'SYSTEM_SETUP';
    public static readonly TYPE_CD_CONTRACT_TYPE_CHANGE: string = 'CONTRACT_TYPE_CHANGE';
    public static readonly TYPE_CD_USER_PER_MONTH: string = 'USER_PER_MONTH';
    public static readonly TYPE_CD_PER_BATCH: string = 'PER_BATCH';
    public static readonly TYPE_CD_PER_MATCH: string = 'PER_MATCH';
    public static readonly TYPE_CD_PER_WITHDRAWAL: string = 'PER_WITHDRAWAL';
    public static readonly TYPE_CD_PER_TRANSACTION: string = 'PER_TRANSACTION';
    public static readonly TYPE_CD_PER_GENERATED_PDF: string = 'PER_GENERATED_PDF';
    public static readonly TYPE_CD_PER_SENT_EMAIL: string = 'PER_SENT_EMAIL';
    public static readonly TYPE_CD_PER_RESENT_EMAIL: string = 'PER_RESENT_EMAIL';
    public static readonly TYPE_CD_X_EUR_PER_MEMBER_ON_ONE_ENTITY: string = 'X_EUR_PER_MEMBER_ON_ONE_ENTITY';
    public static readonly TYPE_CD_X_PERCENT_PER_TRANSACTION_TURNOVER_ON_ONE_ENTITY: string = 'X_PERCENT_PER_TRANSACTION_TURNOVER_ON_ONE_ENTITY';
  public static readonly TYPE_CD_LIST__ALL = [
    McBillingServiceUnitTypeGENERATED.TYPE_CD_SYSTEM_SETUP,
    McBillingServiceUnitTypeGENERATED.TYPE_CD_CONTRACT_TYPE_CHANGE,
    McBillingServiceUnitTypeGENERATED.TYPE_CD_USER_PER_MONTH,
    McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_BATCH,
    McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_MATCH,
    McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_WITHDRAWAL,
    McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_TRANSACTION,
    McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_GENERATED_PDF,
    McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_SENT_EMAIL,
    McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_RESENT_EMAIL,
    McBillingServiceUnitTypeGENERATED.TYPE_CD_X_EUR_PER_MEMBER_ON_ONE_ENTITY,
    McBillingServiceUnitTypeGENERATED.TYPE_CD_X_PERCENT_PER_TRANSACTION_TURNOVER_ON_ONE_ENTITY
];

  public apiService: McBillingServiceUnitTypeService;
  public _rawJson: IMcBillingServiceUnitType;
    id: number = 0;
  idMcBillingService: number;
  typeCd: string;


  public properties: string[] = ['id', 'idMcBillingService', 'typeCd'];
  public propertiesRegular: string[] = ['id', 'idMcBillingService', 'typeCd'];
  public propertiesSpecial: string[] = [];



  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingServiceUnitTypeGENERATED.TYPE_CD_SYSTEM_SETUP, McGod.t('System setup'));
    list.add(McBillingServiceUnitTypeGENERATED.TYPE_CD_CONTRACT_TYPE_CHANGE, McGod.t('Contract type change'));
    list.add(McBillingServiceUnitTypeGENERATED.TYPE_CD_USER_PER_MONTH, McGod.t('User per month'));
    list.add(McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_BATCH, McGod.t('Per batch'));
    list.add(McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_MATCH, McGod.t('Per match'));
    list.add(McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_WITHDRAWAL, McGod.t('Per withdrawal'));
    list.add(McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_TRANSACTION, McGod.t('Per transaction'));
    list.add(McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_GENERATED_PDF, McGod.t('Per generated PDF'));
    list.add(McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_SENT_EMAIL, McGod.t('Per sent email'));
    list.add(McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_RESENT_EMAIL, McGod.t('Per resent email'));
    list.add(McBillingServiceUnitTypeGENERATED.TYPE_CD_X_EUR_PER_MEMBER_ON_ONE_ENTITY, McGod.t('Per franchise member on one entity'));
    list.add(McBillingServiceUnitTypeGENERATED.TYPE_CD_X_PERCENT_PER_TRANSACTION_TURNOVER_ON_ONE_ENTITY, McGod.t('X % per transactions turnover on one entity'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = McBillingServiceUnitTypeGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McBillingServiceUnitType {
    console.error('MTCN-ERROR: Not Implemented: McBillingServiceUnitType::createNewInstance(). Add this method to final class and return new McBillingServiceUnitType();');
    throw new Error('Cannot McBillingServiceUnitType::createNewInstance(). Add this method to final class and return new McBillingServiceUnitType();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBillingServiceUnitType): McBillingServiceUnitType {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingServiceUnitTypeService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBillingServiceUnitType AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getTypeCdLabel(): string {
    return McBillingServiceUnitTypeGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdSYSTEM_SETUP(): boolean {
    const result = (this.typeCd === McBillingServiceUnitTypeGENERATED.TYPE_CD_SYSTEM_SETUP);

    return result;
  }

 public  isTypeCdCONTRACT_TYPE_CHANGE(): boolean {
    const result = (this.typeCd === McBillingServiceUnitTypeGENERATED.TYPE_CD_CONTRACT_TYPE_CHANGE);

    return result;
  }

 public  isTypeCdUSER_PER_MONTH(): boolean {
    const result = (this.typeCd === McBillingServiceUnitTypeGENERATED.TYPE_CD_USER_PER_MONTH);

    return result;
  }

 public  isTypeCdPER_BATCH(): boolean {
    const result = (this.typeCd === McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_BATCH);

    return result;
  }

 public  isTypeCdPER_MATCH(): boolean {
    const result = (this.typeCd === McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_MATCH);

    return result;
  }

 public  isTypeCdPER_WITHDRAWAL(): boolean {
    const result = (this.typeCd === McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_WITHDRAWAL);

    return result;
  }

 public  isTypeCdPER_TRANSACTION(): boolean {
    const result = (this.typeCd === McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_TRANSACTION);

    return result;
  }

 public  isTypeCdPER_GENERATED_PDF(): boolean {
    const result = (this.typeCd === McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_GENERATED_PDF);

    return result;
  }

 public  isTypeCdPER_SENT_EMAIL(): boolean {
    const result = (this.typeCd === McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_SENT_EMAIL);

    return result;
  }

 public  isTypeCdPER_RESENT_EMAIL(): boolean {
    const result = (this.typeCd === McBillingServiceUnitTypeGENERATED.TYPE_CD_PER_RESENT_EMAIL);

    return result;
  }

 public  isTypeCdX_EUR_PER_MEMBER_ON_ONE_ENTITY(): boolean {
    const result = (this.typeCd === McBillingServiceUnitTypeGENERATED.TYPE_CD_X_EUR_PER_MEMBER_ON_ONE_ENTITY);

    return result;
  }

 public  isTypeCdX_PERCENT_PER_TRANSACTION_TURNOVER_ON_ONE_ENTITY(): boolean {
    const result = (this.typeCd === McBillingServiceUnitTypeGENERATED.TYPE_CD_X_PERCENT_PER_TRANSACTION_TURNOVER_ON_ONE_ENTITY);

    return result;
  }


  
}
