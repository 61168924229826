
import {Table3Config} from '../extra/table-3-config';

// import {EbSepaFileReportGENERATED} from '../_generated/eb-sepa-file-report-generated.model';

export class EbSepaFileReportTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colEbSepaFile', 'Sepa File', 'ebSepaFile', 'ebSepaFile');
    this.addColumn('colFtAcceptedForFactoringA', '...', 'ftAcceptedForFactoringA', 'ftAcceptedForFactoringA');
    this.addColumn('colFtAcceptedForFactoringB', '...', 'ftAcceptedForFactoringB', 'ftAcceptedForFactoringB');
    this.addColumn('colFtAcceptedForFactoringC', '...', 'ftAcceptedForFactoringC', 'ftAcceptedForFactoringC');
    this.addColumn('colFtImmediatelyPayableBalanceA', '...', 'ftImmediatelyPayableBalanceA', 'ftImmediatelyPayableBalanceA');
    this.addColumn('colFtImmediatelyPayableBalanceB', '...', 'ftImmediatelyPayableBalanceB', 'ftImmediatelyPayableBalanceB');
    this.addColumn('colFtImmediatelyPayableBalanceC', '...', 'ftImmediatelyPayableBalanceC', 'ftImmediatelyPayableBalanceC');
    this.addColumn('colFtLaterPayableBalanceA', '...', 'ftLaterPayableBalanceA', 'ftLaterPayableBalanceA');
    this.addColumn('colFtLaterPayableBalanceB', '...', 'ftLaterPayableBalanceB', 'ftLaterPayableBalanceB');
    this.addColumn('colFtLaterPayableBalanceC', '...', 'ftLaterPayableBalanceC', 'ftLaterPayableBalanceC');
    this.addColumn('colFtNotAcceptedForFactoringA', '...', 'ftNotAcceptedForFactoringA', 'ftNotAcceptedForFactoringA');
    this.addColumn('colFtNotAcceptedForFactoringB', '...', 'ftNotAcceptedForFactoringB', 'ftNotAcceptedForFactoringB');
    this.addColumn('colFtNotAcceptedForFactoringC', '...', 'ftNotAcceptedForFactoringC', 'ftNotAcceptedForFactoringC');
    this.addColumn('colIdEbSepaFile', 'SEPA file ID', 'idEbSepaFile', 'idEbSepaFile');
    this.addColumn('colReportForFactoringFlg', 'Report for factoring flag', 'reportForFactoringFlg', 'reportForFactoringFlg');
    this.addColumn('colStBatchFeeA', '...', 'stBatchFeeA', 'stBatchFeeA');
    this.addColumn('colStBatchFeeB', '...', 'stBatchFeeB', 'stBatchFeeB');
    this.addColumn('colStBatchFeeC', '...', 'stBatchFeeC', 'stBatchFeeC');
    this.addColumn('colStBatchFeePlusVatC', '...', 'stBatchFeePlusVatC', 'stBatchFeePlusVatC');
    this.addColumn('colStFactoringFeeA', '...', 'stFactoringFeeA', 'stFactoringFeeA');
    this.addColumn('colStFactoringFeeB', '...', 'stFactoringFeeB', 'stFactoringFeeB');
    this.addColumn('colStFactoringFeeC', '...', 'stFactoringFeeC', 'stFactoringFeeC');
    this.addColumn('colStImmediatelyPayableTransactionCount', 'Immediately payable transaction count for non-factoring', 'stImmediatelyPayableTransactionCount', 'stImmediatelyPayableTransactionCount');
    this.addColumn('colStNonFactoringFeePerTransactionB', 'Non-factoring fee per transaction', 'stNonFactoringFeePerTransactionB', 'stNonFactoringFeePerTransactionB');
    this.addColumn('colStPlusVatC', '...', 'stPlusVatC', 'stPlusVatC');
    this.addColumn('colStTotalFactoringFeeToBeDeductedC', '...', 'stTotalFactoringFeeToBeDeductedC', 'stTotalFactoringFeeToBeDeductedC');
    this.addColumn('colTtFactoringFeeA', '...', 'ttFactoringFeeA', 'ttFactoringFeeA');
    this.addColumn('colTtFactoringFeeB', '...', 'ttFactoringFeeB', 'ttFactoringFeeB');
    this.addColumn('colTtFactoringFeeC', '...', 'ttFactoringFeeC', 'ttFactoringFeeC');
    this.addColumn('colTtImmediatelyPayableBalanceA', '...', 'ttImmediatelyPayableBalanceA', 'ttImmediatelyPayableBalanceA');
    this.addColumn('colTtImmediatelyPayableBalanceB', '...', 'ttImmediatelyPayableBalanceB', 'ttImmediatelyPayableBalanceB');
    this.addColumn('colTtImmediatelyPayableBalanceC', '...', 'ttImmediatelyPayableBalanceC', 'ttImmediatelyPayableBalanceC');
    this.addColumn('colTtPayableBalanceC', '...', 'ttPayableBalanceC', 'ttPayableBalanceC');

*/
  }
}
