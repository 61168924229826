/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {WebFileTable3ConfigGENERATED} from '../_generated/web-file-table-3-config-generated.model';

// import {WebFileGENERATED} from '../_generated/web-file-generated.model';

export class WebFileTable3Config extends WebFileTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.addColumn('colIdMcSystemUser', 'User', 'idMcSystemUser', 'idMcSystemUser');
    this.addColumn('colStatusCd', 'Status', 'statusCd', 'statusCd');
    this.addColumn('colFilename', 'Filename', 'filename', 'filename');
    this.addColumn('colPath', 'Path', 'path', 'path');
    this.addColumn('colExt', 'Extension', 'ext', 'ext');
    this.addColumn('colUploadDatetime', 'Upload DateTime', 'uploadDatetime', 'uploadDatetime');
    this.addColumn('colDelimiter', 'Delimiter', 'delimiter', 'delimiter');


  }
}
