/*BbmTestObj*/
import {McConsumerGENERATED} from '../_generated/mc-consumer-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {McDateUtils} from '../common/mc-date-utils';
import {ActionOptions} from '../_core/mc-html-action-options';

export class McConsumer extends McConsumerGENERATED {
  public static ACTION_SHOW_PROFILE = 'PROFILE';
  public static ACTION_SHOW_EDIT = 'EDIT';
  public static ACTION_SHOW_ADD_TRANSACTION = 'AT';
  public static ACTION_SHOW_HISTORY = 'HISTORY';
  public static ACTION_SHOW_ACTION_HISTORY = 'ACT_SHOW_ACTION_HISTORY';

  private _fldActions!: McHtml | null;
  private _fldActionsSmartSearchBox: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McConsumer();
  }

  // ---------------------------------------------------------------------


  fldBlacklisted() {
    /*if (this.activeBlacklist.exists()) { // TODO Blacklist
      return 'Blacklisted';
    } else {
      return '';
    }*/
  }

  individual(): boolean {
    return this.typeCd === McConsumer.TYPE_CD_PERSON;
  }

  fldName() {
    //   if (this.individual === true) {
    return ''.concat(this.fldFullName(), '<br>', this.companyName);
    //  } else {
    //    return this.companyName;
    //  }
  }

  fldActions() {
    // this.router.navigate([`/admin/entity/${entityId}/management/consumers/edit/${content.id}`], {queryParams: {activeTab: "paymentInfoTab"}});

//    return "dasa je car";

    if (!this._fldActions) {
      const html = new McHtml();
      // html.addLinkB('<i class="fa mc-submit-color fa-pen px-1 mc-cursor-pointer"></i>', `/admin/entity/${this.idMcEntity}/management/consumers/edit/${this.id}`, this, '', {});
      // html.addLinkB('<i class="fas mc-submit-color fa-file-invoice"></i>', `/admin/entity/${this.idMcEntity}/management/consumers/edit/${this.id}`, this,  '', {queryParams: {activeTab: 'paymentInfoTab'}});

      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.common.view-profile')}`, McConsumer.ACTION_SHOW_PROFILE, this, [McGod.PERM_MC_CONSUMER_VIEW], '', ActionOptions.OPTION_VIEW);
      actSet.addActionB(`<i class="fa mc-submit-color fa-pen mc-cursor-pointer"></i> ${McGod.t('cc.consumer.edit-consumer')}`, McConsumer.ACTION_SHOW_EDIT, this, [McGod.PERM_MC_CONSUMER_EDIT], '', ActionOptions.OPTION_EDIT);
      actSet.addLinkB(`<i class="fa mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.consumers.view.history')}`, `/entity/${this.idMcEntity}/consumers/history/${this.id}`, this, [McGod.PERM_MC_CONSUMER_HISTORY_VIEW], '', ActionOptions.OPTION_VIEW);

      // actSet.addLinkB('<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> View', `/entity/${this.idMcEntity}/consumers/profile/${this.id}`, this);
      // actSet.addLinkB('<i class="fa mc-submit-color fa-pen px-1 mc-cursor-pointer"></i> Edit', `/admin/entity/${this.idMcEntity}/management/consumers/edit/${this.id}`, this, '', {queryParams: {activeTab: 'paymentInfoTab'}});
      // OLD actSet.addActionB('<i class="fa mc-submit-color fa-file-invoice px-1 mc-cursor-pointer"></i> History', McConsumer.ACTION_SHOW_HISTORY, this);
      actSet.addLinkB(`<i class="fa mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.common.view.view-transactions')}`, `/entity/${this.idMcEntity}/consumers/transactions/${this.id}`, this, [McGod.PERM_MC_CONSUMER_TRANSACTION_VIEW], '', ActionOptions.OPTION_VIEW);
      actSet.addActionB(`<i class="fa mc-submit-color fa-pen mc-cursor-pointer"></i> ${McGod.t('cc.common.view.add-transaction')}`, McConsumer.ACTION_SHOW_ADD_TRANSACTION, this, [McGod.PERM_EB_FACTORING_MY_TRANSACTION_ADD], '', ActionOptions.OPTION_EDIT);
      actSet.addLinkB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.common.invoices')}`, `/admin/entity/${this.idMcEntity}/management/consumers/edit/${this.id}`, this, [McGod.PERM_MC_TEMP_INVOICES_VIEW], '', ActionOptions.OPTION_VIEW, {queryParams: {activeTab: 'paymentInfoTab'}});
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> Action History`, McConsumer.ACTION_SHOW_ACTION_HISTORY, this, [McGod.PERM_MC_CONSUMER_ACTION_HISTORY_VIEW], '', ActionOptions.OPTION_VIEW);
      this._fldActions = html;
    }

    return this._fldActions;
  }

  // ---------------------------------------------------------------------
  fldFullName() {
    return this.firstName + ' ' + this.lastName;
  }

  fldAddress() {
    return this.cleanStr(this.street) + ' ' + this.cleanStr(this.addressSupplement) + '<br>' + this.cleanStr(this.postCode) + ' ' + this.cleanStr(this.city);
  }

  getNow() {
    return McDateUtils.newFormatDateTimeString(new Date());
  }

  cleanStr(str: string) {
    if (str) {
      return str;
    } else {
      return '';
    }
  }

  fldSmartSearchBoxActions() {
    if (!this._fldActionsSmartSearchBox) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addLinkB(`<i class="fa mc-submit-color fa-file-invoice px-1 mc-cursor-pointer"></i> ${McGod.t('cc.common.view.view-transactions')}`, `/entity/${this.idMcEntity}/consumers/transactions/${this.id}`, this, [McGod.PERM_MC_CONSUMER_TRANSACTION_VIEW], '', ActionOptions.OPTION_VIEW);
      actSet.addLinkB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.common.invoices')}`, `/admin/entity/${this.idMcEntity}/management/consumers/edit/${this.id}`, this, [McGod.PERM_MC_TEMP_INVOICES_VIEW], '', ActionOptions.OPTION_VIEW, {queryParams: {activeTab: 'paymentInfoTab'}});
      this._fldActionsSmartSearchBox = html;
    }

    return this._fldActionsSmartSearchBox;
  }

  fldNameIndividualOrCompany(): string {
    switch(this.typeCd) {
      case "PERSON":
        return this.fldFullName();
        break;
      case "COMPANY":
        return this.companyName;
        break;
      default:
        return '';
    }
  }

  getFormattedDate(date: any): string {
    if (date)
      return McDateUtils.formatDateWithoutTimezone(date);
     else {
      return '';
    }
  }



}
