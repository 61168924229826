import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import { McLoginRoutingModule } from './mc-login-routing.module';
import { SetupComponent } from './pages/setup/setup.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TwoFaLoginComponent } from './pages/two-fa-login/two-fa-login.component';
import { AuthLockedComponent } from './pages/auth-locked/auth-locked.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import {LoginComponent} from './pages/login/login.component';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {IncompleteProfileComponent} from './pages/incomplete-profile/incomplete-profile.component';
import {TranslateModule} from '@ngx-translate/core';
import {QRCodeModule} from "angularx-qrcode";

@NgModule({
  declarations: [
    LoginComponent,
    SetupComponent,
    TwoFaLoginComponent,
    ForgotPasswordComponent,
    AuthLockedComponent,
    IncompleteProfileComponent
  ],
  exports: [
    LoginComponent,
    SetupComponent,
    McLoginRoutingModule,
    IncompleteProfileComponent
  ],
    imports: [
        CommonModule,
        McLoginRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        NgbDropdownModule,
        McComponentsModule,
        TranslateModule,
        NgbNavModule,
        QRCodeModule
    ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class McLoginModuleModule { }
