/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EbSepaFileReport} from '../models/eb-sepa-file-report.model';
import {EbSepaFileReportList} from '../models/eb-sepa-file-report-list.model';
import {EbSepaFileReportFilter} from '../models/eb-sepa-file-report-filter.model';
import {EbSepaFileReportService} from '../services/eb-sepa-file-report.service';
import {IEbSepaFileReport} from '../_generated/eb-sepa-file-report.interface';
import {McGod} from "../extra/mc-god.service";

export class EbSepaFileReportListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'ftAcceptedForFactoringA', 'ftAcceptedForFactoringB', 'ftAcceptedForFactoringC', 'ftImmediatelyPayableBalanceA', 'ftImmediatelyPayableBalanceB', 'ftImmediatelyPayableBalanceC', 'ftLaterPayableBalanceA', 'ftLaterPayableBalanceB', 'ftLaterPayableBalanceC', 'ftNotAcceptedForFactoringA', 'ftNotAcceptedForFactoringB', 'ftNotAcceptedForFactoringC', 'idEbSepaFile', 'reportForFactoringFlg', 'stBatchFeeA', 'stBatchFeeB', 'stBatchFeeC', 'stBatchFeePlusVatC', 'stFactoringFeeA', 'stFactoringFeeB', 'stFactoringFeeC', 'stImmediatelyPayableTransactionCount', 'stNonFactoringFeePerTransactionB', 'stPlusVatC', 'stTotalFactoringFeeToBeDeductedC', 'ttFactoringFeeA', 'ttFactoringFeeB', 'ttFactoringFeeC', 'ttImmediatelyPayableBalanceA', 'ttImmediatelyPayableBalanceB', 'ttImmediatelyPayableBalanceC', 'ttPayableBalanceC', 'ftAcceptedForFactoringBLoc', 'ftAcceptedForFactoringCLoc', 'ftImmediatelyPayableBalanceBLoc', 'ftImmediatelyPayableBalanceCLoc', 'ftLaterPayableBalanceBLoc', 'ftLaterPayableBalanceCLoc', 'ftNotAcceptedForFactoringBLoc', 'ftNotAcceptedForFactoringCLoc', 'stBatchFeeBLoc', 'stBatchFeeCLoc', 'stBatchFeePlusVatCLoc', 'stFactoringFeeALoc', 'stFactoringFeeBLoc', 'stFactoringFeeCLoc', 'stNonFactoringFeePerTransactionBLoc', 'stPlusVatCLoc', 'stTotalFactoringFeeToBeDeductedCLoc', 'ttFactoringFeeALoc', 'ttFactoringFeeBLoc', 'ttFactoringFeeCLoc', 'ttImmediatelyPayableBalanceBLoc', 'ttImmediatelyPayableBalanceCLoc', 'ttPayableBalanceCLoc'];
  public items: EbSepaFileReport[] = [];
  public apiService: EbSepaFileReportService;
 // protected tempObjList: EbSepaFileReportList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbSepaFileReportList {
    console.error('MTCN-ERROR: Not Implemented: EbSepaFileReportList::createNewInstance(). Add this method to final class and return new EbSepaFileReportList();');
    throw new Error('Cannot EbSepaFileReportList::createNewInstance(). Add this method to final class and return new EbSepaFileReportList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEbSepaFileReport[]): EbSepaFileReportList {
    const  listObj: EbSepaFileReportList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EbSepaFileReport = EbSepaFileReport.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EbSepaFileReportList {
    const  listObj: EbSepaFileReportList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbSepaFileReportService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEbSepaFileReport[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EbSepaFileReport = EbSepaFileReport.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEbSepaFileReportFilter: EbSepaFileReportFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEbSepaFileReportFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EbSepaFileReport {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EbSepaFileReport();
    }
  }

  public replaceItem(newItem: EbSepaFileReport) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
// ---------------------------------------------------------------------
public callGeneratePreReportForSepaFile( idEbSepaFile: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.generatePreReportForSepaFile(idEbSepaFile).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

}

