import {Component, OnInit} from '@angular/core';
import {ICON_ADD} from "../../../../assets/media/svg_icons/icon-add";
import {CsrContractPreDefined, CsrContractPreDefinedService, McGod, McValueLabelList, SortCriteriaItem, SortCriteriaList} from "@miticon-ui/mc-core";
import {HttpParams} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig} from "@miticon-ui/mc-components";
import {Router} from "@angular/router";
import {ContractPredefinedViewDialogComponent} from "./contract-predefined-view-dialog/contract-predefined-view-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'lib-contracts-predefined-list-page',
  templateUrl: './contracts-predefined-list-page.component.html',
  styleUrls: ['./contracts-predefined-list-page.component.scss']
})
export class ContractsPredefinedListPageComponent implements OnInit {
  iconAdd = ICON_ADD;
  items: CsrContractPreDefined[] = [];
  isLoading = false;
  pageNumber: number = 0;
  pageSize: number = 5;
  totalItemsCount!: number;
  searchToolTip = this.tS.instant('cc.contracts-360-list.search-tooltip');
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant("cc.common.view.view"),
      actionCd: CsrContractPreDefined.ACTION_VIEW,
      matIcon: 'visibility',
      permission: McGod.PERM_CSR_CONSUMER_360
    },
    {
      title: this.tS.instant("cc.common.edit"),
      actionCd: CsrContractPreDefined.ACTION_EDIT,
      matIcon: 'edit',
      permission: McGod.PERM_CSR_CONSUMER_360
    }
  ];


  constructor(private csrContractPredefinedService: CsrContractPreDefinedService,
              private router: Router,
              private tS: TranslateService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.initTableConfig();
    this.initFilterConfig();
    const params = new HttpParams()
      .set('page', this.pageNumber)
      .set('size', this.pageSize);
    this.actLoad(this.pageNumber, this.pageSize, params);
  }

  actLoad(pageNumber: number, pageSize: number, params: HttpParams): void {
    this.isLoading = true;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    const sort = new SortCriteriaItem('id', 'DESC');
    const sortCriteriaList = new SortCriteriaList();
    sortCriteriaList.addSort(sort.propertyName, sort.direction);
    this.csrContractPredefinedService.get(params, pageNumber, pageSize, sortCriteriaList).subscribe((res)=> {
      if (res) {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => object = CsrContractPreDefined.createFromJson(object));
      }
    }, () => {
      this.isLoading = false;
    });
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.name'), 'getName()', 250, 'name');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.billing.price'), 'getPrice()', 250, 'price');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.jobs.edit.type'), 'getTypeLabel()', 250, 'price');
    this.tableConfig.addColumnStandard(this.tS.instant('mem.product.one-time'), 'getOneTimePrice()', 250, 'price');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.products'), 'getServices()', 250,'services');
    this.tableConfig.addColumnStandard(this.tS.instant('mem.common.date-from'), 'getDateFrom()', 250,'startDate');
    this.tableConfig.addColumnStandard(this.tS.instant('mem.common.date-to'), 'getDateTo()', 250, 'endDate');
    this.tableConfig.addColumnSvgIcon(this.tS.instant('cc.common.status'), 'getStatus()','statusCd');
  }

  initFilterConfig() {
    const statusValueLabelList = new McValueLabelList();
    statusValueLabelList.add('active', this.tS.instant("cc.common.view.active"));
    statusValueLabelList.add('inactive', this.tS.instant("cc.common.view.inactive"));

    this.filterConfig.addItem(MkFilterItemType.SELECT, this.tS.instant('cc.common.view.status'), statusValueLabelList.items.map((item) => new MkFilterValue(item.label, item.value)));
    this.filterConfig.addItem(MkFilterItemType.DATEPICKER, this.tS.instant('mem.common.date-from'));
    this.filterConfig.addItem(MkFilterItemType.DATEPICKER, this.tS.instant('mem.common.date-to'));
  }

  onFilterChanged(filterOutput: MkFilterOutput) {
    const params = new HttpParams()
      .set('page', filterOutput.pageEvent.pageIndex)
      .set('size',filterOutput.pageEvent.pageSize)
      .set('term', filterOutput.search ? filterOutput.search : '')
      .set('dateFrom', filterOutput.selections[this.tS.instant('mem.common.date-from')] ? filterOutput.selections[this.tS.instant('mem.common.date-from')] : '')
      .set('dateTo', filterOutput.selections[this.tS.instant('mem.common.date-to')] ? filterOutput.selections[this.tS.instant('mem.common.date-to')] : '')
      .set('visibilityCd', filterOutput.selections[this.tS.instant("mem.memberships.available-on-landing-page")] ? filterOutput.selections[this.tS.instant("mem.memberships.available-on-landing-page")] : '')
      .set('statusCd', filterOutput.selections[this.tS.instant('cc.common.view.status')] ? filterOutput.selections[this.tS.instant('cc.common.view.status')] : '')
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, params);
  }

  onTableAction($event: MkMatTableMenuAction) {
    switch ($event.action.actionCd) {
      case CsrContractPreDefined.ACTION_VIEW:
        this.dialog.open(ContractPredefinedViewDialogComponent, {
          autoFocus: false,
          width: '1200px',
          height: '900px',
          data: $event.item
        });
        break;
      case CsrContractPreDefined.ACTION_EDIT:
        this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/products/contracts-360/edit/${$event.item.id}`);
        break;
    }
  }

  createContract360() {
    this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/products/contracts-360/create`);
  }
}
