export const ICON_ERROR = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <rect id="rect-1" width="24" height="24" x="0" y="0"/>
    <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="userSpaceOnUse">
      <rect width="24" height="24" x="0" y="0" fill="black"/>
      <use fill="white" xlink:href="#rect-1"/>
    </mask>
  </defs>
  <g>
    <use fill="none" xlink:href="#rect-1"/>
    <g mask="url(#mask-2)">
      <path fill="rgb(255,255,255)" fill-rule="evenodd" d="M12 2C6.47998047 2 2 6.48001099 2 12s4.47998047 10 10 10 10-4.48001099 10-10S17.52001953 2 12 2zm0 11c-.55004883 0-1-.4500122-1-1V8c0-.5499878.44995117-1 1-1 .55004883 0 1 .4500122 1 1v4c0 .5499878-.44995117 1-1 1zm-1 2v2h2v-2h-2z"/>
    </g>
  </g>
</svg>`
