/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McBillingBatchAssignedToStatementTable3ConfigGENERATED} from '../_generated/mc-billing-batch-assigned-to-statement-table-3-config-generated.model';

// import {McBillingBatchAssignedToStatementGENERATED} from '../_generated/mc-billing-batch-assigned-to-statement-generated.model';

export class McBillingBatchAssignedToStatementTable3Config extends McBillingBatchAssignedToStatementTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdMcBillingBatch', 'Id of Mc Billing Batch', 'idMcBillingBatch', 'idMcBillingBatch');
    this.addColumn('colIdMcBillingStatement', 'Id of Mc Billing Statement', 'idMcBillingStatement', 'idMcBillingStatement');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colBatchHeader', 'Batch header', 'batchHeader', 'batchHeader');
    this.addColumn('colAcceptedForSFColumnPercentage', 'acceptedForSFColumnPercentage', 'acceptedForSFColumnPercentage', 'acceptedForSFColumnPercentage');
    this.addColumn('colAcceptedForSFColumnInitialSum', 'acceptedForSFColumnInitialSum', 'acceptedForSFColumnInitialSum', 'acceptedForSFColumnInitialSum');
    this.addColumn('colAcceptedForSFColumnFinalSum', 'acceptedForSFColumnFinalSum', 'acceptedForSFColumnFinalSum', 'acceptedForSFColumnFinalSum');
    this.addColumn('colNotAcceptedForSFColumnPercentage', 'notAcceptedForSFColumnPercentage', 'notAcceptedForSFColumnPercentage', 'notAcceptedForSFColumnPercentage');
    this.addColumn('colNotAcceptedForSFColumnInitialSum', 'notAcceptedForSFColumnInitialSum', 'notAcceptedForSFColumnInitialSum', 'notAcceptedForSFColumnInitialSum');
    this.addColumn('colNotAcceptedForSFColumnFinalSum', 'notAcceptedForSFColumnFinalSum', 'notAcceptedForSFColumnFinalSum', 'notAcceptedForSFColumnFinalSum');
    this.addColumn('colImmediatelyPayableBalanceColumnPercentage', 'immediatelyPayableBalanceColumnPercentage', 'immediatelyPayableBalanceColumnPercentage', 'immediatelyPayableBalanceColumnPercentage');
    this.addColumn('colImmediatelyPayableBalanceColumnInitialSum', 'immediatelyPayableBalanceColumnInitialSum', 'immediatelyPayableBalanceColumnInitialSum', 'immediatelyPayableBalanceColumnInitialSum');
    this.addColumn('colImmediatelyPayableBalanceColumnFinalSum', 'immediatelyPayableBalanceColumnFinalSum', 'immediatelyPayableBalanceColumnFinalSum', 'immediatelyPayableBalanceColumnFinalSum');
    this.addColumn('colLaterPayableBalanceColumnPercentage', 'laterPayableBalanceColumnPercentage', 'laterPayableBalanceColumnPercentage', 'laterPayableBalanceColumnPercentage');
    this.addColumn('colLaterPayableBalanceColumnInitialSum', 'laterPayableBalanceColumnInitialSum', 'laterPayableBalanceColumnInitialSum', 'laterPayableBalanceColumnInitialSum');
    this.addColumn('colLaterPayableBalanceColumnFinalSum', 'laterPayableBalanceColumnFinalSum', 'laterPayableBalanceColumnFinalSum', 'laterPayableBalanceColumnFinalSum');
    this.addColumn('colAlreadyPaidBalanceColumnPercentage', 'alreadyPaidBalanceColumnPercentage', 'alreadyPaidBalanceColumnPercentage', 'alreadyPaidBalanceColumnPercentage');
    this.addColumn('colAlreadyPaidBalanceColumnInitialSum', 'alreadyPaidBalanceColumnInitialSum', 'alreadyPaidBalanceColumnInitialSum', 'alreadyPaidBalanceColumnInitialSum');
    this.addColumn('colAlreadyPaidBalanceColumnFinalSum', 'alreadyPaidBalanceColumnFinalSum', 'alreadyPaidBalanceColumnFinalSum', 'alreadyPaidBalanceColumnFinalSum');
    this.addColumn('colReturningAmountFinalBounceColumnPercentage', 'returningAmountFinalBounceColumnPercentage', 'returningAmountFinalBounceColumnPercentage', 'returningAmountFinalBounceColumnPercentage');
    this.addColumn('colReturningAmountFinalBounceColumnInitialSum', 'returningAmountFinalBounceColumnInitialSum', 'returningAmountFinalBounceColumnInitialSum', 'returningAmountFinalBounceColumnInitialSum');
    this.addColumn('colReturningAmountFinalBounceColumnFinalSum', 'returningAmountFinalBounceColumnFinalSum', 'returningAmountFinalBounceColumnFinalSum', 'returningAmountFinalBounceColumnFinalSum');
    this.addColumn('colAlreadyReturnedBalanceColumnPercentage', 'alreadyReturnedBalanceColumnPercentage', 'alreadyReturnedBalanceColumnPercentage', 'alreadyReturnedBalanceColumnPercentage');
    this.addColumn('colAlreadyReturnedBalanceColumnInitialSum', 'alreadyReturnedBalanceColumnInitialSum', 'alreadyReturnedBalanceColumnInitialSum', 'alreadyReturnedBalanceColumnInitialSum');
    this.addColumn('colAlreadyReturnedBalanceColumnFinalSum', 'alreadyReturnedBalanceColumnFinalSum', 'alreadyReturnedBalanceColumnFinalSum', 'alreadyReturnedBalanceColumnFinalSum');


  }
}
