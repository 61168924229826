<div class="content-wrapper">
  <div class="content-header">
    <h1>{{'cc.product.edit.create-new-category' | translate}}</h1>
  </div>
  <mcc-message [errorMsg]="errorMsg"></mcc-message>
  <div class="content-body">
    <mcc-fi-textbox name="ctrlName"
                    label="{{'cc.common.view.name' | translate}}"
                    [validRequiredFlg]="true"
                    [mcForm]="mcForm">
    </mcc-fi-textbox>
    <mcc-fi-textarea name="ctrlDescription" label="{{ 'cc.common.view.description' | translate }}"
                     [numCols]="35"
                     [mcForm]="mcForm"></mcc-fi-textarea>
  </div>
  <div class="content-footer">

    <button class="btn btn-secondary move-right"
            (click)="actCancel()"><i class="far fa-times-circle"></i> {{'cc.common.edit.cancel' | translate}}
    </button>
    <button class="btn btn-primary" (click)="actSave()"><i
      class="fas fa-plus"></i> {{'cc.common.view.create' | translate}}</button>
  </div>
</div>
