import {EbPsd2Report} from './eb-psd2-report.model';
import {EbPsd2ReportListGENERATED} from '../_generated/eb-psd2-report-list-generated.model';

export class EbPsd2ReportList extends EbPsd2ReportListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EbPsd2ReportList();
  }

  // ---------------------------------------------------------------------
}
