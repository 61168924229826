/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {CsrRecurringDetailsFilter} from '../models/csr-recurring-details-filter.model';

export class CsrRecurringDetailsFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): CsrRecurringDetailsFilter {
    console.error('MTCN-ERROR: Not Implemented: CsrRecurringDetailsFilter::createNewInstance(). Add this method to final class and return new CsrRecurringDetailsFilter();');
      throw new Error('Cannot CsrRecurringDetailsFilter::createNewInstance(). Add this method to final class and return new CsrRecurringDetailsFilter();');
  }

}
