
import {Table3Config} from '../extra/table-3-config';

// import {McCategoryGENERATED} from '../_generated/mc-category-generated.model';

export class McCategoryTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colCreatedAt', 'Created at', 'createdAt', 'createdAt');
    this.addColumn('colCreatedBy', 'Created by', 'createdBy', 'createdBy');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colName', 'Name', 'name', 'name');
    this.addColumn('colUpdatedAt', 'Update at', 'updatedAt', 'updatedAt');
    this.addColumn('colUpdatedBy', 'Updated by', 'updatedBy', 'updatedBy');
    this.addColumn('colVersion', 'Version', 'version', 'version');

*/
  }
}
