import {McGod} from '../extra/mc-god.service';

export abstract class Base {

  // ---------------------------------------------------------------------
 public t(aStringOrCd: string, args: string[] | null = null): string {
   return McGod.t(aStringOrCd, args);
 }
}


