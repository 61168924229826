/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McCurrency} from '../models/mc-currency.model';
import {McCurrencyList} from '../models/mc-currency-list.model';
import {McCurrencyFilter} from '../models/mc-currency-filter.model';
import {McCurrencyService} from '../services/mc-currency.service';
import {IMcCurrency} from '../_generated/mc-currency.interface';
import {McGod} from "../extra/mc-god.service";

export class McCurrencyListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'code', 'localizationKey', 'name'];
  public items: McCurrency[] = [];
  public apiService: McCurrencyService;
 // protected tempObjList: McCurrencyList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McCurrencyList {
    console.error('MTCN-ERROR: Not Implemented: McCurrencyList::createNewInstance(). Add this method to final class and return new McCurrencyList();');
    throw new Error('Cannot McCurrencyList::createNewInstance(). Add this method to final class and return new McCurrencyList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcCurrency[]): McCurrencyList {
    const  listObj: McCurrencyList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McCurrency = McCurrency.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McCurrencyList {
    const  listObj: McCurrencyList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McCurrencyService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcCurrency[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McCurrency = McCurrency.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcCurrencyFilter: McCurrencyFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcCurrencyFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McCurrency {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McCurrency();
    }
  }

  public replaceItem(newItem: McCurrency) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

