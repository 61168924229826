<div class="content-wrapper storno-wrapper">

  <div class="content-header">
    <h2>{{mcGod.t('cc.transaction.choose-collection-type')}}</h2>
  </div>

  <div class="content-body">

    <div class="row">
      <mcc-fi-radio-buttons2 name="ctrlCollectionType"
                         [mcForm]="mcForm"
                         [validRequiredFlg]="true"
                         [directionHorizontal]="false"
                         [value]="false"
                         [optionsVll]="collectionTypeVll">
      </mcc-fi-radio-buttons2>

    </div>
  </div>

  <div class="content-footer d-flex justify-content-end">
    <button [ngClass]="disableBtn ? 'mc-disable-button' : ''" class="system-action-btn dark-system-action-btn" (click)="actSave()">{{mcGod.t("cc.common.view.save")}}</button>
    <button class="system-action-btn light-system-action-btn" (click)="actCancel()">{{mcGod.t("cc.common.edit.cancel")}}</button>
  </div>

</div>
