import {Inject, Injectable} from '@angular/core';
import {McBillingEntitiesUsingPricelistServiceGENERATED} from '../_generated/mc-billing-entities-using-pricelist-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McBillingEntitiesUsingPricelistService extends McBillingEntitiesUsingPricelistServiceGENERATED {

}
